import "core-js/modules/es.array.push.js";
import TableReceivable from './TableReceivable';
import { SelectCustom, SelectStaff, BtnExport, InputMonthRange
// TableTotalBox,
} from '@/components/common';
import { incomeList } from '@/config/report';
import { searchMixin, excelMixin } from '@/mixin';
import { getDateBetween } from '@/utils';
import { parseMonth } from '@/utils';
const curYear = new Date().getFullYear();
const Jan = curYear + '-01';
const curMonth = parseMonth(new Date());
import { exportTable } from '@/utils/exportTableUtil';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    BtnExport,
    TableReceivable,
    InputMonthRange
    // TableTotalBox,
  },
  mixins: [searchMixin, excelMixin],
  data: () => ({
    columnData: [],
    tableData: [],
    sendMonth: [Jan, curMonth],
    total: 0,
    statisticData: [],
    searchParams: {
      startMonth: Jan,
      endMonth: curMonth
    },
    statistic: {},
    statisticsKeys: [],
    isTableShow: true
  }),
  methods: {
    fetchColumnData(searchParams = this.searchParams) {
      const {
        startMonth,
        endMonth
      } = searchParams;
      const month = getDateBetween(startMonth, endMonth, 'month');
      const columnYear = month.map(it => {
        const item = {
          attrs: {
            prop: it,
            label: it.replace('-', '年') + '月'
          },
          children: []
        };
        return item;
      });
      this.$nextTick(() => {
        columnYear.map(it => {
          const item = [{
            attrs: {
              prop: it.attrs.prop + 'subsidy',
              label: '返款'
            }
          }, {
            attrs: {
              prop: it.attrs.prop + 'collect',
              label: '代收'
            }
          }, {
            attrs: {
              prop: it.attrs.prop + 'income',
              label: '应收'
            }
          }, {
            attrs: {
              prop: it.attrs.prop + 'incomed',
              label: '已收'
            }
          }, {
            attrs: {
              prop: it.attrs.prop + 'unIncome',
              label: '未收'
            }
          }, {
            attrs: {
              prop: it.attrs.prop + 'account',
              label: '调账'
            }
          }];
          it.children = item;
          return it;
        });
        this.columnData = [...incomeList, ...columnYear, {
          attrs: {
            prop: '',
            label: '合计'
          },
          children: [{
            attrs: {
              prop: 'totalIncome',
              label: '应收汇总'
            }
          }, {
            attrs: {
              prop: 'totalCollect',
              label: '代收汇总'
            }
          }, {
            attrs: {
              prop: 'totalIncomed',
              label: '已收汇总'
            }
          }, {
            attrs: {
              prop: 'totalUnIncome',
              label: '未收汇总'
            }
          }, {
            attrs: {
              prop: 'totalSubsidy',
              label: '代收汇总'
            }
          }, {
            attrs: {
              prop: 'totalAccount',
              label: '调账汇总'
            }
          }]
        }];
        // 计算的字段
        this.statisticsKeys = [];
        this.columnData.forEach(it => {
          if (it.children) {
            it.children.forEach(v => {
              this.statisticsKeys.push(v.attrs.prop);
            });
          }
        });
      });
      // this.isTableShow = true
    },
    fetchData(queryParam = this.searchParams) {
      this.isTableShow = false;
      const {
        startMonth,
        endMonth
      } = queryParam;
      if (!startMonth || !endMonth) {
        this.$bus.tip({
          type: 'warning',
          message: '查询年月开始时间和结束时间不能为空！'
        });
        return false;
      }
      this.fetchColumnData(queryParam);
      this.$store.dispatch('report/index/fetchCustomeIncomeList', queryParam).then(res => {
        // 去除客户为空或者没有区域经理的数据
        const list = res.filter(it => it.customerId && it.customer && it.customer.customerAreaManagerId > 0);
        const data = this.formatData(list);
        // 分组
        const group = this.formatList(data);
        const sumArray = this.formatSumData(group);
        this.tableData = this.formatMergeData(group, sumArray);
        this.statisticData = this.formatSum(sumArray);
        this.isTableShow = true;
      });
    },
    // 计算合计
    formatSum(list) {
      if (!list.length) return [];
      let sumObj = {};
      this.statisticsKeys.forEach(key => {
        sumObj[key] = 0;
      });
      list.forEach(it => {
        this.statisticsKeys.forEach(key => {
          sumObj[key] += it[key];
          sumObj.outOPUserName = '';
          sumObj.customerName = '';
        });
      });
      return sumObj;
    },
    // 合并小计和重构后的数组
    formatMergeData(sortList, sumArray) {
      let list = [];
      sortList.forEach(it => {
        list = [...list, ...it];
      });
      list = [...list, ...sumArray];
      list.sort((a, b) => {
        return a.customer.customerAreaManagerId - b.customer.customerAreaManagerId;
      });
      return list;
    },
    // 计算小计
    formatSumData(list) {
      let sumArray = [];
      list.forEach(item => {
        let sumObj = {};
        this.statisticsKeys.forEach(key => {
          sumObj[key] = 0;
        });
        item.forEach(it => {
          this.statisticsKeys.forEach(key => {
            sumObj.customer = it.customer;
            sumObj[key] += it[key];
            sumObj.index = '小计';
            sumObj.outOPUserName = it.outOPUserName;
            sumObj.customerName = it.outOPUserName + '应收账款';
          });
        });
        sumArray.push({
          ...sumObj,
          isTotal: true
        });
      });
      return sumArray;
    },
    // 分组
    formatList(list) {
      const objBy = (array, f) => {
        let objs = {};
        array.forEach(it => {
          let obj = JSON.stringify(f(it));
          objs[obj] = objs[obj] || [];
          objs[obj].push(it);
        });
        return Object.keys(objs).map(v => {
          return objs[v];
        });
      };
      const sorted = objBy(list, item => {
        return item.customer.customerAreaManagerId;
      });
      // 返回分组并添加上序号
      return sorted.map(it => {
        it.forEach((v, i) => {
          v.index = i + 1;
        });
        return it;
      });
    },
    // 重构基础数据
    formatData(list) {
      if (!list.length) return [];
      return list.map(it => {
        it.totalIncome = 0;
        it.totalCollect = 0;
        it.totalIncomed = 0;
        it.totalUnIncome = 0;
        it.totalSubsidy = 0;
        it.isTotal = false;
        it.customerName = it.customer.name;
        it.outOPUserId = it.customer.customerAreaManagerId;
        it.outOPUserName = it.customer.customerAreaManager;
        it.yearMonthIncomes.forEach(v => {
          it[v.month + 'income'] = v.income;
          it[v.month + 'collect'] = v.collect;
          it[v.month + 'incomed'] = v.incomed;
          it[v.month + 'subsidy'] = v.subsidy;
          it[v.month + 'unIncome'] = v.unIncome;
          it[v.month + 'account'] = '';
          it.totalIncome += v.income;
          it.totalCollect += v.collect;
          it.totalIncomed += v.incomed;
          it.totalUnIncome += v.unIncome;
          it.totalSubsidy += v.subsidy;
          it.totalAccount = 0;
        });
        return it;
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        statistics
      } = data;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    // 选择散、发团日期
    changeMonth(date) {
      this.searchParams.startMonth = date[0];
      this.searchParams.endMonth = date[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    // handlePage({ pageNum, pageSize }) {
    //   this.fetchData({ pageNum, pageSize, ...this.getTagViewSearch })
    // },

    // 导出 excel
    exportExcel() {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有数据导出！'
        });
        return false;
      }
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt = document.getElementById('export-receivable-detail-table');
      exportTable(elt, '应收账款明细表', '应收账款明细表导出', 2);
      loading.close();
    }
  }
};