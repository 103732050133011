var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_vm.getResourceType(_vm.ResourceType.scenic) ? _c("InputScenic", {
    attrs: {
      label: "景区",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  }) : _vm._e(), _vm.getResourceType(_vm.ResourceType.restaurant) ? _c("InputRestaurant", {
    attrs: {
      label: "餐厅",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  }) : _vm._e(), _vm.getResourceType(_vm.ResourceType.fleet) ? _c("InputFleet", {
    attrs: {
      label: "车队",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  }) : _vm._e(), _vm.getResourceType(_vm.ResourceType.traffic) ? _c("InputTraffic", {
    attrs: {
      label: "大交通",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  }) : _vm._e(), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.changeStarTripDate
    }
  }), _c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("BtnExport", {
    attrs: {
      "has-excel": _vm.hasExcel
    },
    on: {
      "on-search": _vm.handleSearch
    }
  }, [_c("BtnMore", {
    attrs: {
      visible: _vm.visibleMore
    },
    on: {
      "on-click": val => _vm.visibleMore = !_vm.visibleMore
    }
  })], 1)], 1)]), _c("transition", {
    attrs: {
      name: "fade-down",
      mode: "out-in"
    }
  }, [_c("FormBase", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getVisible,
      expression: "getVisible"
    }]
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selecthotelRegion
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "计划类型",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  }), _c("InputBase", {
    attrs: {
      label: "小团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectProduct", {
    attrs: {
      label: "产品"
    },
    on: {
      "on-select": _vm.onSelectProduct
    },
    model: {
      value: _vm.searchParams.productLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productLabel", $$v);
      },
      expression: "searchParams.productLabel"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": _vm.changeOrderDate
    }
  }), _c("SelectLine", {
    attrs: {
      label: "线路"
    },
    model: {
      value: _vm.searchParams.travelName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "travelName", $$v);
      },
      expression: "searchParams.travelName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectGuide", {
    attrs: {
      label: "报账导游",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("guide", $event);
      }
    },
    model: {
      value: _vm.searchParams.guideLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideLabel", $$v);
      },
      expression: "searchParams.guideLabel"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": _vm.changeEndTripDate
    }
  })], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };