import { mapGetters } from 'vuex';
import { ruleBank as rules } from '../config';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules,
      form: this.data
    };
  },
  computed: {
    ...mapGetters('resource/account', ['editAccountInfo'])
  },
  watch: {
    editAccountInfo: [{
      handler: 'initAccount',
      immediate: true
    }]
  },
  methods: {
    initAccount(val) {
      val.bankCardType = val.bankCardType ? Number(val.bankCardType) : '';
      // this.form = val
    },
    handleChange(type, val) {
      this.form[type] = val;
      this.$store.dispatch('resource/account/editAccount', this.form);
    }
  }
};