var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("Customer-info", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getType("proxyType", scope.row.type)) + " ")];
      }
    }, {
      key: "totalMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalMoney) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.title) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };