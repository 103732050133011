import "core-js/modules/es.array.push.js";
import { SelectGuide, SelectDictionary } from '@/components/common';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { parseMonth } from '@/utils'; //getDaysBetween
import { getCurTagView } from '@/utils/tagView';
const month = parseMonth(new Date(), '{y}-{m}');
export default {
  components: {
    SelectGuide,
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    columnData: [],
    tableData: [],
    total: 0,
    searchParams: {
      month,
      status: 'arranged',
      statusLabel: '已安排'
    }
  }),
  computed: {
    ...mapGetters({
      guideDutyData: 'plan/index/guideDutyData',
      userData: 'user/userData'
    }),
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    guideDutyData: {
      handler: 'initData',
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        this.searchParams = data;
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    classObject(item, i) {
      if (item['col' + i] === i) {
        return 'pale-orange';
      }
      return '';
    },
    iScolSpan(item, i) {
      if (item.daySItem && item.daySItem.includes(i)) {
        return false;
      }
      return true;
    },
    columnDataFormat(day) {
      const columnList = [];
      const attrs1 = {
        attrs: {
          label: '姓名',
          prop: 'name'
        }
      };
      for (var i = 0; i < day; i++) {
        const item = {
          attrs: {
            label: i + 1 + '日',
            prop: 'day' + Number(i + 1)
          }
        };
        columnList.push(item);
      }
      this.columnData = [attrs1, ...columnList];
    },
    initData(data) {
      if (data) {
        this.getData(data);
      } else {
        this.handleSearch();
      }
    },
    fetchData(params) {
      // 判断路由是不是集团排班 不是的话就不传旅行社id
      const {
        travelAgencyId,
        groupId
      } = this.userData;
      if (this.$route.path != '/plan/group-guide-arrange' || groupId < 0) {
        params = {
          ...params,
          travelAgencyId
        };
      }
      this.$store.dispatch('plan/index/getGuideDuty', params);
    },
    getData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableData = this.formatData2(list);
      this.total = totalCount;
      this.mGetDate();
    },
    formatData2(list) {
      if (!list || !list.length) return [];
      // 获取当前搜索时间 年月
      let [yearNow, monthNow] = this.searchParams.month.split('-');
      const result = [];
      list.forEach(it => {
        let itemList = it.schedules || [];
        if (itemList.length) {
          // 排序从小到大
          itemList.sort(function (a, b) {
            return b.startTripDate < a.startTripDate ? 1 : -1;
          });
          it.daySItem = [];
          itemList.forEach((v, i) => {
            const [yearStart, monthStart] = v.startTripDate.split('-');
            const [, monthEnd] = v.endTripDate.split('-');
            // 使用月份判断行程日期是否跨越到下个月(如果是,则结束日期重置为当月最后一天) 跨年问题
            if (monthNow == monthStart && monthNow < monthEnd) {
              let lastDay = new Date(yearNow, monthNow, 0).getDate();
              v.endTripDate = `${yearStart}-${monthStart}-${lastDay}`;
            }
            // 使用月份判断行程日期是否跨越上个月(如果是,则开始日期重置为1号) 跨年问题
            if (monthNow > monthStart && monthNow == monthEnd) {
              v.startTripDate = `${yearNow}-${monthNow}-01`;
            }
            // 获取所有
            v.dates = this.getDaysListBetween(v.startTripDate, v.endTripDate);
            // 存储天数
            v.arrangeDay = v.dates.length;
            if (i > 0) {
              // 根据已经存的所有日期来对照当前日期是否有重叠
              const overlaps = it.addDays.filter(it => v.dates.includes(it));
              // 如果有重叠就直接去除重叠日期
              v.dates = v.dates.filter(it => !overlaps.includes(it));
              v.arrangeDay = v.dates.length;
            }
            // 第几天开始合并
            it['col' + v.dates[0]] = v.dates[0];
            // 合并几天
            it['colSpan' + v.dates[0]] = v.arrangeDay;
            it['day' + v.dates[0]] = `${v.planNumber}(购物：${v.shopMoney || 0})`;
            it.addDays = [...it.daySItem, ...v.dates];
            it.daySItem = [...it.daySItem, ...v.dates.slice(1)];
          });
        }
        result.push(it);
      });
      return result;
    },
    // 获取行程具体的日期 (只取天)
    getDaysListBetween(start, end) {
      var result = [];
      //使用传入参数的时间
      var startTime = new Date(start);
      var endTime = new Date(end);
      while (endTime - startTime >= 0) {
        let day = startTime.getDate().toString().length == 1 ? startTime.getDate() : startTime.getDate();
        //加入数组
        result.push(day);
        //更新日期
        startTime.setDate(startTime.getDate() + 1);
      }
      return result;
    },
    // 初始化表格头部
    mGetDate() {
      const [year, month] = this.searchParams.month.split('-');
      var d = new Date(year, month, 0);
      this.columnDataFormat(d.getDate());
      return d.getDate();
    },
    // 选择安排类型
    selectStatus(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.statusLabel = label;
      this.searchParams.status = value === 1 ? 'arranged' : value === 0 ? 'un' : 'all';
    },
    // 选择导游
    selectGuide(type, val) {
      this.searchParams[type] = val.label;
    },
    // 选择
    changeMonth(date) {
      if (!date) {
        this.$bus.tip({
          type: 'warning',
          message: '年月不能为空!'
        });
        return false;
      }
      this.searchParams.month = parseMonth(date);
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    }
  }
};