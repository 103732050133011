import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { APP } from '@/config';
export const linkDownload = ({
  data,
  fileName
}) => {
  const blob = new Blob([data]);
  var downloadElement = document.createElement('a');
  var href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};

// 拼接导出url
export const getExportUrl = path => {
  return APP.baseUrl + path;
};