import { Traffic } from '@/components/common/Order';
import { auditEditTraffic } from '@/config/audit';
import { planIsOrderInfoMixin } from '@/mixin';
export default {
  components: {
    Traffic
  },
  mixins: [planIsOrderInfoMixin],
  data() {
    return {
      columnData: auditEditTraffic
    };
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};