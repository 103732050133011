import { FooterBar } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { getPayedMoney } from '@/views/billDown/help';
import { downBillUnitPayed } from '@/config/billDown';
export default {
  components: {
    FooterBar
  },
  mixins: [tagViewMixin],
  data() {
    return {
      exportLoading: false,
      billCheckAll: false,
      tableData: [],
      columnData: downBillUnitPayed.slice(0, -1),
      resourceId: '',
      total: 0,
      money: 0,
      backPath: '/bill/unit-collect/customer'
    };
  },
  computed: {
    getResourceId() {
      return this.resourceId;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData(queryParams = {}) {
      const {
        rid: resourceId,
        id: logCashId
      } = this.$route.query;
      this.resourceId = resourceId;
      this.fetchData({
        resourceId,
        logCashId,
        ...queryParams
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('bill/billDown/fetchPayedTeamFund', queryParams).then(data => {
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    changeMoney(money) {
      money = money - getPayedMoney(this.tableData);
      this.$refs.unPayRef.caclDownMoney(money);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.initData({
        pageNum,
        pageSize
      });
    },
    handleClose() {
      this.closeSelectedTag();
    }
  }
};