import { columns } from '../table';
import { mapGetters } from 'vuex';
export default {
  // components: { SelectGuide },
  data() {
    return {
      columns,
      visible: false,
      checkAll: false,
      loading: false,
      total: 0,
      form: {},
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      guideData: 'resource/guide/guideData' // 导游信息
    })
  },
  methods: {
    show() {
      this.visible = true;
      setTimeout(this.fetchData, 500);
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    fetchData() {
      this.fetchList();
      this.tableData = this.guideData.filter(it => it.alipayEmployeeId != '');
    },
    fetchList(params) {
      return this.$store.dispatch('resource/guide/fetchList', params);
    },
    handleSearch() {
      this.fetchList(this.form);
    },
    handleInvite() {
      // 过滤未勾选的导游
      const guideIdList = this.tableData.filter(it => it.isCheck).map(it => it.id);
      Promise.all(guideIdList.map(guideId => this.$store.dispatch('enterprise/guide/addInvite', {
        guideId
      }))).then(() => {
        this.$bus.tip({
          message: '邀请已发出，待对方确认！'
        });
        this.hide();
      });
    }
  }
};