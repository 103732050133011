import axios from 'axios';
import store from '@/store';
import { Message, Loading } from 'element-ui';
import { getToken } from '@/utils/cookie';
import { getStatus } from '@/utils';
import { APP } from '@/config';
import { requireAuthUrl } from '@/config/requirePath';
const urlPrefix = window.location.host;
const noLoadingList = ['/search-wechat-pay-status', '/order/out/page-out'];
const filterPath = ['system/user/findList'];
// 全国旅游平台
const TourteamPathMap = {
  path: 'req-country-e-trip',
  errno: 0
};

// prettier-ignore
const ErrorTipMap = {
  'GENERAL': '服务器更新中，请稍等...',
  '系统异常': '服务器更新中，请稍等...',
  '60121': '请联系系统管理员，将本系统登录手机号码与钉钉手机号码改为一致',
  '发生前端传参数格式异常!': '提交数据不完整或者格式错误'
};
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: APP.timeout,
});
function showMessage(msg, duration = 6000) {
  Message({
    type: 'error',
    message: msg,
    duration,
    showClose: true
  });
}
let loadingRequestCount = 0;
let loadingInstance;
const showLoading = () => {
  if (loadingRequestCount === 0) {
    loadingInstance = Loading.service({
      lock: true,
      text: '拼命加载数据中...',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, .6)'
    });
  }
  loadingRequestCount++;
};
const hideLoading = () => {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    setTimeout(() => {
      loadingInstance.close();
    }, 0);
  }
};
const exitAuth = () => {
  // 删除 token 挑战到登陆页
  store.dispatch('user/resetToken').then(() => {
    setTimeout(() => {
      window.location.reload();
    });
  });
};

// 处理服务器更新提示信息
const errorTip = msg => {
  let str = msg || '';
  Object.keys(ErrorTipMap).map(key => {
    if (str.includes(key)) {
      str = ErrorTipMap[key];
    }
  });
  return str;
};
const getRouteParam = path => {
  const [url] = path.split('?');
  const subMenus = store.getters['permission/subMenus'];
  if (!subMenus || !subMenus.length) return -1;
  let findItem = subMenus.find(it => it.code == url);

  // 如果不存在则是子级，则直接拿父级的菜单id
  if (!findItem) {
    const item = requireAuthUrl.filter(it => it.path == url);
    if (item.length) {
      findItem = subMenus.find(it => it.code == item[0].pPath);
    }
  }
  return findItem ? findItem.id : -1;
};
export let isJsonStr = str => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
};
export function againRequest(err, axios) {
  let config = err.config;

  // config.retry 具体接口配置的重发次数
  if (!config || !config.retry) return Promise.reject(err);

  // 设置用于记录重试计数的变量 默认为0
  config.__retryCount = config.__retryCount || 0;

  // 判断是否超过了重试次数
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err);
  }
  // 重试次数
  config.__retryCount += 1;

  // 延时处理
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1000);
  });
  // 重新发起axios请求
  return backoff.then(function () {
    // 判断是否是JSON字符串
    // TODO: 未确认config.data再重发时变为字符串的原因
    if (config.data && isJsonStr(config.data)) {
      config.data = JSON.parse(config.data);
    }
    return axios(config);
  });
}
service.interceptors.request.use(config => {
  const isShow = noLoadingList.some(v => config.url.indexOf(v) !== -1);
  const notUserReq = filterPath.some(v => config.url.indexOf(v) !== -1);
  !isShow && showLoading();

  // 菜单id标识
  const menuId = getRouteParam(window.location.href.split(urlPrefix)[1]);
  if (store.getters['user/token']) {
    // 如果传了menuId则不修改
    config.headers = Object.assign(config.headers || {}, {
      Authorization: 'bearer ' + getToken()
      // ...getGroupHeaders(),
    });
    if (config.headers.menuId) {
      config.headers = Object.assign(config.headers || {}, {
        menuId: config.headers.menuId
      });
    } else {
      if (!notUserReq && menuId) {
        config.headers = Object.assign(config.headers || {}, {
          menuId
        });
      }
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});
service.interceptors.response.use(response => {
  const {
    data,
    config
  } = response;
  const {
    code,
    message
  } = data;

  // 如果是支付宝支付接口
  if (config.url === '/system/vas/alipay') {
    return data;
  }
  hideLoading();

  // 全国旅监平台
  if (~config.url.indexOf(TourteamPathMap.path)) {
    if (data.error_code == TourteamPathMap.errno) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(data);
  }
  if (getStatus(response)) {
    if (code == 401) {
      exitAuth();
    }
    return Promise.resolve(data);
  } else {
    // if (code == 500) {
    //   showMessage(message || data.msg)
    // }
    if (code == 5001) {
      showMessage(message || data.msg);
    }

    // 多个旅行社的时候返回或者微信未绑定
    if (code != APP.travelErrNo && code != APP.travelErrNoBind) {
      if (code != 5001) {
        showMessage(message);
      }
    }
    return Promise.reject(data);
  }
}, error => {
  hideLoading();
  let url = '';
  if (error.response) {
    url = error.response.config.url;
  }

  // 断网 或者 请求超时 状态
  if (url == '/blacklist/tourist/searchAndSaveUsers' || url == '/blacklist/tourist/delUsers') {
    return Promise.reject(error);
  }
  if (!error.response) {
    // 请求超时状态
    if (error.message.includes('timeout')) {
      showMessage('请求超时，请检查网络是否连接正常');

      // againRequest(error, service)
    } else {
      // 可以展示断网组件
      showMessage('请求失败，请检查网络是否已连接');
    }
    return Promise.reject(error);
  }

  // 服务器返回不是 2 开头的情况，会进入这个回调
  // 可以根据后端返回的状态码进行不同的操作
  const responseCode = error.response.status;
  const msg = error.response.data.message;
  switch (responseCode) {
    // 401：未登录
    case 401:
      exitAuth(); // 跳转登录页
      break;
    // 403: token过期
    case 403:
      // 弹出错误信息
      showMessage('登录信息过期，请重新登录');

      // 清除token
      // 跳转登录页面，并将要浏览的页面query.panoid传过去，登录成功后跳转需要访问的页面
      setTimeout(() => {
        window.location.href = window.$global.RedirectUrl;
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      showMessage('网络请求不存在');
      break;

    // 其他错误，直接抛出错误提示
    default:
      showMessage(errorTip(msg));
  }
  return Promise.reject(error);
});
export const requestBlob = ({
  url,
  params = {},
  method = 'GET',
  responseType = 'blob'
}) => {
  const menuId = getRouteParam(window.location.href.split(urlPrefix)[1]);
  return axios.request({
    url,
    method,
    responseType,
    headers: {
      Authorization: 'bearer ' + getToken(),
      menuId
    },
    params
  });
};
export default service;