var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "flex"
  }, [_c("h3", [_vm._v("计调权限设置")]), _c("div", {
    staticClass: "pl5"
  }), _c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function ($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? "保存修改" : "编辑权限") + " ")])], 1), _c("CheckboxGroup", {
    attrs: {
      options: _vm.options,
      edit: _vm.isEdit
    },
    on: {
      "on-change": _vm.handleChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };