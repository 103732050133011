var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "span-method": _vm.objectSpanMethod,
      "hide-bar": true,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "customerAreaService",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerAreaService) + " ")];
      }
    }, {
      key: "kpitype",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.projectName(scope.row)) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "action-btn-box"
        }, [_c("span", {
          staticClass: "text-edit pointer",
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("span", {
          staticClass: "text-log pointer",
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("span", {
          staticClass: "text-del pointer",
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };