import SearchUnit from '../components/SearchUnit';
import TableList from './components/TableList';
import { SelectCustom } from '@/components/common';
import { reloadMixin } from '@/mixin';
export default {
  components: {
    SearchUnit,
    TableList,
    SelectCustom
  },
  mixins: [reloadMixin],
  data() {
    return {
      path: '/bill/unit-pay/customer-create',
      resourceId: '',
      searchParams: {}
    };
  },
  mounted() {
    if (this.$route.query.isBack) {
      this.fetchData();
    }
  },
  methods: {
    fetchData(params = {}) {
      const resourceId = this.resourceId;
      this.$store.dispatch('bill/billDown/payUnitCustomer/fetch', {
        resourceId,
        ...this.searchParams,
        ...params
      });
    },
    selectResource(val) {
      const {
        id
      } = val;
      this.resourceId = id;
    },
    // 搜索
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    handlePage(params) {
      this.fetchData(params);
    },
    handleExport() {
      this.$refs.tableRef.exportExcel();
    }
  }
};