import { fetchLog, fetchList, fetchTraffic, createOrUpdateTraffic, deleteTraffic, fetchListGroup } from '@/api/resource/traffic';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  trafficData: null,
  trafficGroupData: null,
  trafficDataOrigin: null,
  trafficLog: null,
  trafficAgreement: null
});
const state = dfaultState();
const getters = {
  trafficData: state => state.trafficData,
  trafficGroupData: state => state.trafficGroupData,
  trafficDataOrigin: state => state.trafficDataOrigin,
  trafficLog: state => state.trafficLog,
  trafficAgreement: state => state.trafficAgreement
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchTraffic(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表(集团)
  fetchListGroup({
    commit
  }, queryParam) {
    return fetchListGroup(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.transport
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.transport
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchAgreement({
    commit,
    rootGetters
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.transport
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        list
      } = res.data;
      // 更新当前协议内容列表
      if (rootGetters['resource/common/curAgreementId']) {
        const findItem = list.find(it => it.id === rootGetters['resource/common/curAgreementId']);
        this.dispatch('resource/common/agreementChild', findItem);
      }
      commit('FETCH_AGREEMENT', res.data);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  addAgreementItem({}, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateTraffic(opts).then(res => {
      const {
        data
      } = res;
      dispatch('fetchList');
      commit('UPDATE', data);
      return data;
    });
  },
  delete({
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      if (!delFiles.length) return;
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteTraffic(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.trafficData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.trafficGroupData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.trafficDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.trafficLog = data;
  },
  FETCH_AGREEMENT: (state, data) => {
    state.trafficAgreement = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.trafficData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.trafficData.list.filter(it => it.id !== id);
    state.trafficData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};