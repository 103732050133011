import "core-js/modules/es.array.reduce.js";
import { MAP_KEYS } from './config';
export function generateTableItem(months = 12) {
  return Array.from({
    length: months
  }).map((_, idx) => {
    const orderNumber = idx + 1 + '';
    const val = orderNumber.padStart(2, '0');
    return {
      attrs: {
        prop: val,
        label: `${val}月`
      },
      children: [{
        attrs: {
          prop: '',
          label: '应收团款'
        },
        children: [{
          attrs: {
            prop: val + MAP_KEYS.totalFee,
            label: '应收'
          }
        }, {
          attrs: {
            prop: val + MAP_KEYS.totalCollect,
            label: '本月已收'
          }
        }, {
          attrs: {
            prop: val + MAP_KEYS.totalIncomed,
            label: '跨月已收'
          }
        }, {
          attrs: {
            prop: val + MAP_KEYS.unTotalFee,
            label: '未收'
          }
        }]
      }, {
        attrs: {
          prop: '',
          label: '应付返款'
        },
        children: [{
          attrs: {
            prop: val + MAP_KEYS.totalSubsidy,
            label: '应付'
          }
        }, {
          attrs: {
            prop: val + MAP_KEYS.totalSubsidyed,
            label: '跨月已付'
          }
        }, {
          attrs: {
            prop: val + MAP_KEYS.unTotalSubsidy,
            label: '未付'
          }
        }]
      }]
    };
  });
}
export const generageStatisticsKeys = () => {
  return Array.from({
    length: 12
  }).reduce((acc, cur, index) => {
    const num = `${index + 1}`;
    const val = num.padStart(2, '0');
    const columnKeys = Object.keys(MAP_KEYS).map(key => {
      return val + key;
    });
    acc = [...acc, ...columnKeys];
    return acc;
  }, []);
};
export const formatRowProperty = data => {
  return data.reduce((acc, cur) => {
    const {
      month
    } = cur;
    const str = month.split('-')[1];
    Object.keys(MAP_KEYS).forEach(key => {
      acc[str + key] = cur[key];
    });

    // acc[str + MAP_KEYS.totalFee] = totalFee
    // acc[str + MAP_KEYS.totalSubsidy] = totalSubsidy
    return acc;
  }, {});
};