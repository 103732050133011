export default {
  name: 'DialogLog',
  data() {
    return {
      visible: false,
      list: []
    };
  },
  methods: {
    show(list) {
      this.visible = true;
      this.list = list;
    },
    hide() {
      this.visible = false;
    }
  }
};