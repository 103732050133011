var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.row.shuttles ? _c("div", _vm._l(_vm.row.shuttles, function (item) {
    return _c("div", {
      key: item.id
    }, [_vm._v(" " + _vm._s(_vm.getShuttleType(item.shuttleType)) + " " + _vm._s(item.shiftNumber) + " " + _vm._s(item.shiftDateTime) + " " + _vm._s(_vm.getTaskType(item.taskType)) + " ")]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };