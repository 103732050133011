import { getUserData } from '@/utils/cookie';
import { getStorage, setStorage } from '@/utils/storage';
import { isMobile } from '@/utils';
const PRINT_TPL = 'PRINT_TPL';
export default {
  name: 'PrintTemplate',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      curIndex: 0,
      defTpl: ''
    };
  },
  computed: {
    isMobile() {
      return isMobile();
    }
  },
  mounted() {
    // this.getDefault()
  },
  methods: {
    change(tpl, idx) {
      this.curIndex = idx;
      this.$emit('tpl-change', tpl);
    },
    changeTplAndSet(tpl, idx) {
      this.change(tpl, idx);
      this.setDefault(tpl);
    },
    getDefault() {
      const userInfo = getUserData();
      const {
        roleId,
        travelAgencyId
      } = userInfo;
      const key = `${PRINT_TPL}-${roleId}-${travelAgencyId}`;
      const tpl = getStorage(key);
      if (tpl) {
        const index = this.list.findIndex(it => it == tpl);
        this.defTpl = tpl;
        this.change(tpl, index > 0 ? index : 0);
      } else {
        this.change('', 0);
      }
    },
    setDefault(tpl) {
      const userInfo = getUserData();
      const {
        roleId,
        travelAgencyId
      } = userInfo;
      const key = `${PRINT_TPL}-${roleId}-${travelAgencyId}`;
      this.defTpl = tpl;
      setStorage(key, tpl);
      this.$bus.tip({
        message: '设置成功'
      });
    }
  }
};