var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col"
    });
  }), 0), _c("tbody", _vm._l(_vm.columns, function (item, index) {
    return _c("tr", {
      key: index
    }, [_c("th", {
      staticStyle: {
        width: "92px"
      }
    }, [_vm._v(" " + _vm._s(item.attrs.label) + " ")]), item.slot ? _c("td", {
      class: item.label ? "item-label" : "item-content",
      attrs: {
        colspan: 11
      }
    }, [item.slot == "tax" ? [_vm.hasEmail ? _c("p", [_vm._v("开票邮箱：" + _vm._s(_vm.getEmail))]) : _vm._e(), !_vm.hasEmail && _vm.hasBtn ? _c("el-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: _vm.genEmail
      }
    }, [_vm._v(" 生成邮箱 ")]) : _vm._e(), _c("SplitText", {
      attrs: {
        text: _vm.data[item.attrs.prop]
      }
    })] : _vm._e()], 2) : _c("td", {
      class: item.label ? "item-label" : "item-content",
      attrs: {
        colspan: 11
      }
    }, [[_c("SplitText", {
      attrs: {
        text: _vm.data[item.attrs.prop]
      }
    })]], 2)]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };