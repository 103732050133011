import "core-js/modules/es.array.reduce.js";
import { ticketItem } from './config';
export function beforeCommit(data, isEmpty = false) {
  const {
    id,
    planId,
    planNumber,
    startTripDate,
    endTripDate,
    shipDate,
    accompanyCount,
    adultCount,
    childCount
  } = data;
  let {
    tickets
  } = data;
  // 过滤没有
  if (isEmpty) {
    tickets = tickets.filter(it => {
      return it.downCount != '' || it.upCount != '' || it.vipCount != '';
    });
  }
  const opts = {
    id,
    accompanyCount,
    adultCount,
    childCount,
    planNumber,
    startTripDate,
    endTripDate,
    planId,
    shipTicket: {
      shipDate,
      tickets
    }
  };
  return opts;
}
export const boatTypeMap = ['三星船', '四星船', '五星船', '兴坪船'];

// 默认船票类型
export const boatTickets = () => {
  return boatTypeMap.map(shipType => {
    return {
      ...ticketItem(),
      shipType
    };
  });
};
export const getShipInfo = item => {
  if (!item) return '';
  const {
    shipDate,
    tickets
  } = item;
  if (tickets.length) {
    const labelMap = {
      downCount: '下舱',
      upCount: '上舱',
      vipCount: 'VIP舱',
      shipType: '类型',
      remarks: '备注'
    };
    const keys = ['shipType', 'downCount', 'upCount', 'vipCount', 'remarks'];
    return tickets.reduce((acc, cur) => {
      const value = keys.reduce((prev, key) => {
        const val = cur[key] || '';
        const label = labelMap[key];
        return prev += val ? `${label}: ${val} ` : '';
      }, '');
      const isEmpty = keys.slice(1).every(key => !cur[key]);
      if (!isEmpty) {
        return [...acc, {
          value,
          shipDate
        }];
      }
      return acc;
    }, []);
  }
  return '';
};