import "core-js/modules/es.array.reduce.js";
import { getPlanListByResIdRequest } from '@/api/plan';
import { ResourceOrder } from '../tableConfig';
import { OrderStatusTransform } from '../config';
import { PersonCount } from '@/components/common';
import { OrderResPlanEnum } from '@/config/order/orderEnum';
import { DialogShipInfo, DialogHotelInfo, DialogBusInfo } from '.';
export default {
  components: {
    PersonCount,
    DialogShipInfo,
    DialogHotelInfo,
    DialogBusInfo
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ResourceOrder,
      orderData: [],
      visible: false
    };
  },
  computed: {
    getExpandClass() {
      return (row, type) => {
        return row[type] ? `${row[type]}-color` : '';
      };
    },
    getRoomCount() {
      return list => {
        return list.reduce((acc, cur) => (acc, acc + cur.count), 0);
      };
    },
    getHotelInfo() {
      return item => {
        return `${item.hotelName || this.data.hotel} `;
        // return `名称：${item.hotelName} 区域：${item.area} 类型：${item.type} 标准：${item.standard} 天数：${item.days} 明细：${item.price}*${item.count}=${item.money}元`
      };
    },
    getShipInfo() {
      return list => {
        return list && list.length && list[0].scenicName;
        // return `${item.shipCompany} ${item.shipType} ${item.ticketType} ${item.scenicName} ${item.money}元`
      };
    },
    getBusInfo() {
      return item => {
        return `${item.busName}`;
        // return `${item.busName} ${item.type} ${item.licenceNumber} ${item.money}元  ${item.remarks} `
      };
    }
  },
  methods: {
    showShipInfo(data) {
      this.$refs.shipRef.show(data);
    },
    showBusInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      // const status = row.busStatus
      // if (this.showInfoDialog(status)) return
      this.$refs.busRef.show({
        planNumber,
        travelAgencyId,
        shuttleType: 'BUS'
      });
    },
    showHotelInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      // const status = row.hotelStatus
      // if (this.showInfoDialog(status)) return

      this.$refs.hotelRef.show({
        planNumber,
        travelAgencyId
      });
    },
    showOrderData() {
      getPlanListByResIdRequest({
        planResBookingId: this.id
      }).then(res => {
        if (!res || !res.data) return;
        this.orderData = res.data;
      });
    },
    setColorForStatus(row, column) {
      const keys = ['hotelOrder', 'busCompanyOrder', 'scenic', 'restaurant', 'shipOrder'];
      if (row.bookingStatus == OrderResPlanEnum.CANCELED) {
        return 'CANCEL-color';
      } else {
        const key = keys.find(key => column.property == key);
        const status = row[key + 'Status'];
        return OrderStatusTransform[status] ? `${OrderStatusTransform[status]}-color` : '';
      }
    },
    tableCellClass({
      row,
      column
    }) {
      return this.setColorForStatus({
        ...this.data,
        ...row
      }, column);
    }
  }
};