import "core-js/modules/es.array.push.js";
import { SelectCustom, SelectStaff } from '@/components/common';
import formTableList from './formTableList';
import { KPIType } from '@/config/system';
import { mapGetters } from 'vuex';
import { parseTime } from '@/utils';
import { formMixin } from '@/mixin';
const year = parseTime(new Date(), '{y}');
const pinyin = require('pinyin');
export default {
  name: 'CustomDialog',
  components: {
    SelectCustom,
    SelectStaff,
    formTableList
  },
  mixins: [formMixin],
  data() {
    return {
      tableData: [],
      form: {},
      columnTab: [],
      turnoverData: [],
      visible: false,
      isUpdate: false,
      monthList: [{
        label: '1月',
        value: '1',
        key: 'jan'
      }, {
        label: '2月',
        value: '2',
        key: 'feb'
      }, {
        label: '3月',
        value: '3',
        key: 'mar'
      }, {
        label: '4月',
        value: '4',
        key: 'apr'
      }, {
        label: '5月',
        value: '5',
        key: 'may'
      }, {
        label: '6月',
        value: '6',
        key: 'jun'
      }, {
        label: '7月',
        value: '7',
        key: 'jul'
      }, {
        label: '8月',
        value: '8',
        key: 'aug'
      }, {
        label: '9月',
        value: '9',
        key: 'sep'
      }, {
        label: '10月',
        value: '10',
        key: 'oct'
      }, {
        label: '11月',
        value: '11',
        key: 'nov'
      }, {
        label: '12月',
        value: '12',
        key: 'dece'
      }],
      validateForm: {
        year: [{
          required: true,
          message: '年份不能为空'
        }],
        customerId: [{
          required: true,
          message: '客户不能为空'
        }],
        month: [{
          required: true,
          message: '月份不能为空'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      customApi: 'system/index/customApi'
    }),
    getH3() {
      return row => {
        if (row.kpitype == KPIType.turnover) {
          return '人均营业额';
        }
        if (row.kpitype == KPIType.profit) {
          return '人均毛利';
        }
        if (row.kpitype == KPIType.numberOfPeople) {
          return '人数';
        }
      };
    }
  },
  // mounted() {
  //   this.fetchDetail(this.form)
  // },

  methods: {
    show() {
      this.visible = true;
      this.columnTab = [];
      this.form = {
        month: 1,
        year
      };

      // 清空缓存数据
      this.resetForm();
    },
    edit(data) {
      const {
        year,
        customerName,
        customerId
      } = data;
      this.form = {
        year: year.toString(),
        customerName,
        customerId,
        month: 1
      };
      this.visible = true;
      this.fetchDetail(this.form);
    },
    processForm(data) {
      const {
        groupTypes,
        outOPUserId,
        outOPUserName,
        saleUserId,
        saleUserName
      } = data;
      const {
        productTypes
      } = groupTypes[0];
      const {
        salesmanId,
        salesmanName
      } = productTypes && productTypes[0];
      this.form = {
        salesmanId,
        salesmanName,
        ...this.form,
        outOPUserId,
        outOPUserName,
        saleUserId,
        saleUserName
      };
      // this.form.salesmanId = this.tableData[0].groupTypes[0].productTypes[0].salesmanId
      // this.form.salesmanName = this.tableData[0].groupTypes[0].productTypes[0].salesmanName
    },
    fetchDetail(opts) {
      if (!this.handleValidate()) return;
      this.$store.dispatch('system/index/fetchCustomerYearKpiEdit', opts).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data);
        this.processForm(data[0]);

        // 表头
        this.$nextTick(() => {
          this.columnTab = this.columnTab.map(it => {
            const prop = this.pinyinFormat(it.productType);
            it.attrs = {
              prop,
              label: it.productType
            };
            it.slot = prop;
            return it;
          });
          const item = {
            attrs: {
              prop: 'groupType',
              label: ''
            }
          };
          this.columnTab = [item, ...this.columnTab];
        });
      });
    },
    selectOPUserName(val) {
      const {
        value,
        label
      } = val;
      this.form.salesmanId = value;
      this.form.salesmanName = label;
      this.resetForm();
      this.beforeCommit(this.tableData);
    },
    beforeCommit(list) {
      const {
        year,
        month,
        customerId,
        customerName,
        salesmanId
      } = this.form;
      const {
        key
      } = this.monthList.find(it => it.value == month);
      const rules = [];
      list.forEach(it => {
        it.groupTypes.forEach(v => {
          const {
            kpitype,
            groupType,
            rowId
          } = v;
          v.productTypes.forEach(z => {
            const {
              kpi,
              productType
            } = z;
            const editId = `${year}-${month}-${rowId}_${productType}`;
            let item = {
              year,
              month,
              customerId,
              customerName,
              salesmanId,
              kpitype,
              groupType,
              productType,
              editId
            };
            item[key] = kpi;
            rules.push(item);
            this.$store.commit('system/index/API_CUSTOM', item);
          });
        });
      });
    },
    // 保存数据到缓存
    saveData(data) {
      const {
        kpitype,
        groupType,
        productType,
        val,
        rowId
      } = data;
      const {
        year,
        month,
        customerId,
        customerName,
        salesmanId
      } = this.form;
      const editId = `${year}-${month}-${rowId}`;
      const opts = {
        year,
        customerId,
        customerName,
        kpitype,
        groupType,
        productType,
        editId,
        salesmanId
      };
      const {
        key
      } = this.monthList.find(it => it.value == month);
      opts[key] = val;
      this.$store.commit('system/index/API_CUSTOM', opts);
    },
    // 转化拼音
    pinyinFormat(name) {
      const py = pinyin(name, {
        style: pinyin.STYLE_NORMAL,
        heteronym: false
      }).map(item => {
        return item[0];
      }).join('');
      return py;
    },
    // 格式化数据
    formatData(list) {
      if (!list.length) return [];
      this.columnTab = list[0].groupTypes[0].productTypes;
      return list.map((item, index) => {
        item.groupTypes.forEach((it, i) => {
          it.productTypes.forEach(v => {
            const key = this.pinyinFormat(v.productType);
            it[`${key}`] = v.kpi;
          });
          it.kpitype = item.kpitype;
          it.rowId = `${index}_${i}`;
        });
        return item;
      });
    },
    handleCustom(row) {
      const {
        label,
        value
      } = row;
      this.form.customerName = label;
      this.form.customerId = value;
      if (value) {
        this.fetchDetail(this.form);
        // 清空缓存数据
        this.resetForm();
      }
    },
    onMonthRow(row) {
      const {
        value
      } = row;
      this.form.month = value;
      this.fetchDetail(this.form);
      if (this.customApi.length) {
        this.handleCreate();
      }
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
      this.resetForm();
    },
    resetForm() {
      this.$store.commit('system/index/CLEAR_API_CUSTOM');
    },
    handleCreate() {
      if (!this.customApi.length) {
        this.$bus.tip({
          message: '没有修改数据！',
          type: 'warning'
        });
        return false;
      }
      this.$store.dispatch('system/index/saveCustomerKpi', this.customApi).then(() => {
        // this.hide()
        this.$bus.tip();
        this.resetForm();
        this.$parent.onSearch();
      });
    }
  }
};