import "core-js/modules/es.array.reduce.js";
/**
 * @module components/base/Table
 * @desc table组件
 * @vue-prop {Object} columnData - 每一列的标题与参数
 */
import variables from '@/styles/variables.scss';
import { getStyle, addClass, removeClass, hasClass, throttle } from '@/utils';
import textToImage from '@/utils/txt2img';
import { DynamicColumn, TableScrollBar } from '@/components/common';
const PADDING_WIDTH = 20;
const BAR_WIDTH = 12;
export default {
  name: 'TableBase',
  components: {
    DynamicColumn,
    TableScrollBar
  },
  inheritAttrs: false,
  inject: ['reload'],
  props: {
    columnData: {
      type: Array,
      required: true
    },
    highlight: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'center'
    },
    headerStyle: {
      type: Object,
      default() {
        return {
          background: '#f9f9f9'
        };
      }
    },
    showEmpty: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    },
    dynamic: {
      type: Boolean,
      default: true
    },
    showPage: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    pageProp: {
      type: Object,
      default: () => {}
    },
    fixBar: {
      type: Boolean,
      default: false
    },
    fixTop: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    sumKeys: {
      type: Array,
      default: () => []
    },
    summaryMethod: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      isRouterAlive: true,
      headHeight: 40,
      tableHead: null,
      tableBody: null,
      tableFoot: null,
      tableFixRight: null,
      tableFixRightHead: null,
      // 固定列表头
      fixHeight: null,
      diffHeight: 0,
      isFirst: true,
      isEntered: false,
      tableElms: null,
      tableScrolls: null,
      scrollXVisible: false,
      currentScrollLeftPos: 0,
      scrollDir: 'down',
      tableBodyTop: 0,
      isHeadFix: false,
      visible: false,
      refreshTable: true,
      checkedColumns: [],
      columnCopy: this.columnData,
      currentIndex: '',
      currentColumnIndex: '',
      fixTableHeight: 500,
      tableStyle: {}
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    },
    sidebar() {
      return this.$store.state.app.sidebar.opened;
    },
    getLeftPosition() {
      return this.sidebar ? 191 : 74;
    },
    getFixHead() {
      return this.$store.state.settings.fixedHeader;
    },
    getFixTagsView() {
      return this.$store.state.settings.tagsView;
    },
    getUserData() {
      return this.$store.state.user.userData;
    },
    getFixHeadHeight() {
      return 0;
      // this.getFixHead ? 50 : 0
    },
    getFixTagsViewHeight() {
      return this.getFixTagsView ? 43 : 0;
    },
    getTable() {
      return this.$refs.tableRef;
    },
    getData() {
      return this.$attrs.data;
    },
    variables() {
      return variables;
    },
    isToTop() {
      return this.$store.state.app.backToTop;
    },
    getFixHeight() {
      return this.fixTop ? this.fixTableHeight : null;
    },
    getPageProp() {
      return {
        total: this.total,
        ...this.pageProp
      };
    },
    getColumns() {
      return this.columnCopy;
    },
    // 表格边框配置
    getTableStyle() {
      return {
        ...this.$attrs,
        ...this.tableStyle
      };
    }
  },
  watch: {
    getData: {
      handler: function handlerGetData(val) {
        if (!val || this.isMobile || !this.isFirst) return;
        this.$nextTick(() => {
          this.isFirst = false;
          this.getElement();
          this.handleBodyScroll();
          this.handleScroll();
          this.toggleRightFix();
          this.setMaskImage();
          this.setTableHeight();
          this.handleSticky();
          setTimeout(() => {
            this.isFirst = true;
            this.fixBar && this.$refs.tableScrollRef.init();
          }, 100);
        });
      },
      immediate: true
    },
    getLeftPosition() {
      this.calcDiffWidth();
    },
    isToTop: {
      handler: function handler() {
        this.resetFixHead();
        this.$store.commit('app/SET_BACK_TO_TOP', false);
      }
    },
    fixTop: {
      handler: 'setTableHeight',
      immediate: true
    }
  },
  mounted() {
    this.init();
    window.onmousewheel = document.onmousewheel = this.scrollFunc;
    window.addEventListener('scroll', throttle(() => {
      this.handleScroll();
    }, 500), false);
    window.addEventListener('resize', throttle(() => {
      this.tableHead ? this.tableHead.style.width = '100%' : null;
      this.setTableHeight();
      this.handleScroll();
    }, 500), false);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, false);
    window.removeEventListener('resize', () => {
      this.calcDiffHeight();
      this.setTableHeight();
    }, false);
  },
  methods: {
    getSummaries(param) {
      if (this.summaryMethod) return this.summaryMethod(param);
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (this.sumKeys.includes(column.property) && !values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    },
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    doLayout() {
      this.$refs['tableRef'].doLayout();
    },
    reloadColumn({
      columns,
      style
    }) {
      this.columnCopy = columns;
      this.tableStyle = style;
      this.$refs['tableRef'].doLayout();
      this.$bus.emit('column:reset');
    },
    setMaskImage() {
      this.$nextTick(() => {
        const bgImg = textToImage(`${this.getUserData.realName} ${this.getUserData.userName}`);
        const tables = document.querySelectorAll('.el-table__body-wrapper');
        tables.forEach(el => {
          let table = el.children[0];
          if (!(table && table.rows && table.rows.length)) return;
          table.style.background = `url(${bgImg}) repeat 20% center`;
          table.style.zIndex = 1;
          el.appendChild(table);
        });
      });
    },
    init() {
      this.setMaskImage();
    },
    // 滚轮方向
    scrollFunc(e) {
      if (e.wheelDelta < 0) {
        // 向下滚动
        this.scrollDir = 'up';
      } else {
        this.scrollDir = 'down';
      }
    },
    handleScroll() {
      this.handleSticky();
    },
    // 获取表格数量
    getElement() {
      const tableElms = document.querySelectorAll('.el-table');
      this.tableElms = tableElms;
      const tableScrolls = document.querySelectorAll('.el-table__body-wrapper');
      this.tableScrolls = tableScrolls;

      // 第一个表格
      const firstTable = tableElms[0];
      if (!firstTable) return;
      // 表头
      const tableHead = firstTable.querySelector('.el-table__header-wrapper');
      this.tableHead = tableHead;

      // 表格主体
      const tableBody = firstTable.querySelector('.el-table__body-wrapper');
      this.tableBody = tableBody;
      const tableFooter = document.querySelector('.el-table__footer-wrapper');
      this.tableFoot = tableFooter;

      // 右侧固定列
      const tableFixRight = firstTable.querySelector('.el-table__fixed-right');
      this.tableFixRight = tableFixRight;
      const {
        height
      } = tableHead.getBoundingClientRect();
      const {
        top
      } = tableBody.getBoundingClientRect();
      this.headHeight = height;
      this.tableBodyTop = this.tableBodyTop == 0 ? top : this.tableBodyTop;
      this.resetFixHead();
      this.calcDiffWidth();
      this.calcDiffHeight();
      this.setRightHeadFix();
      this.fixHeaderPosition(20);
    },
    resetFixHead() {
      this.tableHead.classList.remove('table-head-fix');
      this.tableHead.style.top = 0;
    },
    setTableHeight() {
      const {
        tableWrapRef
      } = this.$refs;
      if (!tableWrapRef) return;
      const topHeight = parseInt(variables.topNavHeight);
      const vh = document.documentElement.clientHeight || document.body.clientHeight;
      const tableHeight = vh - (tableWrapRef.offsetTop + topHeight);
      this.fixTableHeight = tableHeight;
    },
    calcDiffWidth() {
      const clientWidth = document.body.clientWidth;
      if (!this.tableHead) return;
      const w = clientWidth - (this.getLeftPosition + 20) + 'px'; // 20是右边距
      this.tableHead.style.width = w;
    },
    // 计算表头距离顶部的高度
    calcDiffHeight() {
      const fixbarHeight = this.getFixHeadHeight + this.getFixTagsViewHeight;
      const topHeight = parseInt(variables.topNavHeight);
      this.fixHeight = topHeight + fixbarHeight;
      this.diffHeight = this.fixHeight;
    },
    getTableTd(obj) {
      if (!obj) return;
      const table = obj.getElementsByTagName('table')[0];
      const lastTr = table.rows[0];
      return lastTr.cells;
    },
    // 获取最后一个元素
    getLastElement(obj) {
      if (!obj) return;
      const tds = this.getTableTd(obj);
      return tds[tds.length - 2];
    },
    // 设置右侧固定列滚动表头显示
    setRightHeadFix() {
      const lastTd = this.getLastElement(this.tableHead);
      lastTd && lastTd.classList.remove('is-hidden');
    },
    setHeadLastElementPos(pos) {
      const lastTd = this.getLastElement(this.tableHead);
      lastTd ? lastTd.style.right = -pos + 'px' : null;
    },
    // 表头吸顶切换
    toggleHeaderFix(isFix) {
      const tableHead = this.tableHead;
      if (!tableHead) return;
      if (isFix) {
        tableHead.classList.add('table-head-fix');
        tableHead.style.top = this.fixHeight + 'px';
      } else {
        if (this.scrollDir !== 'down') return;
        tableHead.classList.remove('table-head-fix');
      }
    },
    // 右侧固定列
    toggleRightFix(isFix) {
      if (!this.tableFixRight) return;
      if (isFix) {
        this.tableFixRight.style.top = `-${this.tableHead.clientHeight}px`;
        this.tableFixRight.style.zIndex = 10;
        this.tableFixRight.style.height = parseInt(this.tableFixRight.style.height) + this.headHeight + 'px';
      } else {
        this.tableFixRight.style.top = 0;
        const tableHead1 = this.tableFixRight.querySelector('.table-head-bg');
        const last1 = tableHead1.cells[tableHead1.cells.length - 1];
        const tableHead = this.tableHead.querySelector('.table-head-bg');
        const last = tableHead.cells[tableHead.cells.length - 2];
        if (!hasClass(last1, 'is-hidden')) {
          addClass(last1, 'is-hidden');
        }
        if (hasClass(last, 'is-hidden')) {
          removeClass(last, 'is-hidden');
        }
      }
    },
    // 表头吸顶，固定列表头处理
    fixHeaderPosition(pos, isFix = this.isHeadFix, dir = 'vertical') {
      const lastTd = this.getLastElement(this.tableHead);

      // 没有最后元素直接返回
      if (!lastTd) return;
      const tableRight = this.tableFixRight;
      if (!tableRight) return;

      // const position = getStyle(lastTd, 'position')
      // if (position == 'fixed') return

      let width = getStyle(tableRight, 'width');
      const tableHead = this.tableHead;
      const height = tableHead.offsetHeight;
      // let right = isFix ? pos : 0
      const right = dir != 'vertical' ? 20 : isFix ? 20 : 0;
      lastTd.style.cssText = `width: ${width ? width : 'auto'}; height: ${height}px; line-height: ${height - BAR_WIDTH}px; position: ${isFix ? 'fixed' : 'absolute'}; right: ${right}px; top: ${isFix ? this.fixHeight + 'px' : 'auto'}; align-items: center; z-index: 100`;
    },
    // 表格内容滚动
    handleBodyScroll() {
      // 不需要表格固定顶部
      if (!this.sticky) return;
      // 固定表格高度
      if (this.fixTop) return;
      const tableScrolls = this.tableScrolls;
      if (!tableScrolls || tableScrolls.length < 2) {
        return;
      }
      const len = tableScrolls.length;
      const tableTotal = tableScrolls[len - 1];
      const tableParent = tableTotal.offsetParent;
      const tableTitleWidth = document.querySelector('.el-table__header').offsetWidth;

      // 判断是否隐藏横向滚动条
      this.scrollXVisible = tableParent && hasClass(tableParent, 'table-total') && tableTotal.offsetWidth < tableTitleWidth;
      const tableBodyMain = tableScrolls[len - 2];
      const tableHeader = this.tableHead;
      const tableFooter = this.tableFoot;
      const tableTotalInner = tableTotal.getElementsByTagName('table')[0];
      const tableInner = tableBodyMain.getElementsByTagName('table')[0];
      let dragTable = false;
      let dragTotal = false;
      let scrollTimer;
      tableBodyMain.addEventListener('scroll', () => {
        if (dragTotal) return;
        const left = tableInner.getBoundingClientRect().x;
        let position = -left + this.getLeftPosition;
        position = position < 0 ? 0 : position;
        tableTotal.scrollLeft = position;
        tableHeader ? tableHeader.scrollLeft = position : null;
        tableFooter ? tableFooter.scrollLeft = position : null;
        this.fixHeaderPosition(position, this.isHeadFix, 'horization');
        dragTable = true;
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => {
          dragTable = false;
        }, 100);
      }, false);
      tableTotal.addEventListener('scroll', () => {
        if (dragTable) return;
        const left = tableTotalInner.getBoundingClientRect().x;
        let position = -left + this.getLeftPosition;
        position = position < 0 ? 0 : position;
        tableBodyMain.scrollLeft = position;
        tableHeader ? tableHeader.scrollLeft = position : null;
        tableFooter ? tableFooter.scrollLeft = position : null;
        this.fixHeaderPosition(position, this.isHeadFix, 'horization');
        dragTotal = true;
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => {
          dragTotal = false;
        }, 100);
      }, false);
    },
    // 处理表头吸顶
    handleSticky() {
      // 不吸顶，也不需要标题滚动
      if (!this.sticky || this.fixTop) return;
      let st = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      const isFix = parseInt(st) >= parseInt(this.diffHeight);
      this.isHeadFix = isFix;
      isFix && this.setRightHeadFix();
      this.fixHeaderPosition(PADDING_WIDTH, isFix);
      this.toggleHeaderFix(isFix);
      this.toggleRightFix(isFix);
    }
  }
};