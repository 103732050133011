import "core-js/modules/es.array.push.js";
import TableEdit from './TableEdit';
import { systemOrderAuditEdit } from '@/config/system';
import { getSelectLabel } from '@/config/select';
import { findComponentsUpward } from '@/utils';
const defaultState = () => ({
  progress: 1,
  reviewerId: '',
  reviewerName: '',
  reviewerType: 'ROLE',
  reviewerLabel: '角色'
});
export default {
  name: 'FormAudit',
  components: {
    TableEdit
  },
  data() {
    return {
      loading: false,
      columnData: systemOrderAuditEdit,
      visible: false,
      tableData: [],
      dialogTitle: '审核流程修改',
      auditId: -1 // 审核流程id
    };
  },
  methods: {
    show(data, title) {
      const {
        id,
        progresses
      } = data;
      this.auditId = id;
      this.dialogTitle = title + '修改';
      if (progresses.length) {
        this.tableData = this.formatData(data.progresses);
      }
      this.visible = true;
    },
    formatData(list) {
      return list.map(it => {
        it.reviewerLabel = getSelectLabel('reviewerList', it.reviewerType);
        return it;
      });
    },
    hide() {
      this.visible = false;
      this.resetForm();
    },
    deleteTableRow(val) {
      const {
        id,
        progress
      } = val;
      if (!id) {
        this.tableData.splice(progress - 1, 1);
        this.tableData = this.tableData.map((it, index) => {
          it.progress = index + 1;
          return it;
        });
        return false;
      }
      this.$bus.open(() => {
        this.$store.dispatch('system/index/detOrAuditList', {
          id
        }).then(() => {
          this.$bus.tip();
          const findComp = findComponentsUpward(this, 'OrderAudit')[0];
          findComp.hide();
          this.hide();
          this.fetchAuditList();
        });
      }, '是否确定删除?');
    },
    // 创建
    handleCreateOrUpdate() {
      if (!this.validateForm()) return;
      this.$store.dispatch('system/index/saveOrAuditList', this.tableData).then(() => {
        // 获取所有审核类型
        this.fetchAuditList();
        this.$bus.tip();
        const findComp = findComponentsUpward(this, 'OrderAudit')[0];
        findComp.hide();
      });
      this.hide();
    },
    // 获取所有审核类型
    fetchAuditList() {
      this.$store.dispatch('system/index/fetchAuditList', {});
    },
    // 添加
    handleAdd() {
      const progress = this.tableData.length + 1;
      this.tableData.push({
        ...defaultState,
        progress,
        auditId: this.auditId
      });
    },
    handleCancel() {
      this.hide();
    },
    resetForm() {
      this.form = defaultState();
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (!row.reviewerType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择角色/账户类型!'
          });
          return false;
        }
        if (!row.reviewerId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择审批角色/账户!'
          });
          return false;
        }
      }
      return true;
    }
  }
};