import "core-js/modules/es.array.push.js";
import ReceptionFeeTable from './ReceptionFeeTable';
import ReceptionProxyTable from './ReceptionProxyTable';
import ReceptionRefundTable from './ReceptionRefundTable';
import ReceptionItinerariesTable from './ReceptionItinerariesTable';
import ReceptionRemarkTable from './ReceptionRemarkTable';
import { TYPES } from '@/config/audit';
import { mapGetters } from 'vuex';
import { auditMixin, typeMixin } from '@/mixin';
export default {
  components: {
    ReceptionFeeTable,
    ReceptionProxyTable,
    ReceptionRefundTable,
    ReceptionItinerariesTable,
    ReceptionRemarkTable
  },
  mixins: [auditMixin, typeMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isAudit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      activeName: 'fee',
      tabIndex: 0,
      feeList: [],
      collectList: [],
      subsidyList: [],
      remarks: [],
      productItineraries: []
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      receptionList: 'order/reception/receptionList'
    })
  },
  watch: {
    receptionList: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data || !data.length) return;
      this.feeList = [];
      this.collectList = [];
      this.subsidyList = [];
      this.productItineraries = [];
      this.remarks = [];
      data.forEach((it, index) => {
        const feeArr = this.formatData(it, 'feeList');
        this.feeList = [...this.feeList, ...this.getTotalMoney(feeArr, index)];
        const collectArr = this.formatData(it, 'collectList');
        this.collectList = [...this.collectList, ...this.getTotalMoney(collectArr, index)];
        const subsidyArr = this.formatData(it, 'subsidyList');
        this.subsidyList = [...this.subsidyList, ...this.getTotalMoney(subsidyArr, index)];
        this.productItineraries = it.productItineraries;
        this.remarks.push({
          customerName: it.customerName,
          remarks: it.remarks
        });
      });
    },
    getTotalMoney(list, index) {
      if (!list || !list.length) return [];
      let totalMoney = 0;
      list.forEach(it => {
        totalMoney += Number(it.total) * 100;
      });
      return list.map(it => {
        it.totalMoney = totalMoney / 100;
        it.index = index;
        return it;
      });
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
    },
    // 保存及审核
    handleUpdate() {
      return new Promise((resolve, reject) => {
        this.handleAudit(this.feeList, TYPES.out_fee).then(() => {
          this.$parent.fetchData();
          this.$bus.tip();
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    handleAudit(data, type) {
      return new Promise((resolve, reject) => {
        const loading = this.$bus.loading();
        const dataList = this.processData(data, type);
        this.$store.dispatch('audit/index/updateAuditOne', dataList).then(() => {
          loading.close();
          resolve(dataList);
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    processData(dataList, type) {
      const result = [];
      const {
        planId,
        id
      } = this.$route.query;
      dataList.forEach(it => {
        const item = {
          auditProgress: it.ap,
          auditTypeEnum: type,
          status: this.formatStatus(it),
          // 审核状态
          planId: planId || id,
          isCollectMenu: false,
          resourceId: it.rowId // 子项没有rowId 取 id
        };
        result.push(item);
      });
      return result;
    },
    formatData(item, key) {
      const {
        adultCount,
        accompanyCount,
        babyCount,
        childCount,
        contactName,
        customerName
      } = item;
      return item[key] && item[key].map(it => {
        const {
          id,
          auditProgress
        } = it;
        return {
          ...it,
          rowId: id,
          auditProgress,
          ap: auditProgress,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(it.auditProgress),
          adultCount,
          accompanyCount,
          babyCount,
          childCount,
          contactName,
          customerName
        };
      });
    }
  }
};