import "core-js/modules/es.array.push.js";
import { offerRestaurant } from '@/config/offer';
import { TopTitle } from '@/components/common';
import offerMixin from '../offerMixin';
export default {
  components: {
    TopTitle
  },
  mixins: [offerMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: offerRestaurant,
      total: 0
    };
  },
  watch: {
    tableData: {
      handler: function (data) {
        this.total = 0;
        data.forEach(it => {
          this.total += Number(it.cost);
        });
      }
    }
  },
  methods: {
    handlePlus() {
      this.tableData.push({
        price: 0,
        count: 1,
        cost: 0
      });
    }
  }
};