import { tableMixin } from '@/mixin';
import { decimalSplit } from '@/utils/index';
export default {
  components: {},
  mixins: [tableMixin],
  data() {
    return {};
  },
  computed: {
    decimal() {
      return item => {
        return decimalSplit(item);
      };
    },
    calcPerTotal() {
      return item => {
        const per = item.totalBuyMoneyTotal / item.adultCount;
        return decimalSplit(per);
      };
    },
    calcComplete() {
      return item => {
        if (!item.tagPerTotal) {
          return '100%';
        }
        const comp = item.totalBuyMoneyTotal / item.adultCount / item.tagPerTotal * 100;
        return decimalSplit(comp) + '%';
      };
    }
  },
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    tableRowClassName({
      row
    }) {
      if (row.productType == '小计') {
        return 'warning-row';
      }
      return '';
    }
  }
};