import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 签单录入列表
export function fetchTicketPlan(params) {
  return request({
    url: '/order/sign/pagePlan',
    method: 'get',
    params
  });
}

// 签单录入 签单使用状态
export function fetchTicketCount(planId) {
  return request({
    url: '/order/sign/countSign',
    method: 'get',
    params: {
      planId
    }
  });
}

// 获取录入票据列表
export function fetchTicketItems(planId) {
  return request({
    url: '/order/sign/listPlanSignSheet',
    method: 'get',
    params: {
      planId
    }
  });
}

// 更新录入票据列表
export function updateTicketItems(data) {
  return request({
    url: '/order/sign/updateBatchById',
    method: 'post',
    data
  });
}

// 录入票据
export function createTicketItem(params) {
  return request({
    url: '/order/sign/save',
    method: 'post',
    params
  });
}

// 批量录入票据
export function batchCreateTicketItem(data) {
  return request({
    url: '/order/sign/saveBatch',
    method: 'post',
    data
  });
}

// 删除票据
export function deleteTicketItem(id) {
  return request({
    url: '/order/sign/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 删除票据 （批量）
export function deleteTicketIds(data) {
  return request({
    url: '/order/sign/delByIds',
    method: 'post',
    data
  });
}

// 票据转单
export function transferTicket(data) {
  return request({
    url: '/order/sign/transfer',
    method: 'post',
    data
  });
}

// 完成/取消派单
export function dispatchTicket({
  id,
  isDispatch = true
}) {
  return request({
    url: '/order/sign/dispatch',
    method: 'post',
    data: {
      id,
      isDispatch
    }
  });
}

// 完成/取消审核
export function auditTicket({
  id,
  isAudit = true
}) {
  return request({
    url: '/order/sign/audit',
    method: 'post',
    data: {
      id,
      isAudit
    }
  });
}

// 列表
export function fetchTicket(params) {
  // const url = parseQuerys('/order/signSheet/page', queryParams)
  return request({
    url: '/order/signSheet/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateTicket(data) {
  return request({
    url: '/order/signSheet/saveOrUpdate',
    method: 'post',
    data
  });
}

// 签单列表日志
export function fetchLog({
  itemId,
  type,
  subType
}) {
  return request({
    url: '/log/orderlist',
    method: 'get',
    params: {
      itemId,
      type,
      subType
    } // type: 4 签单
  });
}

// 删除
export function deleteTicket(id) {
  return request({
    url: '/order/signSheet/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 查询票据
export function getTicketPage(params) {
  return request({
    url: '/order/sign/page',
    method: 'get',
    params
  });
}

// 设置拖欠签单
export function behindhandSignRequest(data) {
  return request({
    url: '/order/sign/behindhandSign',
    method: 'post',
    data
  });
}