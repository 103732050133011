import { mapGetters } from 'vuex';
import { DICTIONARY } from '@/config/dictionary';
export default {
  computed: {
    ...mapGetters({
      dictionaryData: 'system/dictionary/dictionaryCodeData'
    }),
    // 车辆类型
    getBusType() {
      return id => {
        const item = this.getDictionaryValue(DICTIONARY.busType, id);
        return item ? item.name : '';
      };
    },
    // 站点类型
    getStationType() {
      return id => {
        return this.getStationName(id);
      };
    }
  },
  methods: {
    // 区域类型
    getAreaType(id) {
      return this.getDictionaryValue(DICTIONARY.regionType, id);
      // return item ? item.name : id
    },
    getStationName(id) {
      return this.getDictionaryValue(DICTIONARY.stationType, id);
    },
    getBusUseType(id) {
      return this.getDictionaryValue(DICTIONARY.busUseType, id);
    },
    getInsuranceType(id) {
      return this.getDictionaryValue(DICTIONARY.insuranceType, id);
    },
    getTrafficType(id) {
      return this.getDictionaryValue(DICTIONARY.ticketType, id);
    },
    getRoomBaseType() {
      const roomList = this.dictionaryData[DICTIONARY.roomType].agencyDictionaryList;
      const findItem = roomList.find(it => it.name == '标准间');
      return findItem ? findItem : roomList[0];
    },
    getDictionaryDefVal(type) {
      const teamFundType = this.dictionaryData[type];
      const def = {
        id: '',
        name: ''
      };
      if (teamFundType) {
        return teamFundType.agencyDictionaryList.length ? teamFundType.agencyDictionaryList[0] : def;
      }
      return def;
    },
    getDictionaryValue(type, id) {
      const teamFundType = this.dictionaryData[type];
      if (teamFundType) {
        const item = teamFundType.agencyDictionaryList.find(it => it.id == id);
        return item ? item.name : id;
      }
      return id;
    },
    getCount(data, name) {
      if (!name) return 0;

      // 不知道哪里传了一个对象过来
      if (name.name) {
        name = name.name;
      }
      if (name.indexOf('大人') > -1) {
        return data['adultCount'] || 0;
      }
      if (name.indexOf('小孩') > -1) {
        return data['childCount'] || 0;
      }
      return data['adultCount'] || 0;
    }
  }
};