import { tableMixin } from '@/mixin';
import { SelectRole, SelectStaff } from '@/components/common';
import { findComponentsUpward } from '@/utils';
export default {
  components: {
    SelectRole,
    SelectStaff
  },
  mixins: [tableMixin],
  data() {
    return {};
  },
  methods: {
    changeRole(row, val) {
      row.reviewerType = val.value;
      row.reviewerLabel = val.label;
    },
    changeReviewer(row, val) {
      row.reviewerId = val.value;
      row.reviewerName = val.label;
    },
    handleDelete(row) {
      const findComp = findComponentsUpward(this, 'FormAudit')[0];
      findComp.deleteTableRow(row);
    }
  }
};