import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 代收列表
export const proxyListRequest = params => {
  return request({
    url: '/order/audit/pageCollect',
    method: 'get',
    params
  });
};

// 单条审核
export const proxyAuditRequest = data => {
  return request({
    url: '/order/audit/audit',
    method: 'post',
    data
  });
};

// 审核日志
export function proxyLogRequest(params) {
  return request({
    url: '/log/orderlist',
    method: 'get',
    params
  });
}

// 导出代收审核
export function proxyExportRequest(params) {
  const url = APP.baseUrl + '/order/audit/exportCollectList';
  return requestBlob({
    url,
    params
  });
}