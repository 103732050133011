import { IconRight, IconWrong } from '@/components/common';
// import { isMobile } from '@/utils'

export default {
  components: {
    IconRight,
    IconWrong
  },
  inheritAttrs: false,
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    columnData: {
      type: Array,
      default: () => []
    },
    status: {
      type: [Boolean, Number],
      default: false
    },
    width: {
      type: Number,
      default: 500
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isShow: false,
    timer: null
  }),
  computed: {
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    },
    getStatus() {
      return Boolean(this.status);
    },
    isHotel() {
      return typeof this.status == 'number' && this.status == 2;
    },
    isHotelType() {
      return this.type && this.type == 'hotel';
    },
    isBusType() {
      return this.type && this.type == 'bus';
    },
    triggerType() {
      return this.isMobile ? 'click' : 'hover';
    }
  },
  methods: {
    // onMouseEnter() {
    //   if (this.isMobile) return
    //   clearTimeout(this.timer)
    //   this.isShow = true
    // },
    // onMouseLeave() {
    //   clearTimeout(this.timer)
    //   this.timer = setTimeout(() => {
    //     this.isShow = false
    //   }, 1500)
    // },
  }
};