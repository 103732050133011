import TableAuditList from './TableAuditList';
import FormAudit from './FormAudit';
import { systemOrderAuditProcess } from '@/config/system';
export default {
  name: 'OrderAudit',
  components: {
    TableAuditList,
    FormAudit
  },
  data: () => ({
    loading: false,
    columnData: systemOrderAuditProcess,
    tableData: [],
    title: '',
    visible: false
  }),
  methods: {
    show(data) {
      this.visible = true;
      if (data.audits) {
        this.tableData = this.formatData(data.audits);
        this.title = data.audits[0].name;
      }
    },
    formatData(list) {
      const data = list.map(it => {
        it.reviewerName = it.progresses.map((its, index) => {
          if (index > 0) {
            return `->${its.reviewerName}`;
          }
          return its.reviewerName;
        });
        return it;
      });
      return data;
    },
    hide() {
      this.visible = false;
    },
    fetchData() {
      this.$store.dispatch('system/index/fetchAuditList', {});
    },
    editTableRow(row) {
      this.$refs.formRef.show(row, this.title);
    }
  }
};