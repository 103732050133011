import { inputMixin } from '@/mixin';
/**
 * @module components/base/Input
 * @desc 团款类型 input 组件
 * @vue-data {String} [radio=1] - 输入框的类型 1: 系统生成 1: 用户自定义
 * @vue-data {String} inputValue - 输入框的值
 * @vue-data {String} disableValue - 输入框的状态
 */
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  data() {
    return {
      list: [{
        value: '门票'
      }, {
        value: '餐费'
      }, {
        value: '景区'
      }, {
        value: '车费'
      }]
    };
  }
};