var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "export-area-table"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "summary-method": _vm.getSummaries,
      "hide-bar": true,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "area",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onRedirec(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.area) + " ")])];
      }
    }, {
      key: "profitYearRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimalPCT(scope.row.profitYearRate)) + " ")];
      }
    }, {
      key: "peopleYearRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimalPCT(scope.row.peopleYearRate)) + " ")];
      }
    }, {
      key: "peopleCompletionRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimalPCT(scope.row.peopleCompletionRate)) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };