var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("InputData", _vm._g(_vm._b({
    attrs: {
      list: _vm.list,
      label: _vm.label,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      placeholder: "请选择账户"
    },
    model: {
      value: _vm.curVal,
      callback: function ($$v) {
        _vm.curVal = $$v;
      },
      expression: "curVal"
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };