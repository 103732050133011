var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FormBase", {
    ref: "formRef",
    staticClass: "river-form",
    attrs: {
      rules: _vm.rules,
      model: _vm.form,
      size: "large",
      "label-width": "320px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "所属景区公司",
      prop: "enterpriseId"
    }
  }, [_c("SelectRiver", {
    model: {
      value: _vm.form.enterpriseId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "enterpriseId", $$v);
      },
      expression: "form.enterpriseId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游姓名",
      prop: "guide"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      tip: "请输入导游名字"
    },
    model: {
      value: _vm.form.guide,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guide", $$v);
      },
      expression: "form.guide"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游电话",
      prop: "phone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      tip: "请输入导游电话"
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "乘坐工具",
      prop: ""
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "rideBy",
      placeholder: "请选择乘坐工具"
    },
    on: {
      "on-select": _vm.selectBoat
    },
    model: {
      value: _vm.form.rideBy_dictText,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rideBy_dictText", $$v);
      },
      expression: "form.rideBy_dictText"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "预约时间",
      prop: "appointmentTime"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      type: "datetime",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      placeholder: "请选择预约时间"
    },
    model: {
      value: _vm.form.appointmentTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "appointmentTime", $$v);
      },
      expression: "form.appointmentTime"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "备注",
      prop: ""
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea",
      label: "",
      tip: "备注信息"
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1), _vm.isUpdate ? _c("FormItemBase", {
    attrs: {
      label: "状态",
      prop: ""
    }
  }, [_c("el-tag", {
    attrs: {
      type: "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.guide.statusDictText) + " ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };