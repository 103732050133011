import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { uploadUrl } from '@/config';
import UploadBase from '@/components/Upload';
import { mapGetters } from 'vuex';
import { splitPath, getOssName } from '@/utils';
export default {
  components: {
    UploadBase
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      uploadUrl,
      checkList: [],
      logoUrl: {
        image: '',
        loading: false
      },
      sealBusiness: {
        image: null,
        loading: false,
        name: 'business-seal',
        remarks: '业务章'
      },
      sealAccount: {
        image: '',
        loading: false,
        name: 'account-seal',
        remarks: '财务章'
      },
      sealTrip: {
        image: '',
        loading: false,
        name: 'trip-seal',
        remarks: '电子行程单章'
      },
      objSeal: {
        'trip-seal': 'sealTrip',
        'account-seal': 'sealAccount',
        'business-seal': 'sealBusiness'
      }
    };
  },
  computed: {
    ...mapGetters({
      companyInfo: 'system/companyInfo/companyInfo'
    })
  },
  watch: {
    companyInfo: {
      handler: function (data) {
        if (!data) return;
        const {
          logoUrl
        } = data;
        this.logoUrl.image = logoUrl;
      },
      immediate: true,
      deep: true
    },
    data: {
      handler: function (list) {
        if (!list || !list.length) return;
        list.forEach(it => {
          this[this.objSeal[it.name]].image = it.url;
        });
      },
      immediate: true
    }
  },
  methods: {
    deleteLogoUrl() {
      const url = splitPath(this.companyInfo.logoUrl);
      // 直接从aliyun oss 删除
      this.$store.dispatch('system/companyInfo/deleteImage', url);
      // 从 store 移除
      this.$store.commit('system/companyInfo/CHANGE_COMPANY_INFO', {
        type: 'logoUrl',
        val: ''
      });
    },
    deleteImage(obj) {
      const {
        name
      } = obj;
      const fIndex = this.data.findIndex(it => it.name == name);
      const {
        id,
        imageUrl: url
      } = this.data[fIndex];
      const list = this.data.splice(fIndex, 1);

      // 直接从aliyun oss 删除
      this.$store.dispatch('system/companyInfo/deleteImage', url);
      this.$store.dispatch('system/companyInfo/deleteSealImage', [id]);
      this.$store.commit('system/companyInfo/BUSINESS_SEAL_INFO', list);
    },
    handleAvatarSuccess(res) {
      const {
        url
      } = res.data;
      this.$store.commit('system/companyInfo/CHANGE_COMPANY_INFO', {
        type: 'logoUrl',
        val: url
      });
    },
    handleAvatarSuccess2(obj, {
      res,
      file
    }) {
      const url = URL.createObjectURL(file.raw);
      obj.image = url;
      obj.loading = false;
      let opts = {
        imageUrl: getOssName(res.data.url),
        name: obj.name,
        remarks: obj.remarks
      };
      // opts = { ...opts, ...(this.data || {}) }

      this.$store.dispatch('system/companyInfo/uploadSealImage', opts);
    }
  }
};