import { blackAllListDetail as columns } from '@/config/order';
export default {
  data: () => ({
    columnData: [...columns.slice(0, 4), ...columns.slice(4, -1)],
    tableData: [],
    content: '',
    sendDate: [],
    params: {}
  }),
  methods: {
    changeDate(val) {
      const [start, end] = val;
      this.params.startDate = start;
      this.params.endDate = end;
    },
    handleSearch() {
      if (!(this.params.startDate && this.params.endDate)) {
        this.$bus.tip({
          type: 'warning',
          message: '发团开始日期与结束日期必填'
        });
        return;
      }
      if (!this.content || this.content == '') {
        this.$bus.tip({
          type: 'warning',
          message: '游客名单必填'
        });
        return;
      }
      const list = this.content.replace(/[\n\r]/g, '/@').split('/@');
      const {
        startDate,
        endDate
      } = this.params;
      const dataList = list.map(it => {
        const [name, cardNumber] = it.trim().replace(/\s+/g, '/@').split('/@');
        const items = {
          name,
          cardNumber,
          cardType: 0,
          startTripDate: startDate,
          endTripDate: endDate
        };
        return items;
      });
      this.$store.dispatch('order/index/searchAndSaveUsers', dataList).then(data => {
        if (!data) return;
        this.tableData = data;
      });
    }
  }
};