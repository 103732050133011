var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    staticClass: "plan-list-table",
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: true,
      "show-summary": "",
      selection: ""
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")]), _c("div", [_vm._v(_vm._s(scope.row.productName))])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [scope.row.customerVoList && scope.row.customerVoList.length && scope.row.planType == "T_TOURIST" ? _vm._l(scope.row.customerVoList, function (item, index) {
          return _c("span", {
            key: index
          }, [_c("CustomerInfo", {
            attrs: {
              name: item.name
            }
          }), _c("br")], 1);
        }) : _vm._e()];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " 大 " + _vm._s(scope.row.childCount) + " 小 " + _vm._s(scope.row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "guides",
      fn: function ({
        scope
      }) {
        return [scope.row.guides.length ? [_c("Guides", {
          attrs: {
            row: scope.row
          }
        }), _vm._v(" ["), _c("btn-link", {
          attrs: {
            type: _vm.getGuideBtnType(scope.row.reimbStatus)
          }
        }, [_vm._v(" " + _vm._s(_vm.getGuideStatus(scope.row.reimbStatus)) + " ")]), _vm._v("] ")] : _vm._e()];
      }
    }, {
      key: "fee",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditFeeStatus
          }
        })];
      }
    }, {
      key: "scenic",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditScenicStatus,
            "is-show": scope.row.scenicOrderStatus
          }
        })];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditHotelStatus,
            "is-show": scope.row.hotelOrderStatus
          }
        })];
      }
    }, {
      key: "restaurant",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditRestaurantStatus,
            "is-show": scope.row.restaurantOrderStatus
          }
        })];
      }
    }, {
      key: "bus",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditBusCompanyStatus,
            "is-show": scope.row.busCompanyOrderStatus
          }
        })];
      }
    }, {
      key: "insurance",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditInsuranceStatus,
            "is-show": scope.row.insuranceOrderStatus
          }
        })];
      }
    }, {
      key: "traffic",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditTransportStatus,
            "is-show": scope.row.transportOrderStatus
          }
        })];
      }
    }, {
      key: "pickup",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditShuttleStatus,
            "is-show": scope.row.shuttleStatus
          }
        })];
      }
    }, {
      key: "borrow",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.guideBorrowStatus,
            "is-show": scope.row.guideBorrowApplyStatus
          }
        })];
      }
    }, {
      key: "proxy",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditGuideCollectStatus,
            "is-show": scope.row.collectStatus
          }
        })];
      }
    }, {
      key: "subsidy",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditGuideCollectStatus,
            "is-show": scope.row.collectStatus
          }
        })];
      }
    }, {
      key: "otherIncome",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditOtherIncomeStatus,
            "is-show": scope.row.otherIncomeStatus
          }
        })];
      }
    }, {
      key: "otherPay",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditOtherPayStatus,
            "is-show": scope.row.otherPayStatus
          }
        })];
      }
    }, {
      key: "agency",
      fn: function ({
        scope
      }) {
        return [_c("IconItem", {
          attrs: {
            status: scope.row.auditOutTransferStatus,
            "is-show": scope.row.agencyStatus
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAuditProgress", {
          attrs: {
            row: scope.row,
            "is-name": true
          }
        })];
      }
    }, {
      key: "teamStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusSend", {
          attrs: {
            status: scope.row.sendGroupStatus
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_vm.comparePlanStatus(scope.row) ? [_c("btn-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleAudit(scope.row);
            }
          }
        }, [_vm._v(" 审核 ")]), _c("btn-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope.row);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("btn-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handlePrint(scope.row);
            }
          }
        }, [_vm._v(" 打印 ")])] : _c("el-button", {
          attrs: {
            type: "text",
            disabled: ""
          }
        }, [_vm._v(" 已归档 ")])];
      }
    }, {
      key: "actionHead",
      fn: function ({
        scope
      }) {
        return [_c("btn-link", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleBatchRemoveAudit(scope.row);
            }
          }
        }, [_vm._v(" 批量取消审核 ")]), _c("btn-link", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleBatchAudit(scope.row);
            }
          }
        }, [_vm._v(" 批量审核 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };