import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 保险列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceInsurance/findList',
    method: 'get',
    params
  });
}
// 保险列表 不分页(集团)
export function fetchGroupList(params) {
  return request({
    url: '/resource/resourceInsurance/group-objects',
    method: 'get',
    params
  });
}

// 保险列表
export function fetchInsurance(params) {
  return request({
    url: '/resource/resourceInsurance/page',
    method: 'get',
    params
  });
}

// 保险更新
export function createOrUpdateInsurance(data) {
  return request({
    url: '/resource/resourceInsurance/save',
    method: 'post',
    data
  });
}

// 删除保险
export function deleteInsurance(id) {
  return request({
    url: '/resource/resourceInsurance/del',
    method: 'post',
    data: {
      id
    }
  });
}