/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchVehicle, createOrUpdateVehicle, deleteVehicle } from '@/api/resource/vehicle';
import { deleteFile, getFileUrl } from '@/api/index';
import { TYPES } from '@/config/resource';
import { formatCharData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  vehicleData: null,
  vehicleDataOrigin: null,
  vehicleLog: null
});
const state = dfaultState();
const getters = {
  vehicleData: state => state.vehicleData,
  vehicleDataOrigin: state => state.vehicleDataOrigin,
  vehicleLog: state => state.vehicleLog
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchVehicle(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return getFileUrl(paths).then(res => {
      return res.data[0];
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.bus
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateVehicle(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    return deleteVehicle(id);
  },
  deleteFile({
    commit
  }, files) {
    return deleteFile(files);
  },
  delvehicleData({
    commit
  }) {
    const data = null;
    commit('DELETE_DATA', data);
    return data;
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.vehicleData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.vehicleDataOrigin = data;
  },
  DELETE_DATA: (state, data) => {
    state.vehicleData = data;
  },
  FETCH_LOG: (state, data) => {
    state.vehicleLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.vehicleData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.vehicleData.list.filter(it => it.id !== id);
    state.vehicleData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};