export default {
  props: {
    row: {
      type: Object,
      default: () => {}
    },
    len: {
      type: Number,
      default: 2
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasMember() {
      return this.row.members && this.row.members.length;
    },
    getList() {
      if (this.hasMember) {
        const members = this.row.members;
        const contactList = members.filter(it => it.isContact);
        return contactList.length ? contactList : members.slice(0, this.len);
      }
      return [];
    }
  }
};