export default {
  data() {
    return {
      visible: false,
      placeholder: '请输入修改内容',
      content: ''
    };
  },
  mounted() {
    this.$bus.on('show:notice', this.show);
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.restore();
    },
    handleConfirm() {}
  }
};