var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    staticClass: "plan-list-table",
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")])];
      }
    }, {
      key: "orderType",
      fn: function ({
        scope
      }) {
        return [_c("TypeOrder", {
          attrs: {
            type: scope.row.planType
          }
        })];
      }
    }, {
      key: "dutyOPUserName",
      fn: function ({
        scope
      }) {
        return [scope.row.planType == "T_TOURIST" ? [_vm._v(" " + _vm._s(scope.row.outOPUsers[0].realName) + " ")] : _vm._e()];
      }
    }, {
      key: "guides",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.guides(scope.row.guides)) + " "), scope.row.guides.length ? void 0 : _vm._e()];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "sendGroupStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusSend", {
          attrs: {
            status: scope.row.sendGroupStatus
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAuditProgress", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };