import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import SearchItem from './components/Search';
import DialogBorrow from './components/DialogBorrow';
import DialogApprove from './components/DialogApprove';
import { GuideBorrow as columns, DingTalkStatus } from '@/config/billDown/guideBorrow';
import { PersonCount } from '@/components/common';
import { rowspanMixin, reloadMixin } from '@/mixin';
export default {
  components: {
    SearchItem,
    DialogBorrow,
    DialogApprove,
    PersonCount
  },
  mixins: [rowspanMixin, reloadMixin],
  data: () => ({
    columns,
    DingTalkStatus,
    headMergeNum: 13,
    tailMergeNum: columns.length - 2,
    hasMergeTail: true,
    searchParams: {
      dingTalkStatus: 3
    },
    approvePath: 'guide-borrow/approve',
    batchPath: 'guide-borrow/approve-batch',
    printPath: '/print-borrow'
  }),
  computed: {
    ...mapGetters({
      data: 'bill/guideBorrow/data',
      tableData: 'bill/guideBorrow/list',
      total: 'bill/guideBorrow/total'
    })
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
          return;
        }
        this.rowspan();
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.isBack) {
      this.fetchData();
    }
  },
  methods: {
    fetchData(params = this.searchParams) {
      return this.$store.dispatch('bill/guideBorrow/fetchList', params);
    },
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    reloadData() {
      this.fetchData(this.searchParams);
    },
    handlePage(params) {
      this.fetchData({
        ...params,
        ...this.searchParams
      });
    },
    handleApprove(row) {
      this.$store.commit('bill/guideBorrow/CUR_ITEM', row);
      this.$router.push({
        path: this.approvePath,
        query: {
          applyId: row.applyId,
          planId: row.planId
        }
      });
    },
    handleBatch() {
      this.$router.push({
        path: this.batchPath
      });
    },
    handlePrint() {
      const routeUrl = this.$router.resolve({
        path: this.printPath,
        query: {
          ...this.searchParams,
          pageNum: 1,
          pageSize: this.total
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    showDialog(type, row) {
      this.$refs[type].show(row);
    }
  }
};