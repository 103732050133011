var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "80%",
      title: "更新协议",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleUpdate
    }
  }, [_c("el-form", {
    ref: "formItemRef",
    staticClass: "form-wrapper form-dialog-agr",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      inline: ""
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "协议名称",
      prop: "name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "20",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("name", $event);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "人头单价",
      prop: "shopPrice"
    }
  }, [_c("InputNumber", {
    attrs: {
      label: "",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.shopPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopPrice", $$v);
      },
      expression: "form.shopPrice"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "有效日期",
      prop: "startTime"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      label: "备注"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("remarks", $event);
      }
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1), _c("TableAgreement", {
    ref: "argeementRef",
    attrs: {
      form: _vm.form
    },
    on: {
      reload: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };