import "core-js/modules/es.array.push.js";
import SearchUnPay from '../components/SearchUnPay';
import TableUnPay from './TableUnPay';
import { FooterBar, SelectShop, BillInput } from '@/components/common';
import { TYPES, CashFeeType } from '@/config/billDown';
// import { RebateType } from '@/config/shopping'
import { tagViewMixin } from '@/mixin';
import { formatShopDownData } from '@/views/billDown/help';
export default {
  components: {
    FooterBar,
    SelectShop,
    BillInput,
    SearchUnPay,
    TableUnPay
  },
  mixins: [tagViewMixin],
  data: () => ({
    TYPES,
    CashFeeType,
    resourceId: '',
    backPath: '/shopping/shop-collect',
    backPathBill: '/bill/unit-collect/shopsubsidy',
    exportLoading: false,
    total: 0
  }),
  computed: {
    getResourceId() {
      return this.resourceId;
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$refs.tableRef.fetchData({
        ...params,
        resourceId: this.resourceId
      });
    },
    fetchBalance() {
      return this.$store.dispatch('bill/billDown/collect/fetchCollectBalance', {
        rid: this.resourceId,
        cashType: TYPES.shop_subsidy
      });
    },
    changeMoney(money) {
      // this.$store.dispatch('bill/billAutoDown/changeDownMoney', {
      //   money,
      //   isShop: true,
      //   subsidy: false,
      // })

      this.$refs.tableRef.handleAutoDown(money);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.tableRef.fetchData({
        resourceId
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const tableData = this.$refs.tableRef.tableData;
        const list = tableData.filter(it => it.isChecked);
        const opts = formatShopDownData({
          list,
          form,
          cashAction: CashFeeType.INCOME
        });
        this.$store.dispatch('bill/billDown/collect/addCollect', {
          ...opts,
          cashType: TYPES.shop_subsidy
        }).then(() => {
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        });
      });
    },
    // 返回列表
    goBack() {
      const path = this.$route.path.indexOf('bill') > -1 ? this.backPathBill : this.backPath;
      this.$router.push({
        path,
        query: {
          isBack: true
        }
      });
    }
  }
};