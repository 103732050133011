export default {
  props: {
    label: {
      type: String,
      default: '早餐厅'
    },
    form: {
      type: Object,
      required: true
    }
  }
};