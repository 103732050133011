import { inputMixin, selectListMixin } from '@/mixin';
import { formatDepartmentParams, hasCustomeAuth } from '@/utils';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    fetchPath: 'resource/custom/fetchList'
  }),
  computed: {
    data() {
      const data = this.$store.getters['resource/custom/customData'];
      return data;
    },
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    },
    visible() {
      return hasCustomeAuth(this.$store);
    }
  },
  watch: {
    roleInfo: {
      handler: function (data) {
        if (!data) return;
        this.params = formatDepartmentParams(data);
      },
      immediate: true
    }
  }
};