import { processMinus } from '@/utils/number';
export default {
  data() {
    return {
      isFirst: false
    };
  },
  computed: {
    // 角色信息
    roleInfoAuth() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  watch: {
    roleInfoAuth: {
      handler: function (val) {
        if (val !== null) {
          this.setTableColumn(val.showShopSubsidy);
        }
      },
      immediate: true
    }
  },
  methods: {
    setTableColumn(hasSubsidy) {
      const item = {
        attrs: {
          prop: 'totalSubsidy',
          label: '二返收益',
          sortable: true
        }
      };
      const fIndex = this.columnData.findIndex(it => it.attrs.label.indexOf('公司收益') >= 0);
      this.columnData = hasSubsidy ? [...this.columnData.slice(0, fIndex + 1), item, ...this.columnData.slice(fIndex + 1)] : this.columnData;
    },
    processTotalShopRebate(list) {
      if (!list) return;
      return list.map(it => {
        if (!it) return;
        const {
          totalShopRebate,
          totalSubsidy,
          shopProfit,
          totalIncome,
          totalReimbed
        } = it;
        const total = totalShopRebate != null ? totalShopRebate : shopProfit ? shopProfit.totalShopRebate : 0;
        const subsidy = totalSubsidy != null ? it.totalSubsidy : shopProfit ? shopProfit.totalSubsidy : 0;
        it.totalSubsidy = subsidy;
        it.totalUnIncome = processMinus(totalIncome, totalReimbed);

        // 第一次进入才相减
        if (!it.hasCache) {
          const res = processMinus(total, subsidy);
          it.totalShopRebate = res;
        }
        if (!it.hasCache) {
          it.hasCache = true;
        }
        return it;
      });
    }
  }
};