import { SelectFleet, SelectHotel, SelectScenic, SelectInsurance, SelectRestaurant, SelectTraffic, SelectGuide, SelectCustom, SelectShop } from '@/components/common';
const COMPONENT_MAP = {
  RESOURCE_SCENIC: 'SelectScenic',
  RESOURCE_RESTAURANT: 'SelectRestaurant',
  RESOURCE_HOTEL: 'SelectHotel',
  RESOURCE_BUS_COMPANY: 'SelectFleet',
  RESOURCE_INSURANCE: 'SelectInsurance',
  RESOURCE_TRANSPORT_COMPANY: 'SelectTraffic',
  RESOURCE_GUIDE: 'SelectGuide',
  RESOURCE_SHOP: 'SelectShop',
  RESOURCE_CUSTOMER: 'SelectCustom'
};
export default {
  components: {
    SelectFleet,
    SelectHotel,
    SelectScenic,
    SelectInsurance,
    SelectRestaurant,
    SelectTraffic,
    SelectGuide,
    SelectCustom,
    SelectShop
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    visible: true,
    cashType: COMPONENT_MAP.RESOURCE_SCENIC
  }),
  computed: {
    compName() {
      return COMPONENT_MAP[this.cashType];
    }
  },
  methods: {
    onSelect(type, val) {
      if (!val) {
        this.form.resourceName = '';
        this.visible = false;
      } else {
        this.visible = true;
        this.form[type] = val.value;
      }
      this.cashType = this.form.cashType;
    }
  }
};