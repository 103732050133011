import "core-js/modules/es.array.push.js";
/*
 * @Author: HelloMan
 * @Date: 2021-09-16 17:38:11
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-15 15:48:08
 * @Description:
 */
export default {
  methods: {
    handleDetail(row) {
      const {
        id,
        resourceId
      } = row;
      this.$store.commit('bill/billDown/SET_BILL_INPUT', row);
      this.$router.push({
        path: this.pathDetail,
        query: {
          id,
          rid: resourceId
        }
      });
    },
    handleUpdate(row) {
      const {
        id,
        resourceId
      } = row;
      this.$store.commit('bill/billDown/SET_BILL_INPUT', row);
      this.$router.push({
        path: this.path,
        query: {
          id,
          rid: resourceId
        }
      });
    },
    handleDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('bill/billDown/deleteCollect', id).then(() => {
          this.$parent.reload();
        });
      });
    }
  }
};