import { AuditStatus, CheckStatus, TopBar, DialogLog } from '@/components/common';
import { StatusAudit } from '@/components/common';
import { Members } from '@/components/common';
import CheckBox from './CheckBox';
import DialogProxy from './DialogProxy';
import { parseTime } from '@/utils';
import { mapGetters } from 'vuex';
import { auditPropMixin, rowspanMixin, selectorMixin, auditMixin, planMixin, reloadMixin } from '@/mixin';
export default {
  components: {
    TopBar,
    DialogLog,
    CheckBox,
    StatusAudit,
    Members,
    DialogProxy,
    AuditStatus,
    CheckStatus
  },
  mixins: [auditPropMixin, rowspanMixin, selectorMixin, auditMixin, planMixin, reloadMixin],
  data() {
    return {
      tableData: [],
      tableLoading: false,
      hasDataStatusKey: 'collectStatus',
      deletePath: 'plan/guideProxy/delete',
      updatePath: 'audit/index/updateAuditOne',
      fetchPath: 'audit/index/fetchProxy',
      headMergeNum: 4,
      hasMergeTail: false,
      auditAll: false,
      checkAll: false
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getRemark() {
      return item => {
        return item ? item.split('|')[0] : '';
      };
    }
  },
  watch: {
    checkAll: {
      handler: function (status) {
        const checkAllList = this.tableData.filter(it => it.isCheck || it.checkAccountStatus);
        if (checkAllList.length === this.tableData.length) {
          this.tableData = this.tableData.map(it => {
            it.checkAccountStatus = status;
            return it;
          });
        } else {
          this.tableData = this.tableData.map(it => {
            it.checkAccountStatus = status;
            return it;
          });
        }
      }
    },
    tableData: {
      handler: 'formatCheckTime',
      immediate: true
    }
  },
  mounted() {
    let {
      id,
      planId
    } = this.$route.query;
    id = planId || id;
    this.fetchData({
      id
    });
  },
  methods: {
    // 全选
    changeCheckAll(checkAll) {
      this.checkAll = checkAll;
    },
    initData(data) {
      if (!data) return;
      const list = data.list.filter(it => it.isGuideCollect);
      this.tableData = this.formatData(list);
      this.rowspan();
    },
    formatData(list) {
      if (!list) return [];
      let result = [];
      result = list.map(it => {
        let {
          paymentCollectionTime,
          auditProgress,
          isAgencyCollect
        } = it;
        it.isCheck = true;
        if (paymentCollectionTime) {
          it.paymentCollectionTime = parseTime(paymentCollectionTime);
        }
        if (auditProgress == 'WAITAUDIT' || auditProgress == 'OPAUDITED') {
          it.isCheck = false;
        }
        const proxy = isAgencyCollect ? 'isAgencyCollect' : 'isGuideCollect';
        const item = {
          ...it,
          rowId: it.id,
          proxy,
          totalPay: it.total,
          money: it.total,
          unit: proxy,
          ap: it.auditProgress,
          status: this.hasAudit(it.auditProgress),
          isRead: this.isReadOnly(it.auditProgress)
        };
        return item;
      });
      return result;
    },
    // 默认时间
    formatCheckTime(data) {
      data.forEach(it => {
        if (it.checkAccountStatus && !it.paymentCollectionTime) {
          let myDate = new Date();
          it.paymentCollectionTime = parseTime(myDate);
        }
        return it;
      });
    },
    // 日志
    handleShowLog({
      customerOrderId
    }) {
      // const itemId = id
      // this.$store
      //   .dispatch('audit/index/fetchLog', {
      //     itemId,
      //     subType: 'collect',
      //     types: '2',
      //   })
      //   .then(res => {
      //     this.$refs.logRef.show(res)
      //   })

      this.$store.dispatch('system/index/systemLog', {
        types: '1',
        itemIds: customerOrderId,
        subTypes: 'collect'
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 审核（单条）
    handleCheckout(row) {
      const {
        paymentCollectionTime
      } = row;
      if (!paymentCollectionTime) {
        this.$bus.tip({
          type: 'warning',
          message: '日期不能为空！'
        });
        return false;
      }
      this.$bus.open(() => {
        this.createOrUpdate([row], true);
      }, `是否确认审核?`);
    },
    // 取消审核
    handleCancel(row) {
      this.$bus.open(() => {
        this.createOrUpdate([row], false);
      }, `是否确认取消审核?`);
    },
    // 编辑
    handleEdit({
      row
    }) {
      this.$refs.proxyRef.edit(row);
    },
    // 新增
    handlePlus() {
      this.$refs.proxyRef.show();
    },
    // 删除
    handleDelete({
      row
    }) {
      const {
        id,
        planId
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/deleteProxy', id).then(res => {
          this.fetchData({
            id: planId
          });
          const {
            message
          } = res;
          // 判断是不是修改内容需要订单审核流程
          if (message.indexOf('订单审核') != -1) {
            this.$alert(message, '温馨提示', {
              confirmButtonText: '好的',
              type: 'warning'
            });
          } else {
            this.$bus.tip({
              message
            });
          }
        });
      }, `是否确认删除？?`);
    },
    beforeCommit(list) {
      const dataList = list.filter(it => it.checkAccountStatus);
      const data = this.beforeCommitGB(dataList);
      return data;
    },
    beforeCommitGB(list, status = 'true') {
      const items = list.map(it => {
        let items = {
          auditTypeEnum: 'COLLECT',
          planId: it.planId,
          resourceId: it.id,
          isCollectMenu: false,
          paymentCollectionTime: it.paymentCollectionTime,
          status,
          auditProgress: it.auditProgress
        };
        if (this.isAdminAudit) {
          items.auditProgress = 'OPAUDITED';
        }
        return items;
      });
      return items;
    },
    createOrUpdate(list, status) {
      const opts = this.beforeCommitGB(list, status);
      return new Promise((resolve, reject) => {
        const loading = this.$bus.loading();
        this.$store.dispatch(this.updatePath, opts).then(() => {
          this.fetchData({
            id: this.currentPlanId
          });
          loading.close();
          this.$bus.tip();
          resolve();
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    validateForm() {
      const checkList = this.tableData.filter(it => it.checkAccountStatus);
      if (!checkList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选中数据!'
        });
        return false;
      }
      const dataList = this.beforeCommit(checkList, true);
      dataList.forEach(it => {
        if (!it.paymentCollectionTime) {
          this.$bus.tip({
            type: 'warning',
            message: `选中的数据,日期不能为空!`
          });
          return false;
        }
      });
      return true;
    }
  }
};