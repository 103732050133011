var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectBase", {
    attrs: {
      type: "examineAll"
    },
    on: {
      "on-select": _vm.changeRole
    },
    model: {
      value: _vm.auditAllStatus,
      callback: function ($$v) {
        _vm.auditAllStatus = $$v;
      },
      expression: "auditAllStatus"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };