import { auditProgressStatus, AuditRole } from '@/config/audit';
export default {
  name: 'StatusAudit',
  inheritAttrs: false,
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    // unAudited() {
    //   const { auditProgress } = this.row
    //   return auditProgress && auditProgress == AuditRole['wait']
    // },

    getStatus() {
      const {
        auditProgress
      } = this.row;
      return auditProgress && auditProgress != AuditRole['wait'] ? `${auditProgressStatus[auditProgress]}已审` : '未审核';
    },
    // getRole() {
    //   return `${this.getStatus}已审`
    // },

    getType() {
      const {
        auditProgress
      } = this.row;
      if (!auditProgress || auditProgress && auditProgress == AuditRole['wait']) return 'warning';
      return auditProgress == AuditRole.op ? 'success' : 'danger';
    }
  }
};