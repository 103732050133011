import FormItem from './FormItem.vue';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    reload() {
      this.$parent.fetchData();
    }
  }
};