var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curOrder ? _c("div", {
    staticClass: "order-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础信息")]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "大团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.planNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "参团人数"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.adultCount) + "大")]), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.childCount) + "小")]), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.accompanyCount) + "陪")])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "团上导游"
    }
  }), _vm.curOrder.guides.length ? [_c("div", _vm._l(_vm.curOrder.guides, function (item, index) {
    return _c("p", {
      key: index,
      staticStyle: {
        margin: "5px 0"
      }
    }, [item.reimb ? [_c("TextBase", [_vm._v(_vm._s(item.name) + "(" + _vm._s(item.mobileIphone) + ")")])] : _vm._e()], 2);
  }), 0)] : _vm._e()], 2), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "操作计调"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.dutyOPUserName))])], 1), _vm.curOrder.planType == "T_TOURIST" && _vm.curOrder.customerVoList.length ? _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户"
    }
  }), _vm.visible ? _c("TextBase", [_vm._v(" " + _vm._s(_vm.hasCustomerInfo ? _vm.curOrder.customerVoList[0].name : "***") + " ")]) : _vm._e()], 1) : _vm._e()]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "产品线路"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.productName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "发团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.startPlanDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "散团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.endPlanDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("客服计调 : ")]), _c("OutOPUsers", {
    attrs: {
      list: _vm.curOrder.outOPUsers
    }
  })], 1), _vm.curOrder.planType == "T_TOURIST" ? _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("所属区域 : ")]), _c("span", [_vm._v(" " + _vm._s(_vm.curOrder.customerArea) + " ")])]) : _vm._e()])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };