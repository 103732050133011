import { COLUMNS_DETAIL as columns } from '../tableConfig';
import { parseTime } from '@/utils';
import { RES_TYPE } from '@/config';
export default {
  data() {
    return {
      columns,
      tableData: [],
      visible: false
    };
  },
  methods: {
    show({
      id: planId,
      guideName
    }) {
      this.visible = true;
      this.guideName = guideName;
      this.fetchData({
        planId
      });
    },
    hide() {
      this.visible = false;
    },
    fetchData({
      planId
    }) {
      this.$store.dispatch('enterprise/guide/consumptionDetail', {
        planId
      }).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data);
      });
    },
    formatData(list) {
      return list.map(it => {
        it.$consumeCreateTime = parseTime(it.consumeCreateTime);
        it.$resType = RES_TYPE[it.resType];
        it.guideName = it.guideName || this.guideName;
        return it;
      });
    }
  }
};