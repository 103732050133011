import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 数据分析

// import SidebarBlank from '@/layout/SidebarBlank'
import Layout from '@/layout';
const analyseRouter = {
  path: '/analysis',
  component: Layout,
  redirect: '/analysis/chart',
  name: 'analysis',
  meta: {
    title: 'analysis',
    icon: 'el-icon-s-data'
  },
  children: [{
    path: '/analysis/chart',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/chart'))),
    name: 'analysisIndex',
    // 数据分析首页
    meta: {
      title: 'analysisIndex',
      icon: 'el-icon-s-data'
    }
  }, {
    path: '/analysis/area',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/area'))),
    name: 'analysisArea',
    // 区域分析表
    meta: {
      title: 'analysisArea',
      icon: 'icon-pie-chart'
    }
  }, {
    path: '/analysis/custom',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/custom'))),
    name: 'analysisCustom',
    // 客户分析表
    meta: {
      title: 'analysisCustom',
      icon: 'icon-bumenguanli'
    }
  }, {
    path: '/analysis/line',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/line'))),
    name: 'analysisLin',
    meta: {
      title: 'analysisLine',
      icon: 'icon-tree'
    }
  }, {
    path: '/analysis/guide',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/guide'))),
    name: 'analysisGuide',
    // 导游数据分析
    meta: {
      title: 'analysisGuide',
      icon: 'icon-shenqingdaoyou-01'
    }
  }, {
    path: '/analysis/income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/income'))),
    name: 'analysisIncome',
    // 应收款分析
    meta: {
      title: 'analysisIncome',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/analysis/income-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/income/detail'))),
    name: 'analysisIncomeDetail',
    // 应收款明细
    meta: {
      title: 'analysisIncomeDetail',
      auth: true
    },
    hidden: true
  }, {
    path: '/analysis/shop',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/shop'))),
    name: 'analysisShop',
    // 购物店分析表
    meta: {
      title: 'analysisShop',
      icon: 'icon-cangkuxinxi'
    }
  }, {
    path: '/analysis/soilShop',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/soilShop'))),
    name: 'analysisSoilShop',
    // 土产点分析
    meta: {
      title: 'analysisSoilShop',
      icon: 'icon-wentichaoshi'
    }
  }, {
    path: '/analysis/sale',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/sale'))),
    name: 'analysisSale',
    // 业务员分析表
    meta: {
      title: 'analysisSale',
      icon: 'icon-sijixinxiguanli'
    }
  }, {
    path: '/analysis/group',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/group'))),
    name: 'analysisGroup',
    // 团型分析表
    meta: {
      title: 'analysisGroup',
      icon: 'icon-kaohejiance'
    }
  }, {
    path: '/analysis/performance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/echart/performance'))),
    name: 'analysisPerformance',
    // 个人业绩
    meta: {
      title: 'analysisPerformance',
      icon: 'icon-user'
    }
  }, {
    path: '/analysis/department',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/echart/department'))),
    name: 'analysisDepartment',
    // 部门业绩
    meta: {
      title: 'analysisDepartment',
      icon: 'icon-user'
    }
  }, {
    path: '/analysis/custom-kpi',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/customKpi'))),
    name: 'analysisCustomKpi',
    // 营业额/人数/毛利总表
    meta: {
      title: 'analysisCustomKpi',
      icon: 'icon-icon'
    }
  }, {
    path: '/analysis/member-age',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/memberAge'))),
    name: 'analysisMemberAge',
    // 游客年龄段
    meta: {
      title: 'analysisMemberAge',
      icon: 'icon-zonglan'
    }
  }, {
    path: '/analysis/comparison',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/comparison'))),
    name: 'analysisComparison',
    // 小团利润与单团利润比对
    meta: {
      title: 'analysisComparison',
      icon: 'icon-fund'
    }
  }, {
    path: '/analysis/target-company',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/target/company'))),
    name: 'analysisTargetCompany',
    // 公司目标
    meta: {
      title: 'analysisTargetCompany',
      icon: 'icon-fund'
    }
  }, {
    path: '/analysis/target-department',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/target/department'))),
    name: 'analysisTargetDepartment',
    // 部门目标
    meta: {
      title: 'analysisTargetDepartment',
      icon: 'icon-fund'
    }
  }, {
    path: '/analysis/target-staff',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/target/staff'))),
    name: 'analysisTargetStaff',
    // 员工目标
    meta: {
      title: 'analysisTargetStaff',
      icon: 'icon-fund'
    }
  }]
};
export default analyseRouter;