import { planSmsSendRecord as columns } from '@/config/plan/smsSend';
import { RECHANGE_TYPE } from '@/config/vas';
import { PAY_NET_TYPE_LABEL, PAY_NET_TYPE } from '@/config/payType';
import { parseTime } from '@/utils';
export default {
  data() {
    return {
      tableData: [],
      columns,
      visible: false,
      isUpdate: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      description: '支付宝订单未支付成功，30分钟后自动取消！'
    };
  },
  computed: {
    getPayType() {
      return val => {
        return val && PAY_NET_TYPE_LABEL[val.toUpperCase()];
      };
    },
    getPayTypeColor() {
      return val => {
        return val == PAY_NET_TYPE.WECHAT ? 'success' : 'primary';
      };
    },
    getPageList() {
      const result = this.tableData.slice((this.pageNum - 1) * this.pageSize, this.pageNum * this.pageSize);
      return result;
    },
    getTotal() {
      return this.tableData.length || 0;
    }
  },
  methods: {
    show() {
      this.loading = false;
      this.visible = true;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    filterData(list) {
      const newList = list.filter(it => it.rechargeType == RECHANGE_TYPE.COUNT);
      return newList.map(it => {
        it.createTime = parseTime(it.createTime);
        return it;
      });
    },
    fetchData(params = {}) {
      this.loading = true;
      this.$store.dispatch('system/payment/vasLog', {
        ...params,
        pageSize: 10000
      }).then(res => {
        if (!res || !res.data) return;
        const {
          data
        } = res;
        this.tableData = this.filterData(data.list);
        this.loading = false;
      });
    },
    handlePage(val) {
      // if (this.getPageList.length >= this.tableData.length) return
      this.pageNum = val;
      // this.fetchData()
    },
    handleCancel(row) {
      const {
        id,
        outTradeNo
      } = row;
      if (id) {
        this.$store.dispatch('system/payment/closeOrderById', {
          vasLogId: id
        }).then(() => {
          this.fetchData();
        });
        return;
      }
      if (outTradeNo) {
        this.$store.dispatch('system/payment/closeOrderById', {
          outTradeNo
        }).then(() => {
          this.fetchData();
        });
      }
    },
    handlePay(row) {
      const {
        amount,
        outTradeNo,
        payCodeOrLink,
        payType
      } = row;
      if (!payCodeOrLink) {
        this.$bus.tip({
          type: 'error',
          message: '订单已失效'
        });
        return;
      }
      if (payType === PAY_NET_TYPE.WECHAT) {
        this.$emit('on:pay', {
          value: payCodeOrLink,
          money: amount,
          outTradeNo
        });
      }
      if (payType === PAY_NET_TYPE.ALIPAY) {
        document.querySelector('body').innerHTML = payCodeOrLink;
        document.forms[0].submit();
      }
    }
  }
};