import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import { TableScenic, TableHotel, TableRestaurant, TableFleet, TableGuide, TableOperate } from './components';
import { SelectCustom, FooterBar } from '@/components/common';
import { decimalSplit, processAdd } from '@/utils';
import html2canvas from 'html2canvas';
const itemListKeys = () => ({
  name: '',
  adultCount: 0,
  customerId: '',
  endTripDate: '',
  startTripDate: '',
  opCount: '',
  opPrice: '',
  operateDetails: [{
    price: 0,
    cost: 0
  }],
  busDetails: [],
  busMoney: 0,
  busRemarks: '',
  guideDetails: [],
  guideRemarks: '',
  guideMoney: 0,
  hotelDetails: [],
  hotelRefer: '',
  hotelRemarks: '',
  hotelMoney: 0,
  scenicDetails: [],
  scenicMoney: 0,
  scenicRemarks: '',
  restaurantDetails: [],
  restaurantMoney: 0,
  restaurantRemarks: ''
});
export default {
  components: {
    TableHotel,
    TableFleet,
    TableGuide,
    TableScenic,
    TableOperate,
    SelectCustom,
    FooterBar,
    TableRestaurant
  },
  data() {
    return {
      sendDate: [],
      form: {},
      visible: false,
      title: '',
      link: {
        edit: '/offer/edit',
        add: '/offer/add',
        code: '/offer/code'
      }
    };
  },
  created() {
    const {
      path,
      query
    } = this.$route;
    if (this.link.add == path) {
      this.form = itemListKeys();
      this.form.scenicDetails = this.addFomatEmpty('scenic', 10);
      this.form.hotelDetails = this.addFomatEmpty('hotel', 2);
      this.form.restaurantDetails = this.addFomatEmpty('restaurant');
      this.form.busDetails = this.addFomatEmpty('bus');
      this.form.guideDetails = this.addFomatEmpty('guide');
    } else {
      this.fetchOfferId({
        id: query.id
      });
    }
  },
  methods: {
    // 新增时重构空数组
    addFomatEmpty(type, len = 1) {
      let item = new Array(len);
      item = item.fill({});
      let rules = [];
      item.forEach(() => {
        let rule = {
          price: 0
        };
        if (type == 'scenic') {
          rule.name = '';
        } else {
          if (type == 'hotel') {
            rule.area = '';
          }
          rule.count = 1;
          rule.cost = 0;
        }
        rules.push(rule);
      });
      return rules;
    },
    changeCustom(val) {
      const {
        name,
        id
      } = val;
      this.form.customerId = id;
      this.form.customerName = name;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.form.startTripDate = startDate;
      this.form.endTripDate = endDate;
    },
    sumTotal() {
      const {
        scenicRef,
        hotelRef,
        restaurantRef,
        fleetRef,
        guideRef,
        operateRef
      } = this.$refs;
      const {
        total: scenicMoney
      } = scenicRef;
      const {
        total: hotelMoney
      } = hotelRef;
      const {
        total: restaurantMoney
      } = restaurantRef;
      const {
        total: busMoney
      } = fleetRef;
      const {
        total: guideMoney
      } = guideRef;
      const {
        tableData: operateDetails
      } = operateRef;
      const opMoney = operateDetails[0].price;
      const total = processAdd(scenicMoney, hotelMoney, restaurantMoney, busMoney, guideMoney, opMoney);
      this.form.totalMoney = decimalSplit(total);
      return total;
    },
    fetchOfferId(queryParam) {
      this.$store.dispatch('offer/index/fetchOfferId', queryParam).then(data => {
        this.visible = true;
        this.form = data;
        this.form.operateDetails = [{
          price: data.opPrice,
          cost: data.opPrice
        }];
        this.form.hotelDetails = this.calcCost(data.hotelDetails, data.adultCount);
        this.form.restaurantDetails = this.calcCost(data.restaurantDetails, data.adultCount);
        this.form.busDetails = this.calcCost(data.busDetails, data.adultCount);
        this.form.guideDetails = this.calcCost(data.guideDetails, data.adultCount);
        this.sendDate = [data.startTripDate, data.endTripDate];
      });
    },
    // 计算单人费用
    calcCost(list, adultCount) {
      if (!list || !list.length) return;
      list.map(it => {
        it.cost = it.price * it.count / adultCount;
        return it;
      });
      return list;
    },
    handleCreate() {
      const {
        scenicRef,
        hotelRef,
        restaurantRef,
        fleetRef,
        guideRef,
        operateRef
      } = this.$refs;
      const {
        tableData: scenicData,
        total: scenicMoney
      } = scenicRef;
      const {
        tableData: hotelDetails,
        total: hotelMoney
      } = hotelRef;
      const {
        tableData: restaurantDetails,
        total: restaurantMoney
      } = restaurantRef;
      const {
        tableData: busDetails,
        total: busMoney
      } = fleetRef;
      const {
        tableData: guideDetails,
        total: guideMoney
      } = guideRef;
      const {
        tableData: operateDetails
      } = operateRef;
      const totalMoney = this.sumTotal();
      const scenicDetails = scenicData.filter(it => it.price && it.name);
      const opts = {
        ...this.form,
        scenicDetails,
        scenicMoney,
        hotelDetails,
        hotelMoney,
        restaurantDetails,
        restaurantMoney,
        busDetails,
        busMoney,
        guideDetails,
        guideMoney,
        operateDetails,
        opPrice: operateDetails[0].price,
        opCount: 1,
        totalMoney
      };
      const {
        path
      } = this.$route;
      if (this.link.edit == path) {
        this.updateAfreshOffer(opts);
      } else {
        this.updateOffer(opts);
      }
    },
    // 保存
    updateOffer(opts) {
      const {
        path
      } = this.$route;
      if (this.link.code == path) {
        delete opts.id;
      }
      this.$store.dispatch('offer/index/updateOffer', opts).then(() => {
        this.$bus.tip();
        this.$router.push({
          path: '/offer'
        });
      });
    },
    // 重新报价
    updateAfreshOffer(opts) {
      delete opts.id;
      const {
        id
      } = this.$route.query;
      const reId = id;
      this.$store.dispatch('offer/index/updateAfreshOffer', {
        ...opts,
        reId
      }).then(() => {
        this.$bus.tip();
        this.$router.push({
          path: '/offer'
        });
      });
    },
    handleDelete(id) {
      this.$slots.dispatch('offer/index/deleteOffer', {
        id
      }).then(() => {
        this.$bus.tip();
      });
    },
    handleShare() {
      const loading = this.$bus.loading();
      const element = document.getElementById('share-element'); // 替换为要截屏的元素的ID或选择器
      const canvasHtml = document.getElementById('canvas'); // 替换为要截屏的元素的ID或选择器
      html2canvas(element).then(canvas => {
        canvasHtml.appendChild(canvas); // 直接使用canvas
        const imgURL = canvas.toDataURL('image/png', 1); // 此方法可以设置截图质量0-1
        this.getClipboard(imgURL);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    getClipboard(base64Data) {
      let blob = null;
      const format = 'image/png';
      //将Base64图片编码转换成blob
      const base64 = base64Data;
      const code = window.atob(base64.split(',')[1]);
      const aBuffer = new window.ArrayBuffer(code.length);
      const uBuffer = new window.Uint8Array(aBuffer);
      for (let i = 0; i < code.length; i++) {
        uBuffer[i] = code.charCodeAt(i) & 0xff;
      }
      try {
        blob = new Blob([uBuffer], {
          type: format
        });
      } catch (e) {
        window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
        if (e.name == 'TypeError' && window.BlobBuilder) {
          const bb = new window.BlobBuilder();
          bb.append(uBuffer.buffer);
          blob = bb.getBlob('image/png');
        } else if (e.name == 'InvalidStateError') {
          blob = new Blob([aBuffer], {
            type: format
          });
        }
      }

      // 将创建ClipboardItem并放入blob
      // eslint-disable-next-line no-undef
      const item = new ClipboardItem({
        'image/png': blob
      });
      //设置对象到粘贴板
      navigator.clipboard.write([item]);
      this.$bus.tip({
        message: '截图已复制到剪贴板，您可以粘贴到QQ、微信等通讯软件中发送给好友。'
      });
    }
  }
};