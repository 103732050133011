import { BillInput, ResourceItem } from '@/components/common';
export default {
  components: {
    BillInput,
    ResourceItem
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.form = {};
      this.visible = false;
    },
    handleCommit() {
      this.$refs.billInputRef.onSave();
    },
    handleSave(data) {
      this.$store.dispatch('bill/billDown/pay/createPay', {
        ...data,
        ...this.form,
        regularUseMoney: 1
      }).then(() => {
        this.$bus.tip();
        this.hide();
        this.$emit('reload');
      });
    }
  }
};