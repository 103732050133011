import { Message } from 'element-ui';
import { linkDownload } from './download';
export function downloadExport(res, fileName) {
  if (res.data.size < 1000) {
    Message({
      message: '没有下载数据',
      type: 'error',
      offset: window.innerHeight / 2
    });
    return false;
  }
  linkDownload({
    data: res.data,
    fileName
  });
  // const blob = new Blob([res.data])
  // var downloadElement = document.createElement('a')
  // var href = window.URL.createObjectURL(blob)
  // downloadElement.href = href
  // downloadElement.download = fileName
  // document.body.appendChild(downloadElement)
  // downloadElement.click()
  // document.body.removeChild(downloadElement)
  // window.URL.revokeObjectURL(href)
}