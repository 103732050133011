var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "breakfast",
      fn: function ({
        scope
      }) {
        return [scope.row.breakfast ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "lunch",
      fn: function ({
        scope
      }) {
        return [scope.row.lunch ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "dinner",
      fn: function ({
        scope
      }) {
        return [scope.row.dinner ? _c("IconRight") : _c("IconWrong")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };