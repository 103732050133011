import { SelectRole } from '@/components/common';
export default {
  components: {
    SelectRole
  },
  data() {
    return {
      visible: false,
      option: {}
    };
  },
  methods: {
    show(option) {
      this.option = {
        ...this.option,
        ...option
      };
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      this.$store.dispatch('system/role/importPermission', this.option).then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};