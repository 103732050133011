import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import SealImage from '@/views/print/components/SealImage';
import TopTitle from '@/views/print/components/TopTitle';
import BaseInfo from './components/BaseInfo';
import SubmitInfo from './components/SubmitInfo';
import Collect from './components/Collect';
import GuideBorrow from './components/GuideBorrow';
import ShopCash from './components/ShopCash';
import OtherIncome from './components/OtherIncome';
import GuideFee from './components/GuideFee';
import ShopCommission from './components/ShopCommission';
import Scenic from './components/Scenic';
import ScenicCash from './components/ScenicCash';
import ScenicCommission from './components/ScenicCommission';
import Hotel from './components/Hotel';
import Restaurant from './components/Restaurant';
import Bus from './components/Bus';
import Insurance from './components/Insurance';
import OtherPay from './components/OtherPay';
import { getPayTypeLabel } from '@/config/select';
export default {
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    SealImage,
    TopTitle,
    BaseInfo,
    SubmitInfo,
    Collect,
    GuideBorrow,
    ShopCash,
    OtherIncome,
    GuideFee,
    ShopCommission,
    Scenic,
    ScenicCash,
    ScenicCommission,
    Hotel,
    Restaurant,
    Bus,
    Insurance,
    OtherPay
  },
  data() {
    return {
      data: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let {
        id
      } = this.$route.query;
      this.$store.dispatch('report/index/getGuideSubmit', {
        ...this.params,
        planId: id
      }).then(data => {
        if (!data) return;
        this.data = this.formatData(data);
        this.$emit('onMounted');
      });
    },
    mergeList(list) {
      const hotelMap = {};
      list.forEach(it => {
        const {
          name,
          totalStr
        } = it;
        if (!hotelMap[name]) {
          hotelMap[name] = {
            name,
            totalStr,
            list: []
          };
        }
        hotelMap[name].list.push(it);
      });
      return Object.keys(hotelMap).map(key => hotelMap[key]);
    },
    formatData(data) {
      let result = {};
      const {
        totalBorrow,
        totalBus,
        totalCustomerCollect,
        totalHotel,
        totalInsurance,
        totalOtherIncome,
        totalOtherPay,
        totalRestaurant,
        totalScenic,
        totalScenicCommission,
        totalScenicIncome,
        totalShopCommission,
        totalShopIncome
      } = data;
      const {
        adultCount,
        childCount,
        accompanyCount,
        guideName,
        planNumber
      } = data;
      const {
        totalRemb,
        guideManageFee,
        guideFund,
        guideTax,
        reimbed,
        depositFee,
        voluntarilyFee,
        unReimb,
        recordingFee
      } = data;
      const {
        customerCollections,
        borrows,
        shopIncomes,
        otherIncomes,
        otherPays,
        scenics,
        scenicIncomes,
        scenicCommissions,
        hotels,
        restaurants,
        buses,
        insurances,
        shopCommissions
      } = data;
      let {
        startTripDate,
        endTripDate,
        remark,
        guideServiceFee
      } = data;
      startTripDate = startTripDate ? startTripDate : '';
      endTripDate = endTripDate ? endTripDate : '';

      // 基础信息
      result.baseInfo = {
        number: `${adultCount}大${childCount}小${accompanyCount}陪`,
        guideName,
        planNumber
      };

      // 应报
      result.submitInfo = {
        totalRemb,
        guideManageFee,
        guideFund,
        guideTax,
        reimbed,
        unReimb,
        recordingFee,
        voluntarilyFee,
        depositFee
      };

      // 导服费
      result.guideFee = [{
        name: startTripDate,
        list: [{
          endTripDate,
          number: `${adultCount}大${childCount}小`,
          guideServiceFee,
          remark
        }]
      }];

      // 景区
      result.scenics = scenics.map(it => {
        const tickets = it.tickets;
        it.list = tickets.map(item => {
          item.isAddScenic = it.isAddScenic ? '是' : '否';
          item.reimbMoney = getPayTypeLabel(it.payType) + ':' + item.money;
          item.reimbRemark = it.reimbRemark;
          item.orderDate = it.orderDate;
          item.date = `${startTripDate} ${endTripDate}`;
          item.remarks = it.remarks;
          return item;
        });
        return it;
      });
      // 过滤没有数据的景区
      result.scenics = result.scenics.filter(it => it.list.length > 0);

      // 景区现收
      result.scenicCash = scenicIncomes.map(it => {
        const {
          totalAmountReceive,
          orderDate,
          reimbMoney
        } = it;
        it.list = it.tickets;
        it.list = it.list.map(item => {
          item.isAddScenic = it.isAddScenic ? '是' : '否';
          item.reimbMoney = reimbMoney;
          item.orderDate = orderDate;
          item.totalAmountReceive = totalAmountReceive;
          return item;
        });
        return it;
      });

      // 景区佣金
      result.scenicCommissions = scenicCommissions.map(it => {
        const {
          orderDate,
          accompanyCommission,
          accompanyRate,
          driverCommission,
          driverRate,
          guideCommission,
          guideRate,
          totalAmountReceive,
          remarks,
          reimbRemark
        } = it;
        it.list = it.tickets;
        it.list = it.list.map(item => {
          return {
            ...item,
            orderDate,
            accompanyCommission,
            accompanyRate,
            driverCommission,
            driverRate,
            guideCommission,
            guideRate,
            totalAmountReceive,
            remarks,
            reimbRemark
          };
        });
        return it;
      });

      // 酒店
      const hotelsCopy = hotels.reduce((acc, cur) => {
        const rooms = cur.rooms;

        // 处理金额合并，日期与房型一致的则合并
        rooms.forEach(r => {
          if (!r.hasPass) {
            r.mergeRowCount = rooms.filter(a => {
              if (a.orderDate == r.orderDate && a.roomType == r.roomType) {
                a.hasPass = true;
                return true;
              }
              return false;
            }).length;
          }
        });
        const list = rooms.map(room => {
          delete room.rooms;
          return {
            ...cur,
            ...room
          };
        });
        return [...acc, ...list];
      }, []);
      result.hotels = this.mergeList(hotelsCopy);

      // 餐厅
      const restaurantCopy = restaurants.reduce((acc, pre) => {
        const list = pre.types.map(it => {
          return {
            ...it,
            ...pre
          };
        });
        return [...acc, ...list];
      }, []);
      const mergeRestaurant = this.mergeList(restaurantCopy);
      result.restaurants = mergeRestaurant;

      // 车队
      result.buses = buses.map(it => {
        const {
          reimbMoney,
          reimbRemark,
          remark
        } = it;
        it.list = it.types;
        it.list = it.list.map(item => {
          item.info = `${item.driverName} ${item.driverPhone}`;
          item.reimbMoney = reimbMoney;
          item.reimbRemark = reimbRemark;
          item.remark = remark;
          return item;
        });
        return it;
      });

      // 保险
      result.insurances = insurances.map(it => {
        const {
          reimbMoney,
          reimbRemark
        } = it;
        it.list = it.types;
        it.list = it.list.map(item => {
          item.reimbMoney = reimbMoney;
          item.reimbRemark = reimbRemark;
          return item;
        });
        return it;
      });

      // // 购物现收
      result.shopCash = JSON.stringify(shopIncomes);
      result.borrows = borrows.reduce((pre, cur) => {
        return [...pre, {
          name: cur.subject,
          list: [cur]
        }];
      }, []);

      // 收款
      result.collect = customerCollections.reduce((pre, cur) => {
        const {
          adultCount,
          childCount,
          accompanyCount
        } = cur;
        cur.number = `${adultCount}大${childCount}小${accompanyCount}陪`;
        return [...pre, {
          name: cur.name,
          list: [cur]
        }];
      }, []);
      // 购物佣金
      result.shopCommissions = shopCommissions.filter(it => it.name.indexOf('绩效') == -1).map(it => {
        const {
          name,
          orderDate,
          totalBuyMoney
        } = it;
        it.name = name + '\r\n' + orderDate;
        it.list = it.goods;
        it.list = it.list.map(item => {
          item.goodName = item.name;
          item.totalBuyMoney = totalBuyMoney;
          return item;
        });
        return it;
      });

      // // 购物佣金
      // result.shopCommissions = shopIncomes.map(it => {
      //   const { totalBuyMoney } = it
      //   // it.name = name + '\r\n' + orderDate
      //   it.list = it.goods
      //   it.list = it.list.map(item => {
      //     item.goodName = item.name
      //     item.totalBuyMoney = totalBuyMoney
      //     return item
      //   })
      //   return it
      // })

      // 其他收入
      result.otherIncome = otherIncomes.reduce((pre, cur) => {
        return [...pre, {
          name: cur.title,
          list: [cur]
        }];
      }, []);

      // 其他支出
      result.otherPay = otherPays.reduce((pre, cur) => {
        return [...pre, {
          name: cur.title,
          list: [cur]
        }];
      }, []);

      // 导佣合计
      const {
        guideRebate
      } = data;
      const guideRebateTotal = '导佣合计: ' + guideRebate;
      return {
        ...result,
        guideRebate: guideRebateTotal,
        totalBorrow,
        totalBus,
        totalCustomerCollect,
        totalHotel,
        totalInsurance,
        totalOtherIncome,
        totalOtherPay,
        totalRestaurant,
        totalScenic,
        totalScenicCommission,
        totalScenicIncome,
        totalShopCommission,
        totalShopIncome,
        guideServiceFee
      };
    }
  }
};