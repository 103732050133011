import ModifyItem from '../components/ModifyItem.vue';
import modifyMixin from '../modify';
import { orderFleetInfo } from '@/config/resourceOrder';
import { PickAndSendTypeEnum } from '@/config/plan';
import { DayRangeLabelMap } from '@/config/day';
export default {
  components: {
    ModifyItem
  },
  mixins: [modifyMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: orderFleetInfo,
      tableData: []
    };
  },
  computed: {
    getData() {
      return this.data ? [this.data] : [];
    },
    getPickAndSendType() {
      return (row, type) => {
        const res = this.getContent(row, type);
        return {
          oldVal: PickAndSendTypeEnum[res.oldVal && res.oldVal.toLowerCase()],
          newVal: PickAndSendTypeEnum[res.newVal && res.newVal.toLowerCase()]
        };
      };
    },
    getDatetimeType() {
      return (row, type) => {
        const res = this.getContent(row, type);
        return {
          oldVal: DayRangeLabelMap[res.oldVal],
          newVal: DayRangeLabelMap[res.newVal]
        };
      };
    }
  }
};