import { SelectCustom } from '@/components/common';
import { downloadExport, getSearchCache } from '@/utils';
export default {
  components: {
    SelectCustom
  },
  data() {
    return {
      starDate: [],
      sendDate: [],
      orderDate: [],
      searchParams: {}
    };
  },
  computed: {
    getSearchParams() {
      return getSearchCache(this.$store, this.$route.path);
    }
  },
  watch: {
    getSearchParams: {
      handler(data) {
        if (!data) {
          return;
        }
        this.searchParams = data;
        const {
          startTripDateS,
          startTripDateE,
          endTripDateS,
          endTripDateE,
          orderDateS,
          orderDateE
        } = data;
        this.starDate = [startTripDateS, startTripDateE];
        this.sendDate = [endTripDateS, endTripDateE];
        this.orderDate = [orderDateS, orderDateE];
      },
      immediate: true
    }
  },
  mounted() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.$emit('on:search', this.searchParams);
    },
    selectDate(type, val) {
      const [start, end] = val;
      this.searchParams[type + 'S'] = start;
      this.searchParams[type + 'E'] = end;
    },
    // 选择客户
    selectCustom({
      label
    }) {
      this.searchParams.customerName = label;
    },
    handleExport() {
      this.$bus.tip({
        message: '数据正在导出...'
      });
      this.$store.dispatch('report/index/exportSubsidy2', this.searchParams).then(res => {
        downloadExport(res, '返款汇总报表.xlsx');
      });
    }
  }
};