var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reserve"
  }, [_c("ReserveInfo", {
    ref: "infoRef",
    attrs: {
      inline: _vm.isUpdate,
      "is-update": _vm.isUpdate
    }
  }), _vm.isUpdate ? [_c("CollapseBase", {
    attrs: {
      title: "行程信息",
      "is-strong": ""
    }
  }, [_c("TableTripBase", {
    attrs: {
      "table-data": _vm.tripData
    }
  })], 1), _c("HotelTable", {
    ref: "hotelRef",
    attrs: {
      "column-data": _vm.orderHotel,
      "reserve-id": _vm.form.customerOrderId,
      data: _vm.hotelData
    }
  }), _c("FleetTable", {
    ref: "fleetRef",
    attrs: {
      "column-data": _vm.orderFleet,
      data: _vm.fleetData
    }
  }), _c("ScenicTable", {
    ref: "scenicRef",
    attrs: {
      "column-data": _vm.orderScenic,
      data: _vm.scenicData
    }
  }), _c("RestaurantTable", {
    ref: "restaurantRef",
    attrs: {
      "column-data": _vm.orderRestaurant,
      data: _vm.restaurantData
    }
  }), _c("div", {
    staticClass: "booking-item booking-guide"
  }, [_vm._m(0), _c("InputBase", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.guideRemark,
      callback: function ($$v) {
        _vm.guideRemark = $$v;
      },
      expression: "guideRemark"
    }
  })], 1), _c("FooterBar", [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.handlePlan
    }
  }, [_vm._v(" 保存并预定 ")])], 1)] : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("h2", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v(" 导游 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };