var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "form-chart"
  }, [_c("div", {
    staticClass: "chart-item"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h2", [_vm._v("待办事项")])]), _c("div", {
    staticClass: "backlog-item"
  }, [_vm._l(_vm.backlogItem, function (item, index) {
    return _c("el-badge", {
      key: index,
      staticClass: "item",
      attrs: {
        value: item.value
      }
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        plain: "",
        size: "small"
      },
      on: {
        click: function ($event) {
          return _vm.onRedirects(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), _c("el-badge", {
    staticClass: "item",
    attrs: {
      value: _vm.count
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      plain: "",
      size: "small"
    },
    on: {
      click: _vm.onRedirectPlan
    }
  }, [_vm._v(" 导管待排团 ")])], 1)], 2)])], 1), _c("div", {
    staticClass: "chart-item"
  }, [_c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h2", [_vm._v("近30天收客走势")])]), _c("div", {
    staticClass: "turnover-box flex"
  }, [_c("div", {
    style: {
      width: "100%",
      height: "390px"
    },
    attrs: {
      id: "chart"
    }
  })])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };