var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("FormBase", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planNumber", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.changeStartTripDate
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": _vm.changeEndTripDate
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    model: {
      value: _vm.searchParams.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserId", $$v);
      },
      expression: "searchParams.outOPUserId"
    }
  }), _c("div", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 搜索 ")]), _c("BtnMore", {
    attrs: {
      visible: _vm.visibleMore
    },
    on: {
      "update:visible": function ($event) {
        _vm.visibleMore = $event;
      },
      "on-click": function ($event) {
        _vm.visibleMore = !_vm.visibleMore;
      }
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  }), _c("SelectBase", {
    attrs: {
      label: "审核状态",
      type: "examineSearch"
    },
    model: {
      value: _vm.searchParams.auditProgress,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditProgress", $$v);
      },
      expression: "searchParams.auditProgress"
    }
  }), _c("InputBase", {
    attrs: {
      label: "游客信息",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.memberKey,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "memberKey", $$v);
      },
      expression: "searchParams.memberKey"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调"
    },
    model: {
      value: _vm.searchParams.dutyOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserId", $$v);
      },
      expression: "searchParams.dutyOPUserId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      disabled: !_vm.hasCustomerInfo
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  }), _c("SelectGuide", {
    attrs: {
      label: "导游"
    },
    on: {
      "on-select": _vm.selectGuide
    },
    model: {
      value: _vm.searchParams.guideName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideName", $$v);
      },
      expression: "searchParams.guideName"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "计划类型",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  }), _c("InputBase", {
    attrs: {
      label: "签单号"
    },
    model: {
      value: _vm.searchParams.signNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "signNumber", $$v);
      },
      expression: "searchParams.signNumber"
    }
  })], 1), _c("transition", {
    attrs: {
      name: "fade-down"
    }
  }, [_c("FormBase", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleMore,
      expression: "visibleMore"
    }]
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "流水号"
    },
    model: {
      value: _vm.searchParams.orderNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderNumber", $$v);
      },
      expression: "searchParams.orderNumber"
    }
  }), _c("InputDepartment", {
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }), _c("InputBase", {
    attrs: {
      label: "团队等级"
    },
    model: {
      value: _vm.searchParams.level,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "level", $$v);
      },
      expression: "searchParams.level"
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "线路"
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.searchParams.productId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productId", $$v);
      },
      expression: "searchParams.productId"
    }
  })], 1)])], 1)], 1), _c("TableSingle", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    },
    on: {
      reload: _vm.fetchData
    }
  }), _vm.tableData.length && _vm.statistics ? _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "number",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAdultCount) + "大" + _vm._s(_vm.statistics.totalChildCount) + "小" + _vm._s(_vm.statistics.totalAccompanyCount) + "陪 ")];
      }
    }], null, false, 2373878242)
  }) : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };