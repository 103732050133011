export default {
  data() {
    return {
      visible: false,
      info: null,
      drivingLicensePic: '',
      operationQualificationCertificatePic: ''
    };
  },
  computed: {
    getImageDriver() {
      return this.drivingLicensePic;
    },
    getImageCert() {
      return this.operationQualificationCertificatePic;
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.info = data;
      this.processImage(data);
    },
    hide() {
      this.visible = false;
    },
    getFullImage(path) {
      return this.$store.dispatch('resource/common/getOssImage', path);
    },
    processImage(data) {
      const {
        drivingLicensePic,
        operationQualificationCertificatePic
      } = data;
      this.drivingLicensePic = '';
      this.operationQualificationCertificatePic = '';
      if (drivingLicensePic) {
        this.getFullImage(drivingLicensePic).then(res => {
          if (res) {
            this.drivingLicensePic = res[0];
          }
        });
      }
      if (operationQualificationCertificatePic) {
        this.getFullImage(operationQualificationCertificatePic).then(res => {
          if (res) {
            this.operationQualificationCertificatePic = res[0];
          }
        });
      }
    }
  }
};