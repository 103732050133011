import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchRestaurant, updateRestaurant, deleteRestaurant, deleteRestaurantItem, fetchRestaurantOrder, updateRestaurantOrder, deleteRestaurantOrder, fetchRestaurantOrderList, selectRestaurantOrderList, deleteRestaurantOrderItem } from '@/api/plan/restaurant';
const dfaultState = () => ({
  restaurantData: null,
  restaurantOrderData: null
});
const state = dfaultState();
const getters = {
  restaurantData: state => state.restaurantData,
  restaurantOrderData: state => state.restaurantOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // const { planId } = params
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchRestaurant(params).then(res => {
      const {
        data
      } = res;
      // commit('RESTAURANT_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return updateRestaurant(opts).then(res => {
      const {
        data
      } = res;
      // this.dispatch('fetch')
      // commit('RESTAURANT_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return deleteRestaurant(ids).then(res => {
      const {
        data
      } = res;
      // commit('RESTAURANT_DELETE', { planId, ids })
      this.dispatch('fetch');
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteRestaurantItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchRestaurantOrder(params).then(res => {
      const {
        data
      } = res;
      commit('RESTAURANT_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateRestaurantOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteRestaurantOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteRestaurantOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchRestaurantOrderList({
    commit
  }, opts) {
    return fetchRestaurantOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectRestaurantOrderList({
    commit
  }, opts) {
    return selectRestaurantOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  RESTAURANT_DATA: (state, {
    planId,
    data
  }) => {
    state.restaurantData = data;
  },
  RESTAURANT_DELETE: (state, {
    planId,
    ids
  }) => {
    let restaurantList = state.restaurantData;
    const list = [];
    restaurantList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.restaurantData = list;
  },
  RESTAURANT_ORDER_DATA: (state, data) => {
    state.restaurantOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};