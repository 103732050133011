export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    getShuttleType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'pick' ? '接团' : type === 'send' ? '送团' : '市内中转';
      };
    }
  }
};