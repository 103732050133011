import { orderAuditStatus, orderAuditTagStatus } from '@/config/order';

// 团状态/审核状态
export default {
  name: 'StatusPlan',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    orderAuditStatus,
    orderAuditTagStatus
  })
};