var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "booking-item booking-scenic"
  }, [_c("TopTitle", {
    attrs: {
      title: _vm.tableTitle,
      loading: _vm.btnLoading
    },
    on: {
      "on-add": _vm.handleAdd
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.useDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "useDate", $$v);
            },
            expression: "scope.row.useDate"
          }
        })];
      }
    }, {
      key: "company",
      fn: function ({
        scope
      }) {
        return [_c("InputResource", {
          attrs: {
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectResource(scope.row, $event);
            }
          },
          model: {
            value: scope.row.companyName,
            callback: function ($$v) {
              _vm.$set(scope.row, "companyName", $$v);
            },
            expression: "scope.row.companyName"
          }
        })];
      }
    }, {
      key: "scenicName",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            label: "",
            list: _vm.bindScenicList,
            disabled: _vm.hasBooked(scope.row)
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectScenic(scope.row, $event);
            }
          },
          model: {
            value: scope.row.scenicName,
            callback: function ($$v) {
              _vm.$set(scope.row, "scenicName", $$v);
            },
            expression: "scope.row.scenicName"
          }
        })];
      }
    }, {
      key: "ticketType",
      fn: function ({
        scope
      }) {
        return [scope.row.visible ? _c("SelectBase", {
          attrs: {
            list: scope.row.ticketList,
            disabled: _vm.hasBooked(scope.row),
            label: ""
          },
          model: {
            value: scope.row.ticketType,
            callback: function ($$v) {
              _vm.$set(scope.row, "ticketType", $$v);
            },
            expression: "scope.row.ticketType"
          }
        }) : _vm._e()];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            "controls-position": "right",
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnBase", {
          attrs: {
            type: "delete",
            disabled: _vm.hasBooked(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };