import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import TableNative from '@/components/common/Table/TableNative';
import { parseDate } from '@/utils';
import { processAdd } from '@/utils/number';
const columnTrip = [{
  attrs: {
    label: '大团号',
    prop: 'planNumber',
    width: 120
  }
}, {
  attrs: {
    label: '导游姓名',
    prop: 'guideName',
    width: 90
  }
}, {
  attrs: {
    label: '申请金额(元)',
    prop: 'applyMoney',
    width: 90
  }
}, {
  attrs: {
    label: '已借金额(元)',
    prop: 'borrowedMoney',
    width: 90
  }
}, {
  attrs: {
    label: '操作计调',
    prop: 'dutyOPUserName',
    width: 90
  }
}, {
  attrs: {
    label: '审批人',
    prop: 'applyUserName',
    width: 120
  }
}, {
  attrs: {
    label: '备注',
    prop: ''
  }
}];
export default {
  components: {
    TableNative
  },
  data() {
    return {
      columnTrip,
      data: null,
      printDate: parseDate()
    };
  },
  computed: {
    total() {
      return this.$store.getters['bill/guideBorrow/total'];
    }
  },
  created() {
    this.fetchData(this.$route.query);
  },
  methods: {
    fetchData(params = {}) {
      return this.$store.dispatch('bill/guideBorrow/fetchList', params).then(data => {
        if (!data) return;
        this.data = this.formatData(data);
      }).then(() => {
        this.handlePrint();
      });
    },
    formatData(data) {
      if (!data.list) return;
      data.list = data.list.map(it => {
        const {
          borrows
        } = it;
        const applyUserName = borrows.reduce((acc, cur) => {
          acc += cur.dingTalkOPName || cur.createUserName;
          return acc;
        }, '');
        const uniqueStr = [...new Set(applyUserName)].join('');
        it.applyUserName = uniqueStr;
        it.borrowedMoney = borrows.reduce((acc, cur) => (acc, processAdd(acc, cur.borrowMoney)), 0);
        return it;
      });
      return data;
    },
    handlePrint() {
      const style = document.createElement('style');
      style.innerHTML = '@page { size: a4 auto; margin-top: 1.5cm; margin-bottom: 0.5cm; } @page:first { margin-top: 2.5cm } @page:left { margin-left: 1.75cm; margin-right: 0.75cm } @page:right { margin-left: 1.75cm; margin-right: 0.75cm} ';
      style.media = 'print';
      document.head.appendChild(style);

      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
          window.close();
        });
      }, 1000);
    }
  }
};