import "core-js/modules/es.array.reduce.js";
export const OrderResPlanEnum = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  MODIFIED: 'MODIFIED',
  ARRANGED: 'ARRANGED',
  WAIT_CANCEL: 'WAIT_CANCEL',
  CANCELED: 'CANCELED'
};
export const OrderResPlanEnumLabel = {
  NEW: '新计划',
  CONFIRMED: '已确认',
  MODIFIED: '已修改',
  ARRANGED: '已安排',
  WAIT_CANCEL: '待取消',
  CANCELED: '已取消'
};

// 下拉选择
export const OrderResPlanEnumSelect = Object.keys(OrderResPlanEnum).reduce((acc, key) => {
  const item = {
    label: OrderResPlanEnumLabel[key],
    value: key
  };
  acc = [...acc, item];
  return acc;
}, []);