import TableNative from '@/components/common/Table/TableNative';
import RemarkList from '../../components/RemarkList';
import { typeMixin } from '@/mixin';
import utilsMixin from './utilsMixin';
export default {
  components: {
    TableNative,
    RemarkList
  },
  mixins: [typeMixin, utilsMixin]
};