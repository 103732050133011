import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/transport/findList',
    method: 'get',
    params
  });
}
// 列表（集团）
export function fetchListGroup(params) {
  return request({
    url: '/resource/transport/group-objects',
    method: 'get',
    params
  });
}

// 列表
export function fetchTraffic(params) {
  return request({
    url: '/resource/transport/page',
    method: 'post',
    params
  });
}

// 更新
export function createOrUpdateTraffic(data) {
  return request({
    url: '/resource/transport/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteTraffic(id) {
  return request({
    url: '/resource/transport/del',
    method: 'post',
    data: {
      transportCompanyId: id
    }
  });
}