import { orderInfo, tripDate, orderArrangeRemark, orderOther, orderTotalPay, guide, payed, unPay, createTime, actionItem } from './index';
const lastItem = [orderArrangeRemark, orderOther, orderTotalPay, payed, unPay, createTime, actionItem];

// 按团号去付款 餐厅
export const RestaurantColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'restaurantName',
    label: '餐厅',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'mealTypeLabel',
    label: '类别',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'restaurantType',
    label: '类型',
    'min-width': 80
  }
}, guide, {
  attrs: {
    prop: 'price',
    label: '价格'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, ...lastItem];