/* eslint-disable no-unused-vars */
import { fetchScenic, updateScenic, deleteScenic, deleteScenicItem, fetchScenicOrder, updateScenicOrder, deleteScenicOrder, fetchScenicOrderList, selectScenicOrderList, deleteScenicOrderItem, fetchScenicPlanList, fetchScenicAgreementPrice } from '@/api/plan/scenic';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
import { formatTableData } from '@/utils/data';
const dfaultState = () => ({
  scenicData: null,
  currentScenicData: null,
  scenicOrderData: null
});
const state = dfaultState();
const getters = {
  scenicData: state => state.scenicData,
  scenicOrderData: state => state.scenicOrderData,
  currentScenicData: state => state.currentScenicData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchScenic(params).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(item => {
        item.respOrderScenicTickets = formatTableData(item.respOrderScenicTickets);
        item.reqOrderScenicTickets = formatTableData(item.reqOrderScenicTickets);
        return item;
      });
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    return updateScenic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    return deleteScenic(ids).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteScenicItem(id).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchScenicOrder(params).then(res => {
      const {
        data
      } = res;
      commit('SCENIC_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateScenicOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteScenicOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteScenicOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchScenicOrderList({
    commit
  }, opts) {
    return fetchScenicOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectScenicOrderList({
    commit
  }, opts) {
    return selectScenicOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取景区协议价格及人数
  fetchAgreementPrice({
    commit
  }, opts) {
    return fetchScenicAgreementPrice(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取计划中的景区列表
  fetchScenicPlanList({
    commit
  }, opts) {
    const {
      planId
    } = opts;
    return fetchScenicPlanList(opts).then(res => {
      const data = formatCharData(res.data);
      commit('CUR_SCENIC_DATA', data);
      return data;
    });
  }
};
const mutations = {
  SCENIC_DATA: (state, data) => {
    state.scenicData = data;
  },
  SCENIC_ORDER_DATA: (state, data) => {
    state.scenicOrderData = data;
  },
  CUR_SCENIC_DATA: (state, data) => {
    state.currentScenicData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};