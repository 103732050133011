export const ColumnsNotice = [{
  attrs: {
    prop: 'notifyType',
    label: '通知类型'
  }
}, {
  attrs: {
    prop: 'title',
    label: '标题'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'isRead',
    label: '状态'
  },
  slot: 'isRead'
}];