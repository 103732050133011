import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, DelCheck, DelCheckAll, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectPayType, SelectTraffic, SelectDictionary } from '@/components/common';
import { SelectPlanNumber } from '@/components/common';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import { planEditTraffic } from '@/config/plan';
import { AuditRole, TYPES } from '@/config/audit';
import { mapGetters } from 'vuex';
import { calcMergeRowTotalByKey } from '@/utils';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { defaultState, itemListKeys } from './config';
const itemMergeKeys = {
  orderDate: 'orderDate',
  // transportType: 'transportType',
  destinationPosition: 'destinationPosition',
  shiftNumber: 'shiftNumber',
  startPosition: 'startPosition'
};
export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    TopBar,
    SelectPlanNumber,
    DelCheck,
    DelCheckAll,
    StatusAudit,
    SelectPayType,
    SelectTraffic,
    SelectDictionary,
    DialogOrder,
    DialogList,
    PaymentTotal,
    AuditRoleAll,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'transportOrderStatus',
      deletePath: 'plan/traffic/delete',
      updatePath: 'plan/traffic/update',
      fetchPath: 'plan/traffic/fetch',
      rowId: 1000000000000,
      auditAll: false,
      isOrderInfo: true,
      hasMergeTail: true,
      headMergeNum: 7,
      tailMergeNum: planEditTraffic.length - 5
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      planOrderData: 'plan/index/planOrderData',
      trafficData: 'resource/traffic/trafficData'
    })
  },
  watch: {
    trafficData: {
      handler: function (data) {
        if (!data) this.fetchTraffiData();
      },
      immediate: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    // objectSpanMethod,
    fetchTraffiData() {
      this.$store.dispatch('resource/traffic/fetchList');
    },
    selectType(row, val) {
      console.log('val.label:', val.label);
      row.transportType = val.label;
    },
    initData(data) {
      if (!data) return;
      // 是否显示订单信息
      if (!this.hasOrderInfo) {
        this.customerOrderId = this.planOrderData[0].value;
      }
      this.tableData = this.formatData(data);
      this.resetData();
    },
    // 当前添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      const {
        id
      } = row;
      let {
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/traffic/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false,
          isChecked: false,
          ...itemListKeys(),
          transportType: row.transportType
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.resetData();
    },
    // 新增一条数据
    handlePlus() {
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: this.planInfo.adultCount || 1
      });
      this.resetData();
    },
    // 删除景点门票
    deleteRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        isCustom
      } = row;
      !isCustom ? this.$store.dispatch(this.deletePath, {
        id
      }).then(() => {
        this.fetchData();
      }) : this.tableData.splice(index, 1);
      this.resetData();
    },
    // 计算总额
    calcRowTotalMoney(obj) {
      const {
        price,
        count,
        otherFee
      } = obj;
      let total = price * count + Number(otherFee);
      return total;
    },
    handleSelect(row, val) {
      row.transportCompanyId = val.id;
      row.transportCompanyName = val.label;
    },
    changePayType(row, val) {
      row.payType = val;
    },
    // 校验输入的值
    changeInput(item) {
      item.rowTotal = this.calcRowTotalMoney(item);
      this.mergeRowMoney(); // 合并单条数据总价
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.orderTransportCompanyTypes || [];
        const {
          id,
          auditProgress,
          cashType,
          customerOrderId,
          checkAccountStatus
        } = it;
        const item = {
          ...it,
          rowId: id,
          planTransportId: id,
          customerOrderId,
          isChecked: false,
          isCustom: false,
          ap: auditProgress,
          auditProgress,
          checkAccountStatus,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          cashType: cashType.toLowerCase() || 'sign',
          payType: cashType.toLowerCase() || 'sign'
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const rowTotal = this.calcRowTotalMoney(v);
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              rowTotal
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    processItemList(newItem, oldItem) {
      Object.keys(itemMergeKeys).forEach(key => {
        newItem[key] = oldItem[key];
      });
      return newItem;
    },
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 定义子项目的列表
        it.transportCompanyTypes = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.planTransportId;
        it.cashType = it.cashType.toUpperCase();
        if (!this.isOrderInfo) {
          it.customerOrderId = this.customerOrderId;
        }
        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planTransportId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          // 处理多条合并的数据
          const obj = this.processItemList(item, last.transportCompanyTypes[0]);
          last.transportCompanyTypes.push(obj);
        } else {
          it.transportCompanyTypes.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          // customerOrderId,
          // orderDate,
          transportCompanyId,
          count,
          price
        } = row;
        // if (this.isOrderInfo && !customerOrderId) {
        //   this.$bus.tip({ type: 'warning', message: '请选择订单号！' })
        //   return false
        // }

        if (!transportCompanyId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择交通公司！'
          });
          return false;
        }
        // if (!orderDate) {
        //   this.$bus.tip({ type: 'warning', message: '请选择日期' })
        //   return false
        // }
        if (!count) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写数量'
          });
          return false;
        }
        if (!price) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写价格'
          });
          return false;
        }
      }
      return true;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        it.totalPay = totalPay;
      });
    },
    resetData() {
      // this.getTotalPayMoney()
      this.mergeRowMoney(); // 合并单条数据总价
      this.rowspan();
    },
    mergeRowMoney() {
      this.tableData = calcMergeRowTotalByKey(this.tableData, 'rowTotal', '$total');
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.transport,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};