export const MenuBtnList = [{
  name: '目标、业绩',
  children: [{
    name: '客户目标',
    code: 'analy:target:custom',
    url: '/system/customGoal'
  }, {
    name: '公司目标',
    code: 'analy:target:company',
    url: '/analysis/target-company'
  }, {
    name: '部门目标',
    code: 'analy:target:department',
    url: '/analysis/target-department'
  }, {
    name: '员工目标',
    code: 'analy:target:staff',
    url: '/analysis/target-staff'
  }, {
    name: '个人业绩',
    code: 'analy:performance',
    url: '/analysis/performance'
  }, {
    name: '部门业绩',
    code: 'analy:department',
    url: '/analysis/department'
  }]
}, {
  name: '应收款、营业额、人数、毛利',
  children: [{
    name: '应收分析表',
    code: 'analy:income',
    url: '/analysis/income'
  }, {
    name: '营业额/人数/毛利总表',
    code: 'analy:custom-kpi',
    url: '/analysis/custom-kpi'
  }, {
    name: '团型分析表',
    code: 'analy:group',
    url: '/analysis/group'
  }, {
    name: '省份人数表',
    code: 'analy:member-map',
    url: '/analysis/member-map'
  }]
}, {
  name: '线路',
  children: [{
    name: '线路分析表',
    code: 'analy:line',
    url: '/analysis/line'
  }]
}, {
  name: '区域',
  children: [{
    name: '区域人数/营业额/毛利总表',
    code: 'analy:area',
    url: '/analysis/area'
  }]
}, {
  name: '客户',
  children: [{
    name: '客户分析表',
    code: 'analy:custom',
    url: '/analysis/custom'
  }]
}, {
  name: '业务员',
  children: [{
    name: '业务员分析表',
    code: 'analy:sale',
    url: '/analysis/sale'
  }]
}, {
  name: '购物店',
  children: [{
    name: '购物店分析表',
    code: 'analy:shop',
    url: '/analysis/shop'
  }, {
    name: '土特产分析表',
    code: 'analy:soil:shop',
    url: '/analysis/soilShop'
  }]
}, {
  name: '导游',
  children: [{
    name: '导游分析表',
    code: 'analy:guide',
    url: '/analysis/guide'
  }]
}, {
  name: '游客',
  children: [{
    name: '年龄段分析表',
    code: 'analy:member-age',
    url: '/analysis/member-age'
  }]
}];