var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "团款修改情况",
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("CollapseBase", {
    attrs: {
      title: "团款",
      "is-strong": ""
    }
  }, [_vm.visible ? _c("DetailFund", {
    attrs: {
      id: _vm.form.id,
      "is-review": _vm.form.isReview,
      "is-dialog": true
    }
  }) : _vm._e()], 1), _c("CollapseBase", {
    attrs: {
      title: "返款",
      "is-strong": ""
    }
  }, [_vm.visible ? _c("DetailRefund", {
    attrs: {
      id: _vm.form.id,
      "is-review": _vm.form.isReview,
      "is-dialog": true
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };