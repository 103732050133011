export function formatItinerarys(list, type) {
  if (list.length) {
    // 重组景区
    return list.map(it => {
      const scenics = formatScenic(it.scenics);

      // 重组购物店 （购物店字段可能不一样）
      const keys = ['shops', 'shopVoList'];
      let shopItem;
      keys.forEach(itemKey => {
        if (it[itemKey]) {
          shopItem = formatShop(it[itemKey]);
        }
      });
      const itemItiner = [...scenics, ...shopItem].sort(function (a, b) {
        return a.orderNumber - b.orderNumber; //正序
      });
      const itineraryData = formatItinerary(itemItiner).filter(v => v != '').join('');
      let hotelInfo = '';
      if (type == 'hotel') {
        hotelInfo = it.hotels.map(item => {
          let its = '';
          if (item.hotelId < 1) {
            its = item.hotelArea + item.hotelStandard;
          }
          if (item.hotelId > 0) {
            its = item.hotelName;
          }
          return its;
        });
      }
      const isPick = it.isPick ? '（接团）' : '';
      const isSend = it.isSend ? '（送团）' : '';
      it.itineraryDesc = '';
      it.itineraryDesc = isPick + itineraryData + it.selfScenic + isSend;
      if (type == 'hotel') {
        it.itineraryDesc = hotelInfo ? `【住宿：${hotelInfo}】` + it.itineraryDesc : it.itineraryDesc;
      }
      return it;
    });
  }
}
export function formatScenic(scenics) {
  return scenics.map(it => {
    if (!it.scenicName) return {};
    const item = {
      name: it.scenicName,
      orderNumber: it.orderNumber
    };
    return item;
  });
}
export function formatShop(shops, key = 'shopName') {
  return shops.map(it => {
    if (!it[key]) return '';
    const item = {
      name: it[key],
      orderNumber: it.orderNumber
    };
    return item;
  });
}

// 计划单打印
export function planFormatItinerarys(list) {
  if (list.length) {
    return list.map(it => {
      // 重组景区
      const scenics = formatScenic(it.itineraryScenics);
      // 重组购物店
      const shopItem = formatShop(it.shopVos, 'name');
      const itemItiner = [...scenics, ...shopItem].sort(function (a, b) {
        return a.orderNumber - b.orderNumber; //正序
      });
      const itineraryData = formatItinerary(itemItiner).filter(v => v != '').join('');
      const isPick = it.isPick ? '（接团）' : '';
      const isSend = it.isSend ? '（送团）' : '';
      it.scenicName = '';
      it.scenicName = isPick + itineraryData + it.selfScenic + isSend;
      return it;
    });
  }
}
export function formatItinerary(item) {
  return item.map(it => {
    if (!it.name) return '';
    return '【' + it.name + '】';
  });
}