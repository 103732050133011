import { PieItem } from '@/components/business/echart';
export default {
  components: {
    PieItem
  },
  props: {
    pieData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visible: true
    };
  },
  watch: {
    pieData: {
      handler: function (d) {
        if (!d) return;
        this.visible = false;
        setTimeout(() => {
          this.visible = true;
        }, 20);
      },
      immediate: true
    }
  },
  methods: {}
};