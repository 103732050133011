import SealImage from '@/views/print/components/SealImage';
import TopTitle from '../../components/TopTitle';
import TableSingle from '../../components/TableSingle';
import TableProfit from '../../components/TableProfit';
import { PersonCount } from '@/components/common';
import { customerInfoMixin } from '@/mixin';
import { hasCustomeAuth } from '@/utils';
import { feeList, collectList, subsidieList, shopPlans, scenicAdd, otherIncome, busFee, hotels, scenicsCost, restaurants, insurances, transportCompanys, otherPays, shopsPays, scenicAddPay, guidedFee, columnData1, columnData2 } from './config';
export default {
  components: {
    TopTitle,
    PersonCount,
    TableSingle,
    TableProfit,
    SealImage
  },
  //TableProfit
  mixins: [customerInfoMixin],
  data() {
    return {
      feeList,
      collectList,
      subsidieList,
      shopPlans,
      scenicAdd,
      otherIncome,
      busFee,
      hotels,
      scenicsCost,
      restaurants,
      insurances,
      transportCompanys,
      otherPays,
      shopsPays,
      scenicAddPay,
      guidedFee,
      columnData1,
      columnData2,
      teamData: {},
      show: false,
      data: {
        feeList: [],
        guides: [],
        shops: [],
        scenicAdd: [],
        otherIncomes: [],
        busFee: [],
        hotels: [],
        scenicsCost: [],
        restaurants: [],
        otherPays: [],
        scenicAddPay: []
      },
      teamFund: [],
      addIncome: []
    };
  },
  computed: {
    visibleCustom() {
      return hasCustomeAuth(this.$store);
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchPlanProfitReport', {
        planId: id
      }).then(data => {
        if (data.guides.length) {
          data.guideName = data.guides[0].guideName;
        }
        this.data = {
          ...this.data,
          ...data
        };
        this.teamData = data;
        // 购物收入/购物支出
        if (data.shops && data.shops.length) {
          this.data.shops = data.shops.map(it => {
            it.shopName = it.name;
            it.totalShopIncome = data.totalShopIncome;
            it.totalShopRebatePay = data.totalShopRebatePay;
            it.goods.forEach(its => {
              its.moneyPay = Number(its.guideRebate) + Number(its.driverRebate) + Number(its.accompanyRebate);
            });
            return it;
          });
          this.shopPlans[0].rowspan = this.data.shops.length + 1;
          this.shopPlans[this.shopPlans.length - 1].cRow = this.data.shops.length;
          this.shopsPays[0].rowspan = this.data.shops.length + 1;
          this.shopsPays[this.shopsPays.length - 1].cRow = this.data.shops.length;
        }
        // 景区成本和加点收入/加点支出
        if (data.scenics && data.scenics.length) {
          // 加点
          const AddScenics = data.scenics.filter(it => it.isAddScenic);
          this.data.scenicAdd = AddScenics.length ? AddScenics.map(it => {
            it.totalScenicExtraIncome = data.totalScenicExtraIncome;
            it.totalScenicExtraPay = data.totalScenicExtraPay + data.totalScenicExtraRebatePay;
            it.moneyPay = Number(it.driverCommission) + Number(it.accompanyCommission) + Number(it.guideCommission);
            it.tickets.forEach(its => {
              its.realMoney = its.sellPrice * its.adultCountReceive + its.childCountReceive * its.childPrice + its.otherFee;
            });
            return it;
          }) : [];
          if (AddScenics.length) {
            // 加点成本
            this.scenicAdd[0].rowspan = this.data.scenicAdd.length + 1;
            this.scenicAdd[this.scenicAdd.length - 1].cRow = this.data.scenicAdd.length;

            // 加点支出
            this.scenicAddPay[0].rowspan = this.data.scenicAdd.length + 1;
            this.scenicAddPay[this.scenicAddPay.length - 1].cRow = this.data.scenicAdd.length;
          }

          // 景区
          const scenicsCost = data.scenics.filter(it => !it.isAddScenic);
          this.data.scenicsCost = scenicsCost.length ? scenicsCost.map(it => {
            it.totalScenicInnerQuotePay = data.totalScenicInnerQuotePay;
            return it;
          }) : [];
          if (scenicsCost.length) {
            this.scenicsCost[0].rowspan = this.data.scenicsCost.length + 1;
            this.scenicsCost[this.scenicsCost.length - 1].cRow = this.data.scenicsCost.length;
          }
        }

        // 其他收入
        if (data.otherIncomes && data.otherIncomes.length) {
          this.data.otherIncomes = data.otherIncomes.map(it => {
            it.totalOtherIncome = data.totalOtherIncome;
            return it;
          });
          this.otherIncome[0].rowspan = this.data.otherIncomes.length + 1;
          this.otherIncome[this.otherIncome.length - 1].cRow = this.data.otherIncomes.length;
        }
        // 成本明细 车队
        if (data.busTypes && data.busTypes.length) {
          this.data.busTypes = data.busTypes.map(it => {
            it.total = Number(it.busFee) + Number(it.otherFee);
            it.totalBusCompanyPay = data.totalBusCompanyPay + data.totalShuttlePay;
            return it;
          });
          this.busFee[0].rowspan = this.data.busTypes.length + 1;
          this.busFee[this.busFee.length - 1].cRow = this.data.busTypes.length;
        }

        // 成本明细 房费
        if (data.hotels && data.hotels.length) {
          this.data.hotels = data.hotels.map(it => {
            it.totalHotelQuotePay = data.totalHotelQuotePay;
            return it;
          });
          this.hotels[0].rowspan = this.data.hotels.length + 1;
          this.hotels[this.hotels.length - 1].cRow = this.data.hotels.length;
        }

        // 成本明细 餐饮
        if (data.restaurants && data.restaurants.length) {
          this.data.restaurants = data.restaurants.map(it => {
            it.types.forEach(its => {
              its.money = its.count * its.price + its.otherFee;
            });
            it.totalRestaurantPay = data.totalRestaurantPay;
            return it;
          });
          this.restaurants[0].rowspan = this.data.restaurants.length + 1;
          this.restaurants[this.restaurants.length - 1].cRow = this.data.restaurants.length;
        }

        // 成本明细 保险
        if (data.insurances && data.insurances.length) {
          this.data.insurances = data.insurances.map(it => {
            it.totalInsurancePay = data.totalInsurancePay;
            return it;
          });
          this.insurances[0].rowspan = this.data.insurances.length + 1;
          this.insurances[this.insurances.length - 1].cRow = this.data.insurances.length;
        }

        // 成本明细 大交通
        if (data.transportCompanys && data.transportCompanys.length) {
          this.data.transportCompanys = data.transportCompanys.map(it => {
            it.totalTransportCompanyPay = data.totalTransportCompanyPay;
            return it;
          });
          this.transportCompanys[0].rowspan = this.data.transportCompanys.length + 1;
          this.transportCompanys[this.transportCompanys.length - 1].cRow = this.data.transportCompanys.length;
        }

        // 成本明细 其他支出
        if (data.otherPays && data.otherPays.length) {
          this.data.otherPays = data.otherPays.map(it => {
            it.totalOtherPay = data.totalOtherPay;
            return it;
          });
          this.otherPays[0].rowspan = this.data.otherPays.length + 1;
          this.otherPays[this.otherPays.length - 1].cRow = this.data.otherPays.length;
        }

        // 成本明细 其他支出
        if (data.guides && data.guides.length) {
          this.data.guides = data.guides.map(it => {
            it.totalGuideServiceFeePay = data.totalGuideServiceFeePay;
            it.adultCount = data.adultCount;
            return it;
          });
          this.guidedFee[0].rowspan = this.data.guides.length + 1;
          this.guidedFee[this.guidedFee.length - 1].cRow = this.data.guides.length;
        }
        // this.data.feeList = this.fomratData(this.data.orderCustomers, 'fees')

        // // 收入明细  客户团款 / 客户代收 / 客户返款
        if (this.data.orderCustomers && this.data.orderCustomers.length) {
          this.data.orderCustomers = this.data.orderCustomers.map(it => {
            it.people = `${it.adultCount}大${it.childCount}小${it.accompanyCount}陪`;

            // 判断是否开启客户查看权限
            if (!this.hasCustomerInfo) {
              it.customerName = '';
            }
            return it;
          });
          this.feeList[0].rowspan = this.data.orderCustomers.length + 1;
          this.collectList[0].rowspan = this.data.orderCustomers.length + 1;
          this.subsidieList[0].rowspan = this.data.orderCustomers.length + 1;
        }
        this.show = true;
        this.$emit('onMounted');
      });
    }
  }
};