// 60甲子天干地支
// import { toString, getDaysBetween, getSecondsBetween } from './index'

// 设定基准起位
const TIAN_GAN = '甲,乙,丙,丁,戊,己,庚,辛,壬,癸'.split(',');
const DI_ZHI = '子,丑,寅,卯,辰,巳,午,未,申,酉,戌,亥'.split(',');
const SHU_XIANG = '猪,鼠,牛,虎,兔,龙,蛇,马,羊,猴,鸡,狗'.split(',');
const HE3 = ['猴龙鼠', '马狗虎', '猪羊兔', '鸡牛蛇'];
const HE6 = ['鼠牛', '虎猪', '狗兔', '龙鸡', '猴蛇', '马羊'];
const CHONG = ['鼠马', '虎猴', '兔鸡', '龙狗', '蛇猪'];
const HAI = ['子与未', '丑与午', '寅与巳', '卯与辰', '申与亥', '酉与戌'];

// const JIA_ZHI60 = {
//         1:"甲子", 2:"乙丑", 3:"丙寅", 4:"丁卯", 5:"戊辰", 6:"己巳", 7:"庚午", 8:"辛未", 9:"壬申", 10:"癸酉",
//         11:"甲戌", 12:"乙亥", 13:"丙子", 14:"丁丑", 15:"戊寅", 16:"己卯", 17:"庚辰", 18:"辛巳", 19:"壬午", 20:"癸未",
//         21:"甲申", 22:"乙酉", 23:"丙戌", 24:"丁亥",25:"戊子", 26:"己丑", 27:"庚寅", 28:"辛卯", 29:"壬辰", 30:"癸巳",
//         31:"甲午", 32:"乙未", 33:"丙申", 34:"丁酉", 35:"戊戌", 36:"己亥", 37:"庚子", 38:"辛丑", 39:"壬寅", 40:"癸卯",
//         41:"甲辰", 42:"乙巳", 43:"丙午", 44:"丁未", 45:"戊申", 46:"己酉", 47:"庚戌", 48:"辛亥",49:"壬子", 50:"癸丑",
//         51:"甲寅", 52:"乙卯", 53:"丙辰", 54:"丁巳", 55:"戊午", 56:"己未",57:"庚申", 58:"辛酉", 59:"壬戌", 60:"癸亥",
// }

// // 从0-23小时 对应的地支, 是固定的 23:00-0:59 子, 1:00-2:59 丑
// const getHourZhi = (n,num=false) => {
//   n = +n
//   let cur = Math.floor((n+1)/2)
//   cur = cur == 12 ? 0 : cur
//   console.log('cur:', cur)
//   num && cur+1
//   return DI_ZHI[cur]
// }

// 获取天干的下标
const getTianGanIndex = year => {
  let num = (year - 3) / 10 + '';
  let res = 1;
  if (num.toString().indexOf('.') > -1) {
    const index = num.indexOf('.');
    res = num.slice(index + 1, index + 2);
  }
  return Number(res) - 1;
};

// 获取地支下标
const getDiZhiIndex = year => {
  let num = (year - 3) / 12 + '';
  let res = 1;
  if (num.toString().indexOf('.') > -1) {
    const index = num.indexOf('.');
    res = num.slice(index + 1, index + 2);
  }
  return Number(res) - 1;
};

// 获取天干
export const getTianGan = year => {
  getTianGanIndex(1962);
  return TIAN_GAN[getTianGanIndex(year)] || TIAN_GAN[TIAN_GAN.length - 1];
};

// 获取地支
export const getDiZhi = year => {
  return DI_ZHI[getDiZhiIndex(year)] || DI_ZHI[DI_ZHI.length - 1];
};

// 推算节气, 默认返回立春  OUT:阳历
// 以1899年12月31日(星期日)为基准日, 之后每一天与之差值为积日
// F = 365.242 * (y – 1900) + 6.2 + 15.22 *x - 1.9 * sin(0.262 * x)  误差 0.05天左右
// 计算第x个节气公式 x = [1,2,3,4,5,...........24]
//                   [小寒,大寒,立春..... ]
const getJieqi = (year, x = 3) => {
  // # 基准日
  if (x < 1 || x > 25) return '';
  if (year < 1900) return '';
  x -= 1;
  const startDT = new Date(1899, 12, 31);
  // # 相差的天数
  // const days = 365.242*(year - 1900) + 6.2 + 15.22*x - 1.9 * Math.sin(0.262*x)
  // console.log('days:', days)

  return startDT;
};
export const getShuxiang = dt => {
  if (!dt || dt == '') return;
  dt = new Date(dt);
  const year = dt.getFullYear();
  if (year < 1900) return '';
  let index = (year + 8) % 12;
  // 当年立春日期
  const sprintDT = getJieqi(year, 3);
  if (dt >= sprintDT) {
    index = (index + 1) % 12;
  }
  return SHU_XIANG[index];
};

// 时干支    IN:datetime type ,OUT:干支
export const getHourGanzhi = dt => {
  dt = new Date(dt);
  const year = dt.getFullYear();
  return getTianGan(year) + getDiZhi(year);
};

// 天干地支某项转化为五行
const ganzhi2Wuxing = gz => {
  const A = '子,亥,寅,卯,巳,午,申,酉,辰,戌,丑,未,甲,乙,丙,丁,戊,己,庚,辛,壬,癸'.split(',');
  const B = '水,水,木,木,火,火,金,金,土,土,土,土,木,木,火,火,火,火,金,金,水,水'.split(',');
  return B[A.indexOf(gz)];
};

// 根据年获取五行属性
export const getYear2Wuxing = year => {
  if (!year) return;
  return ganzhi2Wuxing(getTianGan(year)) + ganzhi2Wuxing(getDiZhi(year));
};
// const ganzhi2Nayin = (gz) => {
//   const nayinDict = {
//       "甲子,乙丑":"海中金", "丙寅,丁卯":"炉中火", "戊辰,己巳":"大林木",
//       "庚午,辛未":"路旁土", "壬申,癸酉":"剑锋金", "甲戌,乙亥":"山头火",
//       "丙子,丁丑":"漳下水", "戊寅,己卯":"城头土", "庚辰,辛巳":"白腊金",
//       "壬午,癸未":"杨柳木", "甲申,乙酉":"泉中水", "丙戌,丁亥":"屋上土",
//       "戊子,己丑":"霹雳火", "庚寅,辛卯":"松柏木", "壬辰,癸巳":"长流水",
//       "甲午,乙未":"砂石金", "丙申,丁酉":"山下火", "戊戌,己亥":"平地木",
//       "庚子,辛丑":"壁上土", "壬寅,癸卯":"金薄金", "甲辰,乙巳":"覆灯火",
//       "丙午,丁未":"天河水", "戊申,己酉":"大驿土", "庚戌,辛亥":"钗环金",
//       "壬子,癸丑":"桑柘木", "甲寅,乙卯":"大溪水", "丙辰,丁巳":"沙中土",
//       "戊午,己未":"天上火", "庚申,辛酉":"石榴木", "壬戌,癸亥":"大海水",
//   }
//   return ""
// }

// 获取今年的地支的生、克、六合、三合、半合、六冲、三刑、子卯刑、自刑、害
export const getYearWuxingVal = (year, next = false) => {
  const nowYear = new Date();

  // 获取今年是什么生肖属相
  let curShuxiang = getShuxiang(nowYear);
  if (next) {
    curShuxiang = getShuxiang(new Date().setFullYear(nowYear.getFullYear() + 1));
  }

  // 获取属相
  const animal = getShuxiang(year);
  const dizhiTourist = getDiZhi(year);
  const dizhiStr = getDiZhi(nowYear.getFullYear());
  const he3Str = HE3.find(val => val.indexOf(curShuxiang) > -1);
  const he6Str = HE6.find(val => val.indexOf(curShuxiang) > -1);
  const chongStr = CHONG.find(val => val.indexOf(curShuxiang) > -1);
  const haiStr = HAI.find(val => val.indexOf(dizhiStr) > -1);
  if (animal == curShuxiang) return '值';
  if (he3Str && he3Str.indexOf(animal) > -1) return '三合';
  if (he6Str && he6Str.indexOf(animal) > -1) return '六合';
  if (chongStr && chongStr.indexOf(animal) > -1) return '冲';
  if (haiStr && haiStr.indexOf(dizhiTourist) > -1) return '害';
};