import { orderLine } from '@/config/order';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      columnData: orderLine,
      tableData: [],
      total: 0,
      searchParams: {
        name: ''
      },
      spanArr: [],
      position: 0,
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId'
    })
  },
  methods: {
    show(startTripDate) {
      this.startTripDate = startTripDate;
      this.visible = true;
      this.fetchProductData();
    },
    hide() {
      this.visible = false;
    },
    fetchProductData(queryParam) {
      this.$store.dispatch('resource/product/fetch', queryParam).then(data => {
        this.handleData(data);
      });
    },
    fetchProductDetail(id) {
      this.$store.dispatch('resource/product/fetchItinerary', id).then(res => {
        this.$parent.commonFunt(res, 'PRODUCT');
        this.hide();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableData = list;
      this.total = totalCount;
    },
    handleSelect(row) {
      const {
        isEnable
      } = row;
      if (isEnable) {
        this.$emit('on-select', row);
      }
      this.hide();
    },
    handleSearch() {
      this.fetchProductData(this.searchParams);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchProductData({
        pageNum,
        pageSize
      });
    }
  }
};