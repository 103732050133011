var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "90px"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("el-form-item", {
    attrs: {
      label: "案例类型",
      prop: "type"
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "caseType"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "操作计调",
      prop: "personInCharge1"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectStatff("personInCharge1", $event);
      }
    },
    model: {
      value: _vm.form.personInCharge1,
      callback: function ($$v) {
        _vm.$set(_vm.form, "personInCharge1", $$v);
      },
      expression: "form.personInCharge1"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客服计调",
      prop: "personInCharge2"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectStatff("personInCharge2", $event);
      }
    },
    model: {
      value: _vm.form.personInCharge2,
      callback: function ($$v) {
        _vm.$set(_vm.form, "personInCharge2", $$v);
      },
      expression: "form.personInCharge2"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "发生时间",
      prop: "occurrenceTime"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.occurrenceTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "occurrenceTime", $$v);
      },
      expression: "form.occurrenceTime"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "事件简介",
      prop: "introduction"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.introduction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "introduction", $$v);
      },
      expression: "form.introduction"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "事件经过",
      prop: "details"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: _vm.autosize
    },
    model: {
      value: _vm.form.details,
      callback: function ($$v) {
        _vm.$set(_vm.form, "details", $$v);
      },
      expression: "form.details"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "造成后果",
      prop: "consequence"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: _vm.autosize
    },
    model: {
      value: _vm.form.consequence,
      callback: function ($$v) {
        _vm.$set(_vm.form, "consequence", $$v);
      },
      expression: "form.consequence"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "问题所在",
      prop: "reason"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: _vm.autosize
    },
    model: {
      value: _vm.form.reason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "处理方式",
      prop: "handlingMethod"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: _vm.autosize
    },
    model: {
      value: _vm.form.handlingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.form, "handlingMethod", $$v);
      },
      expression: "form.handlingMethod"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "完善方案",
      prop: "improveThePlan"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: _vm.autosize
    },
    model: {
      value: _vm.form.improveThePlan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "improveThePlan", $$v);
      },
      expression: "form.improveThePlan"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: ""
    }
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-button", {
    attrs: {
      type: "info",
      plain: ""
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onConfirm
    }
  }, [_vm._v("保存")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.onAudit
    }
  }, [_vm._v("提交审核")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };