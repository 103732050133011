import TableSingle from '@/views/print/components/TableSingle';
const columnData = [{
  rows: [{
    label: '应报'
  }, {
    content: 'totalRemb'
  }, {
    label: '导管费'
  }, {
    content: 'guideManageFee'
  }, {
    label: '录单费'
  }, {
    content: 'recordingFee'
  }, {
    label: '导游基金'
  }, {
    content: 'guideFund'
  }, {
    label: '税'
  }, {
    content: 'guideTax'
  }, {
    label: '押金'
  }, {
    content: 'depositFee'
  }, {
    label: '乐捐(能量包)',
    width: '80px'
  }, {
    content: 'voluntarilyFee'
  }, {
    label: '已报'
  }, {
    content: 'reimbed'
  }, {
    label: '欠报'
  }, {
    content: 'unReimb'
  }]
}];
export default {
  components: {
    TableSingle
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData
    };
  }
};