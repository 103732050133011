import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-unused-vars */
import { fetchGuide, updateGuide, deleteGuide, fetchProxy, updateProxy } from '@/api/plan/guide';
import { setPlanBorrow, delBorrowApply, delBorrowApplyByIds, fetchPlanBorrow, fetchPlanBorrowApprove, powerDeleteApprove, requestDDApply, revokeDDApply, statusDDApply } from '@/api/plan/guide';
import { processAdd } from '@/utils/number';
// import { DEFAULT_PAGE } from '@/config'

function findMainGuide(list) {
  let mainGuide;
  if (list.length) {
    mainGuide = list.find(it => it.reimb);
  }
  return mainGuide;
}
const dfaultState = () => ({
  guideData: null,
  guideBorrowData: null,
  proxyData: null,
  mainGuide: null // 报账导游
});
const state = dfaultState();
const getters = {
  guideData: state => state.guideData,
  guideBorrowData: state => state.guideBorrowData,
  proxyData: state => state.proxyData,
  mainGuide: state => state.mainGuide
};
const actions = {
  // 导游获取
  fetch({
    commit
  }, params) {
    return fetchGuide(params).then(res => {
      const {
        data
      } = res;
      const mainGuide = findMainGuide(data);
      commit('MAIN_GUIDE', mainGuide);
      return data;
    });
  },
  // 导游更新
  update({
    commit
  }, opts) {
    return updateGuide(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导游删除
  delete({
    commit
  }, ids) {
    return deleteGuide(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      return data;
    });
  },
  // 代收列表 获取
  fetchProxy({
    commit
  }, params) {
    const {
      planId
    } = params;
    return fetchProxy(params).then(res => {
      const {
        data
      } = res;
      commit('PROXY_LIST', {
        planId,
        data
      });
      return data;
    });
  },
  // 代收列表 更新
  updateProxy({
    commit
  }, params) {
    return updateProxy(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 超管删除
  powerDeleteBorrowApprove({
    commit
  }, ids) {
    return powerDeleteApprove(ids);
  },
  // 查询计划的导游借款(批准记录)
  fetchPlanBorrowApprove({
    commit
  }, params) {
    return fetchPlanBorrowApprove(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 查询计划的导游借款申请记录
  fetchPlanBorrow({
    commit
  }, params) {
    return fetchPlanBorrow(params).then(res => {
      if (!res || !res.data) return;
      const data = res.data.map(it => {
        const {
          borrows
        } = it;
        const borrowMoney = borrows.reduce((acc, cur) => (acc, processAdd(acc, cur.borrowMoney)), 0);
        const item = {
          ...it,
          borrowMoney
        };
        return item;
      });
      commit('GUIDE_BORROW_DATA', data);
      return data;
    });
  },
  // 新增计划的导游借款申请记录
  setPlanBorrow({
    commit
  }, params) {
    return setPlanBorrow(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 删除导游借款申请
  delBorrowApply({
    commit
  }, params) {
    return delBorrowApply(params);
  },
  // 删除导游借款申请
  delBorrowApplyByIds({
    commit
  }, params) {
    return delBorrowApplyByIds(params);
  },
  // 钉钉申请
  dingdingApply({
    commit
  }, opts) {
    return requestDDApply(opts);
  },
  // 撤销钉钉申请
  dingdingRevoke({
    commit
  }, opts) {
    return revokeDDApply(opts);
  },
  // 钉钉申请状态
  dingdingStatus({
    commit
  }, id) {
    return statusDDApply({
      id
    });
  }
};
const mutations = {
  GUIDE_LIST: (state, data) => {
    state.guideData = data;
  },
  GUIDE_BORROW_DATA: (state, data) => {
    state.guideBorrowData = data;
  },
  GUIDE_DELETE: (state, {
    planId,
    ids
  }) => {
    let guideList = state.guideData;
    const list = [];
    guideList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.guideData = list;
  },
  PROXY_LIST: (state, {
    planId,
    data
  }) => {
    state.proxyData = data;
  },
  MAIN_GUIDE: (state, guide) => {
    state.mainGuide = guide;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};