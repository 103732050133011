var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-page"
  }, [_c("TopTitle", {
    attrs: {
      title: "客人保险名单表"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", _vm._l(_vm.tableHead, function (item, index) {
    return _c("th", {
      key: index,
      attrs: {
        colspan: item.span ? item.span : 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(_vm.tableHead, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        staticClass: "item-content tac",
        attrs: {
          colspan: col.span
        }
      }, [col.slot == "serialnumber" ? [_vm._v(" " + _vm._s(item.serialnumber) + " ")] : _vm._e(), col.slot == "customerTripNumber" ? [_vm._v(" " + _vm._s(item.customerTripNumber) + " ")] : _vm._e(), col.slot == "people" ? [_c("PersonCount", {
        attrs: {
          row: item
        }
      })] : _vm._e(), col.slot == "cardType" ? [_vm._v(" " + _vm._s(_vm.getCardType(item.cardType)) + " ")] : _vm._e(), col.slot == "sex" ? [_vm._v(" " + _vm._s(_vm.getSex(item.sex)) + " ")] : _vm._e(), !col.slot ? _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")]) : _vm._e()], 2)];
    })], 2)];
  })], 2)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };