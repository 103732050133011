var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planCustomerNumber) + " ")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "travelAgencyPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.travelAgencyPay) + " ")];
      }
    }, {
      key: "travelAgencyPayed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.travelAgencyPayed) + " ")];
      }
    }, {
      key: "unPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getUnpay(scope.row)) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.checkAccountRemark) + " ")];
      }
    }, {
      key: "checkAccountTime",
      fn: function ({
        scope
      }) {
        return [scope.row.checkAccountStatus ? _c("span", [_vm._v(" " + _vm._s(scope.row.checkAccountTime) + " ")]) : _vm._e()];
      }
    }, {
      key: "reimbStatus",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.ReimbStatus[scope.row.reimbStatus]) + " ")];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-dropdown", {
          on: {
            command: function ($event) {
              return _vm.handleCommand(scope, $event);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text"
          }
        }, [_vm._v(" 操作"), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            icon: scope.row.checkAccountStatus ? "el-icon-folder-checked" : "el-icon-folder-delete",
            command: "toggleAccountStatus"
          }
        }, [_vm._v(" " + _vm._s(scope.row.checkAccountStatus ? "取消确认" : "确认账单") + " ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-document",
            command: "handleDetail"
          }
        }, [_vm._v(" 报账 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: "handleBillDialog"
          }
        }, [_vm._v(" 整团报账 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-collection",
            command: "handleAdvance"
          }
        }, [_vm._v(" 一键报垫付款 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-notebook-1",
            command: "handleCommission"
          }
        }, [_vm._v(" 一键报佣金 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-finished",
            command: "handleFixData"
          }
        }, [_vm._v(" 修复 ")])], 1)], 1)];
      }
    }, {
      key: "actionHead",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };