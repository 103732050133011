import "core-js/modules/es.array.reduce.js";
import { isPhone, deepClone } from '@/utils';
import { SwitchStatus } from '@/components/common';
import { SelectRestaurant, SelectInsurance, SelectTraffic, SelectFleet, SelectScenic, SelectHotel, SelectCustom, SelectShop } from '@/components/common';
const columns = [{
  attrs: {
    label: '资源类型',
    prop: 'name',
    width: '80'
  }
}, {
  attrs: {
    label: '资源名称',
    prop: 'comp'
  },
  slot: 'comp'
}];
const defaultTable = () => [
// { name: '导游', value: '', comp: 'SelectGuide' },
{
  name: '餐厅',
  comp: 'SelectRestaurant',
  key: 'restaurants'
  // list: [],
  // values: [],
}, {
  name: '保险公司',
  comp: 'SelectInsurance',
  key: 'insurances'
  // list: [],
  // values: [],
}, {
  name: '大交通公司',
  comp: 'SelectTraffic',
  key: 'transports'
  // list: [],
  // values: [],
}, {
  name: '车队',
  comp: 'SelectFleet',
  key: 'busCompanies'
  // list: [],
  // values: [],
}, {
  name: '景区',
  comp: 'SelectScenic',
  key: 'scenics'
  // list: [], values: []
}, {
  name: '酒店',
  comp: 'SelectHotel',
  key: 'hotels'
  // list: [], values: []
}, {
  name: '客户',
  comp: 'SelectCustom',
  key: 'customers'
  // list: [],
  // values: [],
}, {
  name: '购物店',
  comp: 'SelectShop',
  key: 'shops'
  // list: [], values: []
}];
const defaultState = () => ({
  account: '',
  bank: '',
  bankCard: '',
  bankCardType: '',
  code: '',
  name: '',
  contact: '',
  telphone: '',
  mobilePhone: '',
  faxNumber: '',
  remarks: '',
  isEnable: true
});
export default {
  components: {
    SwitchStatus,
    // SelectGuide,
    SelectRestaurant,
    SelectInsurance,
    SelectTraffic,
    SelectFleet,
    SelectScenic,
    SelectHotel,
    SelectCustom,
    SelectShop
  },
  data() {
    const checkPhone = val => {
      if (!val || !isPhone(val)) return false;
      return true;
    };
    return {
      columns,
      visible: false,
      visibleTable: false,
      tableData: defaultTable(),
      rules: {
        name: [{
          required: true,
          message: '名字不能为空',
          trigger: 'blur'
        }],
        contact: [{
          required: true,
          message: '联系人不能为空',
          trigger: 'blur'
        }],
        mobilePhone: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validate: checkPhone,
          message: '手机号格式不正确'
        }],
        code: [{
          required: true,
          message: '公司编码不能为空',
          trigger: 'blur'
        }]
      },
      form: defaultState()
    };
  },
  computed: {
    getTableData() {
      return this.tableData;
    }
  },
  methods: {
    delay() {
      this.visibleTable = false;
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    },
    show() {
      this.visible = true;
      this.tableData = defaultTable();
    },
    edit(item) {
      this.visible = true;
      item.bankCardType = +item.bankCardType;
      this.form = item;
      this.tableData = this.formatTable(item);
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
      this.tableData = defaultTable();
    },
    handleMultiple(row, arr) {
      row.values = arr.filter(it => it != undefined).map(v => v.id);
      row.list = arr;
    },
    formatTable(obj) {
      const list = this.tableData;
      return list.map(it => {
        const {
          key
        } = it;
        it.list = deepClone(obj[key]);
        it.values = obj[key].map(v => v.id);
        return it;
      });
    },
    handleCreate() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const srcObj = this.beforeCommit(this.tableData);
        const opts = {
          ...this.form,
          ...srcObj
        };
        this.$store.dispatch('group/setResource', opts).then(() => {
          this.$bus.tip();
          this.hide();
          this.$emit('reload');
        });
      });
    },
    beforeCommit(arr) {
      return arr.map(it => {
        const {
          list
        } = it;
        if (!list) return it;
        it.obj = list.filter(it => it != undefined).reduce((acc, cur) => {
          acc = [...acc, {
            id: cur.id,
            name: cur.name
          }];
          return acc;
        }, []);
        return it;
      }).reduce((acc, cur) => {
        acc[cur.key] = cur.obj;
        return acc;
      }, {});
    }
  }
};