var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "radio-box"
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label
    }
  }), _c("el-radio-group", _vm._g(_vm._b({}, "el-radio-group", _vm.$attrs, false), _vm.$listeners), [_vm.type === "button" ? _vm._l(_vm.radios, function (item, index) {
    return _c("el-radio-button", {
      key: item.label + "" + index,
      attrs: {
        label: item.label
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }) : _vm._l(_vm.radios, function (item, index) {
    return _c("el-radio", {
      key: item.label + "" + index,
      attrs: {
        disabled: item.disabled,
        label: item.label
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };