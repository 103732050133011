var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单列表-其他收入",
      width: "95%",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "plan-order-dialog"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripInfo", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("Contact", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("Custom", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "shuttle",
      fn: function ({
        scope
      }) {
        return [_c("Shuttle", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "price", $event);
            }
          },
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "adultCount", $event);
            }
          },
          model: {
            value: scope.row.adultCount,
            callback: function ($$v) {
              _vm.$set(scope.row, "adultCount", $$v);
            },
            expression: "scope.row.adultCount"
          }
        })];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope);
            }
          }
        }, [_vm._v(" 移除 ")])];
      }
    }])
  })], 1), _c("div", {
    staticClass: "footer-bar"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOrderDialog
    }
  }, [_vm._v(" 增加订单 ")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存更新 ")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 取消 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };