var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "profit-money__num"
  }, _vm._l(_vm.getValue, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(_vm._s(item))]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };