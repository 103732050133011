import "core-js/modules/es.error.cause.js";
import store from '@/store';
import router from '@/router';
import { PathMap } from '@/config/permission';
function findObjectByPath(arr, path) {
  for (let obj of arr) {
    if (obj.path === path) {
      return obj;
    } else if (obj.subMenus && obj.subMenus.length > 0) {
      let found = findObjectByPath(obj.subMenus, path);
      if (found) {
        return found;
      }
    }
  }
  return null;
}
export default {
  inserted(el, binding) {
    let value = el.textContent || binding.value;
    value = value && value.trim();
    const rolePermissionList = store.getters['permission/rolePermissionList'];
    const path = PathMap[router.app._route.path] ? PathMap[router.app._route.path] : router.app._route.path;
    if (!value) {
      el.parentNode && el.parentNode.removeChild(el);
      return false;
    }
    if (value && rolePermissionList && rolePermissionList.length) {
      const findItem = findObjectByPath(rolePermissionList, path);

      // const findMenu = rolePermissionList.find(it => {
      //   return it.path != '/' && path.indexOf(it.path) > -1
      // })

      // if (!findMenu) return
      // const { subMenus } = findMenu

      // const findItem = subMenus.find(sub => {
      //   return sub.path == path || sub.code == path
      // })

      // 如果没加入到旅行社菜单或者未开启，先返回
      if (!findItem) {
        el.parentNode && el.parentNode.removeChild(el);
        return;
      }
      const {
        roleMenuOpts
      } = findItem;
      const hasPermission = roleMenuOpts.findIndex(it => {
        // 兼容原来写的新增
        if (it.name && it.name.trim() == '添加' && (value == '添加' || value == '新增') && it.isRole) return true;
        return it.name && it.name.trim() == value && it.isRole;
      });
      if (hasPermission < 0) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`need roles!`);
    }
  }
};