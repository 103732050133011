import billBaseMixin from '../billBaseMixin.js';
import { StatusAudit } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillTraffic, statisticsKeys } from '@/config/billCheck';
import { objectSpanMethod, tableSummaryFinally } from '@/utils';
import { calcCheckedMoney } from '@/views/billCheck/helper.js';
export default {
  components: {
    StatusAudit
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      urlType: 'transportOrder',
      headMergeNum: 5,
      tailMergeNum: checkBillTraffic.length - 2,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys: [...statisticsKeys, 'totalAccompanyCount', 'totalAdultCount', 'totalChildCount', '$total'],
      needSumKeys: ['orderCount'],
      type: TYPES.transport
    };
  },
  methods: {
    objectSpanMethod,
    getSummaries(param) {
      return tableSummaryFinally({
        param,
        mergeKeys: ['$total', 'guidePay', 'unPay', 'checkAccountMoney']
        // checkKeys: ['checkAccountTime', 'checkAccountMoney', 'ckStatus'],
      });
    }
  }
};