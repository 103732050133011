import "core-js/modules/es.array.push.js";
import TableItinerary from './components/TableItinerary';
import { FooterBar } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { ColumnTourist } from './tableConfig';
import { getUserData, parseDate } from '@/utils';
import { rules, guideRules, defaultForm } from './config';
import { transforForm, formatFormData, formatGuide } from './helper';
import { getCountryEtravelInfo } from '../helper';
import { BusinessCancelType, CancelType } from './enums';
import { syncPlanRequest, cancelPlanRequest, planTeamStatusRequest } from '@/api/plan/tourageCountry';
// import { ShuttleType } from '@/config/plan'
export default {
  components: {
    FooterBar,
    TableItinerary
  },
  mixins: [tagViewMixin],
  data() {
    return {
      rules,
      ColumnTourist,
      backPath: '/plan/onekeyup',
      form: defaultForm(),
      countryEtravelInfo: null
    };
  },
  computed: {
    // sysConfig() {
    //   return this.$store.getters['system/buttJoint/GLETravelData']
    // },
    getAgencyTravel() {
      const {
        travelAgency
      } = getUserData();
      return travelAgency.name;
    },
    getLicense() {
      const {
        value2
      } = this.countryEtravelInfo;
      return value2;
    }
  },
  created() {
    this.fetchSysConfig();
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    this.fetchPlanInfo(id);
    this.getOrderByPlanId(id);
    // this.getFleetByPlanId(id)
    // this.getPlanTeamStatus()
  },
  methods: {
    fetchPlanInfo(planId) {
      return this.$store.dispatch('plan/index/fetchPlanById', planId).then(data => {
        if (!data) return;
        this.form = {
          ...defaultForm(),
          ...transforForm(data),
          native_travel_agency: this.getAgencyTravel,
          guide: formatGuide(data)
        };
        return data;
      }).then(data => {
        const {
          guides
        } = data;
        const cur = guides.find(it => !!it.reimb) || guides[0];
        if (cur) {
          this.fetchGuideInfo(cur.id);
        }
      });
    },
    getOrderByPlanId(id) {
      return this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        if (!data) return;
        const {
          value2: agency_license,
          value3: agency_name
        } = this.countryEtravelInfo;
        this.form = {
          ...this.form,
          ...formatFormData(data[0]),
          agency_license,
          agency_name
        };
      });
    },
    // 获取旅行社配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params).then(res => {
        if (!res || !res.data) return;
        this.countryEtravelInfo = getCountryEtravelInfo(res.data);
      });
    },
    // 获取导游证号
    fetchGuideInfo(id) {
      return this.$store.dispatch('resource/guide/findById', id).then(guide => {
        if (guide && guide.guideCard) {
          this.form.guide.card_id = guide.guideCard;
        }
      });
    },
    changeGuideRule(val) {
      this.rules = val ? {
        ...rules,
        ...guideRules
      } : rules;
    },
    setStatus() {
      const {
        id: planId,
        planNumber
      } = this.$route.query;
      this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        otaGuilinSupervisePlanGuid: planNumber
      });
    },
    getPlanTeamStatus() {
      const {
        planNumber
      } = this.$route.query;
      const param = {
        team_id: planNumber,
        agency_license: this.getLicense
      };
      planTeamStatusRequest(param).then(res => {
        console.log('res:', res);
      });
    },
    handleCancel() {
      const {
        planNumber
      } = this.$route.query;
      this.$bus.open(() => {
        const param = {
          team_id: planNumber,
          agency_license: this.getLicense,
          cancel_desc: '修改，重新上传',
          cancel_date: parseDate(),
          cancel_type: CancelType.TRAVEL,
          business_type: BusinessCancelType.RECEPTION
        };
        cancelPlanRequest(param).then(res => {
          console.log('res:', res);
          if (res.error_code === 0) {
            this.$bus.tip({
              message: '取消成功'
            });
          }
        });
      }, '确定要取消吗？');
    },
    handleUpload() {
      this.$refs.formRef.validate(valid => {
        if (!valid) return;
        const time = this.$route.query.isUpdate ? 1 : 0;
        const {
          tourist_list,
          team_id,
          agency_name,
          guide
        } = this.form;
        const param = {
          ...this.form,
          time,
          receive_group_number: team_id,
          receive_company_name: agency_name
        };
        param.guides = guide.card_id ? [guide] : [];
        param.tourist_list = this.processTourist(tourist_list);
        syncPlanRequest(param).then(res => {
          if (res.error_code === 0) {
            this.$bus.tip();
            this.setStatus();
            this.handleClose();
          }
        }).catch(err => {
          this.$bus.tip({
            type: 'danger',
            message: err.error_msg
          });
        });
      });
    },
    deleteMember(row) {
      this.form.tourist_list = this.form.tourist_list.filter(it => it != row);
    },
    processTourist(list) {
      return list.map(it => {
        it.card_list = [it.cardItem];
        it.card_id = it.cardItem.card_id;
        delete it.cardItem;
        return it;
      });
    },
    handleClose() {
      this.closeSelectedTag();
      this.goBack();
    },
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};