var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "1200px",
      title: "协议内容",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-field",
    staticStyle: {
      "margin-top": "-20px"
    }
  }, [_c("h3", {
    staticClass: "pr10"
  }, [_vm._v(" 新增项目 ")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.addRow
    }
  }, [_vm._v(" 添加 ")])], 1), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "roomType",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.roomType,
            callback: function ($$v) {
              _vm.$set(scope.row, "roomType", $$v);
            },
            expression: "scope.row.roomType"
          }
        })];
      }
    }, {
      key: "bedType",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            label: "",
            code: "bedType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectBed(scope.row, $event);
            }
          },
          model: {
            value: scope.row.bedTypeId,
            callback: function ($$v) {
              _vm.$set(scope.row, "bedTypeId", $$v);
            },
            expression: "scope.row.bedTypeId"
          }
        })];
      }
    }, {
      key: "quantity",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: scope.row.quantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "quantity", $$v);
            },
            expression: "scope.row.quantity"
          }
        })];
      }
    }, {
      key: "area",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: scope.row.area,
            callback: function ($$v) {
              _vm.$set(scope.row, "area", $$v);
            },
            expression: "scope.row.area"
          }
        })];
      }
    }, {
      key: "floor",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.floor,
            callback: function ($$v) {
              _vm.$set(scope.row, "floor", $$v);
            },
            expression: "scope.row.floor"
          }
        })];
      }
    }, {
      key: "isWindow",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            "init-val": scope.row.isWindow,
            label: "",
            type: "empty"
          },
          model: {
            value: scope.row.isWindow,
            callback: function ($$v) {
              _vm.$set(scope.row, "isWindow", $$v);
            },
            expression: "scope.row.isWindow"
          }
        })];
      }
    }, {
      key: "isAddRoom",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            "init-val": scope.row.isAddRoom,
            label: "",
            type: "bed"
          },
          model: {
            value: scope.row.isAddRoom,
            callback: function ($$v) {
              _vm.$set(scope.row, "isAddRoom", $$v);
            },
            expression: "scope.row.isAddRoom"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _vm._t("footer", function () {
    return [_c("span", {
      staticClass: "flex flex-center mt20"
    }, [_c("el-button", {
      on: {
        click: _vm.handleCancel
      }
    }, [_vm._v("取 消")]), _c("el-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: _vm.handleConfirm
      }
    }, [_vm._v(" 保 存 ")])], 1)];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };