var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    on: {
      "tab-click": _vm.handleTab
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "外转费用",
      name: "fee"
    }
  }, [_c("ReceptionFeeTable", {
    ref: "fee",
    attrs: {
      "table-data": _vm.feeList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "外转代收",
      name: "proxy"
    }
  }, [_c("ReceptionProxyTable", {
    ref: "proxy",
    attrs: {
      "table-data": _vm.collectList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "外转返款",
      name: "refund"
    }
  }, [_c("ReceptionRefundTable", {
    ref: "refund",
    attrs: {
      "table-data": _vm.subsidyList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "产品行程",
      name: "itineraries"
    }
  }, [_c("ReceptionItinerariesTable", {
    ref: "itinerariesRef",
    attrs: {
      "table-data": _vm.productItineraries,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "备注",
      name: "remark"
    }
  }, [_c("ReceptionRemarkTable", {
    attrs: {
      "table-data": _vm.remarks,
      "is-audit": _vm.isAudit
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };