import billBaseMixin from '../../billBaseMixin';
import { StatusAudit } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillFleet, statisticsKeys } from '@/config/billCheck';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    StatusAudit
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      urlType: 'busCompanyOrder',
      headMergeNum: 5,
      tailMergeNum: checkBillFleet.length - 11,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys,
      type: TYPES.bus_company
    };
  }
};