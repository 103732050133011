var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "resource-guide-pwd",
    attrs: {
      title: "密码修改",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.hide
    }
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      rules: _vm.rules,
      model: _vm.form
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "旧密码",
      prop: "oldPwd"
    }
  }, [_c("InputBase", {
    attrs: {
      type: _vm.getPwdType,
      label: ""
    },
    model: {
      value: _vm.form.oldPwd,
      callback: function ($$v) {
        _vm.$set(_vm.form, "oldPwd", $$v);
      },
      expression: "form.oldPwd"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "新密码",
      prop: "pwd"
    }
  }, [_c("InputBase", {
    attrs: {
      type: _vm.getPwdType,
      label: ""
    },
    model: {
      value: _vm.form.pwd,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pwd", $$v);
      },
      expression: "form.pwd"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "确认新密码",
      prop: "checkPass"
    }
  }, [_c("InputBase", {
    attrs: {
      type: _vm.getPwdType,
      label: ""
    },
    model: {
      value: _vm.form.checkPass,
      callback: function ($$v) {
        _vm.$set(_vm.form, "checkPass", $$v);
      },
      expression: "form.checkPass"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "显示密码",
      prop: ""
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.showPwd,
      callback: function ($$v) {
        _vm.showPwd = $$v;
      },
      expression: "showPwd"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };