// 发团预定安排 交通
import request from '@/utils/request';

// 安排列表
export function fetchTraffic(params) {
  return request({
    url: '/order/transportCompany/listAll',
    method: 'get',
    params
  });
}

// 安排更新
export function updateTraffic(data) {
  return request({
    url: '/order/transportCompany/save',
    method: 'post',
    data
  });
}

// 安排删除
export function deleteTraffic(ids) {
  return request({
    url: '/order/transportCompany/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteTrafficItem(id) {
  return request({
    url: '/order/transportCompany/delType',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchTrafficOrder(params) {
  return request({
    url: '/order/transportCompany/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateTrafficOrder(data) {
  return request({
    url: '/order/transportCompany/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteTrafficOrder(id) {
  return request({
    url: '/order/transportCompany/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteTrafficOrderItem(id) {
  return request({
    url: '/order/transportCompany/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchTrafficOrderList(params) {
  return request({
    url: '/order/transportCompany/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectTrafficOrderList(data) {
  return request({
    url: '/order/transportCompany/saveOrder',
    method: 'post',
    data
  });
}