import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceBusCompany/findList',
    method: 'get',
    params
  });
}

// 列表 集团
export function fetchGroupList(params) {
  return request({
    url: '/resource/resourceBusCompany/group-objects',
    method: 'get',
    params
  });
}

// 列表
export function fetchFleet(params) {
  return request({
    url: '/resource/resourceBusCompany/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateFleet(data) {
  return request({
    url: '/resource/resourceBusCompany/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteFleet(id) {
  return request({
    url: '/resource/resourceBusCompany/del',
    method: 'post',
    data: {
      id
    }
  });
}