var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.companyInfo ? _c("div", [_c("el-alert", {
    attrs: {
      "show-icon": "",
      title: "提示",
      type: "success",
      description: "单据配置信息修改后，请点击保存按钮确定更新！"
    }
  }), _c("div", {
    staticClass: "mt20"
  }), _c("el-row", {
    attrs: {
      gutter: 8
    }
  }, [_vm._l(_vm.inputList, function (item, index) {
    return [_c("el-col", {
      key: item.value,
      staticStyle: {
        "padding-right": "8px",
        "margin-bottom": "30px"
      },
      attrs: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 12
        },
        lg: {
          span: 12
        },
        xl: {
          span: 12
        }
      }
    }, [_c("p", [_vm._v(_vm._s(item.text))]), _c("el-input", {
      attrs: {
        type: "textarea",
        resize: "vertical",
        rows: 4
      },
      on: {
        change: function ($event) {
          return _vm.handleChange(index, $event);
        }
      },
      model: {
        value: item.input,
        callback: function ($$v) {
          _vm.$set(item, "input", $$v);
        },
        expression: "item.input"
      }
    })], 1)];
  })], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };