import { SelectStaff, SelectAccount, SelectBankCard } from '@/components/common';
import { mapGetters } from 'vuex';
import { reloadMixin } from '@/mixin';
// import { CashWay } from '@/config/billDown'
import { defaultState, CashWay, cashMode, cashModeType, SourceTypeLabel } from '@/views/billDown/help';
export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard
  },
  mixins: [reloadMixin],
  props: {
    lock: {
      type: Boolean,
      default: false
    },
    isGuide: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    resourceId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      radioVal: cashMode,
      autoStatus: true,
      radios: [{
        value: '常规',
        label: cashModeType.COMMON
      }, {
        value: '按预付款',
        label: cashModeType.PRE_PAYMENT
      }],
      form: defaultState(),
      formData: null
    };
  },
  computed: {
    ...mapGetters({
      selectedMoney: 'bill/guide/selectedMoney',
      downMoney: 'bill/guide/downMoney',
      balanceMoney: 'bill/guide/balanceMoney',
      billDetail: 'bill/guide/billDetail',
      subjectData: 'system/accounting/subjectData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    isShowBalance() {
      return !this.isGuide && this.lock;
    }
  },
  watch: {
    resourceId: {
      handler: 'setResourceId',
      immediate: true
    },
    downMoney: {
      handler: function (money) {
        setTimeout(() => {
          this.form.money = money;
        }, 500);
      }
      // immediate: true,
    },
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    }
  },
  mounted() {
    // 重置数据
    this.$store.dispatch('bill/billAutoDown/resetData');
    this.changeForm();
  },
  beforeMount() {
    const {
      id
    } = this.$route.query;
    if (!this.isGuide && id) {
      this.fetchData(id);
    } else {
      if (this.isGuide) {
        this.form = {
          ...this.form,
          ...this.billDetail
        };
      }
      if (this.subjectData) {
        this.setSubject(this.subjectData);
      }
      this.changeForm();
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('bill/billDown/fetchDetail', id).then(data => {
        if (!data) return;
        this.formData = data;
        this.form = {
          ...this.form,
          ...data,
          subject: data.subjectName
        };
        const {
          money,
          used
        } = data;
        this.$store.commit('bill/billAutoDown/SET_BALANCE_MONEY', {
          money,
          used
        });
        this.changeForm();
      });
    },
    changeMoney(money) {
      this.$store.commit('bill/guide/SET_DOWN_MONEY', money);
    },
    setSubject(data) {
      const findItem = this.findSubject(data);
      if (!findItem) return;
      const {
        id: subjectId,
        name
      } = findItem;
      this.form.subject = name;
      this.form.subjectId = subjectId;
    },
    substrPath(path) {
      const name = path.substr(path.lastIndexOf('/') + 1);
      return name.split('-')[0];
    },
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    findSubject(data) {
      const name = SourceTypeLabel[this.substrPath(this.$route.path)];
      if (!name) return;
      return data.find(it => ~it.name.indexOf(name));
    },
    setResourceId(val) {
      if (!val) return;
      this.form.resourceId = val;
    },
    changeForm() {
      this.form = {
        ...this.form,
        subject: this.form.subjectName,
        resourceId: this.resourceId
      };
      this.formData = this.form;
      this.$store.commit('bill/billDown/BASE_INPUT', this.form);
    },
    changeRadio(val) {
      this.form.cashMode = val;
    },
    changeCashMode(val) {
      this.form.cashWay = val === 1 ? CashWay.CASH : CashWay.CARD;
    },
    selectAccount(val) {
      const {
        id,
        subId
      } = val;
      this.form.subjectId = id;
      this.form.pSubjectId = subId || '';
    },
    handleValidation() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          reject();
        } else {
          resolve();
        }
      });
    },
    validateForm() {
      const {
        money,
        subjectId,
        bankId,
        cashUserId
      } = this.form;
      if (!this.isGuide && !this.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择直营单位！'
        });
        return false;
      }
      if (!money) {
        this.$bus.tip({
          type: 'warning',
          message: '请输入金额！'
        });
        return false;
      }
      if (!subjectId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择会计科目！'
        });
        return false;
      }
      if (!bankId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择银行卡！'
        });
        return false;
      }
      if (!cashUserId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择收款人！'
        });
        return false;
      }
      return true;
    }
  }
};