import { SelectStaff, SelectAccount, SelectBankCard, SwitchStatus } from '@/components/common';
import { mapGetters } from 'vuex';
import { reloadMixin } from '@/mixin';
// import { CashWay } from '@/config/billDown'
// import { SourceTypeLabel } from '@/config/resource'
import { defaultState, calcBalance, SourceTypeLabel, cashMode, cashModeType, CashWay } from '@/views/billDown/help';
export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard,
    SwitchStatus
  },
  mixins: [reloadMixin],
  props: {
    lock: {
      type: Boolean,
      default: false
    },
    isGuide: {
      type: Boolean,
      default: false
    },
    isSubsidy: {
      type: Boolean,
      default: false
    },
    resourceId: {
      type: [String, Number],
      default: ''
    },
    payText: {
      type: String,
      default: '预付款'
    },
    payType: {
      type: String,
      default: 'pay'
    }
  },
  data() {
    return {
      radioVal: cashMode,
      autoStatus: true,
      radios: [{
        value: '常规',
        label: cashModeType.COMMON
      }, {
        value: this.payText,
        label: cashModeType.PRE_PAYMENT
      }],
      form: null,
      formData: null
    };
  },
  computed: {
    ...mapGetters({
      billDetail: 'bill/billDown/billInputForm',
      curTotalMoney: 'bill/billAutoDown/curTotalMoney',
      downMoney: 'bill/billAutoDown/downMoney',
      getBalance: 'bill/billAutoDown/getBalance',
      autoDownStatus: 'bill/billAutoDown/autoDownStatus',
      subjectData: 'system/accounting/subjectData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    prePaymentMoney() {
      const type = this.payType == 'pay' ? 'bill/billDown/pay/prePaymentMoney' : 'bill/billDown/collect/prePaymentMoney';
      return this.$store.getters[type];
    },
    isShowBalance() {
      return !this.isGuide && this.lock;
    },
    getPrePayVal() {
      return {
        rid: this.resourceId,
        type: this.radioVal
      };
    },
    getBalance() {
      const {
        balance
      } = calcBalance(this.form);
      return balance;
    },
    isPrePaymentMode() {
      return this.radioVal == cashModeType.PRE_PAYMENT;
    }
  },
  watch: {
    resourceId: {
      handler: 'setResourceId'
    },
    downMoney: {
      handler: function (money) {
        if (this.autoDownStatus && this.form) {
          this.form.money = money;
        }
      }
    },
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form = this.form || {};
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    },
    getPrePayVal: {
      handler: function (obj) {
        const {
          rid,
          type
        } = obj;
        if (rid && type == cashModeType.PRE_PAYMENT) {
          this.$emit('on-balance');
        }
      }
    }
  },
  mounted() {
    // 重置数据
    this.$store.dispatch('bill/billAutoDown/resetData');
  },
  beforeMount() {
    const {
      id
    } = this.$route.query;
    if (!this.billDetail && this.$route.path == '/bill/guide-audit-detail') {
      this.$router.replace('/bill/guide-audit');
      return;
    }

    // if (!this.billBalance) {
    //   let path = this.$route.path
    //   path = path.replace('-create', '')
    //   this.$router.replace(path)
    // }

    if (!this.isGuide && id) {
      this.fetchData(id);
    } else {
      this.form = this.formData = defaultState();
      if (this.isGuide) {
        const {
          planId,
          guideName
        } = this.billDetail;
        this.form.guideName = guideName;
        this.form.planId = planId;
      }
      if (this.subjectData) {
        this.setSubject(this.subjectData);
      }
      this.changeForm();
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('bill/billDown/fetchDetail', id).then(data => {
        if (!data) return;
        this.formData = data;
        this.form = {
          ...defaultState(),
          ...data,
          subject: data.subjectName
        };
        const {
          money,
          used
        } = data;
        this.$store.commit('bill/billAutoDown/SET_BALANCE_MONEY', {
          money,
          used
        });
        this.changeForm();
      });
    },
    // 下账模式
    changeStatus(val) {
      this.$store.dispatch('bill/billAutoDown/changeDownStatus', val);
    },
    changeMoney(money) {
      // 购物店
      const isShop = ~this.$route.path.indexOf('shop-create');
      this.$store.dispatch('bill/billAutoDown/changeDownMoney', {
        money,
        isShop,
        subsidy: this.isSubsidy
      });
      // if (this.autoDownStatus && !this.form.resourceId) {
      //   this.$bus.tip({
      //     type: 'warning',
      //     message: '请先选择客户',
      //   })
      //   return
      // }
    },
    setSubject(data) {
      const findItem = this.findSubject(data);
      if (!findItem) return;
      const {
        id: subjectId,
        name
      } = findItem;
      this.form.subject = name;
      this.form.subjectId = subjectId;
    },
    substrPath(path) {
      const name = path.substr(path.lastIndexOf('/') + 1);
      return name.split('-')[0];
    },
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    findSubject(data) {
      const name = SourceTypeLabel[this.substrPath(this.$route.path)];
      if (!name) return;
      return data.find(it => ~it.name.indexOf(name));
    },
    setResourceId(val) {
      if (!val || !this.form) return;
      this.form.resourceId = val;
    },
    changeForm() {
      const data = this.form;
      data.subject = data.subjectName;
      this.form = data;
      this.$store.commit('bill/billDown/BASE_INPUT', data);
    },
    changeRadio(val) {
      this.form.cashMode = val;
      if (val === cashModeType.PRE_PAYMENT) {
        this.$emit('on-balance');
      }
    },
    changeCashMode(val) {
      this.form.cashWay = val === 1 ? CashWay.CASH : CashWay.CARD;
    },
    selectAccount(val) {
      const {
        id,
        subId
      } = val;
      this.form.subjectId = id;
      this.form.pSubjectId = subId || '';
    },
    handleValidation() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          reject();
        } else {
          resolve();
        }
      });
    },
    validateForm() {
      const {
        money,
        subjectId,
        bankId,
        cashUserId
      } = this.form;
      if (!this.isGuide && !this.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择直营单位！'
        });
        return false;
      }
      if (!money) {
        this.$bus.tip({
          type: 'warning',
          message: '请输入金额！'
        });
        return false;
      }
      if (!subjectId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择会计科目！'
        });
        return false;
      }
      if (!bankId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择银行卡！'
        });
        return false;
      }
      if (!cashUserId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择收款人！'
        });
        return false;
      }
      return true;
    }
  }
};