/* eslint-disable no-unused-vars */
import { fetchReception } from '@/api/plan/reception';
const dfaultState = () => ({});
const state = dfaultState();
const getters = {};
const actions = {
  fetch({
    commit
  }, planId) {
    return fetchReception(planId).then(res => {
      if (!res) return;
      let {
        data
      } = res;
      this.commit('order/reception/CUR_RECEPTION_LIST', data);
      return data;
    });
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};