import { SelectStaff, SelectAccount, SelectBankCard } from '@/components/common';
import { mapGetters } from 'vuex';
// import { processMinus } from '@/utils/number'
// import { parseDate } from '@/utils'
// import { CashWay } from '@/config/billDown'
import { defaultState, calcBalance, rules, cashMode, cashModeType, SourceTypeLabel } from '@/views/billDown/help';

// const cashMode = 'COMMON'
// const defaultState = () => ({
//   cashMode,
//   bankId: '',
//   cashAction: '',
//   cashDate: parseDate(),
//   cashUserId: '',
//   cashWay: CashWay.CARD,
//   cashes: [],
//   resourceId: '',
//   invoiceMoney: 0,
//   invoiceNumber: '',
//   cash: 0,
//   planId: '',
//   remarks: '',
//   subject: '',
//   subjectId: '',
//   subId: '',
// })

// const rules = {
//   money: [{ required: true, message: '请输入金额！', trigger: 'blur' }],
//   subjectId: [{ required: true, message: '请选择会计科目！', trigger: 'blur' }],
//   bankId: [{ required: true, message: '请选择银行卡！', trigger: 'blur' }],
//   cashUserId: [{ required: true, message: '请选择收款人！', trigger: 'blur' }],
// }

// const pathMap = {
//   scenic: '景区',
//   hotel: '酒店',
//   fleet: '车队',
//   restaurant: '餐厅',
//   traffic: '交通',
//   insurance: '保险',
//   reception: '地接',
//   guide: '导游',
//   team: '团款',
//   shop: '购物',
// }

export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    resName: {
      type: String,
      default: ''
    },
    obj: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      radioVal: cashMode,
      autoStatus: true,
      radios: [{
        value: '常规',
        label: cashModeType.COMMON
      }, {
        value: '预付款',
        label: cashModeType.PRE_PAYMENT
      }],
      form: {
        ...defaultState(),
        ...this.obj
      },
      rules
    };
  },
  computed: {
    ...mapGetters({
      prePaymentMoney: 'bill/billDown/pay/prePaymentMoney',
      subjectData: 'system/accounting/subjectData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    currentMoney() {
      return this.form.unPay || 0;
    },
    getPrePayVal() {
      return {
        rid: this.resourceId,
        type: this.radioVal
      };
    },
    getBalance() {
      const {
        balance
      } = calcBalance(this.form);
      return balance;
    },
    isPrePaymentMode() {
      return this.radioVal == cashModeType.PRE_PAYMENT;
    }
  },
  watch: {
    obj: {
      handler: function (data) {
        this.form = {
          ...this.form,
          ...data
        };
      }
    },
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    },
    getPrePayVal: {
      handler: function (obj) {
        const {
          rid,
          type
        } = obj;
        if (rid && type == cashModeType.PRE_PAYMENT) {
          this.$emit('on-balance');
        }
      }
    }
  },
  beforeMount() {
    if (!this.form.subjectId) {
      if (this.subjectData) {
        this.setSubject(this.subjectData);
      }
    }
    this.changeForm();
  },
  methods: {
    setSubject(data) {
      const findItem = this.findSubject(data);
      if (!findItem) return;
      const {
        id: subjectId,
        name
      } = findItem;
      this.form.subject = name;
      this.form.subjectId = subjectId;
    },
    substrPath(path) {
      const name = path.substr(path.lastIndexOf('/') + 1);
      return name.split('-')[0];
    },
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    findSubject(data) {
      const name = SourceTypeLabel[this.substrPath(this.$route.path)];
      if (!name) return;
      return data.find(it => ~it.name.indexOf(name));
    },
    setResourceId(val) {
      if (!val) return;
      this.form.resourceId = val;
    },
    changeForm() {
      this.form = {
        subject: this.form.subjectName,
        ...this.form
      };
    },
    changeRadio(val) {
      this.form.cashMode = val;
    },
    changeCashMode(val) {
      const {
        label
      } = val;
      this.form.cashWay = label;
    },
    selectAccount(val) {
      const {
        id,
        subId
      } = val;
      this.form.subjectId = id;
      this.form.pSubjectId = subId || '';
    },
    validate() {
      return this.$refs.formRef.validate();
    }
  }
};