import "core-js/modules/es.array.reduce.js";
import DialogAllocation from './DialogAllocation.vue';
import { SelectStaff } from '@/components/common';
import { getUserData } from '@/utils/cookie';
import { processAdd, processMinus } from '@/utils/number';
import { APPLY_MODE, ENTERPRISE_MODE, ENTERPRISE_RESTYPE, rules, defaultState, alipayEnterCodeParams } from './config';
export default {
  components: {
    SelectStaff,
    DialogAllocation
  },
  data() {
    return {
      APPLY_MODE,
      ENTERPRISE_MODE,
      ENTERPRISE_RESTYPE,
      rules,
      alipayEnterCodeParams,
      visible: false,
      form: {
        ...defaultState(),
        type: '导游借款申请'
      },
      guideInfo: null,
      content: ''
    };
  },
  computed: {
    getRealName() {
      const {
        realName
      } = getUserData();
      return realName;
    },
    teamProfit() {
      return this.$store.getters['plan/index/teamProfit'];
    },
    planInfo() {
      return this.$store.getters['plan/index/planInfo'];
    },
    getBalance() {
      if (+this.form.totalGuidePay <= 0) return 0;
      const number = this.alipayEnterCodeParams.standards.reduce((acc, cur) => (acc, processAdd(cur.value, acc)), 0);
      return processMinus(this.form.totalGuidePay, number);
    },
    isAlipayMode() {
      return this.form.applyMode == APPLY_MODE.ALIPAY;
    },
    isAlipayDefault() {
      return this.alipayEnterCodeParams.mode == ENTERPRISE_MODE.PLAN;
    },
    isAlipayRes() {
      return this.alipayEnterCodeParams.mode == ENTERPRISE_MODE.RES;
    }
  },
  beforeCreate() {
    if ((!this.teamProfit || !this.teamProfit.length) && this.$route.query.planId) {
      this.$store.dispatch('plan/index/groupProfit', this.$route.query.planId);
    }
  },
  methods: {
    show() {
      this.visible = true;
      if (this.teamProfit && this.teamProfit.length) {
        const data = this.teamProfit[0];
        if (!data) return;
        if (data.guideReimbTotalInfo) {
          const {
            otherPayReimb
          } = data.guideReimbTotalInfo || {
            otherPayReimb: 0
          };
          const {
            totalGuidePay
          } = data.guideReimbTotalInfo || {
            totalGuidePay: 0
          };
          this.processData({
            totalGuidePay: processAdd(totalGuidePay, otherPayReimb)
          });
        }
      }
      if (this.planInfo) {
        const {
          guides,
          planNumber,
          id: planId
        } = this.planInfo;
        if (guides && guides.length) {
          const {
            id
          } = guides.find(it => it.reimb) || guides[0];
          id && this.fetchGuideInfo(id);
          this.processData({
            planNumber,
            planId
          });
        }
      }
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    showAllocation() {
      this.$refs.dialogRef.show(this.alipayEnterCodeParams.standards);
    },
    setStandards(list) {
      this.alipayEnterCodeParams.standards = list;
    },
    handleCancel() {
      this.hide();
    },
    fetchGuideInfo(id) {
      return this.$store.dispatch('resource/guide/findById', id).then(data => {
        if (!data) return;
        this.guideInfo = data;
        const {
          name: bankName,
          bank,
          bankCard: bankNo
        } = data;
        this.processData({
          bankName,
          bank,
          bankNo
        });
        this.processContent();
      });
    },
    processContent() {
      const {
        busCompanyReimb,
        hotelReimb,
        restaurantReimb,
        scenicReimb,
        otherPayReimb
      } = this.teamProfit[0].guideReimbTotalInfo;
      const {
        planNumber
      } = this.planInfo;
      const {
        name
      } = this.guideInfo;
      this.content = `团号：${planNumber}, 导游：${name}，房：${hotelReimb},餐：${restaurantReimb},车：${busCompanyReimb},景：${scenicReimb},其他:${otherPayReimb}`;
      const total = processAdd(busCompanyReimb, hotelReimb, restaurantReimb, scenicReimb, otherPayReimb);
      this.form.totalGuidePay = total;
      this.form.details = this.content;
    },
    processData(data = {}) {
      const {
        id: applyUserId,
        travelAgency
      } = getUserData();
      const {
        name
      } = travelAgency;
      this.form = {
        ...this.form,
        ...data,
        applyUserId,
        name
      };
    },
    // 保存
    handleConfirm() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) return;
      const total = this.form.totalGuidePay;
      const opts = {
        ...this.form,
        details: [{
          name: this.content,
          value: total
        }],
        planId: this.$route.query.planId
      };
      if (this.isAlipayMode) {
        opts.alipayEnterCodeParams = {
          mode: ENTERPRISE_MODE.PLAN,
          standards: [{
            resType: ENTERPRISE_RESTYPE.DEFAULT,
            value: total
          }]
        };

        // if (this.isAlipayRes) {
        //   opts.alipayEnterCodeParams = {

        //     standards: [
        //       {
        //         resType: ENTERPRISE_RESTYPE.HOTEL,
        //         value: total,
        //         planResList: [],
        //         resId: '',
        //         resName: '',
        //       },

        //     ],
        //   }
        // }
      }
      this.$store.dispatch('plan/guide/dingdingApply', opts).then(() => {
        this.hide();
        this.$bus.tip();
      });
    }
  }
};