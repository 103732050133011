var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box line-range",
    class: {
      vertical: _vm.vertical,
      "is-require": _vm.isRequire
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("行程天数")]), _c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.inputDay,
      callback: function ($$v) {
        _vm.inputDay = $$v;
      },
      expression: "inputDay"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("天")]), _c("el-input", {
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.inputNight,
      callback: function ($$v) {
        _vm.inputNight = $$v;
      },
      expression: "inputNight"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("晚")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };