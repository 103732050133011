import { tableMixin, rowProfitMixin, summaryMixin } from '@/mixin';
export default {
  mixins: [tableMixin, rowProfitMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['adultCount', 'childCount', 'totalAmountReceive', 'otherFee', 'totalQuotePay', 'totalPay', 'cashPayment', 'companyTransfer', 'sign', 'totalPayed', 'unPay', 'alipayECodePay']
    };
  },
  methods: {
    onChecksLink(row) {
      this.$parent.onLink({
        row,
        type: 'checks'
      });
    }
  }
};