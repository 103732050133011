import "core-js/modules/es.array.push.js";
import { TopTitle, DialogLog, SelectFleet, SelectDictionary } from '@/components/common';
import { orderFleet, TYPES } from '@/config/order';
import { getNextDate, calcMergeRowTotalByKey } from '@/utils';
import { rowspanMixin, permissionMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import tableMixin from '../tableMixin';
import { mapGetters } from 'vuex';
import summaryMixin from '@/mixin/summary';
// import { processNumber } from '@/utils/number'

const itemListKeys = () => ({
  busFee: 0,
  otherFee: 0,
  driverId: -1,
  driverName: '',
  driverPhone: '',
  licenceNumber: '',
  startDate: '',
  endDate: '',
  busTypeId: '',
  busType: ''
});
const defaultState = () => ({
  isCustom: true,
  // 删除数据用来判断类型
  planId: -1,
  busCompanyId: '',
  busCompanyName: '',
  shuttleType: 'BUS',
  totalPay: 0,
  remark: '',
  orderDate: '',
  rowTotal: 0,
  money: 0,
  ...itemListKeys()
});
export default {
  components: {
    TopTitle,
    DialogLog,
    SelectFleet,
    SelectDictionary
  },
  mixins: [tableMixin, rowspanMixin, permissionMixin, summaryMixin],
  data() {
    return {
      tableData: [],
      columnData: orderFleet,
      deleteMsg: '是否确定删除此条餐厅安排？',
      fetchPath: 'order/index/fetchOrBus',
      deletePath: 'order/index/deleteBus',
      updatePath: 'order/index/createOrBus',
      statisticsKeys: ['otherFee', 'rowTotal'],
      headMergeNum: 1,
      tailMergeNum: orderFleet.length - 4,
      hasMergeTail: true,
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder',
      orderItinerary: 'order/index/orderItinerary'
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.tableData = this.tableData.map(item => {
        this.fetchDriver({
          busCompanyId: item.busCompanyId
        }).then(res => {
          item.driverList = res;
        });
        this.fetchBus(item.busCompanyId).then(res => {
          item.licenceNumberList = res;
        });
        return item;
      });
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    reloadData() {
      const {
        id
      } = this.$route.query;
      this.fetchData(id);
    },
    handlePlus() {
      const {
        adultCount,
        planId
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId,
        planId,
        count: adultCount || 0
      });
      this.$nextTick(() => {
        this.resetData();
      });
    },
    fetchDriver(opts) {
      opts = {
        pageSize: 50,
        ...opts
      };
      return this.$store.dispatch('resource/driver/fetch', opts).then(data => {
        const list = this.formatOptions(data.list, 'name');
        return list;
      });
    },
    fetchBus(busCompanyId) {
      return this.$store.dispatch('resource/vehicle/fetch', {
        busCompanyId
      }).then(data => {
        const list = this.formatOptions(data.list, 'licenceNumber');
        return list;
      });
    },
    // 选择名称
    handleSelect(row, val) {
      if (val.label == '') return;
      const {
        id,
        label
      } = val;
      row.busCompanyName = label;
      row.busCompanyId = id;
      row.driverPhone = '';
      row.driverId = '';
      row.driverName = '';
      row.licenceNumber = '';
      if (label == '') return;
      this.fetchDriver({
        busCompanyId: id
      }).then(res => {
        row.driverList = res;
      });
      this.fetchBus(id).then(res => {
        row.licenceNumberList = res;
      });
    },
    // 车辆类型
    selectBusType(row, val) {
      const {
        label,
        value
      } = val;
      row.busType = label;
      row.busTypeId = value;
    },
    // 选择司机
    selectDriver(scope, val) {
      if (typeof val !== 'string') {
        const {
          id,
          mobilePhone
        } = val;
        const {
          row
        } = scope;
        row.driverPhone = mobilePhone;
        row.driverId = id;
      }
    },
    // 司机选项
    driverSearch(queryString, cb, row) {
      const drivers = row.driverList;
      const results = queryString ? drivers.filter(this.createFilter(queryString)) : drivers;
      cb(results);
    },
    // 司机输入搜索获取最新数据
    changeDriver(scope, val) {
      const {
        row
      } = scope;
      this.fetchDriver({
        busCompanyId: row.busCompanyId,
        name: val
      }).then(res => {
        row.driverList = res;
      });
    },
    // 选择车牌
    selectLicence(row, val) {
      if (val && typeof val !== 'string') {
        const {
          licenceNumber
        } = val;
        row.licenceNumber = licenceNumber;
      }
    },
    // 车牌选项
    licenceSearch(queryString, cb, row) {
      const busList = row.licenceNumberList;
      const results = queryString ? busList.filter(this.createFilter(queryString)) : busList;
      cb(results);
    },
    createFilter(queryString) {
      return list => {
        return list.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      const rowItem = {
        ...row,
        itemIndex: ++index,
        isCustom: true,
        isSelect: false
      };
      this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      this.tableData = calcMergeRowTotalByKey(this.tableData, '$rowTotal', 'rowTotal');
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 删除景点门票
    deleteRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        isCustom
      } = row;
      !isCustom ? this.$store.dispatch('order/index/deleteBusType', {
        id
      }).then(() => {
        this.reloadData();
      }) : this.tableData.splice(index, 1);
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 选择类型
    selectType(row, val) {
      row.useType = val ? val.label : '';
      row.useTypeLabel = val ? val.label : '';
    },
    // 时间修改
    changeStartDate(row, val) {
      row.selectStartDate = new Date(val).getTime();
      row.endDate = getNextDate(row.selectStartDate);
    },
    changeEndDate(row, val) {
      if (!row.selectStartDate) {
        this.$bus.tip({
          type: 'warning',
          message: '请先选择开始日期'
        });
        row.endDate = '';
        return;
      }
      if (row.selectStartDate > new Date(val).getTime()) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期不能早于开始日期'
        });
        row.endDate = '';
        return;
      }
    },
    // 校验输入的值
    changeInput(item) {
      item.$rowTotal = this.calcRowMoney(item);
      this.tableData = calcMergeRowTotalByKey(this.tableData, '$rowTotal', 'rowTotal');
      this.$nextTick(() => {
        this.mergeRowMoney();
      });
    },
    // 计算总额
    calcRowMoney(row) {
      let {
        busFee,
        otherFee
      } = row;
      let total = Number(busFee || 0) + Number(otherFee || 0);
      return total;
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        it.auditProgress = it.auditProgress ? it.auditProgress : AuditRole.wait;
        const itemList = it.types;
        // 下拉列表数据
        const {
          id,
          money,
          remark
        } = it;
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          planFleetId: id,
          itemIndex: 0,
          isCustom: false,
          rowTotal: money,
          totalPay: money
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcRowMoney(v);
            const newItem = {
              ...item,
              ...v,
              itemId: v.id,
              // 子订单id
              itemIndex: i,
              remark,
              totalSum
            };
            result.push(newItem);
          });
        } else {
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 格式化下拉列表
    formatOptions(list, key) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        it.value = it[key];
        result.push(it);
      });
      return result;
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.bus
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 删除安排
    handleDelete(scope) {
      const {
        rowId,
        id
      } = scope.row;
      // 将要删除的项目加入缓存
      if (rowId && id) {
        this.$bus.open(() => {
          this.$store.dispatch(this.deletePath, {
            id: rowId
          }).then(res => {
            const message = res ? res.message : '操作成功';
            this.operationTip(message);
            this.fetchData();
          });
        }, this.deleteMsg);
      } else {
        this.tableData.splice(scope.$index, 1);
      }
    },
    deleteScenic() {},
    beforeCommit(customerOrderId) {
      const list = this.tableData;
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'driverList', 'licenceNumberList', 'planId'];
      list.forEach(it => {
        it.customerOrderId = customerOrderId;
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom && it.itemId ? item.id = it.itemId : null;
        it.money = it.rowTotal || 0;

        // 定义子项目的列表
        it.types = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 重新还原该条数据的id
        it.id = it.planFleetId;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planFleetId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.types.push(item);
        } else {
          it.types.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      // for (let i = 0; i < this.tableData.length; i++) {
      //   const row = this.tableData[i]
      //   if (!row.orderDate) {
      //     this.$bus.tip({ type: 'warning', message: '用车日期不能为空 !' })
      //     return false
      //   }
      // }
      return true;
    },
    resetData() {
      this.rowspan();
    }
  }
};