import "core-js/modules/es.array.reduce.js";
// import billDownMixin from '@/views/billDown/billDownMixin'
import { rowspanMixin } from '@/mixin';
import { Guides } from '@/components/common';
import { RebateType } from '@/config/shopping';
import { processAdd } from '@/utils/number';
import { CustomAuthKeys } from '@/config/permission';
import { shoppingCollectColumn as columnData } from '@/config/shopping';
export default {
  components: {
    Guides
  },
  mixins: [rowspanMixin],
  data: () => ({
    columnData,
    tableData: [],
    total: 0,
    resourceId: '',
    rebateType: RebateType,
    billCheckAll: false,
    isShop: true,
    statisticsKeys: ['parkingFeeRebate', 'memberRebate', 'shopRebate', 'parkingFeeRebated', 'memberRebated', 'guideCollect', 'shopRebated', 'unRedEnvelopeRebated', 'unMemberRebated', 'unShopRebated', 'downPayed']
  }),
  computed: {
    hasSubsidy() {
      const authObj = this.$store.getters['permission/authPermission'];
      return authObj[CustomAuthKeys.shoppingCollectSubsidyTwo];
    },
    billInputForm() {
      return this.$store.getters['bill/billDown/billInputForm'];
    },
    autoStatus() {
      return this.$store.getters['bill/billDown/autoStatus'];
    }
  },
  methods: {
    fetchData(params = {}) {
      if (!this.resourceId && !params.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择单位'
        });
        return;
      }
      this.resourceId = params.resourceId;
      this.fetchUnPayData(params);
    },
    fetchUnPayData(queryParams) {
      return this.$store.dispatch('bill/billDown/fetchUnpayShop', {
        ...queryParams,
        resourceId: this.resourceId,
        rebateType: 'notSubsidy'
      }).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    // 分页
    handlePage(param) {
      this.fetchUnPayData(param);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = this.formatData(list);
      this.handleAutoDown(true);
    },
    formatData(list) {
      if (!list || !list.length) [];
      const map = {
        AGENCY: 'unShopRebated',
        MEMBER: 'unMemberRebated',
        RED: 'unRedEnvelopeRebated',
        PARK: 'unParkingFeeRebated',
        SUBSIDY: 'unSubsidyKP' // 二返
      };
      return list.map(it => {
        const owePay = it.shopRebate;
        it.rebateObj = {};
        Object.keys(RebateType).forEach(key => {
          this.$set(it, RebateType[key], it[map[RebateType[key]]]);
        });
        it.owePay = it.owePayCopy = owePay;
        it.downPayed = '';
        it.isChecked = false;
        it.guides = it.reimbGuides;
        return it;
      });
    },
    checkSelectAll() {
      return this.tableData.every(it => it.isChecked);
    },
    // 单个勾选
    changeCheckbox(row, val) {
      // 社佣
      let agency = Number(row[RebateType.agency] || 0);
      // 人头
      let member = Number(row[RebateType.member] || 0);
      // 红包
      let redbag = Number(row[RebateType.redbag] || 0);
      if (val) {
        agency = agency ? agency : row['unShopRebated'];
        member = member ? member : row['unMemberRebated'];
        redbag = redbag ? redbag : row['unRedEnvelopeRebated'];
      } else {
        member = '';
        agency = '';
        redbag = '';
      }
      row[RebateType.member] = member;
      row[RebateType.agency] = agency;
      row[RebateType.redbag] = redbag;
      row.downPayed = processAdd(member, agency, redbag);

      // 全选框勾选状态设置
      this.billCheckAll = this.checkSelectAll();
      this.setBillDownStore();
    },
    // 输入框
    changeBillDownShop(row, type, val) {
      // 判断校验金额是否打印当前金额
      row[type] = val;
      row[RebateType[type]] = val;
      row.isChecked = true;
      row.downPayed = val;

      // 全选框勾选状态设置
      this.billCheckAll = this.checkSelectAll();
      this.setBillDownStore();
    },
    // 全选
    changeCheckAll(val) {
      if (!this.tableData.length) return;
      this.billCheckAll = val;
      this.handleAutoDown(val);
    },
    // 选择购物店
    handleAutoDown(val) {
      // 1. 全选及设置输入框金额
      this.setAllInputForStatus(val);

      // 2. 计算金额
      const total = this.calcSelectMoney();
      const opts = {
        ...this.billInputForm,
        userMoney: total
      };
      this.autoStatus ? opts.money = total : null;
      this.$store.commit('bill/billDown/SET_BILL_INPUT', opts);
      this.billCheckAll = val;
    },
    setAllInputValue(row, val) {
      const agency = row['unShopRebated'];
      const member = row['unMemberRebated'];
      const redbag = row['unRedEnvelopeRebated'];
      const memberMoney = val ? member : '';
      const agencyMoney = val ? agency : '';
      const redbagMoney = val ? redbag : '';
      row[RebateType.member] = memberMoney;
      row[RebateType.agency] = agencyMoney;
      row[RebateType.redbag] = redbagMoney;
      const downPayed = processAdd(memberMoney, agencyMoney, redbagMoney);
      row.downPayed = val ? downPayed : '';
      row.isChecked = val;
    },
    // 输入框总下账金额
    handleDownMoney(money) {
      // 重置
      this.setAllInputForStatus(false);

      // 1. 全选及设置输入框金额
      this.caclDownMoney(money);
    },
    caclDownMoney(money) {
      // 类型转换
      money = +money;

      // 重置
      this.setAllInputForStatus();
      if (money < 0) {
        this.tableData.forEach(item => {
          const absMoney = Math.abs(money);
          const {
            unShopRebated,
            unMemberRebated,
            unRedEnvelopeRebated
          } = item;
          if (money != 0) {
            // 社佣
            if (unShopRebated < 0) {
              if (absMoney > Math.abs(unShopRebated)) {
                item[RebateType.agency] = unShopRebated;
                item.downPayed += unShopRebated;
                money -= unShopRebated;
              } else {
                item[RebateType.agency] = money;
                item.downPayed += money;
                money = 0;
              }
              item.isChecked = true;
            }

            // 人头费
            if (unMemberRebated < 0) {
              if (absMoney > Math.abs(unMemberRebated)) {
                item[RebateType.member] = unMemberRebated;
                item.downPayed += unMemberRebated;
                money -= unMemberRebated;
              } else {
                item[RebateType.member] = money;
                item.downPayed += money;
                money = 0;
              }
              item.isChecked = true;
            }

            // 红包
            if (unRedEnvelopeRebated < 0) {
              if (absMoney > Math.abs(unRedEnvelopeRebated)) {
                item[RebateType.redbag] = unRedEnvelopeRebated;
                item.downPayed += unRedEnvelopeRebated;
                money -= unRedEnvelopeRebated;
              } else {
                item[RebateType.redbag] = money;
                item.downPayed += money;
                money = 0;
              }
              item.isChecked = true;
            }
          }
        });
      } else {
        this.tableData.forEach(item => {
          let {
            unShopRebated,
            unMemberRebated,
            unRedEnvelopeRebated
          } = item;
          unShopRebated = +unShopRebated;
          unMemberRebated = +unMemberRebated;
          unRedEnvelopeRebated = +unRedEnvelopeRebated;
          item.downPayed = +item.downPayed;
          if (money > 0) {
            if (unShopRebated > 0) {
              // 社佣
              if (money > unShopRebated) {
                item[RebateType.agency] = unShopRebated;
                item.downPayed = processAdd(item.downPayed, unShopRebated);
                money -= unShopRebated;
              } else {
                item[RebateType.agency] = money;
                item.downPayed = processAdd(item.downPayed, money);
                money = 0;
              }
              item.isChecked = true;
            }

            // 人头
            if (unMemberRebated > 0) {
              if (money > unMemberRebated) {
                item[RebateType.member] = unMemberRebated;
                item.downPayed = processAdd(item.downPayed, unMemberRebated);
                money -= unMemberRebated;
              } else {
                item[RebateType.member] = money;
                item.downPayed = processAdd(item.downPayed, money);
                money = 0;
              }
              item.isChecked = true;
            }

            // 红包
            if (unRedEnvelopeRebated > 0) {
              if (money > unRedEnvelopeRebated) {
                item[RebateType.redbag] = unRedEnvelopeRebated;
                item.downPayed = processAdd(item.downPayed, unRedEnvelopeRebated);
                money -= unRedEnvelopeRebated;
              } else {
                item[RebateType.redbag] = money;
                item.downPayed = processAdd(item.downPayed, money);
                money = 0;
              }
              item.isChecked = true;
            }
          }
        });
      }

      // 设置余额
      this.setBillDownStore();
    },
    setInputVal(row, type, val) {
      row[RebateType[type]] = val;
    },
    setAllInputForStatus(flag = false) {
      this.tableData.forEach(it => {
        this.setAllInputValue(it, flag);
      });
    },
    calcSelectMoney() {
      return this.tableData.reduce((acc, cur) => (acc, processAdd(cur.downPayed, acc)), 0);
    },
    setBillDownStore() {
      const selectMoney = this.calcSelectMoney();
      this.$store.commit('bill/billDown/SET_BILL_INPUT_USEMONEY', selectMoney);
    }
  }
};