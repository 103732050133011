export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getOldVal() {
      const {
        oldVal
      } = this.item;
      return oldVal !== null && oldVal !== 'undefined' && oldVal !== '';
    }
  }
};