import { NOTICE } from '@/config/notice';
export default {
  name: 'Notice',
  data() {
    return {
      visible: false,
      notice: null,
      type: NOTICE.type.COMPANY
    };
  },
  computed: {
    noticeList() {
      return this.$store.getters['system/notice/noticeReadList'];
    },
    getContent() {
      return this.notice ? this.notice.content : '';
    },
    getTitle() {
      return this.notice ? this.notice.title : '';
    }
  },
  watch: {
    noticeList: {
      handler: function (list) {
        if (!list) {
          this.fetchData();
          return;
        }
        this.notice = list[0];
        this.visible = true;
      },
      immediate: true
    }
  },
  created() {
    // this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('system/notice/fetchNoticeUnRead');
    },
    closeNotice() {
      this.visible = false;
      const {
        id
      } = this.notice;
      this.$store.commit('system/notice/READ_NOTICE', id);
    },
    setNotice() {
      const {
        id
      } = this.notice;
      const param = {
        noticeId: id,
        status: true
      };
      this.$store.dispatch('system/notice/setNoticeRead', param).then(() => {
        this.$store.commit('system/notice/READ_NOTICE', id);
        this.visible = false;
      });
    }
  }
};