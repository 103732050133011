/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { shopList, shopDetail, exportPlanShopStatic, getPlanShopStatic, getOrderShopStatic, exportOrderShopStatic, getByAreaStatic, exportByAreaStatic, exportByLineStatic, getByLineStatic, exportByShopStatic, getByShopStatic, exportByCustomerStatic, getByCustomerStatic, exportByGuideStatic, getByGuideStatic, getByGuideManagerStatic, exportByGuideManagerStatic, getByGuideTeamStatic, exportByGuideTeamStatic, getShopFinanceStatic, exportShopFinanceStatic, shoppingEntryRequest } from '@/api/shopping';
// import { resourceType } from '@/config/report'
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  currentShop: null,
  totalGuideData: null,
  shopFinanceData: null,
  shopEntryList: [],
  singleData: null,
  // 单团统计
  shopInputData: null,
  shopStaticData: null,
  guideManagerData: null,
  guideTeamData: null,
  areaStaticData: null,
  lineStaticData: null,
  teamSmallData: null,
  needReload: false
});
const state = dfaultState();
const getters = {
  currentShop: state => state.currentShop,
  totalGuideData: state => state.totalGuideData,
  shopFinanceData: state => state.shopFinanceData,
  shopEntryList: state => state.shopEntryList,
  singleData: state => state.singleData,
  shopInputData: state => state.shopInputData,
  shopStaticData: state => state.shopStaticData,
  guideManagerData: state => state.guideManagerData,
  guideTeamData: state => state.guideTeamData,
  areaStaticData: state => state.areaStaticData,
  lineStaticData: state => state.lineStaticData,
  teamSmallData: state => state.teamSmallData,
  needReload: state => state.needReload
};
const actions = {
  // 购物店列表
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return shopList(queryParam).then(res => {
      const {
        data
      } = res;
      commit('SHOP_INPUT_FINACE', data);
      return data;
    });
  },
  // 购物店详情
  detail({
    commit
  }, id) {
    return shopDetail(id).then(res => {
      const {
        data
      } = res;
      commit('CUR_SHOP', data);
      return data;
    });
  },
  // 按大团统计
  getPlanShopStatic({
    commit
  }, opts) {
    return getPlanShopStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SINGLE_DATA', data);
      return data;
    });
  },
  // // 导出大团统计
  fetchPlanShopStaticExcel({
    commit
  }, opts) {
    return exportPlanShopStatic(opts).then(res => {
      return res;
    });
  },
  // 按小团统计
  getOrderShopStatic({
    commit
  }, opts) {
    return getOrderShopStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('TEAM_SMALL_DATA', data);
      return data;
    });
  },
  // // 导出小团统计
  fetchOrderShopStaticExcel({
    commit
  }, opts) {
    return exportOrderShopStatic(opts).then(res => {
      return res;
    });
  },
  // 按区域统计
  getByAreaStatic({
    commit
  }, opts) {
    return getByAreaStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('AREA_STATIC_DATA', data);
      return data;
    });
  },
  // // 导出区域统计
  fetchByAreaStaticExcel({
    commit
  }, opts) {
    return exportByAreaStatic(opts).then(res => {
      return res;
    });
  },
  // 按线路统计
  getByLineStatic({
    commit
  }, opts) {
    return getByLineStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('LINE_STATIC_DATA', data);
      return data;
    });
  },
  // // 导出线路统计
  fetchByLineStaticExcel({
    commit
  }, opts) {
    return exportByLineStatic(opts).then(res => {
      return res;
    });
  },
  // 按购物店统计
  getByShopStatic({
    commit
  }, opts) {
    return getByShopStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SHOP_STATIC_DATA', data);
      return data;
    });
  },
  // // 导出购物店统计
  fetchByShopStaticExcel({
    commit
  }, opts) {
    return exportByShopStatic(opts).then(res => {
      return res;
    });
  },
  // 按客户统计
  getByCustomerStatic({
    commit
  }, opts) {
    return getByCustomerStatic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // // 导出客户统计
  fetchByCustomerStaticExcel({
    commit
  }, opts) {
    return exportByCustomerStatic(opts).then(res => {
      return res;
    });
  },
  // 导游统计
  getByGuideStatic({
    commit
  }, opts) {
    return getByGuideStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('TOTAL_GUIDE', data);
      return data;
    });
  },
  // 导出导游统计
  fetchByGuideStaticExcel({
    commit
  }, opts) {
    return exportByGuideStatic(opts).then(res => {
      return res;
    });
  },
  // 导管统计
  getByGuideManagerStatic({
    commit
  }, opts) {
    return getByGuideManagerStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('GUIDE_M_DATA', data);
      return data;
    });
  },
  // 导出导管统计
  fetchByGuideManagerStaticExcel({
    commit
  }, opts) {
    return exportByGuideManagerStatic(opts).then(res => {
      return res;
    });
  },
  // 导游组长统计
  getByGuideTeamStatic({
    commit
  }, opts) {
    return getByGuideTeamStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('GUIDE_T_DATA', data);
      return data;
    });
  },
  // 导出导游组长统计
  fetchByGuideTeamStaticExcel({
    commit
  }, opts) {
    return exportByGuideTeamStatic(opts).then(res => {
      return res;
    });
  },
  // 购物财务统计
  getShopFinanceStatic({
    commit
  }, opts) {
    return getShopFinanceStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SHOP_FINACE', data);
      return data;
    });
  },
  // 购物进店表
  fetchFhoppingEntry({
    commit
  }, params) {
    return shoppingEntryRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      const addIndex = (it, idx) => {
        it.index = idx + 1;
        it.day = `第${it.day}站`;
        return it;
      };
      commit('SHOP_ENTRY', data.map(addIndex));
      return data;
    });
  },
  // 导出购物财务统计
  fetchShopFinanceStaticExcel({
    commit
  }, opts) {
    return exportShopFinanceStatic(opts).then(res => {
      return res;
    });
  }
};
const mutations = {
  CUR_SHOP(state, data) {
    state.currentShop = data;
  },
  SHOP_FINACE(state, data) {
    state.shopFinanceData = data;
  },
  SHOP_ENTRY(state, data) {
    state.shopEntryList = data;
  },
  SINGLE_DATA(state, data) {
    state.singleData = data;
  },
  TOTAL_GUIDE(state, data) {
    state.totalGuideData = data;
  },
  SHOP_INPUT_FINACE(state, data) {
    state.shopInputData = data;
  },
  SHOP_STATIC_DATA(state, data) {
    state.shopStaticData = data;
  },
  GUIDE_M_DATA(state, data) {
    state.guideManagerData = data;
  },
  GUIDE_T_DATA(state, data) {
    state.guideTeamData = data;
  },
  AREA_STATIC_DATA(state, data) {
    state.areaStaticData = data;
  },
  LINE_STATIC_DATA(state, data) {
    state.lineStaticData = data;
  },
  TEAM_SMALL_DATA(state, data) {
    state.teamSmallData = data;
  },
  RELOAD_DATA(state, flag) {
    this.needReload = flag;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};