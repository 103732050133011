import "core-js/modules/es.array.push.js";
import IconItem from './IconItem';
import { Guides, StatusSend, StatusAuditProgress, BtnLink, CustomerInfo } from '@/components/common';
import { tabMenu, TYPES, FINANCIALAUDITED, WAITAUDIT } from '@/config/audit';
import { permissionMixin, summaryMixin, tableMixin } from '@/mixin';
import { getGuideStatus, getGuideBtnType } from '@/utils';
export default {
  components: {
    IconItem,
    Guides,
    StatusSend,
    BtnLink,
    StatusAuditProgress,
    CustomerInfo
  },
  mixins: [summaryMixin, tableMixin, permissionMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    columnData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pathBase: '/audit/team-single-edit/',
      redirectPath: '/audit/team-single-edit/fee',
      multipleSelection: []
    };
  },
  computed: {
    roleAuth() {
      return this.$store.getters['system/role/rolePermission'];
    }
  },
  watch: {
    roleAuth: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/role/fetchPermission');
        }
      },
      immediate: true
    }
  },
  methods: {
    getGuideStatus,
    getGuideBtnType,
    fetchPlanDetail(planId) {
      this.$store.dispatch('plan/index/fetchPlanById', planId);
    },
    // 获取订单行程
    fetchPlanOrder(id) {
      this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        this.tableData = data.map(it => {
          this.$set(it, 'expand', true);
          return it;
        });
      });
    },
    linkDetail(row) {
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/audit/team-single-detail',
        query: {
          id,
          planType
        }
      });
    },
    currentPermission() {
      const list = this.$store.getters['system/role/rolePermissionList'];
      if (!list || !list.length) return;
      const curNav = list.find(it => it.code === '/audit');
      return curNav.subMenus.find(it => it.code === '/audit/team-single');
    },
    hasMenuPermission(type) {
      const currentMenu = this.currentPermission();
      if (currentMenu) {
        return currentMenu.roleMenuOpts.find(it => it.code === type);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchRemoveAudit() {
      this.$bus.open(() => {
        const optList = this.multipleSelection.filter(it => it.auditStatus == FINANCIALAUDITED).map(it => {
          return {
            auditProgress: FINANCIALAUDITED,
            planId: it.id
          };
        });
        if (!optList.length) return;
        this.$store.dispatch('audit/index/batchCancelAudit', optList).then(() => {
          this.$emit('reload');
        });
      }, '确定批量取消审核吗？');
    },
    handleBatchAudit() {
      this.$bus.open(() => {
        const optList = this.multipleSelection.filter(it => it.auditStatus == WAITAUDIT).map(it => {
          return {
            auditProgress: WAITAUDIT,
            planId: it.id
          };
        });
        if (!optList.length) return;
        this.$store.dispatch('audit/index/batchAudit', optList).then(() => {
          this.$emit('reload');
        });
      }, '确定要批量审核吗？');
    },
    // 审核
    handleAudit(row) {
      const {
        id,
        planType
      } = row;
      const tabStatus = this.pickStatus(row);
      const currentMenu = this.currentPermission();
      let curRole = currentMenu.roleMenuOpts.find(it => it.code == TYPES.fee);
      const hasAuditFee = curRole ? curRole.isRole : false;
      const routerKeys = ['fee', 'guide', 'guide_borrow', 'collect', 'scenic', 'add_scenic', 'hotel', 'restaurant', 'bus_company', 'insurance', 'transport', 'shuttle', 'out_fee', 'shop', 'other_income', 'other_pay'];
      let path = this.redirectPath;
      if (!hasAuditFee) {
        const hasRoleList = currentMenu.roleMenuOpts.filter(it => it.isRole);
        curRole = hasRoleList[0];
        if (!curRole || !routerKeys.includes(curRole.code.toLowerCase())) {
          this.$router.go(-1);
        } else {
          path = this.pathBase + curRole.code.toLowerCase();
        }
      }
      this.fetchPlanDetail(id);

      // tab icon 显示状态
      this.$store.commit('audit/index/TAB_STATUS', tabStatus);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$store.commit('audit/index/BACK_STATUS', false); // 用来判断返回是否要重新刷新数据
      this.$store.commit('audit/index/CUR_ROLE', curRole);
      this.$store.commit('plan/index/FIRST_ENTER', true);
      this.$router.push({
        path,
        query: {
          planId: id,
          id,
          planType
        }
      });
    },
    pickStatus(row) {
      const res = {};
      tabMenu.forEach(item => {
        const val = item.status;
        res[val] = row[val];
      });
      return res;
    },
    handlePrint(row) {
      const {
        id,
        orderType,
        planType
      } = row;
      const {
        toPath
      } = this.$route.query;
      const gateUrl = toPath == '/plan/team-role-arrange' ? 'plan2' : 'plan';
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId: id,
          type: 'plan',
          component: planType == orderType ? 'PlanTeam' : 'PlanPersonal',
          planType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleLog(row) {
      this.$parent.showLog(row);
    }
  }
};