import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/hotel/fetchGroupList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/hotel/hotelGroupData'];
    }
  }

  // mounted() {
  //   if (this.isFirst && !this.data) {
  //     this.fetchData()
  //     this.isFirst = false
  //   }
  // },
};