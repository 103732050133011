var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("startTripDate", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("endTripDate", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("orderDate", $event);
      }
    }
  }), _c("SelectOutCustom", {
    attrs: {
      label: "外转",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selecthotelRegion
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("TableTransfer", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      border: ""
    }
  }), _vm.total > 0 ? [_c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "orders",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.orders) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalPay) + " ")];
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.checkAccountMoney) + " ")];
      }
    }, {
      key: "totalCollect",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalCollect) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalSubsidy) + " ")];
      }
    }, {
      key: "totalPayed",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalPayed) + " ")];
      }
    }, {
      key: "unPay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.unPay) + " ")];
      }
    }, {
      key: "checks",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.checks) + "/" + _vm._s(_vm.statistics.orders) + " ")];
      }
    }], null, false, 318299994)
  })] : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };