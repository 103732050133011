var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TopTitle", {
    attrs: {
      title: "产品行程",
      label: "",
      "has-save": false,
      disabled: _vm.isDisabled,
      "btn-type": "text"
    }
  }), _vm.visible ? _c("TableBase", {
    attrs: {
      data: _vm.itinerariesData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.itineraryDate) + " ")];
      }
    }, {
      key: "itineraryDesc",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.itineraryDesc) + " ")];
      }
    }, {
      key: "breakfast",
      fn: function ({
        scope
      }) {
        return [scope.row.breakfast ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "lunch",
      fn: function ({
        scope
      }) {
        return [scope.row.lunch ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "dinner",
      fn: function ({
        scope
      }) {
        return [scope.row.dinner ? _c("IconRight") : _c("IconWrong")];
      }
    }], null, false, 265216117)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };