import { mapGetters } from 'vuex';

//  回执单 结算单 导游出团表 团队计划表 散客计划表 单团审核表 导游报账表 确认件-转出 确认件-接收
const typeOptions = [{
  value: 'Receipt',
  label: '回执单',
  checked: false
}, {
  value: 'Settlement',
  label: '结算单',
  checked: false
}, {
  value: 'GuidePlanTeam',
  label: '导游出团表',
  checked: false
}, {
  value: 'PlanTeam',
  label: '团队计划表',
  checked: false
}, {
  value: 'PlanPersonal',
  label: '散客计划表',
  checked: false
}, {
  value: 'PlanProfitDetail',
  label: '单团审核表',
  checked: false
}, {
  value: 'GuideSubmit',
  label: '导游报账表',
  checked: false
}
// { value: '', label: '确认件-转出', checked: false },
// { value: '', label: '确认件-接收', checked: false },
];
export default {
  props: {
    edit: {
      type: String,
      default: ''
    },
    head: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'warning'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkAll: false,
      isEdit: false,
      isModify: false,
      isIndeterminate: true,
      checkList: [],
      options: typeOptions,
      formConfigCodes: '',
      curItem: null
    };
  },
  computed: {
    ...mapGetters({
      businessInfo: 'system/companyInfo/businessInfo'
    })
  },
  watch: {
    isEdit: {
      handler: function (val) {
        if (!val && this.isModify) {
          this.$store.dispatch('system/companyInfo/uploadSealImage', {
            ...this.curItem,
            formConfigCodes: this.formConfigCodes
          });
        }
      }
    },
    data: {
      handler: function (list) {
        if (!list || !list.length) return;
        const item = list.find(it => it.name == this.type);
        if (item) {
          this.curItem = item;
          const {
            formConfigCodes
          } = item;
          formConfigCodes.split(',').map(key => {
            const findItem = typeOptions.find(it => it.value == key);
            findItem ? findItem.checked = true : null;
          });
          this.options = typeOptions;
        }
      }
    }
  },
  methods: {
    handleChange(val) {
      this.isModify = true;
      const result = val.map(key => {
        const item = typeOptions.find(it => it.label == key);
        return item.value;
      });
      this.formConfigCodes = result.join(',');
    }
  }
};