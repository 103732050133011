import { parseTime } from '@/utils';
import TopTitle from './components/TopTitle';
const tableHead = [{
  content: 'serialNumber',
  label: '序号',
  span: 2,
  slot: 'serialNumber'
}, {
  content: 'type',
  label: '类型',
  span: 2
}, {
  content: 'name',
  label: '名称',
  span: 4
}, {
  content: 'signNumber',
  label: '号码',
  span: 4
}];
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      tableHead,
      tableData: [],
      today: parseTime(new Date()),
      planInfo: '',
      guideInfo: '',
      currentItem: null,
      id: '',
      guideName: '',
      planNumber: ''
    };
  },
  computed: {
    ticketItems() {
      return this.$store.getters['ticket/index/ticketItems'];
    }
  },
  created() {
    const {
      id,
      guideName,
      planNumber
    } = this.$route.query;
    this.id = id;
    this.planNumber = planNumber;
    this.guideName = guideName;
    // this.fetchData()
    this.fetchTicketItems();
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('ticket/index/fetchPlan', this.id).then(data => {
        this.currentItem = data.list.find(it => it.id == this.id);
        return data;
      });
    },
    // 签单列表
    fetchTicketItems() {
      this.$store.dispatch('ticket/index/fetchTicketItems', this.id).then(data => {
        this.tableData = data;
        this.handlePrint();
      });
    },
    handlePrint() {
      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    }
  }
};