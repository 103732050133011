import request from '@/utils/request';
import boatRequest from '@/utils/requestBoat';
import { getBoatRiverData } from '@/utils/cookie';
import store from '@/store';
const getHeaders = token => ({
  Accept: 'application/json, text/plain, */*',
  'Accept-Language': 'en,zh-CN;q=0.9,zh;q=0.8',
  'Tenant-Id': 0,
  'X-Access-Token': token,
  'X-Sign': 'C8B8146B92CC4720BB091D2D2E977B6D',
  'X-Timestamp': Date.now()
});
const getToken = () => {
  const boatToken = getBoatRiverData();
  const token = store.getters['plan/boat/boatToken'];
  return boatToken && boatToken.token || token;
};

// const HEADERS_OTHER = {
//   'Accept-Encoding': 'gzip, deflate',
//   Connection: 'keep-alive',
//   Cookie: 'username=glxygj',
//   Host: '180.140.114.121:8088',
//   Referer: 'http://180.140.114.121:8088/guide/GuideList',
//   'User-Agent':
//     'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
// }

// 获取token
export const tokenRequest = data => {
  return request({
    url: '/order/plan/digital-li-jiang-token',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

// 获取景区公司
export const scenicCompanyRequest = () => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/enterprise/enterprise/getListByServiceType',
    method: 'get',
    params: {
      _t: Date.now(),
      serviceType: 'CPDW'
    },
    headers: getHeaders(boatToken)
  });
};

// 获取录入列表
export const guideListRequest = params => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/guide/guide/list',
    method: 'get',
    params,
    headers: getHeaders(boatToken)
  });
};

// 添加
// {"appointmentTime":"2023-12-01 10:31:38","enterpriseId":"1630405283912105985","guide":"赵文苗","phone":"13517530107","rideBy":"boat","remark":"none","touristList":[{"id":"16939675208240639873","touristName":"李铁柱","documentNo":"120101195308085517","documentType":"1"}]}
export const guideAddRequest = data => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/guide/guide/add',
    method: 'post',
    data,
    headers: getHeaders(boatToken)
  });
};

// 修改
// {"id":"1699249433008414721","importCode":"2023090633690910","enterpriseId":"1630405283912105985","guide":"赵文苗","phone":"13517530107","rideBy":"raft","importTime":"2023-09-06 10:33:30","appointmentTime":"2023-12-01 10:31:38","createBy":"glxygj","createTime":"2023-09-06 10:33:30","updateBy":null,"updateTime":null,"sysOrgCode":null,"remark":"none","recId":"1693608296088371201","status":"1","statusDictText":"待核销","enterpriseId_dictText":"阳朔县漓江景区管理有限公司","rideBy_dictText":"游船","touristList":[{"id":"16939675208240639873","touristName":"李铁柱","documentNo":"120101195308085517","documentType":"1"}]}
export const guideEditRequest = data => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/guide/guide/edit',
    method: 'put',
    data,
    headers: getHeaders(boatToken)
  });
};

// 详情
// queryTouristByMainId?_t=1693968154&id=1699249433008414721
/**
 *
 * @param {*} id
 * @returns [{}]
 */
export const guideDetailRequest = id => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/guide/guide/queryTouristByMainId',
    method: 'get',
    params: {
      _t: Date.now(),
      id
    },
    headers: getHeaders(boatToken)
  });
};

/**
 * 删除
 * @param {*} id
 * @returns { success: true }
 */
export const guideDeleteRequest = id => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/guide/guide/deleteById',
    method: 'delete',
    params: {
      id
    },
    headers: getHeaders(boatToken)
  });
};

/**
 * 打印明细
 * @param {*}
 * @returns { obj }
 *
 * {"id":"785725929119690752","apiUrl":"","params":"{\"id\":\"1697838404475035650\",\"ip\":\"180.140.114.121\",\"port\":\"8088\",\"guideId\":\"\",\"pageNo\":1,\"pageSize\":10,\"jmViewFirstLoad\":\"1\"}"}
 */
export const touristListRequest = opts => {
  const boatToken = getToken();
  return boatRequest.request({
    url: '/scenic/jmreport/show',
    method: 'post',
    data: {
      apiUrl: '',
      id: '785725929119690752',
      params: JSON.stringify(opts.params)
    },
    headers: getHeaders(boatToken)
  });
};