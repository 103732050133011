import { InputCustom } from '@/components/common';
export default {
  components: {
    InputCustom
  },
  data() {
    return {
      visible: false,
      customerName: '',
      customerId: ''
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.customerId = '';
      this.customerName = '';
    },
    handleSelect(val) {
      this.customerId = val.id;
    },
    handleCreate() {
      this.$parent.handleTranslate(this.customerId);
      this.hide();
    }
  }
};