/*
 * @Author: cyg
 * @Date: 2020-09-03 15:28:33
 * @LastEditors: cyg
 * @LastEditTime: 2023-02-20 15:04:45
 * @FilePath: \travel-admin\src\api\system\accounting.js
 */
import service from '@/utils/request';

/**
 * 获取会计科目列表
 * @param {object} data 搜索数据
 */
export function fetchSubjectList(data) {
  return service({
    url: '/system/subject/list',
    method: 'GET',
    data
  });
}
/**
 * 获取会计科目列表（带级别）
 * @param {object} data 搜索数据
 */
export function fetchSubjectListLevel(params) {
  return service({
    url: '/system/subject/listLevel',
    method: 'GET',
    params
  });
}
/**
 * 获取一级会计科目列表
 */
export function fetchOneList(data) {
  return service({
    url: '/system/subject/oneList',
    method: 'GET',
    data
  });
}

/**
 * 新增、编辑会计科目
 */
export function saveOrUpdate(data) {
  return service({
    url: '/system/subject/saveOrUpdate',
    method: 'POST',
    data
  });
}

/**
 * 删除会计科目
 */
export function delSubject(data) {
  return service({
    url: '/system/subject/del',
    method: 'POST',
    data
  });
}
/**
 * 新增现金流日志
 */
export function saveCash(data) {
  return service({
    url: '/log/cash/save',
    method: 'POST',
    data
  });
}
/**
 * 查询现金流日志
 */
export function fetchCash(params) {
  return service({
    url: '/log/cash/page',
    method: 'GET',
    params
  });
}
/**
 * 查询现金流日志
 */
export function delCash(data) {
  return service({
    url: '/log/cash/delById',
    method: 'POST',
    data
  });
}