import { DetailFund, DetailProxy, DetailRefund } from '@/components/common';
export default {
  components: {
    DetailFund,
    DetailProxy,
    DetailRefund
  },
  data() {
    return {
      visible: false,
      orderData: {}
    };
  },
  mounted() {},
  methods: {
    show(data) {
      this.visible = true;
      const {
        id,
        isReview
      } = data;
      this.$nextTick(() => {
        this.$refs.fundTableRef.fetchData(id, isReview);
        this.$refs.proxyTableRef.fetchData(id, isReview);
        this.$refs.refundTableRef.fetchData(id, isReview);
      });
    }
  }
};