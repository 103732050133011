import "core-js/modules/es.array.reduce.js";
export default {
  name: 'CheckboxGroup',
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkList: [],
      typeList: this.options,
      verticalStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '15px'
      }
    };
  },
  watch: {
    options: {
      handler: function (list) {
        this.typeList = list;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    filterValue() {
      return this.options.reduce((acc, val) => {
        return [...acc, val.label];
      }, []);
    },
    handleCheckAllChange(val) {
      this.checkList = val ? this.filterValue() : [];
      this.isIndeterminate = false;
      this.$emit('on-change', this.checkList);
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.typeList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.typeList.length;
      this.$emit('on-change', value);
    }
  }
};