var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单信息",
      width: "90%",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planCustomerNumber)), _c("br"), _vm._v(" " + _vm._s(scope.row.customerTripNumber)), _c("br")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + "大 " + _vm._s(scope.row.childCount) + "小 " + _vm._s(scope.row.accompanyCount) + "陪 ")];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [_c("Members", {
          attrs: {
            row: scope.row,
            len: 2
          }
        })];
      }
    }, {
      key: "pick",
      fn: function ({
        scope
      }) {
        return [scope.row.pickList.length ? _vm._l(scope.row.pickList, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.formatTime(item.shiftDateTime)) + " " + _vm._s(item.shiftNumber) + " ")]);
        }) : _vm._e()];
      }
    }, {
      key: "send",
      fn: function ({
        scope
      }) {
        return [scope.row.sendList.length ? _vm._l(scope.row.sendList, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.formatTime(item.shiftDateTime)) + " " + _vm._s(item.shiftNumber) + " ")]);
        }) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onLookOver(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };