import "core-js/modules/es.array.push.js";
import TableUnPay from './components/TableUnPay';
import TablePayed from './components/TablePayed';
import { formatCommitTableData } from '@/utils/data';
import { FooterBar, SelectHotel, BillInput } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { getPayedMoney } from '@/views/billDown/help';
import { TYPES } from '@/config/billDown';
export default {
  components: {
    FooterBar,
    SelectHotel,
    BillInput,
    TableUnPay,
    TablePayed
  },
  mixins: [tagViewMixin],
  data() {
    return {
      TYPES,
      exportLoading: false,
      total: 0,
      resourceId: '',
      orderId: '',
      backPath: '/bill/unit-pay/hotel'
    };
  },
  computed: {
    ...mapGetters({
      payedData: 'bill/billDown/payUnitHotel/payedData'
    }),
    payedList() {
      return this.payedData ? this.payedData.list : [];
    }
  },
  mounted() {
    const {
      rid
    } = this.$route.query;
    this.resourceId = rid;
  },
  methods: {
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.unPayRef.fetchData({
        resourceId
      });
    },
    changeMoney(money) {
      this.money = money - getPayedMoney(this.payedList);
      this.$refs.unPayRef.caclDownMoney(this.money);
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    processData(form) {
      const {
        id
      } = this.$route.query;
      const {
        cashWay,
        cashUserId,
        money
      } = form;
      const tableData = this.$refs.unPayRef.tableData;
      const getCashs = it => {
        return {
          cash: it.downPayed || it.cash,
          cashWay,
          cashUserId,
          orderId: it.rowId || it.customerOrderId,
          // 订单id， 其他为资源id
          type: it.type ? it.type : 'NONE'
        };
      };
      const list = tableData.filter(it => it.isChecked);
      const newList = list.map(getCashs);
      let opts = {
        ...form,
        money,
        cashes: formatCommitTableData(newList),
        cashType: TYPES.hotel,
        id
      };
      delete opts.subject;
      return opts;
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const loading = this.$bus.loading();
        const opts = this.processData(form);
        this.$store.dispatch('bill/billDown/pay/updatePayed', opts).then(() => {
          loading.close();
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};