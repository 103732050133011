import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { processAdd, decimalSplit, processMinus } from './';
const PeopleKeys = {
  peopleCount: 'peopleCount',
  adultCount: 'adultCount',
  childCount: 'childCount',
  accompanyCount: 'accompanyCount'
};
export function getSummaries(param) {
  const {
    columns,
    data
  } = param;
  const sums = [];
  const sumsObj = {};
  return ({
    statisticsKeys = [],
    needSumKeys = [],
    callback
  } = {}) => {
    columns.forEach((column, index) => {
      let last;
      if (index === 0) {
        sums[index] = '小计';
        return;
      }
      const values = data.map(item => {
        // 只合计设定的字段
        if (statisticsKeys.includes(column.property)) {
          // 处理多个子项合计
          if (last && item.rowId && last.rowId == item.rowId && !needSumKeys.includes(column.property)) {
            return 0;
          } else {
            last = item;
            return item[column.property];
          }
        }
      });
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            prev = processAdd(prev, curr);
          }
          return prev;
        }, 0);
        sumsObj[column.property] = {
          index,
          value: sums[index]
        };
        sums[index] = decimalSplit(sums[index]);
      }
    });
    return callback ? callback({
      sums,
      sumsObj
    }) : sums;
  };
}

/**
 * 表格小计均分
 * @param {*} sums 计算后的数组
 * @param {*} sumsObj 对应sums里每项index的值与下标的对象
 * @param {*} keyObj 需要单独处理小计的对象，比如人均，不是累加
 * @param {*} statistics 总计对象数据
 * @returns
 */
export function handleSumAvg({
  sums,
  sumsObj,
  keyObj,
  statistics = {},
  sumDivisor = null
}) {
  Object.keys(keyObj).forEach(key => {
    const [dividend, divisor] = keyObj[key];
    if (sumsObj[key]) {
      const {
        index
      } = sumsObj[key];
      const {
        value
      } = sumsObj[dividend];
      let subtotal = 0;
      if (sumDivisor) {
        subtotal = sumDivisor;
      } else {
        const {
          value: value2
        } = sumsObj[divisor];
        subtotal = value2 != 0 ? decimalSplit(Math.round(value / value2)) : 0;
      }
      const allTotal = statistics[key];
      sums[index] = allTotal ? decimalSplit(allTotal) : decimalSplit(subtotal);
      // sums[index] = decimalSplit(subtotal) + '\n' + decimalSplit(allTotal)
    }
  });
  return sums;
}

// 计算人数小计
function calcPeopleCount(data) {
  const calc = key => {
    return data.reduce((acc, cur) => {
      acc = processAdd(acc, cur[key] || 0);
      return acc;
    }, 0);
  };
  return {
    adultCount: calc('adultCount'),
    childCount: calc('childCount'),
    accompanyCount: calc('accompanyCount')
  };
}

// 根据key查找表格下标
export function findColumnIndexByKey(key, columns) {
  return columns.findIndex(column => column.property === key);
}

// 人数字段统计值
export function formatPeopleCountValueByKey({
  key = 'peopleCount',
  data,
  columns
  // statistics,
}) {
  const res = calcPeopleCount(data);
  const value = formatPeopleValue(res);
  const index = findColumnIndexByKey(key, columns);

  // if (statistics) {
  //   const totalValue = formatPeopleValue(statistics)
  //   return { index, value: value + '\n' + totalValue }
  // }
  // return { index, value: value + '\n' + value }
  return {
    index,
    value
  };
}
export function formatPeopleValue(data) {
  if (!data) return '';
  const val1 = data['adultCount'] || 0;
  const val2 = data['childCount'] || 0;
  const val3 = data['accompanyCount'] || 0;
  return `${val1}大${val2}小${val3}陪`;
}
export function handlePeopleSummary({
  sums,
  sumsObj,
  statistics = {}
}) {
  Object.keys(PeopleKeys).forEach(key => {
    if (sumsObj[key]) {
      const {
        index
      } = sumsObj[key];
      const subtotal = formatPeopleValue(sumsObj);
      const allTotal = formatPeopleValue(statistics);
      sums[index] = subtotal + '\n' + allTotal;
    }
  });
  return sums;
}

/**
 * 获取统计的key
 */
export function processStatisticsKeys(keys) {
  const toString = Object.prototype.toString;
  const isObj = toString.call(keys) == '[object Object]';
  const result = isObj ? keys : keys.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});
  return {
    ...result,
    ...PeopleKeys
  };
}

// 累加合并表格某个字段的值
export function calcMergeRowTotalByKey(data, key, newKey) {
  const listArr = data.reduce((acc, cur) => {
    const val = cur.rowId;
    if (!acc[val]) {
      acc[val] = [];
    }
    acc[val].push(cur[key]);
    return acc;
  }, {});
  return data.map(it => {
    const list = listArr[it.rowId];
    let total = 0;
    if (list && list.length) {
      total = list.reduce((acc, cur) => (acc, processAdd(acc, cur)), 0);
    }
    return {
      ...it,
      [newKey]: total
    };
  });
}

// 计算成本 单价* (数量-免去) + 其他
export function calcRowCostTotal({
  price,
  count = 0,
  freeCount = 0,
  otherFee = 0
}) {
  const num = price * processMinus(count, freeCount);
  return processAdd(num, otherFee);
}

// 计算相同id和合计金额
export function clacMergeRowByRowId({
  data,
  rowId,
  priceKey = 'price'
}) {
  const list = data.filter(it => it.rowId == rowId);
  return list.reduce((acc, cur) => {
    const val = calcRowCostTotal({
      ...cur,
      price: cur[priceKey]
    });
    acc = processAdd(acc, val);
    return acc;
  }, 0);
}

// 计算相同id和合计金额
export function clacMergeRowByRowIdAndMoney({
  data,
  rowId,
  priceKey = 'price'
}) {
  const list = data.filter(it => it.rowId == rowId);
  return list.reduce((acc, cur) => {
    acc = processAdd(acc, cur[priceKey]);
    return acc;
  }, 0);
}