/* eslint-disable no-empty-pattern */
import { targetRequest } from '@/api/analysis';
function fetchTarget(params, type = 'trip') {
  return new Promise((resolve, reject) => {
    targetRequest({
      ...params,
      searchType: type
    }).then(res => {
      if (!res) {
        reject();
        return;
      }
      const {
        data
      } = res;
      resolve(data);
    }).catch(error => {
      reject(error);
    });
  });
}
const defaultState = () => ({
  userData: null,
  departmentData: null,
  tripData: null
});
const state = defaultState();
const getters = {
  tripData: state => state.tripData,
  userData: state => state.userData,
  departmentData: state => state.departmentData
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
const actions = {
  async tripList({
    commit
  }, params) {
    const data = await fetchTarget(params);
    commit('SET_DATA', {
      type: 'tripData',
      data
    });
  },
  async userList({
    commit
  }, params) {
    const data = await fetchTarget(params, 'user');
    commit('SET_DATA', {
      type: 'userData',
      data
    });
  },
  async departmentList({
    commit
  }, params) {
    const data = await fetchTarget(params, 'dp');
    commit('SET_DATA', {
      type: 'departmentData',
      data
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};