/* eslint-disable no-empty-pattern */
import { inquireBusListRequest, inquireBusSetRequest, inquireBusDetailRequest, inquireBusDelRequest } from '@/api';
const dfaultState = () => ({
  data: null
});
const state = dfaultState();
const getters = {
  data: state => state.data
};
const actions = {
  list({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      inquireBusListRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          const {
            data
          } = res;
          commit('SET_DATA', {
            type: 'data',
            data
          });
          resolve(data);
        }
      });
    });
  },
  set({}, opt) {
    return new Promise((resolve, reject) => {
      inquireBusSetRequest(opt).then(res => {
        if (!res) {
          reject(res);
        } else {
          const {
            data
          } = res;
          resolve(data);
        }
      });
    });
  },
  detail({}, opt) {
    return new Promise((resolve, reject) => {
      inquireBusDetailRequest(opt).then(res => {
        if (!res) {
          reject(res);
        } else {
          const {
            data
          } = res;
          resolve(data);
        }
      });
    });
  },
  del({}, opt) {
    return new Promise((resolve, reject) => {
      inquireBusDelRequest(opt).then(res => {
        if (!res) {
          reject(res);
        } else {
          const {
            data
          } = res;
          resolve(data);
        }
      });
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};