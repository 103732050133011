import "core-js/modules/es.array.reduce.js";
// export const DepartmentIdKeys = [
//   'departmentId',
//   'subDepartmentId',
//   'grandsonDepartmentId',
// ]

export const DepartmentNameKeys = ['departmentName', 'subDepartmentName', 'grandsonDepartmentName'];
export function getSearchCache(store, path) {
  const curTag = store.getters['tagsView/visitedViews'].find(tag => tag.path === path);
  return curTag ? curTag.search : null;
}
export function getSearchDate(data, key) {
  const start = key + 'S';
  const end = key + 'E';
  const val1 = data[start] || '';
  const val2 = data[end] || '';
  return [val1, val2];
}
export function getSearchDate2(data, key) {
  const start = 'start' + key;
  const end = 'end' + key;
  const val1 = data[start] || '';
  const val2 = data[end] || '';
  return [val1, val2];
}
export function getSearchDepartment(data) {
  return DepartmentNameKeys.reduce((acc, key) => {
    const val = data[key];
    return val ? [...acc, val] : acc;
  }, []);
}