/*
 * @Author: cyg
 * @Date: 2020-09-03 15:28:33
 * @LastEditors: cyg
 * @LastEditTime: 2022-11-10 17:06:44
 * @FilePath: \travel-admin\src\api\index.js
 */
import request from '@/utils/request';
export * from './inquire/bus';

// 获取阿里云 OSS Token
export const getOssToken = () => {
  return request({
    url: '/upload/up',
    method: 'post'
  });
};

// 上传到本地服务器
export const uploadFile = formData => {
  const res = request({
    url: '/upload/up',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res;
};

// 删除文件
export const deleteFile = data => {
  return request({
    url: '/upload/del',
    method: 'post',
    data
  });
};

// 获取文件路径
export const getFileUrl = data => {
  return request({
    url: '/upload/url',
    method: 'post',
    data
  });
};

// 获取航班火车时间
export const fetchTrafficTime = params => {
  // const url = parseQuerys('order/trainAndFlight', queryParam)
  return request({
    url: 'order/trainAndFlight',
    method: 'get',
    params
  });
};

// // 获取旅行社菜单
// export const fetchTripMenu = data => {
//   return request({
//     url: 'system/trip/listMenus',
//     method: 'post',
//     data,
//   })
// }

// 添加旅行社菜单
export const addTripMenu = data => {
  return request({
    url: 'system/trip/addMenu',
    method: 'post',
    data
  });
};

// 删除旅行社菜单
export const delTripMenu = data => {
  return request({
    url: 'system/trip/delMenu',
    method: 'post',
    data
  });
};

// 首页统计数据
export const fetchTripMainCount = params => {
  return request({
    url: 'order/data/trip-main-count',
    method: 'get',
    params
  });
};

// 导管未安排
export const guideManagerUnArrangeRequest = params => {
  return request({
    url: 'order/data/countPlansByUnArrangeGuide',
    method: 'get',
    params
  });
};

// 发送短信类