import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
/*
 * @Author: cyg
 * @Date: 2023-08-10 16:02:35
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-28 08:44:38
 * @FilePath: \travel-admin\src\router\modules\offer.js
 */
// 报价管理
import Layout from '@/layout';
const offerRouter = {
  path: '/offer',
  component: Layout,
  redirect: '/offer/index',
  name: 'offer',
  meta: {
    title: 'offer',
    icon: 'icon-waibi-zijinchizhanghujixianechaxun'
  },
  children: [{
    path: '/offer/index',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/offer/index'))),
    name: 'offerIndex',
    meta: {
      title: 'offerIndex',
      icon: 'icon-file-word'
    }
  }, {
    path: '/offer/add',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/offer/reoffer'))),
    name: 'formOfferAdd',
    // 报价新增、编辑
    meta: {
      title: 'formOfferAdd',
      auth: true
    },
    hidden: true
  }, {
    path: '/offer/edit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/offer/reoffer'))),
    name: 'formOfferEdit',
    // 报价新增、编辑
    meta: {
      title: 'formOfferEdit',
      auth: true
    },
    hidden: true
  }, {
    path: '/offer/code',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/offer/reoffer'))),
    name: 'formOfferCopy',
    // 报价新增、编辑、复制
    meta: {
      title: 'formOfferCopy',
      auth: true
    },
    hidden: true
  }]
};
export default offerRouter;