var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_vm.isSideBar ? _c("div", {
    staticClass: "icon-wrap"
  }, [_c("span", {
    staticClass: "iconfont",
    class: _vm.isCollapse ? "icon-menuoff" : "icon-menuon",
    on: {
      click: _vm.toggleSideBar
    }
  })]) : _vm._e(), _vm.notDashboard ? _c("Breadcrumb") : _vm._e(), _c("div", {
    staticClass: "out-login"
  }, [_c("el-button", {
    attrs: {
      plain: ""
    },
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_vm._v(" 退出 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };