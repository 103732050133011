// translate router.meta.title, be used in breadcrumb sidebar tagsview
import { defaultSettings } from '@/config/setting';
import i18n from '@/lang';
const title = defaultSettings.title;
export function getPageTitle(key) {
  const hasKey = i18n.te(`route.${key}`);
  if (hasKey) {
    const pageName = i18n.t(`route.${key}`);
    return `${pageName} - ${title}`;
  }
  return `${title}`;
}
export function generateTitle(title) {
  const hasKey = i18n.te('route.' + title);
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    const translatedTitle = i18n.t('route.' + title);
    return translatedTitle;
  }
  return title;
}