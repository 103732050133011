/* eslint-disable no-unused-vars */
import { bookingSend, bookingHotelList, bookingHotelDetail, bookingFleetList, bookingFleetDetail, bookingScenicList, bookingScenicDetail, bookingRestaurantList, bookingRestaurantDetail, bookingHotelSet, bookingScenicSet, bookingFleetSet, bookingRestaurantSet, bookingDelete, bookingConfirmApply, bookingCancelOrder, bookingToggleReview, bookingToggleApply } from '@/api/order/resource';
import { ResourceType } from '@/config/billCheck';
const dfaultState = () => ({
  resourceHotelOrder: null,
  resourceFleetOrder: null,
  resourceScenicOrder: null,
  resourceRestaurantOrder: null
});
const state = dfaultState();
const getters = {
  resourceHotelOrder: state => state.resourceHotelOrder,
  resourceFleetOrder: state => state.resourceFleetOrder,
  resourceScenicOrder: state => state.resourceScenicOrder,
  resourceRestaurantOrder: state => state.resourceRestaurantOrder
};
const actions = {
  // 预定资源
  bookingSend({
    commit
  }, opts) {
    return bookingSend(opts);
  },
  // 预定酒店订单列表
  fetchBookingHotelList({
    commit
  }, params) {
    return bookingHotelList(params).then(res => {
      const {
        data
      } = res;
      commit('RESOURCE_HOTEL_ORDER', data);
      return data;
    });
  },
  // 预定酒店订单详情
  fetchBookingHotelDetail({
    commit
  }, params) {
    return bookingHotelDetail(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定车队订单列表
  fetchBookingFleetList({
    commit
  }, params) {
    return bookingFleetList(params).then(res => {
      const {
        data
      } = res;
      commit('RESOURCE_FLEET_ORDER', data);
      return data;
    });
  },
  // 预定车队订单详情
  fetchBookingFleetDetail({
    commit
  }, params) {
    return bookingFleetDetail(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定景区订单列表
  fetchBookingScenicList({
    commit
  }, params) {
    return bookingScenicList(params).then(res => {
      const {
        data
      } = res;
      commit('RESOURCE_SCENIC_ORDER', data);
      return data;
    });
  },
  // 预定景区订单详情
  fetchBookingScenicDetail({
    commit
  }, params) {
    return bookingScenicDetail(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定餐厅订单列表
  fetchBookingRestaurantList({
    commit
  }, params) {
    return bookingRestaurantList(params).then(res => {
      const {
        data
      } = res;
      commit('RESOURCE_RESTAURANT_ORDER', data);
      return data;
    });
  },
  // 预定餐厅订单详情
  fetchBookingRestaurantDetail({
    commit
  }, params) {
    return bookingRestaurantDetail(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定订单删除
  bookingDelete({
    commit
  }, {
    id,
    orderType = ResourceType.hotel
  }) {
    return bookingDelete({
      id,
      orderType: orderType.toUpperCase()
    }).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 酒店预定订单保存/修改
  bookingHotelSet({
    commit
  }, opts) {
    return bookingHotelSet(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 景区预定订单保存/修改
  bookingScenicSet({
    commit
  }, opts) {
    return bookingScenicSet(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 餐厅预定订单保存/修改
  bookingRestaurantSet({
    commit
  }, opts) {
    return bookingRestaurantSet(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 车队预定订单保存/修改
  bookingFleetSet({
    commit
  }, opts) {
    return bookingFleetSet(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 酒店预定订单 取消申请
  bookingCancelOrder({
    commit
  }, id) {
    return bookingCancelOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定订单 确认申请
  bookingConfirmApply({
    commit
  }, id) {
    return bookingConfirmApply(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定订单 申请状态切换
  bookingToggleApply({
    commit
  }, opts) {
    return bookingToggleApply(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 预定订单 确认状态切换
  bookingToggleReview({
    commit
  }, opts) {
    return bookingToggleReview(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  RESOURCE_HOTEL_ORDER: (state, data) => {
    state.resourceHotelOrder = data;
  },
  RESOURCE_FLEET_ORDER: (state, data) => {
    state.resourceFleetOrder = data;
  },
  RESOURCE_SCENIC_ORDER: (state, data) => {
    state.resourceScenicOrder = data;
  },
  RESOURCE_RESTAURANT_ORDER: (state, data) => {
    state.resourceRestaurantOrder = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};