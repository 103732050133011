import "core-js/modules/es.array.reduce.js";
import { decimalSplit } from '@/utils';
import { processAdd } from '@/utils/number';
const StyleMap = ['总购物金额', '总购物人均', '剩余团款', '导游收益', '公司收益', '二返收益'];
export default {
  data() {
    return {
      sumsIndex: 3,
      shopStarIndex: 0
    };
  },
  methods: {
    changeShopCellStyle(row) {
      if (StyleMap.includes(row.column.label)) {
        return 'cell-blue'; //修改的样式
      }
      if (row.column.label === '人均') {
        return 'cell-green';
      }
      return '';
    },
    getShopSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      const avgRes = {};
      const avgIdx = {};
      const avgMaps = this.avgeageMaps;
      if (avgMaps) {
        avgMaps.forEach(item => {
          const {
            hasChild,
            divisor,
            dividend,
            value
          } = item;

          // 获取需要的字段数据
          const avgList = data.map(it => {
            if (hasChild) {
              return {
                ...it[item.key],
                [item['divisor']]: it[divisor]
              };
            } else {
              return it;
            }
          });
          // 总人数
          const totalPeople = avgList.reduce((acc, cur) => (acc, Number(cur[divisor]) + Number(acc)), 0);
          // 总金额
          const totalBuyMoney = avgList.reduce((acc, cur) => (acc, Number(cur[dividend]) + Number(acc)), 0);
          avgRes[value] = decimalSplit(Number(totalBuyMoney / totalPeople));
        });
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        if (avgMaps) {
          avgMaps.forEach(item => {
            const {
              value
            } = item;
            if (value.includes(column.property)) {
              avgIdx[column.property] = index;
            }
          });
        }
        const values = data.map(item => Number(item[column.property]));

        // if (index > this.sumsIndex) {
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return processAdd(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          // 人均毛利(兼容老版本)
          if (this.avgeageKey && this.avgeageKey.length) {
            const avgeageKey = this.avgeageKey;
            const res = sums[avgeageKey[1]] / sums[avgeageKey[0]];
            sums[avgeageKey[2]] = Number(res).toFixed(2);
          }
        }
      });
      Object.keys(avgRes).map(key => {
        const i = avgIdx[key];
        sums[i] = avgRes[key];
      });
      return sums;
    }
  }
};