import billDownMixin from '@/views/billDown/billDownMixin';
import { rowspanMixin } from '@/mixin';
import { Guides } from '@/components/common';
import { shoppingCollectSubsidy as columnData, RebateType } from '@/config/shopping';
export default {
  components: {
    Guides
  },
  mixins: [rowspanMixin, billDownMixin],
  data() {
    return {
      columnData,
      rebateType: RebateType,
      billCheckAll: false,
      isShop: true,
      tableData: [],
      total: 0,
      resourceId: '',
      statisticsKeys: ['parkingFeeRebate', 'memberRebate', 'shopRebate', 'parkingFeeRebated', 'memberRebated', 'guideCollect', 'shopRebated', 'subsidy', 'subsidyKP', 'unSubsidyKP', 'downPayed']
    };
  },
  methods: {
    fetchData(params = {}) {
      if (!this.resourceId && !params.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择单位'
        });
        return;
      }
      this.resourceId = params.resourceId;
      this.fetchUnPayData(params);
    },
    fetchUnPayData(params = {}) {
      return this.$store.dispatch('bill/billDown/fetchUnpayShopSubsidy', {
        ...params,
        resourceId: this.resourceId,
        rebateType: 'subsidy'
      }).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    handlePage(params) {
      this.fetchUnPayData(params);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = this.formatData(list);
      this.handleAutoDown(this.calcTotalMoney());
      // 重置余额
      this.restoreBalance();
    },
    formatData(list) {
      if (!list || !list.length) [];
      const map = {
        AGENCY: 'unShopRebated',
        MEMBER: 'unMemberRebated',
        RED: 'unRedEnvelopeRebated',
        PARK: 'unParkingFeeRebated',
        SUBSIDY: 'unSubsidyKP' // 二返
      };
      return list.map(it => {
        const owePay = it.unSubsidyKP;
        it.rebateObj = {};
        Object.keys(RebateType).forEach(key => {
          this.$set(it, RebateType[key], it[map[RebateType[key]]]);
        });
        it.owePay = it.owePayCopy = owePay;
        it.downPayed = '';
        it.isChecked = false;
        it.guides = it.reimbGuides;
        return it;
      });
    },
    handleSelect(rid) {
      this.resourceId = rid;
      this.fetchUnPayData();
      // 重置余额
      this.restoreBalance();
    },
    caclDownMoney(money) {
      if (!this.tableData.length) return;

      // 设置下账金额
      this.handleAutoDown(money);
    }
  }
};