var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": function ($event) {
        return _vm.$emit("search", $event);
      }
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("startTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("endTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "集团"
    }
  }, [_c("SelectGroup", {
    model: {
      value: _vm.searchParams.kpCompanyId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "kpCompanyId", $$v);
      },
      expression: "searchParams.kpCompanyId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游"
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.guideId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideId", $$v);
      },
      expression: "searchParams.guideId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "项目"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.title,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "title", $$v);
      },
      expression: "searchParams.title"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("export", _vm.searchParams);
      }
    }
  }, [_vm._v(" 导出 ")])], 1), _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "备注"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "remarks", $$v);
      },
      expression: "searchParams.remarks"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "审核状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      clearable: "",
      label: "",
      type: "examineSearch"
    },
    model: {
      value: _vm.searchParams.auditProgress,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditProgress", $$v);
      },
      expression: "searchParams.auditProgress"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "付款方式"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "payType3"
    },
    model: {
      value: _vm.searchParams.payType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "payType", $$v);
      },
      expression: "searchParams.payType"
    }
  })], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };