var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "serialNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("TagGroup", {
          attrs: {
            "group-id": scope.row.groupId
          }
        }), _vm._v(" " + _vm._s(scope.row.type) + " ")];
      }
    }, {
      key: "spend",
      fn: function ({
        scope
      }) {
        return [_c("el-checkbox", {
          attrs: {
            disabled: _vm.isDisabled || scope.row.autoRelease
          },
          model: {
            value: scope.row.isUsed,
            callback: function ($$v) {
              _vm.$set(scope.row, "isUsed", $$v);
            },
            expression: "scope.row.isUsed"
          }
        })];
      }
    }, {
      key: "destroy",
      fn: function ({
        scope
      }) {
        return [_c("el-checkbox", {
          attrs: {
            disabled: _vm.isDisabled || scope.row.isUsed
          },
          model: {
            value: scope.row.autoRelease,
            callback: function ($$v) {
              _vm.$set(scope.row, "autoRelease", $$v);
            },
            expression: "scope.row.autoRelease"
          }
        })];
      }
    }, {
      key: "turn",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text",
            disabled: _vm.isDisabled
          },
          on: {
            click: function ($event) {
              return _vm.handleTurn(scope.row.id);
            }
          }
        }, [_vm._v(" 转 ")])];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            disabled: _vm.isDisabled
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-checkbox", {
          attrs: {
            disabled: _vm.isDisabled
          },
          on: {
            change: function ($event) {
              return _vm.handleCheckOne(scope.row, $event);
            }
          },
          model: {
            value: scope.row.isChecked,
            callback: function ($$v) {
              _vm.$set(scope.row, "isChecked", $$v);
            },
            expression: "scope.row.isChecked"
          }
        }), _c("span", {
          staticClass: "pr5"
        }), _c("el-button", {
          attrs: {
            type: "text",
            disabled: _vm.isDisabled
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };