import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { reportTotal } from '@/config/report';
import { SelectCustom, BtnExport,
// TableTotalBox,
SelectDictionary } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { searchMixin, summaryMixin } from '@/mixin';
import { mapGetters } from 'vuex';
const newYear = new Date().getFullYear() + '';
export default {
  components: {
    SelectCustom,
    BtnExport,
    // TableTotalBox,
    SelectDictionary
  },
  mixins: [searchMixin, summaryMixin],
  data: () => ({
    total: 0,
    orderTypeList: SELECTOR.reportPlanType,
    tableData: [],
    columnData: [],
    searchParams: {
      year: newYear
    },
    statisticData: [],
    statistic: {},
    statisticsKeys: []
  }),
  computed: {
    ...mapGetters({
      grossProfitData: 'report/index/grossProfitData'
    }),
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          this.searchParams = data;
          this.searchParams.year = data.year || newYear;
        }
      },
      immediate: true
    },
    grossProfitData: {
      handler: 'initData',
      immediate: true
    }
  },
  created() {
    // 表格头部拼接
    const tableThead = [];
    let monthKeys = [];
    for (let i = 1; i <= 13; i++) {
      let item = {};
      item = {
        attrs: {
          prop: '',
          label: i + '月'
        },
        children: [{
          attrs: {
            prop: `adultCount${i}`,
            label: '团队人数'
          }
        }, {
          attrs: {
            prop: `grossProfit${i}`,
            label: '团队毛利'
          }
        }, {
          attrs: {
            prop: `adultCount${i}s`,
            label: '散客人数'
          }
        }, {
          attrs: {
            prop: `grossProfit${i}s`,
            label: '散客毛利'
          }
        }]
      };
      let key = '';
      key = `adultCount${i},grossProfit${i},adultCount${i}s,grossProfit${i}s`;
      if (i === 13) {
        item = {
          attrs: {
            prop: '',
            label: '全年累计'
          },
          children: [{
            attrs: {
              prop: `yearAdultCount`,
              label: '团队总人数'
            }
          }, {
            attrs: {
              prop: `yearGrossProfit`,
              label: '团队总毛利'
            }
          }, {
            attrs: {
              prop: `yearAdultCounts`,
              label: '散客总人数'
            }
          }, {
            attrs: {
              prop: `yearGrossProfits`,
              label: '散客总毛利'
            }
          }]
        };
        key = `yearAdultCount,yearGrossProfit,yearAdultCounts,yearGrossProfits`;
      }
      key = key.split(',');
      tableThead.push(item);
      monthKeys = [...monthKeys, ...key];
    }
    this.columnData = [...reportTotal, ...tableThead];
    this.statisticsKeys = [...monthKeys];
  },
  mounted() {},
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 选择年份
    changeYear(val) {
      if (val) {
        this.searchParams.year = new Date(val).getFullYear() + '';
      } else {
        this.searchParams.year = '';
      }
    },
    selectCustomerArea(val) {
      this.searchParams.customerArea = val ? val.label : '';
    },
    selectCustomer(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.customerLabel = label;
      this.searchParams.customerId = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      // TODO statistics 字段后台暂时没返回,暂时置空对象
      this.statistic = statistics || {};
      this.statisticData.push(statistics || {});
    },
    // 获取数据
    fetchData(queryParam) {
      this.$store.dispatch('report/index/getMonthlyGrossProfit', queryParam);
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchMonthlyProfitExcel', this.searchParams).then(res => {
        const fileName = this.searchParams.year + '年度毛利总汇表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};