import request from '@/utils/request';

// 向资源预定中心发送预定订单
export function bookingSend(data) {
  return request({
    url: '/order/booking-to-res-center',
    method: 'post',
    data
  });
}

// 资源预定中心订单酒店新增、修改
export function bookingHotelSet(data) {
  return request({
    url: '/order/res-center-order/saveOrUpdate',
    method: 'post',
    data
  });
}

// 资源预定中心 订单删除
export function bookingDelete(data) {
  return request({
    url: '/order/res-center-order/del',
    method: 'post',
    data
  });
}

// 资源预定中心酒店订单列表
export function bookingHotelList(params) {
  return request({
    url: '/order/res-center-order/page',
    method: 'get',
    params
  });
}

// 资源预定中心单个订单详情
export function bookingHotelDetail(params) {
  return request({
    url: '/order/res-center-order/hotel',
    method: 'get',
    params
  });
}

// 资源预定中心房型列表
export function bookingRoomList(params) {
  return request({
    url: '/order/res-center-order/hotel-room',
    method: 'get',
    params
  });
}

// 资源预定中心房型 删除
export function bookingRoomDelete(id) {
  return request({
    url: '/order/res-center-order/del-room',
    method: 'post',
    params: {
      id
    }
  });
}

// 资源预定中心 确认申请
export function bookingConfirmApply(data) {
  return request({
    url: '/order/res-center-order/review',
    method: 'post',
    data
  });
}

// 资源预定中心 取消订单
export function bookingCancelOrder(id) {
  return request({
    url: '/order/res-center-order/cancel',
    method: 'post',
    data: {
      id
    }
  });
}

// 地接向资源中心发送取消申请
export function bookingToggleApply(data) {
  return request({
    url: '/order/res-center-order/cancel-apply',
    method: 'post',
    data
  });
}

// 地接确认或拒绝资源中心发送的取消申请
export function bookingToggleReview(data) {
  return request({
    url: '/order/res-center-order/review-cancel-apply',
    method: 'post',
    data
  });
}

// 资源预定中心订单车队新增、修改
export function bookingFleetSet(data) {
  return request({
    url: '/order/res-center-order/save-update-bus',
    method: 'post',
    data
  });
}

// 资源预定中心车队订单列表
export function bookingFleetList(params) {
  return request({
    url: '/order/res-center-order/page-bus',
    method: 'get',
    params
  });
}

// 资源预定中心车队详情
export function bookingFleetDetail(params) {
  return request({
    url: '/order/res-center-order/bus',
    method: 'get',
    params
  });
}

// 资源预定中心订单餐厅新增、修改
export function bookingRestaurantSet(data) {
  return request({
    url: '/order/res-center-order/save-update-restaurant',
    method: 'post',
    data
  });
}

// 资源预定中心餐厅订单列表
export function bookingRestaurantList(params) {
  return request({
    url: '/order/res-center-order/page-restaurant',
    method: 'get',
    params
  });
}

// 资源预定中心餐厅详情
export function bookingRestaurantDetail(params) {
  return request({
    url: '/order/res-center-order/restaurant',
    method: 'get',
    params
  });
}

// 资源预定中心订单景区新增、修改
export function bookingScenicSet(data) {
  return request({
    url: '/order/res-center-order/save-update-scenic',
    method: 'post',
    data
  });
}

// 资源预定中心景区订单列表
export function bookingScenicList(params) {
  return request({
    url: '/order/res-center-order/page-scenic',
    method: 'get',
    params
  });
}

// 资源预定中心餐厅详情
export function bookingScenicDetail(params) {
  return request({
    url: '/order/res-center-order/scenic',
    method: 'get',
    params
  });
}