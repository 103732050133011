var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("Search", {
    on: {
      search: _vm.handleSearch,
      export: _vm.handleExport
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.columns,
      statistics: _vm.statistics,
      "statistics-keys": _vm.statisticsTotalKeys,
      total: _vm.total
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        row
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.directPlanDetail(row.plan);
            }
          }
        }, [_vm._v(" " + _vm._s(row.plan.planNumber) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.plan.startPlanDate))]), _c("div", [_vm._v(_vm._s(row.plan.endPlanDate))])];
      }
    }, {
      key: "guideName",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.plan.guideName))])];
      }
    }, {
      key: "people",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row.plan
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        row
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleValid(row);
            }
          }
        }, [_vm._v(" 核销 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };