import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表不分页
export function fetchSelectCompany() {
  return request({
    url: '/resource/company/findList',
    method: 'get'
  });
}

// 列表
export function fetchCompany(params) {
  return request({
    url: '/resource/company/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateCompany(data) {
  return request({
    url: '/resource/company/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteCompany(id) {
  return request({
    url: '/resource/company/del',
    method: 'post',
    data: {
      id
    }
  });
}