import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import SearchUnit from '../components/SearchUnit.vue';
import { SelectShop } from '@/components/common';
import { shoppingCollectListColumn } from '@/config/shopping';
import { DEFAULT_PAGE } from '@/config';
import { today } from '@/utils/date';
import { searchMixin } from '@/mixin';
export default {
  components: {
    SelectShop,
    SearchUnit,
    TableList
  },
  mixins: [searchMixin],
  data: () => ({
    path: '',
    pathShop: '/shopping/shop-create-subsidy',
    pathBill: '/bill/unit-collect/shopsubsidy-create',
    resourceId: '',
    total: 0,
    listQuery: DEFAULT_PAGE,
    columnData: shoppingCollectListColumn,
    tableData: [],
    excelLoading: false
  }),
  computed: {
    shopData() {
      return this.$store.getters['bill/billDown/shopSubsidy'];
    }
  },
  watch: {
    shopData: {
      handler: function (data) {
        !data ? this.fetchData({
          startCashDate: today
        }) : this.handleData(data);
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.isBack) {
      this.fetchData();
    }
    const path = this.$route.fullPath.indexOf('/bill') > -1 ? this.pathBill : this.pathShop;
    this.path = path;
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('bill/billDown/fetchShoppingSubsidy', params);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.fetchData();
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistic
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistic;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        resourceId: this.resourceId
      });
    },
    redirectLink() {
      this.$router.push(this.path);
    },
    showCreate() {
      this.$router.push(this.routeLink);
    }
  }
};