import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { DialogWechatBind } from '@/components/common';
import WechatLogin from '@/components/WechatLogin';
import LangSelect from '@/components/LangSelect';
import CountDown from '@/components/CountDown';
import Sidebar from './components/Sidebar.vue';
import JtxSidebar from './components/JtxSidebar.vue';
import wlSidebar from './components/wlSidebar.vue';
import DialogTravel from './DialogTravel';
import DialogPassword from './DialogPassword';
import { isPhone, getURLParameters } from '@/utils';
import { setToken, setGroupHeaders, setGroupUserData } from '@/utils';
import { APP, WECHAT_INFO } from '@/config';
import { mapGetters, mapState } from 'vuex';
import ResizeMixin from '@/layout/mixin/ResizeHandler';
// import wxlogin from 'vue-wxlogin'
const STATE = 'DDLogin';
export default {
  name: 'Login',
  components: {
    LangSelect,
    CountDown,
    DialogTravel,
    DialogPassword,
    Sidebar,
    JtxSidebar,
    wlSidebar,
    WechatLogin,
    DialogWechatBind
  },
  mixins: [ResizeMixin],
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'));
      } else if (!isPhone(value)) {
        callback(new Error('手机号码不正确'));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入短信验证吗'));
      } else if (value.length !== 6) {
        callback(new Error('请输入6位数的验证码'));
      } else {
        callback();
      }
    };
    const validateVerifyCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证吗'));
      } else {
        callback();
      }
    };
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码长度不能少于6位'));
      } else {
        callback();
      }
    };
    return {
      wxRedirectUrl: `${APP.redirecWxtUrl}login?loginState='WXLogin'`,
      visitCodeImg: '',
      form: {
        phone: '',
        code: '',
        visitCode: '',
        username: '',
        password: ''
      },
      status: '',
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loginSmsRules: {
        phone: [{
          required: true,
          trigger: 'blur',
          validator: validatePhone
        }],
        code: [{
          required: true,
          trigger: 'blur',
          validator: validateCode
        }],
        verifyCode: [{
          required: true,
          trigger: 'blur',
          validator: validateVerifyCode
        }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      showWxDialog: false,
      redirect: undefined,
      otherQuery: {},
      curLoginType: 'normal',
      loginType: {
        normal: 'normal',
        sms: 'sms'
      },
      wxLoginDiaData: WECHAT_INFO,
      currentTheme: '',
      countState: true,
      type: 'LOGIN',
      returnPhone: '',
      present: {
        jtx: 'http://jtx.weilailvxingshe.com',
        local: 'http://127.0.0.1',
        test: 'http://test.weilailvxingshe.com'
      },
      systemName: '',
      wx_uid: '',
      loginState: ''
    };
  },
  computed: {
    ...mapState({
      device: state => state.app.device
    }),
    ...mapGetters({
      userData: 'user/userData'
    }),
    getUsernameLogin() {
      return this.curLoginType === this.loginType.normal;
    },
    getSmsLogin() {
      return this.curLoginType === this.loginType.sms;
    },
    getCodeImage() {
      return 'data:image/jpeg;base64,' + this.visitCodeImg;
    },
    isMobile() {
      return this.device === 'mobile';
    },
    currentView() {
      const url = window.location.href;
      if (url.indexOf('jtx') != -1) {
        return 'JtxSidebar';
      } else if (url.indexOf(this.present.test) != -1) {
        return 'wlSidebar';
      } else {
        return 'Sidebar';
      }
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        const {
          redirect,
          code,
          loginState,
          state,
          token
        } = route.query;
        if (token) {
          this.groupUserIn();
        } else {
          this.loginState = '';
          // 判断是钉钉登录
          if (state == 'DDLogin') {
            this.loginState = 'DDLogin';
          }
          // 判断是微信登录
          if (code) {
            if (loginState == 'WXLogin') {
              this.loginState = 'WXLogin';
              this.getWxLoginCode(route.query);
            }
          } else {
            if (redirect) {
              this.redirect = redirect;
              this.otherQuery = this.getOtherQuery(route.query);
            }
          }
        }
      },
      immediate: true
    },
    'form.phone': {
      handler: function handlePhone(val) {
        this.countState = isPhone(val) ? false : true;
      }
    }
  },
  mounted() {
    if (this.form.username === '') {
      this.$refs.username.focus();
    } else if (this.form.password === '') {
      this.$refs.password.focus();
    }
    // this.setTheme()
    // http://test.weilailvxingshe.com/login/?code=70f612a01b8d321a9c8d5c4fdece0c60&state=DDLogin
    let {
      state,
      code
    } = this.getUrlParams();
    if (code && state == STATE) {
      this.$store.dispatch('user/DDLogin', {
        code
      }).then(() => {
        window.location.reload();
      }).catch(err => {
        this.returnPhone = err.data[0].phone;
        err.code == APP.travelErrNo && this.chooseTravel(err.data);
      });
    }
  },
  methods: {
    async groupUserIn() {
      const {
        TID,
        RID,
        token
      } = this.$route.query;
      if (!TID || !RID || !token) {
        return;
      }

      // const sassToken = getToken()
      // if (sassToken) {
      //   // 删除token和用户数据
      //   removeToken()
      //   delUserData()
      //   await this.$store.dispatch('user/logoutForGroupUser')
      // }

      // 2. 设置header参数
      setToken(token);
      setGroupHeaders({
        RID,
        TID
      });
      setGroupUserData();

      // 跳转
      this.$router.push({
        path: '/'
      });
    },
    initDDCode() {
      const redirectUrl = APP.redirectUrl;
      const appId = APP.DDAppId;
      const responseType = 'code';
      const state = STATE;
      const scope = 'snsapi_login';
      let gotoUrl = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appId}&response_type=${responseType}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
      let goto = encodeURIComponent(gotoUrl);
      DDLogin({
        id: 'threeLogin',
        goto,
        // 请参考注释里的方式
        style: 'border:none;background-color:#FFFFFF;',
        width: '365',
        height: '400'
      });
      const handleMessage = function handleMessage(event) {
        const {
          origin,
          data
        } = event;
        if (origin == 'https://login.dingtalk.com') {
          //判断是否来自ddLogin扫码事件。
          var loginTmpCode = data;
          //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          let redirectUrl = gotoUrl + '&loginTmpCode=' + loginTmpCode;

          // https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingoa7xedcjjmaekigyau&response_type=code&scope=snsapi_login&state=DDLogin&redirect_uri=http://test.weilailvxingshe.com/login/&loginTmpCode=0fbe981dadfe334ab710e2e93996515f

          // https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=SuiteKey&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=REDIRECT_URI&loginTmpCode=loginTmpCode

          window.location.replace(decodeURIComponent(redirectUrl));
        }
      };
      if (typeof window.addEventListener != 'undefined') {
        window.addEventListener('message', handleMessage, false);
      } else if (typeof window.attachEvent != 'undefined') {
        window.attachEvent('onmessage', handleMessage);
      }
    },
    getUrlParams() {
      // let { state, redirect } = getURLParameters(
      //   decodeURIComponent('http://test.weilailvxingshe.com/login?code=ffdda7fb897a3610b3f81e5ad0c5acf2&state=DDLogin')
      //   // decodeURIComponent(window.location.href)
      // )
      // const code = redirect && redirect.split('/code=')[1]
      // return { code, state }
      // http://test.weilailvxingshe.com/login?code=fd05ccd8a0e634b28d6f1bce621c1be8&state=DDLogin
      // http://jtx2.weilailvxingshe.com/login/?code=e9e0e62cf4e1328382746b23d2c17e59&state=DDLogin
      const {
        code,
        state
      } = getURLParameters(window.location.href);
      return {
        code,
        state
      };
    },
    setTheme() {
      const hours = new Date().getHours();
      this.currentTheme = hours > 7 && hours < 19 ? 'theme-day' : 'theme-night';
    },
    handleTheme(val) {
      this.currentTheme = val;
    },
    checkCapslock(e) {
      const {
        key
      } = e;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showDDLogin() {
      this.showDialog = true;
      this.$nextTick(() => {
        this.initDDCode();
      });
    },
    // 打开微信扫码弹窗
    showWxLogin() {
      this.showWxDialog = true;
      // this.$refs.dialogWechatRef.show()
    },
    // 微信扫码
    getWxLoginCode(opts) {
      this.$store.dispatch('user/getWxLoginCode', opts).then(() => {
        this.handleRedirect();
      }).catch(err => {
        const {
          code,
          data
        } = err;
        if (code == APP.travelErrNoBind) {
          this.wx_uid = data.wx_uid;
          this.$refs.dialogWechatRef.show(data);
        }
        if (code == APP.travelErrNo) {
          err.code == APP.travelErrNo && this.chooseTravel(err.data);
        }
        if (code == 400) {
          this.$router.push({
            path: '/login'
          });
        }
      });
    },
    // 微信扫码选择旅行社登录
    wechatLogin(opts) {
      this.$store.dispatch('user/getWxLogin', opts).then(() => {
        this.handleRedirect();
      });
    },
    toggleLoginType() {
      this.curLoginType = this.curLoginType == this.loginType.normal ? this.loginType.sms : this.loginType.normal;
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    // 刷新验证码
    refreshCode() {
      const params = {
        mobilePhone: this.form.phone,
        type: this.type
      };
      this.$store.dispatch('user/refreshCode', params).then(data => {
        const {
          visitCodeImg
        } = data;
        this.visitCodeImg = visitCodeImg;
      });
    },
    // 发送手机验证码
    handleSend() {
      const opts = {
        mobilePhone: this.form.phone,
        type: this.type,
        visitCode: this.form.visitCode
      };
      this.$store.dispatch('user/getSmsCode', opts).then(data => {
        this.visitCodeImg = data.visitCodeImg;
        if (data.visitCodeImg) {
          this.$refs.countDownRef.restoreCount();
          this.countState = false;
        }
      }).catch(() => {
        this.$bus.tip({
          type: 'error',
          message: '请求次数过多，请输入验证码后再重试！'
        });
        this.$refs.countDownRef.restoreCount();
        this.countState = false;
      });
    },
    handleLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let {
            username,
            password
          } = this.form;
          username = username.trim();
          password = password.trim();
          if (this.curLoginType === this.loginType.normal) {
            this.userLogin({
              username,
              password
            }).then(() => {
              this.handleRedirect();
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
          }

          // 短信登陆
          if (this.curLoginType === this.loginType.sms) {
            const {
              phone,
              code,
              visitCode
            } = this.form;
            this.smsLogin({
              phone,
              code,
              visitCode
            }).then(() => {
              this.handleRedirect();
              this.loading = false;
            }).catch(err => {
              err.code == APP.travelErrNo && this.chooseTravel(err.data);
              this.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    // 旅行社选择
    chooseTravel(list) {
      this.$refs.dialogRef.show(list);
    },
    travelSMSLogin(travelAgencyId) {
      const {
        phone,
        code,
        visitCode
      } = this.form;
      this.smsLogin({
        phone,
        code,
        visitCode,
        travelAgencyId
      }).then(() => {
        this.handleRedirect();
      }).catch(() => {
        this.loading = false;
      });
    },
    travelDDLogin(travelAgencyId) {
      let {
        code
      } = this.getUrlParams();
      const phone = this.returnPhone;
      this.$store.dispatch('user/DDLogin', {
        code,
        travelAgencyId,
        phone
      }).then(() => {
        this.$router.push('/');
      });
    },
    // 选择旅行社后登陆
    /**
     * @param 'DDLogin' 钉钉登录 'WXLogin' 微信登录 ''短信登录
     */
    handleEnter(travelInfo) {
      const {
        travelAgencyId,
        wx_uid
      } = travelInfo;
      if (this.loginState == 'DDLogin') {
        this.travelDDLogin(travelAgencyId);
      }
      if (this.loginState == 'WXLogin') {
        this.wechatLogin({
          travelAgencyId,
          wx_uid
        });
      }
      if (this.loginState == '') {
        this.travelSMSLogin(travelAgencyId);
      }
    },
    userLogin(data) {
      return this.$store.dispatch('user/login', data);
    },
    smsLogin(data) {
      return this.$store.dispatch('user/loginSms', data);
    },
    ddLogin(code, travelAgencyId, phone) {
      return this.$store.dispatch('user/DDLogin', {
        code,
        travelAgencyId,
        phone
      });
    },
    handleRedirect() {
      this.$router.push({
        path: this.redirect || '/',
        query: this.otherQuery
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};