import { inputMixin } from '@/mixin';

/**
 * @module components/common/Input
 * @desc 收款方式
 * @vue-prop {Boolean}} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean}} [isRequire=false] - 是否必填项
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  created() {
    this.payments = this.loadAll();
  },
  methods: {
    loadAll() {
      return [{
        value: '微信',
        id: '1'
      }, {
        value: '支付宝',
        id: '2'
      }, {
        value: '扫码',
        id: '3'
      }, {
        value: '对账',
        id: '4'
      }];
    }
  }
};