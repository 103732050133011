import { SwitchStatus } from '@/components/common';
import { departmentAuthArea } from '@/config/radios';
import { defaultState, rules } from '../config';
// import { getSelectLabel } from '@/config'

export default {
  components: {
    SwitchStatus
  },
  data() {
    return {
      rules,
      departmentAuthArea,
      visible: false,
      isEdit: false,
      form: defaultState()
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/userData'];
    },
    dialogTitle() {
      return this.isEdit ? '更新角色' : '新增角色';
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    edit(item) {
      this.visible = true;
      this.isEdit = true;
      const {
        id,
        name,
        remarks,
        singleGroupAuditRole,
        departmentPrivilege,
        showCustomerInfo,
        showShopSubsidy
      } = item;
      this.form = {
        ...this.form,
        id,
        name,
        remarks,
        showCustomerInfo,
        showShopSubsidy,
        singleGroupAuditRole,
        departmentPrivilege
      };
    },
    hide() {
      this.visible = false;
      this.isEdit = false;
      this.form = defaultState();
    },
    updateRoleCustomerInfo() {
      if (this.userInfo) {
        this.$store.dispatch('system/role/getRoleCustomerAuth', this.userInfo.roleId);
      }
      this.$bus.tip();
    },
    createRole(opt) {
      this.$store.dispatch('system/role/create', opt).then(() => {
        // 刷新角色列表
        this.$emit('reload:data');
        this.updateRoleCustomerInfo();
      });
    },
    updateRole(opt) {
      this.$store.dispatch('system/role/update', opt).then(() => {
        // 刷新角色列表
        this.$emit('reload:data');
        this.updateRoleCustomerInfo();
      });
    },
    handleCreateOrUpdate() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        if (this.isEdit) {
          this.updateRole(this.form);
        } else {
          this.createRole(this.form);
        }
        this.hide();
      });
    }
  }
};