export default {
  data() {
    return {
      visible: false,
      curIndex: 1,
      acitveIndex: 1,
      imagesData: null
    };
  },
  methods: {
    show({
      data,
      index
    }) {
      this.visible = true;
      this.imagesData = data;
      this.curIndex = index;
    },
    handleClose() {
      this.imagesData = null;
      this.visible = false;
    },
    // 轮播图切换触发，（获取当前索引值）
    changeCarouselItem(it) {
      this.acitveIndex = it + 1;
    }
  }
};