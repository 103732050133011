import DialogAdd from './components/DialogAdd';
import { BtnLink } from '@/components/common';
import { GroupResource as columns } from '@/config/group';
import { hasRestaurantSelectData, hasInsuranceSelectData, hasTrafficSelectData, hasFleetSelectData, hasScenicSelectData, hasHotelSelectData, hasShopSelectData, hasCustomSelectData } from '@/utils/requestSelectData';
import { reloadMixin } from '@/mixin';
import { getUserData } from '@/utils';
export default {
  components: {
    DialogAdd,
    BtnLink
  },
  mixins: [reloadMixin],
  data() {
    return {
      columns,
      searchParams: {}
    };
  },
  computed: {
    data() {
      return this.$store.getters['group/resourceData'];
    },
    tableData() {
      return this.$store.getters['group/resourceList'];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    }
  },
  created() {
    if (!this.data) {
      this.fetchData();
    }
    // 加载资源，为保证编辑的时候回显数据
    this.loadResource();
  },
  methods: {
    fetchData(params) {
      const {
        travelAgencyId
      } = getUserData();
      this.$store.dispatch('group/fetchResource', {
        ...this.searchParams,
        travelAgencyId,
        ...params
      });
    },
    loadResource() {
      hasRestaurantSelectData();
      hasInsuranceSelectData();
      hasTrafficSelectData();
      hasFleetSelectData();
      hasScenicSelectData();
      hasHotelSelectData();
      hasShopSelectData();
      hasCustomSelectData();
    },
    handleCreate() {
      this.$refs.dialogAddRef.show();
    },
    handleEdit(row) {
      this.$refs.dialogAddRef.edit(row);
    },
    handleDelete(row) {
      this.$bus.open(() => {
        this.$store.dispatch('group/deleteResource', row.id).then(() => {
          this.$bus.tip();
        });
      });
    }
  }
};