var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "row-class-name": _vm.tableRowClassName,
      sticky: _vm.isSticky,
      "hide-bar": true,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planCustomerNumber ? scope.row.planCustomerNumber : "--") + " ")]), _c("br"), _vm._v(" " + _vm._s(scope.row.customerTripNumber ? scope.row.customerTripNumber : "--") + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.productName) + " ")];
      }
    }, {
      key: "TripDate",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onItineraryDate(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.startTripDate) + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")])];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onCustomShow(scope.row);
            }
          }
        }, [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })], 1)];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onCount(scope);
            }
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.adultCount) + "大")]), _c("span", [_vm._v(_vm._s(scope.row.childCount) + "小")]), _c("span", [_vm._v(_vm._s(scope.row.accompanyCount) + "陪")])])];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onTourist(scope);
            }
          }
        }, [scope.row.members.length ? _vm._l(_vm.getContactPeople(scope.row.members), function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(item.name) + " "), _c("br"), _vm._v(_vm._s(item.mobilePhone) + " ")]);
        }) : [_vm._v(" -- ")]], 2)];
      }
    }, {
      key: "guide",
      fn: function ({
        scope
      }) {
        return [scope.row.guides.length ? _vm._l(scope.row.guides, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(item.name) + "-" + _vm._s(item.mobileIphone) + " ")]);
        }) : [_vm._v(" -- ")]];
      }
    }, {
      key: "totalCollect",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalCollect) + " ")])];
      }
    }, {
      key: "totalIncome",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalIncome) + " ")])];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSubsidy) + " ")])];
      }
    }, {
      key: "itinerary",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.onItinerary(scope);
            }
          }
        }, [_vm._v(" 行程 ")])];
      }
    }, {
      key: "state",
      fn: function ({
        scope
      }) {
        return [_vm.orderAuditStatus(scope.row) ? _c("div", {
          staticStyle: {
            cursor: "pointer"
          }
        }, [_c("el-tag", {
          attrs: {
            type: "info"
          },
          on: {
            click: function ($event) {
              return _vm.linkAuditDetail(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.reviewerStatus[scope.row.addAuditStatus]) + " ")])], 1) : _vm._e(), _vm.hasFiled(scope.row.planStatus) ? _c("el-tag", {
          attrs: {
            effect: "plain",
            type: "danger"
          }
        }, [_vm._v(" 已归档 ")]) : _c("StatusOrderTeam", {
          attrs: {
            "plan-id": scope.row.planId
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-dropdown", {
          on: {
            command: function ($event) {
              return _vm.handleCommand(scope, $event);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text"
          }
        }, [_vm._v(" 操作"), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            icon: "el-icon-tickets",
            command: "handleShow"
          }
        }, [_vm._v(" 查看 ")]), !scope.row.isCancel ? _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            icon: "el-icon-document-copy",
            command: "handleCopy"
          }
        }, [_vm._v(" 复制 ")]) : _vm._e(), !scope.row.isCancel ? _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            icon: "el-icon-document-copy",
            command: "handleOnkeyCopy"
          }
        }, [_vm._v(" 一键复制 ")]) : _vm._e(), _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: !scope.row.isCancel && !_vm.comparePlanStatus(scope.row),
            icon: "el-icon-edit-outline",
            command: "handleEdit"
          }
        }, [_vm._v(" 编辑 ")]), _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: !scope.row.isCancel && !_vm.comparePlanStatus(scope.row),
            icon: "el-icon-folder-remove",
            command: "handleRevoke"
          }
        }, [_vm._v(" " + _vm._s(scope.row.isCancel ? "取消退单" : "退单") + " ")]), _vm.addAuditStatus(scope) ? _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            icon: "el-icon-folder-checked",
            command: "handleConfirm"
          }
        }, [_vm._v(" 确认成团 ")]) : _vm._e(), scope.row.planId > 0 ? _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: scope.row.planId != -1 && !_vm.comparePlanStatus(scope.row),
            icon: "el-icon-document-delete",
            command: "handleCancel"
          }
        }, [_vm._v(" 取消计划 ")]) : _vm._e(), _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: !_vm.comparePlanStatus(scope.row),
            icon: "el-icon-document-delete",
            command: "handleDelete"
          }
        }, [_vm._v(" 删除 ")]), _c("el-dropdown-item", {
          attrs: {
            disabled: !_vm.comparePlanStatus(scope.row),
            icon: "el-icon-document-delete",
            command: "handleBooking"
          }
        }, [_vm._v(" 下单 ")]), _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: scope.row.planId == -1,
            icon: "el-icon-coordinate",
            command: "handleAuditDetail"
          }
        }, [_vm._v(" 单团明细 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-printer",
            command: "handlePrint"
          }
        }, [_vm._v(" 打印 ")])], 1)], 1)];
      }
    }])
  }), _c("DialogCustom", {
    ref: "customRef"
  }), _c("DialogCount", {
    ref: "countRef"
  }), _c("DialogTableTourist", {
    ref: "tableTouristRef"
  }), _c("DialogGroupFund", {
    ref: "groupFundRef"
  }), _c("DialogItineraryDate", {
    ref: "itineraryDateRef",
    attrs: {
      "order-type": _vm.orderType
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };