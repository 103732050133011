// 总计
export const StatisticsKeys = ['count', 'checkAccountMoney', 'unPay', 'guidePay', 'freeCount', 'otherFee', 'totalPayed', 'totalPay', 'alipayECodePay'];
export const MergeRowKeys = ['planNumber', 'date', 'peopleCount', 'guideName', '$cashType', 'restaurantName', 'orderDate', 'totalPay', 'guidePay', 'totalPayed', 'alipayECodePay', 'unPay', 'checkAccountMoney', 'checkAccountTime', 'checkAccountUserName', 'checkAccountRemark', 'auditStatus', 'action'];
export const StatisticsTransfor = {
  totalAccompanyCount: 'accompanyCount',
  totalAdultCount: 'adultCount',
  totalChildCount: 'childCount',
  checkAccountMoneyCount: 'checkAccountMoney',
  unTotalPay: 'unPay',
  totalCollect: 'totalCollect',
  totalPayCount: 'totalPay',
  count: 'count',
  freeCount: 'freeCount',
  otherPayCount: 'otherFee',
  totalPayedCount: 'totalPayed',
  guidePayCount: 'guidePay',
  alipayECodePay: 'alipayECodePay'
};