export default {
  data() {
    return {
      visible: false,
      loading: true,
      content: ''
    };
  },
  methods: {
    show(content) {
      this.visible = true;
      this.content = content;
    },
    hide() {
      this.visible = false;
      this.content = '';
    }
  }
};