import { orderTrafficDetail } from '@/config/order';
import { getSummariesMixin, reloadMixin } from '@/mixin';
export default {
  mixins: [getSummariesMixin, reloadMixin],
  data() {
    return {
      columnData: orderTrafficDetail,
      tableData: [],
      statisticsKeys: ['count', 'otherFee', 'money']
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchTraffic', {
        id
      }).then(data => {
        if (!data) return;
        this.tableData = data;
      });
    }
  }
};