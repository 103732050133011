var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FormBase", {
    attrs: {
      "label-width": "80px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "guideReportType",
      label: ""
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": _vm.changeStarTripDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": _vm.changeEndTripDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.form.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planNumber", $$v);
      },
      expression: "form.planNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划审核状态",
      "label-width": "120px"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "examineSearch"
    },
    model: {
      value: _vm.form.auditStatus,
      callback: function ($$v) {
        _vm.$set(_vm.form, "auditStatus", $$v);
      },
      expression: "form.auditStatus"
    }
  })], 1), _c("div", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("on-search", _vm.form);
      }
    }
  }, [_vm._v(" 搜索 ")]), _vm.isBill ? _c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("on-bill", _vm.form);
      }
    }
  }, [_vm._v(" 自动下账 ")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };