var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list && _vm.list.length ? _c("div", _vm._l(_vm.getList, function (item, index) {
    return _c("div", {
      key: item.id + "" + index
    }, [_c("div", [_vm._v(_vm._s(item.realName))])]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };