import { TreeFramework } from '@/components/common';
import MemberList from './components/MemberList';
import formDialog from './components/formDialog';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
export default {
  name: 'WorkInput',
  components: {
    TreeFramework,
    MemberList,
    formDialog
  },
  mixins: [searchMixin],
  data: () => ({
    total: 0,
    tableData: [],
    subjectIds: '',
    searchParams: {
      subjectType: 'office'
    }
  }),
  computed: {
    ...mapGetters({
      subjectWordData: 'system/accounting/subjectWordData'
    })
  },
  watch: {
    subjectWordData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = data;
    },
    handleEdit(node) {
      const {
        data
      } = node;
      const {
        parentsId,
        id
      } = data;
      let ids = [];
      if (parentsId > 0) {
        ids = [parentsId, id];
      } else {
        ids = [id];
      }
      this.$refs.formRef.addShow(ids);
    },
    handleClick(node) {
      const {
        id,
        parentsId
      } = node;
      let ids = [];
      if (parentsId < 1) {
        ids = node.children.map(it => {
          return it.id;
        });
      }
      ids = [...ids, id];
      this.subjectIds = ids.toString();
      this.$refs.memberRef.parentFetchData({
        subjectIds: this.subjectIds
      });
    },
    handleDelItem(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('system/accounting/delCash', {
          id
        }).then(() => {
          this.$refs.memberRef.parentFetchData({
            subjectIds: this.subject
          });
        });
      }, '是否确定删除？');
    },
    handleEditItem(row) {
      this.$refs.formRef.edit(row);
    },
    onChangeDate(date) {
      const [start, end] = date;
      this.billDate = date;
      this.searchParams.endDate = end;
      this.searchParams.startDate = start;
    },
    fetchData(opst = {}) {
      this.$store.dispatch('system/accounting/fetchListLevel', {
        subjectType: 'office',
        ...opst
      });
    }
  }
};