import { parseMonth } from '@/utils';
import { searchMixin } from '@/mixin';
import { exportTable } from '@/utils/exportTableUtil';
const dateArr = val => parseMonth(val);
const today = dateArr(new Date());
export default {
  mixins: [searchMixin],
  data() {
    return {
      sendDate: [],
      searchParams: {}
    };
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        const {
          query
        } = this.$route;
        // 如果url没有带参且缓存搜索条件为空时
        if (!data) {
          if (JSON.stringify(query) == '{}') {
            this.searchParams.startMonth = today;
            this.searchParams.endMonth = today;
            this.sendDate = [today, today];
          }
          return false;
        }
        this.searchParams = data;
        const {
          startMonth,
          endMonth
        } = data;
        this.sendDate = [startMonth, endMonth];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onChangeDate(val) {
      const [start, end] = val;
      this.searchParams.startMonth = start;
      this.searchParams.endMonth = end;
    },
    exportExcel(name, byId, headers = 2) {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有数据导出！'
        });
        return false;
      }
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt = document.getElementById(byId);
      exportTable(elt, name, `${name}导出`, headers);
      loading.close();
    }
  }
};