import { inputMixin } from '@/mixin';
import { getDaysBetween } from '@/utils';
/**
 * @module components/base/Input
 * @desc 日期选择器
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直布局
 * @vue-prop {Boolean} [isRequire=false] - 是否在label前面加 * 标记
 */

const DAY_TIMESTAME = 3600 * 1000 * 24;
const WEEK_TIMESTAME = DAY_TIMESTAME * 7;
export default {
  name: 'InputDateRange',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    defaultDate: {
      type: Array,
      default: () => []
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    type: {
      type: String,
      default: 'date'
    },
    shortcuts: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Boolean,
      default: false
    },
    maxDay: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - WEEK_TIMESTAME);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + WEEK_TIMESTAME);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  },
  watch: {
    defaultDate: {
      handler: function (val) {
        const [start, end] = val;
        this.startDate = start;
        this.endDate = end;
      },
      immediate: true
    },
    startDate: {
      handler: function (val) {
        if (!val) {
          this.clearStartDate();
        }
      },
      immediate: true
    },
    endDate: {
      handler: function (val) {
        if (!val) {
          this.clearEndDate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onStarDate(date) {
      this.startDate = date || '';
      // 如果结束日期不为空而且开始日期大于结束日期,则结束日期与开始日期相等
      if (this.endDate && this.endDate < date) {
        this.endDate = date;
      }
      this.$emit('on-change', [date, this.endDate]);
    },
    onEndDate(date) {
      if (this.startDate && this.startDate > date) {
        this.startDate = date;
      }
      const remain = getDaysBetween(this.startDate, date);
      // if (remain < 0) {
      //   this.$bus.tip({ type: 'warning', message: '结束日期必须大于开始日期' })
      //   this.endDate = ''
      //   return
      // }

      if (this.limit && remain > this.maxDay) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期不能超出' + this.maxDay + '天'
        });
        this.endDate = '';
        return;
      }
      this.endDate = date || '';
      this.$emit('on-change', [this.startDate, date]);
    },
    clearStartDate() {
      this.startDate = '';
      this.$emit('on-change', [this.startDate, this.endDate]);
    },
    clearEndDate() {
      this.endDate = '';
      this.$emit('on-change', [this.startDate, this.endDate]);
    }
  }
};