var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-cascader", _vm._g(_vm._b({
    ref: "cascaderRef",
    attrs: {
      props: {
        checkStrictly: true,
        expandTrigger: "hover"
      },
      options: _vm.list,
      clearable: ""
    },
    on: {
      "visible-change": _vm.handleClick,
      "expand-change": _vm.handleClick
    },
    nativeOn: {
      mouseover: function ($event) {
        return _vm.onMouseEnter.apply(null, arguments);
      }
    }
  }, "el-cascader", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };