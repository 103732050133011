import { mapGetters } from 'vuex';
import { BtnExport, SelectDictionary } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
import { parseDate, parseMonth } from '@/utils';
const dateMonth = val => parseMonth(val);
const today = parseDate(new Date());
const NewMonth = dateMonth(new Date());
export default {
  components: {
    BtnExport,
    SelectDictionary
  },
  data: () => ({
    dailyData: {
      starDate: [today, ''],
      sendDate: [],
      startTripDateE: '',
      startTripDateS: '',
      endTripDateE: '',
      endTripDateS: ''
    },
    tripPay: {
      month: NewMonth
    },
    travelAgency: {
      payable: 'report/index/fetchTripPayStatic',
      payableArea: 'report/index/fetchTripPayAreaStatic'
    }
  }),
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    })
  },
  methods: {
    selectDate(type, val) {
      this.dailyData[type + 'S'] = val[0];
      this.dailyData[type + 'E'] = val[1];
    },
    // 导出天天快报
    exportDaily() {
      const loading = this.$bus.loading();
      delete this.dailyData.starDate;
      delete this.dailyData.sendDate;
      Object.keys(this.dailyData).forEach(key => {
        if (!this.dailyData[key]) {
          delete this.dailyData[key];
        }
      });
      const opts = this.dailyData;
      const fileName = '天天快报.xlsx';
      this.$store.dispatch('report/index/fetchDailyExcel', opts).then(res => {
        loading.close();
        downloadExport(res, fileName);
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    selectArea(val) {
      this.tripPay.area = val.label;
    },
    // 选择年月
    changeMonth(date) {
      this.tripPay.month = dateMonth(date);
    },
    // 导出应付明细表
    exportTotalPay(link) {
      const loading = this.$bus.loading();
      const opts = this.tripPay;
      if (!opts.month) {
        this.$message({
          message: '年月是必选的',
          type: 'warning'
        });
      }
      if (opts.orderType) {
        opts.orderType = opts.orderType.toLowerCase();
      }
      const {
        travelAgency
      } = this.userData;
      let fileName;
      if (link == 'payableArea') {
        fileName = travelAgency.name + opts.month + '应付账款明细表-按区域.xlsx';
      } else {
        fileName = travelAgency.name + opts.month + '应付账款明细表.xlsx';
      }
      this.$store.dispatch(this.travelAgency[link], opts).then(res => {
        loading.close();
        downloadExport(res, fileName);
      });
    },
    exportAreaPay() {}
  }
};