import "core-js/modules/es.array.reduce.js";
// export let widthMin = switchVersion ? 45 : 65
// export let widthMiddle = switchVersion ? 60 : 80
// export let widthMax = switchVersion ? 90 : 120
// export let widthHuge = switchVersion ? 120 : 150

const width30 = 30;
const width40 = 40;
const width50 = 50;
const width60 = 60;
const width70 = 70;
const width80 = 80;
const width90 = 90;
const width100 = 100;
const width110 = 110;
const width120 = 120;
const width140 = 140;
const width160 = 160;
const width180 = 180;
const width200 = 200;
const dateWidth = width100;
const resourceWidth = dateWidth;
const typeKeys = 'guide,scenic,hotel,restaurant,bus,insurance,transport,shuttle,borrow,collect,localTravelAgency,shop,otherIncome,otherPay,alipayECode';
const typeObj = typeKeys.split(',').reduce((acc, key) => ({
  ...acc,
  [key]: key
}), {});
export const PlanType = typeObj;
const PLAIN = 'plain';
const TRAIN = 'train';
const HOTEL = 'hotel';
const SHIP = 'ship';
export const PickAndSendTypeEnum = {
  [PLAIN]: '飞机',
  [TRAIN]: '火车',
  [HOTEL]: '酒店',
  [SHIP]: '轮船'
};
export const DingTalkStatus = {
  0: '审核中',
  1: '成功',
  '-1': '失败'
};
export const ShuttleType = {
  hotel: 'HOTEL',
  scenic: 'SCENIC',
  bus: 'BUS',
  customer: 'CUSTOMER',
  self: 'SELF',
  transport: 'TRANSPORT',
  insurance: 'INSURANCE',
  shop: 'SHOP',
  restaurant: 'RESTAURANT',
  visa: 'VISA',
  shuttle: 'SEND'
};
export const ShuttleTypeEnum = [{
  value: 'HOTEL',
  label: '酒店'
}, {
  value: 'SCENIC',
  label: '景点'
}, {
  value: 'BUS',
  label: '车队'
}, {
  value: 'CUSTOMER',
  label: '客户'
}, {
  value: 'SELF',
  label: '自费商家'
}, {
  value: 'TRANSPORT',
  label: '大交通'
}, {
  value: 'INSURANCE',
  label: '保险'
}, {
  value: 'SHOP',
  label: '购物店'
}, {
  value: 'RESTAURANT',
  label: '餐厅'
}, {
  value: 'VISA',
  label: '签证'
}];

// 子项类型，""=默认,transport=大交通，bus=车队，scenic=景区，guide=导游，selfCompany=自费，hotel=房间，insurance=保险，shop=购物，restaurant=餐饮，shuttle=接送，collect=代收，borrow=借款申请, ortherPay=其他支出, ortherIncome=其他收入, localTravelAgency=外转

export const tabMenu = [{
  id: 1,
  label: '导游',
  component: 'Guide',
  type: typeObj.guide,
  status: 'guideOrderStatus'
}, {
  id: 3,
  label: '酒店',
  component: 'Hotel',
  type: typeObj.hotel,
  status: 'hotelOrderStatus'
}, {
  id: 4,
  label: '餐厅',
  component: 'Restaurant',
  type: typeObj.restaurant,
  status: 'restaurantOrderStatus'
}, {
  id: 5,
  label: '车队',
  component: 'Fleet',
  type: typeObj.bus,
  status: 'busCompanyOrderStatus'
}, {
  id: 2,
  label: '景区',
  component: 'Scenic',
  type: typeObj.scenic,
  status: 'scenicOrderStatus'
}, {
  id: 6,
  label: '保险',
  component: 'Insurance',
  type: typeObj.insurance,
  status: 'insuranceOrderStatus'
}, {
  id: 7,
  label: '交通',
  component: 'Traffic',
  type: typeObj.transport,
  status: 'transportOrderStatus'
}, {
  id: 8,
  label: '接送',
  component: 'Pickup',
  type: typeObj.shuttle,
  status: 'shuttleStatus'
}, {
  id: 16,
  label: '额度申请',
  component: 'GuideQuota',
  type: typeObj.alipayECode,
  status: 'guideBorrowAlipayStatus'
}, {
  id: 9,
  label: '借款申请',
  component: 'GuideBorrow',
  type: typeObj.borrow,
  status: 'guideBorrowApplyStatus'
}, {
  id: 10,
  label: '导游代收',
  component: 'GuideProxy',
  type: typeObj.collect,
  status: 'collectStatus'
}, {
  id: 11,
  label: '外转',
  component: 'Reception',
  type: typeObj.localTravelAgency,
  status: 'agencyStatus'
}, {
  id: 12,
  label: '购物',
  component: 'Shopping',
  type: typeObj.shop,
  status: 'shopStatus'
}, {
  id: 13,
  label: '其他收入',
  component: 'OtherIncome',
  type: typeObj.otherIncome,
  status: 'otherIncomeStatus'
}, {
  id: 14,
  label: '其他支出',
  component: 'OtherPay',
  type: typeObj.otherPay,
  status: 'otherPayStatus'
}];
const auditAndCheckStatus = {
  attrs: {
    prop: 'auditAndCheckStatus',
    label: '审核/对账',
    width: width70
  },
  slot: 'auditAndCheckStatus'
};
export const planInfo = {
  attrs: {
    prop: 'planInfo',
    label: '订单信息',
    'min-width': 140
  },
  slot: 'planInfo'
};
// const planNumber = {
//   attrs: { prop: 'planNumber', label: '大团号' },
// }
const planNumberSlot = {
  // ...planNumber,
  // slot: 'planNumber',
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  },
  slot: 'planNumber'
};
const busCompanyName = {
  attrs: {
    prop: 'busCompanyName',
    label: '车辆公司',
    'min-width': resourceWidth
  },
  slot: 'busCompanyName'
};
const insuracneName = {
  attrs: {
    prop: 'insuracneName',
    label: '保险公司',
    'min-width': resourceWidth
  },
  slot: 'insuracneName'
};
const transportName = {
  attrs: {
    prop: 'transportName',
    label: '大交通公司',
    'min-width': resourceWidth
  },
  slot: 'transportName'
};
const licenceNumber = {
  attrs: {
    prop: 'licenceNumber',
    label: '车牌号',
    'min-width': width120
  },
  slot: 'licenceNumber'
};
const busType = {
  attrs: {
    prop: 'busType',
    label: '车辆类型',
    'min-width': width160
  },
  slot: 'busType'
};
const busFee = {
  attrs: {
    prop: 'busFee',
    label: '车费',
    'min-width': 90
  },
  slot: 'busFee'
};
const date = {
  attrs: {
    prop: 'date',
    label: '日期',
    'min-width': dateWidth
  },
  slot: 'date'
};
const startBusDate = {
  attrs: {
    prop: 'startBusDate',
    label: '用车开始日期',
    'min-width': dateWidth
  },
  slot: 'startDate'
};
const endBusDate = {
  attrs: {
    prop: 'endBusDate',
    label: '用车结束日期',
    'min-width': dateWidth
  },
  slot: 'endDate'
};
const startDate = {
  attrs: {
    prop: 'startDate',
    label: '接站日期',
    'min-width': dateWidth
  },
  slot: 'startDate'
};
const endDate = {
  attrs: {
    prop: 'endDate',
    label: '送站日期',
    'min-width': dateWidth
  },
  slot: 'endDate'
};
const tripDate = {
  attrs: {
    prop: '',
    label: '行程日期',
    'min-width': width100
  },
  slot: 'date'
};
const hotelDate = {
  attrs: {
    prop: 'date',
    label: '入住日期',
    'min-width': dateWidth,
    sortable: true
  },
  slot: 'date'
};
const restaurantDate = {
  attrs: {
    prop: '',
    label: '用餐日期',
    'min-width': dateWidth
  },
  slot: 'date'
};
// const applyDate = {
//   attrs: { prop: '', label: '指定批款时间', 'min-width': 300 },
//   slot: 'date',
// }
const pickupInfo = {
  attrs: {
    prop: '',
    label: '接送信息',
    'min-width': width160
  },
  slot: 'pickupInfo'
};
const restaurantType = {
  attrs: {
    prop: 'restaurantType',
    label: '餐标',
    'min-width': width180
  },
  slot: 'restaurantType'
};
const shuttleType = {
  attrs: {
    prop: 'shuttleType',
    label: '任务类型',
    'min-width': width100
  },
  slot: 'shuttleType'
};
const phone = {
  attrs: {
    prop: 'mobilePhone',
    label: '手机',
    'min-width': width120
  },
  slot: 'mobilePhone'
};
const customer = {
  attrs: {
    prop: 'custom',
    label: '客户(子部门)',
    width: width120
  },
  slot: 'custom'
};
const project = {
  attrs: {
    prop: 'content',
    label: '项目',
    'min-width': 200
  },
  slot: 'content'
};
const customerName = {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'customerName'
};
const touristSlot = {
  attrs: {
    prop: 'members',
    label: '游客',
    'min-width': 130
  },
  slot: 'members'
};
const receptionName = {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'customerName'
};
const guideSlot = {
  attrs: {
    prop: 'guideName',
    label: '导游'
  },
  slot: 'guideName'
};
const guidesSlot = {
  attrs: {
    prop: 'guides',
    label: '导',
    // 'min-width': '160',
    width: width100,
    type: typeObj.guide
  },
  slot: 'guides'
};
const hotel = {
  attrs: {
    prop: 'hotelName',
    label: '酒店',
    'min-width': width140
  }
};
const hotelSlot = {
  ...hotel,
  slot: 'hotel'
};
const price = {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width60
  }
};
const priceSlot = {
  ...price,
  slot: 'price'
};
const quotePrice = {
  attrs: {
    prop: 'quotePrice',
    label: '单价',
    'min-width': width50
  }
};
const settlementPrice = {
  attrs: {
    prop: 'settlementPrice',
    label: '结算价',
    'min-width': width50
  }
};
const quotePriceSlot = {
  ...quotePrice,
  slot: 'quotePrice'
};
const timesSlot = {
  attrs: {
    prop: 'times',
    label: '天数',
    'min-width': width70
  },
  slot: 'times'
};
const auditCountSlot = {
  attrs: {
    prop: 'adultCount',
    label: '成人数',
    'min-width': width60
  },
  slot: 'adultCount'
};
const freeCount = {
  attrs: {
    prop: 'freeCount',
    label: '免去数量',
    'min-width': width60
  }
};
const freeCountSlot = {
  ...freeCount,
  slot: 'freeCount'
};
const type = {
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': width160
  }
};
const typeSlot = {
  ...type,
  slot: 'type'
};
const remark = {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
};
const remarkSlot = {
  ...remark,
  slot: 'remark'
};
const remarks = {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
};
const remarksSlot = {
  ...remarks,
  slot: 'remarks'
};
const count = {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': width50
  }
};
const countSlot = {
  ...count,
  slot: 'count'
};
const money = {
  attrs: {
    prop: 'money',
    label: '金额'
  }
};
const moneySlot = {
  ...money,
  slot: 'money'
};
const total = {
  attrs: {
    prop: 'total',
    label: '金额'
  }
};
const totalSlot = {
  ...total,
  slot: 'total'
};
const rowTotal = {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width100
  },
  slot: 'rowTotal'
};
const totalMoney = {
  attrs: {
    prop: 'totalMoney',
    label: '总金额'
  }
};
const totalMoneySlot = {
  ...totalMoney,
  slot: 'totalMoney'
};
// const totalMoneySlot2 = {
//   attrs: { prop: 'totalPay', label: '总金额' },
//   slot: 'totalPay',
// }
const totalPaySlot = {
  attrs: {
    prop: 'totalPay',
    label: '金额',
    'min-width': width80
  },
  slot: 'totalPay'
};
const unit = {
  attrs: {
    prop: 'unit',
    label: '单位',
    'min-width': width160
  }
};
const unitSlot = {
  ...unit,
  slot: 'unit'
};
const members = {
  attrs: {
    prop: 'members',
    label: '联系人'
  }
};
const membersSlot = {
  ...members,
  slot: 'members'
};
const contact = {
  ...members,
  slot: 'contact'
};
const payType = {
  attrs: {
    prop: 'payType',
    label: '付款方式',
    'min-width': width120
  }
};
const payTypeSlot = {
  ...payType,
  slot: 'payType'
};
const cashType = {
  attrs: {
    prop: 'cashType',
    label: '付款方式',
    'min-width': width120
  }
};
const cashTypeSlot = {
  ...cashType,
  slot: 'cashType'
};
const collectType = {
  attrs: {
    prop: 'cashType',
    label: '收款方式',
    'min-width': width120
  }
};
const collectTypeSlot = {
  ...collectType,
  slot: 'collectType'
};
const shuttleSlot = {
  attrs: {
    prop: 'shuttle',
    label: '抵/离信息'
  },
  slot: 'shuttle'
};
const otherFee = {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width60
  }
};
const otherFeeSlot = {
  ...otherFee,
  slot: 'otherFee'
};
const checkInDate = {
  attrs: {
    prop: 'orderDate',
    label: '入住时间',
    'min-width': width80
  }
};
const checkInDateSlot = {
  attrs: {
    prop: 'orderDate',
    label: '入住时间',
    'min-width': width120
  },
  slot: 'orderDate'
};
const arrangeStatus = {
  attrs: {
    prop: 'arrangeState',
    label: '安排状态',
    'min-width': width60
  },
  slot: 'arrangeState'
};
// const customOrder = { attrs: { prop: 'customOrder', label: '小团号' } }
const customerTripNumber = {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
};
const area = {
  attrs: {
    prop: 'hotelArea',
    label: '地域',
    'min-width': width100
  }
};
const areaSlot = {
  attrs: {
    prop: 'hotelArea',
    label: '地域',
    'min-width': width100
  },
  slot: 'area'
};
const standard = {
  attrs: {
    prop: 'hotelStandard',
    label: '标准',
    'min-width': width80
  }
};
const standardSlot = {
  attrs: {
    prop: 'standard',
    label: '标准',
    'min-width': width100
  },
  slot: 'standard'
};
const roomType = {
  attrs: {
    prop: 'roomType',
    label: '房型',
    'min-width': width60
  }
};
const roomTypeSlot = {
  attrs: {
    prop: 'roomType',
    label: '房型',
    'min-width': width160
  },
  slot: 'roomType'
};
export const auditSlot = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': 85
  },
  slot: 'auditStatus'
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': width80
  },
  slot: 'action',
  header: 'deleteAction'
};
const actionItemMid = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': width110
  },
  slot: 'action',
  header: 'deleteAction'
};
const selection = {
  attrs: {
    prop: 'selection',
    label: '选择',
    'min-width': width40
  },
  slot: 'selection'
};
const peopleNumber = {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
};
const startPlace = {
  attrs: {
    prop: 'startPlace',
    label: '起始地'
  }
};
const endPlace = {
  attrs: {
    prop: 'endPlace',
    label: '目的地'
  }
};
const startPlaceSlot = {
  attrs: {
    prop: 'startPosition',
    label: '起始地'
  },
  slot: 'startPlace'
};
const endPlaceSlot = {
  attrs: {
    prop: 'destinationPosition',
    label: '目的地'
  },
  slot: 'endPlace'
};
const saleUserName = {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
};
const outOPUserName = {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
};

// 发团预定安排行程
export const planTrip = [{
  attrs: {
    prop: 'itineraryDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'transportType',
    label: '大交通'
  },
  slot: 'traffic'
}, startPlaceSlot, endPlaceSlot, {
  attrs: {
    prop: 'itineraryDesc',
    label: '行程',
    'min-width': 380
  }
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐'
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '中餐'
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐'
  },
  slot: 'dinner'
}, {
  attrs: {
    prop: 'hotel',
    label: '酒店'
  }
}];

// 发团预定安排
export const planTeamArrange = [planNumberSlot, tripDate, customer, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '计调',
    width: 90
  },
  slot: 'dutyOPUserName'
}, {
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': width100
  },
  slot: 'number'
}, guidesSlot, {
  attrs: {
    prop: 'hotelOrderStatus',
    label: '房',
    'min-width': width30,
    type: typeObj.hotel
  },
  slot: 'hotelOrderStatus'
}, {
  attrs: {
    prop: 'restaurantOrderStatus',
    label: '餐',
    'min-width': width30,
    type: typeObj.restaurant
  },
  slot: 'restaurantOrderStatus'
}, {
  attrs: {
    prop: 'busCompanyOrderStatus',
    label: '车',
    'min-width': width30,
    type: typeObj.bus
  },
  slot: 'busCompanyOrderStatus'
}, {
  attrs: {
    prop: 'scenicOrderStatus',
    label: '景',
    'min-width': width30,
    type: typeObj.scenic
  },
  slot: 'scenicOrderStatus'
}, {
  attrs: {
    prop: 'insuranceOrderStatus',
    label: '险',
    'min-width': width30,
    type: typeObj.insurance
  },
  slot: 'insuranceOrderStatus'
}, {
  attrs: {
    prop: 'transportCompanyOrderStatus',
    label: '交通',
    'min-width': width30,
    type: typeObj.transport
  },
  slot: 'transportCompanyOrderStatus'
}, {
  attrs: {
    prop: 'shuttleStatus',
    label: '接送',
    'min-width': width30,
    type: typeObj.shuttle
  },
  slot: 'shuttleStatus'
}, {
  attrs: {
    prop: 'guideBorrowAlipayStatus',
    label: '额度申请',
    'min-width': width30,
    type: typeObj.alipayECode
  },
  slot: 'quota'
}, {
  attrs: {
    prop: 'guideBorrowStatus',
    label: '借款申请',
    'min-width': width30,
    type: typeObj.borrow
  },
  slot: 'guideBorrowStatus'
}, {
  attrs: {
    prop: '',
    label: '借款审批',
    'min-width': width30,
    type: typeObj.borrow
  },
  slot: 'guideBorrowApplyStatus'
}, {
  attrs: {
    prop: 'collectStatus',
    label: '代收',
    'min-width': width30,
    type: typeObj.collect
  },
  slot: 'collectStatus'
}, {
  attrs: {
    prop: 'agencyStatus',
    label: '外转',
    'min-width': width30,
    type: typeObj.localTravelAgency
  },
  slot: 'agencyStatus'
}, {
  attrs: {
    prop: 'shopOrderStatus',
    label: '购物',
    'min-width': width30,
    type: typeObj.shop
  },
  slot: 'shopOrderStatus'
}, {
  attrs: {
    prop: 'otherIncomeStatus',
    label: '其他收入',
    'min-width': width30,
    type: typeObj.otherIncome
  },
  slot: 'otherIncomeStatus'
}, {
  attrs: {
    prop: 'otherPayStatus',
    label: '其他支出',
    'min-width': width30,
    type: typeObj.otherPay
  },
  slot: 'otherPayStatus'
}, {
  attrs: {
    prop: 'sendGroupStatus',
    label: '发团状态',
    'min-width': width70
  },
  slot: 'sendGroupStatus'
}, {
  attrs: {
    prop: 'orderType',
    label: '类型',
    'min-width': width60
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'uploadAction',
    label: '旅监',
    'min-width': 60
  },
  slot: 'uploadAction'
}, actionItemMid];

// 接送预定安排
export const planPickup = [selection, {
  attrs: {
    prop: 'serialumber',
    label: '序号',
    'min-width': width40
  }
}, {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': width120
  }
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, customerName, peopleNumber, membersSlot, shuttleType, {
  attrs: {
    prop: 'shuttleDate',
    label: '行程时间'
  }
}, pickupInfo, hotel, {
  attrs: {
    prop: 'guides',
    label: '团上导游'
  },
  slot: 'guides'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '外联计调'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    'min-width': width120
  },
  slot: 'action'
}];

// 房调预定安排
export const planHotal = [planNumberSlot, checkInDate, arrangeStatus, customerTripNumber, touristSlot, area, standard, hotel, roomType, quotePrice, count, freeCount, otherFee, remark, cashTypeSlot, totalMoneySlot, actionItem];

// 房调预定安排  编辑
export const planHotalEdit = [{
  ...customerTripNumber,
  slot: 'customGerTripNumber'
}, arrangeStatus, touristSlot, checkInDateSlot, areaSlot, standardSlot, hotelSlot, roomTypeSlot, quotePriceSlot, countSlot, freeCountSlot, otherFeeSlot, remarkSlot, cashTypeSlot, {
  attrs: {
    prop: 'totalMoney',
    label: '总金额',
    'min-width': width50
  }
},
// actionItem,
{
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 接送下单
export const planPickupFleet = [membersSlot, peopleNumber, shuttleType, pickupInfo, hotel];

// 订单转出
export const planTurnOut = [planInfo, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'productName',
    label: '产品'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    width: 80
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  },
  width: 80
}, {
  attrs: {
    prop: 'memberInfo',
    label: '游客联系人'
  },
  slot: 'memberInfo'
}, {
  attrs: {
    prop: 'itineraries',
    label: '行程',
    'min-width': 200
  },
  slot: 'trip'
}, {
  attrs: {
    prop: 'hotel',
    label: '入住酒店',
    'min-width': 200
  },
  slot: 'hotel'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '应收',
    width: 60
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '应付',
    width: 60
  }
}, {
  attrs: {
    prop: 'totalPayed',
    label: '已付',
    width: 60
  }
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款',
    width: 60
  }
}, {
  attrs: {
    prop: 'unPay',
    label: '未付',
    width: 60
  }
}, {
  attrs: {
    prop: 'orderType',
    label: '类型',
    width: 40
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调',
    width: 60
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80
  },
  slot: 'action'
}];

// 游客短信通知
export const planSmsSend = [{
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号'
  }
}, planInfo, {
  attrs: {
    prop: '',
    label: '计划类型'
  }
}, {
  attrs: {
    prop: '',
    label: '产品名称'
  }
}, {
  attrs: {
    prop: '',
    label: '发团散团'
  }
}, {
  attrs: {
    prop: '',
    label: '团人数'
  }
}, {
  attrs: {
    prop: '',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '计调'
  }
}, {
  attrs: {
    prop: '',
    label: '订单发散团'
  }
}, {
  attrs: {
    prop: '',
    label: '客户'
  }
}, {
  attrs: {
    prop: '',
    label: '订单人数'
  }
}, members, {
  attrs: {
    prop: '',
    label: '销售'
  }
}, {
  attrs: {
    prop: '',
    label: '模板类型'
  }
}, {
  attrs: {
    prop: '',
    label: '通知状态'
  }
}, {
  attrs: {
    prop: '',
    label: '回复状态'
  }
}, actionItem];

/** **************************** 发团预定安排 编辑 ******************* */
// 外转费用
export const planGroundingFee = [typeSlot, remark, price, count, unit, totalSlot, actionItem];

// 外转代收
export const planGroundingProxy = [typeSlot, remark, totalSlot, actionItem];

// 外转返款
export const planGroundingRefund = [typeSlot, remark, price, count, unit, totalSlot, actionItem];

// 导游
export const planEditGuide = [auditAndCheckStatus, guideSlot,
// auditSlot,
{
  attrs: {
    prop: 'guidePhone',
    label: '手机'
  },
  slot: 'guidePhone'
}, {
  attrs: {
    prop: 'startPlanDate',
    label: '上团日期',
    'min-width': 150
  },
  slot: 'startPlanDate'
}, {
  attrs: {
    prop: 'endPlanDate',
    label: '下团日期',
    'min-width': 150
  },
  slot: 'endPlanDate'
}, {
  attrs: {
    prop: 'guideServiceFee',
    label: '导服费',
    'min-width': width100,
    abc: 'abc'
  },
  slot: 'guideServiceFee'
}, {
  attrs: {
    prop: 'reimb',
    label: '是否报账导游',
    'min-width': width100
  },
  slot: 'reimb'
}, {
  attrs: {
    prop: 'collectType',
    label: '结算方式',
    'min-width': width120
  },
  slot: 'collectType'
}, {
  attrs: {
    prop: 'guideManager',
    label: '导管',
    'min-width': width120
  },
  slot: 'guideManager'
}, {
  attrs: {
    prop: 'guideManageFee',
    label: '管理费'
  },
  slot: 'guideManageFee'
}, {
  attrs: {
    prop: 'recordingFee',
    label: '录单费'
  },
  slot: 'recordingFee'
}, {
  attrs: {
    prop: 'guideFund',
    label: '导游基金'
  },
  slot: 'guideFund'
}, {
  attrs: {
    prop: 'guideTax',
    label: '税'
  },
  slot: 'guideTax'
}, {
  attrs: {
    prop: 'depositFee',
    label: '押金',
    width: 100
  },
  slot: 'depositFee',
  header: 'depositHeader'
}, {
  attrs: {
    prop: 'voluntarilyFee',
    label: '乐捐(能量包)'
  },
  slot: 'voluntarilyFee'
}, remarkSlot, actionItem];

// 额度申请
export const planEditGuideQuota = [auditAndCheckStatus, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '申请金额'
  },
  slot: 'applyMoney'
}, {
  attrs: {
    prop: '',
    label: '申请人'
  },
  slot: 'applyer'
}, {
  attrs: {
    prop: '',
    label: '申请日期'
  },
  slot: 'applyDate'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, actionItem];

// 导游借款
export const planEditGuideBorrow = [auditAndCheckStatus, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '申请金额'
  },
  slot: 'applyMoney'
}, {
  attrs: {
    prop: '',
    label: '申请人'
  },
  slot: 'applyer'
}, {
  attrs: {
    prop: '',
    label: '申请日期'
  },
  slot: 'applyDate'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'borrowMoney',
    label: '已批金额'
  }
}, actionItem];

// 钉钉借款申请
export const planEditGuideBorrowDingDing = [auditAndCheckStatus, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'applyMoney',
    label: '申请金额'
  },
  slot: 'applyMoney'
}, {
  attrs: {
    prop: 'applyUserName',
    label: '申请人'
  }
}, {
  attrs: {
    prop: 'applyDate',
    label: '申请日期'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: '',
    label: '审核状态'
  },
  slot: 'applyStatus'
}, {
  attrs: {
    prop: 'borrowMoney',
    label: '已批金额'
  }
}, actionItem];

// 导游代收
export const planEditGuideProxy = [auditAndCheckStatus, planInfo, {
  attrs: {
    prop: 'custom',
    label: '客户(子部门)'
  },
  slot: 'custom'
}, contact, {
  attrs: {
    prop: 'count',
    label: '数量',
    width: 100
  },
  slot: 'count'
},
// totalMoneySlot2,
typeSlot, remarksSlot, moneySlot,
// {
//   attrs: { prop: 'unit', label: '代收单位', 'min-width': 300 },
//   slot: 'unit',
// },
// actionItem,
{
  attrs: {
    prop: 'actionItem',
    label: '操作'
  },
  slot: 'actionItem'
}];

// 景区
const scenicPrefix = [auditAndCheckStatus, planInfo,
// auditSlot,
{
  attrs: {
    prop: 'productName',
    label: '景区名称',
    'min-width': width160
  },
  slot: 'productName'
}, tripDate, {
  attrs: {
    prop: 'ticketType',
    label: '门票类别',
    'min-width': width180
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'sellPrice',
    label: '卖价',
    'min-width': width80
  },
  slot: 'sellPrice'
}, priceSlot];
const sceneicSuffix = [auditCountSlot, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': width70
  },
  slot: 'adultFreeCount'
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童价',
    'min-width': width80
  },
  slot: 'childPrice'
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童数',
    'min-width': width70
  },
  slot: 'childCount'
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪价',
    'min-width': width80
  },
  slot: 'accompanyPrice'
}, {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪数',
    'min-width': width70
  },
  slot: 'accompanyCount'
}];
const scenicBase = [...scenicPrefix, ...sceneicSuffix, otherFeeSlot, rowTotal, payTypeSlot, remarksSlot, actionItem];
export const planEditScenic = scenicBase;
export const planEditScenicAll = [...scenicPrefix, {
  ...settlementPrice,
  slot: 'settlementPrice'
}, ...sceneicSuffix, otherFeeSlot, rowTotal, payTypeSlot, remarksSlot, actionItem];

// 景点列表详情
const scenicDetailPrefix = [planInfo, customer, peopleNumber, contact, shuttleSlot, {
  attrs: {
    prop: 'ticketType',
    label: '门票类别',
    'min-width': width160
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'sellPrice',
    label: '卖价',
    'min-width': width70
  },
  slot: 'sellPrice'
}, priceSlot];
export const planEditScenicDetail = [...scenicDetailPrefix, ...sceneicSuffix, otherFeeSlot, totalPaySlot, actionItem];
export const planEditScenicDetailAll = [...scenicDetailPrefix, ...sceneicSuffix, {
  ...settlementPrice,
  slot: 'settlementPrice'
}, otherFeeSlot, totalPaySlot, actionItem];

// 酒店
const hotelPerfix = [auditAndCheckStatus, planInfo, {
  attrs: {
    prop: 'members',
    label: '联系人',
    'min-width': width200
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'hotelStandard',
    label: '酒店标准',
    'min-width': width120
  },
  slot: 'hotelStandard'
}, {
  attrs: {
    prop: 'hotelName',
    label: '酒店',
    'min-width': width140,
    sortable: true
  },
  slot: 'hotelName'
}, hotelDate, {
  attrs: {
    prop: 'roomInfo',
    label: '房间信息',
    'min-width': 230
  },
  slot: 'roomInfo'
}];
export const planEditHotel = [...hotelPerfix, {
  attrs: {
    prop: '',
    label: '房间类型'
  },
  children: [priceSlot, countSlot, freeCountSlot, otherFeeSlot, rowTotal]
}, payTypeSlot, remarkSlot, actionItem];
export const planEditHotelAll = [...hotelPerfix, {
  attrs: {
    prop: '',
    label: '房间类型'
  },
  children: [priceSlot, {
    ...settlementPrice,
    slot: 'settlementPrice'
  }, countSlot, freeCountSlot, otherFeeSlot, rowTotal]
}, payTypeSlot, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  },
  slot: 'outOPUserName'
}, remarkSlot, actionItem];

// 酒店列表详情
const hotelDetailPerfix = [planInfo, customer, countSlot, contact, shuttleSlot, {
  attrs: {
    prop: 'ticketType',
    label: '房型',
    'min-width': width180
  },
  slot: 'ticketType'
}, priceSlot];
const hotelDetailSuffix = [auditCountSlot, {
  attrs: {
    prop: 'adultFreeCount',
    label: '免去数量'
  },
  slot: 'adultFreeCount'
}, otherFeeSlot, totalPaySlot, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    'min-width': width100
  },
  slot: 'action'
}];
export const planEditHotelDetail = [...hotelDetailPerfix, ...hotelDetailSuffix];
export const planEditHotelDetailAll = [...hotelDetailPerfix, {
  ...settlementPrice,
  slot: 'settlementPrice'
}, ...hotelDetailSuffix];

// 餐厅
export const planEditRestaurant = [auditAndCheckStatus, planInfo,
// auditSlot,
{
  attrs: {
    prop: 'restaurantName',
    label: '餐厅名称',
    'min-width': width180
  },
  slot: 'restaurantName'
},
// 餐厅地区新版
{
  attrs: {
    prop: 'area',
    label: '地区',
    'min-width': width100
  },
  slot: 'area'
}, restaurantDate,
// timesSlot,
{
  attrs: {
    prop: 'mealType',
    label: '用餐类别',
    'min-width': width100
  },
  slot: 'mealType'
}, restaurantType, priceSlot, countSlot, freeCountSlot, otherFeeSlot, rowTotal, payTypeSlot, remarkSlot, actionItem];

// 餐厅列表详情
export const planEditRestaurantDetail = [planInfo, customer, peopleNumber, contact, shuttleSlot, timesSlot, {
  attrs: {
    prop: 'mealType',
    label: '用餐类别',
    'min-width': width120
  },
  slot: 'mealType'
}, restaurantType, priceSlot, auditCountSlot, freeCountSlot, otherFeeSlot, totalPaySlot, actionItem];

// 车队
export const planEditFleet = [auditAndCheckStatus, planInfo,
// auditSlot,
busCompanyName, {
  attrs: {
    prop: 'useType',
    label: '用车类型',
    'min-width': 95
  },
  slot: 'useType'
}, busType, {
  attrs: {
    prop: 'driver',
    label: '驾驶员',
    'min-width': 95
  },
  slot: 'driver'
}, phone, licenceNumber, startBusDate, endBusDate, busFee, otherFeeSlot, rowTotal, payTypeSlot, {
  attrs: {
    prop: 'bill',
    label: '单据',
    'min-width': 85
  },
  slot: 'bill'
}, remarkSlot, actionItem];

// 车队列表详情
export const planEditFleetDetail = [planInfo, customer, peopleNumber, contact, shuttleSlot, priceSlot, countSlot, otherFeeSlot, totalPaySlot, actionItem];

// 接送
export const planEditPickup = [auditAndCheckStatus, planInfo,
// auditSlot,
busCompanyName, shuttleType, busType, {
  attrs: {
    prop: 'driver',
    label: '驾驶员',
    'min-width': width100
  },
  slot: 'driver'
}, phone, licenceNumber, startDate, endDate, busFee, otherFeeSlot, {
  attrs: {
    prop: 'peopleCount',
    label: '人数',
    width: 60
  }
}, rowTotal, payTypeSlot, remarkSlot, actionItem];

// 接送列表详情
export const planEditPickupDetail = [planInfo, customer, countSlot, membersSlot, shuttleSlot, otherFeeSlot, totalPaySlot];

// 保险
export const planEditInsurance = [auditAndCheckStatus, planInfo,
// auditSlot,
insuracneName, {
  attrs: {
    prop: 'type',
    label: '险种',
    'min-width': width200
  },
  slot: 'type'
}, date, priceSlot, countSlot, otherFeeSlot, rowTotal, payTypeSlot, remarkSlot, actionItem];

// 保险列表详情
export const planEditInsuranceDetail = [planInfo, customer, peopleNumber, contact, shuttleSlot, countSlot, otherFeeSlot, totalPaySlot, actionItem];

// 大交通
export const planEditTraffic = [auditAndCheckStatus, planInfo, transportName, date, {
  attrs: {
    prop: 'frequency',
    label: '班次'
  },
  slot: 'frequency'
}, startPlaceSlot, endPlaceSlot, typeSlot, priceSlot, countSlot, otherFeeSlot, rowTotal, {
  attrs: {
    prop: '$total',
    label: '总金额'
  }
}, payTypeSlot, remarkSlot, actionItem];

// 大交通列表详情
export const planEditTrafficDetail = [planInfo, customer, peopleNumber, contact, shuttleSlot, countSlot, otherFeeSlot, totalPaySlot];

// 其他收入
export const planEditOtherIncome = [auditAndCheckStatus, planInfo,
// auditSlot,
project, priceSlot, countSlot, totalPaySlot, collectTypeSlot, remarkSlot, actionItem];

// 其他收入列表详情
export const planEditOtherIncomeDetail = [planInfo, customer, peopleNumber, membersSlot, shuttleSlot, priceSlot, countSlot, totalPaySlot, actionItem];

// 其他支出
export const planEditOtherPay = [auditAndCheckStatus, planInfo,
// auditSlot,
project, priceSlot, countSlot, totalPaySlot, payTypeSlot, {
  attrs: {
    prop: 'otherUnit',
    label: '单位',
    'min-width': 120
  },
  slot: 'otherUnit'
}, remarkSlot, actionItem];

// 其他支出列表详情
export const planEditOtherPayDetail = [planInfo, customer, peopleNumber, membersSlot, shuttleSlot, priceSlot, countSlot, totalPaySlot, actionItem];

// getUserData
// 购物店
export const planEditShopping = [
// auditAndCheckStatus,
// shopName,
// auditSlot,
// enterDate,
{
  attrs: {
    prop: 'shopName',
    label: '购物店',
    'min-width': width90
  },
  slot: 'shopName'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期',
    'min-width': width90
  },
  slot: 'orderDate'
},
// { attrs: { prop: 'guide', label: '导游', 'min-width': 140 }, slot: 'guide' },
{
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    'min-width': width50
  },
  slot: 'enterCount'
}, {
  attrs: {
    prop: 'headPrice',
    label: '人头单价',
    'min-width': width60
  },
  slot: 'headPrice'
}, {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头返佣',
    'min-width': width60
  },
  slot: 'totalHeadRebate'
}, {
  attrs: {
    prop: 'goodsName',
    label: '商品名称',
    'min-width': width90
  },
  slot: 'goodsName'
}, {
  attrs: {
    prop: 'money',
    label: '打单金额',
    'min-width': width80
  },
  slot: 'money'
}, {
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': width90
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [{
    attrs: {
      prop: '',
      label: '社佣'
    },
    children: [{
      attrs: {
        prop: 'shopRate',
        label: '比例',
        'min-width': width50
      },
      slot: 'shopRate'
    }, {
      attrs: {
        prop: 'shopRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'shopRebate'
    }]
  }]
}, {
  attrs: {
    prop: 'pay',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: '',
      label: '导佣',
      'min-width': width40
    },
    children: [{
      attrs: {
        prop: 'guideRate',
        label: '比例',
        'min-width': width50
      },
      slot: 'guideRate'
    }, {
      attrs: {
        prop: 'guideRebateDeduction',
        label: '扣除',
        'min-width': width40
      },
      slot: 'guideRebateDeduction'
    }, {
      attrs: {
        prop: 'guideRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'guideRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '司佣'
    },
    children: [{
      attrs: {
        prop: 'driverRate',
        label: '比例',
        'min-width': width50
      },
      slot: 'driverRate'
    }, {
      attrs: {
        prop: 'driverRebateDeduction',
        label: '扣除',
        'min-width': width40
      },
      slot: 'driverRebateDeduction'
    }, {
      attrs: {
        prop: 'driverRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'driverRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '陪佣'
    },
    children: [{
      attrs: {
        prop: 'accompanyRate',
        label: '比例',
        'min-width': width50
      },
      slot: 'accompanyRate'
    }, {
      attrs: {
        prop: 'accompanyRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'accompanyRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '导管佣金'
    },
    children: [{
      attrs: {
        prop: 'guideManagerRate',
        label: '比例',
        'min-width': width50
      },
      slot: 'guideManagerRate'
    }, {
      attrs: {
        prop: 'guideManagerRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'guideManagerRebate'
    }]
  }]
}, {
  attrs: {
    prop: 'travelGrossProfit',
    label: '社利',
    'min-width': width60
  },
  slot: 'travelGrossProfit'
}, {
  attrs: {
    prop: 'guideCollect',
    label: '导游代收',
    'min-width': width60
  },
  slot: 'guideCollect'
}, {
  attrs: {
    prop: 'shopRemark',
    label: '商品备注',
    'min-width': width60
  },
  slot: 'shopRemark'
}, remarkSlot, actionItem];
export const planEditShoppingOld = [auditAndCheckStatus,
// shopName,
// auditSlot,
// enterDate,
{
  attrs: {
    prop: 'shopName',
    label: '购物店',
    'min-width': width120
  },
  slot: 'shopName'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期',
    'min-width': width120
  },
  slot: 'orderDate'
},
// { attrs: { prop: 'guide', label: '导游', 'min-width': 140 }, slot: 'guide' },
{
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    'min-width': width80
  },
  slot: 'enterCount'
}, {
  attrs: {
    prop: 'headPrice',
    label: '人头单价',
    'min-width': width80
  },
  slot: 'headPrice'
}, {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头返佣',
    'min-width': width80
  },
  slot: 'totalHeadRebate'
}, {
  attrs: {
    prop: 'goodsName',
    label: '商品名称',
    'min-width': width120
  },
  slot: 'goodsName'
}, {
  attrs: {
    prop: 'money',
    label: '打单金额',
    'min-width': width100
  },
  slot: 'money'
}, {
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': width120
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [{
    attrs: {
      prop: '',
      label: '社佣'
    },
    children: [{
      attrs: {
        prop: 'shopRate',
        label: '比例',
        'min-width': width60
      },
      slot: 'shopRate'
    }, {
      attrs: {
        prop: 'shopRebate',
        label: '金额',
        'min-width': width60
      },
      slot: 'shopRebate'
    }]
  }]
},
// redEnvelope
{
  attrs: {
    prop: 'pay',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: '',
      label: '导佣',
      'min-width': width60
    },
    children: [{
      attrs: {
        prop: 'guideRate',
        label: '比例',
        'min-width': width60
      },
      slot: 'guideRate'
    }, {
      attrs: {
        prop: 'guideRebateDeduction',
        label: '扣除',
        'min-width': width60
      },
      slot: 'guideRebateDeduction'
    }, {
      attrs: {
        prop: 'guideRebate',
        label: '金额',
        'min-width': width60
      },
      slot: 'guideRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '司佣'
    },
    children: [{
      attrs: {
        prop: 'driverRate',
        label: '比例',
        'min-width': width60
      },
      slot: 'driverRate'
    }, {
      attrs: {
        prop: 'driverRebateDeduction',
        label: '扣除',
        'min-width': width60
      },
      slot: 'driverRebateDeduction'
    }, {
      attrs: {
        prop: 'driverRebate',
        label: '金额',
        'min-width': width60
      },
      slot: 'driverRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '陪佣'
    },
    children: [{
      attrs: {
        prop: 'accompanyRate',
        label: '比例',
        'min-width': width60
      },
      slot: 'accompanyRate'
    }, {
      attrs: {
        prop: 'accompanyRebate',
        label: '金额',
        'min-width': width60
      },
      slot: 'accompanyRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '导管佣金'
    },
    children: [{
      attrs: {
        prop: 'guideManagerRate',
        label: '比例',
        'min-width': width60
      },
      slot: 'guideManagerRate'
    }, {
      attrs: {
        prop: 'guideManagerRebate',
        label: '金额',
        'min-width': width60
      },
      slot: 'guideManagerRebate'
    }]
  }]
}, {
  attrs: {
    prop: 'travelGrossProfit',
    label: '社利',
    'min-width': width80
  },
  slot: 'travelGrossProfit'
}, {
  attrs: {
    prop: 'guideCollect',
    label: '导游代收',
    'min-width': width80
  },
  slot: 'guideCollect'
}, {
  attrs: {
    prop: 'shopRemark',
    label: '商品备注',
    'min-width': width80
  },
  slot: 'shopRemark'
}, remarkSlot, actionItem];

// 按团转
export const planTransforTeam = [customerTripNumber, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'contactName',
    label: '游客联系人'
  }
}, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'total',
    label: '团款'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'fee',
    label: '外转费用'
  },
  slot: 'fee'
}, {
  attrs: {
    prop: 'proxy',
    label: '外转代收'
  },
  slot: 'proxy'
}, {
  attrs: {
    prop: 'subsidy',
    label: '外转返款'
  },
  slot: 'subsidy'
}];

// 按单转
export const planTransforOrder = [...planTransforTeam.slice(0, 7), {
  attrs: {
    prop: 'trip',
    label: '外转'
  },
  slot: 'trip'
}, ...planTransforTeam.slice(7)];

// 外转费用 查看
export const planEditReception = [receptionName, peopleNumber, totalMoneySlot, typeSlot, price, count, unitSlot, total, remarkSlot, actionItem];

// 外转费用 编辑
export const planEditReceptionEdit = [typeSlot, remarkSlot, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width160
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': width160
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'unit',
    label: '单位',
    'min-width': width160
  },
  slot: 'unit'
}, totalSlot, actionItem];

// 外转费用代收
export const planEditReceptionProxy = [receptionName, peopleNumber, totalMoneySlot, typeSlot, remarkSlot, total];

// 外转费用代收 编辑
export const planEditReceptionProxyEdit = [typeSlot, remarkSlot, totalSlot, actionItem];

// 外转费用返款
export const planEditReceptionRefund = [receptionName, peopleNumber, totalMoneySlot, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, total];

// 外转费用返款 编辑
export const planEditReceptionRefundEdit = [typeSlot, remarkSlot, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, totalSlot, actionItem];

// 外转备注
export const planEditReceptionRemark = [receptionName, remarks];

// 通用列表
export const planEditSelect = [selection, planInfo, customer, peopleNumber, contact];

// 游客名单
export { orderTouristListDetail as planEditTourist } from './order';

// 计划详情小组列表
export const orderListDetail = [{
  attrs: {
    prop: 'orderNumber',
    label: '流水号'
  }
}, customerTripNumber,
// {
//   attrs: { prop: 'customerName', label: '组团社/客户' },
//   slot: 'customerName',
// },
{
  attrs: {
    prop: 'welcomeBorad',
    label: '接站牌'
  }
}, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'productName',
    label: '线路产品'
  }
}, peopleNumber, membersSlot, outOPUserName, saleUserName, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  }
}];

// 计划详情行程列表
export const planItineraryListDetail = [{
  attrs: {
    prop: 'itineraryDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'transportType',
    label: '大交通'
  },
  slot: 'transportType'
}, startPlace, endPlace, {
  attrs: {
    prop: 'itineraryDesc',
    label: '行程',
    'min-width': 380
  }
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐'
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '中餐'
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐'
  },
  slot: 'dinner'
}, {
  attrs: {
    prop: 'standard',
    label: '酒店'
  }
}];

// 计划详情客户团款列表
export const planFundDetail = [planInfo, {
  attrs: {
    prop: 'welcomeBorad',
    label: '接站牌'
  }
}, peopleNumber, {
  attrs: {
    prop: 'itineraryDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'transportType',
    label: '大交通'
  }
}, startPlace, endPlace, {
  attrs: {
    prop: 'itineraryDesc',
    label: '行程',
    'min-width': 380
  }
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐'
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '中餐'
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐'
  },
  slot: 'dinner'
}, {
  attrs: {
    prop: 'standard',
    label: '酒店'
  }
}];
export const planScenicDetail = [auditAndCheckStatus, planInfo, {
  attrs: {
    prop: 'productName',
    label: '景区名称',
    'min-width': width160
  },
  slot: 'productName'
}, date, {
  attrs: {
    prop: 'ticketType',
    label: '门票类别',
    'min-width': width160
  },
  slot: 'ticketType'
}, quotePriceSlot, ...sceneicSuffix, otherFee, totalPaySlot, payType, remark];
export const planScenicDetailAll = [...planScenicDetail.slice(0, 5), settlementPrice, ...planScenicDetail.slice(5)];

// 旅监导游
export const planTravelGuide2 = [{
  attrs: {
    prop: 'guideName',
    label: '导游姓名'
  },
  slot: 'guideName'
}, {
  attrs: {
    prop: 'guideNum',
    label: '导游证号'
  },
  slot: 'guideNum'
}, {
  attrs: {
    prop: 'guideTel',
    label: '导游电话'
  },
  slot: 'guideTel'
}, {
  attrs: {
    prop: 'guideCompany',
    label: '挂靠单位'
  },
  slot: 'guideCompany'
}];

// 导游列表
export const guideLine = [{
  attrs: {
    prop: 'tourname',
    label: '名字'
  }
}, {
  attrs: {
    prop: 'tourcertificateno',
    label: '导游证号'
  }
}, {
  attrs: {
    prop: 'tourtelno',
    label: '导游证号'
  }
}, {
  attrs: {
    prop: 'travelagencylicense',
    label: '挂靠单位'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 60
  },
  slot: 'action'
}];

// 旅监导游
export const planTravelGuide = [{
  attrs: {
    prop: 'name',
    label: '导游姓名',
    'min-width': 100
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'Code',
    label: '导游证号'
  },
  slot: 'Code'
}, {
  attrs: {
    prop: 'startTripDate',
    label: '上团时间',
    'min-width': 160
  },
  slot: 'startTripDate'
}, {
  attrs: {
    prop: 'endTripDate',
    label: '下团时间',
    'min-width': 160
  },
  slot: 'endTripDate'
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '联系电话',
    'min-width': 120
  },
  slot: 'mobileIphone'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 80
  },
  slot: 'action'
}];

// 旅监车队
export const planTravelFleet = [{
  attrs: {
    prop: 'licenceNumber',
    label: '车牌号',
    'min-width': 100
  },
  slot: 'licenceNumber'
}, {
  attrs: {
    prop: 'busType',
    label: '车型'
  },
  slot: 'busType'
}, {
  attrs: {
    prop: 'seat',
    label: '座位'
  },
  slot: 'seat'
}, {
  attrs: {
    prop: 'startDate',
    label: '上团时间',
    'min-width': 160
  },
  slot: 'startDate'
}, {
  attrs: {
    prop: 'endDate',
    label: '下团时间',
    'min-width': 160
  },
  slot: 'endDate'
}, {
  attrs: {
    prop: 'busName',
    label: '车队名称'
  },
  slot: 'busName'
}, {
  attrs: {
    prop: 'driverName',
    label: '司机姓名'
  },
  slot: 'driverName'
}, {
  attrs: {
    prop: 'Phome',
    label: '联系电话',
    'min-width': 120
  },
  slot: 'Phome'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 80
  },
  slot: 'action'
}];

// 旅监客人名单
export const planTravelTourist = [{
  attrs: {
    prop: 'name',
    label: '姓名',
    'min-width': 120
  },
  slot: 'name'
},
// { attrs: { prop: 'sex', label: '性别', 'min-width': 120 }, slot: 'sex' },
{
  attrs: {
    prop: 'cardType',
    label: '证件类型',
    'min-width': 120
  },
  slot: 'cardType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号'
  },
  slot: 'cardNumber'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '联系电话'
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 80
  },
  slot: 'action'
}];

// 旅监客人名单
export const planTravelJourney = [{
  attrs: {
    prop: 'Day',
    label: '行程日期',
    'min-width': 100
  },
  slot: 'Day'
}, {
  attrs: {
    prop: 'itineraries',
    label: '行程内容'
  },
  slot: 'itineraries'
}, {
  attrs: {
    prop: 'hotel',
    label: '入住酒店',
    'min-width': 120
  },
  slot: 'hotel'
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐',
    'min-width': 60
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '中餐',
    'min-width': 60
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐',
    'min-width': 60
  },
  slot: 'dinner'
}, {
  attrs: {
    prop: 'Shop',
    label: '购物',
    'min-width': 160
  },
  slot: 'Shop'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}
// { attrs: { prop: 'action', label: '操作', 'min-width': 80 }, slot: 'action' },
];

// 旅监客人名单
export const planTravelItinerarie = [{
  attrs: {
    prop: 'relevancePlaceType',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'relevancePlaceName',
    label: '地点'
  },
  slot: 'address'
}, {
  attrs: {
    prop: 'arrivalTime',
    label: '到达时间',
    width: 160
  },
  slot: 'arrivalTime'
}, {
  attrs: {
    prop: 'arrivalDistrict',
    label: '到达区县'
  },
  slot: 'arrivalDistrict'
}, {
  attrs: {
    prop: 'stopoverTime',
    label: '停留时间'
  },
  slot: 'stopoverTime'
}, {
  attrs: {
    prop: 'payItemType',
    label: '项目类型'
  },
  slot: 'payItemType'
}, {
  attrs: {
    prop: 'routeRemark',
    label: '站点内容'
  },
  slot: 'routeRemark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 酒店安排 游客联系人=》订单信息
export const planHotelListDetail = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': 140
  },
  slot: 'orderInfo'
}, customerName, {
  attrs: {
    prop: 'welcomeBorad',
    label: '游客姓名（接站牌）',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数',
    'min-width': 80
  },
  slot: 'count'
}, membersSlot, {
  attrs: {
    prop: 'pick',
    label: '抵达信息'
  },
  slot: 'pick'
}, {
  attrs: {
    prop: 'send',
    label: '送离信息'
  },
  slot: 'send'
}, outOPUserName, remark, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 60
  },
  slot: 'action'
}];

// 计划详情客户团款列表
export const orderFundDetail = [planInfo, {
  attrs: {
    prop: 'welcomeBorad',
    label: '接站牌'
  }
}, {
  attrs: {
    prop: 'members',
    label: '联系人'
  },
  slot: 'members'
}, peopleNumber, outOPUserName, saleUserName, {
  attrs: {
    prop: 'totalIncome',
    label: '总金额'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'times',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  }
},
// auditSlot,
{
  attrs: {
    prop: 'totalIncomed',
    label: '已收'
  }
}, {
  attrs: {
    prop: 'unTotalIncome',
    label: '未收'
  }
}];
// 计划游客列表
export const planTourist = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 80
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'name',
    label: '姓名'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '电话',
    width: 120
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型',
    width: 120
  },
  slot: 'cardType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  }
}, {
  attrs: {
    prop: 'total',
    label: '购物金额',
    width: 120
  },
  slot: 'total'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 140
  },
  slot: 'action'
}];

// 计划游客列表
export const guideStatisticsTabel = [{
  attrs: {
    prop: 'name',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'plans',
    label: '团数'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头返佣'
  }
}, {
  attrs: {
    prop: 'totalBuyMoney',
    label: '总购物金额'
  }
}, {
  attrs: {
    prop: 'shopPerCapita',
    label: '总购物人均'
  }
}, {
  attrs: {
    prop: 'totalDriverRebate',
    label: '司机收益'
  }
}, {
  attrs: {
    prop: 'totalGuideRebate',
    label: '导游收益'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利'
  }
}];