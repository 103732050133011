/* eslint-disable no-empty-pattern */
import { signRequest, removeSignRequest, logoffSignRequest, alipayInstitutionRequest } from '@/api/enterprise';
const dfaultState = () => ({});
const state = dfaultState();
const getters = {};
const actions = {
  sign({}, params) {
    return new Promise((resolve, reject) => {
      signRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res.data);
        }
      });
    });
  },
  removeSign({}, params) {
    return new Promise((resolve, reject) => {
      removeSignRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res.data);
        }
      });
    });
  },
  logoffSign({}, params) {
    return new Promise((resolve, reject) => {
      logoffSignRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res.data);
        }
      });
    });
  },
  // 企业码制度详情
  alipayInstitution({}, queryParam) {
    return alipayInstitutionRequest(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};