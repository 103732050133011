var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.starDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangStartTripDate
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    on: {
      "on-select": _vm.selectCustom
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "计划类型",
      list: _vm.reportPlanType
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("div", {
    staticClass: "table-base-box"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };