import TopTitle from '../components/TopTitle';
import { typeMixin } from '@/mixin';
import { parseTime } from '@/utils';
const tableData = [{
  rows: [{
    label: '产品名称'
  }, {
    content: 'productName'
  }, {
    label: '大团号'
  }, {
    content: 'planNumber',
    span: 2
  }, {
    label: '总人数'
  }, {
    content: '电话',
    slot: 'number'
  }, {
    label: '用车单位'
  }, {
    content: 'busCompanyNames',
    span: 2,
    slot: 'busCompanyNames'
  }, {
    label: '车型'
  }, {
    content: 'busCompanyTypes',
    slot: 'busCompanyTypes'
  }]
}, {
  rows: [{
    label: '发团日期'
  }, {
    content: 'startPlanDate'
  }, {
    label: '散团日期'
  }, {
    content: 'endPlanDate',
    span: 2
  }, {
    label: '全陪'
  }, {
    content: 'accompanyInfo'
  }, {
    label: '车号'
  }, {
    content: 'licenceNumbers',
    span: 2,
    slot: 'licenceNumbers'
  }, {
    label: '车费'
  }, {
    content: 'busFees',
    slot: 'busFees'
  }]
}, {
  rows: [{
    label: '操作计调'
  }, {
    content: 'dutyOPUserName'
  }, {
    label: '房调'
  }, {
    content: 'hotel',
    span: 2,
    slot: 'hotel'
  }, {
    label: '地陪'
  }, {
    content: 'guideName'
  }, {
    label: '司机'
  }, {
    content: 'driverName',
    span: 2,
    slot: 'driverName'
  }, {
    label: '付款方式'
  }, {
    content: 'busCompanyPayType'
  }]
}];
const tableData2 = [{
  rows: [{
    label: '服务标准'
  }, {
    content: 'serviceStandardRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '用房'
  }, {
    content: 'agencyInfoHotelAdjustment',
    span: 11
  }]
}, {
  rows: [{
    label: '用餐'
  }, {
    content: 'agencyInfoRestaurantAdjustment',
    span: 11
  }]
}, {
  rows: [{
    label: '用车'
  }, {
    content: 'agencyInfoCarAdjustment',
    span: 11
  }]
}, {
  rows: [{
    label: '景点'
  }, {
    content: 'scenicRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '特别交代'
  }, {
    content: 'friendlyReminderRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '开票信息'
  }, {
    content: 'agencyInfoTaxInvoice',
    span: 11
  }]
}, {
  rows: [{
    label: '票务'
  }, {
    content: 'agencyInfoTicketBusiness',
    span: 11
  }]
}, {
  rows: [{
    label: '运营'
  }, {
    content: 'agencyInfoTrade',
    span: 11
  }]
}, {
  rows: [{
    label: '客服'
  }, {
    content: 'agencyInfoService',
    span: 11
  }]
}, {
  rows: [{
    label: '公司紧急'
  }, {
    content: 'agencyInfoUrgent',
    span: 11
  }]
}];
export default {
  components: {
    TopTitle
  },
  mixins: [typeMixin],
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      data: null,
      tableData,
      tableData2,
      printDate
    };
  },
  computed: {
    getRowspan() {
      return item => {
        return item.respOrderTrips.length;
      };
    },
    formatRoom() {
      return val => {
        if (!val) return;
        let arr = val.split('|');
        arr = arr.filter(it => it !== '' && it !== undefined);
        return arr || [];
      };
    },
    isDataRemark() {
      return val => {
        return val == 'scenicRemark' || val == 'busRemark' || val == 'hotelRemark' || val == 'serviceStandardRemark' || val == 'friendlyReminderRemark';
      };
    },
    getBusTypeList() {
      if (!this.data.busCompanyTypes) return [];
      let arr = this.data.busCompanyTypes.split(', ');
      return arr.map(it => {
        return it.split('|')[0];
      });
    },
    getBusFees() {
      if (!this.data.busFees) return [];
      let arr = this.data.busFees.split(', ');
      return arr.map(it => {
        return it * 0.01;
      });
    },
    getArrayList() {
      return type => {
        if (!type) return [];
        let val = this.data[type];
        if (!val) return [];
        return val.split(', ');
      };
    },
    formatDateTime() {
      return val => {
        if (!val) return '';
        return parseTime(val, '{y}-{m}-{d} {h}:{i}');
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchReceipt', id).then(data => {
        if (data.planId > 0) {
          this.fetchPersonalPlan(data.planId);
        }
      });
    },
    fetchPersonalPlan(id) {
      this.$store.dispatch('order/print/fetchPersonalPlan', id).then(data => {
        this.data = data;
        this.$emit('onMounted');
      });
    }
  }
};