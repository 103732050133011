var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "名称",
      maxlength: "20",
      "show-word-limit": "",
      "is-require": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("InputBase", {
    attrs: {
      label: "卡号",
      "is-require": "",
      maxlength: "20",
      "show-word-limit": "",
      oninput: "value = value.replace(/[^\\d]/g, '')"
    },
    model: {
      value: _vm.form.cardNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cardNo", $$v);
      },
      expression: "form.cardNo"
    }
  }), _c("SelectBase", {
    attrs: {
      list: _vm.SelectBankTypeList,
      label: "类型"
    },
    model: {
      value: _vm.form.bankType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankType", $$v);
      },
      expression: "form.bankType"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "开户人",
      "is-require": "",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.account,
      callback: function ($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  }), _c("InputBase", {
    attrs: {
      label: "开户行",
      "is-require": "",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.bank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bank", $$v);
      },
      expression: "form.bank"
    }
  })], 1), _c("div", {
    staticClass: "input-field is-require-box"
  }, [_c("InputNumber", {
    attrs: {
      disabled: _vm.isUpdate,
      label: "期初金额",
      "is-require": "",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.initBlance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "initBlance", $$v);
      },
      expression: "form.initBlance"
    }
  }), !_vm.isUpdate ? _c("InputDatePicker", {
    attrs: {
      label: "期初日期",
      "is-require": ""
    },
    model: {
      value: _vm.form.initData,
      callback: function ($$v) {
        _vm.$set(_vm.form, "initData", $$v);
      },
      expression: "form.initData"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };