var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex"
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "type",
      disabled: _vm.disabled
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("cashType", $event);
      }
    },
    model: {
      value: _vm.form.cashTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashTypeLabel", $$v);
      },
      expression: "form.cashTypeLabel"
    }
  }), _c("span", {
    staticClass: "ml10"
  }), _vm.visible ? _c(_vm.compName, {
    tag: "component",
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("resourceId", $event);
      }
    },
    model: {
      value: _vm.form.resourceName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "resourceName", $$v);
      },
      expression: "form.resourceName"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };