var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tooltip", {
    attrs: {
      content: _vm.tips,
      placement: "top"
    }
  }, [_c("div", {
    class: ["status-icon", _vm.type]
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };