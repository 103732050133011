var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "export-custom-table"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "turnoverCompletionRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.turnoverCompletionRate)) + " ")];
      }
    }, {
      key: "turnoverYearRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.turnoverCompletionRate)) + " ")];
      }
    }, {
      key: "turnoverDifference",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.turnoverDifference) + " ")];
      }
    }, {
      key: "profitDifference",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.profitDifference) + " ")];
      }
    }, {
      key: "peopleCompletionRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.peopleCompletionRate)) + " ")];
      }
    }, {
      key: "peopleYearRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.peopleYearRate)) + " ")];
      }
    }, {
      key: "profitCompletionRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.profitCompletionRate)) + " ")];
      }
    }, {
      key: "profitYearRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.decimal(scope.row.profitYearRate)) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };