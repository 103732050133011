import { FooterBar } from '@/components/common';
export default {
  components: {
    FooterBar
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    handlePrint() {
      // const comp = this.tabMenu[this.tabIndex].component
      let {
        id,
        itemId,
        planId
      } = this.$route.query;
      const routerObj = this.$router.resolve({
        path: '/print-download',
        query: {
          id,
          planId: planId || id,
          itemId: itemId || '',
          name: this.name
        }
      });
      window.open(routerObj.href, '_blank');
    },
    handleClose() {
      window.close();
    }
  }
};