var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      label: "大团号"
    }
  }), _c("el-radio-group", {
    model: {
      value: _vm.radio,
      callback: function ($$v) {
        _vm.radio = $$v;
      },
      expression: "radio"
    }
  }, [_c("div", [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v(" 由系统生成 ")])], 1), _c("div", [_c("el-radio", {
    attrs: {
      label: 2,
      disabled: _vm.isForbid
    }
  }, [_c("el-input", _vm._g(_vm._b({
    attrs: {
      placeholder: "自定义团号",
      disabled: _vm.hasDisable,
      onkeyup: "value=value.replace(/^\\s+|\\s+$/g,'')"
    },
    model: {
      value: _vm.getValue,
      callback: function ($$v) {
        _vm.getValue = $$v;
      },
      expression: "getValue"
    }
  }, "el-input", _vm.$attrs, false), _vm.$listeners))], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };