import { fetchLog, fetchList, fetchLine, createOrUpdateLine, deleteLine } from '@/api/resource/line';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  lineData: null,
  lineDataOrigin: null,
  lineLog: null
});
const state = dfaultState();
const getters = {
  lineDataOrigin: state => state.lineDataOrigin,
  lineData: state => state.lineData,
  lineLog: state => state.lineLog
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchLine(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.line
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdateLine(opts);
  },
  // eslint-disable-next-line no-unused-vars
  delete({
    commit
  }, id) {
    return deleteLine(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.lineData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.lineDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.lineLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.lineData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.lineData.list.filter(it => it.id !== id);
    state.lineData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};