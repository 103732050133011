import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { asyncRoutes, constantRoutes } from '@/router';
import { permissionRoleHave, permissionRole } from '@/api/system/role';
import { getStorage, setStorage } from '@/utils/storage';
import { CustomAuthKeys } from '@/config/permission';
import paymentRouter from '@/router/modules/payment';
const PERMISSION_KEY = 'permissionKey';
const PERMISSION_ROLES_KEY = 'permissionRolesKey';
const PERMISSION_MAP = Object.keys(CustomAuthKeys).map(key => CustomAuthKeys[key]);
const dfaultState = () => ({
  routes: constantRoutes,
  subMenus: [],
  rolePermissionList: null,
  // 用户拥有的菜单列表
  authPermission: null,
  // 操作权限
  requestPass: false
});
const state = dfaultState();
const getters = {
  routes: state => state.routes,
  subMenus: state => state.subMenus,
  requestPass: state => state.requestPass,
  rolePermissionList: state => {
    return state.rolePermissionList || getStorage(PERMISSION_KEY);
  },
  authPermission: state => {
    return state.authPermission || getStorage(PERMISSION_ROLES_KEY);
  }
};
const actions = {
  permissionRole({
    commit,
    rootGetters
  }, rid) {
    const {
      roleId
    } = rootGetters['user/userData'] || rid;
    return permissionRole(roleId).then(res => {
      if (!res || !res.data) return;
      let {
        roleMenus
      } = res.data;
      const pickData = roleMenus.reduce((acc, cur) => {
        let arr = [];
        cur.subMenus.forEach(sub => {
          if (sub.roleMenuOpts.length) {
            arr = [...arr, ...sub.roleMenuOpts];
          }
        });
        acc = [...acc, ...arr];
        return acc;
      }, []);
      const result = pickerOpts(pickData, PERMISSION_MAP);
      commit('SET_ROLES_PERMISSION', result);
      return result;
    });
  },
  // 获取用户权限菜单
  fetchUserAllowMenu({
    dispatch,
    commit,
    rootGetters
  }, rid) {
    const {
      roleId
    } = rootGetters['user/userData'] || rid;
    commit('SET_STATUS');
    return permissionRoleHave(roleId).then(res => {
      let {
        roleMenus
      } = res.data;
      roleMenus = roleMenus.filter(it => it.code.indexOf('mall') < 0);
      commit('SET_ROLES', roleMenus);
      return dispatch('generateRoutes', roleMenus);
    }).catch(() => {
      commit('SET_ROUTES', constantRoutes);
    });
  },
  generateRoutes({
    commit
  }, roleMenus) {
    return new Promise(resolve => {
      const filteredMenu = sortMenu(filterMenu(roleMenus, asyncRoutes));

      // 增值服务菜单不用授权显示直接拼接后面
      const menu = [...filteredMenu, paymentRouter];
      commit('SET_ROUTES', menu);
      resolve(menu);
    });
  }
};
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = constantRoutes.concat(routes);
  },
  SET_STATUS: state => {
    state.requestPass = true;
  },
  SET_ROLES: (state, roles) => {
    state.rolePermissionList = roles;

    // state.subMenus = roles.reduce((acc, cur) => {
    //   acc = [...acc, ...cur.subMenus]
    //   return acc
    // }, [])
    function flatList(list) {
      let temp = [];
      list.forEach(item => {
        if (item.subMenus && item.subMenus.length) {
          temp = [...temp, ...flatList(item.subMenus)];
        } else {
          temp = [...temp, item];
        }
      });
      return temp;
    }

    // console.log('state.subMenus:', state.subMenus)
    state.subMenus = flatList(roles);
    setStorage(PERMISSION_KEY, roles);
  },
  SET_ROLES_PERMISSION: (state, data) => {
    state.authPermission = data;
    setStorage(PERMISSION_ROLES_KEY, data);
  }
};
function sortMenu(routes) {
  routes.sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber));
  routes.forEach(it => {
    if (it.children) {
      sortMenu(it.children);
    }
  });
  return routes;
}

// 扁平路由数组
function flatRoutes(routes) {
  return routes.reduce((acc, cur) => {
    if (cur.isRole) {
      acc.push(cur.path);
      if (cur.subMenus) {
        acc = [...acc, ...flatRoutes(cur.subMenus)];
      }
    }
    return acc;
  }, []);
}
function filterMenu(roles, routes) {
  const allRoutePaths = flatRoutes(roles);
  const doLoop = (roles, routes) => {
    // 菜单过滤
    return routes.filter(route => {
      // 后台菜单权限匹配
      const matchedItem = roles.find(it => route.path === it.path);
      if (matchedItem) {
        route.id = matchedItem.id;
        route.orderNumber = route.orderNumber || matchedItem.orderNumber;
        if (route.redirect) {
          const hasChild = matchedItem.subMenus.find(it => it.path == route.redirect);
          const welcome = route.children.find(it => ~it.path.indexOf('welcome'));
          if (!hasChild && !welcome) {
            const first = matchedItem.subMenus[0];
            route.redirect = first ? first.path : '/dashboard';

            // const minObj = matchedItem.subMenus.reduce((acc, cur) =>
            //   acc.orderNumber < cur.orderNumber ? acc : cur
            // )
            // const redirect = minObj.path

            // route.redirect = redirect
          }
        }
        if (route.children) {
          const noAuthList = route.children.filter(it => (it.meta.auth || it.hidden) && !allRoutePaths.includes(it.path));
          route.children = [...doLoop(matchedItem.subMenus, route.children), ...noAuthList];
        }
        return true;
      }
      return false;
    });
  };
  return doLoop(roles, routes);
}
const pickerOpts = (data, keys) => {
  const res = {};
  data.forEach(cur => {
    if (cur.code && keys.includes(cur.code)) {
      res[cur.code] = cur.isRole;
    }
  });
  return res;
};

// // 扁平本地菜单
// const flatRoutes = (roleMenus, obj = {}) => {
//   return roleMenus.reduce((acc, cur) => {
//     const { children } = cur
//     acc[cur.path] = cur
//     if (children && children.length) {
//       return flatRoutes(children, acc)
//     }
//     return acc
//   }, obj)
// }
// const tempRoutes = flatRoutes(asyncRoutes)

// const filterAsyncRoutes2 = list => {
//   let tmp = []
//   for (let i = 0; i < list.length; i++) {
//     let item = list[i]
//     const { id, name, roleMenuOpts, subMenus, code, path, isShow, isRole } =
//       item
//     const obj = {
//       id,
//       name,
//       roleMenuOpts,
//       code,
//       path,
//       isShow,
//       isRole,
//       ...tempRoutes[path],
//     }
//     // 首页已经添加到默认路由
//     if (path !== '/') {
//       if (!subMenus) {
//         tmp.push(obj)
//       } else {
//         tmp.push({
//           ...obj,
//           children: filterAsyncRoutes2(subMenus),
//         })
//       }
//     }
//   }

//   return tmp
// }

// const findRoute = (routes, path) => {
//   if (!routes.length) return []
//   const findItem = routes.find(it => it.path == path)
//   return findItem
// }

// const filterAsyncRoutes = roleMenus => {
//   const res = []

//   roleMenus.forEach(item => {
//     const { isRole, path, subMenus } = item

//     if (isRole) {
//       const routeItem = findRoute(asyncRoutes, path.trim())

//       if (!routeItem) return

//       // // 二级菜单编号
//       // routeItem.children = routeItem.children.map((route, index) => {
//       //   route.orderNumber = (route.orderNumber || 1) + index + 1
//       //   return route
//       // })

//       let children = []
//       subMenus.forEach(sub => {
//         if (sub.isRole) {
//           routeItem.children.forEach(menu => {
//             if (menu.path == sub.code || menu.hidden || menu.meta.auth) {
//               // if (sub.subMenus.length) {
//               //   sub.subMenus.forEach(val => {
//               //     if (menu.path == val.code || menu.hidden || menu.meta.auth) {
//               //       children.push(menu)
//               //     }
//               //   })
//               // }
//               children.push(menu)
//             }
//           })
//         }
//       })

//       // // 排序子菜单
//       // children.sort((a, b) => {
//       //   return Number(a.orderNumber) - Number(b.orderNumber)
//       // })

//       // 去除重复
//       const map = {}
//       const filterChildren = []
//       children.map(it => {
//         if (!map[it.path]) {
//           map[it.path] = true
//           filterChildren.push(it)
//         }
//       })

//       if (routeItem) {
//         if (children.length) {
//           routeItem.children = filterChildren
//           res.push(routeItem)
//         }
//       }
//     }
//   })

//   return res
// }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};