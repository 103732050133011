import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog, fetchAgreementTypeList } from './index';

// 列表 不分页
export function fetchList(params) {
  // const url = parseQuerys('/resource/shop/findList', queryParams)
  return request({
    url: '/resource/shop/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchShopping(params) {
  // const url = parseQuerys('/resource/shop/page', queryParams)
  return request({
    url: '/resource/shop/page',
    method: 'get',
    params
  });
}

// 列表()
export function fetchShopGroupList(params) {
  return request({
    url: '/resource/shop/group-objects',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateShopping(data) {
  return request({
    url: '/resource/shop/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteShopping(id) {
  return request({
    url: '/resource/shop/del',
    method: 'post',
    data: {
      id
    }
  });
}