var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "batch-approve form-wrapper"
  }, [_c("BillInput", {
    ref: "formRef",
    attrs: {
      obj: _vm.form
    },
    on: {
      change: _vm.changeMoney
    }
  }), _c("SearchUnPay", {
    on: {
      search: _vm.handleSearch
    }
  }), _c("TableUnPay", {
    ref: "unPayRef"
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };