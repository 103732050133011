var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "1280px",
      title: "缴费记录",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      "show-page": true,
      "page-prop": _vm.pageProp,
      border: ""
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "invoice",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.invoiceName))]), _c("div", [_vm._v(_vm._s(scope.row.idCode))]), _c("div", [_vm._v(_vm._s(scope.row.invoiceResEMail))])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [scope.row.invoicePicPath ? [_c("el-popover", {
          staticClass: "tac",
          attrs: {
            placement: "left",
            title: "发票预览",
            width: "500",
            trigger: "click"
          }
        }, [_c("div", {
          staticClass: "tac"
        }, [_c("h2", [_vm._v("发票名称：" + _vm._s(scope.row.invoiceName))]), _vm.isPdfFile ? _c("iframe", {
          attrs: {
            src: _vm.curBillUrl,
            width: "100%"
          }
        }) : _vm._e(), _vm._v(" --> "), _c("el-image", {
          attrs: {
            src: _vm.curBillUrl,
            fit: "contain"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.getBillImagePath(scope.row.invoicePicPath);
            }
          },
          slot: "reference"
        }, [_vm._v(" 查看 ")])], 1), _c("el-button", {
          staticClass: "ml-10",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.downloadFile(scope.row);
            }
          }
        }, [_vm._v(" 下载 ")])] : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };