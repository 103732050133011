var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "targer-staff"
  }, [_c("el-form", {
    staticClass: "form-wrapper",
    attrs: {
      inline: "",
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "年份"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy",
      format: "yyyy",
      label: "",
      type: "year"
    },
    model: {
      value: _vm.search.year,
      callback: function ($$v) {
        _vm.$set(_vm.search, "year", $$v);
      },
      expression: "search.year"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeDepartment
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.search.customerAreaServiceId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "customerAreaServiceId", $$v);
      },
      expression: "search.customerAreaServiceId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "区域经理"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.search.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "customerAreaManagerId", $$v);
      },
      expression: "search.customerAreaManagerId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.search.salesmanId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "salesmanId", $$v);
      },
      expression: "search.salesmanId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.fetchData
    }
  })], 1)], 1), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.columns,
      border: ""
    },
    on: {
      page: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };