// 区域数据分析
export const customerKpiAnalyze = [{
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}];

// 年度
export const TableYearColumn = {
  attrs: {
    prop: 'total',
    label: '年度'
  }
};

// 营业额
export const TableTurnoverColumn = {
  attrs: {
    label: '营业额',
    prop: 'kpiName'
  }
};

// 人数
export const TableCountColumn = {
  attrs: {
    label: '人数',
    prop: 'kpiName'
  }
};

// 毛利润
export const TableProfitColumn = {
  attrs: {
    label: '毛利润',
    prop: 'kpiName'
  }
};
// { attrs: { label: '年度', prop: 'total' } },

// 人均毛利
export const TableAvgProfitColumn = {
  attrs: {
    label: '人均毛利',
    prop: 'kpiName'
  }
};
// { attrs: { label: '年度', prop: 'total' } },