// 全国旅游系统
import md5 from 'blueimp-md5';
import { encode } from 'js-base64';
import { TourAgeCountryConfig } from '@/config';
const VERSION = '1.0.0';
export const signMethod = timestamp => {
  const {
    appId: signKey
  } = TourAgeCountryConfig;
  const signStr = signKey + timestamp;
  const hash = md5(signStr);
  console.log('hash:', hash);
  return encode(hash);
};
export const getRequestHeader = () => {
  const {
    appId
  } = TourAgeCountryConfig;
  const timestamp = new Date().getTime();
  const signKey = signMethod(timestamp);
  return {
    referer: 'http://8.134.64.188/',
    'x-12301-key': appId,
    'x-12301-version': VERSION,
    'x-12301-timestamp': timestamp,
    'x-12301-signature': signKey
  };
};