var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "标题"
    },
    model: {
      value: _vm.title,
      callback: function ($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  }), _c("BtnCreate", {
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.handleCreate
    }
  })], 1)]), _c("div", {
    staticClass: "h10"
  }), _c("FormNotice", {
    ref: "formRef",
    on: {
      "reload:data": _vm.fetchData
    }
  }), _c("TableNotice", {
    attrs: {
      "table-data": _vm.tableList,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading
    },
    on: {
      "reload:data": _vm.fetchData
    }
  }), _c("PreviewNotice", {
    ref: "previewRef",
    attrs: {
      data: _vm.previewData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };