/**
 * 服务标准 内部备注 用房备注 用餐备注 用车备注 景区备注 友情提示 自费项目 备注
 */
export default {
  props: {
    initVal: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      remarks: [{
        value: '',
        text: '服务标准',
        name: 'serviceStandardRemark'
      }, {
        value: '',
        text: '内部备注',
        name: 'innerRemark'
      }, {
        value: '',
        text: '用房备注',
        name: 'hotelRemark'
      }, {
        value: '',
        text: '用餐备注',
        name: 'restaurantRemark'
      }, {
        value: '',
        text: '用车备注',
        name: 'busRemark'
      }, {
        value: '',
        text: '景区备注',
        name: 'scenicRemark'
      }, {
        value: '',
        text: '友情提示',
        name: 'friendlyTips'
      }, {
        value: '',
        text: '备注',
        name: 'remarks'
      }]
    };
  },
  watch: {
    initVal: {
      handler: 'initValue',
      immediate: true
    },
    remarks: {
      handler: function (val) {
        val.forEach(it => {
          this.handleChange(it.name, it.val);
        });
      },
      immediate: true
    }
  },
  methods: {
    initValue(data) {
      // 如果有id
      //   if (!data || data.id) return
      Object.keys(data).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const fIndex = this.findKey(key);
          if (fIndex > -1) {
            this.remarks[fIndex].value = data[key];
          }
        }
      });
    },
    findKey(key) {
      return this.remarks.findIndex(it => it.name == key);
    },
    handleChange(type, val) {
      this.$emit('on-change', type, val);
    }
  }
};