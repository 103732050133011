const width40 = 40;
const width50 = 50;
const width60 = 60;
// const width70 = 70
const width80 = 80;
const width90 = 90;
const width100 = 100;
export const ResourceType = {
  scenic: 'scenic',
  hotel: 'hotel',
  restaurant: 'restaurant',
  fleet: 'fleet',
  shuttle: 'shuttle',
  insurance: 'insurance',
  traffic: 'traffic',
  shops: 'shops',
  reception: 'reception',
  collect: 'collect',
  otherPay: 'other-pay',
  otherIncome: 'other-income'
};
import { customerInfo } from './order';

/***************** 对账 ******************/
// 订单信息
const orderInfo = {
  attrs: {
    prop: 'planNumber',
    label: '订单信息',
    'min-width': 160
  },
  slot: 'planNumber'
};

// 行程日期
const tripDate = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': width90
  },
  slot: 'tripDate'
};

// 订单日期
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '订单日期',
    'min-width': width90
  }
};

// 订单日期
const orderDateSlot = {
  ...orderDate,
  slot: 'orderDate'
};

// 类别
const orderType = {
  attrs: {
    prop: 'type',
    label: '类别',
    'min-width': width90
  },
  slot: 'type'
};

// 单价
const orderPrice = {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width60
  }
};

// 数量
const orderCount = {
  attrs: {
    prop: 'orderCount',
    label: '数量',
    'min-width': width50
  },
  slot: 'orderCount'
};

// 导游
// const orderGuide = { attrs: { prop: 'guide', label: '报账导游', 'min-width': width50 } }

// 报账导游
const orderGuideBill = {
  attrs: {
    prop: 'guideName',
    label: '报账导游',
    'min-width': width90
  }
};

// 导游现付
const orderGuideCash = {
  attrs: {
    prop: 'guidePay',
    label: '导游现付',
    'min-width': width60
  },
  slot: 'guidePay'
};
// const orderGuideCashSlot = { ...orderGuideCash, slot: 'guidePay' }

// 企业码付 TODO:
const enterpriseCode = {
  attrs: {
    prop: 'alipayECodePay',
    label: '企业码付'
  },
  slot: 'alipayECodePay'
};

// 企业码收 TODO:
const enterpriseCodeCollect = {
  attrs: {
    prop: 'alipayECodeCollect',
    label: '企业码收'
  },
  slot: 'alipayECodeCollect'
};

// 人数
const orderPeople = {
  attrs: {
    prop: 'peopleCount',
    label: '人数',
    'min-width': 120
  },
  slot: 'number'
};

// 游客联系人
const orderTouristContact = {
  attrs: {
    prop: 'touristContact',
    label: '游客联系人',
    'min-width': 140
  },
  slot: 'touristContact'
};

// 责任计调
const orderOpMain = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
};

// 操作计调
const orderOpOperation = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调',
    'min-width': width60
  }
};

// 客服计调
const orderOpService = {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
};

// 业务员
const saleUserName = {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
};

// 安排备注
const orderArrangeRemark = {
  attrs: {
    prop: 'remark',
    label: '安排备注'
  }
};

// 应付
const totalSettlement = {
  attrs: {
    prop: 'totalSettlement',
    label: '应付',
    'min-width': width80
  },
  slot: 'totalSettlement'
};

// 账面应付
const orderTotalPay = {
  attrs: {
    prop: 'totalPay',
    label: '账面应付',
    'min-width': width60
  },
  slot: 'totalPay'
};

// 应收
const orderTotalCollect = {
  attrs: {
    prop: 'totalIncome',
    label: '应收',
    'min-width': width60
  },
  slot: 'totalIncome'
};

// 已付
const orderPayed = {
  attrs: {
    prop: 'totalPayed',
    label: '已付',
    'min-width': width60
  },
  slot: 'totalPayed'
};

// 未收
const orderUnCollect = {
  attrs: {
    prop: 'unTotalIncome',
    label: '未收'
  }
};
const orderUnCollectSlot = {
  ...orderUnCollect,
  slot: 'unTotalIncome'
};

// 未付
const orderUnPay = {
  attrs: {
    prop: 'unPay',
    label: '未付',
    'min-width': width60
  },
  slot: 'unPay'
};

// 对账金额
const orderCheckMoney = {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额',
    'min-width': width80
  },
  slot: 'checkAccountMoney'
};

// 对账时间
const orderCheckDate = {
  attrs: {
    prop: 'checkAccountTime',
    label: '对账时间',
    'min-width': 75
  },
  slot: 'checkAccountTime'
};

// 对账人
const orderChecker = {
  attrs: {
    prop: 'checkAccountUserName',
    label: '对账人'
  }
};

// 费用明细
const orderBillDetail = {
  attrs: {
    prop: 'fee',
    label: '费用明细',
    'min-width': 160
  },
  slot: 'fee'
};

// 其他成本
const orderOther = {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width60
  },
  slot: 'otherFee'
};
const cashType = {
  attrs: {
    prop: '$cashType',
    label: '付款方式'
  }
};

// 备注
const orderRemark = {
  attrs: {
    prop: 'checkAccountRemark',
    label: '备注'
  },
  slot: 'remark'
};

// 审核
const auditStatus = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': width80
  },
  slot: 'auditStatus'
};

// 操作
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action',
  header: 'billCheck'
};
// 总计
export const statisticsKeys = ['number', 'orderCount', 'checkAccountMoney', 'totalSettlement', 'peopleCount', 'unTotalIncome', 'unPay', 'guidePay', 'shopRebate', 'guideIncome', 'otherFee', 'totalPayed', 'totalPay', 'totalIncome', 'agencyIncome', 'totalIncomed', 'guideCollect', 'totalSubsidy', 'totalCollect', 'totalHeadRebate', 'totalShopRebate', 'totalHeadRebated', 'totalGuideCollect', 'totalShopRebated', 'totalBuyMoney', 'enterStoreMemberCount', 'totalRedEnvelopeReimbed', 'redEnvelope', 'alipayECodePay', 'alipayPeerPayAmount'];
const firstList = [orderInfo, tripDate, orderPeople, orderGuideBill];
const lastList = [orderOther, orderArrangeRemark, orderTotalPay, orderGuideCash, orderPayed, enterpriseCode, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 景区
export const checkBillScenic = [...firstList, cashType, {
  attrs: {
    prop: 'scenicName',
    label: '景区',
    'min-width': 160
  },
  slot: 'scenicName'
}, orderDate, {
  attrs: {
    prop: 'ticketType',
    label: '门票'
  }
}, {
  attrs: {
    prop: 'sellPrice',
    label: '成人卖价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'price',
    label: '成人单价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'settlementPrice',
    label: '成人结算价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'adultCountItem',
    label: '成人数',
    'min-width': width40
  },
  slot: 'adultCountItem'
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': width50
  },
  slot: 'adultFreeCount'
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童底价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'childCountItem',
    label: '儿童数',
    'min-width': width40
  },
  slot: 'childCountItem'
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪底价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'accompanyCountItem',
    label: '全陪数',
    'min-width': width40
  },
  slot: 'accompanyCountItem'
}, orderArrangeRemark, orderOther, orderTotalPay, totalSettlement, orderGuideCash, enterpriseCode, orderPayed, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 餐厅
export const checkBillRestaurant = [...firstList, cashType, {
  attrs: {
    prop: 'restaurantName',
    label: '餐厅名称'
  }
}, orderDate, {
  attrs: {
    prop: 'restaurantType',
    label: '餐饮类型',
    'min-width': 120
  },
  slot: 'restaurantType'
}, {
  attrs: {
    prop: 'mealType',
    label: '用餐类别'
  }
}, orderPrice, orderCount, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量',
    'min-width': 55
  }
}, ...lastList];

// 酒店
export const checkBillHotel = [orderInfo, tripDate, {
  attrs: {
    prop: 'area',
    label: '区域'
  }
}, orderPeople, cashType, {
  ...orderGuideBill,
  slot: 'guide'
}, {
  attrs: {
    prop: 'hotelName',
    label: '酒店',
    'min-width': width90
  }
}, orderDate, {
  attrs: {
    prop: 'roomInfo',
    label: '房间信息'
  },
  children: [{
    attrs: {
      prop: 'roomType',
      label: '房型',
      'min-width': width50
    }
  }, orderCount, {
    attrs: {
      prop: 'freeCount',
      label: '免',
      'min-width': width40
    },
    slot: 'freeCount'
  }, {
    attrs: {
      prop: 'quotePrice',
      label: '单价',
      'min-width': width50
    }
  }, {
    attrs: {
      prop: 'settlementPrice',
      label: '结算价',
      'min-width': width50
    }
  }, orderOther]
}, orderArrangeRemark, orderOpOperation, orderTotalPay, totalSettlement, orderGuideCash, enterpriseCode, orderPayed, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 车队
export const checkBillFleet = [...firstList, cashType, {
  attrs: {
    prop: 'busCompanyName',
    label: '车队',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '',
    label: '用车时间'
  },
  slot: 'busUseDate'
}, {
  attrs: {
    prop: '',
    label: '车辆信息'
  },
  slot: 'busInfo'
}, {
  attrs: {
    prop: 'busFee',
    label: '金额'
  }
}, ...lastList];

// 接送
export const checkBillShuttle = [orderInfo, tripDate, orderPeople, {
  attrs: {
    prop: 'peopleCount',
    label: '接站人数'
  },
  slot: 'peopleCount'
}, orderGuideBill, cashType, {
  attrs: {
    prop: 'busCompanyName',
    label: '车队',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '',
    label: '用车时间'
  },
  slot: 'busUseDate'
}, {
  attrs: {
    prop: '',
    label: '车辆信息'
  },
  slot: 'busInfo'
}, {
  attrs: {
    prop: 'busFee',
    label: '金额'
  }
}, ...lastList];

// 大交通
export const checkBillTraffic = [...firstList, cashType, {
  attrs: {
    prop: 'transportCompanyName',
    label: '大交通公司',
    'min-width': 200
  }
}, orderDate, orderType, orderPrice, orderCount, orderOther,
// orderTotalPay,
{
  attrs: {
    prop: 'money',
    label: '账面应付'
  }
}, {
  attrs: {
    prop: '$total',
    label: '账面总应付'
  },
  slot: 'totalPay'
}, orderArrangeRemark, orderGuideCash, orderPayed, enterpriseCode, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 保险
export const checkBillInsurance = [...firstList, cashType, {
  attrs: {
    prop: 'insuranceName',
    label: '保险公司',
    'min-width': 160
  }
}, orderDate, {
  attrs: {
    prop: 'type',
    label: '险种'
  },
  slot: 'type'
}, orderPrice, orderCount, ...lastList];

// 地接
export const checkBillReception = [orderInfo, orderPeople, orderTouristContact, {
  attrs: {
    prop: 'customerName',
    label: '同行地接',
    'min-width': 120
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'customerContactVos',
    label: '地接联系人'
  },
  slot: 'customerContactVos'
}, orderOpMain, orderBillDetail, orderTotalPay, {
  attrs: {
    prop: 'totalCollect',
    label: '代收金额'
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  },
  slot: 'totalSubsidy'
}, orderPayed, enterpriseCode, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 应收对账
export const checkBillCollect = [orderInfo, tripDate, orderDateSlot, customerInfo, orderGuideBill, {
  attrs: {
    prop: 'contact',
    label: '联系人',
    'min-width': 120
  },
  slot: 'contact'
}, orderTouristContact, {
  attrs: {
    prop: 'peopleCount',
    label: '人数',
    'min-width': width100
  },
  slot: 'peopleCount'
}, orderBillDetail, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'totalIncomed',
      label: '社收'
    },
    slot: 'totalIncomed'
  }, {
    attrs: {
      prop: 'totalCollect',
      label: '代收'
    },
    slot: 'totalCollect'
  }, {
    attrs: {
      prop: 'totalSubsidy',
      label: '返款'
    },
    slot: 'totalSubsidy'
  }]
}, orderUnCollectSlot, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, orderOpService, saleUserName, orderOpOperation, auditStatus, actionItem];

// 购物
export const checkBillShopping = [orderInfo, tripDate, orderPeople, {
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '购物店'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期'
  }
}, {
  attrs: {
    prop: 'licenceNumber',
    label: '车号'
  }
}, orderGuideBill, {
  attrs: {
    prop: 'totalBuyMoney',
    label: '购物总额',
    'min-width': width80
  },
  slot: 'totalBuyMoney'
}, {
  attrs: {
    prop: '',
    label: '应收'
  },
  children: [{
    attrs: {
      prop: 'totalHeadRebate',
      label: '人头佣金',
      'min-width': width60
    },
    slot: 'totalHeadRebate'
  }, {
    attrs: {
      prop: 'shopRate',
      label: '比例',
      'min-width': width60
    },
    slot: 'shopRate'
  }, {
    attrs: {
      prop: 'shopRebate',
      label: '社佣',
      'min-width': width80
    },
    slot: 'shopRebate'
  }, {
    attrs: {
      prop: 'redEnvelope',
      label: '红包',
      'min-width': width60
    },
    slot: 'redEnvelope'
  }]
}, enterpriseCode, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'totalHeadRebated',
      label: '人头佣金'
    },
    slot: 'totalHeadRebated'
  }, {
    attrs: {
      prop: 'totalGuideCollect',
      label: '导游代收',
      'min-width': width60
    },
    slot: 'totalGuideCollect'
  }, {
    attrs: {
      prop: 'totalShopRebated',
      label: '社佣'
    },
    slot: 'totalShopRebated'
  }, {
    attrs: {
      prop: 'totalRedEnvelopeReimbed',
      label: '红包'
    }
  }]
}, orderUnCollectSlot, {
  attrs: {
    prop: 'shopRemark',
    label: '购物备注'
  },
  slot: 'shopRemark'
}, orderOpOperation, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 购物对账 二返
export const checkBillShoppingAll = [...checkBillShopping.slice(0, -8), {
  attrs: {
    prop: 'subsidy',
    label: '二返'
  }
}, {
  attrs: {
    prop: 'shopRemark',
    label: '购物备注'
  },
  slot: 'shopRemark'
}, orderOpOperation, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];

// 购物备注窗口
export const shopRemark = [{
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'name',
    label: '商品'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

// 其他支出
export const checkBillOtherPay = [orderInfo, cashType, tripDate, orderPeople, {
  attrs: {
    prop: 'otherUnit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '操作日期'
  }
}, orderPrice, orderCount, orderArrangeRemark, orderTotalPay, orderGuideCash, orderPayed, enterpriseCode, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, orderGuideBill, auditStatus, actionItem];

// 其他收入
export const checkBillOtherIncome = [orderInfo, cashType, tripDate, orderPeople, {
  attrs: {
    prop: 'title',
    label: '项目',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '操作日期',
    width: 80
  }
}, orderPrice, orderCount, orderArrangeRemark, orderTotalCollect, {
  attrs: {
    prop: 'guideIncome',
    label: '导游现收'
  },
  slot: 'guideIncome'
}, {
  attrs: {
    prop: 'agencyIncome',
    label: '社收'
  },
  slot: 'agencyIncome'
}, orderUnCollectSlot, enterpriseCodeCollect, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, orderGuideBill, auditStatus, actionItem];

// 弹窗
export const rowItem = [{
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'totalPay',
    label: '金额'
  }
}];