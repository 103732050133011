import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { guideBorrow } from '@/config/report';
import { SelectGuide, BtnExport, TableTotalBox } from '@/components/common';
import TableGuideBorrow from '../components/TableGuideBorrow';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    SelectGuide,
    BtnExport,
    TableGuideBorrow,
    TableTotalBox
  },
  mixins: [searchMixin],
  data: () => ({
    total: 0,
    // sendDate: [today, ''],
    searchParams: {
      startTripDateS: today
    },
    columnData: guideBorrow,
    tableData: [],
    statisticData: [],
    statistic: {},
    tableLoading: false
  }),
  computed: {
    ...mapGetters({
      guideBorrowData: 'report/index/guideBorrowData'
    })
  },
  watch: {
    guideBorrowData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 导游统计
    onSelectGuide(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.guideId = value;
      this.searchParams.guideLabel = label;
    },
    // 计划类型
    onSelectOrderType({
      value
    }) {
      if (value) {
        value = value.toUpperCase();
      }
      this.searchParams.orderType = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      this.searchParams[type + 'S'] = date[0];
      this.searchParams[type + 'E'] = date[1];
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      if (list.length) {
        // 计算欠报和总数
        list.map(it => {
          it.lacOfPay = it.travelAgencyPay - it.travelAgencyPayed;
        });
      }
      this.total = totalCount;
      this.tableData = list;
      // 计算欠报
      statistics.lacOfPay = statistics.travelAgencyPay - statistics.travelAgencyPayed;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    fetchData(opts) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getGuideBorrow', opts).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchGuideBorrowExcel', this.searchParams).then(res => {
        const fileName = '导游借款统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};