import BtnBar from '../components/BtnBar';
import TotalBar from '../components/TotalBar';
import { DialogLog, SelectGuide, SelectGroup } from '@/components/common';
import { PersonCount } from '@/components/common';
import { GroupCheckBill as columns } from '@/config/group';
import { TYPES } from '@/config/audit';
import { reloadMixin, tagViewMixin, billMixin, rowspanMixin, searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
import billBaseMixin from '../billBaseMixin';
import { processAdd } from '@/utils/number';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    SelectGuide,
    PersonCount,
    DialogLog,
    SelectGroup,
    BtnBar,
    TotalBar
  },
  mixins: [reloadMixin, tagViewMixin, rowspanMixin, billMixin, billBaseMixin, searchMixin],
  data() {
    return {
      columns,
      calcCheckedMoney,
      tableData: [],
      billCheckAll: false,
      searchParams: {},
      sendDate: [parseDate()],
      orderDate: [],
      statistics: null,
      headMergeNum: 8,
      tailMergeNum: columns.length - 7,
      hasMergeTail: true
    };
  },
  computed: {
    data() {
      return this.$store.getters['group/billCheckData'];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    getSum() {
      return item => processAdd(item.money, item.otherFee);
    }
  },
  watch: {
    data: {
      handler: function (d) {
        !d ? this.fetchData({
          startTripDateS: parseDate()
        }) : this.handleData(d);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('group/fetchBillCheck', params);
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    handlePage(param) {
      this.fetchData({
        ...this.searchParams,
        ...param
      });
    },
    handleData(data) {
      this.tableData = this.formatData(data.list);
    },
    formatData(data) {
      return data.map(it => {
        this.$set(it, 'ckStatus', false);
        it.checkAccountMoney = it.ckMoney;
        return it;
      });
    },
    changeStarTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeOrderDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.orderDateS = starDate;
      this.searchParams.orderDateE = endDate;
    },
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    selectOtherType(type, val) {
      this.searchParams[type] = val ? val.value : '';
    },
    // 格式对账参数
    formatBillCheckOptions({
      row,
      type,
      status
    }) {
      const opts = {
        auditTypeEnum: TYPES[type],
        checkAccountRemark: row.ckRemarks,
        checkAccountMoney: row.ckMoney,
        resourceId: row.id,
        status
      };
      return opts;
    },
    // 单条对账
    handleCheckout(row) {
      const opts = this.formatBillCheckOptions({
        row,
        type: row.resType,
        status: !row.checkAccountStatus
      });
      this.updateBillCheck([opts]).then(() => {
        this.fetchData(this.searchParams);
      });
    },
    // 审核所有选中
    handleBillCheck() {
      const selectList = this.tableData.filter(it => it.ckStatus);
      if (!selectList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何对账数据'
        });
        return;
      }
      const dataList = selectList.map(it => {
        return this.formatBillCheckOptions({
          row: it,
          type: it.resType,
          status: !it.checkAccountStatus
        });
      });
      this.updateBillCheck(dataList).then(() => {
        this.fetchData(this.searchParams);
      });
    },
    handleLog(row) {
      const {
        rowId: subItemId,
        planId: itemId
      } = row;
      const param = {
        subItemId,
        itemId,
        subType: row.type
      };
      this.$store.dispatch('bill/billCheck/fetchPlanLog', param).then(data => {
        this.$refs.dialogRef.show(data);
      });
    },
    closeTag() {
      this.closeSelectedTag();
    }
  }
};