import { tableMixin, dictionaryMixin, summaryMixin } from '@/mixin';
import { PersonCount, ShuttleType } from '@/components/common';
import { CustomerInfo } from '@/components/common';
export default {
  components: {
    PersonCount,
    ShuttleType,
    CustomerInfo
  },
  mixins: [tableMixin, dictionaryMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['number']
    };
  },
  computed: {
    getDetail() {
      return row => {
        const {
          taskType,
          shiftNumber
        } = row;
        let {
          desPosition
        } = row;
        desPosition = isNaN(desPosition) ? desPosition : this.getStationType(desPosition);

        // 火车
        if (taskType == 'TRAIN') {
          return `${desPosition} 车次 ${shiftNumber}`;
        }
        // 飞机
        if (taskType == 'PLANE') {
          return `${desPosition} 班次 ${shiftNumber}`;
        }
      };
    },
    getDateTime() {
      return row => {
        const {
          taskType,
          shuttleType
        } = row;
        let {
          shiftDateTime
        } = row;
        shiftDateTime = shiftDateTime || '';
        // 火车
        if (taskType == 'TRAIN') {
          const receiveVal = shuttleType == 'PICK' ? `抵达时间 ${shiftDateTime}` : `发车时间 ${shiftDateTime}`;
          return receiveVal;
        }
        // 飞机
        if (taskType == 'PLANE') {
          const receiveVal = shuttleType == 'PICK' ? `抵达时间 ${shiftDateTime}` : `起飞时间 ${shiftDateTime}`;
          return receiveVal;
        }
      };
    }
  },
  methods: {
    handleConfirm(row) {
      this.$parent.showDialogOrder(row);
    },
    handleFee({
      row
    }) {
      this.$parent.handleFee(row);
    }
  }
};