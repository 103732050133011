var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-date-picker", _vm._g(_vm._b({
    attrs: {
      type: "daterange",
      align: "right",
      "unlink-panels": "",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "picker-options": _vm.showOptions ? _vm.pickerOptions : {}
    }
  }, "el-date-picker", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };