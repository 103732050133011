var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curItem ? _c("div", {
    staticClass: "form-fix-bar"
  }, [_c("h3", [_vm._v("基础信息")]), _c("BillInput", {
    ref: "formRef",
    attrs: {
      "pay-text": "预收款",
      "resource-id": _vm.form.resourceId,
      obj: _vm.form,
      "sub-type": _vm.CashFeeType.INCOME,
      "cash-type": _vm.TYPES.customer
    },
    on: {
      change: _vm.changeMoney
    }
  }, [_c("ResourceItem", {
    attrs: {
      form: _vm.form,
      disabled: false
    }
  })], 1), _vm.form.money > 0 ? [_c("h3", [_vm._v("未核销")]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", [_vm._v(" " + _vm._s(scope.row.plan.planNumber) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.plan.startPlanDate))]), _c("div", [_vm._v(_vm._s(scope.row.plan.endPlanDate))])];
      }
    }, {
      key: "guidesBill",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.plan.guideName) + "22")])];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.plan.customerName))])];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row.plan
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAuditProgress", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "downPayed",
      fn: function ({
        scope
      }) {
        return [_c("InputNum", {
          on: {
            change: function ($event) {
              return _vm.changeDownPay(scope.row, $event);
            }
          },
          model: {
            value: scope.row.downPayed,
            callback: function ($$v) {
              _vm.$set(scope.row, "downPayed", $$v);
            },
            expression: "scope.row.downPayed"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-checkbox", {
          on: {
            change: function ($event) {
              return _vm.changeCheckbox(scope.row, $event);
            }
          },
          model: {
            value: scope.row.isChecked,
            callback: function ($$v) {
              _vm.$set(scope.row, "isChecked", $$v);
            },
            expression: "scope.row.isChecked"
          }
        })];
      }
    }], null, false, 2143736116)
  })] : _vm._e(), _c("h3", [_vm._v("已核销")]), _c("TableBase", {
    attrs: {
      data: _vm.completeData,
      "column-data": _vm.columnsComplete,
      sticky: false,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("div", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "status",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(_vm.getStatus(scope.row.cashierAuditStatus)))])];
      }
    }, {
      key: "actionHead",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }], null, false, 3287296199)
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };