import FormItem from './FormItem.vue';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      visible: false,
      item: {}
    };
  },
  methods: {
    show(row) {
      this.visible = true;
      this.item = {
        ...row,
        money: row.cash
      };
    },
    hide() {
      this.visible = false;
      this.item = {};
    },
    reload() {
      this.$parent.fetchData();
    }
  }
};