import { defaultForm } from '../config';
import { formatTime } from '@/utils';
export default {
  data() {
    return {
      visible: false,
      form: defaultForm(),
      date: []
    };
  },
  computed: {
    getInquireDate() {
      const {
        createTime,
        updateTime
      } = this.form;
      const time = updateTime || createTime;
      return time ? formatTime(new Date(time)) : '';
    },
    getQuotationDate() {
      const {
        quotationTime
      } = this.form;
      if (quotationTime) {
        return formatTime(new Date(quotationTime));
      }
      return '';
    }
  },
  methods: {
    show(params) {
      this.visible = true;
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    processData(val) {
      const [arriveDate, leaveDate] = val || ['', ''];
      this.date = [arriveDate, leaveDate];
    },
    fetchData(params) {
      this.$store.dispatch('inquire/bus/detail', params).then(data => {
        this.form = {
          ...this.form,
          ...data
        };
        this.processData([data.arriveDate, data.leaveDate]);
      });
    }
  }
};