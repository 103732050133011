export const SalesmanProfitKeys = ['allTurnover', 'allYearTurnover', 'turnover', 'tagTurnover', 'yearTurnover', 'tagYearTurnover', 'allProfit', 'allYearProfit', 'profit', 'tagProfit', 'yearProfit', 'tagYearProfit', 'allCount', 'allYearCount', 'count', 'tagCount', 'yearCount', 'tagYearCount'];
export const TurnoverPieKeys = {
  reality: {
    key: 'turnover',
    name: '已完成营业额'
  },
  target: {
    key: 'tagTurnover',
    name: '未完成营业额'
  }
};
export const ProfitPieKeys = {
  reality: {
    key: 'profit',
    name: '已完成毛利'
  },
  target: {
    key: 'tagProfit',
    name: '未完成毛利'
  }
};
export const CountPieKeys = {
  reality: {
    key: 'count',
    name: '已完成收客数'
  },
  target: {
    key: 'tagCount',
    name: '未完成收客数'
  }
};
export const TurnoverPieYearKeys = {
  reality: {
    key: 'yearTurnover',
    name: '已完成营业额'
  },
  target: {
    key: 'tagYearTurnover',
    name: '未完成营业额'
  }
};
export const ProfitPieYearKeys = {
  reality: {
    key: 'yearProfit',
    name: '已完成毛利'
  },
  target: {
    key: 'tagYearProfit',
    name: '未完成毛利'
  }
};
export const CountPieYearKeys = {
  reality: {
    key: 'yearCount',
    name: '已完成收客数'
  },
  target: {
    key: 'tagYearCount',
    name: '未完成收客数'
  }
};
export const TurnoverObjKeys = {
  tagTotal: 'tagTurnover',
  all: 'turnover',
  total: 'turnover'
};
export const ProfitObjKeys = {
  tagTotal: 'tagProfit',
  all: 'profit',
  total: 'profit'
};
export const CountObjKeys = {
  tagTotal: 'tagCount',
  all: 'count',
  total: 'count'
};
export const TurnoverObjYearKeys = {
  tagTotal: 'tagYearTurnover',
  all: 'yearTurnover',
  total: 'yearTurnover'
};
export const ProfitObjYearKeys = {
  tagTotal: 'tagYearProfit',
  all: 'yearProfit',
  total: 'yearProfit'
};
export const CountObjYearKeys = {
  tagTotal: 'tagYearCount',
  all: 'yearCount',
  total: 'yearCount'
};
export const PieConfig = {
  turnover: {
    title: '营业额',
    suffix: '元'
  },
  profit: {
    title: '毛利',
    suffix: '元'
  },
  count: {
    title: '人数',
    suffix: '人'
  }
};