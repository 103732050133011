export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columnData: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isAudit: {
      type: Boolean,
      default: false
    },
    showTopBar: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPlanId() {
      return this.$store.getters['plan/index/currentPlanId'];
    }
  },
  methods: {
    reloadData() {
      const id = this.currentPlanId;
      this.fetchData();
      this.$store.dispatch('plan/index/groupProfit', id);
    }
  }
};