import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 非组长列表  不分页
export function fetchLeader(params) {
  return request({
    url: '/resource/resourceGuideTeam/findList',
    method: 'get',
    params
  });
}

// 组长列表列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceGuideTeam/findTeamList',
    method: 'get',
    params
  });
}

// 组长列表列表
export function fetchGuideTeam(params) {
  return request({
    url: '/resource/resourceGuideTeam/page',
    method: 'get',
    params
  });
}
// 导游组详细信息
export function fetchTeamDetail(params) {
  return request({
    url: '/resource/resourceGuideTeam/getById',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateGuideTeam(data) {
  return request({
    url: '/resource/resourceGuideTeam/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteGuideTeam(id) {
  return request({
    url: '/resource/resourceGuideTeam/del',
    method: 'post',
    data: {
      guideTeamId: id
    }
  });
}

// 删除导游组下的导游
export function removeGroupGuide(data) {
  return request({
    url: '/resource/guide/saveOrUpdateDel',
    method: 'post',
    data
  });
}