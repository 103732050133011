var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.fetchData
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.areaServiceId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "areaServiceId", $$v);
      },
      expression: "searchParams.areaServiceId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("startTripDate", $event);
      }
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "export"
    },
    on: {
      click: _vm.handleExport
    }
  }, [_vm._v(" 导出 ")])], 1)], 2), _c("div", {
    attrs: {
      id: "exportTable"
    }
  }, [_c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.columns,
      total: _vm.total,
      "statistics-keys": _vm.statisticsKeys,
      "no-page": ""
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };