import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import XLSX from 'xlsx/xlsx.mini';
import XLSXStyle from 'xlsx-style';
import FileSaver from 'file-saver';

/**
 *
 * @param elt
 * @param sheetName 文件名
 * @param fileName 导出的文件名
 * @param titleNum  标题行数
 */
export const exportTable = (elt, sheetName, fileName, titleNum = 1, callback) => {
  let wb = XLSX.utils.table_to_book(elt, {
    sheet: sheetName,
    raw: true
  });
  let range = XLSX.utils.decode_range(wb.Sheets[sheetName]['!ref']);
  // 单元格边框样式
  let borderStyle = {
    top: {
      style: 'thin',
      color: {
        rgb: '666666'
      }
    },
    bottom: {
      style: 'thin',
      color: {
        rgb: '666666'
      }
    },
    left: {
      style: 'thin',
      color: {
        rgb: '666666'
      }
    },
    right: {
      style: 'thin',
      color: {
        rgb: '666666'
      }
    }
  };
  let cWidth = [];
  for (let C = range.s.c; C <= range.e.c; ++C) {
    // SHEET列
    let len = 100; // 默认列宽
    let len_max = 400; // 最大列宽

    for (let R = range.s.r; R <= range.e.r; ++R) {
      // SHEET行
      let cell = {
        c: C,
        r: R
      }; // 二维 列行确定一个单元格
      let cell_ref = XLSX.utils.encode_cell(cell); // 单元格 A1、A2
      const cur = wb.Sheets[sheetName][cell_ref];
      if (cur) {
        cur.s = {
          alignment: {
            horizontal: 'left',
            vertical: 'center'
          },
          border: borderStyle // 用上面定义好的边框样式
        };
        if (R < titleNum) {
          cur.s = {
            ...cur.s,
            // 设置第一行单元格的样式 style
            font: {
              sz: 13,
              color: {
                rgb: '060B0E'
              },
              bold: true
            },
            fill: {
              fgColor: {
                rgb: 'E4E4E4'
              }
            }
          };
        }

        // 动态自适应：计算列宽
        let va = JSON.parse(JSON.stringify(cur.v));
        var card1 = JSON.parse(JSON.stringify(va)).match(/[\u4e00-\u9fa5]/g); // 匹配中文
        var card11 = '';
        if (card1) {
          card11 = card1.join('');
        }
        var card2 = JSON.parse(JSON.stringify(va)).replace(/([^\u0000-\u00FF])/g,
        //  eslint-disable-line
        ''); // 剔除中文
        let st = 0;
        if (card11) {
          //  st += card11.length * 16  // 中文字节码长度
          st += card11.length * 20; // 中文字节码长度
        }
        if (card2) {
          //  st += card2.length * 8  // 非中文字节码长度
          st += card2.length * 10; // 非中文字节码长度
        }
        if (st > len) {
          len = st;
        }
      }
    }
    if (len > len_max) {
      // 最大宽度
      len = len_max;
    }
    cWidth.push({
      wpx: len
    }); // 列宽
  }
  if (callback) {
    callback(XLSX, wb, sheetName, range);
  }

  // setColorStyle(wb, sheetName, range)
  wb.Sheets[sheetName]['!cols'] = cWidth;
  var wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  };
  var wbout = XLSXStyle.write(wb, wopts); // 一定要用XLSXStyle不要用XLSX，XLSX是没有格式的！
  FileSaver(new Blob([s2ab(wbout)], {
    type: ''
  }), fileName + '.xlsx');
};
export const s2ab = s => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};
export const exportTableByXlsxStyle = ({
  data,
  fileName = '默认下载文件名称',
  sheetName = '默认名称'
}) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  //  在单元格对象中添加 `s` 属性来设置该单元格的样式
  ws['A1'].s = {
    font: {
      bold: true
    },
    alignment: {
      horizontal: 'center'
    },
    fill: {
      fgColor: {
        rgb: 'FFFF0000'
      }
    }
  };
  ws['B2'].s = {
    alignment: {
      horizontal: 'center'
    },
    font: {
      bold: true
    },
    fill: {
      fgColor: {
        rgb: 'FFFF0000'
      }
    }
  };
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, fileName);
};

// function setColorStyle(wb, sheetName, range) {
//   const nums = []
//   for (let C = range.s.c; C <= range.e.c; ++C) {
//     for (let R = range.s.r; R <= range.e.r; ++R) {
//       // SHEET行
//       let cell = { c: C, r: R } // 二维 列行确定一个单元格
//       let cell_ref = XLSX.utils.encode_cell(cell) // 单元格 A1、A2
//       const cur = wb.Sheets[sheetName][cell_ref]

//       if (cur.v && !isNaN(cur.v) && cur.v < 7) {
//         nums.push(R)
//       }

//       if (nums.includes(R)) {
//         cur.s = {
//           font: {
//             sz: 12,
//             color: { rgb: 'ff0000' },
//           },
//           fill: {
//             fgColor: { rgb: 'ffff00' },
//           },
//         }
//       }
//     }
//   }
// }