import "core-js/modules/es.array.push.js";
import { StatusAudit } from '@/components/common';
import { PersonCount, Custom, Shuttle } from '@/components/common';
import { resourcePayTypeLabel } from '@/config/resourceOrder';
import { planScenicDetail, planScenicDetailAll } from '@/config/plan';
import { rowspanMixin, selectorMixin, auditMixin, permissionMixin } from '@/mixin';
export default {
  components: {
    PersonCount,
    Custom,
    Shuttle,
    StatusAudit
  },
  mixins: [rowspanMixin, selectorMixin, auditMixin, permissionMixin],
  data() {
    return {
      tableData: [],
      columnData: planScenicDetail,
      headMergeNum: 0,
      tailMergeNum: 16,
      hasMergeTail: true,
      planId: '',
      scenicId: '',
      planScenicId: ''
    };
  },
  computed: {
    showPrice() {
      return this.hasPermission('scenicFloorPrice');
    }
  },
  mounted() {
    this.hasPermission('scenicFloorPrice') ? this.columnData = planScenicDetailAll : null;
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('plan/scenic/fetch', {
        planId: id
      }).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data.list);
        this.rowspan();
      });
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderScenicTickets;
        delete it.reqOrderScenicTickets;
        const item = {
          ...it,
          rowId: it.id,
          scenicPlanId: it.id,
          isChecked: false,
          isCustom: false,
          isAudit: this.hasAudit(it.auditProgress),
          payType: it.payType ? resourcePayTypeLabel[it.payType.toLowerCase()] : '',
          scenicId: it.scenicId,
          totalPay: it.totalPay,
          remark: it.remark,
          planId: it.planId,
          orderDate: it.orderDate
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }
        // 同步id
        this.rowId++;
      });
      return result;
    }
  }
};