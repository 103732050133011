var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item plan-border-table"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumber", {
          attrs: {
            id: scope.row.hotelId,
            "use-type": scope.row.useType,
            "order-date": scope.row.orderDate,
            type: "hotel"
          }
        })];
      }
    }, {
      key: "hotelStandard",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatStandard(scope.row)) + " ")];
      }
    }, {
      key: "hotelName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.hotelName) + " ")];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orderDate) + " ")];
      }
    }, {
      key: "times",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.times) + " ")];
      }
    }, {
      key: "roomInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.roomType) + " ")];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.quotePrice) + " ")];
      }
    }, {
      key: "settlementPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.settlementPrice) + " ")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.count) + " ")];
      }
    }, {
      key: "freeCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.freeCount) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.otherFee) + " ")];
      }
    }, {
      key: "rowTotal",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.rowTotal) + " ")];
      }
    }, {
      key: "payType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.cashType) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };