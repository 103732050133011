import { TableOrder } from '@/components/common';
import { planEditSelect } from '@/config/plan';
export default {
  components: {
    TableOrder
  },
  data() {
    return {
      columnData: planEditSelect,
      tableData: [],
      visible: false,
      planId: '',
      trafficId: ''
    };
  },
  mounted() {
    this.$bus.on('traffic:order', this.show);
  },
  methods: {
    show(params) {
      this.visible = true;
      let {
        planId,
        planTransportCompanyId
      } = params;
      this.fetchData(params);
      this.planId = Number(planId);
      this.trafficId = Number(planTransportCompanyId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/traffic/fetchTrafficOrderList', params).then(data => {
        this.tableData = data;
      });
    },
    handleConfirm() {
      const longs = this.tableData.map(it => {
        if (it.isSel) {
          return it.id;
        }
      });
      let filterList = {
        longs,
        planTransportCompanyId: this.trafficId
      };
      this.$store.dispatch('plan/traffic/selectTrafficOrderList', filterList).then(() => {
        this.hide();
        this.$bus.tip({
          message: '操作成功'
        });
        this.$bus.emit('traffic:reload');
      });
    }
  }
};