import TableContact from './TableContact';
import { resourceCustomContactDetail } from './table';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    TableContact,
    SwitchStatus
  },
  data() {
    return {
      columnContact: resourceCustomContactDetail,
      form: {},
      tableContact: [],
      visible: false
    };
  },
  computed: {
    getTitle() {
      return '查看客户信息';
    }
  },
  methods: {
    show(data) {
      this.form = data;
      this.tableContact = data.contacts;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    }
  }
};