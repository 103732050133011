import "core-js/modules/es.array.push.js";
import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditFleetDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';

// const itemListKeys = () => ({
//   count: 0,
//   endPlace: '',
//   frequency: '',
//   id: '',
//   isDelete: false,
//   money: 0,
//   otherFee: 120,
//   planTransportOId: '',
//   price: 120,
//   startPlace: '',
//   transportType: 'plane',
// })

export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditFleetDetail,
      planId: '',
      trafficId: ''
    };
  },
  mounted() {
    this.$bus.on('traffic:list', this.show);
    this.$bus.on('traffic:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('traffic:order', {
        planId: this.planId,
        planTransportCompanyId: this.trafficId
      });
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: trafficId
      } = params;
      this.visible = true;
      this.trafficId = trafficId;
      this.planId = planId;
      this.fetchData(trafficId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(trafficId) {
      this.$store.dispatch('plan/traffic/fetchOrder', {
        planTransportCompanyId: trafficId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = this.formatData(list);
      });
    },
    reloadData() {
      this.fetchData(this.trafficId);
    },
    // 移除
    handleRemove(id) {
      this.$bus.open(() => {
        this.$store.dispatch('plan/traffic/deleteOrder', id).then(() => {
          this.$bus.tip();
          this.fetchData(this.trafficId);
        });
      }, `是否确定删除此条团款？`);
    },
    // 修改人数
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
      item.totalPay = this.calcRowTotalMoney(item);
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      // const { price, count, otherFee } = obj
      let price = obj.price || 0;
      let count = obj.count || 0;
      let otherFee = obj.otherFee || 0;
      let total = price * count + Number(otherFee);
      return total;
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.transportCompanyOTypes || [];
        const item = {
          rowId: it.id,
          planTransportId: it.id,
          isChecked: false,
          isCustom: false,
          customerId: it.customerId,
          customerName: it.customerName,
          customerOrderId: it.customerOrderId,
          members: it.members,
          planCustomerNumber: it.planCustomerNumber,
          adultCount: it.adultCount,
          childCount: it.childCount,
          accompanyCount: it.accompanyCount,
          totalPay: 0
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      result.forEach(items => {
        items.totalPay = this.calcRowTotalMoney(items);
      });
      return result;
    },
    // 保存
    handleSave() {
      this.$store.dispatch('plan/traffic/updateOrder', this.tableData).then(() => {
        this.fetchData(this.trafficId);
      });
    }
  }
};