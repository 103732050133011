export const hasCustomeAuth = store => {
  const data = store.getters['system/role/roleCustomerInfoAuth'];
  return data && data.showCustomerInfo;
};

// 过滤有客户的Column数组
export const filterCustomColumn = columns => {
  return columns.filter(it => {
    return !it.attrs.label.includes('客户');
  });
};