import "core-js/modules/es.array.reduce.js";
// const auditRadio = [
//   { name: '待审核', code: '' },
//   { name: '计调已审', code: '' },
//   { name: '财务已审', code: '' },
// ]

// export const systemConfig = [
//   {
//     name: '订单管理',
//     code: 'order',
//     children: [
//       {
//         name: '联系人重复时可以录单',
//         code: 'order',
//       },
//       {
//         name: '输单团号自动生成模式',
//         code: 'order',
//         children: [
//           {
//             name: '默认模式(例：JLB-20170602-T-001)',
//             value: true,
//           },
//           {
//             name: '年月模式(例：201706-T-001)',
//             value: false,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: '计调安排',
//     code: 'plan',
//     children: [
//       {
//         name: '发团预定安排可以安排购物',
//         code: '',
//       },
//       {
//         name: '团款代收自动选择第一个导游',
//         code: '',
//       },
//       {
//         name: '导游出团表显示所有酒店安排',
//         code: '',
//       },
//       {
//         name: '安装上下团时间将挂帐/公司转账/网付订单显示到导游出团表',
//         code: '',
//       },
//       {
//         name: '启用授信额度',
//         code: '',
//       },
//     ],
//   },
//   {
//     name: '单团审核',
//     code: 'audit',
//     children: [
//       { name: '单团审核可以安排购物', code: '' },
//       { name: '单团审核可以审核购物', code: '' },
//       { name: '未对账可以下账', code: '' },
//       { name: '下账后可以修改对应安排', code: '' },
//       { name: '单团审核需要计调审核', code: '' },
//       {
//         name:
//           '团状态审核后，拥有对应更高单条审核权限才可以编辑，相等或更低权限不能编辑',
//         code: '',
//       },
//       {
//         name: '系统佣金是否四舍五入到整数',
//         code: '',
//         isSingle: false,
//         children: [
//           { name: '景区', code: '' },
//           { name: '购物', code: '' },
//         ],
//       },
//       { name: '单条记录审核到哪一步可以对账', code: '', children: auditRadio },
//       { name: '单条记录审核到哪一步可以收款', code: '', children: auditRadio },
//       { name: '单条记录审核到哪一步可以付款', code: '', children: auditRadio },
//       {
//         name: '单条记录审核到哪一步可以核销收入',
//         code: '',
//         children: auditRadio,
//       },
//       {
//         name: '单条记录审核到哪一步可以核销支出',
//         code: '',
//         children: auditRadio,
//       },
//       {
//         name: '单条审核审核到哪一步可以给导游报账',
//         code: '',
//         children: auditRadio,
//       },
//       {
//         name: '单条记录审核到哪一步可以付景区',
//         code: '',
//         children: auditRadio,
//       },
//     ],
//   },
//   {
//     name: '资源管理',
//     code: 'resource',
//     children: [
//       { name: '当客户的剩余额度小于或等于0时，不允许再上单', code: '' },
//       { name: '限制电话号位数', code: '' },
//     ],
//   },
// ]

const ORDER = ['order:autoNumber:tripNumberMode',
// 输单团号自动生成模式
'order:input:tripNumberMode1',
// 输单团号自动生成模式
'order:repetition:contact',
// 联系人重复时可以录单
'order:enable:credit' //  启用授信额度
];
const PLAN = ['plan:pay-type:guideTable',
// 安装上下团时间将挂帐/公司转账/网付订单显示到导游出团表
'plan:team-fund:guideFirst',
// 团款代收自动选择第一个导游
'plan:guide-table:hotelAll',
// 导游出团表显示所有酒店安排
'plan:arrange:shopping' // 发团预定安排可以安排购物
];
const RESOURCE = ['resource:credits:allow',
//  当客户的剩余额度小于或等于0时不允许再上单
'resource:phone:limit' // 限制电话号位数
];
const AUDIT = ['audit:proxy:log',
//  代收审核是否生成现金日志流
'audit:shopping:enable',
// 单团审核可以审核购物
'audit:bill-down-after:arrange',
// 下账后可以修改对应安排
'audit:un-bill-check:billDown',
// 未对账可以下账
'audit:single-audit:needOpAudit',
// 单团审核需要计调审核
'audit:edit:permission',
//  团状态审核后，拥有对应更高单条审核权限才可以编辑，相等或更低权限不能编辑
'audit:single-audit:shopping',
//  单团审核可以审核购物
'audit:commission:round',
// 系统佣金是否四舍五入到整数 (景区、购物)
'audit:audit-step:billCheck',
// 单条记录审核到哪一步可以对账
'audit:audit-step:billDown',
// 单条记录审核到哪一步可以核销收入
'audit:audit-step:collect',
// 单条记录审核到哪一步可以收款
'audit:audit-step:scenicPay',
// 单条记录审核到哪一步可以付景区
'audit:audit-step:checkPay',
// 单条记录审核到哪一步可以核销支出
'audit:audit-step:guide',
// 单条审核审核到哪一步可以给导游报账
'audit:audit-step:pay',
// 单条记录审核到哪一步可以付款
'audit:team-fund:unAudit' //  团款不需要审核
];
const generateConfig = list => {
  return list.reduce((acc, cur) => {
    const [first,, name] = cur.split(':');
    const humpName = first.substring(0, 1).toUpperCase() + first.substring(1);
    acc[name + humpName] = cur;
    return acc;
  }, {});
};

// 状态对应的值
export const systemStateMap = {
  credit: 'order:order2:order-2-0',
  needOPAudit: 'audit:audit4:audit4-3',
  ...generateConfig(ORDER),
  ...generateConfig(PLAN),
  ...generateConfig(RESOURCE),
  ...generateConfig(AUDIT)
};
export const ORDER_TEAM_FEE_CONFIRM_KEY = 'Order-Team-Fee-Confirm'; // 团款合理才能确认成团
export const SHUTTLE_DEFAULT_COST_KEY = 'Shuttle-Default-Cost'; // 接送默认增加成本
export const COUNTRY_ETRAVEL_PLANT_KEY = 'Country-ETravel-Plant'; // 全国旅监平台key
export const ENTERPRISE_LINK_KEY = 'ENTERPRISE_LINK_KEY'; // 企业码链接