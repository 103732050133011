import { beforeWeek } from '@/utils';
import { SelectStaff } from '@/components/common';
export default {
  components: {
    SelectStaff
  },
  data() {
    const date = beforeWeek();
    return {
      sendDate: [date],
      searchParams: {
        startTripDateS: date
      },
      department: []
    };
  },
  methods: {
    changeDate(val) {
      const [start, end] = val;
      this.searchParams.startTripDateS = start;
      this.searchParams.startTripDateE = end;
    }
  }
};