var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-refund"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on:search": _vm.fetchData
    }
  }), _c("TableNew", {
    attrs: {
      id: "ReportRefundTable",
      data: _vm.tableData,
      columns: _vm.columns,
      total: _vm.total,
      statistics: _vm.totalData,
      "statistics-keys": _vm.statisticsKeys
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        row
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLink(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.customerName) + " ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };