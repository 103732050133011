var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-resource-plan print-page"
  }, [_c("FormDetail", {
    attrs: {
      form: _vm.data
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };