const width60 = 60;
const width80 = 80;
export const Columns = [{
  attrs: {
    prop: 'planNumber',
    label: '订单信息',
    'min-width': 160
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'productName',
    label: '产品线路'
  }
}, {
  attrs: {
    prop: 'customer.name',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'customer.customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'fee',
    label: '费用明细'
  },
  slot: 'fee'
}, {
  attrs: {
    prop: 'total',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'totaled',
    label: '已付'
  }
}, {
  attrs: {
    prop: 'unTotal',
    label: '欠付'
  }
}, {
  attrs: {
    prop: '$checkAccountTime',
    label: '对账时间',
    'min-width': 140
  }
}, {
  attrs: {
    prop: 'checkAccountUserName',
    label: '对账人'
  }
}, {
  attrs: {
    prop: 'checkAccountRemark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调',
    'min-width': width60
  }
}, {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': width80
  },
  slot: 'auditStatus'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
}];