/* eslint-disable no-empty-pattern */
import { DDLogin, login, logout, fetchUser, updateUser, fetchUserInfo, updatePwd, getSmsCode, loginSms, getVerifyCode, getWxLogin, bindWechat, unbindWechat, updateVersionSwitch, getGroupUserInSaasRequest, setGroupRoleInSaasRequest } from '@/api/user';
import { getToken, setToken, removeToken, setUserData, getUserData, delUserData, delTravelData } from '@/utils/cookie';
import { setTableColumnKey } from '@/utils/storage';
import { resetRouter } from '@/router';
import { SMS, LOGIN, WX } from '@/config';
import { setStorage } from '@/utils/storage';
import Keys from '@/constants/keys';
const pickMap = ['id', 'isInitPWD', 'theme', 'groupId', 'roleId', 'audit', 'scope', 'userName', 'realName', 'createUserId', 'travelAgencyId', 'departmentId', 'subDepartmentId', 'grandsonDepartmentId', 'superpowers', 'mobilePhone'];
const dfaultState = () => ({
  token: getToken(),
  userName: 'test',
  userData: null,
  userInfo: null,
  avatar: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
  introduction: '',
  roles: [],
  userId: '',
  groupUser: null
});
const state = dfaultState();
const getters = {
  token: state => state.token,
  avatar: state => state.avatar,
  userInfo: state => state.userInfo,
  name: state => state.userName,
  introduction: state => state.introduction,
  roles: state => state.roles,
  groupUser: state => state.groupUser,
  userId: state => state.userId,
  userData: state => state.userData || (state.userData = getUserData()),
  hasUserAudit: state => {
    return state.userData && state.userData.audit > 0;
  }
};
const actions = {
  // 钉钉登陆
  DDLogin({
    dispatch
  }, {
    code,
    travelAgencyId,
    phone
  }) {
    return new Promise((resolve, reject) => {
      DDLogin({
        code,
        travelAgencyId,
        phone
      }).then(res => {
        dispatch('processLogin', res);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  // 刷新校验码
  refreshCode({}, opts) {
    return getVerifyCode(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取验证码
  getSmsCode({}, opts) {
    return getSmsCode(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 短信登陆
  loginSms({
    dispatch
  }, opts) {
    const {
      grantType,
      clientId,
      clientSecret
    } = SMS;
    opts = {
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      ...opts
    };
    return new Promise((resolve, reject) => {
      loginSms(opts).then(res => {
        dispatch('processLogin', res);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  preLogin({}, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  },
  login({
    dispatch
  }, userInfo) {
    const {
      grantType,
      clientId,
      clientSecret
    } = LOGIN;
    userInfo = {
      grant_type: grantType,
      client_id: clientId,
      client_secret: clientSecret,
      ...userInfo
    };
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        dispatch('processLogin', res);

        // 获取角色部门权限
        this.dispatch('system/role/getRoleCustomerAuth', res.roleId);
        resolve(res);
      }).catch(error => {
        reject(error);
      });
    });
  },
  processLogin({
    commit
  }, data) {
    setTableColumnKey(data);
    commit('SET_TOKEN', data.access_token);
    commit('SET_USER_INFO', data);
    setStorage(Keys.theme, {
      userName: data.userName,
      theme: '#1890ff'
    });
  },
  // user logout
  logout({
    commit,
    dispatch
  }) {
    return logout().then(() => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      delUserData();
      delTravelData();
      resetRouter();
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      setTimeout(() => {
        window.location.reload();
      });

      // // 清理所有getters数据
      // Object.keys(this._mutations).map(key => {
      //   if (key.indexOf('/RESET_ALL') > -1) {
      //     this.commit(key)
      //   }
      // })
    });
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },
  fetchUserList({}, params) {
    return fetchUser(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  bindWechat({}, opts) {
    return bindWechat(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  unbindWechat({}, opts) {
    return unbindWechat(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 切换主题
  updateVersionSwitch({
    dispatch
  }, opts) {
    return updateVersionSwitch(opts).then(() => {
      dispatch('fetchUserInfo');
    });
  },
  fetchUserInfo({
    commit,
    state
  }, userId) {
    userId = userId || state.userData.id;
    return fetchUserInfo(userId).then(res => {
      const {
        data
      } = res;
      commit('USER_INFO', data);
      return data;
    });
  },
  updateUser({}, opts) {
    return updateUser(opts);
  },
  getGroupUserInSaas({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      getGroupUserInSaasRequest(opts).then(res => {
        if (!res) reject();
        commit('GROUP_USER', res.data);
        resolve(res.data);
      }).catch(reject);
    });
  },
  setGroupRoleInSaas({}, opts) {
    return new Promise((resolve, reject) => {
      setGroupRoleInSaasRequest(opts).then(res => {
        if (!res) reject();
        resolve(res.data);
      }).catch(reject);
    });
  },
  getWxLogin({
    dispatch
  }, opts) {
    opts = {
      ...opts,
      ...WX
    };
    return new Promise((resolve, reject) => {
      getWxLogin(opts).then(res => {
        dispatch('processLogin', res);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 扫码
  getWxLoginQR({
    dispatch
  }, opts) {
    return dispatch('getWxLogin', opts);
  },
  // 上传微信code
  getWxLoginCode({
    dispatch
  }, opts) {
    return dispatch('getWxLogin', opts);
  },
  // 微信绑定
  getWxLoginBind({
    dispatch
  }, opts) {
    return dispatch('getWxLogin', opts);
  },
  updatePwd({
    commit,
    dispatch
  }, opts) {
    return updatePwd(opts).then(() => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      delUserData();
      resetRouter();
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
    });
  }
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token);
  },
  SET_USER_INFO: (state, data) => {
    const pickData = {};
    pickMap.forEach(key => {
      pickData['travelAgency'] = data['TravelAgency'];
      pickData[key] = data[key];
    });
    delete pickData.TravelAgency;
    state.userData = pickData;
    state.userId = data.id;
    setUserData(pickData);
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.userName = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  GROUP_USER: (state, data) => {
    state.groupUser = data;
  },
  USER_INFO: (state, data) => {
    data.theme = data.theme && JSON.parse(data.theme);
    state.userInfo = data;
  },
  CHANGE_USER: (state, {
    type,
    val
  }) => {
    state.userData[type] = val;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};