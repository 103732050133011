import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'plan/boat/scenicCompayList'
  }),
  computed: {
    data() {
      return this.$store.getters['plan/boat/scenicData'];
    }
  }
};