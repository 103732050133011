import "core-js/modules/es.array.at.js";
import "core-js/modules/es.string.at-alternative.js";
import { mapGetters } from 'vuex';
import { SEAL_TYPE } from '@/config';
export default {
  props: {
    sealType: {
      type: String,
      default: SEAL_TYPE.business
    },
    comp: {
      type: String,
      default: 'Receipt'
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sealImageUrl: ''
    };
  },
  computed: {
    ...mapGetters({
      businessInfo: 'system/companyInfo/businessInfo'
    }),
    sealImageStyle() {
      // 旅监电子行程单
      const isTrip = this.sealType == SEAL_TYPE.trip;
      return {
        // background: `url(${this.sealImageUrl}) no-repeat 100%`,
        right: isTrip ? '50px' : '60px',
        bottom: '200px'
      };
    }
  },
  watch: {
    businessInfo: {
      handler: function (data) {
        if (!data) {
          this.fetchSealImage();
          return;
        }
        const current = this.findSeal(data);
        const hasAuth = this.visibleSeal(current.formConfigCodes);
        if (hasAuth) {
          this.sealImageUrl = current.url || this.url;
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchSealImage() {
      this.$store.dispatch('system/companyInfo/fetchSealImage', {});
    },
    findSeal(list) {
      if (!list) return;
      const fList = list.filter(it => it.name == this.sealType);
      return fList && fList.length && fList.at(-1);
    },
    visibleSeal(codes) {
      if (this.sealType == SEAL_TYPE.trip) return true;
      return codes && codes.indexOf(this.comp) != -1;
    }
  }
};