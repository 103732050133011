import "core-js/modules/es.array.push.js";
import { SelectCustom } from '@/components/common';
const defaultState = () => ({
  edit: true,
  isUpdate: false,
  customerId: 0,
  price: 0,
  productId: 0,
  remarks: ''
});
export default {
  components: {
    SelectCustom
  },
  data() {
    return {
      visible: false,
      list: [],
      listCount: 0,
      align: 'center'
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '更新客户价格' : '新增客户价格';
    }
  },
  methods: {
    show(scope) {
      const {
        id
      } = scope.row;
      this.visible = true;
      this.productId = id;
      this.fetchData(id);
    },
    hide() {
      this.visible = false;
    },
    fetchData(productId) {
      this.$store.dispatch('resource/product/fetchCustom', {
        productId
      }).then(data => {
        this.list = this.formatData(data);
        this.listCount = data.length;
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        this.$set(it, 'isUpdate', true);
        this.$set(it, 'edit', false);
        it.originObj = {
          ...it
        };
        return it;
      });
    },
    handlePlus() {
      this.listCount++;
      this.list.push(defaultState());
    },
    handleDelete(row, index) {
      if (row.id) {
        this.$bus.open(() => {
          this.$store.dispatch('resource/product/delCustom', row.id);
          this.list.splice(index, 1);
        });
      }
    },
    selectCustom(row, val) {
      const {
        id,
        name
      } = val;
      row.customerId = id;
      row.customerName = name;
    },
    // 取消
    cancelEdit(row) {
      this.resetForm(row);
    },
    // 保存
    confirmEdit(row) {
      const opts = {
        ...row,
        productId: this.productId
      };
      this.$store.dispatch('resource/product/addCustom', opts).then(() => {
        this.$bus.tip();
        this.fetchData(this.productId);
      });
      row.edit = false;
    },
    resetForm(row) {
      const hasOwn = Object.prototype.hasOwnProperty;
      Object.keys(row.originObj).forEach(key => {
        if (key !== 'originObj' && hasOwn.call(row, key)) {
          row[key] = row.originObj[key];
        }
      });
      row.edit = false;
    }
  }
};