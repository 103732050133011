// 按单位 付餐厅
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  price,
  orderArrangeRemark,
  orderOther,
  orderTotalPay,
  payed,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
const lastItem = [orderArrangeRemark, orderOther, orderTotalPay, payed, owePay, downPayed, actionItem];
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '餐厅'
  }
}, ...PayUnitColumn];
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'restaurantName',
    label: '餐厅'
  }
}, {
  attrs: {
    prop: 'times',
    label: '用餐数'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, price, ...lastItem];