import "core-js/modules/es.array.push.js";
import { tableMixin } from '@/mixin';
import DialogDetail from './DialogDetail';
import DialogUpdate from './DialogUpdate';
import { cashLogType, cashFeeTypeLabel, cashModeTypeLabel } from '@/config/billDown';
import { processMinus } from '@/utils/number';
import { parseDate } from '@/utils';
import { TYPES } from '@/config/billDown';

// /bill/unit-pay/scenic-update
const RESOURCE_TO_PATH = {
  RESOURCE_CUSTOMER: '/bill/unit-collect/detail',
  RESOURCE_HOTEL: '/bill/unit-pay/hotel-update',
  RESOURCE_RESTAURANT: '/bill/unit-pay/restaurant-update',
  RESOURCE_INSURANCE: '/bill/unit-pay/insurance-update',
  RESOURCE_SCENIC: '/bill/unit-pay/scenic-update',
  RESOURCE_BUS_COMPANY: '/bill/unit-pay/fleet-update',
  RESOURCE_TRANSPORT_COMPANY: '/bill/unit-pay/traffic-update',
  VERIFICATION_OTHER_INCOME: '/bill/unit-pay/other-update',
  VERIFICATION_OTHER_PAY: '/bill/unit-pay/other-update',
  RESOURCE_OTHER_PAY: '/bill/unit-pay/other-update',
  RESOURCE_SHUTTLE: '/bill/unit-pay/pickup-update',
  RESOURCE_VISA: '',
  RESOURCE_CUSTOMER_SUBSIDY: '',
  GUIDE_REIMB: '/bill/guide-audit-detail',
  GUIDE_BORROW_APPLY: '',
  GUIDE_BORROW: '',
  GUIDE_HANDBACK: '',
  CUSTOM_SUBJECT: '',
  RESOURCE_OUT_TRANSFER: '',
  RESOURCE_HEAD_COMPANY_INCOME: '',
  RESOURCE_HEAD_COMPANY_PAY: '',
  RESOURCE_BANK_TRANSFER: '',
  RESOURCE_BANK_ADJUST: '',
  RESOURCE_BANK: '',
  RESOURCE_SHOP: '/bill/unit-collect/shop-detail',
  RESOURCE_GUIDE: '',
  VERIFICATION_CUSTOMER_COLLECT: '',
  VERIFICATION_BUS_COMPANY_NETPAY: '',
  VERIFICATION_GUIDE_NETPAY: '',
  HEAN_COMPANY: ''
};
export default {
  components: {
    DialogDetail,
    DialogUpdate
  },
  mixins: [tableMixin],
  data: () => ({
    resourceToPath: RESOURCE_TO_PATH,
    cashLogType,
    cashFeeTypeLabel,
    cashModeTypeLabel
  }),
  computed: {
    totalCount() {
      const data = this.$store.getters['bill/billDown/cashLogData'];
      return data ? data.totalCount : 0;
    },
    isGuideType() {
      return val => val === TYPES.borrow || val === TYPES.reimb || val === TYPES.borrow_apply;
    }
  },
  methods: {
    parseDate,
    processMinus,
    handleDetail(row) {
      this.$refs.dialogDetailRef.show(row);
    },
    handleEdit(row) {
      const {
        cashType,
        id,
        resourceId,
        planId
      } = row;
      const path = this.resourceToPath[cashType];
      if (!path) return;

      // 按单位付款，当前编辑的数据
      // this.$store.commit('bill/billDown/payUnitScenic/SET_CUR_ITEM', row)

      const query = {
        id,
        rid: resourceId
      };
      if (planId) {
        query.planId = planId;
      }
      this.$router.push({
        path,
        query
      });
    },
    handleDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('system/accounting/delCash', {
          id
        }).then(() => {
          this.$parent.fetchData();
        });
      });
    }
  }
};