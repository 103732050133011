import { DEFAULT_PAGE } from '@/config';
import { getCurTagView } from '@/utils/tagView';
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: DEFAULT_PAGE.pageSize
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100, 200, 500, 1000];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      changeSize: null
    };
  },
  computed: {
    getTagViewPage() {
      const curTag = getCurTagView();
      let page = this.page;
      let size = this.size;
      if (curTag) {
        page = curTag.page || this.page;
        size = curTag.size || this.size;
        const {
          search
        } = curTag;
        if (search) {
          page = search.pageNum || page;
          size = search.pageSize || size;
        }
      }
      return {
        page,
        size
      };
    },
    currentPage: {
      get() {
        return this.getTagViewPage.page;
      },
      set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get() {
        return this.getTagViewPage.size || this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      }
    },
    getLayout() {
      const device = this.$store.state.app.device;
      return device === 'mobile' ? 'prev, pager, next' : 'total, sizes, prev, pager, next, jumper';
    }
  },
  methods: {
    handleSizeChange(val) {
      this.changeSize = val;
      // 记录当前分页，重新进入后恢复
      const {
        path
      } = this.$route;
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1,
        size: val
      });
      this.$emit('pagination', {
        pageNum: 1,
        pageSize: val
      });
      // if (this.autoScroll) {
      //   scrollTo(0, 500)
      // }
    },
    handleCurrentChange(val) {
      // 记录当前分页，重新进入后恢复
      const {
        path
      } = this.$route;
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: val,
        size: this.pageSize
      });
      this.$emit('pagination', {
        pageNum: val,
        pageSize: this.pageSize
      });
      // if (this.autoScroll) {
      //   scrollTo(0, 500)
      // }
    }
  }
};