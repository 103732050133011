export default {
  data() {
    return {
      visible: false
    };
  },
  methods: {
    handleSearch() {},
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    handleCreate() {},
    handleBind({
      row
    }) {
      this.$refs.bindBusinessRef.show({
        ...row,
        resType: this.resType
      });
    },
    // 取消绑定
    handleUnBind({
      row
    }) {
      const {
        id: resId,
        travelAgencyId
      } = row;
      const resourceType = this.resType;
      const opts = {
        resourceType,
        travelAgencyId,
        resId
      };
      this.$bus.open(() => {
        this.$store.dispatch('resource/common/delBusinessCode', opts).then(() => {
          this.fetchData(this.searchParams);
        });
      }, `是否确认要解绑该商家？`);
    }
  }
};