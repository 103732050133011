// 增值服务类型
export const VAS_TYPE = {
  SMS: 'SMS',
  // 短信
  GLBKLIST: 'GLBKLIST',
  // 桂林地接系统黑名单
  YNBKLIST: 'YNBKLIST',
  // 云南地接系统黑名单
  XBBKLIST: 'XBBKLIST',
  // 西北地接系统黑名单
  TRIPSYSSEV: 'TRIP-SYS-SEV' // 桂林地接系统
};

// 充值类型
export const RECHANGE_TYPE = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY',
  COUNT: 'COUNT',
  FOREVER: 'FOREVER'
};

// 续费类型名字
export const RENEW_TYPE = {
  FUTURE_ADMIN: '地接系统续费'
};