import "core-js/modules/es.array.push.js";
import { parseDate } from '@/utils';
import { mergeTableRow } from '@/utils/table';
export const formatData = (list, key) => {
  if (!list || !list.length) return [];
  const result = [];
  list.forEach(it => {
    const item = {
      ...it,
      rowId: it.id,
      orderDate: it.orderDate && parseDate(it.orderDate)
    };
    if (key && it[key]) {
      const listItem = it[key];
      if (listItem.length) {
        listItem.forEach(child => {
          result.push({
            ...item,
            ...child
          });
        });
      }
    } else {
      result.push(item);
    }
  });
  return result;
};
export const handleTableMerge = (data, keys = []) => {
  return mergeTableRow(data, ['orderDate', 'orderStatus', 'orderNumber', 'companyName', 'customerTripNumber', 'personLiableName', 'totalPay', ...keys]);
};