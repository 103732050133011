import "core-js/modules/es.array.push.js";
/*
 * @Author: HelloMan
 * @Date: 2021-09-30 10:46:41
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-09-30 10:46:42
 * @Description:
 */

import dayjs from 'dayjs';
const dateArr = val => dayjs(val).format('YYYY-MM-DD');
const day = 1000 * 60 * 60 * 24;
const monthFirst = () => {
  const date = new Date();
  date.setDate(1);
  return date;
};
const monthLast = () => {
  const date = new Date();
  const enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return enddate;
};
export const monthFirstDay = dateArr(monthFirst());
export const monthLastDay = dateArr(monthLast());

// 截取图片前缀
export const splitPath = url => {
  url = decodeURIComponent(url);
  return url.indexOf('?') > -1 ? url.split('?')[0] : url;
};

// 获取字符长度
export const getTextLength = str => {
  // eslint-disable-next-line no-control-regex
  return String(str).replace(/[^\x00-\xff]/g, 'aa').length;
};

// 生成随机数 默认32位
export const randomString = e => {
  e = e || 32;
  var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
    a = t.length,
    n = '';
  for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
};

// 格式化url参数
export const parseQuerys = (url, querys) => {
  if (typeof querys != 'object') {
    return url;
  }
  let arr = [];
  for (let key in querys) {
    arr.push(`${key}=${querys[key]}`);
  }
  url = url.indexOf('?') != -1 ? url : url + '?';
  return url + arr.join('&');
};
// 清除url参数
export const clearUrlSuffix = () => {
  var url = window.location.href; //获取当前页面的url
  if (url.indexOf('?') != -1) {
    //判断是否存在参数
    url = url.replace(/(\?|#)[^'"]*/, ''); //去除参数
    window.history.pushState({}, 0, url);
  }
};

// 根据日期获取是星期几
const DAY_MAP = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六'
};
export const getWeekByDate = (date, toLabel = true) => {
  if (!date) return '';
  const day = new Date(date).getDay();
  const val = DAY_MAP[day];
  return toLabel ? val ? val : '' : day;
};

/**
 * 获取2个时间差距
 * @param {t} dateString1 开始日期
 * @param {*} dateString2 结束日期
 * @param {*} addDay 行程的日期要加多一天
 * @returns
 */
export const getDaysBetween = (dateString1, dateString2, addDay = 1) => {
  const startDate = Date.parse(dateString1);
  const endDate = Date.parse(dateString2);
  const days = Math.floor((endDate - startDate) / day) + addDay;
  return days;
};
export const getSecondsBetween = (dateString1, dateString2) => {
  const startDate = Date.parse(dateString1);
  const endDate = Date.parse(dateString2);
  return startDate - endDate;
};

// 获取两个日期之间日期、月份、年份
export const getDateBetween = (start, end, type = 'date') => {
  var result = [];
  //使用传入参数的时间
  var startTime = new Date(start);
  var endTime = new Date(end);
  if (type == 'date') {
    while (endTime - startTime >= 0) {
      let year = startTime.getFullYear();
      let month = startTime.getMonth();
      month = month < 9 ? '0' + (month + 1) : month + 1;
      let day = startTime.getDate().toString().length == 1 ? '0' + startTime.getDate() : startTime.getDate();
      //加入数组
      result.push(year + '-' + month + '-' + day);
      //更新日期
      startTime.setDate(startTime.getDate() + 1);
    }
  }
  if (type == 'month') {
    while (startTime <= endTime) {
      let m = new Date(startTime).getMonth() + 1;
      let month = m < 10 ? '0' + m : m;
      let str = startTime.getFullYear() + '-' + month;
      let s = startTime.getFullYear() + '-0';
      if (str == s) {
        str = startTime.getFullYear() + '-12';
      }
      result.push(str);
      startTime.setMonth(month);
    }
  }
  if (type == 'year') {
    let min = new Date(start).getFullYear();
    let max = new Date(end).getFullYear();
    while (min <= max) {
      result.push(min);
      min = Number(min) + 1;
    }
  }
  return result;
};

// 格式化日期
export function parseTime(time = new Date(), cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}
export function parseDate(time = new Date()) {
  return parseTime(time, '{y}-{m}-{d}');
}
export const today = parseDate();
export function parseMonth(time) {
  if (!time) return;
  return parseTime(time, '{y}-{m}');
}

// 一周前
export function beforeWeek(num = 1) {
  const str = parseDate(new Date() - 3600 * 1000 * 24 * 7 * num);
  return str;
}
export function formatIosDate(date, fmt = '{y}-{m}-{d} {h}:{i}:{s}') {
  return parseTime(new Date(date.replace(/-/g, '/')), fmt);
}

// 格式化时间
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

// 获取之后的时间，默认1小时
export const getNextHours = (pass = 60) => {
  let date = new Date();
  date = +date + 1000 * 60 * pass;
  date = new Date(date);
  return parseTime(date);
};

// 获取下一天
export const getNextDate = (date = new Date()) => {
  date = new Date(date);
  date = +date + day;
  date = new Date(date);
  return parseDate(date);
};

// 根据时间戳获取天数差
export const getDaysByTimestamp = timestamp => {
  // 将时间戳转换为日期对象
  const date = new Date(timestamp);

  // 获取当前日期
  const currentDate = new Date();

  // 计算时间戳与当前日期之间的差值（单位为毫秒）
  const difference = date.getTime() + day - currentDate.getTime();

  // 将毫秒转换为天数
  const daysDifference = Math.floor(difference / day);
  return daysDifference;
};
export const calcDateByCount = (date, days) => {
  return parseDate(new Date(date).getTime() + days * day);
};
export const isDateOrConvertible = value => {
  // 检查是否为 Date 类型
  if (value instanceof Date) {
    return true;
  }

  // 尝试将值转换为 Date
  const date = new Date(value);

  // 检查转换后是否是有效的日期
  return !isNaN(date.getTime());
};
export const checkBirthdayRange = (birthDate, startDate, endDate) => {
  // 获取当前日期
  const sDate = new Date(startDate);
  const eDate = new Date(endDate);

  // 获取出生日期
  const birth = new Date(birthDate);
  return birth.getMonth() >= sDate.getMonth() && birth.getMonth() <= eDate.getMonth() && birth.getDate() >= sDate.getDate() && birth.getDate() <= eDate.getDate();
};