/* eslint-disable no-unused-vars */
// 签单管理
import { getTicketPage, deleteTicket, fetchTicket, createOrUpdateTicket, fetchTicketPlan, fetchTicketCount, fetchTicketItems, updateTicketItems, createTicketItem, batchCreateTicketItem, deleteTicketItem, deleteTicketIds, dispatchTicket, auditTicket, transferTicket, behindhandSignRequest, fetchLog } from '@/api/ticket';
import { formatCharData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
import { getSelectLabel } from '@/config/select';
const getPlanList = queryParam => {
  queryParam = {
    ...DEFAULT_PAGE,
    ...queryParam
  };
  return fetchTicketPlan(queryParam).then(res => {
    const {
      data
    } = res;
    return data;
  });
};
function formatTicketData(data) {
  if (!data || !data.length) return [];
  return data.map(it => {
    const {
      autoRelease,
      isUsed
    } = it;
    it.type = getSelectLabel('signType', it.signType);
    it.rowId = it.id;
    it.isChecked = false;
    it.autoReleaseStatus = autoRelease;
    it.isUsedStatus = isUsed;
    return it;
  });
}
const dfaultState = () => ({
  ticketPlanData: null,
  ticketSearchData: null,
  ticketManageData: null,
  ticketPlanAuditData: null,
  noticeDetail: null,
  ticketData: null,
  ticketItems: null,
  ticketLog: null,
  currentPlan: {}
});
const state = dfaultState();
const getters = {
  ticketPlanData: state => state.ticketPlanData,
  ticketSearchData: state => state.ticketSearchData,
  ticketManageData: state => state.ticketManageData,
  ticketPlanAuditData: state => state.ticketPlanAuditData,
  noticeDetail: state => state.noticeDetail,
  ticketData: state => state.ticketData,
  ticketItems: state => state.ticketItems,
  ticketLog: state => state.ticketLog,
  currentPlan: state => state.currentPlan
};
const actions = {
  // 签单票据录入订单列表
  fetchPlan({
    commit
  }, queryParam) {
    return getPlanList(queryParam).then(data => {
      commit('FETCH_TICKET_PLAN', data);
      return data;
    });
  },
  // 签单票据审核订单列表
  fetchPlanAudit({
    commit
  }, queryParam) {
    return getPlanList(queryParam).then(data => {
      commit('FETCH_TICKET_PLAN_AUDIT', data);
      return data;
    });
  },
  // 签单（景区/酒店/餐厅）列表
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchTicket(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_MANAGE_DATA', data);
      return data;
    });
  },
  // 签单景区/酒店/餐厅） 下拉框列表
  fetchTicket({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchTicket(queryParam).then(res => {
      const {
        data
      } = res;
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      // 如果请求是第一页，清除掉缓存数据
      if (data.currentPage === 1) {
        state.ticketData = null;
      }
      const lastList = state.ticketData ? state.ticketData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...lastList, ...formatCharData(list)];
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 签单（景区/酒店/餐厅）查询
  fetchSearch({
    commit
  }, queryParams) {
    return getTicketPage(queryParams).then(res => {
      const {
        data
      } = res;
      commit('FETCH_TICKET_SEARCH', data);
      return data;
    });
  },
  // 获取票据行程
  fetchTicketCount({
    commit
  }, id) {
    return fetchTicketCount(id).then(res => {
      const {
        data
      } = res;
      // commit('FETCH_TICKET_TRIP', data)
      return data;
    });
  },
  fetchLog({
    commit
  }, itemId) {
    const opts = {
      itemId,
      type: 4,
      subType: ''
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchTicketLog({
    commit
  }, itemId) {
    const opts = {
      itemId,
      type: 2,
      subType: 'sign'
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // 创建资源（景区/酒店/餐厅）签单
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdateTicket(opts).then(res => {
      const {
        data
      } = res;
      commit('UPDATE', data);
      return data;
    });
  },
  // 删除资源（景区/酒店/餐厅）签单
  delete({
    commit
  }, id) {
    return deleteTicket(id).then(res => {
      commit('DELETE', id);
      return res.data;
    });
  },
  // 查询某个资源已经添加的票据列表
  fetchTicketItems({
    commit,
    state
  }, planId) {
    planId = planId || state.currentPlan.id;
    return fetchTicketItems(planId).then(res => {
      let {
        data
      } = res;
      data = formatTicketData(data);
      commit('TICKET_ITEMS', data);
      return data;
    });
  },
  // 更新已经添加的票据列表
  updateTicketItems({
    commit,
    state
  }, data) {
    return updateTicketItems(data).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加某个资源的票据 如： 银子岩的一张签单
  createTicketItem({
    commit
  }, opts) {
    return createTicketItem(opts).then(res => {
      if (!res) return;
      return res.data;
    });
  },
  // 批量添加某个资源的票据
  batchCreateTicketItem({
    commit
  }, opts) {
    return batchCreateTicketItem(opts).then(res => {
      if (!res) return;
      return res.data;
    });
  },
  // 删除某个资源的票据
  deleteTicketItem({
    commit
  }, id) {
    return deleteTicketItem(id).then(data => {
      return data;
    });
  },
  // 删除某个资源的票据 （批量）
  deleteTicketIds({
    commit
  }, id) {
    return deleteTicketIds(id).then(data => {
      return data;
    });
  },
  // 完成/取消派单
  toggleDispatch({
    commit
  }, opts) {
    return dispatchTicket(opts).then(data => {
      return data;
    });
  },
  // 完成/取消审核
  toggleAudit({
    commit
  }, opts) {
    return auditTicket(opts).then(data => {
      return data;
    });
  },
  // 转单
  transferTicket({
    commit
  }, opts) {
    return transferTicket(opts).then(data => {
      return data;
    });
  },
  // 设置拖欠签到
  setBehindhandSign({
    commit
  }, opts) {
    return behindhandSignRequest(opts).then(data => {
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.ticketData = data;
  },
  FETCH_MANAGE_DATA: (state, data) => {
    state.ticketManageData = data;
  },
  FETCH_TICKET_PLAN: (state, data) => {
    state.ticketPlanData = data;
  },
  FETCH_TICKET_PLAN_AUDIT: (state, data) => {
    state.ticketPlanAuditData = data;
  },
  FETCH_TICKET_SEARCH: (state, data) => {
    state.ticketSearchData = data;
  },
  TICKET_ITEMS: (state, data) => {
    // data = data.map(it => {
    //   it.isChecked = false
    //   return it
    // })
    state.ticketItems = data;
  },
  FETCH_LOG: (state, data) => {
    state.ticketLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.ticketData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.ticketData.list.filter(it => it.id !== id);
    state.ticketData.list = list;
  },
  CURRENT_PLAN: (state, data) => {
    state.currentPlan = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};