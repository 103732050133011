import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { decimalSplit, processStatisticsKeys, formatPeopleValue, formatPeopleCountValueByKey, processAdd } from '@/utils';
import { DynamicColumn } from '@/components/common';
export default {
  name: 'TableNew',
  components: {
    DynamicColumn
  },
  inheritAttrs: false,
  props: {
    align: {
      type: String,
      default: 'center'
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    dynamic: {
      type: Boolean,
      default: true
    },
    noPage: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    statistics: {
      type: Object,
      default: () => null
    },
    statisticsKeys: {
      type: [Array, Object],
      default: () => []
    },
    summaryMethod: {
      type: [Function, Boolean],
      default: false
    },
    onlySummary: {
      type: Boolean,
      default: false
    },
    showSummary: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableHeight: 300,
      columns: this.$attrs.columns,
      tableStyle: {}
    };
  },
  computed: {
    getColumns() {
      return this.columns;
    },
    getTableHeight() {
      return this.tableHeight;
    },
    getTableStyle() {
      return {
        ...this.$attrs,
        ...this.tableStyle
      };
    }
  },
  watch: {
    '$attrs.data': {
      handler: function (d) {
        if (!d) return;
        this.tableHeight = this.calcTableHeight();
        this.$nextTick(() => {
          this.getFooter();
          this.$refs['tableRef'].doLayout();
        });
      },
      immediate: true
    }
  },
  mounted() {
    this.tableHeight = this.calcTableHeight();
  },
  methods: {
    reloadColumn({
      columns,
      style
    }) {
      this.columns = columns;
      this.tableStyle = style;
      this.$refs.tableRef.doLayout();
      this.getFooter();
    },
    getStatisticsKeys() {
      return processStatisticsKeys(this.statisticsKeys);
    },
    getFooter() {
      const tableEl = this.$refs.tableRef;
      if (!tableEl) return;
      if (!this.statistics) return;
      if (this.onlySummary) return;
      const el = tableEl.$el;
      const tbody = el.querySelector('.el-table__footer-wrapper .has-gutter');
      const trs = tbody.querySelector('tr');

      // 重复加载多个合计处理
      if (tbody.children.length > 1) {
        for (let i = 1; i < tbody.children.length; i++) {
          tbody.children[i].parentNode.removeChild(tbody.children[i]);
        }
      }

      // 表格合并错位处理
      for (let i = 0; i < trs.children.length; i++) {
        trs.children[i].setAttribute('rowspan', 1);
      }
      const trclone = trs.cloneNode(true);
      function platColumn(list) {
        let temp = [];
        list.forEach(it => {
          if (it.children) {
            temp.push(...platColumn(it.children));
          } else {
            temp.push({
              property: it.attrs.prop
            });
          }
        });
        return temp;
      }
      const columns = platColumn(this.columns);
      const sumObj = this.getSummaries2({
        columns
      });
      trclone.children.forEach((el, index) => {
        el.innerText = sumObj[index] != undefined ? sumObj[index] : '';
      });
      tbody.appendChild(trclone);
    },
    calcTableHeight() {
      const tableEl = this.$refs.tableRef;
      if (!tableEl) return;
      const el = tableEl.$el;
      const barHeight = 100;
      const pageHeight = 32;
      const paddingHeight = 0;
      const topHeight = el.parentNode.offsetTop + barHeight + pageHeight + paddingHeight;
      const clientHeight = document.documentElement.clientHeight;
      return clientHeight - topHeight;
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    // 合计和小计赋值
    getSummaries(param) {
      const statistics = this.statistics;
      const {
        columns,
        data
      } = param;
      const sums = [];
      const sumsObj = {};
      if (!data || !data.length) return [];
      if (!columns || !columns.length) return [];

      // 以下是对小计和总计分别进行赋值
      columns.forEach((column, index) => {
        let last;
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const statisticsKeys = this.getStatisticsKeys();
        const values = data.map(item => {
          // 只合计设定的字段
          if (statisticsKeys[column.property]) {
            // 处理多个子项合计
            if (last && item.rowId && last.rowId == item.rowId) {
              return 0;
            } else {
              last = item;
              return +item[column.property];
            }
          }
        });

        // 将要进行小计合计的字段组成一个数组
        //如果label包含当前column.property，则进行数据处理
        if (statisticsKeys[column.property]) {
          // 小计
          const subtotal = values.reduce((prev, curr) => {
            const value = +curr;
            // 如果是数字则进行累加
            if (!isNaN(value)) {
              prev = processAdd(prev, value);
              return prev;
            } else {
              return prev;
            }
          }, 0);
          sumsObj[column.property] = {
            index,
            value: subtotal
          };

          // 只显示小计，不显示总计
          sums[index] = decimalSplit(subtotal);
        } else {
          sums[index] = '';
        }
      });

      // 计算人数小计与总计
      const {
        index: idx,
        value: peopleRes
      } = formatPeopleCountValueByKey({
        key: this.peopleCountKey,
        data,
        columns,
        statistics
      });
      sums[idx] = peopleRes;
      if (this.summaryMethod) {
        return this.summaryMethod({
          sums,
          sumsObj
        });
      }
      return sums;
    },
    // 合计和小计赋值
    getSummaries2({
      columns
    }) {
      const statistics = this.statistics;
      const sums = [];
      const sumsObj = {};
      if (!statistics) return;
      if (!columns || !columns.length) return;

      // 以下是对小计和总计分别进行赋值
      columns.forEach((column, index) => {
        // let last

        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        const statisticsKeys = this.getStatisticsKeys();

        // 将要进行小计合计的字段组成一个数组
        //如果label包含当前column.property，则进行数据处理
        if (statisticsKeys[column.property]) {
          const subtotal = statistics[statisticsKeys[column.property]];
          sumsObj[column.property] = {
            index,
            value: subtotal
          };

          // 只显示小计，不显示总计
          sums[index] = decimalSplit(subtotal);
          if (column.property == 'peopleCount') {
            // 计算人数小计与总计
            sums[index] = formatPeopleValue(statistics);
          }
        } else {
          sums[index] = '';
        }
      });
      if (this.summaryMethod) {
        return this.summaryMethod({
          sums,
          sumsObj
        });
      }
      return sums;
    }
  }
};