import "core-js/modules/es.array.push.js";
import { defaultAllocationItem as defaultState, ENTERPRISE_RESTYPE } from './config';
import SelectScenic from '../../Select/SelectScenic.vue';
import SelectHotel from '../../Select/SelectHotel.vue';
import SelectRestaurant from '../../Select/SelectRestaurant.vue';
export default {
  components: {
    SelectScenic,
    SelectHotel,
    SelectRestaurant
  },
  data() {
    return {
      ENTERPRISE_RESTYPE,
      visible: false,
      resList: [defaultState()]
    };
  },
  computed: {
    isScenic() {
      return type => ENTERPRISE_RESTYPE.SCENIC == type || ENTERPRISE_RESTYPE.DEFAULT == type;
    },
    isHotel() {
      return type => ENTERPRISE_RESTYPE.HOTEL == type;
    },
    isRestaurant() {
      return type => ENTERPRISE_RESTYPE.RESTAURANT == type;
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.resList = data;
    },
    hide() {
      this.visible = false;
    },
    add(type) {
      this.resList.push(defaultState(type));
    },
    deleteItem(index) {
      this.resList.splice(index, 1);
    },
    handleSelect(item, {
      label
    }) {
      item.resName = label || '';
    },
    // 保存
    handleConfirm() {
      // this.$store.commit('enterprise/guide/SET_STANDARD', this.resList)
      const list = this.resList.filter(it => it.resId != '');
      this.$emit('save', list);
      this.hide();
    }
  }
};