import { APP } from '@/config';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    count: APP.sendSmsTime,
    isCounting: false,
    timer: null
  }),
  methods: {
    handleCounting() {
      if (this.disabled) {
        this.$bus.tip({
          type: 'warning',
          message: '请先输入手机号！'
        });
        return;
      }
      if (this.isCounting) return;
      this.isCounting = true;
      // 发送验证码
      this.countingDone();
      // 按钮状态
      this.$emit('update:disabled', true);
      const doCount = () => {
        if (this.count >= 0) {
          this.timer = setTimeout(() => {
            this.count--;
            doCount();
          }, 1000);
        } else {
          this.isCounting = false;
          this.count = APP.sendSmsTime;
          this.$emit('update:disabled', false);
        }
      };
      doCount();
    },
    restoreCount() {
      clearTimeout(this.timer);
      this.isCounting = false;
      this.count = APP.sendSmsTime;
    },
    countingDone() {
      this.$emit('on-done');
    }
  }
};