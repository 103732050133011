/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-11 10:37:49
 * @Description:
 */
import Vue from 'vue';
import Cookies from 'js-cookie';
import Element from './plugins/ElementUI';
import 'normalize.css/normalize.css'; // a modern
import './styles/element-variables.scss';
import './styles/index.scss';
import '@/components'; //导入全局组件

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';
import './map/amap';
import './permission';
import Bus from './plugins/bus';

// import xss from 'xss'
// Vue.prototype.xss = xss
// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = function (el) {
  return echarts.init(el, null, {
    renderer: 'svg'
  });
};
// import * as filters from './filters'

Vue.use(Element, {
  size: Cookies.get('size') || 'mini',
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(Bus);

// register global utility filters
// Object.keys(filters).forEach(key => {
//   Vue.filter(key, filters[key])
// })

Vue.config.productionTip = false;
window.$vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');