// 发团预定安排
import axios from 'axios';
import request, { requestBlob } from '@/utils/request';
import { LOG_TYPE } from '@/config';
import { fetchLog } from '@/api/order';
import { APP } from '@/config';
import { parseQuerys, getExportUrl } from '@/utils';
import { getToken } from '@/utils/cookie';

// 获取邮件列表
export function mailListRequest(params) {
  return request({
    url: '/order/plan/get-email-messages',
    method: 'get',
    params
  });
}

// 获取邮件指定附件
export function mailAttachementRequest(params) {
  const url = getExportUrl('/order/plan/get-email-attachment');
  return requestBlob({
    url,
    params
  });
}

// 获取邮件指定附件 OFD文件
export function mailAttachementOfdRequest(params) {
  const url = getExportUrl('/order/plan/get-email-attachment');
  return axios.request({
    url,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      Authorization: 'bearer ' + getToken()
    },
    params
  });
}

// 获取团列表
export function planListRequest(params) {
  return request({
    url: '/order/res-booking/plan-list',
    method: 'get',
    params
  });
}

// 获取船票名单
export function planBoatMembersRequest(params) {
  return request({
    url: '/order/res-booking/get-ship-members',
    method: 'get',
    params
  });
}

// 根据资源计划id 查询计划列表
export function getPlanListByResIdRequest(params) {
  return request({
    url: '/order/plan/plans-res-booking-id',
    method: 'get',
    params
  });
}

// 复制计划
export function copyPlanRequest(id) {
  return request({
    url: '/order/plan/copyPlan',
    method: 'post',
    data: {
      id
    }
  });
}

// 接送列表
export function fetchPickup(params) {
  return request({
    url: '/order/report/shuttlePage',
    method: 'get',
    params
  });
}

// 根据接送id获取接送信息
export function fetchPickupId(params) {
  return request({
    url: '/order/orderBus/findByShuttleId',
    method: 'get',
    params
  });
}

// 导出接送列表
export function exportPickup(params) {
  const url = `${APP.baseUrl}/order/report/exportShuttleList`;
  return requestBlob({
    url,
    params
  });
}

// 房调计划列表
export function fetchHotel(params) {
  return request({
    url: '/order/hotel/pageHotelIndividual',
    method: 'get',
    params,
    timeout: APP.timeout,
    retry: APP.retry,
    retryDelay: APP.retryDelay
  });
}

// 房调计划列表
export function fetchHotelDetail(params) {
  return request({
    url: '/order/hotel/pageHotelIndividualByPlanId',
    method: 'get',
    params
  });
}

// 房调安排订单
export function saveHotelOrder(data) {
  return request({
    url: '/order/hotel/saveOrderIndividual',
    method: 'post',
    data
  });
}

// 计划列表
export function fetchPlan(params) {
  return request({
    url: '/order/plan/pageList',
    method: 'get',
    params
  });
}
// 计划列表（根据登陆者部门匹配）
export function fetchPlan2(params) {
  return request({
    url: '/order/plan/pageListWithLoginUserRole',
    method: 'get',
    params
  });
}

// 计划列表日志
export function fetchPlanLog(opts) {
  const params = {
    type: LOG_TYPE.plan,
    subType: '',
    ...opts
  };
  return fetchLog(params);
}

// 查询计划信息
export function fetchPlanById(planId) {
  return request({
    url: '/order/plan/planId',
    method: 'get',
    params: {
      planId
    }
  });
}

// 查询计划日志详细
export function fetchPlanLogs(params) {
  return request({
    url: '/log/orderList2',
    method: 'get',
    params
  });
}

// 查询计划日志详细
export function fetchPlanLogs3(params) {
  return request({
    url: '/log/orderList3',
    method: 'get',
    params
  });
}

// 更新计划信息
export function updatePlan(params) {
  return request({
    url: '/order/plan/update',
    method: 'post',
    params
  });
}

// 发团
export function sendGroupConfirm(id) {
  return request({
    url: '/order/plan/sendGroup',
    method: 'post',
    params: {
      id
    }
  });
}

// 撤销发团
export function sendGroupCancel(id) {
  return request({
    url: '/order/plan/cancelGroup',
    method: 'post',
    params: {
      id
    }
  });
}

// 团利润标
export function groupProfit(planId) {
  return request({
    url: '/order/plan/findPlanProfitById',
    method: 'get',
    params: {
      planId
    }
  });
}

// 获取订单小团号相关列表
export function findCustomerList(planId) {
  return request({
    url: '/order/orderShop/findCustomerList',
    method: 'get',
    params: {
      planId
    }
  });
}
export function getOrderByPlanId(planId) {
  return request({
    url: '/order/getOrderByPlanId',
    method: 'get',
    params: {
      planId
    }
  });
}

// 订单列表日志
export function fetchHotelLog(data) {
  const {
    itemId,
    type,
    subType,
    subItemId
  } = data;
  return request({
    url: '/log/orderlist',
    method: 'get',
    params: {
      itemId,
      subType,
      type,
      subItemId
    }
  });
}

// 导游排班表
export function getGuideDuty(params) {
  return request({
    url: '/order/orderGuide/schedule',
    method: 'get',
    params
  });
}

// 根据计划id获取订单备注
export function fetchPlanIdOrderRemarks(params) {
  return request({
    url: '/order/plan/plan-order-remarks-list',
    method: 'get',
    params
  });
}

// 编辑购物客户返款金额
export function updateCustomerSubsid(data) {
  return request({
    url: '/order/plan/edit-customer-subsidy',
    method: 'post',
    data
  });
}

// 上报派单
export function toDispatchGroupSign(params) {
  const url = parseQuerys('/order/plan/to-group-dispatch-sign', params);
  return request({
    url,
    method: 'post',
    data: {}
  });
}

// 录入游客购物信息
export function updateShopDetail(data) {
  return request({
    url: '/blacklist/tourist/updateShopDetail',
    method: 'post',
    data
  });
}

// 获取指定游客指定团期数据
export function fetchDayShopTourist(data) {
  return request({
    url: '/blacklist/tourist/list-shop-detail-for-cur-day',
    method: 'post',
    data
  });
}

// 获取导游数据
export function fetchGuideStatistics(params) {
  return request({
    url: '/order/reportShop/report-ByGuide-WithCrossTrip',
    method: 'get',
    params
  });
}

// 旅监接口
export function getTravelSuperviseeDic(data) {
  return request({
    url: '/resource/e-travel-platform/gl',
    method: 'post',
    data
  });
}