import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary, DialogLog } from '@/components/common';
import { orderFundProxy, TYPES } from '@/config/order';
import { mapGetters } from 'vuex';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, systemMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import tableMixin from '../tableMixin';
const defaultState = () => ({
  type: '',
  typeId: '',
  remarks: '',
  total: 0,
  proxy: 'isGuideCollect',
  auditProgress: 'WAITAUDIT'
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    DialogLog
  },
  mixins: [dictionaryMixin, tableMixin, systemMixin],
  data() {
    return {
      proxyType: codeType.proxyType,
      tableData: [],
      columnData: orderFundProxy,
      radios: [{
        value: '本社',
        label: 'isAgencyCollect',
        disabled: false
      }, {
        value: '导游',
        label: 'isGuideCollect',
        disabled: false
      }],
      removeCache: [],
      deleteMsg: '是否确定删除此条代收？',
      fetchPath: 'order/index/fetchFundProxy',
      deletePath: 'order/index/deleteProxy',
      updatePath: 'order/index/createOrUpdateProxy'
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId'
    }),
    isDisableBtn() {
      return !this.allowModifyMoney && this.isBtnDisable;
    },
    isDisabled() {
      return row => {
        return this.allowModifyMoney ? this.isTeanFund(row) : this.isTeanAuditFund(row);
      };
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      let isAgencyCollect = this.getConfig();
      isAgencyCollect = isAgencyCollect ? isAgencyCollect : false;
      this.radios[0].disabled = isAgencyCollect;
      if (!data) return;
      this.tableData = data;
    },
    getConfig() {
      const isDisable = this.getConfigStatus('order:isAgencyCollect:isAgencyCollect');
      return isDisable;
    },
    // fetchData() {
    //   this.$nextTick(() => {
    //     this.$store
    //       .dispatch('order/index/fetchFundProxy', this.currentOrderId)
    //       .then(data => {
    //         this.tableData = data
    //       })
    //   })
    // },

    // initTableData(data) {
    //   if (!data || !data.length) return
    //   this.tableData = data.map(it => {
    //     return it
    //   })
    // },

    selectType(row, item) {
      const {
        label,
        value
      } = item;
      row.type = label;
      row.typeId = value;
    },
    handlePlus() {
      const {
        id: typeId,
        name: type
      } = this.getDictionaryDefVal(this.proxyType);
      this.tableData.push({
        ...defaultState(),
        typeId,
        type
      });
    },
    changeRadio(item, val) {
      item.proxy = val;
      item.isAgencyCollect = val == 'isAgencyCollect' ? true : false;
      item.isGuideCollect = val == 'isGuideCollect' ? true : false;
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.collect
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    beforeCommit(customerOrderId, type) {
      const tableData = this.tableData.filter(it => !it.auditProgress || it.auditProgress == AuditRole.wait);
      return tableData.map(it => {
        it.customerOrderId = customerOrderId;
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.total) {
          continue;
        } else {
          this.$bus.tip({
            type: 'warning',
            message: '代收项目信息不全'
          });
          return false;
        }
      }
      return true;
    }
  }
};