import { VAS_TYPE } from '@/config/vas';
export default {
  data() {
    return {
      vasData: null
    };
  },
  computed: {
    getCount() {
      return this.vasData ? this.vasData.remainder : 0;
    }
  },
  created() {
    this.fetchVasList();
  },
  methods: {
    fetchVasList(params = {}) {
      this.$store.dispatch('system/payment/vasBuyedList', {
        code: VAS_TYPE.SMS,
        ...params
      }).then(res => {
        if (!res || !res.data) return;
        this.vasData = res.data[0];
      });
    },
    showTopupDialog() {
      this.$emit('on-topup');
    },
    showRecordDialog() {
      this.$emit('on-topup-record');
    }
  }
};