import { Columns } from './tableConfig';
import { DialogAuth } from './components';
export default {
  components: {
    DialogAuth
  },
  data() {
    return {
      Columns,
      searchParams: {},
      visible: false
    };
  },
  computed: {
    data() {
      return this.$store.getters['user/groupUser'];
    },
    tableData() {
      return this.data ? this.data.list : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('user/getGroupUserInSaas', this.searchParams);
    },
    handleRoleInGroup(row) {
      this.$refs.dialogRef.show(row);
    }
  }
};