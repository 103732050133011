import { Search } from './components';
import { Columns as columns } from './tableConfig';
import { TYPES } from '@/config/audit';
import { processMinus, formatCreateTimeToList,
// processAdd,
downloadExport } from '@/utils';
import { tagViewMixin } from '@/mixin';
export default {
  components: {
    Search
  },
  mixins: [tagViewMixin],
  data() {
    return {
      columns,
      checkType: TYPES.fee,
      tableData: [],
      statisticsKeys: ['peerPayAmount']
    };
  },
  computed: {
    balance() {
      return this.$store.getters['billCheck/aliecode/balance'];
    },
    data() {
      return this.$store.getters['billCheck/aliecode/aliecodeData'];
    },
    dataList() {
      return this.data ? this.data : [];
    },
    total() {
      return this.data ? this.data.length : 0;
    }

    // statistics() {
    //   if (this.data) {
    //     const peerPayAmount = this.data.reduce(
    //       (acc, cur) => (acc, processAdd(cur.peerPayAmount, acc)),
    //       0
    //     )
    //     return { peerPayAmount }
    //   }
    //   return { peerPayAmount: '' }
    // },
  },
  watch: {
    data: {
      handler: function (d) {
        if (d) {
          this.tableData = this.formatData(this.dataList);
        }
      },
      immediate: true
    }
  },
  methods: {
    processMinus,
    fetchData(params = {}) {
      this.fetchList(params);
      this.fetchBalance();
    },
    fetchList(params = {}) {
      this.$store.dispatch('billCheck/aliecode/list', {
        ...params
      });
    },
    fetchBalance() {
      this.$store.dispatch('billCheck/aliecode/balance');
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    formatData(data) {
      const list = formatCreateTimeToList(data, 'consumeCreateTime');
      return list;
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('billCheck/aliecode/export', {
        ...params
      }).then(res => {
        const fileName = '支付宝企业码消费明细.xlsx';
        downloadExport(res, fileName);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};