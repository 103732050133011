/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-11 10:22:12
 * @Description: 
 */
const install = function (Vue) {
  const bus = new Vue({
    methods: {
      emit(event, ...args) {
        this.$emit(event, ...args);
      },
      on(event, cb) {
        this.$on(event, cb);
      },
      off(event, cb) {
        this.$off(event, cb);
      },
      delay(cb, time) {
        setTimeout(cb, time || 1000);
      },
      tip({
        message = '操作成功！',
        type = 'success',
        duration = 2000,
        showClose = true,
        offset = 20
      } = {}) {
        this.$message({
          message,
          showClose,
          duration,
          type,
          offset: offset
        });
      },
      open(cb, msg = '您确定要删除吗? 是否继续?', title = '警告') {
        return this.$confirm(msg, title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cb();
        }).catch(err => {
          console.log('取消删除', err);
        });
      },
      loading({
        text = '加载中',
        icon = 'el-icon-loading',
        bg = 'rgba(255, 255, 255, 0.7)'
      } = {}) {
        return this.$loading({
          lock: true,
          text,
          spinner: icon,
          background: bg
        });
      }
    }
  });
  Vue.prototype.$bus = bus;
};
export default install;