import { inputMixin } from '@/mixin';
export default {
  name: 'InputAgeRange',
  mixins: [inputMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    ageStart: {
      type: String,
      default: ''
    },
    ageEnd: {
      type: String,
      default: ''
    }
  },
  computed: {
    getStart: {
      get() {
        return this.ageStart;
      },
      set(val) {
        this.$emit('update:ageStart', val);
      }
    },
    getEnd: {
      get() {
        return this.ageEnd;
      },
      set(val) {
        this.$emit('update:ageEnd', val);
      }
    }
  },
  methods: {
    changeStart(val) {
      this.$emit('change-start', val);
    },
    changeEnd(val) {
      this.$emit('change-end', val);
    }
  }
};