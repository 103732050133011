import TableList from './TableList';
import FormShopping from './FormShopping';
import { BtnCreate, DialogDetail, DialogAgreement, DialogLog, DialogBindBusiness, SelectDictionary } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceShopping, TYPES } from '@/config/resource';
import { formatTableData } from '@/utils';
export default {
  name: 'Resource',
  components: {
    BtnCreate,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    FormShopping,
    TableList,
    DialogBindBusiness,
    SelectDictionary
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceShopping,
    tableData: [],
    total: 0,
    searchParams: {
      isEnable: -1 // -1 全部， 0 未启用， 1 启用
    },
    resType: TYPES.shop,
    resourceId: null
  }),
  computed: {
    shoppingData() {
      return this.$store.getters['resource/shopping/shoppingDataOrigin'];
    }
  },
  watch: {
    shoppingData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/shopping/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/shopping/fetchList');
    },
    onSelectArea(val) {
      this.searchParams.area = val.label;
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/createOrUpdate', opts).then(() => {
        this.fetchData(this.searchParams);
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
      // const newList = this.formatContracts(formatTableData(list))
      // this.tableData = mergeTableRow(newList, [
      //   'name',
      //   'contact',
      //   'mobilePhone',
      //   'area',
      //   'createTime',
      //   'code',
      //   'isEnable',
      //   'isExport',
      //   'action',
      // ])
    },
    // formatContracts(list) {
    //   const res = list.reduce((acc, cur) => {
    //     const { id, name, contracts, ...args } = cur

    //     if (contracts.length) {
    //       contracts.forEach(v => {
    //         const { contractPrices } = v
    //         if (contractPrices.length) {
    //           contractPrices.forEach(a => {
    //             acc.push({
    //               ...args,
    //               shopName: name,
    //               ...v,
    //               ...a,
    //               rowId: id,
    //               mergeId: id,
    //               id,
    //             })
    //           })
    //         } else {
    //           acc.push({
    //             ...args,
    //             shopName: name,
    //             ...v,
    //             rowId: id,
    //             mergeId: id,
    //             id,
    //           })
    //         }
    //       })
    //     } else {
    //       acc.push(cur)
    //     }
    //     return acc
    //   }, [])
    //   console.log('res:', res)
    //   return res
    // },

    deleteItem(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/delete', id).then(() => {
        this.fetchData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row, {
        title: '购物店',
        name: '购物店'
      });
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/shopping/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    showAgreement(scope) {
      console.log('scope.row:', scope.row);
      const resourceId = scope.row.id;
      this.resourceId = resourceId;
      this.fetchAgreement();
      this.$refs.agreementRef.show(scope.row, TYPES.shop);
    },
    fetchAgreement() {
      this.$store.dispatch('resource/shopping/fetchAgreement', this.resourceId);
    },
    showAgreementItem(contracId) {
      this.$refs.agreementItemRef.show(contracId, TYPES.shop);
    }
  }
};