import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
import { SelectBankCard, SelectStaff } from '@/components/common';
const defaultState = () => ({
  cashMode: 'COMMON',
  cashSourceType: 'CUSTOM_SUBJECT',
  //自定义现金日志
  cashType: 'CUSTOM_SUBJECT',
  //自定义现金日志
  cashDate: '',
  cashAction: '',
  cash: 0,
  bankName: '',
  invoiceNumber: '',
  invoiceMoney: 0,
  subjectItem: [],
  subjectId: 0,
  cashWay: ''
});
export default {
  components: {
    SelectBankCard,
    SelectStaff
  },
  mixins: [formMixin],
  data: () => ({
    visible: false,
    isUpdate: false,
    options: [],
    title: '',
    form: defaultState(),
    validateForm: {
      cashDate: [{
        required: true,
        message: '日期不能为空！'
      }]
      // cashAction: [{ required: true, message: '费用类型不能为空！' }],
      // cashWay: [{ required: true, message: '方式不能为空！' }],
      // bankName: [{ required: true, message: '银行卡不能为空！' }],
    }
  }),
  computed: {
    ...mapGetters({
      subjectWordData: 'system/accounting/subjectWordData'
    })
  },
  watch: {
    subjectWordData: {
      handler: function (data) {
        if (!data) return;
        this.options = data;
      },
      immediate: true
    }
  },
  methods: {
    edit(data) {
      this.form = {
        ...defaultState(),
        ...data
      };
      this.form.cashMode = this.form.cashMode.toUpperCase();
      this.title = '编辑科目费用';
      this.visible = true;
      this.isUpdate = false;
    },
    addShow(subjectItem) {
      this.form = {
        ...defaultState(),
        subjectItem
      };
      this.form.subjectId = subjectItem[subjectItem.length - 1];
      this.title = '新增科目费用';
      this.visible = true;
      this.isUpdate = true;
    },
    hide() {
      this.form = {};
      this.visible = false;
    },
    changeCascader(val) {
      if (!val) return;
      this.form.subjectItem = val;
      this.form.subjectId = val[val.length - 1];
    },
    onSelect(key, val) {
      this.form[key + 'Name'] = val.label;
      this.form[key + 'Id'] = val.value;
    },
    onSelectCost(val) {
      this.form.cashAction = val.value;
    },
    onSelectCash(val) {
      this.form.cashWay = val.label;
    },
    // 添加科目
    handleCreate() {
      if (!this.handleValidate()) return;
      this.form.used = this.form.cash;
      const opst = this.form;
      this.$store.dispatch('system/accounting/saveCash', opst).then(() => {
        this.$parent.fetchData();
        this.hide();
      });
    }
  }
};