var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "orders",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.checkAccountMoney) + " ")];
      }
    }, {
      key: "totalCollect",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalCollect) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalSubsidy) + " ")];
      }
    }, {
      key: "totalPayed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPayed) + " ")];
      }
    }, {
      key: "unPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.unPay) + " ")];
      }
    }, {
      key: "checks",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.checks) + "/" + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onChecksLink(scope.row, "checks");
            }
          }
        }, [_vm._v(" 对账 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onChecksLink(scope.row, "unit");
            }
          }
        }, [_vm._v(" 收款 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };