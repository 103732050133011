/*
 * @Author: cyg
 * @Date: 2022-03-28 14:59:05
 * @LastEditors: cyg
 * @LastEditTime: 2023-06-12 18:04:02
 * @FilePath: \travel-admin\src\mixin\planIsOrderInfo.js
 */
import system from './system';
export default {
  mixins: [system],
  mounted() {
    setTimeout(() => {
      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      // 是否显示订单信息
      if (!isOrderInfo) {
        this.$nextTick(() => {
          this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
        });
      }
    }, 500);
  }
};