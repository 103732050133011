import guideMixin from './guide';
const columnData = [{
  label: '项目',
  prop: 'name'
}, {
  label: '单价',
  prop: 'price'
}, {
  label: '数量',
  prop: 'count'
}, {
  label: '报账金额',
  prop: 'reimbMoney',
  split: true
}, {
  label: '备注',
  prop: 'remarks',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData
    };
  }
};