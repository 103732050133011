import { mapGetters } from 'vuex';
import TableList from './TableList';
import FormOrderAudit from './FormOrderAudit';
import { systemAuditProcess } from '@/config/system';
import { reloadMixin } from '@/mixin';
export default {
  name: 'IndexAudit',
  components: {
    TableList,
    FormOrderAudit
  },
  mixins: [reloadMixin],
  data: () => ({
    loading: false,
    columnData: systemAuditProcess,
    form: {},
    tableData: []
  }),
  computed: {
    ...mapGetters({
      auditListData: 'system/index/auditListData' // 所有审核类型
    })
  },
  watch: {
    auditListData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleData(data) {
      this.tableData = data;
    },
    fetchData() {
      this.$store.dispatch('system/index/fetchAuditList', {});
    },
    editTableRow(row) {
      this.$refs.AuditRef.show(row);
    }
  }
};