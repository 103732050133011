export default {
  computed: {
    businessInfo() {
      return this.$store.getters['system/companyInfo/businessInfo'];
    },
    formConfigCodes() {
      return this.businessInfo ? this.businessInfo.formConfigCodes.split(',') : [];
    }
  },
  methods: {
    fetchSealImage(name, elName = '.print-page') {
      return this.$store.dispatch('system/companyInfo/fetchSealImage', {}).then(data => {
        if (!data) return;
        const names = data.formConfigCodes.split(',');
        if (names.includes(name)) {
          const el = document.querySelector(elName);
          const img = document.createElement('img');
          img.src = this.businessInfo.businessUrl;
          img.style.cssText = `position: absolute; right: 20px; top: 80%; z-index: 10; max-width: 200px; max-height: 200px; background: transparent;`;
          el.appendChild(img);
        }
      });
    }
  }
};