import TopTitle from '../components/TopTitle';
import { typeMixin } from '@/mixin';
import { Guides } from '@/components/common';
import { PersonCount } from '@/components/common';
const tableData = [{
  rows: [{
    label: '团号',
    span: 1
  }, {
    content: 'planNumber',
    span: 5
  }, {
    label: '线路',
    span: 2
  }, {
    content: 'adultCount',
    slot: 'number',
    span: 4
  }]
}, {
  rows: [{
    label: '计调',
    span: 1
  }, {
    content: 'dutyOPUserName',
    span: 2
  }, {
    label: '导游',
    span: 1
  }, {
    content: 'guides',
    span: 2,
    slot: 'guides'
  }, {
    label: '参团人数',
    span: 1
  }, {
    content: '',
    span: 1,
    slot: 'number'
  }, {
    label: '司机',
    span: 1
  }, {
    content: '',
    span: 3,
    slot: 'driver'
  }]
}];
const tableHead = [{
  content: '',
  label: '序号',
  slot: 'serialnumber'
}, {
  content: '',
  label: '小团号',
  span: 3,
  slot: 'customerTripNumber'
}, {
  content: '',
  label: '人数',
  span: 3,
  slot: 'people'
}, {
  content: 'name',
  label: '姓名',
  span: 2
}, {
  content: 'age',
  label: '年龄',
  span: 1
}, {
  content: 'sex',
  label: '性别',
  span: 1,
  slot: 'sex'
}, {
  content: 'location',
  label: '地区',
  span: 4
}, {
  content: 'mobilePhone',
  label: '手机号码',
  span: 2
}, {
  content: 'cardType',
  label: '证件类型',
  span: 2,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号码',
  span: 3
}, {
  content: 'remarks',
  label: '备注',
  span: 5
}];
export default {
  components: {
    TopTitle,
    PersonCount,
    Guides
  },
  mixins: [typeMixin],
  data() {
    return {
      data: null,
      tableData,
      tableHead
    };
  },
  computed: {
    getCardType() {
      return val => {
        return val == '0' ? '身份证' : val == '1' ? '护照' : '学生证';
      };
    },
    getSex() {
      return val => {
        return val == 0 ? '男' : '女';
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchPersonalNameList', id).then(data => {
        data.members = this.formatData(data.members);
        this.data = data;
        this.$emit('onMounted');
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const map = {};
      list.forEach(item => {
        if (!map[item.customerTripNumber]) {
          map[item.customerTripNumber] = 1;
        } else {
          map[item.customerTripNumber]++;
        }
      });
      let count = 0;
      return list.map(item => {
        if (item.customerTripNumber) {
          item.rowspan = map[item.customerTripNumber];
          if (map[item.customerTripNumber]) {
            count++;
            item.serialnumber = count;
            item.hasPass = true;
            delete map[item.customerTripNumber];
          }
        } else {
          item.rowspan = 1;
          item.hasPass = true;
        }
        return item;
      });
    }
  }
};