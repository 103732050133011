var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "80px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  }), _c("div", {
    staticClass: "flex flex-center",
    staticStyle: {
      "padding-left": "12px",
      color: "#38393b"
    }
  }, [_c("LabelBase", {
    attrs: {
      label: "行程日期"
    }
  }), _c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      format: "yyyy-MM-dd HH:mm:ss",
      "default-time": ["04:00:00", "04:00:00"]
    },
    on: {
      change: _vm.onChangeDate
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      disabled: !_vm.hasCustomerInfo
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  }), _c("InputBase", {
    attrs: {
      label: "游客信息",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.memberKey,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "memberKey", $$v);
      },
      expression: "searchParams.memberKey"
    }
  }), _c("InputBase", {
    attrs: {
      label: "航班/车次",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.shiftNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "shiftNumber", $$v);
      },
      expression: "searchParams.shiftNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      label: "任务类型",
      type: "taskTypeSearch",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.shuttleType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "shuttleType", $$v);
      },
      expression: "searchParams.shuttleType"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "订单类型",
      type: "orderType"
    },
    on: {
      change: _vm.changeOrderType
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "地点类型",
      type: "searchTargetType"
    },
    model: {
      value: _vm.searchParams.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "taskType", $$v);
      },
      expression: "searchParams.taskType"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "流水号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.orderNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderNumber", $$v);
      },
      expression: "searchParams.orderNumber"
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "线路"
    },
    model: {
      value: _vm.searchParams.productId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productId", $$v);
      },
      expression: "searchParams.productId"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "外联计调"
    },
    model: {
      value: _vm.searchParams.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserId", $$v);
      },
      expression: "searchParams.outOPUserId"
    }
  }), _c("BtnExport", {
    attrs: {
      "search-loading": _vm.loading
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.handleExport
    }
  })], 1)]), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogPickup", {
    ref: "dialogPickupRef"
  }), _c("DialogDownOrder", {
    ref: "dialogOrderRef"
  }), _c("DialogStation"), _c("DialogArrangePickup", {
    ref: "dialogArrangePickupRef"
  }), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.loading,
      statistic: _vm.statistic
    }
  }), _c("TableTotal", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      statistic: _vm.statistic,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "number",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistic) + " ")];
      }
    }])
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };