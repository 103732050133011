export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    log: {
      type: Boolean,
      default: true
    },
    btnTetx: {
      type: Array,
      default: () => ['取消', '审核']
    }
  }
};