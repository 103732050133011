var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "钉钉借款申请",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.handleCancel
    }
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "",
      label: "预付款类型"
    }
  }, [_c("strong", {
    staticStyle: {
      "font-size": "40px",
      color: "blue"
    }
  }, [_vm._v("导游借款申请")])]), _c("FormItemBase", {
    attrs: {
      prop: "applyUserId",
      label: "申请人"
    }
  }, [_c("SelectStaff", {
    model: {
      value: _vm.form.applyUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "applyUserId", $$v);
      },
      expression: "form.applyUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "",
      label: "申请单位"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "details",
      label: "付款明细"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea"
    },
    on: {
      change: function ($event) {
        _vm.content = $event;
      }
    },
    model: {
      value: _vm.form.details,
      callback: function ($$v) {
        _vm.$set(_vm.form, "details", $$v);
      },
      expression: "form.details"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "totalGuidePay",
      label: "申请总金额"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.totalGuidePay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalGuidePay", $$v);
      },
      expression: "form.totalGuidePay"
    }
  })], 1), _c("FormItemBase", [_c("el-radio-group", {
    model: {
      value: _vm.form.applyMode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "applyMode", $$v);
      },
      expression: "form.applyMode"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.APPLY_MODE.DEFAULT
    }
  }, [_vm._v(" 常规模式 ")])], 1)], 1), _vm.form.applyMode == _vm.APPLY_MODE.ALIPAY ? _c("FormItemBase", [_c("el-radio-group", {
    model: {
      value: _vm.alipayEnterCodeParams.mode,
      callback: function ($$v) {
        _vm.$set(_vm.alipayEnterCodeParams, "mode", $$v);
      },
      expression: "alipayEnterCodeParams.mode"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.ENTERPRISE_MODE.PLAN
    }
  }, [_vm._v(" 按单团分配额度 ")]), _c("el-radio", {
    attrs: {
      label: _vm.ENTERPRISE_MODE.RES
    }
  }, [_vm._v(" 按单位分配额度 ")])], 1), _c("span", {
    staticClass: "ml20"
  }), _vm.alipayEnterCodeParams.mode == _vm.ENTERPRISE_MODE.RES ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.showAllocation
    }
  }, [_vm._v(" 分配 ")]) : _vm._e()], 1) : _vm._e(), _vm.isAlipayMode && _vm.isAlipayRes ? _c("FormItemBase", [_vm.alipayEnterCodeParams.standards.length ? [_c("div", [_c("strong", [_vm._v("剩余额度：")]), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.getBalance))])]), _vm._l(_vm.alipayEnterCodeParams.standards, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm._v(" " + _vm._s(item.resName) + ": "), _c("span", {
      staticClass: "red"
    }, [_vm._v(_vm._s(item.value))])]);
  })] : _vm._e()], 2) : _vm._e(), _c("FormItemBase", {
    attrs: {
      prop: "bankName",
      label: "收款人户名"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", $$v);
      },
      expression: "form.bankName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "bank",
      label: "收款人开户行"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.bank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bank", $$v);
      },
      expression: "form.bank"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "bankNo",
      label: "收款人账号"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.bankNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankNo", $$v);
      },
      expression: "form.bankNo"
    }
  })], 1)], 1), _c("DialogAllocation", {
    ref: "dialogRef",
    on: {
      save: _vm.setStandards
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };