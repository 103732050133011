import TableNative from '@/components/common/Table/TableNative';
import RemarkList from '../../components/RemarkList';
import { typeMixin } from '@/mixin';
import utilsMixin from './utilsMixin';
export const columnInfo = [{
  attrs: {
    label: '产品名称',
    prop: 'productName',
    width: 80
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '团号',
    prop: 'planNumber',
    width: 50
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '人数',
    prop: 'number',
    align: 'center',
    width: 60
  },
  content: {
    width: 100
  },
  slot: 'number'
}, {
  attrs: {
    label: '用车单位',
    prop: 'busCompanyNames',
    width: 60
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '车型',
    prop: 'busCompanyTypes',
    width: 50
  },
  content: {
    width: 100
  },
  slot: 'busCompanyTypes'
}, {
  attrs: {
    label: '接团日期',
    prop: 'arriveDate'
  }
}, {
  attrs: {
    label: '接团航班车次',
    prop: 'arriveInto'
  }
}, {
  attrs: {
    label: '全陪',
    prop: 'accompanyInfo'
  }
}, {
  attrs: {
    label: '车号',
    prop: 'licenceNumbers'
  }
}, {
  attrs: {
    label: '车费',
    prop: 'busFees'
  },
  slot: 'busFees'
}, {
  attrs: {
    label: '送团日期',
    prop: 'leaveDate'
  },
  slot: 'leaveDate'
}, {
  attrs: {
    label: '送团航班车次',
    prop: 'leaveInto'
  }
}, {
  attrs: {
    label: '地陪',
    prop: 'guideName'
  }
}, {
  attrs: {
    label: '司机',
    prop: 'driverName'
  }
}, {
  attrs: {
    label: '付款方式',
    prop: 'busCompanyPayType'
  }
}, {
  attrs: {
    label: '接站牌',
    prop: 'welcomeBorad'
  }
}, {
  attrs: {
    label: '客服',
    prop: 'outOPUserName'
  }
}, {
  attrs: {
    label: '操作计调',
    prop: 'dutyOPUserName'
  }
}, {
  attrs: {
    label: '房调',
    prop: 'hotel'
  },
  slot: 'hotel'
}, {
  attrs: {
    label: '车调',
    prop: 'bus'
  },
  slot: 'bus'
}, {
  attrs: {
    label: '游客联系人',
    prop: 'members'
  }
}];
const columnOther = [{
  attrs: {
    label: '开票信息',
    prop: 'agencyInfoTaxInvoice',
    width: 80
  },
  slot: 'tax'
}, {
  attrs: {
    label: '票务',
    prop: 'agencyInfoTicketBusiness'
  }
}, {
  attrs: {
    label: '运营',
    prop: 'agencyInfoTrade'
  }
}, {
  attrs: {
    label: '客服',
    prop: 'agencyInfoService'
  }
}, {
  attrs: {
    label: '公司紧急',
    prop: 'agencyInfoUrgent'
  }
}];
export default {
  components: {
    TableNative,
    RemarkList
  },
  mixins: [typeMixin, utilsMixin],
  props: {
    mode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: null,
      columnOther,
      columnInfo
    };
  }
};