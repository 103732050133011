import { SelectFleet, SelectHotel, SelectOther, SelectScenic, SelectInsurance, SelectRestaurant, SelectTraffic, SelectGuide, SelectCustom, SelectShop } from '@/components/common';
const COMPONENT_MAP = {
  CUSTOM_SUBJECT: '',
  RESOURCE_OTHER_INCOME: 'SelectOther',
  RESOURCE_OTHER_PAY: 'SelectOther',
  RESOURCE_SCENIC: 'SelectScenic',
  RESOURCE_RESTAURANT: 'SelectRestaurant',
  RESOURCE_HOTEL: 'SelectHotel',
  RESOURCE_BUS_COMPANY: 'SelectFleet',
  RESOURCE_INSURANCE: 'SelectInsurance',
  RESOURCE_TRANSPORT_COMPANY: 'SelectTraffic',
  RESOURCE_GUIDE: 'SelectGuide',
  RESOURCE_SHOP: 'SelectShop',
  RESOURCE_CUSTOMER: 'SelectCustom'
};
export default {
  components: {
    SelectFleet,
    SelectHotel,
    SelectOther,
    SelectScenic,
    SelectInsurance,
    SelectRestaurant,
    SelectTraffic,
    SelectGuide,
    SelectCustom,
    SelectShop
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    visible: true,
    cashType: COMPONENT_MAP.RESOURCE_SCENIC
  }),
  computed: {
    compName() {
      return COMPONENT_MAP[this.cashType];
    }
  },
  methods: {
    selectType(val) {
      const {
        value
      } = val || {
        value: ''
      };
      this.cashType = value;
      this.form.cashType = value;
      this.visible = true;
      this.$emit('on-select', value);
    },
    selectResource(val) {
      const {
        value
      } = val;
      this.form.resourceId = value;
    },
    onClear() {
      this.visible = false;
      this.cashType = '';
      this.form.resourceName = '';
      this.$emit('clear');
    }
  }
};