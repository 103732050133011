import { orderReceiveStatus, orderReceiveTagStatus } from '@/config/order';
export default {
  name: 'StatusReceive',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    orderReceiveStatus,
    orderReceiveTagStatus
  })
};