const customerName = {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 120
  },
  slot: 'customerName'
};
const tripDate = {
  attrs: {
    prop: 'tripDate',
    label: '行程日期'
  },
  slot: 'tripDate'
};
const customerArea = {
  attrs: {
    prop: 'customerArea',
    label: '区域',
    'min-width': '160'
  }
};
const sales = {
  attrs: {
    prop: 'sales',
    label: '业务员',
    'min-width': 120
  },
  slot: 'sales'
};
const auditStatus = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态'
  },
  slot: 'auditStatus'
};
const guides = {
  attrs: {
    prop: 'guides',
    label: '团上导游',
    'min-width': 100
  },
  slot: 'guides'
};
const totalCustomerIncome = {
  attrs: {
    prop: 'totalCustomerIncome',
    label: '团款'
  }
};
const totalOutTransferSubsidyIncome = {
  attrs: {
    prop: 'totalOutTransferSubsidyIncome',
    label: '外转返款'
  }
};
const totalOtherIncome = {
  attrs: {
    prop: 'totalOtherIncome',
    label: '其他收入'
  }
};
const totalShopIncome = {
  attrs: {
    prop: 'totalShopIncome',
    label: '购物'
  }
};
const totalScenicExtraIncome = {
  attrs: {
    prop: 'totalScenicExtraIncome',
    label: '加点'
  }
};
const totalGuideManageFeeIncome = {
  attrs: {
    prop: 'totalGuideManageFeeIncome',
    label: '导游管理'
  }
};
const totalCustomerSubsidyPay = {
  attrs: {
    prop: 'totalCustomerSubsidyPay',
    label: '客户返款'
  }
};
const totalBusCompanyPay = {
  attrs: {
    prop: 'totalBusCompanyPay',
    label: '车队'
  }
};
const totalGuideServiceFeePay = {
  attrs: {
    prop: 'totalGuideServiceFeePay',
    label: '导服'
  }
};
const totalRestaurantPay = {
  attrs: {
    prop: 'totalRestaurantPay',
    label: '餐厅'
  }
};
const totalInsurancePay = {
  attrs: {
    prop: 'totalInsurancePay',
    label: '保险'
  }
};
const totalOutTransferPay = {
  attrs: {
    prop: 'totalOutTransferPay',
    label: '外转'
  }
};
const totalShuttlePay = {
  attrs: {
    prop: 'totalShuttlePay',
    label: '接送'
  }
};
const totalTransportCompanyPay = {
  attrs: {
    prop: 'totalTransportCompanyPay',
    label: '大交通'
  }
};
const totalOtherPay = {
  attrs: {
    prop: 'totalOtherPay',
    label: '其他支出'
  }
};
const totalShopRebatePay = {
  attrs: {
    prop: 'totalShopRebatePay',
    label: '购物佣金'
  }
};
const totalScenicExtraRebatePay = {
  attrs: {
    prop: 'totalScenicExtraRebatePay',
    label: '加点佣金'
  }
};
const totalIncome = {
  attrs: {
    prop: 'totalIncome',
    label: '总收入'
  }
};
const totalIncomed = {
  attrs: {
    prop: 'totalIncomed',
    label: '总已收'
  }
};
const teamTotalPay = {
  attrs: {
    prop: 'totalPayed',
    label: '总已付'
  }
};
const planCustomerNumber = {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': 150
  }
};
const adultCount = {
  attrs: {
    prop: 'adultCount',
    label: '大人',
    'min-width': 60
  }
};
const childCount = {
  attrs: {
    prop: 'childCount',
    label: '小孩',
    'min-width': 60
  }
};
const accompanyCount = {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪',
    'min-width': 60
  }
};
const operatorUser = {
  attrs: {
    prop: 'operatorUser',
    label: '计调',
    'min-width': 120
  },
  slot: 'operatorUser'
};

// 单团利润统计
export const profitPlan = [{
  ...planCustomerNumber,
  slot: 'planNumber'
}, customerName, sales, operatorUser, tripDate, {
  ...customerArea,
  slot: 'area'
}, auditStatus, guides, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [adultCount, childCount, accompanyCount]
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [totalCustomerIncome, totalOutTransferSubsidyIncome, totalOtherIncome, totalShopIncome, totalScenicExtraIncome, totalGuideManageFeeIncome]
}, {
  attrs: {
    prop: 'expenditure',
    label: '支出'
  },
  children: [totalCustomerSubsidyPay, totalBusCompanyPay, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '酒店'
    }
  }, totalGuideServiceFeePay, totalRestaurantPay, totalInsurancePay, totalOutTransferPay, totalShuttlePay, totalTransportCompanyPay, totalOtherPay, totalShopRebatePay, totalScenicExtraRebatePay, {
    attrs: {
      prop: 'totalScenicInnerQuotePay',
      label: '(景)行程内'
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraQuotePay',
      label: '(景)加点'
    }
  }]
}, {
  attrs: {
    prop: 'summation',
    label: '合计和毛利'
  },
  children: [totalIncome, totalIncomed, {
    attrs: {
      prop: 'totalQuotePay',
      label: '总成本'
    }
  }, teamTotalPay, {
    attrs: {
      prop: 'totalQuoteGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利'
    }
  }, {
    attrs: {
      prop: 'shopEndSubsidy',
      label: '购物店后返'
    }
  }, {
    attrs: {
      prop: 'customerShopSubsidy',
      label: '客户后返'
    }
  }, {
    attrs: {
      prop: 'subsidyQuoteGrossProfit',
      label: '最终毛利'
    }
  }, {
    attrs: {
      prop: 'subsidyQuoteGrossMargin',
      label: '最终人均'
    }
  }]
}, {
  attrs: {
    prop: 'totalShopBuyMoney',
    label: '购物打单'
  }
}];

// 单团利润统计优化表格宽度(二返)
export const profitPlanTerseAll = [{
  attrs: {
    ...planCustomerNumber.attrs,
    'min-width': 100
  },
  slot: 'planNumber'
}, {
  attrs: {
    ...customerName.attrs,
    'min-width': 100
  },
  slot: customerName.slot
}, tripDate, {
  attrs: {
    ...guides.attrs,
    'min-width': 60
  },
  slot: guides.slot
}, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: 'adultCount',
      label: '大',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'childCount',
      label: '小',
      'min-width': 25
    }
  }, {
    attrs: {
      prop: 'accompanyCount',
      label: '陪',
      'min-width': 25
    }
  }]
}, {
  attrs: {
    prop: 'summation',
    label: '合计和毛利'
  },
  children: [{
    attrs: {
      ...totalIncome.attrs,
      'min-width': 45
    }
  }, {
    attrs: {
      ...totalIncomed.attrs,
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'totalQuotePay',
      label: '总成本',
      'min-width': 45
    }
  }, {
    attrs: {
      ...teamTotalPay.attrs,
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossProfit',
      label: '毛利',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'shopEndSubsidy',
      label: '二返',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'customerShopSubsidy',
      label: '客户后返',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'subsidyQuoteGrossProfit',
      label: '最终毛利',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'subsidyQuoteGrossMargin',
      label: '最终人均',
      'min-width': 35
    }
  }]
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [{
    attrs: {
      ...totalCustomerIncome.attrs,
      'min-width': 45
    }
  }, {
    attrs: {
      ...totalOutTransferSubsidyIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalOtherIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalShopIncome.attrs,
      'min-width': 50
    }
  }, {
    attrs: {
      ...totalScenicExtraIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalGuideManageFeeIncome.attrs,
      'min-width': 30
    }
  }]
}, {
  attrs: {
    prop: 'expenditure',
    label: '支出'
  },
  children: [{
    attrs: {
      ...totalCustomerSubsidyPay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalBusCompanyPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '酒店',
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalGuideServiceFeePay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalRestaurantPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalInsurancePay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalOutTransferPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalShuttlePay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalTransportCompanyPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalOtherPay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalShopRebatePay.attrs,
      'min-width': 60
    }
  }, {
    attrs: {
      ...totalScenicExtraRebatePay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      prop: 'totalScenicInnerQuotePay',
      label: '(景)行程内',
      'min-width': 45
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraQuotePay',
      label: '(景)加点',
      'min-width': 40
    }
  }]
}];

// 单团利润统计
export const profitPlanTerse = [{
  attrs: {
    ...planCustomerNumber.attrs,
    'min-width': 100
  },
  slot: 'planNumber'
}, {
  attrs: {
    ...customerName.attrs,
    'min-width': 100
  },
  slot: customerName.slot
}, tripDate, {
  attrs: {
    ...guides.attrs,
    'min-width': 60
  },
  slot: guides.slot
}, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: 'adultCount',
      label: '大',
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'childCount',
      label: '小',
      'min-width': 25
    }
  }, {
    attrs: {
      prop: 'accompanyCount',
      label: '陪',
      'min-width': 25
    }
  }]
}, {
  attrs: {
    prop: 'summation',
    label: '合计和毛利'
  },
  children: [{
    attrs: {
      ...totalIncome.attrs,
      'min-width': 45
    }
  }, {
    attrs: {
      ...totalIncomed.attrs,
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'totalQuotePay',
      label: '总成本',
      'min-width': 45
    }
  }, {
    attrs: {
      ...teamTotalPay.attrs,
      'min-width': 35
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossProfit',
      label: '毛利',
      'min-width': 50
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利',
      'min-width': 48
    }
  }]
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [{
    attrs: {
      ...totalCustomerIncome.attrs,
      'min-width': 45
    }
  }, {
    attrs: {
      ...totalOutTransferSubsidyIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalOtherIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalShopIncome.attrs,
      'min-width': 50
    }
  }, {
    attrs: {
      ...totalScenicExtraIncome.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalGuideManageFeeIncome.attrs,
      'min-width': 30
    }
  }]
}, {
  attrs: {
    prop: 'expenditure',
    label: '支出'
  },
  children: [{
    attrs: {
      ...totalCustomerSubsidyPay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalBusCompanyPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '酒店',
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalGuideServiceFeePay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalRestaurantPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalInsurancePay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalOutTransferPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalShuttlePay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalTransportCompanyPay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      ...totalOtherPay.attrs,
      'min-width': 30
    }
  }, {
    attrs: {
      ...totalShopRebatePay.attrs,
      'min-width': 60
    }
  }, {
    attrs: {
      ...totalScenicExtraRebatePay.attrs,
      'min-width': 40
    }
  }, {
    attrs: {
      prop: 'totalScenicInnerQuotePay',
      label: '(景)行程内',
      'min-width': 45
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraQuotePay',
      label: '(景)加点',
      'min-width': 40
    }
  }]
}];