import DialogList from './DialogList';
import { SelectDictionary, SelectGuide, BtnLink, InputHotel, Drawer } from '@/components/common';
import { Members } from '@/components/common';
import { bookingRoomDetail } from '@/config/report';
export default {
  components: {
    SelectDictionary,
    SelectGuide,
    Members,
    BtnLink,
    InputHotel,
    DialogList,
    Drawer
  },
  data() {
    return {
      visible: false,
      direction: 'ltr',
      columnData: bookingRoomDetail,
      tableData: [],
      sendDate: [],
      orderDate: [],
      searchParams: {},
      hotel: '',
      hotelArea: '',
      hotelStandard: '',
      tableHeight: 500,
      total: 0
    };
  },
  methods: {
    fetchData(queryParams) {
      this.tableData = [];
      this.$store.dispatch('report/index/fetchOrderList', queryParams).then(data => {
        this.tableData = data.list;
        this.total = data.totalCount;
        this.calcTableHeight();
      });
    },
    show(data) {
      this.visible = true;
      const {
        hotelArea,
        hotelStandard
      } = data;
      const opts = {
        hotelArea,
        hotelStandard
      };
      this.searchParams.hotelArea = hotelArea;
      this.searchParams.hotelStandard = hotelStandard;
      this.hotelArea = hotelArea;
      this.hotelStandard = hotelStandard;
      this.fetchData(opts);
    },
    calcTableHeight() {
      this.$nextTick(() => {
        const {
          tableRef
        } = this.$refs;
        this.tableHeight = tableRef.calcTableHeight();
      });
    },
    changeHotel(val) {
      this.searchParams.hotelName = val;
      delete this.searchParams.hotelId;
    },
    selectHotel(val) {
      delete this.searchParams.hotelName;
      this.searchParams.hotelId = val ? val.id : '';
    },
    onChangeSendDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    onChangeOrderDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.orderDateS = startDate;
      this.searchParams.orderDateE = endDate;
    },
    handleChange(type, data) {
      this.searchParams[type] = data ? data.label : '';
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    arrangeRoom(row) {
      const {
        id,
        planId
      } = row;
      this.$refs.dialogRef.show({
        id,
        planId
      });
    }
  }
};