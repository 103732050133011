export const Columns = [{
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'total',
    label: '应付返款'
  }
}, {
  attrs: {
    prop: 'totaled',
    label: '已付返款'
  }
}, {
  attrs: {
    prop: 'unTotal',
    label: '未付返款'
  }
}];