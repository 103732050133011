var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("BoatLogin"), _c("h2", [_vm._v("上传游客名单")]), _c("FormItem", {
    ref: "formItemRef",
    attrs: {
      guide: _vm.getGuide,
      "is-update": _vm.isUpdate
    }
  }), _c("div", {
    staticClass: "flex flex-item-center"
  }, [_c("h2", [_vm._v("游客列表")]), _c("div", {
    staticClass: "ml20"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_vm._v(" 添加游客 ")])], 1)]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "touristName",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.touristName,
            callback: function ($$v) {
              _vm.$set(scope.row, "touristName", $$v);
            },
            expression: "scope.row.touristName"
          }
        })];
      }
    }, {
      key: "documentType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            label: "",
            type: "certBoatType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectCardType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.cardType,
            callback: function ($$v) {
              _vm.$set(scope.row, "cardType", $$v);
            },
            expression: "scope.row.cardType"
          }
        })];
      }
    }, {
      key: "documentNo",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.documentNo,
            callback: function ($$v) {
              _vm.$set(scope.row, "documentNo", $$v);
            },
            expression: "scope.row.documentNo"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _c("div", {
    staticClass: "flex flex-center footer-bar"
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      plain: ""
    },
    on: {
      click: _vm.close
    }
  }, [_vm._v(" 关闭 ")]), _vm.isUpdate ? _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.delBoat
    }
  }, [_vm._v(" 删除 ")]) : _vm._e(), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "确认更新" : "确认上传") + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };