import { orderAuditStatus } from '@/config/report';
import { orderType } from '@/config/order';
import { SaleUsers } from '@/components/common';
import { handleSumAvg } from '@/utils';
import { ClassKeys1, ClassKeys2, ClassKeys3, ClassKeys4, AvgeageObj, StatisticsKeys } from '../config';
import { Columns } from '../tableConfig';
export default {
  components: {
    SaleUsers
  },
  data() {
    return {
      Columns,
      statisticsKeys: StatisticsKeys,
      orderType
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/index/teamProfit2'];
    },
    tableData() {
      const data = this.data;
      return data ? data.list : [];
    },
    statistics() {
      const data = this.data;
      return data ? data.statistics : null;
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    }
  },
  methods: {
    handleSummary(args) {
      return handleSumAvg({
        ...args,
        keyObj: AvgeageObj,
        statistics: this.statistics
      });
    },
    planStatus(val) {
      let status = '';
      orderAuditStatus.forEach(it => {
        if (val == it.value) {
          status = it.label;
        }
      });
      return status;
    },
    changeCellClassName(row) {
      const {
        property
      } = row.column;

      //列的label的名称
      if (ClassKeys1.includes(property)) {
        return 'pale-yellow'; //修改的样式
      }
      if (ClassKeys2.includes(property)) {
        return 'baby-blue'; //修改的样式
      }
      if (ClassKeys3.includes(property)) {
        return 'light-red'; //修改的样式
      }
      if (ClassKeys4.includes(property)) {
        return 'pale-green'; //修改的样式
      }
      return '';
    }
  }
};