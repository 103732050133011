var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单列表-车队",
      width: "90%",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "plan-order-dialog"
  }, [_c("TableBase", {
    attrs: {
      border: "",
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "show-summary": ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripInfo", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("Contact", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("Custom", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "shuttle",
      fn: function ({
        scope
      }) {
        return [_c("Shuttle", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "price", $event);
            }
          },
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "count", $event);
            }
          },
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "otherFee", $event);
            }
          },
          model: {
            value: scope.row.otherFee,
            callback: function ($$v) {
              _vm.$set(scope.row, "otherFee", $$v);
            },
            expression: "scope.row.otherFee"
          }
        })];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope.row.id);
            }
          }
        }, [_vm._v(" 移除 ")])];
      }
    }])
  })], 1), _c("FooterBar", {
    attrs: {
      "table-data": _vm.tableData
    },
    on: {
      "on-dialog": _vm.handleOrderDialog,
      "on-save": _vm.handleSave,
      "on-cancel": _vm.hide
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };