var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "游客名单",
      width: "80%",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: "",
      height: "500px"
    },
    scopedSlots: _vm._u([{
      key: "cardType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getCardType(scope.row.cardType)) + " ")];
      }
    }, {
      key: "sex",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.sex ? "女" : "男") + " ")];
      }
    }, {
      key: "isContact",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.isContact ? "是" : "否") + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };