var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "login_container"
    }
  }, [_c("iframe", {
    attrs: {
      sandbox: "allow-scripts allow-top-navigation allow-same-origin",
      scrolling: "no",
      width: "300",
      height: "400",
      frameBorder: "0",
      allowTransparency: "true",
      src: _vm.setSrc
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };