var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "pickupPrint"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "游客接送表"
    }
  }), _vm.teamData ? _c("div", {
    staticClass: "content"
  }, [_c("TableSingle", {
    attrs: {
      data: _vm.teamData,
      "column-data": _vm.columnData1,
      title: "基础信息"
    }
  }, [_c("PersonCount", {
    attrs: {
      slot: "number",
      row: _vm.teamData
    },
    slot: "number"
  }), _vm.teamData.guides && _vm.teamData.guides.length ? _c("Guides", {
    attrs: {
      slot: "guide",
      row: _vm.teamData
    },
    slot: "guide"
  }) : _c("span", {
    attrs: {
      slot: "guide"
    },
    slot: "guide"
  }, [_vm._v("无")]), _vm.teamData.buses && _vm.teamData.buses.length ? _c("div", {
    attrs: {
      slot: "driver"
    },
    slot: "driver"
  }, [_vm._v(" " + _vm._s(_vm.getDriver) + " ")]) : _c("span", {
    attrs: {
      slot: "driver"
    },
    slot: "driver"
  }, [_vm._v("无")])], 1), _c("TableTpl", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData2,
      "row-title": "小组接送列表"
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        data
      }) {
        return _c("div", {}, [_c("CustomerInfo", {
          attrs: {
            name: data.customerName
          }
        })], 1);
      }
    }, {
      key: "tripInfo",
      fn: function ({
        data
      }) {
        return _c("div", {}, _vm._l(data.shuttleItineraries, function (trip) {
          return _c("div", {
            key: trip.id
          }, [_c("span", [_vm._v(_vm._s(trip.itineraryDate))]), trip.isPick ? _c("span", [_vm._v("接团")]) : _vm._e(), trip.isSend ? _c("span", [_vm._v("送团")]) : _vm._e()]);
        }), 0);
      }
    }, {
      key: "pickupInfo",
      fn: function ({
        data
      }) {
        return _c("div", {}, _vm._l(data.shuttles, function (shuttle) {
          return _c("div", {
            key: shuttle.id
          }, [_c("span", [_vm._v(_vm._s(_vm.getShuttleType(shuttle.taskType)))]), _c("span", [_vm._v(_vm._s(_vm.getShuttleTime(shuttle.shiftDateTime)))]), _c("span", [_vm._v(" " + _vm._s(shuttle.shiftNumber) + " " + _vm._s(shuttle.desPosition) + " ~ " + _vm._s(shuttle.endPosition) + " ")])]);
        }), 0);
      }
    }, {
      key: "members",
      fn: function ({
        data
      }) {
        return _c("div", {}, _vm._l(data.members, function (member) {
          return _c("div", {
            key: member.id
          }, [_c("span", [_vm._v(_vm._s(member.name))]), _c("span", [_vm._v(_vm._s(member.mobilePhone))])]);
        }), 0);
      }
    }, {
      key: "rooms",
      fn: function ({
        data
      }) {
        return _c("div", {}, _vm._l(data.hotelOS, function (hotel) {
          return _c("div", {
            key: hotel.id
          }, [_c("span", [_vm._v(_vm._s(hotel.orderDate))]), _c("span", [_vm._v(_vm._s(hotel.hotelArea))]), _c("span", [_vm._v(_vm._s(hotel.hotelStandard))]), _c("span", [_vm._v(_vm._s(hotel.hotelName))]), _c("span", [_vm._v(_vm._s(hotel.count) + "间")])]);
        }), 0);
      }
    }, {
      key: "remarks",
      fn: function ({
        data
      }) {
        return _c("div", {}, _vm._l(data.remarks, function (remark, rIdx) {
          return _c("div", {
            key: rIdx
          }, [_c("span", [_vm._v(_vm._s(remark))])]);
        }), 0);
      }
    }], null, false, 3784427993)
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };