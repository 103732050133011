var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "confirm-group",
    attrs: {
      title: "拼团安排信息确认",
      width: "80%",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("ul", {
    staticClass: "list"
  }, [_c("li", {
    staticClass: "list-item"
  }, [_c("div", {
    staticClass: "date"
  }, [_c("h3", [_vm._v("日期")]), _c("div", [_c("div", {
    staticClass: "radio-box"
  }), _c("div", {
    staticClass: "radio-box"
  })])]), _c("div", {
    staticClass: "hotel tac"
  }, [_c("h3", [_vm._v("酒")]), _c("div", [_c("div", {
    staticClass: "radio-box"
  }), _c("div", {
    staticClass: "radio-box tal"
  }, [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.changeAll("hotelSelf", "hotelCustom", $event);
      }
    },
    model: {
      value: _vm.hotelSelf,
      callback: function ($$v) {
        _vm.hotelSelf = $$v;
      },
      expression: "hotelSelf"
    }
  }, [_vm._v(" 全选 ")])], 1)])]), _c("div", {
    staticClass: "scenic tac"
  }, [_c("h3", [_vm._v("景")]), _c("div", [_c("div", {
    staticClass: "radio-box"
  }), _c("div", {
    staticClass: "radio-box tal"
  }, [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.changeAll("scenicSelf", "scenicCustom", $event);
      }
    },
    model: {
      value: _vm.scenicSelf,
      callback: function ($$v) {
        _vm.scenicSelf = $$v;
      },
      expression: "scenicSelf"
    }
  }, [_vm._v(" 全选 ")])], 1)])]), _c("div", {
    staticClass: "restaurant tac"
  }, [_c("h3", [_vm._v("餐")]), _c("div", [_c("div", {
    staticClass: "radio-box"
  }), _c("div", {
    staticClass: "radio-box tal"
  }, [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.changeAll("restaurantSelf", "restaurantCustom", $event);
      }
    },
    model: {
      value: _vm.restaurantSelf,
      callback: function ($$v) {
        _vm.restaurantSelf = $$v;
      },
      expression: "restaurantSelf"
    }
  }, [_vm._v(" 全选 ")])], 1)])]), _c("div", {
    staticClass: "bus tac"
  }, [_c("h3", [_vm._v("车")]), _c("div", [_c("div", {
    staticClass: "radio-box"
  }), _c("div", {
    staticClass: "radio-box tal"
  }, [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.changeAll("busSelf", "busCustom", $event);
      }
    },
    model: {
      value: _vm.busSelf,
      callback: function ($$v) {
        _vm.busSelf = $$v;
      },
      expression: "busSelf"
    }
  }, [_vm._v(" 全选 ")])], 1)])])]), _vm._l(_vm.data, function (item, index) {
    return _c("li", {
      key: item.id + "" + index,
      staticClass: "list-item"
    }, [_c("div", {
      staticClass: "date"
    }, [_c("span", [_vm._v(_vm._s(item.tripDate))])]), _c("div", {
      staticClass: "hotel"
    }, [_c("div", {
      staticClass: "radio-box"
    }, [_vm.hasMore(item.hotels) ? _c("PopoverTip", {
      attrs: {
        content: _vm.getName(item.hotels),
        title: "酒店"
      }
    }, [_c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "hotelSelf");
        }
      },
      model: {
        value: item.hotelSelf,
        callback: function ($$v) {
          _vm.$set(item, "hotelSelf", $$v);
        },
        expression: "item.hotelSelf"
      }
    }, [_vm._v(" " + _vm._s(_vm.splitName(item.hotels[0], "hotelName")) + " ")])], 1) : _c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "hotelSelf");
        }
      },
      model: {
        value: item.hotelSelf,
        callback: function ($$v) {
          _vm.$set(item, "hotelSelf", $$v);
        },
        expression: "item.hotelSelf"
      }
    }, [_vm._v(" 无 ")])], 1), _c("div", {
      staticClass: "radio-box"
    }, [_c("el-radio", {
      attrs: {
        label: "hotelCustom"
      },
      on: {
        change: function ($event) {
          return _vm.radioCustom(item, "hotelSelf", "hotelCustom");
        }
      },
      model: {
        value: item.hotelSelf,
        callback: function ($$v) {
          _vm.$set(item, "hotelSelf", $$v);
        },
        expression: "item.hotelSelf"
      }
    }, [_vm._v(" 自行安排 ")])], 1)]), _c("div", {
      staticClass: "scenic"
    }, [_c("div", {
      staticClass: "radio-box"
    }, [_vm.hasMore(item.scenices) ? _c("PopoverTip", {
      attrs: {
        content: _vm.getName(item.scenices),
        title: "景区"
      }
    }, [_c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "scenicSelf");
        }
      },
      model: {
        value: item.scenicSelf,
        callback: function ($$v) {
          _vm.$set(item, "scenicSelf", $$v);
        },
        expression: "item.scenicSelf"
      }
    }, [_vm._v(" " + _vm._s(_vm.splitName(item.scenices[0])) + " ")])], 1) : _c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "scenicSelf");
        }
      },
      model: {
        value: item.scenicSelf,
        callback: function ($$v) {
          _vm.$set(item, "scenicSelf", $$v);
        },
        expression: "item.scenicSelf"
      }
    }, [_vm._v(" 无 ")])], 1), _c("div", {
      staticClass: "radio-box"
    }, [_c("el-radio", {
      attrs: {
        label: "scenicCustom"
      },
      on: {
        change: function ($event) {
          return _vm.radioCustom(item, "scenicSelf", "scenicCustom");
        }
      },
      model: {
        value: item.scenicSelf,
        callback: function ($$v) {
          _vm.$set(item, "scenicSelf", $$v);
        },
        expression: "item.scenicSelf"
      }
    }, [_vm._v(" 自行安排 ")])], 1)]), _c("div", {
      staticClass: "restaurant"
    }, [_c("div", {
      staticClass: "radio-box"
    }, [_vm.hasMore(item.restaurants) ? _c("PopoverTip", {
      attrs: {
        content: _vm.getName(item.restaurants),
        title: "餐厅"
      }
    }, [_c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "restaurantSelf");
        }
      },
      model: {
        value: item.restaurantSelf,
        callback: function ($$v) {
          _vm.$set(item, "restaurantSelf", $$v);
        },
        expression: "item.restaurantSelf"
      }
    }, [_vm._v(" " + _vm._s(_vm.splitName(item.restaurants[0])) + " ")])], 1) : _c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "restaurantSelf");
        }
      },
      model: {
        value: item.restaurantSelf,
        callback: function ($$v) {
          _vm.$set(item, "restaurantSelf", $$v);
        },
        expression: "item.restaurantSelf"
      }
    }, [_vm._v(" 无 ")])], 1), _c("div", {
      staticClass: "radio-box"
    }, [_c("el-radio", {
      attrs: {
        label: "restaurantCustom"
      },
      on: {
        change: function ($event) {
          return _vm.radioCustom(item, "restaurantSelf", "restaurantCustom");
        }
      },
      model: {
        value: item.restaurantSelf,
        callback: function ($$v) {
          _vm.$set(item, "restaurantSelf", $$v);
        },
        expression: "item.restaurantSelf"
      }
    }, [_vm._v(" 自行安排 ")])], 1)]), _c("div", {
      staticClass: "bus"
    }, [_c("div", {
      staticClass: "radio-box"
    }, [_vm.hasMore(item.busCompanys) ? _c("PopoverTip", {
      attrs: {
        content: _vm.getName(item.busCompanys),
        title: "车队"
      }
    }, [_c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "busSelf");
        }
      },
      model: {
        value: item.busSelf,
        callback: function ($$v) {
          _vm.$set(item, "busSelf", $$v);
        },
        expression: "item.busSelf"
      }
    }, [_vm._v(" " + _vm._s(_vm.splitName(item.restaurants[0])) + " ")])], 1) : _c("el-radio", {
      attrs: {
        label: item.id
      },
      on: {
        change: function ($event) {
          return _vm.radioSelect(item, "busSelf");
        }
      },
      model: {
        value: item.busSelf,
        callback: function ($$v) {
          _vm.$set(item, "busSelf", $$v);
        },
        expression: "item.busSelf"
      }
    }, [_vm._v(" 无 ")])], 1), _c("div", {
      staticClass: "radio-box"
    }, [_c("el-radio", {
      attrs: {
        label: "busCustom"
      },
      on: {
        change: function ($event) {
          return _vm.radioCustom(item, "busSelf", "busCustom");
        }
      },
      model: {
        value: item.busSelf,
        callback: function ($$v) {
          _vm.$set(item, "busSelf", $$v);
        },
        expression: "item.busSelf"
      }
    }, [_vm._v(" 自行安排 ")])], 1)])]);
  })], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };