import UploadExcelComponent from '@/components/UploadExcel';
// import { resourceAgreementScenic } from '@/config/resource'

export default {
  components: {
    UploadExcelComponent
  },
  data() {
    return {
      visible: false,
      tableData: []
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 5;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: '上传的文件超出5M限制范围',
        type: 'warning'
      });
      return false;
    },
    handleSuccess({
      header
    }) {
      this.tableHeader = header;
      // results.forEach(row => {
      //   // let obj = {}

      //   Object.keys(row).map(key => {
      //     key = key.toString()
      //     // const val = row[key]

      //     // resourceAgreementScenic.forEach(it => {
      //     // })
      //   })

      //   // this.tableData.push(obj)
      // })
    }
  }
};