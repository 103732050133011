import { BtnLink, PersonCount, Members } from '@/components/common';
import { getOrderType, getReceiveStatusType } from '@/utils/data';
import { orderReceiveTagStatus } from '@/config/order';
import { tableMixin, permissionMixin } from '@/mixin';
import { AcceptStatus } from '@/config/order/turnIn';
export default {
  components: {
    PersonCount,
    Members,
    BtnLink
  },
  mixins: [tableMixin, permissionMixin],
  data() {
    return {
      getOrderType,
      getReceiveStatusType
    };
  },
  computed: {
    setReceiveStatusTag() {
      return val => orderReceiveTagStatus[val];
    },
    hasReceive() {
      return val => val == 'ACCEPT';
    },
    getStatus() {
      return val => AcceptStatus[val];
    }
  },
  methods: {
    handleReject(id) {
      this.$bus.open(() => {
        this.$store.dispatch('order/receive/refuse', id);
      }, '是否确认拒绝该订单');
    },
    handleConfirm(id) {
      this.$bus.open(() => {
        this.$store.dispatch('order/receive/confirm', id);
      }, '是否确认接收该订单');
    },
    handleBack(id) {
      this.$bus.open(() => {
        this.$store.dispatch('order/receive/back', id);
      }, '是否确认退回该订单');
    },
    showMessage(row) {
      this.$emit('message', row);
    },
    handleShow(id) {
      this.$emit('show', id);
    },
    createOrder(row) {
      this.$bus.open(() => {
        // this.$store.dispatch('order/receive/confirm', row.id)
        this.$emit('create', row);
      }, '是否确认接收该订单');
    },
    tableRowClassName({
      row
    }) {
      if (row.isModify) {
        return 'notice-row';
      }
      return '';
    }
  }
};