import "core-js/modules/es.array.push.js";
export default {
  methods: {
    linkDetail(row) {
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType
        }
      });
    }
  }
};