import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/customer/findList',
    method: 'get',
    params
  });
}

// 客户联系人列表 不分页
export function fetchCustomList(params) {
  return request({
    url: '/resource/customer/findContractList',
    method: 'get',
    params
  });
}

// 列表
export function fetchCustom(params) {
  return request({
    url: '/resource/customer/listCustomerAndContact',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateCustom(data) {
  return request({
    url: '/resource/customer/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteCustom(id) {
  return request({
    url: '/resource/customer/del',
    method: 'post',
    data: {
      customerId: id
    }
  });
}

// 联系人列表
export function fetchCustomContact(params) {
  return request({
    url: '/resource/customer/pageContact',
    method: 'post',
    params
  });
}

// 联系人更新
export function createOrUpdateCustomContact(data) {
  return request({
    url: '/resource/customer/saveOrUpdateContact',
    method: 'post',
    data
  });
}

// 联系人删除
export function deleteCustomContact(id) {
  return request({
    url: '/resource/customer/delContact',
    method: 'post',
    data: {
      customerContactId: id
    }
  });
}

// 获取客户详细信息
export function fetchCustomDetail(id) {
  return request({
    url: `/resource/customer/getCustomerById?customerId=${id}`,
    method: 'get'
  });
}

// 获取联系人信息
export function fetchCustomContactInfo(id) {
  return request({
    url: `/resource/customer/getCustomerContact?contactId=${id}`,
    method: 'get'
  });
}

// 设置临时额度
export function setTmpCreditRequest(data) {
  return request({
    url: `resource/customer/update-tmp-credit`,
    method: 'post',
    data
  });
}