import { getDaysBetween, getUserData } from '@/utils';
import { rules, defaultForm, StatusType } from '../config';
export default {
  data() {
    return {
      rules,
      visible: false,
      form: defaultForm(),
      date: [],
      arriveDate: [],
      title: '新增',
      status: StatusType.add
    };
  },
  computed: {
    getTitle() {
      return this.title + '车辆报价';
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    edit(row) {
      this.show();
      this.title = '编辑';
      this.form = {
        ...defaultForm(),
        ...row
      };
      this.date = [row.arriveDate, row.leaveDate];
      this.status = StatusType.edit;
      this.changeDate(this.date);
    },
    copy(row) {
      this.edit(row);
      this.title = '复制';
      this.status = StatusType.copy;
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    changeDate(val) {
      const [arriveDate, leaveDate] = val || ['', ''];
      this.form = {
        ...this.form,
        arriveDate,
        leaveDate
      };
      if (arriveDate && leaveDate) {
        const days = getDaysBetween(arriveDate, leaveDate);
        this.form.useDays = days;
      }
    },
    handleSave() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const opts = {
          ...this.form,
          inquiryUser: getUserData().realName
        };
        this.status == StatusType.copy ? delete opts.id : null;
        this.$store.dispatch('inquire/bus/set', opts).then(() => {
          this.$bus.tip();
          this.hide();
          this.$emit('reload');
        });
      });
    }
  }
};