import { mapGetters } from 'vuex';
export default {
  props: {
    showImage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      info: null,
      attr: {},
      images: []
    };
  },
  computed: {
    ...mapGetters({
      imageList: 'resource/common/imageList' // 照片信息
    }),
    getTitle() {
      return this.attr.title ? this.attr.title : '详情';
    }
  },
  watch: {
    imageList: {
      handler: 'initImage'
    }
  },
  methods: {
    initImage(list) {
      this.images = list;
    },
    show(row, attr) {
      this.visible = true;
      this.info = row;
      this.attr = attr;
    },
    hide() {
      this.visible = false;
      this.$store.dispatch('resource/common/clearImage');
    }
  }
};