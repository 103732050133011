import "core-js/modules/es.array.reduce.js";
import Hotel from './Hotel';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import DialogHotelTotel from './DialogHotelTotel';
import { TopBar } from '@/components/common';
import { auditMixin, auditPropMixin, planMixin } from '@/mixin';
export default {
  components: {
    Hotel,
    DialogOrder,
    DialogList,
    DialogHotelTotel,
    TopBar
  },
  mixins: [auditMixin, auditPropMixin, planMixin],
  data() {
    return {
      tableData: [],
      customerList: [],
      tableDataNumber: [],
      hasDataStatusKey: 'hotelOrderStatus',
      totalBtnShow: false,
      visible: true,
      planId: '',
      fetchPath: 'plan/hotel/fetchAll',
      billCount: 0 // 单据数量
    };
  },
  computed: {
    planOrderData() {
      return this.$store.getters['plan/index/planOrderData'];
    }
  },
  watch: {
    planOrderData: {
      handler: function (data) {
        if (!data || !data.length) return;
        this.tableData = this.filterHotelInfo(this.tableData, data);
      },
      immediate: true
    }
  },
  created() {
    const {
      planId
    } = this.$route.query;
    const {
      path
    } = this.$route;
    if (path == '/audit/team-single-edit/hotel') {
      this.totalBtnShow = true;
    }
    this.planId = planId;
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = [...data];
      this.tableData = this.filterHotelInfo(this.tableData, this.planOrderData);
      // // 合并其他收入
      // this.tableData = this.calcMergeRow(
      //   this.tableData,
      //   ['otherFee'],
      //   'roomList'
      // )

      this.tableDataNumber = this.formatTotalNumber([...data]);
      this.toggleVisible();
    },
    toggleVisible() {
      this.visible = false;
      setTimeout(() => {
        this.visible = true;
      }, 20);
    },
    filterHotelInfo(list, list2) {
      if (!list || !list.length) return [];
      if (!list2 || !list2.length) return list;
      return list.map(it => {
        const filteItem = list2.find(v => it.customerOrderId == v.id);
        it.roomInfo = filteItem ? filteItem.roomInfo : null;
        return it;
      });
    },
    showMembers(row) {
      this.$refs.orderRef.show(row);
    },
    handleTotalBox() {
      this.$refs.hotelTotelRef.show(this.tableData);
    },
    handlePlus() {
      this.$refs.hotelRef.handlePlus();
    },
    formatTotalNumber(list) {
      const copyList = list.map(it => {
        const {
          orderDate,
          orderPeople,
          roomList
        } = it;
        const {
          adultCount,
          childCount
        } = orderPeople;
        const totalCount = roomList.reduce((acc, cur) => {
          return acc + cur.count;
        }, 0);
        return {
          orderDate,
          adultCount,
          childCount,
          totalCount
        };
      });
      const mapObj = copyList.reduce((acc, cur) => {
        const {
          adultCount,
          childCount,
          totalCount
        } = cur;
        if (!acc[cur.orderDate]) {
          acc[cur.orderDate] = {
            ...cur
          };
        } else {
          let item = acc[cur.orderDate];
          item.adultCount += adultCount;
          item.childCount += childCount;
          item.totalCount += totalCount;
        }
        return acc;
      }, {});
      const result = Object.keys(mapObj).map(key => mapObj[key]);
      return result;
    },
    // formatData(data) {
    //   return data.map(it => {
    //     it.rowTotal = 0
    //   })
    // },

    createOrUpdate() {
      return this.$refs.hotelRef.createOrUpdate();
    },
    saveAndAudit() {
      return this.$refs.hotelRef.saveAndAudit();
    }
  }
};