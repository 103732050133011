import { inputMixin, selectListMixin } from '@/mixin';
export default {
  name: 'SelectRole',
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data() {
    return {
      fetchPath: 'system/role/fetchRole',
      page: {
        pageNum: 1,
        pageSize: 20
      },
      dataList: []
    };
  },
  computed: {
    roleData() {
      return this.$store.getters['system/role/roleSelectData'];
    },
    data() {
      return this.dataList.length <= 0 ? null : this.dataList;
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('system/role/fetchRole', params).then(data => {
        this.dataList = [...this.dataList, ...data.list];
      });
    },
    loadMoreData() {
      if (!this.roleData) return;
      if (this.dataList.length < this.roleData.totalCount) {
        this.page.pageNum++;
        this.fetchData(this.page);
      }
    }
  }
};