export const COLUMNS = [{
  attrs: {
    prop: '',
    label: '企业码支付日期'
  }
}, {
  attrs: {
    prop: '',
    label: '(一）'
  }
}, {
  attrs: {
    prop: '',
    label: '(二）'
  }
}, {
  attrs: {
    prop: '',
    label: '(三）'
  }
}, {
  attrs: {
    prop: '',
    label: '(四）'
  }
}, {
  attrs: {
    prop: '',
    label: '(五）'
  }
}, {
  attrs: {
    prop: '',
    label: '(六）'
  }
}, {
  attrs: {
    prop: '',
    label: '(日）'
  }
}];
export const COLUMNS_RECORD = [{
  attrs: {
    prop: 'seqNumber',
    label: '序号',
    width: 80
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'applyMoney',
    label: '发放额度'
  }
},
// { attrs: { prop: '', label: '开户银行' } },
// { attrs: { prop: 'bankName', label: '银行账号' } },
{
  attrs: {
    prop: 'applyUserName',
    label: '操作人员'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注信息'
  }
}, {
  attrs: {
    prop: '$createTime',
    label: '充值时间'
  }
}];