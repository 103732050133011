import "core-js/modules/es.array.push.js";
import { Search } from './components';
import { Columns as columns } from './tableConfig';
import { StatisticsKeys } from './config';
import { StatusAudit, DialogLog, BtnLink, FooterBar } from '@/components/common';
import { TYPES } from '@/config/audit';
import { downloadExport } from '@/utils';
import { processAdd, processMinus, formatCreateTimeToList } from '@/utils';
import { tagViewMixin } from '@/mixin';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    Search,
    StatusAudit,
    DialogLog,
    BtnLink,
    FooterBar
  },
  mixins: [tagViewMixin],
  data() {
    return {
      columns,
      calcCheckedMoney,
      checkType: TYPES.fee,
      tableData: [],
      dateArr: [],
      allCheckbox: false,
      StatisticsKeys
    };
  },
  computed: {
    data() {
      return this.$store.getters['billCheck/channel/channelData'];
    },
    dataList() {
      return this.data ? this.data.list : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : null;
    },
    getFeeInfo() {
      return item => {
        const {
          type,
          count,
          price,
          times
        } = item;
        const total = count * price * times;
        return `${type}: ${count}×${price}×${times}=${total}`;
      };
    },
    getAuditType() {
      return val => val !== 'WAITAUDIT';
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (d) {
          this.tableData = this.formatData(this.dataList);
        }
      },
      immediate: true
    }
  },
  methods: {
    processMinus,
    fetchData(params = {}) {
      this.$store.dispatch('billCheck/channel/list', {
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    getFeeUnPay(row) {
      const {
        totalFee,
        totalCollect,
        totalIncomed
      } = row;
      return processMinus(totalFee, processAdd(totalCollect, totalIncomed));
    },
    getUnSubsidy(row) {
      const {
        totalSubsidy,
        totalSubsidyed
      } = row;
      return processMinus(totalSubsidy, totalSubsidyed);
    },
    getTotalUnPay(row) {
      const {
        totalSubsidy,
        totalSubsidyed
      } = row;
      return processMinus(totalSubsidy, totalSubsidyed);
    },
    formatData(data) {
      const list = formatCreateTimeToList(data, 'checkAccountTime');
      return list.map(it => {
        const {
          totalFee,
          checkAccountMoney,
          checkAccountStatus
        } = it;
        it.checkAccountMoney = checkAccountStatus ? checkAccountMoney : totalFee;
        it.rowId = it.id;
        this.$set(it, 'ckStatus', false);
        return it;
      });
    },
    checkAllSelect() {
      this.allCheckbox = this.tableData.every(it => it.ckStatus);
    },
    selectAllCheckbox(val) {
      this.tableData = this.tableData.map(it => {
        it.ckStatus = val;
        return it;
      });
    },
    clickAllCheckbox() {
      this.allCheckbox = !this.allCheckbox;
      this.selectAllCheckbox(this.allCheckbox);
    },
    linkDetail(row) {
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/audit/team-single-detail',
        query: {
          id,
          planType
        }
      });
    },
    showLog(row) {
      const {
        id: itemId
      } = row;
      this.$store.dispatch('billCheck/index/fetchLog', {
        itemId,
        subType: this.checkType
      }).then(data => {
        this.$refs.dialogLogRef.show(data);
      });
    },
    processData(row) {
      const {
        id,
        rowId,
        checkAccountRemark,
        checkAccountMoney,
        checkAccountStatus
      } = row;
      return {
        auditTypeEnum: this.checkType,
        checkAccountRemark,
        checkAccountMoney,
        resourceId: rowId || id,
        status: +!checkAccountStatus
      };
    },
    handleClose() {
      this.closeSelectedTag();
    },
    // 单条对账
    handleCheckout(row) {
      const opts = this.processData(row);
      this.updateBillCheck([opts]);
    },
    // 批量对账
    handleCheck() {
      const filterList = this.tableData.filter(it => it.ckStatus);
      const optList = filterList.map(it => this.processData(it));
      this.updateBillCheck(optList);
    },
    updateBillCheck(dataList) {
      const loading = this.$bus.loading();
      return this.$store.dispatch('billCheck/index/billCheck', dataList).then(() => {
        this.$bus.tip();
        loading.close();
        this.reload();
      }).catch(err => {
        this.$bus.tip({
          type: 'error',
          message: err.message
        });
        loading.close();
      });
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('billCheck/channel/export', {
        ...this.search,
        ...params
      }).then(res => {
        const fileName = '渠道应收对账表.xlsx';
        downloadExport(res, fileName);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};