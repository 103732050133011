// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".count-down {\n  height: 47px;\n  line-height: 47px;\n  padding: 0 10px;\n  border-radius: 0 2px 2px 0;\n  background: #b40f0f;\n  color: #fff;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n.count-down.disabled {\n  background-image: none;\n  color: #fff;\n  background-color: #fab6b6;\n  border-color: #fab6b6;\n  cursor: not-allowed;\n}\n.count-down .count-down-text {\n  margin-right: 5px;\n}", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "170px",
	"topNavHeight": "56px",
	"labelSize": "12px",
	"red": "#c03639",
	"green": "#30b08f"
};
module.exports = exports;
