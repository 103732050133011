/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import { DEFAULT_PAGE } from '@/config';
import { orderType as OrderType } from '@/config/order';
import { fetchData, fetchFleet, fetchScenic, fetchRestaurant, progressRequest, convertRequest, cancelRequest } from '@/api/order/reserve';
const dfaultState = () => ({
  reserveData: null,
  restaurantList: [],
  fleetList: []
});
const state = dfaultState();
const getters = {
  reserveData: state => state.reserveData
};
const actions = {
  fetchData({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      type: OrderType.control,
      ...queryParam
    };
    return fetchData(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchFleetList({
    commit
  }, queryParam) {
    return fetchFleet(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchScenicList({
    commit
  }, queryParam) {
    return fetchScenic(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchRestaurantList({
    commit
  }, queryParam) {
    return fetchRestaurant(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchProgress({}, queryParam) {
    return progressRequest(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  convertOrder({}, queryParam) {
    return convertRequest(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  cancelOrder({}, queryParam) {
    return cancelRequest(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};