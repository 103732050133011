// 'waitAudit'=待审核，'opAudited'=操作计调已审，'guideManagerAudited'=导管已审，'generalManagerAudited'=经理已审，'financialAudited'=财务已审，'bossAudited'=BOSS已审

export const ENUM_AUDIT_TYPE = {
  WAITAUDIT: 'WAITAUDIT',
  OPAUDITED: 'OPAUDITED',
  FINANCIALAUDITED: 'FINANCIALAUDITED',
  GENERALAUDITED: 'GENERALAUDITED',
  GENERALMANAGERAUDITED: 'GENERALMANAGERAUDITED',
  GUIDEMANAGERAUDITED: 'GUIDEMANAGERAUDITED',
  ADMIN: 'ADMIN'
};
export const ENUM_AUDIT_TYPE_LABEL = {
  FINANCIALAUDITED: '财务',
  GENERALAUDITED: '管理员',
  GENERALMANAGERAUDITED: '经理',
  GUIDEMANAGERAUDITED: '导管',
  OPAUDITED: '计调',
  WAITAUDIT: '待审核',
  ADMIN: '管理员'
};