var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "auth-sidebar"
  }, [_c("div", {
    staticClass: "auth-sidebar-container"
  }, [_c("header", [_c("a", {
    attrs: {
      href: "/"
    }
  }, [_vm._v("未来旅行")]), _c("h1", [_vm._v("未来旅行-旅行社管家系统")])]), _c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/world.png"),
      alt: "travel"
    }
  })]), _c("footer", {
    staticClass: "tac"
  }, [_c("p", [_vm._v("桂林未来旅行科技有限公司 联系电话：400-xxxx-xxxx")]), _c("p", {
    staticClass: "mt10"
  }, [_vm._v(" Copyright ©2020 技术支持：未来旅行 ")])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };