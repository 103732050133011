import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableAgreement from './TableAgreement';
import { ruleAgreement as rules } from '../config';
import { TYPES } from '@/config/resource';
export default {
  name: 'DialogAgreementEdit',
  components: {
    TableAgreement
  },
  data() {
    return {
      rules,
      visible: false,
      form: {
        resourceType: TYPES.shop
      },
      sendDate: [],
      resourceId: ''
    };
  },
  methods: {
    show(item) {
      const {
        resourceId
      } = item;
      this.visible = true;
      this.form = {
        ...item,
        ...this.form
      };
      this.resourceId = resourceId;
      this.sendDate = item.expire ? item.expire : [];
      item.id && this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    fetchData() {
      this.$store.dispatch('resource/common/fetchAgreementDetail', this.form.id);
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.form.startTime = startDate;
      this.form.endTime = endDate;
    },
    handleChange(type, val) {
      this.form[type] = val;
    },
    handleUpdate() {
      const {
        formItemRef
      } = this.$refs;
      formItemRef.validate(valid => {
        if (!valid) return;
        const {
          argeementRef
        } = this.$refs;
        if (!this.validateForm(argeementRef.tableData)) return;
        const list = this.beforeCommit(argeementRef.tableData);
        this.form.contractPrices = list;
        this.createOrUpdate(this.form);
      });
    },
    beforeCommit(list) {
      const result = [];
      if (!list || !list.length) return result;
      const {
        id: contracId
      } = this.form;
      return list.reduce((acc, it) => {
        it.id = it.rowId;
        it.contracId = contracId;
        it.resourceType = TYPES.shop;
        acc.push(it);
        return acc;
      }, []);
    },
    createOrUpdate(opts) {
      this.$store.dispatch('resource/common/createOrUpdateAgr', opts).then(() => {
        this.$emit('reload');
        this.hide();
      });
    },
    validateForm(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const {
          contentType,
          price
        } = item;
        if (!contentType) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容'
          });
          return false;
        }
        if (!price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写价格'
          });
          return false;
        }
        return true;
      }
    }
  }
};