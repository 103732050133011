// 发团预定安排 购物
import request from '@/utils/request';

// 安排列表
export function fetchShopping(params) {
  return request({
    url: '/order/orderShop/listAll',
    method: 'get',
    params
  });
}

// 安排更新
export function updateShopping(data) {
  return request({
    url: '/order/orderShop/save',
    method: 'post',
    data
  });
}

// 安排删除
export function deleteShopping(ids) {
  return request({
    url: '/order/orderShop/del',
    method: 'post',
    data: ids
  });
}

// 查询商品列表
export function fetchGoods(params) {
  return request({
    url: '/order/orderShop/findShopGoodsList',
    method: 'get',
    params
  });
}

// 查询商品详情
export function fetchGoodsDetail(params) {
  return request({
    url: '/order/orderShop/findShopGoodsSimple',
    method: 'get',
    params
  });
}

// 安排子项删除
export function deleteShoppingItem(id) {
  return request({
    url: '/order/orderShop/delGoods',
    method: 'post',
    data: {
      id
    }
  });
}

// // 订单列表
// export function fetchShoppingOrder(params) {
//   return request({
//     url: '/order/orderShop/pageOrder',
//     method: 'get',
//     params,
//   })
// }

// // 订单更新
// export function updateShoppingOrder(data) {
//   return request({
//     url: '/order/orderShop/saveOrder',
//     method: 'post',
//     data,
//   })
// }

// // 订单移除
// export function deleteShoppingOrder(id) {
//   return request({
//     url: '/order/orderShop/delOrder',
//     method: 'post',
//     data: { id },
//   })
// }

// // 订单子项删除
// export function deleteShoppingOrderItem(id) {
//   return request({
//     url: '/order/orderShop/delOrderTicket',
//     method: 'post',
//     data: { id },
//   })
// }

// // 获取计划订单列表
// export function fetchShoppingOrderList(params) {
//   return request({
//     url: '/order/orderShop/getOrderList',
//     method: 'post',
//     params,
//   })
// }

// // 添加计划订单
// export function selectShoppingOrderList(data) {
//   return request({
//     url: '/order/orderShop/saveAndList',
//     method: 'post',
//     data,
//   })
// }