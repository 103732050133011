import "core-js/modules/es.array.push.js";
// import { beforeCommit } from '../helper'
import { rules, orderState } from '../config';
import { addResourcePlanOrder } from '@/api/order';
import { SelectCustom,
// SelectStaff,
SelectDictionary } from '@/components/common';
import { getUserData } from '@/utils';
export default {
  components: {
    SelectCustom,
    SelectDictionary
  },
  data() {
    return {
      rules,
      visible: false,
      form: orderState(),
      isBatch: false,
      isBatchDone: false,
      orderCount: 1,
      orderSuffux: '车',
      percentage: 0
    };
  },
  methods: {
    show(row) {
      this.visible = true;
      this.processForm(row);
    },
    hide() {
      this.visible = false;
      this.isBatchDone = false;
    },
    processForm(row) {
      const {
        id,
        outOPUserId,
        outOPUserName,
        accompanyCount,
        adultCount,
        childCount,
        planNumber: planCustomerNumber
      } = row;
      const {
        id: dutyOPUserId
      } = getUserData();
      this.form = {
        ...this.form,
        id,
        dutyOPUserId,
        planCustomerNumber,
        outOPUserId,
        outOPUserName,
        accompanyCount,
        adultCount,
        childCount
      };
    },
    handleBatch(countList) {
      // 批量生成
      const list = [...countList];
      if (list.length) {
        const curNum = list.shift();
        const opts = {
          ...this.form
        };
        opts.planCustomerNumber = this.form.planCustomerNumber + curNum + this.orderSuffux;
        this.percentage = Math.ceil((this.orderCount - list.length) * 100 / this.orderCount);
        addResourcePlanOrder(opts).then(() => {
          this.handleBatch(list);
        }).catch(() => {
          this.$bus.tip({
            type: 'danger',
            message: '出错了，批量创建订单失败！'
          });
        });
      } else {
        this.isBatchDone = true;
        this.hide();
        this.$emit('reload');
        this.$bus.tip({
          message: '批量生成订单完成'
        });
      }
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return;
        // 批量生成
        if (this.isBatch && this.orderCount > 1) {
          const maxCount = Math.min(this.orderCount, 50);
          const countList = Array.from({
            length: maxCount
          }).map((_, idx) => idx + 2);
          this.$bus.tip({
            message: '订单正在后台生成中，您可手动关闭弹窗',
            duration: 5000
          });
          this.handleBatch(countList);
        } else {
          addResourcePlanOrder(this.form).then(res => {
            this.$bus.tip();
            this.hide();
            this.redirectOrder(res.data.id, this.form.customerId);
            this.$emit('reload');
          });
        }
      });
    },
    redirectOrder(id, customerId) {
      const query = {
        id,
        customerId,
        type: 'update'
      };
      this.$router.push({
        path: `/order/team-input-update`,
        query
      });
    }
  }
};