export { default as InputDepartment } from '@/components/base/Input/InputDepartment.vue';

// input
export { default as InputDay } from './InputDay';
export { default as InputLine } from './InputLine';
export { default as InputProduct } from './InputProduct';
export { default as InputRoom } from './InputRoom';
export { default as InputCard } from './InputCard';
export { default as InputDriver } from './InputDriver';
export { default as InputCourse } from './InputCourse';
export { default as InputPlan } from './InputPlan';
export { default as InputPayee } from './InputPayee';
export { default as InputGoods } from './InputGoods';
export { default as InputGroup } from './InputGroup';
export { default as InputRemarks } from './InputRemarks';
export { default as InputPayment } from './InputPayment';
export { default as InputBusiness } from './InputBusiness';
export { default as InputAgeRange } from './InputAgeRange';
export { default as InputGuideType } from './InputGuideType';
export { default as InputReduction } from './InputReduction';
export { default as InputRelatation } from './InputRelatation';
export { default as InputTeamNumber } from './InputTeamNumber';
export { default as InputPeopleCount } from './InputPeopleCount';
export { default as InputAccountList } from './InputAccountList';
export { default as InputTeamFundType } from './InputTeamFundType';
export { default as InputDicType } from './InputDicType';
export { default as InputShop } from './InputShop';
export { default as InputMonthRange } from './InputMonthRange';

// 支持模糊搜索
export { default as InputScenic } from './InputScenic';
export { default as InputHotel } from './InputHotel';
export { default as InputFleet } from './InputFleet';
export { default as InputCustom } from './InputCustom';
export { default as InputTraffic } from './InputTraffic';
export { default as InputInsurance } from './InputInsurance';
export { default as InputRestaurant } from './InputRestaurant';