import { StatusAudit } from '@/components/common';
import { selectorMixin, auditMixin, auditPropMixin } from '@/mixin';
export default {
  components: {
    StatusAudit
  },
  mixins: [selectorMixin, auditMixin, auditPropMixin],
  data() {
    return {
      tableData: [],
      rowId: 10000
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let {
        id,
        planId
      } = this.$route.query;
      id = planId || id;
      this.$store.dispatch('audit/index/fetchGuideBorrow', {
        planId: id
      }).then(data => {
        this.tableData = this.formatData(data);
      });
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          ap: auditProgress,
          auditProgress
        };
        this.rowId++;
        return item;
      });
    }
  }
};