import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '客户'
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    fetchPath: 'resource/custom/fetchList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/custom/customData'];
    }
  }
};