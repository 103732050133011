import { searchMixin } from '@/mixin';
import { SelectGuide, SelectCustom } from '@/components/common';
export default {
  components: {
    SelectGuide,
    SelectCustom
  },
  mixins: [searchMixin],
  data() {
    return {
      sendDate: [],
      endDate: [],
      searchParams: {}
    };
  },
  methods: {
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    }
  }
};