import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceScenic/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchScenic(params) {
  return request({
    url: '/resource/resourceScenic/page',
    method: 'get',
    params
  });
}

// 列表（集团）
export function fetchScenicGroup(params) {
  return request({
    url: '/resource/resourceScenic/group-objects',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateScenic(data) {
  return request({
    url: '/resource/resourceScenic/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteScenic(id) {
  return request({
    url: '/resource/resourceScenic/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 查找资源中心绑定的景区
export function fetchBindScenic(params) {
  return request({
    url: '/resource/resourceScenic/findListBindResCenter',
    method: 'get',
    params
  });
}