import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 订单管理

import Layout from '@/layout';
const workRouter = {
  path: '/work',
  component: Layout,
  redirect: '/work/input',
  name: 'work',
  meta: {
    title: 'work',
    icon: 'table'
  },
  children: [{
    path: '/work/subject',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/work/workSubject'))),
    name: 'workSubject',
    // 办公费用科目
    meta: {
      title: 'workSubject',
      icon: 'icon-shangwuhetong'
    }
  }, {
    path: '/work/input',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/work/workInput'))),
    name: 'workInput',
    // 办公费用录入
    meta: {
      title: 'workInput',
      icon: 'icon-shoufahuorenxinxiguanli'
    }
  }, {
    path: '/work/stream',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/work/workStream'))),
    name: 'workStream',
    // 办公费用流水
    meta: {
      title: 'workStream',
      icon: 'icon-lijingdanguanli'
    }
  }, {
    path: '/work/total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/work/workTotal'))),
    name: 'workTotal',
    // 办公费用统计
    meta: {
      title: 'workTotal',
      icon: 'icon-huizongshenqingdanguanli'
    }
  }]
};
export default workRouter;