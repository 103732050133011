var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("h4", {
    staticClass: "pr10"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.isAddShow ? _c("BtnBase", {
    attrs: {
      disabled: _vm.isAdd,
      type: _vm.btnType
    },
    on: {
      click: _vm.handlePlus
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _vm._t("default"), _vm.hasSave ? _c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-check",
      loading: _vm.loading,
      disabled: _vm.disabled
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存" + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.isLogShow ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-time"
    },
    on: {
      click: _vm.handleLog
    }
  }, [_vm._v(" 日志 ")]) : _vm._e(), _vm._t("end")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };