// 按单位 地接
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guideBill,
  orderTotalPay,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
const payed2 = {
  attrs: {
    prop: '',
    label: '已付'
  },
  children: [{
    attrs: {
      prop: 'totalCollect',
      label: '代收'
    }
  }, {
    attrs: {
      prop: 'totalSubsidy',
      label: '返款'
    }
  }, {
    attrs: {
      prop: 'agencyPay',
      label: '社收'
    }
  }]
};
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '地接'
  }
}, ...PayUnitColumn];
export const UnPayColumn = [orderInfo, auditStatus, tripDate, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'custom'
}, guideBill, orderTotalPay, payed2, owePay, downPayed, actionItem];