var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "添加导游借款",
      visible: _vm.visible,
      width: "1360px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide,
      confirm: _vm.handleConfirm
    }
  }, [_vm.data ? _c("TableBorrow", {
    ref: "tableRef",
    attrs: {
      data: _vm.data
    },
    on: {
      reload: _vm.reload
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };