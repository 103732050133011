// import SearchForm from '../components/SearchForm'
import TableList from '../components/TableList';
import { ticketOrder } from '@/config/ticket';
import { DialogLog } from '@/components/common';
export default {
  components: {
    DialogLog,
    TableList
  },
  data() {
    return {
      tableLoading: false,
      columnData: ticketOrder,
      tableData: [],
      total: 0,
      isAudit: false
    };
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.formatData(data);
      }
    },
    formatData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = list;
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 搜索
    handleSearch(searchParams) {
      const {
        path
      } = this.$route;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search: searchParams
      });

      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1
      });
      this.fetchData(searchParams);
    },
    showLog(scope) {
      const {
        id
      } = scope.row;
      this.$store.dispatch('ticket/index/fetchTicketLog', id).then(data => {
        this.$refs.logRef.show(data);
      });
    }
  }
};