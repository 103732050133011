var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "source-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: _vm.name,
      "is-require": "",
      maxlength: "30",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("name", $event);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  }), _vm.ResourceType.fleet == _vm.currentView || _vm.ResourceType.restaurant == _vm.currentView ? _c("SelectDictionary", {
    attrs: {
      code: "hotelRegionType",
      label: "地区",
      "is-require": ""
    },
    on: {
      "on-select": _vm.onSelectArea
    },
    model: {
      value: _vm.form.area,
      callback: function ($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }) : _vm._e(), _vm.isCompany ? _c("InputBase", {
    attrs: {
      label: "编码"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("code", $event);
      }
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }) : _vm._e(), _vm.isHotel && _vm.form.address ? _c("InputBase", {
    attrs: {
      label: "地址"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("address", $event);
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "企业全称"
    },
    model: {
      value: _vm.form.alipayName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alipayName", $$v);
      },
      expression: "form.alipayName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "企业支付宝"
    },
    model: {
      value: _vm.form.alipayAccount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alipayAccount", $$v);
      },
      expression: "form.alipayAccount"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "联系人",
      maxlength: "10",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("contact", $event);
      }
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.contact"
    }
  }), _c("InputBase", {
    attrs: {
      label: "手机号码",
      maxlength: "11",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("mobilePhone", $event);
      }
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", _vm._n($$v));
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "座机号码",
      maxlength: "18",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("telphone", $event);
      }
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", _vm._n($$v));
      },
      expression: "form.telphone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "传真号码",
      maxlength: "18",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("faxNumber", $event);
      }
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", _vm._n($$v));
      },
      expression: "form.faxNumber"
    }
  })], 1), _vm.isHotel && _vm.form.hotelType ? _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      label: "酒店类型",
      placeholder: "请选择",
      type: "hotelType",
      value: 1
    }
  }), _c("InputBase", {
    attrs: {
      label: "酒店标准"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("level", $event);
      }
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    on: {
      change: function ($event) {
        return _vm.handleChange("remarks", $event);
      }
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    attrs: {
      label: "启用状态"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("isEnable", $event);
      }
    },
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  }), _vm.isExport ? _c("SwitchStatus", {
    attrs: {
      label: "导出状态"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("isEnable", $event);
      }
    },
    model: {
      value: _vm.form.isExport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isExport", $$v);
      },
      expression: "form.isExport"
    }
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };