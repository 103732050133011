import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import SearchForm from '../SearchForm';
import { checkBillHotel } from '@/config/billCheck';
import { tagViewMixin, excelMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { getPayTypeLabel } from '@/config/select';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    SearchForm,
    TableList
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillHotel,
      total: 0,
      statistics: null,
      filename: '酒店对账'
    };
  },
  computed: {
    hotelData() {
      return this.$store.getters['bill/billCheck/hotel'];
    }
  },
  watch: {
    hotelData: {
      handler: function (data) {
        !data ? this.fetchData({
          startTripDateS: this.getToday
        }) : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('bill/billCheck/fetchHotel', {
        ...this.getTagViewSearch,
        ...queryParam
      });
    },
    reload() {
      this.$refs.searchRef.handleSearch();
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      this.statistics = statistics;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          plan,
          checkAccountStatus,
          checkAccountMoney,
          totalSettlement
        } = it;
        let hotelRooms = it.hotelRooms || [];
        hotelRooms = hotelRooms.map(hotel => {
          hotel.roomType = hotel.roomType ? hotel.roomType.split('|')[0] : '';
          return hotel;
        });
        it.checkAccountMoney = checkAccountStatus ? checkAccountMoney : totalSettlement;
        const item = {
          ...it,
          ...plan,
          rowId: id,
          cashType: getPayTypeLabel(it.cashType)
        };
        this.$set(item, 'ckStatus', false);
        if (hotelRooms.length) {
          hotelRooms.forEach(s => {
            s.orderCount = s.count;
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    exporttExcel(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportHotelExcel', searchParams).then(res => {
        const fileName = '酒店对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};