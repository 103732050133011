import TableBorrow from './TableBorrow.vue';
export default {
  components: {
    TableBorrow
  },
  data() {
    return {
      visible: false,
      data: null
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      this.data = data;
    },
    hide() {
      this.visible = false;
      this.$refs.tableRef.reset();
    },
    reload() {
      this.$emit('reload');
    },
    handleConfirm() {
      this.$refs.tableRef.handleSave().then(() => {
        this.reload();
        this.hide();
      });
    }
  }
};