export { SELECTOR } from './select';
export { PlanType } from './planType';
export * from './select';
export * from './enterprise';
export * from './guide';
export * from './echart';
export * from './dictionary';
export * from './payType';
export * from './customer';
export * from './order/inquire';
const {
  VUE_APP_BASE_API: baseUrl,
  VUE_APP_DD_APP_ID: DDAppId,
  VUE_APP_DD_URL: redirectUrl,
  VUE_APP_WX_URL: redirecWxtUrl,
  VUE_APP_ALIPAY_URL: redirecAlipaytUrl
} = process.env;
export const APP = {
  baseUrl,
  DDAppId,
  redirectUrl,
  redirecWxtUrl,
  redirecAlipaytUrl,
  sendSmsTime: 60,
  // 发送短信验证码时间
  expires: 3600,
  defaultStation: '桂林',
  airportName: '两江机场',
  travelErrNo: 15888,
  travelErrNoBind: 8686,
  timeout: 100000,
  retry: 3,
  retryDelay: 2000
};
// 微信应用appid信息
export const WECHAT_INFO = {
  appid: 'wxb49f16f3807f8824',
  scope: 'snsapi_login'
};
export const LOGIN = {
  grantType: 'password',
  clientId: 'erp',
  clientSecret: '123456'
};
export const SMS = {
  grantType: 'sms_code',
  clientId: 'erp',
  clientSecret: '123456'
};
export const WX = {
  grant_type: 'wechat',
  client_id: 'erp',
  wechat_type: 'scan',
  client_secret: '123456'
};
export const OSS = {
  prefix: 'aliyuncs.com/'
};

// 电子章类型
export const SEAL_TYPE = {
  business: 'business-seal',
  account: 'account-seal',
  trip: 'trip-seal'
};

// 分页
export const DEFAULT_PAGE = {
  pageNum: 1,
  pageSize: 20
};

// 下账分页
export const BILL_PAGE_COUNT = {
  pageNum: 1,
  pageSize: 200
};
export const PAGES_OPTIONS = {
  pageSizes: [10, 20, 30, 50, 100, 200, 500, 1000],
  pageLayout: 'total, sizes, prev, pager, next',
  bgShow: true
};
export const STORAGE = {
  dictionary: 'dictionary',
  role: 'role'
};

// 日志
export const LOG_TYPE = {
  order: 1,
  plan: 2,
  receive: 3,
  audit: 5
};

// 确认 拒绝或通过
export const REVIEW = {
  refuse: 0,
  pass: 1
};

// 申请 拒绝或通过
export const APPLY = {
  refuse: 0,
  pass: 1
};
export const TICKET_MAP = {
  印象刘三姐: 'impressionTickeTypeList',
  漓江三星船: 'riverTickeTypeList'
};

// 刷新按钮黑名单
export const RefreshExclude = ['/', '/dashboard', '/system/systemConf', '/system/process', '/shopping/shop-create'];
export const MallSystemUrl = {
  dev: 'http://test-manager-mall.weilailvxingshe.com/',
  prod: 'http://manager-mall.weilailvxingshe.com/'
};
export const uploadBase = process.env.VUE_APP_BASE_API + '/upload';
export const uploadUrl = uploadBase + '/up';
export const deleteFileUrl = uploadBase + '/del';
export const SortBy = {
  desc: 'desc',
  asc: 'asc'
};
export const TourAgeCountryConfig = {
  appId: 'eae3eac0491c11ec844cb1aacddc2173',
  appSecret: '71e54e883fdf46dcada4ce06c9370212'
};
export const EmailConfig = {
  url: 'https://mail.jtxwl.com/'
};