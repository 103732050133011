import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      versionSwitch: false
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    })
  },
  watch: {
    userInfo: {
      handler: function (data) {
        if (!data) {
          return;
        }
        if (data.theme) {
          const {
            switchVersion
          } = data.theme;
          this.versionSwitch = switchVersion;
        }
      },
      immediate: true
    }
  },
  methods: {}
};