import "core-js/modules/es.array.push.js";
import { TopTitle, DialogLog, SelectScenic } from '@/components/common';
import { orderScenic, TYPES } from '@/config/order';
import { rowspanMixin, permissionMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import tableMixin from '../tableMixin';
import { mapGetters } from 'vuex';
import { processNumber, calcMergeRowTotalByKey } from '@/utils';
const itemListKeys = () => ({
  accompanyCount: 0,
  accompanyPrice: 0,
  adultCountReceive: 0,
  childCountReceive: 0,
  adultCount: 0,
  adultFreeCount: 0,
  childCount: 0,
  childPrice: 0,
  freeTotal: 0,
  otherFee: 0,
  price: 0,
  sellPrice: 0,
  settlementPrice: 0,
  ticketList: null,
  ticketType: ''
});
const defaultState = () => ({
  isCustom: true,
  // 删除数据用来判断类型
  planId: '',
  scenicId: '',
  totalPay: 0,
  remark: '',
  orderDate: '',
  accompanyRate: 0,
  rowTotal: 0,
  ...itemListKeys()
});
export default {
  components: {
    TopTitle,
    DialogLog,
    SelectScenic
  },
  mixins: [tableMixin, rowspanMixin, permissionMixin],
  data() {
    return {
      tableData: [],
      columnData: orderScenic,
      deleteMsg: '是否确定删除此条景区安排？',
      fetchPath: 'order/index/fetchScenic',
      deletePath: 'order/index/deleteOrScenic',
      updatePath: 'order/index/createOrScenic',
      statisticsKeys: ['price', 'adultCount', 'adultFreeCount', 'childPrice', 'childCount', 'accompanyPrice', 'accompanyCount', 'rowTotal'],
      headMergeNum: 2,
      tailMergeNum: orderScenic.length - 4,
      hasMergeTail: true,
      // rowId: 1000000000000,
      removeList: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder',
      orderItinerary: 'order/index/orderItinerary',
      orderScenices: 'order/index/orderScenices'
    })
  },
  mounted() {
    this.$bus.on('scenic:reload', this.reloadData);
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.reloadData();
      }, 100);
    });
  },
  methods: {
    // 关闭 $bus.on 事件
    offListener() {
      this.$bus.off(this.reloadData);
      this.$bus.off(this.fetchData);
    },
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.offListener();
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    reloadData() {
      const {
        id
      } = this.$route.query;
      this.fetchData(id);
    },
    handlePlus() {
      const {
        adultCount,
        childCount,
        accompanyCount,
        planId,
        id
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        currentOrderId: id,
        adultCount,
        childCount,
        accompanyCount,
        // rowId: ++this.rowId,
        planId: planId
      });
      this.rowspan();
    },
    // 选择景点
    handleSelect(index, val) {
      // 如果未选中任何数据，直接返回
      if (val.label == '') return;
      const {
        id: scenicId,
        label
      } = val;
      this.fetchResourceType(scenicId).then(list => {
        this.tableData[index].scenicId = scenicId;
        this.tableData[index].scenicName = label;
        this.tableData[index].ticketList = list;
        this.tableData[index].isCustom = true;
        // this.editScenic(this.tableData[index])
      }).catch(() => {
        this.tableData[index].ticketType = '';
      });
    },
    changeDate(row, val) {
      row.orderDate = val;
      // this.editScenic(row)
    },
    //编辑景区或者日期时把原有的景区添加进删除数组
    editScenic(row) {
      const {
        rowId,
        scenicId,
        orderDate
      } = row;
      const scenicItem = this.orderScenices.find(it => it.id == rowId);
      if (scenicItem && (scenicId != scenicItem.scenicId || orderDate != scenicItem.orderDate)) {
        const item = {
          scenicId: scenicItem.scenicId,
          scenicName: scenicItem.scenicName,
          orderDate: scenicItem.orderDate,
          isDelete: true
        };
        this.removeList.push(item);
        this.removeList = this.reduceDistinct(this.removeList);
      }
    },
    // 根据日期和景区id相同去重
    reduceDistinct(list) {
      if (!list || !list.length) return;
      let res = list.filter(function (item, index, self) {
        return self.findIndex(it => it.scenicId == item.scenicId && it.orderDate == item.orderDate) === index;
      });
      return res;
    },
    // 根据景区获取协议
    fetchResourceType(scenicId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/common/fetchResourceType', {
          resourceId: scenicId,
          resourceType: 'scenic'
        }).then(data => {
          if (!data && !data.length) {
            this.$bus.tip({
              type: 'warning',
              message: '没有添加景区协议或者协议已过期！'
            });
            return reject();
          } else {
            const list = this.formatOptions(data);
            return resolve(list);
          }
        }).catch(err => {
          return reject(err);
        });
      });
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      // let { id, itemIndex, ticketList, isCustom } = row

      const rowItem = {
        ...row,
        itemIndex: ++index,
        isCustom: true,
        isSelect: false
      };
      // this.mergeRowDataAndPrice(rowItem, ticketList)
      this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      row.totalPay = this.calcCost(row);
      this.tableData = calcMergeRowTotalByKey(this.tableData, 'totalPay', 'rowTotal');
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    // 删除景点门票
    deleteRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        isCustom
      } = row;
      !isCustom ? this.$store.dispatch('order/index/deleteOrScenicTicket', {
        id
      }).then(() => {
        this.reloadData();
      }) : this.tableData.splice(index, 1);
      this.$nextTick(() => {
        // this.resetData()
        this.rowspan();
      });
    },
    // 选择门票类型
    handleicketType(row, val) {
      const {
        label,
        price,
        quotedPrice
      } = val;
      row.ticketType = label;
      row.price = quotedPrice;
      row.settlementPrice = price;
      row.totalPay = this.calcCost(row);
      row.rowTotal = row.totalPay;
    },
    beforeSelectDriver(scope, val) {
      const {
        $index: index
      } = scope;
      const {
        scenicId
      } = scope.row;
      if (!val) return;
      if (!scope.row.ticketList || !scope.row.ticketList.length) {
        this.fetchResourceType(scenicId).then(list => {
          this.tableData[index].ticketList = list;
        });
      }
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        it.auditProgress = it.auditProgress ? it.auditProgress : AuditRole.wait;
        const itemList = it.scenicOTickets;
        // 下拉列表数据
        const {
          id,
          payType,
          remark
        } = it;
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          scenicPlanId: id,
          itemIndex: 0,
          isCustom: false,
          rowTotal: it.totalPay,
          payType: payType.toLowerCase(),
          ticketList: []
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcCost(v);
            const newItem = {
              ...item,
              ...v,
              itemId: v.id,
              // 子订单id
              itemIndex: i,
              remark,
              totalSum
            };
            result.push(newItem);
          });
        } else {
          result.push(item);
        }
        // 同步id
        // this.rowId++
      });
      return result;
    },
    // 格式化下拉列表
    formatOptions(list) {
      if (!list.length) return;
      return list.map((it, index) => {
        it.label = it.contentType;
        it.name = it.contentType;
        it.value = index + 1;
        it.id = index + 1;
        return it;
      });
    },
    changeInput(row, type, value) {
      row[type] = value;
      // 重新计算当前行合并后的价格
      row.totalPay = this.calcCost(row);
      this.tableData = calcMergeRowTotalByKey(this.tableData, 'totalPay', 'rowTotal');
      // this.$nextTick(() => {
      //   this.mergeRowMoney()
      // })
    },
    // 计算总成本
    calcCost(row, type = 'price') {
      const {
        adultCount,
        adultFreeCount,
        childPrice,
        childCount,
        accompanyPrice,
        accompanyCount,
        otherFee
      } = row;
      const price = row[type];
      let total = price * (Number(adultCount) - Number(adultFreeCount)) + childPrice * childCount + accompanyPrice * accompanyCount + Number(otherFee);
      return processNumber(total);
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.scenic
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handleDelete(scope) {
      const {
        $index: index,
        row
      } = scope;
      const {
        isCustom
      } = row; //orderDate, scenicId,

      if (!isCustom) {
        this.$bus.open(() => {
          // 单独删除
          // this.$store.dispatch('order/index/deleteOrScenic', row.rowId)
          // 封装删除景区的数据<www></www>
          row.isDelete = true;
          const deleteScenic = this.beforeCommit(this.currentOrderId, [row]);
          // // 匹配获取行程景区信息
          // let item = this.orderItinerary.find(
          //   it => it.itineraryDate == orderDate
          // )
          // if (item && item.scenics.length) {
          //   item.scenics = item.scenics.filter(it => it.scenicId == scenicId)
          // }
          // 同步封装行程景区的数据
          // const deleteItinerarys =
          //   item && item.scenics.length
          //     ? this.deleteTinerarysScenic(item)
          //     : null
          const reqItinerarys = []; // deleteItinerarys ? [deleteItinerarys] : []
          // 整合大保存数据
          const opts = {
            ...this.currentOrder,
            reqItinerarys,
            scenicOS: deleteScenic
          };
          // this.reloadData()
          this.updateAll(opts);
        }, this.deleteMsg);
      } else {
        this.tableData.splice(index, 1);
      }
    },
    // 同步删除行程景区
    deleteTinerarysScenic(item) {
      if (!item.scenics.length) return;
      item.scenics.forEach(it => {
        it.isDelete = true;
      });
      item.arrangeState = !item.arrangeState ? 2 : 0;
      return item;
    },
    beforeCommit(customerOrderId, data) {
      const list = data ? data : this.tableData;
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'itemIndex', 'payType', 'itemId', 'planScenicTicketId'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom && it.itemId ? item.id = it.itemId : null;
        it.totalPay = it.totalPay || 0;

        // 定义子项目的列表
        it.scenicOTickets = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 重新还原该条数据的id
        it.id = it.scenicPlanId;
        item.id = it.itemId;
        it.customerOrderId = customerOrderId;
        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.scenicPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        delete item.ticketList;

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.scenicOTickets.push(item);
        } else {
          it.scenicOTickets.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    // 保存景区
    handleUpdate() {
      if (!this.validateForm()) return;
      const {
        id
      } = this.$route.query;
      // 重构景区数据
      const dataList = this.beforeCommit(id);
      // const addScenic = dataList.filter(it => it.isCustom)
      // const addItinerary = this.beforeCommitAddItinerary(
      //   addScenic,
      //   this.removeList
      // )
      // 整合大保存数据
      const opts = {
        ...this.currentOrder,
        reqItinerarys: [],
        //addItinerary
        scenicOS: dataList
      };
      this.updateAll(opts);
    },
    // 构建新增行程景区数据和删除景区数据
    beforeCommitAddItinerary(addList, delLlist) {
      addList = addList ? addList : [];
      const dataItinerary = this.orderItinerary.map(it => {
        it.arrangeState = !it.arrangeState ? 2 : 0;
        delLlist.forEach(item => {
          if (it.itineraryDate == item.orderDate) {
            it.scenics.forEach(v => {
              if (item.scenicId == v.scenicId) {
                v.isDelete = true;
              }
            });
          }
        });
        addList.forEach(item => {
          if (it.itineraryDate == item.orderDate) {
            it.scenics.push({
              scenicId: item.scenicId,
              scenicName: item.scenicName,
              itineraryId: it.id,
              orderNumber: 0
            });
          }
        });
        return it;
      });
      return dataItinerary;
    },
    // 调用大保存接口更新景区和行程景区信息
    updateAll(opts) {
      this.$store.dispatch('order/index/createOrUpdateAll', opts).then(() => {
        this.$bus.tip();
        this.fetchData();
        // 行程信息请求最新数据
        // this.$bus.emit('itinerary:reload')
      }).catch(() => {
        this.fetchData();
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (!row.scenicId) {
          this.$bus.tip({
            type: 'warning',
            message: '景区不能为空!'
          });
          return false;
        }
        if (!row.orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '景区日期不能为空!'
          });
          return false;
        }
        if (!row.ticketType) {
          this.$bus.tip({
            type: 'warning',
            message: '门票类别不能为空!'
          });
          return false;
        }
      }
      return true;
    }
  }
};