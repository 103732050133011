import "core-js/modules/es.array.push.js";
import { COLUMNS as columns } from './table';
import { Search } from './components';
import { PersonCount } from '@/components/common';
import { StatusAudit } from '@/components/common';
import { parseTime, parseDate } from '@/utils';
import { reloadMixin, searchMixin, excelMixin } from '@/mixin';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    Search,
    PersonCount,
    StatusAudit
  },
  mixins: [reloadMixin, searchMixin, excelMixin],
  data() {
    return {
      columns,
      search: {},
      statisticsKeys: ['count', 'totalIncome', 'guideIncome', 'agencyIncome', 'unPay'],
      // 小计
      statisticsTotalKeys: {
        count: 'count',
        totalIncome: 'totalPayCount',
        guideIncome: 'guidePayCount',
        agencyIncome: 'agencyIncome',
        unPay: 'unTotalPay'
      } // 总计
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billDown/otherPay/otherPay'];
    },
    tableData() {
      return this.data ? this.formatCreateTime(this.data.list) : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : {};
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData({
            startTripDateS: parseDate()
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    formatCreateTime(list) {
      return list.map(it => {
        it.$createTime = parseTime(it.createTime);
        return it;
      });
    },
    fetchData(params = {}) {
      return this.$store.dispatch('bill/billDown/otherPay/fetchList', {
        ...this.search,
        ...params
      });
    },
    handleValid(row) {
      const {
        id,
        resourceId
      } = row;
      this.$store.commit('bill/billDown/otherPay/CUR_ITEM', row);
      this.$router.push({
        path: '/bill/other-pay-create',
        query: {
          id,
          rid: resourceId
        }
      });
    },
    directPlanDetail(row) {
      const planId = row.id;
      this.$store.commit('order/index/CUR_ORDER_ID', planId);
      this.$store.commit('plan/index/CUR_PLAN_ID', planId);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id: planId,
          planType: 'team'
        }
      });
    },
    handleSearch(params) {
      const search = this.searchParams;
      const extra = this.extra;
      const {
        path
      } = this.$route;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search,
        extra
      });
      this.search = params;
      this.fetchData();
    },
    handleExport() {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportOtherIncomeExcel', this.search).then(res => {
        const fileName = '其他收入核销表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};