import { SelectGuide } from '@/components/common';
import TableList from './TableList';
import { guideStatisticsTabel } from '@/config/plan';
export default {
  components: {
    SelectGuide,
    TableList
  },
  data: () => ({
    columnData: guideStatisticsTabel,
    tableData: [],
    total: 0,
    searchParams: {}
  }),
  created() {
    // fetchData
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('plan/index/fetchGuideStatistics', params).then(res => {
        this.tableData = res;
      });
    },
    selectGuide(val) {
      this.searchParams.guideId = val.value;
      this.searchParams.guideName = val.label;
    },
    handleSearch() {
      if (!this.searchParams.guideId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择导游！'
        });
        return false;
      }
      this.fetchData(this.searchParams);
    }
  }
};