var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt20 area-index"
  }, [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "60px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDate", {
    attrs: {
      type: "year",
      "value-format": "yyyy",
      label: "年度查询"
    },
    model: {
      value: _vm.searchParams.year,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "year", $$v);
      },
      expression: "searchParams.year"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-bottom": "80px"
    }
  }, [_c("TableList", {
    staticClass: "mt20",
    attrs: {
      id: "turnover-table",
      "table-data": _vm.turnoverList,
      "column-data": _vm.columnData1
    }
  }), _c("div", {
    staticClass: "el-chart-flex"
  }, [_vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "turnoverChart",
      "progress-list": _vm.turnoverList.slice(0, 2),
      title: "营业额预算与实际趋势图"
    }
  }) : _vm._e(), _vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "turnoverChart2",
      "progress-list": _vm.turnoverCompList,
      type: "complete",
      title: "营业额完成率趋势图"
    }
  }) : _vm._e()], 1)], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "80px"
    }
  }, [_c("TableList", {
    staticClass: "mt20",
    attrs: {
      id: "countr-table",
      "table-data": _vm.countrList,
      "column-data": _vm.columnData2
    }
  }), _c("div", {
    staticClass: "el-chart-flex"
  }, [_vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "countrChart",
      "progress-list": _vm.countrList.slice(0, 2),
      title: "接待人数预算与实际趋势图"
    }
  }) : _vm._e(), _vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "countrChart2",
      "progress-list": _vm.countrCompList,
      type: "complete",
      title: "接待人数完成率趋势图"
    }
  }) : _vm._e()], 1)], 1), _c("div", [_c("TableList", {
    staticClass: "mt20",
    attrs: {
      id: "profit-table",
      "table-data": _vm.profitList,
      "column-data": _vm.columnData3
    }
  }), _c("div", {
    staticClass: "el-chart-flex"
  }, [_vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "profitChart",
      "progress-list": _vm.profitList.slice(0, 2),
      title: "毛利润预算与实际趋势图"
    }
  }) : _vm._e(), _vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "profitChart2",
      "progress-list": _vm.profitCompList,
      type: "complete",
      title: "毛利润完成率趋势"
    }
  }) : _vm._e()], 1)], 1), _c("div", [_c("TableList", {
    staticClass: "mt20",
    attrs: {
      id: "avgprofit-table",
      "table-data": _vm.avgProfitList,
      "column-data": _vm.columnData4
    }
  }), _c("div", {
    staticClass: "el-chart-flex"
  }, [_vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "avgProfitChart",
      "progress-list": _vm.avgProfitList.slice(0, 2),
      title: "人均毛利润预算与实际趋势图"
    }
  }) : _vm._e(), _vm.isVisible ? _c("itemChart", {
    attrs: {
      id: "avgProfitChart2",
      "progress-list": _vm.avgProfitCompList,
      type: "complete",
      title: "人均毛利润完成率趋势"
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };