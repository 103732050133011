var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("InputBase", {
    attrs: {
      label: "小团号"
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDate", {
    attrs: {
      label: "发团年份",
      type: "year",
      "is-require": ""
    },
    on: {
      change: _vm.changeYear
    },
    model: {
      value: _vm.searchParams.year,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "year", $$v);
      },
      expression: "searchParams.year"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "区域经理"
    },
    model: {
      value: _vm.searchParams.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaManagerId", $$v);
      },
      expression: "searchParams.customerAreaManagerId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      clearable: "",
      label: "订单类型",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  })], 1)]), _c("div", {
    staticClass: "el-table"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "cell-class-name": _vm.changeCellClassName,
      loading: _vm.tableLoading,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "sequence",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "areaManager",
      fn: function ({
        scope
      }) {
        return [_vm._l(scope.row.areaManager, function (item, index) {
          return [_c("p", {
            key: index,
            domProps: {
              innerHTML: _vm._s(item)
            }
          })];
        })];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };