import { Hotel } from '@/components/common/Order';
import { planEditHotel, planEditHotelAll } from '@/config/plan';
import { permissionMixin, systemMixin } from '@/mixin';
export default {
  components: {
    Hotel
  },
  mixins: [permissionMixin, systemMixin],
  data() {
    return {
      hasRole: true,
      columnData: []
    };
  },
  mounted() {
    setTimeout(() => {
      const {
        toPath
      } = this.$route.query;
      // 判断是否显示底价 (根据toPath区分发团预定安排和发团预定安排2)
      let hotelFloorPrice = toPath == '/plan/team-role-arrange' ? 'hotelFloorPrice2' : 'hotelFloorPrice';
      this.columnData = this.hasPermission(hotelFloorPrice) ? planEditHotelAll : planEditHotel;
      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      if (!isOrderInfo) {
        this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo');
      }
    }, 100);
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    },
    // 是否有房调安排权限
    initRole(list) {
      if (!list) return;
      this.columnData = this.hasPermission('hotelFloorPrice') ? planEditHotelAll : planEditHotel;
    }
  }
};