// 按单位 付景区
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  orderArrangeRemark,
  orderOther,
  payed,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
const lastItem = [orderArrangeRemark, orderOther, {
  attrs: {
    prop: 'totalPay',
    label: '应付'
  }
}, payed, owePay, downPayed, actionItem];

// 列表
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '景区'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'scenicName',
    label: '景区'
  }
}, {
  attrs: {
    prop: 'ticketType',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'sellPrice',
    label: '卖价',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'settlementPrice',
    label: '结算价',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '成人数',
    'min-width': 70
  }
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': 70
  }
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童价',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童数',
    'min-width': 70
  }
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪价',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪数',
    'min-width': 70
  }
}, ...lastItem];