export default {
  props: {
    rowTitle: {
      type: String,
      default: 'title'
    },
    rowSpanTitle: {
      type: String,
      default: 'name'
    },
    rowSpanKey: {
      type: String,
      default: ''
    },
    totalAmount: {
      type: String,
      default: ''
    },
    columnData: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    }
  }
};