import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 企业码管理

import Layout from '@/layout';
const enterpriseRouter = {
  path: '/enterprise',
  component: Layout,
  redirect: '/enterprise/input',
  name: 'enterprise',
  meta: {
    title: 'enterprise',
    icon: 'table'
  },
  children: [{
    path: '/enterprise/check',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/enterprise/check'))),
    name: 'enterpriseCheck',
    // 企业码对账
    meta: {
      title: 'enterpriseCheck',
      icon: 'icon-shangwuhetong'
    }
  }, {
    path: '/enterprise/group',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/enterprise/group'))),
    name: 'enterpriseGroup',
    // 企业码按团统计
    meta: {
      title: 'enterpriseGroup',
      icon: 'icon-shoufahuorenxinxiguanli'
    }
  }, {
    path: '/enterprise/quota-manage',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/enterprise/quotaManage'))),
    name: 'enterpriseQuotaManage',
    // 企业码额度管理
    meta: {
      title: 'enterpriseQuotaManage',
      icon: 'icon-lijingdanguanli'
    }
  }, {
    path: '/enterprise/quota-limit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/enterprise/quotaLimit'))),
    name: 'enterpriseQuotaLimit',
    // 企业码额度预算
    meta: {
      title: 'enterpriseQuotaLimit',
      icon: 'icon-huizongshenqingdanguanli'
    }
  }]
};
export default enterpriseRouter;