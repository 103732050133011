import "core-js/modules/es.array.push.js";
import DialogTicket from './DialogTicket.vue';
import { SelectSign } from '@/components/common';
import { DICTIONARY } from '@/config/dictionary';
import { TicketCompleteStatus } from '@/config/ticket';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectSign,
    DialogTicket
  },
  data: () => ({
    signSheetType: DICTIONARY.signSheetType,
    form: {
      isChecked: false,
      isCustom: true,
      count: 1
    },
    groupId: -1
  }),
  computed: {
    ...mapGetters({
      currentPlan: 'ticket/index/currentPlan'
    }),
    isGroup() {
      return this.groupId > 0;
    },
    isDisabled() {
      return this.currentPlan && TicketCompleteStatus[this.currentPlan.signSheetStatus];
    },
    getPlanNumber() {
      return this.currentPlan ? this.currentPlan.planNumber : '';
    }
  },
  methods: {
    handleAdd() {
      if (!this.validateForm()) return;
      const {
        id: planId
      } = this.$route.query;
      let items = [];
      var reg = /[\r\n]+/g;
      if (!reg.test(this.form.startNo)) {
        const item = this.formatData(this.form.startNo);
        items.push({
          ...item,
          planId
        });
      } else {
        const list = this.form.startNo.replace(/\s+/gi, ';').trim().split(';');
        list.forEach(it => {
          if (it) {
            const item = this.formatData(it);
            items.push({
              ...item,
              planId
            });
          }
        });
      }
      this.$emit('on-add', items);
      this.form.startNo = '';
    },
    selectSign(val) {
      this.groupId = val.groupId;
    },
    selectGroupSign() {
      this.$refs.dialogRef.show(this.form.signSheetId);
    },
    // 格式化
    formatData(val) {
      // 判断是否有“+”号
      if (val.indexOf('+') != -1) {
        let [startNo, count] = val.split('+');
        count = count ? Number(count) : 1;
        return {
          startNo,
          count,
          signSheetId: this.form.signSheetId
        };
      } else {
        return {
          startNo: val,
          count: 1,
          signSheetId: this.form.signSheetId
        };
      }
    },
    validateForm() {
      const {
        signSheetId,
        startNo
      } = this.form;
      if (!signSheetId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择票据类型'
        });
        return false;
      }
      if (!startNo) {
        this.$bus.tip({
          type: 'warning',
          message: '请输入单号'
        });
        return false;
      }
      return true;
    }
  }
};