import "core-js/modules/es.array.push.js";
import orderMixin from '../orderMixin';
import commonMixin from '../commonMixin';
import { orderType } from '@/config/order';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'TeamUpdate',
  mixins: [tagViewMixin, orderMixin, commonMixin, reloadMixin],
  data: () => ({
    backPath: '/order/team',
    form: {},
    orderType: orderType.team,
    activeName: 'lines',
    tipMsg: '点击项目标题可收缩或展开当前项目内容。',
    orderId: '',
    btnLoading: false
  }),
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      visitedViews: 'tagsView/visitedViews'
    })
  },
  beforeMount() {
    const {
      id,
      isNew
    } = this.$route.query;
    this.orderId = id;
    this.fetchData();
    this.$store.commit('order/index/CUR_ORDER_ID', id);
    this.$store.commit('order/index/CUR_ORDER_TYPE', this.orderType);

    // 删除创建的TagView
    if (isNew) {
      const createTag = this.findCreateTagView();
      createTag && this.closeSelectedTag(createTag);
    }
  },
  beforeDestroy() {
    // 更新列表数据
    this.$store.commit('order/index/NEED_RELOAD', {
      type: 'Team',
      flag: true
    });
  },
  mounted() {
    // let timer = setTimeout(() => {
    //   clearTimeout(timer)
    //   const { isNew } = this.$route.query
    //   isNew && this.generateRefund()
    // }, 1000)
    this.$store.commit('resource/custom/CLEAN_CUSTOM_DATA');
  },
  methods: {
    // 根据配置，是否生成返款
    generateRefund() {
      this.$refs.teamRefundRef.handleGenerateRefund();
    },
    findCreateTagView() {
      return this.visitedViews.find(it => it.name === 'orderTeamInput');
    },
    showTraffic(data) {
      this.$refs.teamTrafficRef.show(data);
    },
    editTraffic(data) {
      this.$refs.teamTrafficRef.edit(data);
    },
    fetchData() {
      this.$store.dispatch('order/index/fetchDetail', {
        id: this.orderId
      }).then(data => {
        const {
          provinceId,
          cityId,
          districtId
        } = data;
        let region = [provinceId + ''];
        cityId > 0 && region.push(cityId + '');
        districtId > 0 && region.push(districtId + '');
        data.region = region;
        this.form = data;
        this.$store.commit('order/index/CUR_CUSTOMER_ID', data.customerId);
      });
    },
    // 更新行程
    updateTrip(opts) {
      this.$refs.teamItineraryRef.updateTrip(opts);
    },
    handleUpdate() {
      // this.btnLoading = true
      // this.$set(this.$data, 'btnLoading', true)
      const _this = this;
      _this.loadingBtn(true);
      // const loading = this.$bus.loading()
      // const id = this.currentOrderId
      const {
        id
      } = this.$route.query;
      const {
        teamFormRef,
        teamItineraryRef,
        teamHotelRef,
        teamFundRef,
        teamProxyRef,
        insuranceRef,
        teamRefundRef,
        teamPickupRef,
        teamTouristRef,
        teamRemarksRef,
        teamReceptionRef,
        otherIncomeRef,
        otherPayRef,
        trafficRef,
        scenicRef,
        restaurantRef,
        fleetRef
      } = this.$refs;
      // 基础信息
      let opts = teamFormRef.form;
      // 行程信息
      opts.reqItinerarys = teamItineraryRef.beforeCommit(teamItineraryRef.tableData);
      // 景区
      opts.scenicOS = scenicRef.beforeCommit(id);
      // 备注
      opts.reqOrderRemarks = teamRemarksRef.beforeCommit(id);
      // 酒店信息
      opts.addHotelOs = teamHotelRef.beforeCommit(id);
      // 餐厅信息
      opts.restaurantOS = restaurantRef.beforeCommit(id);
      // 车队信息
      opts.buses = fleetRef.beforeCommit(id);
      // 团款信息
      opts.customerFees = teamFundRef.beforeCommit(id);
      // 代收信息
      opts.reqOrderCustomerCollects = teamProxyRef.beforeCommit(id);
      // 返款信息
      opts.reqOrderCustomerSubsidies = teamRefundRef.beforeCommit(id);
      // 大交通信息
      opts.addOrderTransports = trafficRef.beforeCommit(id);
      // 保险信息
      opts.addOrderInsurances = insuranceRef.beforeCommit(id, '');
      // 接送信息
      opts.reqOrderCustomerShuttles = teamPickupRef.beforeCommit(id);
      // 其他收入信息
      opts.reqOrderOtherIncomeOS = otherIncomeRef.beforeCommit(id);
      // 其他支出信息
      opts.reqOrderOtherPays = otherPayRef.beforeCommit(id);

      // 游客信息
      opts.reqOrderCustomerMembers = teamTouristRef.beforeCommitFomat(id);
      // 外转信息
      opts.reqOutTransfer = teamReceptionRef.receptionList.length ? teamReceptionRef.receptionList : null;
      this.$store.dispatch('order/index/createOrUpdateAll', opts).then(() => {
        this.$bus.tip();
        // 获取最新数据
        teamFormRef.fetchData(id);
        teamTouristRef.fetchData();
        teamHotelRef.fetchData();
        teamPickupRef.fetchData();
        trafficRef.fetchData();
        teamProxyRef.fetchData();
        teamItineraryRef.fetchData();
        teamRemarksRef.fetchData();
        teamFundRef.fetchData();
        insuranceRef.fetchData();
        otherIncomeRef.fetchData();
        otherPayRef.fetchData();
        teamRefundRef.fetchData();
        teamReceptionRef.fetchData();
        scenicRef.fetchData();
        restaurantRef.fetchData();
        fleetRef.fetchData();
      });
      setTimeout(function () {
        _this.loadingBtn(false);
      }, 3000);
    },
    loadingBtn(val) {
      this.$set(this.$data, 'btnLoading', val);
    },
    handleBack() {
      // 清理数据
      this.$store.commit('order/index/CUR_ORDER', null);

      // 关闭页面
      this.$bus.emit('order:team-close');

      // 更新数据
      this.$store.commit('order/index/NEED_RELOAD', {
        type: 'Team',
        flag: true
      });

      // 返回列表 路径是页面内配置
      // this.$router.push({
      //   path: this.backPath,
      // })
    }
  }
};