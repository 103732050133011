import "core-js/modules/es.array.reduce.js";
/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: cyg
 * @LastEditTime: 2023-04-20 10:07:36
 * @Description:
 */
import { decimalSplit } from '@/utils';
import { processAdd } from '@/utils/number';
export default {
  data() {
    return {
      // avgeageKey: [],
      needSumKeys: []
    };
  },
  methods: {
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let last;
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => {
          // 只合计设定的字段
          if (this.statisticsKeys.includes(column.property)) {
            // 处理多个子项合计
            if (last && item.rowId && last.rowId == item.rowId && !this.needSumKeys.includes(column.property)) {
              return 0;
            } else {
              last = item;
              return item[column.property];
            }
          }
        });
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              prev = processAdd(prev, curr);
            }
            return prev;
          }, 0);
          // 人均毛利
          if (this.avgeageKey && this.avgeageKey.length) {
            let avgeageKey = this.avgeageKey;
            const res = sums[avgeageKey[1]] / sums[avgeageKey[0]];
            sums[avgeageKey[2]] = Number(res).toFixed(2);
          }
          let resultNum = sums[index];
          sums[index] = decimalSplit(resultNum);
        }
      });
      return sums;
    }
  }
};