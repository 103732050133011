import { dictionaryMixin, searchMixin } from '@/mixin';
import { SELECTOR } from '@/config/select';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, SelectCustom } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectLine,
    SelectProduct,
    SelectStaff,
    SelectCustom
  },
  mixins: [dictionaryMixin, searchMixin],
  data() {
    return {
      sendDate: [parseDate()],
      endDate: [],
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        startTripDateS: parseDate()
      },
      payType: [{
        value: '',
        label: '全部'
      }, ...SELECTOR.payType]
    };
  },
  computed: {
    collectData() {
      return this.$store.getters['bill/billCheck/collect'];
    }
  },
  watch: {
    collectData: {
      handler: function (d) {
        if (!d) {
          this.$emit('on-search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    onSelectPayTypa(val) {
      let {
        value,
        label
      } = val;
      this.searchParams.payType = value ? value.toUpperCase() : '';
      this.searchParams.payTypeLabel = label;
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    handleBillCheck() {
      this.$refs.tableRef.handleBillCheck();
    },
    handleExcel() {
      this.$emit('on-export', this.searchParams);
    }
  }
};