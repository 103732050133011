/*
import { actionItem } from './billDown/payRegiment/index';
 * @Author: cyg
 * @Date: 2023-08-11 08:29:07
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-24 16:43:42
 * @FilePath: \travel-admin\src\config\offer.js
 */
const price = {
  attrs: {
    prop: 'price',
    label: '单价'
  }
};
const priceSlot = {
  ...price,
  slot: 'price'
};
const count = {
  attrs: {
    prop: 'count',
    label: '数量'
  }
};
const countSlot = {
  ...count,
  slot: 'count'
};
const action = {
  attrs: {
    prop: 'action',
    label: '操作'
  }
};
const actionSlot = {
  ...action,
  slot: 'action'
};
const cost = {
  attrs: {
    prop: 'cost',
    label: '单人费用'
  }
};

// 订单报价
export const offerList = [{
  attrs: {
    prop: 'name',
    label: '报价名称'
  }
}, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'totalMoney',
    label: '成交金额'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '报价人'
  }
}, {
  attrs: {
    prop: 'times',
    label: '报价次数'
  },
  slot: 'times'
}, {
  attrs: {
    prop: 'state',
    label: '报价状态'
  },
  slot: 'state'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 370
  },
  slot: 'action'
}

// actionSlot,
];
export const offerScenic = [{
  attrs: {
    prop: 'name',
    label: '景区'
  },
  slot: 'name'
}, priceSlot, actionSlot];
export const offerScenicDetail = [{
  attrs: {
    prop: 'name',
    label: '景区'
  }
}, price];
export const offerHotel = [{
  attrs: {
    prop: 'area',
    label: '地区'
  },
  slot: 'area'
}, priceSlot, countSlot, cost, actionSlot];
export const offerHotelDetail = [{
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, price, count, cost];
export const offerRestaurant = [priceSlot, countSlot, cost, actionSlot];
export const offerRestaurantDetail = [price, count, cost];
export const offerOperate = [priceSlot, cost, actionSlot];
export const offerOperateDetail = [price, cost];

// 历史记录
export const offerHistory = [{
  attrs: {
    prop: 'name',
    label: '报价名称'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'totalMoney',
    label: '地接总价'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '报价人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '报价时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100
  },
  slot: 'action'
}];