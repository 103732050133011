import { render, staticRenderFns } from "./DialogWechatBind.vue?vue&type=template&id=678388be"
import script from "./DialogWechatBind.vue?vue&type=script&lang=js"
export * from "./DialogWechatBind.vue?vue&type=script&lang=js"
import style0 from "./DialogWechatBind.vue?vue&type=style&index=0&id=678388be&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('678388be')) {
      api.createRecord('678388be', component.options)
    } else {
      api.reload('678388be', component.options)
    }
    module.hot.accept("./DialogWechatBind.vue?vue&type=template&id=678388be", function () {
      api.rerender('678388be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/Dialog/DialogWechatBind.vue"
export default component.exports