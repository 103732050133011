import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import pinyin from 'pinyin';
import { ObjectKeyTransfor, DoubleNameKeys, CardTypeTransfor, defaultGuideInfo } from './config';
import { TransitType, DestinationType } from './enums';
import { getBirthday, getIdCardInfo, mergeTableRow } from '@/utils';
export const transforForm = obj => {
  return Object.keys(ObjectKeyTransfor).reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      acc[ObjectKeyTransfor[key]] = obj[key];
    }
    return acc;
  }, {});
};
const processList = (list, key) => {
  return list.reduce((acc, cur) => {
    return [...acc, cur[key]];
  }, []);
};
const processItineraryDesc = (list, showShop = false) => {
  return list.reduce((acc, cur) => {
    const scenics = processList(cur.scenics, 'scenicName');
    const shops = processList(cur.shops, 'shopName');
    cur.isPick ? acc += '接团, ' : null;
    acc += [...scenics, cur.selfScenic].join(',') + ' ';
    showShop ? acc += shops.join(',') + ' ' : null;
    cur.isSend ? acc += '送团' : null;
    return acc;
  }, '');
};
const processItinerary = list => {
  const res = list.reduce((acc, cur, index) => {
    const obj = {
      day_number: index + 1,
      date: cur.itineraryDate,
      is_transit: TransitType.NO,
      city: DestinationType.CITY,
      province: DestinationType.PROVINCE
    };
    obj.scenics = processList(cur.scenics, 'scenicName');

    // const res = scenics.map((name, idx) => {
    //   console.log('idx:', idx)
    //   obj.site_num = idx + 1
    //   obj.journey_desc = name
    //   return obj
    // })

    return [...acc, obj];
  }, []);
  return res.reduce((acc, cur) => {
    const item = {
      site_num: 1,
      journey_desc: ''
    };
    if (cur.scenics.length) {
      cur.scenics.forEach((val, idx) => {
        delete cur.scenics;
        acc.push({
          ...cur,
          site_num: idx + 1,
          journey_desc: val
        });
      });
    } else {
      acc.push({
        ...cur,
        ...item
      });
    }
    return acc;
  }, []);
};
const isDoubleFirstName = name => {
  return DoubleNameKeys.includes(name);
};
const processNamePinyin = val => {
  const py = pinyin(val, {
    style: pinyin.STYLE_FIRST_LETTER,
    heteronym: false
  }).map(item => {
    return item[0];
  }).join('');
  return py;
};
const formatNameToPinyin = name => {
  if (isDoubleFirstName(name)) {
    const prefix = name.slice(0, 2);
    const suffix = name.slice(2);
    return {
      py_family_name: processNamePinyin(prefix),
      py_given_name: processNamePinyin(suffix)
    };
  } else {
    const prefix = name.slice(0, 1);
    const suffix = name.slice(1);
    return {
      py_family_name: processNamePinyin(prefix),
      py_given_name: processNamePinyin(suffix)
    };
  }
};
const processTourist = data => {
  return data.reduce((acc, cur) => {
    const {
      name,
      cardType,
      cardNumber,
      mobilePhone,
      location
    } = cur;
    const {
      province,
      city,
      district,
      sex
    } = getIdCardInfo(cardNumber);
    const {
      year,
      month,
      day
    } = getBirthday(cardNumber);
    const obj = {
      name,
      date_of_birth: `${year}-${month}-${day}`,
      phone_num: mobilePhone,
      cardNumber,
      cardType: CardTypeTransfor[cardType],
      nationality: province,
      hr_county: district,
      hr_city: city,
      hr_province: province,
      is_child: 0,
      sex,
      cardItem: {
        card_id: cardNumber,
        card_type: CardTypeTransfor[cardType],
        issue_authority: location,
        valid_from: '1990-10-10',
        valid_to: '1997-10-10'
      },
      ...formatNameToPinyin(name),
      card_list: [
        // {
        //   card_type: CardTypeTransfor[cardType],
        //   card_id: cardNumber,
        //   issue_authority: location,
        //   valid_from: '1990-10-10',
        //   valid_to: '1997-10-10',
        // },
      ]
    };
    return [...acc, obj];
  }, []);
};
export const formatGuide = data => {
  const {
    guides
  } = data;
  if (!guides.length) return defaultGuideInfo();
  const {
    name,
    guideCard
  } = guides[0];
  return {
    ...defaultGuideInfo(),
    name,
    card_id: guideCard
  };
};

// 车队信息
export const formatFleet = data => {
  const {
    busCompanyName,
    busCompanyPhone,
    respOrderBusCompanyTypeSimples: list
  } = data;
  const {
    licenceNumber,
    driverName
  } = list[0];
  return {
    fleet: busCompanyName,
    plate_number: licenceNumber,
    driver_name: driverName,
    driver_phone: busCompanyPhone
  };
};

// 酒店信息
export const formatHotel = data => {
  console.log('data:', data);
};

// 行程描述
export const formatFormData = data => {
  const {
    itinerarys,
    customerName,
    customerArea,
    members
  } = data;
  const desc = processItineraryDesc(itinerarys);
  const itineraryList = processItinerary(itinerarys);
  const tableItinerary = mergeTableRow(itineraryList, ['day_number']);
  const touristList = processTourist(members);
  const result = {
    journey_desc: desc,
    itinerary_day: itinerarys.length,
    native_travel_agency: customerName,
    itinerary_list: tableItinerary,
    tourist_list: touristList
  };
  if (customerArea) {
    result.source_city = customerArea;
  }
  return result;
};