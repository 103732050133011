var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper form-fix-bar"
  }, [_c("h3", [_vm._v("已结清数据")]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      "show-page": true,
      total: _vm.total,
      border: ""
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "orderDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate) + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }, {
      key: "money",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.cash) + " ")];
      }
    }])
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };