const dfaultState = () => ({
  orderItinerary: null
});
const state = dfaultState();
const getters = {
  orderItinerary: state => state.orderItinerary
};
const actions = {};
const mutations = {
  ORDER_ITINERARY: (state, data) => {
    state.orderItinerary = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};