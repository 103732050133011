var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("TopTitle", {
    attrs: {
      title: ""
    }
  }), _c("table", [_c("colgroup", _vm._l(12, function (item) {
    return _c("col", {
      key: item,
      staticClass: "col"
    });
  }), 0), _vm._m(0), _c("tbody", [_vm._l(_vm.tableData, function (item, index) {
    return [!item.slot ? _c("tr", {
      key: index
    }, [_vm._l(item.rows, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        class: col.label ? "item-label" : "item-content",
        attrs: {
          colspan: col.span
        }
      }, [col.slot ? [col.slot == "number" ? _c("div", [_c("PersonCount", {
        attrs: {
          row: _vm.data
        }
      })], 1) : _vm._e(), col.slot == "guides" ? _c("div", [_c("Guides", {
        attrs: {
          row: _vm.data
        }
      })], 1) : _vm._e()] : [_vm._v(" " + _vm._s(col.label ? col.label : _vm.data[col.content]) + " ")]], 2)];
    })], 2) : _vm._e()];
  })], 2)]), _c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(24, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", _vm._l(_vm.tableHead, function (item, index) {
    return _c("th", {
      key: index,
      attrs: {
        colspan: item.span ? item.span : 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.shops, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(_vm.tableHead, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        staticClass: "item-content tac",
        staticStyle: {
          height: "60px"
        },
        attrs: {
          colspan: col.span
        }
      }, [col.slot == "serialnumber" ? [_vm._v(" " + _vm._s(index + 1) + " ")] : _vm._e(), col.slot == "customerTripNumber" ? [_vm._v(" " + _vm._s(item.customerTripNumber) + " ")] : _vm._e(), col.slot == "people" ? [_c("PersonCount", {
        attrs: {
          row: item
        }
      })] : _vm._e(), col.slot == "cardType" ? [_vm._v(" " + _vm._s(_vm.getCardType(item.cardType)) + " ")] : _vm._e(), col.slot == "sex" ? [_vm._v(" " + _vm._s(_vm.getSex(item.sex)) + " ")] : _vm._e(), !col.slot ? _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")]) : _vm._e()], 2)];
    })], 2)];
  })], 2)])], 1)]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", [_c("th", {
    attrs: {
      colspan: "12"
    }
  }, [_c("h2", [_vm._v("统计表")])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };