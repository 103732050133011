/* eslint-disable no-empty-pattern */
import { caseListRequest, caseDetailRequest, caseSetRequest, caseDeleteRequest, caseAuditRequest } from '@/api/system';
const dfaultState = () => ({
  dataPraise: null,
  dataPraiseShow: null,
  dataComplaint: null,
  dataComplaintShow: null
});
const state = dfaultState();
const getters = {
  dataPraise: state => state.dataPraise,
  dataPraiseShow: state => state.dataPraiseShow,
  dataComplaint: state => state.dataComplaint,
  dataComplaintShow: state => state.dataComplaintShow
};
const actions = {
  listPraise({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      caseListRequest({
        ...params,
        type: 'praise'
      }).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'dataPraise',
          data: res.data
        });
        resolve(res);
      }).catch(reject);
    });
  },
  listPraiseShow({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      caseListRequest({
        ...params,
        caseStatus: 'announcement',
        type: 'praise'
      }).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'dataPraiseShow',
          data: res.data
        });
        resolve(res);
      }).catch(reject);
    });
  },
  listComplaint({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      caseListRequest({
        ...params,
        type: 'complaint'
      }).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'dataComplaint',
          data: res.data
        });
        resolve(res);
      }).catch(reject);
    });
  },
  listComplaintShow({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      caseListRequest({
        ...params,
        caseStatus: 'announcement',
        type: 'complaint'
      }).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'dataComplaintShow',
          data: res.data
        });
        resolve(res);
      }).catch(reject);
    });
  },
  detail({}, params) {
    return new Promise((resolve, reject) => {
      caseDetailRequest(params).then(res => {
        if (!res) reject(res);
        resolve(res);
      }).catch(reject);
    });
  },
  set({}, opts) {
    return caseSetRequest(opts);
  },
  del({}, opts) {
    return caseDeleteRequest(opts);
  },
  audit({}, opts) {
    return caseAuditRequest(opts);
  }
};
const mutations = {
  SET_DATA(state, {
    type,
    data
  }) {
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};