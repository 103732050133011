var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.fetchData
    }
  }), _c("TableNew", {
    staticClass: "plan-list-table",
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StatisticsKeys,
      border: ""
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        row
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.planNumber) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startPlanDate) + " "), _c("br"), _vm._v(_vm._s(row.endPlanDate) + " ")];
      }
    }, {
      key: "orderType",
      fn: function ({
        row
      }) {
        return [_c("TypeOrder", {
          attrs: {
            type: row.planType
          }
        })];
      }
    }, {
      key: "dutyOPUserName",
      fn: function ({
        row
      }) {
        return [_vm._v(" 操作：" + _vm._s(row.dutyOPUserName)), _c("br"), row.planType == "T_TOURIST" ? [_vm._v(" 客服：" + _vm._s(row.outOPUsers && row.outOPUsers.length ? row.outOPUsers[0].realName : "") + " ")] : _vm._e()];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "guide",
      fn: function ({
        row
      }) {
        return [row.guide ? [_c("div", [_vm._v(_vm._s(row.guide.name))]), _c("div", [_vm._v(_vm._s(row.guide.mobileIphone))]), _c("el-tag", {
          attrs: {
            type: _vm.getGuideAliECodeType(row.guide)
          }
        }, [_vm._v(_vm._s(_vm.getGuideAliECodeStatus(row.guide)))])] : _vm._e()];
      }
    }, {
      key: "guideStatus",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.$reimbStatus) + " "), _vm.invitedStatus(row.guide) ? _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.removeECode(row);
            }
          }
        }, [_vm._v(" 移除企业码 ")]) : _vm._e(), _vm.inviteStatus(row.guide, _vm.EnterpriseCodeType.NO_INVITE) ? _c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.inviteECode(row);
            }
          }
        }, [_vm._v(" 邀请企业码 ")]) : _vm._e()];
      }
    }, {
      key: "sendGroupStatus",
      fn: function ({
        row
      }) {
        return [_c("StatusSend", {
          attrs: {
            status: row.sendGroupStatus
          }
        }), _c("br"), _c("StatusAuditProgress", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [row.alipayECodeEnableStatus ? _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.toggleECodeQuota(row, false);
            }
          }
        }, [_vm._v(" 停用")]) : _vm._e(), !row.alipayECodeEnableStatus ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.toggleECodeQuota(row, true);
            }
          }
        }, [_vm._v(" 启用 ")]) : _vm._e(), _vm.hasApplies(row.applies) ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.addQuote(row);
            }
          }
        }, [_vm._v(" 追加额度 ")]) : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.clearQuote(row);
            }
          }
        }, [_vm._v(" 清除额度 ")]), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(row);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(row);
            }
          }
        }, [_vm._v(" 消费明细 ")])];
      }
    }])
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogLinkCopy", {
    ref: "dialogCopyRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogDetail", {
    ref: "dialogDetailRef"
  }), _c("DialogDDApply", {
    ref: "dialogApplyRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };