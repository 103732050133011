import "core-js/modules/es.array.push.js";
import { Search, TableList } from './components/index';
import { checkBillInsurance } from '@/config/billCheck';
import { tagViewMixin, excelMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    Search,
    TableList
    // BtnBar,
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillInsurance,
      total: 0,
      statistics: null,
      filename: '保险对账'
    };
  },
  computed: {
    insuranceData() {
      return this.$store.getters['bill/billCheck/insurance'];
    }
  },
  watch: {
    insuranceData: {
      handler: function (data) {
        !data ? this.fetchData({
          startTripDateS: this.getToday
        }) : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('bill/billCheck/fetchInsurance', queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      this.statistics = statistics;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          checkAccountStatus,
          checkAccountMoney,
          totalPay,
          insuranceTypes
        } = it;
        const item = {
          ...it,
          ...it.plan,
          rowId: id,
          guidePay: it.guidePayCount || 0,
          checkAccountStatus,
          checkAccountMoney: checkAccountStatus ? checkAccountMoney : totalPay
        };
        this.$set(item, 'ckStatus', false);
        if (insuranceTypes.length) {
          insuranceTypes.forEach(s => {
            s.orderCount = s.count;
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    handleExport(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportInsuranceExcel', searchParams).then(res => {
        const fileName = '保险对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};