var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "resourch-order-hotel-pay"
  }, [_c("h3", [_vm._v("付款")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("TextBase", {
    staticClass: "label"
  }, [_vm._v(" 欠款 ")]), _c("TextBase", [_c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.getOweMoney))])])], 1), _c("div", {
    staticClass: "item"
  }, [_c("TextBase", {
    staticClass: "label"
  }, [_vm._v(" 付款方式 ")]), _c("TextBase", [_vm._v(_vm._s(_vm.getPayTypeLabel))]), _c("div", {
    staticClass: "account-info"
  }, [_c("TextBase", [_vm._v(" （已挂账金额： "), _c("span", {
    staticClass: "red"
  }, [_vm._v("￥0")])]), _c("TextBase", [_vm._v(" 剩余额度： "), _c("span", {
    staticClass: "red"
  }, [_vm._v("￥0")]), _vm._v("） ")])], 1)], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };