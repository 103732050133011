import "core-js/modules/es.array.reduce.js";
import { SignTypeLabel } from '@/config/ticket';
export default {
  data() {
    return {
      visible: false,
      imageList: []
    };
  },
  computed: {
    getImageList() {
      return this.imageList;
    },
    currentSignList() {
      return this.$store.getters['plan/index/currentSignList'];
    },
    getSignType() {
      return index => SignTypeLabel[this.currentSignList[index].signType];
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.getImagePath();
    },
    hide() {
      this.visible = false;
    },
    getImageNames() {
      const paths = this.currentSignList.reduce((acc, cur) => {
        acc = [...acc, cur.picPath];
        return acc;
      }, []);
      return paths;
    },
    async getImagePath() {
      const paths = this.getImageNames();
      if (!paths || !paths.length) return;
      const fullPath = await this.$store.dispatch('resource/common/getOssImage', paths);
      this.imageList = fullPath;
    },
    handleTransfer(key) {
      const el = document.querySelector('.ticket-bill-notify');
      const width = '600px';
      const height = '450px';
      const full = '1000px';
      const command = {
        left: function () {
          el.style.width = full;
          el.style.height = height;
        },
        down: function () {
          el.style.width = width;
          el.style.height = window.innerHeight - 160 - 40 + 'px';
        },
        full: function () {
          el.style.width = full;
          el.style.width = full;
        },
        new: () => {
          const names = this.getImageNames();
          let url = '/sign-ticket?';
          url = url + names.join('&');
          let strWindowFeatures = `
            menubar=yes,
            location=yes,
            resizable=yes,
            scrollbars=yes,
            status=yes
          `;
          window.open(url, '签单列表', strWindowFeatures);
        },
        close: () => {
          this.hide();
        }
      };
      command[key]();
    }
  }
};