export default {
  route: {
    home: 'Home',
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    backToTop: 'Back To Top',
    profile: 'Profile',
    theme: 'Theme',
    avatarUpload: 'Subir avatar',
    charts: 'Charts',
    tab: 'Pestaña',
    form: 'Formulario',
    createArticle: 'Crear artículo',
    editArticle: 'Editar artículo',
    articleList: 'Listado de artículos',
    errorPages: 'Páginas de error',
    page401: '401',
    page404: '404',
    errorLog: 'Registro de errores',
    excel: 'Excel',
    zip: 'Zip',
    pdf: 'PDF',
    Default: 'Default',
    Medium: 'Medium',
    Small: 'Small',
    Mini: 'Mini',
    dropExcel: 'Drop excel file here or',
    // upload
    upload: 'upload',
    ClickUpload: 'Click Upload',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    // 企业码管理
    enterprise: 'Enterprise Manage',
    enterpriseCheck: 'Enterprise Check',
    enterpriseGroup: 'Enterprise Group',
    enterpriseQuotaManage: 'Enterprise Quota Manage',
    enterpriseQuotaLimit: 'Enterprise Quota Limit',
    // order
    order: 'Order',
    orderTeam: 'Team',
    orderTeamInput: 'Team Create',
    orderTeamInputUpdate: 'Team Update',
    orderTeamInputDetail: 'Team Detail',
    orderTeamInputClone: 'TeamInput Clone',
    orderTeamInputBatch: 'Team Batch Create',
    orderPersonal: 'Personal',
    orderPersonalInput: 'Personal Input',
    orderPersonalInputUpdate: 'Personal Update',
    orderPersonalInputDetail: 'Personal Detail',
    orderPersonalInputClone: 'Personal Clone',
    orderDetail: 'Order Detail',
    orderUpdate: 'Order Update',
    orderReserve: 'Reserve',
    orderReserveInput: 'Reserve Input',
    orderReserveBooking: 'Reserve Booking',
    orderTurnInReceive: 'Turn In Receive',
    orderTurnOutReceive: 'Turn Out Receive',
    orderPlanAll: 'Plan All',
    orderAll: 'All',
    orderAll2: 'All2',
    orderRecycle: 'Recycle',
    orderMall: 'Mall Order',
    orderTouristBlacklist: 'Tourist Blacklist Order',
    orderAudit: 'Audit',
    orderAuditDetail: 'Audit Detail',
    orderResourcePlan: 'Resource Plan',
    inquireBus: 'Inquire Bus',
    // plan
    plan: 'Plan',
    planTeamArrange: 'Team Arrange',
    planTeamRoleArrange: 'Team Role Arrange',
    planOneKeyup: 'Boat Upload',
    planOneKeyupBoard: 'Boat Upload',
    planOneKeyupBoardDetail: 'Boat Upload Detail',
    planTurnOut: 'Turn Out',
    planGuideArrange: 'Guide Arrange',
    planGroupGuideArrange: 'plan Group Guide Arrange',
    planGuideStatistics: 'plan Guide Statistics',
    planTouristArrange: 'plan Tourist Arrange',
    planTeamArrangeTravel: 'Upload Plan Travel',
    planTeamArrangeTravelCountry: 'Upload Plan Tourteam',
    planTeamArrangeEdit: 'Team Arrange Edit',
    planSmsSend: 'SMS Message',
    planEditGuide: 'plan Edit Guide',
    planEditScenic: 'plan Edit Scenic',
    planEditHotel: 'plan Edit Hotel',
    planEditRestaurant: 'plan Edit Restaurant',
    planEditFleet: 'plan Edit Fleet',
    planEditInsurance: 'plan Edit Insurance',
    planEditTransport: 'plan Edit Transport',
    planEditPickup: 'plan Edit Pickup',
    planEditQuota: 'plan Edit Quota',
    planEditBorrow: 'plan Edit Borrow',
    planEditCollect: 'plan Edit Collect',
    planEditReception: 'plan Edit Reception',
    planEditShop: 'plan Edit Shop',
    planEditOtherIncome: 'plan Edit OtherIncome',
    planEditOtherPay: 'plan Edit OtherPay',
    planTeamArrangeDetail: 'Team Arrange Detail',
    planPickup: 'Pickup',
    planPickupBatch: 'Batch Pickup',
    planHotel: 'Hotel',
    planHotelDetail: 'Plan Hotel Detail',
    planOrderTurnOut: 'Order Turn Out',
    planCarSort: 'Car Sort',
    planGuestSmsSend: 'Guest SMS Send',
    planGuestSmsReply: 'Guest SMS Reply',
    planTeamNoticeTpl: 'Team Notice Template',
    planEnterpriseCode: 'Enterprise Code Quote',
    planBoatPersonal: 'Plan Boat',
    // shopping
    shop: 'Shopping',
    shopInput: 'Input',
    shopInputCreate: 'Shopping Create',
    shopInputDetail: 'Shopping Goods',
    shopFinance: 'Finance',
    shopAudit: 'Audit',
    shopCheck: 'Shop Check',
    shopCollect: 'Shop Collect',
    shopCollectCreate: 'Shop Collect Create',
    shopCollectDetail: 'Shop Collect Detail',
    shopCollectSubsidy: 'Shop Collect Subsidy',
    shopCollectSubsidyCreate: 'Shop Collect Subsidy Create',
    shopCollectSubsidyDetail: 'Shop Collect Subsidy Detail',
    shopTotal: 'Shop Total',
    shopStatistics: 'Shop Statistics',
    guideStatistics: 'guide Statistics',
    guideLeaderStatistics: 'guide Leader Statistics',
    guideManageStatistics: 'guide Manage Statistics',
    guideManageAnalyze: 'guide Manage Analyze',
    goodsStatistics: 'goods Statistics',
    singleStatistics: 'single Statistics',
    profitAnalysis: 'profit Analysis',
    teamStatistics: 'team Statistics',
    teamSmallStatistics: 'teamSmall Statistics',
    shopCustom: 'Shop custom',
    lineStatistics: 'line Statistics',
    shopArea: 'Shop Area',
    // audit
    audit: 'Audit',
    auditSingle: 'Audit Single',
    auditSingleDetail: 'Audit Single Detail',
    auditSingleEdit: 'Audit Single Edit',
    auditEditFee: 'Audit Edit Fee',
    auditEditGuide: 'Audit Edit Guide',
    auditEditScenic: 'Audit Edit Scenic',
    auditEditAddScenic: 'Audit Edit Scenic Extra',
    auditEditHotel: 'Audit Edit Hotel',
    auditEditRestaurant: 'Audit Edit Restaurant',
    auditEditFleet: 'Audit Edit Fleet',
    auditEditInsurance: 'Audit Edit Insurance',
    auditEditTransport: 'Audit Edit Transport',
    auditEditPickup: 'Audit Edit Pickup',
    auditEditBorrow: 'Audit Edit Borrow',
    auditEditCollect: 'Audit Edit Collect',
    auditEditReception: 'Audit Edit Reception',
    auditEditShop: 'Audit Edit Shop',
    auditEditOtherIncome: 'Audit Edit OtherIncome',
    auditEditOtherPay: 'Audit Edit OtherPay',
    auditEditSubsidyRate: 'Audit Edit SubsidyRate',
    singleFile: 'File Single',
    proxyAudit: 'Audit Proxy',
    // report
    report: 'Report',
    reportWelcome: 'Report',
    reportBooking: 'Booking Fee',
    reportDepPerformance: 'Dep Performance',
    reportCollectCustom: 'Collect Custom',
    reportCollectDetail: 'Collect Detail',
    reportCollectBalanceYear: 'Balance Year',
    reportCollectBalanceMonth: 'Balance Month',
    reportSupplier: 'Supplier',
    reportCollect: 'Collect',
    reportFleet: 'Fleet',
    reportShuttle: 'Shuttle',
    reportFleetOrder: 'Fleet Order',
    reportFleetTotal: 'Fleet Total',
    reportHotel: 'Hotel',
    reportHotelOrder: 'Hotel Order',
    reportHotelTotal: 'Hotel Total',
    roomReserve: 'Hotel Reserve',
    reportHotelRoom: 'HotelRoom',
    reportInsurance: 'Insurance',
    reportInsuranceOrder: 'Insurance Order',
    reportInsuranceTotal: 'Insurance Total',
    reportScenic: 'Scenic',
    reportScenicAdd: 'ScenicAdd',
    reportScenicOrder: 'Scenic Order',
    reportScenicTotal: 'Scenic Total',
    reportRestaurant: 'Restaurant',
    reportRestaurantOrder: 'Restaurant Order',
    reportRestaurantTotal: 'Restaurant Total',
    reportTraffic: 'Traffic',
    reportTrafficOrder: 'Traffic Order',
    reportTrafficTotal: 'Traffic Total',
    reportCustom: 'Custom',
    reportCustomOrder: 'Custom Order',
    reportCustomTotal: 'Custom Total',
    reportCustomGross: 'Custom Gross',
    reportTurnOut: 'TurnOut',
    reportTurnOutOrder: 'TurnOut Order',
    reportTurnOutTotal: 'TurnOut Total',
    reportGuide: 'Guide',
    reportGuideBorrow: 'Report Guide Borrow',
    reportGuideBorrowDetail: 'Report Guide Detail',
    reportGuideTotal: 'Guide Total',
    reportGuideSettle: 'Report Guide Settle',
    reportGuideCaptain: 'Report Guide Captain',
    reportCompany: 'Company',
    reportCompanyOrder: 'Company Order',
    reportCompanyTotal: 'Company Total',
    reportOtherPay: 'OtherPay',
    reportOtherPayTotal: 'OtherPayTotal',
    reportOtherCollect: 'OtherCollect',
    reportOtherIncome: 'OtherIncome',
    reportCustomProxy: 'CustomProxy',
    reportCustomPay: 'CustomPay',
    reportCustomCollect: 'CustomCollect',
    reportCustomSubsidy: 'CustomSubsidy',
    reportProfitCustom: 'ProfitCustom',
    reportProfitTeam: 'ProfitTeam',
    reportProfitTeamSmall: 'reportProfitTeamSmall',
    reportProfitTeamSettle: 'ProfitTeamSettle',
    reportProfitTeamSmallSettle: 'reportProfitTeamSmallSettle',
    reportBalance: 'Balance',
    reportIncome: 'Income',
    reportCountTotal: 'CountTotal',
    reportProfitTotal: 'ProfitTotal',
    reportSaleAnalysis: 'SaleAnalysis',
    reportSaleTotal: 'SaleTotal',
    reportCustomAnalysis: 'CustomAnalysis',
    reportDepartment: 'Department',
    reportOutreach: 'Outreach',
    reportPlan: 'Plan',
    reportArea: 'Area',
    reportAge: 'Age',
    reportSource: 'Source',
    reportOrder: 'Order',
    reportExportExcel: 'Export Excel',
    reportCarRoom: 'Car Room',
    reportTravelAgencyIncom: 'Travel Agency Incom',
    reportTransfer: 'Report Transfer',
    reportGuideStatistics: 'Report Guide Statistics',
    reportGuideTeamStatistics: 'Report GuideTeam Statistics',
    reportGuideReimb: 'Report Guide Reimb',
    reportOrderList: 'Report Order List',
    reportOrderFleet: 'Report Order Fleet',
    reportOrderScenic: 'Report Order Scenic',
    reportOrderRestaurant: 'Report Order Restaurant',
    reportOrderTraffic: 'Report Order Traffic',
    reportRoomRate: 'Report Room Rate',
    reportLettersEveryDay: 'Report Letters Every Day',
    reportReceivable: 'Report Receivable',
    reportReceivableDetail: 'Report Receivable Detail',
    reportReceivableBalance: 'Report Receivable Balance',
    reportAccountsBalance: 'Report Accounts Balance',
    reportOutOPUser: 'OutOP User',
    reportSaleUser: 'Sale User',
    reportDutOPUser: 'DutOP User',
    reportGroupTotal: 'Group Total',
    reportEnterpriseCode: 'Enterprise Code',
    reportEnterpriseCodeRegiment: 'Enterprise Code Regiment',
    reportRefund: 'Refund',
    // resource
    resource: 'Resource',
    resourceGuide: 'Guide',
    resourceGuideManager: 'Guide Manager',
    resourceGuideCaptain: 'Guide Captain',
    resourceFleet: 'Fleet',
    resourceDriver: 'Driver',
    resourceVehicle: 'Vehicle',
    resourceHotel: 'Hotel',
    resourceHotelCreate: 'Hotel Create',
    resourceHotelEdit: 'Hotel Edit',
    resourceHotelDetail: 'Hotel Detail',
    resourceInsurance: 'Insurance',
    resourceSelfPay: 'SelfPay',
    resourceScenic: 'Scenic',
    resourceRestaurant: 'Restaurant',
    resourceShop: 'Shop',
    resourceTraffic: 'Traffic',
    resourceLine: 'Line',
    resourceProduct: 'Product',
    resourceProductCreate: 'Product Create',
    resourceProductUpdate: 'Product Update',
    resourceProductClone: 'Product Clone',
    resourceProductPreview: 'Product Preview',
    resourceCustom: 'Custom',
    resourceOther: 'Other',
    resourceCompany: 'Company',
    resourceTicket: 'Ticket',
    resourceGroup: 'Group',
    // work
    work: 'Work',
    workInput: 'Input',
    workStream: 'Stream',
    workTotal: 'Total',
    workSubject: 'Subject',
    // settings
    system: 'Settings',
    systemConf: 'Configure',
    systemAdapter: 'Adapter',
    systemAccounting: 'Accounting',
    systemDepartmentManage: 'DepartmentManage',
    systemStaffManage: 'StaffManage',
    systemStaffAuth: 'Staff Auth',
    systemStaffGroup: 'Group Staff Auth',
    systemRoleManage: 'RoleManage',
    systemRoleAgency: 'RoleAgency',
    systemDictionary: 'Dictionary',
    systemCompany: 'Company',
    systemNotice: 'Notice',
    systemGuideNotice: 'Guide Helper',
    systemButtJoint: 'ButtJoint',
    systemProcess: 'auditProcess',
    systemCompanyGoal: 'SystemCompanyGoal',
    systemCustomGoal: 'SystemCustomGoal',
    // Ticket
    ticket: 'Ticket',
    ticketSearch: 'Search Ticket',
    ticketManage: 'Ticket Manage',
    ticketInput: 'Ticket Input List',
    ticketInputDetail: 'Ticket Input',
    ticketCheckout: 'Ticket Checkout',
    // 对账
    checkBill: 'Bill Check',
    checkBillWelcome: 'Bill Check',
    checkBillScenic: 'Scenic Check',
    checkBillHotel: 'Hotel Check',
    checkBillRestaurant: 'Restaurant Check',
    checkBillFleet: 'Fleet Check',
    checkBillShuttle: 'Shuttle Check',
    checkBillTraffic: 'Traffic Check',
    checkBillInsurance: 'Insurance Check',
    checkBillShopping: 'Shopping Check',
    checkBillReception: 'Reception Check',
    checkBillCollect: 'Collect Check',
    checkBillOtherIncome: 'OtherIncome Check',
    checkBillOtherPay: 'OtherPay Check',
    checkBillGroup: 'Group Check',
    checkBillRefund: 'Refund Check',
    checkBillChannel: 'Channel Collect Check',
    checkBillEcode: 'ECode Check',
    // 下账
    bill: 'Bill Manage',
    billWelcome: 'Bill Home',
    billRegimentCollect: 'Regiment Collect',
    billRegimentCollectCustomerCreate: 'Regiment Collect Create',
    billDownUnit: 'Bill Collect Unit',
    billCollect: 'Customer',
    billCollectUpdate: 'Customer Update',
    billCollectDetail: 'Customer Detail',
    billCollectShop: 'Shop',
    billCollectShopCreate: 'Shop Create',
    billCollectShopDetail: 'Shop Detail',
    billCollectShopSubsidy: 'Shop Subsidy',
    billCollectShopSubsidyCreate: 'Shop Subsidy Create',
    billCollectShopSubsidyDetail: 'Shop Subsidy Detail',
    billCollectOtherIncome: 'Other Income',
    billCollectOtherIncomeCreate: 'Other Income Create',
    billCollectOtherIncomeDetail: 'Other Income Detail',
    billRegimentPay: 'Regiment Pay',
    billRegimentPayScenic: 'Pay Scenic',
    billRegimentPayHotel: 'Pay Hotel',
    billRegimentPayFleet: 'Pay Fleet',
    billRegimentPayPickup: 'Pay Pickup',
    billRegimentPayRestaurant: 'Pay Restaurant',
    billRegimentPayTraffic: 'Pay Traffic',
    billRegimentPayInsurance: 'Pay Insurance',
    billRegimentPayReception: 'Pay Reception',
    billRegimentPayCustomer: 'Pay Customer',
    billRegimentPayScrenicCreate: 'Create Pay Screnic',
    billRegimentPayHotelCreate: 'Create Pay Hotel',
    billRegimentPayRestaurantCreate: 'Create Pay Restaurant',
    billRegimentPayFleetCreate: 'Create Pay Fleet',
    billRegimentPayPickupCreate: 'Create Pay Pickup',
    billRegimentPayTrafficCreate: 'Create Pay Traffic',
    billRegimentPayInsuranceCreate: 'Create Pay Insurance',
    billRegimentPayReceptionCreate: 'Create Pay Reception',
    billRegimentPayScrenicDetail: 'Create Pay Screnic',
    billRegimentPayHotelDetail: 'Create Pay Hotel',
    billRegimentPayFleetDetail: 'Create Pay Fleet',
    billRegimentPayPickupDetail: 'Create Pay Pickup',
    billRegimentPayRestaurantDetail: 'Create Pay Restaurant',
    billRegimentPayTrafficDetail: 'Create Pay Traffic',
    billRegimentPayInsuranceDetail: 'Create Pay Insurance',
    billRegimentPayReceptionDetail: 'Create Pay Reception',
    billRegimentPayCustomerDetail: 'Create Pay Customer',
    billUnitPay: 'Unit Pay',
    billUnitPayScenic: 'Pay Scenic',
    billUnitPayHotel: 'Pay Hotel',
    billUnitPayRestaurant: 'Pay Restaurant',
    billUnitPayFleet: 'Pay Fleet',
    billUnitPayPickup: 'Pay Pickup',
    billUnitPayTraffic: 'Pay Traffic',
    billUnitPayInsurance: 'Pay Insurance',
    billUnitPayReception: 'Pay Reception',
    billUnitPayOther: 'Pay Other',
    billUnitPayCustomer: 'Pay Customer',
    billUnitPayScenicCreate: 'Create Pay Scenic',
    billUnitPayHotelCreate: 'Create Pay Hotel',
    billUnitPayRestaurantCreate: 'Create Pay Restaurant',
    billUnitPayFleetCreate: 'Create Pay Fleet',
    billUnitPayPickupCreate: 'Create Pay Pickup',
    billUnitPayTrafficCreate: 'Create Pay Traffic',
    billUnitPayInsuranceCreate: 'Create Pay Insurance',
    billUnitPayReceptionCreate: 'Create Pay Reception',
    billUnitPayOtherCreate: 'Create Pay Other',
    billUnitPayCustomerCreate: 'Create Pay Customer',
    billUnitPayScenicUpdate: 'Update Pay Scenic',
    billUnitPayHotelUpdate: 'Update Pay Hotel',
    billUnitPayRestaurantUpdate: 'Update Pay Restaurant',
    billUnitPayFleetUpdate: 'Update Pay Fleet',
    billUnitPayPickupUpdate: 'Update Pay Pickup',
    billUnitPayTrafficUpdate: 'Update Pay Traffic',
    billUnitPayInsuranceUpdate: 'Update Pay Insurance',
    billUnitPayReceptionUpdate: 'Update Pay Reception',
    billUnitPayOtherUpdate: 'Update Pay Other',
    billUnitPayCustomerUpdate: 'Update Pay Customer',
    billUnitPayScenicDetail: 'Detail Pay Scenic',
    billUnitPayHotelDetail: 'Detail Pay Hotel',
    billUnitPayRestaurantDetail: 'Detail Pay Restaurant',
    billUnitPayFleetDetail: 'Detail Pay Fleet',
    billUnitPayPickupDetail: 'Detail Pay Pickup',
    billUnitPayTrafficDetail: 'Detail Pay Traffic',
    billUnitPayInsuranceDetail: 'Detail Pay Insurance',
    billUnitPayReceptionDetail: 'Detail Pay Reception',
    billUnitPayOtherDetail: 'Detail Pay Other',
    billUnitPayCustomerDetail: 'Detail Pay Customer',
    billOtherIncome: 'Other Income',
    billOtherIncomeCreate: 'Other Income Create',
    billOtherIncomeDetail: 'Other Income Detail',
    billOtherPay: 'Other Pay',
    billOtherPayCreate: 'Other Pay Create',
    billOtherPayDetail: 'Other Pay Detail',
    billCompany: 'Company',
    billAdvance: 'Advance',
    billProxy: 'Customer Proxy',
    billRefund: 'Customer Refund',
    billGuideBorrow: 'Guide Borrow',
    billGuideSingle: 'Guide Single',
    billGuideBatch: 'Guide Batch',
    billGuideSingleDetail: 'Guide Single Detail',
    billGuideBatchDetail: 'Guide Batch Detail',
    billPreCollect: 'Pre Collect',
    billCash: 'Cash',
    billAccount: 'Account',
    billAccountDetail: 'Account Detail',
    billGroup: 'Group',
    billGroupCreate: 'Group Create',
    billGroupDetail: 'Group Detail',
    billGroupUpdate: 'Group Update',
    billGuideBorrowApprove: 'Borrow Approve',
    billGuideBorrowApproveBatch: 'Batch Borrow Approve',
    // 下单管理
    resourceOrder: 'Resource Order',
    resourceOrderHotel: 'Hotel Order',
    resourceOrderFleet: 'Fleet Order',
    resourceOrderRestaurant: 'Restaurant Order',
    resourceOrderScenic: 'Scenic Order',
    // 数据分析
    analysis: 'Analysis',
    analysisIndex: 'Index',
    analysisArea: 'Area',
    analysisCustom: 'Custom',
    analysisLine: 'Line',
    analysisGuide: 'Guide',
    analysisIncome: 'Income',
    analysisPerformance: 'Performance',
    analysisDepartment: 'Department',
    analysisIncomeDetail: 'Income Detail',
    analysisShop: 'Shop',
    analysisSoilShop: 'Soil Shop',
    analysisSale: 'Sale',
    analysisCustomKpi: 'Custom Kpi',
    analysisMemberAge: 'Member Age',
    analysisMemberMap: 'Member Map',
    analysisGroup: 'Group',
    analysisComparison: 'Comparison',
    analysisTargetCompany: 'Company',
    analysisTargetDepartment: 'Department',
    analysisTargetStaff: 'Staff',
    // 增值服务
    payment: 'Payment',
    paymentIndex: 'Payment Index',
    // 报价管理
    offer: 'Offer',
    offerIndex: 'Offer Index',
    formOfferAdd: 'Offer Form Add',
    formOfferEdit: 'Offer Form Edit',
    formOfferCopy: 'Offer Form Copy'
  },
  navbar: {
    dashboard: 'Dashboard',
    github: 'Github',
    logOut: 'Log Out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    update: 'Update',
    username: 'Username',
    password: 'Password',
    phone: 'Phone Number',
    code: 'SMS Code',
    visitCode: 'Verify Code',
    getCode: 'Send Code',
    smsLogin: 'SMS Login',
    userLogin: 'User Login',
    thirdparty: 'Or connect with',
    thirdpartyTips: '',
    wxLogin: 'Wechat Login'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo',
    versionSwitch: 'Switch Version'
  }
};