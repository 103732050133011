var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.getData.length ? _c("div", [_c("h3", [_vm._v("订单信息")]), _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "车队"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.getData[0], "busName")
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "联系人"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.getData[0], "busContact")
    }
  })], 1)], 1)])]), _c("div", {
    staticClass: "table-content"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.getData,
      "column-data": _vm.columnData,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "startDate",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "startDate")
          }
        })];
      }
    }, {
      key: "startTime",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getDatetimeType(scope.row, "pickTimeQuantum").oldVal) + " "), _c("span", {
          staticClass: "red"
        }, [_vm._v(_vm._s(_vm.getDatetimeType(scope.row, "pickTimeQuantum").newVal))])];
      }
    }, {
      key: "pick",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getPickAndSendType(scope.row, "pickType").oldVal) + " "), _c("span", {
          staticClass: "red"
        }, [_vm._v(_vm._s(_vm.getPickAndSendType(scope.row, "pickType").newVal))]), _c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "pickRemarks")
          }
        })];
      }
    }, {
      key: "endDate",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "endDate")
          }
        })];
      }
    }, {
      key: "endTime",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getDatetimeType(scope.row, "sendTimeQuantum").oldVal) + " "), _c("span", {
          staticClass: "red"
        }, [_vm._v(_vm._s(_vm.getDatetimeType(scope.row, "sendTimeQuantum").newVal))])];
      }
    }, {
      key: "send",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getPickAndSendType(scope.row, "sendType").oldVal) + " "), _c("span", {
          staticClass: "red"
        }, [_vm._v(_vm._s(_vm.getPickAndSendType(scope.row, "sendType").newVal))]), _c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "sendRemarks")
          }
        })];
      }
    }, {
      key: "busType",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "busType")
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "count")
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "remarks")
          }
        })];
      }
    }], null, false, 2334905940)
  })], 1), _c("div", {
    staticClass: "input-field mt20"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("TextBase", [_vm._v(" 应付 ")]), _c("TextBase", [_c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.getData[0].totalPay))])])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };