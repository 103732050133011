var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.groupId > 0 ? _c("el-tag", {
    attrs: {
      type: "danger"
    }
  }, [_vm._v(" 集团 ")]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };