import { OrderType } from '@/config/order';
export const defaultMenu = [{
  id: 1,
  label: '组团出团回执单',
  component: 'Receipt',
  reqUrl: 'order/print/fetchExportReceipt',
  code: 'print:order:receipt',
  tpl: ['Receipt', 'Receipt2', 'Receipt3'],
  params: {
    Receipt: {
      type: 1
    },
    Receipt2: {
      type: 2
    },
    Receipt3: {
      type: 3
    }
  }
}, {
  id: 2,
  label: '导游出团单',
  component: 'PlanPersonal',
  // reqUrl: 'order/print/fetchExportPlanTeam',
  reqUrl: 'order/print/fetchPersonalCustomer',
  code: 'print:order:plan-personal',
  tpl: false
}, {
  id: 3,
  label: '游客接送表',
  component: 'Pickup',
  reqUrl: '',
  code: 'print:order:pickup',
  tpl: false
}, {
  id: 4,
  label: '团队客人名单表',
  component: 'TeamName',
  reqUrl: '',
  code: 'print:order:team-tourist',
  tpl: false
}

// { id: 4, label: '费用结算单', component: 'Settlement', reqUrl: '' },
];
export const planMenu = [{
  id: 5,
  label: '游客接送表',
  component: 'Pickup',
  reqUrl: '',
  code: 'print:team:pickup-tourist',
  tpl: false
}, {
  id: 11,
  label: '购物统计表',
  component: 'PlanShopping',
  reqUrl: '',
  code: 'print:team:shopping-statistics',
  tpl: false
}, {
  id: 12,
  label: '保险名单',
  component: 'PlanInsurance',
  reqUrl: 'order/print/fetchExportPlanMemberlist',
  code: 'print:plan:insurance',
  tpl: false
}, {
  id: 13,
  label: '导游分佣表',
  component: 'GuideBrokerage',
  reqUrl: 'order/print/fetchExportGuideSubCommission',
  code: 'print:guide:rokerage',
  tpl: false
}, {
  id: 15,
  label: '导游分佣表2',
  component: 'GuideBrokerage2',
  reqUrl: 'order/print/fetchExportGuideSubCommission',
  params: {
    type: 2,
    filename: '导游分佣表2.xlsx'
  },
  code: 'print:guide:rokerage2',
  tpl: false
}, {
  id: 14,
  label: '导游报账表',
  component: 'GuideSubmit',
  reqUrl: '',
  code: 'print:guide:submit',
  tpl: ['GuideSubmit', 'GuideSubmit2'],
  params: {
    GuideSubmit: {
      searchType: 1
    },
    GuideSubmit2: {
      searchType: 2
    }
  }
}, {
  id: 16,
  label: '单团审核表',
  component: 'PlanProfitDetail',
  reqUrl: 'order/print/fetchExportPlanProfitReport',
  code: 'print:team:plan-profit',
  tpl: false
}];
export const BoardNameObj = {
  [OrderType.team]: {
    id: 100,
    label: '团队船票名单表',
    component: 'BoardNameTeam',
    reqUrl: '',
    code: 'print:order:board-tourist',
    tpl: false
  },
  [OrderType.personal]: {
    id: 100,
    label: '散客船票名单表',
    component: 'BoardNamePersonal',
    reqUrl: '',
    code: 'print:order:board-tourist',
    tpl: false
  }
};
export const teamArr = [{
  id: 17,
  label: 'JY团队计划单',
  component: 'JYPlanTeam',
  reqUrl: 'order/print/fetchExportPlanTeam',
  params: {
    type: 2
  },
  code: 'print:team:plan-order-jy',
  tpl: false
}, {
  id: 7,
  label: '团队计划单',
  component: 'PlanTeam',
  reqUrl: 'order/print/fetchExportPlanTeam',
  code: 'print:team:plan-order',
  tpl: ['PlanTeam', 'PlanTeamJzl', 'PlanTeamMhjq'],
  params: {
    PlanTeam: {
      type: 1
    },
    PlanTeamJzl: {
      type: 2
    },
    PlanTeamMhjq: {
      type: 3
    }
  }
}, {
  id: 8,
  label: '团队客人名单表',
  component: 'PlanTeamName',
  reqUrl: 'order/print/fetchTeamMemberlist',
  code: 'print:team:plan-tourist',
  tpl: false
}];
export const personalArr = [{
  id: 9,
  label: '散客计划单',
  component: 'PlanPersonal',
  reqUrl: 'order/print/fetchPersonalCustomer',
  code: 'print:personal:plan-order',
  tpl: ['PlanPersonal', 'PlanPersonalTpl2'],
  params: {
    PlanPersonal: '',
    PlanPersonalTpl2: ''
  }
}, {
  id: 10,
  label: '散客客人名单表',
  component: 'PlanPersonalName',
  reqUrl: 'order/print/fetchPersonalMemberlist',
  code: 'print:personal:plan-tourist',
  tpl: false
}];

// 权限
export const positionAction = {
  audit: {
    pCode: '/audit',
    code: '/audit/team-single'
  },
  plan: {
    pCode: '/plan',
    code: '/plan/team-arrange'
  },
  plan2: {
    pCode: '/plan2',
    code: '/plan/team-role-arrange'
  },
  personal: {
    pCode: '/order',
    code: '/order/personal'
  },
  team: {
    pCode: '/order',
    code: '/order/team'
  }
};