import { downloadExport } from '@/utils/exportExcel';
import TableList from './TableList';
import { ByGuideManagerData } from '@/config/shopping';
import { BtnExport, SelectGuideManager } from '@/components/common';
import { searchMixin, subsidyMixin } from '@/mixin';
export default {
  components: {
    TableList,
    BtnExport,
    SelectGuideManager
  },
  mixins: [searchMixin, subsidyMixin],
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: ByGuideManagerData,
    shopDate: [],
    tableData: []
  }),
  computed: {
    guideManagerData() {
      return this.$store.getters['shopping/index/guideManagerData'];
    }
  },
  watch: {
    guideManagerData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.tableData = data;
        this.tableData = this.processTotalShopRebate(data);
      },
      immediate: true
    }
  },
  methods: {
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getByGuideManagerStatic', queryParam).then(() => {
        // const { list, totalCount } = res
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchByGuideManagerStaticExcel', this.searchParams).then(res => {
        const fileName = '导管统计.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};