// import billDownMixin from '../../billDownMixin'
import billDownMixin from '@/views/billDown/billDownMixin';
import { summaryMixin } from '@/mixin';
import { isBackMoney, processMinus } from '@/utils';
import { CustomerInfo } from '@/components/common';
import { downBillGuideUnPay as columnData } from '@/config/billDown';
export default {
  components: {
    CustomerInfo
  },
  mixins: [summaryMixin, billDownMixin],
  data() {
    return {
      columnData,
      tableData: [],
      form: {},
      billCheckAll: false,
      statisticsKeys: ['totalPay', 'totalPayed', 'owePay', 'downPayed'],
      data: []
    };
  },
  computed: {
    getOwePayMoney() {
      return row => {
        return processMinus(row.totalPay, row.totalPayed);
      };
    },
    autoStatus() {
      return this.$store.getters['bill/billDown/autoStatus'];
    },
    statistics() {
      return this.$store.getters['bill/guide/statistics'];
    },
    getUnpay() {
      return processMinus(this.statistics.totalPay, this.statistics.totalPayed);
    },
    billInputData() {
      return this.$store.getters['bill/billDown/billInputForm'];
    },
    billInputMoney() {
      const data = this.billInputData;
      return data ? data.money : 0;
    },
    // autoDown() {
    //   return this.$store.getters['bill/guide/autoDown']
    // },

    // downMoney() {
    //   return this.$store.getters['bill/guide/downMoney']
    // },

    getTableData() {
      const positive = this.filterData();
      const negetive = this.filterData(false);
      const res = [...positive, ...negetive];
      return res;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(params = {}) {
      const {
        rid: resourceId,
        planId
      } = this.$route.query;
      this.fetchUnPayData({
        guideId: resourceId,
        planId,
        ...params
      });
    },
    fetchUnPayData(params = {}) {
      return this.$store.dispatch('bill/billDown/fetchUnPayGuide', params).then(data => {
        if (!data) return;
        this.handleData(data);
        this.caclDownMoney();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableData = list;
      this.total = totalCount;
    },
    // 搜索
    handleSearch() {
      this.fetchData(this.form);
    },
    filterData(flag = true) {
      return this.tableData.filter(it => flag ? !isBackMoney(it.feeType) : isBackMoney(it.feeType));
    },
    // 总计金额
    calcMoney() {
      const totalPay = this.calcTotalMoney('totalPay');
      const totalPayed = this.calcTotalMoney('totalPayed');
      const downPayed = this.calcTotalMoney('downPayed');
      this.$store.commit('bill/guide/SET_STATISTICS', {
        totalPay,
        totalPayed,
        downPayed
      });
      return {
        totalPay,
        totalPayed,
        downPayed
      };
    },
    changeInput(row, val) {
      this.changeDownPay(row, val);
      this.calcMoney();
      this.isSelectedAll();
      const money = this.calcCheckedMoney();
      this.setBillDownUseMoney(money);
      this.autoStatus && this.setBillDownUse({
        money,
        userMoney: money
      });
    },
    handleCheckAll(val) {
      this.tableData.forEach(it => {
        this.toggleCheckbox(it, val);
      });
      // this.billCheckAll = val
    },
    toggleCheckbox(row, val) {
      const {
        owePayCopy
      } = row;
      row.isChecked = val;
      row.downPayed = val ? owePayCopy : '';
      row.owePay = val ? 0 : owePayCopy;
      this.calcMoney();
      this.isSelectedAll();
      const money = this.calcCheckedMoney();
      this.setBillDownUseMoney(money);
      this.autoStatus && this.setBillDownUse({
        money,
        userMoney: money
      });
    },
    // changeCheckAll(val) {
    //   const data = this.getTableData

    //   // 自动填充下账数据
    //   this.data = data.map(it => {
    //     if (isBackMoney(it.feeType)) {
    //       it.downPayed = val ? it.owePayCopy : ''
    //     } else {
    //       it.downPayed = val ? it.owePayCopy : ''
    //     }
    //     // it.totalPayed = val ? it.owePayCopy : 0
    //     it.isChecked = val
    //     this.billCheckAll = val
    //     return it
    //   })

    //   this.calcMoney()
    // },

    // checkAll() {
    //   this.billCheckAll = this.getTableData.every(it => it.isChecked)
    // },

    // // 勾选
    // changeCheckbox(row, val) {
    //   row.downPayed = val
    //     ? isBackMoney(row.feeType)
    //       ? row.owePay
    //       : row.owePay
    //     : ''
    //   row.totalPayed = row.downPayed || 0

    //   this.calcMoney()
    //   this.checkAll()
    // },

    // // 手动输入
    // changeDownPay(row) {
    //   row.isChecked = !(row.downPayed == 0 || row.downPayed == '')
    //   // 如果金额为0，则检查全选状态
    //   !row.isChecked && this.checkAll()
    //   this.calcMoney()
    // },

    caclDownMoney(money) {
      if (!this.tableData.length) return;
      // mixin方法
      this.handleAutoDown(money);
      this.calcMoney();
    }
  }
};