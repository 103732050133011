import { getTableColumnStorage } from '@/utils/storage';
export default {
  name: 'TableTotal',
  props: {
    align: {
      type: String,
      default: 'center'
    },
    columnData: {
      type: Array,
      required: true
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnCopy: this.columnData,
      tableStyle: {}
    };
  },
  computed: {
    getTableStyle() {
      return this.tableStyle;
    },
    getData() {
      return [this.$attrs.data];
    }
  },
  mounted() {
    this.getCheckColmunsFormStorage();
    this.$bus.on('column:reset', () => {
      this.getCheckColmunsFormStorage();
    });
  },
  methods: {
    hideScroll() {
      if (this.hideScrollBar) {
        const el = this.$el.querySelector('.el-table__body-wrapper');
        // el.style.overflowX = 'hidden'
        el.style['webkitScrollbar'].display = 'none';
      }
    },
    getCheckColmuns() {
      const filterData = this.checkedColumns.filter(it => it.isHide).map(it => it.label);

      // 兼容处理
      const checkedColumns = filterData.length ? filterData : this.checkedColumns;
      this.columnCopy = this.columnData.filter(it => {
        return checkedColumns.includes(it.attrs.label);
      });
    },
    getCheckColmunsFormStorage() {
      const data = getTableColumnStorage();
      if (data && data[this.$route.path]) {
        this.checkedColumns = data[this.$route.path];
        // 表格样式
        this.tableStyle = {
          ...this.tableStyle,
          ...data['style']
        };
        this.getCheckColmuns();
      } else {
        this.checkedColumns = this.columnData.map(it => {
          return it.attrs.label;
        });
      }
      this.getCheckColmuns();
    }
  }
};