import request from '@/utils/request';

// 大交通
export function trafficBookingRequest(params) {
  const url = '/order/report2/report-transport-type-static';
  return request({
    url,
    method: 'get',
    params
  });
}