import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { systemCompanyBill } from '@/config/system';
export default {
  data() {
    return {
      inputList: []
    };
  },
  computed: {
    ...mapGetters('system/companyInfo', ['companyInfo'])
  },
  watch: {
    companyInfo: [{
      handler: 'formatBillInput',
      immediate: true,
      deep: true
    }]
  },
  methods: {
    formatBillInput(val) {
      if (!val) return;
      let list = [];
      systemCompanyBill.forEach(it => {
        list.push({
          text: it.text,
          input: this.companyInfo[it.label]
        });
      });
      this.inputList = list;
    },
    handleChange(index, val) {
      const type = systemCompanyBill[index].label;
      this.$store.commit('system/companyInfo/CHANGE_COMPANY_INFO', {
        type,
        val
      });
    }
  }
};