import "core-js/modules/es.array.push.js";
import { TopBar, DelCheckAll, Audit, AuditCheck, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { SelectStaff, SelectBankCard, StatusAudit, SelectAccount } from '@/components/common';
import { selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { CashWay, cashModeType } from '@/config/billDown';
import { parseDate } from '@/utils';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  guideId: '',
  guideName: '',
  cashWay: CashWay.CARD,
  cashWayLabel: CashWay.CARD,
  cashMode: cashModeType.COMMON,
  cashDate: parseDate(),
  borrowDate: parseDate(),
  bankId: '',
  remarks: '',
  borrowMoney: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait
});
export default {
  components: {
    TopBar,
    DelCheckAll,
    Audit,
    AuditAdmin,
    AuditRoleAll,
    AuditCheck,
    AuditStatus,
    CheckStatus,
    StatusAudit,
    SelectStaff,
    SelectBankCard,
    SelectAccount
  },
  mixins: [selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'guideBorrowApplyStatus',
      deletePath: 'audit/index/deleteGuideBorrow',
      updatePath: 'audit/index/createGuideBorrow',
      fetchPath: 'audit/index/fetchGuideBorrow',
      auditAll: false,
      rowId: 100000000000000,
      checkedAll: false,
      mergeTable: false
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId',
      userData: 'user/userData',
      guideData: 'plan/guide/guideData',
      mainGuide: 'plan/guide/mainGuide',
      accountData: 'resource/account/accountData',
      subjectData: 'system/accounting/subjectData'
    }),
    isAudited() {
      return row => row.auditProgress != AuditRole.wait;
    },
    isRoleDisabled() {
      return row => {
        if (this.showGuideBorrowAuditCol) {
          let status = this.hasAudit(row.auditProgress);
          if (this.getRole('admin')) {
            status = row.auditProgress === AuditRole['admin'];
          }
          return status;
        } else {
          return this.isRowDisable(row) || this.isAudited(row);
        }
      };
    }
  },
  created() {
    let {
      id,
      planId
    } = this.$route.query;
    id = planId || id;
    this.fetchData({
      id
    });
    if (!this.guideData) {
      this.fetchGuideData();
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    fetchGuideData() {
      let {
        id,
        planId
      } = this.$route.query;
      id = planId || id;
      this.$store.dispatch('plan/guide/fetch', {
        planId: id
      });
    },
    // 新增一条
    handlePlus() {
      if (!this.mainGuide) {
        this.$bus.tip({
          type: 'warning',
          message: '请先添加报账导游'
        });
        return;
      }
      const {
        guideId,
        guideName
      } = this.mainGuide;
      const {
        id
      } = this.userData;
      const accountId = this.accountData && this.accountData.list.length && this.accountData.list[0].id;
      const {
        name,
        id: subjectId
      } = this.findSubjectByName();
      this.tableData.push({
        ...defaultState(),
        guideId,
        guideName,
        bankId: accountId || '',
        subject: name || [''],
        subjectId,
        cashUser: id,
        rowId: ++this.rowId
      });
    },
    // 选择导游
    selectGuide(row, val) {
      const {
        label
      } = val;
      row.guideName = label;
    },
    selectBorrowType(row, val) {
      row.cashWay = val ? val.label : '';
    },
    selectAccount(row, val) {
      const {
        id,
        name,
        subId
      } = val;
      if (subId) {
        row.subjectId = subId;
        row.psubjectId = id;
      } else {
        row.subjectId = id;
        row.subjectName = name;
      }
    },
    selectCashWay(row, val) {
      row.cashWay = val.label;
    },
    findSubjectByName(name = '导游借款') {
      const data = this.subjectData;
      let result = {
        name,
        subjectId: ''
      };
      if (!data) return result;
      data.forEach(item => {
        if (item.name === name) {
          result = item;
        } else {
          const nestedItem = item.subSubjects.find(subItem => subItem.name === name);
          if (nestedItem) {
            result = nestedItem;
          }
        }
      });
      return result;
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      const {
        name,
        id: subjectId
      } = this.findSubjectByName();
      return list.map(it => {
        const {
          id,
          auditProgress,
          cashWay
        } = it;
        let cashItem = {};
        if (it.cash) {
          const {
            cashUserName
          } = it.cash;
          cashItem = {
            ...it.cash,
            cashUser: cashUserName,
            cashWayLabel: it.cash.cashWay
          };
        }
        const item = {
          ...it,
          rowId: id,
          $rowId: id,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          ap: auditProgress,
          auditProgress,
          type: cashWay,
          // 会计科目
          subject: name || [''],
          subjectId,
          cashWay: CashWay.CARD,
          cashWayLabel: CashWay.CARD,
          cashMode: cashModeType.COMMON,
          // 导游借款
          ...cashItem,
          remarks: it.remarks
        };
        this.rowId++;
        return item;
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.planId = this.currentPlanId;
        const {
          bankId,
          cashDate,
          cashMode,
          cashWay,
          psubjectId,
          subjectId
        } = it;
        it.id = it.$rowId || '';
        const cash = {
          bankId,
          cashDate,
          cashMode,
          cashWay,
          psubjectId,
          subjectId
        };
        // cash
        it.cash = cash;
        // if (it.ap == AuditRole.finance || it.status) {
        //   it.cash = cash
        // }

        // if (!it.status) {
        //   delete it.cash
        // }

        // 当角色为管理员时，需要单独处理审核状态
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          if (this.isAdminAudit) {
            it.status = it.ap != 'WAITAUDIT';
          }
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          guideId,
          borrowMoney
        } = row;
        if (!guideId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择导游！'
          });
          return false;
        }
        if (!borrowMoney) {
          this.$bus.tip({
            type: 'warning',
            message: '请输入借款金额！'
          });
          return false;
        }
      }
      return true;
    }
  }
};