import { generateTableItem } from './helper';
export const Columns = [{
  attrs: {
    prop: 'seqOrder',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'name',
    label: '组团社'
  }
}, {
  attrs: {
    prop: 'customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'customerAreaService',
    label: '计调'
  }
}, {
  attrs: {
    prop: 'orders',
    label: '订单数'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: '',
    label: '合计'
  },
  children: [{
    attrs: {
      prop: '',
      label: '应收团款'
    },
    children: [{
      attrs: {
        prop: 'unTotalBalance',
        label: '应收余额'
      }
    }, {
      attrs: {
        prop: 'totalFee',
        label: '应收'
      }
    }, {
      attrs: {
        prop: 'totalCollect',
        label: '本月已收'
      }
    }, {
      attrs: {
        prop: 'totalIncomed',
        label: '跨月已收'
      }
    }, {
      attrs: {
        prop: 'unTotalFee',
        label: '未收'
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '应付返款'
    },
    children: [{
      attrs: {
        prop: 'totalSubsidy',
        label: '应付'
      }
    }, {
      attrs: {
        prop: 'totalSubsidyed',
        label: '跨月已付'
      }
    }, {
      attrs: {
        prop: 'unTotalSubsidy',
        label: '未付'
      }
    }]
  }]
}, ...generateTableItem()];