export default {
  props: {
    columnData: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  }
};