var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "remarks"
  }, _vm._l(_vm.remarks, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "textarea-box"
    }, [_c("span", [_vm._v(_vm._s(item.text))]), _c("el-input", _vm._g(_vm._b({
      attrs: {
        type: "textarea",
        autosize: {
          minRows: 4,
          maxRows: 6
        },
        placeholder: item.text
      },
      on: {
        change: function ($event) {
          return _vm.handleChange(item.name, $event);
        }
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, "el-input", _vm.$attrs, false), _vm.$listeners))], 1);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };