import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
export default {
  mixins: [tableMixin, shopMixin],
  data() {
    return {
      sumsIndex: 0,
      avgeageKey: [2, 4, 5] // 计算人均
    };
  },
  methods: {}
};