import "core-js/modules/es.array.push.js";
import { tableMixin, permissionMixin } from '@/mixin';
import { Guides, StatusPlan } from '@/components/common';
import { PersonCount } from '@/components/common';
import { TicketAuditStatus, TicketStatusEnum } from '@/config/ticket';
export default {
  components: {
    PersonCount,
    Guides,
    StatusPlan
  },
  mixins: [tableMixin, permissionMixin],
  props: {
    isAudit: {
      type: Boolean,
      default: false
    },
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      TicketAuditStatus,
      auditStatusShow: ''
    };
  },
  computed: {
    isWaited() {
      return status => {
        return status == TicketStatusEnum.WAITAUDIT;
      };
    },
    isComplete() {
      return status => {
        return status == TicketStatusEnum.COMPLETED;
      };
    },
    isUnArrange() {
      return status => {
        return status == TicketStatusEnum.UNARRANGE;
      };
    },
    getTypeStatus() {
      return status => {
        let type = 'primary';
        if (this.isComplete(status)) {
          type = 'success';
        }
        if (this.isUnArrange(status)) {
          type = 'warning';
        }
        return type;
      };
    },
    getStatusText() {
      // 待派单、已派单、待核单、已核单
      return status => {
        return TicketAuditStatus[status];
      };
    }
  },
  methods: {
    handleCreate(row) {
      const path = '/ticket/ticket-input/detail';
      this.handleRedirect(row, path);
    },
    handleAudit(row) {
      const path = '/ticket/ticket-check/detail';
      this.handleRedirect(row, path);
    },
    handleRedirect(row, path) {
      const {
        id,
        behindhandSign
      } = row;
      this.$store.commit('ticket/index/CURRENT_PLAN', row);
      this.$router.push({
        path,
        query: {
          id,
          behindhandSign
        }
      });
    },
    handleTransfer(id) {
      this.$emit('on-select', id);
    },
    getRowStyle({
      row,
      columnIndex
    }) {
      const colIndex = this.columnData.length - 2;
      if (row.signSheetStatus == TicketStatusEnum.WAITAUDIT) {
        if (columnIndex == colIndex) {
          return {
            background: '#F56C6C'
          };
        }
      }
      if (row.signSheetStatus == TicketStatusEnum.COMPLETED) {
        if (columnIndex == colIndex) {
          return {
            background: '#67C23A'
          };
        }
      }
      return {
        background: 'transparent'
      };
    }
  }
};