import { inputMixin } from '@/mixin';
// import { formartInputData } from '@/utils/data'
import { mapGetters } from 'vuex';

/**
 * @module components/common/Input
 * @desc 科目
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    initVal: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: '科目'
    }
  },
  data() {
    return {
      curVal: '',
      list: []
    };
  },
  computed: {
    ...mapGetters('system/accounting', ['oneList'])
  },
  watch: {
    oneList: {
      handler: function (data) {
        if (!data) return;
        this.list = data.list;
      },
      immediate: true
    }
  }
};