var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FooterScrollBar", {
    attrs: {
      "real-width": _vm.realWidth,
      width: _vm.tableWidth
    },
    on: {
      "on-scroll": _vm.handleBarScroll
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };