var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.title,
      width: "900px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.hide,
      cancel: _vm.hide
    }
  }, [_c("div", {
    staticClass: "form-wrapper"
  }, [_c("TableAuditList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.loading
    }
  }), _c("FormAudit", {
    ref: "formRef"
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };