var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tags-view-container",
    attrs: {
      id: "tags-view-container"
    }
  }, [_c("scroll-pane", {
    ref: "scrollPane",
    staticClass: "tags-view-wrapper"
  }, [_c("span", {
    class: _vm.isCollapse ? "el-icon-s-fold" : "el-icon-s-unfold",
    staticStyle: {
      "font-size": "32px",
      position: "relative",
      top: "8px",
      padding: "5px 15px 0 15px"
    },
    on: {
      click: _vm.toggleSideBar
    }
  }), _vm._l(_vm.visitedViews, function (tag) {
    return _c("router-link", {
      key: tag.path,
      ref: "tag",
      refInFor: true,
      staticClass: "tags-view-item",
      class: _vm.isActive(tag) ? "active" : "",
      style: _vm.isActive(tag) && _vm.getStyle,
      attrs: {
        to: {
          path: tag.path,
          query: tag.query,
          fullPath: tag.fullPath
        },
        tag: "span"
      },
      nativeOn: {
        mouseup: function ($event) {
          if ("button" in $event && $event.button !== 1) return null;
          !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : "";
        },
        contextmenu: function ($event) {
          $event.preventDefault();
          return _vm.openMenu(tag, $event);
        }
      }
    }, [_c("LabelBase", {
      attrs: {
        label: _vm.generateTitle(tag.title),
        semi: false
      }
    }), !_vm.isAffix(tag) ? _c("span", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.closeSelectedTag(tag);
        }
      }
    }) : _vm._e()], 1);
  })], 2), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "contextmenu",
    style: {
      left: _vm.left + "px",
      top: _vm.top + "px"
    }
  }, [_c("li", {
    on: {
      click: function ($event) {
        return _vm.refreshSelectedTag(_vm.selectedTag);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tagsView.refresh")) + " ")]), !_vm.isAffix(_vm.selectedTag) ? _c("li", {
    on: {
      click: function ($event) {
        return _vm.closeSelectedTag(_vm.selectedTag);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tagsView.close")) + " ")]) : _vm._e(), _c("li", {
    on: {
      click: _vm.closeOthersTags
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tagsView.closeOthers")) + " ")]), _c("li", {
    on: {
      click: function ($event) {
        return _vm.closeAllTags(_vm.selectedTag);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tagsView.closeAll")) + " ")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };