var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.$emit("on-check", $event);
      }
    },
    model: {
      value: _vm.getValue,
      callback: function ($$v) {
        _vm.getValue = $$v;
      },
      expression: "getValue"
    }
  }), _c("span", {
    staticClass: "pr5"
  }), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.batchRemove
    }
  }, [_vm._v(" 批量删除 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };