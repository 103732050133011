import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { systemCompanyGoal, KPIType } from '@/config/system';
export default {
  components: {},
  data() {
    return {
      loading: true,
      columnData: systemCompanyGoal,
      turnoverData: [],
      numberOfPeopleData: [],
      profitData: []
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    })
  },
  created() {
    this.fetchData({
      KPIType: KPIType.turnover
    });
    this.fetchData({
      KPIType: KPIType.numberOfPeople
    });
    this.fetchData({
      KPIType: KPIType.profit
    });
  },
  methods: {
    fetchData(opts) {
      this.$store.dispatch('system/index/fetchCustomerYearKpi', opts).then(res => {
        res.list.forEach(it => {
          if (it.kpitype == KPIType.turnover) {
            this.turnoverData.push(it);
          }
          if (it.kpitype == KPIType.numberOfPeople) {
            this.numberOfPeopleData.push(it);
          }
          if (it.kpitype == KPIType.profit) {
            this.profitData.push(it);
          }
        });
      });
    }
  }
};