var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper form-fix-bar"
  }, [_c("h3", [_vm._v("已结清数据")]), _c("TablePayed", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      lock: true
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };