import Reception from '@/components/common/Reception';
import { mapGetters } from 'vuex';
export default {
  components: {
    Reception
  },
  props: {
    planList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      form: {},
      activeName: 'fee',
      tabIndex: 0,
      item: null,
      index: 0
    };
  },
  computed: {
    ...mapGetters({
      receptionList: 'order/reception/receptionList'
    })
  },
  methods: {
    show(index) {
      this.visible = true;
      this.index = index;
      this.item = this.receptionList[index];
    },
    hide() {
      this.visible = false;
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
    },
    createOrUpdate() {
      const {
        receptionRef
      } = this.$refs;
      const {
        customerOrderId
      } = this.item;
      return receptionRef.createOrUpdate(customerOrderId).then(() => {
        this.$bus.emit('reception:reload');
        this.$bus.tip();
        this.hide();
      });
    }
  }
};