import "core-js/modules/es.array.push.js";
import { BtnExport, SelectDictionary, SelectCustom, SelectStaff } from '@/components/common';
import { customerUnIncome } from '@/config/report';
import { mapGetters } from 'vuex';
import TableAccounts from './TableAccounts';
import { exportTable } from '@/utils/exportTableUtil';
import { decimalSplit, parseDate } from '@/utils';
import { searchMixin } from '@/mixin';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    BtnExport,
    SelectDictionary,
    SelectCustom,
    SelectStaff,
    TableAccounts
  },
  mixins: [searchMixin],
  data: () => ({
    searchParams: {
      startTripDateS: '2022-01-01',
      startTripDateE: today,
      customerIsEnable: 1,
      customerIsEnableLabel: '已启用'
    },
    sendDate: ['2022-01-01', today],
    columnData: [],
    tableData: [],
    areaData: [],
    isTable: false,
    children: [],
    keys: [],
    summaryData: []
  }),
  computed: {
    ...mapGetters({
      unIncomeData: 'report/index/unIncomeData'
    })
  },
  watch: {
    unIncomeData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      const item = this.formatData(data);
      this.tableData = item.map(it => {
        this.keys.forEach(key => {
          it[key] = 0;
          it.areaItem.forEach(v => {
            it[key] = decimalSplit(Number(it[key]) + Number(v[key]));
          });
        });
        return it;
      });
      let itemKeys = {};
      this.keys.forEach(key => {
        itemKeys[key] = 0;
        itemKeys.totalIncome = 0;
      });
      const keys = Object.keys(itemKeys);
      this.tableData.forEach(it => {
        keys.forEach(key => {
          itemKeys[key] = decimalSplit(Number(itemKeys[key]) + Number(it[key]));
        });
      });
      this.summaryData = [itemKeys];
    },
    onChangData(date) {
      const [start, end] = date;
      this.sendDate = date;
      this.searchParams.startTripDateS = start;
      this.searchParams.startTripDateE = end;
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    onSelectEnable(val) {
      this.searchParams.customerIsEnableLabel = val ? val.label : '';
      this.searchParams.customerIsEnable = val ? val.value : '';
    },
    onSelectArea(val) {
      this.searchParams.customerArea = val ? val.label : '';
    },
    exportExcel() {
      // 导出 excel
      if (!this.validateForm()) return;
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt = document.getElementById('export-table');
      exportTable(elt, '应收余额表', '应收余额表导出', 2);
      loading.close();
    },
    fetchData(searchParams) {
      if (!this.validateForm()) return;
      this.tableData = [];
      this.summaryData = [];
      this.isTable = false;
      this.$store.dispatch('report/index/fetchUnIncome', searchParams);
    },
    formatData(list) {
      if (!list || !list.length) return [];
      let index = 0;
      list.forEach(it => {
        const {
          customerArea
        } = it;
        it.areaItem = [];
        it.totalIncome = 0;
        it.areaStatics.forEach(v => {
          const {
            areaServiceId,
            areaServiceName
          } = v;
          v.areaServiceStatics.forEach(item => {
            item.totalIncome = 0;
            item.yearStatics.forEach(z => {
              item[z.year] = z.unTotalIncome;
              item.totalIncome = decimalSplit(Number(item.totalIncome) + Number(z.unTotalIncome));
            });
          });
          if (v.areaServiceStatics.length) {
            v.areaServiceStatics.forEach((item, ix) => {
              it.totalIncome += item.totalIncome;
              it.areaItem.push({
                rowIndex: ix + 1,
                index: index += 1,
                ...item,
                areaServiceId,
                areaServiceName,
                customerArea
              });
            });
          }
        });
      });
      this.children = list[0].areaStatics[0].areaServiceStatics[0].yearStatics.map(it => {
        this.keys.push(`${it.year}`);
        const year = {
          attrs: {
            prop: `${it.year}`,
            label: `${it.year}年`
          }
        };
        return year;
      });
      const afterbody = [{
        attrs: {
          prop: '',
          label: '账龄明细'
        },
        children: [{
          attrs: {
            prop: 'totalIncome',
            label: '应收余额'
          },
          slot: 'totalIncome'
        }, ...this.children]
      }, {
        attrs: {
          prop: 'remarks',
          label: '备注'
        }
      }];
      this.columnData = [...customerUnIncome, ...afterbody];
      this.isTable = true;
      return list;
    },
    validateForm() {
      if (!this.searchParams.startTripDateS) {
        this.$bus.tip({
          type: 'warning',
          message: '开始日期不能为空！'
        });
        return false;
      }
      if (!this.searchParams.startTripDateE) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期不能为空！'
        });
        return false;
      }
      return true;
    }
  }
};