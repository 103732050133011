// import { formartInputData } from '@/utils/data'
import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '线路'
    }
  },
  data: () => ({
    list: []
  }),
  computed: {
    ...mapGetters('resource/product', ['productData'])
  },
  watch: {
    productData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.list = data.list;
    }
  }
};