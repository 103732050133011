import { permissionMixin } from '@/mixin';
export default {
  mixins: [permissionMixin],
  props: {
    searchText: {
      type: String,
      default: '搜索'
    },
    createText: {
      type: String,
      default: '添加'
    },
    optIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSearch() {
      this.$emit('on-search');
    },
    handleCreate() {
      this.$emit('on-create');
    }
  }
};