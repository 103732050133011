var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      width: "1360",
      trigger: "manual"
    },
    on: {
      show: _vm.showOrderData
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "flex flex-end"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-close",
      circle: ""
    },
    on: {
      click: function ($event) {
        _vm.visible = false;
      }
    }
  })], 1), _c("TableBase", {
    attrs: {
      data: _vm.orderData,
      "column-data": _vm.ResourceOrder,
      "cell-class-name": _vm.tableCellClass,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startPlanDate))]), _c("div", [_vm._v(_vm._s(scope.row.endPlanDate))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "roomInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getRoomCount(scope.row.hotelTips)) + " ")];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showHotelInfo(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.hotel) + " ")])];
      }
    }, {
      key: "bus",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showBusInfo(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.data.bus) + " ")])];
      }
    }, {
      key: "restaurant",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.restaurant) + " ")];
      }
    }, {
      key: "guide",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.guide) + " ")];
      }
    }, {
      key: "shipTicket",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showShipInfo(scope.row.shipTips);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getShipInfo(scope.row.shipTips)) + " ")])];
      }
    }])
  }), _c("i", {
    staticClass: "el-icon-circle-plus-outline",
    attrs: {
      slot: "reference"
    },
    on: {
      click: function ($event) {
        _vm.visible = !_vm.visible;
      }
    },
    slot: "reference"
  }), _c("DialogShipInfo", {
    ref: "shipRef"
  }), _c("DialogHotelInfo", {
    ref: "hotelRef"
  }), _c("DialogBusInfo", {
    ref: "busRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };