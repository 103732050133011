import { Fleet } from '@/components/common/Order';
import { planEditFleet } from '@/config/plan';
import { planIsOrderInfoMixin } from '@/mixin';
export default {
  components: {
    Fleet
  },
  mixins: [planIsOrderInfoMixin],
  data() {
    return {
      columnData: planEditFleet
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};