import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// import { mapGetters } from 'vuex'
import { BtnExport } from '@/components/common';
import TableList from './TableList';
import itemChart from './itemChart';
import { customerKpiAnalyze, TableYearColumn, TableProfitColumn, TableTurnoverColumn, TableCountColumn, TableAvgProfitColumn } from './tableConfig';
import { parseTime, decimalSplit } from '@/utils';
import { exportTable } from '@/utils/exportTableUtil';
import analysisMixin from '../analysisMixin';
const year = parseTime(new Date(), '{y}');
const kpiType = {
  turnover: 'turnover',
  numberOfPeople: 'numberOfPeople',
  profit: 'profit',
  avgProfit: 'avgProfit'
};
export default {
  components: {
    TableList,
    itemChart,
    BtnExport
  },
  mixins: [analysisMixin],
  data() {
    return {
      searchParams: {
        year
      },
      columnData1: [TableTurnoverColumn, ...customerKpiAnalyze],
      // 营业额
      columnData2: [TableCountColumn, ...customerKpiAnalyze],
      // 人数
      columnData3: [TableProfitColumn, TableYearColumn, ...customerKpiAnalyze],
      // 毛利润
      columnData4: [TableAvgProfitColumn, TableYearColumn, ...customerKpiAnalyze],
      // 人均毛利
      isVisible: true,
      turnoverList: [],
      turnoverCompList: [],
      countrList: [],
      countrCompList: [],
      profitList: [],
      profitCompList: [],
      tableData: [],
      avgProfitList: [],
      avgProfitCompList: []
    };
  },
  computed: {
    customerKpiData() {
      return this.$store.getters['analyse/index/customerKpiData'];
    }
  },
  watch: {
    customerKpiData: {
      handler: function (data) {
        if (!data) {
          this.handleSearch();
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.turnoverList = [];
      this.countrList = [];
      this.profitList = [];
      this.avgProfitList = [];
      this.isVisible = false;
      this.tableData = data;
      this.formatData(data);
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchCustomerKpi', queryParams);
    },
    processDecimal(item) {
      return Object.keys(item).reduce((acc, cur) => {
        if (!isNaN(item[cur])) {
          acc[cur] = Math.round(item[cur]);
        } else {
          acc[cur] = item['kpiName'];
        }
        return acc;
      }, {});
    },
    formatData(list) {
      list.forEach(it => {
        if (it.name == 'kpi') {
          it.kpiName = this.searchParams.year + '年目标';
        } else {
          it.kpiName = '实际完成';
        }
        if (it.kpi == kpiType.turnover) {
          // this.formatColumnsData('columnData1', '营业额')
          this.turnoverList.push(it);
        }
        if (it.kpi == kpiType.numberOfPeople) {
          this.countrList.push(it);
          // this.formatColumnsData('columnData2', '接待人数')
        }
        if (it.kpi == kpiType.profit) {
          this.profitList.push(this.processDecimal(it));
          // this.formatColumnsData('columnData3', '毛利润')
        }
        if (it.kpi == kpiType.avgProfit) {
          this.avgProfitList.push(this.processDecimal(it));
          // this.formatColumnsData('columnData4', '人均毛利')
        }
      });
      // 营业额
      const turnoverData = this.formatSumData(this.turnoverList, 'turnover');
      this.turnoverList = [...this.turnoverList, ...turnoverData];
      // 接待人数
      const peopleData = this.formatSumData(this.countrList, 'countr');
      this.countrList = [...this.countrList, ...peopleData];
      // 毛利润
      const profitData = this.formatSumData(this.profitList, 'profit');
      this.profitList = [...this.profitList, ...profitData];

      // 人均毛利
      const avgProfitData = this.formatSumData(this.avgProfitList, 'avgProfit');
      this.avgProfitList = [...this.avgProfitList, ...avgProfitData];
      this.$nextTick(() => {
        this.isVisible = true;
      });
    },
    // 计算差距、完成率和毛利
    formatSumData(list, type = '') {
      let diff = {
        kpiName: '差距'
      };
      let comp = {
        kpiName: '完成率'
      };
      let profit = {
        kpiName: '毛利率'
      };
      this[type + 'CompList'] = [];
      const keysObj = [TableYearColumn, ...customerKpiAnalyze];
      keysObj.map(it => it.attrs.prop).forEach(key => {
        // 计算差距
        const key1 = +list[1][key];
        const key0 = +list[0][key];
        const curType = this[type + 'CompList'];
        diff[key] = decimalSplit(key1 - key0);

        // 计算完成率
        if (key1 && key0) {
          const item = decimalSplit(key1 / key0);
          comp[key] = decimalSplit(item * 100) + '%';
          // 数据图的完成率
          if (key != 'total') {
            curType.push(item);
          }
        } else if (key1 && !key0) {
          comp[key] = '100%';
          curType.push(1);
        } else if (!key1 && key0) {
          comp[key] = '0%';
          curType.push(0);
        } else {
          comp[key] = '100%';
          curType.push(1);
        }
        if (type == 'profit') {
          const profitItem = this.profitList[1];
          const turnoverItem = this.turnoverList[1];
          const profitVal = +profitItem[key];
          const turnoverVal = +turnoverItem[key];
          if (profitVal && turnoverVal) {
            profit[key] = decimalSplit(profitVal / turnoverVal * 100) + '%';
          } else if (!profitVal && turnoverVal) {
            profit[key] = '100%';
          } else if (profitVal && !turnoverVal) {
            profit[key] = '0%';
          } else {
            profit[key] = '100%';
          }
        }
      });
      if (type == 'profit') {
        return [diff, comp, profit];
      }
      return [diff, comp];
    },
    formatColumnsData(key, label) {
      const item = {
        attrs: {
          prop: 'kpiName',
          label
        }
      };
      this[key] = [item, ...customerKpiAnalyze];
    },
    onChangeDate(val) {
      const [start, end] = val;
      this.searchParams.start = start;
      this.searchParams.end = end;
    },
    changeCustom(val) {
      this.searchParams.customLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    exportExcel() {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有数据导出！'
        });
        return false;
      }
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt1 = document.getElementById('turnover-table');
      exportTable(elt1, '营业额总表', '营业额总表导出', 1);
      let elt2 = document.getElementById('countr-table');
      exportTable(elt2, '人数总表', '人数总表导出', 1);
      let elt3 = document.getElementById('profit-table');
      exportTable(elt3, '毛利总表', '毛利总表导出', 1);
      let elt4 = document.getElementById('avgprofit-table');
      exportTable(elt4, '人均毛利率总表', '人均毛利率总表导出', 1);
      loading.close();
    }
  }
};