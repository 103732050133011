export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '新增'
    },
    btnType: {
      type: String,
      default: 'create'
    },
    hasSave: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    isAddShow: {
      type: Boolean,
      default: true
    },
    isLogShow: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handlePlus() {
      this.$emit('on-add');
    },
    handleSave() {
      this.$emit('on-save');
    },
    handleLog() {
      this.$emit('on-log');
    }
  }
};