var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dictionary"
  }, [_c("div", {
    staticClass: "tabs-wrap"
  }, [_vm.tabList && _vm.tabList.length ? _c("el-tabs", {
    attrs: {
      "tab-position": "left"
    },
    on: {
      "tab-click": _vm.tabChange
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("el-tab-pane", {
      key: item.id + "" + index,
      attrs: {
        label: item.name,
        name: item.name + "" + index
      }
    });
  }), 1) : _vm._e()], 1), _c("TableDictionary", {
    staticClass: "dictionary-content",
    attrs: {
      id: _vm.tabId
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };