var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    staticClass: "out-table tourist-table"
  }, [_c("colgroup", _vm._l(24, function (item) {
    return _c("col", {
      key: item,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", [_c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("团人数：")]), _c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.getPeopleNumber) + " ")]), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, _vm._l(_vm.ageInterval, function (item, index) {
    return _c("span", {
      key: index,
      class: item.color,
      staticStyle: {
        "margin-right": "5px"
      }
    }, [_vm._v(" " + _vm._s(item.min) + "-" + _vm._s(item.max) + "岁: " + _vm._s(item.num) + "人 ")]);
  }), 0)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };