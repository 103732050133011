/*
 * @Author: cyg
 * @Date: 2021-01-22 10:50:58
 * @LastEditors: cyg
 * @LastEditTime: 2022-11-02 15:50:19
 * @FilePath: \travel-admin\src\mixin\getType.js
 */
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('system/dictionary', ['dictionaryCodeData']),
    getType() {
      return (code, val) => {
        const item = this.dictionaryCodeData[code];
        if (!item) return '';
        const findItem = item.agencyDictionaryList.find(it => it.id == val);
        return findItem ? findItem.name : val;
      };
    }
  }
};