var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "粘贴游客信息",
      width: "960px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("el-alert", {
    attrs: {
      title: "温馨提示：",
      description: _vm.tipMsg,
      type: "warning",
      "show-icon": ""
    }
  }), _c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "radio-title"
  }, [_vm._v("请选择添加格式：")]), _c("div", {
    staticClass: "input-field"
  }, [_c("el-radio-group", {
    model: {
      value: _vm.typeFormat,
      callback: function ($$v) {
        _vm.typeFormat = $$v;
      },
      expression: "typeFormat"
    }
  }, _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.val,
      staticClass: "item-radio"
    }, [_c("el-radio", {
      attrs: {
        label: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])], 1);
  }), 0)], 1)]), _c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: _vm.placeholder,
      autosize: {
        minRows: 6,
        maxRows: 10
      },
      "show-word-limit": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };