export default {
  props: {
    columnData: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    rowTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      column: []
    };
  }
};