var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on:search": _vm.fetchData,
      "on:add": _vm.showCreateDialog
    }
  }), _c("TableCustom", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.Columns
    },
    on: {
      reload: _vm.initData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("DialogCustom", {
    ref: "dialogCustom"
  }), _c("DialogContact", {
    ref: "contactRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogDetail", {
    ref: "detailRef"
  }), _c("DialogAgreement", {
    ref: "agreementRef"
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };