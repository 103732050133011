import PreviewImage from './PreviewImage';
import { mapGetters } from 'vuex';
export default {
  components: {
    PreviewImage
  },
  data() {
    return {
      loading: false,
      detail: null,
      roomList: [],
      hotelImages: null,
      listQuery: {
        pageNum: 1,
        pageSize: 100
      }
    };
  },
  computed: {
    ...mapGetters({
      hotelDetail: 'resource/hotel/hotelDetail'
    }),
    getAddress() {
      if (this.detail) {
        const {
          province,
          city,
          district,
          hotelAddr
        } = this.detail;
        return province + city + district + hotelAddr;
      }
      return '';
    },
    getRestImage() {
      return this.hotelImages && this.hotelImages.list.slice(1);
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.initData(id);
  },
  methods: {
    initData(id) {
      this.fetchData(id);
      this.fetchImages(id);
      this.fetchRoomList(id);
    },
    fetchData(id) {
      this.loading = true;
      this.$store.dispatch('resource/hotel/fetchDetail', id).then(data => {
        this.detail = data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    fetchImages(id) {
      const resourceId = id;
      this.$store.dispatch('resource/hotel/fetchImages', {
        ...this.listQuery,
        resourceId
      }).then(data => {
        this.hotelImages = data;
      });
    },
    fetchRoomList(id) {
      this.$store.dispatch('resource/hotel/fetchRoom', {
        hotelId: id
      }).then(data => {
        this.roomList = data.list;
      });
    },
    showPreview(index = 1) {
      this.$refs.previewRef.show({
        data: this.hotelImages,
        index
      });
    }
  }
};