import guideMixin from './guide';
const columnData = [{
  label: '车辆公司',
  prop: 'name'
}, {
  label: '车辆类型',
  prop: 'busType'
}, {
  label: '司机信息',
  prop: 'info'
}, {
  label: '开始日期',
  prop: 'startDate'
}, {
  label: '结束日期',
  prop: 'endDate'
}, {
  label: '车费',
  prop: 'busFee',
  width: 50
}, {
  label: '其他费用',
  prop: 'otherFee',
  width: 50
}, {
  label: '报账金额',
  prop: 'reimbMoney',
  split: true
}, {
  label: '备注',
  prop: 'remark',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};