export const columnData1 = [{
  rows: [{
    label: '大团号',
    width: 60
  }, {
    content: 'planNumber'
  }, {
    label: '人数',
    width: 60
  }, {
    content: 'number',
    slot: 'number'
  }, {
    label: '导游',
    width: 60
  }, {
    content: 'guideName',
    slot: 'guide'
  }]
}];
export const columnData2 = [{
  rows: [{
    label: '总收入',
    width: 60
  }, {
    content: 'totalIncome'
  }, {
    label: '总成本',
    width: 60
  }, {
    content: 'totalQuotePay',
    slot: ''
  }, {
    label: '毛利',
    width: 60
  }, {
    content: 'totalQuoteGrossProfit',
    slot: ''
  }, {
    label: '人均毛利',
    width: 60
  }, {
    content: 'totalQuoteGrossMargin',
    slot: ''
  }]
}];

// 应收团款  购物店
export const shopPlans = [{
  key: 'title',
  label: '购物收入',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'shopName',
  label: '商店',
  span: 4,
  rowspan: 1
}, {
  key: 'enterStoreMemberCount',
  label: '进店人数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'headPrice',
  label: '人头单价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '商品',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '打单金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'redEnvelope',
  label: '红包',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'shopRate',
  label: '社佣比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'shopRebate',
  label: '社佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'agencyProfit',
  label: '社利',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalShopIncome',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  加点收入
export const scenicAdd = [{
  key: 'title',
  label: '加点收入',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '景区',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'ticketType',
  label: '门票类别',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'adultCountReceive',
  label: '成人数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'sellPrice',
  label: '成人价格',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childCountReceive',
  label: '儿童数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childPrice',
  label: '儿童价格',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他收入',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'realMoney',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalScenicExtraIncome',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  其他收入
export const otherIncome = [{
  key: 'titleLabel',
  label: '其他收入',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'title',
  label: '项目',
  span: 8,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalIncome',
  label: '金额',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalOtherIncome',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  客户团款
export const feeList = [{
  key: 'title',
  label: '客户团款',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'customerName',
  label: '客户',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'people',
  label: '人数',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalFees',
  label: '总金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'type',
  label: '类型',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'remarks',
  label: '项目',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'times',
  label: '单位',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'total',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  客户代收
export const collectList = [{
  key: 'title',
  label: '客户代收',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'customerName',
  label: '客户',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'people',
  label: '人数',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalCollect',
  label: '总金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'type',
  label: '类型',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'remarks',
  label: '项目',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'total',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideName',
  label: '代收导游',
  span: 4,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  客户返款
export const subsidieList = [{
  key: 'title',
  label: '客户返款',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'customerName',
  label: '客户',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'people',
  label: '人数',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalSubsidy',
  label: '总金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'type',
  label: '类型',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'remarks',
  label: '项目',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'total',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  车队
export const busFee = [{
  key: 'title',
  label: '车费',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'busType',
  label: '车型',
  span: 8,
  rowspan: 1,
  cRow: ''
}, {
  key: 'busFee',
  label: '车费',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'total',
  label: '金额',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalBusCompanyPay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  房费
export const hotels = [{
  key: 'title',
  label: '房费',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '供应商',
  span: 6,
  rowspan: 1,
  cRow: ''
}, {
  key: 'roomType',
  label: '房间类型',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'days',
  label: '天数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'freeCount',
  label: '免去',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'quotePrice',
  label: '价格',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalHotelQuotePay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  景区
export const scenicsCost = [{
  key: 'title',
  label: '景区成本',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '供应商',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'ticketType',
  label: '门票类型',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'adultCount',
  label: '成人数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '成人价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childCount',
  label: '儿童数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childPrice',
  label: '儿童价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyCount',
  label: '全陪数',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyPrice',
  label: '全陪价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalScenicInnerQuotePay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  餐
export const restaurants = [{
  key: 'title',
  label: '餐',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '供应商',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'mealType',
  label: '餐饮类型',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'restaurantType',
  label: '餐标',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'freeCount',
  label: '免去',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalRestaurantPay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  保险
export const insurances = [{
  key: 'title',
  label: '保险',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '供应商',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'insuranceType',
  label: '险种',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalInsurancePay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  大交通
export const transportCompanys = [{
  key: 'title',
  label: '大交通',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'transportCompanyName',
  label: '项目',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'transportType',
  label: '类型',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalTransportCompanyPay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  其他支出
export const otherPays = [{
  key: 'titleLabel',
  label: '其他支出',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'title',
  label: '项目',
  span: 6,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '单价',
  span: 6,
  rowspan: 1,
  cRow: ''
}, {
  key: 'count',
  label: '数量',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'total',
  label: '金额',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalOtherPay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 成本明细  购物支出
export const shopsPays = [{
  key: 'title',
  label: '购物支出',
  span: 2,
  rowspan: 1
}, {
  key: 'name',
  label: '商品',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'enterStoreMemberCount',
  label: '进店人数',
  span: 2,
  rowspan: 1
}, {
  key: 'money',
  label: '打单金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideRate',
  label: '导游比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideRebate',
  label: '导游佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'driverRate',
  label: '司机比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'driverRebate',
  label: '司机佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyRate',
  label: '全陪比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyRebate',
  label: '全陪佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'moneyPay',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalShopRebatePay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];
// 成本明细  加点支出
export const scenicAddPay = [{
  key: 'title',
  label: '加点支出',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'name',
  label: '景区',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'adultCount',
  label: '大人数',
  span: 1,
  rowspan: 1,
  cRow: ''
}, {
  key: 'price',
  label: '价格',
  span: 1,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childCount',
  label: '儿童数',
  span: 1,
  rowspan: 1,
  cRow: ''
}, {
  key: 'childPrice',
  label: '儿童价',
  span: 1,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideRate',
  label: '导游比例',
  span: 1,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideCommission',
  label: '导游佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'driverRate',
  label: '司机比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'driverCommission',
  label: '司机佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyRate',
  label: '全陪比例',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'accompanyCommission',
  label: '全陪佣金',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'otherFee',
  label: '其他费用',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'money',
  label: '金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalScenicExtraPay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];

// 应收团款  导服费
export const guidedFee = [{
  key: 'title',
  label: '导服费',
  span: 2,
  rowspan: 1,
  cRow: ''
}, {
  key: 'startPlanDate',
  label: '上团日期',
  span: 6,
  rowspan: 1,
  cRow: ''
}, {
  key: 'endPlanDate',
  label: '下团日期',
  span: 6,
  rowspan: 1,
  cRow: ''
}, {
  key: 'adultCount',
  label: '带团人数',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'guideServiceFee',
  label: '导服费',
  span: 4,
  rowspan: 1,
  cRow: ''
}, {
  key: 'totalGuideServiceFeePay',
  label: '合计金额',
  span: 2,
  rowspan: 1,
  cRow: ''
}];