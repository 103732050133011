import Reception from './Reception';
import ReceptionEdit from './ReceptionEdit';
export default {
  name: 'PlanTeamFund',
  components: {
    Reception,
    ReceptionEdit
  },
  methods: {
    showEditDialog({
      rowNum,
      index
    }) {
      this.$refs.receptionEditRef.show({
        rowNum,
        index
      });
    },
    saveAndAudit() {
      return this.$refs.itemRef.handleUpdate();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};