import { downloadExport } from '@/utils/exportExcel';
import { customCollect } from '@/config/report';
import { SelectCustom, BtnExport } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { parseDate } from '@/utils';
const today = parseDate(new Date());
export default {
  components: {
    SelectCustom,
    BtnExport
  },
  data: () => ({
    columnData: customCollect,
    tableLoading: false,
    tableData: [],
    starDate: [today, ''],
    //today
    reportPlanType: SELECTOR.reportPlanType,
    searchParams: {
      startTripDateS: '',
      startTripDateE: '',
      customerId: '',
      customerName: '',
      orderType: null
    }
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    // 选择发团日期
    onChangStartTripDate(val) {
      this.searchParams.startTripDateS = val[0];
      this.searchParams.startTripDateE = val[1];
    },
    // 选择客户
    selectCustom(val) {
      this.searchParams.customerName = val.label;
    },
    // 选择计划类型
    selectReportPlan(val) {
      this.searchParams.orderType = val.value;
    },
    handleSearch() {
      this.fetchData();
    },
    fetchData() {
      const searchParams = this.searchParams;
      this.tableLoading = true;
      this.$store.dispatch('report/index/getCustomerIncome', searchParams).then(res => {
        this.tableData = res;
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      const searchParams = this.searchParams;
      this.$store.dispatch('report/index/fetchCustomerIncomeExcel', searchParams).then(res => {
        const fileName = '客户应收报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};