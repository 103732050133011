/* eslint-disable no-empty-pattern */
import { noticeListRequest, noticeStatusSetRequest } from '@/api2/notice';
const defaultState = () => ({
  data: null
});
const state = defaultState();
const getters = {
  data: state => state.data
};
const actions = {
  // 分页列表
  list({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      noticeListRequest(params).then(res => {
        if (res && res.data) {
          const {
            data
          } = res;
          commit('SET_DATA', {
            type: 'data',
            data
          });
          resolve(data);
          return;
        } else reject(res);
      }).catch(reject);
    });
  },
  statusSet({}, data) {
    return noticeStatusSetRequest(data);
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};