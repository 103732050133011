import { searchMixin } from '@/mixin';
export default {
  components: {},
  mixins: [searchMixin],
  data() {
    return {
      searchParams: {
        type: 'priase'
      }
    };
  },
  methods: {
    onSearch() {
      const search = {
        ...this.searchParams
      };
      this.$emit('search', search);
      // mixin method
      this.handleSearch();
    }
  }
};