import guideMixin from './guide';
const columnData = [{
  label: '会计科目',
  prop: 'name'
}, {
  label: '借款金额',
  prop: 'money'
}, {
  label: '借款方式',
  prop: 'type'
}, {
  label: '借款时间',
  prop: 'borrowDate'
}, {
  label: '指定批款人',
  prop: 'auditUser'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};