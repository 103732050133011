import { SelectDictionary, BtnExport, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import chartPie from './chartPie';
import { memberList } from '@/config/analysis';
import analysisMixin from '../analysisMixin';
export default {
  components: {
    SelectDictionary,
    TableList,
    BtnExport,
    InputMonthRange,
    chartPie
  },
  mixins: [analysisMixin],
  data() {
    return {
      isVisible: true,
      tableData: [],
      columnData: memberList,
      ageList: [],
      perList: [],
      shopMoneyList: []
    };
  },
  computed: {
    memberAgeData() {
      return this.$store.getters['analyse/index/memberAgeData'];
    }
  },
  watch: {
    memberAgeData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.isVisible = false;
      this.tableData = data;
      this.ageList = data.map(it => {
        const item = {
          name: it.kpi,
          value: it.count
        };
        return item;
      });
      this.perList = data.map(it => {
        const item = {
          name: it.kpi,
          value: it.per
        };
        return item;
      });
      this.shopMoneyList = data.map(it => {
        const item = {
          name: it.kpi,
          value: it.shopMoney
        };
        return item;
      });
      this.$nextTick(() => {
        this.isVisible = true;
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchMemberAge', queryParams);
    },
    changeArea(val) {
      this.searchParams.area = val.label;
    }
  }
};