var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "新增订单",
      width: "760px",
      top: "20px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide,
      confirm: _vm.handleSave
    }
  }, [_c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "大团号",
      prop: "planCustomerNumber"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planCustomerNumber", $$v);
      },
      expression: "form.planCustomerNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "团队类型"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "teamType",
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.form = {
          ..._vm.form,
          groupType: $event.label
        };
      }
    },
    model: {
      value: _vm.form.groupType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupType", $$v);
      },
      expression: "form.groupType"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客户",
      prop: "customerId"
    }
  }, [_c("SelectCustom", {
    model: {
      value: _vm.form.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerId", $$v);
      },
      expression: "form.customerId"
    }
  })], 1), _c("div", {
    staticClass: "flex"
  }, [_c("el-form-item", {
    attrs: {
      label: "大人数",
      prop: "adultCount"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.adultCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adultCount", $$v);
      },
      expression: "form.adultCount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "小孩数",
      prop: "childCount"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.childCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "childCount", $$v);
      },
      expression: "form.childCount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "全陪数",
      prop: "accompanyCount"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.accompanyCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accompanyCount", $$v);
      },
      expression: "form.accompanyCount"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };