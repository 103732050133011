var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "station-dialog",
    attrs: {
      title: "选择时间",
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.selectStation
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false
    },
    on: {
      "row-click": _vm.handleRow
    },
    scopedSlots: _vm._u([_vm.type == 1 ? {
      key: "radio",
      fn: function ({
        scope
      }) {
        return [_c("el-radio", {
          staticStyle: {
            width: "40px"
          },
          attrs: {
            label: scope.row.no
          },
          on: {
            change: function ($event) {
              return _vm.changeRadio(scope.$index, $event);
            }
          },
          model: {
            value: _vm.train.radio,
            callback: function ($$v) {
              _vm.$set(_vm.train, "radio", $$v);
            },
            expression: "train.radio"
          }
        }, [_vm._v(" " + _vm._s(scope.row.station) + " ")])];
      }
    } : {
      key: "radio",
      fn: function ({
        scope
      }) {
        return [_c("el-radio", {
          staticStyle: {
            width: "40px"
          },
          attrs: {
            label: scope.row.idx
          },
          on: {
            change: function ($event) {
              return _vm.changePlaneRadio(scope.$index, $event);
            }
          },
          model: {
            value: _vm.planeRadio,
            callback: function ($$v) {
              _vm.planeRadio = $$v;
            },
            expression: "planeRadio"
          }
        }, [_vm._v(" " + _vm._s(scope.row.radio) + " ")])];
      }
    }], null, true)
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };