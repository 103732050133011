import request from '@/utils/request';

// 资源列表
export function resourceListRequest(params) {
  return request({
    url: '/resource/kp-company/page',
    method: 'get',
    params
  });
}

// 资源列表(不分页)
export function resourceListAllRequest(params) {
  return request({
    url: '/resource/kp-company/findList',
    method: 'get',
    params
  });
}

// 资源删除
export function resourceDeleteRequest(data) {
  return request({
    url: '/resource/kp-company/del',
    method: 'post',
    data
  });
}

// 资源详情
export function resourceDetailRequest(params) {
  return request({
    url: '/resource/kp-company/company',
    method: 'get',
    params
  });
}

// 资源删除
export function resourceSetRequest(data) {
  return request({
    url: '/resource/kp-company/save',
    method: 'post',
    data
  });
}

// 对账列表
export function billCheckRequest(params) {
  return request({
    url: '/order/ca/kpCompanyCA',
    method: 'get',
    params
  });
}