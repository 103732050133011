var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("PlanSearch", {
    ref: "searchRef",
    on: {
      "on-search": _vm.onSearch
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      "show-page": true,
      total: _vm.total,
      border: ""
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " "), _c("br"), _vm._v(_vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [scope.row.planType == "T_TOURIST" && scope.row.customerVoList.length ? _c("CustomerInfo", {
          attrs: {
            name: scope.row.customerVoList[0].name
          }
        }) : _vm._e()];
      }
    }, {
      key: "dutyOPUserName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" 操作：" + _vm._s(scope.row.dutyOPUserName)), _c("br"), scope.row.planType == "T_TOURIST" ? [_vm._v(" 客服：" + _vm._s(scope.row.outOPUsers && scope.row.outOPUsers.length ? scope.row.outOPUsers[0].realName : "") + " ")] : _vm._e()];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "guides",
      fn: function ({
        scope
      }) {
        return [scope.row.guides.length ? [_c("Guides", {
          attrs: {
            row: scope.row,
            "is-link": true,
            "is-break": true
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "待接单",
            placement: "left"
          }
        }, [scope.row.reimbStatus == "WAIT_ACCEPT" ? _c("div", {
          staticClass: "dot-status"
        }) : _vm._e()])], 1)] : _c("IconWrong")];
      }
    }, {
      key: "tourgroup",
      fn: function ({
        scope
      }) {
        return [!scope.row.otaGuilinSupervisePlanGuid ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.uploadTourGroup(scope.row);
            }
          }
        }, [_vm._v(" 上传 ")]) : [_c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.cancelTourGroup(scope.row);
            }
          }
        }, [_vm._v(" 取消 ")]), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.statusTourGroup(scope.row);
            }
          }
        }, [_vm._v(" 查看状态 ")])]];
      }
    }, {
      key: "uploadAction",
      fn: function ({
        scope
      }) {
        return [scope.row.signId ? [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.uploadTravel(scope.row, true);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.printTravel(scope.row);
            }
          }
        }, [_vm._v(" 打印 ")])] : _vm._e(), !scope.row.signId && _vm.overSendDate(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.uploadTravel(scope.row);
            }
          }
        }, [_vm._v(" 上传 ")]) : _vm._e()];
      }
    }, {
      key: "boat",
      fn: function ({
        scope
      }) {
        return [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.boatUpload(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.isUploaded(scope.row) ? "编辑" : "上传") + " ")]), _vm.isUploaded(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.boatDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]) : _vm._e(), _vm.isUploaded(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.setStatus(scope.row.id);
            }
          }
        }, [_vm._v(" 删除 ")]) : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };