import { TableOrder } from '@/components/common';
import { planEditSelect } from '@/config/plan';
export default {
  components: {
    TableOrder
  },
  data() {
    return {
      columnData: planEditSelect,
      tableData: [],
      visible: false,
      planId: '',
      planOtherPayId: ''
    };
  },
  mounted() {
    this.$bus.on('otherPay:order', this.show);
  },
  methods: {
    show(params) {
      this.visible = true;
      let {
        planId,
        planOtherPayId
      } = params;
      this.fetchData(params);
      this.planId = Number(planId);
      this.planOtherPayId = Number(planOtherPayId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/otherPay/fetchOtherPayOrderList', params).then(data => {
        this.tableData = data;
      });
    },
    handleConfirm() {
      const filterList = this.tableData.map(it => {
        if (it.isSel) {
          const {
            customerId,
            id: orderId
          } = it;
          return {
            customerId,
            orderId,
            planId: this.planId,
            resourceId: this.planOtherPayId
          };
        }
      });
      this.$store.dispatch('plan/otherPay/selectOtherPayOrderList', filterList).then(data => {
        this.hide();
        this.$bus.tip();
        this.$bus.emit('otherPay:reload', data);
      }).catch(() => {});
    }
  }
};