import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { hotelTotel } from '@/config/audit';
import { mergeTableRow } from '@/utils/table';
import { getPayTypeLabel } from '@/config/select';
export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: hotelTotel,
      headMergeNum: 2,
      hasMergeTail: true
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      this.tableData = this.processData(data);
    },
    hide() {
      this.visible = false;
    },
    processData(list) {
      // 处理相同酒店
      const copyList = list.reduce((pre, cur) => {
        const {
          hotelName
        } = cur;
        if (!pre[hotelName]) {
          pre[hotelName] = [];
        }
        pre[hotelName].push(cur);
        return pre;
      }, {});
      let result = [];

      // 合计处理
      Object.keys(copyList).map(key => {
        let list = copyList[key];
        let extraList = [];

        // 多个房型
        list.forEach(cur => {
          let roomList = cur.roomList || [];
          if (roomList.length) {
            roomList.forEach(room => {
              extraList.push({
                ...cur,
                ...room
              });
            });
          }
        });
        list = extraList.map(it => {
          const {
            cashType,
            totalQuotePay
          } = it;
          const payType = getPayTypeLabel(cashType);
          let obj = {
            payType,
            signTotal: 0,
            guideTotal: 0
          };
          if (it.cashType == 'GUIDE') {
            obj.guideTotal += Number(totalQuotePay);
            obj.signTotal = 0;
          } else {
            obj.signTotal += Number(totalQuotePay);
            obj.guideTotal = 0;
          }
          return {
            ...it,
            ...obj
          };
        });
        const mergeData = mergeTableRow(list, ['hotelName']);
        result.push(mergeData);
      });
      return result;
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    }
  }
};