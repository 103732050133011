import "core-js/modules/es.array.reduce.js";
import { MealTypeLabel } from '@/config/restaurant';
import { restaurantItem, hotelItem, busObjItem, busObjKeyMap } from './config';
import { setRegionData, removeObjKeys } from '@/utils';
const tempKeys = ['shopTempName', 'scenicTempName'];
const tempKeys2 = ['itinerary', 'region'];
const getMealLabel = (key, val) => {
  return val ? MealTypeLabel[key.toUpperCase()] : '';
};
const processScenicOrShop = list => {
  if (!list || !list.length) return '';
  return list.reduce((acc, cur) => {
    const val = cur[cur.type + 'Name'] || cur.name;
    acc = acc + '【' + val + '】';
    return acc;
  }, '');
};

// 转换餐厅类型
const processRestaurantDesc = obj => {
  if (!obj) return '';
  const {
    breakfast,
    lunch,
    dinner
  } = obj;
  const breakfastVal = getMealLabel('breakfast', breakfast);
  const lunchVal = getMealLabel('lunch', lunch);
  const dinnerVal = getMealLabel('dinner', dinner);
  return `${breakfastVal}/${lunchVal}/${dinnerVal}`;
};
const processHotelDesc = obj => {
  if (!obj) return '';
  const {
    area,
    standard
  } = obj;
  return `${area || ''}${standard || ''}`;
};

// 处理景区和购物店拖拽排序数据
export const processDraggableData = (list, type = 'scenic') => {
  if (!list) return [];
  return list.map(it => {
    return {
      ...it,
      type,
      name: it[type + 'Name'],
      id: it[type + 'Id']
    };
  });
};

// 处理景区和购物店拖拽排序数据
export const processMergeDraggableData = (scenics, shops) => {
  const scenicList = processDraggableData(scenics, 'scenic');
  const shopList = processDraggableData(shops, 'shop');
  const mergeList = [...scenicList, ...shopList];
  mergeList.sort((a, b) => a.orderNumber - b.orderNumber);
  return mergeList;
};

// 处理数据
export const formatData = data => {
  const {
    itinerary,
    shipTicket,
    busObj
  } = data;
  const itineraries = itinerary.map(it => {
    const {
      restaurantObj,
      hotelObj,
      scenicList,
      shopList,
      selfScenic
    } = it;
    if (!restaurantObj || !hotelObj) {
      return {
        restaurantObj: {
          ...restaurantItem()
        },
        hotelObj: {
          ...hotelItem()
        },
        scenicList: [],
        ...it,
        selfScenic: it.desc
      };
    }
    // 合并景区与购物店拖拽数据
    const mergeList = processMergeDraggableData(scenicList, shopList);
    const restaurant = processRestaurantDesc(restaurantObj);
    const hotel = processHotelDesc(hotelObj);
    const descInfo = mergeList.length ? processScenicOrShop(mergeList) + selfScenic : it.desc;
    const pickInfo = it.isPick ? '接团' : '';
    const sendInfo = it.isSend ? '送团' : '';
    const desc = pickInfo + descInfo + sendInfo;
    const result = {
      restaurantObj: restaurantItem(),
      hotelObj: hotelItem(),
      ...it,
      scenicList: mergeList,
      restaurant,
      hotel,
      desc
    };
    return result;
  });

  // 船票
  let ship = {};
  if (shipTicket && shipTicket.tickets) {
    const {
      shipDate,
      tickets
    } = shipTicket;
    ship = {
      shipDate,
      ...tickets[0]
    };
  }
  return {
    ...data,
    region: setRegionData(data),
    itineraries,
    ship,
    busObj: busObj || busObjItem()
  };
};
export function beforeCommit(data) {
  const {
    itineraries,
    ship
  } = data;
  const {
    shipDate,
    delShipTicket,
    downCount,
    upCount,
    vipCount,
    shipType,
    remarks,
    course
  } = ship;
  // 船票
  const shipTicket = {
    shipDate,
    delShipTicket,
    tickets: [{
      course,
      downCount,
      remarks,
      shipType,
      upCount,
      vipCount
    }]
  };

  // 景区
  const trips = itineraries.map(cur => {
    const {
      scenicList: scenics,
      selfScenic,
      restaurantObj,
      hotelObj
    } = cur;

    // 景区
    const scenicList = scenics.filter(it => it.type == 'scenic');
    // 购物店
    const shopList = scenics.filter(it => it.type == 'shop');
    // 合并景区与购物店拖拽数据
    const mergeList = processMergeDraggableData(scenicList, shopList);

    // 向下兼容
    const descInfo = processScenicOrShop(mergeList) + selfScenic;
    const restaurant = processRestaurantDesc(restaurantObj);
    const hotel = processHotelDesc(hotelObj);
    const pickInfo = cur.isPick ? '接团' : '';
    const sendInfo = cur.isSend ? '送团' : '';
    const desc = pickInfo + descInfo + sendInfo;
    cur = removeObjKeys(cur, tempKeys);
    return {
      ...cur,
      desc,
      hotel,
      restaurant,
      scenicList,
      shopList
    };
  });
  data = removeObjKeys(data, tempKeys2);
  if (data.busObj) {
    data.bus = JSON.stringify(data.busObj);
  }
  const result = {
    ...data,
    itineraries: trips
  };
  if (shipDate) {
    return {
      ...result,
      shipTicket
    };
  }
  return {
    ...result,
    delShipTicket: true
  };
}
export const getShipInfo = item => {
  if (!item) return '';
  const {
    shipDate,
    tickets
  } = item;
  if (tickets && tickets.length) {
    const labelMap = {
      course: '航向',
      downCount: '下舱',
      upCount: '上舱',
      vipCount: 'vip舱',
      shipType: '类型'
    };
    const keys = ['course', 'shipType', 'downCount', 'upCount', 'vipCount'];
    const str = keys.reduce((acc, key) => {
      const val = tickets[0][key] || '无';
      return acc += ` ${labelMap[key]}: ${val}`;
    }, '');
    return `日期: ${shipDate} ${str}`;
  }
  return '';
};

// 车队信息显示处理
export const formatBusInfo = data => {
  if (!data) return '';
  return Object.keys(data).map(key => {
    const val = data[key];
    const label = busObjKeyMap[key];
    return `${label}: ${val} `;
  }).join('');
};