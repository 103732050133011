var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: [_vm.currentView == "JtxSidebar" ? "jtx-box login-point-box" : _vm.currentView == "wlSidebar" ? "wl-box login-point-box" : ""]
  }, [_c("div", {
    staticClass: "wl-bg-white"
  }, [_c("div", {
    class: ["login-container", "theme-day", {
      mobile: _vm.isMobile
    }]
  }, [_c(_vm.currentView, {
    tag: "component"
  }), _c("section", {
    staticClass: "auth-main"
  }, [_c("el-form", {
    ref: "form",
    staticClass: "login-form",
    attrs: {
      model: _vm.form,
      rules: _vm.getUsernameLogin ? _vm.loginRules : _vm.loginSmsRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("login.title")) + " ")]), _c("div", {
    staticClass: "icon-wrap"
  }, [_c("el-dropdown", {
    staticClass: "set-theme is-show",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleTheme
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_c("i", {
    staticClass: "el-icon-s-platform"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "theme-day"
    }
  }, [_vm._v(" 明亮 ")]), _c("el-dropdown-item", {
    attrs: {
      command: "theme-night"
    }
  }, [_vm._v(" 暗黑 ")])], 1)], 1), _c("lang-select", {
    staticClass: "set-language"
  })], 1)]), _vm.getUsernameLogin ? _c("div", {
    staticClass: "normal"
  }, [_c("p", {
    staticClass: "is-show"
  }, [_c("strong", [_vm._v(_vm._s(_vm.$t("login.username")))])]), _c("el-form-item", {
    attrs: {
      prop: "username"
    }
  }, [_c("span", {
    staticClass: "icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-user"
  })]), _c("el-input", {
    ref: "username",
    attrs: {
      placeholder: _vm.$t("login.username"),
      name: "username",
      type: "text",
      tabindex: "1",
      autocomplete: "off"
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _c("div", {
    staticClass: "mt20"
  }), _c("p", {
    staticClass: "is-show"
  }, [_c("strong", [_vm._v(_vm._s(_vm.$t("login.password")))])]), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("span", {
    staticClass: "icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-lock"
  })]), _c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password",
      tabindex: "2",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("i", {
    staticClass: "iconfont",
    class: _vm.passwordType === "password" ? "icon-eye-close" : "icon-eye-open"
  })])], 1)], 1) : _vm._e(), _vm.getSmsLogin ? _c("div", {
    staticClass: "sms"
  }, [_c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("login.phone")))])]), _c("el-form-item", {
    attrs: {
      prop: "phone"
    }
  }, [_c("span", {
    staticClass: "icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-phone"
  })]), _c("el-input", {
    ref: "phone",
    attrs: {
      placeholder: _vm.$t("login.phone"),
      name: "phone",
      type: "text",
      tabindex: "1",
      autocomplete: "off"
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("login.code")))])]), _c("el-form-item", {
    staticClass: "code-wrapper",
    attrs: {
      prop: "code"
    }
  }, [_c("span", {
    staticClass: "icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-lock1"
  })]), _c("el-input", {
    ref: "code",
    attrs: {
      placeholder: _vm.$t("login.code"),
      name: "code",
      tabindex: "2",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c("div", {
    staticClass: "count-down-warpper"
  }, [_c("CountDown", {
    ref: "countDownRef",
    attrs: {
      disabled: _vm.countState
    },
    on: {
      "update:disabled": function ($event) {
        _vm.countState = $event;
      },
      "on-done": _vm.handleSend
    }
  })], 1)], 1), _vm.visitCodeImg ? [_c("p", [_vm._v(_vm._s(_vm.$t("login.visitCode")))]), _c("el-form-item", {
    staticClass: "visitCode-wrapper",
    attrs: {
      prop: "visitCode"
    }
  }, [_c("span", {
    staticClass: "icon-box"
  }, [_c("i", {
    staticClass: "iconfont icon-dashujukeshihuaico-"
  })]), _c("el-input", {
    ref: "visitCode",
    attrs: {
      placeholder: _vm.$t("login.visitCode"),
      name: "visitCode",
      tabindex: "2",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.visitCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "visitCode", $$v);
      },
      expression: "form.visitCode"
    }
  }), _c("div", {
    staticClass: "count-down-warpper",
    on: {
      click: _vm.refreshCode
    }
  }, [_c("img", {
    attrs: {
      src: _vm.getCodeImage,
      alt: "验证码"
    }
  })])], 1)] : _vm._e()], 2) : _vm._e(), _c("div", {
    staticClass: "mt20"
  }), _c("el-button", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      size: "medium",
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.logIn")) + " ")]), _c("div", {
    staticClass: "three-login-box"
  }, [!_vm.isMobile ? _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "icon dingding",
    on: {
      click: _vm.showDDLogin
    }
  }, [_c("i", {
    staticClass: "iconfont icon-pinpaibiaoshi-dingding"
  })])]) : _vm._e(), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "icon message",
    on: {
      click: _vm.toggleLoginType
    }
  }, [_c("i", {
    staticClass: "iconfont icon-message"
  })])]), !_vm.isMobile ? _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "icon wechat",
    on: {
      click: _vm.showWxLogin
    }
  }, [_c("i", {
    staticClass: "iconfont icon-wechat"
  })])]) : _vm._e(), !_vm.isMobile ? _c("div", {
    staticClass: "item item-text"
  }, [_c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toggleLoginType
    }
  }, [_vm._v(" " + _vm._s(_vm.curLoginType == _vm.loginType.sms ? _vm.$t("login.userLogin") : _vm.$t("login.smsLogin")) + " ")])], 1) : _vm._e()])], 1)], 1), _c("el-dialog", {
    attrs: {
      title: _vm.$t("login.thirdparty"),
      visible: _vm.showDialog,
      width: "500px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      id: "threeLogin"
    }
  })]), _c("el-dialog", {
    attrs: {
      title: _vm.$t("login.wxLogin"),
      visible: _vm.showWxDialog,
      "custom-class": "wechat-scan-dialog",
      width: "500px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.showWxDialog = $event;
      }
    }
  }, [_c("WechatLogin", {
    attrs: {
      appid: _vm.wxLoginDiaData.appid,
      scope: _vm.wxLoginDiaData.scope,
      "redirect-uri": encodeURIComponent(`${_vm.wxRedirectUrl}`),
      rel: "external nofollow"
    }
  })], 1), _c("DialogTravel", {
    ref: "dialogRef"
  }), _c("DialogPassword", {
    ref: "dialogPwdRef"
  }), _c("DialogWechatBind", {
    ref: "dialogWechatRef"
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };