var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curOrder ? _c("div", {
    staticClass: "order-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础信息")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "大团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.planCustomerNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户"
    }
  }), _c("TextBase", [_c("CustomerInfo", {
    attrs: {
      name: _vm.curOrder.customerName
    }
  }), _vm._v(" " + _vm._s(_vm.curOrder.orderType == "T_TOURIST" ? `(${_vm.curOrder.productType})` : "") + " ")], 1)], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "产品线路"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.productName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "全陪及电话"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.accompanyInfo))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "小团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.customerTripNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户联系人"
    }
  }), _c("TextBase", [_c("CustomerInfo", {
    attrs: {
      name: _vm.curOrder.customerContactName
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "发团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.startTripDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "参团人数"
    }
  }), _c("TextBase", [_vm._v(" " + _vm._s(_vm.curOrder.adultCount) + " 大 " + _vm._s(_vm.curOrder.childCount) + "小 " + _vm._s(_vm.curOrder.accompanyCount) + " 陪 ")]), _vm.curOrder.isReview && _vm.curOrder.updateDetails.length ? _c("TextBase", {
    staticStyle: {
      color: "#f44",
      "margin-left": "10px"
    }
  }, [_vm._v(" 改成   " + _vm._s(_vm.alterFormat(["adultCount", "childCount", "accompanyCount"], _vm.curOrder)) + " ")]) : _vm._e()], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客服计调"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.outOPUserName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户联系手机"
    }
  }), _c("TextBase", [_c("CustomerInfo", {
    attrs: {
      name: _vm.curOrder.customerContactMobileNumber
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "散团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.endTripDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客源地"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.getCustomSource))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "操作计调"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.dutyOPUserName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "业务员"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.saleUserName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "团上导游"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.guideName))])], 1), _c("div", {
    staticClass: "input-box red"
  }, [_c("LabelBase", {
    staticStyle: {
      color: "#f44"
    },
    attrs: {
      label: "类型"
    }
  }), _c("TextBase", {
    staticStyle: {
      color: "#f44"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.groupType) + _vm._s(_vm.productType ? "--" + _vm.productType : "") + " ")])], 1)])]), _vm._l(_vm.tableData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "input-field input-red"
    }, [_c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车队公司"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.busCompanyName))])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车辆类型"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.busTypeName) + "(金额：" + _vm._s(item.busFee) + " )")])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "司机"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.driverName) + " " + _vm._s(item.driverPhone) + " ")])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车牌号"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.licenceNumber))])], 1)]);
  }), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "游客信息（接站牌）"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.welcomeBorad))])], 1)])], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };