import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 计调操作
import Layout from '@/layout';

// 注意： name or title must be plan start
const planRouter = {
  path: '/plan',
  component: Layout,
  redirect: '/plan/team-arrange',
  name: 'plan',
  meta: {
    title: 'plan',
    icon: 'icon-tongzhigonggao'
  },
  children: [{
    path: '/plan/team-arrange',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange'))),
    name: 'planTeamArrange',
    // 发团预定安排
    meta: {
      title: 'planTeamArrange',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/team-arrange-shuttle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange/shuttleDel.vue'))),
    name: 'planTeamArrange',
    // 发团预定安排
    meta: {
      title: 'planTeamArrangeShuttle',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/team-role-arrange',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange'))),
    name: 'planTeamRoleArrange',
    // 发团预定安排2
    meta: {
      title: 'planTeamRoleArrange',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/turn-out',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/turnOut'))),
    name: 'planTurnOut',
    // 外转安排
    meta: {
      title: 'planTurnOut',
      icon: 'icon-zhuanban'
    }
  }, {
    path: '/plan/sms-send',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/smsSend'))),
    name: 'planSmsSend',
    // 导游通知短信
    meta: {
      title: 'planSmsSend',
      icon: 'icon-message'
    }
  }, {
    path: '/plan/boat-personal',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/boatPersonal'))),
    name: 'planBoatPersonal',
    // 散客船票计划
    meta: {
      title: 'planBoatPersonal',
      icon: 'icon-message'
    }
  }, {
    path: '/plan/team-arrange-travel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange/superviseTravel.vue'))),
    name: 'planTeamArrangeTravel',
    // 上传旅监编辑页面
    meta: {
      title: 'planTeamArrangeTravel',
      icon: 'el-icon-s-operation',
      auth: true
    },
    hidden: true
  }, {
    path: '/plan/team-arrange-travel-country',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/onekeyup/tourageCountry'))),
    name: 'planTeamArrangeTravelCountry',
    // 上传全国旅监平台
    meta: {
      title: 'planTeamArrangeTravelCountry',
      icon: 'el-icon-s-operation',
      auth: true
    },
    hidden: true
  }, {
    path: '/plan/guide-arrange',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/guideArrange'))),
    name: 'planGuideArrange',
    // 导游排班表
    meta: {
      title: 'planGuideArrange',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/group-guide-arrange',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/guideArrange'))),
    name: 'planGroupGuideArrange',
    // 集团导游排班表
    meta: {
      title: 'planGroupGuideArrange',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/guide-statistics',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/guideStatistics'))),
    name: 'planGuideStatistics',
    // 导游数据查询菜单
    meta: {
      title: 'planGuideStatistics',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/plan/tourist-arrange',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange/touristArrange'))),
    name: 'planTouristArrange',
    // 黑名单购物录入
    meta: {
      title: 'planTouristArrange',
      icon: 'icon-suoyoukehu',
      auth: true
    },
    hidden: true
  },
  // 发团预定安排1编辑
  {
    path: '/plan/team-arrange-edit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/index'))),
    // redirect: '/plan/team-arrange-edit/guide',
    name: 'planTeamArrangeEdit',
    // 编辑发团预定安排
    meta: {
      title: 'planTeamArrangeEdit',
      icon: 'icon-suoyoukehu'
    },
    hidden: true,
    children: [{
      path: '/plan/team-arrange-edit/guide',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Guide/index'))),
      name: 'planEditGuide',
      //
      meta: {
        title: 'planEditGuide'
      }
    }, {
      path: '/plan/team-arrange-edit/alipayECode',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Guide/GuideQuota'))),
      name: 'planEditQuota',
      //
      meta: {
        title: 'planEditQuota'
      }
    }, {
      path: '/plan/team-arrange-edit/borrow',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Guide/GuideBorrow'))),
      name: 'planEditBorrow',
      //
      meta: {
        title: 'planEditBorrow'
      }
    }, {
      path: '/plan/team-arrange-edit/collect',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Guide/GuideProxy'))),
      name: 'planEditCollect',
      //
      meta: {
        title: 'planEditCollect'
      }
    }, {
      path: '/plan/team-arrange-edit/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Scenic/index'))),
      name: 'planEditScenic',
      //
      meta: {
        title: 'planEditScenic'
      }
    }, {
      path: '/plan/team-arrange-edit/hotel',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Hotel/index'))),
      name: 'planEditHotel',
      //
      meta: {
        title: 'planEditHotel'
      }
    }, {
      path: '/plan/team-arrange-edit/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Restaurant/index'))),
      name: 'planEditRestaurant',
      //
      meta: {
        title: 'planEditRestaurant'
      }
    }, {
      path: '/plan/team-arrange-edit/bus',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Fleet/index'))),
      name: 'planEditFleet',
      //
      meta: {
        title: 'planEditFleet'
      }
    }, {
      path: '/plan/team-arrange-edit/insurance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Insurance/index'))),
      name: 'planEditInsurance',
      //
      meta: {
        title: 'planEditInsurance'
      }
    }, {
      path: '/plan/team-arrange-edit/transport',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Traffic/index'))),
      name: 'planEditTransport',
      //
      meta: {
        title: 'planEditTransport'
      }
    }, {
      path: '/plan/team-arrange-edit/shuttle',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Pickup/index'))),
      name: 'planEditPickup',
      //
      meta: {
        title: 'planEditPickup'
      }
    }, {
      path: '/plan/team-arrange-edit/localTravelAgency',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Reception/index'))),
      name: 'planEditReception',
      //
      meta: {
        title: 'planEditReception'
      }
    }, {
      path: '/plan/team-arrange-edit/shop',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/Shopping/index'))),
      name: 'planEditShop',
      //
      meta: {
        title: 'planEditShop'
      }
    }, {
      path: '/plan/team-arrange-edit/otherIncome',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/OtherIncome/index'))),
      name: 'planEditOtherIncome',
      //
      meta: {
        title: 'planEditOtherIncome'
      }
    }, {
      path: '/plan/team-arrange-edit/otherPay',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/planEdit/OtherPay/index'))),
      name: 'planEditOtherPay',
      //
      meta: {
        title: 'planEditOtherPay'
      }
    }]
  }, {
    path: '/plan/team-arrange-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/teamArrange/detail'))),
    name: 'planTeamArrangeDetail',
    // 发团预定安排详情
    meta: {
      title: 'planTeamArrangeDetail',
      icon: 'icon-suoyoukehu',
      auth: true
    },
    hidden: true
  }, {
    path: '/plan/pickup',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/pickup'))),
    name: 'planPickup',
    // 接送预定安排
    meta: {
      title: 'planPickup',
      icon: 'icon-jiejifuwu'
    }
  }, {
    path: '/plan/pickup-batch',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/pickupBatch'))),
    name: 'planPickupBatch',
    // 批量接送预定安排
    meta: {
      title: 'planPickupBatch',
      icon: 'icon-jiejifuwu'
    }
  },
  // {
  //   path: '/plan/hotel',
  //   component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/hotel'),
  //   name: 'planHotel', // 房调安排
  //   meta: { title: 'planHotel', icon: 'icon-cangkuguanli' },
  //   hidden: true, // 先隐藏
  // },
  // {
  //   path: '/plan/hotel-detail',
  //   component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/hotel/detail'),
  //   name: 'planHotelDetail', // 房调安排详情
  //   meta: {
  //     title: 'planHotelDetail',
  //     icon: 'icon-cangkuguanli',
  //     auth: true,
  //   },
  //   hidden: true,
  // },
  {
    path: '/plan/order-turn-out',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/turnOut'))),
    name: 'planOrderTurnOut',
    // 订单转出操作
    meta: {
      title: 'planOrderTurnOut',
      icon: 'icon-jinbizhuanchu'
    },
    hidden: true // 先隐藏
  },
  // {
  //   path: '/plan/car-sort',
  //   component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/carSort'),
  //   name: 'planCarSort', // 车辆排期查询
  //   meta: { title: 'planCarSort', icon: 'icon-cheliangxinxiguanli' },
  //   hidden: true, // 先隐藏
  // },
  {
    path: '/plan/guest-sms-send',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/smsSend'))),
    name: 'planGuestSmsSend',
    // 游客短信通知
    meta: {
      title: 'planGuestSmsSend',
      icon: 'icon-lvyoutehui'
    },
    hidden: true // 先隐藏
  },
  // {
  //   path: '/plan/guest-sms-reply',
  //   component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/smsReply'),
  //   name: 'planGuestSmsReply', // 游客短信回复
  //   meta: { title: 'planGuestSmsReply', icon: 'icon-hudongfankui' },
  //   hidden: true, // 先隐藏
  // },
  // {
  //   path: '/plan/team-notice-tpl',
  //   component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/teamNoticeTpl'),
  //   name: 'planTeamNoticeTpl', // 出团通知模版
  //   meta: { title: 'planTeamNoticeTpl', icon: 'icon-kechengbaoming' },
  //   hidden: true, // 先隐藏
  // },
  {
    path: '/plan/onekeyup',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/onekeyup/index'))),
    name: 'planOneKeyup',
    // 上传兴坪船票
    meta: {
      title: 'planOneKeyup',
      icon: 'el-icon-s-promotion'
    }
  }, {
    path: '/plan/onekeyup-boat',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/onekeyup/boat/index'))),
    name: 'planOneKeyupBoat',
    // 上传兴坪船票
    meta: {
      title: 'planOneKeyupBoat',
      icon: 'icon-suoyoukehu',
      auth: true
    },
    hidden: true
  }, {
    path: '/plan/onekeyup-boat-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/plan/onekeyup/boat/detail'))),
    name: 'planOneKeyupBoatDetail',
    // 上传兴坪船票
    meta: {
      title: 'planOneKeyupBoatDetail',
      icon: 'icon-suoyoukehu',
      auth: true
    },
    hidden: true
  }
  // {
  //   path: '/plan/enterprise-code',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "plan" */ '@/views/plan/enterpriseCode/index'
  //     ),
  //   name: 'planEnterpriseCode', // 企业码额度
  //   meta: {
  //     title: 'planEnterpriseCode',
  //     icon: 'el-icon-s-finance',
  //   },
  // },
  ]
};
export default planRouter;