var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ticket-input-create form-fix-bar"
  }, [_c("h3", [_vm._v("基础信息")]), _c("h4", {
    staticClass: "tac"
  }, [_vm._v(_vm._s(_vm.planNumber) + " 导游: " + _vm._s(_vm.guideName))]), _c("CollapseBase", {
    attrs: {
      title: "行程",
      "is-strong": "",
      "auto-hide": true
    }
  }, [_c("DetailPlanTrip", {
    attrs: {
      "plan-id": _vm.id
    }
  })], 1), _c("el-divider"), _c("TableCount", {
    attrs: {
      "table-data": _vm.tableCountData
    }
  }), _c("h3", [_vm._v("签单录入")]), _c("TicketAdd", {
    on: {
      "on-add": _vm.handleNew
    }
  }), _c("TableTicketAudit", {
    attrs: {
      "table-data": _vm.getTableData,
      "column-data": _vm.ticketAuditList,
      loading: _vm.tableLoading
    },
    on: {
      "on-check": _vm.handleCheckOne,
      "on-del": _vm.handleRemove
    }
  }), _c("div", {
    staticClass: "tar"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.handleCheckAll
    },
    model: {
      value: _vm.checkAll,
      callback: function ($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }), _c("span", {
    staticClass: "pr5"
  }), _c("BtnBase", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleRemoveAll
    }
  }, [_vm._v(" 批量删除 ")])], 1), _c("FooterBar", {
    attrs: {
      slot: "btn"
    },
    slot: "btn"
  }, [_vm.isShowBtn(_vm.TicketStatusEnum.COMPLETED) ? _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.cancelAudit
    }
  }, [_vm._v(" 取消已核销 ")]) : _vm._e(), _c("BtnBase", {
    attrs: {
      type: "save",
      disabled: _vm.getBtnStatus
    },
    on: {
      click: _vm.batchUpdate
    }
  }, [_vm._v(" 保存更新 ")]), _vm.isShowBtn(_vm.TicketStatusEnum.UNARRANGE) ? _c("BtnBase", {
    attrs: {
      type: "dispatch",
      disabled: _vm.getBtnStatus
    },
    on: {
      click: _vm.handleDispatch
    }
  }, [_vm._v(" 派单 ")]) : _vm._e(), _vm.isShowBtn(_vm.TicketStatusEnum.WAITAUDIT) ? _c("BtnBase", {
    attrs: {
      type: "audit"
    },
    on: {
      click: _vm.handleAudit
    }
  }, [_vm._v(" 设为已核销 ")]) : _vm._e(), _c("BtnBase", {
    attrs: {
      disabled: _vm.getBtnStatus,
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印签单列表 ")]), _c("el-button", {
    attrs: {
      type: _vm.$route.query.behindhandSign ? "success" : "danger"
    },
    on: {
      click: _vm.toggleBehindhandSign
    }
  }, [_vm._v(" " + _vm._s(_vm.getBehindhandSignText) + " ")])], 1), _c("DialogPlan", {
    ref: "dialogPlan"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };