/* eslint-disable no-unused-vars */
import { fetchReceipt, fetchTeamPlan, fetchPersonalPlan, fetchDriverNotice, fetchShopping, fetchPersonal, fetchTeam, fetchTourist, fetchPersonalMemberlist, fetchPersonalCustomer, fetchExportPlanTeam, fetchTeamMemberlist, fetchExportPlanMemberlist, fetchGuideSubCommission, fetchExportGuideSubCommission, fetchShuttleBy, fetchPlanProfitReport, fetchExportPlanProfitReport, fetchExportReceipt } from '@/api/order/print';
import { getFileUrl } from '@/api';
import { formatItinerarys, planFormatItinerarys } from '@/views/order/utils/index';
const formatData = async data => {
  // 车费付款方式
  // const findItem = SELECTOR.payType.find(
  //   it => it.value.toUpperCase() == data.busCompanyPayType
  // )

  // data.busCompanyPayType = findItem ? findItem.label : ''

  const {
    logoUrl
  } = data.travelAgencyVo;
  const result = await getFileUrl([logoUrl]);
  if (result) {
    const path = result.data[0];
    data.travelAgencyVo.logoUrl = path;
    return data;
  }
  return data;
};
const dfaultState = () => ({
  receptData: null
});
const state = dfaultState();
const getters = {
  receptData: state => state.receptData
};
const actions = {
  // 回执单
  fetchReceipt({
    commit
  }, opts) {
    return fetchReceipt(opts).then(res => {
      let {
        data
      } = res;
      // 拼接行程的景区和购物店
      data.itineraries = formatItinerarys(data.itineraries);
      commit('RECEPT_DATA', data);
      return data;
    });
  },
  // 团队计划单
  fetchTeamPlan({
    commit
  }, opts) {
    return fetchTeamPlan(opts).then(async res => {
      let {
        data
      } = res;
      data.respOrderTrips = planFormatItinerarys(data.respOrderTrips);
      return formatData(data);
    });
  },
  // 散客计划单
  fetchPersonalPlan({
    commit
  }, planId) {
    return fetchPersonalPlan(planId).then(res => {
      const {
        data
      } = res;
      data.respOrderPlanReportLists.forEach(it => {
        it.respOrderTrips = planFormatItinerarys(it.respOrderTrips);
      });
      return formatData(data);
    });
  },
  // 司机通知单
  fetchDriverNotice({
    commit
  }, opts) {
    return fetchDriverNotice(opts).then(async res => {
      let {
        data
      } = res;
      const {
        logoUrl
      } = data;
      if (!logoUrl) return data;
      const result = await getFileUrl([logoUrl]);
      if (result) {
        const path = result.data[0];
        data.logoUrl = path;
        return data;
      }
      return data;
    });
  },
  // 购物统计
  fetchShopping({
    commit
  }, planId) {
    return fetchShopping(planId).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 散客
  fetchPersonalNameList({
    commit
  }, planId) {
    return fetchPersonal(planId).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 团队
  fetchTeamNameList({
    commit
  }, planId) {
    return fetchTeam(planId).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 保险/游客
  fetchTourist({
    commit
  }, opst) {
    return fetchTourist(opst).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 导游分佣表
  fetchGuideSubCommission({
    commit
  }, planId) {
    return fetchGuideSubCommission(planId).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 客人接送表
  fetchShuttleBy({
    commit
  }, planId) {
    return fetchShuttleBy(planId).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 客人接送表
  fetchPlanProfitReport({
    commit
  }, opts) {
    return fetchPlanProfitReport(opts).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 导出单团核算表
  fetchExportPlanProfitReport({
    commit
  }, {
    planId
  }) {
    return fetchExportPlanProfitReport({
      planId
    }).then(res => {
      const data = {
        filename: '单团核算表.xlsx',
        res: res
      };
      return data;
    });
  },
  // 导出散客客人名单
  fetchPersonalMemberlist({
    commit
  }, {
    planId
  }) {
    return fetchPersonalMemberlist(planId).then(res => {
      const data = {
        filename: '散客客人名单表.xlsx',
        res: res
      };
      return data;
    }).catch(function (error) {});
  },
  // 导出散客计划名单
  fetchPersonalCustomer({
    commit
  }, {
    planId
  }) {
    return fetchPersonalCustomer(planId).then(res => {
      const data = {
        filename: '散客计划单.xlsx',
        res: res
      };
      return data;
    }).catch(function (error) {});
  },
  // 导出团队客人名单
  fetchTeamMemberlist({
    commit
  }, {
    planId
  }) {
    return fetchTeamMemberlist(planId).then(res => {
      const data = {
        filename: '团队客人名单表.xlsx',
        res: res
      };
      return data;
    }).catch(function (error) {});
  },
  // 导出团队计划单
  fetchExportPlanTeam({
    commit
  }, {
    planId,
    type = 1
  }) {
    return fetchExportPlanTeam({
      planId,
      type
    }).then(res => {
      const data = {
        filename: '团队计划单.xlsx',
        res: res
      };
      return data;
    });
  },
  // // 导出团队计划单 金悦
  // fetchExportPlanTeam2({ commit }, planId) {
  //   return fetchExportPlanTeam({ planId, type: 2 })
  //     .then(res => {
  //       const data = {
  //         filename: '团队计划单.xlsx',
  //         res: res,
  //       }
  //       return data
  //     })
  //     .catch(function(error) {})
  // },

  // 导出 保险名单/ 计划游客名单 表
  fetchExportPlanMemberlist({
    commit
  }, planId) {
    return fetchExportPlanMemberlist(planId).then(res => {
      const data = {
        filename: '保险名单.xlsx',
        res: res
      };
      return data;
    });
  },
  // 导出 导游分佣表
  fetchExportGuideSubCommission({
    commit
  }, {
    planId,
    type = 1,
    filename = '导游分佣表.xlsx'
  }) {
    const opts = {
      planId,
      type
    };
    return fetchExportGuideSubCommission(opts).then(res => {
      const data = {
        filename,
        res
      };
      return data;
    });
  },
  // // 导出 导游分佣表2
  // fetchExportGuideSubCommission2({ commit }, planId) {
  //   const opts = {
  //     planId,
  //     type: 2,
  //   }
  //   return fetchExportGuideSubCommission(opts)
  //     .then(res => {
  //       const data = {
  //         filename: '导游分佣表2.xlsx',
  //         res: res,
  //       }
  //       return data
  //     })
  //     .catch(function(error) {})
  // },

  fetchExportReceipt({
    commit
  }, params) {
    return fetchExportReceipt(params).then(res => {
      const data = {
        filename: '回执确认单.xlsx',
        res: res
      };
      return data;
    });
  }
};
const mutations = {
  RECEPT_DATA(state, data) {
    state.receptData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};