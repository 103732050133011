import { FooterBar } from '@/components/common';
export default {
  components: {
    FooterBar
  },
  data: () => ({
    form: {
      guideCommonInfo: '',
      guideImportantInfo: ''
    },
    data: null
  }),
  mounted() {
    this.fetchGuideNotice();
  },
  methods: {
    fetchGuideNotice() {
      this.$store.dispatch('system/systemConf/fetchGuideNotice').then(res => {
        if (!res || !res.data) return;
        this.form = res.data;
      });
    },
    handleSave() {
      this.$store.dispatch('system/systemConf/setGuideNotice', {
        ...this.form
      });
    }
  }
};