import { Columns as columns } from './tableConfig';
import { reloadMixin, searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
import { SelectCustom, SelectStaff } from '@/components/common';
import { exportTable } from '@/utils/exportTableUtil';
export default {
  components: {
    SelectCustom,
    SelectStaff
  },
  mixins: [reloadMixin, searchMixin],
  data() {
    return {
      columns,
      // pageNum: 1,
      // pageSize: 20,
      searchParams: {
        startTripDateS: parseDate(),
        startTripDateE: parseDate()
      },
      statisticsKeys: ['orders', 'adultCount', 'totalFee', 'totalCollect', 'totalIncomed', 'unTotalFee', 'totalSubsidy', 'totalSubsidyed', 'unTotalSubsidy', 'unTotal', 'subsidy', 'headSubsidy', 'subsidised', 'unSubsidy', 'un']
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/collect/customData'];
    },
    tableData() {
      const list = this.data ? this.formatData(this.data) : [];
      return list;
      // return list.slice(
      //   (this.pageNum - 1) * this.pageSize,
      //   this.pageNum * this.pageSize
      // )
    },
    total() {
      return this.data ? this.data.length : 0;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('report/collect/custom', {
        ...this.searchParams,
        ...params
      });
    },
    reload() {
      this.fetchData();
    },
    // handlePage({ pageNum, pageSize }) {
    //   this.pageNum = pageNum
    //   this.pageSize = pageSize
    // },

    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    formatData(data) {
      return data.map((it, idx) => {
        return {
          ...it,
          seqOrder: idx + 1
        };
      });
    },
    handleExport() {
      const element = document.getElementById('exportTable');
      exportTable(element, '渠道客户汇总统计', '渠道客户汇总统计导出', 2);
    }
  }
};