import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { decimalSplit } from '@/utils';
import { processAdd } from '@/utils/number';
export default {
  data() {
    return {
      rowTotal: 0,
      spanArr: [],
      position: 0,
      headMergeNum: 2,
      tailMergeNum: 100,
      hasMergeTail: false,
      targetMergeNum: -1,
      // 默认统计到字段
      statisticsKeys: ['totalPay', 'rowTotal', 'otherFee', 'money', 'price']
    };
  },
  methods: {
    // 计算合并行的累加金额;
    calcMergeRowMoney({
      row,
      moneyKey,
      rate
    }) {
      const {
        rowId
      } = row;
      const sum = this.tableData.reduce((acc, it) => {
        if (it.rowId == rowId) {
          acc = processAdd(acc, it[moneyKey] * rate / 100);
        }
        return acc;
      }, 0);
      return sum;
    },
    mergeRowMoney(needUpdate = true) {
      if (!this.tableData.length) return 0;
      const totalMap = {};
      let amountReceiveMap = {};
      let totalProfitMap = {};
      let tripProfitMap = {};
      this.tableData.forEach(it => {
        let id = it.rowId + '';

        // 加点处理
        if (this.isExtra !== undefined && this.isExtra !== null) {
          const {
            guideCommission,
            driverCommission,
            accompanyCommission
          } = it;
          if (!amountReceiveMap[id]) {
            amountReceiveMap[id] = [];
          }
          const amountReceive = this.calcAmountReceive(it);
          amountReceiveMap[id].push(amountReceive);

          // 总利润
          if (!totalProfitMap[id]) {
            totalProfitMap[id] = [];
          }
          const totalProfit = amountReceive - it.totalSum;
          totalProfitMap[id].push(totalProfit);
          // 社利
          if (!tripProfitMap[id]) {
            tripProfitMap[id] = [];
          }
          const tripProfit = totalProfit - guideCommission - driverCommission - accompanyCommission;
          tripProfitMap[id].push(tripProfit);
        }
        if (!totalMap[id]) {
          totalMap[id] = it.totalPay || 0;
        } else {
          // 初始化的时候，应为totalPay已经统计了
          const keys = Object.keys(totalMap);
          if (keys.includes(id) && needUpdate) {
            totalMap[id] += Number(it.totalPay);
          }
        }
      });

      // 景区加点 实收总计
      if (this.isExtra !== undefined && this.isExtra !== null) {
        this.tableData.forEach(it => {
          if (amountReceiveMap[it.rowId]) {
            it.totalAmountReceive = amountReceiveMap[it.rowId].reduce((acc, cur) => {
              return acc += cur;
            }, 0);
          }
          if (totalProfitMap[it.rowId]) {
            it.totalProfit = totalProfitMap[it.rowId].reduce((acc, cur) => {
              return acc += cur;
            }, 0);
          }
          // if (tripProfitMap[it.rowId]) {
          //   it.tripProfit = tripProfitMap[it.rowId].reduce((acc, cur) => {
          //     return (acc += cur)
          //   }, 0)
          // }
        });
      }
      this.tableData.forEach(it => {
        if (totalMap[it.rowId]) {
          it.rowTotal = totalMap[it.rowId];
        }
      });
    },
    rowspan() {
      this.resetSpanRow();
      if (!this.tableData.length || this.spanArr.length) return;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1);
          this.position = 0;
        } else {
          if (item.rowId === this.tableData[index - 1].rowId) {
            this.spanArr[this.position] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({
      rowIndex,
      columnIndex
    }) {
      //表格合并行
      if (columnIndex < this.headMergeNum || this.hasMergeTail && columnIndex > this.tailMergeNum || this.targetMergeNum > 0 && columnIndex == this.targetMergeNum) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    resetSpanRow() {
      this.spanArr = [];
      this.position = 0;
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      // const unit = 100

      columns.forEach((column, index) => {
        let lastId;
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => {
          if (this.statisticsKeys.includes(column.property)) {
            const {
              adultCount,
              childCount,
              accompanyCount
              // checkAccountMoney,
              // checkAccountStatus,
              // ckStatus,
            } = item;
            // if (column.property === 'checkAccountTime') {
            //   if (lastId == item.rowId) {
            //     return `0/0/0`
            //   } else if (!checkAccountStatus && ckStatus) {
            //     return `${checkAccountMoney}/0/0`
            //   }
            // }

            if (lastId == item.rowId && (index < this.headMergeNum || index > this.tailMergeNum)) {
              return '0/0/0';
            }
            lastId = item.rowId;
            if (column.property === 'number' || column.property === 'count') {
              let res = adultCount + '/' + childCount + '/' + (accompanyCount || 0);
              return res;
            } else {
              return [item[column.property], 0, 0].join('/');
            }
          }
        });
        if (values.every(value => value != undefined)) {
          const res = values.reduce((prev, cur) => {
            const [adultCount, childCount, accompanyCount] = cur.split('/');
            prev[0] += Number(adultCount);
            prev[1] += Number(childCount);
            prev[2] += Number(accompanyCount);
            return prev;
          }, [0, 0, 0]);
          const resultNum = res[0];
          if (column.property === 'number' || column.property === 'count') {
            sums[index] = `${resultNum}大${res[1]}小${res[2]}陪`;
          } else {
            sums[index] = decimalSplit(resultNum);
          }
        }
      });
      return sums;
    }
  }
};