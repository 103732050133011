var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangDate
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "组团社"
    },
    on: {
      "on-select": _vm.onSelectCustom
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "团型",
      type: "reportPlanType"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("orderType", $event);
      }
    },
    model: {
      value: _vm.searchParams.orderTypeLable,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderTypeLable", $$v);
      },
      expression: "searchParams.orderTypeLable"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "市场",
      code: "regionType"
    },
    on: {
      "on-select": _vm.onSelectArea
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "表格类型",
      type: "gropTotal"
    },
    on: {
      "on-select": _vm.onSelectTableType
    },
    model: {
      value: _vm.searchParams.tableTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "tableTypeLabel", $$v);
      },
      expression: "searchParams.tableTypeLabel"
    }
  }), _c("BtnExport", {
    attrs: {
      "has-excel": false
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _vm.visibleTotal ? [_c("TableTotal", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      border: ""
    }
  })] : _vm._e(), _vm.visibleDetail ? [_c("TableDetail", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      border: ""
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };