export default {
  props: {
    isDialog: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number || String,
      default: -1
    },
    isReview: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    // 如果使用弹窗打开
    if (this.isDialog) {
      this.fetchData(this.id, this.isReview);
    } else {
      const {
        id,
        isReview
      } = this.$route.query;
      this.$nextTick(() => {
        if (id) {
          this.fetchData(id, isReview);
        }
      });
    }
  },
  methods: {
    handleSearch() {},
    exportExcel() {},
    exportWord() {}
  }
};