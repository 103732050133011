// 总计
export const StatisticsKeys = ['totalIncome', 'totalIncomed', 'totalCollect', 'totalSubsidy', 'unTotalIncome', 'checkAccountMoney', 'peopleCount'];
export const StatisticsTransfor = {
  totalAccompanyCount: 'accompanyCount',
  totalAdultCount: 'adultCount',
  totalChildCount: 'childCount',
  checkAccountMoneyCount: 'checkAccountMoney',
  subsidyCount: 'totalSubsidy',
  totalCollect: 'totalCollect',
  totalIncomed: 'totalIncomed',
  totalPayCount: 'totalIncome',
  totalSubsidised: 'totalSubsidised',
  unIncomeCount: 'unTotalIncome',
  unTotalSubsidy: 'unTotalSubsidy'
};