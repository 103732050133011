var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-clone form-fix-barl"
  }, [_c("div", {
    staticClass: "h20"
  }), _c("FormInput", {
    ref: "formInputRef",
    attrs: {
      "form-data": _vm.form,
      "order-type": _vm.orderType
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }, [_c("FormRemarks", {
    ref: "teamRemarksRef"
  }), _c("TableFund", {
    ref: "teamFundRef"
  }), _c("TableProxy", {
    ref: "teamProxyRef"
  }), _c("TableRefund", {
    ref: "teamRefundRef"
  }), _c("TablePickup", {
    ref: "teamPickupRef"
  }), _c("TableTourist", {
    ref: "teamTouristRef"
  }), _c("TableReception", {
    ref: "teamReceptionRef"
  })], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "next"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 下一步 ")])], 1), _c("DialogCustom", {
    ref: "teamDialogCustom",
    attrs: {
      "order-type": _vm.orderType
    },
    on: {
      "on-select": _vm.selectCustom
    }
  }), _c("DialogProduct", {
    ref: "teamDialogProduct",
    attrs: {
      "order-type": _vm.orderType
    },
    on: {
      "on-select": _vm.selectProduct
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };