var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "500px",
      title: "更新密码",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.updatePassword
    }
  }, [_c("el-form", {
    ref: "pwdForm",
    attrs: {
      "status-icon": "",
      model: _vm.pwdForm,
      rules: _vm.pwdRules
    }
  }, [_c("el-tag", {
    staticStyle: {
      display: "block",
      "font-size": "16px",
      height: "40px",
      "line-height": "40px"
    },
    attrs: {
      type: "warning",
      size: "medium"
    }
  }, [_vm._v(" 密码必须为字母+数字混合,且长度至少6位 ")]), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "password"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "新密码"
    },
    model: {
      value: _vm.pwdForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "passwordConfirm"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "确认密码"
    },
    model: {
      value: _vm.pwdForm.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "passwordConfirm", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.passwordConfirm"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };