/* eslint-disable no-empty-pattern */
import { supplierOtherPayRequest, supplierOtherPayExportRequest } from '@/api/report';
const defaultState = () => ({
  data: null
});
const state = defaultState();
const getters = {
  data: state => state.data
};
const actions = {
  list({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return supplierOtherPayRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'data',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  export({}, params) {
    return new Promise((resolve, reject) => {
      return supplierOtherPayExportRequest(params).then(res => {
        resolve(res);
      }).catch(err => reject(err));
    });
  }
};
const mutations = {
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};