import "core-js/modules/es.array.push.js";
// import { mapGetters } from 'vuex'
import { SelectGuide, BtnExport, InputMonthRange, SelectDictionary } from '@/components/common';
import TableList from './TableList';
import { guideAnalyze, guideAnalyzeTotal } from '@/config/analysis';
import { mergeTableRow } from '@/utils/table';
import { decimalSplit } from '@/utils/index';
import analysisMixin from '../analysisMixin';
const listKeys = ['totalBuyMoney', 'per', 'tagPer', 'value', 'complete'];
const totalKeys = ['totalBuyMoneyTotal', 'tagPerTotal', 'valueTotal'];
export default {
  components: {
    SelectGuide,
    TableList,
    BtnExport,
    InputMonthRange,
    SelectDictionary
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: [],
      isTableShow: true,
      columnsKeys: []
    };
  },
  computed: {
    guideData() {
      return this.$store.getters['analyse/index/guideData'];
    }
  },
  watch: {
    guideData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParams) {
      this.isTableShow = false;
      this.$store.dispatch('analyse/index/fetchGuide', queryParams);
    },
    onSelect(val) {
      this.searchParams.productType = val.label;
    },
    initData(data) {
      const {
        shops,
        list
      } = data;
      this.columnsKeys = [];
      const columns = this.formatColumnsData(shops);
      this.columnData = [...guideAnalyze, ...columns, ...guideAnalyzeTotal];
      this.isTableShow = true;
      const totalList = this.formatSumData(list);
      const tableData = this.formatData(list);
      const dataList = this.sortData([...tableData, ...totalList]);
      this.tableData = mergeTableRow(dataList, ['guideName']);
    },
    sortData(list) {
      list.sort((a, b) => {
        return a.guideId - b.guideId;
      });
      return list;
    },
    // 表格头部处理
    formatColumnsData(shops) {
      let list = [];
      shops.forEach(it => {
        const item = {
          attrs: {
            prop: '',
            label: it.name
          },
          children: [{
            attrs: {
              prop: `${it.id}totalBuyMoney`,
              label: '购物金额'
            }
          }, {
            attrs: {
              prop: `${it.id}per`,
              label: '人均'
            }
          }, {
            attrs: {
              prop: `${it.id}tagPer`,
              label: '目标人均'
            }
          }, {
            attrs: {
              prop: `${it.id}value`,
              label: '增减值'
            }
          }, {
            attrs: {
              prop: `${it.id}complete`,
              label: '完成率'
            }
          }]
        };
        const kys = [`${it.id}totalBuyMoney`, `${it.id}tagPer`, `${it.id}value`];
        this.columnsKeys = [...this.columnsKeys, ...kys];
        list.push(item);
      });
      return list;
    },
    // 计算小计
    formatSumData(list) {
      if (!list.length) return [];
      const result = [];
      let sumObj = {};
      list.forEach(it => {
        const itemList = it.productTypes;
        const {
          guideId,
          guideName
        } = it;
        const item = {
          guideId,
          guideName,
          mergeId: guideId
        };
        if (itemList.length > 1) {
          let adultCountTotal = 0;
          itemList.forEach(v => {
            adultCountTotal += v.adultCount;
            totalKeys.forEach(k => {
              v[k] = 0;
            });
            v.shops.forEach(z => {
              listKeys.forEach(key => {
                v[key + 'Total'] += decimalSplit(z[key]);
                v[z.shopId + key] = z[key];
                if (key == 'complete') {
                  v[z.shopId + key] = decimalSplit(z[key] * 100) + '%';
                }
              });
            });
            return v;
          });
          result.push({
            ...item,
            ...it,
            adultCountTotal
          });
        }
      });
      const keys = [...this.columnsKeys, ...totalKeys, 'adultCount'];
      const data = result.map(it => {
        keys.forEach(key => {
          sumObj[key] = 0;
        });
        const {
          guideId,
          guideName,
          productTypes,
          mergeId
        } = it;
        productTypes.forEach(v => {
          keys.forEach(key => {
            sumObj[key] += v[key] || 0;
            // 计算小计行的完成率
            const index = key.indexOf('totalBuyMoney');
            if (index > 0) {
              const shopId = key.slice(0, index);
              // 计算完成率有误，需修改
              const per = decimalSplit(sumObj[shopId + 'totalBuyMoney'] / it.adultCountTotal);
              sumObj[shopId + 'per'] = per;
              sumObj[shopId + 'complete'] = decimalSplit(per / sumObj[shopId + 'tagPer'] * 100) + '%';
              if (!v[shopId + 'tagPer']) {
                sumObj[shopId + 'complete'] = '100%';
              }
            }
          });
        });
        return {
          ...sumObj,
          guideId,
          guideName,
          mergeId,
          productType: '小计'
        };
      });
      return data;
    },
    // 整合
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.productTypes;
        const {
          guideId,
          guideName
        } = it;
        const item = {
          guideId,
          guideName,
          mergeId: guideId
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const {
              shops
            } = v;
            totalKeys.forEach(k => {
              v[k] = 0;
            });
            shops.forEach(z => {
              listKeys.forEach(key => {
                v[z.shopId + key] = z[key] || 0;
                if (key == 'complete') {
                  v[z.shopId + key] = decimalSplit(z[key] * 100) + '%';
                }
                v[key + 'Total'] += decimalSplit(z[key]);
              });
            });
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            itemIndex: 0
          });
        }
      });
      return result.map(it => {
        // 计算完成率  总人均/总目标人均
        it.completeTotal = decimalSplit(it.perTotal / it.tagPerTotal * 100) + '%';
        if (it.tagPerTotal == 0) {
          it.completeTotal = '100%';
        }
        totalKeys.forEach(key => {
          it[key] = decimalSplit(it[key]);
        });
        return it;
      });
    }
  }
};