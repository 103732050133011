import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { SelectMutiple } from '@/components/common';
import { radioAuthArea } from '@/config/radios';
import { reloadMixin } from '@/mixin';

// import { generateTitle } from '@/utils/i18n'
const MULTIPLE = 'multiple';
export default {
  components: {
    // FooterBar,
    SelectMutiple
  },
  mixins: [reloadMixin],
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {
      MULTIPLE,
      radioAuthArea,
      checkedAllMenu: false,
      checkedAllAction: false,
      data: []
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    // generateTitle,
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.fetchPermissionMenu(id);
    },
    fetchPermissionMenu(id) {
      this.$store.dispatch('system/role/fetchPermission', id).then(data => {
        if (!data) return;
        this.data = this.formatData(data);
      });
    },
    formatData(list) {
      let tmp = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        const obj = {
          value: item.id,
          label: item.name,
          id: item.id,
          isRole: item.isRole,
          orderNumber: item.orderNumber,
          radioAll: false,
          checkedAllAction: false,
          // 操作全选
          checked: item.isRole,
          // 菜单权限是否选择
          authArea: item.authArea,
          // 权限范围的类型
          isNeedAuthArea: item.isNeedAuthArea,
          // 权限范围是否显示在设置中
          radios: radioAuthArea,
          multipleDepartment: item.multipleDepartment,
          actions: item.roleMenuOpts.map(a => {
            return {
              label: a.name,
              id: a.id,
              checked: a.isRole
            };
          }),
          actionsCheckedAll: item.roleMenuOpts.every(it => it.isRole)
        };
        if (!item.subMenus) {
          tmp.push({
            ...obj,
            subMenus: []
          });
        } else {
          tmp.push({
            ...obj,
            subMenus: this.formatData(item.subMenus)
          });
        }
      }
      return tmp;
    },
    // 菜单全选勾选状态
    getCheckAllState(data) {
      if (!data.subMenus || !data.subMenus.length) return;
      this.checkedAllMenu = data.subMenus.every(it => !!it.checked);
    },
    // 权限操作勾选状态
    getCheckAllOptsState(data) {
      if (!data.subMenus || !data.menus.length) return;
      const hasOpts = data.menus.filter(it => {
        return it.actions.length;
      });
      if (hasOpts.length) {
        this.checkedAllAction = data.menus.every(it => {
          return it.actions.every(act => !!act.checked);
        });
      } else {
        this.checkedAllAction = false;
      }
    },
    // 全选菜单
    changeAllMenu(row, val) {
      row.subMenus.forEach(it => {
        it.checked = val;
      });
    },
    // 全选权限
    changeAllAction(row, val) {
      row.subMenus.forEach(it => {
        this.changeRowAction(it, val);
        // it.actions = it.actions.map(a => {
        //   a.checked = val
        //   return a
        // })
      });
    },
    // 权限全选--当前行
    changeRowAction(row, val) {
      row.actions = row.actions.map(a => {
        a.checked = val;
        return a;
      });
    },
    // 权限范围
    changeAllAuthArea(row, val) {
      row.subMenus.forEach(it => {
        // 多选的时候不处理了，避免性能消耗
        it.authArea = val;
        if (val != MULTIPLE) {
          // 如果多选了就清理掉
          this.batchDepartmentSelect && this.cleanDepartment(row);
        }
      });
    },
    changeDepartment(row, list) {
      const result = [];
      list.forEach(val => {
        const [departmentId, subDepartmentId, grandsonDepartmentId] = val;
        const opts = {
          departmentId
        };
        subDepartmentId ? opts.subDepartmentId = subDepartmentId : null;
        grandsonDepartmentId ? opts.grandsonDepartmentId = grandsonDepartmentId : null;
        result.push(opts);
      });
      row.multipleDepartment = result;
    },
    // 批量设置
    batchDepartment(row, list) {
      row.subMenus.forEach(row => {
        this.changeDepartment(row, list);
      });
      this.batchDepartmentSelect = true;
    },
    cleanDepartment(row) {
      row.subMenus.forEach(row => {
        if (row.authArea != MULTIPLE) {
          delete row.multipleDepartment;
        }
      });
      this.batchDepartmentSelect = false;
    },
    beforeCommit(item) {
      const {
        authArea,
        isRole,
        subMenus
      } = item;
      const {
        travelAgencyId
      } = this.userData;
      const roleId = Number(this.$route.query.id);
      const menuId = item.id;
      const optIds = item.actions.filter(a => a.checked).map(v => v.id);
      const checkedList = subMenus.filter(it => it.checked && it.isRole);
      const filterMenus = checkedList.map(it => {
        it.menuId = it.id;
        it.authArea = authArea != MULTIPLE ? it.authArea : MULTIPLE;
        it.optIds = it.actions.filter(a => a.checked).map(v => v.id);
        return it;
      });
      const options = {
        menuId,
        roleId,
        isRole: +isRole,
        authArea,
        travelAgencyId,
        subMenus: filterMenus,
        optIds
      };
      return options;
    },
    processCommitOption(item) {
      const {
        id: menuId,
        authArea,
        checked,
        actions,
        subMenus
      } = item;
      const {
        travelAgencyId
      } = this.userData;
      const roleId = Number(this.$route.query.id);
      const optIds = actions.filter(a => a.checked).map(v => v.id);
      const options = {
        menuId,
        roleId,
        isRole: +checked,
        authArea: authArea != MULTIPLE ? authArea : MULTIPLE,
        travelAgencyId,
        subMenus,
        optIds
      };
      return options;
    },
    handleUpdateItem(item) {
      const options = this.processCommitOption(item);
      this.$store.dispatch('system/role/updatePermission', options).then(() => {
        this.$store.dispatch('permission/fetchUserAllowMenu');
        this.$store.dispatch('permission/permissionRole');
        this.$bus.tip();
      });
    },
    // 更新
    handleUpdateBatch(item) {
      const {
        subMenus
      } = item;
      // const { travelAgencyId } = this.userData
      // const roleId = Number(this.$route.query.id)
      // const menuId = item.id
      // const optIds = []
      const options = this.processCommitOption(item);
      const checkedList = subMenus.filter(it => it.checked);
      const filterMenus = checkedList.map(this.processCommitOption);
      // const filterMenus = checkedList.map(it => {
      //   it.menuId = it.id
      //   it.authArea = authArea != MULTIPLE ? it.authArea : MULTIPLE
      //   it.optIds = it.actions.filter(a => a.checked).map(v => v.id)
      //   return it
      // })

      // const options = {
      //   menuId,
      //   roleId,
      //   isRole: +isRole,
      //   authArea,
      //   travelAgencyId,
      //   subMenus: filterMenus,
      //   optIds,
      // }
      const optIds = checkedList.reduce((acc, it) => {
        return [...acc, ...it.actions.filter(a => a.checked).map(v => v.id)];
      }, []);
      options.subMenus = filterMenus;
      options.optIds = optIds;
      this.$store.dispatch('system/role/updatePermission', options).then(() => {
        this.$store.dispatch('permission/fetchUserAllowMenu');
        this.$store.dispatch('permission/permissionRole');
        this.$bus.tip();
      });
    }
  }
};