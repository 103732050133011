import TablePayed from './components/TablePayed';
import { FooterBar } from '@/components/common';
import { tagViewMixin } from '@/mixin';
export default {
  components: {
    FooterBar,
    TablePayed
  },
  mixins: [tagViewMixin],
  methods: {
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    }
  }
};