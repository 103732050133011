import FormItem from './FormItem.vue';
import { defaultState, rules } from '../config';
import { beforeCommit } from '../helper';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      rules,
      visible: false,
      form: defaultState()
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    handleSave() {
      this.$refs.formRef.validate().then(data => {
        if (!data) return;
        const filterTickets = true;
        const opts = beforeCommit(data, filterTickets);
        this.$store.dispatch('plan/boatPersonal/add', opts).then(() => {
          this.$bus.tip();
          this.$emit('reload');
          this.hide();
        });
      });
    }
  }
};