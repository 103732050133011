import "core-js/modules/es.array.reduce.js";
import { ShuttleType } from '@/config/plan';
import { PAY_TYPE } from '@/config/payType';
export default {
  data() {
    return {
      billCount: 0
    };
  },
  computed: {
    currentPlanId() {
      return this.$store.getters['plan/index/currentPlanId'];
    },
    fetchStatus() {
      return this.$store.getters['plan/index/fetchStatus'];
    },
    isECodePay() {
      return val => val && val.toUpperCase() === PAY_TYPE.ALIPAY_E_CODE;
    },
    // 企业码付金额是否与当前金额一致
    isShowEnterpriseMoney() {
      return row => {
        return this.isECodePay(row.payType) && row.alipayPeerPayAmount != row.rowTotal;
        // return (
        //   row.alipayPeerPayAmount != 0 &&
        //   row.alipayPeerPayAmount != row.rowTotal
        // )
      };
    },
    // 企业码支付金额不一致
    enterpriseDiffClass() {
      return ({
        row
      }) => {
        // if (
        //   row.alipayPeerPayAmount &&
        //   row.alipayPeerPayAmount != 0 &&
        //   row.alipayPeerPayAmount != row.totalPay
        // ) {
        //   return 'enterprise-diff'
        // }
        if (this.isShowEnterpriseMoney(row)) {
          return 'enterprise-diff';
        }
        return '';
      };
    }
  },
  methods: {
    selectAuditAdmin(row, val) {
      row.auditProgress = val;
    },
    // 多个子项并且合并了表格，合计金额
    calcMergeRow(list, keys, name) {
      keys.forEach(key => {
        list.forEach(it => {
          let sum = 0;
          it[name].forEach(item => {
            sum += +item[key];
          });
          it[name].forEach(item => {
            item[key] = sum;
          });
        });
      });
      return list;
    },
    // 合并子项提交处理
    commitMergeRow(list, keys, name) {
      keys.forEach(key => {
        list.forEach(it => {
          for (let i = 1; i < it[name].length; i++) {
            it[name][i][key] = '';
          }
        });
      });
      return list;
    },
    processParams(params) {
      params = {
        ...params,
        planId: this.currentPlanId
      };
      // 车队
      if (~this.fetchPath.indexOf('fleet')) {
        params = {
          ...params,
          shuttleType: ShuttleType.bus
        };
      }
      // 接送
      if (this.isShuttle) {
        params = {
          ...params,
          shuttleType: ShuttleType.shuttle
        };
      }
      // 景区加点
      if (this.isExtra) {
        params = {
          ...params,
          isAddScenic: this.isExtra
        };
      }
      // 借款申请
      if (this.isGuideBorrow) {
        params = {
          ...params,
          mode: 'DEFAULT'
        };
      }
      // 额度申请
      if (this.isGuideQuota) {
        params = {
          ...params,
          mode: 'ALIPAY'
        };
      }
      return params;
    },
    // 获取数据
    fetchData(params = {}) {
      this.tableLoading = true;
      params = this.processParams(params);
      const commitStatus = (status = false) => {
        this.$store.commit('plan/index/FETCH_STATUS', status);
      };
      commitStatus(true);

      // 清空图片
      this.$store.commit('plan/index/CUR_SIGN_LIST', []);
      this.$store.dispatch(this.fetchPath, params).then(data => {
        const dataList = data.list ? data.list : data;
        const signList = this.getCurrentSignList(dataList);
        this.billCount = signList.length; // 单据数量

        this.initData(data);
        this.tableLoading = false;
        commitStatus();
      }).catch(() => {
        this.tableLoading = false;
        commitStatus();
      });
    },
    // 选择订单列表
    selectOrderNumber(item, val) {
      const {
        label,
        value
      } = val;
      item.customerTripNumber = label;
      item.customerOrderId = value ? value : -1;
    },
    // 签单列表
    getCurrentSignList(list) {
      if (!list || !list.length) return [];
      const result = list.reduce((acc, cur) => {
        if (cur.pics) {
          acc = [...acc, ...cur.pics];
        }
        return acc;
      }, []);
      this.$store.commit('plan/index/CUR_SIGN_LIST', result);
      return result;
    },
    reloadData() {
      const id = this.currentPlanId;
      this.fetchData();
      this.$store.dispatch('plan/index/groupProfit', id);
    },
    handleUpdate() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        }
        const dataList = this.beforeCommit(this.tableData);
        this.$store.dispatch(this.updatePath, dataList).then(() => {
          resolve(dataList);
        }).catch(reject);
      });
    },
    saveAndAudit() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        }
        const loading = this.$bus.loading();
        return this.handleUpdate().then(() => {
          this.reloadData();
          this.$bus.tip();
          loading.close();
          resolve();
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    createOrUpdate() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        }
        const dataList = this.beforeCommit(this.tableData);
        const loading = this.$bus.loading();
        return this.$store.dispatch(this.updatePath, dataList).then(() => {
          this.reloadData();
          this.$bus.tip();
          loading.close();
          resolve();
        }).catch(() => {
          this.reloadData();
          loading.close();
          reject();
        });
      });
    }
  }
};