import DetailBooking from './DetailBooking.vue';
const defaultState = () => ({
  accompanyCount: 0,
  adultCount: '',
  arriveDate: '',
  backDate: '',
  childCount: 0,
  contactsTel: '',
  fax: '',
  hotelId: 0,
  hotelOPUser: '',
  leaveDate: '',
  myTeam: '',
  name: '',
  outDate: '',
  planId: 0,
  price: 0,
  remarks: '',
  saleDepartment: '',
  standard: '',
  tel: '',
  travelAgencyId: 0
});
export default {
  components: {
    DetailBooking
  },
  data() {
    return {
      visible: false,
      form: defaultState(),
      id: '',
      search: {}
    };
  },
  methods: {
    showRes(params) {
      this.visible = true;
      this.search = params;
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    fetchData(params) {
      this.$store.dispatch('order/resourcePlan/orderHotelByI', params).then(data => {
        if (!data) return;
        this.form = {
          travelAgencyId: params.travelAgencyId,
          rowId: params.planId,
          ...data
        };
      });
    }
  }
};