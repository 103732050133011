import { AuditRole } from '@/config/audit';
export const itemListKeys = () => ({
  busFee: 0,
  busType: '',
  busTypeId: '',
  licenceNumber: '',
  totalPay: 0,
  otherFee: 0,
  planBusCompanyId: 0,
  startDate: '',
  endDate: '',
  driverId: '',
  driverName: '',
  driverPhone: ''
  // remark: '',
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  payType: 'sign',
  busCompanyName: '',
  money: 0,
  otherFee: 0,
  shuttleType: '',
  remark: '',
  peopleCount: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});