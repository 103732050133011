var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "微信支付",
      width: "400px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "content tac"
  }, [_c("h3", [_vm._v("微信扫码支付")]), _c("p", {
    staticClass: "red"
  }, [_vm._v(" 支付金额：" + _vm._s(_vm.money) + " ")]), _c("div", {
    staticClass: "mt20"
  }), _c("qrcode-vue", {
    attrs: {
      value: _vm.value,
      size: _vm.size,
      level: "H"
    }
  }), _c("div", {
    staticClass: "mt20"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      plain: "",
      round: ""
    },
    on: {
      click: _vm.handleCheckStatus
    }
  }, [_vm._v(" 支付失败 ")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      plain: true,
      round: ""
    },
    on: {
      click: _vm.handleCheckStatus
    }
  }, [_vm._v(" 支付成功 ")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };