var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "btn-link"
  }, [_c("el-link", _vm._g(_vm._b({
    style: _vm.getFontSize
  }, "el-link", _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };