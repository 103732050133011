import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 开始日期 input 组件
 * @vue-prop {String} [label=发团时间] - 输入框对应的名称
 * @vue-prop {Boolean} [disabled=true] - 是否允许选择大于当前时间
 * @vue-prop {Boolean} [isRequire=false] - 是否在label前面加 * 标记为必填项
 * @vue-data {String} value - 选择时间后的值
 * @vue-data {Object} pickerOptions - 快捷时间配置信息
 */

export default {
  name: 'InputDatePicker',
  mixins: [inputMixin],
  inheritAttrs: false,
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  }
};