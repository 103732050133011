import { defDictionary } from '@/utils/dictionary';
import { MonthKey, MonthObjKey, KPIType, KPIType2Prop } from '@/config/analysis';
export { MonthKey, MonthObjKey, KPIType, KPIType2Prop };
export const DefaultState = month => {
  return {
    month: `${month + 1}月`,
    monthKey: MonthKey[month],
    count: '',
    profit: '',
    turnover: '',
    isCustom: true
  };
};
export const GetTargetType = () => {
  const {
    value: groupType
  } = defDictionary(this.$store, 'teamType');
  const {
    value: productType
  } = defDictionary(this.$store, 'productType');
  return {
    groupType,
    productType,
    $groupType: groupType,
    $productType: productType
  };
};
export const Rules = {
  customerId: [{
    required: true,
    message: '请选择客户',
    trigger: 'change'
  }],
  // outOPUserName: [
  //   { required: true, message: '客服计调必填', trigger: 'change' },
  // ],
  saleUserName: [{
    required: true,
    message: '业务员必填',
    trigger: 'change'
  }]
};