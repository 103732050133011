import "core-js/modules/es.array.push.js";
import { tableMixin, summaryMixin } from '@/mixin';
import { StatusPlanAudit, StatusProgress, Guides, OutOPUsers } from '@/components/common';
export default {
  components: {
    StatusPlanAudit,
    StatusProgress,
    Guides,
    OutOPUsers
  },
  mixins: [tableMixin, summaryMixin],
  props: {
    userId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      statisticsKeys: ['count', 'unIncome', 'totalIncome', 'totalIncomed', 'totalQuotePay', 'totalQuoteGrossProfit']
    };
  },
  methods: {
    linkDetail(row) {
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType
        }
      });
    }
  }
};