var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("SearchBase", {
    on: {
      "on-search": _vm.fetchData
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "单位",
      prop: ""
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleCreate
    }
  })], 1)], 2), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      "show-page": true,
      total: _vm.total
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _c("DialogAdd", {
    ref: "dialogAddRef",
    on: {
      reload: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };