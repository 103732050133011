import { SelectStaff, BtnExport, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import itemChart from './itemChart';
import { saleAnalyze } from '@/config/analysis';
import { decimalSplit } from '@/utils';
import analysisMixin from '../analysisMixin';
export default {
  components: {
    SelectStaff,
    TableList,
    BtnExport,
    InputMonthRange,
    itemChart
  },
  mixins: [analysisMixin],
  data() {
    return {
      isVisible: true,
      tableData: [],
      columnData: saleAnalyze,
      turnoverList: [],
      countrList: [],
      profitList: []
    };
  },
  computed: {
    saleData() {
      return this.$store.getters['analyse/index/saleData'];
    }
  },
  watch: {
    saleData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.isVisible = false;
      this.tableData = data;
      // 营业额
      this.turnoverList = data.map(it => {
        const item = {
          name: it.saleMan,
          value: it.turnover,
          complet: decimalSplit(it.turnoverCompletionRate * 100)
        };
        return item;
      });
      // 人数
      this.countrList = data.map(it => {
        const item = {
          name: it.saleMan,
          value: it.count,
          complet: decimalSplit(it.peopleCompletionRate * 100)
        };
        return item;
      });
      // 毛利
      this.profitList = data.map(it => {
        const item = {
          name: it.saleMan,
          value: it.profit,
          complet: decimalSplit(it.profitCompletionRate * 100)
        };
        return item;
      });
      this.$nextTick(() => {
        this.isVisible = data.length ? true : false;
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchSpecialtSale', queryParams);
    }
  }
};