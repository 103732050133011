// 发团预定安排 保险
import request from '@/utils/request';

// 安排列表
export function fetchInsurance(params) {
  return request({
    url: '/order/insurance/listAll',
    method: 'get',
    params
  });
}

// 安排更新
export function updateInsurance(data) {
  return request({
    url: '/order/insurance/save',
    method: 'post',
    data
  });
}

// 安排删除
export function deleteInsurance(ids) {
  return request({
    url: '/order/insurance/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteInsuranceItem(id) {
  return request({
    url: '/order/insurance/delType',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchInsuranceOrder(params) {
  return request({
    url: '/order/insurance/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateInsuranceOrder(data) {
  return request({
    url: '/order/insurance/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteInsuranceOrder(id) {
  return request({
    url: '/order/insurance/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteInsuranceOrderItem(id) {
  return request({
    url: '/order/insurance/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchInsuranceOrderList(params) {
  return request({
    url: '/order/insurance/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectInsuranceOrderList(data) {
  return request({
    url: '/order/insurance/saveAndList',
    method: 'post',
    data
  });
}