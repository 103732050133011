import { inputMixin } from '@/mixin';

/**
 * @module components/common/Input
 * @desc 收款人
 * @vue-prop {Boolean}} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean}} [isRequire=false] - 是否必填项
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  created() {
    this.payees = this.loadAll();
  },
  methods: {
    loadAll() {
      return [{
        value: '三全鲜食（北新泾店）',
        address: '长宁区新渔路144号'
      }, {
        value: 'Hot honey 首尔炸鸡（仙霞路）',
        address: '上海市长宁区淞虹路661号'
      }, {
        value: '阳阳麻辣烫',
        address: '天山西路389号'
      }, {
        value: '南拳妈妈龙虾盖浇饭',
        address: '普陀区金沙江路1699号鑫乐惠美食广场A13'
      }];
    }
  }
};