import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditInsuranceDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditInsuranceDetail,
      planId: '',
      insuranceId: ''
    };
  },
  mounted() {
    this.$bus.on('insurance:list', this.show);
    this.$bus.on('insurance:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('insurance:order', {
        planId: this.planId,
        insuranceId: this.insuranceId
      });
    },
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: insuranceId
      } = params;
      this.visible = true;
      this.planId = planId;
      this.insuranceId = insuranceId;
      this.fetchData(planId, insuranceId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planId, insuranceId) {
      this.$store.dispatch('plan/insurance/fetchOrder', {
        planId,
        insuranceId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = list;
      });
    },
    reloadData() {
      this.fetchData(this.planId, this.insuranceId);
    },
    // 移除
    handleRemove(id) {
      this.$store.dispatch('plan/insurance/deleteOrder', id).then(() => {
        this.fetchData(this.planId, this.insuranceId);
      });
    },
    // 修改人数
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
      item.money = this.calcRowTotalMoney(item);
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        price,
        count,
        otherFee
      } = obj;
      let total = price * count + Number(otherFee);
      return total;
    },
    // 保存
    handleSave() {
      this.$store.dispatch('plan/insurance/updateOrder', this.tableData).then(() => {
        this.fetchData(this.planId, this.insuranceId);
      });
    },
    handleCancel() {}
  }
};