import CountTo from 'vue-count-to';
import DialogRenewLog from './DialogRenewLog';
export default {
  components: {
    CountTo,
    DialogRenewLog
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    showRenewLog() {
      this.$refs.dialogRef.show();
    }
  }
};