var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.title,
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "科目名称"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("CheckboxBase", {
    attrs: {
      label: "是否属于收支科目"
    },
    model: {
      value: _vm.form.isRevenueAndExpenditure,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isRevenueAndExpenditure", $$v);
      },
      expression: "form.isRevenueAndExpenditure"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };