import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-empty-pattern */

// 预收预付

import { updateBillAdvance, createBillAdvance, fetchPreCash } from '@/api/bill/advance';
// import { fetchBillList } from '@/api/bill/billDown'
import { cashModeType, TYPES } from '@/config/billDown';
import { formatTableData } from '@/utils/data';
const dfaultState = () => ({
  data: null
});
const state = dfaultState();
const getters = {
  data: state => state.data
};
const actions = {
  // 预收预付现金日记流
  fetch({
    commit
  }, params) {
    return fetchPreCash({
      cashType: '',
      cashSourceType: cashModeType.PRE_PAYMENT,
      ...params
    }).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      const getCustomerType = val => TYPES.customer === val;
      data.list = data.list.reduce((acc, cur) => {
        const {
          resourceId,
          details,
          resourceName,
          balance
        } = cur;
        let {
          totalPreIncome,
          totalPrePay
        } = cur;
        if (getCustomerType(cur.cashType)) {
          totalPreIncome = balance;
          totalPrePay = 0;
        } else {
          totalPrePay = balance;
          totalPreIncome = 0;
        }
        details.forEach(it => {
          it.cashModeLabel = it.cashMode;
          it.cashTypeLabel = it.cashType;
          it.cashActionLabel = it.cashAction;
          acc.push({
            rowId: resourceId,
            ...it,
            resourceName,
            balance,
            totalPreIncome,
            totalPrePay
          });
        });
        return acc;
      }, []);
      data.list = formatTableData(data.list);

      // const getTotalMoney = (resourceId, cashAction) => {
      //   // 合计预收、付金额
      //   return data.list.reduce((acc, cur) => {
      //     if (cashAction == cur.cashAction && cur.resourceId == resourceId) {
      //       acc = processAdd(acc, cur.money)
      //     }
      //     return acc
      //   }, 0)
      // }

      // const cashTypeMaps = [TYPES.customer, TYPES.shop]

      // data.list.forEach(it => {
      //   // 处理预收、付款
      //   const { resourceId, cashType } = it

      //   const payMoney = getTotalMoney(resourceId, CashFeeType.PAY)
      //   const incomeMoney = getTotalMoney(resourceId, CashFeeType.INCOME)

      //   // 收入，只显示预收余额
      //   if (cashTypeMaps.includes(cashType)) {
      //     it.incomeMoney = processMinus(incomeMoney, payMoney)
      //     it.payMoney = 0
      //   } else {
      //     // 支出，只显示预付余额
      //     it.payMoney = processMinus(payMoney, incomeMoney)
      //     it.incomeMoney = 0
      //   }

      //   it.cashModeLabel = it.cashMode
      //   it.cashTypeLabel = it.cashType
      //   it.cashActionLabel = it.cashAction
      //   it.rowId = it.resourceId
      //   return it
      // })

      // data.list = formatTableData(data.list)

      commit('SET_DATA', data);
      return res.data;
    });
  },
  // 更新付款
  createItem({}, opts) {
    return createBillAdvance(opts);
  },
  // 更新付款
  updateItem({}, opts) {
    return updateBillAdvance(opts);
  }
};
const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};