import "core-js/modules/es.array.push.js";
import { getNextDate, getDaysBetween } from '@/utils';
const roomsState = () => ({
  quantity: '',
  roomType: '',
  roomTypeId: '',
  roomName: ''
});
export default {
  methods: {
    // 计算结束日期
    calcTripEndDate() {
      let {
        productDays,
        productId,
        startTripDate
      } = this.form;
      if (!productId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择产品线路'
        });
        return false;
      }
      let curDate = startTripDate;
      const getNextDay = days => {
        for (let i = 1; i < days; i++) {
          curDate = getNextDate(curDate);
        }
        return curDate;
      };
      if (!productDays) {
        this.getProductDays(productId).then(data => {
          const {
            days
          } = data;
          this.form.endTripDate = getNextDay(days);
        });
      } else {
        this.form.endTripDate = getNextDay(productDays);
      }
    },
    formatHotelData(dataList) {
      const list = dataList.filter(it => it.area || it.standard);
      const {
        customerOrderId,
        adultCount
      } = this.form;
      if (!list.length) return [];
      return list.map(it => {
        return {
          customerOrderId,
          customerId: '-1',
          count: adultCount / 2,
          hotelArea: it.area,
          hotelName: it.hotelName,
          hotelId: it.hotelId,
          orderDate: it.itineraryDate,
          hotelStandard: it.standard
        };
      });
    },
    createOrUpdateHotel(dataList) {
      return this.$store.dispatch('order/index/createOrHotel', dataList);
    },
    async createOrUpdateItinerary(id) {
      this.clearLastItinerary(id);
      const productInfo = await this.getProduct(this.form.productId);
      const tripList = this.formatProduct(productInfo);
      const processList = this.beforeCommit(tripList);
      const hotelList = this.formatHotelData(tripList);
      this.createOrUpdate(processList);
      this.createOrUpdateHotel(hotelList);
    },
    // 获取订单行程
    fetchItinerary(customerOrderId) {
      this.$store.dispatch('order/index/fetchItinerary', customerOrderId).then(data => {
        this.tripData = this.formatProduct({
          itineraries: data
        });
      });
    },
    createOrUpdate(dataList) {
      this.$store.dispatch('order/index/createOrUpdateItinerary', dataList);
    },
    // 清理上一次的行程
    clearLastItinerary(id) {
      return this.$store.dispatch('order/index/delItineraryByOrderId', id);
    },
    formatProduct(data) {
      if (!data) return;
      const {
        itineraries
      } = data;
      const {
        customerOrderId,
        startTripDate,
        endTripDate
      } = this.form;
      const betweenDay = getDaysBetween(startTripDate, endTripDate);
      let curDate = startTripDate;
      let tripList = [];
      if (!itineraries || !itineraries.length) return;
      for (let i = 0; i < betweenDay; i++) {
        const item = itineraries[i];
        const scenics = item.scenics || [];
        const itineraryList = scenics.length ? scenics.map(it => it.scenicName) : [];
        const isPickText = item.isPick ? '【接团】' : '';
        const isSendText = item.isSend ? '【送团】' : '';
        // 如果日期没有超出行程
        if (item) {
          delete item.id;
          item.itineraryDate = curDate;
          item.customerOrderId = customerOrderId;
          item.rooms = [roomsState()];
          item.itineraryDesc = `${isPickText} ${itineraryList.join(' ')} ${item.selfScenic} ${isSendText}`;
          item.transports = [];
          tripList.push(item);
        } else {
          tripList.push({
            isPick: false,
            isSend: false,
            itineraryDate: curDate,
            itineraryDesc: '',
            customerOrderId,
            rooms: [roomsState()],
            transports: []
          });
        }
        curDate = getNextDate(curDate);
      }
      return tripList;
    },
    // 选择产品 获取产品数据
    getProduct(productId) {
      return this.$store.dispatch('resource/product/fetchItinerary', productId);
    },
    // 处理提交的数据
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list.map((it, index) => {
        if (index == 0) {
          it.isPick = true;
        }
        if (index === this.tripData.length - 1) {
          it.isSend = true;
        }
        it.arrangeState = !it.arrangeState ? 2 : 0;
        return it;
      });
    }
  }
};