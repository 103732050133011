import QrcodeVue from 'qrcode.vue';
import { PAY_ERR_STATUS, PAY_NET_TYPE } from '@/config/payType';

// const MAX_TIMES = 60 * 3

export default {
  name: 'DialogWxPay',
  components: {
    QrcodeVue
  },
  data() {
    return {
      visible: false,
      value: '',
      money: 0,
      size: 240,
      outTradeNo: ''
    };
  },
  methods: {
    show({
      value,
      money,
      outTradeNo
    }) {
      this.visible = true;
      this.value = value;
      this.money = money;
      this.outTradeNo = outTradeNo;
    },
    hide() {
      this.visible = false;
    },
    handleCheckStatus() {
      this.checkWechatPayStatus(this.outTradeNo);
      // 关闭弹窗
      this.hide();
    },
    // 通知支付状态给后端
    notiflyStatus(data, status = PAY_ERR_STATUS.SUCCESS) {
      const {
        out_trade_no,
        payer,
        amount,
        transaction_id: trade_no
      } = data;
      const {
        openid
      } = payer;
      const {
        payer_total
      } = amount;
      const params = {
        status,
        trade_no,
        out_trade_no,
        payType: PAY_NET_TYPE.WECHAT,
        seller_id: openid,
        total_amount: payer_total
      };
      this.$store.dispatch('system/payment/notiflyStatus', params);
    },
    checkWechatPayStatus(outTradeNo) {
      /**
       * 成功后返回数据
       */
      // { "amount": { "currency": "CNY", "payer_currency": "CNY", "payer_total": 1, "total": 1 }, "appid": "wxdace645e0bc2cXXX", "attach": "", "bank_type": "OTHERS", "mchid": "1900006XXX", "out_trade_no": "44_2126281063_5504", "payer": { "openid": "o4GgauJP_mgWEWictzA15WT15XXX" }, "promotion_detail": [], "success_time": "2021-03-22T10:29:05+08:00", "trade_state": "SUCCESS", "trade_state_desc": "支付成功", "trade_type": "JSAPI", "transaction_id": "4200000891202103228088184743" }

      // {"code":200,"message":"操作成功","data":{"transaction_id":"4200001742202303315029329680","amount":{"payer_total":1,"total":1,"currency":"CNY","payer_currency":"CNY"},"mchid":"1636839589","trade_state":"SUCCESS","bank_type":"OTHERS","promotion_detail":[],"success_time":"2023-03-31T08:32:19+08:00","payer":{"openid":"owiIV6izeTLzdeG_7T7KND2OMMYc"},"out_trade_no":"SMS_20_VASPay_1680222720123","appid":"wxf65b0c1dedfdad2a","trade_state_desc":"支付成功","trade_type":"NATIVE","attach":"{\"uId\":187,\"tripId\":3,\"logId\":20,\"type\":\"TripVASPay\",\"vasCode\":\"SMS\"}","scene_info":{"device_id":"127.0.0.1"}}}

      this.$store.dispatch('system/payment/wechatPayStatus', outTradeNo).then(res => {
        if (!res || !res.data) return;
        const data = res.data;
        if (data.trade_state === 'SUCCESS' && data.trade_state_desc === '支付成功') {
          this.$bus.tip({
            message: data.trade_state_desc
          });
          this.$emit('pay:success');
          // 通知后端状态
          this.notiflyStatus(res.data);
        }
      });

      // const isDone = () => {
      //   let index = 1
      //   if (index > MAX_TIMES) {
      //     return true
      //   }
      //   index++
      //   return false
      // }

      // const doLoop = () => {
      //   let timer = setTimeout(() => {
      //     clearTimeout(timer)
      //   }, 1000 * 3)
      // }
      // doLoop()
    }
  }
};