/* eslint-disable no-empty-pattern */
// 公司发票
import { fetchMenu, sortMenu, createMenu, deleteMenu, deleteBatchMenu, addOperateToMenu, delMenuOperateFromMenu,
// fetchPermission,
fetchTripMenu, delTripMenu, addTripMenu, fetchMenuPermission, deleteMenuOperate, setAllMenu, setAllTripMenu, fetchAuditList, saveOrAuditList, detOrAuditList, delAuditById, fetchCustomerKpi, fetchCustomerYearKpi, fetchCustomerYearKpiEdit, fetchCustomerYearKpiEdit2, saveCustomerKpi, delCustomerKpi, systemLog, fetchTrip } from '@/api/system';
import { formatCreateTime, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  menuData: null,
  auditListData: null,
  customApi: [],
  customApiData: null
});
const state = dfaultState();
const getters = {
  menuData: state => state.menuData,
  auditListData: state => state.auditListData,
  customApi: state => state.customApi,
  customApiData: state => state.customApiData
};
const actions = {
  fetchMenu({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchMenu(queryParam).then(res => {
      const {
        data
      } = res;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.menuData ? state.menuData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatCreateTime(list), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('MENU_DATA', data);
      return originData;
    });
  },
  fetchMenuPermission({}, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchMenuPermission(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // fetchPermission({}, queryParam) {
  //   queryParam = { ...DEFAULT_PAGE, ...queryParam }
  //   return fetchPermission(queryParam).then(res => {
  //     const { data } = res
  //     return data
  //   })
  // },

  // 获取旅行社菜单
  fetchTripMenu({
    rootGetters
  }, params) {
    const {
      travelAgencyId
    } = rootGetters['user/userData'];
    const opts = {
      ...DEFAULT_PAGE,
      ...params,
      travelAgencyId,
      terminalSysId: 1,
      groupId: 1
    };
    return fetchTripMenu(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 一键设置旅行社菜单
  setAllTripMenu({}, opts) {
    return setAllTripMenu(opts);
  },
  // 添加菜单到旅行社
  addTripMenu({}, opts) {
    return addTripMenu(opts);
  },
  // 删除旅行社菜单
  delTripMenu({}, opts) {
    return delTripMenu(opts);
  },
  // 删除旅行社(操作)菜单，权限里到菜单
  deleteMenuOperate({}, opts) {
    return deleteMenuOperate(opts);
  },
  // 添加菜单的操作权限项目
  addOperateToMenu({}, opts) {
    return addOperateToMenu(opts);
  },
  // 删除菜单的操作权限项目
  delMenuOperateFromMenu({}, id) {
    return delMenuOperateFromMenu(id);
  },
  // 添加或更新菜单
  createOrUpdate({}, data) {
    return createMenu(data);
  },
  // 排序菜单
  sortMenu({}, opts) {
    return sortMenu(opts);
  },
  // 批量删除菜单
  deleteBatchMenu({}, opts) {
    return deleteBatchMenu(opts);
  },
  // 删除菜单
  deleteMenu({}, opts) {
    return deleteMenu(opts);
  },
  // 一键设置菜单
  setAllMenu({}, opts) {
    return setAllMenu(opts);
  },
  // 获取所有审核类型
  fetchAuditList({
    commit
  }, {}) {
    return fetchAuditList().then(res => {
      const {
        data
      } = res;
      commit('AUDIT_LIST_DATA', data);
      return data;
    });
  },
  // 新增审核流程
  saveOrAuditList({}, opts) {
    return saveOrAuditList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除审核流程
  detOrAuditList({}, opts) {
    return detOrAuditList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除审核项
  delAuditById({}, opts) {
    return delAuditById(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取客户kpi
  fetchCustomerKpi({
    commit
  }, opts) {
    return fetchCustomerKpi(opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_API_CUSTOM', data);
      return data;
    });
  },
  // 获取客户kpi
  fetchCustomerYearKpi({}, opts) {
    return fetchCustomerYearKpi(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取需要编辑的kpi
  fetchCustomerYearKpiEdit({}, opts) {
    return fetchCustomerYearKpiEdit(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取需要编辑的kpi
  fetchCustomerYearKpiEdit2({}, params) {
    return fetchCustomerYearKpiEdit2(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 新增客户kpi
  saveCustomerKpi({}, opts) {
    return saveCustomerKpi(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除客户kpi
  delCustomerKpi({}, opts) {
    return delCustomerKpi(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 系统操作日志log
  systemLog({}, opts) {
    return systemLog(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取旅行社、商户列表
  fetchTrip({}, opts) {
    return fetchTrip(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  MENU_DATA: (state, data) => {
    state.menuData = data;
  },
  AUDIT_LIST_DATA: (state, data) => {
    state.auditListData = data;
  },
  API_CUSTOM: (state, data) => {
    const res = new Map();
    const list = [...state.customApi, data];
    state.customApi = list.filter(it => !res.has(it.editId) && res.set(it.editId, 1));
  },
  CLEAR_API_CUSTOM: state => {
    state.customApi = [];
  },
  FETCH_API_CUSTOM: (state, data) => {
    state.customApiData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};