var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "notice-dialog",
    attrs: {
      width: "960px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleSave,
      cancel: _vm.handleCancel
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "标题"
    },
    model: {
      value: _vm.noticeOpts.title,
      callback: function ($$v) {
        _vm.$set(_vm.noticeOpts, "title", $$v);
      },
      expression: "noticeOpts.title"
    }
  })], 1), _c("div", {
    staticClass: "h10"
  }), _c("Tinymce", {
    ref: "editor",
    attrs: {
      height: 300
    },
    model: {
      value: _vm.noticeOpts.content,
      callback: function ($$v) {
        _vm.$set(_vm.noticeOpts, "content", $$v);
      },
      expression: "noticeOpts.content"
    }
  }), _c("div", {
    staticClass: "h10"
  }), _c("div", {
    staticClass: "input-field flex-center"
  }, [_c("radio-group", {
    attrs: {
      label: "可见范围",
      radios: _vm.radios
    },
    on: {
      change: _vm.changeRadio
    },
    model: {
      value: _vm.noticeOpts.authAreaType,
      callback: function ($$v) {
        _vm.$set(_vm.noticeOpts, "authAreaType", $$v);
      },
      expression: "noticeOpts.authAreaType"
    }
  }), _c("span", {
    staticClass: "pr5"
  }), _c("CheckboxBase", {
    attrs: {
      label: "弹窗展示"
    },
    model: {
      value: _vm.noticeOpts.isPopWindow,
      callback: function ($$v) {
        _vm.$set(_vm.noticeOpts, "isPopWindow", $$v);
      },
      expression: "noticeOpts.isPopWindow"
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isAll,
      expression: "!isAll"
    }],
    staticClass: "input-field flex-center"
  }, [_c("SelectMutiple", {
    attrs: {
      label: "",
      "init-val": _vm.noticeOpts.respNoticeAuths
    },
    on: {
      change: _vm.changeDepartment
    }
  })], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };