import { ResourceType } from '@/config/billCheck';
import { dictionaryMixin, searchMixin } from '@/mixin';
import { SELECTOR } from '@/config/select';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, InputScenic, InputHotel, InputRestaurant, InputFleet, InputTraffic, InputInsurance, SelectDictionary, InputShop, SelectUnit, SelectCustom } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputScenic,
    InputHotel,
    InputRestaurant,
    InputFleet,
    InputTraffic,
    InputInsurance,
    // InputCustom,
    SelectProduct,
    SelectStaff,
    SelectDictionary,
    InputShop,
    SelectUnit,
    SelectCustom
  },
  mixins: [searchMixin, dictionaryMixin],
  // props: {
  //   loading: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },

  data() {
    return {
      ResourceType,
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        startTripDateS: parseDate()
      },
      // extra: {
      //   department: {},
      // },
      // department: {},
      // visibleMore: true,
      hotelArea: '',
      resource: '',
      // 资源
      payType: [{
        value: '',
        label: '全部'
      }, ...SELECTOR.payType],
      // otherObj: {
      //   orderType: '',
      //   planType: '',
      //   auditProgress: '',
      //   checkAccountStatus: '',
      //   isAddScenic: '',
      // },
      standard: ''
    };
  },
  computed: {
    styleWidthObj() {
      return {
        width: '310px'
      };
    },
    isShowOpUser() {
      const arr = [ResourceType.shops, ResourceType.collect];
      return this.getResourceTypeList(arr);
    },
    isShowBus() {
      const arr = [ResourceType.shuttle, ResourceType.fleet];
      return this.getResourceTypeList(arr);
    },
    isShowCustom() {
      const arr = [ResourceType.reception, ResourceType.collect, ResourceType.insurance];
      return this.getResourceTypeList(arr);
    },
    isShowOther() {
      const arr = [ResourceType.otherIncome, ResourceType.otherPay];
      return this.getResourceTypeList(arr);
    },
    isShowPayment() {
      const arr = [ResourceType.reception, ResourceType.collect, ResourceType.shops];
      return this.getResourceTypeList(arr);
    }
  },
  mounted() {
    // 判断是否是
    const {
      isCustom,
      customerLabel: resourceLabel,
      customerId: resourceId
    } = this.$route.query;
    if (isCustom) {
      const {
        query
      } = this.$route;
      this.getResourceType('collect');
      this.searchParams = {
        ...query,
        resourceLabel,
        resourceId
      };
      delete this.searchParams.customerId;
      delete this.searchParams.customerLabel;
      delete this.searchParams.isCustom;
      this.handleSearch();
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    onSelectPayTypa(val) {
      let {
        value,
        label
      } = val;
      this.searchParams.payType = value ? value.toUpperCase() : '';
      this.searchParams.payTypeLabel = label;
    },
    selectUseBusType(val) {
      const {
        label
      } = val;
      this.searchParams.useType = label;
    },
    getResourceType(type) {
      const {
        fullPath
      } = this.$route;
      let [, name] = fullPath.split('/bill-check/');
      name = name.split('?')[0];
      return type == name;
    },
    getResourceTypeList(list) {
      return list.some(type => this.getResourceType(type));
    },
    // 选择客户
    selectCustom(val) {
      this.searchParams.customerLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    // 选择单位
    selectUnit(val) {
      this.searchParams.otherUnitId = val ? val.value : '';
      this.searchParams.otherUnitLabel = val ? val.label : '';
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // changeStarTripDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.startTripDateS = starDate
    //   this.searchParams.startTripDateE = endDate
    // },

    // changeEndTripDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.endTripDateS = starDate
    //   this.searchParams.endTripDateE = endDate
    // },

    // changeOrderDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.orderDateS = starDate
    //   this.searchParams.orderDateE = endDate
    // },

    // changeCheckDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.checkAccountTimeS = starDate
    //   this.searchParams.checkAccountTimeE = endDate
    // },

    selecthotelRegion({
      label
    }) {
      this.searchParams.area = label;
    },
    selectHotelStandard({
      label
    }) {
      this.searchParams.hotelStandard = label;
    },
    selectOtherType(type, val) {
      this.searchParams[type] = val ? val.value : '';
    },
    selectIsAddScenic(val) {
      this.searchParams.isAdd = !!val.value;
    },
    handleSelect(val) {
      this.searchParams.resourceId = val ? val.id : '';
      this.searchParams.resourceLabel = val ? val.label : '';
      delete this.searchParams.resourceName;
    },
    handleChange(val) {
      this.searchParams.resourceName = val;
      delete this.searchParams.resourceId;
    },
    changeTicketType(val) {
      this.searchParams.transportType = val ? val.label : '';
    },
    // 搜索
    handleSearch() {
      // const { isCustom } = this.$route.query
      let {
        path
      } = this.$route;
      // // 如果是客户汇总
      // if (isCustom) {
      //   path = path.split('?')[0]
      // }
      const search = this.searchParams;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search
      });
      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1,
        size: this.searchParams.pageSize
      });
      this.$parent.fetchData(search);
    },
    handleExcel() {
      this.$parent.exporttExcel(this.searchParams);
    }
  }
};