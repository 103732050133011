import { orderProgressStatus, orderProgressTagStatus } from '@/config/order';

// 审核流程状态
export default {
  name: 'StatusProgress',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    orderProgressStatus,
    orderProgressTagStatus
  })
};