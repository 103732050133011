var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-regiment-collect"
  }, [_c("SearchRegiment", {
    attrs: {
      "slot-title": "餐厅"
    },
    on: {
      search: _vm.handleSearch
    }
  }, [_c("SelectRestaurant", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.resourceId,
      callback: function ($$v) {
        _vm.resourceId = $$v;
      },
      expression: "resourceId"
    }
  })], 1), _c("TableList", {
    on: {
      page: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };