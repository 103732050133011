import "core-js/modules/es.array.push.js";
import { planEditScenic, planEditScenicAll, planEditHotel, planEditHotelAll, planEditRestaurant, planEditFleet, planEditInsurance, planEditTraffic, planEditPickup,
// planEditGuideBorrow,
planEditOtherIncome, planEditOtherPay, planEditShopping, planEditShoppingOld, planEditReception, planEditReceptionProxy, planEditReceptionRefund, auditSlot, planInfo } from './plan';
const redEnvelope = {
  attrs: {
    prop: '',
    label: '红包'
  },
  children: [{
    attrs: {
      prop: 'redEnvelope',
      label: '金额',
      'min-width': 60
    },
    slot: 'redEnvelope'
  }]
};
const shopList = JSON.parse(JSON.stringify(planEditShopping)).map(it => {
  if (it.attrs.prop == 'income') {
    it.children.push(redEnvelope);
  }
  return it;
});
const shopListOld = JSON.parse(JSON.stringify(planEditShoppingOld)).map(it => {
  if (it.attrs.prop == 'income') {
    it.children.push(redEnvelope);
  }
  return it;
});

/* 审核类型，
'FEE'=团款，
'COLLECT'=代收团款，
'SUBSIDY'=返款，
'orderOutGuidePay'=订单外转导游支付
'OUT_FEE'=外转团款审核，
'OUT_COLLECT'=外转代收审核，
'OUT_SUBSIDY'=外转返款审核，
'innerFee'=内转团款，
'innerCollect=内转代收，
'SHUTTLE=接送，
'SHOP'=购物，
'HOTEL'=酒店，
'BUS_COMPANY'=车队，
'RESTAURANT'=餐饮，
'visa'=签证，
'INSURANCE'=保险,
'SCENIC'=景点,
'ADD_SCENIC'=加点,
'TRANSPORT'=大交通,
'GUIDE'=导游,
'OTHER_INCOME'=其他收入,
'OTHER_PAY'=其他支出,
'planGuideHandback'=导游交回,
'guideBorrow'=导游借款,
'guideBorrowApply'=导游借款修改,

可用值:ADD_SCENIC,SHUTTLE,FEE,COLLECT,SUBSIDY,ORDER_OUT_GUIDE_PAY,OUT_FEE,OUT_COLLECT,OUT_SUBSIDY,INNER_FEE,INNER_COLLECT,SHOP,HOTEL,BUS_COMPANY,RESTAURANT,VISA,INSURANCE,SCENIC,TRANSPORT,GUIDE,OTHER_INCOME,OTHER_PAY,PLAN_GUIDE_HANDBACK,GUIDE_BORROW,GUIDE_BORROW_APPLY
*/

const str = 'ADD_SCENIC,SHUTTLE,FEE,COLLECT,SUBSIDY,ORDER_OUT_GUIDE_PAY,OUT_FEE,OUT_COLLECT,OUT_SUBSIDY,INNER_FEE,INNER_COLLECT,SHOP,HOTEL,BUS_COMPANY,RESTAURANT,VISA,INSURANCE,SCENIC,TRANSPORT,GUIDE,OTHER_INCOME,OTHER_PAY,PLAN_GUIDE_HANDBACK,GUIDE_BORROW,GUIDE_BORROW_APPLY,SUBSIDY_RATE,SUBSIDY_TWO';
const types = {};
str.split(',').map(key => {
  types[key.toLowerCase()] = key;
  types['customer'] = 'FEE';
});
export const TYPES = types;
const width30 = 30;
const width40 = 40;
const width60 = 60;
const width80 = 80;
// 'waitAudit'=待审核，'opAudited'=操作计调已审，'guideManagerAudited'=导管已审，'generalManagerAudited'=经理已审，'financialAudited'=财务已审，'bossAudited'=BOSS已审
export const WAITAUDIT = 'WAITAUDIT';
export const OPAUDITED = 'OPAUDITED';
export const FINANCIALAUDITED = 'FINANCIALAUDITED';
const GENERALAUDITED = 'GENERALAUDITED';
const GENERALMANAGERAUDITED = 'GENERALMANAGERAUDITED';
const SETTLEMENT = 'SETTLEMENT';
const CONFIRM = 'CONFIRM';
export const auditProgressStatus = {
  FINANCIALAUDITED: '财务',
  GENERALAUDITED: '管理员',
  GENERALMANAGERAUDITED: '经理',
  GUIDEMANAGERAUDITED: '导管',
  OPAUDITED: '计调',
  WAITAUDIT: '待审核',
  ADMIN: '管理员'
};
export const AuditSelect = [{
  value: WAITAUDIT,
  label: '待审核'
}, {
  value: OPAUDITED,
  label: '计调已审'
}, {
  value: FINANCIALAUDITED,
  label: '财务已审'
}];
export const AuditRole = {
  wait: WAITAUDIT,
  op: OPAUDITED,
  finance: FINANCIALAUDITED,
  admin: GENERALAUDITED || GENERALMANAGERAUDITED,
  settlement: SETTLEMENT,
  confirm: CONFIRM,
  1: OPAUDITED,
  2: FINANCIALAUDITED,
  3: GENERALAUDITED || GENERALMANAGERAUDITED
};
export const AuditRoleLevel = {
  WAITAUDIT: 1,
  OPAUDITED: 2,
  FINANCIALAUDITED: 3,
  GENERALMANAGERAUDITED: 4
};
export const AuditStatus = {
  op: WAITAUDIT,
  finance: OPAUDITED,
  financeCancel: FINANCIALAUDITED,
  check: FINANCIALAUDITED,
  admin: GENERALAUDITED || GENERALMANAGERAUDITED
};
export const tabMenu = [{
  id: 0,
  label: '团款',
  component: 'TeamFund',
  type: TYPES.fee,
  status: 'feeStatus'
}, {
  id: 1,
  label: '导游',
  component: 'Guide',
  type: TYPES.guide,
  status: 'guideOrderStatus'
}, {
  id: 3,
  label: '酒店',
  component: 'Hotel',
  type: TYPES.hotel,
  status: 'hotelOrderStatus'
}, {
  id: 4,
  label: '餐饮',
  component: 'Restaurant',
  type: TYPES.restaurant,
  status: 'restaurantOrderStatus'
}, {
  id: 5,
  label: '车队',
  component: 'Fleet',
  type: TYPES.bus_company,
  status: 'busCompanyOrderStatus'
}, {
  id: 2,
  label: '景区',
  component: 'Scenic',
  type: TYPES.scenic,
  status: 'scenicOrderStatus'
}, {
  id: 6,
  label: '保险',
  component: 'Insurance',
  type: TYPES.insurance,
  status: 'insuranceOrderStatus'
}, {
  id: 7,
  label: '交通',
  component: 'Traffic',
  type: TYPES.transport,
  status: 'transportOrderStatus'
}, {
  id: 8,
  label: '接送',
  component: 'Pickup',
  type: TYPES.shuttle,
  status: 'shuttleStatus'
}, {
  id: 9,
  label: '导游借款',
  component: 'GuideBorrow',
  type: TYPES.guide_borrow,
  status: 'guideBorrowApplyStatus'
}, {
  id: 10,
  label: '导游代收',
  component: 'GuideProxy',
  type: TYPES.collect,
  status: 'collectStatus'
}, {
  id: 15,
  label: '加点',
  component: 'ExtraScenic',
  type: TYPES.add_scenic,
  status: 'scenicOrderStatus'
}, {
  id: 11,
  label: '外转',
  component: 'Reception',
  type: TYPES.out_fee,
  status: 'agencyStatus'
}, {
  id: 12,
  label: '购物',
  component: 'Shopping',
  type: TYPES.shop,
  status: 'shopStatus'
}, {
  id: 13,
  label: '其他收入',
  component: 'OtherIncome',
  type: TYPES.other_income,
  status: 'otherIncomeStatus'
}, {
  id: 14,
  label: '其他支出',
  component: 'OtherPay',
  type: TYPES.other_pay,
  status: 'otherPayStatus'
}, {
  id: 15,
  label: '客户后返',
  component: 'SubsidyRate',
  type: TYPES.subsidy_rate,
  status: 'subsidyRateStatus'
}];
const customerInfo = {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 120
  },
  slot: 'customerName'
};
const planNumber = {
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  }
};
const date = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    width: 100
  },
  slot: 'date'
};
// 审核列表
export const auditOrder = [{
  ...planNumber,
  slot: 'planNumber'
}, customerInfo, {
  attrs: {
    prop: 'guides',
    label: '导',
    'min-width': 160
  },
  slot: 'guides'
}, date,
// { attrs: { prop: 'board', label: '接站牌' } },
{
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': 100
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'auditFeeStatus',
    label: '团款',
    'min-width': width40
  },
  slot: 'fee'
}, {
  attrs: {
    prop: 'auditHotelOrderStatus',
    label: '房',
    'min-width': width30
  },
  slot: 'hotel'
}, {
  attrs: {
    prop: 'auditRestaurantOrderStatus',
    label: '餐',
    'min-width': width30
  },
  slot: 'restaurant'
}, {
  attrs: {
    prop: 'auditBusCompanyOrderStatus',
    label: '车',
    'min-width': width30
  },
  slot: 'bus'
}, {
  attrs: {
    prop: 'auditScenicOrderStatus',
    label: '景',
    'min-width': width30
  },
  slot: 'scenic'
}, {
  attrs: {
    prop: 'auditInsuranceOrderStatus',
    label: '险',
    'min-width': width30
  },
  slot: 'insurance'
}, {
  attrs: {
    prop: 'auditTransportCompanyOrderStatus',
    label: '交通',
    'min-width': width30
  },
  slot: 'traffic'
}, {
  attrs: {
    prop: 'auditGuideCollectStatus',
    label: '导游代收',
    'min-width': width30
  },
  slot: 'proxy'
}, {
  attrs: {
    prop: 'auditGuideSubsidyStatus',
    label: '导补',
    'min-width': width30
  },
  slot: 'subsidy'
},
// {
//   attrs: {
//     prop: 'guideBorrowApplyStatus',
//     label: '导游借款',
//     'min-width': width30,
//   },
//   slot: 'borrow',
// },
{
  attrs: {
    prop: 'otherIncome',
    label: '其他收入',
    'min-width': width30
  },
  slot: 'otherIncome'
}, {
  attrs: {
    prop: 'otherPay',
    label: '其他支出',
    'min-width': width30
  },
  slot: 'otherPay'
}, {
  attrs: {
    prop: 'agencyStatus',
    label: '外转',
    'min-width': width30
  },
  slot: 'agency'
}, {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': 130
  },
  slot: 'auditStatus'
}, {
  attrs: {
    prop: 'teamStatus',
    label: '团状态'
  },
  slot: 'teamStatus'
}, {
  attrs: {
    prop: 'mailAddr',
    label: '邮件'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 60
  },
  slot: 'action',
  header: 'actionHead'
}];

// const w40 = 40
const w50 = 50;
const w60 = 60;
// 团利润
export const auditProfit = [{
  attrs: {
    prop: 'income',
    label: '总收入'
  },
  slot: 'income',
  header: 'incomeHeader',
  children: [{
    attrs: {
      prop: 'totalCustomerIncome',
      label: '团款'
    }
  }, {
    attrs: {
      prop: 'totalOutTransferSubsidyIncome',
      label: '外转返款',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalOtherIncome',
      label: '其他收入',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalShopIncome',
      label: '购物',
      'min-width': 80
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraIncome',
      label: '加点',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalGuideManageFeeIncome',
      label: '导游管理费',
      'min-width': w60
    }
  }
  // {
  // attrs: { prop: 'totalScenicInnerIncome', label: '景点', 'min-width': w60 },
  // children: [
  //   { attrs: { prop: 'totalScenicInnerIncome', label: '加点', 'min-width': w60 } },
  // ],
  // },
  ]
}, {
  attrs: {
    prop: 'pay',
    label: '总支出'
  },
  slot: 'pay',
  header: 'payHeader',
  children: [{
    attrs: {
      prop: 'totalCustomerSubsidyPay',
      label: '客户返款',
      'min-width': w50
    }
  }, {
    attrs: {
      prop: 'totalBusCompanyPay',
      label: '车队',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '酒店',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalGuideServiceFeePay',
      label: '导服费',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalRestaurantPay',
      label: '餐厅',
      'min-width': 80
    }
  }, {
    attrs: {
      prop: 'totalInsurancePay',
      label: '保险',
      'min-width': w50
    }
  }, {
    attrs: {
      prop: 'totalOutTransferPay',
      label: '外转',
      'min-width': w50
    }
  }, {
    attrs: {
      prop: 'totalShuttlePay',
      label: '接送',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalTransportCompanyPay',
      label: '大交通',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalOtherPay',
      label: '其他支出',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: 'totalShopRebatePay',
      label: '购物佣金',
      'min-width': w60
    }
  }, {
    attrs: {
      prop: '',
      label: '景区佣金'
    },
    children: [{
      attrs: {
        prop: 'totalScenicInnerRebatePay',
        label: '行程内',
        'min-width': w60
      }
    }, {
      attrs: {
        prop: 'totalScenicExtraRebatePay',
        label: '加点',
        'min-width': w60
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '景区'
    },
    children: [{
      attrs: {
        prop: 'totalScenicInnerQuotePay',
        label: '行程内',
        'min-width': w60
      }
    }, {
      attrs: {
        prop: 'totalScenicExtraQuotePay',
        label: '加点',
        'min-width': w60
      }
    }]
  }]
}, {
  attrs: {
    prop: 'profit',
    label: '合计和毛利'
  },
  children: [{
    attrs: {
      prop: 'totalIncome',
      label: '总收入'
    }
  },
  // { attrs: { prop: 'totalIncomed', label: '总已收' } },
  {
    attrs: {
      prop: 'totalQuotePay',
      label: '总成本'
    }
  },
  // { attrs: { prop: 'totalPayed', label: '总已付' } },
  {
    attrs: {
      prop: 'totalQuoteGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利',
      'min-width': w60
    }
  }]
}];
// 团利润改版
export const auditProfitNew = [...auditProfit.slice(0, -1)];
const auditItem = {
  attrs: {
    prop: 'audit',
    label: '审核',
    width: 120,
    fixed: 'right'
  },
  slot: 'audit',
  header: 'auditAction'
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action',
  header: 'deleteAction'
};
const lastItem = [auditSlot, actionItem, auditItem];
const auditCustom = [{
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'checkStatus',
    label: '对账状态',
    width: 100
  },
  slot: 'checkStatus'
}, customerInfo, {
  attrs: {
    prop: 'members',
    label: '游客联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'totalMoney',
    label: '总金额'
  },
  slot: 'totalMoney'
}, {
  attrs: {
    prop: 'type',
    label: '类型',
    width: 200
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}];

// 团款
export const auditCustomFee = [...auditCustom, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'unit',
    label: '单位'
  },
  slot: 'unit'
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'outOPUser',
    label: '客服计调'
  },
  slot: 'outOPUser'
}, ...lastItem];
const proxyUnit = {
  attrs: {
    prop: 'unit',
    label: '代收单位',
    width: 200
  },
  slot: 'proxyUnit'
};
// 团款代收
export const auditCustomProxy = [...auditCustom, {
  attrs: {
    prop: 'total',
    label: '金额'
  }
}, proxyUnit, {
  attrs: {
    prop: 'guideName',
    label: '代收导游'
  }
}, ...lastItem];

// 团款代收 编辑
export const auditCustomProxyEdit = [{
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  },
  slot: 'total'
}, proxyUnit, actionItem];

// 团款返款
export const auditCustomRefund = [{
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'deduct',
    label: '返款是否冲抵',
    width: 55
  },
  slot: 'deduct'
}, {
  attrs: {
    prop: 'checkStatus',
    label: '对账状态',
    width: 100
  },
  slot: 'checkStatus'
}, customerInfo, {
  attrs: {
    prop: 'members',
    label: '游客联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'totalMoney',
    label: '总金额'
  },
  slot: 'totalMoney'
}, {
  attrs: {
    prop: 'type',
    label: '类型',
    width: 200
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  }
}, ...lastItem];

// 导游
export const auditEditGuide = [{
  attrs: {
    prop: 'guideName',
    label: '导游',
    width: 140
  },
  slot: 'guideName'
}, {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    width: 100
  },
  slot: 'auditStatus'
}, {
  attrs: {
    prop: 'guidePhone',
    label: '手机',
    width: 140
  },
  slot: 'guidePhone'
},
// {
//   attrs: { prop: 'count', label: '带团人数', width: 80 },
//   slot: 'count',
// },
{
  attrs: {
    prop: 'startPlanDate',
    label: '上团日期',
    width: 160
  },
  slot: 'startPlanDate'
}, {
  attrs: {
    prop: 'endPlanDate',
    label: '下团日期',
    width: 160
  },
  slot: 'endPlanDate'
}, {
  attrs: {
    prop: 'guideServiceFee',
    label: '导服费',
    width: 100
  },
  slot: 'guideServiceFee'
}, {
  attrs: {
    prop: 'reimb',
    label: '是否报账导游'
  },
  slot: 'reimb'
}, {
  attrs: {
    prop: 'collectType',
    label: '结算方式'
  },
  slot: 'collectType'
}, {
  attrs: {
    prop: 'guideManager',
    label: '导管'
  },
  slot: 'guideManager'
}, {
  attrs: {
    prop: 'guideManageFee',
    label: '管理费'
  },
  slot: 'guideManageFee'
}, {
  attrs: {
    prop: 'recordingFee',
    label: '录单费'
  },
  slot: 'recordingFee'
}, {
  attrs: {
    prop: 'guideFund',
    label: '导游基金',
    width: 100
  },
  slot: 'guideFund'
}, {
  attrs: {
    prop: 'guideTax',
    label: '税',
    width: 100
  },
  slot: 'guideTax'
}, {
  attrs: {
    prop: 'depositFee',
    label: '押金',
    width: 100
  },
  slot: 'depositFee',
  header: 'depositHeader'
}, {
  attrs: {
    prop: 'voluntarilyFee',
    label: '乐捐(能量包)'
  },
  slot: 'voluntarilyFee'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, ...lastItem];

// 导游借款 审核
export const auditEditGuideBorrow = [{
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '会计科目'
  },
  slot: 'subject'
}, {
  attrs: {
    prop: '',
    label: '借款金额'
  },
  slot: 'borrowMoney'
}, {
  attrs: {
    prop: '',
    label: '借款方式'
  },
  slot: 'borrowType'
}, {
  attrs: {
    prop: '',
    label: '下账模式'
  },
  slot: 'cashMode'
}, {
  attrs: {
    prop: '',
    label: '银行卡'
  },
  slot: 'bankId'
}, {
  attrs: {
    prop: '',
    label: '下账时间'
  },
  slot: 'cashDate'
}, {
  attrs: {
    prop: '',
    label: '借款时间'
  },
  slot: 'borrowDate'
}, {
  attrs: {
    prop: '',
    label: '下账人'
  },
  slot: 'cashUser'
}, {
  attrs: {
    prop: '',
    label: '备注'
  },
  slot: 'remarks'
}, ...lastItem];

// 导游代收
export const auditEditGuideProxy = [planInfo, {
  attrs: {
    prop: 'custom',
    label: '客户(子部门)',
    width: 120
  },
  slot: 'custom'
}, {
  attrs: {
    prop: 'members',
    label: '联系人',
    width: 120
  },
  slot: 'contact'
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    width: 90
  },
  slot: 'count'
},
// {
//   attrs: { prop: 'totalPay', label: '总金额' },
//   slot: 'totalPay',
// },
{
  attrs: {
    prop: 'type',
    label: '类型',
    width: 160
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'money',
    label: '金额',
    width: 90
  },
  slot: 'money'
},
// ...lastItem,
{
  attrs: {
    prop: 'paymentCollectionTime',
    label: '回款时间',
    'min-width': 160
  },
  slot: 'paymentCollectionTime'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action',
  header: 'billCheck'
}, {
  attrs: {
    prop: 'actionItem',
    label: '操作',
    width: 100
  },
  slot: 'actionItem'
}];
const extraScenic = [{
  attrs: {
    prop: 'totalSum',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'adultCountReceive',
    label: '成人实收数'
  },
  slot: 'adultCountReceive'
}, {
  attrs: {
    prop: 'childCountReceive',
    label: '儿童实收数'
  },
  slot: 'childCountReceive'
}, {
  attrs: {
    prop: 'rowTotal',
    label: '成本'
  }
}, {
  attrs: {
    prop: 'totalAmountReceive',
    label: '实收金额'
  },
  slot: 'totalAmountReceive'
}, {
  attrs: {
    prop: 'driverRate',
    label: '司佣比例'
  },
  slot: 'driverRate'
}, {
  attrs: {
    prop: 'driverCommission',
    label: '司佣'
  },
  slot: 'driverCommission'
}, {
  attrs: {
    prop: 'accompanyRate',
    label: '全陪比例'
  },
  slot: 'accompanyRate'
}, {
  attrs: {
    prop: 'accompanyCommission',
    label: '陪佣'
  },
  slot: 'accompanyCommission'
}, {
  attrs: {
    prop: 'guideRate',
    label: '导佣比例'
  },
  slot: 'guideRate'
}, {
  attrs: {
    prop: 'guideCommission',
    label: '导佣'
  },
  slot: 'guideCommission'
}];

// 加点
export const auditExtraScenic = [...planEditScenic.slice(0, -4), ...extraScenic, {
  attrs: {
    prop: 'totalProfit',
    label: '总利润'
  },
  slot: 'totalProfit'
}, {
  attrs: {
    prop: 'tripProfit',
    label: '社利'
  },
  slot: 'tripProfit'
}, {
  attrs: {
    prop: 'payType',
    label: '付款方式',
    'min-width': 120
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, ...lastItem];
export const auditExtraScenicAll = [...planEditScenicAll.slice(0, -4), ...extraScenic, {
  attrs: {
    prop: 'totalProfit',
    label: '总利润'
  },
  slot: 'totalProfit'
}, {
  attrs: {
    prop: 'tripProfit',
    label: '社利'
  },
  slot: 'tripProfit'
}, {
  attrs: {
    prop: 'payType',
    label: '付款方式',
    'min-width': 120
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, ...lastItem];

// 景区
export const auditEditScenic = [...planEditScenic.slice(0, -1), ...lastItem];
export const auditEditScenicAll = [...planEditScenicAll.slice(0, -1), ...lastItem];

// 酒店
export const auditEditHotel = [...planEditHotel.slice(0, -1), ...lastItem];
export const auditEditHotelAll = [...planEditHotelAll.slice(0, -1), ...lastItem];

// 餐厅
export const auditEditRestaurant = [...planEditRestaurant.slice(0, -1), ...lastItem];

// 车队
export const auditEditFleet = [...planEditFleet.slice(0, -1), ...lastItem];

// 保险
export const auditEditInsurance = [...planEditInsurance.slice(0, -1), ...lastItem];

// 交通
export const auditEditTraffic = [...planEditTraffic.slice(0, -1), ...lastItem];

// 接送
export const auditEditPickup = [...planEditPickup.slice(0, -1), ...lastItem];

// 其他收入
export const auditEditOtherIncome = [...planEditOtherIncome.slice(0, -1), ...lastItem];

// 其他支出
export const auditEditOtherPay = [...planEditOtherPay.slice(0, -1), ...lastItem];

// 旧版购物店
export const auditEditShoppingOld = [...shopListOld.slice(0, 9),
//.slice(0, -2),
{
  attrs: {
    prop: 'cashFlow',
    label: '流水',
    'min-width': width80
  },
  slot: 'cashFlow'
}, {
  attrs: {
    prop: 'perCapita',
    label: '人均',
    'min-width': width60
  },
  isHide: false
}, ...shopListOld.slice(9, -2),
//.slice(0, -2),

{
  attrs: {
    prop: 'remark',
    label: '备注',
    'min-width': width60
  },
  slot: 'remark'
}, ...lastItem];

// 购物店 旧版（有二返）
export const auditEditShoppingOldAll = [...shopListOld.slice(0, 9), {
  attrs: {
    prop: 'cashFlow',
    label: '流水',
    'min-width': width80
  },
  slot: 'cashFlow'
}, {
  attrs: {
    prop: 'perCapita',
    label: '人均',
    'min-width': width60
  },
  isHide: false
}, ...shopListOld.slice(9, -2), {
  attrs: {
    prop: 'subsidyRate',
    label: '二返比例',
    'min-width': width60,
    merge: true
  },
  slot: 'subsidyRate'
}, {
  attrs: {
    prop: 'subsidy|merge',
    label: '二返金额',
    'min-width': width60
  },
  slot: 'subsidy'
}, {
  attrs: {
    prop: 'remark',
    label: '备注',
    'min-width': width80
  },
  slot: 'remark'
}, ...lastItem];

// 购物店(新版)
export const auditEditShopping = [...shopList.slice(0, 8), {
  attrs: {
    prop: 'cashFlow',
    label: '流水',
    'min-width': width80
  },
  slot: 'cashFlow'
}, {
  attrs: {
    prop: 'perCapita',
    label: '人均',
    'min-width': width60
  },
  isHide: false
}, ...shopList.slice(8, -2), {
  attrs: {
    prop: 'remark',
    label: '备注',
    'min-width': width60
  },
  slot: 'remark'
}, ...lastItem];
// 购物店(新版)有二返
export const auditEditShoppingAll = [...shopList.slice(0, 8), {
  attrs: {
    prop: 'cashFlow',
    label: '流水',
    'min-width': width80
  },
  slot: 'cashFlow'
}, {
  attrs: {
    prop: 'perCapita',
    label: '人均',
    'min-width': width60
  },
  isHide: false
}, ...shopList.slice(8, -2), {
  attrs: {
    prop: 'subsidyRate',
    label: '二返比例',
    'min-width': width40
  },
  slot: 'subsidyRate'
}, {
  attrs: {
    prop: 'subsidy',
    label: '二返金额',
    'min-width': width40
  },
  slot: 'subsidy'
}, {
  attrs: {
    prop: 'remark',
    label: '备注',
    'min-width': width60
  },
  slot: 'remark'
}, ...lastItem];

// 外转 费用
export const auditReception = [
// ...planEditReception.slice(0, 1),
// ...planEditReception.slice(1, -1),
...planEditReception.slice(0, -1), ...lastItem];

// 外转 代收
export const auditReceptionProxy = [...planEditReceptionProxy, auditItem];

// 外转 返款
export const auditReceptionRefund = [...planEditReceptionRefund, auditItem];

// 代收审核
export const auditProxy = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    width: 160
  },
  slot: 'orderInfo'
}, date, customerInfo, {
  attrs: {
    prop: 'members',
    label: '客人联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  },
  slot: 'guideName'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
},
// {
//   attrs: { prop: 'total', label: '应收' },
// },
{
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'collection',
      label: '社收'
    },
    slot: 'collection'
  }, {
    attrs: {
      prop: 'guideCollection',
      label: '导游代收'
    },
    slot: 'guideCollection'
  }]
},
// {
//   attrs: { prop: 'arrearage', label: '欠款' },
//   slot: 'arrearage',
// },
{
  attrs: {
    prop: 'paymentCollectionTime',
    label: '回款时间',
    width: 180
  },
  slot: 'paymentCollectionTime'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action',
  header: 'billCheck'
}];
// 代收审核
export const hotelTotel = [{
  attrs: {
    prop: 'hotelName',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'totalQuotePay',
    label: '总应付'
  }
}, {
  attrs: {
    prop: 'guideTotal',
    label: '总现付'
  }
}, {
  attrs: {
    prop: 'signTotal',
    label: '总签单'
  }
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: '',
    label: '结算详情'
  },
  children: [{
    attrs: {
      prop: 'payType',
      label: '结算方式'
    }
  }, {
    attrs: {
      prop: 'roomType',
      label: '项目'
    }
  }, {
    attrs: {
      prop: 'count',
      label: '数量'
    }
  }, {
    attrs: {
      prop: 'freeCount',
      label: '免去数量'
    }
  }, {
    attrs: {
      prop: 'quotePrice',
      label: '单价'
    }
  }, {
    attrs: {
      prop: 'amount',
      label: '结算金额'
    }
  }]
}];
// 代收审核
export const auditFile = [planNumber, date, {
  attrs: {
    prop: 'productName',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '总收入'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '总支出'
  }
}, {
  attrs: {
    prop: 'planStatus',
    label: '状态'
  },
  slot: 'planStatus'
}, {
  attrs: {
    prop: 'planFileDate',
    label: '归档时间'
  },
  slot: 'planFileDate'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action',
  header: 'allCheck'
}];