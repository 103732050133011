import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 下账管理
import LayoutPrint from '@/layout/print';
const printRouter = {
  path: '/print',
  component: LayoutPrint,
  redirect: '/print-bill',
  name: 'Print',
  hidden: true,
  meta: {
    title: 'print'
  },
  children: [{
    path: '/print-bill',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print'))),
    name: 'printReceipt',
    // 组团社回执单
    meta: {
      title: 'printReceipt',
      icon: 'icon-caiwuguanli',
      auth: true
    }
  }, {
    path: '/print-download',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/download'))),
    name: 'printDownload',
    // 下载页面
    meta: {
      title: 'printDownload',
      icon: 'icon-caiwuguanli',
      auth: true
    }
  }, {
    path: '/print-driver',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadDriver'))),
    name: 'printDriver',
    // 司机通知单
    meta: {
      title: 'printDriver',
      icon: 'icon-caiwuguanli',
      auth: true
    }
  }, {
    path: '/print-ticket',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadTicket'))),
    name: 'printTicket',
    // 签单打印
    meta: {
      title: 'printTicket',
      icon: 'icon-baojiadanguanli',
      auth: true
    }
  }, {
    path: '/print-approval-bill',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadBill'))),
    name: 'printBill',
    // 付款单
    meta: {
      title: 'printBill',
      icon: 'icon-baojiadanguanli',
      auth: true
    }
  }, {
    path: '/print-supervise-travel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadSuperviseTravel'))),
    name: 'printSuperviseTravel',
    // 旅监行程单打印
    meta: {
      title: 'printSuperviseTravel',
      icon: 'icon-baojiadanguanli',
      auth: true
    }
  }, {
    path: '/print-borrow',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadBorrow'))),
    name: 'printGuideBorrow',
    // 导游借款申请
    meta: {
      title: 'printGuideBorrow',
      icon: 'icon-baojiadanguanli',
      auth: true
    }
  }, {
    path: '/print-resource-plan',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/print/downloadResourcePlan'))),
    name: 'printResourcePlan',
    // 导游借款申请
    meta: {
      title: 'printResourcePlan',
      icon: 'icon-baojiadanguanli',
      auth: true
    }
  }
  // {
  //   path: '/analysis/member-map',
  //   component: () => import(/* webpackChunkName: "print" */ '@/views/analysis/memberMap'),
  //   name: 'analyseMemberMap', // 游客分布地图
  //   meta: { title: 'analyseMemberMap', auth: true },
  //   hidden: true,
  // },
  ]
};
export default printRouter;