var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.loading,
      expression: "loading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    staticClass: "print-download"
  }, [_c(_vm.getComps, {
    tag: "component",
    attrs: {
      name: _vm.name
    },
    on: {
      onMounted: _vm.handleOnMounted
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };