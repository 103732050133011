import "core-js/modules/es.array.push.js";
import FormItem from './components/FormItem';
import BoatLogin from './components/BoatLogin';
import { tagViewMixin } from '@/mixin';
import { touristAdd as columns } from '@/config/boat';
import { isUploadBoat } from '../helper';
const CERT_TYPE = '1';
const CERT_LABEL = '身份证';
const defaultTourist = () => ({
  touristName: '',
  documentNo: '',
  documentType: ''
});
export default {
  components: {
    FormItem,
    BoatLogin
  },
  mixins: [tagViewMixin],
  data() {
    return {
      columns,
      tableData: [],
      guideInfo: null,
      planId: '',
      contractNo: '',
      backPath: '/plan/onekeyup'
    };
  },
  computed: {
    guideData() {
      return this.$store.getters['plan/boat/guideData'];
    },
    getGuide() {
      return this.guideInfo;
    },
    isUpdate() {
      return isUploadBoat(this.contractNo);
    }
  },
  created() {
    const {
      id,
      guide,
      contractNo
    } = this.$route.query;
    this.contractNo = contractNo;
    if (this.isUpdate && guide) {
      // 根据导游名字查询订单
      this.fetchGuideList({
        guide
      });
    } else {
      this.fillTripData(id);
    }
  },
  methods: {
    fillTripData(id) {
      // 地接导游信息
      this.fetchGuide(id);
      // 地接订单游客列表
      this.fetchTourist(id);
    },
    // 数字漓江导游列表，传入参数查询订单
    fetchGuideList(params) {
      this.$store.dispatch('plan/boat/guideList', params).then(list => {
        if (!list || !list.length) {
          return;
        } else {
          const data = list[0];
          this.fetchDetailTourist(data.id);
          this.guideInfo = data;
        }
      });
    },
    // 获取游客（兴坪船数据的游客，回显）
    fetchDetailTourist(id) {
      this.$store.dispatch('plan/boat/detailTourist', id).then(data => {
        if (data && data.length) {
          this.tableData = data.map(it => {
            if (it.documentType === CERT_TYPE) {
              it.cardType = CERT_LABEL;
            }
            return it;
          });
        }
      });
    },
    // 获取导游(地接)
    fetchGuide(id) {
      this.$store.dispatch('plan/guide/fetch', {
        planId: id
      }).then(list => {
        if (!list || !list.length) return;
        const data = list.find(it => it.reimb) || list[0];
        const {
          guideName: guide,
          guidePhone: phone
        } = data;
        this.guideInfo = {
          guide,
          phone
        };
      });
    },
    // 获取游客（地接系统的游客，导入名单用）
    fetchTourist(id) {
      this.$store.dispatch('order/print/fetchTourist', {
        planId: id
      }).then(list => {
        if (!list || !list.length) return;
        this.tableData = this.formatTourist(list);
      });
    },
    formatTourist(list) {
      if (!list) return;
      return list.map(it => {
        const {
          name: touristName,
          cardNumber: documentNo
        } = it;
        return {
          cardType: CERT_LABEL,
          documentType: CERT_TYPE,
          touristName,
          documentNo
        };
      });
    },
    selectCardType(row, val) {
      const {
        value
      } = val;
      row.documentType = value;
    },
    // 添加游客
    add() {
      this.tableData.push(defaultTourist());
    },
    // 删除游客
    del(row) {
      this.tableData = this.tableData.filter(it => it != row);
    },
    // 关闭页面
    close() {
      this.closeSelectedTag();
      this.goBack();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    },
    // 删除该条船票信息
    delBoat() {
      const {
        id
      } = this.guideInfo;
      this.$bus.open(() => {
        this.$store.dispatch('plan/boat/delGuide', id).then(res => {
          if (res && res.success) {
            this.$bus.tip({
              message: res.message
            });
            this.setStatus('');
            this.close();
          }
        });
      });
    },
    confirm() {
      const {
        form
      } = this.$refs.formItemRef;
      const opts = {
        ...form,
        appointmentTime: Date.parse(form.appointmentTime),
        touristList: this.tableData
      };
      this.$refs.formItemRef.handleValid().then(valid => {
        if (!valid) {
          this.$bus.tip({
            type: 'warning',
            message: '内容填写不全！'
          });
          return;
        }
        const loading = this.$bus.loading();
        const path = this.isUpdate ? 'plan/boat/editGuide' : 'plan/boat/addGuide';
        this.$store.dispatch(path, opts).then(res => {
          this.setStatus(opts.guide);
          this.$bus.tip({
            message: res.message
          });
          this.close();
          loading.close();
        }).catch(err => {
          loading.close();
          this.$bus.tip({
            type: 'error',
            message: err.message
          });
        });
      });
    },
    setStatus(guideName) {
      const {
        id: planId
      } = this.$route.query;
      this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        contractNo: guideName ? `${guideName}|${planId}` : ''
      });
    }
  }
};