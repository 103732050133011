export default {
  components: {},
  data() {
    return {
      form: {},
      visible: false,
      title: '',
      text: ''
    };
  },
  methods: {
    show(data) {
      this.fetchOfferId(data);
    },
    hide() {
      this.visible = false;
    },
    fetchOfferId(queryParam) {
      this.$store.dispatch('offer/index/fetchOfferId', queryParam).then(data => {
        this.visible = true;
        this.form = data;
        // 景区
        this.form.scenicDetail = data.scenicDetails.map(it => {
          return ` ${it.name} ${it.price} `;
        });
        this.form.scenicDetail = ` 景：${this.form.scenicDetail.join('+')}=${data.scenicMoney}/人\n`;
        // 房
        this.form.hotelDetail = data.hotelDetails.map(it => {
          return ` ${it.area} ${it.price}*${it.count}/${data.adultCount} `;
        });
        this.form.hotelDetail = ` 房: ${this.form.hotelDetail.join('+')}=${data.hotelMoney}/人\n`;

        // 导游
        this.form.guideDetail = data.guideDetails.map(it => {
          return ` ${it.price} * ${it.count} / ${data.adultCount}`;
        });
        this.form.guideDetail = `导游：${this.form.guideDetail.join('+')}=${data.guideMoney}/人\n`;

        // 餐厅
        this.form.restaurantDetail = data.restaurantDetails.map(it => {
          return ` ${it.price} * ${it.count} / ${data.adultCount}`;
        });
        this.form.restaurantDetail = `餐：${this.form.restaurantDetail.join('+')}=${data.restaurantMoney}/人\n`;

        // 车
        this.form.busDetail = data.busDetails.map(it => {
          return ` ${it.price} * ${it.count} / ${data.adultCount}`;
        });
        this.form.busDetail = `车：${this.form.busDetail.join('+')}=${data.busMoney}/人\n`;
        this.text = this.form.scenicDetail + this.form.hotelDetail + this.form.restaurantDetail + this.form.busDetail + this.form.guideDetail + `操作费：${data.opPrice}/人}\n` + `地接：${data.totalMoney}\n\n\n` + `景：${data.scenicRemarks}\n` + `导游：${data.guideRemarks}\n` + `餐：${data.restaurantRemarks}\n` + `房：${data.hotelRemarks}\n` + `车：${data.busRemarks}\n` + `购物：${data.shopRemarks}\n\n\n` + `参考酒店：\n ${data.hotelRefer}`;
      });
    }
  }
};