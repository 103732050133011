import DialogPayment from '@/components/common/Dialog/DialogPayment';
import { parseDate, getUserData } from '@/utils';
import { RECHANGE_TYPE, RENEW_TYPE, VAS_TYPE } from '@/config/vas';
export default {
  components: {
    DialogPayment
  },
  data() {
    return {
      form: {
        name: RENEW_TYPE.FUTURE_ADMIN,
        rechargeType: RECHANGE_TYPE.YEAR,
        code: VAS_TYPE.TRIPSYSSEV,
        availableDate: '',
        count: 1
      },
      visible: false,
      rules: {},
      yearCount: 5,
      yearList: []
    };
  },
  created() {
    this.yearList = this.createYearList();
  },
  methods: {
    show() {
      const {
        travelAgencyId: id
      } = getUserData();
      this.fetchCompanyInfo(id);
    },
    fetchCompanyInfo(id) {
      this.$store.dispatch('system/companyInfo/getInfo', id).then(data => {
        if (!data) return;
        this.form.tripSysSEVPrice = data.tripSysSEVPrice;
        this.form.startDate = data.availableDate;
        this.selectDate({
          value: this.form.count
        });
        this.visible = true;
        this.$refs.dialogRef.show(this.form);
      });
    },
    createYearList() {
      const arr = Array.from({
        length: this.yearCount
      }).map((_, i) => {
        return {
          label: `${i + 1}年`,
          value: i + 1
        };
      });
      return [{
        label: '请选择',
        value: ''
      }, ...arr];
    },
    getExpiresDate(value) {
      const availableDate = this.form.startDate;
      if (availableDate) {
        const curDate = new Date(availableDate);
        const year = curDate.getFullYear();
        const month = curDate.getMonth();
        const day = curDate.getDate();
        const addYear = year + Number(value);
        return parseDate(new Date(addYear, month, day));
      }
      return '永久';
    },
    selectDate({
      value
    }) {
      if (!value) return;
      this.form.count = value;
      this.form.money = value * this.form.tripSysSEVPrice;
      this.form.endDate = this.getExpiresDate(value);

      // 修改参数传给下载协议用
      this.$refs.dialogRef.reloadForm(this.form);
    },
    submit() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        this.$refs.dialogRef.handlePay(this.form);
      });
    }
  }
};