import { permissionMixin, rowspanMixin, tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
import DialogCredit from './DialogCredit.vue';
import DialogCustom2 from './DialogCustom2.vue';
export default {
  components: {
    SwitchStatus,
    DialogCredit,
    DialogCustom2
  },
  mixins: [tableMixin, rowspanMixin, permissionMixin],
  data() {
    return {
      headMergeNum: 6,
      tailMergeNum: 9,
      hasMergeTail: true
    };
  },
  methods: {
    initTable() {
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    handleContact(type, scope) {
      if (type == 'add') {
        this.$parent.showContact(scope.row.rowId);
      } else if (type == 'delete') {
        this.$parent.deleteContact(scope);
      } else {
        this.$parent.editContact(scope);
      }
    },
    changeStatus(row) {
      const opts = {
        id: row.rowId,
        isEnable: row.isEnable
      };
      this.$parent.createOrUpdate(opts);
      setTimeout(() => {
        this.$parent.fetchList();
      }, 500);
    },
    handleBalance({
      row
    }) {
      this.$refs.dialogCreditRef.show(row);
    },
    handleEdit2(row) {
      this.$refs.dialogCustomRef.show({
        ...row
      });
    }
  }
};