var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.summaryData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "codeName",
      fn: function ({}) {
        return [_vm._v(" 应收总计 ")];
      }
    }, {
      key: "totalIncome",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          staticStyle: {
            color: "#409eff"
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalIncome))])];
      }
    }])
  }), _vm._l(_vm.tableData, function (tabel, index) {
    return [_c("TableBase", {
      key: index,
      attrs: {
        data: tabel.areaItem,
        "column-data": _vm.columnData,
        dynamic: false,
        "show-header": false,
        border: "",
        "summary-method": _vm.getSummaries,
        "show-summary": ""
      },
      scopedSlots: _vm._u([{
        key: "itemIndex",
        fn: function ({
          scope
        }) {
          return [_vm._v(" " + _vm._s(scope.row.rowIndex) + " ")];
        }
      }, {
        key: "index",
        fn: function ({
          scope
        }) {
          return [_vm._v(" " + _vm._s(scope.row.index) + " ")];
        }
      }, {
        key: "areaServiceName",
        fn: function ({
          scope
        }) {
          return [_vm._v(" " + _vm._s(scope.row.areaServiceName) + " ")];
        }
      }, {
        key: "codeName",
        fn: function ({
          scope
        }) {
          return [_vm._v(" " + _vm._s(scope.row.name) + " " + _vm._s(scope.row.businessPrincipal ? `(${scope.row.businessPrincipal})` : "") + " ")];
        }
      }, {
        key: "totalIncome",
        fn: function ({
          scope
        }) {
          return [_c("span", {
            staticStyle: {
              color: "#409eff"
            }
          }, [_vm._v(" " + _vm._s(scope.row.totalIncome))])];
        }
      }], null, true)
    })];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };