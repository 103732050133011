import request from '@/utils/request';
export * from './customGoal';
export * from './case';

// 一键设置所有旅行社菜单
export function setAllTripMenu(data) {
  return request({
    url: '/system/trip/oneKeySet',
    method: 'post',
    data
  });
}

// 一键设置所有菜单
export function setAllMenu(data) {
  return request({
    url: '/system/role/oneKeySet',
    method: 'post',
    data
  });
}

// 权限菜单
export function fetchPermission(params) {
  return request({
    url: '/system/menu/list',
    method: 'post',
    params
  });
}

// 旅行社拥有的菜单(已有)
// export function fetchTripMenu(params) {
//   return request({
//     url: '/system/trip/listMenus',
//     method: 'post',
//     params,
//   })
// }

// 获取旅行社公司的菜单列表2(所有包含未授权)
export function fetchTripMenu(params) {
  return request({
    url: '/system/trip/tripMenus',
    method: 'post',
    params
  });
}

// 添加旅行社拥有的菜单
export function addTripMenu(data) {
  return request({
    url: '/system/trip/addMenu',
    method: 'post',
    data
  });
}

// 删除旅行社拥有的菜单
export function delTripMenu(data) {
  return request({
    url: '/system/trip/delMenu',
    method: 'post',
    data
  });
}

// 菜单权限列表
export function fetchMenuPermission(params) {
  return request({
    url: '/system/menu/listMenuAndOP',
    method: 'post',
    params
  });
}

// 菜单列表
export function fetchMenu(params) {
  return request({
    url: '/system/menu/list',
    method: 'post',
    params
  });
}

// 创建菜单
export function createMenu(data) {
  return request({
    url: '/system/menu/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除菜单
export function deleteMenu(data) {
  return request({
    url: '/system/menu/del',
    method: 'post',
    data
  });
}

// 批量删除 菜单
export function deleteBatchMenu(data) {
  return request({
    url: '/system/menu/delList',
    method: 'post',
    data
  });
}

// 排序菜单
export function sortMenu(params) {
  return request({
    url: '/system/menu/updateMenuSort',
    method: 'post',
    params
  });
}

// 删除菜单操作功能
export function deleteMenuOperate(data) {
  return request({
    url: '/system/menu/delOpByMenuId',
    method: 'post',
    data
  });
}

// 菜单添加操作权限内容
export function addOperateToMenu(data) {
  return request({
    url: '/system/menu/saveOp',
    method: 'post',
    data
  });
}

// 删除菜单权限内容
export function delMenuOperateFromMenu(id) {
  return request({
    url: '/system/menu/delOpById',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取所有审核类型
export function fetchAuditList() {
  return request({
    url: '/system/audit/listTypes',
    method: 'get'
  });
}

// 新增审核流程
export function saveOrAuditList(data) {
  return request({
    url: '/system/audit/saveOrUpdateList',
    method: 'post',
    data
  });
}

// 删除审核流程
export function detOrAuditList(data) {
  return request({
    url: '/system/audit/delProgressById',
    method: 'post',
    data
  });
}

// 删除审核项
export function delAuditById(data) {
  return request({
    url: '/system/audit/delAuditById',
    method: 'post',
    data
  });
}

// 获取客户kpi
export function fetchCustomerKpi(params) {
  return request({
    url: '/order/customer-kpi/page',
    method: 'get',
    params
  });
}

// 获取某年某个客户kpi
export function fetchCustomerYearKpi(params) {
  return request({
    url: '/order/customer-kpi/page-c-year',
    method: 'get',
    params
  });
}
// 获取需要编辑的kpi
export function fetchCustomerYearKpiEdit(params) {
  return request({
    url: '/order/customer-kpi/get-kpi-edit',
    method: 'get',
    params
  });
}
// 获取需要编辑的kpi
export function fetchCustomerYearKpiEdit2(params) {
  return request({
    url: '/order/customer-kpi/get-kpi-edit-2',
    method: 'get',
    params
  });
}
// 更新编辑客户kpi
export function saveCustomerKpi(data) {
  return request({
    url: '/order/customer-kpi/add-or-update',
    method: 'post',
    data
  });
}
// 删除客户kpi
export function delCustomerKpi(params) {
  return request({
    url: '/order/customer-kpi/del',
    method: 'post',
    params
  });
}

// 查询订单系统操作日志
export function systemLog(params) {
  return request({
    url: '/log/orderList3',
    method: 'get',
    params
  });
}

// 获取旅行社、商户列表
export function fetchTrip(params) {
  return request({
    url: '/system/trip/page',
    method: 'get',
    params
  });
}