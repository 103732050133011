import { CustomerType } from '@/config';
export const defaultState = () => ({
  name: '',
  creditMoney: 0,
  customerType: '',
  addr: '',
  isEnable: true,
  account: '',
  bank: '',
  bankCard: '',
  bankCardType: 0,
  region: [],
  province: '',
  provinceId: 0,
  city: '',
  cityId: 0,
  district: '',
  districtId: 0,
  contacts: [],
  teamSubsidyPrice: 0,
  individuaSubsidyPrice: 0,
  outOPUserId: '',
  saleUserId: '',
  type: CustomerType.COMMON
});
export const defaultContacts = () => ({
  contact: '',
  faxNumber: '',
  mobilePhone: '',
  telphone: '',
  type: '业务联系人',
  IDCard: ''
});