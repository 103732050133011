var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      "row-key": "id",
      border: "",
      "default-expand-all": "",
      "tree-props": {
        children: "children",
        hasChildren: "hasChildren"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "科目名称",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "pay",
      label: "收入",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "pay",
      label: "支出",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "balance",
      label: "结余",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建日期",
      align: "center"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };