import DialogPlan from './components/DialogPlan';
import TableTicketAudit from './components/TableTicketAudit';
import { DetailPlanTrip } from '@/components/common';
import { ticketAuditList, TicketBtnStatus, TicketCompleteStatus, TicketStatusEnum } from '@/config/ticket';
import { detailMixin } from './mixin';
export default {
  components: {
    TableTicketAudit,
    DialogPlan,
    DetailPlanTrip
  },
  mixins: [detailMixin],
  data() {
    return {
      TicketStatusEnum,
      backPath: '/ticket/ticket-input',
      printLink: '/print-ticket',
      ticketAuditList,
      planInfo: '',
      id: '',
      guideName: '',
      planNumber: ''
    };
  },
  computed: {
    // ticketPlanData() {
    //   return this.$store.getters['ticket/index/ticketPlanData']
    // },
    isShowBtn() {
      return status => {
        return this.currentPlan && this.currentPlan.signSheetStatus == status;
      };
    },
    getTableData() {
      return this.tableData;
    },
    getBehindhandSignText() {
      const {
        behindhandSign
      } = this.$route.query;
      return !behindhandSign ? '设为欠签单' : '设为已交齐签单';
    }
  },
  watch: {
    currentPlan: {
      handler: 'getInfo',
      immediate: true
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.id = id;
    if (this.currentPlan && !this.currentPlan.signSheetStatus) {
      this.$router.replace(this.backPath);
    }
  },
  methods: {
    showDialog(id) {
      this.$refs.dialogPlan.show(id);
    },
    getInfo(data) {
      if (data) {
        let guides = data.guides || [];
        this.guideName = guides.length ? guides.filter(it => it.reimb)[0].name : '';
        this.planNumber = data.planNumber;
      }
      // this.planInfo = str
    },
    handlePrint() {
      const routerObj = this.$router.resolve({
        path: '/print-ticket',
        query: {
          id: this.id,
          planNumber: this.planNumber,
          guideName: this.guideName
        }
      });
      window.open(routerObj.href, '_blank');
    },
    toggleBehindhandSign() {
      const {
        id,
        behindhandSign
      } = this.$route.query;
      this.$bus.open(() => {
        const behindhandSignStatus = !behindhandSign;
        this.$store.dispatch('ticket/index/setBehindhandSign', {
          behindhandSignStatus,
          id
        }).then(() => {
          this.$bus.emit('ticket:reload', id);
          this.$router.replace({
            path: '/ticket/ticket-input/detail',
            query: {
              id,
              behindhandSign: behindhandSignStatus
            }
          });
        });
      }, '确定要设置吗');
    },
    // 取消审核
    cancelAudit() {
      this.handleAudit();
    },
    // 安排派单
    handleDispatch() {
      // 状态在 confit/ticket 内定义
      const isDispatch = TicketBtnStatus[this.currentPlan.signSheetStatus];
      const id = this.id;
      const opts = {
        id,
        isDispatch
      };
      const loading = this.$bus.loading();
      this.$store.dispatch('ticket/index/toggleDispatch', opts).then(() => {
        this.$bus.emit('ticket:reload', this.id);
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 审核/核销
    handleAudit() {
      const isAudit = TicketCompleteStatus[this.currentPlan.signSheetStatus];
      const id = this.id;
      const opts = {
        id,
        isAudit: !isAudit
      };
      const loading = this.$bus.loading();
      this.$store.dispatch('ticket/index/toggleAudit', opts).then(() => {
        this.$bus.emit('ticket:reload', this.id);
        loading.close();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};