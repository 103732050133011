import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, DelCheck, DelCheckAll, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { mapGetters } from 'vuex';
import { StatusAudit, SelectPayType, SelectPlanNumber, SelectUnit, SelectDictionary } from '@/components/common';
import { isNumber } from '@/utils';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import { PAY_TYPE } from '@/config/payType';
const defaultState = () => ({
  id: 0,
  // 这个id 作用是合并数据
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  cashType: 'guide',
  count: 1,
  customerOrderId: 0,
  freePrice: 0,
  freeTotal: 0,
  orderNumber: '',
  otherFee: 0,
  planOtherPayId: 0,
  price: 0,
  title: '',
  remark: '',
  totalPay: 0,
  totalSettlement: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait
});
export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    TopBar,
    DelCheck,
    DelCheckAll,
    StatusAudit,
    PaymentTotal,
    AuditRoleAll,
    SelectPayType,
    SelectPlanNumber,
    SelectDictionary,
    AuditStatus,
    CheckStatus,
    SelectUnit
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      PAY_TYPE,
      tableData: [],
      hasDataStatusKey: 'otherPayStatus',
      deletePath: 'plan/otherPay/delete',
      updatePath: 'plan/otherPay/update',
      fetchPath: 'plan/otherPay/fetch',
      rowId: 1000000000000,
      auditAll: false,
      mergeTable: false
    };
  },
  computed: {
    ...mapGetters({
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData'
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    // 新增一条
    handlePlus() {
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId,
        planOtherPayId: this.rowId,
        planId: this.currentPlanId,
        planList: this.planList
      });
    },
    selectOtherPay(row, val) {
      row.title = val.value;
    },
    selectOtherPayLabel(row, val) {
      row.title = val.label;
    },
    incomePaySearch(title, cb) {
      const results = this.formatDictionary(this.dictionaryCodeData['payType'], title);
      cb(results);
    },
    formatDictionary(data, title) {
      if (!data || !data.agencyDictionaryList.length) return [];
      let list = data.agencyDictionaryList.map(it => {
        const item = {
          value: it.name
        };
        return item;
      });
      const arr = list.filter(it => it.value.includes(title));
      return arr;
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        count,
        price
      } = obj;
      let total = count * price;
      return total;
    },
    // 修改单行金额
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = val;
        // return
      }
      item.totalPay = this.calcRowTotalMoney(item);
    },
    selectUnit(item, val) {
      const {
        label,
        value
      } = val;
      item.otherUnit = label;
      item.otherUnitId = value == '' ? -1 : value;
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          planOtherPayId: id,
          isCustom: false,
          isChecked: false,
          isNew: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          cashType: it.cashType.toLowerCase(),
          payType: it.cashType.toLowerCase(),
          // planId: it.planId,
          planList: this.planList,
          totalSum: it.count * it.price
        };
        result.push(item);
        // 同步id
        this.rowId++;
      });
      return result;
    },
    beforeCommit(list) {
      if (!list.length) return [];

      // 删除多余字段
      const delKeys = ['isChecked', 'isCustom', 'planList'];
      return list.map(it => {
        if (it.isCustom) {
          delete it.id;
        }
        delKeys.forEach(key => {
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }
        it.cashType = it.cashType.toUpperCase();
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          title,
          price
        } = row;
        if (!title) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容！'
          });
          return false;
        }
        if (!price) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目价格！'
          });
          return false;
        }
      }
      return true;
    },
    changeCheck(flag) {
      return this.tableData.map(it => {
        it.isChecked = flag;
        return it;
      });
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.other_pay,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};