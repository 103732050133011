import { mapGetters } from 'vuex';
import { SelectAccount, SelectStaff, SelectBankCard } from '@/components/common';
import { formMixin } from '@/mixin';
import { parseTime } from '@/utils';
import { defaultCardBetween, cardBetweenRules } from '../config';
export default {
  components: {
    SelectAccount,
    SelectBankCard,
    SelectStaff
  },
  mixins: [formMixin],
  props: {
    title: {
      type: String,
      default: '卡卡调账'
    },
    name: {
      type: String,
      default: '收款人'
    }
  },
  data: () => ({
    isRequire: true,
    visible: false,
    form: defaultCardBetween(),
    validateForm: cardBetweenRules
  }),
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      accountList: 'resource/account/accountList'
    })
  },
  watch: {
    userData: {
      handler: 'getUserData',
      immediate: true
    }
  },
  methods: {
    show(scope) {
      // 获取会计科目列表
      this.form.bankId = scope.row.id;
      this.form.accountCardNo = scope.row.name + '——' + scope.row.cardNo;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.resetForm();
    },
    resetForm() {
      this.form = defaultCardBetween();
    },
    getUserData(val) {
      if (val) {
        this.form.cashUserName = val.realName;
        this.form.cashUserId = val.id;
      }
    },
    changeOPUser(val) {
      // this.form.cashUserId = val.id
      this.form.cashUserName = val.label;
    },
    desBankSelect(val) {
      this.form.desBankId = val.id;
      this.form.desBankName = val.value;
    },
    handleSelectScenic(val) {
      this.form.subjectId = val.id;
      this.form.subjectName = val.value;
    },
    handleCreate() {
      if (this.form.cashDate) {
        this.form.cashDate = parseTime(this.form.cashDate, '{y}-{m}-{d}');
      }
      this.form.money = this.form.moneyMinute;
      if (!this.handleValidate()) return;
      this.$store.dispatch('resource/account/fetchTransfert', this.form).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.visible = false;
      });
    }
  }
};