import { inputMixin } from '@/mixin';
export default {
  name: 'InputPeopleCount',
  mixins: [inputMixin],
  props: {
    adult: {
      type: [String, Number],
      default: ''
    },
    child: {
      type: [String, Number],
      default: ''
    },
    accompany: {
      type: [String, Number],
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getAudlt: {
      get() {
        return this.adult;
      },
      set(val) {
        this.$emit('update:adult', val);
      }
    },
    getChild: {
      get() {
        return this.child;
      },
      set(val) {
        this.$emit('update:child', val);
      }
    },
    getAccompany: {
      get() {
        return this.accompany;
      },
      set(val) {
        this.$emit('update:accompany', val);
      }
    }
  }
};