import "core-js/modules/es.array.push.js";
import DialogAgreementEdit from './DialogAgreementEdit';
import SwitchStatus from '../SwitchStatus.vue';
import { resourceAgreement } from '@/config/resource';
import { mapGetters } from 'vuex';
const mapKeys = {
  HOTEL: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'quotedPrice',
    text: '报价'
  }, {
    label: 'price',
    text: '底价'
  }],
  SCENIC: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'quotedPrice',
    text: '报价'
  }, {
    label: 'sellPrice',
    text: '卖价'
  }, {
    label: 'price',
    text: '底价'
  }, {
    label: 'accompanyRate',
    text: '全陪比例',
    symbol: true
  }, {
    label: 'driverRate',
    text: '司佣比例',
    symbol: true
  }, {
    label: 'guideRate',
    text: '导佣比例',
    symbol: true
  }],
  SHOP: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'accompanyRate',
    text: '全陪比例',
    symbol: true
  }, {
    label: 'driverRate',
    text: '司佣比例',
    symbol: true
  }, {
    label: 'guideRate',
    text: '导佣比例',
    symbol: true
  }, {
    label: 'guideManagerRate',
    text: '导管比例',
    symbol: true
  }, {
    label: 'shopRate',
    text: '社佣比例',
    symbol: true
  }],
  RESTAURANT: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'price',
    text: '单价'
  }],
  TRANSPORT: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'price',
    text: '价格'
  }, {
    label: 'subsidy',
    text: '返款'
  }],
  INSURANCE: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'price',
    text: '价格'
  }],
  BUS: [{
    label: 'contentType',
    text: '项目'
  }, {
    label: 'price',
    text: '价格'
  }]
};
export default {
  name: 'DialogAgreement',
  components: {
    DialogAgreementEdit,
    SwitchStatus
  },
  data() {
    return {
      mapKeys,
      visible: false,
      showInput: false,
      form: {},
      resourceObj: {},
      tableLoading: false,
      columnData: resourceAgreement,
      tableData: [],
      excelStatus: false,
      updateUrl: '',
      fileName: '',
      resourceId: ''
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      curAgreement: 'resource/common/curAgreement',
      agreementItem: 'resource/common/agreementItem'
    }),
    getResourceContent() {
      return list => {
        if (!list || !list.length) return;
        const type = this.resourceObj.resourceType;
        const typeList = mapKeys[type];
        const result = [];
        list.forEach(it => {
          let value = '';
          typeList.forEach(item => {
            const hasSymble = item.symbol ? '%' : item.label !== 'contentType' ? '元' : '';
            value += `${item.text}:
            ${it[item.label]} <span style="margin-right:10px"> ${hasSymble} </span>`;
          });
          result.push(value);
        });
        return result;
      };
    }
  },
  watch: {
    agreementItem: [{
      handler: function (val) {
        this.form = val;
      },
      immediate: true
    }],
    curAgreement: {
      handler: function (data) {
        if (!data) return;
        const {
          list
        } = data;
        this.tableData = list;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    show(row, type) {
      this.form = {};
      this.visible = true;
      this.resourceObj = row;
      this.resourceObj.resourceType = type;
      this.resourceId = row.id;
    },
    hide() {
      this.visible = false;
      this.updateUrl = '';
    },
    changeDate(val) {
      const [start, end] = val;
      this.form.startTime = start;
      this.form.endTime = end;
      this.changeAgreement(this.form);
    },
    handleChange(type, val) {
      this.form[type] = val;
      this.changeAgreement(this.form);
    },
    handleContent(scope) {
      // 显示当前协议列表
      this.$parent.showAgreementItem(scope.row.id);
    },
    handleAdd() {
      const {
        agreementEditRef
      } = this.$refs;
      agreementEditRef.show({
        item: {
          resourceId: this.resourceId
        },
        type: this.resourceObj.resourceType,
        data: []
      });
    },
    handleEdit(scope) {
      const copy = {
        ...scope.row
      };
      copy.expire = [copy.startTime, copy.endTime];
      // 弹框修改
      const {
        agreementEditRef
      } = this.$refs;
      agreementEditRef.show({
        item: copy,
        type: this.resourceObj.resourceType
      });
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('resource/common/deleteAgreement', id);
      });
    },
    changeStatus(row) {
      this.createAgreement(row);
    },
    handleUpdate(form) {
      this.form = form;
      if (!this.validateForm()) return;
      // 因为新增加的数据是直接插入到store里到，并没有重新获取数据，所以ID是没有，需要重新添加一下
      const opts = {
        ...this.form,
        ...this.filterOpts()
      };
      this.createAgreement(opts);
    },
    handleRemove() {
      this.showInput = false;
      this.resetForm();
    },
    handleCreate() {
      if (!this.validateForm()) return;
      const opts = this.filterOpts();
      this.createAgreement(opts);
      this.showInput = false;
    },
    filterOpts() {
      let unit = '';
      // 由于酒店名称特殊字段 hotelName 添加判断
      if (this.resourceObj.resourceType == 'HOTEL') {
        unit = this.resourceObj.hotelName;
      } else {
        unit = this.resourceObj.name;
      }
      const {
        id: resourceId,
        travelAgencyId,
        resourceType
      } = this.resourceObj;
      const opts = {
        contractPrices: [],
        resourceId,
        resourceType,
        travelAgencyId,
        unit,
        ...this.agreementItem
      };
      delete opts.expire;
      return opts;
    },
    createAgreement(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/common/createOrUpdateAgreement', opts).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.resetForm();
        this.$parent.fetchAgreement(opts.resourceId);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    changeAgreement(form = {}) {
      this.$store.dispatch('resource/common/editAgreement', form);
    },
    validateForm() {
      const {
        name,
        expire
      } = this.form;
      if (!name) {
        this.$bus.tip({
          type: 'warning',
          message: '协议名称不能为空'
        });
        return false;
      }
      if (!expire) {
        this.$bus.tip({
          type: 'warning',
          message: '协议日期不能为空'
        });
        return false;
      }
      return true;
    },
    resetForm() {
      this.changeAgreement();
    }
  }
};