import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    handleAdjustment() {
      this.$parent.showCardDialog({
        title: '卡内调账',
        name: '调账人'
      });
    },
    handleTransfor() {
      this.$parent.showCardDialog({
        title: '卡内转账',
        name: '收款人'
      });
    },
    changeStatus(row) {
      this.$parent.createOrUpdateAccount(row);
    }
  }
};