var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.hasMember ? _c("div", _vm._l(_vm.getList, function (item, index) {
    return _c("div", {
      key: index
    }, [index < _vm.len ? [!_vm.wrap ? [_vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.mobilePhone) + " ")] : [_vm._v(" " + _vm._s(item.name)), _c("br"), _vm._v(" " + _vm._s(item.mobilePhone) + " ")]] : _vm._e()], 2);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };