import { tableMixin } from '@/mixin';
import { KPIType } from '@/config/system';
export default {
  mixins: [tableMixin],
  data() {
    return {};
  },
  computed: {
    projectName() {
      return row => {
        if (row.kpitype == KPIType.turnover) {
          return '营业额';
        }
        if (row.kpitype == KPIType.profit) {
          return '毛利';
        }
        if (row.kpitype == KPIType.numberOfPeople) {
          return '人数';
        }
      };
    }
  },
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    rowClassObj({
      rowIndex,
      column
    }) {
      if (!['customerName', 'year', 'action'].includes(column.property)) {
        console.log('rowIndex:', rowIndex);
        if (rowIndex % 3 == 0) {
          return 'row-red';
        }
        if (rowIndex % 3 == 1) {
          return 'row-yellow';
        }
        if (rowIndex % 3 == 2) {
          return 'row-blue';
        }
      }
      return '';
    },
    handleEdit({
      row
    }) {
      this.$parent.handleEdit(row);
    },
    handleDelete({
      row
    }) {
      this.$parent.handleDelete(row);
    },
    handleLog({
      row
    }) {
      this.$parent.handleLog(row);
    }
  }
};