import { orderInfo, tripDate, orderArrangeRemark, orderOther, orderTotalPay, auditStatus, guide, payed, unPay, createTime, actionItem } from './index';
const lastItem = [orderArrangeRemark, orderOther, orderTotalPay, payed, unPay, createTime, auditStatus, actionItem];

// 按团号去付款 酒店
export const HotelColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'hotelName',
    label: '酒店',
    'min-width': 100
  }
}, guide, {
  attrs: {
    prop: 'hotelRooms',
    label: '房间信息'
  },
  children: [{
    attrs: {
      prop: 'roomType',
      label: '房间类型'
    }
  }, {
    attrs: {
      prop: 'count',
      label: '数量'
    }
  }, {
    attrs: {
      prop: 'freeCount',
      label: '免去数量'
    }
  }, {
    attrs: {
      prop: 'settlementPrice',
      label: '价格'
    }
  }, {
    attrs: {
      prop: 'otherFee',
      label: '其他成本'
    }
  }]
}, ...lastItem];