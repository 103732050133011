import "core-js/modules/es.array.push.js";
import { planTeamArrange } from '@/config/plan';
import { PersonCount } from '@/components/common';
import { Guides } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    PersonCount,
    Guides
  },
  data() {
    return {
      visible: false,
      columnData: [...planTeamArrange.slice(0, 2), ...planTeamArrange.slice(3, 6), {
        attrs: {
          label: '操作',
          prop: 'action'
        },
        slot: 'action',
        header: 'headAction'
      }],
      tableData: [],
      total: 0,
      statistics: null,
      multipleSelection: [],
      searchParams: {},
      checkedAll: true
    };
  },
  computed: {
    planData() {
      return this.$store.getters['plan/index/planData'];
    }
  },
  watch: {
    planData: {
      handler: function (data) {
        if (!data) return;
        this.formatData(data);
      },
      immediate: true
    }
  },
  methods: {
    show(params) {
      this.visible = true;
      this.searchParams = params;
    },
    hide() {
      this.visible = false;
      this.sele;
    },
    formatData(data) {
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistics = statistics;
      this.multipleSelection = list.map(it => it.id);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val ? this.tableData.filter(it => it.isChecked).map(it => it.id) : [];
    },
    toggleCheck(row) {
      const {
        id,
        isChecked
      } = row;
      this.multipleSelection = isChecked ? this.multipleSelection.push(id) : this.multipleSelection.filter(v => v != id);
    },
    handleExport() {
      const params = {
        planIds: `${this.multipleSelection.join(',')}`
      };
      this.$store.dispatch('plan/index/exportPlanMeberList', params).then(res => {
        downloadExport(res, '计划名单表.xlsx');
      }).then(() => {
        this.hide();
        this.$bus.tip('导出成功！');
      });
    }
  }
};