import CountDown from '@/components/CountDown';
import { isPhone } from '@/utils';
import { APP } from '@/config';
export default {
  name: 'DialogLog',
  components: {
    CountDown
  },
  data() {
    return {
      visitCodeImg: '',
      visible: false,
      form: {},
      data: {},
      type: 'VALIDATED',
      countState: true
    };
  },
  computed: {
    getCodeImage() {
      return 'data:image/jpeg;base64,' + this.visitCodeImg;
    }
  },
  watch: {
    'form.phone': {
      handler: function (val) {
        this.countState = isPhone(val) ? false : true;
      }
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.data = data;
    },
    // 刷新验证码
    refreshCode() {
      const params = {
        mobilePhone: this.form.phone,
        type: this.type
      };
      this.$store.dispatch('user/refreshCode', params).then(data => {
        const {
          visitCodeImg
        } = data;
        this.visitCodeImg = visitCodeImg;
      });
    },
    // 发送手机验证码
    handleSend() {
      const opts = {
        mobilePhone: this.form.phone,
        type: this.type,
        visitCode: this.form.visitCode
      };
      this.$store.dispatch('user/getSmsCode', opts).then(data => {
        this.visitCodeImg = data.visitCodeImg;
        if (data.visitCodeImg) {
          this.$refs.countDownRef.restoreCount();
          this.countState = false;
        }
      }).catch(() => {
        this.$bus.tip({
          type: 'error',
          message: '请求次数过多，请输入验证码后再重试！'
        });
        this.$refs.countDownRef.restoreCount();
        this.countState = false;
      });
    },
    hide() {
      this.visible = false;
    },
    handleUpdate() {
      const opts = {
        // wx_uid: 'o0pMt514pouuMuW-UYlMtVYwRuG8',
        wx_uid: this.data.wx_uid,
        phoneNumber: this.form.phone,
        phone_visit_sms_code: this.form.code
      };
      this.$store.dispatch('user/getWxLoginBind', opts).then(() => {
        const {
          path
        } = this.$route;
        if (path == '/login') {
          this.$parent.handleRedirect();
        }
      }).catch(err => {
        err.code == APP.travelErrNo && this.$parent.chooseTravel(err.data);
        this.loading = false;
      });
    }
  }
};