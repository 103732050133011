var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "确认件",
      fullscreen: "",
      visible: _vm.visible,
      "append-to-body": true,
      "show-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("input", {
    ref: "excel-upload-input",
    staticClass: "excel-upload-input",
    attrs: {
      type: "file",
      accept: ".xlsx, .xls"
    },
    on: {
      change: _vm.handleClick
    }
  }), _c("div", {
    staticClass: "docxRef"
  }), _c("div", {
    staticClass: "xlsxClass"
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };