import { Search, DialogAdd, DialogEdit, DialogCopy, DialogHistory, DialogName, DialogEditName } from './components';
import { Columns } from './tableConfig';
import { getShipInfo } from './helper';
import { formatPeopleValue } from '@/utils';
import { LOG_YPES } from '@/config/resource';
import { BtnLink, DialogLog } from '@/components/common';
import { OrderResPlanEnum, OrderResPlanEnumLabel } from '@/config/order/orderEnum';
export default {
  components: {
    Search,
    DialogAdd,
    DialogEdit,
    DialogCopy,
    DialogHistory,
    DialogName,
    DialogEditName,
    DialogLog,
    BtnLink
  },
  data() {
    return {
      Columns,
      getShipInfo,
      formatPeopleValue
    };
  },
  computed: {
    data() {
      return this.$store.getters['plan/boatPersonal/data'];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    tableData() {
      return this.data ? Object.freeze(this.formatData(this.data.list)) : [];
    },
    getBookingStatus() {
      return val => val ? OrderResPlanEnumLabel[val] : '';
    },
    getExpandClass() {
      return (row, type) => {
        return row[type] ? `${row[type]}-color` : '';
      };
    },
    isCanceled() {
      return row => row.bookingStatus === OrderResPlanEnum.CANCELED;
    },
    canModify() {
      return row => row.bookingStatus !== OrderResPlanEnum.ARRANGED && row.bookingStatus !== OrderResPlanEnum.WEIT_CANCEL;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = data;
          this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = this.searchParams) {
      this.$store.dispatch('plan/boatPersonal/fetch', params);
    },
    handlePage(params) {
      this.fetchData({
        ...this.searchParams,
        ...params
      });
    },
    setColorForStatus(row, column) {
      const keys = ['shipTicket'];
      if (row.bookingStatus == OrderResPlanEnum.CANCELED) {
        return 'CANCEL-color';
      } else {
        const key = keys.find(key => column.property == key);
        const status = row[key + 'Status'];
        return status ? `${status}-color` : '';
      }
    },
    formatData(data) {
      return data.map(it => {
        const {
          outOPUsers,
          outOPUserName
        } = it;
        it.$outOPUserName = outOPUserName || outOPUsers.length ? outOPUsers[0].realName : '';
        return it;
      });
    },
    tableCellClass({
      row,
      column
    }) {
      return this.setColorForStatus(row, column);
    },
    showDialog() {
      this.$refs.dialogAddRef.show();
    },
    // 修改
    handleUpdate(row) {
      this.$refs.dialogEditRef.edit({
        ...row
      });
    },
    // 查看
    handleDetail(row) {
      this.$refs.dialogDetailRef.show(row);
    },
    // 复制
    handleCopy(row) {
      this.$refs.dialogCopyRef.edit(row);
    },
    // 历史
    handleHistory(row) {
      const {
        id
      } = row;
      this.$refs.dialogHistoryRef.show(id);
    },
    // 取消
    handleCancel(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('plan/boatPersonal/cancel', {
          id
        }).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      }, '确定要取消吗？');
    },
    // 日志
    handleLog({
      id
    }) {
      const types = `${LOG_YPES.resourcePlan},${LOG_YPES.resourcePlan}`;
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId: id,
        types,
        subType: '',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handleEditName(row) {
      this.$refs.dialogEditNameRef.show(row);
    },
    handleBoatName(row) {
      this.$refs.dialogNameRef.show(row);
    }
  }
};