// 备注信息
export const remarkText = {
  serviceStandardRemark: '服务标准',
  innerRemark: '内部备注',
  friendlyTips: '特别交代',
  hotelRemark: '用房备注',
  restaurantRemark: '用餐备注',
  busRemark: '用车备注',
  scenicRemark: '景区备注',
  remark: '备注'
};