export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isRequire: [Boolean]
  }
};