var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box"
  }, [_vm.label ? _c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c("el-cascader", _vm._g(_vm._b({
    attrs: {
      options: _vm.options,
      props: _vm.props,
      clearable: _vm.clearable
    },
    model: {
      value: _vm.curVal,
      callback: function ($$v) {
        _vm.curVal = $$v;
      },
      expression: "curVal"
    }
  }, "el-cascader", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };