export default {
  data() {
    return {
      visible: false,
      ticketData: [],
      selectData: [],
      signSheetId: '',
      ticket: {
        start: '',
        end: ''
      }
    };
  },
  methods: {
    fetchTicket(params) {
      this.$store.dispatch('ticket/index/fetchSearch', params).then(data => {
        if (!data) return;
        this.ticketData = this.formatData(data.list);
      });
    },
    formatData(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        return {
          ...it,
          label: it.signNumber,
          value: it.signSheetId
        };
      });
    },
    show(signSheetId) {
      this.visible = true;
      this.signSheetId = signSheetId;
      this.fetchTicket({
        signSheetId,
        onPlanStatus: 0
      });
    },
    hide() {
      this.visible = false;
    },
    changeCheckbox(val) {
      this.selectData = val;
    },
    handleSearch() {},
    processData() {
      const {
        id: planId
      } = this.$route.query;
      return this.selectData.map(val => {
        return {
          startNo: val,
          count: 1,
          signSheetId: this.signSheetId,
          planId
        };
      });
    },
    handleComfirm() {
      if (!this.selectData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请勾选签单'
        });
        return;
      }
      const optList = this.processData();
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('ticket/index/batchCreateTicketItem', optList).then(() => {
        this.$bus.emit('ticket:reload', id);
        this.hide();
      });
    }
  }
};