import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.reduce.js";
import { ItineraryStatusMap, ItineraryType } from '@/config/superviseTravel';
import { isNumber, isPhone, parseTime } from '@/utils';
import { getIdCardInfo } from '@/utils/idcard';
import { isPlateNo } from '@/utils/validate';
// prettier-ignore
export { DEF_VALUE, rules, AdaptSex, ItineraryStatusMap, defaultState, defaultGuideState, AdaptObj, AdaptBusObj, AdaptCustomerObj, AdaptGuideObj, AdaptTouristObj, insuranceMenu, TransfomCertificate };
const DEF_VALUE = {
  relevancePlaceType: '2',
  payItemType: '',
  stopoverTime: 60,
  arrivalDistrict: '桂林市',
  arrivalDistrictCode: '00,450000000000,450300000000',
  freePlaceName: '',
  relevancePlaceId: '',
  routeItineraryId: null,
  currentSiteNumber: 1
};
const touristState = () => ({
  $cellEdit: false,
  $gender: '',
  $idType: '身份证',
  $index: 1,
  birthDate: '',
  birthPlace: '',
  gender: '2',
  idNumber: '',
  idType: '1',
  listCode: '',
  name: '',
  nationality: '',
  phoneNumber: '',
  year: ''
});
const defaultState = () => ({
  contractNo: ItineraryType.team,
  teamNumber: '',
  groupAgencyName: '',
  groupAgencyManager: '',
  groupAgencyPhone: '',
  routeName: '',
  clientSourcePlace: '广西',
  teamType: '5',
  beginDate: '',
  beginTime: '',
  overDate: '',
  overTime: '',
  totalDays: '',
  transportCompanyInfoName: '',
  transportCompanyLicenceNumber: '',
  arrivalTrainNumber: '',
  receptionGroupAddr: '',
  departureTrainNumber: '',
  sendGroupAddr: '',
  vehicleInfoNumber: '',
  seatingCapacity: '',
  driverName: '',
  driverPhone: '',
  driverIdCardNumber: '',
  driverCertNumber: '',
  totalServerName: '',
  totalServerPhone: '',
  adultNumber: '',
  childNumber: '0',
  isStudentGroup: '0',
  isInsurancePurchased: '0',
  insuranceCompanyName: '',
  insuranceType: '',
  remark: '',
  cateringMsg: '',
  guideId: '',
  guideName: '',
  guideLicencePhone: '',
  guideCompany: '',
  guideGroupBeginTim: '',
  guideGroupEndTime: '',
  ticketStatus: '0',
  totalCustomer: 0,
  itineraryType: '0',
  route: {
    itineraryOptList: [],
    totalDays: 1,
    routeName: ''
  },
  customerList: [touristState()],
  attachmentList: [],
  $isStudentGroup: '不是学生团',
  $isInsurancePurchased: '未购买',
  $insuranceType: '',
  $cellEdit: true,
  $index: 0
});
const insuranceMenu = [{
  value: '0',
  label: '旅游意外险'
}, {
  value: '1',
  label: '旅游责任险'
}, {
  value: '2',
  label: '旅游统保险'
}];
const defaultGuideState = () => ({
  guideName: '',
  guideLicencePhone: '',
  guideCompany: ''
});
const AdaptObj = {
  planNumber: 'teamNumber',
  productName: 'routeName',
  adultCount: 'adultNumber',
  childCount: 'childNumber',
  startPlanDate: 'beginDate',
  endPlanDate: 'overDate',
  customerArea: 'clientSourcePlace',
  customerName: 'groupAgencyName'
};
const AdaptCustomerObj = {
  customerArea: 'clientSourcePlace',
  customerName: 'groupAgencyName'
};
const AdaptGuideObj = {
  name: 'guideName',
  mobileIphone: 'guideLicencePhone'
};
const AdaptBusObj = {
  busCompanyName: 'transportCompanyInfoName',
  driverName: 'driverName',
  driverPhone: 'driverPhone',
  busType: 'seatingCapacity',
  licenceNumber: 'vehicleInfoNumber'
};
const AdaptTouristObj = {
  location: 'birthPlace',
  sex: 'gender',
  cardNumber: 'idNumber',
  cardType: 'idType',
  mobilePhone: 'phoneNumber'
};
const pickerKeys = ['name', 'gender', 'idType', 'idNumber', 'birthDate', 'birthPlace', 'phoneNumber', '$gender', '$idType'];
const TransfomCertificate = {
  0: 1,
  2: 5,
  3: 2,
  5: 3,
  6: 9
};
const TransfomCertificateLabel = {
  1: '身份证',
  5: '护照',
  2: '港澳通行证',
  3: '台湾通行证',
  9: '其它'
};
const AdaptSex = {
  1: '0',
  2: '1'
};
const TransfomSex = {
  0: '1',
  1: '2'
};
const TransfomSexLabel = {
  1: '男',
  2: '女'
};
const validatePhone = (rule, value, callback) => {
  if (isPhone(value)) {
    callback();
  } else {
    callback(new Error('手机号不正确'));
  }
};
const validateVehicle = (rule, value, callback) => {
  if (isPlateNo(value)) {
    callback();
  } else {
    callback(new Error('车牌号不正确'));
  }
};
const rules = {
  groupAgencyName: [{
    required: true,
    message: '组团社名称不能为空!'
  }],
  routeName: [{
    required: true,
    message: '线路名称不能为空!'
  }],
  clientSourcePlace: [{
    required: true,
    message: '客源地不能为空!'
  }],
  teamType: [{
    required: true,
    message: '团队类型不能为空!'
  }],
  beginDate: [{
    required: true,
    message: '行程时间不能为空!'
  }],
  transportCompanyInfoName: [{
    required: true,
    message: '车队不能为空!'
  }],
  vehicleInfoNumber: [{
    required: true,
    message: '车牌号不能为空!'
  }, {
    validator: validateVehicle,
    trigger: 'blur'
  }],
  seatingCapacity: [{
    required: true,
    message: '座位数不能为空!'
  }],
  driverName: [{
    required: true,
    message: '司机姓名不能为空!'
  }],
  driverPhone: [{
    required: true,
    message: '司机电话不能为空!'
  }, {
    validator: validatePhone,
    trigger: 'blur'
  }],
  totalServerName: [{
    required: true,
    message: '全陪姓名不能为空!'
  }],
  totalCustomer: [{
    required: true,
    message: '总人数不能为空!'
  }],
  childNumber: [{
    required: true,
    message: '小孩数不能为空!'
  }],
  guideName: [{
    required: true,
    message: '导游姓名不能为空!'
  }],
  guideLicencePhone: [{
    required: true,
    message: '导游手机号不能为空'
  }]
};
export function formatMembers(list) {
  return list.map(it => {
    return {
      ...it,
      ...getInfo(it.cardNumber)
    };
  });
}
export function formatTourist(list) {
  const newList = list.map(it => {
    it.idType = it.idTyp || TransfomCertificate[it.cardType] || TransfomCertificate['0'];
    it.$idType = TransfomCertificateLabel[it.idType] || TransfomCertificateLabel['1'];
    it.idNumber = it.idNumber || it.cardNumber;
    it.gender = it.gender || TransfomSex[it.sex];
    it.$gender = it.$gender || TransfomSexLabel[it.gender] || TransfomSex[it.sex];
    if (it.idNumber) {
      const {
        birthDate,
        location,
        year
      } = getInfo(it.idNumber);
      it.birthDate = birthDate && parseTime(it.birthDate || birthDate) || '';
      it.birthPlace = it.birthPlace || location || '';
      it.year = year;
    }
    it.phoneNumber = it.phoneNumber || it.mobilePhone;
    return it;
  });
  return pickerTouristKey(newList);
}
function pickerTouristKey(list) {
  return list.map(it => {
    const item = pickerKeys.reduce((acc, key) => {
      acc[key] = it[key];
      return acc;
    }, {});
    return item;
  });
}
function processDrivers(obj) {
  const keys = ['driverCertNumber', 'driverIdCardNumber', 'driverName', 'driverPhone', 'seatingCapacity', 'transportCompanyInfoName', 'transportCompanyLicenceNumber', 'vehicleInfoNumber'];
  return keys.reduce((acc, cur) => {
    acc[cur] = obj[cur];
    return acc;
  }, {});
}
function processGuides(obj) {
  const keys = ['guideCompany', 'guideGroupBeginTim', 'guideGroupEndTime', 'guideId', 'guideLicenceCode', 'guideLicencePhone', 'guideName'];
  return keys.reduce((acc, cur) => {
    acc[cur] = obj[cur];
    return acc;
  }, {});
}
export function fillTouristKey(obj) {
  const {
    customerList,
    guideName,
    guideLicenceCode
  } = obj;

  // // 过滤无手机号码的游客
  const filterList = customerList.filter(it => it.phoneNumber && it.phoneNumber != '');

  // if (!filterList.length) {
  //   obj.customerList = []
  // } else {
  //   obj.customerList = customerList.map((it, idx) => {
  //     const obj = {
  //       $cellEdit: false,
  //       nationality: '中国',
  //       $index: idx,
  //       guideName: guideName,
  //       businessLicenseNumber: '',
  //       listCode: '',
  //       guideCertificateNumber: guideLicenceCode,
  //     }
  //     return { ...it, ...obj }
  //   })
  // }
  obj.customerList = filterList.map((it, idx) => {
    const obj = {
      $cellEdit: false,
      nationality: '中国',
      $index: idx,
      guideName: guideName,
      businessLicenseNumber: '',
      listCode: '',
      guideCertificateNumber: guideLicenceCode
    };
    return {
      ...it,
      ...obj
    };
  });

  // 处理司机
  obj.drivers = [processDrivers(obj)];

  // 导游
  obj.guides = [processGuides(obj)];
  return obj;
}
function trimStrSpace(val) {
  const re = /^\s+|\s+$/g;
  return val && typeof val == 'string' ? val.replace(re, '') : val;
}
export function formatPhoneSpace(obj) {
  const keys = ['totalServerPhone', 'driverPhone'];
  keys.forEach(key => {
    return obj[key] = trimStrSpace(obj[key]);
  });
  return obj;
}
export function processObj(obj, keyObj) {
  if (!obj) return;
  const result = Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];
    const re = /^\s+|\s+$/g;
    if (val) {
      acc[keyObj[key]] = typeof val == 'string' ? val.replace(re, '') : val;
    }
    return acc;
  }, {});
  return result;
}
export function formatSiteNumber(obj) {
  const {
    seatingCapacity,
    totalCustomer,
    busType
  } = obj;
  obj.seatingCapacity = isNumber(seatingCapacity) ? seatingCapacity : parseInt(seatingCapacity) + 2 || parseInt(busType) + 2 || parseInt(totalCustomer) + 2;
  return obj;
}
// 处理全陪信息
export function getServerInfo(val) {
  if (!val) return {
    totalServerName: '',
    totalServerPhone: ''
  };
  // const re = /([\u4e00-\u9fa5]+)(\d+)/g
  // const match = re.exec(val.replace(/\s/g, ''))

  // if (match) {
  //   const [, totalServerName, totalServerPhone] = match
  //   return { totalServerName, totalServerPhone }
  // } else {
  //   // 外国人全陪格式
  //   const [name, phone] = val.split('/')
  //   return { totalServerName: name, totalServerPhone: phone }
  // }
  if (val.indexOf('/') > -1) {
    const [name, phone] = val.split('/');
    return {
      totalServerName: name,
      totalServerPhone: phone
    };
  } else {
    const re = /([\u4e00-\u9fa5]+)(\d+)/g;
    const match = re.exec(val.replace(/\s/g, ''));
    const [, totalServerName, totalServerPhone] = match;
    return {
      totalServerName,
      totalServerPhone
    };
  }
}
export function getInfo(val) {
  if (!val) return;
  const useInfo = getIdCardInfo(val);
  let {
    addr: location,
    birth
  } = useInfo;
  const {
    age,
    year
  } = getAge(birth);
  return {
    location,
    age,
    year,
    birthDate: birth,
    ...useInfo
  };
}
function getAge(birth) {
  if (!birth) return;
  const [y,,,] = birth.split('-');
  const date = new Date();
  const year = date.getFullYear();
  let age = Number(year) - Number(y);
  return {
    age,
    year
  };
}