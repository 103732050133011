var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_c("span", {
    staticClass: "ml20"
  }, [_vm._v("短信剩余条数： " + _vm._s(_vm.getCount) + " 条 ")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.showTopupDialog
    }
  }, [_vm._v(" 充值 ")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.showRecordDialog
    }
  }, [_vm._v(" 充值记录 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };