import "core-js/modules/es.array.push.js";
// import { resourceType } from '@/config/report'
import { downloadExport } from '@/utils/exportExcel';
import { SelectDictionary, SelectCustom, BtnExport } from '@/components/common';
import TableTotal from './TableTotal';
import TableDetail from './TableDetail';
import { groupTotal, groupDetail } from '@/config/report';
import { searchMixin } from '@/mixin';
import { parseDate, decimalSplit, processDecimal } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
import { mapGetters } from 'vuex';
import { processAdd, processMinus } from '@/utils/number';
const sumKeys = ['adultCount', 'childCount', 'feeSum', 'profit', 'totalPay', 'totalFee', 'shopIncome', 'shopSubsidy', 'totalAddScenicIncome', 'totalOtherIncome', 'totalGuideServiceFeePay', 'totalBusPay', 'totalScenicQuotePay', 'totalRestaurantPay', 'totalHotelQuotePay', 'totalOtherPay', 'totalShopRebatePay', 'totalAddScenicQuotePay', 'totalOutTransferPay'];
export default {
  components: {
    TableTotal,
    TableDetail,
    SelectDictionary,
    SelectCustom,
    BtnExport
  },
  mixins: [searchMixin],
  data: () => ({
    link: {
      checks: '/bill-check/reception'
    },
    tableLoading: false,
    visibleTotal: false,
    visibleDetail: false,
    columnData: [],
    tableData: [],
    sendDate: [today, ''],
    searchParams: {
      startTripDateS: today
    },
    searchType: {
      group: 'GROUP',
      area: 'AREA',
      manager: 'MANAGER',
      custom: 'CUSTOM'
    },
    path: {
      GROUP: 'report/index/fetchGroup',
      AREA: 'report/index/fetchGroupCustomerArea',
      CUSTOM: 'report/index/fetchGroupCustomer',
      MANAGER: 'report/index/fetchGroupAreaManager'
    }
  }),
  computed: {
    ...mapGetters({
      groupTotalData: 'report/index/groupTotalData',
      groupCustomerAreaData: 'report/index/groupCustomerAreaData',
      groupCustomerData: 'report/index/groupCustomerData',
      groupAreaManagerData: 'report/index/groupAreaManagerData'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            startTripDateS,
            startTripDateE,
            tableType,
            tableTypeLabel
          } = data;
          this.searchParams = data;
          this.searchParams.tableType = tableType ? tableType : this.searchType.group;
          this.searchParams.tableTypeLabel = tableTypeLabel ? tableTypeLabel : '汇总';
          this.sendDate = [startTripDateS, startTripDateE];
        }
      },
      immediate: true
    },
    groupTotalData: {
      handler: function (data) {
        if (!this.isCurrentPath(this.searchType.group)) return;
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.initTotalData(data);
        }
      },
      immediate: true
    },
    groupCustomerAreaData: {
      handler: function (data) {
        if (!this.isCurrentPath(this.searchType.area)) return;
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.initAreaData(data);
        }
      },
      immediate: true
    },
    groupCustomerData: {
      handler: function (data) {
        if (!this.isCurrentPath(this.searchType.custom)) return;
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.initAreaData(data);
        }
      },
      immediate: true
    },
    groupAreaManagerData: {
      handler: function (data) {
        if (!this.isCurrentPath(this.searchType.manager)) return;
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.initAreaData(data);
        }
      },
      immediate: true
    }
  },
  // create() {
  //   this.fleetData()
  // },
  methods: {
    // 判断表格类型
    isCurrentPath(type) {
      return this.searchParams.tableType == type;
    },
    initTotalData(data) {
      this.columnData = groupTotal;
      setTimeout(() => {
        this.visibleDetail = false;
        this.visibleTotal = true;
      }, 100);
      this.tableData = data.map(it => {
        // 团收入
        it.income = processAdd(it.totalRevenue, it.shopSubsidy);
        // Number(it.totalRevenue) + Number(it.shopSubsidy)

        // 团利润
        it.profit = processMinus(it.income, it.totalPay);
        // 人均利润
        it.perProfit = Number(it.profit) / Number(it.adultCount);
        return processDecimal(it);
      });
    },
    initAreaData(list) {
      this.columnData = groupDetail;
      setTimeout(() => {
        this.visibleDetail = true;
        this.visibleTotal = false;
      }, 100);
      this.tableData = list.map((it, index) => {
        it.id = index;
        if (this.isCurrentPath(this.searchType.area)) {
          it.index = it.customerArea;
        }
        if (this.isCurrentPath(this.searchType.custom)) {
          it.index = it.customerName;
        }
        if (this.isCurrentPath(this.searchType.manager)) {
          it.index = it.customerAreaManager;
        }
        it.children = it.orders.map((item, i) => {
          item.index = i + 1;
          item.id = item.orderId;
          item.shopSubsidy = item.shopSubsidy ? item.shopSubsidy : 0;
          item.feeSum = decimalSplit(Number(item.totalRevenue) + Number(item.shopSubsidy));
          item.profit = decimalSplit(Number(item.feeSum) - Number(item.totalPay));
          item.area = item.customerArea ? item.customerArea : it.customerArea;
          return item;
        });
        let summary = {};
        sumKeys.forEach(key => {
          summary[key] = 0;
          summary.index = `小计(${index + 1})`;
          summary.id += index;
          it.children.forEach(it => {
            summary[key] += decimalSplit(Number(it[key]));
          });
        });
        it.children.push(summary);
        return it;
      });
    },
    // 选择散、发团日期
    onChangDate(date) {
      this.searchParams.startTripDateS = date[0];
      this.searchParams.startTripDateE = date[1];
    },
    // 选择客户
    onSelectCustom(val) {
      this.searchParams.customerLabel = val.value;
      this.searchParams.customerId = val.value;
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type] = value;
      this.searchParams[type + 'Label'] = label;
    },
    onSelectTableType(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.tableType = value;
      this.searchParams.tableTypeLabel = label;
    },
    onSelectArea(val) {
      this.searchParams.customerArea = val.label;
    },
    fetchData(queryParam) {
      const fetchPath = this.path[this.searchParams.tableType];
      this.$store.dispatch(fetchPath, queryParam);
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      const fetchPath = this.isCurrentPath(this.path.shuttle);
      const shuttleType = fetchPath ? 'shuttle' : '';
      this.$store.dispatch('report/index/fetchResourceStaticExcel', {
        ...this.searchParams,
        shuttleType
      }).then(res => {
        const fileName = fetchPath ? '接送汇总统计表.xlsx' : '车队汇总统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};