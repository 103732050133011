/* eslint-disable no-empty-pattern */
import { salesmanRequest, customerRequest, customerPeopleRequest, departmentRequest } from '@/api/analysis';
const defaultState = () => {
  return {
    salesmanData: null,
    customerData: null
  };
};
const state = defaultState();
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
const actions = {
  salesmanList({}, params) {
    return new Promise((resolve, reject) => {
      salesmanRequest(params).then(res => {
        const {
          data
        } = res;
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  departmentList({}, params) {
    return new Promise((resolve, reject) => {
      departmentRequest(params).then(res => {
        const {
          data
        } = res;
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  customerList({}, params) {
    return new Promise((resolve, reject) => {
      customerRequest(params).then(res => {
        const {
          data
        } = res;
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  customerPeople({}, params) {
    return new Promise((resolve, reject) => {
      customerPeopleRequest(params).then(res => {
        const {
          data
        } = res;
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};