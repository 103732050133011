var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.getLoginStatus ? _c("div", {
    staticClass: "mt20 flex flex-center"
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.fetchToken
    }
  }, [_vm._v(" 自动登录失败，请点击按钮重新登录 ")])], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };