import "core-js/modules/es.array.reduce.js";
import { SELECTOR } from '@/config/select';
export default {
  data() {
    return {
      tipMsg: '请选择批量添加格式，用空格隔开，一行一条。',
      typeFormat: '',
      visible: false,
      list: SELECTOR.stickupType,
      placeholder: '请按照格式来粘贴信息，用空格隔开，一行一条',
      content: '',
      validataList: [{
        value: 'name:hkm',
        reg: /^[A-Z]d{7}$/
      }, {
        value: 'name:passport',
        reg: /^[KM]d{8J$/
      }, {
        value: 'name:phone:type:id',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(1[3|4|5|6|7|8|9][0-9]{1}\d{8})?(\s+)?(?:[\u4e00-\u9fa5·]{2,5})(\s+)?(\d+[xX]?)/gm
      }, {
        value: 'name:type:id',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(?:[\u4e00-\u9fa5·]{2,5})(\s+)?(\d+[xX]?)/gm
      }, {
        value: 'name:id:phone',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(\d+[\d|X|x]?)(\s+)?(\d{11})?/gm
      }, {
        value: 'name:id',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(\d{11})(\s+)?(\d+[xX]?)/gm
      }, {
        value: 'name:phone',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(1[3|4|5|6|7|8|9][0-9]{1}\d{8})/gm
      }, {
        value: 'name:phone:id',
        reg: /(?:[\u4e00-\u9fa5·]{2,16})(\s+)?(1[3|4|5|6|7|8|9][0-9]{1}\d{8})(\s+)?(\d+[xX]?)/gm
      }],
      formatType: {
        'nname:id': 'name:cardNumber',
        'name:id:phone': 'name:cardNumber:mobilePhone',
        'name:hkm:@3': 'name:cardNumber:@3',
        'name:passport:@2': 'name:cardNumber:@2',
        'name:phone:cardType:id': 'name:mobilePhone:cardType:cardNumber',
        'name:cardType:id': 'name:cardType:cardNumber',
        'name:phone': 'name:mobilePhone',
        'name:phone:id': 'name:mobilePhone:cardNumber'
      }
    };
  },
  mounted() {
    this.typeFormat = this.list[0].value;
    this.$bus.on('show:tourist', this.show);
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.restore();
    },
    restore() {
      this.content = '';
    },
    handleConfirm() {
      if (!this.typeFormat) {
        this.$bus.tip({
          message: '请选择常用格式',
          type: 'warning'
        });
        return;
      }
      if (!this.content || this.content.trim() == ' ') {
        this.$bus.tip({
          message: '请填写游客信息',
          type: 'warning'
        });
        return;
      }
      const formatStr = this.formatType[this.typeFormat];
      const arr = formatStr.split(':');
      let list = this.content.replace(/[\n\r]/g, '/@').split('/@');
      const result = list.map(row => {
        const rowList = row.trim().split(/\s+/).reduce((pre, cur, idx) => {
          const key = arr[idx];
          pre[key] = cur;
          if (arr[arr.length - 1].indexOf('@') > -1) {
            pre['cardType'] = arr[arr.length - 1].split('@')[1];
          } else {
            pre['cardType'] = '0';
          }
          return pre;
        }, {});
        return rowList;
      });

      // const { reg } = this.validataList.find(it => it.value == this.typeFormat)
      // const matches = this.content.match(reg)
      // let list = []
      // if (matches && matches.length) {
      //   const formatStr = this.formatTpl[this.typeFormat]
      //   const arr = formatStr.split(':')
      //   list = matches.map(val => {
      //     return val
      //       .replace(/\s+/g, '/')
      //       .split('/')
      //       .reduce((pre, cur, idx) => {
      //         const key = arr[idx]
      //         pre[key] = cur
      //         return { ...pre, [key]: cur }
      //       }, {})
      //   })
      // }

      this.$emit('on-save', result);
      this.hide();
    }
  }
};