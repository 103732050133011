/**
 * LOCAL 地陪
 * NO_LOCAL 全陪
 */
export const GuideType = {
  NO_LOCAL: '1',
  LOCAL: '2'
};

/**
 * 是否领队 0:不是 1:是
 */
export const LeaderType = {
  LEADER: '1',
  NO_LEADER: '0'
};
export const SexType = {
  MALE: '0',
  FEMALE: '1'
};

/**
 * 证件类型
 * 1身份证 2护照 3台湾通行证 4港澳通行证 5军官证 8回乡证 9 台胞证
 */

export const CardType = {
  IDCARD: '1',
  PASSPORT: '2',
  TAIWAN_GO: '3',
  GANGAO_GO: '4',
  OFFICER: '5',
  BACKHOME: '8',
  TAIBAO: '9'
};
export const CardTypeLabel = {
  IDCARD: '身份证',
  PASSPORT: '护照',
  TAIWAN_GO: '台湾通行证',
  GANGAO_GO: '港澳通行证',
  OFFICER: '军官证',
  BACKHOME: '回乡证',
  TAIBAO: '台胞证'
};

/**
 * 是否儿童
 * 0 否 1 是
 */
export const ChildType = {
  YES: 1,
  NO: 0
};

/**
 * 是否过境
 * 0 否 1 是
 */
export const TransitType = {
  YES: 1,
  NO: 0
};

/**
 * 大交通类型
 * 交通方式 1:"飞机",2:"火车",3:"轮船",4:"汽车",5:"其它"
 */
export const VehicleType = {
  PLANE: '1',
  TRAIN: '2',
  SHIP: '3',
  CAR: '4',
  OTHER: '5'
};

/**
 * 团队类型 1.自组自接, 2.包机, 3.专列，4.其他
 */
export const TeamGroupType = {
  DEFAULT: '1',
  PLANE: '2',
  TRAIN: '3',
  OTHER: '4'
};

/**
 * 业务类型 2.国内游组团, 6.入境接待,7:国内接待
 */
export const BusinessType = {
  GROUP: '2',
  RECEPTION: '7',
  ENTRY: '6'
};

/**
 * 取消类型
 * 业务类型 1:出境游 2:境内游 4:出境免审核 6:入境接待游 7国内接待游8:港澳游组团
 */
export const BusinessCancelType = {
  ABROAD: 1,
  DOMESTIC: 2,
  'ABROAD-NO-AUDIT': 4,
  ENTRY: 6,
  RECEPTION: 7,
  HKM: 8
};
export const DestinationType = {
  CITY: '桂林',
  PROVINCE: '广西'
};

/**
 * 取消类型
 * 取消类别0:游客个人原因 1:旅行社原因 2:不可抗因素 3:其他
 */

export const CancelType = {
  TOURIST: 0,
  TRAVEL: 1,
  NATURAL: 2,
  OTHER: 3
};

/**
 * //1待提交 2已提交／已审核（免审核） 3审核不通过 4审核通过 7待取消 8取消 9已出团／已接待
 */
export const Status = {
  1: '待提交',
  2: '已提交／已审核（免审核）',
  3: '审核不通过',
  4: '审核通过',
  7: '待取消',
  8: '取消',
  9: '已出团／已接待'
};