/* eslint-disable no-unused-vars */
// 公司信息
import { getCompanyInfo, createOrUpdateCompany } from '@/api/system/company';
import { fetchSeal, uploadSeal, deleteSeal } from '@/api/system/systemConf';
import { deleteFile, getFileUrl } from '@/api/index';
import { splitPath } from '@/utils';
const dfaultState = () => ({
  companyInfo: null,
  companyLogo: '',
  businessInfo: null
});
const state = dfaultState();
const getters = {
  companyLogo: state => state.companyInfo,
  companyInfo: state => state.companyInfo,
  businessInfo: state => state.businessInfo
};
const actions = {
  // 获取公司信息
  getInfo({
    commit
  }, id) {
    return getCompanyInfo(id).then(async res => {
      let {
        data
      } = res;
      if (!data.logoUrl) {
        commit('COMPANY_INFO', data);
        return data;
      } else {
        try {
          const file = await getFileUrl([data.logoUrl]);
          if (file) {
            data.logoUrl = file.data[0];
            commit('COMPANY_INFO', data);
            return data;
          }
        } catch (error) {
          //
        }
      }
    });
  },
  deleteImage({
    commit
  }, url) {
    return deleteFile([url]);
  },
  // 添加或更新公司信息
  createOrUpdate({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      return createOrUpdateCompany(opts).then(res => {
        commit('COMPANY_INFO', opts);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  fetchSealImage({
    commit
  }, params) {
    return fetchSeal(params).then(async res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      try {
        for (let i = 0, len = data.length; i < len; i++) {
          const it = data[i];
          const file = await getFileUrl([splitPath(it.imageUrl)]);
          it.url = file.data[0];
        }
        commit('BUSINESS_SEAL_INFO', data);
      } catch (error) {
        console.log('error:', error);
      }
    });
  },
  uploadSealImage({
    commit
  }, params) {
    return uploadSeal({
      isEnable: true,
      ...params
    });
  },
  deleteSealImage({
    commit
  }, sealIds) {
    return deleteSeal(sealIds);
  }
};
const mutations = {
  COMPANY_INFO: (state, data) => {
    const obj = {};
    // boolean to number
    for (let key in data) {
      if (data[key] == 'true') {
        obj[key] = 1;
      } else if (data[key] == 'false') obj[key] = 0;else {
        obj[key] = data[key];
      }
    }
    state.companyInfo = obj;
  },
  BUSINESS_SEAL_INFO: (state, data) => {
    state.businessInfo = data;
  },
  CHANGE_COMPANY_INFO: (state, data) => {
    const {
      type,
      val
    } = data;
    state.companyInfo[type] = val;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};