import "core-js/modules/es.array.push.js";
import { BtnLink } from '@/components/common';
import { orderReserve, orderType as OrderType } from '@/config/order';
import { PersonCount } from '@/components/common';
const TYPES_MAP = {
  hotel: '酒店',
  scenic: '景区',
  bus: '车队',
  restaurant: '餐厅'
};
export default {
  components: {
    BtnLink,
    PersonCount
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      OrderType,
      link: {
        create: '/order/reserve-input'
      },
      isSticky: true,
      columnData: orderReserve,
      tableData: [],
      progressData: []
    };
  },
  computed: {
    getStatus() {
      return progress => {
        const val = +progress;
        if (val > 75) return 'success';
        if (val > 25 & val < 50) return 'exception';
        if (val > 50 & val < 75) return 'warning';
        return 'exception';
      };
    },
    getType() {
      return type => {
        if (!type) return;
        return TYPES_MAP[type.toLowerCase()];
      };
    }
  },
  methods: {
    fetchProgressData(orderId) {
      this.$store.dispatch('order/reserve/fetchProgress', {
        orderId
      }).then(data => {
        if (!data) return;
        this.progressData = data;
      });
    },
    handleEdit(id) {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'update',
          id
        }
      });
    },
    convertOrder(id, orderType) {
      this.$parent.showCustomerDialog({
        id,
        orderType
      });
      // this.$bus.open(() => {
      //   this.$store
      //     .dispatch('order/reserve/convertOrder', { id, orderType, customerId })
      //     .then(() => {
      //       this.$parent.reloadData()
      //     })
      // }, '您确定要转换吗? 是否继续?')
    },
    cancelOrder(row) {
      const {
        id,
        isCancel
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('order/reserve/cancelOrder', {
          id,
          isCancel: Number(!isCancel)
        }).then(() => {
          this.$parent.reloadData();
        });
      }, '您确定要退单吗? 是否继续?');
    },
    orderLog(row) {
      this.$parent.showLog(row);
    }
  }
};