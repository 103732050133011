import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/guideManager/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchGuideManager(params) {
  return request({
    url: '/resource/guideManager/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateGuideManager(data) {
  return request({
    url: '/resource/guideManager/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteGuideManager(id) {
  return request({
    url: '/resource/guideManager/del',
    method: 'post',
    data: {
      id
    }
  });
}