import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableTeam from './components/TableTeam';
import { SelectProduct, SelectStaff, FooterBar, TableTripBase } from '@/components/common';
import { planNumberList } from '@/config/order';
import { getNextDate } from '@/utils';
import { remarkText } from '@/config/remark';
const deafultFormKeys = ['id', 'planNumber', 'startPlanDate', 'endPlanDate', 'productId', 'productName', 'dutyOPUserId', 'dutyOPUserName'];
export default {
  components: {
    SelectProduct,
    SelectStaff,
    FooterBar,
    TableTripBase,
    TableTeam
  },
  data() {
    return {
      remarkText,
      data: null,
      form: {},
      tableData: [],
      columnData: planNumberList,
      tableLoading: false,
      remarkList: [],
      itineraryData: [],
      sendDate: [],
      readonly: false
    };
  },
  computed: {
    isRead() {
      return this.readonly;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id,
        readonly
      } = this.$route.query;
      this.readonly = readonly && JSON.parse(readonly);
      this.tableLoading = true;
      this.$store.dispatch('order/index/fetchPlanById', {
        id
      }).then(data => {
        this.handleData(data);
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleData(data) {
      const {
        orders,
        itinerarys,
        startPlanDate
      } = data;
      this.form = this.formatForm(data);
      this.sendDate = [this.form.startPlanDate, this.form.endPlanDate];
      this.remarkList = this.formatRemark(data);
      this.itineraryData = this.formatTrip(itinerarys, startPlanDate);
      this.tableData = orders;
      this.data = data;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.sendDate = [startDate, endDate];
      this.form.startPlanDate = startDate;
      this.form.endPlanDate = endDate;
    },
    // 修改备注信息
    changeRemark(index, name, val) {
      this.remarkList[index][name] = val;
    },
    changeStartDate(val) {
      this.form.startPlanDate = val;
      this.calcTripEndDate();
    },
    changeEndDate(val) {
      this.$set(this.form, 'endTripDate', val);
    },
    selectProduct(val) {
      this.form.productId = val.value;
      this.form.productName = val.name;
    },
    selectDutyOPUser(val) {
      this.form.dutyOPUserId = val.value;
      this.form.dutyOPUserName = val.name;
    },
    formatTrip(itinerarys, startDate) {
      const list = [];
      let curDate = startDate;
      itinerarys.forEach(it => {
        curDate = getNextDate(curDate);
        const {
          rooms,
          transports,
          hotelName,
          area,
          stardand,
          dinner,
          lunch,
          breakfast
        } = it;
        const areaStr = area.indexOf('|') > -1 ? area.split('|')[0] : area;
        const item = {
          rooms,
          transports,
          hotelName,
          stardand,
          dinner,
          lunch,
          breakfast,
          area: areaStr
        };
        item.itineraryDate = curDate;
        item.itineraryDesc = it.scenics.reduce((acc, cur) => {
          acc += cur.scenicName + ' ';
          return acc;
        }, '');
        item.itineraryDesc += it.selfScenic;
        list.push(item);
      });
      return list;
    },
    formatForm(data) {
      const res = {};
      deafultFormKeys.forEach(key => {
        res[key] = data[key];
      });
      return res;
    },
    formatRemark(data) {
      const list = [];
      Object.keys(this.remarkText).forEach(key => {
        const item = {
          label: this.remarkText[key],
          name: key,
          value: data[key]
        };
        this.$set(item, key, data[key]);
        list.push(item);
      });
      return list;
    },
    formatCommitRemark(list) {
      const res = {};
      list.forEach(it => {
        res[it.name] = it.value;
      });
      return res;
    },
    // 计算结束日期
    calcTripEndDate() {
      let {
        productDays,
        productId,
        startPlanDate
      } = this.data;
      let curDate = startPlanDate;
      this.getProductDays(productId).then(() => {
        for (let i = 1; i < productDays; i++) {
          curDate = getNextDate(curDate);
          this.itineraryData[i].itineraryDate = curDate;
          // this.itineraryData[i].itineraryDesc = curDate
        }
      });
    },
    getProductDays(id) {
      return this.$store.dispatch('resource/product/fetchDetail', id);
    },
    handleUpdate() {
      const remarks = this.formatCommitRemark(this.remarkList);
      const opts = {
        ...this.form,
        ...remarks
      };
      this.$store.dispatch('plan/index/updatePlan', opts).then(() => {
        this.$bus.tip();
      });
    }
  }
};