import { SelectGuide, SelectCustom, BtnExport, FooterBar, DialogLog, SelectStaff } from '@/components/common';
import TableProxy from './components/TableProxy';
import { auditProxy } from '@/config/audit';
import { mapGetters } from 'vuex';
import { searchMixin, formMixin, auditMixin, customerInfoMixin } from '@/mixin';
import { parseDate, parseTime, downloadExport, hasCustomeAuth, filterCustomColumn } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    SelectGuide,
    SelectCustom,
    BtnExport,
    TableProxy,
    FooterBar,
    DialogLog,
    SelectStaff
  },
  mixins: [searchMixin, formMixin, auditMixin, customerInfoMixin],
  data: () => ({
    tableData: [],
    orderDate: [],
    columnData: [],
    total: 0,
    validateForm: {
      paymentCollectionTime: [{
        required: true,
        message: '日期不能为空'
      }]
    },
    multipleSelection: [],
    searchParams: {
      startTripDateS: today
    },
    statistics: null,
    auditStatus: '',
    examineState: 'examineState'
  }),
  computed: {
    ...mapGetters({
      proxyData: 'audit/index/proxyData',
      userData: 'user/userData'
    })
  },
  watch: {
    proxyData: {
      handler: 'initData',
      immediate: true
    },
    tableData: {
      handler: 'formatCheckTime',
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? auditProxy : filterCustomColumn(auditProxy);
  },
  methods: {
    formatCheckTime(data) {
      data.forEach(it => {
        if (it.checkAccountStatus && !it.paymentCollectionTime) {
          let myDate = new Date();
          it.paymentCollectionTime = parseTime(myDate);
        }
        return it;
      });
    },
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: this.getToday
        }); //{ startTripDateS: this.getToday }
        return;
      }
      this.handleData(data);
    },
    fetchData(params) {
      this.$store.dispatch('audit/index/fetchProxy', params);
    },
    // 日志
    handleShowLog(row) {
      const {
        id,
        planId
      } = row;
      this.$store.dispatch('audit/index/fetchLog', {
        itemId: planId,
        subItemId: id,
        subType: 'collect'
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    selectAuditStatus(val) {
      this.searchParams.auditStatus = val ? val.value + ' ' : '';
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/index/getCollectListExcel', this.searchParams).then(res => {
        const fileName = '代收审核.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics;
      this.tableData = list.map(it => {
        let {
          paymentCollectionTime,
          auditProgress,
          isAgencyCollect,
          isGuideCollect,
          total
        } = it;
        it.isCheck = true;
        if (auditProgress == 'WAITAUDIT' || auditProgress == 'OPAUDITED') {
          it.isCheck = false;
        }
        if (paymentCollectionTime) {
          it.isCheck ? it.paymentCollectionTime = parseTime(paymentCollectionTime) : it.paymentCollectionTime = '';
        }
        if (isAgencyCollect) {
          it.collection = total;
        }
        if (isGuideCollect) {
          it.guideCollection = total;
        }
        return it;
      });
      this.checkList = this.tableData.filter(it => it.isCheck);
    },
    // 选择日期
    changeStartTripDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.startTripDateS = startDate || '';
      this.searchParams.startTripDateE = endDate || '';
    },
    changeEndTripDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.endTripDateS = startDate || '';
      this.searchParams.endTripDateE = endDate || '';
    },
    changeOrderDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.orderDateS = startDate || '';
      this.searchParams.orderDateE = endDate || '';
    },
    // 选择导游/客服/客户
    select(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Id'] = value;
      this.searchParams[type + 'Label'] = label;
    },
    // 处理全选
    formatCheck(status) {
      const checkAllList = this.tableData.filter(it => it.isCheck || it.checkAccountStatus);
      if (checkAllList.length === this.tableData.length) {
        this.tableData = this.tableData.map(it => {
          it.checkAccountStatus = status;
          return it;
        });
      } else {
        this.tableData = this.tableData.map(it => {
          it.checkAccountStatus = status;
          return it;
        });
      }
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      // this.listQuery.pageNum = pageNum
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    handleCreate() {
      const checkList = this.tableData.filter(it => it.checkAccountStatus);
      if (!checkList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选中数据!'
        });
        return false;
      }
      const dataList = this.formatList(checkList, true);
      const paymentTime = dataList.map(it => {
        if (!it.paymentCollectionTime) {
          this.$bus.tip({
            type: 'warning',
            message: `选中的数据,日期不能为空!`
          });
          return Promise.reject('ERROR');
        }
        return it.paymentCollectionTime;
      });
      const loading = this.$bus.loading();
      Promise.all(paymentTime).then(() => {
        loading.close();
        this.$bus.open(() => {
          this.handleSubmit(dataList);
        }, `是否确认审核 选中的订单?`);
      }).catch(() => {
        loading.close();
      });
    },
    // 审核（单条）
    handleCheckout(row) {
      const {
        paymentCollectionTime
      } = row;
      if (!paymentCollectionTime) {
        this.$bus.tip({
          type: 'warning',
          message: '日期不能为空！'
        });
        return false;
      }
      this.$bus.open(() => {
        const list = this.formatList([row], true);
        this.handleSubmit(list);
      }, `是否确认审核?`);
    },
    // 取消审核
    handleCancel(row) {
      this.$bus.open(() => {
        const list = this.formatList([row], false);
        this.handleSubmit(list);
      }, `是否确认取消审核?`);
    },
    formatList(list, status) {
      const items = list.map(it => {
        let items = {
          auditTypeEnum: 'COLLECT',
          planId: it.planId,
          resourceId: it.id,
          isCollectMenu: true,
          paymentCollectionTime: it.paymentCollectionTime,
          status,
          auditProgress: it.auditProgress
        };
        if (this.isAdminAudit) {
          items.auditProgress = 'OPAUDITED';
        }
        return items;
      });
      return items;
    },
    handleSubmit(dataList) {
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/index/updateAuditOne', dataList).then(() => {
        loading.close();
        // 刷新列表数据
        this.fetchData(this.searchParams);
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};