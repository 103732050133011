var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    ref: "tableRef",
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "row-class-name": _vm.tableRowClassName,
      sticky: _vm.isSticky,
      "hide-bar": true,
      "show-summary": "",
      border: ""
    },
    on: {
      "row-click": _vm.tableCellClick
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.linkDetail(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planCustomerNumber ? scope.row.planCustomerNumber : "--") + " ")])], 1)];
      }
    }, {
      key: "customerTripNumber",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff"
          }
        }, [_vm._v(_vm._s(scope.row.customerTripNumber ? scope.row.customerTripNumber : "--"))])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onItineraryDate(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.startTripDate ? scope.row.startTripDate : "--") + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate ? scope.row.endTripDate : "--") + " ")])];
      }
    }, {
      key: "productName",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onItineraryDate(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.productName) + " ")])];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onCount(scope);
            }
          }
        }, [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })], 1)];
      }
    }, {
      key: "guide",
      fn: function ({
        scope
      }) {
        return [_c("Guides", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onTourist(scope);
            }
          }
        }, [scope.row.members.length ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 5
          }
        })] : [_vm._v(" -- ")]], 2)];
      }
    }, {
      key: "shuttles",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onShuttle(scope);
            }
          }
        }, [scope.row.shuttles && scope.row.shuttles.length ? _vm._l(scope.row.shuttles, function (item) {
          return _c("div", {
            key: item.id
          }, [_c("span", [_vm._v(_vm._s(_vm.getShuttleType(item)) + " ")]), _c("span", [_vm._v(_vm._s(item.shiftDateTime))]), _c("br"), _c("span", [_vm._v(_vm._s(item.desPosition))]), _vm._v("    "), _c("span", [_vm._v(_vm._s(_vm.getTaskType(item)) + _vm._s(item.shiftNumber))])]);
        }) : [_vm._v(" -- ")]], 2)];
      }
    }, {
      key: "hotels",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onHotelShow(scope);
            }
          }
        }, [scope.row.hotels && scope.row.hotels.length ? [_vm._l(scope.row.hotels, function (item, index) {
          return [item ? _c("div", {
            key: index
          }, [item.hotelName ? _c("span", [_vm._v(_vm._s(item.hotelName))]) : _c("span", [_vm._v(" " + _vm._s(item.hotelArea ? item.hotelArea + "/" : "") + _vm._s(item.hotelStandard) + " ")])]) : _vm._e()];
        })] : [_vm._v(" -- ")]], 2)];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onCustomShow(scope.row);
            }
          }
        }, [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })], 1)];
      }
    }, {
      key: "feeStr",
      fn: function ({
        scope
      }) {
        return [scope.row.feeStr ? _c("div", {
          domProps: {
            innerHTML: _vm._s(scope.row.feeStr)
          }
        }) : _vm._e()];
      }
    }, {
      key: "totalIncome",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalIncome) + " ")])];
      }
    }, {
      key: "totalCollect",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalCollect) + " ")])];
      }
    }, {
      key: "totalIncomed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalIncomed) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "pointer",
          on: {
            click: function ($event) {
              return _vm.onFundDialog(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSubsidy) + " ")])];
      }
    }, {
      key: "unCollect",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.unCollect) + " ")];
      }
    }, {
      key: "orderType",
      fn: function ({
        scope
      }) {
        return [_c("TypeOrder", {
          attrs: {
            type: scope.row.orderType
          }
        })];
      }
    }, {
      key: "orderStatus",
      fn: function ({
        scope
      }) {
        return [_vm.orderAuditStatus(scope.row) ? _c("div", {
          staticStyle: {
            cursor: "pointer"
          }
        }, [_c("el-tag", {
          attrs: {
            type: "info"
          },
          on: {
            click: function ($event) {
              return _vm.linkAuditDetail(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.reviewerStatus[scope.row.addAuditStatus]) + " ")])], 1) : _vm._e(), _c("StatusOrderTeam", {
          attrs: {
            "plan-id": scope.row.planId
          }
        }), scope.row.checkAccountStatus ? _c("div", [_c("el-tag", [_vm._v("已对账")])], 1) : _c("div", [_c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v(" 未对账 ")])], 1), scope.row.confirmedItemFilePath ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showConfirmFile(scope.row);
            }
          }
        }, [_vm._v(" 确认件 ")]) : _vm._e(), _vm.hasOutOrder(scope.row) ? [_vm.getOutStatus(scope.row.outOrderCountInfo) ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v(" 已外转 " + _vm._s(scope.row.outOrderCountInfo.outOnLineAcceptCount) + " ")]) : _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("已外转")])] : _vm._e()];
      }
    }, {
      key: "auditProgress",
      fn: function ({
        scope
      }) {
        return [_c("StatusProgress", {
          attrs: {
            status: scope.row.auditProgress
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLinkBase", {
          attrs: {
            "btn-type": "detail"
          },
          on: {
            click: function ($event) {
              return _vm.linkDetail(scope);
            }
          }
        }, [_vm._v(" 查看 ")]), _vm.comparePlanStatus(scope.row) ? _c("BtnLinkBase", {
          attrs: {
            "btn-type": "edit"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]) : _c("el-button", {
          attrs: {
            type: "text",
            disabled: ""
          }
        }, [_vm._v(" 已归档 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handlePrint(scope);
            }
          }
        }, [_vm._v(" 打印 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpload(scope.row);
            }
          }
        }, [_vm._v(" 上传确认件 ")])];
      }
    }])
  }), _c("DialogCustom", {
    ref: "customRef"
  }), _c("DialogCount", {
    ref: "countRef"
  }), _c("DialogTableTourist", {
    ref: "tableTouristRef"
  }), _c("DialogGroupFund", {
    ref: "groupFundRef"
  }), _c("DialogItineraryDate", {
    ref: "itineraryDateRef",
    attrs: {
      "order-type": _vm.orderType
    }
  }), _c("DialogTablePickup", {
    ref: "tablePickupRef"
  }), _c("DialogTableHotel", {
    ref: "tablehotelRef"
  }), _c("DialogUploadFile", {
    ref: "uploadFileRef",
    on: {
      success: _vm.uploadConfirmFile
    }
  }), _c("DialogUploadFilePreview", {
    ref: "filePreviewRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };