import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 基础数字输入框组件
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直布局排列
 * @vue-prop {Boolean} [isRequire=false] - 是否是必填项
 * @vue-data {String} input - 输入框的值
 * @vue-event {String} handleChange - Emit 输入框选中后，触发 pick事件，返回选中的item对象
 */
export default {
  name: 'InputNumber',
  mixins: [inputMixin],
  inheritAttrs: false
};