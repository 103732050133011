var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "flex-1 h-full profit-item"
  }, [_c("div", {
    staticClass: "h-title"
  }, [_c("div", [_vm._v(_vm._s(_vm.data.title))]), _c("div", {
    staticClass: "mt-20"
  }, [_vm._v(" " + _vm._s(_vm.data.data.tagTotal) + " ")])]), _c("div", {
    staticClass: "pie-box"
  }, [_c("PieChart", {
    attrs: {
      option: _vm.data.option
    }
  }), _c("div", {
    staticClass: "pie-percent"
  }, [_c("div", {
    staticClass: "flex flex-center"
  }, [_vm._v(" " + _vm._s(_vm.data.data.percent) + " "), _c("span", {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("%")])]), _c("div", {
    staticClass: "flex flex-center mt10 red"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.data.data.unCompleted))])])])], 1), _c("div", {
    staticClass: "profit-item__foot"
  }, [_c("div", {
    staticClass: "flex flex-center profit-money"
  }, [_c("BorderItem", {
    attrs: {
      val: _vm.data.data.all
    }
  }), _c("div", {
    staticClass: "suffix"
  }, [_vm._v(" " + _vm._s(_vm.data.suffix) + " ")])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };