import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import SearchUnPay from '../components/SearchUnPay';
import TableUnPay from './TableUnPay';
import { FooterBar, SelectShop, BillInput } from '@/components/common';
import { TYPES, CashFeeType } from '@/config/billDown';
import { RebateType } from '@/config/shopping';
import { tagViewMixin } from '@/mixin';
import { formatShopDownData } from '@/views/billDown/help';
export default {
  components: {
    FooterBar,
    SelectShop,
    BillInput,
    SearchUnPay,
    TableUnPay
  },
  mixins: [tagViewMixin],
  data: () => ({
    TYPES,
    CashFeeType,
    resourceId: '',
    backPath: '/shopping/shop-collect',
    backPathBill: '/bill/unit-collect/shop',
    exportLoading: false,
    total: 0
    // listQuery: DEFAULT_PAGE,
    // tableData: [],
    // columnData: shoppingCollectColumn,
  }),
  computed: {
    getResourceId() {
      return this.resourceId;
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$refs.tableRef.fetchData({
        ...params,
        resourceId: this.resourceId
      });
    },
    fetchBalance() {
      return this.$store.dispatch('bill/billDown/collect/fetchCollectBalance', {
        rid: this.resourceId,
        cashType: TYPES.shop
      });
    },
    changeMoney(money) {
      this.$refs.tableRef.handleDownMoney(money);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.tableRef.fetchData({
        resourceId
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const list = this.$refs.tableRef.tableData;
        const newList = list.filter(it => it[RebateType.agency] != 0 || it[RebateType.member] != 0 || it[RebateType.redbag] != 0).reduce((acc, cur) => {
          if (cur[RebateType.member] != '' && cur[RebateType.member] != 0) {
            acc.push(cur);
          }
          if (cur[RebateType.agency] != '' && cur[RebateType.agency] != 0) {
            acc.push(cur);
          }
          if (cur[RebateType.redbag] != '' && cur[RebateType.redbag] != 0) {
            acc.push(cur);
          }
          return acc;
        }, []);
        const opts = formatShopDownData({
          list: newList,
          form,
          cashAction: CashFeeType.INCOME
        });
        this.$store.dispatch('bill/billDown/collect/addCollect', {
          ...opts,
          cashType: TYPES.shop
        }).then(() => {
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        });
      });
    },
    // 返回列表
    goBack() {
      const path = this.$route.path.indexOf('bill') > -1 ? this.backPathBill : this.backPath;
      this.$router.push({
        path,
        query: {
          isBack: true
        }
      });
    }
  }
};