import { FormAccount, FormSource
// DropzoneBox,
} from '@/components/common';
import { mapGetters } from 'vuex';
export default {
  components: {
    FormAccount,
    FormSource
    // DropzoneBox,
  },
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {},
      index: -1
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      imageList: 'resource/common/imageList',
      editSourceInfo: 'resource/common/editSourceInfo',
      accountForm: 'resource/account/editAccountInfo'
    }),
    getTitle() {
      return this.isUpdate ? '更新公司' : '添加公司';
    }
  },
  methods: {
    show(scope) {
      if (scope) {
        this.index = scope.$index;
        const row = scope.row;
        this.form = row;
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
      this.visible = true;
      const row = scope ? scope.row : {
        isEnable: true
      };
      this.$store.dispatch('resource/common/editResource', row);
      this.$store.dispatch('resource/account/editAccount', this.form);
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    formatImage() {
      if (this.imageList.length) {
        return this.imageList.map(it => {
          return it.path;
        });
      }
      return [];
    },
    handleCreate() {
      const {
        id,
        groupId,
        travelAgencyId,
        realName,
        createUserId
      } = this.userData;
      const userData = {
        id,
        groupId,
        travelAgencyId,
        realName,
        createUserId
      };
      // const picPaths = this.formatImage()

      const opts = {
        ...this.form,
        ...userData,
        ...this.editSourceInfo,
        ...this.accountForm,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/company/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        loading.close();
        this.hide();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};