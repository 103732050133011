import { tableMixin } from '@/mixin';
import { BtnLink } from '@/components/common';
import { REVIEW, APPLY } from '@/config';
import { TYPES as OrderType } from '@/config/order';
import { showPay, getStatus, getStatusType, isWait, isCancel, getApplyText, showCancelApplay, showCancelConfirmApplay } from '../status';
export default {
  components: {
    BtnLink
  },
  mixins: [tableMixin],
  data() {
    return {};
  },
  computed: {
    showPay,
    getStatus,
    getStatusType,
    isWait,
    isCancel,
    getApplyText,
    showCancelApplay,
    showCancelConfirmApplay
  },
  methods: {
    // 查看详情
    handleDetail(row) {
      this.$parent.showDetail(row);
    },
    // 修改申请
    handleApply(row) {
      this.$parent.editDetail(row);
    },
    // 确认订单
    handleConfirm(row) {
      this.$parent.confirmDetail(row);
    },
    reloadData() {
      this.$bus.tip();
      this.$parent.initData();
    },
    // 取消申请
    cancelApply(row) {
      this.$store.dispatch('order/resource/bookingToggleApply', {
        id: row.rowId,
        status: APPLY.pass,
        orderType: OrderType.scenic.toUpperCase()
      }).then(() => {
        this.reloadData();
      });
    },
    // 确认申请
    confirmReviewer(row) {
      this.$store.dispatch('order/resource/bookingToggleReview', {
        id: row.rowId,
        status: REVIEW.pass
      }).then(() => {
        this.reloadData();
      });
    },
    // 删除
    handleDelete(row) {
      this.$bus.open(() => {
        const {
          rowId: id
        } = row;
        this.$store.dispatch('order/resource/bookingDelete', {
          id,
          orderType: OrderType.scenic.toUpperCase()
        }).then(() => {
          this.reloadData();
        });
      }, '确定要删除吗？');
    },
    // 付款
    handlePay(row) {
      this.$parent.showPayDialog(row);
    },
    handleLog(row) {
      const {
        customerOrderId: itemId
      } = row;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId,
        subType: 'companyHotel'
      }).then(res => {
        this.$parent.showLog(res);
      });
    }
  }
};