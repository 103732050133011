export default {
  data() {
    return {
      tips: [{
        type: 'warning',
        color: '#fff',
        bg: '#E6A23C',
        text: '橙色表示确认了计划'
      }, {
        type: 'success',
        color: '#fff',
        bg: '#67C23A',
        text: '绿色表示确认了安排'
      }, {
        type: 'danger',
        color: '#fff',
        bg: '#F56C6C',
        text: '红色表示计划有修改'
      }, {
        type: 'info',
        color: '#fff',
        bg: '#909399',
        text: '灰色表示计划取消中或已取消'
      }]
    };
  }
};