import TopTitle from '../components/TopTitle';
const tableHead = [{
  content: 'name',
  label: '姓名',
  span: 2
}, {
  content: 'cardType',
  label: '证件类型',
  span: 2,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号码',
  span: 3
}, {
  content: 'mobilePhone',
  label: '手机号码',
  span: 2
}, {
  content: 'sex',
  label: '性别',
  span: 1,
  slot: 'sex'
}, {
  content: 'birthday',
  label: '出生年月日',
  span: 2
}];
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      data: null,
      tableHead
    };
  },
  computed: {
    getCardType() {
      return val => {
        return val == '0' ? '身份证' : val == '1' ? '护照' : '学生证';
      };
    },
    getSex() {
      return val => {
        return val == 0 ? '男' : '女';
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchTourist', {
        planId: id
      }).then(data => {
        this.data = data;
        this.$emit('onMounted');
      });
    }
  }
};