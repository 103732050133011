var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "orders",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };