// 可用值:ADD_SCENIC,SHUTTLE,FEE,COLLECT,SUBSIDY,ORDER_OUT_GUIDE_PAY,OUT_FEE,OUT_COLLECT,OUT_SUBSIDY,INNER_FEE,INNER_COLLECT,SHOP,HOTEL,BUS_COMPANY,RESTAURANT,VISA,INSURANCE,SCENIC,TRANSPORT,GUIDE,OTHER_INCOME,OTHER_PAY,PLAN_GUIDE_HANDBACK,GUIDE_BORROW,GUIDE_BORROW_APPLY

import BaseInfo from './components/BaseInfo';
import Guide from './Guide/index';
import GuideProxy from './Guide/GuideProxy';
import GuideBorrow from './Guide/GuideBorrow';
import Scenic from './Scenic/index';
import ExtraScenic from './Scenic/ExtraScenic';
import Hotel from './Hotel/index';
import Restaurant from './Restaurant/index';
import Fleet from './Fleet/index';
import Insurance from './Insurance/index';
import Traffic from './Traffic/index';
import Pickup from './Pickup/index';
import Shopping from './Shopping/index';
import OtherIncome from './OtherIncome/index';
import OtherPay from './OtherPay/index';
import TeamFund from './TeamFund';
import Reception from './Reception';
import { DialogTourist, TableTotalProfit, FormRemarks } from '@/components/common';
import { FooterBar, DialogLog, DetailPlanTrip } from '@/components/common';
export default {
  components: {
    Guide,
    GuideProxy,
    GuideBorrow,
    Scenic,
    ExtraScenic,
    Hotel,
    Restaurant,
    Fleet,
    Insurance,
    Traffic,
    Pickup,
    Shopping,
    OtherIncome,
    OtherPay,
    Reception,
    TeamFund,
    DialogTourist,
    DialogLog,
    FooterBar,
    BaseInfo,
    DetailPlanTrip,
    TableTotalProfit,
    FormRemarks
  }
};