var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-profit-tabel"
  }, [_c("table", {
    staticClass: "profit-total-tabel"
  }, [_c("tr", [_c("th", {
    staticClass: "red"
  }, [_vm._v(" 毛利 ")]), _c("td", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.totalQuoteGrossProfit) + " ")]), _c("th", {
    staticClass: "red"
  }, [_vm._v(" 人均毛利 ")]), _c("td", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.totalQuoteGrossMargin) + " ")]), _c("th", {
    staticClass: "red"
  }, [_vm._v(" 毛利率 ")]), _c("td", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.totalGrossProfitRate) + "% ")]), _c("th", [_vm._v("总现付")]), _c("td", [_vm._v(" " + _vm._s(_vm.guideReimbTotalInfo ? _vm.guideReimbTotalInfo.totalGuidePay : 0) + " ")]), _c("th", [_vm._v("总应报")]), _c("td", [_vm._v(" " + _vm._s(_vm.guideReimbTotalInfo ? _vm.guideReimbTotalInfo.travelAgencyPay : 0) + " ")]), _c("th", [_vm._v("总已报")]), _c("td", [_vm._v(" " + _vm._s(_vm.guideReimbTotalInfo ? _vm.guideReimbTotalInfo.travelAgencyPayed : 0) + " ")])])]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: false,
      dynamic: false,
      "header-cell-class-name": "table-team-profit"
    },
    scopedSlots: _vm._u([{
      key: "incomeHeader",
      fn: function ({}) {
        return [_vm._v(" 总收入 "), _c("span", {
          staticClass: "red ml20"
        }, [_vm._v(" " + _vm._s(_vm.totalIncome))])];
      }
    }, {
      key: "payHeader",
      fn: function ({}) {
        return [_vm._v(" 总支出 "), _c("span", {
          staticClass: "red ml20"
        }, [_vm._v(" " + _vm._s(_vm.totalQuotePay))])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };