import { auditCustomRefund } from '@/config/audit';
import { Audit, AuditAdmin, AuditCheck, PersonCount, Members, AuditRoleAll } from '@/components/common';
import { StatusAudit, StatusCheck, CustomerInfo } from '@/components/common';
import { typeMixin, rowspanMixin, auditMixin } from '@/mixin';
import { findComponentsUpward, hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  components: {
    Audit,
    AuditAdmin,
    AuditCheck,
    AuditRoleAll,
    PersonCount,
    Members,
    StatusAudit,
    StatusCheck,
    CustomerInfo
  },
  mixins: [typeMixin, rowspanMixin, auditMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      columnData: [],
      headMergeNum: 7,
      tailMergeNum: auditCustomRefund.length - 1,
      hasMergeTail: true,
      statisticsKeys: ['totalMoney'],
      auditAll: false
    };
  },
  watch: {
    tableData: {
      handler: function () {
        this.$nextTick(() => {
          this.rowspan();
        });
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    const len = auditCustomRefund.length;
    this.tailMergeNum = visibleCustom ? len - 1 : len - 2;
    this.columnData = visibleCustom ? auditCustomRefund : filterCustomColumn(auditCustomRefund);
  },
  methods: {
    handleEdit(rowNum) {
      const findComp = findComponentsUpward(this, 'PlanTeamFund')[0];
      findComp.showEditDialog({
        rowNum,
        index: 2
      });
    },
    checkDeduct(row, val) {
      const {
        rowId: id
      } = row;
      const status = val ? 1 : 0;
      this.$store.dispatch('order/index/unDeductSubsidy', {
        id,
        status
      }).then(() => {
        this.$bus.tip();
        const findComp = findComponentsUpward(this, 'PlanTeamFund')[0];
        findComp.reloadData();
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list;
    },
    validateForm() {
      return true;
    },
    handleRowLog() {
      const findComp = findComponentsUpward(this, 'Reception')[0];
      findComp.handleRowLog('subsidy');
    }
  }
};