import { CustomerType } from '@/config';
import { inputMixin, selectListMixin } from '@/mixin';
import { formatDepartmentParams } from '@/utils';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    fetchPath: 'resource/custom/fetchOutList',
    params: {
      type: CustomerType.CO
    }
  }),
  computed: {
    data() {
      const data = this.$store.getters['resource/custom/customOutData'];
      return data;
    },
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  watch: {
    roleInfo: {
      handler: function (data) {
        this.params = formatDepartmentParams(data);
      }
    }
  }
};