import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data: () => ({
    fetchPath: 'plan/index/getOrderByPlanId',
    curVal: ''
  }),
  computed: {
    data() {
      return this.$store.getters['plan/index/planOrderData'];
    },
    mutiplyVal() {
      return {
        val: this.value,
        list: this.data
      };
    }
  },
  watch: {
    mutiplyVal: {
      handler: function (data) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (!data.val || !data.list) return;
            const findItem = data.list.find(it => it.id == data.val);
            this.curVal = findItem ? findItem.label : '';
          }, 100);
        });
      },
      immediate: true
    }
  }
};