var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "source-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "购物店名称",
      "is-require": "",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "hotelRegionType",
      label: "地区",
      "is-require": ""
    },
    on: {
      "on-select": _vm.onSelectArea
    },
    model: {
      value: _vm.form.area,
      callback: function ($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.visible ? _c("SelectDicType", {
    attrs: {
      clearable: "",
      code: "shop",
      list: _vm.shopDicData,
      label: "旅监购物店名称"
    },
    on: {
      "visible-change": _vm.beforeSelectDicType
    },
    nativeOn: {
      input: function ($event) {
        return _vm.filterData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.glETravelShopId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "glETravelShopId", $$v);
      },
      expression: "form.glETravelShopId"
    }
  }) : _vm._e(), _c("SelectBase", {
    attrs: {
      label: "购物店类型",
      type: "shopType"
    },
    on: {
      "on-select": _vm.selectShopType
    },
    model: {
      value: _vm.form.shopTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopTypeLabel", $$v);
      },
      expression: "form.shopTypeLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "目标人均"
    },
    model: {
      value: _vm.form.perCapita,
      callback: function ($$v) {
        _vm.$set(_vm.form, "perCapita", $$v);
      },
      expression: "form.perCapita"
    }
  }), _c("InputBase", {
    attrs: {
      label: "返款比例(%)"
    },
    model: {
      value: _vm.form.subsidyRate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subsidyRate", $$v);
      },
      expression: "form.subsidyRate"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "联系人",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.contact"
    }
  }), _c("InputBase", {
    attrs: {
      label: "手机号码",
      maxlength: "11",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "座机号码",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.telphone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "传真号码",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.faxNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    attrs: {
      label: "启用状态"
    },
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  }), _c("SwitchStatus", {
    attrs: {
      label: "导出状态"
    },
    model: {
      value: _vm.form.isExport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isExport", $$v);
      },
      expression: "form.isExport"
    }
  })], 1)]), _c("FormAccount")], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };