var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "车队报价历史",
      fullscreen: "",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      "show-page": false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        scope
      }) {
        return [_c("el-link", {
          attrs: {
            type: scope.row.$inquiryType
          }
        }, [_vm._v(" " + _vm._s(scope.row.$inquiryStatus) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.arriveDate))]), _c("div", [_vm._v(_vm._s(scope.row.leaveDate))]), _c("div", [_vm._v(_vm._s(scope.row.shiftNumber))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " 大 " + _vm._s(scope.row.childCount) + " 小 " + _vm._s(scope.row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "itinerary",
      fn: function ({
        scope
      }) {
        return _vm._l(_vm.getItinerary(scope.row.itinerary), function (val, index) {
          return _c("div", {
            key: index,
            staticClass: "tal ml10"
          }, [_vm._v(" " + _vm._s(val) + " ")]);
        });
      }
    }, {
      key: "inquiryUser",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.inquiryUser))]), _c("div", [_vm._v(_vm._s(_vm.getInquireDate(scope.row)))])];
      }
    }, {
      key: "quotationUser",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.quotationUser))]), _c("div", [_vm._v(_vm._s(_vm.getQuotaDate(scope.row)))])];
      }
    }, {
      key: "resDate",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.responseTime))])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.showDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };