import request from '@/utils/request';
// import axios from 'axios'
// import { getTravelData } from '@/utils/cookie'

// const service = axios.create({
//   baseURL: process.env.VUE_APP_BASE_API,
//   withCredentials: true,
// })

// service.interceptors.request.use(
//   config => {
//     // 如果传了menuId则不修改
//     config.headers = Object.assign(config.headers || {}, {
//       Authorization: 'Bearer' + getTravelData().token.replace('bearer', ''),
//     })

//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// 获取旅监电子行程单数据(第二版本) 进入点子行程单界面
export function requestToken(params) {
  return request({
    url: '/order/plan/entry-e-travel-platform',
    method: 'get',
    params
  });
}

// 获取资源
export function requestGet(params) {
  return request({
    url: '/order/plan/create-e-travel-platform-get',
    method: 'get',
    params
  });
}

// 保存
export function requestSave(data) {
  return request({
    url: '/order/plan/create-e-travel-platform',
    method: 'post',
    data
  });
}

// 旅监接口信息上传
export function setUploadStatus(data) {
  return request({
    url: 'order/plan/set-e-travel-platform-info',
    method: 'post',
    data
  });
}

// // 获取旅监电子行程单数据
// export function fetchTravelPlatform(params) {
//   return request({
//     url: '/order/plan/get-e-travel-platform',
//     method: 'get',
//     params,
//   })
// }

// // 获取电子行程单 进入点子行程单界面
// export function fetchTravelPlatformPlan(params) {
//   return request({
//     url: '/order/plan/get-e-travel-platform-plan',
//     method: 'get',
//     params,
//   })
// }

// // 上传创建旅监
// export function createTravelPlatform(data) {
//   return request({
//     url: '/order/plan/create-e-travel-platform',
//     method: 'post',
//     data,
//   })
// }

// // 更新编辑旅监信息
// export function updateTravelPlatform(data) {
//   return request({
//     url: '/order/plan/edit-e-travel-platform',
//     method: 'post',
//     data,
//   })
// }

// // 旅监接口
// export function getTravelSuperviseeDic(data) {
//   return request({
//     url: '/resource/e-travel-platform/gl',
//     method: 'post',
//     data,
//   })
// }

// // 旅监接口（获取导游）
// export function getTravelGuide(params) {
//   return request({
//     url: '/order/plan/get-e-travel-platform-guide',
//     method: 'get',
//     params,
//   })
// }