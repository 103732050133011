import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, DelCheck, DelCheckAll, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import { planEditRestaurant } from '@/config/plan';
import { StatusAudit, SelectPayType, SelectRestaurant, SelectDictionary, SelectPlanNumber } from '@/components/common';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import { itemListKeys, defaultState } from './config';
import DialogFix from './DialogFix';
export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    TopBar,
    SelectPlanNumber,
    DelCheck,
    DelCheckAll,
    StatusAudit,
    SelectPayType,
    SelectRestaurant,
    DialogOrder,
    PaymentTotal,
    AuditRoleAll,
    DialogList,
    SelectDictionary,
    AuditStatus,
    CheckStatus,
    DialogFix
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'restaurantOrderStatus',
      deletePath: 'plan/restaurant/delete',
      updatePath: 'plan/restaurant/update',
      fetchPath: 'plan/restaurant/fetch',
      headMergeNum: 5,
      tailMergeNum: planEditRestaurant.length - 7,
      hasMergeTail: true,
      rowId: 1000000000000,
      auditAll: false,
      auditProgress: 'WAITAUDIT'
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      planOrderData: 'plan/index/planOrderData'
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    enterpriseFixDialog(row, column) {
      if (column.property == 'rowTotal' && row.alipayPeerPayAmount != 0 && row.alipayPeerPayAmount != row.totalPay) {
        this.$refs.dialogFixRef.show({
          ...row
        });
      }
    },
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.$nextTick(() => {
        // 是否显示订单信息
        const isOrderInfo = this.hasOrderInfo;
        // 如果不显示订单信息
        if (!isOrderInfo) {
          this.headMergeNum = isOrderInfo ? 5 : 4;
          this.tailMergeNum = planEditRestaurant.length - 5;
        }
        this.resetData();
      });
    },
    // 选择资源
    handleSelect(row, val) {
      row.restaurantId = val.value;
      row.restaurantName = val.label;
      row.area = val.area;
    },
    onSelectArea(row, val) {
      row.area = val.label;
    },
    showNotice() {
      this.$bus.tip({
        type: 'warning',
        message: '该餐厅还未添加任何餐标协议'
      });
    },
    selectType(row, val) {
      // mealLabelPrice
      const {
        label,
        mealLabelPrice
      } = val;
      row.restaurantType = label;
      row.price = mealLabelPrice;
      row.totalPay = this.calcRowMoney(row);
      this.mergeRowMoney(); // 合计单条数据金额
    },
    // TODO 旧版选择餐厅协议 目前作废
    beforeSelectType(row, val) {
      if (!val) return;
      if (!row.restaurantId) {
        this.$bus.tip({
          type: 'warning',
          message: '请先选择餐厅！'
        });
      } else {
        if (!row.agreementList || !row.agreementList.length) {
          this.$store.dispatch('resource/restaurant/fetchAgreementTypeList', row.restaurantId).then(data => {
            if (!data) return;
            const {
              contractPrices
            } = data[0];
            const roomList = this.formatRoomOptions(contractPrices);
            if (!roomList.length) {
              this.showNotice();
              return;
            }
            row.agreementList = roomList;
          });
        }
      }
    },
    formatRoomOptions(list) {
      if (!list) return;
      return list.map(it => {
        return {
          label: it.contentType,
          id: it.id,
          value: it.id,
          quotePrice: it.quotedPrice,
          price: it.price
        };
      });
    },
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/restaurant/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          isCustom: true,
          isSelect: false,
          itemIndex: ++index
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.resetData();
      row.totalPay = this.calcRowMoney(row);
      this.mergeRowMoney(); // 合计单条数据金额
    },
    // 新增一条景区
    handlePlus() {
      const firstPlanOrder = this.planOrderData && this.planOrderData[0];
      const item = {
        ...defaultState(),
        customerOrderId: firstPlanOrder.id || -1,
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: this.planInfo.adultCount || 1
        // agreementList: [],
      };
      this.tableData.push(item);
      this.getTotalPayMoney();
      this.resetData();
    },
    // 计算总额
    calcRowMoney(row) {
      const unit = 100;
      let {
        price,
        count,
        freeCount,
        otherFee
      } = row;
      let total = (count - freeCount) * (price * unit) + otherFee * unit;
      return total / unit;
    },
    // 校验输入的值
    changeInput(item) {
      item.totalPay = this.calcRowMoney(item);
      // this.getTotalPayMoney()
      this.mergeRowMoney(); // 合计单条数据金额
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const children = it.respOrderRestaurantTypes || [];
        const {
          id,
          auditProgress,
          remarks
        } = it;
        const item = {
          ...it,
          rowId: id,
          restaurantName: it.restaurant ? it.restaurant.name : '',
          // 餐厅新增地区新版
          planRestaurantId: id,
          isCustom: false,
          isChecked: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          payType: it.payType.toLowerCase(),
          mealType: it.mealType.toLowerCase(),
          price: '',
          rowTotal: Number(it.totalPay),
          totalPay: Number(it.totalPay)
        };
        if (children.length) {
          children.forEach((v, i) => {
            const totalSum = this.calcRowMoney(v);
            result.push({
              ...item,
              ...v,
              remarks,
              itemIndex: i,
              totalSum
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex'];
      let last = null;
      const result = [];
      list.forEach(it => {
        const item = {};
        // 还原子项id，在format的时候，会覆盖父级的id
        // !it.isCustom ? (item.id = it.id) : null
        // 如果不是
        if (!it.isCustom && it.respOrderRestaurantTypes.length) {
          item.id = it.id;
        }
        it.restaurantTypes = [];
        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        let temp = item.restaurantName;
        item.restaurantName = item.restaurantId;
        item.restaurantId = temp;
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.rowId;
        it.payType = it.payType.toUpperCase();
        it.mealType = it.mealType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete item.id;
          delete it.planRestaurantId;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        if (last && last.rowId == it.rowId) {
          last.restaurantTypes.push(item);
        } else {
          it.restaurantTypes.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i]; //restaurantType,
        const {
          restaurantName,
          orderDate,
          price,
          count
        } = row;
        if (restaurantName) {
          if (!orderDate) {
            this.$bus.tip({
              type: 'warning',
              message: '请选择用餐日期！'
            });
            return false;
          }
          if (price === '') {
            this.$bus.tip({
              type: 'warning',
              message: '请填写用餐价格！'
            });
            return false;
          }
          if (count === '') {
            this.$bus.tip({
              type: 'warning',
              message: '请填写用餐数量！'
            });
            return false;
          }
        }
      }
      return true;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowMoney(it);
        this.$set(it, 'totalPay', totalPay);
      });
    },
    resetData() {
      this.rowspan();
      this.mergeRowMoney(false); // 合计单条数据金额
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.restaurant,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};