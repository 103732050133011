import FormItem from './FormItem.vue';
import { defaultState, rules } from '../config';
import { beforeCommit } from '../helper';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      rules,
      visible: false,
      form: {}
    };
  },
  methods: {
    edit(row) {
      this.visible = true;
      const {
        id,
        shipTicket,
        startTripDate,
        endTripDate,
        planNumber
      } = row;
      this.form = {
        ...defaultState(),
        ...shipTicket,
        id,
        startTripDate,
        endTripDate,
        planNumber
      };
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    handleSave() {
      this.$refs.formRef.validate().then(data => {
        if (!data) return;
        const filterTicket = true;
        const opts = beforeCommit(data, filterTicket);
        this.$store.dispatch('plan/boatPersonal/update', opts).then(() => {
          this.$bus.tip();
          this.$emit('reload');
          this.hide();
        });
      });
    }
  }
};