import "core-js/modules/es.array.reduce.js";
import { tableMixin, rowProfitMixin } from '@/mixin';
export default {
  mixins: [tableMixin, rowProfitMixin],
  data() {
    return {};
  },
  methods: {
    // billCheckTotal
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      const unit = 100;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => {
          const {
            adultCount,
            childCount,
            accompanyCount
          } = item;
          if (column.property === 'people') {
            let res = adultCount + '/' + childCount + '/' + (accompanyCount || 0);
            return res;
          } else {
            return [item[column.property], 0, 0].join('/');
          }
        });
        if (values.every(value => value != undefined)) {
          const res = values.reduce((prev, cur) => {
            const [adultCount, childCount, accompanyCount] = cur.split('/');
            prev[0] += Number(adultCount) * unit;
            prev[1] += Number(childCount);
            prev[2] += Number(accompanyCount);
            return prev;
          }, [0, 0, 0]);
          let resultNum = res[0] / unit;
          if (column.property === 'people') {
            sums[index] = `${resultNum}大${res[1]}小${res[2]}陪`;
          } else if (column.property === 'checkAccountTime') {
            sums[index] = `已选金额：${resultNum}`;
          } else if (column.property == 'billCheckTotal' && data.length) {
            sums[index] = `${sums[1]}/${data[data.length - 1].planCountTotal}`;
          } else {
            sums[index] = resultNum;
          }
        }
      });
      return sums;
    }
  }
};