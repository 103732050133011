import { cashModeTypeLabel, cashLogType } from '../../help';
const formKeyMap = {
  cashMode: '下账模式',
  money: '当前金额',
  used: '余额',
  resourceName: '单位',
  cashDate: '日期',
  cash: '下账金额',
  subjectName: '会计科目',
  cashWay: '方式',
  bankNo: '银行卡',
  cashUserName: '收款人',
  cashTypeLabel: '类型',
  // resourceName: '发票号',
  // resourceName: '发票金额',
  remarks: '备注'
};
export default {
  data() {
    return {
      formKeyMap,
      visible: false,
      form: null
    };
  },
  computed: {
    getFormList() {
      return Object.keys(formKeyMap).map(key => {
        return {
          label: formKeyMap[key],
          value: this.form[key]
        };
      });
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.form = {
        ...data,
        money: data.cash,
        cashTypeLabel: cashLogType[data.cashType],
        cashMode: cashModeTypeLabel[data.cashMode]
      };
    },
    hide() {
      this.visible = false;
    }
  }
};