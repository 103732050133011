var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.handleSearch
    }
  }, [_c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "export"
    },
    on: {
      click: _vm.exportExcel
    }
  })], 1), [_c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planNumber", $$v);
      },
      expression: "searchParams.planNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("startTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("endTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游"
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("guide", $event);
      }
    },
    model: {
      value: _vm.searchParams.guideLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideLabel", $$v);
      },
      expression: "searchParams.guideLabel"
    }
  })], 1)], _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "操作计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "线路产品"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.searchParams.productName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productName", $$v);
      },
      expression: "searchParams.productName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      clearable: "",
      label: "",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "saleUserId", $$v);
      },
      expression: "searchParams.saleUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "审核状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      list: _vm.orderAuditStatusList,
      clearable: ""
    },
    model: {
      value: _vm.searchParams.auditStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditStatus", $$v);
      },
      expression: "searchParams.auditStatus"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "区域"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      label: "",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selecthotelRegion
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  })], 1)], 1)], 2), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading
    },
    on: {
      page: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };