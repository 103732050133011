import ReceptionFeeTable from '@/components/common/Order/Reception/ReceptionFeeTable';
import ReceptionProxyTable from '@/components/common/Order/Reception/ReceptionProxyTable';
import ReceptionRefundTable from '@/components/common/Order/Reception/ReceptionRefundTable';
import ReceptionRemarkTable from '@/components/common/Order/Reception/ReceptionRemarkTable';
import { typeMixin } from '@/mixin';
// import { planEditReception, planEditReceptionProxy, planEditReceptionRefund, planEditReceptionRemark } from '@/config/plan'

export default {
  components: {
    ReceptionFeeTable,
    ReceptionProxyTable,
    ReceptionRefundTable,
    ReceptionRemarkTable
  },
  mixins: [typeMixin],
  data() {
    return {
      visible: false,
      isAudit: false,
      data: null,
      activeName: 'fee',
      tabIndex: 0
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      this.data = this.formatItem(data);
    },
    hide() {
      this.visible = false;
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
    },
    formatItem(it) {
      const {
        feeList,
        collectList,
        subsidyList
      } = it;
      const {
        customerName,
        remarks
      } = it;
      it.feeList = this.formatLabel(feeList, 'teamFundType');
      it.collectList = this.formatLabel(collectList, 'proxyType');
      it.subsidyList = this.formatLabel(subsidyList, 'refundType');
      it.remarkList = [{
        customerName,
        remarks
      }];
      return it;
    },
    formatLabel(list, type) {
      return list.map(fee => {
        fee.typeLabel = this.getType(type, fee.type);
        return fee;
      });
    }
  }
};