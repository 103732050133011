import { RenewColumn as columns } from '../tableConfig';
import { VAS_TYPE } from '@/config/vas';
import { PAY_NET_TYPE_LABEL } from '@/config/payType';
import { parseDate, getFileSuffix } from '@/utils';
export default {
  data() {
    return {
      columns,
      visible: false,
      tableData: [],
      pageProp: {
        total: 0
      },
      search: {
        tripVasCode: VAS_TYPE.TRIPSYSSEV,
        payStatus: 1,
        rechargeType: 'YEAR'
      },
      curBillUrl: ''
    };
  },
  computed: {
    isPdfFile() {
      return () => {
        return this.getFileType(this.curBillUrl, 'pdf');
      };
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.showRenewLog();
    },
    hide() {
      this.visible = false;
    },
    getFileType(url, type) {
      return url.lastIndexOf(type) != -1;
    },
    async getBillImagePath(filePath) {
      const url = await this.fetchOssUrl(filePath);
      this.curBillUrl = url;
    },
    showRenewLog(params) {
      this.$store.dispatch('system/payment/renewLog', {
        ...this.search,
        ...params
      }).then(res => {
        if (!res || !res.data) return;
        const {
          data
        } = res;
        this.tableData = this.formatData(data.list);
        this.pageProp.total = data.totalCount;
      });
    },
    handlePage(params) {
      this.showRenewLog(params);
    },
    formatData(data) {
      return data.map((it, idx) => {
        it.orderNumber = idx + 1;
        it.$createTime = parseDate(it.createTime);
        it.$payType = PAY_NET_TYPE_LABEL[it.payType.toUpperCase()];
        return it;
      });
    },
    fetchOssUrl(filePath) {
      return this.$store.dispatch('resource/common/getOssImage', filePath).then(res => {
        if (!res) return;
        const [url] = res;
        return url;
      });
    },
    async downloadFile(row) {
      const {
        invoicePicPath: filePath
      } = row;
      const fileName = getFileSuffix(filePath, row.invoiceName);

      // 获取图片的URL
      const url = await this.fetchOssUrl(filePath);
      this.$store.dispatch('order/resourcePlan/downloadFile', {
        url: encodeURIComponent(url),
        fileName
      });
    }
  }
};