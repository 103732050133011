var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: _vm.transitionName
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "back-to-ceiling",
    style: _vm.customStyle,
    on: {
      click: _vm.backToTop
    }
  }, [_c("svg", {
    staticClass: "Icon Icon--backToTopArrow",
    staticStyle: {
      height: "16px",
      width: "16px"
    },
    attrs: {
      width: "16",
      height: "16",
      viewBox: "0 0 17 17",
      xmlns: "http://www.w3.org/2000/svg",
      "aria-hidden": "true"
    }
  }, [_c("path", {
    attrs: {
      d: "M12.036 15.59a1 1 0 0 1-.997.995H5.032a.996.996 0 0 1-.997-.996V8.584H1.03c-1.1 0-1.36-.633-.578-1.416L7.33.29a1.003 1.003 0 0 1 1.412 0l6.878 6.88c.782.78.523 1.415-.58 1.415h-3.004v7.004z"
    }
  })])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };