var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.row.plans && _vm.row.plans.length ? _c("div", [_vm.row.plans.length > 2 ? _c("el-popover", {
    attrs: {
      placement: "right-start",
      title: "团号列表",
      width: "200",
      trigger: "hover"
    }
  }, [_vm._l(_vm.row.plans, function (it, idx) {
    return _c("div", {
      key: idx
    }, [_c("BtnLink", {
      attrs: {
        type: "primary"
      }
    }, [_vm._v(" " + _vm._s(it[_vm.keyVal]) + " ")])], 1);
  }), _c("BtnLink", {
    attrs: {
      slot: "reference",
      type: "primary"
    },
    slot: "reference"
  }, [_vm._v(" " + _vm._s(_vm.row.plans[0][_vm.keyVal]) + " ")])], 2) : _vm._l(_vm.row.plans, function (it, idx) {
    return _c("div", {
      key: idx
    }, [_c("BtnLink", {
      attrs: {
        type: "primary"
      }
    }, [_vm._v(" " + _vm._s(it ? it[_vm.keyVal] : "") + " ")])], 1);
  })], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };