var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.getData && _vm.show ? _c("div", {
    staticClass: "el-alert-from"
  }, [_c("el-alert", {
    attrs: {
      title: _vm.getData.title,
      description: _vm.getData.content,
      type: "error"
    }
  }), _vm.creditData.tmpCreditMoney > 0 ? _c("el-alert", {
    staticClass: "mt-20",
    attrs: {
      title: "",
      description: _vm.getTmpCreditContent,
      type: "success"
    }
  }) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };