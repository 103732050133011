/*
 * @Author: cyg
 * @Date: 2021-06-07 10:12:53
 * @LastEditors: cyg
 * @LastEditTime: 2023-04-12 18:05:01
 * @FilePath: \travel-admin\src\views\order\commonMixin.js
 */
import FormInput from './components/FormInputNew';
import FormRemarks from './components/FormRemarks';
import TableFund from './components/TableFund';
import TableProxy from './components/TableProxy';
import TableRefund from './components/TableRefund';
import TableTraffic from './components/TableTraffic';
import TablePickup from './components/TablePickup';
import TableReception from './components/TableReception';
import TableTourist from './components/TableTourist';
import TableScenic from './components/TableScenic';
import TableRestaurant from './components/TableRestaurant';
import TableFleet from './components/TableFleet';
import { FooterBar } from '@/components/common';
import { orderType } from '@/config/order';
export default {
  components: {
    FooterBar,
    TableTourist,
    TableFund,
    TableProxy,
    TableRefund,
    TableTraffic,
    TablePickup,
    TableReception,
    FormInput,
    TableScenic,
    FormRemarks,
    TableRestaurant,
    TableFleet
  },
  data() {
    return {
      type: orderType.team
    };
  },
  methods: {
    fetchDetailData(id) {
      return this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    handlePrint() {
      const {
        path
      } = this.$route;
      let gateUrl = 'team';
      if (path == '/order/personal-input-update') {
        gateUrl = 'personal';
      }
      this.fetchDetailData(this.currentOrderId).then(data => {
        const {
          planId
        } = data;
        let routeUrl = this.$router.resolve({
          path: '/print',
          query: {
            id: this.currentOrderId,
            planId,
            gateUrl,
            planType: this.type
          }
        });
        window.open(routeUrl.href, '_blank');
      });
    }
  }
};