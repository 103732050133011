var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };