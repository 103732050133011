var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "trip",
      fn: function ({
        scope
      }) {
        return [_c("SelectCustom", {
          attrs: {
            label: "",
            clearable: ""
          },
          model: {
            value: scope.row.customerIdNew,
            callback: function ($$v) {
              _vm.$set(scope.row, "customerIdNew", $$v);
            },
            expression: "scope.row.customerIdNew"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " 大 " + _vm._s(scope.row.adultCount) + " 小 ")];
      }
    }, {
      key: "fee",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: "",
            clearable: ""
          },
          model: {
            value: scope.row.fee,
            callback: function ($$v) {
              _vm.$set(scope.row, "fee", $$v);
            },
            expression: "scope.row.fee"
          }
        })];
      }
    }, {
      key: "proxy",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: "",
            clearable: ""
          },
          model: {
            value: scope.row.proxy,
            callback: function ($$v) {
              _vm.$set(scope.row, "proxy", $$v);
            },
            expression: "scope.row.proxy"
          }
        })];
      }
    }, {
      key: "subsidy",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: "",
            clearable: ""
          },
          model: {
            value: scope.row.subsidy,
            callback: function ($$v) {
              _vm.$set(scope.row, "subsidy", $$v);
            },
            expression: "scope.row.subsidy"
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };