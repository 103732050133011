import "core-js/modules/es.array.push.js";
import { Search, DialogCustom, DialogContact, TableCustom, DialogDetail } from './components';
import { DialogAgreement, DialogLog } from '@/components/common';
import { Columns } from './table';
import { parseTime, formatDepartmentParams } from '@/utils';
import { searchMixin } from '@/mixin';
import { CustomerTypeLabel } from '@/config';
export default {
  name: 'CustomTabel',
  components: {
    Search,
    DialogDetail,
    DialogCustom,
    DialogContact,
    TableCustom,
    DialogAgreement,
    DialogLog
  },
  mixins: [searchMixin],
  data: () => ({
    Columns,
    tableData: [],
    total: 0
  }),
  computed: {
    customData() {
      return this.$store.getters['resource/custom/customDataOrigin'];
    },
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    },
    getSearchParams() {
      return this.$refs.searchRef ? this.$refs.searchRef.searchParams : {};
    }
  },
  watch: {
    customData: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
          return;
        }
        this.handleData(d);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/custom/fetch', {
        ...this.getSearchParams,
        ...formatDepartmentParams(this.roleInfo),
        ...params
      }).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    fetchList() {
      this.$store.dispatch('resource/custom/fetchList');
    },
    initData() {
      this.fetchData(this.search);
    },
    fetchCustom(id) {
      this.$store.dispatch('resource/custom/fetchContactInfo', id).then(res => {
        this.$refs.contactRef.edit(res);
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = this.handleCustom(list);
      setTimeout(() => {
        this.$refs.tableRef.initTable();
      });
    },
    handleCustom(list) {
      const result = [];
      list.map(it => {
        const {
          contacts
        } = it;
        const item = {
          ...it,
          $type: CustomerTypeLabel[it.type],
          rowId: it.id,
          resourceId: it.id,
          createTime: parseTime(it.createTime, '{y}-{m}-{d}'),
          contact: '',
          mobilePhone: '',
          telphone: '',
          contactId: ''
        };
        if (contacts && contacts.length) {
          const contactList = this.tableContact = contacts;
          contactList.forEach(val => {
            result.push({
              ...item,
              ...val,
              contactId: val.id
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/custom/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    deleteRow(id) {
      this.$store.dispatch('resource/custom/delete', id).then(() => {
        this.tableData = this.tableData.filter(it => it.rowId !== id);
        this.fetchList();
        this.$bus.tip();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.search,
        pageNum,
        pageSize
      });
    },
    showCreateDialog(item) {
      this.$refs.dialogCustom.show(item);
    },
    hideCreateDialog() {
      this.$refs.dialogCustom.hide();
    },
    deleteTableRow(scope) {
      const {
        rowId: id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    editTableRow(scope) {
      this.showCreateDialog(scope);
    },
    showTableDetail(scope) {
      this.tableContact = scope.row.contacts;
      this.$store.dispatch('resource/custom/fetchDetail', scope.row.rowId).then(data => {
        this.$refs.detailRef.show(data);
      });
    },
    showContact(id) {
      this.$refs.contactRef.show(id);
    },
    editContact(scope) {
      const {
        contactId
      } = scope.row;
      this.fetchCustom(contactId);
    },
    showLog(scope) {
      const resourceId = scope.row.rowId;
      this.$store.dispatch('resource/custom/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    deleteContact(scope) {
      const customerContactId = scope.row.id;
      this.$bus.open(() => {
        this.$store.dispatch('resource/custom/deleteCustomContact', customerContactId).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      });
    }
  }
};