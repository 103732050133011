import TagGroup from '../components/TagGroup.vue';
import { tableMixin } from '@/mixin';
import { SELECTOR } from '@/config';
export default {
  components: {
    TagGroup
  },
  mixins: [tableMixin],
  computed: {
    formatType() {
      return type => {
        let data = SELECTOR.ticketType.find(item => type && item.value == type.toUpperCase());
        return data && data.label;
      };
    }
  }
};