import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.array.reduce.js";
import { auditProgressStatus } from '@/config/audit';
import { today } from '@/utils/date';
export default {
  data() {
    return {
      filename: '导出列表',
      autoWidth: true,
      excelLoading: false,
      bookType: 'xlsx'
    };
  },
  computed: {
    getToday() {
      return today;
    }
  },
  methods: {
    // 导出 excel
    exportExcel() {
      this.excelLoading = true;
      Promise.resolve().then(() => _interopRequireWildcard(require('@/vendor/Export2Excel'))).then(excel => {
        const tHeader = this.columnData.map(it => '\t' + it.attrs.label);
        const filterVal = this.columnData.map(it => it.attrs.prop);
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType
        });
        this.excelLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map((j, i) => {
        if (j !== null && j !== undefined && !isNaN(v[j]) && j != 'cardNumber') {
          v[j] = Number(v[j]);
          v[`sum${v[j]}`] += v[j];
        }

        // if (j == 'cardNumber') {
        //   v[j] = v[j].toString()
        // }

        // 房间信息
        if (j == 'roomInfo') {
          const {
            children
          } = this.columnData[i];
          v.roomInfo = children.reduce((acc, cur) => {
            const {
              label,
              prop
            } = cur.attrs;
            acc += `${label}: ${v[prop]} `;
            return acc;
          }, '');
        }

        // 费用明细
        if (j == 'fee') {
          if (v['caCustomerFees']) {
            v.fee = v['caCustomerFees'].reduce((acc, cur) => {
              let {
                type,
                count,
                price
              } = cur;
              type = type.split('|')[0];
              const total = count * price;
              acc += `${type}: ${count} × ${price} = ${total}`;
              return acc;
            }, '');
          }
        }
        switch (j) {
          case 'members':
            v.members = v.members ? v.members.map(g => {
              return [g.name, g.mobilePhone].join(' ');
            }) : '';
            break;
          case 'date':
            v.date = `${v.startPlanDate}~${v.endPlanDate}`;
            break;
          case 'number':
            v.number = `${v.adultCount}大${v.childCount}小${v.accompanyCount}陪`;
            break;
          case 'auditStatus':
            v.auditStatus = auditProgressStatus[v.auditProgress];
            break;
        }
        return v[j];
      }));
    }
  }
};