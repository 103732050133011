import { downloadExport } from '@/utils/exportExcel';
import TableList from './TableList';
import { guideData } from '@/config/shopping';
import { BtnExport, SelectGuide, SelectShop } from '@/components/common';
import { searchMixin, subsidyMixin } from '@/mixin';
export default {
  components: {
    TableList,
    BtnExport,
    SelectGuide,
    SelectShop
  },
  mixins: [searchMixin, subsidyMixin],
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: guideData,
    sendDate: [],
    // 发团日期
    shopDate: [],
    // 进店日期
    tableData: []
  }),
  computed: {
    totalGuideData() {
      return this.$store.getters['shopping/index/totalGuideData'];
    }
  },
  watch: {
    totalGuideData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.tableData = this.processTotalShopRebate(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getByGuideStatic', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    onSelect(type, val) {
      const {
        label,
        value
      } = val;
      this.searchParams[type + 'Name'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
        this.searchParams.orderType = val.value;
      } else {
        this.searchParams.orderType = '';
      }
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchByGuideStaticExcel', this.searchParams).then(res => {
        const fileName = '导游统计.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};