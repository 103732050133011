var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单更改信息",
      width: "960px",
      visible: _vm.visible,
      "has-foot": false,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("CollapseBase", {
    attrs: {
      title: "团款",
      "is-strong": ""
    }
  }, [_c("DetailFund", {
    ref: "fundTableRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "代收",
      "is-strong": ""
    }
  }, [_c("DetailProxy", {
    ref: "proxyTableRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "返款",
      "is-strong": ""
    }
  }, [_c("DetailRefund", {
    ref: "refundTableRef"
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };