var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-check-refund form-fix-bar"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on-search": _vm.fetchData,
      "on-export": _vm.handleExport
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StatisticsKeys
    },
    on: {
      page: _vm.reload
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        row
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.planCustomerNumber) + " ")]), _c("div", [_vm._v(_vm._s(row.productName))])];
      }
    }, {
      key: "tripDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startTripDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endTripDate) + " ")];
      }
    }, {
      key: "orderDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startOrderDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endOrderDate) + " ")];
      }
    }, {
      key: "number",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.adultCount) + " 大 " + _vm._s(row.childCount) + " 小 " + _vm._s(row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "fee",
      fn: function ({
        row
      }) {
        return _vm._l(row.caCustomerFees, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.getFeeInfo(item)) + " ")]);
        });
      }
    }, {
      key: "totalCollect",
      fn: function ({
        row
      }) {
        return _vm._l(row.caCustomerCollects, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm.getAuditType(item.auditProgress) ? _c("el-button", {
            attrs: {
              type: "text"
            }
          }, [_vm._v(" " + _vm._s(item.type) + " " + _vm._s(item.total) + " ")]) : _c("span", [_vm._v(_vm._s(item.type) + " " + _vm._s(item.total))])], 1);
        });
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({
        row
      }) {
        return [_c("InputNum", {
          model: {
            value: row.checkAccountMoney,
            callback: function ($$v) {
              _vm.$set(row, "checkAccountMoney", $$v);
            },
            expression: "row.checkAccountMoney"
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.checkAccountRemark,
            callback: function ($$v) {
              _vm.$set(row, "checkAccountRemark", $$v);
            },
            expression: "row.checkAccountRemark"
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        row
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("el-checkbox", {
          on: {
            change: _vm.checkAllSelect
          },
          model: {
            value: row.ckStatus,
            callback: function ($$v) {
              _vm.$set(row, "ckStatus", $$v);
            },
            expression: "row.ckStatus"
          }
        }), !row.checkAccountStatus ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheckout(row);
            }
          }
        }, [_vm._v(" 对账 ")]) : _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheckout(row);
            }
          }
        }, [_vm._v(" 取消 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.showLog(row);
            }
          }
        }, [_vm._v(" 日志 ")])];
      }
    }, {
      key: "actionHead",
      fn: function ({}) {
        return [_c("el-checkbox", {
          on: {
            change: _vm.selectAllCheckbox
          },
          model: {
            value: _vm.allCheckbox,
            callback: function ($$v) {
              _vm.allCheckbox = $$v;
            },
            expression: "allCheckbox"
          }
        }), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: _vm.clickAllCheckbox
          }
        }, [_vm._v(" " + _vm._s(_vm.allCheckbox ? "取消" : "全选") + " ")])];
      }
    }])
  }, [_c("template", {
    slot: "extra"
  }, [_vm._v(" " + _vm._s(_vm.calcCheckedMoney(_vm.tableData)) + " ")])], 2), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleCheck
    }
  }, [_vm._v(" 确认对账 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")])], 1), _c("DialogLog", {
    ref: "dialogLogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };