import { InputDepartment, SelectStaff, SelectGuide } from '@/components/common';
import { departKeys } from '@/config/dictionary';
import { searchMixin } from '@/mixin';
export default {
  components: {
    InputDepartment,
    SelectStaff,
    SelectGuide
  },
  mixins: [searchMixin],
  data() {
    return {
      department: '',
      searchParams: {},
      orderDate: [],
      applyDate: []
    };
  },
  methods: {
    // 全局刷新的时候，避免报错
    fetchData() {},
    selectType(type, val) {
      this.searchParams[type] = val ? val.value : '';
    },
    setResourceId(val) {
      this.searchParams.resourceId = val;
    },
    selectStaff(val) {
      this.searchParams.cashUserId = val.value;
    },
    changeDate(type, val) {
      val = val || ['', ''];
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    // 搜索
    handleSearch() {
      const {
        path
      } = this.$route;
      const search = this.searchParams;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search
      });

      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1
      });
      this.$emit('search', search);
    }
  }
};