import FormDepartmentChild from './FormDepartmentChild';
import { TreeDepartment } from '@/components/common';
import { systemMember } from '@/config/system';
import { reloadMixin } from '@/mixin';
export default {
  components: {
    TreeDepartment,
    FormDepartmentChild
  },
  mixins: [reloadMixin],
  data: () => ({
    tableLoading: false,
    columnData: systemMember,
    tableData: [],
    departmentList: [],
    total: 0
  }),
  computed: {
    departmentData() {
      return this.$store.getters['system/department/departmentData'];
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('system/department/fetch').then(data => {
        // const { list } = data
        this.departmentList = data;
      }).then(() => {
        this.fetchUserByIndex();
      });
    },
    fetchUser(queryParams) {
      // const queryParams = {
      //   department: id,
      //   subDepartment: subId,
      //   grandsonDepartment: grandId,
      // }

      this.tableData = [];
      this.$store.dispatch('user/fetchUserList', queryParams).then(data => {
        if (!data) return;
        this.tableData = data.list;
      });
    },
    fetchUserByIndex(index = 0) {
      const firstItem = this.departmentData && this.departmentData.list[index];
      if (!firstItem) return;
      const {
        id
      } = firstItem;
      this.fetchUser({
        department: id
      });
    },
    getStaffList(node, data) {
      const {
        value
      } = node;
      const {
        level
      } = data;
      const obj = [{
        department: value
      }, {
        department: node.pid,
        subDepartment: value
      }, {
        department: node.gid,
        subDepartment: node.pid,
        grandsonDepartment: value
      }];
      this.fetchUser(obj[level - 1]);
    },
    // 删除一级部门
    deleteItem(id) {
      return this.$store.dispatch('system/department/delete', id);
    },
    // 删除二级部门
    deleteChildItem(id) {
      return this.$store.dispatch('system/department/deleteChildItem', id);
    },
    // 删除三级部门
    deleteGrandItem(id) {
      return this.$store.dispatch('system/department/deleteGrandItem', id);
    },
    createDepartment(opts) {
      return this.$store.dispatch('system/department/create', opts);
    },
    createChildDepartment(opts) {
      return this.$store.dispatch('system/department/createChild', opts);
    },
    createGrandDepartment(opts) {
      return this.$store.dispatch('system/department/createGrandChild', opts);
    },
    handleCreate(type) {
      this.visible[type] = true;
    },
    showDialog() {
      this.$refs.dialogRef.show();
    },
    handleEdit(data) {
      this.$refs.dialogRef.edit(data);
    },
    handleRemove(node) {
      const {
        value
      } = node.data;
      const {
        level
      } = node;
      this.$bus.open(() => {
        if (level === 1) {
          this.deleteItem(value).then(() => {
            this.fetchData();
            this.$bus.tip();
          });
        }
        if (level === 2) {
          this.deleteChildItem(value).then(() => {
            this.fetchData();
            this.$bus.tip();
          });
        }
        if (level === 3) {
          this.deleteGrandItem(value).then(() => {
            this.fetchData();
            this.$bus.tip();
          });
        }
      });
    },
    dragStart(node, ev) {
      const {
        level,
        data
      } = node;
      const hasDrag = (level, data) => {
        if (level === 1) {
          if (data.children.length > 0) {
            for (let i = 0; i < data.children.length; i++) {
              const it = data.children[i];
              if (it.children && it.children.length) {
                return false;
              }
            }
          }
        }
        return true;
      };
      if (!hasDrag(level, data)) {
        ev.preventDefault();
        return false;
      }
    },
    dropSuccess({
      draggingNode,
      dropNode
    }) {
      const {
        data: parentNode
      } = dropNode;
      const {
        level,
        data: childNode
      } = draggingNode;
      this.processCreate({
        parentNode,
        childNode,
        level
      });
      // this.processDelete(childNode)
    },
    async processCreate({
      parentNode,
      childNode,
      level
    }) {
      const {
        value: parentsDepartmentId
      } = parentNode;
      const {
        value: id
      } = childNode;
      const opts = {
        id,
        parentsDepartmentId: level ? parentsDepartmentId : undefined,
        isEnable: true
      };
      this.createChildDepartment(opts);
    },
    async processDelete(item) {
      const {
        children,
        value: id
      } = item;
      await this.deleteItem(id);
      if (children.length) {
        children.map(this.processDelete);
      }
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchDetail({
        pageNum,
        pageSize
      });
    }
  }
};