import axios from 'axios';
import { getToken } from '@/utils/cookie';
import { getStatus } from '@/utils';
export default {
  data() {
    return {
      tableData: [],
      headConfig: {
        Authorization: 'bearer ' + getToken()
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.request({
        url: 'https://api.weilailvxingshe.com/order/plan/pageList?pageNum=1&startTripDateS=2024-05-01&startTripDateE=2024-05-31&planType=t_tourist',
        method: 'get',
        params: {
          pageSize: 10000
        },
        headers: this.headConfig
      }).then(res => {
        if (getStatus(res)) {
          const {
            list
          } = res.data.data;
          this.tableData = list.filter(it => it.shuttleTips.length);
        }
      });
    },
    fetchShuttle(id) {
      return axios.request({
        url: `https://api.weilailvxingshe.com/order/orderBus/listAll?shuttleType=SEND&planId=${id}`,
        method: 'get',
        params: {},
        headers: this.headConfig
      }).then(res => {
        if (getStatus(res)) {
          return res.data.data;
        }
      });
    },
    shuttleDelete(data) {
      return axios.request({
        url: 'https://api.weilailvxingshe.com/order/orderBus/del',
        method: 'post',
        data,
        headers: this.headConfig
      }).then(res => {
        if (getStatus(res)) {
          this.$bus.tip();
          return res.data.data;
        }
      });
    },
    handleDelete(id) {
      this.fetchShuttle(id).then(data => {
        if (data && data.length) {
          const ids = data.map(it => it.id);
          this.shuttleDelete(ids).then(() => {
            this.tableData = this.tableData.filter(it => it.id != id);
          });
        }
      });
    }
  }
};