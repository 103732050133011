import { DialogLog, Members, CustomerInfo } from '@/components/common';
import { Search } from './components';
import { Columns, statisticsKeys } from './tableConfig';
import { parseTime, downloadExport, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { ENUM_AUDIT_TYPE, ENUM_AUDIT_LOG_TYPE } from '@/enums';
export default {
  components: {
    Search,
    Members,
    CustomerInfo,
    DialogLog
  },
  data: () => ({
    Columns,
    statisticsKeys,
    tableData: [],
    columnData: [],
    checkAll: false
  }),
  computed: {
    data() {
      return this.$store.getters['audit/proxy/data'];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    statistics() {
      const data = this.data;
      if (data && data.statistics) {
        const {
          totalAccompanyCount: accompanyCount,
          totalAdultCount: adultCount,
          totalChildCount: childCount
        } = data.statistics;
        return {
          ...data.statistics,
          accompanyCount,
          adultCount,
          childCount
        };
      }
      return null;
    },
    getSearchParams() {
      return this.$refs.searchRef ? this.$refs.searchRef.searchParams : {};
    }
  },
  watch: {
    data: {
      handler: 'initData',
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? Columns : filterCustomColumn(Columns);
  },
  methods: {
    initData(data) {
      if (!data) {
        return;
      }
      this.tableData = this.formatData(data);
    },
    fetchData(params = {}) {
      this.$store.dispatch('audit/proxy/list', {
        ...this.getSearchParams,
        ...params
      });
    },
    onChangeOne() {
      this.checkAll = this.tableData.every(it => it.isCheck);
    },
    onCheckedAll() {
      const value = this.checkAll;
      this.tableData.forEach(it => {
        it.isCheck = value;
      });
    },
    auditStatus(val) {
      return val == ENUM_AUDIT_TYPE.WAITAUDIT;
    },
    formatData(data) {
      const {
        list
      } = data;
      return list.map(it => {
        const {
          isAgencyCollect,
          isGuideCollect,
          total,
          auditProgress,
          paymentCollectionTime
        } = it;
        this.$set(it, 'isCheck', true);
        it.checkAccountStatus = false;
        if (this.auditStatus(auditProgress)) {
          this.$set(it, 'isCheck', false);
        }
        if (paymentCollectionTime) {
          const status = it.checkAccountStatus ? it.paymentCollectionTime = parseTime(paymentCollectionTime) : it.paymentCollectionTime = '';
          it.isCheck = status;
          it.checkAccountStatus = status;
        }
        if (isAgencyCollect) {
          it.collection = total;
        }
        if (isGuideCollect) {
          it.guideCollection = total;
        }
        return it;
      });
    },
    batchAudit() {
      const checkList = this.tableData.filter(it => it.isCheck);
      if (!checkList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选中要操作的数据!'
        });
        return;
      }
      this.$bus.open(() => {
        const dataList = this.processData(checkList, true);
        this.handleSubmit(dataList);
      }, '确定要批量操作吗？');
    },
    // 审核（单条）
    handleCheckout(row) {
      row.checkAccountStatus = true;
      this.$bus.open(() => {
        const list = this.processData([row], true);
        this.handleSubmit(list);
      }, '是否确认审核?');
    },
    // 取消审核
    handleCheckCancel(row) {
      this.$bus.open(() => {
        const list = this.processData([row], false);
        this.handleSubmit(list);
      }, `是否确认取消审核?`);
    },
    processData(list, status) {
      return list.map(it => {
        const {
          id,
          planId,
          auditProgress,
          paymentCollectionTime
        } = it;
        let payTime = it.paymentCollectionTime || parseTime(new Date());
        if (status) {
          payTime = parseTime(paymentCollectionTime || new Date());
        } else {
          payTime = parseTime(it.paymentCollectionTime);
        }
        const items = {
          auditTypeEnum: 'COLLECT',
          planId,
          resourceId: id,
          isCollectMenu: true,
          paymentCollectionTime: payTime,
          status,
          auditProgress
        };
        if (this.isAdminAudit) {
          items.auditProgress = ENUM_AUDIT_TYPE.OPAUDITED;
        }
        return items;
      });
    },
    // 日志
    handleLog(row) {
      const {
        id: subItemId,
        planId: itemId
      } = row;
      const params = {
        itemId,
        subType: 'collect',
        subItemId,
        type: ENUM_AUDIT_LOG_TYPE.AUDIT
      };
      this.$store.dispatch('audit/proxy/log', params).then(res => {
        this.$refs.logRef.show(res.data);
      });
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/proxy/export', this.searchParams).then(res => {
        const fileName = '代收审核.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    handleSubmit(dataList) {
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/proxy/audit', dataList).then(() => {
        loading.close();
        // 刷新列表数据
        this.fetchData();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};