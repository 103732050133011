var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "日志",
      "custom-class": "resource-dialog",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_vm.list.length ? _c("ul", {
    staticClass: "content"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("li", {
      key: index
    }, [_c("div", {
      staticClass: "info"
    }, [_c("strong", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.optUserName) + " ")]), _c("span", {
      staticClass: "time fr"
    }, [_vm._v(_vm._s(item.optTime) + " ")])]), _c("div", {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(item.optContent) + " ")])]);
  }), 0) : _c("Empty")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };