/* eslint-disable no-empty-pattern */
import { customRequest, detailRequest, balanceMonthRequest, balanceYearRequest } from '@/api/report';
const defaultState = () => ({
  customData: null,
  detailData: null,
  balanceMonthData: null,
  balanceYearData: null
});
const state = defaultState();
const getters = {
  customData: state => state.customData,
  detailData: state => state.detailData,
  balanceMonthData: state => state.balanceMonthData,
  balanceYearData: state => state.balanceYearData
};
const actions = {
  custom({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return customRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'customData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  detail({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return detailRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'detailData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  balanceMonth({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return balanceMonthRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'balanceMonthData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  balanceYear({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return balanceYearRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'balanceYearData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  }
};
const mutations = {
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};