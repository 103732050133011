/**
 * @module components/common/Input
 * @desc 大团号 input 组件
 * @vue-data {String} [radio=1] - 输入框的类型 1: 系统生成 1: 用户自定义
 * @vue-data {String} inputValue - 输入框的值
 * @vue-data {String} disableValue - 输入框的状态
 */
import { systemMixin } from '@/mixin';
export default {
  mixins: [systemMixin],
  props: {
    planNumber: {
      type: String,
      default: ''
    },
    orderType: {
      type: String,
      default: 'T_TOURIST'
    },
    intiVal: {
      type: [String, Number],
      default: 2
    }
  },
  data() {
    return {
      radio: 2,
      disableValue: false,
      // 是否禁止修改大团号（系统生成）
      isForbid: false
    };
  },
  computed: {
    hasDisable() {
      return this.disableValue;
    },
    getValue: {
      get() {
        return this.planNumber;
      },
      set(val) {
        this.$emit('update:planNumber', val);
      }
    }
  },
  watch: {
    radio: [{
      handler: 'onRadioChanged'
    }],
    intiVal: {
      handler: function (val) {
        this.radio = val;
      }
    }
    // planNumber: {
    //   handler: function (val) {
    //     this.radio = val ? 2 : 1
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.isForbid = this.getConfigStatus('order:isPlanNumber:isPlanNumber');
    if (this.isForbid) {
      this.intiVal = 1;
    }
  },
  methods: {
    // ridioType() {
    //   this.radio = this.orderType == 'S_TOURIST' ? 2 : 1
    // },
    onRadioChanged(val) {
      if (val === 1) {
        this.disableValue = true;
      } else {
        this.disableValue = false;
      }
    }
  }
};