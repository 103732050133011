import { tableMixin } from '@/mixin';
import { KPIType } from '@/config/system';
export default {
  mixins: [tableMixin],
  data() {
    return {};
  },
  computed: {
    projectName() {
      return row => {
        if (row.kpitype == KPIType.turnover) {
          return '营业额';
        }
        if (row.kpitype == KPIType.profit) {
          return '毛利';
        }
        if (row.kpitype == KPIType.numberOfPeople) {
          return '人数';
        }
      };
    }
  },
  mounted() {},
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    handleEdit({
      row
    }) {
      this.$parent.handleEdit(row);
    },
    handleDelete({
      row
    }) {
      this.$parent.handleDelete(row);
    },
    handleLog({
      row
    }) {
      this.$parent.handleLog(row);
    }
  }
};