var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "客户名称",
      clearable: ""
    },
    model: {
      value: _vm.search.name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "name", $$v);
      },
      expression: "search.name"
    }
  }), _c("InputBase", {
    attrs: {
      label: "联系人",
      clearable: ""
    },
    model: {
      value: _vm.search.contactKey,
      callback: function ($$v) {
        _vm.$set(_vm.search, "contactKey", $$v);
      },
      expression: "search.contactKey"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "区域经理"
    },
    model: {
      value: _vm.search.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "customerAreaManagerId", $$v);
      },
      expression: "search.customerAreaManagerId"
    }
  }), _c("InputBase", {
    attrs: {
      label: "客户代码",
      clearable: ""
    },
    model: {
      value: _vm.search.codeName,
      callback: function ($$v) {
        _vm.$set(_vm.search, "codeName", $$v);
      },
      expression: "search.codeName"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    model: {
      value: _vm.search.customerAreaServiceId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "customerAreaServiceId", $$v);
      },
      expression: "search.customerAreaServiceId"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "业务员"
    },
    model: {
      value: _vm.search.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "saleUserId", $$v);
      },
      expression: "search.saleUserId"
    }
  }), _c("SelectBase", {
    attrs: {
      type: "targetSearchAll"
    },
    model: {
      value: _vm.search.kpiStatus,
      callback: function ($$v) {
        _vm.$set(_vm.search, "kpiStatus", $$v);
      },
      expression: "search.kpiStatus"
    }
  }), _c("BtnCreate", {
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.showCreateDialog
    }
  })], 1)]), _c("TableCustom", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    },
    on: {
      reload: _vm.initData
    }
  }), _c("DialogCustom", {
    ref: "dialogCustom"
  }), _c("DialogContact", {
    ref: "contactRef"
  }), _c("DialogDetail", {
    ref: "detailRef"
  }), _c("DialogAgreement", {
    ref: "agreementRef"
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };