import { parseDate } from '@/utils';
export const caseStatus = {
  draft: '草稿',
  waitAudit: '待审核',
  announcement: '已公示'
};
export const caseStatusType = {
  draft: 'danger',
  waitAudit: 'danger',
  announcement: 'success'
};
export const formState = () => ({
  consequence: '',
  details: '',
  handlingMethod: '',
  improveThePlan: '',
  introduction: '',
  occurrenceTime: parseDate(),
  personInCharge1: '',
  personInCharge2: '',
  personInCharge3: '',
  reason: '',
  toAudit: false,
  type: 'complaint'
});
export const rules = {
  personInCharge1: [{
    required: true,
    message: '请选择操作计调',
    trigger: 'change'
  }],
  personInCharge2: [{
    required: true,
    message: '请选择客服计调',
    trigger: 'change'
  }],
  occurrenceTime: [{
    required: true,
    message: '请选择发送时间',
    trigger: 'change'
  }],
  introduction: [{
    required: true,
    message: '请输入事情简介',
    trigger: 'blur'
  }],
  details: [{
    required: true,
    message: '请输入事情经过',
    trigger: 'blur'
  }],
  consequence: [{
    required: true,
    message: '请输入造成后果',
    trigger: 'blur'
  }]

  // reason: [{ required: true, message: '请输入问题所在', trigger: 'blur' }],

  // handlingMethod: [
  //   { required: true, message: '请输入处理方式', trigger: 'blur' },
  // ],

  // improveThePlan: [
  //   { required: true, message: '请输入完善方案', trigger: 'blur' },
  // ],
};