import request from '@/utils/request';

// 案例库列表
export function caseListRequest(params) {
  return request({
    url: '/system/case/page',
    method: 'GET',
    params
  });
}

// 根据id获取案例
export function caseDetailRequest(params) {
  return request({
    url: '/system/case/case',
    method: 'GET',
    params
  });
}

// 新增/更新
export function caseSetRequest(data) {
  return request({
    url: '/system/case/saveOrUpdate',
    method: 'POST',
    data
  });
}

// 删除案例
export function caseDeleteRequest(data) {
  return request({
    url: '/system/case/del',
    method: 'POST',
    data
  });
}

// 设置状态
export function caseAuditRequest(data) {
  return request({
    url: '/system/case/audit',
    method: 'POST',
    data
  });
}