var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tree", {
    attrs: {
      data: _vm.list,
      "node-key": "id",
      "default-expand-all": "",
      "expand-on-click-node": false
    },
    on: {
      "node-click": _vm.handleNodeClick,
      "node-drag-start": _vm.handleDragStart,
      "node-drag-enter": _vm.handleDragEnter,
      "node-drag-leave": _vm.handleDragLeave,
      "node-drag-over": _vm.handleDragOver,
      "node-drag-end": _vm.handleDragEnd,
      "node-drop": _vm.handleDrop
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        node,
        d
      }) {
        return _c("span", {
          staticClass: "custom-tree-node"
        }, [_c("LabelBase", {
          attrs: {
            label: node.label
          }
        }), _c("span", [_c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          },
          on: {
            click: () => _vm.edit(node, d)
          }
        }, [_c("i", {
          staticClass: "iconfont icon-edit"
        })]), _c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          },
          on: {
            click: () => _vm.remove(node, d)
          }
        }, [_c("i", {
          staticClass: "iconfont icon-delete1"
        })])], 1)], 1);
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };