import { blackListDetail } from '@/config/order';
export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: blackListDetail
    };
  },
  computed: {
    getFomatData() {
      return item => {
        if (!item) return '';
        // 换行
        let arr = item.replace(/,/g, '<br/>');
        return arr;
      };
    }
  },
  mounted() {},
  methods: {
    show(row) {
      const {
        list
      } = row;
      this.tableData = list;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.restore();
    },
    tableRowClassName({
      row
    }) {
      // && row.times < 10
      if (row.isCommon && row.times === 1) {
        return 'warning-row';
      }
      if (row.isCommon && row.times > 1 && row.times <= 10) {
        return 'danger-row';
      }
      if (row.isCommon && row.times > 10) {
        return 'info-row';
      }
      return 'success-row';
    },
    restore() {
      this.tableData = [];
      this.visible = false;
    }
  }
};