import "core-js/modules/es.array.push.js";
import { SwitchStatus, FormAccount, SelectDictionary, SelectStaff, InputDepartment, SelectRegion } from '@/components/common';
import { mapGetters } from 'vuex';
import DialogContact from './DialogContact';
import { resourceCustomContact as columnContact } from './table';
import { defaultState, defaultContacts } from './config';
export default {
  name: 'CustomTabel',
  components: {
    SelectStaff,
    InputDepartment,
    SwitchStatus,
    FormAccount,
    SelectDictionary,
    DialogContact,
    SelectRegion
  },
  data() {
    return {
      department: [''],
      departmentItem: {},
      visible: false,
      isUpdate: false,
      columnContact,
      tableData: [],
      index: -1,
      form: defaultState(),
      rowId: null
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      editAccount: 'resource/account/editAccountInfo' // 账户信息
    }),
    getTitle() {
      return this.isUpdate ? '更新客户' : '添加客户';
    }
  },
  methods: {
    show(data) {
      if (data) {
        const {
          rowId,
          departmentId,
          subDepartmentId,
          grandsonDepartmentId
        } = data.row;
        this.rowId = rowId;
        this.departmentItem = {
          departmentId,
          subDepartmentId,
          grandsonDepartmentId
        };
        this.fetchDetail(rowId);
      } else {
        this.rowId = '';
        this.form = defaultState();
        this.$store.dispatch('resource/account/editAccount', this.form);
      }
      this.isUpdate = data ? true : false;
      this.visible = true;
    },
    fetchDetail(id) {
      this.$store.dispatch('resource/custom/fetchDetail', id).then(data => {
        if (!data) return;
        this.form = {
          ...defaultState(),
          ...data
        };
        this.tableData = data.contacts;
        const keys = ['provinceId', 'cityId', 'districtId'];
        keys.forEach(key => {
          if (data[key]) {
            this.form.region.push(data[key].toString());
          }
        });
        this.$store.dispatch('resource/account/editAccount', this.form);
      });
    },
    // 部门
    changeDepartment(val) {
      if (val) {
        ;
        ['departmentId', 'subDepartmentId', 'grandsonDepartmentId'].forEach(key => {
          // this.handleChange(key, val[key])
          this.form[key] = val[key] ? val[key] : -1;
        });
        this.form.department = true;
      } else {
        this.form.department = false;
      }
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    selectArea(val) {
      const {
        label,
        value
      } = val;
      this.form.customerArea = label;
      this.form.customerAreaId = value;
    },
    selectManager(val) {
      const {
        label,
        value
      } = val;
      this.form.customerAreaManager = label;
      this.form.customerAreaManagerId = value;
    },
    selectService(val) {
      const {
        label,
        value
      } = val;
      this.form.customerAreaService = label;
      this.form.customerAreaServiceId = value;
    },
    handleRegion(val) {
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.form[key] = val[key];
      });
    },
    resetForm() {
      this.from = {};
    },
    initData() {
      this.fetchDetail(this.rowId);
    },
    handleContact() {
      this.form.contacts.push(defaultContacts());
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (id) {
        this.$bus.open(() => {
          this.deleteContact(id);
        });
      } else {
        this.form.contacts.splice(scope.$index, 1);
      }
    },
    handleEdit({
      row
    }) {
      this.$refs.contactRef.edit({
        customerId: this.rowId,
        ...row
      });
    },
    deleteContact(customerContactId) {
      this.$store.dispatch('resource/custom/deleteCustomContact', customerContactId).then(() => {
        this.$bus.tip();
        this.initData();
      });
    },
    handleCreate() {
      // 使用Dialog=>@open回调防止多次重复点击
      this.submitButtonDisabled = true;
      const {
        groupIid,
        travelAgencyId,
        realName,
        createUserId
      } = this.userData;
      const userData = {
        groupIid,
        travelAgencyId,
        realName,
        createUserId
      };
      if (!this.form.name) {
        this.$bus.tip({
          type: 'warning',
          message: '客户名称不能为空！'
        });
        return false;
      }
      const opts = {
        ...this.form,
        ...userData,
        ...this.editAccount,
        index: this.isUpdate ? this.index : -1
      };
      opts.id = this.rowId;
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      this.$store.dispatch('resource/custom/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.hide();
        this.$bus.tip();
        this.resetForm();
      });
    }
  }
};