export const systemCompanyOptions = {
  address: '',
  agencyInfoCarAdjustment: '',
  agencyInfoGuideManagement: '',
  agencyInfoHotelAdjustment: '',
  agencyInfoRestaurantAdjustment: '',
  agencyInfoService: '',
  agencyInfoTaxInvoice: '',
  agencyInfoTicketBusiness: '',
  agencyInfoTicketBusinessSupplement: '',
  agencyInfoTrade: '',
  agencyInfoUrgent: '',
  code: '',
  docStorageSpaceSize: 0,
  fixedRemark: '',
  groupId: 0,
  guideAppEnableGuilinSuperviseSystem: true,
  isEnable: true,
  isErpHideShopInfo: true,
  isOpenAgencyApp: true,
  language: '',
  logoUrl: '',
  maxUserCount: 0,
  name: '',
  pwdsSalt: '',
  remainSMSCount: 0,
  remainStorageSpaceSize: 0,
  smallLogoUrl: '',
  smsCount: 0,
  theme: '',
  type: 0
};
export const KPIType = {
  turnover: 'TURNOVER',
  numberOfPeople: 'NUMBER_OF_PEOPLE',
  profit: 'PROFIT'
};
export const systemCompanyBill = [{
  text: '散客导游出团计划单固定内容',
  label: 'fixedRemark'
}, {
  text: '出团表开票信息',
  label: 'agencyInfoTaxInvoice'
}, {
  text: '出团表票务信息',
  label: 'agencyInfoTicketBusiness'
}, {
  text: '出团表票务信息(补充)',
  label: 'agencyInfoTicketBusinessSupplement'
}, {
  text: '出团表导管信息',
  label: 'agencyInfoGuideManagement'
}, {
  text: '出团表运营信息',
  label: 'agencyInfoTrade'
}, {
  text: '出团表客服信息',
  label: 'agencyInfoService'
}, {
  text: '出团表紧急信息',
  label: 'agencyInfoUrgent'
}, {
  text: '房调信息',
  label: 'agencyInfoHotelAdjustment'
}, {
  text: '车调信息',
  label: 'agencyInfoCarAdjustment'
}, {
  text: '用餐信息',
  label: 'agencyInfoRestaurantAdjustment'
}, {
  text: '组团回执单固定信息',
  label: 'returnReceiptInfo'
}, {
  text: '用房信息',
  label: 'agencyInfoUseHotel'
}, {
  text: '景点信息',
  label: 'agencyInfoScenic'
}, {
  text: '用车信息',
  label: 'agencyInfoUseCar'
}, {
  text: '特别交代',
  label: 'agencyInfoSpecial'
}];
export const systemMember = [{
  attrs: {
    prop: 'realName',
    label: '姓名',
    sortable: true
  }
}, {
  attrs: {
    prop: 'userName',
    label: '账号',
    sortable: true
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机',
    sortable: true
  }
}, {
  attrs: {
    prop: 'roleName',
    label: '角色',
    sortable: true
  }
}];

// 员工管理
export const systemStaff = [{
  attrs: {
    prop: 'realName',
    label: '姓名',
    sortable: true,
    width: 80
  }
}, {
  attrs: {
    prop: 'userName',
    label: '账号'
  }
}, {
  attrs: {
    prop: 'departmentName',
    label: '部门',
    sortable: true,
    width: 150
  },
  slot: 'department'
},
// { attrs: { prop: 'subDepartmentName', label: '子部门' } },
{
  attrs: {
    prop: 'mobilePhone',
    label: '手机'
  }
},
// { attrs: { prop: 'telphone', label: '座机' } },
// { attrs: { prop: 'faxNumber', label: '传真' } },
{
  attrs: {
    prop: 'roleName',
    label: '角色'
  }
}, {
  attrs: {
    prop: 'lastIp',
    label: '登录ip'
  },
  slot: 'lastIp'
}, {
  attrs: {
    prop: 'ipRegion',
    label: '登录地址'
  },
  slot: 'ipRegion'
}, {
  attrs: {
    prop: 'loginTime',
    label: '登录时间'
  },
  slot: 'loginTime'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true,
    width: 100
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 角色管理
// 角色姓名	启用状态	备注	创建时间	操作
export const systemRole = [{
  attrs: {
    prop: 'name',
    label: '角色姓名',
    sortable: true
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'singleGroupAuditRole',
    label: '角色'
  },
  slot: 'role'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 公告
export const systemNotice = [{
  attrs: {
    prop: 'title',
    label: '公告标题',
    sortable: true
  }
}, {
  attrs: {
    prop: 'authAreaType',
    label: '可见范围'
  }
}, {
  attrs: {
    prop: 'userName',
    label: '创建人',
    sortable: true
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    sortable: true
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];

// 科目
export const systemAccounting = [{
  attrs: {
    prop: 'name',
    label: '科目名称'
  }
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  }
}, {
  attrs: {
    prop: 'pay',
    label: '支出'
  }
}, {
  attrs: {
    prop: 'balance',
    label: '结余'
  }
}, {
  attrs: {
    prop: 'subjectType',
    label: '科目类型'
  },
  slot: 'subjectType'
}, {
  attrs: {
    prop: 'isRevenueAndExpenditure',
    label: '属于收支科目'
  },
  slot: 'isRevenueAndExpenditure'
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    sortable: true
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];

// 科目
export const systemButtJoint = [{
  attrs: {
    prop: 'account',
    label: '账号'
  },
  slot: 'account'
}, {
  attrs: {
    prop: 'password',
    label: '密码'
  },
  slot: 'password'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}];

// 审核管理
export const systemAuditProcess = [{
  attrs: {
    prop: 'name',
    label: '审批名称'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 审核流程
export const systemOrderAuditProcess = [{
  attrs: {
    prop: 'name',
    label: '审批名称'
  }
}, {
  attrs: {
    prop: 'reviewerName',
    label: '审批流程'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 审核流程修改
export const systemOrderAuditEdit = [{
  attrs: {
    prop: 'progress',
    label: '步骤序号',
    width: 120
  }
}, {
  attrs: {
    prop: 'type',
    label: '角色/账户类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'reviewer',
    label: '审批角色/账户'
  },
  slot: 'reviewer'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 公司目标
export const systemCompanyGoal = [{
  attrs: {
    prop: 'year',
    label: '年份'
  }
}, {
  attrs: {
    prop: 'sum',
    label: '合计'
  }
}, {
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}];
export const systemCustomKpi = [{
  attrs: {
    prop: 'customerName',
    label: '客户',
    width: 120
  }
}, {
  attrs: {
    prop: 'year',
    label: '年份'
  }
}, {
  attrs: {
    prop: 'kpitype',
    label: '项目'
  },
  slot: 'kpitype'
}, {
  attrs: {
    prop: 'customerAreaService',
    label: '客服计调',
    sortable: true
  },
  slot: 'customerAreaService'
}, {
  attrs: {
    prop: 'createUserName',
    label: '业务员',
    sortable: true
  }
}, {
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 150
  },
  slot: 'action'
}];
export const wordFeeList = [{
  attrs: {
    prop: 'cashDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '下账人'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账户'
  }
}, {
  attrs: {
    prop: 'bankNo',
    label: '卡号'
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'cashActionName',
    label: '费用类型'
  }
}, {
  attrs: {
    prop: 'cash',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 110
  },
  slot: 'action'
}];