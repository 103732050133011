import "core-js/modules/es.array.push.js";
import { SHUTTLE_DEFAULT_COST_KEY as CODE_KEY, defaultShuttle } from '../config';
import { DICTIONARY } from '@/config/dictionary';
import { SelectFleet } from '@/components/common';
export default {
  components: {
    SelectFleet
  },
  data() {
    return {
      value: false,
      // 团款合理才能确认成团状态
      configId: '',
      busCompanyName: '',
      item: null
    };
  },
  created() {
    this.fetchSysConfig();
  },
  methods: {
    // 获取对接配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params).then(res => {
        if (!res || !res.data) return;
        this.processRuleData(res.data);
      });
    },
    handleSelect(val) {
      this.item = val;
    },
    processRuleData(data) {
      const findItem = data.find(it => it.code === CODE_KEY);
      if (!findItem) return;
      const {
        id,
        value,
        value1
      } = findItem;
      this.configId = id;
      this.value = !!+value1;
      if (findItem && value) {
        this.item = JSON.parse(value);
        this.busCompanyName = this.item.name;
      }
    },
    processOption(data) {
      return {
        ...defaultShuttle(),
        id: this.configId,
        value: JSON.stringify(data),
        value1: +this.value
      };
    },
    linkDictionay() {
      this.$router.push({
        path: '/system/dictionary',
        query: {
          type: DICTIONARY.stationType
        }
      });
    },
    updateConfigStatus() {
      const opt = this.processOption(this.item);
      this.$store.dispatch('system/buttJoint/setConfig', [opt]);
    }
  }
};