var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "iconfont",
    class: _vm.isFullscreen ? "icon-fullscreen-exit" : "icon-fullscreen",
    on: {
      click: _vm.click
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };