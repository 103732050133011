var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-checkbox", {
    on: {
      change: _vm.checkAuditAll
    },
    model: {
      value: _vm.getValue,
      callback: function ($$v) {
        _vm.getValue = $$v;
      },
      expression: "getValue"
    }
  }), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _vm.checkAuditAll(!_vm.checkval);
      }
    }
  }, [_vm._v(" 全选 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };