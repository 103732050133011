export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    },
    checkStatus: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    isDisabled() {
      return this.checkStatus;
    }
  }
};