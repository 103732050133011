import "core-js/modules/es.array.push.js";
import { selectorMixin, systemMixin, permissionMixin } from '@/mixin';
import { shoppingInputCreate, shoppingInputCreateAll } from '@/config/shopping';
import { mapGetters } from 'vuex';
import { CustomAuthKeys } from '@/config/permission';
const defaultState = () => ({
  isCustom: true,
  money: 0,
  shopRate: 0,
  // 社佣比例
  guideRate: 0,
  // 导佣比例
  driverRate: 0,
  // 司佣比例
  accompanyRate: 0,
  // 陪佣比例
  totalHeadRebate: 0,
  // 人头单价
  guideManagerRate: 0,
  // 导管比例
  guideManagerRebate: 0,
  // 导管金额

  guideRebateDeduction: 0,
  // 导佣扣除
  driverRebateDeduction: 0,
  // 司佣扣除
  guideRebate: 0,
  // 导佣金额
  driverRebate: 0,
  // 司佣金额
  accompanyRebate: 0,
  // 陪佣金额
  shopRebate: 0,
  subsidyRate: 0,
  subsidy: 0,
  redEnvelope: 0
});
export default {
  mixins: [selectorMixin, systemMixin, permissionMixin],
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    planId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      deletePath: 'plan/shopping/delete',
      customerTripNumberList: [],
      rowId: 10000,
      tableData: [],
      columnData: shoppingInputCreate,
      goodsList: [],
      visibility: false
    };
  },
  computed: {
    ...mapGetters({
      currentShop: 'shopping/index/currentShop'
    })
  },
  mounted() {
    this.visibility = false;
    const SubsidyTwo = this.hasSubsidyTwoPrice(CustomAuthKeys.shoppingInputSubsidyTwo); // 二返权限
    this.columnData = SubsidyTwo ? shoppingInputCreateAll : shoppingInputCreate;
    setTimeout(() => {
      this.visibility = true;
    }, 500);
    const {
      planId,
      shopId
    } = this.$route.query;
    shopId && this.fetchGoods({
      shopId,
      planId
    });
    this.fetchCustomerList(planId);
  },
  methods: {
    fetchGoods({
      shopId,
      planId
    }) {
      // 清空之前购物店的商品
      this.goodsList = [];
      this.tableData = [];
      return this.$store.dispatch('plan/shopping/fetchGoods', {
        shopId,
        planId
      }).then(data => {
        if (!data) return;
        this.goodsList = this.formatGoods(data.contractPrices);
        return data;
      });
    },
    fetchCustomerList(planId) {
      this.$store.dispatch('plan/index/findCustomerList', planId).then(data => {
        if (!data) return;
        this.customerTripNumberList = this.formatTripNumber(data);
      });
    },
    // 这个合并方法需要保留，给mixin里的删除方法使用
    rowspan() {},
    // 格式商品数据
    formatGoods(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.label = it.contentType;
        it.value = it.id;
        return it;
      });
    },
    // 格式化小团号
    formatTripNumber(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const item = {
          value: it.id,
          label: it.orderNumber
        };
        result.push(item);
      });
      return result;
    },
    // 新增一条
    handleAdd() {
      const item = {
        ...defaultState(),
        rowId: ++this.rowId
      };
      this.tableData.push(item);
    },
    // 选择小团号
    handleSelectTripNumber(row, val) {
      row.customerTripNumber = val.label;
      row.customerOrderId = val.value;
    },
    // 选择商品
    selectGoods(row, val) {
      ['shopRate', 'guideRate', 'driverRate', 'accompanyRate', 'guideManagerRate'].forEach(key => {
        row[key] = val[key];
      });
      row.name = val.label;
      this.calcRowTotalMoney(row);
    },
    // 校验输入的值
    changeInput(item) {
      this.calcRowTotalMoney(item);
    },
    // 人头返佣修改
    changeInputRateBack(row) {
      this.calcRakeBack(row);
      this.calcTravelProfit(row);
    },
    // 导游金额修改
    changeGuideInput(row) {
      this.calcGuideMoney(row);
      this.calcTravelProfit(row);
    },
    // 司机金额修改
    changeDriverInput(row) {
      this.calcDriverMoney(row);
      this.calcTravelProfit(row);
    },
    // 全陪金额修改
    changeAccompanyInput(row) {
      this.calcAccompanyMoney(row);
      this.calcTravelProfit(row);
    },
    // 导管金额修改
    changeGuideManagerRate(row) {
      this.calcGuideManagerMoney(row);
      this.calcTravelProfit(row);
    },
    changeTravelInput(row) {
      this.calcTravelProfit(row);
    },
    // 人头返佣
    calcRakeBack(row) {
      const {
        headPrice,
        enterStoreMemberCount
      } = row;
      row.totalHeadRebate = headPrice * (enterStoreMemberCount || 1);
    },
    // 导游佣金总额
    calcGuideMoney(row) {
      const {
        money,
        guideRate,
        guideRebateDeduction
      } = row;
      row.guideRebate = this.isDecimalFormat(money * guideRate / 100 - guideRebateDeduction);
    },
    // 社佣金总额
    calcShopMoney(row) {
      const {
        money,
        shopRate
      } = row;
      row.shopRebate = this.isDecimalFormat(money * shopRate / 100);
      // 社利
      this.calcTravelProfit(row);
    },
    // 司机佣金总额
    calcDriverMoney(row) {
      const {
        money,
        driverRate,
        driverRebateDeduction
      } = row;
      row.driverRebate = this.isDecimalFormat(money * driverRate / 100 - driverRebateDeduction);
    },
    // 全陪佣金总额
    calcAccompanyMoney(row) {
      const {
        money,
        accompanyRate
      } = row;
      row.accompanyRebate = this.isDecimalFormat(money * accompanyRate / 100);
    },
    // 导管佣金总额
    calcGuideManagerMoney(row) {
      const {
        money,
        guideManagerRate
      } = row;
      // 取整数 四舍五入
      row.guideManagerRebate = this.isDecimalFormat(money * guideManagerRate / 100);
    },
    // 社利总额
    calcTravelProfit(row) {
      // 社利总额 = 社佣金额（打单金额 * 社佣比例）- 导佣总额（打单金额 * 导佣比例）- 司佣总额（打单金额 * 司佣比例） - 陪佣总额（打单金额 * 陪佣比例） + 人头佣金(单价 * 人数）
      const {
        shopRebate,
        accompanyRebate,
        driverRebate,
        guideRebate,
        guideManagerRebate
      } = row;
      const unit = 100;
      const res = shopRebate * unit - guideRebate * unit - driverRebate * unit - guideManagerRebate * unit - accompanyRebate * unit;
      row.travelGrossProfit = this.isDecimalFormat(res / 100);
    },
    // 计算单行总额
    calcRowTotalMoney(row) {
      this.calcGuideMoney(row);
      this.calcDriverMoney(row);
      this.calcAccompanyMoney(row);
      this.calcShopMoney(row);
      this.calcGuideManagerMoney(row);
      this.calcTravelProfit(row);
    },
    createOrUpdate() {
      if (!this.validateForm()) return;
      const dataList = this.beforeCommit(this.tableData);
      this.$store.dispatch('plan/shopping/update', dataList).then(() => {
        // 是否需要更新购物输入          this.$store.commit('shopping/index/RELOAD_DATA', true)
        this.$nextTick(() => {
          this.goBack();
        });
      });
    },
    goBack() {
      this.$router.push({
        path: '/shopping/input'
      });
    },
    beforeCommit(list) {
      const result = [];
      const {
        shopId,
        guideId,
        orderDate,
        headPrice,
        totalParkingFeeRebated,
        shopAgencyProfit
      } = this.form;
      const {
        planId,
        rowId
      } = this.$route.query;
      const item = {
        id: rowId,
        shopId,
        guideId,
        orderDate,
        headPrice,
        totalParkingFeeRebated,
        shopAgencyProfit,
        planId,
        shopGoods: []
      };
      list.forEach(it => {
        item.shopGoods.push(it);
      });
      result.push(item);
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          money,
          customerOrderId
        } = row;
        if (!customerOrderId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择订单号！'
          });
          return false;
        }
        if (!money) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写打单金额！'
          });
          return false;
        }
      }
      return true;
    }
  }
};