import { SELECTOR, TICKET_MAP } from '@/config';
import { orderScenicInfo } from '@/config/resourceOrder';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: orderScenicInfo
    };
  },
  computed: {
    getData() {
      const key = TICKET_MAP[this.data.scenicName];
      const ticketList = this.data ? SELECTOR[key] : [];
      const obj = {
        ...this.data,
        ticketList
      };
      return this.data ? [obj] : [];
    }
  },
  methods: {
    handleCommit() {
      return this.$store.dispatch('order/resource/bookingScenicSet', this.getData[0]);
    }
  }
};