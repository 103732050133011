import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { SelectCustom, SelectStaff, SelectDictionary } from '@/components/common';
import { DefaultState, GetTargetType, KPIType, MonthKey, MonthObjKey, KPIType2Prop, Rules as rules } from './config';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    SelectDictionary
  },
  data() {
    return {
      rules,
      visible: false,
      form: {},
      tableCount: []
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    edit(customerId) {
      this.visible = true;
      this.changeCustom({
        value: customerId
      });
    },
    hide() {
      this.visible = false;
      this.form = {};
      this.tableCount = [];
      this.$refs.formRef.resetFields();
    },
    fetchData(params) {
      this.$store.dispatch('system/index/fetchCustomerYearKpiEdit2', params).then(data => {
        if (!data) return;
        const d = this.formatData(data);
        this.tableCount = d;
      });
    },
    findType(data, key) {
      return data.find(it => it.kpitype == key);
    },
    formatMonthDataByKey(data) {
      return MonthKey.map(month => {
        return data.reduce((acc, v) => {
          const monthVal = `${MonthObjKey[month]}月`;
          const apiType = KPIType2Prop[v.kpitype];
          return {
            ...acc,
            monthKey: month,
            month: monthVal,
            [apiType]: v[month]
          };
        }, {});
      });
    },
    formatData(data) {
      const {
        groupTypes
      } = data;
      const {
        customerId,
        customerName,
        outOPUserId,
        outOPUserName,
        saleUserId,
        saleUserName,
        year
      } = data;
      this.form = {
        ...this.form,
        customerId,
        customerName,
        outOPUserId,
        outOPUserName,
        saleUserId,
        saleUserName,
        year
      };
      return groupTypes.map(it => {
        const {
          groupType,
          productType
        } = it;
        const {
          months
        } = it;
        const list = this.formatMonthDataByKey(months);
        return {
          groupType,
          productType,
          $groupType: groupType,
          $productType: productType,
          list
        };
      });
    },
    initTableData() {
      const {
        groupType,
        productType,
        $groupType,
        $productType
      } = GetTargetType();
      const list = Array.from({
        length: 12
      }).map((it, idx) => {
        return DefaultState(idx);
      });
      return {
        list,
        groupType,
        productType,
        $groupType,
        $productType
      };
    },
    changeType(type, item, {
      label
    }) {
      item[type] = label;
    },
    addTable() {
      const data = this.initTableData();
      this.tableCount.push(data);
    },
    delTable(index, {
      isCustom
    }) {
      this.tableCount.splice(index, 1);
      if (!isCustom) {
        this.$bus.open(() => {
          this.$store.dispatch('system/index/delCustomerKpi', {
            customerId: this.form.customerId,
            year: new Date().getFullYear()
          });
        }, '确定要删除吗？');
      }
    },
    changeCustom({
      value
    }) {
      this.fetchData({
        customerId: value,
        year: new Date().getFullYear()
      });
      this.$store.dispatch('resource/custom/fetchDetail', value).then(data => {
        if (!data) return;
        const {
          name: customerName,
          saleUserId: salesmanId,
          saleUserName,
          outOPUserId,
          outOPUserName
        } = data;
        this.form = {
          ...this.form,
          customerName,
          salesmanId,
          saleUserName,
          outOPUserId,
          outOPUserName
        };
      });
    },
    fillCurMonth(row, index) {
      const cur = this.tableCount[index];
      cur.list = cur.list.map(it => ({
        ...row,
        monthKey: it.monthKey,
        month: it.month
      }));
    },
    beforeCommit() {
      const pickerVal = (list, key) => {
        return list.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.monthKey]: cur[key]
          };
        }, {});
      };
      const getTableForType = key => {
        return this.tableCount.map(it => {
          const {
            groupType,
            productType,
            list
          } = it;
          const monthObj = pickerVal(list, key);
          const year = new Date().getFullYear();
          const kpitype = KPIType[key];
          return {
            groupType,
            productType,
            year,
            kpitype,
            ...monthObj,
            ...this.form
          };
        });
      };
      const countObj = getTableForType('count');
      const profitObj = getTableForType('profit');
      const turnoverObj = getTableForType('turnover');
      return [...countObj, ...profitObj, ...turnoverObj];
    },
    handleCreate() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const opt = this.beforeCommit();
        this.$store.dispatch('system/index/saveCustomerKpi', opt).then(() => {
          this.$bus.tip();
          this.hide();
          this.$emit('reload');
        });
      });
    }
  }
};