var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "refresh",
    style: _vm.customStyle,
    on: {
      click: _vm.refresh
    }
  }, [_c("i", {
    staticClass: "icon el-icon-refresh-left"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };