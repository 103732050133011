var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("InputData", _vm._g(_vm._b({
    attrs: {
      data: _vm.getInputList,
      label: _vm.label,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      placeholder: "请选择客户"
    },
    on: {
      "on-load": _vm.loadMoreData,
      focus: _vm.lazyLoad
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners), [_vm.sourceType == _vm.customer ? [_c("span", {
    staticStyle: {
      float: "left"
    },
    attrs: {
      value: _vm.item.value
    }
  }, [_vm._v(_vm._s(_vm.item.label))]), _c("span", {
    staticStyle: {
      float: "right",
      color: "#8492a6",
      "font-size": "13px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.province) + " " + _vm._s(_vm.item.city) + " ")])] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };