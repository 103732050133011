var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curOrder ? _c("div", {
    staticClass: "order-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础信息")]), _c("FormBase", {
    attrs: {
      inline: "",
      "label-width": "100px"
    }
  }, [_c("div", {
    staticClass: "form-item-group"
  }, [_c("FormItemBase", {
    attrs: {
      label: "大团号:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.planNumber) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "线路产品:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.productName) + " " + _vm._s(_vm.curOrder.planType == "T_TOURIST" && _vm.curOrder.productType ? `(${_vm.curOrder.productType})` : "") + " ")]), _c("FormItemBase", {
    attrs: {
      label: "参团人数:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.adultCount) + "大" + _vm._s(_vm.curOrder.childCount) + "小 " + _vm._s(_vm.curOrder.accompanyCount) + "陪 ")]), _c("FormItemBase", {
    attrs: {
      label: "操作计调:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.dutyOPUserName) + " ")])], 1), _c("div", {
    staticClass: "form-item-group"
  }, [_c("FormItemBase", {
    attrs: {
      label: "发团日期:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.startPlanDate) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "散团日期:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.endPlanDate) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "发团地点:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.startPlanPosition) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "散团地点:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.endPlanPosition) + " ")])], 1), _c("div", {
    staticClass: "form-item-group"
  }, [_c("FormItemBase", {
    attrs: {
      label: "团上导游:"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatGuides(_vm.curOrder.guides)) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "出发大交通:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.customerContactMobileNumber) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "返回大交通:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.endPlanTransport) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "友情提醒:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.friendlyReminderRemark) + " ")])], 1), _vm._l(_vm.tableData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "form-item-group red"
    }, [_c("FormItemBase", {
      attrs: {
        label: "车队公司:"
      }
    }, [_vm._v(" " + _vm._s(item.busCompanyName) + " ")]), _c("FormItemBase", {
      attrs: {
        label: "车辆类型:"
      }
    }, [_vm._v(" " + _vm._s(item.busTypeName) + "(金额：" + _vm._s(item.busFee) + " ) ")]), _c("FormItemBase", {
      attrs: {
        label: "司机:"
      }
    }, [_vm._v(" " + _vm._s(item.driverName) + " " + _vm._s(item.driverPhone) + " ")]), _c("FormItemBase", {
      attrs: {
        label: "车牌号:"
      }
    }, [_vm._v(" " + _vm._s(item.licenceNumber) + " ")])], 1);
  })], 2), _c("FormBase", {
    attrs: {
      "label-width": "100px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "备注:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.remark) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "内部备注:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.innerRemark) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "服务标准:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.serviceStandardRemark) + " ")]), _c("FormItemBase", {
    attrs: {
      label: "接站牌:"
    }
  }, [_vm._v(" " + _vm._s(_vm.curOrder.welcomeBorad) + " ")])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };