/* eslint-disable no-unused-vars */
// 角色权限
import { createRole, updateRole, deleteRole, fetchRole, permissionRole, getRoleCustomerAuth, permissionUpdateRole, importRolePermission } from '@/api/system/role';
import { formatCreateTime, formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
function handleFetch(queryObj) {
  return fetchRole(queryObj).then(res => {
    const {
      data
    } = res;
    let {
      list
    } = data;
    data.list = list.map(formatCreateTime);
    data.list = formatCharData(list);
    const originData = {
      ...data
    };
    return originData;
  });
}
const dfaultState = () => ({
  roleData: null,
  roleSelectData: null,
  rolePermissionList: null,
  curRoleMenu: [],
  roleCustomerInfoAuth: null
});
const state = dfaultState();
const getters = {
  roleData: state => state.roleData,
  roleSelectData: state => state.roleSelectData,
  curRoleMenu: state => state.curRoleMenu,
  rolePermissionList: state => state.rolePermissionList,
  roleCustomerInfoAuth: state => state.roleCustomerInfoAuth
};
const actions = {
  // 创建角色
  create({
    rootGetters
  }, opts) {
    const userData = rootGetters['user/userData'];
    const {
      createUserId,
      groupId,
      travelAgencyId
    } = userData;
    opts = {
      ...opts,
      createUserId,
      groupId,
      travelAgencyId
    };
    return new Promise((resolve, reject) => {
      createRole(opts).then(data => {
        data.list = formatCharData(data.list);
        resolve(data);
      }).catch(reject);
    });
  },
  // 更新角色
  update({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      return updateRole(opts).then(resolve).catch(reject);
    });
  },
  // 删除角色
  delete({
    commit
  }, roleId) {
    return new Promise((resolve, reject) => {
      return deleteRole(roleId).then(resolve).catch(reject);
    });
  },
  // 获取角色查看客户
  getRoleCustomerAuth({
    commit
  }, roleId) {
    return getRoleCustomerAuth(roleId).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      commit('CUSTOMER_INFO_AUTH', data);
    });
  },
  // 获取角色列表
  fetch({
    commit,
    dispatch
  }, queryObj) {
    queryObj = {
      ...DEFAULT_PAGE,
      ...queryObj
    };
    return handleFetch(queryObj).then(data => {
      commit('FETCH_ROLE', data);
      return data;
    });
  },
  // 角色下拉列表
  fetchRole({
    commit,
    state
  }, queryObj) {
    queryObj = {
      ...DEFAULT_PAGE,
      ...queryObj
    };
    return handleFetch(queryObj).then(data => {
      // const { list } = data
      // const lastList = state.roleSelectData ? state.roleSelectData.list : []
      // data.list = [...lastList, ...list]
      commit('FETCH_ROLE_SELECT', data);
      return data;
    });
  },
  // 获取角色权限列表
  fetchPermission({
    commit,
    rootGetters
  }, roleId) {
    const {
      roleId: rid
    } = rootGetters['user/userData'];
    const id = roleId || rid;
    return permissionRole(id).then(res => {
      const {
        data
      } = res;
      data.roleMenus = data.roleMenus.filter(it => it.code.indexOf('mall') < 0);
      commit('ROLE_PERMISSION', data.roleMenus);
      return data.roleMenus;
    });
  },
  // 更新角色权限
  updatePermission({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      return permissionUpdateRole(opts).then(resolve).catch(err => reject(err));
    });
  },
  // 更新角色权限
  importPermission({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      return importRolePermission(opts).then(resolve).catch(err => reject(err));
    });
  }
};
const mutations = {
  FETCH_ROLE: (state, data) => {
    state.roleData = data;
  },
  FETCH_ROLE_SELECT: (state, data) => {
    state.roleSelectData = data;
  },
  ROLE_PERMISSION: (state, data) => {
    state.rolePermissionList = data;
  },
  CUR_ROLE_MENU: (state, data) => {
    state.curRoleMenu = data;
  },
  CUSTOMER_INFO_AUTH: (state, data) => {
    state.roleCustomerInfoAuth = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};