var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "billdown-dialog-update-pay",
    attrs: {
      title: "修改付款记录",
      width: "1200px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide,
      confirm: _vm.handleConfirm
    }
  }, [_vm.cashItem ? _c("FormItem", {
    ref: "formItem",
    attrs: {
      "res-name": _vm.resourceName,
      obj: _vm.cashItem
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };