export const columnLen = 5;
export const columnInfo = [{
  attrs: {
    label: '产品名称',
    prop: 'productName',
    width: 80
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '团号',
    prop: 'planNumber',
    width: 50
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '人数',
    prop: 'number',
    align: 'center',
    width: 60
  },
  content: {
    width: 100
  },
  slot: 'number'
}, {
  attrs: {
    label: '用车单位',
    prop: 'busCompanyNames',
    width: 60
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '车型',
    prop: 'busCompanyTypes',
    width: 50
  },
  content: {
    width: 100
  }
}, {
  attrs: {
    label: '接团日期',
    prop: 'arriveDate'
  }
}, {
  attrs: {
    label: '接团航班车次',
    prop: 'arriveInto'
  }
}, {
  attrs: {
    label: '全陪',
    prop: 'accompanyInfo'
  }
}, {
  attrs: {
    label: '车号',
    prop: 'licenceNumbers'
  }
}, {
  attrs: {
    label: '车费',
    prop: 'busFees'
  },
  slot: 'busFees'
}, {
  attrs: {
    label: '送团日期',
    prop: 'leaveDate'
  }
}, {
  attrs: {
    label: '送团航班车次',
    prop: 'leaveInto'
  }
}, {
  attrs: {
    label: '地陪',
    prop: 'guideName'
  }
}, {
  attrs: {
    label: '车调',
    prop: 'bus'
  },
  slot: 'bus'
}, {
  attrs: {
    label: '车队备注',
    prop: 'busCompanyRemarks'
  }
}, {
  attrs: {
    label: '接站牌',
    prop: 'welcomeBorad'
  }
}, {
  attrs: {
    label: '客服',
    prop: 'outOPUserName'
  }
}, {
  attrs: {
    label: '操作计调',
    prop: 'dutyOPUserName'
  }
}, {
  attrs: {
    label: '房调',
    prop: 'hotel'
  },
  slot: 'hotel'
}, {
  attrs: {
    label: '司机',
    prop: 'driverName'
  }
}, {
  attrs: {
    label: '游客联系人',
    prop: 'members'
  }
}];
export const columnTrip = [{
  attrs: {
    label: '日期',
    prop: 'itineraryDate',
    width: 80
  }
}, {
  attrs: {
    label: '行程',
    prop: 'scenicName'
  }
}, {
  attrs: {
    label: '酒店',
    prop: 'roomNames',
    align: 'center',
    width: 160
  },
  slot: 'roomNames'
}, {
  attrs: {
    label: '用餐',
    prop: 'mealTypes',
    width: 60
  }
}];
export const columnOther = [{
  attrs: {
    label: '服务标准',
    prop: 'serviceStandardRemark',
    width: 80
  }
}, {
  attrs: {
    label: '用房',
    prop: 'hotelRemark',
    width: 80
  }
}, {
  attrs: {
    label: '用餐',
    prop: 'restaurantText'
  }
}, {
  attrs: {
    label: '用车',
    prop: 'busRemark'
  }
}, {
  attrs: {
    label: '景点',
    prop: 'scenicRemark'
  }
}, {
  attrs: {
    label: '特别交代',
    prop: 'friendlyReminderRemark',
    width: 80
  }
}, {
  attrs: {
    label: '开票信息',
    prop: 'agencyInfoTaxInvoice'
  },
  slot: 'tax'
}, {
  attrs: {
    label: '票务',
    prop: 'agencyInfoTicketBusiness'
  }
}, {
  attrs: {
    label: '运营',
    prop: 'agencyInfoTrade'
  }
}, {
  attrs: {
    label: '客服',
    prop: 'agencyInfoService'
  }
}, {
  attrs: {
    label: '公司紧急',
    prop: 'agencyInfoUrgent'
  }
}];