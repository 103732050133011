import { InputFleet } from '@/components/common/Input';
export default {
  components: {
    InputFleet
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleSelect(item) {
      this.form.resId = item.id;
    },
    handleConfirm() {
      if (!this.form.resId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择车队'
        });
        return;
      }
      this.$emit('confirm', this.form);
    }
  }
};