// 支付宝企业码
import request from '@/utils/request';

// 签约旅行社企业码
export function signRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/sign-alipay-enterprise-code',
    method: 'get',
    params
  });
}

// 解约旅行社企业码
export function removeSignRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/unSign-alipay-enterprise-code',
    method: 'get',
    params
  });
}

// 注销旅行社企业码
export function logoffSignRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-alipay-enterprise-code',
    method: 'get',
    params
  });
}

// 查询制度详情
export function alipayInstitutionRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/query-institution',
    method: 'get',
    params
  });
}