import dayjs from 'dayjs';
import { tableMixin } from './mixin';
import { tagViewMixin, searchMixin } from '@/mixin';
import { SelectGuide } from '@/components/common';
import { mapGetters } from 'vuex';
const dateArr = val => dayjs(val).format('YYYY-MM-DD');
const today = dateArr(new Date());
export default {
  components: {
    SelectGuide
  },
  mixins: [tableMixin, tagViewMixin, searchMixin],
  data() {
    return {
      isAudit: true,
      searchParams: {
        startTripDateS: today
      }
    };
  },
  computed: {
    ...mapGetters({
      ticketPlanAuditData: 'ticket/index/ticketPlanAuditData'
    })
  },
  watch: {
    ticketPlanAuditData: {
      handler: 'initData',
      immediate: true
    }
  },
  mounted() {
    this.onEmit();
  },
  methods: {
    onEmit() {
      this.$bus.on('ticket-check:reload', this.handleReload);
    },
    changeStartDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.startTripDateS = startDate || '';
      this.searchParams.startTripDateE = endDate || '';
    },
    changeEndDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.endTripDateS = startDate || '';
      this.searchParams.endTripDateE = endDate || '';
    },
    selectGuide(val) {
      const {
        label
      } = val;
      this.searchParams.guideName = label;
    },
    handleReload() {
      this.fetchData();
      this.closeFindTagView('ticketCheckDetail');
    },
    fetchData(queryParams) {
      this.tableLoading = true;
      this.$store.dispatch('ticket/index/fetchPlanAudit', queryParams).then(data => {
        this.tableLoading = false;
        this.processData(data);
      }).catch(() => {
        this.tableLoading = false;
      });
    }
  }
};