import { mapGetters } from 'vuex';
import { SelectAccount, SelectStaff } from '@/components/common';
import { formMixin } from '@/mixin';
import { parseDate } from '@/utils';
import { defaultCard, cardRules } from '../config';
export default {
  components: {
    SelectAccount,
    SelectStaff
  },
  mixins: [formMixin],
  props: {
    title: {
      type: String,
      default: '卡内调账'
    },
    name: {
      type: String,
      default: '收款人'
    }
  },
  data: () => ({
    isRequire: true,
    visible: false,
    form: defaultCard(),
    validateForm: cardRules
  }),
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      oneList: 'system/accounting/oneList'
    })
  },
  watch: {
    userData: {
      handler: 'getUserData',
      immediate: true
    }
  },
  methods: {
    show(scope) {
      const {
        id,
        name,
        cardNo
      } = scope.row;
      this.form.bankId = id;
      this.form.accountCardNo = name + '——' + cardNo;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.resetForm();
    },
    resetForm() {
      this.form = defaultCard();
    },
    getUserData(val) {
      if (val) {
        this.form.cashUserName = val.realName;
        this.form.cashUserId = val.id;
      }
    },
    changeOPUser(val) {
      // this.form.cashUserId = val.id
      this.form.cashUserName = val.label;
    },
    handleSelectScenic(val) {
      this.form.subjectId = val.id;
      this.form.subjectName = val.name;
    },
    handleCreate() {
      if (this.form.cashDate) {
        this.form.cashDate = parseDate(this.form.cashDate);
      }
      // this.form.desBankId = this.userData.id
      this.form.money = this.form.moneyMinute;
      if (!this.handleValidate()) return;
      this.$store.dispatch('resource/account/fetchAdjust', this.form).then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};