import "core-js/modules/es.array.push.js";
import { offerOperate } from '@/config/offer';
import { TopTitle } from '@/components/common';
import offerMixin from '../offerMixin';
export default {
  components: {
    TopTitle
  },
  mixins: [offerMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: offerOperate,
      total: 0
    };
  },
  methods: {
    handlePlus() {
      this.tableData.push({
        price: 0,
        cost: 0
      });
    },
    onSelect(row, val) {
      row.area = val.name;
    },
    changeInput(row) {
      const {
        price
      } = row;
      row.cost = price;
      this.total = price;
    }
  }
};