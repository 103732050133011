import FormItem from './FormItem.vue';
import FormAccount from './FormAccount.vue';
import { formState, formBankState } from '../config';
import { mapGetters } from 'vuex';
export default {
  components: {
    FormItem,
    FormAccount
  },
  data() {
    return {
      visible: false,
      form: formState(),
      formBank: formBankState()
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData' // 账户信息
    }),
    getTitle() {
      return '添加购物店';
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.form = formState();
      this.formBank = formBankState();
      this.visible = false;
    },
    filterData(val) {
      const {
        data
      } = val;
      const opts = {
        name: data
      };
      this.$store.dispatch('resource/shopping/searchTravelSuperviseeDic', opts);
    },
    handleCreate() {
      const {
        formRef
      } = this.$refs.formItemRef.$refs;
      const {
        formRef: formBankRef
      } = this.$refs.formBankItemRef.$refs;
      formRef.validate(valid => {
        if (!valid) {
          return;
        }
        formBankRef.validate(valid => {
          if (!valid) {
            return;
          }
          const {
            id,
            groupId,
            travelAgencyId,
            realName
          } = this.userData;
          const userData = {
            createUserId: id,
            groupId,
            travelAgencyId,
            realName
          };
          const {
            model: modelData
          } = formRef;
          const {
            model: modelBank
          } = formBankRef;
          const opts = {
            ...userData,
            ...modelData,
            ...modelBank,
            perCapita: +modelData.perCapita
          };
          this.createOrUpdate(opts);
        });
      });
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/createOrUpdate', opts).then(() => {
        this.$emit('reload');
        this.$bus.tip();
        this.hide();
        loading.close();
      }).catch(() => {
        loading.close();
        this.hide();
      });
    }
  }
};