import { tableMixin, permissionMixin } from '@/mixin';
import { TypeOrder, PersonCount } from '@/components/common';
import { CustomerInfo } from '@/components/common';
export default {
  components: {
    TypeOrder,
    PersonCount,
    CustomerInfo
  },
  mixins: [tableMixin, permissionMixin],
  methods: {
    handleRecovery(scope, type) {
      this.$parent.recovery(scope, type);
    }
  }
};