import "core-js/modules/es.array.push.js";
import { SelectCustom, SelectStaff, BtnExport, InputMonthRange, TableTotalBox } from '@/components/common';
import { incomeBalanceList } from '@/config/report';
// import { mergeTableRow } from '@/utils/table'
import { searchMixin, excelMixin } from '@/mixin';
import { exportTable } from '@/utils/exportTableUtil';
import { parseTime } from '@/utils';
const today = parseTime(new Date(), '{y}-{m}');
const Jan = parseTime(new Date(), '{y}') + '-01';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    BtnExport,
    InputMonthRange,
    TableTotalBox
  },
  mixins: [searchMixin, excelMixin],
  data: () => ({
    columnData: incomeBalanceList,
    tableData: [],
    sendMonth: [Jan, today],
    //queryParam
    total: 0,
    statisticData: [],
    searchParams: {
      startMonth: Jan,
      endMonth: today
    },
    //startMonth: Jan, endMonth: '2022-04'
    statistic: {},
    statisticsKeys: []
  }),
  mounted() {
    // this.fetchColumnData(this.searchParams)
    // this.fetchData(this.searchParams)
  },
  methods: {
    fetchData(queryParam) {
      const {
        startMonth,
        endMonth
      } = queryParam;
      if (!startMonth || !endMonth) {
        this.$bus.tip({
          type: 'warning',
          message: '查询年月开始时间和结束时间不能为空！'
        });
        return false;
      }
      this.$store.dispatch('report/index/fetchCustomeIncomeList', queryParam).then(res => {
        // 去除客户为空或者没有区域经理的数据
        const list = res.filter(it => it.customerId && it.customer && it.customer.customerAreaManagerId > 0).sort((a, b) => {
          return a.customer.customerAreaManagerId - b.customer.customerAreaManagerId;
        });
        const data = this.formatData(list);
        // 分组
        const group = this.formatList(data);
        const sumArray = this.formatSumData(group);
        // this.statistic = this.formatSum(sumArray)
        this.statisticData = this.formatSum(sumArray);
        this.tableData = this.formatMergeData(group, sumArray);
      });
    },
    // 计算合计
    formatSum(list) {
      if (!list.length) return [];
      let t = {
        total: 0
      };
      let i = {
        total: 0
      };
      let s = {
        total: 0
      };
      list.forEach(it => {
        t.total += it.total;
        t.customerName = '合计';
        if (it.total > 0) {
          i.total += it.total;
          i.customerName = '应收合计';
        } else {
          s.total += it.total;
          s.customerName = '应付合计';
        }
      });
      return [i, s, t];
    },
    // 合并小计和重构后的数组
    formatMergeData(sortList, sumArray) {
      let list = [];
      sortList.forEach(it => {
        list = [...list, ...it];
      });
      list = [...list, ...sumArray];
      list.sort((a, b) => {
        return a.customer.customerAreaManagerId - b.customer.customerAreaManagerId;
      });
      return list;
    },
    // 计算小计
    formatSumData(list) {
      let sumArray = [];
      list.forEach(item => {
        let sumObj = {
          total: 0
        };
        item.forEach(it => {
          sumObj.customer = it.customer;
          sumObj.total += it.total;
          sumObj.indexMax = '小计';
          sumObj.outOPUserName = it.outOPUserName;
          const text = sumObj.total < 0 ? '应付账款' : '应收账款';
          sumObj.customerName = it.outOPUserName + text;
        });
        sumArray.push({
          ...sumObj,
          isTotal: sumObj.total < 0 ? 2 : 1
        });
      });
      return sumArray;
    },
    // 分组
    formatList(list) {
      const objBy = (array, f) => {
        let objs = {};
        array.forEach(it => {
          let obj = JSON.stringify(f(it));
          objs[obj] = objs[obj] || [];
          objs[obj].push(it);
        });
        return Object.keys(objs).map(v => {
          return objs[v];
        });
      };
      const sorted = objBy(list, item => {
        return item.outOPUserId;
      });
      // 返回分组并添加上序号
      return sorted.map(it => {
        it.forEach((v, i) => {
          v.index = i + 1;
        });
        return it;
      });
    },
    // 重构基础数据
    formatData(list) {
      if (!list.length) return [];
      return list.map((it, i) => {
        it.total = 0;
        it.isTotal = false;
        it.indexMax = i + 1;
        it.customerName = it.customer.name;
        it.outOPUserId = it.customer.customerAreaManagerId;
        it.outOPUserName = it.customer.customerAreaManager;
        it.mergeId = it.customer.customerAreaManagerId;
        it.yearMonthIncomes.forEach(v => {
          it.total += v.unIncome;
        });
        return it;
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        statistics
      } = data;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    // 选择散、发团日期
    changeMonth(date) {
      this.searchParams.startMonth = date[0];
      this.searchParams.endMonth = date[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 指定设置颜色
    tableRowClassName({
      row
    }) {
      if (row.isTotal == 2) {
        return 'warning-row';
      }
      if (row.isTotal == 1) {
        return 'primary-row';
      }
      return '';
    },
    // 导出 excel
    exportExcel() {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有数据导出！'
        });
        return false;
      }
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt = document.getElementById('export-income-table');
      exportTable(elt, '应收账款余额表', '应收账款余额表导出', 1);
      loading.close();
    }
  }
};