import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    teamPath: '/order/team-input-update',
    teamClonePath: '/order/team-input-update',
    personalPath: '/order/personal-input-update'
  }),
  computed: {
    ...mapGetters({
      tagViewsList: 'tagsView/visitedViews',
      cachedViews: 'tagsView/cachedViews'
    })
  },
  methods: {
    findTagView(name) {
      return this.tagViewsList.find(it => it.name === name);
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    closeFindTagView(name) {
      const tagView = this.findTagView(name);
      tagView && this.closeSelectedTag(tagView);
    },
    closeSelectedTag(tagView) {
      const teamPathArr = ['/order/team-input-update', '/order/team-input-clone'];
      const personalPathArr = ['/order/personal-input-update', '/order/personal-input-clone'];
      const curPath = this.$route.path;

      // 团队/散客订单更新页面关闭，重新刷新数据，因为数据是从store里获取的，不确定是否有数据改动
      if (tagView && teamPathArr.includes(tagView.path)) {
        // 更新数据
        this.$store.commit('order/index/NEED_RELOAD', {
          type: 'Team',
          flag: true
        });
        this.$router.replace({
          path: '/order/team'
        });
      }
      if (tagView && personalPathArr.includes(tagView.path)) {
        // 更新数据
        this.$store.commit('order/index/NEED_RELOAD', {
          type: 'Personal',
          flag: true
        });
        this.$router.replace({
          path: '/order/personal'
        });
      }

      // 如果已经访问过所有订单，重新获取所有订单数据
      const hasOrderAllRoaded = this.cachedViews.some(it => it == 'orderAll');
      if (hasOrderAllRoaded) {
        this.$store.dispatch('order/index/fetchAll');
      }
      const curTagView = tagView ? tagView : this.tagViewsList.find(tag => tag.path == curPath);
      this.$store.dispatch('tagsView/delView', curTagView).then(({
        visitedViews
      }) => {
        if (this.isActive(curTagView)) {
          this.toLastView(visitedViews, curTagView);
        }
      });
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          this.$router.push('/');
        }
      }
    }
  }
};