var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "product-remarks"
  }, [_c("ul", {
    staticClass: "list"
  }, _vm._l(_vm.remarkList, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "list-item"
    }, [_c("strong", [_vm._v(_vm._s(item.text))]), _c("div", {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(item.value) + " ")])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };