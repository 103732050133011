var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "600px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      inline: "",
      "label-width": "140px"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "startDate",
      label: "额度有效开始时间"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: ""
    },
    model: {
      value: _vm.form.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  })], 1), _c("div", {
    staticClass: "mt20"
  }), _c("el-form-item", {
    attrs: {
      prop: "endDate",
      label: "额度有效结束时间"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: ""
    },
    model: {
      value: _vm.form.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  })], 1), _c("div", {
    staticClass: "mt20"
  }), _c("el-form-item", {
    attrs: {
      prop: "tmpCreditMoney",
      label: "临时额度"
    }
  }, [_c("InputNum", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.tmpCreditMoney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tmpCreditMoney", $$v);
      },
      expression: "form.tmpCreditMoney"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };