var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form"
  }, [_c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 客户名称：" + _vm._s(_vm.form.name) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 客户类型：" + _vm._s(_vm.form.customerType) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 客户代码：" + _vm._s(_vm.form.codeName) + " ")])], 1), _c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 授信额度：" + _vm._s(_vm.form.balance) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 区域：" + _vm._s(_vm.form.customerArea) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 区域经理：" + _vm._s(_vm.form.customerAreaManager) + " ")])], 1), _c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 区域客服：" + _vm._s(_vm.form.customerAreaService) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 省市区：" + _vm._s(_vm.form.province) + _vm._s(_vm.form.city) + _vm._s(_vm.form.district) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 账龄：" + _vm._s(_vm.form.accountAge) + "月 ")])], 1), _c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 法人代表：" + _vm._s(_vm.form.legalPerson) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 法人电话号码：" + _vm._s(_vm.form.legalPersonPhone) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 法人身份证：" + _vm._s(_vm.form.legalPersonIDCard) + " ")])], 1), _c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 业务负责人：" + _vm._s(_vm.form.businessPrincipal) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 业务负责人电话：" + _vm._s(_vm.form.businessPrincipalPhone) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 业务负责人身份证：" + _vm._s(_vm.form.businessPrincipalIDCard) + " ")])], 1), _c("el-form-item", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 详细地址：" + _vm._s(_vm.form.addr) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.remarks) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("SwitchStatus", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)], 1)], 1), _c("el-divider"), _c("h3", [_vm._v("联系人信息")]), _c("TableContact", {
    attrs: {
      "table-data": _vm.tableContact,
      "column-data": _vm.columnContact
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };