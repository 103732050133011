import { DialogPwd, DialogForm, TableList, GuideDetail, DialogInvite } from './components';
import { resourceMixin, searchMixin } from '@/mixin';
import { SelectDictionary, BtnCreate, DialogAgreement, DialogLog } from '@/components/common';
import { resourceGuide } from '@/config/resource';
import { formatTableData } from '@/utils/data';
import { mapGetters } from 'vuex';
const defaultState = {
  info: '',
  type: ''
};
export default {
  components: {
    BtnCreate,
    SelectDictionary,
    DialogAgreement,
    DialogLog,
    DialogPwd,
    DialogForm,
    DialogInvite,
    TableList,
    GuideDetail
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceGuide,
    tableData: [],
    form: {
      contact: '',
      isEnable: true,
      selectValue: ''
    },
    total: 0,
    searchParams: defaultState,
    type: ''
  }),
  computed: {
    ...mapGetters({
      guideData: 'resource/guide/guideDataOrigin',
      // 导游信息
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData' // 字典信息
    })
  },
  watch: {
    guideData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/guide/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/guide/fetchList');
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guide/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteRow(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guide/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleSelect(val) {
      this.searchParams.type = val.label;
    },
    onSelectArea(val) {
      this.searchParams.area = val.label;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog(item) {
      this.$refs.dialogFormRef.show(item);
    },
    showPwdDialog(phone) {
      this.$refs.dialogPwdRef.show(phone);
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showCreateDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row);
    },
    showAgreement(scope) {
      this.$refs.agreementRef.show(scope.row);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/guide/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handleInvite(ids) {
      const doInvite = guideId => {
        this.$store.dispatch('enterprise/guide/addInvite', {
          guideId
        }).then(res => {
          console.log(res.signUrl);
          if (ids.length) {
            setTimeout(() => {
              doInvite(ids.pop());
            }, 1000);
          }
        });
      };
      doInvite(ids.pop());

      // Promise.all(
      //   ids.map(guideId =>
      //     this.$store.dispatch('enterprise/guide/addInvite', { guideId })
      //   )
      // ).then(res => {
      //   if (!res) return
      //   const { signUrl } = res.data

      //   this.$bus.tip({
      //     message: `邀请链接：${signUrl}, 请复制该链接发给导游确认`,
      //     duration: 0,
      //   })
      //   this.handleCopy(signUrl)
      // })
    },
    batchInvate() {
      const selectedList = this.$refs.tableRef.multipleSelection;
      console.log('selectedList:', selectedList);
      if (!selectedList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请勾选要邀请的导游!'
        });
        return;
      }
      this.$bus.open(() => {
        const ids = selectedList.map(it => it.id);
        this.handleInvite(ids);
      }, '确定要邀请吗?');
    },
    showViteDialog() {
      this.$refs.dialogInviteRef.show();
    }
  }
};