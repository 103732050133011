/* eslint-disable no-unused-vars */
import { fetchLog, fetchAccount, deleteAccount, createOrUpdateAccount, fetchAdjust, fetchTransfert } from '@/api/resource/account';
import { fetchCashPage } from '@/api/bill/billDown';
import { resourceAccountObj, TYPES } from '@/config/resource';
import { formatCharData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
import { cashLogType, cashFeeTypeLabel, cashModeTypeLabel } from '@/config/billDown';
import { parseDate } from '@/utils';
import { processMinus } from '@/utils/number';
const dfaultState = () => ({
  accountData: null,
  accountDataOrigin: null,
  accountLog: null,
  accountList: null,
  editAccountInfo: {}
});
const state = dfaultState();
const getters = {
  editAccountInfo: state => state.editAccountInfo,
  accountData: state => state.accountData,
  accountDataOrigin: state => state.accountDataOrigin,
  accountList: state => state.accountList,
  accountLog: state => state.accountLog
};
const actions = {
  fetchList({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchAccount(queryParam).then(res => {
      const {
        data
      } = res;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.accountData ? state.accountData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatCharData(list, 'name', 'cardNo'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA', data);
      commit('FETCH_DATA_ORIGIN', originData);
      return originData;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.bank
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // 现金日志流
  fetchDetail({
    commit
  }, params) {
    return fetchCashPage(params).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        const {
          cash,
          used,
          cashType,
          cashAction,
          cashMode,
          createTime
        } = it;
        it.balance = processMinus(cash, used);
        it.cashTypeLabel = cashLogType[cashType];
        it.cashActionLabel = cashFeeTypeLabel[cashAction];
        it.cashModeLabel = cashModeTypeLabel[cashMode];
        it.createTime = parseDate(createTime);
        return it;
      });
      return data;
    });
  },
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdateAccount(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delete({
    commit
  }, id) {
    return deleteAccount(id).then(res => {
      commit('DELETE', id);
      return res;
    });
  },
  // 当前添加资源的账户信息
  editAccount({
    commit
  }, item) {
    const accountForm = {};
    for (let key in resourceAccountObj) {
      accountForm[key] = item[key];
    }
    commit('EDIT_ACCOUNT', accountForm);
  },
  // 卡内调账
  // eslint-disable-next-line no-unused-vars
  fetchAdjust({
    commit
  }, opts) {
    return fetchAdjust(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 卡卡调账
  // eslint-disable-next-line no-unused-vars
  fetchTransfert({
    commit
  }, opts) {
    return fetchTransfert(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.accountData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.accountDataOrigin = data;
  },
  FETCH_LIST: (state, data) => {
    state.accountList = data;
  },
  FETCH_LOG: (state, data) => {
    state.accountLog = data;
  },
  DELETE: (state, id) => {
    const list = state.accountData.list.filter(it => it.id !== id);
    state.accountData.list = list;
  },
  EDIT_ACCOUNT: (state, item) => {
    state.editAccountInfo = item;
  },
  CLEAR_ACCOUNT: state => {
    state.editAccountInfo = {};
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};