import { useEcharts } from '@/utils/echarts';
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    progressList: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  mounted() {
    const list = this.progressList.map(it => {
      if (it.complet < 0) {
        it.complet = 0;
      }
      if (it.complet > 100) {
        it.complet = 100;
      }
      return it;
    });
    this.tableData = list.sort((a, b) => {
      return b.complet - a.complet;
    });
    if (this.progressList.length > 20) {
      this.tableData = this.progressList.slice(0, 19);
    }
    this.drawLine(this.tableData, this.title, this.id);
  },
  methods: {
    drawLine(peopleList, title, id) {
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById(id))
      // 绘制图表
      const opts = {
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: title + '占比',
          type: 'pie',
          center: 'center',
          radius: '50%',
          label: {
            formatter: '{b}: ({d}%)'
          },
          data: peopleList
        }]
      };
      useEcharts(id, opts);
    }
  }
};