var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumber", {
          attrs: {
            id: scope.row.busCompanyId,
            "use-type": scope.row.useType,
            type: "fleet"
          }
        })];
      }
    }, {
      key: "busCompanyName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.busCompanyName) + " ")];
      }
    }, {
      key: "useType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getBusUseType(scope.row.useType)) + " ")];
      }
    }, {
      key: "busType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.busType) + " ")];
      }
    }, {
      key: "mobilePhone",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.driverPhone) + " ")];
      }
    }, {
      key: "driver",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.driverName) + " ")];
      }
    }, {
      key: "licenceNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.licenceNumber) + " ")];
      }
    }, {
      key: "startDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startDate) + " ")];
      }
    }, {
      key: "endDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.endDate) + " ")];
      }
    }, {
      key: "busFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.busFee) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.otherFee) + " ")];
      }
    }, {
      key: "rowTotal",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.rowTotal) + " ")];
      }
    }, {
      key: "payType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.payType) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "bill",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            disabled: scope.row.isCustom,
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleBill(scope.row.rowId);
            }
          }
        }, [_vm._v(" 司机通知单 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };