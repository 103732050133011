import "core-js/modules/es.array.reduce.js";
import { orderRestaurantDetail } from '@/config/order';
import { MealTypeLabel } from '@/config/restaurant';
import { getSummariesMixin, reloadMixin } from '@/mixin';
import { multiplyNumber } from '@/utils/number';
export default {
  mixins: [getSummariesMixin, reloadMixin],
  data() {
    return {
      columnData: orderRestaurantDetail,
      tableData: [],
      statisticsKeys: ['count', 'freeCount', 'otherFee', 'rowTotal']
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchRestaurant', {
        id
      }).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data);
      });
    },
    formatData(list) {
      if (!list) return [];
      return list.reduce((acc, cur) => {
        const {
          restaurantOTypes,
          restaurant,
          mealType
        } = cur;
        if (restaurantOTypes && restaurantOTypes.length) {
          restaurantOTypes.forEach(val => {
            const {
              id: rowId,
              price,
              count
            } = val;
            const rowTotal = multiplyNumber(price, count);
            const item = {
              ...cur,
              ...val,
              rowTotal,
              rowId,
              mealType: MealTypeLabel[mealType],
              name: restaurant ? restaurant.name : ''
            };
            acc = [...acc, item];
          });
        }
        return acc;
      }, []);
    }
  }
};