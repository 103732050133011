import "core-js/modules/es.array.push.js";
import ReserveInfo from './components/ReserveInfo.vue';
import HotelTable from '../components/ReserveHotelTable.vue';
import FleetTable from '../components/ReserveFleetTable.vue';
import ScenicTable from '../components/ReserveScenicTable.vue';
import RestaurantTable from '../components/ReserveRestaurantTable.vue';
import { orderHotel, orderFleet, orderRestaurant, orderScenic } from '@/config/resourceOrder';
import { TableTripBase, FooterBar } from '@/components/common';
import { parseDate } from '@/utils';
import { mapGetters } from 'vuex';
import { reloadMixin, reserveMixin } from '@/mixin';
const defaultState = () => ({
  startTripDate: parseDate(new Date()),
  endTripDate: parseDate(new Date())
});
export default {
  name: 'ReserveUpdate',
  components: {
    FooterBar,
    ReserveInfo,
    HotelTable,
    FleetTable,
    ScenicTable,
    RestaurantTable,
    TableTripBase
  },
  mixins: [reloadMixin, reserveMixin],
  data() {
    return {
      orderHotel,
      orderFleet,
      orderRestaurant,
      orderScenic,
      guideRemark: '',
      show: false,
      link: {
        create: '/order/reserve-input'
      },
      tripData: [],
      hotelData: [],
      fleetData: [],
      scenicData: [],
      restaurantData: [],
      form: defaultState()
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    }),
    isUpdate() {
      return this.$route.query.type == 'update';
    }
  },
  watch: {
    currentOrder: {
      handler: function (item) {
        this.form = item;
      }
    }
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    if (!id) return;
    this.initData(id);
  },
  methods: {
    initData(id) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.fetchItineraryData(id);
          this.fetchData();
        }, 500);
      });
    },
    redirectDetail(id) {
      this.$router.replace({
        path: this.link.create,
        query: {
          type: 'update',
          id
        }
      });
      this.initData(id);
    },
    // 获取订单行程
    fetchItineraryData(id) {
      this.$store.dispatch('order/index/fetchItinerary', id).then(data => {
        this.tripData = data;
      });
    },
    handleClose() {
      this.$router.push({
        path: '/order/reserve'
      });
      this.$bus.emit('order:reserve-close');
    },
    handlePlan() {
      const {
        id,
        customerTripNumber,
        orderType,
        orderNumber,
        planNumber
      } = this.currentOrder;
      const {
        infoRef,
        hotelRef,
        fleetRef,
        scenicRef,
        restaurantRef
      } = this.$refs;
      const isFormValid = infoRef.handleValidate();
      isFormValid.validate(valid => {
        if (!valid) {
          this.$bus.tip({
            type: 'warning',
            message: '订单信息不完整'
          });
          return;
        } else {
          // 校验
          if (!this.validateHotel(hotelRef.tableData)) return;
          if (!this.validateFleet(fleetRef.tableData)) return;
          if (!this.validateScenic(scenicRef.tableData)) return;
          if (!this.validateRestaurant(restaurantRef.tableData)) return;
          const opts = {
            isBooking: true,
            companyCode: this.currentOrder.companyCode || scenicRef.companyCode,
            customerOrderId: id,
            hotelOrders: this.processHotel(hotelRef.tableData),
            busOrders: this.processFleetData(fleetRef.tableData),
            scenicOrders: this.processScenicData(scenicRef.tableData),
            restaurantOrders: this.processRestaurant(restaurantRef.tableData),
            guideOrder: {},
            customerTripNumber,
            orderNumber,
            orderType,
            planNumber,
            sendPlanStatus: this.checkHotel
          };
          this.$store.dispatch('order/resource/bookingSend', opts).then(() => {
            this.handleClose();
            this.$bus.tip();
          });
        }
      });
    }
  }
};