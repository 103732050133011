import { DetailItinerary } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { parseTime } from '@/utils';
export default {
  components: {
    DetailItinerary
  },
  data() {
    return {
      orderId: '',
      itineraryVisible: false,
      data: {},
      pickupData: []
    };
  },
  computed: {
    getTaskType() {
      return val => {
        val = val.toLowerCase();
        const res = SELECTOR.bigTraffic.find(it => it.value == val);
        return res && res.label;
      };
    },
    getShuttleType() {
      return val => {
        val = val.toLowerCase();
        const res = SELECTOR.taskType.find(it => it.value == val);
        return res && res.label;
      };
    },
    getParseDate() {
      return val => {
        return parseTime(val, '{y}-{m}-{d} {h}:{i}');
      };
    }
  },
  methods: {
    show(data) {
      const {
        id
      } = data;
      this.fetchData(id);
      this.data = data;
    },
    fetchData(id) {
      this.$store.dispatch('order/index/fetchPickup', id).then(res => {
        this.pickupData = res;
        this.itineraryVisible = true;
      });
    }
  }
};