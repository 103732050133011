var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "PrintPlanTeam"
    }
  }, [_c("TableNative", {
    attrs: {
      title: "团队计划单",
      data: _vm.data,
      columns: _vm.columnInfo,
      logo: _vm.data.travelAgencyVo.logoUrl,
      "col-num": _vm.columnLen,
      "label-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "tableHead",
      fn: function () {
        return [_c("p", {
          staticClass: "append"
        }, [_c("span", {
          staticClass: "flex-1"
        }, [_vm._v(" 代收：" + _vm._s(_vm.data.totalCollect || 0))])])];
      },
      proxy: true
    }, {
      key: "number",
      fn: function ({
        row
      }) {
        return [_c("span", [_vm._v(" " + _vm._s(row.adultCount) + "大" + _vm._s(row.childCount) + "小" + _vm._s(row.accompanyCount) + "陪 ")])];
      }
    }, {
      key: "busFees",
      fn: function ({
        row
      }) {
        return [_c("span", [_vm._v(" " + _vm._s(_vm.getBusFees(row)) + " ")])];
      }
    }, {
      key: "hotel",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.travelAgencyVo.agencyInfoHotelAdjustment) + " ")];
      }
    }, {
      key: "bus",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.travelAgencyVo.agencyInfoCarAdjustment) + " ")];
      }
    }, {
      key: "leaveDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.leaveDate ? row.leaveDate.split("T").join(" ") : "") + " ")];
      }
    }, {
      key: "busCompanyTypes",
      fn: function ({
        row
      }) {
        return [_c("p", [_vm._v(_vm._s(row.busCompanyTypes))]), _c("p", [_vm._v(_vm._s(row.busCompanyRemarks))])];
      }
    }], null, false, 2684739188)
  }), _c("TableNative", {
    attrs: {
      mode: "horization",
      data: _vm.data.respOrderTrips,
      columns: _vm.columnTrip,
      "label-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "roomNames",
      fn: function ({
        row
      }) {
        return _vm._l(_vm.formatRoom(row.roomNames), function (val, i) {
          return _c("div", {
            key: i
          }, [_vm._v(" " + _vm._s(val) + " ")]);
        });
      }
    }], null, false, 702095512)
  }), _c("RemarkList", {
    attrs: {
      columns: _vm.columnOther,
      data: _vm.data
    }
  }), _c("p", {
    staticClass: "append"
  }, [_c("span", {
    staticClass: "flex-1"
  }, [_vm._v(" 签发人： " + _vm._s(_vm.data.dutyOPUserName))]), _c("span", {
    staticStyle: {
      flex: "0 0 150px"
    }
  }, [_vm._v("日期： " + _vm._s(_vm.printDate))])]), _c("SealImage", {
    attrs: {
      comp: "PlanTeam"
    }
  })], 1) : _c("Empty");
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };