import { ResourceType } from '@/config/billCheck';
import { dictionaryMixin, searchMixin } from '@/mixin';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, InputRestaurant } from '@/components/common';
import { parseDate } from '@/utils';
import { downloadExport } from '@/utils/exportExcel';
import { SELECTOR } from '@/config';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputRestaurant,
    SelectProduct,
    SelectStaff
  },
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      ResourceType,
      sendDate: [parseDate()],
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        startTripDateS: parseDate()
      },
      resource: '',
      // 资源
      payType: [{
        value: '',
        label: '全部'
      }, ...SELECTOR.payType]
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/restaurant'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.$emit('search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    handleSelect(val) {
      this.searchParams.resourceId = val ? val.id : '';
      this.searchParams.resourceLabel = val ? val.label : '';
      delete this.searchParams.resourceName;
    },
    onSelectPayTypa(val) {
      let {
        value,
        label
      } = val;
      this.searchParams.payType = value ? value.toUpperCase() : '';
      this.searchParams.payTypeLabel = label;
    },
    handleChange(val) {
      this.searchParams.resourceName = val;
      delete this.searchParams.resourceId;
    },
    // 搜索
    handleSearch() {
      const search = this.searchParams;
      this.$emit('search', search);
    },
    handleExcel() {
      const search = this.searchParams;
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportRestaurantferExcel', search).then(res => {
        const fileName = '餐厅对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};