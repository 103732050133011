/* eslint-disable no-unused-vars */
import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 购物店列表
export function shopList(params) {
  return request({
    url: '/order/shop/page',
    method: 'get',
    params
  });
}

// 购物店详情
export function shopDetail(id) {
  return request({
    url: '/order/shop/getById',
    method: 'get',
    params: {
      id
    }
  });
}

// 导出大团统计
export function exportPlanShopStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/exportPlanShopStatic';
  return requestBlob({
    url,
    params
  });
}

// 按大团统计
export function getPlanShopStatic(params) {
  return request({
    url: '/order/reportShop/reportPlanShopStatic',
    method: 'get',
    params
  });
}

// 按小团统计
export function getOrderShopStatic(params) {
  return request({
    url: '/order/reportShop/report-OrderShopStatic',
    method: 'get',
    params
  });
}

// 导出小团统计
export function exportOrderShopStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-OrderShopStatic';
  return requestBlob({
    url,
    params
  });
}

// 按区域统计
export function getByAreaStatic(params) {
  return request({
    url: '/order/reportShop/report-ByArea',
    method: 'get',
    params
  });
}

// 导出区域统计
export function exportByAreaStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByArea';
  return requestBlob({
    url,
    params
  });
}

// 线路统计
export function getByLineStatic(params) {
  return request({
    url: '/order/reportShop/report-ByLine',
    method: 'get',
    params
  });
}

// 导出线路统计
export function exportByLineStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByLine';
  return requestBlob({
    url,
    params
  });
}

// 购物店统计
export function getByShopStatic(params) {
  return request({
    url: '/order/reportShop/report-ByShop',
    method: 'get',
    params
  });
}

// 导出购物店统计
export function exportByShopStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByShop';
  return requestBlob({
    url,
    params
  });
}
// 客户统计
export function getByCustomerStatic(params) {
  return request({
    url: '/order/reportShop/report-ByCustomer',
    method: 'get',
    params
  });
}

// 导出客户统计
export function exportByCustomerStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByCustomer';
  return requestBlob({
    url,
    params
  });
}

// 导游统计
export function getByGuideStatic(params) {
  return request({
    url: '/order/reportShop/report-ByGuide',
    method: 'get',
    params
  });
}

// 导出导游统计
export function exportByGuideStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByGuide';
  return requestBlob({
    url,
    params
  });
}
// 导管统计
export function getByGuideManagerStatic(params) {
  return request({
    url: '/order/reportShop/report-ByGuideManager',
    method: 'get',
    params
  });
}

// 导出导管统计
export function exportByGuideManagerStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByGuideManager';
  return requestBlob({
    url,
    params
  });
}

// 导游组长统计
export function getByGuideTeamStatic(params) {
  return request({
    url: '/order/reportShop/report-ByGuideTeam',
    method: 'get',
    params
  });
}

// 导出导游组长统计
export function exportByGuideTeamStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ByGuideTeam';
  return requestBlob({
    url,
    params
  });
}

// 购物财务统计
export function getShopFinanceStatic(params) {
  return request({
    url: '/order/reportShop/report-ShopFinance',
    method: 'get',
    params
  });
}

// 购物进店表
export function shoppingEntryRequest(params) {
  return request({
    url: '/order/reportShop/report-ShopEntryTable',
    method: 'get',
    params
  });
}

// 导出购物财务统计
export function exportShopFinanceStatic(params) {
  const url = APP.baseUrl + '/order/reportShop/export-ShopFinance';
  return requestBlob({
    url,
    params
  });
}