import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  data() {
    return {
      size: 'medium'
    };
  },
  methods: {
    // showDialog(type, scope) {
    //   console.log('type, scope: ', type, scope)
    // },
    // handleAction(type, scope) {
    //   console.log('type, scope: ', type, scope)
    // },
  }
};