var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer-bar-fix",
    style: {
      zIndex: _vm.zIndex,
      left: _vm.getLeftPos
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };