import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { DEFAULT_PAGE, BILL_PAGE_COUNT } from '@/config';
import { TYPES, CashFeeType } from '@/config/billDown';

// 下账管理
import { fetchBillDetail, fetchUnPayShop, fetchUnPayList, fetchUnPayFleet, fetchUnPayInsurance, fetchUnPayHotel, fetchUnPayReception, fetchUnPayRestaurant, fetchUnPayTraffic, fetchUnPayScenic, fetchBillList, fetchPayedList, addCollect, delCollect, deleteDown, fetchBank, fetchGuide, fetchUnPayOther, fetchUnPayGuide, fetchPayedGuide, toggleGuideCheck, fixflushGuideReimb, fetchCashPage, fetchBankStatices } from '@/api/bill/billDown';
import { formatTableData, formatCommitTableData } from '@/utils/data';
import { parseDate, parseTime } from '@/utils';
import { RebateType } from '@/config/shopping';
import { processAdd, processMinus } from '@/utils/number';
import { decimalSplit } from '@/utils';
import { SourceType } from '@/config/resource';
import { cashLogType, cashFeeTypeLabel, cashModeTypeLabel } from '@/config/billDown';
export const calcOwePay = item => {
  const {
    totalPay,
    guidePay,
    agencyPay
  } = item;
  const res = processMinus(totalPay, processAdd(guidePay, agencyPay));
  return res;
};

// 计算未收
export const calcGuideOwePay = item => {
  const {
    totalPay,
    totalPayed
  } = item;
  const res = processMinus(totalPay, totalPayed);
  return decimalSplit(res);
};

// 计算团款未收
export const calcUnitOwePay = item => {
  let {
    totalIncome,
    totalCollect,
    totalSubsidy,
    agencyMoney,
    unIncomeDeductSubsidy
  } = item;
  // unIncomeDeductSubsidy冲抵为true时totalSubsidy加入计算否则totalSubsidy为0
  const subsidy = unIncomeDeductSubsidy ? totalSubsidy : 0;
  const res = processMinus(totalIncome, processAdd(totalCollect, subsidy, agencyMoney));
  return res.toFixed(2);
};

// 计算未收
export const calcReceptionOwePay = item => {
  const {
    totalPay,
    totalCollect,
    totalSubsidy,
    agencyPay
  } = item;
  return processMinus(totalPay, processAdd(totalCollect, totalSubsidy, agencyPay));
};
export const formatList = (list, calcOwePay, key = '') => {
  const result = [];
  list.forEach(it => {
    let item = {
      ...it,
      rowId: it.id,
      isChecked: false,
      downPayed: ''
    };
    item.owePayCopy = item.owePay = calcOwePay(item);
    const subList = key ? it[key] : [];
    if (subList.length) {
      subList.forEach(sub => {
        result.push({
          ...item,
          ...sub
        });
      });
    } else {
      result.push(item);
    }
  });
  return result;
};
const getBillType = queryParam => {
  queryParam = {
    ...DEFAULT_PAGE,
    ...queryParam
  };
  return fetchBillList(queryParam).then(res => {
    const {
      data
    } = res;
    data.list = formatTableData(data.list);
    return data;
  });
};
const getPayedList = queryParam => {
  queryParam = {
    ...DEFAULT_PAGE,
    ...queryParam
  };
  return fetchPayedList(queryParam).then(res => {
    const {
      data
    } = res;
    data.list = formatTableData(data.list);
    data.list = data.list.map(it => {
      it.isChecked = false;
      return it;
    });
    return data;
  });
};
export const handleCreate = ({
  state,
  cashType,
  cashAction = 'PAY',
  subsidy = false,
  isGuide = false,
  customerFeeType = ''
}) => {
  const {
    cashWay,
    cashUserId
  } = state.baseInput;
  const cashs = [];
  state.unPayData.list.forEach(it => {
    if (!it.isChecked) return;
    const item = {
      cash: it.downPayed,
      cashWay,
      cashUserId,
      orderId: it.rowId,
      // 订单id， 其他为资源id
      type: it.type ? it.type : 'NONE',
      feeType: it.feeType
    };
    customerFeeType ? item.customerFeeType = customerFeeType : null;

    // 购物处理
    if (cashType == TYPES.shop || cashType == TYPES.shop_subsidy) {
      // 购物二返
      if (subsidy) {
        const newItem = {
          ...item
        };
        // newItem.cash = it.unSubsidyKP
        newItem.rebateType = RebateType.subsidy;
        cashs.push(newItem);
      } else {
        if (it[RebateType['member']] != null && it[RebateType['member']] != 0) {
          const newItem = {
            ...item
          };
          newItem.cash = it[RebateType['member']];
          newItem.rebateType = RebateType['member'];
          cashs.push(newItem);
        }
        if (it[RebateType['agency']] != null && it[RebateType['agency']] != 0) {
          const newItem = {
            ...item
          };
          newItem.cash = it[RebateType['agency']];
          newItem.rebateType = RebateType['agency'];
          cashs.push(newItem);
        }
        if (it[RebateType['redbag']] != null && it[RebateType['redbag']] != 0) {
          const newItem = {
            ...item
          };
          newItem.cash = it[RebateType['redbag']];
          newItem.rebateType = RebateType['redbag'];
          cashs.push(newItem);
        }
      }
    } else {
      cashs.push(item);
    }
  });
  let opts = {
    ...state.baseInput,
    money: state.baseInput.money,
    // money: isGuide ? Math.abs(state.baseInput.money) : state.baseInput.money,
    cashType
  };
  delete opts.subject;
  const cashList = formatCommitTableData(cashs);
  // 收款
  opts = {
    ...opts,
    cashes: cashList,
    cashAction
  };
  return opts;
};
const processBalance = list => {
  if (!list || !list.length) return [];
  return list.map(it => {
    const {
      money,
      userMoney
    } = it;
    it.balance = processMinus(money, userMoney);
    return it;
  });
};
const dfaultState = () => ({
  unPayData: null,
  payedData: null,
  baseInput: null,
  scenic: null,
  restaurant: null,
  hotel: null,
  fleet: null,
  traffic: null,
  insurance: null,
  team: null,
  guide: null,
  shop: null,
  shopSubsidy: null,
  other: null,
  reception: null,
  billDetail: null,
  statistics: {
    downPayed: 0
  },
  // 统计
  needReload: false,
  cashLogData: null,
  billBalance: {
    sumMoney: 0,
    usedMoney: 0
  },
  createBalance: 0,
  billInputForm: null,
  autoStatus: true
});
const state = dfaultState();
const getters = {
  unPayData: state => state.unPayData,
  payedData: state => state.payedData,
  baseInput: state => state.baseInput,
  scenic: state => state.scenic,
  restaurant: state => state.restaurant,
  hotel: state => state.hotel,
  fleet: state => state.fleet,
  traffic: state => state.traffic,
  insurance: state => state.insurance,
  team: state => state.team,
  guide: state => state.guide,
  shop: state => state.shop,
  shopSubsidy: state => state.shopSubsidy,
  other: state => state.other,
  reception: state => state.reception,
  billDetail: state => state.billDetail,
  statistics: state => state.statistics,
  needReload: state => state.needReload,
  cashLogData: state => state.cashLogData,
  billBalance: state => state.billBalance,
  billInputForm: state => state.billInputForm,
  autoStatus: state => state.autoStatus
};
const actions = {
  // 下账详情
  fetchDetail({
    commit
  }, id) {
    return fetchBillDetail(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 购物收款列表
  fetchShopping({
    commit
  }, queryParam) {
    const cashType = TYPES.shop;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.shop,
        data
      });
    });
  },
  // 购物收款（二返）
  fetchShoppingSubsidy({
    commit
  }, queryParam) {
    const cashType = TYPES.shop_subsidy;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.shopSubsidy,
        data
      });
    });
  },
  // 团款列表
  fetchTeamFund({
    commit
  }, queryParam) {
    const cashType = TYPES.customer;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.team,
        data
      });
    });
  },
  // 景区列表
  fetchScenic({
    commit
  }, queryParam) {
    const cashType = TYPES.scenic;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.scenic,
        data
      });
    });
  },
  // 酒店列表
  fetchHotel({
    commit
  }, queryParam) {
    const cashType = TYPES.hotel;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.hotel,
        data
      });
    });
  },
  // 餐厅列表
  fetchRestaurant({
    commit
  }, queryParam) {
    const cashType = TYPES.restaurant;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.restaurant,
        data
      });
    });
  },
  // 保险列表
  fetchInsurance({
    commit
  }, queryParam) {
    const cashType = TYPES.insurance;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.insurance,
        data
      });
    });
  },
  // 交通列表
  fetchTraffic({
    commit
  }, queryParam) {
    const cashType = TYPES.transport_company;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.traffic,
        data
      });
    });
  },
  // 车队列表
  fetchFleet({
    commit
  }, queryParam) {
    const cashType = TYPES.bus_company;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.fleet,
        data
      });
    });
  },
  // 地接列表
  fetchReception({
    commit
  }, queryParam) {
    const cashType = TYPES.out_transfer;
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.reception,
        data
      });
    });
  },
  // 其他支出列表
  fetchOther({
    commit
  }, queryParam) {
    const cashType = 'RESOURCE_OTHER_PAY';
    queryParam = {
      ...queryParam,
      cashType
    };
    return getBillType(queryParam).then(data => {
      commit('FETCH_DATA', {
        type: SourceType.other,
        data
      });
    });
  },
  // 导游列表
  fetchGuide({
    commit
  }, queryParam) {
    return fetchGuide(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        if (it.checkAccountTime) {
          it.checkAccountTime = parseTime(it.checkAccountTime, '{y}-{m}-{d}');
        }
        return it;
      });
      commit('FETCH_DATA', {
        type: SourceType.guide,
        data
      });
      return data;
    });
  },
  // 购物 未结清
  fetchUnpayShop({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchUnPayShop(queryParam).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      data.list = formatList(data.list, calcUnitOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 购物 未结清
  fetchUnpayShopSubsidy({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchUnPayShop(queryParam).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      data.list = formatList(data.list, () => {});
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 单位 未结清
  fetchUnpayList({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayList(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcUnitOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 车队 未结清
  fetchUnPayFleet({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayFleet(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatList(data.list, calcOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 保险 未结清
  fetchUnPayInsurance({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayInsurance(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatList(data.list, calcOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 酒店 未结清
  fetchUnPayHotel({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayHotel(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatList(data.list, calcOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 地接 未结清
  fetchUnPayReception({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayReception(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatList(data.list, calcReceptionOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 其他支付 未结清
  fetchUnPayOther({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayOther(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatList(data.list, item => {
        const {
          totalPay,
          totalPayed
        } = item;
        return processMinus(totalPay, totalPayed);
      });
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 餐厅 未结清
  fetchUnPayRestaurant({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayRestaurant(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 大交通 未结清
  fetchUnPayTraffic({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayTraffic(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcOwePay, 'transportTypes');
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 景区 未结清
  fetchUnPayScenic({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayScenic(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcOwePay, 'scenicTickets');
      commit('UNPAY_DATA', data);
      return data;
    });
  },
  // 导游 未结清
  fetchUnPayGuide({
    commit,
    dispatch
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayGuide(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcGuideOwePay);
      commit('UNPAY_DATA', data);
      commit('bill/billAutoDown/GUIDE_STATISTICS', data.statistics, {
        root: true
      });
      return data;
    });
  },
  // 已结清 购物
  fetchPayedShop({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.shop
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 团款
  fetchPayedTeamFund({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.customer
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付地接
  fetchPayedReception({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.out_transfer
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付保险
  fetchPayedInsurance({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.insurance
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付交通
  fetchPayedTraffic({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.transport_company
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付车队
  fetchPayedFleet({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.bus_company
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付餐厅
  fetchPayedRestaurant({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.restaurant
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付酒店
  fetchPayedHotel({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.hotel
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付景区
  fetchPayedScenic({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.scenic
    };
    return getPayedList(queryParam);
  },
  // 单位已结清 付其他
  fetchPayedOther({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: 'RESOURCE_OTHER_PAY'
    };
    return getPayedList(queryParam);
  },
  // 导游已结清
  fetchPayedGuide({
    commit
  }, queryParam) {
    return fetchPayedGuide(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  toggleGuideCheck({
    commit
  }, opts) {
    return toggleGuideCheck(opts);
  },
  // 添加付款 购物店
  createPayShop({
    state
  }, resourceId) {
    const cashType = TYPES.shop;
    const opts = handleCreate({
      state,
      cashType,
      cashAction: CashFeeType.INCOME,
      resourceId
    });
    return addCollect(opts);
  },
  // 删除付款
  deleteCollect({
    commit
  }, id) {
    return delCollect(id);
  },
  // 删除下账
  deleteDown({
    commit
  }, ids) {
    const opts = {
      cashType: TYPES.reimb,
      ids
    };
    return deleteDown(opts);
  },
  // 银行列表
  fetchBank({
    commit
  }) {
    return fetchBank().then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 现金日志流
  fetchCashPage({
    commit
  }, opts) {
    return fetchCashPage(opts).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        const {
          cash,
          used,
          cashType,
          cashAction,
          cashMode,
          createTime
        } = it;
        it.balance = processMinus(cash, used);
        it.cashTypeLabel = cashLogType[cashType];
        it.cashActionLabel = cashFeeTypeLabel[cashAction];
        it.cashModeLabel = cashModeTypeLabel[cashMode];
        it.createTime = parseDate(createTime);
        const originId = it.id;
        const planId = it.plan ? it.plan.id : '';
        return {
          ...it,
          ...it.plan,
          planId: planId,
          id: originId
        };
      });
      commit('CASH_LOG_DATA', data);
      return data;
    });
  },
  // 银行账户总金额相关信息
  fetchBankStatices({
    commit
  }, params) {
    return fetchBankStatices(params);
  },
  // 现金日志流银行卡明细
  fetchCashPageLog({
    commit
  }, opts) {
    return fetchCashPage(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 修复导游报账数据
  fixflushGuideReimb({
    commit
  }, params) {
    return fixflushGuideReimb(params).then(res => {
      return res;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    data.list = processBalance(data.list);
    state[type] = data;
    // state.billBalance = data.statistics
  },
  UNPAY_DATA: (state, data) => {
    state.unPayData = data;
  },
  PAYED_DATA: (state, data) => {
    state.payedData = data;
  },
  BASE_INPUT: (state, data) => {
    data.cashDate = parseDate(data.cashDate);
    state.baseInput = data;
  },
  BILL_DETAIL: (state, data) => {
    state.billDetail = data;
    state.billInputForm = data;
  },
  BILL_BALANCE: (state, money) => {
    state.billBalance = money;
  },
  RELOAD_GUIDE_DATA: (state, flag) => {
    state.needReload = flag;
  },
  CASH_LOG_DATA: (state, flag) => {
    state.cashLogData = flag;
  },
  SET_BILL_AUTO_STATUS: (state, val) => {
    state.autoStatus = val;
  },
  SET_BILL_INPUT: (state, form) => {
    state.billInputForm = form;
  },
  SET_BILL_INPUT_USEMONEY: (state, userMoney) => {
    state.billInputForm = {
      ...(state.billInputForm || {}),
      userMoney
    };
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};