var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-account"
  }, [_c("FormBase", {
    attrs: {
      "label-width": "100px",
      align: "left"
    }
  }, [_c("h3", [_vm._v("账户信息")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "开户人",
      maxlength: "10",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("account", $event);
      },
      blur: function ($event) {
        return _vm.validate("account", $event);
      }
    },
    model: {
      value: _vm.form.account,
      callback: function ($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  }), _c("InputBase", {
    attrs: {
      label: "开户行",
      maxlength: "20",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("bank", $event);
      },
      blur: function ($event) {
        return _vm.validate("bank", $event);
      }
    },
    model: {
      value: _vm.form.bank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bank", $$v);
      },
      expression: "form.bank"
    }
  }), _c("InputBase", {
    attrs: {
      label: "银行卡号",
      maxlength: "20",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("bankCard", $event);
      },
      blur: function ($event) {
        return _vm.validate("bankCard", $event);
      }
    },
    model: {
      value: _vm.form.bankCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankCard", $$v);
      },
      expression: "form.bankCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.showType ? _c("SelectBase", {
    attrs: {
      label: "银行卡账户类型",
      placeholder: "请选择",
      type: "bank"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("bankCardType", $event);
      },
      blur: function ($event) {
        return _vm.validate("bankCardType", $event);
      }
    },
    model: {
      value: _vm.form.bankCardType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankCardType", $$v);
      },
      expression: "form.bankCardType"
    }
  }) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };