import "core-js/modules/es.array.push.js";
import { GuideBorrowApply as columnData } from '@/config/billDown/guideBorrow';
import { SelectStaff } from '@/components/common';
import { AuditAdmin } from '@/components/common';
import { parseDate } from '@/utils/index';
import { AuditRole } from '@/config/audit';
const defaultState = () => ({
  borrowMoney: 0,
  applyMoney: 0,
  applyUser: '',
  applyDate: parseDate(),
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  remarks: '',
  isSelect: false
});
export default {
  components: {
    SelectStaff,
    AuditAdmin
  },
  data() {
    return {
      columnData,
      visible: false,
      selectAll: false,
      tableData: [],
      data: {},
      item: defaultState()
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      this.data = data;
      this.init(data);
    },
    hide() {
      this.visible = false;
      this.tableData = [];
      this.selectAll = false;
    },
    init(data) {
      const {
        applyUser,
        applyUserId,
        applyMoney
      } = data;
      const {
        planId,
        guideName,
        guideId,
        auditProgress,
        totalBorrow
      } = data;
      this.tableData.push({
        ...defaultState(),
        ap: auditProgress,
        applyMoney,
        borrowMoney: totalBorrow,
        auditProgress,
        applyUser,
        applyUserId,
        guideName,
        guideId,
        planId
      });
    },
    add() {
      const {
        applyUser,
        applyUserId,
        guideName,
        guideId,
        planId
      } = this.data;
      this.tableData.push({
        ...defaultState(),
        applyUser,
        applyUserId,
        guideName,
        guideId,
        planId
      });
    },
    changeSelectAll(val) {
      this.tableData = this.tableData.map(it => {
        it.isSelect = val;
        return it;
      });
    },
    handleDelete(id) {
      id = Array.isArray(id) ? id : [id];
      this.$bus.open(() => {
        this.$store.dispatch('plan/guide/delBorrowApply', id).then(() => {
          const fIndex = this.tableData.findIndex(it => it.rowId == id);
          this.tableData.splice(fIndex, 1);
        });
      });
    },
    handleDeleteAll() {
      const ids = this.tableData.map(it => it.id);
      this.handleDelete(ids);
    },
    handleConfirm() {
      const list = this.tableData.map(it => {
        const {
          applyDate,
          applyMoney,
          applyUser,
          applyUserId,
          guideId,
          remarks
        } = it;
        return {
          applyDate,
          applyMoney,
          applyUser,
          applyUserId,
          guideId,
          remarks,
          auditProgress: it.ap,
          planId: this.data.planId,
          id: this.data.rowId
        };
      });
      this.$store.dispatch('plan/guide/setPlanBorrow', list).then(() => {
        this.$bus.tip();
        this.$emit('reload');
        this.hide();
      });
    }
  }
};