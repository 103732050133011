import { todayPlan } from '@/config/dashboard';
import TableToday from './component/TableToday';
import chart from './component/chart';
import { parseDate, getNextDate } from '@/utils';
import { hasRoleAuth } from '@/utils/permission';
const today = parseDate(new Date());
const tomorrow = getNextDate(today);
const permissionConf = {
  todayStart: 'home:team:startDate',
  todayEnd: 'home:team:endDate',
  tomorrowStart: 'home:team:tomorrowStartDate'
};
export default {
  name: 'Dashboard',
  components: {
    TableToday,
    chart
  },
  data() {
    return {
      permissionConf,
      permissionObj: {
        todayStart: false,
        todayEnd: false,
        tomorrowStart: false
      },
      columnData: todayPlan,
      tomorrowTotal: 0,
      tomorrowTableData: [],
      sendTotal: 0,
      sendTableData: [],
      strollTotal: 0,
      strollTableData: []
    };
  },
  computed: {
    authList() {
      return this.$store.getters['system/role/rolePermissionList'];
    }
  },
  watch: {
    authList: {
      handler(data) {
        if (!data) return;
        for (let [key, val] of Object.entries(permissionConf)) {
          this.permissionObj[key] = hasRoleAuth(val, data);
          this.permissionObj[key] && this[key]();
        }
      },
      immediate: true
    }
  },
  // async mounted() {
  //   this.$nextTick(() => {
  //     const authList = this.$store.getters['system/role/rolePermissionList']
  //     console.log('authList:', authList)
  //     for (let [key, val] of Object.entries(permissionConf)) {
  //       this.permissionObj[key] = hasRoleAuth(val, authList)

  //       this.permissionObj[key] && this[key]()
  //     }
  //   })
  // },

  methods: {
    // 今日发团
    todayStart(pageNum = 1) {
      const params = {
        startTripDateS: today,
        //today
        startTripDateE: today,
        pageNum
      };
      this.fetchData(params).then(data => {
        const {
          list,
          totalCount
        } = data;
        this.sendTableData = list;
        this.sendTotal = totalCount;
      });
    },
    // 今日散团
    todayEnd(pageNum = 1) {
      const params = {
        endTripDateE: today,
        endTripDateS: today,
        pageNum
      };
      this.fetchData(params).then(data => {
        const {
          list,
          totalCount
        } = data;
        this.strollTableData = list;
        this.strollTotal = totalCount;
      });
    },
    // 明日发团
    tomorrowStart(pageNum = 1) {
      const params = {
        startTripDateS: tomorrow,
        //today
        startTripDateE: tomorrow,
        pageNum
      };
      this.fetchData(params).then(data => {
        const {
          list,
          totalCount
        } = data;
        this.tomorrowTableData = list;
        this.tomorrowTotal = totalCount;
      });
    },
    // 获取数据
    fetchData(params) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('plan/index/fetchPlan', {
          pageSize: 10,
          ...params
        }).then(res => {
          return resolve(res);
        }).catch(err => {
          return reject(err);
        });
      });
    },
    handlePage(type, {
      pageNum
    }) {
      if (type == 'tow') {
        this.todayEnd(pageNum);
      }
      if (type == 'today') {
        this.fetchTodayData(pageNum);
      }
      if (type == 'stroll') {
        this.todayEnd(pageNum);
      }
    }
  }
};