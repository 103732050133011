export default {
  data() {
    return {
      imageList: []
    };
  },
  computed: {
    getImageList() {
      return this.imageList;
    }
  },
  mounted() {
    this.getImagePath();
  },
  methods: {
    async getImagePath() {
      const params = window.location.href.split('?')[1];
      const names = params && params.indexOf('&') > -1 ? params.split('&') || [] : [];
      if (!names.length) return;
      const fullPath = await this.$store.dispatch('resource/common/getOssImage', names);
      this.imageList = fullPath;
    }
  }
};