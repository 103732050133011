import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { CashFeeType, cashModeType, CashWay } from '@/config/billDown';
import { parseDate } from '@/utils/index';
import { isBackMoney } from '@/utils';
import { formatCommitTableData } from '@/utils/data';
import { processAdd } from '@/utils/number';
import { RebateType } from '@/config/shopping';
export { calcBalance, setCheckStatus } from '@/utils/data';
export { SourceTypeLabel } from '@/config/resource';

// prettier-ignore
export { CashFeeType, cashModeType, CashWay, cashModeTypeLabel, cashFeeTypeLabel, cashLogType } from '@/config/billDown';
export const cashMode = cashModeType.COMMON;
export const defaultState = () => ({
  cashMode,
  bankId: '',
  cashAction: CashFeeType.PAY,
  cashDate: parseDate(),
  cashUserId: '',
  cashWay: CashWay.CARD,
  cashes: [],
  resId: '',
  resourceId: '',
  invoiceMoney: '',
  invoiceNumber: '',
  planId: '',
  remarks: '',
  subject: '',
  subjectId: '',
  subId: '',
  money: '',
  userMoney: 0
});
export const rules = {
  money: [{
    required: true,
    message: '请输入金额！',
    trigger: 'blur'
  }],
  subjectId: [{
    required: true,
    message: '请选择会计科目！',
    trigger: 'blur'
  }],
  bankId: [{
    required: true,
    message: '请选择银行卡！',
    trigger: 'blur'
  }],
  cashUserId: [{
    required: true,
    message: '请选择收款人！',
    trigger: 'blur'
  }]
};

// 填充交回数据
const fillBack = it => {
  it.downPayed = -it.owePay;
  it.totalPayed = -it.downPayed;
  it.isChecked = true;
};

// 设置金额
const setMoney = (it, money) => {
  it.downPayed = money;
  it.totalPayed = it.downPayed;
  it.isChecked = true;
};
export const calcTotalMoney = (list, key) => {
  return list.reduce((acc, cur) => {
    const val = cur[key];
    return acc = processAdd(acc, val ? +val : 0);
  }, 0);
};

// 计算下账总金额
export const getBillDownTotal = ({
  list,
  typeKey = 'owePay',
  feeType = '支出'
}) => {
  return list.reduce((acc, cur) => {
    if (cur.feeType == feeType) {
      return acc += +cur[typeKey];
    } else {
      return acc -= +cur[typeKey];
    }
  }, 0);
};
export const setAutoDown = (list, money) => {
  if (money == '') {
    money = getBillDownTotal({
      list
    });
  }
  const newList = list.map(it => {
    it.isChecked = false;
    it.downPayed = '';
    return it;
  });

  // 累加交回数据
  const mergeBack = () => {
    return newList.reduce((acc, cur) => {
      if (isBackMoney(cur.feeType)) {
        acc -= cur.owePay;
      }
      return acc;
    }, Number(money));
  };

  /**
   * 下负数
   * 先消交回 再减支出
   **/

  let temp = mergeBack();
  if (money < 0) {
    return newList.map(it => {
      if (isBackMoney(it.feeType)) {
        if (Math.abs(money) < it.owePay) {
          setMoney(it, money);
          money = 0;
        } else {
          setMoney(it, -it.owePay);
          money = -(Math.abs(money) - Math.abs(it.owePay));
        }
      } else {
        const {
          owePay
        } = it;
        if (temp > owePay) {
          setMoney(it, owePay);
          temp -= owePay;
        } else if (temp > 0 && temp <= owePay) {
          setMoney(it, temp);
          temp = 0;
        } else {
          it.downPayed = '';
          it.totalPayed = it.owePay;
          it.isChecked = false;
        }
      }
      return it;
    });
  } else {
    /**
     * 下正数
     * 1. 先累加交回的金额
     * 2. 计算下账金额
     */
    return newList.map(it => {
      const {
        owePay,
        feeType
      } = it;
      if (isBackMoney(it.feeType)) {
        fillBack(it);
      } else {
        if (temp > owePay) {
          setMoney(it, owePay);
          temp -= owePay;
        } else if (temp > 0 && temp <= owePay) {
          setMoney(it, temp);
          temp = 0;
        } else {
          it.downPayed = isBackMoney(feeType) ? owePay : '';
          it.totalPayed = it.downPayed || 0;
          it.isChecked = isBackMoney(feeType);
        }
      }
      return it;
    });
  }
};

// 格式化报账数据
export const formatShopDownData = ({
  list,
  form,
  cashAction = CashFeeType.INCOME
}) => {
  const {
    cashWay,
    cashUserId
  } = form;
  const cashs = list.map(it => {
    let item = {
      cashWay,
      cashUserId,
      orderId: it.rowId || it.id,
      type: it.type || 'NONE',
      feeType: it.feeType
    };
    const member = it[RebateType.member];
    const agency = it[RebateType.agency];
    const redbag = it[RebateType.redbag];
    const subsidy = it[RebateType.subsidy];
    it.downPayed = +it.downPayed;
    if (member != null && member != 0) {
      item.cash = +member;
      item.rebateType = RebateType.member;
      it[RebateType.member] = null;
    } else if (agency != null && agency != 0) {
      item.cash = +agency;
      item.rebateType = RebateType.agency;
      it[RebateType.agency] = null;
    } else if (redbag != null && redbag != 0) {
      item.cash = +redbag;
      item.rebateType = RebateType.redbag;
      it[RebateType.redbag] = null;
    } else if (subsidy != null && subsidy != 0) {
      item.cash = it.downPayed;
      item.rebateType = RebateType.subsidy;
      it[RebateType.subsidy] = null;
    }
    return item;
  });
  const cashList = formatCommitTableData(cashs);
  // 收款
  return {
    ...form,
    cashes: cashList,
    cashAction
  };
};

// 格式化报账数据
export const formatDownData = ({
  list,
  form,
  cashKey = 'downPayed',
  cashAction = CashFeeType.PAY,
  customerFeeType = '',
  rebateType = ''
}) => {
  const {
    cashWay,
    cashUserId
  } = form;
  const cashs = list.map(it => {
    let item = {
      cash: it[cashKey],
      cashWay,
      cashUserId,
      orderId: it.rowId || it.id,
      type: it.type || 'NONE',
      feeType: it.feeType
    };
    customerFeeType ? item.customerFeeType = customerFeeType : null;
    rebateType ? item.rebateType = rebateType : null;
    return item;
  });
  const cashList = formatCommitTableData(cashs);
  // 收款
  return {
    ...form,
    cashes: cashList,
    cashAction
  };
};

// 返回列表
export const goBack = path => {
  this.$router.push({
    path,
    query: {
      isBack: true
    }
  });
};

// 获取已结清的总金额
export const getPayedMoney = list => {
  return list.length ? list.reduce((acc, cur) => (acc, processAdd(acc, cur.cash)), 0) : 0;
};

// 获取ids
export const getIds = list => {
  return list.filter(it => it.isChecked).map(it => it.id);
};