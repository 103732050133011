import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { customGross } from '@/config/report';
import { SelectCustom, BtnExport, TableTotalBox, SelectStaff, SelectDictionary } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { searchMixin } from '@/mixin';
import { getSummaries, handleSumAvg, processAdd } from '@/utils';
import { parseDate, parseMonth, clearUrlSuffix
// decimalSplit,
// processAdd,
} from '@/utils';
const date = new Date();
const today = parseDate(date);
let yearMonth = parseMonth(date);
yearMonth = yearMonth + '-01';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectCustom,
    BtnExport,
    TableTotalBox,
    SelectStaff,
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    columnData: customGross,
    tableData: [],
    // orderDate: [], //today
    // sendDate: [],
    reportPlanType: SELECTOR.reportPlanType,
    searchParams: {
      orderDateS: yearMonth,
      orderDateE: today,
      customerId: '',
      customerName: '',
      orderType: null
    },
    total: 0,
    statistic: {},
    statisticData: [],
    statisticsKeys: ['groupAdultCount', 'groupGrossProfit', 'groupGrossMargin', 'individualAdultCount', 'individualGrossProfit', 'individualGrossMargin', 'totalQuoteGrossMargin',
    // 合计人均毛利
    'groupShopBackSubsidy', 'totalAdultCount', 'totalGrossProfit', 'totalShopGrossMargin'],
    avgeageObj: {
      groupGrossMargin: ['groupGrossProfit', 'groupAdultCount'],
      // 团队人均
      individualGrossMargin: ['individualGrossProfit', 'individualAdultCount'],
      // 散客人均
      totalQuoteGrossMargin: ['totalGrossProfit', 'totalAdultCount'],
      // 人均毛利
      totalShopGrossMargin: ['totalShopGrossMargin', 'totalAdultCount'] // 最终人均
    }
  }),
  computed: {
    ...mapGetters({
      customerGrossData: 'report/index/customerGrossData'
    })
  },
  watch: {
    customerGrossData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.handleData(data);
        }
      },
      immediate: true
    }
  },
  created() {
    const {
      query
    } = this.$route;
    if (query.outOPUserId) {
      this.initArguments(query);
    }
  },
  methods: {
    handlerSummary(param) {
      const summaryFn = getSummaries(param);
      return summaryFn({
        statisticsKeys: this.statisticsKeys,
        callback: args => handleSumAvg({
          ...args,
          keyObj: this.avgeageObj
        })
      });
    },
    initArguments(query) {
      this.searchParams = query;
      this.handleSearch();
    },
    handleData(data) {
      if (!data) return;
      this.statisticData = [];
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    formatData(data) {
      if (!data) return;
      // 处理
      return data.map(this.processFinalAvg);
    },
    processFinalAvg(item) {
      const {
        totalGrossProfit,
        groupShopBackSubsidy
      } = item;
      item.totalShopGrossMargin = processAdd(totalGrossProfit, groupShopBackSubsidy);
      return item;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 选择发团日期
    onChangData(type, val) {
      this.searchParams[type + 'S'] = val[0];
      this.searchParams[type + 'E'] = val[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    selectCustomerArea(val) {
      this.searchParams.customerArea = val ? val.label : '';
    },
    // 选择计划类型
    selectReportPlan(val) {
      this.searchParams.orderType = val.value;
    },
    fetchData(searchParams) {
      this.$store.dispatch('report/index/getCustomerGross', searchParams);
      clearUrlSuffix();
    },
    exportExcel() {
      const loading = this.$bus.loading();
      const searchParams = this.searchParams;
      this.$store.dispatch('report/index/fetchCustomerGross', searchParams).then(res => {
        const fileName = '客户利润统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};