/*
 * @Author: cyg
 * @Date: 2020-09-03 15:28:33
 * @LastEditors: cyg
 * @LastEditTime: 2023-02-24 10:47:22
 * @FilePath: \travel-admin\src\store\modules\settings.js
 */
import variables from '@/styles/element-variables.scss';
import { defaultSettings } from '@/config/setting';
const {
  showSettings,
  tagsView,
  fixedHeader,
  sidebarLogo,
  supportPinyinSearch
} = defaultSettings;
const dfaultState = () => ({
  theme: variables.theme,
  showSettings,
  tagsView,
  fixedHeader,
  sidebarLogo,
  supportPinyinSearch,
  isShow: false
});
const state = dfaultState();
const getters = {
  isShow: state => state.isShow,
  sidebarLogo: state => state.sidebarLogo,
  theme: state => {
    return state.theme;
  }
};
const mutations = {
  CHANGE_SETTING: (state, {
    key,
    value
  }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  TOGGLE_SETTING: (state, val) => {
    state.isShow = val;
  },
  SET_THEME: (state, val) => {
    state.theme = val;
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  },
  toggleSetting({
    commit
  }, val) {
    commit('TOGGLE_SETTING', val);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};