/* eslint-disable no-empty-pattern */
import { addInviteRequest, delInviteRequest, delBorrowApplyByIds, delGuideQuotaPlanRequest, enterpriseListRequest, consumptionDetailRequest, toggleEnableQuotaRequest } from '@/api/enterprise';
const dfaultState = () => ({
  enterpriseData: null
});
const state = dfaultState();
const getters = {
  enterpriseData: state => state.enterpriseData
};
const actions = {
  addInvite({}, params) {
    return addInviteRequest(params);
  },
  delInvite({}, params) {
    return delInviteRequest(params);
  },
  delQuotaByIds({}, ids) {
    return delBorrowApplyByIds(ids);
  },
  delQuotaByPlanId({}, planId) {
    return delGuideQuotaPlanRequest(planId);
  },
  getList({
    commit
  }, params) {
    return enterpriseListRequest(params).then(res => {
      if (!res) return;
      commit('SET_DATA', {
        type: 'enterpriseData',
        data: res.data
      });
      return res.data;
    });
  },
  consumptionDetail({}, params) {
    return consumptionDetailRequest(params).then(res => {
      if (!res) return;
      return res.data;
    });
  },
  toggleEnableQuota({}, params) {
    return toggleEnableQuotaRequest(params);
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};