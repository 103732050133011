import TripInfo from './TripInfo';
import PersonCount from './PersonCount';
import Contact from './Contact';
import Custom from './Custom';
import { planEditSelect } from '@/config/plan';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnData: planEditSelect
    };
  },
  methods: {
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    handleChecked(scope) {
      let {
        row
      } = scope;
      row.isDelete = !row.isDelete;
    }
  }
};