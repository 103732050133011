import mixin from './mixin';
import TopTitle from '../../components/TopTitle';
import { customerInfoMixin } from '@/mixin';
export default {
  components: {
    TopTitle
  },
  mixins: [mixin, customerInfoMixin],
  computed: {
    removeHotelPhone() {
      return str => {
        const re = /\d+-?\d+/;
        return str ? str.replace(re, '') : str;
      };
    }
  }
};