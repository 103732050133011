import BorderItem from './BorderItem';
import PieChart from '@/components/Chart/PieChart.vue';
export default {
  components: {
    BorderItem,
    PieChart
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};