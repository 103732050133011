export default {
  name: 'DrawerBase',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      direction: 'ltr'
    };
  },
  computed: {
    visiblePanel: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('update:visible');
      }
    }
  },
  methods: {}
};