import "core-js/modules/es.array.reduce.js";
import { tableMixin, rowProfitMixin } from '@/mixin';
import { orderAuditStatus } from '@/config/report';
export default {
  mixins: [tableMixin, rowProfitMixin],
  data() {
    return {};
  },
  methods: {
    planStatus(val) {
      let status = '';
      orderAuditStatus.forEach(it => {
        if (val == it.value) {
          status = it.label;
        }
      });
      return status;
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 求和
        if (column.property == 'totalPay') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    }
  }
};