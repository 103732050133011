import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchOtherPay, updateOtherPay, deleteOtherPay, deleteOtherPayItem, fetchOtherPayOrder, updateOtherPayOrder, deleteOtherPayOrder, fetchOtherPayOrderList, selectOtherPayOrderList, deleteOtherPayOrderItem } from '@/api/plan/otherPay';
const dfaultState = () => ({
  otherPayData: null,
  otherPayOrderData: null
});
const state = dfaultState();
const getters = {
  otherPayData: state => state.otherPayData,
  otherPayOrderData: state => state.otherPayOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // const { planId } = params
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchOtherPay(params).then(res => {
      const {
        data
      } = res;
      // commit('OTHER_PAY_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return updateOtherPay(opts).then(res => {
      const {
        data
      } = res;
      // this.dispatch('fetch')
      // commit('OTHER_PAY_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return deleteOtherPay(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      // commit('OTHER_PAY_DELETE', { planId, ids })
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteOtherPayItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchOtherPayOrder(params).then(res => {
      const {
        data
      } = res;
      commit('OTHER_PAY_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateOtherPayOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteOtherPayOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteOtherPayOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchOtherPayOrderList({
    commit
  }, opts) {
    return fetchOtherPayOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectOtherPayOrderList({
    commit
  }, opts) {
    return selectOtherPayOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  OTHER_PAY_DATA: (state, {
    planId,
    data
  }) => {
    state.otherPayData = data;
  },
  OTHER_PAY_DELETE: (state, {
    planId,
    ids
  }) => {
    let otherPayList = state.otherPayData;
    const list = [];
    otherPayList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.otherPayData = list;
  },
  OTHER_PAY_ORDER_DATA: (state, data) => {
    state.otherPayOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};