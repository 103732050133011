import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary, DialogLog } from '@/components/common';
import { orderType as OrderType, orderRefund, TYPES } from '@/config/order';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, systemMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { AuditRole } from '@/config/audit';
import { multiplyNumber } from '@/utils/number';
import tableMixin from '../tableMixin';
const defaultState = () => ({
  type: '',
  typeId: '',
  remarks: '',
  price: 0,
  count: 1,
  auditProgress: 'WAITAUDIT'
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    DialogLog
  },
  mixins: [dictionaryMixin, systemMixin, tableMixin],
  props: {
    orderType: {
      type: String,
      default: OrderType.team
    }
  },
  data() {
    return {
      refundType: codeType.refundType,
      tableData: [],
      columnData: orderRefund,
      removeCache: [],
      deleteMsg: '是否确定删除此条返款？',
      fetchPath: 'order/index/fetchRefund',
      deletePath: 'order/index/deleteRefund',
      updatePath: 'order/index/createOrUpdateRefund',
      timerStatus: false,
      unIncomeDeductSubsidyCfg: false // 是否冲抵应收款配置
    };
  },
  computed: {
    ...mapGetters({
      creditData: 'resource/custom/credit',
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    calcTotal() {
      return row => multiplyNumber(row.price, row.count);
    },
    isDisableBtn() {
      return !this.allowModifyMoney && !this.collectAuditedAllowChange && this.isBtnDisable || this.isCheckAccount;
    },
    isDisabled() {
      /**
       * collectAuditedAllowChange 应收对账，整团审核后允许新增、修改订单返款
       * allowModifyMoney 允许整团审核后修改团款/代收/返款
       */

      return row => {
        if (this.collectAuditedAllowChange) {
          return this.isAllowRefund(row) || this.isTeanAuditFund(row);
        }
        if (this.allowModifyMoney) {
          return this.isTeanFund(row);
          // return this.isRowDisable(row)
        }
        return this.isRowDisable(row);
      };
    },
    getRefundPrice() {
      if (!this.creditData) return;
      const {
        teamSubsidyPrice,
        individuaSubsidyPrice
      } = this.creditData;
      const price = this.orderType == OrderType.team ? teamSubsidyPrice : individuaSubsidyPrice;
      return this.autoGenerateRefund ? price || 0 : 0;
    },
    getUnIncomeDeductSubsidy() {
      return this.unIncomeDeductSubsidyCfg;
    }
  },
  watch: {
    creditData: {
      handler: function (data) {
        if (!data) return;
        let timer = setTimeout(() => {
          clearTimeout(timer);
          const {
            isNew
          } = this.$route.query;
          isNew && this.handleGenerateRefund();
        }, 1000);
      },
      immediate: true
    }
  },
  created() {
    this.fetchData();
    if (this.currentOrder) {
      this.unIncomeDeductSubsidyCfg = this.currentOrder.unIncomeDeductSubsidy;
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = data;
    },
    changeDeduct(val) {
      const {
        id
      } = this.currentOrder;
      if (!id || !this.tableData.length) return;
      this.unIncomeDeductSubsidyCfg = val;
      this.$store.dispatch('order/index/unDeductSubsidy', {
        id,
        status: +val
      }).then(() => {
        this.timerStatus = true;
        let timer = setTimeout(() => {
          this.timerStatus = false;
          clearTimeout(timer);
        }, 3000);
      }).catch(() => {
        this.unIncomeDeductSubsidyCfg = !val;
      });
    },
    handleGenerateRefund() {
      const price = this.getRefundPrice;
      // 如果没有数据，添加一条
      if (!this.tableData.length) {
        this.handlePlus();
      } else {
        this.tableData = this.tableData.map(it => {
          it.price = +price || +it.price;
          return it;
        });
      }
    },
    selectType(row, item) {
      const {
        label,
        value
      } = item;
      const findItem = this.getDictionaryValue(this.refundType, value);
      if (findItem) {
        row.count = this.getCount(this.currentOrder, findItem) || 1;
      }
      row.type = label;
      // row.typeId = value
    },
    handlePlus() {
      const price = this.getRefundPrice;
      const {
        adultCount: count
      } = this.currentOrder;
      const {
        id: typeId,
        name: type
      } = this.getDictionaryDefVal(this.refundType);
      this.tableData.push({
        ...defaultState(),
        count,
        typeId,
        type,
        price
      });
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.querygit
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.subsidy
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    beforeCommit(customerOrderId, type) {
      const tableData = this.tableData.filter(it => !it.auditProgress || it.auditProgress == AuditRole.wait);
      return tableData.map(it => {
        it.customerOrderId = customerOrderId;
        it.total = it.price * it.count;
        delete it.auditTime;
        delete it.auditUserId;
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.price) {
          continue;
        } else {
          this.$bus.tip({
            type: 'warning',
            message: '返款项目信息不全'
          });
          return false;
        }
      }
      return true;
    }
  }
};