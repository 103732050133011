// 导游报账状态
// 报账状态，WAIT_ACCEPT-待接收，ACCEPT-已接收，SUBMIT-已提交，CONFIRM-已确认，BACK-退回，UN_CONFIRM-未确认,可用值:WAIT_ACCEPT,ACCEPT,SUBMIT,CONFIRM,BACK,UN_CONFIRM
export const GUIDE_REIMB_STATUS = {
  WAIT_ACCEPT: '待接团',
  ACCEPT: '已接团',
  SUBMIT: '已报账',
  CONFIRM: '已确认',
  UN_CONFIRM: '未确认',
  BACK: '退回'
};
export const GUIDE_REIMB_BTN_TYPE = {
  WAIT_ACCEPT: 'danger',
  ACCEPT: 'primary',
  SUBMIT: 'warning',
  CONFIRM: 'success',
  UN_CONFIRM: 'warning',
  BACK: 'danger'
};