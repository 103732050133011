import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { turnOut, turnOutBatch, fetchTurnOut, deleteReception, deleteReceptionFeeItem, deleteReceptionProxyItem, deleteReceptionRefundItem } from '@/api/order';
const dfaultState = () => ({
  receptionList: null
});
const state = dfaultState();
const getters = {
  receptionList: state => state.receptionList
};
const actions = {
  // 外转订单
  turnOut({
    commit
  }, opts) {
    return turnOut(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 外转订单批量
  turnOutBatch({
    commit
  }, opts) {
    return turnOutBatch(opts);
  },
  // 获取外转订单
  fetchTurnOut({
    commit
  }, customerOrderId) {
    return fetchTurnOut(customerOrderId).then(res => {
      const {
        data
      } = res;
      commit('CUR_RECEPTION_LIST', data);
      return data;
    });
  },
  // 删除地接社
  delete({
    commit
  }, {
    id,
    index
  }) {
    return deleteReception(id).then(res => {
      const {
        data
      } = res;
      commit('REMOVE_RECEPTION', {
        id,
        index
      });
      return data;
    });
  },
  // 删除地接费用子项
  deleteFeeItem({
    commit
  }, {
    id,
    index,
    type
  }) {
    return deleteReceptionFeeItem(id).then(res => {
      const {
        data
      } = res;
      commit('REMOVE_RECEPTION', {
        id,
        index,
        type
      });
      return data;
    });
  },
  // 删除地接代收用子项
  deleteProxyItem({
    commit
  }, {
    id,
    index,
    type
  }) {
    return deleteReceptionProxyItem(id).then(res => {
      const {
        data
      } = res;
      commit('REMOVE_RECEPTION', {
        id,
        index,
        type
      });
      return data;
    });
  },
  // 删除地接返款费用子项
  deleteRefundItem({
    commit
  }, {
    id,
    index,
    type
  }) {
    return deleteReceptionRefundItem(id).then(res => {
      const {
        data
      } = res;
      commit('REMOVE_RECEPTION', {
        id,
        index,
        type
      });
      return data;
    });
  },
  changeReception({
    commit
  }, opts) {
    return new Promise(resolve => {
      commit('UPDATE_RECEPTION', opts);
      resolve();
    });
  }
};
const mutations = {
  CUR_RECEPTION_LIST: (state, data) => {
    state.receptionList = data;
  },
  APPEND_RECEPTION: (state, item) => {
    if (!state.receptionList) state.receptionList = [];
    state.receptionList.push(item);
  },
  REDUCE_RECEPTION: (state, index) => {
    state.receptionList.splice(index, 1);
  },
  CLEAR_RECEPTION: state => {
    state.receptionList = null;
  },
  UPDATE_RECEPTION: (state, payload) => {
    const {
      index,
      type,
      data
    } = payload;
    state.receptionList[index][type] = data;
  },
  REMOVE_RECEPTION: (state, payload) => {
    const {
      id,
      index,
      type
    } = payload;
    if (!type) {
      // 删除整块
      state.receptionList.splice(index, 1);
    } else {
      // 删除单个
      const list = state.receptionList[index][type];
      const filterList = list.filter(it => it.id !== id);
      state.receptionList[index][type] = filterList;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};