import { AuditRole } from '@/config/audit';
export const ROOM_LABEL_KEYS = {
  accompany: '陪',
  add: '加',
  doubleRoom: '标',
  king: '大',
  single: '单',
  triple: '三'
};
export const itemListKeys = () => ({
  amount: 0,
  count: 0,
  freeCount: 0,
  quotePrice: 0,
  otherFee: 0,
  roomType: '',
  settlementPrice: 0,
  price: 0
});
export const defaultState = () => ({
  id: 0,
  // 这个id 作用是合并数据
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  roomName: '',
  cashType: 'guidesign',
  hotelArea: '',
  hotelName: '',
  hotelStandard: '',
  orderDate: '',
  remark: '',
  times: 1,
  totalCount: 0,
  totalPay: 0,
  totalQuotePay: 0,
  roomTypeAndPrices: [],
  members: [],
  ap: AuditRole.wait,
  customerOrderId: '',
  orderPeople: {
    outOPUserName: '',
    outOPUserId: ''
  },
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});