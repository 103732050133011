var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "shop-entry"
  }, [_c("SearchBase", {
    on: {
      "on-search": _vm.handleSearch
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "进店日期"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchParams.orderDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderDate", $$v);
      },
      expression: "searchParams.orderDate"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "购物店"
    }
  }, [_c("SelectShop", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.shopId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "shopId", $$v);
      },
      expression: "searchParams.shopId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "export"
    },
    on: {
      click: _vm.handleExport
    }
  })], 1)], 2), _c("TableBase", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.excelLoading,
      expression: "excelLoading"
    }],
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };