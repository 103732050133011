import { tableMixin, getSummariesMixin } from '@/mixin';
import shopMixin from '../shopMixin';
export default {
  mixins: [tableMixin, getSummariesMixin, shopMixin],
  data: () => ({
    avgeageKey: [5, 6, 7],
    // 计算人均
    statisticsKeys: ['plans', 'orders', 'adultCount', 'totalHeadRebate', 'totalBuyMoney', 'shopPerCapita', 'totalShopRebate', 'totalGuideRebate', 'totalGuideManagerRebate']
  }),
  methods: {}
};