import { GUIDE_REIMB_STATUS, GUIDE_REIMB_BTN_TYPE } from '@/config/guide';
export const getGuideStatus = status => {
  return GUIDE_REIMB_STATUS[status];
};
export const getGuideBtnType = status => {
  return GUIDE_REIMB_BTN_TYPE[status];
};
export const hasBooked = () => {
  return row => {
    return row.headCompanyId >= 0;
  };
};