const UPDATE = 'UPDATE';
const moneyKeys = ['price', 'money'];
export default {
  computed: {
    isUpdated() {
      return this.data.updateType == UPDATE;
    },
    getContent() {
      return (row, type) => {
        const newItem = this.getTypeValue(row.updateDetails, type);
        if (moneyKeys.includes(type) && newItem) {
          const {
            oldVal,
            newVal
          } = newItem;
          return {
            oldVal: oldVal / 100,
            newVal: newVal / 100
          };
        }
        return newItem ? newItem : {
          oldVal: row[type]
        };
      };
    }
  },
  methods: {
    getTypeValue(list, type) {
      if (!list || !list.length) return;
      return list.find(it => it.attr == type);
    }
  }
};