var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "cell-class-name": _vm.changeShopCellStyle,
      "header-cell-class-name": _vm.changeShopCellStyle,
      "summary-method": _vm.getShopSummaries,
      "show-summary": "",
      border: ""
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };