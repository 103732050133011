const defaultForm = {
  companyName: '',
  idCode: '',
  bankName: '',
  bankNumber: '',
  registerPhoneNumber: '',
  registerAddress: '',
  postName: '',
  postPhoneNumber: '',
  postAddress: '',
  isDefault: ''
};
export default {
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: defaultForm,
      lastForm: null
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '更新发票信息' : '新增发票';
    }
  },
  methods: {
    showDialog() {
      this.visible = true;
    },
    showInvoiceDialog() {
      this.isUpdate = false;
      // 清理表单数据
      this.resetForm();
      this.showDialog();
    },
    editInvoice(rowData) {
      this.form = rowData;
      this.lastForm = {
        ...rowData
      };
      this.isUpdate = true;
      this.showDialog();
    },
    handleCreate() {
      // 表单校验
      this.validateForm();
      if (this.isUpdate) {
        this.handleInvoice(this.form);
      } else {
        const id = 1; // mock
        const options = {
          ...this.form,
          travelAgencyId: id
        };
        this.handleInvoice(options);
      }
    },
    validateForm() {
      const {
        companyName,
        idCode
      } = this.form;
      if (!companyName) {
        this.$bus.tip({
          message: '单位名称不能为空',
          type: 'warning'
        });
        return;
      }
      if (!idCode) {
        this.$bus.tip({
          message: '纳税人识别码不能为空',
          type: 'warning'
        });
        return;
      }
    },
    handleInvoice(options) {
      this.$store.dispatch('system/invoice/createOrUpdate', options).then(() => {
        this.$bus.emit('invoice:list');
      });
    },
    handleCancel() {
      this.resetForm();
    },
    resetForm() {
      this.form = {};
    }
  }
};