export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '启用状态'
    },
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};