import { tableMixin } from '@/mixin';
import { decimalSplit } from '@/utils/index';
export default {
  components: {},
  mixins: [tableMixin],
  data() {
    return {};
  },
  computed: {
    decimal() {
      return item => {
        return decimalSplit(item * 100) + '%';
      };
    }
  },
  methods: {}
};