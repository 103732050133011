import { generateTableItem } from './helper';
export const Columns = [{
  attrs: {
    prop: 'seqOrder',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'name',
    label: '组团社'
  }
}, {
  attrs: {
    prop: 'customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'customerAreaService',
    label: '计调'
  }
}, {
  attrs: {
    prop: 'orders',
    label: '订单数'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: '',
    label: '合计'
  },
  children: [{
    attrs: {
      prop: 'totalBalance',
      label: '应收余额'
    }
  }, {
    attrs: {
      prop: 'totalFee',
      label: '应收团款'
    }
  }, {
    attrs: {
      prop: 'totalSubsidy',
      label: '应付返款'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '账龄明细'
  },
  children: generateTableItem()
}];