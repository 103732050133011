import guideMixin from './guide';
const columnData = [{
  label: '名称',
  prop: 'name'
}, {
  label: '日期',
  prop: 'orderDate'
}, {
  label: '车返',
  prop: 'parkingFeeRebate'
}, {
  label: '总打单',
  prop: 'totalBuyMoney'
}, {
  label: '商品',
  prop: 'goodName'
}, {
  label: '打单',
  prop: 'money'
}, {
  label: '导收',
  prop: 'guideCollect'
}, {
  label: '合计',
  prop: 'money'
}];
export default {
  mixins: [guideMixin],
  props: {
    tableData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columnData
    };
  },
  computed: {
    guideCollectList() {
      let data = this.tableData ? JSON.parse(this.tableData) : '';
      if (!data) return [];
      const items = data.map(it => {
        const {
          orderDate,
          headRebate
        } = it;
        it.list = it.goods.filter(it => it.guideCollect);
        it.list = it.list.map(item => {
          item.goodName = item.name;
          item.orderDate = orderDate;
          item.headRebate = headRebate;
          item.totalBuyMoney = item.money;
          return item;
        });
        return it;
      });
      const shops = items.filter(it => it.list.length);
      return shops;
    }
  }
};