import { AuditRole } from '@/config/audit';
export const itemListKeys = () => ({
  accompanyCount: 0,
  accompanyPrice: 0,
  adultCountReceive: 0,
  childCountReceive: 0,
  adultCount: 0,
  adultFreeCount: 0,
  childCount: 0,
  childPrice: 0,
  freeTotal: 0,
  otherFee: 0,
  price: 0,
  sellPrice: 0,
  settlementPrice: 0,
  ticketList: null,
  ticketType: ''
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  auditStatus: false,
  payType: 'guidesign',
  planId: '',
  scenicId: '',
  orderScenicTicketList: [],
  totalPay: 0,
  remark: '',
  orderDate: '',
  totalSettlement: 0,
  tripProfit: 0,
  totalProfit: 0,
  driverRate: 0,
  guideRate: 0,
  accompanyRate: 0,
  rowTotal: 0,
  isAddScenic: false,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});

// 加点
export const extraState = () => ({
  adultCountReceive: 0,
  childCountReceive: 0,
  guideCommission: 0,
  driverCommission: 0,
  accompanyCommission: 0,
  totalAmountReceive: 0
});