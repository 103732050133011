import "core-js/modules/es.array.push.js";
import TopTitle from '../components/TopTitle';
import TableTpl from '../components/TableTpl2';
import TableSingle from '../components/TableSingle';
import { PersonCount } from '@/components/common';
import { Guides, CustomerInfo } from '@/components/common';
import { parseTime } from '@/utils';
const columnData1 = [{
  rows: [{
    label: '团号',
    width: 60
  }, {
    content: 'planNumber'
  }, {
    label: '线路',
    width: 60
  }, {
    content: 'productName'
  }, {
    label: '导游',
    width: 60
  }, {
    content: 'guideName',
    slot: 'guide'
  }]
}, {
  rows: [{
    label: '计调',
    width: 60
  }, {
    content: 'dutyOPUserName'
  }, {
    label: '参团人数',
    width: 60
  }, {
    content: 'number',
    slot: 'number'
  }, {
    label: '司机',
    width: 60
  }, {
    content: 'driver',
    slot: 'driver'
  }]
}];
const columnData2 = [{
  label: '客户',
  prop: 'customerName',
  width: 100,
  slot: 'customerName'
}, {
  label: '行程信息',
  prop: 'tripInfo',
  slot: 'tripInfo'
}, {
  label: '接送信息',
  prop: 'pickupInfo',
  slot: 'pickupInfo'
}, {
  label: '游客联系人',
  prop: 'members',
  slot: 'members'
}, {
  label: '酒店信息',
  prop: 'rooms',
  slot: 'rooms'
}, {
  label: '代收',
  prop: 'collect',
  width: 50
}, {
  label: '备注',
  prop: 'remarks',
  width: 150,
  slot: 'remarks'
}];
export default {
  components: {
    TopTitle,
    TableTpl,
    TableSingle,
    PersonCount,
    Guides,
    CustomerInfo
  },
  data() {
    return {
      pickupData: null,
      teamData: null,
      columnData1,
      columnData2,
      tableData: []
    };
  },
  computed: {
    getDriver() {
      return '';
    },
    getShuttleTime() {
      return val => {
        return parseTime(val);
      };
    },
    getShuttleType() {
      return val => {
        return val == 'PLANE' ? '飞机' : '火车';
      };
    }
  },
  created() {
    const {
      planId
    } = this.$route.query;
    if (planId > 0) {
      this.fetchData(planId);
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchShuttleBy', id).then(data => {
        if (!data) return;
        this.teamData = data;
        this.tableData = data.shuttleAlls;
        this.$emit('onMounted');
      });
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = it => {
        const itemList = it.itinerarys.filter(its => its.isPick || its.isSend);
        const item = {
          ...it
        };
        if (itemList && itemList.length) {
          itemList.forEach(v => {
            result.push({
              ...item,
              ...v
            });
          });
        } else {
          result.push({
            ...item
          });
        }
        // 同步id
        this.rowId++;
      };
      list.forEach(it => {
        handleFn(it);
      });
      return result;
    }
  }
};