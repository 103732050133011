import FormOrder from './FormOrder';
import { beforeCommit, formatData } from '../helper';
export default {
  components: {
    FormOrder
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show(form) {
      this.form = formatData(form);
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleSave() {
      const {
        hotel
      } = this.form;
      if (!hotel || hotel.trim() == '') {
        this.$bus.tip({
          type: 'warning',
          message: '请填写酒店！'
        });
        return;
      }
      const opts = beforeCommit(this.form);
      delete opts.id;
      this.$store.dispatch('order/resourcePlan/add', opts).then(() => {
        this.$bus.tip();
        this.$emit('reload');
        this.hide();
      });
    },
    handlePlan() {
      this.hide();
    }
  }
};