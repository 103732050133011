import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import DialogPickup from '../components/DialogPickup';
import DialogStation from '../components/DialogStation';
import { TopTitle, SelectDictionary, DialogLog, SelectFleet } from '@/components/common';
import { orderPickup, TYPES } from '@/config/order';
import { getSelectLabel, SELECTOR } from '@/config/select';
import { mapGetters } from 'vuex';
import { parseDate, parseTime, getDefaultShuttleInfo, isDateOrConvertible } from '@/utils';
import { reloadMixin, systemMixin } from '@/mixin';
import tableMixin from '../tableMixin';
import { AuditRole } from '@/config/audit';
import { resourcePayTypeLabel } from '@/config/resourceOrder';
const defaultState = () => ({
  shiftDateTime: new Date()
});
const defaultFleetTypes = () => ({
  otherFee: 0,
  busType: '',
  busFee: 0
});
const defaultFleetData = () => ({
  busCompanyName: '',
  busCompanyId: '',
  payTypeName: '挂账',
  payType: 'SIGN',
  money: 0,
  busFee: 0,
  shuttleId: -1
});
const TIMT_TYPE = '{y}-{m}-{d} {h}:{i}';
export default {
  components: {
    DialogPickup,
    DialogStation,
    TopTitle,
    SelectDictionary,
    DialogLog,
    SelectFleet
  },
  mixins: [reloadMixin, tableMixin, systemMixin],
  data() {
    return {
      btnLoading: false,
      tableData: [],
      columnData: orderPickup,
      curRow: null,
      curType: '',
      removeCache: [],
      formatTime: 'yyyy-MM-dd HH:mm',
      defaultData: null
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder',
      dictionaryData: 'system/dictionary/dictionaryCodeData'
    }),
    getShuttleType() {
      return val => {
        const res = SELECTOR.taskType.find(it => it.value == val);
        return res && res.label;
      };
    },
    getTaskType() {
      return val => {
        const res = SELECTOR.taskType.find(it => it.value == val);
        return res && res.label;
      };
    },
    getStation() {
      const list = this.dictionaryData['stationType'];
      return val => {
        if (!list) return '';
        const item = list.agencyDictionaryList.find(it => it.id == val);
        return item && item.name;
      };
    },
    getTimeLabel() {
      return val => {
        return val == 'pick' ? '抵达时间' : val == 'send' ? '起飞时间' : '集合时间';
      };
    },
    isDisableBtn() {
      return !this.allowModifyOrderPickup && this.isBtnDisable;
    },
    isDisabled() {
      return row => {
        const {
          bus
        } = row;
        const item = bus && bus.length ? bus[0] : false;
        return this.allowModifyOrderPickup ? item && (item.checkAccountStatus || item.auditProgress != 'WAITAUDIT') : this.isRowDisable(row);
      };
    }

    // isOrderTeamType() {
    //   return this.currentOrder && isTeamType(this.currentOrder)
    // },
  },
  created() {
    this.$store.dispatch('system/buttJoint/getConfig', {}).then(res => {
      if (!res) return;
      this.defaultData = getDefaultShuttleInfo(res.data);
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('order/index/fetchPickup', this.currentOrderId).then(data => {
        this.tableData = this.formatTableData(data);
      });
    },
    selectShuttle(item, val) {
      const {
        startTripDate,
        endTripDate
      } = this.currentOrder;
      item.shuttleDate = val.value == 'send' ? endTripDate : startTripDate;
    },
    changeStationDateTime(row, val) {
      const dateArr = parseTime(val, TIMT_TYPE);
      row.shiftDateTime = dateArr;
      row.shuttleDate = parseDate(dateArr, TIMT_TYPE);
    },
    selectPosition(row, val) {
      row.desPosition = val.label;
      row.busFee = val.mealLabelPrice;
    },
    // 选择车辆
    handleSelectBus(row, val) {
      const {
        label,
        value
      } = val;
      row.busCompanyName = label;
      row.busCompanyId = value;
      // row.fleetPlanId = value
    },
    // 选择车辆类型
    selectBusType(row, val) {
      row.busType = val.label;
    },
    // 付款方式
    selectPayType(row, val) {
      row.payTypeName = val.label;
      row.payType = val.value;
    },
    showPickupDialog() {
      this.$refs.dialogPickupRef.show(this.defaultData);
    },
    changeAddrType(item, val) {
      item.taskType = val;
    },
    changeShuttleDate(item, val) {
      item.date = val;
    },
    formatTableData(list) {
      const optsKeys = Object.keys(defaultFleetData());
      const itemKeys = Object.keys(defaultFleetTypes());
      return list.map(it => {
        let date = null;
        let time = null;
        const {
          shuttleDate
        } = it;
        date = shuttleDate ? parseDate(shuttleDate, TIMT_TYPE) : '';
        if (it.shiftDateTime) {
          it.shiftDateTime = parseTime(it.shiftDateTime, TIMT_TYPE);
          time = it.shiftDateTime.split(' ')[1];
        } else {
          it.shiftDateTime = '';
        }

        // 接团/送团
        it.shuttleTypeVal = it.shuttleType.toLowerCase();
        it.shuttleType = getSelectLabel('shuttleType', it.shuttleType.toLowerCase()); // it.shuttleType.toLowerCase()
        // 站点类型
        it.cityType = getSelectLabel('taskType', it.cityType.toLowerCase());
        it.taskType = it.taskType.toLowerCase();

        // 飞机、火车
        it.taskTypeVal = it.taskType;
        // it.taskType = getSelectLabel('targetType', it.taskType.toLowerCase())

        it.taskTypeLabel = it.taskType == 'plane' ? '飞机' : it.taskType == 'train' ? '火车' : '';
        this.$set(it, 'loading', false);
        this.$set(it, 'date', date);
        this.$set(it, 'time', time);
        // it.bus

        if (it.bus.length) {
          it.bus.forEach(v => {
            optsKeys.forEach(key => {
              it[key] = v[key];
            });
            it.fleetPlanId = v.id;
            it.payTypeName = resourcePayTypeLabel[v.payType.toLowerCase()];
            it.checkAccountStatus = v.checkAccountStatus ? v.checkAccountStatus : false;
            it.auditProgress = v.auditProgress ? v.auditProgress : 'WAITAUDIT';
            v.auditProgress = v.auditProgress ? v.auditProgress : AuditRole.wait;
            v.types.forEach(k => {
              itemKeys.forEach(key => {
                it[key] = k[key];
                it.typesId = k.id;
              });
            });
          });
        } else {
          // let defOpt = {}
          // if (this.defaultData) {
          //   const { name: busType } = defDictionary(DICTIONARY.busType)
          //   const { mealLabelPrice: busFee } = defDictionary(
          //     DICTIONARY.stationType
          //   )
          //   const { id: busCompanyId, name: busCompanyName } = this.defaultData

          //   // 只默认散客接送成本
          //   if (!this.isOrderTeamType) {
          //     defOpt = {
          //       busType,
          //       busFee,
          //       busCompanyId,
          //       busCompanyName,
          //     }
          //   }
          // }

          it = {
            ...defaultFleetData(),
            ...defaultFleetTypes(),
            ...it
          };
        }
        return it;
      });
    },
    selectType(row, val) {
      row.typeId = val;
    },
    handlePlus() {
      this.tableData.push(defaultState());
    },
    // 获取时间
    getTime(type, row) {
      this.curRow = row;
      this.curType = type;
      const {
        date,
        shiftNumber
      } = row;
      const opts = {
        date,
        type,
        shiftNumber
      };
      row.loading = true;
      this.$store.dispatch('app/fetchTrafficTime', opts).then(data => {
        this.$refs.dialogStationRef.show({
          type,
          data,
          to: 'table'
        });
        row.loading = false;
      }).catch(() => {
        row.loading = false;
      });
    },
    // 选择时间后，重新更新数据
    selectStation({
      station,
      startPosition,
      endPosition
    }) {
      const {
        shuttleType,
        shuttleDate
      } = this.curRow;
      console.log('shuttleDate:', shuttleDate);
      this.curRow.desPosition = station.station;
      // 火车
      if (this.curType == 1) {
        const {
          aTime,
          sendTime
        } = station;
        let curTime = shuttleType == 'pick' || shuttleType == '接团' ? aTime : sendTime;
        if (curTime == '----') {
          this.$bus.tip({
            type: 'warning',
            message: '选择站点的时间格式不正确!'
          });
          this.curRow.shiftDateTime = '';
        } else {
          if (isDateOrConvertible(curTime)) {
            this.curRow.shiftDateTime = parseTime(curTime, TIMT_TYPE);
          }
        }
      }

      // 飞机
      if (this.curType == 2) {
        const {
          aTime,
          dTime
        } = station;
        let curTime = shuttleType == 'pick' || shuttleType == '接团' ? aTime : dTime;
        if (curTime == '----') {
          this.$bus.tip({
            type: 'warning',
            message: '选择站点的时间格式不正确!'
          });
          this.curRow.shiftDateTime = '';
        } else {
          this.curRow.shiftDateTime = parseTime(curTime, TIMT_TYPE);
        }
      }
      this.curRow.startPosition = startPosition;
      this.curRow.endPosition = endPosition;
      // this.createOrUpdate()
    },
    handleShuttleDelete({
      fleetPlanId
    }) {
      // this.removeCache.push({ id, isDelete: true })
      // this.fetchData()
      this.$bus.open(() => {
        this.$store.dispatch('order/index/deleteBusType', {
          id: fleetPlanId
        }).then(() => {
          this.$bus.tip();
        });
      }, `确定删除此条接送车队与费用？`);
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (id) {
        // this.removeCache.push({ id, isDelete: true })
        this.$bus.open(() => {
          this.$store.dispatch('order/index/deletePickup', id).then(() => {
            this.fetchData();
            this.$bus.tip();
          });
        }, `是否确定删除此条接送信息？`);
      } else {
        this.tableData.splice(scope.$index, 1);
      }
    },
    handleUpdate() {
      if (!this.validateForm()) return;
      const loading = this.$bus.loading();
      this.createOrUpdate().then(() => {
        this.$bus.tip();
        this.fetchData();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    processCommitData(data, obj) {
      const res = Object.keys(obj).reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
      Object.keys(obj).forEach(key => {
        if (key != 'busCompanyId') {
          delete data[key];
        }
      }, {});
      return res;
    },
    beforeCommit(customerOrderId) {
      return this.tableData.map(it => {
        const {
          payType,
          shuttleTypeVal,
          shiftDateTime
        } = it;
        const shuttleType = shuttleTypeVal != 'city' ? shuttleTypeVal.toUpperCase() : 'pickAndSend';
        it.shiftDateTime = parseTime(shiftDateTime);
        // it.shuttleType = shuttleType

        // types数据
        const typeItem = this.processCommitData(it, defaultFleetTypes());
        typeItem.id = it.typesId;
        typeItem.startDate = it.shuttleDate;
        typeItem.endDate = it.shuttleDate;

        // buses数据
        const busItem = this.processCommitData(it, defaultFleetData());
        busItem.types = [typeItem];
        busItem.customerOrderId = customerOrderId;
        busItem.payType = payType.toUpperCase();
        busItem.shuttleType = shuttleType;
        busItem.id = it.fleetPlanId;
        it.buses = it.busFee !== '' && it.busCompanyId && it.busCompanyId != '' ? [busItem] : [];
        delete it.bus;
        return it;
      });
    },
    // beforeCommit2(customerOrderId, type) {
    //   const tableData = this.tableData.filter(
    //     it => !it.auditProgress || it.auditProgress == AuditRole.wait
    //   )
    //   // 新增的项目加上 订单 id
    //   const copyData = tableData.map(it => {
    //     it.customerOrderId = customerOrderId
    //     return it
    //   })

    //   const optsList = [...copyData, ...this.removeCache]
    //   optsList.map(it => {
    //     it.shiftDateTime = it.shiftDateTime ? parseTime(it.shiftDateTime) : ''

    //     it.togetherDateTime = it.togetherDateTime
    //       ? parseTime(it.togetherDateTime)
    //       : ''

    //     if (type == 'clone') {
    //       delete it.id
    //     }
    //     // 重构车队成本数据
    //     const { date, payType: payTypeVal, shuttleTypeVal } = it

    //     const shuttleType =
    //       shuttleTypeVal != 'city' ? shuttleTypeVal : 'pickAndSend'
    //     const optsKeys = Object.keys(defaultFleetData())
    //     const itemKeys = Object.keys(defaultFleetTypes())

    //     const fleetData = {
    //       customerOrderId,
    //       id: it.fleetPlanId,
    //       money: Number(it.busFee) + Number(it.otherFee),
    //       shuttleType: shuttleType.toUpperCase(),
    //       types: [],
    //     }

    //     optsKeys.forEach(key => {
    //       fleetData[key] = it[key]
    //       fleetData.payType = payTypeVal.toUpperCase()
    //     })
    //     const item = {
    //       startDate: date,
    //       endDate: date,
    //       id: it.typesId,
    //     }
    //     itemKeys.forEach(key => {
    //       item[key] = it[key]
    //     })
    //     fleetData.types = [item]

    //     it.buses = item.busFee !== '' ? [fleetData] : []
    //     delete it.bus
    //     return it
    //   })

    //   return optsList
    // },

    createOrUpdate(customerOrderId = this.currentOrderId) {
      if (!this.tableData.length) return Promise.resolve(1);
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        } else {
          const optsList = this.beforeCommit(customerOrderId);
          this.$store.dispatch('order/index/createOrUpdatePickup', optsList).then(() => {
            this.removeCache = [];
            return resolve('ok');
          }).catch(err => {
            return reject(err);
          });
        }
      });
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.shuttle
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.shuttleType && row.taskType) {
          continue;
        } else {
          this.$bus.tip({
            type: 'warning',
            message: '游客信息不全'
          });
          return false;
        }
      }
      return true;
    }
  }
};