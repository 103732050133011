import { ORDER_TEAM_FEE_CONFIRM_KEY, SHUTTLE_DEFAULT_COST_KEY, ENTERPRISE_LINK_KEY } from '@/config/systemConfig';

// prettier-ignore
export { ORDER_TEAM_FEE_CONFIRM_KEY, SHUTTLE_DEFAULT_COST_KEY, ENTERPRISE_LINK_KEY };
export const defaultForm = () => ({
  keyword: '',
  price: '',
  orderBy: 1,
  isEnable: true
});
export const defaultOption = () => ({
  code: ORDER_TEAM_FEE_CONFIRM_KEY,
  name: '团款合理才能确认成团',
  remarks: '这个是系统配置项内容',
  value: '',
  value1: '',
  value2: '',
  value3: ''
});
export const defaultShuttle = () => ({
  code: SHUTTLE_DEFAULT_COST_KEY,
  name: '接送默认增加成本',
  remarks: '',
  value: '',
  value1: '',
  value2: '',
  value3: ''
});
export const defaultEnterprise = () => ({
  code: ENTERPRISE_LINK_KEY,
  name: '企业码链接',
  remarks: '',
  value: '',
  value1: '',
  value2: '',
  value3: ''
});
export const rules = {
  keyword: [{
    required: true,
    message: '团号关键字必填',
    trigger: 'blur'
  }],
  price: [{
    required: true,
    message: '价格必填',
    trigger: 'blur'
  }]
};
export const Columns = [{
  attrs: {
    label: '序号',
    prop: 'orderBy'
  }
}, {
  attrs: {
    label: '启用状态',
    prop: 'isEnable',
    width: 60
  },
  slot: 'isEnable'
}, {
  attrs: {
    label: '团号关键字',
    prop: 'keyword'
  }
}, {
  attrs: {
    label: '均价',
    prop: 'price'
  }
}, {
  attrs: {
    label: '操作',
    prop: 'action'
  },
  slot: 'action'
}];