import { Guide } from '@/components/common/Order';
import { planEditGuide } from '@/config/plan';
export default {
  components: {
    Guide
  },
  data() {
    return {
      columnData: planEditGuide
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};