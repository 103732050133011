import "core-js/modules/es.array.reduce.js";
import { tableMixin } from '@/mixin';
export default {
  mixins: [tableMixin],
  data: () => ({
    statisticsKeys: ['enterStoreMemberCount', 'totalHeadRebate', 'totalBuyMoney', 'cashFlow', 'money', 'goodShopRebate', 'guideRebate', 'driverRebate', 'accompanyRebate', 'guideManageRebate', 'guideCollect', 'shopRebate', 'totalShopRebate', 'shopPerCapita', 'guideManagerRebate']
  }),
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        let lastId;
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => {
          // 只合计设定的字段
          if (this.statisticsKeys.includes(column.property)) {
            // 处理多个子项合计
            if ((column.property == 'shopRebate' || column.property == 'totalBuyMoney' || column.property == 'enterStoreMemberCount' || column.property == 'shopPerCapita' || column.property == 'totalHeadRebate' || column.property == 'totalShopRebate') && lastId == item.rowId) {
              return 0;
            } else {
              lastId = item.rowId;
              return item[column.property];
            }
          }
        });
        if (!values.every(value => isNaN(value))) {
          const res = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              let res = (Number(prev) * 100 + Number(curr) * 100) / 100;

              // 判断有是否有小数且小数大于三位的保留三位小数

              const toRes = this.formatToFixed(res);
              return toRes;
            } else {
              return prev;
            }
          }, 0);
          if (column.property === 'shopPerCapita') {
            if (this.tableData.length) {
              sums[index] = this.formatToFixed(this.tableData[0].totalShopPerCapita, 2);
            } else {
              sums[index] = 0;
            }
          } else {
            sums[index] = this.formatToFixed(res, 2);
          }
        }
      });
      return sums;
    },
    formatToFixed(sum, toFixed) {
      let resLength = sum.toString().split('.');
      if (resLength.length === 2 && resLength[1].length > toFixed) {
        return sum.toFixed(toFixed);
      }
      return sum;
    }
  }
};