var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "角色设置",
      width: "560px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("SelectRole", {
    model: {
      value: _vm.data.roleId,
      callback: function ($$v) {
        _vm.$set(_vm.data, "roleId", $$v);
      },
      expression: "data.roleId"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };