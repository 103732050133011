import { tableMixin, permissionMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin, permissionMixin],
  methods: {
    changeStatus(scope) {
      this.$parent.updateEnable(scope.row);
    }
  }
};