import SearchRegiment from '../components/SearchRegiment';
import TableList from './components/TableList';
import { SelectHotel } from '@/components/common';
import { excelMixin, reloadMixin } from '@/mixin';
export default {
  components: {
    SelectHotel,
    SearchRegiment,
    TableList
  },
  mixins: [excelMixin, reloadMixin],
  data: () => ({
    resourceId: '',
    searchParams: {}
  }),
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billDown/payRegimentHotel/fetch', {
        resourceId: this.resourceId,
        ...params
      });
    },
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    handlePage(params) {
      this.fetchData({
        ...this.searchParams,
        ...params
      });
    }
  }
};