import FormDetail from '@/views/order/resourcePlan/components/FormDetail';
export default {
  components: {
    FormDetail
  },
  data() {
    return {
      data: null
    };
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    this.fetchData({
      id
    });
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('order/resourcePlan/detail', params).then(data => {
        if (!data) return;
        this.data = data;
        this.handlePrint();
      });
    },
    handlePrint() {
      const style = document.createElement('style');
      style.innerHTML = '@page { size: a4 auto; margin-top: 1.5cm; margin-bottom: 0.5cm; } @page:first { margin-top: 1.75cm } @page:left { margin-left: 0.75cm; margin-right: 0.75cm } @page:right { margin-left: 0.75cm; margin-right: 0.75cm} ';
      style.media = 'print';
      document.head.appendChild(style);

      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
          window.close();
        });
      }, 1000);
    }
  }
};