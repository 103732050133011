import "core-js/modules/es.array.reduce.js";
import { tableMixin } from '@/mixin';
import { KPIType } from '@/config/system';
import { processAdd } from '@/utils/number';
export default {
  mixins: [tableMixin],
  props: {
    align: {
      type: String,
      default: 'center'
    },
    columnData: {
      type: Array,
      required: true
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dece']
    };
  },
  computed: {
    projectName() {
      return row => {
        if (row.kpitype == KPIType.numberOfPeople) {
          return '人数';
        }
        if (row.kpitype == KPIType.profit) {
          return '毛利';
        }
        if (row.kpitype == KPIType.turnover) {
          return '营业额';
        }
      };
    },
    getSum() {
      return row => {
        return this.months.reduce((acc, cur) => (acc, processAdd(acc, row[cur])), 0);
      };
    }
  },
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    hideScroll() {
      if (this.hideScrollBar) {
        const el = this.$el.querySelector('.el-table__body-wrapper');
        // el.style.overflowX = 'hidden'
        el.style['webkitScrollbar'].display = 'none';
      }
    }
  }
};