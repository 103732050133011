var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "PrintPlanPersonal"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("TopTitle", {
    attrs: {
      title: "散客计划单",
      logo: _vm.data.travelAgencyVo.logoUrl
    }
  }), _c("table", [_c("colgroup", _vm._l(12, function (item) {
    return _c("col", {
      key: item,
      staticClass: "col"
    });
  }), 0), _c("tbody", [_vm._l(_vm.tableData, function (item, index) {
    return [!item.slot ? _c("tr", {
      key: index
    }, _vm._l(item.rows, function (col, cIdx) {
      return _c("td", {
        key: cIdx,
        class: col.label ? "item-label" : "item-content",
        attrs: {
          colspan: col.span
        }
      }, [col.slot ? [col.slot == "number" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.adultCount) + "大" + _vm._s(_vm.data.childCount) + "小" + _vm._s(_vm.data.accompanyCount) + "陪 ")]) : _vm._e(), col.slot == "hotel" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.travelAgencyVo.agencyInfoHotelAdjustment) + " ")]) : _vm._e(), col.slot == "busCompanyTypes" ? _c("div", [_vm.getBusTypeList.length ? _vm._l(_vm.getBusTypeList, function (type, tIdx) {
        return _c("div", {
          key: tIdx
        }, [_vm._v(" " + _vm._s(type) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.slot == "busRemark" ? _c("div", [_vm.data.busCompanyTypes && _vm.data.busCompanyTypes.length ? [_vm._v(" " + _vm._s(_vm.getBusRemark) + " ")] : _vm._e()], 2) : _vm._e(), col.content == "busOp" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.travelAgencyVo.agencyInfoCarAdjustment) + " ")]) : _vm._e(), col.content == "driverName" ? _c("div", [_vm.getArrayList("driverName") ? _vm._l(_vm.getArrayList("driverName"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "licenceNumbers" ? _c("div", [_vm.getArrayList("licenceNumbers") ? _vm._l(_vm.getArrayList("licenceNumbers"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "busCompanyNames" ? _c("div", [_vm.getArrayList("busCompanyNames") ? _vm._l(_vm.getArrayList("busCompanyNames"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e()] : [_vm._v(" " + _vm._s(col.label ? col.label : _vm.data[col.content]) + " ")]], 2);
    }), 0) : _vm._e()];
  })], 2)]), _vm._l(_vm.data.respOrderPlanReportLists, function (item, index) {
    return _c("table", {
      key: index,
      staticClass: "inner-table",
      attrs: {
        width: "100%"
      }
    }, [_c("colgroup", _vm._l(24, function (col) {
      return _c("col", {
        key: col,
        staticClass: "col-item"
      });
    }), 0), _c("thead", {
      staticStyle: {
        display: "table-header-group",
        "font-weight": "bold"
      }
    }, [index < 1 ? _c("tr", [_c("th", [_vm._v("序号")]), _c("th", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v("小团号")]), _c("th", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v("游客信息")]), _c("th", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v("抵离信息")]), _c("th", {
      staticStyle: {
        width: "80px"
      },
      attrs: {
        colspan: "2"
      }
    }, [_vm._v("日期")]), _c("th", {
      staticStyle: {
        width: "220px"
      },
      attrs: {
        colspan: "6"
      }
    }, [_vm._v("行程")]), _c("th", [_vm._v("用餐")]), _c("th", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v("住宿酒店")])]) : _vm._e()]), _c("tbody", [_vm._l(item.respOrderTrips, function (day, dIdx) {
      return _c("tr", {
        key: day.id
      }, [dIdx < 1 ? _c("th", {
        staticClass: "tac",
        attrs: {
          rowspan: item.respOrderTrips.length + 1
        }
      }, [_vm._v(" " + _vm._s(index + 1) + " ")]) : _vm._e(), dIdx < 1 ? _c("td", {
        attrs: {
          rowspan: _vm.getRowspan(item),
          colspan: "2"
        }
      }, [_vm._v(" " + _vm._s(item.customerTripNumber)), _c("br"), _vm._v(" 代收:" + _vm._s(item.guideCollect)), _c("br"), _vm._v(" " + _vm._s(item.outOPUserName) + " ")]) : _vm._e(), dIdx < 1 ? _c("td", {
        attrs: {
          rowspan: _vm.getRowspan(item),
          colspan: "2"
        }
      }, [_c("span", [_vm._v(" " + _vm._s(item.adultCount) + "大" + _vm._s(item.childCount) + "小" + _vm._s(item.accompanyCount) + "陪 ")]), _c("span", [_vm._v(_vm._s(item.members))])]) : _vm._e(), dIdx < 1 ? _c("td", {
        attrs: {
          rowspan: _vm.getRowspan(item),
          colspan: "2"
        }
      }, [_c("div", [item.arriveDate ? _c("span", [_vm._v(" 抵达时间：" + _vm._s(_vm.formatDateTime(item.arriveDate)) + " ")]) : _vm._e(), _c("br"), item.arriveNumber ? _c("span", [_vm._v(" 班次：" + _vm._s(item.arriveNumber) + " ")]) : _vm._e(), _c("br"), item.arriveDesPosition ? _c("span", [_vm._v(" 站点：" + _vm._s(item.arriveDesPosition) + " ")]) : _vm._e()]), _c("div", [item.leaveDate ? _c("span", [_vm._v(" 离开时间：" + _vm._s(_vm.formatDateTime(item.leaveDate)) + " ")]) : _vm._e(), _c("br"), item.leaveNumber ? _c("span", [_vm._v(" 班次：" + _vm._s(item.leaveNumber) + " ")]) : _vm._e(), _c("br"), item.leaveDesPosition ? _c("span", [_vm._v(" 站点：" + _vm._s(item.leaveDesPosition) + " ")]) : _vm._e()])]) : _vm._e(), _c("td", {
        staticClass: "tac",
        attrs: {
          colspan: "2"
        }
      }, [_vm._v(" " + _vm._s(day.itineraryDate) + " ")]), _c("td", {
        staticStyle: {
          "line-height": "20px"
        },
        attrs: {
          colspan: "6"
        }
      }, [_vm._v(" " + _vm._s(day.scenicName) + " ")]), _c("td", {
        staticClass: "tac"
      }, [_vm._v(" " + _vm._s(day.mealTypes) + " ")]), _c("td", {
        attrs: {
          colspan: "8"
        }
      }, _vm._l(_vm.formatRoom(day), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }), 0)]);
    }), _c("tr", [_c("td", {
      attrs: {
        colspan: "23"
      }
    }, [_vm._v("备注：" + _vm._s(item.remark))])])], 2)]);
  }), _c("RemarkList", {
    attrs: {
      columns: _vm.columnOther,
      data: _vm.data
    }
  }), _c("p", {
    staticClass: "append"
  }, [_c("span", {
    staticClass: "flex-1"
  }, [_vm._v(" 签发人： " + _vm._s(_vm.data.dutyOPUserName))]), _c("span", {
    staticStyle: {
      flex: "0 0 150px"
    }
  }, [_vm._v("日期： " + _vm._s(_vm.printDate))])])], 2), _c("SealImage", {
    attrs: {
      comp: "PlanPersonal"
    }
  })], 1) : _c("div", {
    staticStyle: {
      margin: "20px auto"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "散客计划单"
    }
  }), _c("Empty")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };