import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchFleet, updateFleet, deleteFleet, deleteFleetItem, fetchFleetOrder, updateFleetOrder, deleteFleetOrder, fetchFleetOrderList, selectFleetOrderList, deleteFleetOrderItem, setFleetArrange, batchUpdateShuttleRequest } from '@/api/plan/fleet';
const dfaultState = () => ({
  fleetData: null,
  fleetOrderData: null
});
const state = dfaultState();
const getters = {
  fleetData: state => state.fleetData,
  fleetOrderData: state => state.fleetOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchFleet(params).then(res => {
      const {
        data
      } = res;
      // commit('FLEET_DATA', data.list )
      return data;
    });
  },
  // 更新 安排
  update({
    commit
  }, opts) {
    return updateFleet(opts).then(res => {
      const {
        data
      } = res;
      // this.dispatch('fetch')
      // commit('FLEET_DATA', data.list)
      return data;
    });
  },
  // 批量更新 安排
  batchUpdateShuttle({
    commit
  }, opts) {
    return batchUpdateShuttleRequest(opts);
  },
  // 删除 安排
  delete({
    commit
  }, ids) {
    return deleteFleet(ids).then(() => {
      this.dispatch('fetch');
    });
    // .then(res => {
    //   const { data } = res
    //   commit('FLEET_DELETE', ids)
    //   return data
    // })
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteFleetItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchFleetOrder(params).then(res => {
      const {
        data
      } = res;
      commit('FLEET_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateFleetOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteFleetOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteFleetOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchFleetOrderList({
    commit
  }, opts) {
    return fetchFleetOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectFleetOrderList({
    commit
  }, opts) {
    return selectFleetOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 保存并安排
  setArrange({
    commit
  }, opts) {
    return setFleetArrange(opts);
  }
};
const mutations = {
  FLEET_DATA: (state, data) => {
    state.fleetData = data;
  },
  FLEET_DELETE: (state, ids) => {
    let filterList = state.filterData || [];
    const list = [];
    filterList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.filterData = list;
  },
  FLEET_ORDER_DATA: (state, data) => {
    state.fleetOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};