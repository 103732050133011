var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "编辑游客船票类型",
      fullscreen: "",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleSave,
      cancel: _vm.hide
    }
  }, [_c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      "no-page": true,
      "show-summary": false,
      border: true
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.name,
            callback: function ($$v) {
              _vm.$set(row, "name", $$v);
            },
            expression: "row.name"
          }
        })];
      }
    }, {
      key: "cardType",
      fn: function ({
        row
      }) {
        return [_c("SelectBase", {
          attrs: {
            "init-val": row.cardType,
            type: "cardType"
          },
          model: {
            value: row.cardType,
            callback: function ($$v) {
              _vm.$set(row, "cardType", $$v);
            },
            expression: "row.cardType"
          }
        })];
      }
    }, {
      key: "cardNumber",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          on: {
            change: function ($event) {
              return _vm.changeCardNumber(row, $event);
            }
          },
          model: {
            value: row.cardNumber,
            callback: function ($$v) {
              _vm.$set(row, "cardNumber", $$v);
            },
            expression: "row.cardNumber"
          }
        })];
      }
    }, {
      key: "age",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.age,
            callback: function ($$v) {
              _vm.$set(row, "age", $$v);
            },
            expression: "row.age"
          }
        })];
      }
    }, {
      key: "country",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.country,
            callback: function ($$v) {
              _vm.$set(row, "country", $$v);
            },
            expression: "row.country"
          }
        })];
      }
    }, {
      key: "ticket",
      fn: function ({
        row
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "shipTicketType"
          },
          model: {
            value: row.shippingSpace,
            callback: function ($$v) {
              _vm.$set(row, "shippingSpace", $$v);
            },
            expression: "row.shippingSpace"
          }
        })];
      }
    }, {
      key: "status",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.status,
            callback: function ($$v) {
              _vm.$set(row, "status", $$v);
            },
            expression: "row.status"
          }
        })];
      }
    }, {
      key: "fee",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.fee,
            callback: function ($$v) {
              _vm.$set(row, "fee", $$v);
            },
            expression: "row.fee"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_vm.deleteBtnDisabled(row) ? _c("el-link", {
          staticClass: "mr10",
          attrs: {
            disabled: _vm.isShipTicketStatusConfirm,
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.delOne(row);
            }
          }
        }, [_vm._v(" 取消 ")]) : _vm._e(), row.shipStatus == _vm.ShipStatusEnum.cancel ? _c("el-link", {
          attrs: {
            disabled: ""
          }
        }, [_vm._v(" 该游客已取消 ")]) : _vm._e(), row.isNewAdd ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.saveOne(row);
            }
          }
        }, [_vm._v(" 添加 ")]) : _vm._e()];
      }
    }])
  }), _vm.curRow ? _c("div", {
    staticClass: "flex flex-center mt20"
  }, [_c("el-button", {
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v("关闭")]), _c("el-button", {
    attrs: {
      type: "success",
      disabled: _vm.curRow.shipNameListStatus
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")]), _vm.curRow ? [_c("el-button", {
    attrs: {
      type: "warning",
      disabled: !!_vm.curRow.shipNameListStatus
    },
    on: {
      click: _vm.setLockStatus
    }
  }, [_vm._v(" " + _vm._s(!_vm.curRow.shipNameListStatus ? "锁定名单" : "已锁定") + " ")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.curRow.shipNameListStatus || !_vm.isShipTicketStatusNo
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 新增游客 ")]), _c("el-button", {
    attrs: {
      type: "danger",
      disabled: _vm.canTicketOut
    },
    on: {
      click: _vm.setTicketStatusConfirm
    }
  }, [_vm._v(" 通知票务可以出票 ")]), _vm.isShipTicketStatusWait ? _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.setTicketStatusCancel
    }
  }, [_vm._v(" 取消出票 ")]) : _vm._e()] : _vm._e()], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };