import { GuideQuota } from '@/components/common/Order';
import { planEditGuideQuota } from '@/config/plan';
import { systemMixin } from '@/mixin';
export default {
  components: {
    GuideQuota
  },
  mixins: [systemMixin],
  data() {
    return {
      columnData: planEditGuideQuota,
      auditAll: false
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};