import { APP } from '@/config';
import request, { requestBlob } from '@/utils/request';
export { deleteOtherIncomeOrder } from '../plan/otherIncome';
export { deleteOtherPayOrder } from '../plan/otherPay';

// 新增订单（资源计划转订单）
export function addResourcePlanOrder(data) {
  return request({
    url: '/order/res-booking/convert',
    method: 'post',
    data
  });
}

// 创建邮箱
export function createEmailRequest(data) {
  return request({
    url: '/order/plan/create-email',
    method: 'post',
    data
  });
}

// 复制订单
export function copyOrderRequest(id) {
  return request({
    url: '/order/copyOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表日志
export function fetchLog(params) {
  return request({
    url: '/log/orderlist',
    method: 'get',
    params
  });
}

// 查询计划信息
export function fetchPlanById(params) {
  return request({
    url: '/order/plan/planInfoById',
    method: 'get',
    params
  });
}

// 查询散客列表
export function fetchPersonal(queryParams) {
  return request({
    url: '/order/s_order/pageList',
    method: 'get',
    params: queryParams
  });
}

// 其他收入 获取
export function fetchOtherIncome(params) {
  return request({
    url: '/order/otherIncome/orderList',
    method: 'get',
    params
  });
}

// 其他收入
export function createOtherIncome(data) {
  return request({
    url: '/order/otherIncome/saveOrder',
    method: 'post',
    data
  });
}

// 其他支出 获取
export function fetchOtherPay(params) {
  return request({
    url: '/order/orderOtherPay/orderList',
    method: 'get',
    params
  });
}

// 其他收入
export function createOtherPay(data) {
  return request({
    url: '/order/orderOtherPay/saveOrder',
    method: 'post',
    data
  });
}

// 所有订单
export function fetchAll(queryParams) {
  return request({
    url: '/order/pageAll',
    method: 'get',
    params: queryParams
  });
}

// 所有订单2
export function fetchAll2(queryParams) {
  return request({
    url: '/order/pageAllAge',
    method: 'get',
    params: queryParams
  });
}

// 所有计划
export function fetchAllPlan(queryParams) {
  return request({
    url: '/order/plan/pageSimpleList',
    method: 'get',
    params: queryParams
  });
}

// 导出所有计划
export function getSimpleListExport(params) {
  const url = APP.baseUrl + '/order/plan/pageSimpleListExport';
  return requestBlob({
    url,
    params
  });
}

// 导出所有订单
export function exportOrderAll(params) {
  const url = APP.baseUrl + '/order/pageAllExport';
  return requestBlob({
    url,
    params
  });
}

// 所有已有散客团列表
export function fetchSPlanSimpleList(queryParams) {
  return request({
    url: '/order/plan/pageSPlanSimpleList',
    method: 'get',
    params: queryParams
  });
}

// 确认成团
export function confirmTeam(id) {
  return request({
    url: '/order/inGroup',
    method: 'post',
    data: {
      id
    }
  });
}

// 散客拼团
export function personalInGroup(data) {
  return request({
    url: '/order/s_order/inGroup',
    method: 'post',
    data
  });
}

// 散客转移
export function personalTransfer(data) {
  return request({
    url: '/order/s_order/transferSGroup',
    method: 'post',
    data
  });
}

// 查询订单安排计划
export function findPlanArranges(planId) {
  return request({
    url: '/order/plan/findPlanArrangesById',
    method: 'get',
    params: {
      planId
    }
  });
}

/** *********************查询************************** */
/**
 * 订单列表
 * @param {object} queryParams {pageNum, pageSize title}
 */
export function fetchList(params) {
  const url = '/order/page';
  return request({
    url,
    method: 'get',
    params
  });
}
export function fetchListSearch(params) {
  const url = '/order/pageAll';
  return request({
    url,
    method: 'get',
    params
  });
}

/**
 * 订单详情
 * @param {number} id 订单id
 */
export function fetchDetail(params) {
  return request({
    url: '/order/getOrderById',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

/**
 * 团款列表
 * @param {number} id 订单id
 */
export function fetchFund(params) {
  return request({
    url: '/order/listOrderFee',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单代收列表
 * @param {number}} id 订单id
 */
export function fetchFundProxy(params) {
  return request({
    url: '/order/listOrderCollect',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单行程列表
 * @param {number}} id 订单id
 */
export function fetchItinerary(id) {
  return request({
    url: '/order/listOrderItinerary',
    method: 'get',
    params: {
      id
    },
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单游客列表
 * @param {number}} id 订单id
 */
export function fetchTourist(params) {
  return request({
    url: '/order/listOrderMember',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

// 下载游客名单模版
export function getTouristTpl() {
  window.open(APP.baseUrl + '/order/downloadMember');
}

/**
 * 订单接送列表
 * @param {number}} id 订单id
 */
export function fetchPickup(id) {
  return request({
    url: '/order/listOrderShuttle',
    method: 'get',
    params: {
      id
    },
    headers: {
      menuId: -1
    }
  });
}

// 获取接送信息
export function fetchPickupDetail(id) {
  return request({
    url: '/order/getOrderShuttleByShuttleId',
    method: 'get',
    params: {
      id
    },
    headers: {
      menuId: -1
    }
  });
}

// 获取大交通列表
export function fetchTraffic(params) {
  return request({
    url: '/order/transportCompany/getOrderTransports',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

// 获取保险列表
export function fetchInsurance(params) {
  return request({
    url: '/order/insurance/getOrderInsurances',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单返款列表
 * @param {number}} id 订单id
 */
export function fetchRefund(params) {
  return request({
    url: '/order/listOrderSubsidy',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单备注列表
 * @param {number}} id 订单id
 */
export function fetchRemarks(id) {
  return request({
    url: '/order/listOrderRemarks',
    method: 'get',
    params: {
      id
    },
    headers: {
      menuId: -1
    }
  });
}

/**
 * 订单景区列表
 * @param {number}} id 订单id
 */
export function fetchScenic(params) {
  return request({
    url: '/order/orderScenic/ListOrderScenices',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

// 订单景点 删除
export function deleteOrScenic(id) {
  return request({
    url: '/order/orderScenic/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单景点 批量删除
export function deleteOrScenics(data) {
  return request({
    url: '/order/orderScenic/delOrders',
    method: 'post',
    data
  });
}

// 订单景区门票类型删除
export function deleteOrScenicTicket(data) {
  return request({
    url: '/order/orderScenic/delOrderTicket',
    method: 'post',
    data
  });
}

/**
 * 订单餐厅列表
 * @param {number}} id 订单id
 */
export function fetchRestaurant(params) {
  return request({
    url: '/order/restaurant/list-order',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}
/**
 * 餐厅删除
 * @param {number}} id 订单id
 */
export function deleteRestaurant(data) {
  return request({
    url: '/order/restaurant/delOrder',
    method: 'post',
    data
  });
}
/**
 * 餐厅类型删除
 * @param {number}} id 订单id
 */
export function deleteRestaurantType(data) {
  return request({
    url: '/order/restaurant/delOrderType',
    method: 'post',
    data
  });
}
/**
 * 餐厅保存
 * @param {number}} id 订单id
 */
export function createOrRestaurant(data) {
  return request({
    url: '/order/restaurant/saveOrder',
    method: 'post',
    data
  });
}
/**
 * 车队保存
 * @param {number}} id 订单id
 */
export function createOrBus(data) {
  return request({
    url: '/order/orderBus/saveOrder',
    method: 'post',
    data
  });
}
/**
 * 车队获取
 * @param {number}} id 订单id
 */
export function fetchOrBus(params) {
  return request({
    url: '/order/orderBus/list-order',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}
/**
 * 车队类型删除
 * @param {number}} id 订单id
 */
export function deleteBusType(data) {
  return request({
    url: '/order/orderBus/delOrderType',
    method: 'post',
    data
  });
}
/**
 * 车队删除
 * @param {number}} id 订单id
 */
export function deleteBus(data) {
  return request({
    url: '/order/orderBus/delOrder',
    method: 'post',
    data
  });
}
/** *********************添加和更新************************** */
// 订单 创建和更新
export function createOrUpdate(data) {
  return request({
    url: '/order/save',
    method: 'post',
    data
  });
}
// 订单 更新全部
export function createOrUpdateAll(data) {
  return request({
    url: '/order/save-all',
    method: 'post',
    data
  });
}

// 团款 创建和更新
export function createOrUpdateFund(data) {
  return request({
    url: '/order/saveOrUpdateFees',
    method: 'post',
    data
  });
}

// 代收 创建和更新
export function createOrUpdateProxy(data) {
  return request({
    url: '/order/saveOrUpdateCollects',
    method: 'post',
    data
  });
}

// 行程 创建和更新
export function createOrUpdateItinerary(data) {
  return request({
    url: '/order/saveListItinerarys',
    method: 'post',
    data
  });
}

// 游客 创建和更新
export function createOrUpdateTourist(data) {
  return request({
    url: '/order/saveOrUpdateMembers',
    method: 'post',
    data
  });
}

// 游客船票名单保存
export function createOrUpdateTourist2(data) {
  return request({
    url: '/order/updateMembers',
    method: 'post',
    data
  });
}

// 备注 创建和更新
export function createOrUpdateRemarks(data) {
  return request({
    url: '/order/saveRemarks',
    method: 'post',
    data
  });
}

// 房型 创建和更新
export function createOrUpdateRoomType(data) {
  return request({
    url: '/order/saveRoom',
    method: 'post',
    data
  });
}

// 接送 创建和更新(多条)
export function createOrUpdatePickup(data) {
  return request({
    url: '/order/saveOrUpdateShuttles',
    method: 'post',
    data
  });
}

// 接送 创建 (单条)
export function createOrShuttle(data) {
  return request({
    url: '/order/saveShuttle',
    method: 'post',
    data
  });
}

// 返款 创建和更新
export function createOrUpdateRefund(data) {
  return request({
    url: '/order/saveOrUpdateSubsidys',
    method: 'post',
    data
  });
}

// 大交通 创建和更新
export function createOrUpdateTraffic(data) {
  return request({
    url: '/order/saveTransport',
    method: 'post',
    data
  });
}

// 创建大交通列表
export function createOrUpdateTraffic2(data) {
  return request({
    url: '/order/transportCompany/orderAddAndUpdateTransport',
    method: 'post',
    data
  });
}

// 创建大交通列表
export function createOrUpdateTraffic3(data) {
  return request({
    url: '/order/transportCompany/orderAddAndUpdateTransport-2',
    method: 'post',
    data
  });
}

// 保险创建和更新
export function createOrUpdateInsurance(data) {
  return request({
    url: '/order/insurance/order-A-and-U-insurance-o',
    method: 'post',
    data
  });
}

// 保存景区
export function createOrScenic(data) {
  return request({
    url: '/order/orderScenic/saveOrder',
    method: 'post',
    data
  });
}

/** *********************删除************************** */

// 退单
export function revoke(data) {
  return request({
    url: '/order/cancel',
    method: 'post',
    data
  });
}

// 取消计划/移除计划
export function cancelPlan(id) {
  return request({
    url: '/order/cancelGroup',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单 删除
export function deleteOrder(id) {
  return request({
    url: '/order/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 代收 删除
export function deleteProxy(id) {
  return request({
    url: '/order/delCollect',
    method: 'post',
    data: {
      id
    }
  });
}

// 团款 删除
export function deleteFund(id) {
  return request({
    url: '/order/delFee',
    method: 'post',
    data: {
      id
    }
  });
}

// 大交通 删除
export function deleteTraffic(id) {
  return request({
    url: '/order/transportCompany/orderDelTransport',
    method: 'post',
    data: {
      id
    }
  });
}

// 大交通类型 删除
export function deleteTrafficTypeRequest(typeId) {
  return request({
    url: '/order/transportCompany/orderDelTransportType',
    method: 'post',
    data: {
      typeId
    }
  });
}

// 保险 删除
export function deleteInsurance(id) {
  return request({
    url: '/order/insurance/orderDelInsurance',
    method: 'post',
    data: {
      id
    }
  });
}

// 行程 删除（单独行程）
export function deleteItinerary(id) {
  return request({
    url: '/order/delItinerary',
    method: 'post',
    data: {
      id
    }
  });
}

// 行程 删除（多条行程）
export function deleteItinerarys(data) {
  return request({
    url: '/order/delItinerarys',
    method: 'post',
    data
  });
}

// 行程 删除 订单的所有行程
export function delItineraryByOrderId(customerOrderId) {
  return request({
    url: '/order/delItineraryByOrderId',
    method: 'post',
    data: {
      customerOrderId
    }
  });
}

// 游客 删除
export function deleteTouriest(id) {
  return request({
    url: '/order/delMember',
    method: 'post',
    data: {
      id
    }
  });
}

// 游客 批量删除删除
export function deleteTouriestList(ids) {
  return request({
    url: '/order/delMemberList',
    method: 'post',
    data: ids
  });
}

// 游客黑名单查询
export function gthAndSaveUsers(data) {
  return request({
    url: '/blacklist/tourist/searchAndSaveUsers',
    method: 'post',
    data
  });
}
// 更新黑 名单游客备注
export function updateMembersRemarks(data) {
  return request({
    url: '/blacklist/tourist/updateMembersRemarks',
    method: 'post',
    data
  });
}

// ids 查询游客黑名单详细信息
export function getSearchByIds(params) {
  return request({
    url: '/blacklist/tourist/searchByIds',
    method: 'get',
    params
  });
}

// 游客黑名删除
export function delBlacklistTouriest(data) {
  return request({
    url: '/blacklist/tourist/delUsers',
    method: 'post',
    data
  });
}

// 房型 删除
export function deleteRoomType(id) {
  return request({
    url: '/order/delRoom',
    method: 'post',
    data: {
      id
    }
  });
}

// 景点 删除
export function deleteScenic(id) {
  return request({
    url: '/order/delScenic',
    method: 'post',
    data: {
      id
    }
  });
}

// 接送 删除
export function deletePickup(id) {
  return request({
    url: '/order/delShuttle',
    method: 'post',
    data: {
      id
    }
  });
}

// 返款 删除
export function deleteRefund(id) {
  return request({
    url: '/order/delSubsidy',
    method: 'post',
    data: {
      id
    }
  });
}

// 大交通 删除
// export function deleteTraffic(id) {
//   return request({
//     url: '/order/delTransport',
//     method: 'post',
//     data: { id },
//   })
// }

// 外转订单
export function turnOut(data) {
  return request({
    url: '/order/out/saveOrUpdate',
    method: 'post',
    data
  });
}

// 外转订单批量
export function turnOutBatch(data) {
  return request({
    url: '/order/out/saveOrUpdateOuts',
    method: 'post',
    data
  });
}

// 查询外转订单
export function fetchTurnOut(customerOrderId) {
  return request({
    url: '/order/out/listByOrderId',
    method: 'get',
    params: {
      customerOrderId
    }
  });
}

// 删除地接社 整块内容
export function deleteReception(id) {
  return request({
    url: '/order/out/removeOutCustomer',
    method: 'post',
    data: {
      id
    }
  });
}

// 删除地接费用项目
export function deleteReceptionFeeItem(id) {
  return request({
    url: '/order/out/removeOutFee',
    method: 'post',
    data: {
      id
    }
  });
}

// 删除地接代收项目
export function deleteReceptionProxyItem(id) {
  return request({
    url: '/order/out/removeOutCollect',
    method: 'post',
    data: {
      id
    }
  });
}

// 删除地接代收项目
export function deleteReceptionRefundItem(id) {
  return request({
    url: '/order/out/removeOutSubsidy',
    method: 'post',
    data: {
      id
    }
  });
}

// 查询订单酒店信息
export function fetchHotel(params) {
  return request({
    url: '/order/hotel/getOrderHotels',
    method: 'get',
    params,
    headers: {
      menuId: -1
    }
  });
}

// 订单酒店安排修改与新增
export function createOrHotel(data) {
  return request({
    url: '/order/hotel/orderAddAndUpdateHotel',
    method: 'post',
    data
  });
}

// 订单酒店删除
export function deleteOrHotel(id) {
  return request({
    url: '/order/hotel/orderDelHotel',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单审核列表
export function fetchApplyAudit(params) {
  return request({
    url: '/order/apply-audit/pageOrder',
    method: 'get',
    params
  });
}

// 订单审核列表取消审核
export function fetchCancelAudit(data) {
  return request({
    url: '/order/apply-audit/del',
    method: 'post',
    data
  });
}

// 订单审核列表审核
export function fetchAuditStatus(data) {
  return request({
    url: '/order/apply-audit/review',
    method: 'post',
    data
  });
}

// 订单审核列表批量审核
export function fetchApplyAuditList(data) {
  return request({
    url: '/order/apply-audit/review-list',
    method: 'post',
    data
  });
}

// 根据订单id 获取订单修改信息以及进度
export function fetchApplyAuditById(params) {
  return request({
    url: '/order/apply-audit/getApplyAuditByOrderId',
    method: 'get',
    params
  });
}

// 获取计划审核状态
export function getPlanObjectStatus(params) {
  return request({
    url: '/order/plan/findObject',
    method: 'get',
    params
  });
}
// 返款是否冲抵 未收
export function unDeductSubsidy(data) {
  return request({
    url: '/order/un-income-deduct-subsidy',
    method: 'post',
    data
  });
}

// 设置订单确认件
export function confirmFileRequest(data) {
  return request({
    url: '/order/confirmed-item-file-path',
    method: 'post',
    data
  });
}