import "core-js/modules/es.array.push.js";
import { SelectGuide, PersonCount } from '@/components/common';
// import { PersonCount } from '@/components/common'
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { DialogLog } from '@/components/common';
import { processMinus } from '@/utils/number';
import { parseDate, getURLParameters } from '@/utils';
import { checkPayStatus, notifyPayStatus } from '@/utils/alipay';
import { planSmsSendManager as columns } from '@/config/plan/smsSend';
// import { VAS_TYPE } from '@/config/vas'
// import DialogTopUp from './DialogTopUp.vue'
// import DialogTopUpRecord from './DialogTopUpRecord.vue'

import DialogSend from './DialogSend.vue';
import { Sms, DialogTopUp, DialogTopUpRecord } from '@/components/common';
export default {
  components: {
    Sms,
    SelectGuide,
    PersonCount,
    DialogLog,
    DialogSend,
    DialogTopUp,
    DialogTopUpRecord
  },
  mixins: [searchMixin],
  data() {
    const start = new Date();
    const lastTwoWeek = parseDate(new Date(start.getTime() - 3600 * 1000 * 24 * 7 * 2));
    return {
      columns,
      tableData: [],
      total: 0,
      searchParams: {},
      sendDate: [lastTwoWeek]
      // vasData: null,
    };
  },
  computed: {
    ...mapGetters({
      guideData: 'bill/billDown/guide'
    }),
    getUnpay() {
      return row => processMinus(row.travelAgencyPay, row.travelAgencyPayed);
    }
    // getCount() {
    //   return this.vasData ? this.vasData.remainder : 0
    // },
  },
  watch: {
    guideData: {
      handler: 'initData',
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            startTripDateS,
            startTripDateE
          } = data;
          this.searchParams = data;
          this.sendDate = [startTripDateS, startTripDateE];
        }
      },
      immediate: true
    },
    getTagViewSearchExtra: {
      handler: function (data) {
        if (data) {
          this.extra = data;
          this.department = this.extra.department;
        }
      },
      immediate: true
    }
  },
  mounted() {
    // https://ddc6-116-1-54-102.ap.ngrok.io/plan/sms-send?charset=UTF-8&out_trade_no=SMS_13_VASPay_1680164154134&method=alipay.trade.page.pay.return&total_amount=0.01&sign=IDSI8Lnx8MGSkxdoWY%2B4VCq%2B%2FesGiSNtN6RxhjtfUSF4pldFAZHRHXJ%2Ftk0b4Tq8Rwnxg8lAYa8oN4BRG0SZLocIxIxRZaPswO06RuwVWE2TV%2BTTHABK5Va5UtbykAvdg5Mxdyjd%2BQtdvkANCaB%2BmEroQ33KA3rpo0WIBfWOzgXk8Iczog7KSzwG8p%2FIIKXOckYx0JAFIR4cytDBF%2B34CSImBHHPffXxuvnS4cN%2BK2EqI6ZhzoF%2BbcC0rKDlw%2BqUuOzYmI7DUZnctvAsMhcffN8FZCNHsDjAf%2B4rvd1zHP3fwh0f%2FZkSga8WytxwPK1HptgZMmwVGo5i4T0aOg2v6Q%3D%3D&trade_no=2023033022001483120503128777&auth_app_id=2021000117602658&version=1.0&app_id=2021000117602658&sign_type=RSA2&seller_id=2088621955209746&timestamp=2023-03-30+16%3A16%3A54

    const searchObj = getURLParameters(this.$route.fullPath);
    if (searchObj && searchObj.trade_no) {
      checkPayStatus(this.$store, {
        outTradeNo: searchObj.out_trade_no
      }).then(res => {
        if (!res || !res.data) return;
        // "{"alipay_trade_query_response":{"code":"10000","msg":"Success","buyer_logon_id":"gcv***@sandbox.com","buyer_pay_amount":"0.00","buyer_user_id":"2088722000283125","buyer_user_type":"PRIVATE","invoice_amount":"0.00","out_trade_no":"SMS_61_VASPay_OX744769","point_amount":"0.00","receipt_amount":"0.00","send_pay_date":"2023-04-03 11:16:06","total_amount":"0.01","trade_no":"2023040322001483120503130583","trade_status":"TRADE_SUCCESS"},"alipay_cert_sn":"c5f642c5604a1cdb9e6cb54ffa804cf5","sign":"VOAc/ate03gPGwSbJdyc/w+7EI5I5Xoob1fxhJg5+Knv1UVlAytLU1Enegi17wR4Q7dyP8mqab3woFaOG0AEPQkIRjlkC3LsvSB3VwzYy16uQh2gmayfFeEZ5sGIdHhbXyweq1NUhkSqoJZNt8/r/+YBpmDZcF305beXjCZ3R3XD9rWhWExw1b3iVuLYkmbHaX6S+2ZosVRxjNnOXWdZ4GEJbX/wT5//igFB34xiu1JR4TmA/Q09vG/Bx7bn5KJPO+tXM8Vs4idBeUaFPqybdoS5TqA3/CTqqe8tO9t7r/GxU+n6Vuxk/6PChsiXuTqVJQW8qmclHUoqqnNJYJ6UXw=="}"

        this.$bus.tip({
          message: '充值成功'
        });
        notifyPayStatus(this.$store, searchObj).then(() => {
          this.fetchVasList();
        });
      }).catch(() => {
        this.$bus.tip({
          type: 'danger',
          message: '充值失败'
        });
        notifyPayStatus(this.$store, searchObj, false);
      });
      this.$router.push('/plan/sms-send');
    } else {
      this.fetchVasList();
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData();
      } else {
        this.handleData(data);
      }
    },
    // alipayNotify(params = {}) {
    //   params = {
    //     ...params,
    //     status: PAY_ERR_STATUS.success,
    //     payType: PAY_NET_TYPE.ALIPAY,
    //   }

    //   this.$store
    //     .dispatch('system/payment/updateAlipayNotify', params)
    //     .then(() => {
    //       this.$bus.tip({ message: '充值成功' })
    //     })
    //     .catch(() => {
    //       this.$bus.tip({ message: '充值失败', type: 'error' })
    //     })

    //   this.$router.push('/plan/sms-send')
    // },

    fetchData(params = {}) {
      this.$store.dispatch('bill/billDown/fetchGuide', params);
    },
    fetchVasList(params = {}) {
      this.$refs.smsRef.fetchVasList(params);
    },
    reload() {
      this.fetchData(this.searchParams);
      this.fetchVasList();
    },
    handleSearch() {
      this.fetchData({
        ...this.searchParams,
        pageNum: 1
      });
    },
    // 微信付款成功
    wechatPaySuccess() {
      this.reload();
      this.$refs.dialogTopUpRef.hide();
    },
    // 微信支付弹窗
    wechatPayDialog(data) {
      this.$refs.dialogWxPayRef.show(data);
    },
    // 充值成功
    topUpDialog() {
      this.$refs.dialogTopUpRef.show();
    },
    // 充值记录弹窗
    topUpRecordDialog() {
      this.$refs.dialogTopUpRecordRef.show();
    },
    // 发送短信弹窗
    sendMessageDialog(row) {
      this.$refs.dialogSendRef.show(row);
    },
    showLog(row) {
      const {
        planId
      } = row;
      this.$store.dispatch('order/index/fetchSMSLog', planId).then(res => {
        this.$refs.dialogRef.show(res);
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistics = statistics;
    },
    selectGuide(val) {
      this.searchParams.guideName = val ? val.label : '';
      this.searchParams.guideId = val ? val.value : '';
    },
    changeStartTripDate(val) {
      val = val || ['', ''];
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    }
  }
};