import "core-js/modules/es.array.reduce.js";
/*
 * @Author: HelloMan
 * @Date: 2021-10-19 17:09:25
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-19 17:19:50
 * @Description:
 */
import { compose } from './index';
import { BigNumber } from 'bignumber.js';
export const processNumber = number => {
  return new BigNumber(number).toNumber();
};
export const plus1000 = n => +n * 1000;
export const decrease1000 = n => +n / 1000;

// 多个数相乘
export const multiplyNumber = (...args) => {
  if (args.length < 2) return args[0];
  let total = 1;
  for (let i = 0; i < args.length; i++) {
    total *= plus1000(+args[i]);
  }
  for (let i = 0; i < args.length; i++) {
    total = decrease1000(total);
  }
  return total;
};
export const add = (...args) => {
  return args.reduce((acc, cur) => (acc, plus1000(cur) + acc), 0);
};
export const reduce = (n1, n2) => plus1000(n1) - plus1000(n2);

// 多个数相加
export const processAdd = (...args) => {
  return compose(decrease1000, add)(...args);
};

// 两个数相减
export const processMinus = (n1, n2) => {
  return compose(decrease1000, reduce)(n1, n2);
};