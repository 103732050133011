import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/account/fetchList'
  }),
  computed: {
    data() {
      const data = this.$store.getters['resource/account/accountData'];
      return data && data.list;
    }
  },
  methods: {}
};