var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("DialogBase", {
    attrs: {
      title: "安排列表",
      width: "90%",
      "append-to-body": false,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("Hotel", {
    ref: "hotelRef",
    attrs: {
      loading: _vm.tableLoading,
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "plan-id": _vm.planId
    },
    on: {
      "on-members": _vm.showMembers
    }
  })], 1), _c("DialogOrder", {
    ref: "orderRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };