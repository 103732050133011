// import { permissionMixin } from '@/mixin'
import permission from '@/directive/permission/index';
export default {
  directives: {
    permission
  },
  // mixins: [permissionMixin],
  props: {
    row: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};