import "core-js/modules/es.array.push.js";
import orderMixin from '../orderMixin';
import commonMixin from '../commonMixin';
import { orderType } from '@/config/order';
import { tagViewMixin, permissionMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { OrderAuditEmit } from '@/config/order';
export default {
  name: 'PersonalUpdate',
  mixins: [tagViewMixin, permissionMixin, orderMixin, commonMixin],
  data: () => ({
    backPath: '/order/personal',
    form: null,
    orderType: orderType.personal,
    tipMsg: '点击项目标题可收缩或展开当前项目内容。',
    type: orderType.personal
  }),
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      visitedViews: 'tagsView/visitedViews'
    })
  },
  beforeMount() {
    const {
      id,
      isNew
    } = this.$route.query;
    this.fetchData(id);
    this.$store.commit('order/index/CUR_ORDER_ID', id);
    this.$store.commit('order/index/CUR_ORDER_TYPE', this.orderType);

    // 删除创建的TagView
    if (isNew) {
      const createTag = this.findCreateTagView();
      createTag && this.closeSelectedTag(createTag);
    }
  },
  beforeDestroy() {
    this.$store.commit('order/index/NEED_RELOAD', {
      type: 'Personal',
      flag: true
    });
  },
  mounted() {
    this.$store.commit('resource/custom/CLEAN_CUSTOM_DATA');
  },
  methods: {
    // 根据配置，是否生成返款
    generateRefund() {
      this.$refs.personalFundRef.handleGenerateRefund();
    },
    findCreateTagView() {
      return this.visitedViews.find(it => it.name === 'orderPersonalInput');
    },
    showTraffic(data) {
      this.$refs.personalTrafficRef.show(data);
    },
    editTraffic(data) {
      this.$refs.personalTrafficRef.edit(data);
    },
    fetchData(id) {
      this.$store.dispatch('order/index/fetchDetail', {
        id
      }).then(data => {
        const {
          provinceId,
          cityId,
          districtId
        } = data;
        let region = [provinceId + ''];
        cityId > 0 && region.push(cityId + '');
        districtId > 0 && region.push(districtId + '');
        data.region = region;
        this.form = data;
        this.$store.commit('order/index/CUR_CUSTOMER_ID', data.customerId);
      });
    },
    // 更新行程
    updateTrip(opts) {
      this.$refs.personalItineraryRef.updateTrip(opts);
    },
    processUpdate(addAuditStatus) {
      const {
        id
      } = this.$route.query;
      const {
        personalFormRef,
        personalItineraryRef,
        personalFundRef,
        personalHotelRef,
        personalProxyRef,
        personalRefundRef,
        trafficRef,
        personalPickupRef,
        personalTouristRef,
        personalRemarksRef,
        personalReceptionRef,
        otherIncomeRef,
        personalInsuranceRef,
        otherPayRef
      } = this.$refs;
      // 基础信息
      let opts = {
        ...personalFormRef.form,
        addAuditStatus
      };
      // 行程信息
      opts.reqItinerarys = personalItineraryRef.beforeCommit(personalItineraryRef.tableData);
      // 备注
      opts.reqOrderRemarks = personalRemarksRef.beforeCommit(id);
      // 酒店信息
      opts.addHotelOs = personalHotelRef.beforeCommit(id);
      // 团款信息
      opts.customerFees = personalFundRef.beforeCommit(id);
      // 代收信息
      opts.reqOrderCustomerCollects = personalProxyRef.beforeCommit(id);
      // 大交通信息
      opts.addOrderTransports = trafficRef.beforeCommit(id);
      // 保险信息
      opts.addOrderInsurances = personalInsuranceRef.beforeCommit(id, '');
      // 接送信息
      opts.reqOrderCustomerShuttles = personalPickupRef.beforeCommit(id);
      // 其他收入信息
      opts.reqOrderOtherIncomeOS = otherIncomeRef.beforeCommit(id);
      // 其他支出信息
      opts.reqOrderOtherPays = otherPayRef.beforeCommit(id);
      // 返款信息
      opts.reqOrderCustomerSubsidies = personalRefundRef.beforeCommit(id);
      // 游客信息
      opts.reqOrderCustomerMembers = personalTouristRef.beforeCommitFomat(id);
      // 外转信息
      opts.reqOutTransfer = personalReceptionRef.receptionList.length ? personalReceptionRef.receptionList : null;
      this.$store.dispatch('order/index/createOrUpdateAll', opts).then(() => {
        this.$bus.tip();
        // 获取最新数据
        personalFormRef.fetchData(id);
        personalTouristRef.fetchData();
        personalHotelRef.fetchData();
        personalPickupRef.fetchData();
        trafficRef.fetchData();
        personalProxyRef.fetchData();
        personalItineraryRef.fetchData();
        personalRemarksRef.fetchData();
        personalFundRef.fetchData();
        personalInsuranceRef.fetchData();
        otherIncomeRef.fetchData();
        otherPayRef.fetchData();
        personalPickupRef.fetchData();
        personalReceptionRef.fetchData();
      });
    },
    handleUpdate() {
      this.processUpdate(OrderAuditEmit.NEW_WAIT);
    },
    // handleUpdateAndAudit() {
    //   this.processUpdate(OrderAuditEmit.NEW_WAIT)
    // },

    handleBack() {
      // 清理数据
      this.$store.commit('order/index/CUR_ORDER', null);

      // 关闭页面
      this.$bus.emit('order:personal-close');

      // 是否需要更新数据
      this.$store.commit('order/index/NEED_RELOAD', {
        type: 'Personal',
        flag: true
      });

      // 返回列表
      // 路径是页面内配置
      this.$router.push({
        path: this.backPath
      });
    }
  }
};