var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "settlementPrint"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "团队计划单",
      logo: _vm.data.travelAgencyVo.logoUrl
    }
  }), _c("p", {
    staticClass: "flex",
    staticStyle: {
      padding: "0 30px"
    }
  }, [_c("span", {
    staticClass: "flex-1"
  }, [_vm._v(" 代收：" + _vm._s(_vm.data.guideCollect || 0))])]), _c("div", {
    staticClass: "content"
  }, [_c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col"
    });
  }), 0), _c("tbody", [_vm._l(_vm.tableData, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(item.rows, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        class: col.label ? "item-label" : "item-content",
        attrs: {
          colspan: col.span
        }
      }, [col.slot ? [col.content == "number" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.adultCount) + "大 " + _vm._s(_vm.data.childCount) + "小" + _vm._s(_vm.data.accompanyCount) + "陪 ")]) : _vm._e(), col.content == "busCompanyTypes" ? _c("div", [_vm.getBusTypeList.length ? _vm._l(_vm.getBusTypeList, function (type, tIdx) {
        return _c("div", {
          key: tIdx
        }, [_vm._v(" " + _vm._s(type) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "busFees" ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.data.busFees))])]) : _vm._e(), col.content == "driverName" ? _c("div", [_vm.getArrayList("driverName") ? _vm._l(_vm.getArrayList("driverName"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "licenceNumbers" ? _c("div", [_vm.getArrayList("licenceNumbers") ? _vm._l(_vm.getArrayList("licenceNumbers"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "busCompanyNames" ? _c("div", [_vm.getArrayList("busCompanyNames") ? _vm._l(_vm.getArrayList("busCompanyNames"), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      }) : _vm._e()], 2) : _vm._e(), col.content == "arriveDate" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.arriveDate ? _vm.formatDateTime(_vm.data.arriveDate) : "") + " ")]) : _vm._e(), col.content == "leaveDate" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.leaveDate ? _vm.formatDateTime(_vm.data.leaveDate) : "") + " ")]) : _vm._e(), col.content == "hotel" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.travelAgencyVo.agencyInfoHotelAdjustment) + " ")]) : _vm._e(), col.content == "bus" ? _c("div", [_vm._v(" " + _vm._s(_vm.data.travelAgencyVo.agencyInfoCarAdjustment) + " ")]) : _vm._e()] : [_vm._v(" " + _vm._s(col.label ? col.label : _vm.data[col.content]) + " ")]], 2)];
    })], 2)];
  })], 2)]), _c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col"
    });
  }), 0), _c("tbody", [_vm._m(0), _vm._l(_vm.data.respOrderTrips, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(_vm.tableTrip, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        class: ["item-content", {
          tac: cIdx == 0 || cIdx == _vm.tableTrip.length - 1
        }],
        attrs: {
          colspan: col.span
        }
      }, [!col.slot ? _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")]) : _vm._l(_vm.formatRoom(item.roomNames), function (it, i) {
        return _c("div", {
          key: i
        }, [_vm._v(" " + _vm._s(it) + " ")]);
      })], 2)];
    })], 2)];
  })], 2)]), _c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col"
    });
  }), 0), _c("tbody", [_vm._l(_vm.tableData2, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(item.rows, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        class: col.label ? "item-label" : "item-content",
        attrs: {
          colspan: col.span
        }
      }, [_vm.isDataRemark(col.content) ? [col.content == "restaurantRemark" ? [_vm._v(" " + _vm._s(_vm.data.travelAgencyVo.agencyInfoRestaurantAdjustment) + " " + _vm._s(_vm.data[col.content]) + " ")] : [_vm._v(" " + _vm._s(_vm.data[col.content]) + " ")]] : [_vm._v(" " + _vm._s(col.label ? col.label : _vm.data.travelAgencyVo[col.content]) + " ")]], 2)];
    })], 2)];
  })], 2)])]), _c("p", {
    staticClass: "flex",
    staticStyle: {
      padding: "10px 30px"
    }
  }, [_c("span", {
    staticClass: "flex-1"
  }, [_vm._v(" 签发人： " + _vm._s(_vm.data.dutyOPUserName))]), _c("span", {
    staticStyle: {
      flex: "0 0 150px"
    }
  }, [_vm._v("日期： " + _vm._s(_vm.printDate))])]), _c("SealImage", {
    attrs: {
      comp: "GuidePlanTeam"
    }
  })], 1) : _c("Empty");
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("日期")]), _c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("行程")]), _c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("酒店")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("用餐")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };