import "core-js/modules/es.array.push.js";
import Tinymce from '@/components/Tinymce';
import { SelectMutiple } from '@/components/common';
import { mapGetters } from 'vuex';
import { NOTICE } from '@/config/notice';
const defaultState = () => ({
  title: '',
  content: '',
  isPopWindow: false,
  authAreaType: NOTICE.authAreaType.ALL,
  noticeAuthAreas: {},
  type: NOTICE.type.COMPANY
});
export default {
  components: {
    Tinymce,
    SelectMutiple
  },
  data() {
    return {
      isUpdate: false,
      visible: false,
      noticeOpts: defaultState(),
      noticeAuthAreas: null,
      radios: [{
        label: NOTICE.authAreaType.ALL,
        value: '全部'
      }, {
        label: NOTICE.authAreaType.ASSIGN,
        value: '指定'
      }]
    };
  },
  computed: {
    ...mapGetters('system/notice', ['noticeDetail']),
    getTitle() {
      return this.isUpdate ? '更新公告' : '新增公告';
    },
    isAll() {
      return this.noticeOpts.authAreaType && this.noticeOpts.authAreaType.toUpperCase() == NOTICE.authAreaType.ALL;
    }
  },
  watch: {
    noticeDetail: {
      handler: function (item) {
        if (!item || !this.isUpdate) return;
        this.noticeOpts = item;
        this.noticeOpts.authAreaType = item.authAreaType.toUpperCase();
      },
      immediate: true
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.isUpdate = false;
      this.noticeOpts = defaultState();
    },
    hide() {
      this.visible = false;
    },
    edit() {
      this.visible = true;
      this.isUpdate = true;
    },
    changeRadio(val) {
      this.noticeOpts.authAreaType = val;
    },
    changeDepartment(list) {
      // this.noticeOpts.authAreaType = list && list.length ? 'assign' : 'all'
      const result = [];
      list.forEach(val => {
        const [departmentId, subDepartmentId, grandsonDepartmentId] = val;
        const opts = {
          departmentId,
          grandsonDepartmentId,
          noticeId: this.noticeOpts.id,
          subDepartmentId
        };
        result.push(opts);
      });
      this.noticeOpts.noticeAuthAreas = result;
    },
    handleSave() {
      if (this.isAll) {
        delete this.noticeOpts.noticeAuthAreas;
      }
      if (this.isUpdate) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    handleCreate() {
      this.$store.dispatch('system/notice/create', this.noticeOpts).then(() => {
        this.$emit('reload:data');
        this.$bus.tip();
        this.hide();
      });
    },
    handleUpdate() {
      this.$store.dispatch('system/notice/update', this.noticeOpts).then(() => {
        this.$emit('reload:data');
        this.$bus.tip();
        this.hide();
      });
    },
    handleCancel() {
      this.resetForm();
    },
    resetForm() {
      this.noticeOpts = {};
    }
  }
};