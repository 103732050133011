var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "修改申请信息",
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true,
      "confirm-text": "确认处理"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_vm.row ? _c("p", [_vm._v(" " + _vm._s(_vm.row.modifyInfo) + " ")]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };