import { inputMixin } from '@/mixin';
export default {
  name: 'LabelBase',
  mixins: [inputMixin],
  props: {
    labelWidth: {
      type: String,
      default: ''
    },
    isRequire: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    semi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      findCount: 0
    };
  },
  computed: {
    getFontSize() {
      const res = {};
      res.fontSize = this.$store.getters['app/getFontSize'] + 'px';
      return res;
    },
    form() {
      let parent = this.$parent;
      let parentName = parent.$options._componentTag;
      while (parentName && parentName !== 'FormBase') {
        parent = parent.$parent;
        parentName = parent.$options._componentTag;
      }
      if (parentName !== 'FormBase') {
        return;
      }
      return parent;
    },
    labelStyle() {
      const ret = {};
      const attrObj = this.form ? this.form.$attrs : {
        width: '60px'
      };
      const labelWidth = this.labelWidth || attrObj['label-width'];
      if (labelWidth) {
        ret.width = labelWidth;
      }
      if (attrObj.align) {
        ret.textAlign = attrObj.align;
      }
      if (attrObj.inline) {
        ret.display = 'inline-block';
      }
      return ret;
    }
  }
};