import "core-js/modules/es.array.reduce.js";
import { CustomerInfo } from '@/components/common';
const subTotal = {
  label: '小计',
  summary: 'row',
  prop: 'summary',
  width: '60px'
};
const total = {
  label: '合计',
  summary: 'total',
  prop: 'total'
};
export default {
  components: {
    CustomerInfo
  },
  props: {
    columnData: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    subTotal: {
      type: Boolean,
      default: false
    },
    rowTitle: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    total: {
      type: [String, Number],
      default: ''
    },
    title: {
      type: String,
      default: '客户代收'
    }
  },
  data() {
    return {
      column: []
    };
  },
  computed: {
    formatSubTotal() {
      return val => {
        return val ? val.replace(/\.0+/g, '') : '';
      };
    },
    getColumn() {
      if (this.column.length) {
        return this.subTotal ? this.column.slice(1, this.column.length - 2) : this.column.slice(1, this.column.length - 1);
      }
      return [];
    },
    getTotalLength() {
      if (!Array.isArray(this.tableData)) return;
      const total = this.tableData.reduce((acc, cur) => {
        acc += cur.list ? cur.list.length : 0;
        return acc;
      }, 1);
      return Number(total).toFixed(2);
    },
    formatMoney() {
      return val => {
        if (!val) return '';
        let result = val.split(':');
        if (result.length < 2) {
          result = val.split('：');
        }
        const [prefix, money] = result;
        return `${prefix.replace(/^导游签单/g, '签单')}:${parseFloat(money)}`;
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      const column = this.subTotal ? [...this.columnData, subTotal, total] : [...this.columnData, total];
      this.column = column;
    });
  }
};