var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page"
  }, [_c("TopTitle", {
    attrs: {
      title: "导游报账表"
    }
  }), _c("BaseInfo", {
    attrs: {
      data: _vm.data.baseInfo
    }
  }), _c("SubmitInfo", {
    attrs: {
      data: _vm.data.submitInfo
    }
  }), _c("Collect", {
    attrs: {
      "table-data": _vm.data.collect,
      total: _vm.data.totalCustomerCollect
    }
  }), _c("GuideBorrow", {
    attrs: {
      "table-data": _vm.data.borrows,
      total: _vm.data.totalBorrow
    }
  }), _c("ShopCash", {
    attrs: {
      "table-data": _vm.data.shopCash,
      total: _vm.data.totalShopIncome
    }
  }), _c("OtherIncome", {
    attrs: {
      "table-data": _vm.data.otherIncome,
      total: _vm.data.totalOtherIncome
    }
  }), _c("ScenicCash", {
    attrs: {
      "table-data": _vm.data.scenicCash,
      total: _vm.data.totalScenicIncome
    }
  }), _c("GuideFee", {
    attrs: {
      "table-data": _vm.data.guideFee,
      total: _vm.data.guideServiceFee,
      "sub-title": _vm.data.guideRebate
    }
  }), _c("ScenicCommission", {
    attrs: {
      "table-data": _vm.data.scenicCommissions,
      total: _vm.data.totalScenicCommission
    }
  }), _c("ShopCommission", {
    attrs: {
      "table-data": _vm.data.shopCommissions,
      total: _vm.data.totalShopCommission
    }
  }), _c("Scenic", {
    attrs: {
      "table-data": _vm.data.scenics,
      total: _vm.data.totalScenic
    }
  }), _c("Hotel", {
    attrs: {
      "table-data": _vm.data.hotels,
      total: _vm.data.totalHotel
    }
  }), _c("Restaurant", {
    attrs: {
      "table-data": _vm.data.restaurants,
      total: _vm.data.totalRestaurant
    }
  }), _c("Bus", {
    attrs: {
      "table-data": _vm.data.buses,
      total: _vm.data.totalBus
    }
  }), _c("Insurance", {
    attrs: {
      "table-data": _vm.data.insurances,
      total: _vm.data.totalInsurance
    }
  }), _c("OtherPay", {
    attrs: {
      "table-data": _vm.data.otherPay,
      total: _vm.data.totalOtherPay
    }
  }), _c("SealImage", {
    attrs: {
      comp: "GuideSubmit"
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };