var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "settlementPrint"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "费用结算单"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("table", [_c("colgroup", _vm._l(24, function (item, index) {
    return _c("col", {
      key: index,
      class: "col-item col" + index
    });
  }), 0), _vm._m(0)])]), _c("SealImage", {
    attrs: {
      comp: "Settlement"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tbody", [_c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【基础信息】")])])]), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("大团号")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("TCdfsfsafsa")])]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("人数")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("XXX")])]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("抵离日期")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "10"
    }
  }, [_c("span", [_vm._v("桂林大漓江4天3晚阳朔纯玩游")])])]), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("小团号")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("TCdfsfsafsa")])]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("游客信息")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("张三丰")])]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("全陪")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "10"
    }
  }, [_c("span", [_vm._v("刘晓梅")])])]), _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【费用信息】")])])]), _c("tr"), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 费用类型 ")]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 项目 ")]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 单价 ")]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 数量 ")]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 单位 ")]), _c("th", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 金额 ")])]), _c("tr", [_c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("xxxxx")])]), _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("行程信息行程信息行程信息行程信息行程信息 行程信息行程信息行程信息行程信息")])]), _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("fsdfsafdas")])]), _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("张默默 13565985478")])]), _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("桂林大公馆")])]), _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("span", [_vm._v("0")])])]), _c("tr"), _c("tr", [_c("th", {
    staticClass: "tac",
    attrs: {
      colspan: "2",
      rowspan: "5"
    }
  }, [_c("strong", [_vm._v("合计")])]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("应收款")])]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v("12000")])])]), _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("已收")])]), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v("12000")])])]), _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("代收")])]), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v("12000")])])]), _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("返款")])]), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v("12000")])])]), _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("余额")])]), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v("12000")])])]), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("开户行")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "22"
    }
  }, [_c("span", [_vm._v("TCdfsfsafsa")])])]), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("户名")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "22"
    }
  }, [_c("span", [_vm._v("TCdfsfsafsa")])])]), _c("tr", [_c("th", {
    staticClass: "label",
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("账号")])]), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "22"
    }
  }, [_c("span", [_vm._v("TCdfsfsafsa")])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };