import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import progressDetail from './progressDetail';
import FooterScrollBar from '@/components/FooterScrollBar';
import { BtnMore, BtnExport, SelectStaff, SelectGuide, SelectCustom, SelectProduct, FooterBar } from '@/components/common';
import { departIdKeys } from '@/config/dictionary';
import { customerInfoMixin, searchMixin } from '@/mixin';
import { throttle, getStyle } from '@/utils';
import { orderAudit, reviewerStatusType } from '@/config/order';
import { mapGetters } from 'vuex';
export default {
  components: {
    BtnMore,
    BtnExport,
    SelectGuide,
    SelectCustom,
    SelectStaff,
    SelectProduct,
    FooterBar,
    TableList,
    progressDetail,
    FooterScrollBar
  },
  mixins: [searchMixin, customerInfoMixin],
  data: () => ({
    filename: '所有订单.xlsx',
    excelLoading: false,
    visibleMore: false,
    columnData: orderAudit,
    tableData: [],
    total: 0,
    link: {
      detail: '/order/order-audit-detail'
    },
    searchParams: {},
    department: [],
    inputDate: [],
    endDate: [],
    barWidth: 0,
    realWidth: 0,
    tableWidth: 0,
    tableBody: null,
    auditStatus: 1,
    progresses: []
  }),
  computed: {
    ...mapGetters({
      orderAuditData: 'order/index/orderAuditData',
      auditPageRefresh: 'order/index/auditPageRefresh'
    }),
    getVisible() {
      return this.visibleMore;
    },
    getBarWidth() {
      return this.barWidth;
    }
  },
  watch: {
    orderAuditData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.getToday
          });
          return;
        }
        this.handleData(data);
        this.getScrollWidth();
      },
      immediate: true
    },
    // 获取页面是否刷新状态(目前审核详情页面转跳回来需要更新数据)
    auditPageRefresh: {
      handler: function (status) {
        if (status) {
          this.fetchData(this.searchParams);
          this.$store.commit('order/index/ORDER_AUDIT_REF', !status);
        }
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = data;
          this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getScrollWidth();
    });
    window.addEventListener('resize', throttle(() => {
      this.getScrollWidth();
    }, 100), true);
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('order/index/fetchApplyAudit', queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics || 0;
      this.tableData = list.map(it => {
        this.$set(it, 'ckStatus', false);
        return it;
      });
    },
    getScrollWidth() {
      const {
        tableRef
      } = this.$refs;
      if (!tableRef) return;
      const parent = tableRef.$el.querySelector('.el-table__body-wrapper');
      const child = parent.children[0];
      const clentWidth = parent.offsetWidth;
      const realWidth = parseInt(getStyle(child, 'width'));
      this.tableBody = parent;
      this.tableWidth = clentWidth;
      this.realWidth = realWidth;
      this.barWidth = realWidth - clentWidth;
    },
    handleBarScroll(position) {
      const {
        tableRef
      } = this.$refs;
      if (!tableRef) return;
      // const parent = tableRef.$el.querySelector('.el-table__body-wrapper')
      const tableHead = tableRef.$el.querySelector('.el-table__header-wrapper');
      tableHead.scrollLeft = position;
      this.tableBody.scrollLeft = position;
    },
    onSelect(type, val) {
      this.searchParams[type + 'Label'] = val.label;
      this.searchParams[type + 'Id'] = val.value;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    selectBase(type, val) {
      this.searchParams[type + 'Label'] = val.label;
      this.searchParams[type] = val.value;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    onChangeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    //输单日期 选择开始-结束日期
    onChangeCreateDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startCreateDate = startDate;
      this.searchParams.endCreateDate = endDate;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 部门
    changeDepartment(val) {
      departIdKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    progressShow(row) {
      const {
        id
      } = row;
      this.estimateFromat(id);
      if (!this.estimateFromat(id)) return;
      this.$refs.progressRef.show(row);
    },
    // 查看
    handleShow({
      row
    }) {
      const {
        orderId: id,
        reviewerId,
        product
      } = row;
      if (!this.estimateFromat(row.id)) return;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: this.link.detail,
        query: {
          id,
          type: 'detail',
          isReview: 1,
          reviewerId,
          productType: product.productType
        }
      });
    },
    // 审核
    affirmAudit({
      row
    }) {
      const {
        orderId: id,
        reviewerId,
        product
      } = row;
      if (!this.estimateFromat(row.id)) return;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: this.link.detail,
        query: {
          id,
          type: 'audit',
          isReview: 1,
          reviewerId,
          productType: product.productType
        }
      });
    },
    estimateFromat(id) {
      if (!id) {
        this.$bus.tip({
          type: 'warning',
          message: '此订单无审核流程!'
        });
        return false;
      }
      return true;
    },
    // 确认审核
    handleAudit() {
      const list = this.tableData.filter(it => it.ckStatus && it.reviewerStatus == reviewerStatusType.wait);
      if (!list.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选中数据!'
        });
        return false;
      }
      if (list.length > 10) {
        this.$bus.tip({
          type: 'warning',
          message: '最多只能审核10条!'
        });
        return false;
      }
      const ids = list.map(it => {
        return it.id;
      });
      this.$store.dispatch('order/index/fetchApplyAuditList', {
        status: 1,
        ids
      }).then(() => {
        this.$bus.tip();
        this.fetchData(this.queryParam);
      });
    },
    exportExcel() {
      this.$bus.tip({
        type: 'warning',
        message: '功能开发中!'
      });

      // this.excelLoading = true
      // this.$store
      //   .dispatch('order/index/exportOrderAll', this.searchParams)
      //   .then(data => {
      //     downloadExport(data, this.filename)
      //     this.$bus.tip({ type: 'success', message: '导出成功' })
      //     this.excelLoading = false
      //   })
      //   .catch(() => {
      //     this.excelLoading = false
      //   })
    }
  }
};