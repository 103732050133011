var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FooterBar", {
    attrs: {
      left: 138
    }
  }, [_c("div", {
    staticClass: "menu-list-item"
  }, [_c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印本页 ")]), _vm._t("default"), _c("BtnBase", {
    attrs: {
      type: "back"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")])], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };