import { render, staticRenderFns } from "./DialogCaptainDetail.vue?vue&type=template&id=53308486&scoped=true"
import script from "./DialogCaptainDetail.vue?vue&type=script&lang=js"
export * from "./DialogCaptainDetail.vue?vue&type=script&lang=js"
import style0 from "./DialogCaptainDetail.vue?vue&type=style&index=0&id=53308486&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53308486",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53308486')) {
      api.createRecord('53308486', component.options)
    } else {
      api.reload('53308486', component.options)
    }
    module.hot.accept("./DialogCaptainDetail.vue?vue&type=template&id=53308486&scoped=true", function () {
      api.rerender('53308486', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/resource/guideCaptain/DialogCaptainDetail.vue"
export default component.exports