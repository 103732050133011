import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
/* eslint-disable no-unused-vars */
import { fetchPickup, fetchPickupId, exportPickup, fetchPlan, fetchPlan2, fetchPlanLog, fetchPlanById, updatePlan, sendGroupConfirm, sendGroupCancel, groupProfit, findCustomerList, fetchHotel, fetchHotelDetail, saveHotelOrder, getOrderByPlanId, fetchHotelLog, getTravelSuperviseeDic, fetchPlanLogs, fetchPlanLogs3, getGuideDuty,
// getTravelPlatform,
fetchPlanIdOrderRemarks, updateCustomerSubsid, toDispatchGroupSign, updateShopDetail, fetchDayShopTourist, fetchGuideStatistics, copyPlanRequest, mailListRequest, mailAttachementRequest } from '@/api/plan';
import { exportPlanMeberListRequest } from '@/api/plan/export';
import { fetchTripMainCount, guideManagerUnArrangeRequest } from '@/api/index';
import { DEFAULT_PAGE, LOG_TYPE } from '@/config';
import { TYPES } from '@/config/audit';
import { formatOpts, formatCharData, formatTableData, formatCommitTableData } from '@/utils/data';
const dfaultState = () => ({
  curPage: 1,
  planData: null,
  curRolePage: 1,
  planRoleData: null,
  planOrderInfo: null,
  teamProfit: [],
  planInfo: null,
  currentPlanId: null,
  currentTabIndex: 0,
  currentRole: null,
  currentTabType: TYPES.guide,
  currentHotelOrder: [],
  pickupArrangeData: null,
  hotelArrangeData: null,
  tripNumberList: null,
  planTabStatus: {},
  // tab icon 图标显示状态
  planGuide: null,
  // 当前行数据
  planOrderData: [],
  // 订单数据
  firstEnter: false,
  // 是否第一次进入
  travelPlatData: null,
  fetchStatus: false,
  guideDutyData: null,
  currentSignList: [] // 签单列表
});
const state = dfaultState();
const getters = {
  curPage: state => state.curPage,
  planData: state => state.planData,
  curRolePage: state => state.curRolePage,
  planRoleData: state => state.planRoleData,
  planOrderInfo: state => state.planOrderInfo,
  teamProfit: state => state.teamProfit,
  planInfo: state => state.planInfo,
  currentPlanId: state => state.currentPlanId,
  currentRole: state => state.currentRole,
  currentTabIndex: state => state.currentTabIndex,
  currentTabType: state => state.currentTabType,
  // 审核类型
  currentHotelOrder: state => state.currentHotelOrder,
  // 审核类型
  pickupArrangeData: state => state.pickupArrangeData,
  // 接送预定安排
  hotelArrangeData: state => state.hotelArrangeData,
  // 酒店预定安排
  tripNumberList: state => state.tripNumberList,
  // 团号列表
  planTabStatus: state => state.planTabStatus,
  planGuide: state => state.planGuide,
  planOrderData: state => state.planOrderData,
  firstEnter: state => state.firstEnter,
  travelPlatData: state => state.travelPlatData,
  fetchStatus: state => state.fetchStatus,
  guideDutyData: state => state.guideDutyData,
  currentSignList: state => state.currentSignList
};
const actions = {
  // 邮件列表
  mailList({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      mailListRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res.data);
        }
      });
    });
  },
  // 邮件附件
  mailAttachement({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      mailAttachementRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res);
        }
      });
    });
  },
  // 接送预定安排
  fetchPickup({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchPickup(params).then(res => {
      let {
        data
      } = res;
      data.list = data.list.map((it, idx) => {
        it.serialumber = idx + 1;
        it.isChecked = false;
        return it;
      });
      commit('PICKUP_ARRANGE_DATA', data);
      return data;
    });
  },
  fetchPickupId({
    commit
  }, opts) {
    return fetchPickupId(opts).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 接送预定安排
  exportPickup({
    commit
  }, params) {
    return exportPickup(params).then(res => {
      const filename = '接送预定安排列表.xlsx';
      const blob = new Blob([res.data]);
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
      return res;
    }).catch(function (error) {});
  },
  // 获取房调安排列表
  fetchHotel({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchHotel(params).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = data.list.map(it => {
        const {
          quotePrice,
          count,
          freeCount,
          otherFee
        } = it;
        it.rowId = it.roomId;
        it.mergeId = it.planNumber;
        it.hotelArea = it.hotelArea.indexOf('|') > 0 ? it.hotelArea.split('|')[0] : it.hotelArea;
        it.hotelStandard = it.hotelStandard.indexOf('|') > 0 ? it.hotelStandard.split('|')[0] : it.hotelStandard;
        it.arrangeStateLabel = it.arrangeState ? '已安排' : '未安排';
        it.totalMoney = Number(quotePrice) * 100 * (Number(count) - Number(freeCount)) / 100 + Number(otherFee);
        it.cashType = it.cashType.toLowerCase();
        it.action = it.planNumber;
        let membersList = [];
        if (it.members.length) {
          membersList = it.members.filter(item => item.isContact);
        }
        it.members = membersList;
        return it;
      });
      commit('HOTEL_ARRANGE_DATA', data);
      return data;
    });
  },
  // 获取房调安排列表
  fetchHotelDetail({
    commit
  }, params) {
    return fetchHotelDetail(params).then(res => {
      let {
        data
      } = res;
      data = data.map(it => {
        const {
          quotePrice,
          count,
          freeCount,
          otherFee
        } = it;
        it.rowId = it.roomId;
        it.mergeId = it.planNumber;
        it.hotelArea = it.hotelArea.indexOf('|') > 0 ? it.hotelArea.split('|')[0] : it.hotelArea;
        it.hotelStandard = it.hotelStandard.indexOf('|') > 0 ? it.hotelStandard.split('|')[0] : it.hotelStandard;
        it.arrangeStateLabel = it.arrangeState ? '已安排' : '未安排';
        it.totalMoney = Number(quotePrice) * 100 * (Number(count) - Number(freeCount)) / 100 + Number(otherFee);
        it.cashType = it.cashType.toLowerCase();
        it.action = it.planNumber;
        let membersList = [];
        if (it.members.length) {
          membersList = it.members.filter(item => item.isContact);
        }
        it.members = membersList;
        it = formatOpts(it);
        return it;
      });
      return data;
    });
  },
  // 获取房调安排列表
  saveHotelOrder({
    commit
  }, dataList) {
    dataList = formatCommitTableData(dataList);
    return saveHotelOrder(dataList);
  },
  // 获取计划列表
  fetchPlan({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchPlan(params).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        it.hotelTips.map(item => {
          item.areaStandard = item.area + item.standard;
          item.money = Number(item.count) * Number(item.price);
          return item;
        });
        it.isChecked = true;
        return it;
      });
      commit('PLAN_LIST', {
        page: params.pageNum,
        data
      });
      return data;
    });
  },
  // 获取计划列表
  fetchPlan2({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchPlan2(params).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        it.hotelTips.map(item => {
          item.areaStandard = item.area + item.standard;
          item.money = Number(item.count) * Number(item.price);
          return item;
        });
        return it;
      });
      commit('PLAN_ROLE_LIST', {
        page: params.pageNum,
        data
      });
      return data;
    });
  },
  // 获取接送日志
  fetchPickupLog({
    commit
  }, {
    itemId
  }) {
    let subType = 'shuttle';
    return fetchPlanLog({
      itemId,
      subType,
      type: LOG_TYPE.order
    });
  },
  // 获取计划日志
  fetchPlanLog({
    commit
  }, opts) {
    return fetchPlanLog(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取计划日志详细信息
  fetchPlanLogs({
    commit
  }, opts) {
    return fetchPlanLogs(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取计划日志详细信息
  fetchPlanLogs3({
    commit
  }, opts) {
    return fetchPlanLogs3(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取计划详情
  fetchPlanById({
    commit
  }, planId) {
    return fetchPlanById(planId).then(res => {
      const {
        data
      } = res;
      commit('PLAN_INFO', data);
      return data;
    });
  },
  // 更新计划
  updatePlan({
    commit
  }, params) {
    return updatePlan(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 确认发团
  sendGroupConfirm({
    commit
  }, id) {
    return sendGroupConfirm(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 取消发团
  sendGroupCancel({
    commit
  }, id) {
    return sendGroupCancel(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 单团利润表
  groupProfit({
    commit
  }, id) {
    return groupProfit(id).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      commit('TEAM_PROFIT', data);
      return data;
    });
  },
  // 获取发团的小团号列表
  findCustomerList({
    commit
  }, id) {
    return findCustomerList(id).then(res => {
      const {
        data
      } = res;
      commit('TRIP_NUMBER_LIST', data);
      return data;
    });
  },
  // 根据计划id获取订单信息
  getOrderByPlanId({
    commit
  }, id) {
    return getOrderByPlanId(id).then(res => {
      const {
        data
      } = res;
      commit('ORDER_LIST_DATA', data);
      return data;
    });
  },
  fetchHotelLog({
    commit
  }, opt) {
    return fetchHotelLog(opt).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // getTravelPlatform({ commit }, opt) {
  //   return getTravelPlatform(opt).then(res => {
  //     const { data } = res
  //     return data
  //   })
  // },

  getTravelSuperviseeDic({
    commit
  }, opt) {
    return getTravelSuperviseeDic(opt).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchPlanIdOrderRemarks({
    commit
  }, opt) {
    return fetchPlanIdOrderRemarks(opt).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  getGuideDuty({
    commit
  }, opt) {
    return getGuideDuty(opt).then(res => {
      let {
        data
      } = res;
      commit('CUR_GUIDE_DUTR', data);
      return data;
    });
  },
  updateCustomerSubsid({
    commit
  }, opt) {
    return updateCustomerSubsid(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  toDispatchGroupSign({
    commit
  }, obj) {
    return toDispatchGroupSign(obj);
  },
  fetchTripMainCount({
    commit
  }, opt) {
    return fetchTripMainCount(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  guideManagerUnArrange({
    commit
  }, opt) {
    return guideManagerUnArrangeRequest(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  updateShopDetail({
    commit
  }, opt) {
    return updateShopDetail(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  fetchDayShopTourist({
    commit
  }, opt) {
    return fetchDayShopTourist(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  fetchGuideStatistics({
    commit
  }, opt) {
    return fetchGuideStatistics(opt).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  exportPlanMeberList({
    commit
  }, params) {
    return exportPlanMeberListRequest(params);
  },
  copyPlan({
    commit
  }, id) {
    return copyPlanRequest(id);
  }
};
const mutations = {
  ORDER_LIST_DATA: (state, data) => {
    const formatData = list => {
      return list.map(it => {
        const {
          id,
          orderNumber,
          customerTripNumber,
          adultCount,
          customerOrderId
        } = it;
        const name = customerTripNumber || orderNumber;
        const orderId = customerOrderId == -1 ? '' : customerOrderId;
        return {
          ...it,
          id,
          name,
          label: name,
          value: id,
          count: adultCount / 2,
          customerOrderId: orderId
        };
      });
    };
    let result = null;
    if (data) {
      result = formatCharData(formatData(data));
    }
    state.planOrderInfo = data;
    state.planOrderData = result;
  },
  PLAN_LIST: (state, {
    page,
    data
  }) => {
    state.curPage = page;
    state.planData = data;
  },
  PLAN_ROLE_LIST: (state, {
    page,
    data
  }) => {
    state.curRolePage = page;
    state.planRoleData = data;
  },
  TRAVEL_PLAT_DATA: (state, data) => {
    state.travelPlatData = data;
  },
  PLAN_INFO: (state, data) => {
    state.planInfo = data;
  },
  CUR_PLAN_ID: (state, id) => {
    state.currentPlanId = id;
  },
  CUR_TAB_INDEX: (state, id) => {
    state.currentTabIndex = id;
  },
  CUR_ROLE: (state, role) => {
    state.currentRole = role;
  },
  CUR_TYPE: (state, type) => {
    state.currentTabType = type;
  },
  TEAM_PROFIT: (state, data) => {
    state.teamProfit = [data];
  },
  CURRENT_HOTEL: (state, data) => {
    state.currentHotelOrder = data;
  },
  PICKUP_ARRANGE_DATA: (state, data) => {
    state.pickupArrangeData = data;
  },
  HOTEL_ARRANGE_DATA: (state, data) => {
    state.hotelArrangeData = data;
  },
  TRIP_NUMBER_LIST: (state, data) => {
    state.tripNumberList = data;
  },
  PLAN_TAB_STATUS: (state, data) => {
    state.planTabStatus = data;
  },
  PLAN_GUIDE: (state, data) => {
    state.planGuide = data;
  },
  FIRST_ENTER: (state, flag) => {
    state.firstEnter = flag;
  },
  FETCH_STATUS: (state, flag) => {
    state.fetchStatus = flag;
  },
  CUR_GUIDE_DUTR: (state, data) => {
    state.guideDutyData = data;
  },
  CUR_SIGN_LIST: (state, data) => {
    state.currentSignList = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};