import ThemePicker from '@/components/ThemePicker';
import { setStorage } from '@/utils/storage';
import { getUserData } from '@/utils/cookie';
import Keys from '@/constants/keys';
import { mapGetters } from 'vuex';
export default {
  components: {
    ThemePicker
  },
  data() {
    return {
      versionSwitch: false
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    isShowJob() {
      return this.$store.getters.language === 'zh';
    },
    fixedHeader: {
      get() {
        return this.$store.state.settings.fixedHeader;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get() {
        return this.$store.state.settings.tagsView;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    },
    supportPinyinSearch: {
      get() {
        return this.$store.state.settings.supportPinyinSearch;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'supportPinyinSearch',
          value: val
        });
      }
    },
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    userInfo: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('user/fetchUserInfo');
          return;
        }
        if (data.theme) {
          const {
            switchVersion
          } = data.theme;
          this.versionSwitch = switchVersion;
        }
      },
      immediate: true
    }
  },
  methods: {
    themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
      const {
        userName
      } = getUserData();

      // 本地持久化
      setStorage(Keys.theme, {
        userName,
        theme: val
      });
    },
    switchVersion(val) {
      const switchVersion = val;
      this.$store.dispatch('user/updateVersionSwitch', {
        switchVersion
      });
    }
  }
};