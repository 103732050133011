import Cookies from 'js-cookie';
import { getLanguage } from '@/lang/index';
import { fetchTrafficTime } from '@/api';
const dfaultState = () => ({
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: getLanguage(),
  size: Cookies.get('size') || 'mini',
  stationData: null,
  dialogLoading: false,
  // 弹窗的按钮 loading 状态
  domHtml: false,
  // 要打印的dom内容
  clipboard: {
    show: false,
    pos: {
      left: 0,
      top: 0
    }
  },
  // 复制按钮
  backToTop: false,
  reloadPage: false,
  foldSearchStatus: false
});
const state = dfaultState();
const getters = {
  size: state => state.size,
  sidebar: state => state.sidebar,
  domHtml: state => state.domHtml,
  language: state => state.language,
  stationData: state => state.stationData,
  reloadPage: state => state.reloadPage,
  dialogLoading: state => state.dialogLoading,
  foldSearchStatus: state => state.foldSearchStatus,
  getFontSize: state => {
    if (state.size == 'mini' || state.size == 'small') return 12;
    return 14;
  }
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  fetchTrafficTime({
    commit
  }, queryParam) {
    return fetchTrafficTime(queryParam).then(res => {
      const {
        type
      } = queryParam;
      const {
        data
      } = res;
      data.map(it => {
        // 火车当前选中
        if (type == 1) {
          // TODO: 默认先取第一个
          it.curRadio = it.no;
        }
        if (type == 2) {
          it.curRadio = it.dpName;
        }
      });
      commit('STATION_DATA', data);
      return data;
    });
  },
  toggleSideBar({
    commit
  }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({
    commit
  }, {
    withoutAnimation
  }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({
    commit
  }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  setDialogLoading({
    commit
  }, loading) {
    commit('SET_DIALOG_LOADING', loading);
  },
  toggleDialogLoading({
    commit
  }) {
    commit('TOGGLE_DIALOG_LOADING');
  },
  setLanguage({
    commit
  }, language) {
    commit('SET_LANGUAGE', language);
  },
  setSize({
    commit
  }, size) {
    commit('SET_SIZE', size);
  },
  printDomHtml({
    commit
  }, dom) {
    commit('PRINT_DOM_HTML', dom);
  },
  setClipboard({
    commit
  }, {
    flag,
    data
  }) {
    commit('SET_CLIPBOARD', {
      flag,
      data
    });
  }
};
const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_DIALOG_LOADING: (state, loading) => {
    state.dialogLoading = loading;
  },
  TOGGLE_DIALOG_LOADING: state => {
    state.dialogLoading = !state.dialogLoading;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_SIZE: (state, size) => {
    state.size = size;
    Cookies.set('size', size);
  },
  STATION_DATA: (state, data) => {
    state.stationData = data;
  },
  PRINT_DOM_HTML: (state, data) => {
    state.domHtml = data;
  },
  SET_CLIPBOARD: (state, {
    flag,
    data
  }) => {
    state.clipboard.show = flag;
    state.clipboard.pos = data;
  },
  SET_BACK_TO_TOP: (state, flag) => {
    state.backToTop = flag;
  },
  RELOAD_PAGE: (state, flag) => {
    state.reloadPage = flag;
  },
  RELOAD_TABLE_HEIGHT: state => {
    state.foldSearchStatus = true;
    setTimeout(() => {
      state.foldSearchStatus = false;
    }, 100);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};