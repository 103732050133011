import request from '@/utils/request';

// 获取系统配置类型
export function fetchType() {
  return request({
    url: '/system/config/listConfigTypes',
    method: 'get'
  });
}

// 获取系统配置类型
export function fetchItem(params) {
  return request({
    url: '/system/config/configByTypeId',
    method: 'get',
    params
  });
}

// 系统配置类型
export function createType(data) {
  return request({
    url: '/system/config/saveOrUpdateType',
    method: 'post',
    data
  });
}

// 系统配置 内容
export function createItem(data) {
  return request({
    url: '/system/config/saveOrUpdateConf',
    method: 'post',
    data
  });
}

// 系统配置值 新增
export function createItemContent(data) {
  return request({
    url: '/system/config/saveValue',
    method: 'post',
    data
  });
}

// 系统配置值 更新
export function update(data) {
  return request({
    url: '/system/config/setValue',
    method: 'post',
    data
  });
}

// 代收审核配置 列表
export function fetchAudit(data) {
  return request({
    url: '/system/trip/getCollectAuthCnf',
    method: 'post',
    data
  });
}

// 代收审核配置 设置
export function updateAudit(params) {
  return request({
    url: '/system/trip/setCollectAuthCnf',
    method: 'post',
    params
  });
}

// 印章上传
export function uploadSeal(params) {
  return request({
    url: '/system/trip/add-update-seal',
    method: 'post',
    data: [params]
  });
}

// 印章删除
export function deleteSeal(data) {
  return request({
    url: '/system/trip/del-seal-ids',
    method: 'post',
    data
  });
}

// 印章获取
export function fetchSeal(params) {
  return request({
    url: '/system/trip/list-seals',
    method: 'get',
    params
  });
}

// 获取导游帮助信息
export function fetchGuideNotice(id) {
  return request({
    url: '/system/trip/get-guide-info',
    method: 'get',
    params: {
      id
    }
  });
}

// 设置导游帮助信息
export function setGuideNotice(data) {
  return request({
    url: '/system/trip/add-update-guide-info',
    method: 'post',
    data
  });
}

// 获取旅行社/公司 的钉钉OA审批模板信息
export function getDDApplyConfig(params) {
  return request({
    url: '/system/trip/get-trip-dingtalk-process-code',
    method: 'get',
    params
  });
}

// 增加 修改 旅行社/公司 的钉钉OA审批模板信息
export function setDDApplyConfig(data) {
  return request({
    url: '/system/trip/set-trip-dingtalk-process-code',
    method: 'post',
    data
  });
}

// 获取车队基础配置
export function getSystemConfigByCode(params) {
  return request({
    url: '/system/config/find-base-config-value',
    method: 'get',
    params
  });
}

// 根据旅行社/公司编码 获取旅行社/公司的字典列表
export function getDictListByCompanyCode(params) {
  return request({
    url: '/system/dictionary/valuesByTripCode',
    method: 'get',
    params
  });
}