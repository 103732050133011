import { formatCharData } from '@/utils/data';
export default {
  name: 'DialogBindBusiness',
  data() {
    return {
      visible: false,
      list: [],
      name: '',
      loading: false
    };
  },
  methods: {
    show(row) {
      this.visible = true;
      this.fetchTrip();
      this.form = row;
    },
    // 旅行社类型，1-地接 2-组团 3-门店/门市 4-黑名单系统公司, 5-资源预定中心, 6-资源商家
    fetchTrip(opts) {
      this.loading = true;
      this.$store.dispatch('system/index/fetchTrip', {
        ...opts,
        type: 6
      }).then(res => {
        this.list = formatCharData(res.list);
        this.loading = false;
      });
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    // 名字搜索
    remoteMethod(name) {
      this.fetchTrip({
        name
      });
    },
    onSelectBusiness(val) {
      this.code = val.code;
      this.name = val.name;
    },
    handleUpdate() {
      const {
        id: resId,
        travelAgencyId,
        resType
      } = this.form;
      const opts = {
        resId,
        resType,
        travelAgencyId,
        code: this.code
      };
      this.$store.dispatch('resource/common/bindBusinessCode', [opts]).then(() => {
        this.hide();
        this.$parent.fetchData();
      });
    }
  }
};