import request from '@/utils/request';
export * from './channel';
export * from './refund';
export * from './aliecode';

// 对账
export const billCheckRequest = data => {
  return request({
    url: '/order/ca/checkAccount',
    method: 'post',
    data
  });
};

// 日志
export function logRequest(params) {
  return request({
    url: '/log/orderList2',
    method: 'get',
    params
  });
}