import { DialogLog } from '@/components/common';
import { TableList, Search } from './components';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { getCurTagView } from '@/utils/tagView';
export default {
  components: {
    Search,
    TableList,
    DialogLog
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      filename: '餐厅对账'
    };
  },
  computed: {
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billCheck/fetchRestaurant', {
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    closeTag() {
      this.closeSelectedTag();
    },
    handleBillCheck() {
      this.$refs.tableRef.handleBillCheck();
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.getTagViewSearch,
        pageNum,
        pageSize
      });
    },
    handleSearch(params) {
      const pageNum = 1;
      const {
        path
      } = this.$route;
      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: pageNum
      });
      this.fetchData({
        ...params,
        pageNum
      });
    },
    showLog(row) {
      const {
        rowId: subItemId,
        planId: itemId
      } = row;
      this.$store.dispatch('bill/billCheck/fetchPlanLog', {
        itemId,
        subItemId,
        subType: 'restaurant'
      }).then(data => {
        this.$refs.dialogRef.show(data);
      });
    }
  }
};