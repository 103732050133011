import "core-js/modules/es.array.push.js";
import TreeFramework from './TreeFramework';
import { mapGetters } from 'vuex';
export default {
  components: {
    TreeFramework
  },
  data() {
    return {
      departments: []
    };
  },
  computed: {
    ...mapGetters({
      departmentData: 'system/department/departmentData'
    })
  },
  watch: {
    departmentData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.departments = this.formatList(data);
    },
    formatList(list) {
      const res = [];
      const fn = list => {
        list.forEach((it, index1) => {
          const pItem = {
            code: it.code,
            value: it.id,
            label: it.name,
            children: []
          };
          res.push(pItem);
          if (it.departments && it.departments.length) {
            it.departments.forEach((item, index2) => {
              const cItem = {
                code: it.code,
                value: item.id,
                label: item.name,
                pid: it.id,
                children: []
              };
              res[index1].children.push(cItem);
              if (item.departments && item.departments.length) {
                item.departments.forEach(child => {
                  const gItem = {
                    code: it.code,
                    value: child.id,
                    label: child.name,
                    pid: item.id,
                    gid: it.id
                  };
                  res[index1].children[index2].children.push(gItem);
                });
              }
            });
          }
        });
      };
      fn(list);
      return res;
    }
  }
};