import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { TopBar, DelCheck, DelCheckAll, Audit, AuditCheck, AuditAdmin, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectScenic, SelectPayType, SelectPlanNumber } from '../../index';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import DialogFix from './DialogFix';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, permissionMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import { processNumber } from '@/utils/number';
import { defaultState, itemListKeys, extraState } from './config';
import { PAY_TYPE } from '@/config';
export default {
  components: {
    TopBar,
    DelCheck,
    DelCheckAll,
    Audit,
    AuditCheck,
    StatusAudit,
    SelectScenic,
    SelectPayType,
    AuditAdmin,
    DialogOrder,
    DialogList,
    DialogFix,
    PaymentTotal,
    AuditRoleAll,
    SelectPlanNumber,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, permissionMixin, planMixin, reloadMixin, systemMixin],
  props: {
    isExtra: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'scenicOrderStatus',
      deletePath: 'plan/scenic/delete',
      updatePath: 'plan/scenic/update',
      fetchPath: 'plan/scenic/fetch',
      headMergeNum: 3,
      tailMergeNum: 14,
      hasMergeTail: true,
      rowId: 1000000000000,
      scenicList: [],
      // 当前计划中的景区列表
      lastScenicId: '',
      lastScenicName: '',
      auditAll: false,
      totalSummary: {},
      statisticsKeys: ['price', 'otherFee', 'rowTotal', 'totalAmountReceive'],
      priceSystemCofig: false,
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      scenicData: 'plan/scenic/scenicData'
    }),
    hasMoreTicket() {
      return item => {
        return item && item.length > 1;
      };
    },
    getMoreTicket() {
      return item => {
        return item && item.length > 1 && item.slice(1);
      };
    }
    // isECodePay() {
    //   return val => {
    //     return val && val.toUpperCase() === PAY_TYPE.ALIPAY_E_CODE
    //   }
    // },
  },
  created() {
    this.fetchData({
      isAddScenic: this.isExtra
    });
    // 景区底价是否默认等于单价配置
    this.priceSystemCofig = this.getConfigStatus('audit:audit15:audit15-0');
  },
  methods: {
    enterpriseFixDialog(row, column) {
      if (column.property == 'rowTotal' && row.alipayPeerPayAmount != 0 && row.alipayPeerPayAmount != row.totalPay) {
        this.$refs.dialogFixRef.show({
          ...row
        });
      }
    },
    initData(data) {
      this.tableData = this.formatData(data.list);

      // 是否显示订单信息
      const isOrderInfo = this.hasOrderInfo;
      const hasFloorPrice = this.hasSettlementPrice('scenicFloorPriceAudit');
      this.$nextTick(() => {
        // 如果不显示订单信息
        if (!isOrderInfo) {
          this.headMergeNum = isOrderInfo ? 3 : 2;
        }
        this.tailMergeNum = this.isAudit ? this.columnData.length - 7 : this.columnData.length - 5;
        // 是否有底价
        if (this.isExtra) {
          this.tailMergeNum = hasFloorPrice ? this.columnData.length - 16 : this.columnData.length - 15;
        }
        this.visible = true;
        this.resetData();
      });
    },
    fetchPlanSceneList() {
      this.$store.dispatch('plan/scenic/fetchScenicPlanList', {
        planId: this.currentPlanId
      });
    },
    beforeChangeResource(row, val) {
      if (val) {
        this.lastScenicId = row.scenicId;
        this.lastScenicName = row.scenicName;
      }
    },
    // 选择景点
    handleSelect(index, val) {
      // 如果未选中任何数据，直接返回
      if (val.label == '') return;
      const {
        id: scenicId,
        label
      } = val;
      this.$store.dispatch('plan/scenic/fetchAgreementPrice', {
        planId: this.currentPlanId,
        scenicId
      }).then(data => {
        const {
          orderScenicTicketList
        } = data;
        if (!orderScenicTicketList) {
          this.$bus.tip({
            type: 'warning',
            message: '没有添加景区协议或者协议已过期！'
          });
          // 恢复之前的景区
          const rowItem = this.tableData[index];
          rowItem.scenicId = this.lastScenicId;
          rowItem.scenicName = this.lastScenicName;
        } else {
          this.tableData[index].scenicId = scenicId;
          this.tableData[index].scenicName = label;
          const ticketList = orderScenicTicketList.sort((a, b) => a.typeOrderNumber - b.typeOrderNumber);
          const firstItem = ticketList[0];
          const curItem = this.tableData[index];
          curItem.ticketList = this.formatOptions(ticketList);
          // 选中第一个
          firstItem && this.mergeRowDataAndPrice(curItem, [firstItem]);
          // 计算金额
          this.changeInput(curItem, 'price', curItem.price);

          // 计算佣金
          if (this.isExtra) {
            this.calcExtraMoney(curItem, curItem.adultCountReceive);
          }
        }
      });
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        ticketList,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/scenic/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false
        };
        this.mergeRowDataAndPrice(rowItem, ticketList);
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 选择门票类型
    handleicketType(row, val) {
      const {
        label
      } = val;
      row.ticketType = label;
      row.price = val.price;
      // 景区底价是否默认等于单价配置(配置打开则结算价等于单价)
      if (this.priceSystemCofig) {
        row.settlementPrice = val.price;
      } else {
        row.settlementPrice = val.settlementPrice || 0;
      }
    },
    // 新增一条景区
    handlePlus() {
      let {
        adultCount,
        childCount,
        accompanyCount
      } = this.planInfo || {};
      adultCount = adultCount || 0;
      childCount = childCount || 0;
      accompanyCount = accompanyCount || 0;
      let item = {
        ...defaultState(),
        adultCount,
        childCount,
        accompanyCount,
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: adultCount
      };
      if (this.isExtra) {
        item = {
          ...item,
          ...extraState(),
          adultCountReceive: adultCount
        };
      }
      this.tableData.push(item);
      this.visible = true;
      this.rowspan();
      this.checkSelectAll();
    },
    mergeRowDataAndPrice(row, ticketList) {
      ;
      ['price', 'ticketType', 'settlementPrice'].forEach(key => {
        row[key] = ticketList[0][key];
      });
      // 景区底价是否默认等于单价配置(配置打开则结算价等于单价)
      if (this.priceSystemCofig) {
        row.settlementPrice = row.price;
      }
      // 加点
      if (this.isExtra) {
        ;
        ['guideRate', 'driverRate', 'accompanyRate'].forEach(key => {
          row[key] = ticketList[0][key];
        });
      }
      this.getTotalPay(row);
    },
    // 校验输入的值
    changeInput(row) {
      // 重新计算当前行合并后的价格
      this.$nextTick(() => {
        this.getTotalPayMoney();
        this.mergeRowMoney();
        this.changeTotalCost(row); // 加点总利润
      });
    },
    // 单价修改
    changePrice(row, val) {
      const {
        payType
      } = row;
      if (payType.toUpperCase() == PAY_TYPE.GUIDE) {
        row.settlementPrice = val;
      }
      if (this.priceSystemCofig) {
        row.settlementPrice = val;
      }
      this.changeInput(row);
      this.calcRate(row);
    },
    // 儿童修改
    changeChild(row) {
      this.changeInput(row);
      this.calcRate(row);
    },
    // 实收修改
    changeAmountReceive(row) {
      this.getAmountReceive(row); // 实收金额
      this.changeTotalCost(row); // 加点总利润
      this.getTripProfit(row); // 社利
      this.calcRate(row);
    },
    // 实收计算
    getAmountReceive(row) {
      const {
        rowId,
        id
      } = row;
      // 找出同一个景点数据
      const findBroder = this.tableData.filter(it => it.rowId === rowId);
      this.tableData.forEach(it => {
        if (it.id === id) {
          findBroder[0].totalAmountReceive = findBroder.reduce((acc, cur) => {
            const amountReceive = this.calcAmountReceive(cur);
            return acc + amountReceive;
          }, 0);
        }
      });
    },
    // 计算利润
    changeTotalCost(row) {
      this.getTotalCost(row); // 加点总利润
      this.getTripProfit(row); // 社利
    },
    getTotalPay(row) {
      row.totalPay = this.calcRowTotalMoney(row);
    },
    // 加点总利润
    getTotalCost(row) {
      row.totalProfit = this.caleTotalCost(row);
    },
    getSettlement(row) {
      row.totalSettlement = this.calcSettlement(row); // 总成本(旅行社成本)
    },
    getTripProfit(row) {
      row.tripProfit = this.calcTripCost(row); // 社利
    },
    // 计算总成本
    calcCost(row, type = 'price') {
      const {
        adultCount,
        adultFreeCount,
        childPrice,
        childCount,
        accompanyPrice,
        accompanyCount,
        otherFee
      } = row;
      const price = row[type];
      let total = price * (adultCount - adultFreeCount) + childPrice * childCount + accompanyPrice * accompanyCount + Number(otherFee);
      return processNumber(total);
    },
    // 计算单行总成本(计调)
    calcRowTotalMoney(row) {
      return this.calcCost(row);
    },
    // 计算单行总成本(旅行社)
    calcSettlement(row) {
      // 总成本 = 单价 * 人数
      return this.calcCost(row, 'settlementPrice');
    },
    // 加点实收金额
    calcAmountReceive(row) {
      // 成本 = 单价 * 成人实收
      const {
        sellPrice,
        adultCountReceive,
        childCountReceive,
        childPrice
      } = row;
      const res = sellPrice * adultCountReceive + (childCountReceive || 0) * childPrice;
      return processNumber(res);
    },
    // 加点计算
    calcExtraMoney(row) {
      /**
       * 实收金额：成人实收 * 成人卖价
       * (卖价 - 单价) * 人数
       * 导佣/司佣/赔佣： 实收金额 * 导佣比例/司佣比例/赔佣比例
       * 金额：单价 * 人数
       * 总利润：实收金额 - 总成本； // 社利 + 导佣 + 司佣 + 赔佣
       * 社利：总利润 - 导佣 - 司佣 - 赔佣
       */
      this.calcRate(row);
      this.changeAmountReceive(row); // 实收金额
      this.getTotalPay(row); // 总成本
      this.getTotalCost(row); // 加点总利润
      this.getSettlement(row); // 总成本(旅行社成本)
      this.getTripProfit(row); // 社利
    },
    // 计算佣金
    calcRate(row) {
      const {
        guideRate,
        driverRate,
        accompanyRate
      } = row;
      this.changeRate(row, 'guideCommission', guideRate);
      this.changeRate(row, 'driverCommission', driverRate);
      this.changeRate(row, 'accompanyCommission', accompanyRate);
    },
    // 计算加点总利润
    caleTotalCost(row) {
      // 总利润: 实收金额 - 总成本 // 社利 + 导佣 + 司佣 + 赔佣
      const {
        totalAmountReceive,
        totalPay
      } = row;
      const res = totalAmountReceive - totalPay;
      return processNumber(res);
    },
    // 计算社利
    calcTripCost(row) {
      // 社利：总利润 - 导佣 - 司佣 - 赔佣
      const {
        totalProfit,
        guideCommission,
        driverCommission,
        accompanyCommission
      } = row;
      const res = totalProfit - guideCommission - driverCommission - accompanyCommission;
      return processNumber(res);
    },
    // 佣金比例计算
    changeRate(row, type, val) {
      // 佣金 = 总利润 * 比例
      row[type] = row.totalProfit * val / 100;
      this.getTripProfit(row); // 社利
    },
    // 佣金
    changeCommission(row) {
      this.getTripProfit(row); // 社利
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderScenicTickets;
        // 下拉列表数据
        const ticketListReq = it.reqOrderScenicTickets || [];
        const {
          id,
          auditProgress,
          payType
        } = it;
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          scenicPlanId: id,
          itemIndex: 0,
          isChecked: false,
          isCustom: false,
          isAddScenic: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          payType: payType.toLowerCase(),
          ticketList: this.formatOptions(ticketListReq)
        };
        // 是否是加点
        if (this.isExtra) {
          item = {
            ...extraState(),
            ...item
          };
          item.isAddScenic = true;
        }
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcCost(v);
            const newItem = {
              ...item,
              ...v,
              itemId: v.id,
              // 子订单id
              itemIndex: i,
              totalSum
            };
            result.push(newItem);
          });
        } else {
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'reqOrderScenicTickets', 'respOrderScenicTickets'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom && it.itemId ? item.id = it.id : null;
        it.totalPay = it.totalPay || 0;

        // 定义子项目的列表
        it.orderScenicTicketList = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 是否是加点
        if (this.isExtra) {
          it.isAddScenic = true;
        }
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.scenicPlanId;
        it.payType = it.payType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.scenicPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        delete item.ticketList;

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.orderScenicTicketList.push(item);
        } else {
          it.orderScenicTicketList.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    // 校验表单数据
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          scenicId,
          adultCount,
          ticketType
        } = row;
        if (!scenicId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择景区！'
          });
          return false;
        }
        if (!ticketType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择门票类型！'
          });
          return false;
        }
        if (adultCount === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写成人数量！'
          });
          return false;
        }
      }
      return true;
    },
    // 格式化下拉列表
    formatOptions(list) {
      if (!list.length) return;
      return list.sort((a, b) => a.typeOrderNumber - b.typeOrderNumber).map((it, index) => {
        it.label = it.ticketType;
        it.name = it.ticketType;
        it.value = index + 1;
        it.id = index + 1;
        return it;
      });
    },
    // 格式化下拉列表
    formatScenicOptions(list) {
      if (!list.length) return;
      return list.sort((a, b) => a.typeOrderNumber - b.typeOrderNumber).map(it => {
        it.label = it.name;
        it.value = it.id;
        return it;
      });
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        this.$set(it, 'totalPay', totalPay);
      });
    },
    resetData() {
      // this.getTotalPayMoney()
      this.rowspan();
      this.mergeRowMoney(false); // 合计单条数据金额
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.scenic,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};