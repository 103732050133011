/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
// 按团号付交通
import { BILL_PAGE_COUNT } from '@/config';
import { TYPES } from '@/config/billDown';
import { fetchBillList, fetchUnPayTraffic, fetchPayedList } from '@/api/bill/billDown';
import { formatList, calcOwePay } from '@/store/modules/bill/billDown';
import { formatTableData, formatBalance, setCheckStatus } from '@/utils/data';
const dfaultState = () => ({
  data: null,
  unPayData: null,
  payedData: null
  // curItem: {},
});
const state = dfaultState();
const getters = {
  data: state => state.data,
  unPayData: state => state.unPayData,
  payedData: state => state.payedData
  // curItem: state => state.curItem,
};
const actions = {
  // 列表
  fetch({
    commit
  }, queryParam) {
    const cashType = TYPES.transport_company;
    queryParam = {
      ...queryParam,
      cashType
    };
    return fetchBillList(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      data.list = formatBalance(data.list);
      commit('SET_DATA', data);
      return data;
    });
  },
  // 未结清
  fetchUnPay({
    commit
  }, queryParam) {
    queryParam = {
      ...BILL_PAGE_COUNT,
      ...queryParam
    };
    return fetchUnPayTraffic(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcOwePay, 'transportTypes');
      return data;
    });
  },
  // 单位已结清
  fetchPayed({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.transport_company
    };
    return fetchPayedList(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = setCheckStatus(data.list);
      commit('PAYED_DATA', data);
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
  // SET_CUR_ITEM: (state, item) => {
  //   state.curItem = item
  // },

  PAYED_DATA: (state, data) => {
    state.payedData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};