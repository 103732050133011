import billBaseMixin from '../../billBaseMixin';
import { StatusAudit, BtnLink, CustomerInfo } from '@/components/common';
import { PersonCount, Members } from '@/components/common';
import { billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { Columns } from '../tableConfig';
import { StatisticsKeys, StatisticsTransfor } from '../config';
import { reviewerStatusType } from '@/config/order';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    Members,
    PersonCount,
    StatusAudit,
    BtnLink,
    CustomerInfo
  },
  mixins: [billMixin, billBaseMixin],
  data() {
    return {
      Columns,
      calcCheckedMoney,
      billCheckAll: false,
      StatisticsKeys,
      type: TYPES.fee,
      reviewerStatusType
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/collect'];
    },
    tableData() {
      return this.data ? this.formatData(this.data.list) : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      const result = {};
      if (this.data && this.data.statistics) {
        const data = this.data.statistics;
        Object.keys(StatisticsTransfor).map(key => {
          result[StatisticsTransfor[key]] = data[key];
        });
      }
      return result;
    },
    getReview() {
      return item => {
        if (item.addAuditStatus == reviewerStatusType.waitNew || item.addAuditStatus == reviewerStatusType.wait) {
          return false;
        }
        return true;
      };
    },
    getDetail() {
      return item => {
        let {
          type,
          count,
          price,
          remarks,
          times
        } = item;
        type = type.split('|')[0];
        if (remarks) {
          type = `${type}(${remarks})`;
        }
        const total = count * price * times;
        return `${type}: ${count} × ${times} × ${price} = ${total}`;
      };
    }
  },
  methods: {
    // mixin调用
    rowspan() {},
    // 根据collectStatus 字段判断代收状态
    getRowStyle({
      row,
      columnIndex
    }) {
      if (row.collectStatus) {
        if (columnIndex == 11) {
          return 'coolect-status';
        }
      }
    },
    onClickFees(row) {
      this.$parent.orderAutitShow(row);
    },
    handleLog({
      row
    }) {
      this.$parent.showLog(row);
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          id,
          checkAccountMoney,
          totalIncome,
          checkAccountStatus
        } = it;
        it.checkAccountMoney = checkAccountStatus ? checkAccountMoney : totalIncome;
        it.rowId = id;
        this.$set(it, 'ckStatus', false);
        return it;
      });
    }
  }
};