import request from '@/utils/request';

// 车队订单列表
export function fetchFleet(params) {
  return request({
    url: '/order/c-order/bus-list',
    method: 'get',
    params
  });
}

// 景区订单列表
export function fetchScenic(params) {
  return request({
    url: '/order/c-order/scenic-list',
    method: 'get',
    params
  });
}

// 餐厅订单列表
export function fetchRestaurant(params) {
  return request({
    url: '/order/c-order/restaurant-list',
    method: 'get',
    params
  });
}

// 预控订单列表
export function fetchData(params) {
  return request({
    url: '/order/c-order/page',
    method: 'get',
    params
  });
}

// 预控订单进度
export function progressRequest(params) {
  return request({
    url: '/order/res-center-order/booking-progress',
    method: 'get',
    params
  });
}

// 预控订单转换
export function convertRequest(data) {
  return request({
    url: '/order/order-convert',
    method: 'post',
    data
  });
}

// 预控订单取消
export function cancelRequest(data) {
  return request({
    url: '/order/c-order/cancel',
    method: 'post',
    data
  });
}