import "core-js/modules/es.array.push.js";
import { tableMixin, getSummariesMixin } from '@/mixin';
import { decimalSplit } from '@/utils';
export default {
  components: {},
  mixins: [tableMixin, getSummariesMixin],
  data() {
    return {
      statisticsKeys: ['tagCount', 'count',
      // 'peopleCompletionRate',
      // 'peopleYearRate',
      'tagProfit', 'profit', 'difference'
      // 'profitYearRate',1
      ]
    };
  },
  computed: {
    decimalPCT() {
      return item => {
        return decimalSplit(item * 100) + '%';
      };
    }
  },
  methods: {
    onRedirec({
      row
    }) {
      const {
        area: customerArea
      } = row;
      const {
        endMonth: end,
        startMonth: start
      } = this.$parent.searchParams;
      this.$router.push({
        path: '/analysis/custom',
        query: {
          customerArea,
          end,
          start,
          type: 'custom'
        }
      });
    }
  }
};