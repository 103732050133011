import guideMixin from './guide';
const columnData = [{
  label: '客户',
  prop: 'name',
  width: '220px'
}, {
  label: '人数',
  prop: 'number'
}, {
  label: '总金额',
  prop: 'totalCollect'
}, {
  label: '类型',
  prop: 'type'
}, {
  label: '项目',
  prop: 'remarks'
}, {
  label: '金额',
  prop: 'collect'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};