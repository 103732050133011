import BoatPersonal from '@/views/print/pages/BoardName/Personal';
import { defaultState, rules } from '../config';
export default {
  components: {
    BoatPersonal
  },
  data() {
    return {
      rules,
      visible: false,
      form: defaultState(),
      id: '',
      activeName: '',
      tabList: [{
        label: '总名单',
        value: ''
      }, {
        label: '三星船',
        value: '三星船'
      }, {
        label: '四星船',
        value: '四星船'
      }, {
        label: '五星船',
        value: '五星船'
      }]
    };
  },
  computed: {
    getBoatType() {
      return this.activeName;
    }
  },
  methods: {
    fetchData(params) {
      this.$refs.boatRef.fetchBoatMembers(params);
    },
    handleExport() {
      this.$refs.boatRef.handleExport();
    },
    show({
      id
    }) {
      this.visible = true;
      this.id = id;
      this.$nextTick(() => {
        this.fetchData({
          id
        });
      });
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    }
  }
};