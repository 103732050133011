import { selectListMixin } from '@/mixin';
import { beforeWeek } from '@/utils';
import { orderType as OrderType } from '@/config/order';
export default {
  mixins: [selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'plan/boatPersonal/fetchPlan',
    params: {
      startTripDateS: beforeWeek(),
      planType: OrderType.personal
    }
  }),
  computed: {
    data() {
      return this.$store.getters['plan/boatPersonal/planList'];
    }
  },
  methods: {
    onFocus() {
      this.fetchData(this.params);
    }
  }
};