import { auditCustomFee } from '@/config/audit';
import { Audit, AuditAdmin, AuditCheck, PersonCount, Members, AuditRoleAll } from '@/components/common';
import { StatusAudit, StatusCheck, CustomerInfo } from '@/components/common';
import { findComponentsUpward, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { rowspanMixin, auditMixin, typeMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    Audit,
    AuditAdmin,
    AuditCheck,
    PersonCount,
    Members,
    StatusAudit,
    StatusCheck,
    AuditRoleAll,
    CustomerInfo
  },
  mixins: [typeMixin, rowspanMixin, auditMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      auditAllStatus: '',
      columnData: [],
      headMergeNum: 6,
      tailMergeNum: auditCustomFee.length - 1,
      hasMergeTail: true,
      targetMergeNum: auditCustomFee.length - 2,
      statisticsKeys: ['totalMoney'],
      auditAll: false,
      curOrder: {}
    };
  },
  computed: {
    ...mapGetters({
      planOrderData: 'plan/index/planOrderData'
    }),
    outOPUserName() {
      return row => {
        const order = this.planOrderData.find(it => it.id == row.rowId);
        return order ? order.outOPUserName : '';
      };
    }
  },
  watch: {
    tableData: {
      handler: function () {
        this.$nextTick(() => {
          this.rowspan();
        });
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    const len = auditCustomFee.length;
    this.tailMergeNum = visibleCustom ? len - 1 : len - 2;
    this.targetMergeNum = visibleCustom ? len - 2 : len - 3;
    this.columnData = visibleCustom ? auditCustomFee : filterCustomColumn(auditCustomFee);
  },
  methods: {
    handleEdit(rowNum) {
      const findComp = findComponentsUpward(this, 'PlanTeamFund')[0];
      findComp.showEditDialog({
        rowNum,
        index: 0
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list;
    },
    validateForm() {
      return true;
    },
    handleRowLog() {
      const findComp = findComponentsUpward(this, 'Reception')[0];
      findComp.handleRowLog('fee');
    }
  }
};