var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "auth-sidebar"
  }, [_c("div", [_c("img", {
    staticClass: "wl-logo",
    attrs: {
      src: require("../../../assets/wl-logo.png")
    }
  }), _vm._l(_vm.leftText, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "left-content-box"
    }, [_c("div", {
      staticClass: "item-content",
      domProps: {
        innerHTML: _vm._s(item.conten)
      }
    })]);
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };