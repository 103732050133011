var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectData", _vm._g(_vm._b({
    attrs: {
      data: _vm.getData,
      list: _vm.list,
      label: _vm.label,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire
    },
    on: {
      "on-load": _vm.loadMoreData,
      focus: _vm.lazyLoad
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        item
      }) {
        return [_c("span", {
          staticStyle: {
            float: "left"
          },
          attrs: {
            value: item.value
          }
        }, [_vm._v(_vm._s(item.label))]), item.customerTravelAgencyId > 0 ? _c("span", {
          staticStyle: {
            float: "right",
            color: "#1890ff",
            "font-size": "13px"
          }
        }, [_vm._v(" (在线) ")]) : _vm._e(), _c("span", {
          staticStyle: {
            float: "right",
            color: "#8492a6",
            "font-size": "13px"
          }
        }, [_vm._v(" " + _vm._s(item.province) + " " + _vm._s(item.city) + " ")])];
      }
    }])
  }, "SelectData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };