import { SelectAccount } from '@/components/common';
import { formMixin } from '@/mixin';
const defaultState = () => ({
  isRevenueAndExpenditure: false
});
export default {
  components: {
    SelectAccount
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isEdit: false,
      isTopLevel: false,
      form: defaultState(),
      validateForm: {
        name: [{
          required: true,
          message: '科目名字不能为空！'
        }]
      },
      title: ''
    };
  },
  methods: {
    show() {
      this.visible = true;
      this.isTopLevel = true;
      this.title = '新增科目';
    },
    edit(row) {
      this.visible = true;
      this.title = '编辑科目';
      const {
        id,
        subjectType,
        parentsId,
        isRevenueAndExpenditure,
        name
      } = row;
      this.form = {
        id,
        subjectType,
        parentsId,
        isRevenueAndExpenditure,
        name
      };
      if (parentsId != 0) {
        this.isTopLevel = true;
        this.parentsName = name;
        this.form.subjectId = parentsId;
        this.form.subId = -1;
      }
    },
    hide() {
      this.visible = false;
      this.isTopLevel = false;
      this.form = defaultState();
    },
    handleSelectScenic(val) {
      const {
        id,
        name
      } = val;
      this.form.parentsId = id;
      this.form.parentsName = name;
    },
    // 添加科目
    handleCreate() {
      if (!this.form.parentsName) {
        this.form.parentsId = 0;
      }
      if (!this.handleValidate()) return;
      const opst = {
        ...this.form
      };
      this.$store.dispatch('system/accounting/saveOrUpdate', opst).then(() => {
        this.$bus.tip();
        this.$parent.fetchData();
        this.hide();
      });
    }
  }
};