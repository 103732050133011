/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-14 16:10:53
 * @Description:
 */
import request from '@/utils/request';

/**
 * 创建角色
 * @param {object}} data 创建角色的数据对象
 */
export function createRole(data) {
  return request({
    url: '/system/role/add',
    method: 'post',
    data
  });
}

/**
 * 更新角色
 * @param {object}} data 更新角色参数对象
 */
export function updateRole(data) {
  return request({
    url: '/system/role/update',
    method: 'post',
    data
  });
}

/**
 * 删除角色
 * @param {number} roleId 角色id
 */
export function deleteRole(roleId) {
  return request({
    url: '/system/role/del',
    method: 'post',
    data: {
      roleId
    }
  });
}

/**
 * 获取角色是否有查看客户信息
 * @param {number} roleId 角色id
 */
export function getRoleCustomerAuth(roleId) {
  return request({
    url: '/system/role/role-by-id',
    method: 'get',
    params: {
      roleId
    }
  });
}

/**
 * 角色拥有的权限
 * @param {number}} roleId 角色id
 */
export function permissionRole(roleId) {
  return request({
    url: '/system/role/listPermission',
    method: 'post',
    data: {
      roleId
    }
  });
}
export function permissionRoleHave(roleId) {
  return request({
    url: '/system/role/listRolePermissionOnlyHave',
    method: 'post',
    data: {
      roleId
    }
  });
}

/**
 * 更新角色权限
 * @param {*} data 角色权限对象
 */
export function permissionUpdateRole(data) {
  return request({
    url: '/system/role/updateRoleMenu',
    method: 'post',
    data
  });
}

/**
 * 获取权限列表
 * @param {object}} queryParam 查询角色权限列表参数
 */
export function fetchRole(params) {
  return request({
    url: '/system/role/list',
    method: 'post',
    params
  });
}

// 导入其它角色的权限
export function importRolePermission(data) {
  return request({
    url: '/system/role/importOtherRole',
    method: 'post',
    data
  });
}