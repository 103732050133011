/* eslint-disable no-empty-pattern */
import { requestGet, requestSave, setUploadStatus } from '@/api/plan/superviseTravel';
import { parseQuerys } from '@/utils/index';
import { ItineraryUrlType, ItineraryStatusMap
// ItineraryIdSetType,
} from '@/config/superviseTravel';
function getTypeUrl(option, val) {
  const {
    contractNo: type
  } = option;
  return type ? ItineraryUrlType[type] + '/' + val : ItineraryUrlType.team + '/' + val;
}

// function getItineraryId(contractNo) {
//   return ItineraryIdSetType[contractNo] || 'receptionId'
// }

const DEFAULT_PAGE = {
  current: 1,
  size: 10
};
const dfaultState = () => ({
  data: null
});
const state = dfaultState();
const getters = {
  data: state => state.data
};
const actions = {
  // 获取列表的信息
  getWaitInfo({}, params) {
    // const url = '/api/kingon-glly/itineraryReception/page'
    const url = getTypeUrl(params, 'page');
    const path = parseQuerys(url, {
      ...DEFAULT_PAGE,
      ...params,
      ticketStatus: ItineraryStatusMap.wait
    });
    return requestGet({
      path
    }).then(res => {
      if (!res || !res.data) return {
        records: []
      };
      return res.data;
    });
  },
  // 获取已提交列表的信息
  getCommitInfo({}, params) {
    const url = getTypeUrl(params, 'page');
    const path = parseQuerys(url, {
      ...DEFAULT_PAGE,
      ...params
      // ticketStatus,
    });
    return requestGet({
      path
    }).then(res => {
      if (!res || !res.data) return {
        records: []
      };
      return res.data;
    });
  },
  // 获取详情信息
  getById({}, opts) {
    const url = getTypeUrl(opts, 'getById');
    const path = parseQuerys(url, {
      id: opts.id
    });
    return requestGet({
      path
    }).then(res => {
      if (!res || !res.data) return {
        records: []
      };
      return res.data;
    });
  },
  getGuideInfo({}, name) {
    const url = '/api/kingon-glly/resourceGuide/pageWithoutAnnotationFilter';
    const path = parseQuerys(url, {
      name,
      descs: 'create_time',
      queryType: 2,
      ...DEFAULT_PAGE
    });
    return requestGet({
      path
    }).then(res => {
      if (!res || !res.data) return {
        records: []
      };
      return res.data;
    });
  },
  // 修改订单状态
  updateStatus({}, opts) {
    const path = getTypeUrl(opts, 'updateStatus');
    // const receptionKey = ItineraryIdSetType[opts.contractNo]
    return requestSave({
      path,
      param: {
        receptionId: opts.id,
        ticketStatus: ItineraryStatusMap.commit
      }
    });
  },
  save({}, opts) {
    const path = getTypeUrl(opts, 'save');
    return requestSave({
      path,
      param: opts
    });
  },
  update({}, opts) {
    const path = getTypeUrl(opts, 'update');
    return requestSave({
      path,
      param: opts
    });
  },
  // 设置上传状态
  setStatus({}, opts) {
    return setUploadStatus(opts);
  },
  // 设置状态
  restoreStatus({}, opts) {
    const path = getTypeUrl(opts, 'updateStatus');
    // const receptionKey = ItineraryIdSetType[opts.contractNo]

    return requestSave({
      path,
      param: {
        receptionId: opts.id,
        ticketStatus: ItineraryStatusMap.wait
      }
    });
  },
  // 设置删除状态
  deleteItem({}, opts) {
    const path = getTypeUrl(opts, 'updateStatus');
    // const receptionKey = ItineraryIdSetType[opts.contractNo]
    return requestSave({
      path,
      param: {
        receptionId: opts.id,
        ticketStatus: ItineraryStatusMap.cancel
      }
    });
  },
  removeItem({}, opts) {
    // const path = getTypeUrl(opts, 'remove')
    const path = ItineraryUrlType[opts.contractNo] + '/remove?ids=' + opts.id;
    return requestGet({
      path,
      param: {}
    });
  }
};
const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};