var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("InputBase", {
    attrs: {
      label: "小团号"
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    on: {
      "on-select": _vm.selectCustomer
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDate", {
    attrs: {
      label: "发团年份",
      type: "year",
      "is-require": ""
    },
    on: {
      change: _vm.changeYear
    },
    model: {
      value: _vm.searchParams.year,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "year", $$v);
      },
      expression: "searchParams.year"
    }
  }), _c("SelectBase", {
    attrs: {
      list: _vm.orderTypeList,
      label: "订单类型"
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "客户区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selectCustomerArea
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("h2", [_vm._v("毛利总汇表")]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "has-total": true,
      "show-summary": "",
      border: ""
    }
  }), _vm.total > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }], null, false, 640951709)
  })] : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };