var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.unAudited ? _c("el-tag", _vm._b({
    attrs: {
      effect: "plain",
      type: "warning"
    }
  }, "el-tag", _vm.$attrs, false), [_vm._v(" 待审核 ")]) : _c("el-tag", _vm._b({
    attrs: {
      effect: "plain",
      type: _vm.getRoleColor
    }
  }, "el-tag", _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.getRole) + "已审 ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };