import "core-js/modules/es.array.push.js";
import billBaseMixin from '../../billBaseMixin.js';
import { StatusAudit } from '@/components/common';
import { TYPES } from '@/config/audit';
import { mergeTableRow, parseTime } from '@/utils';
import { Columns } from '../tableConfig';
import { StatisticsKeys, MergeRowKeys, StatisticsTransfor } from '../config';
import { getSelectLabel, getPayTypeLabel } from '@/config/select';
import { orderType } from '@/config/order';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    StatusAudit
  },
  mixins: [billBaseMixin],
  data() {
    return {
      Columns,
      calcCheckedMoney,
      urlType: 'restaurantOrder',
      billCheckAll: false,
      statisticsKeys: StatisticsKeys,
      type: TYPES.restaurant,
      checkedMoney: 0
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/restaurant'];
    },
    tableData() {
      const data = this.data;
      const list = data ? mergeTableRow(this.formatData(data.list), MergeRowKeys) : [];
      return list;
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    statistics() {
      const result = {};
      if (this.data && this.data.statistics) {
        const data = this.data.statistics;
        Object.keys(StatisticsTransfor).map(key => {
          result[StatisticsTransfor[key]] = data[key];
        });
      }
      return result;
    },
    isDisabled() {
      return row => {
        return row.ckStatus || row.headCompanyId > 0;
      };
    },
    getCheckedMoney() {
      return this.checkedMoney;
    }
  },
  methods: {
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          totalPay,
          mealType,
          checkAccountMoney,
          checkAccountStatus,
          restaurantTypes,
          checkAccountTime
        } = it;
        this.$set(it, 'ckStatus', false);
        const item = {
          ...it,
          rowId: id,
          mergeId: id,
          $cashType: getPayTypeLabel(it.cashType),
          $mealType: mealType ? getSelectLabel('restaurant', mealType.toLowerCase()) : '',
          checkAccountMoney: checkAccountStatus ? checkAccountMoney : totalPay,
          $checkAccountTime: checkAccountStatus ? checkAccountTime && parseTime(checkAccountTime) : ''
        };
        const itemList = restaurantTypes || [];
        if (itemList.length) {
          itemList.forEach(s => {
            delete s.id;
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    directPlanDetail(row) {
      const planId = row.planId || row.inCustomerId;
      this.$store.commit('order/index/CUR_ORDER_ID', planId);
      this.$store.commit('plan/index/CUR_PLAN_ID', planId);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id: planId,
          planType: orderType.team
        }
      });
    },
    directOrderDetail(row) {
      const {
        id,
        orderType
      } = row;
      this.$router.push({
        path: '/order/team-input-detail',
        query: {
          id,
          type: 'detail',
          planType: orderType
        }
      });
    },
    handleCheck(row, val) {
      row.ckStatus = val;
      this.checkedMoney = calcCheckedMoney(this.tableData);
    },
    processMergeData(list) {
      return mergeTableRow(list, MergeRowKeys);
    },
    changeCheckBox(row, val) {
      if (!row.checkAccountMoney) {
        row.checkAccountMoney = val ? row.unPay : row.totalPayed;
      }
    },
    // 全选
    changeCheckAll(val) {
      this.tableData.forEach(it => {
        it.ckStatus = val;
        it.isChecked = val;
      });
      this.billCheckAll = val;
      this.checkedMoney = calcCheckedMoney(this.tableData);
    },
    // 格式对账参数
    formatBillCheckOptions({
      row,
      type,
      status
    }) {
      const opts = {
        auditTypeEnum: type,
        checkAccountRemark: row.checkAccountRemark,
        checkAccountMoney: row.checkAccountMoney,
        resourceId: row.rowId || row.id,
        status
      };
      return opts;
    },
    // 单条对账
    handleCheckout(row) {
      const opts = this.formatBillCheckOptions({
        row,
        type: this.type,
        status: !row.checkAccountStatus
      });
      this.updateBillCheck([opts]);
    },
    // 审核所有选中
    handleBillCheck() {
      const selectList = this.tableData.filter(it => it.ckStatus);
      if (!selectList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何对账数据'
        });
        return;
      }
      const dataList = selectList.map(it => {
        return this.formatBillCheckOptions({
          row: it,
          type: this.type,
          status: !it.checkAccountStatus
        });
      });
      this.updateBillCheck(dataList);
    },
    updateBillCheck(dataList) {
      const loading = this.$bus.loading();
      return this.$store.dispatch('bill/billCheck/billCheck', dataList).then(() => {
        this.$bus.tip();
        loading.close();
        this.billCheckAll = false;
        this.$emit('reload');
      }).catch(err => {
        loading.close();
        this.$bus.tip({
          type: 'error',
          message: err.message
        });
      });
    },
    handleLog({
      row
    }) {
      this.$emit('log', row);
    }
  }
};