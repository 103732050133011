import { excelMixin } from '@/mixin';
import billDownTableMixin from '@/views/billDown/billDownTableMixin';
import { UnitPayColumn as columnData } from '@/config/billDown/payUnit/pickup';
import { PlanNumberMore } from '@/components/common';
export default {
  components: {
    PlanNumberMore
  },
  mixins: [excelMixin, billDownTableMixin],
  data: () => ({
    columnData,
    tableData: [],
    statistic: null,
    total: 0,
    isSticky: true,
    pathDetail: '/bill/unit-pay/pickup-detail',
    path: '/bill/unit-pay/pickup-update',
    filename: '车队销账',
    autoWidth: true,
    bookType: 'xlsx',
    excelLoading: false
  }),
  computed: {
    data() {
      return this.$store.getters['bill/billDown/payUnitPickup/data'];
    }
  },
  watch: {
    data: {
      handler: function (data) {
        !data ? this.$emit('page') : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    handleData(data) {
      const {
        list,
        totalCount,
        statistic
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistic;
    }

    // // 修改
    // handleUpdate(searchParams) {
    //   // mixin里面
    // },

    // // 删除
    // handleDelete(searchParams) {
    //   // mixin里面
    // },

    // exportExcel() {
    //    // mixin里面
    // }
  }
};