import Reception from '@/components/common/Reception';
import { mapGetters } from 'vuex';
import { typeMixin } from '@/mixin';
export default {
  components: {
    Reception
  },
  mixins: [typeMixin],
  data() {
    return {
      visible: false,
      data: null
    };
  },
  computed: {
    ...mapGetters({
      receptionList: 'order/reception/receptionList'
    })
  },
  methods: {
    show(data) {
      this.visible = true;
      this.data = this.formatItem(data);
    },
    hide() {
      this.visible = false;
    },
    formatItem(it) {
      const {
        feeList,
        collectList,
        subsidyList
      } = it;
      it.feeList = this.formatLabel(feeList, 'teamFundType');
      it.collectList = this.formatLabel(collectList, 'proxyType');
      it.subsidyList = this.formatLabel(subsidyList, 'refundType');
      return it;
    },
    formatLabel(list, type) {
      return list.map(fee => {
        fee.typeLabel = this.getType(type, fee.type);
        return fee;
      });
    },
    validateReception() {
      const validateKeys = ['customerId', 'endTripDate', 'startTripDate'];
      for (let i = 0; i < this.receptionList.length; i++) {
        const item = this.receptionList[i];
        for (let j = 0; j < validateKeys.length; j++) {
          const key = validateKeys[j];
          if (!item[key]) {
            return false;
          }
        }
      }
      return true;
    },
    handleCreate() {
      if (!this.receptionList || this.receptionList.length == 0) return;
      if (!this.validateReception()) {
        this.$bus.tip({
          type: 'warning',
          message: '社信息不全'
        });
        return;
      }
      this.$store.dispatch('order/reception/turnOutBatch', this.receptionList).then(() => {
        this.hide();
        this.$parent.fetchData();
      }).catch(() => {});
    }
  }
};