import { tableMixin } from '@/mixin';
export default {
  components: {},
  mixins: [tableMixin],
  data: () => ({}),
  methods: {
    subitemAdd(row) {
      this.$parent.showAddDialog(row);
    }
  }
};