import FormLine from './FormLine';
import TableList from './TableList';
import { BtnCreate, DialogDetail, DialogAgreement, DialogLog } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceLine } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  name: 'Resource',
  components: {
    FormLine,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    TableList,
    BtnCreate
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceLine,
    tableData: [],
    total: 0,
    searchParams: {
      name: ''
    }
  }),
  computed: {
    lineData() {
      return this.$store.getters['resource/line/lineDataOrigin'];
    }
  },
  watch: {
    lineData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/line/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/line/fetchList');
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/line/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    deleteRow(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/line/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    editTableRow(scope) {
      const copy = {
        ...scope.row,
        index: scope.$index
      };
      this.$refs.formRef.show(copy);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/line/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};