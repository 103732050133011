import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-unused-vars */

const pinyin = require('pinyin');
import { orderTypeLabel, orderReceiveStatus, orderAuditStatus } from '../config/order';
import { parseDate, parseTime } from './index';
import { auditProgressStatus } from '@/config/audit';
import { cashModeTypeLabel, cashFeeTypeLabel, cashLogType as CashLogType } from '@/config/billDown';
import { CasherStatusLabel } from '@/config/billDown/cashStatus';
import { processMinus } from './number';
import { getPayTypeLabel } from '@/config/select';
const caseKeys = ['cashType', 'payType'];
export const formatPayTypeList = (list, key = 'cashType') => {
  return list.map(it => {
    it.$cashType = it.$payType = getPayTypeLabel(it[key]);
    return it;
  });
};
const formatDate = val => {
  return val ? val.split('T')[0] : '';
};
const formatDateTime = val => {
  return val ? val.split(' ')[0] : '';
};
export const formatShiftDateTime = val => {
  return val ? parseTime(val) : ' ';
};
const casehump = val => {
  return val.toUpperCase();
};
const genFns = (keys, fn) => {
  const fns = {};
  keys.forEach(key => {
    fns[key] = function (val) {
      return fn(val);
    };
  });
  return fns;
};

// 大写字段
const genCaseHump = genFns(caseKeys, casehump);

// 所有需要除以100的字段，统一在这里维护
const transferReduction = {
  cashDate: val => formatDateTime(val),
  createTime: val => formatDate(val),
  cashTypeLabel: val => CashLogType[val],
  cashActionLabel: val => cashFeeTypeLabel[val],
  cashModeLabel: val => val && cashModeTypeLabel[val.toLowerCase()]
};

// 所有需要 ✖️ 乘以100的字段，统一在这里维护
const transferIncrease = {
  ...genCaseHump
};

// 格式化提交后端金额/枚举字段
export function formatCommitOpts(it) {
  const keys = Object.keys(it);
  keys.forEach(key => {
    if (transferIncrease[key]) {
      it[key] = transferIncrease[key](it[key]);
    }
  });
  return it;
}

// 格式化获取数据 单条
export function formatOpts(it) {
  const keys = Object.keys(it);
  keys.forEach(key => {
    if (transferReduction[key]) {
      it[key] = transferReduction[key](it[key]);
    }
  });
  return it;
}

// 格式化表格数据的, 包含时间，金额处理，将分转成元
export function formatTableData(list) {
  if (!list || !list.length) return [];
  return list.map(it => {
    return formatOpts(it);
  });
}

// 格式化提交后端金额
export function formatCommitTableData(list) {
  if (!list || !list.length) return [];
  return list.map(it => {
    return formatCommitOpts(it);
  });
}

// 只格式创建时间，不格式金额
export const formatCreateTime = (it, key = 'createTime') => {
  const val = it[key];
  it[key] = val ? parseDate(val) : ' ';
  return it;
};
export function formatSelectList(list, key = 'name') {
  if (!list || !list.length) return [];
  return list.map(it => {
    const item = {
      isEnable: it.isEnable,
      label: it[key],
      value: it.id,
      id: it.id,
      name: it[key]
    };
    // 判断单价是否大于-1
    if (it.mealLabelPrice > -1) {
      item.mealLabelPrice = it.mealLabelPrice;
    }
    return item;
  });
}

// 数据标题加上拼音字母
export function formatCharData(list, key = 'name', suffix = '') {
  if (!list || !list.length) return [];
  return list.map(it => {
    const val = it[key];
    const item = {
      ...it,
      isCheck: false,
      label: `${suffix && it[suffix] ? val + '  [' + it[suffix] + ']' : val}`,
      value: it.id
    };
    const py = pinyin(val, {
      style: pinyin.STYLE_FIRST_LETTER,
      heteronym: false
    }).map(v => {
      return v[0];
    }).join('');
    item.slug = py;
    return item;
  });
}

// 处理旅监字典
export function formatDicData(list, type) {
  list = list.filter(it => it.status > 0);
  if (!list || !list.length) return [];
  return list.map(it => {
    let item;
    if (type == 'fleet') {
      item = {
        ...it,
        label: it.dicCode,
        value: it.dicCode,
        id: it.dicCode
      };
    } else {
      item = {
        ...it,
        label: it.dicName,
        value: it.dicCode,
        id: it.dicCode
      };
    }
    const py = pinyin(it.dicName, {
      style: pinyin.STYLE_FIRST_LETTER,
      heteronym: false
    }).map(item => {
      return item[0];
    }).join('');
    item.slug = py;
    return item;
  });
}

// 数据去重复
export function duplicateData(list) {
  if (!list || !list.length) return [];
  const map = {};
  const result = list.filter(it => {
    if (!map[it.id]) {
      map[it.id] = true;
      return it;
    }
  });
  return result;
}

// 数组分页 size 每页条数
export function formatDicDataPage(list, size) {
  let handCaseTable = []; //清空之前的数据
  for (var i = 0; i < Math.ceil(list.length / size); i++) {
    var start = i * size;
    var end = start + size;
    handCaseTable.push(list.slice(start, end));
  }
  return handCaseTable;
}
export const hasMoreData = data => {
  const {
    totalCount,
    currentPage,
    pageSize
  } = data;
  const hasMore = currentPage * pageSize < totalCount;
  return hasMore;
};

// 转换团队类型
export const getOrderType = val => orderTypeLabel[val];

// 转换审核状态
export const getOrderAuditType = val => orderAuditStatus[val];

// 获取接收状态
export const getReceiveStatusType = val => orderReceiveStatus[val];

// 格式化字典字段取其名称显示
export const splitItem = val => {
  if (!val) return [];
  return val.split('|');
};

// 处理多多子项 如： 景区对应多个门票
export const formatTableItem = ({
  list,
  lkey = '',
  tkey = 'createTime',
  tfmt = '{y}-{m}-{d}'
}) => {
  const getStatus = val => {
    if (!val) return;
    return auditProgressStatus[val];
  };
  return list.reduce((acc, cur) => {
    const {
      auditProgress
    } = cur;
    const itemList = cur[lkey] || [];

    // 创建时间
    cur[tkey] = cur[tkey] && parseTime(cur[tkey]);

    // 审核状态
    cur.auditProgress = getStatus(auditProgress);

    // 出纳确认状态
    cur.cashierAuditStatus = cur.cashierAuditStatus ? CasherStatusLabel[cur.cashierAuditStatus] : '';
    let obj = {
      rowId: cur.id,
      ...cur
    };
    if (itemList.length) {
      itemList.forEach((it, i) => {
        it.auditProgress = getStatus(auditProgress);
        obj = {
          ...obj,
          ...it
        };
        acc.push(obj);
      });
    } else {
      acc.push(obj);
    }
    return acc;
  }, []);
};
export const formatBalance = list => {
  return list.map(calcBalance);
};
export const calcBalance = it => {
  const {
    userMoney,
    money
  } = it;
  it.balance = processMinus(money, userMoney);
  return it;
};
export const setCheckStatus = (list, status = false) => {
  return list.map(it => {
    it.isChecked = status;
    return it;
  });
};