import "core-js/modules/es.array.push.js";
import DialogCustom from './DialogCustom';
import DialogProduct from './DialogProduct';
import TableList from './TableList';
import { BtnCreate, DialogLog } from '@/components/common';
import { resourceProduct } from '@/config/resource';
import { formatTableData } from '@/utils/data';
import { searchMixin } from '@/mixin';
const defaultState = {
  createUserName: '',
  name: '',
  displayType: ''
};
export default {
  components: {
    DialogCustom,
    DialogProduct,
    TableList,
    BtnCreate,
    DialogLog
  },
  mixins: [searchMixin],
  data: () => ({
    link: {
      create: '/resource/product-create',
      update: '/resource/product-update',
      clone: '/resource/product-clone',
      preview: '/resource/product-preview'
    },
    columnData: resourceProduct,
    tableData: [],
    total: 0,
    searchParams: defaultState
  }),
  computed: {
    productData() {
      return this.$store.getters['resource/product/productDataOrigin'];
    }
  },
  watch: {
    productData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParams) {
      this.$store.dispatch('resource/product/fetch', queryParams);
    },
    fetchList() {
      this.$store.dispatch('resource/product/fetchList');
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteRow(id) {
      const loading = this.$bus.loading({
        text: '正在删除'
      });
      this.$store.dispatch('resource/product/delete', id).then(() => {
        this.fetchData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 复制线路
    copyProduct(scope) {
      this.$router.push({
        path: this.link.clone,
        query: {
          id: scope.row.id,
          type: 'clone'
        }
      });
    },
    // 添加客户
    addCustom(scope) {
      this.$refs.customRef.show(scope);
    },
    // 添加商城产品
    addMallProcut(row) {
      this.$refs.productRef.show(row);
    },
    // 删除
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    // 预览
    showTableDetail(scope) {
      this.$router.push({
        path: this.link.preview,
        query: {
          id: scope.row.id,
          type: 'preview'
        }
      });
    },
    // 编辑
    editTableRow(scope) {
      this.$router.push({
        path: this.link.update,
        query: {
          id: scope.row.id,
          type: 'update'
        }
      });
    },
    // 新增
    handleCreate() {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'create'
        }
      });
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    // 日志
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/product/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};