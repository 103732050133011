var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getShuttleType(scope.row.shuttleType)) + " ")];
      }
    }, {
      key: "cityType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getType(scope.row)) + " ")];
      }
    }, {
      key: "timeAndTarget",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getAndTarget(scope.row)) + " ")];
      }
    }, {
      key: "desPosition",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.desPosition) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };