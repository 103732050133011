import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import DialogMessage from './DialogMessage';
import DialogOrderCreate from './DialogOrderCreate';
import { orderTurnOutReceive } from '@/config/order';
import { SelectCustom, SelectProduct, DialogLog } from '@/components/common';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
export default {
  components: {
    SelectCustom,
    SelectProduct,
    TableList,
    DialogMessage,
    DialogLog,
    DialogOrderCreate
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: true,
    columnData: orderTurnOutReceive,
    tableData: [],
    total: 0,
    searchParams: {
      isOut: false
    },
    sendDate: [],
    endDate: []
  }),
  computed: {
    ...mapGetters({
      receiveData: 'order/receive/receiveData'
    })
  },
  watch: {
    receiveData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.getToday,
            isOut: false
          });
          return;
        }
        this.handleData(data);
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startTripDateS,
          startTripDateE,
          endTripDateS,
          endTripDateE
        } = data;
        this.searchParams = {
          ...data,
          isOut: false
        };
        this.sendDate = [startTripDateS, startTripDateE];
        this.endDate = [endTripDateS, endTripDateE];
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParams = {
      isOut: false
    }) {
      this.tableLoading = true;
      this.$store.dispatch('order/receive/fetch', queryParams).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableData = list;
      this.total = totalCount;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //发团日期 选择开始-结束日期
    changeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    selectStaff(val) {
      this.searchParams.dutyOPUserName = val.label;
    },
    showMessage(row) {
      this.$refs.messageRef.show(row);
    },
    showDetail(id) {
      this.$router.push({
        path: '/order/turn-in-detail',
        query: {
          id
        }
      });
    },
    showOrderCreate(row) {
      this.$refs.orderCreateRef.show(row);
    },
    showLog(scope) {
      const {
        id
      } = scope.row;
      this.$store.dispatch('order/receive/fetchLog', id).then(data => {
        this.$refs.logRef.show(data);
      });
    }
  }
};