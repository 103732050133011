var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-update form-fix-bar"
  }, [_c("div", {
    staticClass: "h20"
  }), _c("Credits"), _c("div", {
    staticClass: "mt20"
  }), _c("FormInput", {
    ref: "personalFormRef",
    attrs: {
      "form-data": _vm.form,
      "order-type": _vm.orderType
    },
    on: {
      "refund-generate": _vm.generateRefund
    }
  }), _c("div", {
    staticClass: "table-box table-itinerary"
  }, [_c("CollapseBase", {
    attrs: {
      title: "行程信息",
      "is-strong": ""
    }
  }, [_c("TableItinerary", {
    ref: "personalItineraryRef",
    attrs: {
      "comp-name": "PersonalUpdate"
    }
  })], 1)], 1), _c("div", {
    staticClass: "table-box"
  }, [_c("CollapseBase", {
    attrs: {
      title: "备注信息",
      "is-strong": ""
    }
  }, [_c("FormRemarks", {
    ref: "personalRemarksRef"
  })], 1)], 1), _c("CollapseBase", {
    attrs: {
      title: "酒店信息",
      "is-strong": ""
    }
  }, [_c("TableHotel", {
    ref: "personalHotelRef",
    attrs: {
      "has-booking": false
    }
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "团款信息",
      "is-strong": ""
    }
  }, [_c("TableFund", {
    ref: "personalFundRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "代收信息",
      "is-strong": ""
    }
  }, [_c("TableProxy", {
    ref: "personalProxyRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "返款信息",
      "is-strong": ""
    }
  }, [_c("TableRefund", {
    ref: "personalRefundRef",
    attrs: {
      "order-type": _vm.orderType
    }
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "大交通信息",
      "is-strong": ""
    }
  }, [_c("TableTraffic", {
    ref: "trafficRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "保险信息",
      "is-strong": ""
    }
  }, [_c("TableInsurance", {
    ref: "personalInsuranceRef"
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "接送信息",
      "is-strong": ""
    }
  }, [_c("TablePickup", {
    ref: "personalPickupRef"
  })], 1), _c("DialogTraffic", {
    ref: "personalTrafficRef"
  }), _c("CollapseBase", {
    attrs: {
      title: "游客信息",
      "is-strong": ""
    }
  }, [_c("TableTourist", {
    ref: "personalTouristRef"
  })], 1), _c("div", {
    staticClass: "table-box"
  }, [_c("CollapseBase", {
    attrs: {
      title: "其他收入",
      "is-strong": ""
    }
  }, [_c("TableOtherIncome", {
    ref: "otherIncomeRef"
  })], 1)], 1), _c("div", {
    staticClass: "table-box"
  }, [_c("CollapseBase", {
    attrs: {
      title: "其他支出",
      "is-strong": ""
    }
  }, [_c("TableOtherPay", {
    ref: "otherPayRef"
  })], 1)], 1), _c("CollapseBase", {
    attrs: {
      title: "外转信息",
      "is-strong": ""
    }
  }, [_c("TableReception", {
    ref: "personalReceptionRef"
  })], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_c("PopoverTip", {
    attrs: {
      placement: "left-start",
      title: "功能说明：",
      content: "该按钮会更新所有项目的内容，如果只是小部分修改，建议使用项目内的更新按钮，性能更好"
    }
  }, [_vm._v(" 更新全部 ")])], 1), _c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印单据 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.handleBack
    }
  }, [_vm._v(" 关闭 ")])], 1), _c("DialogItinerary"), _c("DialogNotice"), _c("DialogTraffic", {
    ref: "personalTrafficRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };