// 消费类型

// 导游开通状态
export const EnterpriseCodeType = {
  NO_INVITE: 0,
  INVITED: 1,
  OPEND: 2
};
// 导游开通状态
export const EnterpriseCodeStatus = {
  0: '未邀请',
  1: '已邀请',
  2: '已开通'
};

// 导游开通状态 tag
export const EnterpriseCodeTagType = {
  0: 'warning',
  1: 'primary',
  2: 'success'
};
export const RES_TYPE = {
  HOTEL: '酒店',
  RESTAURANT: '餐饮',
  BUS: '车队',
  SCENIC: '景点'
};