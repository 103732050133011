import { SelectStaff } from '@/components/common';
import { searchMixin } from '@/mixin';
import { setSearchCache } from '@/utils';
export default {
  components: {
    SelectStaff
  },
  mixins: [searchMixin],
  data: () => ({
    searchParams: {}
  }),
  computed: {
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  methods: {
    onSearch() {
      const params = {
        pageNum: 1,
        ...this.searchParams
      };
      this.$emit('on:search', params);
      setSearchCache(this.$store, this.$route, params);
    }
  }
};