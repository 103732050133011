var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item mt-20"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_vm._v(" 接送默认增加成本 ")]), _c("div", {
    staticClass: "name"
  }, [_c("SelectFleet", {
    attrs: {
      label: "",
      all: false
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.busCompanyName,
      callback: function ($$v) {
        _vm.busCompanyName = $$v;
      },
      expression: "busCompanyName"
    }
  })], 1), _c("div", {
    staticClass: "btn-box"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      round: ""
    },
    on: {
      click: _vm.updateConfigStatus
    }
  }, [_vm._v(" 保存 ")]), _c("el-button", {
    attrs: {
      type: "success",
      round: ""
    },
    on: {
      click: _vm.linkDictionay
    }
  }, [_vm._v(" 默认价格 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };