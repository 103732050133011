import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary, DialogLog } from '@/components/common';
import { orderFund, TYPES } from '@/config/order';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, rowspanMixin, systemMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { mapGetters } from 'vuex';
import { multiplyNumber } from '@/utils/number';
import tableMixin from '../tableMixin';
const defaultState = () => ({
  type: '',
  typeId: '',
  remarks: '',
  price: 0,
  count: 1,
  times: 1,
  total: 0
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    DialogLog
  },
  mixins: [dictionaryMixin, rowspanMixin, systemMixin, tableMixin],
  data() {
    return {
      teamFundType: codeType.teamFundType,
      tableData: [],
      columnData: orderFund,
      removeCache: [],
      // 要删除的团款项目
      statisticsKeys: ['price', 'total'],
      deleteMsg: '是否确定删除此条团款？',
      fetchPath: 'order/index/fetchFund',
      deletePath: 'order/index/deleteFund',
      updatePath: 'order/index/createOrUpdateFund'
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder'
    }),
    calcTotal() {
      return row => {
        return multiplyNumber(row.price, row.count, row.times);
      };
    },
    isDisableBtn() {
      return !this.allowModifyMoney && this.isBtnDisable;
    },
    isDisabled() {
      return row => {
        return this.allowModifyMoney ? this.isTeanFund(row) : this.isTeanAuditFund(row);
      };
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = data.map(it => {
        it.checkAccountStatus = it.checkAccountStatus ? it.checkAccountStatus : false;
        return it;
      });
    },
    selectType(row, item) {
      const {
        label,
        value
      } = item;
      const findItem = this.getDictionaryValue(this.teamFundType, value);
      if (findItem) {
        row.count = this.getCount(this.currentOrder, findItem) || 1;
      }
      row.type = label;
    },
    handlePlus() {
      const {
        adultCount: count
      } = this.currentOrder;
      const {
        id: typeId,
        name: type
      } = this.getDictionaryDefVal(this.teamFundType);
      this.tableData.push({
        ...defaultState(),
        count,
        typeId,
        type
      });
    },
    beforeCommit(customerOrderId, type) {
      const list = this.tableData.filter(it => !it.auditProgress || it.auditProgress == AuditRole.wait);
      // 判断是否开启审核后允许修改团款
      const tableData = this.allowModifyMoney ? this.tableData : list;
      return tableData.map(it => {
        it.customerOrderId = customerOrderId;
        it.total = it.price * it.count * it.times;
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      // const id
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.fee
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.price === undefined || row.price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '团款单价不能为空！'
          });
          return false;
        }
      }
      return true;
    }
  }
};