var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-check-refund"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.fetchData,
      export: _vm.handleExport
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StatisticsKeys
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.startTripDate))]), _c("div", [_vm._v(_vm._s(row.endTripDate))])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };