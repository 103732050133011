/* eslint-disable no-unused-vars */
import { tokenRequest, scenicCompanyRequest, guideListRequest, guideAddRequest, guideDeleteRequest, guideEditRequest, guideDetailRequest, touristListRequest } from '@/api/thirdpart/boat';
import { getSysConfig } from '@/api/system/buttJoint';
import { setBoatRiverData } from '@/utils/cookie';
import { formatCharData } from '@/utils/data';
const DIGIT_RIVER_TEXT = '数字漓江船';
const dfaultState = () => ({
  boatToken: null,
  scenicData: null,
  guideData: null
});
const state = dfaultState();
const getters = {
  boatToken: state => state.boatToken,
  scenicData: state => state.scenicData,
  guideData: state => state.guideData
};
const actions = {
  // 获取 安排
  riverToken({
    commit
  }, code) {
    return getSysConfig({}).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      const cur = data.find(it => it.name.indexOf(DIGIT_RIVER_TEXT) > -1);
      const {
        code
      } = cur;
      return tokenRequest({
        code
      }).then(res => {
        if (!res || !res.data) return;
        const {
          data
        } = res;
        setBoatRiverData({
          token: data.token || ''
        });
        commit({
          type: 'boatToken',
          data: data.token
        });
        return data;
      });
    });
  },
  // 获取景区公司
  scenicCompayList({
    commit
  }) {
    return scenicCompanyRequest().then(res => {
      if (!res || !res.success) return;
      commit('SET_DATA', {
        type: 'scenicData',
        data: formatCharData(res.result)
      });
      return res.result;
    });
  },
  // 获取导游列表
  guideList({
    commit
  }, params) {
    params = {
      _t: (Date.now() + '').slice(10),
      column: 'createTime',
      order: 'desc',
      field: 'id,,,importCode,enterpriseId_dictText,guide,phone,rideBy_dictText,importTime,report,statusDictText,action',
      pageNo: 1,
      pageSize: 10,
      ...params
    };
    return guideListRequest(params).then(res => {
      if (!res) return;
      const {
        result
      } = res;
      commit('SET_DATA', {
        type: 'guideData',
        data: result.records
      });
      return result.records;
    });
  },
  // 添加导游
  addGuide({
    commit
  }, opts) {
    return guideAddRequest(opts);
  },
  // 删除导游
  delGuide({
    commit
  }, id) {
    return guideDeleteRequest(id);
  },
  // 修改导游
  editGuide({
    commit
  }, opt) {
    return guideEditRequest(opt);
  },
  // 详情 导游
  detailGuide({
    commit
  }, id) {
    return guideDeleteRequest(id);
  },
  // 详情 导游
  detailTourist({
    commit
  }, id) {
    return guideDetailRequest(id).then(res => {
      if (!res || !res.success) return;
      return res.result;
    });
  },
  // 游客详情
  touristList({
    commit
  }, id) {
    const params = {
      id,
      ip: '',
      port: '8088',
      guideId: '',
      pageNo: 1,
      pageSize: 10,
      jmViewFirstLoad: 1
    };
    return touristListRequest({
      id,
      params
    }).then(res => {
      if (!res || !res.success) return;
      return res.result;
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};