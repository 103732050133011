import weixinPng from '@/assets/pay-weChat.png';
import alipayPng from '@/assets/pay-alipay.png';
import { VAS_TYPE, RECHANGE_TYPE } from '@/config/vas';
import { isDev, isTest, isWeilai } from '@/utils/env';
const PaymentType = {
  WECHAT: 'WECHAT',
  ALIPAY: 'ALIPAY'
};
const MessageRadio = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D'
};
const MessageMoney = {
  A: '10',
  B: '9',
  C: '8',
  D: '7'
};
const MessageCount = {
  A: '2000',
  B: '4000',
  C: '8000',
  D: '15000'
};
export default {
  data() {
    return {
      weixinPng,
      alipayPng,
      PaymentType,
      MessageRadio,
      MessageMoney,
      MessageCount,
      visible: false,
      isUpdate: false,
      loading: false,
      form: {},
      expireDate: '24个月',
      money: 200,
      paymentRadio: PaymentType.WECHAT,
      countRadio: MessageRadio.A,
      moneyRadio: MessageMoney.A,
      outTradeNo: ''
    };
  },
  methods: {
    show() {
      this.loading = false;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    changeCountRadio(val) {
      this.money = MessageMoney[val] / 100 * MessageCount[val];
    },
    alipaySubmit(res) {
      // alipay <form name="punchout_form" method="post" action="https://openapi.alipaydev.com/gateway.do?app_cert_sn=5a5629d68bd6ceaf9387e6d14acaab9f&charset=UTF-8&alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&method=alipay.trade.page.pay&sign=O46XdFO7HqhIpkf2QrNtCdG6kmnPIXT0frpBj6hYdDe0RpbjI1%2BBDFq%2BrQvNR%2FyXIoF8SuBEMhvJoTStG64lp3nZ%2B5SU57rZGu1IrkKj3mPkz7E4YD0u%2FH9LWBth1uy8gwviiT1lKUcRNsd1tPDG7u4W6yIxk1SHBww%2BsUznHMSL%2B5gerhDETAcRMs5HdThz0aTQbqqLmJvIso24Yd5SqYfj3wNb1nv%2Fx5SVi1lwU9OYRzSZvy0oBKZ77qboDj6uCJyGaLFyBtbY4E4oGnaPfanusCyO7MrTcN3Xhi6SR2D8zPDzRr1O0RPVsl77O%2FMOCCGQ2RuqDqIeALzjpidGiA%3D%3D&return_url=http%3A%2F%2Flocalhost%3A1234%2Fplan%2Fsms-send&notify_url=http%3A%2F%2Ftest.weilailvxingshe.com%2Fsystem%2Fapi%2Fpay-notify%2Falipay-notify&version=1.0&app_id=2021000117602658&sign_type=RSA2&timestamp=2023-03-31+17%3A47%3A34&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json"><input type="hidden" name="biz_content" value="{&quot;out_trade_no&quot;:&quot;SMS_32_VASPay_MN054742&quot;,&quot;total_amount&quot;:0.01,&quot;subject&quot;:&quot;短信&quot;,&quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;}"><input type="submit" value="立即支付" style="display:none" ></form>

      // 加载页面
      document.querySelector('body').innerHTML = res;
      document.forms[0].submit();
    },
    handleCreate() {
      const returnUrl = window.location.href;
      const opts = {
        code: VAS_TYPE.SMS,
        count: MessageCount[this.countRadio],
        money: isTest || isDev || isWeilai() ? 0.02 : this.money,
        name: '短信',
        rechargeType: RECHANGE_TYPE.COUNT,
        returnUrl
      };
      const requestPath = this.paymentRadio === PaymentType.WECHAT ? 'system/payment/wechatTopUp' : 'system/payment/alipayTopUp';
      this.$store.dispatch(requestPath, opts).then(res => {
        // 微信支付
        if (this.paymentRadio === PaymentType.WECHAT) {
          if (!res || !res.data) return;

          // 调起二维码
          this.$parent.wechatPayDialog({
            value: res.data.code_url,
            outTradeNo: res.data.out_trade_no,
            money: this.money
          });
        } else {
          this.alipaySubmit(res);
        }
      });
    }
  }
};