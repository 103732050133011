/*
 * @Author: HelloMan
 * @Date: 2021-10-14 09:30:53
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-14 16:47:52
 * @Description:
 */

export default {
  computed: {
    roleCustomerInfoAuth() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    },
    // 隐藏客户
    hasCustomerInfo() {
      return this.roleCustomerInfoAuth ? this.roleCustomerInfoAuth.showCustomerInfo : false;
    }
  }
};