import { SwitchStatus } from '@/components/common';
import { mapGetters } from 'vuex';
const defaultState = {
  name: '',
  isEnable: true
};
export default {
  components: {
    SwitchStatus
  },
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: defaultState
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData' // 账户信息
    }),
    getTitle() {
      return this.isUpdate ? '更新线路' : '添加线路';
    }
  },
  methods: {
    show(item) {
      if (item) {
        this.index = item.index;
        this.form = item;
        this.isUpdate = true;
      } else {
        this.isUpdate = false;
        this.form = {};
        this.form.createUserName = this.userData.realName;
        this.form.createUserId = this.userData.id;
      }
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    handleCreate() {
      if (!this.validateForm()) return;
      const opts = {
        ...this.form,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/line/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$parent.fetchList();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    validateForm() {
      if (!this.form.name) {
        this.$bus.tip({
          type: 'warning',
          message: '线路名称不能为空！'
        });
        return false;
      }
      return true;
    }
  }
};