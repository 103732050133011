import request from '@/utils/request';

// 订单列表
export function mallOrderRequest(params) {
  return request({
    url: '/mall/order/orders',
    method: 'get',
    params
  });
}

// 订单详情
export function mallOrderDetailRequest(orderId) {
  return request({
    url: '/mall/order/get-order',
    method: 'get',
    params: {
      orderId
    }
  });
}