var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "tourist-dialog-box",
    attrs: {
      title: "游客名单",
      width: "960px",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-row"
  }, [_vm.visible ? _c("TableTourist", {
    ref: "touristRef"
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };