import "core-js/modules/es.array.push.js";
import TreeFramework from './TreeFramework';
import { mapGetters } from 'vuex';
export default {
  components: {
    TreeFramework
  },
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters({
      subjectData: 'system/accounting/subjectData'
    })
  },
  watch: {
    subjectData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      // 如果已有数据，不做处理
      if (!data) {
        this.$store.dispatch('system/accounting/fetchListLevel');
        return;
      }
      this.list = this.formatList(data);
    },
    formatList(list) {
      const res = [];
      const fn = list => {
        list.forEach((it, index1) => {
          const children = [];
          res.push({
            value: it.id,
            label: it.name,
            ...it,
            children: []
          });
          if (it.subSubjects && it.subSubjects.length) {
            it.subSubjects.forEach(item => {
              res[index1].children.push({
                value: item.id,
                label: item.name,
                ...item,
                children
              });
            });
          }
        });
      };
      fn(list);
      return res;
    }
  }
};