import "core-js/modules/es.array.push.js";
import TableList from './components/TableList';
import DialogExport from './components/DialogExport';
import { planTeamArrange } from '@/config/plan';
import { LOG_YPES } from '@/config/resource';
import { DialogLog, SelectProduct, SelectStaff, SelectCustom, SelectGuide, SelectDictionary, SelectGuideManager } from '@/components/common';
import { mapGetters } from 'vuex';
import { customerInfoMixin, searchMixin, tagViewMixin, permissionMixin } from '@/mixin';
import { parseDate, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { getCurTagView } from '@/utils/tagView';
export default {
  components: {
    DialogLog,
    TableList,
    DialogExport,
    SelectProduct,
    SelectStaff,
    SelectCustom,
    SelectGuide,
    SelectDictionary,
    SelectGuideManager
  },
  mixins: [searchMixin, customerInfoMixin, tagViewMixin, permissionMixin],
  data: () => ({
    tableLoading: false,
    columnData: [],
    tableData: [],
    total: 0,
    statistics: [],
    visibleMore: true,
    searchParams: {
      startTripDateS: parseDate(new Date())
    },
    // department: [],
    fetchUrl: {
      '/plan/team-role-arrange': 'plan/index/fetchPlan2',
      '/plan/team-arrange': 'plan/index/fetchPlan',
      '/plan/team-arrange-edit/hotel': 'plan/index/fetchPlan'
    },
    planStatusLabel: '',
    guideManagerId: '',
    guideManagerType: 'orderGuideManageId'
  }),
  computed: {
    ...mapGetters({
      curPage: 'plan/index/curPage',
      planData: 'plan/index/planData',
      curRolePage: 'plan/index/curRolePage',
      planRoleData: 'plan/index/planRoleData',
      visitedViews: 'tagsView/visitedViews'
    }),
    getVisible() {
      return this.visibleMore;
    },
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    planData: {
      handler: function (data) {
        if (this.$route.path == '/plan/team-arrange') {
          this.initData(data);
        }
      },
      immediate: true
    },
    planRoleData: {
      handler: function (data) {
        if (this.$route.path == '/plan/team-role-arrange') {
          this.initData(data);
        }
      },
      immediate: true
    },
    searchParams: {
      handler: function (params) {
        const {
          orderGuideManageId,
          guideManageId
        } = params;
        this.guideManagerId = orderGuideManageId || guideManageId;
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planTeamArrange : filterCustomColumn(planTeamArrange);
  },
  mounted() {
    this.$bus.on('planlist:reload', () => {
      this.fetchData();
      setTimeout(() => {
        this.$bus.off('planlist:reload');
      }, 200);
    });
    this.$bus.on('plan:arrange-close', () => {
      this.closeTagView();
      this.fetchData();
    });
    if (this.$route.query && this.$route.query != '{}') {
      this.fetchData({
        ...this.$route.query
      });
    }
  },
  methods: {
    batchExport() {
      this.$refs.exportRef.show(this.searchParams);
    },
    initData(data) {
      data ? this.formatData(data) : this.fetchData({
        startTripDateS: this.getToday
      });
    },
    closeTagView(type) {
      const tagView = this.findCreateTagView(type);
      tagView && this.closeSelectedTag(tagView);
    },
    findCreateTagView(type = 'planTeamArrangeTravel') {
      return this.visitedViews.find(it => it.name === type);
    },
    fetchData(params = {}) {
      // params = this.processGuideManagerParams(params)
      this.$store.dispatch(this.fetchUrl[this.$route.path], {
        ...this.searchParams,
        ...params
      });
    },
    reloadData() {
      this.fetchData();
    },
    changeGuideManagerType({
      value
    }) {
      if (value == 'guideManageId') {
        delete this.searchParams['orderGuideManageId'];
      }
      if (value == 'orderGuideManageId') {
        delete this.searchParams['guideManageId'];
      }
    },
    changeGuideManager(val) {
      const value = this.guideManagerType;
      this.searchParams[value] = val.value;
      this.changeGuideManagerType({
        value
      });
    },
    processGuideManagerParams(params) {
      const value = this.guideManagerType;
      this.changeGuideManagerType({
        value
      });
      params[value] = this.guideManagerId;
      return params;
    },
    formatData(data) {
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = Object.freeze(list);
      this.total = totalCount;
      this.statistics = statistics;
    },
    changeProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productName = val.name;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    changeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeDutyOPUserName(val) {
      this.searchParams.dutyOPUserId = val.value;
    },
    changeOPUserName(val) {
      this.searchParams.outOPUserId = val.value;
    },
    selectGuideName(val) {
      this.searchParams.guideName = val.label;
    },
    selectGroupStatus(val) {
      val = val ? val : {
        label: '',
        value: ''
      };
      this.searchParams.planStatus = val.value;
      this.planStatusLabel = val.label;
    },
    changeCustom(val) {
      const {
        name
      } = val;
      this.searchParams.customerName = name;
    },
    // 选择团队类型
    selectTeamType(val) {
      const {
        value
      } = val;
      this.searchParams.groupType = value;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.searchParams.planState = this.planStatusVal;
      this.searchParams.pageNum = pageNum;
      this.searchParams.pageSize = pageSize;
      this.handleChangePage();
    },
    toggleSearchContent() {
      this.visibleMore = !this.visibleMore;
    },
    handleTypeIn(row) {
      const {
        id
      } = row;
      this.$router.push({
        name: 'planTouristArrange',
        query: {
          id
        }
      });
    },
    // 确认发团
    sendGroupConfirm(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('plan/index/sendGroupConfirm', id).then(() => {
          this.fetchData(this.searchParams);
          this.$bus.tip();
        });
      }, '确定要发团了吗?', '提示');
    },
    // 撤销
    handleRevoke(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('plan/index/sendGroupCancel', id).then(() => {
          this.fetchData(this.searchParams);
          this.$bus.tip();
        });
      }, '确定撤销发团了吗?');
    },
    // 日志
    handleShowLog({
      row
    }) {
      const types = `${LOG_YPES.plan},${LOG_YPES.audit}`;
      const {
        id: itemId
      } = row;
      this.$store.dispatch('plan/index/fetchPlanLogs3', {
        itemIds: itemId,
        types,
        subTypes: ',reimb',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};