import { AuditRole } from '@/config/audit';
export const TodoConfig = {
  waitAuditOrderCount: {
    path: '/order/order-audit',
    query: {}
  },
  waitToPlanCount: {
    path: '/order/team',
    query: {}
  },
  waitInGroupCount: {
    path: '/order/personal',
    query: {}
  },
  waitSendPlanCount: {
    path: '/plan/team-arrange',
    query: {}
  },
  waitOPAuditedPlanCount: {
    path: '/audit/team-single',
    query: {
      auditProgress: AuditRole.wait,
      sendGroupStatus: ''
    }
  },
  waitFinancialAuditedPlanCount: {
    path: '/audit/team-single',
    query: {
      auditProgress: AuditRole.op,
      sendGroupStatus: ''
    }
  },
  waitRecOutOrderCount: {
    path: '/order/turn-in',
    query: {
      acceptStatus: 'wait'
    }
  },
  waitConfirmUpdateOutOrderCount: {
    path: '/order/turn-in',
    query: {
      acceptStatus: 'accept'
    }
  }
};