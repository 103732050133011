import { SelectStaff } from '@/components/common';
import { rules, formState } from '../config';
export default {
  components: {
    SelectStaff
  },
  data() {
    return {
      rules,
      visible: false,
      autosize: {
        minRows: 5,
        maxRows: 10
      },
      form: formState()
    };
  },
  computed: {
    getTitle() {
      return '更新问题案例';
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.form = {
        ...formState(),
        ...data
      };
    },
    hide() {
      this.form = formState();
      this.visible = false;
    },
    onSelectStatff(type, val) {
      this.form[type] = val.label;
    },
    onConfirm() {
      this.handleCommit({
        toAudit: false,
        message: '案例已保存'
      });
    },
    onAudit() {
      this.handleCommit({
        toAudit: true,
        message: '案例已提交，审核中...'
      });
    },
    handleCommit({
      toAudit,
      message
    }) {
      const {
        formRef
      } = this.$refs;
      formRef.validate(valid => {
        if (!valid) {
          return;
        }
        const opts = {
          ...this.form,
          occurrenceTime: this.form.occurrenceTime + 'T00:00:00',
          toAudit
        };
        const loading = this.$bus.loading();
        return this.$store.dispatch('system/case/set', opts).then(() => {
          this.hide();
          this.$emit('reload');
          this.$bus.tip({
            message
          });
          loading.close();
        }).catch(() => {
          loading.close();
        });
      });
    }
  }
};