export default {
  name: 'FormBase',
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetFields() {
      return this.$refs.form.resetFields();
    },
    validateField(val) {
      return this.$refs.form.validateField(val);
    }
  }
};