import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    toggleStatus(row) {
      const {
        id,
        name,
        code,
        isEnable
      } = row;
      this.$store.dispatch('resource/company/createOrUpdate', {
        id,
        name,
        code,
        isEnable
      }).then(() => {
        this.$bus.tip();
      });
    }
  }
};