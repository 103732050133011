import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
// import BlankLayout from '@/layout/blank'
// import SidebarBlank from '@/layout/SidebarBlank'

import orderRouter from './modules/order';
import planRouter from './modules/plan';
import ticketRouter from './modules/ticket';
import auditRouter from './modules/audit';
import shoppingRouter from './modules/shopping';
import billCheckRouter from './modules/billCheck';
import billDownRouter from './modules/billDown';
import reportRouter from './modules/report';
import resourceRouter from './modules/resource';
import workRouter from './modules/work';
import systemRouter from './modules/system';
import analysesRouter from './modules/analyses';
import resourceOrderRouter from './modules/resourceOrder';
import printRouter from './modules/print';
// import printsRouter from './modules/prints'
import enterpriseRouter from './modules/enterprise';
import MemberMap from './modules/memberMap';
import offerRouter from './modules/offer';
Vue.use(VueRouter);
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  name: 'redirect',
  children: [{
    path: '/redirect/:path*',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/redirect/index'))),
    meta: {
      auth: true
    }
  }]
}, {
  path: '/login',
  name: 'login',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/index'))),
  hidden: true
}, {
  path: '/auth-redirect',
  name: 'auth-redirect',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/authRedirect'))),
  meta: {
    auth: true
  },
  hidden: true
}, {
  path: '/404',
  name: '404',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/error/404'))),
  meta: {
    auth: true
  },
  hidden: true
}, {
  path: '/401',
  name: '401',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/error/401'))),
  hidden: true
}, {
  path: '/password',
  name: 'password',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/error/password'))),
  hidden: true
}, {
  path: '/sign-ticket',
  name: 'signTicket',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/signTicket/index'))),
  meta: {
    title: 'signTicketBill',
    auth: true
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  name: 'home',
  children: [{
    path: '/dashboard',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/dashboard/index'))),
    name: 'dashboard',
    meta: {
      title: 'dashboard',
      icon: 'icon-dashboard',
      affix: true,
      auth: true
    }
  }, {
    path: '/dashboard/case',
    name: 'case',
    redirect: '/dashboard/case/complaint',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/case/index'))),
    meta: {
      title: 'case',
      icon: 'icon-huizongshenqingdanguanli',
      auth: true
    },
    children: [{
      path: '/dashboard/case/complaint',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/case/complaint/index'))),
      name: 'caseComplaint',
      meta: {
        title: 'caseComplaint',
        icon: 'icon-huizongshenqingdanguanli'
      }
    }, {
      path: '/dashboard/case/praise',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/case/praise'))),
      name: 'casePraise',
      meta: {
        title: 'casePraise',
        icon: 'icon-huizongshenqingdanguanli'
      }
    }]
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  name: 'profile',
  hidden: true,
  children: [{
    path: 'index',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/profile/index'))),
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user'
    }
  }]
}, printRouter, MemberMap];
export const localRoute = [orderRouter, planRouter, ticketRouter, auditRouter, shoppingRouter, billCheckRouter, billDownRouter, reportRouter, resourceRouter, workRouter, systemRouter, resourceOrderRouter, analysesRouter, offerRouter, enterpriseRouter
// printsRouter,
];
export const asyncRoutes = [...localRoute, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
const createRouter = () => new VueRouter({
  mode: 'history',
  // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;