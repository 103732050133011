import { dictionaryMixin, searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { SELECTOR } from '@/config/select';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, SelectOutCustom } from '@/components/common';
export default {
  components: {
    SelectGuide,
    SelectLine,
    SelectProduct,
    SelectStaff,
    SelectOutCustom
  },
  mixins: [searchMixin, dictionaryMixin],
  props: {
    search: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sendDate: [],
      endDate: [],
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        ...this.search
      },
      payType: [{
        value: '',
        label: '全部'
      }, ...SELECTOR.payType]
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews'
    }),
    getTagView() {
      return this.visitedViews.find(tag => tag.path === this.$route.path);
    },
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startTripDateS,
          startTripDateE,
          endTripDateS,
          endTripDateE,
          orderDateS,
          orderDateE,
          checkAccountTimeS,
          checkAccountTimeE
        } = data;
        this.sendDate = [startTripDateS, startTripDateE];
        this.endDate = [endTripDateS, endTripDateE];
        this.orderDate = [orderDateS, orderDateE]; // 订单日期
        this.checkBillDate = [checkAccountTimeS, checkAccountTimeE];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    onSelectPayTypa(val) {
      let {
        value,
        label
      } = val;
      this.searchParams.payType = value ? value.toUpperCase() : '';
      this.searchParams.payTypeLabel = label;
    },
    changeStarTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeEndTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.endTripDateS = starDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeOrderDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.orderDateS = starDate;
      this.searchParams.orderDateE = endDate;
    },
    changeCheckDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.checkAccountTimeS = starDate;
      this.searchParams.checkAccountTimeE = endDate;
    },
    // 搜索
    handleSearch(typeFlags) {
      let {
        path
      } = this.$route;
      const search = this.searchParams;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search
      });
      if (!typeFlags) {
        // 搜索的时候重置分页
        this.$store.dispatch('tagsView/appendVisiteViewPage', {
          path,
          page: 1,
          size: this.searchParams.pageSize
        });
      }
      this.$emit('on-reload', search);
    },
    handleExcel() {
      this.$parent.exporttExcel(this.searchParams);
    }
  }
};