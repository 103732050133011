import "core-js/modules/es.array.reduce.js";
import { tableMixin } from '@/mixin';
import { decimalSplit } from '@/utils/index';
export default {
  components: {},
  mixins: [tableMixin],
  props: {
    yearAdultCountTag: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    decimal() {
      return item => {
        return decimalSplit(item * 100) + '%';
      };
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   const windowHeight = document.getElementsByClassName('el-table ')[0]
    //     .clientHeight
    // })
  },
  methods: {
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (index > 3 && !values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = decimalSplit(sums[index]);
          // 人数完成率
          if (index === 6) {
            if (sums[4] != 0 && sums[5] != 0) {
              sums[index] = decimalSplit(sums[5] / sums[4] * 100) + '%';
            }
            if (sums[4] == 0) {
              sums[index] = '100%';
            }
            if (sums[5] == 0) {
              sums[index] = '0%';
            }
          }
          // 人数年度完成率
          if (index === 7) {
            if (this.yearAdultCountTag != 0 && sums[5] != 0) {
              sums[index] = decimalSplit(sums[5] / this.yearAdultCountTag * 100) + '%';
            }
            if (this.yearAdultCountTag == 0) {
              sums[index] = '100%';
            }
            if (sums[5] == 0) {
              sums[index] = '0%';
            }
          }
          // 营业额完成率
          if (index === 11) {
            if (sums[8] != 0 && sums[9] != 0) {
              sums[index] = decimalSplit(sums[9] / sums[8] * 100) + '%';
            }
            if (sums[8] == 0) {
              sums[index] = '100%';
            }
            if (sums[9] == 0) {
              sums[index] = '0%';
            }
          }
          // 毛利完成率
          if (index === 15) {
            if (sums[12] != 0 && sums[13] != 0) {
              sums[index] = decimalSplit(sums[13] / sums[12] * 100) + '%';
            }
            if (sums[12] == 0) {
              sums[index] = '100%';
            }
            if (sums[13] == 0) {
              sums[index] = '0%';
            }
          }
        }
      });
      return sums;
    }
  }
};