import "core-js/modules/es.array.push.js";
import handleClipboard from '@/utils/clipboard';
import { BtnLink } from '@/components/common';
export default {
  name: 'TripNumberBase',
  components: {
    BtnLink
  },
  props: {
    isLink: {
      type: Boolean,
      default: true
    },
    linkPath: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      timer: null,
      show: false,
      text: '',
      styleObj: {}
    };
  },
  computed: {
    getStyleObj() {
      return this.styleObj;
    }
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    hide() {
      this.timer = setTimeout(() => {
        this.show = false;
      }, 1000);
    },
    linkDetail() {
      if (this.linkPath) {
        this.$router.push({
          path: this.linkPath
        });
      }
      this.$emit('on-click');
    },
    handleCopy(event) {
      handleClipboard(this.text, event);
    },
    onMouseEnter() {
      clearTimeout(this.timer);
      this.show = true;
      const el = this.$refs.myPop.firstChild;
      const offsetTop = this.$refs.myPop.offsetTop;
      const pWidth = el.offsetParent.offsetWidth;
      const text = el.innerText;
      this.text = text.trim();
      this.$nextTick(() => {
        const obj = el.getBoundingClientRect();
        const {
          width
        } = obj;
        const w = Math.ceil(width);
        const pHalf = pWidth / 2;
        this.styleObj = {
          top: Math.ceil(offsetTop) + 'px',
          left: w < pHalf ? (pWidth - w) / 2 + w + 'px' : Math.min(w, pWidth) + 'px'
        };
      });
    },
    onMouseLeave() {
      clearTimeout(this.timer);
      this.show = false;
    },
    onCopyMouseEnter() {
      clearTimeout(this.timer);
      this.show = true;
    }
  }
};