export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    infoList: {
      type: Array,
      required: true
    },
    len: {
      type: Number,
      default: 3
    }
  },
  data: () => ({}),
  computed: {
    getLen() {
      return Math.ceil(this.infoList.length / this.len);
    },
    splitData() {
      return index => this.infoList.slice(index * this.len, (index + 1) * this.len);
    }
  }
};