import { ENTERPRISE_LINK_KEY, defaultEnterprise } from '../config';
import clipboard from '@/utils/clipboard';
export default {
  data() {
    return {
      form: {
        value: ''
      },
      rules: {
        value: [{
          required: true,
          message: '邀请链接必填'
        }]
      }
    };
  },
  computed: {
    data() {
      return this.$store.getters['system/buttJoint/configData'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchSysConfig();
        } else {
          this.processRuleData(d);
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取对接配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params);
    },
    processRuleData(data) {
      const findItem = data.find(it => it.code === ENTERPRISE_LINK_KEY);
      if (!findItem) return;
      const {
        id,
        value
      } = findItem;
      this.configId = id;
      this.form = {
        ...this.form,
        id,
        value
      };
    },
    processOption() {
      return {
        ...defaultEnterprise(),
        ...this.form
      };
    },
    onCopy(event) {
      clipboard(this.form.value, event);
    },
    onSave() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const opt = this.processOption();
        this.$store.dispatch('system/buttJoint/setConfig', [opt]);
      });
    }
  }
};