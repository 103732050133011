import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceRestaurant/findList',
    method: 'get',
    params
  });
}

// 列表 不分页（集团）
export function fetchGroupList(params) {
  return request({
    url: '/resource/resourceRestaurant/group-objects',
    method: 'get',
    params
  });
}

// 列表
export function fetchRestaurant(params) {
  return request({
    url: '/resource/resourceRestaurant/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateRestaurant(data) {
  return request({
    url: '/resource/resourceRestaurant/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteRestaurant(id) {
  return request({
    url: '/resource/resourceRestaurant/del',
    method: 'post',
    data: {
      id
    }
  });
}