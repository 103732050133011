import { Restaurant } from '@/components/common/Order';
import { planEditRestaurant } from '@/config/plan';
import { planIsOrderInfoMixin } from '@/mixin';
export default {
  components: {
    Restaurant
  },
  mixins: [planIsOrderInfoMixin],
  data() {
    return {
      columnData: planEditRestaurant
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};