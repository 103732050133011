import "core-js/modules/es.array.push.js";
import { orderScenicDetail } from '@/config/order';
import { getSummariesMixin, reloadMixin } from '@/mixin';
import { multiplyNumber, processAdd } from '@/utils/number';
export default {
  mixins: [getSummariesMixin, reloadMixin],
  data() {
    return {
      columnData: orderScenicDetail,
      tableData: [],
      statisticsKeys: ['adultCount', 'adultFreeCount', 'childCount', 'childFreeCount', 'accompanyCount', 'otherFee', 'rowTotal']
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchScenic', {
        id
      }).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data);
      });
    },
    formatData(list) {
      if (!list) return [];
      const res = [];
      list.forEach(it => {
        const {
          scenicOTickets
        } = it;
        scenicOTickets.forEach(s => {
          const {
            otherFee
          } = s;
          const {
            price,
            adultCount,
            adultFreeCount
          } = s;
          const {
            childPrice,
            childCount,
            childFreeCount
          } = s;
          const adultMoney = multiplyNumber(price - adultFreeCount, adultCount);
          const childMoney = multiplyNumber(childCount - childFreeCount, childPrice);
          const rowTotal = processAdd(adultMoney, childMoney, otherFee || 0);
          res.push({
            rowId: it.id,
            rowTotal,
            ...it,
            ...s
          });
        });
      });
      return res;
    }
  }
};