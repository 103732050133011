// 客户管理
export const Columns = [{
  attrs: {
    prop: 'name',
    label: '客户名称',
    sortable: true
  }
}, {
  attrs: {
    prop: '$type',
    label: '客户类型'
  }
}, {
  attrs: {
    prop: 'customerType',
    label: '客户备注'
  }
}, {
  attrs: {
    prop: 'codeName',
    label: '客户代码'
  }
}, {
  attrs: {
    prop: 'creditMoney',
    label: '授信额度',
    'min-width': 90
  },
  slot: 'creditMoney'
}, {
  attrs: {
    prop: 'balance',
    label: '授信余额'
  }
}, {
  attrs: {
    prop: 'tmpCreditMoney',
    label: '临时额度'
  }
}, {
  attrs: {
    prop: 'country',
    label: '国家'
  }
}, {
  attrs: {
    prop: 'province',
    label: '省份'
  }
}, {
  attrs: {
    prop: 'info',
    label: '联系人信息'
  },
  children: [{
    attrs: {
      prop: 'contact',
      label: '姓名',
      'min-width': 120
    },
    slot: 'contact'
  }, {
    attrs: {
      prop: 'mobilePhone',
      label: '手机',
      'min-width': 100
    },
    slot: 'mobilePhone'
  }, {
    attrs: {
      prop: 'telphone',
      label: '座机',
      'min-width': 90
    },
    slot: 'telphone'
  }]
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理'
  }
}, {
  attrs: {
    prop: 'customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'customerAreaService',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态'
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
export const resourceCustomContactBase = [{
  attrs: {
    prop: 'contact',
    label: '联系人姓名'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'IDCard',
    label: '身份证号'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  }
}];
export const resourceCustomContact = [{
  attrs: {
    prop: 'contact',
    label: '联系人姓名'
  },
  slot: 'contact'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'IDCard',
    label: '身份证号'
  },
  slot: 'IDCard'
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  },
  slot: 'telphone'
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  },
  slot: 'faxNumber'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
export const resourceCustomContactDetail = resourceCustomContactBase;