var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("input", {
    ref: "excel-upload-input",
    staticClass: "excel-upload-input",
    attrs: {
      type: "file",
      accept: ".xlsx, .xls"
    },
    on: {
      change: _vm.handleClick
    }
  }), _c("div", {
    staticClass: "drop",
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragover,
      dragenter: _vm.handleDragover
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("dropExcel")) + " "), _c("el-button", {
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      loading: _vm.loading,
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.handleUpload
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("Browse")) + " ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };