var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "itinerary-dialog",
    attrs: {
      title: "编辑行程",
      width: "1160px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "content"
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "form-wrapper"
    }, [_c("div", {
      staticClass: "input-field"
    }, [_c("h3", {
      staticClass: "title flex",
      class: [(index + 1) % 2 === 0 ? "baby-blue" : "pale-green"]
    }, [_c("span", [_vm._v("DAY" + _vm._s(index + 1))]), index == 0 ? _c("CheckboxBase", {
      attrs: {
        label: "接团"
      },
      on: {
        change: function ($event) {
          return _vm.handleChange("isPick", item, $event);
        }
      },
      model: {
        value: item.isPick,
        callback: function ($$v) {
          _vm.$set(item, "isPick", $$v);
        },
        expression: "item.isPick"
      }
    }) : _vm._e(), index == _vm.tableData.length - 1 ? _c("CheckboxBase", {
      attrs: {
        label: "送团"
      },
      on: {
        change: function ($event) {
          return _vm.handleChange("isSend", item, $event);
        }
      },
      model: {
        value: item.isSend,
        callback: function ($$v) {
          _vm.$set(item, "isSend", $$v);
        },
        expression: "item.isSend"
      }
    }) : _vm._e()], 1)]), _c("div", {
      staticClass: "input-field"
    }, [_c("div", {
      staticClass: "item-box scenic-box"
    }, [_c("div", {
      staticClass: "flex title-align-center"
    }, [_c("h3", [_vm._v("景区")]), _c("span", {
      staticClass: "pr5"
    }), _c("el-button", {
      attrs: {
        type: "text"
      },
      on: {
        click: function ($event) {
          return _vm.addScenic(item, "PAID");
        }
      }
    }, [_vm._v(" 添加 ")])], 1), item.scenics.length ? [_vm._l(item.scenics, function (scene, idx) {
      return [!scene.isDelete ? _c("div", {
        key: idx,
        staticClass: "scenic-item flex"
      }, [scene.scenicType == "PAID" || !scene.scenicType ? [_c("SelectScenic", {
        attrs: {
          label: "",
          all: false
        },
        on: {
          "on-select": function ($event) {
            return _vm.handleSelectScenic(idx, item, $event);
          }
        },
        model: {
          value: scene.scenicName,
          callback: function ($$v) {
            _vm.$set(scene, "scenicName", $$v);
          },
          expression: "scene.scenicName"
        }
      }), _c("InputBase", {
        staticStyle: {
          flex: "0 0 70px"
        },
        attrs: {
          label: "排序",
          clearable: false
        },
        model: {
          value: scene.orderNumber,
          callback: function ($$v) {
            _vm.$set(scene, "orderNumber", $$v);
          },
          expression: "scene.orderNumber"
        }
      }), _c("div", {
        staticClass: "btn-box"
      }, [_c("el-button", {
        attrs: {
          type: "danger",
          icon: "el-icon-delete",
          plain: ""
        },
        on: {
          click: function ($event) {
            return _vm.deleteScene(scene, idx);
          }
        }
      })], 1)] : _vm._e()], 2) : _vm._e()];
    })] : _vm._e()], 2), _c("div", {
      staticClass: "item-box shop-box"
    }, [_c("div", {
      staticClass: "flex title-align-center"
    }, [_c("h3", [_vm._v("购物店")]), _c("span", {
      staticClass: "pr5"
    }), _c("el-button", {
      attrs: {
        type: "text"
      },
      on: {
        click: function ($event) {
          return _vm.addShop(item);
        }
      }
    }, [_vm._v(" 添加 ")])], 1), item.shops.length ? [_vm._l(item.shops, function (shop, idx) {
      return [!shop.isDelete ? _c("div", {
        key: idx,
        staticClass: "shop-item flex"
      }, [_c("SelectShop", {
        attrs: {
          label: ""
        },
        on: {
          "on-select": function ($event) {
            return _vm.handleSelectShop(idx, item, $event);
          }
        },
        model: {
          value: shop.shopId,
          callback: function ($$v) {
            _vm.$set(shop, "shopId", $$v);
          },
          expression: "shop.shopId"
        }
      }), _c("InputBase", {
        staticStyle: {
          flex: "0 0 70px"
        },
        attrs: {
          label: "排序",
          clearable: false
        },
        model: {
          value: shop.orderNumber,
          callback: function ($$v) {
            _vm.$set(shop, "orderNumber", $$v);
          },
          expression: "shop.orderNumber"
        }
      }), _c("div", {
        staticClass: "btn-box"
      }, [_c("el-button", {
        attrs: {
          type: "danger",
          icon: "el-icon-delete",
          plain: ""
        },
        on: {
          click: function ($event) {
            return _vm.deleteShop(shop, idx);
          }
        }
      })], 1)], 1) : _vm._e()];
    })] : _vm._e()], 2), _c("div", {
      staticClass: "item-box restaurant-box"
    }, [_c("h3", [_vm._v("餐厅")]), _c("div", {
      staticClass: "check-wrapper"
    }, [_c("div", {
      staticClass: "check-box"
    }, [_c("CheckboxBase", {
      attrs: {
        label: "早"
      },
      on: {
        change: function ($event) {
          return _vm.handleChange("breakfast", item, $event);
        }
      },
      model: {
        value: item.breakfast,
        callback: function ($$v) {
          _vm.$set(item, "breakfast", $$v);
        },
        expression: "item.breakfast"
      }
    })], 1), _c("div", {
      staticClass: "check-box"
    }, [_c("CheckboxBase", {
      attrs: {
        label: "中"
      },
      on: {
        change: function ($event) {
          return _vm.handleChange("lunch", item, $event);
        }
      },
      model: {
        value: item.lunch,
        callback: function ($$v) {
          _vm.$set(item, "lunch", $$v);
        },
        expression: "item.lunch"
      }
    })], 1), _c("div", {
      staticClass: "check-box"
    }, [_c("CheckboxBase", {
      attrs: {
        label: "晚"
      },
      on: {
        change: function ($event) {
          return _vm.handleChange("dinner", item, $event);
        }
      },
      model: {
        value: item.dinner,
        callback: function ($$v) {
          _vm.$set(item, "dinner", $$v);
        },
        expression: "item.dinner"
      }
    })], 1)])]), _c("div", {
      staticClass: "item-box hotel-box"
    }, [_c("h3", [_vm._v("酒店")]), _c("div", {
      staticClass: "hotel-item"
    }, [_c("SelectDictionary", {
      attrs: {
        label: "区域",
        code: _vm.hotelRegion
      },
      on: {
        "on-select": function ($event) {
          return _vm.selecthotelRegion(item, $event);
        }
      },
      model: {
        value: item.area,
        callback: function ($$v) {
          _vm.$set(item, "area", $$v);
        },
        expression: "item.area"
      }
    })], 1), _c("div", {
      staticClass: "hotel-item"
    }, [_c("SelectDictionary", {
      attrs: {
        label: "标准",
        code: _vm.hotelStandard
      },
      on: {
        "on-select": function ($event) {
          return _vm.selectHotelStandard(item, $event);
        }
      },
      model: {
        value: item.standard,
        callback: function ($$v) {
          _vm.$set(item, "standard", $$v);
        },
        expression: "item.standard"
      }
    })], 1), false ? _c("div", {
      staticClass: "hotel-item"
    }, [_c("SelectHotel", {
      attrs: {
        label: "指定"
      },
      on: {
        "on-select": function ($event) {
          return _vm.selectHotelName(item, $event);
        }
      },
      model: {
        value: item.hotelId,
        callback: function ($$v) {
          _vm.$set(item, "hotelId", $$v);
        },
        expression: "item.hotelId"
      }
    })], 1) : _vm._e()])]), _c("div", {
      staticClass: "input-field"
    }, [_c("div", {
      staticClass: "item-box scenic-box"
    }, [_c("div", {
      staticClass: "flex"
    }, [_c("h3", [_vm._v("免费景区")]), _c("span", {
      staticClass: "pr5"
    }), _c("el-button", {
      attrs: {
        type: "text"
      },
      on: {
        click: function ($event) {
          return _vm.addScenic(item, "FREE");
        }
      }
    }, [_vm._v(" 添加 ")])], 1), item.scenics.length ? _vm._l(item.scenics, function (scene, idx) {
      return _c("div", {
        key: idx,
        staticClass: "scenic-item flex"
      }, [scene.scenicType == "FREE" && !scene.isDelete ? [_c("SelectScenicFree", {
        attrs: {
          label: ""
        },
        on: {
          "on-select": function ($event) {
            return _vm.handleSelectScenic(idx, item, $event);
          }
        },
        model: {
          value: scene.scenicName,
          callback: function ($$v) {
            _vm.$set(scene, "scenicName", $$v);
          },
          expression: "scene.scenicName"
        }
      }), _c("InputBase", {
        staticStyle: {
          flex: "0 0 70px"
        },
        attrs: {
          label: "排序",
          clearable: false
        },
        model: {
          value: scene.orderNumber,
          callback: function ($$v) {
            _vm.$set(scene, "orderNumber", $$v);
          },
          expression: "scene.orderNumber"
        }
      }), _c("div", {
        staticClass: "btn-box"
      }, [_c("el-button", {
        attrs: {
          type: "danger",
          icon: "el-icon-delete",
          plain: ""
        },
        on: {
          click: function ($event) {
            return _vm.deleteScene(scene, idx);
          }
        }
      })], 1)] : _vm._e()], 2);
    }) : _vm._e()], 2)]), _c("div", {
      staticClass: "input-field"
    }, [_c("div", {
      staticClass: "flex-1"
    }, [_c("h3", {
      staticStyle: {
        "margin-bottom": "10px"
      }
    }, [_vm._v(" 自定义行程 ")]), _c("InputRemark", {
      attrs: {
        label: ""
      },
      model: {
        value: item.selfScenic,
        callback: function ($$v) {
          _vm.$set(item, "selfScenic", $$v);
        },
        expression: "item.selfScenic"
      }
    })], 1)])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };