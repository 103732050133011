import payWxPng from '@/assets/pay-weChat.png';
import payZfbPng from '@/assets/pay-alipay.png';
import payYlPng from '@/assets/pay-unionpay.png';
import { resourceOrderPayInfo } from '@/config/resourceOrder';
export default {
  data() {
    return {
      payWxPng,
      payZfbPng,
      payYlPng,
      payment: 'wechat',
      tableData: [],
      columnData: resourceOrderPayInfo,
      visible: false,
      info: null
    };
  },
  computed: {
    getMoney() {
      return row => {
        const {
          count,
          freeCount,
          days,
          price
        } = row;
        return (price || 0) * (count - freeCount) * days;
      };
    }
  },
  methods: {
    show(row) {
      this.visible = true;
      this.info = row;
      this.tableData = row.rooms;
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      this.$bus.tip({
        type: 'info',
        message: '付款功能正在开发中...'
      });
    }
  }
};