import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import store from '@/store';
import { SourceTypeLabel } from '@/config/resource';
import { CashFeeType, subjectTypeLabel } from '@/config/billDown';
const getJaccardSimilarity = (string1, string2) => {
  const set1 = new Set(string1);
  const set2 = new Set(string2);
  const intersection = new Set([...set1].filter(x => set2.has(x)));
  const union = new Set([...set1, ...set2]);
  return intersection.size / union.size;
};
export const findSubject = (pathName, payType) => {
  const data = store.getters['system/accounting/subjectData'];
  const subType = subjectTypeLabel[CashFeeType[payType]];
  const firstSub = data.find(it => it.name.indexOf(subType) > -1);
  const def = {
    name: firstSub.name,
    subjectId: firstSub.id
  };
  if (firstSub) {
    const {
      subSubjects
    } = firstSub;
    const name = SourceTypeLabel[pathName];
    if (!name) return def;

    // ==判断
    let subItem = subSubjects.find(it => name == it.name);

    // 找相似
    if (!subItem) {
      subItem = subSubjects.reduce((max, obj) => {
        const prev = getJaccardSimilarity(max.name, name);
        const cur = getJaccardSimilarity(obj.name, name);
        return cur > prev ? obj : max;
      });
    }
    return {
      ...def,
      subName: subItem.name,
      subId: subItem.id
    };
  }
  return def;
};