// 发团预定安排 地接
import request from '@/utils/request';

// 查询外转地接订单
export function fetchReception(planId) {
  return request({
    url: '/order/out/listByPlanId',
    method: 'get',
    params: {
      planId
    }
  });
}