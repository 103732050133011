import "core-js/modules/es.array.push.js";
import { planEditReceptionRefundEdit } from '@/config/plan';
import { SelectDictionary } from '../index';
import TopTitle from '../TopTitle.vue';
import { mapGetters } from 'vuex';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin } from '@/mixin';
import propMixin from './propMixin';
const defaultState = () => ({
  title: '',
  type: '',
  typeId: '',
  price: '',
  count: 1,
  total: 0
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  mixins: [dictionaryMixin, propMixin],
  data() {
    return {
      refundType: codeType.refundType,
      columnData: planEditReceptionRefundEdit,
      type: 'subsidyList' // 返款列表，对应接口字段
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    })
  },
  methods: {
    // 新增一条
    handlePlus() {
      this.rowId++;
      const {
        adultCount: count
      } = this.currentOrder;
      const {
        label: type
      } = this.getDictionaryDefVal(this.refundType);
      this.tableData.push({
        ...defaultState(),
        isCustom: true,
        type,
        count
      });
    },
    selectType(row, val) {
      const {
        id,
        label
      } = val;
      row.type = label;
      row.typeId = id;
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price
      } = obj;
      let total = count * price;
      return total;
    },
    // 修改单行金额
    changeInput(row) {
      row.total = this.calcRowItem(row);
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      if (isCustom) {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      } else {
        this.$bus.open(() => {
          const loading = this.$bus.loading();
          this.$store.dispatch('order/reception/deleteRefundItem', {
            id,
            index: this.index,
            type: this.type
          }).then(() => {
            loading.close();
          }).catch(() => {
            loading.close();
          });
        });
      }
    },
    updateData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/reception/changeReception', {
          type: this.type,
          data: this.tableData,
          index: this.index
        }).then(() => {
          return resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    }
  }
};