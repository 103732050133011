var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-autocomplete", _vm._g(_vm._b({
    directives: [{
      name: "loadmore",
      rawName: "v-loadmore",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "fetch-suggestions": _vm.querySearch
    },
    on: {
      select: _vm.handleSelect,
      change: _vm.handleClear
    }
  }, "el-autocomplete", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };