import { inputMixin, selectListMixin } from '@/mixin';
import { formatDepartmentParams } from '@/utils';
export default {
  name: 'SelectStaff',
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'system/staff/fetch',
    params: {}
  }),
  computed: {
    data() {
      return this.$store.getters['system/staff/staffData'];
    },
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  watch: {
    roleInfo: {
      handler: function (data) {
        if (!data) return;
        this.params = formatDepartmentParams(data, 'Id');
        this.fetchData(this.params);
      },
      immediate: true
    }
  },
  methods: {
    lazyLoad() {
      if (this.data) return;
      this.fetchData({
        ...this.params
      });
    }

    // getParams(dparea) {
    //   const {
    //     departmentId,
    //     subDepartmentId,
    //     grandsonDepartmentId,
    //   } = getUserData()

    //   const dpParams = {
    //     departmentId: { departmentId },
    //     subDepartment: {
    //       departmentId: departmentId,
    //       subDepartmentId: subDepartmentId,
    //     },
    //     grandsonDepartment: {
    //       departmentId: departmentId,
    //       subDepartmentId: subDepartmentId,
    //       grandsonDepartmentId: grandsonDepartmentId,
    //     },
    //   }

    //   return dpParams[dparea]
    // },

    // processParams(data) {
    //   const { departmentPrivilege } = data
    //   this.params = formatDepartmentParams(departmentPrivilege, 'Id')
    // },
  }
};