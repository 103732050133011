var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "酒店图片",
      width: "1080px",
      visible: _vm.visible,
      "has-foot": false,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.imagesData ? _c("div", {
    staticClass: "image-preview"
  }, [_c("div", {
    staticClass: "big-image"
  }, [_c("el-carousel", {
    attrs: {
      trigger: "click",
      interval: 5000,
      "initial-index": _vm.curIndex,
      "indicator-position": "none",
      height: "600px"
    },
    on: {
      change: _vm.changeCarouselItem
    }
  }, _vm._l(_vm.imagesData.list, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("div", {
      staticClass: "images-box"
    }, [_c("img", {
      staticClass: "images",
      attrs: {
        src: item.path,
        alt: "index"
      }
    })])]);
  }), 1), _c("div", {
    staticClass: "clearfix"
  }, [_c("div", {
    staticClass: "name fl"
  }, [_vm._v(" 外观 ")]), _c("div", {
    staticClass: "number fr"
  }, [_c("span", [_vm._v(_vm._s(_vm.acitveIndex))]), _vm._v(" /" + _vm._s(_vm.imagesData.list.length) + "张 ")])])], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };