import FormFleet from './FormFleet';
import TableList from './TableList';
import { BtnCreate, DialogDetail, DialogAgreement, DialogAgreementItem2, DialogLog, DialogBindBusiness, SelectDictionary } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceFleet, TYPES } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  components: {
    BtnCreate,
    TableList,
    FormFleet,
    DialogLog,
    DialogBindBusiness,
    DialogDetail,
    DialogAgreement,
    DialogAgreementItem2,
    SelectDictionary
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceFleet,
    tableData: [],
    total: 0,
    searchParams: {
      name: ''
    },
    agreement: {
      content: '',
      price: '',
      remarks: ''
    },
    resourceId: '',
    resType: TYPES.bus
  }),
  computed: {
    fleetData() {
      return this.$store.getters['resource/fleet/fleetDataOrigin'];
    }
  },
  watch: {
    fleetData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/fleet/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/fleet/fetchList');
    },
    onSelectArea(val) {
      this.searchParams.area = val.label;
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/fleet/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    deleteRow(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/fleet/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog(item) {
      this.$store.dispatch('app/setDialogLoading', false);
      this.$refs.formRef.show(item);
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showCreateDialog(copy);
    },
    showTableDetail(scope) {
      this.$store.dispatch('resource/fleet/fetchImage', scope.row.id);
      this.$refs.detailRef.show(scope.row, {
        title: '车队',
        name: '车队名称'
      });
    },
    showAgreement(scope) {
      const resourceId = scope.row.id;
      this.resourceId = resourceId;
      this.fetchAgreement();
      this.$refs.agreementRef.show(scope.row, TYPES.bus);
    },
    fetchAgreement() {
      this.$store.dispatch('resource/fleet/fetchAgreement', this.resourceId);
    },
    showAgreementItem(contracId) {
      this.$refs.agreementItemRef.show(contracId, TYPES.bus);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/fleet/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};