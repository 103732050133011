export default {
  data() {
    return {
      visible: false,
      noticeOpts: {
        title: '',
        content: '',
        isPopWindow: '',
        authAreaType: ''
      }
    };
  },
  computed: {
    getTitle() {
      return this.noticeOpts.title;
    }
  },
  // watch: {
  //   data: {
  //     handler: function(val) {
  //       if (!val) return
  //       this.noticeOpts = val
  //     },
  //   },
  // },

  methods: {
    show(data) {
      this.visible = true;
      this.noticeOpts = data;
    },
    hide() {
      this.visible = false;
    },
    handleCancel() {
      this.resetForm();
      this.hide();
    },
    resetForm() {
      this.noticeOpts = {};
    }
  }
};