var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "orders",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "totalQuoteGrossProfit",
      fn: function ({
        scope
      }) {
        return [_c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.onLink(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalQuoteGrossProfit) + " ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };