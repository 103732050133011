import "core-js/modules/es.array.push.js";
import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
// import { hasSubjectSelectData } from '@/utils/requestSelectData'

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '会计科目'
    },
    hasAll: {
      type: Boolean,
      default: false
    },
    onlyOne: {
      type: Boolean,
      default: false
    },
    initVal: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: true,
      curVal: [],
      list: [],
      originData: []
    };
  },
  computed: {
    ...mapGetters({
      subjectData: 'system/accounting/subjectData'
    }),
    getCurVal: {
      get() {
        return this.curVal;
      },
      set() {
        this.$emit('update:value');
      }
    }
  },
  watch: {
    subjectData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.list = [...data];
        this.originData = [...data];
        this.initialVal(this.initVal);
      },
      immediate: true
    }
  },
  mounted() {
    this.list = this.list.map(it => {
      const subSubjects = this.disableChild(it.children);
      return {
        ...it,
        children: subSubjects
      };
    });
  },
  methods: {
    handleClear() {
      this.$emit('on-select', {});
    },
    fetchData() {
      this.$store.dispatch('system/accounting/fetchListLevel');
    },
    initialVal(item) {
      if (!item) return;
      let {
        psubjectId,
        subjectId,
        subId
      } = item;
      if (psubjectId > 0) {
        this.curVal.push(+psubjectId);
      }
      if (subjectId > 0) {
        this.curVal.push(+subjectId);
      }
      if (subId > 0) {
        this.curVal.push(+subId);
      }
    },
    handleChange(val) {
      const [d1, d2] = val;
      const res = {};
      this.list.forEach(it => {
        if (it.id == d1) {
          res['name'] = it.label;
          res['id'] = d1;
          if (d2) {
            it.children.forEach(c => {
              if (c.id == d2) {
                res['subName'] = c.label;
                res['subId'] = d2;
              }
            });
          }
        }
      });
      this.$emit('on-select', res);
    },
    disableChild(list) {
      if (!list) return;
      return list.map(it => {
        it.disabled = this.onlyOne;
        if (it.children) {
          this.disableChild(it.children);
        }
        return it;
      });
    }
  }
};