import "core-js/modules/es.array.push.js";
import DialogAgreementEdit from './DialogAgreementEdit';
import { resourceTypeList } from '../config';
import { ColumnsAgreement as Columns } from '../configTable';
import { mapGetters } from 'vuex';
export default {
  name: 'DialogAgreement',
  components: {
    DialogAgreementEdit
  },
  data() {
    return {
      Columns,
      visible: false,
      form: {},
      resourceObj: {},
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      curAgreement: 'resource/common/curAgreement',
      agreementItem: 'resource/common/agreementItem'
    }),
    getResourceContent() {
      return list => {
        if (!list || !list.length) return;
        const result = [];
        list.forEach(it => {
          let value = '';
          resourceTypeList.forEach(item => {
            const hasSymble = item.symbol ? '%' : item.label !== 'contentType' ? '元' : '';
            value += `${item.text}:
            ${it[item.label]} <span style="margin-right:10px"> ${hasSymble} </span>`;
          });
          result.push(value);
        });
        return result;
      };
    }
  },
  watch: {
    agreementItem: [{
      handler: function (val) {
        this.form = val;
      },
      immediate: true
    }],
    curAgreement: {
      handler: function (data) {
        if (!data) return;
        const {
          list
        } = data;
        this.tableData = list;
      },
      immediate: true
    }
  },
  methods: {
    show(row) {
      this.visible = true;
      this.resourceObj = row;
      this.fetchData(row.id);
    },
    hide() {
      this.visible = false;
      this.updateUrl = '';
      this.form = {};
    },
    fetchData(params) {
      this.$store.dispatch('resource/shopping/fetchAgreement', params);
    },
    reload() {
      this.fetchData(this.resourceObj.id);
    },
    changeDate(val) {
      const [start, end] = val;
      this.form.startTime = start;
      this.form.endTime = end;
      this.changeAgreement(this.form);
    },
    handleChange(type, val) {
      this.form[type] = val;
      this.changeAgreement(this.form);
    },
    handleContent(row) {
      // 显示当前协议列表
      this.$parent.showAgreementItem(row.id);
    },
    handleAdd() {
      const {
        agreementEditRef
      } = this.$refs;
      agreementEditRef.show({
        resourceId: this.resourceObj.id
      });
    },
    handleEdit(row) {
      const copy = {
        ...row
      };
      copy.expire = [copy.startTime, copy.endTime];
      // 弹框修改
      const {
        agreementEditRef
      } = this.$refs;
      agreementEditRef.show(copy);
    },
    handleDelete(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('resource/common/deleteAgreement', id);
      });
    },
    changeStatus(row) {
      this.createAgreement(row);
    },
    handleUpdate(form) {
      this.form = form;
      if (!this.validateForm()) return;
      // 因为新增加的数据是直接插入到store里到，并没有重新获取数据，所以ID是没有，需要重新添加一下
      const opts = {
        ...this.form,
        ...this.filterOpts()
      };
      this.createAgreement(opts);
    },
    handleRemove() {
      this.resetForm();
    },
    handleCreate() {
      if (!this.validateForm()) return;
      const opts = this.filterOpts();
      this.createAgreement(opts);
    },
    filterOpts() {
      const unit = this.resourceObj.name;
      const {
        id: resourceId,
        travelAgencyId,
        resourceType
      } = this.resourceObj;
      const opts = {
        contractPrices: [],
        resourceId,
        resourceType,
        travelAgencyId,
        unit,
        ...this.agreementItem
      };
      delete opts.expire;
      return opts;
    },
    createAgreement(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/common/createOrUpdateAgreement', opts).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.resetForm();
        this.$emit('reload');
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    changeAgreement(form = {}) {
      this.$store.dispatch('resource/common/editAgreement', form);
    },
    validateForm() {
      const {
        name,
        expire
      } = this.form;
      if (!name) {
        this.$bus.tip({
          type: 'warning',
          message: '协议名称不能为空'
        });
        return false;
      }
      if (!expire) {
        this.$bus.tip({
          type: 'warning',
          message: '协议日期不能为空'
        });
        return false;
      }
      return true;
    },
    resetForm() {
      this.changeAgreement();
    }
  }
};