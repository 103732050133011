import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TopTitle from '@/views/print/components/TopTitle';
import { exportTable, calculateAgeByBirth } from '@/utils';
import { SELECTOR } from '@/config';
const tableHead = [{
  content: 'age',
  label: '年龄',
  span: 1
}, {
  content: 'name',
  label: '姓名',
  span: 1
}, {
  content: 'cardType',
  label: '证件类型',
  span: 1,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号',
  span: 2
}, {
  content: 'ticket',
  label: '全/半票',
  span: 2
}, {
  content: 'status',
  label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）',
  span: 2
}, {
  content: 'country',
  label: '国籍/地区（护照等国际证件时必填）',
  span: 2
}, {
  content: 'fee',
  label: '免票',
  span: 1
}];
const AgeMap = {
  adult: 15,
  half: 14,
  fee: 7
};
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      AgeMap,
      data: null,
      tableHead,
      filename: '团队船票名单表',
      totalObj: {}
    };
  },
  computed: {
    getCardType() {
      return val => {
        if (val == '0') {
          return '身份证';
        }
        const findItem = SELECTOR.cardType.find(it => it.value == val);
        return findItem ? findItem.label : '';
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchTeamData(id);
  },
  methods: {
    // 团队
    fetchTeamData(id) {
      this.$store.dispatch('order/print/fetchTourist', {
        planId: id
      }).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    handleData(data) {
      const list = this.formatData(data);
      this.data = list;
      this.formatTotal(list);
      this.$emit('onMounted');
    },
    formatTotal(list) {
      this.totalObj = {
        adult: getTotal(AgeMap.adult, 100),
        half: getTotal(AgeMap.fee, AgeMap.adult),
        fee: getTotal(0, AgeMap.fee)
      };
      function getTotal(minAge, maxAge) {
        return list.reduce((acc, cur) => {
          acc += cur.age >= minAge && cur.age < maxAge ? 1 : 0;
          return acc;
        }, 0);
      }
    },
    formatData(data) {
      return data.map(it => {
        const {
          cardType,
          birthday
        } = it;
        const newAge = calculateAgeByBirth(birthday);
        it.isKid = newAge < AgeMap.fee;
        it.isHalf = newAge >= AgeMap.fee && newAge < AgeMap.adult;
        it.isAdult = newAge > AgeMap.half;
        it.status = '否';
        it.ticket = it.isKid ? '免票' : it.isAdult ? '全票' : '半票';
        it.fee = it.isKid ? '免票' : '';
        it.country = cardType != 1 ? '中国' : '未知';
        it.age = newAge;
        return it;
      });
    },
    setColorStyle(XLSX, wb, sheetName, range) {
      const nums = [];
      const nums2 = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        for (let R = range.s.r; R <= range.e.r; ++R) {
          // SHEET行
          let cell = {
            c: C,
            r: R
          }; // 二维 列行确定一个单元格
          let cell_ref = XLSX.utils.encode_cell(cell); // 单元格 A1、A2
          const cur = wb.Sheets[sheetName][cell_ref];
          if (cur.v && !isNaN(cur.v) && cur.v < AgeMap.fee) {
            nums.push(R);
          }
          if (cur.v && !isNaN(cur.v) && cur.v >= AgeMap.fee && cur.v < AgeMap.adult) {
            nums2.push(R);
          }
          if (nums.includes(R)) {
            cur.s = {
              ...cur.s,
              font: {
                sz: 12,
                color: {
                  rgb: 'ff0000'
                }
              },
              fill: {
                fgColor: {
                  rgb: 'ffff00'
                }
              }
            };
          }
          if (nums2.includes(R)) {
            cur.s = {
              ...cur.s,
              font: {
                sz: 12,
                color: {
                  rgb: 'ff0000'
                }
              }
            };
          }
        }
      }
    },
    handleExport() {
      if (!this.data.length) {
        this.$bus.tip({
          type: 'warning',
          message: '无任何数据可导出!'
        });
        return;
      }
      const el = this.$refs.boardTeamRef;
      const reg = /colspan="\d"/g;
      const str = el.innerHTML.replace(reg, '');
      const node = document.createElement('div');
      node.innerHTML = str;
      exportTable(node, this.filename, this.filename, 1, this.setColorStyle);
      node.remove();
    }
  }
};