import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    handleCopy(scope) {
      this.$parent.copyProduct(scope);
    },
    toggleRelease(row) {
      this.$parent.addMallProcut(row);
    },
    handleCustom(scope) {
      this.$parent.addCustom(scope);
    },
    changeStatus(row) {
      const {
        isEnable,
        id
      } = row;
      let opts = {
        status: isEnable,
        id
      };
      this.$store.dispatch('resource/product/setStatus', opts);
    }
  }
};