var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单列表-酒店",
      width: "90%",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "plan-order-dialog"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripInfo", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("Custom", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("Contact", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "shuttle",
      fn: function ({
        scope
      }) {
        return [_c("Shuttle", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "ticketType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.ticketType) + " ")];
      }
    }, {
      key: "sellPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.sellPrice) + " ")];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }, {
      key: "settlementPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.settlementPrice) + " ")];
      }
    }, {
      key: "adultCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " ")];
      }
    }, {
      key: "adultFreeCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultFreeCount) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.otherFee) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope);
            }
          }
        }, [_vm._v(" 移除 ")])];
      }
    }])
  })], 1), _c("FooterBar", {
    attrs: {
      "table-data": _vm.tableData
    },
    on: {
      "on-dialog": _vm.handleOrderDialog,
      "on-save": _vm.handleSave,
      "on-cancel": _vm.hide
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };