import { resourcePayType, resourcePayTypeLabel } from '@/config/resourceOrder';
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radioVal: resourcePayType.cash
    };
  },
  computed: {
    getOweMoney() {
      const data = this.data;
      return data ? (data.totalPay || 0) - (data.totalPayed || 0) : 0;
    },
    getPayTypeLabel() {
      return resourcePayTypeLabel[this.radioVal.toLowerCase()];
    }
  },
  watch: {
    data: {
      handler: function (info) {
        if (!info) return;
        this.radioVal = info.payType;
      }
    }
  }
};