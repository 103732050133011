import { customerInfoMixin } from '@/mixin';
import { OutOPUsers } from '@/components/common';
import { hasCustomeAuth } from '@/utils';
export default {
  components: {
    OutOPUsers
  },
  mixins: [customerInfoMixin],
  props: {
    curOrder: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    visible() {
      return hasCustomeAuth(this.$store);
    }
  }
};