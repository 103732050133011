export const columns = [{
  attrs: {
    label: '全选',
    prop: '',
    width: 60
  },
  slot: 'selection',
  header: 'headerAction'
}, {
  attrs: {
    label: '导游',
    prop: 'name'
  }
}, {
  attrs: {
    label: '手机号码',
    prop: 'mobileIphone'
  }
}, {
  attrs: {
    label: '状态',
    prop: 'alipayEmployeeId'
  },
  slot: 'status'
}];