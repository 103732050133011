var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper audit-proxy"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.changeStartTripDate
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": _vm.changeEndTripDate
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.planNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planNumber", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": _vm.changeOrderDate
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.orderNumber,
      label: "流水号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "orderNumber", $event);
      }
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      disabled: !_vm.hasCustomerInfo
    },
    on: {
      "on-select": function ($event) {
        return _vm.select("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectGuide", {
    attrs: {
      label: "导游"
    },
    on: {
      "on-select": function ($event) {
        return _vm.select("guide", $event);
      }
    },
    model: {
      value: _vm.searchParams.guideLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideLabel", $$v);
      },
      expression: "searchParams.guideLabel"
    }
  }), _c("InputBase", {
    attrs: {
      label: "游客信息"
    },
    model: {
      value: _vm.searchParams.memberKey,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "memberKey", $$v);
      },
      expression: "searchParams.memberKey"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      label: "代收类型",
      type: "collectProxyType"
    },
    model: {
      value: _vm.searchParams.isCollect,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "isCollect", $$v);
      },
      expression: "searchParams.isCollect"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "审核状态",
      type: _vm.examineState
    },
    on: {
      "on-select": _vm.selectAuditStatus
    },
    model: {
      value: _vm.auditStatus,
      callback: function ($$v) {
        _vm.auditStatus = $$v;
      },
      expression: "auditStatus"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.select("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDepartment", {
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "计划类型",
      type: "orderType"
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("TableProxy", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.tableData.length && _vm.statistics ? _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "number",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAdultCount) + "大" + _vm._s(_vm.statistics.totalChildCount) + "小" + _vm._s(_vm.statistics.totalAccompanyCount) + "陪 ")];
      }
    }, {
      key: "collection",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAgencyIncome) + " ")];
      }
    }, {
      key: "guideCollection",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalGuideIncome) + " ")];
      }
    }], null, false, 3558367557)
  }) : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "auditOp"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 确认审核 ")])], 1), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };