import { SELECTOR, getSelectLabel } from '@/config/select';
import { mapGetters } from 'vuex';
import { parseTime, decimalSplit } from '@/utils';
import { formatItinerarys } from '@/views/order/utils';
import SealImage from '@/views/print/components/SealImage';
// import { SEAL_TYPE } from '@/config'

export default {
  components: {
    SealImage
  },
  data() {
    return {
      getSelectLabel,
      data: null,
      travelAgency: '',
      newDateTime: '',
      sealImageUrl: ''
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      receptData: 'order/print/receptData'
    }),
    // getTrafficType() {
    //   return type => {
    //     const findItem = SELECTOR.bigTraffic.find(it => it.value == type)
    //     return findItem && findItem.label
    //   }
    // },

    getCardType() {
      return type => {
        const findItem = SELECTOR.cardType.find(it => it.value == type);
        return findItem && findItem.label;
      };
    },
    calcUnCollect() {
      return item => {
        const {
          totalIncome,
          totalSubsidy,
          totalIncomed,
          totalCollect,
          unIncomeDeductSubsidy
        } = item;
        // *乘以10000处理小数点精度丢失问题
        const unit = 10000;
        // unIncomeDeductSubsidy 如果对冲则未收则加上返款，不对冲就不加上
        const Subsidy = unIncomeDeductSubsidy ? totalSubsidy * unit : 0;
        const Incomed = totalIncomed * unit;
        const Collect = totalCollect * unit;
        const UnCollect = totalIncome * unit - (Subsidy + Incomed + Collect);
        return decimalSplit(UnCollect / unit);
      };
    }
  },
  watch: {
    userData: {
      handler: 'initData',
      immediate: true
    },
    receptData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.$route.query.id);
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    shuttles(type) {
      if (!this.data.shuttles || !this.data.shuttles.length) return '';
      let shuttle = '';
      this.data.shuttles.forEach(it => {
        if (type == it.shuttleType) {
          it.shiftDateTime = parseTime(it.shiftDateTime);
          shuttle = `班次${it.shiftNumber}时间${it.shiftDateTime}`;
        }
      });
      return shuttle;
    },
    fetchData(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('order/print/fetchReceipt', id).then(data => {
        this.data = data;
        this.data.itineraries = formatItinerarys(data.itineraries);
        loading.close();
        this.$emit('onMounted');
      }).catch(() => {
        loading.close();
      });
    },
    handleData(data) {
      this.data = data;
      this.data.itineraries = formatItinerarys(data.itineraries);
      this.$emit('onMounted');
    },
    initData(data) {
      const {
        travelAgency
      } = data;
      this.travelAgency = travelAgency;
      this.newDateTime = parseTime(new Date());
    }
  }
};