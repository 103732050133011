import "core-js/modules/es.array.push.js";
import { SELECTOR } from '@/config/select';
import { planTourist } from '@/config/plan';
import { SelectShop } from '@/components/common';
import { validCard } from '@/utils/idcard';
export default {
  components: {
    // FooterBar,
    SelectShop
  },
  data() {
    return {
      columnData: planTourist,
      tableData: [],
      dataInfo: {},
      visible: false
    };
  },
  computed: {
    cardType() {
      return val => {
        if (val == '') return val;
        const type = SELECTOR.cardType.find(it => it.value == val);
        return type.label;
      };
    }
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    this.fetchPlanById(id);
    this.getOrderByPlanId(id);
  },
  methods: {
    fetchPlanById(id) {
      this.$store.dispatch('plan/index/fetchPlanById', id).then(data => {
        this.dataInfo = data;
      });
    },
    getOrderByPlanId(id) {
      this.tableData = [];
      this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        data.forEach(it => {
          it.members.forEach(v => {
            const item = {
              name: v.name,
              remarks: '',
              expand: false,
              cardType: v.cardType,
              cardNumber: v.cardNumber,
              mobilePhone: v.mobilePhone,
              endTripDate: it.endTripDate,
              startTripDate: it.startTripDate
            };
            this.tableData.push(item);
          });
        });
        if (this.tableData.length) {
          this.fetchDayShopTourist(this.tableData);
          // this.fetchShopData({ planId: id })
        }
      });
    },
    fetchDayShopTourist(list) {
      // const loding
      let opts = list.filter(it => it.cardType == 0 && it.cardNumber);
      this.$store.dispatch('plan/index/fetchDayShopTourist', opts).then(data => {
        this.tableData = this.tableData.map(it => {
          it.details = [];
          it.total = 0;
          data.forEach(v => {
            if (v.cardNumber == it.cardNumber) {
              it.touristId = v.id;
              it.remarks = v.remarks;
              it.details = v.shopDetails;
              v.shopDetails.forEach(s => {
                it.total += Number(s.money);
              });
            }
          });
          return it;
        });
        this.fetchShopData();
      });
    },
    // 获取购物店信息
    fetchShopData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('plan/shopping/fetch', {
        planId: id
      }).then(res => {
        const details = [];
        res.forEach(it => {
          const item = {
            money: 0,
            cardType: '',
            isAdd: false,
            delete: false,
            shopId: it.id,
            shopName: it.shopName
          };
          details.push(item);
        });
        const item = JSON.stringify(details);
        this.tableData = this.tableData.map(it => {
          if (!it.details.length) {
            it.details = JSON.parse(item);
          }
          return it;
        });
        this.$nextTick(() => {
          this.visible = true;
        });
      });
    },
    // 选择购物店
    selectShop(row, val) {
      row.shopName = val.label;
      row.shopId = val.value;
    },
    inputRemark(item, val) {
      item.remarks = val;
    },
    // 新增购物店
    onAddShop(row) {
      const item = {
        money: 0,
        shopId: '',
        shopName: '',
        cardType: '',
        remarks: '',
        isAdd: true,
        delete: false
      };
      this.$set(row, 'expand', true);
      row.details.push(item);
    },
    handleDelete(data, index, row) {
      const {
        id
      } = row;
      if (!id) {
        data.details.splice(index, 1);
        return false;
      }
      this.$bus.open(() => {
        row.delete = true;
        this.handleConfirm(data);
      }, `确认删除此购物信息?`);
    },
    handleConfirm(opts) {
      if (!this.validateForm(opts)) return;
      delete opts.shopDetails;
      this.$store.dispatch('plan/index/updateShopDetail', opts).then(() => {
        this.fetchDayShopTourist(this.tableData);
        this.$bus.tip();
      });
    },
    validateForm(data) {
      const {
        details,
        cardNumber
      } = data;
      const valid = validCard(cardNumber);
      if (!valid) {
        this.$bus.tip({
          type: 'warning',
          message: '游客身份证号码不正确'
        });
        return false;
      }
      if (!details.length) return false;
      for (let i = 0; i < details.length; i++) {
        const row = details[i];
        if (!row.shopName) {
          this.$bus.tip({
            type: 'warning',
            message: '购物店信息不能为空！'
          });
          return false;
        }
      }
      return true;
    }
  }
};