var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "case-detail",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      "label-width": "90px"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("el-form-item", {
    attrs: {
      label: "案例类型",
      prop: "type"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.type) + " ")]), _c("el-form-item", {
    attrs: {
      label: "操作计调",
      prop: "personInCharge1"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.personInCharge1) + " ")]), _c("el-form-item", {
    attrs: {
      label: "客服计调",
      prop: "personInCharge2"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.personInCharge2) + " ")]), _c("el-form-item", {
    attrs: {
      label: "发生时间",
      prop: "occurrenceTime"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.occurrenceTime) + " ")])], 1), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "事件简介",
      prop: "introduction"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.introduction) + " ")]), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "事件经过",
      prop: "details"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.details) + " ")]), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "造成后果",
      prop: "consequence"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.consequence) + " ")]), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "问题所在",
      prop: "reason"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.reason) + " ")]), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "处理方式",
      prop: "handlingMethod"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.handlingMethod) + " ")]), _c("el-divider"), _c("el-form-item", {
    attrs: {
      label: "完善方案",
      prop: "improveThePlan"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.improveThePlan) + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };