import "core-js/modules/es.array.push.js";
import {
// ORDER_TEAM_FEE_CONFIRM_KEY,
// SHUTTLE_DEFAULT_COST_KEY,
ENTERPRISE_LINK_KEY } from '@/config/systemConfig';

// 获取指定操作权限
export const hasRoleAuth = (code, authList) => {
  if (!code) return true;

  // const authList = store.getters['system/role/rolePermissionList']

  function getOpts(list) {
    const temp = [];
    if (!list || !list.length) return temp;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.subMenus && item.subMenus.length) {
        temp.push(...getOpts(item.subMenus));
      } else {
        temp.push(...item.roleMenuOpts);
      }
    }
    return temp;
  }
  const findList = getOpts(authList);
  return findList.some(it => it.code == code && it.isRole);
};
export const getSystemConfigByCode = (store, code) => {
  const data = store.getters['system/buttJoint/configData'];
  return data.find(it => it.code === code);
};
export const getEnterpriseLink = store => {
  return getSystemConfigByCode(store, ENTERPRISE_LINK_KEY);
};
const findMenuByPath = (list, path) => {
  for (const obj of list) {
    if (obj.path == path) {
      return obj;
    } else if (obj.subMenus && obj.subMenus.length > 0) {
      const found = findMenuByPath(obj.subMenus, path);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

// 根据菜单来判断是否拥有权限
export const getAuthByPath = (store, path) => {
  const rolePermissionList = store.getters['permission/rolePermissionList'];
  return findMenuByPath(rolePermissionList, path);
};