var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "额度分配",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.add(_vm.ENTERPRISE_RESTYPE.SCENIC);
      }
    }
  }, [_vm._v(" 添加景区 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.add(_vm.ENTERPRISE_RESTYPE.RESTAURANT);
      }
    }
  }, [_vm._v(" 添加餐厅 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.add(_vm.ENTERPRISE_RESTYPE.HOTEL);
      }
    }
  }, [_vm._v(" 添加酒店 ")])], 1), _c("FormBase", {
    ref: "formRef",
    staticClass: "mt20"
  }, _vm._l(_vm.resList, function (item, index) {
    return _c("FormItemBase", {
      key: index
    }, [_c("div", {
      staticClass: "flex"
    }, [_vm.isScenic(item.resType) ? _c("SelectScenic", {
      attrs: {
        label: ""
      },
      on: {
        "on-select": function ($event) {
          return _vm.handleSelect(item, $event);
        }
      },
      model: {
        value: item.resId,
        callback: function ($$v) {
          _vm.$set(item, "resId", $$v);
        },
        expression: "item.resId"
      }
    }) : _vm._e(), _vm.isRestaurant(item.resType) ? _c("SelectRestaurant", {
      attrs: {
        label: ""
      },
      on: {
        "on-select": function ($event) {
          return _vm.handleSelect(item, $event);
        }
      },
      model: {
        value: item.resId,
        callback: function ($$v) {
          _vm.$set(item, "resId", $$v);
        },
        expression: "item.resId"
      }
    }) : _vm._e(), _vm.isHotel(item.resType) ? _c("SelectHotel", {
      attrs: {
        label: ""
      },
      on: {
        "on-select": function ($event) {
          return _vm.handleSelect(item, $event);
        }
      },
      model: {
        value: item.resId,
        callback: function ($$v) {
          _vm.$set(item, "resId", $$v);
        },
        expression: "item.resId"
      }
    }) : _vm._e(), _c("span", {
      staticClass: "ml20"
    }), _c("InputBase", {
      attrs: {
        tip: "请填写分配额度"
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }), _c("span", {
      staticClass: "ml20"
    }), _c("el-button", {
      attrs: {
        type: "danger"
      },
      on: {
        click: function ($event) {
          return _vm.deleteItem(index);
        }
      }
    }, [_vm._v(" 删除 ")])], 1)]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };