import store from './store';
import router from './router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getPageTitle, getToken } from '@/utils';
import { getComponentsData } from '@/request-data';
const whiteList = ['/login', '/auth-redirect'];
NProgress.configure({
  showSpinner: false
});
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  if (hasToken) {
    // 获取路由长度
    const status = store.getters['permission/requestPass'];
    // 判断是否有路由权限，如果没有则获取数据，count用来避免多次获取
    if (!status) {
      try {
        const accessRoutes = await store.dispatch('permission/fetchUserAllowMenu');
        router.addRoutes(accessRoutes);
        getComponentsData();
        next({
          ...to,
          replace: true
        });
      } catch (error) {
        // const url = parseQuerys(to.path, to.query)
        next('/dashboard');
      }
    } else if (to.meta.auth) {
      // 判断当前路由是否需要校验权限
      if (~to.path.indexOf('/login')) {
        next('/dashboard');
      }
    }
    next();
    NProgress.done();
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      // 处理当前页面有查询参数的情况
      next('/login');
      NProgress.done();
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});