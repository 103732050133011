import QrcodeVue from 'qrcode.vue';
import BoatLogin from './components/BoatLogin';
import { touristList as touristColumns } from '@/config/boat';
import { scenicCompany as companyColumns } from '@/config/boat';
export default {
  components: {
    QrcodeVue,
    BoatLogin
  },
  data() {
    return {
      touristColumns,
      companyColumns,
      touristList: [],
      companyList: [],
      loginStatus: false,
      guideInfo: null,
      qrcodeValue: '',
      size: 240
    };
  },
  created() {
    const {
      guide
    } = this.$route.query;
    guide && this.fetchGuideList({
      guide
    });
  },
  methods: {
    // 数字漓江导游列表
    fetchGuideList(params) {
      this.$store.dispatch('plan/boat/guideList', params).then(list => {
        if (!list || !list.length) return;
        const data = list[0];
        this.fetchData(data.id);
        this.guideInfo = data;
      });
    },
    // 获取导游
    fetchData(id) {
      this.$store.dispatch('plan/boat/touristList', id).then(data => {
        if (!data) return;
        const {
          import_batch: companyList,
          tourist_list: touristList
        } = data.dataList;
        this.companyList = companyList.list;
        this.touristList = touristList.list;
        this.qrcodeValue = this.companyList && this.companyList[0].import_code;
      });
    }
  }
};