var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-color-picker", {
    staticClass: "theme-picker",
    attrs: {
      predefine: _vm.predefine,
      "popper-class": "theme-picker-dropdown"
    },
    model: {
      value: _vm.theme,
      callback: function ($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };