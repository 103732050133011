var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-bottom": "10px"
    }
  }, [_vm.title ? _c("h2", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.hasBtn ? _c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 新增 ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };