import { Columns as columns } from './tableConfig';
import { SelectStaff, InputDepartment } from '@/components/common';
import { KPIType2Label } from './config';
import { calcTotal } from './helper';
import { mergeTableRow } from '@/utils';
import { departIdKeys } from '@/config';
export default {
  components: {
    SelectStaff,
    InputDepartment
  },
  mixins: [],
  data() {
    return {
      columns,
      tableData: [],
      search: {
        year: new Date(),
        userType: 'out' // out sale
      }
    };
  },
  computed: {
    userData() {
      return this.$store.getters['analyse/target/userData'];
    }
  },
  watch: {
    userData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.tableData = mergeTableRow(this.formatData(data.list), ['name', 'year']);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      const year = new Date(this.search.year).getFullYear();
      this.$store.dispatch('analyse/target/userList', {
        ...this.search,
        ...params,
        year
      });
    },
    formatData(list) {
      return list.map(it => {
        it.total = calcTotal(it);
        it.type = KPIType2Label[it.kpitype];
        return it;
      });
    },
    changeDepartment(val) {
      // 清空上次的几率
      departIdKeys.forEach(key => {
        this.search[key] = '';
      });
      this.search = {
        ...this.search,
        ...val
      };
    }
  }
};