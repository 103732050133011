import "core-js/modules/es.array.push.js";
import { planEditReceptionEdit } from '@/config/plan';
import { SelectDictionary } from '../index';
import TopTitle from '../TopTitle.vue';
import { mapGetters } from 'vuex';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin } from '@/mixin';
import propMixin from './propMixin';
const defaultState = () => ({
  count: 1,
  unit: 1,
  price: 0,
  times: 0,
  title: '',
  total: 0,
  type: '',
  typeId: ''
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  mixins: [dictionaryMixin, propMixin],
  data() {
    return {
      teamFundType: codeType.teamFundType,
      columnData: planEditReceptionEdit,
      type: 'feeList' // 地接费用列表
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    })
  },
  methods: {
    // 新增一条
    handlePlus() {
      let count = 1;
      const {
        name: type,
        id: typeId
      } = this.getDictionaryDefVal(this.teamFundType);
      if (this.currentOrder) {
        count = this.currentOrder.adultCount;
      }
      this.tableData.push({
        ...defaultState(),
        count,
        type,
        typeId,
        isCustom: true
      });
      this.rowId++;
    },
    selectType(row, val) {
      row.type = val ? val.label : '';
      row.typeId = val ? val.value : '';
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      if (isCustom) {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      } else {
        this.$bus.open(() => {
          const loading = this.$bus.loading();
          this.$store.dispatch('order/reception/deleteFeeItem', {
            id,
            index: this.index,
            type: this.type
          }).then(() => {
            loading.close();
          }).catch(() => {
            loading.close();
          });
        });
      }
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price,
        unit
      } = obj;
      let total = count * price * unit;
      return total;
    },
    // 修改单行金额
    changeInput(item) {
      item.total = this.calcRowItem(item);
    },
    updateData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/reception/changeReception', {
          type: this.type,
          data: this.tableData,
          index: this.index
        }).then(() => {
          resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    }
  }
};