import { orderProductLine } from '@/config/order';
import { dictionaryMixin } from '@/mixin';
import { formatItinerarys } from '@/views/order/utils';
import IconRight from '@/components/common/IconRight.vue';
import IconWrong from '@/components/common/IconWrong.vue';
export default {
  components: {
    IconRight,
    IconWrong
  },
  mixins: [dictionaryMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: orderProductLine.slice(1),
      type: 'itinerariesList' // 返款列表，对应接口字段
    };
  },
  watch: {
    tableData: {
      handler: function (list) {
        const data = formatItinerarys(list);
        this.itinerariesData = data;
      },
      immediate: true
    }
  },
  methods: {}
};