import { formMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
// import { isPhone } from '@/utils'
export default {
  components: {
    SwitchStatus
  },
  mixins: [formMixin],
  data() {
    // const checkPhone = val => {
    //   if (!val || !isPhone(val)) return false
    //   return true
    // }
    return {
      visible: false,
      isUpdate: false,
      validateForm: {
        name: [{
          required: true,
          message: '名字不能为空'
        }]
        // mobileIphone: [
        //   { required: true, message: '手机号不能为空' },
        //   { validate: checkPhone, message: '手机号格式不正确' },
        // ],
      },
      form: {
        name: ''
      }
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '更新单位' : '添加单位';
    }
  },
  methods: {
    show() {
      this.isUpdate = false;
      this.visible = true;
    },
    edit(data) {
      this.form = data;
      this.visible = true;
      this.isUpdate = true;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      // this.$parent.fetchList()
      const opts = this.form;
      this.$parent.createOrUpdate(opts);
      this.hide();
    }
  }
};