var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "goodsName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.name) + " ")];
      }
    }, {
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerOrderId) + " ")];
      }
    }, {
      key: "money",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.money) + " ")];
      }
    }, {
      key: "shopRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopRate) + " ")];
      }
    }, {
      key: "shopRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopRebate) + " ")];
      }
    }, {
      key: "totalGuideRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideRebate) + " ")];
      }
    }, {
      key: "totalDriverRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.driverRebate) + " ")];
      }
    }, {
      key: "totalAccompanyRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.accompanyRebate) + " ")];
      }
    }, {
      key: "guideRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideRate) + " ")];
      }
    }, {
      key: "guideRebateDeduction",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideRebateDeduction) + " ")];
      }
    }, {
      key: "driverRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.driverRate) + " ")];
      }
    }, {
      key: "driverRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.driverRebateDeduction) + " ")];
      }
    }, {
      key: "accompanyRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.accompanyRate) + " ")];
      }
    }, {
      key: "guideCollect",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideCollect) + " ")];
      }
    }, {
      key: "guideManagerRate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideManagerRate) + " ")];
      }
    }, {
      key: "guideManagerRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideManagerRebate) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };