var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "商家绑定",
      "custom-class": "bind-business-dialog",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleUpdate,
      close: _vm.hide
    }
  }, [_c("SelectData", {
    attrs: {
      label: "商家",
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteMethod,
      list: _vm.list
    },
    on: {
      "on-select": _vm.onSelectBusiness
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };