/* eslint-disable no-unused-vars */
import request, { requestBlob } from '@/utils/request';

// 部门业绩统计
export function depPerformanceRequest(params) {
  return request({
    url: '/order/report2/report-department-performance-static',
    method: 'get',
    params
  });
}

// 查询天天快报
export function getDailyExpress(params) {
  return request({
    timeout: 0,
    url: '/order/report2/dailyExpress',
    method: 'get',
    params
  });
}

// 查询客户应收报表数据
export function getCustomerIncome(params) {
  return request({
    url: '/order/report2/getCustomerIncome',
    method: 'get',
    params
  });
}

// 用房用车数据统计
export function getRoomAndBus(params) {
  return request({
    url: '/order/report2/roomAndBusStatistic',
    method: 'get',
    params
  });
}

// 单团润数据统计、结算
export function getTeamProfit(params) {
  return request({
    url: '/order/report2/reportSinglePlanProfitStatic',
    method: 'get',
    params
  });
}

// 小团利润统计
export function getTeamSmall(params) {
  return request({
    url: '/order/report2/reportOrderProfitStatic',
    method: 'get',
    params
  });
}

// 月营业状况表
export function getMonthlyOPStatust(params) {
  return request({
    url: '/order/report2/monthlyOPStatus',
    method: 'get',
    params
  });
}

// 毛利总汇数据
export function getMonthlyGrossProfit(params) {
  return request({
    url: '/order/report2/monthlyGrossProfit',
    method: 'get',
    params
  });
}

// 年度人数统计
export function getYearPeopleCount(params) {
  return request({
    url: '/order/report2/yearPeopleCount',
    method: 'get',
    params
  });
}

// 社收明细表
export function getTripIncomeDetailed(params) {
  return request({
    url: '/order/report2/tripIncomeDetailed',
    method: 'get',
    params
  });
}

// 其他支出统计
export function getOtherPayStatic(params) {
  return request({
    url: '/order/report2/otherPayStatic',
    method: 'get',
    params
  });
}

// 其他支出汇总统计
export function getOtherPayTotal(params) {
  return request({
    url: '/order/report2/otherPaySummaryStatistics',
    method: 'get',
    params
  });
}

// 其他收入汇总
export function getOtherIncomeSummary(params) {
  return request({
    url: '/order/report2/otherIncomeSummaryStatistics',
    method: 'get',
    params
  });
}

// 其他收入统计
export function getOtherIncomeStatic(params) {
  return request({
    url: '/order/report2/otherIncomeStatic',
    method: 'get',
    params
  });
}

// 地接汇总统计
export function getOutTransferStatic(params) {
  return request({
    url: '/order/report2/outTransferStatic',
    method: 'get',
    params
  });
}

// 资源类型汇总统计
export function getResourceStatic(params) {
  return request({
    url: '/order/report2/resourceStatic',
    method: 'get',
    params
  });
}

// 客户汇总统计
export function getCustomerStatic(params) {
  return request({
    url: '/order/report2/customerStatic',
    method: 'get',
    params
  });
}

// 导游数据统计
export function getGuideStatic(params) {
  return request({
    url: '/order/report2/reportGuideData',
    method: 'get',
    params
  });
}

// 导游组长数据统计
export function getGuideTeamStatic(params) {
  return request({
    url: '/order/report2/reportGuideTeamData',
    method: 'get',
    params
  });
}

// 导游报账统计
export function getGuideReimb(params) {
  return request({
    url: '/order/report2/planGuideReimb',
    method: 'get',
    params
  });
}

// 导游报账审核统计
export function getGuideSubmit(params) {
  return request({
    url: '/order/report/guideReimbReport',
    method: 'get',
    params
  });
}

// 导游汇总统计
export function getGuideCount(params) {
  return request({
    url: '/order/report2/planGuideCount',
    method: 'get',
    params
  });
}

// 导游结算统计
export function getGuideSettle(params) {
  return request({
    url: '/order/report2/planGuideSettlement',
    method: 'get',
    params
  });
}

// 借款统计
export function getGuideBorrow(params) {
  return request({
    url: '/order/report2/planGuideBorrow',
    method: 'get',
    params
  });
}

// 用房统计
export function fetchOrderRoom(params) {
  return request({
    url: '/order/hotel/listForRoomCount',
    method: 'get',
    params
  });
}

// 查询房调未安排
export function fetchOrderList(params) {
  return request({
    url: '/order/hotel/pageHotelAreaArrange',
    method: 'get',
    params
  });
}

// 计划id用房统计
export function fetchOrderRoomById(params) {
  return request({
    url: '/order/hotel/findHotelById',
    method: 'get',
    params
  });
}
export function fetchOrderResource(params) {
  return request({
    url: '/order/report2/resourceStaticOrder',
    method: 'get',
    params
  });
}

// 客户毛利总汇
export function fetchCustomerGross(params) {
  return request({
    url: '/order/report2/reportCustomerGrossProfitOfMonth',
    method: 'get',
    params
  });
}

// 酒店房价统计
export function fetchHotelHousePrice(params) {
  return request({
    url: '/order/report2/hotelRoomPriceStatistics',
    method: 'get',
    params
  });
}

// 返款统计表
export function fetchSubsidy(params) {
  return request({
    url: '/order/report2/subsidyStatistics',
    method: 'get',
    params
  });
}

// 返款统计表2
export function subsidyStatistics2Request(params) {
  return request({
    url: '/order/report2/subsidyStatistics2',
    method: 'get',
    params
  });
}

// 查询某个团的购物店的商品列表
export function fetchshopGoodsList(params) {
  return request({
    url: '/order/orderShop/shopGoodsList',
    method: 'get',
    params
  });
}

// 查应收账款汇总统计
export function fetchCustomeIncomeList(params) {
  return request({
    url: '/order/report2/report-customer-income-static',
    method: 'get',
    params
  });
}

// 导游借款列表
export function guideBorrowListRequest(params) {
  return request({
    url: '/order/report2/guideBorrowList',
    method: 'get',
    params
  });
}

// 客服计调绩效
export function fetchOutopuser(params) {
  return request({
    url: '/order/data-analysis/outopuser-analysis',
    method: 'get',
    params
  });
}

// 操作计调绩效
export function fetchDutyOpuser(params) {
  return request({
    url: '/order/data-analysis/dutyopuser-analysis',
    method: 'get',
    params
  });
}

// 应收余额表
export function fetchUnIncome(params) {
  return request({
    url: '/order/report2/report-customer-unIncome-static',
    method: 'get',
    params
  });
}

// 团队汇总
export function fetchGroup(params) {
  return request({
    url: '/order/report2/report-group-total-static',
    method: 'get',
    params
  });
}

// 团队汇总按客户统计
export function fetchGroupCustomer(params) {
  return request({
    url: '/order/report2/report-group-customer-static',
    method: 'get',
    params
  });
}

// 团队汇总按客户区域统计
export function fetchGroupCustomerArea(params) {
  return request({
    url: '/order/report2/report-group-customer-area-static',
    method: 'get',
    params
  });
}

// 团队汇总按区域经理统计
export function fetchGroupAreaManager(params) {
  return request({
    url: '/order/report2/report-group-area-manager-static',
    method: 'get',
    params
  });
}