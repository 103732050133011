import "core-js/modules/es.array.push.js";
import { TripNumber } from '@/components/common';
import { planEditInsurance } from '@/config/plan';
import { StatusAudit } from '@/components/common';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin, dictionaryMixin } from '@/mixin';
import { getPayTypeLabel } from '@/config/select';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin, dictionaryMixin],
  data() {
    return {
      tableData: [],
      deletePath: 'plan/insurance/delete',
      headMergeNum: 3,
      tailMergeNum: planEditInsurance.length - 6,
      hasMergeTail: true,
      rowId: 1000000000000,
      auditAll: false
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId',
      insuranceList: 'resource/insurance/insuranceData'
    }),
    getInsuranceName() {
      return id => {
        if (!this.insuranceList) return '';
        const item = this.insuranceList.find(it => it.id === id);
        return item && item.label;
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.resetData();
    },
    fetchData() {
      this.tableLoading = true;
      this.$store.dispatch('plan/insurance/fetch', {
        planId: this.currentPlanId
      }).then(data => {
        this.initData(data);
      });
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        count,
        price,
        otherFee
      } = obj;
      return count * price + Number(otherFee);
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderInsuranceTypes;
        const {
          id,
          auditProgress
        } = it;
        const item = {
          rowId: id,
          planInsuranceId: id,
          ap: auditProgress,
          auditProgress,
          cashType: getPayTypeLabel(it.cashType.toLowerCase()),
          insuranceId: it.insuranceId,
          totalPay: it.totalPay,
          remark: it.remark,
          money: it.money,
          planId: it.planId
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              insuranceType: v.insuranceType,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        it.totalPay = totalPay;
      });
    },
    resetData() {
      this.getTotalPayMoney();
      this.rowspan();
      this.mergeRowMoney(); // 合并单条数据总价
    }
  }
};