// 按单位 其他
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;

// 列表
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '其他'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'otherUnit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'remark',
    label: '安排备注'
  }
}, {
  attrs: {
    prop: 'totalPayed',
    label: '已付'
  }
}, owePay, downPayed, actionItem];