import { defaultAlipayRenew, alipayRenewRules as rules } from '../config';
import { getUserData, processAdd } from '@/utils';
export default {
  data() {
    return {
      rules,
      visible: false,
      form: defaultAlipayRenew(),
      totalBankInfo: ''
    };
  },
  computed: {
    subjectData() {
      return this.$store.getters['system/accounting/subjectData'];
    }
  },
  watch: {
    subjectData: {
      handler: 'setSubject',
      immediate: true
    }
  },
  methods: {
    show(item) {
      this.visible = true;
      const {
        id: bankId
      } = item;
      this.form = {
        ...this.form,
        bankId
      };
      this.totalBankInfo = this.calcTotalBankInfo(item);
    },
    hide() {
      this.visible = false;
    },
    // calcTotalStatices(data) {
    //   const { initBlance, totalReceipts } = data
    //   // 余额=系统里期初+结余
    //   const balance = Number(processAdd(initBlance, totalReceipts)).toFixed(2)
    //   return `下账金额合计模式：结余：${totalReceipts} 余额：${balance}`
    // },

    calcTotalBankInfo(data) {
      const {
        initBlance,
        totalReceipts
      } = data;
      // 余额=系统里期初+结余
      const balance = Number(processAdd(initBlance, totalReceipts)).toFixed(2);
      return `结余：${totalReceipts}\r\t余额：${balance}`;
    },
    setSubject(data) {
      if (!data) return;
      const findItem = data.find(it => it.name == '预收账款');
      if (findItem) {
        const {
          id,
          name
        } = findItem;
        this.form.subjectId = id;
        this.form.subjectName = name;
      }
    },
    handleSave() {
      this.$refs.formRef.validate(valid => {
        if (!valid) return;
        const {
          id: cashUserId
        } = getUserData();
        const opt = {
          ...this.form,
          cashUserId
        };
        this.$store.dispatch('resource/account/fetchAdjust', opt).then(() => {
          this.$bus.tip();
          this.hide();
        });
      });
    }
  }
};