var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.handleSearch
    }
  }, [[_vm.getResourceType(_vm.ResourceType.scenic) ? _c("FormItemBase", {
    attrs: {
      label: "景区"
    }
  }, [_c("InputScenic", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.hotel) ? _c("FormItemBase", {
    attrs: {
      label: "酒店"
    }
  }, [_c("InputHotel", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.restaurant) ? _c("FormItemBase", {
    attrs: {
      label: "餐厅"
    }
  }, [_c("InputRestaurant", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.isShowBus ? _c("FormItemBase", {
    attrs: {
      label: "车队"
    }
  }, [_c("InputFleet", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.traffic) ? _c("FormItemBase", {
    attrs: {
      label: "大交通"
    }
  }, [_c("InputTraffic", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.traffic) ? _c("FormItemBase", {
    attrs: {
      label: "类别"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "ticketType",
      label: ""
    },
    on: {
      "on-select": _vm.changeTicketType
    },
    model: {
      value: _vm.searchParams.transportTypeId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "transportTypeId", $$v);
      },
      expression: "searchParams.transportTypeId"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.insurance) ? _c("FormItemBase", {
    attrs: {
      label: "保险"
    }
  }, [_c("InputInsurance", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.shops) ? _c("FormItemBase", {
    attrs: {
      label: "购物店"
    }
  }, [_c("InputShop", {
    style: _vm.styleWidthObj,
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect,
      change: _vm.handleChange
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.isShowCustom ? _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectCustom
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  })], 1) : _vm._e(), _vm.isShowOther ? _c("FormItemBase", {
    attrs: {
      label: "项目"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.resourceName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceName", $$v);
      },
      expression: "searchParams.resourceName"
    }
  })], 1) : _vm._e(), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("startTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "报账导游"
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("guide", $event);
      }
    },
    model: {
      value: _vm.searchParams.guideLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideLabel", $$v);
      },
      expression: "searchParams.guideLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "订单日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("orderDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "对账日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.checkBillDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("checkAccountTime", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "对账状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "reconciliationState"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectOtherType("checkAccountStatus", $event);
      }
    },
    model: {
      value: _vm.searchParams.checkAccountStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "checkAccountStatus", $$v);
      },
      expression: "searchParams.checkAccountStatus"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "结清状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "settlementState"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectOtherType("planType", $event);
      }
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  })], 1), !_vm.isShowPayment ? _c("FormItemBase", {
    attrs: {
      label: "付款方式"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      list: _vm.payType
    },
    on: {
      "on-select": _vm.onSelectPayTypa
    },
    model: {
      value: _vm.searchParams.payTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "payTypeLabel", $$v);
      },
      expression: "searchParams.payTypeLabel"
    }
  })], 1) : _vm._e(), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "export"
    },
    on: {
      click: _vm.handleExcel
    }
  })], 1)], _c("template", {
    slot: "more"
  }, [_vm.isShowOpUser ? _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserId", $$v);
      },
      expression: "searchParams.outOPUserId"
    }
  })], 1) : _vm._e(), _vm.getResourceType(_vm.ResourceType.hotel) ? [_c("FormItemBase", {
    attrs: {
      label: "区域"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      label: "",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selecthotelRegion
    },
    model: {
      value: _vm.searchParams.area,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "area", $$v);
      },
      expression: "searchParams.area"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "酒店标准"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "hotelStandardType",
      label: ""
    },
    on: {
      "on-select": _vm.selectHotelStandard
    },
    model: {
      value: _vm.standard,
      callback: function ($$v) {
        _vm.standard = $$v;
      },
      expression: "standard"
    }
  })], 1)] : _vm._e(), _c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "线路"
    }
  }, [_c("SelectLine", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.travelName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "travelName", $$v);
      },
      expression: "searchParams.travelName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "orderType"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectOtherType("orderType", $event);
      }
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "产品"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.onSelectProduct
    },
    model: {
      value: _vm.searchParams.productLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productLabel", $$v);
      },
      expression: "searchParams.productLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "操作计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("endTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "游客信息"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.memberKey,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "memberKey", $$v);
      },
      expression: "searchParams.memberKey"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "审核状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "examineSearch"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectOtherType("auditProgress", $event);
      }
    },
    model: {
      value: _vm.searchParams.auditProgress,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditProgress", $$v);
      },
      expression: "searchParams.auditProgress"
    }
  })], 1), _vm.getResourceType(_vm.ResourceType.fleet) ? [_c("FormItemBase", {
    attrs: {
      label: "车牌号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.licenceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "licenceNumber", $$v);
      },
      expression: "searchParams.licenceNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "用车类型"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      "init-val": _vm.searchParams.useType,
      code: "busUseType",
      label: ""
    },
    on: {
      "on-select": _vm.selectUseBusType
    },
    model: {
      value: _vm.searchParams.useType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "useType", $$v);
      },
      expression: "searchParams.useType"
    }
  })], 1)] : _vm._e(), _vm.getResourceType(_vm.ResourceType.shuttle) ? [_c("FormItemBase", {
    attrs: {
      label: "车牌号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.licenceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "licenceNumber", $$v);
      },
      expression: "searchParams.licenceNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "接送类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "shuttleType",
      label: ""
    },
    model: {
      value: _vm.searchParams.shuttleType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "shuttleType", $$v);
      },
      expression: "searchParams.shuttleType"
    }
  })], 1)] : _vm._e(), _vm.getResourceType(_vm.ResourceType.scenic) ? [_c("FormItemBase", {
    attrs: {
      label: "是否加点"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "isAdd"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectOtherType("isAddScenic", $event);
      }
    },
    model: {
      value: _vm.searchParams.isAddScenic,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "isAddScenic", $$v);
      },
      expression: "searchParams.isAddScenic"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "门票类别"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.ticketType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "ticketType", $$v);
      },
      expression: "searchParams.ticketType"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "结算价"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.settlementPrice,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "settlementPrice", $$v);
      },
      expression: "searchParams.settlementPrice"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "单价"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.price,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "price", $$v);
      },
      expression: "searchParams.price"
    }
  })], 1)] : _vm._e(), _vm.getResourceType(_vm.ResourceType.otherPay) ? [_c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectCustom
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "单位"
    }
  }, [_c("SelectUnit", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectUnit
    },
    model: {
      value: _vm.searchParams.otherUnitLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "otherUnitLabel", $$v);
      },
      expression: "searchParams.otherUnitLabel"
    }
  })], 1)] : _vm._e(), _vm.getResourceType(_vm.ResourceType.restaurant) ? [_c("FormItemBase", {
    attrs: {
      label: "单价"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.price,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "price", $$v);
      },
      expression: "searchParams.price"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "餐标"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.restaurantType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "restaurantType", $$v);
      },
      expression: "searchParams.restaurantType"
    }
  })], 1)] : _vm._e()], 2)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };