// import { orderListDetail } from './plan'
const width80 = 80;
const width100 = 100;
const width120 = 120;
const width160 = 160;

// 创建订单审核状态
export const OrderAuditEmit = {
  NO_AUDITED: 'NO_AUDITED',
  NEW_WAIT: 'NEW_WAIT',
  AUDITED: 'AUDITED',
  WAIT: 'WAIT',
  NEW_UN_PASS: 'NEW_UN_PASS'
};
// 订单类型，'all'=所有 'booking'=预定，'t_tourist'=团客，'c_tourist'=控位输单，'s_tourist'=散客
export const orderType = {
  all: 'ALL',
  booking: 'BOOKING',
  team: 'T_TOURIST',
  control: 'C_TOURIST',
  personal: 'S_TOURIST'
};
export const OrderType = orderType;

// 订单审核修改类型 UPDATE更新修改 ADD新增 DEL删除
export const updateStatusType = {
  update: 'UPDATE',
  add: 'ADD',
  del: 'DEL'
};

// 订单审核更新类型
export const orderUpdateStatus = {
  ADD: '新增',
  UPDATE: '更改',
  DEL: '删除'
};

// 申请修改订单状态  wait-new 新增待审核,pass-审核通过,unPass-未通过,wait-待审核,可用值:NEW_WAIT,UN_PASS,PASS,WAIT
export const reviewerStatus = {
  NEW_WAIT: '新增待审核',
  UN_PASS: '未通过',
  PASS: '审核通过',
  WAIT: '修改待审核'
};

// 审核状态  wait-new 新增待审核,pass-审核通过,unPass-未通过,wait-待审核,可用值:NEW_WAIT,UN_PASS,PASS,WAIT
export const reviewerStatusType = {
  waitNew: 'NEW_WAIT',
  unPass: 'UN_PASS',
  pass: 'PASS',
  wait: 'WAIT',
  audited: 'AUDITED'
};
export const orderTypeLabel = {
  ALL: '全部',
  BOOKING: '预定',
  T_TOURIST: '团队',
  C_TOURIST: '控位',
  S_TOURIST: '散客'
};
// 订单状态
// 团队单 默认为 inGroup， inGroup=已拼团，unGroup=未拼团，split=已拆单，innerTransfer=内转，outTransfer=外转,可用值:INGROUP,UNGROUP,SPLIT,INNERTRANSFER,OUTTRANSFER

export const orderStatusLabel = {
  INGROUP: '已拼团',
  UNGROUP: '未拼团',
  SPLIT: '已拆单',
  INNERTRANSFER: '内转',
  OUTTRANSFER: '外转'
};
export const orderTagStatus = {
  INGROUP: 'success',
  UNGROUP: 'danger',
  SPLIT: 'warning',
  INNERTRANSFER: 'primary',
  OUTTRANSFER: 'primary'
};

// 订单审核类型
export const orderApplyAudits = {
  ORDER: '',
  TRANSPORT: '大交通',
  BUS: '车队',
  SCENIC: '景区',
  GUIDE: '导游',
  HOTEL: '酒店',
  INSURANCE: '保险',
  SHOP: '购物',
  RESTAURANT: '餐饮',
  FEE: '团款',
  SUBSIDY: '返款',
  COLLECT: '代收',
  OTHER_PAY: '其他支出',
  OTHER_INCOME: '其他收入',
  MEMBER: '游客',
  SHUTTLE: '接送',
  CUSTOMER: '客户'
};

// 日志类型：""=默认,transport=大交通，bus=车队，scenic=景区，guide=导游，selfCompany=自费，hotel=房间，insurance=保险，shop=购物，restaurant=餐饮，shuttle=接送，collect=代收，borrow=借款申请, ortherPay=其他支出, ortherIncome=其他收入, localTravelAgency=地接,subsidy = 返款, fee = 团款
const str = 'transport,bus,scenic,guide,selfCompany,hotel,insurance,shop,restaurant,shuttle,collect,borrow,ortherPay,ortherIncome,localTravelAgency,subsidy,fee';
const types = {};
str.split(',').map(it => {
  types[it] = it;
});
export const TYPES = types;
export const ORDER_TYPES = types;

// 审核流程，
// 'waitAudit'=待审核，'opAudited'=操作计调已审，'guideManagerAudited'=导管已审，'generalManagerAudited'=经理已审，'financialAudited'=财务已审，'bossAudited'=BOSS已审 可用值:UNCONFIRM,WAITAUDIT,OPAUDITED,GUIDEMANAGERAUDITED,GENERALAUDITED,FINANCIALAUDITED,BOSSAUDITED
export const orderProgressStatus = {
  WAITAUDIT: '待审核',
  UNCONFIRM: '未确认',
  OPAUDITED: '操作计调已审',
  GUIDEMANAGERAUDITED: '导管已审',
  GENERALAUDITED: '经理已审',
  FINANCIALAUDITED: '财务已审',
  BOSSAUDITED: 'BOSS已审'
};
export const orderProgressTagStatus = {
  WAITAUDIT: 'warning',
  UNCONFIRM: 'primary',
  OPAUDITED: 'success',
  GUIDEMANAGERAUDITED: 'success',
  GENERALAUDITED: 'success',
  FINANCIALAUDITED: 'success',
  BOSSAUDITED: 'success'
};

// 审核状态
export const orderAuditStatus = {
  FILE: '已归档',
  SETTLEMENT: '已结算',
  QUIT: '已退单',
  CANCEL: '已取消',
  CONFIRM: '已成团',
  NEW: '新建立'
};
export const orderAuditTagStatus = {
  FILE: 'info',
  SETTLEMENT: 'info',
  QUIT: 'danger',
  CANCEL: 'danger',
  CONFIRM: 'success',
  NEW: 'primary'
};

// 同行内转 接收状态
// 接收状态，'refuse'=拒绝，'back'=退回，'accept'=接受，'wait'=等待接受
export const orderReceiveStatus = {
  WAIT: '待接收',
  ACCEPT: '已接收',
  BACK: '退回',
  REFUSE: '拒绝'
};
export const orderReceiveTagStatus = {
  WAIT: 'warning',
  ACCEPT: 'success',
  BACK: 'danger',
  REFUSE: 'info'
};
export const agesPrintRangeColumns = [{
  min: 0,
  max: 6
}, {
  min: 7,
  max: 14
}, {
  min: 15,
  max: 18
}, {
  min: 19,
  max: 28
}, {
  min: 29,
  max: 59
}, {
  min: 60,
  max: 64
}, {
  min: 65,
  max: 69
}, {
  min: 70,
  max: 100,
  color: 'red'
}];
const ctrlWidth = 100;
export const customerInfo = {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'customerName'
};
const type = {
  attrs: {
    prop: 'type',
    label: '类型'
  }
};
const typeSlot = {
  ...type,
  slot: 'type'
};
const remark = {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
};
const remarks = {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
};
const remarksSlot = {
  ...remarks,
  slot: 'remarks'
};
const total = {
  attrs: {
    prop: 'total',
    label: '金额'
  }
};
const totalSlot = {
  ...total,
  slot: 'total',
  width: 100
};
const price = {
  attrs: {
    prop: 'price',
    label: '单价'
  }
};
const priceSlot = {
  ...price,
  slot: 'price'
};
const count = {
  attrs: {
    prop: 'count',
    label: '数量'
  }
};
const countSlot = {
  ...count,
  slot: 'count'
};
const money = {
  attrs: {
    prop: 'money',
    label: '金额'
  }
};
const moneySlot = {
  ...money,
  slot: 'money'
};
const times = {
  attrs: {
    prop: 'times',
    label: '单位'
  }
};
const proxySlot = {
  attrs: {
    prop: 'proxy',
    label: '代收单位'
  },
  slot: 'proxy'
};
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '日期'
  }
};
const orderDateSlot = {
  ...orderDate,
  slot: 'orderDate'
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
};

/** **************************** 团队输单 查看 ******************* */

// 团款代收
export const groundingBase = [typeSlot, remarksSlot, totalSlot];

// 团款
export const orderFundDetail = [typeSlot, remarksSlot, priceSlot, countSlot, {
  ...times,
  slot: 'times'
}, totalSlot];

// 团款代收
export const orderFundProxyDetail = [...groundingBase, proxySlot];

// 团款返款
export const orderRefundDetail = [typeSlot, remarksSlot, priceSlot, countSlot, totalSlot];

// 接送
export const orderPickupDetail = [{
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': 100
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'shuttleDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'cityType',
    label: '地点类型'
  },
  slot: 'cityType'
}, {
  attrs: {
    prop: 'timeAndTarget',
    label: '航班/车次/上下车点'
  },
  slot: 'timeAndTarget'
}, {
  attrs: {
    prop: 'shiftDateTime',
    label: '时间'
  }
}, {
  attrs: {
    prop: 'desPosition',
    label: '航站楼/车站'
  },
  slot: 'desPosition'
}, {
  attrs: {
    prop: 'adultCount',
    label: '大人数',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'childCount',
    label: '小孩数',
    'min-width': 80
  }
}, remark];

// 游客名单
export const orderTouristListDetail = [{
  attrs: {
    prop: 'seqNumber',
    label: '序号',
    'min-width': 100
  },
  slot: 'seqNumber'
}, {
  attrs: {
    prop: 'name',
    label: '姓名',
    'min-width': 140
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型',
    'min-width': 80
  },
  slot: 'cardType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  }
}, {
  attrs: {
    prop: 'age',
    label: '年龄',
    'min-width': 100
  },
  slot: 'age'
}, {
  attrs: {
    prop: 'location',
    label: '所在地'
  }
}, {
  attrs: {
    prop: 'sex',
    label: '性别',
    'min-width': 80
  },
  slot: 'sex'
}, {
  attrs: {
    prop: 'isContact',
    label: '是否是联系人',
    'min-width': 60
  },
  slot: 'isContact'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    'min-width': 150
  }
}];

// 产品行程
export const orderItineraryDetail = [{
  attrs: {
    prop: 'itineraryDate',
    label: '日期',
    width: 140
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'itineraryDesc',
    label: '行程'
  }
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐',
    width: 100
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '午餐',
    width: 100
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐',
    width: 100
  },
  slot: 'dinner'
}];

// 酒店信息
export const orderHotelDetail = [orderDate, {
  attrs: {
    prop: 'hotelArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'hotelStandard',
    label: '标准'
  }
}, {
  attrs: {
    prop: 'hotelName',
    label: '酒店名称'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

/** **************************** 团队输单 编辑 ******************* */

export const orderOtherIncome = [{
  attrs: {
    prop: 'content',
    label: '项目'
  },
  slot: 'content'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, countSlot, {
  attrs: {
    prop: 'totalPay',
    label: '金额'
  },
  slot: 'totalPay'
}, {
  attrs: {
    prop: 'payType',
    label: '收款方式'
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];
export const orderOtherPay = [{
  attrs: {
    prop: 'content',
    label: '项目'
  },
  slot: 'content'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, countSlot, {
  attrs: {
    prop: 'totalPay',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'payType',
    label: '付款方式'
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];
export const orderTrain = [
// { attrs: { prop: 'radio', label: '选择', 'min-width': 60 }, slot: 'radio' },
{
  attrs: {
    prop: 'station',
    label: '站名'
  },
  slot: 'radio'
}, {
  attrs: {
    prop: 'aTime',
    label: '到站时间'
  }
}, {
  attrs: {
    prop: 'sendTime',
    label: '发车时间'
  }
}];
export const orderPlane = [
// { attrs: { prop: 'radio', label: '选择', 'min-width': 120 }, slot: 'radio' },
{
  attrs: {
    prop: 'dpName',
    label: '起飞地'
  },
  slot: 'radio'
}, {
  attrs: {
    prop: 'apName',
    label: '降落地'
  }
}, {
  attrs: {
    prop: 'dTime',
    label: '起飞时间'
  }
}, {
  attrs: {
    prop: 'aTime',
    label: '降落时间'
  }
}];

// 产品行程
export const orderProductLine = [{
  attrs: {
    prop: 'itineraryDate',
    label: '日期',
    'min-width': 120
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'itineraryDesc',
    label: '行程'
  },
  slot: 'itineraryDesc'
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐',
    'min-width': 80,
    padding: 0
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '午餐',
    'min-width': 80
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐',
    'min-width': 80
  },
  slot: 'dinner'
}];

// 酒店信息
export const orderHotel = [{
  attrs: {
    prop: 'orderDate',
    label: '日期',
    width: 160
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: 'hotelArea',
    label: '区域'
  },
  slot: 'hotelArea'
}, {
  attrs: {
    prop: 'hotelStandard',
    label: '标准'
  },
  slot: 'hotelStandard'
}, {
  attrs: {
    prop: 'hotelName',
    label: '酒店名称',
    'min-width': 180
  },
  slot: 'hotelName'
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': 100
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': 140
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '金额',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'status',
    label: '状态',
    'min-width': 60
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];

// 景区信息
export const orderScenic = [{
  attrs: {
    prop: 'name',
    label: '景区名称',
    width: 160
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'orderDate',
    label: '日期',
    width: 120
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票类别',
    'min-width': 120
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width80
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'adultCount',
    label: '成人数',
    'min-width': width80
  },
  slot: 'adultCount'
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': width80
  },
  slot: 'adultFreeCount'
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童价',
    'min-width': width80
  },
  slot: 'childPrice'
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童数',
    'min-width': width80
  },
  slot: 'childCount'
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪价',
    'min-width': width80
  },
  slot: 'accompanyPrice'
}, {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪数',
    'min-width': width80
  },
  slot: 'accompanyCount'
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];
export const orderScenicDetail = [{
  attrs: {
    prop: 'orderDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'scenicName',
    label: '景区名称'
  }
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票类别',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '成人数',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童价',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童数',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪价',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪数',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

// 餐厅信息
export const orderRestaurant = [{
  attrs: {
    prop: 'orderDate',
    label: '用餐日期',
    width: 120
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: 'name',
    label: '餐厅名称',
    width: 160
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'area',
    label: '地区',
    'min-width': width100
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'mealType',
    label: '用餐类别',
    'min-width': width100
  },
  slot: 'mealType'
}, {
  attrs: {
    prop: 'ticketType',
    label: '餐标',
    'min-width': width100
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width80
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': width80
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量',
    'min-width': width80
  },
  slot: 'freeCount'
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];
export const orderRestaurantDetail = [{
  attrs: {
    prop: 'orderDate',
    label: '用餐日期'
  }
}, {
  attrs: {
    prop: 'name',
    label: '餐厅名称'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区',
    'min-width': width100
  }
}, {
  attrs: {
    prop: 'mealType',
    label: '用餐类别',
    'min-width': width100
  }
}, {
  attrs: {
    prop: 'restaurantType',
    label: '餐标',
    'min-width': width100
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}];

// 车队
export const orderFleet = [{
  attrs: {
    prop: 'name',
    label: '车辆公司',
    'min-width': width160
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'ticketType',
    label: '车辆类型',
    'min-width': width120
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'driver',
    label: '驾驶员',
    'min-width': width100
  },
  slot: 'driver'
}, {
  attrs: {
    prop: 'licenceNumber',
    label: '车牌号',
    'min-width': width100
  },
  slot: 'licenceNumber'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机',
    'min-width': width120
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'startDate',
    label: '用车开始日期',
    'min-width': width120
  },
  slot: 'startDate'
}, {
  attrs: {
    prop: 'endDate',
    label: '用车结束日期',
    'min-width': width120
  },
  slot: 'endDate'
}, {
  attrs: {
    prop: 'busFee',
    label: '车费',
    'min-width': width80
  },
  slot: 'busFee'
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额',
    'min-width': width80
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];

// 客户信息
export const orderCustom = [{
  attrs: {
    prop: 'name',
    label: '客户名称',
    'min-width': 300
  }
}, {
  attrs: {
    prop: 'info',
    label: '联系人信息'
  },
  children: [{
    attrs: {
      prop: 'contact',
      label: '联系人'
    }
  }, {
    attrs: {
      prop: 'mobilePhone',
      label: '手机号码'
    }
  }]
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
// 客户信息(弹窗)
export const orderCustom2 = [{
  attrs: {
    prop: 'name',
    label: '客户名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 线路产品
export const orderLine = [{
  attrs: {
    prop: 'name',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '产品类型'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 60
  },
  slot: 'action'
}];

// 团款
export const orderFund = [{
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': 180
  },
  slot: 'type'
}, remarksSlot, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, countSlot, {
  attrs: {
    prop: 'times',
    label: '单位'
  },
  slot: 'times'
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  },
  slot: 'total'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];

// 团款代收
export const orderFundProxy = [{
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': 180
  },
  slot: 'type'
}, remarksSlot, {
  attrs: {
    prop: 'total',
    label: '金额'
  },
  slot: 'total'
}, proxySlot, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: ctrlWidth
  },
  slot: 'action'
}];

// 团款返款
export const orderRefund = [{
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': 180
  },
  slot: 'type'
}, remarksSlot, priceSlot, countSlot, moneySlot, actionItem];

// 大交通
export const orderTraffic = [{
  attrs: {
    prop: 'transportName',
    label: '大交通公司',
    'min-width': 180
  },
  slot: 'transportName'
}, {
  attrs: {
    prop: 'orderDate',
    label: '时间',
    width: 180
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: 'shiftNumber',
    label: '班次',
    'min-width': 100
  },
  slot: 'shiftNumber'
}, {
  attrs: {
    prop: 'startPosition',
    label: '始发地',
    'min-width': 100
  },
  slot: 'startPlace'
}, {
  attrs: {
    prop: 'destinationPosition',
    label: '目的地',
    'min-width': 100
  },
  slot: 'endPlace'
}, {
  attrs: {
    prop: 'transportType',
    label: '票务类型',
    'min-width': 180
  },
  slot: 'transportType'
}, priceSlot, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': 100
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: '$money',
    label: '金额',
    'min-width': 100
  }
}, {
  attrs: {
    prop: '$total',
    label: '总金额',
    'min-width': 100
  }
}, remarksSlot, actionItem];
export const orderTrafficDetail = [{
  attrs: {
    prop: 'transportName',
    label: '大交通公司'
  }
}, {
  attrs: {
    prop: 'transportType',
    label: '票务类型'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '时间'
  }
}, {
  attrs: {
    prop: 'shiftNumber',
    label: '班次'
  }
}, {
  attrs: {
    prop: 'startPosition',
    label: '始发地'
  }
}, {
  attrs: {
    prop: 'destinationPosition',
    label: '目的地'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
}, {
  attrs: {
    prop: 'money',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

// 保险
export const orderInsurance = [{
  attrs: {
    prop: 'name',
    label: '保险公司',
    'min-width': 180
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'type',
    label: '险种'
  },
  slot: 'type'
}, orderDateSlot, priceSlot, countSlot, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  },
  slot: 'otherFee'
}, moneySlot, remarksSlot, actionItem];

// 保险详情
export const orderInsuranceDetail = [{
  attrs: {
    prop: 'insuranceName',
    label: '保险公司'
  }
}, {
  attrs: {
    prop: 'insuranceType',
    label: '险种'
  }
}, orderDate, price, count, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
}, money, remark];

// 接送
export const orderPickup = [{
  attrs: {
    prop: 'shuttleType',
    label: '类型',
    'min-width': 100
  },
  slot: 'shuttleType'
}, {
  attrs: {
    prop: 'shuttleDate',
    label: '日期',
    'min-width': 170
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'cityType',
    label: '地点类型',
    'min-width': 100
  },
  slot: 'cityType'
}, {
  attrs: {
    prop: 'timeAndTarget',
    label: '航班/车次/上下车点',
    'min-width': 240
  },
  slot: 'timeAndTarget'
}, {
  attrs: {
    prop: 'time',
    label: '时间',
    'min-width': 300
  },
  slot: 'time'
}, {
  attrs: {
    prop: 'desPosition',
    label: '航站楼/车站',
    'min-width': 130
  },
  slot: 'desPosition'
}, {
  attrs: {
    prop: 'adultCount',
    label: '大人数',
    'min-width': 80
  },
  slot: 'adultCount'
}, {
  attrs: {
    prop: 'childCount',
    label: '小孩数',
    'min-width': 80
  },
  slot: 'childCount'
}, {
  attrs: {
    prop: 'busCompanyName',
    label: '车辆公司',
    'min-width': width160
  },
  slot: 'busCompanyName'
}, {
  attrs: {
    prop: 'busType',
    label: '车辆类型',
    'min-width': width120
  },
  slot: 'busType'
}, {
  attrs: {
    prop: 'busFee',
    label: '车费',
    'min-width': width80
  },
  slot: 'busFee'
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width80
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: 'payType',
    label: '付款方式',
    'min-width': width120
  },
  slot: 'payType'
}, remarksSlot, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 180
  },
  slot: 'action'
}];

// 游客名单
export const orderTouristList = [{
  attrs: {
    prop: 'seqNumber',
    label: '序号',
    width: 50
  },
  slot: 'seqNumber'
}, {
  attrs: {
    prop: 'name',
    label: '姓名',
    width: 100
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码',
    width: 140
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型',
    'min-width': 120
  },
  slot: 'cardType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码',
    'min-width': 150
  },
  slot: 'cardNumber'
}, {
  attrs: {
    prop: 'age',
    label: '年龄',
    width: 60
  },
  slot: 'age'
}, {
  attrs: {
    prop: 'location',
    label: '所在地',
    'min-width': 150
  },
  slot: 'location'
}, {
  attrs: {
    prop: 'sex',
    label: '性别',
    width: 90
  },
  slot: 'sex'
}, {
  attrs: {
    prop: 'isContact',
    label: '是否是联系人',
    width: 70
  },
  slot: 'isContact'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    'min-width': 150
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 100
  },
  slot: 'action',
  header: 'deleteAction'
}];

/** **************************** 团队输单 列表 ******************* */

export const orderTeamItem1 = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': 150
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'state',
    label: '团状态'
  },
  slot: 'state'
}, {
  attrs: {
    prop: 'TripDate',
    label: '行程日期',
    'min-width': 100
  },
  slot: 'TripDate'
}];
export const orderTeamItem2 = [{
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'members',
    label: '联系人',
    'min-width': 100
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'itinerary',
    label: '行程'
  },
  slot: 'itinerary'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'guide',
    label: '团上导游'
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  },
  slot: 'totalSubsidy'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];

// 团队
export const orderTeam = [...orderTeamItem1, customerInfo, ...orderTeamItem2];
export const orderTeamNoCustom = [...orderTeamItem1, ...orderTeamItem2];

/** **************************** 散客输单 列表 ******************* */

// 散客输单 未拼团
export const orderPersonalItem1 = [{
  attrs: {
    prop: 'checkbox',
    label: '选择',
    'min-width': 50
  },
  slot: 'checkbox'
}, {
  attrs: {
    prop: 'state',
    label: '审核状态'
  },
  slot: 'state'
}, {
  attrs: {
    prop: 'orderInfo',
    label: '订单信息'
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}];

// 散客输单 未拼团
export const orderPersonalItem2 = [{
  attrs: {
    prop: 'accompanyInfo',
    label: '联系人',
    'min-width': 100
  },
  slot: 'accompanyInfo'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': 90
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'traffic',
    label: '班次信息',
    'min-width': 200
  },
  slot: 'traffic'
}, {
  attrs: {
    prop: 'itinerary',
    label: '行程',
    'min-width': 350
  },
  slot: 'itinerary'
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  },
  slot: 'totalSubsidy'
}, actionItem];

// 散客输单 未拼团
export const orderPersonal = [...orderPersonalItem1, customerInfo, ...orderPersonalItem2];

// 散客输单 未拼团
export const orderPersonalNoCustom = [...orderPersonalItem1, ...orderPersonalItem2];

// 散客输单 已拼团
export const orderPersonalDoneItem1 = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息'
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}];

// 散客输单 已拼团
export const orderPersonalDoneItem2 = [{
  attrs: {
    prop: 'accompanyInfo',
    label: '联系人',
    'min-width': 100
  },
  slot: 'accompanyInfo'
}, {
  attrs: {
    prop: 'date1',
    label: '行程日期',
    'min-width': 100
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'traffic',
    label: '班次信息',
    'min-width': 200
  },
  slot: 'traffic'
}, {
  attrs: {
    prop: 'itinerary',
    label: '行程',
    'min-width': 500
  },
  slot: 'itinerary'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 100
  },
  slot: 'action'
}];
// 散客输单 已拼团
export const orderPersonalDone = [...orderPersonalDoneItem1, customerInfo, ...orderPersonalDoneItem2];
// 散客输单 已拼团
export const orderPersonalDoneNoCustom = [...orderPersonalDoneItem1, ...orderPersonalDoneItem2];

// 散客拼团
export const orderPersonalMerge = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    'min-width': 300
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'productName',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'count',
    label: '参团人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'guide',
    label: '导游'
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': 120
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}];

/** **************************** 内转输单 列表 ******************* */

// 外转接收
export const orderTurnOutReceive = [{
  attrs: {
    prop: 'acceptStatus',
    label: '状态'
  },
  slot: 'acceptStatus'
}, {
  attrs: {
    prop: 'outCustomerName',
    label: '客户名称'
  }
}, {
  attrs: {
    prop: 'contactName',
    label: '客户联系人'
  },
  slot: 'contactName'
}, {
  attrs: {
    prop: 'orderType',
    label: '订单类型'
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'productName',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'members',
    label: '联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'numberKey',
    label: '接收单号'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '应收团款'
  }
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款给对方'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 150
  },
  slot: 'action'
}];

// 所有计划
export const orderAllPlan = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'planState',
    label: '团状态'
  },
  slot: 'planState'
}, {
  attrs: {
    prop: 'auditState',
    label: '审核状态'
  },
  slot: 'auditState'
}, {
  attrs: {
    prop: 'planType',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'outOPUsers',
    label: '客服计调'
  },
  slot: 'outOPUsers'
}, {
  attrs: {
    prop: 'saleOPUsers',
    label: '业务员'
  },
  slot: 'saleOPUsers'
}, {
  attrs: {
    prop: 'guide',
    label: '导游',
    'min-width': 200
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'totalQuotePay',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'unIncome',
    label: '未收'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossMargin',
    label: '人均毛利'
  }
}];
export const orderAllItem1 = [{
  attrs: {
    prop: 'orderInfo',
    label: '大团号',
    'min-width': 160
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号',
    'min-width': 100
  },
  slot: 'customerTripNumber'
}, {
  attrs: {
    prop: 'productName',
    label: '产品',
    'min-width': 80
  },
  slot: 'productName'
}, {
  attrs: {
    prop: 'orderStatus',
    label: '订单状态',
    'min-width': 100
  },
  slot: 'orderStatus'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': 100
  },
  slot: 'date'
}];
export const orderAllItem2 = [{
  attrs: {
    prop: 'guide',
    label: '导游',
    'min-width': 160
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': 100
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'members',
    label: '联系人',
    'min-width': 100
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'traffic',
    label: '接送信息',
    'min-width': 200
  },
  slot: 'shuttles'
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收',
    'min-width': 60
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收',
    'min-width': 60
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'totalIncomed',
    label: '已收',
    'min-width': 60
  },
  slot: 'totalIncomed'
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款',
    'min-width': 60
  },
  slot: 'totalSubsidy'
}, {
  attrs: {
    prop: 'unCollect',
    label: '未收',
    'min-width': 60
  },
  slot: 'unCollect'
}, {
  attrs: {
    prop: 'hotel',
    label: '酒店',
    'min-width': 120
  },
  slot: 'hotels'
}, {
  attrs: {
    prop: 'orderType',
    label: '类型'
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '输单人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '输单日期'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 140
  },
  slot: 'action'
}];
// 所有订单
export const orderAll = [...orderAllItem1, customerInfo, ...orderAllItem2];
// 所有订单
export const orderAllNoCustom = [...orderAllItem1, ...orderAllItem2];

// 所有订单2
export const orderAll2Item1 = [{
  attrs: {
    prop: 'orderInfo',
    label: '大团号',
    'min-width': 160
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号',
    'min-width': 100
  },
  slot: 'customerTripNumber'
}, {
  attrs: {
    prop: 'productName',
    label: '产品',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'orderStatus',
    label: '订单状态',
    'min-width': 70
  },
  slot: 'orderStatus'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': 100
  },
  slot: 'date'
}];

// 所有订单2
export const orderAll2Item2 = [{
  attrs: {
    prop: 'guide',
    label: '导游',
    'min-width': 160
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': 100
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'members',
    label: '联系人',
    'min-width': 100
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'traffic',
    label: '接送信息',
    'min-width': 200
  },
  slot: 'shuttles'
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收',
    'min-width': 60
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: 'feeStr',
    label: '应收明细',
    'min-width': 160
  },
  slot: 'feeStr'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收',
    'min-width': 60
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'hotel',
    label: '酒店',
    'min-width': 120
  },
  slot: 'hotels'
}, {
  attrs: {
    prop: 'orderType',
    label: '类型'
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '输单人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '输单日期'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    'min-width': 120
  },
  slot: 'action'
}];

// 所有订单2
export const orderAll2 = [...orderAll2Item1, customerInfo, ...orderAll2Item2];

// 所有订单2
export const orderAll2NoCustom = [...orderAll2Item1, ...orderAll2Item2];

// 回收站
export const orderRecycleItem1 = [{
  attrs: {
    prop: 'order',
    label: '订单信息'
  },
  slot: 'order'
}, {
  attrs: {
    prop: 'orderType',
    label: '类型',
    sortable: true,
    'min-width': 80
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调',
    sortable: true
  }
}];

// 回收站
export const orderRecycleItem2 = [{
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'createTime',
    label: '输单日期'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '输单人',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];

// 回收站
export const orderRecycle = [...orderRecycleItem1, customerInfo, ...orderRecycleItem2];

// 回收站
export const orderRecycleNoCustom = [...orderRecycleItem1, ...orderRecycleItem2];

// 车队
export const orderEditFleet = [{
  attrs: {
    prop: 'busCompanyName',
    label: '车辆公司'
  }
}, {
  attrs: {
    prop: 'busTypeName',
    label: '车辆类型'
  }
}, {
  attrs: {
    prop: 'driverName',
    label: '驾驶员'
  }
}, {
  attrs: {
    prop: 'driverPhone',
    label: '手机'
  }
}, {
  attrs: {
    prop: 'licenceNumber',
    label: '车牌号'
  }
}, {
  attrs: {
    prop: 'rowTotal',
    label: '金额'
  }
}];

// 首页订单
export const orderIndex = [{
  attrs: {
    prop: 'name',
    label: '销售'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'time',
    label: '时间',
    'min-width': 100
  }
}];

// 小团列表
export const planNumberList = [{
  attrs: {
    prop: 'orderNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'customerName',
    label: '组团社'
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: '',
    label: '接站牌'
  }
}, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'productName',
    label: '产品线路'
  }
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'members',
    label: '联系人',
    'min-width': 130
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '外联计调'
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  }
}];

// 黑名单查询信息
export const blackList = [{
  attrs: {
    prop: 'name',
    label: '名字'
  }
}, {
  attrs: {
    prop: 'cardNumber',
    label: '身份证'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号'
  }
},
// {
//   attrs: { prop: 'isCommon', label: '是否来过', slot: 'isCommon' },
// },
{
  attrs: {
    prop: 'times',
    label: '来过次数',
    width: 80
  },
  slot: 'times'
}, {
  attrs: {
    prop: 'scenic',
    label: '到过哪些景点'
  },
  slot: 'scenic'
}, {
  attrs: {
    prop: 'shops',
    label: '进过哪些购物店'
  },
  slot: 'shops'
}, {
  attrs: {
    prop: 'consume',
    label: '消费情况'
  },
  slot: 'consume'
}];

// 黑名单查询详细信息列表
export const blackListDetail = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 60
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'name',
    label: '名字'
  }
}, {
  attrs: {
    prop: 'date',
    label: '团期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'desProvince',
    label: '数据来源'
  },
  slot: 'desProvince'
}, {
  attrs: {
    prop: 'product',
    label: '线路'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 110
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'scenic',
    label: '到过哪些景点'
  },
  slot: 'scenic'
}, {
  attrs: {
    prop: 'shops',
    label: '进过哪些购物店'
  },
  slot: 'shops'
}, {
  attrs: {
    prop: 'consume',
    label: '消费情况'
  },
  slot: 'consume'
}];
// 黑名单查询详细信息列表
export const blackAllListDetail = [{
  attrs: {
    prop: 'index',
    label: '序号',
    'min-width': 40
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'name',
    label: '名字',
    'min-width': 60
  }
}, {
  attrs: {
    prop: 'cardNumber',
    label: '身份证',
    'min-width': 140
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号',
    'min-width': 90
  }
}, {
  attrs: {
    prop: 'startTripDate',
    label: '出团日期',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '完团日期',
    'min-width': 80
  }
}, {
  attrs: {
    prop: 'desProvince',
    label: '数据来源'
  },
  slot: 'desProvince'
}, {
  attrs: {
    prop: 'product',
    label: '产品线路'
  }
}, {
  attrs: {
    prop: 'addr',
    label: '客源地'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    'min-width': 110
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '备注',
    'min-width': 40
  },
  slot: 'action'
}];
// 订单审核
export const orderAudit = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': 120
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'orderDate',
    label: '行程日期'
  },
  slot: 'orderDate'
}, customerInfo, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'members',
    label: '联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'applyAudits',
    label: '变更内容',
    'min-width': 250
  },
  slot: 'applyAudits'
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  }
}, {
  attrs: {
    prop: 'applyStatus',
    label: '审核状态'
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100
  },
  slot: 'action',
  header: 'actionHead'
}];

// 审核订单进度查询
export const orderAuditProgress = [{
  attrs: {
    prop: 'index',
    label: '序号',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'reviewerName',
    label: '审核角色'
  }
}, {
  attrs: {
    prop: 'statusType',
    label: '审核结果'
  }
}, {
  attrs: {
    prop: 'userName',
    label: '审核人'
  }
}, {
  attrs: {
    prop: 'time',
    label: '审核时间'
  }
}];

// 控位输单
export const orderReserve = [{
  attrs: {
    prop: 'confirmStatus',
    label: '订单状态'
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'orderNumber',
    label: '订单编号'
  }
}, {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'productName',
    label: '产品'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'progress',
    label: '订单进度'
  },
  slot: 'progress'
}, {
  attrs: {
    prop: 'createUserName',
    label: '操作人'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 160
  },
  slot: 'action'
}];

// 订单管理
export const mallOrder = [{
  attrs: {
    prop: 'product',
    label: '产品',
    width: 260
  },
  slot: 'product'
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'contact',
    label: '联系人信息'
  },
  slot: 'contact'
}, {
  attrs: {
    prop: '',
    label: '日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'orderStatus',
    label: '订单状态',
    width: 60
  },
  slot: 'orderStatus'
}, {
  attrs: {
    prop: 'payStatus',
    label: '付款',
    width: 40
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'roomDifferencePrice',
    label: '房差'
  },
  slot: 'room'
}, {
  attrs: {
    prop: 'tourist',
    label: '游客信息'
  },
  slot: 'tourist'
}, {
  attrs: {
    prop: 'others',
    label: '其他支出'
  },
  slot: 'other'
}, {
  attrs: {
    prop: 'source',
    label: '来源',
    width: 60
  },
  slot: 'source'
}, {
  attrs: {
    prop: 'leaveWord',
    label: '游客备注'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '支出'
  }
}, {
  attrs: {
    prop: 'totalincome',
    label: '收入'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];