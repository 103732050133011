import "core-js/modules/es.array.reduce.js";
import { mapGetters } from 'vuex';
const resourceKeys = {
  guide: '导游',
  scenic: '景点',
  hotel: '房间',
  restaurant: '餐厅',
  fleet: '车辆公司',
  insurance: '保险公司',
  traffic: '大交通公司',
  pickup: '车辆公司',
  otherIncome: '其他收入',
  otherPay: '其他支出'
};
export default {
  props: {
    needId: {
      type: Boolean,
      default: true
    },
    id: {
      type: [String, Number],
      default: ''
    },
    scenicId: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'scenic'
    },
    useType: {
      type: [String, Number],
      default: ''
    },
    orderDate: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    resourceKeys
  }),
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getPlanNumber() {
      if (!this.planInfo) return '';
      const {
        orders
      } = this.planInfo;
      if (!orders || !orders.length) return '';
      return orders[0].customerTripNumber || orders[0].planCustomerNumber;
      // return this.planInfo
      //   ? this.planInfo.orders.length
      //     ? this.planInfo.orders[0].customerTripNumber || this.planInfo.orders[0].planCustomerNumber
      //     : ''
      //   : ''
    },
    getPlanList() {
      return this.planInfo ? this.formatTripNumber(this.planInfo.orders) : [];
    }
  },
  methods: {
    showTripList() {
      if (this.needId && !this.id) {
        const message = '请先选择' + this.resourceKeys[this.type];
        this.$bus.tip({
          type: 'warning',
          message
        });
        return;
      }
      this.$bus.emit(`${this.type}:list`, {
        currentPlanId: this.currentPlanId,
        id: this.id,
        scenicId: this.scenicId,
        orderDate: this.orderDate,
        useType: this.useType
      });
    },
    formatTripNumber(list) {
      if (!list || !list.length) return [];
      return list.reduce((acc, cur) => {
        acc = [...acc, cur.customerTripNumber];
        return acc;
      }, []);
    }
  }
};