export const KPIType2Label = {
  TURNOVER: '营业额',
  PROFIT: '毛利',
  NUMBER_OF_PEOPLE: '人数'
};
export const MonthObjKey = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  dece: 11,
  nov: 12
};
export const MonthKey = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'dece', 'nov'];
export const KPIType = {
  turnover: 'TURNOVER',
  profit: 'PROFIT',
  count: 'NUMBER_OF_PEOPLE'
};
export const KPIType2Prop = {
  TURNOVER: 'turnover',
  PROFIT: 'profit',
  NUMBER_OF_PEOPLE: 'count'
};

// 区域数据分析
export const areaAnalyze = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: '80'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'area',
    label: '区域'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: 'tagCount',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'count',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'peopleCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'peopleCompletionRate'
  }, {
    attrs: {
      prop: 'peopleYearRate',
      label: '完成年度进度',
      sortable: true
    },
    slot: 'peopleYearRate'
  }]
}, {
  attrs: {
    prop: 'date',
    label: '毛利'
  },
  children: [{
    attrs: {
      prop: 'tagProfit',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profit',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'difference',
      label: '差额',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profitYearRate',
      label: '完成率',
      sortable: true
    },
    slot: 'profitYearRate'
  }]
}];
// 数据分析
export const lineAnalyze = [{
  attrs: {
    prop: 'groupType',
    label: '团型'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '线路'
  }
}, {
  attrs: {
    prop: 'tagPer',
    label: '目标人均'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '实际人数'
  }
}, {
  attrs: {
    prop: '',
    label: '行程内毛利'
  },
  children: [{
    attrs: {
      prop: 'innerAmount',
      label: '金额'
    }
  }, {
    attrs: {
      prop: 'innerPer',
      label: '人均'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '业绩(购物)毛利'
  },
  children: [{
    attrs: {
      prop: 'shopAmount',
      label: '金额'
    }
  }, {
    attrs: {
      prop: 'shopPer',
      label: '人均'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '合计毛利'
  },
  children: [{
    attrs: {
      prop: 'totalAmount',
      label: '金额'
    }
  }, {
    attrs: {
      prop: 'totalPer',
      label: '人均'
    }
  }]
}, {
  attrs: {
    prop: 'difference',
    label: '差距'
  }
}];
// 团型数据分析
export const groupAnalyze = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 80
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'kpi',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'groupType',
    label: '团型'
  }
}, {
  attrs: {
    prop: '',
    label: '月度'
  },
  children: [{
    attrs: {
      prop: 'tag',
      label: '目标'
    }
  }, {
    attrs: {
      prop: 'reality',
      label: '实际完成'
    }
  }, {
    attrs: {
      prop: 'completeRate',
      label: '完成率'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '年度'
  },
  children: [{
    attrs: {
      prop: 'tagYear',
      label: '目标'
    }
  }, {
    attrs: {
      prop: 'yearRate',
      label: '完成进度'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '同期对比'
  },
  children: [{
    attrs: {
      prop: 'lastReality',
      label: '上年度'
    }
  }, {
    attrs: {
      prop: 'lastRealityRate',
      label: '比率(%)'
    }
  }]
}];
// 客户数据分析
export const customAnalyze = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: '80'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'area',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'name',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'province',
    label: '省份'
  }
}, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: 'tagCount',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'count',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'peopleCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'peopleCompletionRate'
  }, {
    attrs: {
      prop: 'peopleYearRate',
      label: '完成年度进度',
      sortable: true
    },
    slot: 'peopleYearRate'
  }]
}, {
  attrs: {
    prop: 'date',
    label: '营业额'
  },
  children: [{
    attrs: {
      prop: 'tagTurnover',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'turnover',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'turnoverDifference',
      label: '差额',
      sortable: true
    },
    slot: 'turnoverDifference'
  }, {
    attrs: {
      prop: 'turnoverCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'turnoverCompletionRate'
  }]
}, {
  attrs: {
    prop: 'date',
    label: '毛利'
  },
  children: [{
    attrs: {
      prop: 'tagProfit',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profit',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profitDifference',
      label: '差额',
      sortable: true
    },
    slot: 'profitDifference'
  }, {
    attrs: {
      prop: 'profitCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'profitCompletionRate'
  }]
}];

// 导游数据分析
export const guideAnalyze = [{
  attrs: {
    prop: 'guideName',
    label: '导游姓名'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '线路'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人次'
  }
}];
export const guideAnalyzeTotal = [{
  attrs: {
    prop: '',
    label: '合计'
  },
  children: [{
    attrs: {
      prop: 'totalBuyMoneyTotal',
      label: '购物金额'
    },
    slot: 'totalBuyMoneyTotal'
  }, {
    attrs: {
      prop: 'perTotal',
      label: '人均'
    },
    slot: 'perTotal'
  }, {
    attrs: {
      prop: 'tagPerTotal',
      label: '目标人均'
    },
    slot: 'tagPerTotal'
  }, {
    attrs: {
      prop: 'valueTotal',
      label: '增减值'
    },
    slot: 'valueTotal'
  }, {
    attrs: {
      prop: 'completeTotal',
      label: '完成率'
    },
    slot: 'completeTotal'
  }]
}];

// 应收明细表
export const incomeAnalyze = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 80
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'income',
    label: '应收款总额',
    sortable: true
  }
}, {
  attrs: {
    prop: 'incomed',
    label: '累计回款金额',
    sortable: true
  }
}, {
  attrs: {
    prop: 'unIncome',
    label: '应收款余额',
    sortable: true
  },
  slot: 'unIncome'
}, {
  attrs: {
    prop: 'incomedRate',
    label: '回款率',
    sortable: true
  },
  slot: 'incomedRate'
}, {
  attrs: {
    prop: 'area',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服'
  }
}];
// 应收明细表
export const incomeDetail = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 80
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'startTripMonth',
    label: '出团月份'
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'paymentCollectionMonth',
    label: '应回款月份'
  }
}, {
  attrs: {
    prop: 'totalFee',
    label: '团款金额'
  }
}, {
  attrs: {
    prop: 'paymentCollection',
    label: '回款金额',
    sortable: true
  }
}, {
  attrs: {
    prop: 'balance',
    label: '应收余额',
    sortable: true
  }
}, {
  attrs: {
    prop: 'accountAge',
    label: '账龄(月)'
  }
}, {
  attrs: {
    prop: 'overdue',
    label: '逾期(月)'
  }
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '业务员'
  }
}];

// 购物店数据分析
export const shopAnalyze = [{
  attrs: {
    prop: 'groupType',
    label: '团型'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '类型'
  }
}];
export const shopAnalyzeTotal = [{
  attrs: {
    prop: '',
    label: '合计'
  },
  children: [{
    attrs: {
      prop: 'totalBuyMoneyTotal',
      label: '金额'
    },
    slot: 'totalBuyMoneyTotal'
  }, {
    attrs: {
      prop: 'perTotal',
      label: '人均'
    },
    slot: 'perTotal'
  }]
}];

// 土产店分析表
export const soilShopAnalyze = [{
  attrs: {
    prop: 'groupType',
    label: '团型'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'totalShopRebate',
    label: '购物返佣'
  }
}, {
  attrs: {
    prop: 'per',
    label: '人均'
  }
}, {
  attrs: {
    prop: 'tagPer',
    label: '目标人均'
  }
}, {
  attrs: {
    prop: 'tagPer',
    label: '差额'
  }
}, {
  attrs: {
    prop: 'complete',
    label: '完成率'
  }
}];

// 业务员分析表
export const saleAnalyze = [{
  attrs: {
    prop: 'index',
    label: '序号'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'saleMan',
    label: '业务员'
  }
}, {
  attrs: {
    prop: '',
    label: '营业额'
  },
  children: [{
    attrs: {
      prop: 'tagTurnover',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'turnover',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'turnoverCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'turnoverCompletionRate'
  }, {
    attrs: {
      prop: 'turnoverYearRate',
      label: '完成年度进度',
      sortable: true
    },
    slot: 'turnoverYearRate'
  }]
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: 'tagCount',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'count',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'peopleCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'peopleCompletionRate'
  }, {
    attrs: {
      prop: 'peopleYearRate',
      label: '完成年度进度',
      sortable: true
    },
    slot: 'peopleYearRate'
  }]
}, {
  attrs: {
    prop: '',
    label: '毛利'
  },
  children: [{
    attrs: {
      prop: 'tagProfit',
      label: '目标',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profit',
      label: '实际完成',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'profitCompletionRate',
      label: '完成率',
      sortable: true
    },
    slot: 'profitCompletionRate'
  }, {
    attrs: {
      prop: 'profitYearRate',
      label: '完成年度进度',
      sortable: true
    },
    slot: 'profitYearRate'
  }]
}];
// 区域数据分析
export const customerKpiAnalyze = [{
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}];
export const customerKpiKey = customerKpiAnalyze.map(it => {
  return it.attrs.prop;
});
export const customerMonthKey = customerKpiAnalyze.map(it => {
  return it.attrs.label;
});

// 年龄段分析表
export const memberList = [{
  attrs: {
    prop: 'index',
    label: '序号'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'kpi',
    label: '年龄段'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'shopMoney',
    label: '营业额'
  }
}, {
  attrs: {
    prop: 'per',
    label: '人均购物'
  }
}];