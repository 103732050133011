import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    handleCopy(scope) {
      this.$parent.copyProduct(scope);
    },
    handleCustom(scope) {
      this.$parent.addCustom(scope);
    }
  }
};