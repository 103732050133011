var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_c("TopBar", {
    attrs: {
      title: "接送安排",
      disabled: _vm.isOnlyRowBtnDisable
    },
    on: {
      "on-add": _vm.handleAdaptPlus
    }
  }, [_c("div", {
    staticClass: "flex",
    attrs: {
      slot: "right"
    },
    slot: "right"
  }, [_c("SelectFleet", {
    staticClass: "ml20",
    attrs: {
      disabled: _vm.isOnlyRowBtnDisable,
      label: "批量车队选择",
      all: false
    },
    on: {
      "on-select": _vm.batchBusSelect
    },
    model: {
      value: _vm.batch.busCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.batch, "busCompanyName", $$v);
      },
      expression: "batch.busCompanyName"
    }
  }), _c("SelectDictionary", {
    staticClass: "ml20 mr20",
    attrs: {
      disabled: _vm.isOnlyRowBtnDisable,
      code: "busType",
      label: "批量车辆类型选择"
    },
    on: {
      "on-select": _vm.batchBusTypeSelect
    },
    model: {
      value: _vm.batch.busType,
      callback: function ($$v) {
        _vm.$set(_vm.batch, "busType", $$v);
      },
      expression: "batch.busType"
    }
  }), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.setDefaultBusInfo
    }
  }, [_vm._v(" 批量默认车队与车型 ")])], 1)]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("AuditStatus", {
          attrs: {
            row: scope.row
          }
        }), _c("CheckStatus", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("SelectPlanNumber", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectOrderNumber(scope.row, $event);
            }
          },
          model: {
            value: scope.row.customerOrderId,
            callback: function ($$v) {
              _vm.$set(scope.row, "customerOrderId", $$v);
            },
            expression: "scope.row.customerOrderId"
          }
        })];
      }
    }, {
      key: "busCompanyName",
      fn: function ({
        scope
      }) {
        return [_c("SelectFleet", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            label: "",
            all: false
          },
          on: {
            "on-select": function ($event) {
              return _vm.handleSelect(scope.row, $event);
            }
          },
          model: {
            value: scope.row.busCompanyName,
            callback: function ($$v) {
              _vm.$set(scope.row, "busCompanyName", $$v);
            },
            expression: "scope.row.busCompanyName"
          }
        })];
      }
    }, {
      key: "shuttleType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            type: "shuttleType",
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.changeShuttleType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.shuttleTypeLabel,
            callback: function ($$v) {
              _vm.$set(scope.row, "shuttleTypeLabel", $$v);
            },
            expression: "scope.row.shuttleTypeLabel"
          }
        })];
      }
    }, {
      key: "busType",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "flex ticket-type"
        }, [_c("SelectDictionary", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            code: "busType",
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectBusType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.busType,
            callback: function ($$v) {
              _vm.$set(scope.row, "busType", $$v);
            },
            expression: "scope.row.busType"
          }
        }), _c("el-button", {
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            type: scope.row.itemIndex > 0 ? "warning" : "primary",
            disabled: _vm.isOnlyRowDisable(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleRow(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.itemIndex > 0 ? "删除" : "添加") + " ")])], 1)];
      }
    }, {
      key: "mobilePhone",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            label: ""
          },
          model: {
            value: scope.row.driverPhone,
            callback: function ($$v) {
              _vm.$set(scope.row, "driverPhone", $$v);
            },
            expression: "scope.row.driverPhone"
          }
        })];
      }
    }, {
      key: "driver",
      fn: function ({
        scope
      }) {
        return [_c("el-autocomplete", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            "fetch-suggestions": _vm.driverSearch
          },
          on: {
            change: function ($event) {
              return _vm.selectDriver(scope, $event);
            },
            select: function ($event) {
              return _vm.selectDriver(scope, $event);
            }
          },
          model: {
            value: scope.row.driverName,
            callback: function ($$v) {
              _vm.$set(scope.row, "driverName", $$v);
            },
            expression: "scope.row.driverName"
          }
        })];
      }
    }, {
      key: "licenceNumber",
      fn: function ({
        scope
      }) {
        return [_vm.busList ? [_c("el-autocomplete", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            "fetch-suggestions": _vm.licenceSearch
          },
          on: {
            change: function ($event) {
              return _vm.selectLicence(scope.row, $event);
            },
            select: function ($event) {
              return _vm.selectLicence(scope.row, $event);
            }
          },
          model: {
            value: scope.row.licenceNumber,
            callback: function ($$v) {
              _vm.$set(scope.row, "licenceNumber", $$v);
            },
            expression: "scope.row.licenceNumber"
          }
        })] : _vm._e()];
      }
    }, {
      key: "startDate",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            "value-format": "yyyy-MM-dd",
            label: "",
            disabled: _vm.isOnlyRowDisable(scope.row) || scope.row.shuttleType == "send"
          },
          on: {
            change: function ($event) {
              return _vm.changeStartDate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.startDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "startDate", $$v);
            },
            expression: "scope.row.startDate"
          }
        })];
      }
    }, {
      key: "endDate",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            "value-format": "yyyy-MM-dd",
            label: "",
            disabled: _vm.isOnlyRowDisable(scope.row) || scope.row.shuttleType == "pick"
          },
          on: {
            change: function ($event) {
              return _vm.changeEndDate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.endDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "endDate", $$v);
            },
            expression: "scope.row.endDate"
          }
        })];
      }
    }, {
      key: "busFee",
      fn: function ({
        scope
      }) {
        return [_c("InputNum", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "busFee", $event);
            }
          },
          model: {
            value: scope.row.busFee,
            callback: function ($$v) {
              _vm.$set(scope.row, "busFee", $$v);
            },
            expression: "scope.row.busFee"
          }
        })];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_c("InputNum", {
          attrs: {
            disabled: _vm.isOnlyRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "otherFee", $event);
            }
          },
          model: {
            value: scope.row.otherFee,
            callback: function ($$v) {
              _vm.$set(scope.row, "otherFee", $$v);
            },
            expression: "scope.row.otherFee"
          }
        })];
      }
    }, {
      key: "rowTotal",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.rowTotal) + " ")];
      }
    }, {
      key: "payType",
      fn: function ({
        scope
      }) {
        return [_c("SelectPayType", {
          attrs: {
            row: scope.row,
            "is-disabled": _vm.isOnlyRowDisable(scope.row)
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.remark,
            callback: function ($$v) {
              _vm.$set(scope.row, "remark", $$v);
            },
            expression: "scope.row.remark"
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "bill",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleBill(scope.row.id);
            }
          }
        }, [_vm._v(" 司机通知单 ")])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("DelCheck", {
          attrs: {
            row: scope.row,
            disabled: _vm.isOnlyRowDisable(scope.row)
          },
          on: {
            "on-check": _vm.handleCheckOne,
            "on-remove": _vm.handleRemove,
            "on-checkout": _vm.handleCheckout
          }
        })];
      }
    }, {
      key: "deleteAction",
      fn: function ({
        scope
      }) {
        return [_c("DelCheckAll", {
          attrs: {
            row: scope.row,
            checkval: _vm.checkedAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.checkedAll = $event;
            },
            "on-check": _vm.handleCheckAll,
            "on-remove": _vm.handleRemoveAll
          }
        })];
      }
    }, {
      key: "audit",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("Audit", {
          attrs: {
            row: scope.row
          },
          on: {
            "on-check": _vm.checkAuditSelectAll
          }
        }) : _c("AuditAdmin", {
          attrs: {
            row: scope.row
          },
          model: {
            value: scope.row.auditProgress,
            callback: function ($$v) {
              _vm.$set(scope.row, "auditProgress", $$v);
            },
            expression: "scope.row.auditProgress"
          }
        })];
      }
    }, {
      key: "auditAction",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("AuditCheck", {
          attrs: {
            row: scope.row,
            checkval: _vm.auditAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.auditAll = $event;
            },
            "on-check": _vm.checkAuditAll
          }
        }) : _c("span", [_c("AuditRoleAll", {
          on: {
            "on-select": _vm.handleAuditAll
          }
        })], 1)];
      }
    }])
  }), _c("PaymentTotal", {
    attrs: {
      "table-data": _vm.tableData
    }
  }), _c("DialogOrder"), _c("DialogList")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };