var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "车辆询价单",
      width: "860px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-descriptions", {
    staticClass: "margin-top",
    attrs: {
      title: "",
      column: 1,
      border: ""
    }
  }, [_c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 抵离时间/车次 ")]), _vm._v(" " + _vm._s(_vm.form.arriveDate) + " " + _vm._s(_vm.form.leaveDate) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 航班/车次 ")]), _vm._v(" " + _vm._s(_vm.form.shiftNumber) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 用车天数 ")]), _vm._v(" " + _vm._s(_vm.form.useDays) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 人数 ")]), _vm._v(" " + _vm._s(_vm.form.adultCount) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 车型 ")]), _vm._v(" " + _vm._s(_vm.form.busType) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 车龄 ")]), _vm._v(" " + _vm._s(_vm.form.busAge) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 团型 ")]), _vm._v(" " + _vm._s(_vm.form.groupType) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 用车数量 ")]), _vm._v(" " + _vm._s(_vm.form.useCount) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 司机服务要求 ")]), _vm._v(" " + _vm._s(_vm.form.driverAsk) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 用车行程 ")]), _vm._v(" " + _vm._s(_vm.form.itinerary) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 报价")]), _vm._v(" " + _vm._s(_vm.form.fee) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 报价备注")]), _vm._v(" " + _vm._s(_vm.form.remarks) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 询价人")]), _vm._v(" " + _vm._s(_vm.form.inquiryUser) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 询价时间")]), _vm._v(" " + _vm._s(_vm.getInquireDate) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 报价人")]), _vm._v(" " + _vm._s(_vm.form.quotationUser) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 报价时间")]), _vm._v(" " + _vm._s(_vm.getQuotationDate) + " ")], 2), _c("el-descriptions-item", [_c("template", {
    slot: "label"
  }, [_vm._v(" 响应时间")]), _vm._v(" " + _vm._s(_vm.form.responseTime) + " ")], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };