import "core-js/modules/es.array.push.js";
import { TableTotalBox } from '@/components/common';
import { orderBus } from '@/config/report';
import { searchMixin, tagViewMixin } from '@/mixin';
import SearchForm from './SearchForm';
import TableFleet from './components/TableFleet';
import { mapGetters } from 'vuex';
import { TYPES } from '@/config/resource';
import { parseDate } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    TableTotalBox,
    SearchForm,
    TableFleet
  },
  mixins: [searchMixin, tagViewMixin],
  data: () => ({
    tableLoading: false,
    columnData: orderBus,
    tableData: [],
    total: 0,
    statisticData: [],
    statistic: 0,
    searchParams: {
      resourceType: TYPES.bus.toUpperCase(),
      startTripDateS: today
    }
  }),
  computed: {
    ...mapGetters({
      busOrderData: 'report/index/busOrderData'
    })
  },
  watch: {
    busOrderData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      if (!statistics) return;
      this.statistic = statistics;
      this.statistic.number = `${statistics.adultCount}大${statistics.childCount}小${statistics.accompanyCount}陪`;
      this.statisticData.push(statistics);
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/fetchOrderResource', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    }
  }
};