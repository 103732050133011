import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import XLSX from 'xlsx/xlsx.mini.js';
// import { renderAsync } from 'docx-preview'

export default {
  data() {
    return {
      visible: false,
      excelData: {}
    };
  },
  mounted() {},
  methods: {
    show(path) {
      this.visible = true;
      this.getFile(path);
    },
    hide() {
      this.visible = false;
    },
    getFile(path) {
      this.$store.dispatch('resource/common/getOssImage', path).then(data => {
        if (data && data.length) {
          const url = data[0];
          console.log('url:', url);

          // https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}

          // requestBlob({ url }).then(res => {

          // })
        }
      });
    },
    readerExcel(res) {
      const reader = new FileReader();
      //通过readAsArrayBuffer将blob转换为ArrayBuffer对
      reader.readAsArrayBuffer(res.data); // 这里的res.data是blob文件流
      reader.onload = event => {
        // 读取ArrayBuffer数据变成Uint8Array
        var data = new Uint8Array(event.target.result);
        // 这里的data里面的类型和后面的type类型要对应
        var workbook = XLSX.read(data, {
          type: 'array'
        });
        var sheetNames = workbook.SheetNames; // 工作表名称
        var worksheet = workbook.Sheets[sheetNames[0]];
        // var excelData = XLSX.utils.sheet_to_json(worksheet); //JSON
        let html = XLSX.utils.sheet_to_html(worksheet);
        document.getElementsByClassName('xlsxClass')[0].innerHTML = html;
      };
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      // this.readerData(rawFile)
      this.readerExcel(rawFile);
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, {
            type: 'array'
          });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({
            header,
            results
          });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    generateData({
      header,
      results
    }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({
          c: C,
          r: R
        })];
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};