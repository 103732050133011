import { SelectLine } from '@/components/common';

// const defaultTag = () => ({
//   productId: 0,
//   tid: 0,
//   type: '',
// })
const defaultItinerary = ({
  productId,
  title,
  day
}) => ({
  breakfast: false,
  breakfastDesc: '',
  dinner: false,
  dinnerDesc: '',
  endPoint: '',
  hotelId: 0,
  hotelName: '',
  itineraryDesc: '',
  lunch: false,
  lunchDesc: '',
  startPoint: '',
  traffic: '',
  title,
  productId,
  whichDay: day
});
const defaultForm = () => ({
  name: '',
  days: 0,
  resPId: 0,
  travelLineId: 0,
  departureCity: '',
  arrivalCity: '',
  source: '',
  feature: '',
  feeExclude: '',
  feeInclude: '',
  friendlyTips: '',
  innerRemarks: '',
  serviceStandard: '',
  isHot: true,
  isRecommend: true,
  thumbnailUrl: '',
  isEnable: true,
  itinerarys: [],
  plans: [],
  tags: [],
  tpyes: [],
  bannerPics: []
});
export default {
  components: {
    SelectLine
  },
  data() {
    return {
      visible: false,
      form: defaultForm(),
      rules: {
        name: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }],
        days: [{
          required: true,
          message: '请输入产品天数',
          trigger: 'blur'
        }],
        travelLineId: [{
          required: true,
          message: '请选择产品所属线路',
          trigger: 'change'
        }],
        departureCity: [{
          required: true,
          message: '请输入出发城市',
          trigger: 'blur'
        }],
        arrivalCity: [{
          required: true,
          message: '请输入到达城市',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '更新商城产品' : '同步商城产品';
    }
  },
  methods: {
    show(row) {
      const {
        id,
        remarks
      } = row;
      this.visible = true;
      this.form = {
        ...this.form,
        ...row
      };
      this.form.resPId = id;
      this.form.innerRemarks = remarks;
      this.formatItinerary(row);
    },
    hide() {
      this.visible = false;
    },
    formatItinerary(data) {
      const {
        id,
        days,
        itineraries
      } = data;
      if (!itineraries.length) {
        this.form.itinerarys = Array.from({
          length: days
        }).map((_, i) => defaultItinerary({
          productId: id,
          title: `第${i + 1}天`,
          day: i + 1
        }));
      } else {
        this.form.itinerarys = itineraries;
      }
    },
    handleConfirm() {
      const opt = {
        ...this.form
      };
      delete opt.id;
      delete opt.itineraries;
      const {
        id,
        mallStatus
      } = this.form;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$store.dispatch('resource/product/createMallProduct', opt).then(() => {
            this.$store.dispatch('resource/product/toggleRelease', {
              id,
              status: !mallStatus
            });
            this.$parent.fetchData();
            this.$bus.tip();
            this.hide();
          });
        }
      });
    }
  }
};