/* eslint-disable no-unused-vars */
import { fetchGuide, fetchDetail, fetchList, createOrUpdateGuide, deleteGuide, updatePwd, fetchLog } from '@/api/resource/guide';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { TYPES, DICTYPES } from '@/config/resource';
import { formatCharData, formatDicData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  guideData: null,
  guideDicData: null,
  guideDataOrigin: null,
  guideLog: null,
  guideDicDataPage: null
});
const state = dfaultState();
const getters = {
  guideData: state => state.guideData,
  guideDicData: state => state.guideDicData,
  guideDataOrigin: state => state.guideDataOrigin,
  guideLog: state => state.guideLog,
  guideDicDataPage: state => state.guideDicDataPage
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchGuide(queryParam).then(res => {
      const {
        data
      } = res;
      data.list.map(it => {
        it.type = it.type.split('|')[0];
      });
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  findGuideByName({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchGuide(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  findById({
    commit
  }, id) {
    return fetchDetail(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', Object.freeze(data));
      return data;
    });
  },
  // 查询旅监导游局字典
  getTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.guide
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.guideDicData ? state.guideDicData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatDicData(list, 'guide'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA_GUIDEDIC', data);
      return originData;
    });
  },
  // 查询旅监导游字典名字搜搜
  searchTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.guide
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      data.list = formatDicData(data.list, 'guide');
      commit('FETCH_DATA_GUIDEDIC', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.guide
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.guide
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  createOrUpdate({
    dispatch
  }, opts) {
    // 删除图片操作
    this.dispatch('resource/common/deleteCacheImage');
    return createOrUpdateGuide(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      if (!delFiles.length) return;
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteGuide(id);
  },
  // eslint-disable-next-line no-empty-pattern
  updatePwd({}, opts) {
    return updatePwd(opts);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.guideData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.guideDataOrigin = data;
  },
  FETCH_DATA_GUIDEDIC: (state, data) => {
    state.guideDicData = data;
  },
  FETCH_DATA_GUIDEDIC_PAGE: (state, data) => {
    state.guideDicDataPage = data;
  },
  FETCH_LOG: (state, data) => {
    state.guideLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.guideData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.guideData.list.filter(it => it.id !== id);
    state.guideData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};