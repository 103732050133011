export const Columns = [{
  attrs: {
    label: '交通公司',
    prop: 'transportName'
  }
}, {
  attrs: {
    label: '类型',
    prop: 'transportType'
  }
}, {
  attrs: {
    label: '日期',
    prop: 'orderDate'
  }
}, {
  attrs: {
    label: '人数',
    prop: 'count'
  }
}, {
  attrs: {
    label: '单价',
    prop: 'price'
  }
}, {
  attrs: {
    label: '金额',
    prop: 'money'
  }
}];