var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "case form-wrapper"
  }, [_c("Search", {
    on: {
      search: _vm.initData,
      add: _vm.onAdd
    }
  }), _c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "公示栏",
      name: "announcement"
    }
  }, [_c("TableNew", {
    attrs: {
      columns: _vm.ColumnsShow,
      data: _vm.tableDataShow,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("el-link", {
          staticClass: "mr5",
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.onDetail(row);
            }
          }
        }, [_vm._v(" 查看 ")])];
      }
    }])
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "我的案例库",
      name: "me"
    }
  }, [_c("TableNew", {
    attrs: {
      columns: _vm.Columns,
      data: _vm.tableData,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        row
      }) {
        return [_c("el-link", {
          attrs: {
            type: _vm.getStatusType(row.caseStatus)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatusText(row.caseStatus)) + " ")])];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("el-link", {
          staticClass: "mr5",
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.onDetail(row);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-link", {
          staticClass: "mr5",
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.onEdit(row);
            }
          }
        }, [_vm._v(" 修改 ")]), _c("el-link", {
          staticClass: "mr5",
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.onDel(row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1)], 1), _c("DialogAdd", {
    ref: "dialogAddRef",
    on: {
      reload: _vm.reload
    }
  }), _c("DialogEdit", {
    ref: "dialogEditRef",
    on: {
      reload: _vm.reload
    }
  }), _c("DialogDetail", {
    ref: "dialogDetailRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };