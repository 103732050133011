import { hasCustomeAuth } from '@/utils';
import { SelectOutCustom } from '@/components/common';
import TableTeam from './TableTeam.vue';
import TableOrder from './TableOrder.vue';
const TYPE_MAP = {
  team: 'team',
  // 整团
  order: 'order' // 订单
};
export default {
  name: 'DialogAuditDetail',
  components: {
    SelectOutCustom,
    TableTeam,
    TableOrder
  },
  data() {
    return {
      visible: false,
      customerId: '',
      typeMap: TYPE_MAP,
      activeName: TYPE_MAP.team,
      teamData: [],
      orderData: []
    };
  },
  computed: {
    visibleCustom() {
      return hasCustomeAuth(this.$store);
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.formatData(data);
    },
    hide() {
      this.visible = false;
      this.customerId = '';
      this.teamData = [];
      this.orderData = [];
    },
    formatData(list) {
      this.orderData = list;
      this.teamData = list;
    },
    handleConfirm() {
      const isOrderType = this.activeName == TYPE_MAP.order;
      const data = isOrderType ? this.orderData.filter(it => it.customerIdNew != '') : this.teamData;
      const type = '大人价';
      const times = 1;
      if (!data.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选择外转的订单'
        });
        return;
      }
      const dataList = data.map(it => {
        const {
          id: customerOrderId,
          customerIdNew,
          startTripDate,
          endTripDate,
          fee,
          proxy,
          subsidy
        } = it;
        const obj = {
          contactName: '',
          customerId: isOrderType ? customerIdNew : this.customerId,
          customerOrderId,
          endTripDate,
          startTripDate
        };
        const collectList = [{
          total: proxy,
          type: '代收'
        }];
        const feeList = [{
          count: 1,
          price: fee,
          total: fee,
          times,
          type
        }];
        const subsidyList = [{
          count: 1,
          price: subsidy,
          total: subsidy,
          times,
          type: '返款'
        }];
        return {
          ...obj,
          collectList: proxy != 0 ? collectList : [],
          feeList: fee != 0 ? feeList : [],
          subsidyList: subsidy != 0 ? subsidyList : []
        };
      });
      this.$store.dispatch('order/reception/turnOutBatch', dataList).then(() => {
        this.hide();
        this.$bus.tip();
        this.$parent.init();
      }).catch(err => {
        this.$bus.tip({
          message: err.message
        });
      });
    }
  }
};