import { downloadExport } from '@/utils/exportExcel';
import { customCollect } from '@/config/report';
import { SelectCustom, BtnExport } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { getSummariesMixin, searchMixin } from '@/mixin';

// import { parseDate } from '@/utils'
// const today = parseDate(new Date(), '{y}-{m}-{d}')
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectCustom,
    BtnExport
  },
  mixins: [getSummariesMixin, searchMixin],
  data: () => ({
    columnData: customCollect,
    tableData: [],
    // sendDate: [today, ''], //today
    reportPlanType: SELECTOR.reportPlanType,
    searchParams: {
      startTripDateS: '',
      startTripDateE: '',
      customerId: '',
      customerName: '',
      orderType: null
    },
    statisticsKeys: ['orderCount', 'adultCount', 'childCount', 'accompanyCount', 'totalIncome', 'collect', 'unIncome']
  }),
  computed: {
    ...mapGetters({
      customerIncomeData: 'report/index/customerIncomeData'
    })
  },
  watch: {
    customerIncomeData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.tableData = data;
      }
    },
    // 选择发团日期
    onChangStartTripDate(val) {
      this.searchParams.startTripDateS = val[0];
      this.searchParams.startTripDateE = val[1];
    },
    // 选择客户
    selectCustom(val) {
      this.searchParams.customerLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    // 选择计划类型
    selectReportPlan(val) {
      this.searchParams.orderType = val.value;
    },
    fetchData(searchParams) {
      this.$store.dispatch('report/index/getCustomerIncome', searchParams);
    },
    exportExcel() {
      const loading = this.$bus.loading();
      const searchParams = this.searchParams;
      this.$store.dispatch('report/index/fetchCustomerIncomeExcel', searchParams).then(res => {
        const fileName = '客户应收报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};