import TableNative from '@/components/common/Table/TableNative';
import RemarkList from '../../components/RemarkList';
import utilsMixin from './utilsMixin';
const columnTrip = [{
  attrs: {
    label: '日期',
    prop: 'itineraryDate',
    width: 80
  }
}, {
  attrs: {
    label: '行程',
    prop: 'scenicName'
  }
}, {
  attrs: {
    label: '酒店',
    prop: 'roomNames',
    align: 'center',
    width: 200
  },
  slot: 'roomNames'
}, {
  attrs: {
    label: '用餐',
    prop: 'mealTypes',
    children: [{
      attrs: {
        label: '早',
        prop: 'breakfastRestaurant',
        width: 50
      }
    }, {
      attrs: {
        label: '中',
        prop: 'lunchRestaurant',
        width: 50
      }
    }, {
      attrs: {
        label: '晚',
        prop: 'dinnerRestaurant',
        width: 50
      }
    }]
  }
}];
export default {
  components: {
    TableNative,
    RemarkList
  },
  mixins: [utilsMixin],
  props: {
    mode: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: 3,
      columnTrip
    };
  }
};