import BaseInfo from '../components/BaseInfo.vue';
import DialogForm from '../components/DialogForm.vue';
import { FooterBar, BillInput } from '@/components/common';
import { RecordColumn as columns } from '@/config/billDown/payRegiment';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { TYPES, CashFeeType } from '@/config/billDown';
const infoList = [{
  label: '大团号',
  prop: 'planNumber'
}, {
  label: '人数',
  prop: 'personCount'
}, {
  label: '外联计调',
  prop: 'outOPUserName'
}, {
  label: '客户',
  prop: 'customerName'
}, {
  label: '出游时间',
  prop: 'startTripDate'
}, {
  label: '完成时间',
  prop: 'endTripDate'
}, {
  label: '流水号',
  prop: 'orderNumber'
}, {
  label: '应收',
  prop: 'totalIncome',
  cls: 'red'
}, {
  label: '已收',
  prop: 'totalIncomed',
  cls: 'red'
}, {
  label: '返款',
  prop: 'totalSubsidy',
  cls: 'red'
}, {
  label: '欠收',
  prop: 'unTotalIncome',
  cls: 'red'
}, {
  label: '游客姓名',
  prop: 'memberName',
  cls: 'red'
}];
export default {
  components: {
    BaseInfo,
    FooterBar,
    BillInput,
    DialogForm
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      TYPES,
      CashFeeType,
      columns,
      infoList,
      tableData: [],
      baseInfo: null,
      orderId: '',
      total: 0,
      resourceId: ''
    };
  },
  computed: {
    getBaseInfo() {
      if (!this.baseInfo) return;
      return {
        ...this.baseInfo,
        money: this.baseInfo.unTotalIncome
      };
    }
  },
  created() {
    const {
      id: orderId,
      rid
    } = this.$route.query;
    this.resourceId = rid;
    this.orderId = orderId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchItem();
      this.fetchList();
    },
    fetchItem() {
      this.$store.dispatch('bill/billDown/collectRegimentCustomer/fetchOne', {
        prId: this.orderId
      }).then(data => {
        if (!data) return;
        this.setBaseInfo(data.list);
      });
    },
    // 付款记录列表
    fetchList(params = {}) {
      return this.$store.dispatch('bill/billDown/collect/fetchCollected', {
        orderId: this.orderId,
        cashType: TYPES.customer,
        ...params
      }).then(data => {
        if (!data) return;
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    setBaseInfo(list) {
      const findItem = list.find(it => it.rowId == this.orderId);
      const {
        accompanyCount,
        adultCount,
        childCount
      } = findItem;
      const {
        members
      } = findItem;
      const personCount = `${adultCount}大${childCount}小${accompanyCount}陪`;
      const memberName = members.map(it => it.name).join(',');
      this.baseInfo = {
        ...findItem,
        personCount,
        memberName
      };
    },
    // 修改
    editPayed({
      id,
      cashLogId
    }) {
      const {
        customerName,
        unTotalIncome
      } = this.getBaseInfo;
      this.$store.dispatch('bill/billDown/collect/fetchDetail', cashLogId).then(data => {
        if (!data) return;
        this.$refs.dialogRef.show({
          updateId: id,
          unTotalIncome,
          ...data,
          resourceName: customerName
        });
      });
    },
    // 删除
    handleDelete({
      id
    }) {
      this.$bus.open(() => {
        const opts = {
          cashType: TYPES.customer,
          ids: [id]
        };
        this.$store.dispatch('bill/billDown/collect/deleteCollect', opts).then(() => {
          this.fetchData();
        });
      });
    },
    // 关闭
    handleClose() {
      // mixin 方法
      this.closeSelectedTag();
    },
    formatData(data) {
      const {
        rowId: orderId,
        customerId,
        customerName
      } = this.baseInfo;
      const {
        cashWay,
        cashUserId,
        money,
        updateId
      } = data;
      const cashItem = {
        cash: money,
        cashLogId: 0,
        id: updateId || '',
        cashUserId,
        cashWay,
        orderId,
        customerFeeType: 'FEE'
      };
      const opts = {
        ...data,
        resourceId: customerId,
        cashAction: CashFeeType.INCOME,
        cashType: TYPES.customer,
        resourceName: customerName,
        cashes: [cashItem]
      };
      return opts;
    },
    // 保存
    handleSave() {
      const formItem = this.$refs.formItem;
      formItem.validate().then(valid => {
        if (!valid) return;
        const opts = this.formatData(formItem.form);
        this.$store.dispatch('bill/billDown/collect/addCollect', opts).then(() => {
          this.$bus.tip();
          this.fetchList();
        });
      });
    },
    // 更新
    handleUpdate(data) {
      // const formItem = this.$refs.formItem
      const opts = this.formatData(data);
      this.$store.dispatch('bill/billDown/pay/updatePayed', opts).then(() => {
        this.$bus.tip();
        this.fetchList();
      });
    }
  }
};