import "core-js/modules/es.array.push.js";
import DialogUploadFile from '../components/DialogUploadFile';
import DialogUploadFilePreview from '../components/DialogUploadFilePreview';
import { tableMixin, summaryMixin, systemMixin } from '@/mixin';
import orderMixin from '../orderMixin';
import { orderType as OrderType } from '@/config/order';
import { BtnLink, StatusOrderTeam, StatusProgress, Guides, CustomerInfo } from '@/components/common';
import { Members, PersonCount } from '@/components/common';
import { TypeOrder } from '@/components/common';
export default {
  components: {
    DialogUploadFile,
    DialogUploadFilePreview,
    BtnLink,
    StatusOrderTeam,
    StatusProgress,
    TypeOrder,
    Guides,
    CustomerInfo,
    Members,
    PersonCount
  },
  mixins: [tableMixin, summaryMixin, systemMixin, orderMixin],
  data() {
    return {
      statisticsKeys: ['number', 'totalIncome', 'totalCollect', 'totalIncomed', 'totalSubsidy', 'unCollect'],
      link: {
        team: '/order/team-input-detail',
        personal: '/order/personal-input-detail'
      }
    };
  },
  computed: {
    hasHotel() {
      return item => {
        const res = !item.hotelStandard && !item.hotelArea;
        return res;
      };
    },
    getUnCollect() {
      return item => {
        const {
          totalIncome,
          totalSubsidy,
          totalCollect,
          totalIncomed
        } = item;
        const res = totalIncome - totalSubsidy - totalCollect - totalIncomed;
        return res;
      };
    },
    getShuttleType() {
      return item => {
        return item.shuttleType == 'CITY' ? '【市内中转】' : item.shuttleType == 'SEND' ? '【送团】' : item.shuttleType == 'PICK' ? '【接团】' : '';
      };
    },
    getTaskType() {
      return item => {
        return item.taskType == 'TRAIN' ? '火车' : item.taskType == 'PLANE' ? '飞机' : item.taskType == 'CITY' ? '市内中转' : '';
      };
    },
    hasOutOrder() {
      return row => {
        return row.outOrderCountInfo.outCount > 0;
      };
    },
    getOutStatus() {
      return row => {
        const {
          outOnLineCount,
          outOnLineAcceptCount
        } = row;
        return outOnLineCount > 0 && outOnLineAcceptCount > 0;
      };
    }
  },
  methods: {
    linkDetail({
      row
    }) {
      const {
        id,
        orderType
      } = row;
      const path = orderType == OrderType.team ? this.link.team : this.link.personal;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path,
        query: {
          id,
          type: 'detail',
          planType: orderType
        }
      });
    },
    handlePrint(scope) {
      const {
        id,
        planId,
        orderType
      } = scope.row;
      // 打印页面的打印权限类型
      const gateUrl = orderType == OrderType.team ? 'team' : 'personal';
      const component = orderType == OrderType.team ? 'Receipt' : 'PlanPersonal';
      const routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId,
          component,
          planType: orderType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleUpload({
      id
    }) {
      this.$refs.uploadFileRef.show(id);
    },
    tableCellClick(row, column, event) {
      if (!this.comparePlanStatus(row)) {
        event.preventDefault();
        return false;
      }
    },
    // 显示确认件
    showConfirmFile({
      confirmedItemFilePath: name
    }) {
      this.getFile(name);
      // this.$refs.filePreviewRef.show(name)
    },
    getFile(path) {
      this.$store.dispatch('resource/common/getOssImage', path).then(data => {
        if (data && data.length) {
          const url = data[0];
          const [, suffix] = path.split('.');
          if (['dox', 'docx', 'xlsx', 'xls'].includes(suffix)) {
            const path = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
            window.open(path, '_blank');
          } else {
            // 图片
            console.log('图片');
            window.open(url, '_blank');
          }
        }
      });
    },
    uploadConfirmFile(opt) {
      this.$store.dispatch('order/index/uploadConfirmFile', opt).then(() => {
        this.$bus.tip();
      });
    }
  }
};