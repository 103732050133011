// import dayjs from 'dayjs'
import DialogStation from './DialogStation';
import { SelectDictionary, SelectFleet } from '@/components/common';
import { dictionaryMixin, formMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { parseTime, parseDate, isTeamType } from '@/utils';
import { SELECTOR } from '@/config/select';
import { DICTIONARY } from '@/config';
import { defDictionary, getDictionaryValueByName } from '@/utils/dictionary';
const defaultState = () => ({
  adultCount: '',
  childCount: '',
  shuttleDate: '',
  shiftDateTime: '',
  // shuttleType: 'pick', // 接送类型
  // stationType: 'train', // 站点类型
  // taskType: 'train', // 接送地点类型
  // cityType: 'start_trip_together', // 市区中转类型
  shuttleType: '接团',
  // 接送类型
  stationType: '火车站',
  // 站点类型
  taskType: '火车站',
  // 接送地点类型
  cityType: '散团回送',
  // 市区中转类型
  togetherPosition: '',
  // 上下车集合点
  shiftNumber: '',
  desPosition: '',
  hotelAddress: '',
  hotelName: '',
  startPosition: '',
  endPosition: '',
  remarks: ''
});
const defaultFleetTypes = () => ({
  otherFee: 0,
  busType: '',
  busFee: 0
});
const defaultFleetData = () => ({
  busCompanyName: '',
  busCompanyId: -1,
  payTypeName: '挂账',
  payType: 'sign',
  totalPay: 0
});
export default {
  components: {
    SelectDictionary,
    SelectFleet,
    DialogStation
  },
  mixins: [dictionaryMixin, formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {
        ...defaultState(),
        ...defaultFleetTypes(),
        ...defaultFleetData()
      },
      targetLabel: '抵达时间',
      targetType: 1,
      btnLoading: false,
      currentOrderData: '',
      validateForm: {
        adultCount: [{
          required: true,
          message: '大人数不能为空！'
        }],
        desPosition: [{
          required: true,
          message: '站点不能为空！'
        }],
        shiftDateTime: [{
          required: true,
          message: '航班/车次/集合时间不能为空！'
        }]
      },
      defaultData: null
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    getTitle() {
      return this.isUpdate ? '修改接送' : '新增接送';
    },
    getTargetLabel() {
      // shuttleType 接送类型
      // stationType 站点类型
      // togetherPosition 市内中转
      const {
        shuttleType,
        stationType
      } = this.form;

      // 接团
      if (shuttleType == 'pick') {
        if (stationType == 'train' || stationType == '火车站') {
          return '到站时间';
        }
        if (stationType == 'plane' || stationType == '飞机场') {
          return '抵达时间';
        }
      }
      // 送团
      if (shuttleType == 'send') {
        if (stationType == 'train' || stationType == '火车站') {
          return '发车时间';
        }
        if (stationType == 'plane' || stationType == '飞机场') {
          return '起飞时间';
        }
      }
      // 市内中转
      if (shuttleType == 'city') {
        return '集合时间';
      }
      return '抵达时间';
    },
    isOrderTeamType() {
      return this.currentOrder && isTeamType(this.currentOrder);
    }
  },
  methods: {
    show(data) {
      this.form.shuttleType = SELECTOR.taskType[0].value;
      this.visible = true;
      this.initData(data);
    },
    hide() {
      this.visible = false;
      this.resetForm();
    },
    initData(defData) {
      const {
        id: busCompanyId,
        name: busCompanyName
      } = defData;
      const {
        name: busType
      } = defDictionary(this.$store, DICTIONARY.busType);
      const {
        mealLabelPrice: busFee,
        name: desPosition
      } = defDictionary(this.$store, DICTIONARY.stationType);
      const {
        startTripDate: shuttleDate,
        adultCount,
        childCount
      } = this.currentOrder;

      // 只默认散客接送成本
      this.form = {
        ...defaultState(),
        ...defaultFleetTypes(),
        ...defaultFleetData(),
        shuttleDate,
        adultCount,
        childCount,
        desPosition,
        busType,
        busFee: !this.isOrderTeamType ? busFee : 0,
        busCompanyId,
        busCompanyName
      };
    },
    // showMap() {
    //   this.$bus.emit('show:map')
    // },

    selectTarget(val) {
      this.form.stationType = val;
    },
    selectPosition(val) {
      this.form.desPosition = val.label;
      this.form.busFee = !this.isOrderTeamType ? val.mealLabelPrice : 0;
      if (val.label.indexOf('待定') != -1) {
        if (this.form.shuttleType == 'pick' || this.form.shuttleType == 'send') {
          const shiftDateTime = parseDate(this.form.shuttleDate);
          this.form.shiftDateTime = shiftDateTime + ` 00:00:00`;
        }
      }
    },
    selectCityType(val) {
      this.form.taskCity = 'city';
      this.targetType = val;
    },
    handleSelectBus(val) {
      this.form.busCompanyName = val.label;
      this.form.busCompanyId = val.value;
    },
    selectBusType(val) {
      this.form.busType = val.label;
    },
    selectPayType(val) {
      this.form.payTypeName = val.label;
      this.form.payType = val.value;
    },
    changeShuttleType(val) {
      if (val != 'city') {
        const {
          stationType
        } = this.form;
        if (val == 'pick') {
          this.form.shuttleDate = this.currentOrder.startTripDate;
        }
        if (val == 'send') {
          this.form.shuttleDate = this.currentOrder.endTripDate;
          this.form.taskType = '火车站';
        }
        this.form.taskType = stationType == 'train' || stationType == '火车站' ? '火车站' : '飞机场';
      } else {
        this.form.taskType = 'start_trip_together';
      }
      this.form.shuttleType = val;
    },
    changeStationDateTime(val) {
      const dateArr = parseTime(val, '{y}-{m}-{d} {h}:{i}');
      this.form.shiftDateTime = dateArr;
      this.form.shuttleDate = dateArr.split(' ')[0];
    },
    selectStation({
      station,
      startPosition,
      endPosition
    }) {
      const {
        shuttleDate,
        shuttleType,
        stationType
      } = this.form;
      console.log('shuttleDate:', shuttleDate);
      // 火车
      if (stationType == 'train' || stationType == '火车站') {
        const {
          aTime,
          sendTime
        } = station;
        const curTime = shuttleType == 'pick' || shuttleType == '接团' ? aTime : sendTime;

        // const [hours, minutes] = curTime.split(':')
        this.form.shiftDateTime = curTime; // `${shuttleDate} ${hours}:${minutes}`
        this.form.desPosition = station.station;
      }
      // 飞机
      if (stationType == 'plane' || stationType == '飞机场') {
        const {
          aTime,
          dTime
        } = station;
        const curTime = shuttleType == 'pick' || shuttleType == '接团' ? aTime : dTime;
        this.form.shiftDateTime = parseTime(curTime);
      }
      this.form.startPosition = startPosition;
      this.form.endPosition = endPosition;

      // 只默认散客接送成本
      if (!this.isOrderTeamType) {
        // 联动获取对应站点的车费
        const findItem = getDictionaryValueByName(this.$store, DICTIONARY.stationType, station.station);
        this.form.busFee = findItem ? findItem.mealLabelPrice : 0;
      }
    },
    toDouble(str) {
      if (!str) return;
      return str.toString().padStart(2, '0');
    },
    formatTableData(curTime) {
      const [dateVal, timeVal] = curTime.split(' ');
      let [year, month, day] = dateVal.split('-');
      let [hours, minutes, seconds] = timeVal.split(':');
      return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
      };
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      let opts = {
        ...this.form,
        customerOrderId: this.currentOrderId
      };
      opts = this.beforeCommit(opts);
      opts.shiftDateTime = parseTime(opts.shiftDateTime);
      this.$store.dispatch('order/index/createOrShuttle', opts).then(() => {
        this.$bus.tip();
        this.hide();
        this.$parent.fetchData();
      });
    },
    beforeCommit(data) {
      const reg = /[a-zA-Z]/g;
      const TaskMap = {
        接团: 'pick',
        送团: 'send',
        市内中转: 'city'
      };
      const StationMap = {
        火车: 'train',
        火车站: 'train',
        飞机场: 'plane'
      };
      const CityMap = {
        散团回送: 'end_trip_back',
        出团集合: 'start_trip_together'
      };
      if (!reg.test(data.shuttleType)) {
        data.shuttleType = TaskMap[data.shuttleType];
      }
      if (!reg.test(data.stationType)) {
        data.stationType = StationMap[data.stationType];
      }
      if (!reg.test(data.taskType)) {
        data.taskType = StationMap[data.taskType];
      }
      if (data.shuttleType !== 'city') {
        data.cityType = '';
      } else {
        if (!reg.test(data.cityType)) {
          data.cityType = CityMap[data.cityType];
        }
      }
      const {
        shuttleDate,
        payType
      } = data;
      const shuttleType = data.shuttleType != 'city' ? data.shuttleType : 'pickAndSend';
      const optsKeys = Object.keys(defaultFleetData());
      const itemKeys = Object.keys(defaultFleetTypes());
      const opts = {};
      optsKeys.forEach(key => {
        opts[key] = data[key];
      });
      opts.customerOrderId = data.customerOrderId;
      opts.shuttleType = shuttleType.toUpperCase();
      opts.payType = payType.toUpperCase();
      opts.money = Number(data.busFee) + Number(data.otherFee);
      // 子级
      const item = {};
      itemKeys.forEach(key => {
        item[key] = data[key];
      });
      item.startDate = shuttleDate;
      item.endDate = shuttleDate;
      opts.types = [item];
      // 如果费用是0就不上传接送安排数据
      data.buses = Number(item.busFee) ? [opts] : [];
      return data;
    },
    resetForm() {
      this.form = defaultState();
    },
    fetchTrafficTime() {
      const {
        shuttleDate,
        shiftNumber,
        stationType
      } = this.form;
      if (!shuttleDate) {
        this.$bus.tip({
          type: 'warning',
          message: '日期未填写！'
        });
        return;
      }
      if (!shiftNumber) {
        this.$bus.tip({
          type: 'warning',
          message: '航班号或火车车次未填写！'
        });
        return;
      }

      // 简单判断大交通类型 train 是火车 plane 是飞机
      // const reg = /[a-z]/i
      // if (stationType != 'train' && stationType != 'plane') {
      //   this.$bus.tip({ type: 'warning', message: '航班类型是不是选错了？' })
      //   return
      // }

      const formatDate = parseDate(shuttleDate);
      let stationId;
      if (stationType == 'train' || stationType == '火车站') {
        stationId = 1;
      }
      if (stationType == 'plane' || stationType == '飞机场') {
        stationId = 2;
      }
      const opts = {
        date: formatDate,
        type: stationId,
        shiftNumber
      };
      this.btnLoading = true;
      this.$store.dispatch('app/fetchTrafficTime', opts).then(data => {
        this.$store.commit('app/STATION_DATA', data);
        this.$refs.dialogStationRef.show({
          type: +stationId,
          data,
          to: 'dialog'
        });
        this.btnLoading = false;
      }).catch(() => {
        this.btnLoading = false;
      });
    }
  }
};