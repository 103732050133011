import "core-js/modules/es.array.push.js";
import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { SelectDictionary } from '@/components/common';
import { planEditRestaurantDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';
const restaurantTypes = () => ({
  count: 1,
  freeCount: 0,
  isDelete: true,
  otherFee: 0,
  price: 0,
  restaurantType: ''
});
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar,
    SelectDictionary
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditRestaurantDetail,
      headMergeNum: 7,
      tailMergeNum: planEditRestaurantDetail.length - 2,
      hasMergeTail: true,
      planId: '',
      restaurantId: ''
    };
  },
  mounted() {
    this.$bus.on('restaurant:list', this.show);
    this.$bus.on('restaurant:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('restaurant:order', {
        planId: this.planId,
        id: this.restaurantId
      });
    },
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: restaurantId
      } = params;
      this.visible = true;
      this.planId = planId;
      this.restaurantId = restaurantId;
      this.fetchData(planId, restaurantId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planId, restaurantId) {
      this.$store.dispatch('plan/restaurant/fetchOrder', {
        planRestaurantId: restaurantId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = this.formatData(list);
        this.rowspan();
      });
    },
    selectType(row, val) {
      // mealLabelPrice
      const {
        label,
        mealLabelPrice
      } = val;
      row.restaurantType = label;
      row.price = mealLabelPrice;
    },
    // TODO 旧版选择餐厅协议 目前作废
    beforeSelectType(row, val) {
      const restaurantId = this.restaurantId;
      if (!val) return;
      if (!row.agreementList || !row.agreementList.length) {
        this.$store.dispatch('resource/restaurant/fetchAgreementTypeList', restaurantId).then(data => {
          if (!data) return;
          const {
            contractPrices
          } = data[0];
          const roomList = this.formatRoomOptions(contractPrices);
          if (!roomList.length) {
            this.showNotice();
            return;
          }
          row.agreementList = roomList;
          // this.$set(row,'agreementList', roomList)
        });
      }
    },
    formatRoomOptions(list) {
      if (!list) return;
      return list.map(it => {
        return {
          label: it.contentType,
          id: it.id,
          value: it.id,
          quotePrice: it.quotedPrice
        };
      });
    },
    reloadData() {
      this.fetchData(this.planId, this.restaurantId);
    },
    // 移除
    handleRemove(scope) {
      let {
        row
      } = scope;
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/restaurant/deleteOrder', row.planRestaurantOId).then(() => {
        loading.close();
        this.fetchData(this.planId, this.restaurantId);
      }).catch(() => {
        loading.close();
      });
    },
    // 移除
    handleRemoveRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('plan/restaurant/deleteOrderItem', id).then(() => {
          this.tableData.splice(index, 1);
        });
      });
    },
    // 修改价格
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = val;
        return;
      }
      item.totalPay = this.calcRowMoney(item);
    },
    // 计算单行总额
    calcRowMoney(obj) {
      const {
        price,
        times,
        count,
        freeCount,
        otherFee
      } = obj;
      let total = Number(price) * (Number(count) - Number(freeCount)) * (times || 1) + Number(otherFee);
      return total;
    },
    // 保存
    handleSave() {
      // 后端参数需要
      let list = this.tableData.map(it => {
        it.planId = this.planId;
        return it;
      });
      list = this.beforeCommit(list);
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/restaurant/updateOrder', list).then(() => {
        loading.close();
        this.$bus.tip();
        this.fetchData(this.planId, this.restaurantId);
      }).catch(() => {
        loading.close();
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.restaurantOTypes || [];
        let item = it;
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const rowItem = {
              ...item,
              ...v,
              rowId: it.id,
              agreementList: [],
              restaurantType: v.restaurantType,
              itemIndex: i
            };
            const totalPay = this.calcRowMoney(rowItem);
            this.$set(rowItem, 'totalPay', totalPay);
            result.push(rowItem);
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const keys = Object.keys(restaurantTypes());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'planList', 'restaurantList'];
      let last = null;
      const result = [];
      list.forEach(it => {
        const item = {
          id: it.id
        };
        it.restaurantOTypes = [];
        it.id = it.planRestaurantId; // 重新还原该条数据的id
        it.mealType = it.mealType.toUpperCase();
        delKeys.forEach(key => {
          delete it[key];
        });
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (last && last.planRestaurantId == it.planRestaurantId) {
          last.restaurantOTypes.push(item);
        } else {
          it.restaurantOTypes.push(item);
          result.push(it);
        }
        last = it;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planRestaurantId;
        }
      });
      return result;
    }
  }
};