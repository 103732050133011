import { AuditRole, auditProgressStatus as AuditProgressStatus } from '@/config/audit';
export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    getRowAuditProgress() {
      return this.row ? AuditProgressStatus[this.row.auditProgress] : '';
    },
    getRowType() {
      return AuditProgressStatus[this.row.auditProgress] == AuditRole.wait ? 'primary' : 'success';
    }
  }
};