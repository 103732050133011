import { orderAuditProgress, reviewerStatus } from '@/config/order';
import { parseTime } from '@/utils';
export default {
  name: 'ProgressDetail',
  data() {
    return {
      orderAuditProgress,
      tableProgressData: [],
      visible: false
    };
  },
  methods: {
    handleCreate() {},
    show(data) {
      this.visible = true;
      this.$store.dispatch('order/index/fetchApplyAuditById', {
        orderId: data.id
      });
      this.tableProgressData = this.formatData(data.reviewerInfo);
    },
    formatData(data) {
      if (!data) return [];
      let list = data.map((it, index) => {
        it.statusType = reviewerStatus[it.status];
        it.index = it.progress + 1;
        it.time = parseTime(it.time);
        if (index === 0) {
          it.statusType = '发起';
          it.reviewerName = '';
        }
        // 审核类型是用户
        if (it.reviewerType == 'USER') {
          it.reviewerName = '';
        }
        return it;
      });
      list = list.sort((a, b) => {
        return a.progress - b.progress;
      });
      return list;
    }
  }
};