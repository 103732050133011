import { SELECTOR } from '@/config';
import { isChineseCharacter } from '@/utils';
export default {
  name: 'SelectBase2',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isFirst: true,
      isSearch: false,
      options: []
    };
  },
  computed: {
    getTypeList() {
      return this.options;
    }
  },
  watch: {
    list: {
      handler: function (data) {
        this.options = data.length > 0 ? data : SELECTOR[this.type];
      },
      immediate: true
    }
  },
  methods: {
    initData(val) {
      if (val === '') return;
      this.curVal = val;
    },
    handleChange(val) {
      const item = this.options.find(it => it.value === val);
      this.$emit('on-select', item);
    },
    handleFilter(data, val) {
      data = this.list.length ? this.list : data;
      const result = data.filter(item => {
        const isChiness = isChineseCharacter(val);
        if (!isChiness && !val.match(/\d/)) {
          if (isNaN(Number(val))) {
            return item.slug.charAt(0) == val.charAt(0) && item.slug.toLowerCase().indexOf(val.toLowerCase()) > -1;
          } else {
            return item.slug.indexOf(val) > -1;
          }
        } else {
          return item.label.indexOf(val) > -1;
        }
      });
      return result;
    },
    dataFilter(val) {
      this.isSearch = true;
      const data = [...this.list];
      if (val) {
        const filterData = this.handleFilter(data, val);
        this.options = filterData;
      } else {
        this.options = data;
      }
    },
    lazyLoad() {
      if (!this.isFirst) return;
      this.isFirst = false;
      this.options = this.list.length > 0 ? this.list : SELECTOR[this.type];
    },
    resetOptions() {
      this.options = [...this.list];
    }
  }
};