/* eslint-disable no-empty-pattern */
import { customerKpiTemplateDownloadRequest, customerKpiTemplateImportRequest } from '@/api/system';
const dfaultState = () => ({});
const state = dfaultState();
const getters = {};
const actions = {
  customerKpiTemplateDownload({}, params) {
    return new Promise((resolve, reject) => {
      customerKpiTemplateDownloadRequest(params).then(res => {
        resolve(res);
      }).catch(reject);
    });
  },
  customerKpiTemplateImport({}, params) {
    return new Promise((resolve, reject) => {
      customerKpiTemplateImportRequest(params).then(() => {
        resolve();
      }).catch(reject);
    });
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};