import { SELECTOR } from '@/config';
import { TableList, DialogAuditOrder, Search } from './components';
import { tagViewMixin, excelMixin, reloadMixin } from '@/mixin';
import { downloadExport } from '@/utils/exportExcel';
import { TYPES } from '@/config/audit';
import billCheckMixin from '../searchMixin';
export default {
  components: {
    Search,
    TableList,
    DialogAuditOrder
  },
  mixins: [tagViewMixin, excelMixin, reloadMixin, billCheckMixin],
  data() {
    return {
      // tableData: [],
      // total: 0,
      // statistics: null,
      checkBillDate: [],
      payType: [{
        value: '',
        label: '全部'
      }, ...SELECTOR.payType]
    };
  },
  computed: {
    collectData() {
      return this.$store.getters['bill/billCheck/collect'];
    },
    // tableData() {
    //   const data = this.collectData
    //   return data ? this.formatData(data.list) : []
    // },
    total() {
      const data = this.collectData;
      return data ? data.totalCount : 0;
    }
    // statistics() {
    //   const data = this.collectData
    //   return data ? data.statistics : null
    // },
  },
  // watch: {
  //   collectData: {
  //     handler: function (d) {
  //       if (d) {
  //         this.handleData(d)
  //       }
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('bill/billCheck/fetchCollect', {
        ...params
      });
    },
    showLog(row) {
      const {
        id: itemId
      } = row;
      this.$store.dispatch('bill/billCheck/fetchPlanLog', {
        itemId,
        subType: TYPES.fee
      }).then(data => {
        this.$refs.dialogRef.show(data);
      });
    },
    orderAutitShow(row) {
      const {
        id
      } = row;
      this.$refs.auditOrderRef.show({
        id,
        isReview: 1
      });
    },
    // handleData(data) {
    //   const { list, totalCount, statistics } = data
    //   this.tableData = this.formatData(list)

    //   this.total = totalCount
    //   this.statistics = statistics
    // },

    // formatData(list) {
    //   if (!list || !list.length) return []
    //   return list.map(it => {
    //     const { id, checkAccountMoney, totalIncome, checkAccountStatus } = it

    //     it.checkAccountMoney = checkAccountStatus
    //       ? checkAccountMoney
    //       : totalIncome
    //     it.rowId = id
    //     this.$set(it, 'ckStatus', false)
    //     return it
    //   })
    // },

    handleSearch(params) {
      const {
        path
      } = this.$route;
      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1
      });
      this.fetchData(params);
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportCollectExcel', {
        ...this.searchParams,
        ...params
      }).then(res => {
        const fileName = '应收款对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};