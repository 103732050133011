var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "company-info-base"
  }, [_c("h3", [_vm._v("公司基本信息")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "padding-right": "8px",
      "margin-bottom": "30px"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 8
      },
      lg: {
        span: 8
      },
      xl: {
        span: 8
      }
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("span", [_vm._v("旅行社名称：")]), _c("strong", {
    staticClass: "tar"
  }, [_vm._v(" " + _vm._s(_vm.info.name) + " ")])]), _c("div", {
    staticClass: "field"
  }, [_c("span", [_vm._v("旅行社编码：")]), _c("strong", [_vm._v(_vm._s(_vm.info.code))])]), _c("div", {
    staticClass: "field"
  }, [_c("span", [_vm._v("是否购买地接APP：")]), _c("strong", [_vm._v(_vm._s(_vm.info.isOpenAgencyApp ? "是" : "否"))])]), _c("div", {
    staticClass: "field"
  }, [_c("span", [_vm._v("最大端口数：")]), _c("strong", [_vm._v(_vm._s(_vm.info.maxUserCount) + " ")])]), _c("div", {
    staticClass: "field"
  }, [_c("span", [_vm._v("有效期至：")]), _c("strong", [_vm._v(_vm._s(_vm.info.availableDate))])]), _c("div", {
    staticClass: "link-box"
  }, [_c("el-link", {
    attrs: {
      type: "primary",
      underline: false
    }
  }, [_vm._v(" 系统充值 ")]), _c("el-link", {
    attrs: {
      type: "success",
      underline: false
    }
  }, [_vm._v(" 增加端口 ")]), _c("el-link", {
    attrs: {
      type: "warning",
      underline: false
    },
    on: {
      click: _vm.showRenewLog
    }
  }, [_vm._v(" 缴费记录 ")])], 1)])]), _c("el-col", {
    staticStyle: {
      "padding-right": "8px",
      "margin-bottom": "30px"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 8
      },
      lg: {
        span: 8
      },
      xl: {
        span: 8
      }
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper"
  }, [_c("el-progress", {
    attrs: {
      type: "circle",
      percentage: Number(_vm.info.remainSMSCount)
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" 公司短信使用 ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": Number(_vm.info.smsCount),
      duration: 2600
    }
  })], 1)])]), _c("el-col", {
    staticStyle: {
      "padding-right": "8px",
      "margin-bottom": "30px"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 8
      },
      lg: {
        span: 8
      },
      xl: {
        span: 8
      }
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper"
  }, [_c("el-progress", {
    attrs: {
      type: "circle",
      percentage: Number(_vm.info.remainStorageSpaceSize)
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" 文档空间使用 ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": Number(_vm.info.docStorageSpaceSize),
      duration: 2600
    }
  })], 1)])])], 1), _c("DialogRenewLog", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };