var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangDate("startTripDate", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangDate("endTripDate", $event);
      }
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangDate("orderDate", $event);
      }
    }
  }), _c("SelectFleet", {
    attrs: {
      label: "车队",
      clearable: ""
    },
    on: {
      "on-select": _vm.onSelect
    },
    model: {
      value: _vm.searchParams.resourceLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "resourceLabel", $$v);
      },
      expression: "searchParams.resourceLabel"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "审核状态",
      type: "orderAuditStatus"
    },
    model: {
      value: _vm.searchParams.auditProgress,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditProgress", $$v);
      },
      expression: "searchParams.auditProgress"
    }
  }), _c("SelectBase", {
    attrs: {
      clearable: "",
      label: "计划类型",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  })], 1)]), _c("TableFleet", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      border: ""
    }
  }), _vm.total > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    }
  })] : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };