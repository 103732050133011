var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "resource-group-dialog",
    attrs: {
      title: "新增集团",
      width: "860px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.hide
    }
  }, [_c("h3", [_vm._v("基础信息")]), _c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "90px"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("FormItemBase", {
    attrs: {
      label: "公司名称",
      prop: "name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "座机号码",
      prop: "telphone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", $$v);
      },
      expression: "form.telphone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "联系人",
      prop: "contact"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", $$v);
      },
      expression: "form.contact"
    }
  })], 1)], 1), _c("div", {
    staticClass: "flex"
  }, [_c("FormItemBase", {
    attrs: {
      label: "手机号码",
      prop: "mobilePhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "传真号码",
      prop: "faxNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", $$v);
      },
      expression: "form.faxNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "公司编码",
      prop: "code"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c("div", {
    staticClass: "flex-1"
  }, [_c("FormItemBase", {
    attrs: {
      label: "地址",
      prop: "address"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c("div", {
    staticClass: "flex-1"
  }, [_c("FormItemBase", {
    attrs: {
      label: "备注",
      prop: "remarks"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      type: "textarea"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1), _c("FormItemBase", {
    attrs: {
      label: "启用状态",
      prop: "isEnable"
    }
  }, [_c("SwitchStatus", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)], 1), _c("el-divider"), _c("h3", [_vm._v("账户信息")]), _c("FormBase", {
    ref: "formAccountRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "90px"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("FormItemBase", {
    attrs: {
      label: "开户人",
      prop: "account"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.account,
      callback: function ($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "开户行",
      prop: "bank"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.bank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bank", $$v);
      },
      expression: "form.bank"
    }
  })], 1)], 1), _c("div", {
    staticClass: "flex flex-1"
  }, [_c("FormItemBase", {
    attrs: {
      label: "银行卡号",
      prop: "bankCard"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.bankCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankCard", $$v);
      },
      expression: "form.bankCard"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "账户类型",
      prop: "bankCard"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      placeholder: "请选择",
      type: "bank"
    },
    model: {
      value: _vm.form.bankCardType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankCardType", $$v);
      },
      expression: "form.bankCardType"
    }
  })], 1)], 1)]), _c("h3", [_vm._v("资源信息")]), _vm.getTableData.length ? _c("TableBase", {
    attrs: {
      data: _vm.getTableData,
      "column-data": _vm.columns,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "comp",
      fn: function ({
        scope
      }) {
        return [_c(scope.row.comp, {
          tag: "component",
          attrs: {
            value: scope.row.values,
            "reserve-keyword": true,
            multiple: true
          },
          on: {
            multiple: function ($event) {
              return _vm.handleMultiple(scope.row, $event);
            }
          }
        })];
      }
    }], null, false, 1753657397)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };