import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { downloadExport } from '@/utils/exportExcel';
import { PeopleCountList } from '@/config/report';
import { SelectCustom, SelectDictionary, SelectStaff, BtnExport } from '@/components/common';
import { mergeTableRow } from '@/utils/table';
export default {
  components: {
    SelectCustom,
    SelectDictionary,
    SelectStaff,
    BtnExport
  },
  data: () => ({
    searchParams: {
      year: ''
    },
    tableLoading: false,
    tableData: [],
    columnData: PeopleCountList
  }),
  mounted() {
    this.searchParams.year = new Date().getFullYear() + '';
    this.fetchData(this.searchParams);
  },
  methods: {
    // 选择年份
    changeYear(val) {
      this.searchParams.year = new Date(val).getFullYear() + '';
    },
    // 搜索
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    fetchData(opts) {
      this.$store.dispatch('report/index/getYearPeopleCount', opts).then(res => {
        this.tableData = this.formatData(res);
        this.tableData = mergeTableRow(this.tableData, ['areaManager', 'areaName']);
      });
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = (it, index) => {
        const itemList = it.customerCounts;
        const item = {
          ...it,
          areaManager: it.areaManager.split(','),
          rowId: index
        };
        if (itemList && itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              busType: v.busType || '',
              itemIndex: i
            });
          });
        } else {
          result.push({
            itemIndex: 0,
            ...item,
            id: it.id
          });
        }
      };
      list.forEach((it, index) => {
        handleFn(it, index);
      });
      return result;
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchYearPeopleCount', this.searchParams).then(res => {
        const fileName = this.searchParams.year + '年度人数统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    // 指定列求和
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 3) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 大于第三列就求和
        if (index > 3) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    },
    changeCellClassName(row) {
      //列的label的名称
      if (row.column.property.indexOf('groupCount') !== -1 || row.column.property == 'yearGroupCount') {
        return 'light-red-color'; //修改的样式
      }
      if (row.column.property.indexOf('individualCount') !== -1 || row.column.property == 'yearIndividualCount') {
        return 'baby-blue-color'; //修改的样式
      }
      return '';
    }
  }
};