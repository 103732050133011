/* eslint-disable no-unused-vars */
import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 对账
export const billCheck = data => {
  return request({
    url: '/order/ca/checkAccount',
    method: 'post',
    data
  });
};

// 日志
export function fetchLog(params) {
  return request({
    url: '/log/orderList2',
    method: 'get',
    params
  });
}

// 景点
export const fetchScenic = params => {
  return request({
    url: '/order/ca/pageScenic',
    method: 'get',
    params
  });
};

// 餐厅
export const fetchRestaurant = params => {
  return request({
    url: '/order/ca/pageRestaurant',
    method: 'get',
    params
  });
};

// 酒店
export const fetchHotel = params => {
  return request({
    url: '/order/ca/pageHotel',
    method: 'get',
    params
  });
};

// 大交通
export const fetchTraffic = params => {
  return request({
    url: '/order/ca/pageTransport',
    method: 'get',
    params
  });
};

// 车队
export const fetchFleet = params => {
  return request({
    url: '/order/ca/pageBusCompany',
    method: 'get',
    params
  });
};

// 保险
export const fetchInsurance = params => {
  return request({
    url: '/order/ca/pageInsurance',
    method: 'get',
    params
  });
};

// 购物
export const fetchShopping = params => {
  return request({
    url: '/order/ca/pageShop',
    method: 'get',
    params
  });
};

// 地接
export const fetchReception = params => {
  return request({
    url: '/order/ca/pageOutTransfer',
    method: 'get',
    params
  });
};

// 应收款
export const fetchCollect = params => {
  return request({
    url: '/order/ca/pageCustomer',
    method: 'get',
    params
  });
};

// 导出应收款报表
export function exportCollectExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageCustomer';
  return requestBlob({
    url,
    params
  });
}

// 其他收入
export const fetchOtherIncome = params => {
  return request({
    url: '/order/ca/pageOtherIncome',
    method: 'get',
    params
  });
};

// 其他支出
export const fetchOtherPay = params => {
  return request({
    url: '/order/ca/pageOtherPay',
    method: 'get',
    params
  });
};

// 订单列表
export const fetchOrder = ({
  id,
  type
}) => {
  return request({
    url: '/order/ca/' + type,
    method: 'get',
    params: {
      id
    }
  });
};

// 餐厅订单列表
export const fetchRestaurantOrder = params => {
  return request({
    url: '/order/ca/busCompanyOrder',
    method: 'get',
    params
  });
};

// 景区订单列表
export const fetchScenicOrder = params => {
  return request({
    url: '/order/ca/scenicOrder',
    method: 'get',
    params
  });
};

// 大交通订单列表
export const fetchTransportOrder = params => {
  return request({
    url: '/order/ca/transportOrder',
    method: 'get',
    params
  });
};

// 导出景区报表
export function exportScenicExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageScenic';
  return requestBlob({
    url,
    params
  });
}

// 导出车队报表
export function exportBusExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageBusCompany';
  return requestBlob({
    url,
    params
  });
}

// 导出酒店报表
export function exportHotelExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageHotel';
  return requestBlob({
    url,
    params
  });
}

// 导出保险报表
export function exportInsuranceExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageInsurance';
  return requestBlob({
    url,
    params
  });
}

// 导出其他收入报表
export function exportOtherIncomeExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageOtherIncome';
  return requestBlob({
    url,
    params
  });
}

// 导出其他支出报表
export function exportOtherPayExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageOtherPay';
  return requestBlob({
    url,
    params
  });
}

// 导出地接报表
export function exportOutTransferExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageOutTransfer';
  return requestBlob({
    url,
    params
  });
}

// 导出餐厅报表
export function exportRestaurantferExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageRestaurant';
  return requestBlob({
    url,
    params
  });
}

// 导出购物店报表
export function exportShopExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageShop';
  return requestBlob({
    url,
    params
  });
}

// 导出大交通报表
export function exportTransportExcel(params) {
  const url = APP.baseUrl + '/order/caExport/pageTransport';
  return requestBlob({
    url,
    params
  });
}