export default {
  name: 'AuditRoleAll',
  data() {
    return {
      auditAllStatus: ''
    };
  },
  methods: {
    changeRole(val) {
      this.$emit('on-select', val);
    }
  }
};