var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "in-group"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("BtnCreate", {
    attrs: {
      "create-text": "添加订单"
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.showCreate
    }
  }, [_c("BtnMore", {
    attrs: {
      visible: _vm.visibleMore
    },
    on: {
      "on-click": val => _vm.visibleMore = !_vm.visibleMore
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      disabled: !_vm.hasCustomerInfo
    },
    on: {
      "on-select": _vm.changeCustomerName
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  }), _c("SelectRegion", {
    attrs: {
      label: "客源地"
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.extra.region,
      callback: function ($$v) {
        _vm.$set(_vm.extra, "region", $$v);
      },
      expression: "extra.region"
    }
  }), _c("InputAgeRange", {
    attrs: {
      "age-start": _vm.searchParams.ageStart,
      "age-end": _vm.searchParams.ageEnd
    },
    on: {
      "update:ageStart": function ($event) {
        return _vm.$set(_vm.searchParams, "ageStart", $event);
      },
      "update:age-start": function ($event) {
        return _vm.$set(_vm.searchParams, "ageStart", $event);
      },
      "update:ageEnd": function ($event) {
        return _vm.$set(_vm.searchParams, "ageEnd", $event);
      },
      "update:age-end": function ($event) {
        return _vm.$set(_vm.searchParams, "ageEnd", $event);
      },
      "change-start": function ($event) {
        return _vm.handleAge("ageStart", $event);
      },
      "change-end": function ($event) {
        return _vm.handleAge("ageEnd", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectProduct", {
    attrs: {
      label: "产品线路"
    },
    on: {
      "on-select": val => {
        _vm.searchParams.productName = val.name;
      }
    },
    model: {
      value: _vm.searchParams.productName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productName", $$v);
      },
      expression: "searchParams.productName"
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.memberKey,
      label: "游客信息",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "memberKey", $event);
      }
    }
  }), _c("SelectGuide", {
    attrs: {
      label: "导游"
    },
    model: {
      value: _vm.searchParams.guideId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideId", $$v);
      },
      expression: "searchParams.guideId"
    }
  })], 1), _c("FormBase", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleMore,
      expression: "visibleMore"
    }],
    staticClass: "form-warpper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDepartment", {
    attrs: {
      "init-val": _vm.extra.department
    },
    on: {
      "on-select": _vm.changeDepartment
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.createDate,
      label: "输单日期"
    },
    on: {
      "on-change": _vm.changeCreateDate
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.orderName,
      label: "流水号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "orderName", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectStaff", {
    attrs: {
      label: "操作计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "业务员"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("saleUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.saleUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "saleUserLabel", $$v);
      },
      expression: "searchParams.saleUserLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      label: "计划状态",
      type: "planState"
    },
    model: {
      value: _vm.searchParams.planStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planStatus", $$v);
      },
      expression: "searchParams.planStatus"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "审核状态",
      type: "examineState"
    },
    model: {
      value: _vm.searchParams.auditStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditStatus", $$v);
      },
      expression: "searchParams.auditStatus"
    }
  }), _c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: "在团日期"
    },
    model: {
      value: _vm.searchParams.inTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "inTripDate", $$v);
      },
      expression: "searchParams.inTripDate"
    }
  })], 1)])], 1), _c("TableListDone", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.total > 0 ? [_c("TableTotal", {
    attrs: {
      statistic: _vm.summaryCount,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({}) {
        return [_vm._v(" 小计 ")];
      }
    }, {
      key: "count",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.summaryCount) + " ")];
      }
    }], null, false, 732007142)
  }), _vm.statistics ? _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "count",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAdult) + "大" + _vm._s(_vm.statistics.totalChild) + "小" + _vm._s(_vm.statistics.totalAccompanyCount) + "陪 ")];
      }
    }], null, false, 3543926834)
  }) : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };