var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-create form-fix-barl"
  }, [_c("div", {
    staticClass: "h20"
  }), _c("Credits"), _c("FormInput", {
    ref: "formInputRef",
    attrs: {
      "form-data": _vm.form,
      "back-path": _vm.redirectPath,
      "order-type": _vm.orderType
    }
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "create",
      disabled: _vm.createPermission
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 创建订单 ")]), _c("BtnBase", {
    directives: [{
      name: "permission",
      rawName: "v-permission"
    }],
    attrs: {
      type: "create2",
      disabled: _vm.createPermission
    },
    on: {
      click: _vm.handleCreateAndNotAudit
    }
  }, [_vm._v(" 创建订单并设置为暂不提交审核 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };