import { parseTime } from './date';
import { getUserData } from './cookie';

// 格式化时间 单个
export const formatCreateTime = val => {
  if (!val) return;
  return parseTime(val);
};

// 格式时间 列表
export const formatCreateTimeToList = (list, key = 'createTime') => {
  return list.map(it => {
    it['$' + key] = formatCreateTime(it[key]);
    return it;
  });
};

// 根据角色部门权限，格式化部门参数
export const formatDepartmentParams = (data, id = '') => {
  const {
    departmentId,
    subDepartmentId,
    grandsonDepartmentId
  } = getUserData();
  const {
    departmentPrivilege
  } = data;
  const dparea = departmentPrivilege;
  const dpParams = {
    ['department' + id]: {
      departmentId
    },
    subDepartment: {
      ['department' + id]: departmentId,
      ['subDepartment' + id]: subDepartmentId
    },
    grandsonDepartment: {
      ['department' + id]: departmentId,
      ['subDepartment' + id]: subDepartmentId,
      ['grandsonDepartment' + id]: grandsonDepartmentId
    }
  };
  return dparea ? dpParams[dparea] : {};
};