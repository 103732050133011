var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-resource-plan",
    attrs: {
      title: "复制资源计划",
      width: "1200px",
      top: "20px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_vm.visible ? _c("FormOrder", {
    ref: "formRef",
    attrs: {
      form: _vm.form
    }
  }) : _vm._e(), _c("div", {
    staticClass: "tac mt20"
  }, [_c("el-button", {
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 下计划 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };