/* eslint-disable no-unused-vars */
import { fetchList, fetchGuideTeam, createOrUpdateGuideTeam, deleteGuideTeam, fetchLog, fetchLeader, fetchTeamDetail, removeGroupGuide } from '@/api/resource/guideCaptain';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const formatGuide = data => {
  data = formatCharData(data);
  return data.map(it => {
    it.label = it.name;
    it.value = it.id;
    return it;
  });
};
const dfaultState = () => ({
  guideData: null,
  guideDataOrigin: null,
  guideLog: null,
  guideLeader: null
});
const state = dfaultState();
const getters = {
  guideData: state => state.guideData,
  guideDataOrigin: state => state.guideDataOrigin,
  guideLog: state => state.guideLog,
  guideLeader: state => state.guideLeader
};
const actions = {
  fetchGuideTeam({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchGuideTeam(queryParam).then(res => {
      const {
        data
      } = res;
      // commit('FETCH_DATA_ORIGIN', data)
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatGuide(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.guide_team
    }).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.guide
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  createOrUpdate({
    dispatch
  }, opts) {
    // 删除图片操作
    return createOrUpdateGuideTeam(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取导游组长列表
  fetchLeader({
    commit
  }, queryParam) {
    return fetchLeader(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatGuide(data);
      commit('GUIDE_LEADER', data);
      return data;
    });
  },
  // 获取导游组信息
  fetchTeamDetail({
    dispatch
  }, queryParam) {
    return fetchTeamDetail(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delete({
    commit,
    dispatch
  }, id) {
    return deleteGuideTeam(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  removeGroupGuide({
    commit
  }, data) {
    return removeGroupGuide(data);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.guideData = data;
  },
  GUIDE_LEADER: (state, data) => {
    state.guideLeader = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.guideDataOrigin = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};