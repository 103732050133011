import "core-js/modules/es.array.push.js";
import DialogPlan from './components/DialogPlan';
import { DetailPlanTrip } from '@/components/common';
import TableTicketAudit from './components/TableTicketAudit';
import { ticketAuditList, TicketAuditBtnStatus, TicketCompleteStatus } from '@/config/ticket';
import { detailMixin } from './mixin';
export default {
  components: {
    DialogPlan,
    TableTicketAudit,
    DetailPlanTrip
  },
  mixins: [detailMixin],
  data() {
    return {
      backPath: '/ticket/ticket-check',
      ticketAuditList,
      planId: ''
    };
  },
  computed: {
    getAuditBtnType() {
      return TicketAuditBtnStatus[this.currentPlan.signSheetStatus] ? 'dispatch' : 'close';
    },
    getAuditBtnText() {
      return TicketAuditBtnStatus[this.currentPlan.signSheetStatus] ? '完成审核' : '取消已审核';
    }
  },
  mounted() {
    this.planId = this.$route.query.id;
    if (!this.currentPlan.signSheetStatus) {
      this.$router.replace('/ticket/ticket-check');
    }
  },
  methods: {
    showDialog(id) {
      this.$refs.dialogPlan.show(id);
    },
    // 审核
    handleAudit() {
      const isAudit = TicketCompleteStatus[this.currentPlan.signSheetStatus];
      const {
        id
      } = this.$route.query;
      const opts = {
        id,
        isAudit: !isAudit
      };
      const loading = this.$bus.loading();
      this.$store.dispatch('ticket/index/toggleAudit', opts).then(() => {
        loading.close();
        this.$bus.tip();
        this.$bus.emit('ticket:reload');
        this.goBack();
      }).catch(() => {
        loading.close();
      });
    },
    // 返回列表
    goBack() {
      // 路径是页面内配置
      this.$router.push({
        path: this.backPath
      });
    }
  }
};