import { SelectDictionary } from '@/components/common';
export default {
  name: 'DialogAreaStandard',
  components: {
    SelectDictionary
  },
  data() {
    return {
      visible: false,
      from: {}
    };
  },
  mounted() {},
  methods: {
    show(row) {
      this.from = row;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    selectDic(type, val) {
      this.from[type] = val.label;
    },
    handleConfirm() {
      this.hide();
    }
  }
};