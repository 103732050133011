import "core-js/modules/es.array.push.js";
import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditScenicDetail, planEditScenicDetailAll } from '@/config/plan';
import { isNumber } from '@/utils';
import { mapGetters } from 'vuex';
import { permissionMixin, rowspanMixin } from '@/mixin';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin, permissionMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: [],
      headMergeNum: 6,
      tailMergeNum: 16,
      hasMergeTail: true,
      planId: '',
      scenicId: '',
      planScenicId: ''
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getShuttleType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'pick' ? '接团' : type === 'send' ? '送团' : '市内中转';
      };
    },
    getTaskType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'plan' ? '飞机' : '火车';
      };
    }
  },
  mounted() {
    this.$bus.on('scenic:list', this.show);
    this.$bus.on('scenic:reload', this.reloadData);
    setTimeout(() => {
      this.columnData = this.hasPermission('scenicFloorPrice') ? planEditScenicDetailAll : planEditScenicDetail;
    }, 100);
  },
  methods: {
    // 订单弹窗
    handleOrderDialog() {
      this.$bus.emit('scenic:order', {
        planId: this.planId,
        scenicId: this.scenicId,
        planScenicId: this.planScenicId
      });
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: planScenicId,
        scenicId
      } = params;
      this.visible = true;
      this.planId = planId;
      this.scenicId = scenicId;
      this.planScenicId = planScenicId;
      this.fetchData(planScenicId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planScenicId) {
      this.$store.dispatch('plan/scenic/fetchOrder', {
        planScenicId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = this.formatData(list);
        this.$nextTick(() => {
          this.rowspan();
        });
      });
    },
    reloadData() {
      this.fetchData(this.planScenicId);
    },
    // 移除
    handleRemove(scope) {
      let {
        row
      } = scope;
      this.$store.dispatch('plan/scenic/deleteOrder', row.rowId).then(() => {
        this.fetchData(this.planScenicId);
      });
    },
    // 移除景点
    handleRemoveRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id
      } = row;

      // 大于0 就是删除
      this.$bus.open(() => {
        this.$store.dispatch('plan/scenic/deleteOrderItem', id).then(() => {
          this.tableData.splice(index, 1);
        });
      });
    },
    // 选择景点协议项目
    selectTicketType(scope, val) {
      const {
        row,
        $index: index
      } = scope;
      const findItem = row.ticketList.find(it => it.ticketType == val);
      ['accompanyCount', 'adultCount', 'childCount', 'price'].forEach(key => {
        this.tableData[index][key] = findItem[key];
      });
      setTimeout(() => {
        row.totalPay = this.calcTotalPay(row);
      }, 500);
    },
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
    },
    // 修改人数
    changeCount(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
    },
    // 修改价格
    changePrice(item, type, val) {
      if (isNaN(val)) {
        item[type] = 0;
        return;
      }
      item.totalPay = this.calcTotalPay(item);
    },
    // 计算单行总额
    calcTotalPay(obj) {
      const {
        price,
        adultCount,
        childPrice,
        childCount,
        accompanyPrice,
        accompanyCount,
        otherFee
      } = obj;
      const unit = 100;
      let total = price * adultCount * unit + childPrice * childCount * unit + accompanyPrice * accompanyCount * unit + Number(otherFee) * unit;
      return total / unit;
    },
    handleSave() {
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/scenic/updateOrder', this.tableData).then(() => {
        this.fetchData();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const ticketsRes = it.scenicOTickets;
        let item = {
          ...it,
          rowId: it.id,
          totalPay: 0,
          adultFreeCount: 0,
          otherFee: 0,
          scenicOTickets: []
        };
        if (ticketsRes.length) {
          ticketsRes.forEach((v, i) => {
            item = {
              ...item,
              ...v,
              itemIndex: i,
              ticketList: this.formatOptions(ticketsRes)
            };
            item.totalPay = this.calcTotalPay(item);
            this.totalMoney += Number(item.totalPay);
            result.push(item);
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    // 格式化下拉列表
    formatOptions(list) {
      if (!list.length) return;
      return list.map(it => {
        it.label = it.ticketType;
        it.value = it.ticketType;
        return it;
      });
    }
  }
};