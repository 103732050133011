import { planHotelListDetail } from '@/config/plan';
import { parseTime } from '@/utils';
import { Members } from '@/components/common';
export default {
  components: {
    Members
  },
  data() {
    return {
      columnData: planHotelListDetail,
      tableData: [],
      visible: false,
      planId: '',
      hotelId: '',
      orderDate: ''
    };
  },
  mounted() {
    this.$bus.on('hotel:order', this.show);
  },
  methods: {
    show(row) {
      this.visible = true;
      const {
        planId
      } = row;
      this.fetchData({
        planId
      });
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/hotel/fetchHotelOrderList', params).then(data => {
        this.tableData = this.formatData(data);
      });
    },
    formatData(list) {
      const rules = list.map(it => {
        if (it.shuttles.length) {
          it.pickList = it.shuttles.filter(item => item.shuttleType == 'PICK');
          it.sendList = it.shuttles.filter(item => item.shuttleType == 'SEND');
        }
        if (it.members.length) {
          it.members = it.members.filter(item => item.isContact);
        }
        return it;
      });
      return rules;
    },
    formatTime(val) {
      return parseTime(val, '{y}-{m}-{d} {h}:{i}');
    },
    onLookOver() {},
    handleConfirm() {
      this.hide();
    }
  }
};