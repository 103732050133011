import DialogDetail from './DialogDetail';
import FormVehicle from './FormVehicle';
import TableList from './TableList';
import { BtnCreate, DialogAgreement, DialogLog } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceVehicle } from '@/config/resource';
import { formatTableData } from '@/utils/data';
const defaultState = {
  licenceNumber: ''
};
export default {
  components: {
    BtnCreate,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    FormVehicle,
    TableList
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceVehicle,
    tableData: [],
    total: 0,
    searchParams: defaultState
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(queryParam = {}) {
      const busCompanyId = this.$route.query.id;
      this.$store.dispatch('resource/vehicle/fetch', {
        ...queryParam,
        busCompanyId
      }).then(data => {
        this.handleData(data);
      });
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteItem(row) {
      const {
        id
      } = row;
      const {
        drivingLicensePic,
        operationQualificationCertificatePic
      } = row;
      let files = [drivingLicensePic, operationQualificationCertificatePic];
      files = files.filter(it => it != '');
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/vehicle/delete', id).then(() => {
        this.deleteFile(files);
        this.initData();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    deleteFile(files) {
      this.$store.dispatch('resource/vehicle/deleteFile', files);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    deleteTableRow(scope) {
      this.$bus.open(() => {
        this.deleteItem(scope.row);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/vehicle/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};