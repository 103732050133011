var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "资源计划历史",
      fullscreen: "",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      "show-page": false
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.startTripDate))]), _c("div", [_vm._v(_vm._s(row.endTripDate))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: {
              row
            }
          }
        })];
      }
    }, {
      key: "shipTicket",
      fn: function ({
        row
      }) {
        return _vm._l(_vm.getShipInfo(row.shipTicket), function (item, index) {
          return _c("div", {
            key: index
          }, [_c("div", [_vm._v(_vm._s(item.shipDate) + " " + _vm._s(item.value))]), _c("div", [_vm._v(_vm._s(item.remarks))])]);
        });
      }
    }, {
      key: "createTime",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v("首次：" + _vm._s(row.createTime))]), row.updateTime ? _c("div", [_vm._v("更新：" + _vm._s(row.updateTime))]) : _vm._e()];
      }
    }, {
      key: "status",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.getBookingStatus(row.bookingStatus)) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };