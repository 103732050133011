import "core-js/modules/es.array.push.js";
import { remarkText } from '@/config/remark';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      remarkText,
      remarksObj: {},
      remarkList: [],
      BtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId'
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 获取备注
    fetchData() {
      const {
        id
      } = this.$route.query;
      // const id = this.currentOrderId
      this.$nextTick(() => {
        this.$store.dispatch('order/index/fetchRemarks', id).then(res => {
          this.remarkList = [];
          Object.keys(this.remarkText).forEach(key => {
            const item = {
              label: this.remarkText[key],
              name: key,
              value: res[key]
            };
            this.$set(item, key, res[key]);
            this.remarkList.push(item);
          });
        });
      });
    },
    // 修改备注信息
    changeRemark(index, name, val) {
      this.remarkList[index][name] = val;
    },
    handleUpdate() {
      const {
        id
      } = this.$route.query;
      this.BtnLoading = true;
      const loading = this.$bus.loading();
      this.createOrUpdate(id).then(() => {
        this.fetchData();
        this.$bus.tip();
        this.BtnLoading = false;
        loading.close();
      }).catch(() => {
        this.BtnLoading = false;
        loading.close();
      });
    },
    beforeCommit(id) {
      const opts = {};
      this.remarkList.forEach(it => {
        opts[it.name] = it[it.name];
        opts.customerOrderId = id;
      });
      return opts;
    },
    // 更新备注
    createOrUpdate(id, type) {
      return new Promise((resolve, reject) => {
        const opts = this.beforeCommit(id);
        if (type == 'clone') {
          delete opts.id;
        }
        this.$store.dispatch('order/index/createOrUpdateRemarks', opts).then(() => {
          return resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    }
  }
};