import propMixin from './propMixin';
export default {
  mixins: [propMixin],
  data() {
    return {
      remark: ''
    };
  },
  watch: {
    content: {
      handler: function (data) {
        this.remark = data;
      },
      immediate: true
    }
  },
  methods: {
    handleChange(val) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/reception/changeReception', {
          type: 'remarks',
          data: val,
          index: this.index
        }).then(() => {
          return resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    }
  }
};