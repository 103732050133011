import { ticketMixin } from '../mixin';
import TagGroup from '../components/TagGroup.vue';
export default {
  components: {
    TagGroup
  },
  mixins: [ticketMixin],
  methods: {
    handleTurn(id) {
      this.$parent.showDialog(id);
    }
  }
};