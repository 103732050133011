var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("SwitchStatus", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.isEnable,
            callback: function ($$v) {
              _vm.$set(scope.row, "isEnable", $$v);
            },
            expression: "scope.row.isEnable"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLinkBase", {
          attrs: {
            "btn-type": "detail"
          },
          on: {
            click: function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCopy(scope);
            }
          }
        }, [_vm._v(" 复制 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCustom(scope);
            }
          }
        }, [_vm._v(" 添加客户 ")]), _c("BtnLinkBase", {
          attrs: {
            "btn-type": "log"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };