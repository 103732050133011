// 游客
export const ColumnTourist = [{
  attrs: {
    prop: 'name',
    label: '姓名'
  }
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型'
  },
  slot: 'idType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  },
  slot: 'cardNumber'
}, {
  attrs: {
    prop: 'date_of_birth',
    label: '出生日期'
  }
}, {
  attrs: {
    prop: 'cardItem.issue_authority',
    label: '地址'
  }
}, {
  attrs: {
    prop: 'phone_num',
    label: '手机号码'
  },
  slot: 'phoneNumber'
}, {
  attrs: {
    prop: 'cardItem.valid_from',
    label: '有效期开始时间'
  },
  slot: 'validFrom'
}, {
  attrs: {
    prop: 'cardItem.valid_to',
    label: '到期时间'
  },
  slot: 'validTo'
}, {
  attrs: {
    prop: '',
    label: '操作'
  },
  slot: 'action'
}];
// 行程信息
export const ColumnItinerary = [{
  attrs: {
    prop: 'day_number',
    label: '行程中的第几天'
  },
  slot: 'dayNum'
}, {
  attrs: {
    prop: 'site_num',
    label: '当天的第几个站点'
  },
  slot: 'siteNum'
}, {
  attrs: {
    prop: 'journey_desc',
    label: '该站点行程描述'
  },
  slot: 'desc'
}, {
  attrs: {
    prop: 'province',
    label: '前往的省份'
  },
  slot: 'province'
}, {
  attrs: {
    prop: 'city',
    label: '前往的城市'
  },
  slot: 'city'
}, {
  attrs: {
    prop: '',
    label: '操作',
    width: 100
  },
  slot: 'action'
}];