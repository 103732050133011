// 餐厅

const width40 = 40;
const width60 = 60;
const width80 = 80;
const width90 = 90;
const width100 = 100;

// 订单信息
const orderInfo = {
  attrs: {
    prop: 'planNumber',
    label: '订单信息',
    'min-width': 100
  },
  slot: 'planNumber'
};

// 行程日期
const tripDate = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': width90
  },
  slot: 'tripDate'
};

// 订单日期
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '订单日期',
    'min-width': width90
  }
};

// 单价
const orderPrice = {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': width60
  }
};

// 数量
const orderCount = {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': width60
  }
};

// 报账导游
const orderGuideBill = {
  attrs: {
    prop: 'guideName',
    label: '报账导游',
    'min-width': width80
  }
};

// 人数
const orderPeople = {
  attrs: {
    prop: 'peopleCount',
    label: '人数',
    'min-width': width100
  },
  slot: 'peopleCount'
};
const cashType = {
  attrs: {
    prop: '$cashType',
    label: '付款方式'
  }
};

// 备注
const orderRemark = {
  attrs: {
    prop: 'checkAccountRemark',
    label: '备注'
  },
  slot: 'remark'
};

// 企业码付 TODO:
const enterpriseCode = {
  attrs: {
    prop: 'alipayECodePay',
    label: '企业码付'
  }
};

// 安排备注
const orderArrangeRemark = {
  attrs: {
    prop: 'remark',
    label: '安排备注'
  }
};

// 账面应付
const orderTotalPay = {
  attrs: {
    prop: 'totalPay',
    label: '账面应付',
    'min-width': width60
  }
};

// 已付
const orderPayed = {
  attrs: {
    prop: 'totalPayed',
    label: '已付',
    'min-width': width60
  }
};

// 未付
const orderUnPay = {
  attrs: {
    prop: 'unPay',
    label: '未付',
    'min-width': width60
  }
};

// 对账金额
const orderCheckMoney = {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额',
    'min-width': width80
  },
  slot: 'checkAccountMoney'
};

// 对账时间
const orderCheckDate = {
  attrs: {
    prop: '$checkAccountTime',
    label: '对账时间',
    'min-width': 140
  }
};

// 对账人
const orderChecker = {
  attrs: {
    prop: 'checkAccountUserName',
    label: '对账人'
  }
};

// 其他成本
const orderOther = {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width60
  }
};

// 审核
const auditStatus = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': width80
  },
  slot: 'auditStatus'
};
// 导游现付
const orderGuideCash = {
  attrs: {
    prop: 'guidePay',
    label: '导游现付',
    'min-width': width60
  }
};

// 操作
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': '120',
    fixed: 'right'
  },
  slot: 'action',
  header: 'billCheck'
};
export const Columns = [orderInfo, tripDate, orderPeople, orderGuideBill, cashType, {
  attrs: {
    prop: 'restaurantName',
    label: '餐厅名称'
  }
}, orderDate, {
  attrs: {
    prop: 'restaurantType',
    label: '餐饮类型',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '$mealType',
    label: '用餐类别'
  }
}, orderPrice, orderCount, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量',
    'min-width': width40
  }
}, orderOther, orderArrangeRemark, orderTotalPay, orderGuideCash, orderPayed, enterpriseCode, orderUnPay, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, auditStatus, actionItem];