export default {
  computed: {
    reloadPage() {
      return this.$store.getters['app/reloadPage'];
    }
  },
  watch: {
    reloadPage: {
      handler: function (val) {
        if (!val) return;
        this.fetchData();
        this.$nextTick(() => {
          this.$store.commit("app/RELOAD_PAGE", false);
        });
      },
      immediate: true
    }
  }
};