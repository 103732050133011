var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", _vm._g(_vm._b({
    attrs: {
      placement: _vm.placement,
      width: _vm.width + 50,
      trigger: _vm.trigger
    }
  }, "el-popover", _vm.$attrs, false), _vm.$listeners), [_c("TableBase2", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "max-height": _vm.height,
      "show-summary": "",
      border: ""
    },
    nativeOn: {
      mouseenter: function ($event) {
        return _vm.$emit("onMouseEnter");
      },
      mouseleave: function ($event) {
        return _vm.$emit("onMouseLeave");
      }
    },
    scopedSlots: _vm._u([{
      key: "scenicName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.scenicName) + " "), scope.row.isAddScenic ? _c("span", {
          staticStyle: {
            color: "#f44"
          }
        }, [_vm._v("(加)")]) : _vm._e()];
      }
    }])
  }), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };