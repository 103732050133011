import { Search, DialogAdd, DialogDetail, DialogHistory } from './components';
import { Columns } from './tableConfig';
import { BtnLink, DialogLog } from '@/components/common';
import { formatTime, processMinus } from '@/utils';
import { tagViewMixin } from '@/mixin';
import { InquireStatusLabel, InquireStatusType } from '@/config';
import { LOG_YPES } from '@/config/resource';
export default {
  components: {
    Search,
    DialogAdd,
    DialogDetail,
    DialogHistory,
    DialogLog,
    BtnLink
  },
  mixins: [tagViewMixin],
  data() {
    return {
      Columns
    };
  },
  computed: {
    data() {
      return this.$store.getters['inquire/bus/data'];
    },
    tableData() {
      return this.data ? this.formatData(this.data.list) : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : null;
    },
    getInquireDate() {
      return row => {
        const {
          createTime
        } = row;
        const time = createTime;
        return time ? formatTime(new Date(time)) : '';
      };
    },
    getQuoteDate() {
      return row => {
        const {
          quotationTime
        } = row;
        return quotationTime ? formatTime(new Date(quotationTime)) : '';
      };
    },
    getItinerary() {
      return val => {
        if (!val) return '';
        return val.split('\n');
      };
    }
  },
  methods: {
    processMinus,
    fetchData(params = {}) {
      this.$store.dispatch('inquire/bus/list', {
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    formatData(data) {
      if (!data.length) return [];
      return data.map(it => {
        const {
          inquiryStatus,
          quotationTime
        } = it;
        it.$inquiryStatus = InquireStatusLabel[inquiryStatus];
        it.$inquiryType = InquireStatusType[inquiryStatus];
        it.$quotationTime = formatTime(new Date(quotationTime));
        it.rowId = it.id;
        return it;
      });
    },
    showDialog() {
      this.$refs.dialogAddRef.show();
    },
    handleEdit(row) {
      this.$refs.dialogAddRef.edit(row);
    },
    handleCopy(row) {
      this.$refs.dialogAddRef.copy({
        ...row
      });
    },
    showDetail(row) {
      this.$refs.dialogDetailRef.show({
        id: row.rowId
      });
    },
    handleLog({
      id
    }) {
      const types = `${LOG_YPES.inquire},${LOG_YPES.inquire}`;
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId: id,
        types,
        subType: '',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    showHistory(row) {
      this.$refs.dialogHistoryRef.show(row.histories);
    }
  }
};