var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "GuideBorrowPrint"
    }
  }, [_c("div", {
    staticClass: "print-page__wrap"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("p", [_vm._v("导游借款申请单")]), _c("div", {
    staticClass: "date"
  }, [_c("strong", [_vm._v("日期： " + _vm._s(_vm.printDate))])])]), _c("div", {
    staticClass: "mt20"
  }), _c("TableNative", {
    attrs: {
      mode: "horization",
      data: _vm.data.list,
      columns: _vm.columnTrip
    }
  })], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };