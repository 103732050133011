import "core-js/modules/es.array.push.js";
import { busRoomList } from '@/config/report';
import { searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
import { SelectDictionary } from '@/components/common';
const today = parseDate(new Date(), '{y}-{m}-{d}');
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    hotelRegion: 'hotelRegionType',
    columnData: busRoomList,
    tableData: [],
    sendDate: [today, ''],
    endDate: [],
    searchParams: {
      startTripDateE: '',
      startTripDateS: '',
      endTripDateE: '',
      endTripDateS: '',
      pageSize: null
    }
  }),
  computed: {
    ...mapGetters({
      roomAndBus: 'report/index/roomAndBus'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            startTripDateS,
            startTripDateE,
            endTripDateS,
            endTripDateE
          } = data;
          this.searchParams = data;
          this.sendDate = [startTripDateS, startTripDateE];
          this.endDate = [endTripDateS, endTripDateE];
        }
      },
      immediate: true
    },
    roomAndBus: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    selecthotelRegion(val) {
      this.searchParams.hotelArea = val.label;
    },
    onChangStartTripDate(val) {
      this.searchParams.startTripDateS = val[0];
      this.searchParams.startTripDateE = val[1];
    },
    onChangeEndTripDate(val) {
      this.searchParams.endTripDateS = val[0];
      this.searchParams.endTripDateE = val[1];
    },
    handleData(data) {
      this.tableData = [];
      this.tableData.push(data);
    },
    fetchData(opts) {
      this.$store.dispatch('report/index/getRoomAndBus', opts);
    }
  }
};