import guideMixin from './guide';
const columnData = [{
  label: '名称',
  prop: 'name'
}, {
  label: '类别',
  prop: 'ticketType'
}, {
  label: '日期',
  prop: 'orderDate',
  width: '100px'
}, {
  label: '价格',
  prop: 'price'
}, {
  label: '实收数',
  prop: 'countReceive'
}, {
  label: '实收金',
  prop: 'totalAmountReceive',
  mergeRow: true
}, {
  label: '导比',
  prop: 'guideRate',
  mergeRow: true
}, {
  label: '导佣',
  prop: 'guideCommission',
  mergeRow: true
}, {
  label: '司比',
  prop: 'driverRate',
  mergeRow: true
}, {
  label: '司佣',
  prop: 'driverCommission',
  mergeRow: true
}, {
  label: '陪比',
  prop: 'accompanyRate',
  mergeRow: true
}, {
  label: '陪佣',
  prop: 'accompanyCommission',
  mergeRow: true
}, {
  label: '备注',
  prop: 'remarks',
  mergeRow: true,
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};