import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { COLUMNS as columns, COLUMNS_RECORD as columnsRecord } from './tableConfig';
import { mapGetters } from 'vuex';
import { getWeekByDate, parseDate, parseTime, processAdd, processMinus } from '@/utils';
import { reloadMixin } from '@/mixin';
const StataticsKeys = ['applyMoney'];
export default {
  name: 'AlipayECode',
  mixins: [reloadMixin],
  data() {
    return {
      columns,
      columnsRecord,
      StataticsKeys,
      weekColumns: [],
      tableData: []
      // tableRecord: [],
    };
  },
  computed: {
    ...mapGetters({
      weekData: 'enterprise/report/weekData',
      applyData: 'enterprise/report/applyData'
    }),
    tableRecord() {
      return this.applyData ? this.formatApplyData(this.applyData.list) : [];
    },
    statistics() {
      const data = this.applyData;
      return data ? data.statistics : null;
    },
    total() {
      const data = this.applyData;
      return data ? data.totalCount : 0;
    },
    balance() {
      return this.$store.getters['billCheck/aliecode/balance'];
    },
    // 实际现付总额
    getAllAmount() {
      if (!this.weekData) return 0;
      const all = this.calcTotal(this.weekData, it => it['allAmount']);
      return processAdd(all, this.getPeerAmount);
    },
    // 申请额度金额
    getTotalAmount() {
      if (!this.weekData) return 0;
      return this.calcTotal(this.weekData, it => {
        const {
          totalAmount,
          peerPayAmount
        } = it;
        return processAdd(totalAmount, peerPayAmount);
      });
    },
    // 实际支付金额
    getPeerAmount() {
      if (!this.weekData) return 0;
      return this.calcTotal(this.weekData, it => it['peerPayAmount']);
    },
    // 未付
    getUnAmount() {
      if (!this.weekData) return 0;
      return this.calcTotal(this.weekData, it => it['totalAmount']);
    },
    // 建议支付
    getSuggestAmount() {
      if (!this.weekData) return 0;
      const unPay = this.getUnAmount;
      const balance = this.balance;
      const res = processMinus(unPay, balance);
      return res < 0 ? 0 : res;
    },
    // 累计发放额度
    grantLimitMoney() {
      const data = this.tableRecord;
      return data.reduce((acc, cur) => {
        if (cur.alipayEnterType != 1) {
          acc = processAdd(acc, cur.applyMoney);
        }
        return acc;
      }, 0);
    }
  },
  watch: {
    weekData: {
      handler: function (d) {
        if (!d.length) {
          return;
        }
        this.weekColumns = this.formatWeekColumn(d);
        function calcMoney(it, key) {
          return it[key];
        }

        // allAmount + peerPayAmount
        const allList = this.formatWeekData({
          list: d,
          name: '预计现付金额',
          fn: it => {
            const {
              allAmount,
              peerPayAmount
            } = it;
            return processAdd(allAmount, peerPayAmount);
          },
          cls: 'green'
        });

        // totalAmount + peerPayAmount
        const peerTotalList = this.formatWeekData({
          list: d,
          name: '申请额度金额',
          fn: it => {
            const {
              totalAmount,
              peerPayAmount
            } = it;
            return processAdd(totalAmount, peerPayAmount);
          },
          cls: 'black'
        });
        const totalList = this.formatWeekData({
          list: d,
          name: '实际支付金额',
          fn: it => calcMoney(it, 'peerPayAmount'),
          cls: 'red'
        });
        const unPayList = this.formatWeekData({
          list: d,
          name: '未付金额',
          fn: it => calcMoney(it, 'totalAmount'),
          cls: 'blue'
        });
        this.tableData = [...allList, ...peerTotalList, ...totalList, ...unPayList];
      }
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchWeekData();
      this.fetchApplyData();
      this.fetchBalance();
    },
    fetchApplyData(params) {
      this.$store.dispatch('enterprise/report/applyList', params);
    },
    fetchWeekData(params = {}) {
      this.$store.dispatch('enterprise/report/weekList', params);
    },
    fetchBalance() {
      this.$store.dispatch('billCheck/aliecode/balance');
    },
    handlePage(params) {
      this.fetchApplyData(params);
    },
    tableRowClassName({
      row
    }) {
      return row.className || '';
    },
    tableHeaderClassName({
      column
    }) {
      return column.property == parseDate() ? 'row-red' : '';
    },
    calcTotal(data, fn) {
      return data.reduce((acc, cur) => {
        acc = processAdd(acc, fn(cur));
        return acc;
      }, 0);
    },
    formatWeekColumn(list) {
      return list.reduce((acc, cur) => {
        const week = getWeekByDate(cur.countDay);
        const label = `${cur.countDay}(${week})`;
        const row = {
          attrs: {
            prop: cur.countDay,
            label: label
          }
        };
        acc.push(row);
        return acc;
      }, [{
        attrs: {
          prop: 'name',
          label: '企业码支付日期'
        }
      }]);
    },
    formatWeekData({
      list,
      name,
      fn,
      cls
    }) {
      const res = list.reduce((acc, cur) => {
        acc[cur.countDay] = fn(cur) || 0;
        return acc;
      }, {
        name,
        className: 'row-' + cls
      });
      return [res];
    },
    formatApplyData(data) {
      return data.map((it, idx) => {
        it.seqNumber = idx + 1;
        it.$createTime = parseTime(it.createTime);
        return it;
      });
    }
  }
};