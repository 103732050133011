import { tableMixin, permissionMixin } from '@/mixin';
export default {
  mixins: [tableMixin, permissionMixin],
  data() {
    return {
      link: '/system/auth'
    };
  },
  methods: {
    handleEdit(row) {
      this.$parent.handleEdit(row);
    },
    handleShow(scope) {
      this.$parent.handlePreview(scope.row);
    },
    handleDelete(scope) {
      this.$bus.open(() => {
        this.$store.dispatch('system/notice/delete', scope.row.id).then(res => {
          const {
            message
          } = res;
          this.$emit('reload:data');
          this.$bus.tip({
            message
          });
        });
      });
    }
  }
};