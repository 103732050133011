// import { mapGetters } from 'vuex'
import { BtnExport, InputMonthRange, SelectDictionary, SelectCustom, SelectStaff } from '@/components/common';
import TableDetailList from './TableDetailList';
import { incomeDetail } from '@/config/analysis';
import analysisMixin from '../analysisMixin';
import { mergeTableRow } from '@/utils/table';
export default {
  components: {
    TableDetailList,
    BtnExport,
    InputMonthRange,
    SelectDictionary,
    SelectCustom,
    SelectStaff
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: incomeDetail,
      type: ''
    };
  },
  computed: {
    incomeDetailData() {
      return this.$store.getters['analyse/index/incomeDetailData'];
    }
  },
  watch: {
    incomeDetailData: {
      handler: function (data) {
        if (!data) {
          const {
            type,
            customerId,
            endMonth,
            startMonth,
            customerName
          } = this.$route.query;
          this.type = type;
          if (this.type == 'income') {
            this.searchParams.customerId = customerId;
            this.searchParams.startMonth = startMonth;
            this.searchParams.endMonth = endMonth;
            this.searchParams.customerName = customerName;
            this.sendDate = [startMonth, endMonth];
            // this.fetchData(this.searchParams)
          }
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = mergeTableRow(data, ['customerName']);
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchIncome', queryParams);
    },
    sortData(list) {
      list.sort((a, b) => {
        return a.balance - b.balance;
      });
      return list;
    },
    onSelectCustom(val) {
      this.searchParams.customerName = val.label;
      this.searchParams.customerId = val.value;
    },
    changeArea(val) {
      this.searchParams.area = val.label;
    },
    selectManager(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.customerAreaManager = label;
      this.searchParams.customerAreaManagerId = value;
    },
    changeUserName(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.outOPUserLabel = label;
      this.searchParams.outOPUserId = value;
    },
    // 选择散、发团日期
    changeMonth(date) {
      this.searchParams.startMonth = date[0];
      this.searchParams.endMonth = date[1];
    }
  }
};