/* eslint-disable no-unused-vars */
import { DEFAULT_PAGE } from '@/config';
import { LOG_YPES } from '@/config/resource';
const TYPES = {
  scenic: 'scenic',
  restaurant: 'restaurant',
  hotel: 'hotel',
  fleet: 'fleet',
  traffic: 'traffic',
  insurance: 'insurance',
  otherIncome: 'otherIncome',
  otherPay: 'otherPay',
  collect: 'collect',
  reception: 'reception',
  shopping: 'shopping'
};

// 对账管理
import { billCheck, fetchScenic, fetchRestaurant, fetchHotel, fetchTraffic, fetchFleet, fetchInsurance, fetchShopping, fetchReception, fetchCollect, fetchOtherIncome, fetchOtherPay, exportCollectExcel, exportTransportExcel, exportShopExcel, exportRestaurantferExcel, exportOutTransferExcel, exportOtherPayExcel, exportOtherIncomeExcel, exportInsuranceExcel, exportHotelExcel, exportBusExcel, exportScenicExcel, fetchOrder, fetchLog } from '@/api/bill/billCheck';
import { formatPayTypeList } from '@/utils/data';
const dfaultState = () => ({
  billCheck: null,
  scenic: null,
  restaurant: null,
  hotel: null,
  fleet: null,
  shuttleData: null,
  shuttleBatchData: null,
  traffic: null,
  insurance: null,
  otherIncome: null,
  otherPay: null,
  collect: null,
  reception: null,
  shopping: null,
  scenicOrder: {},
  transportOrder: {},
  restaurantOrder: {},
  busCompanyOrder: {}
});
const state = dfaultState();
const getters = {
  billCheck: state => state.billCheck,
  scenic: state => state.scenic,
  restaurant: state => state.restaurant,
  hotel: state => state.hotel,
  fleet: state => state.fleet,
  shuttleData: state => state.shuttleData,
  shuttleBatchData: state => state.shuttleBatchData,
  traffic: state => state.traffic,
  insurance: state => state.insurance,
  otherIncome: state => state.otherIncome,
  otherPay: state => state.otherPay,
  collect: state => state.collect,
  reception: state => state.reception,
  shopping: state => state.shopping,
  scenicOrder: state => state.scenicOrder,
  transportOrder: state => state.transportOrder,
  restaurantOrder: state => state.restaurantOrder,
  busCompanyOrder: state => state.busCompanyOrder
};
const actions = {
  // 日志
  fetchPlanLog({
    commit
  }, opts) {
    const types = `${LOG_YPES.order},${LOG_YPES.plan},${LOG_YPES.outTransfer},${LOG_YPES.sign},${LOG_YPES.audit},${LOG_YPES.bill}`;
    opts = {
      types,
      ...opts
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 对账
  billCheck({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      billCheck(opts).then(res => {
        if (res && res.data) {
          const {
            data
          } = res;
          commit('SET_DATA', {
            type: 'billCheck',
            data
          });
          resolve(data);
        } else {
          reject(res);
        }
      }).catch(reject);
    });
  },
  // 景区
  fetchScenic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchScenic(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.scenic,
        data
      });
      return data;
    });
  },
  // 餐厅
  fetchRestaurant({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchRestaurant(queryParam).then(res => {
      const {
        data
      } = res;
      // data.list = formatPayTypeList(data.list)
      commit('SET_DATA', {
        type: TYPES.restaurant,
        data
      });
      return data;
    });
  },
  // 酒店
  fetchHotel({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchHotel(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.hotel,
        data
      });
      return data;
    });
  },
  // 大交通
  fetchTraffic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchTraffic(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.traffic,
        data
      });
      return data;
    });
  },
  // 车队
  fetchFleet({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchFleet(opts).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.fleet,
        data
      });
      return data;
    });
  },
  // 接送
  fetchShuttle({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchFleet(opts).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SHUTTLE_DATA', data);
      return data;
    });
  },
  // 批量接送
  batchShuttle({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchFleet(opts).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: 'shuttleBatchData',
        data
      });
      return data;
    });
  },
  // 保险
  fetchInsurance({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchInsurance(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.insurance,
        data
      });
      return data;
    });
  },
  // 购物
  fetchShopping({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchShopping(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.shopping,
        data
      });
      return data;
    });
  },
  // 地接
  fetchReception({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchReception(queryParam).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: TYPES.reception,
        data
      });
      return data;
    });
  },
  // 应收款
  fetchCollect({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchCollect(queryParam).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: TYPES.collect,
        data
      });
      return data;
    });
  },
  // 导出应收款表
  exportCollectExcel({
    commit
  }, opts) {
    return exportCollectExcel(opts).then(res => {
      return res;
    });
  },
  // 导出大交通表
  exportTransportExcel({
    commit
  }, opts) {
    return exportTransportExcel(opts).then(res => {
      return res;
    });
  },
  // 导出购物店表
  exportShopExcel({
    commit
  }, opts) {
    return exportShopExcel(opts).then(res => {
      return res;
    });
  },
  // 导出餐厅表
  exportRestaurantferExcel({
    commit
  }, opts) {
    return exportRestaurantferExcel(opts).then(res => {
      return res;
    });
  },
  // 导出地接表
  exportOutTransferExcel({
    commit
  }, opts) {
    return exportOutTransferExcel(opts).then(res => {
      return res;
    });
  },
  // 导出其他支出表
  exportOtherPayExcel({
    commit
  }, opts) {
    return exportOtherPayExcel(opts).then(res => {
      return res;
    });
  },
  // 导出其他收入表
  exportOtherIncomeExcel({
    commit
  }, opts) {
    return exportOtherIncomeExcel(opts).then(res => {
      return res;
    });
  },
  // 导出保险表
  exportInsuranceExcel({
    commit
  }, opts) {
    return exportInsuranceExcel(opts).then(res => {
      return res;
    });
  },
  // 导出酒店对账表
  exportHotelExcel({
    commit
  }, opts) {
    return exportHotelExcel(opts).then(res => {
      return res;
    });
  },
  // 导出车队对账表
  exportBusExcel({
    commit
  }, opts) {
    return exportBusExcel({
      ...opts,
      shuttleType: ''
    }).then(res => {
      return res;
    });
  },
  // 导出车队对账表
  exportShuttleExcel({
    commit
  }, opts) {
    return exportBusExcel({
      ...opts,
      shuttleType: 'shuttle'
    }).then(res => {
      return res;
    });
  },
  // 导出景区对账表
  exportScenicExcel({
    commit
  }, opts) {
    return exportScenicExcel(opts).then(res => {
      return res;
    });
  },
  // 其他收入
  fetchOtherIncome({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchOtherIncome(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.otherIncome,
        data
      });
      return data;
    });
  },
  // 其他支出
  fetchOtherPay({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchOtherPay(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatPayTypeList(data.list);
      commit('SET_DATA', {
        type: TYPES.otherPay,
        data
      });
      return data;
    });
  },
  // 订单列表
  fetchOrder({
    commit
  }, {
    id,
    type
  }) {
    // 正式服先禁用请求
    // return []
    return fetchOrder({
      id,
      type
    }).then(res => {
      const {
        data
      } = res;
      let idData = {};
      idData[id] = data;
      commit('FETCH_ORDER_DATA', {
        type,
        idData
      });
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, {
    type,
    data
  }) => {
    state[type] = data;
  },
  SHUTTLE_DATA: (state, data) => {
    state.shuttleData = data;
  },
  FETCH_ORDER_DATA: (state, {
    type,
    idData
  }) => {
    state[type] = {
      ...state[type],
      ...idData
    };
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};