var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-all"
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "on-search": _vm.handleSearch
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "",
      disabled: !_vm.hasCustomerInfo
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小团号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": _vm.onChangeEndDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "输单日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.inputDate,
      label: ""
    },
    on: {
      "on-change": _vm.onChangeCreateDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游"
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.guideId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideId", $$v);
      },
      expression: "searchParams.guideId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "游客信息"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.memberKey,
      label: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "memberKey", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeoutOPUserName
    },
    model: {
      value: _vm.searchParams.outOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserName", $$v);
      },
      expression: "searchParams.outOPUserName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "费用"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "hasShuttleNoFee"
    },
    model: {
      value: _vm.searchParams.hasShuttleNoFee,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "hasShuttleNoFee", $$v);
      },
      expression: "searchParams.hasShuttleNoFee"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "确认件"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "confirmedItemFile"
    },
    model: {
      value: _vm.searchParams.confirmedItemFile,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "confirmedItemFile", $$v);
      },
      expression: "searchParams.confirmedItemFile"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "export"
    },
    on: {
      click: _vm.exportExcel
    }
  })], 1), _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "订单类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "orderType"
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "产品线路"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.searchParams.productName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productName", $$v);
      },
      expression: "searchParams.productName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "创建人"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.createUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "createUserId", $$v);
      },
      expression: "searchParams.createUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "责任计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeOPUserName
    },
    model: {
      value: _vm.searchParams.dutyOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserName", $$v);
      },
      expression: "searchParams.dutyOPUserName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeUserName
    },
    model: {
      value: _vm.saleUserId,
      callback: function ($$v) {
        _vm.saleUserId = $$v;
      },
      expression: "saleUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "团队类型"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "teamType",
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectDic("groupType", $event);
      }
    },
    model: {
      value: _vm.searchParams.groupType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "groupType", $$v);
      },
      expression: "searchParams.groupType"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "产品类型"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "productType",
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectDic("productType", $event);
      }
    },
    model: {
      value: _vm.searchParams.productType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productType", $$v);
      },
      expression: "searchParams.productType"
    }
  })], 1)], 1)], 2), _c("TableList", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.statistics ? _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData,
      "hide-scroll-bar": true
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "number",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAdult) + "大" + _vm._s(_vm.statistics.totalChild) + "小" + _vm._s(_vm.statistics.totalAccompanyCount) + "陪 ")];
      }
    }, {
      key: "totalIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalIncome) + " ")];
      }
    }, {
      key: "totalCollect",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalCollect) + " ")];
      }
    }, {
      key: "totalIncomed",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalIncomed) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalSubsidy) + " ")];
      }
    }, {
      key: "unCollect",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.unIncome) + " ")];
      }
    }], null, false, 4039281751)
  }) : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("FooterScrollBar", {
    attrs: {
      "real-width": _vm.realWidth,
      width: _vm.tableWidth
    },
    on: {
      "on-scroll": _vm.handleBarScroll
    }
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };