var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + "大 " + _vm._s(scope.row.childCount) + "小 " + _vm._s(scope.row.accompanyCount) + "陪 ")];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [scope.row.members ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 3
          }
        })] : [_vm._v(" -- ")]];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };