// import dayjs from 'dayjs'
import TableList from './TableList';
import { searchTicketData } from '@/config/ticket';
import { tableMixin } from '../mixin';
import { searchMixin } from '@/mixin';
import { DialogLog } from '@/components/common';
const defaultKeys = () => ({
  signType: 'ALL'
  // auditStatus: '',
  // planNumber: '',
  // signNumber: '',
  // signDateE: '',
  // signDateS: '',
  // unit: '',
});
export default {
  components: {
    TableList,
    DialogLog
  },
  mixins: [tableMixin, searchMixin],
  data: () => ({
    tableLoading: false,
    total: 0,
    columnData: searchTicketData,
    searchParams: defaultKeys()
  }),
  computed: {
    ticketSearchData() {
      return this.$store.getters['ticket/index/ticketSearchData'];
    }
  },
  watch: {
    ticketSearchData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            signDateS: this.getToday
          });
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('ticket/index/fetchSearch', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = list;
    },
    showLog(scope) {
      const {
        id
      } = scope.row;
      this.$store.dispatch('ticket/index/fetchLog', id).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 日期选择
    changeCreateDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.signDateS = startDate || '';
      this.searchParams.signDateE = endDate || '';
    }
  }
};