import { mapGetters } from 'vuex';
import { AuditRole, auditProgressStatus } from '@/config/audit';
import DialogBill from '@/components/common/Dialog/DialogBill';
export default {
  name: 'TopBar',
  components: {
    DialogBill
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    totalShow: {
      type: Boolean,
      default: false
    },
    tableDataNumber: {
      type: Array,
      default: () => []
    },
    auditStatus: {
      type: String,
      default: 'WAITAUDIT'
    },
    hasSign: {
      type: Boolean,
      default: false
    },
    billCount: {
      type: Number,
      default: 0
    },
    hasLog: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo'
    }),
    getAuditStatus() {
      const auditStatus = this.planInfo ? this.planInfo.auditStatus : '';
      // const val1 = auditProgressStatus[this.auditStatus]
      const val2 = this.planInfo ? auditProgressStatus[this.planInfo.auditStatus] : '';
      // const val3 = this.auditStatus === AuditRole.wait ? val1 : `${val1}审核`
      const val4 = auditStatus === AuditRole.wait ? val2 : `${val2}审核`;
      return `计划状态: ${val4}`;
    }
  },
  methods: {
    handleAdd() {
      this.$emit('on-add');
    },
    // 日志
    handleLog() {
      this.$bus.emit('plan:log');
    },
    // // 合计
    // handleTotal() {
    //   this.$emit('on-total')
    // },

    showBill() {
      this.$refs.dialogRef.show();
    }
  }
};