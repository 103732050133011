import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchTraffic, updateTraffic, deleteTraffic, deleteTrafficItem, fetchTrafficOrder, updateTrafficOrder, deleteTrafficOrder, fetchTrafficOrderList, selectTrafficOrderList, deleteTrafficOrderItem } from '@/api/plan/traffic';
const dfaultState = () => ({
  trafficData: null,
  trafficOrderData: null
});
const state = dfaultState();
const getters = {
  trafficData: state => state.trafficData,
  trafficOrderData: state => state.trafficOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // const { planId } = params
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchTraffic(params).then(res => {
      const {
        data
      } = res;
      // commit('TRAFFIC_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return updateTraffic(opts).then(res => {
      const {
        data
      } = res;
      // commit('TRAFFIC_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    // const planId = rootGetters['plan/index/currentPlanId']
    return deleteTraffic(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      // commit('TRAFFIC_DELETE', { planId, ids })
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteTrafficItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchTrafficOrder(params).then(res => {
      const {
        data
      } = res;
      commit('TRAFFIC_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateTrafficOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteTrafficOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteTrafficOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchTrafficOrderList({
    commit
  }, opts) {
    return fetchTrafficOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectTrafficOrderList({
    commit
  }, opts) {
    return selectTrafficOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  TRAFFIC_DATA: (state, {
    planId,
    data
  }) => {
    state.trafficData = data;
  },
  TRAFFIC_DELETE: (state, {
    planId,
    ids
  }) => {
    let trafficList = state.trafficData;
    const list = [];
    trafficList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.trafficData = list;
  },
  TRAFFIC_ORDER_DATA: (state, data) => {
    state.trafficOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};