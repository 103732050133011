import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, DelCheck, DelCheckAll, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import { planEditInsurance } from '@/config/plan';
import { StatusAudit, SelectInsurance, SelectDictionary, SelectPayType, SelectPlanNumber } from '@/components/common';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
const itemListKeys = () => ({
  count: 0,
  freePrice: 0,
  freeTotal: 0,
  insuranceType: '',
  money: 0,
  orderDate: '',
  otherFee: 0,
  price: 0
});
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  insuracneName: '',
  cashType: 'sign',
  remark: '',
  totalPay: 0,
  rowTotal: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});
export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    TopBar,
    DelCheck,
    DelCheckAll,
    StatusAudit,
    SelectInsurance,
    SelectDictionary,
    SelectPayType,
    DialogOrder,
    DialogList,
    PaymentTotal,
    AuditRoleAll,
    SelectPlanNumber,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'insuranceOrderStatus',
      deletePath: 'plan/insurance/delete',
      updatePath: 'plan/insurance/update',
      fetchPath: 'plan/insurance/fetch',
      headMergeNum: 3,
      tailMergeNum: planEditInsurance.length - 6,
      hasMergeTail: true,
      rowId: 1000000000000,
      auditAll: false
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId'
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      // 是否显示订单信息
      // 如果不显示订单信息
      if (!this.hasOrderInfo) {
        this.headMergeNum = 2;
      }
      this.$nextTick(() => {
        this.resetData();
      });
    },
    handleSelect(row, val) {
      row.insuranceId = val.id;
      row.insuranceName = val.label;
    },
    selectInsuranceType(row, val) {
      row.insuranceType = val.label;
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        count,
        price,
        otherFee
      } = obj;
      return count * price + Number(otherFee);
    },
    // 修改单行金额
    changeInput(item) {
      item.money = item.totalPay = this.calcRowTotalMoney(item);
      this.mergeRowMoney(); // 合并单条数据总价
    },
    changePayType(row, val) {
      row.payType = val;
    },
    // 当前行添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/insurance/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isChecked: false,
          ...itemListKeys(),
          insuranceType: row.insuranceType
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.getTotalPayMoney();
      this.mergeRowMoney(); // 合并单条数据总价
      this.rowspan();
    },
    // 新增一条数据
    handlePlus() {
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: this.planInfo.adultCount || 1
      });
      this.rowspan();
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderInsuranceTypes;
        const {
          id,
          auditProgress,
          customerOrderId
        } = it;
        const item = {
          checkAccountStatus: it.checkAccountStatus,
          rowId: id,
          planInsuranceId: id,
          isCustom: false,
          isChecked: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          cashType: it.cashType.toLowerCase() || 'sign',
          payType: it.cashType.toLowerCase() || 'sign',
          // 用于统计金额，因为组件那边判断的是 payType，这里返回的字段是 cashType
          insuranceId: it.insuranceId,
          totalPay: it.totalPay,
          rowTotal: it.totalPay,
          remark: it.remark,
          money: it.money,
          planId: it.planId,
          customerOrderId
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = v.price * v.count + Number(v.otherFee);
            delete v.remark;
            result.push({
              ...item,
              ...v,
              insuranceType: v.insuranceType,
              itemIndex: i,
              totalSum
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;
        // 定义子项目的列表
        it.reqOrderInsuranceTypes = [];
        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.planInsuranceId;
        it.cashType = it.cashType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planInsuranceId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.reqOrderInsuranceTypes.push(item);
        } else {
          it.reqOrderInsuranceTypes.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          insuranceId,
          insuranceType,
          orderDate,
          price,
          count
        } = row;
        if (!insuranceId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择保险公司！'
          });
          return false;
        }
        if (!insuranceType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择保险类型！'
          });
          return false;
        }
        if (!orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择保险日期！'
          });
          return false;
        }
        if (price < 0) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写保险价格！'
          });
          return false;
        }
        if (!count) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写保险数量！'
          });
          return false;
        }
      }
      return true;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        it.totalPay = totalPay;
      });
    },
    resetData() {
      // this.getTotalPayMoney()
      this.rowspan();
      this.mergeRowMoney(false); // 合并单条数据总价
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.insurance,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};