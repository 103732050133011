var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box plan-number"
  }, [_c("LabelBase", {
    attrs: {
      label: "大团号"
    }
  }), _c("TextBase", [_vm._v(" " + _vm._s(_vm.getPlanNumber) + " ")])], 1), _c("SelectSign", {
    attrs: {
      label: "单据"
    },
    on: {
      "on-select": _vm.selectSign
    },
    model: {
      value: _vm.form.signSheetId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "signSheetId", $$v);
      },
      expression: "form.signSheetId"
    }
  }), _c("span", {
    staticClass: "pl5"
  }), _vm.isGroup ? _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.selectGroupSign
    }
  }, [_vm._v(" 选择集团单据 ")]) : _vm._e(), _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("单据号:")]), _c("el-input", {
    attrs: {
      type: "textarea",
      disabled: _vm.isGroup,
      autosize: "",
      placeholder: "请输入初始单号（单号+数量，多个单号则换行输入）"
    },
    model: {
      value: _vm.form.startNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startNo", $$v);
      },
      expression: "form.startNo"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "create",
      disabled: _vm.isDisabled || _vm.isGroup
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 添加单据 ")])], 1)], 1), _c("DialogTicket", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };