import { BtnLink } from '@/components/common';
export default {
  components: {
    BtnLink
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    keyVal: {
      type: String,
      default: 'planNumber'
    }
  },
  data() {
    return {
      visible: false
    };
  }
};