import { auditProfitNew } from '@/config/audit'; //auditProfit
import { mapGetters } from 'vuex';
import { decimalSplit } from '@/utils';
export default {
  data() {
    return {
      tableData: [],
      columnData: auditProfitNew,
      redirectPath: '/audit/team-single-edit',
      id: '',
      totalIncome: 0,
      totalQuotePay: 0,
      totalGrossProfitRate: 0,
      totalQuoteGrossProfit: 0,
      totalQuoteGrossMargin: 0,
      guideReimbTotalInfo: null
    };
  },
  computed: {
    ...mapGetters({
      teamProfit: 'plan/index/teamProfit',
      currentPlanId: 'plan/index/currentPlanId',
      firstEnter: 'plan/index/firstEnter'
    }),
    reloadPage() {
      return this.$store.getters['app/reloadPage'];
    }
  },
  watch: {
    teamProfit: {
      handler: function (data) {
        if (!data || !data.length) return;
        this.tableData = data;
        this.totalIncome = data[0].totalIncome ? data[0].totalIncome : 0;
        this.totalQuotePay = data[0].totalQuotePay ? data[0].totalQuotePay : 0;
        this.totalQuoteGrossProfit = data[0].totalQuoteGrossProfit ? data[0].totalQuoteGrossProfit : 0;
        this.totalQuoteGrossMargin = data[0].totalQuoteGrossMargin ? data[0].totalQuoteGrossMargin : 0;
        this.guideReimbTotalInfo = data[0].guideReimbTotalInfo;
        // 毛利率 = 毛利 / 总收入
        this.totalGrossProfitRate = Number(this.totalIncome) && Number(data[0].totalQuoteGrossProfit) ? decimalSplit(data[0].totalQuoteGrossProfit / this.totalIncome * 100) : 0;
      },
      immediate: true
    },
    reloadPage: {
      handler: function (val) {
        if (!val) return;
        this.fetchData();
      },
      immediate: true
    }
  },
  // mounted() {
  //   let { planId, id } = this.$route.query
  //   this.id = planId || id

  //   if (this.firstEnter) {
  //     this.fetchData(id)
  //   }
  // },

  created() {
    let {
      planId,
      id
    } = this.$route.query;
    this.id = planId || id;
    if (this.firstEnter) {
      this.fetchData(id);
    }
  },
  methods: {
    // 单团利润表
    fetchData() {
      this.$store.dispatch('plan/index/groupProfit', this.id);
    }
  }
};