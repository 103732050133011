import "core-js/modules/es.array.push.js";
import TableUnPay from './TableUnPay';
import { FooterBar, BillInput } from '@/components/common';
import { SelectCustom } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { TYPES, CashFeeType } from '@/config/billDown';
import { formatDownData } from '@/views/billDown/help';
export default {
  components: {
    BillInput,
    FooterBar,
    // BaseInput,
    TableUnPay,
    SelectCustom
  },
  mixins: [tagViewMixin],
  data() {
    return {
      TYPES,
      CashFeeType,
      exportLoading: false,
      form: {},
      // tableData: [],
      // columnData: downBillUnitCollect,
      // total: 0,
      resourceId: '',
      backPath: '/bill/unit-collect/customer'
    };
  },
  computed: {
    unPayData() {
      return this.$store.getters['bill/billDown/unPayData'];
    }
  },
  // watch: {
  //   unPayData: {
  //     handler: function (data) {
  //       if (!data || !data.list) return
  //       const money = calcTotalMoney(data.list, 'owePay')
  //       this.form.money = money
  //       this.form.userMoney = money
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    fetchList() {
      this.$store.dispatch('bill/billDown/fetchTeamFund');
    },
    fetchBalance() {
      if (!this.resourceId) return;
      return this.$store.dispatch('bill/billDown/collect/fetchCollectBalance', {
        rid: this.resourceId,
        cashType: TYPES.customer
      });
    },
    changeMoney(money) {
      this.$refs.tableRef.caclDownMoney(money);
    },
    selectResource(val) {
      // if (!val.label && !val.value) return

      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.tableRef.handleSelect(resourceId);
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const list = this.$refs.tableRef.tableData;
        const filterList = list.filter(it => it.downPayed != '');
        const opts = formatDownData({
          list: filterList,
          form: {
            ...form,
            resourceId: this.resourceId
          },
          cashAction: CashFeeType.INCOME,
          customerFeeType: 'FEE'
        });
        this.$store.dispatch('bill/billDown/collect/addCollect', {
          ...opts,
          cashType: TYPES.customer
        }).then(() => {
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        });
      });
    }

    // // 保存
    // handleSave() {
    //   this.$refs.formRef.handleValidation().then((form) => {
    //     this.$store
    //       // .dispatch('bill/billDown/createCollect', CustomerFeeType.fee)
    //       .dispatch('bill/billDown/collect/addCollect', CustomerFeeType.fee)
    //       .then(() => {
    //         this.$bus.tip()
    //         this.handleClose()
    //         this.goBack()
    //       })
    //   })
    // },
  }
};