import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { remarkText } from '@/config/remark';
export default {
  data() {
    return {
      remarkText,
      s_touristRemark: {
        serviceStandardRemark: '服务标准',
        friendlyReminderRemark: '友情提示'
      },
      remarksObj: {},
      remarkList: [],
      BtnLoading: false,
      planId: '',
      isRemarks: false,
      customerOrderId: ''
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo'
    })
  },
  watch: {
    planInfo: {
      handler: function (data) {
        const {
          planId
        } = this.$route.query;
        if (!data || data.id != planId) this.fetchData();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    const {
      planType
    } = this.$route.query;
    if (planType == 'S_TOURIST') {
      this.remarkText = this.s_touristRemark;
      this.initPlanDetail(this.planInfo);
    } else {
      this.fetchOrData();
    }
  },
  methods: {
    initPlanDetail(res) {
      if (!res) {
        this.fetchData();
        return;
      }
      const remarkList = [];
      Object.keys(this.remarkText).forEach(key => {
        const item = {
          label: this.remarkText[key],
          name: key,
          value: res[key]
        };
        this.$set(item, key, res[key]);
        remarkList.push(item);
      });
      this.remarkList = remarkList;
    },
    fetchData() {
      const {
        planId
      } = this.$route.query;
      this.$store.dispatch('plan/index/fetchPlanById', planId).then(res => {
        if (res.planType == 'S_TOURIST') {
          this.initPlanDetail(res);
        }
      });
    },
    // 获取备注
    fetchOrData() {
      const {
        planId
      } = this.$route.query;
      this.$nextTick(() => {
        this.$store.dispatch('plan/index/fetchPlanIdOrderRemarks', {
          planId
        }).then(res => {
          // 目前只需要用到团队的所以有数据的话就只取第一条
          if (res.length) {
            this.remarkList = [];
            this.customerOrderId = res[0].customerOrderId;
            Object.keys(this.remarkText).forEach(key => {
              const item = {
                label: this.remarkText[key],
                name: key,
                value: res[0][key]
              };
              this.$set(item, key, res[0][key]);
              this.remarkList.push(item);
            });
          } else {
            Object.keys(this.remarkText).forEach(key => {
              const item = {
                label: this.remarkText[key],
                name: key,
                value: res[key]
              };
              this.$set(item, key, res[key]);
              this.remarkList.push(item);
            });
          }
        });
      });
    },
    // 修改备注信息
    changeRemark(index, name, val) {
      this.remarkList[index][name] = val;
    },
    handleUpdate() {
      const {
        planType,
        planId
      } = this.$route.query;
      if (planType == 'T_TOURIST') {
        const {
          orders
        } = this.planInfo;
        let id = -1;
        if (orders) {
          id = orders[0].id;
        }
        const opts = this.beforeCommit(planType, id);
        this.createOrUpdateT(opts);
      } else {
        const opts = this.beforeCommit(planType, planId);
        this.createOrUpdateS(opts);
      }
    },
    beforeCommit(planType, id) {
      const opts = {};
      this.remarkList.forEach(it => {
        opts[it.name] = it[it.name];
        if (planType == 'T_TOURIST') {
          opts.customerOrderId = id;
        } else {
          opts.id = id;
        }
      });
      return opts;
    },
    // 更新散客备注
    createOrUpdateS(opts) {
      this.$store.dispatch('plan/index/updatePlan', opts).then(() => {
        this.fetchData();
        this.$bus.tip();
      });
    },
    // 更新团队备注
    createOrUpdateT(opts) {
      this.$store.dispatch('order/index/createOrUpdateRemarks', opts).then(() => {
        this.fetchOrData();
        this.$bus.tip();
      });
    }
  }
};