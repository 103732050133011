// 按单位 保险
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  orderArrangeRemark,
  orderTotalPay,
  payed,
  price,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
const lastItem = [orderArrangeRemark, orderTotalPay, payed, owePay, downPayed, actionItem];
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '保险'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'insuranceName',
    label: '保险'
  }
}, price, ...lastItem];