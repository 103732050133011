var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FormBase", {
    attrs: {
      inline: ""
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "购物店名称"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "地区"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "hotelRegionType",
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          area: $event.label
        };
      }
    },
    model: {
      value: _vm.searchParams.area,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "area", $$v);
      },
      expression: "searchParams.area"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: ""
    }
  }, [_c("BtnCreate", {
    on: {
      "on-search": _vm.onSearch,
      "on-create": function ($event) {
        return _vm.$emit("add", _vm.searchParams);
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };