import { inputMixin, selectListMixin } from '@/mixin';
// import { hasGuideSelectData } from '@/utils/requestSelectData'

export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/guide/fetchList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/guide/guideData'];
    }
  }

  // created() {
  //   hasGuideSelectData()
  // },
};