import "core-js/modules/es.array.push.js";
import DialogItinerary from './DialogItinerary';
import { IconRight, IconWrong } from '@/components/common';
import { orderProductLine } from '@/config/order';
import { getNextDate, getDaysBetween } from '@/utils';
import { mapGetters } from 'vuex';
import { dictionaryMixin } from '@/mixin';
import { SELECTOR } from '@/config/select';
const roomsState = () => ({
  quantity: '',
  roomType: '',
  roomTypeId: '',
  roomName: ''
});
export default {
  components: {
    IconRight,
    IconWrong,
    DialogItinerary
  },
  mixins: [dictionaryMixin],
  props: {
    compName: {
      type: String,
      default: 'TeamUpdate'
    },
    isShowBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columnData: orderProductLine,
      tableData: [],
      hotelStandard: 'hotelStandardType',
      hotelRegion: 'hotelRegionType',
      hotelRoom: 'roomType',
      selectStatus: true
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    hasMoreRoom() {
      return item => {
        return item.rooms.length > 1;
      };
    },
    getMoreRoom() {
      return item => {
        return item.rooms.slice(1);
      };
    },
    ifLastRow() {
      return index => {
        return this.tableData.length - 1 === index;
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.fetchData();
      }, 500);
    });

    // 刷新数据
    this.$bus.on('itinerary:update', this.updateTrip);
    this.$bus.on('itinerary:reload', this.fetchData);
  },
  methods: {
    // 关闭 $bus.on 事件
    offListener() {
      this.$bus.off(this.updateTrip);
      this.$bus.off(this.fetchData);
    },
    //
    trafficFromat(type) {
      const bigTrafficName = SELECTOR.bigTraffic.filter(item => item.value == type)[0].label;
      return bigTrafficName;
    },
    // 获取订单行程
    fetchData() {
      const id = this.currentOrderId;
      this.selectStatus = false;
      this.$store.dispatch('order/index/fetchItinerary', id).then(data => {
        // 如果行程数据为空则重新获取产品行程并创建
        if (!data || !data.length) {
          const {
            productId,
            startTripDate,
            endTripDate
          } = this.currentOrder;
          const betweenDay = getDaysBetween(startTripDate, endTripDate);
          console.log('productId:', productId);
          this.getProduct({
            productId,
            currentOrderId: this.currentOrderId,
            startTripDate,
            betweenDay
          });
        } else {
          this.processItineraryData(data);
        }
        this.selectStatus = true;
        this.offListener();
      });
    },
    processItineraryData(data) {
      this.tableData = this.formatData(data);
      this.$store.commit('order/itinerary/ORDER_ITINERARY', data);
    },
    getProduct({
      productId,
      currentOrderId,
      startTripDate,
      betweenDay
    }) {
      this.$store.dispatch('resource/product/fetchItinerary', productId).then(data => {
        const itineraryList = this.formatProduct(data, currentOrderId, startTripDate, betweenDay);
        this.$store.dispatch('order/index/createOrUpdateItinerary', itineraryList).then(() => {
          this.$store.dispatch('order/index/fetchItinerary', this.currentOrderId).then(data => {
            this.processItineraryData(data);
          });
        });
      });
    },
    // 格式化产品行程
    formatProduct(data, customerOrderId, startTripDate, betweenDay) {
      console.log('data:', data);
      const {
        itineraries
      } = data;
      let curDate = startTripDate;
      let tripList = [];
      for (let i = 0; i < betweenDay; i++) {
        const item = itineraries[i];

        // 如果日期没有超出行程
        if (item) {
          delete item.id;
          item.itineraryDate = curDate;
          item.customerOrderId = customerOrderId;
          item.rooms = [roomsState()];
          item.transports = [];
          tripList.push(item);
        } else {
          tripList.push({
            isPick: false,
            isSend: false,
            itineraryDate: curDate,
            customerOrderId,
            rooms: [roomsState()],
            transports: []
          });
        }
        curDate = getNextDate(curDate);
      }
      return tripList;
    },
    mergeId(list, id) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.itineraryId = id;
        return it;
      });
    },
    showItinerary() {
      // this.$bus.emit('show:itinerary', this.tableData)
      this.$refs.itineraryRef.show(this.tableData);
    },
    selectArea(item, val) {
      const {
        label,
        value
      } = val;
      item.area = label;
      item.areaName = label;
      item.areaId = value;
      this.changeArrangeState(item);
    },
    selectStandard(item, val) {
      const {
        label,
        value
      } = val;
      item.standard = label;
      item.standardId = value;
      this.changeArrangeState(item);
    },
    // 判断是否需要订单
    changeArrangeState(item) {
      if (item.standard || item.area) {
        item.arrangeState = true;
      } else {
        item.arrangeState = false;
      }
    },
    selectRoom(item, val) {
      const {
        label,
        value
      } = val;
      item.roomTypeId = value;
      item.roomType = label;
    },
    selectHotel(item, val) {
      item.hotelId = val.id;
    },
    changeRoomQuantity(item, val) {
      item.quantity = val;
    },
    addRoomType(item) {
      const copy = {
        ...item.rooms[item.rooms.length - 1]
      };
      const {
        id,
        name
      } = this.getRoomBaseType();
      copy.roomType = name;
      copy.roomTypeId = id;
      item.rooms.push(copy);
    },
    delRoomType(item, index) {
      item.row.rooms.splice(index, 1);
    },
    delTrafficType(data) {
      const {
        id
      } = data;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/deleteTraffic', id).then(() => {
          this.$bus.emit('itinerary:reload');
        });
      }, `是否确定删除该行程的大交通？`);
    },
    // editTraffic(row) {
    //   const comp = findComponentsUpward(this, this.compName)[0]
    //   comp.editTraffic(row)
    // },

    updateTrip({
      id,
      startTripDate,
      endTripDate
    }) {
      let curDate = startTripDate;
      let dateArr = [];
      const betweenDay = getDaysBetween(startTripDate, endTripDate);

      // 加工日期
      dateArr.push(startTripDate);
      for (let i = 1; i < betweenDay; i++) {
        curDate = getNextDate(curDate);
        dateArr.push(curDate);
      }

      // 修改行程日期
      this.tableData.forEach((item, index) => {
        item.itineraryDate = dateArr[index] || endTripDate;
        item.isSend = false;
      });
      const len = dateArr.length;
      if (len > this.tableData.length) {
        const lastArr = dateArr.slice(this.tableData.length);
        const lastIndex = this.tableData.length - 1;
        const temp = this.tableData[lastIndex];
        const {
          createUserId,
          customerOrderId
        } = temp;
        this.tableData[lastIndex].isSend = false;
        lastArr.forEach(itineraryDate => {
          const item = {
            area: '',
            arrangeState: 2,
            breakfast: false,
            createUserId,
            customerOrderId,
            dinner: false,
            hotel: '',
            hotelId: 0,
            id: '',
            isPick: false,
            isSend: false,
            itineraryDate: itineraryDate || '',
            itineraryDesc: '',
            lunch: false,
            rooms: [{
              quantity: 0,
              itineraryId: '',
              remarks: '',
              roomType: ''
            }],
            scenics: [],
            selfScenic: '',
            shops: [],
            standard: '',
            transports: []
          };
          this.tableData.push(item);
        });
      }
      if (len < this.tableData.length) {
        // const lastIndex = this.tableData.length - 1
        const lastTripArr = this.tableData.slice(len);
        const needDelTrip = lastTripArr.map(it => {
          return it.id;
        });
        this.tableData = this.tableData.slice(0, len);

        // needDelTrip.forEach(async it => {
        //   await this.$store.dispatch('order/index/deleteItinerary', it.id)
        // })

        this.$nextTick(() => {
          this.$store.dispatch('order/index/deleteItinerarys', needDelTrip);
        });
      }
      const lastIndex = this.tableData.length - 1;
      this.tableData[lastIndex].isSend = true;
      this.createOrUpdate().then(() => {
        this.fetchData(id);
      });
    },
    handleUpdate() {
      const loading = this.$bus.loading();
      this.createOrUpdate().then(() => {
        this.fetchData();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 处理提交的数据
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        this.tableData.map((it, index) => {
          if (index != 0) {
            it.isPick = false;
          }
          if (this.tableData.length - 1 != index) {
            it.isSend = false;
          }
          it.arrangeState = !it.arrangeState ? 2 : 0;
          return it;
        });
        return it;
      });
    },
    createOrUpdate() {
      if (!this.tableData.length) return Promise.resolve(1);
      return new Promise((resolve, reject) => {
        const dataList = this.beforeCommit(this.tableData);
        //  this.tableData.map(it => {
        //   it.arrangeState = !it.arrangeState ? 2 : 0
        //   return it
        // })
        this.$store.dispatch('order/index/createOrUpdateItinerary', dataList).then(() => {
          return resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    },
    // 处理获取下来的数据
    formatData(data) {
      if (!data || !data.length) return [];
      return data.map(it => {
        it.areaName = it.area;
        if (it.arrangeState === 0) {
          it.arrangeState = true;
        }
        if (it.arrangeState === 2) {
          it.arrangeState = false;
        }

        // 重组景区
        const scenics = this.formatScenic(it);

        // 重组购物店
        const shopItem = this.formatShop(it.shops);
        // 排序
        const itemItiner = [...scenics, ...shopItem].sort(function (a, b) {
          return a.orderNumber - b.orderNumber; //正序
        });
        const itineraryData = this.formatItinerary(itemItiner).filter(v => v != '').join('');
        const isPick = it.isPick ? '（接团）' : '';
        const isSend = it.isSend ? '（送团）' : '';
        it.itineraryDesc = it.itineraryDesc + it.selfScenic + shopItem;
        it.itineraryDesc = isPick + itineraryData + it.selfScenic + isSend;
        if (it.hotelId <= 0) {
          this.$set(it, 'hotelId', '');
        }
        it.shops.forEach(item => {
          if (item.shopId <= 0) {
            this.$set(item, 'shopId', '');
          }
        });
        return it;
      });
    },
    showNotice() {
      this.$bus.tip({
        type: 'warning',
        message: '该酒店还未添加任何房型'
      });
    },
    formatRoomOptions(list) {
      if (!list) return;
      return list.map(it => {
        return {
          id: it.id,
          label: it.contentType,
          value: it.id
        };
      });
    },
    formatRooms(rooms) {
      return rooms.map(item => {
        item.quantity = item.quantity > 0 && this.currentOrder ? item.quantity : this.currentOrder.adultCount / 2;
        return item;
      });
    },
    formatScenic(itineraries) {
      return itineraries.scenics.map(it => {
        if (!it.scenicName) return '';
        const item = {
          name: it.scenicName,
          orderNumber: it.orderNumber
        };
        return item;
      });
    },
    formatShop(shops) {
      return shops.map(it => {
        if (!it.shopName) return '';
        const item = {
          name: it.shopName,
          orderNumber: it.orderNumber,
          shopId: it.shopId + ''
        };
        return item;
      });
    },
    formatItinerary(item) {
      return item.map(it => {
        if (!it.name) return '';
        return '【' + it.name + '】';
      });
    },
    // 清理上一次的行程
    async clearLastItinerary(id) {
      return await this.$store.dispatch('order/index/delItineraryByOrderId', id);
    }
  }
};