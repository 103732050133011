var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.currentPlanId ? _c("div", {
    staticClass: "single-detail form-wrapper"
  }, [_c("DetailBaseInfo"), _vm.getRoleAuthByName("团利润") ? _c("CollapseBase", {
    attrs: {
      title: "团利润"
    }
  }, [_c("TableTotalProfit", {
    ref: "TableTotalProfitRef"
  })], 1) : _vm._e(), _vm.getRoleAuthByName("小组列表") ? _c("CollapseBase", {
    attrs: {
      title: "小组列表"
    }
  }, [_c("DetailOrderList")], 1) : _vm._e(), _vm.getRoleAuthByName("行程") ? _c("CollapseBase", {
    attrs: {
      title: "行程"
    }
  }, [_c("DetailItinerary")], 1) : _vm._e(), _vm.getRoleAuthByName("客户团款") ? _c("CollapseBase", {
    attrs: {
      title: "客户团款"
    }
  }, [_c("DetailPlanFund")], 1) : _vm._e(), _vm.getRoleAuthByName("酒店") ? _c("CollapseBase", {
    attrs: {
      title: "酒店"
    }
  }, [_c("HotelPreview", {
    attrs: {
      "column-data": _vm.columnData
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("餐厅") ? _c("CollapseBase", {
    attrs: {
      title: "餐厅"
    }
  }, [_c("RestaurantPreview", {
    attrs: {
      "column-data": _vm.planEditRestaurant
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("车队") ? _c("CollapseBase", {
    attrs: {
      title: "车队"
    }
  }, [_c("FleetPreview", {
    attrs: {
      "column-data": _vm.planEditFleet
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("导游") ? _c("CollapseBase", {
    attrs: {
      title: "导游"
    }
  }, [_c("GuidePreview", {
    attrs: {
      "column-data": _vm.planEditGuide
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("导游借款") ? _c("CollapseBase", {
    attrs: {
      title: "导游借款"
    }
  }, [_c("GuideBorrowPreview", {
    attrs: {
      "column-data": _vm.planEditGuideBorrow
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("保险") ? _c("CollapseBase", {
    attrs: {
      title: "保险"
    }
  }, [_c("InsurancePreview", {
    attrs: {
      "column-data": _vm.planEditInsurance
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("其他收入") ? _c("CollapseBase", {
    attrs: {
      title: "其他收入"
    }
  }, [_c("OtherIncomePreview", {
    attrs: {
      "column-data": _vm.planEditOtherIncome
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("其他支出") ? _c("CollapseBase", {
    attrs: {
      title: "其他支出"
    }
  }, [_c("OtherPayPreview", {
    attrs: {
      "column-data": _vm.planEditOtherPay
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("大交通") ? _c("CollapseBase", {
    attrs: {
      title: "大交通"
    }
  }, [_c("TrafficPreview", {
    attrs: {
      "column-data": _vm.planEditTraffic
    }
  })], 1) : _vm._e(), _vm.getRoleAuthByName("景区") ? _c("CollapseBase", {
    attrs: {
      title: "景区"
    }
  }, [_c("DetailScenic")], 1) : _vm._e(), _vm.getRoleAuthByName("购物") ? _c("CollapseBase", {
    attrs: {
      title: "购物"
    }
  }, [_c("ShoppingPreview", {
    attrs: {
      "column-data": _vm.planEditShopping
    }
  })], 1) : _vm._e(), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印单据 ")])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };