// 兴坪船票

import axios from 'axios';
import store from '@/store';
const BASE_URL_REVIER = 'http://180.140.114.121:8088';
const service = axios.create({
  baseURL: BASE_URL_REVIER,
  timeout: 6000
});
service.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});
service.interceptors.response.use(response => {
  return Promise.resolve(response.data);
}, error => {
  console.log('error:', error);
  store.dispatch('plan/boat/riverToken');
});
export default service;