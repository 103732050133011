var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "guideName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideName) + " ")];
      }
    }, {
      key: "guidePhone",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guidePhone) + " ")];
      }
    }, {
      key: "startPlanDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " ")];
      }
    }, {
      key: "endPlanDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "guideServiceFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideServiceFee) + " ")];
      }
    }, {
      key: "reimb",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.reimb ? "是" : "否") + " ")];
      }
    }, {
      key: "guideFund",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideFund) + " ")];
      }
    }, {
      key: "guideTax",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideTax) + " ")];
      }
    }, {
      key: "collectType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.settlementMethod) + " ")];
      }
    }, {
      key: "guideManager",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getGuideManageName(scope.row.guideManageId)) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }, {
      key: "depositFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.depositFee) + " ")];
      }
    }, {
      key: "depositHeader",
      fn: function ({}) {
        return [_vm._v(" 押金 ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };