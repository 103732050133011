var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入备注",
      disabled: _vm.isDisabled,
      autosize: {
        minRows: 6,
        maxRows: 20
      }
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.remark,
      callback: function ($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };