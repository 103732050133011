var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "plan-transfor-dialog",
    attrs: {
      title: "外转",
      width: "1360px",
      visible: _vm.visible,
      disable: true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "整团外转",
      name: _vm.typeMap.team
    }
  }, [_vm.visibleCustom ? _c("div", {
    staticClass: "flex"
  }, [_c("SelectOutCustom", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "地接",
      clearable: ""
    },
    model: {
      value: _vm.customerId,
      callback: function ($$v) {
        _vm.customerId = $$v;
      },
      expression: "customerId"
    }
  })], 1) : _vm._e(), _c("TableTeam", {
    ref: "tableTeamRef",
    attrs: {
      data: _vm.teamData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "按单外转",
      name: _vm.typeMap.order
    }
  }, [_c("TableOrder", {
    ref: "tableOrderRef",
    attrs: {
      data: _vm.orderData
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };