import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { SelectStaff, StatusAudit } from '@/components/common';
import { planEditGuideBorrow, planEditGuideBorrowDingDing } from '@/config/plan';
import DialogQuotaApply from './DialogQuotaApply';
import DialogQuotaAppend from './DialogQuotaAppend';
import DialogInstitution from './DialogInstitution';
import { AuditRole } from '@/config/audit';
import { DingTalkStatus } from '@/config/plan';
import { isSuperMan } from '@/utils/power';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  guideId: '',
  guideName: '',
  applyMoney: '',
  borrowType: '',
  bankId: '',
  remarks: '',
  borrowMoney: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait
});
export default {
  components: {
    TopBar,
    // DelCheckAll,
    Audit,
    AuditAdmin,
    AuditRoleAll,
    AuditCheck,
    StatusAudit,
    SelectStaff,
    AuditStatus,
    CheckStatus,
    DialogQuotaApply,
    DialogQuotaAppend,
    DialogInstitution
  },
  mixins: [selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      columns: [],
      hasDataStatusKey: 'guideBorrowApplyStatus',
      deletePath: 'plan/guide/delBorrowApply',
      updatePath: 'plan/guide/setPlanBorrow',
      fetchPath: 'plan/guide/fetchPlanBorrow',
      auditAll: false,
      isGuideQuota: true,
      rowId: 10000000000000,
      checkedAll: false,
      mergeTable: false
    };
  },
  computed: {
    isSuperMan,
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId',
      planInfo: 'plan/index/planInfo',
      guideData: 'plan/guide/guideData',
      mainGuide: 'plan/guide/mainGuide'
    }),
    showDDApply() {
      return this.borrowApplyDingDingEnable;
    },
    getApplyStatus() {
      return status => DingTalkStatus[status];
    },
    getReimbGuide() {
      const data = this.planInfo;
      if (!data) return;
      const {
        guides
      } = data;
      return guides.filter(it => !!it.reimb)[0] || guides[0];
    },
    guideNoInvite() {
      const guide = this.getReimbGuide;
      return guide && guide.alipayEcodeStatus == 0;
    }
  },
  created() {
    let {
      id,
      planId
    } = this.$route.query;
    id = planId || id;
    this.fetchData({
      id,
      planId
    });
    if (!this.guideData) {
      this.fetchGuideData();
    }
    this.columns = this.showDDApply ? planEditGuideBorrowDingDing : planEditGuideBorrow;
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    fetchGuideData() {
      let {
        id,
        planId
      } = this.$route.query;
      id = planId || id;
      this.$store.dispatch('plan/guide/fetch', {
        planId: id
      });
    },
    showApplyDialog() {
      this.$refs.dialogRef.show();
    },
    appendApplyDialog() {
      const row = this.tableData[0];
      this.$refs.dialogAppendRef.append(row);
    },
    // 超管删除的接口
    handleApplyDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('plan/guide/powerDeleteBorrowApprove', [id]).then(() => {
          // 重新请求数据
          this.fetchData();
        });
      });
    },
    // 删除支付宝企业码申请
    handleEcodeDelete({
      id
    }) {
      this.$bus.open(() => {
        this.$store.dispatch('enterprise/guide/delQuotaByIds', [id]).then(() => {
          // 重新请求数据
          this.fetchData();
        });
      });
    },
    // 支付宝企业码详情
    handleEcodeDetail(item) {
      const {
        iid
      } = item[0];
      this.$store.dispatch('enterprise/trip/alipayInstitution', {
        institutionId: iid
      }).then(data => {
        if (!data) return;
        this.$refs.dialogInstitutionRef.show(data);
      });
    },
    onRemove(row) {
      const {
        mode
      } = row;
      const {
        rowId: id,
        isCustom
      } = row;
      const opts = [id];
      if (isCustom) {
        this.handleLocalRemove(opts);
        return;
      }
      this.$bus.open(() => {
        if (mode && mode === 'ALIPAY') {
          this.deleteAlipayGuideApply(opts);
        } else {
          this.handleRomoteRemove(opts);
        }
      });
    },
    // 删除企业码付款申请
    deleteAlipayGuideApply(ids) {
      this.$store.dispatch('plan/guide/delBorrowApplyByIds', {
        ids
      });
    },
    refreshDDApplyStatus(id) {
      this.$store.dispatch('plan/guide/dingdingStatus', id);
      // 重新请求数据
      this.fetchData();
    },
    handleApplyRevoke({
      id
    }) {
      this.$bus.open(() => {
        this.$store.dispatch('plan/guide/dingdingRevoke', {
          id
        });
      }, '确定要撤销吗？');
    },
    // 新增一条
    handlePlus() {
      if (!this.mainGuide) {
        this.$bus.tip({
          type: 'warning',
          message: '请先添加报账导游'
        });
        return;
      }
      const {
        guideId,
        guideName
      } = this.mainGuide;
      this.tableData.push({
        ...defaultState(),
        guideId,
        guideName,
        rowId: ++this.rowId
      });
    },
    // 选择导游
    selectGuide(row, val) {
      const {
        label
      } = val;
      row.guideName = label;
    },
    selectBorrowType(row, val) {
      row.borrowType = val ? val.label : '';
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          id,
          auditProgress,
          borrowType
        } = it;
        const item = {
          ...it,
          rowId: id,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          ap: auditProgress,
          auditProgress,
          type: borrowType
        };
        // this.$set(item, 'type', borrowType)
        this.rowId++;
        return item;
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.planId = this.currentPlanId;

        // 当角色为管理员时，需要单独处理审核状态
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit ? true : it.status;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          guideId,
          applyMoney
        } = row;
        if (!guideId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择导游！'
          });
          return false;
        }
        if (!applyMoney) {
          this.$bus.tip({
            type: 'warning',
            message: '请输入借款金额！'
          });
          return false;
        }
      }
      return true;
    },
    onInvite() {
      const {
        id: guideId
      } = this.getReimbGuide;
      this.$store.dispatch('enterprise/guide/addInvite', {
        guideId
      }).then(res => {
        if (!res) return;
        const {
          signUrl
        } = res.data;
        this.$refs.dialogCopyRef.show(signUrl);
      });
    }
  }
};