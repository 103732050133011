import { inputMixin } from '@/mixin';

/**
 * @module components/common/Input
 * @desc 导游类型
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  created() {
    this.guideTypes = this.loadAll();
  },
  methods: {
    loadAll() {
      return [{
        value: '三全鲜食（北新泾店）',
        address: '长宁区新渔路144号'
      }, {
        value: 'Hot honey 首尔炸鸡（仙霞路）',
        address: '上海市长宁区淞虹路661号'
      }, {
        value: '新旺角茶餐厅',
        address: '上海市普陀区真北路988号创邑金沙谷6号楼113'
      }, {
        value: '泷千家(天山西路店)',
        address: '天山西路438号'
      }, {
        value: '胖仙女纸杯蛋糕（上海凌空店）',
        address: '上海市长宁区金钟路968号1幢18号楼一层商铺18-101'
      }];
    }
  }
};