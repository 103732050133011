var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "hide-bar": true,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.endPlanDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.startPlanDate) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };