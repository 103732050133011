var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", [_c("h3", [_vm._v("年度人数目标（人）")]), _c("TableBase", {
    attrs: {
      data: _vm.numberOfPeopleData,
      border: "",
      "column-data": _vm.columnData
    }
  })], 1), _c("div", [_c("h3", [_vm._v("年度营业额目标（元）")]), _c("TableBase", {
    attrs: {
      data: _vm.turnoverData,
      border: "",
      "column-data": _vm.columnData
    }
  })], 1), _c("div", [_c("h3", [_vm._v("年度毛利目标（元）")]), _c("TableBase", {
    attrs: {
      data: _vm.profitData,
      border: "",
      "column-data": _vm.columnData
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };