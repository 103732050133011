var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TopBar", {
    attrs: {
      title: "导游代收",
      disabled: _vm.isBtnDisable,
      "has-sign": true,
      "has-log": false
    },
    on: {
      "on-add": _vm.handleAdaptPlus
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("AuditStatus", {
          attrs: {
            row: scope.row
          }
        }), _c("CheckStatus", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [scope.row.members && scope.row.members.length ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 1
          }
        })] : _vm._e()];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " 大 " + _vm._s(scope.row.childCount) + " 小 " + _vm._s(scope.row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "money",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.total) + " ")];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getRemark(scope.row.type)) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remarks) + " ")];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "paymentCollectionTime",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            type: "datetime",
            "value-format": "yyyy-MM-dd HH:mm:ss",
            "default-time": "10:00:00"
          },
          model: {
            value: scope.row.paymentCollectionTime,
            callback: function ($$v) {
              _vm.$set(scope.row, "paymentCollectionTime", $$v);
            },
            expression: "scope.row.paymentCollectionTime"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("CheckBox", {
          attrs: {
            item: scope
          },
          on: {
            "on-check": _vm.handleCheckout,
            "on-cancel": _vm.handleCancel,
            "on-log": function ($event) {
              return _vm.handleShowLog(scope.row);
            }
          }
        })];
      }
    }, {
      key: "billCheck",
      fn: function () {
        return [_c("el-checkbox", {
          on: {
            change: _vm.changeCheckAll
          },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v;
            },
            expression: "checkAll"
          }
        }), _c("span", {
          staticClass: "pl5"
        }), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.changeCheckAll(!_vm.checkAll);
            }
          }
        }, [_vm._v(" 全选审核 ")])];
      },
      proxy: true
    }, {
      key: "actionItem",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          staticStyle: {
            color: "#f56c6c"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _c("DialogProxy", {
    ref: "proxyRef"
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };