/* eslint-disable no-unused-vars */
import { findPlanArranges, personalTransfer, personalInGroup, fetchPersonal } from '@/api/order';
import { formatShiftDateTime } from '@/utils/data';
import { formatItinerarys } from '@/views/order/utils/index';
const dfaultState = () => ({
  activeName: 'unGroup',
  inGroupData: null,
  unGroupData: null,
  editForm: null,
  cloneForm: null
});
const state = dfaultState();
const getters = {
  inGroupData: state => state.inGroupData,
  unGroupData: state => state.unGroupData,
  editForm: state => state.editForm,
  cloneForm: state => state.cloneForm
};
const formatOrder = (list, type) => {
  return list.map(it => {
    it.itinerarys = formatItinerarys(it.itinerarys, type);
    it.shuttles.map(sub => {
      sub.shiftDateTime = formatShiftDateTime(sub.shiftDateTime);
      return sub;
    });
    return it;
  });
};
const actions = {
  // 查询计划信息
  findPlan({
    commit
  }, planId) {
    return findPlanArranges(planId).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 散客转移
  personalTransfer({
    commit
  }, opts) {
    return personalTransfer(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 散客拼团
  personalInGroup({
    commit
  }, opts) {
    return personalInGroup(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单列表
  fetch({
    commit
  }, {
    type,
    queryParam
  }) {
    return fetchPersonal(queryParam).then(res => {
      const {
        data
      } = res;
      if (type == 'unGroup') {
        data.list = formatOrder(data.list);
        commit('UNGROUP_DATA', data);
      } else {
        data.list = data.list.map(it => {
          it.expand = true;
          it.orders = formatOrder(it.orders, 'hotel');
          return it;
        });
        commit('INGROUP_DATA', data);
      }
      return data;
    });
  }
};
const mutations = {
  INGROUP_DATA: (state, data) => {
    state.inGroupData = data;
  },
  UNGROUP_DATA: (state, data) => {
    state.unGroupData = data;
  },
  EDIT_FORM: (state, data) => {
    state.editForm = data;
  },
  CLONE_FORM: (state, data) => {
    state.cloneForm = data;
  },
  PERSONAL_TAB_ACTIVE: (state, activeName) => {
    state.activeName = activeName;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};