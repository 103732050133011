var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.sealImageUrl ? _c("img", {
    style: _vm.sealImageStyle,
    attrs: {
      src: _vm.sealImageUrl
    }
  }) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };