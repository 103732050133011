import "core-js/modules/es.array.push.js";
import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
import { processMinus, processAdd } from '@/utils/number';
import { cashFeeTypeLabel, cashLogType } from '@/config/billDown';
import { DialogLog } from '@/components/common';
import { DialogCard, DialogAccount, DialogCardBetween, DialogDetail, DialogAlipayRenew } from './';
export default {
  components: {
    SwitchStatus,
    DialogLog,
    DialogCard,
    DialogAccount,
    DialogCardBetween,
    DialogDetail,
    DialogAlipayRenew
  },
  mixins: [tableMixin],
  data: () => ({
    size: 'medium'
  }),
  computed: {
    getSettlement() {
      return row => processAdd(processMinus(row.totalReceipts, row.totalPay), row.initBlance);
    }
  },
  methods: {
    handleCommand(scope, command) {
      this[command](scope);
    },
    handleReload() {
      this.$emit('reload');
    },
    handleDetail(scope) {
      const {
        id: bankId
      } = scope.row;
      this.$parent.showDetailDialog(bankId);
    },
    formatLog(list) {
      return list.map(it => {
        const {
          cash,
          cashDate,
          cashType,
          resourceName,
          bankName,
          bankNo,
          cashAction,
          cashWay,
          subjectName,
          cashUserName,
          createTime
        } = it;
        const cashTypeLabel = cashLogType[cashType] || '';
        const cashFeeType = cashFeeTypeLabel[cashAction] || '';
        const optContent = `${cashDate} ${bankName} ${bankNo}卡 ${cashFeeType} ${cash}元，类型：${cashTypeLabel} 付款方式：${cashWay} 客户：${resourceName} 会计科目为：${subjectName}`;
        const optUserName = cashUserName;
        const optTime = createTime;
        return {
          optUserName,
          optContent,
          optTime
        };
      });
    },
    changeStatus(row) {
      this.$store.dispatch('resource/account/createOrUpdate', row);
    },
    handleAlipay({
      row
    }) {
      this.$refs.dialogAlipayRef.show(row);
    },
    handleAdjustment(scope) {
      this.$refs.dialogCardRef.show(scope);
    },
    handleTransfor(scope) {
      this.$refs.dialogBetweenRef.show(scope);
    },
    showPreview() {
      this.$router.push({
        path: '/resource/account-detail'
      });
    },
    showDetail({
      row
    }) {
      const {
        id: bankId
      } = row;
      this.$refs.dialogDetailRef.show(bankId);
    },
    showEdit({
      row
    }) {
      this.$refs.dialogAccountRef.edit(row);
    },
    handleDelete(scope) {
      this.$bus.open(() => {
        const resourceId = scope.row.id;
        const loading = this.$bus.loading();
        this.$store.dispatch('resource/account/delete', resourceId).then(() => {
          this.$emit('reload');
          this.$bus.tip();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      });
    },
    showLog({
      row
    }) {
      const {
        id: resourceId
      } = row;
      this.$store.dispatch('resource/account/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};