/* eslint-disable no-unused-vars */
// 部门
import { fetchList, fetchDetail, createOrUpdate, createOrUpdateChild, createOrUpdateGrandChild, deleteItem, deleteChildItem, deleteGrandItem } from '@/api/system/department';
const dfaultState = () => ({
  departmentData: null
});
const state = dfaultState();
const getters = {
  departmentData: state => state.departmentData
};
const actions = {
  fetch({
    commit,
    rootGetters
  }, id) {
    id = id || rootGetters['user/userData'].travelAgencyId;
    return fetchList(id).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA', data);
      return data;
    });
  },
  create({
    dispatch
  }, opts) {
    return createOrUpdate(opts).then(() => {
      return dispatch('fetch');
    });
  },
  createChild({
    dispatch
  }, opts) {
    return createOrUpdateChild(opts).then(() => {
      return dispatch('fetch');
    });
  },
  createGrandChild({
    dispatch
  }, opts) {
    return createOrUpdateGrandChild(opts).then(() => {
      return dispatch('fetch');
    });
  },
  fetchDetail({
    commit
  }, name) {
    return fetchDetail(name).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除一级部门
  delete({
    commit
  }, id) {
    return deleteItem(id);
  },
  // 删除二级部门

  deleteChildItem({
    commit
  }, id) {
    return deleteChildItem(id);
  },
  // 删除三级部门

  deleteGrandItem({
    commit
  }, id) {
    return deleteGrandItem(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.departmentData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};