// 按单位 交通
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  orderOther,
  payed,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '交通'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'transportCompanyName',
    label: '大交通'
  }
}, {
  attrs: {
    prop: 'transportType',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'destinationPosition',
    label: '目的地'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
}, {
  attrs: {
    prop: 'price',
    label: '价格'
  }
}, orderOther, {
  attrs: {
    prop: 'remarks',
    label: '安排备注'
  }
}, payed, owePay, downPayed, actionItem];