import { resourceOrderHotelInfo } from '@/config/resourceOrder';
import { DICTIONARY } from '@/config/dictionary';
import { getNextDate } from '@/utils';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: resourceOrderHotelInfo,
      tableData: [],
      RoomType: DICTIONARY.roomType
    };
  },
  computed: {},
  methods: {
    changeInDate(row) {
      const {
        inDate
      } = row;
      row.outDate = getNextDate(inDate);
    },
    changeOutDate(row) {
      const {
        outDate
      } = row;
      row.inDate = new Date(outDate);
      row.outDate = getNextDate(outDate);
    }
  }
};