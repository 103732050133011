import { SelectPlanList } from '@/components/common';
export default {
  components: {
    SelectPlanList
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        ...this.data
      }
    };
  },
  methods: {
    onSelectPlanNumber(item) {
      const opts = {
        ...this.form,
        ...item,
        planId: item.id
      };
      if (this.data.id) {
        this.form = {
          ...opts,
          id: this.data.id
        };
      } else {
        this.form = {
          ...opts,
          id: ''
        };
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            resolve(this.form);
          } else {
            reject();
          }
        });
      });
    }
  }
};