var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tag", _vm._b({
    attrs: {
      effect: "plain",
      type: _vm.orderAuditTagStatus[_vm.status]
    }
  }, "el-tag", _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.orderAuditStatus[_vm.status]) + " ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };