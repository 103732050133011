import FormItem from './FormItem.vue';
import { defaultState, rules } from '../config';
import { beforeCommit } from '../helper';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      rules,
      visible: false,
      form: defaultState()
    };
  },
  methods: {
    edit(row) {
      this.visible = true;
      const {
        shipTicket,
        startTripDate,
        endTripDate
      } = row;
      this.form = {
        ...defaultState,
        ...shipTicket,
        startTripDate,
        endTripDate
      };
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    handleSave() {
      this.$refs.formRef.validate().then(data => {
        if (!data) return;
        const opts = beforeCommit(data);
        this.$store.dispatch('plan/boatPersonal/add', opts).then(() => {
          this.$bus.tip();
          this.$emit('reload');
          this.hide();
        });
      });
    }
  }
};