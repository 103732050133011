import billDownMixin from '../billDownMixin';
import { summaryMixin, tableMixin } from '@/mixin';
import { isBackMoney, processMinus } from '@/utils';
import { CustomerInfo } from '@/components/common';
export default {
  components: {
    CustomerInfo
  },
  mixins: [tableMixin, summaryMixin, billDownMixin],
  data() {
    return {
      billCheckAll: false,
      statisticsKeys: ['totalPay', 'totalPayed', 'owePay'],
      data: []
    };
  },
  computed: {
    getOwePayMoney() {
      return row => {
        return processMinus(row.totalPay, row.totalPayed);
      };
    },
    autoDown() {
      return this.$store.getters['bill/guide/autoDown'];
    },
    billDetail() {
      return this.$store.getters['bill/guide/billDetail'];
    },
    getTableData() {
      const positive = this.filterData();
      const negetive = this.filterData(false);
      const res = [...positive, ...negetive];
      return res;
    }
  },
  watch: {
    autoDown: {
      handler: function (status) {
        status && this.handleAutoDown();
      }
    },
    tableData: {
      handler: function (data) {
        if (data && data.length) {
          this.calcMoney();
          const downMoney = this.billDetail ? this.billDetail.cash : 0;
          this.$store.commit('bill/guide/SET_DOWN_MONEY', downMoney);
        }
      }
    }
  },
  methods: {
    filterData(flag = true) {
      return this.tableData.filter(it => flag ? !isBackMoney(it.feeType) : isBackMoney(it.feeType));
    },
    calcMoney() {
      const totalPay = this.calcTotalMoney('totalPay');
      const totalPayed = this.calcTotalMoney('totalPayed');
      const downPayed = this.calcTotalMoney('downPayed');
      this.$store.commit('bill/guide/SET_STATISTICS', {
        totalPay,
        totalPayed,
        downPayed
      });
      return {
        totalPay,
        totalPayed,
        downPayed
      };
    },
    changeCheckAll(val) {
      const data = this.getTableData;

      // 自动填充下账数据
      this.data = data.map(it => {
        if (isBackMoney(it.feeType)) {
          it.downPayed = val ? it.owePayCopy : '';
        } else {
          it.downPayed = val ? it.owePayCopy : '';
        }
        // it.totalPayed = val ? it.owePayCopy : 0
        it.isChecked = val;
        this.billCheckAll = val;
        return it;
      });
      this.calcMoney();
    },
    checkAll() {
      this.billCheckAll = this.getTableData.every(it => it.isChecked);
    },
    // 勾选
    changeCheckbox(row, val) {
      row.downPayed = val ? isBackMoney(row.feeType) ? row.owePay : row.owePay : '';
      row.totalPayed = row.downPayed || 0;
      this.calcMoney();
      this.checkAll();
    },
    // 手动输入
    changeDownPay(row) {
      row.isChecked = !(row.downPayed == 0 || row.downPayed == '');
      // 如果金额为0，则检查全选状态
      !row.isChecked && this.checkAll();
      this.calcMoney();
    }
  }
};