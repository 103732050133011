const defaultState = () => ({
  freeCountMinRequire: 0,
  freeCount: 0
});
export default {
  name: 'InputReduction',
  props: {
    label: {
      type: String,
      default: '减免'
    },
    initVal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      listCount: 0,
      form: []
    };
  },
  computed: {
    getList() {
      return this.form.slice(1);
    }
  },
  watch: {
    initVal: {
      handler: function (val) {
        if (!val.length) {
          this.form = [defaultState()];
        } else {
          this.form = val;
          this.listCount = val.length;
        }
      },
      immediate: true
    }
  },
  methods: {
    addRow() {
      // this.listCount++
      // this.form.push(defaultState())
      this.$emit('on-add');
    },
    reduceRow(index) {
      this.$emit('on-delete', this.form[index + 1].id);
      this.listCount--;
      this.form.splice(index + 1, 1);
      this.$emit('on-change', this.form);
    },
    handleChange() {
      this.$emit('on-change', this.form);
    }
  }
};