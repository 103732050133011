import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { fetchAll, fetchFund, createOrUpdateFund } from '@/api/order/index';
import { monthFirstDay, monthLastDay } from '@/utils/date';
export default {
  mixins: [],
  data() {
    return {
      loading: false,
      planNumber: '',
      sendDate: [monthFirstDay, monthLastDay],
      columns: [{
        attrs: {
          prop: 'planNumber',
          label: '大团|小团号'
        }
      }, {
        attrs: {
          prop: 'startPlanDate',
          label: '发团日期'
        }
      }, {
        attrs: {
          prop: 'endPlanDate',
          label: '散团日期'
        }
      }, {
        attrs: {
          prop: 'money',
          label: '团款'
        },
        slot: 'money'
      }, {
        attrs: {
          prop: 'action',
          label: '操作'
        },
        slot: 'action'
      }],
      tableData: [],
      diffList: []
    };
  },
  methods: {
    fetchSingleProfitData(params) {
      return this.$store.dispatch('report/index/getTeamProfit', params);
    },
    fetchSmallProfitData(params) {
      return this.$store.dispatch('report/index/getTeamSmall', params);
    },
    onChangeDate(val) {
      this.sendDate = val;
    },
    processDiff(res1, res2) {
      const {
        list: list1
      } = res1;
      const {
        list: list2
      } = res2;
      const map = {};
      const data = [];
      const orderType = 'S_TOURIST';
      const mergeList = list => {
        const mapObj = {};
        // 1. 过滤散客数据
        const skList = list.filter(it => it.planType == orderType);
        // 团队数据
        const teamList = list.filter(it => it.planType != orderType);

        // 合并散客团款
        skList.forEach(it => {
          const {
            planNumber,
            totalCustomerIncome
          } = it;
          if (!mapObj[planNumber]) {
            mapObj[planNumber] = Number(totalCustomerIncome);
          } else {
            mapObj[planNumber] += Number(totalCustomerIncome);
          }
        });
        const mergeObj = skList.reduce((acc, cur) => {
          if (!acc[cur.planNumber]) {
            const money = mapObj[cur.planNumber];
            cur.totalCustomerIncome = money;
            acc[cur.planNumber] = cur;
          }
          return acc;
        }, {});
        return [...teamList, ...Object.keys(mergeObj).map(key => mergeObj[key])];
      };
      const newList = [...mergeList(list1), ...mergeList(list2)];
      newList.forEach(it => {
        const {
          id,
          planNumber,
          customerTripNumber,
          planType,
          startPlanDate,
          endPlanDate,
          totalCustomerIncome
        } = it;
        const key = planNumber || customerTripNumber;
        if (!map[key]) {
          map[key] = {
            planNumber,
            id,
            planType,
            money1: totalCustomerIncome,
            startPlanDate,
            endPlanDate
          };
        } else {
          map[key] = {
            ...map[key],
            money2: totalCustomerIncome,
            update: map[key].money1 != totalCustomerIncome
          };
        }
      });
      Object.keys(map).map(key => {
        data.push(map[key]);
      });
      this.tableData = data;
      this.diffList = data.filter(it => it.update);
      this.loading = false;
    },
    async handleFix(row) {
      const {
        id,
        planNumber
      } = row;
      const params = {
        planCustomerNumber: planNumber,
        pageSize: 10000
      };
      let ids = [];
      this.loading = true;
      this.tableData = [];

      /**
       * 1. 用团号去所有订单查数据
       * 2. 处理数据，获取订单id
       * 3. 根据id获取订单团款
       * 4. 重新保存更新团款数据
       */
      const res = await fetchAll(params);
      if (res && res.data) {
        ids = [id, ...res.data.list.map(it => it.id)];
      }

      // 获取团款
      const pAll = ids.map(id => {
        return fetchFund({
          id
        });
      });
      const resAll = await Promise.all(pAll);
      const fundAll = resAll.map(it => it.data).reduce((acc, cur) => acc = [...acc, ...cur], []);
      if (!fundAll.length) return;
      // 保存团款
      await createOrUpdateFund(fundAll);
    },
    async handleBatchFix() {
      if (this.diffList.length) return;
      const fixFn = async row => await this.handleFix(row);
      const pFixAll = this.diffList.map(fixFn);
      Promise.all(pFixAll).then(() => {
        console.log('fixed done');
      });
    },
    async handleSearch() {
      if (!this.sendDate.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择日期'
        });
        return;
      }
      const [startTripDateS, startTripDateE] = this.sendDate;
      const params = {
        startTripDateS,
        startTripDateE,
        planNumber: this.planNumber,
        pageSize: 10000
      };
      const res1 = await this.fetchSingleProfitData(params);
      const res2 = await this.fetchSmallProfitData(params);
      this.processDiff(res1, res2);
    }
  }
};