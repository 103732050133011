import { SelectRole } from '@/components/common';
import { getUserData } from '@/utils';
export default {
  components: {
    SelectRole
  },
  data() {
    return {
      visible: false,
      data: {}
    };
  },
  methods: {
    show(row) {
      this.data = row;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      const {
        roleId,
        id: userId
      } = this.data;
      const {
        travelAgencyId
      } = getUserData();
      if (!roleId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择角色'
        });
        return;
      }
      this.$store.dispatch('user/setGroupRoleInSaas', {
        roleId,
        userId,
        travelAgencyId
      }).then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};