export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visibleMore: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('update:visible');
      }
    }
  },
  methods: {
    toggle() {
      this.$emit('on-click');
    }
  }
};