export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleAdd() {
      this.$emit('on-add');
    },
    handleSave() {
      this.$emit('on-save');
    }
  }
};