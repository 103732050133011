import CustomerInfo from './CustomerInfo.vue';
export default {
  components: {
    CustomerInfo
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  }
};