import "core-js/modules/es.array.push.js";
import TableUnPay from './components/TableUnPay';
import TablePayed from './components/TablePayed';
import { formatCommitTableData } from '@/utils/data';
import { FooterBar, SelectGroup, BillInput } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { getPayedMoney } from '@/views/billDown/help';
import { TYPES } from '@/config/billDown';
export default {
  components: {
    FooterBar,
    SelectGroup,
    BillInput,
    TableUnPay,
    TablePayed
  },
  mixins: [tagViewMixin],
  data() {
    return {
      TYPES,
      exportLoading: false,
      total: 0,
      resourceId: '',
      backPath: '/bill/group'
    };
  },
  computed: {
    ...mapGetters({
      payedData: 'group/payedData'
    }),
    payedList() {
      return this.payedData ? this.payedData.list : [];
    }
  },
  mounted() {
    const {
      rid
    } = this.$route.query;
    this.resourceId = rid;
  },
  methods: {
    changeMoney(money) {
      this.money = money - getPayedMoney(this.payedList);
      this.$refs.unPayRef.caclDownMoney(this.money);
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    reload() {
      this.$refs.unPayRef.fetchData();
    },
    processData(form) {
      const {
        cashWay,
        cashUserId,
        money
      } = form;
      const tableData = this.$refs.unPayRef.tableData;
      const list = tableData.filter(it => it.isChecked);
      const cashs = list.map(it => {
        return {
          cash: it.downPayed,
          cashWay,
          cashUserId,
          orderId: it.rowId,
          type: it.type ? it.type : 'NONE',
          kpResType: it.resType && it.resType.toUpperCase(),
          resId: it.resId
        };
      });
      let opts = {
        ...form,
        money,
        cashType: TYPES.company,
        cashes: formatCommitTableData(cashs),
        resourceId: this.resourceId
      };
      return opts;
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const loading = this.$bus.loading();
        const opts = this.processData(form);
        this.$store.dispatch('group/updatePayed', opts).then(() => {
          loading.close();
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};