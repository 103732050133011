import "core-js/modules/es.array.push.js";
import 'echarts/map/js/china';
import { parseTime } from '@/utils';
import echarts from 'echarts';
const year = parseTime(new Date(), '{y}');
let years = [];
for (let i = 0; i < 5; i++) {
  const y = year - i;
  years.push(y);
}
export default {
  data() {
    return {
      searchParams: {
        year,
        memberStaticType: 'province',
        province: ''
      },
      geoJson: {},
      parentInfo: [{
        cityName: '全国',
        code: 100000
      }],
      currentIndex: 0,
      mapJson: [],
      city: [],
      timeTitle: years,
      isToBack: false,
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      isVisible: true,
      timer: false
    };
  },
  watch: {
    parentInfo: {
      handler: function (data) {
        this.isToBack = false;
        if (data.length > 1) {
          this.isToBack = true;
        }
      },
      immediate: true
    },
    // 监听窗口宽度变化
    screenWidth: {
      handler: function (val) {
        if (!this.timer) {
          this.isVisible = false;
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val;
          this.timer = true;
          this.updateData();
        }
      },
      immediate: true
    },
    // 监听窗口宽度变化
    screenHeight: {
      handler: function (val) {
        if (!this.timer) {
          this.isVisible = false;
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenHeight = val;
          this.timer = true;
          this.updateData();
        }
      },
      immediate: true
    }
  },
  mounted() {
    // this.fetchData(this.searchParams)
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        window.screenHeight = document.body.clientHeight;
        this.screenHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    updateData() {
      const t = this;
      setTimeout(function () {
        // 打印screenWidth变化的值
        t.init(t.parentInfo[t.parentInfo.length - 1].code);
        t.timer = false;
      }, 400);
    },
    init(adcode) {
      const loading = this.$bus.loading();
      this.getGeoJson(adcode).then(data => {
        this.geoJson = data;
        this.isVisible = true;
        this.fetchData(this.searchParams);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    getGeoJson(adcode, childAdcode = '') {
      return new Promise((resolve, reject) => {
        function insideFun(adcode, childAdcode) {
          AMapUI.loadUI(['geo/DistrictExplorer'], DistrictExplorer => {
            var districtExplorer = new DistrictExplorer();
            districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
              if (error) {
                reject(error);
                return;
              }
              let Json = areaNode.getSubFeatures();
              if (Json.length === 0) {
                let parent = areaNode._data.geoData.parent.properties.acroutes;
                insideFun(parent[parent.length - 1], adcode);
                return;
              }
              if (childAdcode) {
                Json = Json.filter(item => {
                  return item.properties.adcode == childAdcode;
                });
              }
              let mapJson = {
                features: Json
              };
              resolve(mapJson);
            });
          });
        }
        insideFun(adcode, childAdcode);
      });
    },
    fetchData(queryParams) {
      this.memberTotal = 0;
      const mapData = [];
      const names = [];
      this.$store.dispatch('analyse/index/fetchMemberMap', queryParams).then(res => {
        let other = [];
        res.forEach(it => {
          this.memberTotal += it.count;
          if (it.name == '其他') {
            const em = {
              name: it.name,
              value: it.count,
              center: ['', ''],
              cityCode: '000000'
            };
            other.push(em);
          }
          names.push(it.name);
        });
        const list = this.geoJson.features.filter(it => !names.includes(it.properties.name));
        const isProvince = list.map(it => {
          const {
            name,
            adcode: cityCode,
            center,
            level
          } = it.properties;
          return {
            value: 0,
            cityCode,
            name,
            center,
            level
          };
        });
        this.geoJson.features.forEach(it => {
          const {
            name,
            adcode: cityCode,
            center,
            level
          } = it.properties;
          res.forEach(v => {
            if (v.name == name) {
              const item = {
                name,
                value: v.count,
                center,
                cityCode,
                level
              };
              mapData.push(item);
            }
          });
        });
        this.mapData = [...mapData, ...isProvince, ...other].sort(function (a, b) {
          return b.value - a.value;
        });
        const pointData = this.mapData.map(it => {
          const {
            center,
            value
          } = it;
          const item = {
            ...it,
            value: [...center, value]
          };
          return item;
        });
        this.initEchartMap(this.mapData, this.memberTotal, pointData);
      });
    },
    //渲染echarts
    initEchartMap(mapData, sum, pointData) {
      let myChart = echarts.init(document.getElementById('myChart'));
      var xData = [],
        yData = [];
      var min = mapData[mapData.length - 1].value;
      let max = mapData[0].value;
      if (mapData[0].name == '其他') {
        max = mapData[1].value;
      }
      if (mapData.length === 1) {
        min = 0;
      }
      mapData.forEach(c => {
        xData.unshift(c.name.replace(/(省|市|自治区|回族|维吾尔|自治州|壮族|特别行政区)/g, ''));
        yData.unshift(c.value);
      });
      //这里做个切换，全国的时候才显示南海诸岛  只有当注册的名字为china的时候才会显示南海诸岛
      echarts.registerMap(this.parentInfo.length === 1 ? 'china' : 'map', this.geoJson);
      var option = {
        // 正下方时间自动轮播
        timeline: {
          data: this.timeTitle,
          axisType: 'category',
          autoPlay: false,
          playInterval: 5000,
          left: '10%',
          right: '10%',
          bottom: '2%',
          width: '80%',
          label: {
            normal: {
              textStyle: {
                color: 'rgb(179, 239, 255)'
              }
            },
            emphasis: {
              textStyle: {
                color: '#fff'
              }
            }
          },
          currentIndex: this.currentIndex,
          symbolSize: 10,
          lineStyle: {
            color: '#8df4f4'
          },
          checkpointStyle: {
            borderColor: '#8df4f4',
            color: '#53D9FF',
            borderWidth: 2
          },
          controlStyle: {
            showNextBtn: true,
            showPrevBtn: true,
            normal: {
              color: '#53D9FF',
              borderColor: '#53D9FF'
            },
            emphasis: {
              color: 'rgb(58,115,192)',
              borderColor: 'rgb(58,115,192)'
            }
          }
        },
        baseOption: {
          // backgroundColor: '#012248',
          title: [{
            left: 'center',
            top: 20,
            text: `${this.searchParams.year}${this.parentInfo[this.parentInfo.length - 1].cityName}游客人数分布图(可点击到县)`,
            textStyle: {
              color: '#b3efff',
              fontSize: 16
            }
          }, {
            text: '游客人数：' + sum + '人',
            left: 'center',
            top: 45,
            textStyle: {
              color: '#FFAC50',
              fontSize: 20
            }
          }],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: [{
            show: false,
            right: '24%',
            top: '12%',
            bottom: '8%',
            containLabel: true,
            width: '15%'
          }, {
            show: false,
            right: '21.5%',
            //调整中间文字位置
            top: '14%',
            //使中间文字对齐
            bottom: '8%',
            width: '0%'
          }, {
            show: false,
            right: '5%',
            top: '12%',
            bottom: '8%',
            containLabel: true,
            width: '15%'
          }],
          toolbox: {
            feature: {
              restore: {
                show: false
              },
              dataView: {
                show: false
              },
              saveAsImage: {
                name: this.parentInfo[this.parentInfo.length - 1].cityName + '游客分布图'
              },
              dataZoom: {
                show: false
              },
              magicType: {
                show: false
              }
            },
            iconStyle: {
              normal: {
                borderColor: '#1990DA' //右上角下载的颜色
              }
            },
            top: 15,
            right: 35
          },
          geo: {
            map: this.parentInfo.length === 1 ? 'china' : 'map',
            zoom: this.parentInfo.length === 1 ? 1.1 : 0.9,
            roam: true,
            left: '10%',
            top: '15%',
            tooltip: {
              trigger: 'item',
              formatter: p => {
                let val = p.value;
                if (window.isNaN(val)) {
                  val = 0;
                }
                let txtCon = `<div>${p.name}:<br />${val}人</div>`;
                return txtCon;
              }
            },
            label: {
              normal: {
                show: true,
                color: '#f9f9f9',
                //省份标签字体颜色
                formatter: p => {
                  switch (p.name) {
                    case '内蒙古自治区':
                      p.name = '内蒙古';
                      break;
                    case '西藏自治区':
                      p.name = '西藏';
                      break;
                    case '新疆维吾尔自治区':
                      p.name = '新疆';
                      break;
                    case '宁夏回族自治区':
                      p.name = '宁夏';
                      break;
                    case '广西壮族自治区':
                      p.name = '广西';
                      break;
                    case '香港特别行政区':
                      p.name = '香港';
                      break;
                    case '澳门特别行政区':
                      p.name = '澳门';
                      break;
                  }
                  return p.name;
                }
              },
              emphasis: {
                show: true,
                color: '#f75a00'
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#24CFF4',
                borderColor: '#53D9FF',
                borderWidth: 1.3,
                shadowBlur: 15,
                shadowColor: '#3a73c0',
                shadowOffsetX: 7,
                shadowOffsetY: 6
              },
              emphasis: {
                areaColor: '#8dd7fc',
                borderWidth: 1.6,
                shadowBlur: 25
              }
            }
          },
          visualMap: {
            //右下角
            min: min,
            max: max,
            left: '3%',
            bottom: '5%',
            calculable: true,
            seriesIndex: [0],
            inRange: {
              color: ['#24CFF4', '#2E98CA', '#1E62AC']
            },
            textStyle: {
              color: '#24CFF4'
            }
          },
          xAxis: [{
            type: 'value',
            inverse: true,
            //是否是反向坐标轴。
            scale: true,
            position: 'top',
            boundaryGap: false,
            show: false
          }, {
            gridIndex: 1,
            show: false
          }, {
            gridIndex: 2,
            type: 'value',
            inverse: false,
            //是否是反向坐标轴。
            scale: true,
            position: 'top',
            boundaryGap: false,
            splitLine: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#455B77'
              }
            },
            axisTick: {
              show: true
            },
            axisLabel: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.2)'
              },
              margin: 2,
              textStyle: {
                color: '#c0e6f9'
              }
            }
          }],
          yAxis: [{
            type: 'category',
            inverse: false,
            position: 'right',
            nameGap: 16,
            show: false
          }, {
            gridIndex: 1,
            type: 'category',
            inverse: false,
            position: 'left',
            nameGap: 16,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#c0e6f9',
                fontSize: 12,
                align: 'center'
              }
            },
            data: xData
          }, {
            gridIndex: 2,
            type: 'category',
            inverse: false,
            position: 'left',
            nameGap: 16,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#455B77'
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            data: xData
          }],
          series: [{
            //最外层鼠标经过显示的黑框
            name: this.timeTitle[this.currentIndex] + '游客分布图',
            type: 'map',
            geoIndex: 0,
            map: this.parentInfo.length === 1 ? 'china' : 'map',
            roam: true,
            zoom: 1.1,
            left: '20%',
            tooltip: {
              trigger: 'item',
              formatter: p => {
                let val = p.value;
                if (p.name == '南海诸岛') return;
                if (window.isNaN(val)) {
                  val = 0;
                }
                let txtCon = `<div>${p.name}:<br />${val}人</div>`;
                return txtCon;
              }
            },
            label: {
              normal: {
                formatter: function () {
                  return '4';
                },
                show: true,
                position: 'right',
                distance: 5,
                color: 'white',
                backgroundColor: '#1D3039',
                padding: 10,
                borderRadius: 20
                // show: false,
              },
              emphasis: {
                show: false
              }
            },
            data: mapData
          }, {
            name: '散点',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'fill'
            },
            itemStyle: {
              normal: {
                color: '#F4E925',
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            tooltip: {
              trigger: 'item',
              formatter: p => {
                let val = p.value[2];
                if (p.name == '南海诸岛') return;
                if (window.isNaN(val)) {
                  val = 0;
                }
                let txtCon = `<div>${p.name}:<br />${val}人</div>`;
                return txtCon;
              }
            },
            data: pointData,
            symbolSize: function (val) {
              let value = val[2];
              if (value == max) {
                return 27;
              }
              return 10;
            },
            showEffectOn: 'render' //加载完毕显示特效
          },
          //
          {
            name: '人数',
            type: 'bar',
            barGap: '-100%',
            barCategoryGap: '60%',
            stack: 'right',
            xAxisIndex: 2,
            yAxisIndex: 2,
            label: {
              show: true,
              fontSize: 10,
              distance: 10,
              color: '#fff',
              position: 'right',
              //inside|right
              formatter: params => {
                return params.value + '人';
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
                  offset: 0,
                  color: '#395CFE'
                }, {
                  offset: 1,
                  color: '#2EC7CF'
                }])
              },
              emphasis: {
                show: false
              }
            },
            data: yData
          }]
        }
      };
      myChart.setOption(option, true);
      //点击前解绑，防止点击事件触发多次
      myChart.off('click');
      myChart.on('click', this.echartsMapClick);
      //监听时间切换事件
      myChart.off('timelinechanged');
      myChart.on('timelinechanged', params => {
        this.currentIndex = params.currentIndex;
        // this.getMapData()
      });
    },
    echartsMapClick(params) {
      const {
        componentType
      } = params;
      // 判断是不是触发底部横向年份轴
      if (componentType == 'timeline') {
        this.searchParams.year = params.name;
        this.init(this.parentInfo[this.parentInfo.length - 1].code);
        return;
      }
      if (!params.data) {
        return;
      } else {
        const {
          level,
          name
        } = params.data;
        // 判断省市区请求
        if (level == 'province') {
          this.searchParams.memberStaticType = 'city';
          this.searchParams.province = name;
        } else if (level == 'city') {
          this.searchParams.memberStaticType = 'district';
          this.searchParams.city = name;
        } else {
          this.searchParams.memberStaticType = 'province';
          this.searchParams.province = '';
        }
        //如果当前是最后一级，那就直接return
        if (this.parentInfo[this.parentInfo.length - 1].code == params.data.cityCode) {
          return;
        }
        let data = params.data;
        this.parentInfo.push({
          cityName: data.name,
          code: data.cityCode
        });
        this.init(data.cityCode);
      }
    },
    goBack() {
      if (this.parentInfo.length === 1) {
        return;
      }
      this.parentInfo.pop();
      const parentInfo = this.parentInfo[this.parentInfo.length - 1];
      if (this.parentInfo.length == 1) {
        this.searchParams.memberStaticType = 'province';
      }
      if (this.parentInfo.length == 2) {
        this.searchParams.memberStaticType = 'city';
        this.searchParams.province = parentInfo.cityName;
      }
      if (this.parentInfo.length == 3) {
        this.searchParams.memberStaticType = 'district';
        this.searchParams.city = parentInfo.cityName;
      }
      this.init(parentInfo.code);
    },
    //echarts点击事件

    onChangeDate(val) {
      const [start, end] = val;
      this.searchParams.startMonth = start;
      this.searchParams.endMonth = end;
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    exportExcel() {}
  }
};