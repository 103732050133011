export * from '../helper';

// 销售数据大屏饼状图配置
export const setRingOption = ({
  name,
  data,
  radius = ['40%', '70%'],
  color = ['#37A2DA', '#e91e63']
}) => ({
  tooltip: {
    trigger: 'item',
    formatter: params => {
      const {
        value,
        count,
        label
      } = params.data;
      return `${params.seriesName} <br /> ${label}完成 ${count}, ${label}完成率 ${value}%`;
    }
  },
  legend: {
    top: '2%',
    left: 'center',
    textStyle: {
      color: 'rgb(199, 188, 188)'
    }
  },
  series: [{
    name,
    radius,
    data,
    color,
    type: 'pie',
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    emphasis: {
      label: {
        show: false,
        fontSize: 40,
        fontWeight: 'bold'
      }
    },
    labelLine: {
      show: false
    }
  }]
});