var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      "summary-method": _vm.getSummaries,
      "cell-class-name": _vm.changeCellClassName,
      "show-summary": _vm.summaryStatus,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          domProps: {
            innerHTML: _vm._s(scope.row.type)
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          domProps: {
            innerHTML: _vm._s(scope.row.price)
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          domProps: {
            innerHTML: _vm._s(scope.row.remarks)
          }
        })];
      }
    }, {
      key: "total",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          domProps: {
            innerHTML: _vm._s(scope.row.total)
          }
        })];
      }
    }, {
      key: "proxy",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.proxy == "isGuideCollect" ? "导游代收" : "本社代收") + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };