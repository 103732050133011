var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "guide-helper"
  }, [_c("div", {
    staticClass: "form-wrapper"
  }, [_c("el-tabs", [_c("el-tab-pane", {
    attrs: {
      label: "重要公告"
    }
  }, [_c("input-remark", {
    attrs: {
      label: "",
      "min-rows": 6,
      "max-rows": 10
    },
    model: {
      value: _vm.form.guideImportantInfo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideImportantInfo", $$v);
      },
      expression: "form.guideImportantInfo"
    }
  })], 1)], 1), _c("div", {
    staticClass: "mt20"
  }), _c("el-tabs", [_c("el-tab-pane", {
    attrs: {
      label: "常用信息"
    }
  }, [_c("input-remark", {
    attrs: {
      label: "",
      "min-rows": 6,
      "max-rows": 10
    },
    model: {
      value: _vm.form.guideCommonInfo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideCommonInfo", $$v);
      },
      expression: "form.guideCommonInfo"
    }
  })], 1)], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存更新 ")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };