/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchScenic, fetchBindScenic, createOrUpdateScenic, deleteScenic, fetchScenicGroup } from '@/api/resource/scenic';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { TYPES, DICTYPES } from '@/config/resource';
import { formatCharData, formatDicData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  scenicData: null,
  scenicFreeData: null,
  scenicGroupData: null,
  scenicDicData: null,
  scenicDataOrigin: null,
  scenicLog: null,
  scenicAgreement: null,
  scenicDayList: null
});
const state = dfaultState();
const getters = {
  scenicData: state => state.scenicData,
  scenicFreeData: state => {
    return state.scenicData && state.scenicData.filter(it => it.scenicType == 'FREE');
  },
  scenicGroupData: state => state.scenicGroupData,
  scenicDicData: state => state.scenicDicData,
  scenicDataOrigin: state => state.scenicDataOrigin,
  scenicLog: state => state.scenicLog,
  scenicAgreement: state => state.scenicAgreement,
  scenicDayList: state => state.scenicDayList
};
const actions = {
  fetch({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchScenic(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      // data = data.filter(it => it.isEnable)
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      // commit('FETCH_FREE_DATA', data)
      return data;
    });
  },
  // 不分页列表(绑定集团)
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchScenicGroup(queryParam).then(res => {
      let {
        data
      } = res;
      data = data.filter(it => it.isEnable);
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.scenic
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.scenic
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchBindScenic({
    commit
  }, params) {
    return fetchBindScenic(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 查询旅监局景区字典
  getTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.scenic
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.scenicDicData ? state.scenicDicData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatDicData(list, 'scenic'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_SCENIC_DICDATA', data);
      return originData;
    });
  },
  // 查询旅监酒店字典名字搜搜
  searchTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.scenic
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      data.list = formatDicData(data.list, 'scenic');
      commit('FETCH_SCENIC_DICDATA', data);
      return data;
    });
  },
  fetchAgreement({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.scenic
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_AGREEMENT', data);
    });
  },
  addAgreementItem({
    dispatch
  }, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    dispatch
  }, opts) {
    return createOrUpdateScenic(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    return deleteScenic(id);
  },
  dayList({
    commit
  }, dayList) {
    commit('DAY_LIST', dayList);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.scenicData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.scenicGroupData = data;
  },
  FETCH_SCENIC_DICDATA: (state, data) => {
    state.scenicDicData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.scenicDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.scenicLog = data;
  },
  FETCH_AGREEMENT: (state, data) => {
    state.scenicAgreement = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.scenicData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.scenicData.list.filter(it => it.id !== id);
    state.scenicData.list = list;
  },
  DAY_LIST: (state, list) => {
    state.scenicDayList = list;
  },
  FETCH_BIND_SCENIC: (state, list) => {
    state.scenicBindList = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};