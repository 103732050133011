import { Scenic } from '@/components/common/Order';
import { planEditScenic, planEditScenicAll } from '@/config/plan';
import { permissionMixin, systemMixin } from '@/mixin';
export default {
  components: {
    Scenic
  },
  mixins: [permissionMixin, systemMixin],
  data() {
    return {
      columnData: [],
      rolePermissionList: []
    };
  },
  mounted() {
    setTimeout(() => {
      const {
        toPath
      } = this.$route.query;
      // 判断是否显示底价 (根据toPath区分发团预定安排和发团预定安排2)
      let scenicFloorPrice = toPath == '/plan/team-role-arrange' ? 'scenicFloorPrice2' : 'scenicFloorPrice';
      this.columnData = this.hasPermission(scenicFloorPrice) ? planEditScenicAll : planEditScenic;
      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      // 是否显示订单信息
      if (!isOrderInfo) {
        this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
      }
    }, 100);
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};