import { planEditReceptionProxy } from '@/config/plan';
import PersonCount from '@/components/common/PersonCount.vue';
import { rowspanMixin } from '@/mixin';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  components: {
    PersonCount
  },
  mixins: [rowspanMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isAudit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columnData: [],
      headMergeNum: 3,
      hasMergeTail: false
    };
  },
  watch: {
    tableData: {
      handler: function () {
        this.$nextTick(() => {
          this.rowspan();
        });
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planEditReceptionProxy : filterCustomColumn(planEditReceptionProxy);
  }
};