import FooterBar from './FooterBar';
import DetailBaseInfo from './DetailBaseInfo';
import { DetailFund, DetailProxy, DetailRefund, DetailTourist, DetailRemarks, DetailPickup, DetailInsurance, DetailItinerary, DetailReception, DetailOtherIncome, DetailOtherPay, DetailHotel, DetailScenic, DetailTraffic, DetailRestaurant } from './Table/index';

// import DetailFund from '../Table/DetailFund'
// import DetailProxy from '../Table/DetailProxy'
// import DetailRefund from '../Table/DetailRefund'
// import DetailTourist from '../Table/DetailTourist'
// import DetailRemarks from '../Table/DetailRemarks'
// import DetailPickup from '../Table/DetailPickup'
// import DetailInsurance from '../Table/DetailInsurance'
// import DetailItinerary from '../Table/DetailItinerary'
// import DetailReception from '../Table/DetailReception'
// import DetailOtherIncome from '../Table/DetailOtherIncome'
// import DetailOtherPay from '../Table/DetailOtherPay'
// import DetailHotel from '../Table/DetailHotel'
// import DetailScenic from '../Table/DetailScenic'
// import DetailTraffic from '../Table/DetailTraffic'
// import DetailRestaurant from '../Table/DetailRestaurant'

export default {
  components: {
    FooterBar,
    DetailBaseInfo,
    DetailFund,
    DetailProxy,
    DetailRefund,
    DetailTourist,
    DetailRemarks,
    DetailPickup,
    DetailInsurance,
    DetailItinerary,
    DetailReception,
    DetailOtherIncome,
    DetailOtherPay,
    DetailHotel,
    DetailScenic,
    DetailTraffic,
    DetailRestaurant
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    id: '',
    isReview: '',
    remarkList: []
  }),
  computed: {
    orderAuditTag() {
      const {
        type: orderType
      } = this.$route.query;
      if (orderType == 'audit' && this.type == 'auditOrder') {
        return true;
      }
      return false;
    },
    currentOrder() {
      return this.$store.getters['order/index/currentOrder'];
    },
    currentOrderId() {
      return this.$store.getters['order/index/currentOrderId'];
    }
  },
  created() {
    const {
      id,
      isReview
    } = this.$route.query;
    this.id = id;
    this.isReview = isReview;
    if (!this.currentOrderId) {
      this.$store.commit('order/index/CUR_ORDER_ID', id);
    }
  },
  methods: {
    handlePrint() {
      const {
        planId,
        orderType
      } = this.currentOrder;
      const {
        planType: planTypeUrl
      } = this.$route.query;
      // 打印页面的打印权限类型
      let gateUrl = '';
      if (planTypeUrl == 'T_TOURIST') {
        gateUrl = 'team';
      }
      if (planTypeUrl == 'S_TOURIST') {
        gateUrl = 'personal';
      }
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id: this.id,
          planId,
          component: 'Receipt',
          planType: orderType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    // 通过审核
    handleAudit(status) {
      this.$parent.fetchAuditStatus({
        status
      });
    }
  }
};