var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-reception"
  }, [_c("div", {
    staticClass: "btn-group"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 添加 ")]), _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.handleLog
    }
  }, [_vm._v(" 日志 ")])], 1), _c("Reception", {
    ref: "itemRef",
    attrs: {
      "is-audit": true
    }
  }), _c("Dialog", {
    ref: "dialogRef"
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };