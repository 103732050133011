var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", _vm._g(_vm._b({
    directives: [{
      name: "el-drag-dialog",
      rawName: "v-el-drag-dialog"
    }],
    attrs: {
      visible: _vm.visibleDialog,
      "append-to-body": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visibleDialog = $event;
      },
      close: _vm.handleCancel
    },
    scopedSlots: _vm._u([_vm.hasFoot ? {
      key: "footer",
      fn: function () {
        return [_vm._t("footer", function () {
          return [_c("span", [_c("el-button", {
            on: {
              click: _vm.handleCancel
            }
          }, [_vm._v("取 消")]), _c("el-button", {
            attrs: {
              loading: _vm.loading,
              type: "primary"
            },
            on: {
              click: _vm.handleConfirm
            }
          }, [_vm._v(" " + _vm._s(_vm.confirmText) + " ")])], 1)];
        })];
      },
      proxy: true
    } : null], null, true)
  }, "el-dialog", _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };