var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.filterTabMenu.length ? _c("div", {
    staticClass: "plan-arrange-edit form-fix-bar"
  }, [_vm.form ? _c("div", {
    staticClass: "form-wrapper plan-edit__form"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      disabled: _vm.isForbid,
      label: "大团号"
    },
    model: {
      value: _vm.form.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planNumber", $$v);
      },
      expression: "form.planNumber"
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "产品线路"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectProduct("product", $event);
      }
    },
    model: {
      value: _vm.form.productName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productName", $$v);
      },
      expression: "form.productName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateEndPicker", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: "发团日期"
    },
    on: {
      change: _vm.changeDate
    },
    model: {
      value: _vm.form.date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectProduct("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.form.dutyOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dutyOPUserName", $$v);
      },
      expression: "form.dutyOPUserName"
    }
  }), _c("div", {
    staticClass: "input-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleSaveInfo
    }
  }, [_vm._v(" 保存基础信息 ")])], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.guides ? _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" 参团人数: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.countPeople) + " ")])]) : _vm._e(), _vm.showArea ? _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("所属区域 : ")]), _c("span", [_vm._v(" " + _vm._s(_vm.planInfo.customerArea) + " ")])]) : _vm._e(), _vm.guides.name ? _c("div", {
    staticClass: "input-box"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(" 报账导游: ")]), _c("span", [_vm._v(" " + _vm._s(_vm.guides.name) + "( " + _vm._s(_vm.guides.mobileIphone) + ") ")])]) : _vm._e()])])], 1) : _vm._e(), !_vm.doNotShowTeamProfit ? _c("CollapseBase", {
    attrs: {
      title: "团利润",
      "is-strong": ""
    }
  }, [_c("TableTotalProfit", {
    ref: "TableTotalProfitRef"
  })], 1) : _vm._e(), _c("CollapseBase", {
    attrs: {
      title: "行程安排",
      "is-strong": "",
      "auto-hide": true
    }
  }, [_c("DetailPlanTrip", {
    attrs: {
      "plan-id": _vm.planId
    }
  })], 1), _c("FormRemarks"), _c("el-tabs", {
    on: {
      "tab-click": _vm.handleTab
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.filterTabMenu, function (item) {
    return _c("el-tab-pane", {
      key: item.id,
      attrs: {
        name: item.label
      }
    }, [_c("span", {
      attrs: {
        slot: "label"
      },
      slot: "label"
    }, [_c("i", {
      staticClass: "el-icon-s-opportunity",
      class: _vm.getTabStatus(item)
    }), _vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 1), _c("router-view", {
    ref: "contentRef"
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      loading: _vm.btnLoading,
      disabled: _vm.fetchStatus,
      type: "save"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")]), _vm.isHotelTab || _vm.isFleetTab ? _c("BtnBase", {
    attrs: {
      type: "setting"
    },
    on: {
      click: _vm.updateAndArrange
    }
  }, [_vm._v(" 保存并设置为已安排 ")]) : _vm._e(), _c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印单据 ")])], 1), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogTourist")], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };