var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h3", [_vm._v("订单信息")]), _vm.getData ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "景区"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.getData[0].scenicName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "联系人"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.getData[0].scenicContact))])], 1)])]) : _vm._e(), _c("div", {
    staticClass: "table-content"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.getData,
      "column-data": _vm.columnData,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "orderDate",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            "value-format": "yyyy-MM-dd",
            label: ""
          },
          model: {
            value: scope.row.useDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "useDate", $$v);
            },
            expression: "scope.row.useDate"
          }
        })];
      }
    }, {
      key: "ticketType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            list: scope.row.ticketList,
            label: ""
          },
          model: {
            value: scope.row.ticketType,
            callback: function ($$v) {
              _vm.$set(scope.row, "ticketType", $$v);
            },
            expression: "scope.row.ticketType"
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputNum", {
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "freeCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.freeCount) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }])
  })], 1), _c("div", {
    staticClass: "input-field mt20"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("TextBase", [_vm._v(" 应付 ")]), _c("TextBase", [_c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.getData[0].totalPay))])])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };