var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "merge-group",
    attrs: {
      title: "散客拼团",
      width: "1200px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "wrapper"
  }, [_c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "radio-box"
  }, [_c("el-radio", {
    attrs: {
      label: 1
    },
    on: {
      change: _vm.radioSingle
    },
    model: {
      value: _vm.flag,
      callback: function ($$v) {
        _vm.flag = $$v;
      },
      expression: "flag"
    }
  }, [_vm._v(" 独立成团 ")])], 1), _c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputTeamNumber", {
    attrs: {
      "plan-number": _vm.planNumber,
      "order-type": "S_TOURIST"
    },
    on: {
      "update:planNumber": function ($event) {
        _vm.planNumber = $event;
      },
      "update:plan-number": function ($event) {
        _vm.planNumber = $event;
      },
      change: _vm.changeTeamNumber
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.form.outOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outOPUserName", $$v);
      },
      expression: "form.outOPUserName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectProduct", {
    attrs: {
      label: "产品线路"
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.getProductName,
      callback: function ($$v) {
        _vm.getProductName = $$v;
      },
      expression: "getProductName"
    }
  }), _c("SelectDictionary", {
    attrs: {
      "init-val": _vm.form.groupType,
      code: "teamType",
      label: "团队类型"
    },
    on: {
      "on-select": _vm.selectTeamType
    },
    model: {
      value: _vm.form.groupType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupType", $$v);
      },
      expression: "form.groupType"
    }
  }), _c("InputDate", {
    attrs: {
      "default-format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      "is-require": "",
      label: "发团日期"
    },
    on: {
      change: function ($event) {
        return _vm.changeDate("startTripDate", $event);
      }
    },
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  }), _c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      "is-require": "",
      label: "散团日期"
    },
    on: {
      change: function ($event) {
        return _vm.changeDate("endTripDate", $event);
      }
    },
    model: {
      value: _vm.endDate,
      callback: function ($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("InputRemark", {
    staticStyle: {
      "padding-left": "83px"
    },
    attrs: {
      label: "",
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)])], 1), _c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "radio-box"
  }, [_c("el-radio", {
    attrs: {
      label: 2
    },
    model: {
      value: _vm.flag,
      callback: function ($$v) {
        _vm.flag = $$v;
      },
      expression: "flag"
    }
  }, [_vm._v(" 拼至已有团 ")])], 1), _vm.visible ? _c("TableTransfer", {
    ref: "transferRef",
    on: {
      "on-select": _vm.changeRadio
    }
  }) : _vm._e()], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };