// 外转安排

import request from '@/utils/request';

// 所有外转订单列接口（计调操作--新增 外转安排 菜单）
export function turnOutRequest(params) {
  return request({
    url: '/order/out/page-out',
    method: 'get',
    params,
    timeout: 1000 * 60 * 3
  });
}