import "core-js/modules/es.array.reduce.js";
import { orderAuditStatus } from '@/config/report';
export default {
  props: {
    columnData: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    summaryData: {
      type: Array,
      required: true
    },
    statisticsKeys: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    orderAuditStatus({
      row
    }) {
      const {
        auditProgress
      } = row;
      const item = orderAuditStatus.filter(it => it.value == auditProgress)[0];
      return item.label;
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const {
        customerArea
      } = data[0];
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '区域小计';
          return;
        }
        if (index === 3) {
          sums[index] = customerArea;
          return;
        }
        const values = data.map(item => {
          if (!data.length) return 0;
          const statisticsKeys = ['totalIncome', ...this.statisticsKeys];
          if (statisticsKeys.includes(column.property)) {
            if (column.property === 'number' || column.property === 'count') {
              const {
                adultCount,
                childCount,
                accompanyCount
              } = item;
              let res = adultCount + '/' + childCount + '/' + (accompanyCount || 0);
              return res;
            } else {
              return [item[column.property], 0, 0].join('/');
            }
          }
        });
        if (values.every(value => value != undefined)) {
          const res = values.reduce((prev, cur) => {
            const [adultCount, childCount, accompanyCount] = cur.split('/');
            prev[0] += Number(adultCount);
            prev[1] += Number(childCount);
            prev[2] += Number(accompanyCount);
            return prev;
          }, [0, 0, 0]);
          if (column.property === 'number' || column.property === 'count') {
            sums[index] = `${res[0]}大${res[1]}小${res[2]}陪`;
          } else {
            sums[index] = Math.round(res[0]);
          }
        }
      });
      return sums;
    }
  }
};