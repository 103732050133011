var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tableData.length ? _c("div", {
    ref: "touristTeamRef"
  }, [_c("div", {
    staticClass: "print-page",
    attrs: {
      id: "TouristTeam"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: _vm.filename
    }
  }), _c("div", {
    staticClass: "content tourist-team"
  }, [_c("TouristAge", {
    attrs: {
      data: _vm.getTableData
    },
    on: {
      onMounted: _vm.loadedAgeData
    }
  }), _c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(24, function (index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", _vm._l(_vm.tableHead, function (item, index) {
    return _c("th", {
      key: index,
      attrs: {
        colspan: item.span ? item.span : 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.tableData, function (item, index) {
    return [_c("tr", {
      key: index,
      staticStyle: {
        "mso-number-format": "'\\@'"
      }
    }, [_vm._l(_vm.tableHead, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        staticClass: "tac",
        attrs: {
          colspan: col.span
        }
      }, [col.slot == "serialnumber" ? [_vm._v(" " + _vm._s(item.serialnumber) + " ")] : _vm._e(), col.slot == "customerTripNumber" ? [_vm._v(" " + _vm._s(item.customerTripNumber) + " ")] : _vm._e(), col.slot == "cardType" ? [_c("span", {
        staticStyle: {
          "mso-number-format": "'\\@'"
        }
      }, [_vm._v(" " + _vm._s(item.cardType) + " ")])] : _vm._e(), col.slot == "birth" ? [_c("span", [_vm._v(" " + _vm._s(_vm.isBirthDay(item.birth)) + " ")])] : _vm._e(), !col.slot ? _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")]) : _vm._e()], 2)];
    })], 2)];
  })], 2)])], 1)], 1)]) : _c("div", {
    staticStyle: {
      margin: "20px auto"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: _vm.filename
    }
  }), _c("Empty")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };