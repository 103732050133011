var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper personal-order-list form-fix-bar"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.changeTab
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "未拼团",
      name: "unGroup"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "已拼团",
      name: "inGroup"
    }
  })], 1), _vm.activeName == "unGroup" ? _c("UnGroup", {
    ref: "unGroupRef"
  }) : _vm._e(), _vm.activeName == "inGroup" ? _c("InGroup", {
    ref: "inGroupRef"
  }) : _vm._e(), _c("DialogTransfer", {
    ref: "transferRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };