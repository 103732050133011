var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tableData.length && _vm.total ? _c("table", {
    staticClass: "table-tpl table-box"
  }, [_c("tbody", [_c("th", {
    staticClass: "th-title",
    attrs: {
      rowspan: _vm.tableData.length + 1
    }
  }, [_vm._v(" 购物佣金 ")]), _vm._l(_vm.columnData, function (item, i) {
    return _c("th", {
      key: "th" + i
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), _vm._l(_vm.tableData, function (item, ix) {
    return _c("tr", {
      key: ix
    }, [_vm._l(_vm.columnData, function (its, idx) {
      return [its.prop != "name" && its.prop != "totalBuyMoney" && its.prop != "total" ? _c("td", _vm._l(item.list, function (v, is) {
        return _c("p", {
          key: is + idx,
          staticClass: "line"
        }, [_vm._v(" " + _vm._s(v[its.prop]) + " ")]);
      }), 0) : _vm._e(), its.prop == "name" ? _c("td", [_vm._v(" " + _vm._s(item.name)), _c("br"), _vm._v(" " + _vm._s(item.orderDate) + " ")]) : _vm._e(), its.prop == "totalBuyMoney" ? _c("td", [_vm._v(" " + _vm._s(item.totalBuyMoney) + " ")]) : _vm._e(), ix == 0 && its.prop == "total" ? _c("td", {
        staticStyle: {
          width: "62px"
        },
        attrs: {
          rowspan: _vm.tableData.length
        }
      }, [_vm._v(" " + _vm._s(_vm.total) + " ")]) : _vm._e()];
    })], 2);
  })], 2)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };