import FormStaff from './FormStaff';
import TableList from './TableList';
import { InputDepartment, SelectRole, BtnCreate } from '@/components/common';
import { systemStaff } from '@/config/system';
import { departIdKeys } from '@/config/dictionary';
import { permissionMixin, reloadMixin } from '@/mixin';
import { searchMixin } from '@/mixin';
const defaultState = () => ({
  department: '',
  // 部门
  subDepartment: '',
  // 子部门
  grandsonDepartment: '' // 二级部门
});
const replaceKeys = {
  departmentId: 'department',
  // 部门
  subDepartmentId: 'subDepartment',
  // 子部门
  grandsonDepartmentId: 'grandsonDepartment' // 二级部门
};
export default {
  components: {
    BtnCreate,
    InputDepartment,
    SelectRole,
    FormStaff,
    TableList
  },
  mixins: [permissionMixin, reloadMixin, searchMixin],
  data: () => ({
    tableLoading: false,
    tableData: [],
    columnData: systemStaff,
    total: 0,
    pageNum: 1,
    searchParams: defaultState()
  }),
  computed: {
    systemStaffData() {
      return this.$store.getters['system/staff/systemStaffData'];
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        this.searchParams = data;
      },
      immediate: true
    },
    systemStaffData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      const {
        totalCount,
        list
      } = data;
      this.tableData = list;
      this.total = totalCount;
    },
    fetchData(queryParams) {
      this.tableData = [];
      this.$store.dispatch('system/staff/fetchUser', {
        pageNum: this.pageNum,
        ...queryParams
      });
    },
    changeRole(val) {
      const {
        name,
        id
      } = val;
      this.searchParams.roleLabel = name;
      this.searchParams.roleId = id;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.pageNum = pageNum;
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 部门
    changeDepartment(val) {
      departIdKeys.forEach(key => {
        this.searchParams[replaceKeys[key]] = val[key];
      });
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    showCreateDialog() {
      const isUpdate = false;
      this.showFormDialog(isUpdate);
      this.$store.dispatch('system/staff/edit', {
        isEnable: true
      });
    },
    updateEnable(opts) {
      this.$store.dispatch('system/staff/createOrUpdate', opts).then(() => {
        this.fetchData();
        this.$bus.tip();
        this.$refs.formRef.hide();
      });
    },
    editTableRow(scope) {
      const isUpdate = true;
      this.showFormDialog(isUpdate);
      // 拷贝一份数据，否则会联动，如果用户修改了，但保存，就会出现问题
      const copy = {
        ...scope.row
      };
      this.$store.dispatch('system/staff/edit', copy);
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('system/staff/delete', id).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      });
    },
    showFormDialog(isUpdate) {
      this.$refs.formRef.show(isUpdate);
    }
  }
};