import { Pickup } from '@/components/common/Order';
import { auditEditPickup } from '@/config/audit';
import { planIsOrderInfoMixin } from '@/mixin';
export default {
  components: {
    Pickup
  },
  mixins: [planIsOrderInfoMixin],
  data() {
    return {
      columnData: auditEditPickup,
      auditAll: false
    };
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};