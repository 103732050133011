import "core-js/modules/es.array.push.js";
import { SelectGroup } from '@/components/common';
import { GroupDownBill as columns } from '@/config/group';
import { mapGetters } from 'vuex';
import { reloadMixin } from '@/mixin';
import { processMinus } from '@/utils/number';
import billDownTableMixin from '@/views/billDown/billDownTableMixin';
import { TYPES } from '@/config/billDown';
export default {
  components: {
    SelectGroup
  },
  mixins: [reloadMixin, billDownTableMixin],
  data() {
    return {
      columns,
      searchParams: {
        cashType: TYPES.company
      },
      payDate: [],
      pathCreate: '/bill/group-create',
      pathDetail: '/bill/group-detail',
      pathUpdate: '/bill/group-update'
    };
  },
  computed: {
    ...mapGetters({
      data: ['group/billDownData'],
      tableData: ['group/billDownList']
    }),
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    getBalance() {
      return row => processMinus(row.money, row.userMoney);
    }
  },
  created() {
    if (!this.data) {
      this.fetchData();
    }
    if (this.$route.query.isBack) {
      this.fetchData();
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('group/fetchBillDown', {
        ...this.searchParams,
        ...params
      });
    },
    handleSearch() {
      this.fetchData();
    },
    handlePage(params) {
      this.fetchData(params);
    },
    changeDate(type, val) {
      const [start, end] = val || [];
      this.searchParams['start' + type] = start;
      this.searchParams['end' + type] = end;
    },
    // 添加收款
    handleCreate() {
      this.$router.push({
        path: this.pathCreate
      });
    },
    handleDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('group/deleteCollect', id).then(() => {
          this.fetchData();
          this.$bus.tip();
        });
      });
    },
    handleUpdate(row) {
      const {
        rowId: id,
        resourceId,
        cash: money
      } = row;
      this.$store.commit('bill/billDown/SET_BILL_INPUT', {
        ...row,
        id,
        money
      });
      this.$router.push({
        path: this.pathUpdate,
        query: {
          id,
          rid: resourceId
        }
      });
    }
  }
};