import Reception from './Reception';
import ReceptionEdit from './ReceptionEdit';
import { mapGetters } from 'vuex';
import { reloadMixin } from '@/mixin';
export default {
  name: 'PlanReception',
  components: {
    Reception,
    ReceptionEdit
  },
  mixins: [reloadMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    isAudit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: 'fee',
      tabIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      receptionList: 'order/reception/receptionList'
    })
  },
  mounted() {
    this.fetchData();
    this.$bus.on('reception:reload', this.fetchData);
  },
  methods: {
    fetchData() {
      this.$store.dispatch('plan/reception/fetch', this.currentPlanId || this.$route.query.id);
    },
    showEditDialog(id) {
      this.$refs.receptionEditRef.show(id);
    },
    handleUpdate() {
      if (!this.receptionList || !this.receptionList.length) {
        return Promise.reject();
      }
      return new Promise(resolve => {
        this.$refs.receptionRef.handleUpdate().then(() => {
          resolve();
        });
      });
    }
  }
};