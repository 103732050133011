var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tableData.length ? _c("table", {
    staticClass: "table-tpl"
  }, [_c("tbody", [_vm.rowTitle ? _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.rowTitle))])])]) : _vm._e(), _vm._l(_vm.columnData, function (item, index) {
    return _c("th", {
      key: index
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), _vm._l(_vm.tableData, function (item, index) {
    return [_c("tr", {
      key: index + item.id
    }, _vm._l(_vm.columnData, function (content, cIdx) {
      return _c("td", {
        key: index + " " + cIdx,
        attrs: {
          width: content.width ? content.width : ""
        }
      }, [content.slot ? [_vm._t(content.slot, null, {
        data: item
      })] : [_vm._v(" " + _vm._s(item[content.prop]) + " ")]], 2);
    }), 0)];
  })], 2)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };