var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.dialogTitle,
      width: "700px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreateOrUpdate,
      cancel: _vm.handleCancel
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "form-title"
  }, [_c("h3", [_vm._v("设置流程:")]), _c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 添加 ")])], 1), _vm.visible ? _c("TableEdit", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.loading
    }
  }) : _vm._e()], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };