var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.columnData && _vm.columnData.length ? _c("table", {
    staticClass: "table-custom",
    attrs: {
      border: "1",
      cellspacing: "0",
      bordercolor: "#ebeef5"
    }
  }, [_c("tr", [_vm._l(_vm.columnData, function (item, x) {
    return [_c("th", {
      key: x,
      style: {
        width: x == 0 ? "100px" : "auto"
      }
    }, [_vm._v(" " + _vm._s(item.attrs ? item.attrs.label : "") + " ")])];
  })], 2), _vm._l(_vm.tableData, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(_vm.columnData, function (v, i) {
      return [_c("td", {
        key: i
      }, [i == 0 ? [_vm._v(" " + _vm._s(v.attrs ? item[v.attrs.prop] : "") + " ")] : [_c("el-input", {
        attrs: {
          placeholder: "请输入内容"
        },
        on: {
          change: function ($event) {
            return _vm.updateData(item, v, $event);
          }
        },
        model: {
          value: item[v.attrs.prop],
          callback: function ($$v) {
            _vm.$set(item, v.attrs.prop, $$v);
          },
          expression: "item[v.attrs.prop]"
        }
      })]], 2)];
    })], 2)];
  })], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };