var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.payments ? _c("InputData", _vm._g(_vm._b({
    attrs: {
      list: _vm.payments,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      label: "方式",
      placeholder: "请选择收款方式"
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };