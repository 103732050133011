import FormItem from './FormItem.vue';
export default {
  components: {
    FormItem
  },
  data() {
    return {
      visible: false,
      resourceName: '',
      cashItem: null
    };
  },
  methods: {
    show(item) {
      this.visible = true;
      this.resourceName = item.resourceName;
      this.cashItem = item;
    },
    hide() {
      this.visible = false;
      this.cashItem = null;
    },
    handleConfirm() {
      this.$refs.formItem.validate().then(valid => {
        if (!valid) return;
        this.$emit('on-save', this.$refs.formItem.form);
        this.hide();
      });
    }
  }
};