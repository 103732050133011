var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    ref: "tableRef",
    attrs: {
      "column-data": _vm.columnData,
      "show-empty": false,
      "hide-bar": false,
      sticky: true,
      "empty-text": " "
    }
  }), _vm._l(_vm.tableData, function (table) {
    return _c("div", {
      key: table.id,
      staticClass: "personal-ungroup-table"
    }, [_c("div", {
      staticClass: "table-expand"
    }, [_c("i", {
      class: !table.expand ? "el-icon-caret-top" : "el-icon-caret-bottom",
      on: {
        click: function ($event) {
          table.expand = !table.expand;
        }
      }
    }), _c("TextBase", [_vm._v("发团日期：" + _vm._s(table.date))])], 1), _c("TableBase", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: table.expand,
        expression: "table.expand"
      }],
      attrs: {
        data: table.children,
        "column-data": _vm.columnData,
        "row-class-name": _vm.tableRowClassName,
        "show-header": false,
        sticky: true,
        dynamic: false,
        border: ""
      },
      scopedSlots: _vm._u([{
        key: "checkbox",
        fn: function ({
          scope
        }) {
          return [_c("el-checkbox", {
            attrs: {
              disabled: _vm.addAuditStatus(scope)
            },
            on: {
              change: function ($event) {
                return _vm.handleCheck(scope, $event);
              }
            },
            model: {
              value: scope.row.checked,
              callback: function ($$v) {
                _vm.$set(scope.row, "checked", $$v);
              },
              expression: "scope.row.checked"
            }
          })];
        }
      }, {
        key: "orderInfo",
        fn: function ({
          scope
        }) {
          return [_c("TripNumberBase", {
            on: {
              "on-click": function ($event) {
                return _vm.linkDetail(scope);
              }
            }
          }, [_c("span", {
            staticStyle: {
              cursor: "pointer"
            }
          }, [_vm._v(" " + _vm._s(scope.row.customerTripNumber ? scope.row.customerTripNumber : "--") + " ")])]), _c("br"), _vm._v(" " + _vm._s(scope.row.productName) + " ")];
        }
      }, {
        key: "customerName",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onCustomShow(scope.row);
              }
            }
          }, [_c("CustomerInfo", {
            attrs: {
              name: scope.row.customerName
            }
          })], 1)];
        }
      }, {
        key: "count",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onCount(scope);
              }
            }
          }, [_c("PersonCount", {
            attrs: {
              row: scope.row
            }
          })], 1)];
        }
      }, {
        key: "accompanyInfo",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onTourist(scope);
              }
            }
          }, [scope.row.members.length ? [_c("Members", {
            attrs: {
              row: scope.row,
              wrap: true
            }
          })] : [_vm._v(" -- ")]], 2)];
        }
      }, {
        key: "date",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onItineraryDate(scope);
              }
            }
          }, [_c("span", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("br"), _c("span", [_vm._v(_vm._s(scope.row.endTripDate))])])];
        }
      }, {
        key: "traffic",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onShuttle(scope);
              }
            }
          }, [scope.row.shuttles && scope.row.shuttles.length ? _vm._l(scope.row.shuttles, function (item) {
            return _c("span", {
              key: item.id
            }, [_c("span", [_vm._v(_vm._s(item.shuttleType == "PICK" ? "【接团】" : "【送团】"))]), _c("span", [_vm._v(_vm._s(_vm.formatIosDate(item.shiftDateTime, "{y}-{m}-{d} {h}:{i}")))]), _c("br"), _c("span", [_vm._v(_vm._s(item.desPosition))]), _vm._v("   "), _c("span", [_vm._v(_vm._s(item.taskType == "PLANE" ? "飞机" : "火车") + "  ")]), _c("span", [_vm._v(_vm._s(item.shiftNumber))]), _c("br")]);
          }) : [_vm._v(" -- ")]], 2)];
        }
      }, {
        key: "itinerary",
        fn: function ({
          scope
        }) {
          return [scope.row.itinerarys && scope.row.itinerarys.length ? _vm._l(scope.row.itinerarys, function (item) {
            return _c("div", {
              key: item.id,
              staticStyle: {
                "text-align": "left"
              }
            }, [_vm._v(" " + _vm._s(item.itineraryDate) + _vm._s(item.itineraryDesc) + " ")]);
          }) : _vm._e()];
        }
      }, {
        key: "state",
        fn: function ({
          scope
        }) {
          return [_vm.orderAuditStatus(scope.row) ? _c("div", {
            staticStyle: {
              cursor: "pointer"
            }
          }, [_c("el-tag", {
            attrs: {
              type: "info"
            },
            on: {
              click: function ($event) {
                return _vm.linkAuditDetail(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.reviewerStatus[scope.row.addAuditStatus]) + " ")])], 1) : _vm._e(), _c("el-tag", {
            attrs: {
              effect: "plain",
              type: scope.row.auditStatus ? "success" : "warning"
            }
          }, [_vm._v(" " + _vm._s(scope.row.auditStatus ? "已审核" : "待审核") + " ")]), _vm.getAddAuditStatus(scope.row.addAuditStatus) ? _c("el-tag", {
            attrs: {
              type: "danger",
              effect: "plain"
            }
          }, [_vm._v(" " + _vm._s(_vm.getAddAuditStatus(scope.row.addAuditStatus)) + " ")]) : _vm._e()];
        }
      }, {
        key: "totalIncome",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onFundDialog(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(scope.row.totalIncome) + " ")])];
        }
      }, {
        key: "totalCollect",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onFundDialog(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(scope.row.totalCollect) + " ")])];
        }
      }, {
        key: "totalSubsidy",
        fn: function ({
          scope
        }) {
          return [_c("div", {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                return _vm.onFundDialog(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(scope.row.totalSubsidy) + " ")])];
        }
      }, {
        key: "action",
        fn: function ({
          scope
        }) {
          return [_c("el-dropdown", {
            on: {
              command: function ($event) {
                return _vm.handleCommand(scope, $event);
              }
            }
          }, [_c("el-button", {
            attrs: {
              type: "text"
            }
          }, [_vm._v(" 操作"), _c("i", {
            staticClass: "el-icon-arrow-down el-icon--right"
          })]), scope.row.isCancel ? _c("el-dropdown-menu", {
            attrs: {
              slot: "dropdown"
            },
            slot: "dropdown"
          }, [_c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-folder-remove",
              command: "handleRevoke"
            }
          }, [_vm._v(" 取消退单 ")])], 1) : _c("el-dropdown-menu", {
            attrs: {
              slot: "dropdown"
            },
            slot: "dropdown"
          }, [_c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-tickets",
              command: "handleShow"
            }
          }, [_vm._v(" 查看 ")]), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-edit-outline",
              command: "handleEdit"
            }
          }, [_vm._v(" 编辑 ")]), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-document-copy",
              command: "handleCopy"
            }
          }, [_vm._v(" 复制 ")]), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-document-copy",
              command: "handleOnkeyCopy"
            }
          }, [_vm._v(" 一键复制 ")]), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-folder-remove",
              command: "handleRevoke"
            }
          }, [_vm._v(" 退单 ")]), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-document-delete",
              command: "handleDelete"
            }
          }, [_vm._v(" 删除 ")]), _c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-printer",
              command: "handlePrint"
            }
          }, [_vm._v(" 打印 ")])], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c("DialogCustom", {
    ref: "customRef"
  }), _c("DialogCount", {
    ref: "countRef"
  }), _c("DialogTableTourist", {
    ref: "tableTouristRef"
  }), _c("DialogGroupFund", {
    ref: "groupFundRef"
  }), _c("DialogItineraryDate", {
    ref: "itineraryDateRef",
    attrs: {
      "order-type": _vm.orderType
    }
  }), _c("DialogTablePickup", {
    ref: "tablePickupRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };