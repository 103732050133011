/* eslint-disable no-unused-vars */
/*
 * @Author: cyg
 * @Date: 2023-08-11 08:38:42
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-18 10:50:38
 * @FilePath: \travel-admin\src\store\modules\offer\index.js
 */
/* eslint-disable no-empty-pattern */
import { fetchOffer, deleteOffer, updateOffer, fetchOfferId, fetchOfferCode, updateOfferStatus, updateAfreshOffer } from '@/api/offer/index';
const dfaultState = () => ({
  offerData: null
});
const state = dfaultState();
const getters = {
  offerData: state => state.offerData
};
const actions = {
  fetchOffer({
    commit
  }, params) {
    return fetchOffer(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      commit('FETCH_DATA', data);
      return data;
    });
  },
  updateOffer({
    commit
  }, params) {
    return updateOffer(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchOfferId({
    commit
  }, params) {
    return fetchOfferId(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchOfferCode({
    commit
  }, params) {
    return fetchOfferCode(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  updateOfferStatus({
    commit
  }, params) {
    return updateOfferStatus(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  deleteOffer({
    commit
  }, params) {
    return deleteOffer(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  updateAfreshOffer({
    commit
  }, params) {
    return updateAfreshOffer(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.offerData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};