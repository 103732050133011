import "core-js/modules/es.array.push.js";
import { TableList } from './components';
import { SelectGuide, SelectProduct, SelectCustom, SelectStaff, SelectDictionary } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
import { orderAuditStatus } from '@/config/report';
import { profitPlan, profitPlanTerse, profitPlanTerseAll } from './tableConfig';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { parseDate } from '@/utils';
const today = parseDate();
export default {
  components: {
    TableList,
    SelectProduct,
    SelectGuide,
    SelectCustom,
    SelectStaff,
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    orderAuditStatusList: orderAuditStatus,
    tableLoading: false,
    tableData: [],
    columnData: [],
    total: 0,
    // sendDate: [today, ''],
    // endDate: [],
    searchParams: {
      startTripDateS: today
    },
    statisticData: [],
    statistic: {}
  }),
  computed: {
    ...mapGetters({
      teamProfit: 'report/index/teamProfit',
      roleInfo: 'system/role/roleCustomerInfoAuth'
    })
  },
  watch: {
    teamProfit: {
      handler: 'initData',
      immediate: true
    }
  },
  created() {
    /**
     * 二个菜单是同一个文件
     * 1. 先判断路由地址；profit-team-terse是精简版的；profit-team是原来的
     */
    const showSubsidy = this.roleInfo && this.roleInfo.showShopSubsidy;
    if (this.$route.path.indexOf('profit-team-terse') > -1) {
      // 精简版
      this.columnData = showSubsidy ? profitPlanTerseAll : profitPlanTerse;
    } else {
      this.columnData = profitPlan;
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(); // this.searchParams
      } else {
        this.statisticData = [];
        const {
          list,
          totalCount,
          statistics
        } = data;
        this.tableData = list;
        this.total = totalCount;
        this.statistic = statistics;
        this.statisticData.push(statistics);
      }
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getTeamProfit', {
        ...this.searchParams,
        ...queryParam
      }).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
    },
    // 选择产品
    selectProduct(val) {
      this.searchParams.productName = val.name;
      this.searchParams.productId = val.value;
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    selecthotelRegion({
      label
    }) {
      this.searchParams.customerArea = label;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 导出单团利润表
    exportExcel() {
      const loading = this.$bus.loading();
      let search = {
        ...this.searchParams
      };
      delete search.pageNum;
      delete search.pageSize;
      this.$store.dispatch('report/index/fetchTeamProfitExcel', search).then(res => {
        const fileName = '单团利润报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};