var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }, {
      key: "times",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.times) + " ")];
      }
    }, {
      key: "state",
      fn: function ({
        scope
      }) {
        return [scope.row.quotationStatus == 1 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v(" 报价成功 ")]) : _vm._e(), scope.row.quotationStatus == 0 ? _c("el-tag", [_vm._v(" 报价中 ")]) : _vm._e(), scope.row.quotationStatus < 0 ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v(" 报价失败 ")]) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.onAnewOffer(scope.row);
            }
          }
        }, [_vm._v(" 重新报价 ")]), _c("el-button", {
          attrs: {
            type: "success",
            disabled: scope.row.quotationStatus ? true : false
          },
          on: {
            click: function ($event) {
              return _vm.onConfirm(scope.row);
            }
          }
        }, [_vm._v(" 确认报价 ")]), _c("el-button", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.onHistory(scope.row);
            }
          }
        }, [_vm._v(" 历史报价 ")]), _c("el-button", {
          on: {
            click: function ($event) {
              return _vm.codeOffer(scope.row);
            }
          }
        }, [_vm._v(" 复制报价 ")]), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            placement: "bottom",
            "data-clipboard-text": _vm.codeText
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-arrow-down"
          }
        }, [_vm._v(" 分享报价 ")]), _c("template", {
          slot: "content"
        }, [_c("div", {
          staticClass: "share-dropdown pointer",
          on: {
            click: function ($event) {
              return _vm.handleShare(scope.row);
            }
          }
        }, [_vm._v(" 分享为图片 ")]), _c("div", {
          ref: "copyTips",
          staticClass: "share-dropdown pointer copy-btn",
          attrs: {
            role: "tooltip",
            "data-clipboard-text": _vm.codeText
          },
          on: {
            click: function ($event) {
              return _vm.handleSharaCopy(scope.row);
            }
          }
        }, [_vm._v(" 分享为文本 ")])])], 2)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };