// 船票
import request from '@/utils/request';

// 设置团船票名单的锁定状态
export function setShipLockStatusRequest(params) {
  return request({
    url: '/order/plan/set-ship-name-list-status',
    method: 'get',
    params
  });
}

// 设置团船票出票状态
export function setShipTicketStatusRequest(params) {
  return request({
    url: '/order/plan/set-ship-ticket-status',
    method: 'get',
    params
  });
}

// 设置游客船票名单状态
export function setShipMemberStatusRequest(params) {
  return request({
    url: '/order/setMemberShipStatus',
    method: 'get',
    params
  });
}