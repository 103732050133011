// 部门业绩统计
export const Columns = [{
  attrs: {
    prop: '$orderIndex',
    label: '序号',
    sortable: true
  }
}, {
  attrs: {
    prop: 'name',
    label: '部门'
  }
}, {
  attrs: {
    prop: 'orders',
    label: '订单'
  }
}, {
  attrs: {
    prop: '',
    label: '团数量'
  },
  children: [{
    attrs: {
      prop: 'tdPlans',
      label: '团队',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'skPlans',
      label: '散拼',
      sortable: true
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '总人数'
  },
  children: [{
    attrs: {
      prop: 'adultCount',
      label: '大',
      'min-width': 60,
      sortable: true
    }
  }, {
    attrs: {
      prop: 'childCount',
      label: '小',
      'min-width': 60,
      sortable: true
    }
  }, {
    attrs: {
      prop: 'accompanyCount',
      label: '陪',
      'min-width': 60,
      sortable: true
    }
  }]
}, {
  attrs: {
    prop: 'total',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: '',
      label: '团队'
    },
    children: [{
      attrs: {
        prop: 'tdAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '散客'
    },
    children: [{
      attrs: {
        prop: 'skAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }]
}, {
  attrs: {
    prop: 'feeIncome',
    label: '团款收入',
    sortable: true
  }
}, {
  attrs: {
    prop: '$feeIncomed',
    label: '团款已收',
    sortable: true
  }
}, {
  attrs: {
    prop: '$feeUnIncome',
    label: '团款未收',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '发团收入',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '发团成本'
  },
  sortable: true
}, {
  attrs: {
    prop: 'collectFee',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'subsidyFee',
    label: '返款'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossMargin',
    label: '人均毛利',
    sortable: true
  }
}, {
  attrs: {
    prop: 'grossProfitRate',
    label: '毛利率',
    sortable: true
  }
}];