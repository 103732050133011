import TopTitle from '../components/TopTitle';
import SplitText from '../components/SplitText';
import { parseTime } from '@/utils';
const tableData = [{
  rows: [{
    label: '产品名称'
  }, {
    content: 'productName',
    span: 2
  }, {
    label: '团号'
  }, {
    content: 'planNumber'
  }, {
    label: '人数'
  }, {
    content: 'number',
    slot: 'number'
  }, {
    label: '用车单位'
  }, {
    content: 'busCompanyNames',
    span: 2,
    slot: 'busCompanyNames'
  }, {
    label: '车型'
  }, {
    content: 'busCompanyTypes',
    slot: 'busCompanyTypes'
  }]
}, {
  rows: [{
    label: '接团日期'
  }, {
    content: 'arriveDate',
    span: 2,
    slot: 'arriveDate'
  }, {
    label: '接团航班车次'
  }, {
    content: 'arriveInto'
  }, {
    label: '全陪'
  }, {
    content: 'accompanyInfo'
  }, {
    label: '车号'
  }, {
    content: 'licenceNumbers',
    span: 2,
    slot: 'licenceNumbers'
  }, {
    label: '车费'
  }, {
    content: 'busFees',
    slot: 'busFees'
  }]
}, {
  rows: [{
    label: '送团日期'
  }, {
    content: 'leaveDate',
    span: 2,
    slot: 'leaveDate'
  }, {
    label: '送团航班车次'
  }, {
    content: 'leaveInto'
  }, {
    label: '地陪'
  }, {
    content: 'guideName'
  }, {
    label: '司机'
  }, {
    content: 'driverName',
    span: 2,
    slot: 'driverName'
  }, {
    label: '付款方式'
  }, {
    content: 'busCompanyPayType'
  }]
}, {
  rows: [{
    label: '接站牌'
  }, {
    content: 'welcomeBorad',
    span: 2
  }, {
    label: '客服'
  }, {
    content: 'outOPUserName'
  }, {
    label: '操作计调'
  }, {
    content: 'dutyOPUserName'
  }, {
    label: '房调'
  }, {
    content: 'hotel',
    span: 2,
    slot: 'hotel'
  }, {
    label: '车调'
  }, {
    content: 'bus',
    slot: 'bus'
  }]
}, {
  rows: [{
    label: '游客联系人'
  }, {
    content: 'members',
    span: 11
  }]
}];
const tableTrip = [{
  content: 'itineraryDate',
  span: 1
}, {
  content: 'scenicName',
  span: 4
}, {
  content: 'roomNames',
  span: 4,
  slot: 'roomNames'
}, {
  content: 'breakfastContext',
  span: 1
}, {
  content: 'lunchContext',
  span: 1
}, {
  content: 'dinnerContext',
  span: 1
}];
const tableData2 = [{
  rows: [{
    label: '服务标准'
  }, {
    content: 'serviceStandardRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '用房'
  }, {
    content: 'hotelRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '用餐'
  }, {
    content: 'restaurantRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '用车'
  }, {
    content: 'busRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '景点'
  }, {
    content: 'scenicRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '特别交代'
  }, {
    content: 'friendlyReminderRemark',
    span: 11
  }]
}, {
  rows: [{
    label: '开票信息'
  }, {
    content: 'agencyInfoTaxInvoice',
    span: 11
  }]
}, {
  rows: [{
    label: '票务'
  }, {
    content: 'agencyInfoTicketBusiness',
    span: 11
  }]
}, {
  rows: [{
    label: '运营'
  }, {
    content: 'agencyInfoTrade',
    span: 11
  }]
}, {
  rows: [{
    label: '客服'
  }, {
    content: 'agencyInfoService',
    span: 11
  }]
}, {
  rows: [{
    label: '公司紧急'
  }, {
    content: 'agencyInfoUrgent',
    span: 11
  }]
}];
export default {
  components: {
    TopTitle,
    SplitText
  },
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      data: null,
      tableData,
      tableData2,
      tableTrip,
      printDate
    };
  },
  computed: {
    isDataRemark() {
      return val => {
        return val == 'scenicRemark' || val == 'busRemark' || val == 'hotelRemark' || val == 'restaurantRemark' || val == 'serviceStandardRemark' || val == 'friendlyReminderRemark';
      };
    },
    getBusTypeList() {
      if (!this.data.busCompanyTypes) return [];
      let arr = this.data.busCompanyTypes.split(', ');
      return arr.map(it => {
        return it.split('|')[0];
      });
    },
    getArrayList() {
      return type => {
        if (!type) return [];
        let val = this.data[type];
        if (!val) return [];
        return val.split(', ');
      };
    },
    formatRoom() {
      return val => {
        if (!val) return;
        let arr = val.split('|');
        arr = arr.filter(it => it !== '' && it !== undefined);
        return arr || [];
      };
    },
    getTextContent() {
      return (col, data) => col.label ? col.label + '\n' : data.travelAgencyVo[col.content];
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(planId) {
      this.$store.dispatch('order/print/fetchTeamPlan', {
        planId,
        type: 2
      }).then(data => {
        this.data = data;
        // 格式化时间
        this.data.arriveTime = data.arriveDate ? parseTime(data.arriveDate, '{h}:{i}') : '';
        this.data.arriveDate = data.arriveDate ? parseTime(data.arriveDate, '{y}-{m}-{d}') : '';
        this.data.leaveTime = data.leaveDate ? parseTime(data.leaveDate, '{h}:{i}') : '';
        this.data.leaveDate = data.leaveDate ? parseTime(data.leaveDate, '{y}-{m}-{d}') : '';
        // 拼接接送信息
        this.data.arriveInto = data.arriveNumber ? '接站' + ' ' + this.data.arriveTime + ' ' + data.arriveNumber : '';
        this.data.leaveInto = data.leaveNumber ? '送站' + ' ' + this.data.leaveTime + ' ' + data.leaveNumber : '';
        this.data.respOrderTrips.forEach(it => {
          it.itineraryDate = parseTime(it.itineraryDate, '{m}-{d}');
        });
        // 排序
        data.respOrderTrips.sort(function (a, b) {
          return a.itineraryDate > b.itineraryDate ? 1 : -1; //升序  < 降序
        });
        this.$emit('onMounted');
      });
    },
    formatDateTime(val) {
      const dateArr = parseTime(val, '{y}-{m}-{d} {h}:{i}');
      return dateArr;
    }
  }
};