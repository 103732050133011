/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: cyg
 * @LastEditTime: 2023-06-07 17:31:50
 * @Description:
 */

import { isDecimal } from '@/utils';
const SYS_CONFIG = {
  allowModifyArrangeOrder: 'arrangeHotelAllowEdit',
  allowModifyMoney: 'order:allowModifyOrder:money',
  refundNotOffset: 'order:refundNotOffset:refundNotOffsetValue',
  allowModifyOrderPickup: 'order:allowModifyOrderPickup:allowModifyOrderPickupValue',
  autoGenerateRefund: 'order:subsidy:autoGenerateAudit',
  borrowApplyDingDingEnable: 'plan:borrowApply:borrowApplyDingDing',
  hasOrderInfo: 'plan_order_info',
  needOPAudit: 'audit:audit4:audit4-3',
  auditFinalPrice: 'audit:audit15:audit15-0',
  keepTwoDecimal: 'shop:shopKeepTwoDecimalPlaces:shopKeepTwoDecimalPlacesValue',
  scenicAddAlgorithm1: 'audit:audit_scenic_algorithm:audit_scenic_algorithm_1',
  scenicAddAlgorithm2: 'audit:audit_scenic_algorithm:audit_scenic_algorithm_2',
  collectAuditedAllowChange: 'order:collectAuditedAllowChange:collectAuditedAllowChangeValue'
};
export default {
  data() {
    return {
      keepTwoDecimal: false // 是否保留两位小数
    };
  },
  computed: {
    roleData() {
      return this.$store.getters['system/systemConf/roleData'];
    },
    // 订单安排酒店后允许修改
    allowModifyArrangeOrder() {
      return this.getConfigStatus(SYS_CONFIG.allowModifyArrangeOrder);
    },
    // 整团审核后修改团款、代收、返款
    allowModifyMoney() {
      return this.getConfigStatus(SYS_CONFIG.allowModifyMoney);
    },
    // 返款是否冲抵应收款
    refundNotOffset() {
      return !this.getConfigStatus(SYS_CONFIG.refundNotOffset);
    },
    // 整团审核后修改接送
    allowModifyOrderPickup() {
      return this.getConfigStatus(SYS_CONFIG.allowModifyOrderPickup);
    },
    // 是否自动生成返款
    autoGenerateRefund() {
      return this.getConfigStatus(SYS_CONFIG.autoGenerateRefund);
    },
    // 开启钉钉借款申请审核
    borrowApplyDingDingEnable() {
      return this.getConfigStatus(SYS_CONFIG.borrowApplyDingDingEnable);
    },
    hasOrderInfo() {
      return this.getConfigStatus(SYS_CONFIG.hasOrderInfo);
    },
    // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
    needOPAudit() {
      return this.getConfigStatus(SYS_CONFIG.needOPAudit);
    },
    // 审核底价显示配置
    auditFinalPrice() {
      return this.getConfigStatus(SYS_CONFIG.auditFinalPrice);
    },
    // 景区加点 导佣算法
    isDefScenicAddAlgorithm() {
      return this.getConfigStatus(SYS_CONFIG.scenicAddAlgorithm1);
    },
    // 应收对账，整团审核后允许新增、修改订单返款
    collectAuditedAllowChange() {
      return this.getConfigStatus(SYS_CONFIG.collectAuditedAllowChange);
    }
  },
  created() {
    // 是否保留两位小数
    this.keepTwoDecimal = this.getConfigStatus(SYS_CONFIG.keepTwoDecimal);
  },
  methods: {
    getConfigStatus(codestr) {
      const list = this.roleData;
      if (!list || !list.length) return;
      const findItem = list.find(it => it.key == codestr);
      return findItem && findItem.val;
    },
    // 是否保留两位小数
    isDecimalFormat(val) {
      if (this.keepTwoDecimal) {
        return isDecimal(val) ? Number(val).toFixed(2) : Number(val);
      } else {
        return Number(val);
      }
    },
    // 是否保留两位小数
    isDecimalMoneyFormat(val) {
      if (this.keepTwoDecimal) {
        return isDecimal(val) ? Number(val).toFixed(2) : Number(val);
      } else {
        return Math.round(val);
      }
    }
  }
};