const typeOptions = [{
  label: '待内转接收',
  checked: false
}, {
  label: '待外转接收',
  checked: false
}, {
  label: '待拼团',
  checked: false
}, {
  label: '待确认成团',
  checked: false
}, {
  label: '待发团',
  checked: false
}, {
  label: '房控待安排',
  checked: false
}, {
  label: '车控待安排',
  checked: false
}, {
  label: '待审核',
  checked: false
}, {
  label: '待归档',
  checked: false
}, {
  label: '内转修改申请',
  checked: false
}, {
  label: '餐控待安排',
  checked: false
}, {
  label: '导控待安排',
  checked: false
}, {
  label: '票控待安排',
  checked: false
}, {
  label: '险控待安排',
  checked: false
}, {
  label: '房调待安排',
  checked: false
}, {
  label: '车调待安排',
  checked: false
}, {
  label: '审批待通过',
  checked: false
}, {
  label: '审批已退回',
  checked: false
}, {
  label: '大交通控待安排',
  checked: false
}, {
  label: '审批待下账',
  checked: false
}, {
  label: '订单待审核',
  checked: false
}];
export default {
  data() {
    return {
      isEdit: false,
      options: typeOptions,
      currentVal: []
    };
  },
  methods: {
    handleChange(val) {
      if (typeof val === 'string') {
        this.currentVal = val === 'all' ? this.options : [];
      } else {
        this.currentVal = val;
      }
    }
  }
};