import "core-js/modules/es.array.push.js";
import DialogFix from './DialogFix';
import { Audit, AuditCheck, AuditAdmin, DelCheck, DelCheckAll, AuditRoleAll, PaymentTotal, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectHotel, SelectPayType, SelectPlanNumber } from '@/components/common';
import { PersonCount, Members } from '@/components/common';
import DialogAreaStandard from './DialogAreaStandard';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, permissionMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import handleClipboard from '@/utils/clipboard';
import { mergeTableRow, clacMergeRowByRowId, objectSpanMethod } from '@/utils';
import { ROOM_LABEL_KEYS, itemListKeys, defaultState } from './config';
// import { PAY_TYPE } from '@/config'

export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    DelCheck,
    DelCheckAll,
    AuditRoleAll,
    StatusAudit,
    SelectHotel,
    SelectPayType,
    PersonCount,
    Members,
    PaymentTotal,
    DialogAreaStandard,
    SelectPlanNumber,
    AuditStatus,
    CheckStatus,
    DialogFix
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, permissionMixin, systemMixin],
  props: {
    data: {
      type: Array,
      required: true
    },
    planId: {
      type: [String, Number],
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isAudit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fetchPath: 'plan/hotel/fetch',
      deletePath: 'plan/hotel/delete',
      updatePath: 'plan/hotel/update',
      headMergeNum: 100,
      tailMergeNum: 1,
      // 表格的格子数
      hasMergeTail: true,
      rowId: 1000000000000,
      totalMoney: 0,
      // 总金额
      removeCache: [],
      // 要删除的数据
      roomList: [],
      auditAll: false,
      totalBtnShow: false,
      tableDataNumber: [],
      tableData: [],
      statisticsKeys: ['busFee', 'otherFee', 'rowTotal'],
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      planOrderData: 'plan/index/planOrderData'
    }),
    formatStandard() {
      return row => {
        const area = row.hotelArea.split('|')[0];
        const standard = row.hotelStandard.split('|')[0];
        return `${area}${area ? '/' : ''}${standard}`;
      };
    },
    getRoomInfo() {
      return room => {
        if (!room) return;
        return Object.keys(room).filter(key => room[key] > 0).map(key => {
          const val = room[key];
          return val + ROOM_LABEL_KEYS[key];
        }).join('');
      };
    },
    getData() {
      return this.tableData;
    }
  },
  watch: {
    data: {
      handler: function (list) {
        this.visible = false;
        if (!list || !list.length) {
          this.visible = true;
          return;
        }
        this.tableData = this.handleMergeTable(this.formatData(list));

        // 是否显示订单信息
        this.$nextTick(() => {
          // const hotelFloorPrice = this.hasPermission('hotelFloorPrice')
          // const hotelFloorPriceAudit = this.hasPermission(
          //   'hotelFloorPriceAudit'
          // )

          // this.tailMergeNum = !hotelFloorPrice ? 11 : 12
          // this.tailMergeNum = !hotelFloorPriceAudit ? 11 : 12
          // // 如果不显示订单信息
          // if (!this.hasOrderInfo) {
          //   this.headMergeNum = 5
          //   this.tailMergeNum = !hotelFloorPrice ? 9 : 10
          //   this.tailMergeNum = !hotelFloorPriceAudit ? 9 : 10
          // }
          this.resetData();
          this.visible = true;
        });
      },
      immediate: true
    }
  },
  methods: {
    objectSpanMethod,
    enterpriseFixDialog(row, column) {
      if (column.property == 'rowTotal' && row.payType == 'alipay_e_code' && row.alipayPeerPayAmount != row.rowTotal) {
        this.$refs.dialogFixRef.show({
          ...row
        });
      }
    },
    handleMergeTable(list) {
      return mergeTableRow(list, ['auditAndCheckStatus', 'planInfo', 'members', 'hotelStandard', 'hotelName', 'date', 'rowTotal', 'payType', 'outOPUserName', 'remark', 'action']);
    },
    onMembers(row) {
      this.$emit('on-members', row);
    },
    fetchData() {
      this.$parent.fetchData();
    },
    onAdminSelect(row, val) {
      row.auditProgress = val;
    },
    showAreaStandard(row) {
      this.$refs.areaStandardRef.show(row);
    },
    handleRemoveAdapt(row) {
      this.handleRemove(row);
    },
    handleCopy(text, event) {
      if (!text) return;
      handleClipboard(text, event);
    },
    // 获取酒店协议
    fetchHotelAgreement(hotelId) {
      return this.$store.dispatch('resource/hotel/fetchAgreementTypeList', hotelId).then(data => {
        if (!data || !data.length) return [];
        const {
          contractPrices
        } = data[0];
        const roomList = this.formatRoomOptions(contractPrices);
        return roomList;
      });
    },
    // 当前添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      const {
        id
      } = row;
      let {
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/hotel/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          rowId: row.rowId,
          mergeId: row.rowId,
          otherFee: 0,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
        this.tableData = this.handleMergeTable(this.tableData);
      }
      this.checkSelectAll();
      // row.totalPay = this.calcRowTotalMoney(row)
      // 重新计算当前行合并后的价格
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    // 新增一条数据
    handlePlus() {
      if (!this.tableData.length) {
        this.visible = false;
      }
      this.$nextTick(() => {
        const last = this.tableData[this.tableData.length - 1];
        const orderPeople = last ? last.orderPeople : {};
        const firstPlanOrder = this.planOrderData ? this.planOrderData[0] : {
          customerTripNumber: '',
          outOPUserId: '',
          outOPUserName: ''
        };
        const item = {
          ...defaultState(),
          rowId: ++this.rowId,
          planId: this.currentPlanId,
          count: this.planInfo.adultCount || 1,
          orderPeople: {
            ...orderPeople,
            outOPUserId: firstPlanOrder.outOPUserId,
            outOPUserName: firstPlanOrder.outOPUserName
          },
          customerOrderId: firstPlanOrder.id,
          rowTotal: 0
        };
        this.tableData.push(item);
        this.visible = true;
        // this.rowspan()
        this.checkSelectAll();
        // 设置灯泡状态
        this.toggleDataLenStatus();
        this.visible = true;
      });
    },
    selectHotel(row, val) {
      const {
        rowId
      } = row;
      const {
        id,
        label
      } = val;
      const findItem = this.tableData.find(item => item.rowId == rowId);
      findItem.hotelId = id;
      findItem.hotelName = label;
      // 默认第一个协议
      this.setHotelContent(id, findItem);
    },
    setHotelContent(hotelId, findItem) {
      this.fetchHotelAgreement(hotelId).then(data => {
        if (!data.length) {
          this.showNotice();
          return;
        }
        // settlementPrice
        const {
          quotedPrice,
          contentType
        } = data[0];
        findItem.roomType = contentType;
        findItem.quotePrice = quotedPrice;
        findItem.settlementPrice = quotedPrice;
        findItem.roomTypeAndPrices = data;
      });
    },
    // 选择选择房型
    selectRoom(row, val) {
      // settlementPrice
      const {
        label,
        quotedPrice
      } = val;
      row.roomType = label;
      row.quotePrice = quotedPrice;
      row.settlementPrice = quotedPrice;
      // row.totalPay = this.calcRowTotalMoney(row)

      // 重新计算当前行合并后的价格
      this.$nextTick(() => {
        // this.mergeRowMoney()
        // this.getTotalPayMoney()
      });
    },
    showNotice() {
      this.$bus.tip({
        type: 'warning',
        message: '该酒店还未添加任何房型'
      });
    },
    formatRoomOptions(list) {
      if (!list) return;
      return list.map(it => {
        return {
          ...it,
          label: it.contentType,
          value: it.id,
          settlementPrice: it.settlementPrice || it.price
        };
      });
    },
    formatRoom(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const label = it.contentType.indexOf('|') > -1 ? it.contentType.split('|')[0] : it.contentType;
        const {
          id,
          contentType: roomType,
          quotedPrice,
          price
        } = it;
        return {
          roomType,
          label,
          name: label,
          price,
          id,
          value: id,
          quotedPrice,
          ...it
        };
      });
    },
    beforeSelectRoom(row, val) {
      if (!val) return;
      if (!row.hotelId) {
        this.$bus.tip({
          type: 'warning',
          message: '请先选择酒店！'
        });
      } else {
        if (!row.roomTypeAndPrices || !row.roomTypeAndPrices.length) {
          this.fetchHotelAgreement(row.hotelId).then(data => {
            if (!data.length) {
              this.showNotice();
              return;
            }
            row.roomTypeAndPrices = data;
          });
        }
      }
    },
    changePayType(row, val) {
      row.payType = val;
    },
    // 校验输入的值
    changeInput(item) {
      // 优化后的计算方法
      item.rowTotal = clacMergeRowByRowId({
        data: this.tableData,
        rowId: item.rowId,
        priceKey: 'quotePrice'
      });
      // 重新计算当前行合并后的价格
      // this.$nextTick(() => {
      // this.getTotalPayMoney()
      // this.mergeRowMoney()
      // })
    },
    // 单价修改
    changePrice(row, val) {
      // const { cashType } = row
      // // 单价 修改时 结算价等于单价
      // const type = cashType.toUpperCase()
      // if (
      //   type == PAY_TYPE.GUIDE ||
      //   type == PAY_TYPE.GUIDESIGN ||
      //   type == PAY_TYPE.ALIPAY_E_CODE
      // ) {
      //   row.settlementPrice = val
      // }
      row.settlementPrice = val;
      this.changeInput(row);
    },
    // 计算总额
    calcRowTotalMoney(row) {
      let {
        quotePrice,
        count,
        freeCount,
        otherFee
      } = row;
      const unit = 100;
      count = count || 0;
      otherFee = otherFee || 0;
      freeCount = freeCount || 0;
      const res = Number(quotePrice) * unit * Number(count - freeCount) + Number(otherFee) * unit;
      return res / unit;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.roomList || [];
        it.roomTypeAndPrices = this.formatRoom(it.roomTypeAndPrices);
        it.hotelId = it.hotelId <= 0 ? '' : it.hotelId;
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          mergeId: id,
          planHotelId: id,
          isCustom: false,
          isChecked: false,
          itemIndex: 0,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          cashType: it.cashType.toLowerCase(),
          payType: it.cashType.toLowerCase(),
          rowTotal: it.totalPay
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcRowTotalMoney(v);
            result.push({
              ...item,
              ...v,
              totalSum,
              itemIndex: i
              // totalPay: totalSum,
            });
          });
        } else {
          // 把初始化其他字段
          result.push({
            ...item,
            id: -1,
            ...itemListKeys()
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    clearObjKey(obj, item) {
      const customKeys = ['id', 'planId'];
      const auditKeys = ['auditProgress', 'isAudit', 'status'];
      const keys = [...Object.keys(itemListKeys())];
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'planHotelId'];
      const doDel = keys => {
        keys.forEach(key => {
          delete obj[key];
        });
      };
      doDel(keys);
      doDel(delKeys);
      if (!this.isAudit) {
        doDel(auditKeys);
      }

      // 新增数据，删除id
      if (obj.isCustom) {
        doDel(customKeys);
        delete item.id;
      }
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      // const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'planHotelId']

      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;
        // 定义子项目的列表
        it.roomList = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          // delete it[key]
        });

        // if (!this.isAudit) {
        //   delete it.auditProgress
        //   delete it.isAudit
        //   delete it.status
        // }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.planHotelId;
        it.cashType = it.cashType.toUpperCase();

        // // 新增数据，删除id
        // if (it.isCustom) {
        //   delete it.id
        //   delete it.planHotelId
        //   delete item.id
        // }

        // // 删除多余的字段
        // delKeys.forEach(key => {
        //   delete it[key]
        // })

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.roomList.push(item);
        } else {
          it.roomList.push(item);
          result.push(it);
          last = it;
        }
        this.clearObjKey(it, item);
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          orderDate,
          customerOrderId
        } = row;
        if (!customerOrderId || customerOrderId < 1) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择小团号或者订单号!'
          });
          return false;
        }
        if (!orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择入住时间！'
          });
          return false;
        }
      }
      return true;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      // this.tableData.forEach(it => {
      //   it.totalPay = this.calcRowTotalMoney(it)
      // })
    },
    // 重置表格与价格计算
    resetData() {
      // this.rowspan()
      // this.mergeRowMoney() // 合并单条数据总价
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.hotel,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};