export default {
  name: 'JtxSidebar',
  data() {
    return {
      leftText: [{
        conten: '<div class="left-title"><p>企业</p><p>使命</p></div><div class="right-content">为追求全体员工物质与精神两方面幸福·奋斗不息<br />用爱·让桂林山水因我而更美丽</div>'
      }, {
        conten: '<div class="left-title"><p>企业</p><p>愿景</p></div><div class="right-content">成为奋斗者的幸福家园<br />成为客户信赖、社会尊敬的旅游行业引领者</div>'
      }, {
        conten: '<div class="left-title"><p>企业</p><p>价值观</p></div><div class="right-content"><span>客户至上</span><span>共享协作</span><span>勇于担当</span><br /><span>学习创新</span><span>诚信天下</span><span>品质为金</span></div>'
      }, {
        conten: '<div class="left-title"><p>管理</p><p>理念</p></div><div class="right-content flex-center"><div><span>用心沟通</span><span>按流程做事</span><span>拿数据说话</span></div></div>'
      }, {
        conten: '<div class="left-title"><p>人才</p><p>理念</p></div><div class="right-content"><span>以制度管人</span><span>以标准选人</span><br /><span>以结果用人</span><span>以文化留人</span></div>'
      }, {
        title: '<p>工作</p><p>作风</p>',
        conten: '<div class="left-title"><p>工作</p><p>作风</p></div> <div class="right-content flex-center"><div>认真、迅速、工作无借口、全力以赴</div></div>'
      }]
    };
  }
};