var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "guide-borrow"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "guideName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideName) + " ")];
      }
    }, {
      key: "borrowMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.borrowMoney) + " ")];
      }
    }, {
      key: "borrowType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.borrowType) + " ")];
      }
    }, {
      key: "bankId",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.bankId) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remarks) + " ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };