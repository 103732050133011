var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "制度详情",
      width: "1200px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "effective",
      label: "状态",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.getStatus(scope.row.effective) ? "success" : "danger"
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatus(scope.row.effective) ? "启用" : "禁用") + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "startDate",
      label: "生效时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "endDate",
      label: "到期时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "institutionId",
      label: "制度ID",
      width: "200"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "standardId",
      label: "规则ID",
      width: "200"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "standardName",
      label: "规则名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "明细"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.standardConditionInfoList, function (item, index) {
          return _c("div", {
            key: index
          }, [_c("p", [_vm._v(_vm._s(item.ruleId))]), _c("p", [_vm._v(_vm._s(item.ruleName))]), _c("p", [_vm._v(_vm._s(item.ruleValue))]), _c("p", [_vm._v(_vm._s(item.ruleFactor))])]);
        });
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };