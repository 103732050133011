import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 审核管理
import Layout from '@/layout';
const auditRouter = {
  path: '/ticket',
  component: Layout,
  redirect: '/ticket/ticket-input',
  name: 'ticket',
  meta: {
    title: 'ticket',
    icon: 'icon-jiesuanzhongxin'
  },
  children: [{
    path: '/ticket/ticket-input',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/ticketInput'))),
    name: 'ticketInput',
    // 签单录入列表
    meta: {
      title: 'ticketInput',
      icon: 'icon-text'
    }
  }, {
    path: '/ticket/ticket-check',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/ticketCheckout'))),
    props: {
      type: 'audit'
    },
    name: 'ticketCheckout',
    // 签单核销列表
    meta: {
      title: 'ticketCheckout',
      icon: 'icon-hetongguanli'
    }
  }, {
    path: '/ticket/ticket-manage',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/manage/index'))),
    name: 'ticketManage',
    // 签单管理列表
    meta: {
      title: 'ticketManage',
      icon: 'icon-baojiadanguanli'
    }
  }, {
    path: '/ticket/ticket-search',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/ticketSearch/index'))),
    name: 'ticketSearch',
    // 票据查询
    meta: {
      title: 'ticketSearch',
      icon: 'icon-zijinchizhangbumingxichaxun'
    }
  }, {
    path: '/ticket/ticket-input/detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/ticketInputDetail'))),
    name: 'ticketInputDetail',
    // 签单录入详情
    meta: {
      title: 'ticketInputDetail',
      icon: 'icon-text',
      auth: true
    },
    hidden: true
  }, {
    path: '/ticket/ticket-check/detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/ticket/ticketCheckDetail'))),
    name: 'ticketCheckDetail',
    // 签单核销详情
    meta: {
      title: 'ticketCheckDetail',
      icon: 'icon-text',
      auth: true
    },
    hidden: true
  }]
};
export default auditRouter;