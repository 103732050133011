export const RebateType = {
  member: 'MEMBER',
  // 人头
  agency: 'AGENCY',
  // 社佣
  park: 'PARK',
  redbag: 'RED',
  // 红包
  subsidy: 'SUBSIDY' // 二返
};
const width40 = 40;
const width50 = 50;
const width70 = 70;
const width60 = 60;
const width80 = 80;
const width100 = 100;
const width120 = 120;
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 100,
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
};
const totalShopRebate = {
  attrs: {
    prop: 'totalShopRebate',
    label: '公司收益',
    sortable: true
  }
};
const indexSlot = {
  attrs: {
    prop: 'index',
    label: '序号',
    width: 60
  },
  slot: 'index'
};
const totalShopRebateSlot = {
  ...totalShopRebate,
  slot: 'totalShopRebate'
};
const totalGuideManager = {
  attrs: {
    prop: 'totalGuideManagerRebate',
    label: '导管收益',
    sortable: true
  }
};
const totalGuideManagerRebate = {
  ...totalGuideManager,
  slot: 'totalGuideManagerRebate'
};
const shopPerCapita = {
  attrs: {
    prop: 'shopPerCapita',
    label: '总购物人均',
    sortable: true
  }
};
const shopPerCapitaSlot = {
  ...shopPerCapita,
  slot: 'shopPerCapita'
};
const totalBuyMoney = {
  attrs: {
    prop: 'totalBuyMoney',
    label: '打单金额',
    sortable: true
  }
};
const totalBuyMoneySlot = {
  ...totalBuyMoney,
  slot: 'totalBuyMoney'
};
const totalGuideRebate = {
  attrs: {
    prop: 'totalGuideRebate',
    label: '导游收益',
    sortable: true
  }
};
const adultCount = {
  attrs: {
    prop: 'adultCount',
    label: '人数',
    sortable: true
  }
};
const totalHeadRebate = {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头返佣',
    sortable: true
  }
};
const plans = {
  attrs: {
    prop: 'plans',
    label: '团数',
    sortable: true
  }
};
const customerName = {
  attrs: {
    prop: 'customerName',
    label: '组团社',
    sortable: true
  }
};
const guideName = {
  attrs: {
    prop: 'guideName',
    label: '导游',
    sortable: true
  }
};
const planCustomerNumber = {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    width: 160,
    sortable: true
  }
};
const customerNameSlot = {
  ...customerName,
  slot: 'customerName'
};
const remark = {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
};
const remarkSlot = {
  ...remark,
  slot: 'remark'
};
// 购物收款
export const shoppingCollectListColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '收款日期',
    'min-width': width100
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账号',
    'min-width': 240
  }
}, {
  attrs: {
    prop: 'money',
    label: '下账金额'
  }
}, {
  attrs: {
    prop: 'invoiceMoney',
    label: '发票金额'
  }
}, {
  attrs: {
    prop: 'invoiceNumber',
    label: '发票编码'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '收款人'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, actionItem];
export const shoppingCollectColumn = [planCustomerNumber, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '购物店'
  }
}, {
  attrs: {
    prop: 'title',
    label: '导游'
  },
  slot: 'guides'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期'
  }
}, {
  attrs: {
    prop: '',
    label: '应收'
  },
  children: [{
    attrs: {
      prop: 'memberRebate',
      label: '人头佣金'
    }
  }, {
    attrs: {
      prop: 'shopRebate',
      label: '社佣'
    }
  }, {
    attrs: {
      prop: 'totalRedEnvelope',
      label: '红包'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'memberRebated',
      label: '人头佣金'
    }
  }, {
    attrs: {
      prop: 'guideCollect',
      label: '导游代收'
    }
  }, {
    attrs: {
      prop: 'shopRebated',
      label: '社佣'
    }
  }, {
    attrs: {
      prop: 'totalRedEnvelopeReimbed',
      label: '红包'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '欠收'
  },
  children: [{
    attrs: {
      prop: 'unMemberRebated',
      label: '人头佣金'
    }
  }, {
    attrs: {
      prop: 'unShopRebated',
      label: '社佣'
    }
  }, {
    attrs: {
      prop: 'unRedEnvelopeRebated',
      label: '红包'
    }
  }]
}, {
  attrs: {
    prop: 'downPayed',
    label: '本次下账'
  },
  slot: 'downPayed'
}, actionItem];
export const shoppingCollectSubsidy = [planCustomerNumber, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endTripDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '购物店'
  }
}, {
  attrs: {
    prop: 'title',
    label: '导游'
  },
  slot: 'guides'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期'
  }
}, {
  attrs: {
    prop: 'subsidy',
    label: '二返应收'
  }
}, {
  attrs: {
    prop: 'subsidyKP',
    label: '二返已收'
  }
}, {
  attrs: {
    prop: 'unSubsidyKP',
    label: '二返欠收'
  }
}, {
  attrs: {
    prop: 'downPayed',
    label: '本次销账'
  },
  slot: 'downPayed'
}, actionItem];

// 按小团统计
// 客户	线路产品	小团号	客源地	人数	打单金额	银饰人均	乳胶人均	厨具人均	丝绸人均	抱璞人均	总人均
export const smallTeamData = [{
  attrs: {
    prop: 'custom',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'line',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'numberSmall',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'location',
    label: '客源地'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'price',
    label: '打单金额'
  }
}, {
  attrs: {
    prop: 'ysAverage',
    label: '银饰人均'
  }
}, {
  attrs: {
    prop: 'rjAverage',
    label: '乳胶人均'
  }
}, {
  attrs: {
    prop: 'cjAverage',
    label: '厨具人均'
  }
}, {
  attrs: {
    prop: 'scAverage',
    label: '丝绸人均'
  }
}, {
  attrs: {
    prop: 'bpAverage',
    label: '抱璞人均'
  }
}, {
  attrs: {
    prop: 'average',
    label: '总人均',
    sortable: true
  }
}];

// 按组团统计
// 客户	团数	人数	打单金额	银饰人均	乳胶人均	厨具人均	丝绸人均	抱璞人均	总人均
export const teamData = [{
  attrs: {
    prop: 'custom',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'teamCount',
    label: '团数'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'price',
    label: '打单金额'
  }
}, {
  attrs: {
    prop: 'ysAverage',
    label: '银饰人均'
  }
}, {
  attrs: {
    prop: 'rjAverage',
    label: '乳胶人均'
  }
}, {
  attrs: {
    prop: 'cjAverage',
    label: '厨具人均'
  }
}, {
  attrs: {
    prop: 'scAverage',
    label: '丝绸人均'
  }
}, {
  attrs: {
    prop: 'bpAverage',
    label: '抱璞人均'
  }
}, {
  attrs: {
    prop: 'average',
    label: '总人均',
    sortable: true
  }
}];

// 按客户利润
// 客户	团数	人数	团款分析	欠款分析	购物分析	总收入	总成本	毛利	人均毛利	毛利率
export const customProfitData = [{
  attrs: {
    prop: 'custom',
    label: '客户',
    width: 200
  }
}, {
  attrs: {
    prop: 'teamCount',
    label: '团数'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: '',
    label: '团款分析'
  },
  children: [{
    attrs: {
      prop: 'teamFundShould',
      label: '应收团款'
    }
  }, {
    attrs: {
      prop: 'teamFundPercent',
      label: '人均团款'
    }
  }, {
    attrs: {
      prop: 'teamFundDone',
      label: '已收团款'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '欠款分析'
  },
  children: [{
    attrs: {
      prop: 'income',
      label: '欠收'
    }
  }, {
    attrs: {
      prop: 'debtRatio',
      label: '欠款率'
    }
  }, {
    attrs: {
      prop: 'incomePercent',
      label: '占总欠比'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '购物分析'
  },
  children: [{
    attrs: {
      prop: 'shoppingMoney',
      label: '购物金额'
    }
  }, {
    attrs: {
      prop: 'shoppingProfit',
      label: '进店收益'
    }
  }, {
    attrs: {
      prop: 'shoppingAverage',
      label: '人均'
    }
  }]
}, {
  attrs: {
    sortable: true,
    prop: 'totalIncome',
    label: '总收入'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'totalCost',
    label: '总成本'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'grossProfit',
    label: '毛利'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'averageProfit',
    label: '人均毛利'
  }
}, {
  attrs: {
    fixed: 'right',
    sortable: true,
    prop: 'percentProfit',
    label: '毛利率'
  }
}];

// 按单团利润
// 大团号	线路产品	发团日期	散团日期	人数	总打单金额	人均打单金额
export const singleProfitData = [{
  attrs: {
    prop: 'teamNumber',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'line',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'teamDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'personDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'totalMoney',
    label: '总打单金额'
  }
}, {
  attrs: {
    prop: 'averageMoney',
    label: '人均打单金额'
  }
}];

// 按商品统计
// 购物店	进店人次	团数	人数	停车返佣	人头返款	总金额	人均购物	社佣	应收	已收	欠收	购物项目	打单金额	社佣
// 导佣金额	司佣金额	全陪金额	导管金额	导游代收
// export const goodsData = [
//   { attrs: { prop: '', label: '购物店' } },
//   { attrs: { prop: '', label: '进店人次' } },
//   { attrs: { prop: '', label: '团数' } },
//   { attrs: { prop: '', label: '人数' } },
//   { attrs: { prop: '', label: '停车返佣' } },
//   { attrs: { prop: '', label: '人头返款' } },
//   { attrs: { prop: '', label: '总金额' } },
//   { attrs: { prop: '', label: '人均购物' } },
//   { attrs: { prop: '', label: '社佣' } },
//   { attrs: { prop: '', label: '应收' } },
//   { attrs: { prop: '', label: '已收' } },
//   { attrs: { prop: '', label: '欠收' } },
//   { attrs: { prop: '', label: '购物项目' } },
//   { attrs: { prop: '', label: '打单金额' } },
//   { attrs: { prop: '', label: '社佣' } },
//   { attrs: { prop: '', label: '导佣金额' } },
//   { attrs: { prop: '', label: '司佣金额' } },
//   { attrs: { prop: '', label: '全陪金额' } },
//   { attrs: { prop: '', label: '导管金额' } },
//   { attrs: { prop: '', label: '导游代收' } },
// ]

// 按购物收款
// 客户	团数	人数	打单金额	银饰人均	乳胶人均	厨具人均	丝绸人均	抱璞人均	总人均
export const shopCollectData = [{
  attrs: {
    sortable: true,
    prop: 'custom',
    label: '客户'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'teamCount',
    label: '团数'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'price',
    label: '打单金额'
  }
}, {
  attrs: {
    prop: 'ysAverage',
    label: '银饰人均'
  }
}, {
  attrs: {
    prop: 'rjAverage',
    label: '乳胶人均'
  }
}, {
  attrs: {
    prop: 'cjAverage',
    label: '厨具人均'
  }
}, {
  attrs: {
    prop: 'scAverage',
    label: '丝绸人均'
  }
}, {
  attrs: {
    prop: 'bpAverage',
    label: '抱璞人均'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'average',
    label: '总人均'
  }
}];

// 购物审核
// 大团号	线路产品	发团日期	散团日期	团上导游	人数	责任计调	团状态	购物店	购物总额	导游	进店日期	审核状态	审核
export const shoppingAudit = [{
  attrs: {
    prop: 'teamNumberBig',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'line',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'teamDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'personalDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'guideMain',
    label: '团上导游'
  }
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'plan',
    label: '责任计调'
  }
}, {
  attrs: {
    prop: 'teamState',
    label: '团状态'
  }
}, {
  attrs: {
    prop: 'shop',
    label: '购物店'
  }
}, {
  attrs: {
    prop: 'shoppingTotal',
    label: '购物总额'
  }
}, {
  attrs: {
    prop: 'guide',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'shoppingDate',
    label: '进店日期'
  }
}, {
  attrs: {
    sortable: true,
    prop: 'auditState',
    label: '审核状态'
  }
}];

// 购物财务
// 大团号	发团日期	散团日期	团上导游	进店人数	责任计调	购物店	购物总额	购物均额	导游	进店日期	应收	已收	欠收	明细	社利	总社利
export const shoppingFinance = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    width: 200
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'date',
    label: '发团日期',
    width: 120
  },
  slot: 'date'
}, guideName, {
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数'
  }
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '购物店'
  }
}, {
  attrs: {
    prop: 'totalBuyMoney',
    label: '购物总额'
  }
}, {
  attrs: {
    prop: 'shopPerCapita',
    label: '购物均额'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期'
  }
}, {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头费'
  }
}, {
  attrs: {
    prop: 'detail',
    label: '明细'
  },
  children: [
  // 名称	打单金额	社佣金额	导佣金额	司佣金额	全陪金额	导管金额	导游代收
  {
    attrs: {
      prop: 'name',
      label: '名称'
    }
  }, {
    attrs: {
      prop: 'cashFlow',
      label: '流水'
    }
  }, {
    attrs: {
      prop: 'money',
      label: '打单金额'
    }
  }, {
    attrs: {
      prop: 'goodShopRebate',
      label: '社佣金额'
    }
  }, {
    attrs: {
      prop: 'redEnvelope',
      label: '红包'
    }
  }, {
    attrs: {
      prop: 'guideRebate',
      label: '导佣金额'
    }
  }, {
    attrs: {
      prop: 'driverRebate',
      label: '司佣金额'
    }
  }, {
    attrs: {
      prop: 'accompanyRebate',
      label: '全陪金额'
    }
  }, {
    attrs: {
      prop: 'guideManagerRebate',
      label: '导管金额'
    }
  }, {
    attrs: {
      prop: 'guideCollect',
      label: '导游代收'
    }
  }]
}, {
  attrs: {
    prop: 'shopRebate',
    label: '社利'
  }
}, {
  attrs: {
    prop: 'totalShopRebate',
    label: '总社利'
  }
}];
export const shoppingFinanceAll = [...shoppingFinance.slice(0, -2), {
  attrs: {
    prop: 'subsidy',
    label: '二返'
  }
}, {
  attrs: {
    prop: 'shopRebate',
    label: '社利'
  }
}, {
  attrs: {
    prop: 'totalShopRebate',
    label: '总社利'
  }
}];

// 购物对账
// 订单信息	发团日期	散团日期	线路产品	计划人数	报账导游	进店人数	购物店	进店日期	购物总额	应收	已收	未收	安排备注	责任计调	对账金额	对账时间	对账人	备注	审核状态	操作

// export const shoppingCheck = [
//   { attrs: { prop: '', label: '订单信息' } },
//   { attrs: { prop: '', label: '发团日期' } },
//   { attrs: { prop: '', label: '散团日期' } },
//   { attrs: { prop: '', label: '线路产品' } },
//   { attrs: { prop: '', label: '计划人数' } },
//   { attrs: { prop: '', label: '报账导游' } },
//   { attrs: { prop: '', label: '进店人数' } },
//   { attrs: { prop: '', label: '购物店' } },
//   { attrs: { prop: '', label: '进店日期' } },
//   { attrs: { prop: '', label: '购物总额' } },
//   { attrs: { prop: '', label: '应收' } },
//   { attrs: { prop: '', label: '已收' } },
//   { attrs: { prop: '', label: '未收' } },
//   { attrs: { prop: '', label: '安排备注' } },
//   { attrs: { prop: '', label: '责任计调' } },
//   { attrs: { prop: '', label: '对账金额' } },
//   { attrs: { prop: '', label: '对账时间' } },
//   { attrs: { prop: '', label: '对账人' } },
//   { attrs: { prop: '', label: '备注' } },
//   { attrs: { prop: '', label: '审核状态' } },
// ]

// 购物录入
// 大团号	线路产品	发团日期	散团日期	团上导游	人数	责任计调	购物店	购物总额	导游	进店日期	商品	备注	删除
export const shoppingInput = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'productName',
    label: '线路产品',
    sortable: true
  }
}, {
  attrs: {
    prop: 'tripDate',
    label: '行程日期',
    sortable: true
  },
  slot: 'tripDate'
}, {
  attrs: {
    prop: 'guideMain',
    label: '团上导游',
    sortable: true
  },
  slot: 'guides'
}, {
  attrs: {
    prop: 'count',
    label: '人数',
    width: width100,
    sortable: true
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调',
    width: width80
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '购物店',
    sortable: true
  },
  slot: 'shop'
}, {
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    width: width100,
    sortable: true
  },
  slot: 'enterStoreMemberCount'
}, totalBuyMoneySlot, {
  attrs: {
    prop: 'totalShopRebate',
    label: '社佣',
    width: width80,
    sortable: true
  },
  slot: 'totalShopRebate'
}, {
  attrs: {
    prop: 'totalGuideRebate',
    label: '导佣',
    width: width80,
    sortable: true
  },
  slot: 'totalGuideRebate'
}, {
  attrs: {
    prop: 'perCapita',
    label: '进店人均',
    width: width100,
    sortable: true
  },
  slot: 'perCapita'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期',
    sortable: true,
    width: width100
  }
}, {
  attrs: {
    prop: 'goods',
    label: '商品',
    width: width120
  },
  slot: 'goods'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: width80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 新增购物录入
export const shoppingInputCreate = [{
  attrs: {
    prop: 'shopName',
    label: '购物店',
    'min-width': width120
  },
  slot: 'shopName'
}, {
  attrs: {
    prop: 'orderDate',
    label: '进店日期',
    'min-width': width120
  },
  slot: 'orderDate'
},
// { attrs: { prop: 'guide', label: '导游', 'min-width': 140 }, slot: 'guide' },
{
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    'min-width': width50
  },
  slot: 'enterCount'
}, {
  attrs: {
    prop: 'headPrice',
    label: '人头单价',
    'min-width': width60
  },
  slot: 'headPrice'
}, {
  attrs: {
    prop: 'totalHeadRebate',
    label: '人头返佣',
    'min-width': width60
  },
  slot: 'totalHeadRebate'
}, {
  attrs: {
    prop: 'goodsName',
    label: '商品名称',
    'min-width': width120
  },
  slot: 'goodsName'
}, {
  attrs: {
    prop: 'money',
    label: '打单金额',
    'min-width': width80
  },
  slot: 'money'
}, {
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': width120
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [{
    attrs: {
      prop: '',
      label: '社佣'
    },
    children: [{
      attrs: {
        prop: 'shopRate',
        label: '比例',
        'min-width': width40
      },
      slot: 'shopRate'
    }, {
      attrs: {
        prop: 'shopRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'shopRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '红包'
    },
    children: [{
      attrs: {
        prop: 'redEnvelope',
        label: '金额',
        'min-width': 60
      },
      slot: 'redEnvelope'
    }]
  }]
}, {
  attrs: {
    prop: 'pay',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: '',
      label: '导佣',
      'min-width': width40
    },
    children: [{
      attrs: {
        prop: 'guideRate',
        label: '比例',
        'min-width': width40
      },
      slot: 'guideRate'
    }, {
      attrs: {
        prop: 'guideRebateDeduction',
        label: '扣除',
        'min-width': width40
      },
      slot: 'guideRebateDeduction'
    }, {
      attrs: {
        prop: 'guideRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'guideRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '司佣'
    },
    children: [{
      attrs: {
        prop: 'driverRate',
        label: '比例',
        'min-width': width40
      },
      slot: 'driverRate'
    }, {
      attrs: {
        prop: 'driverRebateDeduction',
        label: '扣除',
        'min-width': width40
      },
      slot: 'driverRebateDeduction'
    }, {
      attrs: {
        prop: 'driverRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'driverRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '陪佣'
    },
    children: [{
      attrs: {
        prop: 'accompanyRate',
        label: '比例',
        'min-width': width40
      },
      slot: 'accompanyRate'
    }, {
      attrs: {
        prop: 'accompanyRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'accompanyRebate'
    }]
  }, {
    attrs: {
      prop: '',
      label: '导管佣金'
    },
    children: [{
      attrs: {
        prop: 'guideManagerRate',
        label: '比例',
        'min-width': width40
      },
      slot: 'guideManagerRate'
    }, {
      attrs: {
        prop: 'guideManagerRebate',
        label: '金额',
        'min-width': width50
      },
      slot: 'guideManagerRebate'
    }]
  }]
}, {
  attrs: {
    prop: 'travelGrossProfit',
    label: '社利',
    'min-width': width60
  },
  slot: 'travelGrossProfit'
}, {
  attrs: {
    prop: 'guideCollect',
    label: '导游代收',
    'min-width': width60
  },
  slot: 'guideCollect'
}, {
  attrs: {
    prop: 'shopRemark',
    label: '商品备注',
    'min-width': width60
  },
  slot: 'shopRemark'
}, remarkSlot, actionItem];
export const shoppingInputCreateAll = [...shoppingInputCreate.slice(0, -2), {
  attrs: {
    prop: 'subsidyRate',
    label: '二返比例',
    'min-width': width70
  },
  slot: 'subsidyRate'
}, {
  attrs: {
    prop: 'subsidy',
    label: '二返金额',
    'min-width': width70
  },
  slot: 'subsidy'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: width50
  },
  slot: 'action'
}];
export const shoppingInputDetail = [...shoppingInputCreate.slice(0, -1)];

// 大团统计
export const planShopStaticData = [indexSlot, {
  attrs: {
    prop: 'guideManagerName',
    label: '导管',
    width: 80,
    sortable: true
  }
}, guideName, {
  attrs: {
    prop: 'tripLine',
    label: '线路'
  },
  sortable: true
}, {
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    width: 160,
    sortable: true
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数',
    sortable: true
  }
}, {
  attrs: {
    prop: 'planType',
    label: '计划类型',
    sortable: true
  },
  slot: 'planType'
}, totalBuyMoneySlot, shopPerCapitaSlot, totalShopRebateSlot];

// 单导游数据分析表
export const planShopGuideStaticData = [indexSlot, guideName, {
  attrs: {
    prop: 'tripLine',
    label: '线路'
  },
  sortable: true
}, {
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    width: 160,
    sortable: true
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数',
    sortable: true
  }
}, totalBuyMoneySlot, shopPerCapitaSlot, totalGuideRebate];

// 小团统计
export const teamSmallShopStaticData = [indexSlot, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理',
    width: 80,
    sortable: true
  },
  slot: 'customerAreaManager'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服',
    width: 80,
    sortable: true
  }
}, {
  attrs: {
    prop: 'tripLine',
    label: '线路',
    sortable: true
  }
}, customerNameSlot, planCustomerNumber, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号',
    width: 160,
    sortable: true
  }
}, guideName, {
  attrs: {
    prop: 'source',
    label: '客人地域',
    width: 160,
    sortable: true
  }
}, {
  attrs: {
    prop: 'ageRange',
    label: '客人年龄',
    width: 80,
    sortable: true
  }
}, adultCount, totalBuyMoneySlot, shopPerCapitaSlot, totalShopRebateSlot, {
  attrs: {
    prop: 'unIncome',
    label: '剩余团款',
    sortable: true
  }
}];

// 单产品客户分析表
export const planShopProductData = [indexSlot, {
  attrs: {
    prop: 'productName',
    label: '产品',
    sortable: true
  }
}, customerNameSlot, planCustomerNumber, {
  attrs: {
    prop: 'planType',
    label: '团型'
  },
  slot: 'planType'
}, adultCount, totalBuyMoneySlot, shopPerCapitaSlot, totalShopRebateSlot];

// 单客户数据分析表
export const planCustomanalyzeData = [indexSlot, customerNameSlot, {
  attrs: {
    prop: 'planType',
    label: '团型'
  },
  slot: 'planType'
}, guideName, {
  attrs: {
    prop: 'productName',
    label: '产品',
    sortable: true
  }
}, planCustomerNumber, {
  attrs: {
    prop: 'source',
    label: '客人地域',
    width: 160,
    sortable: true
  }
}, {
  attrs: {
    prop: 'ageRange',
    label: '客人年龄',
    width: 80,
    sortable: true
  }
}, adultCount, totalBuyMoneySlot, shopPerCapitaSlot, totalShopRebateSlot];
// 区域客户分析表
export const planCustomAnalyzeArea = [indexSlot, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理',
    width: 80,
    sortable: true
  },
  slot: 'customerAreaManager'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服',
    width: 80,
    sortable: true
  }
}, {
  attrs: {
    prop: 'productName',
    label: '线路',
    sortable: true
  }
}, customerNameSlot, planCustomerNumber, {
  attrs: {
    prop: 'planType',
    label: '团型'
  },
  slot: 'planType'
}, guideName, {
  attrs: {
    prop: 'source',
    label: '客人地域',
    width: 160,
    sortable: true
  }
}, {
  attrs: {
    prop: 'ageRange',
    label: '客人年龄',
    width: 80,
    sortable: true
  }
}, adultCount, totalBuyMoneySlot, shopPerCapitaSlot, totalShopRebateSlot, {
  attrs: {
    prop: 'unIncome',
    label: '剩余团款',
    sortable: true
  }
}];

// 按地域统计
export const areaData = [{
  attrs: {
    prop: 'name',
    label: '区域',
    sortable: true
  }
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理',
    sortable: true
  }
}, plans, {
  attrs: {
    prop: 'orders',
    label: '订单数',
    sortable: true
  }
}, totalHeadRebate, adultCount, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate, totalGuideManager];

// 线路统计
export const lineData = [{
  attrs: {
    prop: 'name',
    label: '线路',
    sortable: true
  }
}, plans, adultCount, totalHeadRebate, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate, totalGuideManager];

// 店铺统计
// export const shopData = [
//   { attrs: { prop: 'name', label: '购物店', sortable: true } },
//   plans,
//   adultCount,
//   totalHeadRebate,
//   shopPerCapita,
//   totalBuyMoney,
//   totalShopRebate,
//   totalGuideRebate,
//   { attrs: { prop: 'totalAccompanyRebate', label: '全陪收益' } },
//   { attrs: { prop: 'totalDriverRebate', label: '司机收益' } },
// ]
export const shopData = [{
  attrs: {
    prop: 'orderNumber',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'name',
    label: '购物店',
    sortable: true
  }
}, plans, totalHeadRebate, adultCount, {
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数',
    sortable: true
  }
}, shopPerCapita, totalBuyMoney, {
  attrs: {
    prop: 'totalCashFlow',
    label: '流水',
    sortable: true
  }
}, totalShopRebate, totalGuideRebate, {
  attrs: {
    prop: 'totalAccompanyRebate',
    label: '全陪收益'
  }
}, {
  attrs: {
    prop: 'totalDriverRebate',
    label: '司机收益'
  }
}, totalGuideManagerRebate, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'totalReimbed',
    label: '已收'
  }
}, {
  attrs: {
    prop: 'totalUnIncome',
    label: '未收'
  }
}];
export const shopDataAll = [...shopData.slice(0, 9), {
  attrs: {
    prop: 'totalSubsidy',
    label: '二返收益'
  }
}, ...shopData.slice(9)];

// 客户统计
export const customData = [{
  attrs: {
    prop: 'name',
    label: '客户',
    sortable: true
  }
}, plans, {
  attrs: {
    prop: 'orders',
    label: '订单数',
    sortable: true
  }
}, adultCount, totalHeadRebate, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate, totalGuideManager];

// 导游统计
export const guideData = [{
  attrs: {
    prop: 'name',
    label: '导游',
    sortable: true
  }
}, plans, adultCount, totalHeadRebate, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利',
    sortable: true
  }
}];

// 导管统计
export const ByGuideManagerData = [{
  attrs: {
    prop: 'name',
    label: '导管',
    sortable: true
  }
}, plans, adultCount, totalHeadRebate, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate, totalGuideManager];

// 导管派团数据分析表
export const ByGuideManagerAnaylzeData = [indexSlot, {
  attrs: {
    prop: 'guideManagerName',
    label: '导管',
    sortable: true
  }
}, guideName, {
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    width: 200
  }
}, adultCount, {
  attrs: {
    prop: 'tripLine',
    label: '线路'
  }
}, totalBuyMoneySlot, shopPerCapitaSlot, totalGuideRebate, totalShopRebateSlot, totalGuideManagerRebate];

// 导游组长统计
export const ByGuideTeamData = [{
  attrs: {
    prop: 'name',
    label: '导游组长',
    sortable: true
  }
}, plans, adultCount, totalHeadRebate, totalBuyMoney, shopPerCapita, totalShopRebate, totalGuideRebate];

// 购物进店表
export const ShopEntryTable = [{
  attrs: {
    prop: 'index',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服'
  }
}, {
  attrs: {
    prop: 'source',
    label: '客源地'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'enterStoreMemberCount',
    label: '进店人数'
  }
}, {
  attrs: {
    prop: 'day',
    label: '顺序'
  }
}, {
  attrs: {
    prop: 'hotelName',
    label: '头一天酒店'
  }
}, {
  attrs: {
    prop: 'shopName',
    label: '店'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}];