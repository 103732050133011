import TotalTableList from './components/TotalTableList';
// import { BtnCreate } from '@/components/common'
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
export default {
  components: {
    TotalTableList
    // BtnCreate,
  },
  mixins: [searchMixin],
  data: () => ({
    searchParams: {
      name: '',
      endDate: '',
      startDate: '',
      subjectType: 'office'
    },
    columnData: [],
    tableData: []
  }),
  computed: {
    ...mapGetters({
      subjectWordData: 'system/accounting/subjectWordData'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startDate,
          endDate
        } = data;
        this.billDate = [startDate, endDate]; // 下账日期
        this.searchParams = data;
      },
      immediate: true
    },
    subjectWordData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    showCreateDialog() {},
    initData(data) {
      this.tableData = data;
    },
    onChangeDate(date) {
      const [start, end] = date;
      this.billDate = date;
      this.searchParams.endDate = end;
      this.searchParams.startDate = start;
    },
    fetchData(opst) {
      this.$store.dispatch('system/accounting/fetchListLevel', opst);
    }
  }
};