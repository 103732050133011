var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.resetForm
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectTraffic", {
    attrs: {
      "is-require": "",
      label: "大交通公司"
    },
    model: {
      value: _vm.form.transportId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "transportId", $$v);
      },
      expression: "form.transportId"
    }
  }), _c("SelectDictionary", {
    attrs: {
      "is-require": "",
      code: "ticketType",
      label: "票务类型"
    },
    on: {
      "on-select": _vm.selectType
    },
    model: {
      value: _vm.form.transportType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "transportType", $$v);
      },
      expression: "form.transportType"
    }
  }), _c("InputDate", {
    attrs: {
      "is-require": "",
      "value-format": "yyyy-MM-dd",
      label: "时间",
      placeholder: "请选择时间"
    },
    model: {
      value: _vm.form.itineraryDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "itineraryDate", $$v);
      },
      expression: "form.itineraryDate"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "班次"
    },
    model: {
      value: _vm.form.frequency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "frequency", $$v);
      },
      expression: "form.frequency"
    }
  }), _c("InputBase", {
    attrs: {
      label: "始发地"
    },
    model: {
      value: _vm.form.startPlace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startPlace", $$v);
      },
      expression: "form.startPlace"
    }
  }), _c("InputBase", {
    attrs: {
      label: "目的地"
    },
    model: {
      value: _vm.form.endPlace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endPlace", $$v);
      },
      expression: "form.endPlace"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputNumber", {
    attrs: {
      label: "单价",
      "controls-position": "right"
    },
    on: {
      change: _vm.changeInput
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  }), _c("InputNumber", {
    attrs: {
      label: "数量",
      "controls-position": "right"
    },
    on: {
      change: _vm.changeInput
    },
    model: {
      value: _vm.form.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  }), _c("InputNumber", {
    attrs: {
      label: "其他成本",
      "controls-position": "right"
    },
    on: {
      change: _vm.changeInput
    },
    model: {
      value: _vm.form.otherFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherFee", $$v);
      },
      expression: "form.otherFee"
    }
  }), _c("InputBase", {
    attrs: {
      disabled: true,
      label: "总金额"
    },
    model: {
      value: _vm.totalMoney,
      callback: function ($$v) {
        _vm.totalMoney = $$v;
      },
      expression: "totalMoney"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };