var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "协议名称"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("InputDateEndPicker", {
    attrs: {
      label: "有效日期"
    },
    model: {
      value: _vm.form.expire,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expire", $$v);
      },
      expression: "form.expire"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };