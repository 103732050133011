var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", [_c("ScenicAdd", {
    ref: "itemRef",
    attrs: {
      "column-data": _vm.columnData,
      "is-audit": true,
      "is-extra": true
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };