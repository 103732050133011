var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "团款费用修改",
      width: "960px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.createOrUpdate
    }
  }, [_vm.receptionList && _vm.receptionList.length && _vm.item ? [_c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    on: {
      "tab-click": _vm.handleTab
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "客户团款",
      name: "fee"
    }
  }, [_c("ReceptionFee", {
    ref: "feeRef",
    attrs: {
      "table-data": _vm.item.customerFeeList,
      "check-account-status": _vm.item.checkAccountStatus,
      index: _vm.rowNum
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "客户代收",
      name: "proxy"
    }
  }, [_c("ReceptionProxy", {
    ref: "proxyRef",
    attrs: {
      "table-data": _vm.item.customerCollectList,
      "check-account-status": _vm.item.checkAccountStatus,
      index: _vm.rowNum
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "客户返款",
      name: "refund"
    }
  }, [_c("ReceptionRefund", {
    ref: "refundRef",
    attrs: {
      "table-data": _vm.item.customerSubsidyList,
      "check-account-status": _vm.item.checkAccountStatus,
      index: _vm.rowNum
    }
  })], 1)], 1)] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };