var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("FormBase", {
    staticClass: "form-wrapper"
  }, [_c("h2", [_vm._v("新增配置")]), _c("FormItemBase", {
    attrs: {
      label: "名称"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.name,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "name", $$v);
      },
      expression: "formSys.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "编码"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.code,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "code", $$v);
      },
      expression: "formSys.code"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "账号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.value,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "value", $$v);
      },
      expression: "formSys.value"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "密码"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.value1,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "value1", $$v);
      },
      expression: "formSys.value1"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "value2"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.value2,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "value2", $$v);
      },
      expression: "formSys.value2"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "value3"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSys.value3,
      callback: function ($$v) {
        _vm.$set(_vm.formSys, "value3", $$v);
      },
      expression: "formSys.value3"
    }
  })], 1), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.setSysConfig(_vm.formSys);
      }
    }
  }, [_vm._v(" 添 加 ")])], 1), _c("el-divider"), _vm._l(_vm.configList, function (conf) {
    return _c("FormBase", {
      key: conf.id,
      staticClass: "form-wrapper"
    }, [_c("h2", [_vm._v(_vm._s(conf.name))]), _c("FormItemBase", {
      attrs: {
        label: "编码"
      }
    }, [_c("InputBase", {
      attrs: {
        label: "",
        disabled: ""
      },
      model: {
        value: conf.code,
        callback: function ($$v) {
          _vm.$set(conf, "code", $$v);
        },
        expression: "conf.code"
      }
    })], 1), _c("FormItemBase", {
      attrs: {
        label: "value"
      }
    }, [_c("InputBase", {
      attrs: {
        label: ""
      },
      model: {
        value: conf.value,
        callback: function ($$v) {
          _vm.$set(conf, "value", $$v);
        },
        expression: "conf.value"
      }
    })], 1), _c("FormItemBase", {
      attrs: {
        label: "value1"
      }
    }, [_c("InputBase", {
      attrs: {
        type: _vm.getSysPwdType,
        label: ""
      },
      model: {
        value: conf.value1,
        callback: function ($$v) {
          _vm.$set(conf, "value1", $$v);
        },
        expression: "conf.value1"
      }
    })], 1), _c("FormItemBase", {
      attrs: {
        label: "value2"
      }
    }, [_c("InputBase", {
      attrs: {
        label: ""
      },
      model: {
        value: conf.value2,
        callback: function ($$v) {
          _vm.$set(conf, "value2", $$v);
        },
        expression: "conf.value2"
      }
    })], 1), _c("FormItemBase", {
      attrs: {
        label: "value3"
      }
    }, [_c("InputBase", {
      attrs: {
        label: ""
      },
      model: {
        value: conf.value3,
        callback: function ($$v) {
          _vm.$set(conf, "value3", $$v);
        },
        expression: "conf.value3"
      }
    })], 1), _c("el-button", {
      attrs: {
        type: "success"
      },
      on: {
        click: function ($event) {
          _vm.visibleSysPwd = !_vm.visibleSysPwd;
        }
      }
    }, [_vm._v(" 查看密码 ")]), _c("el-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.setSysConfig(conf);
        }
      }
    }, [_vm._v(" 保 存 ")])], 1);
  }), _c("FormBase", {
    staticClass: "form-wrapper"
  }, [_c("h2", [_vm._v("旅监")]), _c("FormItemBase", {
    attrs: {
      label: "账号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formSupervise.glETravelPlatformAccount,
      callback: function ($$v) {
        _vm.$set(_vm.formSupervise, "glETravelPlatformAccount", $$v);
      },
      expression: "formSupervise.glETravelPlatformAccount"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "密码"
    }
  }, [_c("InputBase", {
    attrs: {
      type: _vm.getPwdType,
      label: ""
    },
    model: {
      value: _vm.formSupervise.glETravelPlatformKey,
      callback: function ($$v) {
        _vm.$set(_vm.formSupervise, "glETravelPlatformKey", $$v);
      },
      expression: "formSupervise.glETravelPlatformKey"
    }
  })], 1), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: function ($event) {
        _vm.visiblePwd = !_vm.visiblePwd;
      }
    }
  }, [_vm._v(" 查看密码 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.createOrUpdate
    }
  }, [_vm._v(" 保 存 ")])], 1), _c("div", {
    staticClass: "mt20"
  }, [_c("h2", [_vm._v("钉钉配置")]), _c("h3", [_vm._v("钉钉借款申请审批流程配置信息")]), _c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.formBorrow,
      rules: _vm.rules
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "name",
      label: "名字"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formBorrow.name,
      callback: function ($$v) {
        _vm.$set(_vm.formBorrow, "name", $$v);
      },
      expression: "formBorrow.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "code",
      label: "审批模板编码"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: _vm.formBorrow.disabled
    },
    model: {
      value: _vm.formBorrow.code,
      callback: function ($$v) {
        _vm.$set(_vm.formBorrow, "code", $$v);
      },
      expression: "formBorrow.code"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "processCode",
      label: "钉钉审批流程模板编码"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.formBorrow.processCode,
      callback: function ($$v) {
        _vm.$set(_vm.formBorrow, "processCode", $$v);
      },
      expression: "formBorrow.processCode"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "",
      label: ""
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.saveBorrow
    }
  })], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };