/* eslint-disable no-unused-vars */
import request from '@/utils/request';

// 其他支出
export function supplierOtherPayRequest(params) {
  const url = '/order/report2/otherPaySummaryStatistics2';
  return request({
    url,
    method: 'get',
    params
  });
}