import "core-js/modules/es.array.push.js";
import { DelCheckAll, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import {
// SelectGuide,
// SelectStaff,
SelectBankCard, StatusAudit, SelectAccount } from '@/components/common';
import { AuditRole } from '@/config/audit';
import { auditEditGuideBorrow as columnData } from '@/config/billDown/guideBorrow';
import { CashFeeType, cashModeType, CashWay } from '@/config/billDown';
import { parseDate } from '@/utils';
import { getUserData } from '@/utils/cookie';
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  cashMode: cashModeType.COMMON,
  cashAction: CashFeeType.PAY,
  cashDate: parseDate(),
  cashUser: '',
  cashUserId: '',
  guideId: '',
  guideName: '',
  cashWay: CashWay.CASH,
  bankId: '',
  remarks: '',
  borrowMoney: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait
});
export default {
  components: {
    DelCheckAll,
    AuditAdmin,
    AuditRoleAll,
    AuditStatus,
    CheckStatus,
    StatusAudit,
    // SelectGuide,
    // SelectStaff,
    SelectBankCard,
    SelectAccount
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      columnData,
      tableData: [],
      hasDataStatusKey: 'guideBorrowApplyStatus',
      deletePath: 'audit/index/deleteGuideBorrow',
      updatePath: 'audit/index/createGuideBorrow',
      fetchPath: 'audit/index/fetchGuideBorrow',
      auditAll: false,
      rowId: 10000,
      checkedAll: false,
      mergeTable: false
    };
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) return;
        this.tableData = this.formatData(d.borrows);
      },
      immediate: true
    }
  },
  methods: {
    // 新增一条
    handlePlus() {
      const {
        realName: cashUser,
        id: cashUserId
      } = getUserData();
      const {
        guideId,
        guideName
      } = this.data;
      this.tableData.push({
        ...defaultState(),
        cashUser,
        cashUserId,
        guideId,
        guideName,
        rowId: ++this.rowId
      });
    },
    // 选择导游
    selectGuide(row, val) {
      const {
        label
      } = val;
      row.guideName = label;
    },
    selectCashWay(row, val) {
      const {
        label
      } = val;
      row.cashWay = label;
    },
    selectAccount(row, val) {
      const {
        id,
        subId
      } = val;
      row.subjectId = id;
      row.pSubjectId = subId || '';
    },
    deleteLocal(id) {
      const fIndex = this.tableData.findIndex(it => it.rowId == id);
      this.tableData.splice(fIndex, 1);
    },
    deleteRemote(id) {
      const ids = Array.isArray(id) ? id : [id];
      return this.$store.dispatch('bill/guideBorrow/delBorrow', ids);
    },
    handleRemove(row) {
      const {
        rowId,
        isCustom
      } = row;
      if (isCustom) {
        this.deleteLocal(rowId);
      } else {
        this.$bus.open(() => {
          this.deleteRemote(rowId).then(() => {
            this.deleteLocal(rowId);
          });
        });
      }
    },
    handleCheckAll(val) {
      this.tableData.forEach(it => {
        it.isChecked = val;
      });
    },
    handleRemoveAll() {
      this.$bus.open(() => {
        const checkList = this.tableData.filter(it => it.isChecked);
        const newIds = checkList.filter(it => !it.isCustom).map(it => it.rowId);
        const oldIds = checkList.filter(it => it.id).map(it => it.rowId);
        newIds.map(this.deleteLocal);
        this.deleteRemote(oldIds);
        this.$emit('reload');
      });
    },
    handleAuditAll({
      label,
      value
    }) {
      this.tableData.forEach(it => {
        it.ap = value || it.ap;
        it.apName = label;
      });
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      const {
        guideId,
        guideName
      } = this.data;
      return list.map(it => {
        const {
          id,
          auditProgress,
          cashWay
        } = it;
        const item = {
          ...it,
          rowId: id,
          isCustom: false,
          isChecked: false,
          ap: auditProgress,
          auditProgress,
          type: cashWay,
          guideId,
          guideName,
          // 导游借款
          ...it.cash,
          cashUser: it.cash.cashUserName,
          subject: it.cash.subjectName
        };
        this.rowId++;
        return item;
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      const filteList = list.filter(it => it.borrowMoney > 0);
      return filteList.map(it => {
        it.planId = this.data.planId || it.planId;
        it.applyId = this.data.rowId;
        it.id = it.rowId;
        const {
          bankId,
          cashDate,
          cashMode,
          cashWay,
          subjectId
        } = it;
        const cash = {
          bankId,
          cashDate,
          cashMode,
          cashWay,
          subjectId
        };
        // cash
        it.cash = cash;

        // 当角色为管理员时，需要单独处理审核状态
        if (it.ap) {
          it.auditProgress = it.ap;
        }
        it.isCustom && delete it.id;
        return it;
      });
    },
    handleSave() {
      if (!this.tableData.length) {
        return Promise.resolve();
      }
      const list = this.beforeCommit(this.tableData);
      return this.$store.dispatch('bill/guideBorrow/ratifySet', list);
    },
    reset() {
      this.checkedAll = false;
      this.handleCheckAll(false);
    }
  }
};