var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.getData.length ? _c("div", [_c("h3", [_vm._v("订单信息")]), _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "餐厅"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.getData[0].restaurantName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "联系人"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.getData[0].restaurantContact))])], 1)])]), _c("div", {
    staticClass: "table-content"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.getData,
      "column-data": _vm.columnData,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            "value-format": "yyyy-MM-dd"
          },
          model: {
            value: scope.row.useDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "useDate", $$v);
            },
            expression: "scope.row.useDate"
          }
        })];
      }
    }, {
      key: "area",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "hotelRegionType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectArea(scope.row, $event);
            }
          },
          model: {
            value: scope.row.restaurantArea,
            callback: function ($$v) {
              _vm.$set(scope.row, "restaurantArea", $$v);
            },
            expression: "scope.row.restaurantArea"
          }
        })];
      }
    }, {
      key: "mealType",
      fn: function ({
        scope
      }) {
        return [_c("SelectRestaurantType", {
          attrs: {
            row: scope.row,
            val: "mealType",
            type: "restaurant"
          }
        })];
      }
    }, {
      key: "restaurantType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            type: "restaurantTypeList"
          },
          model: {
            value: scope.row.type,
            callback: function ($$v) {
              _vm.$set(scope.row, "type", $$v);
            },
            expression: "scope.row.type"
          }
        })];
      }
    }, {
      key: "standardType",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "restaurantStandardType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectStandardType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.restaurantTypeId,
            callback: function ($$v) {
              _vm.$set(scope.row, "restaurantTypeId", $$v);
            },
            expression: "scope.row.restaurantTypeId"
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputNum", {
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "freeCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.freeCount) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }], null, false, 1522415435)
  })], 1), _c("div", {
    staticClass: "input-field mt20"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("TextBase", [_vm._v(" 应付 ")]), _c("TextBase", [_c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.data.totalPay))])])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };