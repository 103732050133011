/*
 * @Author: cyg
 * @Date: 2023-08-11 08:33:14
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-21 11:12:42
 * @FilePath: \travel-admin\src\api\offer\index.js
 */
import request from '@/utils/request';

// 获取报价记录
export const fetchOffer = params => {
  return request({
    url: '/resource/product-quotation/simpleQuotations',
    method: 'get',
    params
  });
};

// 根据id获取报价记录
export const fetchOfferId = params => {
  return request({
    url: '/resource/product-quotation/getQuotationById',
    method: 'get',
    params
  });
};

// 新增报价
export const updateOffer = data => {
  return request({
    url: '/resource/product-quotation/save',
    method: 'post',
    data
  });
};

// 重新报价
export const updateAfreshOffer = data => {
  return request({
    url: '/resource/product-quotation/reQuote',
    method: 'post',
    data
  });
};

// 报价
export const deleteOffer = data => {
  return request({
    url: '/resource/product-quotation/del',
    method: 'post',
    data
  });
};

// 根据code获取历史报价记录
export const fetchOfferCode = params => {
  return request({
    url: '/resource/product-quotation/simpleQuotationsByCode',
    method: 'get',
    params
  });
};

// 修改报价状态
export const updateOfferStatus = data => {
  return request({
    url: '/resource/product-quotation/setMallStatus',
    method: 'post',
    data
  });
};