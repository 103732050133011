var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curItem ? _c("div", {
    staticClass: "form-wrapper",
    attrs: {
      id: "guideBorrow"
    }
  }, [_c("h3", [_vm._v("申请信息")]), _c("TableBase", {
    attrs: {
      data: _vm.tableInfo,
      "column-data": _vm.columns,
      dynamic: false,
      border: ""
    }
  }), _c("h3", [_vm._v("下账登记")]), _c("BillInput", {
    ref: "formRef",
    attrs: {
      obj: _vm.item,
      "resource-id": _vm.resourceId,
      "cash-type": _vm.TYPES.borrow
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.item.guideName,
      callback: function ($$v) {
        _vm.$set(_vm.item, "guideName", $$v);
      },
      expression: "item.guideName"
    }
  })], 1), _c("h3", [_vm._v("下账记录")]), _c("TableBase", {
    attrs: {
      data: _vm.tableRecord,
      "column-data": _vm.GuideBorrowRecord,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startPlanDate))]), _c("div", [_vm._v(_vm._s(scope.row.startPlanDate))])];
      }
    }, {
      key: "cashSourceType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.cashSourceType) + " ")];
      }
    }], null, false, 797403083)
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };