import "core-js/modules/es.array.push.js";
import { OrderDetail } from '@/components/common';
export default {
  name: 'AuditDetail',
  components: {
    OrderDetail
  },
  data() {
    return {
      redirectPath: '/order/order-audit'
    };
  },
  methods: {
    fetchAuditStatus(opts) {
      const {
        reviewerId
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchAuditStatus', {
        ...opts,
        id: reviewerId
      }).then(() => {
        this.$bus.tip();
        // 跳转连接
        this.$router.push({
          path: this.redirectPath
        });
        // 存储
        this.$store.commit('order/index/ORDER_AUDIT_REF', true);
      });
    }
  }
};