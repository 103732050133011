import "core-js/modules/es.array.reduce.js";
import { BtnLink } from '@/components/common';
import handleClipboard from '@/utils/clipboard';
export default {
  name: 'Guides',
  components: {
    BtnLink
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    },
    isBreak: {
      type: Boolean,
      default: false
    },
    isContact: {
      type: Boolean,
      default: true
    },
    isLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      visible: false,
      styleObj: {},
      text: ''
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.timer = setTimeout(() => {
        this.visible = false;
      }, 1000);
    },
    handleCopy(event) {
      const text = this.text.split('').reduce((acc, cur) => {
        acc += cur != '\n' ? cur : '';
        return acc;
      }, '').replace(/复制/g, '');
      handleClipboard(text, event);
    },
    onMouseEnter() {
      clearTimeout(this.timer);
      this.visible = true;
      const re = /\s+复制/g;
      const el = this.$refs.myPop;
      // const offsetTop = this.$refs.myPop.offsetTop
      const pWidth = el.offsetParent.offsetWidth;
      const text = el.textContent;
      this.text = text.trim().replace(re, '');
      this.$nextTick(() => {
        const obj = el.getBoundingClientRect();
        const {
          width
        } = obj;
        const w = Math.ceil(width);
        const pHalf = pWidth / 2;
        this.styleObj = {
          top: '50%',
          transform: 'translateY(-50%)',
          left: w < pHalf ? (pWidth - w) / 2 + w + 'px' : Math.min(w, pWidth) + 'px'
        };
      });
    },
    onMouseLeave() {
      clearTimeout(this.timer);
      this.visible = false;
    },
    onCopyMouseEnter() {
      clearTimeout(this.timer);
      this.visible = true;
    }
  }
};