import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { processNumber } from '@/utils/number';
import { updateScenicSupper } from '@/api/plan/scenic';
import { itemListKeys } from './config';
export default {
  data() {
    return {
      form: null,
      visible: false,
      excludeKey: ['isChecked', 'isCustom', 'itemIndex', 'reqOrderScenicTickets', 'respOrderScenicTickets', 'auditProgress', 'status', 'auditStatus', 'ticketList', 'checkAccountStatus']
    };
  },
  watch: {
    form: {
      handler: function (data) {
        if (!data) return;
        this.getTotalPayMoney(data);
      },
      deep: true
    }
  },
  methods: {
    show(data) {
      if (!data) return;
      this.form = {
        ...data
      };
      const {
        planId
      } = this.$route.query;
      this.form.planId = planId;
      this.form.payType = data.payType ? data.payType.toUpperCase() : 'ALIPAY_E_CODE';
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = null;
    },
    // 计算总成本
    calcCost(row, type = 'price') {
      const {
        adultCount,
        adultFreeCount,
        childPrice,
        childCount,
        accompanyPrice,
        accompanyCount,
        otherFee
      } = row;
      const price = row[type];
      let total = price * (adultCount - adultFreeCount) + childPrice * childCount + accompanyPrice * accompanyCount + Number(otherFee);
      return processNumber(total);
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney(data) {
      const money = this.calcCost(data);
      this.form.rowTotal = money;
    },
    deleteExcludeKey(data) {
      return Object.keys(data).reduce((acc, key) => {
        if (!this.excludeKey.includes(key)) {
          return {
            ...acc,
            [key]: data[key]
          };
        }
        return acc;
      }, {});
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {
          id: it.id
        };
        it.totalPay = it.totalPay || 0;

        // 定义子项目的列表
        it.orderScenicTicketList = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
        });

        // 是否是加点
        if (this.isExtra) {
          it.isAddScenic = true;
        }

        // 重新还原该条数据的id
        it.id = it.scenicPlanId;
        it.payType = it.payType.toUpperCase();

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.orderScenicTicketList.push(item);
        } else {
          it.orderScenicTicketList.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    handleConfirm() {
      const {
        alipayPeerPayAmount,
        rowTotal
      } = this.form;
      if (alipayPeerPayAmount != rowTotal) {
        this.$bus.tip({
          type: 'warning',
          message: '金额与企业码支付金额不一致'
        });
        return;
      }
      const option = this.deleteExcludeKey(this.form);
      const list = this.beforeCommit([option]);
      updateScenicSupper(list).then(() => {
        this.hide();
        this.$emit('reload');
      });
    }
  }
};