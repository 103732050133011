import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { TopTitle, SelectTraffic, SelectDictionary, DialogLog } from '@/components/common';
import { orderTraffic, TYPES } from '@/config/order';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, getSummariesMixin, rowspanMixin } from '@/mixin';
import tableMixin from '../tableMixin';
import { decimalSplit, parseDate, mergeTableRow, calcMergeRowTotalByKey } from '@/utils';
import { multiplyNumber, objectSpanMethod } from '@/utils';
const defaultState = () => ({
  price: 0,
  money: 0,
  count: 1,
  shiftNumber: '',
  startPosition: '',
  destinationPosition: '',
  transportId: '',
  otherFee: '',
  orderDate: parseDate(new Date()),
  remark: '',
  planTransportCompanyTypeId: ''
});
const rowKeys = {
  customerOrderId: 'customerOrderId',
  customerId: 'customerId',
  id: 'id',
  remark: 'remark',
  transportId: 'transportCompanyId',
  transportName: 'transportCompanyName'
  // transportCompanyOTypes: 'transportCompanyOTypes',
  // cashType: 'cashType',
  // headCompanyId: 'headCompanyId',
  // orderNumber: 'orderNumber',
  // planId: 'planId',
  // shuttleId: 'shuttleId',
  // totalPay: 'totalPay',
};
const itemListKeys = {
  orderDate: 'orderDate',
  transportType: 'transportType',
  count: 'count',
  destinationPosition: 'destinationPosition',
  transportTypeId: 'id',
  money: 'money',
  otherFee: 'otherFee',
  price: 'price',
  shiftNumber: 'shiftNumber',
  startPosition: 'startPosition'
  // transportTypeId: 'planTransportCompanyTypeId',
  // transportId: 'planTransportOId',
  // remark: 'remark',
};
const MergeRowKeys = ['transportName', 'date', 'orderDate', 'shiftNumber', 'startPosition', 'destinationPosition', 'remarks', 'action', '$total'];
export default {
  components: {
    TopTitle,
    SelectTraffic,
    SelectDictionary,
    DialogLog
  },
  mixins: [dictionaryMixin, rowspanMixin, tableMixin, getSummariesMixin],
  data() {
    return {
      ticketType: codeType.ticketType,
      tableData: [],
      columnData: orderTraffic,
      removeCache: [],
      // 要删除的团款项目
      statisticsKeys: ['$total', '$money'],
      transportTypeId: '',
      deleteMsg: '是否确定删除此条大交通？',
      fetchPath: 'order/index/fetchTraffic',
      deletePath: 'order/index/deleteTraffic',
      deleteTypePath: 'order/index/deleteTrafficType',
      updatePath: 'order/index/createOrUpdateTraffic3',
      rowId: -10000
    };
  },
  computed: {
    curOrder() {
      return this.$store.getters['order/index/currentOrder'];
    },
    customerId() {
      const data = this.curOrder;
      return data ? data.customerId : '';
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    objectSpanMethod,
    initData(data) {
      if (!data) return;
      const list = data.map(it => {
        it.rowId = it.mergeId = it.id;
        it.type = it.transportType;
        it.orderDate = data.find(val => val.id == it.id).orderDate;
        it.$money = this.calcMoney(it);
        return it;
      });
      this.handleMerge(list);
    },
    handleMerge(list) {
      const mergeMoneyList = calcMergeRowTotalByKey(list, '$money', '$total');
      this.tableData = mergeTableRow(mergeMoneyList, MergeRowKeys);
    },
    calcMoney(row) {
      const {
        price,
        count,
        otherFee
      } = row;
      return multiplyNumber(price, count) + Number(otherFee || 0);
    },
    countTotalMoney(row) {
      const money = this.calcMoney(row);
      row.money = decimalSplit(money);
      this.tableData = calcMergeRowTotalByKey(this.tableData, '$money', '$total');
    },
    selectTraffic(row, val) {
      row.transportName = val.label;
      row.transportId = val.value;
    },
    selectType(row, item) {
      const {
        label
      } = item;
      row.transportType = label;
      row.transportTypeId = !row.isCustom ? row.transportTypeId : '';
    },
    handlePlus() {
      const {
        adultCount: count
      } = this.currentOrder;
      const {
        name: type
      } = this.getDictionaryDefVal(this.ticketType);
      const item = {
        ...defaultState(),
        count,
        transportType: type,
        rowId: ++this.rowId
      };
      this.tableData.push(item);

      // this.handleMerge(this.tableData)
    },
    // 当前景点添加项目
    addRowType(scope) {
      let {
        $index: index,
        row
      } = scope;
      const rowItem = {
        ...row,
        itemIndex: ++index,
        isCustom: true,
        isSelect: false
      };
      this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      this.handleMerge(this.tableData);
    },
    // 删除景点门票
    deleteRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        transportTypeId,
        isCustom
      } = row;
      !isCustom ? this.$store.dispatch(this.deleteTypePath, transportTypeId).then(() => {
        this.fetchData();
      }) : this.tableData.splice(index, 1);
      this.handleMerge(this.tableData);
    },
    // 获取日志
    handleLog() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: id,
        subType: TYPES.transport
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 类型子项
    processRow(item, keys) {
      Object.keys(keys).map(key => {
        item[keys[key]] = item[key];
      });
      return item;
    },
    processItemList(list, keys) {
      return list.reduce((acc, cur) => {
        const obj = {};
        Object.keys(keys).forEach(key => {
          obj[keys[key]] = cur[key];
          if (cur.isCustom) {
            delete obj.id;
          }
        });
        return [...acc, obj];
      }, []);
    },
    processMergeRowVal(data) {
      const keys = ['orderDate', 'shiftNumber', 'startPosition', 'destinationPosition'];
      const obj = data.reduce((acc, cur) => {
        const key = cur['rowId'];
        const val = acc[key];
        if (!val) {
          acc[key] = [];
        }
        acc[key].push(cur);
        return acc;
      }, {});
      const result = Object.keys(obj).map(id => {
        const list = obj[id];
        const mainItem = list[0];
        const newList = list.slice(1).map(it => {
          keys.forEach(k => {
            it[k] = mainItem[k];
          });
          return {
            ...it
          };
        });
        const mainObj = this.processRow(mainItem, rowKeys);
        const itemList = this.processItemList([mainItem, ...newList], itemListKeys);
        mainObj.customerId = this.customerId;
        mainObj.transportCompanyOTypes = itemList;
        return mainObj;
      });
      return result;
    },
    beforeCommit(customerOrderId, type) {
      // 删除合并表格的key
      const mergeRowKeyList = this.tableData.map(it => {
        MergeRowKeys.forEach(key => {
          delete it[`${key}-span`];
        });
        return it;
      });

      // 处理相同id的数据
      const list = this.processMergeRowVal(mergeRowKeyList);
      return list.map(it => {
        it.customerOrderId = customerOrderId;
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (!row.transportId || row.transportId < 1) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择大交通！'
          });
          return false;
        }
        if (row.price === undefined || row.price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '单价不能为空！'
          });
          return false;
        }
      }
      return true;
    },
    resetData() {
      // this.rowspan()
    }
  }
};