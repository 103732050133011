import { dictionaryMixin, searchMixin } from '@/mixin';
import { getDepartmentLevel, parseDate } from '@/utils';
export default {
  components: {},
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      sendDate: [parseDate()],
      endDate: [],
      orderDate: [],
      searchParams: {
        departLevel: '1',
        startTripDateS: parseDate(),
        pageSize: 10000
      }
    };
  },
  methods: {
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    changeDepartment(val) {
      console.log('val:', val);
      const {
        departLevel
      } = getDepartmentLevel(val);

      // const obj = {
      //   subDepartmentParentId: val['departmentId'],
      //   grandsonDepartmentParentId: val['subDepartmentId'],
      // }
      const obj = {};
      if (val['departmentId'] && !val['subDepartmentId']) {
        delete obj.grandsonDepartmentParentId;
        delete this.searchParams.grandsonDepartmentParentId;
        obj.subDepartmentParentId = val['departmentId'];
      }
      if (val['subDepartmentId']) {
        delete obj.subDepartmentParentId;
        delete this.searchParams.subDepartmentParentId;
        obj.grandsonDepartmentParentId = val['subDepartmentId'];
      }
      const level = departLevel ? Math.min(departLevel + 1, 3) : 1;
      this.searchParams = {
        ...this.searchParams,
        ...obj,
        departLevel: level
      };
    }
  }
};