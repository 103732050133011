// 兴坪江船证件类型
export const certBoatType = [{
  value: '1',
  label: '身份证'
}, {
  value: '2',
  label: '护照'
}, {
  value: '3',
  label: '军人证'
}, {
  value: '4',
  label: '港澳居民来往内地通行证'
}, {
  value: '6',
  label: '台湾居民来往大陆通行证'
}, {
  value: '5',
  label: '学生证'
}, {
  value: '7',
  label: '临时身份证'
}, {
  value: '8',
  label: '人民警察证'
}, {
  value: '9',
  label: '军官证'
}, {
  value: '10',
  label: '武警警官证'
}, {
  value: '11',
  label: '士兵证'
}, {
  value: '12',
  label: '外交官证'
}, {
  value: '13',
  label: '外国人居留证'
}, {
  value: '14',
  label: '残疾证'
}, {
  value: '15',
  label: '登机牌'
}, {
  value: '16',
  label: '退役军人'
}, {
  value: '17',
  label: '导游证'
}];
export const touristAdd = [{
  attrs: {
    label: '证件类型',
    prop: 'documentType'
  },
  slot: 'documentType'
}, {
  attrs: {
    label: '游客姓名',
    prop: 'touristName'
  },
  slot: 'touristName'
}, {
  attrs: {
    label: '证件号码',
    prop: 'documentNo'
  },
  slot: 'documentNo'
}, {
  attrs: {
    label: '操作',
    prop: 'action',
    width: 100
  },
  slot: 'action'
}];
export const touristList = [{
  attrs: {
    label: '游客姓名',
    prop: 'tourist_name'
  }
}, {
  attrs: {
    label: '证件类型',
    prop: 'document_type'
  }
}, {
  attrs: {
    label: '证件号码',
    prop: 'document_no'
  }
}, {
  attrs: {
    label: '仓位',
    prop: ''
  }
}, {
  attrs: {
    label: '所属部门',
    prop: ''
  }
}];
export const scenicCompany = [{
  attrs: {
    label: '导入编号',
    prop: 'import_code'
  }
}, {
  attrs: {
    label: '导游',
    prop: 'guide'
  }
}, {
  attrs: {
    label: '所属公司',
    prop: 'ent_name'
  }
}, {
  attrs: {
    label: '所属部门',
    prop: ''
  }
}, {
  attrs: {
    label: '导入时间',
    prop: 'create_time'
  }
}];