import { SelectStaff, SelectAccount, SelectBankCard } from '@/components/common/Select';
import { mapGetters } from 'vuex';
import { rules, defaultState, calcBalance, cashMode, CashWay, cashModeType, CashFeeType } from '@/views/billDown/help';
import { findSubject } from '@/utils/subject';
export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    disableMode: {
      type: Boolean,
      default: false
    },
    resourceId: {
      type: [String, Number],
      default: ''
    },
    cashType: {
      type: String,
      default: ''
    },
    payText: {
      type: String,
      default: '预付款'
    },
    obj: {
      type: Object,
      default: () => ({})
    },
    resName: {
      type: String,
      default: ''
    },
    subType: {
      type: String,
      default: CashFeeType.PAY
    }
  },
  data() {
    return {
      rules,
      radioVal: cashMode,
      cashAction: CashFeeType.PAY,
      autoStatus: true,
      radios: [{
        value: '常规',
        label: cashModeType.COMMON
      }, {
        value: this.payText,
        label: cashModeType.PRE_PAYMENT
      }],
      cashActionRadios: [{
        value: '收入',
        label: CashFeeType.INCOME
      }, {
        value: '支出',
        label: CashFeeType.PAY
      }],
      form: defaultState()
    };
  },
  computed: {
    ...mapGetters({
      prePaymentMoney: 'bill/billDown/pay/prePaymentMoney',
      // billDetail: 'bill/billDown/billDetail',
      billInputForm: 'bill/billDown/billInputForm',
      subjectData: 'system/accounting/subjectData',
      accountData: 'resource/account/accountData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    getBalance() {
      const {
        balance
      } = calcBalance(this.form);
      return balance;
    },
    getPrePayVal() {
      return {
        rid: this.resourceId,
        type: this.radioVal
      };
    },
    isPrePaymentMode() {
      return this.radioVal == cashModeType.PRE_PAYMENT;
    },
    hasSlot() {
      return this.$slots.default != undefined;
    }
  },
  watch: {
    resourceId: {
      handler: 'setResourceId'
    },
    billInputForm: {
      handler: function (data) {
        this.form = data;
      }
      // deep: true,
    },
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form = this.form || {};
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    },
    obj: {
      handler: function (obj) {
        const bankId = this.accountData ? this.accountData.list[0].id : '';
        this.form = {
          ...this.form,
          bankId,
          ...(this.billInputForm || {}),
          ...obj
        };
        this.$store.commit('bill/billDown/SET_BILL_INPUT', this.form);
      },
      immediate: true
    },
    getPrePayVal: {
      handler: function (obj) {
        const {
          rid,
          type
        } = obj;
        if (rid && type == cashModeType.PRE_PAYMENT) {
          this.fetchBalance();
        }
      }
    }
  },
  beforeMount() {
    // 更新不默认设置会计科目
    if (this.isUpdate) return;

    // 如果已设置过，则从原来的数据获取
    // if (this.billInputForm) return

    if (this.subjectData) {
      this.setSubject(this.subjectData);
    }
  },
  beforeDestroy() {
    this.form = {};
    this.$store.commit('bill/billDown/SET_BILL_INPUT', null);
  },
  methods: {
    fetchBalance() {
      const path = this.subType == CashFeeType.PAY ? 'bill/billDown/pay/fetchPayBalance' : 'bill/billDown/collect/fetchCollectBalance';
      return this.$store.dispatch(path, {
        rid: this.resourceId || this.obj.rowId || this.obj.id,
        cashType: this.cashType
      });
    },
    changeCashAction(val) {
      this.form.cashAction = val;
    },
    // 下账模式
    changeStatus(val) {
      this.$emit('downStatus', val);
      this.$store.commit('bill/billDown/SET_BILL_AUTO_STATUS', val);
    },
    changeMoney(money) {
      if (!this.autoStatus) return;
      this.$emit('change', money);
    },
    substrPath() {
      const path = this.$route.path;
      const name = path.substring(path.lastIndexOf('/') + 1);
      return name.split('-')[0];
    },
    setSubject() {
      const findItem = findSubject(this.substrPath(), this.subType);
      const {
        subjectId,
        name,
        subId,
        subName
      } = findItem;
      this.form.subject = name;
      this.form.subjectName = subName;
      this.form.subjectId = subjectId;
      this.form.subId = subId;
    },
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    setResourceId(val) {
      if (!val || !this.form) return;
      this.form.resourceId = val;
    },
    changeForm() {
      const data = this.form;
      // data.subject = data.subjectName
      this.form = data;
    },
    changeRadio(val) {
      this.form.cashMode = val;
      if (val == cashModeType.PRE_PAYMENT) {
        this.fetchBalance();
      }
    },
    changeCashMode(val) {
      this.form.cashWay = val == 1 ? CashWay.CASH : CashWay.CARD;
    },
    selectAccount(val) {
      const {
        id,
        name,
        subId,
        subName
      } = val;
      if (subId && subName) {
        this.form.subName = subName;
        this.form.subjectId = subId;
        this.form.psubjectId = id;
      } else {
        this.form.subjectId = id;
        this.form.subjectName = name;
      }
    },
    validate() {
      return this.$refs.formRef.validate().then(valid => valid);
    },
    processSubject(form) {
      const {
        subjectId,
        subId
      } = form;
      if (subId) {
        form.subjectId = subId;
        form.psubjectId = subjectId;
      }
      return form;
    },
    handleValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate().then(valid => {
          if (!valid) {
            reject();
          } else {
            resolve(this.processSubject(this.form));
          }
        });
      });
    },
    onSave() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        this.$emit('save', this.form);
        this.onClose();
      });
    },
    onClose() {
      // this.$emit('close')
      this.form = defaultState();
    }
  }
};