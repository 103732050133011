var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "show-page": true,
      total: _vm.total,
      border: ""
    },
    on: {
      page: function ($event) {
        return _vm.$emit("page", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("PlanNumberMore", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "customerTripNumber",
      fn: function ({
        scope
      }) {
        return [_c("PlanNumberMore", {
          attrs: {
            row: scope.row,
            "key-val": "customerTripNumber"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(scope.row);
            }
          }
        }, [_vm._v(" 修改 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row.id);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }, {
      key: "actionHead",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };