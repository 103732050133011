import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'SelectDicType',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      url: {
        scenic: 'resource/scenic/getTravelSuperviseeDic',
        hotel: 'resource/hotel/getTravelSuperviseeDic',
        guide: 'resource/guide/getTravelSuperviseeDic',
        shop: 'resource/shopping/getTravelSuperviseeDic',
        fleet: 'resource/fleet/getTravelSuperviseeDic'
      }
    };
  },
  computed: {
    ...mapGetters({
      scenicDicData: 'resource/scenic/scenicDicData',
      hotelDicData: 'resource/hotel/hotelDicData',
      guideDicData: 'resource/guide/guideDicData',
      shopDicData: 'resource/shopping/shopDicData',
      fleetDicData: 'resource/fleet/fleetDicData'
    })
  },
  watch: {
    scenicDicData: {
      handler: 'initData',
      immediate: true
    },
    hotelDicData: {
      handler: 'initData',
      immediate: true
    },
    shopDicData: {
      handler: 'initData',
      immediate: true
    },
    guideDicData: {
      handler: 'initData',
      immediate: true
    },
    fleetDicData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      if (!this.code) return;
      this.$store.dispatch(this.url[this.code], params);
    },
    loadMoreData() {
      let data = null;
      if (this.code == 'scenic') {
        data = this.scenicDicData;
      }
      if (this.code == 'hotel') {
        data = this.hotelDicData;
      }
      if (this.code == 'guide') {
        data = this.guideDicData;
      }
      if (this.code == 'shop') {
        data = this.shopDicData;
      }
      if (this.code == 'fleet') {
        data = this.fleetDicData;
      }
      let {
        currentPage,
        pageSize,
        hasMore
      } = data;
      hasMore && this.fetchData({
        pageNum: ++currentPage,
        pageSize
      });
    },
    initData(data) {
      if (!data) {
        this.list = [];
        return false;
      }
      this.list = data.list;
    },
    remoteMethod(query) {
      this.loading = true;
      this.fetchData({
        name: query
      }).then(() => {
        this.loading = false;
        this.$refs.selectRef.remoteFilter(query);
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};