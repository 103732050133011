/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchHotel, deleteHotel, fetchHotelRoom, deleteHotelRoom, fetchHotelDetail, createOrUpdateHotel, createOrUpdateHotelRoom, fetchGroupList } from '@/api/resource/hotel';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { fetchResourceImage } from '@/api/resource';
import { getFileUrl } from '@/api';
import { formatCharData, formatDicData, duplicateData, hasMoreData, splitItem } from '@/utils/data';
import { TYPES, DICTYPES } from '@/config/resource';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  hotelData: null,
  hotelGroupData: null,
  hotelDicData: null,
  hotelDataOrigin: null,
  hotelLog: null,
  hotelDetail: null,
  hotelRoom: null,
  hotelImages: null
});
const state = dfaultState();
const getters = {
  hotelDetail: state => state.hotelDetail,
  hotelDicData: state => state.hotelDicData,
  hotelGroupData: state => state.hotelGroupData,
  hotelImages: state => state.hotelImages,
  hotelRoom: state => state.hotelRoom,
  hotelData: state => state.hotelData,
  hotelDataOrigin: state => state.hotelDataOrigin,
  hotelLog: state => state.hotelLog
};
const formatValue = list => {
  if (!list || !list.length) return;
  return list.map(it => {
    const [areaLabel, area] = splitItem(it.area);
    const [standardLabel, standard] = splitItem(it.standard);
    it.areaLabel = areaLabel;
    it.area = area;
    it.standardLabel = standardLabel;
    it.standard = standard;
    return it;
  });
};
const actions = {
  fetch({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchHotel(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = formatValue(data.list);
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      // data = data.filter(it => it.isEnable)
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchGroupList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchDetail({
    commit
  }, id) {
    return fetchHotelDetail(id).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DETAIL', data);
      return data;
    });
  },
  fetchRoom({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchHotelRoom(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_ROOM', data);
      return data;
    });
  },
  // 酒店详情图片
  fetchImages({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts,
      resourceType: TYPES.hotel
    };
    return fetchResourceImage(opts).then(res => {
      const {
        data
      } = res;
      const {
        list
      } = data;

      // 从aliyun OSS 获取图片
      const fileList = list.map(async it => {
        try {
          const file = await getFileUrl([it.path]);
          if (file) {
            it.path = file.data[0];
          }
          return it;
        } catch (error) {
          console.log('error: ', error);
        }
      });
      return Promise.all(fileList).then(list => {
        return {
          ...data,
          list
        };
      });
    });
  },
  // 改方法与上面的方法是一样的，目前还未合并
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.hotel
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.hotel
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchAgreement({
    rootGetters
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.hotel
    };
    return this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        list
      } = res.data;
      // TODO: 下面的代码可能不需要了
      // 更新当前协议内容列表
      if (rootGetters['resource/common/curAgreementId']) {
        const findItem = list.find(it => it.id === rootGetters['resource/common/curAgreementId']);
        this.dispatch('resource/common/agreementChild', findItem);
      }
      return list;
    });
  },
  // 获取有效协议
  fetchAgreementTypeList({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.hotel
    }; // TYPES.hotel
    return this.dispatch('resource/common/fetchAgreementTypeList', opts);
  },
  addAgreementItem({
    common
  }, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateHotel(opts);
  },
  // 查询旅监酒店字典
  getTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.hotel
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.hotelDicData ? state.hotelDicData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatDicData(list, 'hotel'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA_HOTELDIC', data);
      return originData;
    });
  },
  // 查询旅监酒店字典名字搜搜
  searchTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.hotel
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      data.list = formatDicData(data.list, 'hotel');
      commit('FETCH_DATA_HOTELDIC', data);
      return data;
    });
  },
  createOrUpdateRoom({
    commit
  }, opts) {
    return createOrUpdateHotelRoom(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delete({
    commit,
    dispatch
  }, id) {
    dispatch('fetchImages', id).then(data => {
      const delFiles = data.map(it => it.path);
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteHotel(id);
    // .then(res => {
    //   dispatch('fetchList')
    //   commit('DELETE', id)
    //   return res.data
    // })
  },
  deleteRoom({
    commit
  }, id) {
    return deleteHotelRoom(id).then(res => {
      commit('DELETE', id);
      return res.data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.hotelData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.hotelGroupData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.hotelDataOrigin = data;
  },
  FETCH_DATA_HOTELDIC: (state, data) => {
    state.hotelDicData = data;
  },
  DELETE_DATA: (state, data) => {
    state.hotelDetail = null;
  },
  FETCH_DETAIL: (state, data) => {
    state.hotelDetail = data;
  },
  FETCH_ROOM: (state, data) => {
    state.hotelRoom = data;
  },
  FETCH_IMAGES: (state, data) => {
    state.hotelImages = data;
  },
  FETCH_LOG: (state, data) => {
    state.hotelLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.hotelData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.hotelData.list.filter(it => it.id !== id);
    state.hotelData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};