/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchOther, deleteOther, createOrUpdateOther } from '@/api/resource/other';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  otherData: null,
  otherListData: null
});
const state = dfaultState();
const getters = {
  otherData: state => state.otherData,
  otherListData: state => state.otherListData
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchOther(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_OTHER', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.other
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delete({
    commit
  }, id) {
    return deleteOther(id);
  },
  createOrUpdateOther({
    commit
  }, opts) {
    return createOrUpdateOther(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA_OTHER: (state, data) => {
    state.otherData = data;
  },
  FETCH_DATA: (state, data) => {
    state.otherListData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};