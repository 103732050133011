import "core-js/modules/es.array.push.js";
import { TableUnPay, TablePayed } from './components';
import { FooterBar, BillInput, SelectGuide } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { TYPES } from '@/config/billDown';
import { formatCommitTableData } from '@/utils/data';
export default {
  components: {
    FooterBar,
    BillInput,
    SelectGuide,
    TableUnPay,
    TablePayed
  },
  mixins: [tagViewMixin],
  data() {
    return {
      TYPES,
      exportLoading: false,
      resourceId: '',
      backPath: '/bill/guide-audit',
      planId: '',
      money: 0
    };
  },
  computed: {
    billDetail() {
      return this.$store.getters['bill/billDown/billInputForm'];
    }
  },
  mounted() {
    const {
      guideId,
      planId
    } = this.$route.query;
    this.resourceId = guideId;
    this.planId = planId;
    // 重置销账数据
    this.$store.commit('bill/guide/RESET_STATISTICS');
  },
  methods: {
    fetchData() {},
    reloadUnPay() {
      this.$refs.unPayRef.fetchData();
    },
    changeMoney(money) {
      this.money = money;
      this.$refs.unPayRef.caclDownMoney(money);
    },
    processData(form) {
      const {
        cashWay,
        cashUserId,
        money
      } = form;
      const tableData = this.$refs.unPayRef.tableData;
      const list = tableData.filter(it => it.isChecked);
      const cashs = list.map(it => {
        return {
          cash: it.downPayed,
          cashWay,
          cashUserId,
          orderId: it.rowId,
          // 订单id， 其他为资源id
          type: it.type ? it.type : 'NONE'
        };
      });
      let opts = {
        ...form,
        money,
        cashType: TYPES.reimb,
        cashes: formatCommitTableData(cashs)
      };
      delete opts.subject;
      return opts;
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const loading = this.$bus.loading();
        const opts = this.processData(form);
        this.$store.dispatch('bill/billDown/pay/createPay', opts).then(() => {
          loading.close();
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath
      });
    }
  }
};