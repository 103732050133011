import "core-js/modules/es.array.push.js";
import printMixin from './printMixin';
import PrintTemplate from './components/PrintTemplate.vue';
import { orderType } from '@/config/order';
import { downloadExport } from '@/utils/exportExcel';
import { mapGetters } from 'vuex';
import { isMobile } from '@/utils';
import { defaultMenu, planMenu, personalArr, teamArr, positionAction, BoardNameObj } from './printConfig';
export default {
  components: {
    PrintTemplate
  },
  mixins: [printMixin],
  data() {
    return {
      defaultMenu,
      planMenu,
      personalArr,
      teamArr,
      fullscreenLoading: false,
      positionAction: false,
      tabPosition: 'left',
      tabMenu: [],
      tabIndex: '0',
      tplParams: {
        type: 1
      },
      queryParams: {
        orderId: '',
        type: '',
        // 类型
        component: '' // 组件名字
      },
      tplName: ''
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      rolePermissionList: 'system/role/rolePermissionList'
    }),
    getCur() {
      return this.tabMenu[this.tabIndex];
    },
    getComps() {
      const menu = this.tabMenu[this.tabIndex];
      return this.tabMenu.length && this.tplName ? this.tplName : menu && menu.component;
    },
    getBtnText() {
      return `导出${this.getCur.label}`;
    },
    isTeamMembers() {
      const {
        type,
        component
      } = this.queryParams;
      return type == orderType.team && component == 'TeamName';
    },
    isBoardMembers() {
      const {
        component
      } = this.queryParams;
      return component && component.indexOf('BoardName') > -1;
    },
    isMobile() {
      return isMobile();
    },
    hasTpl() {
      const menu = this.tabMenu[this.tabIndex];
      return menu ? menu.tpl : null;
    },
    getStyle() {
      return this.isMobile ? {
        width: '860px',
        margin: '90px 0 0 0'
      } : {
        width: '860px',
        margin: '60px auto',
        paddingLeft: this.hasTpl ? '280px' : '160px'
      };
    }
  },
  watch: {
    rolePermissionList: {
      handler: function handler(data) {
        if (!data) {
          this.fetchPermission();
        } else {
          this.handleData(data);
        }
      }
    }
  },
  mounted() {
    if (this.rolePermissionList && this.rolePermissionList.length) {
      this.handleData();
    }
  },
  methods: {
    handleData() {
      this.tabsMenu();
    },
    fetchPermission() {
      const {
        roleId
      } = this.userData;
      this.$store.dispatch('system/role/fetchPermission', roleId);
    },
    changeTpl(tpl) {
      const item = this.tabMenu[this.tabIndex];
      // const compName = item.component
      this.tplName = tpl;
      // this.tplName = tpl == 'index' ? compName : compName + tpl

      this.tplParams = item.params[this.tplName];
    },
    // 右侧菜单重组
    tabsMenu() {
      const {
        id,
        type,
        component,
        itemId,
        planType,
        gateUrl
      } = this.$route.query;
      this.queryParams = {
        orderId: id,
        type: planType,
        component: component
      };
      if (type == 'plan') {
        if (itemId) {
          this.planMenu.push({
            id: 100,
            label: '司机通知单',
            component: 'Driver'
          });
        }
        if (planType == orderType.team) {
          this.planMenu.unshift(...this.teamArr);
        }
        if (planType == orderType.personal) {
          this.planMenu.unshift(...this.personalArr);
        }

        // 船票
        this.planMenu.push(BoardNameObj[planType]);
        this.roleMenu(positionAction[gateUrl].pCode, positionAction[gateUrl].code, this.planMenu);
      } else {
        if (planType == orderType.team) {
          this.defaultMenu[1].component = 'GuidePlanTeam';
        }
        this.roleMenu(positionAction[gateUrl].pCode, positionAction[gateUrl].code, this.defaultMenu);
      }
      const fIndex = this.tabMenu.findIndex(it => it.component === component);
      fIndex > 0 ? this.tabIndex = fIndex + '' : null;
    },
    roleMenu(permissMenu, systemMenu, menuList) {
      // 匹配权限
      permissMenu = permissMenu == '/plan2' ? '/plan' : permissMenu;
      const rolePermission = this.rolePermissionList.filter(it => it.code == permissMenu)[0];
      const {
        subMenus
      } = rolePermission;
      let {
        roleMenuOpts
      } = subMenus.filter(it => it.code == systemMenu)[0];
      roleMenuOpts = roleMenuOpts.filter(it => it.isRole);
      roleMenuOpts = roleMenuOpts.map(it => {
        return it.code;
      });
      // 权限匹配菜单
      this.tabMenu = menuList.filter(it => roleMenuOpts.includes(it.code));
      if (!this.tabMenu.length) {
        this.positionAction = true;
      }
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      let component = this.tabMenu[index].component;
      this.$router.replace({
        path: 'print-bill',
        query: {
          ...this.$route.query,
          component
        }
      });
      this.tplName = '';
      this.queryParams.component = component;
      if (this.$refs.printTplRef) {
        this.$refs.printTplRef.getDefault(index);
      }
    },
    fetchDirectionary() {
      if (!this.dictionaryCodeData) {
        this.$store.dispatch('system/dictionary/fetchDictionaryType');
      }
    },
    handlePrint() {
      let {
        id,
        itemId,
        planId
      } = this.$route.query;
      const routerObj = this.$router.resolve({
        path: '/print-download',
        query: {
          id,
          planId: planId || id,
          itemId: itemId || '',
          name: this.getComps
        }
      });
      window.open(routerObj.href, '_blank');
    },
    exportExecl() {
      const cur = this.tabMenu[this.tabIndex];
      const {
        tpl,
        params
      } = cur;
      let {
        reqUrl
      } = cur;
      const loading = this.$bus.loading();
      let {
        id,
        planId
      } = this.$route.query;
      let opts = {
        id,
        planId
      };
      if (tpl) {
        opts = {
          ...opts,
          ...params[this.tplName]
        };
      } else {
        opts = {
          ...opts,
          ...params
        };
      }
      this.$store.dispatch(reqUrl, opts).then(data => {
        loading.close();
        const {
          filename,
          res
        } = data;
        downloadExport(res, filename);
      }).catch(() => {
        loading.close();
      });
    },
    handleExportPDF() {
      const content = this.$refs.content[this.tabIndex].$vnode.elm;
      const printData = content.innerHTML;
      // 存下原来的内容
      const oldstr = document.body.innerHTML;
      // 把标题去掉
      document.title = '';
      document.body.innerHTML = printData;
      window.print();
      document.body.innerHTML = oldstr;
      window.location.reload();
    },
    exportTourist() {
      const comp = this.$refs.content;
      comp.handleExport();
    },
    handleClose() {
      window.close();
    }
  }
};