import { Search } from './components';
import { Columns } from './tableConfig';
import { StatisticsKeys } from './config';
import { downloadExport } from '@/utils';
export default {
  components: {
    Search
  },
  data() {
    return {
      Columns,
      StatisticsKeys
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/traffic/bookingData'];
    },
    tableData() {
      return this.data ? this.data.list : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : null;
    },
    searchParams() {
      return this.$refs.searchRef.searchParams;
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('report/traffic/listBooking', {
        ...this.searchParams,
        ...params
      });
    },
    handleExport(params) {
      this.$store.dispatch('report/traffic/export', params).then(res => {
        downloadExport(res, '订票费统计.xlsx');
      });
    }
  }
};