import Dropzone from '@/components/Dropzone';
import { uploadUrl, OSS } from '@/config';
import { splitPath } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'DropzoneBox',
  components: {
    Dropzone
  },
  data() {
    return {
      uploadUrl,
      defaultImg: []
    };
  },
  computed: {
    ...mapGetters({
      imageList: 'resource/common/imageList' // 照片信息
    })
  },
  watch: {
    imageList: {
      handler: 'initImage',
      immediate: true
    }
  },
  methods: {
    initImage(list) {
      this.defaultImg = list;
    },
    dropzoneR(file) {
      // 如果有id 说明是编辑资源的信息
      // 新上传的图片是没有id的，所以不作处理，删除
      const {
        id,
        resourceId,
        resourceType,
        url
      } = file;
      if (id) {
        this.$store.dispatch('resource/common/removeImageCache', {
          id,
          resourceId,
          resourceType,
          path: splitPath(url)
        });
      } else {
        const res = file.xhr.response;
        const {
          path
        } = JSON.parse(res).data;
        this.$store.dispatch('resource/common/removeImageAdd', {
          path
        });
      }
    },
    dropzoneS(file) {
      const res = file.xhr.response;
      const {
        url
      } = JSON.parse(res).data;
      const path = splitPath(url);
      const [, name] = path.split(OSS.prefix);
      this.$store.dispatch('resource/common/newImage', {
        name,
        path
      });
    }
  }
};