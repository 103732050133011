import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { TripNumber } from '@/components/common';
import { planEditTraffic } from '@/config/plan';
import { StatusAudit } from '@/components/common';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin } from '@/mixin';
import { getPayTypeLabel, getSelectLabel } from '@/config/select';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin],
  data() {
    return {
      tableData: [],
      deletePath: 'plan/traffic/delete',
      headMergeNum: 2,
      tailMergeNum: planEditTraffic.length - 5,
      hasMergeTail: true,
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId',
      trafficList: 'resource/traffic/trafficData'
    }),
    getTrafficType() {
      return val => {
        return getSelectLabel('bigTraffic', val);
      };
    },
    getTrafficName() {
      return id => {
        if (!this.trafficList) return '';
        const item = this.trafficList.find(it => it.id === id);
        return item && item.label;
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      const list = this.formatData(data);
      this.tableData = this.calcMergeMoney(list);
      this.resetData();
    },
    fetchData() {
      this.$store.dispatch('plan/traffic/fetch', {
        planId: this.currentPlanId
      }).then(data => {
        this.initData(data);
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.orderTransportCompanyTypes || [];
        const {
          id,
          auditProgress,
          cashType
        } = it;
        const item = {
          rowId: id,
          planTransportId: id,
          ap: auditProgress,
          auditProgress,
          transportCompanyId: it.transportCompanyId,
          cashType: getPayTypeLabel(cashType),
          transportType: it.transportType,
          orderDate: it.orderDate,
          // totalPay: it.totalPay,
          remark: it.remark,
          planId: it.planId,
          startPosition: it.startPosition,
          destinationPosition: it.destinationPosition,
          shiftNumber: it.shiftNumber,
          itemList
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              totalPay: v.money,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    calcMergeMoney(list) {
      return list.map(it => {
        it.$total = it.itemList.reduce((acc, cur) => {
          return acc += +cur.money;
        }, 0);
        return it;
      });
    },
    resetData() {
      this.rowspan();
      // this.mergeRowMoney() // 合并单条数据总价
    }
  }
};