var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "接送下单",
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("span", {
    staticClass: "pr10"
  }, [_vm._v("用车类型")]), _c("RadioGroup", {
    attrs: {
      "is-require": "",
      type: "button",
      radios: _vm.radios
    },
    model: {
      value: _vm.curRadio,
      callback: function ($$v) {
        _vm.curRadio = $$v;
      },
      expression: "curRadio"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectFleet", {
    attrs: {
      label: "车队"
    },
    model: {
      value: _vm.form.busCompanyId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "busCompanyId", $$v);
      },
      expression: "form.busCompanyId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("h3", [_vm._v("订单信息")]), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.loading
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };