var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["count-down", {
      disabled: _vm.disabled
    }]
  }, [_vm.isCounting ? _c("span", {
    staticClass: "count-down-text"
  }, [_vm._v(" " + _vm._s(_vm.count)), _c("i", [_vm._v("s")]), _vm._v("后重新获取 ")]) : _vm._e(), !_vm.isCounting ? _c("span", {
    staticClass: "count-down-btn",
    on: {
      click: _vm.handleCounting
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.getCode")) + " ")]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };