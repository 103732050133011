import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { generateTitle } from '@/utils/i18n';

// let isFirst = false
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters({
      menuData: 'system/index/menuData'
    })
  },
  watch: {
    menuData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    generateTitle,
    initData(data) {
      if (!data) return;
      this.list = data.list.map(it => {
        const {
          id,
          name
        } = it;
        it.value = id;
        it.label = this.generateTitle(name);
        return it;
      });
    },
    fetchData(params) {
      this.$store.dispatch('system/index/fetchMenu', params);
    },
    loadMoreData() {
      let {
        currentPage,
        pageSize,
        hasMore
      } = this.menuData;
      hasMore && this.fetchData({
        pageNum: ++currentPage,
        pageSize
      });
    }
  }
};