import { tableMixin, summaryMixin, linkPlanDetailMixin } from '@/mixin';
import { StatusPlanAudit, StatusProgress, Guides, OutOPUsers } from '@/components/common';
export default {
  components: {
    StatusPlanAudit,
    StatusProgress,
    Guides,
    OutOPUsers
  },
  mixins: [tableMixin, summaryMixin, linkPlanDetailMixin],
  props: {
    userId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      statisticsKeys: ['count', 'unIncome', 'totalIncome', 'totalIncomed', 'totalQuotePay', 'totalQuoteGrossProfit']
    };
  }
};