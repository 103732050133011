var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "创建订单",
      width: "1200px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("div", {
    staticClass: "order-create"
  }, [_c("FormInput", {
    ref: "formInputRef",
    attrs: {
      "form-data": _vm.form,
      "back-path": _vm.redirectPath,
      "order-type": _vm.form.orderType,
      "disable-customer": true
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };