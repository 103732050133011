import { PAY_NET_TYPE } from '@/config/payType';
import { downloadPolicy } from '@/utils';
const defaultState = () => ({
  invoiceType: '0'
});
const defaultAccount = () => ({
  bank: '中国工商银行西城支行',
  bankName: '桂林未来旅行信息科技有限公司',
  bankId: '2103209109300103776'
});
const rules = {};
export default {
  data() {
    return {
      PAY_NET_TYPE,
      rules,
      policyLink: 'http://www.weilailvxingshe.com/files/future-system-policy.txt',
      agreement: false,
      visible: false,
      form: defaultState(),
      account: defaultAccount(),
      payType: PAY_NET_TYPE.WECHAT,
      activeName: ''
    };
  },
  methods: {
    show(item) {
      this.visible = true;
      this.reloadForm(item);
    },
    hide() {
      this.visible = false;
    },
    reloadForm(item) {
      this.form = {
        ...this.form,
        ...item
      };
    },
    fetchContractCode() {
      const vsCode = this.form.code;
      return this.$store.dispatch('system/payment/contractCode', {
        vsCode
      }).then(data => {
        return data;
      }).catch(() => {
        this.$bus.tip({
          type: 'danger',
          message: '获取合同编号失败！'
        });
      });
    },
    toWeixinPay(opts) {
      this.$store.dispatch('system/payment/wechatTopUp', opts).then(res => {
        // 微信支付
        if (!res || !res.data) return;

        // 调起二维码
        this.$refs.dialogWxPayRef.show({
          value: res.data.code_url,
          outTradeNo: res.data.out_trade_no,
          money: this.money
        });
      });
    },
    toUnipay(opts) {
      this.$store.dispatch('system/payment/unipayTopUp', opts).then(() => {
        this.$bus.tip({
          message: '订单已创建',
          type: 'success'
        });
      });
    },
    toAlipay(opts) {
      this.$store.dispatch('system/payment/alipayTopUp', opts).then(res => {
        this.alipaySubmit(res);
      });
    },
    alipaySubmit(res) {
      // alipay <form name="punchout_form" method="post" action="https://openapi.alipaydev.com/gateway.do?app_cert_sn=5a5629d68bd6ceaf9387e6d14acaab9f&charset=UTF-8&alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&method=alipay.trade.page.pay&sign=O46XdFO7HqhIpkf2QrNtCdG6kmnPIXT0frpBj6hYdDe0RpbjI1%2BBDFq%2BrQvNR%2FyXIoF8SuBEMhvJoTStG64lp3nZ%2B5SU57rZGu1IrkKj3mPkz7E4YD0u%2FH9LWBth1uy8gwviiT1lKUcRNsd1tPDG7u4W6yIxk1SHBww%2BsUznHMSL%2B5gerhDETAcRMs5HdThz0aTQbqqLmJvIso24Yd5SqYfj3wNb1nv%2Fx5SVi1lwU9OYRzSZvy0oBKZ77qboDj6uCJyGaLFyBtbY4E4oGnaPfanusCyO7MrTcN3Xhi6SR2D8zPDzRr1O0RPVsl77O%2FMOCCGQ2RuqDqIeALzjpidGiA%3D%3D&return_url=http%3A%2F%2Flocalhost%3A1234%2Fplan%2Fsms-send&notify_url=http%3A%2F%2Ftest.weilailvxingshe.com%2Fsystem%2Fapi%2Fpay-notify%2Falipay-notify&version=1.0&app_id=2021000117602658&sign_type=RSA2&timestamp=2023-03-31+17%3A47%3A34&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json"><input type="hidden" name="biz_content" value="{&quot;out_trade_no&quot;:&quot;SMS_32_VASPay_MN054742&quot;,&quot;total_amount&quot;:0.01,&quot;subject&quot;:&quot;短信&quot;,&quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;}"><input type="submit" value="立即支付" style="display:none" ></form>

      // 加载页面
      document.querySelector('body').innerHTML = res;
      document.forms[0].submit();
    },
    handlePay(opts) {
      if (!this.agreement) {
        this.$bus.tip({
          message: '请先勾选同意协议',
          type: 'warning'
        });
        return;
      }
      const returnUrl = window.location.href;
      opts = {
        ...opts,
        ...this.form,
        returnUrl
      };
      const isWeixinPay = this.payType === PAY_NET_TYPE.WECHAT;
      const isAlipayPay = this.payType === PAY_NET_TYPE.ALIPAY;
      const isUnipayPay = this.payType === PAY_NET_TYPE.UNIONPAY;
      isWeixinPay && this.toWeixinPay(opts);
      isAlipayPay && this.toAlipay(opts);
      isUnipayPay && this.toUnipay(opts);
    },
    generateNumber(year) {
      const initNumber = '0001';
      return `WL${year}-${initNumber}`;
    },
    splitDate(timeStr) {
      return timeStr.split('-');
    },
    handleDownload() {
      const date = new Date();
      const year = date.getFullYear();
      const cMonth = date.getMonth() + 1;
      const cDate = date.getDate();
      const {
        startDate,
        endDate,
        count,
        money
      } = this.form;
      const [sYear, sMonth, sDate] = this.splitDate(startDate);
      const [eYear, eMonth, eDate] = this.splitDate(endDate);
      const data = {
        number: this.generateNumber(year),
        money: count * money,
        year: count,
        s_year: sYear,
        s_month: sMonth,
        s_date: sDate,
        e_year: eYear,
        e_month: eMonth,
        e_date: eDate,
        c_year: year,
        c_month: cMonth,
        c_date: cDate
      };
      this.fetchContractCode().then(number => {
        downloadPolicy({
          filePath: '/policy.docx',
          data: {
            ...data,
            number
          },
          fileName: `系统服务协议${year}.docx`
        });
      });
    },
    submit() {
      this.$emit('on-submit');
    }
  }
};