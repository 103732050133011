import "core-js/modules/es.array.push.js";
import TopTitle from '../reserve/components/TopTitle.vue';
import InputResource from '@/components/common/Input/InputResource.vue';
import { parseDate } from '@/utils';
import { hasBooked } from '@/utils/status';
import { mapGetters } from 'vuex';
import { orderScenic as columnData } from '@/config/resourceOrder';
import { SELECTOR, TICKET_MAP } from '@/config';
const defaultState = () => ({
  orderDate: parseDate(new Date()),
  scenicName: '',
  ticketType: '',
  ticketList: [],
  count: 1,
  remarks: '',
  visible: true,
  planId: -1,
  headCompanyId: -1
});
export default {
  components: {
    TopTitle,
    InputResource
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columnData,
      title: '景区',
      tableData: [],
      btnLoading: false,
      lastScenicId: null,
      lastScenicName: null,
      bindScenicList: [],
      companyId: '',
      companyCode: '',
      ticketMap: {}
    };
  },
  computed: {
    hasBooked,
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId',
      currentOrder: 'order/index/currentOrder'
    }),
    tableTitle() {
      return this.showTitle ? this.title : '';
    }
  },
  watch: {
    data: {
      handler: function (list) {
        this.tableData = list;
      },
      immediate: true
    }
  },
  methods: {
    fetchBindScenic() {
      const fetchPath = 'resource/scenic/fetchBindScenic';
      const companyId = this.companyId;
      this.$store.dispatch(fetchPath, {
        companyId
      }).then(data => {
        if (!data) return;
        this.bindScenicList = data.map(it => {
          Object.keys(TICKET_MAP).map(key => {
            if (it.name.indexOf(key) > -1) {
              it.value = TICKET_MAP[key];
            }
          });
          return {
            ...it,
            label: it.name
          };
        });
      });
    },
    handleAdd() {
      this.tableData.push(defaultState());
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (!id) {
        this.tableData.splice(scope.$index, 1);
      }
    },
    selectResource(row, item) {
      const {
        code,
        id,
        value
      } = item;
      this.companyId = id;
      this.companyCode = code;
      row.companyId = id;
      row.companyName = value;
      this.fetchBindScenic();
    },
    selectScenic(row, item) {
      row.visible = false;
      row.ticketType = '';
      if (!item) return;
      row.scenicName = item.name;
      row.ticketList = item ? SELECTOR[item.value] : [];
      row.scenicId = item ? item.id : -1;
      setTimeout(() => {
        row.visible = true;
      }, 20);
    }
  }
};