import { PAY_TYPE } from '@/config/payType';
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  computed: {
    data() {
      const obj = {
        guideSignTotal: 0,
        guideTotal: 0,
        signTotal: 0,
        alipayTotal: 0
      };
      this.tableData.forEach(it => {
        const payType = (it.payType || it.cashType).toUpperCase();
        if (payType == PAY_TYPE.GUIDESIGN) {
          obj.guideSignTotal += Number(it.totalSum);
        }
        if (payType == PAY_TYPE.GUIDE) {
          obj.guideTotal += Number(it.totalSum);
        }
        if (payType == PAY_TYPE.SIGN) {
          obj.signTotal += Number(it.totalSum);
        }
        if (payType == PAY_TYPE.ALIPAY_E_CODE) {
          obj.alipayTotal += Number(it.totalSum);
        }
      });
      return obj;
    }
  }
};