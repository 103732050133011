import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 订单管理

import Layout from '@/layout';
const orderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/order/team',
  name: 'order',
  meta: {
    title: 'order',
    icon: 'icon-puhuoweituo'
  },
  children: [{
    path: '/order/team',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/team'))),
    name: 'orderTeam',
    // 团队输单
    meta: {
      title: 'orderTeam',
      icon: 'icon-group'
    }
  }, {
    path: '/order/team-input-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/team/teamDetail'))),
    name: 'orderTeamInputDetail',
    // 查看团队输单
    meta: {
      title: 'orderTeamInputDetail',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/team-input-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/team/teamCreate'))),
    name: 'orderTeamInput',
    // 新增团队输单
    meta: {
      title: 'orderTeamInput',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/team-input-update',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/team/teamUpdate'))),
    name: 'orderTeamInputUpdate',
    // 更新团队输单
    meta: {
      title: 'orderTeamInputUpdate',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/team-input-clone',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/team/teamClone'))),
    name: 'orderTeamInputClone',
    // 复制团队输单
    meta: {
      title: 'orderTeamInputClone',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/personal',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal'))),
    name: 'orderPersonal',
    // 散客输单
    meta: {
      title: 'orderPersonal',
      icon: 'icon-user'
    }
  }, {
    path: '/order/personal-input-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal/personalCreate'))),
    name: 'orderPersonalInput',
    // 新增散客输单
    meta: {
      title: 'orderPersonalInput',
      icon: 'icon-user',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/personal-input-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal/personalDetail'))),
    name: 'orderPersonaInputDetail',
    // 查看散客输单
    meta: {
      title: 'orderPersonaInputDetail',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/personal-input-update',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal/personalUpdate'))),
    name: 'orderPersonaInputUpdate',
    // 更新散客输单
    meta: {
      title: 'orderPersonaInputUpdate',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/personal-input-clone',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal/personalClone'))),
    name: 'orderPersonaInputClone',
    // 复制散客输单
    meta: {
      title: 'orderPersonaInputClone',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/reserve',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/reserve/index'))),
    name: 'orderReserve',
    // 预控输单
    meta: {
      title: 'orderReserve',
      icon: 'icon-kongzhizhongxin'
    }
  }, {
    path: '/order/reserve-input',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/reserve/reserveCreate'))),
    name: 'orderReserveInput',
    // 预控输单
    meta: {
      title: 'orderReserveInput',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/reserve-booking',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/reserve/reserveBooking'))),
    name: 'orderReserveBooking',
    // 预控下单
    meta: {
      title: 'orderReserveBooking',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/order-update',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/personal/orderUpdate'))),
    name: 'orderUpdate',
    // 团队编辑
    meta: {
      title: 'orderUpdate',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/turn-in',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/turnInReceive'))),
    name: 'orderTurnInReceive',
    // 外转接收
    meta: {
      title: 'orderTurnInReceive',
      icon: 'icon-view'
    }
  }, {
    path: '/order/turn-in-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/turnInReceive/receiveDetail'))),
    name: 'orderTurnInReceiveDetail',
    // 外转接收详情
    meta: {
      title: 'orderTurnInReceiveDetail',
      icon: 'icon-view',
      auth: true
    },
    hidden: true
  },
  // {
  //   path: '/order/turn-out',
  //   component: () => import(/* webpackChunkName: "order" */ '@/views/order/turnOutReceive'),
  //   name: 'orderTurnOutReceive', // 内转接收
  //   meta: { title: 'orderTurnOutReceive', icon: 'icon-zhuanban' },
  //   hidden: true,
  // },
  {
    path: '/order/plan-all',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/planAll'))),
    name: 'orderPlanAll',
    // 所有计划
    meta: {
      title: 'orderPlanAll',
      icon: 'icon-tongzhigonggao'
    }
  }, {
    path: '/order/order-all',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/orderAll'))),
    name: 'orderAll',
    // 所有订单
    meta: {
      title: 'orderAll',
      icon: 'icon-icon'
    }
  }, {
    path: '/order/order-all2',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/orderAll'))),
    name: 'orderAll2',
    // 所有订单2
    meta: {
      title: 'orderAll2',
      icon: 'el-icon-s-operation'
    }
  }, {
    path: '/order/order-audit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/orderAudit'))),
    name: 'orderAudit',
    // 订单审核
    meta: {
      title: 'orderAudit',
      icon: 'el-icon-s-promotion'
    }
  }, {
    path: '/order/order-audit-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/orderAudit/auditDetail'))),
    name: 'orderAuditDetail',
    // 查看订单审核
    meta: {
      title: 'orderAuditDetail',
      auth: true
    },
    hidden: true
  }, {
    path: '/order/recycle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/recycle'))),
    name: 'orderRecycle',
    // 回收站
    meta: {
      title: 'orderRecycle',
      icon: 'icon-delete'
    }
  }, {
    path: '/order/mall',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/mall/index'))),
    name: 'orderMall',
    // 商城订单
    meta: {
      title: 'orderMall',
      icon: 'el-icon-sell'
    }
  }, {
    path: '/order/tourist-blacklist',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/touristBlacklist/index'))),
    name: 'orderTouristBlacklist',
    // 游客查询
    meta: {
      title: 'orderTouristBlacklist',
      icon: 'el-icon-user'
    }
  }, {
    path: '/order/resource-plan',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/order/resourcePlan/index'))),
    name: 'orderResourcePlan',
    // 资源计划
    meta: {
      title: 'orderResourcePlan',
      icon: 'icon-tongzhigonggao'
    }
  }, {
    path: '/order/inquire-bus',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/inquire/bus/index'))),
    name: 'inquireBus',
    // 车队询价
    meta: {
      title: 'inquireBus',
      icon: 'icon-tongzhigonggao'
    }
  }]
};
export default orderRouter;