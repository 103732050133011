var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "policy"
  }, [_c("div", {
    staticClass: "policy-content"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "show-header": false
    },
    on: {
      "row-click": _vm.showDialog
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "notifyType",
      label: "类型"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "标题"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "状态",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.isRead ? _c("span", [_vm._v("已读")]) : _c("img", {
          attrs: {
            src: _vm.newIcon,
            width: "32"
          }
        })];
      }
    }])
  })], 1)], 1), _c("Pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.onPage
    }
  }), _c("DialogPolicy", {
    ref: "dialogRef",
    on: {
      reload: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };