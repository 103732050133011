/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchRestaurant, deleteRestaurant, createOrUpdateRestaurant, fetchGroupList } from '@/api/resource/restuarant';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { TYPES, DICTYPES } from '@/config/resource';
import { formatCharData, formatDicData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  restaurantData: null,
  restaurantGroupData: null,
  restaurantDicData: null,
  restaurantDataOrigin: null,
  restaurantLog: null
});
const state = dfaultState();
const getters = {
  restaurantData: state => state.restaurantData,
  restaurantGroupData: state => state.restaurantGroupData,
  restaurantDicData: state => state.restaurantDicData,
  restaurantDataOrigin: state => state.restaurantDataOrigin,
  restaurantLog: state => state.restaurantLog
};
const actions = {
  fetch({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchRestaurant(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表 (集团)
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchGroupList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.restaurant
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.restaurant
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchAgreement({
    rootGetters
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.restaurant
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        list
      } = res.data;
      // 更新当前协议内容列表
      if (rootGetters['resource/common/curAgreementId']) {
        const findItem = list.find(it => it.id === rootGetters['resource/common/curAgreementId']);
        this.dispatch('resource/common/agreementChild', findItem);
      }
    });
  },
  // 获取有效协议
  fetchAgreementTypeList({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.restaurant
    }; // TYPES.restaurant
    return this.dispatch('resource/common/fetchAgreementTypeList', opts);
  },
  addAgreementItem({
    commit
  }, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateRestaurant(opts).then(res => {
      const {
        data
      } = res;
      dispatch('fetchList');
      commit('UPDATE', data);
      return data;
    });
  },
  delete({
    commit,
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      if (!delFiles.length) return;
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteRestaurant(id);
  },
  // 查询旅监局字典
  getTravelSuperviseeDic({
    commit
  }) {
    return getTravelSuperviseeDic({
      action: 'DIC',
      dicType: DICTYPES.restaurant
    }).then(res => {
      let {
        data
      } = res;
      data = formatDicData(data);
      commit('FETCH_DICDATA', data);
      return res;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.restaurantData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.restaurantGroupData = data;
  },
  FETCH_DICDATA: (state, data) => {
    state.restaurantDicData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.restaurantDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.restaurantLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.restaurantData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.restaurantData.list.filter(it => it.id !== id);
    state.restaurantData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};