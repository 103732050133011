import { SelectDictionary } from '@/components/common';
import { dictionaryMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { parseDate, parseTime } from '@/utils';
export default {
  components: {
    SelectDictionary
  },
  mixins: [dictionaryMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {},
      targetLabel: '抵达时间',
      targetType: 1,
      btnLoading: false,
      currentOrderData: '',
      formatTime: 'yyyy-MM-dd HH:mm'
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    getTitle() {
      return this.isUpdate ? '修改接送' : '新增接送';
    },
    getTargetLabel() {
      // shuttleType 接送类型
      // stationType 站点类型
      // togetherPosition 市内中转
      const {
        shuttleType,
        stationType
      } = this.form;

      // 接团
      if (shuttleType == 'pick') {
        if (stationType == 'train') {
          return '到站时间';
        }
        if (stationType == 'plane') {
          return '抵达时间';
        }
      }
      // 送团
      if (shuttleType == 'send') {
        if (stationType == 'train') {
          return '发车时间';
        }
        if (stationType == 'plane') {
          return '起飞时间';
        }
      }
      // 市内中转
      if (shuttleType == 'city') {
        return '集合时间';
      }
      return '抵达时间';
    }
  },
  methods: {
    show(data) {
      const {
        cityType,
        taskType,
        shuttleType
      } = data;
      this.form = data;
      this.form.cityType = cityType.toLowerCase();
      this.form.taskType = taskType.toLowerCase();
      this.form.shuttleType = shuttleType.toLowerCase();
      // this.form.shuttleDate = new Date(shuttleDate)
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    selectTarget(val) {
      this.form.stationType = val;
    },
    selectCityType(val) {
      this.form.taskCity = 'city';
      this.targetType = val;
    },
    selectPosition(val) {
      this.form.desPosition = val.label;
    },
    changeStationDateTime(val) {
      const dateArr = parseTime(val, '{y}-{m}-{d} {h}:{i}');
      this.form.shiftDateTime = val;
      this.form.shuttleDate = dateArr;
    },
    selectStation(station) {
      const {
        shuttleDate: date,
        shuttleType,
        taskType: stationType
      } = this.form;

      // 火车
      if (stationType == 'train') {
        const {
          inTime,
          sendTime
        } = station;
        const curTime = shuttleType == 'pick' ? inTime : sendTime;
        const [year, month, day] = parseDate(date).split('-');
        const [hours, minutes] = curTime.split(':');
        const rMonth = month; // - 1
        this.form.shuttleDate = `${year}-${this.toDouble(rMonth)}-${this.toDouble(day)}`;
        this.form.shiftDateTime = `${year}-${this.toDouble(rMonth)}-${this.toDouble(day)} ${hours}:${minutes}:${minutes}`;
      }
      // 飞机
      if (stationType == 'plane') {
        const {
          aTime,
          dTime
        } = station;
        const curTime = shuttleType == 'pick' ? aTime : dTime;
        const {
          year,
          month,
          day,
          hours,
          minutes
        } = this.formatTableData(curTime);
        const rMonth = month; // - 1

        this.form.shuttleDate = `${year}-${this.toDouble(rMonth)}-${this.toDouble(day)}`;
        this.form.shiftDateTime = `${year}-${this.toDouble(rMonth)}-${this.toDouble(day)} ${hours}:${minutes}:${minutes}`;
      }
    },
    toDouble(str) {
      if (!str) return;
      return str.toString().padStart(2, '0');
    },
    formatTableData(curTime) {
      const [dateVal, timeVal] = curTime.split(' ');
      let [year, month, day] = dateVal.split('-');
      let [hours, minutes, seconds] = timeVal.split(':');
      return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
      };
    },
    handleCreate() {
      const opts = this.beforeCommit([this.form]);
      this.$store.dispatch('order/index/createOrUpdatePickup', opts).then(() => {
        this.$parent.handleSearch();
        this.$bus.tip();
        this.hide();
      });
    },
    beforeCommit(list) {
      if (!list.length) return [];
      return list.map(it => {
        it.shiftDateTime = it.shiftDateTime ? parseTime(it.shiftDateTime) : '';
        it.togetherDateTime = it.togetherDateTime ? parseTime(it.togetherDateTime) : '';
        return it;
      });
    },
    fetchTrafficTime() {
      const {
        shuttleDate: date,
        shiftNumber,
        taskType: stationType
      } = this.form;
      if (!date) {
        this.$bus.tip({
          type: 'warning',
          message: '日期未填写！'
        });
        return;
      }
      if (!shiftNumber) {
        this.$bus.tip({
          type: 'warning',
          message: '航班号或火车车次未填写！'
        });
        return;
      }

      // 简单判断大交通类型 train 是火车 plane 是飞机
      // const reg = /[a-z]/i
      if (stationType != 'train' && stationType != 'plane') {
        this.$bus.tip({
          type: 'warning',
          message: '航班类型是不是选错了？'
        });
        return;
      }
      const formatDate = parseDate(date);
      let stationId;
      if (stationType == 'train') {
        stationId = 1;
      }
      if (stationType == 'plane') {
        stationId = 2;
      }
      const opts = {
        date: formatDate,
        type: stationId,
        shiftNumber
      };
      this.btnLoading = true;
      this.$store.dispatch('app/fetchTrafficTime', opts).then(data => {
        this.$store.commit('app/STATION_DATA', data);
        this.$bus.emit('show:station', {
          type: +stationId,
          data,
          to: 'dialog'
        });
        this.btnLoading = false;
      });
    }
  }
};