// 按单位收款
import request from '@/utils/request';
export const completeRequest = params => {
  return request({
    url: '/order/ka/pageCashComplete-by-plan',
    method: 'get',
    params
  });
};
export const cashRequest = data => {
  return request({
    url: '/order/ka/add-pre-LogCash',
    method: 'post',
    data
  });
};