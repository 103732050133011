import { searchMixin } from '@/mixin';
import { SelectStaff } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectStaff
  },
  mixins: [searchMixin],
  data() {
    return {
      arriveDate: [parseDate()],
      inquiryDate: [],
      searchParams: {},
      inquiryUserId: ''
    };
  },
  computed: {
    data() {
      return this.$store.getters['inquire/bus/data'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.$emit('on-search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // 搜索
    handleSearch() {
      const search = this.searchParams;
      this.$emit('on-search', search);
    }
  }
};