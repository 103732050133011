var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tableData.length ? _c("table", {
    staticClass: "table-tpl"
  }, [_c("tbody", [_vm.rowTitle ? _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.rowTitle))]), _c("span", {
    staticClass: "ml20"
  }, [_vm._v(_vm._s(_vm.subTitle))])])]) : _vm._e(), _c("tr", [_c("th", {
    staticClass: "th-title",
    attrs: {
      rowspan: _vm.getTotalLength
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm._l(_vm.column, function (item, index) {
    return _c("th", {
      key: index
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  })], 2), _vm._l(_vm.tableData, function (item, index) {
    return _vm._l(item.list, function (row, rIdx) {
      return _c("tr", {
        key: rIdx + index + 50
      }, [rIdx == 0 ? _c("td", {
        staticClass: "td-name",
        style: {
          width: _vm.column[0] ? _vm.column[0].width : "auto"
        },
        attrs: {
          rowspan: item.list.length
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), _vm._l(_vm.getColumn, function (content, cIdx) {
        return [void 0, rIdx == 0 && content.mergeRow ? _c("td", {
          key: index + cIdx + 10,
          attrs: {
            rowspan: item.mergeRowCount,
            width: content && content.width ? content.width : "auto"
          }
        }, [_vm._v(" " + _vm._s(row[content.prop]) + " "), content.slot == "remark" ? [_c("div", [_vm._v(_vm._s(row.reimbRemark))])] : _vm._e()], 2) : _c("td", {
          key: index + cIdx + 100,
          attrs: {
            width: content && content.width ? content.width : "auto"
          }
        }, [content.slot == "customerInfo" ? [_c("CustomerInfo", {
          attrs: {
            name: content.name
          }
        })] : _vm._e(), content.slot == "remark" ? [_c("div", [_vm._v(_vm._s(row[content.prop]))]), _c("div", [_vm._v(_vm._s(row.reimbRemark))])] : content.split ? [_vm._v(" " + _vm._s(_vm.formatMoney(row[content.prop])) + " ")] : [_vm._v(" " + _vm._s(row[content.prop]) + " ")]], 2)];
      }), _vm.subTotal && rIdx == 0 ? _c("td", {
        staticClass: "td-summary",
        attrs: {
          rowspan: item.list.length
        }
      }, [_vm._v(" " + _vm._s(_vm.formatSubTotal(item.totalStr)) + " ")]) : _vm._e(), rIdx == 0 && index == 0 ? _c("td", {
        staticClass: "td-title",
        attrs: {
          rowspan: _vm.getTotalLength
        }
      }, [_vm._v(" " + _vm._s(_vm.total) + " ")]) : _vm._e()], 2);
    });
  })], 2)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };