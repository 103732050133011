/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:10
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-09-29 10:10:25
 * @Description:
 */
// import { systemStateMap } from '@/config/systemConfig'
// import { pickSystemConfig } from '@/utils'

// const CREDIT_MONEY = 0
import { Credits } from '@/components/common';
export default {
  components: {
    Credits
  },
  data() {
    return {
      creditValueState: false,
      // 授信额度启用状态
      createPermission: false
      // message: '',
    };
  },
  computed: {
    // creditData() {
    //   return this.$store.getters['resource/custom/credit']
    // },
    teamFundMoney() {
      return this.$store.getters['order/index/teamFundMoney'];
    },
    customerCredit() {
      return this.creditData[this.orderType];
    },
    visibleNotice() {
      return this.creditValueState;
    },
    configData() {
      return this.$store.getters['system/systemConf/configData'];
    }
  }

  // watch: {
  //   creditData: {
  //     handler: function(val) {
  //       const data = val[this.orderType]

  //       if (!data) return
  //       this.creditValueState = pickSystemConfig(
  //         systemStateMap.credit,
  //         this.configData
  //       )
  //       if (!this.creditValueState) return // 是否开启信用额度状态

  //       // if (Number(this.teamFundMoney) - Number(data.balance) >= CREDIT_MONEY) {
  //       //   this.createPermission = true
  //       //   this.message = data.content
  //       // } else {
  //       //   this.createPermission = false
  //       //   this.message = ''
  //       // }

  //       this.message = data.content
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
};