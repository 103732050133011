var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      "statistics-keys": _vm.StatisticsKeys,
      total: _vm.total,
      statistics: _vm.statistics,
      border: ""
    },
    on: {
      page: function ($event) {
        return _vm.$emit("page", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        row
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.directPlanDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.planNumber) + " ")]), row.customerTripNumber ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.directOrderDetail(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.customerTripNumber) + " ")]) : _vm._e()];
      }
    }, {
      key: "customerName",
      fn: function ({
        row
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: row.customerName
          }
        })];
      }
    }, {
      key: "totalCollect",
      fn: function ({
        row
      }) {
        return [row.collects && row.collects.length ? _vm._l(row.collects, function (item, index) {
          return _c("p", {
            key: index
          }, [_c("span", {
            style: {
              color: item.auditProgress != "WAITAUDIT" ? "#409EFF" : ""
            }
          }, [_vm._v(" " + _vm._s(item.type) + "：" + _vm._s(item.total) + " ")])]);
        }) : [_vm._v(" " + _vm._s(row.totalCollect) + " ")]];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({
        row
      }) {
        return _vm._l(row.subsidies, function (item, index) {
          return _c("p", {
            key: index
          }, [_vm._v(" " + _vm._s(item.type) + ": " + _vm._s(item.total) + " ")]);
        });
      }
    }, {
      key: "tripDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startPlanDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endPlanDate) + " ")];
      }
    }, {
      key: "orderDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(row.endTripDate) + " ")];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "contact",
      fn: function ({
        row
      }) {
        return [row.customerContactVos.length ? _vm._l(row.customerContactVos, function (item) {
          return _c("div", {
            key: item.id
          }, [_vm._v(" " + _vm._s(item.contact) + " "), _c("br"), _vm._v(" " + _vm._s(item.mobilePhone) + " ")]);
        }) : _vm._e()];
      }
    }, {
      key: "touristContact",
      fn: function ({
        row
      }) {
        return [_c("Members", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "fee",
      fn: function ({
        row
      }) {
        return [_vm.getReview(row) ? _vm._l(row.caCustomerFees, function (item) {
          return _c("div", {
            key: item.id
          }, [_vm._v(" " + _vm._s(_vm.getDetail(item)) + " ")]);
        }) : [_c("el-tag", {
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.onClickFees(row);
            }
          }
        }, [_vm._v(" 修改待审核 ")])]];
      }
    }, {
      key: "checkAccountTime",
      fn: function ({
        row
      }) {
        return [_c("check-account-time", {
          attrs: {
            item: row
          }
        })];
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({
        row
      }) {
        return [_c("InputNum", {
          attrs: {
            disabled: _vm.isDisabled(row)
          },
          model: {
            value: row.checkAccountMoney,
            callback: function ($$v) {
              _vm.$set(row, "checkAccountMoney", $$v);
            },
            expression: "row.checkAccountMoney"
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          attrs: {
            disabled: _vm.isDisabled(row)
          },
          model: {
            value: row.checkAccountRemark,
            callback: function ($$v) {
              _vm.$set(row, "checkAccountRemark", $$v);
            },
            expression: "row.checkAccountRemark"
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        row
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("CheckBox", {
          attrs: {
            item: {
              row
            }
          },
          on: {
            "on-check": _vm.handleCheckout,
            "on-log": _vm.handleLog
          }
        })];
      }
    }, {
      key: "billCheck",
      fn: function () {
        return [_c("el-checkbox", {
          on: {
            change: _vm.changeCheckAll
          },
          model: {
            value: _vm.billCheckAll,
            callback: function ($$v) {
              _vm.billCheckAll = $$v;
            },
            expression: "billCheckAll"
          }
        }), _c("span", {
          staticClass: "pl5"
        }), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.changeCheckAll(!_vm.billCheckAll);
            }
          }
        }, [_vm._v(" 全选对账 ")])];
      },
      proxy: true
    }])
  }, [_c("template", {
    slot: "extra"
  }, [_vm._v(" " + _vm._s(_vm.calcCheckedMoney(_vm.tableData)) + " ")])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };