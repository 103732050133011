import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { generateTitle } from '@/utils/i18n';
import SizeSelect from '@/components/SizeSelect';
import LangSelect from '@/components/LangSelect';
import Screenfull from '@/components/Screenfull';
import AppLink from '../Link';
import Logo from '../Logo';
import { getUserData } from '@/utils';
export default {
  components: {
    AppLink,
    Logo,
    SizeSelect,
    LangSelect,
    Screenfull
  },
  data() {
    return {
      activePath: '/'
    };
  },
  computed: {
    ...mapGetters({
      routes: 'permission/routes',
      routeList: 'permission/rolePermissionList',
      avatar: 'user/avatar',
      userData: 'user/userData',
      roleData: 'system/role/roleData',
      theme: 'settings/theme'
    }),
    isTestDev() {
      // const map = ['localhost', '127.0.0.1', 'test.']
      // const isDev = map.some(val => window.location.host.indexOf(val) > -1)
      // return isDev ? '测试服' : ''
      const {
        userName
      } = getUserData();
      return userName.length - 2 == userName.lastIndexOf('-1') ? '测试服' : '';
    },
    getActiveColor() {
      return this.theme;
    },
    getRole() {
      if (!this.roleData) return '';
      const item = this.roleData.list.find(it => it.id == this.userData.roleId);
      return item ? item.name : '';
    },
    getRoutes() {
      return this.routes;
    },
    getUserAccount() {
      return this.userData && this.userData.userName;
    },
    getCompany() {
      if (this.userData) {
        const {
          travelAgency
        } = this.userData;
        return `${travelAgency ? travelAgency.name : ''}`;
      }
      return '';
    },
    getUserName() {
      return this.userData && this.userData.realName;
    }
  },
  watch: {
    $route: {
      handler: function (router) {
        const {
          path
        } = router;
        const findRoute = this.routeList.find(it => {
          if (path.indexOf(it.path) > -1 && it.subMenus.some(sub => path.indexOf(sub.path) > -1)) {
            return it;
          }
        });
        this.activePath = findRoute ? findRoute.path : '/';
      },
      immediate: true
    }
  },
  methods: {
    generateTitle,
    showSetting() {
      this.$store.dispatch('settings/toggleSetting', true);
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login`);
    }
  }
};