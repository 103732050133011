var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "确认件上传",
      width: "560px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleSubmit,
      cancel: _vm.hide
    }
  }, [_c("el-upload", {
    ref: "uploadRef",
    attrs: {
      action: _vm.uploadUrl,
      headers: _vm.headers,
      accept: _vm.acceptType,
      limit: 1,
      "auto-upload": false,
      "file-list": _vm.fileList,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v("点击上传")]), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(" 只能上传jpg/png文件，且不超过5M ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };