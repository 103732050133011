/* eslint-disable no-empty-pattern */

import { billCheckRequest, logRequest } from '@/api/billCheck';
import { LOG_YPES } from '@/config/resource';
const defaultState = () => ({
  channelData: null
});
const state = defaultState();
const getters = {
  channelData: state => state.channelData
};
const actions = {
  billCheck({}, opt) {
    return new Promise((resolve, reject) => {
      return billCheckRequest(opt).then(res => {
        const {
          data
        } = res;
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    });
  },
  // 日志
  fetchLog({}, opts) {
    const types = `${LOG_YPES.order},${LOG_YPES.plan},${LOG_YPES.outTransfer},${LOG_YPES.sign},${LOG_YPES.audit},${LOG_YPES.bill}`;
    opts = {
      types,
      ...opts
    };
    return new Promise((resolve, reject) => {
      logRequest(opts).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};