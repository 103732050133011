export const ColumnsShow = [{
  attrs: {
    label: '案例号',
    prop: 'code'
  }
}, {
  attrs: {
    label: '发生时间',
    prop: 'occurrenceTime'
  }
},
// { attrs: { label: '案例类型', prop: 'type' } },
{
  attrs: {
    label: '操作计调',
    prop: 'personInCharge1'
  }
}, {
  attrs: {
    label: '客服计调',
    prop: 'personInCharge2'
  }
}, {
  attrs: {
    label: '事情经过',
    prop: '$details'
  }
}, {
  attrs: {
    label: '后果',
    prop: '$consequence'
  }
},
// { attrs: { label: '问题所在', prop: '$reason' } },
// { attrs: { label: '处理方式', prop: '$handlingMethod' } },
// { attrs: { label: '改进方案', prop: '$improveThePlan' } },
// { attrs: { label: '状态', prop: '' }, slot: 'status' },
{
  attrs: {
    label: '操作',
    prop: ''
  },
  slot: 'action'
}];
export const Columns = [{
  attrs: {
    label: '案例号',
    prop: 'code'
  }
}, {
  attrs: {
    label: '发生时间',
    prop: 'occurrenceTime'
  }
},
// { attrs: { label: '案例类型', prop: 'type' } },
{
  attrs: {
    label: '操作计调',
    prop: 'personInCharge1'
  }
}, {
  attrs: {
    label: '客服计调',
    prop: 'personInCharge2'
  }
}, {
  attrs: {
    label: '事情经过',
    prop: '$details'
  }
}, {
  attrs: {
    label: '后果',
    prop: '$consequence'
  }
},
// { attrs: { label: '问题所在', prop: '$reason' } },
// { attrs: { label: '处理方式', prop: '$handlingMethod' } },
// { attrs: { label: '改进方案', prop: '$improveThePlan' } },
{
  attrs: {
    label: '状态',
    prop: ''
  },
  slot: 'status'
}, {
  attrs: {
    label: '操作',
    prop: ''
  },
  slot: 'action'
}];