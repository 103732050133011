import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
export default {
  mixins: [tableMixin, shopMixin],
  data: () => ({
    // avgeageKey: [10, 11, 12], // 之前计算人均的方式，已废弃
    avgeageMaps: [{
      hasChild: true,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: 'shopProfit',
      // 当hasChild为true时，key 为 对象字符串

      divisor: 'adultCount',
      // 人数字段
      dividend: 'totalBuyMoney',
      // 总数
      value: 'shopPerCapita' // 平均值
    },
    // 计算人均
    {
      hasChild: false,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: '',
      // 当hasChild为true时，key 为 对象字符串

      divisor: 'adultCount',
      // 人数字段
      dividend: "totalBuyMoney0",
      // 总数
      value: 'shopPerCapita0' // 平均值
    } // 计算人均
    ]
  }),
  methods: {}
};