import { parseDate } from '@/utils';
export { getDepartmentLevel } from '@/utils';
export const DateState = {
  year: new Date().getFullYear(),
  month: new Date().getMonth()
};
export const State = {
  year: 'year',
  month: 'month',
  start: 'start',
  end: 'end',
  salesmanType: '1',
  // 1-客服计调，2-业务员
  departLevel: '1',
  // 1级，2级
  startDateS: parseDate(new Date(DateState.year, DateState.month, '01')),
  startDateE: parseDate()
};
export const defaultState = () => ({
  dateType: State.start,
  // start 发团 end完团
  searchType: State.month,
  // year, month
  salesmanType: State.salesmanType,
  startDateS: State.startDateS,
  startDateE: State.startDateE
});