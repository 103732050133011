// 全国旅游系统

import request from '@/utils/request';
// import axios from 'axios'
// import { getRequestHeader } from '@/utils'

const serverType = process.env.NODE_ENV === 'development' ? 'DEBUG' : 'PRODUCT';
// const service = axios.create({
//   // baseURL: process.env.VUE_APP_COUNTRY_TOURAGE_API,
//   baseURL: 'http://tg-team.stage.tourage.cn',
//   timeout: 6000 * 10,
//   adapter: 'http' | 'fetch',
// })

// service.interceptors.request.use(
//   config => {
//     config.headers = Object.assign(config.headers || {}, {
//       ...getRequestHeader(),
//     })

//     console.log('config:', config)
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// service.interceptors.response.use(
//   response => {
//     return Promise.resolve(response)
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

function tourteamPost(data) {
  return request({
    url: '/order/plan/req-country-e-trip-post',
    method: 'post',
    data,
    timeout: 6000 * 60 * 5
  });
}
function tourteamGet(data) {
  return request({
    url: '/order/plan/req-country-e-trip-get',
    method: 'post',
    data,
    timeout: 6000 * 60 * 5
  });
}

// 上传团队行程单
export function syncPlanRequest(param) {
  return tourteamPost({
    param,
    path: '/team/china',
    serverType
  });
}

// 取消团队
export function cancelPlanRequest(param) {
  return tourteamPost({
    param,
    path: '/team/cancel',
    serverType
  });
}

// 团队名单
export function touristListRequest(param) {
  return tourteamPost({
    param,
    path: '/team/list',
    serverType
  });
}

// 团队状态
export function planTeamStatusRequest(param) {
  return tourteamPost({
    param,
    path: '/team/status',
    serverType
  });
}

// 团队打印
export function planTeamPrintRequest(param) {
  return tourteamPost({
    param,
    path: '/team/pdf/list',
    serverType
  });
}

// 城市信息查询
export function cityByNameRequest(name) {
  return tourteamGet({
    param: {},
    path: `/team/basedata/areas/${name}`,
    serverType
  });
}

// 导游查询
// https://tourgroup.mr.mct.gov.cn/basedata/guidedetailbycomp/2/%E6%9B%BE%E7%A5%A5%E7%A6%8F