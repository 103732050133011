import "core-js/modules/es.array.reduce.js";
import { tableMixin, rowProfitMixin } from '@/mixin';
import { decimalSplit } from '@/utils';
export default {
  mixins: [tableMixin, rowProfitMixin],
  data() {
    return {};
  },
  methods: {
    onChecksLink(row) {
      this.$parent.onLink({
        row,
        type: 'checks'
      });
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 求和
        // if (column.property != 'action') {
        sums[index] = this.sumTotal(values);
        // 如果是付账/对账总数
        if (index == 1) {
          const accompanyCounts = this.tableData.map(item => Number(item.accompanyCount));
          const adultCounts = this.tableData.map(item => Number(item.adultCount));
          const childCounts = this.tableData.map(item => Number(item.childCount));
          const accompanyCount = this.sumTotal(accompanyCounts);
          const adultCount = this.sumTotal(adultCounts);
          const childCount = this.sumTotal(childCounts);
          sums[index] = `${adultCount}大${childCount}小${accompanyCount}陪`;
        }
        // sums[index]
        // }
      });
      return sums;
    },
    sumTotal(values) {
      let sums = '';
      sums = values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
      return decimalSplit(sums);
    }
  }
};