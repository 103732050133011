import "core-js/modules/es.array.push.js";
import ReceptionFeeTable from './ReceptionFeeTable';
import ReceptionProxyTable from './ReceptionProxyTable';
import ReceptionRefundTable from './ReceptionRefundTable';
import { DialogLog } from '@/components/common';
import { auditMixin, systemMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { LOG_YPES } from '@/config/resource';
import { mapGetters } from 'vuex';
export default {
  name: 'Reception',
  components: {
    DialogLog,
    ReceptionFeeTable,
    ReceptionProxyTable,
    ReceptionRefundTable
  },
  mixins: [auditMixin, systemMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fee: TYPES.fee,
      collect: TYPES.collect,
      subsidy: TYPES.subsidy,
      activeName: TYPES.fee,
      tabIndex: 0,
      feeList: [],
      collectList: [],
      subsidyList: []
    };
  },
  computed: {
    ...mapGetters({
      planOrderData: 'plan/index/planOrderData'
    })
  },
  mounted() {
    this.fetchData();
    this.$bus.on('audit:teamfund:reload', this.fetchData);
  },
  methods: {
    fetchData() {
      let {
        id,
        planId
      } = this.$route.query;
      planId = planId || id;
      this.$store.commit('plan/index/FETCH_STATUS', true);
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/index/fetchTeamFund', {
        planId
      }).then(data => {
        this.$store.commit('order/reception/CUR_RECEPTION_LIST', data);
        this.$store.commit('plan/index/FETCH_STATUS', false);
        const {
          feeList,
          collectList,
          subsidyList
        } = this.handleData(data);
        // 客户费用
        this.feeList = feeList;
        // 客户代收
        this.collectList = collectList;
        // 客户返款
        this.subsidyList = subsidyList;
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
      // tab切换的时候更新类型
      this.$store.commit('plan/index/CUR_TYPE', this.activeName.toUpperCase());
    },
    handleData(list) {
      let feeList = [];
      let collectList = [];
      let subsidyList = [];
      if (!list || !list.length) {
        return {
          feeList,
          collectList,
          subsidyList
        };
      }

      // 获取列表对应的数据
      const pickList = (list, key) => {
        return list.map(it => {
          return it[key] || [];
        });
      };
      const customerFeeList = pickList(list, 'customerFeeList');
      const customerCollectList = pickList(list, 'customerCollectList');
      const customerSubsidyList = pickList(list, 'customerSubsidyList');
      list.forEach((it, idx) => {
        // 客户费用
        feeList = [...feeList, ...this.formatData(it, customerFeeList[idx], 'customerFeeList', idx)];
        // 客户代收
        collectList = [...collectList, ...this.formatData(it, customerCollectList[idx], 'customerCollectList', idx)];
        // 客户返款
        subsidyList = [...subsidyList, ...this.formatData(it, customerSubsidyList[idx], 'customerSubsidyList', idx)];
      });
      return {
        feeList,
        collectList,
        subsidyList
      };
    },
    formatData(item, list, key, index) {
      const {
        adultCount,
        accompanyCount,
        customerTripNumber,
        babyCount,
        childCount,
        customerName,
        mTotalIncome,
        totalCollect,
        mTotalSubsidy,
        members,
        checkAccountStatus,
        id,
        auditProgress,
        unIncomeDeductSubsidy
      } = item;
      if (!list.length) {
        list.push({
          id,
          adultCount,
          accompanyCount,
          customerTripNumber,
          babyCount,
          childCount,
          customerName,
          mTotalIncome,
          totalCollect,
          mTotalSubsidy,
          members,
          checkAccountStatus,
          auditProgress,
          unIncomeDeductSubsidy: this.refundNotOffset // 从系统配置中获取状态
        });
      }
      return list.map(it => {
        const {
          id,
          type,
          count,
          price,
          total,
          times,
          remarks,
          auditProgress
        } = it;
        if (key === 'customerCollectList') {
          it.isAgencyCollect = true;
          it.unit = it.isAgencyCollect ? '单位代收' : '导游代收';
          let proxyStatus = '';
          proxyStatus = it.isAgencyCollect ? 'isAgencyCollect' : 'isGuideCollect';
          this.$set(it, 'proxy', proxyStatus);
        }
        const opt = {
          index,
          type,
          count,
          price,
          total,
          times,
          remarks,
          typeId: type,
          resourceId: id,
          rowId: item.id,
          ap: auditProgress,
          auditProgress,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          planId: this.$route.query.id,
          adultCount,
          accompanyCount,
          customerTripNumber,
          babyCount,
          childCount,
          customerName,
          members,
          unIncomeDeductSubsidy,
          checkAccountStatus
        };
        if (key === 'customerFeeList') {
          opt.totalMoney = mTotalIncome;
        }
        if (key === 'customerCollectList') {
          opt.totalMoney = totalCollect;
        }
        if (key === 'customerSubsidyList') {
          opt.totalMoney = mTotalSubsidy;
        }
        return opt;
      });
    },
    // 选择审核全部下拉框处理数据
    auditAllFormat(val) {
      this.feeList = this.feeList.map(it => {
        it.ap = val;
        return it;
      });
    },
    handleUpdate() {
      // 只审核团款一个就可以
      const feeList = this.processData(this.feeList, TYPES.fee);
      const collectList = this.processData(this.collectList, TYPES.collect);
      const subsidyList = this.processData(this.subsidyList, TYPES.subsidy);
      const mergeList = [feeList, collectList, subsidyList];
      const data = mergeList[this.tabIndex];
      return this.handleAudit(data).then(() => {
        this.fetchData();
        this.$bus.tip();
      });
    },
    handleAudit(dataList) {
      const loading = this.$bus.loading();
      // const dataList = this.processData(data, type)
      return new Promise((resolve, reject) => {
        this.$store.dispatch('audit/index/updateAuditOne', dataList).then(() => {
          loading.close();
          resolve();
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    processData(dataList, type) {
      const result = [];
      const {
        id
      } = this.$route.query;
      dataList.forEach(it => {
        const item = {
          auditProgress: it.ap,
          auditTypeEnum: type,
          isCollectMenu: false,
          status: this.formatStatus(it),
          // 审核状态
          planId: it.planId || id,
          resourceId: it.resourceId // 子项没有rowId 取 id
        };
        result.push(item);
      });
      return result;
    },
    handleRowLog(type) {
      const ids = this.planOrderData.map(it => it.id);
      const itemIds = ids.toString();
      this.$store.dispatch('system/index/systemLog', {
        types: LOG_YPES.order,
        itemIds,
        subTypes: type
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};