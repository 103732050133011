import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TopTitle from '../components/TopTitle';
import { typeMixin } from '@/mixin';
import { Guides } from '@/components/common';
import { PersonCount } from '@/components/common';
const tableData = [{
  rows: [{
    label: '团号',
    span: 2
  }, {
    content: 'planNumber',
    span: 4
  }, {
    label: '总人数',
    span: 2
  }, {
    content: 'adultCount',
    slot: 'number',
    span: 4
  }]
}, {
  rows: [{
    label: '导游',
    span: 2
  }, {
    content: 'guides',
    span: 4,
    slot: 'guides'
  }, {
    label: '产品',
    span: 2
  }, {
    content: 'productName',
    span: 4
  }]
}];
const tableHead = [{
  content: 'serialnumber',
  label: '序号',
  slot: 'serialnumber'
}, {
  content: 'name',
  label: '客人名称',
  span: 3
}, {
  content: 'customerTripNumber',
  label: '小团号',
  span: 3
}, {
  content: 'people',
  label: '人数',
  span: 2,
  slot: 'people'
}, {
  content: '',
  label: 'RJ',
  span: 3
}, {
  content: '',
  label: 'YS',
  span: 3
}, {
  content: '',
  label: 'SC',
  span: 3
}, {
  content: '',
  label: 'CJ',
  span: 3
}, {
  content: '',
  label: 'BP',
  span: 3
}];
export default {
  components: {
    TopTitle,
    PersonCount,
    Guides
  },
  mixins: [typeMixin],
  data() {
    return {
      data: null,
      tableData,
      tableHead
    };
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchShopping', id).then(data => {
        data.shops = this.formatData(data.shops);
        this.data = data;
        this.$emit('onMounted');
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const map = {};
      const result = [];
      list.forEach((item, index) => {
        if (item.customerTripNumber && !map[item.customerTripNumber]) {
          map[item.customerTripNumber] = [];
          map[item.customerTripNumber].push(item);
        }
        if (!item.customerTripNumber) {
          map[index] = [];
          map[index].push(item);
        }
      });
      Object.keys(map).map(key => {
        const names = map[key].reduce((acc, cur) => {
          acc = [...acc, cur.name];
          return acc;
        }, []);
        result.push({
          ...map[key][0],
          name: names.slice(0, 2).join(' ')
        });
      });
      return result;
    }
  }
};