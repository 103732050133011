export default {
  route: {
    home: '首页',
    dashboard: '首页',
    case: '案例库',
    caseComplaint: '投诉问题案例',
    casePraise: '表扬喜报案例',
    documentation: '文档',
    backToTop: '返回顶部',
    profile: '个人中心',
    theme: '换肤',
    avatarUpload: '头像上传',
    charts: '图表',
    tab: 'Tab',
    form: '表单',
    createArticle: '创建文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    dropExcel: '拖拽Excel文件到这里或者',
    Browse: '选择',
    // 上传
    upload: '上传',
    ClickUpload: '点击上传',
    Cancel: '取消',
    Confirm: '确定',
    Default: '默认',
    Medium: '中等',
    Small: '小号',
    Mini: '最小',
    // 企业码管理
    enterprise: '企业码管理',
    enterpriseCheck: '对账',
    enterpriseGroup: '按团统计',
    enterpriseQuotaManage: '额度管理',
    enterpriseQuotaLimit: '额度预算',
    // 订单管理
    order: '订单管理',
    orderTeam: '团队输单',
    orderTeamInput: '新增团队输单',
    orderTeamInputUpdate: '编辑团队输单',
    orderTeamInputDetail: '查看团队详情',
    orderTeamInputClone: '复制团队输单',
    orderPersonal: '散客输单',
    orderPersonalInput: '新增散客输单',
    orderPersonaInputUpdate: '编辑散客输单',
    orderPersonaInputDetail: '查看散客详情',
    orderPersonaInputClone: '复制散客输单',
    orderDetail: '查看团队信息',
    orderUpdate: '编辑团队信息',
    orderReserve: '预控输单',
    orderReserveInput: '新增预控输单',
    orderReserveBooking: '资源预定',
    orderTurnInReceive: '同行接收',
    orderTurnInReceiveDetail: '外转接收详情',
    orderTurnOutReceive: '内转接收',
    orderPlanAll: '所有计划',
    orderAll: '所有订单',
    orderAll2: '所有订单2',
    orderRecycle: '回收站',
    orderMall: '商城订单',
    orderAudit: '订单审核',
    orderAuditDetail: '查看审核详情',
    orderTouristBlacklist: '游客查询',
    orderResourcePlan: '资源计划',
    inquireBus: '车队询价',
    // checkFlightTicket: '机票查询',

    // 计调操作
    plan: '计调操作',
    planTeamArrange: '发团预定安排',
    planTeamRoleArrange: '发团预定安排2',
    planOneKeyup: '一键上传数据',
    planOneKeyupBoat: '兴坪船票',
    planOneKeyupBoatDetail: '兴坪船票详情',
    planTurnOut: '外转安排',
    planGuideArrange: '导游排班表',
    planGroupGuideArrange: '集团导游排班表',
    planTouristArrange: '更新游客购物明细',
    planGuideStatistics: '导游数据查询',
    planTeamArrangeTravel: '上传旅监资料',
    planTeamArrangeTravelCountry: '上传全国旅监资料',
    planTeamArrangeEdit: '编辑发团预定安排',
    planSmsSend: '导游短信通知',
    planEditGuide: '导游',
    planEditScenic: '景区',
    planEditHotel: '酒店',
    planEditRestaurant: '餐饮',
    planEditFleet: '车队',
    planEditInsurance: '保险',
    planEditTransport: '交通',
    planEditPickup: '接送',
    planEditQuota: '额度申请',
    planEditBorrow: '借款申请',
    planEditCollect: '导游代收',
    planEditReception: '外转',
    planEditShop: '购物',
    planEditOtherIncome: '其他收入',
    planEditOtherPay: '其他支出',
    planTeamArrangeDetail: '单团明细',
    planPickup: '接送预定安排',
    planPickupBatch: '批量接送安排',
    planHotel: '房调安排',
    planHotelDetail: '房调安排详情',
    planOrderTurnOut: '订单转出操作',
    planCarSort: '车辆排期查询',
    planGuestSmsSend: '游客短信通知',
    planGuestSmsReply: '游客短信回复',
    planTeamNoticeTpl: '出团通知模版',
    planEnterpriseCode: '企业码额度',
    planBoatPersonal: '散客船票计划',
    // 购物管理
    shop: '购物管理',
    shopInput: '购物录入',
    shopInputCreate: '购物录入创建',
    shopInputDetail: '购物店商品信息',
    shopFinance: '购物账务',
    shopAudit: '购物审核',
    shopCheck: '购物对账',
    shopCollect: '购物收款',
    shopCollectCreate: '新增购物收款',
    shopCollectDetail: '查看购物收款',
    shopCollectSubsidy: '购物二返收款',
    shopCollectSubsidyCreate: '新增购物二返',
    shopCollectSubsidyDetail: '查看购物二返',
    shopTotal: '购物统计',
    shopStatistics: '购物店统计',
    shopStaticsEntry: '购物进店表',
    guideStatistics: '导游统计',
    guideLeaderStatistics: '导游组长统计',
    guideManageStatistics: '导管统计',
    guideManageAnalyze: '导管派团分析表',
    shopGuideAnalysis: '单导游数据分析表',
    goodsStatistics: '商品统计',
    singleStatistics: '单团统计',
    profitAnalysis: '客户利润分析',
    teamStatistics: '组团统计',
    teamSmallStatistics: '区域客户明细表',
    shopProductCustom: '单产品客户分析表',
    shopCustomAnalyze: '单客户数据分析表',
    shopCustomAreaAnalyze: '区域客户分析表',
    shopCustom: '客户统计',
    lineStatistics: '线路统计',
    shopArea: '区域统计',
    // 审核管理
    audit: '审核管理',
    auditSingle: '单团审核',
    auditSingleDetail: '单团审核详情',
    auditEditFee: '审核团款',
    auditEditGuide: '审核导游',
    auditEditScenic: '审核景区',
    auditEditAddScenic: '审核加点',
    auditEditHotel: '审核酒店',
    auditEditRestaurant: '审核餐饮',
    auditEditFleet: '审核车队',
    auditEditInsurance: '审核保险',
    auditEditTransport: '审核交通',
    auditEditPickup: '审核接送',
    auditEditBorrow: '审核导游借款',
    auditEditCollect: '审核导游代收',
    auditEditReception: '审核外转',
    auditEditShop: '审核购物',
    auditEditOtherIncome: '审核其他收入',
    auditEditOtherPay: '审核其他支出',
    auditEditSubsidyRate: '审核客户后返',
    auditSingleEdit: '报账审核',
    singleFile: '单团归档',
    proxyAudit: '代收审核',
    // 报表统计
    report: '报表统计',
    reportWelcome: '报表首页',
    reportBooking: '订票费统计',
    reportDepPerformance: '部门业绩统计',
    reportCollectCustom: '渠道客户汇总',
    reportCollectDetail: '应收明细汇总',
    reportCollectBalanceYear: '应收余额年汇总',
    reportCollectBalanceMonth: '应收余额月汇总',
    reportSupplier: '供应商数据统计',
    reportCollect: '财务应付报表',
    reportFleet: '车队汇总统计',
    reportShuttle: '接送汇总统计',
    reportFleetOrder: '车队订单统计',
    reportFleetTotal: '车队汇总统计',
    reportHotel: '酒店汇总统计',
    reportHotelOrder: '酒店订单统计',
    reportHotelTotal: '酒店汇总统计',
    reportHotelRoom: '酒店按房统计',
    roomReserve: '预定用房统计',
    reportInsurance: '保险汇总统计',
    reportInsuranceOrder: '保险订单统计',
    reportInsuranceTotal: '保险汇总统计',
    reportScenic: '景区汇总统计',
    reportScenicAdd: '加点汇总统计',
    reportScenicOrder: '景区订单统计',
    reportScenicTotal: '景区汇总统计',
    reportRestaurant: '餐厅汇总统计',
    reportRestaurantOrder: '餐厅订单统计',
    reportRestaurantTotal: '餐厅汇总统计',
    reportTraffic: '交通汇总统计',
    reportTrafficOrder: '交通订单统计',
    reportTrafficTotal: '交通汇总统计',
    reportCustom: '客户数据统计',
    reportCustomOrder: '客户订单统计',
    reportCustomTotal: '客户汇总统计',
    reportCustomGross: '客户毛利汇总',
    reportTurnOut: '外转财务统计',
    reportTurnOutOrder: '外转订单统计',
    reportTurnOutTotal: '外转汇总统计',
    reportGuide: '导游统计',
    reportGuideBorrow: '导游借款统计',
    reportGuideBorrowDetail: '导游借款明细',
    reportGuideTotal: '导游汇总统计',
    reportGuideSettle: '导游结算明细表',
    reportGuideCaptain: '导游绩效统计',
    reportCompany: '公司财务统计',
    reportCompanyOrder: '公司订单统计',
    reportCompanyTotal: '公司汇总统计',
    reportOtherPay: '其他支出明细',
    reportOtherPayTotal: '其他支出汇总',
    reportOtherCollect: '其他收入明细',
    reportOtherIncome: '其他收入汇总',
    reportCustomProxy: '客户代收统计',
    reportCustomPay: '客户返款统计',
    reportCustomCollect: '客户应收统计',
    reportCustomSubsidy: '客户返款统计',
    reportProfitCustom: '收客利润统计',
    reportProfitTeam: '单团利润统计',
    reportProfitTeamTerse: '单团利润统计',
    reportProfitTeamSettle: '单团结算利润统计',
    reportProfitTeamSmall: '小团利润统计',
    reportProfitTeamSmallSettle: '小团利润结算统计',
    reportBalance: '资金平衡报表',
    reportIncome: '营收状况统计',
    reportCountTotal: '年度人数汇总',
    reportProfitTotal: '毛利汇总报表',
    reportSaleAnalysis: '产品销量分析',
    reportSaleTotal: '销售情况统计',
    reportCustomAnalysis: '客户业绩分析',
    reportDepartment: '部门业绩分析',
    reportOutreach: '外联计调业绩',
    reportPlan: '责任计调业绩',
    reportArea: '客源地域分析',
    reportAge: '客源年龄分析',
    reportSource: '客源数据分析',
    reportOrder: '订单地域分析',
    reportExportExcel: '报表导出',
    reportCarRoom: '用车用房报表',
    reportTravelAgencyIncom: '社收明细统计',
    reportTransfer: '外转汇总统计',
    reportGuideStatistics: '导游数据统计',
    reportGuideTeamStatistics: '导游组长绩效统计',
    reportGuideReimb: '导游报账统计',
    reportOrderList: '小团订单列表',
    reportOrderFleet: '车队订单统计',
    reportOrderScenic: '景区订单统计',
    reportOrderRestaurant: '餐厅订单统计',
    reportOrderTraffic: '交通订单统计',
    reportRoomRate: '房价统计表',
    reportLettersEveryDay: '天天快报',
    reportReceivable: '应收账款汇总表',
    reportReceivableDetail: '应收账款明细表',
    reportReceivableBalance: '应收账款余额表',
    reportAccountsBalance: '应收余额表',
    reportOutOPUser: '销售客服业绩',
    reportSaleUser: '业务员业绩',
    reportDutOPUser: '操作计调业绩',
    reportGroupTotal: '团队汇总统计',
    reportEnterpriseCode: '企业码额度预算',
    reportEnterpriseCodeRegiment: '企业码按团统计',
    reportRefund: '返款汇总统计',
    // 资源管理
    resource: '资源管理',
    resourceGuide: '导游管理',
    resourceGuideManager: '导管管理',
    resourceGuideCaptain: '导游组长',
    resourceFleet: '车队管理',
    resourceDriver: '司机管理',
    resourceVehicle: '车辆管理',
    resourceHotel: '酒店管理',
    resourceHotelCreate: '新增酒店',
    resourceHotelEdit: '编辑酒店',
    resourceHotelDetail: '酒店详情',
    resourceInsurance: '保险管理',
    resourceSelfPay: '自费管理',
    resourceScenic: '景区管理',
    resourceRestaurant: '餐厅管理',
    resourceShop: '购物店管理',
    resourceTraffic: '交通管理',
    resourceLine: '线路管理',
    resourceProduct: '产品管理',
    resourceProductCreate: '产品创建',
    resourceProductUpdate: '产品更新',
    resourceProductClone: '产品克隆',
    resourceProductPreview: '产品预览',
    resourceCustom: '客户管理',
    resourceCompany: '公司管理',
    resourceOther: '其他单位',
    resourceTicket: '票据管理',
    resourceGroup: '集团管理',
    // 办公管理
    work: '办公管理',
    workInput: '办公费用录入',
    workStream: '办公费用流水',
    workTotal: '办公费用统计',
    workSubject: '办公费用科目',
    // 系统配置
    system: '系统管理',
    systemConf: '系统配置',
    systemAdapter: '对接配置',
    systemAccounting: '会计科目',
    systemDepartmentManage: '部门管理',
    systemStaffManage: '员工管理',
    systemStaffAuth: '员工授权',
    systemStaffGroup: '集团角色授权',
    systemRoleManage: '角色管理',
    systemRoleAgency: '角色代办管理',
    systemDictionary: '字典管理',
    systemCompany: '公司信息',
    systemNotice: '公告管理',
    systemGuideNotice: '导游常用信息',
    systemButtJoint: '对接配置',
    systemProcess: '审核管理',
    systemCompanyGoal: '公司目标',
    systemCustomGoal: '客户目标',
    // 签单
    ticket: '签单操作',
    ticketSearch: '签单查询',
    ticketManage: '签单管理',
    ticketInput: '签单录入',
    ticketCheckout: '签单核销',
    ticketInputDetail: '签单录入详情',
    ticketCheckDetail: '签单审核详情',
    // 对账
    checkBill: '对账管理',
    checkBillWelcome: '对账首页',
    checkBillScenic: '景区对账',
    checkBillHotel: '酒店对账',
    checkBillRestaurant: '餐厅对账',
    checkBillFleet: '车队对账',
    checkBillShuttle: '接送对账',
    checkBillTraffic: '交通对账',
    checkBillInsurance: '保险对账',
    checkBillShopping: '购物对账',
    checkBillReception: '外转对账',
    checkBillCollect: '应收对账',
    checkBillOtherIncome: '其他收入对账',
    checkBillOtherPay: '其他支出对账',
    checkBillGroup: '集团对账',
    checkBillRefund: '返款对账',
    checkBillChannel: '渠道应收对账',
    checkBillEcode: '企业码对账',
    // 销账管理
    bill: '销账管理',
    billWelcome: '销账首页',
    billRegimentCollect: '按团号收款',
    billRegimentCollectCustomer: '按团收客户团款',
    billRegimentCollectCustomerCreate: '收团款-按团',
    billDownUnit: '按单位收款',
    billCollect: '客户团款',
    billCollectCreate: '新增单位收款',
    billCollectUpdate: '单位收款修改',
    billCollectDetail: '单位收款详情',
    billCollectShop: '购物收款',
    billCollectShopCreate: '新增购物收款',
    billCollectShopDetail: '购物收款详情',
    billCollectShopSubsidy: '购物二返收款',
    billCollectShopSubsidyCreate: '新增购物二返收款',
    billCollectShopSubsidyDetail: '购物二返收款详情',
    billRegimentPay: '按团号付款',
    billRegimentPayScenic: '付景区',
    billRegimentPayHotel: '付酒店',
    billRegimentPayFleet: '付车队',
    billRegimentPayPickup: '付接送',
    billRegimentPayRestaurant: '付餐厅',
    billRegimentPayTraffic: '付交通',
    billRegimentPayInsurance: '付保险',
    billRegimentPayReception: '付外转',
    billRegimentPayCustomer: '付客户返款',
    billRegimentPayScenicCreate: '新增付景区-按团',
    billRegimentPayHotelCreate: '新增付酒店-按团',
    billRegimentPayFleetCreate: '新增付车队-按团',
    billRegimentPayPickupCreate: '新增付接送-按团',
    billRegimentPayRestaurantCreate: '新增付餐厅-按团',
    billRegimentPayTrafficCreate: '新增付交通-按团',
    billRegimentPayInsuranceCreate: '新增付保险-按团',
    billRegimentPayReceptionCreate: '新增付外转-按团',
    billRegimentPayCustomerCreate: '付客户返款-按团',
    billRegimentPayScenicDetail: '付景区详情-按团',
    billRegimentPayHotelDetail: '付酒店详情-按团',
    billRegimentPayFleetDetail: '付车队详情-按团',
    billRegimentPayPickupDetail: '付接送详情-按团',
    billRegimentPayRestaurantDetail: '付餐厅详情-按团',
    billRegimentPayTrafficDetail: '付交通详情-按团',
    billRegimentPayInsuranceDetail: '付保险详情-按团',
    billRegimentPayReceptionDetail: '付外转详情-按团',
    billUnitPay: '按单位付款',
    billUnitPayScenic: '付景区',
    billUnitPayHotel: '付酒店',
    billUnitPayRestaurant: '付餐厅',
    billUnitPayFleet: '付车队',
    billUnitPayPickup: '付接送',
    billUnitPayTraffic: '付交通',
    billUnitPayInsurance: '付保险',
    billUnitPayReception: '付外转',
    billUnitPayOther: '付其他',
    billUnitPayCustomer: '付客户返款',
    billUnitPayScenicCreate: '新增付景区-单位',
    billUnitPayHotelCreate: '新增付酒店-单位',
    billUnitPayFleetCreate: '新增付车队-单位',
    billUnitPayPickupCreate: '新增付接送-单位',
    billUnitPayRestaurantCreate: '新增付餐厅-单位',
    billUnitPayTrafficCreate: '新增付交通-单位',
    billUnitPayInsuranceCreate: '新增付保险-单位',
    billUnitPayReceptionCreate: '新增付外转-单位',
    billUnitPayOtherCreate: '新增付其他-单位',
    billUnitPayCustomerCreate: '新增付客户返款-单位',
    billUnitPayScenicUpdate: '付景区修改-单位',
    billUnitPayHotelUpdate: '付酒店修改-单位',
    billUnitPayFleetUpdate: '付车队修改-单位',
    billUnitPayPickupUpdate: '付接送修改-单位',
    billUnitPayRestaurantUpdate: '付餐厅修改-单位',
    billUnitPayTrafficUpdate: '付交通修改-单位',
    billUnitPayInsuranceUpdate: '付保险修改-单位',
    billUnitPayReceptionUpdate: '付外转修改-单位',
    billUnitPayOtherUpdate: '付其他修改-单位',
    billUnitPayCustomerUpdate: '付客户修改-单位',
    billUnitPayScenicDetail: '付景区详情-单位',
    billUnitPayHotelDetail: '付酒店详情-单位',
    billUnitPayFleetDetail: '付车队详情-单位',
    billUnitPayPickupDetail: '付接送详情-单位',
    billUnitPayRestaurantDetail: '付餐厅详情-单位',
    billUnitPayTrafficDetail: '付交通详情-单位',
    billUnitPayInsuranceDetail: '付保险详情-单位',
    billUnitPayReceptionDetail: '付外转详情-单位',
    billUnitPayOtherDetail: '付其他详情-单位',
    billUnitPayCustomerDetail: '付客户返款-单位',
    billGuideBorrowApprove: '导游借款审批',
    billGuideBorrowApproveBatch: '批量借款审批',
    billOtherIncome: '其他收入核销',
    billOtherIncomeCreate: '新增其他收入核销',
    billOtherIncomeDetail: '其他收入核销详情',
    billOtherPay: '其他支出核销',
    billOtherPayCreate: '新增其他支出核销',
    billOtherPayDetail: '其他支出核销详情',
    billAdvance: '预收付款管理',
    billCompany: '按公司去下账',
    billProxy: '客户代收核销',
    billRefund: '客户返款核销',
    billGuideBorrow: '导游单团借款',
    billGuideSingle: '导游单团报账',
    billGuideSingleDetail: '导游单团报账详情',
    billGuideBatch: '导游批量报账',
    billGuideBatchDetail: '导游批量报账详情',
    billPreCollect: '预收付款管理',
    billCash: '现金日记流水',
    billAccount: '账户管理',
    billAccountDetail: '账户详情',
    billGroup: '集团销账',
    billGroupCreate: '新增集团销账',
    billGroupDetail: '查看集团销账',
    billGroupUpdate: '更新集团销账',
    // 下单管理
    resourceOrder: '下单管理',
    resourceOrderHotel: '酒店订单',
    resourceOrderFleet: '车队订单',
    resourceOrderRestaurant: '餐厅订单',
    resourceOrderScenic: '景区订单',
    // 数据分析
    analysis: '数据分析',
    analysisIndex: '数据分析',
    analysisArea: '区域分析表',
    analysisCustom: '客户分析表',
    analysisLine: '线路分析表',
    analysisGuide: '导游分析表',
    analysisIncome: '应收款分析表',
    analysisPerformance: '个人业绩',
    analysisDepartment: '部门业绩',
    analysisIncomeDetail: '应收款明细表',
    analysisShop: '购物店分析表',
    analysisSoilShop: '土产店数据分析',
    analysisSale: '业务员分析表',
    analysisCustomKpi: '营业额/人数/毛利总表',
    analysisMemberAge: '年龄段分析表',
    analysisMemberMap: '游客分布图',
    analysisGroup: '团型分析表',
    analysisComparison: '数据比对',
    analysisTargetCompany: '公司目标',
    analysisTargetDepartment: '部门目标',
    analysisTargetStaff: '员工目标',
    // 增值服务
    payment: '增值服务',
    paymentIndex: '增值服务',
    // 报价管理
    offer: '报价管理',
    offerIndex: '订单报价',
    formOfferAdd: '新增报价',
    formOfferEdit: '重新报价',
    formOfferCopy: '复制报价'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    update: '修改密码',
    phone: '手机号码',
    code: '短信验证码',
    visitCode: '验证码',
    getCode: '获取验证码',
    smsLogin: '短信登陆',
    userLogin: '账号登陆',
    thirdparty: '第三方登录',
    wxLogin: '微信扫码登录',
    thirdpartyTips: ''
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo',
    versionSwitch: '切换版本'
  }
};