var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      width: "960px",
      title: _vm.getTitle,
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "票据名称",
      clearable: "",
      "is-require": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.visible ? _c("SelectBase", {
    attrs: {
      "is-require": "",
      "init-val": _vm.form.type,
      type: "ticketType",
      label: "票据类型"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.form.type == "RESTAURANT" ? _c("SelectRestaurant", {
    attrs: {
      label: "餐厅名称"
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.form.resourceId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "resourceId", $$v);
      },
      expression: "form.resourceId"
    }
  }) : _vm._e(), _vm.form.type == "HOTEL" ? _c("SelectHotel", {
    attrs: {
      label: "酒店名称"
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.form.resourceId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "resourceId", $$v);
      },
      expression: "form.resourceId"
    }
  }) : _vm._e(), _vm.form.type == "SCENIC" ? _c("SelectScenic", {
    attrs: {
      label: "景区名称"
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.form.resourceId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "resourceId", $$v);
      },
      expression: "form.resourceId"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "switch-status"
  }, [_c("SwitchStatus", {
    attrs: {
      label: "是否通用"
    },
    model: {
      value: _vm.form.isCommon,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isCommon", $$v);
      },
      expression: "form.isCommon"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };