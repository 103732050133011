// 数据分析查询类型（报表统计），1-客服计调数据分析，2-业务人员数据分析
export const DataAnalysisSearchType = {
  outOp: 1,
  sale: 2
};

// 审核状态
export const orderAuditStatus = [{
  value: 'ALL',
  label: '全部'
}, {
  value: 'UNCONFIRM',
  label: '未确认'
}, {
  value: 'WAITAUDIT',
  label: '待审核'
}, {
  value: 'OPAUDITED',
  label: '操作计调已审'
},
// { value: 'GUIDEMANAGERAUDITED', label: '导管已审' },
// { value: 'GENERALAUDITED', label: '经理已审' },
{
  value: 'FINANCIALAUDITED',
  label: '财务已审'
}
// { value: 'BOSSAUDITED', label: 'BOSS已审' },
];
// 资源
export const resourceType = {
  TRANSPORT: '大交通',
  BUS: '车队',
  SCENIC: '景区',
  GUIDE: '导游',
  HOTEL: '酒店',
  INSURANCE: '保险',
  SHOP: '购物店',
  RESTAURANT: '餐厅',
  CUSTOMER: '客户',
  COMPANY: '公司',
  SIGN: '签单',
  BANK: '账户',
  PRODUCT: '产品',
  LINE: '线路',
  SELFCOMPANY: '自费'
};

// 付款方式
export const payType = {
  SIGN: '挂账',
  GUIDESIGN: '导游签单',
  ALIPAY_E_CODE: '企业码付',
  AGENCY: '公司转账',
  GUIDE: '导游现付'
};
export const statisticsTeamKeys = ['adultCount', 'childCount', 'accompanyCount', 'totalCustomerIncome', 'totalOutTransferSubsidyIncome', 'totalOtherIncome', 'totalShopIncome', 'totalScenicExtraIncome', 'totalCustomerSubsidyPay', 'totalBusCompanyPay', 'totalHotelQuotePay', 'totalGuideServiceFeePay', 'totalRestaurantPay', 'totalInsurancePay', 'totalOutTransferPay', 'totalShuttlePay', 'totalTransportCompanyPay', 'totalOtherPay', 'totalShopRebatePay', 'totalScenicExtraRebatePay', 'totalScenicInnerQuotePay', 'totalScenicExtraPay', 'totalIncome', 'totalIncomed', 'totalQuotePay', 'totalPayed', 'totalGrossProfit', 'totalGrossMargin', 'totalQuoteGrossProfit', 'totalQuoteGrossMargin', 'totalScenicInnerPay', 'totalScenicExtraQuotePay', 'totalGuideManageFeeIncome', 'totalPay', 'teamTotalPay', 'totalHotelPay'];
const planCustomerNumber = {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': 150
  }
};
const guideName = {
  attrs: {
    prop: 'guideName',
    label: '导游',
    'min-width': 100
  }
};
const hotelReimb = {
  attrs: {
    prop: 'hotelReimb',
    label: '房费'
  }
};
const restaurantReimb = {
  attrs: {
    prop: 'restaurantReimb',
    label: '餐费'
  },
  slot: 'restaurantReimb'
};
const busCompanyReimb = {
  attrs: {
    prop: 'busCompanyReimb',
    label: '车费'
  },
  slot: 'busCompanyReimb'
};
const scenicReimb = {
  attrs: {
    prop: 'scenicReimb',
    label: '景区'
  },
  slot: 'scenicReimb'
};
const otherPayReimb = {
  attrs: {
    prop: 'otherPayReimb',
    label: '其他支出'
  },
  slot: 'otherPayReimb'
};
const shopDriverRebateReimb = {
  attrs: {
    prop: 'shopDriverRebateReimb',
    label: '购物司佣'
  },
  slot: 'shopDriverRebateReimb'
};
const shopAccompanyRebateReimb = {
  attrs: {
    prop: 'shopAccompanyRebateReimb',
    label: '购物陪佣'
  },
  slot: 'shopAccompanyRebateReimb'
};
const scenicDriverRebateReimb = {
  attrs: {
    prop: 'scenicDriverRebateReimb',
    label: '景区司佣'
  },
  slot: 'scenicDriverRebateReimb'
};
const scenicAccompanyRebateReimb = {
  attrs: {
    prop: 'scenicAccompanyRebateReimb',
    label: '景区陪佣'
  },
  slot: 'scenicAccompanyRebateReimb'
};
const guideServiceFeeReimb = {
  attrs: {
    prop: 'guideServiceFeeReimb',
    label: '导服费'
  },
  slot: 'guideServiceFeeReimb'
};
const shopGuideRebateReimb = {
  attrs: {
    prop: 'shopGuideRebateReimb',
    label: '购物导佣'
  },
  slot: 'shopGuideRebateReimb'
};
const scenicGuideRebateReimb = {
  attrs: {
    prop: 'scenicGuideRebateReimb',
    label: '景区导佣'
  },
  slot: 'scenicGuideRebateReimb'
};
const collectReceive = {
  attrs: {
    prop: 'collectReceive',
    label: '代收款'
  },
  slot: 'collectReceive'
};
const borrowReimb = {
  attrs: {
    prop: 'borrowReimb',
    label: '导游借款'
  },
  slot: 'borrowReimb'
};
const guideFund = {
  attrs: {
    prop: 'guideFund',
    label: '导游基金'
  },
  slot: 'guideFund'
};
const guideTax = {
  attrs: {
    prop: 'guideTax',
    label: '税'
  },
  slot: 'guideTax'
};
const scenicReceiveReimb = {
  attrs: {
    prop: 'scenicReceiveReimb',
    label: '景区现收'
  },
  slot: 'scenicReceiveReimb'
};
const otherIncomeReimb = {
  attrs: {
    prop: 'otherIncomeReimb',
    label: '其他收入'
  },
  slot: 'otherIncomeReimb'
};
const travelAgencyPay = {
  attrs: {
    prop: 'travelAgencyPay',
    label: '应报'
  },
  slot: 'travelAgencyPay'
};
const travelAgencyPayed = {
  attrs: {
    prop: 'travelAgencyPayed',
    label: '已报'
  },
  slot: 'travelAgencyPayed'
};
const lacOfPay = {
  attrs: {
    prop: 'lacOfPay',
    label: '欠报'
  },
  slot: 'lacOfPay'
};
const orders = {
  attrs: {
    prop: 'orders',
    label: '订单'
  },
  slot: 'orders'
};
const cashPayment = {
  attrs: {
    prop: 'cashPayment',
    label: '现付'
  },
  slot: 'cashPayment'
};
const companyTransfer = {
  attrs: {
    prop: 'companyTransfer',
    label: '公司转款'
  },
  slot: 'companyTransfer'
};
const sign = {
  attrs: {
    prop: 'sign',
    label: '挂账'
  },
  slot: 'sign'
};
const totalPayed = {
  attrs: {
    prop: 'totalPayed',
    label: '已付'
  },
  slot: 'totalPayed'
};
const unPay = {
  attrs: {
    prop: 'unPay',
    label: '未付'
  },
  slot: 'unPay'
};
const totalPay = {
  attrs: {
    prop: 'totalPay',
    label: '应付'
  },
  slot: 'totalPay'
};
const totalQuotePay = {
  attrs: {
    prop: 'totalQuotePay',
    label: '报价应付'
  },
  slot: 'totalQuotePay'
};
const people = {
  attrs: {
    prop: 'people',
    label: '总人数'
  },
  slot: 'people'
};
const adultCount = {
  attrs: {
    prop: 'adultCount',
    label: '大人',
    'min-width': 60
  }
};
const childCount = {
  attrs: {
    prop: 'childCount',
    label: '小孩',
    'min-width': 60
  }
};
const accompanyCount = {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪',
    'min-width': 60
  }
};
const otherFee = {
  attrs: {
    prop: 'otherFee',
    label: '其他费用'
  },
  slot: 'otherFee'
};
const customerName = {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 120
  },
  slot: 'customerName'
};
const hotelStandard = {
  attrs: {
    prop: 'hotelStandard',
    label: '标准'
  }
};
const hotelArea = {
  attrs: {
    prop: 'hotelArea',
    label: '区域'
  }
};
const remark = {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
};
const checks = {
  attrs: {
    prop: 'checks',
    label: '已对账/总数'
  },
  slot: 'checks'
};
const action = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
const customerTripNumber = {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
};
const count = {
  attrs: {
    prop: 'count',
    label: '数量',
    'min-width': 100
  }
};
const tripDate = {
  attrs: {
    prop: 'tripDate',
    label: '行程日期'
  },
  slot: 'tripDate'
};
const number = {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
};
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '订单日期'
  }
};
const total = {
  attrs: {
    prop: 'total',
    label: '金额',
    'min-width': '100'
  }
};
const customerArea = {
  attrs: {
    prop: 'customerArea',
    label: '区域',
    'min-width': '160'
  }
};
const sales = {
  attrs: {
    prop: 'sales',
    label: '业务员',
    'min-width': 120
  },
  slot: 'sales'
};
// const auditStatus = {
//   attrs: { prop: 'auditStatus', label: '审核状态' },
//   slot: 'auditStatus',
// }
const guides = {
  attrs: {
    prop: 'guides',
    label: '团上导游',
    'min-width': 100
  },
  slot: 'guides'
};
const totalCustomerIncome = {
  attrs: {
    prop: 'totalCustomerIncome',
    label: '团款'
  }
};
const totalOutTransferSubsidyIncome = {
  attrs: {
    prop: 'totalOutTransferSubsidyIncome',
    label: '外转返款'
  }
};
const totalOtherIncome = {
  attrs: {
    prop: 'totalOtherIncome',
    label: '其他收入'
  }
};
const totalShopIncome = {
  attrs: {
    prop: 'totalShopIncome',
    label: '购物'
  }
};
const totalScenicExtraIncome = {
  attrs: {
    prop: 'totalScenicExtraIncome',
    label: '加点'
  }
};
const totalGuideManageFeeIncome = {
  attrs: {
    prop: 'totalGuideManageFeeIncome',
    label: '导游管理'
  }
};
const totalCustomerSubsidyPay = {
  attrs: {
    prop: 'totalCustomerSubsidyPay',
    label: '客户返款'
  }
};
const totalBusCompanyPay = {
  attrs: {
    prop: 'totalBusCompanyPay',
    label: '车队'
  }
};
const totalGuideServiceFeePay = {
  attrs: {
    prop: 'totalGuideServiceFeePay',
    label: '导服'
  }
};
const totalRestaurantPay = {
  attrs: {
    prop: 'totalRestaurantPay',
    label: '餐厅'
  }
};
const totalInsurancePay = {
  attrs: {
    prop: 'totalInsurancePay',
    label: '保险'
  }
};
const totalOutTransferPay = {
  attrs: {
    prop: 'totalOutTransferPay',
    label: '外转'
  }
};
const totalShuttlePay = {
  attrs: {
    prop: 'totalShuttlePay',
    label: '接送'
  }
};
const totalTransportCompanyPay = {
  attrs: {
    prop: 'totalTransportCompanyPay',
    label: '大交通'
  }
};
const totalOtherPay = {
  attrs: {
    prop: 'totalOtherPay',
    label: '其他支出'
  }
};
const totalShopRebatePay = {
  attrs: {
    prop: 'totalShopRebatePay',
    label: '购物佣金'
  }
};
const totalScenicExtraRebatePay = {
  attrs: {
    prop: 'totalScenicExtraRebatePay',
    label: '加点佣金'
  }
};
const totalIncome = {
  attrs: {
    prop: 'totalIncome',
    label: '总收入'
  }
};
const totalIncomed = {
  attrs: {
    prop: 'totalIncomed',
    label: '总已收'
  }
};
const teamTotalPay = {
  attrs: {
    prop: 'totalPayed',
    label: '总已付'
  }
};
const orderInfo = {
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    'min-width': 160
  },
  slot: 'orderInfo'
};
const price = {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': 150
  }
};
const operatorUser = {
  attrs: {
    prop: 'operatorUser',
    label: '计调',
    'min-width': 120
  },
  slot: 'operatorUser'
};
const recordingFeeReimb = {
  attrs: {
    prop: 'recordingFeeReimb',
    label: '录单费'
  }
};

// 企业码付 TODO:
const enterpriseCode = {
  attrs: {
    prop: 'alipayECodePay',
    label: '企业码付'
  }
};
// const recordingFeeReimbSlot = {
//   ...recordingFeeReimb,
//   slot: 'recordingFeeReimb',
// }

// 客户应收报表数据
export const customCollect = [{
  attrs: {
    prop: 'name',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'contactName',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'orderCount',
    label: '订单数'
  }
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  children: [adultCount, childCount, accompanyCount]
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: 'collect',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'unIncome',
    label: '未收'
  }
}];

// 客户毛利总汇
export const customGross = [{
  attrs: {
    prop: 'name',
    label: '客户'
  }
}, customerArea, {
  attrs: {
    prop: '',
    label: '团队'
  },
  children: [{
    attrs: {
      prop: 'groupAdultCount',
      label: '人数'
    }
  }, {
    attrs: {
      prop: 'groupGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'groupGrossMargin',
      label: '人均毛利'
    }
  }, {
    attrs: {
      prop: 'groupOutOPUserName',
      label: '客服'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '散客'
  },
  children: [{
    attrs: {
      prop: 'individualAdultCount',
      label: '人数'
    }
  }, {
    attrs: {
      prop: 'individualGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'individualGrossMargin',
      label: '人均毛利'
    }
  }, {
    attrs: {
      prop: 'individualOutOPUserName',
      label: '客服'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '合计'
  },
  children: [{
    attrs: {
      prop: 'totalAdultCount',
      label: '人数'
    }
  }, {
    attrs: {
      prop: 'totalGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利'
    }
  }, {
    attrs: {
      prop: 'groupShopBackSubsidy',
      label: '购物后返'
    }
  }, {
    attrs: {
      prop: 'totalShopGrossMargin',
      label: '最终人均'
    }
  }]
}];

// 客户毛利总汇
export const customSubsidy = [planCustomerNumber, customerName, tripDate, number, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'countSlot',
    label: '数量'
  }
}, total, {
  attrs: {
    prop: 'auditUserName',
    label: '审核人'
  }
}, {
  attrs: {
    prop: 'auditTime',
    label: '审核时间'
  }
}, {
  attrs: {
    prop: 'state',
    label: '审核状态'
  },
  slot: 'state'
}];

// 用房用车数据
export const busRoomList = [{
  attrs: {
    prop: 'room',
    label: '用房人数'
  },
  slot: 'room'
}, {
  attrs: {
    prop: 'bus',
    label: '用车人数'
  },
  slot: 'bus'
}];

// 小团利润统计
export const profitTeamSmall = [orderInfo, tripDate, customerName, {
  ...customerArea,
  slot: 'area'
}, guides, operatorUser, sales, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [adultCount, childCount, accompanyCount]
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [totalCustomerIncome, totalOutTransferSubsidyIncome, totalOtherIncome, totalShopIncome, totalScenicExtraIncome, totalGuideManageFeeIncome]
}, {
  attrs: {
    prop: 'expenditure',
    label: '支出'
  },
  children: [totalCustomerSubsidyPay, totalBusCompanyPay, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '酒店'
    }
  }, totalGuideServiceFeePay, totalRestaurantPay, totalInsurancePay, totalOutTransferPay, totalShuttlePay, totalTransportCompanyPay, totalOtherPay, totalShopRebatePay, totalScenicExtraRebatePay, {
    attrs: {
      prop: 'totalScenicInnerQuotePay',
      label: '(景)行程内'
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraQuotePay',
      label: '(景)加点'
    }
  }]
}, {
  attrs: {
    prop: 'summation',
    label: '合计和毛利'
  },
  children: [totalIncome, totalIncomed, {
    attrs: {
      prop: 'totalQuotePay',
      label: '总成本'
    }
  }, teamTotalPay, {
    attrs: {
      prop: 'totalQuoteGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'totalQuoteGrossMargin',
      label: '人均毛利'
    }
  }]
}];

// 小团利润结算统计
export const profitTeamSmallSettle = [orderInfo, tripDate, customerName, {
  ...customerArea,
  slot: 'area'
}, guides, operatorUser, sales, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  children: [adultCount, childCount, accompanyCount]
}, {
  attrs: {
    prop: 'income',
    label: '收入'
  },
  children: [totalCustomerIncome, totalOutTransferSubsidyIncome, totalOtherIncome, totalShopIncome, totalScenicExtraIncome, totalGuideManageFeeIncome]
}, {
  attrs: {
    prop: 'expenditure',
    label: '支出'
  },
  children: [totalCustomerSubsidyPay, totalBusCompanyPay, {
    attrs: {
      prop: 'totalHotelPay',
      label: '酒店'
    }
  }, totalGuideServiceFeePay, totalRestaurantPay, totalInsurancePay, totalOutTransferPay, totalShuttlePay, totalTransportCompanyPay, totalOtherPay, totalShopRebatePay, totalScenicExtraRebatePay, {
    attrs: {
      prop: 'totalScenicInnerPay',
      label: '(景)行程内'
    }
  }, {
    attrs: {
      prop: 'totalScenicExtraPay',
      label: '(景)加点'
    }
  }]
}, {
  attrs: {
    prop: 'summation',
    label: '合计和毛利'
  },
  children: [{
    attrs: {
      prop: 'totalGrossProfit',
      label: '毛利'
    }
  }, {
    attrs: {
      prop: 'totalGrossMargin',
      label: '人均毛利'
    }
  }]
}];

// 月应收账款
export const incomeReport = [{
  attrs: {
    prop: 'lastMonthBalance',
    label: '上月应收余额'
  }
}, {
  attrs: {
    prop: '',
    label: '本月发生'
  },
  children: [{
    attrs: {
      prop: 'monthIncome',
      label: '本月应收'
    }
  }, {
    attrs: {
      prop: 'branchOfficeCost',
      label: '分公司费用'
    }
  }, {
    attrs: {
      prop: 'monthKeepAccounts',
      label: '收回'
    }
  }, {
    attrs: {
      prop: 'adjust',
      label: '调整'
    }
  }, {
    attrs: {
      prop: 'subtotal',
      label: '小计'
    }
  }]
}, {
  attrs: {
    prop: 'monthBalance',
    label: '本月应余额'
  }
}];

// 毛利总汇
export const reportTotal = [{
  attrs: {
    prop: 'name',
    label: '组团社',
    'min-width': 160
  }
}, customerArea];

// 毛利总汇
export const PeopleCountList = [{
  attrs: {
    prop: 'sequence',
    label: '编号',
    'min-width': 60
  },
  slot: 'sequence'
}, {
  attrs: {
    prop: 'areaManager',
    label: '区域经理',
    'min-width': 100
  },
  slot: 'areaManager'
}, {
  attrs: {
    prop: 'areaName',
    label: '区域',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'name',
    label: '组团社',
    'min-width': 160
  }
}, {
  attrs: {
    prop: '',
    label: '1月'
  },
  children: [{
    attrs: {
      prop: 'groupCount1',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount1',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '2月'
  },
  children: [{
    attrs: {
      prop: 'groupCount2',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount2',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '3月'
  },
  children: [{
    attrs: {
      prop: 'groupCount3',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount3',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '4月'
  },
  children: [{
    attrs: {
      prop: 'groupCount4',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount4',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '5月'
  },
  children: [{
    attrs: {
      prop: 'groupCount5',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount5',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '6月'
  },
  children: [{
    attrs: {
      prop: 'groupCount6',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount6',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '7月'
  },
  children: [{
    attrs: {
      prop: 'groupCount7',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount7',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '8月'
  },
  children: [{
    attrs: {
      prop: 'groupCount8',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount8',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '9月'
  },
  children: [{
    attrs: {
      prop: 'groupCount9',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount9',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '10月'
  },
  children: [{
    attrs: {
      prop: 'groupCount10',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount10',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '11月'
  },
  children: [{
    attrs: {
      prop: 'groupCount11',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount11',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '12月'
  },
  children: [{
    attrs: {
      prop: 'groupCount12',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'individualCount12',
      label: '散客'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '全年累计'
  },
  children: [{
    attrs: {
      prop: 'yearGroupCount',
      label: '团队'
    }
  }, {
    attrs: {
      prop: 'yearIndividualCount',
      label: '散客'
    }
  }]
}];

// 其他支出
export const otherPayList = [{
  ...planCustomerNumber,
  slot: 'planNumber'
}, tripDate, {
  attrs: {
    prop: 'otherUnit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, price, count, {
  attrs: {
    prop: 'totalPay',
    label: '总金额',
    'min-width': 120
  },
  slot: 'totalPay'
}, remark, guideName, {
  attrs: {
    prop: 'cashTypeName',
    label: '付款方式'
  }
}
// { attrs: { prop: 'guideRealReimbRemark', label: '报账备注' } },
];
// 其他支出汇总
export const otherPayTotal = [{
  attrs: {
    prop: 'otherUnit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '总金额',
    'min-width': 120
  },
  slot: 'totalPay'
}, {
  attrs: {
    prop: 'cashTypeName',
    label: '付款方式'
  }
}];
// 其他收入汇总
export const otherIncome = [{
  attrs: {
    prop: 'otherUnit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '总金额'
  }
}];

// 其他收入
export const otherIncomeList = [{
  ...planCustomerNumber,
  slot: 'planNumber'
}, tripDate, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, price, count, {
  attrs: {
    prop: 'totalIncome',
    label: '总金额',
    'min-width': 120
  },
  slot: 'totalIncome'
}, remark, guideName
// { attrs: { prop: 'guideRealReimbRemark', label: '报账备注' } },
];

// 外转总汇
export const TransferList = [{
  attrs: {
    prop: 'customerName',
    label: '外转'
  },
  slot: 'customerName'
}, customerArea, orders, totalPay, {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额'
  },
  slot: 'checkAccountMoney'
}, {
  attrs: {
    prop: 'totalCollect',
    label: '代收'
  },
  slot: 'totalCollect'
}, {
  attrs: {
    prop: 'totalSubsidy',
    label: '返款'
  },
  slot: 'totalSubsidy'
}, totalPayed, unPay, checks, action];

// 保险汇总统计
export const insuranceList = [{
  attrs: {
    prop: 'resName',
    label: '保险'
  },
  slot: 'resName'
}, {
  attrs: {
    prop: 'people',
    label: '人数'
  },
  slot: 'people'
}, otherFee, totalPay, cashPayment, {
  ...enterpriseCode,
  slot: 'alipayECodePay'
}, totalPayed, unPay, companyTransfer, sign];

// 车队汇总统计
export const fleetList = [{
  attrs: {
    prop: 'resName',
    label: '车队'
  },
  slot: 'resName'
}, orders, otherFee, totalPay, cashPayment, enterpriseCode, totalPayed, unPay, companyTransfer, sign];

// 交通汇总统计
export const trafficList = [{
  attrs: {
    prop: 'resName',
    label: '车队'
  },
  slot: 'resName'
}, orders, otherFee, totalPay, cashPayment, {
  ...enterpriseCode,
  slot: 'alipayECodePay'
}, totalPayed, unPay, companyTransfer, sign];

// 酒店汇总统计
export const hotelList = [{
  attrs: {
    prop: 'resName',
    label: '酒店'
  },
  slot: 'resName'
},
// people,
{
  attrs: {
    prop: 'people',
    label: '总人次'
  },
  slot: 'people'
}, {
  attrs: {
    prop: 'useRooms',
    label: '用房数'
  },
  slot: 'useRooms'
}, {
  attrs: {
    prop: 'perPrice',
    label: '平均单价'
  }
}, totalQuotePay, totalPay, cashPayment, enterpriseCode, totalPayed, unPay, companyTransfer, sign];

// 餐厅汇总统计
export const restaurantlList = [{
  attrs: {
    prop: 'resName',
    label: '餐厅'
  },
  slot: 'resName'
}, people, otherFee, totalPay, cashPayment, {
  ...enterpriseCode,
  slot: 'alipayECodePay'
}, totalPayed, unPay, companyTransfer, sign];

// 景区汇总统计
export const sceniclList = [{
  attrs: {
    prop: 'resName',
    label: '景区'
  },
  slot: 'resName'
}, {
  ...adultCount,
  slot: 'adultCount'
}, {
  ...childCount,
  slot: 'childCount'
}, otherFee, totalQuotePay, totalPay, cashPayment, enterpriseCode, totalPayed, unPay, companyTransfer, sign];

// 加点汇总统计
export const sceniclAddList = [{
  attrs: {
    prop: 'resName',
    label: '景区'
  },
  slot: 'resName'
}, {
  ...adultCount,
  slot: 'adultCount'
}, {
  ...childCount,
  slot: 'childCount'
}, {
  attrs: {
    prop: 'totalAmountReceive',
    label: '实收金额'
  },
  slot: 'totalAmountReceive'
}, otherFee, totalQuotePay, totalPay, cashPayment, enterpriseCode, totalPayed, unPay, companyTransfer, sign];

// 客户汇总统计
export const customlList = [customerName, {
  attrs: {
    prop: 'contactName',
    label: '联系人'
  },
  slot: 'contactName'
}, customerArea, {
  attrs: {
    prop: 'customerAreaService',
    label: '客服'
  }
}, orders, {
  attrs: {
    prop: '',
    label: '人数'
  },
  children: [{
    ...adultCount,
    slot: 'adultCount'
  }, {
    ...childCount,
    slot: 'childCount'
  }, {
    ...accompanyCount,
    slot: 'accompanyCount'
  }]
}, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  },
  slot: 'totalIncome'
}, {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额'
  },
  slot: 'checkAccountMoney'
}, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'totalIncomed',
      label: '社收'
    },
    slot: 'totalIncomed'
  }, {
    attrs: {
      prop: 'collect',
      label: '代收'
    },
    slot: 'collect'
  }, {
    attrs: {
      prop: 'subsidy',
      label: '返款'
    },
    slot: 'subsidy'
  }]
}, {
  attrs: {
    prop: 'unIncome',
    label: '未收'
  },
  slot: 'unIncome'
}, checks, action];
// 应收账款汇总表
export const incomeList = [{
  attrs: {
    prop: 'index',
    label: '序号',
    width: 80,
    fixed: 'left'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'customerName',
    label: '客户',
    width: 260,
    fixed: 'left'
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '外联计调',
    fixed: 'left'
  },
  slot: 'outOPUserName'
}];
// 应收账款余额表
export const incomeBalanceList = [{
  attrs: {
    prop: 'indexMax',
    label: '大序',
    width: 80
  }
}, {
  attrs: {
    prop: 'index',
    label: '小序',
    width: 80
  }
}, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '外联计调'
  }
}, {
  attrs: {
    prop: 'total',
    label: '合计'
  }
}];
// 导游数据统计
export const guideStatistics = [{
  attrs: {
    prop: 'index',
    label: '序号'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'name',
    label: '姓名'
  }
}, {
  attrs: {
    prop: 'plans',
    label: '带团量'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'guideRebate',
    label: '导游收入'
  }
}, {
  attrs: {
    prop: 'grossProfit',
    label: '毛利'
  }
}];

// 导游组长绩效数据统计
export const GuideTeamData = [{
  attrs: {
    prop: 'name',
    label: '分组'
  }
}, {
  attrs: {
    prop: 'teamGuideName',
    label: '组长'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '组员'
  }
}, {
  attrs: {
    prop: '',
    label: '导游收入'
  },
  children: [{
    attrs: {
      prop: 'guideIncome',
      label: '组员收入'
    }
  }, {
    attrs: {
      prop: 'guideTeamMembersTotalIncome',
      label: '组员收入合计'
    }
  }, {
    attrs: {
      prop: 'guideTeamHeadManIncome',
      label: '组长绩效'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '导游爆单'
  },
  children: [{
    attrs: {
      prop: 'shopBuyMoneyDatas',
      label: '爆单出单'
    },
    slot: 'shopBuyMoneyDatas'
  }, {
    attrs: {
      prop: 'guideTeamHeadManShopIncome',
      label: '组长绩效'
    }
  }]
}, {
  attrs: {
    prop: 'guideTeamHeadManTotalIncome',
    label: '组长绩效合计'
  }
}];

// 导游组长绩效数据统计
export const travelAgencyIncome = [{
  attrs: {
    prop: 'customerName',
    label: '客户(子部门)'
  }
}, {
  attrs: {
    prop: 'total',
    label: 'N月收款'
  }
}, {
  attrs: {
    prop: 'paymentCollectionDate',
    label: '回款日期'
  }
}, {
  attrs: {
    prop: 'opUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}];

// 导游报账统计
export const guideReimb = [{
  ...planCustomerNumber,
  slot: 'planCustomerNumber'
}, {
  ...guideName,
  slot: 'guideName'
}, tripDate,
// { attrs: { prop: 'date', label: '行程日期' }, slot: 'date' },
{
  attrs: {
    prop: '',
    label: '现付明细'
  },
  children: [hotelReimb, restaurantReimb, busCompanyReimb, scenicReimb, otherPayReimb, shopDriverRebateReimb, shopAccompanyRebateReimb, scenicDriverRebateReimb, scenicAccompanyRebateReimb]
}, {
  attrs: {
    prop: '',
    label: '导游收入'
  },
  children: [guideServiceFeeReimb, shopGuideRebateReimb, scenicGuideRebateReimb]
}, {
  attrs: {
    prop: '',
    label: '交回明细'
  },
  children: [collectReceive, borrowReimb, guideFund, guideTax, scenicReceiveReimb, otherIncomeReimb, {
    attrs: {
      prop: 'guideManageFeeReimb',
      label: '管理费'
    }
  }, recordingFeeReimb, {
    attrs: {
      prop: 'depositFeeReimb',
      label: '押金'
    }
  }, {
    attrs: {
      prop: 'voluntarilyFeeReimb',
      label: '乐捐'
    }
  }]
}, travelAgencyPay, travelAgencyPayed, lacOfPay, {
  attrs: {
    prop: 'planPayAmount',
    label: '企业码付实际挂团金额'
  }
}, {
  attrs: {
    prop: 'alipayEnterpriseCodeAmount',
    label: '企业码汇总统计'
  }
}];

// 导游汇总统计
export const guideTotal = [{
  ...guideName,
  slot: 'guideName'
}, {
  attrs: {
    prop: 'planCount',
    label: '带团统计'
  }
}, people, {
  attrs: {
    prop: 'totalBuyMoney',
    label: '购物金额'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利'
  }
}, {
  attrs: {
    prop: '',
    label: '现付明细'
  },
  children: [hotelReimb, restaurantReimb, busCompanyReimb, {
    attrs: {
      prop: 'transportCompanyReimb',
      label: '大交通'
    }
  }, {
    attrs: {
      prop: 'insuranceReimb',
      label: '保险'
    }
  }, scenicReimb, otherPayReimb, shopDriverRebateReimb, shopAccompanyRebateReimb, scenicDriverRebateReimb, scenicAccompanyRebateReimb]
}, {
  attrs: {
    prop: '',
    label: '导游收入'
  },
  children: [guideServiceFeeReimb, shopGuideRebateReimb, scenicGuideRebateReimb]
}, {
  attrs: {
    prop: '',
    label: '交回明细'
  },
  children: [collectReceive, borrowReimb, guideFund, guideTax, scenicReceiveReimb, otherIncomeReimb, {
    attrs: {
      prop: 'guideManageFeeReimb',
      label: '管理费'
    }
  }, recordingFeeReimb, {
    attrs: {
      prop: 'depositFeeReimb',
      label: '押金'
    }
  }, {
    attrs: {
      prop: 'voluntarilyFeeReimb',
      label: '乐捐'
    }
  }]
}, travelAgencyPay, travelAgencyPayed, lacOfPay, {
  attrs: {
    prop: 'billCheckTotal',
    label: '已对账/总数'
  },
  slot: 'billCheckTotal'
}];

// 导游结算统计
export const guideSettle = [planCustomerNumber, guideName, {
  attrs: {
    prop: '',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: 'guideRebate',
      label: '导佣'
    }
  }, {
    attrs: {
      prop: 'driverRebate',
      label: '司佣'
    }
  }, {
    attrs: {
      prop: 'accompanyRebate',
      label: '陪佣'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '其他收入'
  },
  children: [{
    attrs: {
      prop: 'guideTax',
      label: '税'
    }
  }, {
    attrs: {
      prop: 'guideFund',
      label: '导游基金'
    }
  }, {
    attrs: {
      prop: 'guideManageFeeReimb',
      label: '管理费'
    }
  }, recordingFeeReimb, {
    attrs: {
      prop: 'depositFeeReimb',
      label: '押金'
    }
  }, {
    attrs: {
      prop: 'voluntarilyFeeReimb',
      label: '乐捐'
    }
  }]
}, {
  attrs: {
    prop: 'travelAgencyPay',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'travelAgencyPayed',
    label: '已付'
  }
}, {
  attrs: {
    prop: 'lacOfPay',
    label: '未付'
  }
}];

// 导游借款统计
export const guideBorrow = [planCustomerNumber, guideName, {
  attrs: {
    prop: 'borrowReimb',
    label: '导游借款'
  }
}, {
  attrs: {
    prop: '',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: 'shopGuideRebateReimb',
      label: '购物导佣'
    }
  }, {
    attrs: {
      prop: 'scenicGuideRebateReimb',
      label: '景区导佣'
    }
  }, {
    attrs: {
      prop: 'guideManageFeeReimb',
      label: '导管费'
    }
  }, {
    attrs: {
      prop: 'guideServiceFeeReimb',
      label: '导服费'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '其他收入'
  },
  children: [{
    attrs: {
      prop: 'guideTax',
      label: '税'
    }
  }, {
    attrs: {
      prop: 'guideFund',
      label: '导游基金'
    }
  }]
}, {
  attrs: {
    prop: 'travelAgencyPay',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'travelAgencyPayed',
    label: '已付'
  }
}, {
  attrs: {
    prop: 'lacOfPay',
    label: '未付'
  }
}];

// 导游借款明细
export const guideBorrowDetail = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  }
}, guideName, {
  attrs: {
    prop: 'borrowMoney',
    label: '导游借款'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '借款时间'
  },
  slot: 'createTime'
}];

// 预定用房统计
export const bookingRoom = [hotelArea, hotelStandard, count, action];

// 预定用房统计详情
export const bookingRoomDetail = [{
  attrs: {
    prop: 'guideName',
    label: '报账导游'
  }
}, planCustomerNumber, customerTripNumber, {
  attrs: {
    prop: 'members',
    label: '游客姓名'
  },
  slot: 'members'
}, hotelArea, hotelStandard, {
  attrs: {
    prop: 'hotelName',
    label: '酒店'
  }
}, {
  attrs: {
    prop: 'totalCount',
    label: '数量'
  }
}, remark, action];

//小团订单列表=> 车队
export const orderBus = [planCustomerNumber, customerTripNumber, tripDate, number, customerArea, {
  attrs: {
    prop: 'resName',
    label: '车队'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '用车日期'
  },
  slot: 'orderDate'
}, total];
export const orderScenic = [planCustomerNumber, customerTripNumber, customerArea, tripDate, number, {
  attrs: {
    prop: 'resName',
    label: '景区'
  }
}, orderDate, {
  attrs: {
    prop: 'resType',
    label: '门票'
  }
}, total];
export const orderRestaurant = [planCustomerNumber, customerTripNumber, customerArea, tripDate, number, orderDate, {
  attrs: {
    prop: 'resType',
    label: '餐饮类型'
  }
}, total];
export const orderTraffic = [planCustomerNumber, customerTripNumber, customerArea, tripDate, number, {
  attrs: {
    prop: 'resName',
    label: '大交通公司'
  }
}, {
  attrs: {
    prop: 'resType',
    label: '类别'
  }
}, orderDate, total];
export const hotelsStatist = [{
  attrs: {
    prop: 'hotelName',
    label: '酒店',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'roomCount',
    label: '房间数量'
  }
}, hotelArea, hotelStandard];
export const daysData = [{
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'product',
    label: '线路名称'
  }
}, {
  attrs: {
    prop: 'customerStr',
    label: '组团社',
    width: 260
  }
}, {
  attrs: {
    prop: 'outOPUsers',
    label: '客服计调'
  },
  slot: 'outOPUsers'
}, {
  attrs: {
    prop: 'guide',
    label: '导游'
  },
  slot: 'guide'
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'sinkIn',
    label: '坑值'
  }
}, {
  attrs: {
    prop: 'profitAndLoss',
    label: '盈亏合计'
  }
}, {
  attrs: {
    prop: 'perCapitaProfit',
    label: '人均利润'
  }
}, {
  attrs: {
    prop: 'shoppingPerCapita',
    label: '购物人均'
  }
}];
export const daysDataE = [{
  attrs: {
    prop: '',
    label: '成本'
  },
  children: [{
    attrs: {
      prop: 'room',
      label: '房'
    }
  }, {
    attrs: {
      prop: 'restaurant',
      label: '餐'
    }
  }, {
    attrs: {
      prop: 'bus',
      label: '车'
    }
  }, {
    attrs: {
      prop: 'scenic',
      label: '门票'
    }
  }, {
    attrs: {
      prop: 'shuttle',
      label: '接送站'
    }
  }, {
    attrs: {
      prop: 'pay',
      label: '打出去成本'
    }
  }, {
    attrs: {
      prop: 'guideService',
      label: '导服'
    }
  }, {
    attrs: {
      prop: 'transport',
      label: '火车票'
    }
  }, {
    attrs: {
      prop: 'totalOtherPay',
      label: '其他支出'
    }
  }, {
    attrs: {
      prop: 'subsidyPay',
      label: '客户返款'
    }
  }, {
    attrs: {
      prop: 'insurance',
      label: '保险'
    }
  }, {
    attrs: {
      prop: 'shopRebatePay',
      label: '购物佣金'
    }
  }, {
    attrs: {
      prop: 'addScenicRebatePay',
      label: '加点佣金'
    }
  }, {
    attrs: {
      prop: 'addScenic',
      label: '(加点)门票'
    }
  }, {
    attrs: {
      prop: 'totalPay',
      label: '总成本'
    }
  }]
}, {
  attrs: {
    prop: 'analysisStr',
    label: '分析',
    width: '200'
  }
}];

// 客服计调绩效
export const outOpUserList = [{
  attrs: {
    prop: 'index',
    label: '序号',
    sortable: true
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'opUserName',
    label: '姓名'
  }
}, orders, {
  attrs: {
    prop: '',
    label: '团数量'
  },
  children: [{
    attrs: {
      prop: 'tdPlans',
      label: '团队',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'skPlans',
      label: '散拼',
      sortable: true
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '总人数'
  },
  children: [{
    attrs: {
      prop: 'adultCount',
      label: '大',
      'min-width': 60,
      sortable: true
    }
  }, {
    attrs: {
      prop: 'childCount',
      label: '小',
      'min-width': 60,
      sortable: true
    }
  }, {
    attrs: {
      prop: 'accompanyCount',
      label: '陪',
      'min-width': 60,
      sortable: true
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: '',
      label: '团队'
    },
    children: [{
      attrs: {
        prop: 'tdAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '散客'
    },
    children: [{
      attrs: {
        prop: 'skAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }]
}, {
  attrs: {
    prop: 'feeIncome',
    label: '团款收入',
    sortable: true
  }
}, {
  attrs: {
    prop: '$feeIncomed',
    label: '团款已收',
    sortable: true
  }
}, {
  attrs: {
    prop: '$feeUnIncome',
    label: '团款未收',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalIncome',
    label: '发团收入',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '发团成本'
  },
  sortable: true
}, {
  attrs: {
    prop: 'collectFee',
    label: '代收'
  }
}, {
  attrs: {
    prop: 'subsidyFee',
    label: '返款'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利',
    sortable: true
  },
  slot: 'totalQuoteGrossProfit'
}, {
  attrs: {
    prop: 'totalQuoteGrossMargin',
    label: '人均毛利',
    sortable: true
  }
}, {
  attrs: {
    prop: 'grossProfitRate',
    label: '毛利率',
    sortable: true
  }
}];

// 操作计调绩效
export const dutOpUserList = [{
  attrs: {
    prop: 'index',
    label: '序号',
    sortable: true
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'opUserName',
    label: '姓名'
  }
}, {
  attrs: {
    prop: '',
    label: '团数量'
  },
  children: [{
    attrs: {
      prop: 'tdPlans',
      label: '团队',
      sortable: true
    }
  }, {
    attrs: {
      prop: 'skPlans',
      label: '散拼',
      sortable: true
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  children: [{
    attrs: {
      prop: '',
      label: '团队'
    },
    children: [{
      attrs: {
        prop: 'tdAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'tdAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '散客'
    },
    children: [{
      attrs: {
        prop: 'skAdultCount',
        label: '大',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skChildCount',
        label: '小',
        'min-width': 60,
        sortable: true
      }
    }, {
      attrs: {
        prop: 'skAccompanyCount',
        label: '陪',
        'min-width': 60,
        sortable: true
      }
    }]
  }]
},
// { attrs: { prop: 'feeIncome', label: '团款收入', sortable: true } },
// { attrs: { prop: 'feeIncomed', label: '团款已收', sortable: true } },
// { attrs: { prop: 'feeUnIncome', label: '团款未收', sortable: true } },
{
  attrs: {
    prop: 'totalIncome',
    label: '发团收入',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '发团成本'
  },
  sortable: true
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利',
    sortable: true
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossMargin',
    label: '人均毛利',
    sortable: true
  }
}, {
  attrs: {
    prop: 'grossProfitRate',
    label: '毛利率',
    sortable: true
  }
}];

// 应收余额表
export const customerUnIncome = [{
  attrs: {
    prop: 'index',
    label: '大序',
    width: 80
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'areaServiceName',
    label: '销售'
  },
  slot: 'areaServiceName'
}, {
  attrs: {
    prop: 'itemIndex',
    label: '小序',
    width: 80
  },
  slot: 'itemIndex'
}, {
  attrs: {
    prop: 'codeName',
    label: '组团社简称'
  },
  slot: 'codeName'
}, {
  attrs: {
    prop: 'businessPrincipalPhone',
    label: '联系电话'
  }
}];
export const groupTotal = [{
  attrs: {
    prop: 'customerName',
    label: '组团社'
  }
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'income',
    label: '团收入'
  },
  slot: 'income'
}, {
  attrs: {
    prop: 'totalPay',
    label: '团队成本'
  }
}, {
  attrs: {
    prop: 'profit',
    label: '利润'
  },
  slot: 'profit'
}, {
  attrs: {
    prop: 'totalFee',
    label: '团款'
  }
}, {
  attrs: {
    prop: 'totalBuyMoney',
    label: '购物金额'
  }
}, {
  attrs: {
    prop: 'shopIncome',
    label: '购物收入'
  }
}, {
  attrs: {
    prop: 'perProfit',
    label: '人均利润'
  },
  slot: 'perProfit'
}];
export const groupDetail = [{
  attrs: {
    prop: 'index',
    label: '序号'
  },
  slot: 'index'
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'customerArea',
    label: '办事处'
  }
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '计调'
  }
}, {
  attrs: {
    prop: 'customerName',
    label: '组团社'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '成人'
  }
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童'
  }
}, {
  attrs: {
    prop: 'totalFee',
    label: '团队收入'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '团队成本'
  }
}, {
  attrs: {
    prop: 'profit',
    label: '团队利润'
  }
}, {
  attrs: {
    prop: 'totalFee',
    label: '团款合计'
  }
}, {
  attrs: {
    prop: '',
    label: '收入'
  },
  children: [{
    attrs: {
      prop: 'shopIncome',
      label: '购物收入'
    }
  }, {
    attrs: {
      prop: 'shopSubsidy',
      label: '购物后返'
    }
  }, {
    attrs: {
      prop: 'totalAddScenicIncome',
      label: '加点收入'
    }
  }, {
    attrs: {
      prop: 'totalOtherIncome',
      label: '其他收入'
    }
  }]
}, {
  attrs: {
    prop: '',
    label: '支出'
  },
  children: [{
    attrs: {
      prop: 'totalGuideServiceFeePay',
      label: '导游'
    }
  }, {
    attrs: {
      prop: 'totalBusPay',
      label: '车费'
    }
  }, {
    attrs: {
      prop: 'totalScenicQuotePay',
      label: '门票'
    }
  }, {
    attrs: {
      prop: 'totalHotelQuotePay',
      label: '房费'
    }
  }, {
    attrs: {
      prop: 'totalRestaurantPay',
      label: '餐费'
    }
  }, {
    attrs: {
      prop: 'totalTransportPay',
      label: '大交通'
    }
  }, {
    attrs: {
      prop: 'totalOtherPay',
      label: '其他成本'
    }
  }, {
    attrs: {
      prop: 'totalShopRebatePay',
      label: '购物成本'
    }
  }, {
    attrs: {
      prop: 'totalAddScenicQuotePay',
      label: '加点成本'
    }
  }, {
    attrs: {
      prop: 'totalOutTransferPay',
      label: '外接'
    }
  }]
}];