// 缴费记录
export const RenewColumn = [{
  attrs: {
    label: '序号',
    prop: 'orderNumber'
  }
}, {
  attrs: {
    label: '续费人',
    prop: 'createUserName'
  }
}, {
  attrs: {
    label: '续费年限',
    prop: 'count'
  }
}, {
  attrs: {
    label: '续费金额',
    prop: 'amount'
  }
}, {
  attrs: {
    label: '续费前到期时间',
    prop: 'startDate'
  }
}, {
  attrs: {
    label: '续费后到期时间',
    prop: 'endDate'
  }
}, {
  attrs: {
    label: '支付方式',
    prop: '$payType'
  }
}, {
  attrs: {
    label: '发票信息',
    prop: 'invoice'
  },
  slot: 'invoice'
}, {
  attrs: {
    label: '续费时间',
    prop: 'payTime'
  }
}, {
  attrs: {
    label: '发票',
    prop: ''
  },
  slot: 'action'
}];