var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "booking-item booking-hotel"
  }, [_c("TopTitle", {
    attrs: {
      title: _vm.tableTitle,
      loading: _vm.btnLoading
    },
    on: {
      "on-add": _vm.handleAdd
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.orderDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "orderDate", $$v);
            },
            expression: "scope.row.orderDate"
          }
        })];
      }
    }, {
      key: "area",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "hotelRegionType",
            disabled: _vm.hasBooked(scope.row)
          },
          on: {
            "on-select": function ($event) {
              return _vm.handleSelectChange(scope.row, "hotelArea", $event);
            }
          },
          model: {
            value: scope.row.hotelArea,
            callback: function ($$v) {
              _vm.$set(scope.row, "hotelArea", $$v);
            },
            expression: "scope.row.hotelArea"
          }
        })];
      }
    }, {
      key: "standard",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "hotelStandardType",
            disabled: _vm.hasBooked(scope.row)
          },
          on: {
            "on-select": function ($event) {
              return _vm.handleSelectChange(scope.row, "hotelStandard", $event);
            }
          },
          model: {
            value: scope.row.hotelStandard,
            callback: function ($$v) {
              _vm.$set(scope.row, "hotelStandard", $$v);
            },
            expression: "scope.row.hotelStandard"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            "controls-position": "right",
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: "",
            disabled: _vm.hasBooked(scope.row)
          },
          model: {
            value: scope.row.remark,
            callback: function ($$v) {
              _vm.$set(scope.row, "remark", $$v);
            },
            expression: "scope.row.remark"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnBase", {
          attrs: {
            type: "delete",
            disabled: _vm.hasBooked(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }), !scope.row.id ? _c("BtnBase", {
          attrs: {
            type: "save"
          },
          on: {
            click: function ($event) {
              return _vm.saveOne(scope.row);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };