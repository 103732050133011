import "core-js/modules/es.array.push.js";
import { planTravelItinerarie } from '@/config/plan';
const defaultState = () => ({
  relevancePlaceType: '2',
  payItemType: '2',
  arrivalTime: '08:00',
  relevancePlaceName: '',
  arrivalDistrict: '桂林市',
  stopoverTime: 60,
  routeRemark: ''
});
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {},
      rules: {},
      tableData: [],
      columnData: planTravelItinerarie,
      id: '',
      type: '',
      isFirst: false
    };
  },
  methods: {
    handlePlus() {
      this.tableData.push({
        ...defaultState()
      });
    },
    deleteItinerary(scope) {
      const {
        $index: index
      } = scope;
      this.tableData.splice(index, 1);
    },
    deleteHotel(row) {
      row.glETravelHotelId = '';
      row.hotel = '';
      row.hotelDicName = '';
    },
    saveUpdate() {
      return this.tableData;
    }
  }
};