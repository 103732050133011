import variables from '@/styles/variables.scss';
import { mapGetters } from 'vuex';
export default {
  name: 'FooterBar',
  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    left: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 10
    }
  },
  computed: {
    ...mapGetters('app', ['sidebar']),
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    },
    getLeftPos() {
      if (this.isFull) {
        return 0;
      }
      return this.isMobile ? 0 : this.left ? this.left + 'px' : this.sidebar.opened ? this.variables.sideBarWidth : '54px';
    },
    variables() {
      return variables;
    }
  }
};