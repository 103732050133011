import TableTpl from '@/views/print/components/TableTpl';
export default {
  components: {
    TableTpl
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
};