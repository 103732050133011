import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { remarkText } from '@/config/remark';
import { reloadMixin } from '@/mixin';
export default {
  mixins: [reloadMixin],
  data() {
    return {
      remark: '',
      remarkList: []
    };
  },
  computed: {
    ...mapGetters({
      size: 'app/size',
      currentOrderId: 'order/index/currentOrderId'
    }),
    getFontSize() {
      return `fontSize: ${this.getSize}px`;
    },
    getSize() {
      if (this.size == 'small') return 12;
      if (this.size == 'medium') return 14;
      if (this.size == 'default') return 16;
      return 12;
    }
  },
  // watch: {
  //   currentOrderId: {
  //     handler: 'handleInit',
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    // handleInit(id) {
    //   id && this.fetchData(id)
    // },

    // 获取备注
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchRemarks', id).then(data => {
        if (!data) return;
        const {
          remark
        } = data;
        this.remark = remark;
        this.remarkList = [];
        Object.keys(remarkText).forEach(key => {
          const item = {
            label: remarkText[key],
            name: key,
            value: data[key]
          };
          this.$set(item, key, data[key]);
          this.remarkList.push(item);
        });
      });
    }
  }
};