import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import SearchForm from '../SearchForm';
import { checkBillTraffic } from '@/config/billCheck';
import { tagViewMixin, excelMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { getSelectLabel } from '@/config/select';
import { downloadExport } from '@/utils/exportExcel';
import { mergeTableRow, calcMergeRowTotalByKey } from '@/utils';
import { MergeRowKeys } from './config';
export default {
  components: {
    SearchForm,
    TableList
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillTraffic,
      total: 0,
      statistics: null,
      filename: '大交通对账'
    };
  },
  computed: {
    trafficData() {
      return this.$store.getters['bill/billCheck/traffic'];
    }
  },
  watch: {
    trafficData: {
      handler: function (data) {
        !data ? this.fetchData({
          startTripDateS: this.getToday
        }) : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('bill/billCheck/fetchTraffic', queryParam);
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      const mergeList = mergeTableRow(this.formatData(list), MergeRowKeys);
      this.tableData = calcMergeRowTotalByKey(mergeList, 'money', '$total');
      this.total = totalCount;
      if (statistics) {
        this.statistics = {
          ...statistics,
          $total: statistics.totalPayCount
        };
      }
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          plan,
          totalPay,
          checkAccountMoney,
          checkAccountStatus
        } = it;
        // 删除掉计划里面的id 防止id冲突
        delete plan.id;
        const item = {
          ...it,
          ...plan,
          rowId: id,
          mergeId: id,
          checkAccountMoney: checkAccountStatus ? checkAccountMoney : totalPay,
          money: totalPay
        };
        this.$set(item, 'ckStatus', false);
        let itemList = it.orderTransportCompanyTypes || [];
        itemList = itemList.map(item => {
          delete item.id;
          item.transportType = getSelectLabel('bigTraffic', item.transportType);
          return item;
        });
        if (itemList.length) {
          itemList.forEach(s => {
            s.orderCount = s.count;
            s.orderDate = s.orderDate || itemList[0].orderDate;
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    exporttExcel(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportTransportExcel', searchParams).then(res => {
        const fileName = '大交通对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};