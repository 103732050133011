var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectData", _vm._g(_vm._b({
    attrs: {
      list: _vm.list,
      label: _vm.label,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire
    },
    on: {
      focus: _vm.lazyLoad,
      change: _vm.handleChange
    },
    model: {
      value: _vm.curVal,
      callback: function ($$v) {
        _vm.curVal = $$v;
      },
      expression: "curVal"
    }
  }, "SelectData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };