import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 审核管理
import Layout from '@/layout';
const auditRouter = {
  path: '/audit',
  component: Layout,
  redirect: '/audit/team-single',
  name: 'audit',
  meta: {
    title: 'audit',
    icon: 'icon-shenpishenhe'
  },
  children: [{
    path: '/audit/team-single',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/single'))),
    name: 'auditSingle',
    // 单团审核列表
    meta: {
      title: 'auditSingle',
      icon: 'icon-dingdan'
    }
  }, {
    path: '/audit/team-single-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/singleDetail'))),
    name: 'auditSingleDetail',
    // 单团审核列表
    meta: {
      title: 'auditSingleDetail',
      icon: 'icon-dingdan',
      auth: true
    },
    hidden: true
  }, {
    path: '/audit/team-single-edit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/index'))),
    name: 'auditSingleEdit',
    // 单团审核
    meta: {
      title: 'auditSingleEdit',
      icon: 'icon-dingdan',
      auth: true
    },
    hidden: true,
    children: [{
      path: '/audit/team-single-edit/fee',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/TeamFund/index'))),
      name: 'auditEditFee',
      //
      meta: {
        title: 'auditEditFee',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/guide',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Guide/index'))),
      name: 'auditEditGuide',
      //
      meta: {
        title: 'auditEditGuide',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/guide_borrow',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Guide/GuideBorrow'))),
      name: 'auditEditBorrow',
      //
      meta: {
        title: 'auditEditBorrow',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/collect',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Guide/GuideProxy'))),
      name: 'auditEditCollect',
      //
      meta: {
        title: 'auditEditCollect',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Scenic/index'))),
      name: 'auditEditScenic',
      //
      meta: {
        title: 'auditEditScenic',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/add_scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Scenic/ExtraScenic'))),
      name: 'auditEditAddScenic',
      //
      meta: {
        title: 'auditEditAddScenic',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/hotel',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Hotel/index'))),
      name: 'auditEditHotel',
      //
      meta: {
        title: 'auditEditHotel',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Restaurant/index'))),
      name: 'auditEditRestaurant',
      //
      meta: {
        title: 'auditEditRestaurant',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/bus_company',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Fleet/index'))),
      name: 'auditEditFleet',
      //
      meta: {
        title: 'auditEditFleet',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/insurance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Insurance/index'))),
      name: 'auditEditInsurance',
      //
      meta: {
        title: 'auditEditInsurance',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/transport',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Traffic/index'))),
      name: 'auditEditTransport',
      //
      meta: {
        title: 'auditEditTransport',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/shuttle',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Pickup/index'))),
      name: 'auditEditPickup',
      //
      meta: {
        title: 'auditEditPickup',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/out_fee',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Reception/index'))),
      name: 'auditEditReception',
      //
      meta: {
        title: 'auditEditReception',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/shop',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/Shopping/index'))),
      name: 'auditEditShop',
      //
      meta: {
        title: 'auditEditShop',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/other_income',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/OtherIncome/index'))),
      name: 'auditEditOtherIncome',
      //
      meta: {
        title: 'auditEditOtherIncome',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/other_pay',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/OtherPay/index'))),
      name: 'auditEditOtherPay',
      //
      meta: {
        title: 'auditEditOtherPay',
        auth: true
      },
      hidden: true
    }, {
      path: '/audit/team-single-edit/subsidy_rate',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/auditEdit/SubsidyRate/index'))),
      name: 'auditEditSubsidyRate',
      //
      meta: {
        title: 'auditEditSubsidyRate',
        auth: true
      },
      hidden: true
    }]
  }, {
    path: '/audit/team-file',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/file'))),
    name: 'singleFile',
    // 单团归档
    meta: {
      title: 'singleFile',
      icon: 'el-icon-s-claim'
    }
  }, {
    path: '/audit/proxy',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/audit/proxy'))),
    name: 'proxyAudit',
    // 代收审核
    meta: {
      title: 'proxyAudit',
      icon: 'icon-ic_droptohistory'
    }
  }]
};
export default auditRouter;