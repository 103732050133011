import "core-js/modules/es.array.push.js";
import { TableList, Search } from './components';
import { checkBillReception } from '@/config/billCheck';
import { parseDate } from '@/utils';
import { tagViewMixin, excelMixin } from '@/mixin';
import { downloadExport } from '@/utils/exportExcel';
import { mapGetters } from 'vuex';
import billCheckMixin from '../searchMixin';
export default {
  components: {
    Search,
    TableList
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillReception,
      total: 0,
      statistics: null,
      search: {
        startTripDateS: parseDate()
      },
      filename: '外转对账'
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews'
    }),
    receptionData() {
      return this.$store.getters['bill/billCheck/reception'];
    },
    getTagView() {
      return this.visitedViews.find(tag => tag.path === this.$route.path);
    },
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        this.search = data;
      },
      immediate: true,
      deep: true
    },
    receptionData: {
      handler: function (data) {
        !data ? this.fetchData({
          startTripDateS: this.getToday
        }) : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    showLog({
      row
    }) {
      const {
        rowId: subItemId,
        customerOrderId: itemId
      } = row;
      this.$store.dispatch('billCheck/index/fetchLog2', {
        itemId,
        subItemId,
        type: 'outFee',
        subType: 'outFee'
      }).then(data => {
        this.$refs.dialogRef.show(data);
      });
    },
    fetchData(queryParam) {
      this.$store.dispatch('bill/billCheck/fetchReception', queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      if (!statistics) return;
      statistics.unTotalPay = statistics.unTotalIncome;
      this.statistics = statistics;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          checkAccountStatus,
          unTotalIncome,
          totalPay
        } = it;
        if (!checkAccountStatus) {
          it.checkAccountMoney = totalPay;
        }
        const item = {
          ...it,
          rowId: id,
          unPay: unTotalIncome
        };
        this.$set(item, 'ckStatus', false);
        it.unPay = unTotalIncome;
        result.push(item);
        // }
      });
      return result;
    },
    exporttExcel(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportOutTransferExcel', searchParams).then(res => {
        const fileName = '外转对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};