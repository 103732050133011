import { IconRight, IconWrong } from '@/components/common';
export default {
  name: 'IconCheck',
  components: {
    IconRight,
    IconWrong
  },
  props: {
    checked: {
      type: Boolean,
      default: true
    }
  }
};