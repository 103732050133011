// 短信管理
export const planSmsSendManager = [{
  attrs: {
    prop: 'planCustomerNumber',
    label: '订单信息'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'travelAgencyPay',
    label: '应报'
  }
}, {
  attrs: {
    prop: 'travelAgencyPayed',
    label: '已报'
  }
}, {
  attrs: {
    prop: 'unpay',
    label: '欠报'
  },
  slot: 'unpay'
}, {
  attrs: {
    prop: '',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'count',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'sendSMSCount',
    label: '发送次数'
  },
  slot: 'times'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
// 短信管理
export const planSmsSendRecord = [{
  attrs: {
    prop: 'rechargeType',
    label: '订单信息'
  },
  slot: 'info'
}, {
  attrs: {
    prop: 'count',
    label: '购买数量'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'payType',
    label: '付款方式'
  },
  slot: 'payType'
}, {
  attrs: {
    prop: 'createTime',
    label: '时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];