import TableSingle from '@/views/print/components/TableSingle';
const columnData = [{
  rows: [{
    label: '大团号',
    width: '60px'
  }, {
    content: 'planNumber'
  }, {
    label: '人数',
    width: '60px'
  }, {
    content: 'number'
  }, {
    label: '导游',
    width: '60px'
  }, {
    content: 'guideName'
  }]
}];
// const data = {
//   planNumber: '',
//   number: 0,
//   guide: '',
// }

export default {
  components: {
    TableSingle
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData
      // data,
    };
  }
};