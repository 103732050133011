import { TicketCompleteStatus } from '@/config/ticket';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      currentPlan: 'ticket/index/currentPlan'
    }),
    getCompleteStatus() {
      return TicketCompleteStatus[this.currentPlan.signSheetStatus];
    }
  }
};