var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.searchText) + " ")]), _c("BtnBase", {
    directives: [{
      name: "permission",
      rawName: "v-permission"
    }],
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" " + _vm._s(_vm.createText) + " ")]), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };