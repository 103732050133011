import { mapGetters } from 'vuex';
import ElDragDialog from '@/directive/el-drag-dialog';
/**
 * @module components/base/Dialog
 * @desc 基础弹窗组件
 * @vue-prop {Boolean} visible - 是否显示弹窗
 * @vue-computed {Boolean} visibleDialog
 * @vue-event {Event} handleCancel - 关闭弹窗并触发 Emit cancel 事件
 * @vue-event {Event} handleConfirm - 关闭弹窗并触发 Emit confirm 事件
 */
export default {
  name: 'DialogBase',
  directives: {
    ElDragDialog
  },
  // 默认情况下父作用域的不被认作 props 的 attribute 绑定 (attribute bindings)
  // 将会“回退”且作为普通的 HTML attribute 应用在子组件的根元素上。
  // 通过设置 inheritAttrs 到 false，这些默认行为将会被去掉
  inheritAttrs: false,
  props: {
    // 对外暴露visible属性，用于显示隐藏弹框
    visible: {
      type: Boolean,
      default: false
    },
    hasFoot: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: '确 定'
    }
  },
  computed: {
    ...mapGetters({
      loading: 'app/dialogLoading'
    }),
    // 通过计算属性，对.sync进行转换，外部也可以直接使用visible.sync
    visibleDialog: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('update:visible');
      }
    }
  },
  methods: {
    // 对外抛出cancel事件
    handleCancel() {
      this.visibleDialog = false;
      this.$emit('cancel');
    },
    // 对外抛出 confirm事件
    handleConfirm() {
      // 确认按钮 loading 状态
      this.$store.dispatch('app/setDialogLoading', false);
      this.$emit('confirm');
    }
  }
};