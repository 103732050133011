export default {
  name: 'SearchBase',
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visibleMore: false
    };
  },
  computed: {
    hasMore() {
      return this.$slots.more;
    },
    getVisible() {
      return this.visibleMore;
    }
  },
  watch: {
    form: {
      handler: function (item) {
        this.visibleMore = item.visibleMore || this.visibleMore;
      },
      immediate: true
    }
  },
  methods: {
    toggle() {
      this.visibleMore = !this.visibleMore;
      this.form.visibleMore = this.visibleMore;
    },
    handleSearch() {
      const params = {
        ...this.form
      };
      // 初始化分页
      params.pageNum = 1;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path: this.$route.path,
        search: params
      });
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path: this.$route.path,
        page: 1
      });
      this.$emit('on-search', params);
    }
  }
};