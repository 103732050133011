import "core-js/modules/es.array.push.js";
import { orderAuditStatus } from '@/config/order';
import { BtnLink, CustomerInfo } from '@/components/common';
import { formatIosDate } from '@/utils';
import { PersonCount, Members } from '@/components/common';
import orderMixin from '../../orderMixin';
import { tableMixin, permissionMixin, systemMixin } from '@/mixin';
export default {
  components: {
    BtnLink,
    CustomerInfo,
    Members,
    PersonCount
  },
  mixins: [tableMixin, permissionMixin, systemMixin, orderMixin],
  data() {
    return {
      formatIosDate,
      orderPath: {
        detail: '/order/order-detail',
        update: '/order/order-update'
      }
    };
  },
  methods: {
    toggle(table) {
      table.expand = !table.expand;
    },
    toggleDisplayTable(table) {
      table.expand = !table.expand;
    },
    auditStatus(val) {
      if (val == 'WAITAUDIT') {
        return '未审核';
      } else {
        return '已审核';
      }
    },
    planStatus(val) {
      return orderAuditStatus[val];
    },
    handleMove(scope) {
      this.$parent.transferRow(scope);
    },
    handleCopy(scope) {
      this.$parent.copyTableRow(scope);
    },
    // 退单
    handleRevoke(scope) {
      this.$parent.revokeTableRow(scope);
    },
    // 移除
    handleRemove(scope) {
      this.$parent.cancelTableRow(scope);
    },
    handlePrint(scope) {
      console.log('scope.row:', scope.row);
      const {
        rowId,
        id,
        orderType
      } = scope.row;
      // 打印页面的打印权限类型
      const gateUrl = 'personal';
      const routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId: rowId,
          component: 'Receipt',
          planType: orderType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleBooking(scope) {
      this.$store.commit('order/index/CUR_ORDER', scope.row);
      this.$router.push({
        path: '/order/reserve-booking'
      });
    },
    handleAuditDetail(row) {
      const {
        id
      } = row;
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType: 'S_TOURIST'
        }
      });
    },
    handleCommand(scope, command, table) {
      if (command == 'handleAuditDetail') {
        this.handleAuditDetail(table);
      } else {
        this[command](scope);
      }
    },
    linkDetail(data) {
      const {
        planCustomerNumber
      } = data.row;
      if (!planCustomerNumber) return false;
      this.$parent.showTableDetail(data);
    },
    handleDetail(table) {
      const {
        id
      } = table;
      this.$router.push({
        path: this.orderPath.update,
        query: {
          id,
          readonly: true
        }
      });
    },
    handleUpdate(table) {
      const {
        id
      } = table;
      this.$router.push({
        path: this.orderPath.update,
        query: {
          id
        }
      });
    }
  }
};