/* eslint-disable no-unused-vars */
import { requestBlob } from '@/utils/request';
import { getExportUrl } from '@/utils';

// 大交通订票导出
export function trafficBookingExportRequest(params) {
  const url = getExportUrl('/order/report2/report-transport-type-static-export');
  return requestBlob({
    url,
    params
  });
}

// 部门业绩统计导出
export function depPerformanceExportRequest(params) {
  const url = getExportUrl('/order/report2/report-department-performance-static-export');
  return requestBlob({
    url,
    params
  });
}

// 导出天天快报
export function getDailyTemplate(params) {
  const url = getExportUrl('/order/report2/exportDailyExpress');
  return requestBlob({
    url,
    params
  });
}

// 导出客户应收报表
export function exportCustomerIncome(params) {
  const url = getExportUrl('/order/report2/exportCustomerIncome');
  return requestBlob({
    url,
    params
  });
}

// 导出单团利润统计
export function exportTeamProfitExcel(params) {
  const url = getExportUrl('/order/report2/exportSinglePlanProfitStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出单团利润结算
export function exportTeamProfitSettlementExcel(params) {
  const url = getExportUrl('/order/report2/exportSinglePlanProfitStatic-settlement');
  return requestBlob({
    url,
    params
  });
}

// 导出小团利润统计
export function exportTeamSmallExcel(params) {
  const url = getExportUrl('/order/report2/exportOrderProfitStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出小团利润结算统计
export function exportTeamSmallSettleExcel(params) {
  const url = getExportUrl('/order/report2/exportOrderProfitStatic-settlement');
  return requestBlob({
    url,
    params
  });
}

// 导出营业状况表导出
export function exporMonthlyOPExcel(params) {
  const url = getExportUrl('/order/report2/exportMonthlyOPStatus');
  return requestBlob({
    url,
    params
  });
}

// 导出毛利总汇报表
export function exportMonthlyProfitExcel(params) {
  const url = getExportUrl('/order/report2/exportMonthlyGrossProfit');
  return requestBlob({
    url,
    params
  });
}

// 导出年度人数统计
export function exportYearPeopleCount(params) {
  const url = getExportUrl('/order/report2/exportYearPeopleCount');
  return requestBlob({
    url,
    params
  });
}

// 导出年度人数统计
export function exportTripIncomeDetailed(params) {
  const url = getExportUrl('/order/report2/exportTripIncomeDetailed');
  return requestBlob({
    url,
    params
  });
}

// 导出其他支出
export function exportOtherPayStatic(params) {
  const url = getExportUrl('/order/report2/exportOtherPayStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出其他支出汇总
export function exportOtherPayTotal(params) {
  const url = getExportUrl('/order/report2/exportOtherPaySummaryStatistics');
  return requestBlob({
    url,
    params
  });
}

// 导出其他收入汇总
export function exportOtherIncomeSummary(params) {
  const url = getExportUrl('/order/report2/exportOtherIncomeSummaryStatistics');
  return requestBlob({
    url,
    params
  });
}

// 导出其他收入
export function exportOtherIncomeStatic(params) {
  const url = getExportUrl('/order/report2/exportOtherIncomeStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出地接汇总统计
export function exportOutTransferStatic(params) {
  const url = getExportUrl('/order/report2/exportOutTransferStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出类型汇总统计
export function exportResourceStatic(params) {
  const url = getExportUrl('/order/report2/exportResourceStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出客户汇总统计
export function exportCustomerStatic(params) {
  const url = getExportUrl('/order/report2/exportCustomerStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出应付明细表
export function exportTripPayStatic(params) {
  const url = getExportUrl('/order/report2/exportTripPayStatic');
  return requestBlob({
    url,
    params
  });
}
// 导出应付明细表-按区域
export function exportTripPayAreaStatic(params) {
  const url = getExportUrl('/order/report2/exportTripPayAreaStatic');
  return requestBlob({
    url,
    params
  });
}

// 导出导游数据统计
export function exportGuideData(params) {
  const url = getExportUrl('/order/report2/exportGuideData');
  return requestBlob({
    url,
    params
  });
}

// 导出导游组长数据统计
export function exportGuideTeamStatic(params) {
  const url = getExportUrl('/order/report2/exportGuideTeamData');
  return requestBlob({
    url,
    params
  });
}

// 导出导游报账统计
export function exportGuideReimb(params) {
  const url = getExportUrl('/order/report2/exportPlanGuideReimb');
  return requestBlob({
    url,
    params
  });
}

// 导出导游汇总统计
export function exportPlanGuideCount(params) {
  const url = getExportUrl('/order/report2/exportPlanGuideCount');
  return requestBlob({
    url,
    params
  });
}

// 导出导游结算统计
export function exportPlanGuideSettle(params) {
  const url = getExportUrl('/order/report2/exportPlanSettlement');
  return requestBlob({
    url,
    params
  });
}

// 导出借款统计
export function exportPlanGuideBorrow(params) {
  const url = getExportUrl('/order/report2/exportPlanBorrow');
  return requestBlob({
    url,
    params
  });
}

// 导出客户毛利总汇
export function exportCustomerGross(params) {
  const url = getExportUrl('/order/report2/exportCustomerGrossProfitOfMonth');
  return requestBlob({
    url,
    params
  });
}

// 导出酒店房价统计
export function exportHotelHousePrice(params) {
  const url = getExportUrl('/order/report2/hotelRoomPriceStatisticsexport');
  return requestBlob({
    url,
    params
  });
}

// 导出返款统计表2
export function exportSubsidy2Request(params) {
  const url = getExportUrl('/order/report2/subsidyStatistics2-export');
  return requestBlob({
    url,
    params
  });
}

// 导出返款统计表
export function exportSubsidy(params) {
  const url = getExportUrl('/order/report2/subsidyStatistics-export');
  return requestBlob({
    url,
    params
  });
}

// 导游借款列表导出
export function guideBorrowExportRequest(params) {
  const url = getExportUrl('/order/report2/exportGuideBorrowList');
  return requestBlob({
    url,
    params
  });
}

// 客服计调绩效导出
export function exportOutopuser(params) {
  const url = getExportUrl('/order/data-analysis/export-outopuser-analysis');
  return requestBlob({
    url,
    params
  });
}

// 操作计调绩效导出
export function exportDutyOpuser(params) {
  const url = getExportUrl('/order/data-analysis/export-dutyopuser-analysis');
  return requestBlob({
    url,
    params
  });
}

// 应收余额表导出
export function exportUnIncome(params) {
  const url = getExportUrl('/order/report2/report-customer-unIncome-static-export');
  return requestBlob({
    url,
    params
  });
}

// 其他支出导出
export function supplierOtherPayExportRequest(params) {
  const url = getExportUrl('/order/report2/exportOtherPaySummaryStatistics2');
  return requestBlob({
    url,
    params
  });
}