// import FormItem from '../components/FormItem.vue'
import DialogForm from '../components/DialogForm.vue';
import { FooterBar, BillInput } from '@/components/common';
import { RecordColumn as columns } from '@/config/billDown/payRegiment';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { TYPES, CashFeeType } from '@/config/billDown';
export default {
  components: {
    FooterBar,
    BillInput,
    DialogForm
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      TYPES,
      columns,
      tableData: [],
      baseInfo: null,
      orderId: '',
      total: 0,
      resourceId: ''
    };
  },
  computed: {
    getBaseInfo() {
      return this.baseInfo;
    }
  },
  created() {
    const {
      id: orderId,
      rid
    } = this.$route.query;
    this.resourceId = rid;
    this.orderId = orderId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchItem();
      this.fetchPayed();
    },
    fetchItem() {
      this.$store.dispatch('bill/billDown/payRegimentCustomer/fetchOne', {
        prId: this.orderId
      }).then(data => {
        if (!data) return;
        this.setBaseInfo(data.list);
      });
    },
    // 付款记录列表
    fetchPayed(params = {}) {
      return this.$store.dispatch('bill/billDown/pay/fetchPayed', {
        orderId: this.orderId,
        cashType: TYPES.customer_subsidy,
        ...params
      }).then(data => {
        if (!data) return;
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    // fetchBalance() {
    //   return this.$store.dispatch('bill/billDown/pay/fetchPayBalance', {
    //     rid: this.resourceId,
    //     cashType: TYPES.customer_subsidy,
    //   })
    // },

    setBaseInfo(list) {
      this.baseInfo = list.find(it => it.rowId == this.orderId);
    },
    // 修改
    editPayed({
      id,
      cashLogId
    }) {
      const {
        customerName,
        unPay
      } = this.getBaseInfo;
      this.$store.dispatch('bill/billDown/pay/fetchDetail', cashLogId).then(data => {
        if (!data) return;
        this.$refs.dialogRef.show({
          updateId: id,
          unPay,
          ...data,
          resourceName: customerName
        });
      });
    },
    // 删除
    handleDelete({
      id
    }) {
      this.$bus.open(() => {
        const opts = {
          cashType: TYPES.customer_subsidy,
          ids: [id]
        };
        this.$store.dispatch('bill/billDown/pay/deletePay', opts).then(() => {
          this.fetchPayed();
        });
      });
    },
    // 关闭
    handleClose() {
      // mixin 方法
      this.closeSelectedTag();
    },
    formatData(data) {
      const {
        rowId: orderId,
        customerId,
        customerName
      } = this.baseInfo;
      const {
        cashWay,
        cashUserId,
        money,
        updateId
      } = data;
      const cashItem = {
        cash: money,
        cashLogId: 0,
        id: updateId || '',
        cashUserId,
        cashWay,
        orderId,
        customerFeeType: 'SUBSIDY'
      };
      const opts = {
        ...data,
        resourceId: customerId,
        cashAction: CashFeeType.PAY,
        cashType: TYPES.customer_subsidy,
        resourceName: customerName,
        cashes: [cashItem]
      };
      return opts;
    },
    // 保存
    handleSave() {
      const formItem = this.$refs.formItem;
      formItem.validate().then(valid => {
        if (!valid) return;
        const opts = this.formatData(formItem.form);
        this.$store.dispatch('bill/billDown/pay/createPay', opts).then(() => {
          this.$bus.tip();
          this.fetchPayed();
        });
      });
    },
    // 保存
    handleUpdate(data) {
      // const formItem = this.$refs.formItem
      const opts = this.formatData(data);
      this.$store.dispatch('bill/billDown/pay/updatePayed', opts).then(() => {
        this.$bus.tip();
        this.fetchPayed();
      });
    }
  }
};