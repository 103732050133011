var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tourist-shopping form-wrapper",
    staticStyle: {
      "margin-bottom": "80px"
    }
  }, [_c("FormBase", {
    staticClass: "mt20"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_vm._v(" 大团号：" + _vm._s(_vm.dataInfo.planNumber) + " ")]), _c("div", {
    staticClass: "input-box"
  }, [_vm._v(" 团期：" + _vm._s(_vm.dataInfo.startPlanDate) + " "), _c("span", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_vm._v(_vm._s(_vm.dataInfo.endPlanDate))])]), _vm.dataInfo.guides && _vm.dataInfo.guides.length ? _c("div", {
    staticClass: "input-box"
  }, [_vm._l(_vm.dataInfo.guides, function (item) {
    return [_vm._v(" " + _vm._s(item.reimb ? `导游：${item.name}` : "") + " ")];
  })], 2) : _vm._e()])]), _c("TableBase", {
    staticClass: "tourist-table",
    attrs: {
      "column-data": _vm.columnData,
      "show-empty": false,
      "hide-bar": false,
      dynamic: false,
      "empty-text": " ",
      border: ""
    }
  }), _vm._l(_vm.tableData, function (table, index) {
    return [_c("div", {
      key: index + "1",
      class: ["toruist-item", !table.expand ? "is-expand" : ""]
    }, [_c("div", {
      staticClass: "index",
      on: {
        click: function ($event) {
          table.expand = !table.expand;
        }
      }
    }, [_c("i", {
      class: [!table.expand ? "el-icon-arrow-right" : "el-icon-arrow-down"]
    }), _vm._v(" " + _vm._s(index + 1) + " ")]), _c("div", [_vm._v(_vm._s(table.name))]), _c("div", {
      staticClass: "mobile-phone"
    }, [_vm._v(" " + _vm._s(table.mobilePhone) + " ")]), _c("div", {
      staticClass: "card-type"
    }, [_vm._v(" " + _vm._s(_vm.cardType(table.cardType)) + " ")]), _c("div", [_vm._v(_vm._s(table.cardNumber))]), _c("div", {
      staticClass: "total"
    }, [_c("span", {
      staticClass: "red"
    }, [_vm._v(_vm._s(table.total))])]), _c("div", [_c("InputBase", {
      attrs: {
        value: table.remarks,
        placeholder: "请输入备注"
      },
      on: {
        input: function ($event) {
          return _vm.inputRemark(table, $event);
        }
      }
    })], 1), _c("div", {
      staticClass: "action"
    }, [_c("el-link", {
      staticClass: "mr10",
      attrs: {
        type: "success"
      },
      on: {
        click: function ($event) {
          return _vm.handleConfirm(table);
        }
      }
    }, [_vm._v(" 保存 ")]), _c("el-link", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.onAddShop(table);
        }
      }
    }, [_vm._v(" 新增 ")])], 1)]), _c("TableBase", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: table.expand,
        expression: "table.expand"
      }],
      key: index,
      attrs: {
        data: table.details,
        "column-data": _vm.columnData,
        "show-header": false,
        dynamic: false,
        border: ""
      },
      scopedSlots: _vm._u([{
        key: "name",
        fn: function ({
          scope
        }) {
          return [!scope.row.isAdd ? [_vm._v(" " + _vm._s(scope.row.shopName) + " ")] : [_c("SelectShop", {
            attrs: {
              label: "",
              all: false
            },
            on: {
              "on-select": function ($event) {
                return _vm.selectShop(scope.row, $event);
              }
            },
            model: {
              value: scope.row.shopName,
              callback: function ($$v) {
                _vm.$set(scope.row, "shopName", $$v);
              },
              expression: "scope.row.shopName"
            }
          })]];
        }
      }, {
        key: "total",
        fn: function ({
          scope
        }) {
          return [_c("InputNum", {
            model: {
              value: scope.row.money,
              callback: function ($$v) {
                _vm.$set(scope.row, "money", $$v);
              },
              expression: "scope.row.money"
            }
          })];
        }
      }, {
        key: "remarks",
        fn: function ({
          scope
        }) {
          return [_c("InputBase", {
            model: {
              value: scope.row.remark,
              callback: function ($$v) {
                _vm.$set(scope.row, "remark", $$v);
              },
              expression: "scope.row.remark"
            }
          })];
        }
      }, {
        key: "action",
        fn: function ({
          scope
        }) {
          return [_c("el-link", {
            attrs: {
              type: "danger"
            },
            on: {
              click: function ($event) {
                return _vm.handleDelete(table, scope.$index, scope.row);
              }
            }
          }, [_vm._v(" 删除 ")])];
        }
      }], null, true)
    })];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };