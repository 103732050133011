import TablePayed from './TablePayed';
// import BaseInput from '@/views/billDown/components/BaseInput'
// import { SelectShop } from '@/components/common'
import { downBillUnitPayed } from '@/config/billDown';
export default {
  components: {
    TablePayed
  },
  data() {
    return {
      exportLoading: false,
      tableData: [],
      columnData: downBillUnitPayed.slice(0, -1),
      resourceId: '',
      logCashId: '',
      total: 0
    };
  },
  computed: {
    getResourceId() {
      return this.resourceId;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      const {
        rid: resourceId,
        id: logCashId
      } = this.$route.query;
      this.resourceId = resourceId;
      this.logCashId = logCashId;
      this.fetchData({
        resourceId,
        logCashId
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('bill/billDown/fetchPayedShop', {
        ...queryParams,
        rebateType: 'subsidy'
      }).then(data => {
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    handlePage({
      pageSize,
      pageNum
    }) {
      this.fetchData({
        resourceId: this.resourceId,
        logCashId: this.logCashId,
        pageSize,
        pageNum
      });
    }
  }
};