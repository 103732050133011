import { parseDate } from '@/utils';
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    getDate() {
      return parseDate(this.form.createTime);
    },
    formatDate() {
      return val => {
        if (!val) return '';
        const d = new Date(val);
        const month = d.getMonth() + 1;
        const date = d.getDate();
        return `${month}月${date}日`;
      };
    },
    formatRemark() {
      const val = this.form.remarks;
      return val ? val.split('\n') : [];
    }
  }
};