var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "60px"
    }
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.handleSearch
    }
  }, [[_c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小团号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: _vm.slotTitle
    }
  }, [_vm._t("default")], 2), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.tripDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("startTripDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "订单日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("orderDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeDepartment
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1)], _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.changeDate("endDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "线路产品"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.productId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productId", $$v);
      },
      expression: "searchParams.productId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "操作计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.dutyOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserId", $$v);
      },
      expression: "searchParams.dutyOPUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "审核状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      clearable: "",
      label: "",
      type: "examineSearch"
    },
    model: {
      value: _vm.searchParams.auditProgress,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditProgress", $$v);
      },
      expression: "searchParams.auditProgress"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "结清状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "settlementState"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  })], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };