var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Reception", {
    attrs: {
      "has-edit": false
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };