import TableNative from '@/components/common/Table/TableNative';
import { Guides, TouristAge } from '@/components/common';
import { PersonCount } from '@/components/common';
import { getHourGanzhi, getShuxiang, getYear2Wuxing, getYearWuxingVal } from '@/utils/lunar';
import { getBirthday } from '@/utils/idcard';
import { checkBirthdayRange, getAgeByDate } from '@/utils';
import cakeIcon from '@/assets/cake.png';
const columnInfo = [{
  attrs: {
    label: '团号',
    prop: 'planNumber',
    width: 60
  }
}, {
  attrs: {
    label: '线路',
    prop: 'productName',
    width: 60
  }
}, {
  attrs: {
    label: '计调',
    prop: 'dutyOPUserName',
    width: 60
  },
  content: {
    width: 120
  }
}, {
  attrs: {
    label: '导游',
    prop: 'guides',
    width: 60
  },
  content: {
    width: 120
  },
  slot: 'guides'
}, {
  attrs: {
    label: '参团人数',
    prop: 'number',
    width: 60
  },
  content: {
    width: 120
  },
  slot: 'number'
}, {
  attrs: {
    label: '司机',
    prop: 'driver',
    width: 60
  },
  content: {
    width: 200
  },
  slot: 'driver'
}];
const columnNames = [{
  attrs: {
    prop: 'seqNumber',
    label: '序号',
    width: 30
  }
}, {
  attrs: {
    prop: 'name',
    label: '姓名',
    width: 40
  }
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码',
    width: 130
  }
}, {
  attrs: {
    prop: 'location',
    label: '所在地'
  }
}, {
  attrs: {
    prop: 'sex',
    label: '性别',
    width: 30
  },
  slot: 'sex'
}, {
  attrs: {
    prop: 'age',
    label: '年龄',
    width: 30
  },
  slot: 'age'
}, {
  attrs: {
    prop: 'birth',
    label: '生日'
  },
  slot: 'birth'
}, {
  attrs: {
    prop: 'shuxiang',
    label: '属相',
    width: 30
  }
}, {
  attrs: {
    prop: 'ganzhi',
    label: '年干支',
    width: 40
  }
}, {
  attrs: {
    prop: 'wuxing',
    label: '年五行',
    width: 40
  }
}, {
  attrs: {
    prop: 'wuxingYear',
    label: '今年',
    width: 30
  }
}, {
  attrs: {
    prop: 'wuxingYear2',
    label: '明年',
    width: 30
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码',
    width: 80
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 50
  }
}];
export default {
  components: {
    PersonCount,
    Guides,
    TouristAge,
    TableNative
  },
  data() {
    return {
      cakeIcon,
      columnInfo,
      columnNames,
      data: null,
      ages: {}
    };
  },
  computed: {
    getCardType() {
      return val => {
        return val == '0' ? '身份证' : val == '1' ? '护照' : '学生证';
      };
    },
    getSex() {
      return val => {
        return val == 0 ? '男' : '女';
      };
    },
    getMembers() {
      return this.data ? this.data.members : [];
    },
    isBirthDay() {
      return birth => {
        const {
          startTripDate,
          endTripDate
        } = this.$route.query;
        const isBirth = checkBirthdayRange(birth, startTripDate, endTripDate);
        return isBirth ? birth.replace(/^\d{4}-/, '') : '';
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchTeamNameList', id).then(data => {
        if (!data) return;
        data.members = this.formatMembers(data.members);
        this.data = data;
        this.ages.section1 = data.members.filter(it => it.age >= 50 && it.age < 65);
        this.ages.section2 = data.members.filter(it => it.age >= 65 && it.age < 70);
        this.ages.section3 = data.members.filter(it => it.age >= 70);
        this.$emit('onMounted');
      });
    },
    formatMembers(members) {
      const {
        startTripDate
      } = this.$route.query;
      return members.map(it => {
        if (!it.cardNumber) return it;
        const {
          year,
          month,
          day
        } = getBirthday(it.cardNumber);
        it.age = getAgeByDate(it.birthday, startTripDate);
        it.birth = `${month}月${day}日`;
        it.birthday = `${year}-${month}-${day}`;
        it.wuxing = getYear2Wuxing(year);
        it.ganzhi = getHourGanzhi(year);
        it.shuxiang = getShuxiang(year);
        it.wuxingYear = getYearWuxingVal(year);
        it.wuxingYear2 = getYearWuxingVal(year, true);
        return it;
      });
    }
  }
};