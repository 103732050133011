import { BtnLink } from '@/components/common';
import { permissionMixin } from '@/mixin';
const defaultState = {
  create: {
    text: '新增',
    type: 'primary',
    operateIndex: 0
  },
  edit: {
    text: '编辑',
    type: 'success',
    operateIndex: 1
  },
  delete: {
    text: '删除',
    type: 'danger',
    operateIndex: 2
  },
  copy: {
    text: '复制',
    type: 'warning',
    operateIndex: 3
  },
  detail: {
    text: '查看',
    type: 'primary',
    operateIndex: 4
  },
  log: {
    text: '日志',
    type: 'warning',
    operateIndex: 5
  },
  settlement: {
    text: '客户结算价',
    type: 'primary',
    operateIndex: 6
  },
  agreement: {
    text: '协议',
    type: 'primary',
    operateIndex: 7
  }
};
export default {
  name: 'BtnLinkBase',
  components: {
    BtnLink
  },
  mixins: [permissionMixin],
  props: {
    btnType: {
      type: String,
      default: 'delete'
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getType() {
      return this.$attrs.type || defaultState[this.btnType].type;
    },
    getOperateIndex() {
      return defaultState[this.btnType].operateIndex || this.index;
    }
  }
};