import "core-js/modules/es.array.push.js";
import { resourceAgreementShopping } from '@/config/resource';
import { findComponentsUpward } from '@/utils';
const defaultState = () => ({
  driverRate: 0,
  guideRate: 0,
  sellPrice: 0,
  shopRate: 0,
  subsidy: 0,
  contentType: '',
  price: 0,
  quotedPrice: 0,
  contractPriceFrees: 0,
  remarks: '',
  isCustom: true
});
export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: resourceAgreementShopping,
      rowId: 1000000000000,
      hasMergeTail: true,
      form: {},
      minCount: 0
    };
  },
  methods: {
    edit(id, type, data) {
      this.form.resourceType = type;
      this.form.contracId = id;
      this.tableData = this.formatData(data);
      this.rowId += data.length;
    },
    hide() {
      this.visible = false;
    },
    addRow() {
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId
      });
    },
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于 0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.deleteReduction(id) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
    },
    fetchData() {
      const id = this.form.contracId;
      this.$store.dispatch('resource/common/fetchAgreementDetail', id).then(data => {
        this.tableData = this.formatData(data.contractPrices);
        this.rowId += data.contractPrices.length;
      });
    },
    // 删除协议减免
    deleteReduction(id) {
      this.$store.dispatch('resource/common/delPriceFeeAgreement', {
        id
      }).then(() => {
        const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
        findComps.fetchData(this.form.contracId);
      });
    },
    // 添加价格
    addItem(opts) {
      return this.$store.dispatch('resource/common/addAgreementItem', opts);
    },
    // 删除价格
    deleteItem(id) {
      this.$store.dispatch('resource/common/delAgreementItem', id).then(() => {
        this.fetchData();
        const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
        findComps.fetchData(this.form.contracId);
        this.$bus.tip();
      });
    },
    handleDelete(item) {
      const {
        rowId: id
      } = item;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    handleConfirm(opts) {
      // if (!this.validateForm()) return
      const list = this.beforeCommit(this.tableData);

      // const PAll = dataList.map(this.addItem)
      opts.contractPrices = list;
      const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
      findComps.createOrUpdateAgr(opts);
    },
    formatData(list) {
      const result = [];
      if (!list || !list.length) return result;
      list.forEach((it, idx) => {
        const itemList = it.contractPriceFrees || [];
        const item = {
          rowId: it.id,
          ...it
        };
        delete item.contractPriceFrees;
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            itemIndex: idx
          });
        }
      });
      return result;
    },
    beforeCommit(list) {
      const result = [];
      if (!list || !list.length) return result;
      const {
        contracId,
        resourceType
      } = this.form;
      list.forEach(it => {
        it.id = it.rowId;
        it.contracId = contracId;
        it.resourceType = resourceType;
        result.push(it);
      });
      return result;
    },
    validateForm() {
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i];
        const {
          contentType,
          price,
          subsidy
        } = item;
        if (!contentType) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容'
          });
          return false;
        }
        if (!price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写价格'
          });
          return false;
        }
        if (!subsidy) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写返款金额'
          });
          return false;
        }
        return true;
      }
    }
  }
};