import { planTransforTeam } from '@/config/plan';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: [],
      tableData: this.data
    };
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planTransforTeam : filterCustomColumn(planTransforTeam);
  },
  methods: {
    formatData() {}
  }
};