import { SelectStaff } from '@/components/common';
import { rules, defaultState, ENTERPRISE_MODE, ENTERPRISE_RESTYPE } from '../config';
import { getUserData } from '@/utils';
export default {
  components: {
    SelectStaff
  },
  data() {
    return {
      rules,
      visible: false,
      form: defaultState(),
      guideInfo: null,
      content: ''
    };
  },
  computed: {
    teamProfit() {
      return this.$store.getters['plan/index/teamProfit'];
    },
    planInfo() {
      return this.$store.getters['plan/index/planInfo'];
    },
    getGuideName() {
      return this.guideInfo ? this.guideInfo.name : '';
    }
  },
  beforeCreate() {
    if ((!this.teamProfit || !this.teamProfit.length) && this.$route.query.planId) {
      this.$store.dispatch('plan/index/groupProfit', this.$route.query.planId);
    }
  },
  methods: {
    show(item) {
      const {
        id: planId,
        planNumber: content,
        guide,
        guideName,
        applies
      } = item;
      const applyItem = applies && applies[0];
      if (applyItem) {
        this.form.alipayEnterCodeAddMoneyApplyId = applyItem.id;
      }
      const {
        id: applyUserId
      } = getUserData();
      this.form = {
        ...this.form,
        planId,
        content,
        applyUserId,
        bankName: guide && guide.name || guideName
      };
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
    },
    // 保存
    handleConfirm() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) return;
      const {
        content,
        totalGuidePay: total
      } = this.form;
      const opts = {
        ...this.form,
        details: [{
          name: content,
          value: total
        }],
        alipayEnterCodeParams: {
          mode: ENTERPRISE_MODE.PLAN,
          standards: [{
            resType: ENTERPRISE_RESTYPE.DEFAULT,
            value: total
          }]
        }
      };
      this.$store.dispatch('plan/guide/dingdingApply', opts).then(() => {
        this.hide();
        this.$bus.tip();
      });
    }
  }
};