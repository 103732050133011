var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box"
  }, [_vm.hotels ? _c("InputData", {
    attrs: {
      label: "酒店星级",
      list: _vm.hotels
    },
    on: {
      pick: function ($event) {
        return _vm.handleChange("level", $event);
      }
    }
  }) : _vm._e(), _c("InputNumber", {
    attrs: {
      label: "标间"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("double", $event);
      }
    }
  }), _c("InputNumber", {
    attrs: {
      label: "单间"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("single", $event);
      }
    }
  }), _c("InputNumber", {
    attrs: {
      label: "三人间"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("three", $event);
      }
    }
  }), _c("InputNumber", {
    attrs: {
      label: "司陪房"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("driver", $event);
      }
    }
  }), _c("InputNumber", {
    attrs: {
      label: "加床"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("add", $event);
      }
    }
  }), _c("el-checkbox", {
    on: {
      change: _vm.toggleChecbox
    },
    model: {
      value: _vm.form.checked,
      callback: function ($$v) {
        _vm.$set(_vm.form, "checked", $$v);
      },
      expression: "form.checked"
    }
  }, [_vm._v(" 需要订房 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };