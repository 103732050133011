import billBaseMixin from '../billBaseMixin';
import { StatusAudit } from '@/components/common';
import { PersonCount } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillOtherIncome, statisticsKeys } from '@/config/billCheck';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    PersonCount,
    StatusAudit
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      headMergeNum: 5,
      tailMergeNum: checkBillOtherIncome.length - 2,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys,
      type: TYPES.other_income
    };
  }
};