var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")])];
      }
    }, {
      key: "planState",
      fn: function ({
        scope
      }) {
        return [_c("StatusPlanAudit", {
          attrs: {
            status: scope.row.planStatus
          }
        })];
      }
    }, {
      key: "outOPUsers",
      fn: function ({
        scope
      }) {
        return [_c("OutOPUsers", {
          attrs: {
            list: scope.row.outOPUsers
          }
        })];
      }
    }, {
      key: "saleOPUsers",
      fn: function ({
        scope
      }) {
        return [_c("OutOPUsers", {
          attrs: {
            "user-id": _vm.userId,
            list: scope.row.saleOPUsers
          }
        })];
      }
    }, {
      key: "auditState",
      fn: function ({
        scope
      }) {
        return [_c("StatusProgress", {
          attrs: {
            status: scope.row.auditStatus
          }
        })];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.startPlanDate))]), _c("br"), _c("span", [_vm._v(_vm._s(scope.row.endPlanDate))])];
      }
    }, {
      key: "guide",
      fn: function ({
        scope
      }) {
        return [_c("Guides", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };