var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper order-merge-update"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.form.planNumber,
      label: "大团号",
      clearable: "",
      disabled: _vm.isRead
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.form, "planNumber", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      disabled: _vm.isRead,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectProduct", {
    attrs: {
      value: _vm.form.productName,
      label: "产品线路",
      clearable: "",
      disabled: _vm.isRead
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.form, "productName", $event);
      },
      "on-select": _vm.selectProduct
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调",
      disabled: _vm.isRead
    },
    on: {
      "on-select": _vm.selectDutyOPUser
    },
    model: {
      value: _vm.form.dutyOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dutyOPUserName", $$v);
      },
      expression: "form.dutyOPUserName"
    }
  })], 1)]), _c("CollapseBase", {
    attrs: {
      title: "行程",
      "is-strong": ""
    }
  }, [_c("TableTripBase", {
    attrs: {
      "table-data": _vm.itineraryData
    }
  })], 1), _c("CollapseBase", {
    attrs: {
      title: "备注",
      "is-strong": ""
    }
  }, [_c("div", {
    staticClass: "textarea-box"
  }, [_vm._l(_vm.remarkList, function (item, index) {
    return [_c("div", {
      key: item.name + "" + index,
      staticClass: "input-box"
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(item.label))]), _c("el-input", {
      attrs: {
        type: "textarea",
        autosize: {
          minRows: 4,
          maxRows: 6
        },
        label: item.label,
        disabled: _vm.isRead
      },
      on: {
        change: function ($event) {
          return _vm.changeRemark(index, item.name, $event);
        }
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1)];
  })], 2)]), _c("CollapseBase", {
    attrs: {
      title: "小团列表",
      "is-strong": ""
    }
  }, [_c("TableTeam", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading
    }
  })], 1), !_vm.isRead ? _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_vm._v(" 保存更新 ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };