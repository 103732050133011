import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
// import { mapGetters } from 'vuex'
import { SelectShop, BtnExport, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import { soilShopAnalyze } from '@/config/analysis';
import { decimalSplit } from '@/utils/index';
import { mergeTableRow } from '@/utils/table';
import { useEcharts } from '@/utils/echarts';
import analysisMixin from '../analysisMixin';
const listKeys = ['adultCount', 'per', 'tagPer', 'value', 'totalShopRebate'];
export default {
  components: {
    SelectShop,
    TableList,
    BtnExport,
    InputMonthRange
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: soilShopAnalyze,
      isTableShow: true,
      columnsKeys: [],
      chartData: [],
      Xtitles: []
    };
  },
  computed: {
    soilShopData() {
      return this.$store.getters['analyse/index/soilShopData'];
    }
  },
  watch: {
    soilShopData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    // 数据图
    drawLine(dataList, Xtitles, title, refId) {
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById(refId))

      // 绘制图表
      const opts = {
        legend: {
          show: true,
          right: '10%',
          top: '20px'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: [{
          left: 'center',
          text: title
        }],
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: Xtitles
        }],
        yAxis: [{
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        }],
        series: dataList
      };
      useEcharts(refId, opts);
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchSpecialtShop', queryParams);
    },
    initData(list) {
      this.isTableShow = false;
      const tableData = this.formatData(list);
      this.tableData = mergeTableRow(tableData, ['groupType']);
      let adultCountList = [];
      let shopRebateList = [];
      let perList = [];
      const Xtitles = [...new Set(this.Xtitles)];
      this.chartData.forEach(it => {
        const item = {
          name: it.groupType,
          type: 'bar',
          barGap: 0,
          barWidth: 25,
          label: {
            show: true,
            position: 'top'
          }
        };
        const adultCounts = [];
        const shopRebates = [];
        const pers = [];
        it.productTypes.forEach(v => {
          adultCounts.push(v.adultCount);
          shopRebates.push(v.totalShopRebate);
          pers.push(v.per);
        });
        adultCountList.push({
          ...item,
          data: adultCounts
        });
        shopRebateList.push({
          ...item,
          data: shopRebates
        });
        perList.push({
          ...item,
          data: pers
        });
      });
      this.$nextTick(() => {
        this.drawLine(adultCountList, Xtitles, '人数分布', 'adultCountChart');
        this.drawLine(shopRebateList, Xtitles, '购物返佣', 'shopRebateChart');
        this.drawLine(perList, Xtitles, '人均购物', 'perChart');
        this.isTableShow = true;
      });
    },
    // 整合
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      this.chartData = [];
      this.Xtitles = [];
      list.forEach((it, index) => {
        const itemList = it.productTypes;
        const {
          groupType
        } = it;
        const item = {
          groupType,
          mergeId: index
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            v.index = i;
            listKeys.forEach(key => {
              v[key] = 0;
            });
            const {
              shops
            } = v;
            shops.forEach(z => {
              listKeys.forEach(key => {
                v[key] += z[key] || 0;
              });
            });
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
            this.Xtitles.push(v.productType);
          });
        } else {
          result.push({
            ...item,
            itemIndex: 0
          });
        }
        this.chartData.push(it);
      });
      return result.map(it => {
        it.complete = decimalSplit(it.per / it.tagPer * 100) + '%';
        if (!it.tagPer) {
          it.complete = '100%';
        }
        return it;
      });
    }
  }
};