// import Menu from './Menu'
import TreeMenu from './TreeMenu';
// import TripMenu from './TripMenu'
// import { radioAuthArea } from '@/config/radios'
// import { generateTitle } from '@/utils/i18n'
import { hasStaffSelectData } from '@/utils/requestSelectData';
// import { mapGetters } from 'vuex'

export default {
  components: {
    TreeMenu
  },
  data() {
    return {
      // Menu,
      // activeName: 'auth',
      // tabIndex: 0,
      // link: '/system/systemConf',
    };
  },
  // computed: {
  //   ...mapGetters({
  //     rolePermissionList: 'system/role/rolePermissionList',
  //   }),

  //   curIndex() {
  //     return this.tabIndex
  //   },
  // },

  created() {
    // this.fetchData()
    // 判断是否需要加载员工数据
    hasStaffSelectData();
  },
  methods: {
    // generateTitle,
    // fetchData() {
    //   const { id } = this.$route.query
    //   this.fetchPermissionMenu(id)
    // },
    // fetchPermissionMenu(id) {
    //   this.$store.dispatch('system/role/fetchPermission', id).then(data => {
    //     if (!data) return
    //     this.roleMenus = this.formatData2(data)
    //   })
    // },
    // formatData2(list) {
    //   let tmp = []
    //   for (let i = 0; i < list.length; i++) {
    //     let item = list[i]
    //     const obj = {
    //       value: item.id,
    //       label: item.name,
    //       id: item.id,
    //       isRole: item.isRole,
    //       orderNumber: item.orderNumber,
    //       radioAll: false,
    //       checkedAllAction: false, // 操作全选
    //       checked: item.isRole, // 菜单权限是否选择
    //       authArea: item.authArea, // 权限范围的类型
    //       isNeedAuthArea: item.isNeedAuthArea, // 权限范围是否显示在设置中
    //       radios: radioAuthArea,
    //       multipleDepartment: item.multipleDepartment,
    //       actions: item.roleMenuOpts.map(a => {
    //         return { label: a.name, id: a.id, checked: a.isRole }
    //       }),
    //     }
    //     if (!item.subMenus) {
    //       tmp.push({
    //         ...obj,
    //         subMenus: [],
    //       })
    //     } else {
    //       tmp.push({
    //         ...obj,
    //         subMenus: this.formatData2(item.subMenus),
    //       })
    //     }
    //   }
    //   return tmp
    // },
    // formatData(list) {
    //   if (!list || !list.length) return []
    //   let menus = []
    //   list.forEach(role => {
    //     let itemMenus = {
    //       name: role.name,
    //       id: role.id,
    //       isRole: role.isRole,
    //       orderNumber: role.orderNumber,
    //       menus: [],
    //     }
    //     role.subMenus.sort((a, b) => {
    //       return Number(a.orderNumber) - Number(b.orderNumber)
    //     })
    //     role.subMenus.forEach(it => {
    //       if (it.multiplePerson && it.multiplePerson.length) {
    //         it.multiplePerson = it.multiplePerson.map(m => {
    //           const ids = m.visitorList.map(v => v.userId) || []
    //           this.$set(m, 'visitorListCopy', ids)
    //           return m
    //         })
    //       } else {
    //         // 默认数据
    //         it.multiplePerson = [
    //           {
    //             searchColumn: '',
    //             searchColumnValue: '',
    //             visitorList: [],
    //             visitorListCopy: [],
    //           },
    //         ]
    //       }
    //       const opts = {
    //         id: it.id,
    //         label: it.name, // 菜单名称
    //         checked: it.isRole, // 菜单权限是否选择
    //         radios: radioAuthArea,
    //         authArea: it.authArea, // 权限范围的类型
    //         isNeedAuthArea: it.isNeedAuthArea, // 权限范围是否显示在设置中
    //         orderNumber: it.orderNumber,
    //         multipleDepartment: it.multipleDepartment,
    //         multiplePerson: it.multiplePerson,
    //         actions: [],
    //         checkedAll: false,
    //       }
    //       it.roleMenuOpts.forEach(a => {
    //         const item = { label: a.name, id: a.id, checked: a.isRole }
    //         opts.actions.push(item)
    //       })
    //       opts.checkedAll = it.roleMenuOpts.every(role => role.isRole)
    //       itemMenus.menus.push(opts)
    //     })
    //     menus.push(itemMenus)
    //   })
    //   menus.sort((a, b) => {
    //     return Number(a.orderNumber) - Number(b.orderNumber)
    //   })
    //   return menus
    // },
    // handleTab(tab) {
    //   const { index } = tab
    //   this.tabIndex = index
    //   this.roleMenus = this.formatData(this.rolePermissionList)
    // },
  }
};