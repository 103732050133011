var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._m(0), _c("FormBase", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectShop", {
    attrs: {
      label: "购物店"
    },
    on: {
      "on-select": _vm.selectShop
    },
    model: {
      value: _vm.shop,
      callback: function ($$v) {
        _vm.shop = $$v;
      },
      expression: "shop"
    }
  }), _c("SelectGuide", {
    attrs: {
      label: "导游"
    },
    on: {
      "on-select": _vm.selectGuide
    },
    model: {
      value: _vm.guide,
      callback: function ($$v) {
        _vm.guide = $$v;
      },
      expression: "guide"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDatePicker", {
    attrs: {
      label: "进店日期"
    },
    model: {
      value: _vm.form.orderDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orderDate", $$v);
      },
      expression: "form.orderDate"
    }
  }), _c("InputNum", {
    attrs: {
      label: "进店人数"
    },
    on: {
      change: _vm.calcTotal
    },
    model: {
      value: _vm.form.enterStoreMemberCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "enterStoreMemberCount", $$v);
      },
      expression: "form.enterStoreMemberCount"
    }
  }), _c("InputNum", {
    attrs: {
      label: "人头单价"
    },
    on: {
      change: _vm.calcTotal
    },
    model: {
      value: _vm.form.headPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "headPrice", $$v);
      },
      expression: "form.headPrice"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputNum", {
    attrs: {
      label: "停车返佣"
    },
    model: {
      value: _vm.form.totalParkingFeeRebated,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalParkingFeeRebated", $$v);
      },
      expression: "form.totalParkingFeeRebated"
    }
  }), _c("InputBase", {
    attrs: {
      label: "总社利",
      placeholder: "",
      disabled: ""
    },
    model: {
      value: _vm.form.shopAgencyProfit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopAgencyProfit", $$v);
      },
      expression: "form.shopAgencyProfit"
    }
  }), _c("InputNum", {
    attrs: {
      label: "总计",
      disabled: ""
    },
    model: {
      value: _vm.form.total,
      callback: function ($$v) {
        _vm.$set(_vm.form, "total", $$v);
      },
      expression: "form.total"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)]), _c("div", {
    staticClass: "content"
  }, [_c("el-alert", {
    attrs: {
      "show-icon": "",
      type: "warning",
      description: "*说明：社佣=打单金额*社佣比例% 导佣=打单金额*导佣比例% 司佣=打单金额*司佣比例% 全陪=打单金额*全陪比例%"
    }
  }), _c("div", {
    staticClass: "field-header",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 商品列表 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 添加 ")])], 1), _c("TableList", {
    ref: "tableRef",
    attrs: {
      form: _vm.form,
      "plan-id": _vm.form.planId
    }
  })], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field-header"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 基础信息 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };