import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import DialogPay from './DialogPay.vue';
import DialogRenew from '@/components/common/Dialog/DialogRenew.vue';
const PAYMENT = {
  westNorth: 'XBBKLIST',
  south: 'YNBKLIST',
  money: 2000,
  moneySouth: 4000,
  year: 1
};
const FutureAdminRenew = {
  name: '地接系统续费',
  remarks: ''
};
export default {
  components: {
    DialogPay,
    DialogRenew
  },
  data() {
    return {
      FutureAdminRenew,
      viaList: [],
      paySetMeal: []
    };
  },
  created() {
    // 判断当前url是否带参数回来，如果带参数回来则是支付宝支付回调
    const {
      query
    } = this.$route;
    let arr = Object.keys(query);
    if (arr.length) {
      const {
        out_trade_no,
        trade_no,
        total_amount,
        seller_id
      } = query;
      const opts = {
        out_trade_no,
        trade_no,
        total_amount,
        seller_id
      };
      this.updateAlipayNotify(opts);
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('system/payment/vasList').then(data => {
        if (!data) return;
        const {
          list
        } = data;

        // 过滤西北数据
        const filterList = this.filterWest(list, PAYMENT.westNorth);

        // 去重黑名单
        const newList = filterList.reduce((acc, cur) => {
          const item = acc.find(it => it.code == cur.code);
          if (!item) {
            return acc.concat([cur]);
          }
          return acc;
        }, []);

        // 处理金额
        const formatData = this.formatList(newList);
        this.viaList = formatData;
      });
    },
    filterWest(list, code) {
      return list.filter(it => it.code != code);
    },
    formatList(list) {
      return list.map(it => {
        if (it.code == PAYMENT.south) {
          it.money = PAYMENT.moneySouth;
        } else {
          it.money = PAYMENT.money;
        }
        return it;
      });
    },
    updateAlipayNotify(opts) {
      this.$store.dispatch('system/payment/updateAlipayNotify', opts).then(() => {
        const {
          path
        } = this.$route;
        this.$router.push({
          path
        });
        this.$bus.tip();
        this.fetchData();
      });
    },
    onRenew() {
      this.$refs.paymentRef.show();
    },
    onOpen(item) {
      const {
        isOpen
      } = item;
      if (isOpen) return;
      this.$refs.payRef.show(item);
    },
    // 判断两个日期区间
    getTime(start, end) {
      let dateStart = new Date(start).getTime();
      let dateEnd = new Date(end).getTime();
      let dateNew = new Date().getTime();
      if (dateStart <= dateNew < dateEnd) {
        return true;
      }
      return false;
    }
  }
};