var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [scope.row.members.length ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 3
          }
        })] : [_vm._v(" -- ")]];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };