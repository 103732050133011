export const APPLY_MODE = {
  DEFAULT: 'DEFAULT',
  // 常规
  ALIPAY: 'ALIPAY' // 支付宝
};
export const ENTERPRISE_MODE = {
  PLAN: 'PLAN',
  // 按团
  RES: 'RES' // 按单位
};
export const ENTERPRISE_RESTYPE = {
  DEFAULT: 'DEFAULT',
  SCENIC: 'SCENIC',
  HOTEL: 'HOTEL',
  RESTAURANT: 'RESTAURANT'
};
export const defaultState = () => ({
  applyUserId: 0,
  bank: '',
  bankId: 0,
  bankName: '',
  bankNo: '',
  planId: 0,
  type: '导游额度申请',
  totalGuidePay: 0,
  name: '',
  //地接社
  applyMode: APPLY_MODE.ALIPAY
});
export const rules = {
  applyUserId: [{
    required: true,
    message: '申请人不能为空'
  }],
  bankName: [{
    required: true,
    message: '收款人户名不能为空'
  }],
  content: [{
    required: true,
    message: '付款明细不能为空'
  }]
};
export const StatisticsKeys = ['totalAmount', 'payAmount', '$surplusLimit', '$totalPay', '$unPay', '$diff'];