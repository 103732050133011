import "core-js/modules/es.array.push.js";
import { orderFundDetail } from '@/config/plan';
import { mapGetters } from 'vuex';
import { getSummariesMixin } from '@/mixin';
import { mergeTableRow } from '@/utils/table';
import Members from '../Members.vue';
export default {
  components: {
    Members
  },
  mixins: [getSummariesMixin],
  data() {
    return {
      columnData: orderFundDetail,
      statisticsKeys: ['number', 'total', 'amount', 'totalIncome', 'totalIncomed', 'unTotalIncome'],
      needSumKeys: ['total', 'amount'],
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      // tableData: 'order/index/currentFund',
      currentOrderId: 'order/index/currentOrderId',
      planOrderInfo: 'plan/index/planOrderInfo'
    }),
    getType() {
      return (type, id) => {
        const findItem = this.getDictionaryValue(type, id);
        return findItem && findItem.name;
      };
    }
  },
  watch: {
    planOrderInfo: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
        } else {
          this.fetchPlanData(data);
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('plan/index/getOrderByPlanId', id);
    },
    fetchPlanData(data) {
      this.tableData = this.formatData(data);
      this.tableData = mergeTableRow(this.tableData, ['number', 'planInfo', 'welcomeBorad', 'totalIncome', 'totalIncomed', 'unTotalIncome']);
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = it => {
        const itemList = it.fees || [];
        const {
          adultCount,
          childCount,
          accompanyCount,
          customerName,
          welcomeBorad,
          totalCollect,
          totalIncome,
          totalIncomed,
          totalSubsidy,
          customerTripNumber,
          orderNumber,
          id,
          saleUserName,
          members,
          outOPUserName
        } = it;
        // 未收
        const unTotalIncome = Number(it.totalIncome) - Number(it.totalIncomed) - Number(it.totalSubsidy) - Number(it.totalCollect);
        const item = {
          rowId: id,
          mergeId: id,
          adultCount,
          childCount,
          accompanyCount,
          customerName,
          welcomeBorad,
          totalCollect,
          totalIncome,
          totalIncomed,
          totalSubsidy,
          customerTripNumber,
          orderNumber,
          unTotalIncome,
          saleUserName,
          members,
          outOPUserName
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              amount: v.count,
              itemIndex: i
            });
          });
        } else {
          result.push(item);
        }
      };
      list.forEach(it => {
        handleFn(it);
      });
      return result;
    }
  }
};