/* eslint-disable no-unused-vars */
import { turnOutRequest } from '@/api/plan/turnout';
import { orderTypeLabel } from '@/config/order';
import { processMinus } from '@/utils/number';
const formatScenic = list => {
  return list.map(it => {
    const {
      scenics
    } = it;
    return scenics.map(v => `【${v.scenicName}】`);
  }).join('\r\n');
};

// 处理数据
const formatData = (list, sep = '\r\n') => {
  return list.map(it => {
    const {
      planNumber,
      customerTripNumber
    } = it;
    const {
      startTripDate,
      endTripDate
    } = it;
    const {
      product
    } = it;
    const {
      orderType
    } = it;
    const {
      totalPay,
      totalPayed
    } = it;
    const {
      adultCount,
      childCount,
      accompanyCount
    } = it;
    const {
      members
    } = it;
    const {
      productItineraries
    } = it;
    const {
      hotelOS
    } = it;
    it.planInfo = `${planNumber}\r\n${customerTripNumber}`;
    it.date = `${startTripDate}${sep}${endTripDate}`;
    it.productName = product && product.name;
    it.count = `${adultCount}大${childCount}小${accompanyCount}陪`;
    it.unPay = processMinus(totalPay, totalPayed);
    it.memberInfo = members.slice(0, 1).map(v => v.name + '\r\n' + v.mobilePhone).join('\r\n');
    it.itineraries = formatScenic(productItineraries);
    it.hotel = hotelOS.map(v => v.hotelName).join('\r\n');
    it.orderType = orderTypeLabel[orderType];
    return it;
  });
};
const dfaultState = () => ({
  arrangeData: null
});
const state = dfaultState();
const getters = {
  arrangeData: state => state.arrangeData,
  arrangeList: state => {
    return state.arrangeData ? state.arrangeData.list : [];
  }
};
const actions = {
  // 外转订单列表
  fetchArrange({
    commit
  }, params) {
    return turnOutRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = formatData(data.list);
      commit('PLAN_TURN_OUT', data);
      return data;
    });
  },
  // 外转订单列表
  fetchAllArrange({
    commit
  }, params) {
    return turnOutRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = formatData(data.list, '-');
      return data.list;
    });
  }
};
const mutations = {
  PLAN_TURN_OUT: (state, data) => {
    state.arrangeData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};