var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reserve-booking"
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "form-wrapper",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px",
      inline: ""
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "planCustomerNumber",
      label: "大团号"
    }
  }, [_c("div", {
    staticStyle: {
      width: "190px"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentOrder && _vm.currentOrder.planCustomerNumber) + " ")])]), _c("FormItemBase", {
    attrs: {
      prop: "count",
      label: "人数"
    }
  }, [_c("div", {
    staticStyle: {
      width: "190px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getPeopleCount) + " ")])]), _c("FormItemBase", {
    attrs: {
      prop: "region",
      label: "客源地"
    }
  }, [_c("SelectRegion", {
    attrs: {
      props: {
        expandTrigger: "hover",
        checkStrictly: true
      },
      label: ""
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "code",
      label: "预定中心"
    }
  }, [_c("InputResource", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.checkHotel,
      callback: function ($$v) {
        _vm.checkHotel = $$v;
      },
      expression: "checkHotel"
    }
  }, [_c("strong", [_vm._v("酒店")])])], 1), _c("HotelTable", {
    ref: "hotelRef",
    attrs: {
      data: _vm.hotelData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.checkRestaurant,
      callback: function ($$v) {
        _vm.checkRestaurant = $$v;
      },
      expression: "checkRestaurant"
    }
  }, [_c("strong", [_vm._v("餐厅")])])], 1), _c("RestaurantTable", {
    ref: "restaurantRef",
    attrs: {
      data: _vm.restaurantData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.checkFleet,
      callback: function ($$v) {
        _vm.checkFleet = $$v;
      },
      expression: "checkFleet"
    }
  }, [_c("strong", [_vm._v("车队")])])], 1), _c("FleetTable", {
    ref: "fleetRef",
    attrs: {
      data: _vm.fleetData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.checkScenic,
      callback: function ($$v) {
        _vm.checkScenic = $$v;
      },
      expression: "checkScenic"
    }
  }, [_c("strong", [_vm._v("景区")])])], 1), _c("ScenicTable", {
    ref: "scenicRef",
    attrs: {
      data: _vm.scenicData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_vm._m(0), _c("div", {
    staticClass: "booking-item"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    staticClass: "flex-1",
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.guideRemark,
      callback: function ($$v) {
        _vm.guideRemark = $$v;
      },
      expression: "guideRemark"
    }
  })], 1)])])]), _c("FooterBar", [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v(" 下计划 ")])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-row-box"
  }, [_c("strong", {
    staticStyle: {
      "margin-left": "22px"
    }
  }, [_vm._v("导游")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };