var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-bill-advance",
    attrs: {
      title: "添加预收付款",
      width: "960px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.visible ? _c("FormItem", {
    attrs: {
      obj: _vm.item,
      "is-update": true
    },
    on: {
      close: _vm.hide,
      save: _vm.reload
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };