var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hotel-create"
  }, [_c("div", {
    staticClass: "content info-wrapper"
  }, [_c("FormBase", {
    attrs: {
      "label-width": "100px"
    }
  }, [_c("h2", [_vm._v("酒店信息")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "酒店名称",
      maxlength: "20",
      "is-require": "",
      "show-word-limit": ""
    },
    on: {
      blur: function ($event) {
        return _vm.validate("hotelName", $event);
      }
    },
    model: {
      value: _vm.form.hotelName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.hotelName"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "标准",
      code: _vm.hotelStandardType
    },
    on: {
      "on-select": _vm.selectCustomerStandard
    },
    model: {
      value: _vm.form.standard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "standard", $$v);
      },
      expression: "form.standard"
    }
  }), _c("InputBase", {
    attrs: {
      label: "总房数",
      maxlength: "6",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.countRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "countRoom", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.countRoom"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "前台号码",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.telphone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "传真号码",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.faxNumber"
    }
  }), _c("SelectDictionary", {
    attrs: {
      "init-val": _vm.form.drillGrade,
      label: "钻级",
      code: "levelType"
    },
    model: {
      value: _vm.form.drillGrade,
      callback: function ($$v) {
        _vm.$set(_vm.form, "drillGrade", $$v);
      },
      expression: "form.drillGrade"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      "init-val": _vm.form.tripleRoom,
      label: "三人间",
      type: "threeRoom"
    },
    model: {
      value: _vm.form.tripleRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tripleRoom", $$v);
      },
      expression: "form.tripleRoom"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "酒店所属区域",
      code: _vm.hotelRegionType
    },
    on: {
      "on-select": _vm.selectCustomerArea
    },
    model: {
      value: _vm.form.area,
      callback: function ($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _c("SelectRegion", {
    attrs: {
      "init-val": _vm.form,
      label: "省市区"
    },
    on: {
      "on-select": _vm.handleRegion
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectHotelGroup", {
    attrs: {
      label: "集团酒店"
    },
    model: {
      value: _vm.form.groupResourceId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupResourceId", $$v);
      },
      expression: "form.groupResourceId"
    }
  }), _c("SelectDicType", {
    attrs: {
      clearable: "",
      code: "hotel",
      label: "旅监酒店名称"
    },
    on: {
      "visible-change": _vm.beforeSelectDicType
    },
    nativeOn: {
      input: function ($event) {
        return _vm.filterData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.glETravelHotelId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "glETravelHotelId", $$v);
      },
      expression: "form.glETravelHotelId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "企业全称"
    },
    model: {
      value: _vm.form.alipayName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alipayName", $$v);
      },
      expression: "form.alipayName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "企业支付宝"
    },
    model: {
      value: _vm.form.alipayAccount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alipayAccount", $$v);
      },
      expression: "form.alipayAccount"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      label: "详细地址",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.hotelAddr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelAddr", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.hotelAddr"
    }
  }), _c("InputRemark", {
    attrs: {
      label: "停车场信息",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.stoppage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "stoppage", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.stoppage"
    }
  })], 1)]), _c("el-divider"), _c("FormBase", {
    attrs: {
      "label-width": "100px"
    }
  }, [_c("h2", [_vm._v("负责人信息")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "负责人",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.contact"
    }
  }), _c("InputBase", {
    attrs: {
      label: "负责人职位",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.position,
      callback: function ($$v) {
        _vm.$set(_vm.form, "position", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.position"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "负责人手机",
      maxlength: "11",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.mobilePhone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "负责人微信",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.weChat,
      callback: function ($$v) {
        _vm.$set(_vm.form, "weChat", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.weChat"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      maxlength: "255",
      "show-word-limit": "",
      label: "备注信息",
      placeholder: "备注信息"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)]), _c("el-divider"), _c("h2", [_vm._v("酒店设施")]), _c("div", {
    staticClass: "field"
  }, [_c("LabelBase", {
    attrs: {
      label: "早餐餐厅"
    }
  }), _c("div", {
    staticClass: "field-inner"
  }, [_c("Facilities", {
    attrs: {
      form: _vm.formBreakfast,
      label: "早餐厅"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field"
  }, [_c("LabelBase", {
    attrs: {
      label: "正餐餐厅"
    }
  }), _c("div", {
    staticClass: "field-inner"
  }, [_c("Facilities", {
    attrs: {
      form: _vm.formDinner,
      label: "中餐厅"
    }
  }), _c("Facilities", {
    attrs: {
      form: _vm.formWestern,
      label: "西餐厅"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field"
  }, [_c("LabelBase", {
    attrs: {
      label: "会议室"
    }
  }), _c("div", {
    staticClass: "field-inner"
  }, [_c("Facilities", {
    attrs: {
      form: _vm.formMetting,
      label: "有"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field"
  }, [_c("LabelBase", {
    attrs: {
      label: "泳池"
    }
  }), _c("div", {
    staticClass: "field-inner"
  }, [_c("CheckboxBase", {
    attrs: {
      label: "有"
    },
    model: {
      value: _vm.form.isSwim,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isSwim", $$v);
      },
      expression: "form.isSwim"
    }
  })], 1)], 1), _c("el-divider"), _c("FormAccount"), _c("div", {
    staticClass: "footer-bar tac mt20"
  }, [_c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "保存更新" : "添加酒店") + " ")])], 1)], 1), _c("div", {
    staticClass: "content upload-wrapper"
  }, [_c("h2", [_vm._v("照片上传")]), _c("el-upload", {
    ref: "uploadRef",
    staticClass: "upload-container",
    attrs: {
      drag: "",
      multiple: "",
      headers: {
        Authorization: "bearer " + _vm.getToken()
      },
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload,
      action: _vm.uploadUrl,
      "file-list": _vm.fileList,
      "auto-upload": true,
      "list-type": "picture"
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" 将文件拖到此处，或 "), _c("em", [_vm._v("点击上传")])]), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v(" 只能上传jpg/png文件，且不超过2M ")])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };