var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      statistics: _vm.statistics,
      "statistics-keys": _vm.statisticsKeys,
      "summary-method": _vm.handleSummary,
      "cell-class-name": _vm.changeCellClassName,
      total: _vm.total
    },
    on: {
      page: function ($event) {
        return _vm.$emit("page", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.planNumber) + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        row
      }) {
        return [_vm._l(row.customers, function (item) {
          return _c("span", {
            key: item.id
          }, [_vm._v(" " + _vm._s(item.name) + " ")]);
        }), _c("br")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startPlanDate)), _c("br"), _vm._v(" " + _vm._s(row.endPlanDate) + " ")];
      }
    }, {
      key: "area",
      fn: function ({
        row
      }) {
        return [row.planType == _vm.orderType.team ? _c("span", [_vm._v(" " + _vm._s(row.customers[0].customerArea) + " ")]) : _vm._e()];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.planStatus(row.auditStatus)) + " ")];
      }
    }, {
      key: "guides",
      fn: function ({
        row
      }) {
        return _vm._l(row.guides, function (item) {
          return _c("p", {
            key: item.id
          }, [_vm._v(" " + _vm._s(item.name)), _c("br"), _vm._v(" " + _vm._s(item.mobileIphone) + " ")]);
        });
      }
    }, {
      key: "sales",
      fn: function ({
        row
      }) {
        return [_c("SaleUsers", {
          attrs: {
            data: row.saleUsers
          }
        })];
      }
    }, {
      key: "outOPUsers",
      fn: function ({
        row
      }) {
        return [_c("SaleUsers", {
          attrs: {
            data: row.outOPUsers
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };