import TopTitle from '../components/TopTitle';
import { excelMixin } from '@/mixin';
import { TouristAge } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { exportTable, checkBirthdayRange, getAgeByDate, getIdCardInfo } from '@/utils';
const tableHead = [{
  content: 'name',
  label: '姓名',
  span: 2
}, {
  content: 'mobilePhone',
  label: '手机号码',
  span: 4
}, {
  content: 'cardType',
  label: '证件类型',
  span: 2,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号码',
  span: 4
}, {
  content: 'birth',
  label: '生日',
  span: 2,
  slot: 'birth'
}, {
  content: 'sex',
  label: '性别',
  span: 2
}, {
  content: 'age',
  label: '年龄',
  span: 2
}, {
  content: 'location',
  label: '地址',
  span: 6
}];
const columnData = [{
  attrs: {
    prop: 'name',
    label: '姓名'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型'
  }
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  }
}, {
  attrs: {
    prop: 'birth',
    label: '生日'
  }
}, {
  attrs: {
    prop: 'sex',
    label: '性别'
  }
}, {
  attrs: {
    prop: 'age',
    label: '年龄'
  }
}, {
  attrs: {
    prop: 'location',
    label: '地址'
  }
}];
export default {
  components: {
    TopTitle,
    TouristAge
  },
  mixins: [excelMixin],
  data() {
    return {
      originData: [],
      tableData: [],
      columnData,
      tableHead,
      filename: '游客名单表',
      ages: {},
      teamCountAndAge: null
    };
  },
  computed: {
    curOrder() {
      return this.$store.getters['order/index/currentOrder'];
    },
    getTableData() {
      return this.originData;
    },
    isBirthDay() {
      return birth => {
        const {
          startTripDate,
          endTripDate
        } = this.$route.query;
        const isBirth = checkBirthdayRange(birth, startTripDate, endTripDate);
        return isBirth ? birth.replace(/^\d{4}-/, '') : '';
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
    console.log(this.curOrder);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/index/fetchTourist', {
        id
      }).then(data => {
        if (!data) return;
        this.tableData = this.formatData(data);
        this.originData = data;
        this.$emit('onMounted');
      });
    },
    formatData(data) {
      const {
        startTripDate
      } = this.$route.query;
      return data.map(it => {
        const {
          sex,
          cardType,
          cardNumber
        } = it;
        const {
          birth
        } = getIdCardInfo(cardNumber);
        it.age = getAgeByDate(birth, startTripDate);
        it.cardNumber = '\t' + it.cardNumber.toString();
        it.cardType = this.getSelectLabel(cardType);
        it.sex = sex == 0 ? '男' : '女';
        it.birth = birth;
        return it;
      });
    },
    loadedAgeData(data) {
      this.teamCountAndAge = data;
    },
    getSelectLabel(type) {
      const cardType = SELECTOR.cardType.find(it => type == it.value || type == it.label);
      return cardType.label;
    },
    handleExport() {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '无任何数据可导出!'
        });
        return;
      }
      const el = this.$refs.touristTeamRef; //document.querySelector('#TouristTeam')
      const reg = /colspan="\d"/g;
      const str = el.innerHTML.replace(reg, '');
      const node = document.createElement('div');
      node.innerHTML = str;
      exportTable(node, this.filename, this.filename, 2);
      node.remove();
    }
  }
};