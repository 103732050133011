var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("CustomerInfo", {
    attrs: {
      name: _vm.row.customerName
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };