import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/guide/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchGuide(params) {
  return request({
    url: '/resource/guide/page',
    method: 'get',
    params
  });
}

// 明细
export function fetchDetail(id) {
  return request({
    url: '/resource/guide/getById',
    method: 'get',
    params: {
      id
    }
  });
}

// 更新
export function createOrUpdateGuide(data) {
  return request({
    url: '/resource/guide/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteGuide(id) {
  return request({
    url: '/resource/guide/del',
    method: 'post',
    data: {
      guideId: id
    }
  });
}

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/resource/guide/set-pwd',
    method: 'post',
    data
  });
}