import "core-js/modules/es.array.reduce.js";
import SealImage from '@/views/print/components/SealImage';
import TopTitle from '@/views/print/components/TopTitle';
import RemarkList from '@/views/print/components/RemarkList';
import { parseTime, formatIosDate } from '@/utils';
const tableData = [{
  rows: [{
    label: '产品名称'
  }, {
    content: 'productName',
    span: 2
  }, {
    label: '大团号'
  }, {
    content: 'planNumber',
    span: 2
  }, {
    label: '总人数'
  }, {
    content: '电话',
    span: 2,
    slot: 'number'
  }, {
    label: '用车单位'
  }, {
    content: 'busCompanyNames',
    span: 2,
    slot: 'busCompanyNames'
  }]
}, {
  rows: [{
    label: '发团日期'
  }, {
    content: 'startPlanDate',
    span: 2
  }, {
    label: '散团日期'
  }, {
    content: 'endPlanDate',
    span: 2
  }, {
    label: '车型'
  }, {
    content: 'busCompanyTypes',
    span: 2,
    slot: 'busCompanyTypes'
  }, {
    label: '司机'
  }, {
    content: 'driverName',
    span: 2,
    slot: 'driverName'
  }]
}, {
  rows: [{
    label: '操作计调'
  }, {
    content: 'dutyOPUserName',
    span: 2
  }, {
    label: '地陪'
  }, {
    content: 'guideName',
    span: 2
  }, {
    label: '车调'
  }, {
    content: 'busOp',
    span: 2,
    slot: 'busOp'
  }, {
    label: '车号'
  }, {
    content: 'licenceNumbers',
    span: 2,
    slot: 'licenceNumbers'
  }]
}, {
  rows: [{
    label: '房调'
  }, {
    content: 'hotel',
    span: 2,
    slot: 'hotel'
  }, {
    label: '全陪'
  }, {
    content: 'accompanyInfo',
    span: 2
  }, {
    label: '车费'
  }, {
    content: 'busFees',
    span: 2
  }, {
    label: '车备注'
  }, {
    content: 'busRemark',
    span: 2,
    slot: 'busRemark'
  }]
}];

// const tableData2 = [
//   {
//     rows: [
//       { label: '服务标准' },
//       { content: 'serviceStandardRemark', span: 11 },
//     ],
//   },
//   {
//     rows: [
//       { label: '用房' },
//       { content: 'agencyInfoHotelAdjustment', span: 11 },
//     ],
//   },
//   {
//     rows: [{ label: '用餐' }, { content: 'restaurantRemark', span: 11 }],
//   },
//   {
//     rows: [{ label: '用车' }, { content: 'agencyInfoCarAdjustment', span: 11 }],
//   },
//   {
//     rows: [{ label: '景点' }, { content: 'scenicRemark', span: 11 }],
//   },
//   {
//     rows: [{ label: '客服' }, { content: 'agencyInfoService', span: 11 }],
//   },
//   {
//     rows: [
//       { label: '特别交代' },
//       { content: 'friendlyReminderRemark', span: 11 },
//     ],
//   },
//   {
//     rows: [
//       { label: '开票信息' },
//       { content: 'agencyInfoTaxInvoice', span: 11 },
//     ],
//   },
//   {
//     rows: [
//       { label: '票务' },
//       { content: 'agencyInfoTicketBusiness', span: 11 },
//     ],
//   },
//   {
//     rows: [{ label: '运营' }, { content: 'agencyInfoTrade', span: 11 }],
//   },

//   {
//     rows: [{ label: '公司紧急' }, { content: 'agencyInfoUrgent', span: 11 }],
//   },
// ]

export const columnOther = [{
  attrs: {
    label: '服务标准',
    prop: 'serviceStandardRemark',
    width: 80
  }
}, {
  attrs: {
    label: '用房',
    prop: 'agencyInfoHotelAdjustment',
    width: 80
  }
}, {
  attrs: {
    label: '用餐',
    prop: 'restaurantRemark'
  }
}, {
  attrs: {
    label: '用车',
    prop: 'agencyInfoCarAdjustment'
  }
}, {
  attrs: {
    label: '景点',
    prop: 'scenicRemark'
  }
}, {
  attrs: {
    label: '客服',
    prop: 'agencyInfoService'
  }
}, {
  attrs: {
    label: '特别交代',
    prop: 'friendlyReminderRemark',
    width: 80
  }
}, {
  attrs: {
    label: '开票信息',
    prop: 'agencyInfoTaxInvoice'
  },
  slot: 'tax'
}, {
  attrs: {
    label: '票务',
    prop: 'agencyInfoTicketBusiness'
  }
}, {
  attrs: {
    label: '运营',
    prop: 'agencyInfoTrade'
  }
}, {
  attrs: {
    label: '公司紧急',
    prop: 'agencyInfoUrgent'
  }
}];
export default {
  name: 'PlanPersonal',
  components: {
    TopTitle,
    SealImage,
    RemarkList
  },
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      columnOther,
      tableData,
      printDate,
      // tableData2,
      data: null
    };
  },
  computed: {
    getRowspan() {
      return item => {
        return item.respOrderTrips.length;
      };
    },
    formatRoom() {
      return val => {
        if (!val.roomNames) {
          const area = val.area ? val.area.split('|')[0] : '';
          const standard = val.standard ? val.standard.split('|')[0] : '';
          const res = area && standard ? `${area}/${standard}` : `${area}${standard}`;
          return [res];
        }
        let arr = val.roomNames.split('|');
        arr = arr.filter(it => it !== '' && it !== undefined);
        return arr || [];
      };
    },
    isDataRemark() {
      return val => {
        return val == 'scenicRemark' || val == 'busRemark' || val == 'hotelRemark' || val == 'restaurantRemark' || val == 'serviceStandardRemark' || val == 'friendlyReminderRemark';
      };
    },
    getBusTypeList() {
      const data = this.data.busCompanyTypes;
      if (!data) return [];
      let arr = data.split(', ');
      return arr.map(it => {
        return it.split('|')[0];
      });
    },
    getBusRemark() {
      if (this.data && this.data.busCompanySimples.length) {
        return this.data.busCompanySimples.reduce((acc, cur) => {
          return acc, acc + cur.remark;
        }, ',').split(',')[1];
      }
      return '';
    },
    getArrayList() {
      return type => {
        if (!type) return [];
        let val = this.data[type];
        if (!val) return [];
        return val.split(', ');
      };
    },
    formatDateTime() {
      return val => {
        if (!val) return '';
        return formatIosDate(parseTime(val), '{y}-{m}-{d} {h}:{i}');
      };
    }
  },
  created() {
    const {
      planId
    } = this.$route.query;
    this.fetchData(planId);
  },
  methods: {
    fetchData(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('order/print/fetchPersonalPlan', id).then(data => {
        this.data = this.formatData(data);
        this.$emit('onMounted');
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    formatData(data) {
      const findItem = data.respOrderPlanReportLists.find(it => it.accompanyInfo != '');
      data.accompanyInfo = findItem ? findItem.accompanyInfo : '';
      data.respOrderPlanReportLists = data.respOrderPlanReportLists.map(it => {
        it.respOrderTrips = it.respOrderTrips || [{}];
        // 排序
        it.respOrderTrips.sort(function (a, b) {
          return a.itineraryDate > b.itineraryDate ? 1 : -1; //升序  < 降序
        });
        return it;
      });
      data.restaurantRemark = data.travelAgencyVo.agencyInfoRestaurantAdjustment || data.restaurantRemark;
      return {
        ...data,
        ...data.travelAgencyVo
      };
    }
  }
};