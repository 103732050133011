export const itemListKeys = () => ({
  count: 1,
  freeCount: 0,
  isDelete: true,
  otherFee: 0,
  totalPay: 0,
  price: 0,
  restaurantType: ''
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  payType: 'guidesign',
  //sign
  headCompanyId: 0,
  mealType: 'lunch',
  orderDate: '',
  orderNumber: '',
  remarks: '',
  restaurantId: '',
  restaurantName: '',
  times: 1,
  totalApplyCash: 0,
  totalPay: 0,
  rowTotal: 0,
  totalPayed: 0,
  totalSettlement: 0,
  auditProgress: 'WAITAUDIT',
  ...itemListKeys()
});