var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单列表-餐厅",
      width: "90%",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "plan-order-dialog"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripInfo", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("Contact", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("Custom", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "shuttle",
      fn: function ({
        scope
      }) {
        return [_c("Shuttle", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "times",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "times", $event);
            }
          },
          model: {
            value: scope.row.times,
            callback: function ($$v) {
              _vm.$set(scope.row, "times", $$v);
            },
            expression: "scope.row.times"
          }
        })];
      }
    }, {
      key: "mealType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            "init-val": scope.row.mealType.toLowerCase(),
            label: "",
            type: "restaurant"
          },
          model: {
            value: scope.row.mealType,
            callback: function ($$v) {
              _vm.$set(scope.row, "mealType", $$v);
            },
            expression: "scope.row.mealType"
          }
        })];
      }
    }, {
      key: "restaurantType",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "flex ticket-type"
        }, [_c("SelectDictionary", {
          attrs: {
            code: "restaurantStandardType",
            disabled: scope.row.isRead || scope.row.checkAccountStatus
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.restaurantType,
            callback: function ($$v) {
              _vm.$set(scope.row, "restaurantType", $$v);
            },
            expression: "scope.row.restaurantType"
          }
        }), _c("el-button", {
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            type: scope.row.itemIndex > 0 ? "warning" : "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.itemIndex > 0 ? "删除" : "添加") + " ")])], 1)];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "price", $event);
            }
          },
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "adultCount",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "count", $event);
            }
          },
          model: {
            value: scope.row.count,
            callback: function ($$v) {
              _vm.$set(scope.row, "count", $$v);
            },
            expression: "scope.row.count"
          }
        })];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "otherFee", $event);
            }
          },
          model: {
            value: scope.row.otherFee,
            callback: function ($$v) {
              _vm.$set(scope.row, "otherFee", $$v);
            },
            expression: "scope.row.otherFee"
          }
        })];
      }
    }, {
      key: "freeCount",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeInput(scope.row, "freeCount", $event);
            }
          },
          model: {
            value: scope.row.freeCount,
            callback: function ($$v) {
              _vm.$set(scope.row, "freeCount", $$v);
            },
            expression: "scope.row.freeCount"
          }
        })];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope);
            }
          }
        }, [_vm._v(" 移除 ")])];
      }
    }])
  })], 1), _c("FooterBar", {
    attrs: {
      "table-data": _vm.tableData
    },
    on: {
      "on-dialog": _vm.handleOrderDialog,
      "on-save": _vm.handleSave,
      "on-cancel": _vm.hide
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };