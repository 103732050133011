var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      border: "",
      "show-summary": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row.id);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }, {
      key: "actionHead",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };