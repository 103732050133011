export const orderInfo = {
  attrs: {
    prop: 'planInfo',
    label: '订单信息',
    'min-width': 80
  },
  slot: 'planInfo'
};
export const tripDate = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    width: 100
  },
  slot: 'tripDate'
};
export const guide = {
  attrs: {
    prop: 'guideName',
    label: '团上导游'
  }
};
export const orderArrangeRemark = {
  attrs: {
    prop: 'remarks',
    label: '安排备注'
  }
};
export const orderOther = {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
};
export const orderTotalPay = {
  attrs: {
    prop: 'totalPay',
    label: '应付',
    'min-width': 40
  }
};
export const totalSettlement = {
  attrs: {
    prop: 'totalSettlement',
    label: '应付',
    'min-width': 40
  }
};
export const payed = {
  attrs: {
    prop: '',
    label: '已付'
  },
  children: [{
    attrs: {
      prop: 'guidePay',
      label: '导游现付',
      'min-width': 40
    }
  }, {
    attrs: {
      prop: 'totalPayed',
      label: '社付',
      'min-width': 40
    }
  }]
};
export const unPay = {
  attrs: {
    prop: 'unPay',
    label: '欠付',
    'min-width': 40
  }
};
export const auditStatus = {
  attrs: {
    prop: 'auditProgress',
    label: '审核状态',
    'min-width': 90
  }
};
export const orderOpOperation = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
};
export const guideBill = {
  attrs: {
    prop: 'guideName',
    label: '报账导游',
    'min-width': 60
  }
};
export const type = {
  attrs: {
    prop: 'ticketType',
    label: '类别',
    'min-width': 80
  }
};
export const createTime = {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    width: 130
  }
};
export const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
export const RecordColumn = [{
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'orderNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'date',
    label: '订单日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'cashWay',
    label: '付款方式'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '付款日期'
  }
}, {
  attrs: {
    prop: 'kpDate',
    label: '下账日期'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '付款人'
  }
}, {
  attrs: {
    prop: 'cash',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'cashierAuditStatusLabel',
    label: '出纳确认状态'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];