var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planNumber", $$v);
      },
      expression: "searchParams.planNumber"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("startTripDate", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("endTripDate", $event);
      }
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangStartTripDate("orderDate", $event);
      }
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "线路产品",
      clearable: ""
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.searchParams.productName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productName", $$v);
      },
      expression: "searchParams.productName"
    }
  }), _c("SelectGuide", {
    attrs: {
      label: "导游",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("guide", $event);
      }
    },
    model: {
      value: _vm.searchParams.guideLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideLabel", $$v);
      },
      expression: "searchParams.guideLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "小团号"
    },
    model: {
      value: _vm.searchParams.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerTripNumber", $$v);
      },
      expression: "searchParams.customerTripNumber"
    }
  }), _c("SelectBase", {
    attrs: {
      clearable: "",
      label: "计划类型",
      type: "planGenre"
    },
    model: {
      value: _vm.searchParams.planType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planType", $$v);
      },
      expression: "searchParams.planType"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "审核状态",
      list: _vm.orderAuditStatusList,
      clearable: ""
    },
    model: {
      value: _vm.searchParams.auditStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "auditStatus", $$v);
      },
      expression: "searchParams.auditStatus"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selecthotelRegion
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "业务员",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "saleUserId", $$v);
      },
      expression: "searchParams.saleUserId"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "teamType",
      label: "团队类型"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectDic("groupType", $event);
      }
    },
    model: {
      value: _vm.searchParams.groupType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "groupType", $$v);
      },
      expression: "searchParams.groupType"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      code: "productType",
      label: "产品类型"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelectDic("productType", $event);
      }
    },
    model: {
      value: _vm.searchParams.productType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productType", $$v);
      },
      expression: "searchParams.productType"
    }
  }), _c("InputDepartment", {
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1)]), _c("TableprofitTeamSmallSettle", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      statistic: _vm.statistic
    }
  }), _vm.total > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }], null, false, 640951709)
  })] : _vm._e(), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };