var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.itineraryDate) + " ")];
      }
    }, {
      key: "breakfast",
      fn: function ({
        scope
      }) {
        return [scope.row.breakfast ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "lunch",
      fn: function ({
        scope
      }) {
        return [scope.row.lunch ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "dinner",
      fn: function ({
        scope
      }) {
        return [scope.row.dinner ? _c("IconRight") : _c("IconWrong")];
      }
    }, {
      key: "area",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.area) + " ")];
      }
    }, {
      key: "standard",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.standard) + " ")];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.hotelName) + " ")];
      }
    }, {
      key: "roomType",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.rooms, function (item, index) {
          return _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(item.roomType)), _c("br")]);
        });
      }
    }, {
      key: "quantity",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.rooms, function (item, index) {
          return _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(item.quantity)), _c("br")]);
        });
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.rooms, function (item, index) {
          return _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(item.remarks)), _c("br")]);
        });
      }
    }, {
      key: "transport",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.transports, function (item, index) {
          return _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(item.transportName) + " ")]);
        });
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };