import { SelectPlanNumber, SelectDictionary } from '@/components/common';
import { planEditGuideProxy } from '@/config/plan';
import { Members } from '@/components/common';
import { rowspanMixin } from '@/mixin';
const defaultState = () => ({
  accompanyCount: 0,
  adultCount: 0,
  childCount: 0,
  customerName: '',
  customerOrderId: '',
  customerTripNumber: '',
  remarks: '',
  isAgencyCollect: false,
  isGuideCollect: true,
  members: [],
  type: '',
  total: 0
});
export default {
  components: {
    Members,
    SelectPlanNumber,
    SelectDictionary
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditGuideProxy.filter(it => it.slot != 'auditAndCheckStatus' && it.slot != 'actionItem'),
      title: '新增'
    };
  },
  methods: {
    show() {
      this.title = '新增';
      this.visible = true;
      this.tableData = [{
        ...defaultState
      }];
    },
    edit(params) {
      this.title = '编辑';
      this.tableData = [params];
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.$parent.fetchData();
    },
    // 订单选择
    selectOrderNumber(item, val) {
      const {
        id: customerOrderId,
        adultCount,
        childCount,
        accompanyCount,
        customerName,
        members,
        customerTripNumber
      } = val;
      item.members = members;
      item.adultCount = adultCount;
      item.childCount = childCount;
      item.customerName = customerName;
      item.accompanyCount = accompanyCount;
      item.customerOrderId = customerOrderId;
      item.customerTripNumber = customerTripNumber;
    },
    // 类型选择
    selectType(row, item) {
      const {
        label,
        value
      } = item;
      row.type = label;
      row.typeId = value;
    },
    handleCancel() {
      this.hide();
    },
    // 保存
    handleConfirm() {
      if (!this.validateForm()) return;
      this.$store.dispatch('order/index/createOrUpdateProxy', this.tableData).then(() => {
        this.$bus.tip();
        this.hide();
      });
    },
    validateForm() {
      // if (!this.tableData.length) return false
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.total && row.customerOrderId) {
          continue;
        } else {
          this.$bus.tip({
            type: 'warning',
            message: '金额或订单信息不能为空！'
          });
          return false;
        }
      }
      return true;
    }
  }
};