/* eslint-disable no-empty-pattern */
import { fetchUnPayGuide, guideReimbBatch, guideReimbBatchUnPay, guideReimbBatchPayed } from '@/api/bill/guide';
import { addCollect, createDown } from '@/api/bill/billDown';
import { formatList, calcGuideOwePay } from './billDown';
import { TYPES } from '@/config/billDown';
import { processMinus } from '@/utils/number';
const handleCreate = ({
  state,
  cashType,
  baseInput
}) => {
  const {
    cashWay,
    cashUserId
  } = baseInput;
  const filterList = state.reimbBatchUnPayList.filter(it => it.isChecked);
  const cashes = filterList.map(it => {
    const item = {
      cash: it.downPayed,
      cashWay,
      cashUserId,
      orderId: it.rowId,
      // 订单id， 其他为资源id
      type: it.type ? it.type : 'NONE',
      feeType: it.feeType
    };
    return item;
  });
  let opts = {
    ...baseInput,
    money: Math.abs(baseInput.money),
    cashType
  };
  delete opts.subject;
  // 收款
  opts = {
    ...opts,
    cashes,
    cashAction: 'PAY'
  };
  return opts;
};
const defaultStatics = () => ({
  totalPay: 0,
  totalPayed: 0,
  downPayed: 0
});
const dfaultState = () => ({
  downMoney: 0,
  // 当前下账金额
  selectedMoney: 0,
  // 勾选上的金额
  balanceMoney: 0,
  // 余额
  autoDown: false,
  statistics: defaultStatics(),
  billDetail: null,
  reimbBatchData: null,
  reimbBatchList: [],
  reimbBatchUnPayList: []
  // reimbBatchPayedList: [],
});
const state = dfaultState();
const getters = {
  downMoney: state => state.downMoney,
  selectedMoney: state => state.statistics.downPayed,
  balanceMoney: state => {
    return processMinus(state.downMoney, state.statistics.downPayed);
  },
  autoDown: state => state.autoDown,
  statistics: state => state.statistics,
  billDetail: state => state.billDetail,
  reimbBatchList: state => state.reimbBatchList,
  reimbBatchData: state => state.reimbBatchData
};
const actions = {
  fetchUnPay({}, params) {
    return fetchUnPayGuide(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      return data;
    });
  },
  fetchGuideReimbBatch({
    commit
  }, params) {
    return guideReimbBatch(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      // 处理余额
      data.list = data.list.map(it => {
        const {
          cash,
          used
        } = it;
        it.balance = processMinus(cash, used);
        return it;
      });
      commit('REIMB_BATCH_DATA', data);
      return data;
    });
  },
  fetchGuideReimbBatchUnPay({
    commit
  }, params) {
    return guideReimbBatchUnPay(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      data.list = formatList(data.list, calcGuideOwePay);
      commit('REIMB_BATCH_UNPAY', data.list);
      return data;
    });
  },
  fetchGuideReimbBatchPayed({}, params) {
    return guideReimbBatchPayed(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      return data;
    });
  },
  createPayGuide({
    state,
    rootState
  }) {
    const cashType = TYPES.reimb; //导游报账
    const opts = handleCreate({
      state,
      cashType,
      baseInput: rootState['bill/billDown'].baseInput
    });
    return addCollect(opts);
  },
  updatePayGuide({
    state,
    rootState
  }) {
    const {
      baseInput
    } = rootState['bill/billDown'];
    const {
      cashWay,
      cashUserId
    } = baseInput;
    const cashType = TYPES.reimb; //导游报账

    const filterList = state.reimbBatchUnPayList.filter(it => it.isChecked);
    const cashes = filterList.map(it => {
      const item = {
        cashWay,
        cashUserId,
        cash: it.downPayed,
        orderId: it.rowId,
        // 订单id， 其他为资源id
        type: it.type ? it.type : 'NONE',
        feeType: it.feeType,
        cashLogId: state.billDetail.id
      };
      return item;
    });
    let opts = {
      cashes,
      cashType
    };
    return createDown(opts);
  }
};
const mutations = {
  SET_DOWN_MONEY: (state, money) => {
    state.downMoney = money;
  },
  SET_STATISTICS: (state, data) => {
    state.statistics = {
      ...state.statistics,
      ...data
    };
  },
  RESET_STATISTICS: state => {
    state.statistics = defaultStatics();
    state.downMoney = 0;
  },
  TOGGLE_AUTO_DOWN: state => {
    state.autoDown = true;
    setTimeout(() => {
      state.autoDown = false;
    }, 20);
  },
  BILL_DETAIL: (state, data) => {
    state.billDetail = data;
  },
  REIMB_BATCH_DATA: (state, data) => {
    state.reimbBatchList = data.list;
    state.reimbBatchData = data;
  },
  REIMB_BATCH_UNPAY: (state, data) => {
    state.reimbBatchUnPayList = data;
  },
  REIMB_BATCH_DELETE_ITEM: (state, id) => {
    const index = state.reimbBatchList.findIndex(it => it.id === id);
    state.reimbBatchList.splice(index, 1);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};