import "core-js/modules/es.array.push.js";
import { TripNumber } from '@/components/common';
import { StatusAudit } from '@/components/common';
import { getSelectLabel, getPayTypeLabel } from '@/config/select';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin } from '@/mixin';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin],
  data() {
    return {
      tableData: [],
      headMergeNum: 5,
      tailMergeNum: this.columnData.length - 5,
      hasMergeTail: true,
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId'
    })
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.resetData();
    },
    fetchData() {
      this.$store.dispatch('plan/restaurant/fetch', {
        planId: this.currentPlanId
      }).then(data => {
        this.initData(data);
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const children = it.respOrderRestaurantTypes || [];
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          planRestaurantId: id,
          ap: auditProgress,
          auditProgress,
          totalPay: Number(it.totalPay),
          mealType: getSelectLabel('restaurant', it.mealType.toLowerCase()),
          payType: getPayTypeLabel(it.payType)
        };
        if (children.length) {
          children.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    resetData() {
      this.rowspan();
      this.mergeRowMoney(); // 合计单条数据金额
    }
  }
};