// 发团预定安排 酒店
import request from '@/utils/request';
import { APP } from '@/config';

// 安排列表不分页
export function fetchHotelAll(params) {
  return request({
    url: '/order/hotel/hotelAll',
    method: 'get',
    params
  });
}

// 安排列表
export function fetchHotel(params) {
  return request({
    url: '/order/hotel/page',
    method: 'get',
    params,
    timeout: APP.timeout,
    retry: APP.retry,
    retryDelay: APP.retryDelay
  });
}

// 安排更新
export function updateHotel(data) {
  return request({
    url: '/order/hotel/save',
    method: 'post',
    data
  });
}

// 订单更新
export function updateHotelSuper(data) {
  return request({
    url: '/order/hotel/save',
    method: 'post',
    data,
    timeout: 10000,
    headers: {
      tmpSupper: 'TS-1'
    }
  });
}

// 安排删除
export function deleteHotel(ids) {
  return request({
    url: '/order/hotel/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteHotelItem(id) {
  return request({
    url: '/order/hotel/delRoom',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchHotelOrder(params) {
  return request({
    url: '/order/hotel/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateHotelOrder(data) {
  return request({
    url: '/order/hotel/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteHotelOrder(id) {
  return request({
    url: '/order/hotel/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteHotelOrderItem(id) {
  return request({
    url: '/order/hotel/delOrderRoom',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchHotelOrderList(params) {
  return request({
    url: '/order/hotel/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectHotelOrderList(data) {
  return request({
    url: '/order/hotel/saveAndList',
    method: 'post',
    data
  });
}

// 酒店订房状态
export function changeArrange(data) {
  return request({
    url: '/order/hotel/saveOrderStatus',
    method: 'post',
    data
  });
}

// 设置酒店安排
export function setHotelArrange(data) {
  return request({
    url: '/order/plan/setHotelOrderStatus',
    method: 'post',
    data
  });
}