var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "船票名单",
      width: "1200px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: item.label,
        name: item.value
      }
    });
  }), 1), _c("BoatPersonal", {
    ref: "boatRef",
    attrs: {
      "boat-type": _vm.getBoatType,
      columns: _vm.Columns
    }
  })], 1), _c("div", {
    staticClass: "tac mt20"
  }, [_c("el-button", {
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 取消 ")]), _c("el-button", {
    attrs: {
      size: "large",
      type: "success"
    },
    on: {
      click: _vm.handleExport
    }
  }, [_vm._v(" 导出 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };