var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectBase", {
    attrs: {
      "init-val": _vm.row.apName,
      list: _vm.list,
      disabled: _vm.isAuditDisable(_vm.row.auditProgress)
    },
    on: {
      change: _vm.changeRole
    },
    model: {
      value: _vm.auditProgress,
      callback: function ($$v) {
        _vm.auditProgress = $$v;
      },
      expression: "auditProgress"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };