import { searchMixin } from '@/mixin';
import { SelectTraffic } from '@/components/common';
import { monthFirstDay } from '@/utils';
export default {
  components: {
    SelectTraffic
  },
  mixins: [searchMixin],
  data() {
    return {
      orderDate: [monthFirstDay],
      searchParams: {
        transportType: '订票费',
        orderDateS: monthFirstDay
      }
    };
  },
  computed: {
    data() {
      return this.$store.getters['billCheck/channel/channelData'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.$emit('search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    changeDate(type, val) {
      const [start, end] = val || ['', ''];
      this.searchParams[type + 'S'] = start;
      this.searchParams[type + 'E'] = end;
    },
    // 搜索
    handleSearch() {
      // const { path } = this.$route
      // const search = this.searchParams

      // this.$store.dispatch('tagsView/appendVisiteViewSearch', {
      //   path,
      //   search,
      // })

      // // 搜索的时候重置分页
      // this.$store.dispatch('tagsView/appendVisiteViewPage', {
      //   path,
      //   page: 1,
      //   size: search.pageSize,
      // })

      this.$emit('search', this.searchParams);
    },
    handleExport() {
      this.$emit('export', this.searchParams);
    }
  }
};