import ModifyItem from '../components/ModifyItem.vue';
import modifyMixin from '../modify';
import { orderRestaurantInfo } from '@/config/resourceOrder';
import { MealTypeLabel } from '@/config/restaurant';
export default {
  components: {
    ModifyItem
  },
  mixins: [modifyMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: orderRestaurantInfo,
      tableData: []
    };
  },
  computed: {
    getData() {
      return this.data ? [this.data] : [];
    },
    getMealTypeVal() {
      return row => {
        const res = this.getContent(row, 'mealType');
        return res.oldVal;
      };
    },
    getMealTypeLabel() {
      return row => {
        const res = this.getContent(row, 'mealType');
        return {
          oldVal: MealTypeLabel[res.oldVal],
          newVal: res.newdVal ? MealTypeLabel[res.newdVal] : ''
        };
      };
    }
  }
};