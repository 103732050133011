var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "线路名称（不超过30字）",
      maxlength: "30"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };