export default {
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getShuttleType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'pick' ? '接团' : type === 'send' ? '送团' : '市内中转';
      };
    },
    getTaskType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'plan' ? '飞机' : '火车';
      };
    }
  }
};