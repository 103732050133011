import { mapGetters } from 'vuex';
import { SelectStaff, SelectAccount, SelectBankCard, SelectGuide } from '@/components/common';
import { rules, defaultState, calcBalance, cashMode, CashWay, cashModeType } from '@/views/billDown/help';
import { setSubject, selectAccount } from '../helper';
export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard,
    SelectGuide
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    obj: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      radioVal: cashMode,
      autoStatus: true,
      radios: [{
        value: '常规',
        label: cashModeType.COMMON
      }, {
        value: '预付款',
        label: cashModeType.PRE_PAYMENT
      }],
      form: defaultState()
    };
  },
  computed: {
    ...mapGetters({
      prePaymentMoney: 'bill/billDown/pay/prePaymentMoney',
      // billInputForm: 'bill/billDown/billInputForm',
      subjectData: 'system/accounting/subjectData',
      accountData: 'resource/account/accountData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    getBalance() {
      const {
        balance
      } = calcBalance(this.form);
      return balance;
    },
    getPrePayVal() {
      return {
        rid: this.resourceId,
        type: this.radioVal
      };
    },
    isPrePaymentMode() {
      return this.radioVal == cashModeType.PRE_PAYMENT;
    }
  },
  watch: {
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form = this.form || {};
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    },
    obj: {
      handler: function (obj) {
        const bankId = this.accountData ? this.accountData.list[0].id : '';
        this.form = {
          ...this.form,
          ...obj,
          bankId
        };
      },
      immediate: true
    },
    getPrePayVal: {
      handler: function (obj) {
        const {
          rid,
          type
        } = obj;
        if (rid && type == cashModeType.PRE_PAYMENT) {
          this.fetchBalance();
        }
      }
    }
  },
  beforeMount() {
    // 更新不默认设置会计科目
    if (this.isUpdate) return;

    // 设置会计科目
    if (this.subjectData) {
      this.form = {
        ...this.form,
        ...setSubject(this.subjectData)
      };
    }
  },
  beforeDestroy() {
    this.form = {};
  },
  methods: {
    // 查询余额
    fetchBalance() {
      const path = 'bill/billDown/pay/fetchPayBalance';
      return this.$store.dispatch(path, {
        rid: this.form.resourceId || this.obj.rowId || this.obj.id
      });
    },
    changeCashAction(val) {
      this.form.cashAction = val;
    },
    // 下账模式
    changeStatus(val) {
      this.$emit('downStatus', val);
      // this.$store.commit('bill/billDown/SET_BILL_AUTO_STATUS', val)
    },
    changeMoney(money) {
      if (!this.autoStatus) return;
      this.$emit('change', money);
    },
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    changeRadio(val) {
      this.form.cashMode = val;
      if (val == cashModeType.PRE_PAYMENT) {
        this.fetchBalance();
      }
    },
    changeCashMode(val) {
      this.form.cashWay = val == 1 ? CashWay.CASH : CashWay.CARD;
    },
    changeAccount(val) {
      const accountInfo = selectAccount(val);
      this.form = {
        ...this.form,
        ...accountInfo
      };
    },
    processSubject(form) {
      const {
        subjectId,
        subId
      } = form;
      if (subId) {
        form.subjectId = subId;
        form.psubjectId = subjectId;
      }
      return form;
    },
    onSave() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        this.$emit('save', this.form);
        this.onClose();
      });
    },
    onClose() {
      this.form = defaultState();
    }
  }
};