var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.form ? _c("div", {
    staticClass: "resource-plan-form print-page"
  }, [_c("h2", {
    staticClass: "tac"
  }, [_vm._v("客服房、餐、车预订单")]), _c("div", [_c("h3", {
    staticClass: "tar"
  }, [_vm._v("下订单日期：" + _vm._s(_vm.getOrderTime))])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("组团社(团号)")]), _c("th", [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planNumber", $$v);
      },
      expression: "form.planNumber"
    }
  })], 1), _c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("客服")]), _c("td", {
    staticStyle: {
      width: "14%"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.getCustomerInfo))])])])])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("团队人数")]), _c("td", {
    staticStyle: {
      width: "24%"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "flex flex-center"
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.adultCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adultCount", $$v);
      },
      expression: "form.adultCount"
    }
  }), _c("span", {}, [_vm._v("大")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.childCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "childCount", $$v);
      },
      expression: "form.childCount"
    }
  }), _c("span", {}, [_vm._v("小")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.accompanyCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accompanyCount", $$v);
      },
      expression: "form.accompanyCount"
    }
  }), _c("span", {}, [_vm._v("陪")])], 1)])]), _c("th", {
    staticStyle: {
      width: "7%"
    }
  }, [_vm._v("用房数")]), _c("td", {
    staticStyle: {
      width: "43%"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "flex flex-center"
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotelStandardCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelStandardCount", $$v);
      },
      expression: "form.hotelStandardCount"
    }
  }), _c("span", {}, [_vm._v("标")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotelBigCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelBigCount", $$v);
      },
      expression: "form.hotelBigCount"
    }
  }), _c("span", {}, [_vm._v("大")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotelThreeCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelThreeCount", $$v);
      },
      expression: "form.hotelThreeCount"
    }
  }), _c("span", {}, [_vm._v("三")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotelChildCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelChildCount", $$v);
      },
      expression: "form.hotelChildCount"
    }
  }), _c("span", {}, [_vm._v("亲子")]), _c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotelRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelRemarks", $$v);
      },
      expression: "form.hotelRemarks"
    }
  })], 1)])]), _c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("客源地")]), _c("td", [_c("SelectRegion", {
    attrs: {
      label: "",
      disabled: _vm.disabled
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  })], 1)])])]), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("产品")]), _c("td", {
    staticStyle: {
      width: "24%"
    }
  }, [_c("SelectProduct", {
    on: {
      "on-select": _vm.onSelectProduct
    },
    model: {
      value: _vm.form.productId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productId", $$v);
      },
      expression: "form.productId"
    }
  })], 1), _c("th", {
    staticStyle: {
      width: "7%"
    }
  }, [_vm._v("行程天数")]), _c("td", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.itineraries.length) + " ")]), _c("th", {
    staticStyle: {
      width: "7%"
    }
  }, [_vm._v("行程日期")]), _c("td", [_c("div", {
    staticClass: "flex"
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.changeStartDate
    },
    model: {
      value: _vm.form.startTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startTripDate", $$v);
      },
      expression: "form.startTripDate"
    }
  }), _c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.changeEndDate
    },
    model: {
      value: _vm.form.endTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endTripDate", $$v);
      },
      expression: "form.endTripDate"
    }
  })], 1)])])])]), _c("table", [_vm._m(0), _c("draggable", {
    attrs: {
      list: _vm.form.itineraries,
      tag: "tbody",
      move: _vm.onMove
    }
  }, _vm._l(_vm.form.itineraries, function (item, day) {
    return _c("tr", {
      key: day
    }, [_c("td", [_vm._v(_vm._s(item.date))]), _c("td", {
      staticStyle: {
        width: "42%"
      }
    }, [day == 0 ? _c("el-checkbox", {
      model: {
        value: item.isPick,
        callback: function ($$v) {
          _vm.$set(item, "isPick", $$v);
        },
        expression: "item.isPick"
      }
    }, [_vm._v(" 接团 ")]) : _vm._e(), _c("draggable", {
      attrs: {
        list: item.scenicList,
        move: _vm.onMoveTag
      }
    }, _vm._l(item.scenicList, function (tag, index) {
      return _c("el-tag", {
        key: index,
        staticClass: "mt10",
        attrs: {
          "disable-transitions": false,
          closable: ""
        },
        on: {
          close: function ($event) {
            return _vm.removeScenicItem(item, tag);
          }
        }
      }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
    }), 1), _c("div", {
      staticClass: "flex mt10"
    }, [_c("SelectScenic", {
      attrs: {
        "clear-value": true
      },
      on: {
        "on-select": function ($event) {
          return _vm.changeScenic(item, "scenic", $event);
        }
      },
      model: {
        value: item.scenicTempName,
        callback: function ($$v) {
          _vm.$set(item, "scenicTempName", $$v);
        },
        expression: "item.scenicTempName"
      }
    }), _c("SelectShop", {
      attrs: {
        "clear-value": true
      },
      on: {
        "on-select": function ($event) {
          return _vm.changeScenic(item, "shop", $event);
        }
      },
      model: {
        value: item.shopTempName,
        callback: function ($$v) {
          _vm.$set(item, "shopTempName", $$v);
        },
        expression: "item.shopTempName"
      }
    })], 1), _c("el-input", {
      staticClass: "mt10",
      attrs: {
        placeholder: "自定义景区"
      },
      model: {
        value: item.selfScenic,
        callback: function ($$v) {
          _vm.$set(item, "selfScenic", $$v);
        },
        expression: "item.selfScenic"
      }
    }), _c("div", {
      staticClass: "mt10"
    }), day == _vm.form.itineraries.length - 1 ? _c("el-checkbox", {
      model: {
        value: item.isSend,
        callback: function ($$v) {
          _vm.$set(item, "isSend", $$v);
        },
        expression: "item.isSend"
      }
    }, [_vm._v(" 送团 ")]) : _vm._e()], 1), _c("td", {
      staticStyle: {
        width: "16%"
      }
    }, [_c("SelectDictionary", {
      attrs: {
        code: "hotelRegionType"
      },
      on: {
        "on-label": function ($event) {
          return _vm.selectLabel(item.hotelObj, "area", $event);
        }
      },
      model: {
        value: item.hotelObj.area,
        callback: function ($$v) {
          _vm.$set(item.hotelObj, "area", $$v);
        },
        expression: "item.hotelObj.area"
      }
    }), _c("SelectDictionary", {
      staticClass: "mt10",
      attrs: {
        code: "hotelStandardType"
      },
      on: {
        "on-label": function ($event) {
          return _vm.selectLabel(item.hotelObj, "standard", $event);
        }
      },
      model: {
        value: item.hotelObj.standard,
        callback: function ($$v) {
          _vm.$set(item.hotelObj, "standard", $$v);
        },
        expression: "item.hotelObj.standard"
      }
    })], 1), _c("td", {
      staticStyle: {
        width: "24%"
      }
    }, [_c("el-checkbox", {
      model: {
        value: item.restaurantObj.breakfast,
        callback: function ($$v) {
          _vm.$set(item.restaurantObj, "breakfast", $$v);
        },
        expression: "item.restaurantObj.breakfast"
      }
    }, [_vm._v(" 早餐 ")]), _c("el-checkbox", {
      model: {
        value: item.restaurantObj.lunch,
        callback: function ($$v) {
          _vm.$set(item.restaurantObj, "lunch", $$v);
        },
        expression: "item.restaurantObj.lunch"
      }
    }, [_vm._v(" 中餐 ")]), _c("el-checkbox", {
      model: {
        value: item.restaurantObj.dinner,
        callback: function ($$v) {
          _vm.$set(item.restaurantObj, "dinner", $$v);
        },
        expression: "item.restaurantObj.dinner"
      }
    }, [_vm._v(" 晚餐 ")])], 1), _c("td", {
      staticStyle: {
        width: "8%"
      }
    }, [_c("el-button", {
      attrs: {
        type: "danger",
        size: "mini"
      },
      on: {
        click: function ($event) {
          return _vm.onDelete(day);
        }
      }
    }, [_vm._v(" 删除 ")]), _c("i", {
      staticClass: "el-icon-s-grid"
    })], 1)]);
  }), 0), _c("tr", [_c("th", {
    attrs: {
      colspan: 24
    }
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addItinerary
    }
  }, [_vm._v(" 添加行程 ")])], 1)])], 1), _c("table", [_c("tbody", [_c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("订餐")]), _c("td", [_c("el-input", {
    attrs: {
      type: "textarea",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.restaurant,
      callback: function ($$v) {
        _vm.$set(_vm.form, "restaurant", $$v);
      },
      expression: "form.restaurant"
    }
  })], 1)]), _c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("订车")]), _c("td", [_c("el-form", {
    attrs: {
      "label-width": "100px"
    }
  }, [_c("el-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车型",
      prop: "busObj.busType"
    }
  }, [_c("SelectBase2", {
    attrs: {
      list: _vm.busSeatList,
      label: ""
    },
    model: {
      value: _vm.form.busObj.busType,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "busType", $$v);
      },
      expression: "form.busObj.busType"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "用车天数",
      prop: "busObj.useBusDays"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.busObj.useBusDays,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "useBusDays", $$v);
      },
      expression: "form.busObj.useBusDays"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "用车数量",
      prop: "busObj.useBusCount"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.busObj.useBusCount,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "useBusCount", $$v);
      },
      expression: "form.busObj.useBusCount"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车龄",
      prop: "busObj.busAge"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.busObj.busAge,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "busAge", $$v);
      },
      expression: "form.busObj.busAge"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "报价金额",
      prop: "busObj.fee"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.busObj.fee,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "fee", $$v);
      },
      expression: "form.busObj.fee"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "团型",
      prop: "busObj.groupType"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "teamType",
      label: ""
    },
    on: {
      "on-label": function ($event) {
        return _vm.selectLabel(_vm.form.busObj, "groupType", $event);
      }
    },
    model: {
      value: _vm.form.busObj.groupType,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "groupType", $$v);
      },
      expression: "form.busObj.groupType"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "接团信息",
      prop: "busObj.pick"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.busObj.pick,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "pick", $$v);
      },
      expression: "form.busObj.pick"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "送团信息",
      prop: "busObj.send"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.busObj.send,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "send", $$v);
      },
      expression: "form.busObj.send"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "司机服务要求",
      prop: "busObj.driverRemarks"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.busObj.driverRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "driverRemarks", $$v);
      },
      expression: "form.busObj.driverRemarks"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "用车要求",
      prop: "busObj.remarks"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.busObj.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form.busObj, "remarks", $$v);
      },
      expression: "form.busObj.remarks"
    }
  })], 1)], 1)], 1)]), _c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("订酒店")]), _c("td", [_c("el-input", {
    attrs: {
      type: "textarea",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.hotel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotel", $$v);
      },
      expression: "form.hotel"
    }
  })], 1)]), _c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("订船票")]), _c("td", [_c("el-form", {
    attrs: {
      "label-width": "76px"
    }
  }, [_c("div", {
    staticClass: "flex mt10"
  }, [_c("el-form-item", {
    attrs: {
      label: "航向"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "course",
      label: ""
    },
    on: {
      "on-label": function ($event) {
        return _vm.selectLabel(_vm.form.ship, "course", $event);
      }
    },
    model: {
      value: _vm.form.ship.course,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "course", $$v);
      },
      expression: "form.ship.course"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "船型"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "shipType",
      label: ""
    },
    on: {
      "on-label": function ($event) {
        return _vm.selectLabel(_vm.form.ship, "shipType", $event);
      }
    },
    model: {
      value: _vm.form.ship.shipType,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "shipType", $$v);
      },
      expression: "form.ship.shipType"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "乘船日期"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.ship.shipDate,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "shipDate", $$v);
      },
      expression: "form.ship.shipDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "下舱"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.ship.downCount,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "downCount", $$v);
      },
      expression: "form.ship.downCount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "上舱"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.ship.upCount,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "upCount", $$v);
      },
      expression: "form.ship.upCount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "vip舱"
    }
  }, [_c("InputNum", {
    model: {
      value: _vm.form.ship.vipCount,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "vipCount", $$v);
      },
      expression: "form.ship.vipCount"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.ship.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form.ship, "remarks", $$v);
      },
      expression: "form.ship.remarks"
    }
  })], 1)], 1)], 1)]), _c("tr", [_c("th", {
    staticStyle: {
      width: "6%"
    }
  }, [_vm._v("导游")]), _c("td", [_c("el-input", {
    attrs: {
      type: "textarea",
      disabled: _vm.disabled
    },
    model: {
      value: _vm.form.guide,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guide", $$v);
      },
      expression: "form.guide"
    }
  })], 1)])])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("td", [_vm._v("日期")]), _c("td", [_vm._v("行程")]), _c("td", [_vm._v("酒店")]), _c("td", [_vm._v("餐厅")]), _c("td", [_vm._v("操作")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };