import "core-js/modules/es.array.push.js";
// import { mapGetters } from 'vuex'
import { BtnExport } from '@/components/common';
import TableList from './TableList';
import { groupAnalyze } from '@/config/analysis';
import { mergeTableRow } from '@/utils/table';
import { parseTime, decimalSplit } from '@/utils';
const month = parseTime(new Date(), '{y}-{m}');
import analysisMixin from '../analysisMixin';
export default {
  components: {
    TableList,
    BtnExport
  },
  mixins: [analysisMixin],
  data() {
    return {
      searchParams: {
        month
      },
      tableData: [],
      columnData: groupAnalyze
    };
  },
  computed: {
    groupData() {
      return this.$store.getters['analyse/index/groupData'];
    }
  },
  watch: {
    groupData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(res) {
      this.tableData = this.formatData(res);
      this.tableData = mergeTableRow(this.tableData, ['index', 'kpi']);
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchGroup', queryParams);
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach((it, index) => {
        const itemList = it.groupTypes;
        const {
          kpi
        } = it;
        if (itemList.length) {
          itemList.sort(function (a, b) {
            return a.reality - b.reality; //正序
          });
          itemList.forEach(v => {
            if (kpi == '毛利率') {
              v.tag = decimalSplit(v.tagRate * 100) + '%';
              v.reality = decimalSplit(v.realityRate * 100) + '%';
              v.tagYear = decimalSplit(v.tagYearRate * 100) + '%';
            }
            result.push({
              ...it,
              ...v,
              completeRate: decimalSplit(v.completeRate * 100) + '%',
              yearRate: v.yearRate != null ? decimalSplit(v.yearRate * 100) + '%' : '',
              lastRealityRate: v.lastRealityRate != null ? decimalSplit(v.lastRealityRate * 100) + '%' : '',
              mergeId: index
            });
          });
        } else {
          result.push({
            ...it,
            mergeId: index
          });
        }
      });
      return result;
    }
  }
};