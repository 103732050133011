/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchProduct, fetchProductDetail, fetchProductCustom, deleteProduct, addProductCustom, delProductCustom, fetchProductItinerary, createOrUpdateProduct, createOrUpdateProductTrip, deleteProductDays, setStatusProduct, releaseToMall, createMallProcuct } from '@/api/resource/product';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  productData: null,
  productDataOrigin: null,
  productLog: null,
  productCustom: null,
  productDetail: null,
  productItineray: null
});
const state = dfaultState();
const getters = {
  productItineray: state => state.productItineray,
  productDetail: state => state.productDetail,
  productCustom: state => state.productCustom,
  productData: state => state.productData,
  productDataOrigin: state => state.productDataOrigin,
  productLog: state => state.productLog
};
const actions = {
  // 产品列表
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchProduct(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data, 'name', 'productType');
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 产品详情
  fetchDetail({
    commit
  }, id) {
    return fetchProductDetail(id).then(res => {
      const {
        data
      } = res;
      commit('FETCH_CUSTOM', data);
      return data;
    });
  },
  // 获取产品行程
  fetchItinerary({
    commit
  }, id) {
    return fetchProductItinerary(id).then(res => {
      const {
        data
      } = res;
      commit('FETCH_ITINEARY', data);
      return data;
    });
  },
  // 获取产品客户结算价
  fetchCustom({
    commit
  }, queryParam) {
    return fetchProductCustom(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DETAIL', data);
      return data;
    });
  },
  // 获取日志
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.product
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // 添加或更新产品
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdateProduct(opts);
  },
  // 设置产品状态
  setStatus({
    commit
  }, opts) {
    return setStatusProduct(opts);
  },
  // 添加或更新产品行程
  createOrUpdateTrip({
    commit
  }, opts) {
    return createOrUpdateProductTrip(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除产品
  delete({
    commit
  }, id) {
    return deleteProduct(id);
  },
  // 删除产品天数
  deleteDays({
    commit
  }, id) {
    return deleteProductDays(id).then(res => {
      return res.data;
    });
  },
  // 添加产品客户结算价
  addCustom({
    commit
  }, opts) {
    return addProductCustom(opts).then(res => {
      return res.data;
    });
  },
  // 删除客户结算价
  delCustom({
    commit
  }, id) {
    return delProductCustom(id).then(res => {
      return res.data;
    });
  },
  // 设置线路发布到商城状态
  toggleRelease({
    commit
  }, {
    id,
    status
  }) {
    return releaseToMall({
      id,
      status
    });
  },
  // 发布产品到商城
  createMallProduct({
    commit
  }, data) {
    return createMallProcuct(data);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.productData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.productDataOrigin = data;
  },
  FETCH_DETAIL: (state, data) => {
    state.productDetail = data;
  },
  FETCH_CUSTOM: (state, data) => {
    state.productCustom = data;
  },
  FETCH_ITINEARY: (state, data) => {
    state.productItineray = data;
  },
  FETCH_LOG: (state, data) => {
    state.productLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.productData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.productData.list.filter(it => it.id !== id);
    state.productData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};