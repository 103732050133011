import "core-js/modules/es.array.reduce.js";
import { TYPES } from '@/config/resource';
import { formatTableData } from '@/utils';
import { Columns } from './configTable';
import { SearchItem, DialogAdd, DialogUpdate, DialogDetail, DialogAgreement, DialogBindBusiness } from './components';
import { reloadMixin } from '@/mixin';
export default {
  name: 'Resource',
  components: {
    SearchItem,
    DialogAdd,
    DialogUpdate,
    DialogDetail,
    DialogAgreement,
    DialogBindBusiness
  },
  mixins: [reloadMixin],
  data: () => ({
    Columns,
    tableData: [],
    resType: TYPES.shop
  }),
  computed: {
    data() {
      return this.$store.getters['resource/shopping/shoppingDataOrigin'];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    getSearchParams() {
      return this.$refs.searchRef ? this.$refs.searchRef.searchParams : {};
    }
  },
  watch: {
    data: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/shopping/fetch', {
        ...this.getSearchParams,
        ...queryParam
      });
    },
    fetchList() {
      this.$store.dispatch('resource/shopping/fetchList');
    },
    initData() {
      this.fetchData();
    },
    handleData(data) {
      const {
        list
      } = data;
      this.tableData = formatTableData(list);
    },
    deleteItem(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/delete', id).then(() => {
        this.fetchData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    getItemContent(row) {
      const {
        contracts
      } = row;
      let str = '';
      if (!contracts.length) return str;
      str = contracts.reduce((acc, cur) => {
        const {
          contractPrices
        } = cur;
        const val = contractPrices.reduce((pre, it) => {
          pre += `${it.contentType} `;
          return pre;
        }, '');
        acc += val;
        return acc;
      }, '');
      return str;
    },
    showCreateDialog() {
      this.$refs.dialogAddRef.show();
    },
    showEditDialog(row) {
      this.$refs.dialogUpdateRef.show({
        ...row
      });
    },
    deleteTableRow(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    showTableDetail(row) {
      this.$refs.detailRef.show(row, {
        title: '购物店',
        name: '购物店'
      });
    },
    showAgreement(row) {
      this.$refs.agreementRef.show({
        ...row,
        resourceType: TYPES.shop
      });
    },
    showAgreementItem({
      contracId
    }) {
      this.$refs.agreementItemRef.show(contracId, TYPES.shop);
    },
    handleBind(row) {
      this.$refs.bindBusinessRef.show({
        ...row,
        resType: this.resType
      });
    },
    handleUnBind(row) {
      const {
        id: resId,
        travelAgencyId
      } = row;
      const resourceType = this.resType;
      const opts = {
        resourceType,
        travelAgencyId,
        resId
      };
      this.$bus.open(() => {
        this.$store.dispatch('resource/common/delBusinessCode', opts).then(() => {
          this.fetchData();
        });
      }, `是否确认要解绑该商家？`);
    },
    toggleStatus(row) {
      this.createOrUpdate({
        ...row
      });
    },
    showLog(row) {
      const {
        id: resourceId
      } = row;
      this.$store.dispatch('resource/shopping/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/createOrUpdate', opts).then(() => {
        this.fetchData();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};