/* eslint-disable no-empty-pattern */
import { requestToken, requestSave, setUploadStatus } from '@/api/plan/superviseTravel';
import { parseQuerys } from '@/utils/index';
import { getTravelData } from '@/utils/cookie';
// 详情
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/form/854779228237762560

// 删除
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data/delete/854779172000534528/1681214338521169920

// 列表
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data/query/page/854779172000534528

// 查询
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data/query/page/854779172000534528
// {"size":20,"current":1,"conditions":[{"fieldKey":"zuTuanShe","enabledQueryTypes":"like","value":"JYGL20230719单订车01"}],"sorts":[]}

// 更新接口
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data/update/854779172000534528/1682191550569316352

// 导游列表
// http://www.wenhuazhifa.com/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data/query/page/854779169169379328

// 已安排行程id
const MENU_TRIP_MAP = {
  id: '854779172000534528',
  // 地接行程管理
  add: '854779262249373696',
  // 新增的
  arranged: '854779187271995392',
  // 已安排
  tripping: '854779199947182080',
  // 进行中
  overed: '854779193664114688',
  // 已结束
  designId: '854779228237762560',
  guide: '854779169169379328'
};

// 固定请求参数
const PREFIX_URL = '/mgr/kmf-design/app/use/e8e18c98d853f0e7bd03c45f929589a3/dynamic/data';
const SUFFIX_ID = MENU_TRIP_MAP.id;

// http://www.wenhuazhifa.com/kmf-print-ui/#/print/show?id=854781166903791616&name=%E5%9C%B0%E6%8E%A5%E8%A1%8C%E7%A8%8B%E5%8D%95&designId=854779228237762560&dataModelId=854779172000534528&dataId=1682191550569316352&kmfAppId=e8e18c98d853f0e7bd03c45f929589a3
const printUrl = dataId => {
  const {
    ruleKey
  } = getTravelData();
  const baseUrl = 'http://www.wenhuazhifa.com/kmf-print-ui/#/print/show';
  const params = {
    id: '854781166903791616',
    name: '%E5%9C%B0%E6%8E%A5%E8%A1%8C%E7%A8%8B%E5%8D%95',
    designId: MENU_TRIP_MAP.designId,
    dataModelId: MENU_TRIP_MAP.id,
    dataId,
    kmfAppId: ruleKey
  };
  return parseQuerys(baseUrl, params);
};
const defaultParams = {
  designId: MENU_TRIP_MAP.arranged,
  pageDesignId: MENU_TRIP_MAP.arranged
};
const baseUrl = type => {
  return `${PREFIX_URL}/${type}/${SUFFIX_ID}`;
};
const queryUrl = id => {
  return `${PREFIX_URL}/query/page/${id}`;
};
const updateUrl = (type, id) => {
  return `${PREFIX_URL}/${type}/${SUFFIX_ID}/${id}`;
};
const handleRequest = ({
  param = {},
  submitType = 'form',
  submitText = '提交'
}) => {
  const path = baseUrl(submitType);
  const operator = encodeURIComponent(submitText);
  return requestSave({
    ...defaultParams,
    operator,
    path,
    param
  });
};
const updateRequest = ({
  param = {},
  submitType = 'form',
  submitText = '提交'
}) => {
  const {
    id
  } = param;
  const path = updateUrl(submitType, id);
  const operator = encodeURIComponent(submitText);
  return requestSave({
    ...defaultParams,
    operator,
    path,
    param
  });
};
const queryRequest = ({
  id,
  param = {},
  submitText = ''
}) => {
  const path = queryUrl(id);
  const operator = encodeURIComponent(submitText);
  return requestSave({
    ...defaultParams,
    operator,
    path,
    param
  });
};
function findDetail(records, planNumber) {
  let cur;
  if (records && records.length) {
    cur = records[0];
    if (records.length > 1) {
      cur = records.find(it => it.tuanHao == planNumber);
    }
  }
  return cur;
}
const dfaultState = () => ({
  data: null
});
const state = dfaultState();
const getters = {
  data: state => state.data
};
const actions = {
  fetchInfo({
    commit
  }, opt) {
    return requestToken(opt).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      commit('SET_DATA', data);
      return data;
    });
  },
  fetchList({}) {
    return handleRequest({
      submitType: 'query/page'
    });
  },
  save({}, param) {
    return handleRequest({
      param,
      submitType: 'save'
    });
  },
  update({}, param) {
    return updateRequest({
      param,
      submitType: 'update'
    });
  },
  delete({}, param) {
    return handleRequest({
      param,
      submitType: 'delete',
      submitText: '删除'
    });
  },
  // 查询详情
  detail({}, planNumber) {
    return handleRequest({
      param: {
        conditions: [{
          enabledQueryTypes: 'eq',
          fieldKey: 'tuanHao',
          value: planNumber
        }]
      },
      submitType: 'query/page'
    }).then(res => {
      if (!res) return;
      const {
        data
      } = res.data;
      return data;
    });
  },
  guideDetail({}, planNumber) {
    return queryRequest({
      param: {
        fieldList: ['daoYouZhengHao', 'xingMing', 'shouJiHaoMa', 'daoYouZhengHao', 'suoShuGongSi'],
        conditions: [{
          enabledQueryTypes: 'eq',
          fieldKey: 'shouJiHaoMa',
          value: planNumber
        },
        // {
        //   enabledQueryTypes: 'like',
        //   fieldKey: 'shouJiHaoMa_1',
        //   value: planNumber,
        // },
        {
          enabledQueryTypes: 'eq',
          fieldKey: 'guanLianLvXingSheid',
          value: ['当前登录用户所在部门及以下']
        }],
        // keywords: planNumber,
        current: 1,
        size: 20,
        sorts: []
      },
      id: MENU_TRIP_MAP.guide
    }).then(res => {
      if (!res) return;
      const {
        data
      } = res.data;
      return data;
    });
  },
  // 设置上传状态
  setStatus({}, opts) {
    return setUploadStatus(opts);
  },
  // 设置上传状态
  getPrintUrl({
    dispatch
  }, id) {
    return dispatch('detail', id).then(data => {
      if (!data) return;
      const item = findDetail(data.records, id);
      return printUrl(item.id);
    });
  }
};
const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};