var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper flex"
  }, [_c("FormBase", {
    attrs: {
      inline: ""
    }
  }, [_vm._t("default"), _vm.getVisible ? [_vm._t("more")] : _vm._e(), _c("FormItemBase", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }), _vm._t("btn"), _vm.hasMore ? _c("el-button", {
    attrs: {
      type: "warning",
      icon: _vm.getVisible ? "el-icon-arrow-up" : "el-icon-arrow-down"
    },
    on: {
      click: _vm.toggle
    }
  }, [_vm._v(" 更多搜索 ")]) : _vm._e()], 2)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };