import { customerInfoMixin } from '@/mixin';
export default {
  mixins: [customerInfoMixin],
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    filterCustomerInfo() {
      return this.hasCustomerInfo ? this.name : '***';
    }
  }
};