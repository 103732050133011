var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "发起钉钉额度申请",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "140px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "",
      label: "预付款类型"
    }
  }, [_c("strong", {
    staticStyle: {
      "font-size": "40px",
      color: "red"
    }
  }, [_vm._v("导游额度申请")])]), _c("FormItemBase", {
    attrs: {
      prop: "applyUserId",
      label: "申请人"
    }
  }, [_vm._v(" " + _vm._s(_vm.getRealName) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "",
      label: "申请单位"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "details",
      label: "付款明细"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea"
    },
    on: {
      change: function ($event) {
        _vm.content = $event;
      }
    },
    model: {
      value: _vm.form.details,
      callback: function ($$v) {
        _vm.$set(_vm.form, "details", $$v);
      },
      expression: "form.details"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "totalGuidePay",
      label: "申请总金额"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.totalGuidePay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalGuidePay", $$v);
      },
      expression: "form.totalGuidePay"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "totalGuidePay",
      label: "额度使用时间"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      placeholder: "额度使用开始日期"
    },
    model: {
      value: _vm.form.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  }), _c("span", [_vm._v("至")]), _c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      placeholder: "额度使用到期日期"
    },
    model: {
      value: _vm.form.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  })], 1), _c("el-alert", {
    attrs: {
      title: "开始日期时间默认是00:00:00秒，结束日期时间默认是23:59:59秒",
      type: "warning"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "额度使用人"
    }
  }, [_vm._v(" " + _vm._s(_vm.getGuideName) + " ")]), _vm.showInviteBtn ? _c("FormItemBase", {
    attrs: {
      label: "邀请导游开通企业码"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleInvite
    }
  }, [_vm._v(" 发送邀请 ")])], 1) : _vm._e(), !_vm.showInviteBtn && _vm.getInviteLink ? _c("FormItemBase", {
    attrs: {
      label: "企业码链接"
    }
  }, [_vm._v(" " + _vm._s(_vm.getInviteLink) + " "), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.onCopy
    }
  }, [_vm._v("复制")])], 1) : _vm._e()], 1), _c("DialogLinkCopy", {
    ref: "dialogCopyRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };