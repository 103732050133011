import TableList from './TableList';
import { mallOrder as columns } from '@/config/order';
import { reloadMixin } from '@/mixin';
import { MallSystemUrl } from '@/config';
// import { isProd } from '@/utils/env'
// import Cookies from 'js-cookie'

export default {
  components: {
    TableList
  },
  mixins: [reloadMixin],
  data: () => ({
    loading: true,
    columnData: columns,
    total: 0,
    searchParams: {},
    sendDate: [],
    endDate: []
  }),
  computed: {
    orderData() {
      return this.$store.getters['order/mall/orderData'];
    },
    tableData() {
      return this.orderData ? this.orderData.list : [];
    }
  },
  watch: {
    orderData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.total = data.totalCount;
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/mall/fetch', params);
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    handleNavigate() {
      // const url = isProd ? MallSystemUrl.prod : MallSystemUrl.dev
      const url = window.location.host.indexOf('test') < 0 ? MallSystemUrl.prod : MallSystemUrl.dev;
      window.open(url, '_blank');
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    onChangeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    }
  }
};