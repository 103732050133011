import "core-js/modules/es.array.reduce.js";
import { processMinus, decimalSplit } from '@/utils';

// 格式下拉框组件数据
export function formatSelectData(data, key = 'name') {
  if (!data) return;
  return data.map(it => {
    it.label = it[key];
    it.value = it.id;
    return it;
  });
}
export function getPieData(data, keyObj) {
  const {
    reality,
    target
  } = keyObj;
  const realityVal = data[reality.key];
  const targetVal = data[target.key];
  let percent = 0;
  const percentVal = Number(realityVal / targetVal * 100).toFixed(2);
  const unCompleted = processMinus(targetVal, realityVal);
  if (realityVal != 0 && targetVal != 0) {
    percent = percentVal > 100 ? 100 : percentVal;
  }
  const list = [{
    value: percent,
    name: reality.name,
    count: realityVal,
    label: '已'
  }, {
    value: processMinus(100, percent),
    name: target.name,
    count: unCompleted,
    label: '未'
  }];
  return {
    list,
    percent,
    unCompleted: Math.round(unCompleted) * -1
  };
}

// 格式化万
function formatCurrency(num) {
  num /= 10000;
  return decimalSplit(num);
}
export function processCurrency(data, keys) {
  if (!data) return;
  const obj = {};
  keys.forEach(key => {
    obj[key] = data[key];
    obj['$' + key] = formatCurrency(+data[key]);
  });
  return {
    ...data,
    ...obj
  };
}
/**
 *
 * @param {data} data 数据列表
 * @param {keys} keys 饼图的数据字段
 * @param {obj} obj 中间大屏的数据字段
 * @param {function} optFn 饼图的option配置
 * @returns object
 */
export const formatSalesmanPieData = ({
  data: d,
  keys,
  obj,
  optFn,
  ...args
}) => {
  if (!d) return;
  const result = {};
  const {
    list,
    percent,
    unCompleted
  } = getPieData(d, keys);
  const getKeysVal = (d, obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: d[obj[key]]
      };
    }, {});
  };
  const objKeyVal = getKeysVal(d, obj);
  result.data = {
    percent,
    unCompleted,
    ...objKeyVal
  };
  result.option = optFn({
    name: args.title,
    data: list
  });
  return {
    ...result,
    ...args
  };
};
export const formatSalesmanData = ({
  data,
  config
}) => {
  const result = {
    ...config
  };
  if (!data) return result;
  const list = data.filter(it => it.name !== null && it.name !== undefined);
  Object.keys(config).map(key => {
    const dataList = config[key];
    result[key] = dataList.map(item => {
      item.list = [];
      item.list = list.map(val => {
        const count = decimalSplit(val[item.orderKey] * (item.percent ? item.percent : 1));
        return {
          id: val.id,
          name: val.name,
          count: count + (item.suffix || ''),
          percent: decimalSplit(val[item.percentKey] * 100),
          value: count,
          diffValue: item.diffKey && decimalSplit(processMinus(val[item.diffKey], count)) * -1
        };
      });

      // eslint-disable-next-line eqeqeq
      item.list = item.list.filter(it => it.count != 0);

      // 排序方式
      item.list.sort((a, b) => b.count - a.count);

      // 设置echarts数据
      if (item.isCustom) {
        item.option.series[0].data = item.list;
      }
      return item;
    });
  });
  return result;
};