var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "planProfitDetail"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "单团审核表"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("TableSingle", {
    attrs: {
      data: _vm.data,
      "column-data": _vm.columnData1
    }
  }, [_c("PersonCount", {
    attrs: {
      slot: "number",
      row: _vm.data
    },
    slot: "number"
  }), _vm.data.guides.length ? _c("div", {
    attrs: {
      slot: "guide"
    },
    slot: "guide"
  }, [_vm._v(" " + _vm._s(_vm.data.guides[0].guideName) + " ")]) : _c("p", {
    attrs: {
      slot: "guide"
    },
    slot: "guide"
  }, [_vm._v("无")])], 1), _c("TableSingle", {
    attrs: {
      data: _vm.data,
      "column-data": _vm.columnData2,
      frame: "vsides"
    }
  }), _vm.show ? _c("TableProfit", {
    attrs: {
      data: _vm.data
    }
  }) : _vm._e(), _vm.show ? _c("table", [_c("colgroup", _vm._l(24, function (item, colIndex) {
    return _c("col", {
      key: colIndex,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_vm._m(0), _vm._m(1), _c("tr", _vm._l(_vm.feeList, function (item, fee0) {
    return _c("th", {
      key: fee0,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.orderCustomers, function (item, fee1) {
    return _c("tr", {
      key: fee1
    }, [_vm._l(_vm.feeList, function (col, fee2) {
      return [col.key != "title" ? _c("td", {
        key: fee2,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "customerName" && col.key != "people" && col.key != "totalFees" ? _vm._l(item.fees, function (fee, fee4) {
        return _c("p", {
          key: fee4,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(fee[col.key] ? fee[col.key] : "") + " "), !fee[col.key] ? _c("span", {
          staticStyle: {
            color: "#fff"
          }
        }, [_vm._v("空")]) : _vm._e()]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e()];
    })], 2);
  }), _c("tr", _vm._l(_vm.collectList, function (item, collect0) {
    return _c("th", {
      key: collect0,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.orderCustomers, function (item, collect1) {
    return _c("tr", {
      key: collect1
    }, [_vm._l(_vm.collectList, function (col, collect2) {
      return [col.key != "title" ? _c("td", {
        key: collect2,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "customerName" && col.key != "people" && col.key != "totalCollect" ? _vm._l(item.collects, function (coll, collect4) {
        return _c("p", {
          key: collect4,
          staticClass: "p-m"
        }, [col.key == "guideName" ? _c("span", [_vm._v(" " + _vm._s(coll.isGuideCollect && _vm.data.guides.length ? _vm.data.guides[0].guideName : "无") + " ")]) : _c("span", [_vm._v(" " + _vm._s(coll[col.key] ? coll[col.key] : "") + " ")]), !coll[col.key] ? _c("span", {
          staticStyle: {
            color: "#fff"
          }
        }, [_vm._v("空")]) : _vm._e()]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e()];
    })], 2);
  }), _vm.data.shops.length ? _c("tr", _vm._l(_vm.shopPlans, function (item, Hindex) {
    return _c("th", {
      key: Hindex,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.shops, function (item, shopsIndex) {
    return _c("tr", {
      key: shopsIndex
    }, [_vm._l(_vm.shopPlans, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalShopIncome" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "enterStoreMemberCount" && col.key != "headPrice" && col.key != "totalShopIncome" && col.key != "shopName" ? _vm._l(item.goods, function (good, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(good[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalShopIncome" && shopsIndex == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.scenicAdd.length ? _c("tr", _vm._l(_vm.scenicAdd, function (item, Aindex) {
    return _c("th", {
      key: Aindex,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.scenicAdd, function (item, Sindex) {
    return _c("tr", {
      key: Sindex
    }, [_vm._l(_vm.scenicAdd, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalScenicExtraIncome" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "name" ? _vm._l(item.tickets, function (scenic, ticketsIx) {
        return _c("p", {
          key: ticketsIx,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(scenic[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalScenicExtraIncome" && Sindex == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.otherIncomes.length ? _c("tr", _vm._l(_vm.otherIncome, function (item, Oindex) {
    return _c("th", {
      key: Oindex,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.otherIncomes, function (item, indexO) {
    return _c("tr", {
      key: indexO
    }, [_vm._l(_vm.otherIncome, function (col, cIdx) {
      return [col.key != "titleLabel" && col.key != "totalOtherIncome" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])]) : _vm._e(), col.key == "totalOtherIncome" && indexO == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm._m(2), _vm.data.busTypes.length ? _c("tr", _vm._l(_vm.busFee, function (item, Buindex) {
    return _c("th", {
      key: Buindex,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.busTypes, function (item, Btindex) {
    return _c("tr", {
      key: Btindex
    }, [_vm._l(_vm.busFee, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalBusCompanyPay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])]) : _vm._e(), col.key == "totalBusCompanyPay" && Btindex == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _c("tr", _vm._l(_vm.subsidieList, function (item, sub0) {
    return _c("th", {
      key: sub0,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.orderCustomers, function (item, sub1) {
    return _c("tr", {
      key: sub1
    }, [_vm._l(_vm.subsidieList, function (col, sub2) {
      return [col.key != "title" ? _c("td", {
        key: sub2,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "customerName" && col.key != "people" && col.key != "totalSubsidy" ? _vm._l(item.subsidies, function (coll, sub4) {
        return _c("p", {
          key: sub4,
          staticClass: "p-m"
        }, [_c("span", [_vm._v(" " + _vm._s(coll[col.key] ? coll[col.key] : "") + " ")]), !coll[col.key] ? _c("span", {
          staticStyle: {
            color: "#fff"
          }
        }, [_vm._v("空")]) : _vm._e()]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e()];
    })], 2);
  }), _vm.data.hotels.length ? _c("tr", _vm._l(_vm.hotels, function (item, Hindex) {
    return _c("th", {
      key: Hindex,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.hotels, function (item, Htindex) {
    return _c("tr", {
      key: Htindex
    }, [_vm._l(_vm.hotels, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalHotelQuotePay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "name" ? _vm._l(item.rooms, function (room, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(room[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalHotelQuotePay" && Htindex == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.scenicsCost.length ? _c("tr", _vm._l(_vm.scenicsCost, function (item, index0) {
    return _c("th", {
      key: index0,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.scenicsCost, function (item, index1) {
    return _c("tr", {
      key: index1
    }, [_vm._l(_vm.scenicsCost, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalScenicInnerQuotePay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "name" ? _vm._l(item.tickets, function (scenic, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(scenic[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalScenicInnerQuotePay" && index1 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.restaurants.length ? _c("tr", _vm._l(_vm.restaurants, function (item, index2) {
    return _c("th", {
      key: index2,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.restaurants, function (item, index3) {
    return _c("tr", {
      key: index3
    }, [_vm._l(_vm.restaurants, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalRestaurantPay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "name" && col.key != "mealType" ? _vm._l(item.types, function (it, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(it[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalRestaurantPay" && index3 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.insurances.length ? _c("tr", _vm._l(_vm.insurances, function (item, index4) {
    return _c("th", {
      key: index4,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.insurances, function (item, index5) {
    return _c("tr", {
      key: index5
    }, [_vm._l(_vm.insurances, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalInsurancePay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "name" ? _vm._l(item.types, function (it, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(it[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalInsurancePay" && index5 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.transportCompanys.length ? _c("tr", _vm._l(_vm.transportCompanys, function (item, index6) {
    return _c("th", {
      key: index6,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.transportCompanys, function (item, index6) {
    return _c("tr", {
      key: index6
    }, [_vm._l(_vm.transportCompanys, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalTransportCompanyPay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "transportCompanyName" ? _vm._l(item.types, function (it, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(it[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalTransportCompanyPay" && index6 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.otherPays.length ? _c("tr", _vm._l(_vm.otherPays, function (item, index7) {
    return _c("th", {
      key: index7,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.otherPays, function (item, index8) {
    return _c("tr", {
      key: index8
    }, [_vm._l(_vm.otherPays, function (col, cIdx) {
      return [col.key != "titleLabel" && col.key != "totalOtherPay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])]) : _vm._e(), col.key == "totalOtherPay" && index8 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.shops.length ? _c("tr", _vm._l(_vm.shopsPays, function (item, index9) {
    return _c("th", {
      key: index9,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.shops, function (item, index10) {
    return _c("tr", {
      key: index10
    }, [_vm._l(_vm.shopsPays, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalShopRebatePay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key != "enterStoreMemberCount" && col.key != "totalShopRebatePay" ? _vm._l(item.goods, function (good, ix) {
        return _c("p", {
          key: ix,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(good[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalShopRebatePay" && index10 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.scenicAdd.length ? _c("tr", _vm._l(_vm.scenicAddPay, function (item, index11) {
    return _c("th", {
      key: index11,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.scenicAdd, function (item, index12) {
    return _c("tr", {
      key: index12
    }, [_vm._l(_vm.scenicAddPay, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalScenicExtraPay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [col.key == "price" || col.key == "adultCount" || col.key == "money" || col.key == "childCount" || col.key == "childPrice" || col.key == "otherFee" ? _vm._l(item.tickets, function (it, i) {
        return _c("p", {
          key: i,
          staticClass: "p-m"
        }, [_vm._v(" " + _vm._s(it[col.key]) + " ")]);
      }) : _c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])], 2) : _vm._e(), col.key == "totalScenicExtraPay" && index12 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  }), _vm.data.guides.length ? _c("tr", _vm._l(_vm.guidedFee, function (item, index13) {
    return _c("th", {
      key: index13,
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.data.guides, function (item, index14) {
    return _c("tr", {
      key: index14
    }, [_vm._l(_vm.guidedFee, function (col, cIdx) {
      return [col.key != "title" && col.key != "totalGuideServiceFeePay" ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_c("p", {
        staticClass: "p-m"
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")])]) : _vm._e(), col.key == "totalGuideServiceFeePay" && index14 == 0 ? _c("td", {
        key: cIdx,
        attrs: {
          rowspan: col.rowspan ? col.cRow : col.rowspan,
          colspan: col.span
        }
      }, [_vm._v(" " + _vm._s(item[col.key]) + " ")]) : _vm._e()];
    })], 2);
  })], 2)]) : _vm._e()], 1), _c("SealImage", {
    attrs: {
      comp: "PlanProfitDetail"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【收入明细】")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_c("strong", [_vm._v("团款收入")])]), _c("td", {
    attrs: {
      colspan: "22"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("成本明细")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };