import { SelectRiver } from '@/components/common';
const defaultState = () => ({
  enterpriseId: '',
  guide: '',
  phone: '',
  rideBy: 'boat',
  rideBy_dictText: '游船',
  appointmentTime: '',
  remark: ''
});
const rules = {
  enterpriseId: [{
    required: true,
    message: '请选择所属景区公司',
    trigger: 'blur'
  }],
  guide: [{
    required: true,
    message: '请填写导游姓名',
    trigger: 'blur'
  }],
  phone: [{
    required: true,
    message: '请填写导游电话',
    trigger: 'blur'
  }]
};
export default {
  components: {
    SelectRiver
  },
  props: {
    guide: {
      type: Object,
      default: () => {}
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: defaultState(),
      rules
    };
  },
  watch: {
    guide: {
      handler: function (data) {
        if (!data) return;
        const {
          guideName: guide,
          guidePhone: phone,
          rideBy
        } = data;
        this.form = {
          ...this.form,
          guide,
          phone,
          rideBy_dictText: rideBy,
          ...data
        };
      },
      immediate: true
    }
  },
  methods: {
    selectBoat(val) {
      const {
        value
      } = val;
      this.form.rideBy = value;
    },
    handleValid() {
      return this.$refs.formRef.validate().then(valid => {
        return valid;
      });
    }
  }
};