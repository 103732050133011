var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "登陆账户名称",
      "is-require": "",
      disabled: _vm.isUpdate ? true : false
    },
    on: {
      blur: function ($event) {
        return _vm.validate("userName", $event);
      },
      change: function ($event) {
        return _vm.handleChange("userName", $event);
      }
    },
    model: {
      value: _vm.form.userName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  }), _c("InputBase", {
    attrs: {
      type: "password",
      label: "登陆密码",
      "is-require": "",
      maxlength: 18
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("password", $event);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "姓名",
      "is-require": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("realName", $event);
      }
    },
    model: {
      value: _vm.form.realName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "realName", $$v);
      },
      expression: "form.realName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "手机",
      "is-require": ""
    },
    on: {
      blur: function ($event) {
        return _vm.validate("mobilePhone", $event);
      },
      change: function ($event) {
        return _vm.handleChange("mobilePhone", $event);
      }
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDepartment", {
    attrs: {
      "is-require": "",
      "init-val": _vm.departmentItem
    },
    on: {
      "on-select": _vm.changeDepartment
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }), _c("SelectRole", {
    attrs: {
      "is-require": "",
      label: "角色"
    },
    on: {
      change: _vm.changeRole
    },
    model: {
      value: _vm.form.roleName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roleName", $$v);
      },
      expression: "form.roleName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "座机"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("telphone", $event);
      }
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", $$v);
      },
      expression: "form.telphone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "传真"
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", $$v);
      },
      expression: "form.faxNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "身份证"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("idCard", $event);
      }
    },
    model: {
      value: _vm.form.idCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "idCard", $$v);
      },
      expression: "form.idCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      label: "备注"
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    on: {
      change: function ($event) {
        return _vm.handleChange("isEnable", $event);
      }
    },
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };