import request from '@/utils/request';

// 询价列表
export function inquireBusListRequest(params) {
  return request({
    url: '/order/inquiry-bus/page',
    method: 'get',
    params
  });
}

// 询价详情
export function inquireBusDetailRequest(params) {
  return request({
    url: '/order/inquiry-bus/inquiry',
    method: 'get',
    params
  });
}

// 询价新增
export function inquireBusSetRequest(data) {
  return request({
    url: '/order/inquiry-bus/add-or-update',
    method: 'post',
    data
  });
}

// 询价删除
export function inquireBusDelRequest(data) {
  return request({
    url: '/order/inquiry-bus/del',
    method: 'post',
    data
  });
}