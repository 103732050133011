import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { BtnExport, TableTotalBox, SelectStaff } from '@/components/common';
import TableList from './TableList';
import { dutOpUserList } from '@/config/report';
import { searchMixin } from '@/mixin';
import { parseDate, decimalSplit } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
import { mapGetters } from 'vuex';
export default {
  components: {
    TableList,
    BtnExport,
    TableTotalBox,
    SelectStaff
  },
  mixins: [searchMixin],
  data: () => ({
    link: {
      checks: '/bill-check/reception'
    },
    tableLoading: false,
    columnData: dutOpUserList,
    tableData: [],
    sendDate: [today, ''],
    endDate: [],
    orderDate: [],
    total: 0,
    statisticData: [],
    statistic: 0,
    searchParams: {
      startTripDateS: today
    }
  }),
  computed: {
    ...mapGetters({
      dutyOPUserData: 'report/index/dutyOPUserData'
    }),
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            startTripDateS,
            startTripDateE,
            endTripDateS,
            endTripDateE,
            orderDateE,
            orderDateS
          } = data;
          this.searchParams = data;
          this.sendDate = [startTripDateS, startTripDateE];
          this.endDate = [endTripDateS, endTripDateE];
          this.orderDate = [orderDateS, orderDateE];
        }
      },
      immediate: true
    },
    dutyOPUserData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    onLink({
      row,
      type
    }) {
      const {
        customerName,
        customerId
      } = row;
      this.$router.push({
        path: this.link[type],
        query: {
          customerName,
          customerId
        }
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'orderDate') {
        this.searchParams.orderDateS = date[0];
        this.searchParams.orderDateE = date[1];
      }
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list.map(it => {
        const total = it.totalIncome + it.feeIncome;
        it.grossProfitRate = total ? decimalSplit(Number(it.totalQuoteGrossProfit) / total * 100) + '%' : total;
        return it;
      });
      this.total = totalCount;
      this.statistic = statistics;
      const totalSum = statistics.totalIncome + statistics.feeIncome;
      statistics.grossProfitRate = totalSum ? decimalSplit(Number(statistics.totalQuoteGrossProfit) / totalSum * 100) + '%' : totalSum;
      this.statisticData.push(statistics);
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/fetchDutyOpuser', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/exportDutyOpuser', this.searchParams).then(res => {
        const fileName = '操作计调绩效统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};