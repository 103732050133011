var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog-detail",
    attrs: {
      title: "车牌号",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.info ? _c("div", {
    staticClass: "resource-detail"
  }, [_c("h3", [_vm._v("基本信息")]), _vm.info.licenceNumber ? _c("p", [_vm._v(" 车牌号： "), _c("strong", [_vm._v(_vm._s(_vm.info.licenceNumber))])]) : _vm._e(), _vm.info.isEnable ? _c("p", [_vm._v(" 状态： "), _c("strong", [_vm._v(_vm._s(_vm.info.isEnable ? "已启用" : "未启用"))])]) : _vm._e(), _c("el-divider"), _c("h3", [_vm._v("行驶证")]), _c("div", {
    staticClass: "img-wrappe"
  }, [_c("el-image", {
    staticStyle: {
      width: "250px",
      height: "160px",
      "margin-right": "15px"
    },
    attrs: {
      src: _vm.getImageDriver,
      fit: "cover"
    }
  }, [_c("div", {
    staticClass: "image-slot-empty",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])])], 1), _c("el-divider"), _c("h3", [_vm._v("运营资格证")]), _c("div", {
    staticClass: "img-wrappe"
  }, [_c("el-image", {
    staticStyle: {
      width: "250px",
      height: "160px",
      "margin-right": "15px"
    },
    attrs: {
      src: _vm.getImageCert,
      fit: "cover"
    }
  }, [_c("div", {
    staticClass: "image-slot-empty",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])])], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };