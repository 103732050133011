// 签单状态，'completed'=完成，'waitAudit'=已派单/待审核，'unArrange'=未派单/待安排,可用值:COMPLETED,WAITAUDIT,UNARRANGE
export const TicketStatusEnum = {
  COMPLETED: 'COMPLETED',
  WAITAUDIT: 'WAITAUDIT',
  UNARRANGE: 'UNARRANGE'
};
export const TicketStatus = {
  COMPLETED: '完成',
  WAITAUDIT: '已派单',
  UNARRANGE: '未派单'
};
export const TicketAuditStatus = {
  COMPLETED: '完成',
  WAITAUDIT: '待审核',
  UNARRANGE: '待安排'
};

// 派发按钮事件状态
export const TicketBtnStatus = {
  WAITAUDIT: false,
  UNARRANGE: true,
  COMPLETED: false
};

// 审核按钮事件状态
export const TicketAuditBtnStatus = {
  WAITAUDIT: false,
  UNARRANGE: true,
  COMPLETED: false
};

// 审核完成状态
export const TicketCompleteStatus = {
  COMPLETED: true,
  WAITAUDIT: false,
  UNARRANGE: false
};
export const SignTypeLabel = {
  HOTEL: '酒店',
  SCENIC: '景区',
  BUS: '车队',
  RESTAURANT: '餐厅'
};

// 查询票据
export const searchTicketData = [{
  attrs: {
    prop: 'signNumber',
    label: '单据号'
  },
  slot: 'signNumber'
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'unit',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'signDate',
    label: '签单日期'
  }
}, {
  attrs: {
    prop: 'signType',
    label: '票据类型'
  },
  slot: 'signType'
}, {
  attrs: {
    prop: 'isUsed',
    label: '是否已使用'
  },
  slot: 'isUsed'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  },
  slot: 'guideName'
}, {
  attrs: {
    prop: 'numberPeople',
    label: '人数'
  },
  slot: 'numberPeople'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 签单订单列表
export const ticketOrder = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '发团/散团'
  },
  slot: 'date'
},
// slot: 'date'
{
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'guide',
    label: '导游',
    'min-width': 200
  },
  slot: 'guide'
},
// slot: 'guide'
{
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: '',
    label: '欠签到状态'
  },
  slot: 'behindhandSignStatus'
}, {
  attrs: {
    prop: 'planStatus',
    label: '团状态'
  },
  slot: 'planStatus'
}, {
  attrs: {
    prop: 'ticketStatus',
    label: '票据状态',
    'min-width': 80
  },
  slot: 'ticketStatus'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
const w60 = 60;
// 签单行程
export const ticketTrip = [{
  attrs: {
    prop: 'type',
    label: '类型',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'ticket',
    label: '签单'
  },
  children: [{
    attrs: {
      prop: '',
      label: '通用签单'
    },
    children: [{
      attrs: {
        prop: 'countCommon',
        label: '数量',
        'min-width': w60
      }
    }, {
      attrs: {
        prop: 'unitCommon',
        label: '单位'
      }
    }]
  }, {
    attrs: {
      prop: '',
      label: '非通用签单'
    },
    children: [{
      attrs: {
        prop: 'count',
        label: '数量',
        'min-width': w60
      }
    }, {
      attrs: {
        prop: 'unit',
        label: '单位'
      }
    }]
  }]
}, {
  attrs: {
    prop: '',
    label: '现付',
    'min-width': 200
  },
  children: [{
    attrs: {
      prop: '',
      label: ''
    },
    children: [{
      attrs: {
        prop: 'countCash',
        label: '数量',
        'min-width': w60
      }
    }, {
      attrs: {
        prop: 'unitCash',
        label: '单位'
      }
    }]
  }]
}];

// 签单录入列表
export const ticketList = [{
  attrs: {
    prop: 'serialNumber',
    label: '序号',
    'min-width': 50
  },
  slot: 'serialNumber'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'name',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'signNumber',
    label: '号码'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 95
  },
  slot: 'action'
}];

// 签单审核列表
export const ticketAuditList = [{
  attrs: {
    prop: 'serialNumber',
    label: '序号',
    'min-width': 50
  },
  slot: 'serialNumber'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'name',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'signNumber',
    label: '号码'
  }
}, {
  attrs: {
    prop: 'spend',
    label: '已用',
    'min-width': 50
  },
  slot: 'spend'
}, {
  attrs: {
    prop: 'destroy',
    label: '自动释放',
    'min-width': 70
  },
  slot: 'destroy'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'turn',
    label: '转',
    'min-width': 50
  },
  slot: 'turn'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 95
  },
  slot: 'action'
}];