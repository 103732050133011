var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "notice-detail",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible,
      "has-foot": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "notice-detail__wrap"
  }, [_c("div", {
    staticClass: "notice-detail__content"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("strong", {
    staticStyle: {
      "font-size": "28px",
      "margin-bottom": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.title) + " ")])]), _c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("div", [_c("strong", [_vm._v("发布时间：")]), _c("span", [_vm._v(_vm._s(_vm.form.$createTime))])]), _c("div", {
    staticClass: "ml20"
  }, [_c("strong", [_vm._v("发布人：")]), _c("span", [_vm._v(_vm._s(_vm.form.createName))])])]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.form.content)
    }
  })], 1), _c("div", {
    attrs: {
      id: "mask"
    }
  })]), _c("el-row", {
    staticClass: "mt20",
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticClass: "tac",
    attrs: {
      span: 12
    }
  }, [!_vm.form.isRead ? _c("el-button", {
    attrs: {
      disabled: _vm.curSecond > 0,
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.setStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.curSecond > 0 ? _vm.curSecond + "秒后可以关闭窗口" : "确认已读") + " ")]) : _c("el-button", {
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 已读关闭 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };