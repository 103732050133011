import "core-js/modules/es.array.push.js";
import { planEditReceptionEdit } from '@/config/plan';
import { TopTitle, SelectDictionary } from '@/components/common';
import { auditMixin } from '@/mixin';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
const defaultState = () => ({
  count: 1,
  unit: 1,
  price: 0,
  times: 0,
  title: '',
  total: 0,
  type: '',
  typeId: '',
  remarks: ''
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  mixins: [auditMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    },
    checkAccountStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnData: [],
      type: 'customerFeeList',
      // 地接费用列表
      rowId: 1000000000000
    };
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planEditReceptionEdit : filterCustomColumn(planEditReceptionEdit);
  },
  methods: {
    // 新增一条
    handlePlus() {
      const item = {
        ...defaultState(),
        id: ++this.rowId,
        isCustom: true
      };
      this.tableData.push(item);
    },
    selectType(row, val) {
      const {
        id,
        label
      } = val;
      row.type = label;
      row.typeId = id;
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      const doDelete = () => {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      };
      this.$bus.open(() => {
        if (isCustom) {
          doDelete();
        } else {
          this.$store.dispatch('order/index/deleteFund', id).then(() => {
            doDelete();
          });
        }
      });
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price,
        times
      } = obj;
      let total = count * price * times;
      return total;
    },
    // 修改单行金额
    changeInput(item) {
      item.total = this.calcRowItem(item);
    },
    createOrUpdate(customerOrderId) {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请添加数据'
        });
        return Promise.reject();
      }
      if (!this.validateForm()) {
        return Promise.reject();
      } else {
        const copyData = this.tableData.map(it => {
          it.customerOrderId = customerOrderId;
          it.total = it.price * it.count * it.times;
          return it;
        });
        return this.$store.dispatch('order/index/createOrUpdateFund', copyData);
      }
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.price === undefined || row.price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '团款单价不能为空！'
          });
          return false;
        }
      }
      return true;
    }
  }
};