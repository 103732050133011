import { DetailFund, DetailRefund } from '@/components/common';
export default {
  name: 'DialogAuditDetail',
  components: {
    DetailFund,
    DetailRefund
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show(data) {
      this.form = data;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    }
  }
};