import request from '@/utils/request';

/********************** 导游批量报账 *************************/
// 导游批量报账列表
export const guideReimbBatch = params => {
  return request({
    url: '/order/ka/guideReimbCashes-multiple',
    method: 'get',
    params
  });
};

// 导游批量报账未结清列表
export const guideReimbBatchUnPay = params => {
  return request({
    url: '/order/ka/guidePlanReimb-multiple',
    method: 'get',
    params
  });
};

// 导游批量报账已结清列表
export const guideReimbBatchPayed = params => {
  return request({
    url: '/order/ka/pageGuideReimbComplete-multiple',
    method: 'get',
    params
  });
};

/***********************************************/

// 导游单团报账表 对账列表
export const toggleGuideCheck = params => {
  return request({
    url: '/order/ka/guideReimbKeepAcc',
    method: 'post',
    params
  });
};

// 导游单团报账表 列表
export const fetchGuide = params => {
  return request({
    url: '/order/ka/guidePlanReimbPage',
    method: 'get',
    params
  });
};

// 导游未结清列表
export const fetchUnPayGuide = params => {
  return request({
    url: '/order/ka/guidePlanReimb',
    method: 'get',
    params
  });
};

// 导游已结清列表
export const fetchPayedGuide = params => {
  return request({
    url: '/order/ka/pageGuideReimbComplete',
    method: 'get',
    params
  });
};

// 修复导游报账数据
export const fixflushGuideReimb = params => {
  return request({
    url: '/order/orderGuide/flushGuideReimb',
    method: 'get',
    params
  });
};