var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectData", _vm._g(_vm._b({
    attrs: {
      list: _vm.list,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      label: _vm.label,
      placeholder: "请选择司机"
    }
  }, "SelectData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };