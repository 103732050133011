var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-date-picker", {
    attrs: {
      placeholder: "开始日期",
      format: _vm.format,
      "value-format": _vm.format,
      type: _vm.type,
      "picker-options": _vm.shortcuts ? _vm.pickerOptions : {}
    },
    on: {
      change: _vm.onStarDate
    },
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  }), _c("span", {
    staticStyle: {
      padding: "0 1px",
      color: "#606266"
    }
  }), _c("el-date-picker", {
    attrs: {
      placeholder: "结束日期",
      format: _vm.format,
      "value-format": _vm.format,
      type: _vm.type,
      "picker-options": _vm.shortcuts ? _vm.pickerOptions : {}
    },
    on: {
      change: _vm.onEndDate
    },
    model: {
      value: _vm.endDate,
      callback: function ($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };