export default {
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    // 上传模板
    uploadTemplate(results) {
      const {
        file
      } = results;
      const formData = new FormData();
      formData.append('file', file);
      const loading = this.$bus.loading();
      this.$store.dispatch('system/customGoal/customerKpiTemplateImport', formData).then(() => {
        loading.close();
        this.$bus.tip();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    }
  }
};