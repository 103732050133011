var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("Search", {
    on: {
      add: _vm.showDialog,
      search: _vm.fetchData
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      "cell-class-name": _vm.tableCellClass
    },
    on: {
      page: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.startTripDate))]), _c("div", [_vm._v(_vm._s(row.endTripDate))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatPeopleValue(row)) + " ")];
      }
    }, {
      key: "shipTicket",
      fn: function ({
        row
      }) {
        return _vm._l(_vm.getShipInfo(row.shipTicket), function (item, index) {
          return _c("div", {
            key: index
          }, [_c("div", [_vm._v(_vm._s(item.shipDate) + " " + _vm._s(item.value))]), _c("div", [_vm._v(_vm._s(item.remarks))])]);
        });
      }
    }, {
      key: "createTime",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v("首次：" + _vm._s(row.createTime))]), row.updateTime ? _c("div", [_vm._v("更新：" + _vm._s(row.updateTime))]) : _vm._e()];
      }
    }, {
      key: "status",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.getBookingStatus(row.bookingStatus)) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [!_vm.isCanceled(row) ? [_vm.canModify(row) ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" 更改 ")]) : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleCopy(row);
            }
          }
        }, [_vm._v(" 复制 ")]), _c("BtnLink", {
          attrs: {
            type: "info"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(row);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleCancel(row);
            }
          }
        }, [_vm._v(" 取消 ")])] : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleHistory(row);
            }
          }
        }, [_vm._v(" 历史 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleEditName(row);
            }
          }
        }, [_vm._v(" 编辑名单 ")]), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handleBoatName(row);
            }
          }
        }, [_vm._v(" 船票名单表 ")])];
      }
    }])
  }), _c("DialogAdd", {
    ref: "dialogAddRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogEdit", {
    ref: "dialogEditRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogCopy", {
    ref: "dialogCopyRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogHistory", {
    ref: "dialogHistoryRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogName", {
    ref: "dialogNameRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogEditName", {
    ref: "dialogEditNameRef",
    on: {
      reload: _vm.fetchData
    }
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };