import store from '@/store';

// 系统配置
export const hasSystemConfigData = () => {
  if (!store.state['system/systemConf'].configData) {
    store.dispatch('system/systemConf/fetchType');
  }
};

// 角色
export const hasRoleSelectData = () => {
  if (!store.state['system/role'].roleData) {
    store.dispatch('system/role/fetch');
  }
};

// 员工
export const hasStaffSelectData = () => {
  if (!store.state['system/staff'].staffData) {
    store.dispatch('system/staff/fetch');
  }
};

// 会计科目
export const hasSubjectSelectData = () => {
  if (!store.state['system/accounting'].subjectData) {
    store.dispatch('system/accounting/fetchListLevel');
  }
};

// 账户
export const hasAccountSelectData = () => {
  if (!store.state['resource/account'].accountData) {
    store.dispatch('resource/account/fetchList');
  }
};

// 导游
export const hasGuideSelectData = () => {
  if (!store.state['resource/guide'].guideData) {
    store.dispatch('resource/guide/fetchList');
  }
};

// 景区
export const hasScenicSelectData = () => {
  if (!store.state['resource/scenic'].scenicData) {
    store.dispatch('resource/scenic/fetchList');
  }
};

// 车队
export const hasFleetSelectData = () => {
  if (!store.state['resource/fleet'].fleetData) {
    store.dispatch('resource/fleet/fetchList');
  }
};

// 酒店
export const hasHotelSelectData = () => {
  if (!store.state['resource/hotel'].hotelData) {
    store.dispatch('resource/hotel/fetchList');
  }
};

// 餐厅
export const hasRestaurantSelectData = () => {
  if (!store.state['resource/restaurant'].restaurantData) {
    store.dispatch('resource/restaurant/fetchList');
  }
};

// 大交通
export const hasTrafficSelectData = () => {
  if (!store.state['resource/traffic'].trafficData) {
    store.dispatch('resource/traffic/fetchList');
  }
};

// 大交通
export const hasInsuranceSelectData = () => {
  if (!store.state['resource/insurance'].insuranceData) {
    store.dispatch('resource/insurance/fetchList');
  }
};

// 购物店
export const hasShopSelectData = () => {
  if (!store.state['resource/shopping'].shoppingData) {
    store.dispatch('resource/shopping/fetchList');
  }
};

// 客户
export const hasCustomSelectData = () => {
  if (!store.state['resource/custom'].customData) {
    store.dispatch('resource/custom/fetchList');
  }
};