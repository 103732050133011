var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      "init-val": _vm.form.shuttleType,
      type: "taskType",
      label: "接送类型"
    },
    model: {
      value: _vm.form.shuttleType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shuttleType", $$v);
      },
      expression: "form.shuttleType"
    }
  }), _c("InputDate", {
    attrs: {
      label: "日期",
      "value-format": "yyyy-MM-dd",
      placeholder: "选择日期"
    },
    model: {
      value: _vm.form.shuttleDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shuttleDate", $$v);
      },
      expression: "form.shuttleDate"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputNumber", {
    attrs: {
      label: "大人数",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.adultCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adultCount", $$v);
      },
      expression: "form.adultCount"
    }
  }), _c("InputNumber", {
    attrs: {
      label: "小孩数",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.childCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "childCount", $$v);
      },
      expression: "form.childCount"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.form.shuttleType !== "city" ? _c("SelectBase", {
    attrs: {
      "init-val": _vm.form.taskType,
      type: "targetType",
      label: "地点类型"
    },
    on: {
      change: _vm.selectTarget
    },
    model: {
      value: _vm.form.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "taskType", $$v);
      },
      expression: "form.taskType"
    }
  }) : _c("SelectBase", {
    attrs: {
      "init-val": _vm.form.taskType,
      type: "taskCityType",
      label: "中转类型"
    },
    on: {
      change: _vm.selectCityType
    },
    model: {
      value: _vm.form.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "taskType", $$v);
      },
      expression: "form.taskType"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "stationType",
      label: "站点"
    },
    on: {
      "on-select": _vm.selectPosition
    },
    model: {
      value: _vm.form.desPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desPosition", $$v);
      },
      expression: "form.desPosition"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.form.shuttleType !== "city" ? _c("div", {
    staticClass: "input-group flex"
  }, [_c("InputBase", {
    attrs: {
      label: _vm.form.stationType == "train" ? "火车" : "航班"
    },
    model: {
      value: _vm.form.shiftNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shiftNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.shiftNumber"
    }
  }), _c("div", {
    staticClass: "btn-box",
    staticStyle: {
      "margin-top": "22px",
      "margin-right": "10px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.fetchTrafficTime
    }
  }, [_vm._v(" 获取时间 ")])], 1)], 1) : _c("InputBase", {
    attrs: {
      label: _vm.targetType == "end_trip_back" ? "上车点" : "下车点"
    },
    model: {
      value: _vm.form.togetherPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "togetherPosition", $$v);
      },
      expression: "form.togetherPosition"
    }
  }), _c("InputDate", {
    staticClass: "order-table-pickup",
    attrs: {
      label: _vm.getTargetLabel,
      type: "datetime",
      format: _vm.formatTime
    },
    on: {
      change: _vm.changeStationDateTime
    },
    model: {
      value: _vm.form.shiftDateTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shiftDateTime", $$v);
      },
      expression: "form.shiftDateTime"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-group flex"
  }, [_c("InputBase", {
    attrs: {
      label: "酒店名称"
    },
    model: {
      value: _vm.form.hotelName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelName", $$v);
      },
      expression: "form.hotelName"
    }
  })], 1), _c("InputBase", {
    attrs: {
      label: "酒店地址"
    },
    model: {
      value: _vm.form.hotelAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelAddress", $$v);
      },
      expression: "form.hotelAddress"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };