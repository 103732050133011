import { offerHistory } from '@/config/offer';
import offerMixin from '../offerMixin';
export default {
  mixins: [offerMixin],
  data() {
    return {
      tableData: [],
      visible: false,
      columnData: offerHistory,
      codeText: ''
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    fetchOfferCode(opts) {
      opts = {
        ...opts,
        pageSize: 999
      };
      this.$store.dispatch('offer/index/fetchOfferCode', opts).then(data => {
        this.tableData = data.list;
        this.show();
      });
    },
    handleShare(row) {
      const {
        id
      } = row;
      this.$parent.handleShare({
        id
      });
    },
    handleSharaCopy(row, event) {
      const {
        id
      } = row;
      this.fetchOfferId({
        id
      }).then(data => {
        this.codeText = this.formatOfferCode(data, event);
      });
    }
  }
};