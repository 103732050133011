var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", _vm._g(_vm._b({
    attrs: {
      placement: _vm.placement,
      title: _vm.title,
      content: _vm.content,
      width: _vm.width,
      trigger: _vm.trigger
    }
  }, "el-popover", _vm.$attrs, false), _vm.$listeners), [_c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };