var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    ref: "tableRef",
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "row-class-name": _vm.tableRowClassName,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "hide-bar": true,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planCustomerNumber ? scope.row.planCustomerNumber : "--") + " ")]), _c("br"), _c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_c("span", {
          staticStyle: {
            color: "#333"
          }
        }, [_vm._v(_vm._s(scope.row.customerTripNumber ? scope.row.customerTripNumber : "--"))])])];
      }
    }, {
      key: "orderDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate ? scope.row.startTripDate : "--") + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate ? scope.row.endTripDate : "--") + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [scope.row.members.length ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 3
          }
        })] : [_vm._v(" -- ")]];
      }
    }, {
      key: "status",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.reviewerStatus[scope.row.reviewerStatus])), _c("br"), _vm._v(_vm._s(_vm.progressName(scope.row)) + " ")];
      }
    }, {
      key: "applyAudits",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.applyAudits, function (item, index) {
          return _c("p", {
            key: index
          }, [_vm._v(" " + _vm._s(scope.row.applyUserName) + _vm._s(_vm.formatAudits(item)) + " ")]);
        });
      }
    }, {
      key: "actionHead",
      fn: function () {
        return [_vm.allListVerify ? _c("div", [_c("el-checkbox", {
          on: {
            change: _vm.changeCheckAll
          },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v;
            },
            expression: "checkAll"
          }
        }), _c("span", {
          staticClass: "pl5"
        }), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.changeCheckAll(!_vm.checkAll);
            }
          }
        }, [_vm._v(" 全选 ")])], 1) : _c("div", [_vm._v("操作")])];
      },
      proxy: true
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRate(scope.row);
            }
          }
        }, [_vm._v(" 进度 ")]), _vm.orderStatusBtn(scope) ? _c("div", [_c("span", {
          staticClass: "pl5"
        }), _c("el-checkbox", {
          model: {
            value: scope.row.ckStatus,
            callback: function ($$v) {
              _vm.$set(scope.row, "ckStatus", $$v);
            },
            expression: "scope.row.ckStatus"
          }
        }), _c("span", {
          staticClass: "pl5"
        }), _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleAudit(scope);
            }
          }
        }, [_vm._v(" 审核 ")])], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };