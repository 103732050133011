import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { OSS } from '@/config';
import { getUserData } from './cookie';
export const toString = val => Object.prototype.toString.call(val);
export function setSearchCache(store, route, search) {
  const path = route.path;
  store.dispatch('tagsView/appendVisiteViewSearch', {
    path,
    search
  });
}

// 删除对象中指定字段的key
export const removeObjKeys = (obj, keys) => {
  if (toString(obj) == '[object Object]') {
    keys = Object.keys(keys).map(key => key);
  }
  return Object.keys(obj).reduce((acc, key) => {
    if (!keys.includes(key)) {
      acc[key] = obj[key];
      return acc;
    }
    return acc;
  }, {});
};

// 从登录数据中获取用户所在部门是哪一级
export const getDepartmentLevel = (data = getUserData()) => {
  const DP_KEYS = {
    departmentId: 1,
    subDepartmentId: 2,
    grandsonDepartmentId: 3
  };
  return Object.keys(DP_KEYS).reduce((res, key) => {
    if (data[key] && data[key] >= 0) {
      res['departLevel'] = DP_KEYS[key];
      res['dpId'] = data[key];
    }
    return res;
  }, {});
};
export const hasOwn = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);
export const wait = (fn, time = 2000) => new Promise(resolve => {
  setTimeout(() => {
    resolve(fn);
  }, time);
});

// 请求状态
export const getStatus = res => !!res.status && res.data && Object.is(res.data.code, 200);
export const pipeAsyncFunctions = (...fns) => arg => fns.reduce((p, f) => p.then(f), Promise.resolve(arg));

// 是否交回
export const isBackMoney = feeType => {
  return feeType == '交回';
};

// 获取OSS地址的名字
export const getOssName = path => {
  path = decodeURIComponent(path);
  const [, suffic] = path.split(OSS.prefix);
  const [name] = suffic.split('?');
  return name;
};

// 根据资源路径获取类型
export const getFileSuffix = (path, name = '发票') => {
  const arr = path.split(OSS.prefix)[1].split('.');
  const suffix = arr[arr.length - 1];
  return `${name}.${suffix}`;
};

// 系统配置权限获取对应的值
export const pickSystemConfig = (str, data) => {
  const [parent, child, grandson] = str.split(':');
  const findListByName = (str, data) => data.find(it => it.code === str);
  const layer1 = findListByName(parent, data);
  const layer2 = findListByName(child, layer1.configs);
  const current = findListByName(grandson, layer2.values);
  return current ? current.value : false;
};

// 找到指定组件
export const findComponentsUpward = (ctx, compName) => {
  let parents = [];
  const parent = ctx.$parent;
  if (parent) {
    if (parent.$options.name === compName) parents.push(parent);
    return parents.concat(findComponentsUpward(parent, compName));
  } else {
    return [];
  }
};
export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)));
export const getURLParameters = url => (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((a, v) => (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1), a), {});

// 判断小数
export const isDecimal = value => /^[-]?\d+\.\d+$/g.test(value);

/**
 * 小数点截取
 * @param {string} val 要修改的值
 * @param {*} len 保留小数点长度
 * @returns 截取长度小数的
 */
export const decimalSplit = (val, len = 2) => {
  if (!val) return val;
  if (isDecimal(val)) {
    const [, suffix] = val.toString().split('.');
    return suffix.length > len ? Number(val).toFixed(len) : Number(val);
  }
  return +val;
};

// 处理对象的数字
export const processDecimal = obj => {
  Object.keys(obj).map(key => {
    if (key.toLowerCase().indexOf('id') > -1) return;
    const val = obj[key];
    if (typeof val == 'number') {
      obj[key] = decimalSplit(val);
    }
  });
  return obj;
};
export function getScrollbarWidth() {
  let odiv = document.createElement('div');
  const styles = {
    width: '100px',
    height: '100px',
    overflowY: 'scroll'
  };
  let i, scrollbarWidth;
  for (i in styles) odiv.style[i] = styles[i];
  document.body.appendChild(odiv); //把div添加到body中
  scrollbarWidth = odiv.offsetWidth - odiv.clientWidth; //相减
  odiv.remove(); //移除创建的div
  return scrollbarWidth; //返回滚动条宽度
}
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;
  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return (...args) => {
    // let ctx = this
    // let args = arguments
    if (!inThrottle) {
      fn(args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn(args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};
export function getStyle(obj, attr) {
  return obj.currentStyle ? obj.currentStyle[attr] : getComputedStyle(obj, false)[attr];
}
const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
export const isMobile = () => detectDeviceType() == 'Mobile';
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
export function createUniqueString() {
  const timestamp = +new Date() + '';
  const randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function capitalize(str, lowerRest = false) {
  return str.slice(0, 1).toUpperCase() + lowerRest ? str.slice(1).toUpperCase() : str.slice(1);
}
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
export const pickerDataByObj = ({
  data,
  obj
}) => {
  const keys = Array.isArray(obj) ? obj : Object.keys(obj);
  return keys.reduce((acc, key) => {
    acc[key] = data[key];
    return acc;
  }, {});
};
window._deepClone = deepClone;