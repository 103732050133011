import guideMixin from './guide';
const columnData = [{
  label: '名称',
  prop: 'name1'
}, {
  label: '小团号',
  prop: 'tripNumber'
}, {
  label: '类型',
  prop: 'restaurantType',
  width: '60px'
}, {
  label: '类别',
  prop: 'mealType',
  width: '50px'
}, {
  label: '日期',
  prop: 'orderDate',
  width: '80px'
}, {
  label: '单价',
  prop: 'price',
  width: '40px'
}, {
  label: '数量',
  prop: 'count',
  width: '40px'
}, {
  label: '免去',
  prop: 'freeCount',
  width: '40px'
}, {
  label: '其他',
  prop: 'otherFee',
  width: '40px'
}, {
  label: '金额',
  prop: 'reimbMoney',
  width: '60px',
  split: true
}, {
  label: '备注',
  prop: 'remarks',
  width: '70px',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};