var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "guide-captain-dialog",
    attrs: {
      width: "760px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("FormBase", {
    attrs: {
      "label-width": "40px",
      align: "left"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectGuideCaptain", {
    attrs: {
      label: "组长",
      clearable: ""
    },
    on: {
      "on-select": _vm.selectLeader
    },
    model: {
      value: _vm.guideName,
      callback: function ($$v) {
        _vm.guideName = $$v;
      },
      expression: "guideName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "组名字",
      clearable: ""
    },
    model: {
      value: _vm.groupName,
      callback: function ($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  })], 1)]), _c("div", {
    staticClass: "container"
  }, [_c("strong", {
    staticClass: "title"
  }, [_vm._v("可加入分组导游")]), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectGuide", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectGuide
    },
    model: {
      value: _vm.guide,
      callback: function ($$v) {
        _vm.guide = $$v;
      },
      expression: "guide"
    }
  }), _c("div", {
    staticClass: "btn-box"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 添加 ")])], 1)], 1), _c("strong", {
    staticClass: "title"
  }, [_vm._v("已加入分组导游")]), _c("div", {
    staticClass: "join-list"
  }, [_vm.joinList.length ? _vm._l(_vm.joinList, function (tag) {
    return _c("el-tag", {
      key: tag.name,
      attrs: {
        closable: "",
        type: "primary",
        size: "medium"
      },
      on: {
        close: function ($event) {
          return _vm.handleRemove(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }) : _c("Empty")], 2)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };