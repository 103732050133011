const orderInfo = {
  attrs: {
    prop: 'planInfo',
    label: '订单信息',
    'min-width': 120
  },
  slot: 'planInfo'
};
const type = {
  attrs: {
    prop: 'type',
    label: '类型'
  }
};
const orderTotalPay = {
  attrs: {
    prop: 'total',
    label: '应付'
  }
};
const payed = {
  attrs: {
    prop: 'totaled',
    label: '已付'
  }
};
const unPay = {
  attrs: {
    prop: 'unPay',
    label: '欠付'
  }
};
const createTime = {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
};
const auditStatus = {
  attrs: {
    prop: 'auditProgress',
    label: '审核状态'
  }
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
const lastItem = [orderTotalPay, payed, unPay, createTime, auditStatus, actionItem];

// 按团号去付款 客户返款
export const CustomerColumn = [orderInfo, {
  attrs: {
    prop: 'productName',
    label: '产品线路'
  }
}, {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 100
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'members',
    label: '游客联系人',
    slot: 'members'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, type, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}, ...lastItem];