import { tableMixin, getSummariesMixin } from '@/mixin';
import { orderTypeLabel } from '@/config/order';
export default {
  mixins: [tableMixin, getSummariesMixin],
  data: () => ({
    orderTypeLabel,
    sumsIndex: 9,
    avgeageKey: [10, 11, 12],
    // 计算人均
    statisticsKeys: ['plans', 'unIncome', 'adultCount', 'totalHeadRebate', 'totalBuyMoney', 'shopPerCapita', 'totalShopRebate']
  }),
  methods: {
    changeShopCellStyle(row) {
      if (row.column.label === '总购物金额' || row.column.label === '总购物人均') {
        return 'cell-blue'; //修改的样式
      }
      if (row.column.label === '剩余团款' || row.column.label === '公司收益') {
        return 'cell-green';
      }
      return '';
    }
  }
};