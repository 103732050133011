var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("TripNumber", {
          attrs: {
            id: scope.row.id,
            "need-id": false,
            type: "otherIncome"
          }
        })];
      }
    }, {
      key: "content",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.title) + " ")];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.count) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }, {
      key: "collectType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.cashType) + " ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };