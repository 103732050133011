import TableList from './TableList';
import FormRestaurant from './FormRestaurant';
import DialogAgreementItem2 from './DialogAgreementItem2';
import { BtnCreate, DialogDetail, DialogAgreement, DialogLog, DialogBindBusiness, SelectDictionary } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceRestaurant, TYPES } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  name: 'Resource',
  components: {
    TableList,
    BtnCreate,
    DialogLog,
    DialogBindBusiness,
    DialogDetail,
    FormRestaurant,
    DialogAgreement,
    DialogAgreementItem2,
    SelectDictionary
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceRestaurant,
    tableData: [],
    total: 0,
    searchParams: {
      name: ''
    },
    resourceId: '',
    resType: TYPES.restaurant
  }),
  computed: {
    restaurantData() {
      return this.$store.getters['resource/restaurant/restaurantDataOrigin'];
    }
  },
  watch: {
    restaurantData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('resource/restaurant/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/restaurant/fetchList');
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/restaurant/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteItem(id) {
      this.$store.dispatch('resource/restaurant/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    onSelectArea(val) {
      this.searchParams.area = val.label;
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      const opts = {
        resourceId: scope.row.id,
        resourceType: TYPES.restaurant
      };
      this.$store.dispatch('resource/common/fetchImage', opts);
      this.$refs.detailRef.show(scope.row, {
        title: '餐厅',
        name: '餐厅名称'
      });
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/restaurant/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    showAgreement(scope) {
      const resourceId = scope.row.id;
      this.resourceId = resourceId;
      this.fetchAgreement();
      this.$refs.agreementRef.show(scope.row, TYPES.restaurant);
    },
    fetchAgreement() {
      this.$store.dispatch('resource/restaurant/fetchAgreement', this.resourceId);
    },
    showAgreementItem(contracId) {
      this.$refs.agreementItemRef.show(contracId, TYPES.restaurant);
    }
  }
};