import { mapGetters, mapState } from 'vuex';
import { constantRoutes, asyncRoutes } from '@/router';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem
  },
  data() {
    this.onlyOneChild = null;
    return {
      basePath: '',
      activeIndex: '1',
      currentRoutes: null
    };
  },
  computed: {
    ...mapState({
      device: state => state.app.device
    }),
    ...mapGetters({
      sidebar: 'app/sidebar',
      routes: 'permission/routes'
    }),
    isMobile() {
      return this.device === 'mobile';
    },
    getRoutes() {
      return [...constantRoutes, ...asyncRoutes];
    },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        let {
          name
        } = route;
        name = name === 'dashboard' ? 'home' : name;
        if (this.isMobile) {
          return this.routes;
        }

        // 切换字体大小错误问题
        if (!name) return;
        this.getRoutes.forEach(r => {
          if (name.indexOf(r.name) > -1) {
            this.currentRoutes = r;
          }
        });
      },
      immediate: true
    }
  }
};