export const AdvanceColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'totalPreIncome',
    label: '预收余额'
  }
}, {
  attrs: {
    prop: 'totalPrePay',
    label: '预付余额'
  }
}, {
  attrs: {
    prop: 'logNumber',
    label: '日记号',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashTypeLabel',
    label: '现金日记类型'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '销账人'
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行卡',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'cashActionLabel',
    label: '费用类型'
  }
}, {
  attrs: {
    prop: 'cashModeLabel',
    label: '模式'
  }
}, {
  attrs: {
    prop: 'cash',
    label: '下账金额'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: '',
    label: '操作'
  },
  slot: 'action'
}];