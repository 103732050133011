// 资源列表
export const GroupResource = [{
  attrs: {
    prop: 'name',
    label: '公司名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  }
}, {
  attrs: {
    prop: 'incomeBlance',
    label: '预收余额'
  }
}, {
  attrs: {
    prop: 'payBlance',
    label: '预付余额'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'code',
    label: '编码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态'
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];

// 对账列表
export const GroupCheckBill = [{
  attrs: {
    prop: 'planNumber',
    label: '订单信息'
  }
}, {
  attrs: {
    prop: '',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  slot: 'people'
}, {
  attrs: {
    prop: 'kpCompanyName',
    label: '集团'
  }
}, {
  attrs: {
    prop: 'resTypeLabel',
    label: '订单类型'
  }
}, {
  attrs: {
    prop: 'resName',
    label: '资源名称',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '',
    label: '订单日期'
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: '',
    label: '明细'
  },
  slot: 'detail'
}, {
  attrs: {
    prop: 'payType',
    label: '收支类型'
  }
}, {
  attrs: {
    prop: 'totalMoney',
    label: '账面金额'
  }
}, {
  attrs: {
    prop: 'tsMoney',
    label: '实际金额'
  }
}, {
  attrs: {
    prop: '',
    label: '对账金额'
  },
  slot: 'checkMoney'
}, {
  attrs: {
    prop: '',
    label: '备注'
  },
  slot: 'remark'
},
// { attrs: { prop: '', label: '审核状态' }, slot: 'auditStatus' },
{
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
}];

// 未结清数据
export const GroupDownBillUnPay = [{
  attrs: {
    prop: 'planNumber',
    label: '订单信息'
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: '',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '',
    label: '人数'
  },
  slot: 'people'
}, {
  attrs: {
    prop: 'kpCompanyName',
    label: '集团'
  }
}, {
  attrs: {
    prop: 'resTypeLabel',
    label: '订单类型'
  }
}, {
  attrs: {
    prop: 'resName',
    label: '资源名称',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '',
    label: '订单日期'
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: '',
    label: '明细'
  },
  slot: 'detail'
}, {
  attrs: {
    prop: 'payType',
    label: '收支类型'
  }
}, {
  attrs: {
    prop: 'tsMoney',
    label: '应付'
  }
}, {
  attrs: {
    prop: '',
    label: '已付'
  },
  children: [{
    attrs: {
      prop: 'guidePay',
      label: '导游现付'
    }
  }, {
    attrs: {
      prop: 'payedOrIncomed',
      label: '社付'
    }
  }]
}, {
  attrs: {
    prop: 'owePay',
    label: '欠付'
  }
}, {
  attrs: {
    prop: '',
    label: '本次下账'
  },
  slot: 'downPayed'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
}];

// 销账列表
export const GroupDownBill = [{
  attrs: {
    prop: 'resourceName',
    label: '集团公司'
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '付款日期'
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账号',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'cash',
    label: '销账金额'
  }
}, {
  attrs: {
    prop: 'balance',
    label: '余额'
  }
}, {
  attrs: {
    prop: 'invoiceMoney',
    label: '发票金额'
  }
}, {
  attrs: {
    prop: 'invoiceNumber',
    label: '发票编码'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '付款人'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right'
  },
  slot: 'action'
}];