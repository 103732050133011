var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "游客游玩详细信息",
      width: "1080px",
      "has-foot": false,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate) + " "), _c("br"), _vm._v(_vm._s(scope.row.endTripDate) + " ")];
      }
    }, {
      key: "desProvince",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.desProvince ? scope.row.desProvince : "桂林") + " ")];
      }
    }, {
      key: "scenic",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.scenic))
          }
        })];
      }
    }, {
      key: "shops",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.shops))
          }
        })];
      }
    }, {
      key: "consume",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.consume))
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("el-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("div", {
          attrs: {
            slot: "content"
          },
          slot: "content"
        }, [_vm._v(" " + _vm._s(scope.row.remarks) + " ")]), _c("p", {
          staticClass: "ellipsis-2"
        }, [_vm._v(" " + _vm._s(scope.row.remarks) + " ")])])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };