// 发团预定安排 导游

import request from '@/utils/request';

// 删除导游借款申请
export function powerDeleteApprove(data) {
  return request({
    url: '/order/guideBorrow/delApplies-supper',
    method: 'post',
    data
  });
}

// 获取计划的导游借款申请(批准记录)
export function fetchPlanBorrowApprove(params) {
  return request({
    url: '/order/guideBorrow/findList',
    method: 'get',
    params
  });
}

// 查询计划的导游借款申请记录
export function fetchPlanBorrow(params) {
  return request({
    url: '/order/guideBorrow/listApplies',
    method: 'get',
    params
  });
}

// 查询导游借款申请记录
export function fetchBorrow(params) {
  return request({
    url: '/order/guideBorrow/pageApplies',
    method: 'get',
    params
  });
}

// 查询申请记录的批准记录
export const guideApproveRecord = params => {
  return request({
    url: '/order/guideBorrow/findList-applyId',
    method: 'get',
    params
  });
};

// ----------------------------------------------- //

// 借款申请 删除
export function delBorrowApply(data) {
  return request({
    url: '/order/guideBorrow/delApplies',
    method: 'post',
    data
  });
}

// 借款申请 删除(企业码付的)
export function delBorrowApplyByIds(data) {
  return request({
    url: '/order/guideBorrow/delAlipayApplyByIds',
    method: 'post',
    data
  });
}

// 删除导游借款
export function delBorrow(data) {
  return request({
    url: '/order/guideBorrow/del',
    method: 'post',
    data
  });
}
// ----------------------------------------------- //
// 批准下账借款申请
export function ratifyApply(data) {
  return request({
    url: '/order/guideBorrow/ratifyApplies',
    method: 'post',
    data
  });
}

// 导游借款 修改与新增（批准记录）
export function ratifySave(data) {
  return request({
    url: '/order/guideBorrow/save',
    method: 'post',
    data
  });
}

// 新增计划的导游借款申请
export function addPlanBorrow(data) {
  return request({
    url: '/order/guideBorrow/addApplies',
    method: 'post',
    data
  });
}

// 新增或修改 计划的导游借款申请
export function setPlanBorrow(data) {
  return request({
    url: '/order/guideBorrow/saveOrUpdateApplies',
    method: 'post',
    data
  });
}

// =============================================================== //

// 导游获取
export function fetchGuide(params) {
  return request({
    url: '/order/orderGuide/listAll',
    method: 'get',
    params
  });
}

// 导游更新
export function updateGuide(data) {
  return request({
    url: '/order/orderGuide/save',
    method: 'post',
    data
  });
}

// 导游删除
export function deleteGuide(ids) {
  return request({
    url: '/order/orderGuide/del',
    method: 'post',
    data: ids
  });
}

// 查询所有代收
export function fetchProxy(params) {
  return request({
    url: '/order/plan/guideCollectList',
    method: 'get',
    params
  });
}

// 代收安排
export function updateProxy(data) {
  return request({
    url: '/order/plan/updateGuideCollectType',
    method: 'post',
    data
  });
}

// 发起钉钉借款申请
export function requestDDApply(data) {
  return request({
    url: '/order/guideBorrow/to-dingtalk',
    method: 'post',
    data
  });
}

// 撤销借款申请
export function revokeDDApply(data) {
  return request({
    url: '/order/guideBorrow/cancel-dingtalk',
    method: 'post',
    data
  });
}

// 刷新借款申请
export function statusDDApply(data) {
  return request({
    url: '/order/guideBorrow/flush-dingtalk-status',
    method: 'post',
    data
  });
}