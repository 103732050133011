import { AuditRole } from '@/config/audit';
import { mapGetters } from 'vuex';

// 审核流程状态
export default {
  name: 'StatusAuditProgress',
  inheritAttrs: false,
  props: {
    row: {
      type: Object,
      required: true
    },
    isName: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    }),
    unAudited() {
      return AuditRole['wait'] == this.row.auditStatus || AuditRole['wait'] == this.row.auditProgress;
      // return AuditRole['wait'] == this.row.auditProgress
    },
    getRole() {
      if (this.row) {
        const {
          opAuditUserName,
          financialAuditUserName
        } = this.row;
        if (this.isName) {
          return financialAuditUserName ? `财务${financialAuditUserName}` : opAuditUserName ? `计调${opAuditUserName}` : '';
        }
        return financialAuditUserName ? '财务' : opAuditUserName ? '计调' : '';
      }
      return '';
    },
    getRoleColor() {
      if (this.row) {
        const {
          financialAuditUserName
        } = this.row;
        return financialAuditUserName ? 'success' : '';
      }
      return '';
    },
    getRoleName() {
      return this.row.financialAuditUserName || this.row.opAuditUserName || '';
    }
  }
};