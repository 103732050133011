import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
export default {
  mixins: [tableMixin, shopMixin],
  data: () => ({
    // avgeageKey: [4, 6, 5], // 计算人均
    avgeageMaps: [{
      hasChild: false,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: '',
      // 当hasChild为true时，key 为 对象字符串
      divisor: 'enterStoreMemberCount',
      // 人数字段
      dividend: 'totalBuyMoney',
      // 总数
      value: 'shopPerCapita' // 平均值
    } // 计算人均
    ],
    sumsIndex: 0
  }),
  methods: {
    changeShopCellStyle(row) {
      if (row.column.label === '总购物金额') {
        return 'cell-blue'; //修改的样式
      }
      if (row.column.label === '总购物人均') {
        return 'cell-green';
      }
      return '';
    }
  }
};