var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "receiptPrint"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "contact"
  }, [_c("ul", [_c("li", [_c("span", [_vm._v("To: ")]), _c("span", [_vm._v(_vm._s(_vm.data.inCustomerName))])]), _c("li", [_c("span", [_vm._v("收件人:")]), _c("span", [_vm._v(_vm._s(_vm.data.contactName))])]), _c("li", [_c("span", [_vm._v("手机:")]), _c("span", [_vm._v(_vm._s(_vm.data.contactMobileNumber))])])]), _c("ul", {
    staticStyle: {
      overflow: "hidden"
    }
  }, [_c("li", [_c("span", [_vm._v("Form: ")]), _c("span", [_vm._v(_vm._s(_vm.data.outCustomerName))])]), _c("li", [_c("span", [_vm._v("发件人:")]), _c("span", [_vm._v(" " + _vm._s(_vm.data.outOPUserInfo.realName) + " ")])]), _c("li", [_c("span", [_vm._v("手机:")]), _c("span", [_vm._v(" " + _vm._s(_vm.data.outOPUserInfo.mobilePhone) + " ")])]), _c("li", [_c("span", [_vm._v("电话:")]), _c("span", [_vm._v(" " + _vm._s(_vm.data.outOPUserInfo.telphone) + " ")])]), _c("li", [_c("span", [_vm._v("传真:")]), _c("span", [_vm._v(" " + _vm._s(_vm.data.outOPUserInfo.faxNumber) + " ")])]), _c("li", {
    staticStyle: {
      float: "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.parseTime(new Date())) + " ")])])]), _c("table", [_c("colgroup", _vm._l(24, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【基础信息】")]), _c("span", [_vm._v(_vm._s(_vm.data.planNumber || ""))])])]), _c("tr", [_vm._m(0), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "8"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.data.tripName) + " " + _vm._s(_vm.data.welcomeBorad) + " ")])]), _vm._m(1), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "8"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.data.accompanyInfo))])])]), _c("tr", [_vm._m(2), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "8"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.shuttles("PICK")))])]), _vm._m(3), _c("td", {
    staticClass: "content",
    attrs: {
      colspan: "8"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.shuttles("SEND")))])])]), _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    staticStyle: {
      overflow: "hidden"
    },
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【行程信息】")]), _c("span", {
    staticStyle: {
      "margin-left": "30px"
    }
  }, [_vm._v(" 产品:" + _vm._s(_vm.data.productName) + " ")])])]), _vm._m(4), _vm._l(_vm.data.itineraries, function (trip, index) {
    return _c("tr", {
      key: trip.id + "" + index,
      staticClass: "tac"
    }, [_c("td", {
      staticClass: "content",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(trip.itineraryDate))])]), _c("td", {
      staticClass: "content",
      attrs: {
        colspan: "14"
      }
    }, [_vm._v(" " + _vm._s(trip.itineraryDesc) + _vm._s(trip.selfScenic) + " ")]), _c("td", {
      staticClass: "content tac"
    }, [_c("strong", [_vm._v(_vm._s(trip.breakfast ? "√" : "×"))])]), _c("td", {
      staticClass: "content tac"
    }, [_c("strong", [_vm._v(_vm._s(trip.lunch ? "√" : "×"))])]), _c("td", {
      staticClass: "content tac"
    }, [_c("strong", [_vm._v(_vm._s(trip.dinner ? "√" : "×"))])]), _c("td", {
      staticClass: "content",
      attrs: {
        colspan: "3"
      }
    }, [_c("span", [_vm._v(_vm._s(_vm.removeHotelPhone(trip.hotel)))])])]);
  }), _vm._m(5), _vm._m(6), _vm._l(_vm.data.fees, function (fee) {
    return _c("tr", {
      key: fee.id
    }, [_c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.type.split("|")[0]))])]), _c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.remarks))])]), _c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.price))])]), _c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.count))])]), _c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.times))])]), _c("th", {
      staticClass: "label",
      attrs: {
        colspan: "4"
      }
    }, [_c("span", [_vm._v(_vm._s(fee.price * fee.count * fee.times))])])]);
  }), _c("tr", [_c("th", {
    staticClass: "tac",
    attrs: {
      colspan: "2",
      rowspan: "4"
    }
  }, [_vm._v("合计")]), _vm._m(7), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.data.feeTotal))])])]), _c("tr", [_vm._m(8), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.data.collectTotal))])])]), _c("tr", [_vm._m(9), _c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.calcUnCollect(_vm.data)) + " ")])])], 2), _c("tbody", [_vm._m(10), _vm._m(11), _vm._l(_vm.data.members, function (m, i) {
    return _c("tr", {
      key: i,
      staticClass: "tac"
    }, [_c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(m.name) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(m.mobilePhone) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.getCardType(m.cardType || 0)) + " ")]), _c("td", {
      attrs: {
        colspan: "6"
      }
    }, [_vm._v(" " + _vm._s(m.cardNumber) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(m.sex == 0 ? "男" : "女") + " ")]), _c("td", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(m.location) + " ")])]);
  })], 2)])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("strong", [_vm._v("游客姓名(接站牌)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("strong", [_vm._v("全陪")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("strong", [_vm._v("出发大交通")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("td", {
    staticClass: "label",
    attrs: {
      colspan: "4"
    }
  }, [_c("strong", [_vm._v("返回大交通")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "tac"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("日期")]), _c("th", {
    attrs: {
      colspan: "14"
    }
  }, [_vm._v("行程")]), _c("th", [_vm._v("早")]), _c("th", [_vm._v("午")]), _c("th", [_vm._v("晚")]), _c("th", {
    attrs: {
      colspan: "3"
    }
  }, [_vm._v("酒店")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【费用信息】")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("类型")]), _c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("项目内容")]), _c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("单价")]), _c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("数量")]), _c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("单位")]), _c("th", {
    staticClass: "content",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("金额")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("应收款")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("代收")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_c("span", [_vm._v("未收")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【游客信息】")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("姓名")]), _c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("手机号")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("证件类型")]), _c("th", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v("证件号")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("性别")]), _c("th", {
    attrs: {
      colspan: "8"
    }
  }, [_vm._v("地址")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };