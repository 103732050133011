import SearchRegiment from '../components/SearchRegiment';
import TableList from './components/TableList';
import { SelectFleet } from '@/components/common';
import { excelMixin, reloadMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
export default {
  components: {
    SelectFleet,
    SearchRegiment,
    TableList
  },
  mixins: [excelMixin, reloadMixin],
  data: () => ({
    resourceId: '',
    searchParams: {}
  }),
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billDown/payRegimentPickup/fetch', {
        resourceId: this.resourceId,
        shuttleType: TYPES.shuttle.toLowerCase(),
        ...params
      });
    },
    handleSearch(params) {
      this.searchParams = this.fetchData(params);
    },
    handlePage(params) {
      this.fetchData({
        ...this.searchParams,
        ...params
      });
    }
  }
};