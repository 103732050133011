import TableList from './components/TableList';
import DialogCreate from './components/DialogCreate';
import SearchCashDiray from './components/SearchCashDiray';
import { excelMixin, reloadMixin } from '@/mixin';
import { resourceAccountDetail as columnData } from '@/config/resource';
import { CASHLOGTYPE } from '@/config/billDown';
import { processAdd, processMinus } from '@/utils/number';
export default {
  components: {
    TableList,
    DialogCreate,
    SearchCashDiray
  },
  mixins: [excelMixin, reloadMixin],
  data() {
    return {
      cashLogTypeList: [{
        value: '',
        label: '全部'
      }, ...CASHLOGTYPE],
      tableData: [],
      columnData,
      total: 0,
      statistic: null,
      bankInfo: null,
      searchParams: {},
      filename: '现金日志流水',
      autoWidth: true,
      bookType: 'xlsx',
      excelLoading: false
    };
  },
  computed: {
    cashLogData() {
      return this.$store.getters['bill/billDown/cashLogData'];
    },
    totalStatices() {
      const data = this.cashLogData;
      if (!data || !data.totalCount) return;
      const initBalance = this.bankInfo ? this.bankInfo.initBalance : 0;
      const {
        reimbursedIncome,
        reimbursedPay
      } = data.statistics;
      // 结余=收款-付款
      const diff = Number(processMinus(reimbursedIncome, reimbursedPay)).toFixed(2);
      // 余额=系统里期初+结余
      const balance = Number(processAdd(initBalance, diff)).toFixed(2);
      return `下账金额合计模式：收款：${reimbursedIncome} 付款：${reimbursedPay} 结余：${diff} 余额：${balance}`;
    },
    totalBankInfo() {
      const data = this.bankInfo;
      if (!data) return;
      const {
        initBalance,
        totalPay,
        totalReceipts
      } = data;
      // 结余=收款-付款
      const diff = Number(processMinus(totalReceipts, totalPay)).toFixed(2);
      // 余额=系统里期初+结余
      const balance = Number(processAdd(initBalance, diff)).toFixed(2);
      return `银行账户余额统计：初期：${initBalance} 收款：${totalReceipts} 付款：${totalPay} 结余：${diff} 余额：${balance}`;
    }
  },
  watch: {
    cashLogData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.isBack) {
      this.fetchData();
    }
    this.fetchBankInfo();
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('bill/billDown/fetchCashPage', {
        ...this.searchParams,
        ...params
      });
    },
    fetchBankInfo(params = {}) {
      this.$store.dispatch('bill/billDown/fetchBankStatices', params).then(res => {
        if (!res || !res.data) return;
        this.bankInfo = res.data;
      });
    },
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
      this.fetchBankInfo(params);
    },
    handlePage(params) {
      this.fetchData({
        ...params,
        ...this.searchParams
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistic
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistic;
    },
    addCash() {
      this.$refs.dialogCreateRef.show();
    }
  }
};