import billBaseMixin from '../../billBaseMixin';
import { StatusAudit, BtnLink, CustomerInfo } from '@/components/common';
import { PersonCount, Members } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillReception, statisticsKeys } from '@/config/billCheck';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    PersonCount,
    Members,
    StatusAudit,
    BtnLink,
    CustomerInfo
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      headMergeNum: 5,
      tailMergeNum: checkBillReception.length - 2,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys,
      type: TYPES.out_fee,
      link: {
        detail: '/order/team-input-detail'
      }
    };
  },
  computed: {
    getDetail() {
      return item => {
        let {
          type,
          count,
          price,
          title,
          unit
        } = item;
        if (title) {
          type = `${type}(${title})`;
        }
        const total = count * price * unit;
        return `${type}: ${count} × ${price} × ${unit} = ${total}`;
      };
    }
  }
};