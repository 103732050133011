import { splitPath, getTextLength } from '@/utils';
export default {
  methods: {
    validate(type, val) {
      const value = val && typeof val === 'object' ? val.target.value : val;
      const rules = this.validateForm[type];
      if (!rules) return;
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (rule.required && !value) {
          this.$bus.tip({
            type: 'warning',
            message: rule.message,
            offset: window.innerHeight / 2
          });
          return false;
        }
        const valLen = getTextLength(value);
        if ((rule.min || rule.max) && (valLen < rule.min || valLen > rule.max)) {
          this.$bus.tip({
            type: 'warning',
            message: rule.message,
            offset: window.innerHeight / 2
          });
          return false;
        }
        if (rule.validate && value && !rule.validate(value)) {
          this.$bus.tip({
            type: 'warning',
            message: rule.message,
            offset: window.innerHeight / 2
          });
          return false;
        }
      }
      return true;
    },
    handleValidate() {
      const keys = Object.keys(this.validateForm);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const val = this.editSourceInfo ? this.editSourceInfo[key] : this.form[key];
        if (!this.validate(key, val)) {
          // 如果校验不通过，把弹窗loading状态关闭
          this.$store.dispatch('app/setDialogLoading', false);
          return false;
        }
      }
      return true;
    },
    mergeImage() {
      const merge = this.isUpdate ? [...this.imageList, ...this.imageListNew] : this.imageListNew;
      let imageListNewDel = [...this.imageListNewDel];

      // 如果新增添加图片后直接删除，先用缓存里面的图片匹配filter()删除缓存里的图片并用some()去除
      if (this.imageListNewDel.length) {
        return merge.filter(item => {
          return !imageListNewDel.some(ele => ele.path === item.path);
        }).map(it => {
          it.path = splitPath(it.path);
          return it.path;
        });
      } else {
        return merge.map(it => {
          it.path = splitPath(it.path);
          return it.path;
        });
      }
    }
  }
};