var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reserve-info"
  }, [_c("el-form", {
    ref: "ruleForm",
    staticClass: "form-wrapper",
    attrs: {
      model: _vm.form,
      rules: _vm.getRules,
      inline: "",
      "label-width": "80px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "大团号",
      prop: "planCustomerNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.form.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planCustomerNumber", $$v);
      },
      expression: "form.planCustomerNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小团号",
      prop: "customerTripNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.form.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerTripNumber", $$v);
      },
      expression: "form.customerTripNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "参团人数",
      prop: "adultCount"
    }
  }, [_c("InputPeopleCount", {
    staticClass: "input-field",
    attrs: {
      "show-label": false,
      adult: _vm.form.adultCount,
      child: _vm.form.childCount,
      accompany: _vm.form.accompanyCount
    },
    on: {
      "update:adult": function ($event) {
        return _vm.$set(_vm.form, "adultCount", $event);
      },
      "update:child": function ($event) {
        return _vm.$set(_vm.form, "childCount", $event);
      },
      "update:accompany": function ($event) {
        return _vm.$set(_vm.form, "accompanyCount", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "产品线路",
      prop: "productName"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.form.productName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productName", $$v);
      },
      expression: "form.productName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期",
      prop: "startTripDate"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.changeStartDate
    },
    model: {
      value: _vm.form.startTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startTripDate", $$v);
      },
      expression: "form.startTripDate"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "散团日期",
      prop: "endTripDate"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: ""
    },
    on: {
      change: _vm.changeEndDate
    },
    model: {
      value: _vm.form.endTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endTripDate", $$v);
      },
      expression: "form.endTripDate"
    }
  })], 1), _vm.isUpdate ? _c("FormItemBase", {
    attrs: {
      prop: "code",
      label: "预定中心"
    }
  }, [_c("InputResource", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1) : _vm._e(), _c("FormItemBase", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.saveOrUpdate
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };