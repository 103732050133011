import { OrderType } from '@/config/order';
import { getDaysBetween } from './date';
import { ORDER_TEAM_FEE_CONFIRM_KEY, SHUTTLE_DEFAULT_COST_KEY } from '@/config/systemConfig';
const getOrderType = data => {
  const {
    orderType
  } = data;
  return orderType;
};

// 团队单
export const isTeamType = data => {
  return getOrderType(data) == OrderType.team;
};
const processTeamFeeRule = (data, code) => {
  if (!data) return false;
  const findItem = data.find(it => it.code === code);
  if (!findItem || !+findItem.value1) {
    return false;
  }
  const {
    value
  } = findItem;
  return JSON.parse(value);
};

/**
 * 判断配置是否超出配置金额
 * @param {*} data 订单
 * @param {*} configs 订单团款配置
 * @returns Boolean
 */
export const validateTeamFee = (data, configData) => {
  if (!configData) return false;
  const {
    planCustomerNumber,
    customerTripNumber,
    startTripDate,
    endTripDate,
    adultCount,
    totalIncome
  } = data;

  // const { totalCollect, totalSubsidy } = data
  const days = getDaysBetween(startTripDate, endTripDate) || 1;
  const planNumber = planCustomerNumber || customerTripNumber;
  const configs = processTeamFeeRule(configData, ORDER_TEAM_FEE_CONFIRM_KEY);
  if (!configs) return false;
  configs.sort((a, b) => a.orderBy - b.orderBy);
  const temp = [...configs];
  let pass = false;
  while (temp.length) {
    const rule = temp.shift();
    const {
      keyword,
      price,
      isEnable
    } = rule;

    // 关键字可能有多个，规则为 | 分割
    const keywords = keyword.split('|');
    const matchesAll = keywords.every(key => planNumber.indexOf(key) > -1);

    // 【均价】*【行程天数】*【人数】> 【总团款】，则不允许成团
    const realTotal = price * adultCount * days;
    if (isEnable && matchesAll) {
      pass = realTotal > totalIncome;
      break;
    }
  }
  return pass;

  // const result = configs.some(config => {
  //   const { keyword, price, isEnable } = config

  //   // 关键字可能有多个，规则为 | 分割
  //   const keywords = keyword.split('|')

  //   const matchesAll = keywords.every(key => planNumber.indexOf(key) > -1)

  //   // 【均价】*【行程天数】*【人数】> 【总团款】，则不允许成团
  //   const realTotal = price * adultCount * days

  //   return isEnable && matchesAll && realTotal < totalIncome
  // })
  // return result || true
};

/**
 * 获取接送配置的车队
 */

export const getDefaultShuttleInfo = configData => {
  const config = processTeamFeeRule(configData, SHUTTLE_DEFAULT_COST_KEY);
  return config;
};