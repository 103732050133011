import { BtnMore, SelectProduct, SelectStaff, SelectGuide, SelectCustom, InputDepartment, DialogLog } from '@/components/common';
import TableSingle from './components/TableSingle';
import { auditOrder } from '@/config/audit';
import { mapGetters } from 'vuex';
import { customerInfoMixin, searchMixin } from '@/mixin';
import { beforeWeek, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { LOG_YPES } from '@/config/resource';
export default {
  components: {
    BtnMore,
    SelectProduct,
    SelectStaff,
    SelectGuide,
    SelectCustom,
    InputDepartment,
    DialogLog,
    TableSingle
  },
  mixins: [searchMixin, customerInfoMixin],
  data() {
    const lastTwoWeek = beforeWeek(2);
    return {
      visibleMore: false,
      tableData: [],
      columnData: [],
      searchParams: {},
      total: 0,
      statistics: null,
      sendDate: [lastTwoWeek],
      guideName: '',
      auditProgress: ''
    };
  },
  computed: {
    ...mapGetters({
      auditData: 'audit/index/auditData',
      isBack: 'audit/index/isBack'
    })
  },
  watch: {
    auditData: {
      handler: 'initData',
      immediate: true
    },
    isBack: {
      handler: function (val) {
        if (val) {
          this.fetchData({
            startTripDateS: this.sendDate[0],
            ...this.searchParams
          });
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? auditOrder : filterCustomColumn(auditOrder);
  },
  mounted() {
    // 从首页跳转进入
    const {
      query
    } = this.$route;
    if (query) {
      this.fetchData(query);
      const auditProgress = query['auditProgress'];
      this.auditProgress = auditProgress;
      this.searchParams.auditProgress = auditProgress;
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: this.sendDate[0]
        });
      } else {
        this.handleData(data);
      }
    },
    fetchData(params) {
      this.$store.dispatch('audit/index/fetch', {
        ...this.searchParams,
        ...params
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistics = statistics;
    },
    selectGuide(val) {
      this.searchParams.guideName = val ? val.label : '';
    },
    selectProduct(val) {
      this.searchParams.productName = val ? val.name : '';
      this.searchParams.productId = val ? val.value : '';
    },
    selectAuditProgress(val) {
      this.searchParams.auditProgress = val ? val.value : '';
    },
    changeStartTripDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.startTripDateS = startDate || '';
      this.searchParams.startTripDateE = endDate || '';
    },
    changeEndTripDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.endTripDateS = startDate || '';
      this.searchParams.endTripDateE = endDate || '';
    },
    // changeDepartment(val) {
    //   departKeys.forEach(key => {
    //     this.searchParams[key] = val[key]
    //   })

    //   departIdKeys.forEach(key => {
    //     if (this.extra.department[key]) {
    //       this.extra.department[key] = val[key]
    //     }
    //   })
    // },

    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    },
    showLog(row) {
      const types = `${LOG_YPES.plan},${LOG_YPES.audit}`;
      const {
        id: itemId
      } = row;
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId,
        types,
        subType: '',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};