var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.getContent ? _c("Tips", {
    attrs: {
      content: _vm.content
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.getContent) + "...")])]) : _c("span", [_vm._v(_vm._s(_vm.content))])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };