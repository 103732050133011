export const Columns = [{
  attrs: {
    prop: 'inquiryStatus',
    label: '状态'
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'date',
    label: '抵离日期/车次'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'useDays',
    label: '用车天数'
  }
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'busType',
    label: '车型'
  }
}, {
  attrs: {
    prop: 'groupType',
    label: '团型'
  }
}, {
  attrs: {
    prop: 'useCount',
    label: '用车数量'
  }
}, {
  attrs: {
    prop: 'busAge',
    label: '车龄'
  }
}, {
  attrs: {
    prop: 'useAsk',
    label: '用车要求'
  }
}, {
  attrs: {
    prop: 'driverAsk',
    label: '司机服务要求'
  }
}, {
  attrs: {
    prop: 'itinerary',
    label: '用车行程',
    'min-width': 300
  },
  slot: 'itinerary'
}, {
  attrs: {
    prop: '',
    label: '询价人/时间'
  },
  slot: 'inquiryUser'
}, {
  attrs: {
    prop: 'fee',
    label: '车辆报价(辆)'
  },
  slot: 'fee'
}, {
  attrs: {
    prop: '',
    label: '报价人/时间'
  },
  slot: 'quotationUser'
}, {
  attrs: {
    prop: 'responseTime',
    label: '响应时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
export const ColumnsHistory = Columns;