var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "修改团期",
      width: "960px",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      close: _vm.hide
    }
  }, [_c("div", [_c("div", {
    staticClass: "input-row"
  }, [_c("div", {
    staticClass: "input-box is-require",
    staticStyle: {
      "max-width": "560px !important"
    }
  }, [_c("LabelBase", {
    attrs: {
      label: "产品线路"
    }
  }), _c("el-input", {
    staticClass: "mr10",
    attrs: {
      placeholder: "请选择产品",
      clearable: "",
      "is-require": ""
    },
    on: {
      focus: _vm.showProductialog
    },
    model: {
      value: _vm.form.productName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productName", $$v);
      },
      expression: "form.productName"
    }
  }), !_vm.isMobile ? _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.handleNewProduct
    }
  }, [_vm._v(" 添加产品 ")]) : _vm._e(), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showProductialog
    }
  }, [_vm._v(" 选择 ")])], 1)]), _c("div", {
    staticClass: "input-row"
  }, [_c("InputDate", {
    attrs: {
      "is-require": "",
      label: "发团日期",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.changeStartDate
    },
    model: {
      value: _vm.form.startTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startTripDate", $$v);
      },
      expression: "form.startTripDate"
    }
  }), _c("InputDate", {
    attrs: {
      "is-require": "",
      "value-format": "yyyy-MM-dd",
      label: "散团日期"
    },
    on: {
      change: _vm.changeEndDate
    },
    model: {
      value: _vm.form.endTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endTripDate", $$v);
      },
      expression: "form.endTripDate"
    }
  })], 1)]), _vm.visible ? _c("TableItinerary", {
    ref: "tableItineraryRef",
    attrs: {
      "is-show-btn": false
    }
  }) : _vm._e(), _c("DialogProduct", {
    ref: "productRef",
    attrs: {
      "order-type": _vm.orderType
    },
    on: {
      "on-select": _vm.selectProduct
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };