import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { SelectGuideCaptain, SelectGuide } from '@/components/common';
export default {
  components: {
    SelectGuideCaptain,
    SelectGuide
  },
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {},
      joinList: [],
      // 已加入的组员
      guideName: '',
      groupName: '',
      // 组名字
      tempGuide: null,
      guide: '',
      removeList: []
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '编辑导游组' : '新增导游组';
    }
  },
  methods: {
    show() {
      this.isUpdate = false;
      this.visible = true;
      this.restore();
    },
    edit(scope) {
      const {
        row
      } = scope;
      const {
        id,
        name,
        guideName
      } = scope.row;
      this.guideName = guideName;
      this.groupName = name;
      this.isUpdate = true;
      this.visible = true;
      this.form = row;
      // 已加入组员列表
      this.fetchTeamDetail({
        id
      });
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    restore() {
      this.guide = '';
      this.guideName = '';
      this.groupName = '';
      this.joinList = [];
      this.removeList = [];
    },
    selectLeader(val) {
      this.form.teamGuideId = val ? val.id : '';
    },
    selectGuide(val) {
      this.tempGuide = val;
    },
    handleAdd() {
      if (!this.tempGuide) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择要添加的导游'
        });
        return;
      }
      const hasJoined = this.joinList.some(it => it.id == this.tempGuide.id);
      if (hasJoined) {
        this.$bus.tip({
          type: 'warning',
          message: '该导游已加入分组'
        });
        return;
      }
      this.joinList.push(this.tempGuide);
      this.tempGuide = null;
      this.guide = '';
    },
    // 缓存要删掉的导游
    handleRemove(tag) {
      this.joinList = this.joinList.filter(it => it.id !== tag.id);
      // 新增的有label 和 value,如果没有则是获取的
      if (!tag.lable) {
        // 缓存要删除的导游
        this.removeList.push(tag.id);
      }
    },
    // 获取组员列表
    fetchTeamDetail(opts) {
      this.$store.dispatch('resource/guideCaptain/fetchTeamDetail', opts).then(res => {
        let {
          guides
        } = res;
        this.joinList = guides;
      });
    },
    // 删除组员
    handleDelete(ids) {
      return this.$store.dispatch('resource/guideCaptain/removeGroupGuide', ids);
    },
    handleConfirm() {
      if (!this.guideName) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择组长！'
        });
        return;
      }
      if (!this.groupName) {
        this.$bus.tip({
          type: 'warning',
          message: '请输入组名！'
        });
        return;
      }
      const {
        id,
        teamGuideId
      } = this.form;
      const opts = {
        id,
        teamGuideId,
        name: this.groupName,
        ids: this.joinList.map(it => it.id)
      };
      this.$store.dispatch('resource/guideCaptain/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$bus.tip();
        this.hide();
      }).then(() => {
        // 删除导游
        if (this.removeList.length) {
          const uniqueList = [...new Set(this.removeList)];
          const addIds = this.joinList.map(it => it.id);
          const ids = uniqueList.filter(id => !addIds.includes(id));
          ids.length && this.handleDelete(ids);
        }
      });
    }
  }
};