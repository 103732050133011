import { tableMixin } from '@/mixin';
import { findComponentsUpward } from '@/utils';
export default {
  mixins: [tableMixin],
  data() {
    return {};
  },
  methods: {
    handleEdit(row) {
      const findComp = findComponentsUpward(this, 'OrderAudit')[0];
      findComp.editTableRow(row);
    }
  }
};