import DialogDetail from './DialogDetail';
import FormDriver from './FormDriver';
import TableList from './TableList';
import { BtnCreate, DialogAgreement, DialogLog } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceDriver } from '@/config/resource';
import { formatTableData } from '@/utils/data';
import { uploadBase } from '@/config/index';
const defaultState = {
  busCompanyId: '',
  mobilePhone: '',
  name: ''
};
export default {
  components: {
    BtnCreate,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    FormDriver,
    TableList
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    uploadBase: uploadBase,
    info: null,
    visible: false,
    tableLoading: false,
    columnData: resourceDriver,
    tableData: [],
    total: 0,
    searchParams: defaultState
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(queryParam = {}) {
      const busCompanyId = this.$route.query.id;
      this.tableData = [];
      this.tableLoading = true;
      this.$store.dispatch('resource/driver/fetch', {
        ...queryParam,
        busCompanyId
      }).then(data => {
        this.handleData(data);
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteItem(row) {
      const {
        id
      } = row;
      const {
        driversLicensPic,
        qualificationCertificatePic
      } = row;
      let files = [driversLicensPic, qualificationCertificatePic];
      files = files.filter(it => it != '');
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/driver/delete', id).then(() => {
        this.initData();
        this.deleteFile(files);
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    },
    deleteFile(files) {
      this.$store.dispatch('resource/driver/deleteFile', files);
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      this.$bus.open(() => {
        this.deleteItem(scope.row);
      });
    },
    editTableRow(scope) {
      const copy = {
        ...scope
      };
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/driver/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};