import "core-js/modules/es.array.push.js";
import { tableMixin, permissionMixin, summaryMixin } from '@/mixin';
// import { orderType as OrderType } from '@/config/order'
import { orderApplyAudits, orderUpdateStatus, updateStatusType, reviewerStatus, reviewerStatusType } from '@/config/order';
import { Members, PersonCount } from '@/components/common/index';
import { CustomerInfo } from '@/components/common/index';
import { mapGetters } from 'vuex';
export default {
  components: {
    Members,
    PersonCount,
    CustomerInfo
  },
  mixins: [tableMixin, summaryMixin, permissionMixin],
  data() {
    return {
      checkAll: false,
      statisticsKeys: ['number', 'totalIncome', 'totalCollect', 'totalSubsidy'],
      reviewerStatus,
      progresses: [],
      link: {
        detail: '/order/order-audit-detail'
      }
    };
  },
  computed: {
    ...mapGetters({
      auditListData: 'system/index/auditListData',
      userData: 'user/userData'
    }),
    allListVerify() {
      const allWait = this.tableData.filter(it => it.reviewerStatus == reviewerStatusType.wait);
      if (allWait.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    auditListData: {
      handler: function (data) {
        if (!data) {
          this.fetchAuditList();
          return;
        }
        if (data.length) {
          const items = data.map(it => {
            const item = it.audits.filter(i => i.code == 'OrderReview')[0];
            return item.progresses;
          });
          this.progresses = items[0];
        }
      },
      immediate: true
    }
  },
  methods: {
    // 全选
    changeCheckAll(val) {
      this.tableData.forEach(it => {
        if (it.reviewerStatus == reviewerStatusType.wait) {
          it.ckStatus = val;
        }
      });
      this.checkAll = val;
    },
    progressName(row) {
      const {
        reviewerStatus,
        progress
      } = row;
      if (reviewerStatus == reviewerStatusType.waitNew || reviewerStatus == reviewerStatusType.wait) {
        if (!this.progresses[progress]) return '';
        const {
          reviewerName
        } = this.progresses[progress];
        return `需${reviewerName}审核`;
      }
      return '';
    },
    fetchAuditList() {
      this.$store.dispatch('system/index/fetchAuditList', {});
    },
    // 判断审核按钮是否显示
    orderStatusBtn({
      row
    }) {
      const {
        reviewerStatus,
        progress
      } = row;
      if (reviewerStatus == reviewerStatusType.waitNew || reviewerStatus == reviewerStatusType.wait) {
        const {
          roleId,
          id
        } = this.userData;
        if (this.progresses[progress]) {
          const {
            reviewerType,
            reviewerId
          } = this.progresses[progress];
          if (reviewerType == 'ROLE' && roleId === reviewerId || reviewerType == 'USER' && reviewerId === id) {
            return true;
          }
        }
      }
      return false;
    },
    formatAudits(item) {
      const {
        type,
        updateType,
        updateDetails,
        newContent
      } = item;
      let details = [];
      const {
        count,
        price,
        total,
        times,
        type: newType,
        remarks,
        isGuideCollect
      } = newContent;
      let newCont = '';
      if (updateType == updateStatusType.add) {
        // 团款新增
        if (type == 'FEE') {
          const formula = `${newType}: ${price / 100} x ${count} x ${times || 1} = ${total / 100}`;
          const remark = remarks ? '备注:' + remarks : '';
          newCont = '(' + formula + remark + ')';
        }
        // 代收新增
        if (type == 'COLLECT') {
          const formula = `${newType}: ${total / 100} ${isGuideCollect ? '导游收' : '社收'}`;
          const remark = remarks ? '备注:' + remarks : '';
          newCont = '(' + formula + remark + ')';
        }
        // 返款新增
        if (type == 'SUBSIDY') {
          const formula = `${newType}: ${price / 100} x ${count} = ${total / 100}`;
          const remark = remarks ? '备注:' + remarks : '';
          newCont = '(' + formula + remark + ')';
        }
      } else {
        updateDetails.forEach(it => {
          let det;
          det = `(${it.name}: '${it.attr == 'total' || it.attr == 'price' ? it.oldVal / 100 : it.oldVal}'改为'${it.attr == 'total' || it.attr == 'price' ? it.newVal / 100 : it.newVal}')`;
          details.push(det);
        });
      }
      return orderUpdateStatus[updateType] + orderApplyAudits[type] + details + newCont;
    },
    // 进度
    handleRate(row) {
      this.$parent.progressShow(row);
    },
    // 查看
    handleShow(scope) {
      this.$parent.handleShow(scope);
    },
    // 审核
    handleAudit(scope) {
      this.$parent.affirmAudit(scope);
    },
    handleCommand(scope, command) {
      this[command](scope);
    },
    tableRowClassName({
      row
    }) {
      if (row.reviewerStatus && (row.reviewerStatus == reviewerStatusType.waitNew || row.reviewerStatus == reviewerStatusType.wait)) {
        return 'light-red';
      }
      return '';
    }
  }
};