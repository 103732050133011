import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableItineraryForm from './components/TableItineraryForm';
import { FooterBar } from '@/components/common';
import { ShuttleType } from '@/config/plan';
import { ItineraryIdType } from '@/config/superviseTravel';
import { tagViewMixin } from '@/mixin';
import { columnTourist } from './config';
import { parseTime, parseDate, getNextHours, isPhone, validCard, validHKM, validPassport, processAdd } from '@/utils';
import { formatItinerarys } from '@/views/order/utils';
import { getUserData } from '@/utils/cookie';
import { DEF_VALUE, rules, insuranceMenu, defaultState, AdaptObj, AdaptBusObj, processObj, fillTouristKey, formatSiteNumber, formatTourist, getServerInfo, TransfomCertificate, formatPhoneSpace } from './helper';
export default {
  components: {
    FooterBar,
    TableItineraryForm
  },
  mixins: [tagViewMixin],
  data() {
    return {
      rules,
      insuranceMenu,
      columnTourist,
      backPath: '/plan/team-arrange',
      form: defaultState(),
      tripData: [],
      execDate: [],
      insuranceList: [],
      loadSuccess: false,
      isError: false,
      errMsg: '已修改了导游或行程日期无法查询到数据，点击修复按钮尝试修复并重新上传'
    };
  },
  computed: {
    isReload() {
      const {
        reload
      } = this.$route.query;
      return !!reload;
    },
    signId2Param() {
      const {
        signId
      } = this.$route.query;
      return signId ? JSON.parse(signId) : {};
    },
    getAgencyTravel() {
      const {
        travelAgency
      } = getUserData();
      return travelAgency.name;
    },
    isNumberError() {
      return item => this.validateCardNumber(item);
    }
  },
  async created() {
    const {
      id,
      isUpdate
    } = this.$route.query;
    if (isUpdate) {
      const item = await this.fetchSuperviseList(this.signId2Param);
      if (!item) {
        this.$bus.tip({
          type: 'warning',
          message: '修改导游与行程日期无法查询到数据！'
        });
        this.isError = true;
        return;
      }
      const {
        itineraryReceptionId: id
      } = item;
      this.fetchSuperviseDetail(id);
    } else {
      await this.fetchPlanInfo(id);
      await this.getOrderByPlanId(id);
    }
  },
  methods: {
    reFetch() {
      this.fetchSuperviseList(this.signId2Param);
    },
    reloadData() {
      const {
        id
      } = this.$route.query;
      this.fetchPlanInfo(id);
      this.getOrderByPlanId(id);
    },
    fetchSuperviseList(params) {
      const loading = this.$bus.loading();
      const contractNo = this.$route.query.contractNo || this.form.contractNo;
      return this.$store.dispatch('plan/superviseTravel/getCommitInfo', {
        'guideQueryDto.guideName': params.guideName,
        ...params,
        contractNo
      }).then(data => {
        const {
          records
        } = data;
        const item = records[0];
        this.loadSuccess = true;
        return item;
      }).catch(() => {
        this.loadSuccess = false;
        loading.close();
      });
    },
    fetchSuperviseDetail(id) {
      const loading = this.$bus.loading();
      const {
        contractNo
      } = this.form;
      return this.$store.dispatch('plan/superviseTravel/getById', {
        id,
        contractNo
      }).then(data => {
        if (!data) return;
        this.form = formatPhoneSpace({
          ...defaultState(),
          ...data
        });
        this.form.customerList = data.customerList;
        this.setExecDate([data.beginDate, data.overDate]);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    fetchGuideDetail(name) {
      const loading = this.$bus.loading();
      name = name ? name.trim() : '';
      this.$store.dispatch('plan/superviseTravel/getGuideInfo', name).then(data => {
        if (!data) return;
        const {
          records
        } = data;
        if (records && records.length) {
          const curGuide = records.find(it => it.name == name) || records[0];
          const {
            name: guideName,
            phoneNumber: guideLicencePhone,
            resourceGuideId: guideId,
            guideLicenceCode,
            company
          } = curGuide;
          this.form = {
            ...this.form,
            guideId,
            guideName,
            guideLicencePhone,
            guideCompany: company,
            guideLicenceCode
          };
        }
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    fetchPlanInfo(planId) {
      return this.$store.dispatch('plan/index/fetchPlanById', planId).then(data => {
        if (!data) return;
        const res = processObj(data, AdaptObj);
        this.form = {
          ...this.form,
          ...res
        };
        if (data.guides && data.guides.length) {
          const guide = data.guides[0];
          // const guideRes = processObj(guide, AdaptGuideObj)

          // this.form = { ...this.form, ...guideRes }
          this.fetchGuideDetail(guide.name);
        }
        // 从订单中获取行程时间
        const {
          id
        } = data.orders[0];
        this.fetchOrderInfo(id).then(orderData => {
          if (orderData) {
            const {
              startTripDate,
              endTripDate
            } = orderData;
            this.changeExecDate([startTripDate, endTripDate]);
          }
        });
      });
    },
    fetchOrderInfo(id) {
      return this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    selectItineraryType({
      value
    }) {
      if (value === 'inbound') {
        this.form.recAgencyName = this.getAgencyTravel;
        this.rules = {
          ...rules,
          recAgencyName: [{
            required: true,
            message: '地接社名称不能为空!'
          }]
          // recAgencyPhone: [
          //   { required: true, message: '地接社联系电话不能为空!' },
          // ],
        };
      } else {
        this.rules = rules;
      }
    },
    // 散客其他名单
    processMembers(data) {
      const members = data.reduce((acc, cur) => {
        const {
          members
        } = cur;
        return [...acc, ...formatTourist(members)];
      }, []);
      this.form.customerList = members;
    },
    calcPersonCount(data) {
      const count = data.reduce((acc, cur) => {
        const {
          adultCount,
          childCount
        } = cur;
        acc = processAdd(acc, adultCount, childCount);
        return acc;
      }, 0);
      // 总人数
      this.form.totalCustomer = !this.form.adultCount ? count : this.form.adultNumber;
    },
    processOrderInfo(data) {
      const {
        itinerarys
      } = data;
      const {
        name: productName
      } = data.product;
      const tripData = this.processItinerary(itinerarys);
      const totalDays = itinerarys.length;
      this.form.clientSourcePlace = '广西';
      this.form.route.itineraryOptList = tripData;
      this.form.route.totalDays = totalDays;
      this.form.route.routeName = productName;
      this.form.groupAgencyName = this.getAgencyTravel;
      this.form.totalDays = totalDays;
      // this.form.customerList = formatTourist(members)

      const {
        startTripDate,
        endTripDate
      } = data;
      this.setExecDate([startTripDate, endTripDate]);

      // 全陪信息
      const {
        accompanyInfo
      } = data;
      const {
        totalServerName,
        totalServerPhone
      } = getServerInfo(accompanyInfo);
      this.form.totalServerName = totalServerName;
      this.form.totalServerPhone = totalServerPhone;
    },
    getOrderByPlanId(id) {
      return this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        if (!data) return;
        const curItem = data[0];
        this.processOrderInfo(curItem);
        this.processMembers(data);
        this.calcPersonCount(data);
        this.getFleetByPlanId(id);
        this.getHotelByPlanId(id);
        this.getRestaurantByPlanId(id);
      });
    },
    getHotelByPlanId(planId) {
      this.$store.dispatch('plan/hotel/fetchAll', {
        planId
      }).then(data => {
        if (!data || !data.length) return;
        this.tripData.forEach(it => {
          it.hotel = this.formatHotel(data, it.itineraryDate);
        });
      });
    },
    getRestaurantByPlanId(planId) {
      this.$store.dispatch('plan/restaurant/fetch', {
        planId
      }).then(data => {
        if (!data || !data.length) return;
        this.tripData.forEach(it => {
          it.restaurant = this.formatRestaurant(data, it.itineraryDate);
        });
      });
    },
    getFleetByPlanId(planId) {
      return this.$store.dispatch('plan/fleet/fetch', {
        planId,
        shuttleType: ShuttleType.bus
      }).then(data => {
        if (!data || !data.length) return;
        const curItem = data[0];
        const {
          respOrderBusCompanyTypeSimples: busList
        } = curItem;
        const {
          busCompanyName,
          busCompanyPhone
        } = curItem;
        let obj = {
          busCompanyName,
          busCompanyPhone
        };
        if (busList && busList.length) {
          obj = {
            ...obj,
            ...busList[0]
          };
        }
        const res = processObj(obj, AdaptBusObj);
        const {
          seatingCapacity
        } = formatSiteNumber({
          ...this.form,
          ...res
        });
        this.form = {
          ...this.form,
          ...res,
          seatingCapacity
        };
      });
    },
    changeExecDate(val) {
      const [start, end] = val;
      this.form.beginDate = start;
      this.form.beginTime = start;
      this.form.overDate = end;
      this.form.overTime = end;
    },
    setExecDate([start, end]) {
      const beginDate = parseTime(new Date(start));
      const overDate = parseTime(new Date(end));
      this.execDate = [beginDate, overDate];
      this.form.beginDate = beginDate;
      this.form.beginTime = beginDate;
      this.form.overDate = overDate;
      this.form.overTime = overDate;
    },
    formatHotel(list, date) {
      const findList = list.filter(it => it.orderDate == date);
      return findList.reduce((acc, it) => {
        acc += it.hotelName ? '【' + it.hotelName + '】' : '';
        return acc;
      }, '');
    },
    formatRestaurant(list, date) {
      const findList = list.filter(it => it.orderDate == date);
      return findList.reduce((acc, it) => {
        let str = it.restaurant && it.restaurant.name && '【' + it.restaurant.name + '】';
        it.respOrderRestaurantTypes.forEach(v => {
          str += v.restaurantType ? '/' + v.restaurantType : '';
        });
        acc += str;
        return acc;
      }, '');
    },
    findDetail(records, planNumber) {
      let cur;
      if (records && records.length) {
        cur = records[0];
        if (records.length > 1) {
          cur = records.find(it => it.tuanHao == planNumber);
        }
      }
      return cur;
    },
    changeIdType(val) {
      this.form.idType = TransfomCertificate[val];
    },
    processItinerary(list) {
      if (!list || !list.length) return;
      const newList = formatItinerarys(list);
      return newList.map((it, index) => {
        const siteList = [];
        const {
          scenics,
          shops
        } = it;
        const isToday = parseDate() == it.itineraryDate;
        const arrivalTime = isToday && index == 0 ? getNextHours() : it.itineraryDate + ' 08:00:00';
        if (scenics.length) {
          scenics.forEach(v => {
            v.currentDayNumber = index + 1;
            this.$set(v, 'relevancePlaceName', v.scenicName);
            this.$set(v, 'arrivalTime', arrivalTime);
            this.$set(v, 'routeRemark', '无');
            const item = {
              ...DEF_VALUE,
              relevancePlaceName: v.relevancePlaceName,
              arrivalTime: v.arrivalTime,
              routeRemark: v.routeRemark,
              currentDayNumber: v.currentDayNumber
            };
            siteList.push(item);
          });
        } else {
          /*
            第一天没有景点的情况，备注写到了自定义景区内，上传旅检失败问题
            内容超出，只截取20个字符
          */

          const desc = it.itineraryDesc;
          const relevancePlaceName = desc && desc.length > 20 ? desc.substr(0, 20) : desc;
          const item = {
            ...DEF_VALUE,
            relevancePlaceName,
            arrivalTime,
            routeRemark: '自由活动',
            currentDayNumber: index + 1
          };
          siteList.push(item);
        }
        if (shops && shops.length) {
          shops.forEach(v => {
            v.currentDayNumber = index + 1;
            this.$set(v, 'relevancePlaceName', v.shopName);
            this.$set(v, 'arrivalTime', arrivalTime);
            this.$set(v, 'routeRemark', '无');
            const item = {
              ...DEF_VALUE,
              relevancePlaceName: v.relevancePlaceName,
              arrivalTime: v.arrivalTime,
              routeRemark: v.routeRemark,
              currentDayNumber: v.currentDayNumber
            };
            siteList.push(item);
          });
        }
        it.siteList = siteList;
        return {
          siteList
        };
      });
    },
    getadultNumber(data) {
      const {
        totalCustomer,
        childNumber
      } = data;
      const adultNumber = Number(totalCustomer) - Number(childNumber || 0);
      return {
        ...data,
        adultNumber
      };
    },
    montageSearchKey(obj) {
      const {
        guideName,
        beginDate,
        overDate,
        contractNo
      } = obj;
      return {
        guideName,
        beginDate,
        overDate,
        contractNo
      };
    },
    updateStatus(id, contractNo) {
      const loading = this.$bus.loading({
        text: '正在提交数据'
      });
      this.$store.dispatch('plan/superviseTravel/updateStatus', {
        contractNo,
        id
      }).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    getUploadSuccessData(params) {
      const loading = this.$bus.loading({
        text: '获取提交数据'
      });
      const {
        contractNo
      } = params || this.$route.query || this.form;
      return this.$store.dispatch('plan/superviseTravel/getWaitInfo', {
        contractNo,
        ...params
      }).then(data => {
        const {
          records
        } = data;
        const key = ItineraryIdType[contractNo];
        const itineraryId = records[0][key];
        this.updateStatus(itineraryId, contractNo);
        loading.close();
        this.handleClose();
      }).catch(() => {
        loading.close();
      });
    },
    setSignId(opts) {
      const {
        contractNo
      } = opts;
      const searchKey = this.montageSearchKey(opts);
      const {
        id: planId
      } = this.$route.query;
      this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        signId: JSON.stringify(searchKey),
        contractNo
      });
    },
    beforeCommit(data) {
      let opts = fillTouristKey(data);
      opts = this.getadultNumber(data);
      opts.execDate = this.execDate;
      delete opts['undefined'];
      return opts;
    },
    validateTourist(data) {
      const {
        customerList
      } = data;
      // 过滤无手机号码的游客
      const hasPhoneMember = customerList.filter(it => it.phoneNumber && it.phoneNumber != '');
      // 过滤无身份证的游客
      const hasIdNumberMember = customerList.filter(it => it.idNumber != '');
      if (!hasPhoneMember.length) {
        this.$bus.tip({
          type: 'error',
          message: '至少需要一名游客填写正确的手机号'
        });
        return false;
      }
      const phoneValid = hasPhoneMember.some(it => {
        return !isPhone(it.phoneNumber);
      });
      if (phoneValid) {
        this.$bus.tip({
          type: 'error',
          message: '游客手机号码不正确！'
        });
        return false;
      }
      const validIdNumver = hasIdNumberMember.some(it => !validCard(it.idNumber));
      if (validIdNumver && validIdNumver.length) {
        this.$bus.tip({
          type: 'error',
          message: '游客身份证号不正确！'
        });
        return false;
      }
      return true;
    },
    validateCardNumber(item) {
      const {
        idType,
        idNumber
      } = item;
      if (idType == 1 || idType == '身份证') {
        return validCard(idNumber);
      }
      if (idType == 5 || idType == '护照') {
        return validPassport(idNumber);
      }
      if (idType == 2 || idType == '港澳通行证') {
        return validHKM(idNumber);
      }
      return true;
    },
    handleUpload() {
      this.$refs.formRef.validate(isValid => {
        if (!isValid) {
          this.$bus.tip({
            type: 'error',
            message: '还有内容未填写完整！'
          });
          return;
        }
        if (!this.validateTourist(this.form)) return;
        const opts = this.beforeCommit(this.form);
        const searchKey = this.montageSearchKey(opts);
        this.$store.dispatch('plan/superviseTravel/save', opts).then(() => {
          this.setSignId(opts);
          this.getUploadSuccessData(searchKey);
        }).catch(err => {
          console.log('err:', err);
          this.$bus.tip({
            type: 'error',
            message: err.msg
          });
        });
      });
    },
    async handleUpdate() {
      this.$refs.formRef.validate(isValid => {
        if (!isValid) {
          this.$bus.tip({
            type: 'error',
            message: '还有内容未填写完整！'
          });
          return;
        }
        const opts = this.beforeCommit(this.form);
        this.$store.dispatch('plan/superviseTravel/update', opts).then(() => {
          this.setSignId(opts);
          this.handleClose();
        });
      });
    },
    async handleFix() {
      const {
        id: planId
      } = this.$route.query;
      await this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        signId: ''
      });
      await this.fetchPlanInfo(planId);
      await this.getOrderByPlanId(planId);
    },
    handlePrint() {
      let routeUrl = this.$router.resolve({
        path: '/print-supervise-travel',
        query: {
          name: 'SuperviseTravel',
          signId: JSON.stringify(this.signId2Param)
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleClose() {
      this.closeSelectedTag();
      this.goBack();
    },
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};