export const Columns = [{
  attrs: {
    prop: 'planInfo',
    label: '订单信息'
  },
  slot: 'planInfo'
}, {
  attrs: {
    prop: '',
    label: '用车时间'
  },
  slot: 'busUseDate'
}, {
  attrs: {
    prop: 'peopleCount',
    label: '接站人数'
  }
}, {
  attrs: {
    prop: 'busCompanyName',
    label: '车队',
    'min-width': 120
  }
}, {
  attrs: {
    prop: '$shuttleType',
    label: '任务类型'
  }
}, {
  attrs: {
    prop: '',
    label: '车辆信息'
  },
  slot: 'busInfo'
}, {
  attrs: {
    prop: 'busFee',
    label: '车费'
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '总金额'
  }
}, {
  attrs: {
    prop: '$cashType',
    label: '付款方式'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];