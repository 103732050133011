import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 基础输入框组件
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean} [isRequire=false] - 是否必填项
 * @vue-data {String} input - 输入框的值
 */
export default {
  name: 'InputBase',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    tip: {
      type: [String, Number],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set(val) {
        val = val.trim();
        this.$emit('update:value', val);
      }
    }
  }
};