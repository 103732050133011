import TableTripBase from './TableTripBase';
import { orderItineraryDetail } from '@/config/order';
import { dictionaryMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { formatItinerarys } from '@/views/order/utils';
export default {
  name: 'DetailItinerary',
  components: {
    TableTripBase
  },
  mixins: [dictionaryMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: [],
      columnData: orderItineraryDetail
    };
  },
  ...mapGetters({
    currentOrderId: 'order/index/currentOrderId'
  }),
  watch: {
    id: {
      handler: function (id) {
        if (id) {
          this.fetchData(id);
        }
      },
      immediate: true
    }
  },
  created() {
    this.$nextTick(() => {
      let {
        id
      } = this.$route.query;
      if (id) {
        this.fetchData(id);
      }
    });
  },
  methods: {
    // 获取订单行程
    fetchData(id) {
      this.$store.dispatch('order/index/fetchItinerary', id).then(data => {
        this.tableData = formatItinerarys(data);
        // this.formatData(data)
      });
    }
  }
};