var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "发起钉钉额度申请(增加额度)",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "",
      label: "预付款类型"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.type) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "applyUserId",
      label: "申请人"
    }
  }, [_c("SelectStaff", {
    model: {
      value: _vm.form.applyUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "applyUserId", $$v);
      },
      expression: "form.applyUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "",
      label: "申请单位"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "details",
      label: "付款明细"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "totalGuidePay",
      label: "申请总金额"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.totalGuidePay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalGuidePay", $$v);
      },
      expression: "form.totalGuidePay"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "bankName",
      label: "收款人户名"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", $$v);
      },
      expression: "form.bankName"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };