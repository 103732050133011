import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import FooterScrollBar from '@/components/FooterScrollBar';
import { SelectStaff, SelectGuide, SelectCustom, SelectProduct, InputDepartment, DialogLog, SelectDictionary } from '@/components/common';
import { customerInfoMixin, searchMixin } from '@/mixin';
import { parseDate, parseTime, throttle, getStyle, hasCustomeAuth } from '@/utils';
import { downloadExport } from '@/utils/exportExcel';
import { orderAll, orderAllNoCustom, orderAll2, orderType } from '@/config/order';
import { LOG_YPES } from '@/config/resource';
import { mapGetters } from 'vuex';
const PADDING_WIDTH = 20;
const BAR_WIDTH = 12;
export default {
  components: {
    SelectGuide,
    SelectCustom,
    InputDepartment,
    SelectStaff,
    SelectProduct,
    TableList,
    FooterScrollBar,
    DialogLog,
    SelectDictionary
  },
  mixins: [searchMixin, customerInfoMixin],
  data: () => ({
    filename: '所有订单.xlsx',
    excelLoading: false,
    visibleMore: false,
    columnData: orderAllNoCustom,
    tableData: [],
    total: 0,
    statistics: 0,
    createDate: '',
    link: {
      team: '/order/team-input-update',
      personal: '/order/personal-input-update',
      detail: '/order/order-audit-detail'
    },
    path: {
      orderAll: '/order/order-all',
      orderAll2: '/order/order-all2'
    },
    searchParams: {},
    department: [],
    inputDate: [],
    endDate: [],
    saleUserId: '',
    barWidth: 0,
    realWidth: 0,
    tableWidth: 0,
    tableBody: null,
    tableHead: null
  }),
  computed: {
    ...mapGetters({
      needReload: 'order/index/needReload',
      orderAll: 'order/index/orderAllData',
      orderAll2: 'order/index/orderAllData2'
    }),
    getVisible() {
      return this.visibleMore;
    },
    getBarWidth() {
      return this.barWidth;
    }
  },
  watch: {
    // 刷新列表数据
    needReload: {
      handler: function (val) {
        if (val['Team'] || val['Personal']) {
          this.handleSearch();
        }
      },
      immediate: true
    },
    orderAll: {
      handler: function (data) {
        if (!this.isCurrentPath(this.path.orderAll)) return;
        this.initData(data);
      },
      immediate: true
    },
    orderAll2: {
      handler: function (data) {
        if (!this.isCurrentPath(this.path.orderAll2)) return;
        this.initData(data);
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = data;
          this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderAll : orderAllNoCustom;
  },
  mounted() {
    this.$nextTick(() => {
      this.getScrollWidth();
      this.findElement();
      this.columnData = this.isCurrentPath(this.path.orderAll) ? orderAll : orderAll2;
    });
    window.addEventListener('resize', throttle(() => {
      this.getScrollWidth();
    }, 100), true);
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: this.getToday
        });
        return;
      }
      this.handleData(data);
      this.getScrollWidth();
    },
    findElement() {
      const {
        tableRef
      } = this.$refs;
      this.tableHead = tableRef.$el.querySelector('.el-table__header-wrapper');
    },
    fetchData(queryParam) {
      const fetchPath = this.isCurrentPath(this.path.orderAll) ? 'order/index/fetchAll' : 'order/index/fetchAll2';
      this.$store.dispatch(fetchPath, queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics || 0;
      this.tableData = this.formatData(list);
    },
    isCurrentPath(path) {
      return this.$route.path == path;
    },
    getScrollWidth() {
      const {
        tableRef
      } = this.$refs;
      if (!tableRef) return;
      const parent = tableRef.$el.querySelector('.el-table__body-wrapper');
      const child = parent.children[0];
      const clentWidth = parent.offsetWidth;
      const realWidth = parseInt(getStyle(child, 'width'));
      this.tableBody = parent;
      this.tableWidth = clentWidth;
      // this.realWidth = realWidth < 1920 ? realWidth + PADDING_WIDTH : realWidth // 内边距宽度
      this.realWidth = realWidth + PADDING_WIDTH; // 内边距宽度
      this.barWidth = realWidth - clentWidth - PADDING_WIDTH - BAR_WIDTH; // 滚动条宽度
    },
    handleBarScroll(position) {
      this.tableHead.scrollLeft = position;
      this.tableBody.scrollLeft = position;
    },
    onSelectDic(type, val) {
      this.searchParams[type] = val.label;
    },
    selectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productName = val.name;
    },
    changeOPUserName(val) {
      this.searchParams.dutyOPUserName = val.label;
    },
    changeoutOPUserName(val) {
      this.searchParams.outOPUserName = val.label;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    onChangeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    //输单日期 选择开始-结束日期
    onChangeCreateDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startCreateDate = startDate;
      this.searchParams.endCreateDate = endDate;
    },
    changeCreateDate(val) {
      if (val == null) {
        val = '';
        this.searchParams.startCreateDate = val;
        this.searchParams.endCreateDate = val;
      } else {
        const startDate = parseDate(val[0]); // dayjs(val[0]).format('YYYY-MM-DD')
        const endDate = parseDate(val[1]); // dayjs(val[1]).format('YYYY-MM-DD')

        this.searchParams.startCreateDate = startDate;
        this.searchParams.endCreateDate = endDate;
      }
    },
    // 编辑计划
    editTableRow(scope) {
      const {
        id,
        orderType: type,
        customerId
      } = scope.row;
      let path = this.link.team;
      if (type == orderType.personal) {
        path = this.link.personal;
      }
      this.$router.push({
        path,
        query: {
          id,
          customerId,
          type: 'update'
        }
      });
    },
    changeUserName(val) {
      this.searchParams.saleUserId = val ? val.value : '';
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 日志
    showLog({
      row
    }) {
      const types = `${LOG_YPES.order}`;
      const {
        id: itemId
      } = row;
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId,
        types,
        subType: '',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    exportExcel() {
      this.excelLoading = true;
      this.$store.dispatch('order/index/exportOrderAll', this.searchParams).then(data => {
        downloadExport(data, this.filename);
        this.$bus.tip({
          type: 'success',
          message: '导出成功'
        });
        this.excelLoading = false;
      }).catch(() => {
        this.excelLoading = false;
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const keys = orderAll.map(it => it.attrs.prop);
      return list.map(it => {
        it.unCollect = it.unIncome;
        it.shuttles.forEach(item => {
          item.shiftDateTime = item.shiftDateTime && parseTime(item.shiftDateTime, '{y}-{m}-{d} {h}:{i}');
        });
        it.createTime = it.createTime && parseTime(it.createTime);
        keys.forEach(key => {
          switch (key) {
            case 'count':
              it.count = `${it.adultCount}大${it.childCount || 0}小${it.accompanyCount || 0}陪`;
              break;
            default:
              it[key] ? it[key] : '';
          }
        });
        return it;
      });
    }
  }
};