var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-regiment-collect"
  }, [_c("SearchRegiment", {
    on: {
      search: _vm.handleSearch
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      "span-method": _vm.objectSpanMethod,
      total: _vm.total,
      sticky: true,
      "show-page": true,
      border: ""
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.planNumber))]), _c("div", [_vm._v(_vm._s(scope.row.customerTripNumber))])];
      }
    }, {
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.stTripDate))]), _c("div", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return _vm._l(scope.row.customerContactVos, function (it) {
          return _c("div", {
            key: it.id
          }, [_vm._v(" " + _vm._s(it.contact) + " "), _c("br"), _vm._v(" " + _vm._s(it.mobilePhone) + " ")]);
        });
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "detail",
      fn: function ({
        scope
      }) {
        return [_vm._l(scope.row.caCustomerFees, function (item) {
          return _c("div", {
            key: item.id
          }, [_vm._v(" " + _vm._s(_vm.getDetail(item)) + " ")]);
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCreate(scope.row);
            }
          }
        }, [_vm._v(" 收款 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };