import request from '@/utils/request';
export { fetchLog } from './index';

// 同行接收
export function fetchReceive(params) {
  return request({
    url: '/order/out/pageOrderOutTransfer',
    method: 'get',
    params
  });
}

// 修改订单状态
export function updateReceive(data) {
  return request({
    url: '/order/out/updateStatusById',
    method: 'post',
    data
  });
}

// 查询外转订单行程信息
export function updateTripOrder(params) {
  return request({
    url: '/order/out/outTransferOrder',
    method: 'get',
    params
  });
}

// 查询外转订单行程信息详情（表单）
export function updateTripOrderDetail(id) {
  return request({
    url: '/order/out/outTransferDetails',
    method: 'get',
    params: {
      id
    }
  });
}

// 接收订单详情
export function orderDetail(customerOrderId) {
  return request({
    url: '/order/out/listByOrderId',
    method: 'get',
    params: {
      customerOrderId
    }
  });
}

// 修改申请确认
export function orderUpdateConfirm(id) {
  return request({
    url: '/order/out/updateById',
    method: 'post',
    data: {
      id
    }
  });
}

// 查询外转订单详情
export function outOrderDetailRequest(params) {
  return request({
    url: '/order/out/getOutOrderDetails',
    method: 'get',
    params
  });
}

// 新增/修改 订单外转
export function outOrderSetRequest(params) {
  return request({
    url: '/order/out/saveOrUpdate',
    method: 'get',
    params
  });
}