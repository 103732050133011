import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { SELECTOR } from '@/config/select';
import { dictionaryMixin } from '@/mixin';
// import { uploadBase } from '@/config'
import { mapGetters } from 'vuex';
import { formatItinerarys } from '@/views/order/utils';
export default {
  mixins: [dictionaryMixin],
  props: {
    name: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      data: null,
      printDate,
      loading: false
    };
  },
  computed: {
    ...mapGetters('system/dictionary', ['dictionaryCodeData']),
    getPayType() {
      return type => {
        if (!type) return '';
        const fItem = SELECTOR.payType.find(it => it.value.toLowerCase() === type.toLowerCase());
        return fItem.label;
      };
    },
    getBusLength() {
      return this.data ? this.data.busTypes.length : 1;
    }

    // getLogo() {
    //   return logoUrl => {
    //     return logoUrl ? uploadBase + logoUrl : ''
    //   }
    // },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id,
        itemId: planId
      } = this.$route.query;
      this.loading = true;
      this.$store.dispatch('order/print/fetchDriverNotice', {
        id,
        planId
      }).then(data => {
        data.transferItineraryList.length ? data.transferItineraryList = formatItinerarys(data.transferItineraryList) : data.transferItineraryList = [];
        this.data = data;
        this.handlePrint();
      });
    },
    handlePrint() {
      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
    formatItinerary(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach((it, idx) => {
        if (idx > 0 && it.itineraryDate == list[idx - 1].itineraryDate) {
          if (result[idx - 1]) {
            result[idx - 1].scenicName = it.scenics.reduce((acc, cur) => {
              return acc += cur.scenicName;
            }, '');
          }
        } else {
          it.scenicName = it.scenics.reduce((acc, cur) => {
            return acc += cur.scenicName;
          }, '');
          result.push(it);
        }
      });
      return result;
    }
  }
};