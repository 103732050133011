import DialogEdit from './DialogEdit';
import { TreeCourse, BtnCreate, IconRight, IconWrong } from '@/components/common';
import { systemAccounting } from '@/config/system';
import { reloadMixin } from '@/mixin';
const defaultfrom = () => ({
  isRevenueAndExpenditure: false,
  name: '',
  parentsId: 0,
  parentsName: '',
  subjectType: 'common'
});
export default {
  components: {
    BtnCreate,
    DialogEdit,
    TreeCourse,
    IconRight,
    IconWrong
  },
  mixins: [reloadMixin],
  data: () => ({
    columnData: systemAccounting,
    tableData: [],
    // isEdit: false,
    searchData: {
      // expire: '',
      // name: '',
      // endDate: '',
      // startDate: '',
    },
    form: defaultfrom(),
    data: null,
    total: 0
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(opst = {}) {
      this.$store.dispatch('system/accounting/fetchListLevel', opst).then(data => {
        if (!data || !data.length) return;
        // 第一次默认获取第一条数据
        // let childrenList = this.fileListFormat(data)
        // this.tableData = [...data, ...childrenList]
        this.tableData = data;

        // this.fetchOneList()
      });
    },
    fileListFormat(list) {
      let result = [];
      list.forEach(it => {
        result = result.concat(it.subSubjects);
      });
      return result;
    },
    changeDate(val) {
      const [start, end] = val;
      this.searchData.startDate = start;
      this.searchData.endDate = end;
    },
    handleSearch() {
      this.fetchData(this.searchData);
    },
    onTreeCourse(node) {
      this.tableData = [node];
      // this.tableData = []
      // this.tableData.push(node, ...node.children)
    },
    fetchOneList() {
      this.$store.dispatch('system/accounting/fetchOneList');
    },
    handleDelete(row) {
      const {
        id
      } = row;
      if (row.parentsId == 0) {
        this.$bus.open(() => {
          this.deleteRow(id);
        }, '请注意，删除一级科目会把该科目的子科目清空！');
      } else {
        this.$bus.open(() => {
          this.deleteRow(id);
        }, '是否确定删除该科目？');
      }
    },
    deleteRow(id) {
      const opst = {
        id
      };
      this.$store.dispatch('system/accounting/delSubject', opst).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.fetchData();
      });
    },
    removeNode(node) {
      const {
        data
      } = node;
      this.handleDelete(data);
    },
    handleCreate() {
      this.$refs.dialogRef.show();
    },
    handleEdit(row) {
      this.$refs.dialogRef.edit(row);
    }
  }
};