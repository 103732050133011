var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      selection: true,
      border: ""
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.type) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("PopoverTip", {
          attrs: {
            title: "",
            content: scope.row.remarks
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.remarks.length > 20 ? scope.row.remarks.slice(0, 10) + "..." : scope.row.remarks))])])];
      }
    }, {
      key: "enterpriseCode",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.getType(scope.row.alipayEcodeStatus)
          }
        }, [_vm._v(" " + _vm._s(_vm.getEnterpriseStatus(scope.row.alipayEcodeStatus)) + " ")])];
      }
    }, {
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("SwitchStatus", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeStatus(scope.row);
            }
          },
          model: {
            value: scope.row.isEnable,
            callback: function ($$v) {
              _vm.$set(scope.row, "isEnable", $$v);
            },
            expression: "scope.row.isEnable"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-dropdown", {
          on: {
            command: function ($event) {
              return _vm.handleCommand(scope, $event);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text"
          }
        }, [_vm._v(" 操作"), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: "handleShow"
          }
        }, [_vm._v(" 查看 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-edit",
            command: "handleEdit"
          }
        }, [_vm._v(" 编辑 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-takeaway-box",
            command: "handleLog"
          }
        }, [_vm._v(" 日志 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: "handleDelete"
          }
        }, [_vm._v(" 删除 ")]), !scope.row.alipayEmployeeId ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-bell",
            command: "addInvite"
          }
        }, [_vm._v(" 邀请企业码 ")]) : _vm._e(), scope.row.alipayEmployeeId ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: "delInvite"
          }
        }, [_vm._v(" 移除企业码 ")]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-upload2",
            command: "updatePwd"
          }
        }, [_vm._v(" 修改密码 ")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-refresh-left",
            command: "resetPwd"
          }
        }, [_vm._v(" 重置密码 ")])], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };