var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    on: {
      mouseenter: _vm.onMouseEnter
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "right",
      trigger: "hover",
      "open-delay": 500,
      width: _vm.width
    }
  }, [_vm.tableData.length ? _c("TableBase2", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "max-height": _vm.height,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }], null, false, 1238922005)
  }) : _c("div", [_c("Empty")], 1), _c("el-button", {
    attrs: {
      slot: "reference",
      type: "text"
    },
    slot: "reference"
  }, [_c("PersonCount", {
    attrs: {
      row: _vm.data.row
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };