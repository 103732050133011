import { tableMixin } from '@/mixin';
import { orderAuditStatus } from '@/config/report';
import { orderType } from '@/config/order';
import { handleSumAvg } from '@/utils';
import { SaleUsers, OmitTip } from '@/components/common';
import { ClassKeys1, ClassKeys2, ClassKeys3, ClassKeys4, ClassKeys5, StatisticsKeys, AvgeageObj } from '../config';
// import { statisticsKeys } from '@/components/common/Order/Shopping/config'

export default {
  components: {
    SaleUsers,
    OmitTip
  },
  mixins: [tableMixin],
  data() {
    return {
      statisticsKeys: StatisticsKeys,
      orderType
    };
  },
  computed: {
    getOutOPUserInfo() {
      return row => {
        const {
          realName
        } = row;
        return `客服：${realName}`;
      };
    },
    data() {
      return this.$store.getters['report/index/teamProfit'];
    },
    statistics() {
      const data = this.data;
      return data ? data.statistics : null;
    },
    getTotal() {
      const data = this.data;
      return data ? data.totalCount : 0;
    }
  },
  methods: {
    handleSummary(args) {
      // if (!param) return
      // const callSummaryFn = getSummaries(param)

      return handleSumAvg({
        ...args,
        keyObj: AvgeageObj,
        statistics: this.statistics
      });

      // return callSummaryFn({
      //   statisticsKeys: StatisticsKeys,
      //   callback: args => handleSumAvg({ ...args, keyObj: AvgeageObj }),
      // })
    },
    planStatus(val) {
      let status = '';
      orderAuditStatus.forEach(it => {
        if (val == it.value) {
          status = it.label;
        }
      });
      return status;
    },
    changeCellClassName(row) {
      //列的label的名称
      const {
        property
      } = row.column;
      let className = '';
      if (ClassKeys1.includes(property)) {
        return 'pale-yellow'; //修改的样式
      }
      if (ClassKeys2.includes(property)) {
        return 'baby-blue'; //修改的样式
      }
      if (ClassKeys3.includes(property)) {
        return 'light-red'; //修改的样式
      }
      if (ClassKeys4.includes(property)) {
        className = 'pale-green'; //修改的样式
      }
      ClassKeys5.forEach(key => {
        if (property === key && row.row[key] < 0) {
          className = 'pale-green-red';
        }
        if (property === key && row.row[key] > 0) {
          className = 'pale-green-success';
        }
      });
      return className;
    }
    // getSummaries(param) {
  }
};