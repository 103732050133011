var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog-detail",
    attrs: {
      title: "导游组信息",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.info ? _c("div", {
    staticClass: "guide-detail"
  }, [_c("h3", [_vm._v("基本信息")]), _c("p", [_vm._v(" 组长："), _c("strong", [_vm._v(_vm._s(_vm.info.guideName))])]), _c("p", [_vm._v(" 组名："), _c("strong", [_vm._v(_vm._s(_vm.info.name))])]), _c("p", [_vm._v(" 备注："), _c("strong", [_vm._v(" " + _vm._s(_vm.info.remarks))])])]) : _vm._e(), _c("div", {
    staticClass: "title"
  }, [_c("h3", [_vm._v("组员")])]), _vm.tableData.length ? _c("div", {
    staticClass: "table-alt"
  }, [_c("TableBase", {
    attrs: {
      height: "350",
      data: _vm.tableData,
      "column-data": _vm.columnData
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };