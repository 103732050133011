var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "resName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.resName) + " ")];
      }
    }, {
      key: "adultCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " ")];
      }
    }, {
      key: "childCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.childCount) + " ")];
      }
    }, {
      key: "totalAmountReceive",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalAmountReceive) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.otherFee) + " ")];
      }
    }, {
      key: "totalQuotePay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalQuotePay) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "cashPayment",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.cashPayment) + " ")];
      }
    }, {
      key: "companyTransfer",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.companyTransfer) + " ")];
      }
    }, {
      key: "sign",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.sign) + " ")];
      }
    }, {
      key: "totalPayed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPayed) + " ")];
      }
    }, {
      key: "unPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.unPay) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };