import { parseDate, parseTime } from '@/utils';
import { systemMixin } from '@/mixin';
export default {
  name: 'CheckAccountTime',
  mixins: [systemMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    timeFomat(item) {
      const {
        checkAccountTime
      } = item;
      const code = this.getConfigStatus('checkAccount:checkAccountShowTime:checkAccountShowValue');
      if (code && checkAccountTime) {
        return parseTime(checkAccountTime);
      }
      if (!code && checkAccountTime) {
        return parseDate(checkAccountTime);
      }
      return '';
    }
  }
};