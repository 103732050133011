import FooterScrollBar from '@/components/FooterScrollBar';
import { getStyle } from '@/utils';
const PADDING_WIDTH = 20;
const BAR_WIDTH = 12;
export default {
  name: 'TableScrollBar',
  components: {
    FooterScrollBar
  },
  data() {
    return {
      barWidth: 0,
      realWidth: 0,
      tableWidth: 0,
      tableBody: null,
      tableHead: null
    };
  },
  methods: {
    init() {
      this.findElement();
      this.getScrollWidth();
    },
    findElement() {
      if (!this.$parent) return;
      this.tableHead = this.$parent.$el.querySelector('.el-table__header-wrapper');
    },
    getScrollWidth() {
      if (!this.$parent) return;
      const parent = this.$parent.$el.querySelector('.el-table__body-wrapper');
      const child = parent.children[0];
      const clentWidth = parent.offsetWidth;
      const realWidth = parseInt(getStyle(child, 'width'));
      this.tableBody = parent;
      this.tableWidth = clentWidth;
      // this.realWidth = realWidth < 1920 ? realWidth + PADDING_WIDTH : realWidth // 内边距宽度
      this.realWidth = realWidth + PADDING_WIDTH; // 内边距宽度
      this.barWidth = realWidth - clentWidth - PADDING_WIDTH - BAR_WIDTH; // 滚动条宽度
    },
    handleBarScroll(position) {
      this.tableHead.scrollLeft = position;
      this.tableBody.scrollLeft = position;
    }
  }
};