// 企业码付对账
import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';
export const aliecodeListRequest = params => {
  return request({
    url: '/order/guideBorrow/alipay-consume-l',
    method: 'get',
    params
  });
};
export const aliecodeBalanceRequest = params => {
  return request({
    url: '/order/guideBorrow/alipay-acc-balance',
    method: 'get',
    params
  });
};
export const aliecodeExportRequest = params => {
  const url = APP.baseUrl + '/order/guideBorrow/alipay-consume-l-export';
  return requestBlob({
    url,
    params
  });
};