export const systemCustomKpi = [{
  attrs: {
    prop: 'customerName',
    label: '客户',
    width: 120
  }
}, {
  attrs: {
    prop: 'year',
    label: '年份'
  }
}, {
  attrs: {
    prop: 'kpitype',
    label: '项目'
  },
  slot: 'kpitype'
}, {
  attrs: {
    prop: 'customerAreaService',
    label: '客服计调',
    sortable: true
  },
  slot: 'customerAreaService'
}, {
  attrs: {
    prop: 'createUserName',
    label: '业务员',
    sortable: true
  }
}, {
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 150
  },
  slot: 'action'
}];