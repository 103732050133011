var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-refund"
  }, [_c("SearchBase", {
    on: {
      "on-search": _vm.fetchData
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectCustom
    },
    model: {
      value: _vm.searchParams.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerId", $$v);
      },
      expression: "searchParams.customerId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.starDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.selectDate("startTripDate", $event);
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "散团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.selectDate("endTripDate", $event);
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "订单日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: ""
    },
    on: {
      "on-change": function ($event) {
        return _vm.selectDate("orderDate", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划类型"
    }
  }, [_c("SelectBase", {
    attrs: {
      clearable: "",
      label: "",
      type: "orderType"
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.handleExport
    }
  }, [_vm._v(" 导出 ")])], 1)], 2), _c("TableNew", {
    attrs: {
      id: "ReportRefundTable",
      data: _vm.tableData,
      columns: _vm.columns,
      total: _vm.total,
      statistics: _vm.totalData,
      "statistics-keys": _vm.statisticsKeys
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        row
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLink(row);
            }
          }
        }, [_vm._v(" " + _vm._s(row.customerName) + " ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };