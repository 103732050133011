// 发团预定安排 车队
import request from '@/utils/request';

// 安排列表
export function fetchFleet(params) {
  return request({
    url: '/order/orderBus/listAll',
    method: 'get',
    params
  });
}

// 安排更新
export function updateFleet(data) {
  return request({
    url: '/order/orderBus/save',
    method: 'post',
    data
  });
}

// 安排更新
export function updateFleetSuper(data) {
  return request({
    url: '/order/orderBus/save',
    method: 'post',
    data,
    timeout: 10000,
    headers: {
      tmpSupper: 'TS-1'
    }
  });
}

// 安排删除
export function deleteFleet(ids) {
  return request({
    url: '/order/orderBus/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteFleetItem(id) {
  return request({
    url: '/order/orderBus/delType',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchFleetOrder(params) {
  return request({
    url: '/order/orderBus/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateFleetOrder(data) {
  return request({
    url: '/order/orderBus/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteFleetOrder(id) {
  return request({
    url: '/order/orderBus/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteFleetOrderItem(id) {
  return request({
    url: '/order/orderBus/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchFleetOrderList(params) {
  return request({
    url: '/order/orderBus/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectFleetOrderList(data) {
  return request({
    url: '/order/orderBus/saveAndList',
    method: 'post',
    data
  });
}

// 批量车队安排 修改车队
export function batchUpdateShuttleRequest(data) {
  return request({
    url: '/order/orderBus/updateRes',
    method: 'post',
    data
  });
}

// 设置车队安排
export function setFleetArrange(data) {
  return request({
    url: '/order/plan/setBusCompanyOrderStatus',
    method: 'post',
    data
  });
}