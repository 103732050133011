import { getUserData } from '@/utils/cookie';
import { APPLY_MODE, ENTERPRISE_MODE, ENTERPRISE_RESTYPE, rules, defaultStateQuota, alipayEnterCodeParams } from './config';
export default {
  data() {
    return {
      APPLY_MODE,
      ENTERPRISE_MODE,
      ENTERPRISE_RESTYPE,
      rules,
      alipayEnterCodeParams,
      visible: false,
      form: {
        ...defaultStateQuota(),
        type: '导游额度追加'
      },
      guideInfo: null,
      content: '',
      isAppend: true
    };
  },
  computed: {
    getRealName() {
      const {
        realName
      } = getUserData();
      return realName;
    }
  },
  methods: {
    append(item) {
      const {
        id: alipayEnterCodeAddMoneyApplyId,
        planId,
        planNumber: details,
        guide,
        guideName
      } = item;
      const {
        id: applyUserId,
        travelAgency
      } = getUserData();
      const {
        name
      } = travelAgency;
      this.form = {
        ...this.form,
        name,
        alipayEnterCodeAddMoneyApplyId,
        planId,
        details,
        applyUserId,
        bankName: guide && guide.name || guideName
      };
      this.content = details;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = defaultStateQuota();
    },
    // 保存
    handleConfirm() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) return;
      const total = this.form.totalGuidePay;
      const opts = {
        ...this.form,
        details: [{
          name: this.content,
          value: total
        }],
        applyMode: APPLY_MODE.ALIPAY
      };
      opts.alipayEnterCodeParams = {
        mode: ENTERPRISE_MODE.PLAN,
        standards: [{
          resType: ENTERPRISE_RESTYPE.DEFAULT,
          value: total
        }]
      };
      this.$store.dispatch('plan/guide/dingdingApply', opts).then(() => {
        this.hide();
        this.$bus.tip();
      });
    }
  }
};