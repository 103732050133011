import guideMixin from './guide';
const columnData = [{
  label: '购物店|日期',
  prop: 'name'
}, {
  label: '小团号',
  prop: 'customerTripNumber'
}, {
  label: '总打单金额',
  prop: 'totalBuyMoney',
  width: '70px'
}, {
  label: '商品',
  prop: 'goodName'
}, {
  label: '打单金额',
  prop: 'money',
  width: '30px'
}, {
  label: '导佣比例',
  prop: 'guideRate',
  width: '30px'
}, {
  label: '导佣扣除',
  prop: 'guideRebateDeduction',
  width: '30px'
}, {
  label: '导游佣金',
  prop: 'guideRebate',
  width: '30px'
}, {
  label: '司佣比例',
  prop: 'driverRate',
  width: '30px'
}, {
  label: '司佣扣除',
  prop: 'driverRebateDeduction',
  width: '30px'
}, {
  label: '司机佣金',
  prop: 'driverRebate',
  width: '30px'
}, {
  label: '全陪比例',
  prop: 'accompanyRate',
  width: '30px'
}, {
  label: '全陪佣金',
  prop: 'accompanyRebate',
  width: '30px'
}, {
  label: '合计',
  prop: 'total'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};