import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableListDone from './TableListDone';
import { departKeys, departIdKeys } from '@/config/dictionary';
import { orderPersonalDone, orderPersonalDoneNoCustom } from '@/config/order';
import { findComponentsUpward, parseDate, hasCustomeAuth } from '@/utils';
import { BtnMore, BtnCreate, SelectGuide, SelectProduct, SelectStaff, SelectCustom, SelectRegion, InputAgeRange, InputDepartment } from '@/components/common';
import { mapGetters } from 'vuex';
import { customerInfoMixin } from '@/mixin';
const dateArr = val => parseDate(val);
export default {
  components: {
    BtnMore,
    BtnCreate,
    SelectGuide,
    SelectProduct,
    SelectStaff,
    SelectCustom,
    InputAgeRange,
    TableListDone,
    SelectRegion,
    InputDepartment
  },
  mixins: [customerInfoMixin],
  data() {
    return {
      link: {
        create: '/order/personal-input-create'
      },
      visibleMore: false,
      tableData: [],
      columnData: orderPersonalDoneNoCustom,
      total: 0,
      summaryCount: '',
      statistics: 0,
      createDate: [],
      form: {
        startTripDate: ''
      },
      searchParams: {
        startTripDateS: dateArr(new Date())
      },
      sendDate: [dateArr(new Date()), ''],
      extra: {
        region: [],
        department: {
          departmentId: '',
          grandsonDepartmentId: '',
          subDepartmentId: ''
        }
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews',
      inGroupData: 'order/personal/inGroupData'
    }),
    getTagView() {
      return this.visitedViews.find(tag => tag.path === this.$route.path);
    },
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    },
    getTagViewSearchExtra() {
      const curTag = this.getTagView;
      return curTag ? curTag.extra : this.extra;
    }
  },
  watch: {
    inGroupData: {
      handler: 'initData',
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data && data['inGroup']) {
          const {
            startTripDateS,
            startTripDateE,
            startCreateDate,
            endCreateDate
          } = data['inGroup'];
          this.searchParams = data['inGroup'];
          this.form.createDate = [startCreateDate, endCreateDate];
          this.sendDate = [startTripDateS, startTripDateE];
        }
      },
      immediate: true
    },
    getTagViewSearchExtra: {
      handler: function (data) {
        if (data && data['inGroup']) {
          this.extra = data['inGroup'];
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderPersonalDone : orderPersonalDoneNoCustom;
  },
  mounted() {
    const parent = findComponentsUpward(this, 'Personal');
    this.parentNode = parent[0];
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
        return;
      }
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics || 0;
      this.tableData = this.formatData(list, true);
      this.summaryCount = this.formatSummary(list);
    },
    // 已拼团
    fetchData(queryParam) {
      queryParam = {
        ...queryParam,
        isUnGroup: 0
      };
      this.$store.dispatch('order/personal/fetch', {
        type: 'inGroup',
        queryParam
      });
    },
    formatData(list) {
      const listDateObj = list.reduce((acc, cur) => {
        if (!acc[cur.startPlanDate]) {
          acc[cur.startPlanDate] = [];
        }
        acc[cur.startPlanDate].push(cur);
        return acc;
      }, {});
      const sortList = Object.keys(listDateObj).map(key => {
        return {
          date: key,
          content: listDateObj[key]
        };
      }).sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      return sortList.reduce((acc, cur) => {
        const content = cur.content.map(it => {
          this.$set(it, 'expend', true);
          it.orders = it.orders.map(or => {
            or.rowId = it.id;
            or.planStatus = it.planStatus;
            return or;
          });
          return it;
        });
        return [...acc, ...content];
      }, []);
    },
    formatSummary(data) {
      const [audlt, child, accompany] = data.reduce((acc, cur) => {
        acc[0] += cur.adultCount;
        acc[1] += cur.childCount;
        acc[2] += cur.accompanyCount;
        return acc;
      }, [0, 0, 0]);
      return `${audlt}大${child}小${accompany}陪`;
    },
    showCreate() {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'create'
        }
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.searchParams.pageNum = pageNum;
      this.searchParams.pageSize = pageSize;
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    handleListQuerySearch() {
      this.handleSearch(this.listQuery);
    },
    // 搜索
    handleSearch() {
      // 搜索的时候重置页码
      this.searchParams.pageNum = 1;
      this.$store.dispatch('tagsView/appendVisiteTabSearch', {
        path: this.$route.path,
        search: this.searchParams,
        extra: this.extra,
        name: 'inGroup'
      });
      this.fetchData(this.searchParams);
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.id;
      this.searchParams[type + 'Label'] = val.label;
    },
    changeCreateDate(val) {
      let [start, end] = val;
      const startDate = start && parseDate(start); // dayjs(val[0]).format('YYYY-MM-DD')
      const endDate = end && parseDate(end); // dayjs(val[1]).format('YYYY-MM-DD')
      this.form.createDate = [startDate, endDate];
      this.searchParams.startCreateDate = startDate;
      this.searchParams.endCreateDate = endDate;
    },
    handleAge(type, val) {
      this.searchParams[type] = val;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
      departIdKeys.forEach(key => {
        this.extra.department[key] = val[key];
      });
    },
    // 客源地
    changeRegion(val) {
      console.log('val:', val);
      const {
        city,
        district,
        province
      } = val;
      this.searchParams.source = `${province || ''}${city || ''}${district || ''}`;
      // this.extra.region = [provinceId, cityId, districtId]
    },
    // 选择客户
    changeCustomerName(val) {
      this.searchParams.customerName = val.label;
    },
    revokeTableRow(scope) {
      this.parentNode.revokeTableRow(scope);
    },
    transferRow(scope) {
      this.parentNode.transferRow(scope);
    },
    cancelTableRow(scope) {
      // 取消计划/移除计划
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        const loading = this.$bus.loading();
        this.$store.dispatch('order/index/cancelPlan', id).then(() => {
          this.$bus.tip();
          this.fetchData(this.searchParams);
          this.loading.close();
        }).catch(() => {
          loading.close();
        });
      });
    },
    deleteTableRow(scope) {
      this.parentNode.deleteTableRow(scope);
    },
    editTableRow(scope) {
      this.parentNode.editTableRow(scope);
    },
    showTableDetail(scope) {
      this.parentNode.showTableDetail(scope);
    },
    copyTableRow(scope) {
      this.parentNode.copyTableRow(scope);
    }
  }
};