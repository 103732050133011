import OrderInfo from './OrderInfo.vue';
import OrderApply from './OrderApply.vue';
import PayInfo from '../components/PayInfo.vue';
import ModifyItem from '../components/ModifyItem.vue';
import modifyMixin from '../modify';
import { parseDate, parseTime } from '@/utils';
import { SelectDictionary } from '@/components/common';
export default {
  components: {
    OrderInfo,
    OrderApply,
    PayInfo,
    ModifyItem,
    SelectDictionary
  },
  mixins: [modifyMixin],
  data() {
    return {
      parseTime,
      parseDate,
      visible: false,
      isEdit: false,
      info: {},
      baseInfo: null,
      payInfoData: null,
      orderInfoData: null,
      hotelStandard: 'hotelStandardType',
      hotelRegion: 'hotelRegionType'
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    getEditStatus() {
      return this.isEdit;
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/resource/fetchBookingHotelDetail', params).then(data => {
        this.handleData(data);
      });
    },
    show(row, isEdit = false) {
      const params = {
        id: row.rowId
      };
      !isEdit ? params.isReview = 1 : null;
      this.isEdit = isEdit;
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
    },
    notice() {
      this.$notify({
        title: '修改成功',
        message: '修改内容申请已提交，待对方确认后，修改内容才会变更。',
        type: 'success'
      });
    },
    selectHotelRegion(item, val) {
      const {
        label
      } = val;
      item.hotelArea = label;
    },
    selectHotelStandard(item, val) {
      const {
        label
      } = val;
      item.hotelStandard = label;
    },
    handleData(row) {
      const {
        payType,
        totalPay
      } = row;
      const {
        rooms,
        hotelName,
        hotelContact
      } = row;
      this.info = row;
      this.payInfoData = {
        payType,
        totalPay
      };
      this.orderInfoData = {
        rooms,
        hotelName,
        hotelContact,
        totalPay
      };
      this.visible = true;
    },
    beforeCommit() {
      const {
        id,
        rooms,
        orderDate,
        customerOrderId,
        planNumber,
        companyId,
        hotelId,
        hotelArea,
        hotelStandard
      } = this.info;
      const newRooms = rooms.map(it => {
        return {
          ...it,
          // id: it.id,
          // checkInName: it.checkInName,
          // remarks: it.remarks,
          inDate: parseDate(it.inDate),
          outDate: parseDate(it.outDate)
        };
      });
      return {
        id,
        companyId,
        customerOrderId,
        hotelArea,
        hotelOrderId: hotelId,
        hotelStandard,
        orderDate,
        planNumber,
        rooms: newRooms
      };
    },
    handleConfirm() {
      const opts = this.beforeCommit();
      this.$store.dispatch('order/resource/bookingHotelSet', opts).then(() => {
        this.hide();
        this.$bus.tip();
        this.$parent.initData();
        this.info.sendPlanStatus && this.notice();
      });
    }
  }
};