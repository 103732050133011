import service from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 获取公告列表
export function fetchNotice(params) {
  return service({
    url: '/system/sysNotice/page',
    method: 'get',
    params
  });
}

// 获取公告详情
export function fetchNoticeDetail(id) {
  // const url = parseQuerys('/system/sysNotice/id', { id })
  return service({
    url: '/system/sysNotice/id',
    method: 'get',
    params: {
      id
    }
  });
}

// 添加公告
export function createNotice(data) {
  return service({
    url: '/system/sysNotice/add',
    method: 'post',
    data
  });
}

// 更新公告
export function updateNotice(data) {
  return service({
    url: '/system/sysNotice/update',
    method: 'post',
    data
  });
}

// 删除公告
export function deleteNotice(id) {
  return service({
    url: `/system/sysNotice/del?id=${id}`,
    method: 'post',
    data: {}
  });
}

/**
 * 公告查询相关接口
 */

// 公告列表
export function fetchNoticeRead(params) {
  return service({
    url: '/system/sysNoticeUserRecord/notices',
    method: 'get',
    params
  });
}

// 公告列表 未读
export function fetchNoticeUnRead(params) {
  return service({
    url: '/system/sysNoticeUserRecord/notices-un-read',
    method: 'get',
    params
  });
}

// 设为已读
export function setNoticeRead(params) {
  return service({
    url: `/system/sysNoticeUserRecord/read-status`,
    method: 'post',
    params
  });
}