import { GuideProxy } from '@/components/common/Order';
import { auditEditGuideProxy } from '@/config/audit';
export default {
  components: {
    GuideProxy
  },
  data() {
    return {
      columnData: auditEditGuideProxy
    };
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    }
  }
};