/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchInsurance, createOrUpdateInsurance, deleteInsurance, fetchGroupList } from '@/api/resource/insurance';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  insuranceLog: null,
  insuranceData: null,
  insuranceGroupData: null,
  insuranceDataOrigin: null,
  insuranceAgreement: null
});
const state = dfaultState();
const getters = {
  insuranceLog: state => state.insuranceLog,
  insuranceData: state => state.insuranceData,
  insuranceGroupData: state => state.insuranceGroupData,
  insuranceDataOrigin: state => state.insuranceDataOrigin,
  insuranceAgreement: state => state.insuranceAgreement
};
const actions = {
  fetch({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchInsurance(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表(集团)
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchGroupList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.insurance
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.insurance
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchAgreement({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.insurance
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      commit('FETCH_AGREEMENT', res.data);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  addAgreementItem({}, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateInsurance(opts);
  },
  delete({
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      delFiles.length && this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteInsurance(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.insuranceData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.insuranceGroupData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.insuranceDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.insuranceLog = data;
  },
  FETCH_AGREEMENT: (state, data) => {
    state.insuranceAgreement = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.insuranceData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.insuranceData.list.filter(it => it.id !== id);
    state.insuranceData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};