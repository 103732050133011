import { mapGetters } from 'vuex';
import { tableMixin } from '@/mixin';
import { TicketStatusEnum } from '@/config/ticket';
export default {
  mixins: [tableMixin],
  computed: {
    ...mapGetters({
      currentPlan: 'ticket/index/currentPlan'
    }),
    isDisabled() {
      return this.currentPlan ? this.currentPlan.signSheetStatus === TicketStatusEnum.COMPLETED : false;
      // return row => {
      //   const { autoReleaseStatus, isUsedStatus } = row
      //   return autoReleaseStatus || isUsedStatus
      // }
    }
  },
  methods: {
    handleCheckOne(row, val) {
      this.$emit('on-check', {
        row,
        val
      });
    },
    handleRemove(row) {
      this.$emit('on-del', row);
    }
  }
};