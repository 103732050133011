export const defaultState = () => ({
  id: '',
  name: '',
  singleGroupAuditRole: '',
  remarks: '',
  isEnable: true,
  showCustomerInfo: true,
  showShopSubsidy: true,
  selectRole: '',
  departmentPrivilege: ''
});
export const rules = {
  name: [{
    required: true,
    message: '姓名不能为空',
    trigger: 'blur'
  }],
  singleGroupAuditRole: [{
    required: true,
    message: '单团审核角色不能为空',
    trigger: 'blur'
  }]
};