import "core-js/modules/es.array.push.js";
import { BtnLink } from '@/components/common';
import { tableMixin } from '@/mixin'; //summaryMixin
import { PersonCount } from '@/components/common';
import { Guides } from '@/components/common';
import shopMixin from '../../shopMixin';
// import { shoppingInput } from '@/config/shopping'
export default {
  components: {
    BtnLink,
    PersonCount,
    Guides
  },
  mixins: [tableMixin, shopMixin],
  //summaryMixin
  data: () => ({
    create: '/shopping/input-create',
    detail: '/shopping/input-detail',
    avgeageMaps: [{
      hasChild: false,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: '',
      // 当hasChild为true时，key 为 对象字符串

      divisor: 'enterStoreMemberCount',
      // 人数字段
      dividend: 'totalBuyMoney',
      // 总数
      value: 'perCapita' // 平均值
    } // 计算人均
    ],
    statisticsKeys: ['count', 'enterStoreMemberCount', 'totalBuyMoney', 'totalShopRebate', 'totalGuideRebate']
  }),
  computed: {
    getGoodsName() {
      return list => {
        if (list && list.length) {
          return list[0].name;
        }
        return '';
      };
    }
  },
  // mounted() {
  //   this.tailMergeNum = this.columnData.length - 2
  // },

  methods: {
    handleDetail(row) {
      const {
        id,
        planId,
        shopId,
        planShopId
      } = row;
      if (!planShopId) {
        this.$bus.tip({
          type: 'warning',
          message: '该购物店没有商品！'
        });
        return false;
      }
      this.$router.push({
        path: this.detail,
        query: {
          id,
          planId,
          shopId,
          planShopId
        }
      });
    },
    addShop(row) {
      // this.$emit('on:add', row)
      this.$parent.showDialog(row);
      // const { planShopId: id, rowId, planId, shopId } = row
      // this.$router.push({
      //   path: this.create,
      //   query: { id, rowId, planId, shopId },
      // })
    },
    removeItem(id) {
      this.$bus.open(() => {
        this.$store.dispatch('plan/shopping/delete', [id]).then(() => {
          this.$parent.handleSearch();
        });
      });
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    }
  }
};