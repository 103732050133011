import "core-js/modules/es.array.push.js";
import { ShuttleType } from '@/config/plan';
import { mapGetters } from 'vuex';
import { customerInfoMixin } from '@/mixin';
import CustomerInfo from './CustomerInfo.vue';
export default {
  components: {
    CustomerInfo
  },
  mixins: [customerInfoMixin],
  data() {
    return {
      productType: '',
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      curOrder: 'order/index/currentOrder'
    }),
    getCustomSource() {
      const {
        province,
        city,
        district
      } = this.curOrder;
      return `${province}${city}${district}`;
    }
  },
  mounted() {
    const {
      productType
    } = this.$route.query;
    this.productType = productType;
    this.fetchData();
  },
  methods: {
    // 获取修改后的人数
    alterFormat(list, data) {
      let format = {};
      list.forEach(key => {
        const obj = data.updateDetails.filter(it => it.attr == key)[0];
        format[key] = obj ? obj.newVal : data[key];
      });
      return `${format[list[0]]}大${format[list[1]]}小${format[list[2]]}陪`;
    },
    fetchData() {
      const {
        id,
        isReview
      } = this.$route.query;
      const opts = {
        id,
        isReview
      };
      this.$store.dispatch('order/index/fetchDetail', opts).then(data => {
        if (!data || data.planId <= 0) return;
        this.fetchFleetData(data.planId);
      });
    },
    fetchFleetData(planId) {
      this.$store.dispatch('plan/fleet/fetch', {
        planId,
        shuttleType: ShuttleType.bus
      }).then(data => {
        this.tableData = this.formatData(data);
      });
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = it => {
        const itemList = it.respOrderBusCompanyTypeSimples;
        const item = {
          ...it,
          rowId: it.id,
          planFleetId: it.id,
          isChecked: false,
          busCompanyId: it.busCompanyId,
          busCompanyName: it.busCompanyName,
          payType: (it.payType || 'guidesign').toLowerCase(),
          useType: it.useType,
          totalPay: it.totalPay,
          remark: it.remark,
          planId: it.planId,
          driverList: [],
          busList: []
        };
        if (itemList && itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              busType: v.busType.split('|')[1] || '',
              busTypeName: v.busType.split('|')[0] || '',
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      };
      list.forEach(it => {
        handleFn(it);
      });
      // result.map(it => {
      //   this.$set(it, 'totalPay', this.calcRowTotalMoney(it))
      // })
      return result;
    }
  }
};