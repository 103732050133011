import TableList from './TableList';
import DialogPickup from './DialogPickup';
import DialogArrangePickup from './DialogArrangePickup';
import DialogDownOrder from './DialogDownOrder';
import { BtnExport, SelectProduct, SelectCustom, SelectStaff, DialogLog } from '@/components/common';
import DialogStation from '@/views/order/components/DialogStation';
import { planPickup } from '@/config/plan';
import {
// getNextDate,
parseDate,
// parseTime,
filterCustomColumn, hasCustomeAuth } from '@/utils';
import { customerInfoMixin, searchMixin } from '@/mixin';
const dateArr = val => parseDate(val);
const today = dateArr(new Date());
export default {
  components: {
    BtnExport,
    SelectProduct,
    SelectCustom,
    SelectStaff,
    DialogLog,
    DialogPickup,
    TableList,
    DialogDownOrder,
    DialogStation,
    DialogArrangePickup
  },
  mixins: [searchMixin, customerInfoMixin],
  data: () => ({
    isFirst: false,
    loading: false,
    columnData: [],
    tableData: [],
    total: 0,
    searchParams: {},
    statistic: 0,
    lastEndDate: today,
    value: ''
  }),
  computed: {
    pickupArrangeData() {
      return this.$store.getters['plan/index/pickupArrangeData'];
    }
  },
  watch: {
    pickupArrangeData: {
      handler: function (data) {
        if (!data) {
          this.shiftDate = [today, today];
          const formatDate = this.processDate({
            shiftDateTimeS: today,
            shiftDateTimeE: today
          });
          this.searchParams = formatDate;
          this.fetchData(this.searchParams);
        } else {
          this.handleData(data);
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchData({
      shuttleType: 'SEND'
    });
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planPickup : filterCustomColumn(planPickup);
  },
  mounted() {
    this.$bus.on('select:station', this.$refs.dialogPickupRef.selectStation);
  },
  methods: {
    fetchData(queryParams) {
      this.loading = true;
      return this.$store.dispatch('plan/index/fetchPickup', queryParams).then(() => {
        this.loading = false;
        this.processDateAfter();
      }).catch(() => {
        this.loading = false;
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistic
      } = data;
      this.tableData = Object.freeze(list);
      this.total = totalCount;
      this.statistic = statistic;
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.lastEndDate = endDate;
      this.searchParams.shiftDateTimeS = startDate || '';
      this.searchParams.shiftDateTimeE = endDate || '';
    },
    showDialogOrder(data) {
      this.$refs.dialogOrderRef.show(data);
    },
    showDialogPickup(data) {
      this.$refs.dialogPickupRef.show(data);
    },
    handleFee(row) {
      this.$refs.dialogArrangePickupRef.show(row);
    },
    editTableRow(row) {
      const {
        id
      } = row;
      this.$store.dispatch('order/index/fetchPickupDetail', id).then(data => {
        this.showDialogPickup(data);
      });
    },
    showLog(row) {
      const {
        customerOrderId: itemId
      } = row;
      this.$store.dispatch('plan/index/fetchPickupLog', {
        itemId
      }).then(res => {
        this.$refs.logRef.show(res.data);
      });
    },
    changeOrderType(val) {
      this.searchParams.orderType = val;
    },
    processDate(data) {
      return data;
      // let { shiftDateTimeS, shiftDateTimeE } = data
      // const dataObj = {}
      // if (shiftDateTimeS) {
      //   dataObj.shiftDateTimeS = `${parseDate(shiftDateTimeS)} 04:00:00`
      // }

      // if (shiftDateTimeE) {
      //   const next = new Date(getNextDate(shiftDateTimeE))
      //   next.setHours(4)
      //   next.setMinutes(0)
      //   next.setSeconds(0)

      //   dataObj.shiftDateTimeE = parseTime(next)
      // }
      // return dataObj
    },
    processDateAfter() {
      this.$nextTick(() => {
        this.searchParams.shiftDateTimeE = this.lastEndDate;
      });
    },
    handleProcess() {
      const formatDateObj = this.processDate(this.searchParams);
      this.searchParams = {
        ...this.searchParams,
        ...formatDateObj
      };
    },
    handleSearch() {
      const {
        path
      } = this.$route;
      const searchParams = this.searchParams;
      console.log('searchParams:', searchParams);
      // const formatDateObj = this.processDate(this.searchParams)
      // this.searchParams = { ...this.searchParams }

      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1
      });
      this.fetchData(searchParams);
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search: searchParams
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      const {
        path
      } = this.$route;
      const searchParams = this.searchParams;
      const formatDateObj = this.processDate(this.searchParams);
      this.searchParams = {
        ...this.searchParams,
        ...formatDateObj
      };
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search: searchParams
      });
    },
    handleExport() {
      const formatDateObj = this.processDate(this.searchParams);
      this.searchParams = {
        ...this.searchParams,
        ...formatDateObj
      };
      const searchParams = this.searchParams;
      this.$store.dispatch('plan/index/exportPickup', searchParams).then(() => {
        this.processDateAfter();
      });
    },
    resetDate() {
      this.searchParams.shiftDateTimeS = '';
      this.searchParams.shiftDateTimeE = '';
    }
  }
};