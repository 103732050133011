var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableTripBase", {
    attrs: {
      "table-data": _vm.tableData,
      border: ""
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };