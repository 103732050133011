import { ResourceType } from '@/config/billCheck';
import { dictionaryMixin, searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { SelectLine, SelectGuide, SelectProduct, BtnExport, BtnMore, InputScenic, InputRestaurant, InputFleet, InputTraffic, SelectDictionary } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputScenic,
    InputRestaurant,
    InputFleet,
    InputTraffic,
    SelectProduct,
    SelectDictionary,
    BtnExport,
    BtnMore
  },
  mixins: [searchMixin, dictionaryMixin],
  props: {
    resourceType: {
      type: String,
      default: 'bus'
    }
  },
  data() {
    return {
      ResourceType,
      // orderDate: [],
      // checkBillDate: [],
      searchParams: {
        startTripDateS: parseDate(new Date())
      },
      hasExcel: false,
      department: {},
      visibleMore: true,
      hotelArea: '',
      resource: '',
      // 资源
      checkAccountStatus: ''
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews'
    }),
    getVisible() {
      return this.visibleMore;
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    getResourceType(type) {
      const {
        fullPath
      } = this.$route;
      let [, name] = fullPath.split('/report/order-list/');
      name = name.split('?')[0];
      return type == name;
    },
    toggleVisible(val) {
      this.visibleMore = val;
    },
    changeStarTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeEndTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.endTripDateS = starDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeOrderDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.orderDateS = starDate;
      this.searchParams.orderDateE = endDate;
    },
    selecthotelRegion({
      label
    }) {
      this.searchParams.customerArea = label;
    },
    handleSelect(val) {
      this.searchParams.resourceId = val ? val.id : '';
      this.searchParams.resourceLabel = val ? val.label : '';
      delete this.searchParams.resourceName;
    },
    handleChange(val) {
      this.searchParams.resourceName = val;
      this.searchParams.resourceLabel = val;
      delete this.searchParams.resourceId;
    },
    closeTag() {
      this.closeSelectedTag();
    },
    show() {},
    // 搜索
    handleSearch() {
      this.searchParams.resourceType = this.resourceType;
      let {
        path
      } = this.$route;
      const search = this.searchParams;
      const extra = this.extra;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search,
        extra
      });
      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1,
        size: this.searchParams.pageSize
      });
      this.$parent.fetchData(this.searchParams);
    }
  }
};