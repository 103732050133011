import { downloadExport } from '@/utils/exportExcel';
import { SelectGuide, BtnExport } from '@/components/common';
import TableOtherIncome from './components/TableOtherIncome';
import { otherIncomeList } from '@/config/report';
import { searchMixin } from '@/mixin';

// import { parseDate } from '@/utils'
// const today = parseDate(new Date(), '{y}-{m}-{d}')
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectGuide,
    TableOtherIncome,
    BtnExport
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    columnData: otherIncomeList,
    tableData: [],
    // sendDate: [today, ''],
    // endDate: [],
    total: 0,
    statistics: 0,
    searchParams: {}
  }),
  computed: {
    ...mapGetters({
      otherCollectData: 'report/index/otherCollectData'
    })
  },
  watch: {
    otherCollectData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistics = statistics;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getOtherIncomeStatic', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchOtherIncomeStaticExcel', this.searchParams).then(res => {
        const fileName = '其他收入统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};