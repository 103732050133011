import { FormAccount, SelectDicType, SelectDictionary, SwitchStatus
// SelectShopGroup,
} from '@/components/common';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
import { getSelectLabel } from '@/config/select';
export default {
  components: {
    FormAccount,
    SelectDicType,
    SwitchStatus,
    SelectDictionary
    // SelectShopGroup,
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {
        shopType: 'SHOP',
        shopTypeLabel: '购物店'
      },
      index: -1,
      validateForm: {
        name: [{
          required: true,
          message: '购物店不能为空'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      accountForm: 'resource/account/editAccountInfo',
      editSourceInfo: 'resource/common/editSourceInfo',
      shopDicData: 'resource/shopping/shopDicData'
    }),
    getTitle() {
      return this.isUpdate ? '更新购物店' : '添加购物店';
    }
  },
  methods: {
    edit(scope) {
      const {
        row
      } = scope;
      const shopTypeLabel = getSelectLabel('shopType', row.shopType);
      this.index = scope.$index;
      this.visible = true;
      this.isUpdate = true;
      this.form = {
        ...row,
        shopTypeLabel
      };
      this.$store.dispatch('resource/common/editResource', row);
    },
    show() {
      this.form = {
        shopType: 'SHOP',
        shopTypeLabel: '购物店'
      };
      this.visible = true;
      this.isUpdate = false;
      this.$store.dispatch('resource/common/editSource', {});
      this.$store.commit('resource/account/CLEAR_ACCOUNT');
    },
    hide() {
      this.form = {};
      this.visible = false;
      this.isUpdate = false;
      this.index = -1;
    },
    selectShopType(val) {
      this.form.shopTypeLabel = val.label;
      this.form.shopType = val.value;
    },
    onSelectArea(val) {
      this.form.area = val.label;
    },
    beforeSelectDicType() {
      this.$store.dispatch('resource/shopping/getTravelSuperviseeDic');
    },
    filterData(val) {
      const {
        data
      } = val;
      const opts = {
        name: data
      };
      this.$store.dispatch('resource/shopping/searchTravelSuperviseeDic', opts);
    },
    handleCreate() {
      if (!this.form.name) {
        this.$bus.tip({
          type: 'warning',
          message: '购物店名字不能为空!'
        });
      }
      const {
        id,
        groupId,
        travelAgencyId,
        realName
      } = this.userData;
      const userData = {
        createUserId: id,
        groupId,
        travelAgencyId,
        realName
      };
      const opts = {
        ...userData,
        ...this.form,
        ...this.accountForm,
        index: this.isUpdate ? this.index : -1
      };
      opts.perCapita = Number(opts.perCapita);
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/shopping/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$bus.tip();
        this.hide();
        loading.close();
      }).catch(() => {
        loading.close();
      });

      // this.$parent.createOrUpdate(opts)
      this.hide();
    }
  }
};