var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.title) + " ")];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.count) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price * scope.row.count) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.remark) + " ")];
      }
    }, {
      key: "payType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getType("payType", scope.row.cashType.toLowerCase())) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };