var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.resetForm
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "导管姓名",
      "is-require": "",
      maxlength: "20",
      "show-word-limit": ""
    },
    on: {
      blur: function ($event) {
        return _vm.validate("name", $event);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "手机号码",
      maxlength: "11",
      "show-word-limit": ""
    },
    on: {
      blur: function ($event) {
        return _vm.validate("mobileIphone", $event);
      }
    },
    model: {
      value: _vm.form.mobileIphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobileIphone", $$v);
      },
      expression: "form.mobileIphone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "身份证号",
      maxlength: "18",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.idCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "idCard", $$v);
      },
      expression: "form.idCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1), _c("div", {
    staticClass: "h10"
  }), _vm.visible ? _c("FormAccount") : _vm._e(), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      pattern: "//"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _vm.visible ? _c("DropzoneBox") : _vm._e()], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };