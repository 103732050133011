import "core-js/modules/es.array.reduce.js";
import { processAdd } from '@/utils';

// 获取已勾选金额
export function calcCheckedMoney(data, key = 'checkAccountMoney') {
  if (!data) return '';
  let lastId;
  const money = data.reduce((acc, cur) => {
    if (cur.ckStatus && !cur.checkAccountStatus && lastId != cur.rowId) {
      acc = processAdd(acc, cur[key]);
    }
    lastId = cur.rowId;
    return acc;
  }, 0);
  return `已选金额： ${money}`;
}