/* eslint-disable no-unused-vars */
// 同行接收
import { fetchReceive, fetchLog, updateReceive, updateTripOrder, updateTripOrderDetail, orderUpdateConfirm, outOrderDetailRequest, outOrderSetRequest
// orderDetail,
} from '@/api/order/receive';
import { LOG_TYPE } from '@/config';
const updateReceiveOrder = opts => {
  return updateReceive(opts).then(res => {
    const {
      data
    } = res;
    return data;
  });
};
const dfaultState = () => ({
  receiveData: null
});
const state = dfaultState();
const getters = {
  receiveData: state => state.receiveData
};
const actions = {
  // 同行接收
  fetch({
    commit
  }, params) {
    return fetchReceive(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_RECEIVE', data);
      return data;
    });
  },
  // 日志
  fetchLog({
    commit
  }, itemId) {
    const type = LOG_TYPE.receive; // type: 3 外转接收类型

    return fetchLog({
      itemId,
      type,
      subItemId: '-1',
      subType: 'localTravelAgency'
    }).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 拒绝
  refuse({
    commit
  }, id) {
    const opts = {
      id,
      statusEnum: 'REFUSE'
    };
    updateReceiveOrder(opts);
  },
  // 接受
  confirm({
    commit
  }, id) {
    const opts = {
      id,
      statusEnum: 'ACCEPT'
    };
    updateReceiveOrder(opts);
  },
  // 退回
  back({
    commit
  }, id) {
    const opts = {
      id,
      statusEnum: 'BACK'
    };
    updateReceiveOrder(opts);
  },
  // 修改申请
  orderUpdateConfirm({
    commit
  }, id) {
    return orderUpdateConfirm(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 外转订单行程
  updateTripOrder({
    commit
  }, params) {
    return updateTripOrder(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 外转订单行程 详情
  updateTripOrderDetail({
    commit
  }, id) {
    return updateTripOrderDetail(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 详情
  outOrderDetail({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      outOrderDetailRequest(params).then(res => {
        if (res && res.data) {
          resolve(res.data);
        }
      }).catch(reject);
    });
  },
  // 新增/修改
  outOrderSet({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      outOrderSetRequest(params).then(res => {
        if (res && res.data) {
          resolve(res.data);
        }
      }).catch(reject);
    });
  }
};
const mutations = {
  FETCH_RECEIVE: (state, data) => {
    state.receiveData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};