import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
// 会计科目

import { fetchSubjectListLevel, fetchSubjectList, fetchOneList, saveOrUpdate, delSubject, saveCash, fetchCash, delCash } from '@/api/system/accounting';
import { formatCreateTime, formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
import { cashFeeTypeLabel } from '@/config/billDown';
const formatList = list => {
  const listFormat = list => {
    let tmp = [];
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      item = formatCreateTime(item);
      if (item.subSubjects && item.subSubjects.length) {
        tmp.push({
          ...item,
          value: item.id,
          label: item.name,
          income: item.income || 0,
          pay: item.pay || 0,
          children: listFormat(item.subSubjects)
        });
      } else {
        tmp.push({
          ...item,
          value: item.id,
          label: item.name,
          income: item.income || 0,
          pay: item.pay || 0
        });
      }
    }
    return tmp;
  };
  return listFormat(list);
};
const dfaultState = () => ({
  subjectData: null,
  subjectWordData: null,
  oneList: null,
  cashData: null,
  cashFlowData: null
});
const state = dfaultState();
const getters = {
  subjectData: state => state.subjectData,
  subjectWordData: state => state.subjectWordData,
  oneList: state => state.oneList,
  cashData: state => state.cashData,
  cashFlowData: state => state.cashFlowData
};
const actions = {
  fetchList({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchSubjectList(opts).then(res => {
      let {
        data
      } = res;
      data = data.map(formatCreateTime);
      commit('ONELIST_DATA', data);
      return data;
    });
  },
  fetchListLevel({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchSubjectListLevel(opts).then(res => {
      if (!res || !res.data) return;
      let {
        data
      } = res;
      // data = data.map(it => {
      //   if (it.subSubjects.length) {
      //     it.subSubjects = it.subSubjects.map(formatCreateTime)
      //   }
      //   it = formatCreateTime(it)
      //   return it
      // })
      data = formatList(data);
      if (opts.subjectType && opts.subjectType == 'office') {
        commit('SUBJEC_WORD_DATA', data);
      } else {
        commit('SUBJEC_DATA', data);
      }
      return data;
    });
  },
  //获取一级会计科目列表
  fetchOneList({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchOneList(opts).then(res => {
      let {
        data
      } = res;
      // 标题拼音处理
      data = formatCharData(data);
      commit('ONELIST_DATA', data);
      return data;
    });
  },
  // 保存一级会计科目列表
  saveOrUpdate({
    commit
  }, opts) {
    return saveOrUpdate(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delSubject({
    commit
  }, opts) {
    return delSubject(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  saveCash({
    commit
  }, opts) {
    return saveCash(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchCash({
    commit
  }, opts) {
    return fetchCash(opts).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        it.cashActionName = cashFeeTypeLabel[it.cashAction];
        it = formatCreateTime(it);
        return it;
      });
      commit('FETCH_CASH', data);
      return data;
    });
  },
  fetchCashFlow({
    commit,
    dispatch
  }, opts) {
    return dispatch('fetchCash', opts).then(data => {
      commit('FETCH_CASH_FLOW', data);
      return data;
    });
  },
  delCash({
    commit
  }, opts) {
    return delCash(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  SUBJEC_DATA: (state, data) => {
    state.subjectData = data;
  },
  SUBJEC_WORD_DATA: (state, data) => {
    state.subjectWordData = data;
  },
  ONELIST_DATA: (state, data) => {
    state.oneList = data;
  },
  FETCH_CASH: (state, data) => {
    state.cashData = data;
  },
  FETCH_CASH_FLOW: (state, data) => {
    state.cashFlowData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};