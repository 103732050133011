import "core-js/modules/es.array.reduce.js";
import { tableMixin, rowProfitMixin } from '@/mixin';
export default {
  mixins: [tableMixin, rowProfitMixin],
  data() {
    return {};
  },
  methods: {
    onChecksLink(row, type) {
      this.$parent.onLink({
        row,
        type
      });
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 求和
        if (column.property != 'action' && column.property != 'customerArea') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // 如果是付账/对账总数
          if (index == 9) {
            sums[index] = `${parseInt(sums[9])}/ ${parseInt(sums[2])}`;
          } else {
            sums[index] = Number(sums[index]).toFixed(2);
          }
        }
      });
      return sums;
    }
  }
};