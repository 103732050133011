// 发团预定安排 车队
import request from '@/utils/request';

// 安排列表
export function fetchPickup(params) {
  return request({
    url: '/order/planShuttle/page',
    method: 'get',
    params
  });
}

// 安排更新
export function updatePickup(data) {
  return request({
    url: '/order/planShuttle/save',
    method: 'post',
    data
  });
}

// 安排删除
export function deletePickup(ids) {
  return request({
    url: '/order/planShuttle/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deletePickupItem(id) {
  return request({
    url: '/order/planShuttle/delType',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchPickupOrder(params) {
  return request({
    url: '/order/planShuttle/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updatePickupOrder(data) {
  return request({
    url: '/order/planShuttle/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deletePickupOrder(id) {
  return request({
    url: '/order/planShuttle/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deletePickupOrderItem(id) {
  return request({
    url: '/order/planShuttle/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchPickupOrderList(params) {
  return request({
    url: '/order/planShuttle/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectPickupOrderList(data) {
  return request({
    url: '/order/planShuttle/saveAndList',
    method: 'post',
    data
  });
}