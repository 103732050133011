import { AuditRole } from '@/config/audit';
export const itemListKeys = () => ({
  redEnvelope: 0,
  accompanyRate: 0,
  accompanyRebate: 0,
  driverRate: 0,
  driverRebate: 0,
  driverRebateDeduction: 0,
  guideCollect: 0,
  guideManagerRate: 0,
  guideManagerRebate: 0,
  guideManageRebateDeduction: 0,
  guideRate: 0,
  guideRebate: 0,
  guideRebateDeduction: 0,
  id: 0,
  money: 0,
  perCapita: 0,
  cashFlow: 0,
  name: '',
  planShopId: 0,
  shopRemark: '',
  shopRate: 0,
  shopRebate: 0,
  travelGrossProfit: 0,
  customerTripNumber: '',
  customerOrderId: ''
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  rowCount: 1,
  rowList: [],
  rate: 0,
  deduction: 0,
  rebate: 0,
  enterStoreMemberCount: 0,
  // 进店人数
  headCompanyId: 0,
  headPrice: 0,
  shopPrice: 0,
  orderDate: '',
  orderNumber: '',
  parkingFee: 0,
  remark: '',
  subsidy: '0',
  subsidyRate: '0',
  shopAgencyProfit: 0,
  shopName: '',
  shopId: '',
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});
export const RateKeyMap = ['shopRate', 'guideRate', 'driverRate', 'accompanyRate', 'guideManagerRate'];
export const RabeteKeyMap = ['money', 'shopRebate', 'guideRebate', 'driverRebate', 'accompanyRebate', 'guideManagerRebate'];
export const statisticsKeys = ['money', 'enterStoreMemberCount', 'travelGrossProfit', 'totalHeadRebate', 'shopRebate', 'guideRebateDeduction', 'driverRebateDeduction', 'driverRebate', 'accompanyRebate', 'guideRebate', 'subsidyRate', 'subsidy', 'guideManagerRebate', 'accompanyRebate', 'driverRebate', 'guideCollect'];
export const shopUrl = {
  planUrl: '/plan/team-arrange-edit/shop',
  auditUrl: '/audit/team-single-edit/shop'
};
export const MergeKeys = ['auditAndCheckStatus', 'shopName', 'orderDate', 'enterStoreMemberCount', 'headPrice', 'totalHeadRebate', 'goodsName', 'money', 'orderInfo', 'cashFlow', 'perCapita', 'travelGrossProfit', 'guideCollect', 'shopRemark', 'remark', 'auditStatus', 'action', 'audit'];