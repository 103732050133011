import { PayedColumn as columnData } from '@/config/billDown/payUnit';
import { TYPES } from '@/config/billDown';
import { setCheckStatus, getPayedMoney, getIds } from '@/views/billDown/help';
import payedMixin from '@/views/billDown/payUnit/payedMixin';
export default {
  mixins: [payedMixin],
  data() {
    return {
      columnData,
      tableData: [],
      billCheckAll: false
    };
  },
  // computed: {
  //   curItem() {
  //     return this.$store.getters['bill/billDown/payUnitHotel/curItem']
  //   },
  // },

  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        rid: resourceId,
        id: logCashId
      } = this.$route.query;
      this.fetchPayedData({
        resourceId,
        logCashId
      });
    },
    fetchPayedData(params) {
      this.$store.dispatch('bill/billDown/payUnitHotel/fetchPayed', params).then(data => {
        if (!data || !data.list) return;
        this.tableData = data.list;

        // 更新余额
        this.updateInfo();
      });
    },
    changeCheckAll(status) {
      this.setTableCheckStatus({
        status
      });
    },
    cancelSelect() {
      const ids = getIds(this.tableData);
      if (!ids || !ids.length) {
        this.$bus.tip({
          message: '请勾选要取消的数据',
          type: 'warning'
        });
        return;
      }
      this.$bus.open(() => {
        const opts = {
          cashType: TYPES.hotel,
          ids
        };
        this.$store.dispatch('bill/billDown/pay/deletePay', opts).then(() => {
          this.fetchData();
          this.billCheckAll = false;
        });
      }, '您确定要取消吗? 是否继续?');
    },
    updateInfo() {
      const userMoney = getPayedMoney(this.tableData);
      this.$store.commit('bill/billDown/SET_BILL_INPUT_USEMONEY', userMoney);
    },
    setTableCheckStatus({
      status = false
    }) {
      this.tableData = setCheckStatus(this.tableData, status);
    }
  }
};