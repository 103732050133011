// 渠道对账
import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';
export const channelListRequest = params => {
  return request({
    url: '/order/ca/pageCustomerChannel',
    method: 'get',
    params
  });
};

// 导出
export function channelExportRequest(params) {
  const url = APP.baseUrl + '/order/caExport/pageCustomerChannel';
  return requestBlob({
    url,
    params
  });
}