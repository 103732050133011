import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-empty-pattern */
import { billCheckRequest } from '@/api/group';
import { resourceListRequest, resourceListAllRequest, resourceDeleteRequest, resourceSetRequest, resourceDetailRequest } from '@/api/group';
import { fetchPayedList, addDownPay, deleteDown, delCollect, updateDownPay, fetchCashPage } from '@/api/bill/billDown';
import { orderApplyAudits, ORDER_TYPES } from '@/config/order';
import { formatCharData, setCheckStatus } from '@/utils/data';
import { parseDate } from '@/utils';
import { processMinus } from '@/utils/number';
import { TYPES } from '@/config/billDown';
import { cashLogType, cashFeeTypeLabel, cashModeTypeLabel } from '@/config/billDown';
const dfaultState = () => ({
  resourceData: null,
  resourceAllData: null,
  // 不分页数据
  billCheckData: null,
  billDownData: null,
  billDownUnPay: null,
  payedData: null
});
const state = dfaultState();
const getters = {
  resourceData: state => state.resourceData,
  resourceAllData: state => state.resourceAllData,
  billCheckData: state => state.billCheckData,
  billDownData: state => state.billDownData,
  billDownUnPay: state => state.billDownUnPay,
  payedData: state => state.payedData,
  resourceList: state => {
    return state.resourceData ? state.resourceData.list : [];
  },
  billCheckList: state => {
    return state.billCheckData ? state.billCheckData.list : [];
  },
  billDownList: state => {
    return state.billDownData ? state.billDownData.list : [];
  }
};
const actions = {
  fetchResource({
    commit
  }, params) {
    return resourceListRequest({
      ...params,
      isEnable: 1
    }).then(res => {
      if (!res || !res.data) return;
      commit('SET_DATA', {
        type: 'resourceData',
        data: res.data
      });
      return res.data;
    });
  },
  fetchAllResource({
    commit
  }) {
    return resourceListAllRequest().then(res => {
      if (!res || !res.data) return;
      commit('SET_DATA', {
        type: 'resourceAllData',
        data: formatCharData(res.data)
      });
      return res.data;
    });
  },
  setResource({}, opts) {
    return resourceSetRequest(opts);
  },
  deleteResource({
    commit
  }, id) {
    return resourceDeleteRequest({
      id
    }).then(() => {
      commit('DELETE_ITEM', id);
    });
  },
  detailResource({}, id) {
    return resourceDetailRequest({
      id
    });
  },
  // 对账列表
  fetchBillCheck({
    commit
  }, params) {
    return billCheckRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = data.list.reduce((acc, cur) => {
        const {
          details,
          resType,
          ckMoney,
          totalMoney,
          checkAccountStatus
        } = cur;

        // 状态
        cur.ckStatus = false;
        cur.isChecked = false;

        // 收支类型
        cur.payType = resType == ORDER_TYPES.shop || resType == ORDER_TYPES.fee ? '收款' : '付款';
        // 订单类型
        cur.resTypeLabel = resType != '' ? orderApplyAudits[resType.toUpperCase()] : '';

        // 对账金额
        cur.ckMoney = checkAccountStatus ? ckMoney : totalMoney;
        details.forEach(it => {
          acc.push({
            ...cur,
            item: it,
            rowId: cur.id
          });
        });
        return acc;
      }, []);
      commit('SET_DATA', {
        type: 'billCheckData',
        data
      });
      return res.data;
    });
  },
  // 对账列表
  fetchBillDownUnPay({
    commit
  }, params) {
    return billCheckRequest({
      ...params,
      planType: 0
    }).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = data.list.reduce((acc, cur) => {
        const {
          details,
          resType
          // ckMoney,
          // totalMoney,
          // checkAccountStatus,
        } = cur;

        // 状态
        cur.ckStatus = false;
        cur.isChecked = false;

        // 下账金额
        cur.downPayed = '';
        cur.owePay = cur.owePayCopy = cur.unPayOrIncome;

        // 收支类型
        cur.payType = resType == ORDER_TYPES.shop || resType == ORDER_TYPES.fee ? '收款' : '付款';
        // 订单类型
        cur.resTypeLabel = resType != '' ? orderApplyAudits[resType.toUpperCase()] : '';
        details.forEach(it => {
          acc.push({
            ...cur,
            item: it,
            rowId: cur.id
          });
        });
        return acc;
      }, []);
      commit('SET_DATA', {
        type: 'billDownUnPay',
        data
      });
      return res.data;
    });
  },
  fetchBillDown({
    commit
  }, opts) {
    return fetchCashPage(opts).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        const {
          id,
          cash,
          used,
          cashType,
          cashAction,
          cashMode,
          createTime
        } = it;
        it.rowId = id;
        it.balance = processMinus(cash, used);
        it.cashTypeLabel = cashLogType[cashType];
        it.cashActionLabel = cashFeeTypeLabel[cashAction];
        it.cashModeLabel = cashModeTypeLabel[cashMode];
        it.createTime = parseDate(createTime);
        return {
          ...it,
          ...it.plan
        };
      });
      commit('SET_DATA', {
        type: 'billDownData',
        data
      });
      return data;
    });
  },
  // 付款
  createPay({}, opts) {
    return addDownPay(opts);
  },
  // 付款
  deletePay({}, opts) {
    return deleteDown(opts);
  },
  // 删除付款
  deleteCollect({}, id) {
    return delCollect(id);
  },
  // 更新付款
  updatePayed({}, opts) {
    return updateDownPay(opts);
  },
  // 已结清
  fetchPayed({
    commit
  }, queryParam) {
    queryParam = {
      ...queryParam,
      cashType: TYPES.company
    };
    return fetchPayedList(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = setCheckStatus(data.list);
      commit('SET_DATA', {
        type: 'payedData',
        data
      });
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  },
  DELETE_ITEM: (state, id) => {
    const data = state.resourceData;
    const {
      list
    } = data;
    const index = list.findIndex(it => it.id == id);
    data.list.splice(index, 1);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};