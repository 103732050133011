import { tableMixin } from '@/mixin';
import { OrderType } from '@/config/order';
import { mallSourceMap, mallPayStatusMap, mallOrderStatusMap, mallOrderStatusTypeMap } from '@/config/orderStatus';
import DialogForm from './DialogForm';
export default {
  components: {
    DialogForm
  },
  mixins: [tableMixin],
  data() {
    return {
      mallSourceMap,
      mallPayStatusMap,
      mallOrderStatusMap,
      payed: 'SUCCESS'
    };
  },
  computed: {
    getOrderTagType() {
      return type => mallOrderStatusTypeMap[type];
    },
    isPayOrder() {
      return scope => scope.row.payStatus !== this.payed;
    }
  },
  methods: {
    handlePersonalOrder(row) {
      this.$refs.dialogRef.show(row, OrderType.personal);
    },
    handleTeamOrder(row) {
      this.$refs.dialogRef.show(row, OrderType.team);
    }
  }
};