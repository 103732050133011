import { AuditRole } from '@/config/audit';
export const itemListKeys = () => ({
  busFee: 0,
  busType: '',
  busTypeId: '',
  driverId: '',
  driverName: '',
  licenceNumber: '',
  totalPay: 0,
  otherFee: 0,
  planBusCompanyId: 0,
  startDate: '',
  endDate: '',
  driverPhone: ''
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  payType: 'guidesign',
  busCompanyName: '',
  money: 0,
  otherFee: 0,
  totalPay: 0,
  busCompanyTypeSimples: [],
  remark: '',
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});