import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditFleetDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditFleetDetail,
      planId: '',
      pickupId: ''
    };
  },
  mounted() {
    this.$bus.on('pickup:list', this.show);
    this.$bus.on('pickup:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('pickup:order', {
        planId: this.planId,
        busCompanyId: this.pickupId
      });
    },
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: pickupId
      } = params;
      this.visible = true;
      this.pickupId = pickupId;
      this.planId = planId;
      this.fetchData(pickupId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(pickupId) {
      this.$store.dispatch('plan/pickup/fetchOrder', {
        planShuttleId: pickupId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = list;
      });
    },
    reloadData() {
      this.fetchData(this.planId, this.pickupId);
    },
    // 移除
    handleRemove(id) {
      this.$store.dispatch('plan/pickup/deleteOrder', id).then(() => {
        this.fetchData(this.planId, this.pickupId);
      });
    },
    // 修改人数
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
      item.totalPay = this.calcRowTotalMoney(item);
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        price,
        count,
        otherFee
      } = obj;
      let total = price * count + Number(otherFee);
      return total;
    },
    // 保存
    handleSave() {
      this.$store.dispatch('plan/pickup/updateOrder', this.tableData).then(() => {
        this.fetchData(this.planId, this.pickupId);
      });
    }
  }
};