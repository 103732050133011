var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-button", {
    style: {
      background: _vm.color,
      borderColor: _vm.color
    },
    attrs: {
      icon: "el-icon-upload",
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("upload")) + " ")]), _c("DialogBase", {
    attrs: {
      visible: _vm.dialogVisible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-upload", {
    staticClass: "editor-slide-upload",
    attrs: {
      multiple: true,
      "file-list": _vm.fileList,
      "show-file-list": true,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      "before-upload": _vm.beforeUpload,
      "list-type": "picture-card"
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("ClickUpload")) + " ")])], 1), _c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("Cancel")) + " ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.generateTitle("Confirm")) + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };