import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary, DialogLog, SelectHotel } from '@/components/common';
import { orderHotel, TYPES } from '@/config/order';
import { resourceOrderStatusTagType, resourceOrderStatusLabel } from '@/config/resourceOrder';
import { dictionaryMixin, systemMixin, rowspanMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { mapGetters } from 'vuex';
import { processAdd, processMinus, multiplyNumber } from '@/utils/number';
import tableMixin from '../tableMixin';
import BookingResource from './BookingResource.vue';
const defaultState = () => ({
  price: '',
  count: '',
  remark: '',
  isAdd: true,
  orderDate: '',
  hotelArea: '',
  hotelName: '',
  hotelStandard: '',
  checkAccountStatus: false,
  auditProgress: 'WAITAUDIT'
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    DialogLog,
    SelectHotel,
    BookingResource
  },
  mixins: [dictionaryMixin, tableMixin, systemMixin, rowspanMixin],
  props: {
    hasBooking: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      columnData: orderHotel,
      deleteMsg: '是否确定删除此条酒店安排？',
      fetchPath: 'order/index/fetchHotel',
      deletePath: 'order/index/deleteOrHotel',
      updatePath: 'order/index/createOrHotel',
      statisticsKeys: ['amount'],
      roomInfo: {
        doubleRoom: 0,
        single: 0,
        triple: 0,
        accompany: 0,
        add: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder'
    }),
    isEdit() {
      return row => {
        if (this.getConfig()) {
          return row.checkAccountStatus || row.headCompanyId > 0;
        } else {
          return row.hotelName != '' || row.checkAccountStatus || row.headCompanyId > 0;
        }
      };
    },
    getStatus() {
      return val => {
        if (!val) return '未下单';
        return resourceOrderStatusLabel[val];
      };
    },
    getStatusType() {
      return val => {
        if (!val) {
          return resourceOrderStatusTagType['NULL'];
        }
        return resourceOrderStatusTagType[val];
      };
    },
    canEditHotel() {
      return this.getConfig();
    }
  },
  watch: {
    // 获取用房标准
    currentOrder: {
      handler: function (data) {
        this.roomInfo = data.roomInfo;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.reloadData();
      }, 500);
    });
  },
  methods: {
    getConfig() {
      const hotelEdit = this.getConfigStatus('order:arrangeHotelAllowEdit:arrangeHotelAllowEditValue');
      return hotelEdit;
    },
    saveOrderInfo() {
      const opts = this.currentOrder;
      opts.roomInfo = this.roomInfo;
      this.$store.dispatch('order/index/createOrUpdate', opts).then(() => {
        this.$bus.tip();
        this.fetchOrderInfo(this.currentOrderId);
      });
    },
    fetchOrderInfo(id) {
      this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    initData(data) {
      if (!data) return;
      this.tableData = data.map(it => {
        it.auditProgress = it.auditProgress ? it.auditProgress : AuditRole.wait;
        if (!it.checkAccountStatus) {
          it.checkAccountStatus = false;
        }
        // 判断单条是否禁用
        if (this.getConfig() && it.headCompanyId < 0) {
          if (it.checkAccountStatus || it.auditProgress != AuditRole.wait) {
            it.disabled = true;
          }
        } else {
          it.disabled = false;
        }
        return it;
      });
    },
    reloadData() {
      const {
        id
      } = this.$route.query;
      this.fetchData(id);
    },
    showBooking() {
      // this.$refs.resourceRef.show()
      this.$router.push({
        path: '/order/reserve-booking'
      });
    },
    handlePlus() {
      const {
        adultCount: count
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        count: count / 2
      });
    },
    // 选择区域/标准
    selectDic(type, row, val) {
      row[type] = val.label;
    },
    // 酒店
    selectHotel(row, val) {
      row.hotelName = val.label;
      row.hotelId = val.value;
    },
    // 输入数量
    changeInput(row) {
      const {
        price,
        count,
        freeCount,
        otherFee
      } = row;
      // row.amount = count * row.price
      // row.totalQuotePay = price * row.price
      // this.$set(row, 'amount', count * price)
      // this.$set(row, 'totalQuotePay', count * price)
      const result = processAdd(multiplyNumber(processMinus(count, freeCount || 0), price), otherFee || 0);
      row['amount'] = result;
      row['totalQuotePay'] = result;
    },
    beforeCommit(customerOrderId, type) {
      // 如果是不可编辑的酒店则去除
      let tableData = this.tableData.filter(it => !it.disabled);
      if (!this.tableData.length || !tableData.length) return [];
      return tableData.map(it => {
        it.customerOrderId = customerOrderId;
        it.customerId = this.currentOrder.customerId;
        if (type == 'clone') {
          delete it.id;
          delete it.hotelId;
          delete it.hotelName;
          it.price = 0;
          it.totalQuotePay = 0;
        }
        return it;
      });
    },
    // 获取日志
    handleLog() {
      // const { id } = this.$route.query
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.hotel
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (!row.orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '酒店信息日期不能为空!'
          });
          return false;
        }
        if (!row.hotelArea) {
          this.$bus.tip({
            type: 'warning',
            message: '酒店信息区域不能为空!'
          });
          return false;
        }
        if (!row.hotelStandard) {
          this.$bus.tip({
            type: 'warning',
            message: '酒店信息标准不能为空!'
          });
          return false;
        }
      }
      return true;
    }
  }
};