import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary, SelectInsurance, DialogLog } from '@/components/common';
import { orderInsurance, TYPES } from '@/config/order';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, rowspanMixin } from '@/mixin';
import { decimalSplit } from '@/utils';
import { AuditRole } from '@/config/audit';
import { multiplyNumber } from '@/utils/number';
import tableMixin from '../tableMixin';
const defaultState = () => ({
  remark: '',
  price: 0,
  count: 1,
  otherFee: 0,
  money: 0,
  customerOrderId: '',
  customerId: '',
  auditProgress: 'WAITAUDIT'
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    SelectInsurance,
    DialogLog
  },
  mixins: [dictionaryMixin, rowspanMixin, tableMixin],
  data() {
    return {
      teamFundType: codeType.teamFundType,
      tableData: [],
      columnData: orderInsurance,
      removeCache: [],
      // 要删除的团款项目
      statisticsKeys: ['money'],
      deleteMsg: '是否确定删除此条保险？',
      fetchPath: 'order/index/fetchInsurance',
      deletePath: 'order/index/deleteInsurance',
      updatePath: 'order/index/createOrUpdateInsurance'
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = data;
    },
    handleSelect(row, val) {
      row.insuranceId = val.id;
      row.insuranceName = val.label;
    },
    selectInsuranceType(row, val) {
      row.insuranceType = val.label;
      // row.insuranceTypeId = val.value
    },
    sumMoney(item) {
      const {
        price,
        count,
        otherFee
      } = item;
      const money = multiplyNumber(price, count) + Number(otherFee || 0);
      item.money = decimalSplit(money);
    },
    handlePlus() {
      const {
        id: customerOrderId,
        customerId
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        customerId,
        customerOrderId
      });
    },
    beforeCommit(customerOrderId, type) {
      const tableData = this.tableData.filter(it => !it.auditProgress || it.auditProgress == AuditRole.wait);
      return tableData.map(it => {
        it.customerOrderId = customerOrderId;
        it.total = it.price * it.count * it.times;
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    // 获取日志
    handleLog() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: id,
        subType: TYPES.insurance
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row.price === undefined || row.price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '保险单价不能为空！'
          });
          return false;
        }
      }
      return true;
    }
  }
};