const defaultState = {
  search: {
    icon: 'el-icon-search',
    text: '搜索',
    type: 'primary'
  },
  more: {
    icon: 'el-icon-more',
    text: '更多搜索',
    type: 'success'
  },
  create: {
    icon: 'el-icon-plus',
    text: '新增',
    type: 'primary'
  },
  create2: {
    icon: 'el-icon-plus',
    text: '新增',
    type: 'danger'
  },
  save: {
    icon: 'el-icon-check',
    text: '保存',
    type: 'primary'
  },
  save2: {
    icon: 'el-icon-check',
    text: '保存并提交审核',
    type: 'danger'
  },
  close: {
    icon: 'el-icon-close',
    text: '关闭',
    type: 'danger'
  },
  delete: {
    icon: 'el-icon-delete',
    text: '删除',
    type: 'danger',
    plain: true
  },
  reset: {
    icon: 'el-icon-refresh-left',
    text: '重置',
    type: 'danger'
  },
  export: {
    icon: 'el-icon-takeaway-box',
    text: '导出',
    type: 'success'
  },
  print: {
    icon: 'el-icon-printer',
    text: '打印单据',
    type: 'warning'
  },
  download: {
    icon: 'el-icon-bottom',
    text: '下载模版',
    type: 'primary'
  },
  upload: {
    icon: 'el-icon-upload',
    text: '上传协议',
    type: 'primary'
  },
  paste: {
    icon: 'el-icon-brush',
    text: '粘贴',
    type: 'warning'
  },
  merge: {
    icon: 'el-icon-copy-document',
    text: '拼团',
    type: 'primary'
  },
  dispatch: {
    icon: 'el-icon-s-promotion',
    text: '派单',
    type: 'danger'
  },
  next: {
    icon: 'el-icon-right',
    text: '下一步',
    type: 'primary'
  },
  text: {
    icon: '',
    text: '添加',
    type: 'text'
  },
  cancel: {
    icon: 'el-icon-refresh-right',
    text: '取消',
    type: 'warning'
  },
  back: {
    icon: 'el-icon-refresh-left',
    text: '返回',
    type: 'danger'
  },
  auditOp: {
    icon: 'el-icon-s-check',
    text: '审核',
    type: 'primary'
  },
  audit: {
    icon: 'el-icon-s-check',
    text: '审核',
    type: 'success'
  },
  checkout: {
    icon: 'el-icon-money',
    text: '结算',
    type: 'danger'
  },
  exportExcel: {
    icon: 'el-icon-upload',
    text: '导入Excel',
    type: 'primary'
  },
  setting: {
    icon: 'el-icon-setting',
    text: '设置为已安排',
    type: 'danger'
  },
  send: {
    icon: 'el-icon-position',
    text: '发送短信',
    type: 'primary'
  }
};
const btnKeys = Object.keys(defaultState);

// 判断参数是否是其中之一
function oneOf(value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}
export default {
  name: 'BtnBase',
  props: {
    type: {
      validator(value) {
        return oneOf(value, btnKeys);
      },
      default: 'search'
    }
  },
  computed: {
    getRoleValue() {
      return ['search', 'reset'].includes(this.type) ? '' : this.type;
    },
    getPlain() {
      return defaultState[this.type].plain;
    },
    getType() {
      return defaultState[this.type].type;
    },
    getIcon() {
      return defaultState[this.type].icon;
    },
    getText() {
      return defaultState[this.type].text;
    },
    gitDisabled() {
      if (!this.disabled) {
        return true;
      }
      return false;
    }
  }
};