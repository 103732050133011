import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableList from './TableList';
import DialogMerge from './DialogMerge';
import DialogTransfer from './DialogTransfer';
import DialogConfirm from './DialogConfirm';
import { findComponentsUpward, parseDate } from '@/utils';
import { departKeys, departIdKeys } from '@/config/dictionary';
import { BtnMore, BtnCreate, FooterBar, SelectProduct, SelectStaff, SelectCustom, SelectRegion, InputAgeRange, InputDepartment } from '@/components/common';
import { orderPersonal, orderPersonalNoCustom } from '@/config/order';
import { mapGetters } from 'vuex';
import { reviewerStatusType, orderType } from '@/config/order';
import { systemMixin } from '@/mixin';
import { customerInfoMixin } from '@/mixin';
import { hasCustomeAuth } from '@/utils';
const dateArr = val => parseDate(val);
export default {
  components: {
    BtnMore,
    BtnCreate,
    FooterBar,
    SelectProduct,
    SelectStaff,
    SelectCustom,
    InputAgeRange,
    TableList,
    DialogMerge,
    DialogTransfer,
    DialogConfirm,
    SelectRegion,
    InputDepartment
  },
  mixins: [systemMixin, customerInfoMixin],
  data() {
    return {
      checkedAll: false,
      link: {
        create: '/order/personal-input-create'
      },
      parentNode: null,
      visibleMore: false,
      tableData: [],
      columnData: orderPersonal,
      total: 0,
      statistics: 0,
      summaryCount: '',
      department: '',
      checkedData: {
        adultCount: 0,
        childCount: 0,
        accompanyCount: 0
      },
      createDate: [],
      form: {
        startTripDate: ''
      },
      searchParams: {
        startTripDateS: dateArr(new Date())
      },
      sendDate: [dateArr(new Date()), ''],
      extra: {
        region: [],
        department: {
          departmentId: '',
          grandsonDepartmentId: '',
          subDepartmentId: ''
        }
      },
      tableScrollLeft: 0,
      // 表格滚动位置
      configData: null
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews',
      unGroupData: 'order/personal/unGroupData'
    }),
    getTagView() {
      return this.visitedViews.find(tag => tag.path === this.$route.path);
    },
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    },
    getTagViewSearchExtra() {
      const curTag = this.getTagView;
      return curTag ? curTag.extra : this.extra;
    },
    getDepartment() {
      return this.extra.department;
    },
    getScrollLeft() {
      return this.tableScrollLeft;
    }
  },
  watch: {
    unGroupData: {
      handler: 'initData',
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data && data['unGroup']) {
          const {
            startTripDateS,
            startTripDateE,
            startCreateDate,
            endCreateDate
          } = data['unGroup'];
          this.searchParams = data['unGroup'];
          this.form.createDate = [startCreateDate, endCreateDate];
          this.sendDate = [startTripDateS, startTripDateE];
        }
      },
      immediate: true
    },
    getTagViewSearchExtra: {
      handler: function (data) {
        if (data && data['unGroup']) {
          this.extra = data['unGroup'];
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderPersonal : orderPersonalNoCustom;
  },
  async mounted() {
    const parent = findComponentsUpward(this, 'Personal');
    this.parentNode = parent[0];
    const {
      data
    } = await this.$store.dispatch('system/buttJoint/getConfig', {});
    this.configData = data;
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
        return;
      }
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics || 0;
      this.tableData = this.formatData(list);
      // 人数小计
      this.summaryCount = this.formatSummary(list);
    },
    // 未拼团
    fetchData(queryParam) {
      queryParam = {
        ...queryParam,
        isUnGroup: 1
      };
      this.$store.dispatch('order/personal/fetch', {
        type: 'unGroup',
        queryParam
      });
    },
    handleScroll() {
      const tables = document.querySelectorAll('.table-total');
      let sumEl = tables[0].querySelector('.is-scrolling-left');
      let sumBody = null;
      if (sumEl) {
        // sumEl ? (sumEl.style.overflowX = 'hidden') : null
        sumBody = sumEl.getElementsByTagName('table')[0];
      }
      let totalEl = tables[tables.length - 1];
      const tableInner = totalEl.getElementsByTagName('table')[0];
      totalEl.addEventListener('scroll', () => {
        const left = tableInner.getBoundingClientRect().x;
        const position = -left + this.getLeftPosition;
        this.tableScrollLeft = left;
        sumBody.scrollLeft = position;
      }, true);
    },
    formatData(list) {
      // 未拼团，合并同一天的数据
      const result = [];
      const mapObj = {};
      list.forEach(it => {
        // if (it.shuttles.length) {
        //   it.shuttles = it.shuttles.map(item => {
        //     item.shiftDateTime = parseTime(
        //       item.shiftDateTime,
        //       '{y}-{m}-{d} {h}:{i}'
        //     )
        //     return item
        //   })
        // }
        this.$set(it, 'checked', false);
        this.$set(it, 'orderType', orderType.personal);
        if (!mapObj[it.startTripDate]) {
          mapObj[it.startTripDate] = [];
        }
        mapObj[it.startTripDate].push(it);
      });
      Object.keys(mapObj).map(key => {
        const children = mapObj[key];
        let item = {
          date: key
        };
        this.$set(item, 'expand', true);
        item.children = children;
        result.push(item);
      });
      return result;
    },
    formatSummary(data) {
      const [audlt, child, accompany] = data.reduce((acc, cur) => {
        acc[0] += cur.adultCount;
        acc[1] += cur.childCount;
        acc[2] += cur.accompanyCount;
        return acc;
      }, [0, 0, 0]);
      return `${audlt}大${child}小${accompany}陪`;
    },
    showCreate() {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'create'
        }
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 搜索
    handleSearch() {
      this.$store.dispatch('tagsView/appendVisiteTabSearch', {
        path: this.$route.path,
        search: this.searchParams,
        extra: this.extra,
        name: 'unGroup'
      });
      this.fetchData(this.searchParams);
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeCreateDate(val) {
      let [start, end] = val;
      const startDate = start && parseDate(start);
      const endDate = end && parseDate(end);
      this.form.createDate = [startDate, endDate];
      this.searchParams.startCreateDate = startDate;
      this.searchParams.endCreateDate = endDate;
    },
    handleAge(type, val) {
      this.searchParams[type] = val;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
      departIdKeys.forEach(key => {
        this.extra.department[key] = val[key];
      });
    },
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.id;
      this.searchParams[type + 'Label'] = val.label;
    },
    // 客源地
    changeRegion(val) {
      console.log('val:', val);
      // ;[
      //   'province',
      //   'provinceId',
      //   'city',
      //   'cityId',
      //   'district',
      //   'districtId',
      // ].forEach(key => {
      //   this.form[key] = val[key]
      //   this.searchParams.source = val[key]
      // })
      const {
        city,
        district,
        province
      } = val;
      this.searchParams.source = `${province || ''}${city || ''}${district || ''}`;
      // this.extra.region = [provinceId, cityId, districtId]
    },
    // 选择客户
    changeCustomerName(val) {
      this.searchParams.customerName = val.label;
    },
    // 确认弹窗
    showConfirmDialog(id) {
      this.$refs.confirmRef.show(id);
    },
    // 处理选择全部数据
    handleCheckAllData() {
      let allList = [];
      this.tableData.forEach(it => {
        const children = it.children.filter(child => child.id);
        if (children.length) {
          allList.push(...children);
        }
      });
      // 已选中
      const checkedList = this.checkSelect();
      if (checkedList.length === allList.length) {
        this.checkedAll = true;
      }
      // 计算已选人数
      this.countPeople();
    },
    // 全选
    handleCheckAll(val) {
      // 是否开启订单审核
      const code = this.getConfigStatus('order:openOrderAudit:openOrderAuditValue');
      this.tableData.forEach(it => {
        it.children = it.children.map(item => {
          if (code) {
            if (item.addAuditStatus == reviewerStatusType.audited) {
              item.checked = val;
            }
          } else {
            item.checked = val;
          }
          return item;
        });
      });
      // 计算已选人数
      this.countPeople();
    },
    // 拼团
    handleMerge() {
      const checkedData = this.checkSelect();
      if (!checkedData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何数据',
          offset: window.innerHeight / 2
        });
        return;
      }
      this.$refs.mergeRef.show(checkedData, this.configData);
    },
    // 计算已选人数
    countPeople() {
      const checkedData = this.checkSelect();
      this.checkedData.adultCount = 0;
      this.checkedData.childCount = 0;
      this.checkedData.accompanyCount = 0;
      checkedData.forEach(it => {
        this.checkedData.adultCount += it.adultCount;
        this.checkedData.childCount += it.childCount;
        this.checkedData.accompanyCount += it.accompanyCount;
      });
    },
    checkSelect() {
      const res = [];
      this.tableData.forEach(it => {
        const children = it.children.filter(child => {
          return child.checked;
        });
        if (children.length) {
          res.push(...children);
        }
      });
      return res;
    },
    revokeTableRow(scope) {
      this.parentNode.revokeTableRow(scope);
    },
    transferRow(scope) {
      this.parentNode.transferRow(scope);
    },
    cancelTableRow(scope) {
      this.parentNode.cancelTableRow(scope);
    },
    deleteTableRow(scope) {
      this.parentNode.deleteTableRow(scope);
    },
    editTableRow(scope) {
      this.parentNode.editTableRow(scope);
    },
    showTableDetail(scope) {
      this.parentNode.showTableDetail(scope);
    },
    copyTableRow(scope) {
      this.parentNode.copyTableRow(scope);
    }
  }
};