import { Search } from './components';
import { Columns as columns } from './tableConfig';
import { DialogLog, BtnLink, FooterBar } from '@/components/common';
import { TYPES } from '@/config/audit';
import { processMinus, formatCreateTimeToList, downloadExport } from '@/utils';
import { tagViewMixin } from '@/mixin';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    Search,
    DialogLog,
    BtnLink,
    FooterBar
  },
  mixins: [tagViewMixin],
  data() {
    return {
      columns,
      tableData: [],
      checkType: TYPES.subsidy,
      statisticsTotalKeys: {
        total: 'total',
        totaled: 'totaled',
        unTotal: 'unTotal'
      },
      statisticsKeys: ['total', 'totaled', 'unTotal'],
      allCheckbox: false
    };
  },
  computed: {
    data() {
      return this.$store.getters['billCheck/refund/refundData'];
    },
    dataList() {
      return this.data ? this.data.list : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : null;
    },
    getFeeInfo() {
      return item => {
        const {
          type,
          count,
          price
        } = item;
        const total = count * price;
        return `${type}: ${count}×${price}=${total}`;
      };
    },
    getUnPay() {
      return row => {
        const {
          total,
          totaled
        } = row;
        return processMinus(total, totaled);
      };
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (d) {
          this.tableData = this.formatData(this.dataList);
        }
      },
      immediate: true
    }
  },
  created() {
    // 如果是从报表跳转过来
    const {
      query
    } = this.$route;
    if (query.id) {
      this.fetchData();
    }
  },
  methods: {
    processMinus,
    calcCheckedMoney,
    fetchData(params = {}) {
      const resourceId = this.$route.query.id || '';
      this.$store.dispatch('billCheck/refund/list', {
        resourceId,
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    formatData(data) {
      const list = formatCreateTimeToList(data, 'checkAccountTime');
      return list.map(it => {
        const {
          total,
          checkAccountMoney,
          checkAccountStatus
        } = it;
        this.$set(it, 'ckStatus', false);
        it.checkAccountMoney = checkAccountStatus ? checkAccountMoney : total;
        it.rowId = it.id;
        return it;
      });
    },
    checkAllSelect() {
      this.allCheckbox = this.tableData.every(it => it.ckStatus);
    },
    selectAllCheckbox(val) {
      this.tableData = this.tableData.map(it => {
        it.ckStatus = val;
        return it;
      });
    },
    clickAllCheckbox() {
      this.allCheckbox = !this.allCheckbox;
      this.selectAllCheckbox(this.allCheckbox);
    },
    showLog(row) {
      const {
        customerOrderId: itemId
      } = row;
      this.$store.dispatch('billCheck/index/fetchLog2', {
        itemId,
        subType: this.checkType
      }).then(data => {
        this.$refs.dialogLogRef.show(data);
      });
    },
    processData(row) {
      const {
        id,
        rowId,
        checkAccountRemark,
        unTotal: checkAccountMoney,
        checkAccountStatus
      } = row;
      return {
        auditTypeEnum: this.checkType,
        checkAccountRemark,
        checkAccountMoney,
        resourceId: rowId || id,
        status: +!checkAccountStatus
      };
    },
    handleClose() {
      this.closeSelectedTag();
    },
    // 单条对账
    handleCheckout(row) {
      const opts = this.processData(row);
      this.updateBillCheck([opts]);
    },
    // 批量对账
    handleCheck() {
      const filterList = this.tableData.filter(it => it.ckStatus);
      const optList = filterList.map(it => this.processData(it));
      this.updateBillCheck(optList);
    },
    updateBillCheck(dataList) {
      const loading = this.$bus.loading();
      return this.$store.dispatch('billCheck/index/billCheck', dataList).then(() => {
        this.$bus.tip();
        loading.close();
        this.reload();
      }).catch(err => {
        loading.close();
        this.$bus.tip({
          type: 'error',
          message: err.message
        });
      });
    },
    handleExcel(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('billCheck/refund/export', params).then(res => {
        const fileName = '返款对账.xlsx';
        downloadExport(res, fileName);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};