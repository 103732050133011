import { hasCustomerInfo } from '@/utils';
import { SelectGuide, SelectCustom, SelectStaff } from '@/components/common';
import { setSearchCache, parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectCustom,
    SelectStaff
  },
  data() {
    return {
      searchParams: {
        startTripDateS: parseDate()
      },
      sendDate: [parseDate()],
      endDate: [],
      orderDate: [],
      department: []
    };
  },
  computed: {},
  mounted() {
    this.onSearch();
  },
  methods: {
    hasCustomerAuth() {
      return hasCustomerInfo(this.$store);
    },
    onSearch() {
      this.$emit('on:search', this.searchParams);
      setSearchCache(this.$store, this.$route, this.searchParams);
    },
    // 选择日期
    changeDate(type, val) {
      const [startDate, endDate] = val;
      this.searchParams[type + 'S'] = startDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // 选择导游/客服/客户
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Id'] = value;
      this.searchParams[type + 'Label'] = label;
    },
    selectAuditStatus(val) {
      this.searchParams.auditStatus = val ? val.value + ' ' : '';
    }
  }
};