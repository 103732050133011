import { inputMixin, selectListMixin } from '@/mixin';
// import { hasFleetSelectData } from '@/utils/requestSelectData'

export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/fleet/fetchList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/fleet/fleetData'];
    }
  }

  // created() {
  //   hasFleetSelectData()
  // },

  // mounted() {
  //   if (this.isFirst && !this.data) {
  //     this.fetchData()
  //     this.isFirst = false
  //   }
  // },
};