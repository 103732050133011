import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { downloadExport } from '@/utils';
import { mailAttachementOfdRequest } from '@/api/plan';
import { parseOfdDocument, renderOfd } from '@/utils/ofd/util/ofd';
export default {
  data() {
    return {
      visible: false,
      loading: true,
      data: '',
      fileName: '',
      fileTypeObj: {
        image: ['png', 'jpg', 'pdf', 'jpeg'],
        pdf: ['pdf'],
        ofd: ['ofd']
      }
    };
  },
  computed: {
    isPicture() {
      if (!this.fileName) return;
      const index = this.fileName.lastIndexOf('.');
      const suffix = this.fileName.substring(index + 1);
      return this.fileTypeObj.image.includes(suffix);
    },
    isPdf() {
      if (!this.fileName) return;
      return this.isPdfFile(this.fileName);
    },
    isOfd() {
      if (!this.fileName) return;
      return this.isOfdFile(this.fileName);
    }
  },
  methods: {
    show(opts) {
      this.visible = true;
      this.fileName = opts.fileName;
      if (this.isOfdFile(this.fileName)) {
        this.previewOFD(opts);
      } else {
        this.showAttachment(opts);
      }
    },
    hide() {
      this.visible = false;
      this.fileName = '';
    },
    fileType(name, types) {
      const index = name.lastIndexOf('.');
      const suffix = name.substring(index + 1);
      return types.includes(suffix);
    },
    isImage(name) {
      return this.fileType(name, this.fileTypeObj.image);
    },
    isPdfFile(name) {
      return this.fileType(name, this.fileTypeObj.pdf);
    },
    isOfdFile(name) {
      return this.fileType(name, this.fileTypeObj.ofd);
    },
    previewOFD(opts) {
      mailAttachementOfdRequest(opts).then(response => {
        this.data = response;
        parseOfdDocument({
          ofd: response.data,
          //file 可以为文件链接、上传的文件对象
          success(res) {
            const divs = renderOfd(920, res[0]);
            let contentDiv = document.getElementById('OfdContent');
            contentDiv.innerHTML = '';
            for (const div of divs) {
              contentDiv.appendChild(div);
            }
          },
          fail(error) {
            console.log(error, 'getOfd');
          }
        });
      });
    },
    previewPDF(file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: 'application/pdf'
        });
        const url = URL.createObjectURL(blob);
        const pdfPreview = document.getElementById('pdfPreview');
        pdfPreview.src = url;
        pdfPreview.hidden = false; // 显示iframe
      };
      reader.readAsArrayBuffer(file);
    },
    previewImage(blob) {
      // 创建 FileReader 对象
      const reader = new FileReader();
      reader.onloadend = function () {
        // 在这里处理转换后的 Base64 数据
        const base64data = reader.result;

        // 在 img 标签中显示 Base64 图片
        const imgElement = document.getElementById('imagePreview');
        imgElement.src = base64data;
      };
      // 将 Blob 读取为 Data URL
      reader.readAsDataURL(blob);
    },
    handlePreview(data) {
      const name = this.fileName;
      if (this.isImage(name)) {
        this.previewImage(data);
      }
      if (this.isPdfFile(name)) {
        this.previewPDF(data);
      }
      if (this.isOfdFile(name)) {
        this.previewOFD(data);
      }
    },
    showAttachment(opts) {
      this.$store.dispatch('plan/index/mailAttachement', opts).then(res => {
        this.handlePreview(res.data);
        this.data = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleDownload() {
      this.$bus.tip('发票下载中...');
      downloadExport(this.data, this.fileName);
    }
  }
};