import { inputMixin } from '@/mixin';
import { getDaysBetween } from '@/utils';

// import { parseTime } from '@/utils'
// const today = parseTime(new Date(), '{y}-{m}')

/**
 * @module components/base/Input
 * @desc 日期选择器
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直布局
 * @vue-prop {Boolean} [isRequire=false] - 是否在label前面加 * 标记
 */

export default {
  name: 'InputDateRange',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    defaultDate: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      startDate: '',
      endDate: ''
    };
  },
  watch: {
    defaultDate: {
      handler: function (val) {
        const [start, end] = val;
        this.startDate = start;
        this.endDate = end;
      },
      immediate: true
    },
    startDate: {
      handler: function (val) {
        if (!val) {
          this.clearStartDate();
        }
      },
      immediate: true
    },
    endDate: {
      handler: function (val) {
        if (!val) {
          this.clearEndDate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onStarDate(date) {
      this.startDate = date || '';
      // 如果结束日期不为空而且开始日期大于结束日期,则结束日期与开始日期相等
      if (this.endDate && this.endDate < date) {
        this.endDate = date;
      }
      this.$emit('on-change', [date, this.endDate]);
    },
    onEndDate(date) {
      const remain = getDaysBetween(this.startDate, date);
      if (remain < 0) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期必须大于开始日期'
        });
        this.endDate = '';
        return;
      }
      this.endDate = date || '';
      this.$emit('on-change', [this.startDate, date]);
    },
    clearStartDate() {
      this.startDate = '';
      this.$emit('on-change', [this.startDate, this.endDate]);
    },
    clearEndDate() {
      this.endDate = '';
      this.$emit('on-change', [this.startDate, this.endDate]);
    }
  }
};