import "core-js/modules/es.array.push.js";
import { processMinus } from '@/utils/number';
import { decimalSplit } from '@/utils';
import { findSubject } from '@/utils/subject';

// 计算未收
export const calcGuideOwePay = item => {
  const {
    totalPay,
    totalPayed
  } = item;
  const res = processMinus(totalPay, totalPayed);
  return decimalSplit(res);
};

// 格式未结清数据
export const formatUnPayList = (list, key = '') => {
  const result = [];
  list.forEach(it => {
    let item = {
      ...it,
      rowId: it.id,
      isChecked: false,
      downPayed: ''
    };
    item.owePayCopy = item.owePay = calcGuideOwePay(item);
    const subList = key ? it[key] : [];
    if (subList.length) {
      subList.forEach(sub => {
        result.push({
          ...item,
          ...sub
        });
      });
    } else {
      result.push(item);
    }
  });
  return result;
};
export const substrPath = path => {
  const name = path.substring(path.lastIndexOf('/') + 1);
  return name.split('-')[0];
};
export const setSubject = path => {
  const findItem = findSubject(this.substrPath(path), this.subType);
  const obj = {};
  const {
    subjectId,
    name,
    subId,
    subName
  } = findItem;
  obj.subject = name;
  obj.subjectName = subName;
  obj.subjectId = subjectId;
  obj.subId = subId;
  return obj;
};

// 账户信息
export const selectAccount = val => {
  const {
    id,
    name,
    subId,
    subName
  } = val;
  const obj = {};
  if (subId && subName) {
    obj.subName = subName;
    obj.subjectId = subId;
    obj.psubjectId = id;
  } else {
    obj.subjectId = id;
    obj.subjectName = name;
  }
  return obj;
};