import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import FormInput from '@/views/order/components/FormInputNew';
import { orderType as OrderType, OrderAuditEmit } from '@/config/order';
import { getUserData } from '@/utils';
import { defDictionary } from '@/utils/dictionary';
const OrderPathMap = {
  [OrderType.team]: '/order/team-input-update',
  [OrderType.personal]: '/personal-input-update'
};
export default {
  components: {
    FormInput
  },
  data() {
    return {
      OrderType,
      visible: false,
      form: {}
    };
  },
  computed: {
    redirectPath() {
      const {
        orderType
      } = this.form;
      return OrderPathMap[orderType];
    }
  },
  methods: {
    show(row) {
      this.form = this.processForm(row);
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    processForm(data) {
      const dictItem = defDictionary('teamType');
      const dictProduct = defDictionary('productType');
      const productType = dictProduct ? dictProduct.label : '';
      const groupType = dictItem ? dictItem.label : '';
      const {
        orderType,
        startTripDate,
        endTripDate,
        adultCount,
        childCount,
        accompanyCount,
        elderlyCount
        // productId,
        // productName,
        // planNumber: planCustomerNumber,
      } = data;
      const {
        outCustomerName: customerName,
        outCustomerInfo
      } = data;
      const {
        id: customerId,
        contactVos
      } = outCustomerInfo;
      const contactInfo = contactVos[0];
      const obj = {};
      if (contactInfo) {
        const {
          contact,
          mobilePhone
        } = contactInfo;
        obj.customerContactName = contact;
        obj.customerContactMobileNumber = mobilePhone;
      }
      return {
        ...obj,
        ...this.getUserData(),
        region: [],
        customerId,
        customerName,
        orderType,
        groupType,
        productType,
        // planCustomerNumber,
        // productId,
        // productName,
        startTripDate,
        endTripDate,
        adultCount,
        childCount,
        accompanyCount,
        elderlyCount
      };
    },
    // 获取用户数据
    getUserData() {
      const {
        id,
        realName
      } = getUserData();
      const idKeys = ['dutyOPUserId', 'saleUserId', 'outOPUserId'];
      const nameKeys = ['dutyOPUserName', 'saleUserName', 'outOPUserName'];
      const setFormByKey = (keys, val) => {
        return keys.reduce((acc, key) => {
          acc[key] = val;
          return acc;
        }, {});
      };
      return {
        ...setFormByKey(idKeys, id),
        ...setFormByKey(nameKeys, realName)
      };
    },
    processCreate(auditStatus = OrderAuditEmit.NEW_WAIT) {
      this.$refs.formInputRef.createOrUpdate(auditStatus).then(res => {
        const {
          id,
          customerId
        } = res;

        // 跳转连接
        this.$router.push({
          path: this.redirectPath,
          query: {
            id,
            customerId,
            type: 'update',
            isNew: true
          }
        });
      });
    },
    handleConfirm() {
      this.processCreate();
    }
  }
};