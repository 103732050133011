import { TableOrder } from '@/components/common';
import { planEditSelect } from '@/config/plan';
export default {
  components: {
    TableOrder
  },
  data() {
    return {
      tableLoading: false,
      columnData: planEditSelect,
      tableData: [],
      visible: false,
      planId: '',
      fleetId: '',
      useType: ''
    };
  },
  mounted() {
    this.$bus.on('fleet:order', this.show);
  },
  methods: {
    show(params) {
      this.visible = true;
      let {
        planId,
        busCompanyId,
        useType
      } = params;
      this.planId = Number(planId);
      this.fleetId = Number(busCompanyId);
      this.useType = useType;
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/fleet/fetchFleetOrderList', params).then(data => {
        this.tableData = data;
      });
    },
    handleConfirm() {
      const filterList = this.tableData.map(it => {
        if (it.isSel) {
          const {
            customerId,
            id: orderId
          } = it;
          return {
            customerId,
            orderId,
            planId: this.planId,
            resourceId: this.fleetId,
            useType: this.useType
          };
        }
      });
      this.$store.dispatch('plan/fleet/selectFleetOrderList', filterList).then(() => {
        this.hide();
        this.$bus.tip({
          message: '操作成功'
        });
        this.$bus.emit('fleet:reload');
      });
    }
  }
};