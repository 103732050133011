var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tag", {
    attrs: {
      effect: "plain",
      type: _vm.planId > 0 ? "success" : "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.planId > 0 ? "已成团" : "新订单") + " ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };