import "core-js/modules/es.array.push.js";
import { rowspanMixin, tableMixin } from '@/mixin';
export default {
  mixins: [tableMixin, rowspanMixin],
  data: () => ({
    path: '/shopping/shop-detail-subsidy',
    pathBill: '/bill/unit-collect/shopsubsidy-detail'
  }),
  methods: {
    handleDetail(row) {
      const {
        id,
        resourceId
      } = row;
      const {
        money,
        userMoney: used
      } = row;
      // 同个页面在2个菜单下，为保证跳转后，一级菜单高亮显示
      const path = this.$route.fullPath.indexOf('/bill') > -1 ? this.pathBill : this.path;
      this.$router.push({
        path: path,
        query: {
          id,
          rid: resourceId
        }
      });
      this.$store.commit('bill/billAutoDown/SET_BALANCE_MONEY', {
        money,
        used
      });
    },
    handleDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('bill/billDown/deleteCollect', id).then(() => {
          this.$parent.fetchData();
        });
      });
    }
  }
};