import { mapGetters } from 'vuex';
export default {
  components: {},
  data: () => ({
    pwdType: 'password',
    visiblePwd: false,
    visibleSysPwd: false,
    formSupervise: {},
    formBorrow: {
      disabled: false
    },
    formSys: {},
    configList: [],
    rules: {
      name: [{
        required: true,
        message: '请填写名字'
      }],
      code: [{
        required: true,
        message: '请填写审批模板编码'
      }],
      processCode: [{
        required: true,
        message: '请填写钉钉审批流程模板编码'
      }]
    }
  }),
  computed: {
    ...mapGetters({
      GLETravelData: 'system/buttJoint/GLETravelData' // 配置信息
    }),
    getPwdType() {
      return this.visiblePwd ? '' : this.pwdType;
    },
    getSysPwdType() {
      return this.visibleSysPwd ? '' : this.pwdType;
    }
  },
  watch: {
    GLETravelData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.fetchDDConfig();
      this.fetchSysConfig();
    },
    fetchData(queryParam) {
      this.$store.dispatch('system/buttJoint/fetchGLETravel', queryParam);
    },
    fetchDDConfig() {
      this.$store.dispatch('system/systemConf/getDDConfig').then(data => {
        if (!data || !data.length) return;
        this.formBorrow = data[0];
        this.formBorrow.disabled = true;
      });
    },
    // 获取旅行社配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params).then(res => {
        if (!res || !res.data) return;
        this.configList = res.data;
      });
    },
    // 设置旅行社配置信息
    setSysConfig(item) {
      this.$store.dispatch('system/buttJoint/setConfig', [item]).then(() => {
        this.$bus.tip();
      });
    },
    handleData(data) {
      this.formSupervise = data;
    },
    // 更新字典信息
    updateDic() {
      this.$bus.open(() => {
        this.updateGLETravelDic();
      }, `更新旅监字典数据量大,时间会较久,期间不能进行其他操作,是否确认更新?`);
    },
    updateGLETravelDic() {
      const loading = this.$bus.loading();
      this.$store.dispatch('system/buttJoint/updateGLETravelDic').then(() => {
        loading.close();
        this.$bus.tip();
        this.fetchData();
      }).catch(() => {
        loading.close();
      });
    },
    saveBorrow() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const opts = {
          details: [this.formBorrow]
        };
        this.$store.dispatch('system/systemConf/setDDConfig', opts).then(() => {
          this.$bus.tip();
        });
      });
    },
    createOrUpdate() {
      const opts = this.formSupervise;
      const loading = this.$bus.loading();
      this.$store.dispatch('system/buttJoint/update', opts).then(() => {
        loading.close();
        this.$bus.tip();
        this.fetchData();
      }).catch(() => {
        loading.close();
      });
    }
  }
};