import "core-js/modules/es.array.reduce.js";
/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:10
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-18 15:31:03
 * @Description:
 */
import { hasOwn, isBackMoney } from '@/utils';
import { processMinus, processAdd } from '@/utils/number';
export default {
  computed: {
    autoStatus() {
      return this.$store.getters['bill/billDown/autoStatus'];
    },
    billInputForm() {
      return this.$store.getters['bill/billDown/billInputForm'];
    },
    InputMoney() {
      return this.billInputForm ? this.billInputForm.money : 0;
    }
  },
  methods: {
    setBillDownUseMoney(userMoney) {
      this.$store.commit('bill/billDown/SET_BILL_INPUT_USEMONEY', userMoney);
    },
    setBillDownUse({
      money,
      userMoney
    }) {
      this.$store.commit('bill/billDown/SET_BILL_INPUT', {
        ...this.billInputForm,
        money,
        userMoney
      });
    },
    isSelectedAll() {
      const checkedAll = this.tableData.every(it => it.isChecked);
      this.billCheckAll = checkedAll;
    },
    // 全选，清掉手动输入的金额，填充累计金额
    changeCheckAll(val) {
      // this.restore()

      this.tableData.forEach(it => {
        it.isChecked = val;
        it.downPayed = val ? it.owePayCopy : '';
        it.owePay = val ? 0 : it.owePayCopy;
      });
      this.$nextTick(() => {
        this.billCheckAll = val;

        // 重置下账金额
        const money = this.calcTotalMoney('downPayed');
        this.setBillDownUse({
          money,
          userMoney: money
        });
      });
    },
    cleanCheckAll() {
      this.billCheckAll = false;
    },
    // 检查金额是否还够
    checkEnoughMoney() {
      const billInputForm = this.$store.getters['bill/billDown/billInputForm'];
      const money = billInputForm.money || this.calcCheckedMoney('owePayCopy');
      const curMoney = this.calcCheckedMoney();
      return processMinus(money, curMoney);
    },
    // 处理勾选
    handleCheck(row, val) {
      const diffMoney = this.checkEnoughMoney();
      const minVal = Math.min(row.downPayed || row.owePayCopy, diffMoney);

      // 根据下账金额计算可下的金额
      row.downPayed = val ? minVal : '';
      row.owePay = val ? processMinus(row.owePayCopy, minVal) : row.owePayCopy;
      if (!row.downPayed) {
        row.isChecked = false;
        row.downPayed = '';
      }
      this.setBillDownUseMoney(this.calcCheckedMoney());
      this.isSelectedAll();
    },
    // 勾选复选框
    changeCheckbox(row, val) {
      // 如果未输入下账金额
      if (!this.InputMoney) {
        row.downPayed = row.owePayCopy;
      } else {
        this.handleCheck(row, val);
      }
      if (!row.downPayed && val) {
        this.$bus.tip({
          type: 'warning',
          message: '下账金额已下完'
        });
      }
    },
    // 修改单条输入框的金额
    changeDownPay(row, val) {
      const {
        owePayCopy
      } = row;
      const inputPay = Number(owePayCopy);
      if (!val || val == -val) {
        // 判断0
        row.isChecked = false;
        row.owePay = inputPay;
      } else {
        row.isChecked = true;
        row.downPayed = val;
        row.owePay = row.owePayCopy - val;
      }

      // this.$nextTick(() => {
      //   this.changeCheckbox(row, true)
      // })
      // 设置下账金额与余额
      this.setBillDownUseMoney(this.calcCheckedMoney());
    },
    // 销账算法修改，将原来先下负数改为从上到下，如果是下负数，只勾选负数。如果是正数则先勾选正数，遇到负数累加到下账金额上
    handleAutoDown(val = this.calcTotalMoney()) {
      let money = +val;
      const originMoney = money;

      // 如果不是自动下账不需要处理
      if (!this.autoStatus) {
        this.billCheckAll = false;
        return;
      }

      // 重置数据
      this.restore();
      const tableData = this.filterDataByRowId(this.tableData);

      // 设置金额
      const setMoney = (it, money) => {
        const isBack = hasOwn(it, 'feeType') && isBackMoney(it.feeType);
        if (isBack) {
          money *= -1;
        }
        it.downPayed = money;
        it.isChecked = true;
        it.owePay = isBack ? processAdd(it.owePayCopy, money) : processMinus(it.owePayCopy, money);
      };
      // 设置下负数
      const setNegativeMoney = (it, money) => {
        it.downPayed = money;
        it.isChecked = true;
        it.owePay = -(Math.abs(it.owePayCopy) - Math.abs(money));
      };

      /**
       * 下负数
       * 先消交回 再减支出
       **/

      // let temp = mergeBack()
      // temp = temp > 0 ? temp + this.calcNegativeMoney() : temp

      // 下负数的情况
      if (money < 0) {
        tableData.forEach(it => {
          let absMoney = Math.abs(money);
          const {
            owePay
          } = it;
          const absOwePay = Math.abs(owePay);
          // 取绝对值后，欠付金额大于下账金额，并且money还未下完
          if ((owePay < 0 || isBackMoney(it.feeType)) && money != 0) {
            if (absOwePay >= absMoney) {
              setNegativeMoney(it, money);
              money = 0;
            }
            if (absOwePay < absMoney) {
              setNegativeMoney(it, owePay);
              money = processMinus(money, owePay);
            }
          }
        });
      } else {
        /**
         * 下正数
         * 1. 先累加交回的金额
         * 2. 计算下账金额
         */
        tableData.forEach(it => {
          let {
            owePay,
            owePayCopy
          } = it;

          // 1. 先判断欠付金额
          // if (owePayCopy < 0) {
          // // 2. 判断feeType类型是支出还是交回，支出是负的是等于是交回，交回是负的等于是支出
          // if (hasOwn(it, 'feeType')) {
          //   money += owePay * -1
          // } else {
          //   money + owePay * -1
          // }
          // }
          // 下账金额大于欠付金额则全部下完，遇到负数
          if (money > 0) {
            if (money >= owePayCopy) {
              setMoney(it, owePay);
              owePayCopy < 0 ? money = processAdd(money, owePay * -1) : money = processMinus(money, owePayCopy);
            } else {
              setMoney(it, money);
              money = 0;
            }
          }
        });
      }
      // if (money < 0) {
      //   this.tableData.forEach(it => {
      //     if (isBackMoney(it.feeType)) {
      //       fillBack(it)
      //     } else {
      //       const { owePay } = it
      //       if (temp >= owePay) {
      //         setMoney(it, owePay)
      //         temp -= owePay
      //       } else if (owePay <= 0 && temp <= owePay) {
      //         setMoney(it, owePay)
      //         temp -= owePay
      //       } else {
      //         it.downPayed = ''
      //         // it.totalPayed = it.owePay
      //         it.isChecked = false
      //       }
      //     }
      //   })
      // } else {
      //   /**
      //    * 下正数
      //    * 1. 先累加交回的金额
      //    * 2. 计算下账金额
      //    */
      //   this.tableData.forEach(it => {
      //     const { owePayCopy, feeType } = it
      //     const owePay = owePayCopy

      //     if (isBackMoney(it.feeType)) {
      //       fillBack(it)
      //     } else {
      //       if (temp > owePay) {
      //         setMoney(it, owePay)

      //         temp > 0 ? (temp -= owePay) : null
      //       } else if (temp > 0 && temp <= owePay) {
      //         setMoney(it, temp)
      //         temp = 0
      //       } else {
      //         it.downPayed = isBackMoney(feeType) ? owePay : ''
      //         it.totalPayed = it.downPayed || 0
      //         it.isChecked = isBackMoney(feeType)
      //       }
      //     }
      //   })
      // }

      this.isSelectedAll();
      this.setBillDownUse({
        money: originMoney,
        userMoney: this.calcCheckedMoney()
      });
    },
    calcCheckedMoney(key = 'downPayed') {
      return this.calcTotalMoney(key);
    },
    // 计算负数
    calcNegativeMoney() {
      const list = this.tableData.filter(it => it.owePayCopy < 0 || isBackMoney(it.feeType));
      return list.reduce((acc, cur) => {
        // 判断有没有feeType,收团款没有该字段，有负数时候出错
        if (hasOwn(cur, 'feeType')) {
          acc = processAdd(acc, cur.owePay * -1);
          return acc;
        }
        return 0;
      }, 0);
    },
    // 计算总金额
    calcTotalMoney(key = 'owePayCopy') {
      const filterData = this.filterDataByRowId(this.tableData);
      return filterData.reduce((acc, cur) => {
        if (hasOwn(cur, 'feeType') && isBackMoney(cur.feeType)) {
          // 应付金额没加工处理过，已报和欠报已转为负数
          acc = processMinus(acc, cur[key] || 0);
        } else {
          acc = processAdd(acc, cur[key] || 0);
        }
        return acc;
      }, 0);
    },
    // 过滤rowId相同的数据
    filterDataByRowId(data) {
      // 使用Set和map方法进行去重
      // 过滤数据, 相同类型的数据id 是一样的，需要根据类型和id同时判断
      const filteredData = data.filter((item, index, self) => index === self.findIndex(t => (t.id === item.id || t.rowId === item.rowId) && t.type === item.type));
      return filteredData;
      // return Array.from(new Set(data.map(a => a.rowId))).map(rowId => {
      //   return data.find(a => a.rowId === rowId)
      // })
    },
    // 余额重置
    restoreBalance() {
      this.setBillDownUseMoney(0);
    },
    // 重置
    restore() {
      this.restoreBalance();
      // 重置数据
      this.tableData.forEach(it => {
        it.isChecked = false;
        it.downPayed = '';
        it.owePay = it.owePayCopy;
      });
      this.billCheckAll = false;
    }
  }
};