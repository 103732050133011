/* eslint-disable no-empty-pattern */
import { appliesRequest, weekListRequest, regimentAllRequest, regimentListRequest, exportAlipayConsumeLRequest } from '@/api/enterprise';
const dfaultState = () => ({
  weekData: [],
  applyData: null,
  regimentData: null,
  regimentAllData: null
});
const state = dfaultState();
const getters = {
  weekData: state => state.weekData,
  applyData: state => state.applyData,
  regimentData: state => state.regimentData,
  regimentAllData: state => state.regimentAllData
};
const actions = {
  weekList({
    commit
  }) {
    return weekListRequest({}).then(res => {
      if (!res) return;
      commit('SET_DATA', {
        type: 'weekData',
        data: res.data
      });
      return res.data;
    });
  },
  applyList({
    commit
  }, params) {
    return appliesRequest(params).then(res => {
      if (!res) return;
      commit('SET_DATA', {
        type: 'applyData',
        data: res.data
      });
      return res.data;
    });
  },
  regimentList({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      regimentListRequest(params).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'regimentData',
          data: res.data
        });
        resolve(res.data);
      }).catch(reject);
    });
  },
  regimentAll({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      regimentAllRequest(params).then(res => {
        if (!res) reject(res);
        commit('SET_DATA', {
          type: 'regimentAllData',
          data: res.data
        });
        resolve(res.data);
      }).catch(reject);
    });
  },
  export({}, params) {
    return new Promise((resolve, reject) => {
      exportAlipayConsumeLRequest(params).then(res => {
        resolve(res);
      }).catch(reject);
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};