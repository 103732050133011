export default {
  name: 'OutOPUsers',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isLength: {
      type: String,
      default: '1'
    },
    userId: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    getList() {
      if (this.userId && this.list.length) {
        const item = this.list.find(it => it.id == this.userId) || this.list[0];
        return [item];
      }
      return this.list.slice(0, this.isLength);
    }
  }
};