import { Message } from 'element-ui';
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

// 护照校验
export function validPassport(val) {
  const reg = /^[A-Z]\d{7}$/;
  return reg.test(val);
}

// 港澳通行证
export function validHKM(val) {
  const reg = /^[KM]\d{8}$/;
  return reg.test(val);
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
export const validateTable = (list, validateKeys) => {
  if (!list.length) {
    return false;
  }
  const keyMap = Object.keys(validateKeys);
  let status = true;
  for (let i = 0; i < list.length; i++) {
    const row = list[i];
    for (let j = 0; j < keyMap.length; j++) {
      if (!row[keyMap[j]]) {
        Message.warning({
          message: validateKeys[keyMap[j]]
        });
        status = false;
        break;
      }
    }
  }
  return status;
};

// 校验车牌号
export function isPlateNo(plateNo) {
  const re = /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5,6}$/;
  const val = plateNo.replace(/\s/g, '');
  return re.test(val);
}

// 银行卡校验
export const isAccountNumber = value => /^[1-9]\d{9,29}$/g.test(value);

// 手机校验
export const isPhone = value => {
  value = value + '';
  let reg = /^1[3|4|5|6|7|8|9][0-9]{1}\d{8}$/;
  return value.length == 11 && reg.test(value);
};

// 电话校验
export const isTelephone = value => {
  let reg = /(^(\d{3,4}-?)?(\d{7,9})$)|(^400-?(\d{3,4})-?(\d{3,4}))|(^800-?(\d{3,4})-?(\d{3,4}))/g;
  return reg.test(value);
};

// 生份证号
export const isIDCard = value => /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/g.test(value);

// 验证中文
export const isChineseCharacter = value => /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/g.test(value);

// http
export const isHttpUrl = str => {
  if (!str) return str;
  return str.indexOf('http') > -1;
};

// 是否有效数字
export const isNumber = val => {
  val = val + '';
  if (val == '') return false;
  // if (val.charAt(0) == 0) return false
  if (!val.match(/^[+-]?\d+(\.\d+)?$/)) return false;
  return true;
};