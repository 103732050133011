var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.product ? _c("div", {
    staticClass: "product-preivew"
  }, [_c("div", {
    staticClass: "title tac"
  }, [_c("h2", [_vm._v(_vm._s(_vm.product.name))]), _c("el-divider")], 1), _c("div", {
    staticClass: "field"
  }, [_c("div", {
    staticClass: "field-item"
  }, [_c("strong", [_vm._v("行程天数")]), _c("span", [_vm._v(_vm._s(_vm.product.days))])]), _c("div", {
    staticClass: "field-item"
  }, [_c("strong", [_vm._v("可见范围")]), _c("span", [_vm._v(_vm._s(_vm.getDisplayArea(_vm.product.displayType)))])]), _c("div", {
    staticClass: "field-item"
  }, [_c("strong", [_vm._v("所属系列")]), _c("span", [_vm._v(_vm._s(_vm.product.linenName))])])]), _c("div", {
    staticClass: "table-box"
  }, [_c("h3", [_vm._v("行程列表")]), _c("TableLine", {
    attrs: {
      "table-data": _vm.tableLineData,
      "column-data": _vm.columnLineData,
      loading: _vm.tableLineLoading
    }
  })], 1), _c("div", {
    staticClass: "table-box"
  }, [_c("h3", [_vm._v("客户结算价")]), _c("TableCustom", {
    attrs: {
      "table-data": _vm.tableCustomData,
      "column-data": _vm.columnCustomData,
      loading: _vm.tableCustomLoading
    }
  })], 1), _c("div", {
    staticClass: "remarks"
  }, [_c("Remarks", {
    attrs: {
      remarks: _vm.remarks
    }
  })], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };