import { dictionaryMixin, searchMixin } from '@/mixin';
import { SelectMerchantList, COMPONENT_MAP } from '../config';
import { SelectGuide } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide
  },
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      SelectMerchantList,
      sendDate: [],
      endDate: [],
      consumeDate: [parseDate()],
      searchParams: {
        resType: '',
        money: ''
      }
    };
  },
  computed: {
    data() {
      return this.$store.getters['billCheck/aliecode/aliecodeData'];
    },
    compName() {
      return COMPONENT_MAP[this.searchParams.resType];
    }
  },
  // watch: {
  //   data: {
  //     handler: function (d) {
  //       if (!d) {
  //         this.$emit('on-search', this.searchParams)
  //       }
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // 搜索
    handleSearch() {
      const params = this.searchParams;
      this.$emit('on-search', params);
    },
    // 导出
    handleExport() {
      const params = this.searchParams;
      this.$emit('on-export', params);
    }
  }
};