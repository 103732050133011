var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.hide
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      "is-require": "",
      type: "taskType",
      label: "接送类型"
    },
    on: {
      change: _vm.changeShuttleType
    },
    model: {
      value: _vm.form.shuttleType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shuttleType", $$v);
      },
      expression: "form.shuttleType"
    }
  }), _c("InputDate", {
    attrs: {
      "is-require": "",
      "value-format": "yyyy-MM-dd",
      label: "日期",
      placeholder: "选择日期"
    },
    model: {
      value: _vm.form.shuttleDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shuttleDate", $$v);
      },
      expression: "form.shuttleDate"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputNumber", {
    attrs: {
      label: "大人数",
      "controls-position": "right",
      "is-require": ""
    },
    model: {
      value: _vm.form.adultCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adultCount", $$v);
      },
      expression: "form.adultCount"
    }
  }), _c("InputNumber", {
    attrs: {
      label: "小孩数",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.childCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "childCount", $$v);
      },
      expression: "form.childCount"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_vm.form.shuttleType !== "city" ? _c("SelectBase", {
    attrs: {
      "init-val": _vm.form.taskType,
      "is-require": "",
      type: "targetType",
      label: "地点类型"
    },
    on: {
      change: _vm.selectTarget
    },
    model: {
      value: _vm.form.taskType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "taskType", $$v);
      },
      expression: "form.taskType"
    }
  }) : _c("SelectBase", {
    attrs: {
      "init-val": _vm.form.cityType,
      "is-require": "",
      type: "taskCityType",
      label: "中转类型"
    },
    on: {
      change: _vm.selectCityType
    },
    model: {
      value: _vm.form.cityType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cityType", $$v);
      },
      expression: "form.cityType"
    }
  }), _c("InputBase", {
    attrs: {
      label: _vm.form.stationType == "train" || _vm.form.stationType == "火车站" ? "车次" : "航班",
      "is-require": ""
    },
    model: {
      value: _vm.form.shiftNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shiftNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.shiftNumber"
    }
  }), _vm.form.shuttleType !== "city" ? _c("div", {
    staticClass: "input-group flex"
  }, [_c("div", {
    staticClass: "btn-box",
    staticStyle: {
      "margin-top": "22px",
      "margin-right": "10px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.btnLoading
    },
    on: {
      click: _vm.fetchTrafficTime
    }
  }, [_vm._v(" 获取时间 ")])], 1)]) : _c("InputBase", {
    attrs: {
      "is-require": "",
      label: _vm.targetType == "end_trip_back" ? "上车点" : "下车点"
    },
    model: {
      value: _vm.form.togetherPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "togetherPosition", $$v);
      },
      expression: "form.togetherPosition"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDate", {
    attrs: {
      type: "datetime",
      "is-require": "",
      label: _vm.getTargetLabel,
      placeholder: "选择时间"
    },
    on: {
      change: _vm.changeStationDateTime
    },
    model: {
      value: _vm.form.shiftDateTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shiftDateTime", $$v);
      },
      expression: "form.shiftDateTime"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "stationType",
      "is-require": "",
      label: "站点"
    },
    on: {
      "on-select": _vm.selectPosition
    },
    model: {
      value: _vm.form.desPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desPosition", $$v);
      },
      expression: "form.desPosition"
    }
  })], 1), _vm.form.shuttleType !== "city" ? _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "始发站点"
    },
    model: {
      value: _vm.form.startPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startPosition", $$v);
      },
      expression: "form.startPosition"
    }
  }), _c("InputBase", {
    attrs: {
      label: "终点站点"
    },
    model: {
      value: _vm.form.endPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endPosition", $$v);
      },
      expression: "form.endPosition"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectFleet", {
    attrs: {
      label: "车辆公司"
    },
    on: {
      "on-select": _vm.handleSelectBus
    },
    model: {
      value: _vm.form.busCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "busCompanyName", $$v);
      },
      expression: "form.busCompanyName"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "busType",
      label: "车辆类型"
    },
    on: {
      "on-select": _vm.selectBusType
    },
    model: {
      value: _vm.form.busType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "busType", $$v);
      },
      expression: "form.busType"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-group flex"
  }, [_c("InputBase", {
    attrs: {
      label: "车费"
    },
    model: {
      value: _vm.form.busFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "busFee", $$v);
      },
      expression: "form.busFee"
    }
  })], 1), _c("div", {
    staticClass: "input-group flex"
  }, [_c("InputBase", {
    attrs: {
      label: "其他成本"
    },
    model: {
      value: _vm.form.otherFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherFee", $$v);
      },
      expression: "form.otherFee"
    }
  })], 1), _c("SelectBase", {
    attrs: {
      label: "付款方式",
      type: "collectType"
    },
    on: {
      "on-select": _vm.selectPayType
    },
    model: {
      value: _vm.form.payTypeName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "payTypeName", $$v);
      },
      expression: "form.payTypeName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-group flex"
  }, [_c("InputBase", {
    attrs: {
      label: "酒店名称"
    },
    model: {
      value: _vm.form.hotelName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelName", $$v);
      },
      expression: "form.hotelName"
    }
  })], 1), _c("InputBase", {
    attrs: {
      label: "酒店地址"
    },
    model: {
      value: _vm.form.hotelAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelAddress", $$v);
      },
      expression: "form.hotelAddress"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)]) : _vm._e(), _c("DialogStation", {
    ref: "dialogStationRef",
    on: {
      "on-select": _vm.selectStation
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };