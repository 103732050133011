import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 下账管理
import Layout from '@/layout';
import BlankLayout from '@/layout/blank';
const billRouter = {
  path: '/bill',
  component: Layout,
  redirect: '/bill/welcome',
  name: 'bill',
  meta: {
    title: '下账管理',
    icon: 'icon-shenpishenhe'
  },
  children: [{
    path: '/bill/welcome',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/welcome/index'))),
    name: 'billWelcome',
    // 欢迎页
    meta: {
      title: 'billWelcome',
      icon: 'icon-zhuye',
      auth: true
    },
    orderNumber: -1
  }, {
    path: '/bill/regiment-collect',
    name: 'billRegimentCollect',
    // 按团号去收款
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectRegiment'))),
    meta: {
      title: 'billRegimentCollect',
      icon: 'icon-puhuoweituo'
    },
    redirect: '/bill/regiment-collect/customer',
    children: [{
      path: '/bill/regiment-collect/customer',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectRegiment/customer'))),
      name: 'billRegimentCollectCustomer',
      // 收客户
      meta: {
        title: 'billRegimentCollectCustomer',
        icon: 'icon-puhuoweituo'
      }
    }, {
      path: '/bill/regiment-collect/customer-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectRegiment/customer/create'))),
      name: 'billRegimentCollectCustomerCreate',
      // 新增客户
      meta: {
        title: 'billRegimentCollectCustomerCreate'
      },
      hidden: true
    }]
  },
  // 按单位收款
  {
    path: '/bill/unit-collect',
    name: 'billDownUnit',
    // 按单位去收款
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectUnit'))),
    meta: {
      title: 'billDownUnit',
      icon: 'icon-puhuoweituo'
    },
    children: [{
      path: '/bill/unit-collect/customer',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectUnit/customer'))),
      name: 'billCollect',
      // 列表
      meta: {
        title: 'billCollect',
        icon: 'icon-huozhuguanli',
        auth: true
      }
    }, {
      path: '/bill/unit-collect/team-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectUnit/customer/create'))),
      name: 'billCollectCreate',
      meta: {
        title: 'billCollectCreate',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectUnit/customer/update'))),
      name: 'billCollectUpdate',
      meta: {
        title: 'billCollectUpdate',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/collectUnit/customer/detail'))),
      name: 'billCollectDetail',
      meta: {
        title: 'billCollectDetail',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/shop',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect'))),
      name: 'billCollectShop',
      // 购物收款
      meta: {
        title: 'billCollectShop',
        icon: 'icon-bangongshichengben',
        auth: true
      }
    }, {
      path: '/bill/unit-collect/shop-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect/create'))),
      name: 'billCollectShopCreate',
      // 购物收款
      meta: {
        title: 'billCollectShopCreate',
        icon: 'icon-bangongshichengben',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/shop-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect/detail'))),
      name: 'billCollectShopDetail',
      // 购物收款
      meta: {
        title: 'billCollectShopDetail',
        icon: 'icon-bangongshichengben',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/shopsubsidy',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy'))),
      name: 'billCollectShopSubsidy',
      // 购物二返收款
      meta: {
        title: 'billCollectShopSubsidy',
        icon: 'icon-chexiaoshenqingdanguanli',
        auth: true
      }
    }, {
      path: '/bill/unit-collect/shopsubsidy-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy/create'))),
      name: 'billCollectShopSubsidyCreate',
      // 购物二返收款
      meta: {
        title: 'billCollectShopSubsidyCreate',
        icon: 'icon-chexiaoshenqingdanguanli',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-collect/shopsubsidy-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy/detail'))),
      name: 'billCollectShopSubsidyDetail',
      // 购物二返收款
      meta: {
        title: 'billCollectShopSubsidyDetail',
        icon: 'icon-chexiaoshenqingdanguanli',
        auth: true
      },
      hidden: true
    }]
  }, {
    path: '/bill/other-income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/otherIncome'))),
    name: 'billOtherIncome',
    // 其他收入核销
    meta: {
      title: 'billOtherIncome',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/bill/other-income-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/otherIncome/create'))),
    name: 'billOtherIncomeCreate',
    // 其他收入核销
    meta: {
      title: 'billOtherIncomeCreate',
      icon: 'icon-bangongshichengben',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/other-pay',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/otherPay'))),
    name: 'billOtherPay',
    // 其他支出核销
    meta: {
      title: 'billOtherPay',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/bill/other-pay-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/otherPay/create'))),
    name: 'billOtherPayCreate',
    // 其他支出核销
    meta: {
      title: 'billOtherPayCreate',
      icon: 'icon-bangongshichengben',
      auth: true
    },
    hidden: true
  },
  // 按团号付款
  {
    path: '/bill/regiment-pay',
    name: 'billRegimentPay',
    // 按团号去付款
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment'))),
    meta: {
      title: 'billRegimentPay',
      icon: 'icon-credit-card'
    },
    children: [{
      path: '/bill/regiment-pay/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/scenic'))),
      name: 'billRegimentPayScenic',
      // 付景区列表
      meta: {
        title: 'billRegimentPayScenic',
        icon: 'icon-lvyoufabu',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/scenic-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/scenic/create'))),
      name: 'billRegimentPayScenicCreate',
      // 付款页面
      meta: {
        title: 'billRegimentPayScenicCreate',
        icon: 'icon-lvyoufabu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/hotel',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/hotel'))),
      name: 'billRegimentPayHotel',
      // 付酒店
      meta: {
        title: 'billRegimentPayHotel',
        icon: 'icon-changguanyuyue',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/hotel-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/hotel/create'))),
      name: 'billRegimentPayHotelCreate',
      // 新增酒店销账
      meta: {
        title: 'billRegimentPayHotelCreate',
        icon: 'icon-changguanyuyue',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/fleet',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/fleet'))),
      name: 'billRegimentPayFleet',
      // 付车队
      meta: {
        title: 'billRegimentPayFleet',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/fleet-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/fleet/create'))),
      name: 'billRegimentPayFleetCreate',
      // 新增车队
      meta: {
        title: 'billRegimentPayFleetCreate',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/pickup',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/pickup'))),
      name: 'billRegimentPayPickup',
      // 付接送
      meta: {
        title: 'billRegimentPayPickup',
        icon: 'icon-jiejifuwu',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/pickup-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/pickup/create'))),
      name: 'billRegimentPayPickupCreate',
      // 新增接送
      meta: {
        title: 'billRegimentPayPickupCreate',
        icon: 'icon-jiejifuwu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/restaurant'))),
      name: 'billRegimentPayRestaurant',
      // 付餐厅
      meta: {
        title: 'billRegimentPayRestaurant',
        icon: 'icon-canting',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/restaurant-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/restaurant/create'))),
      name: 'billRegimentPayRestaurantCreate',
      // 新增餐厅
      meta: {
        title: 'billRegimentPayRestaurantCreate',
        icon: 'icon-canting',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/traffic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/traffic'))),
      name: 'billRegimentPayTraffic',
      // 付交通
      meta: {
        title: 'billRegimentPayTraffic',
        icon: 'icon-jiejifuwu',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/traffic-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/traffic/create'))),
      name: 'billRegimentPayTrafficCreate',
      // 新增交通
      meta: {
        title: 'billRegimentPayTrafficCreate',
        icon: 'icon-jiejifuwu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/insurance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/insurance'))),
      name: 'billRegimentPayInsurance',
      // 付保险
      meta: {
        title: 'billRegimentPayInsurance',
        icon: 'icon-insurance',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/insurance-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/insurance/create'))),
      name: 'billRegimentPayInsuranceCreate',
      // 新增保险
      meta: {
        title: 'billRegimentPayInsuranceCreate',
        icon: 'icon-insurance',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/reception',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/reception'))),
      name: 'billRegimentPayReception',
      // 付地接
      meta: {
        title: 'billRegimentPayReception',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/reception-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/reception/create'))),
      name: 'billRegimentPayReceptionCreate',
      // 新增地接
      meta: {
        title: 'billRegimentPayReceptionCreate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/regiment-pay/customer',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/customer'))),
      name: 'billRegimentPayCustomer',
      // 付客户
      meta: {
        title: 'billRegimentPayCustomer',
        icon: 'icon-lvyoutehui',
        auth: true
      }
    }, {
      path: '/bill/regiment-pay/customer-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payRegiment/customer/create'))),
      name: 'billRegimentPayCustomerCreate',
      // 新增客户
      meta: {
        title: 'billRegimentPayCustomerCreate',
        auth: true
      },
      hidden: true
    }]
  },
  // 按单位付款
  {
    path: '/bill/unit-pay',
    component: BlankLayout,
    name: 'billUnitPay',
    // 按单位去付款
    meta: {
      title: 'billUnitPay',
      icon: 'icon-credit-card'
    },
    redirect: '/bill/unit-pay/fleet',
    children: [{
      path: '/bill/unit-pay/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/scenic'))),
      name: 'billUnitPayScenic',
      // 付景区
      meta: {
        title: 'billUnitPayScenic',
        icon: 'icon-lvyoufabu',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/scenic-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/scenic/create'))),
      name: 'billUnitPayScenicCreate',
      // 付景区创建
      meta: {
        title: 'billUnitPayScenicCreate',
        icon: 'icon-lvyoufabu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/scenic-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/scenic/update'))),
      name: 'billUnitPayScenicUpdate',
      // 付景区更新
      meta: {
        title: 'billUnitPayScenicUpdate',
        icon: 'icon-lvyoufabu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/scenic-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/scenic/detail'))),
      name: 'billUnitPayScenicDetail',
      // 付景区详情
      meta: {
        title: 'billUnitPayScenicDetail',
        icon: 'icon-lvyoufabu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/hotel',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/hotel'))),
      name: 'billUnitPayHotel',
      // 付酒店列表
      meta: {
        title: 'billUnitPayHotel',
        icon: 'icon-changguanyuyue',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/hotel-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/hotel/create'))),
      name: 'billUnitPayHotelCreate',
      // 新增酒店
      meta: {
        title: 'billUnitPayHotelCreate',
        icon: 'icon-changguanyuyue',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/hotel-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/hotel/update'))),
      name: 'billUnitPayHotelUpdate',
      // 修改酒店
      meta: {
        title: 'billUnitPayHotelUpdate',
        icon: 'icon-changguanyuyue',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/hotel-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/hotel/detail'))),
      name: 'billUnitPayHotelDetail',
      // 酒店明细
      meta: {
        title: 'billUnitPayHotelDetail',
        icon: 'icon-changguanyuyue',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/fleet',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/fleet'))),
      name: 'billUnitPayFleet',
      // 付车队
      meta: {
        title: 'billUnitPayFleet',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/fleet-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/fleet/create'))),
      name: 'billUnitPayFleetCreate',
      // 付车队
      meta: {
        title: 'billUnitPayFleetCreate',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/fleet-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/fleet/update'))),
      name: 'billUnitPayFleetUpdate',
      // 付车队
      meta: {
        title: 'billUnitPayFleetUpdate',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/fleet-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/fleet/detail'))),
      name: 'billUnitPayFleetDetail',
      // 付车队详情
      meta: {
        title: 'billUnitPayFleetDetail',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/pickup',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/pickup'))),
      name: 'billUnitPayPickup',
      // 付接送
      meta: {
        title: 'billUnitPayPickup',
        icon: 'icon-jiejifuwu',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/pickup-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/pickup/create'))),
      name: 'billUnitPayPickupCreate',
      // 付接送
      meta: {
        title: 'billUnitPayPickupCreate',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/pickup-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/pickup/update'))),
      name: 'billUnitPayPickupUpdate',
      // 付接送
      meta: {
        title: 'billUnitPayPickupUpdate',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/pickup-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/pickup/detail'))),
      name: 'billUnitPayPickupDetail',
      // 付接送明细
      meta: {
        title: 'billUnitPayPickupDetail',
        icon: 'icon-lvyoubaochedingdan',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/restaurant'))),
      name: 'billUnitPayRestaurant',
      // 付餐厅
      meta: {
        title: 'billUnitPayRestaurant',
        icon: 'icon-canting',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/restaurant-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/restaurant/create'))),
      name: 'billUnitPayRestaurantCreate',
      // 付餐厅
      meta: {
        title: 'billUnitPayRestaurantCreate',
        icon: 'icon-canting',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/restaurant-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/restaurant/update'))),
      name: 'billUnitPayRestaurantUpdate',
      // 付餐厅更新
      meta: {
        title: 'billUnitPayRestaurantUpdate',
        icon: 'icon-canting',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/restaurant-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/restaurant/detail'))),
      name: 'billUnitPayRestaurantDetail',
      // 付餐厅明细
      meta: {
        title: 'billUnitPayRestaurantDetail',
        icon: 'icon-canting',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/traffic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/traffic'))),
      name: 'billUnitPayTraffic',
      // 付交通
      meta: {
        title: 'billUnitPayTraffic',
        icon: 'icon-jiejifuwu',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/traffic-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/traffic/create'))),
      name: 'billUnitPayTrafficCreate',
      // 付交通
      meta: {
        title: 'billUnitPayTrafficCreate',
        icon: 'icon-jiejifuwu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/traffic-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/traffic/update'))),
      name: 'billUnitPayTrafficUpdate',
      // 付交通
      meta: {
        title: 'billUnitPayTrafficUpdate',
        icon: 'icon-jiejifuwu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/traffic-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/traffic/detail'))),
      name: 'billUnitPayTrafficDetail',
      // 付交通
      meta: {
        title: 'billUnitPayTrafficDetail',
        icon: 'icon-jiejifuwu',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/insurance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/insurance'))),
      name: 'billUnitPayInsurance',
      // 付保险
      meta: {
        title: 'billUnitPayInsurance',
        icon: 'icon-insurance',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/insurance-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/insurance/create'))),
      name: 'billUnitPayInsuranceCreate',
      // 付保险
      meta: {
        title: 'billUnitPayInsuranceCreate',
        icon: 'icon-insurance',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/insurance-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/insurance/update'))),
      name: 'billUnitPayInsuranceUpdate',
      // 付保险
      meta: {
        title: 'billUnitPayInsuranceUpdate',
        icon: 'icon-insurance',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/insurance-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/insurance/detail'))),
      name: 'billUnitPayInsuranceDetail',
      // 付保险
      meta: {
        title: 'billUnitPayInsuranceDetail',
        icon: 'icon-insurance',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/reception',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/reception'))),
      name: 'billUnitPayReception',
      // 付地接
      meta: {
        title: 'billUnitPayReception',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/reception-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/reception/create'))),
      name: 'billUnitPayReceptionCreate',
      // 付地接
      meta: {
        title: 'billUnitPayReceptionCreate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/reception-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/reception/update'))),
      name: 'billUnitPayReceptionUpdate',
      // 付地接
      meta: {
        title: 'billUnitPayReceptionUpdate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/reception-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/reception/detail'))),
      name: 'billUnitPayReceptionDetail',
      // 付地接
      meta: {
        title: 'billUnitPayReceptionDetail',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/customer',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/customer'))),
      name: 'billUnitPayCustomer',
      // 付其他
      meta: {
        title: 'billUnitPayCustomer',
        icon: 'icon-lvyoutehui',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/customer-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/customer/create'))),
      name: 'billUnitPayCustomerCreate',
      // 付其他
      meta: {
        title: 'billUnitPayCustomerCreate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/customer-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/customer/update'))),
      name: 'billUnitPayCustomerUpdate',
      // 付其他
      meta: {
        title: 'billUnitPayCustomerUpdate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/customer-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/customer/detail'))),
      name: 'billUnitPayCustomerDetail',
      // 付其他
      meta: {
        title: 'billUnitPayCustomerDetail',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/other-pay',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/other'))),
      name: 'billUnitPayOther',
      // 付其他
      meta: {
        title: 'billUnitPayOther',
        icon: 'el-icon-rank',
        auth: true
      }
    }, {
      path: '/bill/unit-pay/other-create',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/other/create'))),
      name: 'billUnitPayOtherCreate',
      // 付其他
      meta: {
        title: 'billUnitPayOtherCreate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/other-update',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/other/update'))),
      name: 'billUnitPayOtherUpdate',
      // 付其他
      meta: {
        title: 'billUnitPayOtherUpdate',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }, {
      path: '/bill/unit-pay/other-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/payUnit/other/detail'))),
      name: 'billUnitPayOtherDetail',
      // 付其他
      meta: {
        title: 'billUnitPayOtherDetail',
        icon: 'icon-jizhongkongzhitai',
        auth: true
      },
      hidden: true
    }]
  }, {
    path: '/bill/advance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/advance'))),
    name: 'billAdvance',
    // 按预付款
    meta: {
      title: 'billAdvance',
      icon: 'icon-gongsiguanli'
    }
  }, {
    path: '/bill/company',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown'))),
    name: 'billCompany',
    // 按公司去下账
    meta: {
      title: 'billCompany',
      icon: 'icon-gongsiguanli'
    }
  },
  // {
  //   path: '/bill/other-collect',
  //   component: () => import(/* webpackChunkName: "billDown" */ '@/views/billDown'),
  //   name: 'billOtherIncome', // 其他收入核销
  //   meta: { title: 'billOtherIncome', icon: 'icon-shangwuhetong1' },
  // },
  // {
  //   path: '/bill/other-pay',
  //   component: () => import(/* webpackChunkName: "billDown" */ '@/views/billDown'),
  //   name: 'billOtherPay', // 其他支出核销
  //   meta: { title: 'billOtherPay', icon: 'icon-bangongshichengben' },
  // },
  // {
  //   path: '/bill/custom-proxy',
  //   component: () => import(/* webpackChunkName: "billDown" */ '@/views/billDown'),
  //   name: 'billProxy', // 客户代收核销
  //   meta: { title: 'billProxy', icon: 'icon-renyuanguanli' },
  // },
  // {
  //   path: '/bill/custom-pay',
  //   component: () => import(/* webpackChunkName: "billDown" */ '@/views/billDown'),
  //   name: 'billRefund', // 客户返款核销
  //   meta: { title: 'billRefund', icon: 'icon-weituokehuxinxiguanli' },
  // },
  {
    path: '/bill/guide-borrow',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideBorrow'))),
    name: 'billGuideBorrow',
    // 导游单团借款
    meta: {
      title: 'billGuideBorrow',
      icon: 'icon-xunjiaguanli'
    }
  }, {
    path: '/bill/guide-borrow/approve',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideBorrow/approve'))),
    name: 'billGuideBorrowApprove',
    // 审批
    meta: {
      title: 'billGuideBorrowApprove',
      icon: 'icon-xunjiaguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/guide-borrow/approve-batch',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideBorrow/approveBatch'))),
    name: 'billGuideBorrowApproveBatch',
    // 审批
    meta: {
      title: 'billGuideBorrowApproveBatch',
      icon: 'icon-xunjiaguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/guide-audit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideSingle'))),
    name: 'billGuideSingle',
    // 导游单团报账
    meta: {
      title: 'billGuideSingle',
      icon: 'icon-shenqingdaoyou-01'
    }
  }, {
    path: '/bill/guide-audit-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideSingle/create'))),
    name: 'billGuideSingleDetail',
    // 导游单团报账详情
    meta: {
      title: 'billGuideSingleDetail',
      icon: 'icon-shenqingdaoyou-01',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/guide-audit-batch',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideBatch'))),
    name: 'billGuideBatch',
    // 导游批量报账
    meta: {
      title: 'billGuideBatch',
      icon: 'icon-jinbizhuanchu'
    }
  }, {
    path: '/bill/guide-audit-batch-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/guideBatch/create'))),
    name: 'billGuideBatchDetail',
    // 导游批量报账详情
    meta: {
      title: 'billGuideBatchDetail',
      icon: 'icon-shenqingdaoyou-01',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/pre-collect-pay',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown'))),
    name: 'billPreCollect',
    // 预收付款管理
    meta: {
      title: 'billPreCollect',
      icon: 'icon-credit-card'
    }
  }, {
    path: '/bill/cash-diray',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/cashDiray'))),
    name: 'billCash',
    // 现金日记流水
    meta: {
      title: 'billCash',
      icon: 'icon-zijinchizhangbumingxichaxun'
    }
  }, {
    path: '/bill/account',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/account'))),
    name: 'billAccount',
    // 账户管理
    meta: {
      title: 'billAccount',
      icon: 'icon-credit-card'
    }
  }, {
    path: '/bill/account-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/accountDetail'))),
    name: 'billAccountDetail',
    // 账户详情
    meta: {
      title: 'billAccountDetail',
      icon: 'icon-credit-card',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/group',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/group'))),
    name: 'billGroup',
    // 集团销账
    meta: {
      title: 'billGroup',
      icon: 'icon-zuzhixinxi'
    }
  }, {
    path: '/bill/group-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/group/create'))),
    name: 'billGroupCreate',
    // 集团销账
    meta: {
      title: 'billGroupCreate',
      icon: 'icon-zuzhixinxi',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/group-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/group/detail'))),
    name: 'billGroupDetail',
    // 集团销账
    meta: {
      title: 'billGroupDetail',
      icon: 'icon-zuzhixinxi',
      auth: true
    },
    hidden: true
  }, {
    path: '/bill/group-update',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billDown/group/update'))),
    name: 'billGroupUpdate',
    // 集团销账
    meta: {
      title: 'billGroupUpdate',
      icon: 'icon-zuzhixinxi',
      auth: true
    },
    hidden: true
  }]
};
export default billRouter;