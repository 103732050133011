import "core-js/modules/es.array.push.js";
import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditHotelDetail, planEditHotelDetailAll } from '@/config/plan';
import { permissionMixin, rowspanMixin } from '@/mixin';
const itemListKeys = () => ({
  accompanyCount: '',
  accompanyPrice: '',
  adultCount: '',
  adultFreeCount: '',
  childCount: '',
  childPrice: '',
  freeTotal: '',
  otherFee: '',
  price: '',
  sellPrice: '',
  settlementPrice: '',
  ticketList: null,
  ticketType: ''
});
const defaultState = () => ({
  rowId: 0,
  // 这个id 作用是合并数据
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  planId: 0,
  hotelId: 0,
  hotelOTickets: [],
  totalPay: 0,
  remark: '',
  ...itemListKeys()
});
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin, permissionMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: [],
      deletePath: 'plan/hotel/delete',
      headMergeNum: 5,
      tailMergeNum: planEditHotelDetail.length - 1,
      // 数表格的格子数
      hasMergeTail: true,
      planId: '',
      hotelId: '',
      orderDate: ''
    };
  },
  mounted() {
    this.$bus.on('hotel:list', this.show);
    this.$bus.on('hotel:reload', this.reloadData);
    setTimeout(() => {
      this.columnData = this.hasPermission('hotelFloorPrice') ? planEditHotelDetailAll : planEditHotelDetail;
    }, 100);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('hotel:order', {
        planId: this.planId,
        hotelId: this.hotelId,
        orderDate: this.orderDate
      });
    },
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    show(params) {
      this.visible = true;
      const {
        currentPlanId: planId,
        id: hotelId,
        orderDate
      } = params;
      this.planId = planId;
      this.hotelId = hotelId;
      this.orderDate = orderDate;
      this.fetchData(planId, hotelId, orderDate);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planId, hotelId, orderDate) {
      this.$store.dispatch('plan/hotel/fetchOrder', {
        planId,
        hotelId,
        orderDate
      }).then(data => {
        const {
          list
        } = data;
        this.originTableData = list;
        this.tableData = this.formatData(list);
        this.rowspan();
        this.calcItemTotal();
      });
    },
    reloadData() {
      this.fetchData(this.planId, this.hotelId, this.orderDate);
    },
    // 添加订单
    handleAddOrder(data = this.originTableData) {
      this.tableData = this.formatData(data);
      this.rowspan();
    },
    // 移除
    handleRemove(scope) {
      let {
        row
      } = scope;
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/hotel/deleteOrder', row.planHotelOId).then(() => {
        loading.close();
        this.fetchData(this.planId, this.hotelId);
      }).catch(() => {
        loading.close();
      });
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        rowId,
        itemIndex,
        ticketList
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...defaultState(),
          hotelPlanId: this.rowId,
          itemIndex: ++index,
          ticketList,
          rowId
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.rowspan();
    },
    selectType(row, val) {
      const {
        label,
        quotePrice,
        price
      } = val;
      row.restaurantType = label;
      row.price = quotePrice;
      row.settlementPrice = price;
    },
    beforeSelectType(row, val) {
      if (!val) return;
      if (!row.hotelRoomList || !row.hotelRoomList.length) {
        this.$store.dispatch('resource/hotel/fetchAgreementTypeList', this.hotelId).then(data => {
          if (!data) return;
          const {
            contractPrices
          } = data[0];
          const roomList = this.formatRoomOptions(contractPrices);
          if (!roomList.length) {
            this.showNotice();
            return;
          }
          row.hotelRoomList = roomList;
        });
      }
    },
    formatRoomOptions(list) {
      if (!list) return;
      return list.map(it => {
        return {
          label: it.contentType,
          id: it.id,
          value: it.id,
          quotePrice: it.quotedPrice
        };
      });
    },
    // 选择景点协议项目
    selectTicketType(scope, val) {
      const {
        row,
        $index: index
      } = scope;
      const findItem = row.ticketList.find(it => it.ticketType == val);
      ['accompanyCount', 'adultCount', 'childCount', 'price'].forEach(key => {
        this.tableData[index][key] = findItem[key];
      });
      this.$nextTick(() => {
        row.totalPay = this.calcRowTotalMoney(row);
      });
    },
    // 修改人数
    changeCount(item, value) {
      if (value < 0) {
        this.$bus.tip({
          type: 'warning',
          message: '输入数量必须大于0'
        });
        return;
      }
      this.changePrice(item, value);
    },
    // 计算总额
    calcRowTotalMoney(row) {
      const {
        quotePrice,
        count,
        freeCount,
        times,
        otherFee
      } = row;
      return quotePrice * (Number(count) - Number(freeCount || 0)) * times + Number(otherFee);
    },
    // 保存
    handleSave() {
      const mergeData = [...this.tableData, ...this.removeTableData];
      const dataList = this.beforeCommit(mergeData);
      this.$store.dispatch('plan/hotel/updateOrder', dataList).then(() => {
        this.fetchData(this.planId, this.hotelId);
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.orderHotelORooms || [];
        const item = {
          ...it,
          rowId: it.id,
          planHotelId: it.id,
          isCustom: false,
          isChecked: false,
          hotelRoomList: []
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...v,
              itemIndex: i,
              ...item
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }
        // 同步id
        this.rowId++;
        // 计算总额
        this.totalMoney += Number(it.totalPay);
      });
      return result;
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'planHotelId'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 定义子项目的列表
        it.hotelRoomList = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 重新还原该条数据的id
        it.id = it.planHotelId;
        it.cashType = it.cashType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planHotelId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.hotelRoomList.push(item);
        } else {
          it.hotelRoomList.push(item);
          result.push(it);
        }
        last = it;
      });
      return result;
    }
  }
};