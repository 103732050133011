import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// import { isBackMoney } from '@/utils'
import { parseTime } from '@/utils';
import { initPageSize } from '@/utils/tagView';
import { CustomerInfo } from '@/components/common';
import { downBillGuidePayed as columnData } from '@/config/billDown';
export default {
  components: {
    CustomerInfo
  },
  data() {
    return {
      columnData,
      billCheckAll: false,
      tableData: [],
      total: 0,
      statisticsKeys: ['money'],
      cacheList: []
    };
  },
  computed: {
    getRowMoney() {
      return row => {
        const money = Number(row.money || 0);
        return money;
      };
    }
  },
  mounted() {
    initPageSize(this.$store);
    this.fetchData();
  },
  methods: {
    fetchData(params) {
      const {
        rid: resourceId,
        planId,
        id: logCashId
      } = this.$route.query;
      this.fetchPayedData({
        ...params,
        guideId: resourceId,
        planId,
        logCashId
      });
    },
    fetchPayedData(params = {}) {
      this.$store.dispatch('bill/billDown/fetchPayedGuide', params).then(data => {
        if (!data) return;
        this.total = data.totalCount;
        this.tableData = this.formatData(data.list);
      });
    },
    formatData(list) {
      if (!list) return [];
      return list.map(it => {
        it.money = it.cash;
        it.cashDate = parseTime(it.cashDate);
        it.isChecked = false;
        return it;
      });
    },
    handlePage(params) {
      this.fetchData(params);
    },
    changeCheckbox(row, val) {
      const {
        id
      } = row;
      val ? this.cacheList.push(id) : this.cacheList = this.cacheList.filter(val => val != id);
    },
    changeCheckAll(val) {
      this.tableData.forEach(it => {
        it.isChecked = val;
      });
      val ? this.cacheList = this.tableData.map(it => it.id) : [];
    },
    handleCancel(id) {
      const ids = [id];
      this.$store.dispatch('bill/billDown/deleteDown', ids).then(() => {
        this.$emit('reload');
        this.fetchData();
      });
    },
    batchCancel() {
      if (!this.cacheList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何数据'
        });
        return;
      }
      this.$store.dispatch('bill/billDown/deleteDown', this.cacheList).then(() => {
        this.billCheckAll = false;
        this.$emit('reload');
        this.fetchData();
      });
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        const values = data.map(item => {
          // 只合计设定的字段
          if (this.statisticsKeys.includes(column.property)) {
            if (item.feeType !== '支出') {
              return -item[column.property];
            } else {
              return item[column.property];
            }
          }
        });
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              const res = (Number(prev) * 100 + Number(curr) * 100) / 100;
              return res;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    }
  }
};