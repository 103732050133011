var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ticket-input-create form-fix-bar"
  }, [_c("CollapseBase", {
    attrs: {
      title: "行程",
      "is-strong": "",
      "auto-hide": true
    }
  }, [_c("DetailPlanTrip", {
    attrs: {
      "plan-id": _vm.planId
    }
  })], 1), _c("el-divider"), _c("TableCount", {
    attrs: {
      "table-data": _vm.tableCountData
    }
  }), _c("h3", [_vm._v("签单录入")]), _c("TicketAdd", {
    on: {
      "on-add": _vm.handleNew
    }
  }), _c("TableTicketAudit", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.ticketAuditList,
      loading: _vm.tableLoading
    },
    on: {
      "on-check": _vm.handleCheckOne,
      "on-del": _vm.handleRemove
    }
  }), _c("div", {
    staticClass: "tar"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.handleCheckAll
    },
    model: {
      value: _vm.checkAll,
      callback: function ($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }), _c("span", {
    staticClass: "pr5"
  }), _vm.tableData.length ? _c("BtnBase", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleRemoveAll
    }
  }, [_vm._v(" 删除全部 ")]) : _vm._e()], 1), _c("FooterBar", {
    attrs: {
      slot: "btn"
    },
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "save",
      disabled: _vm.getBtnStatus
    },
    on: {
      click: _vm.batchUpdate
    }
  }, [_vm._v(" 保存更新 ")]), _c("BtnBase", {
    attrs: {
      type: _vm.getAuditBtnType
    },
    on: {
      click: _vm.handleAudit
    }
  }, [_vm._v(" " + _vm._s(_vm.getAuditBtnText) + " ")])], 1), _c("DialogPlan", {
    ref: "dialogPlan"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };