// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".base-input {\n  position: relative;\n  max-width: 1200px;\n}\n.base-input .money {\n  position: absolute;\n  right: -220px;\n  top: 45px;\n  width: 200px;\n  color: #606266;\n  font-size: 12px;\n}\n.base-input .money strong {\n  font-size: 16px;\n}", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "170px",
	"topNavHeight": "56px",
	"labelSize": "12px",
	"red": "#c03639",
	"green": "#30b08f"
};
module.exports = exports;
