var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-fix-bar supervise-travel"
  }, [_vm.isError ? _c("el-alert", {
    attrs: {
      title: _vm.errMsg,
      type: "error",
      effect: "dark"
    }
  }) : _vm._e(), _c("h2", {
    staticClass: "blue mb20"
  }, [_vm._v("基本信息")]), _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      size: "small",
      "label-width": "125px",
      inline: ""
    }
  }, [_c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "组团社",
      prop: "groupAgencyName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.groupAgencyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupAgencyName", $$v);
      },
      expression: "form.groupAgencyName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "组团社联系人",
      prop: "groupAgencyManager"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.groupAgencyManager,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupAgencyManager", $$v);
      },
      expression: "form.groupAgencyManager"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "组团社联系电话",
      prop: "groupAgencyPhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.groupAgencyPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupAgencyPhone", $$v);
      },
      expression: "form.groupAgencyPhone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "线路名称",
      prop: "routeName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.routeName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "routeName", $$v);
      },
      expression: "form.routeName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客源地",
      prop: "clientSourcePlace"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.clientSourcePlace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "clientSourcePlace", $$v);
      },
      expression: "form.clientSourcePlace"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "团队类型",
      prop: "teamType"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "ETeamType",
      label: ""
    },
    model: {
      value: _vm.form.teamType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "teamType", $$v);
      },
      expression: "form.teamType"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "行程时间",
      prop: "beginDate"
    }
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.execDate,
      label: ""
    },
    on: {
      "on-change": _vm.changeExecDate
    }
  })], 1)]), _c("FormItemBase", {
    attrs: {
      label: "游玩天数",
      prop: "totalDays"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.totalDays,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalDays", $$v);
      },
      expression: "form.totalDays"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "车队",
      prop: "transportCompanyInfoName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.transportCompanyInfoName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "transportCompanyInfoName", $$v);
      },
      expression: "form.transportCompanyInfoName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "车队许可证",
      prop: "transportCompanyLicenceNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.transportCompanyLicenceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "transportCompanyLicenceNumber", $$v);
      },
      expression: "form.transportCompanyLicenceNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "到达车次/班次",
      prop: "arrivalTrainNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.arrivalTrainNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "arrivalTrainNumber", $$v);
      },
      expression: "form.arrivalTrainNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "接团地点",
      prop: "receptionGroupAddr"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.receptionGroupAddr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "receptionGroupAddr", $$v);
      },
      expression: "form.receptionGroupAddr"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "离开车次/班次",
      prop: "departureTrainNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.departureTrainNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "departureTrainNumber", $$v);
      },
      expression: "form.departureTrainNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "送团地点",
      prop: "sendGroupAddr"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.sendGroupAddr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sendGroupAddr", $$v);
      },
      expression: "form.sendGroupAddr"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "车牌号",
      prop: "vehicleInfoNumber"
    }
  }, [_c("el-input", {
    attrs: {
      label: "",
      onkeyup: "form = { ...form, vehicleInfoNumber: value.replace(/[\\s]/g,'') }"
    },
    model: {
      value: _vm.form.vehicleInfoNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vehicleInfoNumber", $$v);
      },
      expression: "form.vehicleInfoNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "座位数",
      prop: "seatingCapacity"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.seatingCapacity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "seatingCapacity", $$v);
      },
      expression: "form.seatingCapacity"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "司机",
      prop: "driverName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.driverName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "driverName", $$v);
      },
      expression: "form.driverName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "司机电话",
      prop: "driverPhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.driverPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "driverPhone", $$v);
      },
      expression: "form.driverPhone"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "司机身份证号",
      prop: "driverIdCardNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.driverIdCardNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "driverIdCardNumber", $$v);
      },
      expression: "form.driverIdCardNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "司机从业资格证号",
      prop: "driverCertNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.driverCertNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "driverCertNumber", $$v);
      },
      expression: "form.driverCertNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "总人数",
      prop: "totalCustomer"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.totalCustomer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalCustomer", $$v);
      },
      expression: "form.totalCustomer"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "其中儿童人数",
      prop: "childNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.childNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "childNumber", $$v);
      },
      expression: "form.childNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "全陪",
      prop: "totalServerName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.totalServerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalServerName", $$v);
      },
      expression: "form.totalServerName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "全陪电话",
      prop: "totalServerPhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.totalServerPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalServerPhone", $$v);
      },
      expression: "form.totalServerPhone"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "是否为学生团",
      prop: "isStudentGroup"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "radio",
      label: ""
    },
    model: {
      value: _vm.form.isStudentGroup,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isStudentGroup", $$v);
      },
      expression: "form.isStudentGroup"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "是否购买保险",
      prop: "isInsurancePurchased"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "radio",
      label: ""
    },
    model: {
      value: _vm.form.isInsurancePurchased,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isInsurancePurchased", $$v);
      },
      expression: "form.isInsurancePurchased"
    }
  })], 1), _vm.form.isInsurancePurchased ? [_c("FormItemBase", {
    attrs: {
      label: "保险公司名称",
      prop: "insuranceCompanyName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.insuranceCompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "insuranceCompanyName", $$v);
      },
      expression: "form.insuranceCompanyName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "保险险种",
      prop: "insuranceType"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.insuranceList,
      callback: function ($$v) {
        _vm.insuranceList = $$v;
      },
      expression: "insuranceList"
    }
  }, _vm._l(_vm.insuranceMenu, function (item) {
    return _c("el-checkbox", {
      key: item.value,
      attrs: {
        value: item.value,
        label: item.label
      }
    });
  }), 1)], 1)] : _vm._e()], 2), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "行程类型",
      prop: "remark"
    }
  }, [_c("SelectBase", {
    attrs: {
      "init-val": _vm.form.contractNo,
      type: "superviseIteraryType",
      label: ""
    },
    on: {
      "on-select": _vm.selectItineraryType
    },
    model: {
      value: _vm.form.contractNo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contractNo", $$v);
      },
      expression: "form.contractNo"
    }
  })], 1), _vm.form.contractNo == "inbound" ? [_c("FormItemBase", {
    attrs: {
      label: "地接社",
      prop: "recAgencyName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.recAgencyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "recAgencyName", $$v);
      },
      expression: "form.recAgencyName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "地接社联系电话",
      prop: "recAgencyPhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.recAgencyPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "recAgencyPhone", $$v);
      },
      expression: "form.recAgencyPhone"
    }
  })], 1)] : _vm._e()], 2), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea",
      label: ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "餐饮信息",
      prop: "cateringMsg"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea",
      label: ""
    },
    model: {
      value: _vm.form.cateringMsg,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cateringMsg", $$v);
      },
      expression: "form.cateringMsg"
    }
  })], 1)], 1), _c("el-alert", {
    attrs: {
      title: "旅监平台查询不到导游的团队将无法上传！",
      type: "error",
      effect: "dark"
    }
  }), _c("h2", {
    staticClass: "blue mb20 mt20"
  }, [_vm._v("导游信息")]), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "导游",
      prop: "guideName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.guideName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideName", $$v);
      },
      expression: "form.guideName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游电话",
      prop: "guideLicencePhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.guideLicencePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideLicencePhone", $$v);
      },
      expression: "form.guideLicencePhone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游证号",
      prop: "guideLicenceCode"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.guideLicenceCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideLicenceCode", $$v);
      },
      expression: "form.guideLicenceCode"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "所属公司",
      prop: "guideCompany"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.guideCompany,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideCompany", $$v);
      },
      expression: "form.guideCompany"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "上团时间",
      prop: "guideGroupBeginTim"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.guideGroupBeginTim,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideGroupBeginTim", $$v);
      },
      expression: "form.guideGroupBeginTim"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "下团时间",
      prop: "guideGroupEndTime"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.guideGroupEndTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideGroupEndTime", $$v);
      },
      expression: "form.guideGroupEndTime"
    }
  })], 1)], 1)], 1), _c("TableItineraryForm", {
    ref: "tineraryRef",
    staticClass: "mt20",
    attrs: {
      data: _vm.form.route.itineraryOptList
    }
  }), _c("el-alert", {
    attrs: {
      title: "旅监平台游客手机号为必填项，这里过滤了无号码的游客，如果要上传游客，请补上手机号！",
      type: "error",
      effect: "dark"
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.form.customerList,
      "column-data": _vm.columnTourist,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "idType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.$idType) + " ")];
      }
    }, {
      key: "idNumber",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          class: {
            "is-error": !_vm.isNumberError(scope.row)
          },
          model: {
            value: scope.row.idNumber,
            callback: function ($$v) {
              _vm.$set(scope.row, "idNumber", $$v);
            },
            expression: "scope.row.idNumber"
          }
        })];
      }
    }, {
      key: "birthDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.birthDate) + " ")];
      }
    }, {
      key: "birthPlace",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          model: {
            value: scope.row.birthPlace,
            callback: function ($$v) {
              _vm.$set(scope.row, "birthPlace", $$v);
            },
            expression: "scope.row.birthPlace"
          }
        })];
      }
    }, {
      key: "phoneNumber",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          model: {
            value: scope.row.phoneNumber,
            callback: function ($$v) {
              _vm.$set(scope.row, "phoneNumber", $$v);
            },
            expression: "scope.row.phoneNumber"
          }
        })];
      }
    }])
  }), _c("FooterBar", [_vm.form.itineraryReceptionId ? [_c("BtnBase", {
    on: {
      click: _vm.handleUpdate
    }
  }, [_vm._v(" 更新 ")]), _c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印 ")]), !_vm.loadSuccess ? _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.reFetch
    }
  }, [_vm._v(" 重新获取旅监数据 ")]) : _vm._e()] : _c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpload
    }
  }, [_vm._v(" 上传 ")]), _vm.isError ? _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleFix
    }
  }, [_vm._v(" 修复 ")]) : _vm._e(), _vm.isReload ? _c("BtnBase", {
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.reloadData
    }
  }, [_vm._v(" 重新加载数据 ")]) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };