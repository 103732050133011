export const StatisticsKeys = ['totalFee', 'totalCollect', 'totalIncomed', 'totalSubsidy', 'totalSubsidyed', 'unTotal', 'unTotalSubsidy', 'unTotalFee'];
// export const StatisticsKeys = {
//   totalFee: 'totalFee',
//   totalCollect: 'totalCollect',
//   totalIncomed: 'totalIncomed',
//   totalSubsidy: 'totalSubsidy',
//   totalSubsidyed: 'totalSubsidyed',
//   unTotal: 'unTotal',
//   unTotalSubsidy: 'unTotalSubsidy',
//   unTotalFee: 'unTotalFee',
// }