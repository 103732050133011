import TableList from './TableList';
import DialogCaptain from './DialogCaptain2';
import DialogCaptainDetail from './DialogCaptainDetail';
import { BtnCreate, DialogLog } from '@/components/common';
import { resourceGuideCaptain } from '@/config/resource';
import { searchMixin } from '@/mixin';
export default {
  components: {
    BtnCreate,
    TableList,
    DialogCaptain,
    DialogCaptainDetail,
    DialogLog
  },
  mixins: [searchMixin],
  data() {
    return {
      tableData: [],
      columnData: resourceGuideCaptain,
      searchParams: {
        name: ''
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('resource/guideCaptain/fetchGuideTeam', queryParam).then(res => {
        const {
          list
        } = res;
        this.tableData = list;
      });
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    initData() {
      this.fetchData(this.searchParams);
    },
    handleCreate() {
      this.$refs.dialogRef.show();
    },
    editTableRow(scope) {
      this.$refs.dialogRef.edit(scope);
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    deleteRow(id) {
      this.$store.dispatch('resource/guideCaptain/delete', id).then(() => {
        this.$bus.tip();
        this.initData();
      });
    },
    showTableDetail(scope) {
      const {
        id
      } = scope.row;
      const opts = {
        id
      };
      this.$refs.detailRef.show(opts);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/guideCaptain/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};