var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-scroll-bar",
    style: _vm.getBarWidth
  }, [_c("div", {
    staticClass: "bar-inner",
    style: _vm.getDragBarWidth
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };