// 按单位 车队
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  guide,
  guideBill,
  orderArrangeRemark,
  downPayed,
  actionItem
} = ColumnObj;
const lastItem = [orderArrangeRemark, {
  attrs: {
    prop: 'total',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'totaled',
    label: '已付'
  }
}, {
  attrs: {
    prop: 'owePay',
    label: '欠付'
  }
}, downPayed, actionItem];

// 列表
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '客户'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, ...lastItem];