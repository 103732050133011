var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableTpl", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      total: _vm.total,
      title: "景区佣金"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };