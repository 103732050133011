const planCustomerNumber = {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': 120
  }
};
const orderName = {
  attrs: {
    prop: 'orderNumber',
    label: '流水号'
  }
};
const orderOpMain = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
};
const collectMode = {
  attrs: {
    prop: 'cashWay',
    label: '收款方式'
  }
};
const collectDate = {
  attrs: {
    prop: 'cashDate',
    label: '收款日期',
    'min-width': 120
  }
};
const collecter = {
  attrs: {
    prop: 'cashUserName',
    label: '收款人'
  }
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100,
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
};
const busInfo = {
  attrs: {
    prop: 'busInfo',
    label: '车辆信息',
    'min-width': 140
  },
  slot: 'busInfo'
};
export const ColumnObj = {
  tripDate: {
    attrs: {
      prop: 'date',
      label: '行程日期',
      'min-width': 120
    },
    slot: 'tripDate'
  },
  orderDate: {
    attrs: {
      prop: 'orderDate',
      label: '订单日期'
    }
  },
  auditStatus: {
    attrs: {
      prop: 'auditStatus',
      label: '审核状态',
      'min-width': 90
    },
    slot: 'auditStatus'
  },
  orderInfo: {
    attrs: {
      prop: 'planInfo',
      label: '订单信息',
      'min-width': 120
    },
    slot: 'planInfo'
  },
  guideBill: {
    attrs: {
      prop: 'guidesBill',
      label: '报账导游',
      'min-width': 120
    },
    slot: 'guidesBill'
  },
  guide: {
    attrs: {
      prop: 'guides',
      label: '团上导游',
      'min-width': 160
    },
    slot: 'guides'
  },
  price: {
    attrs: {
      prop: 'price',
      label: '单价'
    }
  },
  payed: {
    attrs: {
      prop: '',
      label: '已付'
    },
    children: [{
      attrs: {
        prop: 'guidePay',
        label: '导游现付'
      }
    }, {
      attrs: {
        prop: 'agencyPay',
        label: '社付'
      }
    }]
  },
  orderOther: {
    attrs: {
      prop: 'otherFee',
      label: '其他成本'
    }
  },
  orderArrangeRemark: {
    attrs: {
      prop: 'remark',
      label: '安排备注'
    }
  },
  orderTotalPay: {
    attrs: {
      prop: 'totalPay',
      label: '应付'
    }
  },
  owePay: {
    attrs: {
      prop: 'owePay',
      label: '欠付'
    }
  },
  downPayed: {
    attrs: {
      prop: 'downPayed',
      label: '本次下账',
      'min-width': 100
    },
    slot: 'downPayed'
  },
  busInfo,
  actionItem
};
export const PayUnitColumn = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    'min-width': 120
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  },
  slot: 'customerTripNumber'
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '收款日期',
    width: 100
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账号',
    'min-width': 220
  }
}, {
  attrs: {
    prop: 'userMoney',
    label: '销账金额'
  }
}, {
  attrs: {
    prop: 'balance',
    label: '余额'
  }
}, {
  attrs: {
    prop: 'invoiceMoney',
    label: '发票金额'
  }
}, {
  attrs: {
    prop: 'invoiceNumber',
    label: '发票编码'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '付款人'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, ColumnObj.actionItem];
export const PayedColumn = [planCustomerNumber, orderName, orderOpMain, {
  attrs: {
    prop: 'startTripDate',
    label: '订单日期'
  }
}, collectMode, collectDate, collecter, {
  attrs: {
    prop: 'cash',
    label: '金额'
  }
}, actionItem];