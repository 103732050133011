import { inputMixin } from '@/mixin';

/**
 * @module components/common/Input
 * @desc 行程天数 input 组件
 * @vue-prop {Boolean}} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean}} [isRequire=false] - 是否必填项
 */

export default {
  name: 'InputDay',
  mixins: [inputMixin],
  data: () => ({
    inputDay: '',
    inputNight: ''
  }),
  methods: {}
};