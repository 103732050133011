import { searchMixin } from '@/mixin';
import { COLUMNS as columns } from './tableConfig';
import { StatisticsKeys } from './config';
import { SelectGuide } from '@/components/common';
import { getCurTagView } from '@/utils/tagView';
import { downloadExport } from '@/utils';
export default {
  components: {
    SelectGuide
  },
  mixins: [searchMixin],
  data() {
    return {
      columns,
      StatisticsKeys,
      sendDate: [],
      endDate: [],
      consumeCreateDate: [],
      searchParams: {
        searchType: 2 // 1 明细（默认） 2 按团统计
      }
    };
  },
  computed: {
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    },
    data() {
      return this.$store.getters['enterprise/report/regimentData'];
    },
    tableData() {
      return this.data ? this.formatData(this.data.list) : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      return this.data ? this.data.statistics : null;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startTripDateS,
          startTripDateE
        } = data;
        const {
          endTripDateS,
          endTripDateE
        } = data;
        const {
          consumeCreateDateS,
          consumeCreateDateE
        } = data;
        this.searchParams = data;
        this.sendDate = [startTripDateS, startTripDateE]; // 发团日期
        this.endDate = [endTripDateS, endTripDateE]; // 酒店发团日期
        this.consumeCreateDate = [consumeCreateDateS, consumeCreateDateE]; // 支付日期
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('enterprise/report/regimentList', {
        ...this.searchParams,
        ...params
      });
    },
    formatData(data) {
      if (!data || !data.length) return;
      return data.map((it, idx) => {
        it.seqNumber = idx + 1;
        return it;
      });
    },
    onChangeDate(type, val) {
      const [start, end] = val;
      this.searchParams[type + 'S'] = start;
      this.searchParams[type + 'E'] = end;
    },
    handleExport() {
      // const loadding = this.$bus.loading()

      // const ele = document.getElementById('exportTable')
      // exportTable(ele, '企业码付按团统计', '企业码付按团统计', 1)

      // loadding.close()
      this.$store.dispatch('enterprise/report/export', {
        ...this.searchParams
      }).then(res => {
        downloadExport(res, '企业码付按团统计.xlsx');
      });
    }
  }
};