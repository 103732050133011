import "core-js/modules/es.array.push.js";
import Facilities from './components/Facilities';
import { SelectDictionary, FormAccount, SelectDicType, SelectHotelGroup, SelectRegion } from '@/components/common'; // StickyBar
import { resourceRoomType } from '@/config/resource';
import { isPhone, isTelephone, splitPath } from '@/utils';
import { mapGetters } from 'vuex';
import { formMixin, tagViewMixin } from '@/mixin';
import { getToken } from '@/utils/cookie';
import { uploadUrl, OSS } from '@/config';
const defaultState = () => ({
  is: false,
  quantity: '',
  // 早餐厅数量
  area: '',
  // 早餐厅面积，单位平方米
  capacity: '' // 早餐厅容纳人数
});
const defaultHotel = () => ({
  hotelName: '',
  tripleRoom: 'none',
  standard: '',
  countRoom: '',
  telphone: '',
  faxNumber: '',
  drillGrade: '',
  stoppage: '',
  contact: '',
  weChat: '',
  mobilePhone: '',
  position: '',
  address: '',
  hotelAddr: '',
  remarks: '',
  id: ''
});
export default {
  components: {
    // StickyBar,
    Facilities,
    FormAccount,
    SelectDictionary,
    SelectDicType,
    SelectHotelGroup,
    SelectRegion
  },
  mixins: [formMixin, tagViewMixin],
  data() {
    // 校验手机
    const checkPhone = val => {
      if (!val || !isPhone(val)) return false;
      return true;
    };
    // 校验电话
    const checkTelephone = val => {
      if (!isTelephone(val)) return false;
      return true;
    };
    return {
      getToken,
      isUpdate: false,
      hotelRegionType: 'hotelRegionType',
      areaText: '',
      // 所属区域 格式 区域 + ‘|’ + id
      hotelStandardType: 'hotelStandardType',
      hotelStandardText: '',
      // 酒店标准  标准 + ‘|’ + id
      initVal: ['310000', '310100', '310105'],
      uploadUrl,
      loading: false,
      tableLoading: false,
      columnData: resourceRoomType,
      tableData: [],
      form: defaultHotel(),
      addrObj: {},
      picPaths: [],
      // 上传图片地址
      // 显示的图片地址
      fileList: [],
      formBreakfast: defaultState(),
      formDinner: defaultState(),
      formWestern: defaultState(),
      formMetting: defaultState(),
      breakfast: {
        isBreakfast: false,
        breakfastQuantity: '',
        // 早餐厅数量
        breakfastArea: '',
        // 早餐厅面积，单位平方米
        breakfastCapacity: '' // 早餐厅容纳人数
      },
      dinner: {
        isDinner: false,
        dinnerQuantity: '',
        // 早餐厅数量
        dinnerArea: '',
        // 早餐厅面积，单位平方米
        dinnerCapacity: '' // 早餐厅容纳人数
      },
      western: {
        isWestern: false,
        westernQuantity: '',
        // 早餐厅数量
        westernArea: '',
        // 早餐厅面积，单位平方米
        westernCapacity: '' // 早餐厅容纳人数
      },
      meeting: {
        isMeeting: false,
        meetingQuantity: '',
        // 早餐厅数量
        meetingArea: '',
        // 早餐厅面积，单位平方米
        meetingCapacity: '' // 早餐厅容纳人数
      },
      validateForm: {
        telphone: [{
          validate: checkTelephone,
          message: '前台号格式不正确'
        }],
        faxNumber: [{
          validate: checkTelephone,
          message: '传真号格式不正确'
        }],
        mobilePhone: [{
          validate: checkPhone,
          message: '手机号码格式不正确'
        }]
      },
      hotelCodeList: []
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 用户信息
      editAccount: 'resource/account/editAccountInfo',
      // 账户信息
      hotelDetail: 'resource/hotel/hotelDetail',
      // 酒店信息
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData',
      // 字典信息
      hotelDicData: 'resource/hotel/hotelDicData'
    })
  },
  watch: {
    hotelDetail: {
      handler: function (data) {
        this.form = defaultHotel();
        if (!data) return;
        const {
          provinceId,
          cityId,
          districtId
        } = data;
        this.isUpdate = true;
        this.form = data;
        this.form.address = [];
        if (provinceId) {
          this.form.address[0] = provinceId + '';
          this.form.address[1] = cityId + '';
          this.form.address[2] = districtId + '';
        }

        // 早餐厅
        this.formBreakfast.is = data.isBreakfast; // 是否有
        this.formBreakfast.area = data.breakfastArea; //面积
        this.formBreakfast.capacity = data.breakfastCapacity; //容纳人数
        this.formBreakfast.quantity = data.breakfastQuantity; // 餐厅数量
        // 中餐厅
        this.formDinner.is = data.isDinner; // 是否有
        this.formDinner.area = data.dinnerArea; //面积
        this.formDinner.capacity = data.dinnerCapacity; //容纳人数
        this.formDinner.quantity = data.dinnerQuantity; // 餐厅数量
        // 西餐厅
        this.formWestern.is = data.isWestern;
        this.formWestern.area = data.westernArea; //面积
        this.formWestern.capacity = data.westernCapacity; //容纳人数
        this.formWestern.quantity = data.westernQuantity; // 餐厅数量
        // 会议室
        this.formMetting.is = data.isMeeting;
        this.formMetting.area = data.meetingArea; //面积
        this.formMetting.capacity = data.meetingCapacity; //容納人數
        this.formMetting.quantity = data.meetingQuantity; //会议室數量

        this.$store.dispatch('resource/account/editAccount', data);
      },
      immediate: true
    }
  },
  created() {
    // 如果有id就是编辑更新，需获取数据
    const {
      id
    } = this.$route.query;
    if (id) {
      this.fetchData(id);
    } else {
      this.$store.commit('resource/hotel/DELETE_DATA');
    }

    // 清理账户的信息
    const data = {};
    this.form.isSwim = false;
    this.$store.dispatch('resource/account/editAccount', data);
  },
  methods: {
    fetchData(id) {
      this.loading = true;
      this.$store.dispatch('resource/hotel/fetchDetail', id).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
      this.fetchImage(id);
    },
    fetchImage(id) {
      this.$store.dispatch('resource/hotel/fetchImage', id).then(data => {
        const fileList = data.map(it => {
          const {
            path
          } = it;
          const [, suffic] = path.split(OSS.prefix);
          const [name] = suffic.split('?');
          return {
            name,
            url: path
          };
        });
        this.fileList = fileList;
        this.picPaths = fileList;
      });
    },
    handleSuccess(response) {
      const {
        url
      } = response.data;
      const [, suffic] = url.split(OSS.prefix);
      const [name] = suffic.split('?');
      this.picPaths.push({
        name,
        url
      });
    },
    beforeSelectDicType() {
      this.$store.dispatch('resource/hotel/getTravelSuperviseeDic');
    },
    filterData(val) {
      const {
        data
      } = val;
      const opts = {
        name: data
      };
      this.$store.dispatch('resource/hotel/searchTravelSuperviseeDic', opts);
    },
    handleRemove(file) {
      const {
        name
      } = file;
      // 如果是新增酒店 上传图片前点击了删除，则图片链接等于删除后fileList的列表链接
      this.picPaths = this.picPaths.filter(it => it.name !== name);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小最大不超过 2MB!');
      }
      return isLt2M;
    },
    handleRegion(obj) {
      for (let key in obj) {
        this.form[key] = obj[key];
      }
    },
    selectCustomerArea(val) {
      this.form.area = val.label;
    },
    selectCustomerStandard(val) {
      this.form.standard = val.label;
    },
    // 封装已有图片的
    fileListFormat() {
      return this.fileList.map(it => {
        if (it.id) {
          return it.path;
        } else {
          return '';
        }
      });
    },
    showTipMsg(message) {
      this.$bus.tip({
        type: 'warning',
        message: message,
        offset: window.innerHeight / 2
      });
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      // 上传图片
      this.$refs.uploadRef.submit();
      this.$nextTick(() => {
        setTimeout(() => {
          let opts = {};
          opts = {
            ...this.form
          };

          // 处理酒店设施
          if (this.formBreakfast.is) {
            this.formatObj(this.formBreakfast, 'breakfast', this.breakfast);
            opts = {
              ...opts,
              ...this.breakfast
            };
            if (opts.isBreakfast && !opts.breakfastQuantity) {
              this.showTipMsg('早餐厅的餐厅数量不能为空');
              return false;
            }
          } else {
            opts.isBreakfast = false;
          }
          if (this.formDinner.is) {
            this.formatObj(this.formDinner, 'dinner', this.dinner);
            opts = {
              ...opts,
              ...this.dinner
            };
            if (opts.isDinner && !opts.dinnerQuantity) {
              this.showTipMsg('中餐厅的餐厅数量不能为空');
              return false;
            }
          } else {
            opts.isDinner = false;
          }
          if (this.formWestern.is) {
            this.formatObj(this.formWestern, 'western', this.western);
            opts = {
              ...opts,
              ...this.western
            };
            if (opts.isWestern && !opts.westernQuantity) {
              this.showTipMsg('西餐厅的餐厅数量不能为空');
              return false;
            }
          } else {
            opts.isWestern = false;
          }
          if (this.formMetting.is) {
            this.formatObj(this.formMetting, 'meeting', this.meeting);
            opts = {
              ...opts,
              ...this.meeting
            };
            if (opts.isMeeting && !opts.meetingQuantity) {
              this.showTipMsg('会议室数量不能为空');
              return false;
            }
          } else {
            opts.isMeeting = false;
          }

          // 获取原有图片
          // let fileList,
          //   picPaths = []
          // if (this.isUpdate) {
          //   fileList = this.fileListFormat()
          //   picPaths = [...fileList, ...this.picPaths]
          // } else {
          //   picPaths = [...this.picPaths]
          // }

          const picPaths = this.picPaths.map(it => {
            return splitPath(it.url);
          });
          opts = {
            ...opts,
            picPaths,
            ...this.editAccount
          };
          this.$store.dispatch('resource/hotel/createOrUpdate', opts).then(() => {
            this.$bus.tip();
            // this.$store.dispatch('resource/hotel/fetch')
            // this.$store.dispatch('resource/hotel/fetchList')
            this.$bus.emit('on:reload');
            // 关闭当前页面
            this.closeSelectedTag();
            this.$router.push({
              path: '/resource/hotel'
            });
          });
        }, 100);
      });
    },
    // 处理酒店设置对象
    formatObj(obj, word, target) {
      const capitalizeEveryWord = str => str.replace(/\b[a-z]/g, char => char.toUpperCase());
      // const hasOwn = Object.prototype.hasOwnProperty
      for (let key in obj) {
        if (key == 'is') {
          target['is' + capitalizeEveryWord(word)] = obj[key];
        } else {
          const name = word + capitalizeEveryWord(key);
          target[name] = obj[key];
        }
      }
      return target;
    }
  }
};