var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "760px",
      title: _vm.dialogTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreateOrUpdate,
      cancel: _vm.hide
    }
  }, [_c("el-form", {
    ref: "formRef",
    staticClass: "form-wrapper",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称",
      prop: "name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "单团审核角色",
      prop: "singleGroupAuditRole"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "auditRole"
    },
    model: {
      value: _vm.form.singleGroupAuditRole,
      callback: function ($$v) {
        _vm.$set(_vm.form, "singleGroupAuditRole", $$v);
      },
      expression: "form.singleGroupAuditRole"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "备注",
      prop: ""
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: ""
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  }), _c("SwitchStatus", {
    attrs: {
      label: "是否有权查看客户信息"
    },
    model: {
      value: _vm.form.showCustomerInfo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showCustomerInfo", $$v);
      },
      expression: "form.showCustomerInfo"
    }
  }), _c("SwitchStatus", {
    attrs: {
      label: "是否有权查看二返"
    },
    model: {
      value: _vm.form.showShopSubsidy,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showShopSubsidy", $$v);
      },
      expression: "form.showShopSubsidy"
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      label: "部门权限",
      prop: ""
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.form.departmentPrivilege,
      callback: function ($$v) {
        _vm.$set(_vm.form, "departmentPrivilege", $$v);
      },
      expression: "form.departmentPrivilege"
    }
  }, _vm._l(_vm.departmentAuthArea, function (radio, index) {
    return _c("el-radio", {
      key: index,
      attrs: {
        label: radio.label
      }
    }, [_vm._v(" " + _vm._s(radio.value) + " ")]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };