import { summaryMixin } from '@/mixin';
import TableBase2 from '@/components/common/Table/TableBase2.vue';

// 判断参数是否是其中之一
function oneOf(value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}
export default {
  name: 'PopoverTable',
  components: {
    TableBase2
  },
  mixins: [summaryMixin],
  props: {
    title: {
      type: String,
      default: '标题'
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      default: 350
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    tableData: {
      type: Array,
      required: true
    },
    columnData: {
      type: Array,
      required: true
    },
    placement: {
      validator(value) {
        return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end']);
      },
      default: 'left'
    }
  },
  data() {
    return {
      statisticsKeys: ['money']
    };
  }
};