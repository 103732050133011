import { TopBar, Pie } from './components';
import { TurnoverPieKeys, ProfitPieKeys, CountPieKeys } from './config';
import { TurnoverObjKeys, ProfitObjKeys, CountObjKeys } from './config';
import { SalesmanProfitKeys, PieConfig } from './config';
import { TurnoverPieYearKeys, ProfitPieYearKeys, CountPieYearKeys } from './config';
import { TurnoverObjYearKeys, ProfitObjYearKeys, CountObjYearKeys } from './config';
import { processCurrency, formatSalesmanPieData } from '@/utils';
import { setRingOption, defaultState } from './helper';
export default {
  components: {
    TopBar,
    Pie
  },
  data() {
    return {
      salesmanData: {},
      pieMonthData: null,
      pieYearData: null
    };
  },
  methods: {
    fetchData(params = defaultState()) {
      const path = 'analyse/echart/salesmanList';
      this.$store.dispatch(path, {
        ...params
      }).then(data => {
        if (!data || !data.length) return;
        data = data.filter(it => it.id && it.name);
        const newData = processCurrency(data[0], SalesmanProfitKeys);
        this.pieMonthData = this.formatMonthData(newData);
        this.pieYearData = this.formatYearData(newData);
      });
    },
    formatMonthData(data) {
      const turnoverData = formatSalesmanPieData({
        data,
        keys: TurnoverPieKeys,
        obj: TurnoverObjKeys,
        optFn: setRingOption,
        ...PieConfig.turnover
      });
      const profitData = formatSalesmanPieData({
        data,
        keys: ProfitPieKeys,
        obj: ProfitObjKeys,
        optFn: setRingOption,
        ...PieConfig.profit
      });
      const countData = formatSalesmanPieData({
        data,
        keys: CountPieKeys,
        obj: CountObjKeys,
        optFn: setRingOption,
        ...PieConfig.count
      });
      return {
        turnoverData,
        profitData,
        countData
      };
    },
    formatYearData(data) {
      const turnoverData = formatSalesmanPieData({
        data,
        keys: TurnoverPieYearKeys,
        obj: TurnoverObjYearKeys,
        optFn: setRingOption,
        ...PieConfig.turnover
      });
      const profitData = formatSalesmanPieData({
        data,
        keys: ProfitPieYearKeys,
        obj: ProfitObjYearKeys,
        optFn: setRingOption,
        ...PieConfig.profit
      });
      const countData = formatSalesmanPieData({
        data,
        keys: CountPieYearKeys,
        obj: CountObjYearKeys,
        optFn: setRingOption,
        ...PieConfig.count
      });
      return {
        turnoverData,
        profitData,
        countData
      };
    }
  }
};