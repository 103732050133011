var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.filterTabMenu.length ? _c("div", {
    staticClass: "audit-single-edit form-fix-bar"
  }, [_c("BaseInfo", {
    attrs: {
      "cur-order": _vm.planInfo
    }
  }), !_vm.doNotShowTeamProfit ? _c("CollapseBase", {
    attrs: {
      title: "团利润",
      "is-strong": ""
    }
  }, [_c("TableTotalProfit")], 1) : _vm._e(), _c("CollapseBase", {
    attrs: {
      title: "行程安排",
      "is-strong": "",
      "auto-hide": true
    }
  }, [_c("DetailPlanTrip", {
    attrs: {
      "plan-id": _vm.planId
    }
  })], 1), _c("FormRemarks"), _c("el-menu", {
    attrs: {
      "default-active": _vm.tabIndex,
      mode: "horizontal"
    }
  }, _vm._l(_vm.filterTabMenu, function (tab, index) {
    return _c("el-menu-item", {
      key: index,
      attrs: {
        index: index + ""
      },
      on: {
        click: function ($event) {
          return _vm.handleTab(index);
        }
      }
    }, [_vm.tabStatus[tab.status] ? _c("span", [_vm._v(" " + _vm._s(tab.label) + " "), _c("i", {
      staticClass: "yellow el-icon-s-opportunity"
    })]) : _c("span", [_vm._v(" " + _vm._s(tab.label) + " ")])]);
  }), 1), _c("div", {
    staticClass: "mt10"
  }), _c("keep-alive", {
    attrs: {
      include: _vm.cachedViews
    }
  }, [_c("router-view", {
    key: _vm.key,
    ref: "contentRef"
  })], 1), _c("FooterBar", [_vm.statusCheckBtn ? _c("BtnBase", {
    staticClass: "fl",
    attrs: {
      type: "checkout"
    },
    on: {
      click: _vm.handleSettle
    }
  }, [_vm._v(" " + _vm._s(_vm.statusCheck ? "取消结算" : "确认结算") + " ")]) : _vm._e(), _c("BtnBase", {
    attrs: {
      type: "save",
      loading: _vm.btnLoading,
      disabled: _vm.getSaveBtnDisable
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" " + _vm._s(_vm.getBtnText) + " ")]), _c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印单据 ")]), _vm.statusOpBtn ? _c("BtnBase", {
    attrs: {
      type: "auditOp"
    },
    on: {
      click: function ($event) {
        return _vm.setOpAudit(_vm.AuditRole.op);
      }
    }
  }, [_vm._v(" 设置计调已审 ")]) : _vm._e(), _vm.statusOpBackBtn ? _c("BtnBase", {
    attrs: {
      type: "back"
    },
    on: {
      click: function ($event) {
        return _vm.backOpAudit(_vm.AuditRole.op);
      }
    }
  }, [_vm._v(" 退回计调 ")]) : _vm._e(), _vm.statusFinanceBtn ? _c("BtnBase", {
    attrs: {
      type: "audit"
    },
    on: {
      click: _vm.setFinanceAudit
    }
  }, [_vm._v(" 设置财务已审 ")]) : _vm._e(), _vm.statusFinanceBackBtn ? _c("BtnBase", {
    attrs: {
      type: "cancel"
    },
    on: {
      click: _vm.cancelFinanceAudit
    }
  }, [_vm._v(" 取消财务已审 ")]) : _vm._e(), _c("el-button", {
    directives: [{
      name: "permission",
      rawName: "v-permission"
    }],
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.openEmail
    }
  }, [_vm._v(" 获取发票 ")])], 1), _c("DialogEmail", {
    ref: "emailRef"
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogTourist")], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };