import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/other/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchOther(params) {
  return request({
    url: '/resource/other/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateOther(data) {
  return request({
    url: '/resource/other/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteOther(id) {
  return request({
    url: '/resource/other/del',
    method: 'post',
    data: {
      id
    }
  });
}