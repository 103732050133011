import "core-js/modules/es.array.push.js";
import { SelectDictionary } from '@/components/common';
import { resourceRoomType } from '@/config/resource';
import { dictionaryMixin } from '@/mixin';
const defaultState = () => ({
  area: 0,
  bedType: '',
  roomType: '',
  floor: '',
  isAddRoom: 0,
  isWindow: 1,
  quantity: 1,
  bedTypeId: ''
  // roomTypeId: '',
});
export default {
  components: {
    SelectDictionary
  },
  mixins: [dictionaryMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: resourceRoomType,
      form: {},
      hotelId: ''
    };
  },
  methods: {
    show(id) {
      this.visible = true;
      this.hotelId = id;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    handleCancel() {
      this.hide();
    },
    addRow() {
      const {
        id: roomTypeId,
        label: roomType
      } = this.getDictionaryDefVal('roomType');
      const {
        id: bedTypeId,
        label: bedType
      } = this.getDictionaryDefVal('bedType');
      this.tableData.push({
        ...defaultState(),
        roomType,
        roomTypeId,
        bedType,
        bedTypeId
      });
    },
    selectRoom(row, val) {
      const {
        id,
        label
      } = val;
      row.roomType = label;
      row.roomTypeId = id;
    },
    selectBed(row, val) {
      const {
        id,
        label
      } = val;
      row.bedType = label;
      row.bedTypeId = id;
    },
    fetchData() {
      this.$store.dispatch('resource/hotel/fetchRoom', {
        hotelId: this.hotelId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = this.formatData(list);
      });
    },
    addItem(opts) {
      if (!opts) return;
      this.$store.dispatch('resource/hotel/createOrUpdateRoom', opts);
    },
    deleteItem(id) {
      this.$store.dispatch('resource/hotel/deleteRoom', id).then(() => {
        this.fetchData();
        this.$bus.tip();
      });
    },
    handleDelete(row) {
      this.$bus.open(() => {
        this.deleteItem(row.id);
      });
    },
    handleConfirm() {
      if (!this.validateForm()) return;
      const loading = this.$bus.loading();
      const dataList = this.beforeCommit(this.tableData);
      const PAll = dataList.map(this.addItem);
      Promise.all(PAll).then(() => {
        this.fetchData();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          bedType: bedTypeOri
          // roomType: roomTypeOri,
        } = it;
        const [bedType, bedTypeId] = bedTypeOri.split('|');
        // const [roomType, roomTypeId] = roomTypeOri.split('|')

        this.$set(it, 'bedTypeId', bedTypeId);
        // this.$set(it, 'roomTypeId', roomTypeId)
        // it.roomType = roomType
        it.bedType = bedType;
        const {
          isWindow,
          isAddRoom
        } = it;
        it.isWindow = +isWindow;
        it.isAddRoom = +isAddRoom;
        return it;
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        const {
          bedType,
          bedTypeId
        } = it;
        // const { roomType, roomTypeId } = it

        if (bedType && bedTypeId) {
          it.bedType = bedType + '|' + bedTypeId;
        }

        // if (roomType && roomTypeId) {
        //   it.roomType = roomType + '|' + roomTypeId
        // }

        it.hotelId = this.hotelId;
        return it;
      });
    },
    validateForm() {
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i];
        const {
          area,
          floor
        } = item;
        if (!area) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写房间面积'
          });
          return false;
        }
        if (!floor) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写房间所在楼层'
          });
          return false;
        }
        return true;
      }
    }
  }
};