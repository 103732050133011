var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.row.reimbGuides && _vm.row.reimbGuides.length ? _c("div", _vm._l(_vm.row.reimbGuides, function (item, index) {
    return _c("div", {
      key: item.id + "" + index
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };