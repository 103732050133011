var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "resource-plan-tips flex"
  }, [_c("h4", {
    staticClass: "red"
  }, [_vm._v(" 颜色状态提示： ")]), _vm._l(_vm.tips, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "flex flex-center"
    }, [_c("div", {
      staticClass: "box ml10",
      style: {
        background: item.bg,
        width: "20px",
        height: "20px",
        "border-radius": "2px"
      }
    }), _c("div", {
      staticClass: "pl5",
      style: {
        color: item.bg
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])]);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };