var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.getLogo,
      alt: ""
    }
  }), _c("h2", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };