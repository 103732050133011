import PayInfo from '../components/PayInfo.vue';
import OrderInfo from './OrderInfo.vue';
import ModifyItem from '../components/ModifyItem.vue';
import modifyMixin from '../modify';
import { parseTime } from '@/utils';
import { TYPES as ORDER_TYPE } from '@/config/order';
import { resourceOrderConfirmStatus } from '@/config/resourceOrder';
export default {
  components: {
    OrderInfo,
    PayInfo,
    ModifyItem
  },
  mixins: [modifyMixin],
  data() {
    return {
      parseTime,
      visible: false,
      isEdit: false,
      data: {},
      payInfoData: {},
      orderInfoData: {}
    };
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/resource/fetchBookingRestaurantDetail', params).then(data => {
        this.handleData(data);
      });
    },
    show(row) {
      const params = {
        id: row.rowId,
        isReview: 1
      };
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
    },
    handleData(row) {
      const {
        payType,
        totalPay
      } = row;
      this.data = row;
      this.payInfoData = {
        payType,
        totalPay
      };
      this.orderInfoData = row;
      this.visible = true;
    },
    handleConfirm() {
      const {
        id: orderId,
        resOrderId
      } = this.data;
      const opts = {
        orderId,
        resOrderId,
        status: resourceOrderConfirmStatus.PASS,
        orderType: ORDER_TYPE.restaurant
      };
      this.$store.dispatch('order/resource/bookingConfirmApply', opts).then(() => {
        this.hide();
        this.$bus.tip();
        this.$parent.initData();
      });
    }
  }
};