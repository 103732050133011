export default {
  data() {
    return {
      visible: false,
      row: null
    };
  },
  methods: {
    show(row) {
      this.row = row;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      this.$store.dispatch('order/receive/orderUpdateConfirm', this.row.id).then(() => {
        this.hide();
        this.$emit('reload');
      });
    }
  }
};