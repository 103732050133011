import { Search } from './components';
import { Columns } from './tableConfig';
import { StatisticsKeys } from './config';
import { downloadExport } from '@/utils';
import { tagViewMixin } from '@/mixin';
import { formatUnpayData } from '../helper';
export default {
  components: {
    Search
  },
  mixins: [tagViewMixin],
  data() {
    return {
      Columns,
      StatisticsKeys
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/supplierOtherPay/data'];
    },
    tableData() {
      return this.data ? formatUnpayData(this.data.list) : [];
    },
    statistics() {
      return this.data ? this.data.statistics : {};
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.$store.dispatch('report/supplierOtherPay/list', {
        ...searchParams,
        ...params
      });
    },
    reload() {
      this.fetchData();
    },
    handlePage(params) {
      this.fetchData(params);
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/supplierOtherPay/export', {
        ...params
      }).then(res => {
        const fileName = '其他支出汇总.xlsx';
        downloadExport(res, fileName);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};