import "core-js/modules/es.array.push.js";
import { Columns as columns } from './tableConfig';
import { Search } from './components';
// import { downloadExport } from '@/utils'

export default {
  components: {
    Search
  },
  data() {
    return {
      columns,
      // starDate: [],
      // sendDate: [],
      // orderDate: [],
      // searchParams: {},
      statisticsKeys: {
        adultCount: 'adultCount',
        total: 'total',
        totaled: 'totaled',
        unTotal: 'unTotal'
      }
    };
  },
  computed: {
    data() {
      return this.$store.state['report/index'].customerSubsidy2;
    },
    tableData() {
      const data = this.data ? this.data.list : [];
      return data;
    },
    total() {
      const data = this.data ? this.data.totalCount : 0;
      return data;
    },
    totalData() {
      const data = this.data ? this.data.statistics : {};
      return data;
    },
    getSearchParams() {
      return this.$refs.searchRef ? this.$refs.searchRef.searchParams : {};
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('report/index/subsidyStatistics', {
        ...this.getSearchParams,
        ...params
      });
    },
    // selectDate(type, val) {
    //   const [start, end] = val
    //   this.searchParams[type + 'S'] = start
    //   this.searchParams[type + 'E'] = end
    // },
    // // 选择客户
    // selectCustom({ label }) {
    //   this.search.customerName = label
    // },

    handleLink(row) {
      const query = {
        resourceLabel: row.customerName,
        id: row.customerId
      };
      this.$router.push({
        path: '/bill-check/refund',
        query
      });
    }

    // handleExport() {
    //   // const el = document.querySelector('#ReportRefundTable')
    //   // exportTable(el, '返款汇总报表.xlsx', '返款汇总')
    //   this.$bus.tip({ message: '数据正在导出...' })

    //   this.$store
    //     .dispatch('report/index/exportSubsidy2', this.searchParams)
    //     .then(res => {
    //       downloadExport(res, '返款汇总报表.xlsx')
    //     })
    // },
  }
};