// 公司发票
import { fetchInvoiceList, deleteInvoice, createOrUpdateInvoice } from '@/api/system/invoice';
const dfaultState = () => ({
  invoiceData: null
});
const state = dfaultState();
const getters = {
  invoiceData: state => state.invoiceData,
  invoiceList: state => state.invoiceData && state.invoiceData.list
};
const actions = {
  // 获取发票列表
  fetchList({
    commit
  }, queryParam) {
    return fetchInvoiceList(queryParam).then(res => {
      const {
        data
      } = res;
      commit('INVOICE_DATA', data);
      return data;
    });
  },
  // 添加或更新发票
  // eslint-disable-next-line no-empty-pattern
  createOrUpdate({}, data) {
    return new Promise((resolve, reject) => {
      return createOrUpdateInvoice(data).then(() => {
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  },
  // 删除发票
  // eslint-disable-next-line no-empty-pattern
  delete({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      return deleteInvoice(id).then(() => {
        commit('INVOICE_DELETE', id);
        resolve();
      }).catch(err => reject(err));
    });
  }
};
const mutations = {
  COMPANY_INFO: (state, data) => {
    const obj = {};
    // boolean to number
    for (let key in data) {
      if (data[key] == 'true') {
        obj[key] = 1;
      } else if (data[key] == 'false') obj[key] = 0;else {
        obj[key] = data[key];
      }
    }
    state.companyInfo = obj;
  },
  CHANGE_COMPANY_INFO: (state, data) => {
    const {
      type,
      val
    } = data;
    state.companyInfo[type] = val;
  },
  INVOICE_DATA: (state, data) => {
    state.invoiceData = data;
  },
  INVOICE_DELETE: (state, id) => {
    const list = state.invoiceData.list.filter(it => it.id !== id);
    state.invoiceData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};