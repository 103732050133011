import { customerInfoMixin } from '@/mixin';
import { parseTime, decimalSplit, getUserData } from '@/utils';
import { SELECTOR } from '@/config/select';
export default {
  mixins: [customerInfoMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      travelAgency: getUserData().travelAgency
    };
  },
  computed: {
    removeHotelPhone() {
      return str => {
        const re = /\d+-?\d+/;
        return str ? str.replace(re, '') : str;
      };
    },
    getCardType() {
      return type => {
        const findItem = SELECTOR.cardType.find(it => it.value == type);
        return findItem && findItem.label;
      };
    },
    calcUnCollect() {
      return item => {
        const {
          totalIncome,
          totalSubsidy,
          totalIncomed,
          totalCollect,
          unIncomeDeductSubsidy
        } = item;
        // *乘以10000处理小数点精度丢失问题
        const unit = 10000;
        // unIncomeDeductSubsidy 如果对冲则未收则加上返款，不对冲就不加上
        const Subsidy = unIncomeDeductSubsidy ? totalSubsidy * unit : 0;
        const Incomed = totalIncomed * unit;
        const Collect = totalCollect * unit;
        const UnCollect = totalIncome * unit - (Subsidy + Incomed + Collect);
        return decimalSplit(UnCollect / unit);
      };
    }
  },
  methods: {
    parseTime,
    shuttles(type) {
      if (!this.data.shuttles || !this.data.shuttles.length) return '';
      let shuttle = '';
      this.data.shuttles.forEach(it => {
        if (type == it.shuttleType) {
          it.shiftDateTime = parseTime(it.shiftDateTime);
          shuttle = `班次${it.shiftNumber}时间${it.shiftDateTime}`;
        }
      });
      return shuttle;
    }
  }
};