/* eslint-disable no-unused-vars */
// 数据分析
import request from '@/utils/request';
export * from './echart';
export * from './target';

// 导游数据分析
export function fetchGuide(params) {
  return request({
    url: '/order/data-analysis/guide-data-analysis',
    method: 'get',
    params
  });
}

// 应收款明细表
export function fetchIncome(params) {
  return request({
    url: '/order/data-analysis/fee-detail-analysis',
    method: 'get',
    params
  });
}

// 区域分析表
export function fetchCustomeArea(params) {
  return request({
    url: '/order/data-analysis/customer-area-analysis',
    method: 'get',
    params
  });
}
// 购物店分析表
export function fetchShop(params) {
  return request({
    url: '/order/data-analysis/shop-analysis',
    method: 'get',
    params
  });
}

// 土店分析表
export function fetchSpecialtShop(params) {
  return request({
    url: '/order/data-analysis/specialty-shop-analysis',
    method: 'get',
    params
  });
}

// 业务员分析表
export function fetchSpecialtSale(params) {
  return request({
    url: '/order/data-analysis/sale-man-analysis',
    method: 'get',
    params
  });
}
// 客户分析表
export function fetchCustom(params) {
  return request({
    url: '/order/data-analysis/customer-analysis',
    method: 'get',
    params
  });
}
// 线路产品分析表
export function fetchProduct(params) {
  return request({
    url: '/order/data-analysis/product-type-analysis',
    method: 'get',
    params
  });
}

// 团型分析表
export function fetchGroup(params) {
  return request({
    url: '/order/data-analysis/group-type-analysis',
    method: 'get',
    params
  });
}

// 营业额/人数/毛利总表
export function fetchCustomerKpi(params) {
  return request({
    url: '/order/data-analysis/customer-kpi-analysis',
    method: 'get',
    params
  });
}
// 应收款总表
export function fetchTotalIncome(params) {
  return request({
    url: '/order/data-analysis/customer-income-total-analysis',
    method: 'get',
    params
  });
}
// 游客年龄分析表
export function fetchMemberAge(params) {
  return request({
    url: '/order/data-analysis/member-age-analysis',
    method: 'get',
    params
  });
}
// 游客分布数据表
export function fetchMemberMap(params) {
  return request({
    url: '/order/data-analysis/member-analysis',
    method: 'get',
    params
  });
}