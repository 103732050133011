var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "hide-bar": true,
      "row-class-name": "resource-order-table",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "orderStatus",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.getStatusType(scope.row.orderStatus)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatus(scope.row.orderStatus)) + " ")]), _c("br"), _c("el-tag", [_vm._v(" " + _vm._s(scope.row.sendPlanStatus ? "已发团" : "未发团") + " ")])];
      }
    }, {
      key: "orderAndPlanNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          attrs: {
            "is-link": false
          }
        }, [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.orderNumber) + " ")])], 1), scope.row.planNumber ? [_c("TripNumberBase", {
          attrs: {
            "is-link": false
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.planNumber))])])] : _vm._e()];
      }
    }, {
      key: "useDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.useDate) + " ")];
      }
    }, {
      key: "mealType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getMealType(scope.row.mealType)) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [!_vm.isCancel(scope.row) && !_vm.isWait(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleApply(scope.row);
            }
          }
        }, [scope.row.hasApply ? _c("el-badge", {
          staticClass: "item",
          attrs: {
            value: "new"
          }
        }, [_vm._v(" " + _vm._s(_vm.getApplyText(scope.row)) + " ")]) : _c("span", [_vm._v(_vm._s(_vm.getApplyText(scope.row)))])], 1) : _vm._e(), _vm.showCancelApplay(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.cancelApply(scope.row);
            }
          }
        }, [_vm._v(" 取消申请 ")]) : _vm._e(), _vm.showCancelConfirmApplay(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.cancelApply(scope.row);
            }
          }
        }, [_vm._v(" 同意取消申请 ")]) : _vm._e(), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope.row);
            }
          }
        }, [_vm._v(" 日志 ")]), _vm.showPay(scope.row.payType) ? _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handlePay(scope.row);
            }
          }
        }, [_vm._v(" 付款 ")]) : _vm._e(), _vm.isCancel(scope.row) ? _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")]) : _vm._e(), scope.row.hasReviewer ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleConfirm(scope.row);
            }
          }
        }, [_c("el-badge", {
          staticClass: "item",
          attrs: {
            value: "new"
          }
        }, [_vm._v(" 确认 ")])], 1) : _vm._e(), scope.row.cancelReviewerStatus ? _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.confirmReviewer(scope.row);
            }
          }
        }, [_c("el-badge", {
          staticClass: "item",
          attrs: {
            value: "new"
          }
        }, [_vm._v(" 确认申请 ")])], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };