import "core-js/modules/es.array.push.js";
import { DayRangeMap } from '@/config/day';
import { TrafficMap } from '@/config/traffic';
import { SELECTOR } from '@/config';
import { defDictionary } from '@/utils/dictionary';
import { validateTable } from '@/utils/validate';
import { mapGetters } from 'vuex';
const TICKET_MAP = {
  印象刘三姐: 'impressionTickeTypeList',
  漓江三星船: 'riverTickeTypeList'
};
export default {
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    })
  },
  methods: {
    fetchData(id) {
      id = id || this.$route.query.id;
      if (!id) return;
      const params = {
        planId: -1,
        customerOrderId: id
      };

      // this.fetchItinerary(id)
      this.fetchHotelData(params);
      this.fetchFleetData(params);
      this.fetchScenicData(params);
      this.fetchRestaurantData(params);
    },
    fetchHotelData(params) {
      this.$store.dispatch('order/index/fetchHotel', params).then(data => {
        if (!data || !data.length) return;
        this.hotelData = data;
      });
    },
    fetchRestaurantData(params) {
      this.$store.dispatch('order/reserve/fetchRestaurantList', params).then(data => {
        if (!data || !data.length) return;
        const {
          label,
          value
        } = defDictionary('hotelRegionType');
        const {
          label: restaurantType,
          value: restaurantTypeId
        } = defDictionary('restaurantStandardType');
        this.restaurantData = data.map(it => {
          it.type = '团餐厅';
          it.restaurantArea = label;
          it.restaurantAreaId = value;
          it.restaurantType = restaurantType;
          it.restaurantTypeId = restaurantTypeId;
          return it;
        });
      });
    },
    fetchFleetData(params) {
      this.$store.dispatch('order/reserve/fetchFleetList', params).then(data => {
        if (!data || !data.length) return;
        this.fleetData = data;
      });
    },
    fetchScenicData(params) {
      this.$store.dispatch('order/reserve/fetchScenicList', params).then(data => {
        if (!data || !data.length) return;
        this.scenicData = data.map(it => {
          const ticketList = SELECTOR[TICKET_MAP[it.scenicName]];
          const cur = data[0];
          it.visible = true;
          it.ticketType = it.ticketType || ticketList[0].label;
          it.ticketList = ticketList;
          it.orderDate = cur.orderDate;
          it.customerOrderId = cur.customerOrderId;
          return it;
        });
      });
    },
    formatScenicData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const count = this.currentOrder ? this.currentOrder.adultCount : 1;
      const {
        planId,
        orderNumber
      } = this.currentOrder;
      list.forEach(it => {
        const {
          itineraryDate,
          customerOrderId,
          scenics
        } = it;
        const {
          scenicId,
          scenicName
        } = scenics[0];
        const row = {
          customerOrderId,
          count,
          planId,
          orderNumber,
          scenicId,
          scenicName,
          orderDate: itineraryDate,
          ticketType: '',
          freeCount: 0,
          id: 0,
          otherFee: 0,
          price: 0,
          remark: '',
          scenicPlanId: 0
        };
        result.push(row);
      });
      return result;
    },
    formatBusData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const startItem = list[0];
      const endItem = list[list.length - 1];
      const {
        planId,
        orderNumber
      } = this.currentOrder;
      const {
        customerOrderId
      } = startItem;
      const row = {
        busCompanyId: 0,
        busCompanyName: '',
        busPlanId: 0,
        busType: '',
        count: 0,
        id: 0,
        licenceNumber: '',
        otherFee: 0,
        price: 0,
        remark: '',
        planId,
        orderNumber,
        customerOrderId,
        startDate: startItem.itineraryDate,
        endDate: endItem.itineraryDate,
        pickRemarks: '',
        pickTimeQuantum: DayRangeMap.night,
        pickType: TrafficMap.plane,
        sendRemarks: '',
        sendTimeQuantum: DayRangeMap.morning,
        sendType: TrafficMap.plane
      };
      result.push(row);
      return result;
    },
    formatRestaurantData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          itineraryDate,
          lunch,
          dinner,
          area,
          customerOrderId
        } = it;
        const count = this.currentOrder ? this.currentOrder.adultCount : 1;
        const row = {
          rowId: id,
          customerOrderId,
          orderDate: itineraryDate,
          restaurantArea: area,
          count,
          restaurantType: '',
          type: '团餐厅',
          remark: ''
        };
        if (lunch) {
          result.push({
            ...row,
            mealType: 'lunch'
          });
        }
        if (dinner) {
          result.push({
            ...row,
            mealType: 'dinner'
          });
        }
      });
      return result;
    },
    processHotel(list) {
      return list.filter(it => {
        // 入住人取用户人数
        it.checkInName = this.getPeopleCount;
        return it.headCompanyId < 0;
      });
    },
    processRestaurant(list) {
      const {
        id,
        orderNumber
      } = this.currentOrder;
      const planId = this.currentOrder.planId || -1;
      return list.filter(it => {
        it.planId = planId;
        it.customerOrderId = id;
        it.orderNumber = orderNumber;
        it.companyId = '';
        return it.headCompanyId < 0;
      });
    },
    processFleetData(list) {
      return list.filter(it => {
        it.customerOrderId = this.$route.query.id || this.currentOrder.id;
        return it.headCompanyId < 0;
      });
    },
    processScenicData(list) {
      return list.filter(it => {
        it.customerOrderId = this.$route.query.id || this.currentOrder.id;
        it.planId = this.currentOrder.planId || -1;
        return it.headCompanyId < 0;
      });
    },
    validateHotel(list) {
      list = list.filter(it => {
        return it.headCompanyId < 0 || !it.headCompanyId;
      });
      // 如果没有数据则不需要校验
      if (!list || !list.length) return true;
      const validKeys = {
        orderDate: '请选择入住日期',
        hotelArea: '请选择区域',
        hotelStandard: '请选择标准'
      };
      return validateTable(list, validKeys);
    },
    validateFleet(list) {
      list = list.filter(it => {
        return it.headCompanyId < 0 || !it.headCompanyId;
      });
      if (!list || !list.length) return true;
      const validKeys = {
        pickTimeQuantum: '请选择开始时间',
        // pickRemarks: '请输入接内容',
        sendTimeQuantum: '请选择结束时间',
        // sendRemarks: '请输入送内容',
        busTypeId: '请选择车型'
      };
      return validateTable(list, validKeys);
    },
    validateScenic(list) {
      list = list.filter(it => {
        return it.headCompanyId < 0 || !it.headCompanyId;
      });
      if (!list || !list.length) return true;
      const validKeys = {
        scenicName: '请选择景区',
        ticketType: '请选择门票类型'
      };
      return validateTable(list, validKeys);
    },
    validateRestaurant(list) {
      list = list.filter(it => {
        return it.headCompanyId < 0 || !it.headCompanyId;
      });
      if (!list || !list.length) return true;
      const validKeys = {
        useDate: '请选择用餐日期',
        restaurantArea: '请选择用餐区域',
        mealType: '请选择用餐类别',
        type: '请选择餐标'
      };
      return validateTable(list, validKeys);
    }
  }
};