var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-select", _vm._g(_vm._b({
    attrs: {
      placeholder: _vm.placeholder,
      clearable: ""
    },
    on: {
      change: _vm.handleChange,
      focus: _vm.lazyLoad
    },
    model: {
      value: _vm.curVal,
      callback: function ($$v) {
        _vm.curVal = $$v;
      },
      expression: "curVal"
    }
  }, "el-select", _vm.$attrs, false), _vm.$listeners), _vm._l(_vm.getTypeList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value + "",
        disabled: item.disabled
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };