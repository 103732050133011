import "core-js/modules/es.array.reduce.js";
import { BtnExport, SelectCustom, SelectStaff, SelectGuide } from '@/components/common';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { downloadExport } from '@/utils/exportExcel';
import { daysData, daysDataE } from '@/config/report';
import { parseDate, decimalSplit } from '@/utils';
import { processAdd } from '@/utils/number';
const today = parseDate(new Date());
const tabelKey = ['profitAndLoss', 'receivingSettlement', 'perCapitaProfit'];
export default {
  components: {
    BtnExport,
    SelectCustom,
    SelectStaff,
    SelectGuide
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    isTableLoading: true,
    searchParams: {
      startTripDateS: today
    },
    tripDate: [today, ''],
    endDate: [],
    tableData: [],
    // columnData: [...daysData, ...daysDataE],
    columnData: [],
    avgeageMaps: [{
      hasChild: false,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: '',
      // 当hasChild为true时，key 为 对象字符串

      divisor: 'adultCount',
      // 人数字段
      dividend: 'profitAndLoss',
      // 总数
      value: 'perCapitaProfit' // 平均值
    },
    // 计算人均
    {
      hasChild: false,
      // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
      key: '',
      // 当hasChild为true时，key 为 对象字符串

      divisor: 'adultCount',
      // 人数字段
      dividend: 'totalShop',
      // 总数
      value: 'shoppingPerCapita' // 平均值
    } // 计算人均
    ]
  }),
  computed: {
    ...mapGetters({
      data: 'report/index/everyDayData'
    })
  },
  // watch: {
  //   data: {
  //     handler: function (d) {
  //       if (!d) {
  //         this.fetchData(this.searchParams)
  //         return
  //       }
  //       this.tableData = this.formatData(d)
  //     },
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.fetchData(this.searchParams);
  },
  methods: {
    // 选择散、发团日期
    selectDate(type, val) {
      this.searchParams[type + 'S'] = val[0];
      this.searchParams[type + 'E'] = val[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    onSelectPlanType(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type] = value;
    },
    // 删除提交多余字段
    formatSearch(data) {
      let opts = {};
      Object.keys(data).forEach(key => {
        if (data[key]) {
          opts[key] = data[key];
        }
      });
      // delete opts.starDate
      // delete opts.dissolveDate
      return opts;
    },
    // handleSearch(params) {
    //   // 获取数据，对应页面需要有该方法
    //   this.fetchData(params)
    // },

    fetchData(params = {}) {
      // const opts = this.formatSearch(this.searchParams)
      // this.tableLoading = true
      this.$store.dispatch('report/index/getDayExpress', this.formatSearch(params)).then(res => {
        if (!res || !res.data) return;
        // this.isTableLoading = false
        const {
          shop,
          data
        } = res;
        this.formatColumnData(shop);
        this.tableData = this.formatData(data);
      });
    },
    // 格式化数据
    formatData(list) {
      if (!list.length) return [];
      return list.map(item => {
        item.shops.forEach(it => {
          item[`shopMone${it.id}`] = it.money;
        });
        return item;
      });
    },
    // 格式化表头
    formatColumnData(list) {
      if (!list.length) return [];
      const column = list.map(it => {
        const item = {
          attrs: {
            prop: `shopMone${it.id}`,
            label: `${it.shopName}`
          }
        };
        return item;
      });
      const headerColumn = {
        attrs: {
          prop: '',
          label: '收入'
        },
        children: [...column, {
          attrs: {
            prop: 'totalShop',
            label: '购物总计'
          }
        }, {
          attrs: {
            prop: 'totalShopIncome',
            label: '购物收入'
          }
        }, {
          attrs: {
            prop: 'receivingSettlement',
            label: '收客结算价'
          }
        }, {
          attrs: {
            prop: 'totalOtherIncome',
            label: '其他收入'
          }
        }, {
          attrs: {
            prop: 'addScenicIncome',
            label: '(景)加点'
          }
        }, {
          attrs: {
            prop: 'outSubsidyIncome',
            label: '外转返款'
          }
        }, {
          attrs: {
            prop: 'guideManageFeeIncome',
            label: '导游管理'
          }
        }, {
          attrs: {
            prop: 'totalIncome',
            label: '总收入'
          }
        }]
      };
      this.columnData = [...daysData, headerColumn, ...daysDataE];
      // let totalIndex = 0
      // this.columnData.forEach((item, index) => {
      //   if (item.property == 'totalShop') {
      //     totalIndex = index
      //   }
      // })
      this.isTableLoading = true;
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      const opts = this.formatSearch(this.searchParams);
      const fileName = '天天快报.xlsx';
      this.$store.dispatch('report/index/fetchDailyExcel', opts).then(res => {
        loading.close();
        downloadExport(res, fileName);
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    changeCellClassName(row) {
      let className = '';
      //列的label的名称
      if (row.column.property === 'sinkIn' || row.column.property === 'shoppingPerCapita' || row.column.property === 'totalShopIncome' || row.column.property === 'totalShop') {
        return 'blue'; //修改的样式
      }
      tabelKey.forEach(key => {
        if (row.column.property == key && row.row[key] < 0) {
          className = 'red'; //修改的样式
        }
      });
      return className;
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      const avgRes = {};
      const avgIdx = {};
      const avgMaps = this.avgeageMaps;
      if (avgMaps) {
        avgMaps.forEach(item => {
          const {
            hasChild,
            divisor,
            dividend,
            value
          } = item;
          // 获取需要的字段数据
          const avgList = data.map(it => {
            if (hasChild) {
              return {
                ...it[item.key],
                [item['divisor']]: it[divisor]
              };
            } else {
              return it;
            }
          });
          // 总人数
          const totalPeople = avgList.reduce((acc, cur) => (acc, Number(cur[divisor]) + Number(acc)), 0);
          // 总金额
          const totalBuyMoney = avgList.reduce((acc, cur) => (acc, Number(cur[dividend]) + Number(acc)), 0);
          avgRes[value] = decimalSplit(Number(totalBuyMoney / totalPeople));
        });
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        if (avgMaps) {
          avgMaps.forEach(item => {
            const {
              value
            } = item;
            if (value.includes(column.property)) {
              avgIdx[column.property] = index;
            }
          });
        }
        const values = data.map(item => Number(item[column.property]));

        // if (index > this.sumsIndex) {
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return processAdd(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          // 人均毛利(兼容老版本)
          if (this.avgeageKey && this.avgeageKey.length) {
            const avgeageKey = this.avgeageKey;
            const res = sums[avgeageKey[1]] / sums[avgeageKey[0]];
            sums[avgeageKey[2]] = Number(res).toFixed(2);
          }
        }
      });
      Object.keys(avgRes).map(key => {
        const i = avgIdx[key];
        sums[i] = avgRes[key];
      });
      return sums;
    }
  }
};