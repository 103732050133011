// import { mapGetters } from 'vuex'
import { resourceGuideCrew } from '@/config/resource';
export default {
  data() {
    return {
      visible: false,
      info: null,
      columnData: resourceGuideCrew,
      id: '',
      tableData: []
    };
  },
  computed: {},
  methods: {
    show(row) {
      this.id = row.id;
      this.fetchTeamDetail(row);
    },
    hide() {
      this.visible = false;
      this.images = [];
    },
    fetchTeamDetail(opts) {
      this.$store.dispatch('resource/guideCaptain/fetchTeamDetail', opts).then(res => {
        this.visible = true;
        this.info = res;
        this.tableData = res.guides;
      });
    }
  }
};