import { getUserData, textToImage } from '@/utils';
const SECONDS = 10;
export default {
  data() {
    return {
      visible: false,
      form: {},
      curSecond: SECONDS
    };
  },
  computed: {
    getTitle() {
      return '通知';
    }
  },
  mounted() {},
  methods: {
    show(row) {
      this.visible = true;
      this.form = row;
      !row.isRead ? this.countDown() : null;
      this.$nextTick(() => {
        setTimeout(() => {
          this.setMarkImage();
        }, 500);
      });
    },
    hide() {
      this.form = {};
      this.visible = false;
      this.curSecond = SECONDS;
    },
    setMarkImage() {
      const {
        realName,
        userName
      } = getUserData();
      const bgImg = textToImage(`${realName} ${userName}`);
      const contentEl = document.querySelector('.notice-detail__content');
      const mask = document.querySelector('#mask');
      mask.style.height = contentEl.offsetHeight + 'px';
      mask.style.background = `url(${bgImg}) repeat 20% center`;
    },
    countDown() {
      let timer;
      const doCount = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.curSecond--;
          if (this.curSecond > 0) {
            doCount();
          }
        }, 1000);
      };
      doCount();
    },
    setStatus() {
      this.$store.dispatch('notice/policy/statusSet', {
        noticeId: this.form.id,
        status: 1
      }).then(() => {
        this.$emit('reload');
        this.hide();
      });
    }
  }
};