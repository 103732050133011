/*
 * @Author: cyg
 * @Date: 2022-10-26 10:16:12
 * @LastEditors: cyg
 * @LastEditTime: 2022-10-27 10:07:50
 * @FilePath: \travel-admin\src\api\system\payment.js
 */
// 支付功能
import request from '@/utils/request';
export function fetchVasList(params) {
  return request({
    url: '/system/vas/list',
    method: 'get',
    params
  });
}
// 支付宝支付
export function updateAlipay(data) {
  return request({
    url: '/system/vas/alipay',
    method: 'post',
    data
  });
}
// 支付宝支付回调通知接口
export function updateAlipayNotify(params) {
  return request({
    url: '/system/api/pay-notify/alipay-notify',
    method: 'get',
    params
  });
}

// 获取增值服务 列表
export const vasListRequest = params => {
  return request({
    url: '/system/vas/page',
    method: 'get',
    params
  });
};

// 获取增值服务充值日志
export const vasLogRequest = params => {
  return request({
    url: '/system/vas/page-log',
    method: 'get',
    params
  });
};

// 已购买的增值服务
export const vasBuyedListRequest = params => {
  return request({
    url: '/system/vas/list-trip-vas',
    method: 'get',
    params
  });
};

// 支付结果 通知接口
export const notiflyStatusRequest = params => {
  return request({
    url: '/system/vas/payNotify',
    method: 'get',
    params
  });
};

// 微信查询订单支付状态
export const wechatPayStatusRequest = params => {
  return request({
    url: '/system/vas/search-wechat-pay-status',
    method: 'get',
    params
  });
};

// 支付宝查询订单支付状态
export const aliPayStatusRequest = params => {
  return request({
    url: '/system/vas/search-alipay-pay-status',
    method: 'get',
    params
  });
};

// 充值 微信
export const wechatTopUpRequest = data => {
  return request({
    url: '/system/vas/wechatPay',
    method: 'post',
    data
  });
};

// 充值 支付宝
export const alipayTopUpRequest = data => {
  return request({
    url: '/system/vas/alipay',
    method: 'post',
    data
  });
};

// 充值 银联
export const unipayTopUpRequest = data => {
  return request({
    url: '/system/vas/unionPay',
    method: 'post',
    data
  });
};

// 根据购买记录 关闭取消待支付订单
export const closeOrderByIdRequest = params => {
  return request({
    url: '/system/vas/close-order-by-valLogId',
    method: 'get',
    params
  });
};

// 根据购买记录 关闭取消待支付订单
export const closeOrderByTradeNoRequest = params => {
  return request({
    url: '/system/vas/close-order-by-trade-no',
    method: 'get',
    params
  });
};

// 获取增值服务充值日志
export const renewLogRequest = params => {
  return request({
    url: '/system/vas/page-log',
    method: 'get',
    params
  });
};

// 获取 增值服务 合同编码
export const contractCodeRequest = params => {
  return request({
    url: '/system/vas/initVASContractCodeSuffix',
    method: 'get',
    params
  });
};