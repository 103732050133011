import "core-js/modules/es.array.push.js";
import TableList from './components/TableList';
import ItineraryDialog from './components/ItineraryDialog';
import { getCurTagView } from '@/utils/tagView';
import { orderTeam, orderTeamNoCustom, orderType } from '@/config/order';
import { mapGetters } from 'vuex';
import { tagViewMixin, customerInfoMixin } from '@/mixin';
import { SelectGuide, SelectStaff, SelectCustom, SelectProduct, InputAgeRange, InputDepartment, SelectRegion } from '@/components/common';
import { parseDate, validateTeamFee, hasCustomeAuth } from '@/utils';
export default {
  name: 'Team',
  components: {
    SelectGuide,
    SelectStaff,
    SelectProduct,
    SelectCustom,
    InputAgeRange,
    InputDepartment,
    TableList,
    ItineraryDialog,
    SelectRegion
  },
  mixins: [tagViewMixin, customerInfoMixin],
  data() {
    return {
      link: {
        create: '/order/team-input-create',
        detail: '/order/team-input-detail',
        update: '/order/team-input-update',
        clone: '/order/team-input-clone'
      },
      columnData: orderTeamNoCustom,
      tableData: [],
      total: 0,
      statistics: null,
      isConfirm: false,
      inputDate: [],
      sendDate: [parseDate()],
      region: [],
      search: {
        startTripDateS: parseDate()
      },
      department: [],
      configData: null // 系统对接配置
    };
  },
  computed: {
    ...mapGetters({
      teamData: 'order/index/teamData',
      needReload: 'order/index/needReload',
      visitedViews: 'tagsView/visitedViews'
    }),
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.search;
    }
  },
  watch: {
    // 刷新列表数据
    needReload: {
      handler: function (val) {
        if (val['Team']) {
          const params = {
            ...this.getTagViewSearch
          };
          delete params.team;
          this.fetchData(params);
        }
      },
      immediate: true
    },
    teamData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleData(data);
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data && data['team']) {
          const search = data['team'];
          const {
            startTripDateS,
            startTripDateE,
            startCreateDate,
            endCreateDate
          } = search;
          this.sendDate = [startTripDateS, startTripDateE];
          this.inputDate = [startCreateDate, endCreateDate];
          this.search = search;
          const {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = search;
          this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
          const {
            provinceId,
            cityId,
            districtId
          } = search;
          this.region = [provinceId, cityId, districtId];
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderTeam : orderTeamNoCustom;
  },
  async mounted() {
    this.$bus.on('order:team-close', () => {
      this.closeTagView();
    });
    this.$bus.on('order:booking-close', () => {
      this.closeTagView('orderReserveBooking');
    });
    const {
      data
    } = await this.$store.dispatch('system/buttJoint/getConfig', {});
    this.configData = data;
  },
  methods: {
    fetchData(params) {
      const curTag = getCurTagView();
      if (curTag && curTag.page) {
        this.search.pageNum = curTag.page;
      }
      this.$store.dispatch('order/index/fetch', {
        type: 'team',
        queryParam: {
          ...this.search,
          ...params
        }
      }).then(() => {
        this.$store.commit('order/index/NEED_RELOAD', {
          type: 'Team',
          flag: false
        });
      });
    },
    // fetchSysConfig(params = {}) {
    //   return this.$store.dispatch('system/buttJoint/getConfig', params)
    // },

    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    },
    // 搜索
    handleSearch(search) {
      this.search.pageNum = 1;
      this.fetchData(search);
      this.$store.dispatch('tagsView/appendVisiteTabSearch', {
        path: this.$route.path,
        search: {
          ...search
        },
        name: 'team'
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = Object.freeze(list);
      this.statistics = statistics || 0;
      this.total = totalCount;
    },
    onItinerary(row) {
      this.orderId = row.id;
      this.$refs.ItineraryRef.show(row);
    },
    closeTagView(type) {
      const tagView = this.findCreateTagView(type);
      tagView && this.closeSelectedTag(tagView);
    },
    findCreateTagView(type = 'orderTeamInputUpdate') {
      return this.visitedViews.find(it => it.name === type);
    },
    handleSelectChange(type, val) {
      const {
        label,
        value
      } = val ? val : {
        label: '',
        value: ''
      };
      this.search[type] = label;
      this.search[type] = value;
    },
    changeUserName(type, val) {
      this.search[type] = val.value ? val.value : '';
    },
    selectProduct(val) {
      this.search.productName = val.name ? val.name : '';
    },
    handleAge(type, val) {
      this.search[type] = val;
    },
    changeCustom(val) {
      const {
        name
      } = val;
      this.search.customerName = name;
    },
    // 客源地
    changeRegion(val) {
      console.log('val:', val);
      const {
        city,
        district,
        province
      } = val;
      this.searchParams.source = `${province || ''}${city || ''}${district || ''}`;
      // const { cityId, districtId, provinceId } = val
      // let { city, district, province } = val
      // province = province || ''
      // city = city || ''
      // district = district || ''

      // const source = `${province}${city}${district}`
      // this.search = {
      //   ...this.search,
      //   cityId,
      //   districtId,
      //   provinceId,
      //   source,
      // }

      // this.region = [provinceId, cityId, districtId]
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.search.startTripDateS = startDate;
      this.search.startTripDateE = endDate;
    },
    changeOrderInputDate(val) {
      if (!val) {
        this.search.startCreateDate = '';
        this.search.endCreateDate = '';
      } else {
        const [startDate, endDate] = val;
        this.search.startCreateDate = startDate;
        this.search.endCreateDate = endDate;
      }
    },
    showCreate() {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'create'
        }
      });
    },
    // 退单
    revokeTableTeam(scope) {
      const {
        id,
        isCancel
      } = scope.row;
      let msg = scope.row.isCancel ? '是否取消退单操作？' : '是否确定退单？';
      this.$bus.open(() => {
        this.$store.dispatch('order/index/revoke', {
          id,
          isCancel: +!isCancel
        }).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      }, msg);
    },
    // 取消计划
    cancelTableTeam(scope) {
      let msg = '是否确定取消计划？';
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/cancelPlan', id).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      }, msg);
    },
    // 删除计划
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/deleteOrder', id).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      });
    },
    // 确认成团
    confirmTableTeam({
      row
    }) {
      const {
        id
      } = row;

      // 确认成团之前判断配置规则，如果不通过不允许拼团
      if (validateTeamFee(row, this.configData)) {
        this.$bus.tip({
          type: 'error',
          message: '团款不合理，无法确认成团，请录入合理的团款!',
          duration: 0
        });
        return;
      }
      if (this.isConfirm) return;
      this.isConfirm = true;
      this.$store.dispatch('order/index/confirmOrder', id).then(() => {
        this.$bus.tip();
        this.isConfirm = false;
        this.fetchData();
      }).catch(() => {
        this.isConfirm = false;
      });
    },
    // 编辑计划
    editTableRow(scope) {
      const {
        id,
        customerId
      } = scope.row;
      // this.$store.commit('order/index/CUR_ORDER_ID', id)
      this.$router.push({
        path: this.link.update,
        query: {
          id,
          customerId,
          type: 'update'
        }
      });
    },
    // 查看计划
    showTableDetail(scope) {
      const {
        id
      } = scope.row;
      // this.$store.commit('order/index/CUR_ORDER_ID', id)
      this.$router.push({
        path: this.link.detail,
        query: {
          id,
          type: 'detail',
          planType: orderType.team
        }
      });
    },
    // 复制订单
    copyTableTeam(scope) {
      const {
        id
      } = scope.row;
      // this.$store.commit('order/index/CUR_ORDER_ID', id)
      this.$router.push({
        path: this.link.clone,
        query: {
          id,
          type: 'clone'
        }
      });
    }
  }
};