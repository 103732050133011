import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { SelectGuide, SelectProduct, SelectCustom, SelectStaff, TableTotalBox, BtnExport, SelectDictionary } from '@/components/common';
import TableprofitTeamSmallSettle from './components/TableprofitTeamSmallSettle';
import { profitTeamSmallSettle, orderAuditStatus } from '@/config/report';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectProduct,
    SelectGuide,
    SelectCustom,
    SelectStaff,
    TableprofitTeamSmallSettle,
    TableTotalBox,
    BtnExport,
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    orderAuditStatusList: orderAuditStatus,
    tableLoading: false,
    tableData: [],
    columnData: profitTeamSmallSettle,
    total: 0,
    // startTripDate: [today, ''],
    // endTripDate: [],
    // orderDate: [],
    searchParams: {
      auditStatus: '',
      customerTripNumber: '',
      dutyOPUserId: '',
      outOPUserId: '',
      planNumber: '',
      // 大团号
      productName: '',
      // 产品名称
      startTripDateS: '',
      // 发团开始日期
      startTripDateE: '',
      // 发团结束日期
      endTripDateS: '',
      // 散团开始日期
      endTripDateE: '',
      // 散团结束日期
      orderDateS: '',
      // 订单开始日期
      orderDateE: '',
      // 订单结束日期
      guideName: '',
      // 导游姓名
      customerId: '' // 客户搜索
    },
    statisticData: [],
    statistic: {}
  }),
  computed: {
    ...mapGetters({
      teamSmallSettleData: 'report/index/teamSmallSettleData'
    })
  },
  watch: {
    teamSmallSettleData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    onChangStartTripDate(type, date) {
      this.searchParams[type + 'S'] = date[0];
      this.searchParams[type + 'E'] = date[1];
    },
    // 选择产品
    selectProduct(val) {
      this.searchParams.productName = val.name;
      this.searchParams.productId = val.value;
    },
    selecthotelRegion({
      label
    }) {
      this.searchParams.customerArea = label;
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    onSelectDic(type, val) {
      this.searchParams[type] = val.label;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getTeamSmallSettle', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出单团利润表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchProfitSmallSettleExcel', this.searchParams).then(res => {
        const fileName = '小团利润结算报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};