import { Search } from './components';
import { Columns as columns } from './tableConfig';
import { StatisticsKeys, AvgeageObj } from './config';
import { downloadExport, processAdd, processMinus, decimalSplit, handleSumAvg } from '@/utils';
import { tagViewMixin } from '@/mixin';
export default {
  components: {
    Search
  },
  mixins: [tagViewMixin],
  data() {
    return {
      columns,
      tableData: [],
      dateArr: [],
      StatisticsKeys
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/depPerformance/data'];
    },
    dataList() {
      return this.data ? this.data.list : [];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    statistics() {
      if (this.data) {
        const grossProfitRate = this.calcGrossProfitRate(this.data.statistics);
        console.log('grossProfitRate:', grossProfitRate);
        return {
          ...this.data.statistics,
          grossProfitRate
        };
      }
      return null;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (d) {
          this.tableData = this.formatData(this.dataList);
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('report/depPerformance/list', {
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    handleSummary(args) {
      // 计算总收入
      const {
        sumsObj
      } = args;
      const {
        totalIncome,
        feeIncome,
        totalQuoteGrossProfit
      } = sumsObj;
      // const value = processAdd(totalIncome.value, feeIncome.value)
      const sumDivisor = this.calcGrossProfitRate({
        totalIncome: totalIncome.value,
        feeIncome: feeIncome.value,
        totalQuoteGrossProfit: totalQuoteGrossProfit.value
      });
      return handleSumAvg({
        ...args,
        keyObj: AvgeageObj,
        sumDivisor,
        statistics: this.statistics
      });
    },
    calcGrossProfitRate(data) {
      const {
        totalIncome,
        feeIncome,
        totalQuoteGrossProfit
      } = data;
      const total = processAdd(totalIncome, feeIncome);
      return total ? decimalSplit(Number(totalQuoteGrossProfit) / total * 100) : total;
    },
    formatData(data) {
      return data.map((it, idx) => {
        it.$orderIndex = idx + 1;
        const {
          feeUnIncome,
          collectFee,
          feeIncomed
        } = it;

        // 已收+代收
        it.$feeIncomed = processAdd(collectFee, feeIncomed);
        // 未收-代收
        it.$feeUnIncome = processMinus(feeUnIncome, collectFee);
        it.grossProfitRate = this.calcGrossProfitRate(it) + '%';
        return it;
      });
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/depPerformance/export', {
        ...this.search,
        ...params
      }).then(res => {
        const fileName = '部门业绩统计表.xlsx';
        downloadExport(res, fileName);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};