var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "80%",
      title: "更新协议",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleUpdate
    }
  }, [_c("FormBase", {
    staticClass: "form-wrapper form-dialog-agr"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "协议名称",
      maxlength: "20",
      "show-word-limit": ""
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("name", $event);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.type == "SHOP" ? _c("InputNumber", {
    attrs: {
      label: "人头单价",
      "controls-position": "right"
    },
    model: {
      value: _vm.form.shopPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopPrice", $$v);
      },
      expression: "form.shopPrice"
    }
  }) : _vm._e(), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "有效日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      label: "备注"
    },
    on: {
      change: function ($event) {
        return _vm.handleChange("remarks", $event);
      }
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)]), _c(_vm.currentView, {
    ref: _vm.currentView,
    tag: "component"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };