import "core-js/modules/es.array.push.js";
import TableList from './components/TableList';
import DialogRoom2 from './components/DialogRoom2';
import DialogAgreementItem2 from './components/DialogAgreementItem2';
import { BtnCreate, BtnAgreement, DialogDetail, DialogAgreement, DialogBindBusiness, DialogLog } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceHotel, TYPES } from '@/config/resource';
import { formatTableData } from '@/utils/data';
import { getCurTagView } from '@/utils/tagView';
const defaultState = {
  hotelName: '',
  hotelAddr: '',
  isEnable: -1
};
export default {
  name: 'Resource',
  components: {
    BtnCreate,
    BtnAgreement,
    TableList,
    DialogRoom2,
    DialogDetail,
    DialogLog,
    DialogAgreement,
    DialogAgreementItem2,
    DialogBindBusiness
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    link: {
      create: '/resource/hotel-create',
      detail: '/resource/hotel-detail',
      edit: '/resource/hotel-edit'
    },
    columnData: resourceHotel,
    tableData: [],
    total: 0,
    searchParams: defaultState,
    resourceId: '',
    resType: TYPES.hotel
  }),
  computed: {
    hotelData() {
      return this.$store.getters['resource/hotel/hotelDataOrigin'];
    },
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    hotelData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  mounted() {
    this.$bus.on('on:reload', () => {
      this.fetchData(this.getTagViewSearch);
      this.$bus.off('on:reload');
    });
  },
  methods: {
    fetchData(queryParam = {}) {
      this.tableData = [];
      this.$store.dispatch('resource/hotel/fetch', {
        ...this.searchParams,
        ...queryParam
      });
    },
    fetchList() {
      this.$store.dispatch('resource/hotel/fetchList');
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    createOrUpdate(item) {
      const loading = this.$bus.loading();
      let opts = {
        id: item.id,
        isEnable: item.isEnable
      };
      this.$store.dispatch('resource/hotel/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    fetchRoom(hotelId) {
      let queryParams = {
        hotelId
      };
      this.$store.dispatch('resource/hotel/fetchRoom', queryParams);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    // 添加
    showCreateDialog() {
      this.$router.push({
        path: this.link.create
      });
    },
    // 详情
    showTableDetail(scope) {
      this.$router.push({
        path: this.link.detail,
        query: {
          id: scope.row.id
        }
      });
    },
    // 编辑
    editTableRow(scope) {
      this.$router.push({
        path: this.link.edit,
        query: {
          id: scope.row.id
        }
      });
    },
    // 删除单个酒店
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    deleteRow(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/hotel/delete', id).then(() => {
        this.fetchData(this.searchParams);
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 日志
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/hotel/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 协议
    showAgreement(scope) {
      const resourceId = scope.row.id;
      this.resourceId = resourceId;
      this.fetchAgreement();
      this.$refs.agreementRef.show(scope.row, TYPES.hotel);
    },
    // 获取协议
    fetchAgreement() {
      this.$store.dispatch('resource/hotel/fetchAgreement', this.resourceId);
    },
    // 协议子项
    showAgreementItem(contracId) {
      this.$refs.agreementItemRef.show(contracId, TYPES.hotel);
    },
    // 房型
    showRoomType(scope) {
      const {
        id
      } = scope.row;
      this.$refs.roomRef.show(id);
    }
  }
};