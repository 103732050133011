export default {
  data() {
    return {
      tempData: [],
      page: {
        pageNum: 1,
        pageSize: 20
      },
      params: {}
    };
  },
  computed: {
    getData() {
      return this.data || [];
    },
    list() {
      return this.getData ? this.getData.slice(0, this.page.pageNum * this.page.pageSize) : [];
    },
    getInputList() {
      return this.getData.map(it => {
        return {
          ...it,
          value: it.label
        };
      });
    }
  },
  methods: {
    fetchData(params = {}) {
      const {
        planId
      } = this.$route.query;
      if (planId) {
        this.$store.dispatch(this.fetchPath, {
          ...params,
          planId
        });
      } else {
        this.$store.dispatch(this.fetchPath, params);
      }
    },
    loadMoreData() {
      if (!this.getData || this.getData.length <= this.list.length) return;
      this.page.pageNum++;
    },
    lazyLoad() {
      if (!this.data) {
        this.fetchData(this.params);
      }
    }
  }
};