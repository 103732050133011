import DialogAttachement from './DialogAttachement.vue';
import DialogContent from './DialogContent.vue';
export default {
  components: {
    DialogAttachement,
    DialogContent
  },
  data() {
    return {
      visible: false,
      planId: '',
      data: []
    };
  },
  methods: {
    show({
      id
    }) {
      this.visible = true;
      this.planId = id;
      this.fetchEmail({
        id
      });
    },
    hide() {
      this.visible = false;
    },
    fetchEmail(params) {
      this.$store.dispatch('plan/index/mailList', params).then(data => {
        this.data = data;
      });
    },
    showAttachment(fileName, msgNum) {
      this.$refs.dialogRef.show({
        fileName,
        msgNum,
        id: this.planId
      });
    },
    showContent(content) {
      this.$refs.dialogContentRef.show(content);
    }
  }
};