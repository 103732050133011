var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    staticStyle: {
      "border-bottom": "none"
    }
  }, [_c("colgroup", _vm._l(24, function (item, colIndex) {
    return _c("col", {
      key: colIndex,
      staticClass: "col-item"
    });
  }), 0), _vm._m(0), _c("tbody", [_c("tr", {
    staticClass: "tr-conten"
  }, [_c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalCustomerIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalOutTransferSubsidyIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalOtherIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalShopIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicExtraIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalGuideManageFeeIncome) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalCustomerSubsidyPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalBusCompanyPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalHotelQuotePay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalGuideServiceFeePay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalRestaurantPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalInsurancePay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalOutTransferPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalShuttlePay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalTransportCompanyPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalOtherPay) + " ")]), _c("td", {
    attrs: {
      colspan: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalShopRebatePay) + " ")]), _c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicInnerRebatePay) + " ")]), _c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicExtraRebatePay) + " ")]), _c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicInnerQuotePay) + " ")]), _c("td", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicExtraQuotePay) + " ")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", {
    staticClass: "firstHead"
  }, [_c("th", {
    attrs: {
      colspan: "7"
    }
  }, [_vm._v(" 收入 ")]), _c("th", {
    attrs: {
      colspan: "18"
    }
  }, [_vm._v(" 支出 ")])]), _c("tr", {
    staticClass: "twoHead"
  }, [_c("th", {
    attrs: {
      colspan: "2",
      rowspan: "2"
    }
  }, [_vm._v(" 团款 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 外转返款 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 其他收入 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 购物 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 加点 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 导游管理 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 客户返款 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 车 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 房 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 导服费 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 餐 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 保险 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 外转 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 接送 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 大交通 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 其他支出 ")]), _c("th", {
    attrs: {
      colspan: "1",
      rowspan: "2"
    }
  }, [_vm._v(" 购物佣金 ")]), _c("th", {
    attrs: {
      colspan: "4",
      rowspan: "2"
    }
  }, [_vm._v(" 景区佣金 "), _c("tr", {
    staticClass: "three-thead"
  }, [_c("th", {
    attrs: {
      colspan: "2",
      rowspan: "1"
    }
  }, [_vm._v(" 行程内 ")]), _c("th", {
    attrs: {
      colspan: "2",
      rowspan: "1"
    }
  }, [_vm._v(" 加点 ")])])]), _c("th", {
    attrs: {
      colspan: "4",
      rowspan: "1"
    }
  }, [_vm._v(" 景区 "), _c("tr", {
    staticClass: "three-thead"
  }, [_c("th", {
    attrs: {
      colspan: "2",
      rowspan: "1"
    }
  }, [_vm._v(" 行程内 ")]), _c("th", {
    attrs: {
      colspan: "2",
      rowspan: "1"
    }
  }, [_vm._v(" 加点 ")])])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };