var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      maxlength: "20",
      "show-word-limit": "",
      label: "姓名"
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", $$v);
      },
      expression: "form.contact"
    }
  }), _c("InputBase", {
    attrs: {
      maxlength: "11",
      "show-word-limit": "",
      label: "手机号码"
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      type: "customerContactType",
      label: "类型"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c("InputBase", {
    attrs: {
      label: "身份证号码"
    },
    model: {
      value: _vm.form.IDCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "IDCard", $$v);
      },
      expression: "form.IDCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      maxlength: "15",
      "show-word-limit": "",
      label: "座机号码"
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", $$v);
      },
      expression: "form.telphone"
    }
  }), _c("InputBase", {
    attrs: {
      maxlength: "15",
      "show-word-limit": "",
      label: "传真号码"
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", $$v);
      },
      expression: "form.faxNumber"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };