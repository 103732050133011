// 支付宝企业码
import request from '@/utils/request';

// 邀请开通
export function addInviteRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/add-alipay-enterprise-employee',
    method: 'get',
    params
  });
}

// 删除开通
export function delInviteRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-alipay-enterprise-employee',
    method: 'get',
    params
  });
}

// 删除导游接口申请对应的制度
export function delInstitutionRequest(applyId) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-institution',
    method: 'get',
    params: {
      applyId
    }
  });
}

// 删除导游
export function delGuideRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-alipay-enterprise-employee',
    method: 'get',
    params
  });
}

// 设置计划所有的制度启用状态
export function toggleEnableQuotaRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/update-institution-enable-by-planId',
    method: 'get',
    params
  });
}

// 借款申请 删除(企业码付的)
export function delBorrowApplyByIds(ids) {
  return request({
    url: '/order/guideBorrow/delAlipayApplyByIds',
    method: 'post',
    data: ids
  });
}

// 删除计划的导游支付宝企业码付额度申请(删除计划)
export function delGuideQuotaPlanRequest(planId) {
  return request({
    url: '/order/guideBorrow/delAlipayApplyByPlanId',
    method: 'post',
    data: {
      planId
    },
    timeout: 1000 * 60 * 5
  });
}

// 查询企业码账单详情
export function detailRequest(payNo) {
  return request({
    url: '/system/trip-alipay-enterprise-code/order-query',
    method: 'get',
    params: {
      payNo
    }
  });
}

// 注销旅行社企业码
export function distoryTravelRequest(id) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-alipay-enterprise-employee',
    method: 'get',
    params: {
      id
    }
  });
}

// 修改制度额度
export function updateLimiteRequest({
  applyId,
  institutionId,
  money,
  quotaId
}) {
  return request({
    url: '/system/trip-alipay-enterprise-code/update-institution-quota',
    method: 'get',
    params: {
      applyId,
      institutionId,
      money,
      quotaId
    }
  });
}

// 修改计划的所有企业码付制度时效性
export function updatePlanValidityRequest(planId) {
  return request({
    url: '/system/trip-alipay-enterprise-code/update-institution-effective',
    method: 'get',
    params: {
      planId
    }
  });
}

// 签约旅行社企业码
export function signTravelRequest(id, returnUrl) {
  return request({
    url: '/system/trip-alipay-enterprise-code/sign-alipay-enterprise-code',
    method: 'get',
    params: {
      id,
      returnUrl
    }
  });
}

// 删除制度 根据制度id
export function delRuleRequest(id) {
  return request({
    url: '/system/trip-alipay-enterprise-code/rm-institution-by-id',
    method: 'get',
    params: {
      id
    }
  });
}

// 查询制度列表
export function searchRuleRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/query-institution-list',
    method: 'get',
    params
  });
}

// 查询导游可用余额列表
export function guideLimitRequest(params) {
  return request({
    url: '/system/trip-alipay-enterprise-code/query-guide-balance',
    method: 'get',
    params
  });
}

// 支付宝企业码付余额情况查询
export function enterpriseListRequest(params) {
  return request({
    url: '/order/plan/alipay-enterprise-plan-guide-balance',
    method: 'get',
    params
  });
}

// 查询支付宝企业码消费明细
export function consumptionDetailRequest(params) {
  return request({
    url: '/order/guideBorrow/alipay-consume-l',
    method: 'get',
    params
  });
}