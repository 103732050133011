import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
/*
 * @Author: cyg
 * @Date: 2022-10-25 16:02:35
 * @LastEditors: cyg
 * @LastEditTime: 2022-10-28 09:14:19
 * @FilePath: \travel-admin\src\router\modules\payment.js
 */
// 增值服务
// import Layout from '@/layout/SidebarBlank'
import Layout from '@/layout';
const paymentRouter = {
  path: '/payment',
  component: Layout,
  redirect: '/payment/index',
  name: 'payment',
  meta: {
    auth: true,
    icon: 'icon-yonghuguanli'
  },
  children: [{
    path: '/payment/index',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/payment/index'))),
    name: 'paymentIndex',
    meta: {
      title: 'paymentIndex',
      icon: 'icon-yonghuguanli',
      auth: true
    }
  }]
};
export default paymentRouter;