import { SelectProduct, SelectStaff, SelectCustom, SelectGuide, SelectDictionary } from '@/components/common';
import { searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectProduct,
    SelectStaff,
    SelectCustom,
    SelectGuide,
    SelectDictionary
  },
  mixins: [searchMixin],
  // props: {
  //   search: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      planStatusLabel: '',
      searchParams: {
        startTripDateS: parseDate()
      }
    };
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchParams);
    },
    changeProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productName = val.name;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    changeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeDutyOPUserName(val) {
      this.searchParams.dutyOPUserId = val.value;
    },
    changeOPUserName(val) {
      this.searchParams.outOPUserId = val.value;
    },
    selectGuideName(val) {
      this.searchParams.guideName = val.label;
    },
    selectGroupStatus(val) {
      val = val ? val : {
        label: '',
        value: ''
      };
      this.searchParams.planStatus = val.value;
      this.planStatusLabel = val.label;
    },
    changeCustom(val) {
      const {
        name
      } = val;
      this.searchParams.customerName = name;
    },
    // 选择团队类型
    selectTeamType(val) {
      const {
        value
      } = val;
      this.searchParams.groupType = value;
    }
  }
};