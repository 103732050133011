var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planNumber", $$v);
      },
      expression: "searchParams.planNumber"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "订单类型",
      list: _vm.reportPlanType,
      clearable: ""
    },
    on: {
      "on-select": _vm.selectOrderType
    },
    model: {
      value: _vm.searchParams.orderType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderType", $$v);
      },
      expression: "searchParams.orderType"
    }
  }), _c("InputDate", {
    attrs: {
      label: "选择年月",
      type: "month",
      "is-require": ""
    },
    on: {
      change: _vm.changeMonth
    },
    model: {
      value: _vm.searchParams.yearMonth,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "yearMonth", $$v);
      },
      expression: "searchParams.yearMonth"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: true,
      "show-summary": "",
      border: ""
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };