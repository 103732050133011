// const width80 = 80

// 订单状态，error=提交下单错误,wait=待接单，accepted=已接受，refused=已拒绝，completed=已完成，waitConfirm=待确认， cancel=已取消,可用值:ERROR,WAIT,ACCEPTED,REFUSED,COMPLETED,WAIT_CONFIRM,CANCEL
export const resourceOrderStatus = {
  ERROR: 'ERROR',
  WAIT: 'WAIT',
  ACCEPTED: 'ACCEPTED',
  REFUSED: 'REFUSED',
  COMPLETED: 'COMPLETED',
  WAIT_CONFIRM: 'WAIT_CONFIRM',
  CANCEL: 'CANCEL'
};
export const resourceOrderLabel = {
  ERROR: '下单错误',
  WAIT: '待接单',
  ACCEPTED: '已接单',
  REFUSED: '已拒绝',
  COMPLETED: '已完成',
  WAIT_CONFIRM: '待确认',
  CANCEL: '已取消'
};
export const resourceOrderStatusTagType = {
  WAIT: 'primary',
  ACCEPTED: 'success',
  REFUSED: 'danger',
  COMPLETED: 'success',
  WAIT_CONFIRM: 'warning',
  CANCEL: 'warning',
  NULL: 'warning'
};
export const resourceOrderStatusLabel = {
  WAIT: '待接单',
  ACCEPTED: '已接单',
  REFUSED: '已拒绝',
  COMPLETED: '已完成',
  WAIT_CONFIRM: '待确认',
  CANCEL: '已取消'
};
export const resourceOrderType = {
  ERROR: 'danger',
  WAIT: 'warning',
  ACCEPTED: 'primary',
  REFUSED: 'danger',
  COMPLETED: 'success',
  WAIT_CONFIRM: 'primary',
  CANCEL: 'danger'
};

// 确认修改申请，审核状态
// -1 = 取消、待审核, 0=未通过，1=通过
export const resourceOrderConfirmStatus = {
  CANCEL: 1,
  WAIT: 0,
  PASS: 1
};

// 付款方式
export const resourcePayType = {
  guide: 'GUIDE',
  cash: 'CASH',
  // 现付
  sign: 'SIGN',
  // 签单
  agency: 'AGENCY' // 公司转账
};

// 付款方式
export const resourcePayTypeLabel = {
  guide: '导游现付',
  cash: '公司现付',
  // 现付
  sign: '挂账',
  // 签单
  agency: '公司转账',
  // 公司转账
  guidesign: '导游签单',
  ALIPAY_E_CODE: '企业码付',
  alipay_e_code: '企业码付',
  alipayecode: '企业码付'
};

// const planNumber = {
//   attrs: { prop: 'planNumber', label: '大团号' },
//   slot: 'planNumber',
// }
const orderAndPlanNumber = {
  attrs: {
    prop: '',
    label: '订单编号/大团号',
    width: 160
  },
  slot: 'orderAndPlanNumber'
};
const orderStatus = {
  attrs: {
    prop: 'orderStatus',
    label: '状态'
  },
  slot: 'orderStatus'
};
// const hotelOrderStatus = {
//   attrs: { prop: 'hotelOrderStatus', label: '酒店订单状态' },
// }
// const orderNumber = { attrs: { prop: 'orderNumber', label: '订单编号' }, slot: 'orderNumber' }
const hotelName = {
  attrs: {
    prop: 'hotelName',
    label: '酒店名称'
  }
};
const fleetName = {
  attrs: {
    prop: 'busName',
    label: '车队名称'
  }
};
const inDate = {
  prop: 'inDate',
  label: '入住日期'
};
const outDate = {
  prop: 'outDate',
  label: '离店日期'
};
const orderDate = {
  prop: 'orderDate',
  label: '接单日期'
};
// const startBusDate = {
//   attrs: {
//     prop: 'startDate',
//     label: '用车开始日期',
//     'min-width': 120,
//   },
// }
// const endBusDate = {
//   attrs: { prop: 'endDate', label: '用车结束日期', 'min-width': 120 },
// }

const personLiableName = {
  attrs: {
    prop: 'personLiableName',
    label: '跟单人'
  }
};
const roomType = {
  prop: 'roomType',
  label: '房型'
};
// const count = { attrs: { prop: 'count', label: '间数', width: 60 } }
const remarks = {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
};
const price = {
  attrs: {
    prop: 'price',
    label: '单价'
  }
};

// 资源中心酒店订单
export const resourceOrderHotel = [orderStatus, orderAndPlanNumber, {
  attrs: {
    prop: 'companyName',
    label: '订房中心'
  }
}, hotelName, {
  attrs: {
    ...orderDate
  }
}, personLiableName, {
  attrs: {
    ...inDate
  }
}, {
  attrs: {
    ...outDate
  }
}, {
  attrs: {
    prop: 'checkInName',
    label: '入住人'
  }
}, {
  attrs: {
    ...roomType
  }
}, {
  attrs: {
    prop: 'count',
    label: '间数'
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, price, {
  attrs: {
    prop: 'totalPay',
    label: '应付'
  }
}, remarks, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 资源中心车队订单
export const resourceOrderFleet = [orderStatus, orderAndPlanNumber, {
  attrs: {
    prop: 'companyName',
    label: '预定中心'
  }
}, {
  attrs: {
    prop: 'pickRemarks',
    label: '接团信息'
  },
  slot: 'pick'
}, {
  attrs: {
    prop: 'sendRemarks',
    label: '送团信息'
  },
  slot: 'send'
}, fleetName, {
  attrs: {
    prop: 'busType',
    label: '车型'
  }
},
// { attrs: { prop: 'licenceNumber', label: '车牌' } },
{
  attrs: {
    prop: 'date',
    label: '用车时间'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    ...orderDate
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'price',
    label: '车费'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '应付'
  }
}, personLiableName, remarks, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 资源中心餐厅订单
export const resourceOrderRestaurant = [orderStatus, orderAndPlanNumber, {
  attrs: {
    prop: 'companyName',
    label: '预定中心'
  }
}, {
  attrs: {
    ...orderDate
  }
}, {
  attrs: {
    prop: 'restaurantName',
    label: '餐厅'
  }
}, {
  attrs: {
    prop: 'date',
    label: '用餐日期'
  },
  slot: 'useDate'
}, {
  attrs: {
    prop: 'restaurantArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'mealType',
    label: '用餐类别'
  },
  slot: 'mealType'
}, {
  attrs: {
    prop: 'type',
    label: '餐厅类型'
  }
}, {
  attrs: {
    prop: 'restaurantType',
    label: '餐标类型'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, personLiableName, remarks, price, {
  attrs: {
    prop: 'totalPay',
    label: '应付',
    width: 60
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 资源中心景区订单
export const resourceOrderScenic = [orderStatus, orderAndPlanNumber, {
  attrs: {
    prop: 'companyName',
    label: '预定中心'
  }
}, {
  attrs: {
    prop: 'scenicName',
    label: '景区'
  }
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票类型'
  }
}, {
  attrs: {
    ...orderDate
  }
}, {
  attrs: {
    prop: 'useDate',
    label: '游览日期'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  }
}, personLiableName, remarks, price, {
  attrs: {
    prop: 'totalPay',
    label: '应付',
    width: 60
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];

// 资源中心酒店订单详情
export const resourceOrderHotelInfo = [{
  attrs: {
    ...inDate,
    width: 180
  },
  slot: 'inDate'
}, {
  attrs: {
    prop: 'days',
    label: '天数',
    width: 60
  }
}, {
  attrs: {
    ...outDate,
    width: 180
  },
  slot: 'outDate'
}, {
  attrs: {
    ...roomType,
    width: 120
  },
  slot: 'roomType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  },
  slot: 'freeCount'
}, {
  attrs: {
    prop: 'price',
    label: '接价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'checkInName',
    label: '客人信息'
  },
  slot: 'checkInName'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}];

// 资源中心酒店订单详情
export const resourceOrderFleetInfo = [{
  attrs: {
    ...inDate,
    width: 180
  },
  slot: 'inDate'
}, {
  attrs: {
    ...outDate,
    width: 180
  },
  slot: 'outDate'
}, {
  attrs: {
    prop: 'busType',
    label: '车型'
  },
  slot: 'busType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'price',
    label: '接价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'checkInName',
    label: '客人信息'
  },
  slot: 'checkInName'
}, remarks];

// 付款页面房间信息
export const resourceOrderPayInfo = [{
  attrs: {
    prop: 'roomType',
    label: '房型'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'money',
    label: '合计'
  },
  slot: 'money'
}];
const action = {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 100
  },
  slot: 'action'
};
export const orderHotel = [{
  attrs: {
    prop: 'orderDate',
    label: '入住日期',
    width: 220
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'area',
    label: '区域'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'standard',
    label: '标准'
  },
  slot: 'standard'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action'
}];
export const orderFleet = [{
  attrs: {
    prop: 'startDate',
    label: '开始日期',
    width: 220
  },
  slot: 'startDate'
}, {
  attrs: {
    prop: 'startTime',
    label: '时间',
    width: 100
  },
  slot: 'startTime'
}, {
  attrs: {
    prop: 'pick',
    label: '接'
  },
  slot: 'pick'
}, {
  attrs: {
    prop: 'endDate',
    label: '结束日期',
    width: 220
  },
  slot: 'endDate'
}, {
  attrs: {
    prop: 'endTime',
    label: '时间',
    width: 100
  },
  slot: 'endTime'
}, {
  attrs: {
    prop: 'send',
    label: '送'
  },
  slot: 'send'
}, {
  attrs: {
    prop: 'busType',
    label: '车型'
  },
  slot: 'busType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, action];
export const orderFleetInfo = [{
  attrs: {
    prop: 'startDate',
    label: '开始日期',
    width: 220
  },
  slot: 'startDate'
}, {
  attrs: {
    prop: 'startTime',
    label: '时间',
    width: 100
  },
  slot: 'startTime'
}, {
  attrs: {
    prop: 'pick',
    label: '接'
  },
  slot: 'pick'
}, {
  attrs: {
    prop: 'endDate',
    label: '结束日期',
    width: 220
  },
  slot: 'endDate'
}, {
  attrs: {
    prop: 'endTime',
    label: '时间',
    width: 100
  },
  slot: 'endTime'
}, {
  attrs: {
    prop: 'send',
    label: '送'
  },
  slot: 'send'
}, {
  attrs: {
    prop: 'busType',
    label: '车型'
  },
  slot: 'busType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}];
export const orderRestaurant = [{
  attrs: {
    prop: 'orderDate',
    label: '日期',
    width: 220
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'area',
    label: '用餐区域'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'restaurantType',
    label: '餐厅类别'
  },
  slot: 'restaurantType'
}, {
  attrs: {
    prop: 'mealType',
    label: '用餐类型'
  },
  slot: 'mealType'
}, {
  attrs: {
    prop: 'restaurantStandardType',
    label: '餐标'
  },
  slot: 'standardType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, action];
export const orderRestaurantInfo = [{
  attrs: {
    prop: 'useDate',
    label: '订单日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'area',
    label: '用餐区域'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'restaurantType',
    label: '餐厅类型'
  },
  slot: 'restaurantType'
},
// 餐厅类别
{
  attrs: {
    prop: 'mealType',
    label: '用餐类型'
  },
  slot: 'mealType'
}, {
  attrs: {
    prop: 'standardType',
    label: '标准'
  },
  slot: 'standardType'
},
// 餐标
{
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  },
  slot: 'freeCount'
},
// { attrs: { prop: 'price', label: '单价', width: 90 }, slot: 'price' },
{
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}];
export const orderScenic = [{
  attrs: {
    prop: 'orderDate',
    label: '日期',
    width: 220
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'company',
    label: '资源中心'
  },
  slot: 'company'
}, {
  attrs: {
    prop: 'scenicName',
    label: '景区'
  },
  slot: 'scenicName'
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票类型'
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  },
  slot: 'remark'
}, action];
export const orderScenicInfo = [{
  attrs: {
    prop: 'orderDate',
    label: '订单日期'
  },
  slot: 'orderDate'
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票类型'
  },
  slot: 'ticketType'
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'freeCount',
    label: '免去数量'
  },
  slot: 'freeCount'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}];