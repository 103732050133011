import billDownMixin from '@/views/billDown/billDownMixin';
import SearchUnPay from '../../components/SearchUnPay';
import { TripInfo } from '@/components/common';
import { Guides, StatusAuditProgress } from '@/components/common';
import { rowspanMixin } from '@/mixin';
import { UnPayColumn as columnData } from '@/config/billDown/payUnit/hotel';
export default {
  components: {
    TripInfo,
    Guides,
    SearchUnPay,
    StatusAuditProgress
  },
  mixins: [rowspanMixin, billDownMixin],
  props: {
    resourceId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      columnData,
      tableData: [],
      total: 0,
      billCheckAll: false,
      headMergeNum: 6,
      tailMergeNum: 9,
      hasMergeTail: false,
      statisticsKeys: ['totalPay', 'guidePay', 'agencyPay', 'otherFee', 'downPayed', 'owePay']
    };
  },
  watch: {
    resourceId: {
      handler: function (rid) {
        if (rid) {
          this.fetchData({
            resourceId: this.resourceId
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      if (!this.resourceId && !params.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择单位'
        });
        return;
      }
      this.fetchUnPayData(params);
    },
    fetchUnPayData(params = {}) {
      return this.$store.dispatch('bill/billDown/payUnitHotel/fetchUnPay', {
        resourceId: this.resourceId,
        ...params
      }).then(data => {
        if (!data) return;
        this.handleData(data);
        this.rowspan();
        this.handleAutoDown();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableData = list;
      this.total = totalCount;
    },
    caclDownMoney(money) {
      if (!this.tableData.length) return;
      // mixin方法
      this.handleAutoDown(money);
    }
  }
};