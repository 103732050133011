import "core-js/modules/es.array.push.js";
import { ShuttleType } from '@/config/plan';
import { remarkText } from '@/config/remark';
export default {
  data() {
    return {
      remarkText,
      tableData: [],
      curOrder: ''
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // 获取备注
    fetchOrData() {
      const {
        id: planId
      } = this.$route.query;
      this.$nextTick(() => {
        this.$store.dispatch('plan/index/fetchPlanIdOrderRemarks', {
          planId
        }).then(res => {
          // 目前只需要用到团队的所以有数据的话就只取第一条
          if (res.length) {
            Object.keys(this.remarkText).forEach(key => {
              const item = {
                label: this.remarkText[key],
                name: key,
                value: res[0][key]
              };
              this.$set(item, key, res[0][key]);
              this.curOrder[key] = res[0][key];
            });
          }
        });
      });
    },
    formatGuides(guides) {
      if (!guides.length) return '';
      const guidesReimg = guides.filter(item => item.reimb);
      if (guidesReimg.length) {
        return guidesReimg[0].name + `(${guidesReimg[0].mobileIphone})`;
      }
    },
    fetchData() {
      const {
        id,
        planType
      } = this.$route.query;
      this.$store.dispatch('plan/index/fetchPlanById', id).then(data => {
        this.curOrder = data;
        this.fetchFleetData(id, planType);
      });
      this.$store.dispatch('plan/index/getOrderByPlanId', id);
    },
    // 获取车队信息
    fetchFleetData(planId, planType) {
      this.$store.dispatch('plan/fleet/fetch', {
        planId,
        shuttleType: ShuttleType.bus
      }).then(data => {
        this.tableData = this.formatData(data);
        if (planType == 'T_TOURIST') {
          this.fetchOrData();
        }
      });
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = it => {
        const itemList = it.respOrderBusCompanyTypeSimples;
        const item = {
          ...it,
          rowId: it.id,
          planFleetId: it.id,
          isChecked: false,
          busCompanyId: it.busCompanyId,
          busCompanyName: it.busCompanyName,
          payType: (it.payType || 'guidesign').toLowerCase(),
          useType: it.useType,
          totalPay: it.totalPay,
          remark: it.remark,
          planId: it.planId,
          driverList: [],
          busList: []
        };
        if (itemList && itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              busType: v.busType.split('|')[1] || '',
              busTypeName: v.busType.split('|')[0] || '',
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      };
      list.forEach(it => {
        handleFn(it);
      });
      // result.map(it => {
      //   this.$set(it, 'totalPay', this.calcRowTotalMoney(it))
      // })
      return result;
    }
  }
};