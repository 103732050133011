import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表
export function fetchAccount(params) {
  // const url = parseQuerys('/resource/bank/page', queryParams)
  return request({
    url: '/resource/bank/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateAccount(data) {
  return request({
    url: '/resource/bank/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteAccount(id) {
  return request({
    url: '/resource/bank/del',
    method: 'post',
    data: {
      bankId: id
    }
  });
}

// 卡内调账
export function fetchAdjust(data) {
  return request({
    url: '/resource/bank/adjust',
    method: 'post',
    data
  });
}

// 卡卡调账
export function fetchTransfert(data) {
  return request({
    url: '/resource/bank/transfer',
    method: 'post',
    data
  });
}