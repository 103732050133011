// import { formartInputData } from '@/utils/data'
import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '线路'
    },
    initVal: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    lines: []
  }),
  computed: {
    ...mapGetters('resource/line', ['lineData'])
  },
  watch: {
    lineData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.lines = data.list;
    }
  }
};