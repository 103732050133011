/*
 * @Author: HelloMan
 * @Date: 2021-08-11 09:28:09
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-08 10:05:46
 * @Description:
 */
import store from './store';
import { getUserData } from '@/utils/cookie';
export const getComponentsData = () => {
  // 部门
  const userInfo = getUserData();
  if (userInfo != '{}') {
    if (!store.state['system/role'].roleCustomerInfoAuth) {
      store.dispatch('system/role/getRoleCustomerAuth', userInfo.roleId);
    }
    if (!store.state['system/department'].departmentData) {
      store.dispatch('system/department/fetch', userInfo.travelAgencyId);
    }
    if (!store.state['system/role'].rolePermissionList) {
      store.dispatch('system/role/fetchPermission', userInfo.roleId);
    }
  }

  // 字典数据
  if (!store.state['system/dictionary'].dictionaryCodeData) {
    store.dispatch('system/dictionary/fetchDictionaryType');
  }

  // 系统配置
  if (!store.state['system/systemConf'].configData) {
    store.dispatch('system/systemConf/fetchType');
  }

  // 菜单操作权限： 已开启
  if (!store.state['permission'].rolePermissionList) {
    store.dispatch('permission/rolePermissionList');
  }

  // 菜单权限所有
  if (!store.state['permission'].authPermission) {
    store.dispatch('permission/permissionRole');
  }

  // 角色
  if (!store.state['system/role'].roleData) {
    store.dispatch('system/role/fetch');
  }

  // // 员工
  // if (!store.state['system/staff'].staffData) {
  //   store.dispatch('system/staff/fetch')
  // }

  // // 客户
  // if (!store.state['resource/custom'].customData) {
  //   store.dispatch('resource/custom/fetchList')
  // }

  // 会计科目
  if (!store.state['system/accounting'].subjectData) {
    store.dispatch('system/accounting/fetchListLevel');
  }

  // 导游
  if (!store.state['resource/guide'].guideData) {
    store.dispatch('resource/guide/fetchList');
  }

  // 银行账户
  if (!store.state['resource/account'].accountData) {
    store.dispatch('resource/account/fetchList');
  }

  // 车队（石君要默认接送的车队名称）
  if (!store.state['resource/fleet'].fleetData) {
    store.dispatch('resource/fleet/fetchList');
  }

  // 系统配置
  if (!store.state['system/buttJoint'].configData) {
    store.dispatch('system/buttJoint/getConfig');
  }
};