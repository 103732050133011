import "core-js/modules/es.array.push.js";
import DialogTraffic from './components/DialogTraffic';
import DialogPickup from './components/DialogPickup';
import DialogItinerary from './components/DialogItinerary';
import DialogNotice from './components/DialogNotice';
import TableItinerary from './components/TableItinerary';
import TableHotel from './components/TableHotel';
import TableOtherIncome from './components/TableOtherIncome';
import TableOtherPay from './components/TableOtherPay';
import TableInsurance from './components/TableInsurance';
import { Credits } from '@/components/common';
import DialogCustom from './components/DialogCustom';
import DialogCount from './components/DialogCount';
import DialogTableTourist from './components/DialogTableTourist';
import DialogGroupFund from './components/DialogGroupFund';
import DialogItineraryDate from './components/DialogItineraryDate';
import DialogTablePickup from './components/DialogTablePickup';
import DialogTableHotel from './components/DialogTableHotel';
import { customerInfoMixin } from '@/mixin';
import { reviewerStatusType, reviewerStatus } from '@/config/order';
export default {
  components: {
    Credits,
    TableOtherIncome,
    TableOtherPay,
    TableItinerary,
    DialogItinerary,
    DialogPickup,
    DialogTraffic,
    DialogNotice,
    TableHotel,
    TableInsurance,
    DialogCustom,
    DialogCount,
    DialogTableTourist,
    DialogGroupFund,
    DialogItineraryDate,
    DialogTablePickup,
    DialogTableHotel
  },
  mixins: [customerInfoMixin],
  data() {
    return {
      reviewerStatusType,
      reviewerStatus,
      orderType: ''
    };
  },
  computed: {
    orderAuditStatus() {
      return item => {
        const {
          addAuditStatus
        } = item;
        if (addAuditStatus && addAuditStatus != reviewerStatusType.audited) {
          return true;
        }
        return false;
      };
    }
  },
  methods: {
    // 更新订单信息
    createOrUpdate(opts) {
      this.$store.dispatch('order/index/createOrUpdate', opts).then(() => {
        this.$bus.tip();
        this.$parent.fetchData();
      });
    },
    // 打开人数修改
    onCount({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.countRef.show(res);
      });
    },
    fetchData(id) {
      return this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    // 打开客户编辑弹窗
    onCustomShow(row) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        if (this.hasCustomerInfo) {
          this.$refs.customRef.orderShow(res);
        } else {
          this.$bus.tip({
            type: 'warning',
            message: '没有操作客户权限'
          });
        }
      });
    },
    onShuttle({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.tablePickupRef.show(res);
      });
    },
    onHotelShow({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.tablehotelRef.show(res);
      });
    },
    // 打开客户编辑弹窗
    onTourist({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.tableTouristRef.show(res);
      });
    },
    handleSearch() {
      this.$parent.handleSearch();
    },
    // 团款
    onFundDialog({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.groupFundRef.show(res);
      });
    },
    // 更改行程日期
    onItineraryDate({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.fetchData(id).then(res => {
        this.$store.commit('order/index/CUR_ORDER_ID', id);
        this.$store.commit('order/index/CUR_ORDER', res);
        this.$refs.itineraryDateRef.show(res);
      });
    },
    // 跳转审核详情页
    linkAuditDetail({
      row
    }) {
      const {
        id
      } = row;
      if (!this.comparePlanStatus(row)) return;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: '/order/order-audit-detail',
        query: {
          id,
          type: 'detail',
          isReview: 1
        }
      });
    },
    tableRowClassName({
      row
    }) {
      if (row.isCancel) {
        return 'warning-row';
      }
      // 是否开启订单审核
      const code = this.getConfigStatus('order:openOrderAudit:openOrderAuditValue');
      if (code && row.addAuditStatus && row.addAuditStatus != this.reviewerStatusType.audited) {
        return 'light-red';
      }
      return '';
    }
  }
};