import { TripInfo } from '@/components/common';
import { Guides, StatusAuditProgress } from '@/components/common';
import { reloadMixin } from '@/mixin';
import { GuideBorrowBatch as columnData } from '@/config/billDown/guideBorrow';
// import { setAutoDown } from '@/views/billDown/help'
import { mapGetters } from 'vuex';
export default {
  components: {
    TripInfo,
    Guides,
    StatusAuditProgress
  },
  mixins: [reloadMixin],
  props: {
    resourceId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      columnData,
      billCheckAll: false
    };
  },
  computed: {
    ...mapGetters({
      data: 'bill/guideBorrow/data',
      total: 'bill/guideBorrow/total'
    }),
    tableData() {
      return this.data ? this.data.list : [];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      }
    }
  },
  methods: {
    fetchData(params = {}) {
      return this.$store.dispatch('bill/guideBorrow/fetchList', params);
    },
    changeCheckbox(row, val) {
      row.downPayed = val ? row.owePayCopy : '';
    },
    caclDownMoney(money) {
      if (!this.tableData.length) return;

      // 设置下账金额
      this.changeCheckAll(money);
    },
    changeCheckAll(val) {
      // 自动填充下账数据
      this.billCheckAll = val;
      this.tableData.forEach(it => {
        it.isChecked = val;
        it.downPayed = val ? it.owePayCopy : '';
      });
    },
    restore() {
      this.billCheckAll = false;
    }
  }
};