import IconRight from '../IconRight';
import IconWrong from '../IconWrong';
import { orderItineraryDetail } from '@/config/order';
export default {
  components: {
    IconRight,
    IconWrong
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: orderItineraryDetail
    };
  }
};