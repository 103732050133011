import { planIsOrderInfoMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [planIsOrderInfoMixin],
  data() {
    return {
      subsidy: '0'
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo'
    })
  },
  watch: {
    planInfo: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        const {
          planId
        } = this.$route.query;
        this.getPlanInfo({
          planId
        });
      }
      this.subsidy = data.customerShopSubsidy || 0;
    },
    // 获取团信息
    getPlanInfo(planId) {
      this.$store.dispatch('plan/index/fetchPlanById', planId);
    },
    saveAndAudit() {
      const {
        planId
      } = this.$route.query;
      const opts = {
        id: planId,
        subsidy: +this.subsidy
      };
      return this.$store.dispatch('plan/index/updateCustomerSubsid', opts);
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};