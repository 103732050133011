import { tableMixin } from './mixin';
import { tagViewMixin, searchMixin } from '@/mixin';
import { SelectGuide, SelectStaff } from '@/components/common';
import { mapGetters } from 'vuex';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectStaff
  },
  mixins: [tableMixin, tagViewMixin, searchMixin],
  data() {
    const start = new Date();
    const lastTwoWeek = parseDate(new Date(start.getTime() - 3600 * 1000 * 24 * 7 * 2));
    return {
      sendDate: [lastTwoWeek],
      searchParams: {
        startTripDateS: lastTwoWeek
      }
    };
  },
  computed: {
    ...mapGetters({
      ticketPlanData: 'ticket/index/ticketPlanData'
    })
  },
  watch: {
    ticketPlanData: {
      handler: 'initData',
      immediate: true
    }
  },
  mounted() {
    this.onEmit();
  },
  methods: {
    onEmit() {
      this.$bus.on('ticket:reload', this.handleReload);
    },
    fetchData(queryParams) {
      this.tableLoading = true;
      return this.$store.dispatch('ticket/index/fetchPlan', queryParams).then(data => {
        this.tableLoading = false;
        const {
          list,
          totalCount
        } = data;
        this.total = totalCount;
        this.tableData = list;
        return data;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    changeStartDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.startTripDateS = startDate || '';
      this.searchParams.startTripDateE = endDate || '';
    },
    changeEndDate(val) {
      const [startDate, endDate] = val;
      this.searchParams.endTripDateS = startDate || '';
      this.searchParams.endTripDateE = endDate || '';
    },
    selectGuide(val) {
      const {
        label
      } = val;
      this.searchParams.guideName = label;
    },
    handleReload(id) {
      this.fetchData(this.searchParams).then(data => {
        const {
          list
        } = data;
        this.updateCurrentPlan(list, id);
      });
    },
    updateCurrentPlan(list, id) {
      const findItem = list.find(it => it.id == id);
      this.$store.commit('ticket/index/CURRENT_PLAN', findItem);
    }
  }
};