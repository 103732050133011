/*
 * @Author: HelloMan
 * @Date: 2021-01-22 10:50:58
 * @LastEditors: cyg
 * @LastEditTime: 2023-04-07 16:20:37
 * @FilePath: \travel-admin\src\mixin\permission.js
 */
import permission from '@/directive/permission/index';
import { CustomAuthKeys } from '@/config/permission';
export default {
  directives: {
    permission
  },
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  computed: {
    // 角色信息
    roleCustomerInfoAuth() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  methods: {
    hasPermission(authKey = CustomAuthKeys.hotelFloorPrice) {
      const authObj = this.$store.getters['permission/authPermission'];
      return authObj[authKey];
    },
    hasSettlementPrice(authKey = CustomAuthKeys.hotelFloorPrice) {
      return this.hasPermission(authKey);
    },
    hasSubsidyTwoPrice() {
      return this.roleCustomerInfoAuth && this.roleCustomerInfoAuth.showShopSubsidy;
    }
  }
};