var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FormBase", {
    ref: "formRef",
    staticClass: "billdown-regiment-form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "80px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "下账模式"
    }
  }, [_c("RadioGroup", {
    attrs: {
      type: "button",
      radios: _vm.radios
    },
    on: {
      change: _vm.changeRadio
    },
    model: {
      value: _vm.radioVal,
      callback: function ($$v) {
        _vm.radioVal = $$v;
      },
      expression: "radioVal"
    }
  }), _vm.isPrePaymentMode ? _c("div", {
    staticClass: "pl10"
  }, [_vm._v(" 预付款余额 "), _c("strong", {
    staticClass: "red pl10"
  }, [_vm._v(_vm._s(_vm.prePaymentMoney) + "元")])]) : _vm._e()], 1), _c("FormItemBase", {
    attrs: {
      label: "当前金额"
    }
  }, [_c("strong", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.currentMoney))])]), _c("FormItemBase", {
    attrs: {
      label: "余额"
    }
  }, [_c("strong", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.getBalance))])])], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      prop: "resourceId",
      label: "直营单位"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.resName,
      disabled: ""
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "cashDate",
      label: "日期"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      "default-format": "yyyy-MM-dd",
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.cashDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashDate", $$v);
      },
      expression: "form.cashDate"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "money",
      label: "下账金额"
    }
  }, [_c("InputNum", {
    attrs: {
      "controls-position": "right",
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.money,
      callback: function ($$v) {
        _vm.$set(_vm.form, "money", $$v);
      },
      expression: "form.money"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      prop: "subject",
      label: "会计科目"
    }
  }, [_c("SelectAccount", {
    attrs: {
      "init-val": _vm.form,
      label: "",
      disabled: _vm.isUpdate
    },
    on: {
      "on-select": _vm.selectAccount
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "cashWay",
      label: "付款方式"
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "payType2",
      label: "",
      disabled: _vm.isUpdate
    },
    on: {
      "on-select": _vm.changeCashMode
    },
    model: {
      value: _vm.form.cashWay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashWay", $$v);
      },
      expression: "form.cashWay"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "bankId",
      label: "银行卡"
    }
  }, [_c("SelectBankCard", {
    attrs: {
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.bankId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankId", $$v);
      },
      expression: "form.bankId"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      prop: "cashUserId",
      label: "收款人"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.cashUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashUserId", $$v);
      },
      expression: "form.cashUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "invoiceNumber",
      label: "发票号"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.invoiceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceNumber", $$v);
      },
      expression: "form.invoiceNumber"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "invoiceMoney",
      label: "发票金额"
    }
  }, [_c("InputNumber", {
    attrs: {
      "controls-position": "right",
      label: "",
      min: 0,
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.invoiceMoney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceMoney", $$v);
      },
      expression: "form.invoiceMoney"
    }
  })], 1)], 1), _c("FormItemBase", {
    attrs: {
      label: "备注"
    }
  }, [_c("InputRemark", {
    staticClass: "pl10",
    attrs: {
      label: "",
      disabled: _vm.isUpdate
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };