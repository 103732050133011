import "core-js/modules/es.array.push.js";
// 对账参数
import { orderType } from '@/config/order';
import { getCurTagView } from '@/utils/tagView';
export default {
  watch: {
    tableData: {
      handler: function (data) {
        if (data) {
          this.$nextTick(() => {
            this.rowspan();
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    },
    isDisabled() {
      return row => {
        return row.ckStatus || row.headCompanyId > 0;
      };
    }
  },
  methods: {
    directPlanDetail(row) {
      const planId = row.planId || row.inCustomerId;
      this.$store.commit('order/index/CUR_ORDER_ID', planId);
      this.$store.commit('plan/index/CUR_PLAN_ID', planId);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id: planId,
          planType: orderType.team
        }
      });
    },
    directOrderDetail(row) {
      const {
        id,
        orderType
      } = row;
      this.$router.push({
        path: '/order/team-input-detail',
        query: {
          id,
          type: 'detail',
          planType: orderType
        }
      });
    },
    handleCheck(row, val) {
      this.tableData.forEach(it => {
        if (it.rowId == row.rowId) {
          it.ckStatus = val;
        }
      });
    },
    changeCheckBox(row, val) {
      if (!row.checkAccountMoney) {
        row.checkAccountMoney = val ? row.unPay : row.totalPayed;
      }
    },
    // 全选
    changeCheckAll(val) {
      this.tableData.forEach(it => {
        it.ckStatus = val;
        it.isChecked = val;
      });
      this.billCheckAll = val;
    },
    // 格式对账参数
    formatBillCheckOptions({
      row,
      type,
      status
    }) {
      const opts = {
        auditTypeEnum: type,
        checkAccountRemark: row.checkAccountRemark,
        checkAccountMoney: row.checkAccountMoney,
        resourceId: row.rowId || row.id,
        status
      };
      return opts;
    },
    // 单条对账
    handleCheckout(row) {
      const opts = this.formatBillCheckOptions({
        row,
        type: this.type,
        status: !row.checkAccountStatus
      });
      this.updateBillCheck([opts]);
    },
    // 审核所有选中
    handleBillCheck() {
      // const unSelect = this.tableData
      //   .filter(it => !it.ckStatus)
      //   .map(it => it.rowId)

      // const selectList = this.tableData.filter(
      //   it => it.ckStatus && !unSelect.includes(it.rowId)
      // )
      // const unCheckedIds = this.tableData
      //   .filter(it => !it.ckStatus)
      //   .map(it => it.rowId)
      // const checkedList = this.tableData.filter(
      //   it => !unCheckedIds.includes(it.rowId)
      // )

      // const selectList = this.tableData.filter(it =>
      //   checkedList.find(v => v.rowId == it.rowId)
      // )

      const selectList = this.tableData.filter(it => it.ckStatus);
      if (!selectList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何对账数据'
        });
        return;
      }
      const dataList = selectList.map(it => {
        return this.formatBillCheckOptions({
          row: it,
          type: this.type,
          status: !it.checkAccountStatus
        });
      });
      this.updateBillCheck(dataList);
    },
    updateBillCheck(dataList) {
      const loading = this.$bus.loading();
      return this.$store.dispatch('bill/billCheck/billCheck', dataList).then(() => {
        this.$parent.reload(this.getTagViewSearch);
        this.$bus.tip();
        loading.close();
        this.billCheckAll = false;
      }).catch(() => {
        loading.close();
      });
    }
  }
};