var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "团号关键字,多个关键字请用|分割开",
      prop: "keyword"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.keyword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keyword", $$v);
      },
      expression: "form.keyword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "均价",
      prop: "price"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "排序",
      prop: "orderBy"
    }
  }, [_c("el-input-number", {
    model: {
      value: _vm.form.orderBy,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orderBy", $$v);
      },
      expression: "form.orderBy"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "启用状态"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };