import billBaseMixin from '../billBaseMixin.js';
import { StatusAudit } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillScenic, statisticsKeys } from '@/config/billCheck';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    StatusAudit
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      urlType: 'scenicOrder',
      headMergeNum: 5,
      tailMergeNum: checkBillScenic.length - 13,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys: [...statisticsKeys, 'adultCountItem', 'childCountItem', 'adultFreeCount', 'accompanyCountItem'],
      type: TYPES.scenic
    };
  }
};