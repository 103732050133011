var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g(_vm._b({
    attrs: {
      clearable: ""
    },
    on: {
      change: _vm.handleChange,
      focus: _vm.lazyLoad
    }
  }, "el-select", _vm.$attrs, false), _vm.$listeners), _vm._l(_vm.getTypeList, function (item, index) {
    return _c("el-option", {
      key: index + "",
      attrs: {
        label: item.label,
        value: item.value + "",
        disabled: item.disabled
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };