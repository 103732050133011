import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表
export function fetchList(params) {
  return request({
    url: '/resource/resourceBus/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchVehicle(params) {
  return request({
    url: '/resource/resourceBus/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateVehicle(data) {
  return request({
    url: '/resource/resourceBus/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteVehicle(id) {
  return request({
    url: '/resource/resourceBus/del',
    method: 'post',
    data: {
      id
    }
  });
}