import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      visible: false,
      info: null,
      images: []
    };
  },
  computed: {
    ...mapGetters({
      imageList: 'resource/common/imageList' // 照片信息
    })
  },
  watch: {
    imageList: {
      handler: function (list) {
        this.images = list;
      },
      immediate: true
    }
  },
  methods: {
    show(row) {
      this.visible = true;
      this.info = row;
      //   if (!this.imageList.length) {
      this.$store.dispatch('resource/guide/fetchImage', row.id).then(res => {
        this.images = res;
      });
      //   }
    },
    hide() {
      this.visible = false;
      this.images = [];
    }
  }
};