import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 开始/结束日期 input 组件
 * @vue-prop {String} [label=发团时间] - 输入框对应的名称
 * @vue-prop {Boolean} [isRequire=false] - 是否在label前面加 * 标记为必填项
 * @vue-data {String} value - 选择时间后的值
 * @vue-data {Object} pickerOptions - 快捷时间配置信息
 */
export default {
  name: 'InputDateEndPicker',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    showOptions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  }
};