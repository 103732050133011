import FormNotice from './FormNotice';
import TableNotice from './TableNotice';
import PreviewNotice from './PreviewNotice';
import { BtnCreate } from '@/components/common';
import { systemNotice } from '@/config/system';
import { searchMixin } from '@/mixin';
export default {
  components: {
    BtnCreate,
    FormNotice,
    TableNotice,
    PreviewNotice
  },
  mixins: [searchMixin],
  data: () => ({
    title: '',
    tableLoading: false,
    columnData: systemNotice,
    tableList: [],
    previewData: null,
    total: 0
  }),
  computed: {
    noticeData() {
      return this.$store.getters['system/notice/noticeData'];
    }
  },
  watch: {
    noticeData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableList = [];
      this.$store.dispatch('system/notice/fetch', queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.tableList = list;
      this.total = totalCount;
    },
    handleCreate() {
      this.$refs.formRef.show();
    },
    handleEdit(row) {
      this.$refs.formRef.edit();
      this.$store.dispatch('system/notice/fetchDetail', row.id);
    },
    handlePreview(row) {
      this.previewData = row;
      this.$refs.previewRef.show(row);
    },
    // 搜索
    handleSearch() {
      this.fetchData({
        title: this.title
      });
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        title: this.title
      });
    }
  }
};