export const mallOrderStatusMap = {
  WAIT_PAY: '待付款',
  // 待付款
  WAIT_MERGE: '待拼团',
  // 待拼团
  WAIT_TRAVEL: '待出行',
  // 待出行
  TRIPING: '出行中',
  // 出行中
  REFUND: '退款',
  // 退款
  CANCEL: '已取消' // 取消
};
export const mallOrderStatusTypeMap = {
  WAIT_PAY: 'warning',
  // 待付款
  WAIT_MERGE: 'primary',
  // 待拼团
  WAIT_TRAVEL: 'success',
  // 待出行
  TRIPING: 'success',
  // 出行中
  REFUND: 'info',
  // 退款
  CANCEL: 'danger' // 取消
};
export const mallPayStatusMap = {
  WECHAT: '微信',
  UNIONPAY: '银联',
  ALIPAY: '支付宝'
};
export const mallSourceMap = {
  MINIAPPS: '小程序',
  OTHER: '其他'
};