var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_vm.receptionList && _vm.receptionList.length ? [_c("Reception", {
    ref: "receptionRef",
    attrs: {
      type: "plan",
      readonly: _vm.readonly,
      "is-audit": _vm.isAudit
    }
  })] : _c("Empty"), _c("ReceptionEdit", {
    ref: "receptionEditRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };