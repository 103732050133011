import service from '@/utils/request';
// import { parseQuerys } from '@/utils'

export function fetchDictionaryList(id) {
  return service({
    url: `/system/dictionary/list`,
    method: 'post',
    data: {
      dictionaryTypeId: id
    }
  });
}

// 新增或修改字典
export function createOrUpdateDictionary(data) {
  return service({
    url: '/system/dictionary/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除字典
export function deleteDictionary(id) {
  return service({
    url: `/system/dictionary/del?dictionaryId=${id}`,
    method: 'post'
  });
}

// 新增或修改字典类型
export function createOrUpdateDictionaryType(data) {
  return service({
    url: '/system/dictionary/saveOrUpdateType',
    method: 'post',
    data
  });
}

// 获取字典类型列表
export function fetchDictionaryTypeList() {
  return service({
    url: '/system/dictionary/listType',
    method: 'post',
    data: {}
  });
}

// 根据字典类型编码查询列表
export function fetchTypeFormCode(code) {
  return service({
    url: '/system/dictionary/listByTypeCode',
    method: 'post',
    data: {
      dictionaryTypeCode: code
    }
  });
}

// 获取所有字典
export function fetchDictionaryType() {
  return service({
    url: `/system/dictionary/listTypeAndSub`,
    method: 'get'
  });
}