var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "signNumber",
      fn: function ({
        scope
      }) {
        return [_c("TagGroup", {
          attrs: {
            "group-id": scope.row.groupId
          }
        }), _vm._v(" " + _vm._s(scope.row.signNumber) + " ")];
      }
    }, {
      key: "signType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatType(scope.row.signType)) + " ")];
      }
    }, {
      key: "isUsed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.isUsed ? "是" : "否") + " ")];
      }
    }, {
      key: "guideName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideName) + " ")];
      }
    }, {
      key: "numberPeople",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + "大 " + _vm._s(scope.row.childCount) + "小 ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };