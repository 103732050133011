var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isShow ? _c("div", {
    staticClass: "logo-container"
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_c("router-link", {
    key: "collapse",
    staticClass: "logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.getLogo
    }
  }), _c("h1", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.getTitle) + " ")])])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };