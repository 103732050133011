import { downloadExport } from '@/utils/exportExcel';
import TableList from './TableList';
import { ByGuideManagerAnaylzeData } from '@/config/shopping';
import { BtnExport, SelectGuideManager } from '@/components/common';
import { searchMixin, subsidyMixin } from '@/mixin';
export default {
  components: {
    TableList,
    BtnExport,
    SelectGuideManager
  },
  mixins: [searchMixin, subsidyMixin],
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: ByGuideManagerAnaylzeData,
    shopDate: [],
    tableData: [],
    total: 0
  }),
  computed: {
    singleData() {
      return this.$store.getters['shopping/index/singleData'];
    }
  },
  watch: {
    singleData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        const {
          totalCount,
          list
        } = data;
        this.total = totalCount;
        const tableData = list.map(it => {
          it.shopPerCapita = it.shopProfit.shopPerCapita;
          it.totalBuyMoney = it.shopProfit.totalBuyMoney;
          it.totalShopRebate = it.shopProfit.totalShopRebate;
          it.totalGuideRebate = it.shopProfit.totalGuideRebate;
          it.totalGuideManagerRebate = it.shopProfit.totalGuideManagerRebate;
          return it;
        });
        this.tableData = this.processTotalShopRebate(tableData);
      },
      immediate: true
    }
  },
  methods: {
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getPlanShopStatic', queryParam).then(() => {
        // const { list, totalCount } = res
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchPlanShopStaticExcel', {
        ...this.searchParams,
        searchType: 3
      }).then(res => {
        const fileName = '导管派团数据分析表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};