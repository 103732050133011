import axios from 'axios';
import request from '@/utils/request';
import { linkDownload } from '@/utils/download';
import { getToken } from '@/utils/cookie';
const OSS_PATH = 'https://glwllx-saas.oss-cn-guangzhou.aliyuncs.com';
export const requestBlob = ({
  url,
  params = {},
  method = 'GET',
  responseType = 'blob'
}) => {
  return axios.request({
    url,
    method,
    responseType,
    headers: {
      'Access-Control-Allow-Origin': OSS_PATH,
      Authorization: 'bearer ' + getToken()
    },
    params
  });
};
export const downloadBlob = ({
  url,
  fileName
}) => {
  return requestBlob({
    url: decodeURIComponent(url)
  }).then(data => {
    linkDownload({
      data,
      fileName
    });
  });
};

// 资源计划列表
export function listRequest(params) {
  return request({
    url: '/order/res-booking/page',
    method: 'get',
    params
  });
}

// 新增计划列表
export function addRequest(data) {
  return request({
    url: '/order/res-booking/save',
    method: 'post',
    data
  });
}

// 修改计划列表
export function updateRequest(data) {
  return request({
    url: '/order/res-booking/re-booking',
    method: 'post',
    data
  });
}

// 安排计划列表
export function arrangeRequest(data) {
  return request({
    url: '/order/res-booking/arrange',
    method: 'post',
    data
  });
}

// 确认计划列表
export function confirmRequest(data) {
  return request({
    url: '/order/res-booking/confirm',
    method: 'post',
    data
  });
}

// 取消计划列表
export function cancelRequest(data) {
  return request({
    url: '/order/res-booking/cancel',
    method: 'post',
    data
  });
}

// 打印次数
export function printCountRequest(data) {
  return request({
    url: '/order/res-booking/print-count',
    method: 'post',
    data
  });
}

// 根据id获取预定计划
export function detailRequest(params) {
  return request({
    url: '/order/res-booking/booking-id',
    method: 'get',
    params
  });
}

// 根据 预定计划id 获取预定计划的历史记录
export function listHistoryRequest(params) {
  return request({
    url: '/order/res-booking/booking-master-id',
    method: 'get',
    params
  });
}

// 根据历史记录id获取历史预定计划
export function historyIdPlanListRequest(params) {
  return request({
    url: '/order/res-booking/history-booking-id',
    method: 'get',
    params
  });
}

// 获取酒店列表
export function hotelListRequest(params) {
  return request({
    url: '/order/hotel/hotelAll-planNumber',
    method: 'get',
    params
  });
}

// 获取车队列表
export function busListRequest(params) {
  return request({
    url: '/order/orderBus/listAll-planNumber',
    method: 'get',
    params
  });
}

// 订房单 根据id查询订房单
export function orderHotelByIdRequest(params) {
  return request({
    url: '/order/hotel/hotelReservation',
    method: 'get',
    params
  });
}

// 获取酒店订房单列表
export function bookingHotelListRequest(params) {
  return request({
    url: '/order/hotel/hotelReservationList',
    method: 'get',
    params
  });
}