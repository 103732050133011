var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "80%",
      title: "新增协议",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.handleRemove
    }
  }, [_c("div", {
    staticClass: "input-field top-bar"
  }, [_c("strong", [_vm._v(" 新增项目 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" 添加协议项目 ")])], 1), [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      height: "500"
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.startTime))]), _vm._v(" 至 "), _c("span", [_vm._v(_vm._s(scope.row.endTime))])];
      }
    }, {
      key: "detail",
      fn: function ({
        scope
      }) {
        return [_vm.getResourceContent(scope.row.contractPrices) ? _vm._l(_vm.getResourceContent(scope.row.contractPrices), function (item, index) {
          return _c("div", {
            key: index
          }, [_c("p", {
            domProps: {
              innerHTML: _vm._s(item)
            }
          })]);
        }) : _vm._e()];
      }
    }, {
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("SwitchStatus", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeStatus(scope.row);
            }
          },
          model: {
            value: scope.row.isEnable,
            callback: function ($$v) {
              _vm.$set(scope.row, "isEnable", $$v);
            },
            expression: "scope.row.isEnable"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 修改 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], _c("DialogAgreementEdit", {
    ref: "agreementEditRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };