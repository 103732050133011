import "core-js/modules/es.array.push.js";
export default {
  props: {
    label: {
      type: String,
      default: '可见范围'
    },
    initVal: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      props: {
        multiple: true,
        checkStrictly: true
      },
      options: [],
      curVal: []
    };
  },
  computed: {
    data() {
      return this.$store.getters['system/department/departmentData'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
          return;
        }
        this.options = this.formatList(d.list);
      },
      immediate: true
    },
    initVal: {
      handler: function (list) {
        if (!list.length) return;
        const department = [];
        list.forEach(item => {
          const res = [];
          let {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = item;
          res.push(departmentId);
          subDepartmentId > 0 ? res.push(subDepartmentId) : null;
          grandsonDepartmentId > 0 ? res.push(grandsonDepartmentId) : null;
          department.push(res);
        });
        this.curVal = department;
      },
      immediate: true
    }
  },
  // created() {
  //   if (!this.data) {
  //     this.fetchData()
  //   } else {
  //     this.options = this.formatList(this.data.list)
  //   }
  // },

  methods: {
    fetchData() {
      this.$store.dispatch('system/department/fetch').then(data => {
        const {
          list
        } = data;
        this.options = this.formatList(list);
      });
    },
    formatList(list) {
      const listFormat = list => {
        let tmp = [];
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          if (!item.departments) {
            tmp.push({
              value: item.id,
              label: item.name
            });
          } else {
            tmp.push({
              value: item.id,
              label: item.name,
              children: listFormat(item.departments)
            });
          }
        }
        return tmp;
      };
      return listFormat(list);
    }
  }
};