var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-approval"
  }, [_c("h2", [_vm._v("[付酒店]费用请款单")]), _c("span", [_vm._v("(流水号：24241)")]), _c("div", {
    staticClass: "line"
  }), _vm._m(0), _c("div", {
    staticClass: "table-box"
  }, [_c("el-table", {
    attrs: {
      data: _vm.tableData,
      "cell-style": _vm.borderStyle,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "type",
      label: "费用类别",
      align: "center",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "info",
      label: "款项说明",
      align: "center",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "mondy",
      label: "金额",
      align: "center"
    }
  })], 1), _c("el-table", {
    attrs: {
      data: _vm.tableDetail,
      "show-header": false,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "text",
      label: ""
    }
  })], 1), _c("el-table", {
    attrs: {
      data: _vm.tableDetail,
      "show-header": false,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "text",
      label: ""
    }
  }, [_vm._v(" 小团号： ")])], 1)], 1), _vm._m(1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approval-date"
  }, [_c("div", {
    staticClass: "date-item"
  }, [_vm._v(" 申请日期：2023-02-02 ")]), _c("div", {
    staticClass: "date-item"
  }, [_vm._v(" 申请日期：2023-02-02 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approval"
  }, [_c("div", {
    staticClass: "approval-item"
  }, [_vm._v(" 经理： ")]), _c("div", {
    staticClass: "approval-item"
  }, [_vm._v(" 复核： ")]), _c("div", {
    staticClass: "approval-item"
  }, [_vm._v(" 出纳： ")]), _c("div", {
    staticClass: "approval-item"
  }, [_vm._v(" 申请人： ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };