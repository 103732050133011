var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "title-item"
    }, [_c("span", [_vm._v("小团号：" + _vm._s(item.customerTripNumber))]), _c("span", [_vm._v("接站牌：" + _vm._s(item.welcomeBorad))]), _c("span", [_vm._v("线路产品：" + _vm._s(item.productName))]), _c("span", [_vm._v("发团日期：" + _vm._s(item.startTripDate))]), _c("span", [_vm._v("散团日期：" + _vm._s(item.endTripDate))]), _c("span", [_vm._v("外联计调：" + _vm._s(item.outOPUserName))]), _c("span", [_vm._v("人数：" + _vm._s(item.adultCount) + "大" + _vm._s(item.childCount) + "小 " + _vm._s(item.accompanyCount) + "陪")])]), _c("TableBase", {
      attrs: {
        data: item.itinerarys,
        "column-data": _vm.columnData,
        border: ""
      },
      scopedSlots: _vm._u([{
        key: "transportType",
        fn: function ({
          scope
        }) {
          return [_vm._v(" " + _vm._s(_vm.getSelectLabel("bigTraffic", scope.row.transportType)) + " ")];
        }
      }, {
        key: "breakfast",
        fn: function ({
          scope
        }) {
          return [scope.row.breakfast ? _c("IconRight") : _c("IconWrong")];
        }
      }, {
        key: "lunch",
        fn: function ({
          scope
        }) {
          return [scope.row.lunch ? _c("IconRight") : _c("IconWrong")];
        }
      }, {
        key: "dinner",
        fn: function ({
          scope
        }) {
          return [scope.row.dinner ? _c("IconRight") : _c("IconWrong")];
        }
      }], null, true)
    }), _c("div", {
      staticStyle: {
        "line-height": "30px"
      }
    }, [_vm._v(" 备注：" + _vm._s(item.remark) + " ")])], 1);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };