import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 100
      },
      tableData: []
    };
  },
  computed: {
    ...mapGetters('system/invoice', ['invoiceList'])
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$bus.on('invoice:list', this.fetchData);
  },
  methods: {
    fetchData() {
      this.showLoading();
      this.$store.dispatch('system/invoice/fetchList', this.listQuery).then(() => {
        this.hideLoading();
      }).catch(err => {
        this.hideLoading();
        this.$bus.tip({
          type: 'error',
          message: err.message
        });
      });
    },
    handleEdit(index, row) {
      this.$parent.editInvoice(row);
    },
    handleDelete(index, row) {
      this.$bus.open(() => {
        this.showLoading();
        this.$store.dispatch('system/invoice/delete', row.id).then(() => {
          this.$bus.tip({
            message: '删除成功'
          });
          this.hideLoading();
        });
      });
    },
    showLoading() {
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    }
  }
};