var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-upload", _vm._g(_vm._b({
    ref: "uploadRef",
    staticClass: "uploader",
    attrs: {
      headers: _vm.headers,
      accept: _vm.acceptType,
      action: _vm.uploadUrl,
      "before-upload": _vm.beforeUpload,
      "on-success": _vm.handleSuccess
    }
  }, "el-upload", _vm.$attrs, false), _vm.$listeners), [_vm._t("default"), _c("div", {
    staticClass: "avatar"
  }, [_vm.imageUrl ? _c("el-image", {
    attrs: {
      src: _vm.imageUrl,
      fit: "contain"
    }
  }) : _vm._e(), _vm.imageUrl ? _c("i", {
    staticClass: "el-icon-delete-solid delete-icon",
    on: {
      click: function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.handleDelete.apply(null, arguments);
      }
    }
  }) : _c("i", {
    staticClass: "el-icon-plus uploader-icon"
  })], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };