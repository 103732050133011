import { mapGetters } from 'vuex';
import { departKeys, departIdKeys } from '@/config/dictionary';
export default {
  data() {
    return {
      tipMsg: '未选择任何部门则是添加一级部门，选择了部门则是添加子部门',
      visible: false,
      form: {
        departmentId: '',
        subDepartmentId: '',
        grandsonDepartmentId: ''
      },
      title: '',
      editStatus: false,
      rules: {
        name: [{
          required: true,
          message: '部门名称必填'
        }],
        code: [{
          required: true,
          message: '部门编码必填'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    })
  },
  methods: {
    edit(item) {
      this.editStatus = true;
      const {
        level
      } = item;
      const {
        label: name,
        value: id,
        code
      } = item.data;
      this.form = {
        id,
        name,
        code,
        level
      };
      if (level == 2) {
        this.form.departmentId = id;
        this.form.subDepartmentId = item.parent.data.value;
      }
      if (level == 3) {
        this.form.departmentId = id;
        this.form.subDepartmentId = item.parent.data.value;
        this.form.grandsonDepartmentId = item.parent.parent.data.value;
      }
      this.title = '编辑部门';
      this.visible = true;
    },
    show() {
      this.title = '新增部门';
      this.visible = true;
      this.editStatus = false;
      this.form = {};
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    changeDepartment(val) {
      console.log('val:', val);
      departKeys.forEach(key => {
        this.form[key] = val[key];
      });
      departIdKeys.forEach(key => {
        this.form[key] = val[key];
      });
    },
    handleCreate() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const {
          groupId,
          createUserId,
          travelAgencyId
        } = this.userData;
        const {
          id,
          name,
          code
        } = this.form;
        let res = null;
        const opts = {
          id,
          name,
          code,
          travelAgencyId,
          createUserId,
          groupId,
          isEnable: true
        };
        if (!this.editStatus) {
          this.handleNewCreate(res, opts);
        } else {
          this.handleEditCreate(res, opts);
        }
      });
    },
    // 如果是编辑
    handleEditCreate(res, opts) {
      const {
        level,
        subDepartmentId
      } = this.form;
      if (level === 1) {
        res = this.$parent.createDepartment(opts);
      }
      if (level === 2) {
        opts.parentsDepartmentId = subDepartmentId;
        res = this.$parent.createChildDepartment(opts);
      }
      if (level === 3) {
        opts.parentsDepartmentId = subDepartmentId;
        res = this.$parent.createGrandDepartment(opts);
      }
      res.then(() => {
        this.$parent.fetchData();
        this.$bus.tip();
        this.hide();
      });
    },
    // 如果是新建
    handleNewCreate(res, opts) {
      const {
        departmentName,
        subDepartmentName
      } = this.form;
      const {
        departmentId,
        subDepartmentId
      } = this.form;
      if (!departmentName) {
        res = this.$parent.createDepartment(opts);
      }
      if (departmentName && !subDepartmentName) {
        opts.parentsDepartmentId = departmentId;
        res = this.$parent.createChildDepartment(opts);
      }
      if (subDepartmentName) {
        opts.parentsDepartmentId = subDepartmentId;
        res = this.$parent.createGrandDepartment(opts);
      }
      res.then(() => {
        this.$parent.fetchData();
        this.$bus.tip();
        this.hide();
      });
    }
  }
};