import DetailBaseInfo from './components/DetailBaseInfo';
import DetailScenic from './components/DetailScenic';
import DetailOrderList from './components/DetailOrderList';
import DetailItinerary from './components/DetailItinerary';
import { FooterBar, DetailPlanFund, TableTotalProfit } from '@/components/common';
import { HotelPreview, RestaurantPreview, FleetPreview, GuidePreview, GuideBorrowPreview, InsurancePreview, OtherIncomePreview, OtherPayPreview, TrafficPreview, ShoppingPreview } from '@/components/common/Order';
import { orderType } from '@/config/order';
import { planEditHotel,
// planEditHotelAll,
planEditRestaurant, planEditFleet, planEditGuide, planEditGuideBorrow, planEditInsurance, planEditOtherIncome, planEditOtherPay, planEditTraffic, planEditShopping } from '@/config/plan';
import { permissionMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    FooterBar,
    DetailBaseInfo,
    TableTotalProfit,
    DetailScenic,
    DetailPlanFund,
    DetailOrderList,
    DetailItinerary,
    HotelPreview,
    RestaurantPreview,
    FleetPreview,
    GuidePreview,
    GuideBorrowPreview,
    InsurancePreview,
    OtherIncomePreview,
    OtherPayPreview,
    TrafficPreview,
    ShoppingPreview
  },
  mixins: [permissionMixin],
  data() {
    const splitTail = list => {
      return list.slice(0, -1);
    };
    return {
      remarkList: [],
      columnData: planEditHotel,
      planEditRestaurant: splitTail(planEditRestaurant),
      planEditFleet: splitTail(planEditFleet),
      planEditGuide: splitTail(planEditGuide),
      planEditGuideBorrow: splitTail(planEditGuideBorrow),
      planEditInsurance: splitTail(planEditInsurance),
      planEditOtherIncome: splitTail(planEditOtherIncome),
      planEditOtherPay: splitTail(planEditOtherPay),
      planEditTraffic: splitTail(planEditTraffic),
      planEditShopping: splitTail(planEditShopping),
      doNotShowTeamProfit: false
    };
  },
  computed: {
    ...mapGetters({
      rolePermissionList: 'system/role/rolePermissionList',
      currentPlanId: 'plan/index/currentPlanId'
    })
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    if (!id) {
      this.$router.go(-1);
      return;
    }
    if (!this.currentPlanId) {
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
    }
    this.$store.commit('plan/index/FIRST_ENTER', true);
  },
  methods: {
    currentPermission() {
      const list = this.rolePermissionList;
      if (!list || !list.length) return;
      const curNav = list.find(it => it.code === '/plan');
      return curNav.subMenus.find(it => it.code === '/plan/team-arrange-detail');
    },
    getRoleAuthByName(code) {
      const cur = this.currentPermission();
      if (!cur) return;
      const {
        roleMenuOpts
      } = cur;
      const findItem = roleMenuOpts.find(it => it.code == code);
      return findItem && findItem.isRole;
    },
    handlePrint() {
      const {
        id,
        planType,
        toPath
      } = this.$route.query;
      const gateUrl = toPath == '/plan/team-role-arrange' ? 'plan2' : 'plan';
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId: id,
          type: 'plan',
          component: planType == orderType.team ? 'PlanTeam' : 'PlanPersonal',
          planType: planType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    }
  }
};