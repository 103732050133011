/* eslint-disable no-empty-pattern */
// 订单资源计划
import { listRequest, hotelListRequest, busListRequest, detailRequest, listHistoryRequest, historyIdPlanListRequest, downloadBlob, orderHotelByIdRequest, bookingHotelListRequest } from '@/api/order/resourcePlan';
import { addRequest, updateRequest, cancelRequest, confirmRequest, arrangeRequest, printCountRequest } from '@/api/order/resourcePlan';
import { planListRequest } from '@/api/plan';
import { parseTime } from '@/utils';
import { OrderType } from '@/config/order';
function formatData(list) {
  return list.map(it => {
    const {
      createTime,
      masterCreateTime,
      updateTime,
      storyCreateTime
    } = it;
    const lastTime = masterCreateTime || updateTime;
    it.itineraries = it.itinerary;
    it.createTime = parseTime(createTime);
    it.updateTime = updateTime && parseTime(updateTime);
    it.masterCreateTime = lastTime ? parseTime(lastTime) : it.createTime;
    it.storyCreateTime = storyCreateTime && parseTime(storyCreateTime);
    return it;
  });
}
const dfaultState = () => ({
  data: null,
  dataHistory: null,
  curItem: null,
  planList: null
});
const state = dfaultState();
const getters = {
  data: state => state.data,
  dataHistory: state => state.dataHistory,
  curItem: state => state.curItem,
  planList: state => state.planList
};
const actions = {
  fetchPlan({
    commit
  }, params) {
    return planListRequest(params).then(res => {
      if (!res || !res.data) return;
      const data = res.data.map(it => {
        const {
          planNumber,
          startPlanDate: startTripDate,
          endPlanDate: endTripDate,
          id
        } = it;
        return {
          label: planNumber,
          value: id,
          startTripDate,
          endTripDate,
          ...it
        };
      });
      commit('SET_DATA', {
        type: 'planList',
        data
      });
    });
  },
  // 列表
  fetch({
    commit
  }, params) {
    return listRequest({
      ...params,
      bookingType: OrderType.personal
    }).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = formatData(data.list);
      commit('SET_DATA', {
        type: 'data',
        data
      });
    });
  },
  // 详情
  detail({}, params) {
    return detailRequest(params).then(res => {
      if (!res || !res.data) return;
      const data = formatData([res.data]);
      return data[0];
    });
  },
  // 根据id获取历史记录
  fetchHistory({
    commit
  }, params) {
    return listHistoryRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = formatData(data.list);
      commit('SET_DATA', {
        type: 'dataHistory',
        data
      });
      return res.data;
    });
  },
  // 根据历史id获取详情
  getPlanByHistoryId({}, params) {
    return historyIdPlanListRequest(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 新增
  add({}, opts) {
    return addRequest({
      ...opts,
      bookingType: OrderType.personal
    });
  },
  // 更新
  update({}, opts) {
    return updateRequest({
      ...opts,
      bookingType: OrderType.personal
    });
  },
  // 确认
  confirm({}, opts) {
    return confirmRequest(opts);
  },
  // 取消
  cancel({}, opts) {
    return cancelRequest(opts);
  },
  // 安排
  arrange({}, opts) {
    return arrangeRequest(opts);
  },
  // 设置打印次数
  printCount({}, opts) {
    return printCountRequest(opts);
  },
  downloadFile({}, params) {
    return downloadBlob(params);
  },
  // 根据planNumber查询已安排酒店的列表
  hotelList({}, params) {
    return hotelListRequest(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 根据planNumber查询已安排车队的列表
  busList({}, params) {
    return busListRequest(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 订房单 根据id查询订房单
  orderHotelByI({}, params) {
    return orderHotelByIdRequest(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 订房单 根据id查询订房单
  bookingHotelList({}, params) {
    return bookingHotelListRequest(params).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  },
  SET_CUR_ITEM: (state, payload) => {
    state.curItem = payload;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};