import { orderFundProxyDetail, updateStatusType } from '@/config/order';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin, summaryMixin, fundMixin, reloadMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [dictionaryMixin, summaryMixin, fundMixin, reloadMixin],
  data() {
    return {
      columnData: orderFundProxyDetail,
      statisticsKeys: ['total'],
      tableData: [],
      summaryStatus: false
    };
  },
  computed: {
    ...mapGetters({
      // tableData: 'order/index/currentProxy',
      currentOrderId: 'order/index/currentOrderId'
    }),
    getType() {
      return id => {
        const findItem = this.getDictionaryValue(codeType.proxyType, id);
        return findItem && findItem.name;
      };
    }
  },
  // mounted() {
  //   const { id, isReview } = this.$route.query
  //   this.$nextTick(() => {
  //     if (id) {
  //       this.fetchData(id, isReview)
  //     }
  //   })
  // },

  methods: {
    fetchData(id, isReview) {
      // 判断是否显示小计
      if (!isReview) {
        this.summaryStatus = true;
      } else {
        this.summaryStatus = false;
      }
      this.$store.dispatch('order/index/fetchFundProxy', {
        id,
        isReview
      }).then(data => {
        if (!data) return;
        // isReview==1 则是审核查看
        if (isReview == 1) {
          this.tableData = this.formatData(data);
        } else {
          this.tableData = data;
        }
      });
    },
    formatData(data) {
      const keys = ['total', 'remarks', 'type'];
      data.forEach(it => {
        if (!it.isReview) return;
        if (it.updateType == updateStatusType.update) {
          keys.forEach(key => {
            let newKye = it.updateDetails.filter(its => its.attr == key)[0];
            if (!newKye) return;
            if (key == 'total' || key == 'price') {
              newKye.oldVal = newKye.oldVal / 100;
              newKye.newVal = newKye.newVal / 100;
            }
            it[key] = newKye ? `${newKye.oldVal != '' ? newKye.oldVal : '空'}=><span style="color:red">${newKye.newVal}</span>` : it[key];
          });
        }
        if (it.updateType == updateStatusType.del) {
          it.type = `(本条删除)&nbsp;${it.type}`;
        }
        if (it.updateType == updateStatusType.add) {
          it.type = `(本条新增)&nbsp;${it.type}`;
        }
      });
      return data;
    },
    changeCellClassName(scope) {
      const {
        isReview,
        updateType
      } = scope.row;
      if (isReview && updateType == 'UPDATE') {
        // return 'table-color-yellow' //修改的样式
      }
      if (isReview && updateType == 'ADD') {
        return 'pale-green'; //修改的样式
      }
      if (isReview && updateType == 'DEL') {
        return 'table-color-red'; //修改的样式
      }
    }
  }
};