import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
export default {
  mixins: [formMixin],
  props: {
    showType: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {},
    validateForm: {
      account: [{
        required: true,
        message: '请输入账户名'
      }],
      bank: [{
        required: true,
        message: '请输入开户行'
      }],
      bankCard: [{
        required: true,
        message: '请输入银行卡号'
      }],
      bankCardType: [{
        required: true,
        message: '请选择账户类型'
      }]
    }
  }),
  computed: {
    ...mapGetters('resource/account', ['editAccountInfo'])
  },
  watch: {
    editAccountInfo: [{
      handler: 'initAccount',
      immediate: true
    }]
  },
  methods: {
    initAccount(val) {
      val.bankCardType = val.bankCardType ? Number(val.bankCardType) : '';
      this.form = val;
    },
    handleChange(type, val) {
      this.form[type] = val;
      this.$store.dispatch('resource/account/editAccount', this.form);
    }
  }
};