import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { findComponentsUpward, pickSystemConfig } from '@/utils';
import { PAY_TYPE } from '@/config/payType';
import { AuditRole, AuditStatus } from '@/config/audit';
import { systemStateMap } from '@/config/systemConfig';
import { COLLECT_TYPE } from '@/config/collectType';
import permissionMixin from './permission';
export default {
  mixins: [permissionMixin],
  data() {
    return {
      auditCache: []
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      planInfo: 'plan/index/planInfo',
      fetchStatus: 'plan/index/fetchStatus',
      currentPlanId: 'plan/index/currentPlanId',
      currentTabType: 'audit/index/currentTabType',
      systemConfig: 'system/systemConf/configData'
    }),
    isAdminAudit() {
      return AuditRole[this.userData.audit] == AuditStatus['admin'];
    },
    getAuditRole() {
      return this.userData.audit;
    },
    getStatus() {
      return type => {
        return this.planInfo && this.planInfo.auditStatus == AuditRole[type];
      };
    },
    getRole() {
      return val => {
        return this.userData && AuditRole[this.userData.audit] == AuditRole[val];
      };
    },
    showGuideBorrowAuditCol() {
      return this.userData && this.userData.audit > 1;
    },
    planStatus() {
      if (!this.planInfo) return false;
      const {
        planStatus
      } = this.planInfo;
      return planStatus;
    },
    // 企业码付不可修改
    payTypeDisable() {
      return row => {
        const val = row.payType || row.cashType;
        const isAlipay = val && val.toUpperCase() == PAY_TYPE.ALIPAY_E_CODE;
        // 计划不可改，审核可以改，内容是一起的，只能通过路径判断了
        if (this.$route.path.indexOf('/plan') > -1 && isAlipay) {
          return true;
        }
        return false;
      };
    },
    isRowDisable() {
      return row => {
        // 1. 权限 2. 角色权限 2. 对账状态 4. 现收和挂账不允许修改 5. 企业码付不可修改
        return this.hasOperation || row.isRead || row.checkAccountStatus || this.payTypeDisable(row) || row.resCenterOrder && row.resCenterOrder.resCollectMoneyType && row.resCenterOrder.resCollectMoneyType.toUpperCase() != COLLECT_TYPE.NONE;
      };
    },
    isHotelRowDisable() {
      return row => {
        return this.isRowDisable(row);
      };
    },
    // 不校验整团状态
    isOnlyRowDisable() {
      return row => {
        return this.hasOperation || row.checkAccountStatus;
      };
    },
    isOnlyRowBtnDisable() {
      // 禁止操作权限
      if (this.hasOperation) return true;
      // 获取数据loading状态
      if (this.fetchStatus) return true;
    },
    // 添加按钮
    isBtnDisable() {
      // 禁止操作权限

      if (this.hasOperation) return true;
      // 获取数据loading状态

      if (this.fetchStatus) return true;
      if (this.getRole('op')) {
        return !this.getStatus('wait');
      }
      if (this.getRole('finance')) {
        return this.getStatus('finance');
      }
      if (this.getRole('admin')) {
        return this.getStatus('finance');
      }
    },
    hasOperation() {
      const {
        path
      } = this.$route;
      const {
        toPath
      } = this.$route.query;
      if (path.indexOf('/audit') > -1) {
        return this.hasPermission('forbidOperationAudit');
      } else {
        if (toPath == '/plan/team-arrange') {
          return this.hasPermission('forbidOperationPlan');
        }
        if (toPath == '/plan/team-role-arrange') {
          return this.hasPermission('forbidOperationPlan2');
        }
      }
    }
  },
  methods: {
    // 审核勾选状态
    hasAudit(status) {
      if (this.getRole('op')) {
        return status == AuditRole['op'];
      }
      if (this.getRole('finance')) {
        return status == AuditRole['finance'];
      }
      if (this.getRole('admin')) {
        return status != AuditRole['wait'];
      }
    },
    // 删除/审核禁用状态
    isAuditDisable(status) {
      // 单团审核-是否需要计调审核
      const singleAuditStatus = pickSystemConfig(systemStateMap.needOPAudit, this.systemConfig);
      const isSettlement = this.planStatus == AuditRole['settlement'];

      // 禁止操作权限
      if (this.hasOperation) return true;
      if (this.getRole('op')) {
        return !this.getStatus('wait') || status == AuditRole['finance'] || isSettlement;
      }
      if (this.getRole('finance')) {
        return status == AuditRole['wait'] && singleAuditStatus || isSettlement && singleAuditStatus;
      }
      if (this.getRole('admin')) {
        return isSettlement;
      }
    },
    // 输入框、下拉框禁用状态
    isReadOnly(status) {
      // status 是单条
      // 如果是计调 & 当前子项是待审核 & 当前团不是计调已审核
      // 如果是财务 & 当前子项不是待审核 || 如果整团计调已审核当前子项是待审核
      if (this.getRole('op')) {
        return !this.getStatus('wait') || status == AuditRole['op'] || status == AuditRole['finance'];
      }
      if (this.getRole('finance')) {
        return this.getStatus('finance') || status == AuditRole['finance'];
      }
      if (this.getRole('admin')) {
        return this.getStatus('finance');
      }
    },
    // 管理员提交的审核状态
    formatStatus(it) {
      const {
        ap,
        status
      } = it;
      if (this.getRole('op') || this.getRole('finance')) {
        return status;
      }
      if (this.getRole('admin')) {
        return ap != AuditRole['wait'];
      }
    },
    // 单条日志
    handleRowLog(id) {
      const findComp = findComponentsUpward(this, 'AuditEdit')[0];
      findComp.showRowLog(id);
    },
    // 单条审核
    handleAuditOne(row) {
      const opts = {
        auditTypeEnum: this.currentTabType,
        planId: row.planId || this.currentPlanId,
        resourceId: row.id,
        status: !row.status
      };
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/index/update', [opts]).then(() => {
        this.fetchData();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 审核所有选中
    updateAudit() {
      if (!this.validateForm()) return;
      const loading = this.$bus.loading();
      // 组件内定义的方法
      this.handleUpdate().then(dataList => {
        dataList = this.processData(dataList);
        this.$store.dispatch('audit/index/update', dataList).then(() => {
          this.fetchData();
          this.$bus.tip();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 设置计调审核
    updateAuditOrder() {
      return this.$store.dispatch('audit/index/updateAuditOrder', {
        planId: this.$route.query.id
      });
    },
    // 单个选中
    handleCheckAudit(row, val) {
      row.status = val;
      this.checkAuditSelectAll();
    },
    // 全部选中
    checkAuditAll(val) {
      this.auditAll = val;
      this.tableData.forEach(it => {
        const {
          auditProgress
        } = it;
        if (!this.isAuditDisable(auditProgress)) {
          it.status = val;
        }
      });
    },
    // 检查全选框的状态
    checkAuditSelectAll() {
      this.auditAll = this.tableData.every(it => {
        return it.status === true;
      });
    },
    processData(dataList) {
      const result = [];
      const {
        id
      } = this.$route.query;
      dataList.forEach((it, idx) => {
        const item = {
          auditTypeEnum: this.currentTabType,
          status: it.status,
          // 审核状态
          planId: it.planId || id,
          resourceId: it.rowId // 子项没有rowId 取 id
        };
        if (idx == 0 || dataList[idx - 1].rowId != it.rowId) {
          result.push(item);
        }
      });
      return result;
    },
    // 选择选择全部审核的下拉框
    handleAuditAll(val) {
      const {
        value,
        label
      } = val;
      this.tableData.forEach(it => {
        it.ap = value || 'WAITAUDIT';
        it.apName = label;
        return it;
      });
      this.isAuditAq = true;
    }
  }
};