import "core-js/modules/es.array.push.js";
import { hotelsStatist } from '@/config/report';
import { BtnExport, SelectDictionary, SelectHotel } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
import { parseMonth } from '@/utils';
const dateMonth = val => parseMonth(val);
const NewMonth = dateMonth(new Date());
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
export default {
  components: {
    BtnExport,
    SelectDictionary,
    SelectHotel
  },
  mixins: [searchMixin],
  data: () => ({
    searchParams: {
      startTripDate: NewMonth + '-01',
      pageSize: 20,
      pageNum: 1
    },
    tableData: [],
    columnData: [],
    tableDayThead: [],
    tableLoading: false,
    isVisible: false,
    total: 0
  }),
  computed: {
    ...mapGetters({
      hotelPriceData: 'report/index/hotelPriceData'
    }),
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          this.searchParams = data;
        }
      },
      immediate: true
    },
    hotelPriceData: {
      handler: 'initData',
      immediate: true
    }
  },
  mounted() {
    this.tableDayThead = [];
  },
  methods: {
    initData(data) {
      this.isVisible = false;
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    handleData(data) {
      data = JSON.parse(data);

      // 数据页码分割
      this.tableData = this.pageFormat(data, this.searchParams.pageNum, this.searchParams.pageSize);
      this.total = data.length;
      this.isVisible = true;
    },
    pageFormat(list, pageNum, pageSize) {
      const num = (pageNum - 1) * pageSize;
      const size = pageSize * pageNum;
      return list.slice(num, size);
    },
    selectPlanType(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.planType = value;
      this.searchParams.planTypeLavel = label;
    },
    selectHotel(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.hotelId = value ? value : 0;
      this.searchParams.hotelLabel = label;
    },
    // 导出
    exportExcel() {
      const opts = this.searchParams;
      if (!opts.startTripDate) {
        this.$bus.tip({
          type: 'warning',
          message: '发团年月不能为空!'
        });
        return false;
      }
      const loading = this.$bus.loading();
      delete opts.pageSize;
      delete opts.pageNum;
      this.$store.dispatch('report/index/fetchHotelHousePriceExcel', opts).then(res => {
        const fileName = dateMonth(opts.startTripDate) + '房价统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    // 选择年月
    changeMonth(date) {
      this.searchParams.startTripDate = dateMonth(date) + '-01';
    },
    handleChange(type, data) {
      this.searchParams[type] = data ? data.label : '';
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      const list = JSON.parse(this.hotelPriceData);
      this.searchParams.pageSize = pageSize;
      this.searchParams.pageNum = pageNum;
      this.tableData = [];
      this.tableData = this.pageFormat(list, pageNum, pageSize);
    },
    // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    //   this.searchParams.pageNum = 1
    // },

    fetchData(opts) {
      if (!opts.startTripDate) {
        this.$bus.tip({
          type: 'warning',
          message: '发团年月不能为空!'
        });
        return false;
      }
      // 表格头部拼接
      const {
        startTripDate
      } = opts;
      const [year, month] = startTripDate.split('-');
      const days = new Date(year, month, 0).getDate();
      this.tableDayThead = [];
      for (let i = 1; i <= days; i++) {
        const item = {
          attrs: {
            prop: 'day' + i,
            label: i + '日'
          }
        };
        this.tableDayThead.push(item);
      }
      this.columnData = [...hotelsStatist, ...this.tableDayThead];
      this.$store.dispatch('report/index/fetchHotelHousePrice', opts);
    }
  }
};