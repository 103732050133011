var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "区域经理"
    },
    model: {
      value: _vm.searchParams.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaManagerId", $$v);
      },
      expression: "searchParams.customerAreaManagerId"
    }
  }), _c("InputMonthRange", {
    attrs: {
      "default-date": _vm.sendMonth,
      label: "查询时间"
    },
    on: {
      "on-change": _vm.changeMonth
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1)]), _c("div", {
    attrs: {
      id: "export-receivable-detail-table"
    }
  }, [_vm.isTableShow ? _c("TableReceivable", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      "statistics-key": _vm.statisticsKeys
    }
  }) : _vm._e(), _vm.statisticData.length > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    }
  })] : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };