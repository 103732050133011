import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { TopBar, DelCheck, DelCheckAll, Audit, AuditCheck, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectShop, SelectGuide } from '@/components/common';
import { mapGetters } from 'vuex';
import { selectorMixin, auditMixin, auditPropMixin, rowspanMixin, planMixin, reloadMixin, systemMixin, themeMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import { decimalSplit } from '@/utils/index';
import { defaultState, itemListKeys, RateKeyMap, RabeteKeyMap, statisticsKeys, shopUrl } from './config';
export default {
  components: {
    TopBar,
    DelCheck,
    DelCheckAll,
    Audit,
    AuditCheck,
    AuditAdmin,
    AuditRoleAll,
    StatusAudit,
    SelectShop,
    SelectGuide,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, auditMixin, auditPropMixin, rowspanMixin, planMixin, reloadMixin, systemMixin, themeMixin],
  data() {
    return {
      statisticsKeys,
      shopUrl,
      tableData: [],
      hasDataStatusKey: 'shopStatus',
      deletePath: 'plan/shopping/delete',
      updatePath: 'plan/shopping/update',
      fetchPath: 'plan/shopping/fetch',
      headMergeNum: 5,
      hasMergeTail: true,
      rowId: 1000000000000,
      totalMoney: 0,
      // 总金额
      customerTripNumberList: [],
      auditAll: false,
      isDecimal: false,
      // 是否保留两位小数
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      planGuide: 'plan/index/planGuide',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    isTotal() {
      return item => {
        return item.isTotal;
      };
    },
    getPlanInfo() {
      return this.planInfo[this.currentPlanId];
    },
    getPlanNumber() {
      return this.getPlanInfo ? this.getPlanInfo.planNumber : '';
    },
    getData() {
      return this.tableData;
    }
  },
  watch: {
    // 版本切换
    versionSwitch: {
      handler: function (state) {
        const isOrderInfo = this.hasOrderInfo;
        const {
          path
        } = this.$route;
        // const SubsidyTwo = this.hasSubsidyTwoPrice() // 二返权限
        if (!state) {
          this.headMergeNum = 6;
          // 如果是计划安排的购物
          if (path == this.shopUrl.planUrl) {
            this.tailMergeNum = 21;
            if (!isOrderInfo) {
              this.tailMergeNum = 20;
            }
          }
          // 如果是单团审核的购物
          if (path == this.shopUrl.auditUrl) {
            this.tailMergeNum = 23;
            if (!isOrderInfo) {
              this.tailMergeNum = 23;
            }
          }
        } else {
          this.headMergeNum = 5;
          // 如果是计划安排的购物
          if (path == this.shopUrl.planUrl) {
            this.tailMergeNum = 19;
            if (!isOrderInfo) {
              this.tailMergeNum = 18;
            }
          }
          // 如果是单团审核的购物
          if (path == this.shopUrl.auditUrl) {
            this.tailMergeNum = 23;
            if (!isOrderInfo) {
              this.tailMergeNum = 22;
            }
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchCustomerList();
    this.fetchData();
  },
  methods: {
    onkeyupRule(row, value) {
      if (!value) return;
      row.subsidy = this.keepTwoDecimal ? decimalSplit(value) : Math.round(value);
    },
    initData(data) {
      if (!data) return;
      this.visible = true;
      const list = this.processList(data);
      const newList = this.initialDecimalPermission(this.formatData(list));
      this.tableData = newList;

      // 导游小程序修改打单金额，后端未计算，这里临时处理
      this.tableData.forEach(row => {
        this.needCalcGuideDriverAccompanyMoney(row);
      });
      this.$nextTick(() => {
        this.resetData();
      });
    },
    processList(data) {
      const list = this.formatTabelData(data);
      // this.calcMoreGoodsAndMergeRow(list)
      // plan mixin方法
      this.calcMergeRow(list, ['travelGrossProfit'], 'shopGoods');
      this.moreGoodsAndMergeRowEditValue(list);
      return list;
    },
    // 抽取计算多个商品小计
    formatTabelData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        let totalChildren = {};
        it.isTotal = false;
        if (it.shopGoods.length > 1) {
          const money = this.getSum(it.shopGoods, 'money');
          const shopRebate = this.getSum(it.shopGoods, 'shopRebate');
          const redEnvelope = this.getSum(it.shopGoods, 'redEnvelope');
          const guideRebate = this.getSum(it.shopGoods, 'guideRebate');
          const driverRebate = this.getSum(it.shopGoods, 'driverRebate');
          const accompanyRebate = this.getSum(it.shopGoods, 'accompanyRebate');
          const guideManagerRebate = this.getSum(it.shopGoods, 'guideManagerRebate');
          const guideCollect = this.getSum(it.shopGoods, 'guideCollect');
          const travelGrossProfit = this.getSum(it.shopGoods, 'travelGrossProfit');
          let total = {
            totalPid: it.id,
            isTotal: true,
            totalText: '小计',
            moneyTotal: money,
            shopRebateTotal: shopRebate,
            redEnvelopeTotal: redEnvelope,
            guideRebateTotal: guideRebate,
            driverRebateTotal: driverRebate,
            accompanyRebateTotal: accompanyRebate,
            guideManagerRebateTotal: guideManagerRebate,
            guideCollectTotal: guideCollect,
            travelGrossProfitTotal: travelGrossProfit,
            shopRebate: 0,
            guideRebate: 0,
            driverRebate: 0,
            accompanyRebate: 0,
            guideCollect: 0,
            travelGrossProfit: 0,
            contractPrices: [],
            shopGoods: []
          };
          totalChildren = total;
          result.push(it, totalChildren);
        } else {
          result.push(it);
        }
      });
      return result;
    },
    // 计算合计
    getSum(list, key) {
      var s = 0;
      list.forEach(it => {
        s += Number(it[key]);
      }, 0);
      return s;
    },
    // 多个商品并且合并行，不需要合计，但需要修改输入框的内容
    moreGoodsAndMergeRowEditValue(list) {
      const calcKeys = ['guideCollect'];
      calcKeys.forEach(key => {
        list.forEach(it => {
          if (it.shopGoods.length) {
            const sum = it.shopGoods.reduce((acc, cur) => (acc, acc += +cur[key]), 0);
            it.shopGoods[0][key] = sum;
          }
        });
      });
      return list;
    },
    fetchCustomerList() {
      const planId = this.currentPlanId;
      this.$store.dispatch('plan/index/findCustomerList', planId).then(list => {
        this.customerTripNumberList = this.formatTripNumber(list);
      });
    },
    handleAvg(scope) {
      let {
        $index: index,
        row
      } = scope;
      const orderList = this.customerTripNumberList;
      if (orderList.length < 2) {
        this.$bus.tip({
          type: 'warning',
          message: '只有一个订单无需分摊'
        });
        return;
      }
      const len = orderList.length;
      const money = row.money / len;
      const cashFlow = row.cashFlow / len;
      row.money = money;
      row.cashFlow = cashFlow;
      for (let i = 1; i < orderList.length; i++) {
        const item = {
          ...itemListKeys(),
          ...row,
          itemIndex: i,
          name: row.name,
          customerOrderId: orderList[i].id + '',
          customerOrderLabel: orderList[i].label,
          id: row.planShopId
        };
        this.tableData.splice(index, 0, item);
      }
      row.customerOrderId = orderList[0].id;
      row.customerOrderLabel = orderList[0].label;

      // 重新计算金额
      this.tableData = this.tableData.map(row => {
        const {
          money,
          shopRate
        } = row;
        row.guideRebate = this.calcGuideMoney(row);
        row.driverRebate = this.calcDriverMoney(row);
        row.accompanyRebate = this.calcAccompanyMoney(row);
        row.shopRebate = this.isDecimalMoneyFormat(money * shopRate) / 100;
        row.guideManagerRebate = this.calcGuideManagerMoney(row);
        return row;
      });
      this.resetData();
    },
    // 当前添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/shopping/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...itemListKeys(),
          ...row,
          guideCollect: 0,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false,
          name: row.name,
          enterStoreMemberCount: this.planInfo.adultCount || 0
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.resetData();
    },
    // 新增一条
    handlePlus() {
      const item = {
        ...defaultState(),
        guideName: this.planGuide ? this.planGuide.name : '',
        guideId: this.planGuide ? this.planGuide.id : '',
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        orderDate: this.planInfo.startPlanDate,
        enterStoreMemberCount: this.planInfo ? this.planInfo.adultCount : 0
      };
      this.tableData.push(item);
      this.visible = true;
      this.resetData();
    },
    // 选择购物店
    selectShop(row, val) {
      const {
        shopName: shopNameOrigin,
        shopId: shopIdOrigin,
        planId
      } = row;
      if (!val.label || !val.value) {
        row.shopId = '';
        row.shopPrice = 0;
        row.headPrice = 0;
        this.$set(row, 'goodsList', []);
        return;
      }
      const {
        id: shopId,
        label,
        subsidyRate
      } = val;
      row.subsidyRate = subsidyRate;
      if (subsidyRate) {
        this.calcSubsidy(row); //计算后返金额
      }
      this.$store.dispatch('plan/shopping/fetchGoods', {
        shopId,
        planId
      }).then(data => {
        if (!data) {
          this.$bus.tip({
            type: 'warning',
            message: '查询不到购物店协议'
          });
          row.shopId = shopIdOrigin || shopId;
          row.shopName = shopNameOrigin || label;
          this.$set(row, 'goodsList', []);
          return;
        }
        const {
          contractPrices,
          shopPrice
        } = data;
        row.shopId = shopId;
        row.shopName = label;
        row.shopPrice = shopPrice;
        row.headPrice = shopPrice;
        this.$set(row, 'goodsList', this.formatOptions(contractPrices));
        // 选择后直接计算人头返佣
        this.changeEnterCount(row, row.enterStoreMemberCount);
        // this.calcRowTotalMoney(row)
      });
    },
    // 小团号
    handleSelectTripNumber(row, val) {
      row.customerTripNumber = val.label;
      row.customerOrderId = val.value;
    },
    // 选择导游
    selectGuide(row, val) {
      const {
        label,
        value
      } = val;
      row.guideName = label;
      row.guideId = value;
    },
    // 选择商品
    handleSelect(row, val) {
      RateKeyMap.forEach(key => {
        row[key] = this.isDecimalFormat(val[key]);
      });
      const {
        subsidy
      } = val;
      row.name = val.label;
      row.shopAgencyProfit = subsidy;
      this.calcRowTotalMoney(row);
    },
    findSameRowIdListAndCalcProfit(row, enterCount) {
      const {
        rowId
      } = row;
      // 当有多个商品时，社利只更新了第一条，需要根据id查找另外一条
      const findList = this.tableData.filter(it => it.rowId == rowId);
      findList.forEach(it => {
        it.enterStoreMemberCount = enterCount;
        this.calcTravelProfit(it);
      });
    },
    // 进店人数, 需要计算导/司/陪佣及社佣、社利
    changeEnterCount(row, val) {
      // 有多个商品的时候，修改进店人数需要查找出相同的数据一起更新
      this.findSameRowIdListAndCalcProfit(row, val);
      this.calcPerCapita(row); //计算人均

      // 导游、司机、全陪金额
      this.calcGuideDriverAccompanyMoney(row);
    },
    // 打单金额， 需要计算导/司/陪佣及社佣、社利
    changePrintMoney(row) {
      this.calcSubsidy(row); //计算后返金额
      this.calcRowTotalMoney(row);
      this.calcPerCapita(row); //计算人均

      // 导游、司机、全陪金额
      this.calcGuideDriverAccompanyMoney(row);
    },
    // 计算人均
    calcPerCapita(row) {
      if (row.enterStoreMemberCount == 0) {
        row.perCapita = 0;
        return;
      }
      row.perCapita = this.isDecimalMoneyFormat(row.money / row.enterStoreMemberCount);
    },
    // 流水修改
    changeCashFlow(row) {
      this.calcGuideManagerMoney(row);
    },
    // 社佣
    changeShopRate(row) {
      this.calcShopMoney(row);
      this.calcTravelProfit(row);
    },
    // 导游比例和扣除修改
    changeGuideRate(row) {
      this.calcGuideMoney(row);
      this.calcTravelProfit(row);
    },
    // 司机金额修改
    changeDriverRate(row) {
      this.calcDriverMoney(row);
      this.calcTravelProfit(row);
    },
    // 全陪金额修改
    changeAccompanyRate(row) {
      this.calcAccompanyMoney(row);
      this.calcTravelProfit(row);
    },
    // 导管金额修改
    changeGuideManagerRate(row) {
      this.calcGuideManagerMoney(row);
      this.calcTravelProfit(row);
    },
    // 社佣金总额
    calcShopMoney(row) {
      const {
        money,
        shopRate
      } = row;
      const sum = this.isDecimalMoneyFormat(money * shopRate / 100);
      row.shopRebate = sum;
      return sum;
    },
    isNeedCalc(money, rate, rebate) {
      // 比例为0不重新计算
      if (!rate) return false;

      // 如果计算比例与金额不一致也不修改
      if (rebate && rebate != money * rate) return false;
      return true;
    },
    calcGuideDriverAccompanyMoney(row) {
      this.calcGuideMoney(row);
      this.calcDriverMoney(row);
      this.calcAccompanyMoney(row);
    },
    needCalcGuideDriverAccompanyMoney(row) {
      const {
        money
      } = row;
      const {
        guideRebate,
        guideRate
      } = row;
      const {
        driverRebate,
        driverRate
      } = row;
      const {
        accompanyRebate,
        accompanyRate
      } = row;
      const needUpdateGuide = this.isNeedCalc(money, guideRate, guideRebate);
      const needUpdateDriver = this.isNeedCalc(money, driverRate, driverRebate);
      const needUpdateAccompany = this.isNeedCalc(money, accompanyRate, accompanyRebate);
      needUpdateGuide && this.calcGuideMoney(row);
      needUpdateDriver && this.calcDriverMoney(row);
      needUpdateAccompany && this.calcAccompanyMoney(row);
    },
    // 导游佣金总额
    calcGuideMoney(row) {
      const {
        money,
        guideRate
      } = row;
      const sum = this.isDecimalMoneyFormat(money * guideRate / 100 - row.guideRebateDeduction);
      row.guideRebate = sum;
      return sum;
    },
    // 司机佣金总额
    calcDriverMoney(row) {
      const {
        money,
        driverRate
      } = row;
      const sum = this.isDecimalMoneyFormat(money * driverRate / 100 - row.driverRebateDeduction);
      row.driverRebate = sum;
      return sum;
    },
    // 全陪佣金总额
    calcAccompanyMoney(row) {
      const {
        money,
        accompanyRate
      } = row;

      // 取整数 四舍五入
      const sum = this.isDecimalMoneyFormat(money * accompanyRate / 100);
      row.accompanyRebate = sum;
      return sum;
    },
    // 导管佣金总额
    calcGuideManagerMoney(row) {
      const {
        money,
        guideManagerRate,
        name,
        cashFlow
      } = row;
      let sum = 0;
      if (['土产', '车购'].includes(name)) {
        // 取整数 四舍五入
        sum = this.isDecimalMoneyFormat(guideManagerRate * cashFlow / 100);
      } else {
        // 取整数 四舍五入
        sum = this.isDecimalMoneyFormat(money * guideManagerRate / 100);
      }
      row.guideManagerRebate = sum;
      return sum;
    },
    // 社利总额
    calcTravelProfit(row) {
      // 社利总额 = 社佣金额（打单金额 * 社佣比例）- 导佣总额（打单金额 * 导佣比例）- 司佣总额（打单金额 * 司佣比例） - 陪佣总额（打单金额 * 陪佣比例） + 人头佣金(单价 * 人数）
      const {
        headPrice,
        enterStoreMemberCount
      } = row;

      // 人头返佣金额
      const totalHeadRebate = headPrice * enterStoreMemberCount;
      const {
        shopRebate,
        redEnvelope,
        accompanyRebate,
        driverRebate,
        guideRebate,
        guideManagerRebate
      } = row;
      const unit = 100;
      const res = shopRebate * unit + redEnvelope * unit + totalHeadRebate * unit - accompanyRebate * unit - guideRebate * unit - guideManagerRebate * unit - driverRebate * unit;
      row.travelGrossProfit = this.isDecimalMoneyFormat(res / unit);
      // 计算小计行金额总计
      this.calcSubtotal(row);
      return row.travelGrossProfit;
    },
    // 计算小计行金额总计
    calcSubtotal(row) {
      const {
        rowId
      } = row;
      const list = this.tableData.filter(it => it.totalPid == rowId);
      if (!list.length) return false;
      const calclist = this.tableData.filter(it => it.rowId == rowId);
      list[0].moneyTotal = this.getSum(calclist, 'money');
      list[0].shopRebateTotal = this.getSum(calclist, 'shopRebate');
      list[0].guideRebateTotal = this.getSum(calclist, 'guideRebate');
      list[0].driverRebateTotal = this.getSum(calclist, 'driverRebate');
      list[0].accompanyRebateTotal = this.getSum(calclist, 'accompanyRebate');
      list[0].guideManagerRebateTotal = this.getSum(calclist, 'guideManagerRate');
      list[0].guideCollectTotal = this.getSum(calclist, 'guideCollect');
      list[0].travelGrossProfitTotal = this.getSum(calclist, 'travelGrossProfit');
    },
    // 计算返款金额
    calcSubsidy(row) {
      const {
        subsidyRate
      } = row;
      // row.subsidy = this.isDecimalFormat((money * subsidyRate || 0) / 100)
      // 累计行合并金额
      const subsidyTatol = this.calcMergeRowMoney({
        row,
        moneyKey: 'money',
        rate: subsidyRate
      });
      row.subsidy = this.keepTwoDecimal ? decimalSplit(subsidyTatol) : Math.round(subsidyTatol);
    },
    // 计算单行总额
    calcRowTotalMoney(row) {
      this.calcShopMoney(row);
      this.calcGuideMoney(row);
      this.calcDriverMoney(row);
      this.calcAccompanyMoney(row);
      this.calcTravelProfit(row);
      this.calcGuideManagerMoney(row);
    },
    resetData() {
      this.rowspan();
      this.mergeRowMoney(true); // 合并单条数据总价
    },
    // 格式化小团号
    formatTripNumber(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const item = {
          value: it.id,
          label: it.customerTripNumber || it.orderNumber,
          name: it.customerTripNumber || it.orderNumber,
          ...it
        };
        result.push(item);
      });
      return result;
    },
    // 处理系统配置是否保留二位小数
    initialDecimalPermission(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        RabeteKeyMap.forEach(key => {
          it[key] = this.isDecimalFormat(it[key]);
        });
        return it;
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.shopGoods;
        const {
          id,
          auditProgress
        } = it;
        let goodsList = it.contractPrices || [];
        goodsList = this.formatOptions(goodsList);
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          shoppingPlanId: id,
          isChecked: false,
          isCustom: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          goodsList,
          hasGoods: !!itemList.length,
          customerOrderId: ''
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const shopRemark = v.remark;
            delete v.remark;
            if (it.enterStoreMemberCount == 0) {
              v.perCapita = 0;
            } else {
              v.perCapita = this.isDecimalMoneyFormat(v.money / it.enterStoreMemberCount);
            }
            result.push({
              ...item,
              itemIndex: i,
              shopRemark,
              customerOrderLabel: v.customerTripNumber,
              ...v
            });
          });
        } else {
          item.itemIndex = 0;
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      // 去除总计行
      list = list.filter(it => !it.isTotal);
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'goodsList'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        if (!it.isCustom) {
          item.id = it.id;
          item.planShopId = it.shoppingPlanId;
        }

        // 定义子项目的列表
        it.shopGoods = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          if (key == 'shopRemark') {
            item.remark = it.shopRemark;
          }
          delete it[key];
        });
        if (!it.hasGoods) {
          delete item.id;
        }
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.shoppingPlanId;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.shoppingPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        it.totalGuideCollect = Number(item.guideCollect || 0);
        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.totalGuideCollect += Number(item.guideCollect || 0);
          last.shopGoods.push(item);
        } else {
          it.shopGoods.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      // 去除总计行
      const tableData = this.tableData.filter(it => !it.isTotal);
      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        const {
          shopId,
          orderDate,
          goodsList
        } = row;
        if (!shopId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择购物店!'
          });
          return false;
        }
        if (!goodsList || !goodsList.length) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择商品，无商品选择请到购物店管理增加商品协议!'
          });
          return false;
        }
        if (!orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择进店日期！'
          });
          return false;
        }
      }
      return true;
    },
    formatOptions(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.label = it.contentType;
        it.value = it.id;
        return it;
      });
    },
    tableRowClassName({
      row
    }) {
      if (row.isTotal) {
        return 'shop-row-bg';
      }
      return '';
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.shop,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};