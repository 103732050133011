// 发团预定安排 车队
import { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 查询计划游客列表（按团分组） 导出
export function exportPlanMeberListRequest(params) {
  const url = APP.baseUrl + '/order/report/exportPlanMembersList';
  return requestBlob({
    url,
    params
  });
}