import "core-js/modules/es.array.push.js";
import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  data() {
    return {
      size: 'medium',
      link: {
        driver: '/resource/driver',
        vehicle: '/resource/vehicle'
      }
    };
  },
  methods: {
    handleLink(type, id) {
      this.$router.push({
        path: this.link[type],
        query: {
          id
        }
      });
    }
  }
};