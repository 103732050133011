// 回收站
import { fetchRecycle, recoveryOrder } from '@/api/order/recycle';
import { DEFAULT_PAGE } from '@/config';
import { formatTableData } from '../../../utils/data';
const dfaultState = () => ({
  recycleData: null
});
const state = dfaultState();
const getters = {
  recycleData: state => state.recycleData
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  recovery({
    commit
  }, opts) {
    return recoveryOrder(opts);
  },
  // 回收站
  fetch({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchRecycle(opts).then(res => {
      const {
        data
      } = res;
      data.list = formatTableData(data.list);
      commit('FETCH_RECYCLE', data);
      return data;
    });
  }
};
const mutations = {
  FETCH_RECYCLE: (state, data) => {
    state.recycleData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};