var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt20 area-index"
  }, [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "60px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputMonthRange", {
    attrs: {
      "is-require": "",
      "default-date": _vm.sendDate,
      label: "查询时间"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    on: {
      "on-select": _vm.onSelectCustom
    },
    model: {
      value: _vm.searchParams.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerName", $$v);
      },
      expression: "searchParams.customerName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.changeArea
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "区域经理"
    },
    on: {
      "on-select": _vm.selectManager
    },
    model: {
      value: _vm.searchParams.customerAreaManager,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaManager", $$v);
      },
      expression: "searchParams.customerAreaManager"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    on: {
      "on-select": _vm.changeUserName
    },
    model: {
      value: _vm.searchParams.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserId", $$v);
      },
      expression: "searchParams.outOPUserId"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": function ($event) {
        return _vm.exportExcel("应收款明细分析表", "export-income-det-table");
      }
    }
  })], 1)]), _c("TableDetailList", {
    staticClass: "mt20",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };