var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };