var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "分享报价",
      width: "900px",
      height: "auto",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px"
    },
    attrs: {
      id: "share-element"
    }
  }, [_c("div", {}, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 人数：" + _vm._s(_vm.form.adultCount) + " ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" 行程日期：" + _vm._s(_vm.form.startTripDate) + " / " + _vm._s(_vm.form.endTripDate) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 景 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.scenicMoney))])])]), _c("TableBase", {
    attrs: {
      data: _vm.form.scenicDetails,
      dynamic: false,
      "column-data": _vm.offerScenicDetail,
      border: ""
    }
  }), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.scenicRemarks) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 房 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.hotelMoney))])])]), _c("TableBase", {
    attrs: {
      data: _vm.form.hotelDetails,
      dynamic: false,
      "column-data": _vm.offerHotelDetail,
      border: ""
    }
  }), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.hotelRemarks) + " ")])], 1), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 参考：" + _vm._s(_vm.form.hotelRefer) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 餐 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.restaurantMoney))])])]), _c("TableBase", {
    attrs: {
      data: _vm.form.restaurantDetails,
      dynamic: false,
      "column-data": _vm.offerRestaurantDetail,
      border: ""
    }
  }), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.restaurantRemarks) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 车 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.busMoney))])])]), _c("TableBase", {
    attrs: {
      data: _vm.form.busDetails,
      dynamic: false,
      "column-data": _vm.offerRestaurantDetail,
      border: ""
    }
  }), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.busRemarks) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 导 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.guideMoney))])])]), _c("TableBase", {
    attrs: {
      data: _vm.form.guideDetails,
      dynamic: false,
      "column-data": _vm.offerRestaurantDetail,
      border: ""
    }
  }), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 备注：" + _vm._s(_vm.form.guideRemarks) + " ")])], 1)], 1), _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 操作费 ")]), _c("div", {
    staticClass: "ml20"
  }, [_vm._v(" 单人费用合计： "), _c("span", {
    staticClass: "red"
  }, [_vm._v(" ¥" + _vm._s(_vm.form.opPrice))])])]), _c("el-row", {
    staticClass: "mt10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v(" 购物备注：" + _vm._s(_vm.form.shopRemarks) + " ")])], 1), _c("div", {
    staticClass: "mt10",
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold"
    }
  }, [_vm._v(" 总价格："), _c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.form.totalMoney))])])], 1)]), _c("div", {
    staticClass: "offer-footer"
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function ($event) {
        return _vm.onChecksLink("share");
      }
    }
  }, [_vm._v(" 分享报价 ")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onChecksLink
    }
  }, [_vm._v(" 下载报价 ")]), _c("el-button", {
    attrs: {
      type: "info",
      plain: ""
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 取消 ")])], 1), _c("div", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "canvas"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };