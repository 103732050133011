import { orderInfo, tripDate, orderArrangeRemark, orderOpOperation, orderOther, orderTotalPay, auditStatus, guideBill, payed, unPay, createTime, actionItem } from './index';
const busInfo = {
  attrs: {
    prop: 'busInfo',
    label: '车辆信息',
    'min-width': 100
  },
  slot: 'busInfo'
};
const lastItem = [orderArrangeRemark, orderOpOperation, orderOther, orderTotalPay, payed, unPay, createTime, auditStatus, actionItem];

// 按团号去付款 接送
export const PickupColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'busCompanyName',
    label: '接送车队'
  }
}, busInfo, guideBill, {
  attrs: {
    prop: '',
    label: '用车时间'
  },
  slot: 'tripDate'
},
// 开始时间 - 结束时间
...lastItem];