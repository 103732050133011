var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page"
  }, [_c("TableNative", {
    attrs: {
      title: "团队客人名单表",
      data: _vm.data,
      columns: _vm.columnInfo.slice(0, 2),
      "col-num": 2
    }
  }), _c("TableNative", {
    attrs: {
      data: _vm.data,
      columns: _vm.columnInfo.slice(2),
      "col-num": 4,
      "label-width": "30"
    },
    scopedSlots: _vm._u([{
      key: "guides",
      fn: function ({
        row
      }) {
        return [_c("Guides", {
          attrs: {
            row: row,
            "is-break": false
          }
        })];
      }
    }, {
      key: "number",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "driver",
      fn: function ({
        row
      }) {
        return _vm._l(row.buses, function (d) {
          return _c("span", {
            key: d.id
          }, [_vm._v(" 车队：" + _vm._s(d.busCompanyName) + " 司机： " + _vm._s(d.driverName) + " 车费： " + _vm._s(d.fee) + " ")]);
        });
      }
    }], null, false, 2507730504)
  }), _c("TouristAge", {
    attrs: {
      data: _vm.getMembers
    }
  }), _c("TableNative", {
    attrs: {
      mode: "horization",
      data: _vm.data.members,
      columns: _vm.columnNames,
      "col-num": _vm.columnNames.length
    },
    scopedSlots: _vm._u([{
      key: "people",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "phone",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "cardType",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.getCardType(row.cardType)) + " ")];
      }
    }, {
      key: "sex",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(_vm.getSex(row.sex)) + " ")];
      }
    }, {
      key: "age",
      fn: function ({
        row
      }) {
        return [_c("div", {
          style: {
            "background-color": row.age >= 65 ? "grey !important" : ""
          }
        }, [_vm._v(" " + _vm._s(row.age) + " ")])];
      }
    }, {
      key: "birth",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.birth) + " "), _vm.isBirthDay(row.birthday) ? _c("img", {
          attrs: {
            src: _vm.cakeIcon,
            width: "16",
            height: "16"
          }
        }) : _vm._e()];
      }
    }, {
      key: "isContact",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.isContact ? "是" : "") + " ")];
      }
    }], null, false, 605467881)
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };