import BaseInfo from './BaseInfo';
import TableList from './TableList';
const defaultState = () => ({
  shopId: '',
  guideId: '',
  orderDate: '',
  headPrice: '',
  enterStoreMemberCount: '',
  shopAgencyProfit: '',
  total: 0
});
export default {
  components: {
    TableList,
    BaseInfo
  },
  data: () => ({
    total: 0,
    form: {}
  }),
  created() {
    const {
      id,
      planShopId
    } = this.$route.query;
    if (!id) return;
    this.fetchData(planShopId);
  },
  methods: {
    fetchData(id) {
      return this.$store.dispatch('shopping/index/detail', id).then(data => {
        this.form = data ? {
          ...data,
          total: 0
        } : defaultState();
        this.calcTotal();
      });
    },
    selectShop(val) {
      const {
        id
      } = val;
      this.fetchData(id).then(data => {
        if (!data) {
          this.form.shopId = id;
        }
      });
      this.$refs.tableRef.fetchGoods(id);
    },
    calcTotal() {
      const {
        enterStoreMemberCount,
        headPrice
      } = this.form;
      this.form.total = enterStoreMemberCount * headPrice;
    },
    handleCreate() {
      this.$refs.tableRef.handleAdd();
    },
    handleSave() {
      this.$refs.tableRef.createOrUpdate();
    },
    handleClose() {}
  }
};