import "core-js/modules/es.array.push.js";
import { tableMixin } from '@/mixin';
import shopMixin from '@/views/shopping/shopMixin';
export default {
  mixins: [tableMixin, shopMixin],
  data() {
    return {
      avgeageMaps: [{
        hasChild: false,
        // 如果是对象下的属性，如： scope.row.shopProfit.totalShopRebate， 则是true; 否则为false
        key: '',
        // 当hasChild为true时，key 为 对象字符串

        divisor: 'adultCount',
        // 人数字段
        dividend: 'totalQuoteGrossProfit',
        // 总数
        value: 'totalQuoteGrossMargin' // 平均值
      } // 计算人均
      ]
    };
  },
  methods: {
    onChecksLink(row) {
      this.$parent.onLink({
        row,
        type: 'checks'
      });
    },
    onLink({
      row
    }) {
      const {
        opUserName,
        opUserId
      } = row;
      let searchParams = this.$parent.searchParams;
      searchParams.outOPUserLabel = opUserName;
      searchParams.outOPUserId = opUserId;
      delete searchParams.pageNum;
      this.$router.push({
        path: '/report/custom/gross',
        query: searchParams
      });
    }
  }
};