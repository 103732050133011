import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'group/fetchAllResource'
  }),
  computed: {
    data() {
      return this.$store.getters['group/resourceAllData'];
    }
  }
};