import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
import { TypeOrder } from '@/components/common';
export default {
  components: {
    TypeOrder
  },
  mixins: [tableMixin, shopMixin],
  data: () => ({
    sumsIndex: 4,
    avgeageKey: [5, 7, 8],
    // 计算人均
    shopStarIndex: 10
  }),
  methods: {}
};