import TableList from './TableList';
import { BtnExport, InputDepartment, SelectDictionary } from '@/components/common';
import { resourceAccountDetail } from '@/config/resource';
import { parseTime } from '@/utils';
export default {
  components: {
    BtnExport,
    TableList,
    InputDepartment,
    SelectDictionary
  },
  data: () => ({
    tableLoading: false,
    columnData: resourceAccountDetail,
    tableData: [],
    total: 0,
    searchParams: {}
  }),
  methods: {
    formatList(list) {
      return list.map(it => {
        it.initDate = parseTime(it.initDate, '{y}-{m}-{d}');
        it.createTime = parseTime(it.createTime, '{y}-{m}-{d}');
        return it;
      });
    },
    handleSearch() {},
    showCreateDialog() {}
  }
};