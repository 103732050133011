import { orderListDetail } from '@/config/plan';
import { mapGetters } from 'vuex';
import { dictionaryMixin } from '@/mixin';
import { CustomerInfo, Members } from '@/components/common';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  components: {
    Members,
    CustomerInfo
  },
  mixins: [dictionaryMixin],
  data() {
    return {
      columnData: [],
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      planOrderInfo: 'plan/index/planOrderInfo'
    })
  },
  watch: {
    planOrderInfo: {
      handler: 'handleInit',
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderListDetail : filterCustomColumn(orderListDetail);
  },
  methods: {
    handleInit(data) {
      this.tableData = data;
    }
  }
};