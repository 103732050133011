var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "padding-bottom": "50px"
    }
  }, [_vm.companyData ? _c("BaseInfo", {
    attrs: {
      info: _vm.companyData
    }
  }) : _vm._e(), _c("h3", [_vm._v("支付宝企业码信息")]), _vm.companyInfo ? _c("el-form", {
    attrs: {
      inline: ""
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "公司全称"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.companyInfo.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.companyInfo, "fullName", $$v);
      },
      expression: "companyInfo.fullName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "支付宝账户"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.companyInfo.alipayAccount,
      callback: function ($$v) {
        _vm.$set(_vm.companyInfo, "alipayAccount", $$v);
      },
      expression: "companyInfo.alipayAccount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "签约状态"
    }
  }, [_c("el-tag", {
    attrs: {
      type: _vm.getTagType
    }
  }, [_vm._v(" " + _vm._s(_vm.getAlipayStatus) + " ")])], 1), _c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleSign
    }
  }, [_vm._v(" 支付宝企业码签约 ")]), _vm.isOpen ? _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleRemoveSign
    }
  }, [_vm._v(" 支付宝企业码解约 ")]) : _vm._e(), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleLogoffSign
    }
  }, [_vm._v(" 支付宝企业码注销 ")])], 1)], 1) : _vm._e(), _c("h3", [_vm._v("Logo/印章上传")]), _c("UploadBox", {
    attrs: {
      data: _vm.getBusinessInfo
    }
  }), _c("Checkbox", {
    attrs: {
      data: _vm.getBusinessInfo,
      type: _vm.SEAL_TYPE.business,
      head: "业务章使用范围",
      title: "业务章模板配置",
      description: "勾选下列复选框配置业务章使用范围"
    }
  }), _c("Checkbox", {
    attrs: {
      data: _vm.getBusinessInfo,
      type: _vm.SEAL_TYPE.account,
      head: "财务章使用范围",
      title: "财务章模板配置",
      description: "勾选下列复选框配置财务章使用范围"
    }
  }), _c("div", {
    staticClass: "h10"
  }), _c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("h3", {
    staticClass: "pr10"
  }, [_vm._v("发票管理")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.showInvoiceDialog
    }
  }, [_vm._v(" 添加发票 ")])], 1), _c("Invoice"), _c("div", {
    staticClass: "h10"
  }), _c("h3", [_vm._v("单据信息配置")]), _c("BillInput", {
    ref: "billRef"
  }), _c("FormSource", {
    ref: "formRef"
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_vm._v(" 保存更新 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };