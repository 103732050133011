const orderInfo = {
  attrs: {
    prop: 'planInfo',
    label: '订单信息',
    'min-width': 120
  },
  slot: 'planInfo'
};
const income = {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
};
const incomed = {
  attrs: {
    prop: 'totalIncomed',
    label: '已收'
  }
};
const unIncome = {
  attrs: {
    prop: 'unTotalIncome',
    label: '未收'
  }
};
const tripDate = {
  attrs: {
    prop: 'tripDate',
    label: '行程日期'
  },
  slot: 'tripDate'
};
const auditStatus = {
  attrs: {
    prop: 'auditProgress',
    label: '审核状态'
  }
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
const lastItem = [income, incomed, unIncome, auditStatus, actionItem];

// 按团号去收款 客户返款
export const CustomerColumn = [orderInfo, {
  attrs: {
    prop: 'orderNumber',
    label: '订单号'
  }
}, tripDate, {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 100
  }
}, {
  attrs: {
    prop: '',
    label: '联系人'
  },
  slot: 'contact'
}, {
  attrs: {
    prop: 'adultCount',
    label: '人数'
  },
  slot: 'count'
}, {
  attrs: {
    prop: 'members',
    label: '游客联系人',
    slot: 'members'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: '',
    label: '费用明细'
  },
  slot: 'detail'
}, ...lastItem];