var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar"
  }, [!_vm.isMobile ? _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_vm.currentRoutes && _vm.currentRoutes.children.length ? _c("el-menu", {
    attrs: {
      collapse: _vm.isCollapse,
      "default-active": _vm.activeMenu,
      "unique-opened": false,
      "collapse-transition": false
    }
  }, _vm._l(_vm.currentRoutes.children, function (route) {
    return _c("SidebarItem", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1) : _vm._e()], 1) : _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      collapse: _vm.isCollapse,
      "default-active": _vm.activeMenu,
      "unique-opened": false,
      "collapse-transition": false
    }
  }, _vm._l(_vm.routes, function (route) {
    return _c("SidebarItem", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };