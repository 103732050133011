import "core-js/modules/es.array.push.js";
import TopTitle from '../reserve/components/TopTitle.vue';
import { SelectDictionary } from '@/components/common';
import { orderFleet as columnData } from '@/config/resourceOrder';
import { parseDate } from '@/utils';
import { hasBooked } from '@/utils/status';
import { DayRangeMap } from '@/config/day';
import { TrafficMap } from '@/config/traffic';
import { mapGetters } from 'vuex';
import { pickTimeRadios, sendTimeRadios, pickRadios, sendRadios } from '@/config/radios';
const defaultState = () => ({
  busType: '',
  busTypeId: '',
  pickRemarks: '',
  sendRemarks: '',
  startDate: parseDate(new Date()),
  endDate: parseDate(new Date()),
  pickTimeQuantum: DayRangeMap.morning,
  sendTimeQuantum: DayRangeMap.morning,
  pickType: TrafficMap.plane,
  sendType: TrafficMap.plane,
  count: 1,
  remarks: '',
  planId: -1,
  headCompanyId: -1
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columnData,
      pickRadios,
      sendRadios,
      pickTimeRadios: pickTimeRadios,
      endTimeRadios: sendTimeRadios,
      title: '车队',
      tableData: [],
      btnLoading: false
    };
  },
  computed: {
    hasBooked,
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    }),
    tableTitle() {
      return this.showTitle ? this.title : '';
    }
  },
  watch: {
    data: {
      handler: function (fleetList) {
        if (!fleetList || !fleetList.length) return;
        this.tableData = fleetList;
      },
      immediate: true
    }
  },
  methods: {
    handleAdd() {
      const {
        startTripDate,
        endTripDate
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        startDate: startTripDate,
        endDate: endTripDate
      });
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (!id) {
        this.tableData.splice(scope.$index, 1);
      }
    },
    changeStartDate(row) {
      const {
        startDate,
        endDate
      } = row;
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        row.endDate = startDate;
      }
    },
    changeEndDate(row) {
      const {
        startDate,
        endDate
      } = row;
      if (new Date(endDate) < new Date(startDate)) {
        row.startDate = endDate;
      }
    },
    handleSelect(row, val) {
      row.busType = val.label;
    },
    changePickType(row, val) {
      row.pickRemarks = val;
    },
    changeSendType(row, val) {
      row.sendRemarks = val;
    },
    changePickTime(row, val) {
      row.pickTimeQuantum = val;
    },
    changeSendTime(row, val) {
      row.sendTimeQuantum = val;
    },
    handleUpdate() {}
  }
};