import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/insurance/fetchList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/insurance/insuranceData'];
    }
  }
};