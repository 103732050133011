var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-fix-bar"
  }, [_c("h3", [_vm._v("基础信息")]), _c("BillInput", {
    ref: "formRef",
    attrs: {
      "resource-id": _vm.resourceId,
      "cash-type": _vm.TYPES.company
    },
    on: {
      change: _vm.changeMoney
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: ""
    }
  }, [_c("SelectGroup", {
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.resourceId,
      callback: function ($$v) {
        _vm.resourceId = $$v;
      },
      expression: "resourceId"
    }
  })], 1)], 1), _c("TableUnPay", {
    ref: "unPayRef",
    attrs: {
      "resource-id": _vm.resourceId
    }
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };