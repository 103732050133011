import { parseDate } from '@/utils';
import { DEFAULT_PAGE } from '@/config';
import { getCurTagView } from '@/utils/tagView';
const dateArr = val => parseDate(val);
const today = dateArr(new Date());
const defaultDate = () => [today];
export default {
  data() {
    return {
      today,
      shiftDate: defaultDate(),
      checkDate: defaultDate(),
      sendDate: defaultDate(),
      inputDate: defaultDate(),
      billDate: defaultDate(),
      signDate: defaultDate(),
      shopDate: defaultDate(),
      orderDate: defaultDate(),
      createDate: defaultDate(),
      checkBillDate: defaultDate(),
      endDate: [''],
      tripDate: [''],
      department: []
    };
  },
  computed: {
    getToday() {
      return today;
    },
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    },
    getTagViewSearchExtra() {
      const curTag = getCurTagView();
      return curTag && curTag.extra;
    },
    reloadPage() {
      return this.$store.getters['app/reloadPage'];
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startTripDateS,
          startTripDateE,
          startCreateDate,
          endCreateDate
        } = data;
        const {
          endTripDateS,
          endTripDateE
        } = data;
        const {
          shiftDateTimeS,
          shiftDateTimeE
        } = data;
        const {
          orderDateS,
          orderDateE
        } = data;
        const {
          createDateS,
          createDateE
        } = data;
        const {
          startCashDate,
          endCashDate
        } = data;
        const {
          signDateS,
          signDateE
        } = data;
        const {
          inDateS,
          inDateE
        } = data;
        const {
          checkAccountTimeS,
          checkAccountTimeE
        } = data;
        const {
          departmentId,
          subDepartmentId,
          grandsonDepartmentId
        } = data;
        this.searchParams = data;
        this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
        this.shiftDate = [shiftDateTimeS, shiftDateTimeE]; // 接送预定安排发团日期
        this.sendDate = [startTripDateS, startTripDateE]; // 发团日期
        this.tripDate = [startTripDateS, startTripDateE]; // 酒店发团日期
        this.inputDate = [startCreateDate, endCreateDate]; // 输单日期
        this.endDate = [endTripDateS, endTripDateE]; // 散团日期
        this.checkDate = [orderDateS, orderDateE]; // 散团日期
        this.orderDate = [orderDateS, orderDateE]; // 订单日期
        this.createDate = [createDateS, createDateE]; // 创建日期
        this.billDate = [startCashDate, endCashDate]; // 下账日期
        this.signDate = [signDateS, signDateE]; // 下账日期
        this.shopDate = [inDateS, inDateE]; // 进店日期
        this.checkBillDate = [checkAccountTimeS, checkAccountTimeE]; // 对账日期
      },
      immediate: true
    },
    reloadPage: {
      handler: function (val) {
        if (!val) return;
        this.handleChangePage();
        this.$store.commit('app/RELOAD_PAGE', false);
      },
      immediate: true
    }
  },
  methods: {
    fetchData() {},
    handleSearch() {
      const search = {
        ...this.searchParams,
        pageNum: 1
      };
      // 缓存数据
      this.resetPage();
      this.appendView(search);

      // 获取数据，对应页面需要有该方法
      this.fetchData(search);
      this.$emit('on-search', search);
      // this.$store.commit('app/RELOAD_TABLE_HEIGHT')
    },
    handleChangePage() {
      const search = this.searchParams;
      this.appendView(search);
      this.fetchData(search);
    },
    appendView(search) {
      const {
        path
      } = this.$route;
      const extra = this.extra;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search,
        extra
      });
    },
    resetPage() {
      const {
        path
      } = this.$route;

      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1,
        size: this.searchParams ? this.searchParams.pageSize : DEFAULT_PAGE.pageSize
      });
    }
  }
};