export const planOneKeyup = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'custom',
    label: '客户(子部门)'
  },
  slot: 'custom'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '计调'
  },
  slot: 'dutyOPUserName'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'guides',
    label: '导游',
    width: 120
  },
  slot: 'guides'
}, {
  attrs: {
    prop: '',
    label: '旅监'
  },
  slot: 'uploadAction'
}, {
  attrs: {
    prop: '',
    label: '全国旅游系统'
  },
  slot: 'tourgroup'
}, {
  attrs: {
    prop: '',
    label: '兴坪船'
  },
  slot: 'boat'
}];