var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-check-refund"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on-search": _vm.fetchData,
      add: _vm.showDialog
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      statistics: _vm.statistics
    },
    on: {
      page: _vm.reload
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        row
      }) {
        return [_c("el-link", {
          attrs: {
            type: row.$inquiryType
          }
        }, [_vm._v(_vm._s(row.$inquiryStatus))])];
      }
    }, {
      key: "date",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.arriveDate))]), _c("div", [_vm._v(_vm._s(row.leaveDate))]), _c("div", [_vm._v(_vm._s(row.shiftNumber))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.adultCount) + " 大 " + _vm._s(row.childCount) + " 小 " + _vm._s(row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "itinerary",
      fn: function ({
        row
      }) {
        return _vm._l(_vm.getItinerary(row.itinerary), function (val, index) {
          return _c("div", {
            key: index,
            staticClass: "tal ml10"
          }, [_vm._v(" " + _vm._s(val) + " ")]);
        });
      }
    }, {
      key: "fee",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.fee))]), _c("div", {
          staticClass: "red"
        }, [_vm._v(_vm._s(row.remarks))])];
      }
    }, {
      key: "inquiryUser",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.inquiryUser))]), _c("div", [_vm._v(_vm._s(_vm.getInquireDate(row)))])];
      }
    }, {
      key: "quotationUser",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.quotationUser))]), _c("div", [_vm._v(_vm._s(_vm.getQuoteDate(row)))])];
      }
    }, {
      key: "resDate",
      fn: function ({
        row
      }) {
        return [_c("div", [_vm._v(_vm._s(row.responseTime))])];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(row);
            }
          }
        }, [_vm._v(" 更改 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.showDetail(row);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(row);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.showHistory(row);
            }
          }
        }, [_vm._v(" 历史 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleCopy(row);
            }
          }
        }, [_vm._v(" 复制 ")])];
      }
    }])
  }), _c("DialogAdd", {
    ref: "dialogAddRef",
    on: {
      reload: _vm.reload
    }
  }), _c("DialogDetail", {
    ref: "dialogDetailRef"
  }), _c("DialogHistory", {
    ref: "dialogHistoryRef",
    on: {
      detail: _vm.showDetail
    }
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };