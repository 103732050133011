var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "empty"
  }, [_c("img", {
    staticClass: "pic-no-data left",
    attrs: {
      src: require("@/assets/empty.png"),
      alt: "暂无数据"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };