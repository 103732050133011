import "core-js/modules/es.array.push.js";
import TableScenic from './TableScenic';
import { downloadExport } from '@/utils/exportExcel';
import { SelectScenic, SelectGroup, BtnExport, TableTotalBox } from '@/components/common';
import { sceniclAddList } from '@/config/report';
import { TYPES } from '@/config/resource';
import { searchMixin } from '@/mixin';
import { formatUnpayData } from '../helper';
import { today } from '@/utils';
export default {
  components: {
    TableScenic,
    SelectScenic,
    SelectGroup,
    BtnExport,
    TableTotalBox
  },
  mixins: [searchMixin],
  data: () => ({
    link: {
      checks: '/bill-check/reception'
    },
    tableLoading: false,
    columnData: sceniclAddList,
    tableData: [],
    sendDate: [today, ''],
    endDate: [],
    orderDate: [],
    total: 0,
    statistic: {},
    statisticData: [],
    searchParams: {
      resourceType: TYPES.scenic,
      startTripDateS: today
    }
  }),
  computed: {
    data() {
      return this.$store.getters['report/index/scenicDataAdd'];
    }
  },
  watch: {
    data: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    onLink({
      row,
      type
    }) {
      const {
        customerName,
        customerId
      } = row;
      this.$router.push({
        path: this.link[type],
        query: {
          customerName,
          customerId
        }
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, val) {
      // if (type == 'endTripDate') {
      //   this.searchParams.endTripDateS = date[0]
      //   this.searchParams.endTripDateE = date[1]
      // }
      // if (type == 'startTripDate') {
      //   this.searchParams.startTripDateS = date[0]
      //   this.searchParams.startTripDateE = date[1]
      // }
      // if (type == 'orderDate') {
      //   this.searchParams.orderDateS = date[0]
      //   this.searchParams.orderDateE = date[1]
      // }
      const [start, end] = val || ['', ''];
      this.searchParams[type + 'S'] = start;
      this.searchParams[type + 'E'] = end;
    },
    onSelect(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.resourceId = value;
      this.searchParams.resourceLabel = label;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = formatUnpayData(list);
      this.total = totalCount;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    fetchData(queryParam) {
      queryParam = {
        ...queryParam,
        addScenic: 1
      };
      this.$store.dispatch('report/index/getResourceStatic', queryParam);
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      const queryParam = {
        ...this.searchParams,
        addScenic: 1
      };
      this.$store.dispatch('report/index/fetchResourceStaticExcel', queryParam).then(res => {
        const fileName = '加点汇总统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};