var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-trip"
  }, [_c("TableBase", {
    attrs: {
      "column-data": _vm.columnData,
      "show-empty": false,
      "empty-text": " "
    }
  }), _vm._l(_vm.tableData, function (table, index) {
    return _c("div", {
      key: index,
      staticClass: "mb10"
    }, [_c("div", {
      staticClass: "table-expand",
      on: {
        click: function ($event) {
          table.expand = !table.expand;
        }
      }
    }, [_c("i", {
      class: !table.expand ? "el-icon-caret-top" : "el-icon-caret-bottom"
    }), _c("TextBase", [_vm._v("小团号：" + _vm._s(table.customerTripNumber))]), _c("TextBase", [_vm._v("接站牌：" + _vm._s(table.welcomeBorad))]), _c("TextBase", [_vm._v("产品线路：" + _vm._s(table.productName))]), _c("TextBase", [_vm._v(" 客户： "), _c("CustomerInfo", {
      attrs: {
        name: table.customerName
      }
    })], 1), _c("TextBase", [_vm._v(" 出团日期：" + _vm._s(table.startTripDate) + " ")]), _c("TextBase", [_vm._v(" 散团日期：" + _vm._s(table.endTripDate) + " ")]), _c("TextBase", [_vm._v(" 外联计调：" + _vm._s(table.dutyOPUserName) + " ")]), _c("TextBase", [_vm._v(" 总人数：" + _vm._s(table.adultCount) + "大" + _vm._s(table.childCount) + "小" + _vm._s(table.accompanyCount) + "陪 ")]), _c("TextBase", [_vm._v(" 团款：" + _vm._s(table.totalIncome) + " ")]), table.shuttles.length ? _c("TextBase", [_vm._v(" " + _vm._s(_vm.getShuttlesInfo(table.shuttles)) + " ")]) : _vm._e()], 1), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: table.remark && table.expand,
        expression: "table.remark && table.expand"
      }],
      staticStyle: {
        padding: "10px"
      }
    }, [_vm._v(" 备注： " + _vm._s(table.remark) + " ")]), _c("TableBase", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: table.expand,
        expression: "table.expand"
      }],
      staticClass: "plan-table",
      attrs: {
        "show-header": false,
        data: table.itinerarys,
        "column-data": _vm.columnData,
        dynamic: false
      },
      scopedSlots: _vm._u([{
        key: "traffic",
        fn: function ({
          scope
        }) {
          return [scope.row.transports.length ? _c("div", _vm._l(scope.row.transports, function (item) {
            return _c("div", {
              key: item.id
            }, [_vm._v(" " + _vm._s(item.transportName) + " ")]);
          }), 0) : _vm._e()];
        }
      }, {
        key: "breakfast",
        fn: function ({
          scope
        }) {
          return [scope.row.breakfast ? _c("IconRight") : _c("IconWrong")];
        }
      }, {
        key: "lunch",
        fn: function ({
          scope
        }) {
          return [scope.row.lunch ? _c("IconRight") : _c("IconWrong")];
        }
      }, {
        key: "dinner",
        fn: function ({
          scope
        }) {
          return [scope.row.dinner ? _c("IconRight") : _c("IconWrong")];
        }
      }], null, true)
    })], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };