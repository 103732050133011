// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/tpl_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-6e57a4aa]:export {\n  menuText: #bfcbd9;\n  menuActiveText: #409eff;\n  subMenuActiveText: #f4f4f5;\n  menuBg: #304156;\n  menuHover: #263445;\n  subMenuBg: #1f2d3d;\n  subMenuHover: #001528;\n  sideBarWidth: 170px;\n  topNavHeight: 56px;\n  labelSize: 12px;\n  red: #c03639;\n  green: #30b08f;\n}\n.print-template[data-v-6e57a4aa] {\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  float: right;\n}\n.print-template__item[data-v-6e57a4aa] {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100px;\n  height: 120px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  margin-bottom: 20px;\n  font-size: 14px;\n  color: #30b08f;\n  font-weight: bold;\n  cursor: pointer;\n}\n.print-template__item i[data-v-6e57a4aa] {\n  font-size: 30px;\n}\n.print-template__item.active[data-v-6e57a4aa] {\n  border: 2px solid #fec171;\n}\n.print-template__item:hover .btn-group[data-v-6e57a4aa] {\n  opacity: 1;\n}\n.print-template__item .btn-group[data-v-6e57a4aa] {\n  position: absolute;\n  bottom: 5px;\n  left: 10px;\n  opacity: 0;\n}", ""]);
// Exports
module.exports = exports;
