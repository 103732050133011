var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h3", [_vm._v("照片")]), _c("dropzone", {
    ref: "myVueDropzone",
    attrs: {
      id: "myVueDropzone",
      url: _vm.uploadUrl,
      "default-img": _vm.defaultImg
    },
    on: {
      "dropzone-removedFile": _vm.dropzoneR,
      "dropzone-success": _vm.dropzoneS
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };