export const departKeys = ['departmentName', 'subDepartmentName', 'grandsonDepartmentName'];
export const departIdKeys = ['departmentId', 'subDepartmentId', 'grandsonDepartmentId'];

// 字典类型
export const DICTIONARY = {
  guide: 'guideType',
  guideType: 'guideType',
  // 导游类型
  room: 'roomType',
  roomType: 'roomType',
  // 房型
  region: 'regionType',
  regionType: 'regionType',
  // 区域
  hotelStandard: 'hotelStandardType',
  hotelStandardType: 'hotelStandardType',
  // 标准
  level: 'levelType',
  levelType: 'levelType',
  // 钻级
  bedType: 'bedType',
  // 床型
  hotelRegionType: 'hotelRegionType',
  // 酒店区域
  teamType: 'teamType',
  // 团队类型
  teamFundType: 'teamFundType',
  // 团款类型
  refundType: 'refundType',
  // 返款类型
  ticketType: 'ticketType',
  // 票务类型
  signSheetType: 'signSheetType',
  // 签单类型
  proxyType: 'proxyType',
  // 代收类型
  stationType: 'stationType',
  // 站点类型
  busType: 'busType',
  // 车辆类型
  busUseType: 'busUseType',
  // 用车类型
  insuranceType: 'insuranceType',
  // 险种类型
  restaurantStandardType: 'restaurantStandardType',
  // 餐标
  restaurantType: 'restaurantType',
  // 餐厅类别
  authCollectType: 'authCollectType',
  // 收款/借款方式
  course: 'course',
  // 游船航向
  shipTicketType: 'shipTicketType',
  // 船舱类型
  shipType: 'shipType' // 船型
};
export const codeType = DICTIONARY;