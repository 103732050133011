var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-manage form-fix-bar"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.fetchData,
      update: _vm.showDialog
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      "is-select": ""
    },
    on: {
      page: _vm.fetchData,
      "selection-change": _vm.handleSelectionChange
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.planNumber) + " " + _vm._s(row.customerTripNumber) + " ")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startTripDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endTripDate) + " ")];
      }
    }, {
      key: "busUseDate",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endDate) + " ")];
      }
    }, {
      key: "busInfo",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.driverName) + " " + _vm._s(row.driverPhone) + " " + _vm._s(row.licenceNumber) + " " + _vm._s(row.busType) + " ")];
      }
    }])
  }), _c("DialogItem", {
    ref: "dialogRef",
    on: {
      confirm: _vm.batchUpdate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };