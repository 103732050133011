import "core-js/modules/es.array.push.js";
import { orderCustom2 as columns } from '@/config/order';
import { formatDepartmentParams } from '@/utils';
export default {
  data() {
    return {
      visible: false,
      isOrderEdit: false,
      columns,
      tableData: [],
      total: 0,
      search: {},
      spanArr: [],
      orderData: {}
    };
  },
  computed: {
    customData() {
      return this.$store.getters['resource/custom/customDataOrigin'];
    },
    roleInfo() {
      return this.$store.getters['system/role/roleCustomerInfoAuth'];
    }
  },
  methods: {
    restorePage() {
      const {
        path
      } = this.$route;
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1,
        size: 20
      });
    },
    fetchData(params = {}) {
      this.$store.dispatch('resource/custom/fetch2', {
        ...this.search,
        ...params
      }).then(res => {
        if (!res) return;
        this.formatCustomList(res);
      });
    },
    orderShow(data) {
      this.orderData = data;
      this.search.name = data.customerName;
      this.visible = true;
      this.isOrderEdit = true;
      this.processParams(this.roleInfo);
      this.fetchData();
    },
    show() {
      this.visible = true;
      this.processParams(this.roleInfo);
      this.fetchData();
    },
    hide() {
      this.visible = false;
      this.isOrderEdit = false;
      this.orderData = {};
      this.search = {};
    },
    processParams(data) {
      console.log('data:', data);
      const dpParams = formatDepartmentParams(data);
      this.search = {
        ...this.search,
        ...dpParams
      };
    },
    formatCustomList(data) {
      if (!data) return;
      this.tableData = [];
      this.total = data.totalCount;
      data.list.map(it => {
        const {
          contacts
        } = it;
        const {
          id,
          name,
          saleUserId,
          saleUserName,
          balance,
          creditMoney,
          isEnable,
          customerAreaManagerId,
          customerAreaManager
        } = it;
        if (contacts && contacts.length) {
          const contactList = contacts;
          contactList.map(a => {
            this.tableData.push({
              customerAreaManagerId,
              customerAreaManager,
              id,
              name,
              balance,
              creditMoney,
              isEnable,
              customerContactId: a.id,
              contact: a.contact,
              mobilePhone: a.mobilePhone,
              telphone: a.telphone,
              saleUserId,
              saleUserName
            });
          });
        } else {
          this.tableData.push({
            customerAreaManagerId,
            customerAreaManager,
            id,
            customerContactId: -1,
            name,
            contact: '',
            mobilePhone: '',
            telphone: '',
            balance,
            creditMoney,
            isEnable,
            saleUserId,
            saleUserName
          });
        }
      });
    },
    handleSelect(row) {
      if (!row.isEnable) return;
      this.$emit('on-select', row);
      // 如果是
      if (this.isOrderEdit) {
        const {
          name: customerName,
          id: customerId,
          creditMoney,
          contact: customerContactName,
          customerContactId
        } = row;
        this.createUpdate({
          customerName,
          customerId,
          creditMoney,
          customerContactName,
          customerContactId
        });
      } else {
        this.hide();
      }
    },
    createUpdate(opts) {
      // this.$bus.open(() => {
      this.$parent.createOrUpdate({
        ...this.orderData,
        ...opts
      });
      // }, `是否确定修改客户？`)
      this.hide();
    },
    handleSearch() {
      this.fetchData(this.search);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.search
      });
    }
  }
};