var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "地接费用修改",
      width: "960px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.createOrUpdate
    }
  }, [_vm.receptionList && _vm.receptionList.length ? [_c("Reception", {
    ref: "receptionRef",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      item: _vm.item,
      index: _vm.index,
      type: "plan"
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };