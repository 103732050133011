var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-bill-cashlog",
    attrs: {
      title: "查看现金日志",
      width: "600px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.form ? _c("FormBase", {
    attrs: {
      inline: "",
      "label-width": "80px"
    }
  }, _vm._l(_vm.getFormList, function (item, index) {
    return _c("FormItemBase", {
      key: index,
      attrs: {
        label: item.label + ":"
      }
    }, [_c("div", {
      staticStyle: {
        width: "180px"
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")])]);
  }), 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };