export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    }
  }
};