import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { updateFleetSuper } from '@/api/plan/fleet';
import { itemListKeys } from './config';
import { AuditRole } from '@/config/audit';
import { processAdd } from '@/utils';
export default {
  data() {
    return {
      form: null,
      visible: false,
      excludeKey: ['isChecked', 'isCustom', 'itemIndex', 'reqOrderScenicTickets', 'respOrderScenicTickets', 'auditProgress', 'status', 'auditStatus', 'ticketList', 'checkAccountStatus']
    };
  },
  watch: {
    form: {
      handler: function (data) {
        if (!data) return;
        this.getTotalPayMoney(data);
      },
      deep: true
    }
  },
  methods: {
    show(data) {
      if (!data) return;
      this.form = {
        ...data
      };
      const {
        planId
      } = this.$route.query;
      this.form.planId = planId;
      this.form.payType = data.payType ? data.payType.toUpperCase() : 'ALIPAY_E_CODE';
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = null;
    },
    // 计算总成本
    calcCost(row) {
      const {
        busFee,
        otherFee
      } = row;
      let total = processAdd(busFee, otherFee);
      return total;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney(data) {
      const money = this.calcCost(data);
      this.form.rowTotal = money;
    },
    deleteExcludeKey(data) {
      return Object.keys(data).reduce((acc, key) => {
        if (!this.excludeKey.includes(key)) {
          return {
            ...acc,
            [key]: data[key]
          };
        }
        return acc;
      }, {});
    },
    // 处理提交触角
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'selectStartDate'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 定义子项目的列表
        it.busCompanyTypeSimples = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.planFleetId;
        it.payType = it.payType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planFleetId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.busCompanyTypeSimples.push(item);
        } else {
          it.busCompanyTypeSimples.push(item);
          result.push(it);
          last = it;
        }
      });
      // 计算
      list.map(it => {
        it.totalPay = 0;
        it.busCompanyTypeSimples.map(its => {
          it.totalPay += this.calcCost(its);
        });
      });
      return result;
    },
    handleConfirm() {
      const {
        alipayPeerPayAmount,
        rowTotal
      } = this.form;
      if (alipayPeerPayAmount != rowTotal) {
        this.$bus.tip({
          type: 'warning',
          message: '金额与企业码支付金额不一致'
        });
        return;
      }
      const option = this.deleteExcludeKey(this.form);
      const list = this.beforeCommit([option]);
      updateFleetSuper(list).then(() => {
        this.hide();
        this.$emit('reload');
      });
    }
  }
};