import { uploadBase } from '@/config/index';
export default {
  data: () => ({
    uploadBase: uploadBase,
    info: null,
    visible: false
  }),
  methods: {
    show(row) {
      this.visible = true;
      this.info = row || {};
      this.showViewDetail(row);
    },
    showViewDetail() {
      const {
        driversLicensPic,
        qualificationCertificatePic
      } = this.info;
      this.$store.dispatch('resource/common/getOssImage', [driversLicensPic, qualificationCertificatePic]).then(res => {
        const [pic1, pic2] = res;
        this.info.driversLicensPic = pic1;
        this.info.qualificationCertificatePic = pic2;
      });
    },
    handleClose() {
      this.visible = false;
    }
  }
};