var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };