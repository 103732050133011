import "core-js/modules/es.array.push.js";
import { TableList, DialogShare, DialogHistory, DialogText } from './components';
import { SelectCustom } from '@/components/common';
import { offerList } from '@/config/offer';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    TableList,
    DialogText,
    DialogHistory,
    DialogShare,
    SelectCustom
  },
  mixins: [searchMixin],
  data() {
    return {
      searchParams: {},
      total: 0,
      columnData: offerList,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      offerData: 'offer/index/offerData'
    })
  },
  watch: {
    offerData: {
      handler: 'initData',
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        this.searchParams = data;
      },
      immediate: true
    }
  },
  created() {
    this.handleSearch();
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: this.getToday
        });
        return;
      }
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = list.map(it => {
        it.times = it.times + 1;
        return it;
      });
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    handleCreate() {
      this.$router.push({
        path: '/offer/add'
      });
    },
    handleEdit(data) {
      this.$router.push({
        path: '/offer/edit',
        query: {
          ...data
        }
      });
    },
    handleHistory(data) {
      this.$refs.historyRef.fetchOfferCode(data);
    },
    handleShare(id) {
      this.$refs.shareRef.show(id);
    },
    handleCode(data) {
      this.$router.push({
        path: '/offer/code',
        query: {
          ...data
        }
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    fetchData(opts) {
      this.$store.dispatch('offer/index/fetchOffer', opts);
    }
  }
};