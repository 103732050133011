import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchAgreement, delelteAgreement, addAgreementItem, delAgreementItem, fetchResourceImage, deleteResourceImage, fetchAgreementDetail, fetchAgreementTypeList, createOrUpdateAgreement, createOrUpdateAgr, delPriceFeeAgreement, getDownloadTemplate, importScenicElsx, importHotelElsx, getTravelSuperviseeDic, bindBusinessCode, delBusinessCode, fetchResourceType, fetchResourcesType } from '@/api/resource';
import { deleteFile, getFileUrl } from '@/api';
import { DEFAULT_PAGE } from '@/config';
import { formatTableData } from '@/utils/data';
const dfaultState = () => ({
  curResourceId: null,
  editSourceInfo: null,
  agreementItem: {},
  imageList: [],
  curAgreement: {
    list: [],
    totalCount: 0
  },
  curAgreementId: null,
  // 当前协议的id
  curAgreementDetail: null,
  curAgreementChild: [],
  // 当前协议的子列表项目
  imageCacheList: [],
  // 要删除的图片缓存(有id的，编辑操作)
  imageDelAddList: [],
  // 要删除的图片缓存(无id的，新增操作)
  imageListNew: [] // 存储新上传的图片
});
const state = dfaultState();
const getters = {
  imageList: state => state.imageList,
  imageListNew: state => state.imageListNew,
  imageCacheList: state => state.imageCacheList,
  imageDelAddList: state => state.imageDelAddList,
  curResourceId: state => state.curResourceId,
  agreementItem: state => state.agreementItem,
  editSourceInfo: state => state.editSourceInfo,
  curAgreement: state => state.curAgreement,
  curAgreementId: state => state.curAgreementId,
  curAgreementChild: state => state.curAgreementChild,
  curAgreementDetail: state => state.curAgreementDetail
};
const actions = {
  getOssImage({
    commit
  }, path) {
    path = Array.isArray(path) ? path : [path];
    return new Promise((resolve, reject) => {
      getFileUrl(path).then(res => {
        if (res && res.data) {
          resolve(res.data);
        }
      }).catch(err => {
        reject(err);
      });
    });
  },
  editImage({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      resourceId: '',
      resourceType: '',
      ...opts
    };
    return fetchResourceImage(opts).then(res => {
      let {
        list
      } = res.data;

      // 从aliyun OSS 获取图片
      const fileList = list.map(async it => {
        try {
          const file = await getFileUrl([it.path]);
          if (file) {
            it.path = file.data[0];
          }
          return it;
        } catch (error) {
          //
        }
      });
      return Promise.all(fileList).then(dataList => {
        commit('OLD_IMAGE', dataList);
        return dataList;
      });
    });
  },
  fetchAgreement({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchAgreement(opts).then(res => {
      const {
        data
      } = res;
      commit('AGREEMENT_LIST', data);
      commit('RESOURCE_ID', opts.resourceId);
      return res;
    });
  },
  fetchAgreementTypeList({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return fetchAgreementTypeList(opts).then(res => {
      let {
        data
      } = res;
      data = data.map(it => {
        it.contractPrices = formatTableData(it.contractPrices);
        return it;
      });
      return data;
    });
  },
  fetchAgreementDetail({
    commit
  }, id) {
    return fetchAgreementDetail(id).then(res => {
      const {
        data
      } = res;
      commit('AGREEMENT_DETAIL', data);
      return data;
    });
  },
  createOrUpdateAgreement({
    commit
  }, opts) {
    return createOrUpdateAgreement(opts);
  },
  createOrUpdateAgr({
    commit
  }, opts) {
    return createOrUpdateAgr(opts);
  },
  delPriceFeeAgreement({
    commit
  }, opts) {
    return delPriceFeeAgreement(opts);
  },
  // 删除协议
  deleteAgreement({
    commit
  }, id) {
    return delelteAgreement(id).then(() => {
      commit('AGREEMENT_DELETE', id);
    });
  },
  // 添加协议子项
  addAgreementItem({
    dispatch
  }, opts) {
    return addAgreementItem(opts).then(res => {
      // // 获取协议数据子内容
      // dispatch('fetchAgreementDetail', opts.contracId)
      return res;
    });
  },
  // 删除协议子项
  delAgreementItem({
    commit
  }, id) {
    return delAgreementItem(id).then(res => {
      commit('AGREEMENT_CHILD_DEL', id);
      return res;
    });
  },
  // 查询旅监局字典
  getTravelSuperviseeDic({
    commit
  }, opts) {
    return getTravelSuperviseeDic(opts).then(res => {
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  fetchImage({
    commit
  }, opts) {
    return this.dispatch('resource/common/editImage', opts);
  },
  editAgreement({
    commit
  }, item) {
    commit('EDIT_AGREEMENT', item);
  },
  pickImage({
    commit
  }, item) {
    commit('PICK_IMAGE', item);
  },
  // 新上传的图片
  newImage({
    commit
  }, item) {
    commit('NEW_IMAGE', item);
  },
  // 删除图片，删除有id 的图片（编辑的删除）
  removeImageCache({
    commit
  }, it) {
    commit('REMOVE_IMAGE_CACHE', it);
  },
  // 删除图片，删除添加上传前的图片（新增）
  removeImageAdd({
    commit
  }, it) {
    commit('REMOVE_IMAGE_ADD', it);
  },
  // 删除oss上的文件或图片
  deleteImage({
    commit
  }, files) {
    files = Array.isArray(files) ? files : [files];
    return deleteFile(files);
  },
  // 删除缓存的图片(有id的，编辑操作)
  deleteCacheImage({
    commit,
    dispatch,
    getters
  }) {
    if (getters.imageCacheList.length) {
      // 删除 oss 图片
      const delFiles = getters.imageCacheList.map(it => it.path);
      dispatch('deleteImage', delFiles);

      // 过滤要删除资源的数据
      const queryList = getters.imageCacheList.map(it => {
        const {
          id,
          resourceId,
          resourceType
        } = it;
        return {
          id,
          resourceId,
          resourceType
        };
      });

      // 删除资源
      const Pall = queryList.map(deleteResourceImage);
      return Promise.all(Pall).then(() => {
        commit('CLEAR_IMAGE_CACHE');
      });
    }
  },
  // 删除缓存的图片(无id的，新增操作)
  deleteAddImage({
    commit,
    getters
  }) {
    commit('CLEAR_IMAGE_ADD');
  },
  clearImage({
    commit
  }) {
    commit('CLEAR_IMAGE');
  },
  // 当前添加资源的信息
  editSource({
    commit
  }, item) {
    commit('EDIT_SOURCE', item);
  },
  editResource({
    dispatch
  }, item) {
    dispatch('editSource', item);
    this.dispatch('resource/account/editAccount', item);
  },
  // 下载协议模版
  getDownloadTemplate({
    commit
  }, params) {
    return getDownloadTemplate(params).then(res => {
      return res;
    }).catch(function (error) {});
  },
  // 上传景区协议excel
  importScenicElsx({
    commit
  }, params) {
    return importScenicElsx(params).then(res => {
      return res;
    }).catch(function (error) {});
  },
  // 上传酒店协议excel
  importHotelElsx({
    commit
  }, params) {
    return importHotelElsx(params).then(res => {
      return res;
    }).catch(function (error) {});
  },
  // 商家资源绑定
  bindBusinessCode({
    commit
  }, opts) {
    return bindBusinessCode(opts).then(res => {
      return res;
    });
  },
  // 商家资源解除绑定
  delBusinessCode({
    commit
  }, opts) {
    return delBusinessCode(opts).then(res => {
      return res;
    });
  },
  // 获取资源协议  根据资源类型和资源id获取
  fetchResourceType({
    rootGetters
  }, opts) {
    const {
      travelAgencyId
    } = rootGetters['user/userData'];
    return fetchResourceType({
      ...opts,
      travelAgencyId
    }).then(res => {
      return res.data;
    });
  },
  // 获取资源协议  根据资源类型和资源id获取 多个资源获取
  fetchResourcesType({
    rootGetters
  }, opts) {
    const {
      travelAgencyId
    } = rootGetters['user/userData'];
    return fetchResourcesType({
      ...opts,
      travelAgencyId
    }).then(res => {
      return res.data;
    });
  }
};
const mutations = {
  PICK_IMAGE: (state, item) => {
    // state.imageList = []
    if (Array.isArray(item)) {
      state.imageList = state.imageList.concat(item);
    } else {
      state.imageList.push(item);
    }
  },
  AGREEMENT_LIST: (state, data) => {
    state.curAgreement = data;
  },
  AGREEMENT_DETAIL: (state, data) => {
    state.curAgreementDetail = data;
  },
  AGREEMENT_CHILD: (state, data) => {
    state.curAgreementChild = data;
  },
  CUR_AGREEMENT_ID: (state, id) => {
    state.curAgreementId = id;
  },
  AGREEMENT_CHILD_ADD: (state, data) => {
    state.curAgreementChild.push(data);
  },
  AGREEMENT_CHILD_DEL: (state, id) => {
    state.curAgreementChild = state.curAgreementChild.filter(it => it.id !== id);
  },
  AGREEMENT_CU: (state, item) => {
    const {
      isUpdate,
      id
    } = item;
    if (!isUpdate) {
      state.curAgreement.totalCount++;
      state.curAgreement.list.unshift(item);
    } else {
      const fIndex = state.curAgreement.list.findIndex(it => it.id == id);
      state.curAgreement.list.splice(fIndex, 1, item);
    }
  },
  AGREEMENT_DELETE: (state, id) => {
    state.curAgreement.list = state.curAgreement.list.filter(it => it.id !== id);
  },
  REMOVE_IMAGE: (state, name) => {
    const list = state.imageList.filter(it => it.name !== name);
    state.imageList = list;
  },
  REMOVE_IMAGE_CACHE(state, item) {
    state.imageCacheList.push(item);
    const list = state.imageList.filter(it => it.id !== item.id);
    state.imageList = list;
  },
  REMOVE_IMAGE_ADD(state, item) {
    state.imageDelAddList.push(item);
    // const list = state.imageList.filter(it => it.id !== item.id)
    // state.imageList = list
  },
  CLEAR_IMAGE_CACHE: state => {
    state.imageCacheList = [];
  },
  CLEAR_IMAGE_ADD: state => {
    state.imageDelAddList = [];
  },
  OLD_IMAGE: (state, list) => {
    state.imageList = list;
  },
  NEW_IMAGE: (state, item) => {
    state.imageListNew.push(item);
  },
  CLEAR_IMAGE: state => {
    state.imageList = [];
    state.imageListNew = [];
    state.imageDelAddList = [];
  },
  EDIT_SOURCE: (state, item) => {
    state.editSourceInfo = item;
  },
  EDIT_AGREEMENT: (state, item) => {
    state.agreementItem = item;
  },
  RESOURCE_ID: (state, id) => {
    state.curResourceId = id;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};