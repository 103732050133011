var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      "column-data": _vm.columnData,
      "show-empty": false,
      "hide-bar": true,
      sticky: true,
      "empty-text": " "
    }
  }), _vm._l(_vm.tableData, function (table) {
    return _c("div", {
      key: table.id
    }, [_c("div", {
      staticClass: "table-expand"
    }, [_c("i", {
      class: !table.expand ? "el-icon-caret-top" : "el-icon-caret-bottom",
      on: {
        click: function ($event) {
          return _vm.toggle(table);
        }
      }
    }), _c("TextBase", [_vm._v("团号：" + _vm._s(table.planNumber))]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 出团日期：" + _vm._s(table.startPlanDate) + " ")]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 散团日期：" + _vm._s(table.endPlanDate) + " ")]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 操作计调：" + _vm._s(table.dutyOPUserName) + " ")]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 总人数：" + _vm._s(table.adultCount) + "大" + _vm._s(table.childCount) + "小" + _vm._s(table.accompanyCount) + "陪 ")]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 团状态：" + _vm._s(_vm.planStatus(table.planStatus)) + " ")]), _c("TextBase", {
      staticClass: "right-span"
    }, [_vm._v(" 审核状态：" + _vm._s(_vm.auditStatus(table.auditStatus)) + " ")]), _vm._l(table.guides, function (item) {
      return _c("TextBase", {
        key: item.id,
        staticClass: "right-span"
      }, [item.reimb ? _c("i", [_vm._v(" 导游：" + _vm._s(item.name) + " " + _vm._s(item.mobileIphone) + " ")]) : _vm._e()]);
    }), _c("div", {
      staticClass: "order-control"
    }, [_c("BtnLink", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.handleDetail(table);
        }
      }
    }, [_vm._v(" 查看 ")]), _vm.comparePlanStatus(table) ? _c("BtnLink", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.handleUpdate(table);
        }
      }
    }, [_vm._v(" 编辑 ")]) : _c("el-button", {
      attrs: {
        type: "text",
        disabled: ""
      }
    }, [_vm._v(" 已归档 ")])], 1)], 2), _c("TableBase", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: table.expand,
        expression: "table.expand"
      }],
      attrs: {
        data: table.orders,
        "column-data": _vm.columnData,
        "row-class-name": _vm.tableRowClassName,
        "show-header": false,
        sticky: true,
        dynamic: false,
        border: ""
      },
      scopedSlots: _vm._u([{
        key: "orderInfo",
        fn: function ({
          scope
        }) {
          return [_c("TripNumberBase", {
            on: {
              "on-click": function ($event) {
                return _vm.linkDetail(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(scope.row.customerTripNumber ? scope.row.customerTripNumber : "--") + " ")]), _c("br"), _vm._v(" " + _vm._s(scope.row.productName) + " "), _vm.orderAuditStatus(scope.row) ? _c("div", {
            staticStyle: {
              cursor: "pointer"
            }
          }, [_c("el-tag", {
            attrs: {
              type: "info"
            },
            on: {
              click: function ($event) {
                return _vm.linkAuditDetail(scope);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.reviewerStatus[scope.row.addAuditStatus]) + " ")])], 1) : _vm._e()];
        }
      }, {
        key: "accompanyInfo",
        fn: function ({
          scope
        }) {
          return [scope.row.members.length ? [_c("Members", {
            attrs: {
              row: scope.row
            }
          })] : [_vm._v(" -- ")]];
        }
      }, {
        key: "customerName",
        fn: function ({
          scope
        }) {
          return [_c("CustomerInfo", {
            attrs: {
              name: scope.row.customerName
            }
          })];
        }
      }, {
        key: "count",
        fn: function ({
          scope
        }) {
          return [_c("person-count", {
            attrs: {
              row: scope.row
            }
          })];
        }
      }, {
        key: "date",
        fn: function ({
          scope
        }) {
          return [_c("span", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("br"), _c("span", [_vm._v(_vm._s(scope.row.endTripDate))])];
        }
      }, {
        key: "traffic",
        fn: function ({
          scope
        }) {
          return [scope.row.itinerarys && scope.row.shuttles.length ? _vm._l(scope.row.shuttles, function (item) {
            return _c("span", {
              key: item.id
            }, [_c("span", [_vm._v(_vm._s(item.shuttleType == "PICK" ? "【接团】" : "【送团】"))]), _c("span", [_vm._v(" " + _vm._s(_vm.formatIosDate(item.shiftDateTime, "{y}-{m}-{d} {h}:{i}")) + " ")]), _c("br"), _c("span", [_vm._v(" " + _vm._s(item.desPosition) + " ")]), _vm._v("   "), _c("span", [_vm._v(_vm._s(item.taskType == "PLANE" ? "飞机" : "火车"))]), _c("span", [_vm._v(_vm._s(item.shiftNumber))]), _c("br")]);
          }) : _vm._e()];
        }
      }, {
        key: "itinerary",
        fn: function ({
          scope
        }) {
          return [scope.row.itinerarys && scope.row.itinerarys.length ? _vm._l(scope.row.itinerarys, function (item) {
            return _c("div", {
              key: item.id,
              staticStyle: {
                "text-align": "left",
                "padding-left": "10px"
              }
            }, [_vm._v(" " + _vm._s(item.itineraryDate) + _vm._s(item.itineraryDesc) + " ")]);
          }) : _vm._e()];
        }
      }, {
        key: "action",
        fn: function ({
          scope
        }) {
          return [_c("el-dropdown", {
            on: {
              command: function ($event) {
                return _vm.handleCommand(scope, $event, table);
              }
            }
          }, [_c("el-button", {
            attrs: {
              type: "text"
            }
          }, [_vm._v(" 操作"), _c("i", {
            staticClass: "el-icon-arrow-down el-icon--right"
          })]), _c("el-dropdown-menu", {
            attrs: {
              slot: "dropdown"
            },
            slot: "dropdown"
          }, [_c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-tickets",
              command: "handleShow"
            }
          }, [_vm._v(" 查看 ")]), _vm.comparePlanStatus(scope.row) ? _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-edit-outline",
              command: "handleEdit"
            }
          }, [_vm._v(" 编辑 ")]) : _vm._e(), _c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-document-copy",
              command: "handleCopy"
            }
          }, [_vm._v(" 复制 ")]), _vm.comparePlanStatus(scope.row) ? _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-folder-checked",
              command: "handleMove"
            }
          }, [_vm._v(" 转移 ")]) : _vm._e(), _vm.comparePlanStatus(scope.row) ? _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-folder-remove",
              command: "handleRevoke"
            }
          }, [_vm._v(" " + _vm._s(scope.row.isCancel ? "取消退单" : "退单") + " ")]) : _vm._e(), _vm.comparePlanStatus(scope.row) ? _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              icon: "el-icon-document-delete",
              command: "handleRemove"
            }
          }, [_vm._v(" 移除 ")]) : _vm._e(), !_vm.comparePlanStatus(scope.row) ? _c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-folder-checked",
              disabled: ""
            }
          }, [_vm._v(" 已归档 ")]) : _vm._e(), _c("el-dropdown-item", {
            directives: [{
              name: "permission",
              rawName: "v-permission"
            }],
            attrs: {
              disabled: scope.row.planId == -1,
              icon: "el-icon-coordinate",
              command: "handleAuditDetail"
            }
          }, [_vm._v(" 单团明细 ")]), _c("el-dropdown-item", {
            attrs: {
              icon: "el-icon-printer",
              command: "handlePrint"
            }
          }, [_vm._v(" 打印 ")])], 1)], 1)];
        }
      }], null, true)
    })], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };