// 支付宝企业码
import request from '@/utils/request';

// 当前及未来7天 导游现付金额预计
export function weekListRequest(params) {
  return request({
    url: '/order/plan/guide-next-7-days-pay-static',
    method: 'get',
    params
  });
}

// 查询旅行社支付宝企业码付审批记录
export function appliesRequest(params) {
  return request({
    url: '/order/guideBorrow/page-alipay-applies',
    method: 'get',
    params
  });
}

// 企业码按团统计
export function regimentListRequest(params) {
  const url = '/order/guideBorrow/alipay-consume-p';
  return request({
    url,
    method: 'get',
    params
  });
}

// 企业码按团统计 (不分页)
export function regimentAllRequest(params) {
  const url = '/order/guideBorrow/alipay-consume-l';
  return request({
    url,
    method: 'get',
    params
  });
}