import { findComponentsUpward, getNextDate, parseDate, validateTeamFee } from '@/utils';
import { orderPersonalMerge } from '@/config/order';
import { InputTeamNumber, SelectStaff, SelectProduct, SelectDictionary } from '@/components/common';
import TableTransfer from './TableTransfer';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  ids: [],
  arranges: [],
  startTripDate: '',
  endTripDate: '',
  planNumber: '',
  // 大团号
  planId: '',
  // 拼至已有团
  productId: '',
  productName: '',
  productDays: '',
  dutyOPUserId: '' // 责任计调
});
export default {
  components: {
    InputTeamNumber,
    SelectStaff,
    SelectProduct,
    TableTransfer,
    SelectDictionary
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableData: [],
      columnData: orderPersonalMerge,
      flag: 1,
      // 1 =>  独立成团； 2 => 拼至已有团
      form: defaultState(),
      orderList: [],
      planNumber: '',
      productName: '',
      startDate: '',
      endDate: '',
      parentComp: null,
      // 父组件
      configData: null
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      productData: 'resource/product/productData',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getProductName() {
      return this.productName;
    },
    getProductDays() {
      return {
        productId: this.form.productId,
        date: this.form.startTripDate
      };
    }
  },
  watch: {
    userData: {
      handler: 'getUserData',
      immediate: true
    }
  },
  mounted() {
    this.$bus.on('group:custom', this.handlePlanCustom);
    this.parentComp = findComponentsUpward(this, 'Personal')[0];
  },
  methods: {
    show(list, config) {
      this.restore();
      this.configData = config;
      const isValid = list.some(row => {
        return validateTeamFee(row, this.configData);
      });
      if (isValid) {
        this.$bus.tip({
          type: 'error',
          message: '团款不合理，无法确认成团，请录入合理的团款!',
          duration: 0
        });
        return;
      }
      this.visible = true;
      this.handleDate(list);
      this.getUserData(this.userData);
    },
    hide() {
      this.visible = false;
    },
    restore() {
      this.form = defaultState();
      this.planNumber = '';
      this.flag = 1;
      this.productName = '';
      this.startDate = '';
      this.endDate = '';
    },
    validateConfig(row) {
      // 确认成团之前判断配置规则，如果不通过不允许拼团
      if (validateTeamFee(row, this.configData)) {
        return;
      }
    },
    changeDate(type, val) {
      this.form[type] = val;
    },
    getDate(list) {
      const pickDate = (list, key) => {
        return list.map(it => {
          return Date.parse(it[key]);
        });
      };
      const arrMin = arr => Math.min(...arr);
      const arrMax = arr => Math.max(...arr);
      const startList = pickDate(list, 'startTripDate');
      const endList = pickDate(list, 'endTripDate');
      const min = arrMin(startList);
      const max = arrMax(endList);
      return {
        min,
        max
      };
    },
    handleDate(list) {
      const {
        min,
        max
      } = this.getDate(list);
      const {
        productId
      } = list[0];

      // this.startDate = new Date(min)
      // this.endDate = new Date(max)
      this.startDate = parseDate(min);
      this.endDate = parseDate(max);
      this.form.productId = productId;
      this.form.startTripDate = this.startDate;
      this.form.endTripDate = this.endDate;
      this.orderList = list;
      this.$store.dispatch('resource/product/fetchDetail', productId).then(res => {
        if (!res) return;
        const {
          name,
          days
        } = res;
        this.productName = name;
        this.form.productName = name;
        this.form.productDays = days;
      });
    },
    changeTeamNumber(val) {
      this.form.planNumber = val;
    },
    // 选择团队类型
    selectTeamType(val) {
      const {
        value
      } = val;
      this.form.groupType = value;
    },
    // DialogConfirm(id) {
    //   this.$parent.commonFunt(id, 'CONFIRM')
    // },

    changeRadio(id) {
      // 当选择拼至已有团的单选框后触发
      this.flag = 2;
      this.form.planId = id;
      this.$parent.showConfirmDialog(id);
    },
    radioSingle() {
      this.flag = 1;
      this.form.arranges = [];
      this.$refs.transferRef.clearSelectRadio();
    },
    onSelect(type, val) {
      const {
        label,
        value
      } = val;
      this.form[type + 'Id'] = value;
      this.form[type + 'Name'] = label;
    },
    selectProduct(data) {
      const {
        id,
        name,
        days
      } = data;
      this.form.productId = id;
      this.form.productName = name;
      this.form.productDays = days;
      this.getProductInfo();
    },
    getProductInfo() {
      // 如果当前页面刷新，则不会触发创建行程
      const {
        productDays,
        startTripDate
      } = this.form;
      const doCreate = () => {
        let curDate = startTripDate;
        for (let i = 1; i <= productDays; i++) {
          curDate = getNextDate(curDate);
        }
        setTimeout(() => {
          this.endDate = curDate;
          this.form.endTripDate = curDate;
        });
      };
      doCreate();
    },
    getUserData(val) {
      if (val) {
        this.form.outOPUserName = val.realName;
        this.form.dutyOPUserId = val.id;
      }
    },
    // 拼团安排数据
    handlePlanCustom(data) {
      this.form.arranges = data.map(it => {
        ;
        ['busSelf', 'hotelSelf', 'restaurantSelf', 'scenicSelf'].forEach(key => {
          const val = it[key];
          if (typeof val == 'number') {
            it[key] = false;
          }
          if (typeof val == 'string') {
            it[key] = true;
          }
        });
        const {
          busSelf,
          hotelSelf,
          restaurantSelf,
          scenicSelf,
          tripDate
        } = it;
        const item = {
          busSelf,
          hotelSelf,
          restaurantSelf,
          scenicSelf,
          tripDate
        };
        return item;
      });
    },
    // 拼团
    handleCreate() {
      this.form.ids = this.getIds();
      if (this.flag == 1) {
        // 独立成团
        if (!this.validateForm()) return;
        this.form.planId = '';
      }
      const loading = this.$bus.loading();
      if (this.flag == 2) {
        if (!this.form.planId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择要拼的团！'
          });
          loading.close();
          return;
        }
        // 散客拼团
        delete this.form.startTripDate;
        delete this.form.endTripDate;
      }
      const opts = {
        ...this.form,
        flag: this.flag
      };
      this.$store.dispatch('order/personal/personalInGroup', opts).then(() => {
        loading.close();
        this.$bus.tip();
        this.hide();

        // 小团号列表
        this.currentPlanId && this.$store.dispatch('plan/index/getOrderByPlanId', this.currentPlanId);
        this.parentComp.batchFetchData();
      }).catch(() => {
        loading.close();
      });
    },
    getIds() {
      let ids = [];
      ids = [...ids, ...this.orderList.filter(it => it.checked).map(it => it.id)];
      return ids;
    },
    validateForm() {
      if (!this.form.productId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择产品线路'
        });
        this.$store.dispatch('app/setDialogLoading', false);
        return false;
      }
      return true;
    }
  }
};