var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "group-detail"
  }, [_c("el-table", {
    staticClass: "table-detail",
    attrs: {
      data: _vm.tableData,
      "row-key": "id",
      border: "",
      "default-expand-all": "",
      "tree-props": {
        children: "children",
        hasChildren: "hasChildren"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "index",
      label: "序号",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.children ? _c("span", {
          staticClass: "blue"
        }, [_vm._v(_vm._s(scope.row.index))]) : _c("span", [_vm._v(_vm._s(scope.row.index))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "planNumber",
      label: "团号",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "date",
      label: "日期",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.startTripDate)), _c("br"), _vm._v(" " + _vm._s(scope.row.endTripDate) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "area",
      label: "办事处",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "customerAreaManager",
      label: "业务员",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "dutyOPUserName",
      label: "计调",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "customerName",
      label: "组团社",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "guideName",
      label: "导游",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "adultCount",
      label: "成人",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "childCount",
      label: "儿童",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "feeSum",
      label: "团队收入",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalPay",
      label: "团队成本",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "profit",
      label: "团队利润",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalFee",
      label: "团队合计",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "收入",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "shopIncome",
      label: "购物收入",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shopSubsidy",
      label: "购物后返",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalAddScenicIncome",
      label: "加点收入",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalOtherIncome",
      label: "其他收入",
      align: "center"
    }
  })], 1), _c("el-table-column", {
    attrs: {
      label: "支出",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "totalGuideServiceFeePay",
      label: "导游",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalBusPay",
      label: "车费",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalScenicQuotePay",
      label: "门票",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalHotelQuotePay",
      label: "房费",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalRestaurantPay",
      label: "餐费",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalOtherPay",
      label: "其他成本",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalShopRebatePay",
      label: "购物成本",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalAddScenicQuotePay",
      label: "加点成本",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalOutTransferPay",
      label: "外接",
      align: "center"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };