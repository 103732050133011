import Tips from './Tips';
export default {
  name: 'OmitTips',
  components: {
    Tips
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    getContent() {
      const result = this.content;
      if (!result) return '';
      return result.length > 10 && result.slice(0, 10);
    }
  }
};