var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      border: ""
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };