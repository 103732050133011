var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "on-search": _vm.handleSearch
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "年"
    }
  }, [_c("InputDate", {
    attrs: {
      "value-format": "yyyy",
      format: "yyyy",
      label: "",
      type: "year"
    },
    model: {
      value: _vm.searchParams.year,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "year", $$v);
      },
      expression: "searchParams.year"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.onSelectCustom
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerAreaServiceId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaServiceId", $$v);
      },
      expression: "searchParams.customerAreaServiceId"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 新增 ")]), _c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.handleTarget
    }
  }, [_vm._v(" 新增客户目标 ")]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.importTemplate
    }
  }, [_vm._v("导入目标")]), _c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.downloadTemplate
    }
  }, [_vm._v("下载模版")])], 1), _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "区域经理"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaManagerId", $$v);
      },
      expression: "searchParams.customerAreaManagerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.salesmanId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "salesmanId", $$v);
      },
      expression: "searchParams.salesmanId"
    }
  })], 1)], 1)], 2), _c("div", [_c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.total > 0 ? [_c("TotalTable", {
    attrs: {
      border: "",
      "table-data": _vm.statisticData,
      statistic: _vm.statistic,
      "column-data": _vm.columnData
    }
  })] : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2), _c("DialogTarget", {
    ref: "dialogTargetRef",
    on: {
      reload: _vm.onSearch
    }
  }), _c("customDialog", {
    ref: "dialogRef"
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogExcel", {
    ref: "dialogExcelRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };