// 下账
import request from '@/utils/request';
import { SortBy } from '@/config';

// prettier-ignore
export { toggleGuideCheck, fetchGuide, fetchUnPayGuide, fetchPayedGuide, fixflushGuideReimb } from './guide/index';

// 银行账户总金额相关信息（期初余额，余额，总收入、总支出）
export const fetchBankStatices = params => {
  return request({
    url: '/order/ka/bank-static-money',
    method: 'get',
    params
  });
};

// 预收收付余额查询
export const fetchPrePayBalance = params => {
  return request({
    url: '/order/ka/getPreCashBalance',
    method: 'get',
    params
  });
};

// 按团号客户返款
export const fetchUnPayRegimentCustomer = params => {
  return request({
    url: '/order/ka/planSubsidyList',
    method: 'get',
    params
  });
};

// 按单位客户返款
export const fetchUnPayUnitCustomer = params => {
  return request({
    url: '/order/ka/unitSubsidyList',
    method: 'get',
    params
  });
};

// 下账列表
export const fetchBillList = params => {
  return request({
    url: '/order/ka/page',
    method: 'get',
    params: {
      cashSourceType: 'RESOURCE',
      descOrAsc: SortBy.desc,
      ...params
    }
  });
};

// 根据id 查询下账数据
export const fetchBillDetail = id => {
  return request({
    url: '/order/ka/getLogCash',
    method: 'get',
    params: {
      id
    }
  });
};

// 按团付款，获取已结清数据
export const fetchRegimentPayed = params => {
  return request({
    url: '/order/ka/pageCashComplete-by-plan',
    method: 'get',
    params
  });
};
export const fetchRegimentCollect = fetchRegimentPayed;

// 单位团款未结清列表
export const fetchUnPayList = params => {
  return request({
    url: '/order/ka/unitFeesList',
    method: 'get',
    params
  });
};

// 购物未结清列表
export const fetchUnPayShop = params => {
  return request({
    url: '/order/ka/unitShopUn',
    method: 'get',
    params
  });
};

// 车队未结清列表
export const fetchUnPayFleet = params => {
  return request({
    url: '/order/ka/unitBusUn',
    method: 'get',
    params
  });
};

// 保险未结清列表
export const fetchUnPayInsurance = params => {
  return request({
    url: '/order/ka/unitInsuranceUn',
    method: 'get',
    params
  });
};

// 酒店未结清列表
export const fetchUnPayHotel = params => {
  return request({
    url: '/order/ka/unitHotelUn',
    method: 'get',
    params
  });
};

// 地接未结清列表
export const fetchUnPayReception = params => {
  return request({
    url: '/order/ka/unitOutTransferUn',
    method: 'get',
    params
  });
};

// 餐厅未结清列表
export const fetchUnPayRestaurant = params => {
  return request({
    url: '/order/ka/unitRestaurantUn',
    method: 'get',
    params
  });
};

// 大交通未结清列表
export const fetchUnPayTraffic = params => {
  return request({
    url: '/order/ka/unitTransportUn',
    method: 'get',
    params
  });
};

// 大交通未结清列表
export const fetchUnPayScenic = params => {
  return request({
    url: '/order/ka/unitScenicUn',
    method: 'get',
    params
  });
};

// 单位团款已结清列表
export const fetchPayedList = params => {
  return request({
    url: '/order/ka/pageCashComplete',
    method: 'get',
    params
  });
};

// 添加下账
export const createDown = data => {
  return request({
    url: '/order/ka/addCash',
    method: 'post',
    data
  });
};

// 删除下账
export const deleteDown = data => {
  return request({
    url: '/order/ka/delCash',
    method: 'post',
    data
  });
};

// 添加收款/付款
export const addCollect = data => {
  return request({
    url: '/order/ka/addLogCash',
    method: 'post',
    data
  });
};
export const addDownPay = addCollect;

// 更新收款/付款
const updatePayOrCollec = data => {
  return request({
    url: '/order/ka/updateLogCash',
    method: 'post',
    data
  });
};
export const updateDownPay = updatePayOrCollec;
export const updateDownCollect = updatePayOrCollec;

// 删除收款/付款
export const delCollect = id => {
  return request({
    url: '/order/ka/delLogCash',
    method: 'post',
    data: {
      id
    }
  });
};
export const delDownPay = delCollect;

// 银行账户列表
export const fetchBank = () => {
  return request({
    url: '/order/ka/listBank',
    method: 'get'
  });
};

// 现金日志流
export const fetchCashPage = params => {
  return request({
    url: '/log/cash/page',
    method: 'get',
    params
  });
};
// 其他单位未结清数据
export const fetchUnPayOther = params => {
  return request({
    url: '/order/ka/unitPayUn',
    method: 'get',
    params
  });
};