import { orderType as OrderType } from '@/config/order';
import { mapGetters } from 'vuex';
export default {
  props: {
    orderType: {
      type: String,
      default: OrderType.team
    }
  },
  data() {
    return {
      show: true,
      data: null
    };
  },
  computed: {
    ...mapGetters({
      customerId: 'order/index/customerId',
      curOrderType: 'order/index/curOrderType',
      creditData: 'resource/custom/credit'
    }),
    getData() {
      return this.creditData ? this.creditData : null;
    },
    getTmpCreditContent() {
      if (!this.creditData) return '';
      const {
        tmpCreditMoney,
        startDate,
        endDate
      } = this.creditData;
      return `临时额度： ${tmpCreditMoney} 有效期 (${startDate} ~ ${endDate})`;
    }
  },
  watch: {
    customerId: {
      handler: function (id) {
        if (!id) {
          this.show = false;
          this.$store.commit('resource/custom/CLEAN_CUSTOM_DATA');
          return;
        }
        this.show = true;
        this.fetchCustomDetail(id);
      },
      immediate: true
    }
  },
  methods: {
    fetchCustomDetail(id) {
      return this.$store.dispatch('resource/custom/fetchDetail', id).then(data => {
        this.data = data;
        return data;
      });
    }
  }
};