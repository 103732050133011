var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.notice && _vm.visible ? _c("div", {
    staticClass: "my-notification right"
  }, [_c("div", {
    staticClass: "my-notification__group"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.getTitle) + " ")]), _c("div", {
    staticClass: "content",
    domProps: {
      innerHTML: _vm._s(_vm.getContent)
    }
  }), _c("div", {
    staticClass: "close-btn",
    on: {
      click: _vm.closeNotice
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]), _c("div", {
    staticClass: "foot-button"
  }, [_c("el-button", {
    staticClass: "button",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.setNotice
    }
  }, [_vm._v(" 设为已读 ")])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };