import "core-js/modules/es.array.push.js";
import { resourceAgreementItem } from '@/config/resource';
const defaultState = () => ({
  contentType: '',
  price: 0,
  remarks: ''
});
export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: resourceAgreementItem,
      form: {}
    };
  },
  methods: {
    show(id, type) {
      this.visible = true;
      this.form.resourceType = type;
      this.form.contracId = id;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    handleCancel() {
      this.hide();
    },
    addRow() {
      this.tableData.push({
        ...defaultState()
      });
    },
    fetchData() {
      const id = this.form.contracId;
      this.$store.dispatch('resource/common/fetchAgreementDetail', id).then(data => {
        this.tableData = data.contractPrices;
      });
    },
    addItem(opts) {
      if (!opts) return;
      this.$store.dispatch('resource/common/addAgreementItem', opts);
    },
    deleteItem(id) {
      this.$store.dispatch('resource/common/delAgreementItem', id).then(() => {
        this.fetchData();
        this.$parent.fetchAgreement();
        this.$bus.tip();
      });
    },
    handleDelete(item) {
      const {
        id
      } = item;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    handleConfirm() {
      if (!this.validateForm()) return;
      const loading = this.$bus.loading();
      const dataList = this.beforeCommit(this.tableData);
      const PAll = dataList.map(this.addItem);
      Promise.all(PAll).then(() => {
        this.fetchData();
        this.$parent.fetchAgreement();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return;
      const {
        contracId,
        resourceType
      } = this.form;
      return list.map(it => {
        const {
          id,
          contentType,
          price,
          remarks
        } = it;
        return {
          id,
          contentType,
          price,
          remarks,
          contracId,
          resourceType
        };
      });
    },
    validateForm() {
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i];
        const {
          contentType,
          price
        } = item;
        if (!contentType) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容'
          });
          return false;
        }
        if (price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写价格'
          });
          return false;
        }
        return true;
      }
    }
  }
};