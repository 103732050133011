var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.title + "导游代收",
      width: "90%",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "plan-order-dialog"
  }, [_c("TableBase", {
    attrs: {
      border: "",
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("SelectPlanNumber", {
          attrs: {
            "init-val": scope.row.customerOrderId
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectOrderNumber(scope.row, $event);
            }
          },
          model: {
            value: scope.row.customerOrderId,
            callback: function ($$v) {
              _vm.$set(scope.row, "customerOrderId", $$v);
            },
            expression: "scope.row.customerOrderId"
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " 大 " + _vm._s(scope.row.childCount) + " 小 " + _vm._s(scope.row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [scope.row.members && scope.row.members.length ? [_c("Members", {
          attrs: {
            row: scope.row,
            len: 1
          }
        })] : _vm._e()];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            code: "proxyType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.type,
            callback: function ($$v) {
              _vm.$set(scope.row, "type", $$v);
            },
            expression: "scope.row.type"
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "money",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.total,
            callback: function ($$v) {
              _vm.$set(scope.row, "total", $$v);
            },
            expression: "scope.row.total"
          }
        })];
      }
    }])
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };