import "core-js/modules/es.array.reduce.js";
import { Search, DialogAdd, DialogEdit, DialogDetail } from './components';
import { Columns, ColumnsShow } from './tableConfig';
import { caseStatus, caseStatusType } from './config';
import { parseDate } from '@/utils';
export default {
  components: {
    Search,
    DialogAdd,
    DialogEdit,
    DialogDetail
  },
  data() {
    return {
      Columns,
      ColumnsShow,
      activeName: 'announcement'
    };
  },
  computed: {
    data() {
      return this.$store.getters['system/case/dataComplaint'];
    },
    dataShow() {
      return this.$store.getters['system/case/dataComplaintShow'];
    },
    tableDataShow() {
      const data = this.dataShow;
      return data ? this.formatData(data.list) : [];
    },
    tableData() {
      const data = this.data;
      return data ? this.formatData(data.list) : [];
    },
    getStatusText() {
      return val => caseStatus[val];
    },
    getStatusType() {
      return val => caseStatusType[val];
    },
    getSearchParams() {
      return this.$refs.searchRef ? this.$refs.searchRef.searchParams : {};
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    },
    dataShow: {
      handler: function (d) {
        if (!d) {
          this.fetchDataShow();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      return this.$store.dispatch('system/case/listComplaint', {
        ...this.getSearchParams,
        ...params
      });
    },
    fetchDataShow(params) {
      return this.$store.dispatch('system/case/listComplaintShow', {
        ...this.getSearchParams,
        ...params
      });
    },
    initData() {
      this.fetchData();
      this.fetchDataShow();
    },
    reload(tabName = 'me') {
      this.initData();
      this.activeName = tabName;
    },
    onAdd() {
      this.$refs.dialogAddRef.show();
    },
    onDetail(row) {
      this.$refs.dialogDetailRef.show({
        ...row
      });
    },
    onEdit(row) {
      this.$refs.dialogEditRef.show({
        ...row
      });
    },
    onDel({
      id
    }) {
      this.$store.dispatch('system/case/del', {
        id
      }).then(() => {
        this.fetchData();
        this.$bus.tip();
      });
    },
    formatData(data) {
      const textKeys = ['details', 'consequence', 'reason', 'handlingMethod', 'improveThePlan'];
      return data.map(it => {
        const obj = textKeys.reduce((acc, cur) => {
          acc['$' + cur] = it[cur].substring(0, 20) + '....';
          return acc;
        }, {});
        return {
          ...it,
          ...obj,
          occurrenceTime: parseDate(it.occurrenceTime)
        };
      });
    }
  }
};