var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", {
    staticClass: "search-form"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangData("startTripDate", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.orderDate,
      label: "订单日期"
    },
    on: {
      "on-change": function ($event) {
        return _vm.onChangData("orderDate", $event);
      }
    }
  }), _c("InputBase", {
    attrs: {
      label: "大团号",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "planCustomerNumber", $$v);
      },
      expression: "searchParams.planCustomerNumber"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "客户区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selectCustomerArea
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调",
      clearable: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDepartment", {
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1)]), _c("div", {
    staticClass: "table-base-box"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.handlerSummary,
      sticky: true,
      "show-summary": ""
    }
  }), _vm.total > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    }
  })] : _vm._e()], 2), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };