import request from '@/utils/request';

// 恢复订单
export function recoveryOrder(data) {
  return request({
    url: '/order/resumeOrder',
    method: 'post',
    data
  });
}

// 回收站列表
export function fetchRecycle(data) {
  return request({
    url: '/order/pageListRecycle',
    method: 'post',
    data
  });
}