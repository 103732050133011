import { tableMixin } from '@/mixin';
import { PersonCount, Members } from '@/components/common';
import { CustomerInfo } from '@/components/common';
export default {
  components: {
    PersonCount,
    Members,
    CustomerInfo
  },
  mixins: [tableMixin]
};