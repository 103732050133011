import "core-js/modules/es.array.reduce.js";
import Dialog from './components/Dialog';
import { Reception } from '@/components/common/Order';
import { DialogLog } from '@/components/common';
export default {
  components: {
    Reception,
    Dialog,
    DialogLog
  },
  data() {
    return {
      auditAll: false,
      data: [],
      itemsIds: ''
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const {
        planId
      } = this.$route.query;
      const p1 = this.fetchTurnoutData(planId);
      const p2 = this.fetchOrderByPlanId(planId);
      Promise.all([p1, p2]).then(data => {
        const [val1, val2] = data;
        const list = val2.map(it => {
          it.total = it.fees.reduce((acc, cur) => (acc, acc += Number(cur.total)), 0);
          this.$set(it, 'fee', '');
          this.$set(it, 'proxy', '');
          this.$set(it, 'subsidy', '');
          this.$set(it, 'customerIdNew', '');
          return it;
        });
        if (val1.length && val2.length) {
          const customerOrderIdList = val1.map(it => it.customerOrderId);
          this.data = list.filter(it => !customerOrderIdList.includes(it.id));
          this.itemsIds = val1.map(it => it.customerOrderId).join(',');
        } else {
          this.data = list;
        }
      });
    },
    fetchOrderByPlanId(id) {
      return this.$store.dispatch('plan/index/getOrderByPlanId', id);
    },
    fetchTurnoutData(id) {
      return this.$store.dispatch('plan/reception/fetch', id);
    },
    handleAdd() {
      this.$refs.dialogRef.show(this.data);
    },
    handleLog() {
      const {
        planId
      } = this.$route.query;
      this.$store.dispatch('system/index/systemLog', {
        itemIds: planId,
        types: '5',
        subTypes: 'outFee,outCollect,outSubsidy'
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    saveAndAudit() {
      return this.$refs.itemRef.handleUpdate();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};