import { fetchPlan } from '@/api/plan';
const dfaultState = () => ({
  planData: null
});
const state = dfaultState();
const getters = {
  planData: state => state.planData
};
const actions = {
  // 获取计划列表
  fetchPlan({
    commit
  }, params) {
    return fetchPlan(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'planData',
        data
      });
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, payload) => {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};