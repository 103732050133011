import QrcodeVue from 'qrcode.vue';
import SealImage from '@/views/print/components/SealImage';
import { SEAL_TYPE, SELECTOR } from '@/config';
import { parseDate } from '@/utils';
const headInfo = [{
  label: '团号',
  value: 'teamNumber'
}, {
  label: '线路名称',
  value: 'routeName'
}];
const mainInfo = [{
  label: '地接社',
  value: 'travelAgencyName'
}, {
  label: '客源地',
  value: 'clientSourcePlace'
}, {
  label: '人数(大人)',
  value: 'adultNumber',
  slot: 'count'
}, {
  label: '组团社',
  value: 'groupAgencyName'
}, {
  label: '游客代表',
  value: 'youKeDaiBiao'
}, {
  label: '游客代表电话',
  value: 'yunShuGongSiDianHua'
}, {
  label: '全陪',
  value: 'totalServerName'
}, {
  label: '全陪电话',
  value: 'totalServerPhone'
}, {
  label: '保险',
  value: 'insuranceCompanyName'
}, {
  label: '接团日期',
  value: 'beginDate',
  slot: 'date'
}, {
  label: '到达班次/车次',
  value: 'jiaoTongGongJuDao'
}, {
  label: '接团地点',
  value: 'receptionGroupAddr'
}, {
  label: '送团日期',
  value: 'overDate',
  slot: 'date'
}, {
  label: '离开班次/车次',
  value: 'departureTrainNumber'
}, {
  label: '送团地点',
  value: 'sendGroupAddr'
}, {
  label: '运输公司',
  value: 'transportCompanyInfoName'
}, {
  label: '运输公司电话',
  value: 'transportCompanyPhone'
}, {
  label: '车号',
  value: 'vehicleInfoNumber'
}, {
  label: '车型',
  value: 'seatingCapacity'
}, {
  label: '司机',
  value: 'driverName'
}, {
  label: '司机电话',
  value: 'driverPhone'
}];
const guideInfo = [{
  label: '导游',
  value: 'guideName',
  width: '120px'
}, {
  label: '导游电话',
  value: 'guideLicencePhone',
  width: '140px'
}, {
  label: '导游证号',
  value: 'guideLicenceCode',
  width: '150px'
}, {
  label: '所属旅行社',
  value: 'groupAgencyName'
}];
const tripColumns = [{
  content: 'totalDay',
  label: '行程天数',
  width: '55px'
}, {
  content: '$relevancePlaceType',
  label: '类型',
  width: '90px'
}, {
  content: 'relevancePlaceName',
  label: '地点',
  width: '109px'
}, {
  content: 'arrivalTime',
  label: '到达时间',
  width: '80px'
}, {
  content: 'arrivalDistrict',
  label: '到达县区',
  width: '80px'
}, {
  content: 'stopoverTime',
  label: '停留时间(分钟)',
  width: '60px'
}, {
  content: 'routeRemark',
  label: '行程说明'
}];
export default {
  components: {
    QrcodeVue,
    SealImage
  },
  data() {
    return {
      SEAL_TYPE,
      headInfo,
      guideInfo,
      mainInfo,
      tripColumns,
      data: null,
      guideData: null,
      tripData: [],
      colLen: 3
    };
  },
  computed: {
    getLength() {
      return Math.ceil(this.mainInfo.length / this.colLen);
    },
    getColumn() {
      return index => this.mainInfo.slice(index * this.colLen, index * this.colLen + this.colLen);
    }
  },
  mounted() {
    const {
      signId
    } = this.$route.query;
    const params = signId ? JSON.parse(signId) : {};
    this.fetchSuperviseList(params).then(data => {
      const {
        itineraryReceptionId: id
      } = data;
      this.fetchSuperviseDetail(id);
    });
  },
  methods: {
    parseDate,
    // 查询列表
    fetchSuperviseList(params) {
      const loading = this.$bus.loading();
      const {
        contractNo
      } = this.$route.query;
      const {
        guideName
      } = params;
      return this.$store.dispatch('plan/superviseTravel/getCommitInfo', {
        contractNo,
        ...params,
        'guideQueryDto.guideName': guideName
      }).then(data => {
        const {
          records
        } = data;
        const item = records[0];
        this.loadSuccess = true;
        loading.close();
        return item;
      }).catch(() => {
        this.loadSuccess = false;
        loading.close();
      });
    },
    // 查询明细
    fetchSuperviseDetail(id) {
      const loading = this.$bus.loading();
      return this.$store.dispatch('plan/superviseTravel/getById', {
        id
      }).then(data => {
        if (!data) return;
        this.data = data;
        this.tripData = this.formatItinerarys(data.route.itineraryOptList);
        this.handlePrint();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    formatItinerarys(list) {
      return list.map(it => {
        it.siteList.map(v => {
          v.$relevancePlaceType = SELECTOR.EScenicType.find(a => a.value == v.relevancePlaceType).label;
          return v;
        });
        return it;
      });
    },
    handlePrint() {
      const style = document.createElement('style');
      style.innerHTML = '@page { size: a4 auto; margin-top: 1.5cm; margin-bottom: 0.5cm; } @page:first { margin-top: 0.5cm } @page:left { margin-left: 0.75cm; margin-right: 0.75cm } @page:right { margin-left: 0.75cm; margin-right: 0.75cm} ';
      style.media = 'print';
      document.head.appendChild(style);

      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();

          // window.close()
        });
      }, 1000);
    }
  }
};