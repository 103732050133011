/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
// 部门
import { fetchDictionaryType, fetchDictionaryList, fetchDictionaryTypeList, deleteDictionary, fetchTypeFormCode, createOrUpdateDictionary, createOrUpdateDictionaryType } from '@/api/system/dictionary';
import { getStorage, setStorage } from '@/utils/storage';
import { formatSelectList } from '@/utils/data';
const DICTIONARY_KEY = 'dictionary';
const dfaultState = () => ({
  dictionaryList: [],
  dictionaryTypeList: [],
  dictionaryCodeData: null
});
const state = dfaultState();
const getters = {
  dictionaryList: state => state.dictionaryList,
  dictionaryTypeList: state => state.dictionaryTypeList,
  dictionaryCodeData: state => {
    return state.dictionaryCodeData || getStorage(DICTIONARY_KEY);
  }
};
const actions = {
  fetchList({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      return fetchDictionaryList(id).then(res => {
        let {
          data
        } = res;
        commit('FETCH_LIST', data);
        resolve(data);
      }).catch(reject);
    });
  },
  createOrUpdate({
    dispatch
  }, opts) {
    return new Promise((resolve, reject) => {
      return createOrUpdateDictionary(opts).then(res => {
        const {
          data
        } = res;
        dispatch('fetchDictionaryType');
        resolve(data);
      }).catch(reject);
    });
  },
  delete({
    dispatch
  }, id) {
    return new Promise((resolve, reject) => {
      return deleteDictionary(id).then(() => {
        dispatch('fetchDictionaryType');
        resolve();
      }).catch(reject);
    });
  },
  createOrUpdateType({
    commit
  }, opts) {
    return createOrUpdateDictionaryType(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 类型列表
  fetchTypeList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      return fetchDictionaryTypeList().then(res => {
        const {
          data
        } = res;
        commit('FETCH_TYPE_LIST', data);
        resolve(data);
      }).catch(reject);
    });
  },
  // 获取字典列表里面包含数据
  fetchDictionaryType({
    commit
  }) {
    return fetchDictionaryType().then(res => {
      const {
        data
      } = res;
      commit('TYPE_CODE_LIST', data);
      return data;
    });
  },
  fetchTypeFormCode({
    commit
  }, code) {
    return fetchTypeFormCode(code).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  findDictionaryLabel({
    rootState
  }, {
    type,
    id
  }) {
    const {
      dictionaryCodeData
    } = rootState['system/dictionary'];
    const curList = dictionaryCodeData[type].agencyDictionaryList;
    return curList.find(it => it.id === id);
  }
};
const mutations = {
  FETCH_LIST: (state, data) => {
    state.dictionaryList = data;
  },
  FETCH_TYPE_LIST: (state, data) => {
    state.dictionaryTypeList = data;
  },
  DELETE_DICTIONARY: (state, id) => {
    const list = state.dictionaryTypeList.filter(it => it.id !== id);
    state.dictionaryTypeList = list;
  },
  // 存储字典所有类型
  TYPE_CODE_LIST: (state, list) => {
    state.dictionaryCodeData = {};
    list.map(it => {
      state.dictionaryCodeData[it.code] = {
        ...it,
        agencyDictionaryList: formatSelectList(it.agencyDictionaryList)
      };
    });
    setStorage(DICTIONARY_KEY, state.dictionaryCodeData);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};