import { SelectProduct, Guides } from '@/components/common';
import handleClipboard from '@/utils/clipboard';
import { parseDate } from '@/utils';
import { orderPersonalMerge } from '@/config/order';
import { tableMixin } from '@/mixin';
const dateArr = val => parseDate(val);
const defaultSearch = {
  planNumber: '',
  productName: '',
  startTripDateE: '',
  startTripDateS: dateArr(new Date())
};
export default {
  components: {
    SelectProduct,
    Guides
  },
  mixin: [tableMixin],
  props: {
    showArranges: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableData: [],
      columnData: orderPersonalMerge,
      tableHeight: 300,
      form: {},
      orderRadio: 0,
      total: 0,
      searchParams: defaultSearch
    };
  },
  mounted() {
    this.fetchData();
  },
  destroy() {
    this.orderRadio = 0;
  },
  methods: {
    fetchData(queryParam = {
      ...this.searchParams
    }) {
      this.$store.dispatch('order/index/fetchSPlanSimpleList', queryParam).then(data => {
        const {
          list,
          totalCount
        } = data;
        this.total = totalCount;
        this.tableData = this.formatData(list);
      });
    },
    formatData(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        this.$set(it, 'checked', false);
        return it;
      });
    },
    handleCopy(text, event) {
      handleClipboard(text, event);
    },
    showProductDialog() {
      this.$bus.emit('show:product');
    },
    // 确认弹窗
    showDialog(scope) {
      const {
        id
      } = scope.row;
      this.orderRadio = id;
      this.$set(scope.row, 'checked', true);
      this.$emit('on-select', id);
    },
    clearSelectRadio() {
      this.orderRadio = 0;
    },
    handleProduct(val) {
      if (!val.label || !val.value) {
        this.searchParams.productName = '';
      } else {
        const {
          name
        } = val;
        this.searchParams.productName = name;
      }
      this.handleSearch();
    },
    handleSearch() {
      this.fetchData();
    },
    changeStartDate(val) {
      this.searchParams.startTripDateS = val;
      this.handleSearch();
    },
    changeEndDate(val) {
      this.searchParams.startTripDateE = val;
      this.handleSearch();
    },
    handlePage(val) {
      this.listQuery.pageNum = val;
      this.handleSearch();
    }
  }
};