/* eslint-disable no-empty-pattern */

import { fetchPrePayBalance, fetchRegimentCollect, addCollect, deleteDown, fetchBillDetail } from '@/api/bill/billDown';
import { completeRequest } from '@/api/bill/billDown/collectUnit';
import { fetchOtherPay } from '@/api/bill/billCheck';
import { CashFeeType } from '@/config/billDown';
import { CasherStatusLabel } from '@/config/billDown/cashStatus';
import { formatCreateTime } from '@/utils/data';
const dfaultState = () => ({
  prePaymentMoney: 0,
  otherPay: null,
  curItem: null
});
const state = dfaultState();
const getters = {
  otherPay: state => state.otherPay,
  curItem: state => state.curItem,
  prePaymentMoney: state => state.prePaymentMoney
};
const actions = {
  // 其他支出
  fetchList({
    commit
  }, params) {
    return fetchOtherPay(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherPay',
        data
      });
      return data;
    });
  },
  // 获取应收余额
  fetchBalance({
    commit
  }, params) {
    return fetchPrePayBalance({
      ...params,
      action: CashFeeType.INCOME
    }).then(res => {
      if (!res) return;
      commit('PRE_PAYMENT_MONEY', res.data);
    });
  },
  fetchCollected({}, params) {
    return fetchRegimentCollect(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        // 出纳确认状态
        const {
          cashierAuditStatus
        } = it;
        const cashierAuditStatusLabel = CasherStatusLabel[cashierAuditStatus.toUpperCase()];
        return {
          ...formatCreateTime(it, 'kpDate'),
          cashierAuditStatusLabel
        };
      });
      return data;
    });
  },
  fetchComplete({}, params) {
    return completeRequest(params);
  },
  // 收款
  addPay({}, opts) {
    const cashAction = CashFeeType.PAY;
    return addCollect({
      ...opts,
      cashAction
    });
  },
  // 删除付款
  deleteCollect({}, opts) {
    return deleteDown(opts);
  },
  // 按id 查询日志流
  fetchDetail({}, id) {
    return fetchBillDetail(id).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  }
};
const mutations = {
  PRE_PAYMENT_MONEY(state, money) {
    state.prePaymentMoney = money;
  },
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  },
  CUR_ITEM(state, data) {
    state.curItem = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};