var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-base input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-input", _vm._g(_vm._b({
    attrs: {
      placeholder: "请输入" + _vm.tip ? _vm.tip : _vm.label,
      clearable: _vm.clearable,
      onkeyup: "value=value.replace(/^\\s+|\\s+$/g,'')"
    },
    model: {
      value: _vm.getValue,
      callback: function ($$v) {
        _vm.getValue = $$v;
      },
      expression: "getValue"
    }
  }, "el-input", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };