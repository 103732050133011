var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "system form-fix-bar"
  }, [_c("div", {
    staticClass: "config-wrapper"
  }, [_c("EnterpriseLink"), _vm._l(_vm.formData, function (config) {
    return _c("div", {
      key: config.id,
      staticClass: "content"
    }, [_c("div", {
      staticClass: "input-field"
    }, [_c("h2", [_vm._v(_vm._s(config.name))])]), _vm._l(config.configs, function (item) {
      return _c("div", {
        key: item.id,
        staticClass: "item"
      }, [!item.values.length ? _c("el-checkbox", {
        model: {
          value: item.isCheck,
          callback: function ($$v) {
            _vm.$set(item, "isCheck", $$v);
          },
          expression: "item.isCheck"
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), item.values.length > 1 ? [_c("div", {
        staticClass: "name"
      }, [_c("div", {
        staticStyle: {
          "white-space": "nowrap"
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]), config.code == "planNumberAutoCreate" ? _c("InputData", {
        attrs: {
          label: "前缀："
        },
        model: {
          value: item.planNumberPrefix,
          callback: function ($$v) {
            _vm.$set(item, "planNumberPrefix", $$v);
          },
          expression: "item.planNumberPrefix"
        }
      }) : _vm._e()], 1), _c("div", [item.isSingle ? _vm._l(item.values, function (radio) {
        return _c("el-radio-group", {
          key: radio.id,
          staticClass: "item-group",
          attrs: {
            disabled: item.disabled
          },
          on: {
            change: function ($event) {
              return _vm.changeRadio(item, $event);
            }
          },
          model: {
            value: item.radioValue,
            callback: function ($$v) {
              _vm.$set(item, "radioValue", $$v);
            },
            expression: "item.radioValue"
          }
        }, [!radio.disabled ? _c("el-radio", {
          attrs: {
            label: radio.code,
            disabled: radio.disabled
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getConfName(radio)))]), _vm.ISNAME.includes(radio.code) ? _c("div", {
          staticClass: "is-name-auto"
        }, [_c("div", [_vm._v("前缀+")]), _c("el-select", {
          staticClass: "pl5",
          attrs: {
            multiple: "",
            placeholder: "请选择"
          },
          on: {
            change: function ($event) {
              return _vm.onSelectAuto(radio, $event);
            }
          },
          model: {
            value: radio.strValue1,
            callback: function ($$v) {
              _vm.$set(radio, "strValue1", $$v);
            },
            expression: "radio.strValue1"
          }
        }, _vm._l(radio.planNums1, function (it) {
          return _c("el-option", {
            key: it.value,
            attrs: {
              label: it.label,
              value: it.value
            }
          });
        }), 1), _c("div", {
          staticClass: "pl5"
        }, [_vm._v("+序号")])], 1) : _vm._e()]) : _vm._e()], 1);
      }) : _vm._l(item.values, function (check) {
        return _c("el-checkbox", {
          key: check.id,
          staticClass: "item-group",
          attrs: {
            disabled: check.disabled
          },
          model: {
            value: check.isCheck,
            callback: function ($$v) {
              _vm.$set(check, "isCheck", $$v);
            },
            expression: "check.isCheck"
          }
        }, [_vm._v(" " + _vm._s(check.name) + " ")]);
      })], 2)] : _vm._e(), item.values.length == 1 ? [_vm._l(item.values, function (check) {
        return [!check.disabled ? _c("el-checkbox", {
          key: check.id,
          staticClass: "item-group",
          attrs: {
            disabled: check.disabled
          },
          model: {
            value: check.isCheck,
            callback: function ($$v) {
              _vm.$set(check, "isCheck", $$v);
            },
            expression: "check.isCheck"
          }
        }, [_vm._v(" " + _vm._s(check.name) + " ")]) : _vm._e()];
      })] : _vm._e()], 2);
    }), config.name == "订单管理" ? [_c("TableTeamFee"), _c("ShuttlePrice")] : _vm._e()], 2);
  }), _vm.authProxy ? _c("div", {
    staticClass: "content"
  }, [_c("h2", [_vm._v("代收审核")]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "下账模式"
    }
  }), _c("el-radio-group", {
    model: {
      value: _vm.authProxy.collectAuthModel,
      callback: function ($$v) {
        _vm.$set(_vm.authProxy, "collectAuthModel", $$v);
      },
      expression: "authProxy.collectAuthModel"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "COMMON"
    }
  }, [_vm._v(" 常规 ")]), _c("el-radio", {
    attrs: {
      label: "OTHER"
    }
  }, [_vm._v(" 其他 ")])], 1)], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "会计科目"
    }
  }), _c("SelectAccount", {
    attrs: {
      "init-val": _vm.subjectObj,
      label: ""
    },
    on: {
      "update:initVal": function ($event) {
        _vm.subjectObj = $event;
      },
      "update:init-val": function ($event) {
        _vm.subjectObj = $event;
      },
      "on-select": _vm.selectAccount
    },
    model: {
      value: _vm.subjectId,
      callback: function ($$v) {
        _vm.subjectId = $$v;
      },
      expression: "subjectId"
    }
  })], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "收款方式"
    }
  }), _c("SelectDictionary", {
    attrs: {
      code: "authCollectType",
      label: ""
    },
    on: {
      "on-select": _vm.selectCollectMode
    },
    model: {
      value: _vm.collectMode,
      callback: function ($$v) {
        _vm.collectMode = $$v;
      },
      expression: "collectMode"
    }
  })], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "账户"
    }
  }), _c("SelectBankCard", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.authProxy.collectAuthBankId,
      callback: function ($$v) {
        _vm.$set(_vm.authProxy, "collectAuthBankId", $$v);
      },
      expression: "authProxy.collectAuthBankId"
    }
  })], 1)])]) : _vm._e()], 2), _c("FooterBar", [_c("div", {
    staticClass: "menu-list-item"
  }, [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v(" 保存 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 关闭 ")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };