import TableList from './TableList.vue';
import DetailDialog from './DetailDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import DialogPay from './PayDialog.vue';
import { SelectDictionary, InputHotel, DialogLog } from '@/components/common';
import InputResource from '@/components/common/Input/InputResource.vue';
import { resourceOrderHotel } from '@/config/resourceOrder';
import { parseDate } from '@/utils';
import { formatData, handleTableMerge } from '../process';
import { reloadMixin, searchMixin } from '@/mixin';
import { DICTIONARY } from '@/config/dictionary';
// const searchState = {}

export default {
  components: {
    InputResource,
    SelectDictionary,
    InputHotel,
    TableList,
    DetailDialog,
    ConfirmDialog,
    DialogLog,
    DialogPay
  },
  mixins: [reloadMixin, searchMixin],
  data() {
    return {
      RoomType: DICTIONARY.roomType,
      searchParams: {
        inDateS: parseDate(new Date())
      },
      columnData: resourceOrderHotel,
      tableData: [],
      orderDate: [],
      inDate: [parseDate(new Date()), ''],
      outDate: [],
      total: 0,
      companyName: ''
    };
  },
  computed: {
    resourceOrder() {
      return this.$store.getters['order/resource/resourceHotelOrder'];
    }
  },
  watch: {
    resourceOrder: {
      handler: function (data) {
        if (!data) {
          this.initData();
        } else {
          const newData = formatData(data.list, 'rooms');
          this.tableData = handleTableMerge(newData);
          this.total = data.totalCount;
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/resource/fetchBookingHotelList', params);
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    showDetail(row) {
      this.$refs.dialogRef.show(row);
    },
    editDetail(row) {
      const isEdit = true;
      this.$refs.dialogRef.show(row, isEdit);
    },
    confirmDetail(row) {
      const isEdit = true;
      this.$refs.dialogConfirmRef.show(row, isEdit);
    },
    showLog(data) {
      this.$refs.dialogLogRef.show(data);
    },
    showPayDialog(data) {
      this.$refs.dialogPayRef.show(data);
    },
    handleDate(type, val) {
      const [startDate, endDate] = val;
      this.searchParams[type + 'S'] = startDate;
      this.searchParams[type + 'E'] = endDate;
    },
    selectResource(val) {
      this.searchParams.companyId = val.id;
    },
    selectHotel(val) {
      this.searchParams.hotelId = val.id;
    },
    selectRoomType(val) {
      this.searchParams.roomType = val.label;
    },
    handleSearch() {
      this.fetchData({
        ...this.searchParams,
        pageNum: 1
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    }
  }
};