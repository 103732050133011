var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("InputCascader", _vm._g(_vm._b({
    attrs: {
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      label: _vm.label,
      list: _vm.departments
    },
    on: {
      change: _vm.handleChange,
      clear: _vm.handleClear
    },
    model: {
      value: _vm.getCurVal,
      callback: function ($$v) {
        _vm.getCurVal = $$v;
      },
      expression: "getCurVal"
    }
  }, "InputCascader", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };