import "core-js/modules/es.array.push.js";
import TableUnPay from './TableUnPay';
import { FooterBar, SelectCustom, BillInput } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { setCheckStatus } from '@/utils/data';
import { getPayedMoney } from '@/views/billDown/help';
import { downBillUnitPayed, TYPES, CashFeeType } from '@/config/billDown';
import { formatDownData } from '@/views/billDown/help';
export default {
  components: {
    FooterBar,
    BillInput,
    SelectCustom,
    TableUnPay
  },
  mixins: [tagViewMixin],
  data() {
    return {
      exportLoading: false,
      billCheckAll: false,
      tableData: [],
      columnData: downBillUnitPayed,
      resourceId: '',
      total: 0,
      money: 0,
      backPath: '/bill/unit-collect/customer'
    };
  },
  computed: {
    getResourceId() {
      return this.resourceId;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData(queryParams = {}) {
      const {
        rid: resourceId,
        id: logCashId
      } = this.$route.query;
      this.resourceId = resourceId;
      this.fetchData({
        resourceId,
        logCashId,
        ...queryParams
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('bill/billDown/fetchPayedTeamFund', queryParams).then(data => {
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    changeMoney(money) {
      money = money - getPayedMoney(this.tableData);
      this.$refs.unPayRef.caclDownMoney(money);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.initData({
        pageNum,
        pageSize
      });
    },
    handleClose() {
      this.closeSelectedTag();
    },
    getIds(list) {
      return list.filter(it => it.isChecked).map(it => it.id);
    },
    changeCheckAll(status) {
      this.setTableCheckStatus({
        status
      });
    },
    cancelSelect() {
      const ids = this.getIds(this.tableData);
      if (!ids || !ids.length) {
        this.$bus.tip({
          message: '请勾选要取消的数据',
          type: 'warning'
        });
        return;
      }
      this.$bus.open(() => {
        const opts = {
          cashType: TYPES.customer,
          ids
        };
        this.$store.dispatch('bill/billDown/pay/deletePay', opts).then(() => {
          this.initData();
          this.billCheckAll = false;
        }, '您确定要取消吗? 是否继续?');
      });
    },
    setTableCheckStatus({
      status = false
    }) {
      this.tableData = setCheckStatus(this.tableData, status);
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const list = this.$refs.unPayRef.tableData;
        const opts = formatDownData({
          list,
          form,
          cashAction: CashFeeType.INCOME,
          customerFeeType: 'FEE'
        });
        this.$store.dispatch('bill/billDown/collect/addCollect', {
          ...opts,
          cashType: TYPES.customer
        }).then(() => {
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        });
      });
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};