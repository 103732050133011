import "core-js/modules/es.array.push.js";
// import { mapGetters } from 'vuex'
import { mapGetters } from 'vuex';
import { MenuBtnList } from './config';
export default {
  mixins: [],
  data() {
    return {
      menus: MenuBtnList,
      roleMenuOpts: [],
      visible: true
    };
  },
  computed: {
    ...mapGetters({
      rolePermissionList: 'permission/rolePermissionList'
    })
  },
  watch: {
    rolePermissionList: {
      handler: function (data) {
        const loading = this.$bus.loading();
        this.visible = false;
        if (!data) {
          this.fetchData();
          return;
        }
        this.formatData(data);
        loading.close();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('permission/fetchUserAllowMenu');
    },
    formatData(list) {
      if (!list.length) return [];
      const {
        path
      } = this.$route;
      const menu = list.find(it => it.code == '/analysis');
      const auth = menu.subMenus.find(it => it.code == path);
      auth.roleMenuOpts.forEach(it => {
        const {
          isRole
        } = it;
        if (isRole) {
          this.menus.map(v => {
            v.children.map(k => {
              if (k.code == it.code) {
                v.isRole = isRole;
                k.isRole = isRole;
              }
              return k;
            });
            return v;
          });
        }
      });
      this.visible = true;
    },
    onClickRouter({
      isRole,
      url
    }) {
      if (!isRole) return;
      if (url == '/analysis/member-map') {
        let routeUrl = this.$router.resolve({
          path: url
        });
        window.open(routeUrl.href, '_blank');
      } else {
        this.$router.push({
          path: url
        });
      }
    }
  }
};