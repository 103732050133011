import FormScenic from './FormScenic';
import TableList from './TableList';
import DialogCustom from './DialogCustom';
import DialogAgreementItem2 from './DialogAgreementItem2';
import { BtnCreate, BtnAgreement, DialogDetail, DialogAgreement, DialogLog, DialogBindBusiness, SelectDictionary } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceScenic, TYPES } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  name: 'Resource',
  components: {
    BtnCreate,
    BtnAgreement,
    DialogDetail,
    DialogAgreement,
    DialogCustom,
    DialogLog,
    DialogBindBusiness,
    SelectDictionary,
    DialogAgreementItem2,
    FormScenic,
    TableList
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceScenic,
    tableData: [],
    total: 0,
    searchParams: {
      isEnable: -1,
      // -1 全部， 0 未启用， 1 启用
      name: ''
    },
    resourceId: '',
    resType: TYPES.scenic
  }),
  computed: {
    scenicData() {
      return this.$store.getters['resource/scenic/scenicDataOrigin'];
    }
  },
  watch: {
    scenicData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    onSelectArea(val) {
      this.searchParams.area = val.label;
    },
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/scenic/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/scenic/fetchList');
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    createOrUpdate(opts) {
      this.$store.dispatch('resource/scenic/createOrUpdate', opts).then(() => {
        this.initData();
        this.$bus.tip();
      });
    },
    handleData(data) {
      let {
        list,
        totalCount
      } = data;
      list = list.map(it => {
        it.isFree = it.scenicType == 'FREE' ? true : false;
        return it;
      });
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteScenic(id) {
      this.$store.dispatch('resource/scenic/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    // 添加客户结算价
    addCustom(scope) {
      this.$refs.customRef.show(scope);
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteScenic(id);
      });
    },
    editTableRow(scope) {
      const copy = {
        ...scope
      };
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      this.$store.dispatch('resource/scenic/fetchImage', scope.row.id).then(res => {
        this.images = res;
      });
      this.$refs.detailRef.show(scope.row, {
        title: '景区',
        name: '景区名称'
      });
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/scenic/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    fetchAgreement() {
      this.$store.dispatch('resource/scenic/fetchAgreement', this.resourceId);
    },
    showAgreement(scope) {
      const resourceId = scope.row.id;
      this.resourceId = resourceId;
      this.fetchAgreement();
      this.$refs.agreementRef.show(scope.row, TYPES.scenic);
    },
    showAgreementItem(contracId) {
      this.$refs.agreementItemRef.show(contracId, TYPES.scenic);
    }
  }
};