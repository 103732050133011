/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchCustomList, fetchCustom, createOrUpdateCustom, deleteCustom, fetchCustomDetail, fetchCustomContact, fetchCustomContactInfo, createOrUpdateCustomContact, deleteCustomContact, setTmpCreditRequest } from '@/api/resource/custom';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  customLog: null,
  customData: null,
  customOutData: null,
  customDataOrigin: null,
  customDetail: null,
  credit: null
  // credit: {
  // 	[orderType.team]: null,
  // 	[orderType.personal]: null,
  // },
});
const state = dfaultState();
const getters = {
  customLog: state => state.customLog,
  customData: state => state.customData,
  customOutData: state => state.customOutData,
  customDataOrigin: state => state.customDataOrigin,
  customDetail: state => state.customDetail,
  credit: state => state.credit
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchCustom(queryParam).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customDataOrigin',
        data
      });
      return data;
    });
  },
  fetch2({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchCustom(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('SET_DATA', {
        type: 'customData',
        data
      });
      return data;
    });
  },
  // 不分页列表 地接社
  fetchOutList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('SET_DATA', {
        type: 'customOutData',
        data
      });
      return data;
    });
  },
  // 客户联系人不分页列表
  fetchCustomList({
    commit
  }, queryParam) {
    return fetchCustomList(queryParam).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.customer
    }).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customLog',
        data
      });
      return data;
    });
  },
  fetchDetail({
    commit
  }, id) {
    return fetchCustomDetail(id).then(res => {
      if (!res || !res.data) return;
      let {
        data
      } = res;
      commit('CUSTOM_DATA', data);
      return data;
    });
  },
  fetchContact({
    commit
  }, opts) {
    return fetchCustomContact(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchContactInfo({
    commit
  }, id) {
    return fetchCustomContactInfo(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateCustom(opts);
  },
  createOrUpdateContact({
    commit
  }, opts) {
    return createOrUpdateCustomContact(opts);
  },
  delete({
    commit
  }, id) {
    return deleteCustom(id);
  },
  deleteCustomContact({
    commit
  }, id) {
    return deleteCustomContact(id);
  },
  setTmpCredit({
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      setTmpCreditRequest(opt).then(res => {
        if (!res) reject(res);
        resolve(res.data);
      }).catch(reject);
    });
  }
};
const mutations = {
  SET_DATA: (state, {
    type,
    data
  }) => {
    state[type] = data;
  },
  CUSTOM_DATA: (state, data) => {
    const {
      name,
      balance,
      creditMoney,
      individuaSubsidyPrice,
      teamSubsidyPrice,
      tmpCreditMoney
    } = data;
    const str = tmpCreditMoney > 0 ? '(包含临时额度)' : '';
    const opt = {
      ...data,
      title: `客户：${name}`,
      content: `授信额度：${creditMoney}元，剩余额度：${balance}元 ${str}`,
      balance,
      creditMoney,
      individuaSubsidyPrice,
      teamSubsidyPrice
    };
    state.credit = opt;
  },
  CLEAN_CUSTOM_DATA: state => {
    state.credit = null;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.customData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.customData.list.filter(it => it.id !== id);
    state.customData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};