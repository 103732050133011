import { rules, formState } from '../config';
export default {
  data() {
    return {
      rules,
      visible: false,
      form: {}
    };
  },
  computed: {
    getTitle() {
      return '案例详情';
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.form = data;
    },
    hide() {
      this.form = formState();
      this.visible = false;
    }
  }
};