var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-manage form-fix-bar"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on-search": _vm.handleSearch,
      "on-export": _vm.handleExport
    }
  }), _c("TableList", {
    ref: "tableRef",
    on: {
      page: _vm.handlePage
    }
  }), _vm.total > 0 ? [_c("BtnBar", {
    on: {
      "on-close": _vm.closeTag,
      "on-save": _vm.handleBillCheck
    }
  })] : _vm._e(), _c("DialogLog", {
    ref: "dialogRef"
  }), _c("DialogAuditOrder", {
    ref: "auditOrderRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };