var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-manage form-fix-bar"
  }, [_c("Search", {
    ref: "searchRef",
    attrs: {
      search: _vm.search
    },
    on: {
      "on-reload": _vm.fetchData
    }
  }), _c("TableList", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.total > 0 ? [_c("BtnBar", {
    on: {
      "on-close": _vm.closeTag,
      "on-save": _vm.handleBillCheck
    }
  }), _vm.statistics ? _c("TotalBar", {
    attrs: {
      data: _vm.statistics,
      "column-data": _vm.columnData
    }
  }) : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })] : _vm._e(), _c("DialogLog", {
    ref: "dialogRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };