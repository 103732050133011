/* eslint-disable no-unused-vars */
import { fetchHotelAll, fetchHotel, updateHotel, deleteHotel, deleteHotelItem, fetchHotelOrder, updateHotelOrder, deleteHotelOrder, fetchHotelOrderList, selectHotelOrderList, deleteHotelOrderItem, changeArrange, setHotelArrange } from '@/api/plan/hotel';
import { fetchHotelDetail } from '@/api/plan';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  hotelData: null,
  hotelOrderData: null
});
const state = dfaultState();
const getters = {
  hotelData: state => state.hotelData,
  hotelOrderData: state => state.hotelOrderData
};
const actions = {
  // 获取 全部安排
  fetchAll({
    commit
  }, params) {
    return fetchHotelAll(params).then(res => {
      const {
        data
      } = res;
      commit('HOTEL_DATA', data);
      return data;
    });
  },
  // 获取 安排
  fetch({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchHotel(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 更新 安排
  update({
    commit
  }, opts) {
    return updateHotel(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除 安排
  delete({
    dispatch
  }, ids) {
    return deleteHotel(ids).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteHotelItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchHotelOrder(params).then(res => {
      const {
        data
      } = res;
      commit('HOTEL_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, optsList) {
    return updateHotelOrder(optsList).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, RemoveOrderById) {
    return deleteHotelOrder(RemoveOrderById).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteHotelOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchHotelOrderList({
    commit
  }, opts) {
    return fetchHotelOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectHotelOrderList({
    commit
  }, opts) {
    return selectHotelOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  //根据计划id 获取酒店列表
  fetchHotelDetail({
    commit
  }, opts) {
    return fetchHotelDetail(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订房状态
  changeArrange({
    commit
  }, status) {
    return changeArrange(status);
  },
  // 设置酒店安排状态
  setArrange({
    commit
  }, opt) {
    return setHotelArrange(opt);
  }
};
const mutations = {
  HOTEL_DATA: (state, data) => {
    state.hotelData = data;
  },
  HOTEL_ORDER_DATA: (state, data) => {
    state.hotelOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};