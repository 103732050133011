var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orderNumber) + " ")];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [_c("Custom", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orderDate) + " ")];
      }
    }, {
      key: "productName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.scenicName) + " ")];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "shuttle",
      fn: function ({
        scope
      }) {
        return [_c("Shuttle", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "ticketType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.ticketType) + " ")];
      }
    }, {
      key: "sellPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.sellPrice) + " ")];
      }
    }, {
      key: "quotePrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }, {
      key: "settlementPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.settlementPrice) + " ")];
      }
    }, {
      key: "adultCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " ")];
      }
    }, {
      key: "adultFreeCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultFreeCount) + " ")];
      }
    }, {
      key: "childPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.childPrice) + " ")];
      }
    }, {
      key: "childCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.childCount) + " ")];
      }
    }, {
      key: "accompanyPrice",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.accompanyPrice) + " ")];
      }
    }, {
      key: "accompanyCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.accompanyCount) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.otherFee) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalPay) + " ")];
      }
    }, {
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };