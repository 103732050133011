var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.typeList.length ? [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.edit ? true : false,
      indeterminate: _vm.isIndeterminate
    },
    on: {
      change: _vm.handleCheckAllChange
    },
    model: {
      value: _vm.checkAll,
      callback: function ($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v(" 全选 ")]), _c("el-checkbox-group", {
    class: {
      vertical: _vm.vertical
    },
    attrs: {
      disabled: !_vm.edit ? true : false
    },
    on: {
      change: _vm.handleCheckedCitiesChange
    },
    model: {
      value: _vm.checkList,
      callback: function ($$v) {
        _vm.checkList = $$v;
      },
      expression: "checkList"
    }
  }, [_vm._l(_vm.typeList, function (item) {
    return [_c("el-checkbox", {
      key: item.label,
      attrs: {
        label: item.label,
        checked: item.checked
      }
    })];
  })], 2)] : [_c("Empty")]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };