var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tag", {
    attrs: {
      effect: "plain",
      type: _vm.getType
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatus) + " ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };