var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订单详情",
      width: "1200px",
      "custom-class": "resource-order-dialog",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": _vm.isEdit
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("FormBase", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.companyName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "接单日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.parseDate(_vm.info.orderDate)))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.planNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "跟单人"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.personLiableName))])], 1)])]), _c("el-divider"), !_vm.getEditStatus ? _c("OrderInfo", {
    attrs: {
      data: _vm.orderInfoData
    }
  }) : _c("OrderApply", {
    ref: "tableRef",
    attrs: {
      data: _vm.orderInfoData
    }
  }), _c("el-divider"), _c("PayInfo", {
    attrs: {
      data: _vm.info,
      "is-edit": _vm.getEditStatus
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };