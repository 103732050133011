var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-mall--product",
    attrs: {
      width: "960px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("div", {
    staticClass: "field-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "产品名称",
      prop: "name"
    }
  }, [_c("InputBase", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "行程天数",
      prop: "days"
    }
  }, [_c("InputBase", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.days,
      callback: function ($$v) {
        _vm.$set(_vm.form, "days", $$v);
      },
      expression: "form.days"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "所属线路",
      prop: "travelLineId"
    }
  }, [_c("SelectLine", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.travelLineId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "travelLineId", $$v);
      },
      expression: "form.travelLineId"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "出发城市",
      prop: "departureCity"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.departureCity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "departureCity", $$v);
      },
      expression: "form.departureCity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "到达城市",
      prop: "arrivalCity"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.arrivalCity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "arrivalCity", $$v);
      },
      expression: "form.arrivalCity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "来源",
      prop: "source"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.source,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "行程特性",
      prop: "feature"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.feature,
      callback: function ($$v) {
        _vm.$set(_vm.form, "feature", $$v);
      },
      expression: "form.feature"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "费用包含",
      prop: "feeInclude"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.feeInclude,
      callback: function ($$v) {
        _vm.$set(_vm.form, "feeInclude", $$v);
      },
      expression: "form.feeInclude"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "费用不含",
      prop: "feeExclude"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.feeExclude,
      callback: function ($$v) {
        _vm.$set(_vm.form, "feeExclude", $$v);
      },
      expression: "form.feeExclude"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "服务标准",
      prop: "serviceStandard"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.serviceStandard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "serviceStandard", $$v);
      },
      expression: "form.serviceStandard"
    }
  })], 1)], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "友情提示",
      prop: "friendlyTips"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.friendlyTips,
      callback: function ($$v) {
        _vm.$set(_vm.form, "friendlyTips", $$v);
      },
      expression: "form.friendlyTips"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "内部备注",
      prop: "innerRemarks"
    }
  }, [_c("InputRemark", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.innerRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "innerRemarks", $$v);
      },
      expression: "form.innerRemarks"
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };