import BaseInfo from './components/Base';
import Invoice from './components/Invoice';
import UploadBox from './components/UploadBox';
import BillInput from './components/BillInput';
import FormSource from './components/FormSource';
import Checkbox from './components/Checkbox';
import { FooterBar } from '@/components/common';
import { mapGetters } from 'vuex';
import { systemCompanyBill } from '@/config/system';
import { SEAL_TYPE, EnterpriseCodeStatus } from '@/config';
import { splitPath, getUserData } from '@/utils';
import handleClipboard from '@/utils/clipboard';
import { reloadMixin } from '@/mixin';
// import { deleteSeal } from '@/api/system/systemConf'

const ECODE_OPEN = 2;
export default {
  components: {
    BaseInfo,
    Invoice,
    UploadBox,
    BillInput,
    FormSource,
    Checkbox,
    FooterBar
  },
  mixins: [reloadMixin],
  data() {
    return {
      SEAL_TYPE,
      loading: true,
      companyData: null,
      checkList: [],
      billInput: null
    };
  },
  computed: {
    ...mapGetters({
      companyInfo: 'system/companyInfo/companyInfo',
      businessInfo: 'system/companyInfo/businessInfo',
      userData: 'user/userData'
    }),
    getBusinessInfo() {
      return this.businessInfo;
    },
    getAlipayStatus() {
      return this.companyInfo && this.companyInfo.alipayEcodeStatus ? EnterpriseCodeStatus[this.companyInfo.alipayEcodeStatus] : '未开通';
    },
    isOpen() {
      const {
        alipayEcodeStatus
      } = this.companyInfo;
      return alipayEcodeStatus == ECODE_OPEN;
    },
    getTagType() {
      const {
        alipayEcodeStatus
      } = this.companyInfo;
      if (!this.companyInfo) return 'info';
      return alipayEcodeStatus == 2 ? 'success' : alipayEcodeStatus == 1 ? 'warning' : 'info';
    }
  },
  created() {
    this.fetchData();
    this.$store.dispatch('system/companyInfo/fetchSealImage', {});
  },
  methods: {
    fetchData() {
      const id = this.userData.travelAgencyId;
      this.$store.dispatch('system/companyInfo/getInfo', id).then(data => {
        this.companyData = data;
      }).catch(err => {
        this.$bus.tip({
          type: 'error',
          message: err.message || '获取信息失败！'
        });
      });
    },
    showInvoiceDialog() {
      this.$refs.formRef.showInvoiceDialog();
    },
    editInvoice(row) {
      this.$refs.formRef.editInvoice(row);
    },
    formatOptions() {
      const newObj = {};
      systemCompanyBill.forEach(it => {
        newObj[it.label] = this.companyInfo[it.label];
      });
      // 处理上传图片路径
      const imageObj = this.formatImageUrl(this.companyInfo);
      const options = {
        ...this.companyInfo,
        ...newObj,
        ...imageObj
      };
      return options;
    },
    formatImageUrl(data) {
      const {
        logoUrl: url
      } = data;
      const logoUrl = splitPath(url);
      return {
        logoUrl
      };
    },
    handleUpdate() {
      const options = this.formatOptions();
      options.businessSynchStatus = options.businessSynchStatus ? 1 : 0;
      options.theme = options.theme ? 1 : 0;
      this.$store.dispatch('system/companyInfo/createOrUpdate', options).then(() => {
        this.fetchData();
        this.$bus.tip();
      });
    },
    handleCope(txt, event) {
      handleClipboard(txt, event);
    },
    handleSign(event) {
      const {
        travelAgencyId: id
      } = getUserData();
      this.$bus.open(() => {
        this.$store.dispatch('enterprise/trip/sign', {
          id
        }).then(data => {
          const {
            signUrl
          } = data;
          this.$bus.tip({
            message: `签约链接：${signUrl}, 请打开该链接，填写签约信息`,
            duration: 0
          });
          handleClipboard(signUrl, event);
        });
      }, '确定要签约吗？');
    },
    handleRemoveSign(event) {
      const {
        travelAgencyId: id
      } = getUserData();
      this.$bus.open(() => {
        this.$store.dispatch('enterprise/trip/removeSign', {
          id
        }).then(data => {
          const {
            unSignUrl
          } = data;
          this.$bus.tip({
            message: `解约链接：${unSignUrl}, 请打开该链接，填写签约信息`,
            duration: 0
          });
          handleClipboard(unSignUrl, event);
        });
      }, '确定要解约吗？');
    },
    handleLogoffSign(event) {
      const {
        travelAgencyId: id
      } = getUserData();
      this.$bus.open(() => {
        this.$store.dispatch('enterprise/trip/logoffSign', {
          id
        }).then(data => {
          const {
            signUrl
          } = data;
          this.$bus.tip({
            message: `解约链接：${signUrl}, 请打开该链接，填写签约信息`,
            duration: 0
          });
          handleClipboard(signUrl, event);
        });
      }, '确定要注销吗？');
    }
  }
};