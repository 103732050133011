var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "guide-borrow"
  }, [_c("TopBar", {
    attrs: {
      title: "借款申请",
      disabled: _vm.isBtnDisable || _vm.showDDApply
    },
    on: {
      "on-add": _vm.handleAdaptPlus
    }
  }, [_vm.showDDApply ? [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showApplyDialog
    }
  }, [_vm._v(" 钉钉借款申请 ")])] : _vm._e()], 2), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [_c("AuditStatus", {
          attrs: {
            row: scope.row
          }
        }), _c("CheckStatus", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "guideName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.guideName) + " ")];
      }
    }, {
      key: "applyMoney",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: scope.row.alipayEnterType ? "danger" : "success"
          }
        }, [_vm._v(" " + _vm._s(scope.row.alipayEnterType ? "追加金额" : "总金额") + " ")]), _c("span", {
          staticClass: "ml5"
        }, [_vm._v(_vm._s(scope.row.applyMoney))])];
      }
    }, {
      key: "applyer",
      fn: function ({
        scope
      }) {
        return [_c("SelectStaff", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: "",
            code: "authCollectType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectBorrowType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.applyUserId,
            callback: function ($$v) {
              _vm.$set(scope.row, "applyUserId", $$v);
            },
            expression: "scope.row.applyUserId"
          }
        })];
      }
    }, {
      key: "applyDate",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          attrs: {
            "value-format": "yyyy-MM-dd",
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          model: {
            value: scope.row.applyDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "applyDate", $$v);
            },
            expression: "scope.row.applyDate"
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: "",
            disabled: _vm.isRowDisable(scope.row)
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row,
            disabled: _vm.isRowDisable(scope.row)
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_vm.showDDApply ? [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleApplyRevoke(scope.row);
            }
          }
        }, [_vm._v(" 撤销申请 ")]), _c("el-button", {
          staticClass: "ml10",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.refreshDDApplyStatus(scope.row.id);
            }
          }
        }, [_vm._v(" 刷新状态 ")])] : _vm._e(), _c("el-button", {
          staticClass: "ml10",
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleApplyDelete(scope.row.id);
            }
          }
        }, [_vm._v(" 超管删除 ")])];
      }
    }, {
      key: "audit",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("Audit", {
          attrs: {
            row: scope.row
          },
          on: {
            "on-check": function ($event) {
              return _vm.handleCheckAudit(scope.row, $event);
            }
          }
        }) : _c("AuditAdmin", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "auditAction",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("AuditCheck", {
          attrs: {
            row: scope.row,
            checkval: _vm.auditAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.auditAll = $event;
            },
            "on-check": _vm.checkAuditAll
          }
        }) : _c("span", [_c("AuditRoleAll", {
          on: {
            "on-select": _vm.handleAuditAll
          }
        })], 1)];
      }
    }, {
      key: "deleteAction",
      fn: function ({}) {
        return [[_vm._v(" 操作 ")]];
      }
    }, _vm.showDDApply ? {
      key: "applyStatus",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getApplyStatus(scope.row.dingTalkStatus)) + " ")];
      }
    } : null], null, true)
  }), _c("DialogDDApply", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };