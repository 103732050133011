import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { TopTitle, DialogLog, SelectRestaurant, SelectDictionary, SelectPayType } from '@/components/common';
import { orderRestaurant, TYPES } from '@/config/order';
import { rowspanMixin, permissionMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import tableMixin from '../tableMixin';
import { mapGetters } from 'vuex';
import { processAdd, processMinus, calcMergeRowTotalByKey } from '@/utils';
const itemListKeys = () => ({
  count: 0,
  freeCount: 0,
  id: 0,
  otherFee: 0,
  planRestaurantOId: 0,
  price: 0,
  restaurantType: ''
});
const defaultState = () => ({
  isCustom: true,
  // 删除数据用来判断类型
  planId: -1,
  area: '',
  restaurantId: '',
  restaurantName: '',
  totalPay: 0,
  remark: '',
  orderDate: '',
  accompanyRate: 0,
  rowTotal: 0,
  mealType: 'DINNER',
  remarks: '',
  ...itemListKeys()
});
export default {
  components: {
    TopTitle,
    DialogLog,
    SelectRestaurant,
    SelectDictionary,
    SelectPayType
  },
  mixins: [tableMixin, rowspanMixin, permissionMixin],
  data() {
    return {
      tableData: [],
      columnData: orderRestaurant,
      deleteMsg: '是否确定删除此条餐厅安排？',
      fetchPath: 'order/index/fetchRestaurant',
      deletePath: 'order/index/deleteRestaurant',
      updatePath: 'order/index/createOrRestaurant',
      statisticsKeys: ['otherFee', 'rowTotal'],
      headMergeNum: 4,
      tailMergeNum: orderRestaurant.length - 4,
      hasMergeTail: true,
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder',
      orderItinerary: 'order/index/orderItinerary'
    })
  },
  created() {
    this.reloadData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.$nextTick(() => {
        this.rowspan();
      });
    },
    reloadData() {
      const {
        id
      } = this.$route.query;
      this.fetchData(id);
    },
    handlePlus() {
      const {
        adultCount,
        planId
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId,
        planId,
        count: adultCount || 0
      });
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 选择名称
    handleSelect(index, val) {
      if (val.label == '') return;
      const {
        id,
        label,
        area
      } = val;
      this.tableData[index].restaurantId = id;
      this.tableData[index].restaurantName = label;
      this.tableData[index].area = area;
    },
    onSelectArea(row, val) {
      row.area = val.label;
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      const rowItem = {
        ...row,
        itemIndex: ++index,
        isCustom: true,
        isSelect: false
      };
      this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      this.changeInput(row);
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 删除景点门票
    deleteRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        isCustom
      } = row;
      !isCustom ? this.$store.dispatch('order/index/deleteRestaurantType', {
        id
      }).then(() => {
        this.reloadData();
      }) : this.tableData.splice(index, 1);
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 选择类型
    selectType(row, val) {
      const {
        label,
        mealLabelPrice
      } = val;
      row.restaurantType = label;
      row.price = mealLabelPrice;
      row.rowTotal = this.calcRowMoney(row);
      this.mergeRowMoney(); // 合计单条数据金额
    },
    // 校验输入的值
    changeInput(item) {
      item.$rowTotal = this.calcRowMoney(item);
      this.tableData = calcMergeRowTotalByKey(this.tableData, '$rowTotal', 'rowTotal');
      this.mergeRowMoney(); // 合计单条数据金额
    },
    // 校验输入的值
    changeOtherFee(item, val) {
      const {
        restaurantOTypes: rList
      } = item;
      if (rList) {
        const money = rList.reduce((acc, cur, idx) => {
          acc = processAdd(acc, this.calcRowMoney({
            ...cur,
            otherFee: rList.length == 1 ? val : idx !== 0 ? 0 : val
          }));
          return acc;
        }, 0);
        item.rowTotal = money;
      } else {
        item.rowTotal = this.calcRowMoney(item);
      }
      this.mergeRowMoney(); // 合计单条数据金额
    },
    // 计算总额
    calcRowMoney(row) {
      let {
        price,
        count,
        freeCount,
        otherFee
      } = row;
      return processAdd(processMinus(count, freeCount) * price, otherFee);
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.restaurantOTypes;
        // 下拉列表数据
        const {
          id,
          restaurant,
          remarks
        } = it;
        it.auditProgress = it.auditProgress ? it.auditProgress : AuditRole.wait;
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          restaurantPlanId: id,
          restaurantName: restaurant ? restaurant.name : '',
          restaurantId: restaurant ? restaurant.id : '',
          itemIndex: 0,
          isCustom: false,
          rowTotal: it.totalMoney
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcRowMoney(v);
            const newItem = {
              ...item,
              ...v,
              itemId: v.id,
              // 子订单id
              itemIndex: i,
              remarks,
              totalSum
            };
            result.push(newItem);
          });
        } else {
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 格式化下拉列表
    formatOptions(list) {
      if (!list.length) return;
      return list.map((it, index) => {
        it.label = it.contentType;
        it.name = it.contentType;
        it.value = index + 1;
        it.id = index + 1;
        return it;
      });
    },
    // 获取日志
    handleLog() {
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: this.currentOrderId,
        subType: TYPES.restaurant
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handleDelete(scope) {
      const {
        rowId,
        id
      } = scope.row;

      // 将要删除的项目加入缓存
      if (rowId && id) {
        this.$bus.open(() => {
          this.$store.dispatch(this.deletePath, {
            id: rowId
          }).then(res => {
            const message = res ? res.message : '操作成功';
            this.operationTip(message);
            this.reloadData();
          });
        }, this.deleteMsg);
      } else {
        this.tableData.splice(scope.$index, 1);
      }
    },
    mergeOtherFeeMoney(list) {
      const otherFee = list.reduce((acc, cur) => {
        acc = processAdd(acc, cur.otherFee);
        return acc;
      }, 0);
      list = list.slice(0, -1);
      const tail = list.slice(-1)[0];
      return [...list, {
        ...tail,
        otherFee
      }];
    },
    beforeCommit(customerOrderId) {
      const list = this.tableData;
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex'];
      list.forEach(it => {
        it.customerOrderId = customerOrderId;

        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {
          customerOrderId
        };
        !it.isCustom && it.itemId ? item.id = it.itemId : null;
        it.totalMoney = it.rowTotal || 0;

        // 定义子项目的列表
        it.restaurantOTypes = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 重新还原该条数据的id
        it.id = it.restaurantPlanId;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.restaurantPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.restaurantOTypes.push(item);
        } else {
          it.restaurantOTypes.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (!row.orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '餐厅日期不能为空!'
          });
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.rowspan();
    }
  }
};