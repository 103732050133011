import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
import { parseTime } from '@/utils';
import { defaultAccount, accountRules, SelectBankTypeList } from '../config';
export default {
  mixins: [formMixin],
  data() {
    return {
      SelectBankTypeList,
      visible: false,
      isUpdate: false,
      form: defaultAccount(),
      validateForm: accountRules
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    }),
    getTitle() {
      return this.isUpdate ? '更新账户' : '添加账户';
    }
  },
  methods: {
    show(row) {
      this.visible = true;
      if (row) {
        this.form = row;
      }
      this.isUpdate = row ? true : false;
    },
    edit(row) {
      this.visible = true;
      this.isUpdate = true;
      this.form = {
        ...row
      };
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
      this.form = defaultAccount();
    },
    handleCreate() {
      if (!this.isUpdate) {
        this.form.initDataFormat = this.form.initData ? parseTime(this.form.initData, '{y}-{m}-{d}') : '';
      } else {
        this.form.initDataFormat = this.form.initDate;
      }

      // form mixin
      if (!this.handleValidate()) return;
      const {
        groupId,
        travelAgencyId,
        realName,
        id
      } = this.userData;
      const defaultOpts = {
        createUserName: realName,
        createUserId: id,
        groupId,
        travelAgencyId
      };
      const opts = {
        ...this.form,
        ...defaultOpts
      };
      this.createOrUpdateAccount(opts);
    },
    // 创建账户
    createOrUpdateAccount(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/account/createOrUpdate', opts).then(() => {
        loading.close();
        this.$bus.tip();
        this.hide();
        this.$emit('reload');
      }).catch(() => {
        loading.close();
      });
    }
  }
};