import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { removeToken } from '@/utils/cookie';
import { mapGetters } from 'vuex';
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.pwdForm.password !== '') {
          this.$refs.pwdForm.validateField('passwordConfirm');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwdForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      pwdForm: {
        oldPassword: '123456',
        password: '',
        passwordConfirm: ''
      },
      pwdRules: {
        password: [{
          validator: validatePass,
          trigger: 'blur'
        }],
        passwordConfirm: [{
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
      userData: 'user/userData'
    }),
    isNeedUpdatePwd() {
      return this.userData && this.userData.isInitPWD;
    }
  },
  mounted() {
    if (!this.isNeedUpdatePwd) {
      this.$router.push({
        path: '/dashboard'
      });
    }
  },
  methods: {
    updatePassword() {
      if (!this.validatePassword()) return;
      const loading = this.$bus.loading();
      this.$store.dispatch('user/updatePwd', {
        ...this.pwdForm,
        id: this.userId || this.userData.id
      }).then(() => {
        this.$bus.tip({
          message: '更新成功, 请重新登录！'
        });
        // 让登陆界面刷新
        removeToken();
        this.$router.push('/login');
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    validatePassword() {
      const {
        password,
        passwordConfirm
      } = this.pwdForm;
      const reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$/g;
      if (!reg.test(password)) {
        this.$bus.tip({
          type: 'warning',
          message: '密码必须包含字母与数字！'
        });
        return false;
      }
      if (!passwordConfirm) {
        this.$bus.tip({
          type: 'warning',
          message: '确认密码不能为空！'
        });
        return false;
      }
      if (password !== passwordConfirm) {
        this.$bus.tip({
          type: 'warning',
          message: '两次输入密码不一致！'
        });
        return false;
      }
      return true;
    }
  }
};