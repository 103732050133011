import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import FormInput from '@/views/order/components/FormInputNew';
import { orderType as OrderType, OrderAuditEmit } from '@/config/order';
import { getUserData } from '@/utils';
import { defDictionary } from '@/utils/dictionary';
const OrderPathMap = {
  [OrderType.team]: '/order/team-input-update',
  [OrderType.personal]: '/personal-input-update'
};
export default {
  components: {
    FormInput
  },
  data() {
    return {
      OrderType,
      visible: false,
      loading: false,
      form: {}
    };
  },
  computed: {
    redirectPath() {
      const {
        orderType
      } = this.form;
      return OrderPathMap[orderType];
    }
  },
  methods: {
    show(row) {
      console.log('row:', row);
      this.form = this.processForm(row);
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    processForm(data) {
      const dictItem = defDictionary(this.$store, 'teamType');
      const dictProduct = defDictionary(this.$store, 'productType');
      const productType = dictProduct ? dictProduct.label : '';
      const groupType = dictItem ? dictItem.label : '';
      const {
        orderType,
        startTripDate,
        endTripDate,
        adultCount,
        childCount,
        accompanyCount,
        elderlyCount,
        fees,
        collects,
        subsidies
      } = data;
      const {
        outCustomerName: customerName,
        outCustomerInfo
      } = data;
      const {
        id: customerId,
        contactVos
      } = outCustomerInfo;
      const contactInfo = contactVos[0];
      const obj = {};
      if (contactInfo) {
        const {
          contact,
          mobilePhone
        } = contactInfo;
        obj.customerContactName = contact;
        obj.customerContactMobileNumber = mobilePhone;
      }
      return {
        ...obj,
        ...this.getUserData(),
        region: [],
        customerId,
        customerName,
        orderType,
        groupType,
        productType,
        startTripDate,
        endTripDate,
        adultCount,
        childCount,
        accompanyCount,
        elderlyCount,
        fees,
        collects,
        subsidies
      };
    },
    // 获取用户数据
    getUserData() {
      const {
        id,
        realName
      } = getUserData();
      const idKeys = ['dutyOPUserId', 'saleUserId', 'outOPUserId'];
      const nameKeys = ['dutyOPUserName', 'saleUserName', 'outOPUserName'];
      const setFormByKey = (keys, val) => {
        return keys.reduce((acc, key) => {
          acc[key] = val;
          return acc;
        }, {});
      };
      return {
        ...setFormByKey(idKeys, id),
        ...setFormByKey(nameKeys, realName)
      };
    },
    processOrderId(id, list, path) {
      if (!list.length) {
        return Promise.resolve();
      }
      const optList = list.map(it => {
        delete it.id;
        return {
          ...it,
          customerOrderId: id
        };
      });
      return this.$store.dispatch(path, optList);
    },
    // 团款、返款、代收处理
    processOrder(id, customerId) {
      this.loading = true;
      const pathListObj = {
        fees: 'order/index/createOrUpdateFund',
        collects: 'order/index/createOrUpdateProxy',
        subsidies: 'order/index/createOrUpdateRefund'
      };
      const arrs = ['fees', 'collects', 'subsidies'];
      const getList = key => {
        const list = this.form[key];
        const path = pathListObj[key];
        this.processOrderId(id, list, path).then(() => {
          if (arrs.length) {
            setTimeout(() => {
              getList(arrs.shift());
            }, 2000);
          }
          if (!arrs.length) {
            this.loading = false;
            // 跳转连接
            this.$router.push({
              path: this.redirectPath,
              query: {
                id,
                customerId,
                type: 'update',
                isNew: true
              }
            });
          }
        });
      };
      getList(arrs.shift());
    },
    processCreate(auditStatus = OrderAuditEmit.NEW_WAIT) {
      this.$refs.formInputRef.createOrUpdate(auditStatus).then(res => {
        const {
          id,
          customerId
        } = res;
        this.processOrder(id, customerId);
      });
    },
    handleConfirm() {
      this.processCreate();
    }
  }
};