var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TopTitle", {
    attrs: {
      title: "代收",
      disabled: !_vm.tableData.length || _vm.isDisableBtn,
      "is-add": _vm.isDisableBtn,
      "is-log-show": true,
      loading: _vm.btnLoading
    },
    on: {
      "on-add": _vm.handlePlus,
      "on-save": _vm.handleUpdate,
      "on-log": _vm.handleLog
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      "sum-keys": ["total"],
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("SelectDictionary", {
          attrs: {
            disabled: _vm.isDisabled(scope.row),
            code: "proxyType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectType(scope.row, $event);
            }
          },
          model: {
            value: scope.row.type,
            callback: function ($$v) {
              _vm.$set(scope.row, "type", $$v);
            },
            expression: "scope.row.type"
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            disabled: _vm.isDisabled(scope.row)
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "total",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            disabled: _vm.isDisabled(scope.row),
            "controls-position": "right"
          },
          model: {
            value: scope.row.total,
            callback: function ($$v) {
              _vm.$set(scope.row, "total", $$v);
            },
            expression: "scope.row.total"
          }
        })];
      }
    }, {
      key: "proxy",
      fn: function ({
        scope
      }) {
        return [_c("RadioGroup", {
          attrs: {
            radios: _vm.radios
          },
          on: {
            change: function ($event) {
              return _vm.changeRadio(scope.row, $event);
            }
          },
          model: {
            value: scope.row.proxy,
            callback: function ($$v) {
              _vm.$set(scope.row, "proxy", $$v);
            },
            expression: "scope.row.proxy"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnBase", {
          attrs: {
            type: "delete",
            disabled: _vm.isDisabled(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        })];
      }
    }])
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogAuditDetail", {
    ref: "auditDetailRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };