import { SelectStaff } from '@/components/common';
export default {
  components: {
    SelectStaff
  },
  data() {
    return {
      visible: false,
      tableData: [],
      form: {}
    };
  },
  methods: {
    show(row) {
      const {
        rowId: id,
        customerAreaService,
        saleUserId
      } = row;
      this.form = {
        id,
        customerAreaService,
        saleUserId
      };
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.from = {};
    },
    selectService(val) {
      const {
        label,
        value
      } = val;
      this.form.customerAreaService = label;
      this.form.customerAreaServiceId = value;
    },
    handleCreate() {
      this.createOrUpdate(this.form);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/custom/createOrUpdate', opts).then(() => {
        this.$emit('reload');
        this.hide();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};