var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "login-select-travel",
    attrs: {
      title: "选择旅行社",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleLogin
    }
  }, [_vm.list ? [_c("RadioGroup", {
    attrs: {
      radios: _vm.list
    },
    model: {
      value: _vm.travelId,
      callback: function ($$v) {
        _vm.travelId = $$v;
      },
      expression: "travelId"
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };