import { Columns as columns } from './tableConfig';
import { formatRowProperty, generageStatisticsKeys } from './helper';
import { reloadMixin, searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
import { SelectCustom, SelectStaff } from '@/components/common';
import { exportTable } from '@/utils/exportTableUtil';
export default {
  components: {
    SelectCustom,
    SelectStaff
  },
  mixins: [reloadMixin, searchMixin],
  data() {
    return {
      columns,
      // pageNum: 1,
      // pageSize: 20,
      searchParams: {
        startTripDateS: parseDate(),
        startTripDateE: parseDate()
      },
      statisticsKeys: ['orders', 'adultCount', 'totalBalance', 'totalFee', 'totalSubsidy', ...generageStatisticsKeys()]
    };
  },
  computed: {
    data() {
      return this.$store.getters['report/collect/balanceYearData'];
    },
    tableData() {
      const list = this.data ? this.formatData(this.data) : [];
      return list;
      // return list.slice(
      //   (this.pageNum - 1) * this.pageSize,
      //   this.pageNum * this.pageSize
      // )
    },
    // statistics() {
    //   return this.data ? this.data.statistics : null
    // },

    total() {
      return this.data ? this.data.length : 0;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('report/collect/balanceYear', {
        ...this.searchParams,
        ...params
      });
    },
    reload() {
      this.fetchData();
    },
    // handlePage({ pageNum, pageSize }) {
    //   this.pageNum = pageNum
    //   this.pageSize = pageSize
    // },

    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    formatData(data) {
      return data.map((it, idx) => {
        const {
          yearOrMonthData
        } = it;
        const obj = formatRowProperty(yearOrMonthData);
        return {
          ...it,
          ...obj,
          seqOrder: idx + 1
        };
      });
    },
    handleExport() {
      const element = document.getElementById('exportTable');
      exportTable(element, '应收余额年汇总统计', '应收余额年汇总统计导出', 1);
    }
  }
};