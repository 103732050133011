// import TableGuideBorrow from '../components/TableGuideBorrow'
import { downloadExport } from '@/utils/exportExcel';
import { guideBorrowDetail as columnData } from '@/config/report';
import { BtnExport, TableTotalBox } from '@/components/common';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { parseDate, parseTime } from '@/utils';
const today = parseDate(new Date());
export default {
  components: {
    BtnExport,
    TableTotalBox
    // TableGuideBorrow,
  },
  mixins: [searchMixin],
  data: () => ({
    // sendDate: [today, ''],
    // createDate: [],
    searchParams: {
      startTripDateS: today,
      createDateS: '',
      createDateE: '',
      isReimb: ''
    },
    total: 0,
    columnData,
    tableData: [],
    statisticData: [],
    statistic: {},
    tableLoading: false
  }),
  computed: {
    ...mapGetters({
      guideBorrowDetail: 'report/index/guideBorrowDetail'
    }),
    parseCreateTime() {
      return val => {
        if (!val) return '';
        return parseTime(val);
      };
    }
  },
  watch: {
    guideBorrowDetail: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 计划类型
    onSelectOrderType({
      value
    }) {
      if (value) {
        value = value.toUpperCase();
      }
      this.searchParams.orderType = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.getTagViewSearch,
        pageNum,
        pageSize
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      this.searchParams[type + 'S'] = date[0];
      this.searchParams[type + 'E'] = date[1];
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.tableData = list;
      this.statistic = statistics;
      this.statisticData = [statistics];
    },
    fetchData(opts) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/fetchGuideBorrow', opts).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/exportGuideBorrow', this.searchParams).then(res => {
        const fileName = '导游借款明细.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};