var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-manage"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.handleSearch
    }
  }), _c("TableList", {
    ref: "tableRef",
    on: {
      page: _vm.handlePage,
      reload: _vm.reload,
      log: _vm.showLog
    }
  }), _c("div", {
    staticClass: "tac"
  }, [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleBillCheck
    }
  }, [_vm._v(" 确认对账 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.closeTag
    }
  }, [_vm._v(" 关闭 ")])], 1), _c("DialogLog", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };