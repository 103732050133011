export const COLUMNS = [{
  attrs: {
    prop: 'seqNumber',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '团期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'hotelPay',
    label: '房'
  }
}, {
  attrs: {
    prop: 'restaurantPay',
    label: '餐'
  }
}, {
  attrs: {
    prop: 'busPay',
    label: '车'
  }
}, {
  attrs: {
    prop: 'scenicPay',
    label: '景'
  }
}, {
  attrs: {
    prop: 'unknownPay',
    label: '未知'
  }
}, {
  attrs: {
    prop: 'planPayAmount',
    label: '实际挂团金额'
  }
}, {
  attrs: {
    prop: 'peerPayAmount',
    label: '企业码付金额'
  }
}
// { attrs: { prop: 'consumeAmount', label: '企业码付金额' } },
];