// 按单位 车队
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  busInfo,
  guide,
  guideBill,
  orderArrangeRemark,
  orderTotalPay,
  payed,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
const lastItem = [orderArrangeRemark, orderTotalPay, payed, owePay, downPayed, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, actionItem];

// 列表
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '车队'
  }
}, ...PayUnitColumn];

// 未付
export const UnPayColumn = [orderInfo, auditStatus, tripDate, guide, guideBill, {
  attrs: {
    prop: 'busCompanyName',
    label: '车队'
  }
}, busInfo, {
  attrs: {
    prop: 'orderDate',
    label: '用车时间',
    'min-width': 100
  }
},
// 开始时间 - 结束时间
...lastItem];