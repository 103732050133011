var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-resource-plan",
    attrs: {
      title: "资源计划详情",
      width: "1200px",
      top: "20px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("FormOrder", {
    attrs: {
      form: _vm.form,
      disabled: true
    }
  }), _c("div", {
    staticClass: "tac mt20"
  }, [_c("el-button", {
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 关闭 ")]), _c("el-button", {
    attrs: {
      size: "large",
      type: "success"
    },
    on: {
      click: _vm.onPrint
    }
  }, [_vm._v(" 打印 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };