import { orderInfo, tripDate, orderTotalPay, payed, unPay, createTime, actionItem } from './index';
const lastItem = [orderTotalPay, payed, unPay, createTime, actionItem];

// 按团号去付款 地接
export const ReceptionColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
},
// 开始-结束
{
  attrs: {
    prop: 'touristName',
    label: '游客姓名'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'adultCount',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
}, ...lastItem];