var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormItem", {
    ref: "formItemRef",
    attrs: {
      data: _vm.form
    }
  }), _c("el-divider"), _c("h3", [_vm._v("账户信息")]), _c("FormAccount", {
    ref: "formBankItemRef",
    attrs: {
      data: _vm.formBank
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };