export const Columns = [{
  attrs: {
    prop: 'realName',
    label: '姓名'
  }
}, {
  attrs: {
    prop: 'userName',
    label: '账号'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];