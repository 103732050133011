import { tableMixin, getSummariesMixin } from '@/mixin';
export default {
  components: {},
  mixins: [tableMixin, getSummariesMixin],
  data() {
    return {
      avgeageKey: [2, 3, 4],
      // 计算人均
      statisticsKeys: ['count', 'per', 'shopMoney']
    };
  },
  methods: {}
};