import Remarks from './Remarks';
import TableLine from './TableLine';
import TableCustom from './TableCustom';
import { resourceProductLine, resourceProductCustom } from '@/config/resource';
import { parseTime } from '@/utils';
import { SELECTOR } from '@/config/select';
import { formatItinerarys } from '@/views/order/utils';
export default {
  components: {
    Remarks,
    TableLine,
    TableCustom
  },
  data() {
    return {
      product: null,
      lines: [],
      customers: [],
      remarks: [],
      tableLineData: [],
      columnLineData: resourceProductLine,
      tableLineLoading: false,
      tableCustomData: [],
      columnCustomData: resourceProductCustom,
      tableCustomLoading: false
    };
  },
  computed: {
    getDisplayArea() {
      return index => {
        return SELECTOR.lineVisible[index].label;
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.initData(id);
  },
  methods: {
    initData(id) {
      this.fetchProduct(id);
    },
    // 产品详情
    fetchProduct(id) {
      this.$store.dispatch('resource/product/fetchDetail', id).then(res => {
        this.product = res;
        this.product.itineraries = formatItinerarys(res.itineraries);
        this.tableCustomData = res.customers;
        this.remarks = res.remarks;
        const createTime = parseTime(res.createTime, '{yy}-{mm}-{dd}');
        this.tableLineData = this.formatLineData(res.itineraries, createTime);
      });
    },
    formatLineData(lines, createTime) {
      return lines.map((it, idx) => {
        const obj = {
          date: `第${idx + 1}天`,
          line: it.itineraryDesc,
          breakfast: it.breakfast,
          lunch: it.lunch,
          dinner: it.dinner,
          area: it.area && it.area.split('|')[0],
          standard: it.standard && it.standard.split('|')[0],
          createTime
        };
        return obj;
      });
    }
  }
};