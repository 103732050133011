var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex flex-end"
  }, [_vm._t("default"), _c("div", {
    staticClass: "icon-select"
  }, [_c("span", {
    staticClass: "iconfont icon-fullscreen mr10",
    on: {
      click: _vm.click
    }
  }), _c("i", {
    staticClass: "el-icon-menu",
    on: {
      click: _vm.show
    }
  })]), _c("DialogBase", {
    attrs: {
      title: "列表设置",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("div", [_c("h3", [_vm._v("表格样式设置")]), _c("div", {
    staticClass: "flex"
  }, [_c("el-checkbox", {
    model: {
      value: _vm.tableStyle.border,
      callback: function ($$v) {
        _vm.$set(_vm.tableStyle, "border", $$v);
      },
      expression: "tableStyle.border"
    }
  }, [_vm._v(" 边框 ")])], 1)]), _c("el-divider"), _c("div", {
    staticStyle: {
      margin: "15px 0"
    }
  }), _vm._l(_vm.checkedColumns, function (item, index) {
    return _c("el-checkbox", {
      key: index,
      model: {
        value: item.isHide,
        callback: function ($$v) {
          _vm.$set(item, "isHide", $$v);
        },
        expression: "item.isHide"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  })], 2)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };