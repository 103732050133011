var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("DialogBase", {
    attrs: {
      visible: _vm.visible,
      title: _vm.getTitle,
      "append-to-body": true,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      close: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("span", {
    staticClass: "pr10"
  }, [_vm._v("公告类型： "), _c("strong", [_vm._v(_vm._s(_vm.noticeOpts.type == "system" ? "系统公告" : "公司公告"))])]), _c("span", {
    staticClass: "pr10"
  }, [_vm._v("公告类型： "), _c("strong", [_vm._v(" " + _vm._s(_vm.noticeOpts.authAreaType == "all" ? "全体可见" : "指定部门") + " ")])]), _c("span", {
    staticClass: "pr10"
  }, [_vm._v("创建时间："), _c("strong", [_vm._v(_vm._s(_vm.noticeOpts.createTime))])])]), _c("el-divider"), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.noticeOpts.content)
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };