import "core-js/modules/es.array.reduce.js";
/*
 * @Author: HelloMan
 * @Date: 2021-10-13 10:17:37
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-19 17:22:30
 * @Description:
 */

import { decimalSplit, isBackMoney } from '@/utils';
import { RebateType } from '@/config/shopping';
import { processNumber, processAdd, processMinus } from '@/utils/number';
import router from '@/router';
const curDownList = ({
  commit,
  rootState
}) => {
  const {
    unPayData
  } = rootState['bill/billDown'];
  if (!unPayData) return [];
  const {
    list
  } = unPayData;
  if (!list || !list.length) return [];
  commit('CURRENT_DATA', unPayData);
  return list;
};

// 导游
const negativeList = list => list.filter(it => isBackMoney(it.feeType));
// 单位付款
const negativeUnitPayList = list => list.filter(it => it.totalPay < 0);
// 单位收款
// const negativeUnitCollectList = list => list.filter(it => it.owePay < 0)

const positiveList = list => list.filter(it => it.owePay > 0 && !isBackMoney(it.feeType));
const setData = ({
  list,
  val = true
}) => {
  if (!list || !list.length) return list;
  list.forEach(it => {
    const {
      owePay
    } = it;
    it.isChecked = val;
    it.downPayed = decimalSplit(val ? isBackMoney(it.feeType) ? owePay * -1 : owePay : 0);
    it.owePay = decimalSplit(val ? 0 : it.owePayCopy);
  });
};
const setShopData = ({
  list,
  val = true,
  subsidy = false
}) => {
  if (!list || !list.length) return;
  list.forEach(it => {
    if (subsidy) {
      let member = Number(it[RebateType.member] || it['memberRebate']);
      let agency = Number(it[RebateType.agency] || it['shopRebate']);
      let redbag = Number(it[RebateType.redbag] || it['unRedEnvelopeRebated']);
      it.isChecked = val;
      it.downPayed = val ? member + agency + redbag : 0;
      it[RebateType.member] = val ? member : 0;
      it[RebateType.agency] = val ? agency : 0;
      it[RebateType.redbag] = val ? redbag : 0;
    } else {
      it.isChecked = val;
      // it.downPayed = it.downPayed
      // it.downPayed = it.unSubsidyKP
    }
  });
};

// 计算总数
const calcTotalMoney = (list, key = 'owePay') => {
  return list.reduce((acc, cur) => {
    const val = Number(cur[key]);
    acc = isBackMoney(cur.feeType) ? processMinus(acc, val) : processAdd(acc, val);
    return acc;
  }, 0);
};

// 收款下账
const collectPayDown = (list, money) => {
  let moneyCopy = money;
  list.forEach(it => {
    const rowMoney = it.owePay;
    // 下正数
    if (moneyCopy > 0) {
      if (moneyCopy >= rowMoney) {
        it.downPayed = it.owePayCopy;
        it.owePay = 0;
        it.isChecked = true;
        moneyCopy -= it.owePayCopy;
      } else if (moneyCopy <= it.owePayCopy) {
        it.downPayed = moneyCopy;
        it.owePay -= moneyCopy;
        it.isChecked = true;
        moneyCopy = 0;
      }
    } else {
      // 下账金额为负数，并且欠付也是负数的情况下
      if (moneyCopy < 0 && rowMoney < 0) {
        const diff = Math.abs(moneyCopy) > Math.abs(it.owePayCopy);
        it.downPayed = diff ? it.owePayCopy : moneyCopy;
        it.owePay = diff ? 0 : it.owePayCopy - moneyCopy;
        it.isChecked = true;
        moneyCopy = diff ? moneyCopy -= it.owePayCopy : 0;
      } else {
        it.downPayed = '';
        it.owePay = it.owePayCopy;
        it.isChecked = false;
      }
    }
    it.owePay = decimalSplit(it.owePay);
    it.downPayed = decimalSplit(it.downPayed);
  });
};
const process = (list, money) => {
  if (!list || !list.length) return;
  money = Number(money);

  // 过滤数据
  let negativeData = [];
  const currentRouter = router.app._route;
  const isCollect = currentRouter.path.indexOf('unit-collect') > -1;

  // 单位收款
  if (isCollect) {
    collectPayDown(list, money);
    return;
  }

  // 单位付款
  if (~currentRouter.path.indexOf('unit-pay')) {
    negativeData = negativeUnitPayList(list);
  } else {
    negativeData = negativeList(list);
  }
  const positiveData = positiveList(list);
  // let digtType = !!(money > 0)

  // 是否有下账金额
  if (money > 0) {
    positiveData.forEach(it => {
      if (money > 0) {
        let owePay = Number(it.owePay);
        let curMoney = Number(money > owePay ? owePay : money) || 0;
        it.downPayed = curMoney;
        it.isChecked = money > 0 ? true : false;
        money -= decimalSplit(curMoney);
      }
    });
  }

  // 如果前面数据未下完帐，则不处理
  // if (digtType && money <= 0) return

  // 如果有交回
  if (negativeData && negativeData.length) {
    if (money > 0) return; // 下正数直接返回
    // money = Math.abs(money)
    negativeData.forEach(it => {
      let owePay = Number(it.owePay);
      let curMoney = Number(money < owePay ? owePay : money) || 0;
      it.downPayed = curMoney;
      it.isChecked = money >= 0 ? false : true;
      money -= decimalSplit(curMoney);
    });
  }
};
const processShop = (list, money) => {
  if (!list || !list.length) return;
  money = Number(money);
  // 过滤数据
  const negativeData = list.filter(it => Number(it.unShopRebated) < 0);
  const positiveData = list.filter(it => Number(it.unShopRebated) > 0);
  let digtType = !!(money > 0);

  // 是否有下账金额
  if (digtType) {
    positiveData.forEach(item => {
      const {
        memberRebate,
        shopRebate
      } = item;
      const rowMoney = Math.abs(memberRebate) + Math.abs(shopRebate);
      if (money >= rowMoney) {
        item[RebateType.member] = memberRebate;
        item[RebateType.agency] = shopRebate;
        item.isChecked = true;
        item.downPayed = rowMoney;
        money -= rowMoney;
      } else if (money < rowMoney && money > 0) {
        item.isChecked = true;
        item.downPayed = money;

        // 人头费
        if (money < memberRebate) {
          item[RebateType.member] = money;
          money = 0;
        } else {
          item[RebateType.member] = memberRebate;
          money -= memberRebate;
        }

        // 社利
        if (money < shopRebate) {
          item[RebateType.agency] = money;
          money = 0;
        } else {
          item[RebateType.agency] = shopRebate;
          money -= shopRebate;
        }
      }
    });
  }

  // 如果前面数据未下完帐，则不处理负数部分
  // if (digtType && money <= 0) return

  // 如果有交回
  if (negativeData && negativeData.length) {
    money = Math.abs(money);
    negativeData.forEach(item => {
      let {
        memberRebate,
        shopRebate
      } = item;
      shopRebate = Number(shopRebate);
      memberRebate = Number(memberRebate);
      item.isChecked = true;
      item.downPayed = -money;

      // 社利
      if (money < shopRebate) {
        item[RebateType.agency] = -money;
        money = 0;
      } else {
        item[RebateType.agency] = shopRebate;
        money -= shopRebate;
      }
      if (money == 0) return;

      // 人头费
      if (money < memberRebate) {
        item[RebateType.member] = -money;
        money = 0;
      } else {
        item[RebateType.member] = memberRebate;
        money -= memberRebate;
      }
    });
  }
};

// const repeatData = list => {
//   const map = {}
//   const res = []
//   list.forEach(it => {
//     if (!map[it.rowId]) {
//       res.push(it)
//       map[it.rowId] = true
//     }
//   })
//   return res
// }

const dfaultState = () => ({
  downMoney: 0,
  // 下账金额
  curTotalMoney: 0,
  // 选中的金额
  totalMoney: 0,
  // 总金额
  balanceMoney: 0,
  // 余额
  autoDownStatus: true,
  // 自动下账状态
  isSelectAll: false,
  // 是否全选
  curBillData: null,
  guideStatistics: {}
});
const state = dfaultState();
const getters = {
  getBalance: state => {
    const res = state.downMoney ? processMinus(state.downMoney, state.curTotalMoney) : state.downMoney;
    return res;
  },
  downMoney: state => state.downMoney,
  isSelectAll: state => state.isSelectAll,
  curTotalMoney: state => state.curTotalMoney,
  autoDownStatus: state => state.autoDownStatus,
  guideStatistics: state => state.guideStatistics
};
const actions = {
  selectAll({
    commit,
    rootState
  }, val) {
    let list = curDownList({
      commit,
      rootState
    });

    // 处理合并的数据
    // list = repeatData(list)

    // 重复数据处理
    const obj = new Map();
    const newList = list.filter(it => !obj.has(it.rowId) && obj.set(it.rowId, 1));

    // 计算总金额
    const totalMoney = val ? calcTotalMoney(newList) : 0;

    // 清空或设置数据
    setData({
      list,
      val
    });
    commit('SELECT_ALL', val);
    commit('PART_TOTAL_MONEY', totalMoney);
    commit('TOTAL_MONEY', totalMoney);
    commit('CHANGE_DOWN_MONEY', totalMoney);
  },
  selectShopAll({
    commit,
    rootState
  }, {
    val,
    subsidy = false
  }) {
    let list = curDownList({
      commit,
      rootState
    });

    // 计算总金额
    const totalMoney = val ? list.reduce((acc, cur) => {
      let agency = Number(cur[RebateType.agency] || 0);
      let member = Number(cur[RebateType.member] || 0);
      let redbag = Number(cur[RebateType.redbag] || 0);
      if (val) {
        agency = agency ? agency : cur['unShopRebated'];
        member = member ? member : cur['unMemberRebated'];
        redbag = redbag ? redbag : cur['unRedEnvelopeRebated'];
      } else {
        member = 0;
        agency = 0;
        redbag = 0;
      }
      cur[RebateType.agency] = agency;
      cur[RebateType.member] = member;
      cur[RebateType.redbag] = redbag;

      /**
       * 是否显示二返
       * 如果显示二返，取 unSubsidyKP
       */
      acc = subsidy ? processAdd(acc, cur.unSubsidyKP) : processAdd(acc, agency, member, redbag);
      return acc;
      // let { memberRebate, shopRebate, unRedEnvelopeRebated } = cur
      // shopRebate = Number(shopRebate)
      // memberRebate = Number(memberRebate)
      // unRedEnvelopeRebated = Number(unRedEnvelopeRebated)
      // acc = processAdd(acc, shopRebate, memberRebate, unRedEnvelopeRebated)
      // return acc
    }, 0) : 0;

    // 清空或设置数据
    setShopData({
      list,
      val,
      subsidy
    });
    commit('SELECT_ALL', val);
    commit('PART_TOTAL_MONEY', totalMoney);
    commit('TOTAL_MONEY', totalMoney);
    commit('CHANGE_DOWN_MONEY', totalMoney);
  },
  // 输入框下账
  changeDownMoney({
    commit,
    state,
    rootState
  }, {
    money,
    isShop,
    subsidy = false
  }) {
    if (!money) {
      money = 0;
    }
    let list = curDownList({
      commit,
      rootState
    });

    // 自动下账
    if (state.autoDownStatus) {
      // 清空数据
      !isShop ? setData({
        list,
        val: false
      }) : setShopData({
        list,
        val: false
      });
      !isShop ? process(list, money) : processShop(list, money);
    }

    // 计算总金额
    const partMoney = list.reduce((acc, cur) => {
      if (isShop) {
        acc = subsidy ? processAdd(acc, cur.unSubsidyKP) : processAdd(acc, cur[RebateType.member], cur[RebateType.agency]);
      } else {
        acc = processAdd(acc, cur.downPayed);
      }
      return acc;
    }, 0);

    // 全选状态
    const isSelectAll = list.every(it => it.isChecked);
    commit('SELECT_ALL', isSelectAll);
    commit('CHANGE_DOWN_MONEY', money);
    commit('PART_TOTAL_MONEY', partMoney);
  },
  /**
   * 当前条目数据金额下账
   * @param {boolean} isCheck 是否勾选
   * @param {boolean} isShop 是否购物
   * @param {boolean} subsidy 是否购物二返
   */
  changeInputMoney({
    commit,
    state,
    rootState
  }, {
    isCheck = false,
    isShop = false,
    list
  }) {
    list = list || curDownList({
      commit,
      rootState
    });

    // 修改当前下账
    const currentMoney = list.reduce((acc, cur) => {
      if (cur.isChecked) {
        if (isShop) {
          // const num1 = cur.downPayed
          // Number(cur.shopRebate) +
          // Number(cur.memberRebate) +
          // Number(cur.totalRedEnvelope)
          // const num2 =
          //   Number(cur[RebateType.agency]) +
          //   Number(cur[RebateType.member]) +
          //   Number(cur[RebateType.redbag])
          // 勾选
          acc += Number(cur.downPayed);
        } else {
          acc += isCheck ? Number(cur.owePay) : Number(cur.downPayed);
        }
      }
      return acc;
    }, 0);

    // 自动下账
    if (state.autoDownStatus) {
      commit('CHANGE_DOWN_MONEY', currentMoney);
    }
    commit('PART_TOTAL_MONEY', currentMoney);
  },
  // 下账模式
  changeDownStatus({
    commit,
    state,
    rootState
  }, status) {
    let list = curDownList({
      commit,
      rootState
    });
    if (state.downMoney && status) {
      process(list, state.downMoney);
    }
    commit('CHANGE_STATUS', status);
  },
  cleanShopAll({
    commit,
    rootState
  }) {
    let list = curDownList({
      commit,
      rootState
    });
    // 还原数据
    setShopData({
      list,
      val: false
    });
    commit('SELECT_ALL', false);
    commit('PART_TOTAL_MONEY', 0);
    commit('TOTAL_MONEY', 0);
  },
  guideStatistics({
    commit
  }, data) {
    commit('GUIDE_STATISTICS', data);
  },
  resetData({
    commit
  }) {
    commit('TOTAL_MONEY', 0);
    commit('PART_TOTAL_MONEY', 0);
    commit('CHANGE_DOWN_MONEY', 0);
  }
};
const mutations = {
  TOTAL_MONEY: (state, data) => {
    state.totalMoney = data;
  },
  SET_BALANCE_MONEY: (state, data) => {
    const {
      money,
      used
    } = data;
    state.downMoney = processNumber(money);
    state.curTotalMoney = processNumber(used);
    state.balanceMoney = state.downMoney - state.curTotalMoney;
  },
  PART_TOTAL_MONEY: (state, money) => {
    money = processNumber(money);
    state.guideStatistics = {
      ...state.guideStatistics,
      downPayed: money
    };
    state.curTotalMoney = money;
  },
  CURRENT_DATA: (state, data) => {
    state.curBillData = data;
  },
  CHANGE_STATUS: (state, val) => {
    state.autoDownStatus = val;
  },
  // 修改下账金额
  CHANGE_DOWN_MONEY: (state, money) => {
    state.downMoney = processNumber(money);
  },
  SELECT_ALL: (state, val) => {
    state.isSelectAll = val;
  },
  SELECT_NONE: state => {
    state.isSelectAll = false;
  },
  GUIDE_STATISTICS: (state, data) => {
    state.guideStatistics = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};