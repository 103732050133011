export const prefix = 'plan:team-arrange:';
export const columnScenic = [{
  attrs: {
    prop: 'scenicName',
    label: '名称'
  },
  slot: 'scenicName'
}, {
  attrs: {
    prop: 'money',
    label: '金额',
    width: 60
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 100
  }
}];
export const columnBus = [{
  attrs: {
    prop: 'busName',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'startDate',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'licenceNumber',
    label: '车牌'
  }
}, {
  attrs: {
    prop: 'money',
    label: '金额',
    width: 60
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 100
  }
}];
export const columnInsurance = [{
  attrs: {
    prop: 'insuranceName',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'money',
    label: '金额',
    width: 60
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 100
  }
}];
export const columnDefault = [{
  attrs: {
    prop: 'name',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'money',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}];
export const columnQuota = [{
  attrs: {
    prop: 'money',
    label: '申请额度'
  }
}, {
  attrs: {
    prop: 'applyUserName',
    label: '申请人'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '状态'
  }
}];
export const columnCollect = [{
  attrs: {
    prop: 'type',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'total',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}];
export const columnCustomer = [{
  attrs: {
    prop: 'name',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'teamSubsidyPrice',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'balance',
    label: '余额'
  }
}, {
  attrs: {
    prop: 'creditMoney',
    label: '信用额度'
  }
}];
export const columnHotelTips = [{
  attrs: {
    prop: 'hotelName',
    label: '酒店名称'
  }
}, {
  attrs: {
    prop: 'areaStandard',
    label: '房间',
    width: 100
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '订单日期'
  }
}, {
  attrs: {
    prop: 'type',
    label: '房间类型'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量',
    width: 50
  }
}, {
  attrs: {
    prop: 'price',
    label: '价格',
    width: 60
  }
}, {
  attrs: {
    prop: 'money',
    label: '总价',
    width: 60
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    width: 100
  }
}];
// 旅检游客
export const columnTourist = [{
  attrs: {
    prop: 'name',
    label: '姓名'
  }
}, {
  attrs: {
    prop: 'cardType',
    label: '证件类型'
  },
  slot: 'idType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  },
  slot: 'idNumber'
}, {
  attrs: {
    prop: '$gender',
    label: '性别'
  }
}, {
  attrs: {
    prop: 'birthDate',
    label: '出生日期'
  },
  slot: 'birthDate'
}, {
  attrs: {
    prop: 'location',
    label: '出生地'
  },
  slot: 'birthPlace'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  },
  slot: 'phoneNumber'
}];
export const clickableList = ['guides', 'scenicOrderStatus', 'hotelOrderStatus', 'restaurantOrderStatus', 'busCompanyOrderStatus', 'insuranceOrderStatus', 'transportCompanyOrderStatus', 'shuttleStatus', 'guideBorrowStatus', 'collectStatus', 'agencyStatus', 'shopOrderStatus', 'otherIncomeStatus', 'otherPayStatus', 'guideBorrowAlipayStatus'];