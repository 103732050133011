var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("BaseInfo", {
    attrs: {
      shop: _vm.form
    }
  }), _vm._m(0), _c("TableList", {
    ref: "tableRef",
    attrs: {
      form: _vm.form
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field-header"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 商品列表 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };