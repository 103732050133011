import { mapGetters } from 'vuex';
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      name: '',
      orderNumber: '',
      mealLabelPrice: '',
      list: null,
      listLoading: false
    };
  },
  computed: {
    ...mapGetters({
      dictionaryList: 'system/dictionary/dictionaryList',
      userData: 'user/userData'
    })
  },
  watch: {
    dictionaryList: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.list = [];
      if (!data || !data.length) return;
      this.list = data.map(v => {
        this.$set(v, 'edit', false); // https://vuejs.org/v2/guide/reactivity.html
        v.originalTitle = v.name; //  will be used when user click the cancel botton
        v.originalNumber = v.orderNumber; //  will be used when user click the cancel botton
        v.originalPrice = v.mealLabelPrice; //  will be used when user click the cancel botton
        return v;
      });
    },
    cancelEdit(row) {
      row.name = row.originalTitle;
      row.orderNumber = row.originalNumber;
      row.mealLabelPrice = row.originalPrice;
      row.edit = false;
    },
    confirmEdit(row) {
      if (!this.validateForm(row.name, '字段不能为空')) return;
      row.edit = false;
      row.mealLabelPrice = row.originalPrice;
      const {
        createUserId,
        travelAgencyId
      } = this.userData;
      const opts = {
        createUserId,
        travelAgencyId,
        dictionaryTypeId: row.dictionaryTypeId,
        id: row.id,
        name: row.name,
        orderNumber: row.orderNumber,
        mealLabelPrice: row.mealLabelPrice
      };
      this.createdictionary(opts);
    },
    handleCreate() {
      if (!this.validateForm(this.name, '字段不能为空')) return;
      const {
        createUserId,
        travelAgencyId
      } = this.userData;
      const opts = {
        createUserId,
        travelAgencyId,
        dictionaryTypeId: this.id,
        name: this.name,
        orderNumber: this.orderNumber,
        mealLabelPrice: this.mealLabelPrice
      };
      this.createdictionary(opts);
    },
    handleDelete(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('system/dictionary/delete', id).then(() => {
          this.list = this.list.filter(it => it.id !== id);
          this.$bus.tip();
        });
      });
    },
    createdictionary(opts) {
      this.$store.dispatch('system/dictionary/createOrUpdate', opts).then(() => {
        this.$parent.triggleTab();
        this.$bus.tip();
        this.resetForm();
      });
    },
    validateForm(field, message) {
      if (!field) {
        this.$bus.tip({
          type: 'warning',
          message
        });
        return false;
      }
      return true;
    },
    resetForm() {
      this.name = '';
      this.orderNumber = '';
    }
  }
};