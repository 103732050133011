import TableTpl3 from '@/views/print/components/TableTpl3';
const columnData = [{
  label: '名称',
  prop: 'hotelName'
}, {
  label: '小团号',
  prop: 'customerTripNumber'
}, {
  label: '日期',
  prop: 'orderDate',
  width: '80px'
}, {
  label: '房型',
  prop: 'roomType',
  width: '40px'
}, {
  label: '报价',
  prop: 'quotePrice',
  width: '30px'
}, {
  label: '数量',
  prop: 'count',
  width: '30px'
}, {
  label: '其他',
  prop: 'otherFee',
  width: '30px'
}, {
  label: '免',
  prop: 'freeCount',
  width: '30px'
}, {
  label: '金额',
  prop: 'reimbMoney',
  width: '90px',
  split: true
}, {
  label: '备注',
  prop: 'remarks',
  slot: 'remark'
}];
export default {
  components: {
    TableTpl3
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columnData
    };
  }
};