var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "guideBrokerage"
    }
  }, [_c("TopTitle", {
    attrs: {
      title: "导游分佣表2"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_vm.show ? _c("table", [_c("colgroup", _vm._l(24, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", [_c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 导游姓名 ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.guideName) + " ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 人数 ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.adultCount) + " + " + _vm._s(_vm.data.childCount) + "+ " + _vm._s(_vm.data.accompanyCount) + " ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 团号 ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.planNumber) + " ")])]), _c("tr", [_c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" 接团日期 ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.shuttle.pickDate ? _vm.getTimeFomat(_vm.data.shuttle.pickDate) : "") + " ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" 接团航班车次 ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.shuttle.pickShiftNumber) + " ")])]), _c("tr", [_c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" 送团日期 ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.shuttle.sendDate ? _vm.getTimeFomat(_vm.data.shuttle.sendDate) : "") + " ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" 送团航班车次 ")]), _c("td", {
    attrs: {
      colspan: "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.shuttle.sendShiftNumber) + " ")])]), _vm._m(0), _c("tr", [_c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 应收团款 ")]), _c("td", {
    attrs: {
      colspan: "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.fee) + " ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 导游代收 ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.collect) + " ")])]), _vm.data.guideShopPlans.length ? [_c("tr", _vm._l(_vm.guideShopPlansHead, function (item, index) {
    return _c("th", {
      key: index + "0",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.guideShopPlans, function (item, index) {
    return _c("tr", {
      key: index + "1"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.shopName) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.money) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.redEnvelope) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.headRebate) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.shopRate) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.totalShopRebate) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalGuideShopIncome) + " ")])])] : _vm._e(), _vm.data.otherIncomePlans.length ? [_c("tr", _vm._l(_vm.otherIncomeHead, function (item, index) {
    return _c("th", {
      key: index + "0",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.otherIncomePlans, function (item, index) {
    return _c("tr", {
      key: index + "1"
    }, [_c("td", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.price) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.otherIncome) + " ")])])] : _vm._e(), _vm.data.scenicAddPlans.length ? [_c("tr", _vm._l(_vm.scenicAddPlansHead, function (item, index) {
    return _c("th", {
      key: index + "2",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.scenicAddPlans, function (ticket, index) {
    return _vm._l(ticket.tickets, function (item, idx) {
      return _c("tr", {
        key: index + " " + idx
      }, [idx == 0 ? _c("td", {
        attrs: {
          colspan: "4",
          rowspan: ticket.tickets.length
        }
      }, [_vm._v(" " + _vm._s(ticket.name) + " ")]) : _vm._e(), _c("td", {
        attrs: {
          colspan: "4"
        }
      }, [_vm._v(" " + _vm._s(item.ticketType) + " ")]), _c("td", {
        attrs: {
          colspan: "4"
        }
      }, [_vm._v(" " + _vm._s(item.adultCount) + " ")]), _c("td", {
        attrs: {
          colspan: "4"
        }
      }, [_vm._v(" " + _vm._s(item.sellPrice) + " ")]), idx == 0 ? _c("td", {
        attrs: {
          colspan: "4",
          rowspan: ticket.tickets.length
        }
      }, [_vm._v(" " + _vm._s(ticket.totalAmountReceive) + " ")]) : _vm._e()]);
    });
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicAddIncome) + " ")])])] : _vm._e(), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 收入合计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalIncome) + " ")])]), _vm._m(1), _vm.data.busCompanyPlans.length ? [_c("tr", _vm._l(_vm.busCompanyPlansHead, function (item, index) {
    return _c("th", {
      key: index + "4",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.busCompanyPlans, function (item, index) {
    return _c("tr", {
      key: index + "5"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.busCompanyName) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.useType) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.busType) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.busFee) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 其他成本 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.busCompanyOtherFee) + " ")])]), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalBusCompanyPay) + " ")])])] : _vm._e(), _vm.data.hotelPlans.length ? [_c("tr", _vm._l(_vm.hotelPlansHead, function (item, index) {
    return _c("th", {
      key: index + "6",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.hotelPlans, function (item, index) {
    return _c("tr", {
      key: index + "7"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.hotelName) + " ")]), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.roomType) + " ")]), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.freeCount) + " ")]), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.quotePrice) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 其他成本 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.hotelOtherFee) + " ")])]), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalHotelPay) + " ")])])] : _vm._e(), _vm.data.scenicPlans.length ? [_c("tr", _vm._l(_vm.scenicPlansHead, function (item, index) {
    return _c("th", {
      key: index + "8",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.scenicPlans, function (item, index) {
    return _c("tr", {
      key: index + "9"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.ticketType) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.adultCount) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.price) + " ")]), _c("td", {
      attrs: {
        colspan: "1"
      }
    }, [_vm._v(" " + _vm._s(item.adultFreeCount) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.childCount) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.childPrice) + " ")]), _c("td", {
      attrs: {
        colspan: "1"
      }
    }, [_vm._v(" " + _vm._s(item.accompanyCount) + " ")]), _c("td", {
      attrs: {
        colspan: "1"
      }
    }, [_vm._v(" " + _vm._s(item.accompanyPrice) + " ")]), _c("td", {
      attrs: {
        colspan: "1"
      }
    }, [_vm._v(" " + _vm._s(item.otherFee) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 其他成本 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.scenicOtherFee) + " ")])]), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalScenicPay) + " ")])])] : _vm._e(), _vm.data.restaurantPlans.length ? [_c("tr", _vm._l(_vm.restaurantPlansHead, function (item, index) {
    return _c("th", {
      key: index + "10",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.restaurantPlans, function (item) {
    return _c("tr", {
      key: item.id
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.restaurantType) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.freeCount) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.price) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 其他成本 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.restaurantOtherFee) + " ")])]), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalRestaurantPay) + " ")])])] : _vm._e(), _vm.data.insurancePlans && _vm.data.insurancePlans.length ? [_c("tr", _vm._l(_vm.insurancePlansHead, function (item, index) {
    return _c("th", {
      key: index + "10",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.insurancePlans, function (item) {
    return _c("tr", {
      key: item.id
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("td", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(item.insuranceType) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.price) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 其他成本 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.insuranceOtherFee || 0) + " ")])]), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalInsurancePay) + " ")])])] : _vm._e(), _vm.data.otherPayPlans.length ? [_c("tr", _vm._l(_vm.otherPayPlansHead, function (item, index) {
    return _c("th", {
      key: index + "12",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.otherPayPlans, function (item, index) {
    return _c("tr", {
      key: index + "13"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("td", {
      attrs: {
        colspan: "6"
      }
    }, [_vm._v(" " + _vm._s(item.price) + " ")]), _c("td", {
      attrs: {
        colspan: "6"
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.total) + " ")])]);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 小计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalOtherPay) + " ")])])] : _vm._e(), _vm.data.transportPlans.length ? [_c("tr", _vm._l(_vm.transportPlansHead, function (item, index) {
    return _c("th", {
      key: index + "12",
      attrs: {
        rowspan: item.rowspan,
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data.transportPlans, function (item, index) {
    return _c("tr", {
      key: index + "14"
    }, [_c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.transportType) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.orderDate) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.shiftNumber) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.startPosition) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.destinationPosition) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.price || 0) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.count || 0) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.total || 0) + " ")])]);
  })] : _vm._e(), _vm._m(2), _vm._l(_vm.footerHead, function (item, ind) {
    return _c("tr", {
      key: ind
    }, [_vm._l(item, function (i, index) {
      return [index != item.length - 1 ? _c("td", {
        key: index,
        attrs: {
          colspan: i.span
        }
      }, [_vm._v(" " + _vm._s(i.label) + " ")]) : _c("td", {
        key: index,
        attrs: {
          colspan: i.span
        }
      }, [_vm._v(" " + _vm._s(_vm.data[i.content]) + " ")])];
    })], 2);
  }), _c("tr", {
    staticClass: "bg"
  }, [_c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 费用合计 ")]), _c("th", {
    attrs: {
      colspan: "20"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalPay) + " ")])]), _c("tr", [_c("td", {
    attrs: {
      colspan: "4",
      rowspan: "3"
    }
  }, [_vm._v(" 备注 ")]), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 总利润： ")]), _c("td", {
    attrs: {
      colspan: "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.totalProfit) + " ")])]), _c("tr", [_c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 总利润*70%： ")]), _c("td", {
    attrs: {
      colspan: "16"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.profit) + " ")])])], 2)]) : _vm._e()])], 1) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【收入明细】")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v("【成本明细】")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("td", {
    attrs: {
      colspan: "20"
    }
  }), _c("td", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 合计金额 ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };