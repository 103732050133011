var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "btn-box"
  }, [_vm.hasSearch ? _c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 搜索 ")]) : _vm._e(), _vm._t("middle"), _vm.hasExcel ? _c("BtnBase", {
    attrs: {
      loading: _vm.excelLoading,
      type: "export"
    },
    on: {
      click: _vm.exportExcel
    }
  }, [_vm._v(" 导出Excel ")]) : _vm._e(), _vm.hasWord ? _c("BtnBase", {
    attrs: {
      loading: _vm.wordLoading,
      type: "export"
    },
    on: {
      click: _vm.exportWord
    }
  }, [_vm._v(" 导出Word ")]) : _vm._e(), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };