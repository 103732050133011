/* eslint-disable no-empty-pattern */
import { trafficBookingRequest, trafficBookingExportRequest } from '@/api/report';
const defaultState = () => ({
  bookingData: null
});
const state = defaultState();
const getters = {
  bookingData: state => state.bookingData
};
const actions = {
  listBooking({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return trafficBookingRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'bookingData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  export({}, params) {
    return new Promise((resolve, reject) => {
      return trafficBookingExportRequest(params).then(res => {
        resolve(res);
      }).catch(err => reject(err));
    });
  }
};
const mutations = {
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};