export default {
  name: 'Refresh',
  props: {
    customStyle: {
      type: Object,
      default: function () {
        return {
          right: '10px',
          bottom: '60px',
          width: '40px',
          height: '40px',
          'border-radius': '4px',
          'line-height': '45px',
          background: '#e7eaf1'
        };
      }
    }
  },
  methods: {
    refresh() {
      this.$store.commit('app/RELOAD_PAGE', true);
    }
  }
};