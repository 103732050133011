import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchOtherIncome, updateOtherIncome, deleteOtherIncome, deleteOtherIncomeItem, fetchOtherIncomeOrder, updateOtherIncomeOrder, deleteOtherIncomeOrder, fetchOtherIncomeOrderList, selectOtherIncomeOrderList, deleteOtherIncomeOrderItem } from '@/api/plan/otherIncome';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  otherIncomeData: null,
  otherIncomeOrderData: null
});
const state = dfaultState();
const getters = {
  otherIncomeData: state => state.otherIncomeData,
  otherIncomeOrderData: state => state.otherIncomeOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // const { planId } = params
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchOtherIncome(params).then(res => {
      const {
        data
      } = res;

      // commit('OTHER_INCOME_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    const planId = rootGetters['plan/index/currentPlanId'];
    return updateOtherIncome(opts).then(res => {
      const {
        data
      } = res;
      // this.dispatch('fetch')
      // commit('OTHER_INCOME_DATA', { planId, data: data.list })
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    const planId = rootGetters['plan/index/currentPlanId'];
    return deleteOtherIncome(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      // commit('OTHER_INCOME_DELETE', { planId, ids })
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteOtherIncomeItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchOtherIncomeOrder(params).then(res => {
      const {
        data
      } = res;
      commit('OTHER_INCOME_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updateOtherIncomeOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deleteOtherIncomeOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deleteOtherIncomeOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchOtherIncomeOrderList({
    commit
  }, opts) {
    return fetchOtherIncomeOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectOtherIncomeOrderList({
    commit
  }, opts) {
    return selectOtherIncomeOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  OTHER_INCOME_DATA: (state, {
    planId,
    data
  }) => {
    state.otherIncomeData = data;
  },
  OTHER_INCOME_DELETE: (state, {
    planId,
    ids
  }) => {
    let otherIncomeList = state.otherIncomeData;
    const list = [];
    otherIncomeList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.otherIncomeData = list;
  },
  OTHER_INCOME_ORDER_DATA: (state, data) => {
    state.otherIncomeOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};