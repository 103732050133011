import { State, defaultState } from '../helper';
import { reloadMixin } from '@/mixin';
export default {
  mixins: [reloadMixin],
  data() {
    return {
      search: defaultState(),
      dateValue: [State.startDateS, State.startDateE]
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    fetchData() {
      this.$emit('search', this.search);
    },
    handleSearch() {
      this.$emit('search', this.search);
    },
    changeDate(val) {
      const [start, end] = val;
      this.search.startDateS = start;
      this.search.startDateE = end;
      this.handleSearch();
    }
  }
};