import request, { requestBlob } from '@/utils/request';
import { LOG_TYPE, APP } from '@/config';
export { fetchPlan } from './plan/index';
// 审核
export const updateAudit = data => {
  return request({
    url: '/order/audit/auditUpdate',
    method: 'post',
    data
  });
};

// 审核 整团
export const updateAuditOrder = data => {
  return request({
    url: '/order/audit/auditUpdateOne',
    method: 'post',
    data
  });
};

// 审核 批量取消 整团
export const batchAuditRequest = data => {
  return request({
    url: '/order/audit/auditUpdateOne-list',
    method: 'post',
    data
  });
};

// 审核 批量取消 整团
export const batchCancelAuditRequest = data => {
  return request({
    url: '/order/audit/removeAdultPlan-list',
    method: 'post',
    data
  });
};

// 单条审核
export const updateAuditOne = data => {
  return request({
    url: '/order/audit/audit',
    method: 'post',
    data
  });
};

// 计调审核 取消
export const cancelAuditOrder = data => {
  return request({
    url: '/order/audit/removeAdultPlan',
    method: 'post',
    data
  });
};

// 确认结算
export const checkAuditOrder = data => {
  return request({
    url: '/order/audit/settlement',
    method: 'post',
    data
  });
};

// 团款
export const fetchTeamFund = params => {
  return request({
    url: '/order/audit/findList',
    method: 'get',
    params
  });
};

// 导游借款
export const fetchGuideBorrow = params => {
  return request({
    url: '/order/guideBorrow/findList',
    method: 'get',
    params
  });
};

// 导游借款 删除
export const deleteGuideBorrow = data => {
  return request({
    url: '/order/guideBorrow/del',
    method: 'post',
    data
  });
};

// 导游借款 保存
export const createGuideBorrow = data => {
  return request({
    url: '/order/guideBorrow/save',
    method: 'post',
    data
  });
};

// 审核日志
export function fetchAuditLog({
  itemId,
  subType = '',
  subItemId
}) {
  return request({
    url: '/log/orderlist',
    method: 'get',
    params: {
      itemId,
      subType,
      subItemId,
      type: LOG_TYPE.audit
    } // type: 5 审核类型
  });
}

// 代收列表
export const fetchCollect = params => {
  return request({
    url: '/order/audit/pageCollect',
    method: 'get',
    params
  });
};

// 导出代收审核
export function getCollectListExcel(params) {
  const url = APP.baseUrl + '/order/audit/exportCollectList';
  return requestBlob({
    url,
    params
  });
}

// 单团归档 归档
export const getPlanFileState = data => {
  return request({
    url: '/order/plan/updatePlanStatus',
    method: 'post',
    data
  });
};