import "core-js/modules/es.array.push.js";
import RowItem from './RowItem';
import { tabMenu } from '@/config/plan';
import { getGuideBtnType } from '@/utils/status';
import { Guides, StatusSend, BtnLink, IconWrong, CustomerInfo, StatusAuditProgress } from '@/components/common';
import { PersonCount, TypeOrder } from '@/components/common';
import { tableMixin, permissionMixin, summaryMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { isSuperMan } from '@/utils/power';
import { ItineraryIdType } from '@/config/superviseTravel';
import { parseDate, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { prefix, columnScenic, columnBus, columnInsurance, columnQuota, columnDefault, columnCollect, columnCustomer, columnHotelTips, clickableList } from '../config';
export default {
  components: {
    RowItem,
    Guides,
    PersonCount,
    StatusSend,
    BtnLink,
    IconWrong,
    CustomerInfo,
    TypeOrder,
    StatusAuditProgress
  },
  mixins: [tableMixin, permissionMixin, summaryMixin],
  data() {
    return {
      tabMenu,
      shopOrderTips: [],
      collectTips: [],
      columnScenic,
      columnBus,
      columnInsurance,
      columnQuota,
      columnDefault,
      columnCollect,
      columnHotelTips,
      clickableList,
      columnCustomer: []
    };
  },
  computed: {
    ...mapGetters({
      rolePermissionList: 'system/role/rolePermissionList'
    }),
    isEnabled() {
      if (this.rolePermissionList) {
        const role = this.hasMenuPermission('confirm');
        return !role.isRole;
      }
      return true;
    },
    overSendDate() {
      return row => {
        return new Date(row.startPlanDate).getTime() >= new Date(parseDate()).getTime();
      };
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnCustomer = visibleCustom ? columnCustomer : filterCustomColumn(columnCustomer);
  },
  methods: {
    getGuideBtnType,
    currentPermission() {
      const {
        path
      } = this.$route;
      const list = this.rolePermissionList;
      if (!list || !list.length) return;
      const curNav = list.find(it => it.code === '/plan');
      return curNav.subMenus.find(it => it.code === path);
    },
    hasMenuPermission(type) {
      const currentMenu = this.currentPermission();
      if (currentMenu) {
        return currentMenu.roleMenuOpts.find(it => it.code === type || it.code === prefix + type);
      }
    },
    handleCell(row, column) {
      // 判断是否已归档
      if (!this.comparePlanStatus(row) && !isSuperMan()) {
        this.$bus.tip({
          type: 'warning',
          message: '已归档不可进行操作!'
        });
        return;
      }
      if (!this.clickableList.includes(column.property)) return;

      // 202-08-30 10:00 点击列表设置后，权限type匹配不上问题修复
      const labelRow = this.columnData.find(it => it.attrs.label === column.label);
      const role = this.hasMenuPermission(labelRow.attrs.type);
      if (!role || !role.isRole) {
        this.$bus.tip({
          type: 'warning',
          message: '无操作权限！'
        });
        return;
      }

      // tab icon 显示状态
      const tabStatus = this.pickStatus(row);
      this.$store.commit('plan/index/PLAN_TAB_STATUS', tabStatus);
      const currentMenu = this.currentPermission();
      const hasPermission = currentMenu.roleMenuOpts.filter(it => it.isRole);

      // 202-08-30 10:00 点击列表设置后，权限type匹配不上问题修复
      const curRole = hasPermission.find(role => role.code == labelRow.attrs.type || role.code == prefix + labelRow.attrs.type);
      // const curRole = hasPermission.find(
      //   role => role.code == column.type || role.code == prefix + column.type
      // )
      this.handleDetail(row.id, curRole, row.planType, row.busCompanyOrderStatus);
    },
    pickStatus(row) {
      const res = {};
      tabMenu.forEach(item => {
        const val = item.status;
        res[val] = row[val];
      });
      return res;
    },
    handleTypeIn({
      row
    }) {
      this.$parent.handleTypeIn(row);
    },
    toggleSendGroup(row) {
      const {
        sendGroupStatus
      } = row;
      if (sendGroupStatus) {
        this.$parent.handleRevoke(row);
      } else {
        this.$parent.sendGroupConfirm(row);
      }
    },
    toggleDispatch({
      id,
      toGroupDispatchSign
    }) {
      const message = !toGroupDispatchSign ? '派单已上报' : '派单已取消';
      const tips = !toGroupDispatchSign ? '确定上报派单吗' : '确定取消上报派单吗';
      this.$bus.open(() => {
        this.$store.dispatch('plan/index/toDispatchGroupSign', {
          id,
          toGroupDispatchSign: !toGroupDispatchSign
        }).then(res => {
          if (!res) return;
          this.$bus.tip({
            message
          });
          this.$parent.handleChangePage();
        });
      }, tips);
    },
    handleCopy(id) {
      this.$bus.open(() => {
        this.$store.dispatch('plan/index/copyPlan', id).then(() => {
          this.$parent.reloadData();
        });
      }, '确定要复制该团吗？');
    },
    handleLog(scope) {
      this.$parent.handleShowLog(scope);
    },
    handlePrint(scope) {
      const {
        id,
        orderType,
        planType,
        startPlanDate: startTripDate,
        endPlanDate: endTripDate
      } = scope.row;
      const {
        toPath
      } = this.$route.query;
      const gateUrl = toPath == '/plan/team-role-arrange' ? 'plan2' : 'plan';
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId: id,
          type: 'plan',
          component: planType == orderType ? 'PlanTeam' : 'PlanPersonal',
          planType,
          gateUrl,
          startTripDate,
          endTripDate
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    linkDetail(row) {
      const {
        path
      } = this.$route;
      const toPath = path;
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/FIRST_ENTER', true);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType,
          toPath
        }
      });
    },
    handleDetail(id, role, planType, busStatus) {
      this.$store.commit('plan/index/CUR_ROLE', role);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$store.commit('plan/index/FIRST_ENTER', true);
      // 兼容菜单
      const [, code] = role.code.indexOf(prefix) > -1 ? role.code.split(prefix) : ['', role.code];
      const {
        path
      } = this.$route;
      const toPath = path;
      const link = `/plan/team-arrange-edit/${code}?id=${role.id}`;
      this.$router.replace({
        path: link,
        query: {
          planId: id,
          planType,
          toPath,
          busStatus
        }
      });
    },
    uploadTravel(row, isUpdate) {
      const {
        id,
        planNumber,
        signId
      } = row;
      const contractNo = row.contractNo;
      this.$router.push({
        name: 'planTeamArrangeTravel',
        query: {
          id,
          planNumber,
          signId,
          contractNo,
          isUpdate
        }
      });
    },
    printTravel({
      signId,
      contractNo
    }) {
      let routeUrl = this.$router.resolve({
        path: '/print-supervise-travel',
        query: {
          name: 'SuperviseTravel',
          signId,
          contractNo: contractNo || 'team'
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    deleteTravel({
      id: planId,
      signId,
      contractNo
    }) {
      this.$bus.open(async () => {
        const params = signId ? JSON.parse(signId) : {};
        const data = await this.getSuperviseId({
          ...params,
          contractNo
        });
        this.$store.dispatch('plan/superviseTravel/setStatus', {
          planId,
          signId: '',
          contractNo
        }).then(() => {
          this.$parent.reloadData();
        });
        if (data) {
          const idKey = ItineraryIdType[contractNo];
          const {
            [idKey]: id
          } = data;
          await this.$store.dispatch('plan/superviseTravel/restoreStatus', {
            id,
            contractNo
          });
          await this.$store.dispatch('plan/superviseTravel/deleteItem', {
            id,
            contractNo
          });
        }
      });

      // const id = '1722073483300573186'
      // this.$store
      //   .dispatch('plan/superviseTravel/restoreStatus', id)
      //   .then(() => {
      //     this.$store.dispatch('plan/superviseTravel/deleteItem', id)
      //   })
      //   .then(() => {
      //     this.$store.dispatch('plan/superviseTravel/setStatus', {
      //       planId,
      //       signId: '',
      //     })
      //   })
    },
    getSuperviseId(params) {
      return this.$store.dispatch('plan/superviseTravel/getCommitInfo', params).then(data => {
        const {
          records
        } = data;
        const item = records[0];
        return item;
      });
    }
  }
};