var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.onSearch,
      "on-add": function ($event) {
        return _vm.$emit("on:add");
      }
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "客户名称"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "联系人"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.contactKey,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "contactKey", $$v);
      },
      expression: "searchParams.contactKey"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "区域经理"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerAreaManagerId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaManagerId", $$v);
      },
      expression: "searchParams.customerAreaManagerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户代码"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.codeName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "codeName", $$v);
      },
      expression: "searchParams.codeName"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.customerAreaServiceId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerAreaServiceId", $$v);
      },
      expression: "searchParams.customerAreaServiceId"
    }
  })], 1), _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.searchParams.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "saleUserId", $$v);
      },
      expression: "searchParams.saleUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      type: "targetSearchAll",
      label: ""
    },
    model: {
      value: _vm.searchParams.kpiStatus,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "kpiStatus", $$v);
      },
      expression: "searchParams.kpiStatus"
    }
  })], 1)], 1), _c("el-button", {
    attrs: {
      slot: "btn",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("on:add");
      }
    },
    slot: "btn"
  }, [_vm._v(" 添加 ")])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };