import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { planShopStaticData } from '@/config/shopping';
import TableList from './TableList';
import { BtnExport, SelectGuide, SelectGuideManager, SelectProduct, SelectLine, SelectCustom } from '@/components/common';
import { searchMixin, subsidyMixin } from '@/mixin';
export default {
  components: {
    SelectGuide,
    SelectGuideManager,
    SelectLine,
    TableList,
    BtnExport,
    SelectProduct,
    SelectCustom
  },
  mixins: [searchMixin, subsidyMixin],
  data: () => ({
    tableLoading: true,
    total: 0,
    columnData: [],
    sendDate: [],
    shopDate: [],
    tableData: [],
    searchParams: {}
  }),
  computed: {
    singleData() {
      return this.$store.getters['shopping/index/singleData'];
    }
  },
  watch: {
    singleData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  // created() {
  //   this.fetchData()
  // },

  methods: {
    initData(data) {
      this.tableLoading = false;
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      // 重组头部
      this.columnData = this.formatHead(list);
      // 格式化数组
      const tableData = this.formatData(list);
      this.$nextTick(() => {
        this.tableLoading = true;
        this.tableData = this.processTotalShopRebate(tableData);
      });
    },
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    // // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    selectPlanType(val) {
      this.searchParams.orderType = val.value;
    },
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getPlanShopStatic', queryParam);
    },
    formatHead(list) {
      if (!list || !list.length) return planShopStaticData;
      let columnData = [];
      let columnShops = [];
      if (list[0].shops.length) {
        columnShops = list[0].shops.map((it, index) => {
          const item = {
            attrs: {
              prop: '',
              label: it.name
            },
            children: [{
              attrs: {
                prop: `totalBuyMoney${index}`,
                label: '总额'
              }
            }, {
              attrs: {
                prop: `shopPerCapita${index}`,
                label: '人均'
              }
            }]
          };
          return item;
        });
      }
      columnData.push(...planShopStaticData, ...columnShops);
      return columnData;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.shopPerCapita = it.shopProfit.shopPerCapita;
        it.totalBuyMoney = it.shopProfit.totalBuyMoney;
        it.totalShopRebate = it.shopProfit.totalShopRebate;
        const itemList = it.shops;
        if (itemList && itemList.length) {
          itemList.forEach((its, index) => {
            it[`shopPerCapita${index}`] = its.shopPerCapita;
            it[`totalBuyMoney${index}`] = its.totalBuyMoney;
            it.shopId = its.id;
            it.shopName = its.name;
          });
        }
        return it;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchPlanShopStaticExcel', {
        ...this.searchParams,
        searchType: 1
      }).then(res => {
        const fileName = '大团统计.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};