var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("DialogBase", {
    staticClass: "tourist-dialog-box",
    attrs: {
      title: "游客游玩信息列表",
      width: "960px",
      "has-foot": false,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "tourist-note"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v(" 本团人数："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.touristList.length) + "大")])]), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v(" 重复出团人数："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.tableData.length) + "大")])]), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v(" 重复出团率："), _c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.groupRate(_vm.tableData, _vm.touristList)) + "%")])]), _vm.groupRate(_vm.tableData, _vm.touristList) >= 10 ? _c("el-col", {
    staticClass: "red",
    attrs: {
      span: 8
    }
  }, [_vm._v(" 建议：重新与组团社协商价格或者直接退团 ")]) : _vm._e(), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onCheckAll
    }
  }, [_vm._v(" 查看完整列表 ")])], 1)], 1)], 1), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "isCommon",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.isCommon ? "是" : "否") + " ")];
      }
    }, {
      key: "scenic",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.scenic, "scenic"))
          }
        }), scope.row.times ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onTourist(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]) : _vm._e()];
      }
    }, {
      key: "shops",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.shops))
          }
        })];
      }
    }, {
      key: "consume",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getFomatData(scope.row.consume))
          }
        })];
      }
    }, {
      key: "times",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.times) + " "), scope.row.times ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onTourist(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")]) : _vm._e()];
      }
    }])
  })], 1), _c("DialogBlackTouristItem", {
    ref: "TouristItemRefs"
  }), _c("DialogBlackTouristAll", {
    ref: "TouristAllRefs"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };