var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-order-list"
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.search
    },
    on: {
      "update:form": function ($event) {
        _vm.search = $event;
      },
      "on-search": _vm.handleSearch
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "大团号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.search.planCustomerNumber,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.search, "planCustomerNumber", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客户"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "",
      disabled: !_vm.hasCustomerInfo
    },
    on: {
      select: _vm.changeCustom
    },
    model: {
      value: _vm.search.customerId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "customerId", $$v);
      },
      expression: "search.customerId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发团日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: ""
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "流水号"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.search.orderName,
      label: "",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.search, "orderName", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "产品线路"
    }
  }, [_c("SelectProduct", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.search.productId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "productId", $$v);
      },
      expression: "search.productId"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("BtnBase", {
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.showCreate
    }
  }, [_vm._v(" 添加订单 ")])], 1), _c("template", {
    slot: "more"
  }, [_c("FormItemBase", {
    attrs: {
      label: "部门"
    }
  }, [_c("InputDepartment", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        _vm.search = {
          ..._vm.search,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "游客信息"
    }
  }, [_c("InputBase", {
    attrs: {
      value: _vm.search.memberKey,
      label: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.search, "memberKey", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "输单日期"
    }
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.inputDate,
      label: ""
    },
    on: {
      "on-change": _vm.changeOrderInputDate
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "年龄段"
    }
  }, [_c("InputAgeRange", {
    attrs: {
      "age-start": _vm.search.ageStart,
      "age-end": _vm.search.ageEnd,
      label: ""
    },
    on: {
      "update:ageStart": function ($event) {
        return _vm.$set(_vm.search, "ageStart", $event);
      },
      "update:age-start": function ($event) {
        return _vm.$set(_vm.search, "ageStart", $event);
      },
      "update:ageEnd": function ($event) {
        return _vm.$set(_vm.search, "ageEnd", $event);
      },
      "update:age-end": function ($event) {
        return _vm.$set(_vm.search, "ageEnd", $event);
      },
      "change-start": function ($event) {
        return _vm.handleAge("ageStart", $event);
      },
      "change-end": function ($event) {
        return _vm.handleAge("ageEnd", $event);
      }
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客源地"
    }
  }, [_c("SelectRegion", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.region,
      callback: function ($$v) {
        _vm.region = $$v;
      },
      expression: "region"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "操作计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeUserName("dutyOPUserId", $event);
      }
    },
    model: {
      value: _vm.search.dutyOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "dutyOPUserId", $$v);
      },
      expression: "search.dutyOPUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeUserName("saleUserId", $event);
      }
    },
    model: {
      value: _vm.search.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "saleUserId", $$v);
      },
      expression: "search.saleUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeUserName("outOPUserId", $event);
      }
    },
    model: {
      value: _vm.search.outOPUserId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "outOPUserId", $$v);
      },
      expression: "search.outOPUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "输单员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeUserName("createUserId", $event);
      }
    },
    model: {
      value: _vm.search.createUserId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "createUserId", $$v);
      },
      expression: "search.createUserId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游"
    }
  }, [_c("SelectGuide", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeUserName("guideId", $event);
      }
    },
    model: {
      value: _vm.search.guideId,
      callback: function ($$v) {
        _vm.$set(_vm.search, "guideId", $$v);
      },
      expression: "search.guideId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "计划状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "planState"
    },
    on: {
      "on-select": function ($event) {
        return _vm.handleSelectChange("planStatus", $event);
      }
    },
    model: {
      value: _vm.search.planStatus,
      callback: function ($$v) {
        _vm.$set(_vm.search, "planStatus", $$v);
      },
      expression: "search.planStatus"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "审核状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "examineState"
    },
    on: {
      "on-select": function ($event) {
        return _vm.handleSelectChange("auditStatus", $event);
      }
    },
    model: {
      value: _vm.search.auditStatus,
      callback: function ($$v) {
        _vm.$set(_vm.search, "auditStatus", $$v);
      },
      expression: "search.auditStatus"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "退单状态"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "isCancel"
    },
    on: {
      "on-select": function ($event) {
        return _vm.handleSelectChange("isCancel", $event);
      }
    },
    model: {
      value: _vm.search.isCancel,
      callback: function ($$v) {
        _vm.$set(_vm.search, "isCancel", $$v);
      },
      expression: "search.isCancel"
    }
  })], 1)], 1)], 2), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.total > 0 ? [_vm.statistics ? _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "count",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalAdult) + "大" + _vm._s(_vm.statistics.totalChild) + "小" + _vm._s(_vm.statistics.totalAccompanyCount) + "陪 ")];
      }
    }, {
      key: "totalCollect",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalCollect) + " ")];
      }
    }, {
      key: "totalIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalIncome) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.statistics.totalSubsidy) + " ")];
      }
    }], null, false, 1202797418)
  }) : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })] : _vm._e(), _c("ItineraryDialog", {
    ref: "ItineraryRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };