import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import data from 'region-picker/dist/data.json';
export const formatCity = () => {
  const citys = data.districts;
  const obj = [];
  citys.forEach((it, index) => {
    obj.push({
      value: it.adcode,
      label: it.name,
      children: []
    });
    if (it.districts && it.districts.length) {
      it.districts.forEach((child, cindex) => {
        obj[index].children.push({
          value: child.adcode,
          label: child.name,
          children: []
        });
        if (child.districts && child.districts.length) {
          child.districts.forEach(gson => {
            obj[index].children[cindex].children.push({
              value: gson.adcode,
              label: gson.name
            });
          });
        }
      });
    }
  });
  return obj;
};
export const processCityChange = data => {
  if (!data) return;
  const res = {
    province: '',
    city: '',
    district: '',
    provinceId: '',
    cityId: '',
    districtId: ''
  };
  const [provinceId, cityId, districtId] = data;
  const list = formatCity();
  list.forEach(it => {
    if (it.value === provinceId) {
      res['province'] = it.label;
      res['provinceId'] = provinceId;
      it.children.forEach(i => {
        if (i.value === cityId) {
          res['city'] = i.label;
          res['cityId'] = cityId;
        }
        i.children.forEach(j => {
          if (j.value === districtId) {
            res['district'] = j.label;
            res['districtId'] = districtId;
          }
        });
      });
    }
  });
  return res;
};
export const setRegionData = data => {
  const keys = ['provinceId', 'cityId', 'districtId'];
  return keys.reduce((acc, key) => {
    const val = data[key];
    if (val) {
      acc.push(val > 0 ? val + '' : '');
    }
    return acc;
  }, []);
};