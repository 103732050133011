import { shopRemark } from '@/config/billCheck';
export default {
  data() {
    return {
      tableData: [],
      columnData: shopRemark,
      visible: false,
      form: {}
    };
  },
  mounted() {},
  methods: {
    show(data) {
      this.tableData = data;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.tableData = [];
    }
  }
};