import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { BillInput, SelectGuide, FooterBar } from '@/components/common';
import { GuideBorrowApplyInfo as columns, GuideBorrowRecord } from '@/config/billDown/guideBorrow';
import { tagViewMixin, reloadMixin } from '@/mixin';
import { TYPES } from '@/config/billDown';
import { processAdd } from '@/utils/number';
export default {
  components: {
    BillInput,
    SelectGuide,
    FooterBar
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      TYPES,
      columns,
      GuideBorrowRecord,
      tableRecord: [],
      tableInfo: [],
      item: {},
      resourceId: '',
      resourceName: '',
      borrowMoney: 0,
      backPath: '/bill/guide-borrow'
    };
  },
  computed: {
    curItem() {
      return this.$store.getters['bill/guideBorrow/curItem'];
    }
  },
  watch: {
    curItem: {
      handler: function (item) {
        if (!item) {
          this.handleClose();
          this.$router.go(-1);
        }
        this.item = item;
        this.resourceId = item.id;
      },
      immediate: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        applyId
      } = this.$route.query;
      this.fetchRecord({
        applyId
      });
    },
    fetchRecord(params = {}) {
      this.$store.dispatch('bill/guideBorrow/fetchApproveRecord', params).then(data => {
        if (!data) return;
        this.tableRecord = this.formatRecordData(data);
        this.borrowMoney = this.calcBorrowMoney(data);
        this.tableInfo = this.formatInfoData(this.curItem);
        this.item.money = this.item.applyMoney - this.borrowMoney;
        this.item.userMoney = this.item.applyMoney - this.borrowMoney;
      });
    },
    calcBorrowMoney(list) {
      return list.reduce((acc, cur) => (acc, processAdd(acc, cur.borrowMoney)), 0);
    },
    formatRecordData(list) {
      return list.map(it => {
        const {
          cash,
          plan
        } = it;
        return {
          ...it,
          ...cash,
          ...plan
        };
      });
    },
    formatInfoData(data) {
      const {
        applyUserName,
        applyDate,
        applyMoney,
        guideName,
        remarks
      } = data;
      return [{
        applyUserName,
        applyDate,
        applyMoney,
        guideName,
        remarks,
        borrowMoney: this.borrowMoney
      }];
    },
    handleSave() {
      const form = this.$refs.formRef.form;
      const opt = {
        ...this.item,
        ...form,
        applyId: this.item.rowId,
        resourceId: this.resourceId
      };
      this.$store.dispatch('bill/guideBorrow/ratifyApply', [opt]).then(() => {
        this.$bus.tip();
        this.handleClose();
        this.goBack();
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};