import "core-js/modules/es.array.push.js";
import { DialogTourist, TableTotalProfit, FormRemarks } from '@/components/common';
import { FooterBar, SelectProduct, DialogLog, SelectStaff, DetailPlanTrip } from '@/components/common';
import { tabMenu, planTrip, PlanType } from '@/config/plan';
import { orderType } from '@/config/order';
import { mapGetters } from 'vuex';
import { systemMixin } from '@/mixin';
import { getAuthByPath } from '@/utils';

// const prefix = 'plan:team-arrange:'

export default {
  components: {
    FooterBar,
    SelectProduct,
    DialogLog,
    DetailPlanTrip,
    TableTotalProfit,
    DialogTourist,
    SelectStaff,
    FormRemarks
  },
  mixins: [systemMixin],
  data() {
    return {
      orderType,
      tabMenu,
      btnLoading: false,
      columnData: planTrip,
      tableData: [],
      filterTabMenu: [],
      form: {
        planNumber: '',
        productId: '',
        date: ['']
      },
      tabIndex: '0',
      activeTab: '导游',
      curTab: null,
      planData: null,
      planOrders: [],
      planId: '',
      guides: [],
      doNotShowTeamProfit: false,
      planType: ''
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      planTabStatus: 'plan/index/planTabStatus',
      currentRole: 'plan/index/currentRole',
      currentPlanId: 'plan/index/currentPlanId',
      fetchStatus: 'plan/index/fetchStatus',
      rolePermissionList: 'system/role/rolePermissionList'
    }),
    isForbid() {
      const isForbid = this.getConfigStatus('order:isPlanNumber:isPlanNumber');
      return isForbid;
    },
    getCurTab() {
      return this.filterTabMenu[this.tabIndex];
    },
    getLogType() {
      return this.filterTabMenu.length ? this.getCurTab.type : 'guide';
    },
    getBtnText() {
      return `保存${this.getCurTab.label}`;
    },
    isHotelTab() {
      return PlanType.hotel == this.getCurTab.type;
    },
    isFleetTab() {
      return PlanType.bus == this.getCurTab.type;
    },
    showArea() {
      return this.planInfo && this.planInfo.planType == orderType.team;
    },
    countPeople() {
      if (!this.planInfo) return '';
      const {
        adultCount,
        childCount,
        accompanyCount
      } = this.planInfo;
      return `${adultCount}大${childCount}小${accompanyCount}陪`;
    },
    isFirstEnter() {
      return this.$store.getters['plan/index/firstEnter'];
    },
    getTabStatus() {
      return tab => {
        if (!this.planTabStatus) return '';
        const findItem = tabMenu.find(it => it.label == tab.label);
        return findItem && this.planTabStatus[findItem.status] ? 'yellow' : '';
      };
    }
  },
  watch: {
    planInfo: {
      handler: 'initPlanDetail',
      immediate: true,
      deep: true
    },
    rolePermissionList: {
      handler: 'getRoleTabMenu',
      immediate: true
    },
    planTabStatus: {
      handler: 'getTabStatus',
      immediate: true
    }
  },
  created() {
    const {
      planId: id,
      planType
    } = this.$route.query;
    this.planId = id;
    this.planType = planType;
    // 如果在当前团切换tab则不请求
    if (this.isFirstEnter) {
      this.fetchPlanDetail(id);
    }
    this.fetchPlanOrder(id);

    // 监听日志
    this.$bus.on('plan:log', this.showLog);
    this.$store.commit('plan/index/CUR_PLAN_ID', id);
    // 清理地接数据
    this.$store.commit('order/reception/CLEAR_RECEPTION', null);
  },
  beforeDestroy() {
    this.$bus.off('plan:log', this.showLog); // 注销on 方法
  },
  methods: {
    fetchPlanDetail() {
      this.$store.dispatch('plan/index/fetchPlanById', this.planId).then(data => {
        this.initPlanDetail(data);
        this.$store.commit('plan/index/FIRST_ENTER', false);
      });
    },
    // 获取订单行程
    fetchPlanOrder(id) {
      this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        this.tableData = data.map(it => {
          this.$set(it, 'expand', true);
          return it;
        });
      });
    },
    initPlanDetail(data) {
      if (!data) return;
      const {
        planNumber,
        productId,
        startPlanDate,
        endPlanDate,
        planType,
        guides,
        dutyOPUserId,
        productName,
        dutyOPUserName
      } = data;
      this.form.planNumber = planNumber;
      this.form.productId = productId;
      this.form.productName = productName;
      this.form.planType = planType;
      this.form.dutyOPUserId = dutyOPUserId;
      this.form.dutyOPUserName = dutyOPUserName;
      this.form.date = [startPlanDate + '', endPlanDate + ''];
      this.guides = this.pickGuide(guides);
      this.$store.commit('plan/index/PLAN_GUIDE', this.pickGuide(guides));
    },
    pickGuide(guides) {
      if (!guides.length) return {
        name: '',
        id: ''
      };
      let findGuide = guides.find(it => !!it.reimb);
      const reimbGuide = findGuide || guides[0];
      return reimbGuide;
    },
    getRoleTabMenu(list) {
      // const { toPath } = this.$route.query
      if (list && list.length) {
        const currentMenu = getAuthByPath(this.$store, '/plan/team-arrange') || getAuthByPath(this.$store, '/plan/team-role-arrange');
        const map = [];
        currentMenu.roleMenuOpts.filter(it => {
          if (it.isRole) {
            map.push(it.code);
          }
        });
        this.filterTabMenu = tabMenu.filter(it => !!map.includes(it.type) || !!map.includes('plan:team-arrange:' + it.type));

        // const planAuth = getAuthByPath(this.$store, '/plan/team-arrange')

        this.doNotShowTeamProfit = currentMenu.roleMenuOpts.some(it => it.code.indexOf('plan:team-arrange:profit') > -1);

        // if (!this.currentRole) {
        //   this.tabIndex = 0
        // } else {
        //   const tabIndex = this.filterTabMenu.findIndex(
        //     it =>
        //       it.type == this.currentRole.code ||
        //       it.type == this.currentRole.code.split('plan:team-arrange:')[1]
        //   )
        //   this.tabIndex = tabIndex + ''
        // }
        const findIndex = this.filterTabMenu.findIndex(it => this.$route.path.indexOf(it.type) > -1);
        const {
          label
        } = this.filterTabMenu[findIndex];
        this.activeTab = label;
        this.tabIndex = findIndex;

        // const authMenu = getAuthByPath(this.$store, '/plan/team-arrange-edit')
        // this.filterTabMenu = authMenu.subMenus.sort((a, b) => a.orderNumber - b.orderNumber)

        // const findIndex = this.filterTabMenu.findIndex(it => it.path == this.$route.path)
        // const { name } = this.filterTabMenu[findIndex]

        // this.activeTab = name
        // this.tabIndex = findIndex
      }
    },
    // // 重新获取tab icon状态, 如果刷新，跳转到列表
    // getTabStatus(data) {
    //   const { toPath } = this.$route.query
    //   if (JSON.stringify(data) == '{}') {
    //     this.$router.replace({
    //       path: toPath,
    //     })
    //   }
    // },

    selectProduct(type, val) {
      this.form[type + 'Name'] = val.name;
      this.form[type + 'Id'] = val.value;
    },
    changeDate(val) {
      const [startDate, endDate] = val;
      this.form.startPlanDate = startDate;
      this.form.endPlanDate = endDate;
    },
    findCurTabMenuItem(name) {
      return tabMenu.find(it => it.label == name);
    },
    handleTab(tab) {
      const {
        planType,
        toPath
      } = this.$route.query;
      const {
        id,
        label,
        type
      } = this.findCurTabMenuItem(tab.name);
      const role = {
        isRole: true,
        id,
        name: label,
        code: type
      };
      const path = `/plan/team-arrange-edit/${type}?id=${id}`;
      this.$router.replace({
        path,
        query: {
          planId: this.planId,
          planType,
          toPath
        }
      });
      this.$store.commit('plan/index/CUR_ROLE', role);
      this.$store.commit('plan/index/CUR_TAB_INDEX', tab.index);
      this.activeTab = tab.name;
    },
    updateAndArrange() {
      const path = this.isHotelTab ? 'plan/hotel/setArrange' : 'plan/fleet/setArrange';
      this.$bus.open(() => {
        this.$store.dispatch(path, {
          id: this.planId
        }).then(() => {
          this.$bus.emit('planlist:reload');
          this.$bus.tip();
        });
      }, '设置后将无法取消，确定设置为已安排吗？');
    },
    // 对应当前tab的内容更新
    handleSave() {
      this.btnLoading = true;
      const {
        contentRef
      } = this.$refs;
      contentRef.createOrUpdate().then(() => {
        this.btnLoading = false;
        this.$bus.emit('plan-list:reload');
      }).catch(() => {
        this.btnLoading = false;
      });
    },
    // 保存基础信息
    handleSaveInfo() {
      const {
        planNumber,
        productName,
        startPlanDate,
        endPlanDate
      } = this.form;
      if (!planNumber) {
        this.$bus.tip({
          type: 'warning',
          message: '大团号不能为空!'
        });
        return false;
      }
      if (planNumber || productName || startPlanDate || endPlanDate) {
        const opts = {
          ...this.form,
          id: this.currentPlanId
        };
        delete opts.date;
        const loading = this.$bus.loading();
        this.$store.dispatch('plan/index/updatePlan', opts).then(() => {
          this.$bus.tip();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      }
    },
    handlePrint() {
      const {
        toPath
      } = this.$route.query;
      const gateUrl = toPath == '/plan/team-role-arrange' ? 'plan2' : 'plan';
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id: this.currentPlanId,
          planId: this.currentPlanId,
          type: 'plan',
          component: this.form.planType == orderType.team ? 'PlanTeam' : 'PlanPersonal',
          planType: this.form.planType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    showLog(params = {}) {
      // 兼容处理
      const type = this.getLogType;
      const logTypeObj = {
        otherIncome: 'ortherIncome',
        otherPay: 'ortherPay',
        borrow: 'guideBorrowApply'
      };
      this.$store.dispatch('plan/index/fetchPlanLog', {
        itemId: this.planId,
        subType: logTypeObj[type] ? logTypeObj[type] : type,
        ...params
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};