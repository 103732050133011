import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { APP, WECHAT_INFO } from '@/config';
import wxlogin from 'vue-wxlogin';
export default {
  components: {
    wxlogin
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.pwdForm.password !== '') {
          this.$refs.pwdForm.validateField('passwordConfirm');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwdForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      activeTab: 'userinfo',
      showWxDialog: false,
      wxLoginDiaData: WECHAT_INFO,
      pwdForm: {
        oldPassword: '',
        password: '',
        passwordConfirm: ''
      },
      pwdRules: {
        // oldPassword: [{ validator: validataOld, trigger: 'blur' }],
        password: [{
          validator: validatePass,
          trigger: 'blur'
        }],
        passwordConfirm: [{
          validator: validatePass2,
          trigger: 'blur'
        }]
      },
      formUser: {},
      wxRedirectUrl: `${APP.redirecWxtUrl}profile/index?wechatBing='true'`
    };
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar',
      user: 'user/userData',
      roleData: 'system/role/roleData'
    }),
    getRole() {
      if (!this.roleData) return '';
      const item = this.roleData.list.find(it => it.id == this.formUser.roleId);
      return item ? item.name : '';
    },
    getCompany() {
      return this.user ? this.user.travelAgency ? this.user.travelAgency.name : '' : '';
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.fetchData().then(() => {
          const {
            wechatBing,
            code
          } = route.query;
          if (wechatBing && code) {
            this.$nextTick(() => {
              this.bindWechat(code);
            });
          }
        });
      },
      immediate: true
    }
  },
  // mounted() {
  //   this.fetchData()
  // },

  methods: {
    fetchData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('user/fetchUserInfo').then(data => {
          this.formUser = data;
          resolve();
        }).catch(err => {
          reject(err);
        });
      });
    },
    updatePassword() {
      if (!this.validatePassword()) return;
      const loading = this.$bus.loading();
      this.$store.dispatch('user/updatePwd', {
        ...this.pwdForm,
        id: this.formUser.id
      }).then(() => {
        this.$bus.tip({
          message: '更新成功！'
        });
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    wechatBind() {
      this.showWxDialog = true;
    },
    bindWechat(code) {
      const {
        id,
        travelAgencyId,
        groupId
      } = this.formUser;
      this.$store.dispatch('user/bindWechat', {
        id,
        travelAgencyId,
        groupId,
        code
      }).then(() => {
        this.$router.push('/profile/index');
        this.$bus.tip({
          message: '绑定成功！'
        });
      });
    },
    clearWechat() {
      this.$bus.open(() => {
        this.unbindWechat();
      }, `确定要解除微信绑定吗？`);
    },
    unbindWechat() {
      const {
        id,
        travelAgencyId,
        groupId
      } = this.formUser;
      this.$store.dispatch('user/unbindWechat', {
        id,
        travelAgencyId,
        groupId
      }).then(() => {
        this.fetchData();
        this.$bus.tip({
          message: '解除成功！'
        });
      });
    },
    updateProfile() {
      const loading = this.$bus.loading();
      this.$store.dispatch('user/updateUser', {
        ...this.formUser,
        terminalSysId: -1
      }).then(() => {
        this.$bus.tip({
          message: '更新成功！'
        });
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    validatePassword() {
      const {
        password,
        passwordConfirm
      } = this.pwdForm;
      // if (!oldPassword) {
      //   this.$bus.tip({ type: 'warning', message: '旧密码不能为空！' })
      //   return false
      // }
      if (!password) {
        this.$bus.tip({
          type: 'warning',
          message: '新密码不能为空！'
        });
        return false;
      }
      if (!passwordConfirm) {
        this.$bus.tip({
          type: 'warning',
          message: '新密码不能为空！'
        });
        return false;
      }
      if (password !== passwordConfirm) {
        this.$bus.tip({
          type: 'warning',
          message: '两次输入密码不一致！'
        });
        return false;
      }
      return true;
    }
  }
};