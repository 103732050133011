import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { SelectCustom, SelectStaff, BtnExport, TableTotalBox } from '@/components/common';
import TableCustoml from './TableCustoml';
import { customlList } from '@/config/report';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';

// import { parseDate } from '@/utils'
// const today = parseDate(new Date(), '{y}-{m}-{d}')
export default {
  components: {
    TableCustoml,
    SelectCustom,
    SelectStaff,
    BtnExport,
    TableTotalBox
  },
  mixins: [searchMixin],
  data: () => ({
    link: {
      checks: '/bill-check/collect',
      unit: '/bill/unit-collect'
    },
    tableLoading: false,
    columnData: customlList,
    tableData: [],
    // sendDate: [today, ''],
    // endDate: [],
    // orderDate: [],
    inputDate: [],
    total: 0,
    statisticData: [],
    searchParams: {},
    statistic: null
  }),
  computed: {
    ...mapGetters({
      customerStatic: 'report/index/customerStatic'
    })
  },
  watch: {
    customerStatic: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableLoading = true;
      this.statistic = null;
      this.$store.dispatch('report/index/getCustomerStatic', queryParam);
    },
    handleData(data) {
      if (!data) return;
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.statisticData = [];
      this.tableData = list;
      this.total = totalCount;
      this.tableLoading = false;
      if (this.statistic) return;
      this.statistic = statistics;
      this.statistic.checks = `${statistics.checks}/${statistics.orders}`;
      this.statisticData.push(this.statistic);
    },
    onLink({
      row,
      type
    }) {
      let search, query;
      if (type != 'unit') {
        const {
          customerName: customerLabel,
          customerId
        } = row;
        search = {
          ...this.searchParams,
          customerLabel,
          customerId
        };
        query = {
          isCustom: true,
          ...search
        };
      }
      this.$router.push({
        path: this.link[type],
        query
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'orderDate') {
        this.searchParams.orderDateS = date[0];
        this.searchParams.orderDateE = date[1];
      }
      if (type == 'createDate') {
        this.searchParams.startCreateDate = date[0];
        this.searchParams.endCreateDate = date[1];
      }
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchCustomerStatic', this.searchParams).then(res => {
        const fileName = '客户汇总统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};