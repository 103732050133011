var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", _vm._g(_vm._b({
    attrs: {
      title: "团号列表",
      width: "200",
      trigger: "hover",
      placement: "top-start"
    }
  }, "el-popover", _vm.$attrs, false), _vm.$listeners), [_c("div", _vm._l(_vm.getPlanList, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(" " + _vm._s(item) + " "), _c("br")]);
  }), 0), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("span", {
    on: {
      click: _vm.showTripList
    }
  }, [_vm._v(_vm._s(_vm.getPlanNumber))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };