import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/travelLine/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchLine(params) {
  return request({
    url: '/resource/travelLine/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateLine(data) {
  return request({
    url: '/resource/travelLine/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteLine(id) {
  return request({
    url: '/resource/travelLine/del',
    method: 'post',
    data: {
      id
    }
  });
}