export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    getTaskType() {
      return item => {
        const type = item.toLowerCase();
        return type === 'plan' ? '飞机' : '火车';
      };
    }
  }
};