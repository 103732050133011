import { blackAllListDetail } from '@/config/order';
import { getUserData, exportTable } from '@/utils';
const LocationKeys = [{
  key: '峨眉,乐山,九寨,九黄',
  value: '四川'
}, {
  key: '昆大丽,昆明,芒市,腾冲,大理,丽江',
  value: '云南'
}, {
  key: '新疆,喀纳斯',
  value: '新疆'
}, {
  key: '长安',
  value: '长安'
}, {
  key: '贵州',
  value: '贵州'
}, {
  key: '福建',
  value: '福建'
}, {
  key: '西藏',
  value: '西藏'
}, {
  key: '甘肃',
  value: '甘肃'
}];
export default {
  data() {
    return {
      visible: false,
      visibleRemark: false,
      tableData: [],
      touristRevamp: {},
      columnData: blackAllListDetail
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    getDesCity() {
      return row => {
        const {
          tripAddr,
          desProvince
        } = row;
        if (desProvince) return desProvince;
        const {
          address
        } = getUserData() && getUserData().travelAgency;
        const reg = '(?:.*?(?:省|自治区|特别行政区|市))?(.*?(?:市|自治州|地区|盟))';
        const matches = (tripAddr || address).match(reg);
        return matches ? matches[1] : address || '桂林';
      };
    }
  },
  methods: {
    show(list) {
      this.tableData = list.map(it => {
        LocationKeys.forEach(v => {
          if (it.product && v.key.split(',').some(val => it.product.indexOf(val) > -1)) {
            it.desProvince = v.value;
          }
        });
        return it;
      });
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.restore();
    },
    onRemarkDialog(item) {
      this.touristRevamp = item;
      this.visibleRemark = true;
    },
    handleCancel() {
      this.visibleRemark = false;
      this.touristRevamp = {};
    },
    handleConfirm() {
      const {
        travelAgencyId
      } = this.userData;
      const opts = this.touristRevamp;
      opts.travelAgencyId = travelAgencyId;
      opts.cardType = 0;
      delete opts.list;
      delete opts.isCommon;
      delete opts.id;
      this.$store.dispatch('order/index/updateMembersRemarks', [opts]).then(() => {
        this.handleCancel();
      });
    },
    tableRowClassName({
      row
    }) {
      // && row.times < 10
      if (row.times === 1) {
        return 'warning-row';
      }
      if (row.times > 1 && row.times <= 10) {
        return 'danger-row';
      }
      if (row.times > 10) {
        return 'info-row';
      }
      return 'success-row';
    },
    restore() {
      this.tableData = [];
      this.visible = false;
    },
    onExport() {
      this.$bus.tip({
        message: '正在导出...'
      });
      const ele = document.getElementById('exportTab');
      exportTable(ele, '游客完整列表', '游客完整列表导出', 1);
    }
  }
};