import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 列表图片
export function fetchResourceImage(params) {
  return request({
    url: '/resource/resourcePic/page',
    method: 'get',
    params
  });
}

// 更新
export function addResourceImage(data) {
  return request({
    url: '/resource/resourcePic/adds',
    method: 'post',
    data
  });
}

// 删除
export function deleteResourceImage(params) {
  return request({
    url: '/resource/resourcePic/remove',
    method: 'post',
    params
  });
}

// 协议列表
export function fetchAgreement(params) {
  return request({
    url: '/resource/resourceContract/page',
    method: 'get',
    params
  });
}

// 协议列表
export function fetchAgreementTypeList(params) {
  return request({
    url: '/resource/resourceContract/list',
    method: 'get',
    params
  });
}

// 协议详情
export function fetchAgreementDetail(id) {
  return request({
    url: '/resource/resourceContract/id',
    method: 'get',
    params: {
      id
    }
  });
}

// 协议添加
export function createOrUpdateAgreement(data) {
  return request({
    url: '/resource/resourceContract/save',
    method: 'post',
    data
  });
}

// 协议添加 全
export function createOrUpdateAgr(data) {
  return request({
    url: '/resource/resourceContract/add-or-update',
    method: 'post',
    data
  });
}

// 协议减免价格删除
export function delPriceFeeAgreement(data) {
  return request({
    url: '/resource/resourceContract/delPriceFee',
    method: 'post',
    data
  });
}

// 协议刪除
export function delelteAgreement(id) {
  return request({
    url: '/resource/resourceContract/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 协议子项添加
export function addAgreementItem(data) {
  return request({
    url: '/resource/resourceContract/savePrice',
    method: 'post',
    data
  });
}

// 协议子项删除
export function delAgreementItem(id) {
  return request({
    url: '/resource/resourceContract/delPrice',
    method: 'post',
    data: {
      id
    }
  });
}

// 日志
export function fetchLog(params) {
  return request({
    url: '/log/resList',
    method: 'get',
    params
  });
}

// 下载协议模板
export function getDownloadTemplate(params) {
  const url = APP.baseUrl + '/resource/resourceContract/download-template';
  return requestBlob({
    url,
    params
  });
}

// 上传景区excel协议
export function importScenicElsx(data) {
  return request({
    url: '/resource/resourceContract/import-scenic-elsx',
    method: 'post',
    data
  });
}

// 上传酒店excel协议
export function importHotelElsx(data) {
  return request({
    url: '/resource/resourceContract/import-hotel-elsx',
    method: 'post',
    data
  });
}

// 旅监接口
export function getTravelSuperviseeDic(params) {
  const url = APP.baseUrl + '/resource/e-travel-platform/getDic';
  return requestBlob({
    url,
    params
  });
}

// 商家资源绑定
export function bindBusinessCode(data) {
  return request({
    url: '/resource/bind-business-code/bind',
    method: 'post',
    data
  });
}
// 商家资源解除绑定
export function delBusinessCode(data) {
  return request({
    url: '/resource/bind-business-code/delBind',
    method: 'post',
    data
  });
}
// 根据资源id获取协议
export function fetchResourceType(params) {
  return request({
    url: '/order/res/listContracts',
    method: 'get',
    params
  });
}
// 根据资源id获取协议（多个资源）
export function fetchResourcesType(params) {
  return request({
    url: '/order/res/listContractsByBatchResId',
    method: 'get',
    params
  });
}