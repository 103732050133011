var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-check-refund"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.fetchData,
      export: _vm.handleExport
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StatisticsKeys,
      "summary-method": _vm.handleSummary
    },
    on: {
      page: _vm.reload
    },
    scopedSlots: _vm._u([{
      key: "remark",
      fn: function ({
        row
      }) {
        return [_c("InputBase", {
          model: {
            value: row.checkAccountRemark,
            callback: function ($$v) {
              _vm.$set(row, "checkAccountRemark", $$v);
            },
            expression: "row.checkAccountRemark"
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };