import "core-js/modules/es.array.push.js";
import TableList from './components/TableList.vue';
import DialogCustomer from './components/DialogCustomer.vue';
import { parseDate } from '@/utils';
import { mapGetters } from 'vuex';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { DialogLog, BtnCreate, SelectStaff, SelectProduct } from '@/components/common';
import { LOG_YPES } from '@/config/resource';
export default {
  components: {
    TableList,
    DialogLog,
    DialogCustomer,
    BtnCreate,
    SelectStaff,
    SelectProduct
  },
  mixins: [reloadMixin, tagViewMixin],
  data() {
    return {
      link: {
        create: '/order/reserve-input'
      },
      data: null,
      total: 0,
      tableData: [],
      startTripDate: [],
      endTripDate: [],
      searchParams: {
        startTripDateS: parseDate(new Date())
      },
      reserveId: '',
      orderType: ''
    };
  },
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews'
    })
  },
  created() {
    this.reloadData();
  },
  mounted() {
    this.$bus.on('order:reserve-close', this.closeTagView);
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/reserve/fetchData', params).then(data => {
        if (!data) return;
        this.data = data;
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    reloadData() {
      this.fetchData();
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    closeTagView() {
      const tagView = this.findCreateTagView();
      tagView && this.closeSelectedTag(tagView);
    },
    findCreateTagView() {
      return this.visitedViews.find(it => it.name === 'orderReserveInput');
    },
    handleTranslate(customerId) {
      this.$store.dispatch('order/reserve/convertOrder', {
        id: this.reserveId,
        orderType: this.orderType,
        customerId
      }).then(() => {
        this.reloadData();
      });
    },
    showCustomerDialog({
      id,
      orderType
    }) {
      this.reserveId = id;
      this.orderType = orderType;
      this.$refs.customerRef.show();
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    selectProduct(val) {
      this.searchParams.productName = val.name;
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    showCreate() {
      this.$router.push({
        path: this.link.create,
        query: {
          type: 'create'
        }
      });
    },
    // 日志
    showLog(row) {
      const {
        id: itemId
      } = row;
      const type = LOG_YPES.order;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId,
        type,
        subItemId: '',
        subType: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};