var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "myPop",
    on: {
      mouseover: _vm.onMouseEnter,
      mouseleave: _vm.onMouseLeave
    }
  }, [_vm.isLink ? _c("BtnLink", {
    attrs: {
      type: _vm.linkType
    },
    on: {
      click: _vm.linkDetail
    }
  }, [_vm._t("default")], 2) : _vm._t("default"), _vm.show ? _c("span", {
    ref: "copyTips",
    staticClass: "el-tooltip__popper is-dark pointer tips-copy",
    style: _vm.styleObj,
    attrs: {
      role: "tooltip"
    },
    on: {
      mouseenter: _vm.onCopyMouseEnter,
      mouseout: _vm.hide,
      click: _vm.handleCopy
    }
  }, [_vm._v(" 复制 ")]) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };