import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// import { downloadExport } from '@/utils/exportExcel'
import TableList from './TableList';
import { exportTable } from '@/utils/exportTableUtil';
import { shopData } from '@/config/shopping';
import { BtnExport, SelectShop, SelectRegion } from '@/components/common';
import { searchMixin, excelMixin, subsidyMixin } from '@/mixin';
// import { processMinus } from '@/utils/number'

export default {
  components: {
    TableList,
    BtnExport,
    SelectShop,
    SelectRegion
  },
  mixins: [searchMixin, excelMixin, subsidyMixin],
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: shopData,
    startTripDate: [],
    shopDate: [],
    tableData: [],
    region: [],
    total: 0
  }),
  computed: {
    shopStaticData() {
      return this.$store.getters['shopping/index/shopStaticData'];
    }
  },
  watch: {
    shopStaticData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.$nextTick(() => {
          this.formatData(data);
        });
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          provinceId,
          cityId,
          districtId,
          startTripDateS,
          startTripDateE,
          inDateS,
          inDateE
        } = data;
        this.searchParams = data;
        this.sendDate = [startTripDateS, startTripDateE]; // 发团日期
        this.shopDate = [inDateS, inDateE]; // 进店日期
        this.region = [provinceId, cityId, districtId];
        this.region = [];
        const cyKes = ['provinceId', 'cityId', 'districtId'];
        cyKes.forEach(key => {
          if (data[key]) {
            this.region.push(data[key]);
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    formatData(data) {
      // 1. 找出数据中的所有导游
      const guideList = this.findAllGuide(data);

      // 2. 过滤重复导游
      const duplicateList = this.duplicateGuide(guideList);

      // 3. 动态表格列
      this.appendColumns(duplicateList);

      // 4. 填充数据（平铺数据）
      const tableData = this.processData(data, duplicateList);
      this.tableData = this.processTotalShopRebate(tableData);
    },
    findAllGuide(data) {
      return data.reduce((acc, cur) => {
        acc = [...acc, ...cur.teamGuides];
        return acc;
      }, []);
    },
    duplicateGuide(guideList) {
      const obj = {};
      return guideList.reduce((acc, cur) => {
        if (!obj[cur.teamGuideId] && cur.teamGuideId && cur.teamGuideId > 0) {
          obj[cur.teamGuideId] = true;
          acc = [...acc, cur];
        }
        return acc;
      }, []);
    },
    processData(data, guideList) {
      return data.map((it, idx) => {
        it.orderNumber = idx + 1;
        guideList.forEach(guide => {
          const findGuide = it.teamGuides.find(g => g.teamGuideId == guide.teamGuideId);
          if (findGuide) {
            const {
              teamGuideId,
              adultCount,
              totalBuyMoney
            } = findGuide;
            it['teamGuideName' + teamGuideId] = findGuide;
            it['adultCount' + teamGuideId] = adultCount;
            it['totalBuyMoney' + teamGuideId] = totalBuyMoney;
          }
        });
        return it;
      });
    },
    appendColumns(list) {
      this.columnData = [...this.columnData];
      list.forEach(it => {
        const {
          teamGuideId,
          teamGuideName
        } = it;
        this.columnData.push({
          attrs: {
            prop: 'teamGuideName' + teamGuideId,
            label: teamGuideName
          },
          children: [{
            attrs: {
              prop: 'adultCount' + teamGuideId,
              label: '人数'
            }
          }, {
            attrs: {
              prop: 'totalBuyMoney' + teamGuideId,
              label: '购物总金额'
            }
          }]
        });
      });
    },
    getGuideList(data) {
      return data.map(it => {
        return it.teamGuides;
      });
    },
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    // 修改省市区
    changeRegion(val) {
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.searchParams[key] = val[key] || '';
      });
    },
    // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getByShopStatic', queryParam).then(() => {
        // const { list, totalCount } = res
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      exportTable(document.querySelector('.table-wrapper'), '购物店统计.xsls', '购物店统计', '购物店统计');
      //   const loading = this.$bus.loading()
      //   this.$store
      //     .dispatch('shopping/index/fetchByShopStaticExcel', this.searchParams)
      //     .then(res => {
      //       const fileName = '单商店数据分析表.xlsx'
      //       loading.close()
      //       downloadExport(res, fileName)
      //     })
    }
  }
};