import TableList from './TableList';
import { downloadExport } from '@/utils/exportExcel';
import { orderAllPlan, orderTypeLabel, orderAuditStatus, orderProgressStatus } from '@/config/order';
import { BtnExport, SelectCustom, SelectProduct, SelectGuide, SelectStaff } from '@/components/common';
import { departKeys } from '@/config/dictionary';
import { customerInfoMixin, searchMixin } from '@/mixin';
export default {
  components: {
    BtnExport,
    SelectCustom,
    SelectProduct,
    SelectGuide,
    SelectStaff,
    TableList
  },
  mixins: [searchMixin, customerInfoMixin],
  data: () => ({
    planStatusLabel: '',
    startTripDate: '',
    filename: '计划订单',
    autoWidth: true,
    bookType: 'xlsx',
    excelLoading: false,
    columnData: orderAllPlan,
    tableData: [],
    total: 0,
    searchParams: {},
    visibleMore: false
  }),
  computed: {
    planAllData() {
      return this.$store.getters['order/index/planAllData'];
    },
    getVisible() {
      return this.visibleMore;
    }
  },
  watch: {
    planAllData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.getToday
          });
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam = {}) {
      this.$store.dispatch('order/index/fetchAllPlan', queryParam);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = Object.freeze(this.formatData(list));
    },
    selectGroupStatus(val) {
      val = val ? val : {
        label: '',
        value: ''
      };
      this.searchParams.planStatus = val.value;
      this.planStatusLabel = val.label;
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    changeGuide(val) {
      this.searchParams.guideName = val.label;
    },
    // 部门
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const keys = orderAllPlan.map(it => it.attrs.prop);
      return list.map(it => {
        // 团型判断转义
        it.planType = orderTypeLabel[it.planType] ? orderTypeLabel[it.planType] : it.planType;
        keys.forEach(key => {
          const val = it[key];
          switch (key) {
            case 'count':
              it.count = `${it.adultCount}大${it.childCount || 0}小${it.accompanyCount || 0}陪`;
              break;
            // case 'planType':
            //   it.planType = val ? orderTypeLabel[val] : it.planType
            //   break
            case 'planStatus':
              it.planState = orderAuditStatus[val];
              break;
            case 'auditStatus':
              it.auditState = orderProgressStatus[val];
              break;
            default:
              it[key] ? it[key] : '';
          }
        });
        return it;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('order/index/getSimpleListExport', this.searchParams).then(res => {
        const fileName = '所有计划表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      }).catch(() => {
        loading.close();
      });
    }
  }
};