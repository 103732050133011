import { ColumnsHistory as Columns } from '../tableConfig';
import { PersonCount } from '@/components/common';
import { getShipInfo } from '../helper';
import { OrderResPlanEnumLabel } from '@/config/order/orderEnum';
export default {
  components: {
    PersonCount
  },
  data() {
    return {
      Columns,
      getShipInfo,
      visible: false,
      tableData: []
    };
  },
  computed: {
    getBookingStatus() {
      return val => val ? OrderResPlanEnumLabel[val] : '';
    }
  },
  methods: {
    show(masterId) {
      this.visible = true;
      this.fetchData({
        masterId
      });
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/boatPersonal/fetchHistory', params).then(data => {
        if (!data) return;
        this.tableData = data.list.map(it => {
          return {
            ...it,
            $outOPUserName: it.createUserName
          };
        });
      });
    },
    handleDetail(row) {
      this.$parent.handleDetail(row);
    }
  }
};