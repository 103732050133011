var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("SearchForm", {
    ref: "searchRef",
    attrs: {
      "resource-type": _vm.searchParams.resourceType
    }
  }), _c("TableScenic", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData,
      loading: _vm.tableLoading,
      statistic: _vm.statistic
    }
  }), _vm.total > 0 ? [_c("TableTotalBox", {
    attrs: {
      border: "",
      statistic: _vm.statistic,
      data: _vm.statisticData,
      "column-data": _vm.columnData
    }
  })] : _vm._e(), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };