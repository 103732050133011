import request from '@/utils/request';

// 列表
export function fetchList(travelAgencyId) {
  return request({
    url: `/system/sysDepartment/list`,
    method: 'get',
    params: {
      travelAgencyId
    }
  });
}

// 创建一级
export function createOrUpdate(data) {
  return request({
    url: '/system/sysDepartment/save',
    method: 'post',
    data
  });
}

// 创建二级
export function createOrUpdateChild(data) {
  return request({
    url: '/system/sysSubDepartment/save',
    method: 'post',
    data
  });
}

// 创建三级
export function createOrUpdateGrandChild(data) {
  return request({
    url: '/system/sysGrandsonDepartment/save',
    method: 'post',
    data
  });
}

// 一级部门详情
export function fetchDetail(name) {
  return request({
    url: '/system/sysDepartment/findNamesLikeName',
    method: 'get',
    params: {
      name
    }
  });
}

// 一级删除部门
export function deleteItem(id) {
  return request({
    url: '/system/sysDepartment/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 二级部门详情
export function fetchChildDetail(name) {
  return request({
    url: '/system/sysSubDepartment/findNamesLikeName',
    method: 'get',
    params: {
      name
    }
  });
}

// 二级删除部门
export function deleteChildItem(id) {
  return request({
    url: '/system/sysSubDepartment/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 三级部门详情
export function fetchGrandDetail(name) {
  return request({
    url: '/system/sysGrandsonDepartment/findNamesLikeName',
    method: 'get',
    params: {
      name
    }
  });
}

// 三级删除部门
export function deleteGrandItem(id) {
  return request({
    url: '/system/sysGrandsonDepartment/del',
    method: 'post',
    data: {
      id
    }
  });
}