import { Hotel } from '@/components/common/Order';
import { auditEditHotel, auditEditHotelAll } from '@/config/audit';
import { permissionMixin, planIsOrderInfoMixin } from '@/mixin';
export default {
  components: {
    Hotel
  },
  mixins: [permissionMixin, planIsOrderInfoMixin],
  data() {
    return {
      columnData: auditEditHotel,
      auditAll: false
    };
  },
  created() {
    setTimeout(() => {
      this.columnData = this.hasSettlementPrice('hotelFloorPriceAudit') ? auditEditHotelAll : auditEditHotel;
    }, 100);
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};