/* eslint-disable no-unused-vars */
import { fetchAll, fetchAllPlan, fetchPlanById, getTouristTpl, fetchList, fetchDetail, fetchListSearch, fetchItinerary, fetchFund, fetchFundProxy, fetchRefund, fetchTraffic, fetchPickup, fetchTourist, fetchRemarks, createOrUpdate, createOrUpdateFund, createOrUpdateProxy, createOrUpdateRefund, createOrUpdatePickup, createOrShuttle, createOrUpdateTourist, createOrUpdateTraffic, createOrUpdateInsurance, createOrUpdateTraffic2, createOrUpdateTraffic3, createOrUpdateRemarks, createOrUpdateItinerary, deleteItinerary, deleteItinerarys, confirmTeam, deleteOrder, revoke, cancelPlan, deleteScenic, deleteTraffic, deleteTrafficTypeRequest, delItineraryByOrderId, deleteTouriestList, deleteTouriest, fetchSPlanSimpleList, fetchPickupDetail, deleteRefund, deleteFund, deleteProxy, deletePickup, gthAndSaveUsers, updateMembersRemarks, getSearchByIds, delBlacklistTouriest, fetchOtherIncome, createOtherIncome, fetchOtherPay, createOtherPay, deleteOtherIncomeOrder, deleteOtherPayOrder, deleteInsurance, fetchInsurance, fetchHotel, createOrHotel, deleteOrHotel, getSimpleListExport, exportOrderAll, fetchLog, fetchAll2, fetchApplyAudit, fetchCancelAudit, fetchAuditStatus, fetchApplyAuditList, fetchApplyAuditById, getPlanObjectStatus, createOrUpdateAll, unDeductSubsidy, fetchScenic, deleteOrScenic, deleteOrScenics, createOrScenic, fetchRestaurant, deleteRestaurantType, createOrRestaurant, deleteRestaurant, deleteOrScenicTicket, createOrBus, fetchOrBus, deleteBusType, deleteBus, copyOrderRequest, confirmFileRequest } from '@/api/order';
import { DEFAULT_PAGE } from '@/config';
import { orderType } from '@/config/order';
const dfaultState = () => ({
  orderHotel: [],
  planAllData: null,
  orderAllData: null,
  orderAllData2: null,
  teamData: null,
  personalData: null,
  isNewOrder: true,
  currentOrder: null,
  // 当前编辑的订单
  currentOrderId: null,
  // 当前编辑的订单ID
  currentOrderList: null,
  // 当前订单列表
  orderInput: {},
  // 未编辑前表单内容
  needReload: {
    Team: false,
    Personal: false
  },
  // 是否需要更新列表
  teamFundMoney: 0,
  orderType: orderType.team,
  orderAuditData: null,
  customerId: null,
  auditPageRefresh: false,
  planAuditStatus: false,
  orderScenices: null,
  orderItinerary: null,
  orderItineraryJson: null
});
const state = dfaultState();
const getters = {
  orderHotel: state => state.orderHotel,
  planAllData: state => state.planAllData,
  orderAllData: state => state.orderAllData,
  orderAllData2: state => state.orderAllData2,
  teamData: state => state.teamData,
  personalData: state => state.personalData,
  isNewOrder: state => state.isNewOrder,
  currentOrder: state => state.currentOrder,
  currentOrderId: state => state.currentOrderId,
  currentOrderList: state => state.currentOrderList,
  orderInput: state => state.orderInput,
  needReload: state => state.needReload,
  teamFundMoney: state => state.teamFundMoney,
  orderAuditData: state => state.orderAuditData,
  customerId: state => state.customerId,
  auditPageRefresh: state => state.auditPageRefresh,
  planAuditStatus: state => state.planAuditStatus,
  orderScenices: state => state.orderScenices,
  orderItinerary: state => state.orderItinerary,
  orderItineraryJson: state => state.orderItineraryJson
};
const actions = {
  // 所有订单
  fetchAll({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchAll(queryParam).then(res => {
      const {
        data
      } = res;
      data.list.forEach(it => {
        it.members = it.members.filter(item => item.isContact);
      });
      commit('ORDER_ALL', data);
      return data;
    });
  },
  // 所有订单2
  fetchAll2({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchAll2(queryParam).then(res => {
      const {
        data
      } = res;
      data.list.forEach(it => {
        it.members = it.members.filter(item => item.isContact);
      });
      commit('ORDER_ALL2', data);
      return data;
    });
  },
  // 所有订单导出
  exportOrderAll({
    commit
  }, queryParam) {
    return exportOrderAll(queryParam);
  },
  // 所有计划
  fetchAllPlan({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchAllPlan(queryParam).then(res => {
      const {
        data
      } = res;
      commit('PLAN_ALL', data);
      return data;
    });
  },
  // 导出所有计划
  getSimpleListExport({
    commit
  }, queryParam) {
    return getSimpleListExport(queryParam).then(res => {
      return res;
    });
  },
  // 查询计划信息
  fetchPlanById({
    commit
  }, queryParam) {
    return fetchPlanById(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 其他收入 列表
  fetchOtherIncome({
    commit
  }, opst) {
    opst.customerOrderId = opst.id;
    delete opst.id;
    return fetchOtherIncome(opst).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 其他支出 列表
  fetchOtherPay({
    commit
  }, opst) {
    opst.customerOrderId = opst.id;
    delete opst.id;
    return fetchOtherPay(opst).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 其他收入 保存
  createOtherIncome({
    commit
  }, opts) {
    return createOtherIncome(opts);
  },
  // 其他支出 保存
  createOtherPay({
    commit
  }, opts) {
    return createOtherPay(opts);
  },
  // 其他收入 保存
  deleteOtherIncome({
    commit
  }, id) {
    return deleteOtherIncomeOrder(id);
  },
  // 其他支出 保存
  deleteOtherPay({
    commit
  }, id) {
    return deleteOtherPayOrder(id);
  },
  fetchSPlanSimpleList({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchSPlanSimpleList(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 下载游客名单模版
  downloadTouristTpl({
    commit
  }) {
    return getTouristTpl();
  },
  // 订单列表
  fetch({
    commit
  }, {
    type,
    queryParam
  }) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchList(queryParam).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        it.value = it.id;
        return it;
      });
      commit('CUR_ORDER_LIST', {
        type,
        data
      });
      return data;
    });
  },
  // 订单列表搜索
  fetchSearch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchListSearch(queryParam).then(res => {
      const {
        data
      } = res;
      commit('CUR_ORDER_LIST', data);
      return data;
    });
  },
  // 订单详情
  fetchDetail({
    commit,
    dispatch
  }, opst) {
    return fetchDetail(opst).then(res => {
      const {
        data
      } = res;
      if (data.planId > 0) {
        dispatch('getPlanObjectStatus', {
          id: data.planId
        });
      }
      commit('CUR_ORDER', data);
      commit('CUR_ORDER_ID', opst.id);
      return data;
    });
  },
  // 订单
  createOrUpdateAll({
    commit
  }, opts) {
    return createOrUpdateAll(opts).then(res => {
      const {
        data
      } = res;
      // commit('CUR_ORDER_ID', data.id)
      return res;
    });
  },
  // 订单
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdate(opts).then(res => {
      const {
        data
      } = res;
      commit('CUR_ORDER_ID', data.id);
      return res;
    });
  },
  fetchHotel({
    commit
  }, opts) {
    let obj = {};
    if (typeof opts != 'object') {
      obj = {
        customerOrderId: opts
      };
    } else {
      obj = {
        customerOrderId: opts.id,
        ...opts
      };
    }
    return fetchHotel(obj).then(res => {
      const {
        data
      } = res;
      commit('ORDER_HOTEL', data);
      return data;
    });
  },
  createOrHotel({
    commit
  }, opts) {
    return createOrHotel(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  deleteOrHotel({
    commit
  }, id) {
    return deleteOrHotel(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 团款列表
  fetchFund({
    commit,
    dispatch,
    state
  }, opts) {
    return fetchFund(opts).then(res => {
      const {
        data
      } = res;
      if (!data) return;
      let teamFundMoney = 0;
      let customerOrderId;
      data.forEach(item => {
        const [type, typeId] = item.type.split('|');
        item.typeId = typeId;
        item.type = type;
        teamFundMoney += Number(data.total);
        customerOrderId = item.customerOrderId;
      });
      commit('TEAM_FUND', teamFundMoney);
      return data || [];
    });
  },
  // 团款
  createOrUpdateFund({
    dispatch
  }, opts) {
    return createOrUpdateFund(opts);
  },
  // 代收列表
  fetchFundProxy({
    commit,
    rootGetters
  }, id) {
    id = id || rootGetters['order/index/currentOrderId'];
    return fetchFundProxy(id).then(res => {
      let {
        data
      } = res;
      data = data.map(it => {
        const [type, typeId] = it.type.split('|');
        it.typeId = typeId;
        it.type = type;
        // 初始化代收状态
        it.proxy = it.isGuideCollect ? 'isGuideCollect' : 'isAgencyCollect';
        return it;
      });
      return data || [];
    });
  },
  // 代收
  createOrUpdateProxy({
    dispatch
  }, opts) {
    return createOrUpdateProxy(opts);
  },
  // 返款列表
  fetchRefund({
    commit
  }, id) {
    return fetchRefund(id).then(res => {
      const {
        data
      } = res;
      data.forEach(it => {
        it.value = it.id;
        const [type, typeId] = it.type.split('|');
        it.typeId = typeId;
        it.type = type;
      });
      return data || [];
    });
  },
  // 返款
  createOrUpdateRefund({
    dispatch
  }, opts) {
    return createOrUpdateRefund(opts);
  },
  // 大交通列表
  fetchTraffic({
    commit,
    rootGetters
  }, opst) {
    opst.customerOrderId = opst.id || rootGetters['order/index/currentOrderId'];
    delete opst.id;
    return fetchTraffic(opst).then(res => {
      const {
        data
      } = res;
      return data || [];
    });
  },
  // 大交通添加
  createOrUpdateTraffic({
    dispatch
  }, opts) {
    return createOrUpdateTraffic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 大交通添加
  createOrUpdateTraffic2({
    dispatch
  }, opts) {
    return createOrUpdateTraffic2(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 大交通添加
  createOrUpdateTraffic3({
    dispatch
  }, opts) {
    return createOrUpdateTraffic3(opts);
  },
  // 保险添加/更新
  createOrUpdateInsurance({
    dispatch
  }, opts) {
    return createOrUpdateInsurance(opts).then(res => {
      return res;
    });
  },
  // 保险列表
  fetchInsurance({
    commit,
    rootGetters
  }, opts) {
    opts.customerOrderId = opts.id;
    return fetchInsurance(opts).then(res => {
      const {
        data
      } = res;
      return data || [];
    });
  },
  // 接送列表
  fetchPickup({
    commit,
    rootGetters
  }, id) {
    id = id || rootGetters['order/index/currentOrderId'];
    return fetchPickup(id).then(res => {
      const {
        data
      } = res;
      return data || [];
    });
  },
  // 接送详情
  fetchPickupDetail({
    commit
  }, id) {
    return fetchPickupDetail(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 接送（多条）
  createOrUpdatePickup({
    dispatch
  }, opts) {
    return createOrUpdatePickup(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 接送(单条)
  createOrShuttle({
    dispatch
  }, opts) {
    return createOrShuttle(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 游客列表
  fetchTourist({
    commit
  }, opts) {
    return fetchTourist(opts).then(res => {
      const {
        data
      } = res;
      return data || [];
    });
  },
  // 游客
  createOrUpdateTourist({
    commit
  }, opts) {
    return createOrUpdateTourist(opts).then(res => {
      return res;
    });
  },
  // 行程列表
  fetchItinerary({
    commit,
    rootGetters
  }, id) {
    id = id || rootGetters['order/index/currentOrderId'];
    return fetchItinerary(id).then(res => {
      const {
        data
      } = res;
      commit('ORDER_ITINERARY', data);
      commit('ORDER_ITINERARY_JOSN', JSON.stringify(data));
      return data || [];
    });
  },
  // 行程
  createOrUpdateItinerary({
    commit
  }, opts) {
    return createOrUpdateItinerary(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 行程删除
  deleteItinerary({
    commit
  }, id) {
    return deleteItinerary(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 行程删除 多条
  deleteItinerarys({
    commit
  }, opts) {
    return deleteItinerarys(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单行程删除
  delItineraryByOrderId({
    commit
  }, id) {
    return delItineraryByOrderId(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单行程景区删除
  deleteScenic({
    commit
  }, id) {
    return deleteScenic(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 行程安排大交通删除
  deleteTraffic({
    commit
  }, id) {
    return deleteTraffic(id);
  },
  // 删除大交通类型
  deleteTrafficType({
    commit
  }, id) {
    return deleteTrafficTypeRequest(id);
  },
  // 保险删除
  deleteInsurance({
    commit
  }, id) {
    return deleteInsurance(id).then(res => {
      return res;
    });
  },
  // 游客批量删除
  deleteTouriestList({
    commit
  }, ids) {
    return deleteTouriestList(ids).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 游客删除
  deleteTouriest({
    commit
  }, ids) {
    return deleteTouriest(ids).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 查询游客黑名单
  searchAndSaveUsers({
    commit
  }, opts) {
    return gthAndSaveUsers(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 更新黑 名单游客备注
  updateMembersRemarks({
    commit
  }, opts) {
    return updateMembersRemarks(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 查询根据游客ids查询信息
  getSearchByIds({
    commit
  }, opts) {
    return getSearchByIds(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除游客黑名单
  delBlacklistTouriest({
    commit
  }, opts) {
    return delBlacklistTouriest(opts).then(res => {
      return res;
    });
  },
  // 备注列表
  fetchRemarks({
    commit
  }, id) {
    return fetchRemarks(id).then(res => {
      const {
        data
      } = res;
      // commit('CUR_REMARKS', data)
      return data || [];
    });
  },
  // 备注
  createOrUpdateRemarks({
    commit
  }, opts) {
    return createOrUpdateRemarks(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 确认成团
  confirmOrder({
    commit
  }, id) {
    return confirmTeam(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单删除
  deleteOrder({
    commit
  }, id) {
    return deleteOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 返款删除
  deleteRefund({
    commit
  }, id) {
    return deleteRefund(id).then(res => {
      // const { data } = res
      return res;
    });
  },
  // 团款删除
  deleteFund({
    commit
  }, id) {
    return deleteFund(id).then(res => {
      return res;
    });
  },
  // 代收删除
  deleteProxy({
    commit
  }, id) {
    return deleteProxy(id).then(res => {
      return res;
    });
  },
  // 代收删除
  deletePickup({
    commit
  }, id) {
    return deletePickup(id).then(res => {
      return res;
    });
  },
  // 退单
  revoke({
    commit
  }, opts) {
    return revoke(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 取消计划
  cancelPlan({
    commit
  }, id) {
    return cancelPlan(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单日志
  fetchPlanLog({
    commit
  }, opts) {
    opts = {
      ...opts,
      type: 1
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单审核列表
  fetchApplyAudit({
    commit
  }, opts) {
    return fetchApplyAudit(opts).then(res => {
      let {
        data
      } = res;
      commit('ORDER_AUDIT', data);
      return data;
    });
  },
  // 订单审核列表审核
  fetchAuditStatus({
    commit
  }, opts) {
    return fetchAuditStatus(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单审核列表审核
  fetchApplyAuditList({
    commit
  }, opts) {
    return fetchApplyAuditList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单审核列表取消审核
  fetchCancelAudit({
    commit
  }, opts) {
    return fetchCancelAudit(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 根据订单id 获取订单修改信息以及进度
  fetchApplyAuditById({
    commit
  }, opts) {
    return fetchApplyAuditById(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 返款是否冲抵 未收
  unDeductSubsidy({
    commit
  }, opts) {
    return unDeductSubsidy(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 根据订单id 获取订单团审核信息
  getPlanObjectStatus({
    commit
  }, params) {
    return getPlanObjectStatus(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      commit('PLAN_AUDIT_STATUS', data.auditStatus);
    });
  },
  // 保存订单景区列表
  createOrScenic({
    commit
  }, opts) {
    return createOrScenic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单景区列表
  fetchScenic({
    commit
  }, opts) {
    const {
      id: customerOrderId
    } = opts;
    return fetchScenic({
      customerOrderId
    }).then(res => {
      const {
        data
      } = res;
      commit('ORDER_SCENIC', data);
      return data;
    });
  },
  // 订单景区删除
  deleteOrScenic({
    commit
  }, id) {
    return deleteOrScenic(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单景区批量删除
  deleteOrScenicAll({
    commit
  }, opts) {
    return deleteOrScenics(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单景区类型删除
  deleteOrScenicTicket({
    commit
  }, opts) {
    return deleteOrScenicTicket(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 餐厅保存
  createOrRestaurant({
    commit
  }, opts) {
    return createOrRestaurant(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 订单餐厅列表
  fetchRestaurant({
    commit
  }, opts) {
    opts = {
      ...opts,
      orderId: opts.id
    };
    return fetchRestaurant(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 餐厅类型删除
  deleteRestaurantType({
    commit
  }, opts) {
    return deleteRestaurantType(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 餐厅删除
  deleteRestaurant({
    commit
  }, opts) {
    return deleteRestaurant(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 车队保存
  createOrBus({
    commit
  }, opts) {
    return createOrBus(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 车队获取
  fetchOrBus({
    commit
  }, opts) {
    opts = {
      ...opts,
      orderId: opts.id
    };
    return fetchOrBus(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 车队类型删除
  deleteBusType({
    commit
  }, opts) {
    return deleteBusType(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 车队删除
  deleteBus({
    commit
  }, opts) {
    return deleteBus(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  copyOrder({
    commit
  }, id) {
    return copyOrderRequest(id);
  },
  uploadConfirmFile({
    commit
  }, opt) {
    return confirmFileRequest(opt);
  }
};
const mutations = {
  ORDER_HOTEL: (state, data) => {
    state.orderHotel = data;
  },
  ORDER_ALL: (state, data) => {
    state.orderAllData = data;
  },
  ORDER_ALL2: (state, data) => {
    state.orderAllData2 = data;
  },
  PLAN_ALL: (state, data) => {
    state.planAllData = data;
  },
  CUR_ORDER: (state, data) => {
    state.currentOrder = data;
  },
  CLEAR_ORDER: (state, type = 'currentOrder') => {
    state[type] = null;
  },
  CUR_ORDER_ID: (state, id) => {
    state.currentOrderId = id;
  },
  CLEAR_ORDER_ID: state => {
    state.currentOrderId = null;
  },
  CUR_ORDER_LIST: (state, {
    type,
    data
  }) => {
    state[type + 'Data'] = data;
    state.currentOrderList = data;
  },
  NEED_RELOAD: (state, {
    type,
    flag
  }) => {
    state.needReload[type] = flag;
  },
  TEAM_FUND: (state, data) => {
    state.teamFundMoney = data;
  },
  CUR_ORDER_TYPE: (state, type) => {
    state.orderType = type;
  },
  ORDER_AUDIT: (state, type) => {
    state.orderAuditData = type;
  },
  CUR_CUSTOMER_ID: (state, customerId) => {
    state.customerId = customerId;
  },
  ORDER_AUDIT_REF: (state, auditPageRefresh) => {
    state.auditPageRefresh = auditPageRefresh;
  },
  PLAN_AUDIT_STATUS: (state, status) => {
    state.planAuditStatus = status;
  },
  ORDER_SCENIC: (state, status) => {
    state.orderScenices = status;
  },
  ORDER_ITINERARY: (state, status) => {
    state.orderItinerary = status;
  },
  ORDER_ITINERARY_JOSN: (state, status) => {
    state.orderItineraryJson = status;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};