var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "修改人数",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "input-row"
  }, [_c("InputPeopleCount", {
    attrs: {
      "is-require": "",
      adult: _vm.form.adultCount,
      child: _vm.form.childCount,
      accompany: _vm.form.accompanyCount
    },
    on: {
      "update:adult": function ($event) {
        return _vm.$set(_vm.form, "adultCount", $event);
      },
      "update:child": function ($event) {
        return _vm.$set(_vm.form, "childCount", $event);
      },
      "update:accompany": function ($event) {
        return _vm.$set(_vm.form, "accompanyCount", $event);
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };