import "core-js/modules/es.array.push.js";
import TableUnPay from './components/TableUnPay';
import { formatCommitTableData } from '@/utils/data';
import { FooterBar, SelectUnit, BillInput } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { TYPES } from '@/config/billDown';
export default {
  components: {
    FooterBar,
    SelectUnit,
    BillInput,
    TableUnPay
  },
  mixins: [tagViewMixin],
  data() {
    return {
      TYPES,
      money: 0,
      resourceId: '',
      backPath: '/bill/unit-pay/other'
    };
  },
  mounted() {
    const {
      rid
    } = this.$route.query;
    this.resourceId = rid;
  },
  methods: {
    // 修改资源
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.unPayRef.fetchData({
        resourceId
      });
    },
    changeMoney(money) {
      this.money = money;
      this.$refs.unPayRef.caclDownMoney(money);
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    processData(form) {
      const {
        cashWay,
        cashUserId,
        money
      } = form;
      const tableData = this.$refs.unPayRef.tableData;
      const list = tableData.filter(it => it.isChecked);
      const cashs = list.map(it => {
        return {
          cash: it.downPayed,
          cashWay,
          cashUserId,
          orderId: it.rowId,
          // 订单id， 其他为资源id
          type: it.type ? it.type : 'NONE'
        };
      });
      let opts = {
        ...form,
        money,
        cashType: TYPES.other_pay,
        cashes: formatCommitTableData(cashs)
      };
      delete opts.subject;
      opts.cashes = opts.cashes.map(it => {
        return {
          ...it,
          reimbedUnitType: 'OTHER'
        };
      });
      return opts;
    },
    // 保存
    handleSave() {
      this.$refs.formRef.handleValidation().then(form => {
        const loading = this.$bus.loading();
        const opts = this.processData(form);
        this.$store.dispatch('bill/billDown/pay/createPay', opts).then(() => {
          loading.close();
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};