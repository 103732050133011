var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "top-start",
      title: _vm.title,
      width: "240",
      trigger: "hover",
      content: _vm.content
    }
  }, [_c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };