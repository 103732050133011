var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt20 area-index"
  }, [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "60px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputMonthRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "查询时间"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.changeArea
    },
    model: {
      value: _vm.searchParams.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerArea", $$v);
      },
      expression: "searchParams.customerArea"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": function ($event) {
        return _vm.exportExcel("区域分析表", "export-area-table");
      }
    }
  })], 1)]), _c("TableList", {
    ref: "tableRef",
    staticClass: "mt20",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.tableData.length ? _c("div", [_c("div", {
    style: {
      width: "100%",
      height: "800px"
    },
    attrs: {
      id: "myChart"
    }
  })]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };