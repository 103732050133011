/* eslint-disable no-unused-vars */
// 公告
import { createNotice, fetchNotice, updateNotice, deleteNotice, fetchNoticeDetail, fetchNoticeRead, fetchNoticeUnRead, setNoticeRead } from '@/api/system/notice';
import { formatCreateTime } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  noticeData: null,
  noticeDetail: null,
  noticeReadList: null
});
const state = dfaultState();
const getters = {
  noticeData: state => state.noticeData,
  noticeDetail: state => state.noticeDetail,
  noticeReadList: state => state.noticeReadList
};
const actions = {
  // 获取公告
  fetch({
    commit
  }, queryParams) {
    queryParams = {
      ...DEFAULT_PAGE,
      ...queryParams
    };
    return fetchNotice(queryParams).then(res => {
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        it = formatCreateTime(it);
        it.authAreaType = it.authAreaType == 'all' ? '全部' : '指定部门';
        return it;
      });
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 获取公告详情
  fetchDetail({
    commit
  }, id) {
    return fetchNoticeDetail(id).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DETAIL', data);
      return data;
    });
  },
  // 添加公告
  create({
    commit
  }, opts) {
    return createNotice(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 更新公告
  update({
    commit
  }, opts) {
    return updateNotice(opts);
  },
  // 删除公告
  delete({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      return deleteNotice(id).then(resolve).catch(reject);
    });
  },
  // 获取通知公告 已读
  fetchNoticeRead({
    commit
  }, param) {
    return new Promise((resolve, reject) => {
      return fetchNoticeRead(param).then(res => {
        if (!res) return;
        resolve(res.data);
      }).catch(reject);
    });
  },
  // 获取通知公告
  fetchNoticeUnRead({
    commit
  }, param) {
    return new Promise((resolve, reject) => {
      return fetchNoticeUnRead(param).then(res => {
        if (!res) return;
        const {
          data
        } = res;
        commit('NOTICE_LIST', data);
        resolve(data);
      }).catch(reject);
    });
  },
  // 修改通知公告状态
  setNoticeRead({
    commit
  }, param) {
    return new Promise((resolve, reject) => {
      return setNoticeRead(param).then(resolve).catch(reject);
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.noticeData = data;
  },
  FETCH_DETAIL: (state, data) => {
    state.noticeDetail = data;
  },
  NOTICE_LIST: (state, data) => {
    state.noticeReadList = data;
  },
  READ_NOTICE: (state, id) => {
    const filterList = state.noticeReadList.filter(it => it.id != id);
    state.noticeReadList = filterList;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};