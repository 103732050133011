import "core-js/modules/es.array.push.js";
import { Search, DialogDetail, DialogDDApply } from './components';
import { BtnLink, StatusSend, StatusAuditProgress, DialogLog, TypeOrder, PersonCount } from '@/components/common';
import { LOG_YPES } from '@/config/resource';
import { Columns } from './tableConfig';
import { StatisticsKeys } from './config';
import { mapGetters } from 'vuex';
import { summaryMixin } from '@/mixin';
import { GUIDE_REIMB_STATUS, EnterpriseCodeType } from '@/config';
import { parseDate, processAdd, processMinus } from '@/utils';
import { EnterpriseCodeStatus, EnterpriseCodeTagType } from '@/config/enterprise';
export default {
  components: {
    Search,
    DialogDetail,
    DialogDDApply,
    PersonCount,
    BtnLink,
    TypeOrder,
    StatusSend,
    StatusAuditProgress,
    DialogLog
  },
  mixins: [summaryMixin],
  data() {
    return {
      Columns,
      StatisticsKeys,
      EnterpriseCodeType,
      // tableData: [],
      statisticsKeys: ['totalAmount']
    };
  },
  computed: {
    ...mapGetters({
      data: 'enterprise/guide/enterpriseData'
    }),
    hasApplies() {
      return list => list && list.length > 0;
    },
    tableData() {
      const data = this.data;
      return data ? this.formatData(data.list) : [];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    statistics() {
      const data = this.data;
      return data ? this.formatData([data.statistics])[0] : null;
    },
    getText() {
      return arr => {
        return arr && arr.length ? '增加额度' : '申请额度';
      };
    },
    getGuideAliECodeStatus() {
      return row => EnterpriseCodeStatus[row.alipayEcodeStatus];
    },
    getGuideAliECodeType() {
      return row => EnterpriseCodeTagType[row.alipayEcodeStatus];
    },
    invitedStatus() {
      return row => {
        return row && row.alipayEcodeStatus > 0;
      };
    },
    inviteStatus() {
      return (row, val) => {
        return row && row.alipayEcodeStatus == val;
      };
    }
  },
  watch: {
    data: {
      handler: function (d) {
        this.$nextTick(() => {
          this.initData(d);
        });
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: parseDate()
        });
      }
    },
    fetchData(params = {}) {
      this.$store.dispatch('enterprise/guide/getList', {
        ...this.$refs.searchRef.searchParams,
        ...params
      });
    },
    formatData(list) {
      return list.map(it => {
        const {
          hotelReimb,
          restaurantReimb,
          busCompanyReimb,
          scenicReimb,
          insuranceReimb,
          otherPayReimb,
          transportCompanyReimb,
          payAmount,
          // 已用额度
          totalAmount // 支付宝企业码付总额度
        } = it;
        it.$reimbStatus = GUIDE_REIMB_STATUS[it.reimbStatus];

        // 总现付
        const total = processAdd(hotelReimb, restaurantReimb, busCompanyReimb, scenicReimb, insuranceReimb, otherPayReimb, transportCompanyReimb);
        // 剩余额度 = 总额度-已付
        const limit = processMinus(totalAmount, payAmount);
        it.$surplusLimit = limit;

        // 现付总额
        it.$totalPay = processAdd(total, payAmount);

        // 剩余未付
        // const unPay = processMinus(total, payAmount)
        it.$unPay = total;

        // 差额估算 = 剩余未付-剩余额度
        it.$diff = processMinus(total, limit);
        return it;
      });
    },
    addQuote(row) {
      this.$refs.dialogApplyRef.show(row);
    },
    clearQuote(row) {
      this.$bus.open(() => {
        const {
          id
        } = row;
        this.$store.dispatch('enterprise/guide/delQuotaByPlanId', id).then(() => {
          this.$bus.tip();
        });
      }, '是否确定清除本团的额度?');
    },
    handleLog(row) {
      const types = `${LOG_YPES.plan},${LOG_YPES.audit}`;
      const {
        id: itemId
      } = row;
      // this.$store
      //   .dispatch('plan/index/fetchPlanLog', {
      //     itemId,
      //     subType: 'guideBorrowApply',
      //     subItemId: '',
      //   })
      //   .then(res => {
      //     this.$refs.logRef.show(res)
      //   })
      this.$store.dispatch('plan/index/fetchPlanLogs3', {
        itemIds: itemId,
        types,
        subTypes: 'alipayECode',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handleDetail(row) {
      this.$refs.dialogDetailRef.show(row);
    },
    toggleECodeQuota({
      id: planId
    }, status) {
      const msg = status ? '确定要启用？' : '确定要停用？';
      this.$bus.open(() => {
        const opt = {
          planId,
          status
        };
        this.$store.dispatch('enterprise/guide/toggleEnableQuota', opt).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      }, msg);
    },
    linkDetail(row) {
      const {
        path
      } = this.$route;
      const toPath = path;
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/FIRST_ENTER', true);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType,
          toPath
        }
      });
    },
    removeECode({
      guideId
    }) {
      this.$bus.open(() => {
        this.$store.dispatch('enterprise/guide/delInvite', {
          guideId
        }).then(() => {
          this.$bus.tip({
            message: '操作成功！'
          });
          this.fetchData();
        });
      }, '确定要移除导游企业码状态吗？');
    },
    inviteECode({
      guideId
    }) {
      this.$store.dispatch('enterprise/guide/addInvite', {
        guideId
      }).then(res => {
        if (!res) return;
        console.log('res.data:', res.data);
        const {
          signUrl
        } = res.data;
        this.$refs.dialogCopyRef.show(signUrl);
      });
    }
  }
};