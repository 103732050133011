import { tableMixin, summaryMixin } from '@/mixin';
import { orderAuditStatus } from '@/config/report';
export default {
  mixins: [tableMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['number', 'countSlot', 'total']
    };
  },
  methods: {
    orderAuditStatus({
      row
    }) {
      const {
        auditProgress
      } = row;
      const item = orderAuditStatus.filter(it => it.value == auditProgress)[0];
      return item.label;
    }
  }
};