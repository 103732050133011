import service from '@/utils/request';

/**
 * 新增或更新公司发票
 * @param {object} data 公司发票信息
 */
export function createOrUpdateInvoice(data) {
  return service({
    url: '/system/trip/saveOrUpdateInvoice',
    method: 'post',
    data
  });
}

// 删除发票
export function deleteInvoice(id) {
  return service({
    url: '/system/trip/delInvoice',
    method: 'post',
    data: {
      invoiceId: id
    }
  });
}

// 发票列表
export function fetchInvoiceList({
  pageNum = 1,
  pageSize = 50
}) {
  return service({
    url: '/system/trip/listInvoice',
    method: 'post',
    data: {
      pageNum,
      pageSize
    }
  });
}