import handleClipboard from '@/utils/clipboard';
export default {
  name: 'DialogLinkCopy',
  data() {
    return {
      visible: false,
      inviteLink: ''
    };
  },
  methods: {
    show(link) {
      this.visible = true;
      this.inviteLink = link;
    },
    hide() {
      this.visible = false;
    },
    onCopy(event) {
      handleClipboard(this.inviteLink + '', event);
    },
    beforeClose(done) {
      this.$bus.open(() => {
        done();
      }, '请确认已复制成功');
    }
  }
};