// waitQuotation-待报价，quotation-已报价， update-修改中,可用值:UPDATE,QUOTATION,WAIT_QUOTATION
export const InquireStatus = {
  UPDATE: 'UPDATE',
  QUOTATION: 'QUOTATION',
  WAIT_QUOTATION: 'WAIT_QUOTATION'
};
export const InquireStatusLabel = {
  UPDATE: '修改中',
  QUOTATION: '已报价',
  WAIT_QUOTATION: '待报价'
};
export const InquireStatusType = {
  UPDATE: 'success',
  QUOTATION: 'warning',
  WAIT_QUOTATION: 'danger'
};