export default {
  props: {
    val: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    getValue() {
      return (this.val + '').split('');
    }
  }
};