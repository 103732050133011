var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "在线预定资源",
      width: "1200px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("FormBase", {
    attrs: {
      inline: "",
      "label-width": "100px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "region",
      label: "大团号"
    }
  }, [_c("div", {
    staticStyle: {
      width: "190px"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentOrder && _vm.currentOrder.planCustomerNumber) + " ")])]), _c("FormItemBase", {
    attrs: {
      prop: "region",
      label: "大团号"
    }
  }, [_c("div", {
    staticStyle: {
      width: "190px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getPeopleCount) + " ")])])], 1), _c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "100px",
      inline: ""
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "region",
      label: "客源地"
    }
  }, [_c("SelectRegion", {
    attrs: {
      props: {
        expandTrigger: "hover",
        checkStrictly: true
      },
      label: ""
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "code",
      label: "预定中心"
    }
  }, [_c("InputResource", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.hotelData.length
    },
    model: {
      value: _vm.checkHotel,
      callback: function ($$v) {
        _vm.checkHotel = $$v;
      },
      expression: "checkHotel"
    }
  }, [_c("strong", [_vm._v("酒店")])])], 1), _c("HotelTable", {
    attrs: {
      data: _vm.hotelData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.restaurantData.length
    },
    on: {
      change: _vm.changeCheckbox
    },
    model: {
      value: _vm.checkRestaurant,
      callback: function ($$v) {
        _vm.checkRestaurant = $$v;
      },
      expression: "checkRestaurant"
    }
  }, [_c("strong", [_vm._v("餐厅")])])], 1), _c("RestaurantTable", {
    attrs: {
      data: _vm.restaurantData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.fleetData.length
    },
    on: {
      change: _vm.changeCheckbox
    },
    model: {
      value: _vm.checkFleet,
      callback: function ($$v) {
        _vm.checkFleet = $$v;
      },
      expression: "checkFleet"
    }
  }, [_c("strong", [_vm._v("车队")])])], 1), _c("FleetTable", {
    attrs: {
      data: _vm.fleetData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("el-checkbox", {
    attrs: {
      disabled: !_vm.scenicData.length
    },
    on: {
      change: _vm.changeCheckbox
    },
    model: {
      value: _vm.checkScenic,
      callback: function ($$v) {
        _vm.checkScenic = $$v;
      },
      expression: "checkScenic"
    }
  }, [_c("strong", [_vm._v("景区")])])], 1), _c("ScenicTable", {
    attrs: {
      data: _vm.scenicData,
      "show-title": false
    }
  })], 1), _c("div", {
    staticClass: "table-row"
  }, [_c("div", {
    staticClass: "table-row-box"
  }, [_c("strong", {
    staticStyle: {
      "margin-left": "22px"
    }
  }, [_vm._v("导游")])]), _c("div", {
    staticClass: "booking-item"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    staticClass: "flex-1",
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.guideRemark,
      callback: function ($$v) {
        _vm.guideRemark = $$v;
      },
      expression: "guideRemark"
    }
  })], 1)])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };