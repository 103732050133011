import "core-js/modules/es.array.push.js";
import { resourceAgreementHotel } from '@/config/resource';
import { rowspanMixin } from '@/mixin';
import { findComponentsUpward } from '@/utils';
const itemListKeys = () => ({
  id: '',
  freeCount: 0,
  freeCountMinRequire: 0
});
const defaultState = () => ({
  contentType: '',
  price: 0,
  quotedPrice: 0,
  contractPriceFrees: 0,
  remarks: '',
  isCustom: true
});
export default {
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: resourceAgreementHotel,
      rowId: 1000000000000,
      headMergeNum: 3,
      tailMergeNum: resourceAgreementHotel.length - 3,
      hasMergeTail: true,
      form: {}
    };
  },
  methods: {
    edit(id, type, data) {
      this.visible = true;
      this.form.resourceType = type;
      this.form.contracId = id;
      // this.fetchData()
      this.tableData = this.formatData(data);
      this.rowId += data.length;
      this.rowspan();
    },
    hide() {
      this.visible = false;
    },
    addRow() {
      this.tableData.push({
        ...defaultState(),
        rowId: ++this.rowId
      });
      this.rowspan();
    },
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于 0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.deleteReduction(id) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.rowspan();
    },
    fetchData() {
      const id = this.form.contracId;
      this.$store.dispatch('resource/common/fetchAgreementDetail', id).then(data => {
        this.tableData = this.formatData(data.contractPrices);
        this.rowId += data.contractPrices.length;
        this.rowspan();
      });
    },
    // 删除协议减免
    deleteReduction(id) {
      this.$store.dispatch('resource/common/delPriceFeeAgreement', {
        id
      }).then(() => {
        const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
        findComps.fetchData(this.form.contracId);
      });
    },
    // 添加价格
    addItem(opts) {
      return this.$store.dispatch('resource/common/addAgreementItem', opts);
    },
    // 删除价格
    deleteItem(id) {
      this.$store.dispatch('resource/common/delAgreementItem', id).then(() => {
        const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
        findComps.fetchData(this.form.contracId);
      });
    },
    handleDelete(item) {
      const {
        rowId: id
      } = item;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    handleConfirm(opts) {
      if (!this.validateForm()) return;
      const list = this.beforeCommit(this.tableData);
      opts.contractPrices = list;
      const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0];
      findComps.createOrUpdateAgr(opts);
    },
    formatData(list) {
      const result = [];
      if (!list || !list.length) return result;
      list.forEach((it, idx) => {
        const itemList = it.contractPriceFrees || [];
        const item = {
          rowId: it.id,
          ...it
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            itemIndex: idx
          });
        }
      });
      return result;
    },
    beforeCommit(list) {
      const result = [];
      if (!list || !list.length) return result;
      let last = null;
      const {
        contracId,
        resourceType
      } = this.form;
      list.forEach(it => {
        const keys = Object.keys(itemListKeys());
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        it.id = it.rowId;
        it.contracId = contracId;
        it.resourceType = resourceType;
        it.contractPrices = [];
        delete it.contractPriceFrees;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete item.id;
        }

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.contractPrices.push(item);
        } else {
          it.contractPrices.push(item);
          result.push(it);
        }
        last = it;
      });
      return result;
    },
    validateForm() {
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i];
        const {
          contentType,
          price
        } = item;
        if (!contentType) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容'
          });
          return false;
        }
        if (price === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写价格'
          });
          return false;
        }
        return true;
      }
    }
  }
};