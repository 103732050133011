import { SELECTOR } from '@/config';
import { inputMixin, disabledMixin } from '@/mixin';

/**
 * @module components/base/Select
 * @desc 搜索数据的各种下拉框的基础组件
 * @vue-prop {String} label 下拉框类型的名字
 * @vue-prop {String} type 下拉数据配置的类型（在config/index.js中统一配置，使用时候对应查看类型的名字）
 */
export default {
  name: 'SelectBase',
  mixins: [inputMixin, disabledMixin],
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '全部'
    },
    initVal: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isFirst: true,
      curVal: this.$attrs.value,
      data: [],
      tempData: []
    };
  },
  computed: {
    getTypeList() {
      return this.tempData;
    }
  },
  watch: {
    list: {
      handler: function (data) {
        this.data = data.length > 0 ? data : SELECTOR[this.type];
      },
      immediate: true
    },
    initVal: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(val) {
      val = val || this.$attrs.value;
      const item = this.data.find(it => it.value == val);
      this.curVal = item ? item.label : val;
    },
    handleChange(val) {
      const item = this.data.find(it => it.value == val);
      this.$emit('on-select', item);
    },
    lazyLoad() {
      if (!this.isFirst) return;
      this.isFirst = false;
      this.tempData = this.list.length > 0 ? this.list : SELECTOR[this.type];
    }
  }
};