var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("table", {
    staticClass: "table-tpl"
  }, [_c("tbody", [_vm.title ? _c("tr", {
    staticClass: "row-title"
  }, [_c("td", {
    attrs: {
      colspan: "24"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.title))])])]) : _vm._e(), _vm._l(_vm.columnData, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(item.rows, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        attrs: {
          width: col.width ? col.width : "40px"
        }
      }, [col.slot ? [_vm._t(col.slot)] : [_vm._v(" " + _vm._s(col.label ? col.label : _vm.data[col.content]) + " ")]], 2)];
    })], 2)];
  })], 2)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };