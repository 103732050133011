var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "960px",
      title: _vm.getTitle,
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("h3", {
    staticClass: "pr10"
  }, [_vm._v(" 结算客户列表 ")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handlePlus
    }
  }, [_vm._v(" 增加客户结算价 ")])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": "",
      "header-cell-style": {
        background: "#eee"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      align: _vm.align,
      "min-width": "120px",
      label: "客户"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [row.edit ? [_c("SelectCustom", {
          attrs: {
            "init-val": row.customerName,
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectCustom(row, $event);
            }
          },
          model: {
            value: row.customerName,
            callback: function ($$v) {
              _vm.$set(row, "customerName", $$v);
            },
            expression: "row.customerName"
          }
        })] : _c("span", [_vm._v(_vm._s(row.customerName))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: _vm.align,
      "min-width": "80px",
      label: "结算价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [row.edit ? [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: row.price,
            callback: function ($$v) {
              _vm.$set(row, "price", $$v);
            },
            expression: "row.price"
          }
        })] : _c("span", [_vm._v(_vm._s(row.price))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: _vm.align,
      "min-width": "120px",
      label: "备注"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [row.edit ? [_c("InputBase", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: row.remarks,
            callback: function ($$v) {
              _vm.$set(row, "remarks", $$v);
            },
            expression: "row.remarks"
          }
        })] : _c("span", [_vm._v(_vm._s(row.remarks))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: _vm.align,
      label: "操作",
      "min-width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [row.edit ? _c("el-button", {
          attrs: {
            type: "success",
            icon: "el-icon-circle-check-outline",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.confirmEdit(row);
            }
          }
        }, [_vm._v(" 确定 ")]) : _vm._e(), row.edit && row.isUpdate ? _c("el-button", {
          attrs: {
            type: "warning",
            icon: "el-icon-refresh",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.cancelEdit(row);
            }
          }
        }, [_vm._v(" 取消 ")]) : _vm._e(), !row.edit ? _c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-edit",
            plain: ""
          },
          on: {
            click: function ($event) {
              row.edit = !row.edit;
            }
          }
        }, [_vm._v(" 编辑 ")]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            plain: ""
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(row, $index);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };