import "core-js/modules/es.array.reduce.js";
import TopTitle from '../components/TopTitle';
import { parseTime } from '@/utils';
// 应收团款  购物店
const guideShopPlansHead = [{
  content: 'name',
  label: '购物店',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '购物店名',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '商品',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '打单金额',
  span: 2,
  rowspan: 1
}, {
  content: 'redEnvelope',
  label: '红包',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '人头返佣',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '收入比例',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '折扣金额',
  span: 4,
  rowspan: 1
}];
// 应收团款  其他收入
const otherIncomeHead = [{
  content: 'name',
  label: '其他收入',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '项目',
  span: 8,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '金额',
  span: 4,
  rowspan: 1
}];
// 加点收入
const scenicAddPlansHead = [{
  content: 'name',
  label: '加点收入',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '景区',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '门票类别',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '人数',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 成本明细  车费
const busCompanyPlansHead = [{
  content: 'name',
  label: '车费',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '车辆公司',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '车辆类型',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '车型',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '车费',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 房费
const hotelPlansHead = [{
  content: 'name',
  label: '房费',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '房间类型',
  span: 3,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 3,
  rowspan: 1
}, {
  content: '',
  label: '免去',
  span: 3,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 3,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 景区成本
const scenicPlansHead = [{
  content: 'name',
  label: '景区成本',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '门票类别',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '成人数',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '成人价',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '免票数',
  span: 1,
  rowspan: 1
}, {
  content: '',
  label: '儿童数',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '儿童价',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '全陪数',
  span: 1,
  rowspan: 1
}, {
  content: '',
  label: '全陪价',
  span: 1,
  rowspan: 1
}, {
  content: '',
  label: '其他成本',
  span: 1,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 2,
  rowspan: 1
}];
// 保险成本
const insurancePlansHead = [{
  content: 'name',
  label: '保险',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '保险类型',
  span: 8,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 餐
const restaurantPlansHead = [{
  content: 'name',
  label: '餐',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '餐饮类型',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '免去',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 其他支出
const otherPayPlansHead = [{
  content: 'name',
  label: '其他支出',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '价位',
  span: 6,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 6,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 大交通
const transportPlansHead = [{
  content: 'name',
  label: '大交通',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '类型',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '日期',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '班次',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '始发地',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '目的地',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '单价',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 2,
  rowspan: 1
}, {
  content: '',
  label: '金额',
  span: 2,
  rowspan: 1
}];
// 加点成本
const addCostHead = [{
  content: 'name',
  label: '加点成本',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '供应商',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '门票类别',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '数量',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '价格',
  span: 4,
  rowspan: 1
}, {
  content: '',
  label: '合计金额',
  span: 4,
  rowspan: 1
}];
// 底部
const footerHead = [[{
  content: '',
  label: '导游佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'shopGuideRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '司机佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'shopDriveRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '全陪佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'shopAccompanyRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '导管佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'guideManagerRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '导游加点佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'addGuideRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '司机加点佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'addDriveRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '全陪加点佣金',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'addAccompanyRebate',
  label: '',
  span: 4
}], [{
  content: '',
  label: '导服',
  span: 4
}, {
  content: '',
  label: '',
  span: 16
}, {
  content: 'totalGuideServiceFeePay',
  label: '',
  span: 4
}]];
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      guideShopPlansHead,
      otherIncomeHead,
      scenicAddPlansHead,
      busCompanyPlansHead,
      hotelPlansHead,
      scenicPlansHead,
      insurancePlansHead,
      restaurantPlansHead,
      otherPayPlansHead,
      transportPlansHead,
      addCostHead,
      footerHead,
      addCost: [],
      show: false,
      data: {
        bucCompanyPlans: []
      },
      teamFund: [],
      addIncome: []
    };
  },
  computed: {
    getTimeFomat() {
      return val => {
        return parseTime(val);
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/print/fetchGuideSubCommission', id).then(data => {
        this.data = data;
        this.guideShopPlansHead[0].rowspan = data.guideShopPlans.length + 1;
        this.otherIncomeHead[0].rowspan = data.otherIncomePlans.length + 1;
        this.scenicAddPlansHead[0].rowspan = data.scenicAddPlans.reduce((acc, cur) => {
          acc += cur.tickets.length;
          return acc;
        }, 1);
        this.hotelPlansHead[0].rowspan = data.hotelPlans.length + 1;
        this.scenicPlansHead[0].rowspan = data.scenicPlans.length + 1;
        this.restaurantPlansHead[0].rowspan = data.restaurantPlans.length + 1;
        this.otherPayPlansHead[0].rowspan = data.otherPayPlans.length + 1;
        this.transportPlansHead[0].rowspan = data.transportPlans.length + 1;
        this.addCost = data.scenicAddPlans;
        this.addCostHead[0].rowspan = data.scenicAddPlans.length + 1;

        // 过滤车队
        data.busCompanyPlans = data.busCompanyPlans.filter(it => it.busCompanyName !== null);
        this.busCompanyPlansHead[0].rowspan = data.busCompanyPlans.length + 1;

        // this.footerHead.guide[2].label = data.guideRebate
        // this.footerHead.bus[2].label = data.driveRebate
        // this.footerHead.accompanyCount[2].label = data.accompanyRebate
        // this.footerHead.guideServe[2].label = data.totalGuideServiceFeePay
        this.show = true;
        this.$emit('onMounted');
      });
    }
  }
};