import "core-js/modules/es.array.push.js";
import DialogTourist from './DialogTourist';
import DialogBlackTourist from './DialogBlackTourist';
import { TopTitle, TouristAge } from '@/components/common';
import { DelCheckAll } from '@/components/common';
import UploadExcelComponent from '@/components/UploadExcel';
import { orderTouristList } from '@/config/order';
import { selectorMixin, rowspanMixin, reloadMixin } from '@/mixin';
import { isPhone } from '@/utils';
// import { validHKM, validPassport } from '@/utils/validate'
import { validCard, getIdCardInfo } from '@/utils/idcard';
import { mapGetters } from 'vuex';
import { SELECTOR } from '@/config/select';
import tableMixin from '../tableMixin';
const defaultState = () => ({
  type: '',
  typeId: 0,
  cardType: '身份证',
  name: '',
  mobilePhone: '',
  cardNumber: '',
  location: '',
  age: 0,
  sex: 0,
  isContact: false,
  remarks: '',
  isCustom: true
});
export default {
  components: {
    TopTitle,
    TouristAge,
    UploadExcelComponent,
    DelCheckAll,
    DialogTourist,
    DialogBlackTourist
  },
  mixins: [selectorMixin, rowspanMixin, reloadMixin, tableMixin],
  data() {
    return {
      tableExcelData: [],
      tableHeader: [],
      removeCache: [],
      tableData: [],
      columnData: orderTouristList,
      seqNumber: 0,
      inputType: 'manual',
      radios: [{
        value: '男',
        label: 0
      }, {
        value: '女',
        label: 1
      }],
      sexList: SELECTOR.sex,
      deletePath: 'order/index/deleteTouriestList',
      rowId: 10000
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder'
    }),
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    },
    isMobileError() {
      return val => {
        if (val) {
          return isPhone(val) ? '' : 'is-error';
        }
        return '';
      };
    },
    isNumberError() {
      return item => {
        return this.validateCardNumber(item) ? '' : 'is-error';
      };
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const id = this.currentOrderId;
      this.$store.dispatch('order/index/fetchTourist', {
        id
      }).then(data => {
        this.fixTouristData(data);
        this.tableData = data.map(it => {
          // it.cardType = Number(it.cardType) || '身份证'
          it.rowId = it.id;
          this.$set(it, 'isChecked', false);
          this.rowId++;
          return it;
        });
        this.seqNumber = data.length;
      });
    },
    reloadData() {
      this.fetchData();
    },
    initData(data) {
      this.tableData = [];
      this.tableData = data.map((it, index) => {
        it.cardType = Number(it.cardType) || 0;
        it.rowId = it.id;
        it.index = index;
        return it;
      });
      this.seqNumber = data.length;
    },
    handlePlus() {
      this.inputType = 'manual';
      this.seqNumber++;
      const isContact = this.seqNumber === 1 ? true : false;
      this.tableData.push({
        ...defaultState(),
        seqNumber: this.seqNumber,
        isContact
      });
    },
    // 删除单条
    handleRemove(scope, isBatch = true) {
      const {
        row,
        $index: index
      } = scope;
      const {
        rowId: id,
        isCustom
      } = row;
      const opts = isBatch ? [id] : id;
      if (!isCustom) {
        this.$bus.open(() => {
          this.handleRomoteRemove(opts);
        });
      } else {
        this.tableData.splice(index, 1);
        this.$bus.tip({
          message: '删除成功！'
        });
      }
    },
    // 远程删除
    handleRomoteRemove(ids) {
      // 获取删除的数据
      const removeList = this.tableData.filter(it => ids.includes(it.rowId));
      if (!ids.length) return;
      const loading = this.$bus.loading();
      this.$store.dispatch(this.deletePath, ids).then(() => {
        this.reloadData();
        loading.close();
        // 删除黑名单游客信息
        this.delBlacklistTouriest(removeList);
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    },
    // 删除黑名单游客信息
    delBlacklistTouriest(removeList) {
      const list = removeList.filter(it => it.cardNumber);
      const delList = list.map(it => {
        let items = {
          cardNumber: it.cardNumber,
          cardType: typeof it.cardType == 'string' ? 0 : it.cardType,
          name: it.name,
          endTripDate: this.currentOrder.endTripDate,
          startTripDate: this.currentOrder.startTripDate,
          mobilePhone: it.mobilePhone,
          remarks: it.remarks
        };
        return items;
      });
      if (!delList.length) return;
      this.$store.dispatch('order/index/delBlacklistTouriest', delList);
    },
    repeatRow({
      row
    }) {
      const cardNumbers = this.tableData.filter(it => it.cardNumber == row.cardNumber);
      return cardNumbers.length > 1 ? 'error-row' : '';
    },
    // 查询黑名单
    handleInquire({
      queryType
    }) {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '游客名单不能为空'
        });
        return false;
      }
      // 筛选掉身份证号不正确的游客
      // let list = this.tableData.filter(it => validCard(it.cardNumber))
      // 根据身份证号去重且去除身份证号不正确的游客
      let list = this.tableData.filter(function (item, index, self) {
        return self.findIndex(it => it.cardNumber == item.cardNumber) === index && validCard(item.cardNumber);
      });
      let queryList = list.map(it => {
        let items = {
          cardNumber: it.cardNumber,
          cardType: typeof it.cardType == 'string' ? 0 : it.cardType,
          name: it.name,
          endTripDate: this.currentOrder.endTripDate,
          startTripDate: this.currentOrder.startTripDate,
          product: this.currentOrder.productName,
          mobilePhone: it.mobilePhone,
          remarks: it.remarks
        };
        return items;
      });
      if (queryType == 'update') {
        this.inquireUpdate(queryList, queryType);
      }
      if (queryType == 'inquire') {
        this.$bus.open(() => {
          this.inquireUpdate(queryList, queryType);
        }, '查询黑名单身份号码是必要条件，确定查询会自动筛选掉没有身份证号码的游客！是否确定查询？');
      }
    },
    inquireUpdate(queryList, queryType) {
      let opts = queryList.filter(item => {
        return item.cardNumber;
      });
      if (!opts.length) {
        // this.$bus.tip({
        //   type: 'warning',
        //   message: '游客名单列表身份证号都为空！',
        // })
        return false;
      }
      this.$store.dispatch('order/index/searchAndSaveUsers', opts).then(res => {
        let times = res;
        if (queryType == 'update') {
          times = res.filter(it => it.times > 0);
          if (times.length) {
            this.$refs.blackTourist.show(times, this.tableData);
          }
        }
        if (queryType == 'inquire') {
          this.$refs.blackTourist.show(times, this.tableData);
        }
      });
    },
    changeCardType(row) {
      this.validateCardNumber(row);
    },
    validateCardNumber(item) {
      if (item.cardType == 0 || item.cardType == '身份证') {
        return validCard(item.cardNumber);
      }

      // if (item.cardType == 2 || item.cardType == '护照') {
      //   return validPassport(item.cardNumber)
      // }

      // if (item.cardType == 3 || item.cardType == '港澳通行证') {
      //   return validHKM(item.cardNumber)
      // }

      return true;
    },
    // 验证身份证号
    validateIdcard(row, val) {
      if (!val) return;
      // if (!(row.cardType == '身份证' || row.cardType == '0')) return
      // const isValid = validCard(val)

      const isValid = this.validateCardNumber(row);
      if (!isValid) {
        this.$bus.tip({
          type: 'warning',
          message: '证件信息格式不正确'
        });
        return;
      } else {
        const userInfo = this.getInfo(val);
        const {
          location,
          age,
          sex
        } = userInfo;
        row.location = location;
        row.sex = sex ? 1 : 0; // 端口 0: 男 1: 女， 返回：0为女，1为男
        row.age = age;
        return userInfo;
      }
    },
    getInfo(val) {
      const useInfo = getIdCardInfo(val);
      let {
        addr: location,
        birth
      } = useInfo;

      // sex = sex ? 0 : 1 // 端口 0: 男 1: 女， 返回：0为女，1为男
      const age = this.getAge(birth);
      return {
        location,
        age,
        ...useInfo
      };
    },
    getAge(birth) {
      if (!birth) return;
      const [y,,,] = birth.split('-');
      const date = new Date();
      const year = date.getFullYear();
      // const month = date.getMonth() + 1
      // const day = date.getDate()
      let age = Number(year) - Number(y);
      // const isFullMonth = Number(month) - Number(m)
      // const isFullDay = Number(day) - Number(d)
      // if (isFullMonth < 0) {
      //   age--
      // }
      // if (isFullMonth == 0 && isFullDay < 0) {
      //   age--
      // }
      return age;
    },
    // 粘贴名单
    handlePasteList(list) {
      if (!list || !list.length) return;
      const newtList = list.map((it, idx) => {
        // this.validateIdcard(it, it['cardNumber'])
        const {
          cardType
        } = it;
        const otherInfo = it.cardNumber && cardType === '0' && this.getInfo(it.cardNumber);
        this.rowId++;
        return {
          ...defaultState(),
          ...it,
          ...otherInfo,
          rowId: this.rowId,
          seqNumber: this.tableData.length + idx + 1
        };
      });
      this.tableData = [...this.tableData, ...newtList];
      // 计算人数
      // this.calePeopleNumber()
    },
    // 删除
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      this.seqNumber--;
      this.tableData.splice(scope.$index, 1);
      if (id) {
        this.removeCache.push({
          id,
          isDelete: true
        });
      }
    },
    handleContact(item, val) {
      item.isContact = val;
    },
    handleDownload() {
      this.$store.dispatch('order/index/downloadTouristTpl');
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: '上传的文件超出1M限制范围',
        type: 'warning'
      });
      return false;
    },
    handleSuccess({
      results,
      header
    }) {
      this.tableHeader = header;
      results.forEach(row => {
        let obj = {};
        let userInfo;
        Object.keys(row).map(key => {
          key = key.toString();
          orderTouristList.forEach(it => {
            if (it.attrs.label == key) {
              // 联系人
              if (it.attrs.prop == 'isContact') {
                //TODO:联系人判断有问题，没有走进来，打印it.attrs.prop没看到isContact字段
                obj[it.attrs.prop] = row[key] == 1 ? true : false;
              } else if (it.attrs.prop == 'cardType') {
                // 证件类型
                switch (row[key]) {
                  case '学生证':
                    obj[it.attrs.prop] = 1;
                    break;
                  case '护照':
                    obj[it.attrs.prop] = 2;
                    break;
                  default:
                    obj[it.attrs.prop] = 0;
                }
              } else {
                if ('cardNumber' === it.attrs.prop) {
                  userInfo = this.validateIdcard(row, row[key]);
                  if (userInfo) {
                    const {
                      sex,
                      addr,
                      birth
                    } = userInfo;
                    obj.location = addr;
                    obj.age = this.getAge(birth);
                    obj.sex = sex ? 0 : 1; // 接口 0: 男 1: 女， 返回：0为女，1为男
                  }
                  obj[it.attrs.prop] = row[key];
                } else {
                  if (!userInfo) {
                    obj[it.attrs.prop] = row[key];
                  }
                }
              }
            }
          });
        });
        obj.seqNumber = this.seqNumber + obj.seqNumber;
        obj.isCustom = true;
        this.tableData.push(obj);
      });
      this.seqNumber = this.tableData.length;
      this.fixTouristData(this.tableData);
      // this.calePeopleNumber()
    },
    showPasteDialog() {
      this.$refs.touristRef.show();
    },
    handleExcel() {
      this.inputType = 'excel';
    },
    handleUpdate() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) return reject();
        const loading = this.$bus.loading();
        this.createOrUpdate().then(res => {
          this.fetchData();
          const {
            message
          } = res;
          // 判断是不是修改内容需要订单审核流程
          if (message.indexOf('订单审核') != -1) {
            this.$alert(message, '温馨提示', {
              confirmButtonText: '好的',
              type: 'warning'
            });
          } else {
            this.$bus.tip();
          }
          loading.close();
          resolve();
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    beforeCommitFomat(customerOrderId) {
      if (!this.tableData.length) return [];
      this.handleInquire({
        queryType: 'update'
      });

      // 新增的项目加上 订单 id
      const copyData = this.beforeCommit(this.tableData, customerOrderId);
      return this.fixTouristData(copyData); // 修复错误
      // const opts = [...fixData, ...this.removeCache]
      // return opts
    },
    // 添加游客名单
    createOrUpdate(customerOrderId = this.currentOrderId) {
      if (!this.tableData.length) return Promise.resolve(1);
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        } else {
          const opts = this.beforeCommitFomat(customerOrderId);
          this.$store.dispatch('order/index/createOrUpdateTourist', opts).then(res => {
            this.removeCache = [];
            const {
              path
            } = this.$route;
            // 如果是在订单列表弹窗 根据url判断
            if (path == '/order/team' || path == '/order/personal' || path == '/order/order-all' || path == '/order/order-all2') {
              this.$parent.hide();
            }
            return resolve(res);
          }).catch(err => {
            return reject(err);
          });
        }
      });
    },
    beforeCommit(list, customerOrderId) {
      // 先过滤只有id的数据
      return list.filter(it => it.name != '').map(it => {
        // 默认值给了'身份证'，所以这里单独处理
        // if (typeof it.cardType == 'string') {
        //   it.cardType = 0
        // }
        it.name = it.name.trim();
        it.cardNumber = it.cardNumber.trim();
        it.customerOrderId = customerOrderId;
        return it;
      });
    },
    uniqueTourist(list) {
      const map = {};
      const res = [];
      list.forEach(it => {
        if (!map[it.cardNumber]) {
          map[it.cardNumber] = true;
          res.push(it);
        }
      });
      return res;
    },
    // 修复错误信息：如性别、年龄、所在地
    fixTouristData(list) {
      return list.map(it => {
        const {
          cardNumber
        } = it;
        if (it.cardType == 0) {
          if (!cardNumber) return it;
          const {
            location,
            sex,
            age,
            province,
            city,
            district
          } = this.getInfo(cardNumber);
          it.location = location;
          it.sex = sex;
          it.age = age;
          it.province = province;
          it.city = city;
          it.district = district;
        }
        return it;
      });
    },
    setDefaultContact(list) {
      if (!list.length) return [];
      // 设置默认联系人
      if (!this.hasDefaultContact(list)) {
        list[0].isContact = true;
      }
      return list;
    },
    hasDefaultContact(list) {
      if (!list || !list.length) return false;
      return list.some(it => it.isContact === true);
    },
    validateForm() {
      if (!this.tableData.length && !this.removeCache.length) return false;
      let repeatCrad = [];
      let map = {};
      this.tableData.forEach(it => {
        const {
          cardNumber
        } = it;
        if (!cardNumber) return;
        if (!map[cardNumber]) {
          map[cardNumber] = 1;
        } else {
          repeatCrad.push(it.cardNumber);
          map[cardNumber] += 1;
        }
      });
      for (let i = 0; i < this.tableData.length; i++) {
        const {
          name,
          mobilePhone,
          cardNumber,
          cardType
        } = this.tableData[i];
        if (cardNumber && map[cardNumber] > 1) {
          const names = this.tableData.filter(it => repeatCrad.includes(it.cardNumber));
          const Number = names.map(it => {
            return it.seqNumber;
          });
          this.$bus.tip({
            type: 'warning',
            message: `第【${Number}】行游客身份证重复`,
            duration: 6000
          });
          return false;
        }
        if (!name) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写游客名字'
          });
          return false;
        }
        if (mobilePhone && !isPhone(mobilePhone)) {
          this.$bus.tip({
            type: 'warning',
            message: '游客手机号码不正确'
          });
          return false;
        }
        if (cardType == 0 && cardNumber && !validCard(cardNumber)) {
          this.$bus.tip({
            type: 'warning',
            message: '游客身份证号码不正确'
          });
          return false;
        }
      }
      return true;
    }
  }
};