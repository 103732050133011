import "core-js/modules/es.array.push.js";
// import { beforeCommit } from '../helper'
import { rules, orderState } from '../config';
import { addResourcePlanOrder } from '@/api/order';
import { SelectCustom,
// SelectStaff,
SelectDictionary } from '@/components/common';
import { getUserData } from '@/utils';
export default {
  components: {
    SelectCustom,
    SelectDictionary
  },
  data() {
    return {
      rules,
      visible: false,
      form: orderState()
    };
  },
  methods: {
    show(row) {
      this.visible = true;
      this.processForm(row);
    },
    hide() {
      this.visible = false;
    },
    processForm(row) {
      const {
        id,
        outOPUserId,
        outOPUserName,
        accompanyCount,
        adultCount,
        childCount,
        planNumber: planCustomerNumber
      } = row;
      const {
        id: dutyOPUserId
      } = getUserData();
      this.form = {
        ...this.form,
        id,
        dutyOPUserId,
        planCustomerNumber,
        outOPUserId,
        outOPUserName,
        accompanyCount,
        adultCount,
        childCount
      };
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return;
        addResourcePlanOrder(this.form).then(res => {
          console.log('res:', res);
          this.$bus.tip();
          this.hide();
          this.redirectOrder(res.data.id, this.form.customerId);
          this.$emit('reload');
        });
      });
    },
    redirectOrder(id, customerId) {
      const query = {
        id,
        customerId,
        type: 'update'
      };
      this.$router.push({
        path: `/order/team-input-update`,
        query
      });
    }
  }
};