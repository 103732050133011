import billDownMixin from '../../billDownMixin';
import { BillInput } from '@/components/common';
import { mapGetters } from 'vuex';
import { setAutoDown, formatDownData } from '../../help';
import { processMinus } from '@/utils/number';
export default {
  components: {
    // BaseInput,
    BillInput
  },
  mixins: [billDownMixin],
  data() {
    return {
      visible: false,
      type: '',
      form: {},
      resourceId: '',
      planId: '',
      data: [],
      unPayList: []
    };
  },
  computed: {
    ...mapGetters({
      unPayData: 'bill/billDown/unPayData',
      billDetail: 'bill/guide/billDetail',
      downMoney: 'bill/guide/downMoney'
    }),
    getTableData() {
      return this.unPayData ? this.unPayData.list : [];
    },
    getTitle() {
      return this.type == 'guide' ? '一键报垫付款' : this.type == 'shop_comm' ? '一键报佣金' : '整团报账';
    }
  },
  watch: {
    billDetail: {
      handler: function (data) {
        if (!data) return;
        this.form.planId = data.planId;
        this.form.guideName = data.guideName;
      },
      immediate: true
    }
  },
  methods: {
    fetchUnpayData(params = {}) {
      return this.$store.dispatch('bill/billDown/fetchUnPayGuide', {
        ...params,
        pageSize: 1000
      }).then(data => {
        if (!data) return;
        const {
          totalPay,
          totalPayed
        } = data.statistics;
        this.unPayList = setAutoDown(data.list);
        // const money = getBillDownTotal({
        //   list: data.list,
        //   typeKey: 'totalPay',
        // })
        const money = processMinus(totalPay, totalPayed);
        this.form.money = money;
        this.form.userMoney = processMinus(money, totalPayed);
        this.visible = true;
      });
    },
    show({
      guideId,
      planId,
      type
    }) {
      this.resourceId = guideId;
      this.planId = planId;
      this.type = type;
      this.fetchUnpayData({
        guideId,
        planId,
        type
      });
    },
    hide() {
      this.visible = false;
    },
    handleCreate() {
      this.$refs.formRef.handleValidation().then(form => {
        const data = formatDownData({
          list: this.unPayList,
          form,
          cashKey: 'owePay'
        });
        const opts = {
          ...data,
          resourceId: this.resourceId
        };
        this.$store.dispatch('bill/billDown/guide/createPay', opts).then(() => {
          this.$bus.tip();
          this.hide();
          this.$parent.reloadData();
        });
      });
    }
  }
};