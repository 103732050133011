import { tableMixin, permissionMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
import handleClipboard from '@/utils/clipboard';
import { EnterpriseCodeStatus } from '@/config/enterprise';
const StatusType = {
  0: 'warning',
  1: 'primary',
  2: 'success'
};
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin, permissionMixin],
  data() {
    return {
      multipleSelection: []
    };
  },
  computed: {
    getType() {
      return val => {
        return StatusType[val];
      };
    },
    getEnterpriseStatus() {
      return val => {
        return EnterpriseCodeStatus[val];
      };
    }
  },
  methods: {
    handleCommand(scope, command) {
      this[command](scope);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addInvite(scope) {
      this.$bus.open(() => {
        const {
          id: guideId
        } = scope.row;
        this.$store.dispatch('enterprise/guide/addInvite', {
          guideId
        }).then(res => {
          if (!res) return;
          const {
            signUrl
          } = res.data;
          this.$bus.tip({
            message: `邀请链接：${signUrl}, 请复制该链接发给导游确认`,
            duration: 0
          });
          this.handleCopy(signUrl);
          // this.$bus.tip({ message: '邀请已发出，待对方确认！' })
        });
      }, '确定要邀请吗？');
    },
    handleCopy(text) {
      handleClipboard(text);
    },
    delInvite(scope) {
      this.$bus.open(() => {
        const {
          id: guideId
        } = scope.row;
        this.$store.dispatch('enterprise/guide/delInvite', {
          guideId
        }).then(() => {
          this.$bus.tip({
            message: '操作成功！'
          });
          this.$emit('reload');
        });
      }, '确定要移除吗？');
    },
    updatePwd(scope) {
      const {
        mobileIphone: phone
      } = scope.row;
      this.$emit('uppwd', phone);
    },
    resetPwd(scope) {
      const {
        mobileIphone: phone
      } = scope.row;
      this.$bus.open(() => {
        const opts = {
          pwd: '123456',
          reSet: false,
          mobileIphone: phone
        };
        this.$store.dispatch('resource/guide/updatePwd', opts).then(() => {
          this.$bus.tip({
            message: '重置密码成功'
          });
          this.hide();
        });
      }, '确定要重置吗？');
    }
  }
};