var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getShopSummaries,
      sticky: _vm.isSticky,
      border: "",
      "show-summary": ""
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")];
      }
    }, {
      key: "tripDate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " "), _c("br"), _vm._v(" " + _vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "shop",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopName) + " ")];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "guides",
      fn: function ({
        scope
      }) {
        return [_c("Guides", {
          attrs: {
            row: scope.row,
            "is-contact": false
          }
        })];
      }
    }, {
      key: "goods",
      fn: function ({
        scope
      }) {
        return [_vm._l(scope.row.shopGoods, function (item) {
          return [_vm._v(" " + _vm._s(item.name ? `[${item.name}]` : "") + " ")];
        })];
      }
    }, {
      key: "totalBuyMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalBuyMoney) + " ")];
      }
    }, {
      key: "enterStoreMemberCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.enterStoreMemberCount) + " ")];
      }
    }, {
      key: "perCapita",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.perCapita) + " ")];
      }
    }, {
      key: "totalShopRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalShopRebate) + " ")];
      }
    }, {
      key: "totalGuideRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalGuideRebate) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.removeItem(scope.row.rowId);
            }
          }
        }, [_vm._v(" 删除 ")]), _c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.addShop(scope.row);
            }
          }
        }, [_vm._v(" 添加 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };