var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "760px",
      title: "客户目标导入",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide,
      confirm: _vm.handleConfirm
    }
  }, [_c("div", {
    staticClass: "flex flex-center"
  }, [_c("el-upload", {
    attrs: {
      action: "",
      drag: "",
      accept: ".xlsx, .xls",
      "show-file-list": false,
      "http-request": _vm.uploadTemplate
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("将文件拖到此处，或"), _c("em", [_vm._v("点击上传")])])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };