var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.className,
    style: {
      top: _vm.isSticky ? _vm.stickyTop + "px" : "",
      zIndex: _vm.zIndex,
      position: _vm.position,
      width: _vm.width,
      height: _vm.height + "px"
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };