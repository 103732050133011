var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.row.guides && _vm.row.guides.length ? _c("div", {
    ref: "myPop"
  }, _vm._l(_vm.row.guides, function (item, index) {
    return _c("div", {
      key: item.id + "" + index,
      class: ["flex-center", {
        flex: !_vm.isBreak
      }],
      on: {
        mouseover: _vm.onMouseEnter,
        mouseleave: _vm.onMouseLeave
      }
    }, [!_vm.isLink ? [_c("div", [_vm._v(_vm._s(item.name))]), _c("div", [_vm._v(_vm._s(_vm.isContact ? item.mobileIphone : ""))])] : [_vm._t("default"), _c("div", [_vm.isBreak ? [_c("BtnLink", {
      attrs: {
        type: "primary"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("br"), _vm.isContact ? _c("BtnLink", {
      attrs: {
        type: "primary"
      }
    }, [_vm._v(" " + _vm._s(item.mobileIphone) + " ")]) : _vm._e()] : _c("BtnLink", {
      attrs: {
        type: "primary"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " " + _vm._s(_vm.isContact ? item.mobileIphone : "") + " ")])], 2)], _vm.visible ? _c("span", {
      ref: "copyTips",
      refInFor: true,
      staticClass: "el-tooltip__popper is-dark pointer tips-copy",
      style: _vm.styleObj,
      attrs: {
        role: "tooltip"
      },
      on: {
        mouseenter: _vm.onCopyMouseEnter,
        mouseout: _vm.hide,
        click: function ($event) {
          $event.stopPropagation();
          return _vm.handleCopy.apply(null, arguments);
        }
      }
    }, [_vm._v(" 复制 ")]) : _vm._e()], 2);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };