import { orderRestaurantInfo } from '@/config/resourceOrder';
import { SelectDictionary, SelectPayType as SelectRestaurantType } from '@/components/common';
const restaurantTypeList = [{
  label: '社会餐厅',
  value: '社会餐厅'
}, {
  label: '团餐厅',
  value: '团餐厅'
}];
export default {
  components: {
    SelectDictionary,
    SelectRestaurantType
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: orderRestaurantInfo,
      tableData: [],
      restaurantTypeList
    };
  },
  computed: {
    getData() {
      return this.data ? [this.data] : [];
    }
  },
  methods: {
    selectArea(row, val) {
      row.restaurantArea = val.label;
      row.restaurantAreaId = val.value;
    },
    selectStandardType(row, val) {
      row.restaurantType = val.label;
    }
  }
};