import request from '@/utils/request';

// 部门业绩
export function departmentRequest(params = {}) {
  return request({
    url: '/order/data/data-big-screen-salesman-by-department',
    method: 'get',
    params
  });
}
// 个人业绩
export function salesmanRequest(params = {}) {
  return request({
    url: '/order/data/data-big-screen-salesman',
    method: 'get',
    params
  });
}
export function customerRequest(params = {}) {
  return request({
    url: '/order/data/data-big-screen-customer',
    method: 'get',
    params
  });
}
export function customerPeopleRequest(params = {}) {
  return request({
    url: '/order/data/data-big-screen-customer-people',
    method: 'get',
    params
  });
}