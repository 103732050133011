import { boatTickets } from './helper';
export const defaultBoatType = {
  shipType: '三星船'
};
export const defaultBoatTicketType = {
  shipTicketType: '下舱'
};
export const ticketItem = () => ({
  downCount: '',
  shipType: '',
  upCount: '',
  vipCount: '',
  remarks: ''
});
export const defaultState = () => ({
  planNumber: '',
  shipDate: '',
  tickets: boatTickets()
});
export const rules = {
  planNumber: [{
    required: true,
    message: '请选择团号',
    trigger: 'change'
  }],
  shipDate: [{
    required: true,
    message: '请选择时间',
    trigger: 'change'
  }]
};
export const TouristObj = {
  age: 0,
  cardNumber: '',
  cardType: '',
  city: '',
  cityId: 0,
  createUserId: 0,
  customerOrderId: 0,
  district: '',
  districtId: 0,
  id: 0,
  location: '',
  mobilePhone: '',
  name: '',
  nationality: '',
  province: '',
  provinceId: 0,
  remarks: '',
  seqNumber: 0,
  sex: 0,
  shipTicketType: '',
  shipType: '',
  shippingSpace: '',
  shopMoney: 0
};