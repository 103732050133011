import { Shopping } from '@/components/common/Order';
import { auditEditShopping, auditEditShoppingAll, auditEditShoppingOldAll, auditEditShoppingOld } from '@/config/audit';
import { permissionMixin, themeMixin, systemMixin } from '@/mixin';
export default {
  components: {
    Shopping
  },
  mixins: [permissionMixin, themeMixin, systemMixin],
  data() {
    return {
      columnData: [],
      auditAll: false,
      visibility: false
    };
  },
  watch: {
    // 版本切换
    versionSwitch: {
      handler: function (state) {
        const SubsidyTwo = this.hasSubsidyTwoPrice(); // 二返权限
        this.visibility = false;
        // this.columnData =
        //   state && SubsidyTwo
        //     ? auditEditShoppingAll
        //     : state && !SubsidyTwo
        //     ? auditEditShopping
        //     : !state && SubsidyTwo
        //     ? auditEditShoppingOldAll
        //     : auditEditShoppingOld

        if (state) {
          this.columnData = SubsidyTwo ? auditEditShoppingAll : auditEditShopping;
        } else {
          this.columnData = SubsidyTwo ? auditEditShoppingOldAll : auditEditShoppingOld;
        }

        // 是否显示订单信息
        const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
        if (!isOrderInfo) {
          this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
        }
        setTimeout(() => {
          this.visibility = true;
        }, 500);
      },
      immediate: true
    }
  },
  // created() {
  //   setTimeout(() => {
  //     this.columnData = this.hasSubsidyTwoPrice()
  //       ? auditEditShoppingAll
  //       : auditEditShopping
  //   }, 100)
  // },

  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};