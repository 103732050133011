/* eslint-disable no-unused-vars */
import { fetchPlan, updateAudit, updateAuditOne, updateAuditOrder, cancelAuditOrder, fetchAuditLog, fetchTeamFund, checkAuditOrder, fetchCollect, fetchGuideBorrow, createGuideBorrow, deleteGuideBorrow, getCollectListExcel, getPlanFileState, batchAuditRequest, batchCancelAuditRequest } from '@/api/audit';
import { DEFAULT_PAGE } from '@/config';
import { TYPES } from '@/config/audit';
const dfaultState = () => ({
  auditData: null,
  fileData: null,
  proxyData: null,
  borrowData: null,
  receptionList: [],
  currentTabIndex: 0,
  currentTabType: TYPES.fee,
  isBack: false,
  currentRole: null,
  tabStatus: {}
});
const state = dfaultState();
const getters = {
  auditData: state => state.auditData,
  fileData: state => state.fileData,
  proxyData: state => state.proxyData,
  borrowData: state => state.borrowData,
  receptionList: state => state.receptionList,
  currentTabIndex: state => state.currentTabIndex,
  currentTabType: state => state.currentTabType,
  isBack: state => state.isBack,
  currentRole: state => state.currentRole,
  tabStatus: state => state.tabStatus
};
const actions = {
  // 获取计划列表
  list({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return new Promise((resolve, reject) => {
      fetchPlan(params).then(res => {
        if (!res) reject(res);
        const {
          data
        } = res;
        resolve(data);
      }).catch(reject);
    });
  },
  // 获取计划列表
  fetch({
    commit,
    dispatch
  }, params) {
    return dispatch('list', params).then(data => {
      commit('FETCH_AUDIT', data);
      return data;
    });
    // params = { ...DEFAULT_PAGE, ...params }
    // return fetchPlan(params).then(res => {
    //   const { data } = res
    //   commit('FETCH_AUDIT', data)
    //   return data
    // })
  },
  // 获取归档列表
  fetchFile({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchPlan(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_FILE', data);
      return data;
    });
  },
  // 获取团款
  fetchTeamFund({
    commit
  }, queryParam) {
    queryParam = {
      status: false,
      ...queryParam
    };
    return fetchTeamFund(queryParam).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 审核
  update({
    commit
  }, opts) {
    return updateAudit(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 审核 单条
  updateAuditOne({
    commit
  }, opts) {
    return updateAuditOne(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 计调审核
  updateAuditOrder({
    commit
  }, opts) {
    return updateAuditOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 计调审核 取消
  cancelAuditOrder({
    commit
  }, opts) {
    return cancelAuditOrder(opts);
  },
  // 代收列表
  fetchProxy({
    commit
  }, opts) {
    return fetchCollect(opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_PROXY_AUDIT', data);
      return data;
    });
  },
  // 导游借款
  fetchGuideBorrow({
    commit
  }, params) {
    return fetchGuideBorrow(params).then(res => {
      const {
        data
      } = res;
      commit('GUIDE_BORROW_AUDIT', data);
      return data;
    });
  },
  // 导游借款 添加
  createGuideBorrow({
    commit
  }, opts) {
    return createGuideBorrow(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导游借款 删除
  deleteGuideBorrow({
    commit
  }, opts) {
    return deleteGuideBorrow(opts);
  },
  // 确认结算
  checkAuditOrder({
    commit
  }, opts) {
    return checkAuditOrder(opts);
  },
  // 日志
  fetchLog({
    commit
  }, itemId) {
    return fetchAuditLog(itemId).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 代收审核导出
  getCollectListExcel({
    commit
  }, opts) {
    return getCollectListExcel(opts).then(res => {
      return res;
    });
  },
  //  单团归档 =>归档
  getPlanFileState({
    commit
  }, opts) {
    return getPlanFileState(opts).then(res => {
      return res;
    });
  },
  // 批量审核
  batchAudit({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      batchAuditRequest(opts).then(res => {
        if (!res) reject(res);
        resolve(res.data);
      }).catch(reject);
    });
  },
  // 批量取消审核
  batchCancelAudit({
    commit
  }, opts) {
    return new Promise((resolve, reject) => {
      batchCancelAuditRequest(opts).then(res => {
        if (!res) reject(res);
        resolve(res.data);
      }).catch(reject);
    });
  }
};
const mutations = {
  FETCH_AUDIT: (state, data) => {
    state.auditData = data;
  },
  FETCH_FILE: (state, data) => {
    state.fileData = data;
  },
  FETCH_PROXY_AUDIT: (state, data) => {
    state.proxyData = data;
  },
  GUIDE_BORROW_AUDIT: (state, data) => {
    state.borrowData = data;
  },
  CUR_TAB_DATA: (state, {
    index,
    type
  }) => {
    state.currentTabIndex = index;
    state.currentTabType = type;
  },
  BACK_STATUS: (state, flag) => {
    state.isBack = flag;
  },
  CUR_ROLE: (state, role) => {
    state.currentRole = role;
  },
  TAB_STATUS: (state, data) => {
    state.tabStatus = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};