import "core-js/modules/es.array.push.js";
import { tableMixin, permissionMixin, summaryMixin, systemMixin } from '@/mixin';
import orderMixin from '../../orderMixin';
import { StatusOrderTeam, CustomerInfo } from '@/components/common';
import { orderType } from '@/config/order';
export default {
  components: {
    StatusOrderTeam,
    CustomerInfo
  },
  mixins: [tableMixin, permissionMixin, summaryMixin, systemMixin, orderMixin],
  data() {
    return {
      orderType: orderType.team,
      visible: false,
      statisticsKeys: ['count', 'totalCollect', 'totalIncome', 'totalSubsidy']
    };
  },
  computed: {
    getContactPeople() {
      return item => {
        let findList = item.filter(it => it.isContact);
        if (findList.length > 2) {
          findList = findList.splice(0, 2);
        }
        return findList;
      };
    },
    hasFiled() {
      return status => {
        return status == 'FILE';
      };
    }
  },
  methods: {
    onItinerary({
      row
    }) {
      this.$parent.onItinerary(row);
    },
    addAuditStatus({
      row
    }) {
      const {
        planId,
        isCancel,
        addAuditStatus
      } = row;
      // 是否开启订单审核
      const code = this.getConfigStatus('order:openOrderAudit:openOrderAuditValue');
      if (code) {
        if (planId == -1 && !isCancel && addAuditStatus && addAuditStatus == this.reviewerStatusType.audited) {
          return true;
        }
      } else {
        if (planId == -1 && !isCancel) {
          return true;
        }
      }
      return false;
    },
    // handleRedirect(id) {
    //   let routeUrl = this.$router.resolve({
    //     path: '/print',
    //     query: {
    //       id,
    //       gateUrl: 'team',
    //       planType: orderType.team,
    //     },
    //   })
    //   window.open(routeUrl.href, '_blank')
    // },

    handleConfirm(scope) {
      this.$parent.confirmTableTeam(scope);
    },
    handleCancel(scope) {
      this.$parent.cancelTableTeam(scope);
    },
    handleCopy(scope) {
      this.$parent.copyTableTeam(scope);
    },
    handleOnkeyCopy(scope) {
      this.$bus.open(() => {
        const {
          id
        } = scope.row;
        this.$store.dispatch('order/index/copyOrder', id).then(() => {
          this.$parent.handleSearch();
        });
      }, '确定要复制该订单吗？');
    },
    handleRevoke(scope) {
      this.$parent.revokeTableTeam(scope);
    },
    handleBooking(scope) {
      this.$store.commit('order/index/CUR_ORDER', scope.row);
      this.$router.push({
        path: '/order/reserve-booking'
      });
    },
    handleAuditDetail(scope) {
      const {
        planId,
        orderType
      } = scope.row;
      this.$store.commit('plan/index/CUR_PLAN_ID', planId);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id: planId,
          planType: orderType
        }
      });
    },
    handlePrint(scope) {
      const {
        id,
        planId,
        orderType,
        startTripDate,
        endTripDate
      } = scope.row;
      // 打印页面的打印权限类型
      const gateUrl = 'team';
      const routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId,
          component: 'Receipt',
          planType: orderType,
          gateUrl,
          startTripDate,
          endTripDate
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleCommand(scope, command) {
      this.$store.commit('order/index/CUR_ORDER_ID', scope.row.id);
      this[command](scope);
    },
    linkDetail(data) {
      const {
        planCustomerNumber
      } = data.row;
      if (!planCustomerNumber) return false;
      this.$parent.showTableDetail(data);
    }
  }
};