var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "from-create-wrapper"
  }, [_c("div", {
    staticClass: "input-row flex"
  }, [_vm._m(0), !_vm.isTeamOrder ? _c("InputTeamNumber", {
    attrs: {
      "plan-number": _vm.form.planCustomerNumber
    },
    on: {
      "update:planNumber": function ($event) {
        return _vm.$set(_vm.form, "planCustomerNumber", $event);
      },
      "update:plan-number": function ($event) {
        return _vm.$set(_vm.form, "planCustomerNumber", $event);
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "input-row"
  }, [_vm.statusShow ? _c("SelectStaff", {
    attrs: {
      label: "操作计调",
      "is-require": _vm.isRequire
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.form.dutyOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dutyOPUserName", $$v);
      },
      expression: "form.dutyOPUserName"
    }
  }) : _vm._e(), _vm.statusShow ? _c("SelectStaff", {
    attrs: {
      label: "客服计调",
      "is-require": _vm.isRequire
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("outOPUser", $event);
      }
    },
    model: {
      value: _vm.form.outOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outOPUserName", $$v);
      },
      expression: "form.outOPUserName"
    }
  }) : _vm._e(), _vm.statusShow ? _c("SelectStaff", {
    attrs: {
      label: "业务员",
      "is-require": _vm.isRequire
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("saleUser", $event);
      }
    },
    model: {
      value: _vm.form.saleUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "saleUserName", $$v);
      },
      expression: "form.saleUserName"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("InputBase", {
    attrs: {
      label: "小团号"
    },
    model: {
      value: _vm.form.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerTripNumber", $$v);
      },
      expression: "form.customerTripNumber"
    }
  }), _c("SelectGuideManager", {
    attrs: {
      label: "导管",
      disabled: _vm.form.planId > 0
    },
    model: {
      value: _vm.form.guideManageId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideManageId", $$v);
      },
      expression: "form.guideManageId"
    }
  })], 1), _vm.hasCustomerInfo ? _c("div", {
    staticClass: "input-row"
  }, [_c("div", {
    staticClass: "input-box w40 is-require",
    staticStyle: {
      "min-width": "390px !important"
    }
  }, [_c("LabelBase", {
    attrs: {
      label: "客户"
    }
  }), _c("el-input", {
    attrs: {
      clearable: "",
      "is-require": "",
      placeholder: "请选择客户",
      disabled: _vm.form.checkAccountStatus || _vm.disableCustomer
    },
    on: {
      focus: _vm.showCustomDialog,
      "on-select": _vm.changeCustomerName
    },
    model: {
      value: _vm.form.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  }), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.form.checkAccountStatus || _vm.disableCustomer
    },
    on: {
      click: _vm.showCustomDialog
    }
  }, [_vm._v(" 选择 ")])], 1), _c("InputBase", {
    attrs: {
      "is-require": _vm.isRequire,
      disabled: _vm.form.checkAccountStatus,
      label: "客户联系人"
    },
    model: {
      value: _vm.form.customerContactName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerContactName", $$v);
      },
      expression: "form.customerContactName"
    }
  }), _c("InputBase", {
    attrs: {
      disabled: _vm.form.checkAccountStatus,
      label: "客户手机"
    },
    model: {
      value: _vm.form.customerContactMobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerContactMobileNumber", $$v);
      },
      expression: "form.customerContactMobileNumber"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "input-row"
  }, [_c("div", {
    staticClass: "input-box w40 is-require",
    staticStyle: {
      "min-width": "390px !important"
    }
  }, [_c("LabelBase", {
    attrs: {
      label: "产品线路"
    }
  }), _c("el-input", {
    attrs: {
      placeholder: "请选择产品",
      clearable: "",
      "is-require": _vm.isRequire
    },
    on: {
      focus: _vm.showProductialog
    },
    model: {
      value: _vm.form.productName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productName", $$v);
      },
      expression: "form.productName"
    }
  }), !_vm.isMobile ? _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.handleNewProduct
    }
  }, [_vm._v(" 添加产品 ")]) : _vm._e()], 1), _c("InputDate", {
    attrs: {
      "is-require": _vm.isRequire,
      label: "发团日期",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      change: _vm.changeStartDate
    },
    model: {
      value: _vm.form.startTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startTripDate", $$v);
      },
      expression: "form.startTripDate"
    }
  }), _c("InputDate", {
    attrs: {
      "is-require": _vm.isRequire,
      "value-format": "yyyy-MM-dd",
      label: "散团日期"
    },
    on: {
      change: _vm.changeEndDate
    },
    model: {
      value: _vm.form.endTripDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endTripDate", $$v);
      },
      expression: "form.endTripDate"
    }
  })], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("InputBase", {
    attrs: {
      label: "全陪名字"
    },
    model: {
      value: _vm.form.accompanyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accompanyName", $$v);
      },
      expression: "form.accompanyName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "全陪电话"
    },
    model: {
      value: _vm.form.accompanyPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accompanyPhone", $$v);
      },
      expression: "form.accompanyPhone"
    }
  }), _c("InputPeopleCount", {
    attrs: {
      "is-require": "",
      adult: _vm.form.adultCount,
      child: _vm.form.childCount,
      accompany: _vm.form.accompanyCount
    },
    on: {
      "update:adult": function ($event) {
        return _vm.$set(_vm.form, "adultCount", $event);
      },
      "update:child": function ($event) {
        return _vm.$set(_vm.form, "childCount", $event);
      },
      "update:accompany": function ($event) {
        return _vm.$set(_vm.form, "accompanyCount", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-row clearfix"
  }, [_c("SelectDictionary", {
    attrs: {
      code: _vm.productType,
      label: "产品类型",
      "is-require": ""
    },
    on: {
      "on-select": _vm.onSelectProductType
    },
    model: {
      value: _vm.form.productType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productType", $$v);
      },
      expression: "form.productType"
    }
  }), _c("SelectRegion", {
    attrs: {
      label: "客源地"
    },
    on: {
      "on-select": _vm.changeRegion
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  }), _c("SelectDictionary", {
    attrs: {
      value: _vm.form.groupType,
      code: "teamType",
      label: "团队类型",
      "is-require": true
    },
    on: {
      "on-select": _vm.selectTeamType
    }
  })], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("InputBase", {
    attrs: {
      label: "游客接站牌"
    },
    model: {
      value: _vm.form.welcomeBorad,
      callback: function ($$v) {
        _vm.$set(_vm.form, "welcomeBorad", $$v);
      },
      expression: "form.welcomeBorad"
    }
  })], 1), _vm.type == "update" ? _c("div", {
    staticClass: "tac",
    staticStyle: {
      margin: "20px 0"
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_vm._v(" 保存基础信息 ")])], 1) : _vm._e(), _c("DialogCustom", {
    ref: "customerRef",
    attrs: {
      "order-type": _vm.orderType
    },
    on: {
      "on-select": _vm.selectCustom
    }
  }), _c("DialogProduct", {
    ref: "productRef",
    attrs: {
      "order-type": _vm.orderType
    },
    on: {
      "on-select": _vm.selectProduct
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box w40"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("订单号：")]), _c("strong", [_vm._v("系统自动生成")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };