import { SelectStaff, SelectAccount, SelectBankCard, ResourceItem } from '@/components/common';
import { formatCommitTableData, calcBalance } from '@/utils/data';
import { mapGetters } from 'vuex';
import { findSubject } from '@/utils/subject';
import { SourceType } from '@/config/resource';
import { rules, defaultState, CashFeeType, cashModeType } from '@/views/billDown/help';
const RESOURCE_SUBJECT_MAP = {
  RESOURCE_SCENIC: SourceType.scenic,
  RESOURCE_RESTAURANT: SourceType.restaurant,
  RESOURCE_HOTEL: SourceType.hotel,
  RESOURCE_BUS_COMPANY: SourceType.fleet,
  RESOURCE_INSURANCE: SourceType.insurance,
  RESOURCE_TRANSPORT_COMPANY: SourceType.traffic,
  RESOURCE_GUIDE: SourceType.guide,
  RESOURCE_SHOP: SourceType.shop,
  RESOURCE_CUSTOMER: SourceType.customer
};
export default {
  components: {
    SelectStaff,
    SelectAccount,
    SelectBankCard,
    ResourceItem
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    resName: {
      type: String,
      default: ''
    },
    obj: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      visible: true,
      radios: [{
        value: '向该单位预收款',
        label: CashFeeType.INCOME
      }, {
        value: '向该单位预付款',
        label: CashFeeType.PAY
      }],
      form: defaultState()
    };
  },
  computed: {
    ...mapGetters({
      subjectData: 'system/accounting/subjectData',
      staffList: 'system/staff/staffData',
      userData: 'user/userData'
    }),
    currentMoney() {
      return this.form.unPay || 0;
    },
    balanceMoney() {
      const {
        balance
      } = calcBalance(this.form);
      return balance;
    }
  },
  watch: {
    staffList: {
      handler: function (data) {
        if (!data) {
          this.$store.dispatch('system/staff/fetch');
        }
        this.$nextTick(() => {
          const findStaff = this.findCurrentStaff(data);
          this.form.cashUserId = findStaff ? findStaff.id || '' : '';
        });
      },
      immediate: true
    },
    obj: {
      handler: function (data) {
        if (!this.isUpdate) return;
        this.form = {
          ...this.form,
          resId: data.resourceId + '',
          ...data
        };
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.form = defaultState();
  },
  methods: {
    findCurrentStaff(list) {
      if (!list || !list.length) return;
      return list.find(it => it.id == this.userData.id);
    },
    setResourceId(val) {
      if (!val) return;
      this.form.resourceId = val;
    },
    selectResource(val) {
      this.visible = false;
      const pathName = RESOURCE_SUBJECT_MAP[val];
      const payType = pathName != RESOURCE_SUBJECT_MAP.RESOURCE_SHOP && pathName != RESOURCE_SUBJECT_MAP.customer ? CashFeeType.PAY : CashFeeType.INCOME;
      const subObj = findSubject(pathName, payType);
      this.form = {
        ...this.form,
        ...subObj
      };
      setTimeout(() => {
        this.visible = true;
      }, 50);
    },
    changeRadio(val) {
      this.form.cashAction = val;
    },
    changeCashMode(val) {
      const {
        label
      } = val;
      this.form.cashWay = label;
    },
    selectAccount(val) {
      const {
        id,
        name,
        subId,
        subName
      } = val;
      if (subId && subName) {
        this.form.subName = subName;
        this.form.subjectId = subId;
        this.form.psubjectId = id;
      } else {
        this.form.subjectId = id;
        this.form.subjectName = name;
      }
    },
    validate() {
      return this.$refs.formRef.validate();
    },
    processData(form) {
      const {
        cashWay,
        cashUserId,
        money
      } = form;
      const tableData = this.$refs.unPayRef.tableData;
      const list = tableData.filter(it => it.isChecked);
      const cashs = list.map(it => {
        return {
          cash: it.downPayed,
          cashWay,
          cashUserId,
          orderId: it.rowId,
          // 订单id， 其他为资源id
          type: it.type ? it.type : 'NONE'
        };
      });
      let opts = {
        ...form,
        money,
        cashes: formatCommitTableData(cashs)
      };
      delete opts.subject;
      return opts;
    },
    handleCommit(opts) {
      const path = this.isUpdate ? 'bill/billDown/advance/updateItem' : 'bill/billDown/advance/createItem';
      return this.$store.dispatch(path, opts);
    },
    onSave() {
      this.$refs.formRef.validate().then(valid => {
        if (!valid) return;
        const {
          cashMode
        } = this.form;
        const opt = {
          ...this.form,
          cashMode: cashMode ? cashMode.toUpperCase() : cashModeType.COMMON
        };
        this.handleCommit(opt).then(() => {
          this.$emit('save');
          this.onClose();
        });
      });
    },
    onClose() {
      this.$emit('close');
      this.form = defaultState();
    }
  }
};