import "core-js/modules/es.array.push.js";
import { TypeOrder, PersonCount } from '@/components/common';
import { StatusSend, StatusAuditProgress } from '@/components/common';
import { summaryMixin } from '@/mixin';
export default {
  components: {
    TypeOrder,
    PersonCount,
    StatusSend,
    StatusAuditProgress
  },
  mixins: [summaryMixin],
  props: {
    columnData: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      statisticsKeys: ['number', 'totalCustomerIncome', 'totalCustomerIncomed', 'unIncome', 'totalIncome', 'totalPay', 'totalQuoteGrossProfit', 'totalQuoteGrossMargin']
    };
  },
  computed: {
    guides() {
      return item => {
        if (!item.length) return '';
        const guide = item.find(it => it.reimb);
        return guide.name;
      };
    }
  },
  methods: {
    linkDetail(row) {
      const {
        path
      } = this.$route;
      const toPath = path;
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/FIRST_ENTER', true);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType,
          toPath
        }
      });
    }
  }
};