import "core-js/modules/es.array.push.js";
import InGroup from './components/InGroup';
import UnGroup from './components/UnGroup';
import DialogTransfer from './components/DialogTransfer';
import { tagViewMixin, dictionaryMixin, searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'Personal',
  components: {
    InGroup,
    UnGroup,
    DialogTransfer
  },
  mixins: [tagViewMixin, dictionaryMixin, searchMixin],
  data: () => ({
    link: {
      create: '/order/personal-input-create',
      detail: '/order/personal-input-detail',
      update: '/order/personal-input-update',
      clone: '/order/personal-input-clone'
    },
    activeName: 'unGroup',
    tabIndex: 0,
    unGroupSearchParams: {},
    inGroupSearchParams: {}
  }),
  computed: {
    ...mapGetters({
      visitedViews: 'tagsView/visitedViews',
      needReload: 'order/index/needReload',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getTagViewSearch() {
      const curTag = this.getTagView;
      return curTag ? curTag.search : this.searchParams;
    }
  },
  watch: {
    needReload: {
      handler: function (val) {
        if (val['Personal']) {
          this.batchFetchData();
        }
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data && data['unGroup']) {
          this.unGroupSearchParams = data['unGroup'];
        }
        if (data && data['inGroup']) {
          this.inGroupSearchParams = data['inGroup'];
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$bus.on('order:personal-close', this.closeTagView);
    this.$bus.on('order:booking-close', () => {
      this.closeTagView('orderReserveBooking');
    });
    this.activeName = this.$store.state['order/personal'].activeName;
  },
  methods: {
    fetchData() {
      if (this.tabIndex > 0) {
        this.$refs.inGroupRef.handleSearch();
      } else {
        this.$refs.unGroupRef.handleSearch();
      }
    },
    closeTagView() {
      const tagView = this.findCreateTagView();
      tagView && this.closeSelectedTag(tagView);
    },
    findCreateTagView() {
      return this.visitedViews.find(it => it.name === 'orderPersonaInputUpdate');
    },
    batchFetchData() {
      const {
        unGroupRef,
        inGroupRef
      } = this.$refs;
      unGroupRef && this.$refs.unGroupRef.handleSearch();
      inGroupRef && this.$refs.inGroupRef.handleSearch();
    },
    changeTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
      this.$store.commit('order/personal/PERSONAL_TAB_ACTIVE', this.activeName);
    },
    // 退单
    revokeTableRow(scope) {
      const {
        id,
        isCancel
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/revoke', {
          id,
          isCancel: Number(!isCancel)
        }).then(res => {
          const {
            message
          } = res;
          this.$bus.tip({
            message
          });
          this.fetchData();
        });
      }, '确定要退单吗？ 是否继续');
    },
    // 转移计划
    transferRow(scope) {
      const {
        id
      } = scope.row;
      this.$refs.transferRef.show(id);
    },
    // 取消计划/移除计划
    cancelTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/cancelPlan', id).then(() => {
          this.$bus.tip();
          this.batchFetchData();
          // 小团号列表
          this.currentPlanId && this.$store.dispatch('plan/index/getOrderByPlanId', this.currentPlanId);
        });
      });
    },
    // 删除计划
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.$store.dispatch('order/index/deleteOrder', id).then(res => {
          const {
            message
          } = res;
          this.$bus.tip({
            message
          });
          this.fetchData(this.searchParams);
        });
      });
    },
    // 编辑计划
    editTableRow(scope) {
      const {
        id,
        customerId
      } = scope.row;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: this.link.update,
        query: {
          id,
          customerId,
          type: 'update'
        }
      });
    },
    // 查看计划
    showTableDetail(scope) {
      const {
        id
      } = scope.row;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: this.link.detail,
        query: {
          id,
          type: 'detail',
          planType: 'S_TOURIST'
        }
      });
    },
    // 复制订单
    copyTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$router.push({
        path: this.link.clone,
        query: {
          id,
          type: 'clone'
        }
      });
    }
  }
};