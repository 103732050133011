import "core-js/modules/es.array.reduce.js";
export default {
  data() {
    return {
      data: [],
      visible: false,
      hotelSelf: 'hotelCustom',
      scenicSelf: 'scenicCustom',
      restaurantSelf: 'restaurantCustom',
      busSelf: 'busCustom',
      isConfirm: false
    };
  },
  computed: {
    hasMore() {
      return list => {
        return list.length > 0;
      };
    },
    getName() {
      return hotels => {
        return hotels.reduce((acc, cur) => {
          return `${acc} ${cur.name || cur.hotelName}`;
        }, '');
      };
    },
    splitName() {
      return (item, name = 'name') => {
        if (!item) return '';
        const value = item[name];
        if (!value) return '';
        return value.length > 5 ? value.slice(0, 5) + '...' : value;
      };
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/personal/findPlan', id).then(data => {
        this.data = this.formatData(data);
      });
    },
    formatData(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.hotelSelf = it.id;
        it.scenicSelf = it.id;
        it.restaurantSelf = it.id;
        it.busSelf = it.id;
        return it;
      });
    },
    show(id) {
      this.visible = true;
      this.fetchData(id);
    },
    hide() {
      this.visible = false;
      this.restoreState();
    },
    radioSelect(item, type) {
      item[type] = item.id;
    },
    radioCustom(item, type, value) {
      item[type] = value;
    },
    changeAll(radio, value, checked) {
      this.data = this.data.map(it => {
        it[radio] = checked ? value : it.id;
        return it;
      });
    },
    handleCreate() {
      this.$bus.emit('group:custom', this.data);
      this.isConfirm = true;
      this.hide();
    },
    handleCancel() {
      this.hide();
      if (this.isConfirm) return;
      this.$bus.emit('group:custom', this.data);
    },
    restoreState() {
      this.hotelSelf = false;
      this.scenicSelf = false;
      this.restaurantSelf = false;
      this.busSelf = false;
      this.$store.dispatch('app/setDialogLoading', false);
    }
  }
};