import guideMixin from './guide';
const columnData = [{
  label: '上团日期',
  prop: 'name',
  width: '120px'
}, {
  label: '下团日期',
  prop: 'endTripDate',
  width: '120px'
}, {
  label: '带团人数',
  prop: 'number'
}, {
  label: '导服费',
  prop: 'guideServiceFee'
}, {
  label: '备注',
  prop: 'remark',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};