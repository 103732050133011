import "core-js/modules/es.array.push.js";
import { incomeReport } from '@/config/report';
import { BtnExport } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
import { parseDate } from '@/utils';
export default {
  components: {
    BtnExport
  },
  data: () => ({
    startTripDate: [],
    searchParams: {
      startTripDateS: '',
      startTripDateE: '',
      orderType: ''
    },
    columnData: incomeReport,
    reportPlanType: [{
      label: '全部',
      value: null
    }, {
      label: '团客',
      value: 'T_TOURIST'
    }, {
      label: '散客',
      value: 'S_TOURIST'
    }],
    tableData: {},
    tableShow: false,
    transTitle: [],
    rowSpantitle: [{
      name: '一、接待人数',
      code: 'adultCount',
      bgcolor: 'green'
    }, {
      name: '月人均',
      code: 'PerMonth',
      bgcolor: ''
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '二、收入',
      code: 'IncomeTotal',
      bgcolor: 'green'
    }, {
      name: '其中：代收团款',
      code: 'otherIncome',
      bgcolor: ''
    }, {
      name: '现收团款',
      code: 'collectionIncome',
      bgcolor: ''
    }, {
      name: '应收团款',
      code: 'income',
      bgcolor: ''
    }, {
      name: '导游加点',
      code: 'scenicExtraGuideIncome',
      bgcolor: ''
    }, {
      name: '购物佣金',
      code: 'shopIncome',
      bgcolor: ''
    }, {
      name: '团款调整',
      code: '',
      bgcolor: 'yellow'
    }, {
      name: '购物调整',
      code: '',
      bgcolor: 'yellow'
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '三、成本',
      code: 'costTotal',
      bgcolor: 'green'
    }, {
      name: '其中：现付成本',
      code: 'guidePay',
      bgcolor: ''
    }, {
      name: '应付成本',
      code: 'pay',
      bgcolor: ''
    }, {
      name: '本月调整',
      code: '',
      bgcolor: 'yellow'
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '四、本月毛利',
      code: 'grossMarginTotal',
      bgcolor: 'green'
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '五、费用',
      code: '',
      bgcolor: 'yellow'
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '六、本月利润',
      code: '',
      bgcolor: 'yellow'
    }, {
      name: null,
      code: null,
      bgcolor: null
    }, {
      name: '七、接待团数',
      code: 'orderCount',
      bgcolor: 'green'
    }]
  }),
  created() {
    let now = new Date(); //当前日期
    let nowMonth = now.getMonth(); //当前月
    let nowYear = now.getFullYear(); //当前年
    //本月的开始时间
    let monthStartDate = new Date(nowYear, nowMonth, 1);
    //本月的结束时间
    let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
    this.startTripDate = [parseDate(monthStartDate), parseDate(monthEndDate)];
    this.searchParams.startTripDateS = parseDate(monthStartDate);
    this.searchParams.startTripDateE = parseDate(monthEndDate);
    this.fetchData(this.searchParams);
  },
  methods: {
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      const opts = this.searchParams;
      this.$store.dispatch('report/index/fetchMonthlyOPExcel', opts).then(res => {
        const fileName = opts.startTripDateS + '至' + opts.startTripDateE + '营业报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(date) {
      this.searchParams.startTripDateS = date[0];
      this.searchParams.startTripDateE = date[1];
    },
    formatTransTitle() {
      // 下方月经营状况表
      this.tableData.incomeList = [];
      this.tableData.incomeList.push(this.tableData.income);
      // 年累计收入
      this.tableData.yearOpStatic.IncomeTotal = (Number(this.tableData.yearOpStatic.otherIncome) * 100 + Number(this.tableData.yearOpStatic.income) * 100 + Number(this.tableData.yearOpStatic.collectionIncome) * 100 + Number(this.tableData.yearOpStatic.otherIncome) * 100 + Number(this.tableData.yearOpStatic.scenicExtraGuideIncome) * 100 + Number(this.tableData.yearOpStatic.shopIncome) * 100) / 100;

      // 年累计成本
      this.tableData.yearOpStatic.costTotal = (Number(this.tableData.yearOpStatic.guidePay) * 100 + Number(this.tableData.yearOpStatic.pay) * 100) / 100;
      this.tableData.monthOpes.forEach(item => {
        // 收入
        item.IncomeTotal = (Number(item.otherIncome) * 100 + Number(item.income) * 100 + Number(item.collectionIncome) * 100 + Number(item.otherIncome) * 100 + Number(item.scenicExtraGuideIncome) * 100 + Number(item.shopIncome) * 100) / 100;

        // 成本
        item.costTotal = (Number(item.guidePay) * 100 + Number(item.pay) * 100) / 100;

        // 本月毛利
        item.grossMarginTotal = (Number(item.costTotal) * 100 - Number(item.IncomeTotal) * 100) / 100;

        // 月人均
        if (!item.adultCount) {
          item.PerMonth = 0;
        } else {
          item.PerMonth = Number(item.grossMarginTotal) * 100 / (Number(item.adultCount) * 100);
        }
        // 小计求和
        Object.keys(this.tableData.subtotal).forEach(keys => {
          this.tableData.subtotal[keys] += item[keys];
        });
        this.tableShow = true;
      });
    },
    // 保留两位小数
    toDecimal(num) {
      var val = Number(num);
      // 如果是整数则直接返回
      if (Number.isInteger(num)) return num;
      if (!isNaN(parseFloat(num))) {
        val = val.toFixed(2); //把 Number 四舍五入为指定小数位数的数字。
      }
      return val;
    },
    // 搜索
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getMonthlyOPStatust', queryParam).then(res => {
        this.tableLoading = false;
        this.transTitle = [];
        res.monthOpes.forEach(item => {
          this.transTitle.push(item.areaName);
          item.IncomeTotal = 0;
          item.PerMonth = 0;
          item.costTotal = 0;
          item.grossMarginTotal = 0;
          // 定义小计字段
          res.subtotal = {};
          // 判断值为null时赋值为0
          Object.keys(item).forEach(keyItem => {
            if (!isNaN(item[keyItem])) {
              if (!item[keyItem]) {
                item[keyItem] = 0;
              }
              // 定义小计字段
              res.subtotal[keyItem] = 0;
            }
          });
        });
        this.tableData = res;
        this.formatTransTitle();
      }).catch(() => {
        this.tableLoading = false;
      });
    }
  }
};