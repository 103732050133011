import { inputMixin } from '@/mixin';
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  }

  // initData(data) {
  //   if (!data) return
  //   this.list = this.formartList(data.list)
  // },
};