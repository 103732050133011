import { orderPickupDetail } from '@/config/order';
import { mapGetters } from 'vuex';
import { SELECTOR } from '@/config/select';
import { dictionaryMixin, reloadMixin } from '@/mixin';
import { parseTime } from '@/utils';
export default {
  mixins: [dictionaryMixin, reloadMixin],
  data() {
    return {
      columnData: orderPickupDetail,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      dictionaryData: 'system/dictionary/dictionaryCodeData'
    }),
    // 判断类型
    getShuttleType() {
      return type => {
        const name = SELECTOR.taskType.filter(item => item.value == type.toLowerCase())[0].label;
        return name;
      };
    },
    // 判断接送站点
    getType() {
      return row => {
        if (row.shuttleType.toLowerCase() != 'city') {
          const name = SELECTOR.targetType.filter(item => item.value == row.taskType.toLowerCase())[0].label;
          return name;
        } else {
          const name = SELECTOR.taskCityType.filter(item => item.value == row.taskType.toLowerCase())[0].label;
          return name;
        }
      };
    },
    getAndTarget() {
      return row => {
        if (row.shuttleType.toLowerCase() != 'city') {
          return row.shiftNumber;
        } else {
          return row.togetherPosition;
        }
      };
    },
    // 航天楼、车站
    getDesPosition() {
      return id => {
        if (!id) return '--';
        const list = this.dictionaryData.stationType.agencyDictionaryList;
        const name = list.filter(item => item.id == id)[0].name;
        return name;
      };
    }
  },
  // watch: {
  //   currentOrderId: {
  //     handler: 'handleInit',
  //     immediate: true,
  //   },
  //   currentPickup: {
  //     handler: 'handleData',
  //     immediate: true,
  //   },
  // },

  mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchPickup', id).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    // handleInit(id) {
    //   id && this.fetchData(id)
    // },

    handleData(data) {
      if (!data) return [];
      this.tableData = data.map(it => {
        it.shiftDateTime = it.shiftDateTime && parseTime(it.shiftDateTime);
        return it;
      });
    }
  }
};