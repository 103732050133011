import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/***************** 下账 ******************/
// 现金日记类型：
/*
resource_customer=收团款
resource_hotel=付酒店/房间
resource_restaurant=付餐厅
resource_visa=付签证
resource_insurance=付保险
resource_scenic=付景区
resource_transport_company=付大交通
verification_other_income=其他收入核销
resource_bus_company=付车队
VERIFICATION_OTHER_PAY=其他支出核销
verification_customer_collect=客户代收核销,verification_customer_subsidy=客户返款核销
verification_bus_company_netpay=车费网付核销,guide_borrow_apply=导游借款申请
guide_borrow=导游借款
guide_handback=导游交款
guide_reimb=导游报账
custom_subject=自定义现金日志
head_company=按公司下账
resource_out_transfer=付外转
verification_guide_netpay=导服费网付核销
resource_head_company_income=预收集团公司款
resource_head_company_pay=预付集团公司款
resource_bank_transfer=卡卡转账
resource_bank_adjust=卡内调
resource_bank=付账户
resource_shop=付购物店
resource_guide=付导游,
resource_guide=付导游,
resource_other_pay=付其他单位,
*/
export const RESOURCE_TYPES = 'RESOURCE_CUSTOMER,RESOURCE_HOTEL,RESOURCE_RESTAURANT,RESOURCE_VISA,RESOURCE_INSURANCE,RESOURCE_SCENIC,RESOURCE_BUS_COMPANY,RESOURCE_TRANSPORT_COMPANY,VERIFICATION_OTHER_INCOME,,VERIFICATION_CUSTOMER_COLLECT,RESOURCE_CUSTOMER_SUBSIDY,VERIFICATION_BUS_COMPANY_NETPAY,VERIFICATION_GUIDE_NETPAY,GUIDE_BORROW_APPLY,GUIDE_BORROW,GUIDE_HANDBACK,GUIDE_REIMB,CUSTOM_SUBJECT,HEAN_COMPANY,RESOURCE_OUT_TRANSFER,RESOURCE_HEAD_COMPANY_INCOME,RESOURCE_HEAD_COMPANY_PAY,RESOURCE_BANK_TRANSFER,RESOURCE_BANK_ADJUST,RESOURCE_BANK,RESOURCE_SHOP,RESOURCE_GUIDE,RESOURCE_OTHER_PAY,RESOURCE_SHUTTLE,RESOURCE_SHOP_SUBSIDY,KP_COMPANY';
export const TYPES = RESOURCE_TYPES.split(',').reduce((acc, cur) => {
  const arr = cur.split('_');
  let key = '';
  if (arr.length > 1) {
    key = arr.slice(1).map(it => it.toLowerCase()).join('_');
  } else {
    key = arr[1];
  }
  acc[key] = cur;
  return acc;
}, {});

// 报账状态， WAIT_ACCEPT 待接单 submit-已提交，confirm-已确认，back-退回，unConfirm-未确认,可用值:SUBMIT,CONFIRM,BACK,UN_CONFIRM

export const ReimbStatus = {
  SUBMIT: '已接团',
  CONFIRM: '已确认',
  BACK: '退回',
  UN_CONFIRM: '未确认',
  WAIT_ACCEPT: '未接团'
};

// 现金日记类型resource_customer=收团款，resource_hotel=付酒店/房间，resource_restaurant=付餐厅,resource_visa=付签证,resource_insurance=付保险， resource_scenic=付景区，resource_transport_company=付大交通，verification_other_income=其他收入核销，resource_bus_company=付车队， =其他支出核销，verification_customer_collect=客户代收核销,verification_customer_subsidy=客户返款核销，verification_bus_company_netpay=车费网付核销,guide_borrow_apply=导游借款申请，guide_borrow=导游借款，guide_handback=导游交款，guide_reimb=导游报账，custom_subject=自定义现金日志，head_company=按公司下账，resource_out_transfer=付外转，verification_guide_netpay=导服费网付核销，resource_head_company_income=预收集团公司款，resource_head_company_pay=预付集团公司款，resource_bank_transfer=卡卡转账，resource_bank_adjust=卡内调， resource_bank=付账户,resource_shop=付购物店，resource_guide=付导游,
// 可用值:RESOURCE_CUSTOMER,RESOURCE_HOTEL,RESOURCE_RESTAURANT, RESOURCE_VISA, RESOURCE_INSURANCE,RESOURCE_SCENIC, RESOURCE_BUS_COMPANY,RESOURCE_TRANSPORT_COMPANY,VERIFICATION_OTHER_INCOME,VERIFICATION_OTHER_PAY,VERIFICATION_CUSTOMER_COLLECT,VERIFICATION_CUSTOMER_SUBSIDY,VERIFICATION_BUS_COMPANY_NETPAY,VERIFICATION_GUIDE_NETPAY,GUIDE_BORROW_APPLY,GUIDE_BORROW,GUIDE_HANDBACK,GUIDE_REIMB,CUSTOM_SUBJECT,HEAN_COMPANY,RESOURCE_HEAD_COMPANY_INCOME,RESOURCE_HEAD_COMPANY_PAY,RESOURCE_BANK_TRANSFER,RESOURCE_BANK_ADJUST,RESOURCE_BANK,RESOURCE_SHOP,RESOURCE_GUIDE

export const cashLogType = {
  RESOURCE_CUSTOMER: '收团款',
  RESOURCE_HOTEL: '付酒店',
  RESOURCE_RESTAURANT: '付餐厅',
  RESOURCE_VISA: '付签证',
  RESOURCE_INSURANCE: '付保险',
  RESOURCE_SCENIC: '付景区',
  RESOURCE_BUS_COMPANY: '付车队',
  RESOURCE_SUBSIDY: '付返款',
  RESOURCE_TRANSPORT_COMPANY: '付大交',
  VERIFICATION_OTHER_INCOME: '其他收入核销',
  VERIFICATION_OTHER_PAY: '其他支出核销',
  VERIFICATION_CUSTOMER_COLLECT: '客户代收核销',
  VERIFICATION_CUSTOMER_SUBSIDY: '客户返款核销',
  VERIFICATION_BUS_COMPANY_NETPAY: '车费网付核销',
  GUIDE_BORROW_APPLY: '导游借款申请',
  GUIDE_BORROW: '导游借款',
  GUIDE_HANDBACK: '导游交款',
  GUIDE_REIMB: '导游报账',
  CUSTOM_SUBJECT: '自定义现金日志',
  HEAN_COMPANY: '按公司下账',
  RESOURCE_HEAD_COMPANY_INCOME: '预收集团公司款',
  RESOURCE_HEAD_COMPANY_PAY: '预付集团公司款',
  RESOURCE_BANK_TRANSFER: '卡卡转账',
  RESOURCE_BANK_ADJUST: '卡内调',
  RESOURCE_BANK: '付账户',
  RESOURCE_SHOP: '购物店',
  RESOURCE_GUIDE: '导游',
  RESOURCE_OTHER_PAY: '付其他单位',
  VERIFICATION_GUIDE_NETPAY: '导服费网付核销'
};
let cashLogTypeList = Object.keys(cashLogType).reduce((acc, keys) => {
  acc.push({
    value: keys.toLowerCase(),
    label: cashLogType[keys]
  });
  return acc;
}, []);
export const CASHLOGTYPE = cashLogTypeList;

// 下账资源单位类型（仅用于其它收入） DEFAULT-默认，transport-大交通，bus-车队，scenic-景区，guide-导游， hotel-酒店，insurance-保险， shop-购物店，\nrestaurant-餐厅，customer-客户，company-公司，第三方资源公司，other-其他单位,可用值:DEFAULT,TRANSPORT,BUS,SCENIC,GUIDE,HOTEL,INSURANCE,SHOP,RESTAURANT,CUSTOMER,COMPANY,OTHER

export const REIMBEDUNIT_TYPE = {};
export const CashWay = {
  CARD: '银行卡转账',
  CASH: '现金',
  RECONCILIATION: '调账',
  ENTERPRISE: '企业码付' // TODO:
};

// 费用类型
export const CashFeeType = {
  INCOME: 'INCOME',
  PAY: 'PAY'
};

// 费用类型
export const cashFeeTypeLabel = {
  INCOME: '收入',
  PAY: '支出'
};
// 会计科目费用类型
export const subjectTypeLabel = {
  INCOME: '主营业务收入',
  PAY: '主营业务支出'
};

// 下账模式
export const cashModeType = {
  COMMON: 'COMMON',
  PRE_PAYMENT: 'PRE_PAYMENT',
  OTHER: 'OTHER'
};

// 下账模式
export const cashModeTypeLabel = {
  COMMON: '常规',
  PRE_PAYMENT: '预付预收',
  OTHER: '其他'
};
const dateWidth = 100;
const planCustomerNumber = {
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': 120
  }
};
const orderName = {
  attrs: {
    prop: 'orderNumber',
    label: '流水号'
  }
};
const orderOpMain = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
};
const tripDate = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': dateWidth
  },
  slot: 'tripDate'
};
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '订单日期',
    'min-width': dateWidth
  }
};
const applyDate = {
  attrs: {
    prop: 'applyDate',
    label: '申请日期',
    'min-width': dateWidth
  }
};
const collectDate = {
  attrs: {
    prop: 'cashDate',
    label: '收款日期',
    'min-width': dateWidth
  }
};
const payDate = {
  attrs: {
    prop: 'payDate',
    label: '付款日期',
    'min-width': dateWidth
  }
};
const applyMoney = {
  attrs: {
    prop: 'applyMoney',
    label: '申请金额',
    'min-width': 80
  }
};
const applyer = {
  attrs: {
    prop: 'applyer',
    label: '申请人',
    'min-width': 80
  }
};
const collectMode = {
  attrs: {
    prop: 'cashWay',
    label: '收款方式'
  }
};
const payMode = {
  attrs: {
    prop: 'payMode',
    label: '付款方式'
  }
};
const collecter = {
  attrs: {
    prop: 'cashUserName',
    label: '收款人'
  }
};
const payer = {
  attrs: {
    prop: 'payer',
    label: '付款人'
  }
};
const money = {
  attrs: {
    prop: 'money',
    label: '金额'
  },
  slot: 'money'
};
const status = {
  attrs: {
    prop: 'status',
    label: '出纳确认状态'
  }
};
const auditStatus = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': 90
  },
  slot: 'auditStatus'
};
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100,
    fixed: 'right'
  },
  slot: 'action',
  header: 'actionHead'
};

// const actionHead = {
//   attrs: { prop: '', label: '操作', 'min-width': 120, fixed: 'right' },
//   header: 'actionHead',
// }

const orderInfo = {
  attrs: {
    prop: 'planInfo',
    label: '订单信息',
    'min-width': 120
  },
  slot: 'planInfo'
};

// const product = { attrs: { prop: 'productName', label: '产品线路' } }
const customer = {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'custom'
};
const orderTouristContact = {
  attrs: {
    prop: 'welcomeBorad',
    label: '游客联系人',
    'min-width': 140
  }
};
const orderPeople = {
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': 140
  },
  slot: 'number'
};
const createTime = {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
};
const creater = {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
};
const remark = {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
};
const remarkSlot = {
  ...remark,
  slot: 'remarks'
};
const guideBill = {
  attrs: {
    prop: 'guidesBill',
    label: '报账导游',
    'min-width': 120
  },
  slot: 'guidesBill'
};
const collected = {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'totalCollect',
      label: '代收'
    }
  }, {
    attrs: {
      prop: 'totalSubsidy',
      label: '返款'
    }
  }, {
    attrs: {
      prop: 'agencyMoney',
      label: '社收'
    }
  }]
};
const receiveMoney = {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
};
const oweIncome = {
  attrs: {
    prop: 'owePay',
    label: '欠收'
  }
};
const downPayed = {
  attrs: {
    prop: 'downPayed',
    label: '本次下账',
    'min-width': 100
  },
  slot: 'downPayed'
};
export const unitPay = [{
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, collectDate, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账号',
    'min-width': 240
  }
}, {
  attrs: {
    prop: 'money',
    label: '销账金额'
  }
}, {
  attrs: {
    prop: 'balance',
    label: '余额'
  }
}, {
  attrs: {
    prop: 'invoiceMoney',
    label: '发票金额'
  }
}, {
  attrs: {
    prop: 'invoiceNumber',
    label: '发票编码'
  }
}, remark, {
  attrs: {
    prop: 'cashUserName',
    label: '付款人'
  }
}, creater, createTime, actionItem];

// 按团号去付款（付款记录）
export const downBillPayRecord = [planCustomerNumber, orderName, orderOpMain, orderDate, applyDate, applyMoney, applyer, payMode, payDate, payer, money, status, actionItem];

/***************** 列表 ******************/
// 按单位 收团款
export const downBillUnitTeamFund = [{
  attrs: {
    prop: 'resourceName',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  },
  slot: 'planNumber'
}, ...unitPay.slice(0, 4), {
  attrs: {
    prop: 'userMoney',
    label: '销账金额'
  }
}, ...unitPay.slice(5, 8), {
  attrs: {
    prop: 'cashUserName',
    label: '收款人'
  }
}, creater, createTime, actionItem];

// 按单位 付车队
export const downBillUnitFleet = [{
  attrs: {
    prop: 'resourceName',
    label: '车队'
  }
}, ...unitPay];

// 按单位 付保险
export const downBillUnitInsurance = [{
  attrs: {
    prop: 'resourceName',
    label: '保险'
  }
}, ...unitPay];

// 按单位 付酒店
export const downBillUnitHotel = [{
  attrs: {
    prop: 'resourceName',
    label: '酒店'
  }
}, ...unitPay];

// 按团号去付款 地接
export const downBillUnitReception = [{
  attrs: {
    prop: 'resourceName',
    label: '外转'
  }
}, ...unitPay];

// 按团号去付款 餐厅
export const downBillUnitRestaurant = [{
  attrs: {
    prop: 'resourceName',
    label: '餐厅'
  }
}, ...unitPay];

// 按单位 付交通
export const downBillUnitTraffic = [{
  attrs: {
    prop: 'resourceName',
    label: '交通'
  }
}, ...unitPay];

// 按单位 付景区
export const downBillUnitScenic = [{
  attrs: {
    prop: 'resourceName',
    label: '景区'
  }
}, ...unitPay];
// // 对账时间
// const checkDate = {
//   attrs: { prop: 'checkAccountTime', label: '对账时间', 'min-width': 100 },
//   slot: 'checkAccountTime',
// }
// // 对账人
// const checker = {
//   attrs: { prop: 'checkAccountUserName', label: '对账人' },
// }

const travelAgencyPay = {
  attrs: {
    prop: 'travelAgencyPay',
    label: '应报'
  },
  slot: 'travelAgencyPay'
};
const travelAgencyPayed = {
  attrs: {
    prop: 'travelAgencyPayed',
    label: '已报'
  },
  slot: 'travelAgencyPayed'
};
const unPay = {
  attrs: {
    prop: 'unPay',
    label: '欠报'
  },
  slot: 'unPay'
};

// 导游单团报账 列表
export const downBillGuide = [orderInfo, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, travelAgencyPay, travelAgencyPayed, unPay, tripDate, orderPeople, {
  attrs: {
    prop: 'reimbStatus',
    label: '报账状态'
  },
  slot: 'reimbStatus'
}, remarkSlot, auditStatus, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action',
  header: 'actionHead'
}];

// 导游批量报账
export const downBillGuideBatch = [{
  attrs: {
    prop: 'planNumbers',
    label: '大团号'
  },
  slot: 'planNumbers'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '付款日期'
  }
}, {
  attrs: {
    prop: 'invoiceMoney',
    label: '发票金额'
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账号'
  }
}, {
  attrs: {
    prop: 'cash',
    label: '报账金额'
  },
  slot: 'cash'
}, {
  attrs: {
    prop: 'used',
    label: '下账金额'
  },
  slot: 'used'
}, {
  attrs: {
    prop: 'balance',
    label: '余额'
  },
  slot: 'balance'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '付款人'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, actionItem];

/***************** 已结清 ******************/
// 按单位 收团款
export const downBillUnitPayed = [planCustomerNumber, orderName, orderOpMain, {
  attrs: {
    prop: 'startTripDate',
    label: '订单日期'
  }
}, collectMode, collectDate, collecter, {
  attrs: {
    prop: 'cash',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100
  },
  slot: 'action',
  header: 'actionHead'
}];

// 导游 已结清
export const downBillGuidePayed = [planCustomerNumber, orderOpMain, {
  attrs: {
    prop: 'companyName',
    label: '单位'
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'feeType',
    label: '费用类型'
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '下账方式'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '下账日期'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '下账人'
  }
}, money, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': 100
  },
  slot: 'action',
  header: 'actionHead'
}];

/***************** 未结清 ******************/
// 导游 未结清
export const downBillGuideUnPay = [{
  attrs: {
    prop: 'planCustomerNumber',
    label: '大团号',
    'min-width': 120
  },
  slot: 'planCustomerNumber'
}, {
  attrs: {
    prop: 'startTripDate',
    label: '发团日期'
  }
}, orderDate, orderOpMain, {
  attrs: {
    prop: 'companyName',
    label: '单位'
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'feeType',
    label: '费用类型'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '应报'
  },
  slot: 'totalPay'
}, {
  attrs: {
    prop: 'totalPayed',
    label: '已报'
  },
  slot: 'totalPayed'
}, {
  attrs: {
    prop: 'owePay',
    label: '欠报'
  },
  slot: 'owePay'
}, {
  attrs: {
    prop: 'downPayed',
    label: '本次下账'
  },
  slot: 'downPayed'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, actionItem];

// 按单位 收团款
export const downBillUnitCollect = [orderInfo, auditStatus, tripDate, customer, guideBill, orderTouristContact, receiveMoney, collected, oweIncome, downPayed, actionItem];