var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "flex",
    staticStyle: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("h2", {
    staticClass: "fl"
  }, [_vm._v(" 部门管理 ")]), _c("BtnBase", {
    staticClass: "fr",
    attrs: {
      type: "create"
    },
    on: {
      click: _vm.showDialog
    }
  }, [_vm._v(" 创建部门 ")])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      xs: 24
    }
  }, [_c("el-card", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("LabelBase", {
    attrs: {
      label: "组织结构"
    }
  })], 1), _c("TreeDepartment", {
    on: {
      edit: _vm.handleEdit,
      remove: _vm.handleRemove,
      click: _vm.getStaffList
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 18,
      xs: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("LabelBase", {
    attrs: {
      label: "部门成员列表"
    }
  })], 1), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1)], 1)], 1), _c("FormDepartmentChild", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };