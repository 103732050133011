var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.data,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: scope.row.confirmStatus ? "success" : "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.confirmStatus ? "已确认" : "未确认") + " ")])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "progress",
      fn: function ({
        scope
      }) {
        return [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "400",
            trigger: "click"
          },
          on: {
            show: function ($event) {
              return _vm.fetchProgressData(scope.row.id);
            }
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.progressData,
            border: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "type",
            label: "类型"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (local) {
              return [_vm._v(" " + _vm._s(_vm.getType(local.row.type)) + " ")];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            property: "progress",
            label: "进度"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (local) {
              return [_vm._v(" " + _vm._s(local.row.progress || 0) + " ")];
            }
          }], null, true)
        })], 1), _c("el-progress", {
          attrs: {
            slot: "reference",
            status: _vm.getStatus(scope.row.progress),
            "text-inside": true,
            "stroke-width": 18,
            percentage: scope.row.progress
          },
          slot: "reference"
        })], 1)];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("br"), _c("span", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.row.id);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.convertOrder(scope.row.id, _vm.OrderType.team, scope.row.customerId);
            }
          }
        }, [_vm._v(" 转团队 ")]), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.convertOrder(scope.row.id, _vm.OrderType.personal, scope.row.customerId);
            }
          }
        }, [_vm._v(" 转散客 ")]), _c("BtnLink", {
          attrs: {
            type: scope.row.isCancel ? "danger" : "primary"
          },
          on: {
            click: function ($event) {
              return _vm.cancelOrder(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.isCancel ? "撤销" : "取消") + " ")]), _c("BtnLink", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.orderLog(scope.row);
            }
          }
        }, [_vm._v(" 日志 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };