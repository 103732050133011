var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "编辑游客船票类型",
      fullscreen: "",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleSave,
      cancel: _vm.hide
    }
  }, [_c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      "no-page": true,
      "show-summary": false
    },
    scopedSlots: _vm._u([{
      key: "shipType",
      fn: function ({
        row
      }) {
        return [_c("SelectBase", {
          attrs: {
            type: "shipType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.batchChangeVal("shipType", row.mergeId, $event);
            }
          },
          model: {
            value: row.shipType,
            callback: function ($$v) {
              _vm.$set(row, "shipType", $$v);
            },
            expression: "row.shipType"
          }
        })];
      }
    }, {
      key: "shipTicketType",
      fn: function ({
        row
      }) {
        return [_c("SelectBase", {
          attrs: {
            type: "boatTicketType"
          },
          on: {
            "on-select": function ($event) {
              return _vm.batchChangeVal("shipTicketType", row.mergeId, $event);
            }
          },
          model: {
            value: row.shipTicketType,
            callback: function ($$v) {
              _vm.$set(row, "shipTicketType", $$v);
            },
            expression: "row.shipTicketType"
          }
        })];
      }
    }, {
      key: "ticket",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.ticket) + " ")];
      }
    }, {
      key: "status",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.status) + " ")];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: {
              row
            }
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };