var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "alipay-e-code"
  }, [_c("div", {
    staticClass: "flex flex-item-center"
  }, [_c("h3", [_vm._v("数据统计")]), _c("div", {
    staticClass: "red ml20"
  }, [_vm._v("当前支付宝余额: " + _vm._s(_vm.balance))]), _c("div", {
    staticClass: "green ml20"
  }, [_vm._v("预计现付总额: " + _vm._s(_vm.getAllAmount))]), _c("div", {
    staticClass: "black ml20"
  }, [_vm._v("申请额度总额: " + _vm._s(_vm.getTotalAmount))]), _c("div", {
    staticClass: "red ml20"
  }, [_vm._v("实际支付总额: " + _vm._s(_vm.getPeerAmount))]), _c("div", {
    staticClass: "blue ml20"
  }, [_vm._v("未付总额: " + _vm._s(_vm.getUnAmount))]), _c("div", {
    staticClass: "red ml20 mr20"
  }, [_vm._v("建议充值: " + _vm._s(_vm.getSuggestAmount))]), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v(" 刷新数据 ")])], 1), _vm.tableData && _vm.weekColumns.length ? _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.weekColumns,
      dynamic: false,
      "row-class-name": _vm.tableRowClassName,
      "header-cell-class-name": _vm.tableHeaderClassName,
      border: ""
    }
  }) : _vm._e(), _c("div", {
    staticClass: "flex flex-item-center"
  }, [_c("h3", [_vm._v("额度发放额度")]), _c("div", {
    staticClass: "red ml20"
  }, [_vm._v("累计发放余额: " + _vm._s(_vm.grantLimitMoney))])]), _c("TableNew", {
    attrs: {
      data: _vm.tableRecord,
      columns: _vm.columnsRecord,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StataticsKeys,
      "fix-height": 500
    },
    on: {
      page: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };