import "core-js/modules/es.array.push.js";
import TableCount from '../components/TableCount';
import TicketAdd from '../components/TicketAdd';
import { FooterBar } from '@/components/common';
import { mapGetters } from 'vuex';
export default {
  components: {
    TableCount,
    TicketAdd,
    FooterBar
  },
  data() {
    return {
      checkAll: false,
      removeCache: [],
      tableLoading: true,
      tableCountLoading: true,
      tableData: [],
      tableCountData: []
    };
  },
  computed: {
    ...mapGetters({
      currentPlan: 'ticket/index/currentPlan'
    }),
    getBtnStatus() {
      return this.tableData && !this.tableData.length;
    }
  },
  mounted() {
    this.initData();
    this.handleEmit();
  },
  methods: {
    handleEmit() {
      this.$bus.on('ticket:reload', this.fetchTicketItems);
    },
    initData() {
      this.fetchTicketItems();
      this.fetchTicketCount();
    },
    // 签单数量列表
    fetchTicketCount() {
      const {
        id
      } = this.$route.query;
      this.tableCountLoading = true;
      this.$store.dispatch('ticket/index/fetchTicketCount', id).then(data => {
        this.tableCountData = this.formatTicketCount(data);
        this.tableCountLoading = false;
      }).catch(() => {
        this.tableCountLoading = false;
      });
    },
    // 签单列表
    fetchTicketItems() {
      const {
        id
      } = this.$route.query;
      this.tableLoading = true;
      this.$store.dispatch('ticket/index/fetchTicketItems', id).then(data => {
        this.tableData = data;
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    findCurrentTicket() {
      this.$store.dispatch('ticket/index/fetchPlan', {
        planId: this.id
      }).then(data => {
        if (!data) {
          const cur = data.list.find(it => it.id == this.id);
          this.$store.commit('ticket/index/CURRENT_PLAN', cur);
        }
      });
    },
    // 添加签单
    handleNew(form) {
      this.$store.dispatch('ticket/index/batchCreateTicketItem', form).then(() => {
        this.initData();
      });
    },
    // 保存更新
    batchUpdate() {
      if (!this.tableData || !this.tableData.length) {
        return;
      }
      const optsList = this.processData(this.tableData);
      this.$store.dispatch('ticket/index/updateTicketItems', optsList).then(() => {
        this.fetchTicketItems();
        // 获取签单列表匹配当前选择的签单
        // this.findCurrentTicket()
        this.$bus.tip();
      });
    },
    processData(list) {
      const {
        id: planId
      } = this.$route.query;
      return list.map(it => {
        const {
          autoRelease,
          id,
          isUsed,
          remarks
        } = it;
        return {
          id,
          planId,
          isUsedStatus: isUsed,
          isUsed,
          remarks,
          autoReleaseStatus: autoRelease,
          autoRelease
        };
      });
    },
    // 格式签单数量
    formatTicketCount(data) {
      const {
        hotelSign,
        restaurantSign,
        scenicSign
      } = data;
      const cashType = 'guide';
      const doFormat = (list, type) => {
        if (!list || !list.length) return [];
        return list.map(it => {
          const item = {
            id: it.id || '',
            type
          };
          if (it.cashType === cashType) {
            item.countCash = it.count;
            item.unitCash = it.unit;
          } else {
            if (it.isCommon) {
              item.countCommon = it.count;
              item.unitCommon = it.unit;
            } else {
              item.count = it.count;
              item.unit = it.unit;
            }
          }
          return item;
        });
      };
      const hotelList = doFormat(hotelSign, '酒店');
      const restaurantList = doFormat(restaurantSign, '餐厅');
      const scenicList = doFormat(scenicSign, '景区');
      return [...hotelList, ...restaurantList, ...scenicList];
    },
    // 单个选中
    handleCheckOne({
      row,
      val
    }) {
      const {
        id
      } = row;
      if (val) {
        this.removeCache.push(id);
      } else {
        this.resetRemoveCache(id);
      }
      this.checkAll = this.tableData.every(it => {
        return it.isChecked === true;
      });
    },
    // 全部选择
    handleCheckAll(val) {
      this.tableData = this.tableData.map(it => {
        it.isChecked = val;
        if (val) {
          this.removeCache.push(it.id);
        } else {
          this.removeCache = [];
        }
        return it;
      });
    },
    // 删除全部
    handleRemoveAll() {
      if (!this.removeCache.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择要删除的签单！'
        });
        return;
      }
      // const pArr = []
      this.$bus.open(() => {
        // const loading = this.$bus.loading()
        this.$store.dispatch('ticket/index/deleteTicketIds', this.removeCache).then(() => {
          this.fetchTicketItems();
          this.$bus.tip();
          this.removeCache = [];
        });
        // this.handleRomoteRemoveAll(this.removeCache)
        // this.removeCache.forEach(id => {
        //   const p = this.handleRomoteRemove(id)
        //   pArr.push(p)
        // })

        // Promise.all(pArr)
        //   .then(() => {
        //     loading.close()
        //     this.fetchTicketItems()
        //     this.$bus.tip()
        //     this.removeCache = []
        //   })
        //   .catch(() => {
        //     loading.close()
        //   })
      });
    },
    // 删除单个
    handleRemove(row) {
      this.$bus.open(() => {
        const {
          id
        } = row;
        const loading = this.$bus.loading();
        this.handleRomoteRemove(id).then(() => {
          this.fetchTicketItems();
          this.resetRemoveCache(id);
          this.$bus.tip();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      });
    },
    // 本地缓存
    resetRemoveCache(id) {
      this.removeCache = this.removeCache.filter(it => it != id);
    },
    // 远程删除
    handleRomoteRemove(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('ticket/index/deleteTicketItem', id).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    }
  }
};