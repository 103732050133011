var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectData", _vm._g(_vm._b({
    attrs: {
      label: _vm.label,
      list: _vm.listData,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      filterable: "",
      remote: "",
      "remote-method": _vm.remoteMethod,
      placeholder: "请选择签单"
    },
    on: {
      "on-load": _vm.loadMoreData,
      focus: _vm.lazyLoad
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        item
      }) {
        return [_c("span", {
          staticStyle: {
            float: "left"
          },
          attrs: {
            value: item.value
          }
        }, [_vm._v(_vm._s(item.label))]), _c("span", {
          staticStyle: {
            float: "right",
            color: "#8492a6",
            "font-size": "13px"
          }
        }, [item.groupId > 0 ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("集团")]) : _vm._e()], 1)];
      }
    }])
  }, "SelectData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };