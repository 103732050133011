import { ResourceType } from '@/config/billCheck';
import { dictionaryMixin, searchMixin } from '@/mixin';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, InputInsurance, SelectCustom } from '@/components/common';
import { parseDate } from '@/utils';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputInsurance,
    SelectProduct,
    SelectStaff,
    SelectCustom
  },
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      ResourceType,
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        startTripDateS: parseDate()
      },
      resourceName: ''
    };
  },
  computed: {},
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Name'] = val.label;
    },
    onSelectResource({
      id
    }) {
      this.searchParams.resourceId = id;
    },
    onSelectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productLabel = val.name;
    },
    // 选择客户
    selectCustom(val) {
      this.searchParams.customerLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // changeStarTripDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.startTripDateS = starDate
    //   this.searchParams.startTripDateE = endDate
    // },

    // changeEndTripDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.endTripDateS = starDate
    //   this.searchParams.endTripDateE = endDate
    // },

    // changeOrderDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.orderDateS = starDate
    //   this.searchParams.orderDateE = endDate
    // },

    // changeCheckDate(val) {
    //   const [starDate, endDate] = val
    //   this.searchParams.checkAccountTimeS = starDate
    //   this.searchParams.checkAccountTimeE = endDate
    // },

    handleExcel() {
      this.$emit('on-export', this.searchParams);
    }
  }
};