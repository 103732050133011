import "core-js/modules/es.array.push.js";
import { rowspanMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { RestaurantColumn as columns } from '@/config/billDown/payRegiment/restaurant';
export default {
  mixins: [rowspanMixin],
  data: () => ({
    path: '/bill/regiment-pay/restaurant-create',
    columns,
    isSticky: true
  }),
  computed: {
    ...mapGetters({
      data: 'bill/billDown/payRegimentRestaurant/data',
      total: 'bill/billDown/payRegimentRestaurant/total',
      tableData: 'bill/billDown/payRegimentRestaurant/list'
    }),
    getBusInfo() {
      return row => {
        const {
          driverName,
          driverPhone,
          licenceNumber,
          busType
        } = row;
        return [driverName, driverPhone, licenceNumber, busType].join(' ');
      };
    }
  },
  watch: {
    data: {
      handler: function (data) {
        if (!data) this.$emit('page', {});
        this.rowspan();
      },
      immediate: true
    }
  },
  methods: {
    handlePay({
      rowId,
      restaurantId: rid
    }) {
      this.$router.push({
        path: this.path,
        query: {
          id: rowId,
          rid
        }
      });
    }
  }
};