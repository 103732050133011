import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// 表格小计方法
import { processAdd, decimalSplit, processMinus } from './';

/**
 * 如果要优化该方法，需要处理以下几种情况
 * 1. 人数
 * 2. 用房数
 * 3. 平均值
 * 4. 合并的行金额不需要重复累加
 * 
 */

const PeopleKeys = ['number', 'peopleCount'];
const PeopleKeys2 = {
  peopleCount: 'peopleCount',
  adultCount: 'adultCount',
  childCount: 'childCount',
  accompanyCount: 'accompanyCount'
};

/**
 * 1. 多条合并的不累加
 * 2. 人数
 * 3. 平均值
 * 4. 其他非合计的字段，显示别的内容
 * 5. 统计勾选选金额
 * 6. 用房数
 * 7. 正常值
 */

// 金额计算
const calcValues = values => {
  if (!values.every(value => isNaN(value))) {
    return values.reduce((prev, curr) => {
      const value = Number(curr);
      if (!isNaN(value)) {
        return processAdd(prev, curr);
      } else {
        return prev;
      }
    }, 0);
  }
  return 0;
};
const tableSummary = ({
  columns,
  data
}, calcKeys) => {
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '小计';
      return;
    }
    let values = [];
    if (calcKeys.includes(column.property)) {
      values = data.map(item => Number(item[column.property]));
      sums[index] = calcValues(values);
    }
  });
  return sums;
};

// 多条合并
const tableSummaryMergeRow = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach((column, index) => {
    const values = data.map(item => {
      if (keys.includes(column.property)) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return 0;
        } else {
          last = item;
          return item[column.property];
        }
      }
    });
    if (keys.includes(column.property)) {
      sums[index] = calcValues(values);
    }
  });
  return sums;
};

// 人数
const tableSummaryPeople = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach((column, index) => {
    const values = data.map(item => {
      if (keys.includes(column.property)) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return [item[column.property], 0, 0].join('/');
        } else {
          last = item;
          const {
            adultCount,
            childCount,
            accompanyCount
          } = item;
          return adultCount + '/' + childCount + '/' + (accompanyCount || 0);
        }
      }
    });
    if (values.every(value => value && value.indexOf('/') > -1)) {
      const res = values.reduce((prev, cur) => {
        const [val0, val1, val2] = cur.split('/');
        prev[0] = processAdd(prev[0], val0);
        prev[1] = processAdd(prev[1], val1);
        prev[2] = processAdd(prev[2], val2);
        return prev;
      }, [0, 0, 0]);
      if (keys.includes(column.property)) {
        sums[index] = `${res[0]}大${res[1]}小${res[2]}陪`;
      }
    }
  });
  return sums;
};

// 对账金额
const tableSummaryCheckedMoney = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach(column => {
    const values = data.map(item => {
      if (column.property == keys[1]) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return 0;
        } else {
          last = item;
          return item[keys[2]] ? item[column.property] : 0;
        }
      }
    });
    if ([keys[1]].includes(column.property)) {
      const idx = columns.findIndex(it => it.property == keys[0]);
      sums[idx] = `已选金额：${calcValues(values)}`;
    }
  });
  return sums;
};

//
/**
 * 小计最终方法
 * @mergeKeys [] 合并的列，不需要累加的字段
 * @peopleKeys [] 人数小计字段
 * @checkKey [] 勾选计算金额
 * @returns []
 */
export const tableSummaryFinally = ({
  param,
  mergeKeys = [],
  peopleKeys = PeopleKeys,
  calcKeys = [],
  checkKeys = [],
  // 第一个 显示内容列的字段、第二个：勾选的金额、第三个：勾选状态的值
  avgFn
}) => {
  let sums = tableSummary(param, calcKeys);
  if (mergeKeys.length) {
    sums = tableSummaryMergeRow(param, sums, mergeKeys);
  }
  if (peopleKeys.length) {
    sums = tableSummaryPeople(param, sums, peopleKeys);
  }
  if (checkKeys.length) {
    sums = tableSummaryCheckedMoney(param, sums, checkKeys);
  }
  return avgFn ? avgFn(sums) : sums;
};
export function getSummaries(param) {
  const {
    columns,
    data
  } = param;
  const sums = [];
  const sumsObj = {};
  return ({
    statisticsKeys = [],
    needSumKeys = [],
    callback
  } = {}) => {
    columns.forEach((column, index) => {
      let last;
      if (index === 0) {
        sums[index] = '小计';
        return;
      }
      const values = data.map(item => {
        // 只合计设定的字段
        if (statisticsKeys.includes(column.property)) {
          // 处理多个子项合计
          if (last && item.rowId && last.rowId == item.rowId && !needSumKeys.includes(column.property)) {
            return 0;
          } else {
            last = item;
            return item[column.property];
          }
        }
      });
      if (!values.every(value => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            prev = processAdd(prev, curr);
          }
          return prev;
        }, 0);
        sumsObj[column.property] = {
          index,
          value: sums[index]
        };
        sums[index] = decimalSplit(sums[index]);
      }
    });
    return callback ? callback({
      sums,
      sumsObj
    }) : sums;
  };
}

/**
 * 表格小计均分
 * @param {*} sums 计算后的数组
 * @param {*} sumsObj 对应sums里每项index的值与下标的对象
 * @param {*} keyObj 需要单独处理小计的对象，比如人均，不是累加
 * @param {*} statistics 总计对象数据
 * @returns
 */
export function handleSumAvg({
  sums,
  sumsObj,
  keyObj,
  statistics = {},
  sumDivisor = null
}) {
  Object.keys(keyObj).forEach(key => {
    const [dividend, divisor] = keyObj[key];
    if (sumsObj[key]) {
      const {
        index
      } = sumsObj[key];
      const {
        value
      } = sumsObj[dividend];
      let subtotal = 0;
      if (sumDivisor) {
        subtotal = sumDivisor;
      } else {
        const {
          value: value2
        } = sumsObj[divisor];
        subtotal = value2 != 0 ? decimalSplit(Math.round(value / value2)) : 0;
      }
      const allTotal = statistics[key];
      sums[index] = allTotal ? decimalSplit(allTotal) : decimalSplit(subtotal);
      // sums[index] = decimalSplit(subtotal) + '\n' + decimalSplit(allTotal)
    }
  });
  return sums;
}

// 计算人数小计
function calcPeopleCount(data) {
  const calc = key => {
    return data.reduce((acc, cur) => {
      acc = processAdd(acc, cur[key] || 0);
      return acc;
    }, 0);
  };
  return {
    adultCount: calc('adultCount'),
    childCount: calc('childCount'),
    accompanyCount: calc('accompanyCount')
  };
}

// 根据key查找表格下标
export function findColumnIndexByKey(key, columns) {
  return columns.findIndex(column => column.property === key);
}

// 人数字段统计值
export function formatPeopleCountValueByKey({
  key = 'peopleCount',
  data,
  columns
}) {
  const res = calcPeopleCount(data);
  const value = formatPeopleValue(res);
  const index = findColumnIndexByKey(key, columns);
  return {
    index,
    value
  };
}
export function formatPeopleValue(data) {
  if (!data) return '';
  const val1 = data['adultCount'] || 0;
  const val2 = data['childCount'] || 0;
  const val3 = data['accompanyCount'] || 0;
  return `${val1}大${val2}小${val3}陪`;
}
export function handlePeopleSummary({
  sums,
  sumsObj,
  statistics = {}
}) {
  Object.keys(PeopleKeys2).forEach(key => {
    if (sumsObj[key]) {
      const {
        index
      } = sumsObj[key];
      const subtotal = formatPeopleValue(sumsObj);
      const allTotal = formatPeopleValue(statistics);
      sums[index] = subtotal + '\n' + allTotal;
    }
  });
  return sums;
}

/**
 * 获取统计的key
 */
export function processStatisticsKeys(keys) {
  const toString = Object.prototype.toString;
  const isObj = toString.call(keys) == '[object Object]';
  const result = isObj ? keys : keys.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});
  return {
    ...result,
    ...PeopleKeys2
  };
}

// 累加合并表格某个字段的值
export function calcMergeRowTotalByKey(data, key, newKey) {
  const listArr = data.reduce((acc, cur) => {
    const val = cur.rowId;
    if (!acc[val]) {
      acc[val] = [];
    }
    acc[val].push(cur[key]);
    return acc;
  }, {});
  return data.map(it => {
    const list = listArr[it.rowId];
    let total = 0;
    if (list && list.length) {
      total = list.reduce((acc, cur) => (acc, processAdd(acc, cur)), 0);
    }
    return {
      ...it,
      [newKey]: total
    };
  });
}

// 计算成本 单价* (数量-免去) + 其他
export function calcRowCostTotal({
  price,
  count = 0,
  freeCount = 0,
  otherFee = 0
}) {
  const num = price * processMinus(count, freeCount);
  return processAdd(num, otherFee);
}

// 计算相同id和合计金额
export function clacMergeRowByRowId({
  data,
  rowId,
  priceKey = 'price'
}) {
  const list = data.filter(it => it.rowId == rowId);
  return list.reduce((acc, cur) => {
    const val = calcRowCostTotal({
      ...cur,
      price: cur[priceKey]
    });
    acc = processAdd(acc, val);
    return acc;
  }, 0);
}

// 计算相同id和合计金额
export function clacMergeRowByRowIdAndMoney({
  data,
  rowId,
  priceKey = 'price'
}) {
  const list = data.filter(it => it.rowId == rowId);
  return list.reduce((acc, cur) => {
    acc = processAdd(acc, cur[priceKey]);
    return acc;
  }, 0);
}