import "core-js/modules/es.array.push.js";
import { customerMonthKey, customerKpiKey } from './config';
import { decimalSplit } from '@/utils';
import { useEcharts } from '@/utils/echarts';
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    progressList: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'reality'
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLine(this.progressList, this.title, this.id, this.type);
    });
  },
  methods: {
    formatData(list) {
      let legendData = [];
      let tableData = list.map(it => {
        legendData.push(it.kpiName);
        const item = {
          name: it.kpiName,
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top'
          },
          data: []
        };
        customerKpiKey.forEach(key => {
          item.data.push(it[key]);
        });
        return item;
      });
      return {
        tableData,
        legendData
      };
    },
    drawLine(tableData, title, id, itemType) {
      let seriesData = [];
      let legendTitle = ['完成率'];
      if (itemType == 'reality') {
        seriesData = this.formatData(tableData).tableData;
        legendTitle = this.formatData(tableData).legendData;
      } else {
        const item = {
          name: '完成率',
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top',
            formatter: function (params) {
              return decimalSplit(params.data * 100) + '%';
            }
          },
          data: tableData
        };
        seriesData = [item];
      }

      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById(id))
      // 绘制图表
      const opts = {
        title: {
          text: title,
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let str = '';
            params.forEach((it, index) => {
              if (itemType == 'reality') {
                str += `${it.marker}${index == 0 ? it.axisValue + '目标' : it.axisValue + '完成'}:${it.data}`;
                str += index === params.length - 1 ? '' : '<br/>';
              } else {
                str += `${it.marker}${it.axisValue + '完成率'}${decimalSplit(it.data * 100)}%`;
              }
            });
            return str;
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          nameTextStyle: {
            padding: [0, 5]
          },
          data: customerMonthKey
        },
        legend: {
          data: legendTitle,
          bottom: 0
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          boundaryGap: true
        },
        series: seriesData
      };
      useEcharts(id, opts);
    }
  }
};