var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-template"
  }, [!_vm.isMobile ? _vm._l(_vm.list, function (tpl, idx) {
    return _c("div", {
      key: tpl,
      class: ["print-template__item", {
        active: _vm.curIndex == idx
      }],
      on: {
        click: function ($event) {
          return _vm.change(tpl, idx);
        }
      }
    }, [_c("p", [_vm._v("模板" + _vm._s(idx + 1))]), _vm.defTpl != tpl ? _c("div", {
      staticClass: "btn-group"
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        size: "mini",
        round: ""
      },
      on: {
        click: function ($event) {
          return _vm.setDefault(tpl);
        }
      }
    }, [_vm._v(" 设为默认 ")])], 1) : _c("i", {
      staticClass: "el-icon-success"
    })]);
  }) : _c("div", {
    staticClass: "btn-group"
  }, _vm._l(_vm.list, function (tpl, idx) {
    return _c("el-button", {
      key: tpl,
      attrs: {
        type: "primary"
      },
      on: {
        click: function ($event) {
          return _vm.changeTplAndSet(tpl, idx);
        }
      }
    }, [_vm._v(" 模板" + _vm._s(idx + 1) + " ")]);
  }), 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };