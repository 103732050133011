const dfaultState = () => ({
  editForm: null,
  cloneForm: null
});
const state = dfaultState();
const getters = {
  editForm: state => state.editForm,
  cloneForm: state => state.cloneForm
};
const actions = {};
const mutations = {
  EDIT_FORM: (state, data) => {
    state.editForm = data;
  },
  CLONE_FORM: (state, data) => {
    state.cloneForm = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};