var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "upload-wrapper"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.logoUrl.loading,
      expression: "logoUrl.loading"
    }],
    staticClass: "upload-box"
  }, [_c("UploadBase", {
    ref: "uploadRef",
    staticClass: "avatar-uploader",
    attrs: {
      "default-img": _vm.logoUrl.image,
      "show-file-list": false
    },
    on: {
      "on-delete": _vm.deleteLogoUrl,
      "on-success": _vm.handleAvatarSuccess
    }
  }), _c("p", [_vm._v("LOGO")])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.sealBusiness.loading,
      expression: "sealBusiness.loading"
    }],
    staticClass: "upload-box"
  }, [_c("UploadBase", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.uploadUrl,
      "default-img": _vm.sealBusiness.image,
      "show-file-list": false
    },
    on: {
      "on-delete": function ($event) {
        return _vm.deleteImage(_vm.sealBusiness, $event);
      },
      success: function ($event) {
        return _vm.handleAvatarSuccess2(_vm.sealBusiness, $event);
      }
    }
  }), _c("p", [_vm._v("业务章")])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.sealAccount.loading,
      expression: "sealAccount.loading"
    }],
    staticClass: "upload-box"
  }, [_c("UploadBase", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.uploadUrl,
      "default-img": _vm.sealAccount.image,
      "show-file-list": false
    },
    on: {
      "on-delete": function ($event) {
        return _vm.deleteImage(_vm.sealAccount, $event);
      },
      success: function ($event) {
        return _vm.handleAvatarSuccess2(_vm.sealAccount, $event);
      }
    }
  }), _c("p", [_vm._v("财务章")])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.sealTrip.loading,
      expression: "sealTrip.loading"
    }],
    staticClass: "upload-box"
  }, [_c("UploadBase", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.uploadUrl,
      "default-img": _vm.sealTrip.image,
      "show-file-list": false
    },
    on: {
      "on-delete": function ($event) {
        return _vm.deleteImage(_vm.sealTrip, $event);
      },
      success: function ($event) {
        return _vm.handleAvatarSuccess2(_vm.sealTrip, $event);
      }
    }
  }), _c("p", [_vm._v("电子行程单章")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };