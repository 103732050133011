var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "mall-order-transfor",
    attrs: {
      title: "转单信息",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "产品名称",
      prop: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.form.productName) + " ")])], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "发团日期",
      prop: "startTripDate"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.startTripDate))])]), _c("el-form-item", {
    attrs: {
      label: "散团日期",
      prop: "endTripDate"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.endTripDate))])]), _c("el-form-item", {
    attrs: {
      label: "人数",
      prop: ""
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.adultCount) + "大" + _vm._s(_vm.form.childCount) + "小")])])], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人",
      prop: "contactName"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.contactName))])]), _c("el-form-item", {
    attrs: {
      label: "联系人手机",
      prop: "contactMobilePhone"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.contactMobilePhone))])]), _c("el-form-item", {
    attrs: {
      label: "邮箱",
      prop: ""
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.form.contactEmail))])])], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "成人价",
      prop: "adultPrice"
    }
  }, [_c("span", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.form.adultPrice) + " * " + _vm._s(_vm.form.adultCount) + " = " + _vm._s(_vm.form.adultPrice * _vm.form.adultCount) + "元 ")])]), _c("el-form-item", {
    attrs: {
      label: "儿童价",
      prop: "childPrice"
    }
  }, [_c("span", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.form.childPrice) + " * " + _vm._s(_vm.form.childCount) + " = " + _vm._s(_vm.form.childPrice * _vm.form.childCount) + "元 ")])]), _c("el-form-item", {
    attrs: {
      label: "单房差",
      prop: ""
    }
  }, [_c("span", {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.form.roomDifferenceCount) + " * " + _vm._s(_vm.form.roomDifferencePrice) + " = " + _vm._s(_vm.form.roomDifferenceCount * _vm.form.roomDifferencePrice) + "元 ")])])], 1), _c("div", {
    staticClass: "input-row"
  }, [!_vm.isTeamOrder ? _c("el-form-item", {
    attrs: {
      label: "大团号",
      prop: "planCustomerNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.planCustomerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planCustomerNumber", $$v);
      },
      expression: "form.planCustomerNumber"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "小团号",
      prop: "customerTripNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.customerTripNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerTripNumber", $$v);
      },
      expression: "form.customerTripNumber"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "全陪及电话",
      prop: "accompanyInfo"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.accompanyInfo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accompanyInfo", $$v);
      },
      expression: "form.accompanyInfo"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "产品类型",
      prop: "productType"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "productType",
      label: ""
    },
    model: {
      value: _vm.form.productType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productType", $$v);
      },
      expression: "form.productType"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "操作计调",
      prop: "dutyOPUserName"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.form.dutyOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dutyOPUserName", $$v);
      },
      expression: "form.dutyOPUserName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客服计调",
      prop: "outOPUserName"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("outOPUser", $event);
      }
    },
    model: {
      value: _vm.form.outOPUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outOPUserName", $$v);
      },
      expression: "form.outOPUserName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "业务员",
      prop: "saleUserName"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": function ($event) {
        return _vm.changeOPUser("saleUser", $event);
      }
    },
    model: {
      value: _vm.form.saleUserName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "saleUserName", $$v);
      },
      expression: "form.saleUserName"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-row"
  }, [_c("el-form-item", {
    attrs: {
      label: "客户",
      prop: "dutyOPUserName"
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "",
      clearable: "",
      placeholder: "请选择客户"
    },
    on: {
      "on-select": _vm.onSelectCustomer
    },
    model: {
      value: _vm.form.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客户联系人",
      prop: "customerContactName"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.customerContactName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerContactName", $$v);
      },
      expression: "form.customerContactName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客户手机",
      prop: "customerContactMobileNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.customerContactMobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerContactMobileNumber", $$v);
      },
      expression: "form.customerContactMobileNumber"
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-row clearfix"
  }, [_c("el-form-item", {
    attrs: {
      label: "游客接站牌",
      prop: "welcomeBorad"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.welcomeBorad,
      callback: function ($$v) {
        _vm.$set(_vm.form, "welcomeBorad", $$v);
      },
      expression: "form.welcomeBorad"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客源地",
      prop: "region"
    }
  }, [_c("SelectRegion", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "团队类型",
      prop: "groupTypeName"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "teamType",
      label: ""
    },
    on: {
      "on-select": _vm.onSelectGroupType
    },
    model: {
      value: _vm.form.groupTypeName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "groupTypeName", $$v);
      },
      expression: "form.groupTypeName"
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };