var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper order-all form-fix-bar"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户",
      disabled: !_vm.hasCustomerInfo
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("customer", $event);
      }
    },
    model: {
      value: _vm.searchParams.customerLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "customerLabel", $$v);
      },
      expression: "searchParams.customerLabel"
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("BtnExport", {
    attrs: {
      "excel-loading": _vm.excelLoading
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": _vm.exportExcel
    }
  }, [_c("BtnMore", {
    attrs: {
      visible: _vm.visibleMore
    },
    on: {
      "on-click": val => _vm.visibleMore = !_vm.visibleMore
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endDate,
      label: "散团日期"
    },
    on: {
      "on-change": _vm.onChangeEndDate
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.inputDate,
      label: "输单日期"
    },
    on: {
      "on-change": _vm.onChangeCreateDate
    }
  }), _c("InputDepartment", {
    attrs: {
      label: "部门"
    },
    on: {
      "on-select": function ($event) {
        _vm.searchParams = {
          ..._vm.searchParams,
          ...$event
        };
      }
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectBase", {
    attrs: {
      label: "审核状态",
      type: "reviewerStatus"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectBase("reviewStatus", $event);
      }
    },
    model: {
      value: _vm.searchParams.reviewStatusLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "reviewStatusLabel", $$v);
      },
      expression: "searchParams.reviewStatusLabel"
    }
  }), _c("SelectBase", {
    attrs: {
      label: "订单类型",
      type: "orderType"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectBase("orderType", $event);
      }
    },
    model: {
      value: _vm.searchParams.orderTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "orderTypeLabel", $$v);
      },
      expression: "searchParams.orderTypeLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("outOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.outOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "outOPUserLabel", $$v);
      },
      expression: "searchParams.outOPUserLabel"
    }
  })], 1), _c("transition", {
    attrs: {
      name: "fade-down",
      mode: "out-in"
    }
  }, [_c("FormBase", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getVisible,
      expression: "getVisible"
    }]
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectGuide", {
    attrs: {
      label: "导游"
    },
    model: {
      value: _vm.searchParams.guideId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "guideId", $$v);
      },
      expression: "searchParams.guideId"
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.memberKey,
      label: "游客信息"
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "memberKey", $event);
      }
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "产品线路"
    },
    on: {
      "on-select": _vm.onSelectProduct
    },
    model: {
      value: _vm.searchParams.productLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productLabel", $$v);
      },
      expression: "searchParams.productLabel"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectStaff", {
    attrs: {
      label: "创建人"
    },
    model: {
      value: _vm.searchParams.createUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "createUserId", $$v);
      },
      expression: "searchParams.createUserId"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "责任计调"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("dutyOPUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.dutyOPUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "dutyOPUserLabel", $$v);
      },
      expression: "searchParams.dutyOPUserLabel"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "业务员"
    },
    on: {
      "on-select": function ($event) {
        return _vm.onSelect("saleUser", $event);
      }
    },
    model: {
      value: _vm.searchParams.saleUserLabel,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "saleUserLabel", $$v);
      },
      expression: "searchParams.saleUserLabel"
    }
  })], 1)])], 1)], 1), _c("TableList", {
    ref: "tableRef",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("FooterScrollBar", {
    attrs: {
      "real-width": _vm.realWidth,
      width: _vm.tableWidth
    },
    on: {
      "on-scroll": _vm.handleBarScroll
    }
  }), _c("progressDetail", {
    ref: "progressRef"
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "audit"
    },
    on: {
      click: _vm.handleAudit
    }
  }, [_vm._v(" 确认审核 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };