var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item plan-border-table"
  }, [_c("TopBar", {
    attrs: {
      title: "酒店安排",
      disabled: _vm.isBtnDisable,
      "table-data-number": _vm.tableDataNumber,
      "has-sign": true,
      "bill-count": _vm.billCount
    },
    on: {
      "on-add": _vm.handlePlus
    }
  }, [_c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.handleTotalBox
    }
  }, [_vm._v(" 合计 ")])], 1), _vm.visible ? _c("Hotel", {
    ref: "hotelRef",
    attrs: {
      loading: _vm.fetchStatus,
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "plan-id": _vm.planId,
      "is-audit": _vm.isAudit
    },
    on: {
      "on-members": _vm.showMembers
    }
  }, [_c("DialogList")], 1) : _vm._e(), _c("DialogOrder", {
    ref: "orderRef"
  }), _c("DialogHotelTotel", {
    ref: "hotelTotelRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };