var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("Search", {
    ref: "searchRef",
    on: {
      "on:search": _vm.fetchData,
      "on:export": _vm.exportExcel
    }
  }), _c("TableNew", {
    ref: "tableRef",
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.statisticsKeys,
      "show-summary": ""
    },
    on: {
      page: _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "orderInfo",
      fn: function ({
        row
      }) {
        return [_c("span", {
          staticStyle: {
            color: "#13ce66"
          }
        }, [_vm._v(_vm._s(row.planNumber))]), _c("br"), _c("span", {
          staticStyle: {
            color: "#1890ff"
          }
        }, [_vm._v(_vm._s(row.customerTripNumber))])];
      }
    }, {
      key: "date",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.startPlanDate) + " "), _c("br"), _vm._v(" " + _vm._s(row.endPlanDate) + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        row
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: row.customerName
          }
        })];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.adultCount) + " 大 " + _vm._s(row.childCount) + " 小 " + _vm._s(row.accompanyCount) + " 陪 ")];
      }
    }, {
      key: "members",
      fn: function ({
        row
      }) {
        return [_c("Members", {
          attrs: {
            row: row
          }
        })];
      }
    }, {
      key: "guideName",
      fn: function ({
        row
      }) {
        return [row.isGuideCollect ? _c("span", [_vm._v(" " + _vm._s(row.guideName) + " ")]) : _vm._e(), row.isAgencyCollect ? _c("span", [_vm._v(" 本社收 ")]) : _vm._e()];
      }
    }, {
      key: "collection",
      fn: function ({
        row
      }) {
        return [row.isAgencyCollect ? _c("span", [_vm._v(" " + _vm._s(row.collection) + " ")]) : _c("span", [_vm._v("0")])];
      }
    }, {
      key: "guideCollection",
      fn: function ({
        row
      }) {
        return [row.isGuideCollect ? _c("span", [_vm._v(" " + _vm._s(row.guideCollection) + " ")]) : _c("span", [_vm._v("0")])];
      }
    }, {
      key: "arrearage",
      fn: function ({
        row
      }) {
        return [_vm._v(" " + _vm._s(row.total - row.travelAgencyReceiveReimbed) + " ")];
      }
    }, {
      key: "paymentCollectionTime",
      fn: function ({
        row
      }) {
        return [!row.checkAccountStatus ? _c("InputDate", {
          attrs: {
            type: "datetime",
            "value-format": "yyyy-MM-dd HH:mm:ss",
            "default-time": "10:00:00"
          },
          model: {
            value: row.paymentCollectionTime,
            callback: function ($$v) {
              _vm.$set(row, "paymentCollectionTime", $$v);
            },
            expression: "row.paymentCollectionTime"
          }
        }) : _c("span", [_vm._v(_vm._s(row.paymentCollectionTime))])];
      }
    }, {
      key: "action",
      fn: function ({
        row
      }) {
        return [!row.checkAccountStatus ? [_c("el-checkbox", {
          on: {
            change: function ($event) {
              return _vm.onChangeOne(row);
            }
          },
          model: {
            value: row.isCheck,
            callback: function ($$v) {
              _vm.$set(row, "isCheck", $$v);
            },
            expression: "row.isCheck"
          }
        }), _c("el-button", {
          staticStyle: {
            "margin-left": "4px",
            color: "#13ce66"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheckout(row);
            }
          }
        }, [_vm._v(" 审核 ")])] : _c("el-button", {
          staticStyle: {
            "margin-left": "4px",
            color: "#e6a23c"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCheckCancel(row);
            }
          }
        }, [_vm._v(" 取消 ")]), _c("el-button", {
          staticStyle: {
            "margin-left": "4px"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(row);
            }
          }
        }, [_vm._v(" 日志 ")])];
      }
    }, {
      key: "billCheck",
      fn: function ({}) {
        return [_c("el-checkbox", {
          on: {
            change: _vm.onCheckedAll
          },
          model: {
            value: _vm.checkAll,
            callback: function ($$v) {
              _vm.checkAll = $$v;
            },
            expression: "checkAll"
          }
        }), _c("span", {
          staticClass: "pl5"
        }), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: _vm.batchAudit
          }
        }, [_vm._v(" 批量审核 ")])];
      }
    }])
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };