import service from '@/utils/request';

// 获取旅行社配置
export function getSysConfig(params) {
  return service({
    url: '/system/trip/config-list',
    method: 'get',
    params
  });
}

// 旅行社配置
export function setSysConfig(data) {
  return service({
    url: '/system/trip/config-set',
    method: 'post',
    data
  });
}

// 获取旅行社/公司 对接桂林旅游电子行程平台账户、token、key信息
export function fetchGLETravel(params) {
  return service({
    url: '/system/trip/getGLETravelPlatformInfo',
    method: 'get',
    params
  });
}

// 设置旅行社/公司 对接桂林旅游电子行程平台账户、token、key信息
export function updateGLETravel(params) {
  return service({
    url: '/system/trip/setGLETravelPlatformInfo',
    method: 'post',
    params
  });
}

// 更新获取桂林旅游平台字典信息(酒店/景区/导游/车辆/购物店)
export function updateGLETravelDic(params) {
  return service({
    url: '/resource/e-travel-platform/updateLocal',
    method: 'post',
    timeout: 120000,
    params
  });
}