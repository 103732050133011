var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "textarea-box"
  }, [_c("div", {
    staticClass: "input-field",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("h4", {
    staticClass: "pr10"
  }, [_vm._v(" 备注 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      loading: _vm.BtnLoading
    },
    on: {
      click: _vm.handleUpdate
    }
  }, [_vm._v(" 保存 ")])], 1), _vm._l(_vm.remarkList, function (item, index) {
    return [_c("div", {
      key: item.name + "" + index,
      staticClass: "input-box"
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(item.label))]), _c("el-input", {
      attrs: {
        type: "textarea",
        autosize: {
          minRows: 4,
          maxRows: 6
        },
        label: item.label
      },
      on: {
        change: function ($event) {
          return _vm.changeRemark(index, item.name, $event);
        }
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1)];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };