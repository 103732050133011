import "core-js/modules/es.array.push.js";
import { permissionMixin, summaryMixin, tableMixin } from '@/mixin';
import { ReimbStatus } from '@/config/billDown';
import { StatusAudit } from '@/components/common';
import { PersonCount } from '@/components/common';
import { processMinus } from '@/utils/number';
export default {
  components: {
    StatusAudit,
    PersonCount
  },
  mixins: [tableMixin, summaryMixin, permissionMixin],
  data: () => ({
    ReimbStatus,
    isSticky: true,
    path: '/bill/guide-audit-detail',
    statisticsKeys: ['travelAgencyPay', 'travelAgencyPayed', 'unPay', 'number']
  }),
  computed: {
    getUnpay() {
      return row => processMinus(row.travelAgencyPay, row.travelAgencyPayed);
    }
  },
  methods: {
    handleSendSms(params) {
      this.$store.dispatch('system/sms/confirmMessage', params);
    },
    handleSendSms2(params) {
      this.$store.dispatch('system/sms/confirm2Message', params);
    },
    handleFixData(row) {
      const {
        planId,
        planCustomerNumber: planNumber
      } = row;
      this.$store.dispatch('bill/billDown/fixflushGuideReimb', {
        planId,
        planNumber
      }).then(res => {
        this.$parent.handleSearch();
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
      });
    },
    handleDetail(row) {
      const {
        guideId,
        planId,
        unPay
      } = row;
      this.$store.commit('bill/billDown/SET_BILL_INPUT', {
        ...row,
        money: unPay,
        userMoney: unPay
      });
      this.$router.push({
        path: this.path,
        query: {
          guideId,
          planId,
          rid: guideId
        }
      });
    },
    handleBillDialog(row) {
      const {
        guideId,
        planId
      } = row;
      this.$store.commit('bill/guide/SET_DOWN_MONEY', row.unPay);
      this.$store.commit('bill/guide/BILL_DETAIL', row);
      this.$parent.showDialog({
        guideId,
        planId,
        type: ''
      });
    },
    // 垫付款
    handleAdvance(row) {
      const {
        guideId,
        planId
      } = row;
      this.$store.commit('bill/guide/BILL_DETAIL', row);
      this.$parent.showDialog({
        guideId,
        planId,
        type: 'guide'
      });
    },
    // 佣金
    handleCommission(row) {
      const {
        guideId,
        planId
      } = row;
      this.$store.commit('bill/guide/BILL_DETAIL', row);
      this.$parent.showDialog({
        guideId,
        planId,
        type: 'shop_comm'
      });
    },
    toggleAccountStatus(row) {
      const {
        id,
        checkAccountStatus,
        checkAccountRemark
      } = row;
      const {
        guideId,
        checkAccountTime
      } = row;
      const opts = {
        id,
        status: !checkAccountStatus,
        remarks: checkAccountRemark
      };
      const msg = checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        // 对账发短信，取消不发
        if (!checkAccountStatus) {
          const smsOpt = {
            guideId,
            reimbId: id
          };
          if (checkAccountTime != null) {
            this.handleSendSms2(smsOpt);
          } else {
            this.handleSendSms(smsOpt);
          }
        }
        this.$store.dispatch('bill/billDown/toggleGuideCheck', opts).then(() => {
          this.$parent.handleSearch();
        });
      }, msg);
    },
    handleCommand(scope, command) {
      this[command](scope.row);
    }
  }
};