import { inputMixin } from '@/mixin';

/**
 * @module components/common/Input
 * @desc 关系 input 组件
 * @vue-prop {Boolean}} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean}} [isRequire=false] - 是否必填项
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  created() {
    this.relatations = this.loadAll();
  },
  methods: {
    // 这里是mock数据
    loadAll() {
      return [{
        value: '一起',
        type: '4'
      }, {
        value: '拼',
        type: '3'
      }, {
        value: '单',
        type: '1'
      }, {
        value: '单间',
        type: '2'
      }];
    }
  }
};