var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-reserve"
  }, [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "50px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      value: _vm.searchParams.planCustomerNumber,
      label: "大团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "planCustomerNumber", $event);
      }
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.customerTripNumber,
      label: "小团号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "customerTripNumber", $event);
      }
    }
  }), _c("SelectProduct", {
    attrs: {
      label: "产品线路"
    },
    on: {
      "on-select": _vm.selectProduct
    },
    model: {
      value: _vm.searchParams.productId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "productId", $$v);
      },
      expression: "searchParams.productId"
    }
  }), _c("InputBase", {
    attrs: {
      value: _vm.searchParams.orderNumber,
      label: "订单编号",
      clearable: ""
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.searchParams, "orderNumber", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputDateRange", {
    attrs: {
      "default-date": _vm.startTripDate,
      label: "发团日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("InputDateRange", {
    attrs: {
      "default-date": _vm.endTripDate,
      label: "散团日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "操作计调"
    },
    model: {
      value: _vm.searchParams.createUserId,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "createUserId", $$v);
      },
      expression: "searchParams.createUserId"
    }
  }), _c("BtnCreate", {
    attrs: {
      "create-text": "添加预定"
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.showCreate
    }
  })], 1)]), _c("TableList", {
    attrs: {
      data: _vm.tableData
    }
  }), _c("pagination", {
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  }), _c("DialogCustomer", {
    ref: "customerRef"
  }), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };