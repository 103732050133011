import "core-js/modules/es.array.push.js";
import SearchRegiment from '../components/SearchRegiment';
import { CustomerColumn as columns } from '@/config/billDown/collectRegiment/customer';
import { reloadMixin, rowspanMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { PersonCount } from '@/components/common';
export default {
  components: {
    SearchRegiment,
    PersonCount
  },
  mixins: [rowspanMixin, reloadMixin],
  data() {
    return {
      columns,
      searchParams: {},
      path: '/bill/regiment-collect/customer-create'
    };
  },
  computed: {
    ...mapGetters({
      data: 'bill/billDown/collectRegimentCustomer/data',
      total: 'bill/billDown/collectRegimentCustomer/total',
      tableData: 'bill/billDown/collectRegimentCustomer/list'
    }),
    getDetail() {
      return item => {
        const {
          type,
          count,
          price,
          remarks,
          times
        } = item;
        const title = type + (remarks && '(' + remarks + ')');
        const total = count * price * times;
        return `${title}: ${count} × ${times} × ${price} = ${total}`;
      };
    }
  },
  watch: {
    data: {
      handler: function (data) {
        if (!data) this.fetchData();
        this.rowspan();
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('bill/billDown/collectRegimentCustomer/fetch', params);
    },
    // 搜索
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    handleCreate({
      rowId,
      customerId
    }) {
      this.$router.push({
        path: this.path,
        query: {
          id: rowId,
          rid: customerId
        }
      });
    }
  }
};