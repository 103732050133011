import UploadBase from '@/components/Upload';
import { mapGetters } from 'vuex';
import { splitPath } from '@/utils';
const defaultState = () => ({
  name: '',
  mobilePhone: '',
  driversLicensPic: '',
  qualificationCertificatePic: ''
});
export default {
  components: {
    UploadBase
  },
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: defaultState(),
      index: -1,
      driversLicensPic: '',
      qualificationCertificatePic: ''
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData' // 账户信息
    }),
    getTitle() {
      return this.isUpdate ? '更新司机' : '添加司机';
    },
    getDriverImage() {
      return this.driversLicensPic;
    },
    getCertImage() {
      return this.qualificationCertificatePic;
    }
  },
  methods: {
    edit(scope) {
      let {
        row
      } = scope;
      this.resetForm();
      this.processImage(row);
      this.index = scope.$index;
      this.form = row;
      this.visible = true;
      this.isUpdate = true;
      this.$store.dispatch('resource/insurance/fetchImage', row.id);
    },
    show() {
      this.resetForm();
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    getFullImage(url) {
      return this.$store.dispatch('resource/driver/fetchImage', url);
    },
    processImage(row) {
      this.driversLicensPic = '';
      this.qualificationCertificatePic = '';
      const {
        driversLicensPic: driverPic,
        qualificationCertificatePic: certPic
      } = row;
      if (driverPic) {
        this.getFullImage(driverPic).then(url => {
          this.driversLicensPic = url;
        });
      }
      if (certPic) {
        this.getFullImage(certPic).then(url => {
          this.qualificationCertificatePic = url;
        });
      }
    },
    handleLicensImage(res) {
      const {
        url
      } = res.data;
      this.form.driversLicensPic = splitPath(url);
    },
    handleCertificateImage(res) {
      const {
        url
      } = res.data;
      this.form.qualificationCertificatePic = splitPath(url);
    },
    deleteLicensImage() {
      this.driversLicensPic = '';
      this.form.driversLicensPic = '';
    },
    deleteCertificateImage() {
      this.qualificationCertificatePic = '';
      this.form.qualificationCertificatePic = '';
    },
    handleCreate() {
      const {
        id,
        groupId,
        travelAgencyId,
        realName
      } = this.userData;
      const userData = {
        createUserId: id,
        groupId,
        travelAgencyId,
        realName
      };
      const opts = {
        ...userData,
        ...this.form,
        busCompanyId: this.$route.query.id,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/driver/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        loading.close();
        this.hide();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    },
    resetForm() {
      this.form = defaultState();

      // 清理图片
      this.$store.commit('resource/common/CLEAR_IMAGE');
      // 清理新增没有id 的图片
      this.$store.dispatch('resource/common/deleteAddImage');
    }
  }
};