/*
 * @Author: HelloMan
 * @Date: 2021-10-08 08:38:47
 * @LastEditors: HelloMan
 * @LastEditTime: 2021-10-08 08:38:48
 * @Description:
 */

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Element.Select.computed.readonly = function () {
  const isIE = !this.isServer && !Number.isNaN(Number(document.documentMode));
  return !(this.filterable || this.multiple || !isIE) && !this.visible;
};
export default Element;