var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", _vm._b({
    staticClass: "table-total",
    class: {
      "hide-bar": _vm.hideScrollBar
    },
    attrs: {
      data: [{}],
      "show-header": false,
      "highlight-current-row": ""
    }
  }, "el-table", _vm.getTableStyle, false), _vm._l(_vm.columnCopy, function (column, index) {
    return _c("el-table-column", _vm._b({
      key: index,
      attrs: {
        align: _vm.align
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [column.slot ? _c("span", [_vm._t(column.slot, null, {
            scope: scope
          })], 2) : _c("span", [_vm._v(_vm._s(scope.row[scope.column.property]))])];
        }
      }], null, true)
    }, "el-table-column", column.attrs || {}, false), [column.children ? _vm._l(column.children, function (child, cIndex) {
      return _c("el-table-column", _vm._b({
        key: cIndex,
        attrs: {
          align: _vm.align
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function (scope) {
            return [child.slot ? _c("span", [_vm._t(child.slot, null, {
              scope: scope
            })], 2) : _c("span", [_vm._v(_vm._s(scope.row[scope.column.property]))])];
          }
        }], null, true)
      }, "el-table-column", child.attrs || {}, false));
    }) : _vm._e()], 2);
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };