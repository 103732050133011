export const COLUMNS = [{
  attrs: {
    label: '订单信息',
    prop: ''
  },
  slot: 'planNumber'
}, {
  attrs: {
    label: '行程日期',
    prop: ''
  },
  slot: 'date'
}, {
  attrs: {
    label: '人数',
    prop: ''
  },
  slot: 'people'
}, {
  attrs: {
    label: '项目',
    prop: 'title'
  }
}, {
  attrs: {
    label: '操作日期',
    prop: '$createTime'
  }
}, {
  attrs: {
    label: '单价',
    prop: 'price'
  }
}, {
  attrs: {
    label: '数量',
    prop: 'count'
  }
}, {
  attrs: {
    label: '安排备注',
    prop: 'remark'
  }
}, {
  attrs: {
    label: '应付',
    prop: 'totalPay'
  }
}, {
  attrs: {
    label: '导游现收',
    prop: 'guidePay'
  }
}, {
  attrs: {
    label: '已付',
    prop: 'totalPayed'
  }
}, {
  attrs: {
    label: '社付',
    prop: 'agencyIncome'
  }
}, {
  attrs: {
    label: '未付',
    prop: 'unPay'
  }
}, {
  attrs: {
    label: '报账导游',
    prop: 'guideName'
  },
  slot: 'guideName'
}, {
  attrs: {
    label: '审核状态',
    prop: ''
  },
  slot: 'auditStatus'
}, {
  attrs: {
    label: '操作',
    prop: ''
  },
  slot: 'action'
}];
export const COLUMNS_VALID = [{
  attrs: {
    label: '订单信息',
    prop: ''
  },
  slot: 'planNumber'
}, {
  attrs: {
    label: '行程日期',
    prop: ''
  },
  slot: 'date'
}, {
  attrs: {
    label: '审核状态',
    prop: ''
  },
  slot: 'auditStatus'
}, {
  attrs: {
    label: '报账导游',
    prop: 'guideName'
  },
  slot: 'guideName'
}, {
  attrs: {
    label: '单位',
    prop: 'otherUnit'
  }
}, {
  attrs: {
    label: '项目',
    prop: 'title'
  }
}, {
  attrs: {
    label: '单价',
    prop: 'price'
  }
}, {
  attrs: {
    label: '数量',
    prop: 'count'
  }
}, {
  attrs: {
    label: '安排备注',
    prop: 'remark'
  }
}, {
  attrs: {
    label: '已收',
    prop: 'totalPayed'
  }
}, {
  attrs: {
    label: '未收',
    prop: 'unPay'
  }
}, {
  attrs: {
    label: '本次下账',
    prop: 'downPayed'
  },
  slot: 'downPayed'
}, {
  attrs: {
    label: '操作',
    prop: ''
  },
  slot: 'action'
}];
export const COLUMNS_VALID_COMPLETE = [{
  attrs: {
    label: '订单信息',
    prop: 'orderNumber'
  }
}, {
  attrs: {
    label: '项目',
    prop: 'title'
  }
}, {
  attrs: {
    label: '行程日期',
    prop: 'date'
  },
  slot: 'date'
}, {
  attrs: {
    label: '销账日期',
    prop: 'cashDate'
  }
}, {
  attrs: {
    label: '销账人',
    prop: 'cashUserName'
  }
}, {
  attrs: {
    label: '销账方式',
    prop: 'cashWay'
  }
}, {
  attrs: {
    label: '下账单位',
    prop: 'companyName2'
  }
}, {
  attrs: {
    label: '金额',
    prop: 'cash'
  }
}, {
  attrs: {
    label: '状态',
    prop: 'cashierAuditStatus'
  },
  slot: 'status'
}
// { attrs: { label: '操作', prop: '' }, slot: 'action' },
];