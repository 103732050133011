import { planEditReception } from '@/config/plan';
import { auditReception } from '@/config/audit';
import PersonCount from '@/components/common/PersonCount.vue';
import AuditCheck from '@/components/common/AuditCheck.vue';
import Audit from '@/components/common/Audit.vue';
import AuditAdmin from '@/components/common/AuditAdmin.vue';
import CustomerInfo from '@/components/common/CustomerInfo.vue';
import { StatusAudit } from '@/components/common';
import { findComponentsUpward, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { typeMixin, rowspanMixin, selectorMixin, auditMixin } from '@/mixin';
export default {
  components: {
    PersonCount,
    StatusAudit,
    AuditCheck,
    Audit,
    AuditAdmin,
    CustomerInfo
  },
  mixins: [typeMixin, rowspanMixin, selectorMixin, auditMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    isAudit: {
      type: Boolean,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columnData = this.isAudit ? auditReception : planEditReception.slice(0, -1);
    return {
      columnData,
      headMergeNum: 2,
      tailMergeNum: columnData.length - 2,
      hasMergeTail: false,
      auditAll: false
    };
  },
  watch: {
    tableData: {
      handler: function () {
        this.$nextTick(() => {
          this.rowspan();
        });
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    if (this.isAudit) {
      this.columnData = visibleCustom ? auditReception : filterCustomColumn(auditReception);
    } else {
      const columns = planEditReception.slice(0, -1);
      this.columnData = visibleCustom ? columns : filterCustomColumn(columns);
    }
  },
  methods: {
    handleEdit(index) {
      const findComp = findComponentsUpward(this, 'PlanReception')[0];
      findComp.showEditDialog(index);
    }
  }
};