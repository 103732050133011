import { orderInfo, tripDate, orderArrangeRemark, orderOther, orderTotalPay, payed, unPay, createTime, actionItem } from './index';
const type = {
  attrs: {
    prop: 'transportType',
    label: '类别',
    'min-width': 80
  }
};
const guide = {
  attrs: {
    prop: 'guideName',
    label: '团上导游'
  },
  slot: 'guide'
};
const lastItem = [orderArrangeRemark, orderOther, orderTotalPay, payed, unPay, createTime, actionItem];

// 按团号去付款 交通
export const TrafficColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'transportCompanyName',
    label: '大交通'
  }
}, type, guide, {
  attrs: {
    prop: 'location',
    label: '地点'
  },
  slot: 'location'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, ...lastItem];