export const Columns = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    'min-width': 150
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: '',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '计调'
  },
  slot: 'dutyOPUserName'
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'guide.name',
    label: '导游',
    width: 100
  },
  slot: 'guide'
}, {
  attrs: {
    prop: '',
    label: '导游端状态',
    width: 100
  },
  slot: 'guideStatus'
}, {
  attrs: {
    prop: 'sendGroupStatus',
    label: '发团状态',
    width: 100
  },
  slot: 'sendGroupStatus'
}, {
  attrs: {
    prop: 'orderType',
    label: '类型',
    width: 100
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'totalAmount',
    label: '额度'
  }
}, {
  attrs: {
    prop: '$surplusLimit',
    label: '剩余额度'
  }
}, {
  attrs: {
    prop: '$totalPay',
    label: '总现付'
  }
}, {
  attrs: {
    prop: 'payAmount',
    label: '支付宝已付'
  }
}, {
  attrs: {
    prop: '$unPay',
    label: '剩余未付'
  }
}, {
  attrs: {
    prop: '$diff',
    label: '差额预估'
  }
}, {
  attrs: {
    prop: '',
    label: '操作',
    width: 160
  },
  slot: 'action'
}];
export const COLUMNS_DETAIL = [
// { attrs: { prop: 'resName', label: '名称' } },
{
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'resName',
    label: '地接系统商家'
  }
}, {
  attrs: {
    prop: 'payNo',
    label: '订单号',
    'min-width': 200
  }
}, {
  attrs: {
    prop: '$consumeCreateTime',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'merchantName',
    label: '商家',
    'min-width': 200
  }
}, {
  attrs: {
    prop: 'consumeAmount',
    label: '账单金额'
  }
}, {
  attrs: {
    prop: 'peerPayAmount',
    label: '企业码付金额'
  }
}];