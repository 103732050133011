import { SelectGuide } from '@/components/common';
import { PersonCount } from '@/components/common';
import { GroupDownBillUnPay as columns } from '@/config/group';
import { reloadMixin, rowspanMixin } from '@/mixin';
import { processAdd } from '@/utils/number';
import billDownMixin from '@/views/billDown/billDownMixin';
export default {
  components: {
    SelectGuide,
    PersonCount
  },
  mixins: [reloadMixin, rowspanMixin, billDownMixin],
  props: {
    resourceId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      columns,
      tableData: [],
      billCheckAll: false,
      searchParams: {},
      sendDate: [],
      orderDate: [],
      headMergeNum: 8,
      tailMergeNum: columns.length - 7,
      hasMergeTail: true,
      statistics: null,
      statisticsKeys: ['payedOrIncomed', 'ckMoney', 'guidePay', 'downPayed', 'owePay']
    };
  },
  computed: {
    data() {
      return this.$store.getters['group/billDownUnPay'];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    },
    getSum() {
      return item => processAdd(item.money, item.otherFee);
    }
  },
  // created() {
  //   this.fetchData()
  // },

  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('group/fetchBillDownUnPay', {
        kpCompanyId: this.resourceId,
        ...params
      }).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    handlePage(param) {
      this.fetchData({
        ...this.searchParams,
        ...param
      });
    },
    handleData(data) {
      this.tableData = data.list;
      this.statistics = data.statistics;
      this.rowspan();
    },
    changeStarTripDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeOrderDate(val) {
      const [starDate, endDate] = val;
      this.searchParams.orderDateS = starDate;
      this.searchParams.orderDateE = endDate;
    },
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    selectOtherType(type, val) {
      this.searchParams[type] = val ? val.value : '';
    },
    caclDownMoney(money) {
      if (!this.tableData.length) return;
      // mixin方法
      this.handleAutoDown(money);
    }
  }
};