import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 订单管理

import Layout from '@/layout';
const settingsRouter = {
  path: '/system',
  component: Layout,
  redirect: '/system/systemConf',
  name: 'system',
  meta: {
    title: 'system',
    icon: 'icon-setting'
  },
  children: [{
    path: '/system/systemConf',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/systemConf/index'))),
    name: 'systemConf',
    // 系统配置
    meta: {
      title: 'systemConf',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/system/accounting',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/accounting'))),
    name: 'systemAccounting',
    // 会计科目
    meta: {
      title: 'systemAccounting',
      icon: 'icon-tongji'
    },
    orderNumber: 2
  }, {
    path: '/system/department',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/department'))),
    name: 'systemDepartmentManage',
    // 部门管理
    meta: {
      title: 'systemDepartmentManage',
      icon: 'icon-huozhuguanli'
    },
    orderNumber: 3
  }, {
    path: '/system/staff',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/staff/index'))),
    name: 'systemStaffManage',
    // 员工管理
    meta: {
      title: 'systemStaffManage',
      icon: 'icon-bumenguanli'
    },
    orderNumber: 4
  }, {
    path: '/system/group-user',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/staffGroup/index'))),
    name: 'systemStaffGroup',
    // 集团员工角色设置
    meta: {
      title: 'systemStaffGroup',
      icon: 'icon-quanxianguanli'
    }
  }, {
    path: '/system/auth',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/auth/index'))),
    name: 'systemStaffAuth',
    // 员工授权
    meta: {
      title: 'systemStaffAuth',
      icon: 'icon-quanxianguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/system/role',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/role/index'))),
    name: 'systemRoleManage',
    // 角色管理
    meta: {
      title: 'systemRoleManage',
      icon: 'icon-jiaoseguanli'
    },
    orderNumber: 5
  }, {
    path: '/system/role-agency',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/roleAgency'))),
    name: 'systemRoleAgency',
    // 角色代办管理
    meta: {
      title: 'systemRoleAgency',
      icon: 'icon-cangkuguanli'
    },
    orderNumber: 6
  }, {
    path: '/system/dictionary',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/dictionary'))),
    name: 'systemDictionary',
    // 字典管理
    meta: {
      title: 'systemDictionary',
      icon: 'icon-shoufahuorenxinxiguanli'
    },
    orderNumber: 7
  }, {
    path: '/system/company',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/companyInfo/index'))),
    name: 'systemCompany',
    // 公司信息
    meta: {
      title: 'systemCompany',
      icon: 'icon-gongsiguanli'
    },
    orderNumber: 8
  }, {
    path: '/system/companyGoal',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/companyGoal/index'))),
    name: 'systemCompanyGoal',
    // 公司目标
    meta: {
      title: 'systemCompanyGoal',
      icon: 'el-icon-s-flag'
    },
    orderNumber: 9
  }, {
    path: '/system/customGoal',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/customGoal/index'))),
    name: 'systemCustomGoal',
    // 客户目标
    meta: {
      title: 'systemCustomGoal',
      icon: 'el-icon-s-custom'
    },
    orderNumber: 10
  }, {
    path: '/system/notice',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/notice'))),
    name: 'systemNotice',
    // 公告管理
    meta: {
      title: 'systemNotice',
      icon: 'icon-tongzhigonggao'
    },
    orderNumber: 11
  }, {
    path: '/system/guideNotice',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/guideNotice'))),
    name: 'systemGuideNotice',
    // 导游帮助信息
    meta: {
      title: 'systemGuideNotice',
      icon: 'icon-tongzhigonggao'
    },
    orderNumber: 11
  }, {
    path: '/system/buttJoint',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/buttJoint'))),
    name: 'systemButtJoint',
    // 对接配置
    meta: {
      title: 'systemButtJoint',
      icon: 'el-icon-s-promotion'
    },
    orderNumber: 12
  }, {
    path: '/system/process',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/system/auditProcess'))),
    name: 'systemProcess',
    // 审核流程
    meta: {
      title: 'systemProcess',
      icon: 'el-icon-s-promotion'
    },
    orderNumber: 13
  }]
};
export default settingsRouter;