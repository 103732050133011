var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      width: "960px",
      title: "协议内容",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-field",
    staticStyle: {
      "margin-top": "-20px"
    }
  }, [_c("h3", {
    staticClass: "pr10"
  }, [_vm._v(" 新增项目 ")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.addRow
    }
  }, [_vm._v(" 添加 ")])], 1), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.contentType,
            callback: function ($$v) {
              _vm.$set(scope.row, "contentType", $$v);
            },
            expression: "scope.row.contentType"
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _vm._t("footer", function () {
    return [_c("span", {
      staticClass: "flex flex-center mt20"
    }, [_c("el-button", {
      on: {
        click: _vm.handleCancel
      }
    }, [_vm._v("取 消")]), _c("el-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: _vm.handleConfirm
      }
    }, [_vm._v(" 保 存 ")])], 1)];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };