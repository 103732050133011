import "core-js/modules/es.array.reduce.js";
// import { processAdd, processMinus } from '@/utils'
export const MAP_KEYS = {
  fee: 'fee',
  subsidy: 'subsidy'
};
export function generateTableItem(months = 12) {
  return Array.from({
    length: months
  }).map((_, idx) => {
    const num = idx + 1 + '';
    const v = num.padStart(2, '0');
    return {
      attrs: {
        prop: v + '',
        label: `${v}月`
      },
      children: [{
        attrs: {
          prop: v + MAP_KEYS.fee,
          label: '应收团款'
        }
      }, {
        attrs: {
          prop: v + MAP_KEYS.subsidy,
          label: '应付返款'
        }
      }]
    };
  });
}
export const generageStatisticsKeys = () => {
  return Array.from({
    length: 12
  }).reduce((acc, cur, index) => {
    const num = `${index + 1}`;
    const val = num.padStart(2, '0');
    const fee = val + MAP_KEYS.fee;
    const subsidy = val + MAP_KEYS.subsidy;
    acc = [...acc, fee, subsidy];
    return acc;
  }, []);
};
export const formatRowProperty = data => {
  return data.reduce((acc, cur) => {
    const {
      fee,
      subsidy,
      yearOrMonth
    } = cur;
    const str = yearOrMonth.split('-')[1];
    acc[str + MAP_KEYS.fee] = fee;
    acc[str + MAP_KEYS.subsidy] = subsidy;
    return acc;
  }, {});
};

// const totalRow = (data, key) => {
//   return data.reduce((acc, cur) => (acc, processAdd(acc, cur[key])), 0)
// }

// export const formatRowTotal = data => {
//   const totalFee = totalRow(data, MAP_KEYS.fee)
//   const totalSubsidy = totalRow(data, MAP_KEYS.subsidy)
//   const totalBalance = processMinus(totalFee, totalSubsidy)

//   return { totalFee, totalSubsidy, totalBalance }
// }