import request from '@/utils/request';

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceDriver/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchDriver(params) {
  return request({
    url: '/resource/resourceDriver/page',
    method: 'get',
    params
  });
}

// 更新
export function createOrUpdateDriver(data) {
  return request({
    url: '/resource/resourceDriver/save',
    method: 'post',
    data
  });
}

// 删除
export function deleteDriver(id) {
  return request({
    url: '/resource/resourceDriver/del',
    method: 'post',
    data: {
      id
    }
  });
}