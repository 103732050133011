import FormCompany from './FormCompany';
import TableList from './TableList';
import { BtnCreate, DialogDetail, DialogAgreement, DialogLog } from '@/components/common';
import { resourceMixin, searchMixin } from '@/mixin';
import { resourceCompany } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  components: {
    BtnCreate,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    FormCompany,
    TableList
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    tableLoading: false,
    columnData: resourceCompany,
    tableData: [],
    total: 0,
    searchParams: {
      name: '',
      isEnable: -1 // -1 全部， 0 未启用， 1 启用
    }
  }),
  computed: {
    companyData() {
      return this.$store.getters['resource/company/companyData'];
    }
  },
  watch: {
    companyData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.tableLoading = true;
      this.$store.dispatch('resource/company/fetch', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteItem(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/company/delete', id).then(() => {
        this.initData();
        loading.close();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    },
    handleSearch() {
      this.searchParams.pageNum = 1;
      this.fetchData(this.searchParams);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog(item) {
      this.$refs.formRef.show(item);
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteItem(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      // this.showCreateDialog(copy)
      this.$refs.formRef.show(copy);
    },
    showTableDetail(scope) {
      // this.$store.dispatch('resource/company/fetchImage', scope.row.id)
      this.$refs.detailRef.show(scope.row, {
        title: '公司',
        name: '公司名称'
      });
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/company/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};