import { DEFAULT_PAGE } from '@/config';
import { isSuperMan } from '@/utils/power';
export default {
  props: {
    columnData: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    total: {
      type: Number,
      default: 0
    },
    totalData: {
      type: Array,
      default: () => []
    },
    page: {
      type: Object,
      default: () => DEFAULT_PAGE
    }
  },
  data() {
    return {
      isSticky: true,
      height: 'auto',
      windowHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.windowHeight = document.getElementsByClassName('app-main')[0].clientHeight;
    });
  },
  methods: {
    // 判断是否已归档
    comparePlanStatus(row) {
      const {
        planStatus
      } = row;
      if (planStatus == 'FILE' && !isSuperMan()) {
        return false;
      } else {
        return true;
      }
    },
    handleDelete(scope) {
      // 将操作放到父级组件中管理操作
      this.$parent.deleteTableRow(scope);
    },
    handleEdit(scope) {
      this.$parent.editTableRow(scope);
    },
    handleShow(scope) {
      this.$parent.showTableDetail(scope);
    },
    handleLog(scope) {
      this.$parent.showLog(scope);
    },
    handleAgreement(scope) {
      this.$parent.showAgreement(scope);
    },
    changeStatus(row) {
      this.$parent.createOrUpdate(row);
      // setTimeout(() => {
      //   this.$parent.fetchList()
      // }, 100)
    },
    changeIsExport(row) {
      this.$parent.createOrUpdate(row);
    },
    handleBindBusiness(row) {
      this.$parent.handleBind(row);
    },
    handleUnBindBusiness(row) {
      this.$parent.handleUnBind(row);
    }
  }
};