import { findComponentsUpward } from '@/utils';
import { TYPES } from '@/config/resource';
import TableAgrShop from '@/views/resource/shopping/TableAgrShop';
import TableAgrScenic from '@/views/resource/scenic/TableAgrScenic';
import TableAgrHotel from '@/views/resource/hotel/components/TableAgrHotel';
import TableAgr from './TableAgr';
import { formMixin } from '@/mixin';
export default {
  name: 'DialogAgreementEdit',
  components: {
    TableAgrShop,
    TableAgrScenic,
    TableAgr,
    TableAgrHotel
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      form: {},
      sendDate: [],
      type: null,
      parentComp: null,
      currentView: '',
      validateForm: {
        name: [{
          required: true,
          message: '名字不能为空'
        }],
        startTime: [{
          required: true,
          message: '开始日期不能为空'
        }],
        endTime: [{
          required: true,
          message: '结束日期不能为空'
        }]
      }
    };
  },
  methods: {
    show({
      item,
      type
    }) {
      this.type = type;
      this.visible = true;
      this.form = item;
      const {
        id,
        resourceId
      } = item;
      this.resourceId = resourceId;
      this.sendDate = item.expire ? item.expire : [];
      if (type == TYPES.shop) {
        this.currentView = 'TableAgrShop';
      } else if (type == TYPES.scenic) {
        this.currentView = 'TableAgrScenic';
      } else if (type == TYPES.hotel) {
        this.currentView = 'TableAgrHotel';
      } else {
        this.currentView = 'TableAgr';
      }
      if (id) {
        this.fetchData(id);
      } else {
        this.$refs[this.currentView].edit(id, this.type, []);
      }
    },
    hide() {
      this.visible = false;
    },
    fetchData(id) {
      this.$store.dispatch('resource/common/fetchAgreementDetail', id).then(data => {
        this.form.expire = [data.startTime, data.endTime];
        this.$refs[this.currentView].edit(id, this.type, data.contractPrices);
      });
    },
    // 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.form.startTime = startDate;
      this.form.endTime = endDate;
    },
    handleChange(type, val) {
      this.form[type] = val;
    },
    handleUpdate() {
      // findComps.handleUpdate(this.form)
      this.$refs[this.currentView].handleConfirm(this.form);
    },
    createOrUpdateAgr(opts) {
      if (!this.handleValidate()) return;
      // const dataList = this.beforeCommit(opts)
      opts.resourceId = opts.resourceId || this.resourceId;
      opts.resourceType = opts.resourceType || this.type;
      this.$store.dispatch('resource/common/createOrUpdateAgr', opts).then(() => {
        const findComps = findComponentsUpward(this, 'Resource')[0];
        findComps.fetchAgreement();
        // this.$parent.fetchAgreement()
        this.hide();
      });
    }
  }
};