var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "cell-class-name": _vm.changeShopCellStyle,
      "header-cell-class-name": _vm.changeShopCellStyle,
      "summary-method": _vm.getShopSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customer.name) + " ")];
      }
    }, {
      key: "customerAreaManager",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customer.customerAreaManager) + " ")];
      }
    }, {
      key: "totalBuyMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopProfit.totalBuyMoney) + " ")];
      }
    }, {
      key: "shopPerCapita",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopProfit.shopPerCapita) + " ")];
      }
    }, {
      key: "totalShopRebate",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.shopProfit.totalShopRebate) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };