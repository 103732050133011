import "core-js/modules/es.array.push.js";
import { TopBar, DelCheckAll, Audit, AuditCheck, AuditAdmin, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectScenic, SelectPayType, SelectPlanNumber } from '@/components/common';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, permissionMixin, systemMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { processMinus, processAdd, multiplyNumber } from '@/utils/number';
const itemListKeys = () => ({
  accompanyCount: 0,
  accompanyPrice: 0,
  adultCountReceive: 0,
  childCountReceive: 0,
  adultCount: 0,
  adultFreeCount: 0,
  childCount: 0,
  childPrice: 0,
  freeTotal: 0,
  otherFee: 0,
  price: 0,
  sellPrice: 0,
  settlementPrice: 0,
  ticketList: null,
  ticketType: '',
  totalSum: 0
});

// 加点
const extraState = () => ({
  adultCountReceive: 0,
  childCountReceive: 0,
  guideCommission: 0,
  driverCommission: 0,
  accompanyCommission: 0,
  totalAmountReceive: 0
});
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  auditStatus: false,
  payType: 'guidesign',
  planId: '',
  scenicId: '',
  orderScenicTicketList: [],
  totalPay: 0,
  remark: '',
  orderDate: '',
  totalSettlement: 0,
  tripProfit: 0,
  totalProfit: 0,
  driverRate: 0,
  guideRate: 0,
  accompanyRate: 0,
  isAddScenic: false,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  rowTotal: 0,
  ...itemListKeys()
});
export default {
  components: {
    TopBar,
    DelCheckAll,
    Audit,
    AuditCheck,
    StatusAudit,
    SelectScenic,
    SelectPayType,
    AuditAdmin,
    DialogOrder,
    DialogList,
    PaymentTotal,
    AuditRoleAll,
    SelectPlanNumber,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, permissionMixin, planMixin, reloadMixin, systemMixin],
  props: {
    isExtra: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      deletePath: 'plan/scenic/delete',
      updatePath: 'plan/scenic/update',
      fetchPath: 'plan/scenic/fetch',
      headMergeNum: 2,
      tailMergeNum: 14,
      hasMergeTail: true,
      rowId: 1000000000000,
      scenicList: [],
      // 当前计划中的景区列表
      lastScenicId: '',
      lastScenicName: '',
      auditAll: false,
      totalSummary: {},
      statisticsKeys: ['otherFee', 'rowTotal', 'totalSum', 'totalAmountReceive', 'driverCommission', 'accompanyCommission', 'guideCommission', 'totalProfit', 'tripProfit'],
      priceSystemCofig: false,
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      scenicData: 'plan/scenic/scenicData'
    }),
    hasMoreTicket() {
      return item => {
        return item && item.length > 1;
      };
    },
    getMoreTicket() {
      return item => {
        return item && item.length > 1 && item.slice(1);
      };
    }
  },
  created() {
    this.fetchData({
      isAddScenic: this.isExtra
    });
    // 景区底价是否默认等于单价配置
    this.priceSystemCofig = this.auditFinalPrice;
  },
  methods: {
    initData(data) {
      this.tableData = this.formatData(data.list);
      const hasFloorPrice = this.hasSettlementPrice('scenicFloorPriceAudit');
      this.$nextTick(() => {
        // 如果不显示订单信息

        // if (!isOrderInfo) {
        //   this.headMergeNum = isOrderInfo ? 2 : 2
        // }
        // this.headMergeNum = isOrderInfo ? 2 : 2

        this.tailMergeNum = hasFloorPrice ? this.columnData.length - 16 : this.columnData.length - 15;
        this.visible = true;
        this.resetData();
      });
    },
    fetchPlanSceneList() {
      this.$store.dispatch('plan/scenic/fetchScenicPlanList', {
        planId: this.currentPlanId
      });
    },
    beforeChangeResource(row, val) {
      if (val) {
        this.lastScenicId = row.scenicId;
        this.lastScenicName = row.scenicName;
      }
    },
    setProgress(row, val) {
      row.auditProgress = val;
    },
    // 选择景点
    handleSelect(index, val) {
      // 如果未选中任何数据，直接返回
      if (val.label == '') return;
      const {
        id: scenicId,
        label
      } = val;
      this.$store.dispatch('plan/scenic/fetchAgreementPrice', {
        planId: this.currentPlanId,
        scenicId
      }).then(data => {
        const {
          orderScenicTicketList
        } = data;
        if (!orderScenicTicketList) {
          this.$bus.tip({
            type: 'warning',
            message: '没有添加景区协议或者协议已过期！'
          });
          // 恢复之前的景区
          const rowItem = this.tableData[index];
          rowItem.scenicId = this.lastScenicId;
          rowItem.scenicName = this.lastScenicName;
        } else {
          this.tableData[index].scenicId = scenicId;
          this.tableData[index].scenicName = label;
          const firstItem = orderScenicTicketList[0];
          const curItem = this.tableData[index];
          curItem.ticketList = this.formatOptions(orderScenicTicketList);
          // 选中第一个
          firstItem && this.mergeRowDataAndPrice(curItem, [firstItem]);
          this.processInput(curItem);
        }
      });
    },
    // 当前景点添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        ticketList,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/scenic/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false
        };
        this.mergeRowDataAndPrice(rowItem, ticketList);
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.$nextTick(() => {
        this.resetData();
      });
    },
    // 选择门票类型
    handleicketType(row, val) {
      const {
        label
      } = val;
      row.ticketType = label;
      row.price = val.price;
      // 景区底价是否默认等于单价配置(配置打开则结算价等于单价)
      if (this.priceSystemCofig) {
        row.settlementPrice = val.price;
      } else {
        row.settlementPrice = val.settlementPrice || 0;
      }
    },
    // 新增一条景区
    handlePlus() {
      let {
        adultCount,
        childCount,
        accompanyCount
      } = this.planInfo || {};
      adultCount = adultCount || 0;
      childCount = childCount || 0;
      accompanyCount = accompanyCount || 0;
      let item = {
        ...defaultState(),
        ...extraState(),
        adultCount,
        childCount,
        accompanyCount,
        rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: adultCount,
        adultCountReceive: adultCount
      };
      this.tableData.push(item);
      this.visible = true;
      this.rowspan();
      this.checkSelectAll();

      // this.processInput(item)
    },
    mergeRowDataAndPrice(row, ticketList) {
      // eslint-disable-next-line no-extra-semi
      ;
      ['price', 'ticketType', 'settlementPrice'].forEach(key => {
        row[key] = ticketList[0][key];
      });
      // 景区底价是否默认等于单价配置(配置打开则结算价等于单价)
      if (this.priceSystemCofig) {
        row.settlementPrice = row.price;
      }

      // eslint-disable-next-line no-extra-semi
      ;
      ['guideRate', 'driverRate', 'accompanyRate'].forEach(key => {
        row[key] = ticketList[0][key];
      });

      // this.getTotalPay(row)
    },
    // 处理Input输入框
    processInput(row) {
      /**
       * 总成本 = 其他成本 + 单价 * 成人数 + 儿童价 * 儿童数 + 全陪价 * 全陪数
       * 实收金额 = 成人实收数 * 成人卖价 + 儿童价 * 儿童实收数
       * 总利润 = 实收金额 - 总成本
       * 社利 = 总利润 -（导佣 + 司佣 + 赔佣）
       * 导佣 = 导佣 = 实收金额 - 景区成本 - 司佣 - 陪佣
       * 司佣/赔佣 = 实收金额 * 司佣比例/赔佣比例
       * 金额 = 成人实收数 * 成人卖价 + 儿童价 * 儿童实收数
       */
      if (this.priceSystemCofig) {
        row.settlementPrice = row.price;
      }
      // 总成本
      row.totalPay = this.calcCost(row);

      // 总成本(旅行社成本)
      row.totalSum = this.calcCost(row, 'settlementPrice');

      // 实收金额
      row.totalAmountReceive = this.calcTotalAmountReceive(row);

      // 总利润
      row.totalProfit = this.caleTotalCost(row);

      // 司佣
      row.driverCommission = this.changeRate(row, 'driverRate');

      // 陪佣
      row.accompanyCommission = this.changeRate(row, 'accompanyRate');

      // 导佣（计算方式不一样，单独核算）
      row.guideCommission = this.changeGuideRate(row);

      // 社利
      row.tripProfit = this.calcTripCost(row);
    },
    // 校验输入的值
    changeInput(row) {
      this.processInput(row);
      // 重新计算当前行合并后的价格
      this.$nextTick(() => {
        this.mergeRowMoney();
        // this.changeTotalCost(row) // 加点总利润
      });
    },
    changeRateInput(row, type) {
      row[type + 'Commission'] = this.changeRate(row, type + 'Rate');
      if (type == 'guide') {
        row.guideCommission = this.changeGuideRate(row);
      }
      this.changeCommission(row);
    },
    changeCommission(row) {
      // 总利润
      row.totalProfit = this.caleTotalCost(row);

      // 社利
      row.tripProfit = this.calcTripCost(row);
    },
    // 实收金额
    calcTotalAmountReceive(row) {
      // 实收金额：成人实收数 * 成人卖价 + 儿童价 * 儿童实收数
      const {
        sellPrice,
        adultCountReceive
      } = row;
      const {
        childPrice,
        childCountReceive
      } = row;
      return processAdd(sellPrice * adultCountReceive, childPrice * childCountReceive);
    },
    // 计算总成本
    calcCost(row, type = 'price') {
      // 总成本：其他成本 + 单价 * 成人数 + 儿童价 * 儿童数 + 全陪价 * 全陪数

      const {
        adultCount,
        adultFreeCount,
        childPrice,
        childCount,
        accompanyPrice,
        accompanyCount,
        otherFee
      } = row;
      const price = row[type];
      const total = processAdd(price * (adultCount - adultFreeCount), childPrice * childCount, accompanyPrice * accompanyCount, otherFee);
      return total;
    },
    // 加点实收金额
    calcAmountReceive(row) {
      // 成本 = 单价 * 成人实收
      const {
        sellPrice,
        adultCountReceive,
        childCountReceive,
        childPrice
      } = row;
      const total = processAdd(sellPrice * adultCountReceive, (childCountReceive || 0) * childPrice);
      return total;
    },
    // 计算加点总利润
    caleTotalCost(row) {
      // 总利润: 实收金额 - 总成本
      const {
        totalAmountReceive,
        totalPay
      } = row;
      return processMinus(totalAmountReceive, totalPay);
    },
    // 计算社利
    calcTripCost(row) {
      // 社利：总利润 - 导佣 - 司佣 - 赔佣
      const {
        totalProfit,
        guideCommission,
        driverCommission,
        accompanyCommission
      } = row;
      const res = processMinus(totalProfit, processAdd(guideCommission, driverCommission, accompanyCommission));
      return res;
    },
    // 佣金比例计算
    changeRate(row, val) {
      // 佣金 = 总利润 * 比例
      return row.totalProfit * row[val] / 100;

      // this.getTripProfit(row) // 社利
    },
    // 导佣计算
    changeGuideRate(row) {
      const {
        totalAmountReceive,
        rowTotal,
        driverCommission,
        accompanyCommission,
        guideRate
      } = row;

      /**
       * 这里要根据配置来计算导佣
       * 默认算法1：导佣 = （总利润 - 司佣 - 陪佣）* 导佣比例
       * 算法2：导佣 = 总利润 * 导佣比例
       */

      // 算法2
      if (!this.isDefScenicAddAlgorithm) {
        return this.changeRate(row, 'guideRate');
      }

      // 默认：导佣 = 实收金额 - (景区成本 + 司佣 + 陪佣) * 导佣比例
      const money = processMinus(totalAmountReceive, processAdd(rowTotal, driverCommission, accompanyCommission));
      return multiplyNumber(money, guideRate / 100);
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderScenicTickets;
        // 下拉列表数据
        const ticketListReq = it.reqOrderScenicTickets || [];
        const {
          id,
          auditProgress,
          payType
        } = it;
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          scenicPlanId: id,
          itemIndex: 0,
          isChecked: false,
          isCustom: false,
          isAddScenic: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          payType: payType.toLowerCase(),
          ticketList: this.formatOptions(ticketListReq)
        };
        // 是否是加点
        item = {
          ...extraState(),
          ...item
        };
        item.isAddScenic = true;
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcCost(v);
            const newItem = {
              ...item,
              ...v,
              itemId: v.id,
              // 子订单id
              itemIndex: i,
              totalSum
            };
            result.push(newItem);
          });
        } else {
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'reqOrderScenicTickets', 'respOrderScenicTickets'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom && it.itemId ? item.id = it.id : null;
        it.totalPay = it.totalPay || 0;

        // 定义子项目的列表
        it.orderScenicTicketList = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });

        // 是否是加点
        if (this.isExtra) {
          it.isAddScenic = true;
        }
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.scenicPlanId;
        it.payType = it.payType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.scenicPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        delete item.ticketList;

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.orderScenicTicketList.push(item);
        } else {
          it.orderScenicTicketList.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    // 校验表单数据
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          scenicId,
          adultCount,
          ticketType,
          orderDate
        } = row;
        if (!scenicId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择景区！'
          });
          return false;
        }
        if (!orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择日期！'
          });
          return false;
        }
        if (!ticketType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择门票类型！'
          });
          return false;
        }
        if (adultCount === '') {
          this.$bus.tip({
            type: 'warning',
            message: '请填写成人数量！'
          });
          return false;
        }
      }
      return true;
    },
    // 格式化下拉列表
    formatOptions(list) {
      if (!list.length) return;
      return list.map((it, index) => {
        it.label = it.ticketType;
        it.name = it.ticketType;
        it.value = index + 1;
        it.id = index + 1;
        return it;
      });
    },
    // 格式化下拉列表
    formatScenicOptions(list) {
      if (!list.length) return;
      return list.map(it => {
        it.label = it.name;
        it.value = it.id;
        return it;
      });
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(this.processInput);
    },
    resetData() {
      this.rowspan();
      this.mergeRowMoney(false); // 合计单条数据金额
    }
  }
};