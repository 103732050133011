var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box age-range flex"
  }, [_c("LabelBase", {
    attrs: {
      label: ""
    }
  }), _c("el-input", {
    staticStyle: {
      width: "70px"
    },
    attrs: {
      onkeyup: "value=value.replace(/[^\\d]/g,'')",
      placeholder: "最小年龄",
      clearable: ""
    },
    on: {
      change: _vm.changeStart
    },
    model: {
      value: _vm.getStart,
      callback: function ($$v) {
        _vm.getStart = $$v;
      },
      expression: "getStart"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "2px"
    }
  }), _c("el-input", {
    staticStyle: {
      width: "70px"
    },
    attrs: {
      onkeyup: "value=value.replace(/[^\\d]/g,'')",
      clearable: "",
      placeholder: "最大年龄"
    },
    on: {
      change: _vm.changeEnd
    },
    model: {
      value: _vm.getEnd,
      callback: function ($$v) {
        _vm.getEnd = $$v;
      },
      expression: "getEnd"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };