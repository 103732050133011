import guideMixin from './guide';
const columnData = [{
  label: '景区',
  prop: 'name'
}, {
  label: '类型',
  prop: 'ticketType',
  width: '45px'
}, {
  label: '加点',
  prop: 'isAddScenic',
  width: '24px'
}, {
  label: '日期',
  prop: 'orderDate',
  width: '80px'
}, {
  label: '成人价',
  prop: 'price',
  width: '35px'
}, {
  label: '成人数',
  prop: 'adultCount',
  width: '35px'
}, {
  label: '成人免',
  prop: 'adultFreeCount',
  width: '35px'
}, {
  label: '儿童价',
  prop: 'childPrice',
  width: '35px'
}, {
  label: '儿童数',
  prop: 'childCount',
  width: '35px'
}, {
  label: '儿童免',
  prop: 'childFreeCount',
  width: '35px'
}, {
  label: '其他',
  prop: 'otherFee',
  width: '35px'
}, {
  label: '金额',
  prop: 'reimbMoney',
  split: true
}, {
  label: '备注',
  prop: 'remarks',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};