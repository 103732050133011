import { planItineraryListDetail } from '@/config/plan';
import { IconRight, IconWrong } from '@/components/common';
import { dictionaryMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { formatItinerarys } from '@/views/order/utils';
import { getSelectLabel } from '@/config/select';
export default {
  components: {
    IconRight,
    IconWrong
  },
  mixins: [dictionaryMixin],
  data() {
    return {
      tableData: [],
      getSelectLabel,
      columnData: planItineraryListDetail
    };
  },
  computed: {
    ...mapGetters({
      planOrderInfo: 'plan/index/planOrderInfo'
    })
  },
  watch: {
    planOrderInfo: {
      handler: 'handleInit',
      immediate: true
    }
  },
  methods: {
    handleInit(data) {
      if (!data) {
        this.$store.dispatch('plan/index/getOrderByPlanId', this.$route.query.id);
        return;
      }
      // 重组参数
      data.forEach(it => {
        if (it.itinerarys && it.itinerarys.length) {
          it.itinerarys = formatItinerarys(it.itinerarys);
          it.itinerarys.forEach(item => {
            if (item.transports.length) {
              const {
                endPlace,
                startPlace,
                transportType
              } = item.transports[0];
              item.endPlace = endPlace;
              item.startPlace = startPlace;
              item.transportType = transportType;
            }
          });
        }
      });
      this.tableData = data;
    }
  }
};