import FormOrder from './FormDetail';
import { formatData } from '../helper';
export default {
  components: {
    FormOrder
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show(item) {
      this.form = formatData(item);
      this.visible = true;
      // this.fetchData({ id: item.id })
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      return this.$store.dispatch('order/resourcePlan/detail', params).then(data => {
        if (!data) return;
        this.form = formatData(data);
      });
    },
    onPrint() {
      this.$nextTick(() => {
        // const { id, travelAgencyId } = this.form
        // this.$store.dispatch('order/resourcePlan/printCount', {
        //   id,
        //   travelAgencyId,
        // })
        let routeUrl = this.$router.resolve({
          path: '/print-resource-plan',
          query: {
            id: this.form.id
          }
        });
        window.open(routeUrl.href, '_blank');
      });
    }
  }
};