import { orderTypeLabel } from '@/config/order';
export default {
  name: 'TypeOrder',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    orderTypeLabel
  })
};