var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "restaurant-info"
  }, [_c("CheckboxBase", {
    attrs: {
      label: _vm.label
    },
    model: {
      value: _vm.form.is,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is", $$v);
      },
      expression: "form.is"
    }
  }), _vm.form.is ? [_c("div", {
    staticClass: "item"
  }, [_c("InputNumber", {
    attrs: {
      label: "数量",
      max: 100000,
      "controls-position": "right"
    },
    model: {
      value: _vm.form.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  }, [_c("span", [_vm._v("个")])])], 1), _c("div", {
    staticClass: "item"
  }, [_c("InputNumber", {
    attrs: {
      label: "面积",
      max: 100000,
      "controls-position": "right"
    },
    model: {
      value: _vm.form.area,
      callback: function ($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }, [_c("span", [_vm._v("平方米")])])], 1), _c("div", {
    staticClass: "item"
  }, [_c("InputNumber", {
    attrs: {
      label: "接待量",
      "controls-position": "right",
      max: 100000
    },
    model: {
      value: _vm.form.capacity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "capacity", $$v);
      },
      expression: "form.capacity"
    }
  }, [_c("span", [_vm._v("人")])])], 1)] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };