/* eslint-disable no-empty-pattern */

import { channelListRequest, channelExportRequest } from '@/api/billCheck';
const defaultState = () => ({
  channelData: null
});
const state = defaultState();
const getters = {
  channelData: state => state.channelData
};
const actions = {
  list({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      return channelListRequest(params).then(res => {
        const {
          data
        } = res;
        commit('SET_DATA', {
          type: 'channelData',
          data
        });
        resolve(data);
      }).catch(err => reject(err));
    });
  },
  export({}, params) {
    return new Promise((resolve, reject) => {
      return channelExportRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res);
        }
      }).catch(err => reject(err));
    });
  }
};
const mutations = {
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};