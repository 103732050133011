/**
 * @module components/base/Checkbox
 * @desc 选择框框组件
 * @vue-prop {String} label - 选择框对应的名称
 * @vue-data {Boolean} checked - 是否选中
 */

export default {
  name: 'CheckboxBase',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};