var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "80px"
    }
  }, [_c("div", {
    staticClass: "flex flex-justify-center"
  }, [_c("el-form-item", {
    attrs: {
      label: "团号",
      prop: "planNumber"
    }
  }, [_c("SelectPlanList", {
    on: {
      "on-select": _vm.onSelectPlanNumber
    },
    model: {
      value: _vm.form.planNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "planNumber", $$v);
      },
      expression: "form.planNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: ""
    }
  }), _c("el-form-item", {
    attrs: {
      label: "乘船日期",
      prop: "shipDate"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.form.shipDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shipDate", $$v);
      },
      expression: "form.shipDate"
    }
  })], 1)], 1), _c("el-divider"), _vm._l(_vm.form.tickets, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "flex flex-item-center"
    }, [_c("el-form-item", {
      attrs: {
        label: item.shipType
      }
    }), _c("div", {
      staticClass: "mt10"
    }, [_c("el-form-item", {
      attrs: {
        label: "下舱"
      }
    }, [_c("InputNum", {
      model: {
        value: item.downCount,
        callback: function ($$v) {
          _vm.$set(item, "downCount", $$v);
        },
        expression: "item.downCount"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        label: "上舱"
      }
    }, [_c("InputNum", {
      model: {
        value: item.upCount,
        callback: function ($$v) {
          _vm.$set(item, "upCount", $$v);
        },
        expression: "item.upCount"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        label: "vip舱"
      }
    }, [_c("InputNum", {
      model: {
        value: item.vipCount,
        callback: function ($$v) {
          _vm.$set(item, "vipCount", $$v);
        },
        expression: "item.vipCount"
      }
    })], 1)], 1), _c("el-form-item", {
      attrs: {
        label: "备注"
      }
    }, [_c("InputBase", {
      model: {
        value: item.remarks,
        callback: function ($$v) {
          _vm.$set(item, "remarks", $$v);
        },
        expression: "item.remarks"
      }
    })], 1)], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };