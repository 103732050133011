var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "update-password"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("el-form", {
    ref: "pwdForm",
    attrs: {
      model: _vm.pwdForm,
      rules: _vm.pwdRules
    }
  }, [_c("el-alert", {
    attrs: {
      title: "温馨提示：密码必须为字母 + 数字混合，且长度至少6位！",
      type: "error"
    }
  }), _c("div", {
    staticClass: "mt20"
  }), _c("h2", [_vm._v("修改密码")]), _c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "旧密码"
    },
    model: {
      value: _vm.pwdForm.oldPassword,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "oldPassword", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.oldPassword"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "password"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "新密码"
    },
    model: {
      value: _vm.pwdForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "passwordConfirm"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "确认密码"
    },
    model: {
      value: _vm.pwdForm.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "passwordConfirm", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.passwordConfirm"
    }
  })], 1), _c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      loading: _vm.loading,
      size: "medium",
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.updatePassword.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.update")) + " ")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };