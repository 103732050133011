import { getUserData } from '@/utils/cookie';
export const setStorage = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};
export const getStorage = key => {
  return JSON.parse(window.localStorage.getItem(key));
};
export const removeStorage = key => {
  window.localStorage.removeItem(key);
};
export const clearStorage = () => {
  window.localStorage.clear();
};
let TABLE_COL_KEY;
export const setTableColumnKey = userInfo => {
  const {
    id,
    roleId,
    travelAgencyId
  } = userInfo;
  TABLE_COL_KEY = `TABLE_COL_KEY-${id}-${roleId}-${travelAgencyId}`;
};
let TABLE_COL_CACHE = {};
try {
  const userInfo = getUserData();
  if (userInfo && JSON.stringify(userInfo) != '{}') {
    const {
      id,
      roleId,
      travelAgencyId
    } = userInfo;
    TABLE_COL_KEY = `TABLE_COL_KEY-${id}-${roleId}-${travelAgencyId}`;
  }
} catch (error) {
  console.log('error:', error);
}
export const setTableColumnStorage = ({
  path,
  data,
  style = {}
}) => {
  const oldData = getTableColumnStorage() || TABLE_COL_CACHE;
  oldData[path] = data;
  oldData[path + '__STYLE'] = style;
  setStorage(TABLE_COL_KEY, JSON.stringify(oldData));
};
export const getTableColumnStorage = () => {
  let data;
  try {
    data = JSON.parse(getStorage(TABLE_COL_KEY));
  } catch (err) {
    console.log('没有自定义表格数据');
  }
  return data;
};