const width40 = 40;
const width50 = 50;
const width60 = 60;
const width80 = 80;
const width90 = 90;
const width100 = 100;
export const Columns = [{
  attrs: {
    prop: 'planNumber',
    label: '订单信息',
    'min-width': 160
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': width90
  },
  slot: 'tripDate'
}, {
  attrs: {
    prop: 'number',
    label: '人数',
    'min-width': width100
  },
  slot: 'number'
}, {
  attrs: {
    prop: 'guideName',
    label: '报账导游',
    'min-width': width80
  }
}, {
  attrs: {
    prop: '$cashType',
    label: '付款方式'
  }
}, {
  attrs: {
    prop: 'scenicName',
    label: '景区',
    'min-width': 160
  },
  slot: 'scenicName'
}, {
  attrs: {
    prop: 'orderDate',
    label: '订单日期',
    'min-width': width90
  }
}, {
  attrs: {
    prop: 'ticketType',
    label: '门票'
  }
}, {
  attrs: {
    prop: 'sellPrice',
    label: '成人卖价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'price',
    label: '成人单价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'settlementPrice',
    label: '成人结算价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'adultCountItem',
    label: '成人数',
    'min-width': width40
  },
  slot: 'adultCountItem'
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': width50
  },
  slot: 'adultFreeCount'
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童底价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'childCountItem',
    label: '儿童数',
    'min-width': width40
  },
  slot: 'childCountItem'
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪底价',
    'min-width': width50
  }
}, {
  attrs: {
    prop: 'accompanyCountItem',
    label: '全陪数',
    'min-width': width40
  },
  slot: 'accompanyCountItem'
}, {
  attrs: {
    prop: 'remark',
    label: '安排备注'
  }
}, {
  attrs: {
    prop: 'otherFee',
    label: '其他成本',
    'min-width': width60
  },
  slot: 'otherFee'
}, {
  attrs: {
    prop: 'totalPay',
    label: '账面应付',
    'min-width': width60
  },
  slot: 'totalPay'
}, {
  attrs: {
    prop: 'totalSettlement',
    label: '应付',
    'min-width': width80
  },
  slot: 'totalSettlement'
}, {
  attrs: {
    prop: 'guidePay',
    label: '导游现付',
    'min-width': width60
  },
  slot: 'guidePay'
}, {
  attrs: {
    prop: 'alipayECodePay',
    label: '企业码付'
  },
  slot: 'alipayECodePay'
}, {
  attrs: {
    prop: 'totalPayed',
    label: '已付',
    'min-width': width60
  },
  slot: 'totalPayed'
}, {
  attrs: {
    prop: 'unPay',
    label: '未付',
    'min-width': width60
  },
  slot: 'unPay'
}, {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额',
    'min-width': width80
  },
  slot: 'checkAccountMoney'
}, {
  attrs: {
    prop: 'checkAccountTime',
    label: '对账时间',
    'min-width': 140
  },
  slot: 'checkAccountTime'
}, {
  attrs: {
    prop: 'checkAccountUserName',
    label: '对账人'
  }
}, {
  attrs: {
    prop: 'checkAccountRemark',
    label: '备注'
  },
  slot: 'remark'
}, {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': width80
  },
  slot: 'auditStatus'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': '120',
    fixed: 'right'
  },
  slot: 'action',
  header: 'billCheck'
}];
export const ColumnsTotal = [];