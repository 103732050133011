import "core-js/modules/es.array.push.js";
import { TableOrder } from '@/components/common';
import { planEditSelect } from '@/config/plan';
export default {
  components: {
    TableOrder
  },
  data() {
    return {
      columnData: planEditSelect,
      tableData: [],
      visible: false,
      planId: '',
      planRestaurantId: ''
    };
  },
  mounted() {
    this.$bus.on('restaurant:order', this.show);
  },
  methods: {
    show(params) {
      this.visible = true;
      let {
        planId,
        id: planRestaurantId
      } = params;
      this.fetchData(params);
      this.planId = Number(planId);
      this.planRestaurantId = Number(planRestaurantId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('plan/restaurant/fetchRestaurantOrderList', params).then(data => {
        this.tableData = data;
      });
    },
    handleConfirm() {
      const filterList = [];
      this.tableData.forEach(it => {
        if (it.isSel) {
          const {
            customerId,
            id: orderId
          } = it;
          filterList.push({
            customerId,
            orderId,
            planId: this.planId,
            resourceId: this.planRestaurantId
          });
        }
      });
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/restaurant/selectRestaurantOrderList', filterList).then(data => {
        this.hide();
        loading.close();
        this.$bus.emit('restaurant:reload', data);
      }).catch(() => {
        loading.close();
      });
    }
  }
};