var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "钉钉额度申请(追加)",
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      prop: "",
      label: "预付款类型"
    }
  }, [_vm._v(" 导游额度追加 ")]), _c("FormItemBase", {
    attrs: {
      prop: "applyUserId",
      label: "申请人"
    }
  }, [_vm._v(" " + _vm._s(_vm.getRealName) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "",
      label: "申请单位"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")]), _c("FormItemBase", {
    attrs: {
      prop: "details",
      label: "付款明细"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea"
    },
    on: {
      change: function ($event) {
        _vm.content = $event;
      }
    },
    model: {
      value: _vm.form.details,
      callback: function ($$v) {
        _vm.$set(_vm.form, "details", $$v);
      },
      expression: "form.details"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      prop: "totalGuidePay",
      label: "申请总金额"
    }
  }, [_c("InputBase", {
    model: {
      value: _vm.form.totalGuidePay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "totalGuidePay", $$v);
      },
      expression: "form.totalGuidePay"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "额度使用人"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.bankName) + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };