import { GuideBorrowAudit } from '@/components/common/Order';
import { auditEditGuideBorrow as columnData } from '@/config/audit';
import { auditMixin } from '@/mixin';
export default {
  components: {
    GuideBorrowAudit
  },
  mixins: [auditMixin],
  data() {
    return {
      columnData,
      auditAll: false
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    }
    // handleUpdate() {
    //   return this.$refs.itemRef.handleUpdate()
    // },

    // updateAuditOrder() {
    //   return this.$refs.itemRef.updateAuditOrder()
    // },
  }
};