import { auditMixin } from '@/mixin';
import { AuditSelect } from '@/config/audit';
import { getSelectLabel } from '@/config/select';
export default {
  name: 'AuditAdmin',
  mixins: [auditMixin],
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      list: AuditSelect
    };
  },
  computed: {
    auditProgress: {
      get() {
        return getSelectLabel('examineSearch', this.row.ap);
      },
      set() {}
    }
  },
  methods: {
    changeRole(val) {
      this.row.ap = val;
      this.$emit('on-select', val);
    }
  }
};