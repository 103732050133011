import { orderTrain, orderPlane } from '@/config/order';
import { APP } from '@/config';
export default {
  data() {
    return {
      visible: false,
      columnData: [],
      tableData: [],
      tableLoading: false,
      type: 1,
      to: '',
      // 选择时间后，返回到哪里 table: 是从表格点击； dialog: 是从dialog点击
      plane: {
        radio: '',
        index: 0
      },
      train: {
        radio: '',
        index: 0
      },
      planeRadio: 0
    };
  },
  mounted() {
    this.$bus.on('show:station', this.show);
  },
  methods: {
    show({
      type,
      data,
      to
    }) {
      this.visible = true;
      this.to = to;
      this.initType(type);
      this.initRadio(type, data);
      this.tableData = this.formatData(data);
    },
    hide() {
      this.visible = false;
    },
    initType(type) {
      this.type = type;
      this.columnData = type === 1 ? orderTrain : orderPlane;
    },
    changeRadio(index, val) {
      // 重置状态
      // this.resetTable()
      this.train.radio = val;
      this.train.index = index;
    },
    changePlaneRadio(index, val) {
      // 重置状态
      // this.resetTable()
      const radio = this.tableData.filter(it => it.idx === val)[0].dpName;
      this.plane.radio = radio;
      this.plane.index = index;
    },
    selectStation() {
      let startPosition = '';
      let endPosition = '';
      if (this.type == 1) {
        startPosition = this.tableData[0].station;
        endPosition = this.tableData[this.tableData.length - 1].station;
      } else {
        startPosition = this.tableData[this.plane.index].dpName;
        endPosition = this.tableData[this.plane.index].apName;
      }
      const station = this.type == 1 ? this.tableData[this.train.index] : this.tableData[this.plane.index];
      this.$emit('on-select', {
        station,
        startPosition,
        endPosition
      });
      this.hide();
      this.$store.dispatch('app/setDialogLoading', false);
    },
    handleRow(val) {
      if (this.type == 1) {
        this.train.radio = val.no;
        this.train.index = this.findRow('no', val.no);
      }
      if (this.type == 2) {
        this.plane.radio = val.dpName;
        this.plane.index = this.findRow('dpName', val.dpName);
      }
    },
    // resetTable() {
    //   this.tableData = this.tableData.map(it => {
    //     it.curRadio = ''
    //     return it
    //   })
    // },

    initRadio(type, data) {
      if (!data || !data.length) return [];
      const {
        defaultStation
      } = APP;
      let it;
      // 火车当前选中
      if (type == 1) {
        // TODO: 默认先取第一个
        const fIndex = data.findIndex(item => item.station.indexOf(defaultStation) != -1);
        it = fIndex == -1 ? data[0] : data[fIndex];
        this.train.radio = it.no;
        this.train.index = Number(it.no) - 1;
      }
      if (type == 2) {
        data = data.map((its, index) => {
          its.idx = index;
          return its;
        });
        it = data[0];
        this.plane.radio = it.idx;
        data[0] = it;
      }
    },
    formatData(data) {
      if (!data || !data.length) return [];
      return data.filter(it => {
        it.radio = it.dpName + ' ' + it.dtName;
        return it;
      });
    },
    findRow(key, val) {
      return this.tableData.findIndex(it => it[key] == val);
    }
  }
};