var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data.length ? _c("div", _vm._l(_vm.data, function (item) {
    return _c("p", {
      key: item.id
    }, [_c("span", [_vm._v(_vm._s(item.realName))]), _c("span", [_vm._v(_vm._s(item.mobilePhone))])]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };