import "core-js/modules/es.array.reduce.js";
// 表格小计方法
import { processAdd } from './index';
/**
 * 1. 多条合并的不累加
 * 2. 人数
 * 3. 平均值
 * 4. 其他非合计的字段，显示别的内容
 * 5. 统计勾选选金额
 * 6. 用房数
 * 7. 正常值
 */

// 金额计算
const calcValues = values => {
  if (!values.every(value => isNaN(value))) {
    return values.reduce((prev, curr) => {
      const value = Number(curr);
      if (!isNaN(value)) {
        return processAdd(prev, curr);
      } else {
        return prev;
      }
    }, 0);
  }
  return 0;
};
export const tableSummary = ({
  columns,
  data
}) => {
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '小计';
      return;
    }
    const values = data.map(item => Number(item[column.property]));
    sums[index] = calcValues(values);
  });
  return sums;
};

// 多条合并
export const tableSummaryMergeRow = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach((column, index) => {
    const values = data.map(item => {
      if (keys.includes(column.property)) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return 0;
        } else {
          last = item;
          return item[column.property];
        }
      }
    });
    if (keys.includes(column.property)) {
      sums[index] = calcValues(values);
    }
  });
  return sums;
};

// 人数
export const tableSummaryPeople = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach((column, index) => {
    const values = data.map(item => {
      if (keys.includes(column.property)) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return [item[column.property], 0, 0].join('/');
        } else {
          last = item;
          const {
            adultCount,
            childCount,
            accompanyCount
          } = item;
          return adultCount + '/' + childCount + '/' + (accompanyCount || 0);
        }
      }
    });
    if (values.every(value => value && value.indexOf('/') > -1)) {
      const res = values.reduce((prev, cur) => {
        const [val0, val1, val2] = cur.split('/');
        prev[0] = processAdd(prev[0], val0);
        prev[1] = processAdd(prev[1], val1);
        prev[2] = processAdd(prev[2], val2);
        return prev;
      }, [0, 0, 0]);
      if (keys.includes(column.property)) {
        sums[index] = `${res[0]}大${res[1]}小${res[2]}陪`;
      }
    }
  });
  return sums;
};

// 对账金额
export const tableSummaryCheckedMoney = ({
  columns,
  data
}, sums, keys) => {
  let last;
  columns.forEach(column => {
    const values = data.map(item => {
      if (column.property == keys[1]) {
        if (last && item.rowId && last.rowId == item.rowId) {
          return 0;
        } else {
          last = item;
          return item[keys[2]] ? item[column.property] : 0;
        }
      }
    });
    if ([keys[1]].includes(column.property)) {
      const idx = columns.findIndex(it => it.property == keys[0]);
      sums[idx] = `已选金额：${calcValues(values)}`;
    }
  });
  return sums;
};
const PeopleKeys = ['number', 'peopleCount'];
//
/**
 * 小计最终方法
 * @mergeKeys [] 合并不需要累加的字段
 * @peopleKeys [] 人数小计字段
 * @checkKey [] 勾选计算金额
 * @returns []
 */
export const tableSummaryFinally = ({
  param,
  mergeKeys = [],
  peopleKeys = PeopleKeys,
  checkKeys = [],
  // 第一个 显示内容列的字段、第二个：勾选的金额、第三个：勾选状态的值
  avgFn
}) => {
  let sums = tableSummary(param);
  if (mergeKeys.length) {
    sums = tableSummaryMergeRow(param, sums, mergeKeys);
  }
  if (peopleKeys.length) {
    sums = tableSummaryPeople(param, sums, peopleKeys);
  }
  if (checkKeys.length) {
    sums = tableSummaryCheckedMoney(param, sums, checkKeys);
  }
  return avgFn ? avgFn(sums) : sums;
};