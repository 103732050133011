import { inputMixin } from '@/mixin';
export default {
  name: 'InputNum',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set(val) {
        val = val.trim();
        this.$emit('update:value', val);
      }
    }
  }
};