import { getBoatRiverData } from '@/utils/cookie';
export default {
  data() {
    return {
      loginStatus: false
    };
  },
  computed: {
    boatToken() {
      const boatData = getBoatRiverData();
      return this.$store.getters['plan/boat/boatToken'] || boatData && boatData.token;
    },
    getLoginStatus() {
      return !this.loginStatus;
    }
  },
  mounted() {
    if (!this.boatToken) {
      this.fetchToken();
    }
    this.loginStatus = this.boatToken;
  },
  methods: {
    // 数字漓江系统token
    fetchToken() {
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/boat/riverToken').then(data => {
        this.loginStatus = data;
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};