import { orderOtherIncome } from '@/config/order';
import { getSelectLabel } from '@/config/select';
import { getSummariesMixin, reloadMixin } from '@/mixin';
export default {
  mixins: [getSummariesMixin, reloadMixin],
  data() {
    return {
      columnData: orderOtherIncome.slice(0, -1),
      tableData: [],
      statisticsKeys: ['totalPay']
    };
  },
  computed: {
    getType() {
      return (type, val) => {
        return getSelectLabel(type, val);
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchOtherIncome', {
        id
      }).then(data => {
        if (data.length) {
          this.tableData = data.map(it => {
            it.totalPay = it.price * it.count;
            return it;
          });
        } else {
          this.tableData = [];
        }
      });
    }
  }
};