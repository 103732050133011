import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { auditPropMixin, rowspanMixin } from '@/mixin';
export default {
  mixins: [auditPropMixin, rowspanMixin],
  data() {
    return {
      tableData: [],
      deletePath: 'plan/shopping/delete',
      headMergeNum: 5,
      tailMergeNum: 20,
      // 这里获取不到确切长度，手动设定
      hasMergeTail: true,
      rowId: 1000000000000,
      totalMoney: 0,
      // 总金额
      statisticsKeys: ['money', 'enterStoreMemberCount', 'travelGrossProfit']
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      planGuide: 'plan/index/planGuide',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getPlanInfo() {
      return this.planInfo[this.currentPlanId];
    },
    getPlanNumber() {
      return this.getPlanInfo ? this.getPlanInfo.planNumber : '';
    },
    getData() {
      return this.tableData;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.resetData();
    },
    fetchCustomerList() {
      const planId = this.currentPlanId;
      this.$store.dispatch('plan/index/findCustomerList', planId).then(list => {
        this.customerTripNumberList = this.formatTripNumber(list);
      });
    },
    fetchData() {
      const id = this.currentPlanId;
      this.$store.dispatch('plan/shopping/fetch', {
        planId: id
      }).then(data => {
        this.initData(data);
      });
    },
    resetData() {
      this.rowspan();
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.shopGoods;
        const {
          id,
          auditProgress
        } = it;
        let goodsList = it.contractPrices || [];
        // goodsList = this.formatOptions(goodsList)

        let item = {
          ...it,
          rowId: id,
          shoppingPlanId: id,
          ap: auditProgress,
          auditProgress,
          goodsList
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              goodsRemark: v.remark
            });
          });
        } else {
          item.itemIndex = 0;
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    }
  }
};