import "core-js/modules/es.array.reduce.js";
import { processAdd } from '@/utils/number';
export default {
  data() {
    return {
      spanArr: [],
      position: 0,
      spanArrDate: [],
      positionDate: 0,
      headMergeNum: 1,
      tailMergeNum: 100,
      hasMergeTail: false,
      // 默认统计到字段
      statisticsKeys: ['adultCount', 'childCount', 'accompanyCount', 'totalCustomerIncome', 'totalOutTransferSubsidyIncome', 'totalOtherIncome', 'totalScenicExtraIncome', 'totalCustomerSubsidyPay', 'totalBusCompanyPay', 'totalHotelQuotePay', 'totalGuideServiceFeePay', 'totalRestaurantPay', 'totalInsurancePay', 'totalOutTransferPay', 'totalShuttlePay', 'totalTransportCompanyPay', 'totalOtherPay', 'totalShopRebatePay', 'totalScenicExtraRebatePay', 'totalIncome', 'totalIncomed', 'totalQuotePay', 'totalPayed', 'totalGrossProfit', 'totalGrossMargin']
    };
  },
  methods: {
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        let lastId;
        const values = data.map(item => {
          // 只合计设定的字段
          if (this.statisticsKeys.includes(column.property)) {
            // 如果是合并的行，不重复累计
            if (lastId == item.rowId && (index < this.headMergeNum || index >= this.tailMergeNum)) {
              return 0;
            }
            lastId = item.rowId;
            return item[column.property];
          }
        });
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return processAdd(prev, curr);
              // return (Number(prev) * 100 + Number(curr) * 100) / 100
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    }
  }
};