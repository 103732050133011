var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "复制下方链接，发送给导游进行开通",
      width: "600px",
      visible: _vm.visible,
      "before-close": _vm.beforeClose,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "flex flex-center"
  }, [_vm._v(" " + _vm._s(_vm.inviteLink) + " "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.onCopy($event);
      }
    }
  }, [_vm._v("复制")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };