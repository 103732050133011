import "core-js/modules/es.array.push.js";
import ScrollPane from './ScrollPane';
import { generateTitle } from '@/utils/i18n';
import path from 'path';
import variables from '@/styles/variables.scss';
import { tagViewMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    ScrollPane
  },
  mixins: [tagViewMixin],
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: []
    };
  },
  computed: {
    ...mapGetters({
      routes: 'permission/routes',
      theme: 'settings/theme'
    }),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    getStyle() {
      return {
        'background-color': this.theme,
        'border-color': this.theme
      };
    },
    isCollapse() {
      return this.$store.state.app.sidebar.opened;
    }
  },
  watch: {
    $route() {
      this.addTags();
      this.moveToCurrentTag();
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    generateTitle,
    // generateTitle by vue-i18n

    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = [];
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: {
              ...route.meta
            }
          });
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags];
          }
        }
      });
      return tags;
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes);
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag);
        }
      }
    },
    addTags() {
      const {
        name
      } = this.$route;
      const ignoreTag = 'planEdit';
      const ignoreAuditTag = 'auditEdit';
      const validateFn = it => {
        return it.indexOf(ignoreTag) > -1 || it.indexOf(ignoreAuditTag) > -1;
      };
      const hasTagCache = this.cachedViews.some(it => {
        return validateFn(it);
      });

      // 发团预定安排切换类型，只保留当前一个tag， 如果切换到非发团预定安排页面，则不删除
      if (hasTagCache && validateFn(name)) {
        const view = this.visitedViews.find(tag => {
          return validateFn(tag.name);
        });
        this.$store.dispatch('tagsView/delView', view);
      }
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route);
      }
      return false;
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag;
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag);
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route);
            }
            break;
          }
        }
      });
    },
    refreshSelectedTag(view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const {
          fullPath
        } = view;
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath
          });
        });
      });
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag);
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag();
      });
    },
    closeAllTags(view) {
      this.$store.dispatch('tagsView/delAllViews').then(({
        visitedViews
      }) => {
        if (this.affixTags.some(tag => tag.path === view.path)) {
          return;
        }
        this.toLastView(visitedViews, view);
      });
    },
    openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY - parseInt(variables.topNavHeight);
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    }
  }
};