import { GuideProxy } from '@/components/common/Order';
import { planEditGuideProxy } from '@/config/plan';
export default {
  components: {
    GuideProxy
  },
  data() {
    return {
      columnData: planEditGuideProxy
    };
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};