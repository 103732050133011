import { Columns as columns } from './tableConfig';
import { KPIType2Label } from './config';
import { calcTotal } from './helper';
import { mergeTableRow } from '@/utils';
export default {
  mixins: [],
  data() {
    return {
      columns,
      tableData: [],
      search: {
        year: new Date(),
        depLevel: 3
      }
    };
  },
  computed: {
    dpData() {
      return this.$store.getters['analyse/target/departmentData'];
    }
  },
  watch: {
    dpData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.tableData = mergeTableRow(this.formatData(data.list), ['name', 'year']);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      const year = new Date(this.search.year).getFullYear();
      this.$store.dispatch('analyse/target/departmentList', {
        ...this.search,
        ...params,
        year
      });
    },
    formatData(list) {
      return list.map(it => {
        it.total = calcTotal(it);
        it.type = KPIType2Label[it.kpitype];
        return it;
      });
    }
  }
};