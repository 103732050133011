import { inputMixin } from '@/mixin';
// import { formartInputData } from '@/utils/data'
import { mapGetters } from 'vuex';

/**
 * @module components/common/Input
 * @desc 账户列表
 */

export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    initVal: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: '账户'
    }
  },
  data() {
    return {
      curVal: '',
      list: []
    };
  },
  computed: {
    ...mapGetters('resource/account', ['accountList'])
  },
  watch: {
    accountList: {
      handler: function (data) {
        if (data) {
          this.list = this.formartInputData(data.list);
          this.list.forEach(it => {
            if (it.id == this.initVal) {
              this.curVal = it.value + '';
            }
          });
        } else {
          this.$store.dispatch('resource/account/fetchAccountList', {
            pageSize: 100,
            pageNum: 1
          }).then(res => {
            this.list = this.formartInputData(res.list);
            this.list.forEach(it => {
              if (it.id == this.initVal) {
                this.curVal = it.value + '';
              }
            });
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    formartInputData(list, key = 'name') {
      if (!list || !list.length) return;
      return list.map(it => {
        const item = it;
        item.value = it[key] + '-' + it.cardNo;
        item.slug = it.slug;
        return item;
      });
    }
  }
};