import { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 查询支付宝企业码消费数据-导出
export function exportAlipayConsumeLRequest(params) {
  const url = APP.baseUrl + '/order/guideBorrow/alipay-consume-l-export';
  return requestBlob({
    url,
    params
  });
}