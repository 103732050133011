import request from '@/utils/request';
import { parseQuerys } from '@/utils';

// 列表 不分页
export const noticeListAllRequest = params => {
  return request({
    url: '/system/sysNoticeUserRecord/list-notify',
    method: 'get',
    params
  });
};
// 列表
export const noticeListRequest = params => {
  return request({
    url: '/system/sysNoticeUserRecord/page-notify',
    method: 'get',
    params
  });
};
// 设置已读状态
export const noticeStatusSetRequest = params => {
  const url = parseQuerys('/system/sysNoticeUserRecord/read-status', params);
  return request({
    url,
    method: 'post',
    data: {}
  });
};