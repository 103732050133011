import { PersonCount } from '@/components/common';
import { rowItem } from '@/config/billCheck';
import { mapGetters } from 'vuex';
import TableBase2 from '@/components/common/Table/TableBase2';
export default {
  components: {
    PersonCount,
    TableBase2
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    // status: {
    //   type: [Boolean, Number],
    //   default: false,
    // },

    height: {
      type: Number,
      default: 400
    },
    urlType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    timer: null,
    columnData: rowItem,
    tableData: [],
    isShow: false,
    width: 500
  }),
  computed: {
    ...mapGetters({
      scenicOrder: 'bill/billCheck/scenicOrder',
      transportOrder: 'bill/billCheck/transportOrder',
      restaurantOrder: 'bill/billCheck/restaurantOrder',
      busCompanyOrder: 'bill/billCheck/busCompanyOrder'
    }),
    isHotel() {
      return typeof this.status == 'number' && this.status == 2;
    }
  },
  methods: {
    onMouseEnter() {
      const {
        id
      } = this.data.row;
      this.tableData = [];
      this.isShow = true;
      // 判断缓存是否有这个对象
      const status = this[this.urlType][id] !== undefined;
      if (status) {
        this.tableData = this[this.urlType][id];
      } else {
        this.$store.dispatch('bill/billCheck/fetchOrder', {
          id,
          type: this.urlType
        }).then(res => {
          this.tableData = res;
          this.getWidth();
        });
      }
    },
    getWidth() {
      if (this.tableData.length) {
        this.width = 700;
      } else {
        this.width = 300;
      }
    },
    onMouseOver() {
      this.timer = setTimeout(() => {
        this.isShow = false;
      }, 500);
    }
  }
};