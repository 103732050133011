// import { mapGetters } from 'vuex'
import { SelectDictionary, BtnExport, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import { areaAnalyze } from '@/config/analysis';
import { useEcharts } from '@/utils/echarts';
import analysisMixin from '../analysisMixin';
export default {
  components: {
    SelectDictionary,
    TableList,
    BtnExport,
    InputMonthRange
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: areaAnalyze
    };
  },
  computed: {
    areaData() {
      return this.$store.getters['analyse/index/areaData'];
    }
  },
  watch: {
    areaData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = data;
      this.$nextTick(() => {
        this.formaChart(data);
      });
    },
    changeArea(val) {
      this.searchParams.customerArea = val.label;
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchCustomeArea', queryParams);
    },
    formaChart(list) {
      const peopleList = list.map(it => {
        const item = {
          value: it.count,
          name: it.area
        };
        return item;
      });
      const profitList = list.map(it => {
        const item = {
          value: it.profit,
          name: it.area
        };
        return item;
      });
      this.drawLine(peopleList, profitList);
    },
    drawLine(peopleList, profitList) {
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      const opts = {
        title: [{
          left: 'center',
          top: '10%',
          text: '区域人数占比(图一)         区域毛利占比(图二)'
        }],
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: '区域人数占比',
          type: 'pie',
          center: ['25%', '50%'],
          radius: '50%',
          label: {
            formatter: '{b}: ({d}%)'
          },
          data: peopleList
        }, {
          name: '区域毛利占比',
          type: 'pie',
          center: ['75%', '50%'],
          radius: '50%',
          data: profitList,
          label: {
            formatter: '{b}: ({d}%)'
          }
        }]
      };
      useEcharts('myChart', opts);
    }
  }
};