import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
export const MAP_KEYS = {
  fee: 'fee',
  subsidy: 'subsidy'
};
export function generateTableItem(start = 2021) {
  const end = new Date().getFullYear();
  const yearList = [];
  for (let i = start; i <= end; i++) {
    yearList.push(i);
  }
  return yearList.map(v => {
    return {
      attrs: {
        prop: v + '',
        label: `${v}年`
      },
      children: [{
        attrs: {
          prop: v + MAP_KEYS.fee,
          label: '应收团款'
        }
      }, {
        attrs: {
          prop: v + MAP_KEYS.subsidy,
          label: '应付返款'
        }
      }]
    };
  });
}
export const generageStatisticsKeys = () => {
  const start = 2021;
  const end = new Date().getFullYear();
  return Array.from({
    length: end - start + 1
  }).reduce((acc, _, idx) => {
    const val = start + idx;
    const fee = val + MAP_KEYS.fee;
    const subsidy = val + MAP_KEYS.subsidy;
    acc = [...acc, fee, subsidy];
    return acc;
  }, []);
};
export const formatRowProperty = data => {
  return data.reduce((acc, cur) => {
    const {
      fee,
      subsidy,
      yearOrMonth
    } = cur;
    const str = yearOrMonth.split('-')[0];
    acc[str + MAP_KEYS.fee] = fee;
    acc[str + MAP_KEYS.subsidy] = subsidy;
    return acc;
  }, {});
};