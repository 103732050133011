var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "boardTeamRef",
    staticClass: "print-page"
  }, [_c("TopTitle", {
    attrs: {
      title: "散客船票名单表"
    }
  }), _c("div", {
    staticClass: "flex"
  }, [_c("h2", [_vm._v("成人： " + _vm._s(_vm.totalObj.adult))]), _c("h2", {
    staticClass: "red ml20"
  }, [_vm._v("半票： " + _vm._s(_vm.totalObj.half))]), _c("h2", {
    staticClass: "blue ml20"
  }, [_vm._v("免票： " + _vm._s(_vm.totalObj.fee))])]), _c("div", {
    staticClass: "content"
  }, [_c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(24, function (item) {
    return _c("col", {
      key: item,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", _vm._l(_vm.tableHead, function (item, index) {
    return _c("th", {
      key: index,
      attrs: {
        colspan: item.span
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.data, function (item, index) {
    return _c("tr", {
      key: index,
      class: [item.isKid ? "is-child" : "", {
        "is-half": item.isHalf
      }]
    }, [_vm._l(_vm.tableHead, function (col, cIdx) {
      return [item.hasPass ? _c("th", {
        key: cIdx,
        staticClass: "tac",
        attrs: {
          rowspan: cIdx < 3 ? item.rowspan : 1,
          colspan: col.span
        }
      }, [col.slot == "cardType" ? [_vm._v(" " + _vm._s(_vm.getCardType(item.cardType)) + " ")] : _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")])], 2) : _vm._e(), !item.hasPass && cIdx > 2 ? _c("th", {
        key: cIdx,
        attrs: {
          colspan: col.span
        }
      }, [col.slot == "cardType" ? [_vm._v(" " + _vm._s(_vm.getCardType(item.cardType)) + " ")] : _vm._e(), !col.slot ? _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")]) : _vm._e()], 2) : _vm._e()];
    })], 2);
  })], 2)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };