/* eslint-disable no-unused-vars */
import { fetchGuide, fetchIncome, fetchCustomeArea, fetchShop, fetchSpecialtShop, fetchSpecialtSale, fetchCustom, fetchProduct, fetchCustomerKpi, fetchTotalIncome, fetchMemberAge, fetchMemberMap, fetchGroup } from '@/api/analysis';
const dfaultState = () => ({
  lineData: null,
  areaData: null,
  saleData: null,
  shopData: null,
  groupData: null,
  guideData: null,
  customData: null,
  incomeData: null,
  soilShopData: null,
  memberAgeData: null,
  customerKpiData: null,
  incomeDetailData: null
});
const state = dfaultState();
const getters = {
  lineData: state => state.lineData,
  areaData: state => state.areaData,
  saleData: state => state.saleData,
  shopData: state => state.shopData,
  groupData: state => state.groupData,
  guideData: state => state.guideData,
  customData: state => state.customData,
  incomeData: state => state.incomeData,
  soilShopData: state => state.soilShopData,
  memberAgeData: state => state.memberAgeData,
  customerKpiData: state => state.customerKpiData,
  incomeDetailData: state => state.incomeDetailData
};
const actions = {
  // 获取导游分析
  fetchGuide({
    commit
  }, params) {
    return fetchGuide(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_GUIDE_DATA', data);
      return data;
    });
  },
  // 应收款明细表
  fetchIncome({
    commit
  }, params) {
    return fetchIncome(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_INCOME_DETAIL_DATA', data);
      return data;
    });
  },
  // 区域分析表
  fetchCustomeArea({
    commit
  }, params) {
    return fetchCustomeArea(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_CUSTOME_DATA', data);
      return data;
    });
  },
  // 购物店分析表
  fetchShop({
    commit
  }, params) {
    return fetchShop(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_SHOP_DATA', data);
      return data;
    });
  },
  //土店分析表
  fetchSpecialtShop({
    commit
  }, params) {
    return fetchSpecialtShop(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_SOILSHOP_DATA', data);
      return data;
    });
  },
  //业务员分析表
  fetchSpecialtSale({
    commit
  }, params) {
    return fetchSpecialtSale(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_SALE_DATA', data);
      return data;
    });
  },
  //客户分析表
  fetchCustom({
    commit
  }, params) {
    return fetchCustom(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_CUSTOM_DATA', data);
      return data;
    });
  },
  //线路产品分析表
  fetchProduct({
    commit
  }, params) {
    return fetchProduct(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LINE_DATA', data);
      return data;
    });
  },
  //团型分析表
  fetchGroup({
    commit
  }, params) {
    return fetchGroup(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  // 营业额/人数/毛利总表
  fetchCustomerKpi({
    commit
  }, params) {
    return fetchCustomerKpi(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_KPI_DATA', data);
      return data;
    });
  },
  // 应收汇总表
  fetchTotalIncome({
    commit
  }, params) {
    return fetchTotalIncome(params).then(res => {
      let {
        data
      } = res;
      data = data.sort((a, b) => {
        return b.income - a.income;
      });
      commit('FETCH_INCOME_DATA', data);
      return data;
    });
  },
  // 游客年龄分析表
  fetchMemberAge({
    commit
  }, params) {
    return fetchMemberAge(params).then(res => {
      const {
        data
      } = res;
      commit('FETCH_MEMBER_AGE_DATA', data);
      return data;
    });
  },
  // 游客分布数据表
  fetchMemberMap({
    commit
  }, params) {
    return fetchMemberMap(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  FETCH_KPI_DATA: (state, data) => {
    state.customerKpiData = data;
  },
  FETCH_CUSTOME_DATA: (state, data) => {
    state.areaData = data;
  },
  FETCH_INCOME_DATA: (state, data) => {
    state.incomeData = data;
  },
  FETCH_INCOME_DETAIL_DATA: (state, data) => {
    state.incomeDetailData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.groupData = data;
  },
  FETCH_LINE_DATA: (state, data) => {
    state.lineData = data;
  },
  FETCH_CUSTOM_DATA: (state, data) => {
    state.customData = data;
  },
  FETCH_GUIDE_DATA: (state, data) => {
    state.guideData = data;
  },
  FETCH_MEMBER_AGE_DATA: (state, data) => {
    state.memberAgeData = data;
  },
  FETCH_SALE_DATA: (state, data) => {
    state.saleData = data;
  },
  FETCH_SHOP_DATA: (state, data) => {
    state.shopData = data;
  },
  FETCH_SOILSHOP_DATA: (state, data) => {
    state.soilShopData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};