/**
 * 导出word文档（带图片）
 *
 */
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import PizZipUtils from 'pizzip/utils/index.js';
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

// 下载价格表
export function downloadPolicy({
  filePath,
  data,
  fileName
}) {
  loadFile(filePath, function (error, content) {
    console.log('content:', content);
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true
    });
    doc.render(data);
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
    // Output the document using Data-URI
    saveAs(out, fileName);
  });
}