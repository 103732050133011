import { tableMixin, permissionMixin } from '@/mixin';
import { SELECTOR } from '@/config';
import TagGroup from '../components/TagGroup.vue';
export default {
  components: {
    TagGroup
  },
  mixins: [tableMixin, permissionMixin],
  computed: {
    isGroup() {
      return row => row.groupId < 0;
    }
  },
  methods: {
    formatType(type) {
      let data = SELECTOR.ticketType.filter(item => item.value == type)[0];
      return data.label;
    },
    changeSwitch(row) {
      this.$parent.createOrUpdate(row);
    }
  }
};