var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "订房单详情",
      width: "980px",
      "has-foot": false,
      visible: _vm.visible,
      "before-close": _vm.hide
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("DetailBooking", {
    attrs: {
      form: _vm.form
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };