var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableSingle", {
    attrs: {
      data: _vm.data,
      "column-data": _vm.columnData,
      title: "报账信息"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };