import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { uploadUrl } from '@/config';
import { getToken } from '@/utils/cookie';
export default {
  name: 'UploadBase',
  props: {
    uploadUrl: {
      type: String,
      default: uploadUrl
    },
    acceptType: {
      type: String,
      default: 'image/png, image/jpeg'
    },
    defaultImg: {
      type: String,
      default: ''
    },
    headers: {
      type: Object,
      default: () => ({
        Authorization: 'bearer ' + getToken()
      })
    }
  },
  data() {
    return {
      imageUrl: ''
    };
  },
  watch: {
    defaultImg: {
      handler: function (val) {
        if (!val) return;
        this.imageUrl = val;
      },
      immediate: true
    }
  },
  methods: {
    handleDelete() {
      this.$bus.open(() => {
        this.$emit('on-delete', this.imageUrl);
        this.imageUrl = '';
      });
    },
    handleSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$emit('on-success', res, file);
      this.$emit('success', {
        res,
        file
      });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小最大不超过 2MB!');
      }
      return isLt2M;
    },
    clearImage() {
      this.imageUrl = '';
    }
  }
};