/**
 * @module components/common/Inputs
 * @desc 用房 input 组件
 * @vue-data {Boolean} checked - 输入框下拉数据列表
 * @vue-event {String} handleSelect - Emit 输入框选中后，触发 pick事件，返回选中的item对象
 */
export default {
  data: () => ({
    form: {
      checked: true,
      level: 0,
      double: 0,
      single: 0,
      three: 0,
      driver: 0,
      add: 0
    }
  }),
  created() {
    this.hotels = this.loadAll();
  },
  methods: {
    loadAll() {
      return [{
        value: '三全鲜食（北新泾店）',
        address: '长宁区新渔路144号'
      }, {
        value: 'Hot honey 首尔炸鸡（仙霞路）',
        address: '上海市长宁区淞虹路661号'
      }];
    },
    handleChange(type, val) {
      this.form[type] = val;
    },
    toggleChecbox(val) {
      this.form.checked = val;
    }
  }
};