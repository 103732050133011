var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "外转订单详情",
      width: "90%",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_vm.data ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "field-row"
  }, [_c("span", [_vm._v("外转")]), _c("LabelBase", [_vm._v(_vm._s(_vm.data.customerName))])], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("span", [_vm._v("联系人")]), _c("LabelBase", [_vm._v(_vm._s(_vm.data.contactName))])], 1), _c("div", {
    staticClass: "field-row"
  }, [_c("span", [_vm._v("行程日期")]), _c("LabelBase", [_vm._v(_vm._s(_vm.data.date))])], 1)]), _c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    on: {
      "tab-click": _vm.handleTab
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "外转费用",
      name: "fee"
    }
  }, [_c("ReceptionFeeTable", {
    ref: "fee",
    attrs: {
      "table-data": _vm.data.feeList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "外转代收",
      name: "proxy"
    }
  }, [_c("ReceptionProxyTable", {
    ref: "proxy",
    attrs: {
      "table-data": _vm.data.collectList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "外转返款",
      name: "refund"
    }
  }, [_c("ReceptionRefundTable", {
    ref: "refund",
    attrs: {
      "table-data": _vm.data.subsidyList,
      "is-audit": _vm.isAudit
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "备注",
      name: "remark"
    }
  }, [_c("ReceptionRemarkTable", {
    attrs: {
      "table-data": _vm.data.remarkList,
      "is-audit": _vm.isAudit
    }
  })], 1)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };