var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_vm._m(0), _c("div", {
    staticClass: "item"
  }, [_c("FormBase", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      inline: "",
      "label-width": "80px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "邀请链接",
      prop: "value"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-s-open"
    },
    on: {
      click: function ($event) {
        return _vm.onCopy($event);
      }
    },
    slot: "append"
  })], 1)], 1), _c("FormItemBase", {
    attrs: {
      label: "",
      prop: ""
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSave
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-field"
  }, [_c("h2", [_vm._v("企业码")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };