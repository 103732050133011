import "core-js/modules/es.array.push.js";
import { ColumnsAgreementItem as Columns } from '../configTable';
import { agreementState } from '../config';
// import { findComponentsUpward } from '@/utils'

export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      Columns,
      tableData: [],
      hasMergeTail: true,
      minCount: 0
    };
  },
  computed: {
    agreementData() {
      return this.$store.getters['resource/common/curAgreementDetail'];
    }
  },
  watch: {
    agreementData: {
      handler(d) {
        this.tableData = this.formatData(d.contractPrices);
      },
      immediate: true
    }
  },
  methods: {
    addRow() {
      this.tableData.push({
        ...agreementState()
      });
    },
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于 0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.deleteReduction(id) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
    },
    reload() {
      this.$emit('reload');
    },
    // 删除协议减免
    deleteReduction(id) {
      this.$store.dispatch('resource/common/delPriceFeeAgreement', {
        id
      }).then(() => {
        this.reload();
        this.$bus.tip();
      });
    },
    // 添加价格
    addItem(opts) {
      return this.$store.dispatch('resource/common/addAgreementItem', opts);
    },
    // 删除价格
    deleteItem(id) {
      this.$store.dispatch('resource/common/delAgreementItem', id).then(() => {
        this.reload();
        this.$bus.tip();
      });
    },
    handleDelete(item) {
      const {
        rowId: id,
        isCustom
      } = item;
      if (isCustom) {
        const findIndex = this.tableData.findIndex(it => it == item);
        this.tableData.splice(findIndex, 1);
      } else {
        this.$bus.open(() => {
          this.deleteItem(id);
        });
      }
    },
    formatData(list) {
      const result = [];
      if (!list || !list.length) return result;
      list.forEach((it, idx) => {
        const itemList = it.contractPriceFrees || [];
        const item = {
          rowId: it.id,
          ...it
        };
        delete item.contractPriceFrees;
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            itemIndex: idx
          });
        }
      });
      return result;
    }

    // handleConfirm(opts) {
    //   const list = this.beforeCommit(this.tableData)

    //   opts.contractPrices = list
    //   const findComps = findComponentsUpward(this, 'DialogAgreementEdit')[0]
    //   findComps.createOrUpdateAgr(opts)
    // },

    // beforeCommit(list) {
    //   const result = []
    //   if (!list || !list.length) return result

    //   const { contracId, resourceType } = this.form
    //   list.forEach(it => {
    //     it.id = it.rowId
    //     it.contracId = contracId
    //     it.resourceType = resourceType
    //     result.push(it)
    //   })
    //   return result
    // },

    // validateForm() {
    //   for (let i = 0; i < this.tableData.length; i++) {
    //     const item = this.tableData[i]
    //     const { contentType, price, subsidy } = item
    //     if (!contentType) {
    //       this.$bus.tip({ type: 'warning', message: '请填写项目内容' })
    //       return false
    //     }
    //     if (!price === '') {
    //       this.$bus.tip({ type: 'warning', message: '请填写价格' })
    //       return false
    //     }
    //     if (!subsidy) {
    //       this.$bus.tip({ type: 'warning', message: '请填写返款金额' })
    //       return false
    //     }
    //     return true
    //   }
    // },
  }
};