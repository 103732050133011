import "core-js/modules/es.array.push.js";
const dfaultState = () => ({
  visitedViews: [],
  cachedViews: []
});
const state = dfaultState();
const getters = {
  visitedViews: state => state.visitedViews,
  cachedViews: state => state.cachedViews
};
const actions = {
  addView({
    dispatch
  }, view) {
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  replaceView({
    dispatch
  }, view) {
    dispatch('replaceVisitedView', view);
    // dispatch('replaceCachedView', view)
  },
  addVisitedView({
    commit
  }, view) {
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView({
    commit
  }, view) {
    commit('ADD_CACHED_VIEW', view);
  },
  replaceVisitedView({
    commit
  }, view) {
    commit('REPLACE_VISITED_VIEW', view);
  },
  replaceCachedView({
    commit
  }, view) {
    commit('REPLACE_CACHED_VIEW', view);
  },
  appendVisiteViewPage({
    commit
  }, data) {
    commit('APPEND_VISITED_VIEW_PAGE', data);
  },
  appendVisiteViewSearch({
    commit
  }, data) {
    commit('APPEND_VISITED_VIEW_SEARCH', data);
  },
  appendVisiteTabSearch({
    commit
  }, data) {
    commit('APPEND_VISITED_TAB_SEARCH', data);
  },
  delView({
    dispatch,
    state
  }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delVisitedView({
    commit,
    state
  }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view);
      resolve([...state.visitedViews]);
    });
  },
  delCachedView({
    commit,
    state
  }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view);
      resolve([...state.cachedViews]);
    });
  },
  delOthersViews({
    dispatch,
    state
  }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delOthersVisitedViews({
    commit,
    state
  }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve([...state.visitedViews]);
    });
  },
  delOthersCachedViews({
    commit,
    state
  }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve([...state.cachedViews]);
    });
  },
  delAllViews({
    dispatch,
    state
  }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      });
    });
  },
  delAllVisitedViews({
    commit,
    state
  }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve([...state.visitedViews]);
    });
  },
  delAllCachedViews({
    commit,
    state
  }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve([...state.cachedViews]);
    });
  },
  updateVisitedView({
    commit
  }, view) {
    commit('UPDATE_VISITED_VIEW', view);
  }
};
const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return;
    state.visitedViews.push(Object.assign({}, view, {
      title: view.meta.title || 'no-name'
    }));
  },
  APPEND_VISITED_VIEW_PAGE: (state, data) => {
    const {
      path,
      page,
      size
    } = data;
    // const currentTag = state.visitedViews.find(v => v.path === path)
    // if (!currentTag.search) {
    //   currentTag.search = {}
    // }
    // currentTag.search.pageNum = page
    // currentTag.page = page
    state.visitedViews = state.visitedViews.map(it => {
      if (it.path === path) {
        if (!it.search) {
          it.search = {};
        }
        it.search.pageNum = page;
        it.search.pageSize = size;
        it.page = page;
        it.size = size;
      }
      return it;
    });
  },
  APPEND_VISITED_VIEW_SEARCH: (state, data) => {
    const {
      path,
      search,
      extra
    } = data;
    const currentTag = state.visitedViews.find(v => v.path === path);
    if (!currentTag) return;
    currentTag.search = search;
    currentTag.extra = extra;
  },
  APPEND_VISITED_TAB_SEARCH: (state, data) => {
    const {
      path,
      search,
      extra,
      name
    } = data;
    const currentTag = state.visitedViews.find(v => v.path === path);
    if (!currentTag) return;
    if (!currentTag.search) {
      currentTag.search = {};
    }
    if (!currentTag.extra) {
      currentTag.extra = {};
    }
    delete search[name];
    currentTag.extra[name] = extra;
    currentTag.search[name] = search;
  },
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  REPLACE_VISITED_VIEW: (state, view) => {
    state.visitedViews = state.visitedViews.filter(it => it.name.indexOf('planEdit') === -1);
    state.visitedViews.unshift(view);
  },
  REPLACE_CACHED_VIEW: (state, view) => {
    const fIndex = state.cachedViews.findIndex(it => it.indexOf(view.title) > -1);
    fIndex > 0 && state.cachedViews.splice(fIndex, 1, view.name);
  },
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
  },
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name);
    index > -1 && state.cachedViews.splice(index, 1);
  },
  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },
  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix);
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};