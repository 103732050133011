import { render, staticRenderFns } from "./DialogAgreement.vue?vue&type=template&id=edfd5fc2"
import script from "./DialogAgreement.vue?vue&type=script&lang=js"
export * from "./DialogAgreement.vue?vue&type=script&lang=js"
import style0 from "./DialogAgreement.vue?vue&type=style&index=0&id=edfd5fc2&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('edfd5fc2')) {
      api.createRecord('edfd5fc2', component.options)
    } else {
      api.reload('edfd5fc2', component.options)
    }
    module.hot.accept("./DialogAgreement.vue?vue&type=template&id=edfd5fc2", function () {
      api.rerender('edfd5fc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/Dialog/DialogAgreement.vue"
export default component.exports