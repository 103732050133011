import { AuditRole } from '@/config/audit';
export default {
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    },
    isShow: {
      type: [Boolean, Number],
      default: false
    }
  },
  computed: {
    getStatus() {
      return this.status != AuditRole['wait'];
    },
    visibleStatus() {
      return Boolean(this.isShow);
    }
  }
};