var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.shop ? _c("div", {
    staticClass: "order-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 基础信息 ")]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "购物店"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.shopName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "导游"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.guideName))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "进店日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.orderDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "进店人数"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.enterStoreMemberCount))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "人头单价"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.headPrice))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "总社利"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.shopAgencyProfit))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "总计"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.totalBuyMoney))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "备注"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.shop.remark))])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };