var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "product",
      fn: function ({
        scope
      }) {
        return [scope.row.product ? _c("p", [_vm._v(" " + _vm._s(scope.row.product.name) + " ")]) : _vm._e(), scope.row.product ? _c("p", [_c("el-tag", {
          attrs: {
            type: "info"
          }
        }, [_vm._v(" " + _vm._s(scope.row.product.code) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + "大" + _vm._s(scope.row.childCount) + "小 ")];
      }
    }, {
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("p", [_vm._v("姓名：" + _vm._s(scope.row.contactName))]), _c("p", [_vm._v("电话：" + _vm._s(scope.row.contactMobilePhone))]), _c("p", [_vm._v("邮箱：" + _vm._s(scope.row.contactEmail))])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("p", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("p", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "room",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.roomDifferenceCount) + " × " + _vm._s(scope.row.roomDifferencePrice) + " = " + _vm._s(scope.row.roomDifferenceCount * scope.row.roomDifferencePrice) + " 元 ")];
      }
    }, {
      key: "source",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.mallSourceMap[scope.row.source]) + " ")];
      }
    }, {
      key: "payType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.mallPayStatusMap[scope.row.payType]) + " ")];
      }
    }, {
      key: "orderStatus",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.getOrderTagType(scope.row.orderStatus)
          }
        }, [_vm._v(" " + _vm._s(_vm.mallOrderStatusMap[scope.row.orderStatus]) + " ")])];
      }
    }, {
      key: "tourist",
      fn: function ({
        scope
      }) {
        return [scope.row.tourists ? _c("div", _vm._l(scope.row.tourists, function (item) {
          return _c("p", {
            key: item.id
          }, [_vm._v(" " + _vm._s(item.name) + " ")]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "other",
      fn: function ({
        scope
      }) {
        return [scope.row.others && scope.row.others.length ? _vm._l(scope.row.others, function (item) {
          return _c("div", {
            key: item.id
          }, [_c("p", [_c("span", [_vm._v(_vm._s(item.title) + " ")]), _c("span", [_vm._v(" " + _vm._s(item.count) + " * " + _vm._s(item.price) + " = " + _vm._s(item.totalPay) + " ")])]), _c("p", [_vm._v("备注：" + _vm._s(item.remarks))])]);
        }) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLinkBase", {
          attrs: {
            type: "primary",
            disabled: _vm.isPayOrder(scope)
          },
          on: {
            click: function ($event) {
              return _vm.handlePersonalOrder(scope.row);
            }
          }
        }, [_vm._v(" 转散客订单 ")]), _c("BtnLinkBase", {
          attrs: {
            index: 1,
            type: "success",
            disabled: _vm.isPayOrder(scope)
          },
          on: {
            click: function ($event) {
              return _vm.handleTeamOrder(scope.row);
            }
          }
        }, [_vm._v(" 转团队订单 ")])];
      }
    }])
  }), _c("DialogForm", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };