var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visibleStatus ? _c("div", {
    staticClass: "icon-box"
  }, [_vm.getStatus ? _c("i", {
    staticClass: "iconfont lock icon-lock1"
  }) : _c("i", {
    staticClass: "iconfont unlock icon-unlock"
  })]) : _c("div", [_vm._v(" - ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };