var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.getBaseInfo ? _c("div", {
    staticStyle: {
      "padding-bottom": "60px"
    }
  }, [_c("h3", [_vm._v("基础信息")]), _c("BaseInfo", {
    attrs: {
      data: _vm.getBaseInfo,
      "info-list": _vm.infoList
    }
  }), _c("el-divider"), _c("h3", [_vm._v("付交通")]), _vm.getBaseInfo ? _c("BillInput", {
    ref: "formItem",
    attrs: {
      "res-name": _vm.getBaseInfo.transportCompanyName,
      "resource-id": _vm.resourceId,
      "cash-type": _vm.TYPES.transport_company,
      obj: _vm.getBaseInfo
    }
  }) : _vm._e(), _c("el-divider"), _c("h3", [_vm._v("付款记录")]), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      total: _vm.total,
      "show-page": true,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startTripDate))]), _c("div", [_vm._v(_vm._s(scope.row.endTripDate))])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.editPayed(scope.row);
            }
          }
        }, [_vm._v(" 修改 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }], null, false, 2175387161)
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1), _c("DialogForm", {
    ref: "dialogRef",
    on: {
      "on-save": _vm.handleUpdate
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };