export const StatisticsKeys = [];
export const StatusType = {
  copy: 'copy',
  edit: 'edit',
  add: 'add'
};
export const defaultForm = () => ({
  arriveDate: '',
  leaveDate: '',
  shiftNumber: '',
  useDays: '',
  adultCount: '',
  busType: '',
  busAge: '',
  groupType: '',
  useCount: '1',
  inquiryUser: '',
  useAsk: '',
  driverAsk: '',
  itinerary: ''
});
export const rules = {
  arriveDate: [{
    required: true,
    message: '抵达时间必填',
    trigger: 'blur'
  }],
  leaveDate: [{
    required: true,
    message: '离开时间必填',
    trigger: 'blur'
  }],
  useDays: [{
    required: true,
    message: '用车天数必填',
    trigger: 'blur'
  }],
  adultCount: [{
    required: true,
    message: '人数必填',
    trigger: 'blur'
  }],
  busType: [{
    required: true,
    message: '车型必填',
    trigger: 'blur'
  }],
  busAge: [{
    required: true,
    message: '车龄必填',
    trigger: 'blur'
  }],
  groupType: [{
    required: true,
    message: '团型必填',
    trigger: 'blur'
  }],
  useCount: [{
    required: true,
    message: '用车数量必填',
    trigger: 'blur'
  }],
  itinerary: [{
    required: true,
    message: '用车行程必填',
    trigger: 'blur'
  }]
};