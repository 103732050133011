import DialogTurnOut from './components/DialogTurnOut.vue';
import DialogDetail from './components/DialogDetail.vue';
import { planTurnOut } from '@/config/plan';
import { SelectCustom, SelectStaff, DialogLog } from '@/components/common';
import { reloadMixin, permissionMixin, searchMixin } from '@/mixin';
import { TYPES } from '@/config/order';
import { exportTableByXlsxStyle, hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    DialogLog,
    DialogTurnOut,
    DialogDetail
  },
  mixins: [reloadMixin, permissionMixin, searchMixin],
  data: () => ({
    columnData: [],
    loading: true,
    fileName: '外转安排表.xlsx',
    autoWidth: true,
    bookType: 'xlsx',
    excelLoading: false,
    sendDate: [],
    endDate: [],
    searchParams: {}
  }),
  computed: {
    data() {
      return this.$store.getters['plan/turnout/arrangeData'];
    },
    tableData() {
      return this.$store.getters['plan/turnout/arrangeList'];
    },
    getTotal() {
      const data = this.$store.getters['plan/turnout/arrangeData'];
      return data ? data.totalCount : 0;
    },
    getList() {
      return val => val.split('\r\n');
    }
  },
  watch: {
    data: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planTurnOut : filterCustomColumn(planTurnOut);
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('plan/turnout/fetchArrange', {
        ...this.searchParams,
        ...params
      });
    },
    fetchAllData() {
      return this.$store.dispatch('plan/turnout/fetchAllArrange', {
        pageSize: this.getTotal
      });
    },
    // 发团日期 选择开始-结束日期
    changeStartDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    // 散团日期 选择开始-结束日期
    changeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    handleCommand(row, type) {
      this[type](row);
    },
    handleDetail(row) {
      this.$refs.detailRef.show(row);
    },
    handleEdit(row) {
      this.$refs.turnOutRef.show(row);
      this.$store.commit('order/reception/CUR_RECEPTION_LIST', [row]);
    },
    handleLog(row) {
      const {
        customerOrderId: itemId
      } = row;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId,
        subType: TYPES.localTravelAgency
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    async handleExport() {
      this.excelLoading = true;
      this.$bus.tip({
        message: '任务已执行，正在后台导出, 请稍等...'
      });
      const tableData = await this.fetchAllData();
      const customerColumns = [{
        attrs: {
          prop: 'index',
          label: '序号'
        }
      }, {
        attrs: {
          prop: 'customerTripNumber',
          label: '小团号'
        }
      }, {
        attrs: {
          prop: 'info',
          label: '游客信息/人数/客源地'
        }
      }, {
        attrs: {
          prop: 'itineraries',
          label: '行程安排'
        }
      }, {
        attrs: {
          prop: 'hotel',
          label: '入住酒店'
        }
      }];
      const tHeader = customerColumns.map(it => '\t' + it.attrs.label);
      const filterVal = customerColumns.map(it => it.attrs.prop);
      const data = this.formatJson(filterVal, tableData);
      exportTableByXlsxStyle({
        data: [tHeader, ...data],
        fileName: this.fileName,
        sheetName: this.fileName
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => {
        return filterVal.map(j => {
          if (j == 'index') {
            v[j] = i + 1;
          }
          if (j == 'info') {
            v[j] = `${v.memberInfo} \r\n ${v.count}`;
            v[j] += v.source ? ` \r\n $${v.source}` : '';
          }
          return v[j];
        });
      });
    }
  }
};