var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "编辑酒店区域/标准",
      width: "650px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_vm.visible ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "hotelRegionType"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectDic("hotelArea", $event);
      }
    },
    model: {
      value: _vm.from.hotelArea,
      callback: function ($$v) {
        _vm.$set(_vm.from, "hotelArea", $$v);
      },
      expression: "from.hotelArea"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "标准",
      code: "hotelStandardType"
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectDic("hotelStandard", $event);
      }
    },
    model: {
      value: _vm.from.hotelStandard,
      callback: function ($$v) {
        _vm.$set(_vm.from, "hotelStandard", $$v);
      },
      expression: "from.hotelStandard"
    }
  })], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };