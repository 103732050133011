import { orderAuditStatus, orderAuditTagStatus } from '@/config/order';

// 审核状态
export default {
  name: 'StatusPlanAudit',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: () => ({
    orderAuditStatus,
    orderAuditTagStatus
  })
};