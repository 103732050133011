var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "input-field"
  }, [_c("h3", {
    staticClass: "pr10"
  }, [_vm._v(" 新增项目 ")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.addRow
    }
  }, [_vm._v(" 添加 ")])], 1), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.contentType,
            callback: function ($$v) {
              _vm.$set(scope.row, "contentType", $$v);
            },
            expression: "scope.row.contentType"
          }
        })];
      }
    }, {
      key: "quotedPrice",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: scope.row.quotedPrice,
            callback: function ($$v) {
              _vm.$set(scope.row, "quotedPrice", $$v);
            },
            expression: "scope.row.quotedPrice"
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("InputNumber", {
          attrs: {
            label: "",
            "controls-position": "right"
          },
          model: {
            value: scope.row.price,
            callback: function ($$v) {
              _vm.$set(scope.row, "price", $$v);
            },
            expression: "scope.row.price"
          }
        })];
      }
    }, {
      key: "contractPriceFrees",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "reduction"
        }, [_c("div", {
          staticClass: "flex"
        }, [_c("el-input", {
          model: {
            value: scope.row.freeCountMinRequire,
            callback: function ($$v) {
              _vm.$set(scope.row, "freeCountMinRequire", _vm._n($$v));
            },
            expression: "scope.row.freeCountMinRequire"
          }
        }), _c("span", [_vm._v("免")]), _c("el-input", {
          model: {
            value: scope.row.freeCount,
            callback: function ($$v) {
              _vm.$set(scope.row, "freeCount", _vm._n($$v));
            },
            expression: "scope.row.freeCount"
          }
        }), _c("el-button", {
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            type: scope.row.itemIndex > 0 ? "warning" : "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleRow(scope);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.itemIndex > 0 ? "删除" : "添加") + " ")])], 1)])];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          attrs: {
            label: ""
          },
          model: {
            value: scope.row.remarks,
            callback: function ($$v) {
              _vm.$set(scope.row, "remarks", $$v);
            },
            expression: "scope.row.remarks"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };