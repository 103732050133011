import { tableMixin, getSummariesMixin } from '@/mixin';
import shopMixin from '../shopMixin';
export default {
  mixins: [tableMixin, getSummariesMixin, shopMixin],
  data: () => ({
    sumsIndex: 0,
    avgeageKey: [2, 4, 5],
    // 计算人均
    statisticsKeys: ['plans', 'adultCount', 'totalHeadRebate', 'totalBuyMoney', 'shopPerCapita', 'totalShopRebate', 'totalGuideRebate', 'totalGuideManagerRebate']
  }),
  methods: {}
};