var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "添加订单",
      width: "960px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("TableOrder", {
    attrs: {
      "table-data": _vm.tableData,
      "table-loading": _vm.tableLoading
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };