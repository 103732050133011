import { tableMixin, summaryMixin } from '@/mixin';
export default {
  components: {},
  mixins: [tableMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['balance', 'accountAge',
      // 'overdue',
      'paymentCollection', 'totalFee']
    };
  },
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    },
    tableCellClassName({
      columnIndex
    }) {
      if (columnIndex == 7) {
        return 'primary-row';
      }
      if (columnIndex == 9) {
        return 'warning-row';
      }
      return '';
    }
  }
};