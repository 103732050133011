var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-drawer", _vm._g(_vm._b({
    attrs: {
      title: _vm.title,
      visible: _vm.visiblePanel,
      direction: _vm.direction
    },
    on: {
      "update:visible": function ($event) {
        _vm.visiblePanel = $event;
      }
    }
  }, "el-drawer", _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };