import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { wordFeeList } from '@/config/system';
import formDialog from './components/formDialog';
import { departKeys, departIdKeys } from '@/config/dictionary';
import { InputDepartment, SelectStaff } from '@/components/common';
export default {
  name: 'InlineForm',
  components: {
    InputDepartment,
    SelectStaff,
    formDialog
  },
  mixins: [searchMixin],
  data: () => ({
    total: 0,
    department: [],
    tableData: [],
    statistics: {},
    columnData: wordFeeList,
    searchParams: {
      startCashDate: '',
      endCashDate: ''
    }
  }),
  computed: {
    ...mapGetters({
      cashFlowData: 'system/accounting/cashFlowData'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startCashDate,
          endCashDate
        } = data;
        this.billDate = [startCashDate, endCashDate]; // 下账日期
        this.searchParams = data;
      },
      immediate: true
    },
    cashFlowData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.statistics = statistics;
      this.total = totalCount;
      this.tableData = list;
    },
    handleEdit({
      row
    }) {
      this.$refs.formRef.edit(row);
    },
    handleRemove({
      row
    }) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('system/accounting/delCash', {
          id
        }).then(() => {
          this.fetchData(this.searchParams);
        });
      }, '是否确定删除？');
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.department[key] = val[key];
      });
      departIdKeys.forEach(key => {
        this.searchParams[key] = val[key] || -1;
      });
    },
    onSelect(key, val) {
      this.searchParams[key + 'Name'] = val ? val.label : '';
      this.searchParams[key + 'Id'] = val ? val.value : '';
    },
    onSelectCash(val) {
      this.searchParams.cashWay = val ? val.label : '';
    },
    onSelectCost(val) {
      this.searchParams.cashAction = val ? val.value : '';
      this.searchParams.cashActionName = val ? val.label : '';
    },
    onChangeDate(date) {
      const [start, end] = date;
      this.billDate = date;
      this.searchParams.endCashDate = end;
      this.searchParams.startCashDate = start;
    },
    parentFetchData(data) {
      this.searchParams = {
        ...this.searchParams,
        ...data
      };
      this.handleSearch();
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    fetchData(opts) {
      if (!opts.subjectIds) {
        delete opts.subjectIds;
      }
      opts = {
        ...opts,
        subjectType: 'office'
      };
      this.$store.dispatch('system/accounting/fetchCashFlow', opts);
    }
  }
};