import { formMixin } from '@/mixin';
export default {
  mixins: [formMixin],
  data: () => ({
    visible: false,
    title: '',
    form: {},
    validateForm: {
      name: [{
        required: true,
        message: '科目名字不能为空！'
      }]
    }
  }),
  methods: {
    showDialog(data, title) {
      this.form = {
        ...data,
        subjectType: 'office'
      };
      this.title = title;
      this.visible = true;
    },
    hide() {
      this.form = {};
      this.visible = false;
    },
    // 添加科目
    handleCreate() {
      if (!this.handleValidate()) return;
      const opst = this.form;
      this.$store.dispatch('system/accounting/saveOrUpdate', opst).then(() => {
        this.$parent.fetchData({
          subjectType: 'office'
        });
        this.hide();
      });
    }
  }
};