import TableList from './TableList';
import { SelectGuide } from '@/components/common';
import { ticketOrder } from '@/config/ticket';
import { mapGetters } from 'vuex';
export default {
  components: {
    TableList,
    SelectGuide
  },
  data() {
    return {
      visible: false,
      isAudit: true,
      isSelect: true,
      tableLoading: false,
      columnData: ticketOrder,
      tableData: [],
      total: 0,
      searchParams: {},
      sendDate: [],
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      signSheetId: ''
    };
  },
  computed: {
    ...mapGetters({
      ticketPlanAuditData: 'ticket/index/ticketPlanAuditData'
    })
  },
  // watch: {
  //   ticketPlanAuditData: {
  //     handler: 'initData',
  //     immediate: true,
  //   },
  // },

  methods: {
    show(id) {
      this.signSheetId = id;
      this.visible = true;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    // initData(data) {
    //   if (!data) {
    //     this.fetchData()
    //   } else {
    //     this.processData(data)
    //   }
    // },
    fetchData(queryParams = this.listQuery) {
      this.tableLoading = true;
      this.$store.dispatch('ticket/index/fetchPlanAudit', queryParams).then(data => {
        this.tableLoading = false;
        this.processData(data);
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleTransfer(newPlanId) {
      const opts = {
        newPlanId,
        signSheetId: this.signSheetId
      };
      this.$store.dispatch('ticket/index/transferTicket', opts).then(() => {
        this.hide();
        this.$bus.tip({
          message: '转单成功'
        });
        this.$bus.emit('ticket:reload');
      });
    },
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    processData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = list;
    }
  }
};