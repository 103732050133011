var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-detail form-wrapper"
  }, [_c("DetailBaseInfo"), _c("div", {
    staticClass: "table-box"
  }, [_c("DetailRemarks")], 1), _c("CollapseBase", {
    attrs: {
      title: "行程",
      "is-strong": ""
    }
  }, [_c("DetailItinerary")], 1), _c("CollapseBase", {
    attrs: {
      title: "景区",
      "is-strong": ""
    }
  }, [_c("DetailScenic")], 1), _c("CollapseBase", {
    attrs: {
      title: "酒店",
      "is-strong": ""
    }
  }, [_c("DetailHotel")], 1), _c("CollapseBase", {
    attrs: {
      title: "餐厅",
      "is-strong": ""
    }
  }, [_c("DetailRestaurant")], 1), _c("CollapseBase", {
    attrs: {
      title: "团款",
      "is-strong": ""
    }
  }, [_c("DetailFund")], 1), _c("CollapseBase", {
    attrs: {
      title: "代收",
      "is-strong": ""
    }
  }, [_c("DetailProxy")], 1), _c("CollapseBase", {
    attrs: {
      title: "返款",
      "is-strong": ""
    }
  }, [_c("DetailRefund")], 1), _c("CollapseBase", {
    attrs: {
      title: "接送",
      "is-strong": ""
    }
  }, [_c("DetailPickup")], 1), _c("CollapseBase", {
    attrs: {
      title: "保险",
      "is-strong": ""
    }
  }, [_c("DetailInsurance")], 1), _c("CollapseBase", {
    attrs: {
      title: "大交通",
      "is-strong": ""
    }
  }, [_c("DetailTraffic")], 1), _c("CollapseBase", {
    attrs: {
      title: "游客名单",
      "is-strong": ""
    }
  }, [_c("DetailTourist")], 1), _c("CollapseBase", {
    attrs: {
      title: "其他收入",
      "is-strong": ""
    }
  }, [_c("DetailOtherIncome")], 1), _c("CollapseBase", {
    attrs: {
      title: "其他支出",
      "is-strong": ""
    }
  }, [_c("DetailOtherPay")], 1), _c("CollapseBase", {
    attrs: {
      title: "外转",
      "is-strong": ""
    }
  }, [_c("DetailReception")], 1), _c("FooterBar", [_vm.orderAuditTag ? _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: function ($event) {
        _vm.handleAudit(_vm.status = 0);
      }
    }
  }, [_vm._v(" 拒绝通过 ")]) : _vm._e(), _vm.orderAuditTag ? _c("BtnBase", {
    attrs: {
      type: "auditOp"
    },
    on: {
      click: function ($event) {
        _vm.handleAudit(_vm.status = 1);
      }
    }
  }, [_vm._v(" 审核通过 ")]) : _vm._e(), _vm.type != "auditOrder" ? _c("BtnBase", {
    attrs: {
      type: "print"
    },
    on: {
      click: _vm.handlePrint
    }
  }, [_vm._v(" 打印单据 ")]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };