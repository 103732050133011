import { orderStatusLabel, orderTagStatus } from '@/config/order';
export default {
  name: 'StatusOrder',
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    orderStatusLabel,
    orderTagStatus
  })
};