var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "profile"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6,
      xs: 24
    }
  }, [_c("el-card", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("关于我")])]), _c("div", {
    staticClass: "user-profile"
  }, [_c("div", {
    staticClass: "box-center"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.avatar
    }
  })]), _c("div", {
    staticClass: "box-center"
  }, [_c("div", {
    staticClass: "user-name text-center"
  }, [_vm._v(" " + _vm._s(_vm.getCompany) + " ")]), _c("div", {
    staticClass: "user-role text-center text-muted"
  }, [_vm._v(" " + _vm._s(_vm.getRole) + " ")])])])])], 1), _c("el-col", {
    attrs: {
      span: 18,
      xs: 24
    }
  }, [_c("el-card", [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "用户信息",
      name: "userinfo"
    }
  }, [_c("div", {
    staticClass: "mt10"
  }, [_c("InputBase", {
    attrs: {
      label: "姓名",
      vertical: ""
    },
    model: {
      value: _vm.formUser.realName,
      callback: function ($$v) {
        _vm.$set(_vm.formUser, "realName", $$v);
      },
      expression: "formUser.realName"
    }
  })], 1), _c("div", {
    staticClass: "mt10"
  }, [_c("InputBase", {
    attrs: {
      label: "手机",
      vertical: ""
    },
    model: {
      value: _vm.formUser.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.formUser, "mobilePhone", $$v);
      },
      expression: "formUser.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "mt10"
  }, [_c("InputBase", {
    attrs: {
      label: "座机",
      vertical: ""
    },
    model: {
      value: _vm.formUser.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.formUser, "telphone", $$v);
      },
      expression: "formUser.telphone"
    }
  })], 1), _c("div", {
    staticClass: "mt10"
  }, [_c("InputBase", {
    attrs: {
      label: "传真",
      vertical: ""
    },
    model: {
      value: _vm.formUser.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formUser, "faxNumber", $$v);
      },
      expression: "formUser.faxNumber"
    }
  })], 1), _c("div", {
    staticClass: "mt10"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateProfile
    }
  }, [_vm._v(" 保存 ")]), !_vm.formUser.pwdsSalt ? _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.wechatBind
    }
  }, [_vm._v(" 绑定微信 ")]) : _vm._e(), _vm.formUser.pwdsSalt ? _c("el-button", {
    attrs: {
      type: "success",
      plain: ""
    },
    on: {
      click: _vm.clearWechat
    }
  }, [_vm._v(" 解除微信绑定 ")]) : _vm._e()], 1)]), _c("el-tab-pane", {
    attrs: {
      label: "修改密码",
      name: "password"
    }
  }, [_c("el-form", {
    ref: "pwdForm",
    attrs: {
      "status-icon": "",
      model: _vm.pwdForm,
      rules: _vm.pwdRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "oldPassword"
    }
  }, [_vm._v(" 账号: " + _vm._s(_vm.user.userName) + " ")]), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "password"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "新密码"
    },
    model: {
      value: _vm.pwdForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "",
      prop: "passwordConfirm"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "password",
      autocomplete: "off",
      label: "确认密码"
    },
    model: {
      value: _vm.pwdForm.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.pwdForm, "passwordConfirm", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "pwdForm.passwordConfirm"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updatePassword
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "微信扫码绑定",
      visible: _vm.showWxDialog,
      "custom-class": "wechat-scan-dialog",
      width: "500px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.showWxDialog = $event;
      }
    }
  }, [_c("wxlogin", {
    attrs: {
      appid: _vm.wxLoginDiaData.appid,
      scope: _vm.wxLoginDiaData.scope,
      theme: "black",
      redirect_uri: encodeURIComponent(`${_vm.wxRedirectUrl}`),
      rel: "external nofollow"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };