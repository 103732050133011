var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-checkbox", {
    on: {
      change: function ($event) {
        return _vm.$emit("on-check", _vm.row, $event);
      }
    },
    model: {
      value: _vm.row.isChecked,
      callback: function ($$v) {
        _vm.$set(_vm.row, "isChecked", $$v);
      },
      expression: "row.isChecked"
    }
  }), _c("span", {
    staticClass: "pr5"
  }), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("on-remove", _vm.row);
      }
    }
  }, [_vm._v(" 删除 ")]), _c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("on-log", _vm.row.id);
      }
    }
  }, [_vm._v(" 日志 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };