import DrawerPanel from './DrawerPanel';
import { SelectDictionary, BtnLink } from '@/components/common';
import { bookingRoom } from '@/config/report';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import variables from '@/styles/variables.scss';
export default {
  components: {
    SelectDictionary,
    BtnLink,
    DrawerPanel
  },
  mixins: [searchMixin],
  data() {
    return {
      columnData: bookingRoom,
      data: [],
      searchParams: {}
    };
  },
  computed: {
    ...mapGetters({
      roomCountData: 'report/index/roomCountData'
    }),
    variables() {
      return variables;
    }
  },
  watch: {
    roomCountData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
        return;
      }
      this.data = data;
      this.calcTableHeight();
    },
    fetchData(queryParams = {}) {
      this.$store.dispatch('report/index/fetchOrderRoom', queryParams);
    },
    calcTableHeight() {
      this.$nextTick(() => {
        const {
          tableRef
        } = this.$refs;
        tableRef && tableRef.setTableHeight();
      });
    },
    onChangeSendDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    onChangeOrderDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.orderDateS = startDate;
      this.searchParams.orderDateE = endDate;
    },
    handleChange(type, data) {
      this.searchParams[type] = data ? data.label : '';
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    handleDetail(row) {
      this.$refs.drawerRef.show(row);
    }
  }
};