// 按单位 付酒店
import { ColumnObj, PayUnitColumn } from './index';
const {
  orderInfo,
  auditStatus,
  tripDate,
  orderDate,
  guide,
  orderArrangeRemark,
  orderTotalPay,
  payed,
  owePay,
  downPayed,
  actionItem
} = ColumnObj;
export const UnitPayColumn = [{
  attrs: {
    prop: 'resourceName',
    label: '酒店'
  }
}, ...PayUnitColumn];

// 按单位去付款 酒店
export const UnPayColumn = [orderInfo, auditStatus, tripDate, orderDate, guide, {
  attrs: {
    prop: 'hotelName',
    label: '酒店'
  }
}, {
  attrs: {
    prop: 'days',
    label: '天数'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, orderArrangeRemark, orderTotalPay, payed, owePay, downPayed, actionItem];