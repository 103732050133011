/* eslint-disable no-unused-vars */
// 系统配置
import { fetchGLETravel, updateGLETravel, updateGLETravelDic, getSysConfig, setSysConfig } from '@/api/system/buttJoint';
const dfaultState = () => ({
  GLETravelData: null,
  configData: null
});
const state = dfaultState();
const getters = {
  GLETravelData: state => state.GLETravelData,
  configData: state => state.configData
};
const actions = {
  getConfig({
    commit
  }, params) {
    return getSysConfig(params).then(res => {
      commit('SET_DATA', {
        type: 'configData',
        data: res.data
      });
      return res;
    });
  },
  setConfig({
    commit
  }, opts) {
    return setSysConfig(opts);
  },
  fetchGLETravel({
    commit
  }) {
    return fetchGLETravel().then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA', data);
      return data;
    });
  },
  update({
    commit
  }, opts) {
    return updateGLETravel(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  updateGLETravelDic({
    commit
  }) {
    return updateGLETravelDic().then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA(state, data) {
    state.GLETravelData = data;
  },
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};