var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box input-people-count"
  }, [_vm.showLabel ? _c("span", {
    class: ["label", {
      "is-require": _vm.isRequire
    }]
  }, [_vm._v("参团人数")]) : _vm._e(), _c("el-input", {
    attrs: {
      onkeyup: "value=value.replace(/[^\\d]/g,'')"
    },
    model: {
      value: _vm.getAudlt,
      callback: function ($$v) {
        _vm.getAudlt = $$v;
      },
      expression: "getAudlt"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("大")]), _c("el-input", {
    attrs: {
      onkeyup: "value=value.replace(/[^\\d]/g,'')"
    },
    model: {
      value: _vm.getChild,
      callback: function ($$v) {
        _vm.getChild = $$v;
      },
      expression: "getChild"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("小")]), _c("el-input", {
    attrs: {
      onkeyup: "value=value.replace(/[^\\d]/g,'')"
    },
    model: {
      value: _vm.getAccompany,
      callback: function ($$v) {
        _vm.getAccompany = $$v;
      },
      expression: "getAccompany"
    }
  }), _c("span", {
    staticClass: "text"
  }, [_vm._v("陪")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };