var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.title ? _c("div", {
    staticClass: "print-top"
  }, [_vm.logo ? _c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.logo,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    staticClass: "print-top__title tac"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };