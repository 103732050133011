import { tableMixin, rowProfitMixin, summaryMixin } from '@/mixin';
import { PersonCount } from '@/components/common';
export default {
  components: {
    PersonCount
  },
  mixins: [tableMixin, rowProfitMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['number', 'total']
    };
  }
};