import guideMixin from './guide';
const columnData = [{
  label: '名称',
  prop: 'name'
}, {
  label: '小团号',
  prop: 'customerTripNumber'
}, {
  label: '类别',
  prop: 'ticketType'
}, {
  label: '加点',
  prop: 'isAddScenic',
  width: '40px'
}, {
  label: '日期',
  prop: 'orderDate',
  width: '80px'
}, {
  label: '价格',
  prop: 'sellPrice'
}, {
  label: '数量',
  prop: 'adultCount'
}, {
  label: '免去',
  prop: 'adultFreeCount'
}, {
  label: '实收数量',
  prop: 'countReceive'
}, {
  label: '实收金额',
  prop: 'totalAmountReceive'
}];
export default {
  mixins: [guideMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData
    };
  }
};