import { SelectTraffic, SelectDictionary } from '@/components/common';
import { dictionaryMixin, formMixin } from '@/mixin';
import { codeType } from '@/config/dictionary';
import { mapGetters } from 'vuex';

// import dayjs from 'dayjs'

const defaultState = () => ({
  price: 0,
  quantity: 0,
  transportId: '',
  remarks: '',
  itineraryDate: '',
  otherFee: ''
});
export default {
  components: {
    SelectTraffic,
    SelectDictionary
  },
  mixins: [dictionaryMixin, formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: defaultState(),
      bigTraffic: 'bigTraffic',
      teamFundType: codeType.teamFundType,
      totalMoney: 0,
      validateForm: {
        transportId: [{
          required: true,
          message: '请选择大交通'
        }],
        transportType: [{
          required: true,
          message: '请选择票务类型'
        }],
        itineraryDate: [{
          required: true,
          message: '请选择时间'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    getTitle() {
      return this.isUpdate ? '修改大交通' : '新增大交通';
    }
  },
  methods: {
    edit(data) {
      this.visible = true;
      this.data = data;
      this.form = {
        ...defaultState(),
        ...data
      };
      this.countTotalMoney();
      // delete this.form.id
    },
    show(data) {
      this.visible = true;
      this.data = data;
      this.totalMoney = 0;
      this.form = defaultState();
      this.form.quantity = this.currentOrder.adultCount;
      this.form.itineraryDate = data.itineraryDate;
      this.form.itineraryId = data.id;
      const ticketType = this.getDictionaryDefVal('ticketType');
      this.form.transportType = ticketType.name;
    },
    hide() {
      this.visible = false;
    },
    countTotalMoney() {
      this.totalMoney = this.form.price * this.form.quantity + Number(this.form.otherFee);
    },
    changeInput() {
      this.countTotalMoney();
    },
    selectType(val) {
      this.form.transportType = val.label;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      // const { itineraryId } = this.data
      const {
        quantity,
        price
      } = this.form;
      const amount = price * quantity;
      const opts = {
        ...this.form,
        amount
        // itineraryId,
      };
      this.$store.dispatch('order/index/createOrUpdateTraffic', opts).then(() => {
        this.$bus.emit('itinerary:reload');
        this.$bus.tip();
        this.hide();
        this.resetForm();
      });
    },
    resetForm() {
      this.form = defaultState();
    }
  }
};