import store from '@/store';
import router from '@/router';

// 获取当前路径的搜索条件与分页数据
export const getCurTagView = () => {
  const {
    path
  } = router.history.current;
  return store.getters['tagsView/visitedViews'].find(tag => tag.path === path);
};

// 重置分页
export const initPageSize = () => {
  const {
    path
  } = router.history.current;
  const {
    size
  } = getCurTagView();
  store.dispatch('tagsView/appendVisiteViewPage', {
    path,
    page: 1,
    size
  });
};