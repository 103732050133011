import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import DialogCustom from './DialogCustom';
import DialogProduct from './DialogProduct';
import { SelectStaff, InputPeopleCount, InputTeamNumber, SelectDictionary, SelectRegion, SelectGuideManager } from '@/components/common';
import { getNextDate, getDaysBetween, getUserData } from '@/utils';
import { customerInfoMixin, formMixin, tagViewMixin } from '@/mixin';
import { OrderAuditEmit, orderType } from '@/config/order';
import { defDictionary } from '@/utils/dictionary';
import { formatCity } from '@/utils';
import { mapGetters } from 'vuex';
const roomsState = () => ({
  quantity: '',
  roomType: '',
  roomTypeId: '',
  roomName: ''
});
// 景区门票类别
const scenicTicketsState = () => ({
  ticketType: '成人票',
  ticketTypeId: -1,
  tickeaccompanyCounttType: 0,
  accompanyFreeCount: 0,
  accompanyPrice: 0,
  price: 0,
  adultCount: 0,
  adultFreeCount: 0,
  childCount: 0,
  childFreeCount: 0,
  childPrice: 0,
  otherFee: 0,
  sellPrice: 0,
  settlementPrice: 0
});
const defaultState = () => ({
  accompanyCount: '',
  accompanyInfo: '',
  adultCount: '',
  babyCount: '',
  childCount: '',
  customerTripNumber: '',
  // 小团号
  planCustomerNumber: '',
  // 大团号
  customerContactMobileNumber: '',
  customerContactName: '',
  customerName: '',
  customerId: '',
  endTripDate: '',
  endTripPosition: '',
  endTripTransport: '',
  grandsonDepartmentId: '',
  groupType: defDictionary('teamType') ? defDictionary('teamType').label : '',
  outOPUserId: '',
  outOPUserName: '',
  productId: '',
  productName: '',
  saleUserId: '',
  saleUserName: '',
  startTripDate: '',
  startTripPosition: '',
  takeCustomerAddress: '',
  welcomeBorad: '',
  province: '',
  city: '',
  district: '',
  provinceId: '',
  cityId: '',
  districtId: '',
  region: [],
  creditMoney: '',
  balance: '',
  dutyOPUserName: '',
  dutyOPUserId: '',
  addAuditStatus: OrderAuditEmit.NEW_WAIT,
  // rooms: [...roomsState()],
  roomInfo: {
    single: 0,
    triple: 0,
    accompany: 0,
    add: 0,
    doubleRoom: 0
  }
});
export default {
  components: {
    DialogCustom,
    DialogProduct,
    SelectStaff,
    InputPeopleCount,
    InputTeamNumber,
    SelectDictionary,
    SelectRegion,
    SelectGuideManager
  },
  mixins: [formMixin, tagViewMixin, customerInfoMixin],
  props: {
    orderType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => {}
    },
    // 同行接收不允许修改客户
    disableCustomer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      createState: true,
      isRequire: true,
      type: 'create',
      form: {
        ...defaultState(),
        orderType: this.orderType
      },
      validateForm: {
        outOPUserName: [{
          required: true,
          message: '请选择客服计调'
        }],
        saleUserName: [{
          required: true,
          message: '请选择业务员'
        }],
        customerName: [{
          required: true,
          message: '请选择客户'
        }],
        customerContactName: [{
          required: true,
          message: '请填写联系人姓名'
        }],
        productName: [{
          required: true,
          message: '请选择线路产品'
        }],
        startTripDate: [{
          required: true,
          message: '请选择发团日期'
        }],
        endTripDate: [{
          required: true,
          message: '请选择散团日期'
        }],
        // adultCount: [
        //   { required: true, message: '请填写团人数' },
        //   { validate: isAdultCount, message: '大人数必须大于0！' },
        // ],
        groupType: [{
          required: true,
          message: '请选择团队类型'
        }]
      },
      isFirst: true,
      oldForm: {},
      tipMsg: '',
      tipTelite: '',
      newFormItem: null,
      productId: '',
      startTripDate: '',
      endTripDate: '',
      statusShow: true,
      planId: '',
      productType: 'productType',
      cityList: formatCity()
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      systemConfigData: 'system/systemConf/configData',
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData',
      orderScenices: 'order/index/orderScenices',
      guideManagerData: 'resource/guideManager/guideData'
    }),
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    },
    isTeamOrder() {
      return this.orderType == 'S_TOURIST';
    },
    mutipleData() {
      return {
        productId: this.form.productId,
        startTripDate: this.form.startTripDate,
        endTripDate: this.form.endTripDate
      };
    },
    isShowLimit() {
      const list = this.systemConfigData && this.systemConfigData.find(it => it.code == 'order');
      const item = list.configs.find(it => it.name == '启用授信额度');
      const findItem = item.values.find(it => it.name == '启用授信额度');
      return findItem ? findItem.value : false;
    }
  },
  watch: {
    userData: {
      handler: 'getUserData',
      immediate: true
    },
    form: {
      handler: 'changeFormInput',
      deep: true
    },
    formData: {
      handler: function (data) {
        if (!data) return;
        this.form = {
          ...data,
          ...this.processAccompanyInfo(data.accompanyInfo)
        };
        this.form.groupTypeId = this.form.groupType;
        const {
          productId,
          startTripDate,
          endTripDate
        } = data;
        if (this.isFirst) {
          this.isFirst = false;
          this.productId = productId;
          this.startTripDate = startTripDate;
          this.endTripDate = endTripDate;
        }
      },
      immediate: true
    },
    mutipleData(val) {
      this.newFormItem = val;
    }
  },
  mounted() {
    const {
      type,
      id
    } = this.$route.query;
    this.type = type || 'create';
    // 添加订单时产品类型默认选择第一个
    if (this.type == 'create') {
      const productDict = this.dictionaryCodeData[this.productType];
      this.form.productType = productDict ? productDict.agencyDictionaryList[0].label : '';
    }
    this.planId = id;
    // this.fetchResourcesType()
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    processAccompanyInfo(val) {
      if (!val) return {
        accompanyName: '',
        accompanyPhone: ''
      };
      if (val.indexOf('/') > -1) {
        const [accompanyName, accompanyPhone] = val.split('/');
        return {
          accompanyName,
          accompanyPhone
        };
      }
      const re = /([\u4e00-\u9fa5]+)(\d+)/g;
      const match = re.exec(val.replace(/\s/g, ''));
      if (match) {
        const [, accompanyName, accompanyPhone] = match;
        return {
          accompanyName,
          accompanyPhone
        };
      }
      return {
        accompanyName: val,
        accompanyPhone: ''
      };
    },
    getProductDays(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/product/fetchDetail', id).then(res => {
          resolve(res);
        }).catch(() => {
          reject();
        });
      });
    },
    createOrUpdateHotel(opts) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/index/createOrHotel', opts).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    createOrUpdateItinerary(data) {
      const {
        path
      } = this.$route;
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/index/createOrUpdateItinerary', data).then(() => {
          // 根据缓存判断是否要清空当前景区
          if (path == '/order/team-input-update') {
            this.deleteOrScenicAll(data);
          }
          // 同行接收/资源计划 弹窗创建订单
          if (path == '/order/team-input-create' || path == '/order/turn-in' || path == '/order/resource-plan') {
            this.createOrUpdateScenic(data);
          }
          this.$bus.emit('itinerary:reload');
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    // 批量删除景区
    deleteOrScenicAll(data) {
      // 如果有景区则先删除 不然直接创建
      if (this.orderScenices.length) {
        let ids = [];
        this.orderScenices.forEach(it => {
          ids.push(it.id);
        });
        this.$store.dispatch('order/index/deleteOrScenicAll', ids).then(() => {
          this.createOrUpdateScenic(data);
        });
      } else {
        this.createOrUpdateScenic(data);
      }
    },
    // 创建订单景区
    createOrUpdateScenic(data) {
      this.fetchResourcesType(data).then(res => {
        const scenicList = this.beforeCommitScenic(data, res);
        if (!scenicList.length) {
          this.$bus.emit('scenic:reload');
          return;
        }
        this.$store.dispatch('plan/scenic/updateOrder', scenicList).then(() => {
          this.$bus.emit('scenic:reload');
        });
      });
      // })
    },
    // 景区资源协议获取
    fetchResourcesType(list) {
      const resourceIds = [];
      list.forEach(it => {
        it.scenics.forEach(v => {
          resourceIds.push(v.scenicId);
        });
      });
      if (!resourceIds.length) return Promise.resolve([]);
      const opts = {
        resourceIds: resourceIds.toString(),
        resourceType: 'scenic'
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/common/fetchResourcesType', {
          ...opts,
          resourceType: 'scenic'
        }).then(res => {
          const keys = ['quotedPrice', 'price', 'contentType'];
          const types = res.map(it => {
            const item = {
              scenicId: it.resourceId,
              scenicName: it.unit
            };
            let content = it.contractPrices.find(f => f.contentType.indexOf('成人票') != -1);
            content = content ? content : it.contractPrices[0];
            keys.forEach(key => {
              item[key] = content ? content[key] : 0;
              item.contentType = content ? content[key] : '成人票';
            });
            return item;
          });
          resolve(types);
        }).catch(() => {
          reject();
        });
      });
    },
    // 景区提交前格式化
    beforeCommitScenic(list, types) {
      let scenics = [];
      list.forEach(it => {
        it.scenics = it.scenics.filter(ie => ie.scenicType == 'PAID');
        it.scenics.forEach(v => {
          const resourceType = types.find(z => z.scenicId == v.scenicId);
          const item = {
            orderDate: it.itineraryDate,
            customerOrderId: it.customerOrderId,
            scenicId: v.scenicId,
            scenicName: v.scenicName,
            scenicOTickets: [{
              ...scenicTicketsState(),
              adultCount: this.form.adultCount,
              childCount: this.form.childCount,
              accompanyCount: this.form.accompanyCount,
              ticketType: resourceType ? resourceType.contentType : '成人票',
              price: resourceType ? resourceType.quotedPrice : 0,
              settlementPrice: resourceType ? resourceType.price : 0
            }]
          };
          scenics.push(item);
        });
      });
      return scenics;
    },
    // 清理上一次的行程
    clearLastItinerary(id) {
      return this.$store.dispatch('order/index/delItineraryByOrderId', id);
    },
    // 选择产品 获取产品数据
    getProduct({
      productId,
      customerOrderId,
      startTripDate,
      betweenDay
    }) {
      return this.$store.dispatch('resource/product/fetchItinerary', productId).then(data => {
        return this.formatProduct({
          data,
          customerOrderId,
          startTripDate,
          betweenDay
        });
      });
    },
    // 复制酒店信息
    copyHotelInfo(customerOrderId) {
      const EmptyKey = ['hotelName'];
      const RestoreKey = ['hotelId', 'headCompanyId'];
      const DelKeys = ['id', 'auditStatus', 'auditProgress', 'arrangeState'];

      // 获取复制的酒店信息
      this.$store.dispatch('order/index/fetchHotel', this.planId).then(data => {
        const list = data.map(it => {
          it.customerOrderId = customerOrderId;
          DelKeys.forEach(key => {
            delete it[key];
          });
          RestoreKey.forEach(key => {
            it[key] = -1;
          });
          EmptyKey.forEach(key => {
            it[key] = '';
          });
          return it;
        });
        this.createOrUpdateHotel(list);
      });
    },
    // 复制行程
    copyTripInfo({
      startTripDate,
      customerOrderId
    }) {
      this.$store.dispatch('order/index/fetchItinerary', this.planId).then(data => {
        let curDate = startTripDate;
        const list = data.map((item, index) => {
          if (index != 0) {
            curDate = getNextDate(curDate);
          }
          item.customerOrderId = customerOrderId;
          item.whichDay = index + 1;
          item.itineraryDate = curDate;
          delete item.id;
          item.scenics = item.scenics.map(it => {
            delete it.itineraryId;
            delete it.id;
            return it;
          });
          // 去除失效的景区
          if (item.scenics && item.scenics.length) {
            item.scenics = item.scenics.filter(it => it.scenicEnableStatus);
          }
          item.shops = item.shops.map(it => {
            delete it.itineraryId;
            delete it.id;
            return it;
          });
          delete item.rooms.itineraryId;
          delete item.rooms.id;
          return item;
        });
        this.createOrUpdateItinerary(list);
      });
    },
    // 获取用户数据
    getUserData(val) {
      if (val) {
        const {
          id,
          realName
        } = val;
        const idKeys = ['dutyOPUserId', 'saleUserId', 'outOPUserId'];
        const nameKeys = ['dutyOPUserName', 'saleUserName', 'outOPUserName'];
        const setFormByKey = (keys, val) => {
          return keys.reduce((acc, key) => {
            acc[key] = val;
            return acc;
          }, {});
        };
        this.form = {
          ...this.form,
          ...setFormByKey(idKeys, id),
          ...setFormByKey(nameKeys, realName)
        };
      }
    },
    handleNewProduct() {
      this.$router.push({
        path: '/resource/product-create',
        query: {
          type: 'create'
        }
      });
    },
    showCustomDialog() {
      this.$refs.customerRef.show();
    },
    showProductialog() {
      this.$refs.productRef.show();
    },
    // 选择团队类型
    selectTeamType(val) {
      const {
        label
      } = val;
      this.form.groupType = label;
    },
    selectCustom(data) {
      const keys = ['customerId', 'customerContactId', 'customerName', 'customerContactName', 'customerContactMobileNumber', 'balance', 'creditMoney'];
      const dataKey = {
        customerId: 'id',
        customerContactId: 'customerContactId',
        customerName: 'name',
        customerContactName: 'contact',
        customerContactMobileNumber: 'mobilePhone',
        balance: 'balance',
        creditMoney: 'creditMoney'
      };
      keys.map(key => {
        this.$set(this.form, key, data[dataKey[key]]);
      });
      // // 判断客户是否有区域经理，如果有的话业务员则默认为区域经理
      // if (data.customerAreaManagerId && data.customerAreaManagerId > 0) {
      //   this.form.saleUserName = data.customerAreaManager
      //   this.form.saleUserId = data.customerAreaManagerId
      // }
      if (data.saleUserId && data.saleUserId > 0) {
        this.form.saleUserName = data.saleUserName;
        this.form.saleUserId = data.saleUserId;
      } else {
        const {
          id,
          realName
        } = getUserData();
        this.form.saleUserName = realName;
        this.form.saleUserId = id;
      }
      // 重新请求客户授信额度
      this.$store.commit('order/index/CUR_CUSTOMER_ID', data.id);
      // 如果用户开启了自动生成返款的话
      this.$emit('refund-generate');
    },
    selectProduct(data) {
      const {
        id,
        name,
        days,
        productType
      } = data;
      this.$set(this.form, 'productId', id);
      this.$set(this.form, 'productName', name);
      this.$set(this.form, 'productDays', days);
      this.$set(this.form, 'productType', productType);
      this.calcTripEndDate();
    },
    changeCustomerName(val) {
      if (!val) {
        this.form.balance = '';
        this.form.creditMoney = '';
      }
    },
    // 保存当前表单的修改
    changeFormInput(data) {
      const copy = {
        ...data
      };
      setTimeout(() => {
        if (this.orderType === orderType.team) {
          if (this.type === 'clone' || this.type === 'update') {
            this.$store.commit('order/team/CLONE_FORM', copy);
          } else {
            this.$store.commit('order/team/EDIT_FORM', copy);
          }
        } else if (this.orderType === orderType.personal) {
          if (this.type === 'update' || this.type === 'clone') {
            this.$store.commit('order/personal/CLONE_FORM', copy);
          } else {
            this.$store.commit('order/personal/EDIT_FORM', copy);
          }
        }
      }, 800);
    },
    changeOPUser(type, val) {
      this.form[type + 'Name'] = val.label;
      this.form[type + 'Id'] = val.value;
    },
    changeProduct(val) {
      if (!val || val.trim() == '') {
        this.isUserChange = false;
        this.form.productId = '';
        this.form.productName = '';
        this.form.productDays = 0;
      }
    },
    // 修改省市区
    changeRegion(val) {
      // const cityObj = processCityChange(val)
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.form[key] = val[key];
      });
    },
    onSelectProductType(val) {
      this.form.productType = val.label;
    },
    changeStartDate(val) {
      this.form.startTripDate = val;
      this.calcTripEndDate();
    },
    changeEndDate(val) {
      this.$set(this.form, 'endTripDate', val);
    },
    // 计算结束日期
    calcTripEndDate() {
      let {
        productDays,
        productId,
        startTripDate
      } = this.form;
      let curDate = startTripDate;
      if (!productId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择产品线路'
        });
        return false;
      }
      if (!productDays) {
        this.getProductDays(productId).then(data => {
          const {
            days
          } = data;
          for (let i = 1; i < days; i++) {
            curDate = getNextDate(curDate);
          }
          this.form.endTripDate = curDate;
        });
      } else {
        for (let i = 1; i < productDays; i++) {
          curDate = getNextDate(curDate);
        }
        this.form.endTripDate = curDate;
      }
    },
    handleClone({
      startTripDate,
      customerOrderId
    }) {
      // 如果是复制订单  需要获取复制订单的行程信息
      const p1 = this.copyTripInfo({
        startTripDate,
        customerOrderId
      });
      const p2 = this.copyHotelInfo(customerOrderId);
      return Promise.all([p1, p2]);
    },
    // 生成行程
    generateTrip(customerOrderId, type) {
      const {
        productId,
        startTripDate,
        endTripDate
      } = this.form;
      const betweenDay = getDaysBetween(startTripDate, endTripDate);
      if (betweenDay <= 0) {
        this.$bus.tip({
          type: 'warning',
          message: '散团日期不能小于发团日期',
          offset: window.innerHeight / 2
        });
        return;
      }
      if (type == 'clone') {
        return this.handleClone({
          startTripDate,
          customerOrderId
        });
      } else {
        return this.getProduct({
          productId,
          customerOrderId,
          startTripDate,
          betweenDay
        }).then(data => {
          console.log('itemItinerary:', data);
          const itemItinerary = data.map(it => {
            if (it.scenics && it.scenics.length) {
              it.scenics = it.scenics.filter(item => item.scenicEnableStatus);
            }
            return it;
          });
          // 不需要删除行程，因为是第一次创建
          this.createOrUpdateItinerary(itemItinerary);
          // // 如果是新建的时候  根据产品信息生成酒店信息
          if (this.type == 'create') {
            const list = this.formatHotel(data, customerOrderId);
            if (!list.length) return false;
            return this.createOrUpdateHotel(list);
          }
        });
      }
    },
    hasUpdateTrip(id) {
      const doCreate = type => {
        this.clearLastItinerary(id).then(() => {
          this.generateTrip(id, type);
        });
      };
      if (this.type == 'clone') {
        this.generateTrip(id, 'clone');
      }
      const {
        productId,
        startTripDate,
        endTripDate
      } = this.newFormItem;

      // 对比产品ID， 产品不一样，清空重新添加
      if (productId && this.productId && productId !== this.productId) {
        doCreate('update');
        return;
      }
      const betweenDay = getDaysBetween(startTripDate, endTripDate);
      const productDay = getDaysBetween(this.startTripDate, this.endTripDate);

      // 对比开始日期
      if (startTripDate && startTripDate != this.startTripDate || betweenDay > productDay || this.endTripDate !== this.form.endTripDate) {
        this.$parent.updateTrip({
          id,
          startTripDate,
          endTripDate
        });
      }
    },
    handleUpdate() {
      this.createOrUpdate().then(data => {
        const {
          id,
          message
        } = data;

        // 是否需要更新行程
        this.hasUpdateTrip(id);
        this.isFirst = true;
        // 更新数据，下面外转需要同步更新日期
        this.$parent.fetchData(id);

        // 判断是不是修改内容需要订单审核流程
        if (message.indexOf('订单审核') != -1) {
          this.$alert(message, '温馨提示', {
            confirmButtonText: '好的',
            type: 'warning'
          });
        } else {
          this.$bus.tip();
        }
      });
    },
    createOrUpdate(addAuditStatus = OrderAuditEmit.NEW_WAIT) {
      return new Promise((resolve, reject) => {
        if (!this.handleValidate()) {
          return reject();
        }
        const {
          region,
          accompanyName,
          accompanyPhone
        } = this.form;
        const accompanyInfo = `${accompanyName || ''}/${accompanyPhone || ''}`;
        if (region.length == 1) {
          this.form.district = '';
          this.form.districtId = -1;
          this.form.city = '';
          this.form.cityId = -1;
        }
        if (region.length == 2) {
          this.form.district = '';
          this.form.districtId = -1;
        }
        const opts = {
          ...this.form,
          accompanyInfo
        };
        delete opts.region;
        delete opts.tripDate;
        if (this.type == 'clone') {
          delete opts.id;
        }
        this.$store.dispatch('order/index/createOrUpdate', {
          ...opts,
          addAuditStatus: addAuditStatus
        }).then(res => {
          const {
            id
          } = res.data;
          const {
            message
          } = res;
          // 刷新列表数据
          this.$store.dispatch('order/index/fetch', {
            type: 'team'
          });
          if (this.type == 'create' || this.type == 'clone') {
            this.generateTrip(id, this.type).then(() => {
              return resolve({
                ...res.data,
                customerId: opts.customerId,
                message
              });
            });
          } else {
            return resolve({
              ...res.data,
              customerId: opts.customerId,
              message
            });
          }
        });
      });
    },
    formatHotel(data, customerOrderId) {
      let tripList = [];
      if (!data || !data.length) return [];
      data = data.filter(it => it.area || it.standard);
      tripList = data.map(it => {
        const item = {
          customerOrderId,
          customerId: this.form.customerId,
          count: this.form.adultCount / 2,
          hotelArea: it.area,
          hotelName: it.hotelName,
          hotelId: it.hotelId,
          orderDate: it.itineraryDate,
          hotelStandard: it.standard
        };
        return item;
      });
      return tripList;
    },
    formatProduct({
      data,
      customerOrderId,
      startTripDate,
      betweenDay
    }) {
      const {
        itineraries
      } = data;
      let curDate = startTripDate;
      let tripList = [];
      for (let i = 0; i < betweenDay; i++) {
        const item = itineraries[i];

        // 如果日期没有超出行程
        if (item) {
          delete item.id;
          item.itineraryDate = curDate;
          item.customerOrderId = customerOrderId;
          item.rooms = [roomsState()];
          item.transports = [];
          tripList.push(item);
        } else {
          tripList.push({
            isPick: false,
            isSend: false,
            itineraryDate: curDate,
            customerOrderId,
            rooms: [roomsState()],
            transports: []
          });
        }
        curDate = getNextDate(curDate);
      }
      return tripList;
    },
    mergeId(list, id) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.itineraryId = id;
        return it;
      });
    },
    clearEditForm() {
      if (this.orderType === orderType.team) {
        this.$store.commit('order/team/EDIT_FORM', null);
        this.$store.commit('order/team/CLONE_FORM', null);
      } else {
        this.$store.commit('order/personal/EDIT_FORM', null);
        this.$store.commit('order/personal/CLONE_FORM', null);
      }
    },
    //重置表单
    resetFromData() {
      this.form = {
        ...defaultState(),
        orderType: this.orderType
      };
      this.getUserData(this.userData);
    }
  }
};