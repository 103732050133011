// 按团号付酒店
import { DEFAULT_PAGE } from '@/config';
import { fetchFleet } from '@/api/bill/billCheck';
import { formatTableItem } from '@/utils/data';
import { TYPES } from '@/config/billDown';
const formatData = data => {
  return formatTableItem({
    list: data,
    lkey: 'busCompanyTypeSimples',
    tkey: 'checkAccountTime'
  });
};
const dfaultState = () => ({
  data: null,
  list: []
});
const state = dfaultState();
const getters = {
  data: state => state.data,
  list: state => state.list,
  total: state => state.data ? state.data.totalCount : 0
};
const actions = {
  // 获取列表
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
      // planType: PlanType.unComplete,
    };
    return fetchFleet(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      state.list = formatData(data.list);
      commit('SET_DATA', data);
      return data;
    });
  },
  // 获取列表
  // eslint-disable-next-line no-empty-pattern
  fetchOne({}, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
      // planType: PlanType.unComplete,
    };
    return fetchFleet(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = formatData(data.list);
      return data;
    });
  },
  fetchBalance(rid) {
    return this.dispatch('bill/billDown/pay/fetchPayBalance', {
      rid,
      cashType: TYPES.bus_company
    });
  }
};
const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};