import { StatusAudit } from '@/components/common';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin } from '@/mixin';
import { getSelectLabel } from '@/config/select';
export default {
  components: {
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin],
  data() {
    return {
      tableData: [],
      mergeTable: false,
      totalMoney: 0,
      // 总金额
      rowId: 1000000000000,
      statisticsKeys: ['guideServiceFee'],
      planDataInfo: {}
    };
  },
  computed: {
    ...mapGetters({
      guideManageData: 'resource/guideManager/guideData',
      currentPlanId: 'plan/index/currentPlanId'
    }),
    getGuideManageName() {
      return id => {
        if (!this.guideManageData) return;
        const item = this.guideManageData.find(it => it.id === id);
        return item && item.label;
      };
    }
  },
  mounted() {
    this.fetchData();
    // this.getPlanInfo(this.currentPlanId)
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    // 获取团信息
    getPlanInfo(planId) {
      this.$store.dispatch('plan/index/fetchPlanById', planId).then(res => {
        this.planDataInfo = res;
      });
    },
    // 获取数据
    fetchData() {
      this.$store.dispatch('plan/guide/fetch', {
        planId: this.currentPlanId || this.$route.query.id
      }).then(data => {
        this.tableData = this.formatData(data);
      });
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          guidePlanId: id,
          ap: auditProgress,
          settlementMethod: getSelectLabel('settleType', it.settlementMethod),
          auditProgress
        };
        this.rowId++;
        return item;
      });
    }
  }
};