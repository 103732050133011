import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { SelectDictionary, SelectScenic, SelectHotel, SelectShop, SelectScenicFree } from '@/components/common';
import { mapGetters } from 'vuex';
import { hasShopSelectData } from '@/utils/requestSelectData';
const itemListKeys = () => ({
  accompanyCount: 0,
  accompanyPrice: 0,
  adultCountReceive: 0,
  childCountReceive: 0,
  adultCount: 0,
  adultFreeCount: 0,
  childCount: 0,
  childPrice: 0,
  freeTotal: 0,
  otherFee: 0,
  price: 0,
  sellPrice: 0,
  settlementPrice: 0,
  ticketList: null,
  ticketType: '成人票'
});
// 景区同步默认构建数据
const defaultState = () => ({
  isCustom: true,
  // 删除数据用来判断类型
  planId: '',
  scenicId: '',
  totalPay: 0,
  remark: '',
  orderDate: '',
  accompanyRate: 0,
  rowTotal: 0,
  scenicOTickets: []
});
const defaultScenicState = () => ({
  scenicId: 0,
  scenicName: '',
  isAdd: true
});
const defaultShopState = () => ({
  shopId: '',
  shopName: ''
});
export default {
  components: {
    SelectDictionary,
    SelectScenic,
    SelectHotel,
    SelectShop,
    SelectScenicFree
  },
  data() {
    return {
      tableData: [],
      hotelStandard: 'hotelStandardType',
      hotelRegion: 'hotelRegionType',
      daysData: [],
      isNew: true,
      visible: false,
      removeShops: [],
      removeScenics: [],
      url: {
        s_tourist: '/order/personal-input-update',
        t_tourist: '/order/team-input-update'
      }
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId',
      currentOrder: 'order/index/currentOrder',
      currentItinerary: 'order/index/currentItinerary',
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData',
      orderScenices: 'order/index/orderScenices',
      orderItineraryJson: 'order/index/orderItineraryJson'
    }),
    hasCheckbox() {
      return index => {
        return index == 0 ? true : index == this.tableData.length - 1 ? true : false;
      };
    },
    getName() {
      return index => {
        return index == 0 ? '接团' : index == this.tableData.length - 1 ? '送团' : '';
      };
    },
    hasMoreScenic() {
      return item => {
        return item.scenics.length > 0;
      };
    },
    getMoreScenic() {
      return item => {
        return item.scenics.length > 1 && item.scenics.slice(1);
      };
    },
    hasMoreShop() {
      return item => {
        return item.shops.length > 1;
      };
    },
    getMoreShop() {
      return item => {
        return item.shops.length > 1 && item.shops.slice(1);
      };
    }
  },
  mounted() {
    this.$bus.on('show:itinerary', this.show);
    // 是否需要加载购物店下拉数据
    hasShopSelectData();
  },
  methods: {
    show(data) {
      this.visible = true;
      this.tableData = data;
      // this.$bus.emit('scenic:reload')
      this.removeShops = [];
      this.removeScenics = [];
    },
    handleCancel() {
      this.$parent.fetchData();
    },
    hide() {
      this.visible = false;
    },
    getCodeType(type) {
      return this.dictionaryCodeData[type] && this.dictionaryCodeData[type].agencyDictionaryList;
    },
    changeDaysData(data) {
      this.$emit('on-change', data);
    },
    handleChange(type, item, val) {
      item[type] = val;
    },
    handleSelectShop(index, item, val) {
      const {
        label,
        value
      } = val;
      item.shops[index].shopId = value;
      item.shops[index].shopName = label;
      if (!label || !value) {
        delete item[index].shops;
      }
    },
    handleSelectScenic(index, item, val) {
      const {
        label,
        value
      } = val;
      item.scenics[index].scenicId = value;
      item.scenics[index].scenicName = label;
      item.scenics[index].isAdd = true;
      if (!label || !value) {
        delete item[index].scenics;
      }
      if (item.scenics[index].scenicType == 'PAID') {
        this.editScenic(index, item);
      }
    },
    //编辑景区或者日期时把原有的景区添加进删除数组
    editScenic(index, item) {
      const itineraryList = JSON.parse(this.orderItineraryJson);
      const {
        itineraryDate,
        id: rowId
      } = item;
      const itineraryScenicId = item.scenics[index].id;
      const itinerary = itineraryList.find(it => it.id == rowId);
      const scenicItem = itinerary.scenics.find(it => it.id == itineraryScenicId);
      if (scenicItem && itineraryScenicId == scenicItem.id) {
        const item = {
          scenicId: scenicItem.scenicId,
          scenicName: scenicItem.scenicName,
          orderDate: itineraryDate,
          isDelete: true
        };
        this.removeScenics.push(item);
        this.removeScenics = this.reduceDistinct(this.removeScenics);
      }
    },
    // 根据日期和景区id相同去重
    reduceDistinct(list) {
      if (!list || !list.length) return;
      let res = list.filter(function (item, index, self) {
        return self.findIndex(it => it.scenicId == item.scenicId && it.orderDate == item.orderDate) === index;
      });
      return res;
    },
    selecthotelRegion(item, val) {
      const {
        label
      } = val;
      item.area = label;
      this.changeArrangeState(item);
    },
    selectHotelStandard(item, val) {
      const {
        label,
        value
      } = val;
      item.standard = label;
      item.standardId = value;
      this.changeArrangeState(item);
    },
    // 判断是否需要订单
    changeArrangeState(item) {
      if (item.standard || item.area) {
        item.arrangeState = true;
      } else {
        item.arrangeState = false;
      }
    },
    selectHotelName(item, val) {
      item.hotelId = val.value;
      item.hotelName = val.label;
    },
    addScenic(item, type) {
      item.whichDay++;
      item.scenics.push({
        ...defaultScenicState(),
        orderNumber: item.scenics.length,
        scenicType: type
      });
    },
    // 删除行程景区
    deleteScene(item) {
      this.$set(item, 'isDelete', true);
      if (!item.id) {
        this.$set(item, 'scenicId', -1);
      }
    },
    addShop(item) {
      item.shops.push({
        ...defaultShopState(),
        orderNumber: item.shops.length
      });
    },
    deleteShop(item) {
      this.$set(item, 'isDelete', true);
      if (!item.id) {
        this.$set(item, 'shopId', -1);
      }
    },
    handleCreate() {
      const {
        path
      } = this.$route;
      const dataList = this.tableData.map(it => {
        it.arrangeState = !it.arrangeState ? 2 : 0;
        // 清空掉没有id或id是-1 的景区和购物店
        it.scenics = it.scenics.filter(item => item.scenicId > 0);
        it.shops = it.shops.filter(item => item.shopId > 0);
        return it;
      });
      if (path == this.url.s_tourist) {
        this.createOrUpdateItinerary(dataList);
      } else {
        this.updateAll(dataList);
      }
    },
    createOrUpdateItinerary(opts) {
      this.$store.dispatch('order/index/createOrUpdateItinerary', opts).then(() => {
        this.$parent.fetchData();
        this.hide();
      });
    },
    // 匹配删除景区的数据
    removeMatch(list) {
      if (!list || !list.length) return [];
      const result = [];
      let obj = {};
      list.forEach(it => {
        const item = this.orderScenices.find(item => item.scenicId == it.scenicId && item.orderDate == it.orderDate);
        if (item) result.push({
          ...item,
          isDelete: true
        });
      });
      // 根据id 去重
      return result.reduce(function (item, next) {
        //item为没有重复id的数组，next为当前对象
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
      }, []);
    },
    reduce() {
      let obj = {};
      return this.arr.reduce(function (item, next) {
        //item为没有重复id的数组，next为当前对象
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
      }, []);
    },
    // 同步景区重构
    beforeScenicCommit(list, typelist) {
      if (!list || !list.length) return [];
      let result = [];
      let addScenic = [];
      let deleteScenic = [];
      const {
        adultCount
      } = this.currentOrder;
      list.forEach(it => {
        it.scenics.forEach(item => {
          // 新增
          if (item.isAdd && item.scenicType == 'PAID') {
            const resourceType = typelist.find(z => z.scenicId == item.scenicId);
            const v = {
              ...defaultState(),
              // ...item,
              scenicId: item.scenicId,
              scenicName: item.scenicName,
              orderDate: it.itineraryDate,
              customerOrderId: it.customerOrderId,
              scenicOTickets: [{
                ...itemListKeys(),
                ticketType: resourceType ? resourceType.contentType : '成人票',
                price: resourceType ? resourceType.quotedPrice : 0,
                settlementPrice: resourceType ? resourceType.price : 0,
                adultCount
              }]
            };
            addScenic.push(v);
          }
          // 删除
          if (item.isDelete && item.scenicType == 'PAID') {
            const z = {
              scenicId: item.scenicId,
              scenicName: item.scenicName,
              orderDate: it.itineraryDate
            };
            deleteScenic.push(z);
          }
        });
      });
      const removeList = this.removeMatch(deleteScenic);
      // 切换编辑的行程景区
      const removes = this.removeMatch(this.removeScenics);
      result = [...result, ...addScenic, ...removeList, ...removes];
      return result;
    },
    // 景区资源协议获取
    fetchResourcesType(list) {
      const resourceIds = [];
      list.forEach(it => {
        it.scenics.forEach(v => {
          if ((v.isCustom || v.isAdd) && v.scenicType == 'PAID') {
            resourceIds.push(v.scenicId);
          }
        });
      });
      if (!resourceIds.length) return Promise.resolve([]);
      const opts = {
        resourceIds: resourceIds.toString(),
        resourceType: 'scenic'
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/common/fetchResourcesType', {
          ...opts,
          resourceType: 'scenic'
        }).then(res => {
          const keys = ['quotedPrice', 'price', 'contentType'];
          const types = res.map(it => {
            const item = {
              scenicId: it.resourceId,
              scenicName: it.unit
            };
            let content = it.contractPrices.find(f => f.contentType.indexOf('成人票') != -1);
            content = content ? content : it.contractPrices[0];
            keys.forEach(key => {
              item[key] = content ? content[key] : 0;
              item.contentType = content ? content[key] : '成人票';
            });
            return item;
          });
          resolve(types);
        }).catch(() => {
          reject();
        });
      });
    },
    updateAll(list) {
      this.fetchResourcesType(list).then(res => {
        let scenicOS = this.beforeScenicCommit(list, res);
        if (!scenicOS || !scenicOS.length) {
          scenicOS = [];
        }
        const update = {
          ...this.currentOrder,
          reqItinerarys: list,
          scenicOS
        };
        this.$store.dispatch('order/index/createOrUpdateAll', update).then(() => {
          this.$bus.emit('scenic:reload');
          this.$parent.fetchData();
          this.hide();
        });
      });
    }
  }
};