import { auditMixin } from '@/mixin';
export default {
  mixins: [auditMixin],
  props: {
    checkval: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue: {
      get() {
        return this.checkval;
      },
      set(val) {
        this.$emit('update:checkval', val);
      }
    }
  },
  methods: {
    // 全部选中
    checkAuditAll(val) {
      // this.auditAll = val
      this.$emit('on-check', val);
    }
  }
};