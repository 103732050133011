var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reduction"
  }, [_c("div", {
    staticClass: "reduction-wrapper"
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("el-input", _vm._b({
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form[0].freeCountMinRequire,
      callback: function ($$v) {
        _vm.$set(_vm.form[0], "freeCountMinRequire", _vm._n($$v));
      },
      expression: "form[0].freeCountMinRequire"
    }
  }, "el-input", _vm.$attrs, false)), _c("span", [_vm._v("免")]), _c("el-input", _vm._b({
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form[0].freeCount,
      callback: function ($$v) {
        _vm.$set(_vm.form[0], "freeCount", _vm._n($$v));
      },
      expression: "form[0].freeCount"
    }
  }, "el-input", _vm.$attrs, false)), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addRow
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };