var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "消费明细",
      width: "1200px",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      border: ""
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };