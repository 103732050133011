var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            "init-val": scope.row.reviewerLabel,
            type: "reviewerList"
          },
          on: {
            "on-select": function ($event) {
              return _vm.changeRole(scope.row, $event);
            }
          },
          model: {
            value: scope.row.reviewerLabel,
            callback: function ($$v) {
              _vm.$set(scope.row, "reviewerLabel", $$v);
            },
            expression: "scope.row.reviewerLabel"
          }
        })];
      }
    }, {
      key: "reviewer",
      fn: function ({
        scope
      }) {
        return [scope.row.reviewerType == "ROLE" ? _c("SelectRole", {
          on: {
            "on-select": function ($event) {
              return _vm.changeReviewer(scope.row, $event);
            }
          },
          model: {
            value: scope.row.reviewerId,
            callback: function ($$v) {
              _vm.$set(scope.row, "reviewerId", $$v);
            },
            expression: "scope.row.reviewerId"
          }
        }) : _vm._e(), scope.row.reviewerType == "USER" ? _c("SelectStaff", {
          on: {
            "on-select": function ($event) {
              return _vm.changeReviewer(scope.row, $event);
            }
          },
          model: {
            value: scope.row.reviewerId,
            callback: function ($$v) {
              _vm.$set(scope.row, "reviewerId", $$v);
            },
            expression: "scope.row.reviewerId"
          }
        }) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };