import { ORDER_TEAM_FEE_CONFIRM_KEY as CODE_KEY, defaultOption, Columns } from '../config';
import { DialogTeamFee } from './index';
export default {
  components: {
    DialogTeamFee
  },
  data() {
    return {
      Columns,
      allowMergeTeam: false,
      // 团款合理才能确认成团状态
      tableData: [],
      configId: ''
    };
  },
  created() {
    this.fetchSysConfig();
  },
  methods: {
    // 获取对接配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params).then(res => {
        if (!res || !res.data) return;
        this.processRuleData(res.data);
      });
    },
    processRuleData(data) {
      const findItem = data.find(it => it.code === CODE_KEY);
      if (!findItem) return;
      const {
        id,
        value,
        value1
      } = findItem;
      this.configId = id;
      this.allowMergeTeam = !!+value1;
      if (findItem && value) {
        this.tableData = JSON.parse(value);
        this.tableData.sort((a, b) => a.orderBy - b.orderBy);
      }
    },
    addItem() {
      this.$refs.dialogTeamFeeRef.show(this.configId, this.tableData, this.allowMergeTeam);
    },
    editItem(row) {
      const list = this.tableData.filter(it => it != row);
      this.$refs.dialogTeamFeeRef.edit({
        ...row,
        id: this.configId
      }, list, this.allowMergeTeam);
    },
    processOption(data) {
      return {
        ...defaultOption(),
        id: this.configId,
        value: JSON.stringify(data),
        value1: +this.allowMergeTeam
      };
    },
    delItem(row) {
      const data = this.tableData.filter(it => it != row);
      const opt = this.processOption(data);
      this.$bus.open(() => {
        this.$store.dispatch('system/buttJoint/setConfig', [opt]).then(() => {
          this.$bus.tip();
          this.fetchSysConfig();
        });
      });
    },
    updateConfigStatus(val) {
      this.tableData = this.tableData.map(it => {
        it.isEnable = val;
        return it;
      });
      const opt = this.processOption(this.tableData);
      this.$store.dispatch('system/buttJoint/setConfig', [opt]);
    }
  }
};