var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", {
    staticClass: "ticket-bill-notify"
  }, [_c("div", {
    staticClass: "ticket-bill-notify__container"
  }, [_c("div", {
    staticClass: "ticket-top-header"
  }, [_c("strong", {
    staticClass: "ticket-top-header__title fl"
  }, [_vm._v(" 单据查看 ")]), _c("div", {
    staticClass: "ticket-top-header__control fr"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-c-scale-to-original"
    },
    on: {
      click: function ($event) {
        return _vm.handleTransfer("left");
      }
    }
  }), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-download"
    },
    on: {
      click: function ($event) {
        return _vm.handleTransfer("down");
      }
    }
  }), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-full-screen"
    },
    on: {
      click: function ($event) {
        return _vm.handleTransfer("full");
      }
    }
  }), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-copy-document"
    },
    on: {
      click: function ($event) {
        return _vm.handleTransfer("new");
      }
    }
  }), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-close"
    },
    on: {
      click: function ($event) {
        return _vm.handleTransfer("close");
      }
    }
  })], 1)], 1)]), _vm.getImageList.length ? _c("div", {
    staticClass: "ticket-top-content"
  }, _vm._l(_vm.getImageList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "sign-img-box"
    }, [_c("el-image", {
      attrs: {
        src: item,
        fit: "scale-down",
        "preview-src-list": _vm.getImageList
      }
    })], 1);
  }), 0) : _c("Empty")], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };