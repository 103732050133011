export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  computed: {
    getLogo() {
      return this.logo ? this.logo : '';
    }
  }
};