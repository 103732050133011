import request from '@/utils/request';

// 客户应收统计表-渠道版
export function customRequest(params) {
  const url = '/order/report2/report-customer-fee-static-channel';
  return request({
    url,
    method: 'get',
    params
  });
}

// 应收明细汇总表-渠道版
export function detailRequest(params) {
  const url = '/order/report2/report-customer-fee-details-static-channel';
  return request({
    url,
    method: 'get',
    params
  });
}

// 应收余额月汇总表-渠道版
export function balanceMonthRequest(params) {
  const url = '/order/report2/report-customer-fee-balance-static-month-channel';
  return request({
    url,
    method: 'get',
    params
  });
}

// 应收余额年汇总表-渠道版
export function balanceYearRequest(params) {
  const url = '/order/report2/report-customer-fee-balance-static-channel';
  return request({
    url,
    method: 'get',
    params
  });
}