import { mapGetters } from 'vuex';
import { findComponentsUpward } from '@/utils';
export default {
  data() {
    return {
      visible: false,
      isUpdate: false,
      index: -1,
      form: {},
      customerId: -1
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      editAccount: 'resource/account/editAccountInfo' // 账户信息
    }),
    getTitle() {
      return this.isUpdate ? '更新联系人' : '添加联系人';
    }
  },
  methods: {
    edit(data) {
      this.visible = true;
      this.isUpdate = true;
      const {
        contact,
        id,
        mobilePhone,
        telphone,
        faxNumber
      } = data;
      this.customerId = data.customerId;
      this.form = {
        contact,
        mobilePhone,
        telphone,
        faxNumber,
        id
      };
    },
    show(id) {
      this.form = {};
      this.visible = true;
      this.isUpdate = false;
      this.customerId = id;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    handleCreate() {
      const opts = {
        ...this.form,
        customerId: this.customerId
      };
      this.createOrUpdateContact(opts);
    },
    // 新增或更新客户联系人
    createOrUpdateContact(opts) {
      const findComp = findComponentsUpward(this, 'CustomTabel')[0];
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/custom/createOrUpdateContact', opts).then(() => {
        this.hide();
        findComp.initData();
        loading.close();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};