import FormTicket from './FormTicket';
import TableList from './TableList';
import { BtnCreate, DialogDetail, DialogAgreement, DialogLog } from '@/components/common';
import { searchMixin } from '@/mixin';
import { resourceTickete } from '@/config/resource';
import { formatTableData } from '@/utils/data';
export default {
  components: {
    BtnCreate,
    DialogDetail,
    DialogAgreement,
    DialogLog,
    FormTicket,
    TableList
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    columnData: resourceTickete,
    tableData: [],
    total: 0,
    searchParams: {
      name: ''
    }
  }),
  computed: {
    ticketManageData() {
      return this.$store.getters['ticket/index/ticketManageData'];
    }
  },
  watch: {
    ticketManageData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleDate(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.tableLoading = true;
      this.$store.dispatch('ticket/index/fetch', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleDate(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    createOrUpdate(opts) {
      this.$store.dispatch('ticket/index/createOrUpdate', opts).then(res => {
        this.$bus.loading().close();
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        // 如果index > 0
        this.fetchData();
        this.hideCreateDialog();
      });
    },
    deleteScenic(id) {
      this.$store.dispatch('ticket/index/delete', id).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.fetchData();
      });
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    showCreateDialog(item) {
      this.$refs.formRef.show(item);
    },
    hideCreateDialog() {
      this.$refs.formRef.hide();
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteScenic(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showCreateDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row);
    },
    showLog(scope) {
      const {
        id
      } = scope.row;
      this.$store.dispatch('ticket/index/fetchLog', id).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};