import { Shopping } from '@/components/common/Order';
import { planEditShopping, planEditShoppingOld } from '@/config/plan';
import { themeMixin, systemMixin } from '@/mixin';
export default {
  components: {
    Shopping
  },
  mixins: [themeMixin, systemMixin],
  data() {
    return {
      visibility: false,
      columnData: []
    };
  },
  watch: {
    versionSwitch: {
      handler: function handler(state) {
        this.visibility = false;
        this.columnData = state ? planEditShopping : planEditShoppingOld;

        // 是否显示订单信息
        const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
        if (!isOrderInfo) {
          this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
        }
        setTimeout(() => {
          this.visibility = true;
        }, 500);
      },
      immediate: true
    }
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};