import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
// import { uniqueArr } from '@/utils'

export default {
  data() {
    return {
      mergeTable: true,
      checkedAll: false,
      removeCache: [],
      deletePath: 'plan/hotel/delete'
    };
  },
  computed: {
    getPlanStatus() {
      return this.$store.getters['plan/index/planTabStatus'];
    }
  },
  methods: {
    // 检查数据长度
    toggleDataLenStatus() {
      const hasLen = this.tableData.length > 0;
      const key = this.hasDataStatusKey;
      this.getPlanStatus[key] = hasLen;
      this.$store.commit('plan/index/PLAN_TAB_STATUS', this.getPlanStatus);
    },
    handleAdaptPlus() {
      this.handlePlus();
      this.toggleDataLenStatus();
    },
    // 选择单个
    handleCheckOne(row, val) {
      const {
        rowId: id,
        isCustom
      } = row;
      if (val) {
        const {
          isRead,
          checkAccountStatus,
          status
        } = row;
        if (!isRead && !checkAccountStatus && !status) {
          // 非禁用
          this.removeCache.push({
            id,
            isCustom
          });
        }
        this.checkSelectAll();
      } else {
        this.removeCache = this.removeCache.filter(it => it.id !== id);
        this.checkedAll = false;
      }
    },
    // 选择全部
    handleCheckAll(val) {
      this.checkedAll = val;
      if (val) {
        this.tableData.forEach(it => {
          const {
            isRead,
            checkAccountStatus,
            status
          } = it;
          if (!isRead && !checkAccountStatus && !status) {
            // 非禁用
            const {
              rowId: id,
              isCustom,
              cardType,
              cardNumber
            } = it;
            this.removeCache.push({
              id,
              isCustom,
              cardType,
              cardNumber
            });
          }
        });
        this.tableData = this.changeCheck(true);
      } else {
        this.tableData = this.changeCheck(false);
        this.removeCache = [];
      }
    },
    // 删除单条
    async handleRemove(row, isBatch = true) {
      const {
        rowId: id,
        isCustom
      } = row;
      const opts = isBatch ? [id] : id;
      if (isCustom) {
        await this.handleLocalRemove(opts);
        return;
      }
      this.$bus.open(() => {
        this.handleRomoteRemove(opts);
      });
    },
    // 批量删除
    handleRemoveAll(isBatch = true) {
      if (this.removeCache.length <= 0) {
        this.$bus.tip({
          type: 'warning',
          message: '请勾选要删除的数据'
        });
        return;
      }
      this.$bus.open(() => {
        let customIds = [];
        let remoteIds = [];

        // 批量删除的接口
        if (isBatch) {
          this.removeCache.forEach(it => {
            if (!it) return;
            if (it.isCustom) {
              customIds.push(it.id);
            } else {
              remoteIds.push(it.id);
            }
          });
          this.handleLocalRemove(customIds);
          this.handleRomoteRemove([...new Set(remoteIds)]);
        } else {
          // 单独删除的接口
          this.removeCache.map(it => {
            if (it.isCustom) {
              this.handleLocalRemove(it.id);
            } else {
              this.handleRomoteRemove([it.id]);
            }
          });
        }
        this.checkedAll = false;
        this.removeCache = [];
        this.tableData = [];
      });
    },
    // 本地删除
    handleLocalRemove(ids) {
      if (!ids || !ids.length) return;

      // return Promise.resolve(() => {
      this.tableData = this.tableData.filter(it => !ids.includes(it.rowId));
      // 如果先选中复选框，再删除的话，会出现问题，对应同步缓存的数组
      this.removeCache = this.removeCache.filter(it => !ids.includes(it.id));
      this.$nextTick(() => {
        this.mergeTable && this.rowspan();

        // 切换灯泡状态
        this.toggleDataLenStatus();
      });
      // })
    },
    // 远程删除
    handleRomoteRemove(ids) {
      if (!ids.length) return;
      return this.$store.dispatch(this.deletePath, ids).then(() => {
        this.reloadData();
        // 切换灯泡状态
        this.toggleDataLenStatus();
        this.$bus.tip();
      }).catch(() => {
        this.reloadData();
      });
    },
    changeCheck(flag) {
      return this.tableData.map(it => {
        const {
          isRead,
          checkAccountStatus,
          status
        } = it;
        if (!isRead && !checkAccountStatus && !status) {
          it.isChecked = flag;
        } else {
          it.isChecked = false;
        }
        return it;
      });
    },
    checkSelectAll() {
      this.checkedAll = this.removeCache.length === this.tableData.length;
    }
  }
};