import { tableMixin } from '@/mixin';
import offerMixin from '../offerMixin';
// import Clipboard from 'clipboard'
// import handleClipboard from '@/utils/clipboard'

export default {
  mixins: [tableMixin, offerMixin],
  data() {
    return {
      codeText: '',
      form: {}
    };
  },
  methods: {
    onAnewOffer(row) {
      const {
        id
      } = row;
      this.$parent.handleEdit({
        id
      });
    },
    onHistory(row) {
      const {
        code
      } = row;
      this.$parent.handleHistory({
        code
      });
    },
    handleShare(row) {
      const {
        id
      } = row;
      this.$parent.handleShare({
        id
      });
    },
    handleSharaCopy(row) {
      const {
        id
      } = row;
      this.fetchOfferId({
        id
      }).then(data => {
        this.form = data;
        this.codeText = this.formatOfferCode(data);

        // let copy_text = this.codeText //拿到想要复制的值
      });
    },
    codeOffer({
      id
    }) {
      this.$parent.handleCode({
        id
      });
      // this.fetchOfferId({ id }).then(data => {
      //   delete data.id
      //   data.name = data.name + 1
      //   this.updateOffer(data)
      // })
    },
    // 保存
    updateOffer(opts) {
      this.$store.dispatch('offer/index/updateOffer', opts).then(() => {
        this.$bus.tip();
        this.$parent.handleSearch();
      });
    },
    onConfirm(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.updateOfferStatus({
          id,
          status: 1
        });
      }, `确认报价成功？`);
    },
    updateOfferStatus(opts) {
      this.$store.dispatch('offer/index/updateOfferStatus', opts).then(() => {
        this.$bus.tip();
        this.$parent.handleSearch();
      });
    }
  }
};