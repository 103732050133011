var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "contactName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.contactName) + " ")];
      }
    }, {
      key: "orders",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "adultCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + " ")];
      }
    }, {
      key: "childCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.childCount) + " ")];
      }
    }, {
      key: "accompanyCount",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.accompanyCount) + " ")];
      }
    }, {
      key: "totalIncome",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalIncome) + " ")];
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.checkAccountMoney) + " ")];
      }
    }, {
      key: "totalIncomed",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalIncomed) + " ")];
      }
    }, {
      key: "collect",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.collect) + " ")];
      }
    }, {
      key: "subsidy",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.subsidy) + " ")];
      }
    }, {
      key: "unIncome",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.unIncome) + " ")];
      }
    }, {
      key: "checks",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.checks) + "/" + _vm._s(scope.row.orders) + " ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onChecksLink(scope.row, "checks");
            }
          }
        }, [_vm._v(" 对账 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onChecksLink(scope.row, "unit");
            }
          }
        }, [_vm._v(" 收款 ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };