import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import TableItinerary from './TableItinerary';
import DialogProduct from './DialogProduct';
import { getNextDate, getDateBetween } from '@/utils';
export default {
  components: {
    TableItinerary,
    DialogProduct
  },
  props: {
    orderType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      form: {},
      data: {}
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.app.device === 'mobile';
    }
  },
  mounted() {},
  methods: {
    show(data) {
      const {
        productId,
        productName,
        startTripDate,
        endTripDate
      } = data;
      this.data = data;
      this.form = {
        productId,
        productName,
        startTripDate,
        endTripDate
      };
      this.form.days = getDateBetween(startTripDate, endTripDate).length;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.data = {};
      this.$parent.handleSearch();
    },
    selectProduct(data) {
      const {
        id,
        name,
        days
      } = data;
      this.$set(this.form, 'productId', id);
      this.$set(this.form, 'productName', name);
      this.$set(this.form, 'productDays', days);
      this.calcTripEndDate();
    },
    handleNewProduct() {
      this.$router.push({
        path: '/resource/product-create',
        query: {
          type: 'create'
        }
      });
    },
    showProductialog() {
      this.$refs.productRef.show();
    },
    changeStartDate(val) {
      this.form.startTripDate = val;
      const {
        startTripDate,
        days
      } = this.form;
      let curDate = startTripDate;
      for (let i = 1; i < days; i++) {
        curDate = getNextDate(curDate);
      }
      this.$set(this.form, 'endTripDate', curDate);
    },
    changeEndDate(val) {
      this.$set(this.form, 'endTripDate', val);
      const dateLen = getDateBetween(this.form.startTripDate, val);
      if (this.form.days < dateLen.length) {
        const list = dateLen.map((date, index) => {
          const item = {
            area: '',
            itineraryDate: date,
            scenics: [],
            rooms: [],
            shops: [],
            transports: [],
            customerOrderId: this.data.id,
            hotelId: 0,
            hotel: '',
            isPick: false,
            isSend: false,
            arrangeState: 0,
            breakfast: false,
            dinner: false,
            lunch: false,
            standard: ''
          };
          if (index == dateLen.length - 1) {
            item.isSend = true;
          }
          return item;
        });
        this.fetchItinerary(this.data.id).then(res => {
          let Itinerary = [...res, ...list];
          let obj = {};
          Itinerary = Itinerary.reduce((cur, next) => {
            obj[next.itineraryDate] ? '' : obj[next.itineraryDate] = true && cur.push(next);
            return cur;
          }, []);
          this.createOrUpdateItinerary(Itinerary);
        });
      }
      if (this.form.days > dateLen.length) {
        this.fetchItinerary(this.data.id).then(res => {
          let list = res.slice(0, dateLen.length);
          list = list.map((it, index) => {
            if (index == list.length - 1) {
              it.isSend = true;
            }
            return it;
          });
          this.clearLastItinerary(this.data.id).then(() => {
            const item = this.formatItineraryData(list, this.form.startTripDate);
            this.createOrUpdateItinerary(item);
          });
        });
      }
    },
    // 计算结束日期
    calcTripEndDate() {
      let {
        productId,
        startTripDate
      } = this.form;
      let curDate = startTripDate;
      if (!productId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择产品线路'
        });
        return false;
      }
      this.getProductDays(productId).then(data => {
        const {
          days
        } = data;
        for (let i = 1; i < days; i++) {
          curDate = getNextDate(curDate);
        }
        this.form.endTripDate = curDate;
        if (this.form.productId != data.productId) {
          this.clearLastItinerary(this.data.id).then(() => {
            const itineraryList = this.formatItineraryData(data.itineraries, startTripDate);
            this.createOrUpdateItinerary(itineraryList);
          });
        }
      });
    },
    formatItineraryData(list, curDate) {
      if (!list.length) return [];
      return list.map((item, index) => {
        item.customerOrderId = this.data.id;
        if (index != 0) {
          curDate = getNextDate(curDate);
        }
        item.whichDay = index + 1;
        item.itineraryDate = curDate;
        delete item.id;
        item.scenics.forEach(it => {
          delete it.itineraryId;
          delete it.id;
          return it;
        });
        item.shops.forEach(it => {
          delete it.itineraryId;
          delete it.id;
          return it;
        });
        item.transports = [];
        item.rooms = [];
        return item;
      });
    },
    // 清理上一次的行程
    clearLastItinerary(id) {
      return this.$store.dispatch('order/index/delItineraryByOrderId', id);
    },
    createOrUpdateItinerary(data) {
      this.$store.dispatch('order/index/createOrUpdateItinerary', data).then(() => {
        this.$parent.createOrUpdate({
          ...this.data,
          ...this.form
        });
        this.$refs.tableItineraryRef.fetchData();
      });
    },
    // 产品id获取产品信息
    getProductDays(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/product/fetchDetail', id).then(res => {
          resolve(res);
        }).catch(() => {
          reject();
        });
      });
    },
    // 获取订单行程
    fetchItinerary(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/index/fetchItinerary', id).then(data => {
          this.form.days = data.length;
          resolve(data);
        }).catch(() => {
          reject();
        });
      });
    },
    handleCreate() {
      // this.$parent.createOrUpdate(this.form)
      this.hide();
    }
  }
};