import { parseTime } from '@/utils';
const Columns = [{
  attrs: {
    prop: 'effectiveStartDate',
    label: '生效时间'
  }
}, {
  attrs: {
    prop: 'effectiveEndDate',
    label: '到期时间'
  }
}, {
  attrs: {
    prop: '',
    label: '明细'
  },
  slot: 'detail'
}];
export default {
  data() {
    return {
      Columns,
      visible: false,
      data: null,
      tableData: []
    };
  },
  computed: {
    getStatus() {
      return val => val == '1';
    }
  },
  methods: {
    parseTime,
    show(data) {
      this.visible = true;
      this.data = data;
      this.tableData = this.formatData(data);
    },
    hide() {
      this.visible = false;
    },
    formatData(data) {
      const {
        effectiveEndDate,
        effectiveStartDate,
        effective,
        institutionId,
        standardInfoDetailList
      } = data;
      const startDate = parseTime(effectiveStartDate);
      const endDate = parseTime(effectiveEndDate);
      return standardInfoDetailList.map(it => {
        const {
          standardId,
          standardName,
          standardConditionInfoList
        } = it;
        return {
          startDate,
          endDate,
          effective,
          institutionId,
          standardId,
          standardName,
          standardConditionInfoList
        };
      });
    }
  }
};