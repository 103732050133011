import { PlanNumberMore } from '@/components/common';
import { tableMixin } from '@/mixin';
import billDownTableMixin from '../../billDownTableMixin';
export default {
  components: {
    PlanNumberMore
  },
  mixins: [tableMixin, billDownTableMixin],
  data: () => ({
    pathDetail: '/bill/unit-collect/detail',
    path: '/bill/unit-collect/update'
  }),
  computed: {
    getPlanNumberContent() {
      return list => list.map(it => it.planNumber).join('<br />');
    }
  }
};