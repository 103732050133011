import "core-js/modules/es.array.push.js";
import TopTitle from '../reserve/components/TopTitle.vue';
import { SelectDictionary, SelectPayType as SelectRestaurantType } from '@/components/common';
import { parseDate } from '@/utils';
import { hasBooked } from '@/utils/status';
import { orderRestaurant as columnData } from '@/config/resourceOrder';
import { MealType } from '@/config/restaurant';
const restaurantTypeList = [{
  label: '社会餐厅',
  value: '社会餐厅'
}, {
  label: '团餐厅',
  value: '团餐厅'
}];
const defaultState = () => ({
  orderDate: parseDate(new Date()),
  area: '',
  count: 1,
  remarks: '',
  mealType: MealType.LUNCH,
  restaurantType: 1,
  planId: -1,
  headCompanyId: -1
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    SelectRestaurantType
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columnData,
      restaurantTypeList,
      title: '餐厅',
      tableData: [],
      btnLoading: false
    };
  },
  computed: {
    hasBooked,
    tableTitle() {
      return this.showTitle ? this.title : '';
    }
  },
  watch: {
    data: {
      handler: function (restList) {
        this.tableData = restList;
      },
      immediate: true
    }
  },
  methods: {
    handleAdd() {
      this.tableData.push(defaultState());
    },
    selectArea(row, val) {
      row.restaurantArea = val.label;
    },
    selectStandardType(row, val) {
      row.restaurantType = val.label;
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (!id || id < 0) {
        this.tableData.splice(scope.$index, 1);
      }
    },
    handleUpdate() {}
  }
};