var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "shop-input-dialog",
    attrs: {
      title: "商品添加/编辑",
      width: "90%",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("TableListPlanShop", {
    ref: "tableRef",
    attrs: {
      "column-data": _vm.columnData
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };