import "core-js/modules/es.array.push.js";
import { TopBar, Audit, DelCheck, DelCheckAll, AuditCheck, AuditAdmin, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import DialogOrder from './DialogOrder';
import DialogList from './DialogList';
import { planEditFleet, ShuttleType } from '@/config/plan';
import { StatusAudit, SelectFleet, SelectDictionary, SelectPayType, SelectPlanNumber } from '@/components/common';
import { getNextDate } from '@/utils';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, dictionaryMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole, TYPES } from '@/config/audit';
import { itemListKeys, defaultState } from './config';
import DialogFix from './DialogFix';
export default {
  components: {
    TopBar,
    StatusAudit,
    Audit,
    AuditCheck,
    AuditAdmin,
    DelCheck,
    DelCheckAll,
    SelectFleet,
    SelectDictionary,
    SelectPayType,
    DialogOrder,
    DialogList,
    PaymentTotal,
    AuditRoleAll,
    SelectPlanNumber,
    AuditStatus,
    CheckStatus,
    DialogFix
  },
  mixins: [selectorMixin, rowspanMixin, dictionaryMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'busCompanyOrderStatus',
      deletePath: 'plan/fleet/delete',
      updatePath: 'plan/fleet/update',
      fetchPath: 'plan/fleet/fetch',
      headMergeNum: 3,
      tailMergeNum: planEditFleet.length - 6,
      hasMergeTail: true,
      // rowId: 1000000000000,
      auditAll: false,
      busList: [],
      driverList: [],
      statisticsKeys: ['busFee', 'otherFee', 'rowTotal']
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      vehicleData: 'resource/vehicle/vehicleData',
      driverData: 'resource/driver/driverData'
    }),
    // 是否集团已安排车状态
    isArrangeBus() {
      return this.planInfo && this.planInfo.busCompanyOrderStatus == 2;
    },
    isDisabledBus() {
      return row => this.isRowDisable(row) || this.isArrangeBus;
    }
  },
  created() {
    this.fetchData({
      shuttleType: ShuttleType.bus
    });
  },
  methods: {
    enterpriseFixDialog(row, column) {
      if (column.property == 'rowTotal' && row.alipayPeerPayAmount != 0 && row.alipayPeerPayAmount != row.totalPay) {
        this.$refs.dialogFixRef.show({
          ...row
        });
      }
    },
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.tableData = this.tableData.map(item => {
        this.fetchDriver({
          busCompanyId: item.busCompanyId
        }).then(res => {
          item.driverList = res;
        });
        this.fetchBus(item.busCompanyId).then(res => {
          item.licenceNumberList = res;
        });
        return item;
      });
      // 是否显示订单信息
      const isOrderInfo = this.hasOrderInfo;
      this.$nextTick(() => {
        this.tailMergeNum = this.isAudit ? this.columnData.length - 8 : this.columnData.length - 6;
        // 如果不显示订单信息
        if (!isOrderInfo) {
          this.headMergeNum = isOrderInfo ? 3 : 2;
        }
        this.resetData();
      });
    },
    fetchDriver(opts) {
      opts = {
        pageSize: 50,
        ...opts
      };
      return this.$store.dispatch('resource/driver/fetch', opts).then(data => {
        const list = this.formatOptions(data.list, 'name');
        return list;
      });
    },
    fetchBus(busCompanyId) {
      return this.$store.dispatch('resource/vehicle/fetch', {
        busCompanyId
      }).then(data => {
        const list = this.formatOptions(data.list, 'licenceNumber');
        return list;
      });
    },
    onAdminSelect(row, val) {
      row.auditProgress = val;
    },
    // 选择车队
    handleSelect(row, val) {
      // 如果未选中任何数据，直接返回
      const {
        id,
        label
      } = val;
      row.busCompanyName = label;
      row.busCompanyId = id;
      row.driverPhone = '';
      row.driverId = '';
      row.driverName = '';
      row.licenceNumber = '';
      if (val.label == '') return;
      this.fetchDriver({
        busCompanyId: id
      }).then(res => {
        row.driverList = res;
      });
      this.fetchBus(id).then(res => {
        row.licenceNumberList = res;
      });
    },
    // 用车类型
    selectUseBusType(row, val) {
      row.useType = val ? val.label : '';
      row.useTypeLabel = val ? val.label : '';
    },
    // 车辆类型
    selectBusType(row, val) {
      const {
        label,
        value
      } = val;
      row.busType = label;
      row.busTypeId = value;
    },
    // 选择司机
    selectDriver(scope, val) {
      if (typeof val !== 'string') {
        const {
          id,
          mobilePhone
        } = val;
        const {
          row
        } = scope;
        row.driverPhone = mobilePhone;
        row.driverId = id;
      }
    },
    // 司机输入搜索获取最新数据
    changeDriver(scope, val) {
      const {
        row
      } = scope;
      this.fetchDriver({
        busCompanyId: row.busCompanyId,
        name: val
      }).then(res => {
        row.driverList = res;
      });
    },
    // 选择车牌
    selectLicence(row, val) {
      if (val && typeof val !== 'string') {
        const {
          licenceNumber
        } = val;
        row.licenceNumber = licenceNumber;
      }
    },
    // 司机选项
    driverSearch(queryString, cb, row) {
      const drivers = row.driverList;
      const results = queryString ? drivers.filter(this.createFilter(queryString)) : drivers;
      cb(results);
    },
    // 车牌选项
    licenceSearch(queryString, cb, row) {
      const busList = row.licenceNumberList;
      const results = queryString ? busList.filter(this.createFilter(queryString)) : busList;
      cb(results);
    },
    createFilter(queryString) {
      return list => {
        return list.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleBill(row) {
      const {
        rowId: id,
        planId
      } = row;
      let routeUrl = this.$router.resolve({
        path: '/print-driver',
        query: {
          id,
          itemId: planId,
          type: 'plan',
          component: 'Driver'
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    // 当前行添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于 0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/fleet/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isChecked: false
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.changeInput();
      this.rowspan();
    },
    // 新增一条数据
    handlePlus() {
      const busUseType = this.getDictionaryDefVal('busUseType');
      let adultCount = 0,
        endPlanDate = '',
        startPlanDate = '';
      if (this.planInfo) {
        adultCount = this.planInfo.adultCount;
        endPlanDate = this.planInfo.endPlanDate;
        startPlanDate = this.planInfo.startPlanDate;
      }
      this.tableData.push({
        ...defaultState(),
        // rowId: ++this.rowId,
        planId: this.currentPlanId,
        count: adultCount,
        useType: busUseType.name,
        useTypeLabel: busUseType.name,
        auditProgress: 'WAITAUDIT',
        endDate: endPlanDate,
        startDate: startPlanDate,
        selectStartDate: new Date(startPlanDate).getTime()
      });
      this.rowspan();
    },
    // 时间修改
    changeStartDate(row, val) {
      row.selectStartDate = new Date(val).getTime();
      row.endDate = getNextDate(row.selectStartDate);
    },
    changeEndDate(row, val) {
      if (!row.selectStartDate) {
        this.$bus.tip({
          type: 'warning',
          message: '请先选择开始日期'
        });
        row.endDate = '';
        return;
      }
      if (row.selectStartDate > new Date(val).getTime()) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期不能早于开始日期'
        });
        row.endDate = '';
        return;
      }
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        busFee,
        otherFee
      } = obj;
      let total = Number(busFee || 0) + Number(otherFee || 0);
      return total;
    },
    // 修改单行金额
    changeInput() {
      // row.totalPay = this.calcRowTotalMoney(row)
      this.getTotalPayMoney();
      this.mergeRowMoney(); // 合并单条数据总价
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderBusCompanyTypeSimples || [];
        const {
          id,
          auditProgress,
          useType
        } = it;
        const useTypeLabel = this.getBusUseType(useType) || '';
        let item = {
          ...it,
          ap: auditProgress,
          auditProgress,
          rowId: id,
          planFleetId: id,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          payType: (it.payType || 'guidesign').toLowerCase(),
          useTypeLabel,
          driverName: '',
          licenceNumber: '',
          itemIndex: 0,
          totalPay: 0,
          rowTotal: 0
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = this.calcRowTotalMoney(v);
            result.push({
              ...item,
              ...v,
              busType: v.busType || '',
              itemIndex: i,
              totalPay: totalSum,
              totalSum,
              selectStartDate: new Date(v.startDate).getTime()
            });
          });
        } else {
          result.push(item);
        }

        // 同步id
        // this.rowId++
      });
      return result;
    },
    // 处理提交触角
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'selectStartDate'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 定义子项目的列表
        it.busCompanyTypeSimples = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.ap;
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.needOPAudit && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.planFleetId;
        it.payType = it.payType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.planFleetId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.busCompanyTypeSimples.push(item);
        } else {
          it.busCompanyTypeSimples.push(item);
          result.push(it);
          last = it;
        }
      });
      // 计算
      list.map(it => {
        it.totalPay = 0;
        it.busCompanyTypeSimples.map(its => {
          it.totalPay += this.calcRowTotalMoney(its);
        });
      });
      return result;
    },
    // 校验数据
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          busCompanyName,
          busType,
          useType,
          startDate,
          endDate
        } = row;
        if (!busCompanyName) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择车辆公司！'
          });
          return false;
        }
        if (!busType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择用车类型！'
          });
          return false;
        }
        if (!useType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择车辆类型！'
          });
          return false;
        }
        if (!startDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择用车开始时间！'
          });
          return false;
        }
        if (!endDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择用车结束时间！'
          });
          return false;
        }
      }
      return true;
    },
    // 格式化下拉列表
    formatOptions(list, key) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        it.value = it[key];
        result.push(it);
      });
      return result;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        it.totalPay = totalPay;
        // this.$set(it, 'totalPay', totalPay)
      });
    },
    // 重置表格与价格计算
    resetData() {
      // this.getTotalPayMoney()
      this.rowspan();
      this.mergeRowMoney(); // 合并单条数据总价
    },
    handleCheckout(row) {
      const msg = row.checkAccountStatus ? '确定要取消对账吗？' : '确定要对账吗？';
      this.$bus.open(() => {
        const opts = {
          auditTypeEnum: TYPES.bus_company,
          checkAccountRemark: '',
          checkAccountMoney: row.rowTotal,
          resourceId: row.rowId,
          status: !row.checkAccountStatus
        };
        this.$store.dispatch('bill/billCheck/billCheck', [opts]).then(() => {
          this.fetchData();
        });
      }, msg);
    }
  }
};