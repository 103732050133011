import Receipt from './pages/Receipt/index';
import Receipt2 from './pages/Receipt/Receipt2';
import Receipt3 from './pages/Receipt/Receipt3';
import PlanInsurance from './pages/PlanInsurance';
import GuideBrokerage from './pages/GuideBrokerage';
import GuideBrokerage2 from './pages/GuideBrokerage2';
import GuideSubmit from './pages/GuideSubmit/index.vue';
import GuideSubmit2 from './pages/GuideSubmit/GuideSubmit2.vue';
import Pickup from './pages/Pickup';
import TeamName from './pages/TeamName';
import BoardNameTeam from './pages/BoardName/Team';
import BoardNamePersonal from './pages/BoardName/Personal';
import Settlement from './pages/Settlement';
import PlanTeam from './pages/PlanTeam/index';
import PlanTeamJzl from './pages/PlanTeam/Jzl';
import PlanTeamMhjq from './pages/PlanTeam/Mhjq';
import JYPlanTeam from './pages/JYPlanTeam';
import GuidePlanTeam from './pages/GuidePlanTeam';
import GuidePlanPersonal from './pages/GuidePlanPersonal';
import PlanPersonal from './pages/PlanPersonal/index';
import PlanPersonalTpl2 from './pages/PlanPersonal/Tpl2.vue';
import PlanShopping from './pages/PlanShopping';
import PlanTeamName from './pages/PlanTeamName';
import PlanPersonalName from './pages/PlanPersonalName';
import PlanProfitDetail from './pages/PlanProfitDetail/index';

// import { FooterBar } from '@/components/common'
import FooterItem from './components/FooterItem.vue';
export default {
  components: {
    FooterItem,
    GuideSubmit,
    GuideSubmit2,
    Receipt,
    Receipt2,
    Receipt3,
    PlanInsurance,
    GuideBrokerage,
    GuideBrokerage2,
    Pickup,
    TeamName,
    BoardNameTeam,
    BoardNamePersonal,
    JYPlanTeam,
    Settlement,
    PlanTeam,
    PlanTeamJzl,
    PlanTeamMhjq,
    GuidePlanTeam,
    PlanPersonal,
    PlanPersonalTpl2,
    GuidePlanPersonal,
    PlanShopping,
    PlanTeamName,
    PlanPersonalName,
    PlanProfitDetail
  }
};