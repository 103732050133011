import { findComponentsUpward } from '@/utils';
export default {
  props: {
    columnData: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    updateData(item, row, val) {
      const {
        productType,
        slot
      } = row;
      let {
        groupType,
        kpitype,
        rowId
      } = item;
      rowId = `${rowId}_${slot}`;
      const findComp = findComponentsUpward(this, 'CustomDialog')[0];
      findComp.saveData({
        productType,
        groupType,
        val,
        kpitype,
        rowId
      });
    }
  }
};