import { DayRangeMap } from './day';
import { TrafficMap } from './traffic';
export const radioAuthArea = [{
  value: '个人',
  label: 'private'
}, {
  value: '二级部门',
  label: 'grandsonDepartment'
}, {
  value: '一级部门',
  label: 'subDepartment'
}, {
  value: '业务部门',
  label: 'department'
}, {
  value: '公司',
  label: 'company'
}, {
  value: '部门自定义',
  label: 'multiple'
}, {
  value: '员工自定义',
  label: 'multiplePerson'
}];
export const departmentAuthArea = [{
  value: '公司',
  label: ''
}, {
  value: '业务部门',
  label: 'department'
}, {
  value: '一级部门',
  label: 'subDepartment'
}, {
  value: '二级部门',
  label: 'grandsonDepartment'
}];
export const pickTimeRadios = [{
  value: '上午',
  label: DayRangeMap.morning
}, {
  value: '下午',
  label: DayRangeMap.afternoon
}, {
  value: '晚上',
  label: DayRangeMap.night
}];
export const sendTimeRadios = [{
  value: '上午',
  label: DayRangeMap.morning
}, {
  value: '下午',
  label: DayRangeMap.afternoon
}, {
  value: '晚上',
  label: DayRangeMap.night
}];
export const pickRadios = [{
  pickRemarks: '',
  value: '接飞机',
  label: TrafficMap.plane
}, {
  pickRemarks: '',
  value: '接火车',
  label: TrafficMap.train
}, {
  pickRemarks: '',
  value: '接酒店',
  label: TrafficMap.hotel
}];
export const sendRadios = [{
  sendRemarks: '',
  value: '送飞机',
  label: TrafficMap.plane
}, {
  sendRemarks: '',
  value: '送火车',
  label: TrafficMap.train
}, {
  sendRemarks: '',
  value: '送酒店',
  label: TrafficMap.hotel
}];