var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "短信发送",
      width: "460px",
      visible: _vm.visible,
      "confirm-btn-text": "发送"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleSend
    }
  }, [_c("FormBase", {
    attrs: {
      "label-width": "80px"
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "团号"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.form.planNumber))])]), _c("FormItemBase", {
    attrs: {
      label: "导游"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.form.guideName))])]), _c("FormItemBase", {
    attrs: {
      label: "付款项目"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("SelectBase", {
    attrs: {
      type: "smsPaymenu"
    },
    model: {
      value: _vm.form.item,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item", $$v);
      },
      expression: "form.item"
    }
  })], 1)]), _c("FormItemBase", {
    attrs: {
      label: "金额"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.money,
      callback: function ($$v) {
        _vm.$set(_vm.form, "money", $$v);
      },
      expression: "form.money"
    }
  })], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };