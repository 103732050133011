import "core-js/modules/es.array.push.js";
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import { getDailyTemplate, getCustomerIncome, exportCustomerIncome, getRoomAndBus, getTeamProfit, exportTeamProfitExcel, getMonthlyOPStatust, exporMonthlyOPExcel, exportTeamProfitSettlementExcel, getMonthlyGrossProfit, exportMonthlyProfitExcel, getYearPeopleCount, exportYearPeopleCount, getTripIncomeDetailed, exportTripIncomeDetailed, exportOtherIncomeStatic, exportOtherPayStatic, getOtherPayTotal, exportOtherPayTotal, getOtherIncomeStatic, getOtherPayStatic, getOutTransferStatic, exportOutTransferStatic, getResourceStatic, exportResourceStatic, getCustomerStatic, exportCustomerStatic, exportTripPayStatic, exportTripPayAreaStatic, getGuideStatic, exportGuideData, getGuideTeamStatic, exportGuideTeamStatic, getGuideReimb, exportGuideReimb, getGuideSubmit, fetchOrderRoom, fetchOrderList, fetchOrderRoomById, fetchOrderResource, getGuideCount, exportPlanGuideCount, getGuideSettle, exportPlanGuideSettle, getGuideBorrow, exportPlanGuideBorrow, getTeamSmall, exportTeamSmallExcel, exportTeamSmallSettleExcel, fetchCustomerGross, exportCustomerGross, fetchHotelHousePrice, exportHotelHousePrice, fetchSubsidy, exportSubsidy, fetchshopGoodsList, getDailyExpress, fetchCustomeIncomeList, guideBorrowListRequest, guideBorrowExportRequest, fetchOutopuser, exportOutopuser, fetchDutyOpuser, exportDutyOpuser, fetchUnIncome, exportUnIncome, fetchGroup, fetchGroupCustomer, fetchGroupAreaManager, fetchGroupCustomerArea, getOtherIncomeSummary, exportOtherIncomeSummary, subsidyStatistics2Request, exportSubsidy2Request } from '@/api/report';
import { DEFAULT_PAGE } from '@/config';
const calculatePer = data => {
  const {
    totalAdultCount,
    totalGrossProfit
  } = data;
  let sum = totalGrossProfit * 100 / (totalAdultCount * 100);
  sum = sum.toFixed(2);
  return sum;
};
const formatList = data => {
  if (!data.list.length) return [];
  let result = [];
  data.list.forEach(it => {
    it.totalAdultCount = perAdultCount(it);
    it.totalGrossProfit = perGrossMargin(it);
    it.totalQuoteGrossMargin = calculatePer(it);
    result.push(it);
  });
  data.statistics.totalAdultCount = perAdultCount(data.statistics);
  data.statistics.totalGrossProfit = perGrossMargin(data.statistics);
  data.statistics.totalQuoteGrossMargin = calculatePer(data.statistics);
  data.list = result;
  return data;
};
const perAdultCount = data => {
  return (Number(data.groupAdultCount) * 100 + Number(data.individualAdultCount) * 100) / 100;
};
const perGrossMargin = data => {
  return (Number(data.groupGrossProfit) * 100 + Number(data.individualGrossProfit) * 100) / 100;
};
const dfaultState = () => ({
  customerIncomeData: null,
  roomAndBus: null,
  teamProfit: null,
  teamProfit2: null,
  teamSettlemen: null,
  monthlyOP: null,
  customerStatic: null,
  fleetData: null,
  shuttletData: null,
  shopData: null,
  hotelData: null,
  insuranceData: null,
  scenicData: null,
  scenicDataAdd: null,
  restaurantData: null,
  transportData: null,
  roomCountData: null,
  otherPayData: null,
  otherPayTotalData: null,
  otherCollectData: null,
  grossProfitData: null,
  tripIncomeData: null,
  transferData: null,
  guideReimbData: null,
  busOrderData: null,
  scenicOrderData: null,
  transportOrderData: null,
  restaurantOrderData: null,
  guideCountData: null,
  guideSettleData: null,
  guideBorrowData: null,
  guideBorrowDetail: null,
  teamSmallData: null,
  teamSmallSettleData: null,
  customerGrossData: null,
  customerSubsidy: null,
  customerSubsidy2: null,
  hotelPriceData: null,
  everyDayData: null,
  outOpUserData: null,
  saleUserData: null,
  dutyOPUserData: null,
  unIncomeData: null,
  groupTotalData: null,
  groupCustomerData: null,
  groupAreaManagerData: null,
  groupCustomerAreaData: null,
  otherIncomeTotal: null
});
const state = dfaultState();
const getters = {
  shopData: state => state.shopData,
  monthlyOP: state => state.monthlyOP,
  hotelData: state => state.hotelData,
  fleetData: state => state.fleetData,
  shuttletData: state => state.shuttletData,
  teamProfit: state => state.teamProfit,
  roomAndBus: state => state.roomAndBus,
  scenicData: state => state.scenicData,
  scenicDataAdd: state => state.scenicDataAdd,
  teamProfit2: state => state.teamProfit2,
  otherPayData: state => state.otherPayData,
  unIncomeData: state => state.unIncomeData,
  transferData: state => state.transferData,
  busOrderData: state => state.busOrderData,
  everyDayData: state => state.everyDayData,
  outOpUserData: state => state.outOpUserData,
  saleUserData: state => state.saleUserData,
  dutyOPUserData: state => state.dutyOPUserData,
  teamSmallData: state => state.teamSmallData,
  roomCountData: state => state.roomCountData,
  transportData: state => state.transportData,
  insuranceData: state => state.insuranceData,
  teamSettlemen: state => state.teamSettlemen,
  restaurantData: state => state.restaurantData,
  customerStatic: state => state.customerStatic,
  hotelPriceData: state => state.hotelPriceData,
  guideReimbData: state => state.guideReimbData,
  guideCountData: state => state.guideCountData,
  tripIncomeData: state => state.tripIncomeData,
  grossProfitData: state => state.grossProfitData,
  guideSettleData: state => state.guideSettleData,
  customerSubsidy: state => state.customerSubsidy,
  scenicOrderData: state => state.scenicOrderData,
  guideBorrowData: state => state.guideBorrowData,
  otherCollectData: state => state.otherCollectData,
  otherPayTotalData: state => state.otherPayTotalData,
  customerGrossData: state => state.customerGrossData,
  guideBorrowDetail: state => state.guideBorrowDetail,
  transportOrderData: state => state.transportOrderData,
  customerIncomeData: state => state.customerIncomeData,
  teamSmallSettleData: state => state.teamSmallSettleData,
  restaurantOrderData: state => state.restaurantOrderData,
  groupTotalData: state => state.groupTotalData,
  groupCustomerData: state => state.groupCustomerData,
  groupAreaManagerData: state => state.groupAreaManagerData,
  groupCustomerAreaData: state => state.groupCustomerAreaData,
  otherIncomeTotal: state => state.otherIncomeTotal
};
const actions = {
  // 导出天天快报
  fetchDailyExcel({
    commit
  }, opts) {
    return getDailyTemplate(opts).then(res => {
      return res;
    });
  },
  // 查询天天快报
  getDayExpress({
    commit
  }, opts) {
    return getDailyExpress(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'everyDayData',
        data
      });
      return data;
    });
  },
  // 查询客户应收报表数据
  getCustomerIncome({
    commit
  }, opts) {
    return getCustomerIncome(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customerIncomeData',
        data
      });
      return data;
    });
  },
  // 导出客户应收报表
  fetchCustomerIncomeExcel({
    commit
  }, opts) {
    return exportCustomerIncome(opts).then(res => {
      return res;
    });
  },
  // 查询用车用房数据
  getRoomAndBus({
    commit
  }, opts) {
    return getRoomAndBus(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'roomAndBus',
        data
      });
      return data;
    });
  },
  // 查询单团利润数据统计
  getTeamProfit({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return getTeamProfit(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'teamProfit',
        data
      });
      return data;
    });
  },
  // 查询单团利润数据统计
  getTeamProfit2({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return getTeamProfit(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'teamProfit2',
        data
      });
      return data;
    });
  },
  // 导出单团利润统计报表
  fetchTeamProfitExcel({
    commit
  }, opts) {
    return exportTeamProfitExcel(opts).then(res => {
      return res;
    });
  },
  // 查询单团利润结算数据统计
  getTeamSettlement({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return getTeamProfit(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'teamSettlemen',
        data
      });
      return data;
    });
  },
  // 导出单团利润结算报表
  fetchProfitSettlementExcel({
    commit
  }, opts) {
    return exportTeamProfitSettlementExcel(opts).then(res => {
      return res;
    });
  },
  // 小团利润统计
  getTeamSmall({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return getTeamSmall(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'teamSmallData',
        data
      });
      return data;
    });
  },
  // 小团利润结算统计
  getTeamSmallSettle({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return getTeamSmall(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'teamSmallSettleData',
        data
      });
      return data;
    });
  },
  // 导出小团利润报表
  fetchProfitSmallExcel({
    commit
  }, opts) {
    return exportTeamSmallExcel(opts).then(res => {
      return res;
    });
  },
  // 导出小团利润结算报表
  fetchProfitSmallSettleExcel({
    commit
  }, opts) {
    return exportTeamSmallSettleExcel(opts).then(res => {
      return res;
    });
  },
  // 月营业状况表
  getMonthlyOPStatust({
    commit
  }, opts) {
    return getMonthlyOPStatust(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'monthlyOP',
        data
      });
      return data;
    });
  },
  // 导出月营业状况表
  fetchMonthlyOPExcel({
    commit
  }, opts) {
    return exporMonthlyOPExcel(opts).then(res => {
      return res;
    });
  },
  // 毛利总汇
  getMonthlyGrossProfit({
    commit
  }, opts) {
    return getMonthlyGrossProfit(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'grossProfitData',
        data
      });
      return data;
    });
  },
  // 导出毛利总汇表
  fetchMonthlyProfitExcel({
    commit
  }, opts) {
    return exportMonthlyProfitExcel(opts).then(res => {
      return res;
    });
  },
  // 年度人数统计
  getYearPeopleCount({
    commit
  }, opts) {
    return getYearPeopleCount(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导出毛利总汇表
  fetchYearPeopleCount({
    commit
  }, opts) {
    return exportYearPeopleCount(opts).then(res => {
      return res;
    });
  },
  //社收明细统计表
  getTripIncomeDetailed({
    commit
  }, opts) {
    return getTripIncomeDetailed(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'tripIncomeData',
        data
      });
      return data;
    });
  },
  // 社收明细统计表
  fetchTripIncomeDetailed({
    commit,
    rootGetters
  }, opts) {
    return exportTripIncomeDetailed(opts).then(res => {
      return res;
    });
  },
  //其他收入统计
  getOtherIncomeStatic({
    commit
  }, opts) {
    return getOtherIncomeStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherCollectData',
        data
      });
      return data;
    });
  },
  // 其他收入导出
  fetchOtherIncomeStaticExcel({
    commi
  }, opts) {
    return exportOtherIncomeStatic(opts).then(res => {
      return res;
    });
  },
  //其他支出统计
  getOtherPayStatic({
    commit
  }, opts) {
    return getOtherPayStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherPayData',
        data
      });
      return data;
    });
  },
  // 其他支出导出
  fetchOtherPayStaticExcel({
    commi
  }, opts) {
    return exportOtherPayStatic(opts).then(res => {
      return res;
    });
  },
  //其他支出汇总统计
  getOtherPayTotal({
    commit
  }, opts) {
    return getOtherPayTotal(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherPayTotalData',
        data
      });
      return data;
    });
  },
  // 其他支出汇总导出
  fetchOtherPayTotaExcel({
    commi
  }, opts) {
    return exportOtherPayTotal(opts).then(res => {
      return res;
    });
  },
  //其他收入汇总统计
  getOtherIncomeSummary({
    commit
  }, opts) {
    return getOtherIncomeSummary(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherIncomeTotal',
        data
      });
      return data;
    });
  },
  // 其他收入汇总导出
  fetchOtherIncomeTotaExcel({
    commi
  }, opts) {
    return exportOtherIncomeSummary(opts).then(res => {
      return res;
    });
  },
  //地接汇总统计
  getOutTransferStatic({
    commit
  }, opts) {
    return getOutTransferStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'transferData',
        data
      });
      return data;
    });
  },
  // 地接汇总统计表导出
  fetchOutTransferExcel({
    commi
  }, opts) {
    return exportOutTransferStatic(opts).then(res => {
      return res;
    });
  },
  //资源汇总接口
  getResourceStatic({
    commit
  }, opts) {
    const {
      resourceType,
      addScenic
    } = opts;
    return getResourceStatic(opts).then(res => {
      const {
        data
      } = res;
      const type = resourceType + '_DATA';
      // 判断是否是景区加点
      if (resourceType == 'SCENIC' && addScenic == 1) {
        commit('SET_DATA', {
          type: 'scenicDataAdd',
          data
        });
      } else {
        commit(type, data);
      }
      return data;
    });
  },
  // 车队汇总统计
  fetchFleet({
    commit
  }, opts) {
    return getResourceStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'fleetData',
        data
      });
      return data;
    });
  },
  //接送汇总统计
  fetchShuttle({
    commit
  }, opts) {
    return getResourceStatic({
      ...opts,
      shuttleType: 'shuttle'
    }).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'shuttletData',
        data
      });
      return data;
    });
  },
  // 资源汇总接口导出
  fetchResourceStaticExcel({
    commi
  }, opts) {
    return exportResourceStatic(opts).then(res => {
      return res;
    });
  },
  //客户汇总统计
  getCustomerStatic({
    commit
  }, opts) {
    return getCustomerStatic(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customerStatic',
        data
      });
      return data;
    });
  },
  // 客户汇总统计导出
  fetchCustomerStatic({
    commi
  }, opts) {
    return exportCustomerStatic(opts).then(res => {
      return res;
    });
  },
  //客户毛利统计
  getCustomerGross({
    commit
  }, opts) {
    return fetchCustomerGross(opts).then(res => {
      let {
        data
      } = res;
      data = formatList(data);
      commit('SET_DATA', {
        type: 'customerGrossData',
        data
      });
      return data;
    });
  },
  // 客户毛利统计导出
  fetchCustomerGross({
    commi
  }, opts) {
    return exportCustomerGross(opts).then(res => {
      return res;
    });
  },
  //返款统计表
  fetchSubsidy({
    commit
  }, opts) {
    return fetchSubsidy(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customerSubsidy',
        data
      });
      return data;
    });
  },
  // 导出返款统计表
  exportSubsidy({
    commi
  }, opts) {
    return exportSubsidy(opts).then(res => {
      return res;
    });
  },
  //返款统计表2
  subsidyStatistics({
    commit
  }, opts) {
    return subsidyStatistics2Request(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'customerSubsidy2',
        data
      });
      return data;
    });
  },
  // 导出返款统计表2
  exportSubsidy2({
    commi
  }, opts) {
    return exportSubsidy2Request(opts).then(res => {
      return res;
    });
  },
  // 导出应付明细表
  fetchTripPayStatic({
    commi,
    rootGetters
  }, opts) {
    return exportTripPayStatic(opts).then(res => {
      return res;
    });
  },
  // 导出应付明细表-按区域
  fetchTripPayAreaStatic({
    commi,
    rootGetters
  }, opts) {
    return exportTripPayAreaStatic(opts).then(res => {
      return res;
    });
  },
  //导游数据统计
  getGuideStatic({
    commit
  }, opts) {
    return getGuideStatic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导游数据统计导出
  fetchGuideStaticExcel({
    commi
  }, opts) {
    return exportGuideData(opts).then(res => {
      return res;
    });
  },
  //导游组长数据统计
  getGuideTeamStatic({
    commit
  }, opts) {
    return getGuideTeamStatic(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导游组长数据统计导出
  fetchGuideTeamStaticExcel({
    commi
  }, opts) {
    return exportGuideTeamStatic(opts).then(res => {
      return res;
    });
  },
  //导游报账统计
  getGuideReimb({
    commit
  }, opts) {
    return getGuideReimb(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'guideReimbData',
        data
      });
      return data;
    });
  },
  // 导游报账统计导出
  fetchGuideReimbExcel({
    commi
  }, opts) {
    return exportGuideReimb(opts).then(res => {
      return res;
    });
  },
  //导游汇总统计
  getGuideCount({
    commit
  }, opts) {
    return getGuideCount(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'guideCountData',
        data
      });
      return data;
    });
  },
  // 导游汇总统计导出
  fetchPlanGuideCountExcel({
    commi
  }, opts) {
    return exportPlanGuideCount(opts).then(res => {
      return res;
    });
  },
  //导游结算统计
  getGuideSettle({
    commit
  }, opts) {
    return getGuideSettle(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'guideSettleData',
        data
      });
      return data;
    });
  },
  // 导游结算统计导出
  fetchGuideSettleExcel({
    commi
  }, opts) {
    return exportPlanGuideSettle(opts).then(res => {
      return res;
    });
  },
  //导游借款统计
  getGuideBorrow({
    commit
  }, opts) {
    return getGuideBorrow(opts).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'guideBorrowData',
        data
      });
      return data;
    });
  },
  // 导游借款统计导出
  fetchGuideBorrowExcel({
    commi
  }, opts) {
    return exportPlanGuideBorrow(opts).then(res => {
      return res;
    });
  },
  // 导游报账审核统计
  getGuideSubmit({
    commi
  }, params) {
    return getGuideSubmit(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      return data;
    });
  },
  // 用房统计
  fetchOrderRoom({
    commit
  }, params) {
    return fetchOrderRoom(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'roomCountData',
        data
      });
      return data;
    });
  },
  // 房价统计
  fetchHotelHousePrice({
    commit
  }, params) {
    return fetchHotelHousePrice(params).then(res => {
      if (!res) return;
      let {
        data
      } = res;
      // 转化成json字符串,防止出现浅拷贝问题
      data = JSON.stringify(data);
      commit('SET_DATA', {
        type: 'hotelPriceData',
        data
      });
      return data;
    });
  },
  // 房价统计导出
  fetchHotelHousePriceExcel({
    commi
  }, opts) {
    return exportHotelHousePrice(opts).then(res => {
      return res;
    });
  },
  // 查询房调未安排订单
  fetchOrderList({
    commi
  }, params) {
    return fetchOrderList(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      return data;
    });
  },
  // 根据plan id 查询用房统计
  fetchOrderRoomById({
    commi
  }, params) {
    return fetchOrderRoomById(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      return data;
    });
  },
  // 查询某个团的购物店的商品列表
  fetchshopGoodsList({
    commi
  }, params) {
    return fetchshopGoodsList(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 查应收账款汇总统计
  fetchCustomeIncomeList({
    commi
  }, params) {
    return fetchCustomeIncomeList(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 根据id查资源订单
  fetchOrderResource({
    commit
  }, params) {
    const {
      resourceType
    } = params;
    return fetchOrderResource(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      commit('ORDER_DATA', {
        resourceType: resourceType.toLowerCase(),
        data
      });
      return data;
    });
  },
  fetchGuideBorrow({
    commit
  }, params) {
    return guideBorrowListRequest(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'guideBorrowDetail',
        data
      });
      return res.data;
    });
  },
  exportGuideBorrow({
    commit
  }, params) {
    return guideBorrowExportRequest(params);
  },
  // 客服计调绩效
  fetchOutopuser({
    commit
  }, params) {
    params = {
      ...params,
      pageSize: 100
    };
    return fetchOutopuser(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'outOpUserData',
        data
      });
      return data;
    });
  },
  // 业务员绩效
  fetchSaleUser({
    commit
  }, params) {
    params = {
      ...params,
      pageSize: 100
    };
    return fetchOutopuser(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'saleUserData',
        data
      });
      return data;
    });
  },
  exportOutopuser({
    commit
  }, opts) {
    return exportOutopuser(opts).then(res => {
      return res;
    });
  },
  // 客服计调绩效
  fetchDutyOpuser({
    commit
  }, params) {
    params = {
      ...params,
      pageSize: 100
    };
    return fetchDutyOpuser(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'dutyOPUserData',
        data
      });
      return data;
    });
  },
  exportDutyOpuser({
    commit
  }, opts) {
    return exportDutyOpuser(opts).then(res => {
      return res;
    });
  },
  // 应收余额表导出
  exportUnIncome({
    commit
  }, opts) {
    return exportUnIncome(opts).then(res => {
      return res;
    });
  },
  // 应收余额表
  fetchUnIncome({
    commit
  }, params) {
    return fetchUnIncome(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'unIncomeData',
        data
      });
      return data;
    });
  },
  // 团款汇总统计
  fetchGroup({
    commit
  }, params) {
    return fetchGroup(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'groupTotalData',
        data
      });
      return data;
    });
  },
  // 团款汇总统计（按客户）
  fetchGroupCustomer({
    commit
  }, params) {
    return fetchGroupCustomer(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'groupCustomerData',
        data
      });
      return data;
    });
  },
  // 团款汇总统计（按区域经理）

  fetchGroupAreaManager({
    commit
  }, params) {
    return fetchGroupAreaManager(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'groupAreaManagerData',
        data
      });
      return data;
    });
  },
  // 团款汇总统计（按客户区域）
  fetchGroupCustomerArea({
    commit
  }, params) {
    return fetchGroupCustomerArea(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'groupCustomerAreaData',
        data
      });
      return data;
    });
  }
};
const mutations = {
  SET_DATA: (state, {
    type,
    data
  }) => {
    state[type] = data;
  },
  ORDER_DATA: (state, {
    resourceType,
    data
  }) => {
    state[resourceType + 'OrderData'] = data;
  },
  INCOME_DATA: (state, data) => {
    state.customerIncomeData = data;
  },
  DAY_DATA: (state, data) => {
    state.everyDayData = data;
  },
  BUS_DATA: (state, data) => {
    state.fleetData = data;
  },
  SHOP_DATA: (state, data) => {
    state.shopData = data;
  },
  HOTEL_DATA: (state, data) => {
    state.hotelData = data;
  },
  INSURANCE_DATA: (state, data) => {
    state.insuranceData = data;
  },
  SCENIC_DATA: (state, data) => {
    state.scenicData = data;
  },
  SCENIC_ADD_DATA: (state, data) => {
    state.scenicDataAdd = data;
  },
  TRANSPORT_DATA: (state, data) => {
    state.transportData = data;
  },
  RESTAURANT_DATA: (state, data) => {
    state.restaurantData = data;
  },
  SHUTTLE_DATA: (state, data) => {
    state.shuttletData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};