import ResourceItem from './components/ResourceItem.vue';
import DialogCreate from './components/DialogCreate.vue';
import DialogUpdate from './components/DialogUpdate.vue';
import { reloadMixin, rowspanMixin } from '@/mixin';
import { AdvanceColumn as columns } from '@/config/billDown/advance';
import { SelectStaff, SelectAccount } from '@/components/common';
import { beforeWeek } from '@/utils';
export default {
  components: {
    ResourceItem,
    SelectStaff,
    SelectAccount,
    DialogCreate,
    DialogUpdate
  },
  mixins: [reloadMixin, rowspanMixin],
  data() {
    const lastWeek = beforeWeek();
    return {
      columns,
      tableData: [],
      total: 0,
      headMergeNum: 3,
      searchParams: {
        startCashDate: lastWeek,
        endCashDate: ''
      },
      cashDate: [lastWeek, ''],
      subject: []
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billDown/advance/data'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
          return;
        }
        this.handleData(d);
        this.rowspan();
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = this.searchParams) {
      this.$store.dispatch('bill/billDown/advance/fetch', params);
    },
    handleSearch() {
      const search = this.searchParams;
      if (!search.cashType) {
        delete search.resourceId;
      }
      this.fetchData(search);
    },
    handleData(data) {
      this.tableData = data.list;
      this.total = data.totalCount;
    },
    showDialog() {
      this.$refs.createRef.show();
    },
    changeDate(val) {
      val = val || ['', ''];
      const [starDate, endDate] = val;
      this.searchParams.startCashDate = starDate;
      this.searchParams.endCashDate = endDate;
    },
    changeCashWay(val) {
      this.searchParams.cashWay = val.label;
    },
    selectAccount(val) {
      const {
        id,
        subId
      } = val;
      this.searchParams.subjectId = id;
      this.searchParams.pSubjectId = subId || '';
    },
    // 修改
    handleUpdate(row) {
      this.$refs.updateRef.show(row);
    },
    // 删除
    handleDelete(id) {
      this.$bus.open(() => {
        this.$store.dispatch('bill/billDown/deleteCollect', id).then(() => {
          this.fetchData();
        });
      });
    }
  }
};