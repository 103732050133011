var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    on: {
      "tab-click": _vm.handleTab
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "客户费用",
      name: _vm.fee
    }
  }, [_c("ReceptionFeeTable", {
    ref: _vm.fee,
    attrs: {
      "table-data": _vm.feeList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "客户代收",
      name: _vm.collect
    }
  }, [_c("ReceptionProxyTable", {
    ref: _vm.collect,
    attrs: {
      "table-data": _vm.collectList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "客户返款",
      name: _vm.subsidy
    }
  }, [_c("ReceptionRefundTable", {
    ref: _vm.subsidy,
    attrs: {
      "table-data": _vm.subsidyList
    }
  })], 1)], 1), _c("DialogLog", {
    ref: "logRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };