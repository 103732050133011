var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      "span-method": _vm.objectSpanMethod,
      dynamic: false,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.adultCount) + "大 " + _vm._s(scope.row.childCount) + "小 " + _vm._s(scope.row.accompanyCount) + "陪 ")];
      }
    }, {
      key: "members",
      fn: function ({
        scope
      }) {
        return [_c("Members", {
          attrs: {
            row: scope.row,
            len: 3
          }
        })];
      }
    }, {
      key: "planInfo",
      fn: function ({
        scope
      }) {
        return [_c("p", {
          staticStyle: {
            "text-align": "left"
          }
        }, [_vm._v("小团号:" + _vm._s(scope.row.customerTripNumber))]), _c("p", {
          staticStyle: {
            "text-align": "left"
          }
        }, [_vm._v("流水号:" + _vm._s(scope.row.orderNumber))])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };