// 发团状态
export default {
  name: 'StatusSend',
  inheritAttrs: false,
  props: {
    status: {
      type: Boolean,
      required: true
    }
  }
};