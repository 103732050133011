import { inputMixin } from '@/mixin';
import { isChineseCharacter } from '@/utils';
import * as loadMore from '@/directive/loadMore';
/**
 * @module components/base/Select
 * @desc 搜索数据的各种下拉框的基础组件, 该组建接收到id来初始化值
 * @vue-prop {String} label 下拉框类型的名字
 */

export default {
  name: 'SelectData',
  directives: {
    loadMore
  },
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      required: true
    },
    data: {
      // 所有数据
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    all: {
      type: Boolean,
      default: true
    },
    clearValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      customer: 'customer',
      product: 'product',
      group: 'group',
      curVal: '',
      isSearch: false
    };
  },
  computed: {
    disableStatus() {
      return val => {
        return !this.all && val != null && val != undefined && !val;
      };
    }
  },
  watch: {
    list: {
      handler: 'initData',
      deep: true,
      immediate: true
    },
    value: {
      handler: function (val) {
        this.curVal = val;
      }
    }
  },
  methods: {
    initData(list) {
      this.options = [...list];
      this.$nextTick(() => {
        // 如果传的是
        if (isNaN(this.value)) {
          this.curVal = this.value;
        } else {
          this.findCurLabel();
        }
      });
    },
    findCurLabel() {
      this.$nextTick(() => {
        let data = this.data.length ? this.data : this.options;
        const findItem = data.find(it => it.id == this.value || it.value == this.value);
        this.curVal = findItem ? findItem.label : '';
      });
    },
    // 加载更多
    loadMore() {
      // 输入关键字过滤不加载更多
      if (this.isSearch) return;
      this.$emit('on-load');
    },
    findItem(id) {
      const data = this.data.length ? this.data : this.list;
      const findItem = data.find(it => it.id == id);
      return findItem;
    },
    handleChange(val) {
      let findItem = {
        label: '',
        value: ''
      };
      if (val) {
        if (Array.isArray(val)) {
          const findList = val.map(this.findItem);
          this.$emit('multiple', findList);
        } else {
          findItem = this.findItem(val);
        }
      }
      this.$emit('on-select', findItem);
      if (this.clearValue) {
        this.curVal = '';
      }
    },
    dataFilter(val) {
      this.isSearch = true;
      const data = [...this.list];
      if (val) {
        const filterData = this.handleFilter(data, val);
        this.options = filterData;
      } else {
        this.options = data;
      }
    },
    remoteFilter(val) {
      const data = [...this.list];
      const filterData = this.handleFilter(data, val);
      this.options = filterData;
    },
    handleFilter(data, val) {
      data = this.data.length ? this.data : data;
      const result = data.filter(item => {
        const isChiness = isChineseCharacter(val);
        if (!isChiness && !val.match(/\d/)) {
          if (isNaN(Number(val))) {
            return item.slug.charAt(0) == val.charAt(0) && item.slug.toLowerCase().indexOf(val.toLowerCase()) > -1;
          } else {
            return item.slug.indexOf(val) > -1;
          }
        } else {
          return item.label.indexOf(val) > -1;
        }
      });
      return result;
    },
    resetOptions() {
      this.options = this.list;
    }
  }
};