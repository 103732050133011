/* eslint-disable no-unused-vars */
import { fetchList, fetchGuideManager, createOrUpdateGuideManager, deleteGuideManager, fetchLog } from '@/api/resource/guideManager';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  guideData: null,
  guideDataOrigin: null,
  guideLog: null
});
const state = dfaultState();
const getters = {
  guideData: state => state.guideData,
  guideDataOrigin: state => state.guideDataOrigin,
  guideLog: state => state.guideLog
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchGuideManager(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.guide_manager
    }).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.guide
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  createOrUpdate({
    dispatch
  }, opts) {
    // 删除图片操作
    this.dispatch('resource/common/deleteCacheImage');
    return createOrUpdateGuideManager(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      if (!delFiles.length) return;
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteGuideManager(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.guideData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.guideDataOrigin = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};