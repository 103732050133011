import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
import { TypeOrder } from '@/components/common';
export default {
  components: {
    TypeOrder
  },
  mixins: [tableMixin, shopMixin],
  data: () => ({
    sumsIndex: 3,
    avgeageKey: [4, 5, 6],
    // 计算人均
    shopStarIndex: 8
  }),
  methods: {}
};