var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.formData ? _c("div", {
    staticClass: "base-input"
  }, [_c("div", {
    staticClass: "money"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      semi: true,
      label: "当前金额"
    }
  }), _c("strong", [_vm._v(_vm._s(_vm.curTotalMoney))])], 1), _c("div", {
    staticClass: "input-box mt20"
  }, [_c("LabelBase", {
    attrs: {
      semi: true,
      label: "余额"
    }
  }), _c("strong", [_vm._v(_vm._s(_vm.getBalance))])], 1)]), _c("FormBase", {
    attrs: {
      "label-width": "80px",
      align: "right"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c("LabelBase", {
    attrs: {
      label: "下账模式"
    }
  }), _c("RadioGroup", {
    staticStyle: {
      "padding-left": "0"
    },
    attrs: {
      type: "button",
      radios: _vm.radios
    },
    on: {
      change: _vm.changeRadio
    },
    model: {
      value: _vm.radioVal,
      callback: function ($$v) {
        _vm.radioVal = $$v;
      },
      expression: "radioVal"
    }
  }), _vm.isPrePaymentMode ? _c("div", {
    staticClass: "pl10"
  }, [_vm._v(" " + _vm._s(_vm.payText) + "余额 "), _c("strong", {
    staticClass: "red pl10"
  }, [_vm._v(_vm._s(_vm.prePaymentMoney) + "元")])]) : _vm._e(), _c("switch-status", {
    attrs: {
      label: "自动下账"
    },
    on: {
      change: _vm.changeStatus
    },
    model: {
      value: _vm.autoStatus,
      callback: function ($$v) {
        _vm.autoStatus = $$v;
      },
      expression: "autoStatus"
    }
  })], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_vm._t("default"), _c("InputDate", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      label: "日期",
      "is-require": ""
    },
    model: {
      value: _vm.form.cashDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashDate", $$v);
      },
      expression: "form.cashDate"
    }
  }), _c("InputNum", {
    attrs: {
      "controls-position": "right",
      label: "下账金额",
      "is-require": ""
    },
    on: {
      change: _vm.changeMoney
    },
    model: {
      value: _vm.form.money,
      callback: function ($$v) {
        _vm.$set(_vm.form, "money", $$v);
      },
      expression: "form.money"
    }
  })], 2), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectAccount", {
    attrs: {
      "init-val": _vm.form,
      label: "会计科目",
      "is-require": ""
    },
    on: {
      "on-select": _vm.selectAccount
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _c("SelectBase", {
    attrs: {
      type: "payType2",
      label: "方式",
      "is-require": ""
    },
    on: {
      change: _vm.changeCashMode
    },
    model: {
      value: _vm.form.cashWay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashWay", $$v);
      },
      expression: "form.cashWay"
    }
  }), _c("SelectBankCard", {
    attrs: {
      label: "银行卡",
      "is-require": ""
    },
    model: {
      value: _vm.form.bankId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankId", $$v);
      },
      expression: "form.bankId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectStaff", {
    attrs: {
      label: "收款人",
      "is-require": ""
    },
    model: {
      value: _vm.form.cashUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cashUserId", $$v);
      },
      expression: "form.cashUserId"
    }
  }), _c("InputBase", {
    attrs: {
      label: "发票号"
    },
    model: {
      value: _vm.form.invoiceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceNumber", $$v);
      },
      expression: "form.invoiceNumber"
    }
  }), _c("InputNumber", {
    attrs: {
      "controls-position": "right",
      min: 0,
      label: "发票金额"
    },
    model: {
      value: _vm.form.invoiceMoney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "invoiceMoney", $$v);
      },
      expression: "form.invoiceMoney"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputRemark", {
    attrs: {
      label: "备注"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)])], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };