// 发团预定安排 其他收入
import request from '@/utils/request';

// 安排列表
export function fetchOtherIncome(params) {
  return request({
    url: '/order/otherIncome/listAll',
    method: 'get',
    params
  });
}

// 安排更新
export function updateOtherIncome(data) {
  return request({
    url: '/order/otherIncome/save',
    method: 'post',
    data
  });
}

// 安排删除
export function deleteOtherIncome(ids) {
  return request({
    url: '/order/otherIncome/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteOtherIncomeItem(id) {
  return request({
    url: '/order/otherIncome/delTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchOtherIncomeOrder(params) {
  return request({
    url: '/order/otherIncome/list',
    method: 'get',
    params
  });
}

// 订单更新
export function updateOtherIncomeOrder(data) {
  return request({
    url: '/order/otherIncome/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteOtherIncomeOrder(id) {
  return request({
    url: '/order/otherIncome/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteOtherIncomeOrderItem(id) {
  return request({
    url: '/order/otherIncome/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchOtherIncomeOrderList(params) {
  return request({
    url: '/order/otherIncome/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectOtherIncomeOrderList(data) {
  return request({
    url: '/order/otherIncome/saveAndList',
    method: 'post',
    data
  });
}