var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      width: "1200px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      open: function ($event) {
        _vm.submitButtonDisabled = false;
      }
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "客户名称",
      "is-require": "",
      maxlength: "50",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("SelectBase", {
    attrs: {
      type: "customerType",
      "is-require": "",
      label: "客户类型"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c("InputBase", {
    attrs: {
      label: "客户公司全称"
    },
    model: {
      value: _vm.form.codeName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "codeName", $$v);
      },
      expression: "form.codeName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputNumber", {
    attrs: {
      "controls-position": "right",
      label: "授信额度(人民币单位/元)"
    },
    model: {
      value: _vm.form.creditMoney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "creditMoney", _vm._n($$v));
      },
      expression: "form.creditMoney"
    }
  }), _c("InputNum", {
    attrs: {
      label: "账龄（月）"
    },
    model: {
      value: _vm.form.accountAge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountAge", $$v);
      },
      expression: "form.accountAge"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "法人代表"
    },
    model: {
      value: _vm.form.legalPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "legalPerson", $$v);
      },
      expression: "form.legalPerson"
    }
  }), _c("InputBase", {
    attrs: {
      label: "法人电话号码"
    },
    model: {
      value: _vm.form.legalPersonPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "legalPersonPhone", _vm._n($$v));
      },
      expression: "form.legalPersonPhone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "法人身份证"
    },
    model: {
      value: _vm.form.legalPersonIDCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "legalPersonIDCard", $$v);
      },
      expression: "form.legalPersonIDCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "业务负责人"
    },
    model: {
      value: _vm.form.businessPrincipal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessPrincipal", $$v);
      },
      expression: "form.businessPrincipal"
    }
  }), _c("InputBase", {
    attrs: {
      label: "业务负责人电话"
    },
    model: {
      value: _vm.form.businessPrincipalPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessPrincipalPhone", _vm._n($$v));
      },
      expression: "form.businessPrincipalPhone"
    }
  }), _c("InputBase", {
    attrs: {
      label: "业务负责人身份证"
    },
    model: {
      value: _vm.form.businessPrincipalIDCard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessPrincipalIDCard", $$v);
      },
      expression: "form.businessPrincipalIDCard"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.selectArea
    },
    model: {
      value: _vm.form.customerArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerArea", $$v);
      },
      expression: "form.customerArea"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "区域经理"
    },
    on: {
      "on-select": _vm.selectManager
    },
    model: {
      value: _vm.form.customerAreaManager,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerAreaManager", $$v);
      },
      expression: "form.customerAreaManager"
    }
  }), _c("SelectStaff", {
    attrs: {
      label: "业务员"
    },
    model: {
      value: _vm.form.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "saleUserId", $$v);
      },
      expression: "form.saleUserId"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectStaff", {
    attrs: {
      label: "客服计调"
    },
    on: {
      "on-select": _vm.selectService
    },
    model: {
      value: _vm.form.customerAreaService,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerAreaService", $$v);
      },
      expression: "form.customerAreaService"
    }
  }), _c("InputBase", {
    attrs: {
      label: "客户备注"
    },
    model: {
      value: _vm.form.customerType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerType", $$v);
      },
      expression: "form.customerType"
    }
  }), _vm.visible ? _c("InputDepartment", {
    attrs: {
      disabled: "",
      "init-val": _vm.departmentItem
    },
    on: {
      "on-select": _vm.changeDepartment
    },
    model: {
      value: _vm.department,
      callback: function ($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }) : _vm._e()], 1), _vm.visible ? _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      label: "国家",
      code: "CountryType"
    },
    on: {
      "on-select": function ($event) {
        _vm.form.country = $event.label;
      }
    },
    model: {
      value: _vm.form.country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "country", $$v);
      },
      expression: "form.country"
    }
  }), _c("SelectRegion", {
    attrs: {
      label: "省市区"
    },
    on: {
      "on-select": _vm.handleRegion
    },
    model: {
      value: _vm.form.region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  }), _c("InputBase", {
    attrs: {
      label: "详细地址"
    },
    model: {
      value: _vm.form.addr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "addr", $$v);
      },
      expression: "form.addr"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "input-field"
  }), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "团队返款单价"
    },
    model: {
      value: _vm.form.teamSubsidyPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "teamSubsidyPrice", $$v);
      },
      expression: "form.teamSubsidyPrice"
    }
  }), _c("InputBase", {
    attrs: {
      label: "散客返款单价"
    },
    model: {
      value: _vm.form.individuaSubsidyPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "individuaSubsidyPrice", $$v);
      },
      expression: "form.individuaSubsidyPrice"
    }
  }), _c("InputBase", {
    attrs: {
      label: "备注"
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1), _c("FormAccount"), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleContact
    }
  }, [_vm._v(" 新增联系人 ")]), _c("TableBase", {
    attrs: {
      data: _vm.form.contacts,
      "column-data": _vm.columnContact,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "contact",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.contact,
            callback: function ($$v) {
              _vm.$set(scope.row, "contact", $$v);
            },
            expression: "scope.row.contact"
          }
        })];
      }
    }, {
      key: "mobilePhone",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.mobilePhone,
            callback: function ($$v) {
              _vm.$set(scope.row, "mobilePhone", _vm._n($$v));
            },
            expression: "scope.row.mobilePhone"
          }
        })];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase", {
          attrs: {
            type: "customerContactType"
          },
          model: {
            value: scope.row.type,
            callback: function ($$v) {
              _vm.$set(scope.row, "type", _vm._n($$v));
            },
            expression: "scope.row.type"
          }
        })];
      }
    }, {
      key: "IDCard",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.IDCard,
            callback: function ($$v) {
              _vm.$set(scope.row, "IDCard", _vm._n($$v));
            },
            expression: "scope.row.IDCard"
          }
        })];
      }
    }, {
      key: "telphone",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.telphone,
            callback: function ($$v) {
              _vm.$set(scope.row, "telphone", _vm._n($$v));
            },
            expression: "scope.row.telphone"
          }
        })];
      }
    }, {
      key: "faxNumber",
      fn: function ({
        scope
      }) {
        return [_c("InputBase", {
          model: {
            value: scope.row.faxNumber,
            callback: function ($$v) {
              _vm.$set(scope.row, "faxNumber", _vm._n($$v));
            },
            expression: "scope.row.faxNumber"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLinkBase", {
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  }), _c("div")], 1), _c("DialogContact", {
    ref: "contactRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };