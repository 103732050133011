import "core-js/modules/es.array.push.js";
import { Search, DialogItem } from './components';
import { Columns } from './tableConfig';
import { processAdd } from '@/utils';
import { getPayTypeLabel } from '@/config/select';
import { ShuttleTypeLabel } from '@/config/planType';
export default {
  components: {
    Search,
    DialogItem
  },
  data() {
    return {
      Columns,
      multipleSelection: []
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/shuttleBatchData'];
    },
    tableData() {
      const data = this.data;
      return data ? this.formatData(data.list) : [];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    }
  },
  mounted() {
    this.fetchData({
      shuttleType: 'shuttle'
    });
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billCheck/batchShuttle', {
        ...this.$refs.searchRef.searchParams,
        ...params
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          cashType,
          adultCount,
          childCount,
          busCompanyTypeSimples,
          shuttleType
        } = it;
        const item = {
          ...it,
          rowId: id,
          peopleCount: processAdd(adultCount, childCount),
          $cashType: getPayTypeLabel(cashType),
          $shuttleType: ShuttleTypeLabel[shuttleType]
        };
        const itemList = busCompanyTypeSimples || [];
        if (itemList.length) {
          itemList.forEach(s => {
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    batchUpdate({
      resId,
      resName
    }) {
      const opts = this.multipleSelection.map(it => {
        const {
          rowId: id
        } = it;
        return {
          id,
          resId,
          resName
        };
      });
      this.$store.dispatch('plan/fleet/batchUpdateShuttle', opts).then(() => {
        this.$bus.tip();
      });
    },
    showDialog() {
      this.$refs.dialogRef.show();
    }
  }
};