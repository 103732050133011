var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("RadioGroup", {
    attrs: {
      radios: _vm.radios
    },
    on: {
      change: _vm.changeRadio
    },
    model: {
      value: _vm.row.proxy,
      callback: function ($$v) {
        _vm.$set(_vm.row, "proxy", $$v);
      },
      expression: "row.proxy"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };