import { SwitchStatus, SelectDictionary, FormAccount, DropzoneBox } from '@/components/common';
import { isPhone } from '@/utils';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
export default {
  components: {
    SwitchStatus,
    SelectDictionary,
    FormAccount,
    DropzoneBox
  },
  mixins: [formMixin],
  data() {
    const checkPhone = val => {
      if (!val || !isPhone(val)) return false;
      return true;
    };
    return {
      visible: false,
      isUpdate: false,
      form: {
        isEnable: true
      },
      validateForm: {
        name: [{
          required: true,
          message: '名字不能为空'
        }, {
          min: 2,
          max: 20,
          message: '名字长度不低于2个字，最多不超过20个字'
        }],
        mobileIphone: [{
          required: true,
          message: '手机号不能为空'
        }, {
          validate: checkPhone,
          message: '手机号格式不正确'
        }]
      },
      index: -1
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      imageList: 'resource/common/imageList',
      // 照片信息
      imageListNew: 'resource/common/imageListNew',
      // 新上传的照片
      imageListNewDel: 'resource/common/imageDelAddList',
      // 新上传删除的照片
      editAccount: 'resource/account/editAccountInfo',
      // 账户信息
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData' // 字典信息
    }),
    getTitle() {
      return this.isUpdate ? '更新导游' : '添加导游';
    }
  },
  methods: {
    show(scope) {
      // 点击编辑后，并没有确认，直接取消，清理数据
      this.resetForm();
      if (scope) {
        this.index = scope.$index;
        const row = scope.row;
        this.form = {
          ...row
        };
        this.isUpdate = true;
        this.$store.dispatch('resource/guide/fetchImage', this.form.id);
      } else {
        this.isUpdate = false;
      }
      this.visible = true;
      this.$store.dispatch('resource/account/editAccount', this.form);
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    selectGuid(val) {
      // this.form.typeLabel = val.label
      this.form.type = val.label;
    },
    onSelectArea(val) {
      this.form.area = val.label;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      const picPaths = this.mergeImage();
      const {
        groupId,
        travelAgencyId,
        realName,
        createUserId
      } = this.userData;
      const userData = {
        groupId,
        travelAgencyId,
        realName,
        createUserId
      };
      const opts = {
        ...this.form,
        ...userData,
        ...this.editAccount,
        picPaths,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guide/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$parent.fetchList();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    resetForm() {
      // this.form = {}
      this.form = {
        // name: '',
        isEnable: true
        // mobileIphone: '',
      };

      //  this.form.isEnable = true
      // 清理图片
      this.$store.commit('resource/common/CLEAR_IMAGE');
      // 清理新增没有id 的图片
      this.$store.dispatch('resource/common/deleteAddImage');
    }
  }
};