import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    handleRoom(scope) {
      this.$parent.showRoomType(scope);
    }
  }
};