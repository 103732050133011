import "core-js/modules/es.array.push.js";
import { SelectGuide, SelectStaff, FooterBar } from '@/components/common';
import { auditFile } from '@/config/audit';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectGuide,
    SelectStaff,
    FooterBar
  },
  mixins: [searchMixin],
  data: () => ({
    btnTetx: ['取消归档', '归档'],
    tableData: [],
    searchParams: {},
    columnData: auditFile,
    checkAll: false,
    sendDate: [],
    total: 0,
    allCheck: []
  }),
  computed: {
    ...mapGetters({
      fileData: 'audit/index/fileData'
    }),
    planStatus(row) {
      const {
        planStatus
      } = row;
      const status = planStatus == 'FILE' ? '已归档' : '未归档';
      return status;
    }
  },
  watch: {
    checkAll: {
      handler: function (val) {
        this.formatCheck(val);
      }
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            startTripDateS,
            startTripDateE
          } = data;
          this.searchParams = data;
          this.sendDate = [startTripDateS, startTripDateE];
        }
      },
      immediate: true
    },
    fileData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData();
      } else {
        this.handleData(data);
      }
    },
    handleData(data) {
      let {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = list.map(it => {
        this.$set(it, 'checkAccountStatus', false);
        it.isCheck = false;
        if (it.planStatus == 'FILE') {
          it.isCheck = true;
        }
        return it;
      });
    },
    fetchData(params) {
      this.$store.dispatch('audit/index/fetchFile', params);
    },
    // 选择日期
    changeDate(type, date) {
      this.searchParams[type + 'S'] = date[0];
      this.searchParams[type + 'E'] = date[1];
    },
    // 下拉框选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    // 选择计划类型
    onSelectType(val) {
      this.searchParams.orderType = val ? val.value : '';
      this.searchParams.orderTypeLabel = val ? val.label : '';
    },
    // 处理全选
    formatCheck(status) {
      // const checkAllList = this.tableData.filter(
      //   it => it.isCheck || it.checkAccountStatus
      // )
      // if (checkAllList.length === this.tableData.length) {
      //   this.tableData = this.tableData.map(it => {
      //     it.checkAccountStatus = status
      //     return it
      //   })
      // } else {
      //   this.tableData = this.tableData.map(it => {
      //     it.checkAccountStatus = status
      //     return it
      //   })
      // }
      this.tableData = this.tableData.map(it => {
        if (it.planStatus != 'FILE') {
          it.checkAccountStatus = status;
        }
        return it;
      });
    },
    // 点击确认归档键
    handleCreate() {
      const checkList = this.tableData.filter(it => it.checkAccountStatus);
      if (!checkList.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有选中数据!'
        });
        return false;
      }
      this.$bus.open(() => {
        const data = {
          ids: [],
          planStatus: 'FILE'
        };
        checkList.forEach(it => {
          data.ids.push(it.id);
        });
        this.handleSubmit(data);
      }, `是否确认归档?`);
    },
    // 全选
    changeCheckAll(checkAll) {
      this.checkAll = checkAll;
    },
    // 单选选中触发
    handleCheckout(item) {
      this.$bus.open(() => {
        const {
          id
        } = item;
        const data = {
          ids: [id],
          planStatus: 'FILE'
        };
        this.handleSubmit(data);
      }, `是否确认归档?`);
    },
    // 取消审核触发
    handleCancelCheckout(item) {
      this.$bus.open(() => {
        const {
          id
        } = item;
        const data = {
          ids: [id],
          planStatus: 'CONFIRM'
        };
        this.handleSubmit(data);
      }, `是否取消归档?`);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // 归档
    handleSubmit(data) {
      const loading = this.$bus.loading();
      this.$store.dispatch('audit/index/getPlanFileState', data).then(() => {
        this.$bus.tip();
        // 刷新列表数据
        this.fetchData(this.searchParams);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    }
  }
};