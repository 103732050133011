var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "组名"
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  }), _c("BtnCreate", {
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.handleCreate
    }
  })], 1)]), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("DialogCaptain", {
    ref: "dialogRef"
  }), _c("DialogCaptainDetail", {
    ref: "detailRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };