import { orderListDetail } from '@/config/plan';
import { mapGetters } from 'vuex';
import { dictionaryMixin } from '@/mixin';
import { CustomerInfo } from '@/components/common';
import { Members } from '@/components/common';
export default {
  components: {
    Members,
    CustomerInfo
  },
  mixins: [dictionaryMixin],
  data() {
    return {
      columnData: orderListDetail,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      planOrderInfo: 'plan/index/planOrderInfo'
    })
  },
  watch: {
    planOrderInfo: {
      handler: 'handleInit',
      immediate: true
    }
  },
  methods: {
    handleInit(data) {
      this.tableData = data;
    }
  }
};