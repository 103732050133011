import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import SearchForm from '../SearchForm';
import { checkBillShopping, checkBillShoppingAll } from '@/config/billCheck';
import { tagViewMixin, excelMixin, permissionMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { TableTotalBox } from '@/components/common';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    SearchForm,
    TableList,
    TableTotalBox
  },
  mixins: [tagViewMixin, excelMixin, permissionMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillShoppingAll,
      total: 0,
      filename: '购物店对账',
      statisticData: [],
      statistic: {}
    };
  },
  computed: {
    shopData() {
      return this.$store.getters['bill/billCheck/shopping'];
    }
  },
  watch: {
    shopData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.getToday
          });
        } else {
          this.handleData(data);
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.$store.dispatch('bill/billCheck/fetchShopping', queryParam);
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    handleData(data) {
      const SubsidyTwo = this.hasSubsidyTwoPrice(); // 二返权限
      this.columnData = SubsidyTwo ? checkBillShoppingAll : checkBillShopping;
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      console.log('this.total:', this.total);
      if (!statistics) return;
      statistics.checkAccountMoney = statistics.checkAccountMoneyCount;
      statistics.unTotalIncome = statistics.unTotalPay;
      statistics.shopRebate = statistics.totalShopRebate;
      statistics.redEnvelope = statistics.totalRedEnvelope;
      statistics.totalRedEnvelopeReimbed = statistics.totalRedEnvelopeReimbed || 0;
      statistics.enterStoreMemberCount = statistics.enterStoreMemberCount || 0;
      statistics.peopleCount = `${statistics.totalAdultCount}大${statistics.totalChildCount}小${statistics.totalAccompanyCount}陪`;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.goods || [];
        const {
          id,
          plan,
          checkAccountStatus
        } = it;
        it.unTotalIncome = it.unPay;
        if (!checkAccountStatus) {
          it.checkAccountMoney = it.unPay;
        }
        const item = {
          rowId: id,
          ...it,
          ...plan
        };
        this.$set(item, 'ckStatus', false);
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    exporttExcel(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportShopExcel', searchParams).then(res => {
        const fileName = '购物店对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};