var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-driver",
    attrs: {
      width: "660px",
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.resetForm
    }
  }, [_c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "司机姓名",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("InputBase", {
    attrs: {
      label: "联系方式",
      maxlength: "11",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("div", {
    staticClass: "upload-content flex"
  }, [_c("div", {
    staticClass: "upload-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 驾驶证 ")]), _vm.visible ? _c("UploadBase", {
    attrs: {
      "show-file-list": false,
      "default-img": _vm.getDriverImage
    },
    on: {
      "on-delete": _vm.deleteLicensImage,
      "on-success": _vm.handleLicensImage
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "upload-item"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" 从业资格证 ")]), _vm.visible ? _c("UploadBase", {
    attrs: {
      "show-file-list": false,
      "default-img": _vm.getCertImage
    },
    on: {
      "on-delete": _vm.deleteCertificateImage,
      "on-success": _vm.handleCertificateImage
    }
  }) : _vm._e()], 1)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };