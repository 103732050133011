import { tableMixin, summaryMixin } from '@/mixin';
import { decimalSplit } from '@/utils/index';
export default {
  components: {},
  mixins: [tableMixin, summaryMixin],
  data() {
    return {
      statisticsKeys: ['income', 'incomed', 'unIncome']
    };
  },
  computed: {
    decimal() {
      return item => {
        return decimalSplit(item * 100) + '%';
      };
    }
  },
  methods: {
    onLineDetail({
      row
    }) {
      // this.resetPage()
      this.$parent.onLineDetail(row);
    },
    tableCellClassName({
      columnIndex,
      row
    }) {
      const incomedRate = row.incomedRate * 100;
      if (columnIndex == 4) {
        return 'primary-row';
      }
      if (columnIndex == 5) {
        if (incomedRate >= 80) {
          return 'primary-row';
        }
        if (incomedRate > 60 && incomedRate < 80) {
          return 'warning-row';
        }
        if (incomedRate <= 60) {
          return 'danger-row';
        }
      }
      return '';
    }
  }
};