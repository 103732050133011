import { TableList, DialogAccount
// FormCard,
// FormAccount,
// FormCardBetween,
// DialogDetail,
} from './components';
import { resourceAccount } from '@/config/resource';
import { parseDate } from '@/utils';
// import { DialogLog } from '@/components/common'
import { reloadMixin } from '@/mixin';
export default {
  components: {
    TableList,
    DialogAccount
    // FormCard,
    // FormCardBetween,
    // FormAccount,
    // TableList,
    // DialogDetail,
    // DialogLog,
  },
  mixins: [reloadMixin],
  data: () => ({
    link: '/resource/account-detail',
    tableData: [],
    columnData: resourceAccount,
    total: 0,
    searchParams: {
      name: '',
      cardNo: '',
      isEnable: -1 // -1 全部， 0 未启用， 1 启用
    }
  }),
  computed: {
    accountData() {
      return this.$store.getters['resource/account/accountDataOrigin'];
    }
  },
  watch: {
    accountData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('resource/account/fetchList', {
        ...this.searchParams,
        ...params
      });
    },
    handleAdd() {
      this.$refs.dialogRef.show();
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = this.formatList(list);
    },
    formatList(list) {
      return list.map(it => {
        it.initDate = it.initDate && parseDate(it.initDate);
        it.createTime = it.createTime && parseDate(it.createTime);
        return it;
      });
    }
  }
};