import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { customSubsidy } from '@/config/report';
import TableSubsidy from './TableSubsidy';
import { SelectCustom, BtnExport, TableTotalBox, SelectDictionary } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { searchMixin } from '@/mixin';
import { parseDate, parseMonth, parseTime } from '@/utils';
const date = new Date();
const today = parseDate(date);
let yearMonth = parseMonth(date);
yearMonth = yearMonth + '-01';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectCustom,
    BtnExport,
    TableTotalBox,
    SelectDictionary,
    TableSubsidy
  },
  mixins: [searchMixin],
  data() {
    return {
      tableLoading: false,
      startTripDate: [],
      columnData: customSubsidy,
      tableData: [],
      orderDate: [yearMonth, today],
      //today
      // endDate: [],
      reportPlanType: SELECTOR.reportPlanType,
      searchParams: {},
      total: 0,
      statistic: {},
      statisticData: []
    };
  },
  computed: {
    ...mapGetters({
      customerSubsidy: 'report/index/customerSubsidy'
    })
  },
  watch: {
    customerSubsidy: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
        } else {
          this.handleData(data);
        }
      },
      immediate: true
    }
  },
  methods: {
    handleData(data) {
      this.statisticData = [];
      let {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list.map(it => {
        it.countSlot = it.count;
        it.auditTime = it.auditTime ? parseTime(it.auditTime) : it.auditTime;
        return it;
      });
      const {
        adultCount,
        childCount,
        accompanyCount
      } = statistics;
      this.total = totalCount;
      this.statistic = statistics;
      this.statisticData.push({
        ...statistics,
        countSlot: statistics.count,
        number: `${adultCount}大${childCount}小${accompanyCount}陪`
      });
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 选择发团日期
    onChangData(type, val) {
      this.searchParams[type + 'S'] = val[0];
      this.searchParams[type + 'E'] = val[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    selectType(val) {
      this.searchParams.type = val ? val.label : '';
    },
    // 选择审核类型
    onSelectAuditStatus(val) {
      let {
        label,
        value
      } = val;
      value = value ? value.toUpperCase() : value;
      this.searchParams.auditProgressLabel = label;
      this.searchParams.auditProgress = value;
    },
    fetchData(searchParams) {
      this.$store.dispatch('report/index/fetchSubsidy', searchParams);
    },
    exportExcel() {
      const loading = this.$bus.loading();
      const searchParams = this.searchParams;
      this.$store.dispatch('report/index/exportSubsidy', searchParams).then(res => {
        const fileName = '客户返款统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};