import { SwitchStatus } from '@/components/common';
import { mapGetters } from 'vuex';
export default {
  components: {
    SwitchStatus
  },
  data: () => ({
    form: {
      name: '',
      expire: '',
      remarks: '',
      isEnable: ''
    }
  }),
  computed: {
    ...mapGetters({
      agreementItem: 'resource/common/editAgreement'
    })
  },
  watch: {
    agreementItem: [{
      handler: function (val) {
        this.form = val;
      },
      immediate: true
    }]
  },
  methods: {
    handleChange(type, val) {
      this.form[type] = val;
      this.$store.dispatch('resource/account/editAgreement', this.form);
    }
  }
};