var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "ticket-choose",
    attrs: {
      title: "选择签单",
      width: "960px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleComfirm,
      close: _vm.hide
    }
  }, [_c("FormBase", {
    attrs: {
      "label-width": "60px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("span", [_vm._v("单据范围")]), _c("InputBase", {
    attrs: {
      value: _vm.ticket.start
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.ticket, "start", $event);
      }
    }
  }), _c("span", {
    staticClass: "pl5"
  }, [_vm._v("~")]), _c("InputBase", {
    attrs: {
      value: _vm.ticket.end
    },
    on: {
      "update:value": function ($event) {
        return _vm.$set(_vm.ticket, "end", $event);
      }
    }
  }), _c("div", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 搜索 ")])], 1)], 1)]), _c("div", {
    staticClass: "content"
  }, [_vm.visible ? _c("CheckboxGroup", {
    attrs: {
      options: _vm.ticketData,
      edit: ""
    },
    on: {
      "on-change": _vm.changeCheckbox
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };