import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
// 系统配置
import { fetchType, fetchItem, update, updateAudit, fetchAudit, fetchGuideNotice, setGuideNotice, getDDApplyConfig, setDDApplyConfig } from '@/api/system/systemConf';
import { getUserData } from '@/utils/cookie';
const formatConfigValue = list => {
  const res = [];
  list.forEach(it => {
    const {
      code,
      configs
    } = it;
    let str = '';
    if (configs.length) {
      configs.forEach(cfg => {
        const {
          values
        } = cfg;
        values.forEach(val => {
          str = `${code}:${cfg.code}:${val.code}`;
          const item = {
            key: str,
            val: val.value,
            name: val.name
          };
          res.push(item);
        });
      });
    }
  });
  return res;
};
const dfaultState = () => ({
  roleData: null,
  configData: null,
  configType: {}
});
const state = dfaultState();
const getters = {
  roleData: state => state.roleData,
  configData: state => state.configData,
  configType: state => state.configType
};
const actions = {
  fetchType({
    commit
  }) {
    return fetchType().then(res => {
      const {
        data
      } = res;
      const configRole = formatConfigValue(data);
      commit('FETCH_DATA', data);
      commit('CONFIG_ROLE_DATA', configRole);
      return data;
    });
  },
  fetchItem({
    commit
  }, configTypeId) {
    return fetchItem({
      configTypeId
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_TYPE_DATA', {
        id: configTypeId,
        data
      });
      return data;
    });
  },
  update({
    commit
  }, opts) {
    return update(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 代收审核配置信息
  fetchAudit({
    commit
  }) {
    return fetchAudit().then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 代收审核配置 更新
  updateAudit({
    commit
  }, opts) {
    return updateAudit(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取导游帮助信息
  fetchGuideNotice({
    commit
  }, id) {
    return fetchGuideNotice(id);
  },
  // 设置导游帮助信息
  setGuideNotice({
    commit
  }, opts) {
    return setGuideNotice(opts);
  },
  // 获取订单审批信息
  getDDConfig({
    commit
  }, opts) {
    const {
      travelAgencyId
    } = getUserData();
    return getDDApplyConfig({
      travelAgencyId
    }).then(res => {
      if (!res) return;
      return res.data;
    });
  },
  // 设置订单审批信息
  setDDConfig({
    commit
  }, opts) {
    const {
      travelAgencyId
    } = getUserData();
    return setDDApplyConfig({
      ...opts,
      travelAgencyId
    });
  }
};
const mutations = {
  FETCH_DATA(state, data) {
    state.configData = data;
  },
  CONFIG_ROLE_DATA(state, data) {
    state.roleData = data;
  },
  FETCH_TYPE_DATA(state, {
    id,
    data
  }) {
    state.configType[id] = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};