import { tableMixin } from '@/mixin';
export default {
  components: {},
  mixins: [tableMixin],
  data() {
    return {};
  },
  methods: {
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    }
  }
};