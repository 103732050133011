export default {
  props: {
    isBill: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        guideId: ''
      },
      sendDate: [],
      endDate: []
    };
  },
  methods: {
    setDefVal(val) {
      val = !val ? ['', ''] : val;
      return val;
    },
    changeStarTripDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.form.startTripDateS = starDate;
      this.form.startTripDateE = endDate;
    },
    changeEndTripDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.form.endTripDateS = starDate;
      this.form.endTripDateE = endDate;
    }
  }
};