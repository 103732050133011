import "core-js/modules/es.array.push.js";
import { InputDepartment, SelectStaff, BtnExport } from '@/components/common';
import { departKeys } from '@/config/dictionary';
export default {
  components: {
    InputDepartment,
    SelectStaff,
    BtnExport
  },
  props: {
    path: {
      type: String,
      required: true
    },
    isPay: {
      type: Boolean,
      default: true
    },
    resourceId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      searchParams: {},
      department: ''
    };
  },
  watch: {
    resourceId: {
      handler: 'setResourceId'
    }
  },
  created() {
    this.billDate = [this.getToday, this.getToday];
    this.cashLabel = this.isPay ? '付款人' : '收款人';
    this.btnLabel = this.isPay ? '添加付款' : '添加收款';
  },
  methods: {
    setResourceId(val) {
      this.searchParams.resourceId = val;
    },
    selectStaff(val) {
      this.searchParams.cashUserId = val.value;
    },
    changeStarDate(val) {
      val = val || ['', ''];
      const [starDate, endDate] = val;
      this.searchParams.startCashDate = starDate;
      this.searchParams.endCashDate = endDate;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    // 添加收款
    handleCreate() {
      // 存储统计数据
      const splitPath = this.path.substring(this.path.lastIndexOf('/') + 1).split('-')[0];
      const currentData = this.$store.getters['bill/billDown/' + splitPath];
      const {
        statistics
      } = currentData ? currentData : {
        sumMoney: 0,
        usedMoney: 0
      };
      this.$store.commit('bill/billDown/BILL_BALANCE', statistics);
      this.$router.push({
        path: this.path
      });
    },
    // 搜索
    handleSearch() {
      const {
        path
      } = this.$route;
      const search = this.searchParams;
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search
      });

      // 搜索的时候重置分页
      this.$store.dispatch('tagsView/appendVisiteViewPage', {
        path,
        page: 1
      });
      this.$emit('search', search);
    }
  }
};