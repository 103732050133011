import InputResource from '@/components/common/Input/InputResource.vue';
import HotelTable from '../components/ReserveHotelTable.vue';
import FleetTable from '../components/ReserveFleetTable.vue';
import ScenicTable from '../components/ReserveScenicTable.vue';
import RestaurantTable from '../components/ReserveRestaurantTable.vue';
import { FooterBar, SelectRegion } from '@/components/common';
import { mapGetters } from 'vuex';
import { reserveMixin } from '@/mixin';
const rules = {
  region: [{
    required: true,
    message: '客源地不能为空',
    trigger: 'change'
  }],
  code: [{
    required: true,
    message: '预定中心不能为空',
    trigger: 'change'
  }]
};
export default {
  components: {
    InputResource,
    RestaurantTable,
    HotelTable,
    ScenicTable,
    FleetTable,
    FooterBar,
    SelectRegion
  },
  mixins: [reserveMixin],
  data() {
    return {
      hotelData: [],
      fleetData: [],
      scenicData: [],
      restaurantData: [],
      visible: false,
      guideRemark: '',
      form: {
        region: [],
        code: ''
      },
      rules,
      checkHotel: false,
      checkRestaurant: false,
      checkFleet: false,
      checkScenic: false,
      planId: -1
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    }),
    getPeopleCount() {
      if (!this.currentOrder) return '';
      const {
        adultCount,
        childCount,
        accompanyCount
      } = this.currentOrder;
      return `${adultCount}大${childCount}小${accompanyCount}陪`;
    }
  },
  mounted() {
    if (!this.currentOrder) {
      this.$router.go(-1);
    } else {
      const {
        id
      } = this.currentOrder;
      this.$store.dispatch('order/index/fetchDetail', {
        id
      }).then(data => {
        if (!data) return;
        this.planId = data.planId;
      });
      this.fetchData(id);
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    // 修改省市区
    changeRegion(val) {
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.form[key] = val[key];
      });
    },
    selectResource(val) {
      this.form.code = val.value;
      this.form.companyCode = val.code;
    },
    handleClose() {
      this.$bus.emit('order:booking-close');
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          this.$bus.tip({
            type: 'warning',
            message: '客源地和预定中心必选'
          });
          return false;
        }
        const {
          customerTripNumber,
          orderType,
          orderNumber,
          planNumber
        } = this.currentOrder;
        const {
          companyCode
        } = this.form;
        const {
          hotelRef,
          fleetRef,
          scenicRef
        } = this.$refs;

        // 校验
        if (this.checkHotel && !this.validateHotel(hotelRef.tableData)) {
          return;
        }
        if (this.checkFleet && !this.validateFleet(fleetRef.tableData)) return;
        if (this.checkScenic && !this.validateScenic(scenicRef.tableData)) return;
        if (this.checkRestaurant && !this.validateRestaurant(this.restaurantData)) return;
        const hotelOrders = this.checkHotel ? this.processHotel(hotelRef.tableData) : [];
        const busOrders = this.checkFleet ? this.processFleetData(fleetRef.tableData) : [];
        const scenicOrders = this.checkScenic ? this.processScenicData(scenicRef.tableData) : [];
        const restaurantOrders = this.checkRestaurant ? this.processRestaurant(this.restaurantData) : [];
        const opts = {
          isBooking: true,
          companyCode,
          customerOrderId: this.currentOrder.id,
          hotelOrders,
          busOrders,
          scenicOrders,
          restaurantOrders,
          guideOrder: {},
          customerTripNumber,
          orderNumber,
          orderType,
          planNumber,
          sendPlanStatus: false
        };
        this.$store.dispatch('order/resource/bookingSend', opts).then(() => {
          this.handleClose();
          this.$bus.tip();
        });
      });
    }
  }
};