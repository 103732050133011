import "core-js/modules/es.array.push.js";
import { FooterBar, BillInput } from '@/components/common';
import { tagViewMixin } from '@/mixin';
import { TYPES, CashFeeType } from '@/config/billDown';
import { formatDownData } from '@/views/billDown/help';
import { COLUMNS_VALID as columns, COLUMNS_VALID_COMPLETE as columnsComplete } from './table';
import { PersonCount } from '@/components/common';
import { StatusAuditProgress, ResourceItem } from '@/components/common';
import { rowspanMixin } from '@/mixin';
import { resourceOrderLabel } from '@/config/resourceOrder';
import billDownMixin from '../billDownMixin';
const VERIFICATION_OTHER_PAY = 'VERIFICATION_OTHER_PAY';
export default {
  components: {
    BillInput,
    FooterBar,
    PersonCount,
    StatusAuditProgress,
    ResourceItem
  },
  mixins: [tagViewMixin, rowspanMixin, billDownMixin],
  data() {
    return {
      TYPES,
      CashFeeType,
      columns,
      columnsComplete,
      billCheckAll: false,
      form: {},
      resourceId: '',
      backPath: '/bill/other-pay',
      completeData: []
    };
  },
  computed: {
    getStatus() {
      return val => {
        return val ? resourceOrderLabel[val.toUpperCase()] : '';
      };
    },
    curItem() {
      return this.$store.getters['bill/billDown/otherPay/curItem'];
    },
    tableData() {
      return this.curItem ? [{
        ...this.curItem,
        downPayed: this.curItem.unPay,
        owePayCopy: this.curItem.unPay,
        isChecked: true
      }] : [];
    }
  },
  watch: {
    curItem: {
      handler: function (data) {
        if (!data) return;
        const {
          id,
          unPay
        } = data;
        this.form.money = unPay;
        this.form.userMoney = unPay;
        this.fetchComplete(id);
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.curItem) {
      this.goBack();
    }
  },
  methods: {
    fetchComplete(orderId) {
      return this.$store.dispatch('bill/billDown/otherPay/fetchComplete', {
        orderId,
        cashType: VERIFICATION_OTHER_PAY
      }).then(res => {
        if (!res || !res.data) return;
        const {
          data
        } = res;
        this.completeData = data.list;
      });
    },
    fetchBalance() {
      if (!this.resourceId) return;
      return this.$store.dispatch('bill/billDown/otherPay/fetchBalance', {
        rid: this.resourceId,
        cashType: VERIFICATION_OTHER_PAY
      });
    },
    changeMoney(money) {
      this.$refs.tableRef.caclDownMoney(money);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
      this.$refs.tableRef.handleSelect(resourceId);
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    },
    // 保存
    handleSave() {
      const form = this.form;
      if (!form.cashType || !form.resourceId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择直营单位和资源类型'
        });
        return;
      }
      this.$refs.formRef.handleValidation().then(form => {
        const list = this.tableData;
        const filterList = list.filter(it => it.downPayed != '');
        const opts = formatDownData({
          list: filterList,
          form,
          cashAction: CashFeeType.INCOME,
          cashMode: form.cashMode
        });
        opts.cashes = opts.cashes.map(it => {
          return {
            ...it,
            reimbedUnitType: 'OTHER'
          };
        });
        this.$store.dispatch('bill/billDown/otherPay/addPay', {
          ...opts,
          cashType: VERIFICATION_OTHER_PAY,
          resourceId: this.form.resourceId
        }).then(() => {
          this.$bus.tip();
          this.handleClose();
          this.goBack();
        });
      });
    }
  }
};