import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      size: 'app/getFontSize'
    }),
    getFontSize() {
      return `fontSize: ${this.size}px`;
    }
  }
};