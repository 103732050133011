import "core-js/modules/es.array.push.js";
import FormInput from '../components/FormInputNew';
import { FooterBar } from '@/components/common';
import { orderType as OrderType, OrderAuditEmit } from '@/config/order';
import { mapGetters } from 'vuex';
import orderCreateMixin from '../orderCreate';
import { permissionMixin } from '@/mixin';
export default {
  name: 'TeamCreate',
  components: {
    FormInput,
    FooterBar
  },
  mixins: [orderCreateMixin, permissionMixin],
  data() {
    return {
      orderType: OrderType.team,
      redirectPath: '/order/team-input-update',
      form: null
    };
  },
  computed: {
    ...mapGetters({
      editForm: 'order/team/editForm'
    })
  },
  mounted() {
    const {
      type
    } = this.$route.query;
    if (type == 'create') return;
    this.$nextTick(() => {
      this.form = this.editForm;
      const customerId = this.editForm ? this.editForm.customerId : null;
      this.$store.commit('order/index/CUR_CUSTOMER_ID', customerId);
    });
  },
  methods: {
    processCreate(auditStatus = OrderAuditEmit.NEW_WAIT) {
      this.$refs.formInputRef.createOrUpdate(auditStatus).then(res => {
        const {
          id,
          customerId
        } = res;
        this.$bus.tip();

        // 清理缓存
        this.$store.commit('order/index/CUR_CUSTOMER_ID', customerId);
        this.$store.commit('order/team/EDIT_FORM', null);
        this.$store.commit('order/reception/CUR_RECEPTION_LIST', null);

        // 跳转连接
        this.$router.push({
          path: this.redirectPath,
          query: {
            id,
            customerId,
            type: 'update',
            isNew: true
          }
        });
      });
    },
    handleCreate() {
      this.processCreate();
    },
    handleCreateAndNotAudit() {
      this.processCreate(OrderAuditEmit.NO_AUDITED);
    }
  }
};