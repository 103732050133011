import { SwitchStatus, SelectRestaurant, SelectHotel, SelectScenic } from '@/components/common';
// import { mapGetters } from 'vuex'
import { formMixin } from '@/mixin';
const itemListKeys = () => ({
  isCommon: true,
  name: '',
  type: '',
  remark: '',
  id: ''
});
export default {
  components: {
    SwitchStatus,
    SelectRestaurant,
    SelectHotel,
    SelectScenic
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {
        ...itemListKeys()
      },
      validateForm: {
        name: [{
          required: true,
          message: '名字不能为空'
        }, {
          min: 2,
          max: 20,
          message: '名字长度不低于2个字，最多不超过20个字'
        }],
        type: [{
          required: true,
          message: '请选择票据类型'
        }]
      }
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '更新票据' : '添加票据';
    }
  },
  methods: {
    show(scope) {
      if (scope) {
        this.index = scope.$index;
        const row = scope.row;
        this.form = {
          ...row
        };
        this.isUpdate = true;
      } else {
        this.form = {
          ...itemListKeys()
        };
        this.isUpdate = false;
      }
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    selectTickt(val) {
      this.form.typeLabel = val.label;
      this.form.type = val.value;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      this.$bus.loading();
      const opts = {
        ...this.form
      };
      this.$parent.createOrUpdate(opts);
    },
    handleSelect() {}
  }
};