import { StatusAudit } from '@/components/common';
import { PersonCount, Members } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin } from '@/mixin';
import { TYPES } from '@/config/audit';
import { checkBillHotel, statisticsKeys } from '@/config/billCheck';
import { calcCheckedMoney } from '@/views/billCheck/helper';
import billBaseMixin from '../billBaseMixin';
export default {
  components: {
    PersonCount,
    StatusAudit,
    Members
  },
  mixins: [tableMixin, rowspanMixin, billMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      headMergeNum: 8,
      tailMergeNum: checkBillHotel.length - 9,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys: ['roomCount', 'freeCount', 'otherFee', ...statisticsKeys],
      type: TYPES.hotel
    };
  }
};