import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 基础备注组件
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean} [isRequire=false] - 是否必填项
 * @vue-data {String} message - 输入框的值
 */
import { getTextLength } from '@/utils';
const MAX_LEN = 5000;
export default {
  name: 'InputRemark',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '备注'
    },
    minRows: {
      type: Number,
      default: 2
    },
    maxRows: {
      type: Number,
      default: 6
    }
  },
  data() {
    this.placeholder = '请输入' + this.label;
    return {};
  },
  methods: {
    validate(ev) {
      const {
        value
      } = ev.target;
      if (getTextLength(value) > MAX_LEN) {
        this.$bus.tip({
          type: 'warning',
          message: `备注信息长度不超过${Math.ceil(MAX_LEN / 2)}字`
        });
      }
    }
  }
};