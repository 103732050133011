import "core-js/modules/es.array.push.js";
import { TopTitle, SelectDictionary } from '@/components/common';
import { auditCustomProxyEdit } from '@/config/audit';
import { RadioProxy } from '@/components/common';
import { auditMixin } from '@/mixin';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
const defaultState = () => ({
  title: '',
  total: 0,
  type: '',
  remarks: ''
});
export default {
  components: {
    TopTitle,
    SelectDictionary,
    RadioProxy
  },
  mixins: [auditMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    },
    checkAccountStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnData: [],
      type: 'customerCollectList',
      // 对应接口的字段
      rowId: 1000000000000
    };
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? auditCustomProxyEdit : filterCustomColumn(auditCustomProxyEdit);
  },
  methods: {
    // 新增一条
    handlePlus() {
      this.tableData.push({
        ...defaultState(),
        id: ++this.rowId,
        isCustom: true
      });
    },
    selectType(row, val) {
      const {
        id,
        label
      } = val;
      row.type = label;
      row.typeId = id;
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price
      } = obj;
      let total = count * price;
      return total;
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      const doDelete = () => {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      };
      this.$bus.open(() => {
        if (isCustom) {
          doDelete();
        } else {
          this.$store.dispatch('order/index/deleteProxy', id).then(() => {
            doDelete();
          });
        }
      });
    },
    createOrUpdate(customerOrderId) {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请添加数据'
        });
        return Promise.reject();
      }
      if (!this.validateForm(this.tableData)) {
        return Promise.reject();
      }

      // 新增的项目加上 订单 id
      const copyData = this.tableData.map(it => {
        it.customerOrderId = customerOrderId;
        return it;
      });
      return this.$store.dispatch('order/index/createOrUpdateProxy', copyData);
    },
    validateForm(tableData) {
      if (!tableData.length) return false;
      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        if (!row.total) {
          this.$bus.tip({
            type: 'warning',
            message: '代收金额不能为空'
          });
          return false;
        }
      }
      return true;
    }
  }
};