var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: _vm.getTitle,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.resetForm
    }
  }, [_c("div", {
    staticClass: "form-wrapper content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "车牌号",
      maxlength: "8",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.licenceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "licenceNumber", $$v);
      },
      expression: "form.licenceNumber"
    }
  })], 1), _c("div", {
    staticClass: "upload-content"
  }, [_c("strong", [_vm._v("行驶证")]), _c("div", {
    staticClass: "upload-item"
  }, [_vm.visible ? _c("UploadBase", {
    attrs: {
      "show-file-list": false,
      "default-img": _vm.getDriverImage
    },
    on: {
      "on-delete": _vm.deleteLicensImage,
      "on-success": _vm.handleLicensImage
    }
  }) : _vm._e()], 1), _c("strong", [_vm._v("运营资格证")]), _c("div", {
    staticClass: "upload-item"
  }, [_vm.visible ? _c("UploadBase", {
    attrs: {
      "show-file-list": false,
      "default-img": _vm.getCertImage
    },
    on: {
      "on-delete": _vm.deleteCertificateImage,
      "on-success": _vm.handleCertificateImage
    }
  }) : _vm._e()], 1)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };