import "core-js/modules/es.array.push.js";
import { tableMixin, orderMixin, permissionMixin, systemMixin } from '@/mixin';
import { Members, PersonCount } from '@/components/common';
import { CustomerInfo } from '@/components/common';
import { formatIosDate } from '@/utils';
import { orderType, OrderAuditEmit } from '@/config/order';
import orderMx from '../../orderMixin';
export default {
  components: {
    Members,
    PersonCount,
    CustomerInfo
  },
  mixins: [tableMixin, orderMixin, permissionMixin, systemMixin, orderMx],
  props: {
    scrollLeft: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formatIosDate,
      tableHeader: null,
      tableElItems: [],
      orderType: orderType.personal
    };
  },
  computed: {
    getLeftPosition() {
      return this.$store.state.app.sidebar.opened ? 188 : 74;
    },
    getAddAuditStatus() {
      return val => {
        return val == OrderAuditEmit.NO_AUDITED ? '录单中' : '';
      };
    }
  },
  watch: {
    scrollLeft: {
      handler: function (pos) {
        let left = -pos + this.getLeftPosition;
        this.tableHeader.scrollLeft = left;
        if (!this.tableElItems.length) return;
        this.tableElItems.forEach(el => {
          el.scrollLeft = left;
        });
      }
    },
    tableData: {
      handler: function (data) {
        if (data && data.length) {
          this.$nextTick(() => {
            setTimeout(() => {
              // this.hideScrollBar()
              // this.handleScroll()
            }, 200);
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    addAuditStatus({
      row
    }) {
      const {
        isCancel,
        addAuditStatus
      } = row;
      // 是否开启订单审核
      const code = this.getConfigStatus('order:openOrderAudit:openOrderAuditValue');
      if (code) {
        if (addAuditStatus && addAuditStatus == this.reviewerStatusType.audited && !isCancel) {
          return false;
        }
        return true;
      } else {
        return isCancel;
      }
    },
    hideScrollBar() {
      // 处理单个团的滚动条
      const tableElems = document.querySelectorAll('.personal-ungroup-table');
      for (let i = 0; i < tableElems.length; i++) {
        let tableEl = tableElems[i].querySelector('.el-table');
        let el = tableEl.getElementsByClassName('is-scrolling-left')[0];
        if (el) {
          el.style.overflowX = 'hidden';
          this.tableElItems.push(el);
        }
      }
      const tableHeader = this.$refs.tableRef.$el.querySelector('.el-table__header-wrapper');
      this.tableHeader = tableHeader;
    },
    handleScroll() {
      const tables = document.querySelectorAll('.table-total');
      let sumEl = tables[0].querySelector('.is-scrolling-left');
      let sumBody = null;
      if (sumEl) {
        sumEl ? sumEl.style.overflowX = 'hidden' : null;
        sumBody = sumEl.getElementsByTagName('table')[0];
      }
      let totalEl = tables[tables.length - 1];
      const tableInner = totalEl.querySelector('.el-table__body-wrapper');
      tableInner.addEventListener('scroll', () => {
        const left = tableInner.getBoundingClientRect().x;
        const position = -left + this.getLeftPosition;
        this.tableElItems.forEach(el => {
          el.scrollLeft = position;
        });
        this.tableScrollLeft = left;
        sumBody.scrollLeft = position;
      }, false);
    },
    handleCheck(scope, val) {
      this.$set(scope.row, 'checked', val);
      this.$parent.handleCheckAllData();
    },
    handleCopy(scope) {
      this.$parent.copyTableRow(scope);
    },
    handleOnkeyCopy(scope) {
      this.$bus.open(() => {
        const {
          id
        } = scope.row;
        this.$store.dispatch('order/index/copyOrder', id).then(() => {
          this.$parent.batchFetchData();
        });
      }, '确定要复制该订单吗？');
    },
    handleRevoke(scope) {
      this.$parent.revokeTableRow(scope);
    },
    handleBooking(scope) {
      this.$store.commit('order/index/CUR_ORDER', scope.row);
      this.$router.push({
        path: '/order/reserve-booking'
      });
    },
    handleCommand(scope, command) {
      this[command](scope);
    },
    handlePrint(scope) {
      const {
        id,
        planId,
        orderType
      } = scope.row;
      // 打印页面的打印权限类型
      const gateUrl = 'personal';
      const routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId,
          component: 'Receipt',
          planType: orderType,
          gateUrl
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    linkDetail(data) {
      const {
        planCustomerNumber
      } = data.row;
      if (!planCustomerNumber) return false;
      this.$parent.showTableDetail(data);
    }
  }
};