var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "车队选择",
      width: "760px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm,
      cancel: _vm.hide
    }
  }, [_c("InputFleet", {
    attrs: {
      label: "",
      clearable: ""
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.form.resName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "resName", $$v);
      },
      expression: "form.resName"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };