export const PAY_TYPE = {
  SIGN: 'SIGN',
  // 挂账
  GUIDE: 'GUIDE',
  // 导游现付
  GUIDESIGN: 'GUIDESIGN',
  // 导游签单
  AGENCY: 'AGENCY',
  // 公司转账
  CARD: 'CARD',
  // 刷卡
  NET: 'NET',
  // 网付
  ALIPAY_E_CODE: 'ALIPAY_E_CODE',
  // 企业码付
  alipayecode: 'ALIPAY_E_CODE' // 企业码付
};
export const PAY_TYPE_LABEL = {
  SIGN: '挂账',
  // 挂账
  GUIDE: '导游现付',
  // 导游现付
  GUIDESIGN: '导游签单',
  // 导游签单
  AGENCY: '公司转账',
  // 公司转账
  CARD: '刷卡',
  // 刷卡
  NET: '网付',
  // 网付
  ALIPAY_E_CODE: '企业码付',
  // 网付
  alipayECode: '企业码付' // 网付
};

// 支付成功状态  0
export const PAY_ERR_STATUS = {
  SUCCESS: 1,
  FAIL: 0
};
export const PAY_NET_TYPE = {
  WECHAT: 'wechat',
  ALIPAY: 'alipay',
  UNIONPAY: 'union'
};
export const PAY_NET_TYPE_LABEL = {
  WECHAT: '微信',
  ALIPAY: '支付宝',
  UNIONPAY: '银联'
};