import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { BtnExport, TableTotalBox, SelectStaff } from '@/components/common';
import TableList from './TableList';
import { outOpUserList } from '@/config/report';
import { searchMixin } from '@/mixin';
import { parseDate, decimalSplit } from '@/utils';
import { mapGetters } from 'vuex';
import { processAdd, processMinus } from '@/utils/number';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    TableList,
    BtnExport,
    TableTotalBox,
    SelectStaff
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    columnData: outOpUserList,
    tableData: [],
    total: 0,
    statisticData: [],
    statistic: 0,
    searchParams: {
      startTripDateS: today
    }
  }),
  computed: {
    ...mapGetters({
      outOpUserData: 'report/index/outOpUserData'
    })
  },
  watch: {
    outOpUserData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    onLink({
      row,
      type
    }) {
      const {
        customerName,
        customerId
      } = row;
      this.$router.push({
        path: this.link[type],
        query: {
          customerName,
          customerId
        }
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'orderDate') {
        this.searchParams.orderDateS = date[0];
        this.searchParams.orderDateE = date[1];
      }
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list.map(it => {
        const {
          feeUnIncome,
          collectFee,
          feeIncomed,
          totalIncome,
          feeIncome
        } = it;
        const total = processAdd(totalIncome, feeIncome);

        // 已收+代收
        it.$feeIncomed = processAdd(collectFee, feeIncomed);
        // 未收-代收
        it.$feeUnIncome = processMinus(feeUnIncome, collectFee);
        it.grossProfitRate = total ? decimalSplit(Number(it.totalQuoteGrossProfit) / total * 100) + '%' : total;
        return it;
      });
      this.total = totalCount;
      this.statistic = statistics;
      const totalSum = statistics.totalIncome + statistics.feeIncome;
      statistics.$feeIncomed = processAdd(statistics.collectFee, statistics.feeIncomed);
      statistics.$feeUnIncome = processMinus(statistics.feeUnIncome, statistics.collectFee);
      statistics.grossProfitRate = totalSum ? decimalSplit(Number(statistics.totalQuoteGrossProfit) / totalSum * 100) + '%' : totalSum;
      this.statisticData.push(statistics);
    },
    fetchData(queryParam) {
      this.$store.dispatch('report/index/fetchOutopuser', queryParam);
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/exportOutopuser', this.searchParams).then(res => {
        const fileName = '销售客服绩效统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};