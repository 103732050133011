import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 购物管理
import Layout from '@/layout';
const shoppingRouter = {
  path: '/shopping',
  component: Layout,
  redirect: '/shopping/input',
  name: 'shop',
  meta: {
    title: 'shop',
    icon: 'icon-wentichaoshi'
  },
  children: [{
    path: '/shopping/input',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopInput'))),
    name: 'shopInput',
    // 购物录入
    meta: {
      title: 'shopInput',
      icon: 'icon-dingdan'
    }
  }, {
    path: '/shopping/input-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopInputCreate'))),
    name: 'shopInputCreate',
    // 购物录入
    meta: {
      title: 'shopInputCreate',
      icon: 'icon-dingdan',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/input-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopInputDetail'))),
    name: 'shopInputDetail',
    // 购物店详情
    meta: {
      title: 'shopInputDetail',
      icon: 'icon-dingdan',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/finance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopFinance'))),
    name: 'shopFinance',
    // 购物账务
    meta: {
      title: 'shopFinance',
      icon: 'icon-ic_droptohistory'
    }
  },
  // {
  //   path: '/shopping/audit',
  //   component: () =>
  //     import(/* webpackChunkName: "shopping" */ '@/views/shopping/shopAudit'),
  //   name: 'shopAudit', // 购物审核
  //   meta: { title: 'shopAudit', icon: 'icon-caiwuguanli' },
  // },
  // {
  //   path: '/shopping/check',
  //   component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/shopCheck'),
  //   name: 'shopCheck', // 购物对账
  //   meta: { title: 'shopCheck', icon: 'icon-caiwuguanli' },
  // },
  {
    path: '/shopping/shop-collect',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect'))),
    name: 'shopCollect',
    // 购物收款
    meta: {
      title: 'shopCollect',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/shopping/shop-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect/create'))),
    name: 'shopCollectCreate',
    // 新增购物收款
    meta: {
      title: 'shopCollectCreate',
      icon: 'icon-caiwuguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/shop-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollect/detail'))),
    name: 'shopCollectDetail',
    // 编辑购物收款
    meta: {
      title: 'shopCollectDetail',
      icon: 'icon-caiwuguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/shop-collect-subsidy',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy'))),
    name: 'shopCollectSubsidy',
    // 购物二返收款
    meta: {
      title: 'shopCollectSubsidy',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/shopping/shop-create-subsidy',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy/create'))),
    name: 'shopCollectCreateSubsidy',
    // 新增二返购物收款
    meta: {
      title: 'shopCollectCreateSubsidy',
      icon: 'icon-caiwuguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/shop-detail-subsidy',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCollectSubsidy/detail'))),
    name: 'shopCollectSubsidyDetail',
    // 编辑购物二返收款
    meta: {
      title: 'shopCollectSubsidyDetail',
      icon: 'icon-caiwuguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/shopping/total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping'))),
    name: 'shopTotal',
    // 购物统计
    meta: {
      title: 'shopTotal',
      icon: 'icon-zonglan'
    },
    redirect: '/shopping/total/shop',
    children: [{
      path: '/shopping/total/shop',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopStatistics'))),
      name: 'shopStatistics',
      //单商店数据分析表
      meta: {
        title: 'shopStatistics'
      }
    }, {
      path: '/shopping/total/entry',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopEntry'))),
      name: 'shopStaticsEntry',
      //单商店数据分析表
      meta: {
        title: 'shopStaticsEntry'
      }
    }, {
      path: '/shopping/total/guide',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/guideStatistics'))),
      name: 'guideStatistics',
      // 按导游统计
      meta: {
        title: 'guideStatistics'
      }
    }, {
      path: '/shopping/total/guideLeader',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/guideLeaderStatistics'))),
      name: 'guideLeaderStatistics',
      // 按导游组长统计
      meta: {
        title: 'guideLeaderStatistics'
      }
    }, {
      path: '/shopping/total/guideManage',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/guideManageStatistics'))),
      name: 'guideManageStatistics',
      // 按导管统计
      meta: {
        title: 'guideManageStatistics'
      }
    }, {
      path: '/shopping/total/guide-manage-analyze',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/guideManageAnalyze'))),
      name: 'guideManageAnalyze',
      // 按导管派团分析表
      meta: {
        title: 'guideManageAnalyze'
      }
    }, {
      path: '/shopping/total/goods',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/goodsStatistics'))),
      name: 'goodsStatistics',
      // 按商品统计
      meta: {
        title: 'goodsStatistics'
      },
      hidden: true
    }, {
      path: '/shopping/total/single',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/singleStatistics'))),
      name: 'singleStatistics',
      // 按单团统计
      meta: {
        title: 'singleStatistics'
      }
    }, {
      path: '/shopping/total/gudie-analyse',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/guideAnalysis'))),
      name: 'shopGuideAnalysis',
      // 单导游数据分析表
      meta: {
        title: 'shopGuideAnalysis'
      }
    },
    // {
    //   path: '/shopping/total/profit',
    //   component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/profitAnalysis'),
    //   name: 'profitAnalysis', // 客户利润分析
    //   meta: { title: 'profitAnalysis' },
    // },
    // {
    //   path: '/shopping/total/team',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "shopping" */ '@/views/shopping/teamStatistics'
    //     ),
    //   name: 'teamStatistics', // 按组团统计
    //   meta: { title: 'teamStatistics' },
    //   hidden: true,
    // },
    {
      path: '/shopping/total/team-small',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/teamSmallStatistics'))),
      name: 'teamSmallStatistics',
      // 按小团统计
      meta: {
        title: 'teamSmallStatistics'
      }
    }, {
      path: '/shopping/total/product-custom',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/productCustom'))),
      name: 'shopProductCustom',
      // 单产品客户分析表
      meta: {
        title: 'shopProductCustom'
      }
    }, {
      path: '/shopping/total/analyse-custom-data',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/customAnalyze'))),
      name: 'shopCustomAnalyze',
      // 客户数据分析表
      meta: {
        title: 'shopCustomAnalyze'
      }
    }, {
      path: '/shopping/total/analyse-custom-area',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/customAreaAnalyze'))),
      name: 'shopCustomAreaAnalyze',
      // 区域客户分析表
      meta: {
        title: 'shopCustomAreaAnalyze'
      }
    }, {
      path: '/shopping/total/custom',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopCustom'))),
      name: 'shopCustom',
      // 按客户统计
      meta: {
        title: 'shopCustom'
      }
    }, {
      path: '/shopping/total/area',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/shopArea'))),
      name: 'shopArea',
      // 按区域统计
      meta: {
        title: 'shopArea'
      }
    }, {
      path: '/shopping/total/line',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shopping/lineStatistics'))),
      name: 'lineStatistics',
      // 按线路统计
      meta: {
        title: 'lineStatistics'
      }
    }]
  }]
};
export default shoppingRouter;