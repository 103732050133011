import "core-js/modules/es.array.push.js";
import TableList from './components/TableList';
import DialogPlanShop from './components/DialogPlanShop.vue';
import { shoppingInput } from '@/config/shopping';
import { SelectShop, SelectStaff, SelectGuide } from '@/components/common';
import { searchMixin } from '@/mixin';
import { mergeTableRow } from '@/utils/table';
import { decimalSplit } from '@/utils';
export default {
  components: {
    SelectShop,
    SelectStaff,
    SelectGuide,
    DialogPlanShop,
    TableList
  },
  mixins: [searchMixin],
  data: () => ({
    tableLoading: false,
    tableData: [],
    columnData: shoppingInput,
    searchParams: {},
    total: 0,
    statistics: 0,
    shopDate: [],
    totalKeys: ['perCapita', 'perCapita', 'perCapita', 'perCapita']
  }),
  computed: {
    reloadData() {
      return this.$store.getters['shopping/index/needReload'];
    },
    shopInputData() {
      return this.$store.getters['shopping/index/shopInputData'];
    }
  },
  watch: {
    // 刷新列表数据
    shopInputData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.today
          });
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParams = {}) {
      return this.$store.dispatch('shopping/index/fetch', queryParams);
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      const tableList = this.formatData(list);
      this.tableData = mergeTableRow(tableList, ['planNumber', 'productName', 'tripDate', 'guideMain', 'count', 'dutyOPUserName', 'action']);
      this.total = totalCount;
      this.$store.commit('shopping/index/RELOAD_DATA', false);
      this.statistics = statistics || 0;
      if (!statistics) return;
      this.statistics.perCapita = statistics.totalBuyMoney > 0 ? decimalSplit(statistics.totalBuyMoney / statistics.enterStoreMemberCount) : 0;
    },
    formatData(list) {
      const res = [];
      list.forEach(it => {
        const shops = it.shops || [];
        const item = {
          ...it,
          rowId: it.planId,
          mergeId: it.planId
        };
        if (shops.length) {
          shops.forEach(val => {
            const perCapita = val.totalBuyMoney > 0 ? decimalSplit(val.totalBuyMoney / val.enterStoreMemberCount) : 0;
            res.push({
              ...item,
              ...val,
              perCapita
            });
          });
        } else {
          res.push(item);
        }
      });
      return res;
    },
    mergeTableRow(data, merge) {
      if (!merge || merge.length === 0) {
        return data;
      }
      merge.forEach(m => {
        const mList = {};
        data = data.map((v, index) => {
          const rowVal = v[m];
          if (mList[rowVal] && mList[rowVal].mergeId === v.mergeId && mList[rowVal].shopId === v.shopId && mList[rowVal].newIndex === index) {
            mList[rowVal]['num']++;
            mList[rowVal]['newIndex']++;
            data[mList[rowVal]['index']][m + '-span'].rowspan++;
            v[m + '-span'] = {
              rowspan: 0,
              colspan: 0
            };
          } else {
            mList[rowVal] = {
              mergeId: v.mergeId,
              shopId: v.shopId,
              goodsId: v.goodsId,
              num: 1,
              index: index,
              newIndex: index + 1
            };
            v[m + '-span'] = {
              rowspan: 1,
              colspan: 1
            };
          }
          return v;
        });
      });
      return data;
    },
    showDialog(row) {
      this.$refs.DialogPlanShopRef.show(row);
    },
    onChangeDate(type, val) {
      this.searchParams[type + 'S'] = val[0];
      this.searchParams[type + 'E'] = val[1];
    },
    onSelect(type, val) {
      const {
        label,
        value
      } = val;
      this.searchParams[type + 'Name'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    }
  }
};