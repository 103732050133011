/* eslint-disable no-unused-vars */
// 员工
import { createOrUpdate, fetchUser, fetchUserList, delelteUser, updateInfo } from '@/api/user';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  staffData: null,
  staffList: null,
  systemStaffData: null,
  staffEdit: {}
});
const state = dfaultState();
const getters = {
  staffData: state => state.staffData,
  systemStaffData: state => state.systemStaffData,
  staffEdit: state => state.staffEdit
};
const actions = {
  fetch({
    commit,
    state
  }, params) {
    return fetchUserList(params).then(res => {
      let {
        data
      } = res;
      // 处理标题，对应添加拼音, 合并上次的数据
      data = [...formatCharData(data, 'realName')];
      commit('FETCH_DATA', data);
      return data;
    });
  },
  fetchUser({
    commit
  }, queryParams) {
    queryParams = {
      ...DEFAULT_PAGE,
      ...queryParams
    };
    return fetchUser(queryParams).then(res => {
      const {
        data
      } = res;
      commit('FETCH_STAFF_DATA', data);
      return data;
    });
  },
  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdate(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  updateInfo({
    commit
  }, opts) {
    return updateInfo(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  delete({
    commit
  }, id) {
    return delelteUser(id);
  },
  edit({
    commit
  }, opts) {
    commit('EDIT', opts);
  },
  change({
    commit
  }, opts) {
    commit('CHANGE', opts);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.staffData = data;
  },
  EDIT: (state, data) => {
    state.staffEdit = data;
  },
  FETCH_STAFF_DATA: (state, data) => {
    state.systemStaffData = data;
  },
  CHANGE: (state, data) => {
    const {
      type,
      val
    } = data;
    state.staffEdit[type] = val;
  },
  DELETE_DATA: (state, id) => {
    const list = state.staffData.list.filter(it => it.id !== id);
    state.staffData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};