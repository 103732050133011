var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "修改团款",
      width: "960px",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      close: _vm.hide
    }
  }, [_vm.visible ? _c("TableFund") : _vm._e(), _vm.visible ? _c("TableProxy") : _vm._e(), _vm.visible ? _c("TableRefund") : _vm._e(), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.hide
    }
  }, [_vm._v(" 关闭 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };