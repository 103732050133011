import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
const downloadType = {
  hotelUrl: 'resource/common/importHotelElsx',
  scenicUrl: 'resource/common/importScenicElsx',
  hotelName: '酒店协议模板.xlsx',
  scenicName: '景区协议模板.xlsx'
};
export default {
  props: {
    source: {
      type: String,
      default: 'scenic'
    }
  },
  computed: {
    fileName() {
      return this.source == 'scenic' ? downloadType.scenicName : downloadType.hotelName;
    },
    updateUrl() {
      return this.source == 'scenic' ? downloadType.scenicUrl : downloadType.hotelUrl;
    }
  },
  methods: {
    // 下载模板
    downloadTemplate() {
      const opts = {
        type: this.source
      };
      this.$store.dispatch('resource/common/getDownloadTemplate', opts).then(res => {
        const blob = new Blob([res.data]);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = this.fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 上传模板
    uploadTemplate(results) {
      const {
        file
      } = results;
      let formData = new FormData();
      formData.append('file', file);
      const loading = this.$bus.loading();
      this.$store.dispatch(this.updateUrl, formData).then(() => {
        // this.$parent.fetchAgreement()
        loading.close();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    }
  }
};