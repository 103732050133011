var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "cell-style": _vm.getRowStyle,
      sticky: _vm.isSticky,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " / " + _vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "guide",
      fn: function ({
        scope
      }) {
        return [_c("Guides", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "planStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusPlan", {
          attrs: {
            status: scope.row.planStatus
          }
        })];
      }
    }, {
      key: "behindhandSignStatus",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: scope.row.behindhandSign ? "danger" : "success"
          }
        }, [_vm._v(" " + _vm._s(scope.row.behindhandSign ? "欠签单" : "已交齐") + " ")])];
      }
    }, {
      key: "ticketStatus",
      fn: function ({
        scope
      }) {
        return [!_vm.isUnArrange(scope.row.signSheetStatus) ? _c("span", {
          staticStyle: {
            color: "#fff"
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatusText(scope.row.signSheetStatus)) + " ")]) : _c("span", [_vm._v(" " + _vm._s(_vm.getStatusText(scope.row.signSheetStatus)) + " ")])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [!_vm.isSelect ? [!_vm.isAudit ? _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleCreate(scope.row);
            }
          }
        }, [_vm._v(" 录单 ")]) : _vm._e(), _vm.isAudit ? _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleAudit(scope.row);
            }
          }
        }, [_vm._v(" 审核 ")]) : _vm._e(), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")])] : _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleTransfer(scope.row.id);
            }
          }
        }, [_vm._v(" 转单 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };