import { orderTypeLabel, orderStatusLabel, orderProgressStatus, orderAuditStatus } from '@/config/order';
export default {
  computed: {
    // 获取团队类型
    getType() {
      return state => {
        return orderTypeLabel[state];
      };
    },
    // 获取审核状态
    getAdultState() {
      return state => {
        return orderProgressStatus[state];
      };
    },
    // 获取订单状态
    getState() {
      return state => {
        return orderStatusLabel[state];
      };
    },
    // 获取订单状态
    getPlanState() {
      return state => {
        return orderAuditStatus[state];
      };
    },
    // 订单状态的 tag 状态
    getTagState() {
      return state => {
        return state != 'UNGROUP' ? 'success' : 'warning';
      };
    },
    // 审核状态的 tag 状态
    getAdultTagState() {
      return state => {
        return state !== orderProgressStatus.WAITAUDIT || state !== orderProgressStatus.UNCONFIRM ? 'warning' : 'success';
      };
    },
    // 计划状态的 tag 状态
    getPlanTagState() {
      return state => {
        return state !== orderAuditStatus.NEW ? 'success' : 'info';
      };
    }
  }
};