import { SelectOutCustom, SelectProduct } from '@/components/common';
import ReceptionFee from './ReceptionFee';
import ReceptionProxy from './ReceptionProxy';
import ReceptionRefund from './ReceptionRefund';
import ReceptionItineraries from './ReceptionItineraries';
import ReceptionRemark from './ReceptionRemark';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectOutCustom,
    ReceptionFee,
    ReceptionProxy,
    ReceptionRefund,
    ReceptionItineraries,
    ReceptionRemark,
    SelectProduct
  },
  props: {
    type: {
      // 是订单 还是 计划
      type: String,
      default: 'order'
    },
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      title: '外转',
      form: {},
      date: [''],
      activeName: 'fee',
      tabIndex: 0,
      tableDataFee: [],
      contactList: [],
      customId: ''
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      receptionList: 'order/reception/receptionList',
      customData: 'resource/custom/customData'
    })
  },
  watch: {
    // currentOrder: {
    //   handler: function handler(data) {
    //     if (!this.item.startTripDate || !this.item.endTripDate) {
    //       const { startTripDate, endTripDate } = data
    //       this.date = [startTripDate, endTripDate]
    //       this.form.startTripDate = startTripDate
    //       this.form.endTripDate = endTripDate
    //     }
    //   },
    //   immediate: true,
    // },
    item: {
      handler: function handler(data) {
        const curData = data || this.currentOrder;
        const {
          startTripDate,
          endTripDate
        } = curData;
        this.date = [startTripDate, endTripDate];
        this.form.startTripDate = startTripDate;
        this.form.endTripDate = endTripDate;
      },
      immediate: true
    }
  },
  methods: {
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
    },
    // 外转社
    selectCustom(val) {
      const {
        id,
        label
      } = val;
      this.item.contactId = id;
      this.item.customerName = label;
      this.contactList = [];
      this.item.contactName = '';
      this.changeReception('customerId', id);
      // 选择之后清空联系人
      this.changeReception('contactName', '');
      this.changeReception('contactMobileNumber', '');
      this.findCustomById({
        customerId: id
      });
    },
    findCustomById(params) {
      this.$store.dispatch('resource/custom/fetchCustomList', params).then(data => {
        this.contactList = this.formatContact(data);
        // 切换客户如果有联系人默认选中第一个
        if (this.contactList.length && !this.item.contactName) {
          const {
            label,
            mobileIphone
          } = this.contactList[0];
          this.item.contactName = label;
          this.changeReception('contactName', label);
          this.changeReception('contactMobileNumber', mobileIphone);
        }
      });
    },
    // 保存产品
    selectProduct(val) {
      const {
        label,
        value
      } = val;
      this.changeReception('productName', label);
      this.changeReception('productId', value);
    },
    beforeSelectContact() {
      if (!this.contactList.length) {
        this.findCustomById({
          customerId: this.item.contactId || this.item.id
        });
      }
    },
    formatContact(list) {
      return list.map(it => {
        it.label = it.name;
        it.value = it.id;
        return it;
      });
    },
    // 联系人
    selectStaff(val) {
      const {
        label,
        mobilePhone
      } = val;
      this.changeReception('contactName', label);
      this.changeReception('contactMobileNumber', mobilePhone);
    },
    changeDate(val) {
      const [startDate, endDate] = val;
      this.form.startTripDate = startDate;
      this.form.endTripDate = endDate;
      this.changeReception('startTripDate', startDate);
      this.changeReception('endTripDate', endDate);
    },
    changeReception(type, val) {
      this.$store.commit('order/reception/UPDATE_RECEPTION', {
        type,
        data: val,
        index: this.index
      });
    },
    createOrUpdate(orderId) {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          this.$bus.tip({
            type: 'warning',
            message: '外转社信息不全'
          });
          return reject();
        } else {
          const {
            feeRef,
            proxyRef,
            refundRef
          } = this.$refs;
          Promise.all([feeRef.updateData(this.index), proxyRef.updateData(this.index), refundRef.updateData(this.index)]).then(() => {
            if (this.item.isCustom) {
              delete this.item.id;
            }
            this.item.customerOrderId = orderId;
            this.$store.dispatch('order/reception/turnOut', this.item).then(() => {
              return resolve();
            }).catch(err => {
              return reject(err);
            });
          });
        }
      });
    },
    validateForm() {
      for (let i = 0; i < this.receptionList.length; i++) {
        const item = this.receptionList[i];
        // 'contactMobileNumber', 联系人电话不是必要，暂时注释
        const validateKeys = [
        // 'contactName',
        // 'customerId',
        // 'customerOrderId',
        'endTripDate', 'startTripDate'];
        for (let j = 0; j < validateKeys.length; j++) {
          const key = validateKeys[j];
          if (item[key]) {
            continue;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
};