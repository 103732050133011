var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "bottom-start",
      width: "1360",
      trigger: "manual"
    },
    on: {
      show: _vm.showOrderData
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "flex flex-end"
  }, [_c("el-button", {
    attrs: {
      type: "success",
      icon: "el-icon-close",
      circle: ""
    },
    on: {
      click: function ($event) {
        _vm.visible = false;
      }
    }
  })], 1), _c("TableBase", {
    attrs: {
      data: _vm.orderData,
      "column-data": _vm.ResourceOrder,
      "cell-class-name": _vm.tableCellClass,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("div", [_vm._v(_vm._s(scope.row.startPlanDate))]), _c("div", [_vm._v(_vm._s(scope.row.endPlanDate))])];
      }
    }, {
      key: "peopleCount",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "roomInfo",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getRoomCount(scope.row.hotelTips)) + " ")];
      }
    }, {
      key: "hotel",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showHotelInfo(scope.row);
            }
          }
        }, _vm._l(scope.row.hotelTips, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.getHotelInfo(item)) + " ")]);
        }), 0)];
      }
    }, {
      key: "bus",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showBusInfo(scope.row);
            }
          }
        }, _vm._l(scope.row.busTips, function (item, index) {
          return _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.getBusInfo(item)) + " ")]);
        }), 0)];
      }
    }, {
      key: "restaurant",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.restaurant) + " ")];
      }
    }, {
      key: "guide",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.guide) + " ")];
      }
    }, {
      key: "shipTicket",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.showShipInfo(scope.row.shipTips);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getShipInfo(scope.row.shipTips)) + " ")])];
      }
    }, {
      key: "shipTicketName",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.editShipName(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.showShipName(scope.row);
            }
          }
        }, [_vm._v("查看")])];
      }
    }, {
      key: "confirmTicketStatus",
      fn: function ({
        scope
      }) {
        return [!scope.row.shipTicketStatus ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v(" 不可出票 ")]) : _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("可出票")])];
      }
    }, {
      key: "shipTicketStatus",
      fn: function ({
        scope
      }) {
        return [scope.row.shipTicketStatus != 2 ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v(" 未出票 ")]) : _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("已出票")])];
      }
    }])
  }), _c("i", {
    staticClass: "el-icon-circle-plus-outline",
    attrs: {
      slot: "reference"
    },
    on: {
      click: function ($event) {
        _vm.visible = !_vm.visible;
      }
    },
    slot: "reference"
  }), _c("DialogShipInfo", {
    ref: "shipRef"
  }), _c("DialogHotelInfo", {
    ref: "hotelRef"
  }), _c("DialogBusInfo", {
    ref: "busRef"
  }), _c("DialogShipName", {
    ref: "shipNameRef"
  }), _c("DialogShipNameEdit", {
    ref: "shipNameEditRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };