var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleSetSize
    }
  }, [_c("div", [_c("span", {
    staticClass: "iconfont icon-text"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.sizeOptions, function (item) {
    return _c("el-dropdown-item", {
      key: item.value,
      attrs: {
        disabled: _vm.size === item.value,
        command: item.value
      }
    }, [_vm._v(" " + _vm._s(_vm.generateTitle(item.label)) + " ")]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };