import TableList from './TableList';
import { SelectFleet } from '@/components/common';
import { planPickupFleet } from '@/config/plan';
export default {
  components: {
    SelectFleet,
    TableList
  },
  data() {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      form: {},
      curRadio: '1',
      radios: [{
        label: '1',
        value: '拼车'
      }, {
        label: '2',
        value: '包车'
      }],
      tableData: [],
      columnData: planPickupFleet
    };
  },
  methods: {
    show(data) {
      this.tableData = [data];
      this.loading = false;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    selectTicket(val) {
      this.form.transportType = val.label;
      this.form.transportTypeId = val.value;
    },
    handleCreate() {}
  }
};