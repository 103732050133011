var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "form-wrapper"
  }, [_c("SearchBase", {
    attrs: {
      form: _vm.searchParams
    },
    on: {
      "update:form": function ($event) {
        _vm.searchParams = $event;
      },
      "on-search": _vm.fetchData
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "姓名"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  })], 1)], 1)], 1), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      dynamic: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        row
      }) {
        return [_c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRoleInGroup(row);
            }
          }
        }, [_vm._v(" 设置地接角色 ")])];
      }
    }])
  }), _c("DialogAuth", {
    ref: "dialogRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };