import { getTableColumnStorage, setTableColumnStorage } from '@/utils/storage';
import screenfull from 'screenfull';
const MIN_COLUMNS = 4;
export default {
  props: {
    columns: {
      type: Array,
      required: true
    },
    border: {
      type: [Boolean, String],
      required: true
    }
  },
  data() {
    return {
      visible: false,
      checkedColumns: [],
      columnCopy: this.columns.slice(),
      tableStyle: {},
      isFullscreen: false
    };
  },
  mounted() {
    this.getCheckColmunsFormStorage();
    this.$emit('reload', {
      columns: this.columnCopy,
      style: this.tableStyle
    });
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning'
        });
        return false;
      }
      if (screenfull.isEnabled) {
        screenfull.request(document.getElementById('tableNewId') || document.getElementById('tableElId'));
      }
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change);
      }
    },
    getCheckColmuns() {
      const checkedColumns = this.checkedColumns.filter(it => it.isHide).map(it => it.label);
      this.columnCopy = this.columns.filter(it => {
        return checkedColumns.includes(it.attrs.label);
      });
    },
    getCheckColmunsFormStorage() {
      const data = getTableColumnStorage();
      if (data && data[this.$route.path]) {
        this.checkedColumns = data[this.$route.path];
        // 表格样式
        this.tableStyle = {
          ...this.tableStyle,
          ...data[this.$route.path + '__STYLE']
        };
        this.getCheckColmuns();
      } else {
        this.checkedColumns = this.columns.map(it => {
          return {
            label: it.attrs.label,
            isHide: !it.isHide
          };
        });
        this.tableStyle = {
          border: this.border
        };
        this.columnCopy = this.columns.filter(it => !it.isHide);
      }
    },
    handleConfirm() {
      if (this.checkedColumns.length < MIN_COLUMNS) {
        this.$bus.tip({
          message: `至少保留${MIN_COLUMNS}列`,
          type: 'warning'
        });
        return;
      }
      this.getCheckColmuns();
      this.hide();
      setTableColumnStorage({
        path: this.$route.path,
        data: this.checkedColumns,
        style: this.tableStyle
      });
      this.$emit('reload', {
        columns: this.columnCopy,
        style: this.tableStyle
      });
    }
  }
};