export const Columns = [{
  attrs: {
    prop: 'name',
    label: '购物店名称'
  }
}, {
  attrs: {
    prop: 'contentType',
    label: '项目'
  },
  slot: 'item'
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'isExport',
    label: '导出状态',
    sortable: true
  },
  slot: 'isExport'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];
export const ColumnsAgreementItem = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'shopRate',
    label: '社佣比例(%)'
  },
  slot: 'shopRate'
}, {
  attrs: {
    prop: 'guideRate',
    label: '导佣比例(%)'
  },
  slot: 'guideRate'
}, {
  attrs: {
    prop: 'driverRate',
    label: '司佣比例(%)'
  },
  slot: 'driverRate'
}, {
  attrs: {
    prop: 'accompanyRate',
    label: '陪佣比例(%)'
  },
  slot: 'accompanyRate'
}, {
  attrs: {
    prop: 'guideManagerRate',
    label: '导管比例(%)'
  },
  slot: 'guideManagerRate'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 协议列表
export const ColumnsAgreement = [{
  attrs: {
    prop: 'name',
    label: '协议名称'
  }
}, {
  attrs: {
    prop: 'unit',
    label: '协议单位'
  }
}, {
  attrs: {
    prop: 'date',
    label: '日期范围'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'detail',
    label: '明细',
    'min-width': 400
  },
  slot: 'detail'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '是否启用',
    'min-width': 80
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];