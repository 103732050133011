import { parseDate } from '@/utils';
export default {
  data() {
    return {
      search: {},
      sendDate: [parseDate()],
      orderDate: [],
      billDate: []
    };
  },
  methods: {
    changeDate(type, val) {
      const [starDate, endDate] = val ? val : ['', ''];
      this.search[type + 'S'] = starDate;
      this.search[type + 'E'] = endDate;
    },
    handleSearch() {
      this.$emit('search', this.search);
    },
    handleExport() {
      this.$emit('export', this.search);
    }
  }
};