import "core-js/modules/es.array.push.js";
import cloneMixin from '../cloneMixin';
import commonMixin from '../commonMixin';
import { orderType } from '@/config/order';
import { tagViewMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'TeamClone',
  mixins: [tagViewMixin, cloneMixin, commonMixin],
  data() {
    return {
      orderType: orderType.team,
      form: null,
      redirectPath: '/order/team-input-update',
      showOther: false
    };
  },
  computed: {
    ...mapGetters({
      cloneForm: 'order/team/cloneForm'
    })
  },
  mounted() {
    this.form = this.cloneForm;
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('order/index/fetchDetail', {
        id
      }).then(data => {
        const {
          provinceId,
          cityId,
          districtId
        } = data;
        const region = [provinceId + '', cityId + '', districtId + ''];
        data.region = region;
        this.form = data;
        if (this.form.customerTripNumber != '') {
          this.form.customerTripNumber = this.form.customerTripNumber + '（复制）';
        }
        // delete this.form.customerTripNumber
        delete this.form.planCustomerNumber;
        delete this.form.id;
      });
    },
    //打开选择产品线路弹窗
    showProductDialog() {
      this.$refs.teamDialogProduct.show();
    },
    //打开选择客户弹窗
    showDialogCustom() {
      this.$refs.teamDialogCustom.show();
    },
    selectCustom(data) {
      this.$refs.formInputRef.selectCustom(data);
    },
    selectProduct(data) {
      this.$refs.formInputRef.selectProduct(data);
    },
    handleCreate() {
      this.$refs.formInputRef.createOrUpdate().then(data => {
        const {
          id,
          customerId
        } = data;
        this.createOther(id, customerId);
      });
    },
    createOther(id, customerId) {
      const loading = this.$bus.loading();
      const {
        teamFundRef,
        teamProxyRef,
        teamRefundRef,
        teamRemarksRef
      } = this.$refs;
      const {
        type
      } = this.$route.query;
      // 备注
      teamRemarksRef.createOrUpdate(id, type);
      // 团款
      teamFundRef.createOrUpdate(id, type).then(() => {
        // 代收
        teamProxyRef.createOrUpdate(id, type).then(() => {
          // 返款
          teamRefundRef.createOrUpdate(id, type).then(() => {
            // 外转
            // teamReceptionRef.createOrUpdate(id, type)
            loading.close();
            // 清理缓存
            this.$store.commit('order/team/CLONE_FORM', null);
            // 跳转连接
            this.$router.push({
              path: this.redirectPath,
              query: {
                id,
                type: 'update',
                customerId
              }
            });
            this.$bus.tip();
          });
        });
      });
    }
  }
};