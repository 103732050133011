var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "boat-detail"
  }, [_c("BoatLogin"), _c("div", {
    staticClass: "boat-detail__content"
  }, [_c("div", {
    class: ["table-box", {
      "full-width": !_vm.qrcodeValue
    }]
  }, [_c("TableBase", {
    attrs: {
      data: _vm.companyList,
      "column-data": _vm.companyColumns,
      dynamic: false,
      border: ""
    }
  }), _c("div", {
    staticClass: "mt20"
  }), _c("TableBase", {
    attrs: {
      data: _vm.touristList,
      "column-data": _vm.touristColumns,
      dynamic: false,
      border: ""
    }
  })], 1), _vm.qrcodeValue ? _c("div", {
    staticClass: "qrcode flex"
  }, [_c("qrcode-vue", {
    attrs: {
      value: _vm.qrcodeValue,
      size: _vm.size,
      level: "H"
    }
  })], 1) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };