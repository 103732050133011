import guideMixin from './guide';
const columnData = [{
  label: '保险公司',
  prop: 'name'
}, {
  label: '险种',
  prop: 'insuranceType'
}, {
  label: '司机信息',
  prop: 'date'
}, {
  label: '日期',
  prop: 'orderDate'
}, {
  label: '单价',
  prop: 'price'
}, {
  label: '数量',
  prop: 'count'
},
// { label: '金额', prop: 'money' },
{
  label: '报账金额',
  prop: 'reimbMoney',
  split: true
}, {
  label: '备注',
  prop: 'remark',
  slot: 'remark'
}];
export default {
  mixins: [guideMixin],
  data() {
    return {
      columnData
    };
  }
};