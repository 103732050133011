// 资源计划

const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
const ResourceBase = [{
  attrs: {
    prop: 'icon',
    label: '',
    width: 30
  },
  slot: 'icon'
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'hotelCount',
    label: '用房数'
  },
  slot: 'roomInfo'
}, {
  attrs: {
    prop: 'hotel',
    label: '房'
  },
  slot: 'hotel'
}, {
  attrs: {
    prop: 'hotelReservations',
    label: '控房'
  },
  slot: 'reservation'
}, {
  attrs: {
    prop: 'restaurant',
    label: '餐'
  },
  slot: 'restaurant'
}, {
  attrs: {
    prop: 'bus',
    label: '车'
  },
  slot: 'bus'
}, {
  attrs: {
    prop: 'shipTicket',
    label: '船票'
  },
  slot: 'shipTicket'
},
// { attrs: { prop: 'scenic', label: '景' }, slot: 'scenic' },
{
  attrs: {
    prop: 'guide',
    label: '导'
  }
}, {
  attrs: {
    prop: 'source',
    label: '客源地'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '下单时间',
    'min-width': 100
  },
  slot: 'createTime'
}, {
  attrs: {
    prop: 'bookingStatus',
    label: '计划状态'
  },
  slot: 'status'
}];
export const ResourceOrder = [{
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'hotelCount',
    label: '用房数'
  },
  slot: 'roomInfo'
}, {
  attrs: {
    prop: 'hotelOrder',
    label: '房'
  },
  slot: 'hotel'
}, {
  attrs: {
    prop: 'restaurant',
    label: '餐'
  },
  slot: 'restaurant'
}, {
  attrs: {
    prop: 'busCompanyOrder',
    label: '车'
  },
  slot: 'bus'
}, {
  attrs: {
    prop: 'shipOrder',
    label: '船票'
  },
  slot: 'shipTicket'
}, {
  attrs: {
    prop: '',
    label: '船票名单'
  },
  slot: 'shipTicketName'
}, {
  attrs: {
    prop: '',
    label: '确认出票状态'
  },
  slot: 'confirmTicketStatus'
}, {
  attrs: {
    prop: '',
    label: '船票状态'
  },
  slot: 'shipTicketStatus'
}, {
  attrs: {
    prop: 'guide',
    label: '导'
  },
  slot: 'guide'
}];

// 资源列表
export const OrderResourcePlan = [...ResourceBase,
// { attrs: { prop: 'confirmTime', label: '确认时间' } },
actionItem];

// 历史
export const OrderResourcePlanHistory = [...ResourceBase, actionItem];

// 酒店信息
export const OrderResourcePlanHotelInfo = [{
  attrs: {
    prop: 'hotelName',
    label: '酒店名称'
  }
}, {
  attrs: {
    prop: 'hotelStandard',
    label: '标准'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '订单日期'
  }
}, {
  attrs: {
    prop: 'roomType',
    label: '房间类型'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'quotePrice',
    label: '价格'
  }
}, {
  attrs: {
    prop: 'amount',
    label: '总价'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

// 船票信息
export const OrderResourcePlanShipInfo = [{
  attrs: {
    prop: 'scenicName',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'ticketType',
    label: '仓位'
  }
}, {
  attrs: {
    prop: 'money',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}];

// 车队信息
export const OrderResourcePlanBusInfo = [{
  attrs: {
    prop: 'busCompanyName',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'busType',
    label: '类型'
  }
}, {
  attrs: {
    prop: '',
    label: '日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'licenceNumber',
    label: '车牌'
  }
}, {
  attrs: {
    prop: 'busFee',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'remark',
    label: '备注'
  }
}];

// 编辑游客名单
export const ColumnsShipNameEdit = [{
  attrs: {
    prop: 'orderNumber',
    label: '序号',
    width: 60
  }
}, {
  attrs: {
    prop: 'name',
    label: '姓名'
  },
  slot: 'name'
}, {
  attrs: {
    prop: '$cardType',
    label: '证件类型'
  },
  slot: 'cardType'
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  },
  slot: 'cardNumber'
}, {
  attrs: {
    prop: 'age',
    label: '年龄',
    width: 70
  },
  slot: 'age'
}, {
  attrs: {
    prop: 'ticket',
    label: '全/半票'
  },
  slot: 'ticket'
}, {
  attrs: {
    prop: 'status',
    label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）'
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'country',
    label: '国籍/地区（护照等国际证件时必填）'
  },
  slot: 'country'
}, {
  attrs: {
    prop: 'fee',
    label: '免票',
    width: 100
  },
  slot: 'fee'
}, {
  attrs: {
    prop: '',
    label: '操作'
  },
  slot: 'action'
}];

// 查看游客名单
export const ColumnsShipName = [{
  content: 'age',
  label: '年龄',
  span: 1
}, {
  content: 'name',
  label: '姓名',
  span: 1
}, {
  content: 'cardType',
  label: '证件类型',
  span: 1,
  slot: 'cardType'
}, {
  content: 'cardNumber',
  label: '证件号',
  span: 2
}, {
  content: 'shippingSpace',
  label: '仓位',
  span: 2
}, {
  content: 'status',
  label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）',
  span: 2
}, {
  content: 'country',
  label: '国籍/地区（护照等国际证件时必填）',
  span: 2
}, {
  content: 'fee',
  label: '免票',
  span: 1
}];