import "core-js/modules/es.array.push.js";
import { rowspanMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { ScenicColumn as columns } from '@/config/billDown/payRegiment/scenic';
export default {
  mixins: [rowspanMixin],
  data: () => ({
    path: '/bill/regiment-pay/scenic-create',
    columns,
    isSticky: true,
    headMergeNum: 6,
    tailMergeNum: 13,
    hasMergeTail: true
  }),
  computed: {
    ...mapGetters({
      data: 'bill/billDown/payRegimentScenic/data',
      total: 'bill/billDown/payRegimentScenic/total',
      tableData: 'bill/billDown/payRegimentScenic/list'
    })
  },
  watch: {
    data: {
      handler: function (data) {
        if (!data) this.$emit('page', {});
        this.rowspan();
      },
      immediate: true
    }
  },
  methods: {
    handlePay({
      rowId,
      scenicId: rid
    }) {
      this.$router.push({
        path: this.path,
        query: {
          id: rowId,
          rid
        }
      });
    }
  }
};