import { ColumnsHistory } from '../tableConfig';
import { formatTime } from '@/utils';
import { InquireStatusLabel, InquireStatusType } from '@/config';
export default {
  data() {
    return {
      columns: ColumnsHistory.slice(0, -1),
      visible: false,
      tableData: []
    };
  },
  computed: {
    getInquireDate() {
      return row => {
        const {
          createTime,
          updateTime
        } = row;
        const time = updateTime || createTime;
        return time ? formatTime(new Date(time)) : '';
      };
    },
    getQuotaDate() {
      return row => {
        const {
          quotationTime
        } = row;
        if (!quotationTime) return;
        return formatTime(new Date(quotationTime));
      };
    },
    getItinerary() {
      return val => {
        if (!val) return '';
        return val.split('\n');
      };
    }
  },
  methods: {
    show(data) {
      this.visible = true;
      this.tableData = this.formatData(data);
    },
    hide() {
      this.visible = false;
    },
    showDetail(row) {
      this.$emit('detail', row);
    },
    formatData(data) {
      if (!data.length) return [];
      return data.map(it => {
        const {
          inquiryStatus
        } = it;
        it.$inquiryStatus = InquireStatusLabel[inquiryStatus];
        it.$inquiryType = InquireStatusType[inquiryStatus];
        return it;
      });
    }
  }
};