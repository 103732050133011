import { SelectFleet, SelectHotel, SelectScenic, SelectRestaurant } from '@/components/common';
export const SelectMerchantList = [{
  value: '',
  label: '全部'
}, {
  value: 'HOTEL',
  label: '酒店'
}, {
  value: 'RESTAURANT',
  label: '餐厅'
}, {
  value: 'BUS',
  label: '车队'
}, {
  value: 'SCENIC',
  label: '景区'
}];
export const COMPONENT_MAP = {
  HOTEL: SelectHotel,
  RESTAURANT: SelectRestaurant,
  BUS: SelectFleet,
  SCENIC: SelectScenic
};