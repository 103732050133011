var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    staticClass: "plan-list-table",
    attrs: {
      "show-summary": "",
      sticky: true,
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      border: true
    },
    on: {
      "cell-click": _vm.handleCell
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({
        scope
      }) {
        return [_c("TripNumberBase", {
          on: {
            "on-click": function ($event) {
              return _vm.linkDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.planNumber) + " ")])];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.startPlanDate) + " "), _c("br"), _vm._v(_vm._s(scope.row.endPlanDate) + " ")];
      }
    }, {
      key: "orderType",
      fn: function ({
        scope
      }) {
        return [_c("TypeOrder", {
          attrs: {
            type: scope.row.planType
          }
        })];
      }
    }, {
      key: "dutyOPUserName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" 操作：" + _vm._s(scope.row.dutyOPUserName)), _c("br"), scope.row.planType == "T_TOURIST" ? [_vm._v(" 客服：" + _vm._s(scope.row.outOPUsers && scope.row.outOPUsers.length ? scope.row.outOPUsers[0].realName : "") + " ")] : _vm._e()];
      }
    }, {
      key: "custom",
      fn: function ({
        scope
      }) {
        return [scope.row.planType == "T_TOURIST" && scope.row.customerVoList.length ? _c("CustomerInfo", {
          attrs: {
            name: scope.row.customerVoList[0].name
          }
        }) : _vm._e()];
      }
    }, {
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "guides",
      fn: function ({
        scope
      }) {
        return [scope.row.guides.length ? [_c("Guides", {
          attrs: {
            row: scope.row,
            "is-link": true,
            "is-break": true
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "待接单",
            placement: "left"
          }
        }, [scope.row.reimbStatus == "WAIT_ACCEPT" ? _c("div", {
          staticClass: "dot-status"
        }) : _vm._e()])], 1)] : _c("IconWrong")];
      }
    }, {
      key: "hotelOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.hotelOrderStatus,
            "table-data": scope.row.hotelTips,
            "column-data": _vm.columnHotelTips,
            width: 680,
            type: "hotel"
          }
        })];
      }
    }, {
      key: "restaurantOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.restaurantOrderStatus,
            "table-data": scope.row.restaurantTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "busCompanyOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.busCompanyOrderStatus,
            "table-data": scope.row.busTips,
            "column-data": _vm.columnBus,
            width: 500,
            type: "bus"
          }
        })];
      }
    }, {
      key: "scenicOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.scenicOrderStatus,
            "table-data": scope.row.scenicTips,
            "column-data": _vm.columnScenic
          }
        })];
      }
    }, {
      key: "insuranceOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.insuranceOrderStatus,
            "table-data": scope.row.insuranceTips,
            "column-data": _vm.columnInsurance
          }
        })];
      }
    }, {
      key: "transportCompanyOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.transportCompanyOrderStatus,
            "table-data": scope.row.transportCompanyTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "shuttleStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.shuttleStatus,
            "table-data": scope.row.shuttleTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "quota",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.guideBorrowAlipayStatus,
            "table-data": scope.row.guideAlipayTips,
            "column-data": _vm.columnQuota
          }
        })];
      }
    }, {
      key: "guideBorrowApplyStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.guideBorrowApplyStatus,
            "table-data": scope.row.guideBorrowApplyTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "guideBorrowStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.guideBorrowStatus,
            "table-data": scope.row.guideBorrowTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "collectStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.collectStatus,
            "table-data": scope.row.guideCollectTips,
            "column-data": _vm.columnCollect
          }
        })];
      }
    }, {
      key: "agencyStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.agencyStatus,
            "table-data": scope.row.customerVoList,
            "column-data": _vm.columnCustomer
          }
        })];
      }
    }, {
      key: "shopOrderStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.shopOrderStatus,
            "table-data": scope.row.shopTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "otherIncomeStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.otherIncomeStatus,
            "table-data": scope.row.otherIncomeTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "otherPayStatus",
      fn: function ({
        scope
      }) {
        return [_c("RowItem", {
          attrs: {
            status: scope.row.otherPayStatus,
            "table-data": scope.row.otherPayTips,
            "column-data": _vm.columnDefault
          }
        })];
      }
    }, {
      key: "sendGroupStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusSend", {
          attrs: {
            status: scope.row.sendGroupStatus
          }
        }), _c("br"), _c("StatusAuditProgress", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "uploadAction",
      fn: function ({
        scope
      }) {
        return [scope.row.signId ? [_c("BtnLink", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.uploadTravel(scope.row, true);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.printTravel2(scope.row);
            }
          }
        }, [_vm._v(" 打印 ")]), _c("BtnLink", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.deleteTravel(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])] : _vm._e(), !scope.row.signId && _vm.overSendDate(scope.row) ? _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.uploadTravel(scope.row);
            }
          }
        }, [_vm._v(" 上传 ")]) : _vm._e()];
      }
    }, {
      key: "deleteAction",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handleTypeIn(scope);
            }
          }
        }, [_vm._v(" 录入 ")]), _vm.comparePlanStatus(scope.row) ? [_c("BtnLink", {
          attrs: {
            type: !scope.row.sendGroupStatus ? "primary" : "danger",
            disabled: _vm.isEnabled
          },
          on: {
            click: function ($event) {
              return _vm.toggleSendGroup(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(!scope.row.sendGroupStatus ? "确认发团" : "撤销发团") + " ")])] : _vm._e(), _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            disabled: _vm.isEnabled,
            type: scope.row.toGroupDispatchSign ? "danger" : "success"
          },
          on: {
            click: function ($event) {
              return _vm.toggleDispatch(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.toGroupDispatchSign ? "取消上报" : "上报派单") + " ")]), _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.handleCopy(scope.row.id);
            }
          }
        }, [_vm._v(" 复制 ")]), !_vm.comparePlanStatus(scope.row) ? _c("el-button", {
          attrs: {
            type: "text",
            disabled: ""
          }
        }, [_vm._v(" 已归档 ")]) : _vm._e(), _vm.showResPlanBtn(scope.row) ? _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.handlePlan(scope);
            }
          }
        }, [_vm._v(" 下计划 ")]) : _vm._e(), _vm.showResPlanedBtn(scope.row) ? _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handlePlan(scope);
            }
          }
        }, [_vm._v(" 已计划 ")]) : _vm._e(), _c("BtnLink", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLink", {
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.handlePrint(scope);
            }
          }
        }, [_vm._v(" 打印 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };