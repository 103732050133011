import { statisticsTeamKeys } from '@/config/report';
export const ClassKeys1 = ['adultCount', 'childCount', 'accompanyCount'];
export const ClassKeys2 = ['totalCustomerIncome', 'totalOutTransferSubsidyIncome', 'totalOtherIncome', 'totalShopIncome', 'totalScenicExtraIncome', 'totalGuideManageFeeIncome'];
export const ClassKeys3 = ['totalCustomerSubsidyPay', 'totalBusCompanyPay', 'totalHotelPay', 'totalHotelQuotePay', 'totalGuideServiceFeePay', 'totalInsurancePay', 'totalOutTransferPay', 'totalShuttlePay', 'totalTransportCompanyPay', 'totalOtherPay', 'totalShopRebatePay', 'totalScenicExtraRebatePay', 'totalScenicInnerQuotePay', 'totalScenicExtraQuotePay', 'totalRestaurantPay'];
export const ClassKeys4 = ['totalIncome', 'totalIncomed', 'totalQuotePay', 'totalPay', 'totalPayed', 'totalQuoteGrossProfit', 'totalQuoteGrossMargin', 'shopEndSubsidy', 'customerShopSubsidy', 'subsidyQuoteGrossProfit', 'subsidyQuoteGrossMargin'];
export const ClassKeys5 = ['totalQuoteGrossProfit', 'totalQuoteGrossMargin', 'subsidyQuoteGrossProfit', 'subsidyQuoteGrossMargin'];
export const StatisticsKeys = [...statisticsTeamKeys, 'adultCount', 'shopEndSubsidy', 'customerShopSubsidy', 'totalShopRebatePay', 'totalIncome', 'subsidyQuoteGrossMargin', 'subsidyQuoteGrossProfit', 'totalQuoteGrossMargin', 'totalQuoteGrossProfit', 'totalShopBuyMoney'];
export const AvgeageObj = {
  totalQuoteGrossMargin: ['totalQuoteGrossProfit', 'adultCount'],
  // 人均毛利
  subsidyQuoteGrossMargin: ['subsidyQuoteGrossProfit', 'adultCount'] // 最终人均
};