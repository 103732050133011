import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import draggable from 'vuedraggable';
// import UploadBase from '@/components/Upload'
import { parseTime, splitPath, getOssName, parseDate, calcDateByCount, getDateBetween, getNextDate } from '@/utils';
import { SelectRegion, SelectProduct, SelectScenic, SelectShop, SelectDictionary } from '@/components/common';
import { genItinerary, getRoleName, hotelItem, restaurantItem } from '../config';
// import { processDraggableData } from '../helper'

export default {
  components: {
    draggable,
    SelectShop,
    SelectRegion,
    SelectProduct,
    SelectScenic,
    SelectDictionary
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderTime: parseTime(new Date()),
      timer: null,
      productName: '',
      days: 0,
      busSeatList: []
    };
  },
  computed: {
    getCustomerInfo() {
      return this.form.createUserName || getRoleName();
    },
    getOrderTime() {
      return this.form.createTime || this.orderTime;
    }
  },
  watch: {
    form: {
      handler: function (data) {
        if (!data || !data.itineraries.length) return;
        this.days = data.itineraries.length - 1;
      },
      immediate: true
    }
  },
  mounted() {
    if (this.isNew) {
      this.timer = setInterval(() => {
        this.orderTime = parseTime(new Date());
      }, 1000);
    }
    this.$store.dispatch('system/index/fetchSystemDictionary', 'bus:SetType').then(data => {
      this.busSeatList = data.map(it => {
        return {
          label: it.name,
          value: it.name
        };
      });
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    fetchProductDetail(id) {
      return this.$store.dispatch('resource/product/fetchItinerary', id);
    },
    addItinerary() {
      let {
        endTripDate
      } = this.form;
      let date;
      if (!endTripDate) {
        const startTripDate = parseDate();
        this.form.startTripDate = startTripDate;
        date = getNextDate(startTripDate);
      } else {
        date = getNextDate(endTripDate);
      }
      this.form.itineraries.push(...genItinerary({
        days: 1,
        date
      }));
      this.form.endTripDate = date;
    },
    uploadSuccess({
      res,
      file
    }) {
      const {
        name
      } = file;
      const {
        url
      } = res.data;
      this.form.filePath = getOssName(splitPath(url));
      this.form.fileName = name;
    },
    selectLabel(item, type, val) {
      item[type] = val;
    },
    // 修改省市区
    changeRegion(val) {
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.form[key] = val[key] && isNaN(val[key]) ? val[key] : Number(val[key]);
      });
    },
    handleDelete() {
      this.form.filePath = '';
      this.form.fileName = '';
    },
    swapItineraryDate(newIndex, oldIndex) {
      const {
        itineraries
      } = this.form;
      const tempDate = itineraries[newIndex].date;
      itineraries[newIndex].date = itineraries[oldIndex].date;
      itineraries[oldIndex].date = tempDate;
      this.form.itineraries = itineraries;
    },
    swapOrderNumber(newIndex, oldIndex) {
      const {
        itineraries
      } = this.form;
      const tempDate = itineraries[newIndex].date;
      itineraries[newIndex].date = itineraries[oldIndex].date;
      itineraries[oldIndex].date = tempDate;
      this.form.itineraries = itineraries;
    },
    onMove(evt) {
      const {
        draggedContext
      } = evt;
      // futureIndex 拖拽后的节点下标，index 拖拽前的节点下标
      const {
        futureIndex,
        index
      } = draggedContext;
      this.swapItineraryDate(futureIndex, index);
    },
    onMoveTag(evt) {
      const {
        draggedContext,
        relatedContext
      } = evt;

      // futureIndex 拖拽后的节点下标，index 拖拽前的节点下标
      const {
        futureIndex,
        index,
        element
      } = draggedContext;
      element.orderNumber = futureIndex;
      const {
        element: el
      } = relatedContext;
      el.orderNumber = index;
    },
    onDelete(index) {
      this.$bus.open(() => {
        this.form.itineraries.splice(index, 1);
        this.$nextTick(() => {
          const {
            startTripDate
          } = this.form;
          // 重新设置行程日期
          this.form.itineraries.forEach((it, idx) => {
            it.date = calcDateByCount(startTripDate, idx);
          });

          // 修改行程结束日期
          this.form.endTripDate = this.form.itineraries[this.form.itineraries.length - 1].date;
        });
      }, '确定要删除吗');
    },
    changeScenic(item, type, val) {
      const {
        label,
        value
      } = val;
      item.scenicTempName = '';
      item.shopTempName = '';
      if (!label || !value) return;
      item.scenicList.push({
        type,
        name: label,
        id: value,
        [type + 'Id']: value,
        [type + 'Name']: label,
        orderNumber: item.scenicList.length + 1
      });
    },
    removeScenicItem(item, tag) {
      item.scenicList = item.scenicList.filter(it => it != tag);
    },
    removeShopItem(item, tag) {
      item.shopList = item.shopList.filter(it => it != tag);
    },
    filterRestaurant(item) {
      const {
        breakfast,
        dinner,
        lunch
      } = item;
      return {
        breakfast,
        dinner,
        lunch
      };
    },
    filterScenic(scenics) {
      if (!scenics || !scenics.length) return [];
      return scenics.reduce((acc, cur, index) => {
        const {
          scenicId,
          scenicName
        } = cur;
        /**
         * id, name兼容修改时，下拉返回的数据字段
         */
        return [...acc, {
          type: 'scenic',
          orderNumber: index,
          id: scenicId,
          name: scenicName,
          scenicId,
          scenicName
        }];
      }, []);
    },
    // 生成行程与计算行程天数
    generateItinerary(itineraries, date) {
      return itineraries.reduce((acc, it, index) => {
        const {
          selfScenic,
          hotelName: hotel,
          hotelId,
          area,
          standard
        } = it;
        const scenicList = this.filterScenic(it.scenics);
        const obj = {
          scenicList,
          selfScenic,
          shopList: [],
          hotelObj: {
            hotel,
            hotelId,
            area: area || '',
            standard
          },
          restaurantObj: this.filterRestaurant(it),
          date: calcDateByCount(date, index)
        };
        return [...acc, obj];
      }, []);
    },
    // append 行程
    appendItinerary(dateList, date) {
      const list = dateList.map(date => {
        return {
          date,
          scenics: [],
          shops: [],
          selfScenic: '',
          hotel: '',
          ...hotelItem(),
          ...restaurantItem()
        };
      });
      return this.generateItinerary(list, date);
    },
    // 更新计划行程日期
    updateDate(startDate, days) {
      this.form.startTripDate = startDate;
      this.form.endTripDate = calcDateByCount(startDate, days);
    },
    fixedItineraryDate() {
      const {
        itineraries,
        startTripDate
      } = this.form;
      this.form.itineraries = itineraries.map((it, index) => {
        it.date = calcDateByCount(startTripDate, index);
        return it;
      });
    },
    async onSelectProduct(data) {
      const {
        id,
        days
      } = data;
      const today = parseDate();

      // 删除产品
      if (!id) return;
      const productData = await this.fetchProductDetail(id);
      // 设置行程
      this.form.itineraries = this.generateItinerary(productData.itineraries, today);
      const dayLen = days - 1;
      // 设置日期
      this.updateDate(today, dayLen);
      this.days = dayLen;
    },
    setItinerary() {
      const {
        itineraries,
        startTripDate,
        endTripDate
      } = this.form;
      const dayList = getDateBetween(startTripDate, endTripDate);
      const dayLen = dayList.length;
      const len = itineraries.length;
      if (dayLen > len) {
        const dateList = dayList.slice(len);
        const append = this.appendItinerary(dateList, dateList[0]);
        this.form.itineraries = [...itineraries, ...append];
      }
      if (dayLen < len) {
        this.form.itineraries = this.form.itineraries.slice(0, dayLen);
      }
      this.days = dayLen - 1;
      this.updateDate(startTripDate, this.days);
      this.fixedItineraryDate();
    },
    changeStartDate(val) {
      // if (!this.productName || !val) return

      this.updateDate(val, this.days);
      this.setItinerary();
    },
    changeEndDate(val) {
      // if (!this.productName || !val) return
      if (new Date(val).getTime() <= new Date(this.form.startTripDate).getTime()) {
        this.$bus.tip({
          type: 'warning',
          message: '结束日期不能小于开始日期'
        });
        return;
      }
      this.setItinerary();
    }
  }
};