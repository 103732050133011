import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import RightPanel from '@/components/RightPanel';
import BackToTop from '@/components/BackToTop';
import Refresh from '@/components/Refresh';
import ResizeMixin from './mixin/ResizeHandler';
import { AppMain, HeadNav, Sidebar,
// Navbar,
Settings, TagsView } from './components';
import { RefreshExclude } from '@/config';
import NoticeDialog from '@/components/Notice/NoticeDialog.vue';
import DialogRenew from '@/components/common/Dialog/DialogRenew.vue';
import { getUserData, getDaysByTimestamp } from '@/utils';
const DEADLINE_DAY = 10;
export default {
  name: 'Layout',
  components: {
    AppMain,
    HeadNav,
    Sidebar,
    // Navbar,
    Settings,
    TagsView,
    RightPanel,
    BackToTop,
    Refresh,
    NoticeDialog,
    DialogRenew
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    getAvailableDate() {
      const {
        travelAgency
      } = getUserData();
      return travelAgency.availableDate;
    },
    getExpiresDate() {
      const availableDate = this.getAvailableDate;
      if (!availableDate) return;
      return getDaysByTimestamp(availableDate);
    },
    // 判断是否过期
    isExpired() {
      const availableDate = this.getAvailableDate;
      if (availableDate != null && availableDate !== undefined) {
        return this.getExpiresDate <= DEADLINE_DAY;
      }
      return false;
    },
    isMobile() {
      return this.device === 'mobile';
    },
    classObj() {
      return {
        isDashboard: this.isDashboard,
        hideSidebar: this.isDashboard ? false : !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.isMobile
      };
    },
    // 只有一个子菜单的
    isDashboard() {
      return ['/dashboard'].includes(this.$route.name);
    },
    showRefresh() {
      return !RefreshExclude.includes(this.$route.path);
    }
  },
  mounted() {
    if (this.isExpired) {
      const msg = !this.getExpiresDate ? `系统即将到期，请及时续费` : `系统还有${this.getExpiresDate}天到期，请及时续费`;
      this.$bus.open(() => {
        this.$refs.paymentRef.show();
      }, msg);
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login`);
    }
  }
};