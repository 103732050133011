/* eslint-disable no-empty-pattern */

import { fetchPrePayBalance, fetchRegimentCollect, addCollect, deleteDown, fetchBillDetail } from '@/api/bill/billDown';
import { completeRequest } from '@/api/bill/billDown/collectUnit';
import { fetchOtherIncome } from '@/api/bill/billCheck';
import { CashFeeType } from '@/config/billDown';
import { CasherStatusLabel } from '@/config/billDown/cashStatus';
import { formatCreateTime } from '@/utils/data';
const dfaultState = () => ({
  prePaymentMoney: 0,
  otherIncome: null,
  curOtherIncome: null
});
const state = dfaultState();
const getters = {
  otherIncome: state => state.otherIncome,
  curOtherIncome: state => state.curOtherIncome,
  prePaymentMoney: state => state.prePaymentMoney
};
const actions = {
  // 其他收入
  fetchOtherIncomeList({
    commit
  }, params) {
    return fetchOtherIncome(params).then(res => {
      const {
        data
      } = res;
      commit('SET_DATA', {
        type: 'otherIncome',
        data
      });
      return data;
    });
  },
  // 获取应收余额
  fetchCollectBalance({
    commit
  }, params) {
    return fetchPrePayBalance({
      ...params,
      action: CashFeeType.INCOME
    }).then(res => {
      if (!res) return;
      commit('PRE_PAYMENT_MONEY', res.data);
    });
  },
  fetchCollected({}, params) {
    return fetchRegimentCollect(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        // 出纳确认状态
        const {
          cashierAuditStatus
        } = it;
        const cashierAuditStatusLabel = CasherStatusLabel[cashierAuditStatus.toUpperCase()];
        return {
          ...formatCreateTime(it, 'kpDate'),
          cashierAuditStatusLabel
        };
      });
      return data;
    });
  },
  fetchOtherIncomeComplete({}, params) {
    return completeRequest(params);
  },
  // 收款
  addCollect({}, opts) {
    const cashAction = CashFeeType.INCOME;
    return addCollect({
      ...opts,
      cashAction
    });
  },
  // 删除付款
  deleteCollect({}, opts) {
    return deleteDown(opts);
  },
  // 按id 查询日志流
  fetchDetail({}, id) {
    return fetchBillDetail(id).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  }
};
const mutations = {
  PRE_PAYMENT_MONEY(state, money) {
    state.prePaymentMoney = money;
  },
  SET_DATA(state, payload) {
    const {
      type,
      data
    } = payload;
    state[type] = data;
  },
  CUR_OTHER_INCOME(state, data) {
    state.curOtherIncome = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};