/* eslint-disable no-empty-pattern */

// 按团号付
import { DEFAULT_PAGE } from '@/config';
import { fetchRegimentPayed, addDownPay, updateDownPay, deleteDown, fetchBillDetail, fetchPrePayBalance } from '@/api/bill/billDown';
import { formatCreateTime } from '@/utils/data';
import { CashFeeType } from '@/config/billDown';
import { CasherStatusLabel } from '@/config/billDown/cashStatus';
const dfaultState = () => ({
  prePaymentMoney: 0
});
const state = dfaultState();
const getters = {
  prePaymentMoney: state => state.prePaymentMoney
};
const actions = {
  // 获取列表
  fetchPayed({}, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchRegimentPayed(queryParam).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      data.list = data.list.map(it => {
        // 出纳确认状态
        const {
          cashierAuditStatus
        } = it;
        const cashierAuditStatusLabel = CasherStatusLabel[cashierAuditStatus.toUpperCase()];
        return {
          ...formatCreateTime(it, 'kpDate'),
          cashierAuditStatusLabel
        };
      });
      return data;
    });
  },
  // 按id 查询日志流
  fetchDetail({}, id) {
    return fetchBillDetail(id).then(res => {
      if (!res || !res.data) return;
      return res.data;
    });
  },
  // 付款
  createPay({}, opts) {
    const cashAction = CashFeeType.PAY;
    return addDownPay({
      cashAction,
      ...opts
    });
  },
  // 更新付款
  updatePayed({}, opts) {
    const cashAction = CashFeeType.PAY;
    return updateDownPay({
      ...opts,
      cashAction
    });
  },
  // 删除付款
  deletePay({}, opts) {
    return deleteDown(opts);
  },
  // 获取应付余额
  fetchPayBalance({
    commit
  }, params) {
    return fetchPrePayBalance({
      ...params,
      action: CashFeeType.PAY
    }).then(res => {
      if (!res) return;
      commit('PRE_PAYMENT_MONEY', res.data);
    });
  }
};
const mutations = {
  PRE_PAYMENT_MONEY(state, money) {
    state.prePaymentMoney = money;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};