var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bill-regiment-collect"
  }, [_c("SearchUnit", {
    ref: "searchRef",
    attrs: {
      path: _vm.path,
      "is-pay": false,
      loading: _vm.excelLoading,
      "resource-id": _vm.resourceId
    }
  }, [_c("SelectCustom", {
    attrs: {
      label: "客户"
    },
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.resourceId,
      callback: function ($$v) {
        _vm.resourceId = $$v;
      },
      expression: "resourceId"
    }
  })], 1), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };