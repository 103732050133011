var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.status ? _c("el-tag", {
    attrs: {
      effect: "plain",
      type: "success"
    }
  }, [_vm._v(" 已对账 ")]) : _c("el-tag", {
    attrs: {
      effect: "plain",
      type: "danger"
    }
  }, [_vm._v(" 未对账 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };