import { agesPrintRangeColumns as ageInterval } from '@/config/order';
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    config: {
      type: Array,
      default: () => ageInterval
    }
  },
  data: () => ({
    ageInterval: ageInterval,
    sexTotal: {
      maleCount: 0,
      femaleCount: 0
    }
  }),
  computed: {
    getPeopleNumber() {
      return `${this.sexTotal.maleCount} 男 ${this.sexTotal.femaleCount} 女`;
    }
  },
  watch: {
    data: {
      handler: function () {
        this.calcAge();
        this.calePeopleNumber();
        this.$emit('onMounted', {
          sexTotal: this.sexTotal,
          ageRange: this.ageInterval
        });
      },
      immediate: true
    }
  },
  methods: {
    calcAge() {
      // 计算年龄区间人数
      this.ageInterval = this.config.map(it => {
        it.num = this.data.filter(v => v.age >= it.min && v.age <= it.max).length;
        return it;
      });
    },
    calePeopleNumber() {
      const femaleList = this.data.filter(it => it.sex === 1 || it.sex === '女');
      this.sexTotal.femaleCount = femaleList.length;
      this.sexTotal.maleCount = this.data.length - femaleList.length;
      return this.sexTotal;
    }
  }
};