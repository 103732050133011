import FormGuide from './FormGuide';
import TableList from './TableList';
import GuideDetail from './GuideDetail';
import { resourceMixin, searchMixin } from '@/mixin';
import { BtnCreate, DialogAgreement, DialogLog } from '@/components/common';
import { resourceGuideManager } from '@/config/resource';
import { formatTableData } from '@/utils/data';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  info: ''
});
export default {
  components: {
    BtnCreate,
    DialogAgreement,
    DialogLog,
    FormGuide,
    TableList,
    GuideDetail
  },
  mixins: [resourceMixin, searchMixin],
  data: () => ({
    columnData: resourceGuideManager,
    tableData: [],
    form: {
      contact: '',
      isEnable: true,
      selectValue: ''
    },
    total: 0,
    searchParams: defaultState(),
    type: ''
  }),
  computed: {
    ...mapGetters({
      guideData: 'resource/guideManager/guideDataOrigin' // 导游信息
    })
  },
  watch: {
    guideData: {
      handler: function (data) {
        !data ? this.fetchData() : this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.tableData = [];
      this.$store.dispatch('resource/guideManager/fetch', queryParam);
    },
    fetchList() {
      this.$store.dispatch('resource/guideManager/fetchList');
    },
    initData() {
      this.fetchData(this.searchParams);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guideManager/createOrUpdate', opts).then(() => {
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = formatTableData(list);
    },
    deleteRow(id) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guideManager/delete', id).then(() => {
        this.initData();
        this.fetchList();
        this.$bus.tip();
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    handleSelect(val) {
      this.searchParams.type = val.label;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    showCreateDialog() {
      this.$refs.formRef.show();
    },
    showEditDialog(item) {
      this.$refs.formRef.edit(item);
    },
    deleteTableRow(scope) {
      const {
        id
      } = scope.row;
      this.$bus.open(() => {
        this.deleteRow(id);
      });
    },
    editTableRow(scope) {
      const copy = scope;
      this.showEditDialog(copy);
    },
    showTableDetail(scope) {
      this.$refs.detailRef.show(scope.row);
    },
    showAgreement(scope) {
      this.$refs.agreementRef.show(scope.row);
    },
    showLog(scope) {
      const resourceId = scope.row.id;
      this.$store.dispatch('resource/guideManager/fetchLog', resourceId).then(res => {
        this.$refs.logRef.show(res);
      });
    }
  }
};