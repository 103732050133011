import "core-js/modules/es.array.push.js";
import TableReceivable from './TableReceivable';
import { SelectCustom, SelectStaff, BtnExport, InputMonthRange, TableTotalBox } from '@/components/common';
import { incomeList } from '@/config/report';
import { searchMixin, excelMixin } from '@/mixin';
import { getDateBetween } from '@/utils';
import { exportTable } from '@/utils/exportTableUtil';
// import { parseTime } from '@/utils'
// const today = parseTime(new Date(), '{y}-{m}')
// const Jan = parseTime(new Date(), '{y}') + '-01'
export default {
  components: {
    SelectCustom,
    SelectStaff,
    BtnExport,
    TableReceivable,
    InputMonthRange,
    TableTotalBox
  },
  mixins: [searchMixin, excelMixin],
  data: () => ({
    columnData: [],
    tableData: [],
    sendMonth: [],
    //queryParam
    total: 0,
    statisticData: [],
    searchParams: {
      startMonth: '',
      endMonth: ''
    },
    //startMonth: Jan, endMonth: '2022-04'
    statistic: {},
    statisticsKeys: [],
    isTableShow: true
  }),
  mounted() {
    // this.fetchColumnData(this.searchParams)
    // this.fetchData(this.searchParams)
  },
  methods: {
    fetchColumnData(searchParams) {
      const {
        startMonth,
        endMonth
      } = searchParams;
      const year = getDateBetween(startMonth, endMonth, 'year');
      const month = getDateBetween(startMonth, endMonth, 'month');
      const columnYear = year.map(it => {
        const item = {
          attrs: {
            prop: '',
            label: it.toString()
          },
          children: []
        };
        return item;
      });
      this.$nextTick(() => {
        this.statisticsKeys = ['total'];
        month.forEach(it => {
          const [year, month] = it.split('-');
          columnYear.forEach(v => {
            if (year == v.attrs.label) {
              const item = {
                attrs: {
                  prop: it + 'income',
                  label: month + '月'
                }
              };
              this.statisticsKeys.push(it + 'income');
              v.children.push(item);
            }
          });
        });
        this.columnData = [...incomeList, ...columnYear, {
          attrs: {
            prop: 'total',
            label: '合计'
          }
        }];
        this.isTableShow = true;
      });
    },
    fetchData(queryParam) {
      this.isTableShow = false;
      const {
        startMonth,
        endMonth
      } = queryParam;
      if (!startMonth || !endMonth) {
        this.$bus.tip({
          type: 'warning',
          message: '查询年月开始时间和结束时间不能为空！'
        });
        return false;
      }
      this.fetchColumnData(queryParam);
      this.$store.dispatch('report/index/fetchCustomeIncomeList', queryParam).then(res => {
        // 去除客户为空或者没有区域经理的数据
        const list = res.filter(it => it.customerId && it.customer && it.customer.customerAreaManagerId > 0);
        const data = this.formatData(list);
        // 分组
        const group = this.formatList(data);
        const sumArray = this.formatSumData(group);
        this.statistic = this.formatSum(sumArray);
        this.statisticData = [this.statistic];
        this.tableData = this.formatMergeData(group, sumArray);
      });
    },
    // 计算合计
    formatSum(list) {
      if (!list.length) return [];
      let sumObj = {};
      this.statisticsKeys.forEach(key => {
        sumObj[key] = 0;
      });
      list.forEach(it => {
        this.statisticsKeys.forEach(key => {
          sumObj[key] += it[key];
          sumObj.outOPUserName = '';
          sumObj.customerName = '';
        });
      });
      return sumObj;
    },
    // 合并小计和重构后的数组
    formatMergeData(sortList, sumArray) {
      let result = [];
      sortList.forEach(it => {
        it.forEach(v => {
          result.push(v);
        });
      });
      let list = [...result, ...sumArray];
      list.sort((a, b) => {
        return a.outOPUserId - b.outOPUserId;
      });
      return list;
    },
    // 计算小计
    formatSumData(list) {
      let sumArray = [];
      list.forEach(item => {
        let sumObj = {};
        this.statisticsKeys.forEach(key => {
          sumObj[key] = 0;
        });
        item.forEach(it => {
          this.statisticsKeys.forEach(key => {
            sumObj.customer = it.customer;
            sumObj[key] += it[key];
            sumObj.index = '小计';
            sumObj.outOPUserName = it.outOPUserName;
            sumObj.customerName = it.outOPUserName + '应收账款';
            sumObj.outOPUserId = it.customer.customerAreaManagerId;
          });
        });
        sumArray.push({
          ...sumObj,
          isTotal: true
        });
      });
      return sumArray;
    },
    // 分组
    formatList(list) {
      const objBy = (array, f) => {
        let objs = {};
        array.forEach(it => {
          let obj = JSON.stringify(f(it));
          objs[obj] = objs[obj] || [];
          objs[obj].push(it);
        });
        return Object.keys(objs).map(v => {
          return objs[v];
        });
      };
      const sorted = objBy(list, item => {
        return item.outOPUserId;
      });
      // 返回分组并添加上序号
      return sorted.map(it => {
        it.forEach((v, i) => {
          v.index = i + 1;
        });
        return it;
      });
    },
    // 重构基础数据
    formatData(list) {
      if (!list.length) return [];
      return list.map(it => {
        it.total = 0;
        it.isTotal = false;
        it.customerName = it.customer.name;
        it.outOPUserId = it.customer.customerAreaManagerId;
        it.outOPUserName = it.customer.customerAreaManager;
        it.yearMonthIncomes.forEach(v => {
          it[v.month + 'income'] = v.income;
          it.total += v.income;
        });
        return it;
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        statistics
      } = data;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    // 选择散、发团日期
    changeMonth(date) {
      this.searchParams.startMonth = date[0];
      this.searchParams.endMonth = date[1];
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 导出 excel
    exportExcel() {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '没有数据导出！'
        });
        return false;
      }
      const loading = this.$bus.loading();
      // 如果打印出错定时关闭
      setInterval(() => {
        loading.close();
      }, 3000);
      let elt = document.getElementById('export-receivable-table');
      exportTable(elt, '应收账款汇总表', '应收账款汇总表导出', 2);
      loading.close();
    }
  }
};