// 查询类型：transport-大交通，bus-车队，scenic-景区，guide-导游， hotel-酒店，insurance-保险， shop-购物店，restaurant-餐厅，customer-客户，company-公司，第三方资源公司，sign-签单，visa-签证 selfCompany-自费
const str = 'bank,transport,bus,scenic,guide,guide_manager,guide_team,hotel,insurance,shop,restaurant,customer,company,sign,line,product,selfCompany,other';
const types = {};
str.split(',').map(it => {
  types[it] = it.toUpperCase();
});
export const TYPES = types;

// 对应地址栏的路由名称
export const SourceTypeLabel = {
  scenic: '景区成本',
  hotel: '酒店成本',
  fleet: '车队成本',
  pickup: '接送成本',
  restaurant: '餐厅成本',
  traffic: '交通成本',
  insurance: '保险成本',
  reception: '地接成本',
  outTransfer: '外转成本',
  guide: '导游报账',
  team: '应收团款',
  shop: '购物收款',
  shopsubsidy: '购物二返收款',
  customer: '客户返款|应收团款',
  approve: '导游借款'
};
export const SourceType = {
  scenic: 'scenic',
  restaurant: 'restaurant',
  hotel: 'hotel',
  fleet: 'fleet',
  pickup: 'pickup',
  traffic: 'traffic',
  insurance: 'insurance',
  reception: 'reception',
  team: 'team',
  guide: 'guide',
  shop: 'shop',
  shopSubsidy: 'shopSubsidy',
  other: 'other',
  customer: 'customer',
  outTransfer: 'outTransfer',
  approve: 'approve'
};

//  1-订单， 2-计划，3-外转订单，4-签单，5-（团）审核, 6-对账,
export const LOG_YPES = {
  order: 1,
  plan: 2,
  outTransfer: 3,
  sign: 4,
  audit: 5,
  bill: 6,
  resourcePlan: 8,
  inquire: 9
};
export const LOG_SUBTYPE = {
  OTHER_INCOME: 'ortherIncome',
  OTHER_PAY: 'ortherPay',
  GUIDE_BORROW: 'guideBorrow',
  BUS_COMPANY: 'bus',
  HOTEL: 'hotel',
  SCENIC: 'scenic',
  RESTAURANT: 'restaurant',
  INSURANCE: 'insurance',
  TRANSPORT: 'transport',
  SHUTTLE: 'shuttle',
  ADD_SCENIC: 'scenic',
  SHOP: 'shop',
  GUIDE: 'guide',
  FEE: 'fee',
  OTHER: 'other',
  COLLECT: 'collect'
};
export const DICTYPES = {
  scenic: 'TOURIST',
  // 景区景点
  hotel: 'HOTEL',
  // 酒店民宿
  restaurant: 'RESTAURANT',
  // 餐厅酒楼
  shop: 'MARKET',
  // 购物店
  guide: 'GUIDE',
  // 导游信息
  barclub: 'BARCLUB',
  // 酒吧娱乐
  parking: 'PARKING',
  // 停车场
  car: 'CAR' // 车辆信息
};
export const resourceAccountObj = {
  account: '',
  bank: '',
  bankCard: '',
  bankCardType: 0 // 银行账号类型，0-私人账户，1-对公账户
};
export const resourceSourceObj = {
  name: '',
  contact: '',
  mobilePhone: '',
  telphone: '',
  faxNumber: '',
  remarks: '',
  isEnable: false
};

// 导游管理
export const resourceGuide = [{
  attrs: {
    prop: 'name',
    label: '姓名',
    sortable: true
  }
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '手机号码',
    'min-width': 90
  }
}, {
  attrs: {
    prop: 'guideCard',
    label: '导游证'
  }
}, {
  attrs: {
    prop: 'idCard',
    label: '身份证',
    'min-width': 180
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: '',
    label: '企业码'
  },
  slot: 'enterpriseCode'
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人',
    sortable: true
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 200
  },
  slot: 'action'
}];

// 导游管理
export const resourceGuideManager = [{
  attrs: {
    prop: 'name',
    label: '姓名',
    sortable: true
  }
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'idCard',
    label: '身份证',
    'min-width': 220
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人',
    sortable: true
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 200
  },
  slot: 'action'
}];
export const resourceGuideCrew = [{
  attrs: {
    prop: 'name',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '手机号码'
  }
}];
export const resourceGuideList = [{
  attrs: {
    prop: 'name',
    label: '导游'
  }
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
export const resourceGuideCaptain = [{
  attrs: {
    prop: 'guideName',
    label: '组长'
  }
}, {
  attrs: {
    prop: 'mobileIphone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'name',
    label: '组名'
  }
}, {
  attrs: {
    prop: '',
    label: '导游证号'
  }
}, {
  attrs: {
    prop: 'idCard',
    label: '身份证号'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 车队管理
export const resourceFleet = [{
  attrs: {
    prop: 'name',
    label: '车队名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'manage',
    label: '管理'
  },
  slot: 'manage'
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 其他单位
export const resourceOther = [{
  attrs: {
    prop: 'name',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 司机管理
export const resourceDriver = [{
  attrs: {
    prop: 'name',
    label: '司机姓名'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 车辆管理
export const resourceVehicle = [{
  attrs: {
    prop: 'licenceNumber',
    label: '车牌号'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 酒店管理
export const resourceHotel = [{
  attrs: {
    prop: 'hotelName',
    label: '酒店名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'standard',
    label: '酒店标准'
  },
  slot: 'standard'
}, {
  attrs: {
    prop: 'area',
    label: '区域'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 220,
    fixed: 'right'
  },
  slot: 'action'
}];

// 酒店房型
export const resourceRoomType = [{
  attrs: {
    prop: 'roomType',
    label: '房型'
  },
  slot: 'roomType'
}, {
  attrs: {
    prop: 'bedType',
    label: '床型'
  },
  slot: 'bedType'
}, {
  attrs: {
    prop: 'quantity',
    label: '间数'
  },
  slot: 'quantity'
}, {
  attrs: {
    prop: 'area',
    label: '面积(平方米)'
  },
  slot: 'area'
}, {
  attrs: {
    prop: 'floor',
    label: '楼层'
  },
  slot: 'floor'
}, {
  attrs: {
    prop: 'isWindow',
    label: '窗户'
  },
  slot: 'isWindow'
}, {
  attrs: {
    prop: 'isAddRoom',
    label: '加床'
  },
  slot: 'isAddRoom'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
// 保险管理
export const resourceInsurance = [{
  attrs: {
    prop: 'name',
    label: '保险公司'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// // 自费管理
// export const resourceSelfPay = [
//   { attrs: { prop: '', label: '自费商家' } },
//   { attrs: { prop: '', label: '联系人' } },
//   { attrs: { prop: '', label: '手机号码' } },
//   { attrs: { prop: '', label: '座机号码' } },
//   { attrs: { prop: '', label: '传真号码' } },
//   { attrs: { prop: '', label: '预付余额' } },
//   { attrs: { prop: '', label: '备注' } },
//   { attrs: { prop: '', label: '账户' } },
//   { attrs: { prop: 'createTime', label: '创建时间' } },
//   {
//     attrs: { prop: 'isEnable', label: '启用状态', sortable: true },
//     slot: 'isEnable',
//   },
//   {
//     attrs: { prop: 'action', label: '操作', width: 200, fixed: 'right' },
//     slot: 'action',
//   },
// ]

// 景区管理
export const resourceScenic = [{
  attrs: {
    prop: 'name',
    label: '景区名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'scenicType',
    label: '是否免费',
    sortable: true
  },
  slot: 'scenicType'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 餐厅管理
export const resourceRestaurant = [{
  attrs: {
    prop: 'name',
    label: '餐厅名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商户'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 购物店管理
export const resourceShopping = [{
  attrs: {
    prop: 'name',
    label: '购物店名称'
  }
}, {
  attrs: {
    prop: 'contentType',
    label: '项目'
  },
  slot: 'item'
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'area',
    label: '地区'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商家'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'isExport',
    label: '导出状态',
    sortable: true
  },
  slot: 'isExport'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 交通管理
export const resourceTraffic = [{
  attrs: {
    prop: 'name',
    label: '大交通'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'code',
    label: '商户'
  },
  slot: 'code'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 产品管理
export const resourceProduct = [{
  attrs: {
    prop: 'name',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'days',
    label: '行程天数'
  }
}, {
  attrs: {
    prop: 'productType',
    label: '产品类型'
  }
}, {
  attrs: {
    prop: 'linenName',
    label: '所属系列'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 360,
    fixed: 'right'
  },
  slot: 'action'
}];

// 产品客户结算价
export const resourceProductCustom = [{
  attrs: {
    prop: 'customerName',
    label: '客户'
  }
}, {
  attrs: {
    prop: 'price',
    label: '结算价'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}];

// 产品行程
export const resourceProductLine = [{
  attrs: {
    prop: 'date',
    label: '日期'
  }
}, {
  attrs: {
    prop: 'line',
    label: '行程',
    'min-width': 500
  }
}, {
  attrs: {
    prop: 'breakfast',
    label: '早餐',
    'min-width': 40
  },
  slot: 'breakfast'
}, {
  attrs: {
    prop: 'lunch',
    label: '午餐',
    'min-width': 40
  },
  slot: 'lunch'
}, {
  attrs: {
    prop: 'dinner',
    label: '晚餐',
    'min-width': 40
  },
  slot: 'dinner'
}, {
  attrs: {
    prop: 'hotel',
    label: '酒店'
  },
  children: [{
    attrs: {
      prop: 'area',
      label: '区域'
    }
  }, {
    attrs: {
      prop: 'standard',
      label: '标准'
    }
  }]
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}];

// 线路管理
export const resourceLine = [{
  attrs: {
    prop: 'name',
    label: '线路名称'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    sortable: true
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 客户管理
export const resourceCustom = [{
  attrs: {
    prop: 'name',
    label: '客户名称',
    sortable: true,
    'min-width': 220
  }
}, {
  attrs: {
    prop: 'customerType',
    label: '客户类型'
  }
}, {
  attrs: {
    prop: 'codeName',
    label: '客户代码'
  }
}, {
  attrs: {
    prop: 'creditMoney',
    label: '授信额度',
    'min-width': 120
  },
  slot: 'creditMoney'
}, {
  attrs: {
    prop: 'balance',
    label: '授信余额'
  }
}, {
  attrs: {
    prop: 'province',
    label: '省份'
  }
}, {
  attrs: {
    prop: 'info',
    label: '联系人信息'
  },
  children: [{
    attrs: {
      prop: 'contact',
      label: '姓名',
      'min-width': 120
    },
    slot: 'contact'
  }, {
    attrs: {
      prop: 'mobilePhone',
      label: '手机',
      'min-width': 120
    },
    slot: 'mobilePhone'
  }, {
    attrs: {
      prop: 'telphone',
      label: '座机',
      'min-width': 120
    },
    slot: 'telphone'
  }]
}, {
  attrs: {
    prop: 'customerAreaManager',
    label: '区域经理'
  }
}, {
  attrs: {
    prop: 'customerArea',
    label: '区域'
  }
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态'
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 220,
    fixed: 'right'
  },
  slot: 'action'
}];
export const resourceCustomContactBase = [{
  attrs: {
    prop: 'contact',
    label: '联系人姓名'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'IDCard',
    label: '身份证号'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  }
}];
export const resourceCustomContact = [{
  attrs: {
    prop: 'contact',
    label: '联系人姓名'
  },
  slot: 'contact'
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  },
  slot: 'mobilePhone'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'IDCard',
    label: '身份证号'
  },
  slot: 'IDCard'
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  },
  slot: 'telphone'
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  },
  slot: 'faxNumber'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];
export const resourceCustomContactDetail = resourceCustomContactBase;

// export const contactDetailAction = [
//   { attrs: { prop: 'contact', label: '联系人姓名' }, slot: 'contact' },
//   { attrs: { prop: 'mobilePhone', label: '手机号码' }, slot: 'mobilePhone' },
//   { attrs: { prop: 'telphone', label: '座机号码' }, slot: 'telphone' },
//   { attrs: { prop: 'faxNumber', label: '传真号码' }, slot: 'faxNumber' },
//   {
//     attrs: { prop: 'action', label: '操作' },
//     slot: 'action',
//   },
// ]

// 账户管理
export const resourceAccount = [{
  attrs: {
    prop: 'name',
    label: '名称'
  }
}, {
  attrs: {
    prop: 'cardNo',
    label: '卡号',
    width: 180
  }
}, {
  attrs: {
    prop: 'bank',
    label: '开户行',
    width: 180
  }
}, {
  attrs: {
    prop: 'account',
    label: '开户人',
    width: 180
  }
}, {
  attrs: {
    prop: 'initDate',
    label: '期初日期',
    width: 90
  }
}, {
  attrs: {
    prop: 'initBlance',
    label: '期初金额'
  }
}, {
  attrs: {
    prop: 'totalReceipts',
    label: '收款'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '付款'
  }
}, {
  attrs: {
    prop: '',
    label: '结余'
  },
  slot: 'settlement'
}, {
  attrs: {
    prop: 'blance',
    label: '余额'
  },
  slot: 'blance'
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人',
    width: 60
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    width: 90
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    width: 80
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 账户详情
export const resourceAccountDetail = [{
  attrs: {
    prop: 'logNumber',
    label: '日记号'
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '大团号'
  }
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '下账日期',
    width: 80
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '日期',
    width: 80
  }
}, {
  attrs: {
    prop: 'resourceName',
    label: '单位'
  }
}, {
  attrs: {
    prop: 'subjectName',
    label: '会计科目'
  },
  slot: 'subject'
}, {
  attrs: {
    prop: 'cashTypeLabel',
    label: '现金日记类型'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '销账人'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行账户'
  }
}, {
  attrs: {
    prop: 'bankNo',
    label: '卡号',
    'min-width': 120
  }
}, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'cashActionLabel',
    label: '费用类型'
  }
}, {
  attrs: {
    prop: 'cashModeLabel',
    label: '销账模式'
  }
}, {
  attrs: {
    prop: 'cash',
    label: '报账金额',
    width: 80
  }
}, {
  attrs: {
    prop: 'used',
    label: '销账金额',
    width: 80
  }
}, {
  attrs: {
    prop: 'balance',
    label: '余额',
    width: 80
  }
}, {
  attrs: {
    prop: 'createUserName',
    label: '创建人'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间',
    width: 80
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 120
  },
  slot: 'action'
}];

// 公司管理
export const resourceCompany = [{
  attrs: {
    prop: 'name',
    label: '公司名称'
  }
}, {
  attrs: {
    prop: 'contact',
    label: '联系人'
  }
}, {
  attrs: {
    prop: 'mobilePhone',
    label: '手机号码'
  }
}, {
  attrs: {
    prop: 'telphone',
    label: '座机号码'
  }
}, {
  attrs: {
    prop: 'faxNumber',
    label: '传真号码'
  }
}, {
  attrs: {
    prop: 'incomeBlance',
    label: '预收余额'
  }
}, {
  attrs: {
    prop: 'payBlance',
    label: '预付余额'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'code',
    label: '编码'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态'
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 200,
    fixed: 'right'
  },
  slot: 'action'
}];

// 票据管理
export const resourceTickete = [{
  attrs: {
    prop: 'name',
    label: '票据名称'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'type',
    label: '票据类型'
  },
  slot: 'type'
}, {
  attrs: {
    prop: 'isCommon',
    label: '是否通用'
  },
  slot: 'isCommon'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: '',
    label: '启用状态'
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'createTime',
    label: '创建时间'
  }
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    width: 200
  },
  slot: 'action'
}];

// 资源酒店协议价格
export const resourceAgreementHotel = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'quotedPrice',
    label: '报价',
    'min-width': 140
  },
  slot: 'quotedPrice'
}, {
  attrs: {
    prop: 'price',
    label: '底价',
    'min-width': 140
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'contractPriceFrees',
    label: '减免',
    'min-width': 220
  },
  slot: 'contractPriceFrees'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注',
    'min-width': 200
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 餐厅协议价格
export const resourceAgreementRestaurant = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'price',
    label: '单价',
    'min-width': 140
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'contractPriceFrees',
    label: '减免',
    'min-width': 220
  },
  slot: 'contractPriceFrees'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 购物店协议价格
export const resourceAgreementShopping = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
},
// {
//   attrs: { prop: 'subsidy', label: '返款' },
//   slot: 'subsidy',
// },
{
  attrs: {
    prop: 'shopRate',
    label: '社佣比例(%)'
  },
  slot: 'shopRate'
}, {
  attrs: {
    prop: 'guideRate',
    label: '导佣比例(%)'
  },
  slot: 'guideRate'
}, {
  attrs: {
    prop: 'driverRate',
    label: '司佣比例(%)'
  },
  slot: 'driverRate'
}, {
  attrs: {
    prop: 'accompanyRate',
    label: '陪佣比例(%)'
  },
  slot: 'accompanyRate'
}, {
  attrs: {
    prop: 'guideManagerRate',
    label: '导管比例(%)'
  },
  slot: 'guideManagerRate'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 景区协议价格
export const resourceAgreementScenic = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'quotedPrice',
    label: '成人报价'
  },
  slot: 'quotedPrice'
}, {
  attrs: {
    prop: 'price',
    label: '成人底价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'contractPriceFrees',
    label: '减免',
    'min-width': 220
  },
  slot: 'contractPriceFrees'
}, {
  attrs: {
    prop: 'orderNumber',
    label: '排序'
  },
  slot: 'orderNumber'
}, {
  attrs: {
    prop: 'shopRate',
    label: '社佣比例(%)'
  },
  slot: 'shopRate'
}, {
  attrs: {
    prop: 'guideRate',
    label: '导佣比例(%)'
  },
  slot: 'guideRate'
}, {
  attrs: {
    prop: 'driverRate',
    label: '司佣比例(%)'
  },
  slot: 'driverRate'
}, {
  attrs: {
    prop: 'accompanyRate',
    label: '陪佣比例(%)'
  },
  slot: 'accompanyRate'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action'
}];

// 交通协议价格
export const resourceAgreementTraffic = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'subsidy',
    label: '返款',
    'min-width': 140
  },
  slot: 'subsidy'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80
  },
  slot: 'action'
}];

// 协议列表
export const resourceAgreement = [{
  attrs: {
    prop: 'name',
    label: '协议名称'
  }
}, {
  attrs: {
    prop: 'unit',
    label: '协议单位'
  }
}, {
  attrs: {
    prop: 'date',
    label: '日期范围'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'detail',
    label: '明细',
    'min-width': 400
  },
  slot: 'detail'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
}, {
  attrs: {
    prop: 'isEnable',
    label: '是否启用',
    'min-width': 80
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
}];

// 协议价格列表（其他资源通用）
export const resourceAgreementItem = [{
  attrs: {
    prop: 'name',
    label: '项目内容'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80
  },
  slot: 'action'
}];

// 资源酒店协议价格
export const resourceAgreementShop = [{
  attrs: {
    prop: 'name',
    label: '协议名称'
  },
  slot: 'name'
}, {
  attrs: {
    prop: 'unit',
    label: '协议单位'
  },
  slot: 'unit'
}, {
  attrs: {
    prop: 'date',
    label: '日期范围',
    width: 240
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'shopPrice',
    label: '人头单价'
  },
  slot: 'shopPrice'
}, {
  attrs: {
    prop: 'contentType',
    label: '项目内容',
    width: 200
  },
  slot: 'contentType'
}, {
  attrs: {
    prop: 'price',
    label: '单价'
  },
  slot: 'price'
}, {
  attrs: {
    prop: 'subsidy',
    label: '返款'
  },
  slot: 'subsidy'
}, {
  attrs: {
    prop: 'shopRate',
    label: '社佣比例(%)'
  },
  slot: 'shopRate'
}, {
  attrs: {
    prop: 'guideRate',
    label: '导佣比例(%)'
  },
  slot: 'guideRate'
}, {
  attrs: {
    prop: 'driverRate',
    label: '司佣比例(%)'
  },
  slot: 'driverRate'
}, {
  attrs: {
    prop: 'accompanyRate',
    label: '陪佣比例(%)'
  },
  slot: 'accompanyRate'
}, {
  attrs: {
    prop: 'isEnable',
    label: '启用状态',
    'min-width': 80
  },
  slot: 'isEnable'
}, {
  attrs: {
    prop: 'remarks',
    label: '备注'
  },
  slot: 'remarks'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80
  },
  slot: 'action'
}];