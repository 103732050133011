var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "bill-fast-dialog",
    attrs: {
      title: _vm.getTitle,
      "confirm-text": "报账",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_vm.visible ? _c("FormBase", [_c("BillInput", {
    ref: "formRef",
    attrs: {
      "is-guide": true,
      "resource-id": _vm.resourceId,
      "is-dialog": true,
      obj: _vm.form
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.guideName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideName", $$v);
      },
      expression: "form.guideName"
    }
  })], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };