import { processAdd, processMinus } from '@/utils';
export const formatUnpayData = list => {
  return list.map(it => {
    const {
      totalPay,
      cashPayment,
      alipayECodePay,
      totalPayed
    } = it;
    // 未付 = 应付-(现付+企业码付+已付)
    const sum = processAdd(cashPayment, alipayECodePay, totalPayed);
    const unPay = processMinus(totalPay, sum);
    return {
      ...it,
      unPay
    };
  });
};