import { tableMixin, summaryMixin } from '@/mixin';
import { orderAuditStatus } from '@/config/report';
export default {
  mixins: [tableMixin, summaryMixin],
  props: {
    statisticsKey: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      statisticsKeys: this.statisticsKey,
      sumTxt: '总计'
    };
  },
  methods: {
    orderAuditStatus({
      row
    }) {
      const {
        auditProgress
      } = row;
      const item = orderAuditStatus.filter(it => it.value == auditProgress)[0];
      return item.label;
    },
    //
    tableRowClassName({
      row
    }) {
      if (row && row.isTotal) {
        return 'warning-row';
      }
      return '';
    },
    tableCellClass({
      column
    }) {
      if (column.label == '未收' || column.label == '未收汇总') {
        return 'table-color-red';
      }
    }
  }
};