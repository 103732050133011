var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", _vm._g(_vm._b({
    attrs: {
      type: _vm.getType,
      icon: _vm.getIcon,
      plain: _vm.getPlain
    }
  }, "el-button", _vm.$attrs, false), _vm.$listeners), [_vm.$slots.default ? _vm._t("default") : _c("span", [_vm._v(_vm._s(_vm.getText))])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };