var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: _vm.isEdit ? "修改订单" : "订单详情",
      width: "1200px",
      "custom-class": "resource-order-dialog",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": _vm.isEdit
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleConfirm
    }
  }, [_c("FormBase", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客户"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.companyName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "接单日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.parseDate(_vm.info.orderDate)))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "跟单人"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.personLiableName))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.info.planNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [!_vm.getEditStatus ? [_c("LabelBase", {
    attrs: {
      label: "区域"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.info, "hotelArea")
    }
  })], 1)] : _c("SelectDictionary", {
    attrs: {
      label: "",
      placeholder: "请选择区域",
      code: _vm.hotelRegion
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectHotelRegion(_vm.info, $event);
      }
    },
    model: {
      value: _vm.info.hotelArea,
      callback: function ($$v) {
        _vm.$set(_vm.info, "hotelArea", $$v);
      },
      expression: "info.hotelArea"
    }
  })], 2), _c("div", {
    staticClass: "input-box"
  }, [!_vm.getEditStatus ? [_c("LabelBase", {
    attrs: {
      label: "标准"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.info, "hotelStandard")
    }
  })], 1)] : _c("SelectDictionary", {
    attrs: {
      label: "",
      placeholder: "请选择标准",
      code: _vm.hotelStandard
    },
    on: {
      "on-select": function ($event) {
        return _vm.selectHotelStandard(_vm.info, $event);
      }
    },
    model: {
      value: _vm.info.hotelStandard,
      callback: function ($$v) {
        _vm.$set(_vm.info, "hotelStandard", $$v);
      },
      expression: "info.hotelStandard"
    }
  })], 2)])]), _c("el-divider"), !_vm.getEditStatus ? _c("OrderInfo", {
    attrs: {
      data: _vm.orderInfoData
    }
  }) : _c("OrderApply", {
    attrs: {
      data: _vm.orderInfoData
    }
  }), _c("el-divider"), _c("PayInfo", {
    attrs: {
      data: _vm.info,
      "is-edit": _vm.getEditStatus
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };