import { planEditReceptionRemark } from '@/config/plan';
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isAudit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columnData: planEditReceptionRemark
    };
  }
};