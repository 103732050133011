import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 审核管理
import Layout from '@/layout';
const resourceOrderRouter = {
  path: '/resource-order',
  component: Layout,
  redirect: '/resource-order/hotel',
  name: 'resourceOrder',
  meta: {
    title: 'resourceOrder',
    icon: 'icon-dingdanxiafa'
  },
  children: [{
    path: '/resource-order/hotel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resourceOrder/hotel/index.vue'))),
    name: 'resourceOrderHotel',
    // 酒店预定
    meta: {
      title: 'resourceOrderHotel',
      icon: 'icon-changguanyuyue'
    }
  }, {
    path: '/resource-order/fleet',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resourceOrder/fleet/index.vue'))),
    name: 'resourceOrderFleet',
    // 车辆预定
    meta: {
      title: 'resourceOrderFleet',
      icon: 'icon-jiejifuwu'
    }
  }, {
    path: '/resource-order/restaurant',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resourceOrder/restaurant/index.vue'))),
    name: 'resourceOrderRestaurant',
    // 餐厅预定
    meta: {
      title: 'resourceOrderRestaurant',
      icon: 'icon-canting'
    }
  }, {
    path: '/resource-order/scenic',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resourceOrder/scenic/index.vue'))),
    name: 'resourceOrderScenic',
    // 景区预定
    meta: {
      title: 'resourceOrderScenic',
      icon: 'icon-lvyoufabu'
    }
  }]
};
export default resourceOrderRouter;