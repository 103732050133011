var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-table", _vm._g(_vm._b({
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "highlight-current-row": true
    }
  }, "el-table", _vm.$attrs, false), _vm.$listeners), [_vm._l(_vm.columnData, function (column, index) {
    return _c("el-table-column", _vm._b({
      key: index,
      attrs: {
        align: _vm.align
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [column.slot ? _c("span", [_vm._t(column.slot, null, {
            scope: scope
          })], 2) : _c("span", [_vm._v(_vm._s(scope.row[scope.column.property]))])];
        }
      }], null, true)
    }, "el-table-column", column.attrs || {}, false));
  }), _vm.showEmpty ? _c("template", {
    slot: "empty"
  }, [_c("Empty")], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };