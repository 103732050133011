import "core-js/modules/es.error.cause.js";
import { isSuperMan } from '@/utils/power';
const defaultState = () => ({
  oldPwd: '',
  pwd: '',
  checkPass: '',
  reSet: false
});
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.formRef.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      showPwd: false,
      passwordType: 'password',
      form: defaultState(),
      rules: {
        // oldPwd: [
        //   { required: true, message: '旧密码不能为空！', trigger: 'blur' },
        // ],
        pwd: [{
          required: true,
          message: '新密码不能为空！'
        }, {
          validator: validatePass,
          trigger: 'blur'
        }],
        checkPass: [{
          required: true,
          message: '确认密码不能为空！'
        }, {
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    getPwdType() {
      return !this.showPwd ? 'password' : '';
    }
  },
  methods: {
    show(phone) {
      this.visible = true;
      this.form.mobileIphone = phone;
    },
    hide() {
      this.visible = false;
      this.form = defaultState();
      this.$refs.formRef.resetFields();
    },
    handleCreate() {
      this.$refs.formRef.validate().then(valid => {
        if (valid) {
          const opts = this.form;
          // 超级管理员不验证旧密码
          isSuperMan() ? opts.reSet = false : null;
          this.$store.dispatch('resource/guide/updatePwd', opts).then(() => {
            this.$bus.tip({
              message: '更新密码成功'
            });
            this.hide();
          });
        }
      });
    }
  }
};