import billDownMixin from '../../billDownMixin';
import SearchUnPay from '../../components/SearchUnPay.vue';
import { PersonCount, Custom } from '@/components/common';
import { Guides, StatusAuditProgress } from '@/components/common';
import { downBillUnitCollect } from '@/config/billDown';
import { rowspanMixin } from '@/mixin';
export default {
  components: {
    PersonCount,
    Custom,
    Guides,
    SearchUnPay,
    StatusAuditProgress
  },
  mixins: [rowspanMixin, billDownMixin],
  props: {
    rid: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      columns: downBillUnitCollect,
      tableData: [],
      total: 0,
      billCheckAll: false,
      resourceId: '',
      statisticsKeys: ['totalCollect', 'totalIncome', 'totalSubsidy', 'agencyMoney', 'downPayed', 'owePay']
    };
  },
  computed: {
    getTableData() {
      return this.tableData;
    }
  },
  mounted() {
    const {
      rid: resourceId,
      id: logCashId
    } = this.$route.query;
    if (resourceId && logCashId) {
      this.fetchData({
        resourceId,
        id: logCashId
      });
    }
    this.resourceId = resourceId;
  },
  methods: {
    fetchData(params = {}) {
      this.fetchUnpayData(params);
    },
    fetchUnpayData(params) {
      return this.$store.dispatch('bill/billDown/fetchUnpayList', {
        resourceId: this.resourceId,
        ...params
      }).then(data => {
        this.tableData = data.list;
        this.total = data.totalCount;
        this.handleAutoDown();
        // this.changeCheckAll(true)
      });
    },
    handleSelect(rid) {
      this.resourceId = rid;
      this.fetchUnpayData();
      // 重置余额
      this.restoreBalance();
    },
    handleSearch(params) {
      this.fetchData(params);
    },
    handlePage(params) {
      this.fetchData(params);
    },
    caclDownMoney(money) {
      if (!this.tableData.length) return;

      // 设置下账金额
      this.handleAutoDown(money);
    }
  }
};