import { getTableColumnStorage } from '@/utils/storage';
export default {
  name: 'TableTotalBox',
  props: {
    // statistic: {
    //   type: Object,
    //   required: true,
    // },

    data: {
      type: Array,
      required: true
    },
    align: {
      type: String,
      default: 'center'
    },
    isTitel: {
      type: Boolean,
      default: true
    },
    columnData: {
      type: Array,
      required: true
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnCopy: this.columnData.slice()
    };
  },
  mounted() {
    this.getCheckColmunsFormStorage();
    this.$bus.on('column:reset', () => {
      this.getCheckColmunsFormStorage();
    });
  },
  methods: {
    hideScroll() {
      if (this.hideScrollBar) {
        const el = this.$el.querySelector('.el-table__body-wrapper');
        // el.style.overflowX = 'hidden'
        el.style['webkitScrollbar'].display = 'none';
      }
    },
    getCheckColmuns() {
      this.columnCopy = this.columnData.filter(it => {
        return this.checkedColumns.includes(it.attrs.label);
      });
    },
    getCheckColmunsFormStorage() {
      const data = getTableColumnStorage();
      if (data && data[this.$route.path]) {
        this.checkedColumns = data[this.$route.path];
      } else {
        this.checkedColumns = this.columnData.map(it => {
          return it.attrs.label;
        });
      }
      this.getCheckColmuns();
    }
  }
};