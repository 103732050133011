var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      margin: "40px"
    }
  }, [_c("InputData", {
    attrs: {
      label: "客户后返"
    },
    model: {
      value: _vm.subsidy,
      callback: function ($$v) {
        _vm.subsidy = $$v;
      },
      expression: "subsidy"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };