import "core-js/modules/es.array.push.js";
import { ColumnItinerary } from '../tableConfig';
import { defaultItinerary } from '../config';
import { mergeTableRow } from '@/utils';
import { rowspanMixin } from '@/mixin';
export default {
  mixins: [rowspanMixin],
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: this.data,
      columnData: ColumnItinerary,
      headMergeNum: 1
    };
  },
  watch: {
    data: {
      handler: function (d) {
        this.tableData = d;
      },
      immediate: true
    }
  },
  methods: {
    handleAdd() {
      let index = this.tableData[this.tableData.length - 1].day_number;
      this.tableData.push({
        ...defaultItinerary(),
        day_number: ++index
      });
    },
    handleAddScenic(row) {
      const fIndex = this.tableData.findIndex(it => it == row);
      const list = this.tableData.filter(it => it.day_number == row.day_number);
      const item = {
        ...defaultItinerary(),
        ...row,
        site_num: ++list.length
      };
      this.tableData.splice(fIndex, 0, item);
      this.tableData = mergeTableRow(this.tableData, ['day_number']);
    },
    deleteItinerary(row) {
      this.tableData = this.tableData.filter(it => it != row);
      this.tableData = mergeTableRow(this.tableData, ['day_number']);
    },
    objectSpanMethod({
      row,
      column
    }) {
      const span = column['property'] + '-span';
      if (row[span]) {
        return row[span];
      }
    }
  }
};