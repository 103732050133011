import "core-js/modules/es.array.push.js";
import { TripNumber } from '@/components/common';
import { StatusAudit } from '@/components/common';
import { getPayTypeLabel } from '@/config/select';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin } from '@/mixin';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin],
  props: {
    columnData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      headMergeNum: 4,
      tailMergeNum: 9,
      // 数表格的格子数
      hasMergeTail: true,
      rowId: 1000000000000,
      statisticsKeys: ['rowTotal', 'otherFee', 'price']
    };
  },
  computed: {
    ...mapGetters({
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId'
      // hotelData: 'plan/hotel/hotelData',
    }),
    formatStandard() {
      return row => {
        const area = row.hotelArea.split('|')[0];
        const standard = row.hotelStandard.split('|')[0];
        return area && standard ? `${area}/${standard}` : `${area}${standard}`;
      };
    },
    getPrice() {
      return row => {
        return (row.quotePrice || 0) * (row.count || 0);
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.resetData();
    },
    fetchData() {
      this.$store.dispatch('plan/hotel/fetchAll', {
        planId: this.currentPlanId
      }).then(data => {
        this.initData(data);
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.roomList || [];
        it.hotelId = it.hotelId <= 0 ? '' : it.hotelId;
        const item = {
          ...it,
          rowId: it.id,
          planHotelId: it.id,
          itemIndex: 0,
          cashType: getPayTypeLabel(it.cashType)
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              rowTotal: it.totalPay,
              price: v.quotePrice
            });
          });
        }

        // 同步id
        this.rowId++;
      });

      // result.map(it => {
      //   it.rowTotal = this.calcRowTotalMoney(it)
      //   return it
      // })

      return result;
    },
    // 计算总额
    calcRowTotalMoney(row) {
      let {
        quotePrice,
        count,
        freeCount,
        times,
        otherFee
      } = row;
      quotePrice = quotePrice || 0;
      count = count || 0;
      freeCount = +freeCount || 0;
      times = times || 1;
      otherFee = +otherFee || 0;
      const res = quotePrice * (count - freeCount) * times + otherFee;
      return res;
    },
    // 重置表格与价格计算
    resetData() {
      this.rowspan();
      // this.mergeRowMoney() // 合并单条数据总价
    }
  }
};