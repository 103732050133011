import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import { SelectShop, SelectCustom } from '@/components/common';
import { ShopEntryTable as columns } from '@/config/shopping';
export default {
  components: {
    SelectShop,
    SelectCustom
  },
  data() {
    return {
      columns,
      fileName: '购物进店表',
      autoWidth: true,
      bookType: 'xlsx',
      excelLoading: false,
      searchParams: {
        shopId: '',
        orderDate: ''
      }
    };
  },
  computed: {
    tableData() {
      return this.$store.getters['shopping/index/shopEntryList'];
    }
  },
  methods: {
    fetchData(params = this.searchParams) {
      this.$store.dispatch('shopping/index/fetchFhoppingEntry', params);
    },
    handleSearch() {
      this.fetchData();
    },
    handleExport() {
      this.excelLoading = true;
      Promise.resolve().then(() => _interopRequireWildcard(require('@/vendor/Export2Excel'))).then(excel => {
        const tHeader = this.columns.map(it => '\t' + it.attrs.label);
        const filterVal = this.columns.map(it => it.attrs.prop);
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.fileName,
          autoWidth: this.autoWidth,
          bookType: this.bookType
        });
        this.excelLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j];
      }));
    }
  }
};