var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "pageParent",
    attrs: {
      id: "driverPrint"
    }
  }, [_c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "print-content"
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "0",
      left: "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.printDate) + " ")]), _c("table", {
    staticClass: "Prtable SxPrtable"
  }, [_c("tbody", [_c("tr", [_c("td", {
    attrs: {
      colspan: "20"
    }
  }, [_c("p", {
    staticStyle: {
      "font-size": "16pt",
      "text-align": "center",
      margin: "0px 0px",
      "line-height": "30px",
      "font-family": "'microsoft yahei'"
    }
  }, [_c("span", {
    staticStyle: {
      "line-height": "30px"
    }
  }, [_vm._v("司机接团通知单")]), _vm.data.orderDriverNumber ? _c("span", {
    staticStyle: {
      "margin-left": "100px",
      "font-size": "13px"
    }
  }, [_vm._v("NO. "), _c("span", {
    staticClass: "T-onlyNumber"
  }, [_vm._v(_vm._s(_vm.data.orderDriverNumber))])]) : _vm._e()])])])])]), _c("table", {
    staticClass: "Prtable"
  }, [_c("colgroup", _vm._l(24, function (item, colIndex) {
    return _c("col", {
      key: colIndex,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", {
    staticClass: "T-planBaseInfo"
  }, [_c("tr", [_c("td", {
    staticClass: "font-bold",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 大团号 ")]), _c("td", {
    attrs: {
      colspan: "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.planNumber) + " ")]), _c("td", {
    staticClass: "font-bold",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 地陪姓名 ")]), _c("td", {
    staticClass: "T-guideInfo",
    attrs: {
      colspan: "8"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.data.guideName ? _vm.data.guideName : "无") + " ")])])]), _vm._l(2, function (item, index) {
    return _c("tr", {
      key: item.id
    }, [_c("td", {
      staticClass: "font-bold",
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" 日期 ")]), _c("td", {
      staticClass: "getDate",
      staticStyle: {
        "mso-number-format": "'\\@'",
        "min-width": "100px"
      },
      attrs: {
        colspan: "4"
      }
    }, [_vm._v("     " + _vm._s(_vm.data.shuttleList[index] ? _vm.data.shuttleList[index].shuttleDate : "") + " ")]), _c("td", {
      staticClass: "font-bold",
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" 航班(车次) ")]), _c("td", {
      staticClass: "getNumber",
      staticStyle: {
        "mso-number-format": "'\\@'"
      },
      attrs: {
        colspan: "4"
      }
    }, [_vm._v("      " + _vm._s(_vm.data.shuttleList[index] ? _vm.data.shuttleList[index].shiftNumber : "") + " " + _vm._s(_vm.data.shuttleList[index] ? _vm.data.shuttleList[index].shiftDateTime : "") + " ")]), _c("td", {
      staticClass: "font-bold",
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" 人数 ")]), _c("td", {
      staticClass: "getPeople",
      staticStyle: {
        "min-width": "100px"
      },
      attrs: {
        colspan: "4"
      }
    }, [_vm.data.shuttleList.length ? [_vm._v("      " + _vm._s(_vm.data.shuttleList[0].adultCount) + " 大 " + _vm._s(_vm.data.shuttleList[0].childCount) + " 小 ")] : _vm._e()], 2)]);
  })], 2)]), _c("div", [_c("table", {
    staticClass: "Prtable"
  }, [_c("tbody", {
    staticClass: "T-list T-busCompanyOrder"
  }, [_c("tr", [_c("th", {
    staticStyle: {
      width: "60pt"
    },
    attrs: {
      rowspan: _vm.data.busTypes.length + 1,
      colspan: "2"
    }
  }, [_vm._v(" 旅游用车 ")]), _c("th", {
    attrs: {
      colspan: "3"
    }
  }, [_vm._v(" 车队 ")]), _c("th", {
    attrs: {
      colspan: "3"
    }
  }, [_vm._v(" 车型 ")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" 司机姓名 ")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" 司机电话 ")]), _c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 车牌号码 ")]), _c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" 车费 ")]), _c("th", {
    attrs: {
      colspan: "4"
    }
  }, [_vm._v(" 备注 ")]), _c("th", {
    staticStyle: {
      width: "160pt"
    },
    attrs: {
      colspan: "2"
    }
  }, [_vm._v(" 付款方式 ")])]), _vm._l(_vm.data.busTypes, function (item, index) {
    return _c("tr", {
      key: item.id
    }, [index == 0 ? _c("td", {
      attrs: {
        rowspan: _vm.getBusLength,
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.data.busCompanyName) + " ")]) : _vm._e(), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.busType) + " ")]), _c("td", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(item.driverName) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.mobilePhone) + " ")]), _c("td", {
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(item.licenceNumber) + " ")]), _c("td", {
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.busFee) + " ")]), index == 0 ? _c("td", {
      staticStyle: {
        width: "80pt"
      },
      attrs: {
        rowspan: _vm.getBusLength,
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.data.remark) + " ")]) : _vm._e(), index == 0 ? _c("td", {
      staticStyle: {
        width: "160pt"
      },
      attrs: {
        rowspan: _vm.getBusLength,
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.getPayType(_vm.data.payType)) + " ")]) : _vm._e()]);
  })], 2)])]), _c("div", {
    staticClass: "T-item"
  }, [_c("table", {
    staticClass: "Prtable"
  }, [_vm.data.transferItineraryList.length ? _c("tbody", {
    staticClass: "T-list"
  }, [_c("tr", [_c("th", {
    staticClass: "font-bold",
    staticStyle: {
      width: "60pt"
    },
    attrs: {
      rowspan: _vm.data.transferItineraryList.length + 1
    }
  }, [_vm._v(" 行程 ")]), _c("th", {
    staticClass: "font-bold",
    attrs: {
      colspan: "19"
    }
  }, [_vm._v(" 行程信息 ")])]), _vm._l(_vm.data.transferItineraryList, function (item, index) {
    return _c("tr", {
      key: index,
      staticStyle: {
        "font-size": "10pt"
      }
    }, [_c("td", {
      staticStyle: {
        display: "flex",
        "text-align": "left"
      },
      attrs: {
        colspan: "19"
      }
    }, [_c("div", {
      staticClass: "itinerary-title"
    }, [_vm._v(" " + _vm._s(item ? item.itineraryDate : "") + " ")]), _c("div", {
      staticClass: "flex-1"
    }, [_vm._v(" " + _vm._s(item ? item.itineraryDesc : "") + " ")])])]);
  })], 2) : _vm._e()])]), _c("table", {
    staticClass: "Prtable",
    staticStyle: {
      "border-left": "none"
    }
  }, [_vm._m(0), _c("tbody", {
    staticClass: "T-otherInfo"
  }, [_c("tr", {
    staticStyle: {
      "border-left": "1px solid black"
    }
  }, [_c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "20"
    }
  }, [_vm._v("   付司机费用：" + _vm._s(_vm.data.busFee) + "元 ")])]), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)])])])])]) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("colgroup", [_c("col", {
    staticStyle: {
      width: "10%"
    }
  }), _c("col", {
    staticStyle: {
      width: "15%"
    }
  }), _c("col", {
    staticStyle: {
      width: "10%"
    }
  }), _c("col", {
    staticStyle: {
      width: "10%"
    }
  }), _c("col", {
    staticStyle: {
      width: "10%"
    }
  }), _c("col", {
    staticStyle: {
      width: "15%"
    }
  }), _c("col", {
    staticStyle: {
      width: "10%"
    }
  }), _c("col", {
    staticStyle: {
      width: "10%"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticStyle: {
      "border-left": "1px solid black"
    }
  }, [_c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "10"
    }
  }, [_vm._v("  cg: ")]), _c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "10"
    }
  }, [_vm._v("  gwd: ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticStyle: {
      "border-left": "1px solid black"
    }
  }, [_c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "10"
    }
  }, [_vm._v("  tc: ")]), _c("td", {
    staticClass: "font-bold",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "10"
    }
  }, [_vm._v("  其他: ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticStyle: {
      "border-left": "1px solid black"
    }
  }, [_c("td", {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "20"
    }
  }, [_c("span", [_c("span", {
    staticClass: "font-bold"
  }, [_vm._v("备注：")]), _vm._v("此单一式三份，黄单留底，白单由司机交回车队，红单由导游交社 "), _c("br"), _vm._v("  （秦铭宏车队：黄单交司机，白单交车队邓小琼，红单由导游交社）。 ")]), _c("br"), _c("span", [_c("span", {
    staticClass: "font-bold"
  }, [_vm._v("说明：")]), _vm._v("1、司机凭此单结车费，请妥善保管，遗失不补，盖公司公章有效！"), _c("br"), _vm._v("   2、司机结算时需交10元回社 "), _c("br"), _vm._v("   3. 运营副总汪国丽 15107735006 经理：葛星辉 18807831717 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", {
    staticClass: "noBorder"
  }, [_c("td", {
    staticClass: "noBorder",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("   派团计调：管理员 ")]), _c("td", {
    staticClass: "noBorder",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      colspan: "5"
    }
  }, [_vm._v("   签字： ")]), _c("td", {
    staticClass: "noBorder",
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v(" 质检员签字： ")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };