export const DingTalkStatus = {
  '-1': '未通过/撤销',
  '0': '审批中',
  '1': '审批通过',
  '2': '审批中/通过',
  '3': '审批未通过'
};
const planCustomerNumber = {
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    'min-width': 120
  }
};
const productName = {
  attrs: {
    prop: 'productName',
    label: '产品线路'
  }
};
const tripDate = {
  attrs: {
    prop: 'date',
    label: '发团日期',
    'min-width': 120
  },
  slot: 'tripDate'
};
const count = {
  attrs: {
    prop: 'orderNumber',
    label: '人数'
  },
  slot: 'count'
};
const guide = {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
};
const guides = {
  attrs: {
    prop: '',
    label: '导游'
  },
  slot: 'guides'
};
const orderOpMain = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '责任计调'
  }
};
const auditStatus = {
  attrs: {
    prop: 'auditProgressLabel',
    label: '审核状态'
  }
};
const planStatus = {
  attrs: {
    prop: 'planStatusLabel',
    label: '计划状态'
  }
};
const approved = {
  attrs: {
    prop: 'borrowMoney',
    label: '已批'
  }
};
const remarks = {
  attrs: {
    prop: 'remarks',
    label: '备注'
  }
};
const applyUser = {
  attrs: {
    prop: 'applyUserName',
    label: '申请人'
  }
};
const applyDate = {
  attrs: {
    prop: 'applyDate',
    label: '申请日期'
  }
};
const applyMoney = {
  attrs: {
    prop: 'applyMoney',
    label: '申请金额'
  }
};
const actionItem = {
  attrs: {
    prop: '',
    label: '操作'
  },
  slot: 'action'
};
const auditItem = {
  attrs: {
    prop: 'audit',
    label: '审核',
    width: 120,
    fixed: 'right'
  },
  slot: 'audit',
  header: 'auditAction'
};
const actionHeadItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 80,
    fixed: 'right'
  },
  slot: 'action',
  header: 'deleteAction'
};
// 列表
export const GuideBorrow = [planCustomerNumber, productName, tripDate, count, guides, orderOpMain, auditStatus, planStatus, {
  attrs: {
    prop: 'dingTalkStatus',
    label: '钉钉审核状态'
  },
  slot: 'dingTalkStatus'
}, {
  attrs: {
    prop: 'totalBorrow',
    label: '总已借款'
  },
  slot: 'totalBorrow'
}, {
  ...applyUser,
  slot: 'applyUserName'
}, applyDate, guide, applyMoney, approved, actionItem];

// 批量报账
export const GuideBorrowBatch = [planCustomerNumber, orderOpMain, guide, {
  attrs: {
    prop: 'applyUserName',
    label: '指定批款人'
  }
}, {
  attrs: {
    prop: 'applyUserName',
    label: '指定批款时间'
  }
}, remarks, applyMoney, {
  attrs: {
    prop: 'totalBorrow',
    label: '已批'
  }
}, {
  attrs: {
    prop: '',
    label: '未批'
  },
  slot: 'unApprove'
}, {
  attrs: {
    prop: '',
    label: '当前批款金额'
  },
  slot: 'downPayed'
}, actionHeadItem];

// 导游借款
// 导游借款 审核
export const auditEditGuideBorrow = [{
  attrs: {
    prop: 'guideName',
    label: '导游',
    width: 100
  },
  slot: 'guide'
}, {
  attrs: {
    prop: '',
    label: '会计科目'
  },
  slot: 'subject'
}, {
  attrs: {
    prop: '',
    label: '借款金额',
    width: 60
  },
  slot: 'borrowMoney'
}, {
  attrs: {
    prop: '',
    label: '借款方式',
    width: 100
  },
  slot: 'borrowType'
}, {
  attrs: {
    prop: '',
    label: '销账模式',
    width: 100
  },
  slot: 'cashMode'
}, {
  attrs: {
    prop: '',
    label: '银行卡'
  },
  slot: 'bankId'
}, {
  attrs: {
    prop: '',
    label: '借款时间',
    width: 120
  },
  slot: 'cashDate'
}, {
  attrs: {
    prop: '',
    label: '销账人',
    width: 120
  },
  slot: 'cashUser'
}, {
  attrs: {
    prop: '',
    label: '备注'
  },
  slot: 'remarks'
}, actionHeadItem, auditItem];

// 借款申请
export const GuideBorrowApply = [{
  ...guide,
  slot: 'guide'
}, {
  ...applyMoney,
  slot: 'applyMoney'
}, {
  ...applyUser,
  slot: 'applyUser'
}, {
  ...applyDate,
  slot: 'applyDate'
}, {
  ...remarks,
  slot: 'remarks'
}, approved, {
  ...auditStatus,
  slot: 'auditStatus'
}, {
  attrs: {
    prop: '',
    label: '操作'
  },
  slot: 'action',
  header: 'actionHead'
}];

// 申请信息
export const GuideBorrowApplyInfo = [applyUser, applyDate, applyMoney, guide, approved, remarks];

// 下账记录
export const GuideBorrowRecord = [{
  attrs: {
    prop: 'logNumber',
    label: '日记号',
    'min-width': 200
  }
}, planCustomerNumber, {
  attrs: {
    prop: '',
    label: '流水号'
  }
}, orderOpMain, tripDate,
// applyUser,
// applyDate,
// applyMoney,
remarks, {
  attrs: {
    prop: 'cashWay',
    label: '方式'
  }
}, {
  attrs: {
    prop: 'bankName',
    label: '银行卡',
    'min-width': 200
  }
}, {
  attrs: {
    prop: 'cashDate',
    label: '下账日期'
  }
}, {
  attrs: {
    prop: 'cashUserName',
    label: '下账人'
  }
}, {
  attrs: {
    prop: 'borrowMoney',
    label: '下账金额'
  }
}, {
  attrs: {
    prop: 'cashierAuditStatusLabel',
    label: '出纳确认状态'
  }
}
// actionItem,
];