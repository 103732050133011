import { APP } from '@/config';
export default {
  data() {
    return {
      title: '桂林',
      visible: false,
      tableData: [],
      activeName: '',
      activeTabsName: 'first',
      form: {
        year: 1
      },
      formInvoice: {
        invoiceRise: '',
        dutyParagraph: ''
      },
      data: {},
      paymentNumber: 1,
      payType: '1'
    };
  },
  computed: {},
  mounted() {},
  methods: {
    inputYear(money, val) {
      this.data.money = val * money;
    },
    changeCollapse(item) {
      this.activeName = item;
    },
    handleClick(item) {
      this.activeTabsName = item.name;
    },
    show(row) {
      this.data = row;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      if (this.payType == 2) {
        const {
          money,
          year,
          code,
          name
        } = this.data;
        const opts = {
          name,
          code,
          year,
          money: money * 0.5,
          payType: 1,
          returnUrl: APP.redirecAlipaytUrl,
          rechargeType: 'YEAR',
          count: year
        };
        this.$store.dispatch('system/payment/updateAlipay', opts).then(res => {
          document.querySelector('body').innerHTML = res;
          document.forms[0].submit();
          this.visible = false;
          this.$parent.openPayment(res);
        });
      }
    }
  }
};