import { parseDate } from '@/utils';
const defaultState = () => ({
  startDate: parseDate(),
  endDate: parseDate(),
  tmpCreditMoney: ''
});
const rules = {
  startDate: [{
    required: true,
    message: '额度开始日期不能为空！',
    trigger: 'blur'
  }],
  endDate: [{
    required: true,
    message: '额度结束日期不能为空！',
    trigger: 'blur'
  }],
  tmpCreditMoney: [{
    required: true,
    message: '临时额度不能为空！',
    trigger: 'blur'
  }]
};
export default {
  data() {
    return {
      rules,
      visible: false,
      form: defaultState(),
      title: '',
      limitDate: []
    };
  },
  computed: {
    getTitle() {
      return this.title;
    }
  },
  methods: {
    show(row) {
      const {
        rowId: id,
        tmpCreditMoney,
        name: title
      } = row;
      this.title = title;
      this.form = {
        ...this.form,
        id,
        tmpCreditMoney
      };
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleChange(val) {
      const [start, end] = val;
      this.form.startDate = start;
      this.form.endDate = end;
    },
    handleCreate() {
      this.$refs.formRef.validate(valid => {
        if (!valid) return;
        this.$store.dispatch('resource/custom/setTmpCredit', this.form).then(() => {
          this.hide();
          this.$bus.tip();
          this.$emit('reload');
        });
      });
    }
  }
};