import "core-js/modules/es.array.push.js";
import { TopBar, DelCheckAll, Audit, AuditCheck, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectShop, SelectGuide } from '@/components/common';
import { selectorMixin, auditMixin, auditPropMixin, rowspanMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
const itemListKeys = () => ({
  redEnvelope: 0,
  accompanyRate: 0,
  accompanyRebate: 0,
  driverRate: 0,
  driverRebate: 0,
  driverRebateDeduction: 0,
  guideCollect: 0,
  guideManagerRate: 0,
  guideManagerRebate: 0,
  guideManageRebateDeduction: 0,
  guideRate: 0,
  guideRebate: 0,
  guideRebateDeduction: 0,
  id: 0,
  money: 0,
  name: '',
  planShopId: 0,
  shopRemark: '',
  shopRate: 0,
  shopRebate: 0,
  travelGrossProfit: 0,
  customerTripNumber: '',
  customerOrderId: ''
});
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  rowCount: 1,
  rowList: [],
  rate: 0,
  deduction: 0,
  rebate: 0,
  enterStoreMemberCount: 0,
  // 进店人数
  headCompanyId: 0,
  headPrice: 0,
  shopPrice: 0,
  orderDate: '',
  orderNumber: '',
  parkingFee: 0,
  remark: '',
  subsidy: '0',
  subsidyRate: '0',
  shopAgencyProfit: 0,
  shopName: '',
  shopId: '',
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});
const RateKeyMap = ['shopRate', 'guideRate', 'driverRate', 'accompanyRate', 'guideManagerRate'];
const RabeteKeyMap = ['money', 'shopRebate', 'guideRebate', 'driverRebate', 'accompanyRebate', 'guideManagerRebate'];
export default {
  components: {
    TopBar,
    DelCheckAll,
    Audit,
    AuditCheck,
    AuditAdmin,
    AuditRoleAll,
    StatusAudit,
    SelectShop,
    SelectGuide,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, auditMixin, auditPropMixin, rowspanMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      deletePath: 'plan/shopping/delete',
      updatePath: 'plan/shopping/update',
      fetchPath: 'plan/shopping/fetch',
      headMergeNum: 5,
      tailMergeNum: 21,
      // 这里获取不到确切长度，手动设定
      hasMergeTail: true,
      rowId: 1000000000000,
      totalMoney: 0,
      // 总金额
      statisticsKeys: ['money', 'enterStoreMemberCount', 'travelGrossProfit', 'totalHeadRebate', 'shopRebate', 'guideRebateDeduction', 'driverRebateDeduction', 'driverRebate', 'accompanyRebate', 'guideRebate'],
      customerTripNumberList: [],
      auditAll: false,
      isDecimal: false,
      // 是否保留两位小数
      visible: false,
      planData: {}
    };
  },
  computed: {
    isTotal() {
      return item => {
        return item.isTotal;
      };
    },
    getPlanInfo() {
      return this.planData[this.planId];
    },
    getPlanNumber() {
      return this.getPlanInfo ? this.getPlanInfo.planNumber : '';
    },
    getData() {
      return this.tableData;
    }
  },
  // created() {
  //   this.fetchData({ planId: this.planId })
  //   this.fetchCustomerList()
  // },

  methods: {
    initData(data) {
      const {
        planId
      } = data;
      this.planId = planId;
      this.planData = data;
      this.fetchData({
        planId: this.planId
      });
      this.fetchCustomerList();
    },
    // 获取数据
    fetchData(params = {}) {
      params = {
        ...params,
        planId: this.planId
      };
      this.tableLoading = true;
      const commitStatus = (status = false) => {
        this.$store.commit('plan/index/FETCH_STATUS', status);
      };
      commitStatus(true);

      // 清空图片
      this.$store.commit('plan/index/CUR_SIGN_LIST', []);
      this.$store.dispatch(this.fetchPath, params).then(data => {
        this.gainData(data);
        this.tableLoading = false;
        const dataList = data.list ? data.list : data;
        this.getCurrentSignList(dataList);
        commitStatus();
      }).catch(() => {
        this.tableLoading = false;
        commitStatus();
      });
    },
    gainData(data) {
      this.visible = true;
      if (!data) return;
      const list = this.formatTabelData(data);
      this.tableData = this.initialDecimalPermission(this.formatData(list));

      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      this.$nextTick(() => {
        this.tailMergeNum = 22;
        // 如果不显示订单信息
        if (!isOrderInfo) {
          this.tailMergeNum = 21;
        }
        this.resetData();
      });
    },
    // 抽取计算多个商品小计
    formatTabelData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        let totalChildren = {};
        it.isTotal = false;
        if (it.shopGoods.length > 1) {
          const money = this.getSum(it.shopGoods, 'money');
          const shopRebate = this.getSum(it.shopGoods, 'shopRebate');
          const redEnvelope = this.getSum(it.shopGoods, 'redEnvelope');
          const guideRebate = this.getSum(it.shopGoods, 'guideRebate');
          const driverRebate = this.getSum(it.shopGoods, 'driverRebate');
          const accompanyRebate = this.getSum(it.shopGoods, 'accompanyRebate');
          const guideManagerRebate = this.getSum(it.shopGoods, 'guideManagerRebate');
          const guideCollect = this.getSum(it.shopGoods, 'guideCollect');
          const travelGrossProfit = this.getSum(it.shopGoods, 'travelGrossProfit');
          let total = {
            totalPid: it.id,
            isTotal: true,
            totalText: '小计',
            moneyTotal: money,
            shopRebateTotal: shopRebate,
            redEnvelopeTotal: redEnvelope,
            guideRebateTotal: guideRebate,
            driverRebateTotal: driverRebate,
            accompanyRebateTotal: accompanyRebate,
            guideManagerRebateTotal: guideManagerRebate,
            guideCollectTotal: guideCollect,
            travelGrossProfitTotal: travelGrossProfit,
            shopRebate: 0,
            guideRebate: 0,
            driverRebate: 0,
            accompanyRebate: 0,
            guideCollect: 0,
            travelGrossProfit: 0,
            contractPrices: [],
            shopGoods: []
          };
          totalChildren = total;
          result.push(it, totalChildren);
        } else {
          result.push(it);
        }
      });
      return result;
    },
    // 计算合计
    getSum(list, key) {
      var s = 0;
      list.forEach(it => {
        s += Number(it[key]);
      }, 0);
      return s;
    },
    fetchCustomerList() {
      const planId = this.planId;
      this.$store.dispatch('plan/index/findCustomerList', planId).then(list => {
        this.customerTripNumberList = this.formatTripNumber(list);
      });
    },
    // 当前添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/shopping/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...itemListKeys(),
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isSelect: false,
          name: row.name,
          enterStoreMemberCount: this.planData.adultCount || 0
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.resetData();
    },
    // 新增一条
    handlePlus() {
      const {
        guides,
        adultCount,
        startPlanDate
      } = this.planData;
      const item = {
        ...defaultState(),
        guideName: guides && guides.length ? guides[0].name : '',
        guideId: guides && guides.length ? guides[0].name.id : '',
        rowId: ++this.rowId,
        planId: this.planId,
        orderDate: startPlanDate,
        enterStoreMemberCount: adultCount ? adultCount : 0
      };
      this.tableData.push(item);
      this.visible = true;
      this.resetData();
    },
    // 选择购物店
    selectShop(row, val) {
      const {
        shopName: shopNameOrigin,
        shopId: shopIdOrigin,
        planId
      } = row;
      if (!val.label || !val.value) {
        row.shopId = '';
        row.shopPrice = 0;
        row.headPrice = 0;
        this.$set(row, 'goodsList', []);
        return;
      }
      const {
        id: shopId,
        label,
        subsidyRate
      } = val;
      row.subsidyRate = subsidyRate;
      if (subsidyRate) {
        this.calcSubsidy(row); //计算后返金额
      }
      this.$store.dispatch('plan/shopping/fetchGoods', {
        shopId,
        planId
      }).then(data => {
        if (!data) {
          this.$bus.tip({
            type: 'warning',
            message: '查询不到购物店协议'
          });
          row.shopId = shopIdOrigin || shopId;
          row.shopName = shopNameOrigin || label;
          this.$set(row, 'goodsList', []);
          return;
        }
        const {
          contractPrices,
          shopPrice
        } = data;
        row.shopId = shopId;
        row.shopName = label;
        row.shopPrice = shopPrice;
        row.headPrice = shopPrice;
        this.$set(row, 'goodsList', this.formatOptions(contractPrices));
        // 选择后直接计算人头返佣
        this.changeEnterCount(row, row.enterStoreMemberCount);
        // this.calcRowTotalMoney(row)
      });
    },
    // 小团号
    handleSelectTripNumber(row, val) {
      row.customerTripNumber = val.label;
      row.customerOrderId = val.value;
    },
    // 选择导游
    selectGuide(row, val) {
      const {
        label,
        value
      } = val;
      row.guideName = label;
      row.guideId = value;
    },
    // 选择商品
    handleSelect(row, val) {
      RateKeyMap.forEach(key => {
        row[key] = this.isDecimalFormat(val[key]);
      });
      const {
        subsidy
      } = val;
      row.name = val.label;
      row.shopAgencyProfit = subsidy;
      this.calcRowTotalMoney(row);
    },
    // 进店人数, 需要计算导/司/陪佣及社佣、社利
    changeEnterCount(row, val) {
      const {
        headPrice
      } = row;
      // 人头返佣金额
      row.totalHeadRebate = headPrice * val;
      this.calcTravelProfit(row);
    },
    // 打单金额， 需要计算导/司/陪佣及社佣、社利
    changePrintMoney(row) {
      this.calcSubsidy(row); //计算后返金额
      this.calcRowTotalMoney(row);
    },
    // 社佣
    changeShopRate(row) {
      this.calcShopMoney(row);
      this.calcTravelProfit(row);
    },
    // 导游金额修改
    changeGuideRate(row) {
      this.calcGuideMoney(row);
      this.calcTravelProfit(row);
    },
    // 司机金额修改
    changeDriverRate(row) {
      this.calcDriverMoney(row);
      this.calcTravelProfit(row);
    },
    // 全陪金额修改
    changeAccompanyRate(row) {
      this.calcAccompanyMoney(row);
      this.calcTravelProfit(row);
    },
    // 导管金额修改
    changeGuideManagerRate(row) {
      this.calcGuideManagerMoney(row);
      this.calcTravelProfit(row);
    },
    // 社佣金总额
    calcShopMoney(row) {
      const {
        money,
        shopRate
      } = row;
      row.shopRebate = this.isDecimalFormat(money * shopRate / 100);
    },
    // 导游佣金总额
    calcGuideMoney(row) {
      const {
        money,
        guideRate
      } = row;
      row.guideRebate = this.isDecimalFormat(money * guideRate / 100 - row.guideRebateDeduction);
    },
    // 司机佣金总额
    calcDriverMoney(row) {
      const {
        money,
        driverRate
      } = row;
      row.driverRebate = this.isDecimalFormat(money * driverRate / 100 - row.driverRebateDeduction);
    },
    // 全陪佣金总额
    calcAccompanyMoney(row) {
      const {
        money,
        accompanyRate
      } = row;
      // 取整数 四舍五入
      row.accompanyRebate = this.isDecimalFormat(money * accompanyRate / 100);
    },
    // 导管佣金总额
    calcGuideManagerMoney(row) {
      const {
        money,
        guideManagerRate
      } = row;
      // 取整数 四舍五入
      row.guideManagerRebate = this.isDecimalFormat(money * guideManagerRate / 100);
    },
    // 社利总额
    calcTravelProfit(row) {
      // 社利总额 = 社佣金额（打单金额 * 社佣比例）- 导佣总额（打单金额 * 导佣比例）- 司佣总额（打单金额 * 司佣比例） - 陪佣总额（打单金额 * 陪佣比例） + 人头佣金(单价 * 人数）
      const {
        shopRebate,
        redEnvelope,
        totalHeadRebate,
        accompanyRebate,
        driverRebate,
        guideRebate,
        guideManagerRebate
      } = row;
      const unit = 100;
      const res = shopRebate * unit + redEnvelope * unit + totalHeadRebate * unit - accompanyRebate * unit - guideRebate * unit - guideManagerRebate * unit - driverRebate * unit;
      row.travelGrossProfit = this.isDecimalFormat(res / unit);
      // 计算小计行金额总计
      this.calcSubtotal(row);
    },
    // 计算小计行金额总计
    calcSubtotal(row) {
      const {
        rowId
      } = row;
      const list = this.tableData.filter(it => it.totalPid == rowId);
      if (!list.length) return false;
      const calclist = this.tableData.filter(it => it.rowId == rowId);
      list[0].moneyTotal = this.getSum(calclist, 'money');
      list[0].shopRebateTotal = this.getSum(calclist, 'shopRebate');
      list[0].guideRebateTotal = this.getSum(calclist, 'guideRebate');
      list[0].driverRebateTotal = this.getSum(calclist, 'driverRebate');
      list[0].accompanyRebateTotal = this.getSum(calclist, 'accompanyRebate');
      list[0].guideManagerRebateTotal = this.getSum(calclist, 'guideManagerRate');
      list[0].guideCollectTotal = this.getSum(calclist, 'guideCollect');
      list[0].travelGrossProfitTotal = this.getSum(calclist, 'travelGrossProfit');
    },
    // 计算返款金额
    calcSubsidy(row) {
      const {
        money,
        subsidyRate
      } = row;
      row.subsidy = this.isDecimalFormat((money * subsidyRate || 0) / 100);
    },
    // 计算单行总额
    calcRowTotalMoney(row) {
      this.calcShopMoney(row);
      this.calcGuideMoney(row);
      this.calcGuideManagerMoney(row);
      this.calcDriverMoney(row);
      this.calcAccompanyMoney(row);
      this.calcTravelProfit(row);
    },
    resetData() {
      this.rowspan();
    },
    // 格式化小团号
    formatTripNumber(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const item = {
          value: it.id,
          label: it.customerTripNumber || it.orderNumber,
          name: it.customerTripNumber || it.orderNumber,
          ...it
        };
        result.push(item);
      });
      return result;
    },
    // 处理系统配置是否保留二位小数
    initialDecimalPermission(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        RabeteKeyMap.forEach(key => {
          it[key] = this.isDecimalFormat(it[key]);
        });
        return it;
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.shopGoods;
        const {
          id,
          auditProgress
        } = it;
        let goodsList = it.contractPrices || [];
        goodsList = this.formatOptions(goodsList);
        let item = {
          ...defaultState(),
          ...it,
          rowId: id,
          shoppingPlanId: id,
          isChecked: false,
          isCustom: false,
          ap: auditProgress,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          goodsList,
          hasGoods: !!itemList.length
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const shopRemark = v.remark;
            delete v.remark;
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              shopRemark
            });
          });
        } else {
          item.itemIndex = 0;
          result.push(item);
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    createOrUpdate() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        }
        const dataList = this.beforeCommit(this.tableData);
        const loading = this.$bus.loading();
        return this.$store.dispatch(this.updatePath, dataList).then(() => {
          this.$bus.tip();
          loading.close();
          resolve();
        }).catch(() => {
          loading.close();
          reject();
        });
      });
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      // 去除总计行
      list = list.filter(it => !it.isTotal);
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'goodsList'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        if (!it.isCustom) {
          item.id = it.id;
          item.planShopId = it.shoppingPlanId;
        }

        // 定义子项目的列表
        it.shopGoods = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          if (key == 'shopRemark') {
            item.remark = it.shopRemark;
          }
          delete it[key];
        });
        if (!it.hasGoods) {
          delete item.id;
        }
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.getConfigStatus('audit:audit4:audit4-3') && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.shoppingPlanId;

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.shoppingPlanId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        it.totalGuideCollect = Number(item.guideCollect || 0);
        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.totalGuideCollect += Number(item.guideCollect || 0);
          last.shopGoods.push(item);
        } else {
          it.shopGoods.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    validateForm() {
      if (!this.tableData.length) return false;
      // 去除总计行
      const tableData = this.tableData.filter(it => !it.isTotal);
      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        const {
          shopId,
          orderDate,
          goodsList
        } = row;
        if (!shopId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择购物店!'
          });
          return false;
        }
        if (!goodsList || !goodsList.length) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择商品，无商品选择请到购物店管理增加商品协议!'
          });
          return false;
        }
        if (!orderDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择进店日期！'
          });
          return false;
        }
      }
      return true;
    },
    formatOptions(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.label = it.contentType;
        it.value = it.id;
        return it;
      });
    },
    tableRowClassName({
      row
    }) {
      if (row.isTotal) {
        return 'shop-row-bg';
      }
      return '';
    }
  }
};