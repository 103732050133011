import "core-js/modules/es.array.push.js";
/**
 * @module components/common/Input/InputDepartment
 * @desc 部门与子部门 input 组件
 */
import { inputMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'InputDepartment',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '部门'
    },
    hasAll: {
      type: Boolean,
      default: false
    },
    initVal: {
      type: Object,
      default: () => {}
    },
    hideLast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curVal: [],
      departments: []
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      departmentData: 'system/department/departmentData'
    }),
    getCurVal: {
      get() {
        return this.curVal;
      },
      set() {
        this.$emit('update:value');
      }
    }
  },
  watch: {
    departmentData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      // const { list } = data
      this.departments = this.formatList(data);
      this.$nextTick(() => {
        this.initialVal(this.initVal || this.$attrs.value);
      });
    },
    initialVal(item) {
      if (!item) return;
      const department = [];
      const {
        departmentId,
        subDepartmentId,
        grandsonDepartmentId
      } = this.processParams(item);
      if (departmentId && departmentId > 0) {
        department.push(departmentId);
      }
      if (subDepartmentId && subDepartmentId > 0) {
        department.push(subDepartmentId);
      }
      if (grandsonDepartmentId && grandsonDepartmentId > 0) {
        department.push(grandsonDepartmentId);
      }
      this.curVal = department;
    },
    processParams(data) {
      if (Array.isArray(data)) {
        const [pid, spid, gpid] = data;
        return {
          departmentId: pid,
          subDepartmentId: spid,
          grandsonDepartmentId: gpid
        };
      }
      return data;
    },
    formatList(list) {
      const res = [];
      if (this.hasAll) {
        res.push({
          value: '-1',
          label: '全部'
        });
      }
      const fn = list => {
        list.forEach((it, index1) => {
          const children = [];
          const pItem = {
            value: it.id,
            label: it.name,
            children: []
          };
          pItem.children = !it.departments.length ? undefined : [];
          res.push(pItem);
          if (it.departments && it.departments.length) {
            it.departments.forEach((item, index2) => {
              const cItem = {
                value: item.id,
                label: item.name,
                children
              };
              cItem.children = !item.departments.length ? undefined : [];
              res[index1].children.push(cItem);
              if (item.departments && item.departments.length) {
                item.departments.forEach(child => {
                  res[index1].children[index2].children.push({
                    disabled: this.hideLast,
                    value: child.id,
                    label: child.name
                  });
                });
              }
            });
          }
        });
      };
      fn(list);
      return res;
    },
    handleChange(val) {
      const [d1, d2, d3] = val;
      const res = {
        departmentName: '',
        subDepartmentName: '',
        grandsonDepartmentName: ''
      };
      this.departmentData.forEach(it => {
        if (it.id == d1) {
          res['departmentName'] = it.name;
          res['departmentId'] = d1;
          if (d2) {
            it.departments.forEach(c => {
              if (c.id == d2) {
                res['subDepartmentName'] = c.name;
                res['subDepartmentId'] = d2;
                if (d3) {
                  c.departments.forEach(g => {
                    if (g.id == d3) {
                      res['grandsonDepartmentName'] = g.name;
                      res['grandsonDepartmentId'] = d3;
                    }
                  });
                }
              }
            });
          }
        }
      });
      this.$emit('on-select', res);
    },
    handleClear() {
      const res = {
        departmentId: '',
        subDepartmentId: '',
        grandsonDepartmentId: '',
        departmentName: '',
        subDepartmentName: '',
        grandsonDepartmentName: ''
      };
      this.$emit('on-select', res);
    }
  }
};