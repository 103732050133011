var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "发票列表",
      width: "1200",
      visible: _vm.visible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.data,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "subject",
      label: "标题",
      width: "400"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "from",
      label: "发件人",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "sentDate",
      label: "发送时间",
      width: "170"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "内容",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.content ? _c("el-link", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.showContent(scope.row.content);
            }
          }
        }, [_vm._v(" 查看内容 ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "address",
      label: "附件(点击发票预览)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.attachments ? _vm._l(scope.row.attachments, function (file, index) {
          return _c("el-button", {
            key: index,
            attrs: {
              type: "text"
            },
            on: {
              click: function ($event) {
                return _vm.showAttachment(file, scope.row.msgNum);
              }
            }
          }, [_vm._v(" " + _vm._s(file) + " ")]);
        }) : _c("span", {
          staticClass: "red"
        }, [_vm._v("无附件，发票信息请点击查看内容")])];
      }
    }])
  })], 1), _c("DialogAttachement", {
    ref: "dialogRef"
  }), _c("DialogContent", {
    ref: "dialogContentRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };