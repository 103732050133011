// 商城订单
import { mallOrderRequest, mallOrderDetailRequest } from '@/api/order/mall';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  orderData: null
});
const state = dfaultState();
const getters = {
  orderData: state => state.orderData
};
const actions = {
  // 商城订单
  fetch({
    commit
  }, opts) {
    opts = {
      ...DEFAULT_PAGE,
      ...opts
    };
    return mallOrderRequest(opts).then(res => {
      const {
        data
      } = res;
      commit('ORDER_DATA', data);
      return data;
    });
  },
  // 商城订单详情
  // eslint-disable-next-line no-empty-pattern
  fetchDetail({}, orderId) {
    return mallOrderDetailRequest(orderId).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  ORDER_DATA: (state, data) => {
    state.orderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};