var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.text ? _c("div", _vm._l(_vm.text.split("\n"), function (v, tidx) {
    return _c("div", {
      key: tidx
    }, [_vm._v(" " + _vm._s(v) + " ")]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };