import { orderInfo, tripDate, orderArrangeRemark, totalSettlement, orderOther, auditStatus, guide, payed, unPay, createTime, actionItem } from './index';
const type = {
  attrs: {
    prop: 'ticketType',
    label: '类别',
    'min-width': 80
  }
};
const lastItem = [orderArrangeRemark, orderOther, payed, totalSettlement, unPay, createTime, auditStatus, actionItem];

// 按团号去付款 景区
export const ScenicColumn = [orderInfo, {
  attrs: {
    prop: 'scenicName',
    label: '景区',
    'min-width': 100
  }
}, tripDate, type, guide, {
  attrs: {
    prop: 'settlementPrice',
    label: '结算价',
    'min-width': 50
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '成人数',
    'min-width': 40
  }
}, {
  attrs: {
    prop: 'adultFreeCount',
    label: '成人免票',
    'min-width': 40
  }
}, {
  attrs: {
    prop: 'childPrice',
    label: '儿童价',
    'min-width': 40
  }
}, {
  attrs: {
    prop: 'childCount',
    label: '儿童数',
    'min-width': 40
  }
}, {
  attrs: {
    prop: 'accompanyPrice',
    label: '全陪价',
    'min-width': 40
  }
}, {
  attrs: {
    prop: 'accompanyCount',
    label: '全陪数',
    'min-width': 40
  }
}, ...lastItem];