import TableList from './TableList';
import { FooterBar, SelectShop, SelectGuide } from '@/components/common';
import { tagViewMixin } from '@/mixin';
const defaultState = () => ({
  shopId: '',
  guideId: '',
  orderDate: '',
  headPrice: 0,
  enterStoreMemberCount: 0,
  shopAgencyProfit: 0,
  total: 0
});
export default {
  components: {
    FooterBar,
    SelectShop,
    SelectGuide,
    TableList
  },
  mixins: [tagViewMixin],
  data: () => ({
    total: 0,
    form: {},
    shop: '',
    guide: '',
    planId: ''
  }),
  mounted() {
    const {
      planId,
      shopId
    } = this.$route.query;
    this.planId = planId;
    this.shop = shopId;
    // if (!id) return

    // this.fetchData(id).then(data => {
    //   if (!data) {
    //     this.form = defaultState()
    //   } else {
    //     const { guideName, shopName } = data
    //     this.guide = guideName
    //     this.shop = shopName
    //     this.form = data
    //     this.form.total = 0
    //     this.calcTotal()
    //   }
    // })
    this.form = {
      ...defaultState(),
      shopId
    };
  },
  methods: {
    fetchData(id) {
      return this.$store.dispatch('shopping/index/detail', id);
    },
    selectShop(val) {
      const {
        id,
        label
      } = val;
      this.form.shopId = id;
      this.form.shopName = label;
      // 商品列表
      this.$refs.tableRef.fetchGoods({
        shopId: id,
        planId: this.planId
      }).then(data => {
        if (!data) return;
        this.form.headPrice = data.shopPrice;
      });
    },
    selectGuide(val) {
      const {
        id,
        label
      } = val;
      this.form.guideId = id;
      this.form.guideName = label;
    },
    calcTotal() {
      const {
        enterStoreMemberCount,
        headPrice
      } = this.form;
      this.form.total = enterStoreMemberCount * headPrice;
    },
    handleCreate() {
      this.$refs.tableRef.handleAdd();
    },
    handleSave() {
      this.$refs.tableRef.createOrUpdate();
    },
    handleClose() {
      this.closeSelectedTag();
    }
  }
};