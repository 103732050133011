var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.type) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("PopoverTip", {
          attrs: {
            content: scope.row.remarks
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.remarks.length > 20 ? scope.row.remarks.slice(0, 10) + "..." : scope.row.remarks))])])];
      }
    }, {
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("SwitchStatus", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeStatus(scope.row);
            }
          },
          model: {
            value: scope.row.isEnable,
            callback: function ($$v) {
              _vm.$set(scope.row, "isEnable", $$v);
            },
            expression: "scope.row.isEnable"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("BtnLinkBase", {
          attrs: {
            "btn-type": "detail"
          },
          on: {
            click: function ($event) {
              return _vm.handleShow(scope);
            }
          }
        }, [_vm._v(" 查看 ")]), _c("BtnLinkBase", {
          attrs: {
            "btn-type": "edit"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("BtnLinkBase", {
          attrs: {
            "btn-type": "log"
          },
          on: {
            click: function ($event) {
              return _vm.handleLog(scope);
            }
          }
        }, [_vm._v(" 日志 ")]), _c("BtnLinkBase", {
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };