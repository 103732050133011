var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "product"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v(" 添加产品 ")]), _c("el-alert", {
    attrs: {
      title: "警告",
      type: "warning",
      description: "请先确定行程天数，再修改行程内容，当修改行程天数会重新生成行程天数模版，输入当前数据将会被清空！",
      "show-icon": ""
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "产品名称",
      clearable: ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("InputNumber", {
    attrs: {
      min: 1,
      max: _vm.maxTripDays,
      required: true,
      "controls-position": "right",
      label: "行程天数"
    },
    on: {
      change: _vm.changeDays
    },
    model: {
      value: _vm.form.days,
      callback: function ($$v) {
        _vm.$set(_vm.form, "days", _vm._n($$v));
      },
      expression: "form.days"
    }
  }), _c("SelectBase", {
    attrs: {
      "init-val": _vm.form.displayType,
      label: "设置可见",
      type: "visibleProduct"
    },
    model: {
      value: _vm.form.displayType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "displayType", $$v);
      },
      expression: "form.displayType"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("SelectDictionary", {
    attrs: {
      code: "productType",
      label: "类型",
      "is-require": true
    },
    on: {
      "on-select": _vm.onSelectProductType
    },
    model: {
      value: _vm.form.productType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productType", $$v);
      },
      expression: "form.productType"
    }
  }), _c("SelectLine", {
    attrs: {
      label: "所属系列"
    },
    on: {
      "on-select": _vm.selectLine
    },
    model: {
      value: _vm.form.linenName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linenName", $$v);
      },
      expression: "form.linenName"
    }
  }), _c("div", {
    staticClass: "input-box"
  }, [_c("SwitchStatus", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1)], 1)])], 1), _c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "行程安排",
      name: "days"
    }
  }, [_vm.daysStatus ? [_c("DayItem", {
    attrs: {
      days: _vm.form.days,
      "default-data": _vm.form.itineraries,
      "product-id": _vm.form.id
    },
    on: {
      "on-change": _vm.handleDayList
    }
  })] : _vm._e()], 2), _c("el-tab-pane", {
    attrs: {
      label: "备注",
      name: "remarks"
    }
  }, [_c("InputRemarks", {
    attrs: {
      "init-val": _vm.form.remarks
    },
    on: {
      "on-change": _vm.changeRemarks
    }
  })], 1)], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "更新线路产品" : "保存线路产品") + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };