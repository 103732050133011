import "core-js/modules/es.array.reduce.js";
import { travelAgencyIncome } from '@/config/report';
import { downloadExport } from '@/utils/exportExcel';
import { SelectCustom, SelectStaff, BtnExport } from '@/components/common';
import { SELECTOR } from '@/config/select';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { parseDate, parseMonth } from '@/utils';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    BtnExport
  },
  mixins: [searchMixin],
  data: () => ({
    reportPlanType: SELECTOR.reportPlanType,
    columnData: [],
    monthTitel: '',
    yearMonth: '',
    tableShow: false,
    tableData: [],
    searchParams: {
      dutyOPUserId: '',
      planNumber: '',
      // 大团号
      startTripDateS: '',
      // 发团开始日期
      startTripDateE: '',
      // 发团结束日期
      customerId: '',
      // 客户搜索
      orderType: '',
      yearMonth: ''
    },
    orderTypeName: ''
  }),
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      tripIncomeData: 'report/index/tripIncomeData'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          this.searchParams = data;
        }
      },
      immediate: true
    },
    tripIncomeData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.getNewMonthDate();
      } else {
        this.handleData(data);
      }
    },
    selectOrderType(val) {
      if (!val) {
        this.searchParams.orderType = '';
        this.orderTypeName = '';
      }
      if (val.value) {
        this.searchParams.orderType = val.value.toLowerCase();
        this.orderTypeName = val.label;
      }
    },
    getNewMonthDate() {
      var date = new Date();
      // 获取当前月份
      var yearMonth = parseMonth(date);
      this.searchParams.yearMonth = yearMonth;

      // 获取当前日期
      var yearMonthDate = parseDate(date);
      // 拼接本月1号
      this.searchParams.startTripDateS = yearMonth + '-1';
      this.searchParams.startTripDateE = yearMonthDate;
      this.fetchData(this.searchParams);
    },
    // 选择散、发团日期
    changeMonth(date) {
      var year = dayjs(date).format('YYYY');
      var month = dayjs(date).format('MM');
      var endOfMonth = new Date(year, month, 0).getDate(); // 获取本月最后一天
      // 拼接日期
      this.searchParams.startTripDateS = `${year}-${month}-1`;
      this.searchParams.startTripDateE = `${year}-${month}-${endOfMonth}`;
      // 判断是否是当前月份  如果是当前月份，则结束日期为当天
      let newMonth = dayjs(new Date()).format('MM');
      if (month == newMonth) {
        this.searchParams.startTripDateE = dayjs(new Date()).format('YYYY-MM-DD');
      }
      if (!date) {
        this.searchParams.startTripDateS = '';
        this.searchParams.startTripDateE = '';
      }
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    handleData(data) {
      this.tableData = data;
      this.tableShow = true;
      this.monthTitel = dayjs(this.searchParams.startTripDateS).format('MM') + '月收款';
      travelAgencyIncome[1].attrs.label = this.monthTitel;
      this.columnData = travelAgencyIncome;
    },
    fetchData(queryParam) {
      if (!queryParam.startTripDateE || !queryParam.startTripDateS) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择年月！'
        });
        return false;
      }
      this.$store.dispatch('report/index/getTripIncomeDetailed', queryParam);
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      const {
        travelAgency
      } = this.userData;
      this.$store.dispatch('report/index/fetchTripIncomeDetailed', this.searchParams).then(res => {
        const fileName = travelAgency.name + ' ' + this.orderTypeName + ' ' + this.searchParams.startTripDateS + '--' + this.searchParams.startTripDateE + '代收款明细表表(社收).xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    // 指定列求和
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 大于第三列就求和
        if (column.property == 'total') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    }
  }
};