import Hotel from '@/components/common/Order/Hotel/Hotel';
import DialogOrder from '@/components/common/Order/Hotel/DialogOrder';
import { planEditHotel, planEditHotelAll } from '@/config/plan';
import { permissionMixin } from '@/mixin';
export default {
  components: {
    Hotel,
    DialogOrder
  },
  mixins: [permissionMixin],
  data() {
    return {
      tableLoading: false,
      tableData: [],
      columnData: [],
      visible: false,
      planId: ''
    };
  },
  mounted() {
    setTimeout(() => {
      this.columnData = this.hasPermission('hotelFloorPrice') ? planEditHotelAll : planEditHotel;
    }, 100);
  },
  methods: {
    fetchData(id) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/fetchOrderRoomById', {
        id
      }).then(data => {
        this.tableData = [data];
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    show({
      id,
      planId
    }) {
      this.visible = true;
      this.planId = planId;
      this.fetchData(id);
    },
    hide() {
      this.visible = false;
    },
    showMembers(row) {
      this.$refs.orderRef.show(row);
    },
    // // 校验表单数据
    // validateForm() {
    //   return this.$refs.itemRef.validateForm()
    // },

    // // 校验表单数据
    // createOrUpdate() {
    //   return this.$refs.itemRef.createOrUpdate()
    // },

    handleConfirm() {
      this.$refs.hotelRef.handleUpdate().then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};