import CreateDialog from './createDialog';
import SubjectTableList from './components/SubjectTableList';
import { BtnCreate } from '@/components/common';
import { searchMixin } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  components: {
    BtnCreate,
    CreateDialog,
    SubjectTableList
  },
  mixins: [searchMixin],
  data: () => ({
    searchParams: {
      name: '',
      endDate: '',
      startDate: '',
      subjectType: 'office'
    },
    columnData: [],
    tableData: []
  }),
  computed: {
    ...mapGetters({
      subjectWordData: 'system/accounting/subjectWordData'
    })
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        const {
          startDate,
          endDate
        } = data;
        this.billDate = [startDate, endDate]; // 下账日期
        this.searchParams = data;
      },
      immediate: true
    },
    subjectWordData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = data;
    },
    showCreateDialog() {
      const title = '添加一级科目';
      this.$refs.creatRef.showDialog({
        parentsId: 0
      }, title);
    },
    showAddDialog(row) {
      const title = `添加《${row.name}》二级科目`;
      this.$refs.creatRef.showDialog({
        parentsId: row.id
      }, title);
    },
    // 编辑
    editTableRow({
      row
    }) {
      const title = `编辑《${row.name}》科目`;
      this.$refs.creatRef.showDialog(row, title);
    },
    onChangeDate(date) {
      const [start, end] = date;
      this.billDate = date;
      this.searchParams.endDate = end;
      this.searchParams.startDate = start;
    },
    // 查看
    showTableDetail() {},
    // 删除
    deleteTableRow({
      row
    }) {
      const {
        id
      } = row;
      if (row.parentsId == 0) {
        this.$bus.open(() => {
          this.deleteRow(id);
        }, '请注意，删除一级科目会把该科目的子科目清空！');
      } else {
        this.$bus.open(() => {
          this.deleteRow(id);
        }, '是否确定删除该科目？');
      }
    },
    deleteRow(id) {
      const opst = {
        id
      };
      this.$store.dispatch('system/accounting/delSubject', opst).then(() => {
        this.fetchData(this.searchParams);
        this.$bus.tip();
      });
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    fetchData(opst) {
      this.$store.dispatch('system/accounting/fetchListLevel', opst);
    }
  }
};