import "core-js/modules/es.array.push.js";
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    resType: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCheckoutCancel(item) {
      this.$emit('on-check', item.row);
    },
    currentPermission() {
      const list = this.$store.getters['system/role/rolePermissionList'];
      if (!list || !list.length) return;
      const curNav = list.find(it => it.code === '/audit');
      return curNav.subMenus.find(it => it.code === '/audit/team-single');
    },
    onEdit() {
      const roleMenu = this.currentPermission();
      const curRole = roleMenu.roleMenuOpts.find(it => it.code.toLowerCase() == this.resType);
      this.$store.commit('audit/index/CUR_ROLE', curRole);
      const {
        planId,
        planNumber
      } = this.item.row;
      this.$store.commit('plan/index/CUR_PLAN_ID', planId);
      this.$store.dispatch('audit/index/list', {
        planNumber
      }).then(data => {
        const {
          planType
        } = data.list[0];
        const path = `/audit/team-single-edit/${this.resType}?id=${planId}&planId=${planId}&planType=${planType}&from=billCheck`;
        this.$router.push(path);
      });
    }
  }
};