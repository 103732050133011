export const formState = () => ({
  shopType: 'SHOP',
  shopTypeLabel: '购物店'
});
export const formBankState = () => ({});
export const agreementState = () => ({
  driverRate: 0,
  guideRate: 0,
  sellPrice: 0,
  shopRate: 0,
  subsidy: 0,
  contentType: '',
  price: 0,
  quotedPrice: 0,
  contractPriceFrees: 0,
  remarks: '',
  isCustom: true
});
export const rules = {
  name: [{
    required: true,
    message: '购物店不能为空'
  }]
};
export const ruleBank = {
  account: [{
    required: false,
    message: '请输入账户名'
  }],
  bank: [{
    required: false,
    message: '请输入开户行'
  }],
  bankCard: [{
    required: false,
    message: '请输入银行卡号'
  }],
  bankCardType: [{
    required: false,
    message: '请选择账户类型'
  }]
};
export const ruleAgreement = {
  name: [{
    required: true,
    message: '名字不能为空'
  }],
  startTime: [{
    required: true,
    message: '开始日期不能为空'
  }],
  endTime: [{
    required: true,
    message: '结束日期不能为空'
  }]
};
export const bankKeys = ['account', 'bank', 'bankCard', 'bankCardType'];
export const resourceTypeList = [{
  label: 'contentType',
  text: '项目'
}, {
  label: 'accompanyRate',
  text: '全陪比例',
  symbol: true
}, {
  label: 'driverRate',
  text: '司佣比例',
  symbol: true
}, {
  label: 'guideRate',
  text: '导佣比例',
  symbol: true
}, {
  label: 'guideManagerRate',
  text: '导管比例',
  symbol: true
}, {
  label: 'shopRate',
  text: '社佣比例',
  symbol: true
}];

// export const mapKeys = {
//   HOTEL: [
//     { label: 'contentType', text: '项目' },
//     { label: 'quotedPrice', text: '报价' },
//     { label: 'price', text: '底价' },
//   ],
//   SCENIC: [
//     { label: 'contentType', text: '项目' },
//     { label: 'quotedPrice', text: '报价' },
//     { label: 'sellPrice', text: '卖价' },
//     { label: 'price', text: '底价' },
//     { label: 'accompanyRate', text: '全陪比例', symbol: true },
//     { label: 'driverRate', text: '司佣比例', symbol: true },
//     { label: 'guideRate', text: '导佣比例', symbol: true },
//   ],
//   SHOP: [
//     { label: 'contentType', text: '项目' },
//     { label: 'accompanyRate', text: '全陪比例', symbol: true },
//     { label: 'driverRate', text: '司佣比例', symbol: true },
//     { label: 'guideRate', text: '导佣比例', symbol: true },
//     { label: 'guideManagerRate', text: '导管比例', symbol: true },
//     { label: 'shopRate', text: '社佣比例', symbol: true },
//   ],
//   RESTAURANT: [
//     { label: 'contentType', text: '项目' },
//     { label: 'price', text: '单价' },
//   ],
//   TRANSPORT: [
//     { label: 'contentType', text: '项目' },
//     { label: 'price', text: '价格' },
//     { label: 'subsidy', text: '返款' },
//   ],
//   INSURANCE: [
//     { label: 'contentType', text: '项目' },
//     { label: 'price', text: '价格' },
//   ],
//   BUS: [
//     { label: 'contentType', text: '项目' },
//     { label: 'price', text: '价格' },
//   ],
// }