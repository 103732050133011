import InputResource from '@/components/common/Input/InputResource.vue';
import { SelectProduct, InputPeopleCount } from '@/components/common';
import { parseDate } from '@/utils';
import { orderType as OrderType } from '@/config/order';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  startTripDate: parseDate(new Date()),
  endTripDate: parseDate(new Date())
});
const rules = {
  planCustomerNumber: [{
    required: true,
    message: '请输入大团号',
    trigger: 'blur'
  }],
  adultCount: [{
    required: true,
    message: '请输入参团人数',
    trigger: 'blur'
  }],
  productName: [{
    required: true,
    message: '请选择产品线路',
    trigger: 'change'
  }],
  startTripDate: [{
    required: true,
    message: '请选择发团日期',
    trigger: 'change'
  }],
  endTripDate: [{
    required: true,
    message: '请选择散团日期',
    trigger: 'change'
  }]
};
export default {
  components: {
    InputResource,
    InputPeopleCount,
    SelectProduct
  },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules,
      tripData: [],
      form: defaultState()
    };
  },
  computed: {
    ...mapGetters({
      curOrder: 'order/index/currentOrder'
    }),
    getRules() {
      return this.isUpdate ? {
        ...rules,
        code: [{
          required: true,
          message: '请选择预定中心',
          trigger: 'change'
        }]
      } : rules;
    }
  },
  watch: {
    curOrder: {
      handler: function (item) {
        this.form = item;
      }
    }
  },
  mounted() {
    const {
      id
    } = this.$route.query;
    if (!id) return;
    this.fetchDetail(id);
  },
  methods: {
    selectProduct(item) {
      this.form.productDays = item.days;
      this.form.productId = item.value;
      this.calcTripEndDate();
    },
    selectResource(val) {
      this.form.code = val.value;
      this.form.companyCode = val.code;
      this.$store.commit('order/index/CUR_ORDER', {
        ...this.form,
        companyCode: val.code
      });
    },
    changeStartDate(val) {
      this.form.startTripDate = val;
      this.calcTripEndDate();
    },
    changeEndDate(val) {
      this.$set(this.form, 'endTripDate', val);
    },
    handleValidate() {
      return this.$refs['ruleForm'];
    },
    fetchDetail(customerOrderId) {
      this.$store.dispatch('order/index/fetchDetail', {
        id: customerOrderId
      });
    },
    saveOrUpdate() {
      this.$refs['ruleForm'].validate(valid => {
        if (!valid) return;
        const opts = {
          ...this.form,
          orderType: OrderType.control
        };
        this.$store.dispatch('order/index/createOrUpdate', opts).then(res => {
          if (res) {
            const {
              id
            } = res.data;
            if (!this.form.id) {
              this.createOrUpdateItinerary(id);
              this.$parent.redirectDetail(id);
            }
            this.form.id = id;
            this.form.customerOrderId = id;
            this.fetchDetail(id);
          }
        });
      });
    }
  }
};