import { isTest, isDev } from './env';
import Cookies from 'js-cookie';
const TokenKey = !isTest ? 'Future-Saas-Token' : 'Future-Saas-Token-Test';
const TokenUser = !isTest ? 'UserData-Saas-Token' : 'UserData-Saas-Token-Test';

// 旅检上传
const TokenSuperviseKey = 'E-Travel-Supervise';
// 兴坪船
const TokenBoatKey = 'Boat-River-Token';

// 集团
// const GroupSystemTokenKey = 'Future-Group-Token'
const GroupSystemDataKey = 'Future-Group-UserData';
const GroupSystemHeaderKey = 'Future-Group-Headers';
function getDomain() {
  return !isDev ? {
    path: '/',
    domain: '.weilailvxingshe.com'
  } : null;
}
function getCookieByKey(key) {
  return Cookies.get(key);
}
function delCookieByKey(key) {
  return Cookies.remove(key);
}
function setCookieByKey(key, data) {
  const domain = getDomain();
  return Cookies.set(key, data, domain);
}
function getCookieDataByKey(key) {
  let data = Cookies.get(key);
  try {
    data = JSON.parse(data);
  } catch (error) {
    console.log('error: ', error);
  }
  return data;
}
export function getToken() {
  return getCookieByKey(TokenKey);
}
export function setToken(token) {
  return setCookieByKey(TokenKey, token);
  // const domain = getDomain()
  // return Cookies.set(TokenKey, token, domain)
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function setUserData(data) {
  const domain = getDomain();
  return Cookies.set(TokenUser, data, domain);
}
export function getUserData() {
  let data = Cookies.get(TokenUser);
  try {
    data = JSON.parse(data);
  } catch (error) {
    console.log('error: ', error);
  }
  return data;
}
export function delUserData() {
  return delCookieByKey(TokenUser);
}

// 旅检平台token设置
export function getTravelData() {
  return getCookieDataByKey(TokenSuperviseKey);
  // let data = Cookies.get(TokenSuperviseKey)
  // try {
  //   data = JSON.parse(data)
  // } catch (error) {
  //   console.log('error: ', error)
  // }

  // return data
}
export function setTravelData(data) {
  return setCookieByKey(TokenSuperviseKey, data);
}
export function delTravelData() {
  return delCookieByKey(TokenSuperviseKey);
}

// 兴坪船平台token设置
export function getBoatRiverData() {
  return getCookieDataByKey(TokenBoatKey);
  // let data = Cookies.get(TokenBoatKey)
  // try {
  //   data = JSON.parse(data)
  // } catch (error) {
  //   console.log('error: ', error)
  // }

  // return data
}
export function setBoatRiverData(data) {
  return setCookieByKey(TokenBoatKey, data);
}
export function delBoatRiverData() {
  return delCookieByKey(TokenBoatKey);
}
export function setGroupHeaders(data) {
  return setCookieByKey(GroupSystemHeaderKey, data);
}
export function getGroupHeaders() {
  return getCookieByKey(GroupSystemHeaderKey);
}
export function setGroupUserData() {
  const data = getCookieByKey(GroupSystemDataKey);
  return setCookieByKey(TokenUser, data);
}