import { TripInfo, PersonCount, Contact, Custom, Shuttle } from '@/components/common';
import { planEditOtherPayDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditOtherPayDetail,
      planId: '',
      planOtherPayId: ''
    };
  },
  mounted() {
    this.$bus.on('otherPay:list', this.show);
    this.$bus.on('otherPay:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('otherPay:order', {
        planId: this.planId,
        planOtherPayId: this.planOtherPayId
      });
    },
    handleTouristDialog(id) {
      this.$bus.emit('plan:tourist', id);
    },
    show(params) {
      const {
        currentPlanId: planId,
        id: planOtherPayId
      } = params;
      this.visible = true;
      this.planId = planId;
      this.planOtherPayId = planOtherPayId;
      this.fetchData(planId, planOtherPayId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planId, planOtherPayId) {
      this.$store.dispatch('plan/otherPay/fetchOrder', {
        planId,
        planOtherPayId: planOtherPayId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = list;
      });
    },
    reloadData(data) {
      this.tableData = data;
    },
    // 移除
    handleRemove(scope) {
      let {
        row
      } = scope;
      const loading = this.$bus.loading();
      this.$store.dispatch('plan/otherPay/deleteOrder', row.id).then(() => {
        this.fetchData(this.planId, this.planOtherPayId);
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    // 修改价格
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = val;
        return;
      }
      item.totalPay = this.calcItemMoney(item);
    },
    // 计算单行金额
    calcItemMoney(obj) {
      const {
        price,
        adultCount
      } = obj;
      let total = price * (adultCount || 0);
      return total;
    },
    // 保存
    handleSave() {
      // 后端参数需要
      const list = this.tableData.map(it => {
        it.planOtherPayId = this.planOtherPayId;
        return it;
      });
      this.$store.dispatch('plan/otherPay/updateOrder', list).then(() => {
        this.fetchData(this.planId, this.planOtherPayId);
      });
    }
  }
};