import "core-js/modules/es.array.push.js";
import { DialogForm, DialogAuth } from './components';
import { BtnCreate } from '@/components/common';
import { RoleColumns as columnData } from './tableConfig';
import { permissionMixin, reloadMixin } from '@/mixin';
import { getSelectLabel } from '@/config/select';
import { formatCreateTimeToList } from '@/utils';
export default {
  components: {
    BtnCreate,
    DialogForm,
    DialogAuth
  },
  mixins: [permissionMixin, reloadMixin],
  data: () => ({
    columnData,
    tableLoading: true,
    search: {},
    isEdit: false,
    tableData: [],
    pageProp: {
      total: 0
    }
  }),
  computed: {
    dialogTitle() {
      return this.isEdit ? '更新角色' : '新增角色';
    },
    getAuditRole() {
      return val => {
        return getSelectLabel('auditRole', val);
      };
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(params = {}) {
      // 重置状态与数据
      this.tableLoading = true;
      this.tableData = [];
      this.$store.dispatch('system/role/fetch', {
        ...this.search,
        ...params
      }).then(res => {
        const {
          list,
          totalCount
        } = res;
        this.pageProp.total = totalCount;
        this.tableData = formatCreateTimeToList(list);
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    handleAdd() {
      this.$refs.dialogFormRef.show();
    },
    handleEdit(row) {
      this.$refs.dialogFormRef.edit(row);
    },
    handleAuth(row) {
      const {
        id
      } = row;
      this.$router.push({
        path: '/system/auth',
        query: {
          id
        }
      });
    },
    handleImport(row) {
      const {
        id: desRoleId
      } = row;
      const option = {
        desRoleId
      };
      this.$refs.dialogAuthRef.show(option);
    },
    handleDelete({
      id
    }) {
      this.$bus.open(() => {
        this.$store.dispatch('system/role/delete', id).then(() => {
          this.$bus.tip({
            message: '删除成功'
          });
          this.fetchData();
        });
      });
    },
    handlePage(params) {
      this.fetchData(params);
    }
  }
};