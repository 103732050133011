var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TopTitle", {
    attrs: {
      title: _vm.title,
      disabled: _vm.isSaveDisable,
      "is-log-show": true,
      "is-add": _vm.ignoreAudit
    },
    on: {
      "on-add": _vm.handlePlus,
      "on-save": _vm.handleUpdate,
      "on-log": _vm.handleLog
    }
  }), _vm.tableData && _vm.tableData.length ? _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: item.id + "" + index,
      staticClass: "order-reception mt20 tac"
    }, [_c("Reception", {
      ref: "receptionRef",
      refInFor: true,
      staticStyle: {
        width: "calc(100% - 60px)"
      },
      attrs: {
        item: item,
        index: index,
        type: "order"
      }
    }), _c("BtnBase", {
      attrs: {
        type: "delete",
        plain: "",
        disabled: item.checkAccountStatus
      },
      on: {
        click: function ($event) {
          return _vm.handleRemove(item, index);
        }
      }
    })], 1);
  }) : _c("Empty"), _c("DialogLog", {
    ref: "logRef"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };