import { SelectCustom } from '@/components/common';
import { planTransforOrder } from '@/config/plan';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  components: {
    SelectCustom
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: [],
      tableData: this.data
    };
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planTransforOrder : filterCustomColumn(planTransforOrder);
  }
};