const Remarks = [{
  value: '',
  text: '服务标准',
  name: 'serviceStandardRemark'
}, {
  value: '',
  text: '内部备注',
  name: 'innerRemark'
}, {
  value: '',
  text: '用房备注',
  name: 'hotelRemark'
}, {
  value: '',
  text: '用餐备注',
  name: 'restaurantRemark'
}, {
  value: '',
  text: '用车备注',
  name: 'busRemark'
}, {
  value: '',
  text: '景区备注',
  name: 'scenicRemark'
}, {
  value: '',
  text: '友情提示',
  name: 'friendlyTips'
}, {
  value: '',
  text: '备注',
  name: 'remarks'
}];
export default {
  props: {
    remarks: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      remarkList: Remarks
    };
  },
  watch: {
    remarks: {
      handler: 'formatRemarks',
      immediate: true
    }
  },
  methods: {
    formatRemarks(data) {
      if (!data) return;
      Object.keys(data).map(key => {
        const fIndex = this.findKey(key);
        if (fIndex > -1) {
          this.remarkList[fIndex].value = data[key];
        }
      });
    },
    findKey(key) {
      return this.remarkList.findIndex(it => it.name == key);
    }
  }
};