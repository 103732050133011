import "core-js/modules/es.array.push.js";
import TopTitle from '../reserve/components/TopTitle.vue';
import { SelectDictionary } from '@/components/common';
import { parseDate } from '@/utils';
import { hasBooked } from '@/utils/status';
import { orderHotel as columnData } from '@/config/resourceOrder';
const defaultState = () => ({
  orderDate: parseDate(new Date()),
  hotelArea: '',
  hotelStandard: '',
  count: 1,
  remarks: '',
  headCompanyId: -1,
  auditProgress: "WAITAUDIT",
  checkAccountStatus: false,
  customerId: '',
  customerOrderId: "",
  hotelId: -1,
  hotelName: "",
  isAdd: true
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    reserveId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      columnData,
      title: '酒店',
      tableData: [],
      btnLoading: false,
      fetchPath: 'order/index/fetchHotel',
      deletePath: 'order/index/deleteOrHotel',
      updatePath: 'order/index/createOrHotel'
    };
  },
  computed: {
    hasBooked,
    tableTitle() {
      return this.showTitle ? this.title : '';
    },
    currentOrder() {
      return this.$store.getters['order/index/currentOrder'];
    }
  },
  watch: {
    data: {
      handler: function (roomList) {
        this.tableData = roomList;
      },
      immediate: true
    }
  },
  methods: {
    fetchData(id) {
      const params = {
        planId: -1,
        customerOrderId: id
      };
      this.$store.dispatch('order/index/fetchHotel', params).then(data => {
        this.tableData = data;
      });
    },
    handleAdd() {
      this.tableData.push({
        ...defaultState(),
        customerOrderId: this.currentOrder.id
      });
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      if (id) {
        this.$store.dispatch('order/index/deleteOrHotel', id);
      }
      this.tableData.splice(scope.$index, 1);
    },
    handleSelectChange(row, type, val) {
      row[type] = val.name;
    },
    saveOne(row) {
      if (!this.validateForm(row)) return;
      this.$store.dispatch('order/index/createOrHotel', [row]).then(() => {
        this.fetchData(row.customerOrderId);
      });
    },
    validateForm(row) {
      if (!row.orderDate) {
        this.$bus.tip({
          type: 'warning',
          message: '酒店信息日期不能为空!'
        });
        return false;
      }
      if (!row.hotelArea) {
        this.$bus.tip({
          type: 'warning',
          message: '酒店信息区域不能为空!'
        });
        return false;
      }
      if (!row.hotelStandard) {
        this.$bus.tip({
          type: 'warning',
          message: '酒店信息标准不能为空!'
        });
        return false;
      }
      return true;
    }
  }
};