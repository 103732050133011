import ReceptionFee from './components/ReceptionFee';
import ReceptionProxy from './components/ReceptionProxy';
import ReceptionRefund from './components/ReceptionRefund';
import { mapGetters } from 'vuex';
import { auditMixin } from '@/mixin';
export default {
  components: {
    ReceptionFee,
    ReceptionProxy,
    ReceptionRefund
  },
  mixins: [auditMixin],
  props: {
    planList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      form: {},
      activeName: 'fee',
      tabIndex: 0,
      item: null,
      index: 0,
      rowNum: 0,
      tabNameMap: ['fee', 'proxy', 'refund']
    };
  },
  computed: {
    ...mapGetters({
      receptionList: 'order/reception/receptionList'
    })
  },
  methods: {
    show({
      rowNum,
      index
    }) {
      this.visible = true;
      this.tabIndex = index;
      this.rowNum = rowNum;
      this.activeName = this.tabNameMap[index];
      this.item = this.receptionList[rowNum];
    },
    hide() {
      this.visible = false;
    },
    handleTab(tab) {
      const {
        index
      } = tab;
      this.tabIndex = index;
      this.activeName = this.tabNameMap[index];
    },
    createOrUpdate() {
      const curRef = this.tabNameMap[this.tabIndex] + 'Ref';
      this.$refs[curRef].createOrUpdate(this.item.id).then(res => {
        const {
          message
        } = res;
        // 判断是不是修改内容需要订单审核流程
        if (message.indexOf('订单审核') != -1) {
          this.$alert(message, '温馨提示', {
            confirmButtonText: '好的',
            type: 'warning'
          });
        } else {
          this.$bus.tip({
            message
          });
        }
        this.hide();
        this.$bus.emit('audit:teamfund:reload');
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      return list;
    }
  }
};