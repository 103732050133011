export default {
  name: 'StatusIcon',
  props: {
    tips: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'danger'
    }
  }
};