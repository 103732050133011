import { AuditRole } from '@/config/audit';
export const MergeRowKeys = ['auditAndCheckStatus', 'planInfo', 'transportName', 'date', 'frequency', 'shiftNumber', 'startPosition', 'destinationPosition', 'remarks', 'action', 'totalPay'];
export const itemListKeys = () => ({
  count: 1,
  orderDate: '',
  price: 0,
  otherFee: 0,
  transportType: '',
  startPosition: '',
  destinationPosition: '',
  shiftNumber: ''
});
export const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  cashType: 'sign',
  transportCompanyId: '',
  transportName: '',
  remark: '',
  totalPay: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  rowTotal: 0,
  ...itemListKeys()
});