var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "box-dialog",
    attrs: {
      title: "行程",
      width: "80%",
      visible: _vm.itineraryVisible,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.itineraryVisible = $event;
      }
    }
  }, [_c("el-breadcrumb", {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      separator: "/"
    }
  }, [_c("el-breadcrumb-item", [_vm._v(" 团号：" + _vm._s(_vm.data.planCustomerNumber) + " ")]), _vm.data.province ? _c("el-breadcrumb-item", [_vm._v(" " + _vm._s(_vm.data.province + _vm.data.city + _vm.data.district) + " ")]) : _vm._e(), _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.data.customerName))])], 1), _vm.pickupData.length ? _vm._l(_vm.pickupData, function (it, index) {
    return _c("el-breadcrumb", {
      key: index,
      staticStyle: {
        "margin-bottom": "15px"
      },
      attrs: {
        separator: "/"
      }
    }, [_c("el-breadcrumb-item", [_vm._v(" " + _vm._s(_vm.getShuttleType(it.shuttleType)) + "： ")]), _c("el-breadcrumb-item", [_vm._v(" " + _vm._s(_vm.getParseDate(it.shiftDateTime)) + " ")]), _c("el-breadcrumb-item", [_vm._v(" " + _vm._s(_vm.getTaskType(it.taskType)) + "(" + _vm._s(it.shiftNumber) + ") ")]), _c("el-breadcrumb-item", [_vm._v(_vm._s(it.desPosition))])], 1);
  }) : _vm._e(), _c("DetailItinerary", {
    ref: "itineraryRef",
    attrs: {
      id: _vm.data.id
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };