// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".from-create-wrapper .input-box {\n  min-width: 330px !important;\n}\n.from-create-wrapper .input-box > .label {\n  position: relative;\n  min-width: 30px;\n  margin-right: 12px;\n  font-size: 12px;\n  color: #53575f;\n  font-weight: bold;\n}\n.from-create-wrapper .input-box > .label.is-require::before {\n  position: absolute;\n  top: 50%;\n  right: -8px;\n  font-size: 12px;\n  color: red;\n  content: \"*\";\n  transform: translateY(-45%);\n}\n.el-alert-from {\n  margin-bottom: 20px;\n}", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "170px",
	"topNavHeight": "56px",
	"labelSize": "12px",
	"red": "#c03639",
	"green": "#30b08f"
};
module.exports = exports;
