// import store from '@/store'

// 字典
export const getDictionaryList = (store, type) => {
  const data = store.getters['system/dictionary/dictionaryCodeData'];
  const dicType = data[type];
  return dicType ? dicType.agencyDictionaryList : [];
};
// 字典
export const getDictionaryValue = (store, type, id) => {
  const data = store.getters['system/dictionary/dictionaryCodeData'];
  const dicType = data[type];
  if (dicType && id) {
    return dicType.agencyDictionaryList.find(it => it.id == id);
  }
  return dicType ? dicType.agencyDictionaryList[0] : null;
};

// 字典
export const getDictionaryValueByName = (store, type, name) => {
  const data = store.getters['system/dictionary/dictionaryCodeData'];
  const dicType = data[type];
  if (dicType && name) {
    return dicType.agencyDictionaryList.find(it => it.label == name);
  }
};
export const defDictionary = (store, type) => {
  return getDictionaryValue(store, type);
};