var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      title: "发票预览",
      visible: _vm.visible,
      "confirm-text": "下载"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      cancel: _vm.hide,
      confirm: _vm.handleDownload
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_vm.isPicture ? _c("img", {
    attrs: {
      id: "imagePreview",
      width: "100%",
      height: "100%"
    }
  }) : _vm._e(), _vm.isPdf ? _c("iframe", {
    staticStyle: {
      width: "100%",
      height: "500px"
    },
    attrs: {
      id: "pdfPreview",
      hidden: ""
    }
  }) : _vm._e(), _vm.isOfd ? _c("div", {
    attrs: {
      id: "OfdContent"
    }
  }) : _c("el-empty", {
    attrs: {
      description: "无法预览"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };