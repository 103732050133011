import { Scenic } from '@/components/common/Order';
import { auditEditScenic, auditEditScenicAll } from '@/config/audit';
import { permissionMixin, systemMixin } from '@/mixin';
export default {
  components: {
    Scenic
  },
  mixins: [permissionMixin, systemMixin],
  data() {
    return {
      columnData: auditEditScenic,
      auditAll: false,
      isShow: true
    };
  },
  created() {
    setTimeout(() => {
      this.columnData = this.hasSettlementPrice('scenicFloorPriceAudit') ? auditEditScenicAll : auditEditScenic;
      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      if (!isOrderInfo) {
        this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
      }
    }, 100);
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    },
    reloadData() {
      return this.$refs.itemRef.fetchData();
    }
  }
};