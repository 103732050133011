/* eslint-disable no-unused-vars */
import { fetchLog, fetchCompany, deleteCompany, fetchSelectCompany, createOrUpdateCompany } from '@/api/resource/company';
import { TYPES } from '@/config/resource';
import { formatCharData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  companyData: null,
  companyLog: null,
  companyHotel: null
});
const state = dfaultState();
const getters = {
  companyData: state => state.companyData,
  companyLog: state => state.companyLog,
  companyHotel: state => state.companyHotel
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchCompany(queryParam).then(res => {
      const {
        data
      } = res;
      let {
        list
      } = data;
      data.list = formatCharData(list);
      commit('FETCH_DATA', data);
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.company
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // 资源公司酒店列表
  fetchCompanyHotel({
    commit
  }, params) {
    params = {
      ...params,
      resourceType: TYPES.hotel
    };
    return fetchSelectCompany(params).then(res => {
      if (!res) return;
      const {
        data
      } = res;
      commit('COMPANY_HOTEL', formatCharData(data));
      return data;
    });
  },
  // fetchImage({ commit }, resourceId) {
  //   const opts = { resourceId, resourceType: TYPES.company }
  //   return this.dispatch('resource/common/editImage', opts)
  // },

  createOrUpdate({
    commit
  }, opts) {
    return createOrUpdateCompany(opts);
  },
  delete({
    commit
  }, id) {
    commit('DELETE', id);
    return deleteCompany(id);
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.companyData = data;
  },
  FETCH_LOG: (state, data) => {
    state.companyLog = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.companyData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.companyData.list.filter(it => it.id !== id);
    state.companyData.list = list;
  },
  COMPANY_HOTEL: (state, data) => {
    state.companyHotel = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};