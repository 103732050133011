import DialogExcel from './DialogExcel';
import customDialog from './customDialog';
import DialogTarget from './DialogTarget';
import TableList from './TableList';
import TotalTable from './TableTotal';
import { SelectCustom, SelectStaff, DialogLog } from '@/components/common';
import { searchMixin } from '@/mixin';
import { mergeTableRow, downloadExport } from '@/utils';
import { mapGetters } from 'vuex';
import { MonthKey, KPIType } from './config';
import { systemCustomKpi } from './configTable';
export default {
  components: {
    SelectCustom,
    SelectStaff,
    customDialog,
    DialogLog,
    DialogTarget,
    DialogExcel,
    TotalTable,
    TableList
  },
  mixins: [searchMixin],
  data() {
    const curYear = new Date();
    return {
      loading: true,
      columnData: systemCustomKpi,
      tableData: [],
      statisticsKeys: MonthKey,
      statisticData: [],
      statistic: {},
      searchParams: {
        year: curYear
      },
      total: 0
    };
  },
  computed: {
    ...mapGetters({
      customApiData: 'system/index/customApiData'
    }),
    projectName() {
      return row => {
        if (row.kpitype == KPIType.numberOfPeople) {
          return '人数';
        }
        if (row.kpitype == KPIType.profit) {
          return '毛利';
        }
        if (row.kpitype == KPIType.turnover) {
          return '营业额';
        }
      };
    }
  },
  watch: {
    customApiData: {
      handler: function (data) {
        if (!data) {
          // mixin
          this.handleSearch();
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      const {
        statistics,
        totalCount,
        list
      } = data;
      this.tableData = list.map(it => {
        it.rowId = it.customerId;
        it.mergeId = it.customerId;
        it.createUserName = it.createUserNames.toString();
        return it;
      });
      // 根据kpitype字母固定排序
      // this.tableData.sort((a, b) => {
      //   return a.kpitype.localeCompare(b.kpitype)
      // })
      // 合并客户和年份
      this.tableData = mergeTableRow(this.tableData, ['customerName', 'year']);
      this.total = totalCount;
      // this.tableData = mergeTableRow(this.tableData, [
      //   'customerName',
      //   'year',
      //   'action',
      // ])
      let sumList = this.formatSumData(this.tableData);
      this.statistics = statistics.map(it => {
        it.customerName = '合计';
        it.mergeId = 0;
        it.rowId = 0;
        return it;
      });
      this.statisticData = [...sumList, ...this.statistics];
      this.statistics = mergeTableRow(this.statisticData, ['customerName', 'year']);
    },
    fetchData(params) {
      params = {
        ...this.searchParams,
        ...params
      };
      params.year = this.searchParams.year ? new Date(this.searchParams.year).getFullYear() : new Date().getFullYear();
      this.$store.dispatch('system/index/fetchCustomerKpi', params);
    },
    // 计算整合小计
    formatSumData(list) {
      if (!list || !list.length) return [];
      let turnoverTotal = {};
      let peopleTotal = {};
      let profitTotal = {};
      this.statisticsKeys.forEach(key => {
        peopleTotal[key] = 0;
        profitTotal[key] = 0;
        turnoverTotal[key] = 0;
      });
      list.forEach(it => {
        if (KPIType.count == it.kpitype) {
          this.statisticsKeys.forEach(key => {
            peopleTotal[key] += it[key];
            peopleTotal.kpitype = it.kpitype;
          });
        }
        if (KPIType.profit == it.kpitype) {
          this.statisticsKeys.forEach(key => {
            profitTotal[key] += it[key];
            profitTotal.kpitype = it.kpitype;
          });
        }
        if (KPIType.turnover == it.kpitype) {
          this.statisticsKeys.forEach(key => {
            turnoverTotal[key] += it[key];
            turnoverTotal.kpitype = it.kpitype;
          });
        }
      });
      let item = [peopleTotal, profitTotal, turnoverTotal];
      const arr = item.map(it => {
        it.customerName = '小计';
        it.mergeId = 1;
        return it;
      });
      return arr;
    },
    // 新增
    handleCreate() {
      this.$refs.dialogRef.show();
    },
    // 新增客户目标
    handleTarget() {
      this.$refs.dialogTargetRef.show();
    },
    // 编辑
    handleEdit(row) {
      this.$refs.dialogTargetRef.edit(row.customerId);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    },
    onSelectCustom(val) {
      this.searchParams.customerLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    onSearch() {
      const search = {
        ...this.searchParams,
        year: new Date(this.searchParams.year).getFullYear()
      };
      this.fetchData(search);
    },
    // 获取日志
    handleLog(row) {
      const {
        ids
      } = row;
      this.$store.dispatch('system/index/systemLog', {
        itemIds: ids,
        types: '7'
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    // 删除
    handleDelete(row) {
      const {
        customerId,
        year
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('system/index/delCustomerKpi', {
          customerId,
          year
        }).then(() => {
          this.fetchData(this.searchParams);
          this.$bus.tip();
        });
      });
    },
    downloadTemplate() {
      this.$store.dispatch('system/customGoal/customerKpiTemplateDownload').then(res => {
        downloadExport(res, '客户目标导入模版.xlsx');
        this.$bus.tip();
      });
    },
    importTemplate() {
      this.$refs.dialogExcelRef.show();
    }
  }
};