export default {
  data() {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      form: {}
    };
  },
  methods: {
    show(data) {
      this.loading = false;
      this.visible = true;
      const {
        id: reimbId,
        guideId,
        guideName,
        travelAgencyPay: money,
        planCustomerNumber: planNumber
      } = data;
      this.form = {
        guideId,
        planNumber,
        guideName,
        money,
        reimbId
      };
    },
    hide() {
      this.visible = false;
    },
    handleSend() {
      const {
        money
      } = this.form;
      if (!money) {
        this.$bus.tip({
          message: '请填写金额',
          type: 'warning'
        });
        return;
      }
      this.$store.dispatch('system/sms/sendMessageToGuide', this.form).then(() => {
        this.hide();
        this.$bus.tip({
          message: '短信已发送'
        });
        this.$parent.reload();
      });
    }
  }
};