import { summaryMixin, tableMixin } from '@/mixin';
import CheckBox from '../components/CheckBox';
import { Members } from '@/components/common';
import { CustomerInfo } from '@/components/common';
export default {
  components: {
    Members,
    CheckBox,
    CustomerInfo
  },
  mixins: [summaryMixin, tableMixin],
  // props: {
  //   tableData: {
  //     type: Array,
  //     required: true,
  //   },
  //   columnData: {
  //     type: Array,
  //     required: true,
  //   },
  //   loading: {
  //     type: Boolean,
  //     default: true,
  //   },
  // },

  data() {
    return {
      delayTime: 1000,
      redirectPath: '/audit/team-single-edit',
      statisticsKeys: ['total', 'number', 'guideCollection', 'collection'],
      checkAll: false
    };
  },
  watch: {
    checkAll: {
      handler: function (data) {
        this.$parent.formatCheck(data);
      }
    }
  },
  methods: {
    // 日志
    handleLog(row) {
      this.$parent.handleShowLog(row);
    },
    // 全选
    changeCheckAll(checkAll) {
      this.checkAll = checkAll;
    },
    // 单选选中触发
    handleCheckout(item) {
      this.$parent.handleCheckout(item);
    },
    // 取消审核触发
    handleCancelCheckout(item) {
      this.$parent.handleCancel(item);
    }
  }
};