var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt20 form-fix-bar"
  }, [_c("BillInput", {
    ref: "formRef",
    attrs: {
      "pay-text": "预收款",
      "resource-id": _vm.getResourceId,
      "cash-type": _vm.TYPES.shop_subsidy,
      "is-subsidy": true,
      "sub-type": _vm.CashFeeType.INCOME
    },
    on: {
      change: _vm.changeMoney
    }
  }, [_c("SelectShop", {
    attrs: {
      label: "",
      "is-require": ""
    },
    on: {
      "on-select": _vm.selectResource
    },
    model: {
      value: _vm.resourceId,
      callback: function ($$v) {
        _vm.resourceId = $$v;
      },
      expression: "resourceId"
    }
  })], 1), _c("div", {
    staticClass: "table-wrapper"
  }, [_c("h3", [_vm._v("未结清数据")]), _c("SearchUnPay", {
    attrs: {
      loading: _vm.exportLoading
    },
    on: {
      search: _vm.fetchData
    }
  }), _c("TableUnPay", {
    ref: "tableRef"
  })], 1), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "close",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(" 关闭 ")]), _c("BtnBase", {
    attrs: {
      type: "save",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };