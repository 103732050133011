import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchShopping, updateShopping, deleteShopping, deleteShoppingItem, fetchGoods, fetchGoodsDetail } from '@/api/plan/shopping';
const dfaultState = () => ({
  shoppingData: null,
  shoppingOrderData: null
});
const state = dfaultState();
const getters = {
  shoppingData: state => state.shoppingData,
  shoppingOrderData: state => state.shoppingOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    // params = { ...DEFAULT_PAGE, ...params }
    return fetchShopping(params).then(res => {
      const {
        data
      } = res;
      // commit('SHOPPING_DATA', data.list)
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    return updateShopping(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除 安排
  delete({
    commit
  }, ids) {
    return deleteShopping(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      // commit('SHOPPING_DELETE', ids)
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteShoppingItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 商品列表
  fetchGoods({
    commit
  }, params) {
    return fetchGoods(params).then(res => {
      let {
        data
      } = res;
      return data;
    });
  },
  // 获取 商品信息
  fetchGoodsDetail({
    commit
  }, params) {
    return fetchGoodsDetail(params).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  SHOPPING_DATA: (state, list) => {
    state.shoppingData = list;
  },
  SHOPPING_DELETE: (state, ids) => {
    let shoppingList = state.shoppingData;
    const list = [];
    shoppingList.forEach((it, idx) => {
      if (!ids.includes(it.shoppingPlanId)) {
        list.push(it);
      }
    });
    state.shoppingData = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};