var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.label ? _c("span", _vm._b({
    class: ["label", {
      "is-require": _vm.isRequire
    }],
    style: [_vm.labelStyle, _vm.getFontSize]
  }, "span", _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.label) + _vm._s(_vm.semi ? "：" : "") + " ")]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };