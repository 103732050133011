import { PayedColumn as columnData } from '@/config/billDown/payUnit';
import { TYPES } from '@/config/billDown';
import { setCheckStatus, getPayedMoney, getIds } from '@/views/billDown/help';
import payedMixin from '@/views/billDown/payUnit/payedMixin';
export default {
  mixins: [payedMixin],
  data() {
    return {
      columnData,
      tableData: [],
      total: 0,
      billCheckAll: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(params = {}) {
      const {
        rid: resourceId,
        id: logCashId
      } = this.$route.query;
      this.fetchPayedData({
        ...params,
        resourceId,
        logCashId
      });
    },
    fetchPayedData(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('group/fetchPayed', params).then(data => {
        if (!data || !data.list) return;
        this.tableData = data.list;
        this.total = data.totalCount;
        loading.close();

        // 更新余额
        this.updateInfo();
      }).catch(() => {
        loading.close();
      });
    },
    changeCheckAll(status) {
      this.setTableCheckStatus({
        status
      });
    },
    handleCancel({
      id,
      cashLogId
    }) {
      const loading = this.$bus.loading();
      const ids = Array.isArray(id) ? id : [id];
      const opts = {
        cashType: TYPES.company,
        logCashId: cashLogId,
        ids
      };
      this.$store.dispatch('group/deletePay', opts).then(() => {
        this.fetchData();
        this.billCheckAll = false;
        this.$emit('reload');
        loading.close();
      }).catch(() => {
        loading.close();
      });
    },
    cancelSelect(row) {
      const {
        id,
        cashLogId
      } = row;
      this.$bus.open(() => {
        this.handleCancel({
          id,
          cashLogId
        });
      }, '您确定要取消吗? 是否继续?');
    },
    cancelSelectAll() {
      const ids = getIds(this.tableData);
      if (!ids || !ids.length) {
        this.$bus.tip({
          message: '请勾选要取消的数据',
          type: 'warning'
        });
        return;
      }
      this.$bus.open(() => {
        const {
          cashLogId
        } = this.tableData[0];
        this.handleCancel({
          id: ids,
          cashLogId
        });
      }, '您确定要取消吗? 是否继续?');
    },
    updateInfo() {
      const userMoney = getPayedMoney(this.tableData);
      this.$store.commit('bill/billDown/SET_BILL_INPUT_USEMONEY', userMoney);
    },
    setTableCheckStatus({
      status = false
    }) {
      this.tableData = setCheckStatus(this.tableData, status);
    }
  }
};