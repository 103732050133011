import { CashWay } from './billDown';
import { ShuttleTypeEnum, PickAndSendTypeEnum } from './plan';
import { OrderResPlanEnumSelect } from './order/orderEnum';
import { certBoatType } from './boat';
import { CustomerType } from './customer';
const ALL = {
  value: '',
  label: '全部'
};
const ALL_NEGATIVE = {
  value: -1,
  label: '全部'
};

// 全国旅游平台证件类型
// 1身份证 2护照 3台湾通行证 4港澳通行证 5军官证 8回乡证 9 台胞证
const cardTypeCountry = [{
  label: '身份证',
  value: 1
}, {
  label: '护照',
  value: 2
}, {
  label: '台湾通行证',
  value: 3
}, {
  label: '港澳通行证',
  value: 4
}, {
  label: '台胞证',
  value: 9
}];
const teamTypeCountry = [{
  label: '自组自接',
  value: '1'
}, {
  label: '包机',
  value: '2'
}, {
  label: '专列',
  value: '3'
}, {
  label: '其他',
  value: '4'
}];
const businessTypeCountry = [{
  label: '国内游组团',
  value: '2'
}, {
  label: '入境接待',
  value: '6'
}, {
  label: '国内接待',
  value: '7'
}];
const vehicleTypeCountry = [{
  label: '飞机',
  value: '1'
}, {
  label: '火车',
  value: '2'
}, {
  label: '轮船',
  value: '3'
}, {
  label: '汽车',
  value: '4'
}, {
  label: '其他',
  value: '5'
}];
const guideTypeCountry = [{
  label: '全陪',
  value: '1'
}, {
  label: '地陪',
  value: '2'
}];
const isLeaderTypeCountry = [{
  label: '否',
  value: '0'
}, {
  label: '是',
  value: '1'
}];

// 企业码
const inviteStatus = [{
  label: '已邀请',
  value: '1'
}, {
  label: '未邀请',
  value: '0'
}];
const inviteStatusSearch = [ALL, {
  label: '已邀请',
  value: '1'
}, {
  label: '未邀请',
  value: '0'
}];

// 企业码
const enterpriseCode = inviteStatus;
// 企业码
const enterpriseCodeSearch = [ALL, {
  label: '已开通',
  value: '2'
}, ...inviteStatus];

// 旅监行程单类型
const superviseIteraryType = [{
  value: 'team',
  label: '地接行程'
}, {
  value: 'custom',
  label: '散客行程'
}, {
  value: 'inbound',
  label: '入境行程单'
}];

// 客户目标搜索
const targetSearch = [{
  value: '1',
  label: '已计划'
}, {
  value: '0',
  label: '未计划'
}];

// 兴坪船乘坐工具
const rideBy = [{
  value: 'boat',
  label: '游船'
}, {
  value: 'raft',
  label: '排筏'
}];
const cashMode = [{
  value: 'COMMON',
  label: '常规'
}, {
  value: 'PRE_PAYMENT',
  label: '预付预收'
}, {
  value: 'OTHER',
  label: '其他'
}];

// 这边的 value 会根据后端来配置
const taskType = [{
  value: 'pick',
  label: '接团'
}, {
  value: 'send',
  label: '送团'
}, {
  value: 'city',
  label: '市内中转'
}];
const examine = [{
  value: 1,
  label: '待审核'
}, {
  value: 2,
  label: '计调已审'
}, {
  value: 3,
  label: '财务已审'
}];
const examineSearch = [ALL, {
  value: 'WAITAUDIT',
  label: '待审核'
}, {
  value: 'OPAUDITED',
  label: '计调已审'
}, {
  value: 'FINANCIALAUDITED',
  label: '财务已审'
}
// { 'GUIDEMANAGERAUDITED': '导管已审' },
// { 'GENERALAUDITED': '经理已审' },
// { 'BOSSAUDITED': 'BOSS已审' },
];

// 发送短信类型
const smsPaymenu = [{
  value: '结算款',
  label: '结算款'
}, {
  value: '全陪司机结算款',
  label: '全陪司机结算款'
}, {
  value: '垫付款',
  label: '垫付款'
}, {
  value: '退货',
  label: '退货'
}];

// 旅检团队类型
const ETeamType = [{
  value: 5,
  label: '本地中巴车团队'
}, {
  value: 6,
  label: '本地大客车团队'
}, {
  value: 7,
  label: '本地小车团队'
}, {
  value: 8,
  label: '本地无车团队'
}, {
  value: 9,
  label: '本地接送客'
}, {
  value: 10,
  label: '外地车进桂'
}];

// 旅检景点类型
const EScenicType = [{
  value: '1',
  label: '游览A级景区'
}, {
  value: '2',
  label: '游览非A级景区'
}, {
  value: '3',
  label: '购物'
}, {
  value: '4',
  label: '就餐'
}, {
  value: '5',
  label: '住宿'
}, {
  value: '6',
  label: '娱乐'
}, {
  value: '7',
  label: '自由活动'
}, {
  value: '8',
  label: '接客'
}, {
  value: '9',
  label: '送客'
}];

// 旅检项目类型
const EPayItemType = [{
  value: 1,
  label: '自费项目'
}, {
  value: 2,
  label: '购物项目'
}];
const yesNo = [{
  value: 0,
  label: '否'
}, {
  value: 1,
  label: '是'
}];
const cashSourceType = [{
  value: '',
  label: '是'
}, {
  value: 'other',
  label: '否'
}, {
  value: 'pre_payment',
  label: '仅查看预收付'
}];
const balanceStatus = [ALL_NEGATIVE, {
  value: 'nq',
  label: '不等于0'
}, {
  value: 'eq',
  label: '等于0'
}, {
  value: 'gt',
  label: '大于0'
}, {
  value: 'lt',
  label: '小于0'
}];
const invoicingStatus = [ALL, {
  value: '1',
  label: '已开清'
}, {
  value: '0',
  label: '未开清'
}];

// 收支科目
const subjectType = [ALL, {
  value: '0',
  label: '否'
}, {
  value: '1',
  label: '是'
}];
const teamState = [{
  value: 1,
  label: '已发团'
}, {
  value: 0,
  label: '未发团'
}];

// 客户联系人类型
const customerContactType = [{
  value: '法人',
  label: '法人'
}, {
  value: '业务联系人',
  label: '业务联系人'
}, {
  value: '公司负责人',
  label: '公司负责人'
}, {
  value: '其他',
  label: '其他'
}];

// 钉钉审核状态
const dingTalkStatus = [{
  value: '-1',
  label: '未通过/撤销'
}, {
  value: '0',
  label: '审批中'
}, {
  value: '1',
  label: '审批通过'
}, {
  value: '2',
  label: '审批中/通过'
}, {
  value: '3',
  label: '非未通过'
}];
const shipType = [{
  value: '四星船',
  label: '四星船'
}, {
  value: '三星船',
  label: '三星船'
}, {
  value: '五星船',
  label: '五星船'
}, {
  value: '兴坪船',
  label: '兴坪船'
}];
const boatTicketType = [{
  value: '下舱',
  label: '下舱'
}, {
  value: '上舱',
  label: '上舱'
}, {
  value: 'vip舱',
  label: 'vip舱'
}];
const boatFeeType = [{
  value: '全票',
  label: '全票'
}, {
  value: '半票',
  label: '半票'
}, {
  value: '免票',
  label: '免票'
}];
export const SELECTOR = {
  // 是否有余额
  radio: yesNo,
  isAdd: [ALL, ...yesNo],
  // 企业码邀请状态
  inviteStatus,
  inviteStatusSearch,
  // 企业码开通状态
  enterpriseCode,
  enterpriseCodeSearch,
  ETeamType,
  EScenicType,
  EPayItemType,
  // 是否包含预收预付管理流水
  cashSourceType,
  // 开票状态
  invoicingStatus,
  // 收支科目
  subjectType,
  // 余额状态
  balanceStatus,
  smsPaymenu,
  // 乘坐方式
  rideBy,
  // 购买竹筏证件类型
  certBoatType,
  // 目标搜索状态
  targetSearchAll: [ALL, ...targetSearch],
  superviseIteraryType,
  // 订单资源计划状态
  resPlanStatus: OrderResPlanEnumSelect,
  resPlanStatusSearch: [ALL, ...OrderResPlanEnumSelect],
  customerContactType,
  dingTalkStatusSearch: [ALL, ...dingTalkStatus],
  planResBookingStatus: [ALL_NEGATIVE, {
    value: 0,
    label: '下计划'
  }, {
    value: 1,
    label: '已计划'
  }],
  empty: [{
    value: 0,
    label: '无'
  }, {
    value: 1,
    label: '有'
  }],
  rebateType: [{
    value: 'MEMBER',
    label: '人头费'
  }, {
    value: 'AGENCY',
    label: '社利'
  }, {
    value: 'PARK',
    label: '停车返佣'
  }],
  isCancel: [ALL_NEGATIVE, {
    value: 0,
    label: '未退单'
  }, {
    value: 1,
    label: '已退单'
  }],
  restaurant: [{
    value: 'BREAKFAST',
    label: '早餐'
  }, {
    value: 'LUNCH',
    label: '午餐'
  }, {
    value: 'DINNER',
    label: '晚餐'
  }],
  bed: [{
    value: 0,
    label: '不可加床'
  }, {
    value: 1,
    label: '可加床'
  }],
  threeRoom: [{
    value: 'add',
    label: '加床'
  }, {
    value: 'triple',
    label: '标准三人房'
  }, {
    value: 'none',
    label: '没有'
  }],
  // 性别
  sex: [ALL_NEGATIVE, {
    value: 0,
    label: '男'
  }, {
    value: 1,
    label: '女'
  }],
  bank: [{
    value: 0,
    label: '私人账户'
  }, {
    value: 2,
    label: '对公账户'
  }],
  currency: [{
    value: 1,
    label: 'CNY'
  }, {
    value: 2,
    label: 'USD'
  }],
  // 对账状态
  reconciliationState: [ALL, {
    value: '1',
    label: '已对账'
  }, {
    value: '0',
    label: '未对账'
  }],
  // 完成状态
  doneState: [{
    value: 1,
    label: '已完成'
  }, {
    value: 2,
    label: '未完成'
  }],
  // 启用状态
  enableState: [ALL_NEGATIVE, {
    value: 1,
    label: '已启用'
  }, {
    value: 0,
    label: '未启用'
  }],
  // 询价状态
  inquiryStatus: [ALL, {
    value: 'waitQuotation',
    label: '待报价'
  }, {
    value: 'quotation',
    label: '已报价'
  }, {
    value: 'update',
    label: '修改中'
  }],
  // 营业收支
  business: [{
    value: 1,
    label: '营业内收支'
  }, {
    value: 2,
    label: '营业外收支'
  }],
  hotelType: [{
    value: 1,
    label: '常规房'
  }, {
    value: 2,
    label: '买断房'
  }],
  collectType: [{
    value: 'sign',
    label: '挂账'
  }, {
    value: 'guide',
    label: '导游现收'
  }, {
    value: 'guidesign',
    label: '导游签单'
  }, {
    value: 'agency',
    label: '公司转账'
  }
  // { value: 'card', label: '导游刷公司卡' },
  // { value: 'net', label: '网付' },
  ],
  collectProxyType: [ALL, {
    value: 0,
    label: '社收'
  }, {
    value: 1,
    label: '导游代收'
  }],
  cashWay: [{
    value: '1',
    label: '预收付款冲抵'
  }, {
    value: '2',
    label: CashWay.CARD
  }, {
    value: '3',
    label: CashWay.CASH
  }, {
    value: '4',
    label: '坏账冲抵'
  }, {
    value: '5',
    label: '信用卡'
  }],
  payType: [{
    value: 'SIGN',
    label: '挂账'
  }, {
    value: 'GUIDE',
    label: '导游现付'
  }, {
    value: 'GUIDESIGN',
    label: '导游签单'
  }, {
    value: 'AGENCY',
    label: '公司转账'
  }, {
    value: 'ALIPAY_E_CODE',
    label: '企业码付',
    disabled: false
  }
  // { value: 'card', label: '导游刷公司卡' },
  // { value: 'net', label: '网付' },
  ],
  payTypeOther: [{
    value: 'SIGN',
    label: '挂账'
  }, {
    value: 'GUIDE',
    label: '导游现付'
  }, {
    value: 'GUIDESIGN',
    label: '导游签单'
  }, {
    value: 'AGENCY',
    label: '公司转账'
  }, {
    value: 'ALIPAY_E_CODE',
    label: '企业码付',
    disabled: true
  }],
  payType2: [{
    value: 1,
    label: CashWay.CASH
  }, {
    value: 2,
    label: CashWay.CARD
  }, {
    value: 3,
    label: CashWay.RECONCILIATION
  }],
  payType3: [ALL, {
    value: 'SIGN',
    label: '挂账'
  }, {
    value: 'GUIDE',
    label: '导游现付'
  }, {
    value: 'GUIDESIGN',
    label: '导游签单'
  }, {
    value: 'AGENCY',
    label: '公司转账'
  }, {
    value: 'alipayECode',
    label: '企业码付',
    disabled: false
  }],
  settleType: [{
    value: 'MONTH',
    label: '按月结算'
  }, {
    value: 'PLAN',
    label: '按团结算'
  }],
  // 线路可见性
  lineVisible: [{
    value: 1,
    label: '全体可见'
  }, {
    value: 2,
    label: '仅自己可见'
  }, {
    value: 3,
    label: '根据菜单权限可见'
  }],
  // 类型
  type: [{
    value: 'CUSTOM_SUBJECT',
    label: '自定义'
  }, {
    value: 'RESOURCE_SCENIC',
    label: '景区'
  }, {
    value: 'RESOURCE_RESTAURANT',
    label: '餐厅'
  }, {
    value: 'RESOURCE_HOTEL',
    label: '酒店'
  }, {
    value: 'RESOURCE_BUS_COMPANY',
    label: '车队'
  }, {
    value: 'RESOURCE_INSURANCE',
    label: '保险'
  }, {
    value: 'RESOURCE_TRANSPORT_COMPANY',
    label: '大交通'
  }, {
    value: 'RESOURCE_GUIDE',
    label: '导游'
  }, {
    value: 'RESOURCE_SHOP',
    label: '购物'
  }, {
    value: 'RESOURCE_CUSTOMER',
    label: '客户'
  }, {
    value: 'RESOURCE_OTHER_INCOME',
    label: '其他收入'
  }, {
    value: 'RESOURCE_OTHER_PAY',
    label: '其他支出'
  }],
  // 资源类型
  resType: [{
    value: 'HOTEL',
    label: '酒店'
  }, {
    value: 'RESTAURANT',
    label: '餐厅'
  }, {
    value: 'BUS',
    label: '车队'
  }, {
    value: 'SCENIC',
    label: '景区'
  }, {
    value: 'INSURANCE',
    label: '保险'
  }, {
    value: 'TRANSPORT',
    label: '大交通'
  }, {
    value: 'SHOP',
    label: '购物'
  }, {
    value: 'CUSTOMER',
    label: '客户'
  }],
  // 集团安排状态
  groupArrangeState: [ALL_NEGATIVE, {
    value: 1,
    label: '已安排'
  }, {
    value: 2,
    label: '待安排'
  }, {
    value: 3,
    label: '申请取消'
  }],
  // 是否需要预定
  isNeedOrder: [{
    value: 1,
    label: '需要'
  }, {
    value: 0,
    label: '不需要'
  }],
  // 安排状态
  arrangeState: [ALL, {
    value: 0,
    label: '未安排'
  }, {
    value: 1,
    label: '已安排'
  }],
  // 下单状态
  orderState: [{
    value: 1,
    label: '已下单'
  }, {
    value: 0,
    label: '未下单'
  }],
  // 订单类型
  orderType: [ALL, {
    value: 'S_TOURIST',
    label: '散客'
  }, {
    value: 'T_TOURIST',
    label: '团队'
  }],
  // 车队订单类型
  orderFleetType: [ALL_NEGATIVE, {
    value: 1,
    label: '团上订单'
  }, {
    value: 2,
    label: '单独订单'
  }],
  // 发团状态
  teamState,
  teamStateSearch: [{
    value: -1,
    label: '全部'
  }, ...teamState],
  // 团队状态
  groupStatus: [{
    value: 'file',
    label: '已归档'
  }, {
    value: 'settlement',
    label: '已结算'
  }, {
    value: 'quit',
    label: '已退单'
  }, {
    value: 'cancel',
    label: '已取消'
  }, {
    value: 'confirm',
    label: '已确认'
  }, {
    value: 'new',
    label: '新订单'
  }],
  // 大交通
  bigTraffic: [{
    value: 'plane',
    label: PickAndSendTypeEnum.plain
  }, {
    value: 'train',
    label: PickAndSendTypeEnum.train
  }, {
    value: 'ship',
    label: PickAndSendTypeEnum.ship
  }],
  // 计划类型
  planType: [ALL, {
    value: 1,
    label: '团队计划'
  }, {
    value: 2,
    label: '散客计划'
  }, {
    value: 3,
    label: '控位计划'
  }, {
    value: 4,
    label: '代订计划'
  }],
  // 计划类型
  planGenre: [ALL, {
    value: 't_tourist',
    label: '团队'
  }, {
    value: 's_tourist',
    label: '散客'
  }, {
    value: 'booking',
    label: '单项预定'
  }, {
    value: 'c_tourist',
    label: '控位输单'
  }],
  // 计划状态
  planState: [{
    value: 'all',
    label: '全部'
  }, {
    value: 'new',
    label: '新建'
  }, {
    value: 'confirm',
    label: '已确认成团'
  }, {
    value: 'quit',
    label: '已退团'
  }, {
    value: 'cancel',
    label: '已取消'
  }, {
    value: 'settlement',
    label: '已确认结算'
  }, {
    value: 'file',
    label: '已归档'
  }],
  // 票据状态
  ticketState: [{
    value: 1,
    label: '未派单'
  }, {
    value: 2,
    label: '待审核'
  }, {
    value: 3,
    label: '已完成'
  }],
  // 结算状态
  settlementState: [ALL, {
    value: '1',
    label: '已结清'
  }, {
    value: '0',
    label: '未结清'
  }],
  // 导游报账状态
  guideReimbursementState: [ALL, {
    value: 1,
    label: '未确认'
  }, {
    value: 2,
    label: '已确认'
  }, {
    value: 3,
    label: '已报账'
  }, {
    value: 4,
    label: '已退回'
  }],
  // 导游结算
  guideSettlement: [{
    value: 1,
    label: '挂帐结算'
  }, {
    value: 2,
    label: '按团结算'
  }],
  // 接送类型
  shuttleType: [
  // { value: 'shuttle', label: '全部' },
  {
    value: 'pick',
    label: '接团'
  }, {
    value: 'send',
    label: '送团'
  }, {
    value: 'pickandsend',
    label: '接送团'
  }],
  // 接送类型
  shuttleType2: [{
    value: 'shuttle',
    label: '全部'
  }, {
    value: 'pick',
    label: '接团'
  }, {
    value: 'send',
    label: '送团'
  }, {
    value: 'pickandsend',
    label: '接送团'
  }],
  // 任务类型
  taskType,
  taskTypeSearch: [ALL, ...taskType],
  // 市内中转类型 市内中转类型，end_trip_back'-散团集合,'start_trip_together'-出团集合,'none'-默认没有
  taskCityType: [
  // { value: 'none', label: '没有' },
  {
    value: 'end_trip_back',
    label: '散团回送'
  }, {
    value: 'start_trip_together',
    label: '出团集合'
  }],
  cardType: [{
    value: '0',
    label: '身份证',
    disabled: false
  }, {
    value: '1',
    label: '学生证',
    disabled: false
  }, {
    value: '2',
    label: '护照',
    disabled: false
  }, {
    value: '3',
    label: '港澳通行证',
    disabled: false
  }, {
    value: '4',
    label: '台胞证',
    disabled: false
  }, {
    value: '5',
    label: '台湾居民来往大陆通行证',
    disabled: false
  }, {
    value: '6',
    label: '其他',
    disabled: false
  }],
  // 粘贴类型
  stickupType: [{
    value: 'nname:id',
    label: '姓名 身份证号'
  }, {
    value: 'name:id:phone',
    label: '姓名 身份证号 手机号'
  }, {
    value: 'name:hkm:@3',
    label: '姓名 港澳通行证'
  }, {
    value: 'name:passport:@2',
    label: '姓名 护照号'
  }, {
    value: 'name:phone:cardType:id',
    label: '姓名 手机 证件类型 证件证号'
  }, {
    value: 'name:cardType:id',
    label: '姓名 证件类型 证件证号'
  }, {
    value: 'name:phone',
    label: '姓名 手机'
  }, {
    value: 'name:phone:id',
    label: '姓名 手机 身份证号'
  }],
  // 接收状态
  receiveState: [{
    value: 1,
    label: '待接收'
  }, {
    value: 2,
    label: '已接收'
  }, {
    value: 3,
    label: '已退回'
  }, {
    value: 4,
    label: '已拒绝'
  }],
  // 审核
  examine,
  examineSearch,
  // 表头选择审核全部
  examineAll: [{
    value: '',
    label: '审核全部'
  }, ...examineSearch.slice(1, 4)],
  visible: [{
    value: 0,
    label: '个人可见'
  }, {
    value: 1,
    label: '公司可见'
  }, {
    value: 2,
    label: '自定义可见'
  }],
  visibleProduct: [{
    value: 0,
    label: '全体可见'
  }, {
    value: 1,
    label: '仅自己可见'
  }, {
    value: 2,
    label: '根据权限菜单权限可见'
  }],
  // 审核角色 op-计调 默认无, guideManager-导管 generalManager-经管 financial-财务 boss-BOSS ''-无
  auditRole: [{
    value: 'op',
    label: '计调'
  },
  // { value: 'guideManager', label: '导管' },
  {
    value: 'financial',
    label: '财务'
  }, {
    value: 'generalManager',
    label: '管理员'
  }
  // { value: 'boss-BOSS', label: 'BOSS' },
  ],
  // 审核状态
  examineState: [ALL, {
    value: 0,
    label: '待审核'
  }, {
    value: 1,
    label: '已审核'
  }
  // { value: 1, label: '成团前' },
  // { value: 2, label: '待审核' },
  // { value: 3, label: '计调已审' },
  // { value: 4, label: '导管已审' },
  // { value: 5, label: '经理已审' },
  // { value: 6, label: '财务已审' },
  // { value: 7, label: 'BOSS已审' },
  ],
  // 地点类型
  // 'train'-火车 'plane'-飞机，'city'-市内中转
  targetType: [
  // ALL,
  {
    value: 'train',
    label: '火车站'
  }, {
    value: 'plane',
    label: '飞机场'
  }],
  searchTargetType: [ALL, {
    value: 'train',
    label: '火车站'
  }, {
    value: 'plane',
    label: '飞机场'
  }],
  // 模版类型
  templateType: [ALL_NEGATIVE, {
    value: 1,
    label: '出团通知书'
  }],
  // 归档状态
  fileState: [{
    value: 1,
    label: '已归档'
  }, {
    value: 0,
    label: '未归档'
  }],
  // 单位类型
  companyType: [{
    value: 1,
    label: '直营单位'
  }, {
    value: 2,
    label: '集团公司'
  }],
  // 费用类型
  costType: [{
    value: 1,
    label: '支出'
  }, {
    value: 2,
    label: '收入'
  }],
  // 现金日记类型
  cashType: [{
    value: 1,
    label: '收团款'
  }, {
    value: 2,
    label: '付酒店'
  }, {
    value: 3,
    label: '付房费'
  }, {
    value: 4,
    label: '付餐厅'
  }, {
    value: 5,
    label: '付保险'
  }, {
    value: 6,
    label: '付自费'
  }, {
    value: 7,
    label: '付自费项'
  }, {
    value: 8,
    label: '付签证'
  }, {
    value: 9,
    label: '付景区'
  }, {
    value: 10,
    label: '付大交通'
  }, {
    value: 11,
    label: '付车队'
  }, {
    value: 12,
    label: '付外转'
  }, {
    value: 13,
    label: '按公司下账'
  }, {
    value: 14,
    label: '其他代入核销'
  }, {
    value: 15,
    label: '其他支出核销'
  }, {
    value: 16,
    label: '客户代收核销'
  }, {
    value: 17,
    label: '客户返款核销'
  }, {
    value: 18,
    label: '车费网付核销'
  }, {
    value: 19,
    label: '导服费网付核销'
  }, {
    value: 20,
    label: '导游借款申请'
  }, {
    value: 21,
    label: '导游借款'
  }, {
    value: 22,
    label: '导游报账'
  }, {
    value: 23,
    label: '自定义现金日记'
  }, {
    value: 24,
    label: '预收集团公司款'
  }, {
    value: 25,
    label: '预防集团公司款'
  }],
  // 下账模式
  billMode: [{
    value: 1,
    label: '常规'
  }, {
    value: 2,
    label: '预收预付'
  }, {
    value: 3,
    label: '其他'
  }],
  sourceType: [{
    value: 1,
    label: '客户'
  }, {
    value: 2,
    label: '景区'
  }, {
    value: 3,
    label: '酒店'
  }, {
    value: 4,
    label: '餐厅'
  }, {
    value: 5,
    label: '车队'
  }, {
    value: 6,
    label: '保险'
  }, {
    value: 7,
    label: '导游'
  }, {
    value: 8,
    label: '大交通'
  }, {
    value: 9,
    label: '自费商家'
  }, {
    value: 10,
    label: '其他收入'
  }, {
    value: 11,
    label: '其他支出'
  }, {
    value: 12,
    label: '营业外收入'
  }, {
    value: 13,
    label: '预收款收入'
  }, {
    value: 14,
    label: '银行卡调入调出'
  }],
  // 科目类型
  courseType: [{
    value: 'office',
    label: '办公费用'
  }, {
    value: 'common',
    label: '常规'
  }],
  // 财务方向
  financeType: [{
    value: 'INCOME',
    label: '收入'
  }, {
    value: 'PAY',
    label: '支出'
  }],
  //票据类型 hotel-酒店 busCompany-车队 customer-客户 selfCompany-自费商家 transportCompany-大交通 insurance-保险 shop-购物店 restaurant-餐厅 visa-签证,可用值:TRANSPORT,BUS,SCENIC,HOTEL,INSURANCE,SHOP,RESTAURANT,CUSTOMER,VISA,SELF
  // 票据管理
  ticketType: ShuttleTypeEnum,
  // 签单类型
  signType: [{
    value: 'ALL',
    label: '全部'
  }, ...ShuttleTypeEnum],
  reportPlanType: [{
    value: null,
    label: '全部'
  }, {
    value: 'T_TOURIST',
    label: '团客'
  }, {
    value: 'S_TOURIST',
    label: '散客'
  }, {
    value: 'BOOKING',
    label: '预定'
  }, {
    value: 'C_TOURIST',
    label: '空位输单'
  }],
  guideReportType: [ALL, {
    value: 'guide',
    label: '导游现付'
  }, {
    value: 'guide_serv',
    label: '导服费'
  }, {
    value: 'guide_fund',
    label: '导游基金'
  }, {
    value: 'guide_tax',
    label: '导游税'
  }, {
    value: 'shop_comm',
    label: '购物佣金'
  }, {
    value: 'shop_guide',
    label: '购物导佣'
  }, {
    value: 'shop_driver',
    label: '购物司佣'
  }, {
    value: 'shop_acc',
    label: '购物陪佣'
  }, {
    value: 'comm_fund_tax',
    label: '佣金+导基+税'
  }],
  trafficType: [ALL, {
    value: '1',
    label: '汽车'
  }, {
    value: '2',
    label: '火车'
  }, {
    value: '3',
    label: '高铁'
  }, {
    value: '4',
    label: '飞机'
  }, {
    value: '5',
    label: '自驾车'
  }],
  fileStatus: [ALL, {
    value: 'file',
    label: '已归档'
  }, {
    value: 'unFile',
    label: '未归档'
  }],
  reviewerList: [{
    value: 'ROLE',
    label: '角色'
  }, {
    value: 'USER',
    label: '用户'
  }],
  orderAuditStatus: [ALL,
  // { value: 'UNCONFIRM', label: '未确认' },
  {
    value: 'waitAudit',
    label: '待审核'
  }, {
    value: 'opAudited',
    label: '操作计调已审'
  },
  // { value: 'guideManagerAudited', label: '导管已审' },
  // { value: 'generalManagerAudited', label: '经理已审' },
  {
    value: 'financialAudited',
    label: '财务已审'
  }
  // { value: 'bossAudited', label: 'BOSS已审' },
  ],
  cashFeeType: [ALL, {
    value: 'income',
    label: '收入'
  }, {
    value: 'pay',
    label: '支出'
  }],
  cashMode,
  cashModeType: [ALL, ...cashMode],
  isBalance: [ALL, {
    value: '1',
    label: '是'
  }, {
    value: '0',
    label: '否'
  }],
  reviewerStatus: [ALL,
  // { value: 'NEW_WAIT', label: '新增待审核' },
  {
    value: 'UN_PASS',
    label: '未通过'
  }, {
    value: 'PASS',
    label: '审核通过'
  }, {
    value: 'WAIT',
    label: '待审核'
  }],
  // 发团状态
  sendGroupStatus: [ALL_NEGATIVE, {
    value: 0,
    label: '未发'
  }, {
    value: 1,
    label: '已发'
  }],
  // 大团号配置规则
  planNumberAuto: [{
    value: 'yyyyMMdd',
    label: '日期'
  }, {
    value: 'PD',
    label: '产品'
  }, {
    value: 'CD',
    label: '客户'
  }],
  restaurantTypeList: [{
    label: '社会餐厅',
    value: '社会餐厅'
  }, {
    label: '团餐厅',
    value: '团餐厅'
  }],
  impressionTickeTypeList: [{
    label: 'C区普通席',
    value: 'C区普通席'
  }, {
    label: 'B1贵宾席',
    value: 'B1贵宾席'
  }, {
    label: 'B2贵宾席',
    value: 'B2贵宾席'
  }, {
    label: 'A2贵宾席',
    value: 'A2贵宾席'
  }, {
    label: 'A1总统席',
    value: 'A1总统席'
  }],
  riverTickeTypeList: [{
    label: '成人票',
    value: '成人票'
  }, {
    label: '优惠票',
    value: '优惠票'
  }, {
    label: '老人票',
    value: '老人票'
  }, {
    label: '市民票',
    value: '市民票'
  }],
  // 景区类型
  scenicType: [{
    value: 'PAID',
    label: '付费的'
  }, {
    value: 'FREE',
    label: '免费的'
  }],
  // 购物店类型
  shopType: [{
    value: 'SHOP',
    label: '购物店'
  }, {
    value: 'SPECIALTY',
    label: '土产店'
  }],
  // 票据状态
  signSheetStatus: [{
    value: 'all',
    label: '全部'
  }, {
    value: 'completed',
    label: '完成'
  }, {
    value: 'waitAudit',
    label: '待审核'
  }, {
    value: 'unArrange',
    label: '待安排'
  }],
  mallOrderStatus: [{
    value: 'WAIT_PAY',
    label: '待支付'
  }, {
    value: 'WAIT_IN_GROUP',
    label: '待拼团'
  }, {
    value: 'WAIT_TRAVEL',
    label: '待出行'
  }, {
    value: 'TRAVELLING',
    label: '出行中'
  }, {
    value: 'REFUND',
    label: '退款'
  }, {
    value: 'CANCEL',
    label: '取消'
  }],
  mallPayType: [{
    value: 'WECHAT',
    label: '微信'
  }, {
    value: 'ALIPAY',
    label: '支付宝'
  }, {
    value: 'UNIONPAY',
    label: '银联'
  }],
  gropTotal: [{
    value: 'GROUP',
    label: '汇总'
  }, {
    value: 'AREA',
    label: '明细(按市场)'
  }, {
    value: 'MANAGER',
    label: '明细(按业务员)'
  }, {
    value: 'CUSTOM',
    label: '明细(按组团社)'
  }],
  quotationStatus: [{
    value: '',
    label: '全部'
  }, {
    value: -1,
    label: '报价失败'
  }, {
    value: 0,
    label: '报价中'
  }, {
    value: 1,
    label: '报价成功'
  }],
  customerType: [{
    value: CustomerType.COMMON,
    label: '组团社'
  }, {
    value: CustomerType.OUT,
    label: '地接社'
  }, {
    value: CustomerType.COMBINATION,
    label: '组团社和地接社'
  }],
  hasShuttleNoFee: [ALL, {
    value: '1',
    label: '有录入费用'
  }, {
    value: '0',
    label: '无录入费用'
  }],
  behindhandSignStatus: [ALL, {
    value: '1',
    label: '拖欠'
  }, {
    value: '0',
    label: '未拖欠'
  }],
  confirmedItemFile: [ALL, {
    value: '1',
    label: '已上传'
  }, {
    value: '0',
    label: '未上传'
  }],
  eCodeEnableStatus: [ALL_NEGATIVE, {
    value: '1',
    label: '启用'
  }, {
    value: '0',
    label: '停用'
  }],
  bookingFee: [ALL, {
    value: '订票费',
    label: '订票费'
  }, {
    value: '手续费',
    label: '手续费'
  }, {
    value: '成人票',
    label: '成人票'
  }, {
    value: '儿童票',
    label: '儿童票'
  }],
  guideManagerType: [{
    value: 'orderGuideManageId',
    label: '按订单'
  }, {
    value: 'guideManageId',
    label: '按计划'
  }],
  cardTypeCountry,
  teamTypeCountry,
  businessTypeCountry,
  isLeaderTypeCountry,
  vehicleTypeCountry,
  guideTypeCountry,
  isTransitCountry: isLeaderTypeCountry,
  shipType,
  boatTicketType,
  boatFeeType,
  acceptStatus: [ALL, {
    value: 'WAIT',
    label: '待接收'
  }, {
    value: 'ACCEPT',
    label: '已接收'
  }, {
    value: 'REFUSE',
    label: '拒绝'
  }, {
    value: 'BACK',
    label: '退回'
  }],
  modifyStatus: [ALL, {
    value: '0',
    label: '未修改'
  }, {
    value: '1',
    label: '修改中'
  }],
  caseType: [{
    value: '优秀案例',
    label: '优秀案例'
  }, {
    value: '问题案例',
    label: '问题案例'
  }]
};
function findValue(type, val) {
  if (!val) return;
  const findItem = SELECTOR[type].find(it => it.value.toLowerCase() == val.toLowerCase());
  return findItem ? findItem.label : val;
}

// 获取下拉框的值
export const getSelectLabel = (type, val) => {
  if (val === '') return '';
  return findValue(type, val);
};

// 获取下拉框的值
export const getSelectAuditLabel = (type, val) => {
  return findValue(type, val);
};
export const getPayTypeLabel = val => {
  if (!val) return val;
  val = val && val.toLowerCase();
  return getSelectLabel('payType', val);
};