/*
 * @Author: cyg
 * @Date: 2023-08-18 11:43:34
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-29 14:48:42
 * @FilePath: \travel-admin\src\views\offer\offerMixin.js
 */
// import handleClipboard from '@/utils/clipboard'
// import Clipboard from 'clipboard'
import { decimalSplit } from '@/utils';
export default {
  components: {},
  props: {
    adultCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteRow(scope) {
      const {
        $index: index
      } = scope;
      // this.$bus.open(() => {
      this.tableData.splice(index, 1);
      // })
    },
    changeInput(row) {
      const {
        price,
        count
      } = row;
      row.cost = price * count / this.adultCount;
      this.sumTotal();
    },
    sumTotal() {
      this.total = 0;
      if (!this.tableData.length) return;
      this.tableData.forEach(it => {
        this.total += Number(it.cost);
      });
      this.total = decimalSplit(this.total);
      this.$parent.sumTotal();
    },
    // 获取报价详情
    fetchOfferId(queryParam) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('offer/index/fetchOfferId', queryParam).then(data => {
          return resolve(data);
        }).catch(err => {
          return reject(err);
        });
      });
    },
    formatOfferCode(data) {
      // 景区
      let scenicDetail = data.scenicDetails.map(it => {
        return ` ${it.name} ${it.price} `;
      });
      scenicDetail = ` 景：${scenicDetail.join('+')}=${data.scenicMoney}/人\n`;
      // 房
      let hotelDetail = data.hotelDetails.map(it => {
        return ` ${it.area} ${it.price}*${it.count}/${data.adultCount} `;
      });
      hotelDetail = ` 房: ${hotelDetail.join('+')}=${data.hotelMoney}/人\n`;

      // 导游
      let guideDetail = data.guideDetails.map(it => {
        return ` ${it.price} * ${it.count} / ${data.adultCount}`;
      });
      guideDetail = `导游：${guideDetail.join('+')}=${data.guideMoney}/人\n`;

      // 餐厅
      let restaurantDetail = data.restaurantDetails.map(it => {
        return ` ${it.price} * ${it.count} / ${data.adultCount}`;
      });
      restaurantDetail = `餐：${restaurantDetail.join('+')}=${data.restaurantMoney}/人\n`;

      // 车
      let busDetail = data.busDetails.map(it => {
        return ` ${it.price} * ${it.count} / ${data.adultCount}`;
      });
      busDetail = `车：${busDetail.join('+')}=${data.busMoney}/人\n`;
      const codeText = scenicDetail + hotelDetail + restaurantDetail + busDetail + guideDetail + `操作费：${data.opPrice}/人}\n` + `地接：${data.totalMoney}\n\n\n` + `景：${data.scenicRemarks}\n` + `导游：${data.guideRemarks}\n` + `餐：${data.restaurantRemarks}\n` + `房：${data.hotelRemarks}\n` + `车：${data.busRemarks}\n` + `购物：${data.shopRemarks}\n\n\n` + `参考酒店：\n ${data.hotelRefer}`;

      // handleClipboard(codeText, event)
      // const loading = this.$bus.loading()
      // setTimeout(function () {
      //   loading.close()
      // }, 1000)
      this.fetchCopyText(codeText);
      return codeText;
    },
    fetchCopyText(copy_text) {
      let textarea_dom = document.createElement('textarea'); //创建textarea元素
      document.body.appendChild(textarea_dom); //向页面底部追加输入框
      textarea_dom.value = copy_text; //添加需要复制的内容到value属性
      textarea_dom.select(); //选择input元素
      document.execCommand('Copy'); //执行复制命令
      // alert('复制成功！') //弹出提示信息，不同组件可能存在写法不同
      this.$bus.tip({
        message: '复制成功',
        type: 'success'
      });
      //复制之后再删除元素，否则无法成功赋值
      document.body.removeChild(textarea_dom); //删除动态创建的节点
    }
  }
};