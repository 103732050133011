import "core-js/modules/es.array.push.js";
// import { mapGetters } from 'vuex'
import { BtnExport, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import { lineAnalyze } from '@/config/analysis';
import { mergeTableRow } from '@/utils/table';
import analysisMixin from '../analysisMixin';
export default {
  components: {
    TableList,
    BtnExport,
    InputMonthRange
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: lineAnalyze
    };
  },
  computed: {
    lineData() {
      return this.$store.getters['analyse/index/lineData'];
    }
  },
  watch: {
    lineData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = this.formatData(data);
      this.tableData = mergeTableRow(this.tableData, ['groupType']);
    },
    fetchData(queryParams) {
      this.isVisible = false;
      this.$store.dispatch('analyse/index/fetchProduct', queryParams);
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach((it, index) => {
        const itemList = it.productTypes;
        if (itemList.length) {
          itemList.forEach(v => {
            result.push({
              ...it,
              ...v,
              mergeId: index
            });
          });
        } else {
          result.push({
            ...it,
            mergeId: index
          });
        }
      });
      return result;
    }
  }
};