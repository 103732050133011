import "core-js/modules/es.array.push.js";
import { TripNumber } from '@/components/common';
import { StatusAudit } from '@/components/common';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin } from '@/mixin';
import { getPayTypeLabel } from '@/config/select';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin],
  data() {
    return {
      tableData: [],
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId'
    })
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    fetchData() {
      this.$store.dispatch('plan/otherPay/fetch', {
        planId: this.currentPlanId
      }).then(data => {
        this.initData(data);
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          rowId: id,
          planOtherPayId: id,
          ap: auditProgress,
          auditProgress,
          cashType: getPayTypeLabel(it.cashType),
          planList: this.planList
        };
        result.push(item);
        // 同步id
        this.rowId++;
      });
      return result;
    }
  }
};