/* eslint-disable no-unused-vars */
import qs from 'qs';
import request from '@/utils/request';
import { parseQuerys } from '@/utils';

// 钉钉登陆
export const DDLogin = ({
  code,
  travelAgencyId,
  phone
}) => {
  let url = 'uaa/oauth/token?grant_type=ding_scan&client_id=erp&client_secret=123456&auth_code=' + code;
  url = travelAgencyId ? url + '&travelAgencyId=' + travelAgencyId : url;
  url = phone ? url + '&phone=' + phone : url;
  return request({
    url,
    method: 'post'
  });
};

// 图形校验码
export const getVerifyCode = ({
  mobilePhone,
  type = ''
}) => {
  return request({
    url: 'uaa/flushVisitCode',
    method: 'get',
    params: {
      mobilePhone,
      type
    }
  });
};

// 短信验证码
export const getSmsCode = ({
  mobilePhone,
  type = '',
  visitCode = ''
}) => {
  return request({
    url: 'uaa/sendSMS',
    method: 'post',
    params: {
      mobilePhone,
      type,
      visitCode
    }
  });
};

// 短信登陆
export const loginSms = data => {
  return request({
    url: 'uaa/oauth/token',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  });
};

/**
 *
 * @export
 * @description 用户登陆接口
 * @param {Object} data 用户登陆名与密码
 * @returns {Object} 登陆成功后的用户信息
 */
export function login(data) {
  return request({
    url: '/uaa/oauth/token',
    method: 'post',
    data: qs.stringify(qs.parse(data))
  });
}

// TODO： 退出
export function logout() {
  return request({
    url: '/uaa/tokens/revoke',
    method: 'get'
  });
}

// 创建用户
export function createOrUpdate(data) {
  return request({
    url: '/system/user/saveOrUpdate',
    method: 'post',
    data
  });
}

// 创建用户
export function updateInfo(data) {
  return request({
    url: '/system/user/updateInfo',
    method: 'post',
    data
  });
}

// 获取用户列表 -- 带分页
export function fetchUser(params) {
  const url = '/system/user/page';
  return request({
    url,
    method: 'post',
    params
  });
}

// 获取用户列表 -- 不分页
export function fetchUserList(params) {
  const url = parseQuerys('/system/user/findList', params);
  return request({
    url,
    method: 'post'
  });
}

// 获取用户信息
export function fetchUserInfo(userId) {
  const url = '/system/user/getUser';
  return request({
    url,
    method: 'post',
    params: {
      userId
    }
  });
}

// 删除用户
export function delelteUser(id) {
  return request({
    url: '/system/user/del',
    method: 'post',
    data: {
      userId: id
    }
  });
}

// 更新用户
export function updateUser(data) {
  return request({
    url: '/system/user/updateInfo',
    method: 'post',
    data
  });
}

// 更新密码
export function updatePwd(data) {
  return request({
    url: '/system/user/updatePassword',
    method: 'post',
    data
  });
}

// 微信登录
export function getWxLogin(params) {
  return request({
    url: '/uaa/oauth/token',
    method: 'get',
    params
  });
}

// 用户中心绑定微信
export function bindWechat(data) {
  return request({
    url: '/system/user/bind-wx',
    method: 'post',
    data
  });
}
// 用户中心解除绑定微信
export function unbindWechat(data) {
  return request({
    url: '/system/user/unbind-wx',
    method: 'post',
    data
  });
}

// 系统主题切换
export function updateVersionSwitch(data) {
  return request({
    url: '/system/user/update-theme',
    method: 'post',
    data
  });
}

// 查询集团用户
export function getGroupUserInSaasRequest(params) {
  return request({
    url: '/system/user/loginTripOfGroupUsers',
    method: 'get',
    params
  });
}

// 设置集团用户在地接的角色
export function setGroupRoleInSaasRequest(data) {
  return request({
    url: '/system/user/updateGroupUserTripRole',
    method: 'post',
    data
  });
}

// 根据编码获取终端 /terminal/terminal-code
export function settingTerminalCodeRequest(params) {
  return request({
    url: '/system/terminal/terminal-code',
    method: 'get',
    params
  });
}

// 获取终端系统列表
export function settingTerminalListRequest(params) {
  return request({
    url: '/system/terminal/list',
    method: 'get',
    params
  });
}