import "core-js/modules/es.array.push.js";
import { TripNumber } from '@/components/common';
import { planEditFleet, ShuttleType } from '@/config/plan';
import { StatusAudit } from '@/components/common';
import { mapGetters } from 'vuex';
import { rowspanMixin, auditPropMixin, dictionaryMixin } from '@/mixin';
import { getPayTypeLabel } from '@/config/select';
export default {
  components: {
    TripNumber,
    StatusAudit
  },
  mixins: [rowspanMixin, auditPropMixin, dictionaryMixin],
  data() {
    return {
      tableData: [],
      headMergeNum: 3,
      tailMergeNum: planEditFleet.length - 4,
      hasMergeTail: true,
      rowId: 1000000000000
    };
  },
  computed: {
    ...mapGetters({
      currentPlanId: 'plan/index/currentPlanId'
    })
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.resetData();
    },
    fetchData(queryParams = {}) {
      this.$store.dispatch('plan/fleet/fetch', {
        planId: this.currentPlanId,
        shuttleType: ShuttleType.bus,
        ...queryParams
      }).then(data => {
        this.initData(data);
      });
    },
    reloadData() {
      const id = this.currentPlanId;
      this.fetchData();
      this.$store.dispatch('plan/index/groupProfit', id);
    },
    fetchDriver(busCompanyId) {
      return this.$store.dispatch('resource/driver/fetch', {
        busCompanyId
      }).then(data => {
        return data;
      });
    },
    fetchBus(busCompanyId) {
      return this.$store.dispatch('resource/vehicle/fetch', {
        busCompanyId
      }).then(data => {
        return data;
      });
    },
    handleBill(id) {
      let routeUrl = this.$router.resolve({
        path: '/print-driver',
        query: {
          id,
          itemId: this.currentPlanId,
          type: 'plan',
          component: 'Driver'
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        busFee,
        otherFee
      } = obj;
      let total = Number(busFee || 0) + Number(otherFee || 0);
      return total;
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      const handleFn = it => {
        const itemList = it.respOrderBusCompanyTypeSimples;
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...it,
          ap: auditProgress,
          auditProgress,
          rowId: id,
          planFleetId: id,
          payType: getPayTypeLabel(it.payType),
          driverName: '',
          licenceNumber: ''
        };
        if (itemList && itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              busType: v.busType || '',
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            rowId: it.id,
            itemIndex: 0
          });
        }

        // 同步id
        this.rowId++;
      };
      list.forEach(it => {
        handleFn(it);
      });
      result.map(it => {
        this.$set(it, 'totalPay', this.calcRowTotalMoney(it));
      });
      return result;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        this.$set(it, 'totalPay', totalPay);
      });
    },
    // 重置表格与价格计算
    resetData() {
      this.getTotalPayMoney();
      this.rowspan();
      this.mergeRowMoney(); // 合并单条数据总价
    }
  }
};