var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TouristAge", {
    attrs: {
      data: _vm.tableData
    }
  }), _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "cardType",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getCardType(scope.row.cardType)) + " ")];
      }
    }, {
      key: "seqNumber",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "sex",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.sex ? "女" : "男") + " ")];
      }
    }, {
      key: "age",
      fn: function ({
        scope
      }) {
        return [_c("span", {
          domProps: {
            innerHTML: _vm._s(scope.row.age)
          }
        })];
      }
    }, {
      key: "isContact",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.isContact ? "是" : "否") + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };