var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "选择产品",
      width: "960px",
      visible: _vm.visible,
      "append-to-body": true,
      "has-foot": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "input-row"
  }, [_c("InputBase", {
    attrs: {
      label: "线路名称"
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  }), _c("div", {
    staticClass: "btn-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "search"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 搜索 ")])], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("TableBase", {
    attrs: {
      align: "left",
      dynamic: false,
      data: _vm.tableData,
      "column-data": _vm.columnData
    },
    on: {
      "row-click": _vm.handleSelect
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        scope
      }) {
        return [scope.row.isEnable ? _c("el-button", {
          attrs: {
            type: "text"
          }
        }, [_vm._v(" 选择 ")]) : _c("el-button", {
          attrs: {
            type: "text",
            disabled: ""
          }
        }, [_vm._v(" 未启用 ")])];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };