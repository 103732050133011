import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 资源管理

import Layout from '@/layout';
const resourceRouter = {
  path: '/resource',
  component: Layout,
  redirect: '/resource/guide',
  name: 'resource',
  meta: {
    title: 'resource',
    icon: 'icon-fund'
  },
  children: [{
    path: '/resource/guide',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/guide'))),
    name: 'resourceGuide',
    // 导游管理
    meta: {
      title: 'resourceGuide',
      icon: 'icon-shenqingdaoyou-01'
    }
  }, {
    path: '/resource/guide-manager',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/guideManager'))),
    name: 'resourceGuideManager',
    // 导管
    meta: {
      title: 'resourceGuideManager',
      icon: 'icon-jiaoseguanli'
    }
  }, {
    path: '/resource/guide-captain',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/guideCaptain'))),
    name: 'resourceGuideCaptain',
    // 导游组长
    meta: {
      title: 'resourceGuideCaptain',
      icon: 'icon-renyuanguanli'
    }
  }, {
    path: '/resource/fleet',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/fleet'))),
    name: 'resourceFleet',
    // 车队管理
    meta: {
      title: 'resourceFleet',
      icon: 'icon-lvyoubaochedingdan'
    }
  }, {
    path: '/resource/driver',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/driver'))),
    name: 'resourceDriver',
    // 司机
    meta: {
      title: 'resourceDriver',
      icon: 'icon-chengyunshangguanli',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/vehicle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/vehicle'))),
    name: 'resourceVehicle',
    // 车辆
    meta: {
      title: 'resourceVehicle',
      icon: 'icon-lvyoubaochedingdan',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/hotel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/hotel/index'))),
    name: 'resourceHotel',
    // 酒店管理
    meta: {
      title: 'resourceHotel',
      icon: 'icon-changguanyuyue'
    },
    orderNumber: 3
  }, {
    path: '/resource/hotel-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/hotel/hotelCreate'))),
    name: 'resourceHotelCreate',
    // 新增酒店
    meta: {
      title: 'resourceHotelCreate',
      icon: 'icon-shenqingdaoyou-01',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/hotel-edit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/hotel/hotelCreate'))),
    name: 'resourceHotelEdit',
    // 编辑酒店
    meta: {
      title: 'resourceHotelEdit',
      icon: 'icon-shenqingdaoyou-01',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/hotel-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/hotelDetail'))),
    name: 'resourceHotelDetail',
    // 酒店详情
    meta: {
      title: 'resourceHotelDetail',
      icon: 'icon-shenqingdaoyou-01',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/insurance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/insurance'))),
    name: 'resourceInsurance',
    // 保险管理
    meta: {
      title: 'resourceInsurance',
      icon: 'icon-insurance'
    },
    orderNumber: 4
  }, {
    path: '/resource/scenic',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/scenic'))),
    name: 'resourceScenic',
    // 景区管理
    meta: {
      title: 'resourceScenic',
      icon: 'icon-lvyoufabu'
    },
    orderNumber: 5
  }, {
    path: '/resource/restaurant',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/restaurant'))),
    name: 'resourceRestaurant',
    // 餐厅管理
    meta: {
      title: 'resourceRestaurant',
      icon: 'icon-canting'
    },
    orderNumber: 6
  }, {
    path: '/resource/shop',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/shopping'))),
    name: 'resourceShop',
    // 购物店管理
    meta: {
      title: 'resourceShop',
      icon: 'icon-wentichaoshi'
    }
  }, {
    path: '/resource/traffic',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/traffic'))),
    name: 'resourceTraffic',
    // 交通管理
    meta: {
      title: 'resourceTraffic',
      icon: 'icon-jiejifuwu'
    }
  }, {
    path: '/resource/line',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/line'))),
    name: 'resourceLine',
    // 线路管理
    meta: {
      title: 'resourceLine',
      icon: 'icon-jizhongkongzhitai'
    }
  }, {
    path: '/resource/product',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/product'))),
    name: 'resourceProduct',
    // 产品管理
    meta: {
      title: 'resourceProduct',
      icon: 'icon-tag'
    }
  }, {
    path: '/resource/product-clone',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/productCreate'))),
    name: 'resourceProductClone',
    // 产品复制
    meta: {
      title: 'resourceProductClone',
      icon: 'icon-tag',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/product-create',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/productCreate'))),
    name: 'resourceProductCreate',
    // 产品添加
    meta: {
      title: 'resourceProductCreate',
      icon: 'icon-tag',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/product-update',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/productCreate'))),
    name: 'resourceProductUpdate',
    // 产品更新
    meta: {
      title: 'resourceProductUpdate',
      icon: 'icon-tag',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/product-preview',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/product/preview'))),
    name: 'resourceProductPreview',
    // 产品预览
    meta: {
      title: 'resourceProductPreview',
      icon: 'icon-tag',
      auth: true
    },
    hidden: true
  }, {
    path: '/resource/custom',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/custom'))),
    name: 'resourceCustom',
    // 客户管理
    meta: {
      title: 'resourceCustom',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/resource/company',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/company'))),
    name: 'resourceCompany',
    // 公司管理
    meta: {
      title: 'resourceCompany',
      icon: 'icon-gongsiguanli'
    }
  }, {
    path: '/resource/other',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/other'))),
    name: 'resourceOther',
    // 其他单位
    meta: {
      title: 'resourceOther',
      icon: 'icon-gongsiguanli'
    }
  }, {
    path: '/resource/group',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resource/group'))),
    name: 'resourceGroup',
    // 集团管理
    meta: {
      title: 'resourceGroup',
      icon: 'icon-zuzhixinxi'
    }
  }]
};
export default resourceRouter;