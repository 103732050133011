import "core-js/modules/es.array.push.js";
import TableList from './TableList';
import SearchForm from '../SearchForm';
import { Columns as columns } from './tableConfig';
import { tagViewMixin, excelMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { getPayTypeLabel } from '@/config/select';
import { downloadExport } from '@/utils/exportExcel';
export default {
  components: {
    TableList,
    SearchForm
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: columns,
      total: 0,
      statistics: null,
      filename: '景区对账'
    };
  },
  computed: {
    scenicData() {
      return this.$store.getters['bill/billCheck/scenic'];
    }
  },
  watch: {
    scenicData: {
      handler: function (data) {
        if (!data) {
          this.fetchData({
            startTripDateS: this.getToday
          });
        } else {
          this.handleData(data);
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(queryParam) {
      this.total = 0;
      return this.$store.dispatch('bill/billCheck/fetchScenic', queryParam);
    },
    // reload() {
    //   const { searchParams } = this.$refs.searchRef
    //   this.fetchData(searchParams)
    // },

    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = this.formatData(list);
      this.total = totalCount;
      this.statistics = statistics;
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          checkAccountMoney,
          checkAccountStatus,
          totalSettlement
        } = it;
        const item = {
          ...it,
          ...it.plan,
          rowId: it.id,
          cashType: getPayTypeLabel(it.cashType),
          checkAccountMoney: checkAccountStatus ? checkAccountMoney : totalSettlement
        };
        this.$set(item, 'ckStatus', false);
        if (it.scenicTickets.length) {
          it.scenicTickets.forEach(s => {
            delete s.id;
            const {
              accompanyCount: accompanyCountItem,
              adultCount: adultCountItem,
              childCount: childCountItem
            } = s;
            result.push({
              ...s,
              ...item,
              accompanyCountItem,
              adultCountItem,
              childCountItem
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    exporttExcel(searchParams) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportScenicExcel', searchParams).then(res => {
        const fileName = '景区对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};