export const Columns = [{
  attrs: {
    label: '部门',
    prop: 'name'
  }
}, {
  attrs: {
    label: '年份',
    prop: 'year'
  }
}, {
  attrs: {
    label: '项目',
    prop: 'type'
  }
}, {
  attrs: {
    prop: 'total',
    label: '合计'
  }
}, {
  attrs: {
    prop: 'jan',
    label: '1月'
  }
}, {
  attrs: {
    prop: 'feb',
    label: '2月'
  }
}, {
  attrs: {
    prop: 'mar',
    label: '3月'
  }
}, {
  attrs: {
    prop: 'apr',
    label: '4月'
  }
}, {
  attrs: {
    prop: 'may',
    label: '5月'
  }
}, {
  attrs: {
    prop: 'jun',
    label: '6月'
  }
}, {
  attrs: {
    prop: 'jul',
    label: '7月'
  }
}, {
  attrs: {
    prop: 'aug',
    label: '8月'
  }
}, {
  attrs: {
    prop: 'sep',
    label: '9月'
  }
}, {
  attrs: {
    prop: 'oct',
    label: '10月'
  }
}, {
  attrs: {
    prop: 'nov',
    label: '11月'
  }
}, {
  attrs: {
    prop: 'dece',
    label: '12月'
  }
}];