import { Shopping } from '@/components/common/Order';
import { planEditShopping, planEditShoppingOld } from '@/config/plan';
import { systemMixin } from '@/mixin';
export default {
  components: {
    Shopping
  },
  mixins: [systemMixin],
  data() {
    return {
      visibility: false,
      columnData: []
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/userInfo'];
    }
  },
  watch: {
    userInfo: {
      handler: function (data) {
        if (!data) {
          return;
        }
        if (data.theme) {
          const {
            switchVersion
          } = data.theme;
          this.versionSwitch = switchVersion;
        }
      },
      immediate: true
    },
    versionSwitch: {
      handler: function handler(state) {
        this.visibility = false;
        this.columnData = state ? planEditShopping : planEditShoppingOld;

        // 是否显示订单信息
        const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
        if (!isOrderInfo) {
          this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
        }
        setTimeout(() => {
          this.visibility = true;
        }, 500);
      },
      immediate: true
    }
  },
  methods: {
    // 校验表单数据
    validateForm() {
      return this.$refs.itemRef.validateForm();
    },
    // 校验表单数据
    createOrUpdate() {
      return this.$refs.itemRef.createOrUpdate();
    }
  }
};