import { orderInfo, tripDate, orderArrangeRemark, orderOther, orderTotalPay, guide, payed, unPay, createTime, actionItem } from './index';
const type = {
  attrs: {
    prop: 'insuranceType',
    label: '类别',
    'min-width': 80
  }
};
const lastItem = [orderArrangeRemark, {
  attrs: {
    prop: 'freeTotal',
    label: '优惠总额'
  }
}, orderOther, orderTotalPay, payed, unPay, createTime, actionItem];

// 按团号去付款 保险
export const InsuranceColumn = [orderInfo, tripDate, {
  attrs: {
    prop: 'insuranceName',
    label: '保险',
    'min-width': 100
  }
}, type, guide, {
  attrs: {
    prop: 'price',
    label: '价格'
  }
}, {
  attrs: {
    prop: 'freePrice',
    label: '优惠单价'
  }
}, {
  attrs: {
    prop: 'count',
    label: '数量'
  }
}, {
  attrs: {
    prop: 'orderDate',
    label: '订单时间'
  }
}, ...lastItem];