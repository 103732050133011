import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/restaurant/fetchList'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/restaurant/restaurantData'];
    },
    list() {
      if (this.$attrs.multiple) {
        return this.getData;
      }
      return this.getData ? this.getData.slice(0, this.page.pageNum * this.page.pageSize) : [];
    }
  }
};