/**
 * @module components/base/Table
 * @desc table组件
 * @vue-prop {Object} columnData - 每一列的标题与参数
 */
export default {
  name: 'TableBase2',
  inheritAttrs: false,
  props: {
    columnData: {
      type: Array,
      required: true
    },
    align: {
      type: String,
      default: 'center'
    },
    headerStyle: {
      type: Object,
      default() {
        return {
          background: '#f9f9f9'
        };
      }
    },
    showEmpty: {
      type: Boolean,
      default: true
    }
  }
};