import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import html2canvas from 'html2canvas';
// import * as clipboard from 'clipboard-polyfill'
import { offerHotelDetail, offerScenicDetail, offerRestaurantDetail } from '@/config/offer';
export default {
  components: {},
  data() {
    return {
      offerHotelDetail,
      offerScenicDetail,
      offerRestaurantDetail,
      form: {},
      visible: false,
      title: ''
    };
  },
  methods: {
    show(params) {
      this.fetchOfferId(params);
    },
    hide() {
      this.visible = false;
    },
    fetchOfferId(queryParam) {
      this.$store.dispatch('offer/index/fetchOfferId', queryParam).then(data => {
        this.visible = true;
        this.form = data;
        this.form.hotelDetails = data.hotelDetails.map(it => {
          it.cost = this.calculateCost(it, data.adultCount);
          return it;
        });
        this.form.guideDetails = data.guideDetails.map(it => {
          it.cost = this.calculateCost(it, data.adultCount);
          return it;
        });
        this.form.restaurantDetails = data.restaurantDetails.map(it => {
          it.cost = this.calculateCost(it, data.adultCount);
          return it;
        });
        this.form.busDetails = data.busDetails.map(it => {
          it.cost = this.calculateCost(it, data.adultCount);
          return it;
        });
      });
    },
    calculateCost(row, adultCount) {
      const cost = row.price * row.count / adultCount;
      return cost;
    },
    onChecksLink(type) {
      const element = document.getElementById('share-element'); // 替换为要截屏的元素的ID或选择器
      const canvasHtml = document.getElementById('canvas'); // 替换为要截屏的元素的ID或选择器
      html2canvas(element).then(canvas => {
        canvasHtml.appendChild(canvas); // 直接使用canvas
        const imgURL = canvas.toDataURL('image/png', 1); // 此方法可以设置截图质量0-1
        // const imgURL = canvas.toDataURL()

        if (type == 'share') {
          this.getClipboard(imgURL);
          return;
          // // 复制图片 URL 到剪贴板
          // navigator.clipboard.writeText(imgURL).then(
          //   function () {
          //     alert(
          //       '截图已复制到剪贴板，您可以粘贴到QQ、WeChat等通讯软件中发送给好友。'
          //     )
          //   },
          //   function (err) {
          //     console.error('复制失败：', err)
          //   }
          // )
          // canvas.toBlob(blob => {
          //   // 将blob对象放入剪切板item中
          //   // eslint-disable-next-line no-undef
          //   const data = [new ClipboardItem({ [blob.type]: blob })]
          //   // 写入剪切板,成功会执行resolve,失败则走reject
          //   navigator.clipboard.write(data).then(
          //     () => {
          //       this.$message({ message: '截图成功！', type: 'success' })
          //     },
          //     () => {
          //       this.$message({ message: '截图失败', type: 'warning' })
          //     }
          //   )
          // }, 'image/png')
          // this.getClipboard(imgURL)
        }
        this.downloadImage(imgURL);
      });
    },
    getClipboard(base64Data) {
      let blob = null;
      const format = 'image/png';
      //将Base64图片编码转换成blob
      const base64 = base64Data;
      const code = window.atob(base64.split(',')[1]);
      const aBuffer = new window.ArrayBuffer(code.length);
      const uBuffer = new window.Uint8Array(aBuffer);
      for (let i = 0; i < code.length; i++) {
        uBuffer[i] = code.charCodeAt(i) & 0xff;
      }
      try {
        blob = new Blob([uBuffer], {
          type: format
        });
      } catch (e) {
        window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
        if (e.name == 'TypeError' && window.BlobBuilder) {
          const bb = new window.BlobBuilder();
          bb.append(uBuffer.buffer);
          blob = bb.getBlob('image/png');
        } else if (e.name == 'InvalidStateError') {
          blob = new Blob([aBuffer], {
            type: format
          });
        }
      }
      navigator.clipboard.write([
      // eslint-disable-next-line no-undef
      new ClipboardItem({
        'image/png': blob
      })]).then(() => {
        console.log('成功写入剪贴板');
        this.$bus.tip({
          message: '截图已复制到剪贴板，您可以粘贴到QQ、微信等通讯软件中发送给好友。'
        });
      }).catch(function (error) {
        console.error('写入剪贴板时发生错误：', error);
      });

      // 将创建ClipboardItem并放入blob
      // eslint-disable-next-line no-undef
      // const item = new ClipboardItem({ 'image/png': blob })
      // //设置对象到粘贴板
      // navigator.clipboard.write([item])
      // this.$bus.tip({
      //   message:
      //     '截图已复制到剪贴板，您可以粘贴到QQ、微信等通讯软件中发送给好友。',
      // })
    },
    //下载图片
    downloadImage(url) {
      const loading = this.$bus.loading();

      // 如果是在网页中可以直接创建一个 a 标签直接下载
      let link = document.createElement('a');
      link.href = url;
      link.download = '报价截图';
      // 将 <a> 元素添加到页面并触发点击下载图片
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$bus.tip({
        message: '下载截图成功'
      });
      loading.close();
    }
  }
};