import "core-js/modules/es.array.push.js";
import echarts from 'echarts';
import Policy from './Policy';
import { summaryMixin, permissionMixin } from '@/mixin';
import { parseTime, getAuthByPath, getUserData } from '@/utils';
import { TodoConfig } from '../config';
import { mapGetters } from 'vuex';
export default {
  components: {
    Policy
  },
  mixins: [summaryMixin, permissionMixin],
  data() {
    return {
      statisticsKeys: [],
      roleMenuOpts: [],
      backlogItem: [],
      count: 0
    };
  },
  computed: {
    ...mapGetters({
      routes: 'permission/routes',
      rolePermissionList: 'permission/rolePermissionList'
    })
  },
  created() {
    // 注册必须的组件
    this.fetchData();
    this.fetchGuideManagerUnArrange();
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('plan/index/fetchTripMainCount').then(data => {
        if (!data) return;
        this.setTodoList(data);
        this.setDrawLineData(data);
      });
    },
    // 搜索类型，1-查询所有未安排导管的数据，2-查询订单指定了登陆者为导管的 且 未安排导管的 计划数量,默认 1
    fetchGuideManagerUnArrange() {
      this.$store.dispatch('plan/index/guideManagerUnArrange', {
        searchType: 2
      }).then(data => {
        this.count = data || 0;
      });
    },
    setTodoList(data) {
      const {
        roleMenuOpts
      } = getAuthByPath(this.$store, '/dashboard');
      const authMenu = roleMenuOpts.filter(it => it.isRole);
      this.backlogItem = authMenu.map(it => {
        const key = it.code;
        const value = data[key];
        if (value != undefined) {
          return {
            ...it,
            value
          };
        }
      }).filter(it => it != undefined);
    },
    setDrawLineData(data) {
      const xAxisData = [];
      const seriesData = [];
      // 日期从小到大排序
      const list = data.memberEveryDayCounts.sort((a, b) => {
        return new Date(a.day).getTime() - new Date(b.day).getTime();
      });
      list.forEach(it => {
        const day = parseTime(it.day, '{m}-{d}');
        xAxisData.push(day);
        seriesData.push(it.adultCount);
      });
      this.$nextTick(() => {
        this.drawLine(xAxisData, seriesData);
      });
    },
    drawLine(xAxisData, seriesData) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('chart'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['收客人数']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '成人',
          data: seriesData,
          type: 'line',
          smooth: true
        }]
      });
    },
    onRedirects(item) {
      const {
        code
      } = item;
      const {
        path,
        query
      } = TodoConfig[code];
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search: query
      });
      this.$router.push({
        path,
        query
      });
    },
    onRedirectPlan() {
      const path = '/plan/team-arrange';
      const {
        id
      } = getUserData();
      const query = {
        orderGuideManageId: id
      };
      this.$store.dispatch('tagsView/appendVisiteViewSearch', {
        path,
        search: query
      });
      this.$router.push({
        path,
        query
      });
    }
  }
};