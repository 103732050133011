export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    getRowCheckType() {
      return this.row.checkAccountStatus ? 'success' : 'primary';
    },
    getRowCheckStatus() {
      return this.row.checkAccountStatus ? '已对' : '未对';
    }
  }
};