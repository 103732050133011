import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import { ColumnsBoatName as Columns, mergeKeys } from '../tableConfig';
import { defaultBoatType, defaultBoatTicketType, TouristObj } from '../config';
import { PersonCount } from '@/components/common';
import { planBoatMembersRequest } from '@/api/plan';
import { createOrUpdateTourist2 } from '@/api/order';
import { mergeTableRow, formatPeopleValue, getCardTypeLabel } from '@/utils';
const AgeMap = {
  adult: 15,
  half: 14,
  fee: 7
};
export default {
  components: {
    PersonCount
  },
  data() {
    return {
      Columns,
      visible: false,
      tableData: []
    };
  },
  methods: {
    fetchBoatMembers(params) {
      planBoatMembersRequest(params).then(res => {
        if (!res || !res.data) return;
        const list = this.formatData(res.data);

        // 分组
        const groupList = this.formatGroup(list);
        // 合并
        this.tableData = mergeTableRow(groupList, mergeKeys);
      });
    },
    formatGroup(data) {
      const mapObj = data.reduce((acc, cur) => {
        const key = cur.customerTripNumber;
        const val = acc[key];
        if (!val) {
          acc[key] = [];
        }
        acc[key].push(cur);
        return acc;
      }, {});
      return Object.keys(mapObj).reduce((acc, key, index) => {
        const list = mapObj[key];
        const listIndex = list.map(it => {
          return {
            ...defaultBoatType,
            ...defaultBoatTicketType,
            ...it,
            orderNumber: index + 1,
            mergeId: it.customerOrderId
          };
        });
        return [...acc, ...listIndex];
      }, []);
    },
    formatData(data) {
      return data.map(it => {
        const {
          cardType,
          age
        } = it;
        const newAge = age;
        it.isKid = newAge < AgeMap.fee;
        it.isHalf = newAge >= AgeMap.fee && newAge < AgeMap.adult;
        it.isAdult = newAge > AgeMap.half;
        it.status = '否';
        it.ticket = it.isKid ? '免票' : it.isAdult ? '全票' : '半票';
        it.fee = it.isKid ? '免票' : '';
        it.country = cardType != 1 ? '中国' : '未知';
        it.age = newAge;
        it.peopleCount = formatPeopleValue(it);
        it.$cardType = getCardTypeLabel(it.cardType);
        // 默认下舱
        it.shipTicketType = it.shipTicketType || '下舱';
        return it;
      });
    },
    batchChangeVal(type, customerOrderId, val) {
      this.tableData = this.tableData.map(it => {
        if (it.customerOrderId == customerOrderId) {
          it[type] = val.label;
        }
        return it;
      });
    },
    selectShipType(customerOrderId, val) {
      this.batchChangeVal(customerOrderId, val);
    },
    show(row) {
      this.visible = true;
      this.fetchBoatMembers({
        id: row.id
      });
    },
    hide() {
      this.visible = false;
      this.tableData = [];
    },
    pickerData(data) {
      return data.map(it => {
        return Object.keys(TouristObj).reduce((acc, key) => {
          acc[key] = it[key];
          return acc;
        }, {});
      });
    },
    handleSave() {
      const optList = this.pickerData(this.tableData);
      createOrUpdateTourist2(optList).then(() => {
        this.$bus.tip();
        this.hide();
      });
    }
  }
};