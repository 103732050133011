import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/product/findList',
    method: 'get',
    params
  });
}

// 列表
export function fetchProduct(params) {
  return request({
    url: '/resource/product/pageNoAuthority',
    method: 'get',
    params
  });
}

// 根据 产品id 查询产品行程
export function fetchProductItinerary(id) {
  return request({
    url: '/resource/product/findItineraryById',
    method: 'get',
    params: {
      id
    },
    headers: {
      menuId: -1
    }
  });
}

// 产品详情
export function fetchProductDetail(id) {
  return request({
    url: '/resource/product/id',
    method: 'get',
    params: {
      id
    }
  });
}

// 添加或更新
export function createOrUpdateProduct(data) {
  return request({
    url: '/resource/product/save',
    method: 'post',
    data
  });
}

// 设置产品 启用/禁用 状态
export function setStatusProduct(data) {
  return request({
    url: '/resource/product/setEnableStatus',
    method: 'post',
    data
  });
}

// 添加或更新
export function createOrUpdateProductTrip(data) {
  return request({
    url: '/resource/product/saveItinerary',
    method: 'post',
    data
  });
}

// 删除
export function deleteProduct(id) {
  return request({
    url: '/resource/product/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 删除產品天數
export function deleteProductDays(productItineraryIds) {
  return request({
    url: '/resource/product/delItineraryByIds',
    method: 'post',
    data: {
      productItineraryIds
    }
  });
}

// 客户列表
export function fetchProductCustom(params) {
  // const url = parseQuerys('/resource/product/pageCustomer', queryParams)
  return request({
    url: '/resource/product/pageCustomer',
    method: 'get',
    params
  });
}

// 添加客户
export function addProductCustom(data) {
  return request({
    url: `/resource/product/saveCustomer`,
    method: 'post',
    data
  });
}

// 删除客户
export function delProductCustom(id) {
  return request({
    url: `/resource/product/delCustomer?productCustomerId=${id}`,
    method: 'post'
  });
}

// 同步商城设置
export function releaseToMall({
  id,
  status = true
}) {
  return request({
    url: `/resource/product/setMallStatus`,
    method: 'post',
    data: {
      id,
      status
    }
  });
}

// 添加产品至商城
export function createMallProcuct(data) {
  return request({
    url: `/mall/product/save-update-product`,
    method: 'post',
    data
  });
}