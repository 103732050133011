import { SelectDicType, SelectDictionary } from '@/components/common';
import { mapGetters } from 'vuex';
import { rules } from '../config';
export default {
  components: {
    SelectDicType,
    SelectDictionary
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      form: {
        ...this.data
      }
    };
  },
  computed: {
    ...mapGetters({
      shopDicData: 'resource/shopping/shopDicData'
    })
  },
  methods: {
    selectShopType(val) {
      this.form.shopTypeLabel = val.label;
      this.form.shopType = val.value;
    },
    onSelectArea(val) {
      this.form.area = val.label;
    },
    beforeSelectDicType() {
      this.$store.dispatch('resource/shopping/getTravelSuperviseeDic');
    },
    filterData(val) {
      const {
        data
      } = val;
      const opts = {
        name: data
      };
      this.$store.dispatch('resource/shopping/searchTravelSuperviseeDic', opts);
    }
  }
};