import { planEditPickup } from '@/config/plan';
import TableListArrange from './TableListArrange';
export default {
  components: {
    TableListArrange
  },
  mixins: [],
  data() {
    return {
      columnData: planEditPickup.slice(0, -1),
      visible: false
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      // 请求数据
      setTimeout(() => {
        this.$refs.arrangePickupRef.getData(data);
      }, 100);
    },
    hide() {
      this.visible = false;
    },
    handleCreate() {
      this.$refs.arrangePickupRef.createOrUpdate().then(() => {
        this.$parent.handleSearch();
        this.hide();
      });
    }
  }
};