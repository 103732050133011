export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    edit(node, data) {
      this.$emit('edit', node, data);
    },
    remove(node, data) {
      this.$emit('remove', node, data);
    },
    handleNodeClick(node, data) {
      this.$emit('click', node, data);
    },
    handleDragStart(node, ev) {
      this.$emit('drag-start', node, ev);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      this.$emit('drag-enter: ', dropNode.label, ev);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      this.$emit('drag-leave: ', dropNode.label, ev);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      this.$emit('drag-over: ', dropNode.label, ev);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.$emit('drag-end: ', dropNode, dropType, ev);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.$emit('drop-success', {
        draggingNode,
        dropNode,
        dropType,
        ev
      });
    }
  }
};