var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-remarks",
    style: _vm.getFontSize
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 备注 ")]), _c("div", {
    staticClass: "remark-content"
  }, _vm._l(_vm.remarkList, function (item) {
    return _c("div", {
      key: item.id
    }, [_c("LabelBase", {
      attrs: {
        label: item.label
      }
    }), _c("TextBase", [_vm._v(" " + _vm._s(item.value) + " ")])], 1);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };