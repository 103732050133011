export default {
  name: 'RadioProxy',
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    radios: [{
      value: '本社',
      label: 'isAgencyCollect'
    }, {
      value: '导游',
      label: 'isGuideCollect'
    }]
  }),
  methods: {
    changeRadio(val) {
      this.row.proxy = val;
      this.row.isAgencyCollect = val == 'isAgencyCollect' ? true : false;
      this.row.isGuideCollect = val == 'isGuideCollect' ? true : false;
      this.$emit('on-change', val);
    }
  }
};