import { BtnCreate, SelectDictionary } from '@/components/common';
import { searchMixin } from '@/mixin';
export default {
  components: {
    BtnCreate,
    SelectDictionary
  },
  mixins: [searchMixin],
  data() {
    return {
      searchParams: {
        name: '',
        area: ''
      }
    };
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchParams);
      // mixin method
      this.handleSearch();
    }
  }
};