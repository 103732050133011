import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { guideTotal } from '@/config/report';
import { SelectGuide, BtnExport, TableTotalBox } from '@/components/common';
import TableGuideTotal from '../components/TableGuideTotal';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
const today = parseDate();
export default {
  components: {
    SelectGuide,
    BtnExport,
    TableGuideTotal,
    TableTotalBox
  },
  mixins: [searchMixin],
  data: () => ({
    total: 0,
    // sendDate: [today, ''],
    endDate: [],
    searchParams: {
      startTripDateS: today
    },
    columnData: guideTotal,
    tableData: [],
    statisticData: [],
    statistic: {},
    tableLoading: false
  }),
  computed: {
    ...mapGetters({
      guideCountData: 'report/index/guideCountData'
    })
  },
  watch: {
    guideCountData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 导游统计
    onSelectGuide(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.guideId = value;
      this.searchParams.guideLabel = label;
    },
    // 计划类型
    onSelectOrderType({
      value
    }) {
      if (value) {
        value = value.toUpperCase();
      }
      this.searchParams.orderType = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      this.searchParams[type + 'S'] = date[0];
      this.searchParams[type + 'E'] = date[1];
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      if (list.length) {
        // 计算欠报和总数
        let sum = 0;
        list.map(it => {
          it.lacOfPay = it.travelAgencyPay - it.travelAgencyPayed;
          sum += it.planCount;
          it.planCountTotal = sum;
          // 代收款计算  代收+ 外接代收 + 购物代收
          it.collectReceive = it.collectReceive + it.outCollectReceiveReimb + it.shopCollectReceive;
        });
      }
      this.total = totalCount;
      this.tableData = list;
      // 计算欠报
      statistics.lacOfPay = statistics.travelAgencyPay - statistics.travelAgencyPayed;
      // 总人数
      statistics.people = `${statistics.adultCount}大${statistics.childCount}小${statistics.accompanyCount}陪`;
      this.statistic = statistics;
      // 已对账/总数
      statistics.billCheckTotal = `${statistics.checkAccountPlanCount}/${statistics.planCount}`;
      this.statistic = statistics;
      // 计算代收款
      statistics.collectReceive = statistics.collectReceive + statistics.outCollectReceiveReimb + statistics.shopCollectReceive;
      this.statisticData.push(statistics);
    },
    fetchData(opts) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getGuideCount', opts).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchPlanGuideCountExcel', this.searchParams).then(res => {
        const fileName = '导游汇总统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};