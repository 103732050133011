import { planTransforTeam } from '@/config/plan';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columnData: [],
      tableData: this.data
    };
  },
  // watch: {
  //   data: {
  //     handler: list => {
  //       if (!list || !list.length) return

  //       this.tableData = list.map(it => it)
  //     },
  //   },
  // },

  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planTransforTeam : filterCustomColumn(planTransforTeam);
  },
  methods: {
    formatData() {}
  }
};