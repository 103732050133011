import { render, staticRenderFns } from "./TableList.vue?vue&type=template&id=280aa5c9"
import script from "./TableList.vue?vue&type=script&lang=js"
export * from "./TableList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('280aa5c9')) {
      api.createRecord('280aa5c9', component.options)
    } else {
      api.reload('280aa5c9', component.options)
    }
    module.hot.accept("./TableList.vue?vue&type=template&id=280aa5c9", function () {
      api.rerender('280aa5c9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/analysis/memberAge/TableList.vue"
export default component.exports