import { orderOtherPay } from '@/config/order';
import { getSelectLabel } from '@/config/select';
import { getSummariesMixin, reloadMixin } from '@/mixin';
export default {
  mixins: [getSummariesMixin, reloadMixin],
  data() {
    return {
      columnData: orderOtherPay.slice(0, -1),
      tableData: [],
      statisticsKeys: ['totalPay']
    };
  },
  computed: {
    getType() {
      return (type, val) => {
        return getSelectLabel(type, val);
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchOtherPay', {
        id
      }).then(data => {
        this.tableData = data;
      });
    }
  }
};