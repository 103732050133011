import "core-js/modules/es.array.reduce.js";
/*
 * @Author: cyg
 * @Date: 2023-05-11 10:16:18
 * @LastEditors: cyg
 * @LastEditTime: 2023-05-17 15:39:19
 * @FilePath: \travel-admin\src\views\print\pages\PlanTeam\utilsMixin.js
 */
import { parseTime, parseDate } from '@/utils';
import { columnLen, columnInfo, columnTrip, columnOther } from './config';
import { payType as PAY_TYPE } from '@/config/report';
import SealImage from '@/views/print/components/SealImage';
export default {
  components: {
    SealImage
  },
  props: {
    mode: {
      type: Boolean,
      default: true
    },
    params: {
      type: Object
    }
  },
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      data: null,
      // type: 1, // 1.默认， 2.金源 3.美华; 以从外面传入
      columnLen,
      printDate,
      columnInfo,
      columnTrip,
      columnOther
    };
  },
  computed: {
    formatRoom() {
      return val => {
        if (!val) return;
        let arr = val.split('|');
        return arr.filter(it => it !== '' && it !== undefined);
      };
    },
    getBusFees() {
      return row => {
        const {
          busCompanySimples
        } = row;
        return busCompanySimples.reduce((acc, cur) => {
          return acc += `${cur.totalPay}元(${cur.payType ? PAY_TYPE[cur.payType] : ''})`;
        }, '');
      };
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.fetchData(id);
  },
  methods: {
    fetchData(planId) {
      this.$store.dispatch('order/print/fetchTeamPlan', {
        planId,
        ...this.params
      }).then(data => {
        if (!data) return;
        const {
          arriveDate,
          leaveDate
        } = data;
        // 拼接接送信息
        const {
          arriveDesPosition,
          arriveNumber
        } = data;
        const {
          leaveDesPosition,
          leaveNumber
        } = data;

        // 格式化时间
        if (arriveDate) {
          const aDate = arriveDate && parseDate(arriveDate);
          const aTime = arriveDate && parseTime(arriveDate, '{h}:{i}');
          data.arriveInto = `${arriveDesPosition} 接站 ${aTime} ${arriveNumber}`;
          data.arriveDate = aDate;
        }
        if (leaveDate) {
          const lDate = leaveDate && parseDate(leaveDate);
          const lTime = leaveDate && parseTime(leaveDate, '{h}:{i}');
          data.leaveInto = `${leaveDesPosition} 送站 ${lTime} ${leaveNumber}`;
          data.leaveDate = lDate;
        }

        // 排序
        data.respOrderTrips.sort(function (a, b) {
          return a.itineraryDate > b.itineraryDate ? 1 : -1; //升序  < 降序
        });

        // 备注拼接
        const restaurantText = data.restaurantRemark + '\n' + data.travelAgencyVo.agencyInfoRestaurantAdjustment;

        // 房餐车景特别交代，如果团上没有取公司信息上的
        const remarkKeys = {
          hotelRemark: 'agencyInfoUseHotel',
          busRemark: 'agencyInfoUseCar',
          scenicRemark: 'agencyInfoScenic',
          friendlyReminderRemark: 'agencyInfoSpecial'
        };
        const remarkObj = Object.keys(remarkKeys).reduce((acc, key) => {
          acc[key] = data[key] || data.travelAgencyVo[remarkKeys[key]];
          return acc;
        }, {});
        this.data = {
          ...data,
          ...data.travelAgencyVo,
          ...remarkObj,
          restaurantText,
          accompanyInfo: data.accompanyInfo.split('/').join(' ')
        };

        // // data.respOrderTrips = planFormatItinerarys(data.respOrderTrips)
        this.$emit('onMounted');
      });
    }
  }
};