var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box"
  }, [_c("BtnBase", {
    attrs: {
      type: "download"
    },
    on: {
      click: _vm.downloadTemplate
    }
  }, [_vm._v(" 下载模板 ")]), _c("el-upload", {
    staticClass: "upload-template-btn",
    attrs: {
      action: "",
      accept: ".xlsx, .xls",
      multiple: "",
      "show-file-list": false,
      "http-request": _vm.uploadTemplate
    }
  }, [_c("BtnBase", {
    attrs: {
      type: "upload"
    }
  }, [_vm._v(" 上传协议 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };