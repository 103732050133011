import IconRight from '../IconRight';
import IconWrong from '../IconWrong';
import CustomerInfo from '../CustomerInfo';
import { formatItinerarys } from '@/views/order/utils';
import { planTrip } from '@/config/plan';
export default {
  components: {
    IconRight,
    IconWrong,
    CustomerInfo
  },
  props: {
    planId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      columnData: planTrip
    };
  },
  computed: {
    getShuttlesInfo() {
      return arr => {
        const pickItem = arr.find(it => it.shuttleType == 'PICK');
        const sendItem = arr.find(it => it.shuttleType == 'SEND');
        if (pickItem && sendItem) {
          const [startDate, startTime] = this.splitTime(pickItem.shiftDateTime);
          const [endDate, endTime] = this.splitTime(sendItem.shiftDateTime);
          return `接团：${pickItem.shiftNumber}    ${startDate}    ${startTime}    送团： ${sendItem.shiftNumber}    ${endDate}    ${endTime}`;
        }
        if (pickItem) {
          const [startDate, startTime] = this.splitTime(pickItem.shiftDateTime);
          return `接团：${pickItem.shiftNumber}    ${startDate}    ${startTime}`;
        }
        if (sendItem) {
          const [endDate, endTime] = this.splitTime(sendItem.shiftDateTime);
          return `送团： ${sendItem.shiftNumber}    ${endDate}    ${endTime}`;
        }
      };
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // 获取订单行程
    fetchData() {
      this.$store.dispatch('plan/index/getOrderByPlanId', this.planId).then(data => {
        const list = data.map(it => {
          this.$set(it, 'expand', true);
          return it;
        });
        this.tableData = this.formatData(list);
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.itinerarys = formatItinerarys(it.itinerarys);
        return it;
      });
    },
    splitTime(val) {
      if (!val) return [];
      return val.split('T');
    }
  }
};