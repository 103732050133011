import CheckBox from './components/CheckBox';
import RowItem from './components/RowItem';
import CheckAccountTime from './components/CheckAccountTime';
export default {
  components: {
    CheckBox,
    RowItem,
    CheckAccountTime
  }
  // data() {
  //   return {
  //     sumObj: {
  //       mergeKeys: ['$total', 'guidePay', 'unPay', 'checkAccountMoney'],
  //       checkKeys: ['checkAccountTime', 'checkAccountMoney', 'ckStatus'],
  //     },
  //   }
  // },
};