/* eslint-disable no-empty-pattern */
/*
 * @Author: cyg
 * @Date: 2022-10-26 10:37:17
 * @LastEditors: cyg
 * @LastEditTime: 2022-10-27 10:08:35
 * @FilePath: \travel-admin\src\store\modules\system\payment.js
 */
import { fetchVasList, updateAlipayNotify, vasLogRequest, vasBuyedListRequest, notiflyStatusRequest, aliPayStatusRequest, wechatPayStatusRequest, closeOrderByIdRequest, closeOrderByTradeNoRequest, vasListRequest, renewLogRequest, contractCodeRequest } from '@/api/system/payment';
import { wechatTopUpRequest, alipayTopUpRequest, unipayTopUpRequest } from '@/api/system/payment';
const dfaultState = () => ({});
const state = dfaultState();
const getters = {};
const actions = {
  // 增值服务列表（不分页）
  fetchVasList({}, opts) {
    return fetchVasList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 增值服务列表（分页）
  vasList({}, opts) {
    return vasListRequest(opts).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      return data;
    });
  },
  // 支付宝支付
  updateAlipay({}, opts) {
    return alipayTopUpRequest(opts).then(res => {
      return res;
    });
  },
  // 支付宝支付回调通知接口
  updateAlipayNotify({}, params) {
    return updateAlipayNotify(params).then(res => {
      return res;
    });
  },
  // 已购买的增值服务
  vasBuyedList({}, params) {
    return vasBuyedListRequest(params);
  },
  // 获取增值服务充值日志
  vasLog({}, params) {
    return vasLogRequest(params);
  },
  // 通知后端支付结果
  notiflyStatus({}, params) {
    return notiflyStatusRequest(params);
  },
  // 支付宝查询订单支付状态
  aliPayStatus({}, params) {
    return aliPayStatusRequest(params);
  },
  // 微信查询订单支付状态
  wechatPayStatus({}, outTradeNo) {
    return wechatPayStatusRequest({
      outTradeNo
    });
  },
  // 微信充值
  wechatTopUp({}, opts) {
    return wechatTopUpRequest(opts);
  },
  // 支付宝充值
  alipayTopUp({}, opts) {
    return alipayTopUpRequest(opts);
  },
  // 银联充值
  unipayTopUp({}, opts) {
    return unipayTopUpRequest(opts);
  },
  // 关闭取消待支付订单 订单id
  closeOrderById({}, params) {
    return closeOrderByIdRequest(params);
  },
  // 关闭取消待支付订单 trade no
  closeOrderByTradeNo({}, params) {
    return closeOrderByTradeNoRequest(params);
  },
  // 充值记录
  renewLog({}, params) {
    return renewLogRequest(params);
  },
  // 合同编码获取
  contractCode({}, params) {
    return new Promise((resolve, reject) => {
      contractCodeRequest(params).then(res => {
        if (!res) {
          reject(res);
        } else {
          resolve(res.data);
        }
      });
    });
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};