var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "team-fund"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "summary-method": _vm.getSummaries,
      sticky: _vm.isSticky,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "income",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.income) + " ")];
      }
    }, {
      key: "profit",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.profit) + " ")];
      }
    }, {
      key: "perProfit",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.perProfit) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };