import "core-js/modules/es.array.push.js";
import { SelectGuide } from '@/components/common';
import { reloadMixin, summaryMixin } from '@/mixin';
import { downBillGuideBatch } from '@/config/billDown';
import { mapGetters } from 'vuex';
import { processMinus } from '@/utils/number';
export default {
  components: {
    SelectGuide
  },
  mixins: [summaryMixin, reloadMixin],
  data() {
    return {
      statisticsKeys: ['cash', 'used', 'balance'],
      path: '/bill/guide-audit-batch-detail',
      columnData: downBillGuideBatch,
      loading: false,
      total: 0,
      cashDate: [],
      sendDate: [],
      searchParams: {}
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'bill/guide/reimbBatchList',
      reimbBatchData: 'bill/guide/reimbBatchData'
    }),
    getTotal() {
      return this.reimbBatchData ? this.reimbBatchData.totalCount : 0;
    },
    statistics() {
      return this.reimbBatchData ? this.reimbBatchData.statistics : null;
    },
    getUnpay() {
      if (!this.reimbBatchData) return 0;
      return processMinus(this.statistics.cash, this.statistics.used);
    }
  },
  created() {
    if (!this.tableData || !this.tableData.length) {
      this.fetchData();
    }
  },
  methods: {
    fetchData(queryParmas = {}) {
      this.loading = true;
      this.$store.dispatch('bill/guide/fetchGuideReimbBatch', queryParmas).then(data => {
        if (!data) return;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    handleDetail(row) {
      const {
        id,
        guideId
      } = row;
      this.$store.commit('bill/guide/BILL_DETAIL', row);
      this.$router.push({
        path: this.path,
        query: {
          id,
          guideId
        }
      });
    },
    handleDelete(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('bill/billDown/deleteCollect', id).then(() => {
          this.$store.commit('bill/guide/REIMB_BATCH_DELETE_ITEM', id);
        });
      });
    },
    changeStarDate(val) {
      const [startTripDateS, startTripDateE] = val;
      this.searchParams.startTripDateS = startTripDateS;
      this.searchParams.startTripDateE = startTripDateE;
    },
    changeCashDate(val) {
      const [startCashDate, endCashDate] = val;
      this.searchParams.startCashDate = startCashDate;
      this.searchParams.endCashDate = endCashDate;
    }
  }
};