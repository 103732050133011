import service from '@/utils/request';
export { permissionRole } from './role';
/**
 * 创建角色
 * @param {object}} data 创建角色的数据对象
 */
export function createRole(data) {
  return service({
    url: '/system/role/add',
    method: 'post',
    data
  });
}

/**
 * 更新角色
 * @param {object}} data 更新角色参数对象
 */
export function updateRole(data) {
  return service({
    url: '/system/role/update',
    method: 'post',
    data
  });
}

/**
 * 删除角色
 * @param {number} roleId 角色id
 */
export function deleteRole(roleId) {
  return service({
    url: '/system/role/del',
    method: 'post',
    data: {
      roleId
    }
  });
}

/**
 * 更新角色权限
 * @param {*} data 角色权限对象
 */
export function permissionUpdateRole(data) {
  return service({
    url: '/system/role/updateRoleMenu',
    method: 'post',
    data
  });
}

/**
 * 获取权限列表
 * @param {object}} queryParam 查询角色权限列表参数
 */
export function fetchRoleList(queryParam) {
  return service({
    url: '/system/role/list',
    method: 'post',
    queryParam
  });
}

/**
 * 获取公司信息
 * @param {number} id 公司id
 */
export function getCompanyInfo(id) {
  return service({
    url: '/system/trip/infoById',
    method: 'post',
    data: {
      travelAgencyId: id
    }
  });
}

/**
 * 新增或者更新公司信息
 * @param {object} data 公司信息数据
 */
export function createOrUpdateCompany(data) {
  return service({
    url: '/system/trip/saveOrUpdate',
    method: 'post',
    data
  });
}

/**
 * 新增或更新公司发票
 * @param {object} data 公司发票信息
 */
export function createOrUpdateInvoice(data) {
  return service({
    url: '/system/trip/saveOrUpdateInvoice',
    method: 'post',
    data
  });
}

// 删除发票
export function deleteInvoice(id) {
  return service({
    url: '/system/trip/delInvoice',
    method: 'post',
    data: {
      invoiceId: id
    }
  });
}

// 发票列表
export function fetchInvoiceList({
  pageNum = 1,
  pageSize = 50
}) {
  return service({
    url: '/system/trip/listInvoice',
    method: 'post',
    data: {
      pageNum,
      pageSize
    }
  });
}

// 添加公告
export function createNotice(data) {
  return service({
    url: '/system/sysNotice/add',
    method: 'post',
    data
  });
}

// 删除公告
export function deleteNotice(id) {
  return service({
    url: `/system/sysNotice/del?id=${id}`,
    method: 'post',
    data: {}
  });
}

// 获取公告
export function fetchNotice(params) {
  return service({
    url: '/system/sysNotice/page',
    method: 'get',
    params
  });
}

// 更新公告
export function updateNotice(data) {
  return service({
    url: '/system/sysNotice/update',
    method: 'post',
    data
  });
}