import "core-js/modules/es.array.push.js";
import { TopTitle } from '@/components/common';
import { planEditReceptionRefundEdit } from '@/config/plan';
import { SelectDictionary } from '@/components/common';
import { auditMixin } from '@/mixin';
import { hasCustomeAuth, filterCustomColumn } from '@/utils';
const defaultState = () => ({
  title: '',
  type: '',
  typeId: '',
  price: '',
  count: 1,
  total: 0,
  remarks: ''
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  mixins: [auditMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    },
    checkAccountStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnData: [],
      type: 'customerSubsidyList',
      // 返款列表，对应接口字段
      rowId: 1000000000000
    };
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planEditReceptionRefundEdit : filterCustomColumn(planEditReceptionRefundEdit);
  },
  methods: {
    // 新增一条
    handlePlus() {
      this.tableData.push({
        ...defaultState(),
        id: ++this.rowId,
        isCustom: true
      });
    },
    selectType(row, val) {
      const {
        id,
        label
      } = val;
      row.type = label;
      row.typeId = id;
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price
      } = obj;
      let total = count * price;
      return total;
    },
    // 修改单行金额
    changeInput(row, type, value) {
      if (value < 0) {
        row[type] = 0;
        return;
      }
      row.total = this.calcRowItem(row);
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      const doDelete = () => {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      };
      this.$bus.open(() => {
        if (isCustom) {
          doDelete();
        } else {
          this.$store.dispatch('order/index/deleteRefund', id).then(() => {
            doDelete();
            this.$bus.tip();
          });
        }
      });
    },
    createOrUpdate(customerOrderId) {
      if (!this.tableData.length) {
        this.$bus.tip({
          type: 'warning',
          message: '请添加数据'
        });
        return Promise.reject();
      }
      if (!this.validateForm()) {
        return Promise.reject();
      } else {
        // 新增的项目加上 订单 id
        let copyData = this.tableData.map(it => {
          it.customerOrderId = customerOrderId;
          it.total = it.price * it.count;
          return it;
        });
        return this.$store.dispatch('order/index/createOrUpdateRefund', copyData);
      }
    },
    validateForm() {
      const tableData = this.tableData;
      if (!tableData.length) return false;
      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        if (!row.type) {
          this.$bus.tip({
            type: 'warning',
            message: '返款类型不能为空'
          });
          return false;
        }
        if (!row.price) {
          this.$bus.tip({
            type: 'warning',
            message: '返款金额不能为空'
          });
          return false;
        }
        if (!row.count) {
          this.$bus.tip({
            type: 'warning',
            message: '返款数量不能为空'
          });
          return false;
        }
      }
      return true;
    }
  }
};