import { resourceOrderHotelInfo } from '@/config/resourceOrder';
import ModifyItem from '../components/ModifyItem.vue';
import modifyMixin from '../modify';
export default {
  components: {
    ModifyItem
  },
  mixins: [modifyMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columnData: resourceOrderHotelInfo,
      tableData: []
    };
  }
};