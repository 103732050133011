export const Columns = [{
  attrs: {
    prop: 'resName',
    label: '商家名称'
  }
}, {
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  }
}, {
  attrs: {
    prop: '$consumeCreateTime',
    label: '支付日期'
  }
}, {
  attrs: {
    prop: 'peerPayAmount',
    label: '金额'
  }
}, {
  attrs: {
    prop: 'merchantName',
    label: '支付宝商家名称'
  }
}, {
  attrs: {
    prop: 'oppositeFullName',
    label: '支付宝商户全称'
  }
}];