// 发团预定安排 餐厅
import request from '@/utils/request';

// 安排列表
export function fetchRestaurant(params) {
  return request({
    url: '/order/restaurant/listAll',
    method: 'get',
    params,
    timeout: 1000 * 60 * 2
  });
}

// 安排更新
export function updateRestaurant(data) {
  return request({
    url: '/order/restaurant/save',
    method: 'post',
    data
  });
}

// 订单更新
export function updateRestaurantSuper(data) {
  return request({
    url: '/order/restaurant/save',
    method: 'post',
    data,
    timeout: 10000,
    headers: {
      tmpSupper: 'TS-1'
    }
  });
}

// 安排删除
export function deleteRestaurant(ids) {
  return request({
    url: '/order/restaurant/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteRestaurantItem(id) {
  return request({
    url: '/order/restaurant/removeType',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchRestaurantOrder(params) {
  return request({
    url: '/order/restaurant/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateRestaurantOrder(data) {
  return request({
    url: '/order/restaurant/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteRestaurantOrder(id) {
  return request({
    url: '/order/restaurant/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteRestaurantOrderItem(id) {
  return request({
    url: '/order/restaurant/delOrderType',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchRestaurantOrderList(params) {
  return request({
    url: '/order/restaurant/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectRestaurantOrderList(data) {
  return request({
    url: '/order/restaurant/saveAndList',
    method: 'post',
    data
  });
}