var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "income-table"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "row-class-name": _vm.tableRowClassName,
      "cell-class-name": _vm.tableCellClass,
      sticky: true,
      "fix-top": true,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.index) + " ")];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.customerName) + " ")];
      }
    }, {
      key: "outOPUserName",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.outOPUserName) + " ")];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };