import { DialogLog } from '@/components/common';
import { TYPES } from '@/config/audit';
import BtnBar from './components/BtnBar';
import TotalBar from './components/TotalBar';
import { getCurTagView } from '@/utils/tagView';
export default {
  components: {
    DialogLog,
    BtnBar,
    TotalBar
  },
  computed: {
    getResourceType() {
      const {
        fullPath
      } = this.$route;
      let [, name] = fullPath.split('/bill-check/');
      name = name == 'traffic' ? TYPES.transport : name;
      name = name == 'shops' ? TYPES.shop : name;
      return name;
    },
    getTagViewSearch() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : this.searchParams;
    }
  },
  methods: {
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    // 未付
    calcUnpay(item) {
      let {
        totalPay,
        totalPayed,
        guidePay
      } = item;
      const unit = 100;
      const payMoney = totalPay || 0;
      totalPayed = totalPayed || 0;
      guidePay = guidePay || 0;
      const money = payMoney * unit - totalPayed * unit;
      const res = money - guidePay * unit;
      return res / unit;
    },
    // 未收
    calcUnCollect(item) {
      let {
        totalIncome,
        guideIncome,
        agencyIncome
      } = item;
      const unit = 100;
      const money = totalIncome * unit - guideIncome * unit - agencyIncome * unit;
      return money / unit;
    },
    handleBillCheck() {
      this.$refs.tableRef.handleBillCheck();
    },
    closeTag() {
      this.closeSelectedTag();
    },
    // 分页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    restoreSelect() {
      this.tableData.forEach(it => {
        it.ckStatus = false;
        it.checkAccountStatus = false;
      });
    },
    showLog(scope) {
      const {
        rowId: subItemId,
        planId: itemId
      } = scope.row;
      this.$store.dispatch('bill/billCheck/fetchPlanLog', {
        itemId,
        subItemId,
        subType: this.getResourceType == 'fleet' ? 'bus' : this.getResourceType
      }).then(data => {
        this.$refs.dialogRef.show(data);
      });
    }
  }
};