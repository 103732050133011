import { SelectStaff, InputDepartment, SelectAccount, ResourceItem, SelectBankCard } from '@/components/common';
import { excelMixin } from '@/mixin';
import { departKeys } from '@/config/dictionary';
import { CASHLOGTYPE } from '@/config/billDown';
export default {
  components: {
    SelectStaff,
    InputDepartment,
    SelectAccount,
    ResourceItem,
    SelectBankCard
  },
  mixins: [excelMixin],
  props: {
    showBtn: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cashLogTypeList: [{
        value: '',
        label: '全部'
      }, ...CASHLOGTYPE],
      cashDate: [],
      sendDate: [],
      createDate: [],
      department: '',
      searchParams: {
        cashWay: ''
      },
      subjectLabel: []
    };
  },
  methods: {
    onSearch() {
      const search = this.searchParams;
      this.$emit('search', search);
    },
    clearResource() {
      delete this.searchParams.cashType;
    },
    selectCashWay(val) {
      const {
        label
      } = val ? val : {
        label: ''
      };
      this.searchParams.cashWay = label;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    onChangeDate(type, val) {
      const [start, end] = val;
      this.searchParams[type + 'S'] = start;
      this.searchParams[type + 'E'] = end;
    },
    onCreateDate(val) {
      const [start, end] = val;
      this.searchParams.startCreateDate = start;
      this.searchParams.endCreateDate = end;
    },
    onCashDate(val) {
      const [start, end] = val;
      this.searchParams.startCashDate = start;
      this.searchParams.endCashDate = end;
    },
    onApplyDate(val) {
      const [start, end] = val;
      this.searchParams.startApplyDate = start;
      this.searchParams.endApplyDate = end;
    },
    selectSubject(val) {
      const {
        id,
        name,
        subId
      } = val;
      if (subId) {
        this.searchParams.subjectId = subId;
      } else {
        this.searchParams.subjectId = id;
        this.searchParams.subjectLabel = name;
      }
    }
  }
};