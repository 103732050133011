var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "international",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleSetLanguage
    }
  }, [_c("span", [_c("i", {
    staticClass: "iconfont icon-language"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      disabled: _vm.language === "zh",
      command: "zh"
    }
  }, [_vm._v(" 中文 ")]), _c("el-dropdown-item", {
    attrs: {
      disabled: _vm.language === "en",
      command: "en"
    }
  }, [_vm._v(" English ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };