var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SearchBase", {
    attrs: {
      inline: ""
    },
    on: {
      "on-search": _vm.onSearch
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "案例号",
      prop: "code"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.code,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "code", $$v);
      },
      expression: "searchParams.code"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "发生时间",
      prop: "occurrenceDate"
    }
  }, [_c("InputDate", {
    attrs: {
      label: "",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchParams.occurrenceDate,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "occurrenceDate", $$v);
      },
      expression: "searchParams.occurrenceDate"
    }
  })], 1), _c("template", {
    slot: "btn"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("add");
      }
    }
  }, [_vm._v("新增案例")])], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };