var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      border: "",
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky
    },
    scopedSlots: _vm._u([{
      key: "department",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.departmentName))]), _c("span", [_vm._v(_vm._s(scope.row.subDepartmentName ? " / " + scope.row.subDepartmentName : ""))]), _c("span", [_vm._v(_vm._s(scope.row.grandsonDepartmentName ? " / " + scope.row.grandsonDepartmentName : ""))])];
      }
    }, {
      key: "lastIp",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.loginInfo ? scope.row.loginInfo.lastIp : "") + " ")];
      }
    }, {
      key: "ipRegion",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.loginInfo ? scope.row.loginInfo.ipRegion : "") + " ")];
      }
    }, {
      key: "loginTime",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.loginInfo ? scope.row.loginInfo.loginTime : "") + " ")];
      }
    }, {
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("SwitchStatus", {
          attrs: {
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeStatus(scope);
            }
          },
          model: {
            value: scope.row.isEnable,
            callback: function ($$v) {
              _vm.$set(scope.row, "isEnable", $$v);
            },
            expression: "scope.row.isEnable"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleDelete(scope);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };