var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "print-page",
    attrs: {
      id: "ArrangeTravel"
    }
  }, [_c("div", {
    staticClass: "wrapper"
  }, [_c("div", {
    staticClass: "pos-r tac"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(" 桂林市旅游服务质量管理系统 ")]), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(" 团队行程及导游委派单（电子行程单） ")]), _c("qrcode-vue", {
    staticStyle: {
      position: "absolute",
      right: "20px",
      top: "10px"
    },
    attrs: {
      value: _vm.data.id,
      size: 70,
      level: "H"
    }
  })], 1), _c("FormBase", {
    attrs: {
      inline: ""
    }
  }, _vm._l(_vm.headInfo, function (item, index) {
    return _c("FormItemBase", {
      key: index,
      attrs: {
        label: item.label + ":"
      }
    }, [_vm._v(" " + _vm._s(_vm.data[item.value]) + " ")]);
  }), 1), _c("div", {
    staticClass: "content"
  }, [_c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(24, function (item) {
    return _c("col", {
      key: item
    });
  }), 0), _c("tbody", _vm._l(_vm.getLength, function (item, index) {
    return _c("tr", {
      key: index
    }, _vm._l(_vm.getColumn(index), function (col) {
      return _c("td", {
        key: col.label,
        attrs: {
          colspan: "8"
        }
      }, [col.slot ? [col.slot == "count" ? [_c("span", [_vm._v(" " + _vm._s(col.label) + ": " + _vm._s(_vm.data["adultNumber"]) + " ")]), _c("span", {
        staticClass: "ml10"
      }, [_vm._v(" 小孩：" + _vm._s(_vm.data["childNumber"]) + " ")])] : _vm._e(), col.slot == "date" ? [_vm._v(" " + _vm._s(col.label) + ": " + _vm._s(_vm.parseDate(_vm.data[col.value])) + " ")] : _vm._e()] : [_vm._v(" " + _vm._s(col.label) + ": " + _vm._s(_vm.data[col.value]) + " ")]], 2);
    }), 0);
  }), 0)]), _c("div", {
    staticClass: "table-guide"
  }, [_c("div", {
    staticClass: "table-body"
  }, [_c("div", {
    staticClass: "table-tr"
  }, _vm._l(_vm.guideInfo, function (col) {
    return _c("div", {
      key: col.label,
      style: {
        width: col.width
      }
    }, [_vm._v(" " + _vm._s(col.label) + ": " + _vm._s(_vm.data[col.value]) + " ")]);
  }), 0)])]), _c("div", {
    staticClass: "tac table-trip"
  }, [_c("div", {
    staticClass: "table-body"
  }, [_c("div", {
    staticClass: "table-tr"
  }, _vm._l(_vm.tripColumns, function (col, index) {
    return _c("div", {
      key: index,
      style: {
        width: col.width
      }
    }, [_vm._v(" " + _vm._s(col.label) + " ")]);
  }), 0), _vm._l(_vm.tripData, function (item) {
    return _vm._l(item.siteList, function (val) {
      return _c("div", {
        key: val.routeId,
        staticClass: "table-tr"
      }, _vm._l(_vm.tripColumns, function (col) {
        return _c("div", {
          key: col.label,
          style: {
            width: col.width
          }
        }, [_vm._v(" " + _vm._s(val[col.content]) + " ")]);
      }), 0);
    });
  })], 2)]), _c("div", {
    staticClass: "tac table-trip"
  }, [_c("div", {
    staticClass: "table-body"
  }, [_c("div", {
    staticClass: "table-tr"
  }, [_c("div", {
    staticStyle: {
      width: "55px"
    }
  }, [_vm._v(" 备注 ")]), _c("div", {
    staticClass: "flex-1"
  }, [_vm._v(" " + _vm._s(_vm.data.remark) + " ")])])])]), _vm._m(0)]), _c("SealImage", {
    attrs: {
      "seal-type": _vm.SEAL_TYPE.trip,
      url: _vm.data.electronicSealUrl
    }
  })], 1)]) : _c("Empty");
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tac table-remark"
  }, [_c("div", {
    staticClass: "table-body"
  }, [_c("div", {
    staticClass: "table-tr"
  }, [_c("div", [_c("p", [_vm._v(" 此行程单一式三份，旅行社一份，导游一份，游客代表一份 ")]), _c("p", [_vm._v(" 监制：桂林市文化广电和旅游局, 网络验证：http://wenhuazhifa.com/ ")]), _c("p", [_vm._v(" 技术热线：0771-2830160 投诉热线：12345 ")])])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };