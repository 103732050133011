var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "print-page"
  }, [_c("TopTitle", {
    attrs: {
      title: "签单"
    }
  }), _c("div", {
    staticClass: "print-ticket-head flex flex-center"
  }, [_c("span", {
    staticClass: "pr10"
  }, [_vm._v("大团号：" + _vm._s(_vm.planNumber))]), _c("span", [_vm._v("导游：" + _vm._s(_vm.guideName) + " ")])]), _c("div", {
    staticClass: "content mt10"
  }, [_c("table", {
    staticClass: "out-table"
  }, [_c("colgroup", _vm._l(12, function (item, index) {
    return _c("col", {
      key: index,
      staticClass: "col-item"
    });
  }), 0), _c("tbody", [_c("tr", _vm._l(_vm.tableHead, function (item, index) {
    return _c("th", {
      key: index,
      attrs: {
        colspan: item.span ? item.span : 1
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm._l(_vm.tableData, function (item, index) {
    return [_c("tr", {
      key: index
    }, [_vm._l(_vm.tableHead, function (col, cIdx) {
      return [_c("td", {
        key: cIdx,
        staticClass: "item-content tac",
        attrs: {
          colspan: col.span
        }
      }, [col.slot == "serialNumber" ? [_vm._v(" " + _vm._s(index + 1) + " ")] : _vm._e(), _c("span", [_vm._v(" " + _vm._s(item[col.content]) + " ")])], 2)];
    })], 2)];
  })], 2)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };