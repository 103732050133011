import "core-js/modules/es.array.push.js";
import { TopTitle, SelectPayType, DialogLog } from '@/components/common';
import { orderOtherPay, TYPES } from '@/config/order';
import tableMixin from '../tableMixin';
import { selectorMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { multiplyNumber } from '@/utils/number';
const defaultState = () => ({
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  cashType: 'guide',
  auditStatus: false,
  count: 1,
  orderNumber: '',
  otherFee: 0,
  price: 0,
  remark: '',
  shuttleId: 0,
  title: '',
  totalPay: 0
});
export default {
  components: {
    TopTitle,
    SelectPayType,
    DialogLog
  },
  mixins: [selectorMixin, tableMixin],
  data() {
    return {
      tableLoading: false,
      columnData: orderOtherPay,
      tableData: [],
      deleteMsg: '是否确定删除此条其他支出？',
      fetchPath: 'order/index/fetchOtherPay',
      deletePath: 'order/index/deleteOtherPay',
      updatePath: 'order/index/createOtherPay'
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.cashType = it.cashType.toLowerCase();
        it.totalPay = multiplyNumber(it.price, it.count);
        return it;
      });
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        count,
        price
      } = obj;
      let total = multiplyNumber(count, price);
      return total;
    },
    // 修改单行金额
    changeInput(item, type, val) {
      item[type] = val;
      item.totalPay = this.calcRowTotalMoney(item);
    },
    // 新增一条景区
    handlePlus() {
      const {
        adultCount: count
      } = this.currentOrder;
      this.tableData.push({
        ...defaultState(),
        count
      });
    },
    // 获取日志
    handleLog() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: id,
        subType: TYPES.ortherPay
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    beforeCommit(customerOrderId, type) {
      const tableData = this.tableData.filter(it => !it.auditProgress || it.auditProgress == AuditRole.wait);
      return tableData.map(it => {
        it.customerOrderId = +customerOrderId;
        it.customerId = this.currentOrder.customerId;
        it.planId = this.currentOrder.planId;
        it.total = it.price * it.count;
        it.cashType = it.cashType.toUpperCase();
        if (type == 'clone') {
          delete it.id;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          title,
          price
        } = row;
        if (!title) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目内容！'
          });
          return false;
        }
        if (!price) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写项目价格！'
          });
          return false;
        }
      }
      return true;
    }
  }
};