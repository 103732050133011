import { throttle } from '@/utils';
export default {
  name: '',
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      myChart: null
    };
  },
  mounted() {
    this.option && this.setChart();
    window.addEventListener('resize', throttle(this.setChart, 2000));
  },
  beforeDestroy() {
    this.myChart.clear();
    this.myChart.resize();
  },
  methods: {
    setChart() {
      if (!this.myChart) {
        this.myChart = this.$echarts(this.$el);
      }
      this.myChart.clear();
      this.myChart.resize();
      this.myChart.setOption(this.option);
    }
  }
};