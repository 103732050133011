import "core-js/modules/es.array.push.js";
import { SelectDictionary, SelectScenic, SelectHotel, SelectShop, SelectScenicFree } from '@/components/common';
const defaultScenicState = () => ({
  scenicId: '',
  scenicName: '',
  orderNumber: 0
});
const defaultShopState = () => ({
  shopId: '',
  shopName: '',
  orderNumber: 0
});
const defaultState = () => ({
  breakfast: false,
  dinner: false,
  lunch: false,
  hotelName: '',
  // 酒店名字
  hotelId: 0,
  // 酒店id
  area: '',
  // 酒店区域
  areaId: '',
  // 酒店区域
  standard: '',
  // 酒店标准
  standardId: '',
  // 酒店标准
  isPick: false,
  // 接团
  isSend: false,
  // 送团
  itineraryDesc: '',
  // 行程简介
  // scenicName: '', // 第一个景点
  // scenicId: '', // 第一个景点
  scenics: [defaultScenicState()],
  shops: [defaultShopState()]
});
export default {
  components: {
    SelectDictionary,
    SelectScenic,
    SelectHotel,
    SelectShop,
    SelectScenicFree
  },
  props: {
    days: {
      type: [String, Number],
      default: 1
    },
    productId: {
      type: Number,
      default: 1
    },
    defaultData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      hotelStandard: 'hotelStandardType',
      hotelRegion: 'hotelRegionType',
      daysData: [],
      isNew: true,
      removeCache: []
    };
  },
  computed: {
    hasCheckbox() {
      return index => {
        return index == 0 ? true : index == this.days - 1 ? true : false;
      };
    },
    hasMoreScenic() {
      return item => {
        return item.scenics.length > 1;
      };
    },
    getMoreScenic() {
      return item => {
        return item.scenics.length > 1 && item.scenics.slice(1);
      };
    },
    hasMoreShop() {
      return item => {
        return item.shops.length > 1;
      };
    },
    getMoreShop() {
      return item => {
        return item.shops.length > 1 && item.shops.slice(1);
      };
    }
  },
  watch: {
    days: {
      handler: 'changeDay',
      immediate: true
    },
    daysData: {
      handler: 'changeDaysData',
      immediate: true,
      deep: true
    },
    defaultData: {
      handler: 'initData',
      immediate: true
    }
  },
  created() {
    const {
      type
    } = this.$route.query;
    this.type = type;
  },
  methods: {
    initData(list) {
      if (!list.length) return;
      this.isNew = false;
      this.daysData = list;
    },
    changeDay() {
      if (this.isNew) {
        this.daysData = [];
        for (let i = 1; i <= this.days; i++) {
          this.daysData.push(defaultState());
        }
      } else {
        if (this.days > this.daysData.length) {
          this.daysData.push(defaultState());
        } else {
          const removelistLength = this.daysData.length - this.days;
          let productItineraryId = [];
          for (let i = 0; i < removelistLength; i++) {
            if (this.daysData[this.days + i].id) {
              productItineraryId.push(this.daysData[this.days + i].id);
            } else {
              this.daysData.splice(this.days + i, 1);
            }
          }
          if (productItineraryId.length > 0) {
            if (this.type != 'clone') {
              this.deleteDays(productItineraryId);
            } else {
              // 如果是克隆，则不走删除行程天数接口
              this.daysData.splice(this.days, 1);
            }
          }
        }
      }
    },
    //
    deleteDays(productItineraryId) {
      this.$store.dispatch('resource/product/deleteDays', productItineraryId).then(res => {
        const {
          message
        } = res;
        this.$bus.tip({
          message
        });
        this.$bus.emit('fetchProduct', this.productId);
      });
    },
    changeDaysData(data) {
      this.$emit('on-change', data);
    },
    handleChange(type, item, val) {
      item[type] = val;
    },
    handleSelectShop(index, item, val) {
      const {
        label,
        value
      } = val;
      item.shops[index].shopId = value || -1;
      item.shops[index].shopName = label;
    },
    handleSelectScenic(index, item, val) {
      const {
        label,
        value
      } = val;
      item.scenics[index].scenicId = value || -1;
      item.scenics[index].scenicName = label;
    },
    selecthotelRegion(item, val) {
      const {
        label,
        value
      } = val;
      item.areaId = value || -1;
      item.area = label;
    },
    selectHotelStandard(item, val) {
      const {
        label,
        value
      } = val;
      item.standardId = value || -1;
      item.standard = label;
    },
    selectHotelName(item, val) {
      const {
        label,
        value
      } = val;
      item.hotelId = value || -1;
      item.hotelName = label;
    },
    addScenic(item, type) {
      item.whichDay++;
      item.scenics.push({
        ...defaultScenicState(),
        orderNumber: item.scenics.length,
        scenicType: type
      });
    },
    addShop(item) {
      item.shops.push({
        ...defaultShopState(),
        orderNumber: item.shops.length
      });
    },
    deleteScenic(item, index, day) {
      if (item.scenics[index].id) {
        this.$bus.emit('removeCacheList', {
          item,
          index,
          day
        });
      }
      item.scenics.splice(index, 1);
    },
    deleteShop(item, index, day) {
      if (item.shops[index].id) {
        this.$bus.emit('removeShopsList', {
          item,
          index,
          day
        });
      }
      item.shops.splice(index, 1);
    }
  }
};