import SearchRegiment from '../components/SearchRegiment';
import TableList from './components/TableList';
import { SelectFleet } from '@/components/common';
import { excelMixin, searchMixin } from '@/mixin';
export default {
  components: {
    SelectFleet,
    SearchRegiment,
    TableList
  },
  mixins: [excelMixin, searchMixin],
  data: () => ({
    resourceId: '',
    searchParams: {}
  }),
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billDown/payRegimentFleet/fetch', {
        resourceId: this.resourceId,
        shuttleType: '',
        ...params
      });
    },
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    handlePage(params) {
      this.fetchData({
        ...this.searchParams,
        ...params
      });
    }
  }
};