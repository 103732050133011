var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableTotal", {
    attrs: {
      "column-data": _vm.columnData,
      data: _vm.data
    },
    scopedSlots: _vm._u([{
      key: "planNumber",
      fn: function ({}) {
        return [_vm._v(" 总计 ")];
      }
    }, {
      key: "number",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalAdultCount) + "大" + _vm._s(_vm.data.totalChildCount) + "小 " + _vm._s(_vm.data.totalAccompanyCount) + "陪 ")];
      }
    }, {
      key: "adultCountItem",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.adultCount) + " ")];
      }
    }, {
      key: "childCountItem",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.childCount) + " ")];
      }
    }, {
      key: "accompanyCountItem",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.accompanyCount) + " ")];
      }
    }, {
      key: "orderCount",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.count || 0) + " ")];
      }
    }, {
      key: "adultFreeCount",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.adultFreeCount || _vm.data.freeCount || 0) + " ")];
      }
    }, {
      key: "freeCount",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.freeCount || 0) + " ")];
      }
    }, {
      key: "otherFee",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.otherPayCount) + " ")];
      }
    }, {
      key: "checkAccountMoney",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.checkAccountMoneyCount) + " ")];
      }
    }, {
      key: "totalIncomed",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalIncomed) + " ")];
      }
    }, {
      key: "totalSubsidy",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalSubsidyCount || _vm.data.subsidyCount) + " ")];
      }
    }, {
      key: "guidePay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.guidePayCount || 0) + " ")];
      }
    }, {
      key: "guideIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.guidePayCount || 0) + " ")];
      }
    }, {
      key: "totalSettlement",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalSettlementCount) + " ")];
      }
    }, {
      key: "unTotalIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.unIncomeCount) + " ")];
      }
    }, {
      key: "unPay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.unTotalPay) + " ")];
      }
    }, {
      key: "totalIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalPayCount) + " ")];
      }
    }, {
      key: "totalCollect",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalCollect || _vm.data.totalCollectCount) + " ")];
      }
    }, {
      key: "totalPay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalPayCount) + " ")];
      }
    }, {
      key: "totalPayed",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.totalPayedCount || 0) + " ")];
      }
    }, {
      key: "agencyIncome",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.agencyIncome || 0) + " ")];
      }
    }, {
      key: "alipayECodePay",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.alipayECodePay || 0) + " ")];
      }
    }, {
      key: "peopleCount",
      fn: function ({}) {
        return [_vm._v(" " + _vm._s(_vm.data.peopleCount || 0) + " ")];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };