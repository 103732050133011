// 旅检地接行程状态
export const ItineraryStatusMap = {
  wait: 0,
  // 待提交
  commit: 1,
  // 已提交
  start: 2,
  // 已出团
  end: 3,
  // 已结束
  cancel: 4 // 已取消
};

// 类型
export const ItineraryType = {
  team: 'team',
  custom: 'custom',
  inbound: 'inbound'
};

// 类型对应的行程Id key
export const ItineraryIdType = {
  team: 'itineraryReceptionId',
  custom: 'itineraryReceptionId',
  inbound: 'itineraryInboundId'
};

// 类型对应的行程Id key
export const ItineraryIdSetType = {
  team: 'receptionId',
  custom: 'receptionId',
  inbound: 'receptionId'
};

// 类型
export const ItineraryUrlType = {
  team: '/api/kingon-glly/itineraryReception/V2',
  custom: '/api/kingon-glly/itineraryCustom/V2',
  inbound: '/api/kingon-glly/itineraryInbound/V2'
};