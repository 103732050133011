import { orderRefundDetail } from '@/config/order';
import Reception from '@/components/common/Order/Reception';
import { reloadMixin } from '@/mixin';
export default {
  components: {
    Reception
  },
  mixins: [reloadMixin],
  data() {
    return {
      columnData: orderRefundDetail
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const {
        id
      } = this.$route.query;
      this.$nextTick(() => {
        this.$store.dispatch('order/reception/fetchTurnOut', id);
      });
    }
  }
};