var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "status-box flex el-input"
  }, [_vm.label ? _c("LabelBase", {
    attrs: {
      label: _vm.label
    }
  }) : _vm._e(), _c("el-switch", _vm._g(_vm._b({
    model: {
      value: _vm.getValue,
      callback: function ($$v) {
        _vm.getValue = $$v;
      },
      expression: "getValue"
    }
  }, "el-switch", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };