import "core-js/modules/es.array.push.js";
import DayItem from './components/DayItem';
import { SwitchStatus, SelectLine, InputRemarks, FooterBar, SelectDictionary } from '@/components/common';
import { defDictionary } from '@/utils/dictionary';
import { mapGetters } from 'vuex';
import { tagViewMixin } from '@/mixin';
const MAX_TRIP_DAYS = 15;
const defOpts = () => ({
  days: 0,
  displayType: 0,
  travelLineId: 0,
  name: '',
  // departmentId: 0,
  // grandsonDepartmentId: 0,
  // subDepartmentId: 0,
  isEnable: true,
  itineraries: [],
  remarks: {}
});
export default {
  components: {
    SwitchStatus,
    InputRemarks,
    SelectLine,
    DayItem,
    FooterBar,
    SelectDictionary
  },
  mixins: [tagViewMixin],
  data() {
    return {
      activeName: 'days',
      maxTripDays: MAX_TRIP_DAYS,
      isUpdate: false,
      type: 'create',
      form: {
        remarks: {},
        days: 2,
        itineraries: [],
        isEnable: true,
        travelLine: '',
        travelLineId: '',
        displayType: 0,
        productType: defDictionary(this.$store, 'productType') ? defDictionary(this.$store, 'productType').label : '',
        linenName: ''
      },
      dayList: [],
      productId: '',
      removeCache: {},
      removeShops: {},
      removeDay: [],
      daysStatus: false
    };
  },
  computed: {
    ...mapGetters({
      scenicDayLis: 'resource/scenic/scenicDayList'
    }),
    getScenicData() {
      return this.$store.getters['resource/scenic/scenicData'];
    },
    defProductType() {
      const item = defDictionary('productType');
      return item ? item.label : '';
    }
  },
  created() {
    const {
      id,
      type
    } = this.$route.query;
    this.isUpdate = type == 'update' ? true : false;
    this.productId = id;
    this.type = type;
    id && this.fetchProduct(id);
    if (!id) {
      this.daysStatus = true;
    }
    this.needFetchScenicData();
  },
  mounted() {
    this.$bus.on('removeCacheList', this.removeCacheList);
    this.$bus.on('removeShopsList', this.removeShopsList);
    this.$bus.on('removeDayItem', this.removeDayItem);
    this.$bus.on('fetchProduct', this.fetchProduct);
  },
  methods: {
    fetchProduct(id) {
      this.$store.dispatch('resource/product/fetchDetail', id).then(res => {
        this.form.itineraries = res.itineraries;
        this.dayList = res.itineraries;
        this.daysStatus = true;
        this.form = {
          ...res
        };
        this.form.productType = this.form.productType || this.defProductType;
      });
    },
    needFetchScenicData() {
      if (!this.getScenicData) {
        this.$store.dispatch('resource/scenic/fetchList');
      }
    },
    changeRemarks(type, val) {
      this.form.remarks[type] = val;
    },
    selectLine(val) {
      this.form.travelLineId = val.id;
      this.form.linenName = val.name;
    },
    onSelectProductType(val) {
      this.form.productType = val.label;
    },
    removeDayItem(item) {
      item.isDelete = true;
      item.productId = this.productId;
      this.removeDay.push(item);
    },
    // 删除景区
    removeCacheList({
      item,
      index,
      day
    }) {
      const {
        id,
        scenicName
      } = item.scenics[index];
      if (!this.removeCache[day]) {
        this.removeCache[day] = [];
      }
      // 将要删除的项目加入缓存
      this.removeCache[day].push({
        id,
        scenicName,
        isDelete: true
      });
    },
    // 删除购物店

    removeShopsList({
      item,
      index,
      day
    }) {
      const {
        id,
        shopName
      } = item.shops[index];
      if (!this.removeShops[day]) {
        this.removeShops[day] = [];
      }
      // 将要删除的项目加入缓存
      this.removeShops[day].push({
        id,
        shopName,
        isDelete: true
      });
    },
    handleCreate() {
      if (!this.validateForm()) return;
      let opts = {
        ...defOpts(),
        ...this.form
      };
      opts.itineraries = this.dayList.map((day, index) => {
        let removeCache = this.removeCache[index];
        let removeShops = this.removeShops[index];
        this.isUpdate ? day.productId = this.productId : null;
        day.whichDay = index + 1;
        day.scenics = day.scenics.filter(it => it.scenicId > 0);
        day.shops = day.shops.filter(it => it.shopId > 0);
        day.productId = this.productId;
        removeCache ? day.scenics = [...day.scenics, ...removeCache] : day.scenics = [...day.scenics];
        removeShops ? day.shops = [...day.shops, ...removeShops] : day.shops = [...day.shops];
        return day;
      });
      // 克隆产品 删除所有的ID
      if (this.type == 'clone') {
        delete opts.id;
        // opts.remarks = this.deleteItemId(opts.remarks)
        // opts.customers = this.deleteItemId(opts.customers)
        opts.itineraries = opts.itineraries.map(it => {
          delete it.id;
          it.scenics = this.deleteItemId(it.scenics);
          it.shops = this.deleteItemId(it.shops);
          return it;
        });
      }
      opts.itineraries = [...opts.itineraries, ...this.removeDay];
      // 添加判断,如果不是最后一天,isSend送团状态都为false
      // opts.itineraries = this.formatIsSend(opts.itineraries)
      this.$store.dispatch('resource/product/createOrUpdate', opts).then(() => {
        this.$bus.tip();
        // 关闭当前页面
        this.closeSelectedTag();
        if (this.isUpdate) {
          this.fetchProduct(this.productId);
          this.$store.dispatch('resource/product/fetchList');
        }
        this.$store.dispatch('resource/product/fetch');
      }).catch(() => {
        this.fetchProduct(this.productId);
      });
    },
    formatIsSend(list) {
      list = list.map((it, index) => {
        if (list.length + 1 != index) {
          it.isSend = false;
          return it;
        }
      });
      return list;
    },
    changeDays(val) {
      this.form.days = val;
    },
    handleDayList(data) {
      this.dayList = data;
    },
    deleteItemId(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        delete it.id;
        return it;
      });
    },
    validateScenic() {
      for (let i = 0; i < this.dayList.length; i++) {
        const item = this.dayList[i];
        if (item.scenics.length && item.scenics.every(it => it.scenicId != '')) {
          continue;
        } else {
          return false;
        }
      }
      return true;
    },
    validateForm() {
      const {
        name,
        productType
      } = this.form;
      if (!name) {
        this.$bus.tip({
          type: 'warning',
          message: '请输入产品名称'
        });
        return false;
      }
      if (!productType) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择产品类型'
        });
        return false;
      }
      return true;
    }
  }
};