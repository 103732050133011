import { orderType } from '@/config/order';
import { OrderDetail } from '@/components/common';
export default {
  components: {
    OrderDetail
  },
  data() {
    return {
      OrderType: orderType
    };
  }
};