var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "plan-tab-item plan-border-table plan-shop-table"
  }, [_c("TopBar", {
    attrs: {
      title: "购物安排",
      disabled: _vm.isBtnDisable,
      "has-sign": true
    },
    on: {
      "on-add": _vm.handlePlus
    }
  }), _vm.visible ? _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      "summary-method": _vm.getSummaries,
      "row-class-name": _vm.tableRowClassName,
      "show-summary": "",
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "auditAndCheckStatus",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [_c("AuditStatus", {
          attrs: {
            row: scope.row
          }
        }), _c("CheckStatus", {
          attrs: {
            row: scope.row
          }
        })], 1) : _c("div", [_vm._v(" " + _vm._s(scope.row.totalText) + " ")])];
      }
    }, {
      key: "shopName",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("SelectShop", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: "",
            all: false
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectShop(scope.row, $event);
            }
          },
          model: {
            value: scope.row.shopName,
            callback: function ($$v) {
              _vm.$set(scope.row, "shopName", $$v);
            },
            expression: "scope.row.shopName"
          }
        }) : _vm._e()];
      }
    }, {
      key: "orderDate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputDate", {
          staticClass: "shop-input-date",
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            "value-format": "yyyy-MM-dd",
            label: ""
          },
          model: {
            value: scope.row.orderDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "orderDate", $$v);
            },
            expression: "scope.row.orderDate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "guide",
      fn: function ({
        scope
      }) {
        return [_c("SelectGuide", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.selectGuide(scope.row, $event);
            }
          },
          model: {
            value: scope.row.guideName,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideName", $$v);
            },
            expression: "scope.row.guideName"
          }
        })];
      }
    }, {
      key: "headPrice",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [_vm._v(" " + _vm._s(scope.row.headPrice) + " ")]) : _vm._e()];
      }
    }, {
      key: "totalHeadRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [_vm._v(" " + _vm._s(scope.row.totalHeadRebate) + " ")]) : _vm._e()];
      }
    }, {
      key: "enterCount",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeEnterCount(scope.row, $event);
            }
          },
          model: {
            value: scope.row.enterStoreMemberCount,
            callback: function ($$v) {
              _vm.$set(scope.row, "enterStoreMemberCount", $$v);
            },
            expression: "scope.row.enterStoreMemberCount"
          }
        }) : _vm._e()];
      }
    }, {
      key: "goodsName",
      fn: function ({
        scope
      }) {
        return [scope.row.goodsList && scope.row.goodsList.length && !_vm.isTotal(scope.row) ? _c("div", {
          staticClass: "flex ticket"
        }, [_c("SelectData", {
          attrs: {
            list: scope.row.goodsList,
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.handleSelect(scope.row, $event);
            }
          },
          model: {
            value: scope.row.name,
            callback: function ($$v) {
              _vm.$set(scope.row, "name", $$v);
            },
            expression: "scope.row.name"
          }
        }), scope.row.itemIndex > 0 ? _c("i", {
          staticClass: "el-icon-remove operation-icon",
          staticStyle: {
            color: "#ffba00"
          },
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleRow(scope);
            }
          }
        }) : _c("i", {
          staticClass: "el-icon-circle-plus operation-icon",
          staticStyle: {
            color: "#1890ff"
          },
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleRow(scope);
            }
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "money",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changePrintMoney(scope.row, $event);
            }
          },
          model: {
            value: scope.row.money,
            callback: function ($$v) {
              _vm.$set(scope.row, "money", $$v);
            },
            expression: "scope.row.money"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.moneyTotal) + " ")])];
      }
    }, {
      key: "orderInfo",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("SelectData", {
          attrs: {
            list: _vm.customerTripNumberList,
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            "on-select": function ($event) {
              return _vm.handleSelectTripNumber(scope.row, $event);
            }
          },
          model: {
            value: scope.row.customerOrderId,
            callback: function ($$v) {
              _vm.$set(scope.row, "customerOrderId", $$v);
            },
            expression: "scope.row.customerOrderId"
          }
        }) : _vm._e()];
      }
    }, {
      key: "shopRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeShopRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.shopRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "shopRate", $$v);
            },
            expression: "scope.row.shopRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "shopRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.shopRebate,
            callback: function ($$v) {
              _vm.$set(scope.row, "shopRebate", $$v);
            },
            expression: "scope.row.shopRebate"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.shopRebateTotal) + " ")])];
      }
    }, {
      key: "redEnvelope",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.redEnvelope,
            callback: function ($$v) {
              _vm.$set(scope.row, "redEnvelope", $$v);
            },
            expression: "scope.row.redEnvelope"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.redEnvelopeTotal) + " ")])];
      }
    }, {
      key: "guideRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeGuideRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.guideRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideRate", $$v);
            },
            expression: "scope.row.guideRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "guideRebateDeduction",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeGuideRate(scope.row);
            }
          },
          model: {
            value: scope.row.guideRebateDeduction,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideRebateDeduction", $$v);
            },
            expression: "scope.row.guideRebateDeduction"
          }
        }) : _vm._e()];
      }
    }, {
      key: "guideRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.guideRebate,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideRebate", $$v);
            },
            expression: "scope.row.guideRebate"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.guideRebateTotal) + " ")])];
      }
    }, {
      key: "driverRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeDriverRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.driverRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "driverRate", $$v);
            },
            expression: "scope.row.driverRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "driverRebateDeduction",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeDriverRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.driverRebateDeduction,
            callback: function ($$v) {
              _vm.$set(scope.row, "driverRebateDeduction", $$v);
            },
            expression: "scope.row.driverRebateDeduction"
          }
        }) : _vm._e()];
      }
    }, {
      key: "driverRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.driverRebate,
            callback: function ($$v) {
              _vm.$set(scope.row, "driverRebate", $$v);
            },
            expression: "scope.row.driverRebate"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.driverRebateTotal) + " ")])];
      }
    }, {
      key: "accompanyRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeAccompanyRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.accompanyRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "accompanyRate", $$v);
            },
            expression: "scope.row.accompanyRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "accompanyRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.accompanyRebate,
            callback: function ($$v) {
              _vm.$set(scope.row, "accompanyRebate", $$v);
            },
            expression: "scope.row.accompanyRebate"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.accompanyRebateTotal) + " ")])];
      }
    }, {
      key: "guideManagerRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.changeGuideManagerRate(scope.row, $event);
            }
          },
          model: {
            value: scope.row.guideManagerRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideManagerRate", $$v);
            },
            expression: "scope.row.guideManagerRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "guideManagerRebate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.calcTravelProfit(scope.row);
            }
          },
          model: {
            value: scope.row.guideManagerRebate,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideManagerRebate", $$v);
            },
            expression: "scope.row.guideManagerRebate"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.guideManagerRebateTotal) + " ")])];
      }
    }, {
      key: "travelGrossProfit",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [_vm._v(" " + _vm._s(scope.row.travelGrossProfit) + " ")]) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.travelGrossProfitTotal) + " ")])];
      }
    }, {
      key: "guideCollect",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputNum", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          model: {
            value: scope.row.guideCollect,
            callback: function ($$v) {
              _vm.$set(scope.row, "guideCollect", $$v);
            },
            expression: "scope.row.guideCollect"
          }
        }) : _c("div", {
          staticClass: "red"
        }, [_vm._v(" " + _vm._s(scope.row.guideCollectTotal) + " ")])];
      }
    }, {
      key: "shopRemark",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputBase", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          model: {
            value: scope.row.shopRemark,
            callback: function ($$v) {
              _vm.$set(scope.row, "shopRemark", $$v);
            },
            expression: "scope.row.shopRemark"
          }
        }) : _vm._e()];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputBase", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          model: {
            value: scope.row.remark,
            callback: function ($$v) {
              _vm.$set(scope.row, "remark", $$v);
            },
            expression: "scope.row.remark"
          }
        }) : _vm._e()];
      }
    }, {
      key: "subsidyRate",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputBase", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          on: {
            change: function ($event) {
              return _vm.calcSubsidy(scope.row);
            }
          },
          model: {
            value: scope.row.subsidyRate,
            callback: function ($$v) {
              _vm.$set(scope.row, "subsidyRate", $$v);
            },
            expression: "scope.row.subsidyRate"
          }
        }) : _vm._e()];
      }
    }, {
      key: "subsidy",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("InputBase", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row),
            label: ""
          },
          model: {
            value: scope.row.subsidy,
            callback: function ($$v) {
              _vm.$set(scope.row, "subsidy", $$v);
            },
            expression: "scope.row.subsidy"
          }
        }) : _vm._e()];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        }) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [_c("el-checkbox", {
          attrs: {
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            change: function ($event) {
              return _vm.handleCheckOne(scope.row, $event);
            }
          },
          model: {
            value: scope.row.isChecked,
            callback: function ($$v) {
              _vm.$set(scope.row, "isChecked", $$v);
            },
            expression: "scope.row.isChecked"
          }
        }), _c("span", {
          staticClass: "pr-5"
        }), _c("el-button", {
          attrs: {
            type: "text",
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleRemove(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])], 1) : _vm._e()];
      }
    }, {
      key: "audit",
      fn: function ({
        scope
      }) {
        return [!_vm.isTotal(scope.row) ? _c("div", [!_vm.isAdminAudit ? _c("Audit", {
          attrs: {
            row: scope.row
          },
          on: {
            "on-check": _vm.checkAuditSelectAll
          }
        }) : _c("AuditAdmin", {
          attrs: {
            row: scope.row
          },
          on: {
            "on-select": val => scope.row.auditProgress = val
          },
          model: {
            value: scope.row.auditProgress,
            callback: function ($$v) {
              _vm.$set(scope.row, "auditProgress", $$v);
            },
            expression: "scope.row.auditProgress"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "auditAction",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("AuditCheck", {
          attrs: {
            row: scope.row,
            checkval: _vm.auditAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.auditAll = $event;
            },
            "on-check": _vm.checkAuditAll
          }
        }) : _c("span", [_c("AuditRoleAll", {
          on: {
            "on-select": _vm.handleAuditAll
          }
        })], 1)];
      }
    }, {
      key: "deleteAction",
      fn: function ({
        scope
      }) {
        return [_c("DelCheckAll", {
          attrs: {
            row: scope.row,
            checkval: _vm.checkedAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.checkedAll = $event;
            },
            "on-check": _vm.handleCheckAll,
            "on-remove": _vm.handleRemoveAll
          }
        })];
      }
    }], null, false, 1711467901)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };