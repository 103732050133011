import TableList from './components/TableList';
import DialogBill from './components/DialogBill.vue';
import { downBillGuide } from '@/config/billDown';
import { SelectGuide } from '@/components/common';
import { searchMixin } from '@/mixin';
import { processMinus } from '@/utils/number';
import { Sms, DialogTopUp, DialogTopUpRecord } from '@/components/common';
export default {
  components: {
    TableList,
    SelectGuide,
    DialogBill,
    Sms,
    DialogTopUp,
    DialogTopUpRecord
  },
  mixins: [searchMixin],
  data() {
    return {
      // path: '/bill/unit-pay/guide-create',
      tableData: [],
      columnData: downBillGuide,
      total: 0,
      sendDate: [],
      searchParams: {},
      statistics: null
    };
  },
  computed: {
    getUnpay() {
      return processMinus(this.statistics.travelAgencyPay, this.statistics.travelAgencyPayed);
    }
  },
  watch: {
    getTagViewSearch: {
      handler: function (data) {
        if (!data) return;
        this.searchParams = data;
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData(this.searchParams);
    });
  },
  methods: {
    fetchData(queryParmas = {}) {
      this.$store.dispatch('bill/billDown/fetchGuide', queryParmas).then(data => {
        if (!data) return;
        this.handleData(data);
      });
    },
    reloadData() {
      this.fetchData(this.searchParams);
    },
    changeStarDate(val) {
      val = val || ['', ''];
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      // 兼容审核状态组件
      this.tableData = list.map(it => {
        it.unPay = processMinus(it.travelAgencyPay, it.travelAgencyPayed);
        it.auditProgress = it.auditStatus;
        return it;
      });
      this.total = totalCount;
      this.statistics = statistics;
      this.$store.commit('bill/billDown/RELOAD_GUIDE_DATA', false);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        ...this.searchParams,
        pageNum,
        pageSize
      });
    },
    topupDialog() {
      this.$refs.dialogTopUpRef.show();
    },
    wechatPaySuccess() {
      this.reload();
      this.$refs.dialogTopUpRef.hide();
    },
    topUpRecordDialog() {
      this.$refs.dialogTopUpRecordRef.show();
    },
    wechatPayDialog(data) {
      this.$refs.dialogWxPayRef.show(data);
    },
    showDialog(params) {
      this.$refs.dialogRef.show(params);
    }
  }
};