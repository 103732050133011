import { StatusAudit } from '@/components/common';
import { PersonCount } from '@/components/common';
import { tableMixin, rowspanMixin, billMixin, permissionMixin } from '@/mixin';
import DialogShopRemark from './DialogShopRemark';
import { TYPES } from '@/config/audit';
import { checkBillShopping, checkBillShoppingAll, statisticsKeys } from '@/config/billCheck';
import billBaseMixin from '../billBaseMixin';
import { tableSummaryFinally } from '@/utils';
import { calcCheckedMoney } from '@/views/billCheck/helper';
export default {
  components: {
    PersonCount,
    StatusAudit,
    DialogShopRemark
  },
  mixins: [tableMixin, rowspanMixin, billMixin, permissionMixin, billBaseMixin],
  data() {
    return {
      calcCheckedMoney,
      headMergeNum: 10,
      tailMergeNum: checkBillShopping.length - 8,
      hasMergeTail: true,
      billCheckAll: false,
      statisticsKeys,
      type: TYPES.shop
    };
  },
  created() {
    const SubsidyTwo = this.hasSubsidyTwoPrice(); // 二返权限
    this.tailMergeNum = SubsidyTwo ? checkBillShoppingAll.length - 9 : checkBillShopping.length - 8;
  },
  methods: {
    onShopRemark({
      row
    }) {
      const {
        shopId,
        planId
      } = row;
      this.$store.dispatch('report/index/fetchshopGoodsList', {
        shopId,
        planId
      }).then(res => {
        this.$refs.shopRemarkRef.show(res);
      });
    },
    getSummaries(param) {
      return tableSummaryFinally({
        param,
        mergeKeys: ['enterStoreMemberCount', 'totalBuyMoney', 'totalHeadRebate', 'totalGuideCollect', 'totalShopRebated', 'totalRedEnvelopeReimbed', 'unTotalIncome', 'subsidy', 'checkAccountMoney']
        // checkKeys: ['checkAccountMoney', 'ckStatus'],
      });
    }
  }
};