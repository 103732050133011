var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.goods ? _c("InputData", _vm._g(_vm._b({
    attrs: {
      list: _vm.goods,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      label: "商品",
      placeholder: "请选择商品"
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };