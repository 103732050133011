import "core-js/modules/es.array.push.js";
/* eslint-disable no-unused-vars */
import { fetchPickup, updatePickup, deletePickup, fetchPickupOrder, updatePickupOrder, deletePickupOrder, fetchPickupOrderList, selectPickupOrderList, deletePickupOrderItem } from '@/api/plan/pickup';
import { deleteFleetItem } from '@/api/plan/fleet';
import { DEFAULT_PAGE } from '@/config';
const stateTpl = {
  pickupData: null,
  pickupOrderData: null
};
const state = window._deepClone(stateTpl);
const getters = {
  pickupData: state => state.pickupData,
  pickupOrderData: state => state.pickupOrderData
};
const actions = {
  // 获取 安排
  fetch({
    commit
  }, params) {
    params = {
      ...DEFAULT_PAGE,
      ...params
    };
    return fetchPickup(params).then(res => {
      const {
        data
      } = res;
      commit('PICKUP_DATA', data.list);
      return data;
    });
  },
  // 更新 安排
  update({
    commit,
    rootGetters
  }, opts) {
    return updatePickup(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除 安排
  delete({
    commit,
    rootGetters
  }, ids) {
    return deletePickup(ids).then(res => {
      const {
        data
      } = res;
      this.dispatch('fetch');
      return data;
    });
  },
  // 子项删除 安排
  deleteItem({
    commit
  }, id) {
    return deleteFleetItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 获取 订单
  fetchOrder({
    commit
  }, params) {
    return fetchPickupOrder(params).then(res => {
      const {
        data
      } = res;
      commit('PICKUP_ORDER_DATA', data);
      return data;
    });
  },
  // 更新 订单
  updateOrder({
    commit
  }, opts) {
    return updatePickupOrder(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 移除 订单
  deleteOrder({
    commit
  }, id) {
    return deletePickupOrder(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 子项删除 订单
  deleteOrderItem({
    commit
  }, id) {
    return deletePickupOrderItem(id).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 选择添加的订单的列表
  fetchPickupOrderList({
    commit
  }, opts) {
    return fetchPickupOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 添加或移除的订单
  selectPickupOrderList({
    commit
  }, opts) {
    return selectPickupOrderList(opts).then(res => {
      const {
        data
      } = res;
      return data;
    });
  }
};
const mutations = {
  RESET_ALL: state => {
    Object.assign(state, stateTpl);
  },
  PICKUP_DATA: (state, data) => {
    state.pickupData = data;
  },
  PICKUP_DELETE: (state, {
    planId,
    ids
  }) => {
    let pickupList = state.pickupData;
    const list = [];
    pickupList.forEach((it, idx) => {
      if (!ids.includes(it.id)) {
        list.push(it);
      }
    });
    state.pickupData = list;
  },
  PICKUP_ORDER_DATA: (state, data) => {
    state.pickupOrderData = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};