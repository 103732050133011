import { inputMixin } from '@/mixin';
export default {
  name: 'RadioGroup',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'radio'
    },
    isRequire: {
      type: Boolean,
      default: false
    },
    radios: {
      type: Array,
      required: true
    }
  }
};