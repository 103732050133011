/* eslint-disable no-empty-pattern */
import { addDownPay, fetchUnPayGuide } from '@/api/bill/billDown';
import { TYPES } from '@/config/billDown';
import { BILL_PAGE_COUNT } from '@/config';
import { formatList, calcGuideOwePay } from '@/store/modules/bill/billDown';
const dfaultState = () => ({
  prePaymentMoney: 0,
  guideUnPay: null
});
const state = dfaultState();
const getters = {
  guideUnPay: state => state.guideUnPay
};
const actions = {
  // 添加付款 导游
  createPay({}, opts) {
    const cashType = TYPES.reimb; //导游报账

    return addDownPay({
      cashType,
      ...opts
    });
  },
  // 导游 未结清
  fetchUnPay({
    commit
  }, params) {
    params = {
      ...BILL_PAGE_COUNT,
      ...params
    };
    return fetchUnPayGuide(params).then(res => {
      const {
        data
      } = res;
      data.list = formatList(data.list, calcGuideOwePay);
      commit('UNPAY_DATA', data);
      return data;
    });
  }
};
const mutations = {
  UNPAY_DATA(state, data) {
    state.guideUnPay = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};