import "core-js/modules/es.array.push.js";
import { rowspanMixin } from '@/mixin';
import { mapGetters } from 'vuex';
import { HotelColumn as columns } from '@/config/billDown/payRegiment/hotel';
export default {
  mixins: [rowspanMixin],
  data: () => ({
    path: '/bill/regiment-pay/hotel-create',
    columns,
    isSticky: true
  }),
  computed: {
    ...mapGetters({
      data: 'bill/billDown/payRegimentHotel/data',
      total: 'bill/billDown/payRegimentHotel/total',
      tableData: 'bill/billDown/payRegimentHotel/list'
    })
  },
  watch: {
    data: {
      handler: function (data) {
        if (!data) this.$emit('page', {});
        this.rowspan();
      },
      immediate: true
    }
  },
  methods: {
    handlePay({
      rowId,
      hotelId: rid
    }) {
      this.$router.push({
        path: this.path,
        query: {
          id: rowId,
          rid
        }
      });
    }
  }
};