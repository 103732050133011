import { inputMixin } from '@/mixin';

/**
 * @module components/base/Input
 * @desc 部门与子部门组件
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直排序
 * @vue-prop {Boolean} [isRequire=false] - 是否必填项
 * @vue-prop {Array} list - 输入框下拉数据列表
 */
export default {
  name: 'InputCascader',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    // value: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },

    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('update:value');
      }
    }
  },
  methods: {
    onMouseEnter() {
      const that = this;
      document.querySelectorAll('.el-icon-circle-close').forEach(el => {
        el.onclick = function () {
          that.$emit('clear');
        };
      });
    },
    handleClick() {
      let that = this;
      setTimeout(function () {
        document.querySelectorAll('.el-cascader-node__label').forEach(el => {
          el.onclick = function () {
            this.previousElementSibling.click();
            that.$refs.cascaderRef.dropDownVisible = false;
          };
        });
        document.querySelectorAll('.el-cascader-panel .el-radio').forEach(el => {
          el.onclick = function () {
            that.$refs.cascaderRef.dropDownVisible = false;
          };
        });
      }, 100);
    }
  }
};