var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-fix-bar supervise-travel"
  }, [_c("h2", {
    staticClass: "blue mb20"
  }, [_vm._v("基本信息")]), _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      size: "small",
      "label-width": "125px",
      inline: ""
    }
  }, [_c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "团队编号",
      prop: "team_id"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      disabled: ""
    },
    model: {
      value: _vm.form.team_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "team_id", $$v);
      },
      expression: "form.team_id"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "线路名称",
      prop: "itinerary_name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.itinerary_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "itinerary_name", $$v);
      },
      expression: "form.itinerary_name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "游玩天数",
      prop: "itinerary_day"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.itinerary_day,
      callback: function ($$v) {
        _vm.$set(_vm.form, "itinerary_day", $$v);
      },
      expression: "form.itinerary_day"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "团队类型",
      prop: "team_type"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "teamTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.team_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "team_type", $$v);
      },
      expression: "form.team_type"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "业务类型",
      prop: "business_type"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "businessTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.business_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "business_type", $$v);
      },
      expression: "form.business_type"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "地接旅行社",
      prop: "agency_name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.agency_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "agency_name", $$v);
      },
      expression: "form.agency_name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "大人数",
      prop: "people_nums"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.people_nums,
      callback: function ($$v) {
        _vm.$set(_vm.form, "people_nums", $$v);
      },
      expression: "form.people_nums"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "小孩数",
      prop: "child_nums"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.child_nums,
      callback: function ($$v) {
        _vm.$set(_vm.form, "child_nums", $$v);
      },
      expression: "form.child_nums"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "出发省份",
      prop: "source_province"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.source_province,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source_province", $$v);
      },
      expression: "form.source_province"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "出发城市",
      prop: "source_city"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.source_city,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source_city", $$v);
      },
      expression: "form.source_city"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "出发大交通",
      prop: "vehicle"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "vehicleTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.vehicle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vehicle", $$v);
      },
      expression: "form.vehicle"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "出发车次/班次",
      prop: "vehicle_out_nums"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.vehicle_out_nums,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vehicle_out_nums", $$v);
      },
      expression: "form.vehicle_out_nums"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "出发日期",
      prop: "date_go"
    }
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_c("InputDate", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.date_go,
      callback: function ($$v) {
        _vm.$set(_vm.form, "date_go", $$v);
      },
      expression: "form.date_go"
    }
  })], 1)]), _c("FormItemBase", {
    attrs: {
      label: "出发时间",
      prop: "time_go"
    }
  }, [_c("el-time-select", {
    attrs: {
      "picker-options": {
        start: "06:30",
        step: "00:10",
        end: "22:50"
      },
      placeholder: "任意时间点"
    },
    model: {
      value: _vm.form.time_go,
      callback: function ($$v) {
        _vm.$set(_vm.form, "time_go", $$v);
      },
      expression: "form.time_go"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "目的地省份",
      prop: "destination_province"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.destination_province,
      callback: function ($$v) {
        _vm.$set(_vm.form, "destination_province", $$v);
      },
      expression: "form.destination_province"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "目的地城市",
      prop: "destination_city"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.destination_city,
      callback: function ($$v) {
        _vm.$set(_vm.form, "destination_city", $$v);
      },
      expression: "form.destination_city"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "返回大交通",
      prop: "vehicle_in"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "vehicleTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.vehicle_in,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vehicle_in", $$v);
      },
      expression: "form.vehicle_in"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "返回车次/班次",
      prop: "vehicle_in_nums"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.vehicle_in_nums,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vehicle_in_nums", $$v);
      },
      expression: "form.vehicle_in_nums"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "返回日期",
      prop: "date_back"
    }
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_c("InputDate", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.date_back,
      callback: function ($$v) {
        _vm.$set(_vm.form, "date_back", $$v);
      },
      expression: "form.date_back"
    }
  })], 1)]), _c("FormItemBase", {
    attrs: {
      label: "返回时间",
      prop: "time_back"
    }
  }, [_c("el-time-select", {
    attrs: {
      "picker-options": {
        start: "06:30",
        step: "00:10",
        end: "22:50"
      },
      placeholder: "任意时间点"
    },
    model: {
      value: _vm.form.time_back,
      callback: function ($$v) {
        _vm.$set(_vm.form, "time_back", $$v);
      },
      expression: "form.time_back"
    }
  })], 1)], 1), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "行程描述",
      prop: "journey_desc"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea",
      label: ""
    },
    model: {
      value: _vm.form.journey_desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "journey_desc", $$v);
      },
      expression: "form.journey_desc"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "拼团及备注",
      prop: "remark"
    }
  }, [_c("InputBase", {
    attrs: {
      type: "textarea",
      label: ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1), _c("h2", {
    staticClass: "blue mb20 mt20"
  }, [_vm._v("导游信息")]), _c("div", {
    staticClass: "form-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "导游",
      prop: "guide.name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.guide.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.guide, "name", $$v);
      },
      expression: "form.guide.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游证号",
      prop: "guide.card_id"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.guide.card_id,
      callback: function ($$v) {
        _vm.$set(_vm.form.guide, "card_id", $$v);
      },
      expression: "form.guide.card_id"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导游类型",
      prop: "guide.type"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "guideTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.guide.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.guide, "type", $$v);
      },
      expression: "form.guide.type"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "是否领队",
      prop: "guide.is_leader"
    }
  }, [_c("SelectBase2", {
    attrs: {
      type: "isLeaderTypeCountry",
      label: ""
    },
    model: {
      value: _vm.form.guide.is_leader,
      callback: function ($$v) {
        _vm.$set(_vm.form.guide, "is_leader", $$v);
      },
      expression: "form.guide.is_leader"
    }
  })], 1)], 1)]), _vm.form.itinerary_list.length ? _c("TableItinerary", {
    ref: "tineraryRef",
    staticClass: "mt20",
    attrs: {
      data: _vm.form.itinerary_list
    }
  }) : _vm._e(), _c("TableBase", {
    attrs: {
      data: _vm.form.tourist_list,
      "column-data": _vm.ColumnTourist,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "idType",
      fn: function ({
        scope
      }) {
        return [_c("SelectBase2", {
          attrs: {
            type: "cardTypeCountry"
          },
          model: {
            value: scope.row.cardType,
            callback: function ($$v) {
              _vm.$set(scope.row, "cardType", $$v);
            },
            expression: "scope.row.cardType"
          }
        })];
      }
    }, {
      key: "cardNumber",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          model: {
            value: scope.row.cardNumber,
            callback: function ($$v) {
              _vm.$set(scope.row, "cardNumber", $$v);
            },
            expression: "scope.row.cardNumber"
          }
        })];
      }
    }, {
      key: "birthDate",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          model: {
            value: scope.row.birthDate,
            callback: function ($$v) {
              _vm.$set(scope.row, "birthDate", $$v);
            },
            expression: "scope.row.birthDate"
          }
        })];
      }
    }, {
      key: "phoneNumber",
      fn: function ({
        scope
      }) {
        return [_c("el-input", {
          model: {
            value: scope.row.phone_num,
            callback: function ($$v) {
              _vm.$set(scope.row, "phone_num", $$v);
            },
            expression: "scope.row.phone_num"
          }
        })];
      }
    }, {
      key: "validFrom",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          model: {
            value: scope.row.cardItem.valid_from,
            callback: function ($$v) {
              _vm.$set(scope.row.cardItem, "valid_from", $$v);
            },
            expression: "scope.row.cardItem.valid_from"
          }
        })];
      }
    }, {
      key: "validTo",
      fn: function ({
        scope
      }) {
        return [_c("InputDate", {
          model: {
            value: scope.row.cardItem.valid_to,
            callback: function ($$v) {
              _vm.$set(scope.row.cardItem, "valid_to", $$v);
            },
            expression: "scope.row.cardItem.valid_to"
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            icon: "el-icon-delete"
          },
          on: {
            click: function ($event) {
              return _vm.deleteMember(scope.row);
            }
          }
        })];
      }
    }])
  }), _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: _vm.handleUpload
    }
  }, [_vm._v(" 上传 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };