import { orderTouristListDetail } from '@/config/order';
import { SELECTOR } from '@/config/select';
export default {
  data() {
    return {
      columnData: orderTouristListDetail,
      tableData: [],
      visible: false
    };
  },
  computed: {
    getCardType() {
      return type => {
        // 之前有数据是空的，默认给身份证
        const findItem = SELECTOR.cardType.find(it => it.value == type);
        return findItem ? findItem.label : '身份证';
      };
    }
  },
  mounted() {
    this.$bus.on('plan:tourist', this.show);
  },
  methods: {
    show(id) {
      this.visible = true;
      this.fetchData(id);
    },
    fetchData(id) {
      this.$store.dispatch('order/index/fetchTourist', {
        id
      }).then(data => {
        this.tableData = data;
      });
    }
  }
};