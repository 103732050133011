var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TreeFramework", _vm._g(_vm._b({
    attrs: {
      list: _vm.list
    }
  }, "TreeFramework", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };