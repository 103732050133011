/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchShopping, createOrUpdateShopping, deleteShopping, fetchAgreementTypeList, fetchShopGroupList } from '@/api/resource/shopping';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { TYPES, DICTYPES } from '@/config/resource';
import { formatCharData, formatDicData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  shoppingData: null,
  shoppingGroupData: null,
  shopDicData: null,
  shoppingDataOrigin: null,
  shoppingLog: null
});
const state = dfaultState();
const getters = {
  shoppingData: state => state.shoppingData,
  shoppingGroupData: state => state.shoppingGroupData,
  shopDicData: state => state.shopDicData,
  shoppingDataOrigin: state => state.shoppingDataOrigin,
  shoppingLog: state => state.shoppingLog
};
const actions = {
  fetch({
    commit,
    state
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchShopping(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      // data = data.filter(it => it.isEnable)
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表（集团绑定）
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchShopGroupList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    return fetchLog({
      resourceId,
      resourceType: TYPES.shop
    }).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  // 查询旅监局购物店字典
  getTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.shop
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.shopDicData ? state.shopDicData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatDicData(list, 'shop'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA_SHOPDIC', data);
      return originData;
    });
  },
  // 查询旅监购物店字典名字搜
  searchTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.shop
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      data.list = formatDicData(data.list, 'shop');
      commit('FETCH_DATA_SHOPDIC', data);
      return data;
    });
  },
  fetchAgreement({
    rootGetters
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.shop
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        list
      } = res.data;
      // 更新当前协议内容列表
      if (rootGetters['resource/common/curAgreementId']) {
        const findItem = list.find(it => it.id === rootGetters['resource/common/curAgreementId']);
        this.dispatch('resource/common/agreementChild', findItem);
      }
      return list;
    });
  },
  // eslint-disable-next-line no-empty-pattern
  addAgreementItem({}, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit,
    dispatch
  }, opts) {
    return createOrUpdateShopping(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    return deleteShopping(id);
  },
  fetchAgreementList({
    commit
  }, resourceId) {
    const params = {
      resourceId,
      resourceType: 'shop'
    };
    return fetchAgreementTypeList(params).then(res => {
      return res.data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.shoppingData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.shoppingGroupData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.shoppingDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.shoppingLog = data;
  },
  FETCH_DATA_SHOPDIC: (state, data) => {
    state.shopDicData = data;
  },
  UPDATE: (state, data) => {
    const fIndex = state.shoppingData.list.findIndex(it => it.id === data.id);
    state.shoppingData.list.splice(fIndex, 1, data);
  },
  DELETE: (state, id) => {
    const list = state.shoppingData.list.filter(it => it.id !== id);
    state.shoppingData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};