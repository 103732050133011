import { FormAccount, DropzoneBox, SwitchStatus, SelectDicType, SelectDictionary } from '@/components/common';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
export default {
  components: {
    FormAccount,
    DropzoneBox,
    SwitchStatus,
    SelectDicType,
    SelectDictionary
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      index: -1,
      form: {
        isEnable: true,
        scenicType: 'PAID',
        isFree: false
      },
      validateForm: {
        name: [{
          required: true,
          message: '景区名称不能为空'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      editSourceInfo: 'resource/common/editSourceInfo',
      accountForm: 'resource/account/editAccountInfo',
      imageList: 'resource/common/imageList',
      // 照片信息
      imageListNew: 'resource/common/imageListNew',
      // 新上传的照片
      imageListNewDel: 'resource/common/imageDelAddList',
      // 新上传删除的照片
      scenicDicData: 'resource/scenic/scenicDicData'
    }),
    getTitle() {
      return this.isUpdate ? '更新景区' : '添加景区';
    }
  },
  methods: {
    edit(scope) {
      this.resetForm();
      const {
        row
      } = scope;
      this.index = scope.$index;
      this.visible = true;
      this.isUpdate = true;
      const isFree = row.scenicType == 'PAID' ? false : true;
      this.form = {
        ...row,
        isFree
      };
      this.$store.dispatch('resource/scenic/fetchImage', row.id);
      this.$store.dispatch('resource/common/editResource', row);
    },
    show() {
      this.visible = true;
      this.isUpdate = false;
      this.$store.dispatch('resource/common/editSource', {});
    },
    hide() {
      this.resetForm();
      this.visible = false;
      this.isUpdate = false;
    },
    changeIsFree(val) {
      this.form.isFree = val;
      this.form.scenicType = val ? 'FREE' : 'PAID';
    },
    onSelectArea(val) {
      this.form.area = val.label;
    },
    beforeSelectDicType(opts) {
      this.$store.dispatch('resource/scenic/getTravelSuperviseeDic', opts);
    },
    filterData(val) {
      const {
        data
      } = val;
      const opts = {
        name: data
      };
      this.$store.dispatch('resource/scenic/searchTravelSuperviseeDic', opts);
    },
    handleCreate() {
      if (!this.form.name) {
        this.$bus.tip({
          type: 'warning',
          message: '景区名字不能为空!'
        });
      }
      const picPaths = this.mergeImage();
      const {
        id,
        groupId,
        travelAgencyId,
        realName
      } = this.userData;
      const userData = {
        createUserId: id,
        groupId,
        travelAgencyId,
        realName
      };
      const opts = {
        ...userData,
        ...this.form,
        ...this.accountForm,
        picPaths,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/scenic/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$parent.fetchList();
        loading.close();
        this.hide();
        this.$bus.tip();
      }).catch(() => {
        loading.close();
      });
    },
    resetForm() {
      this.form = {};
      // 清理图片
      this.$store.commit('resource/common/CLEAR_IMAGE');
      // 清理新增没有id 的图片
      this.$store.dispatch('resource/common/deleteAddImage');
    }
  }
};