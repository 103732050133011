import { TopTitle } from '@/components/common';
import Reception from '@/components/common/Reception';
import tableMixin from '../tableMixin';
import { mapGetters } from 'vuex';
import { permissionMixin, typeMixin } from '@/mixin';
import { TYPES } from '@/config/order';
import { DialogLog } from '@/components/common';
const defaultState = () => ({
  collectList: [],
  contactMobileNumber: '',
  contactName: '',
  customerId: '',
  customerOrderId: 0,
  endTripDate: '',
  feeList: [],
  remarks: '',
  startTripDate: '',
  subsidyList: []
});
export default {
  components: {
    TopTitle,
    Reception,
    DialogLog
  },
  mixins: [typeMixin, tableMixin, permissionMixin],
  data() {
    return {
      title: '外转',
      receptionNum: 100000,
      btnLoading: false,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      receptionList: 'order/reception/receptionList',
      currentOrder: 'order/index/currentOrder',
      currentOrderId: 'order/index/currentOrderId'
    }),
    isDisabled() {
      return !this.receptionList || !this.receptionList.length;
    },
    ignoreAudit() {
      return this.isBtnDisable && !this.hasPermission('audit:team-single:ignoreAudit');
    },
    isSaveDisable() {
      return !this.tableData.length || this.ignoreAudit;
    }
  },
  watch: {
    receptionList: {
      handler: function (data) {
        this.tableData = [];
        if (!data || !data.length) return;
        this.tableData = data.map(it => {
          const {
            feeList,
            collectList,
            subsidyList
          } = it;
          it.feeList = this.formatLabel(feeList, 'teamFundType');
          it.collectList = this.formatLabel(collectList, 'proxyType');
          it.subsidyList = this.formatLabel(subsidyList, 'refundType');
          it.productName = it.product ? it.product.name : '';
          it.productId = it.product ? it.product.id : -1;
          return it;
        });
      },
      immediate: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatLabel(list, type) {
      return list.map(fee => {
        fee.typeLabel = this.getType(type, fee.type);
        return fee;
      });
    },
    fetchData() {
      // const { id } = this.$route.query
      const id = this.currentOrderId;
      this.$nextTick(() => {
        this.$store.dispatch('order/reception/fetchTurnOut', id);
      });
    },
    handlePlus() {
      const {
        adultCount,
        startTripDate,
        endTripDate
      } = this.currentOrder;
      const item = {
        ...defaultState(),
        id: ++this.receptionNum,
        adultCount,
        startTripDate,
        endTripDate,
        isCustom: true,
        customerOrderId: this.currentOrderId || this.$route.query.id
      };
      this.$store.commit('order/reception/APPEND_RECEPTION', item);
    },
    // 删除整个外转
    handleRemove(item, index) {
      const {
        id,
        isCustom
      } = item;
      this.$bus.open(() => {
        if (!isCustom) {
          const loading = this.$bus.loading();
          this.$store.dispatch('order/reception/delete', {
            id,
            index
          }).then(() => {
            loading.close();
          }).catch(() => {
            loading.close();
          });
        } else {
          this.$store.commit('order/reception/REDUCE_RECEPTION', index);
        }
      });
    },
    showNoticeDialog() {
      this.$bus.emit('show:notice');
    },
    // 获取日志
    handleLog() {
      const {
        id
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchPlanLog', {
        itemId: id,
        subType: TYPES.localTravelAgency
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    validateReception() {
      const validateKeys = ['customerId', 'endTripDate', 'startTripDate'];
      for (let i = 0; i < this.receptionList.length; i++) {
        const item = this.receptionList[i];
        for (let j = 0; j < validateKeys.length; j++) {
          const key = validateKeys[j];
          if (!item[key]) {
            return false;
          }
        }
      }
      return true;
    },
    handleUpdate() {
      if (!this.receptionList || this.receptionList.length == 0) return;
      if (!this.validateReception()) {
        this.$bus.tip({
          type: 'warning',
          message: '外转社信息不全'
        });
        return;
      }
      this.btnLoading = true;
      this.$store.dispatch('order/reception/turnOutBatch', this.receptionList).then(() => {
        this.fetchData();
        this.$bus.tip();
        this.btnLoading = false;
      }).catch(() => {
        this.btnLoading = false;
        this.fetchData();
      });
    },
    createOrUpdate(customerOrderId) {
      if (!this.receptionList || this.receptionList.length == 0) {
        return Promise.resolve(1);
      }
      const {
        receptionRef
      } = this.$refs;
      return new Promise((resolve, reject) => {
        receptionRef.map(comp => {
          comp.createOrUpdate(customerOrderId).then(() => {
            return resolve('ok');
          }).catch(err => {
            return reject(err);
          });
        });
      });
    }
  }
};