var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      sticky: _vm.isSticky,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "order",
      fn: function ({
        scope
      }) {
        return [_c("div", {
          staticClass: "blue"
        }, [_vm._v(" " + _vm._s(scope.row.planCustomerNumber) + " ")]), _c("div", {
          staticClass: "yellow"
        }, [_vm._v(" " + _vm._s(scope.row.customerTripNumber) + " ")]), _c("div", [_vm._v(_vm._s(scope.row.productName))])];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("CustomerInfo", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }, {
      key: "orderType",
      fn: function ({
        scope
      }) {
        return [_c("TypeOrder", {
          attrs: {
            type: scope.row.orderType
          }
        })];
      }
    }, {
      key: "date",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.startTripDate))])];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "pickup",
      fn: function ({
        scope
      }) {
        return [scope.row.shuttles.length ? _vm._l(scope.row.shuttles, function (item) {
          return _c("span", {
            key: item.id
          }, [_vm._v(" " + _vm._s(item.name) + " ")]);
        }) : [_vm._v(" -- ")]];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          staticStyle: {
            color: "#13ce66"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRecovery(scope, "t_tourist");
            }
          }
        }, [_vm._v(" 恢复团队 ")]), _c("el-button", {
          directives: [{
            name: "permission",
            rawName: "v-permission"
          }],
          staticStyle: {
            color: "#ffba00"
          },
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.handleRecovery(scope, "s_tourist");
            }
          }
        }, [_vm._v(" 恢复散客 ")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };