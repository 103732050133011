import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// import { mapGetters } from 'vuex'
import { BtnExport, InputMonthRange, SelectDictionary, SelectCustom, SelectStaff } from '@/components/common';
import TableList from './TableList';
import { incomeAnalyze } from '@/config/analysis';
import analysisMixin from '../analysisMixin';
import { decimalSplit } from '@/utils';
export default {
  components: {
    TableList,
    BtnExport,
    InputMonthRange,
    SelectDictionary,
    SelectCustom,
    SelectStaff
  },
  mixins: [analysisMixin],
  data() {
    return {
      tableData: [],
      columnData: incomeAnalyze,
      incomedRate: 0,
      filename: '应收款分析表',
      positiveRate: 0,
      negativeRate: 0
    };
  },
  computed: {
    incomeData() {
      return this.$store.getters['analyse/index/incomeData'];
    }
  },
  watch: {
    incomeData: {
      handler: function (data) {
        if (!data) {
          this.handleSearch();
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.tableData = data;
      this.processData(data);
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchTotalIncome', queryParams);
    },
    processData(data) {
      const positive = data.filter(it => it.income >= 0);
      const negative = data.filter(it => it.income < 0);
      const getTotal = (list, key) => {
        return list.reduce((acc, cur) => {
          return acc += cur[key];
        }, 0);
      };
      const pIncomeTotal = getTotal(positive, 'income');
      const pIncomedTotal = getTotal(positive, 'incomed');
      const nIncomeTotal = getTotal(negative, 'income');
      const nIncomedTotal = getTotal(negative, 'incomed');
      if (!pIncomeTotal || !pIncomedTotal) {
        this.positiveRate = 0;
      } else {
        this.positiveRate = decimalSplit(pIncomedTotal / pIncomeTotal * 100);
      }
      if (!nIncomeTotal || !nIncomedTotal) {
        this.negativeRate = 0;
      } else {
        this.negativeRate = decimalSplit(nIncomedTotal / nIncomeTotal * 100);
      }
    },
    onLineDetail(row) {
      const {
        customerName,
        customerId
      } = row;
      this.$router.push({
        path: '/analysis/income-detail',
        query: {
          ...this.searchParams,
          customerName,
          customerId,
          type: 'income'
        }
      });
    },
    changeArea(val) {
      this.searchParams.customerArea = val.label;
    },
    selectCustom(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.customerLabel = label;
      this.searchParams.customerId = value;
    },
    selectManager(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.customerAreaManager = label;
      this.searchParams.customerAreaManagerId = value;
    },
    changeUserName(val) {
      const {
        label,
        value
      } = val;
      this.searchParams.outOPUserLabel = label;
      this.searchParams.outOPUserId = value;
    }
  }
};