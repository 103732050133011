var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.detail ? _c("div", {
    staticClass: "hotel-detail"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("酒店名称：")]), _c("span", [_vm._v(_vm._s(_vm.detail.hotelName))])]), _c("div", {
    staticClass: "address"
  }, [_c("strong", [_vm._v("酒店地址：")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.getAddress))])]), _vm.hotelImages && _vm.hotelImages.totalCount > 0 ? _c("div", {
    staticClass: "image-wrapper"
  }, [_c("div", {
    staticClass: "big-image",
    on: {
      click: function ($event) {
        return _vm.showPreview(1);
      }
    }
  }, [_c("img", {
    attrs: {
      src: _vm.hotelImages.list[0].path,
      alt: ""
    }
  }), _c("div", {
    staticClass: "label"
  }, [_vm._v(" 酒店图片共有 " + _vm._s(_vm.hotelImages.totalCount) + " 张 ")])]), _c("div", {
    staticClass: "image-list"
  }, _vm._l(_vm.getRestImage, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "image-item"
    }, [_c("el-image", {
      attrs: {
        src: item.path
      },
      on: {
        click: function ($event) {
          return _vm.showPreview(index + 1);
        }
      }
    })], 1);
  }), 0)]) : _vm._e(), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("strong", [_vm._v("总房数：")]), _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.countRoom) + "间")])])]), _c("div", {
    staticClass: "flex"
  }, [_c("strong", [_vm._v("标准：")]), _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.standard.split("|")[0]))])])]), false ? _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("风格：")]), _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.countRoom) + "间")])])]) : _vm._e()]), _c("div", {
    staticClass: "room-type field"
  }, [_c("strong", [_vm._v("房型：")]), _c("div", {
    staticClass: "room-list"
  }, _vm._l(_vm.roomList, function (room, index) {
    return _c("div", {
      key: index,
      staticClass: "room-list-item"
    }, [_c("span", [_vm._v(_vm._s(room.roomType))]), _c("span", [_vm._v(_vm._s(room.bedType.split("|")[0]))]), _c("span", [_vm._v(_vm._s(room.quantity) + "间")]), _c("span", [_vm._v(_vm._s(room.area) + "平米")]), _c("span", [_vm._v(_vm._s(room.floor) + "层")]), _c("span", [_vm._v(_vm._s(room.isWindow ? "有窗" : "无窗"))]), _c("span", [_vm._v(_vm._s(room.isAddRoom ? "可加床" : "不可加"))])]);
  }), 0)]), _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("停车场：")]), _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.stoppage))])])]), _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("早餐餐厅：")]), _c("div", {
    staticClass: "field-item"
  }, [_vm.detail.isBreakfast && _vm.detail.breakfastQuantity > 0 ? _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.breakfastQuantity) + " 个")]), _c("span", [_vm._v("面积 " + _vm._s(_vm.detail.breakfastArea) + " 平米")]), _c("span", [_vm._v("可接待 " + _vm._s(_vm.detail.breakfastCapacity) + " 人")])]) : _vm._e()])]), _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("正餐餐厅：")]), _c("div", {
    staticClass: "field-item"
  }, [_vm.detail.isDinner && _vm.detail.dinnerQuantity > 0 ? _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.dinnerQuantity) + " 个")]), _c("span", [_vm._v("面积 " + _vm._s(_vm.detail.dinnerArea) + " 平米")]), _c("span", [_vm._v("可接待 " + _vm._s(_vm.detail.dinnerCapacity) + " 人")])]) : _vm._e(), _vm.detail.isWestern && _vm.detail.westernQuantity > 0 ? _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.westernQuantity) + " 个")]), _c("span", [_vm._v("面积 " + _vm._s(_vm.detail.westernArea) + " 平米")]), _c("span", [_vm._v("可接待 " + _vm._s(_vm.detail.westernCapacity) + " 人")])]) : _vm._e()])]), _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("会议室：")]), _vm.detail.isMeeting ? _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.meetingQuantity) + " 个")]), _c("span", [_vm._v("面积 " + _vm._s(_vm.detail.meetingArea) + " 平米")]), _c("span", [_vm._v("可接待 " + _vm._s(_vm.detail.meetingCapacity) + " 人")])]) : _c("span", [_vm._v("没有")])]), _c("div", {
    staticClass: "field"
  }, [_c("strong", [_vm._v("游泳池：")]), _c("div", {
    staticClass: "field-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.detail.isSwim ? "有" : "没有"))])])])]), _c("PreviewImage", {
    ref: "previewRef"
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };