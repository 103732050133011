import { tableMixin } from '@/mixin';
import shopMixin from '../shopMixin';
import { orderTypeLabel } from '@/config/order';
export default {
  mixins: [tableMixin, shopMixin],
  data: () => ({
    orderTypeLabel,
    sumsIndex: 4,
    avgeageKey: [5, 6, 7],
    // 计算人均
    shopStarIndex: 9
  }),
  methods: {}
};