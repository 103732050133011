// 下账
import request from '@/utils/request';

// 预收预付现金日志流
export const fetchPreCash = params => {
  return request({
    url: '/order/ka/page-pre-cashes',
    method: 'get',
    params
  });
};

// 更新预收付
export const updateBillAdvance = data => {
  return request({
    url: '/order/ka/update-pre-LogCash',
    method: 'post',
    data
  });
};

// 添加预收付
export const createBillAdvance = data => {
  return request({
    url: '/order/ka/add-pre-LogCash',
    method: 'post',
    data
  });
};