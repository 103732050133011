import { inputMixin, selectListMixin } from '@/mixin';
export default {
  name: 'SelectTrafficGroup',
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'resource/traffic/fetchListGroup'
  }),
  computed: {
    data() {
      return this.$store.getters['resource/traffic/trafficGroupData'];
    }
  }
};