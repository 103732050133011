import { rules, defaultForm, defaultOption } from '../config';
export default {
  data() {
    return {
      rules,
      visible: false,
      isUpdate: false,
      form: defaultForm(),
      oldData: [],
      status: false
    };
  },
  computed: {
    getTitle() {
      return this.isUpdate ? '修改规则' : '新增规则';
    }
  },
  methods: {
    show(id, tableData, status) {
      this.visible = true;
      this.form.id = id;
      this.form.value1 = +status;
      this.oldData = tableData;
    },
    edit(item, tableData, status) {
      this.visible = true;
      this.isUpdate = true;
      this.form = {
        ...this.form,
        ...item,
        value1: +status
      };
      this.oldData = tableData;
    },
    hide() {
      this.visible = false;
      this.form = defaultForm();
    },
    processOption() {
      const {
        id,
        isEnable,
        keyword,
        price,
        orderBy
      } = this.form;
      const value = JSON.stringify([{
        isEnable,
        keyword,
        price,
        orderBy
      }, ...this.oldData]);
      return {
        ...defaultOption(),
        ...this.form,
        id,
        value
      };
    },
    handleCreate() {
      this.$refs.formRef.validate(valid => {
        if (!valid) return;
        const opt = this.processOption();
        this.$store.dispatch('system/buttJoint/setConfig', [opt]).then(() => {
          this.hide();
          this.$bus.tip();
          this.$emit('reload');
        });
      });
    }
  }
};