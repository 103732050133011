import { TopBar, DelCheckAll, Audit, AuditCheck, AuditAdmin, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { StatusAudit, SelectGuide, SelectGuideManager, SelectPayType } from '@/components/common';
import { mapGetters } from 'vuex';
import { getNextDate, isNumber } from '@/utils';
import { rowspanMixin, selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  guideName: '',
  guidePhone: '',
  guideId: '',
  startPlanDate: '',
  endPlanDate: '',
  guideServiceFee: 0,
  guideManageFee: 0,
  recordingFee: 0,
  guideFund: '',
  guideTax: 0,
  remark: '',
  reimb: false,
  auditStatus: '',
  guidePlanId: 0,
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  settlementMethod: 'MONTH'
});
const getTotalList = () => ({
  guideServiceFee: 0,
  guideManageFee: 0,
  guideFund: 0,
  guideTax: 0
});
export default {
  components: {
    TopBar,
    DelCheckAll,
    Audit,
    AuditCheck,
    AuditAdmin,
    AuditRoleAll,
    StatusAudit,
    SelectGuide,
    SelectPayType,
    SelectGuideManager,
    AuditStatus,
    CheckStatus
  },
  mixins: [rowspanMixin, selectorMixin, auditMixin, auditPropMixin, planMixin, reloadMixin],
  data() {
    return {
      tableData: [],
      hasDataStatusKey: 'guideOrderStatus',
      totalList: getTotalList(),
      deletePath: 'plan/guide/delete',
      updatePath: 'plan/guide/update',
      fetchPath: 'plan/guide/fetch',
      mergeTable: false,
      totalMoney: 0,
      // 总金额
      rowId: 1000000000000,
      selectStartDate: Date.now(),
      statisticsKeys: ['guideServiceFee'],
      auditAll: false,
      planDataInfo: {}
    };
  },
  computed: {
    ...mapGetters({
      planOrderInfo: 'plan/index/planOrderInfo',
      currentPlanId: 'plan/index/currentPlanId'
      // guideManagerData: 'resource/guideManager/guideData',
    }),
    isGuideManagerDisable() {
      return row => {
        return this.isRowDisable(row) || row.guideManageId > 0;
      };
    }
  },
  watch: {
    guideManagerData: {
      handler: function (d) {
        if (!d) {
          this.$store.dispatch('resource/guideManager/fetchList');
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchData();
    this.getPlanInfo();
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.setGuideManagerId();
    },
    // 获取团信息
    getPlanInfo() {
      const planId = this.currentPlanId || this.$route.query.id;
      this.$store.dispatch('plan/index/fetchPlanById', planId).then(res => {
        if (!res) return;
        this.planDataInfo = res;
      });
    },
    setGuideManagerId() {
      const {
        guideManageId
      } = this.planOrderInfo[0];
      if (guideManageId) {
        this.tableData.forEach(it => {
          const {
            guideManageId: gmid
          } = it;
          it.guideManageId = gmid || guideManageId;
        });
      }
    },
    // 时间修改
    changeStartDate(row, val) {
      this.selectStartDate = new Date(val).getTime();
      row.endPlanDate = getNextDate(val);
    },
    // 选择导游
    changeGuide(row, val) {
      const {
        id,
        name,
        mobileIphone
      } = val;
      row.guideId = id;
      row.guideName = name;
      row.guidePhone = mobileIphone;
    },
    // 添加数据
    handlePlus() {
      if (this.planDataInfo) {
        const {
          startPlanDate,
          endPlanDate
        } = this.planDataInfo;
        this.tableData.unshift({
          ...defaultState(),
          startPlanDate,
          endPlanDate,
          guidePlanId: ++this.rowId,
          rowId: ++this.rowId
        });
      } else {
        this.tableData.unshift({
          ...defaultState(),
          guidePlanId: ++this.rowId,
          rowId: ++this.rowId
        });
      }
      this.setGuideManagerId();
    },
    changeInput(row, type, val) {
      if (!isNumber(val)) {
        row[type] = 0;
        return;
      }
    },
    changeReimb(row) {
      this.tableData.forEach(it => {
        it.reimb = false;
      });
      row.reimb = true;
    },
    setGuideReimb(list) {
      if (!list || !list.length) return;
      const result = list.every(it => it.reimb == false);
      result ? list[0].reimb = true : null;
      return list;
    },
    // 加工数据
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        const {
          id,
          auditProgress
        } = it;
        const item = {
          ...defaultState(),
          ...it,
          rowId: id,
          guidePlanId: id,
          isCustom: false,
          isChecked: false,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          ap: auditProgress,
          auditProgress
        };
        this.rowId++;
        return item;
      });
    },
    beforeCommit(list) {
      if (!list || !list.length) return [];
      const dataList = this.setGuideReimb(list);
      return dataList.map(it => {
        it.planId = this.currentPlanId;
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
        }
        return it;
      });
    },
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          guideId,
          guideName,
          guidePhone,
          startPlanDate,
          endPlanDate
        } = row;
        if (!guideName && !guideId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择导游！'
          });
          return false;
        }
        if (!guidePhone) {
          this.$bus.tip({
            type: 'warning',
            message: '请填写导游手机号码！'
          });
          return false;
        }
        if (!startPlanDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择导游上团日期！'
          });
          return false;
        }
        if (!endPlanDate) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择导游下团日期！'
          });
          return false;
        }
      }
      return true;
    }
  }
};