var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "rightPanel",
    staticClass: "rightPanel-container",
    class: {
      show: _vm.isShow
    }
  }, [_c("div", {
    staticClass: "rightPanel-background"
  }), _c("div", {
    staticClass: "rightPanel"
  }, [_vm.isShow ? _c("div", {
    staticClass: "handle-button",
    style: {
      top: _vm.buttonTop + "px",
      "background-color": _vm.theme
    },
    on: {
      click: _vm.hiddenPanel
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]) : _vm._e(), _c("div", {
    staticClass: "rightPanel-items"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };