import { SelectCustom } from '@/components/common';
import TableTeam from './TableTeam.vue';
import TableOrder from './TableOrder.vue';
const TAB_TYPE_TEAM = 'team';
const TAB_TYPE_ORDER = 'order';
export default {
  name: 'DialogAuditDetail',
  components: {
    SelectCustom,
    TableTeam,
    TableOrder
  },
  data() {
    return {
      visible: false,
      customerId: '',
      typeTeam: TAB_TYPE_TEAM,
      typeOrder: TAB_TYPE_ORDER,
      activeName: TAB_TYPE_TEAM,
      teamData: [],
      orderData: []
    };
  },
  methods: {
    show(data) {
      this.visible = true;
      this.formatData(data);
    },
    hide() {
      this.visible = false;
      this.customerId = '';
      this.teamData = [];
      this.orderData = [];
    },
    formatData(list) {
      this.orderData = list;
      this.teamData = list;
    },
    handleConfirm() {
      const isOrderType = this.activeName == this.typeOrder;
      const data = isOrderType ? this.orderData.filter(it => it.customerIdNew != '') : this.teamData;
      const type = '大人价';
      const times = 1;
      if (!data.length) return;
      const dataList = data.map(it => {
        const {
          id: customerOrderId,
          customerIdNew,
          startTripDate,
          endTripDate,
          fee,
          proxy,
          subsidy
        } = it;
        const obj = {
          contactName: '',
          customerId: isOrderType ? customerIdNew : this.customerId,
          customerOrderId,
          endTripDate,
          startTripDate
        };
        const collectList = [{
          total: proxy,
          type: '代收'
        }];
        const feeList = [{
          count: 1,
          price: fee,
          total: fee,
          times,
          type
        }];
        const subsidyList = [{
          count: 1,
          price: subsidy,
          total: subsidy,
          times,
          type: '返款'
        }];
        return {
          ...obj,
          collectList: proxy != 0 ? collectList : [],
          feeList: fee != 0 ? feeList : [],
          subsidyList: subsidy != 0 ? subsidyList : []
        };
      });
      this.$store.dispatch('order/reception/turnOutBatch', dataList).then(() => {
        this.hide();
        this.$bus.tip();
        this.$parent.init();
      }).catch(err => {
        this.$bus.tip({
          message: err.message
        });
      });
    }
  }
};