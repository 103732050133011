var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columnData,
      "span-method": _vm.objectSpanMethod,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "number",
      fn: function ({
        scope
      }) {
        return [_c("PersonCount", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "customerName",
      fn: function ({
        scope
      }) {
        return [_c("Customer-info", {
          attrs: {
            name: scope.row.customerName
          }
        })];
      }
    }, {
      key: "type",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.type) + " ")];
      }
    }, {
      key: "totalMoney",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.totalMoney) + " ")];
      }
    }, {
      key: "unit",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.unit) + " ")];
      }
    }, {
      key: "remark",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.row.title) + " ")];
      }
    }, {
      key: "auditStatus",
      fn: function ({
        scope
      }) {
        return [_c("StatusAudit", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text",
            disabled: _vm.isRowDisable(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.handleEdit(scope.row.index);
            }
          }
        }, [_vm._v(" 编辑 ")])];
      }
    }, {
      key: "deleteAction",
      fn: function ({}) {
        return [_vm._v(" 操作 ")];
      }
    }, {
      key: "audit",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("Audit", {
          attrs: {
            row: scope.row
          },
          on: {
            "on-check": function ($event) {
              return _vm.handleCheckAudit(scope.row, $event);
            }
          }
        }) : _c("AuditAdmin", {
          attrs: {
            row: scope.row
          }
        })];
      }
    }, {
      key: "auditAction",
      fn: function ({
        scope
      }) {
        return [!_vm.isAdminAudit ? _c("AuditCheck", {
          attrs: {
            row: scope.row,
            checkval: _vm.auditAll
          },
          on: {
            "update:checkval": function ($event) {
              _vm.auditAll = $event;
            },
            "on-check": _vm.checkAuditAll
          }
        }) : _c("span", [_vm._v("审核")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };