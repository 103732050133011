import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
/*
 * @Author: cyg
 * @Date: 2022-05-23 17:35:30
 * @LastEditors: cyg
 * @LastEditTime: 2023-08-17 09:37:38
 * @FilePath: \travel-admin\src\router\modules\memberMap.js
 */
//  数据分析
import Layout from '@/layout/print';
const printRouter = {
  path: '/print',
  component: Layout,
  redirect: '/print-bill',
  name: 'print',
  hidden: true,
  meta: {
    title: 'print'
  },
  children: [{
    path: '/analysis/member-map',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/analysis/memberMap'))),
    name: 'analyseMemberMap',
    // 游客分布地图
    meta: {
      title: 'analyseMemberMap',
      auth: true
    },
    hidden: true
  }]
};
export default printRouter;