import store from '@/store';

// 字典
const getDictionaryValue = (type, id) => {
  const data = store.getters['system/dictionary/dictionaryCodeData'];
  const dicType = data[type];
  if (dicType && id) {
    return dicType.agencyDictionaryList.find(it => it.id == id);
  }
  return dicType ? dicType.agencyDictionaryList[0] : null;
};
// 字典
export const getDictionaryValueByName = (type, name) => {
  const data = store.getters['system/dictionary/dictionaryCodeData'];
  const dicType = data[type];
  if (dicType && name) {
    return dicType.agencyDictionaryList.find(it => it.label == name);
  }
};
export const defDictionary = type => {
  return getDictionaryValue(type);
};