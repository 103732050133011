// 返款对账列表
import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';
export const refundListRequest = params => {
  return request({
    url: '/order/ca/pageSubsidy',
    method: 'get',
    params
  });
};

// 导出
export function refundExportRequest(params) {
  const url = APP.baseUrl + '/order/caExport/pageCustomerSubsidy';
  return requestBlob({
    url,
    params
  });
}