var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hotel-booking-bill__detail"
  }, [_c("div", {
    staticClass: "booking-header"
  }, [_c("h1", {
    staticClass: "tac"
  }, [_vm._v(" 喜悦旅业 ")]), _c("h2", {
    staticClass: "tac"
  }, [_vm._v(" 团队订房单 ")]), _c("div", {
    staticClass: "text-input"
  }, [_c("span", {
    staticClass: "red",
    staticStyle: {
      "font-size": "32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.statusInfo) + " " + _vm._s(_vm.form.cancelStatus ? "已取消" : "") + " ")])])]), _vm.form ? _c("div", {
    staticClass: "booking-form"
  }, [_c("FormBase", {
    attrs: {
      inline: "",
      "label-width": "50px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    staticClass: "flex-1",
    attrs: {
      label: "TO"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")])]), _c("FormItemBase", {
    staticStyle: {
      width: "280px"
    },
    attrs: {
      label: "酒店销售部",
      "label-width": "115px"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.saleDepartment) + " ")])]), _c("FormItemBase", {
    staticStyle: {
      width: "160px"
    },
    attrs: {
      label: "TEL"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.tel) + " ")])]), _c("FormItemBase", {
    staticStyle: {
      width: "155px"
    },
    attrs: {
      label: "FAX",
      "label-width": "45px"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.fax || "无") + " ")])])], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("FormItemBase", {
    attrs: {
      label: "请安排我社团队",
      "label-width": "130px"
    }
  }, [_c("div", {
    staticClass: "label flex-1",
    staticStyle: {
      width: "330px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.myTeam) + " ")])]), _c("FormItemBase", {
    attrs: {
      label: "人数"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.adultCount) + " ")])]), _c("FormItemBase", {
    attrs: {
      label: "电话"
    }
  }, [_c("div", {
    staticClass: "label",
    staticStyle: {
      width: "268px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contactsTel) + " ")])])], 1)])], 1) : _vm._e(), _c("table", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("tbody", [_c("tr", [_vm._m(0), _c("th", [_c("div", {
    staticClass: "input-content"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.form.arriveDate)) + " 抵达酒店 ")])]), _c("td", {
    staticClass: "remarks",
    staticStyle: {
      width: "50%"
    },
    attrs: {
      rowspan: "6"
    }
  }, _vm._l(_vm.formatRemark, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "line-height": "24px",
        height: "24px"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]), _c("tr", [_c("th", [_c("div", {
    staticClass: "input-content"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.form.leaveDate)) + "离开酒店 ")])])]), _c("tr", [_c("th", [_vm._v("住房标准")]), _c("th", {
    staticClass: "tal"
  }, [_c("div", {
    staticClass: "input-content pl20"
  }, [_vm._v(" " + _vm._s(_vm.form.standard) + " ")])])]), _c("tr", [_vm._m(1), _c("th", [_c("div", {
    staticClass: "input-content"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.form.outDate)) + "外出 ")])])]), _c("tr", [_c("th", [_c("div", {
    staticClass: "input-content"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.form.backDate)) + "返回 ")])])]), _c("tr", [_c("th", [_vm._v("费用")]), _c("th", {
    staticClass: "tal"
  }, [_c("div", {
    staticClass: "input-content pl20"
  }, [_vm._v(" " + _vm._s(_vm.form.price) + " ")])])])])]), _c("div", {
    staticClass: "booking-footer flex flex-center mt20"
  }, [_c("div", {
    staticClass: "flex-1",
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c("strong", {
    staticClass: "tac"
  }, [_vm._v("房调: ")]), _c("span", [_vm._v(_vm._s(_vm.form.hotelOPUser))])]), _c("div", {
    staticClass: "flex-1 tac"
  }, [_c("strong", [_vm._v("日期：")]), _c("span", [_vm._v(_vm._s(_vm.getDate))])]), _vm._m(2)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("th", {
    staticStyle: {
      width: "13%"
    },
    attrs: {
      rowspan: "2"
    }
  }, [_c("span", [_vm._v("抵离时间")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("th", {
    attrs: {
      rowspan: "2"
    }
  }, [_c("span", [_vm._v("外住日期")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-1 tar"
  }, [_c("strong", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v("★")]), _vm._v(" 请速回传确认，致谢！ ")]);
}];
render._withStripped = true;
export { render, staticRenderFns };