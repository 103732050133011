import "core-js/modules/es.array.push.js";
import { TopBar, Audit, AuditCheck, AuditAdmin, DelCheckAll, PaymentTotal, AuditRoleAll, AuditStatus, CheckStatus } from '@/components/common';
import { planEditPickup } from '@/config/plan';
import { StatusAudit, SelectFleet, SelectDictionary, SelectPayType, SelectPlanNumber } from '@/components/common';
import { getNextDate } from '@/utils';
import { mapGetters } from 'vuex';
import { selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin } from '@/mixin';
import { AuditRole } from '@/config/audit';
import { getSelectLabel } from '@/config/select';
const itemListKeys = () => ({
  busFee: 0,
  busType: '',
  busTypeId: '',
  licenceNumber: '',
  totalPay: 0,
  otherFee: 0,
  planBusCompanyId: 0,
  startDate: '',
  endDate: '',
  driverId: '',
  driverName: '',
  driverPhone: ''
  // remark: '',
});
const defaultState = () => ({
  status: false,
  isCustom: true,
  // 删除数据用来判断类型
  isChecked: false,
  // 是否选中
  payType: 'sign',
  busCompanyName: '',
  money: 0,
  otherFee: 0,
  shuttleType: '',
  remark: '',
  ap: AuditRole.wait,
  auditProgress: AuditRole.wait,
  ...itemListKeys()
});
export default {
  components: {
    Audit,
    AuditCheck,
    AuditAdmin,
    TopBar,
    SelectPlanNumber,
    DelCheckAll,
    StatusAudit,
    SelectFleet,
    SelectDictionary,
    SelectPayType,
    PaymentTotal,
    AuditRoleAll,
    AuditStatus,
    CheckStatus
  },
  mixins: [selectorMixin, rowspanMixin, auditMixin, auditPropMixin, planMixin, reloadMixin, systemMixin],
  data() {
    return {
      tableData: [],
      deletePath: 'plan/fleet/delete',
      updatePath: 'plan/fleet/update',
      fetchPath: 'plan/fleet/fetch',
      headMergeNum: 3,
      tailMergeNum: planEditPickup.length - 5,
      hasMergeTail: true,
      rowId: 1000000000000,
      selectStartDate: Date.now(),
      auditAll: false,
      busList: [],
      driverList: [],
      isShuttle: true,
      planData: {},
      planId: -1,
      shuttleId: ''
    };
  },
  computed: {
    ...mapGetters({
      planOrderData: 'plan/index/planOrderData'
    })
  },
  methods: {
    getData(data) {
      this.planData = data;
      this.planId = data.planId;
      this.shuttleId = data.id;
      // this.fetchData({ planId: this.planId })
      this.$store.dispatch('plan/index/getOrderByPlanId', this.planId);
      this.fetchData(data.id);
    },
    // 获取订单列表
    fetchData(id) {
      this.$store.dispatch('plan/index/fetchPickupId', {
        shuttleId: id
      }).then(data => {
        this.initData(data);
      });
    },
    // fetchData(params) {
    //   params = { ...params, shuttleType: ShuttleType.shuttle }
    //   this.$store.dispatch(this.fetchPath, params).then(data => {
    //     this.initData(data)
    //   })
    // },

    initData(data) {
      if (!data) return;
      this.tableData = this.formatData(data);
      this.$nextTick(() => {
        // 是否显示订单信息
        const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
        // 如果不显示订单信息
        if (!isOrderInfo) {
          this.headMergeNum = isOrderInfo ? 4 : 3;
          this.tailMergeNum = planEditPickup.length - 6;
        }
        this.resetData();
      });
    },
    fetchDriver(busCompanyId) {
      return this.$store.dispatch('resource/driver/fetch', {
        busCompanyId
      }).then(data => {
        return data;
      });
    },
    fetchBus(busCompanyId) {
      return this.$store.dispatch('resource/vehicle/fetch', {
        busCompanyId
      }).then(data => {
        return data;
      });
    },
    // 选择车队
    handleSelect(row, val) {
      // 如果未选中任何数据，直接返回
      const {
        id,
        label
      } = val;
      row.busCompanyName = label;
      row.busCompanyId = id;
      row.driverPhone = '';
      row.driverId = '';
      row.driverName = '';
      row.licenceNumber = '';
      if (val.label == '') return;
      this.fetchDriver(id).then(data => {
        this.driverList = this.formatOptions(data.list, 'name');
      });
      this.fetchBus(id).then(data => {
        this.busList = this.formatOptions(data.list, 'licenceNumber');
      });
    },
    // 选择司机
    driverSearch(queryString, cb) {
      const drivers = this.driverList;
      const results = queryString ? drivers.filter(this.createFilter(queryString)) : drivers;
      cb(results);
    },
    // 选择司机

    selectDriver(scope, val) {
      if (typeof val !== 'string') {
        const {
          id,
          mobilePhone
        } = val;
        const {
          row
        } = scope;
        row.driverPhone = mobilePhone;
        row.driverId = id;
      }
    },
    createFilter(queryString) {
      return list => {
        return list.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    // 选择车牌
    selectLicence(row, val) {
      if (val && typeof val !== 'string') {
        const {
          licenceNumber
        } = val;
        row.licenceNumber = licenceNumber;
      }
    },
    licenceSearch(queryString, cb) {
      const busList = this.busList;
      const results = queryString ? busList.filter(this.createFilter(queryString)) : busList;
      cb(results);
    },
    handleBill() {},
    // 当前行添加项目
    handleRow(scope) {
      let {
        $index: index,
        row
      } = scope;
      let {
        id,
        itemIndex,
        isCustom
      } = row;

      // 大于 0 就是删除
      if (itemIndex && itemIndex > 0) {
        !isCustom ? this.$store.dispatch('plan/pickup/deleteItem', id).then(() => {
          this.reloadData();
        }) : this.tableData.splice(index, 1);
      } else {
        const rowItem = {
          ...row,
          itemIndex: ++index,
          isCustom: true,
          isChecked: false,
          ...itemListKeys(),
          busType: row.busType
        };
        this.tableData.splice(rowItem.itemIndex, 0, rowItem);
      }
      this.changeInput();
      this.rowspan();
    },
    // 新增一条数据
    handlePlus() {
      const firstPlanOrder = this.planOrderData && this.planOrderData[0];
      const item = {
        ...defaultState(),
        customerOrderId: firstPlanOrder.id || -1,
        rowId: ++this.rowId,
        planId: this.planId,
        count: this.planData.adultCount,
        rowTotal: 0
      };
      this.tableData.push(item);
      this.rowspan();
    },
    selectBusType(row, val) {
      const {
        label,
        value
      } = val;
      row.busType = label;
      row.busTypeId = value;
    },
    changeShuttleType(row, val) {
      const {
        value
      } = val;
      if (value == 'pick') {
        row.endDate = '';
      }
      if (value == 'send') {
        row.startDate = '';
      }
      row.shuttleType = value.toUpperCase();
    },
    // 时间修改
    changeStartDate(row, val) {
      this.selectStartDate = new Date(val).getTime();
      if (row.shuttleType === 'pickandsend') {
        row.endDate = getNextDate(this.selectStartDate);
      }
    },
    changeEndDate(row, val) {
      row.endDate = val;
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        busFee,
        otherFee
      } = obj;
      let total = Number(busFee) + Number(otherFee);
      return total;
    },
    // 修改单行金额
    changeInput() {
      this.getTotalPayMoney();
      this.mergeRowMoney(); // 合并单条数据总价
    },
    // 处理初始数据
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.respOrderBusCompanyTypeSimples || [];
        const {
          id,
          auditProgress,
          shuttleType
        } = it;
        const shuttleTypeLabel = getSelectLabel('shuttleType', shuttleType.toLowerCase());
        const item = {
          ...it,
          rowId: id,
          pickupId: id,
          isCustom: false,
          isChecked: false,
          ap: auditProgress,
          rowTotal: it.totalPay,
          auditProgress,
          status: this.hasAudit(auditProgress),
          isRead: this.isReadOnly(auditProgress),
          payType: (it.payType || 'sign').toLowerCase(),
          shuttleTypeLabel,
          shuttleType,
          busCompanyId: it.busCompanyId,
          startDate: '',
          endDate: '',
          itemIndex: 0
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            const totalSum = Number(v.busFee) + Number(v.otherFee);
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              totalSum
            });
          });
        } else {
          result.push({
            ...itemListKeys(),
            ...item
          });
        }

        // 同步id
        this.rowId++;
      });
      return result;
    },
    // 处理提交数据
    beforeCommit(list) {
      if (!list.length) return [];
      const result = [];
      let last = null;
      const keys = Object.keys(itemListKeys());
      const delKeys = ['isChecked', 'isCustom', 'itemIndex', 'driverList', 'busList', 'shuttleTypeLabel'];
      list.forEach(it => {
        // 还原子项id，在format的时候，会覆盖父级的id
        const item = {};
        !it.isCustom ? item.id = it.id : null;

        // 定义子项目的列表
        it.busCompanyTypeSimples = [];

        // 从合并数据中获取子项的内容
        keys.forEach(key => {
          item[key] = it[key];
          delete it[key];
        });
        if (!this.isAudit) {
          delete it.auditProgress;
          delete it.isAudit;
          delete it.status;
        }

        // 当角色为管理员时，需要单独处理审核状态 管理员新增时默认设置为计调审核
        if (it.ap && this.isAudit) {
          it.auditProgress = it.ap;
          it.status = this.isAdminAudit && it.ap != AuditRole.wait ? true : it.status;
          if (this.getConfigStatus('audit:audit4:audit4-3') && it.isCustom) {
            // 如果开启了需要计调审核配置，但并没有修改审核状态，则修改成计调审核
            it.auditProgress = AuditRole.op;
          }
        }

        // 重新还原该条数据的id
        it.id = it.pickupId;
        it.payType = it.payType.toUpperCase();
        // it.shuttleType =
        // it.shuttleType != ShuttleType.bus
        //   ? it.shuttleType.toLowerCase()
        //   : ''

        it.shuttleType = it.shuttleType.toUpperCase();

        // 新增数据，删除id
        if (it.isCustom) {
          delete it.id;
          delete it.pickupId;
          delete item.id;
        }

        // 删除多余的字段
        delKeys.forEach(key => {
          delete it[key];
        });
        it.totalPay = it.rowTotal;

        // 判断是否与上一条数据是同一条数据，该数据是在format的时候做的拆分
        if (last && last.rowId == it.rowId) {
          last.busCompanyTypeSimples.push(item);
        } else {
          it.busCompanyTypeSimples.push(item);
          result.push(it);
          last = it;
        }
      });
      return result;
    },
    // 校验数据
    validateForm() {
      if (!this.tableData.length) return false;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        const {
          busCompanyId,
          shuttleType,
          busType
        } = row;
        if (!busCompanyId) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择车队！'
          });
          return false;
        }
        if (!shuttleType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择接送类型！'
          });
          return false;
        }
        if (!busType) {
          this.$bus.tip({
            type: 'warning',
            message: '请选择车辆类型！'
          });
          return false;
        }
      }
      return true;
    },
    // 计算单行的价格，因为数据没有返回totalPay
    getTotalPayMoney() {
      this.tableData.forEach(it => {
        const totalPay = this.calcRowTotalMoney(it);
        this.$set(it, 'totalPay', totalPay);
      });
    },
    // 重置表格与价格计算
    resetData() {
      // this.getTotalPayMoney()
      this.rowspan();
      this.mergeRowMoney(false); // 合并单条数据总价
    },
    // 格式化下拉列表
    formatOptions(list, key) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        it.value = it[key];
        result.push(it);
      });
      return result;
    },
    createOrUpdate() {
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        }
        const dataList = this.beforeCommit(this.tableData);
        const loading = this.$bus.loading();
        return this.$store.dispatch(this.updatePath, dataList).then(() => {
          this.fetchData(this.shuttleId);
          this.$bus.tip();
          loading.close();
          resolve();
        }).catch(() => {
          this.fetchData(this.shuttleId);
          loading.close();
          reject();
        });
      });
    }
  }
};