// 发团预定安排
import request from '@/utils/request';
import { APP } from '@/config';

// 安排列表
export function fetchScenic(params) {
  return request({
    url: '/order/orderScenic/page',
    method: 'get',
    params,
    timeout: APP.timeout,
    retry: APP.retry,
    retryDelay: APP.retryDelay
  });
}

// 安排更新
export function updateScenic(data) {
  return request({
    url: '/order/orderScenic/save',
    method: 'post',
    data,
    timeout: 10000
  });
}

// 安排更新
export function updateScenicSupper(data) {
  return request({
    url: '/order/orderScenic/save',
    method: 'post',
    data,
    timeout: 10000,
    headers: {
      tmpSupper: 'TS-1'
    }
  });
}

// 安排删除
export function deleteScenic(ids) {
  return request({
    url: '/order/orderScenic/del',
    method: 'post',
    data: ids
  });
}

// 安排子项删除
export function deleteScenicItem(id) {
  return request({
    url: '/order/orderScenic/delTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单列表
export function fetchScenicOrder(params) {
  return request({
    url: '/order/orderScenic/pageOrder',
    method: 'get',
    params
  });
}

// 订单更新
export function updateScenicOrder(data) {
  return request({
    url: '/order/orderScenic/saveOrder',
    method: 'post',
    data
  });
}

// 订单移除
export function deleteScenicOrder(id) {
  return request({
    url: '/order/orderScenic/delOrder',
    method: 'post',
    data: {
      id
    }
  });
}

// 订单子项删除
export function deleteScenicOrderItem(id) {
  return request({
    url: '/order/orderScenic/delOrderTicket',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取计划订单列表
export function fetchScenicOrderList(params) {
  return request({
    url: '/order/orderScenic/getOrderList',
    method: 'post',
    params
  });
}

// 添加计划订单
export function selectScenicOrderList(data) {
  return request({
    url: '/order/orderScenic/saveAndList',
    method: 'post',
    data
  });
}

// ===== 其他内容没有这2个接口 ==== //
// 查询计划景区列表
export function fetchScenicPlanList(params) {
  return request({
    url: '/order/orderScenic/listScenics',
    method: 'get',
    params
  });
}

// 查询景点协议价格
export function fetchScenicAgreementPrice(params) {
  return request({
    url: '/order/orderScenic/scenicOrder',
    method: 'get',
    params
  });
}