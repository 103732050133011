import "core-js/modules/es.array.push.js";
import { Search, TableList } from './components';
import { checkBillShuttle } from '@/config/billCheck';
import { tagViewMixin, excelMixin } from '@/mixin';
import billCheckMixin from '../searchMixin';
import { getPayTypeLabel } from '@/config/select';
import { downloadExport } from '@/utils/exportExcel';
import { processAdd } from '@/utils';
export default {
  components: {
    Search,
    TableList
  },
  mixins: [tagViewMixin, excelMixin, billCheckMixin],
  data() {
    return {
      tableData: [],
      columnData: checkBillShuttle,
      total: 0,
      statistics: null,
      filename: '接送对账'
    };
  },
  computed: {
    shuttleData() {
      return this.$store.getters['bill/billCheck/shuttleData'];
    }
  },
  watch: {
    shuttleData: {
      handler: function (data) {
        if (!data) return;
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      this.$store.dispatch('bill/billCheck/fetchShuttle', {
        ...this.search,
        ...params
      });
    },
    reload() {
      const {
        searchParams
      } = this.$refs.searchRef;
      this.fetchData(searchParams);
    },
    initData(data) {
      if (!data) {
        this.fetchData({
          startTripDateS: this.getToday
        });
      } else {
        this.handleData(data);
      }
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      const newList = this.formatData(list);
      this.tableData = this.matchOrderNumberPeople(newList);
      this.total = totalCount;
      const {
        totalAdultCount,
        totalChildCount
      } = statistics;
      this.statistics = {
        ...statistics,
        peopleCount: processAdd(totalAdultCount, totalChildCount)
      };
    },
    // 匹配订单并统计人数
    matchOrderNumberPeople(list) {
      return list.map(it => {
        const {
          orderAdultCount,
          orderChildCount
        } = it;
        const peopleCount = processAdd(orderAdultCount, orderChildCount);
        return {
          ...it,
          peopleCount
        };
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      list.forEach(it => {
        const {
          id,
          plan,
          totalPay,
          checkAccountStatus,
          checkAccountMoney,
          busCompanyTypeSimples
        } = it;
        const item = {
          ...plan,
          ...it,
          rowId: id,
          cashType: getPayTypeLabel(it.cashType),
          checkAccountMoney: checkAccountStatus ? checkAccountMoney : totalPay
        };
        this.$set(item, 'ckStatus', false);
        const itemList = busCompanyTypeSimples || [];
        if (itemList.length) {
          itemList.forEach(s => {
            delete s.id;
            result.push({
              ...item,
              ...s
            });
          });
        } else {
          result.push(item);
        }
      });
      return result;
    },
    handleExport(params) {
      const loading = this.$bus.loading();
      this.$store.dispatch('bill/billCheck/exportShuttleExcel', {
        ...this.search,
        ...params
      }).then(res => {
        const fileName = '接送对账表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};