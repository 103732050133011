import { dictionaryMixin, searchMixin } from '@/mixin';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, InputFleet } from '@/components/common';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputFleet,
    SelectProduct,
    SelectStaff
  },
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      sendDate: [],
      endDate: [],
      orderDate: [],
      checkBillDate: [],
      searchParams: {}
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/fleet'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.$emit('on-search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    // // 搜索
    // handleSearch() {
    //   let { path } = this.$route

    //   const search = this.searchParams

    //   this.$store.dispatch('tagsView/appendVisiteViewSearch', {
    //     path,
    //     search,
    //   })
    //   // 搜索的时候重置分页
    //   this.$store.dispatch('tagsView/appendVisiteViewPage', {
    //     path,
    //     page: 1,
    //     size: this.searchParams.pageSize,
    //   })
    //   this.$emit('on-search', search)
    // },

    handleExcel() {
      this.$emit('on-export', this.searchParams);
    }
  }
};