var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "renew"
  }, [_c("DialogPayment", {
    ref: "dialogRef",
    on: {
      "on-submit": _vm.submit
    }
  }, [_vm.visible ? _c("el-form", {
    ref: "formRef",
    staticClass: "renew-content form-content",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "到期时间"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.startDate || "永久") + " ")]), _c("el-form-item", {
    attrs: {
      label: "续费后到期时间"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.endDate) + " ")]), _c("el-form-item", {
    attrs: {
      label: "续费时长",
      prop: "count"
    }
  }, [_c("SelectBase", {
    attrs: {
      list: _vm.yearList,
      clearable: false,
      placeholder: "请选择"
    },
    on: {
      "on-select": _vm.selectDate
    },
    model: {
      value: _vm.form.count,
      callback: function ($$v) {
        _vm.$set(_vm.form, "count", $$v);
      },
      expression: "form.count"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "续费金额"
    }
  }, [_c("span", {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.form.money))])])], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };