var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.business ? _c("InputData", {
    attrs: {
      list: _vm.business,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      label: "商家",
      placeholder: "请选择自费商家"
    }
  }) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };