import { mapGetters } from 'vuex';
import { inputMixin } from '@/mixin';
export default {
  name: 'SelectDictionary',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    code: {
      type: String,
      default: 'guideType'
    },
    resLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curVal: '',
      list: []
    };
  },
  computed: {
    ...mapGetters('system/dictionary', ['dictionaryCodeData']),
    getCurVal() {
      return this.curVal;
    }
  },
  watch: {
    value: {
      handler: 'initData',
      immediate: true
    },
    code: {
      handler: 'filterVal',
      immediate: true
    }
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('system/dictionary/fetchDictionaryType');
    },
    initData(val) {
      this.curVal = val;
    },
    lazyLoad() {
      if (!this.dictionaryCodeData) {
        this.fetchData().then(() => {
          this.filterVal();
        });
      } else {
        this.filterVal();
      }
    },
    handleCode(val) {
      if (!val) return;
      this.filterVal();
    },
    handleChange(val) {
      let curVal = val ? this.list.find(it => it.value == val) : {
        label: '',
        value: ''
      };
      this.$emit('on-select', curVal);
      this.$emit('on-label', curVal.label);
    },
    filterVal() {
      const item = this.dictionaryCodeData[this.code];
      this.list = item ? item.agencyDictionaryList : [];
    }
  }
};