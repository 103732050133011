import { SelectRole, SwitchStatus, InputDepartment } from '@/components/common';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
import { isPhone } from '@/utils';
export default {
  components: {
    SelectRole,
    SwitchStatus,
    InputDepartment
  },
  mixins: [formMixin],
  data() {
    const checkPhone = val => {
      if (!val || !isPhone(val)) return false;
      return true;
    };
    return {
      visible: false,
      isUpdate: false,
      department: [''],
      departmentItem: {},
      form: {},
      validateForm: {
        userName: [{
          required: true,
          message: '账号不能为空'
        }, {
          min: 2,
          max: 20,
          message: '长度不低于2个字，最多不超过20个字'
        }],
        realName: [{
          required: true,
          message: '名字不能为空'
        }, {
          min: 2,
          max: 20,
          message: '长度不低于2个字，最多不超过20个字'
        }],
        department: [{
          required: true,
          message: '部门不能为空'
        }],
        roleName: [{
          required: true,
          message: '角色不能为空'
        }],
        mobilePhone: [{
          required: true,
          message: '手机号不能为空'
        }, {
          validate: checkPhone,
          message: '手机号格式不正确'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      staff: 'system/staff/staffEdit',
      user: 'user/userData'
    }),
    getTitle() {
      return this.isUpdate ? '更新员工信息' : '新增员工';
    }
  },
  watch: {
    staff: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) return;
      this.form = data;
      this.form.department = true;
      const {
        departmentId,
        grandsonDepartmentId,
        subDepartmentId
      } = data;
      this.departmentItem = {
        departmentId,
        subDepartmentId,
        grandsonDepartmentId
      };
    },
    show(isUpdate) {
      this.visible = true;
      this.isUpdate = isUpdate;
    },
    hide() {
      this.visible = false;
    },
    changeRole(val) {
      this.handleDispatch('roleId', val);
    },
    // 部门
    changeDepartment(val) {
      if (val) {
        ['departmentId', 'subDepartmentId', 'grandsonDepartmentId'].forEach(key => {
          this.handleDispatch(key, val[key]);
          if (!val[key]) {
            this.handleDispatch(key, -1);
          }
        });
        this.form.department = true;
      } else {
        this.form.department = false;
      }
    },
    handleChange(type, val) {
      this.handleDispatch(type, val);
    },
    handleDispatch(type, val) {
      this.$store.dispatch('system/staff/change', {
        type,
        val
      });
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      const all = {
        createUserId: this.user.id,
        groupId: this.user.groupId,
        travelAgencyId: this.user.travelAgencyId
      };
      const opts = {
        ...all,
        ...this.staff
      };
      this.$store.dispatch('system/staff/createOrUpdate', opts).then(() => {
        const {
          searchParams
        } = this.$parent;
        this.$parent.fetchData(searchParams);
        this.$bus.tip();
        this.hide();
      });
    }
  }
};