export const PieConfig = {
  turnover: {
    title: '营业额',
    suffix: '万'
  },
  profit: {
    title: '毛利',
    suffix: '万'
  },
  count: {
    title: '人数',
    suffix: '人'
  }
};
export const SalesConfig = {
  left: [{
    title: '销售毛利排名',
    orderKey: 'profit',
    percentKey: 'profitRate',
    diffKey: 'tagProfit',
    keys: ['profit', 'profitRate'],
    list: [],
    delay: 1
  }, {
    title: '销售收客排名',
    orderKey: 'count',
    percentKey: 'countRate',
    diffKey: 'tagCount',
    keys: ['count', 'countRate'],
    list: [],
    delay: 2
  }, {
    title: '销售毛利率排名',
    orderKey: 'realityProfitTurnoverRate',
    percentKey: 'profitTurnoverRate',
    keys: ['realityProfitTurnoverRate', 'profitTurnoverRate'],
    percent: 100,
    suffix: '%',
    list: [],
    delay: 3
  }],
  mid: [{
    title: '销售人均毛利排名',
    orderKey: 'avgProfit',
    percentKey: 'avgProfitRate',
    keys: ['avgProfit', 'avgProfitRate'],
    list: [],
    delay: 1
  }, {
    title: '销售未收款排名',
    orderKey: 'unIncome',
    percentKey: 'unIncomeRate',
    keys: ['unIncome', 'unIncomeRate'],
    list: [],
    delay: 2
  }],
  right: [{
    title: '销售营业额排名',
    orderKey: 'turnover',
    percentKey: 'turnoverRate',
    diffKey: 'tagTurnover',
    keys: ['turnover', 'turnoverRate'],
    list: [],
    delay: 1,
    isCustom: false
  }, {
    title: '销售新开发客户排名',
    orderKey: 'customers',
    percentKey: 'customerRate',
    keys: ['customers', 'customerRate'],
    list: [],
    delay: 2,
    isCustom: true,
    option: {}
  }, {
    title: '销售订单量排名',
    orderKey: 'orders',
    percentKey: 'orderRate',
    keys: ['orders', 'orderRate'],
    list: [],
    delay: 3,
    isCustom: true,
    option: {}
  }]
};
export const CustomerConfig = {
  left: [{
    title: '客户毛利排名',
    orderKey: 'profit',
    percentKey: 'profitRate',
    keys: ['profit', 'profitRate'],
    list: [],
    delay: 1
  }, {
    title: '客户人均毛利排名',
    orderKey: 'avgProfit',
    percentKey: 'avgProfitRate',
    keys: ['avgProfit', 'avgProfitRate'],
    list: [],
    delay: 2
  }, {
    title: '客户毛利率排名',
    orderKey: 'profitTurnoverRate',
    percentKey: 'realityProfitTurnoverRate',
    keys: ['profitTurnoverRate', 'realityProfitTurnoverRate'],
    list: [],
    delay: 3
  }],
  mid: [{
    title: '客户欠款排名',
    orderKey: 'unIncome',
    percentKey: 'unIncomeRate',
    keys: ['unIncome', 'unIncomeRate'],
    list: [],
    delay: 1
  }, {
    title: '客户回款周期排名',
    orderKey: 'returnedMoneyDays',
    percentKey: 'avgReturnedMoneyDaysRate',
    keys: ['returnedMoneyDays', 'avgReturnedMoneyDaysRate'],
    list: [],
    delay: 2
  }],
  right: [{
    title: '客户营业额排名',
    orderKey: 'turnover',
    percentKey: 'turnoverRate',
    keys: ['turnover', 'turnoverRate'],
    list: [],
    delay: 1
  }, {
    title: '客户交客人数排名',
    orderKey: 'count',
    percentKey: 'countRate',
    keys: ['count', 'countRate'],
    list: [],
    delay: 2
  }, {
    title: '客户交客活跃度排名',
    orderKey: 'days',
    percentKey: 'daysRate',
    keys: ['days', 'daysRate'],
    list: [],
    delay: 3
  }]
};
export const SalesmanProfitKeys = ['turnover', 'totalTurnover', 'lastTurnover', 'totalLastTurnover', 'tagYearTurnover', 'tagTurnover', 'allTurnover', 'tagYearTotalTurnover', 'count', 'totalCount', 'lastAdultCount', 'totalLastCount', 'tagYearCount', 'tagCount', 'allCount', 'tagYearTotalCount', 'profit', 'totalProfit', 'lastProfit', 'totalLastProfit', 'tagYearProfit', 'tagProfit', 'allProfit', 'tagYearTotalProfit', 'tagMonthTotalCount', 'tagMonthTotalTurnover', 'tagMonthTotalProfit'];
export const TurnoverPieKeys = {
  reality: {
    key: 'totalTurnover',
    name: '已完成营业额'
  },
  target: {
    key: 'tagMonthTotalTurnover',
    name: '未完成营业额'
  }
};
export const ProfitPieKeys = {
  reality: {
    key: 'totalProfit',
    name: '已完成毛利'
  },
  target: {
    key: 'tagMonthTotalProfit',
    name: '未完成毛利'
  }
};
export const CountPieKeys = {
  reality: {
    key: 'totalCount',
    name: '已完成收客数'
  },
  target: {
    key: 'tagMonthTotalCount',
    name: '未完成收客数'
  }
};
export const TurnoverObjKeys = {
  tagTotal: 'tagMonthTotalTurnover',
  all: '$allTurnover',
  total: '$totalTurnover',
  totalLast: '$totalLastTurnover'
};
export const ProfitObjKeys = {
  tagTotal: 'tagMonthTotalProfit',
  all: '$allProfit',
  total: '$totalProfit',
  totalLast: '$totalLastProfit'
};
export const CountObjKeys = {
  tagTotal: 'tagMonthTotalCount',
  all: 'allCount',
  total: 'totalCount',
  totalLast: 'totalLastCount'
};