import "core-js/modules/es.array.push.js";
import { tabMenu, AuditRole, TYPES } from '@/config/audit';
import { planTrip } from '@/config/plan';
import { mapGetters } from 'vuex';
import { auditMixin } from '@/mixin';
import { orderType } from '@/config/order';
import { pickSystemConfig } from '@/utils';
import { systemStateMap } from '@/config/systemConfig';
import { LOG_YPES, LOG_SUBTYPE } from '@/config/resource';
// import { EmailConfig } from '@/config'
import importMixin from './mixin';
import permission from '@/directive/permission/index';
import DialogEmail from './components/DialogEmail.vue';
export default {
  name: 'AuditEdit',
  directives: {
    permission
  },
  components: {
    DialogEmail
  },
  mixins: [importMixin, auditMixin],
  data() {
    return {
      AuditRole,
      columnData: planTrip,
      btnLoading: false,
      tableLoading: false,
      tableData: [],
      tabMenu,
      filterTabMenu: [],
      tabIndex: '0',
      planId: null,
      doNotShowTeamProfit: false
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      planInfo: 'plan/index/planInfo',
      currentPlanId: 'plan/index/currentPlanId',
      currentRole: 'audit/index/currentRole',
      tabStatus: 'audit/index/tabStatus',
      systemConfig: 'system/systemConf/configData',
      rolePermissionList: 'system/role/rolePermissionList'
    }),
    getSaveBtnDisable() {
      if (this.filterTabMenu[this.tabIndex].type == TYPES.shuttle) {
        return this.isOnlyRowBtnDisable;
      }
      return this.isBtnDisable;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
    getBtnText() {
      return `保存${this.filterTabMenu[this.tabIndex].label}`;
    },
    getComps() {
      return this.filterTabMenu.length ? this.filterTabMenu[this.tabIndex].component : tabMenu[0].component;
    },
    getLogType() {
      return this.filterTabMenu[this.tabIndex].type;
    },
    planStatus() {
      if (!this.planInfo) return false;
      const {
        planStatus
      } = this.planInfo;
      return planStatus;
    },
    statusCheck() {
      return this.planStatus === 'SETTLEMENT';
    },
    statusFinance() {
      return this.getStatus('op') && this.getRole('finance');
    },
    statusFinanceCancel() {
      return this.getStatus('finance') && this.getRole('finance') || this.getRole('admin');
    },
    needOPAudit() {
      return pickSystemConfig(systemStateMap.needOPAudit, this.systemConfig);
    },
    // 计调审核
    statusOpBtn() {
      return this.isAdminAudit && this.getStatus('wait') && this.needOPAudit || this.getRole('op') && this.needOPAudit && this.getStatus('wait');
    },
    // 财务审核
    statusFinanceBtn() {
      return this.isAdminAudit && !this.getStatus('finance') || this.getRole('finance') && !this.getStatus('finance');
    },
    // 退回计调
    statusOpBackBtn() {
      return this.getStatus('op') && this.needOPAudit || this.getRole('finance') && this.getStatus('op') && this.needOPAudit;
    },
    // 取消财务审核
    statusFinanceBackBtn() {
      // 不是已归档，并且是财务已审核
      return (this.getRole('admin') || this.getRole('finance')) && this.getStatus('finance') && this.planStatus != AuditRole['settlement'];
    },
    statusCheckBtn() {
      return this.planStatus == AuditRole['settlement'] && this.getStatus('finance');
    },
    isFirstEnter() {
      return this.$store.getters['plan/index/firstEnter'];
    }
  },
  watch: {
    rolePermissionList: {
      handler: 'getRoleTabMenu',
      immediate: true
    }
  },
  created() {
    const {
      id
    } = this.$route.query;
    this.planId = id;
    this.$store.commit('plan/index/CUR_PLAN_ID', id);
    // 监听日志
    this.$bus.on('plan:log', this.showLog);

    // 如果在当前团切换tab则不请求
    if (this.isFirstEnter) {
      this.fetchPlanDetail(id);
    }
    this.fetchPlanOrder(id);
  },
  beforeDestroy() {
    this.$bus.off('plan:log', this.showLog); // 注销on 方法
  },
  methods: {
    fetchPlanDetail(id) {
      this.$store.dispatch('plan/index/fetchPlanById', id).then(() => {
        this.$store.commit('plan/index/FIRST_ENTER', false);
      });
    },
    // 获取订单行程
    fetchPlanOrder(id) {
      this.$store.dispatch('plan/index/getOrderByPlanId', id).then(data => {
        this.tableData = data.map(it => {
          this.$set(it, 'expand', true);
          return it;
        });
      });
    },
    // 获取团信息
    getPlanInfo(planId) {
      this.$store.dispatch('plan/index/fetchPlanById', planId);
    },
    fetchTeamProfit() {
      this.$store.dispatch('plan/index/groupProfit', this.planId);
    },
    getRoleTabMenu(list) {
      if (list && list.length) {
        const curNav = list.find(it => it.code === '/audit');
        const currentMenu = curNav.subMenus.find(it => it.code === '/audit/team-single');
        const map = [];
        currentMenu.roleMenuOpts.filter(it => {
          if (it.isRole) {
            map.push(it.code.toLowerCase());
          }
        });
        this.filterTabMenu = tabMenu.filter(it => !!map.includes(it.type.toLowerCase()));
        this.doNotShowTeamProfit = map.some(it => ~it.indexOf('audit:team-single:profit'));
        if (!this.currentRole) {
          this.tabIndex = '0';
        } else {
          const tabIndex = this.filterTabMenu.findIndex(it => it.type.toLowerCase() == this.currentRole.code.toLowerCase());
          this.tabIndex = tabIndex + '';
        }
      }
    },
    handleTab(index) {
      const {
        planType
      } = this.$route.query;
      let {
        id,
        label,
        type
      } = this.filterTabMenu[index];
      type = type.toLowerCase();
      const role = {
        isRole: true,
        id,
        name: label,
        code: type
      };
      const path = `/audit/team-single-edit/${type}`;
      this.$router.replace({
        path,
        query: {
          id: this.planId,
          planId: this.planId,
          planType
        }
      });
      this.$store.commit('audit/index/CUR_ROLE', role);
      this.tabIndex = index + '';
    },
    // 更新审核状态
    handleSave() {
      const {
        contentRef
      } = this.$refs;
      this.btnLoading = true;
      contentRef.saveAndAudit().then(() => {
        this.btnLoading = false;
      }).catch(() => {
        this.btnLoading = false;
      });
    },
    refresh() {
      const {
        contentRef
      } = this.$refs;
      contentRef.reloadData();
      this.getPlanInfo(this.planId);
      this.$store.commit('audit/index/BACK_STATUS', true); // 返回列表刷新列表数据
    },
    // 保存更新和设置选中审核
    setOpAudit(role) {
      this.$store.dispatch('audit/index/updateAuditOrder', {
        planId: this.planId,
        auditProgress: role
      }).then(() => {
        this.$bus.tip();
        this.refresh();
      });
    },
    // 退回计调
    backOpAudit(role = AuditRole.op) {
      this.$store.dispatch('audit/index/cancelAuditOrder', {
        planId: this.planId,
        auditProgress: role
      }).then(() => {
        this.$bus.tip();
        this.refresh();
      });
    },
    // 财务已审
    setFinanceAudit() {
      this.setOpAudit(AuditRole.finance);
    },
    // 取消财务已审
    cancelFinanceAudit() {
      this.backOpAudit(AuditRole.finance);
    },
    // 确认结算
    handleSettle() {
      this.$store.dispatch('audit/index/checkAuditOrder', {
        planId: this.planId,
        status: !this.statusCheck
      }).then(() => {
        this.getPlanInfo(this.planId);
      });
    },
    showLog() {
      const types = `${LOG_YPES.plan},${LOG_YPES.outTransfer},${LOG_YPES.sign},${LOG_YPES.audit}`;
      let subType = LOG_SUBTYPE[this.getLogType];
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId: this.planId,
        subType,
        types
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    showRowLog(subItemId) {
      this.$store.dispatch('audit/index/fetchLog', {
        itemId: this.planId,
        subType: this.getLogType,
        subItemId
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    handlePrint() {
      const {
        id
      } = this.planInfo;
      let routeUrl = this.$router.resolve({
        path: '/print',
        query: {
          id,
          planId: id,
          type: 'plan',
          component: this.planInfo.planType == orderType.team ? 'PlanTeam' : 'PlanPersonal',
          planType: this.planInfo.planType,
          gateUrl: 'audit'
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    openEmail() {
      const {
        id
      } = this.planInfo;
      this.$refs.emailRef.show({
        id
      });
    }
  }
};