import { dictionaryMixin, searchMixin } from '@/mixin';
import { SelectLine, SelectGuide, SelectProduct, SelectStaff, InputFleet } from '@/components/common';
export default {
  components: {
    SelectGuide,
    SelectLine,
    InputFleet,
    SelectProduct,
    SelectStaff
  },
  mixins: [searchMixin, dictionaryMixin],
  data() {
    return {
      sendDate: [],
      endDate: [],
      orderDate: [],
      checkBillDate: [],
      searchParams: {
        shuttleType: 'shuttle'
      }
    };
  },
  computed: {
    data() {
      return this.$store.getters['bill/billCheck/fleet'];
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.$emit('on-search', this.searchParams);
        }
      },
      immediate: true
    }
  },
  methods: {
    // 选择
    onSelect(type, val) {
      this.searchParams[type + 'Id'] = val.value;
      this.searchParams[type + 'Label'] = val.label;
    },
    changeDate(type, val) {
      const [starDate, endDate] = val;
      this.searchParams[type + 'S'] = starDate;
      this.searchParams[type + 'E'] = endDate;
    },
    selectShuttle(val) {
      if (!val) {
        this.searchParams.shuttleType = 'shuttle';
      }
    },
    handleExcel() {
      this.$emit('on-export', this.searchParams);
    }
  }
};