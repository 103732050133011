// 代收审核
export const Columns = [{
  attrs: {
    prop: 'orderInfo',
    label: '订单信息',
    width: 160
  },
  slot: 'orderInfo'
}, {
  attrs: {
    prop: 'customerName',
    label: '客户',
    'min-width': 120
  },
  slot: 'customerName'
}, {
  attrs: {
    prop: 'date',
    label: '行程日期',
    width: 100
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'members',
    label: '客人联系人'
  },
  slot: 'members'
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  },
  slot: 'peopleCount'
}, {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'guideName',
    label: '导游'
  },
  slot: 'guideName'
}, {
  attrs: {
    prop: 'type',
    label: '类型'
  }
}, {
  attrs: {
    prop: 'remarks',
    label: '项目'
  }
}, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'collection',
      label: '社收'
    },
    slot: 'collection'
  }, {
    attrs: {
      prop: 'guideCollection',
      label: '导游代收'
    },
    slot: 'guideCollection'
  }]
}, {
  attrs: {
    prop: 'paymentCollectionTime',
    label: '回款时间',
    width: 180
  },
  slot: 'paymentCollectionTime'
}, {
  attrs: {
    prop: 'action',
    label: '操作',
    width: 120
  },
  slot: 'action',
  header: 'billCheck'
}];
export const statisticsKeys = {
  peopleCount: 'peopleCount',
  collection: 'totalAgencyIncome',
  guideCollection: 'totalGuideIncome'
};