export default {
  data: () => ({
    visible: false,
    travelId: '',
    list: null
  }),
  methods: {
    show(list) {
      this.visible = true;
      this.list = this.formatList(list);
    },
    formatList(list) {
      return list.map(it => {
        const {
          wx_uid,
          id
        } = it;
        return {
          label: id,
          wx_uid,
          travelAgencyId: id,
          value: it.name
        };
      });
    },
    handleLogin() {
      if (!this.travelId) {
        this.$bus.tip({
          type: 'warning',
          message: '请选择旅行社'
        });
        return;
      }
      const travelInfo = this.list.find(it => it.travelAgencyId == this.travelId);
      this.$parent.handleEnter(travelInfo);
      this.visible = false;
    }
  }
};