import { FormAccount, FormSource, DropzoneBox } from '@/components/common';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
export default {
  components: {
    FormAccount,
    FormSource,
    DropzoneBox
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      index: -1,
      images: [],
      validateForm: {
        name: [{
          required: true,
          message: '餐厅名字不能为空'
        }]
      }
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      accountForm: 'resource/account/editAccountInfo',
      editSourceInfo: 'resource/common/editSourceInfo',
      imageList: 'resource/common/imageList',
      // 照片信息
      imageListNew: 'resource/common/imageListNew',
      // 新上传的照片
      imageListNewDel: 'resource/common/imageDelAddList' // 新上传删除的照片
    }),
    getTitle() {
      return this.isUpdate ? '更新餐厅' : '添加餐厅';
    }
  },
  methods: {
    edit(scope) {
      const {
        row
      } = scope;
      this.index = scope.$index;
      this.visible = true;
      this.isUpdate = true;
      this.resetForm();
      this.$store.dispatch('resource/restaurant/fetchImage', row.id);
      this.$store.dispatch('resource/common/editResource', row);
    },
    show() {
      this.resetForm();
      this.visible = true;
      this.isUpdate = false;
      this.$store.dispatch('resource/common/editSource', {});
      this.$store.commit('resource/account/CLEAR_ACCOUNT');
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      const picPaths = this.mergeImage();
      const {
        id,
        groupId,
        travelAgencyId,
        realName
      } = this.userData;
      const userData = {
        createUserId: id,
        groupId,
        travelAgencyId,
        realName
      };
      const opts = {
        ...userData,
        ...this.accountForm,
        ...this.editSourceInfo,
        picPaths,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/restaurant/createOrUpdate', opts).then(() => {
        this.$parent.initData();
        this.$parent.fetchList();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    resetForm() {
      // 清理图片
      this.$store.commit('resource/common/CLEAR_IMAGE');
      // 清理新增没有id 的图片
      this.$store.dispatch('resource/common/deleteAddImage');
    }
  }
};