// 资源计划

const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作'
  },
  slot: 'action'
};
const ResourceBase = [{
  attrs: {
    prop: 'planNumber',
    label: '团号'
  }
}, {
  attrs: {
    prop: 'date',
    label: '行程日期'
  },
  slot: 'date'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'peopleCount'
},
// { attrs: { prop: 'tourist', label: '游客联系人' } },
{
  attrs: {
    prop: 'shipTicket',
    label: '船票',
    'min-width': 300
  },
  slot: 'shipTicket'
}, {
  attrs: {
    prop: 'source',
    label: '客源地'
  }
}, {
  attrs: {
    prop: '$outOPUserName',
    label: '客服计调'
  }
}, {
  attrs: {
    prop: 'createTime',
    label: '下单时间',
    'min-width': 100
  },
  slot: 'createTime'
}, {
  attrs: {
    prop: 'bookingStatus',
    label: '计划状态'
  },
  slot: 'status'
}];

// 资源列表
export const Columns = [...ResourceBase, actionItem];

// 历史
export const ColumnsHistory = ResourceBase;

// 游客名单
export const ColumnsBoatName = [{
  attrs: {
    prop: 'shipType',
    label: '船型'
  },
  slot: 'shipType'
}, {
  attrs: {
    prop: 'shipTicketType',
    label: '舱位'
  },
  slot: 'shipTicketType'
}, {
  attrs: {
    prop: 'orderNumber',
    label: '序号'
  }
}, {
  attrs: {
    prop: 'customerTripNumber',
    label: '小团号'
  }
}, {
  attrs: {
    prop: 'peopleCount',
    label: '人数'
  }
}, {
  attrs: {
    prop: 'age',
    label: '年龄'
  }
}, {
  attrs: {
    prop: 'name',
    label: '姓名'
  }
}, {
  attrs: {
    prop: '$cardType',
    label: '证件类型'
  }
}, {
  attrs: {
    prop: 'cardNumber',
    label: '证件号码'
  }
}, {
  attrs: {
    prop: 'ticket',
    label: '全/半票'
  },
  slot: 'ticket'
}, {
  attrs: {
    prop: 'status',
    label: '半票儿童使用监护人证件（全票或半票使用本人证件请选否）'
  },
  slot: 'status'
}, {
  attrs: {
    prop: 'country',
    label: '国籍/地区（护照等国际证件时必填）'
  }
}];
export const mergeKeys = ['shipType', 'shipTicketType', 'orderNumber', 'customerTripNumber', 'peopleCount'];