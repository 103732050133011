import TableTransfer from './TableTransfer';
const defaultState = () => ({
  ids: [],
  arranges: [],
  startTripDate: new Date(),
  endTripDate: '',
  planNumber: '',
  // 大团号
  planId: '',
  // 拼至已有团
  productId: '',
  productName: '',
  productDays: '',
  dutyOPUserId: '' // 责任计调
});
export default {
  components: {
    TableTransfer
  },
  data() {
    return {
      visible: false,
      id: null,
      newPlanId: null,
      showArranges: false,
      form: defaultState()
    };
  },
  methods: {
    show(id) {
      this.visible = true;
      this.id = id;
    },
    selectNewPlan(id) {
      this.newPlanId = id;
    },
    hide() {
      this.visible = false;
    },
    // 拼团
    handleCreate() {
      const opts = {
        id: this.id,
        newPlanId: this.newPlanId
      };
      this.$store.dispatch('order/personal/personalTransfer', opts).then(data => {
        const {
          message
        } = data;
        this.$bus.tip({
          message
        });
        this.hide();
        this.$parent.fetchData();
      });
    }
  }
};