import { uploadUrl } from '@/config';
import { getToken, getOssName } from '@/utils';
export default {
  data() {
    return {
      uploadUrl,
      visible: false,
      headers: {
        Authorization: 'bearer ' + getToken()
      },
      acceptType: 'image/*, .doc,.docx,.xlsx, .xls,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      fileList: [],
      id: ''
    };
  },
  mounted() {},
  methods: {
    show(id) {
      this.visible = true;
      this.id = id;
    },
    hide() {
      this.visible = false;
      this.fileList = [];
    },
    handleSuccess(res) {
      // const { name, size, row } = file
      // const imageUrl = URL.createObjectURL(file.raw)
      const {
        url
      } = res.data;
      const path = getOssName(url);
      // this.fileList.push({ name: file.name, url })

      this.$emit('success', {
        id: this.id,
        path
      });
      this.hide();
    },
    beforeUpload(file) {
      console.log('file.type:', file.type);
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isDoc = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isPass = isJPG || isPNG || isExcel || isDoc;
      if (!isJPG && !isPNG && !isExcel) {
        this.$message.error('上传格式错误!');
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isPass && isLt5M;
    },
    handleSubmit() {
      this.$refs.uploadRef.submit();
    }
  }
};