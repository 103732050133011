import OrderInfo from './OrderInfo.vue';
import OrderApply from './OrderApply.vue';
import PayInfo from '../components/PayInfo.vue';
import modifyMixin from '../modify';
import { parseDate, parseTime } from '@/utils';
export default {
  components: {
    OrderInfo,
    OrderApply,
    PayInfo
  },
  mixins: [modifyMixin],
  data() {
    return {
      parseTime,
      parseDate,
      visible: false,
      isEdit: false,
      info: {},
      baseInfo: null,
      payInfoData: null,
      orderInfoData: null
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    getEditStatus() {
      return this.isEdit;
    }
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('order/resource/fetchBookingScenicDetail', params).then(data => {
        this.handleData(data);
      });
    },
    show(row, isEdit = false) {
      const params = {
        id: row.rowId
      };
      !isEdit ? params.isReview = 1 : null;
      this.isEdit = isEdit;
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
    },
    notice() {
      this.$notify({
        title: '修改成功',
        message: '修改内容申请已提交，待对方确认后，修改内容才会变更。',
        type: 'success'
      });
    },
    handleData(row) {
      const {
        payType,
        totalPay
      } = row;
      this.info = row;
      this.payInfoData = {
        payType,
        totalPay
      };
      this.orderInfoData = row;
      this.visible = true;
    },
    handleConfirm() {
      const {
        tableRef
      } = this.$refs;
      tableRef.handleCommit().then(() => {
        this.hide();
        this.$bus.tip();
        this.$parent.initData();
        this.info.sendPlanStatus && this.notice();
      });
      // this.$store.dispatch('order/resource/bookingScenicSet', this.orderInfoData)
    }
  }
};