import { ScenicAdd } from '@/components/common/Order';
import { auditExtraScenic, auditExtraScenicAll } from '@/config/audit';
import { permissionMixin, systemMixin } from '@/mixin';
export default {
  components: {
    ScenicAdd
  },
  mixins: [permissionMixin, systemMixin],
  data() {
    return {
      columnData: [],
      visible: false,
      auditAll: false
    };
  },
  mounted() {
    this.visible = false;
    setTimeout(() => {
      this.columnData = this.hasSettlementPrice('scenicFloorPriceAudit') ? auditExtraScenicAll : auditExtraScenic;

      // 是否显示订单信息
      const isOrderInfo = this.getConfigStatus('plan:plan_order_info:plan_order_info');
      if (!isOrderInfo) {
        this.columnData = this.columnData.filter(it => it.attrs.prop != 'planInfo' && it.slot != 'orderInfo');
      }
      this.visible = true;
    }, 100);
  },
  methods: {
    saveAndAudit() {
      return this.$refs.itemRef.saveAndAudit();
    }
  }
};