import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { guideReimb } from '@/config/report';
import { SelectGuide, BtnExport, TableTotalBox } from '@/components/common';
import TableGuideReimb from '../components/TableGuideReimb';
import { SELECTOR } from '@/config/select';
import { mapGetters } from 'vuex';
import { searchMixin } from '@/mixin';
import { processAdd, processMinus } from '@/utils/number';
import { parseDate } from '@/utils';
const today = parseDate(new Date(), '{y}-{m}-{d}');
export default {
  components: {
    SelectGuide,
    BtnExport,
    TableGuideReimb,
    TableTotalBox
  },
  mixins: [searchMixin],
  data: () => ({
    orderTypeList: SELECTOR.reportPlanType,
    total: 0,
    // sendDate: [today, ''],
    searchParams: {
      startTripDateS: today
    },
    tableLoading: false,
    tableData: [],
    columnData: guideReimb,
    statisticData: [],
    statistic: {}
  }),
  computed: {
    ...mapGetters({
      guideReimbData: 'report/index/guideReimbData'
    })
  },
  watch: {
    guideReimbData: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
    },
    onSelectAuditStatus(val) {
      this.searchParams.auditProgressLabel = val.label;
      this.searchParams.auditProgress = val.value;
    },
    onSelectGuide(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.guideId = value;
      this.searchParams.guideLabel = label;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      // 计算欠报
      list.map(it => {
        const {
          collectReceive,
          shopCollectReceive
        } = it;
        const {
          travelAgencyPay,
          travelAgencyPayed
        } = it;
        it.lacOfPay = processMinus(travelAgencyPay, travelAgencyPayed);
        // 代收款计算  代收+ 外接代收 + 购物代收
        // it.collectReceive =
        //   it.collectReceive + it.outCollectReceiveReimb + it.shopCollectReceive
        it.collectReceive = processAdd(collectReceive, shopCollectReceive);
        return it;
      });
      this.total = totalCount;
      this.tableData = list;
      const {
        collectReceive,
        shopCollectReceive
      } = statistics;
      const {
        travelAgencyPay,
        travelAgencyPayed
      } = statistics;
      // 计算欠报
      statistics.lacOfPay = processMinus(travelAgencyPay, travelAgencyPayed);
      // 计算代收款
      statistics.collectReceive = processAdd(collectReceive, shopCollectReceive);
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    fetchData(opts) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getGuideReimb', opts).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出报表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchGuideReimbExcel', this.searchParams).then(res => {
        const fileName = '导游报账统计表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};