// 判断参数是否是其中之一
function oneOf(value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}
export default {
  name: 'PopoverTip',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    content: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 200
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      validator(value) {
        return oneOf(value, ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end']);
      },
      default: 'top-start'
    }
  }
};