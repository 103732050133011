// import { mapGetters } from 'vuex'
import { SelectDictionary, BtnExport, SelectCustom, InputMonthRange } from '@/components/common';
import TableList from './TableList';
import { customAnalyze } from '@/config/analysis';
import itemChart from './itemChart';
import { decimalSplit } from '@/utils';
import analysisMixin from '../analysisMixin';
export default {
  components: {
    SelectDictionary,
    TableList,
    BtnExport,
    SelectCustom,
    InputMonthRange,
    itemChart
  },
  mixins: [analysisMixin],
  data() {
    return {
      columnData: customAnalyze,
      tableData: [],
      isVisible: true,
      turnoverList: [],
      countrList: [],
      profitList: [],
      // 人数年度目标
      yearAdultCountTag: 0
    };
  },
  computed: {
    customData() {
      return this.$store.getters['analyse/index/customData'];
    }
  },
  watch: {
    customData: {
      handler: function (data) {
        if (!data) {
          const {
            start,
            end,
            type,
            customerArea
          } = this.$route.query;
          if (type) {
            this.sendDate = [start, end];
            this.searchParams.startMonth = start;
            this.searchParams.endMonth = end;
            this.searchParams.customerArea = customerArea;
          }
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    initData(res) {
      this.isVisible = false;
      this.tableData = res;
      this.yearAdultCountTag = 0;
      // 人数
      this.countrList = res.map(it => {
        this.yearAdultCountTag += it.yearAdultCountTag;
        const item = {
          name: this.formatName(it.name),
          value: it.count,
          complet: decimalSplit(it.peopleCompletionRate * 100)
        };
        return item;
      });
      // 营业额
      this.turnoverList = res.map(it => {
        const item = {
          name: this.formatName(it.name),
          value: it.turnover,
          complet: decimalSplit(it.turnoverCompletionRate * 100)
        };
        return item;
      });
      // 毛利
      this.profitList = res.map(it => {
        const item = {
          name: this.formatName(it.name),
          value: it.profit,
          complet: decimalSplit(it.profitCompletionRate * 100)
        };
        return item;
      });
      this.$nextTick(() => {
        this.isVisible = true;
      });
    },
    fetchData(queryParams) {
      this.$store.dispatch('analyse/index/fetchCustom', queryParams);
    },
    changeArea(val) {
      this.searchParams.customerArea = val.label;
    },
    changeCustom(val) {
      this.searchParams.customLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    formatName(name) {
      name.replace(/(广西|桂林市|有限公司|有限责任公司|旅行社|旅游)/g, '');
      if (name.length > 6) {
        name = name.slice(0, 6);
      }
      return name;
    }
  }
};