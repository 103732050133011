import { isAccountNumber, parseDate } from '@/utils';
const validateAcquaintance = val => {
  if (val <= 0) return false;
  return true;
};
const checkCardNo = val => isAccountNumber(val);
export const BankType = {
  BANK: 'BANK',
  ALIPAY: 'ALIPAY'
};
export const SelectBankTypeList = [{
  label: '银行卡',
  value: BankType.BANK
}, {
  label: '支付宝',
  value: BankType.ALIPAY
}];
export const defaultAlipayRenew = () => ({
  accountCardNo: '',
  // 账户
  bankType: BankType.ALIPAY,
  cashAction: 'INCOME',
  // 财务方向
  subjectName: '预收账款',
  // 会计科目
  cashUserId: '',
  // 调账人
  cashDate: parseDate()
});
export const defaultAccount = () => ({
  name: '',
  cardNo: '',
  account: '',
  bank: '',
  bankType: BankType.BANK,
  initBlance: 0,
  initDate: '',
  initDataFormat: '',
  remarks: ''
});
export const defaultCardBetween = () => ({
  cashDate: '',
  accountCardNo: '',
  cashAction: '',
  money: '',
  moneyMinute: '',
  subjectId: '',
  bankId: '',
  cashUserId: '',
  desBankId: '',
  desBankName: '',
  cashUserName: ''
});
export const defaultCard = () => ({
  cashDate: '',
  accountCardNo: '',
  cashAction: '',
  moneyMinute: '',
  money: '',
  bankId: '',
  cashUserId: '',
  desBankId: '',
  cashUserName: '',
  subjectName: '',
  subjectId: ''
});
export const accountRules = {
  name: [{
    required: true,
    message: '开户名不能为空'
  }, {
    min: 2,
    max: 40,
    message: '长度不低于2个字，最多不超过20个字'
  }],
  cardNo: [{
    required: true,
    message: '卡号不能为空'
  }, {
    validate: checkCardNo,
    message: '银行卡号格式不正确'
  }],
  account: [{
    required: true,
    message: '开户人不能为空'
  }],
  bank: [{
    required: true,
    message: '开户行不能为空'
  }],
  initDataFormat: [{
    required: true,
    message: '期初日期不能为空'
  }]
};
export const cardBetweenRules = {
  cashAction: [{
    required: true,
    message: '请选择财务方向'
  }],
  moneyMinute: [{
    required: true,
    message: '转账金额不能等于0'
  }, {
    validate: validateAcquaintance,
    message: '转账金额不能等于0'
  }],
  subjectId: [{
    required: true,
    message: '请选择会计科目'
  }],
  desBankId: [{
    required: true,
    message: '请选择收款账户'
  }],
  cashDate: [{
    required: true,
    message: '日期不能为空'
  }]
};
export const cardRules = {
  cashAction: [{
    required: true,
    message: '请选择财务方向'
  }],
  moneyMinute: [{
    required: true,
    message: '转账金额不能等于0'
  }, {
    validate: validateAcquaintance,
    message: '转账金额不能等于0'
  }],
  cashDate: [{
    required: true,
    message: '日期不能为空'
  }],
  subjectId: [{
    required: true,
    message: '请选择会计科目'
  }]
};
export const alipayRenewRules = {
  money: [{
    required: true,
    message: '请输入金额！',
    trigger: 'blur'
  }],
  cashDate: [{
    required: true,
    message: '请选择充值日期！',
    trigger: 'blur'
  }]
};