export function mergeTableRow(data, merge) {
  if (!merge || merge.length === 0) {
    return data;
  }
  merge.forEach(m => {
    const mList = {};
    data = data.map((v, index) => {
      const rowVal = v[m];
      if (mList[rowVal] && mList[rowVal].mergeId === v.mergeId && mList[rowVal].newIndex === index) {
        mList[rowVal]['num']++;
        mList[rowVal]['newIndex']++;
        data[mList[rowVal]['index']][m + '-span'].rowspan++;
        v[m + '-span'] = {
          rowspan: 0,
          colspan: 0
        };
      } else {
        mList[rowVal] = {
          mergeId: v.mergeId,
          orderDate: v.orderDate,
          roomId: v.roomId,
          num: 1,
          index: index,
          newIndex: index + 1
        };
        v[m + '-span'] = {
          rowspan: 1,
          colspan: 1
        };
      }
      return v;
    });
  });
  return data;
}
export function objectSpanMethod({
  row,
  column
}) {
  const span = column['property'] + '-span';
  if (row[span]) {
    return row[span];
  }
}