var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ticket-bill-preview"
  }, [_c("h2", [_vm._v("签单票据")]), _vm.getImageList.length ? _c("div", {
    staticClass: "content"
  }, _vm._l(_vm.getImageList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "sign-img-box"
    }, [_c("el-image", {
      attrs: {
        src: item,
        fit: "scale-down",
        "preview-src-list": _vm.getImageList
      }
    })], 1);
  }), 0) : _c("Empty")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };