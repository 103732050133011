var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "item"
  }, [_c("el-checkbox", {
    on: {
      change: _vm.updateConfigStatus
    },
    model: {
      value: _vm.allowMergeTeam,
      callback: function ($$v) {
        _vm.allowMergeTeam = $$v;
      },
      expression: "allowMergeTeam"
    }
  }, [_vm._v(" 团款合理才能确认成团 ")]), _c("div", {
    staticClass: "btn-box"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      round: ""
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v(" 新增规则 ")])], 1)], 1), _c("div", {
    staticStyle: {
      width: "400px"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.Columns,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "isEnable",
      fn: function ({
        scope
      }) {
        return [_c("el-tag", {
          attrs: {
            type: scope.row.isEnable ? "success" : "info"
          }
        }, [_vm._v(" " + _vm._s(scope.row.isEnable ? "是" : "否") + " ")])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.editItem(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.delItem(scope.row);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }])
  })], 1), _c("DialogTeamFee", {
    ref: "dialogTeamFeeRef",
    on: {
      reload: _vm.fetchSysConfig
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };