import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    handleCustom(scope) {
      this.$parent.addCustom(scope);
    },
    changeStatusFree(row, val) {
      row.scenicType = val ? 'FREE' : 'PAID';
      this.$parent.createOrUpdate(row);
    }
  }
};