import TableListPlanShop from './TableListPlanShop.vue';
import { shoppingInputCreateAll, shoppingInputCreate } from '@/config/shopping';
import { permissionMixin } from '@/mixin';
// import { auditEditShoppingOld, auditEditShoppingOldAll } from '@/config/audit'
export default {
  components: {
    TableListPlanShop
  },
  mixins: [permissionMixin],
  data: () => ({
    visible: false,
    shop: null,
    planId: -1,
    columnData: []
  }),
  methods: {
    show(data) {
      const SubsidyTwo = this.hasSubsidyTwoPrice(); // 二返权限
      this.columnData = SubsidyTwo ? shoppingInputCreateAll : shoppingInputCreate;
      this.visible = true;
      setTimeout(() => {
        this.$refs.tableRef.initData(data);
      }, 500);
    },
    hide() {
      this.visible = false;
    },
    handleConfirm() {
      this.$refs.tableRef.createOrUpdate().then(() => {
        this.$parent.handleSearch();
        this.hide();
      });
    }
  }
};