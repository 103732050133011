var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt20 area-index"
  }, [_c("FormBase", {
    staticClass: "form-wrapper",
    attrs: {
      "label-width": "60px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputMonthRange", {
    attrs: {
      "default-date": _vm.sendDate,
      label: "查询时间"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("SelectDictionary", {
    attrs: {
      label: "区域",
      code: "regionType"
    },
    on: {
      "on-select": _vm.changeArea
    },
    model: {
      value: _vm.searchParams.area,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "area", $$v);
      },
      expression: "searchParams.area"
    }
  }), _c("BtnExport", {
    on: {
      "on-search": _vm.handleSearch,
      "on-excel": function ($event) {
        return _vm.exportExcel("游客年龄段分析表", "export-age-table", 1);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "table-chart-member"
  }, [_c("TableList", {
    ref: "tableRef",
    staticClass: "mt20",
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _vm.isVisible ? _c("chartPie", {
    staticClass: "age-chart",
    attrs: {
      id: "ageChart",
      title: "游客年龄段人数占比",
      "progress-list": _vm.ageList
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "chart-item-member flex"
  }, [_vm.isVisible ? _c("chartPie", {
    staticClass: "age-chart",
    attrs: {
      id: "perChart",
      title: "游客年龄段人均购物占比",
      "progress-list": _vm.perList
    }
  }) : _vm._e(), _vm.isVisible ? _c("chartPie", {
    staticClass: "age-chart",
    attrs: {
      id: "shopMoneyChart",
      title: "游客年龄营业额占比",
      "progress-list": _vm.shopMoneyList
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };