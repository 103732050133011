import { GuideType, CardType, CardTypeLabel, LeaderType, VehicleType, TeamGroupType, TransitType, BusinessType, DestinationType } from './enums';

// 字段转换
export const ObjectKeyTransfor = {
  planNumber: 'team_id',
  productName: 'itinerary_name',
  adultCount: 'people_nums',
  childCount: 'child_nums',
  startPlanDate: 'date_go',
  endPlanDate: 'date_back',
  // customerArea: 'source_city',
  customerName: 'receive_company_name'
};
export const defaultItinerary = () => ({
  day_number: '',
  site_num: '',
  journey_desc: '',
  is_transit: TransitType.NO,
  city: DestinationType.CITY,
  province: DestinationType.PROVINCE
});

// 证件类型
export const CardTypeTransfor = {
  0: CardType.IDCARD,
  2: CardType.PASSPORT,
  5: CardType.TAIWAN_GO,
  3: CardType.GANGAO_GO,
  4: CardType.TAIBAO,
  [CardTypeLabel.IDCARD]: CardType.IDCARD,
  [CardTypeLabel.PASSPORT]: CardType.PASSPORT,
  [CardTypeLabel.TAIWAN_GO]: CardType.TAIWAN_GO,
  [CardTypeLabel.GANGAO_GO]: CardType.GANGAO_GO,
  [CardTypeLabel.TAIBAO]: CardType.TAIBAO
};

// 证件类型
export const CardTypeTransforLabel = {
  0: CardType.IDCARD,
  2: CardType.PASSPORT,
  5: CardType.TAIWAN_GO,
  3: CardType.GANGAO_GO,
  4: CardType.TAIBAO
};

// 车辆信息
export const defaultBusInfo = () => ({
  car_id: '',
  car_comp: '',
  driver_name: '',
  driver_phone: ''
});

// 行程信息
export const defaultItineraryInfo = () => ({
  day_number: 1,
  site_num: 2,
  date: '',
  journey_desc: '',
  is_transit: TransitType.NO,
  title: '',
  city: '',
  province: ''
});

/**
 * 导游信息
 * 导游类型 1:全陪 2:地陪
 */
export const defaultGuideInfo = () => ({
  name: '',
  card_id: '',
  type: GuideType.LOCAL,
  is_leader: LeaderType.NO_LEADER,
  insurance: []
});
export const defaultForm = () => ({
  team_id: '',
  // 团队唯一编号
  time: 0,
  // 上传次数，0:表示新建团队，大于0:表示取消后的重传
  agency_license: '',
  // 旅游社许可证号
  agency_name: '',
  // 旅游社名称
  // agency_license: 'L-JS-CJ100055',
  // agency_name: '吐鲁番旅游股份有限公司',

  vehicle: VehicleType.PLANE,
  vehicle_in: VehicleType.PLANE,
  business_type: BusinessType.RECEPTION,
  team_type: TeamGroupType.OTHER,
  destination_province: '广西省',
  destination_city: '桂林',
  time_go: '12:20',
  time_back: '16:30',
  date_go: '',
  date_back: '',
  receive_company_name: '',
  itinerary_name: '',
  itinerary_day: '',
  people_nums: '',
  child_nums: '',
  journey_desc: '',
  itinerary_list: [],
  guides: [],
  bus_info: [],
  tourist_list: [],
  guide: defaultGuideInfo(),
  vehicle_out_nums: 'ZA6745',
  vehicle_in_nums: 'ZA6745',
  source_province: '上海',
  source_city: '上海市',
  receive_group_number: '',
  receive_company_id: '',
  fleet: '',
  plate_number: '',
  driver_name: '',
  driver_phone: '',
  driver_card_number: '',
  hotel_name: '',
  hotel_contact: '',
  hotel_phone: '',
  total_room_charge: '',
  unique_group_number: '',
  departure_county: ''
});
export const guideRules = {
  'guide.type': [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }],
  'guide.is_leader': [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }],
  'guide.name': [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  'guide.card_id': [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }]
};
export const rules = {
  team_id: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  itinerary_name: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  itinerary_day: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  agency_name: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  people_nums: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  child_nums: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  source_province: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  source_city: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  vehicle_in_nums: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  vehicle_out_nums: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  date_go: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  beginDate: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  time_go: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  date_back: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  time_back: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  receive_company_name: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  destination_province: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  destination_city: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  journey_desc: [{
    required: true,
    message: '必填项',
    trigger: 'blur'
  }],
  team_type: [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }],
  business_type: [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }],
  vehicle: [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }],
  vehicle_in: [{
    required: true,
    message: '必选项',
    trigger: 'change'
  }]
  // ...guideRules,
};

// 身份信息
export const defaultCardInfo = () => ({
  card_type: CardType.IDCARD,
  card_id: '312312312312313',
  issue_authority: '北京市XX',
  valid_from: '1990-10-10',
  valid_to: '1997-10-10'
});

// 游客信息
export const defaultTouristInfo = () => ({
  name: '周意义',
  py_family_name: 'Zhou',
  py_given_name: 'YiYi',
  date_of_birth: '1990-10-10',
  phone_num: '13809901010',
  card_list: [defaultCardInfo()],
  nationality: '北京',
  sex: 0,
  hr_county: '东城区',
  hr_city: '北京市',
  hr_province: '北京市',
  age: '29',
  is_child: 0
});

// 保险信息
export const defaultInsuranceInfo = () => ({
  company: '人寿保险',
  name: '刘燕',
  order_no: '2012812900cdhn',
  quota: '200000',
  currency: 'CNY'
});
export const commitForm = () => ({
  team_id: '2016060117357',
  time: 0,
  agency_license: 'L-HUN-01025',
  agency_name: '长沙市康泰国际旅行社有限公司',
  business_type: 2,
  team_type: 1,
  people_nums: 20,
  child_nums: 2,
  itinerary_day: 2,
  itinerary_name: '东京2日游',
  journey_desc: '东京第一天，第二天大阪回。',
  date_go: '2016-07-12',
  date_back: '2016-07-14',
  time_go: '17:25',
  time_back: '17:25',
  address_go: '虹桥火车站',
  address_back: '浦东机场',
  vehicle: 1,
  vehicle_in: 2,
  vehicle_out_nums: 'ZA6745',
  vehicle_in_nums: 'ZA6745',
  source_city: '邯郸市',
  source_province: '河北省',
  destination_city: '邯郸市',
  destination_province: '河北省',
  applicant_name: '李白',
  applicant_phone: '13100000000',
  bus_info: [{
    car_id: '京A4432N',
    car_comp: '首旅集团',
    driver_name: '王思源',
    driver_phone: '139000005678'
  }],
  guides: [defaultGuideInfo()],
  itinerary_list: [{
    day_number: 1,
    site_num: 2,
    date: '2017-03-04',
    journey_desc: '该站点行程描述',
    is_transit: 0,
    title: '本站点行程标题',
    city: '北海道',
    province: '北海道'
  }],
  tourist_list: [{
    name: '周意义',
    py_family_name: 'Zhou',
    py_given_name: 'YiYi',
    date_of_birth: '1990-10-10',
    phone_num: '13809901010',
    card_list: [{
      card_type: 1,
      card_id: '312312312312313',
      issue_authority: '北京市XX',
      valid_from: '1990-10-10',
      valid_to: '1997-10-10'
    }, {
      card_type: 2,
      card_id: 'SGC8hik',
      issue_authority: '北京市XX',
      valid_from: '1990-10-10',
      valid_to: '1997-10-10'
    }],
    nationality: '北京',
    sex: 0,
    hr_county: '东城区',
    hr_city: '北京市',
    hr_province: '北京市',
    age: '29',
    is_child: 0
  }],
  receive_group_number: '',
  receive_company_id: '',
  receive_company_name: '',
  fleet: '',
  plate_number: '',
  driver_name: '',
  driver_phone: '',
  driver_card_number: '',
  hotel_name: '',
  hotel_contact: '',
  hotel_phone: '',
  total_room_charge: '',
  unique_group_number: '',
  departure_county: ''
});

// 复姓
export const DoubleNameKeys = ['欧阳', '太史', '端木', '上官', '司马', '东方', '独孤', '南宫', '万俟', '闻人', '夏侯', '诸葛', '尉迟', '公羊', '赫连', '澹台', '皇甫', '宗政', '濮阳', '公冶', '太叔', '申屠', '公孙', '慕容', '仲孙', '钟离', '长孙', '宇文', '司徒', '鲜于', '司空', '闾丘', '子车', '亓官', '司寇', '巫马', '公西', '颛孙', '壤驷', '公良', '漆雕', '乐正', '宰父', '谷梁', '拓跋', '夹谷', '轩辕', '令狐', '段干', '百里', '呼延', '东郭', '南门', '羊舌', '微生', '公户', '公玉', '公仪', '梁丘',
// '公仲内',
// '公上容',
'公门', '公山', '公坚', '左丘', '公伯', '西门', '公祖', '第五', '公乘', '贯丘', '公皙', '南荣', '东里', '东宫', '仲长', '子书', '子桑', '即墨', '达奚', '褚师'];