const width60 = 60;
const width80 = 80;
const width90 = 90;
const width100 = 100;

/***************** 对账 ******************/
// 订单信息
const orderInfo = {
  attrs: {
    prop: 'planNumber',
    label: '订单信息',
    'min-width': 160
  },
  slot: 'planNumber'
};

// 行程日期
const tripDate = {
  attrs: {
    prop: 'date',
    label: '行程日期',
    'min-width': width90
  },
  slot: 'tripDate'
};

// 订单日期
const orderDate = {
  attrs: {
    prop: 'orderDate',
    label: '订单日期',
    'min-width': width90
  }
};

// 订单日期
const orderDateSlot = {
  ...orderDate,
  slot: 'orderDate'
};

// 导游
// const orderGuide = { attrs: { prop: 'guide', label: '报账导游', 'min-width': width50 } }

// 报账导游
const orderGuideBill = {
  attrs: {
    prop: 'guideName',
    label: '报账导游',
    'min-width': width80
  }
};

// 游客联系人
const orderTouristContact = {
  attrs: {
    prop: 'touristContact',
    label: '游客联系人',
    'min-width': 140
  },
  slot: 'touristContact'
};

// 操作计调
const orderOpOperation = {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调',
    'min-width': width60
  }
};

// 客服计调
const orderOpService = {
  attrs: {
    prop: 'outOPUserName',
    label: '客服计调'
  }
};

// 业务员
const saleUserName = {
  attrs: {
    prop: 'saleUserName',
    label: '业务员'
  }
};

// 未收
const orderUnCollect = {
  attrs: {
    prop: 'unTotalIncome',
    label: '未收'
  }
};
const orderUnCollectSlot = {
  ...orderUnCollect
};

// 对账金额
const orderCheckMoney = {
  attrs: {
    prop: 'checkAccountMoney',
    label: '对账金额',
    'min-width': width80
  },
  slot: 'checkAccountMoney'
};

// 对账时间
const orderCheckDate = {
  attrs: {
    prop: 'checkAccountTime',
    label: '对账时间',
    'min-width': 140
  },
  slot: 'checkAccountTime'
};

// 对账人
const orderChecker = {
  attrs: {
    prop: 'checkAccountUserName',
    label: '对账人'
  }
};

// 费用明细
const orderBillDetail = {
  attrs: {
    prop: 'fee',
    label: '费用明细',
    'min-width': 180
  },
  slot: 'fee'
};

// 备注
const orderRemark = {
  attrs: {
    prop: 'checkAccountRemark',
    label: '备注'
  },
  slot: 'remark'
};

// 审核
const auditStatus = {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    'min-width': width80
  },
  slot: 'auditStatus'
};

// 操作
const actionItem = {
  attrs: {
    prop: 'action',
    label: '操作',
    'min-width': '120',
    fixed: 'right'
  },
  slot: 'action',
  header: 'billCheck'
};

// 应收对账
export const Columns = [orderInfo, tripDate, orderDateSlot, {
  attrs: {
    prop: 'customerName',
    label: '客户'
  },
  slot: 'customerName'
}, orderGuideBill, {
  attrs: {
    prop: 'contact',
    label: '联系人',
    'min-width': 120
  },
  slot: 'contact'
}, orderTouristContact, {
  attrs: {
    prop: 'peopleCount',
    label: '人数',
    'min-width': width100
  },
  slot: 'peopleCount'
}, orderBillDetail, {
  attrs: {
    prop: 'totalIncome',
    label: '应收'
  }
}, {
  attrs: {
    prop: '',
    label: '已收'
  },
  children: [{
    attrs: {
      prop: 'totalIncomed',
      label: '社收'
    }
  }, {
    attrs: {
      prop: 'totalCollect',
      label: '代收'
    },
    slot: 'totalCollect'
  }, {
    attrs: {
      prop: 'totalSubsidy',
      label: '返款'
    }
  }]
}, orderUnCollectSlot, orderCheckMoney, orderCheckDate, orderChecker, orderRemark, orderOpService, saleUserName, orderOpOperation, auditStatus, actionItem];