import { inputMixin } from '@/mixin';
import { isChineseCharacter } from '@/utils';
import * as loadMore from '@/directive/loadMore';
/**
 * @module components/base/Input
 * @desc 带下拉数据输入框组件
 * @vue-prop {String} label - 输入框对应的名称
 * @vue-prop {Boolean} [vertical=false] - 是否垂直布局
 * @vue-prop {Boolean} [isRequire=false] - 是否在label前面加 * 标记
 * @vue-prop {Array} list - 输入框下拉数据列表
 */
export default {
  name: 'InputData',
  directives: {
    loadMore
  },
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      // 所有数据
      type: Array,
      default: () => []
    }
  },
  methods: {
    querySearch(queryString, cb) {
      const data = this.data;
      const results = queryString ? data.filter(this.createFilter(queryString)) : data;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      const isChiness = isChineseCharacter(queryString);
      return item => {
        if (!isChiness) {
          return item.slug.indexOf(queryString.toLowerCase()) !== -1;
        } else {
          return item.label.indexOf(queryString) !== -1;
        }
      };
    },
    handleSelect(item) {
      this.$emit('on-select', item);
      // this.$emit('on-label', item.label)
    },
    handleClear() {
      this.$emit('on-clear');
    },
    loadMore() {
      this.$emit('on-load');
    }
  }
};