import { InputDepartment, SelectProduct } from '@/components/common';
import { mapGetters } from 'vuex';
import { departKeys } from '@/config/dictionary';
import { BILL_PAGE_COUNT } from '@/config';
import { getCurTagView } from '@/utils/tagView';
export default {
  components: {
    InputDepartment,
    SelectProduct
  },
  data() {
    return {
      searchParams: {
        ...BILL_PAGE_COUNT
      },
      department: '',
      form: {},
      sendDate: [],
      endDate: [],
      orderDate: [],
      billDate: []
    };
  },
  computed: {
    ...mapGetters({
      baseInput: 'bill/billDown/baseInput'
    }),
    getTagViewPage() {
      const curTag = getCurTagView();
      return curTag ? curTag.search : {};
    }
  },
  methods: {
    changeStarTripDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeEndTripDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.searchParams.endTripDateS = starDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeOrderDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.searchParams.startTripDateS = starDate;
      this.searchParams.startTripDateE = endDate;
    },
    changeCheckDate(val) {
      val = this.setDefVal(val);
      const [starDate, endDate] = val;
      this.searchParams.checkAccountTimeS = starDate;
      this.searchParams.checkAccountTimeE = endDate;
    },
    setDefVal(val) {
      val = !val ? ['', ''] : val;
      return val;
    },
    selectProduct(val) {
      this.searchParams.productName = val.name;
      this.searchParams.productId = val.value;
    },
    changeDepartment(val) {
      departKeys.forEach(key => {
        this.searchParams[key] = val[key];
      });
    },
    handleBillCheck() {
      this.$refs.tableRef.handleBillCheck();
    },
    closeTag() {
      this.closeSelectedTag();
    },
    // 搜索
    handleSearch() {
      // this.deleteKey()
      // const { resourceId } = this.baseInput
      // if (!resourceId) {
      //   this.$bus.tip({ type: 'warning', message: '请选择直营单位' })
      //   return
      // }
      // this.searchParams.resourceId = resourceId
      // const { path } = this.$route
      // const search = this.searchParams

      // this.$store.dispatch('tagsView/appendVisiteViewSearch', {
      //   path,
      //   search,
      // })

      // // 搜索的时候重置分页
      // this.$store.dispatch('tagsView/appendVisiteViewPage', {
      //   path,
      //   page: 1,
      //   size: search.pageSize,
      // })

      // this.$parent.fetchUnpayData({
      //   ...this.searchParams,
      // })

      this.$emit('search', this.searchParams);
    },
    handleAutoDown() {
      this.$parent.handleAutoDown();
    },
    deleteKey() {
      const keys = ['startTripDate', 'endPlanDate', 'orderDate', 'checkAccountTime'];
      keys.forEach(key => {
        delete this.searchParams[key];
      });
    }
  }
};