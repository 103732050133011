var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "welcome"
  }, [_c("div", {
    staticClass: "welcome-text"
  }, [_c("h1", [_vm._v("欢迎！")]), _c("p", [_vm._v("旅行社管理系统 -- " + _vm._s(_vm.generateTitle(_vm.$route.name)))])]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/welcome.png"),
      alt: "travel"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };