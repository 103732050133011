import { SelectDictionary } from '@/components/common';
import { orderFleetInfo } from '@/config/resourceOrder';
import { pickTimeRadios, sendTimeRadios, pickRadios, sendRadios } from '@/config/radios';
import { getNextDate } from '@/utils';
export default {
  components: {
    SelectDictionary
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pickRadios,
      sendRadios,
      pickTimeRadios,
      sendTimeRadios,
      columnData: orderFleetInfo,
      tableData: []
    };
  },
  computed: {
    getData() {
      return this.data ? [this.data] : [];
    }
  },
  methods: {
    changeStartDate(row) {
      const {
        startDate,
        endDate
      } = row;
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        row.endDate = getNextDate(startDate);
      }
    },
    changeEndDate(row) {
      const {
        startDate,
        endDate
      } = row;
      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        row.endDate = getNextDate(startDate);
      }
    },
    selectBusType(row, val) {
      row.busType = val.label;
    },
    handleCommit() {
      return this.$store.dispatch('order/resource/bookingFleetSet', {
        planId: -1,
        ...this.getData[0]
      });
    }
  }
};