import { downloadExport } from '@/utils/exportExcel';
import { SelectGuide, BtnExport, SelectCustom } from '@/components/common';
import { otherIncome } from '@/config/report';
import { searchMixin, summaryMixin } from '@/mixin';

// import { parseDate } from '@/utils'
// const today = parseDate(new Date(), '{y}-{m}-{d}')
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectGuide,
    BtnExport,
    SelectCustom
  },
  mixins: [searchMixin, summaryMixin],
  data: () => ({
    tableLoading: false,
    columnData: otherIncome,
    tableData: [],
    // sendDate: [today, ''],
    // endDate: [],
    total: 0,
    statistics: 0,
    searchParams: {},
    statisticsKeys: ['totalIncome'],
    isSticky: true
  }),
  computed: {
    ...mapGetters({
      otherIncomeTotal: 'report/index/otherIncomeTotal'
    })
  },
  watch: {
    otherIncomeTotal: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData(this.searchParams);
      } else {
        this.handleData(data);
      }
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
    },
    onSelectGuide(val) {
      const {
        value,
        label
      } = val;
      this.searchParams.guideId = value;
      this.searchParams.guideLabel = label;
    },
    // 选择客户
    selectCustom(val) {
      this.searchParams.customerLabel = val.label;
      this.searchParams.customerId = val.value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.getTagViewSearch
      });
    },
    handleData(data) {
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.statistics = statistics;
      this.tableData = list;
    },
    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getOtherIncomeSummary', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchOtherIncomeTotaExcel', this.searchParams).then(res => {
        const fileName = '其他收入汇总表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};