var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper"
  }, [_c("FormBase", [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "产品名称",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.name,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  }), _c("InputBase", {
    attrs: {
      label: "创建人",
      clearable: ""
    },
    model: {
      value: _vm.searchParams.createUserName,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "createUserName", $$v);
      },
      expression: "searchParams.createUserName"
    }
  }), _c("SelectBase", {
    attrs: {
      "init-val": _vm.searchParams.displayType,
      label: "设置可见",
      type: "visibleProduct"
    },
    model: {
      value: _vm.searchParams.displayType,
      callback: function ($$v) {
        _vm.$set(_vm.searchParams, "displayType", $$v);
      },
      expression: "searchParams.displayType"
    }
  }), _c("BtnCreate", {
    attrs: {
      "create-text": "添加产品"
    },
    on: {
      "on-search": _vm.handleSearch,
      "on-create": _vm.handleCreate
    }
  })], 1)]), _c("DialogCustom", {
    ref: "customRef"
  }), _c("DialogProduct", {
    ref: "productRef"
  }), _c("DialogLog", {
    ref: "logRef"
  }), _c("TableList", {
    attrs: {
      "table-data": _vm.tableData,
      "column-data": _vm.columnData
    }
  }), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total
    },
    on: {
      pagination: _vm.handlePage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };