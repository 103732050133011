import service from '@/utils/request';

// 订单短信
export function orderMessageRequest(data) {
  return service({
    url: '/system/sms/guide-plan',
    method: 'POST',
    data
  });
}

// 导游单团报账 确认账单短信
export function confirmMessageRequest(data) {
  return service({
    url: '/system/sms/guide-plan-confirm',
    method: 'POST',
    data
  });
}

// 导游单团报账 再次发送确认账单短信
export function confirm2MessageRequest(data) {
  return service({
    url: '/system/sms/guide-plan-confirm-again',
    method: 'POST',
    data
  });
}