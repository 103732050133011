var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "turnover-box flex"
  }, [_c("div", {
    staticClass: "progress-box"
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "progress-item"
    }, [_c("h6", [_vm._v(_vm._s(index + 1) + "、" + _vm._s(item.name))]), _c("el-progress", {
      attrs: {
        "text-inside": true,
        "stroke-width": 20,
        percentage: Number(item.complet)
      }
    }), _c("span", {
      staticClass: "num"
    }, [_vm._v(_vm._s(item.value) + _vm._s(_vm.unit))])], 1);
  }), 0), _c("div", {
    style: {
      width: "50%",
      height: "500px"
    },
    attrs: {
      id: _vm.id
    }
  })]), _c("div", {
    staticClass: "fot-title"
  }, [_c("h2", [_vm._v(_vm._s(_vm.title) + "排名")]), _c("h2", [_vm._v(_vm._s(_vm.title) + "占比")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };