import { statisticsTeamKeys } from '@/config/report';
export const ClassKeys1 = ['adultCount', 'childCount', 'accompanyCount'];
export const ClassKeys2 = ['totalCustomerIncome', 'totalOutTransferSubsidyIncome', 'totalOtherIncome', 'totalShopIncome', 'totalScenicExtraIncome', 'totalGuideManageFeeIncome'];
export const ClassKeys3 = ['totalCustomerSubsidyPay', 'totalBusCompanyPay', 'totalHotelPay', 'totalHotelQuotePay', 'totalGuideServiceFeePay', 'totalInsurancePay', 'totalOutTransferPay', 'totalShuttlePay', 'totalTransportCompanyPay', 'totalOtherPay', 'totalShopRebatePay', 'totalScenicExtraRebatePay', 'totalScenicInnerQuotePay', 'totalScenicExtraQuotePay', 'totalRestaurantPay'];
export const ClassKeys4 = ['totalScenicInnerPay', 'totalScenicExtraPay', 'totalIncome', 'totalIncomed', 'totalQuotePay', 'totalPay', 'totalPayed', 'totalGrossProfit', 'totalQuoteGrossMargin', 'shopEndSubsidy', 'customerShopSubsidy', 'subsidyQuoteGrossProfit', 'totalGrossMargin', 'subsidyQuoteGrossMargin'];
export const StatisticsKeys = [...statisticsTeamKeys, 'adultCount', 'shopEndSubsidy', 'customerShopSubsidy', 'totalShopRebatePay', 'totalIncome', 'totalGrossMargin', 'totalGrossProfit', 'subsidyGrossProfit', 'subsidyGrossMargin'];
export const AvgeageObj = {
  totalGrossMargin: ['totalGrossProfit', 'adultCount'],
  // 人均毛利
  subsidyGrossMargin: ['subsidyGrossProfit', 'adultCount'] // 最终人均
};