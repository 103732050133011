var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    attrs: {
      title: "更新客户",
      width: "700px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.form,
      "label-width": "100px",
      inline: ""
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "业务员"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.saleUserId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "saleUserId", $$v);
      },
      expression: "form.saleUserId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "客服计调"
    }
  }, [_c("SelectStaff", {
    attrs: {
      label: ""
    },
    on: {
      "on-select": _vm.selectService
    },
    model: {
      value: _vm.form.customerAreaService,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerAreaService", $$v);
      },
      expression: "form.customerAreaService"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };