import "core-js/modules/es.array.reduce.js";
// 只统计人数
export default {
  data() {
    return {
      // 默认统计到字段
      statisticsKeys: ['count', 'number'],
      sumTxt: '小计'
    };
  },
  methods: {
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.sumTxt;
          return;
        }
        const values = data.map(item => {
          if (!data.length) return 0;
          if (this.statisticsKeys.includes(column.property)) {
            if (column.property === 'number' || column.property === 'count') {
              const {
                adultCount,
                childCount,
                accompanyCount
              } = item;
              let res = adultCount + '/' + childCount + '/' + (accompanyCount || 0);
              return res;
            } else if (column.property === 'roomInfo') {
              const {
                hotelStandardCount,
                hotelBigCount,
                hotelThreeCount,
                hotelChildCount
              } = item;
              return `${hotelStandardCount}/${hotelBigCount}/${hotelThreeCount}/${hotelChildCount}`;
            } else {
              return [item[column.property], 0, 0].join('/');
            }
          }
        });
        if (values.every(value => value != undefined)) {
          const res = values.reduce((prev, cur) => {
            const [val1, val2, val3, val4] = cur.split('/');
            prev[0] += +val1;
            prev[1] += +val2;
            prev[2] += +val3;
            prev[3] += +val4;
            return prev;
          }, [0, 0, 0, 0]);
          if (column.property === 'number' || column.property === 'count') {
            sums[index] = `${res[0]}大${res[1]}小${res[2]}陪`;
          } else if (column.property === 'roomInfo') {
            sums[index] = `${res[0]}标${res[1]}大${res[2]}三${res[3]}亲`;
          } else {
            sums[index] = Math.round(res[0]);
          }
        }
      });
      return sums;
    }
  }
};