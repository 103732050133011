import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { blackList } from '@/config/order';
import DialogBlackTouristItem from './DialogBlackTouristItem';
import DialogBlackTouristAll from './DialogBlackTouristAll';
import { decimalSplit } from '@/utils';
export default {
  components: {
    DialogBlackTouristItem,
    DialogBlackTouristAll
  },
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: blackList,
      touristList: [],
      touristAllList: []
    };
  },
  computed: {
    getFomatData() {
      return (item, type) => {
        if (!item) return '';
        item = item.split(',');
        let arr = item;
        // 如果是景区的则需要去重
        if (type == 'scenic') {
          arr = Array.from(new Set(item));
          if (arr.length > 3) {
            arr = arr.slice(0, 2);
          }
        }
        // 换行
        arr = arr.join('<br/>');
        return arr;
      };
    },
    groupRate() {
      return (list, item) => {
        const rate = list.length / item.length;
        return decimalSplit(rate * 100);
      };
    }
  },
  mounted() {},
  methods: {
    show(list, touristList) {
      this.touristList = touristList.filter(it => it.age >= 18);
      list = list.filter(it => it.times);
      this.touristAllList = list;
      this.tableData = this.formData(list);
      this.visible = true;
    },
    formData(list) {
      if (!list.length) return [];
      let map = {};
      let newArr = [];
      list.forEach(it => {
        it.isCommon = false;
        const item = {
          addr: it.addr,
          cardNumber: it.cardNumber,
          consume: it.consume,
          desProvince: it.desProvince,
          endTripDate: it.endTripDate,
          id: it.id,
          mobilePhone: it.mobilePhone,
          name: it.name,
          scenic: it.scenic,
          shopMoney: it.shopMoney,
          shops: it.shops,
          startTripDate: it.startTripDate,
          times: it.times,
          product: it.product,
          remarks: it.remarks
        };
        if (!map[it.cardNumber]) {
          newArr.push(it);
          it.list = [item];
          map[it.cardNumber] = it;
        } else {
          newArr.forEach(v => {
            if (v.cardNumber == it.cardNumber) {
              v.times = it.times + v.times;
              v.list.push(item);
            }
          });
        }
      });
      return newArr;
    },
    hide() {
      this.visible = false;
      this.restore();
    },
    onTourist(row) {
      this.$refs.TouristItemRefs.show(row);
    },
    onCheckAll() {
      this.$refs.TouristAllRefs.show(this.touristAllList);
    },
    tableRowClassName({
      row
    }) {
      // && row.times < 10
      if (row.isCommon && row.times === 1) {
        return 'warning-row';
      }
      if (row.isCommon && row.times > 1 && row.times <= 10) {
        return 'danger-row';
      }
      if (row.isCommon && row.times > 10) {
        return 'info-row';
      }
      return 'success-row';
    },
    restore() {
      this.tableData = [];
      this.visible = false;
    }
  }
};