import Receipt from './Receipt.vue';
export default {
  components: {
    Receipt
  },
  data() {
    return {
      data: {}
    };
  },
  computed: {
    getHotelVal() {
      return val => val ? val.split('|')[0] : '';
    }
  },
  created() {
    this.$store.dispatch('order/receive/outOrderDetail', this.$route.query).then(data => {
      this.data = data;
    });
  },
  methods: {
    handleConfirm() {
      this.$bus.open(() => {
        this.$store.dispatch('order/receive/confirm', this.id);
      }, '是否确认接收该订单');
    },
    handleRefuse() {
      this.$bus.open(() => {
        this.$store.dispatch('order/receive/refuse', this.id);
      }, '是否确认拒绝该订单');
    }
  }
};