import { formatCity, processCityChange } from '@/utils';
export default {
  name: 'SelectRegion',
  inheritAttrs: false,
  data() {
    const cityData = formatCity();
    return {
      list: cityData
    };
  },
  methods: {
    handleChange(val) {
      const res = processCityChange(val);
      this.$emit('on-select', res);
    }
  }
};