import { PAY_NET_TYPE, PAY_ERR_STATUS } from '@/config/payType';

// 查询付款状态
export const checkPayStatus = (store, params) => {
  return store.dispatch('system/payment/aliPayStatus', params);
};

// 通知付款状态
export const notifyPayStatus = (store, params, isOk = true) => {
  const opts = {
    ...params,
    status: isOk ? PAY_ERR_STATUS.SUCCESS : PAY_ERR_STATUS.FAIL,
    payType: PAY_NET_TYPE.ALIPAY
  };
  return store.dispatch('system/payment/notiflyStatus', opts);
};