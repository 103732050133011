import BaseInfo from '../components/BaseInfo.vue';
// import FormItem from '../components/FormItem.vue'
import DialogForm from '../components/DialogForm.vue';
import { FooterBar, BillInput } from '@/components/common';
import { RecordColumn as columns } from '@/config/billDown/payRegiment';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { TYPES, CashFeeType } from '@/config/billDown';
const infoList = [{
  label: '大团号',
  prop: 'planNumber'
}, {
  label: '类型',
  prop: 'insuranceType'
}, {
  label: '价格',
  prop: 'price'
}, {
  label: '保险',
  prop: 'insuranceName'
}, {
  label: '数量',
  prop: 'adultCount'
}, {
  label: '订单日期',
  prop: 'orderDate'
}, {
  label: '流水号',
  prop: 'orderNumber'
}, {
  label: '应付',
  prop: 'totalPay',
  cls: 'red'
}, {
  label: '导游现付',
  prop: 'guidePayCount',
  cls: 'red'
}, {
  label: '社付',
  prop: 'totalPayed',
  cls: 'red'
}, {
  label: '欠付',
  prop: 'unPay',
  cls: 'red'
}];
export default {
  components: {
    BaseInfo,
    FooterBar,
    BillInput,
    DialogForm
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      TYPES,
      infoList,
      columns,
      tableData: [],
      baseInfo: null,
      orderId: '',
      total: 0,
      resourceId: ''
    };
  },
  computed: {
    getBaseInfo() {
      return this.baseInfo;
    }
  },
  created() {
    const {
      id: orderId,
      rid
    } = this.$route.query;
    this.resourceId = rid;
    this.orderId = orderId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchItem();
      this.fetchPayed();
    },
    fetchItem() {
      this.$store.dispatch('bill/billDown/payRegimentInsurance/fetchOne', {
        prId: this.orderId
      }).then(data => {
        if (!data) return;
        this.setBaseInfo(data.list);
      });
    },
    // 付款记录列表
    fetchPayed(params = {}) {
      return this.$store.dispatch('bill/billDown/pay/fetchPayed', {
        orderId: this.orderId,
        cashType: TYPES.insurance,
        ...params
      }).then(data => {
        if (!data) return;
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    },
    // fetchBalance() {
    //   return this.$store.dispatch('bill/billDown/pay/fetchPayBalance', {
    //     rid: this.resourceId,
    //     cashType: TYPES.insurance,
    //   })
    // },

    setBaseInfo(list) {
      this.baseInfo = list.find(it => it.rowId == this.orderId);
    },
    selectResource(val) {
      const {
        id: resourceId
      } = val;
      this.resourceId = resourceId;
    },
    // 修改
    editPayed({
      id,
      cashLogId
    }) {
      const {
        insuranceName,
        unPay
      } = this.getBaseInfo;
      this.$store.dispatch('bill/billDown/pay/fetchDetail', cashLogId).then(data => {
        if (!data) return;
        this.$refs.dialogRef.show({
          updateId: id,
          unPay,
          ...data,
          resourceName: insuranceName
        });
      });
    },
    // 删除
    handleDelete({
      id
    }) {
      this.$bus.open(() => {
        const opts = {
          cashType: TYPES.insurance,
          ids: [id]
        };
        this.$store.dispatch('bill/billDown/pay/deletePay', opts).then(() => {
          this.fetchPayed();
        });
      });
    },
    // 关闭
    handleClose() {
      // mixin 方法
      this.closeSelectedTag();
    },
    formatData(data) {
      const {
        rowId: orderId,
        insuranceId,
        insuranceName
      } = this.baseInfo;
      const {
        cashWay,
        cashUserId,
        money,
        updateId
      } = data;
      const cashItem = {
        cash: money,
        cashLogId: 0,
        id: updateId || '',
        cashUserId,
        cashWay,
        orderId
      };
      const opts = {
        ...data,
        money,
        resourceId: insuranceId,
        cashAction: CashFeeType.PAY,
        cashType: TYPES.insurance,
        resourceName: insuranceName,
        cashes: [cashItem]
      };
      return opts;
    },
    // 保存
    handleSave() {
      const formItem = this.$refs.formItem;
      formItem.validate().then(valid => {
        if (!valid) return;
        const opts = this.formatData(formItem.form);
        this.$store.dispatch('bill/billDown/pay/createPay', opts).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      });
    },
    // 保存
    handleUpdate(data) {
      // const formItem = this.$refs.formItem
      const opts = this.formatData(data);
      this.$store.dispatch('bill/billDown/pay/updatePayed', opts).then(() => {
        this.$bus.tip();
        this.fetchData();
      });
    }
  }
};