// 客户类型

export const CustomerType = {
  COMMON: 'COMMON',
  // 组团
  OUT: 'OUT',
  //  地接
  COMBINATION: 'COMBINATION',
  // 组合
  CC: 'CC',
  // 组团+地接
  CO: 'CO' // 地接+组团
};
export const CustomerTypeLabel = {
  COMMON: '组团',
  // 组团
  OUT: '地接',
  //  地接
  COMBINATION: '地接+组团' // 组合
};