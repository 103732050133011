var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "dialog-bill-cashlog",
    attrs: {
      title: "修改现金日志",
      width: "1200px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCommit
    }
  }, [_c("BillInput", {
    ref: "billInputRef",
    attrs: {
      obj: _vm.form
    },
    on: {
      save: _vm.handleSave
    }
  }, [_c("ResourceItem", {
    attrs: {
      form: _vm.form
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };