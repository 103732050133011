var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    staticClass: "analysis-home"
  }, _vm._l(_vm.menus, function (item, index) {
    return _c("el-form-item", {
      key: index
    }, [_c("el-divider", {
      attrs: {
        "content-position": "left"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", _vm._l(item.children, function (it, i) {
      return _c("el-button", {
        key: i,
        attrs: {
          type: it.isRole ? "primary" : "info",
          disabled: !item.isRole
        },
        on: {
          click: function ($event) {
            return _vm.onClickRouter(it);
          }
        }
      }, [_vm._v(" " + _vm._s(it.name) + " ")]);
    }), 1)], 1);
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };