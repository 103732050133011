import "core-js/modules/es.array.push.js";
import BaseInput from '../components/BaseInput2';
import TableUnPay from './TableUnPay';
import TablePayed from './TablePayed';
import Search from './Search.vue';
import { downBillGuideUnPay, downBillGuidePayed } from '@/config/billDown';
import { FooterBar, SelectGuide } from '@/components/common';
import { tagViewMixin, reloadMixin } from '@/mixin';
import { parseTime } from '@/utils';
import { processMinus } from '@/utils/number';
import { DEFAULT_PAGE } from '@/config';
import { initPageSize } from '@/utils/tagView';
export default {
  components: {
    FooterBar,
    SelectGuide,
    BaseInput,
    TableUnPay,
    TablePayed,
    Search
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      search: {},
      exportLoading: false,
      unPayData: [],
      payedData: [],
      columnData: downBillGuideUnPay,
      payedColumnData: downBillGuidePayed,
      total: 0,
      totalPayed: 0,
      guideId: '',
      backPath: '/bill/guide-batch',
      pageUnPay: DEFAULT_PAGE,
      pagePayed: DEFAULT_PAGE,
      cashLogId: '',
      isUpdate: false,
      statistics: {}
    };
  },
  computed: {
    // statistics() {
    //   return this.$store.getters['bill/guide/statistics']
    // },
    getUnpay() {
      return processMinus(this.statistics.totalPay, this.statistics.totalPayed);
    }
  },
  mounted() {
    const {
      guideId,
      id
    } = this.$route.query;
    this.cashLogId = id;
    this.guideId = guideId;
    this.isUpdate = guideId != '' && guideId > 0;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchUnpayData();
      this.fetchPayedData();
    },
    fetchList() {
      this.$store.dispatch('bill/guide/fetchGuideReimbBatch');
    },
    fetchUnpayData(queryParams = {}) {
      if (!this.guideId) return;
      queryParams = {
        ...this.search,
        ...queryParams,
        guideId: this.guideId,
        cashLogId: this.cashLogId
      };
      return this.$store.dispatch('bill/guide/fetchGuideReimbBatchUnPay', queryParams).then(data => {
        if (!data) return;
        const {
          list,
          totalCount,
          statistics
        } = data;
        this.unPayData = list;
        this.total = totalCount;
        this.statistics = statistics;
      });
    },
    fetchPayedData(queryParams) {
      if (!this.cashLogId) return;
      queryParams = {
        ...this.search,
        ...queryParams,
        guideId: this.guideId,
        cashLogId: this.cashLogId
      };
      this.$store.dispatch('bill/guide/fetchGuideReimbBatchPayed', queryParams).then(data => {
        if (!data) return;
        this.totalPayed = data.totalCount;
        this.payedData = data.list.map(it => {
          it.money = it.cash;
          it.cashDate = parseTime(it.cashDate);
          it.isChecked = false;
          return it;
        });
      });
    },
    changeGuide() {
      this.fetchUnpayData();
    },
    autoBillDown() {
      this.$store.commit('bill/guide/TOGGLE_AUTO_DOWN');
    },
    handleSearch(params) {
      this.search = params;
      initPageSize();
      this.fetchUnpayData();
    },
    // 分页--未结清
    handleUnPayPage({
      pageNum,
      pageSize
    }) {
      this.$refs.unPayTable.changeCheckAll(false);
      this.fetchUnpayData({
        pageNum,
        pageSize
      });
    },
    // 分页--已结清
    handlePayedPage({
      pageNum,
      pageSize
    }) {
      this.$refs.payedTable.changeCheckAll(false);
      // this.pagePayed.pageNum = val
      this.fetchPayedData({
        pageNum,
        pageSize
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 保存
    handleSave() {
      const hasSelect = this.unPayData.some(it => it.isChecked == true);
      if (!hasSelect) {
        this.$bus.tip({
          type: 'warning',
          message: '未选中任何下账数据!'
        });
        return;
      }
      this.$refs.formRef.handleValidation().then(() => {
        const path = this.isUpdate ? 'bill/guide/updatePayGuide' : 'bill/guide/createPayGuide';
        this.$store.dispatch(path).then(() => {
          this.$bus.tip();
          this.fetchData();
          this.handleClose();
          this.goBack();
        });
      });
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath
      });
    }
  }
};