import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import { SelectGuide, SelectProduct, SelectCustom, SelectStaff, BtnExport, SelectDictionary } from '@/components/common';
import { TableList } from './components';
import { orderAuditStatus } from '@/config/report';
import { searchMixin } from '@/mixin';
import { parseDate } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectProduct,
    SelectGuide,
    SelectCustom,
    SelectStaff,
    TableList,
    BtnExport,
    SelectDictionary
  },
  mixins: [searchMixin],
  data: () => ({
    orderAuditStatusList: orderAuditStatus,
    tableData: [],
    total: 0,
    searchParams: {
      auditStatus: '',
      customerTripNumber: '',
      dutyOPUserId: '',
      outOPUserId: '',
      planNumber: '',
      // 大团号
      productName: '',
      // 产品名称
      startTripDateS: parseDate(),
      // 发团开始日期
      startTripDateE: '',
      // 发团结束日期
      endTripDateS: '',
      // 散团开始日期
      endTripDateE: '',
      // 散团结束日期
      guideName: '',
      // 导游姓名
      customerId: '' // 客户搜索
    },
    statisticData: [],
    statistic: {}
  }),
  computed: {
    ...mapGetters({
      teamProfit2: 'report/index/teamProfit2'
    })
  },
  watch: {
    teamProfit2: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    initData(data) {
      if (!data) {
        this.fetchData();
      } else {
        this.handleData(data);
      }
    },
    // 选择散、发团日期
    onChangStartTripDate(type, date) {
      if (type == 'endTripDate') {
        this.searchParams.endTripDateS = date[0];
        this.searchParams.endTripDateE = date[1];
      }
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
    },
    // 选择产品
    selectProduct(val) {
      this.searchParams.productName = val.name;
      this.searchParams.productId = val.value;
    },
    selecthotelRegion({
      label
    }) {
      this.searchParams.customerArea = label;
    },
    onSelect(type, val) {
      const {
        value,
        label
      } = val;
      this.searchParams[type + 'Label'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    handleData(data) {
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.tableData = list;
      this.total = totalCount;
      this.statistic = statistics;
      this.statisticData.push(statistics);
    },
    fetchData(params) {
      this.tableLoading = true;
      this.$store.dispatch('report/index/getTeamProfit2', {
        ...this.searchParams,
        ...params
      }).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    // 导出单团利润表
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('report/index/fetchProfitSettlementExcel', this.searchParams).then(res => {
        const fileName = '单团利润结算报表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};