import { TripInfo, PersonCount, Contact, Custom, Shuttle, FooterBar } from '@/components/common';
import { planEditFleetDetail } from '@/config/plan';
import { isNumber } from '@/utils';
import { rowspanMixin } from '@/mixin';
export default {
  components: {
    TripInfo,
    PersonCount,
    Contact,
    Custom,
    Shuttle,
    FooterBar
  },
  mixins: [rowspanMixin],
  data() {
    return {
      visible: false,
      tableData: [],
      columnData: planEditFleetDetail,
      planId: '',
      fleetId: '',
      useType: ''
    };
  },
  mounted() {
    this.$bus.on('fleet:list', this.show);
    this.$bus.on('fleet:reload', this.reloadData);
  },
  methods: {
    handleOrderDialog() {
      this.$bus.emit('fleet:order', {
        planId: this.planId,
        busCompanyId: this.fleetId,
        useType: this.useType
      });
    },
    show(params) {
      this.visible = true;
      const {
        currentPlanId: planId,
        id: fleetId,
        useType
      } = params;
      this.planId = planId;
      this.fleetId = fleetId;
      this.useType = useType;
      this.fetchData(planId, fleetId);
    },
    hide() {
      this.visible = false;
    },
    fetchData(planId, fleetId) {
      this.$store.dispatch('plan/fleet/fetchOrder', {
        planId,
        busCompanyId: fleetId
      }).then(data => {
        const {
          list
        } = data;
        this.tableData = list;
      });
    },
    reloadData() {
      this.fetchData(this.planId, this.fleetId);
    },
    // 移除
    handleRemove(id) {
      this.$store.dispatch('plan/fleet/deleteOrder', id).then(() => {
        this.fetchData(this.planId, this.fleetId);
      });
    },
    // 修改人数
    changeInput(item, type, val) {
      if (!isNumber(val)) {
        item[type] = 0;
        return;
      }
      item.totalPay = this.calcRowTotalMoney(item);
    },
    // 计算单行价格
    calcRowTotalMoney(obj) {
      const {
        price,
        count,
        otherFee
      } = obj;
      let total = price * count + Number(otherFee);
      return total;
    },
    // 保存
    handleSave() {
      this.$store.dispatch('plan/fleet/updateOrder', this.tableData).then(() => {
        this.fetchData(this.planId, this.fleetId);
      });
    }
  }
};