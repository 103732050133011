var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "head-nav"
  }, [_c("Logo", {
    staticClass: "logo-container"
  }), _c("el-menu", {
    staticClass: "head-nav-menu",
    attrs: {
      "default-active": _vm.activePath,
      router: true,
      "active-text-color": _vm.getActiveColor,
      mode: "horizontal"
    }
  }, [_vm._l(_vm.getRoutes, function (router, index) {
    return [!router.hidden ? _c("app-link", {
      key: router.path + "" + index,
      attrs: {
        to: router.path
      }
    }, [_c("el-menu-item", {
      style: {
        color: router.path == "/payment" ? " #f44" : ""
      },
      attrs: {
        index: router.path
      }
    }, [_vm._v(" " + _vm._s(_vm.generateTitle(router.name)) + " ")])], 1) : _vm._e()];
  })], 2), _c("div", {
    staticClass: "right-menu"
  }, [_c("SizeSelect"), _c("Screenfull"), _c("LangSelect"), _c("span", {
    on: {
      click: _vm.showSetting
    }
  }, [_c("i", {
    staticClass: "iconfont icon-setting"
  })]), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item flex-center",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper pointer flex-center"
  }, [_c("el-avatar", {
    attrs: {
      shape: "square",
      size: "small",
      src: _vm.avatar
    }
  }), _c("div", {
    staticClass: "name ellipsis"
  }, [_c("div", [_vm._v(_vm._s(_vm.getCompany))]), _c("div", [_vm._v(_vm._s(_vm.getRole) + " - " + _vm._s(_vm.getUserName))])]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/profile/index"
    }
  }, [_c("el-dropdown-item", [_vm._v(" " + _vm._s(_vm.$t("navbar.profile")) + " ")])], 1), _c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.logOut")) + " ")])], 1)], 1)]), _c("h2", {
    staticClass: "green"
  }, [_vm._v(_vm._s(_vm.isTestDev))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };