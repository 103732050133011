import InputResource from '@/components/common/Input/InputResource.vue';
import HotelTable from './ReserveHotelTable.vue';
import FleetTable from './ReserveFleetTable.vue';
import ScenicTable from './ReserveScenicTable.vue';
import RestaurantTable from './ReserveRestaurantTable.vue';
import { mapGetters } from 'vuex';
import { reserveMixin } from '@/mixin';
// import { DayRangeMap } from '@/config/day'
import { SelectRegion } from '@/components/common';
export default {
  components: {
    InputResource,
    RestaurantTable,
    HotelTable,
    ScenicTable,
    FleetTable,
    SelectRegion
  },
  mixins: [reserveMixin],
  data() {
    return {
      // hotelTableData: [],
      // restaurantTableData: [],
      // fleetTableData: [],
      // scenicTableData: [],

      hotelData: [],
      fleetData: [],
      scenicData: [],
      restaurantData: [],
      visible: false,
      guideRemark: '',
      form: {
        region: '',
        code: ''
      },
      rules: {
        region: [{
          required: true,
          message: '客源地不能为空',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '预定中心不能为空',
          trigger: 'blur'
        }]
      },
      checkHotel: false,
      checkRestaurant: false,
      checkFleet: false,
      checkScenic: false
    };
  },
  computed: {
    ...mapGetters({
      // orderHotel: 'order/index/orderHotel',
      currentOrder: 'order/index/currentOrder'
      // orderItinerary: 'order/itinerary/orderItinerary',
    }),
    getPeopleCount() {
      if (!this.currentOrder) return '';
      const {
        adultCount,
        childCount,
        accompanyCount
      } = this.currentOrder;
      return `${adultCount}大${childCount}小${accompanyCount}陪`;
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    // 修改省市区
    changeRegion(val) {
      ;
      ['province', 'provinceId', 'city', 'cityId', 'district', 'districtId'].forEach(key => {
        this.form[key] = val[key];
      });
    },
    selectResource(val) {
      this.form.code = val.value;
      this.form.companyCode = val.code;
    },
    changeCheckbox() {},
    handleConfirm() {
      // this.$refs.ruleForm.validate(valid => {
      // if (!valid) {
      //   return false
      // }
      // const {
      //   id,
      //   customerTripNumber,
      //   orderType,
      //   orderNumber,
      //   planNumber,
      // } = this.currentOrder
      // const { companyCode } = this.form
      // const opts = {
      //   companyCode,
      //   customerOrderId: id,
      //   hotelOrders: this.checkHotel ? this.processHotel(this.hotelData) : [],
      //   busOrders: this.checkFleet ? this.processFleetData(this.fleetData) : [],
      //   scenicOrders: this.checkScenic ? this.processScenicData(this.scenicData) : [],
      //   restaurantOrders: this.checkRestaurant ? this.processRestaurant(this.restaurantData) : [],
      //   guideOrder: {},
      //   customerTripNumber,
      //   orderNumber,
      //   orderType,
      //   planNumber,
      //   sendPlanStatus: this.checkHotel,
      // }
      // this.$store.dispatch('order/resource/bookingSend', opts).then(() => {
      //   this.hide()
      //   this.$bus.tip()
      //   this.$parent.reloadData()
      // })
      // })
    }
  }
};