var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-input", _vm._g(_vm._b({
    attrs: {
      type: "textarea",
      autosize: {
        minRows: _vm.minRows,
        maxRows: _vm.maxRows
      },
      "show-word-limit": ""
    },
    on: {
      blur: _vm.validate
    }
  }, "el-input", _vm.$attrs, false), _vm.$listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };