import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
// 报表统计

import Layout from '@/layout';
import BlankLayout from '@/layout/blank';
const reportRouter = {
  path: '/report',
  component: Layout,
  redirect: '/report/welcome',
  name: 'report',
  meta: {
    title: 'report',
    icon: 'icon-zonglan'
  },
  children: [{
    path: '/report/welcome',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/welcome/index'))),
    name: 'reportWelcome',
    // 欢迎页
    meta: {
      title: 'reportWelcome',
      icon: 'icon-zhuye',
      auth: true
    },
    orderNumber: -1
  }, {
    path: '/report/booking',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/booking'))),
    name: 'reportBooking',
    // 订票费统计
    meta: {
      title: 'reportBooking',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/report/dep-performance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/depPerformance'))),
    name: 'reportDepPerformance',
    // 部门业绩统计
    meta: {
      title: 'reportDepPerformance',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/report/collect-channel-custom',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/collect/channelCustom/index'))),
    name: 'reportCollectCustom',
    // 渠道客户汇总
    meta: {
      title: 'reportCollectCustom',
      icon: 'icon-suoyoukehu'
    }
  }, {
    path: '/report/collect-channel-detail',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/collect/channelDetail/index'))),
    name: 'reportCollectDetail',
    // 渠道应收明细
    meta: {
      title: 'reportCollectDetail',
      icon: 'icon-waibi-zijinchizhanghujixianechaxun'
    }
  }, {
    path: '/report/collect-channel-balance-year',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/collect/channelBalanceYear/index'))),
    name: 'reportCollectBalanceYear',
    // 渠道应收余额年汇总
    meta: {
      title: 'reportCollectBalanceYear',
      icon: 'icon-ic_droptohistory'
    }
  }, {
    path: '/report/collect-channel-balance-month',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/collect/channelBalanceMonth/index'))),
    name: 'reportCollectBalanceMonth',
    // 渠道应收余额年汇总
    meta: {
      title: 'reportCollectBalanceMonth',
      icon: 'icon-piaojulishixinxichaxun'
    }
  }, {
    path: '/report/supplier',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/index'))),
    redirect: '/report/supplier/hotel',
    name: 'reportSupplier',
    // 供应商数据统计
    meta: {
      title: 'reportSupplier',
      icon: 'icon-icon1'
    },
    children: [{
      path: '/report/supplier/hotel',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/hotel/index'))),
      name: 'reportHotel',
      // 酒店财务统计
      meta: {
        title: 'reportHotel',
        icon: 'icon-changguanyuyue'
      }
    }, {
      path: '/report/supplier/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/restaurant/index'))),
      name: 'reportRestaurant',
      // 餐厅财务统计
      meta: {
        title: 'reportRestaurant',
        icon: 'icon-canting'
      }
    }, {
      path: '/report/supplier/fleet',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/fleet/index'))),
      name: 'reportFleet',
      // 车队汇总统计
      meta: {
        title: 'reportFleet',
        icon: 'icon-car'
      }
    }, {
      path: '/report/supplier/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/scenic/index'))),
      name: 'reportScenic',
      // 景区财务统计
      meta: {
        title: 'reportScenic',
        icon: 'icon-lvyoufabu'
      }
    }, {
      path: '/report/supplier/scenic-add',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/scenicAdd/index'))),
      name: 'reportScenicAdd',
      // 景区财务统计
      meta: {
        title: 'reportScenicAdd',
        icon: 'icon-lvyoufabu'
      }
    }, {
      path: '/report/supplier/shuttle',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/shuttle/index'))),
      name: 'reportShuttle',
      // 接送汇总统计
      meta: {
        title: 'reportShuttle',
        icon: 'el-icon-s-flag'
      }
    }, {
      path: '/report/supplier/traffic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/traffic/index'))),
      name: 'reportTraffic',
      // 交通财务统计
      meta: {
        title: 'reportTraffic',
        icon: 'icon-jiejifuwu'
      }
    }, {
      path: '/report/supplier/insurance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/insurance/index'))),
      name: 'reportInsurance',
      // 保险财务统计
      meta: {
        title: 'reportInsurance',
        icon: 'icon-insurance'
      }
    }, {
      path: '/report/supplier/other-pay',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/supplier/otherPay/index'))),
      name: 'reportOtherPay',
      // 其他支出
      meta: {
        title: 'reportOtherPay',
        icon: 'icon-insurance'
      }
    }]
  }, {
    path: '/report/collect',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/collect'))),
    name: 'reportCollect',
    // 财务应付报表
    meta: {
      title: 'reportCollect',
      icon: 'icon-icon1'
    }
  }, {
    path: '/report/group-total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/group'))),
    name: 'reportGroupTotal',
    // 团队统计
    meta: {
      title: 'reportGroupTotal',
      icon: 'icon-icon1'
    }
  }, {
    path: '/report/room-reserve',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/roomReserve/index'))),
    name: 'reportRoomReserve',
    // 预定用房统计
    meta: {
      title: 'roomReserve',
      icon: 'el-icon-s-open'
    }
  }, {
    path: '/report/custom',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom'))),
    name: 'reportCustom',
    // 客户数据统计
    meta: {
      title: 'reportCustom',
      icon: 'el-icon-s-custom'
    },
    redirect: '/report/custom/total',
    children: [{
      path: '/report/custom/total',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/customTotal'))),
      name: 'reportCustomTotal',
      // 客户汇总统计
      meta: {
        title: 'reportCustomTotal',
        icon: 'icon-pie-chart',
        auth: true
      }
    }, {
      path: '/report/custom/collect',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/customCollect'))),
      name: 'reportCustomCollect',
      // 客户应收统计
      meta: {
        title: 'reportCustomCollect',
        icon: 'icon-zonglan',
        auth: true
      }
    }, {
      path: '/report/custom/subsidy',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/customSubsidy'))),
      name: 'reportCustomSubsidy',
      // 客户返款统计
      meta: {
        title: 'reportCustomSubsidy',
        icon: 'el-icon-s-open',
        auth: true
      }
    }, {
      path: '/report/custom/gross',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/customGross'))),
      name: 'reportCustomGross',
      // 客户毛利汇总
      meta: {
        title: 'reportCustomGross',
        icon: 'icon-bangongshichengben',
        auth: true
      }
    }, {
      path: '/report/custom/receivable',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/receivable'))),
      name: 'reportReceivable',
      // 应收账款汇总表
      meta: {
        title: 'reportReceivable',
        icon: 'el-icon-reading',
        auth: true
      }
    }, {
      path: '/report/custom/receivable-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/receivableDetail'))),
      name: 'reportReceivableDetail',
      // 应收账款明细表
      meta: {
        title: 'reportReceivableDetail',
        icon: 'el-icon-document-copy',
        auth: true
      }
    }, {
      path: '/report/custom/receivable-balance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/receivableBalance'))),
      name: 'reportReceivableBalance',
      // 应收账款余额表
      meta: {
        title: 'reportReceivableBalance',
        icon: 'el-icon-document',
        auth: true
      }
    }, {
      path: '/report/custom/accounts-balance',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/custom/accountsBalance'))),
      name: 'reportAccountsBalance',
      // 应收余额表
      meta: {
        title: 'reportAccountsBalance',
        icon: 'el-icon-s-marketing',
        auth: true
      }
    }]
  }, {
    path: '/report/guide',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide'))),
    name: 'reportGuide',
    // 导游财务统计
    meta: {
      title: 'reportGuide',
      icon: 'icon-dingdan'
    },
    redirect: '/report/guide/reimb',
    children: [{
      path: '/report/guide/reimb',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide/guideReimb'))),
      name: 'reportGuideReimb',
      // 导游报账统计
      meta: {
        title: 'reportGuideReimb',
        icon: 'el-icon-s-open',
        auth: true
      }
    }, {
      path: '/report/guide/borrow',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide/guideBorrow'))),
      name: 'reportGuideBorrow',
      // 导游借款统计
      meta: {
        title: 'reportGuideBorrow',
        icon: 'icon-icon1',
        auth: true
      }
    }, {
      path: '/report/guide/total',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide/guideTotal'))),
      name: 'reportGuideTotal',
      // 导游汇总统计
      meta: {
        title: 'reportGuideTotal',
        icon: 'icon-pie-chart',
        auth: true
      }
    }, {
      path: '/report/guide/settle',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide/guideSettle'))),
      name: 'reportGuideSettle',
      // 导游结算明细表
      meta: {
        title: 'reportGuideSettle',
        icon: 'el-icon-s-marketing',
        auth: true
      }
    }, {
      path: '/report/guide/borrow-detail',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/guide/guideBorrowDetail'))),
      name: 'reportGuideBorrowDetail',
      // 导游借款明细
      meta: {
        title: 'reportGuideBorrowDetail',
        icon: 'icon-icon1',
        auth: true
      }
    }]
  }, {
    path: '/report/other-pay-total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/otherPayTotal'))),
    name: 'reportOtherPayTotal',
    // 其他支出汇总统计
    meta: {
      title: 'reportOtherPayTotal',
      icon: 'icon-zijinchizhangbumingxichaxun'
    }
  }, {
    path: '/report/other-pay',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/otherPay'))),
    name: 'reportOtherPay',
    // 其他支出统计
    meta: {
      title: 'reportOtherPay',
      icon: 'icon-zijinchizhangbumingxichaxun'
    }
  }, {
    path: '/report/other-collect',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/otherCollect'))),
    name: 'reportOtherCollect',
    // 其他收入统计
    meta: {
      title: 'reportOtherCollect',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/report/other-income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/otherIncome'))),
    name: 'reportOtherIncome',
    // 其他收入汇总
    meta: {
      title: 'reportOtherIncome',
      icon: 'icon-bangongshichengben'
    }
  },
  // {
  //   path: '/report/custom-proxy',
  //   component: () =>
  //     import(/* webpackChunkName: "report" */ '@/views/report/customProxy'),
  //   name: 'reportCustomProxy', // 客户代收统计
  //   meta: {
  //     title: 'reportCustomProxy',
  //     icon: 'icon-liuliangyunpingtaitubiao03',
  //   },
  // },
  {
    path: '/report/custom-pay',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/customPay'))),
    name: 'reportCustomPay',
    // 客户返款统计
    meta: {
      title: 'reportCustomPay',
      icon: 'icon-kongzhizhongxin'
    }
  }, {
    path: '/report/profit-team',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTeam/index'))),
    name: 'reportProfitTeam',
    // 单团利润统计（二返）
    meta: {
      title: 'reportProfitTeam',
      icon: 'icon-tongji'
    }
  }, {
    path: '/report/profit-team-terse',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTeam/index'))),
    name: 'reportProfitTeamTerse',
    // 单团利润统计
    meta: {
      title: 'reportProfitTeamTerse',
      icon: 'icon-tongji'
    }
  }, {
    path: '/report/profit-team-settle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTeamSettle/index'))),
    name: 'reportProfitTeamSettle',
    // 单团结算利润统计
    meta: {
      title: 'reportProfitTeamSettle',
      icon: 'icon-kaoheweidufenxi'
    }
  }, {
    path: '/report/profit-team-small',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTeamSmall'))),
    name: 'reportProfitTeamSmall',
    // 小团利润统计
    meta: {
      title: 'reportProfitTeamSmall',
      icon: 'el-icon-s-grid'
    }
  }, {
    path: '/report/profit-team-small-settle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTeamSmallSettle'))),
    name: 'reportProfitTeamSmallSettle',
    // 小团利润结算统计
    meta: {
      title: 'reportProfitTeamSmallSettle',
      icon: 'el-icon-menu'
    }
  },
  // {
  //   path: '/report/balance',
  //   component: () =>
  //     import(/* webpackChunkName: "report" */ '@/views/report/balance'),
  //   name: 'reportBalance', // 资金平衡报表
  //   meta: { title: 'reportBalance', icon: 'icon-feiyongjiesuandan' },
  // },
  {
    path: '/report/income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/income'))),
    name: 'reportIncome',
    // 营收状况统计
    meta: {
      title: 'reportIncome',
      icon: 'icon-zijinchizhangbumingxichaxun'
    }
  }, {
    path: '/report/count-total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/countTotal'))),
    name: 'reportCountTotal',
    // 年度人数汇总
    meta: {
      title: 'reportCountTotal',
      icon: 'icon-view'
    }
  }, {
    path: '/report/profit-total',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/profitTotal'))),
    name: 'reportProfitTotal',
    // 毛利汇总报表
    meta: {
      title: 'reportProfitTotal',
      icon: 'icon-cheliangxinxiguanli'
    }
  }, {
    path: '/report/export-excel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/exportExcel'))),
    name: 'reportExportExcel',
    // 报表导出
    meta: {
      title: 'reportExportExcel',
      icon: 'icon-jinbizhuanchu'
    }
  }, {
    path: '/report/use-carRoom',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/useCarRoom'))),
    name: 'reportCarRoom',
    // 用车用房报表
    meta: {
      title: 'reportCarRoom',
      icon: 'icon-car'
    }
  }, {
    path: '/report/travel-agency-income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/travelAgencyIncom'))),
    name: 'reportTravelAgencyIncom',
    // 社收明细统计
    meta: {
      title: 'reportTravelAgencyIncom',
      icon: 'el-icon-notebook-2'
    }
  }, {
    path: '/report/transfer',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/transfer'))),
    name: 'reportTransfer',
    // 地接汇总统计
    meta: {
      title: 'reportTransfer',
      icon: 'el-icon-sort'
    }
  }, {
    path: '/report/out-op-user',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/outOPUser'))),
    name: 'reportOutOPUser',
    // 销售客服业绩
    meta: {
      title: 'reportOutOPUser',
      icon: 'el-icon-s-custom'
    }
  }, {
    path: '/report/sale-user',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/saleUser'))),
    name: 'reportSaleUser',
    // 业务员业绩
    meta: {
      title: 'reportSaleUser',
      icon: 'el-icon-s-custom'
    }
  }, {
    path: '/report/duty-op-user',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/dutOPUser'))),
    name: 'reportDutOPUser',
    // 计调操作绩效
    meta: {
      title: 'reportDutOPUser',
      icon: 'el-icon-s-custom'
    }
  }, {
    path: '/report/order-list',
    component: BlankLayout,
    name: 'reportOrderList',
    meta: {
      title: 'reportOrderList',
      icon: 'el-icon-s-order'
    },
    redirect: '/report/order-list/fleet',
    // hidden: true,
    children: [{
      path: '/report/order-list/fleet',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/orderList/fleet'))),
      name: 'reportOrderFleet',
      //车队订单
      meta: {
        title: 'reportOrderFleet',
        icon: 'el-icon-truck',
        auth: true
      }
    }, {
      path: '/report/order-list/scenic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/orderList/scenic'))),
      name: 'reportOrderScenic',
      // 景区订单
      meta: {
        title: 'reportOrderScenic',
        icon: 'el-icon-wind-power',
        auth: true
      }
    }, {
      path: '/report/order-list/restaurant',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/orderList/restaurant'))),
      name: 'reportOrderRestaurant',
      // 餐厅订单
      meta: {
        title: 'reportOrderRestaurant',
        icon: 'el-icon-knife-fork',
        auth: true
      }
    }, {
      path: '/report/order-list/traffic',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/orderList/traffic'))),
      name: 'reportOrderTraffic',
      // 交通订单
      meta: {
        title: 'reportOrderTraffic',
        icon: 'el-icon-orange',
        auth: true
      }
    }]
  }, {
    path: '/report/room-rate',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/roomRate'))),
    name: 'reportRoomRate',
    // 房价统计表
    meta: {
      title: 'reportRoomRate',
      icon: 'el-icon-office-building'
    }
  }, {
    path: '/report/letters-every-day',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/lettersEveryDay'))),
    name: 'reportLettersEveryDay',
    // 天天快报
    meta: {
      title: 'reportLettersEveryDay',
      icon: 'el-icon-office-building'
    }
  },
  // {
  //   path: '/report/enterprise-code',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "report" */ '@/views/report/enterpriseCode/staticstics'
  //     ),
  //   name: 'reportEnterpriseCode', // 企业码额度预算
  //   meta: {
  //     title: 'reportEnterpriseCode',
  //     icon: 'el-icon-office-building',
  //   },
  // },
  // {
  //   path: '/report/enterprise-code-regiment',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "report" */ '@/views/report/enterpriseCode/regiment'
  //     ),
  //   name: 'reportEnterpriseCodeRegiment', // 企业码安团统计
  //   meta: {
  //     title: 'reportEnterpriseCodeRegiment',
  //     icon: 'el-icon-office-building',
  //   },
  // },
  {
    path: '/report/refund',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/report/refund'))),
    name: 'reportRefund',
    // 返款汇总
    meta: {
      title: 'reportRefund',
      icon: 'el-icon-office-building'
    }
  }]
};
export default reportRouter;