import "core-js/modules/es.array.reduce.js";
import { OrderResourcePlanHotelInfo as columns } from '../tableConfig';
export default {
  data() {
    return {
      columns,
      visible: false,
      tableData: []
    };
  },
  methods: {
    show(params) {
      this.tableData = [];
      this.fetchData(params);
    },
    hide() {
      this.visible = false;
    },
    fetchData(params) {
      this.$store.dispatch('order/resourcePlan/hotelList', params).then(data => {
        if (!data) return;
        this.visible = true;
        this.tableData = this.formatData(data);
      }).catch(() => {
        this.visible = false;
      });
    },
    formatData(list) {
      if (!list) return;
      return list.reduce((acc, it) => {
        const {
          roomList
        } = it;
        it.rowId = it.id;
        if (roomList.length) {
          roomList.forEach(room => {
            acc = [...acc, {
              ...it,
              ...room
            }];
          });
        } else {
          acc = [...acc, it];
        }
        return acc;
      }, []);
    }
  }
};