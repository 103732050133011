import "core-js/modules/es.array.push.js";
import { planEditReceptionProxyEdit } from '@/config/plan';
import { SelectDictionary } from '../index';
import TopTitle from '../TopTitle.vue';
import { codeType } from '@/config/dictionary';
import { dictionaryMixin } from '@/mixin';
import propMixin from './propMixin';
import { mapGetters } from 'vuex';
const defaultState = () => ({
  title: '',
  total: 0,
  type: ''
});
export default {
  components: {
    TopTitle,
    SelectDictionary
  },
  mixins: [dictionaryMixin, propMixin],
  data() {
    return {
      proxyType: codeType.proxyType,
      columnData: planEditReceptionProxyEdit,
      type: 'collectList' // 对应接口的字段
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder'
    })
  },
  methods: {
    // 新增一条
    handlePlus() {
      this.rowId++;
      const {
        label: type
      } = this.getDictionaryDefVal(this.proxyType);
      this.tableData.push({
        ...defaultState(),
        isCustom: true,
        type
      });
    },
    selectType(row, val) {
      row.type = val ? val.label : '';
      row.typeId = val ? val.value : '';
    },
    // 计算单行价格
    calcRowItem(obj) {
      const {
        count,
        price
      } = obj;
      let total = count * price;
      return total;
    },
    handleRemove(row) {
      const {
        id,
        isCustom
      } = row;
      if (isCustom) {
        this.$store.commit('order/reception/REMOVE_RECEPTION', {
          id,
          index: this.index,
          type: this.type
        });
      } else {
        this.$bus.open(() => {
          const loading = this.$bus.loading();
          this.$store.dispatch('order/reception/deleteProxyItem', {
            id,
            index: this.index,
            type: this.type
          }).then(() => {
            loading.close();
          }).catch(() => {
            loading.close();
          });
        });
      }
    },
    updateData() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('order/reception/changeReception', {
          type: this.type,
          data: this.tableData,
          index: this.index
        }).then(() => {
          return resolve('ok');
        }).catch(err => {
          return reject(err);
        });
      });
    }
  }
};