var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "form-wrapper comparison"
  }, [_c("h3", [_vm._v("数据比对")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "大团号"
    },
    model: {
      value: _vm.planNumber,
      callback: function ($$v) {
        _vm.planNumber = $$v;
      },
      expression: "planNumber"
    }
  }), _c("InputDateRange", {
    attrs: {
      label: "日期",
      shortcuts: true,
      "default-date": _vm.sendDate
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  }), _c("div", {
    staticClass: "btn-box"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 小团利润与单团利润比对 ")]), _c("el-button", {
    attrs: {
      type: "danger",
      disabled: !(_vm.diffList.length && _vm.tableDate.length)
    },
    on: {
      click: _vm.handleBatchFix
    }
  }, [_vm._v(" 批量修复 ")])], 1)], 1), _vm.tableData.length ? _c("TableBase", {
    attrs: {
      data: _vm.tableData,
      "column-data": _vm.columns,
      dynamic: false,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "money",
      fn: function ({
        scope
      }) {
        return [_c("span", [_vm._v(_vm._s(scope.row.money1) + " / " + _vm._s(scope.row.money2))])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [scope.row.update ? _c("BtnBase", {
          attrs: {
            type: "save"
          },
          on: {
            click: function ($event) {
              return _vm.handleFix(scope.row);
            }
          }
        }, [_vm._v(" 修复 ")]) : _vm._e()];
      }
    }], null, false, 966252098)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };