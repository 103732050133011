import { AuditRole } from '@/config/audit';
const TEAM_FUND_TYPE = 'createOrUpdateFund';
import { reviewerStatusType, reviewerStatus } from '@/config/order';
import DialogAuditDetail from './components/DialogAuditDetail';
/**
 * 单条对账或者审核不可修改
 * 整团 对账 后不可新增和修改
 * 整团 审核 后不可新增和修改
 */
export default {
  components: {
    DialogAuditDetail
  },
  data() {
    return {
      btnLoading: false,
      removeCache: [],
      reviewerStatus,
      reviewerStatusType
    };
  },
  computed: {
    currentOrderId() {
      return this.$store.getters['order/index/currentOrderId'];
    },
    currentOrder() {
      return this.$store.getters['order/index/currentOrder'];
    },
    planAuditStatus() {
      return this.$store.getters['order/index/planAuditStatus'];
    },
    // 整团对账
    isCheckAccount() {
      // 应收对账整团审核后允许修改返款
      const currentOrder = this.currentOrder || {};
      return currentOrder.checkAccountStatus;
    },
    // 整团审核状态
    isBtnDisable() {
      return this.planAuditStatus && this.planAuditStatus != AuditRole.wait;
    },
    // 单条团款、返款、代收(审核后可修改,对账不可修改)
    isTeanFund() {
      return () => {
        return this.isCheckAccount;
      };
    },
    // 单条团款、返款、代收（审核和对账后不可修改）
    isTeanAuditFund() {
      return row => {
        return this.isBtnDisable || row.auditProgress && row.auditProgress !== AuditRole.wait || this.isCheckAccount;
      };
    },
    // 返款
    isAllowRefund() {
      /**
       *
       */
      return row => {
        if (this.collectAuditedAllowChange) {
          return false;
        } else {
          return this.isBtnDisable || row.auditProgress && row.auditProgress !== AuditRole.wait;
        }
      };
    },
    // 单行禁用
    isRowDisable() {
      return row => {
        return this.isBtnDisable || row.checkAccountStatus || row.auditProgress && row.auditProgress !== AuditRole.wait;
      };
    },
    reloadPage() {
      return this.$store.getters['app/reloadPage'];
    },
    orderAuditStatus() {
      return item => {
        const addAuditStatus = item ? item.addAuditStatus : '';
        if (addAuditStatus && addAuditStatus == reviewerStatusType.wait) {
          return true;
        }
        return false;
      };
    }
  },
  watch: {
    reloadPage: {
      handler: function (val) {
        if (!val) return;
        this.fetchData();
      },
      immediate: true
    }
  },
  methods: {
    fetchDetail(id) {
      this.$store.dispatch('order/index/fetchDetail', {
        id
      });
    },
    fetchData(id) {
      this.$store.dispatch(this.fetchPath, {
        id: id || this.currentOrderId
      }).then(data => {
        this.initData(data);
      });
    },
    handleDelete(scope) {
      const {
        id
      } = scope.row;
      // 将要删除的项目加入缓存
      if (id) {
        this.$bus.open(() => {
          this.$store.dispatch(this.deletePath, id).then(res => {
            const message = res ? res.message : '操作成功';
            this.operationTip(message);
            this.fetchData && this.fetchData();
          });
        }, this.deleteMsg);
      } else {
        this.tableData.splice(scope.$index, 1);
      }
    },
    // 跳转审核详情页
    linkAuditDetail(row) {
      const {
        id
      } = row;
      this.$store.commit('order/index/CUR_ORDER_ID', id);
      this.$refs.auditDetailRef.show({
        id,
        isReview: 1
      });
    },
    handleUpdate() {
      if (!this.validateForm()) return;
      this.createOrUpdate().then(res => {
        const {
          message
        } = res ? res : '';
        message ? this.operationTip(message) : this.$bus.tip();
      });
    },
    // 操作成功提示
    operationTip(message) {
      // 判断是不是修改内容需要订单审核流程
      if (message && message.indexOf('订单审核') != -1) {
        this.$alert(message, '温馨提示', {
          confirmButtonText: '好的',
          type: 'warning'
        });
        // 如果需要审核则请求订单获取新状态
        this.fetchDetail(this.currentOrderId);
      } else {
        this.$bus.tip();
      }
    },
    createOrUpdate(customerOrderId = this.currentOrderId, type) {
      if (!this.tableData.length) return Promise.resolve(1);
      const loading = this.$bus.loading();
      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          return reject();
        } else {
          const copyData = this.beforeCommit(customerOrderId, type);
          // 新增的项目加上 订单 id
          let opts = [...copyData, ...this.removeCache];
          // 点击全部保存时筛选出已审核的数据，只提交未审核的

          // opts = opts.filter(
          //   it => it.auditProgress && it.auditProgress == AuditRole.wait
          // )
          this.$store.dispatch(this.updatePath, opts).then(res => {
            this.fetchData();
            this.removeCache = [];
            // 更新客户余额
            if (~this.updatePath.indexOf(TEAM_FUND_TYPE)) {
              this.$store.commit('order/index/CUR_CUSTOMER_ID', this.currentOrder.customerId);
            }
            loading.close();
            return resolve(res);
          }).catch(err => {
            this.fetchData();
            loading.close();
            return reject(err);
          });
        }
      });
    }
  }
};