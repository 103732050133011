import { SelectStaff, PersonCount } from '@/components/common';
import { OrderResourcePlan as columns } from './tableConfig';
import { OrderResPlanEnum, OrderResPlanEnumLabel } from '@/config/order/orderEnum';
import { reloadMixin, summaryMixin, searchMixin } from '@/mixin';
import { DialogLog, BtnLink } from '@/components/common';
import { LOG_YPES } from '@/config/resource';
import { getRoomInfo } from './config';
import { getShipInfo } from './helper';
import { parseDate } from '@/utils';
import { DialogAdd, DialogCopy, DialogOrder, DialogUpdate, DialogDetail, DialogHistory, DialogBusInfo, DialogHotelInfo, DialogBookingDetail, PopoverItem } from './components';
import TipsItem from './components/TipsItem';
import { OrderType } from '@/config/order';
const BookingType = {
  plan: 1,
  resource: 2
};
export default {
  components: {
    TipsItem,
    DialogLog,
    BtnLink,
    SelectStaff,
    PersonCount,
    DialogAdd,
    DialogCopy,
    DialogOrder,
    DialogUpdate,
    DialogDetail,
    DialogHistory,
    DialogBusInfo,
    DialogHotelInfo,
    DialogBookingDetail,
    PopoverItem
  },
  mixins: [reloadMixin, summaryMixin, searchMixin],
  data() {
    const today = parseDate();
    return {
      getShipInfo,
      columns,
      sendDate: [today],
      searchParams: {
        startTripDateS: today
      },
      department: [],
      orderData: []
    };
  },
  computed: {
    data() {
      return this.$store.getters['order/resourcePlan/data'];
    },
    total() {
      return this.data ? this.data.totalCount : 0;
    },
    tableData() {
      return this.data ? Object.freeze(this.data.list) : [];
    },
    isCanceled() {
      return row => row.bookingStatus === OrderResPlanEnum.CANCELED;
    },
    canModify() {
      return row => row.bookingStatus !== OrderResPlanEnum.ARRANGED && row.bookingStatus !== OrderResPlanEnum.WEIT_CANCEL;
    },
    _getRoomInfo() {
      return row => getRoomInfo(row);
    },
    getBookingStatus() {
      return val => val ? OrderResPlanEnumLabel[val] : '';
    },
    getExpandClass() {
      return (row, type) => {
        return row[type] ? `${row[type]}-color` : '';
      };
    },
    isTeamOrder() {
      return val => val == OrderType.team.toLowerCase();
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    },
    getTagViewSearch: {
      handler: function (data) {
        if (data) {
          const {
            departmentId,
            subDepartmentId,
            grandsonDepartmentId
          } = data;
          this.department = [departmentId, subDepartmentId, grandsonDepartmentId];
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params = this.searchParams) {
      this.$store.dispatch('order/resourcePlan/fetch', params);
    },
    handlePage(params) {
      this.fetchData({
        ...this.searchParams,
        ...params
      });
    },
    formatData(data) {
      return data.map(it => {
        // const { itinerary } = it

        // const hotel = itinerary.reduce((acc, cur) => {
        //   acc += cur.hotelObj
        // }, '')

        return it;
      });
    },
    setColorForStatus(row, column) {
      const keys = ['hotel', 'bus', 'scenic', 'restaurant', 'shipTicket'];
      if (row.bookingStatus == OrderResPlanEnum.CANCELED) {
        return 'CANCEL-color';
      } else {
        // 控房列
        if (column.property == 'hotelReservations' && row.hotelReservations.length > 0) {
          return `${row['hotelStatus']}-color`;
        }
        const key = keys.find(key => column.property == key);
        const status = row[key + 'Status'];
        return status && row[key] ? `${status}-color` : '';
      }
    },
    tableCellClass({
      row,
      column
    }) {
      return this.setColorForStatus(row, column);
    },
    changeDate(val) {
      const [start, end] = val;
      this.searchParams.startTripDateS = start;
      this.searchParams.startTripDateE = end;
    },
    // 修改
    handleUpdate(row) {
      this.$refs.dialogUpdateRef.show({
        ...row
      });
    },
    // 查看
    handleDetail(row) {
      this.$refs.dialogDetailRef.show({
        ...row
      });
    },
    // 复制
    handleCopy(row) {
      this.$refs.dialogCopyRef.show({
        ...row
      });
    },
    // 历史
    handleHistory(row) {
      const {
        id
      } = row;
      this.$refs.dialogHistoryRef.show(id);
    },
    // 取消
    handleCancel(row) {
      const {
        id
      } = row;
      this.$bus.open(() => {
        this.$store.dispatch('order/resourcePlan/cancel', {
          id
        }).then(() => {
          this.$bus.tip();
          this.fetchData();
        });
      }, '确定要取消吗？');
    },
    // 日志
    handleLog({
      id
    }) {
      const types = `${LOG_YPES.resourcePlan},${LOG_YPES.resourcePlan}`;
      this.$store.dispatch('plan/index/fetchPlanLogs', {
        itemId: id,
        types,
        subType: '',
        subItemId: ''
      }).then(res => {
        this.$refs.logRef.show(res);
      });
    },
    showDialog() {
      this.$refs.dialogAddRef.show();
    },
    showInfoDialog(status) {
      return status != OrderResPlanEnum.ARRANGED && status != OrderResPlanEnum.MODIFIED;
    },
    showBusInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      const status = row.busStatus;
      if (this.showInfoDialog(status)) return;
      this.$refs.dialogBusRef.show({
        planNumber,
        travelAgencyId,
        shuttleType: 'BUS'
      });
    },
    showHotelInfo(row) {
      const {
        planNumber,
        travelAgencyId
      } = row;
      const status = row.hotelStatus;
      if (this.showInfoDialog(status)) return;
      this.$refs.dialogHotelRef.show({
        planNumber,
        travelAgencyId
      });
    },
    showReservationInfo(row, item) {
      this.$refs.dialogBookingDetailRef.showRes({
        id: item.id,
        planId: item.planId,
        type: BookingType.resource
      });
    },
    downloadFile({
      filePath,
      fileName
    }) {
      this.$store.dispatch('resource/common/getOssImage', filePath).then(res => {
        if (!res) return;
        const [url] = res;
        this.$store.dispatch('order/resourcePlan/downloadFile', {
          url,
          fileName
        });
      });
    },
    addOrder(row) {
      this.$refs.dialogOrderRef.show({
        ...row
      });
    }
  }
};