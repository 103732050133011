var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.curOrder ? _c("div", {
    staticClass: "order-info"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("基础信息")]), _c("FormBase", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "大团号"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.planNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "线路产品"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.productName))]), _vm._v(" " + _vm._s(_vm.curOrder.planType == "T_TOURIST" && _vm.curOrder.productType ? `(${_vm.curOrder.productType})` : "") + " ")], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "参团人数"
    }
  }), _c("TextBase", [_vm._v(" " + _vm._s(_vm.curOrder.adultCount) + "大" + _vm._s(_vm.curOrder.childCount) + "小 " + _vm._s(_vm.curOrder.accompanyCount) + "陪 ")])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "操作计调"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.dutyOPUserName))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "客服"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.outOPUserName))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "发团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.startPlanDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "散团日期"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.endPlanDate))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "发团地点"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.startPlanPosition))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "散团地点"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.endPlanPosition))])], 1)]), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "团上导游"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.formatGuides(_vm.curOrder.guides)))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "出发大交通"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.customerContactMobileNumber))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "返回大交通"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.endPlanTransport))])], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "友情提醒"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.friendlyReminderRemark))])], 1)]), _c("div", {
    staticClass: "input-field full"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "备注"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.remark))])], 1)]), _c("div", {
    staticClass: "input-field full"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "内部备注"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.innerRemark))])], 1)]), _c("div", {
    staticClass: "input-field full"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "服务标准"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.serviceStandardRemark))])], 1)]), _vm._l(_vm.tableData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "input-field input-red"
    }, [_c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车队公司"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.busCompanyName))])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车辆类型"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.busTypeName) + "(金额：" + _vm._s(item.busFee) + " )")])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "司机"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.driverName) + " " + _vm._s(item.driverPhone) + " ")])], 1), _c("div", {
      staticClass: "input-box"
    }, [_c("LabelBase", {
      attrs: {
        label: "车牌号"
      }
    }), _c("TextBase", [_vm._v(_vm._s(item.licenceNumber))])], 1)]);
  }), _c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "游客信息（接站牌）"
    }
  }), _c("TextBase", [_vm._v(_vm._s(_vm.curOrder.welcomeBorad))])], 1)])], 2)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };