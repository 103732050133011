var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "110px",
      inline: ""
    }
  }, [_c("FormItemBase", {
    attrs: {
      label: "购物店名称",
      prop: "name"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "地区",
      prop: "area"
    }
  }, [_c("SelectDictionary", {
    attrs: {
      code: "hotelRegionType",
      label: "",
      "is-require": ""
    },
    on: {
      "on-select": _vm.onSelectArea
    },
    model: {
      value: _vm.form.area,
      callback: function ($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "旅监购物店名称",
      prop: "glETravelShopId"
    }
  }, [_c("SelectDicType", {
    attrs: {
      clearable: "",
      code: "shop",
      list: _vm.shopDicData,
      label: ""
    },
    on: {
      "visible-change": _vm.beforeSelectDicType
    },
    nativeOn: {
      input: function ($event) {
        return _vm.filterData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.glETravelShopId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "glETravelShopId", $$v);
      },
      expression: "form.glETravelShopId"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "购物店类型",
      prop: "shopTypeLabel"
    }
  }, [_c("SelectBase", {
    attrs: {
      label: "",
      type: "shopType"
    },
    on: {
      "on-select": _vm.selectShopType
    },
    model: {
      value: _vm.form.shopTypeLabel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopTypeLabel", $$v);
      },
      expression: "form.shopTypeLabel"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "目标人均",
      prop: "perCapita"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.perCapita,
      callback: function ($$v) {
        _vm.$set(_vm.form, "perCapita", $$v);
      },
      expression: "form.perCapita"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "返款比例(%)",
      prop: "subsidyRate"
    }
  }, [_c("InputBase", {
    attrs: {
      label: ""
    },
    model: {
      value: _vm.form.subsidyRate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subsidyRate", $$v);
      },
      expression: "form.subsidyRate"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "联系人",
      prop: "contact"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "10",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.contact,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contact", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.contact"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "手机号码",
      prop: "mobilePhone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "11",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.mobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobilePhone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.mobilePhone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "座机号码",
      prop: "telphone"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.telphone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telphone", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.telphone"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "传真号码",
      prop: "faxNumber"
    }
  }, [_c("InputBase", {
    attrs: {
      label: "",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.faxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "faxNumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.faxNumber"
    }
  })], 1), _c("div", [_c("FormItemBase", {
    staticStyle: {
      display: "flex"
    },
    attrs: {
      label: "备注",
      prop: "remarks"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.form.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1), _c("div", [_c("FormItemBase", {
    attrs: {
      label: "启用状态",
      prop: "isEnable"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.form.isEnable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isEnable", $$v);
      },
      expression: "form.isEnable"
    }
  })], 1), _c("FormItemBase", {
    attrs: {
      label: "导出状态",
      prop: "isExport"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.form.isExport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isExport", $$v);
      },
      expression: "form.isExport"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };