/* eslint-disable no-empty-pattern */

// 短信
import { orderMessageRequest, confirmMessageRequest, confirm2MessageRequest } from '@/api/system/sms';
const dfaultState = () => ({});
const state = dfaultState();
const getters = {};
const actions = {
  // 给导游发送短信
  sendMessageToGuide({}, opts) {
    return orderMessageRequest(opts);
  },
  // 导游单团报账 确认账单短信
  confirmMessage({}, opts) {
    return confirmMessageRequest(opts);
  },
  // 导游单团报账 再次发送确认账单短信
  confirm2Message({}, opts) {
    return confirm2MessageRequest(opts);
  }
};
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};