import { SELECTOR } from '@/config';
export default {
  name: 'SelectBase2',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isFirst: true,
      data: []
    };
  },
  computed: {
    getTypeList() {
      return this.data;
    }
  },
  watch: {
    list: {
      handler: function (data) {
        this.data = data.length > 0 ? data : SELECTOR[this.type];
      },
      immediate: true
    }
  },
  methods: {
    initData(val) {
      if (val === '') return;
      this.curVal = val;
    },
    handleChange(val) {
      const item = this.data.find(it => it.value === val);
      this.$emit('on-select', item);
    },
    lazyLoad() {
      if (!this.isFirst) return;
      this.isFirst = false;
      this.data = this.list.length > 0 ? this.list : SELECTOR[this.type];
    }
  }
};