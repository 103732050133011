import _interopRequireWildcard from "E:/develop/project/future-admin/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
/*
 * @Author: cyg
 * @Date: 2021-01-22 10:50:58
 * @LastEditors: cyg
 * @LastEditTime: 2023-02-15 17:20:01
 * @FilePath: \travel-admin\src\router\modules\billCheck.js
 */
// 对账管理
import Layout from '@/layout';
const billCheckRouter = {
  path: '/bill-check',
  component: Layout,
  redirect: '/bill-check/welcome',
  name: 'checkBill',
  meta: {
    title: '对账管理',
    icon: 'icon-zhuanban'
  },
  children: [{
    path: '/bill-check/welcome',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/welcome/index'))),
    name: 'checkBill',
    // 对账管理
    meta: {
      title: 'checkBillWelcome',
      icon: 'icon-zhuye',
      auth: true
    },
    orderNumber: -1
  }, {
    path: '/bill-check/scenic',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/scenic'))),
    name: 'checkBillScenic',
    // 景区对账
    meta: {
      title: 'checkBillScenic',
      icon: 'icon-lvyoufabu'
    }
  }, {
    path: '/bill-check/hotel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/hotel'))),
    name: 'checkBillHotel',
    // 酒店对账
    meta: {
      title: 'checkBillHotel',
      icon: 'icon-changguanyuyue'
    }
  }, {
    path: '/bill-check/restaurant',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/restaurant'))),
    name: 'checkBillRestaurant',
    // 餐厅对账
    meta: {
      title: 'checkBillRestaurant',
      icon: 'icon-canting'
    }
  }, {
    path: '/bill-check/fleet',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/fleet'))),
    name: 'checkBillFleet',
    // 车队对账
    meta: {
      title: 'checkBillFleet',
      icon: 'icon-lvyoubaochedingdan'
    }
  }, {
    path: '/bill-check/shuttle',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/shuttle'))),
    name: 'checkBillShuttle',
    // 接送对账
    meta: {
      title: 'checkBillShuttle',
      icon: 'el-icon-s-flag'
    }
  }, {
    path: '/bill-check/traffic',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/traffic'))),
    name: 'checkBillTraffic',
    // 交通对账
    meta: {
      title: 'checkBillTraffic',
      icon: 'icon-jiejifuwu'
    }
  }, {
    path: '/bill-check/insurance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/insurance'))),
    name: 'checkBillInsurance',
    // 保险对账
    meta: {
      title: 'checkBillInsurance',
      icon: 'icon-insurance'
    }
  }, {
    path: '/bill-check/shops',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/shops'))),
    name: 'checkBillShopping',
    // 购物对账
    meta: {
      title: 'checkBillShopping',
      icon: 'icon-wentichaoshi'
    }
  }, {
    path: '/bill-check/reception',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/reception'))),
    name: 'checkBillReception',
    // 地接对账
    meta: {
      title: 'checkBillReception',
      icon: 'icon-shoufahuorenxinxiguanli'
    }
  }, {
    path: '/bill-check/collect',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/collect'))),
    name: 'checkBillCollect',
    // 应收对账
    meta: {
      title: 'checkBillCollect',
      icon: 'icon-jiesuanzhongxin1'
    }
  }, {
    path: '/bill-check/other-income',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/otherIncome'))),
    name: 'checkBillOtherIncome',
    // 其他收入对账
    meta: {
      title: 'checkBillOtherIncome',
      icon: 'icon-bangongshichengben'
    }
  }, {
    path: '/bill-check/other-pay',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/otherPay'))),
    name: 'checkBillOtherPay',
    // 其他支出对账
    meta: {
      title: 'checkBillOtherPay',
      icon: 'icon-credit-card'
    }
  }, {
    path: '/bill-check/group',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/group'))),
    name: 'checkBillGroup',
    // 集团对账
    meta: {
      title: 'checkBillGroup',
      icon: 'icon-zuzhixinxi'
    }
  }, {
    path: '/bill-check/refund',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/refund/index'))),
    name: 'checkBillRefund',
    // 返款对账
    meta: {
      title: 'checkBillRefund',
      icon: 'icon-zhuanban'
    }
  }, {
    path: '/bill-check/channel',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/billCheck/channel/index'))),
    name: 'checkBillChannel',
    // 渠道应收对账
    meta: {
      title: 'checkBillChannel',
      icon: 'icon-tree'
    }
  }
  // {
  //   path: '/bill-check/aliecode',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "billCheck" */ '@/views/billCheck/aliEcode/index'
  //     ),
  //   name: 'checkBillEcode', // 企业码付对账
  //   meta: { title: 'checkBillEcode', icon: 'icon-xunjiaguanli' },
  // },
  ]
};
export default billCheckRouter;