export default {
  data() {
    return {
      count: 0
    };
  },
  computed: {
    talbe() {
      let parent = this.$parent;
      let parentName = parent.$options._componentTag;
      // 只在3层以内找
      while (parent && parentName && parentName !== 'TableBase' && this.count < 3) {
        this.count++;
        if (parent) {
          parent = parent.$parent;
          parentName = parent && parent.$options._componentTag;
        }
      }
      return parent;
    },
    isRead() {
      const attrObj = this.talbe ? this.talbe.$attrs : {
        readonly: false
      };
      return !!attrObj.readonly;
    }
  }
};