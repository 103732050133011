var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("Search", {
    ref: "searchRef",
    on: {
      search: _vm.fetchData,
      export: _vm.handleExport
    }
  }), _c("TableNew", {
    attrs: {
      data: _vm.tableData,
      columns: _vm.Columns,
      total: _vm.total,
      statistics: _vm.statistics,
      "statistics-keys": _vm.StatisticsKeys
    },
    on: {
      page: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };