import { orderTouristListDetail, updateStatusType } from '@/config/order';
import TouristAge from '@/components/common/TouristAge.vue';
import { SELECTOR } from '@/config/select';
import { mapGetters } from 'vuex';
import { reloadMixin } from '@/mixin';
export default {
  components: {
    TouristAge
  },
  mixins: [reloadMixin],
  data() {
    return {
      columnData: orderTouristListDetail,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrderId: 'order/index/currentOrderId'
    }),
    getCardType() {
      return type => {
        // 之前有数据是空的，默认给身份证
        const findItem = SELECTOR.cardType.find(it => it.value == type);
        return findItem ? findItem.label : '身份证';
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      const {
        id,
        isReview
      } = this.$route.query;
      this.$store.dispatch('order/index/fetchTourist', {
        isReview,
        id
      }).then(data => {
        if (!data) return;
        if (isReview == 1) {
          this.tableData = this.formatData(data);
        } else {
          this.tableData = data;
        }
      });
    },
    formatData(data) {
      data.forEach(it => {
        if (!it.isReview) return;
        if (it.updateType == updateStatusType.update) {
          let newKye = it.updateDetails.filter(its => its.attr == 'age')[0];
          if (!newKye) return;
          it.age = newKye ? `${newKye.oldVal}=><span style="color:red">${newKye.newVal}</span>` : it.age;
        }
      });
      return data;
    }
  }
};