import { mapGetters } from 'vuex';
import { SwitchStatus, SelectDictionary
// SelectTrafficGroup,
// SelectFleetGroup,
// SelectInsuranceGroup,
// SelectRestaurantGroup,
} from '@/components/common';
import { ResourceType } from '@/config/billCheck';
const defaultState = () => ({
  name: '',
  code: '',
  contact: '',
  mobilePhone: '',
  telphone: '',
  faxNumber: '',
  area: '',
  remarks: '',
  enable: true,
  isEnable: true
});
export default {
  components: {
    SwitchStatus,
    SelectDictionary
    // SelectTrafficGroup,
    // SelectFleetGroup,
    // SelectInsuranceGroup,
    // SelectRestaurantGroup,
  },
  props: {
    name: {
      type: String,
      default: '名称'
    },
    isHotel: {
      type: Boolean,
      default: false
    },
    isCompany: {
      type: Boolean,
      default: false
    },
    isExport: {
      type: Boolean,
      default: false
    },
    currentView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: defaultState(),
      ResourceType
    };
  },
  computed: {
    ...mapGetters({
      editSourceInfo: 'resource/common/editSourceInfo'
    })
  },
  watch: {
    editSourceInfo: [{
      handler: 'initForm',
      immediate: true
    }]
  },
  methods: {
    initForm(val) {
      this.form = {
        ...defaultState(),
        ...val
      };
    },
    onSelectArea(val) {
      this.form.area = val.label;
      this.handleChange('area', val.label);
    },
    handleChange(type, val) {
      this.form[type] = val;
      this.form.enable = val;
      this.$store.dispatch('resource/common/editSource', this.form);
    }
  }
};