import { tableMixin, getSummariesMixin } from '@/mixin';
import { orderAuditStatus, statisticsTeamKeys } from '@/config/report';
import { orderType } from '@/config/order';
export default {
  mixins: [tableMixin, getSummariesMixin],
  data() {
    return {
      statisticsKeys: statisticsTeamKeys,
      orderType,
      avgeageKey: [7, 33, 34]
    };
  },
  methods: {
    planStatus(val) {
      let status = '';
      orderAuditStatus.forEach(it => {
        if (val == it.value) {
          status = it.label;
        }
      });
      return status;
    },
    changeCellClassName(row) {
      //列的label的名称
      if (row.column.property === 'adultCount' || row.column.property === 'childCount' || row.column.property === 'accompanyCount') {
        return 'pale-yellow'; //修改的样式
      }
      if (row.column.property === 'totalCustomerIncome' || row.column.property === 'totalOutTransferSubsidyIncome' || row.column.property === 'totalOtherIncome' || row.column.property === 'totalShopIncome' || row.column.property === 'totalScenicExtraIncome' || row.column.property === 'totalGuideManageFeeIncome') {
        return 'baby-blue'; //修改的样式
      }
      if (row.column.property === 'totalCustomerSubsidyPay' || row.column.property === 'totalBusCompanyPay' || row.column.property === 'totalHotelPay' || row.column.property === 'totalGuideServiceFeePay' || row.column.property === 'totalInsurancePay' || row.column.property === 'totalOutTransferPay' || row.column.property === 'totalShuttlePay' || row.column.property === 'totalTransportCompanyPay' || row.column.property === 'totalOtherPay' || row.column.property === 'totalShopRebatePay' || row.column.property === 'totalScenicExtraRebatePay' || row.column.property === 'totalScenicInnerPay' || row.column.property === 'totalScenicExtraPay' || row.column.property === 'totalRestaurantPay') {
        return 'light-red'; //修改的样式
      }
      if (row.column.property === 'totalIncome' || row.column.property === 'totalIncomed' || row.column.property === 'totalPay' || row.column.property === 'totalPayed' || row.column.property === 'totalGrossMargin' || row.column.property === 'totalGrossProfit') {
        return 'pale-green'; //修改的样式
      }
      return '';
    }
  }
};