import TableList from './TableList';
import { formatTableData, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { orderRecycle, orderRecycleNoCustom } from '@/config/order';
import { SelectCustom, SelectProduct } from '@/components/common';
import { orderMixin, reloadMixin, customerInfoMixin, searchMixin } from '@/mixin';
const defaultState = () => ({
  customerName: '',
  customerTripNumber: '',
  endCreateDate: '',
  planCustomerNumber: '',
  productName: '',
  startCreateDate: '',
  startTripEDate: '',
  startTripSDate: '',
  welcomeBorad: ''
});
export default {
  components: {
    TableList,
    SelectCustom,
    SelectProduct
  },
  mixins: [orderMixin, reloadMixin, searchMixin, customerInfoMixin],
  data: () => ({
    loading: true,
    columnData: orderRecycleNoCustom,
    tableData: [],
    inputDate: [],
    total: 0,
    form: defaultState(),
    searchParams: {}
  }),
  computed: {
    recycleData() {
      return this.$store.getters['order/recycle/recycleData'];
    }
  },
  watch: {
    recycleData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? orderRecycle : filterCustomColumn(orderRecycle);
  },
  methods: {
    fetchData(queryParam = {}) {
      this.$store.dispatch('order/recycle/fetch', queryParam);
    },
    onSelectCustom(val) {
      this.searchParams.customerName = val.label;
      this.searchParams.customerId = val.value;
    },
    selectProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productName = val.name;
    },
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      this.tableData = Object.freeze(formatTableData(list));
    },
    // 恢复
    recovery(scope, type) {
      let orderTypeLabel = type == 't_tourist' ? '团队' : type == 's_tourist' ? '散客' : '';
      const {
        id,
        customerTripNumber,
        productName,
        planCustomerNumber
      } = scope.row;
      const opts = {
        id,
        orderType: type
      };
      this.$bus.open(() => {
        this.$store.dispatch('order/recycle/recovery', opts).then(res => {
          const {
            message
          } = res;
          this.$bus.tip({
            message
          });
          this.fetchData();
        });
      }, `是否确认将订单【${customerTripNumber || productName || planCustomerNumber || ''}】恢复为${orderTypeLabel}订单`);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize
      });
    }
  }
};