export const Columns = [{
  attrs: {
    prop: 'title',
    label: '项目'
  }
}, {
  attrs: {
    prop: 'adultCount',
    label: '总人数'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '应付'
  }
}, {
  attrs: {
    prop: 'cashPayment',
    label: '现付'
  }
}, {
  attrs: {
    prop: 'alipayECodePay',
    label: '企业码付'
  }
}, {
  attrs: {
    prop: 'totalPayed',
    label: '已付'
  }
}, {
  attrs: {
    prop: 'unPay',
    label: '未付'
  }
}, {
  attrs: {
    prop: 'companyTransfer',
    label: '公司转款'
  }
}, {
  attrs: {
    prop: 'sign',
    label: '挂账'
  }
}];