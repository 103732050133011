import { SwitchStatus, FormAccount, DropzoneBox } from '@/components/common';
import { isPhone } from '@/utils';
import { mapGetters } from 'vuex';
import { formMixin } from '@/mixin';
export default {
  components: {
    SwitchStatus,
    FormAccount,
    DropzoneBox
  },
  mixins: [formMixin],
  data() {
    return {
      visible: false,
      isUpdate: false,
      form: {
        isEnable: true
      },
      validateForm: {
        name: [{
          required: true,
          message: '名字不能为空'
        }, {
          min: 2,
          max: 20,
          message: '名字长度不低于2个字，最多不超过20个字'
        }]
      },
      index: -1
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      // 账户信息
      imageList: 'resource/common/imageList',
      // 照片信息
      imageListNew: 'resource/common/imageListNew',
      // 新上传的照片
      imageListNewDel: 'resource/common/imageDelAddList',
      // 新上传删除的照片
      editAccount: 'resource/account/editAccountInfo',
      // 账户信息
      dictionaryCodeData: 'system/dictionary/dictionaryCodeData' // 字典信息
    }),
    getTitle() {
      return this.isUpdate ? '更新导管' : '添加导管';
    }
  },
  methods: {
    show() {
      // 点击编辑后，并没有确认，直接取消，清理数据
      this.resetForm();
      this.isUpdate = false;
      this.visible = true;
      this.$store.dispatch('resource/account/editAccount', {});
    },
    edit(scope) {
      this.index = scope.$index;
      const row = scope.row;
      this.form = row;
      this.visible = true;
      this.isUpdate = true;
      this.$store.dispatch('resource/account/editAccount', this.form);
      this.$store.dispatch('resource/guideManager/fetchImage', this.form.id);
    },
    hide() {
      this.visible = false;
      this.isUpdate = false;
    },
    selectGuid(val) {
      // this.form.typeLabel = val.label
      this.form.type = val.label;
    },
    handleCreate() {
      if (!this.handleValidate()) return;
      const {
        mobileIphone
      } = this.form;
      if (mobileIphone && mobileIphone != '' && !isPhone(mobileIphone)) {
        this.$bus.tip({
          type: 'warning',
          message: '手机号不正确'
        });
        return;
      }
      const picPaths = this.mergeImage();
      const {
        groupId,
        travelAgencyId,
        realName,
        createUserId
      } = this.userData;
      const userData = {
        groupId,
        travelAgencyId,
        realName,
        createUserId
      };
      const opts = {
        ...this.form,
        ...userData,
        ...this.editAccount,
        picPaths,
        index: this.isUpdate ? this.index : -1
      };
      this.createOrUpdate(opts);
    },
    createOrUpdate(opts) {
      const loading = this.$bus.loading();
      this.$store.dispatch('resource/guideManager/createOrUpdate', opts).then(() => {
        this.$parent.fetchData();
        this.$parent.fetchList();
        this.$bus.tip();
        loading.close();
        this.hide();
      }).catch(() => {
        loading.close();
      });
    },
    resetForm() {
      this.form = {
        isEnable: true
      };
      // 清理图片
      this.$store.commit('resource/common/CLEAR_IMAGE');
      // 清理新增没有id 的图片
      this.$store.dispatch('resource/common/deleteAddImage');
    }
  }
};