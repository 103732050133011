import request from '@/utils/request';
// import { parseQuerys } from '@/utils'

// 日志
export { fetchLog } from './index';

// 列表 不分页
export function fetchList(params) {
  return request({
    url: '/resource/resourceHotel/findList',
    method: 'get',
    params
  });
}
// 列表 不分页 （集团）
export function fetchGroupList(params) {
  return request({
    url: '/resource/resourceHotel/group-objects',
    method: 'get',
    params
  });
}

// 列表 -- 酒店
export function fetchHotel(params) {
  return request({
    url: '/resource/resourceHotel/page',
    method: 'get',
    params
  });
}

// 详情信息
export function fetchHotelDetail(hotelId) {
  const url = `/resource/resourceHotel/getHotel?hotelId=${hotelId}`;
  return request({
    url,
    method: 'get'
  });
}

// 添加或更新
export function createOrUpdateHotel(data) {
  return request({
    url: '/resource/resourceHotel/saveOrUpdate',
    method: 'post',
    data
  });
}

// 删除
export function deleteHotel(id) {
  return request({
    url: '/resource/resourceHotel/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 酒店房型信息 -- 列表
export function fetchHotelRoom(params) {
  // const url = parseQuerys('/resource/resourceHotel/pageRoom', queryParams)
  return request({
    url: '/resource/resourceHotel/pageRoom',
    method: 'get',
    params
  });
}

// 酒店房型信息 -- 添加或更新
export function createOrUpdateHotelRoom(data) {
  // const url = parseQuerys('/resource/resourceHotel/saveOrUpdateRoom')
  return request({
    url: '/resource/resourceHotel/saveOrUpdateRoom',
    method: 'post',
    data
  });
}

// 酒店房型信息 -- 删除
export function deleteHotelRoom(id) {
  // const url = parseQuerys('/resource/resourceHotel/delRoom')
  return request({
    url: '/resource/resourceHotel/delRoom',
    method: 'post',
    data: {
      roomId: id
    }
  });
}