export default {
  props: {
    name: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const date = new Date();
    const printDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return {
      data: null,
      printDate,
      loading: false,
      tableData: [{
        type: '2016-05-02',
        info: '王小虎',
        mondy: '上海市普陀区金沙江路 1518 弄'
      }, {
        type: '2016-05-04',
        info: '王小虎',
        mondy: '上海市普陀区金沙江路 1517 弄'
      }, {
        type: '2016-05-01',
        info: '王小虎',
        mondy: '上海市普陀区金沙江路 1519 弄'
      }, {
        type: '2016-05-03',
        info: '王小虎',
        mondy: '上海市普陀区金沙江路 1516 弄'
      }],
      tableDetail: [{
        text: '上海市普陀区金沙江路 1516 弄'
      }]
    };
  },
  computed: {
    borderStyle() {
      return {
        'border-color': '#000'
      };
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {},
    arraySpanMethod({
      columnIndex
    }) {
      if (columnIndex === 0) {
        return [1, 3];
      } else if (columnIndex === 1) {
        return [0, 0];
      }
    },
    handlePrint() {
      // 把标题去掉
      document.title = '';
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    }
  }
};