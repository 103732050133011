import { defaultSettings } from '@/config/setting';
import { mapGetters } from 'vuex';
export default {
  name: 'Logo',
  data() {
    return {
      title: defaultSettings.title,
      visible: defaultSettings.sidebarLogo,
      logo: ''
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      sidebarLogo: 'settings/sidebarLogo'
    }),
    isShow() {
      return this.sidebarLogo || this.visible;
    },
    getLogo() {
      return this.logo;
    },
    getTitle() {
      return this.userData && this.userData.travelAgency.name || this.title;
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.userData && this.sidebarLogo && this.fetchLogo(this.userData.travelAgency.logoUrl);
      }, 1000);
    });
  },
  methods: {
    fetchLogo(path) {
      this.$store.dispatch('resource/common/getOssImage', path).then(data => {
        this.logo = data ? data[0] : '';
      });
    }
  }
};