import { resourceAccountDetail as columnData } from '@/config/resource';
import { cashLogType, cashFeeTypeLabel, cashModeTypeLabel } from '@/config/billDown';
import SearchCashDiray from '@/views/billDown/cashDiray/components/SearchCashDiray';
import { processMinus } from '@/utils/number';
import { parseDate } from '@/utils';
export default {
  components: {
    SearchCashDiray
  },
  data() {
    return {
      cashLogType,
      cashFeeTypeLabel,
      cashModeTypeLabel,
      visible: false,
      columnData: columnData.slice(0, -1),
      tableData: [],
      total: 0,
      bankId: ''
    };
  },
  methods: {
    processMinus,
    parseDate,
    show(bankId) {
      this.visible = true;
      this.bankId = bankId;
      this.fetchData();
    },
    hide() {
      this.visible = false;
    },
    fetchData(params = {}) {
      this.$store.dispatch('resource/account/fetchDetail', {
        ...params,
        bankId: this.bankId
      }).then(data => {
        if (!data) return;
        this.tableData = data.list;
        this.total = data.totalCount;
      });
    }
  }
};