var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("InputData", _vm._g(_vm._b({
    attrs: {
      data: _vm.getInputList,
      label: _vm.label,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire,
      placeholder: "请选择酒店"
    },
    on: {
      "on-load": _vm.loadMoreData,
      focus: _vm.lazyLoad
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };