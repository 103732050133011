import clipboard from '@/utils/clipboard';
import { getUserData, getEnterpriseLink, parseDate } from '@/utils';
import { processAdd } from '@/utils/number';
import { APPLY_MODE, ENTERPRISE_MODE, ENTERPRISE_RESTYPE, rules, defaultStateQuota, alipayEnterCodeParams } from './config';
export default {
  data() {
    return {
      APPLY_MODE,
      ENTERPRISE_MODE,
      ENTERPRISE_RESTYPE,
      rules,
      alipayEnterCodeParams,
      visible: false,
      form: defaultStateQuota(),
      guideInfo: null,
      content: '',
      isAppend: false
    };
  },
  computed: {
    getRealName() {
      const {
        realName
      } = getUserData();
      return realName;
    },
    getGuideName() {
      return this.guideInfo ? this.guideInfo.name : '';
    },
    teamProfit() {
      return this.$store.getters['plan/index/teamProfit'];
    },
    planInfo() {
      return this.$store.getters['plan/index/planInfo'];
    },
    showInviteBtn() {
      if (!this.guideInfo) return;
      return !this.guideInfo.alipayEcodeStatus;
    },
    getInviteLink() {
      const data = getEnterpriseLink(this.$store);
      return data ? data.value : '';
    }
  },
  beforeCreate() {
    if ((!this.teamProfit || !this.teamProfit.length) && this.$route.query.planId) {
      this.$store.dispatch('plan/index/groupProfit', this.$route.query.planId);
    }
  },
  methods: {
    show() {
      this.visible = true;
      if (this.teamProfit && this.teamProfit.length) {
        const data = this.teamProfit[0];
        if (!data) return;
        if (data.guideReimbTotalInfo) {
          const {
            otherPayReimb
          } = data.guideReimbTotalInfo || {
            otherPayReimb: 0
          };
          const {
            totalGuidePay
          } = data.guideReimbTotalInfo || {
            totalGuidePay: 0
          };
          this.processData({
            totalGuidePay: processAdd(totalGuidePay, otherPayReimb)
          });
        }
      }
      if (this.planInfo) {
        const {
          guides,
          planNumber,
          id: planId,
          startPlanDate,
          endPlanDate: endDate
        } = this.planInfo;
        const today = parseDate();
        const start = new Date(startPlanDate) > new Date(today) ? startPlanDate : today;
        if (guides && guides.length) {
          const {
            id
          } = guides.find(it => it.reimb) || guides[0];
          id && this.fetchGuideInfo(id);
          this.processData({
            planNumber,
            planId,
            startDate: start,
            endDate
          });
        }
      }
    },
    hide() {
      this.visible = false;
      this.form = defaultStateQuota();
    },
    onCopy(event) {
      clipboard(this.getInviteLink, event);
    },
    showAllocation() {
      this.$refs.dialogRef.show(this.alipayEnterCodeParams.standards);
    },
    setStandards(list) {
      this.alipayEnterCodeParams.standards = list;
    },
    fetchGuideInfo(id) {
      return this.$store.dispatch('resource/guide/findById', id).then(data => {
        if (!data) return;
        this.guideInfo = data;
        const {
          name: bankName,
          bank,
          bankCard: bankNo
        } = data;
        this.processData({
          bankName,
          bank,
          bankNo
        });
        this.processContent();
      });
    },
    processContent() {
      const {
        busCompanyReimb,
        hotelReimb,
        restaurantReimb,
        scenicReimb,
        otherPayReimb
      } = this.teamProfit[0].guideReimbTotalInfo;
      const {
        planNumber
      } = this.planInfo;
      const {
        name
      } = this.guideInfo;
      this.content = `团号：${planNumber}, 导游：${name}，房：${hotelReimb},餐：${restaurantReimb},车：${busCompanyReimb},景：${scenicReimb},其他:${otherPayReimb}`;
      const total = processAdd(busCompanyReimb, hotelReimb, restaurantReimb, scenicReimb, otherPayReimb);
      this.form.totalGuidePay = total;
      this.form.details = this.content;
    },
    processData(data = {}) {
      const {
        id: applyUserId,
        travelAgency
      } = getUserData();
      const {
        name
      } = travelAgency;
      this.form = {
        ...this.form,
        ...data,
        applyUserId,
        name
      };
    },
    // 保存
    handleConfirm() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) return;
      const total = this.form.totalGuidePay;
      const opts = {
        ...this.form,
        details: [{
          name: this.content,
          value: total
        }],
        planId: this.$route.query.planId
      };
      const {
        startDate,
        endDate
      } = this.form;
      opts.alipayEnterCodeParams = {
        mode: ENTERPRISE_MODE.PLAN,
        standards: [{
          resType: ENTERPRISE_RESTYPE.DEFAULT,
          value: total
        }],
        startDate,
        endDate
      };
      this.$store.dispatch('plan/guide/dingdingApply', opts).then(() => {
        this.hide();
        this.$bus.tip();
        this.$emit('reload');
      });
    },
    handleInvite() {
      const {
        guides
      } = this.planInfo;
      const guideId = guides[0].id;
      this.$store.dispatch('enterprise/guide/addInvite', {
        guideId
      }).then(res => {
        if (!res) return;
        const {
          signUrl
        } = res.data;
        this.$refs.dialogCopyRef.show(signUrl);
      });
    }
  }
};