import { downloadExport } from '@/utils/exportExcel';
import TableList from './TableList';
import { customData } from '@/config/shopping';
import { BtnExport, SelectCustom } from '@/components/common';
import { subsidyMixin } from '@/mixin';
import { hasCustomeAuth } from '@/utils';
export default {
  components: {
    TableList,
    BtnExport,
    SelectCustom
  },
  mixins: [subsidyMixin],
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: customData,
    startTripDate: [],
    shopDate: [],
    tableData: []
  }),
  computed: {
    visibleCustom() {
      return hasCustomeAuth(this.$store);
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // 搜索
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getByCustomerStatic', queryParam).then(res => {
        // const { list, totalCount } = res
        // this.tableData = res
        this.tableData = this.processTotalShopRebate(res);
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchByCustomerStaticExcel', this.searchParams).then(res => {
        const fileName = '客户统计.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};