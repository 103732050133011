import { createEmailRequest } from '@/api/order/index';
import SplitText from './SplitText';
import permission from '@/directive/permission/index';
import { getAuthByPath } from '@/utils';
export default {
  components: {
    SplitText
  },
  directives: {
    permission
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: this.data.mailAddr
    };
  },
  computed: {
    hasEmail() {
      return this.value != '';
    },
    getEmail() {
      return this.value;
    },
    showBtn() {
      return !this.hasEmail && this.$route.path != '/print-download';
    },
    hasBtn() {
      const currentRole = getAuthByPath(this.$store, '/plan/team-arrange');
      const findItem = currentRole.roleMenuOpts.find(it => it.name == '创建邮箱');
      return findItem && findItem.isRole;
    }
  },
  methods: {
    genEmail() {
      const {
        planId: id
      } = this.$route.query;
      this.$bus.open(() => {
        createEmailRequest({
          id
        }).then(res => {
          if (!res || !res.data) return;
          this.value = res.data.ac;
          this.$bus.tip({});
        }).catch(err => {
          this.$bus.tip({
            message: err.message
          });
        });
      }, '创建后将无法撤回，确定吗？');
    }
  }
};