var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.plans ? _c("InputData", _vm._g(_vm._b({
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      list: _vm.plans,
      vertical: _vm.vertical,
      "is-require": _vm.isRequire
    }
  }, "InputData", _vm.$attrs, false), _vm.$listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };