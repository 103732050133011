import { ticketTrip } from '@/config/ticket';
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      columnData: ticketTrip
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch('ticket/index/fetchTicketTrip');
    }
  }
};