import "core-js/modules/es.array.push.js";
import { downloadExport } from '@/utils/exportExcel';
import TableList from './TableList';
import { shoppingFinanceAll, shoppingFinance } from '@/config/shopping';
import { BtnExport, SelectGuide, SelectStaff, SelectShop, TableTotalBox } from '@/components/common';
import { mergeTableRow } from '@/utils/table';
import { searchMixin, permissionMixin } from '@/mixin';
export default {
  components: {
    SelectGuide,
    TableList,
    BtnExport,
    SelectStaff,
    SelectShop,
    TableTotalBox
  },
  mixins: [searchMixin, permissionMixin],
  data: () => ({
    visibility: false,
    tableLoading: false,
    totalShopPerCapita: 0,
    searchParams: {
      totalBuyMoneyS: '',
      totalBuyMoneyE: ''
    },
    shopDate: [],
    total: 0,
    columnData: [],
    tableData: [],
    statisticData: [],
    statistic: {}
  }),
  computed: {
    financeData() {
      return this.$store.getters['shopping/index/shopFinanceData'];
    }
  },
  watch: {
    financeData: {
      handler: function (data) {
        if (!data) {
          this.fetchData(this.searchParams);
          return;
        }
        this.initData(data);
      },
      immediate: true
    }
  },
  methods: {
    initData(data) {
      this.visibility = false;
      const SubsidyTwo = this.hasSubsidyTwoPrice(); // 二返权限

      this.columnData = SubsidyTwo ? shoppingFinanceAll : shoppingFinance;
      setTimeout(() => {
        this.visibility = true;
      }, 500);
      this.statisticData = [];
      const {
        list,
        totalCount,
        statistics
      } = data;
      this.total = totalCount;
      this.tableData = this.formatData(list);
      this.tableData.map(it => {
        it.totalShopPerCapita = this.totalShopPerCapita;
        it.shopRebate = it.shopRebate + it.subsidy;
        return it;
      });
      this.statistic = statistics;
      this.statisticData.push(statistics);
      this.tableData = mergeTableRow(this.tableData, ['planNumber', 'date', 'guideName', 'enterStoreMemberCount', 'dutyOPUserName', 'totalBuyMoney', 'shopName', 'shopPerCapita', 'orderDate', 'totalHeadRebate', 'shopRebate', 'totalShopRebate']);
    },
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    onSelect(type, val) {
      const {
        label,
        value
      } = val;
      this.searchParams[type + 'Name'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    fetchData(queryParam) {
      this.tableLoading = true;
      this.$store.dispatch('shopping/index/getShopFinanceStatic', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      this.$store.dispatch('shopping/index/fetchShopFinanceStaticExcel', this.searchParams).then(res => {
        const fileName = '购物财务统计.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      const result = [];
      let sum = 0;
      let totalAdultCount = 0;
      const handleFn = (it, index) => {
        const itemList = it.goods || [];
        sum += it.totalBuyMoney;
        totalAdultCount += it.adultCount;
        const item = {
          ...it,
          rowId: index,
          mergeId: index,
          itemIndex: 0
        };
        if (itemList && itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              itemIndex: i,
              itemId: i
            });
          });
        } else {
          result.push(item);
        }
      };
      list.forEach((it, index) => {
        handleFn(it, index);
      });
      this.totalShopPerCapita = sum / totalAdultCount;
      return result;
    }
  }
};