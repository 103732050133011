import { PAY_TYPE, getSelectLabel } from '@/config';
export default {
  name: 'SelectPayType',
  props: {
    row: {
      type: Object,
      required: true
    },
    val: {
      type: String,
      default: 'payType'
    },
    type: {
      type: String,
      default: 'payType'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    // 同步单价与结算价
    syncPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatType() {
      return val => {
        const type = this.type;

        // 处理个别数据返回不同的内容
        val = val == 'alipayecode' ? PAY_TYPE.ALIPAY_E_CODE : val;
        return getSelectLabel(type, val);
      };
    }
  },
  methods: {
    selectPayType(row, val) {
      if (this.val == 'cashType') {
        row.cashType = val.value;
      } else {
        row[this.val] = val.value;
      }

      // 导游现付，单价和结算价应该一致（小程序问题)
      if (this.syncPrice && row.payType == PAY_TYPE.GUIDE) {
        row.settlementPrice = row.price;
      }
    }
  }
};