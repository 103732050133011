import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
/* eslint-disable no-empty-pattern */
import { fetchBorrow, addPlanBorrow, guideApproveRecord, ratifyApply, ratifySave, delBorrow } from '@/api/plan/guide';
import { auditProgressStatus } from '@/config/audit';
import { GUIDE_REIMB_STATUS } from '@/config/guide';
import { processAdd } from '@/utils/number';
import { CasherStatusLabel } from '@/config/billDown/cashStatus';
import { processMinus } from '@/utils/number';
const getStatus = (val, obj) => {
  if (!val) return;
  return obj[val];
};
const formatTable = list => {
  let temp = [];
  list.forEach(cur => {
    const {
      borrows
    } = cur;
    const item = {
      rowId: cur.id,
      applyId: cur.id,
      borrowMoney: 0,
      ...cur
    };
    if (borrows.length) {
      const bList = borrows.map(val => {
        return {
          ...item,
          ...val
        };
      });
      temp = [...temp, ...bList];
    } else {
      temp.push(item);
    }
  });
  return temp;
};
const formatProperty = list => {
  return list.map(it => {
    const {
      auditProgress,
      planStatus,
      borrows
    } = it;
    it.planStatusLabel = getStatus(planStatus, GUIDE_REIMB_STATUS);
    it.auditProgressLabel = getStatus(auditProgress, auditProgressStatus);
    it.totalBorrow = borrows.reduce((acc, cur) => (acc, processAdd(acc, cur.borrowMoney)), 0);
    it.downPayed = it.owePayCopy = processMinus(it.applyMoney, it.totalBorrow);
    it.isChecked = true;
    return it;
  });
};
const dfaultState = () => ({
  data: null,
  list: [],
  curItem: null
});
const state = dfaultState();
const getters = {
  data: state => state.data,
  list: state => state.list,
  total: state => state.data ? state.data.totalCount : 0,
  curItem: state => state.curItem
};
const actions = {
  fetchBorrow({}, params) {
    return fetchBorrow(params).then(res => {
      if (!res || !res.data) return;
      const {
        data
      } = res;
      return data;
    });
  },
  fetchList({
    dispatch,
    commit
  }, params) {
    return dispatch('fetchBorrow', params).then(data => {
      const list = formatTable(data.list);
      data.list = formatProperty(data.list);
      commit('SET_DATA', data);
      commit('SET_LIST', formatProperty(list));
      return data;
    });
  },
  addPlanBorrow({}, opts) {
    return addPlanBorrow(opts);
  },
  // 下账记录
  fetchApproveRecord({}, params) {
    return guideApproveRecord(params).then(res => {
      const {
        data
      } = res;
      const list = data.map(it => {
        // 出纳确认状态
        const {
          cashierAuditStatus: cs
        } = it.cash;
        const cashierAuditStatusLabel = CasherStatusLabel[cs.toUpperCase()];
        return {
          ...it,
          cashierAuditStatusLabel
        };
      });
      return list;
    });
  },
  // 批准下账借款申请
  ratifyApply({}, opt) {
    return ratifyApply(opt).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 导游借款 修改与新增（批准记录）
  ratifySet({}, opt) {
    return ratifySave(opt).then(res => {
      const {
        data
      } = res;
      return data;
    });
  },
  // 删除导游借款
  delBorrow({}, ids) {
    return delBorrow(ids);
  }
};
const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
  SET_LIST: (state, data) => {
    state.list = data;
  },
  CUR_ITEM: (state, item) => {
    state.curItem = item;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};