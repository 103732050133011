import request, { requestBlob } from '@/utils/request';
import { APP } from '@/config';

// 回执单
export function fetchReceipt(id) {
  return request({
    url: '/order/report/returnReceipt',
    method: 'get',
    params: {
      id
    }
  });
}
// 导出回执单
export function fetchExportReceipt(params) {
  const url = `${APP.baseUrl}/order/report/exportReturnReceipt`;
  return requestBlob({
    url,
    params
  });
}
// -------------------------- 计划 ---------------------- //
// 团队计划单
export function fetchTeamPlan(params) {
  return request({
    url: '/order/report/returnPlanTeam',
    method: 'get',
    params
  });
}

// 散客计划单
export function fetchPersonalPlan(planId) {
  return request({
    url: '/order/report/returnPlanCustomer',
    method: 'get',
    params: {
      planId
    }
  });
}

// 司机通知单
export function fetchDriverNotice(params) {
  return request({
    url: '/order/orderBus/planDriver',
    method: 'get',
    params
  });
}

// 购物统计表
export function fetchShopping(planId) {
  return request({
    url: '/order/report/shopStatistic',
    method: 'get',
    params: {
      planId
    }
  });
}

// 散客
export function fetchPersonal(planId) {
  return request({
    url: '/order/report/sMemberlist',
    method: 'get',
    params: {
      planId
    }
  });
}

// 团队
export function fetchTeam(planId) {
  return request({
    url: '/order/report/groupMemberlist',
    method: 'get',
    params: {
      planId
    }
  });
}

// 保险
export function fetchTourist(params) {
  return request({
    url: '/order/report/planMemberlist',
    method: 'get',
    params
  });
}

// 导游分佣
export function fetchGuideSubCommission(planId) {
  return request({
    url: '/order/report/guideSubCommission',
    method: 'get',
    params: {
      planId
    }
  });
}

// 散客客人名单导出
export function fetchPersonalMemberlist(planId) {
  const url = `${APP.baseUrl}/order/report/exportSMemberlist`;
  return requestBlob({
    url,
    params: {
      planId
    }
  });
}

// 散客计划单导出
export function fetchPersonalCustomer(planId) {
  const url = `${APP.baseUrl}/order/report/exportPlanCustomer`;
  return requestBlob({
    url,
    params: {
      planId
    }
  });
}

// 团队客人名单导出
export function fetchTeamMemberlist(planId) {
  const url = `${APP.baseUrl}/order/report/exportGroupMemberlist`;
  return requestBlob({
    url,
    params: {
      planId
    }
  });
}

// 团队计划单导出
export function fetchExportPlanTeam(params) {
  const url = `${APP.baseUrl}/order/report/exportPlanTeam`;
  return requestBlob({
    url,
    params
  });
}

// 导出 保险名单/ 计划游客名单 表
export function fetchExportPlanMemberlist(planId) {
  const url = `${APP.baseUrl}/order/report/exportPlanMemberlist`;
  return requestBlob({
    url,
    params: {
      planId
    }
  });
}

// 导出导游分佣表
export function fetchExportGuideSubCommission(params) {
  const url = `${APP.baseUrl}/order/report/exportGuideSubCommission`;
  return requestBlob({
    url,
    params
  });
}

// 客人接送表
export function fetchShuttleBy(planId) {
  return request({
    url: '/order/report/getShuttleByPlanId',
    method: 'get',
    params: {
      planId
    }
  });
}

// 单团核算利润统计
export function fetchPlanProfitReport(params) {
  return request({
    url: '/order/report/planProfitReport',
    method: 'get',
    params
  });
}

// 导出单团核算利润统计表
export function fetchExportPlanProfitReport(params) {
  const url = `${APP.baseUrl}/order/report/exportPlanProfitReport`;
  return requestBlob({
    url,
    params
  });
}