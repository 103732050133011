// 不判断权限的url 父级
export const requireAuthUrl = [
// 团队输单
{
  path: '/order/team-input-detail',
  pPath: '/order/team'
}, {
  path: '/order/team-input-create',
  pPath: '/order/team'
}, {
  path: '/order/team-input-update',
  pPath: '/order/team'
}, {
  path: '/order/team-input-clone',
  pPath: '/order/team'
},
// 散客输单
{
  path: '/order/personal-input-update',
  pPath: '/order/personal'
}, {
  path: '/order/personal-input-create',
  pPath: '/order/personal'
}, {
  path: '/order/personal-input-detail',
  pPath: '/order/personal'
}, {
  path: '/order/personal-input-clone',
  pPath: '/order/personal'
},
// 计调操作
{
  path: '/plan/team-arrange-edit/guide',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/scenic',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/hotel',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/restaurant',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/bus',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/insurance',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/transport',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/shuttle',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/borrow',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/collect',
  pPath: '/plan/team-arrange'
}, {
  path: '/plan/team-arrange-edit/shop',
  pPath: '/plan/team-arrange'
},
// 审核管理
{
  path: '/audit/team-single-edit/fee',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/guide',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/scenic',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/hotel',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/restaurant',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/bus_company',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/insurance',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/transport',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/shuttle',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/guide_borrow',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/collect',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/add_scenic',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/out_fee',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/shop',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/other_income',
  pPath: '/audit/team-single'
}, {
  path: '/audit/team-single-edit/other_pay',
  pPath: '/audit/team-single'
},
// 购物管理--购物收款
{
  path: '/shopping/shop-detail',
  pPath: '/shopping/shop-collect'
},
// 销账管理
{
  path: '/bill/unit-collect/detail',
  pPath: '/bill/unit-collect'
}, {
  path: '/bill/unit-pay/scenic-detail',
  pPath: '/bill/unit-pay/scenic'
}, {
  path: '/bill/unit-pay/hotel-detail',
  pPath: '/bill/unit-pay/hotel'
}, {
  path: '/bill/unit-pay/hotel-detail',
  pPath: '/bill/unit-pay/hotel'
}, {
  path: '/bill/unit-pay/fleet-detail',
  pPath: '/bill/unit-pay/fleet'
}, {
  path: '/bill/unit-pay/restaurant-detail',
  pPath: '/bill/unit-pay/restaurant'
}, {
  path: '/bill/unit-pay/restaurant-detail',
  pPath: '/bill/unit-pay/restaurant'
}, {
  path: '/bill/unit-pay/traffic-detail',
  pPath: '/bill/unit-pay/traffic'
}, {
  path: '/bill/unit-pay/insurance-detail',
  pPath: '/bill/unit-pay/insurance'
}, {
  path: '/bill/unit-pay/reception-detail',
  pPath: '/bill/unit-pay/reception'
}, {
  path: '/bill/guide-audit-detail',
  pPath: '/bill/guide-audit'
}, {
  path: '/bill/guide-audit-detail',
  pPath: '/bill/guide-audit'
},
// 资源管理
{
  path: '/resource/hotel-edit',
  pPath: '/resource/hotel'
}, {
  path: '/resource/hotel-create',
  pPath: '/resource/hotel'
}, {
  path: '/resource/product',
  pPath: '/resource/hotel'
}];