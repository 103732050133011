import { downloadExport } from '@/utils/exportExcel';
import { planCustomanalyzeData } from '@/config/shopping';
import TableList from './TableList';
import { searchMixin, subsidyMixin } from '@/mixin';
import { hasCustomeAuth } from '@/utils';
import { SelectProduct, SelectLine, SelectStaff, SelectCustom } from '@/components/common';
export default {
  components: {
    SelectLine,
    SelectStaff,
    TableList,
    SelectCustom,
    SelectProduct
  },
  mixins: [searchMixin, subsidyMixin],
  data: () => ({
    tableLoading: false,
    total: 0,
    columnData: planCustomanalyzeData,
    shopDate: [],
    tableData: [],
    searchParams: {}
  }),
  computed: {
    visibleCustom() {
      return hasCustomeAuth(this.$store);
    },
    teamSmallData() {
      return this.$store.getters['shopping/index/teamSmallData'];
    }
  },
  watch: {
    teamSmallData: {
      handler: function (data) {
        if (!data) {
          this.fetchData();
          return;
        }
        this.handleData(data);
      },
      immediate: true
    }
  },
  methods: {
    handleData(data) {
      const {
        list,
        totalCount
      } = data;
      this.total = totalCount;
      // 重组头部
      // this.columnData = this.formatHead(list)
      // 格式化数组
      const tableData = this.formatData(list);
      this.tableData = this.processTotalShopRebate(tableData);
    },
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // 搜索
    // handleSearch() {
    //   this.fetchData(this.searchParams)
    // },

    // 翻页
    handlePage({
      pageNum,
      pageSize
    }) {
      this.fetchData({
        pageNum,
        pageSize,
        ...this.searchParams
      });
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    fetchData(queryParam) {
      this.$store.dispatch('shopping/index/getOrderShopStatic', queryParam).then(() => {
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
      });
    },
    formatData(list) {
      if (!list || !list.length) return [];
      return list.map(it => {
        it.shopPerCapita = it.shopProfit.shopPerCapita;
        it.totalBuyMoney = it.shopProfit.totalBuyMoney;
        it.totalShopRebate = it.shopProfit.totalShopRebate;
        const itemList = it.shops;
        if (itemList && itemList.length) {
          itemList.forEach((its, index) => {
            it[`shopPerCapita${index}`] = its.shopPerCapita;
            it[`totalBuyMoney${index}`] = its.totalBuyMoney;
            it.shopId = its.id;
            it.shopName = its.name;
          });
        }
        return it;
      });
    },
    exportExcel() {
      const loading = this.$bus.loading();
      const opts = {
        ...this.searchParams,
        searchType: 3
      };
      this.$store.dispatch('shopping/index/fetchOrderShopStaticExcel', opts).then(res => {
        const fileName = '单客户数据分析表.xlsx';
        loading.close();
        downloadExport(res, fileName);
      });
    }
  }
};