var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "input-box",
    class: {
      vertical: _vm.vertical
    }
  }, [_c("LabelBase", {
    attrs: {
      label: _vm.label,
      "is-require": _vm.isRequire
    }
  }), _c("el-input-number", _vm._g(_vm._b({}, "el-input-number", _vm.$attrs, false), _vm.$listeners)), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };