import "core-js/modules/es.array.push.js";
import { searchMixin } from '@/mixin';
import { planOneKeyup } from '@/config/plan/oneKeyup';
import { Guides, BtnLink, IconWrong, CustomerInfo } from '@/components/common';
import { PersonCount } from '@/components/common';
import PlanSearch from '@/views/plan/components/PlanSearch';
import { isUploadBoat, getCountryEtravelInfo } from './helper';
import { parseDate, hasCustomeAuth, filterCustomColumn } from '@/utils';
import { CancelType, BusinessCancelType, Status } from './tourageCountry/enums';
import { cancelPlanRequest, planTeamStatusRequest } from '@/api/plan/tourageCountry';
export default {
  components: {
    PlanSearch,
    PersonCount,
    Guides,
    BtnLink,
    IconWrong,
    CustomerInfo
  },
  mixins: [searchMixin],
  data() {
    return {
      columnData: [],
      searchParams: {},
      countryEtravelInfo: null
    };
  },
  computed: {
    sysConfig() {
      return this.$store.getters['system/buttJoint/GLETravelData'];
    },
    planData() {
      return this.$store.getters['plan/oneKeyup/planData'];
    },
    tableData() {
      return this.planData ? this.planData.list : [];
    },
    total() {
      return this.planData ? this.planData.totalCount : 0;
    },
    // 判断是否已经上传过兴坪船，旅检那边用了contractNo字段，这里只能用|来判断
    isUploaded() {
      return ({
        contractNo
      }) => isUploadBoat(contractNo);
    },
    overSendDate() {
      return row => {
        return new Date(row.startPlanDate).getTime() >= new Date(parseDate()).getTime();
      };
    }
  },
  created() {
    const visibleCustom = hasCustomeAuth(this.$store);
    this.columnData = visibleCustom ? planOneKeyup : filterCustomColumn(planOneKeyup);
  },
  mounted() {
    const params = this.$refs.searchRef.searchParams;
    !this.planData && this.fetchData(params);
    const {
      isBack
    } = this.$route.query;
    isBack && this.fetchData(params);
    this.fetchSysConfig();
  },
  methods: {
    fetchData(params = {}) {
      this.$store.dispatch('plan/oneKeyup/fetchPlan', {
        ...this.searchParams,
        ...params
      });
    },
    // 获取旅行社配置
    fetchSysConfig(params = {}) {
      this.$store.dispatch('system/buttJoint/getConfig', params).then(res => {
        if (!res || !res.data) return;
        this.countryEtravelInfo = getCountryEtravelInfo(res.data);
      });
    },
    reload() {
      const params = this.$refs.searchRef.searchParams;
      this.fetchData(params);
    },
    handlePage({
      pageNum,
      pageSize
    }) {
      this.handleChangePage({
        pageNum,
        pageSize
      });
    },
    onSearch(params) {
      this.searchParams = params;
      this.handleSearch();
    },
    linkDetail(row) {
      const {
        path
      } = this.$route;
      const toPath = path;
      const {
        id,
        planType
      } = row;
      this.$store.commit('plan/index/FIRST_ENTER', true);
      this.$store.commit('plan/index/CUR_PLAN_ID', id);
      this.$router.push({
        path: '/plan/team-arrange-detail',
        query: {
          id,
          planType,
          toPath
        }
      });
    },
    uploadTravel(row, isUpdate) {
      const {
        id,
        planNumber
      } = row;
      this.$router.push({
        name: 'planTeamArrangeTravel',
        query: {
          id,
          planNumber,
          isUpdate
        }
      });
    },
    // 上传全国旅游行程单
    uploadTourGroup(row, isUpdate = false) {
      const {
        id,
        planNumber,
        signId,
        contractNo,
        otaGuilinSupervisePlanGuid: ota
      } = row;
      this.$router.push({
        name: 'planTeamArrangeTravelCountry',
        query: {
          id,
          planNumber,
          signId,
          contractNo,
          ota,
          isUpdate
        }
      });
    },
    cleanStatus(row) {
      const {
        id: planId
      } = row;
      this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        otaGuilinSupervisePlanGuid: ''
      });
    },
    statusTourGroup(row) {
      // 许可证号
      const {
        value2
      } = this.countryEtravelInfo;
      planTeamStatusRequest({
        agency_license: value2,
        team_id: row.planNumber
      }).then(res => {
        if (res && res.error_code == 0) {
          const {
            groupNumber,
            status
          } = res.data;
          this.$bus.tip({
            message: `${groupNumber}状态为: ${Status[status]}`,
            duration: 5000
          });
        }
      });
    },
    // 取消全国旅游行程单
    cancelTourGroup(row) {
      const {
        planNumber
      } = row;
      // 许可证号
      const {
        value2
      } = this.countryEtravelInfo;
      this.$bus.open(() => {
        const param = {
          team_id: planNumber,
          agency_license: value2 || '',
          cancel_desc: '修改，重新上传',
          cancel_date: parseDate(),
          cancel_type: CancelType.TRAVEL,
          business_type: BusinessCancelType.RECEPTION
        };
        cancelPlanRequest(param).then(res => {
          if (res.error_code === 0) {
            this.$bus.tip({
              message: '取消成功'
            });
            this.cleanStatus(row);
            this.reload();
          }
        });
      }, '确定要取消吗？');
    },
    printTravel({
      planNumber
    }) {
      let routeUrl = this.$router.resolve({
        path: '/print-supervise-travel',
        query: {
          name: 'SuperviseTravel',
          planNumber
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    boatUpload(row) {
      const {
        id,
        contractNo,
        guides
      } = row;
      let guide = '';
      if (guides.length) {
        const cur = guides.find(it => it.reimb) || guides[0];
        guide = cur.name;
      }
      this.$router.push({
        path: '/plan/onekeyup-boat',
        query: {
          id,
          contractNo,
          guide
        }
      });
    },
    boatDetail(row) {
      const {
        id,
        contractNo
      } = row;
      const guide = contractNo.split('|')[0];
      this.$router.push({
        path: '/plan/onekeyup-boat-detail',
        query: {
          id,
          guide
        }
      });
    },
    setStatus(planId) {
      this.$store.dispatch('plan/superviseTravel/setStatus', {
        planId,
        contractNo: ''
      });
    }
  }
};