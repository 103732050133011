import "core-js/modules/es.array.reduce.js";
import { tableMixin } from '@/mixin';
import { SwitchStatus } from '@/components/common';
export default {
  components: {
    SwitchStatus
  },
  mixins: [tableMixin],
  methods: {
    getItemContent(row) {
      const {
        contracts
      } = row;
      let str = '';
      if (!contracts.length) return str;
      str = contracts.reduce((acc, cur) => {
        const {
          contractPrices
        } = cur;
        const val = contractPrices.reduce((pre, it) => {
          pre += `${it.contentType} `;
          return pre;
        }, '');
        acc += val;
        return acc;
      }, '');
      return str;
    }
  }
};