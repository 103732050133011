import { SelectProduct, SelectStaff, SelectCustom, SelectGuide, SelectDictionary } from '@/components/common';
// import { getSelectLabel } from '@/config/select'
// import { getCurTagView } from '@/utils/tagView'
import { parseDate } from '@/utils';
import { searchMixin } from '@/mixin';
export default {
  components: {
    SelectProduct,
    SelectStaff,
    SelectCustom,
    SelectGuide,
    SelectDictionary
  },
  mixins: [searchMixin],
  data() {
    return {
      planStatusLabel: '',
      sendDate: [parseDate()],
      endDate: [],
      searchParams: {
        startTripDateS: parseDate(new Date())
      }
    };
  },
  // computed: {
  //   getTagViewSearch() {
  //     const curTag = getCurTagView()
  //     return curTag ? curTag.search : this.searchParams
  //   },
  // },

  // watch: {
  //   getTagViewSearch: {
  //     handler: function (data) {
  //       if (!data) return
  //       const { planStatus } = data
  //       const { startTripDateS, startTripDateE } = data
  //       const { endTripDateS, endTripDateE } = data

  //       this.searchParams = data
  //       this.sendDate = [startTripDateS, startTripDateE] // 发团日期
  //       this.endDate = [endTripDateS, endTripDateE] // 散团日期
  //       this.planStatusLabel = getSelectLabel('groupStatus', planStatus)
  //     },
  //     immediate: true,
  //   },
  // },

  methods: {
    changeProduct(val) {
      this.searchParams.productId = val.value;
      this.searchParams.productName = val.name;
    },
    //发团日期 选择开始-结束日期
    onChangeDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.startTripDateS = startDate;
      this.searchParams.startTripDateE = endDate;
    },
    //散团日期 选择开始-结束日期
    changeEndDate(date) {
      const [startDate, endDate] = date;
      this.searchParams.endTripDateS = startDate;
      this.searchParams.endTripDateE = endDate;
    },
    changeDutyOPUserName(val) {
      this.searchParams.dutyOPUserId = val.value;
    },
    changeOPUserName(val) {
      this.searchParams.outOPUserId = val.value;
    },
    selectGuideName(val) {
      this.searchParams.guideName = val.label;
    },
    selectGroupStatus(val) {
      val = val ? val : {
        label: '',
        value: ''
      };
      this.searchParams.planStatus = val.value;
    },
    changeCustom(val) {
      const {
        name
      } = val;
      this.searchParams.customerName = name;
    }
  }
};