import { render, staticRenderFns } from "./SelectHotelGroup.vue?vue&type=template&id=f897d234"
import script from "./SelectHotelGroup.vue?vue&type=script&lang=js"
export * from "./SelectHotelGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f897d234')) {
      api.createRecord('f897d234', component.options)
    } else {
      api.reload('f897d234', component.options)
    }
    module.hot.accept("./SelectHotelGroup.vue?vue&type=template&id=f897d234", function () {
      api.rerender('f897d234', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/Select/SelectHotelGroup.vue"
export default component.exports