import "core-js/modules/es.array.push.js";
import { tagViewMixin } from '@/mixin';
import { FooterBar, BillInput } from '@/components/common';
import { calcTotalMoney } from '../../help';
import TableUnPay from '../components/TableUnPay.vue';
import SearchUnPay from '../components/SearchUnPay';
export default {
  components: {
    TableUnPay,
    FooterBar,
    BillInput,
    SearchUnPay
  },
  mixins: [tagViewMixin],
  data() {
    return {
      backPath: '/bill/guide-borrow',
      form: {},
      resourceId: '',
      searchParams: {}
    };
  },
  computed: {
    tableData() {
      return this.$store.getters['bill/guideBorrow/list'];
    }
  },
  watch: {
    tableData: {
      handler: function (list) {
        const money = calcTotalMoney(list, 'applyMoney');
        this.form.money = money;
        this.form.userMoney = money;
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      return this.$store.dispatch('bill/guideBorrow/fetchList', params);
    },
    handleSearch(params) {
      this.searchParams = params;
      this.fetchData(params);
    },
    changeMoney(money) {
      this.money = money;
      this.$refs.unPayRef.caclDownMoney(money);
    },
    handleSave() {
      // const item = {
      //   "applyId": 0,
      //   "bankId": 0,
      //   "cashAction": "",
      //   "cashDate": "",
      //   "cashMode": "",
      //   "cashRemarks": "",
      //   "cashUserId": 0,
      //   "cashWay": "",
      //   "invoiceMoney": 0,
      //   "invoiceNumber": "",
      //   "money": 0,
      //   "planId": 0,
      //   "psubjectId": 0,
      //   "remarks": "",
      //   "subjectId": 0,
      //   "travelAgencyId": 0
      // }

      // const { bankId, cashAction, cashDate, cashMode, remarks } = this.$refs.formRef.form
      // const { appId, bankId, cashAction, cashDate, cashMod, remarks: cashRemarks, cashUserId, cashWay, invoiceMoney } = form
      const form = this.$refs.formRef.form;
      const optList = this.tableData.filter(it => it.isChecked).map(it => {
        const {
          rowId,
          downPayed
        } = it;
        return {
          applyId: rowId,
          ...form,
          money: downPayed
        };
      });
      this.$store.dispatch('bill/guideBorrow/ratifyApply', optList).then(() => {
        this.$bus.tip();
        this.handleClose();
        this.goBack();
      });
    },
    // 关闭
    handleClose() {
      this.closeSelectedTag();
    },
    // 返回列表
    goBack() {
      this.$router.push({
        path: this.backPath,
        query: {
          isBack: true
        }
      });
    }
  }
};