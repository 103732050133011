var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("FooterBar", [_c("BtnBase", {
    attrs: {
      type: "save"
    },
    on: {
      click: () => _vm.$emit("on-save")
    }
  }, [_vm._v(" 确认对账 ")]), _c("BtnBase", {
    attrs: {
      type: "close"
    },
    on: {
      click: () => _vm.$emit("on-close")
    }
  }, [_vm._v(" 关闭 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };