export default {
  name: 'CollapseBase',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '更多条件搜索'
    },
    isStrong: {
      type: Boolean,
      default: false
    },
    autoHide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeNames: ['1']
    };
  },
  created() {
    this.autoHide && this.hide();
  },
  methods: {
    hide() {
      this.activeNames = [''];
    }
  }
};