var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.isMobile && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _c("HeadNav"), _c("Sidebar", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isDashboard,
      expression: "!isDashboard"
    }],
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_vm.needTagsView ? _c("TagsView") : _vm._e(), _vm.isMobile ? _c("el-button", {
    staticClass: "pos-a",
    staticStyle: {
      right: "4px",
      top: "4px"
    },
    attrs: {
      plain: ""
    },
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_vm._v(" 退出 ")]) : _vm._e()], 1), _c("AppMain"), _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()], 1), _vm.showRefresh ? _c("Refresh") : _vm._e(), _c("BackToTop"), _vm.isDashboard ? _c("NoticeDialog", {
    ref: "noticeRef"
  }) : _vm._e(), _c("DialogRenew", {
    ref: "paymentRef"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };