import service, { requestBlob } from '@/utils/request';
import { getExportUrl } from '@/utils';

// 下载客户kpi模版
export function customerKpiTemplateDownloadRequest(params) {
  const url = getExportUrl('/order/customer-kpi/download-template');
  return requestBlob({
    url,
    params
  });
}

// 导入客户目标
export function customerKpiTemplateImportRequest(data) {
  return service({
    url: '/order/customer-kpi/import-customer-kpi',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}