/* eslint-disable no-unused-vars */
import { fetchLog, fetchList, fetchFleet, deleteFleet, fetchGroupList, createOrUpdateFleet } from '@/api/resource/fleet';
import { TYPES, DICTYPES } from '@/config/resource';
import { getTravelSuperviseeDic } from '@/api/resource/index';
import { formatCharData, formatDicData, duplicateData, hasMoreData } from '@/utils/data';
import { DEFAULT_PAGE } from '@/config';
const dfaultState = () => ({
  fleetData: null,
  fleetGroupData: null,
  fleetDicData: null,
  fleetDataOrigin: null,
  fleetLog: null,
  fleetAgreement: null
});
const state = dfaultState();
const getters = {
  fleetData: state => state.fleetData,
  fleetGroupData: state => state.fleetGroupData,
  fleetDicData: state => state.fleetDicData,
  fleetDataOrigin: state => state.fleetDataOrigin,
  fleetLog: state => state.fleetLog,
  fleetAgreement: state => state.fleetAgreement
};
const actions = {
  fetch({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      ...queryParam
    };
    return fetchFleet(queryParam).then(res => {
      const {
        data
      } = res;
      commit('FETCH_DATA_ORIGIN', data);
      return data;
    });
  },
  // 不分页列表
  fetchList({
    commit
  }, queryParam) {
    return fetchList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_DATA', data);
      return data;
    });
  },
  // 不分页列表
  fetchGroupList({
    commit
  }, queryParam) {
    return fetchGroupList(queryParam).then(res => {
      let {
        data
      } = res;
      data = formatCharData(data);
      commit('FETCH_GROUP_DATA', data);
      return data;
    });
  },
  fetchLog({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.bus
    };
    return fetchLog(opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_LOG', data);
      return data;
    });
  },
  fetchImage({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.bus
    };
    return this.dispatch('resource/common/editImage', opts);
  },
  fetchAgreement({
    commit
  }, resourceId) {
    const opts = {
      resourceId,
      resourceType: TYPES.bus
    };
    this.dispatch('resource/common/fetchAgreement', opts).then(res => {
      const {
        data
      } = res;
      commit('FETCH_AGREEMENT', data);
    });
  },
  addAgreementItem({
    dispatch
  }, opts) {
    this.dispatch('resource/common/fetchAgreement', opts);
  },
  createOrUpdate({
    commit
  }, opts) {
    // 删除图片操作
    this.dispatch('resource/common/deleteCacheImage');
    return createOrUpdateFleet(opts);
  },
  delete({
    commit,
    dispatch
  }, id) {
    dispatch('fetchImage', id).then(data => {
      const delFiles = data.map(it => it.path);
      if (!delFiles.length) return;
      this.dispatch('resource/common/deleteImage', delFiles);
    });
    return deleteFleet(id);
  },
  // 查询旅监局车辆字典
  getTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.car
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      const originData = {
        ...data
      };
      let {
        list
      } = data;
      const hasMore = hasMoreData(data);
      const lastList = state.fleetDicData ? state.fleetDicData.list : [];

      // 处理标题，对应添加拼音, 合并上次的数据
      list = [...formatDicData(list, 'fleet'), ...lastList];
      list = duplicateData(list);
      data.list = list;
      data.hasMore = hasMore;
      commit('FETCH_DATA_CARDIC', data);
      return originData;
    });
  },
  // 查询旅监导游字典名字搜搜
  searchTravelSuperviseeDic({
    commit
  }, queryParam) {
    queryParam = {
      ...DEFAULT_PAGE,
      pageSize: 50,
      ...queryParam,
      type: DICTYPES.car
    };
    return getTravelSuperviseeDic(queryParam).then(res => {
      let {
        data
      } = res.data;
      data.list = formatDicData(data.list, 'fleet');
      commit('FETCH_DATA_CARDIC', data);
      return data;
    });
  }
};
const mutations = {
  FETCH_DATA: (state, data) => {
    state.fleetData = data;
  },
  FETCH_GROUP_DATA: (state, data) => {
    state.fleetGroupData = data;
  },
  FETCH_DATA_CARDIC: (state, data) => {
    state.fleetDicData = data;
  },
  FETCH_DATA_ORIGIN: (state, data) => {
    state.fleetDataOrigin = data;
  },
  FETCH_LOG: (state, data) => {
    state.fleetLog = data;
  },
  FETCH_AGREEMENT: (state, data) => {
    state.fleetAgreement = data;
  },
  UPDATE: (state, data) => {
    const {
      index
    } = data;
    if (index && index != -1) {
      state.fleetData.list.splice(index, 1);
    }
  },
  DELETE: (state, id) => {
    const list = state.fleetData.list.filter(it => it.id !== id);
    state.fleetData.list = list;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};