var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "notice-dialog",
    attrs: {
      title: _vm.getTitle,
      width: "760px",
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate,
      cancel: _vm.handleCancel
    }
  }, [_vm.form ? _c("div", {
    staticClass: "source-form"
  }, [_c("h3", {
    staticStyle: {
      "margin-top": "0"
    }
  }, [_vm._v(" 公司信息 ")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "单位名称",
      "is-require": ""
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "纳税人识别码",
      "is-require": ""
    },
    model: {
      value: _vm.form.idCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "idCode", $$v);
      },
      expression: "form.idCode"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "开户银行"
    },
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", $$v);
      },
      expression: "form.bankName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "银行账户"
    },
    model: {
      value: _vm.form.bankNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankNumber", $$v);
      },
      expression: "form.bankNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "注册电话"
    },
    model: {
      value: _vm.form.registerPhoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registerPhoneNumber", $$v);
      },
      expression: "form.registerPhoneNumber"
    }
  }), _c("InputBase", {
    attrs: {
      label: "注册地址"
    },
    model: {
      value: _vm.form.registerAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registerAddress", $$v);
      },
      expression: "form.registerAddress"
    }
  })], 1), _c("h3", [_vm._v("收件人信息")]), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "收件人"
    },
    model: {
      value: _vm.form.postName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "postName", $$v);
      },
      expression: "form.postName"
    }
  }), _c("InputBase", {
    attrs: {
      label: "收件人电话"
    },
    model: {
      value: _vm.form.postPhoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "postPhoneNumber", $$v);
      },
      expression: "form.postPhoneNumber"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      label: "收件人地址"
    },
    model: {
      value: _vm.form.postAddress,
      callback: function ($$v) {
        _vm.$set(_vm.form, "postAddress", $$v);
      },
      expression: "form.postAddress"
    }
  })], 1), _c("CheckboxBase", {
    attrs: {
      label: "是否设为默认"
    },
    model: {
      value: _vm.form.isDefault,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isDefault", $$v);
      },
      expression: "form.isDefault"
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };