var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.data ? _c("div", {
    staticClass: "resourch-order-hotel-info"
  }, [_c("h3", [_vm._v("订单信息")]), _c("div", {
    staticClass: "form-wrapper"
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "景区"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.getData[0], "scenicName")
    }
  })], 1)], 1), _c("div", {
    staticClass: "input-box"
  }, [_c("LabelBase", {
    attrs: {
      label: "联系人"
    }
  }), _c("TextBase", [_c("ModifyItem", {
    attrs: {
      item: _vm.getContent(_vm.getData[0], "scenicContact")
    }
  })], 1)], 1)])]), _c("div", {
    staticClass: "table-content"
  }, [_c("TableBase", {
    attrs: {
      data: _vm.getData,
      "column-data": _vm.columnData,
      "hide-bar": true
    },
    scopedSlots: _vm._u([{
      key: "orderDate",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "orderDate")
          }
        })];
      }
    }, {
      key: "ticketType",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "ticketType")
          }
        })];
      }
    }, {
      key: "price",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "price")
          }
        })];
      }
    }, {
      key: "count",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "count")
          }
        })];
      }
    }, {
      key: "freeCount",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "freeCount")
          }
        })];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("ModifyItem", {
          attrs: {
            item: _vm.getContent(scope.row, "remarks")
          }
        })];
      }
    }], null, false, 2403443881)
  })], 1), _c("div", {
    staticClass: "input-field mt20"
  }, [_c("div", {
    staticClass: "input-box"
  }, [_c("TextBase", [_vm._v(" 应付 ")]), _c("TextBase", [_c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.data.totalPay))])])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };