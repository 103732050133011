import "core-js/modules/es.array.push.js";
import { inputMixin } from '@/mixin';
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    busId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      list: []
    };
  },
  watch: {
    busId: {
      handler: 'fetchData'
    }
  },
  methods: {
    fetchData(id) {
      if (id) {
        this.$store.dispatch('resource/driver/fetch', {
          pageNum: 1,
          pageSize: 100,
          busCompanyId: id
        }).then(data => {
          const {
            list
          } = data;
          this.list = this.formartList(list);
        });
      }
    },
    // initData(data) {
    //   if (!data) return
    //   this.list = this.formartList(data.list)
    // },

    formartList(list) {
      const result = [];
      list.forEach(it => {
        if (it.isEnable) {
          const item = {};
          item.id = it.id;
          item.value = it.name;
          item.slug = it.slug;
          result.push(item);
        }
      });
      return result;
    }
  }
};