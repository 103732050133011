var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DialogBase", {
    staticClass: "order-product-dialog",
    attrs: {
      title: "修改人数",
      visible: _vm.visible,
      "append-to-body": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      },
      confirm: _vm.handleCreate
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputCustom", {
    attrs: {
      label: "请选择客户"
    },
    on: {
      "on-select": _vm.handleSelect
    },
    model: {
      value: _vm.customerName,
      callback: function ($$v) {
        _vm.customerName = $$v;
      },
      expression: "customerName"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };