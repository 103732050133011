var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-wrapper footer-pb80",
    staticStyle: {
      "max-width": "960px"
    }
  }, [_c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      "is-require": "",
      label: "报价名称"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c("SelectCustom", {
    attrs: {
      label: "客户",
      "is-require": ""
    },
    on: {
      "on-select": _vm.changeCustom
    },
    model: {
      value: _vm.form.customerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "customerName", $$v);
      },
      expression: "form.customerName"
    }
  })], 1), _c("div", {
    staticClass: "input-field"
  }, [_c("InputBase", {
    attrs: {
      "is-require": "",
      label: "人数"
    },
    model: {
      value: _vm.form.adultCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adultCount", $$v);
      },
      expression: "form.adultCount"
    }
  }), _c("InputDateRange", {
    attrs: {
      "is-require": "",
      "default-date": _vm.sendDate,
      label: "行程日期"
    },
    on: {
      "on-change": _vm.onChangeDate
    }
  })], 1), _c("div", {
    attrs: {
      id: "share-element"
    }
  }, [_c("div", [_c("TableScenic", {
    ref: "scenicRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.scenicDetails
    }
  }), _c("InputBase", {
    attrs: {
      label: "备注："
    },
    model: {
      value: _vm.form.scenicRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "scenicRemarks", $$v);
      },
      expression: "form.scenicRemarks"
    }
  })], 1), _c("div", [_c("TableHotel", {
    ref: "hotelRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.hotelDetails
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "备注："
    },
    model: {
      value: _vm.form.hotelRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelRemarks", $$v);
      },
      expression: "form.hotelRemarks"
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "参考："
    },
    model: {
      value: _vm.form.hotelRefer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotelRefer", $$v);
      },
      expression: "form.hotelRefer"
    }
  })], 1), _c("div", [_c("TableRestaurant", {
    ref: "restaurantRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.restaurantDetails
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "备注："
    },
    model: {
      value: _vm.form.restaurantRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "restaurantRemarks", $$v);
      },
      expression: "form.restaurantRemarks"
    }
  })], 1), _c("div", [_c("TableFleet", {
    ref: "fleetRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.busDetails
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "备注："
    },
    model: {
      value: _vm.form.busRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "busRemarks", $$v);
      },
      expression: "form.busRemarks"
    }
  })], 1), _c("div", [_c("TableGuide", {
    ref: "guideRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.guideDetails
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "备注："
    },
    model: {
      value: _vm.form.guideRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guideRemarks", $$v);
      },
      expression: "form.guideRemarks"
    }
  })], 1), _c("div", [_c("TableOperate", {
    ref: "operateRef",
    attrs: {
      "adult-count": _vm.form.adultCount,
      "table-data": _vm.form.operateDetails
    }
  }), _c("InputBase", {
    staticClass: "mt10",
    attrs: {
      label: "购物备注："
    },
    model: {
      value: _vm.form.shopRemarks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shopRemarks", $$v);
      },
      expression: "form.shopRemarks"
    }
  }), _c("div", {
    staticClass: "mt20",
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold"
    }
  }, [_vm._v(" 总价格："), _c("span", {
    staticClass: "red"
  }, [_vm._v("￥" + _vm._s(_vm.form.totalMoney))])])], 1)]), _c("div", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "canvas"
    }
  }), _c("FooterBar", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" 保存报价 ")]), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.handleShare
    }
  }, [_vm._v(" 分享报价 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };