import "core-js/modules/es.array.push.js";
import { SelectStaff, SelectDictionary, SelectCustom, SelectRegion } from '@/components/common';
import { OrderAuditEmit } from '@/config/order';
import { getNextDate, getDaysBetween } from '@/utils';
const roomsState = () => ({
  quantity: '',
  roomType: '',
  roomTypeId: '',
  roomName: ''
});
const defaultState = () => ({
  accompanyCount: '',
  accompanyInfo: '',
  adultCount: '',
  babyCount: '',
  childCount: '',
  customerTripNumber: '',
  // 小团号
  planCustomerNumber: '',
  // 大团号
  customerContactMobileNumber: '',
  customerContactName: '',
  customerName: '',
  customerId: '',
  endTripDate: '',
  endTripPosition: '',
  endTripTransport: '',
  grandsonDepartmentId: '',
  groupType: '常规团',
  outOPUserId: '',
  outOPUserName: '',
  productId: '',
  productName: '',
  saleUserId: '',
  saleUserName: '',
  startTripDate: '',
  startTripPosition: '',
  takeCustomerAddress: '',
  welcomeBorad: '',
  province: '',
  city: '',
  district: '',
  provinceId: '',
  cityId: '',
  districtId: '',
  region: [''],
  creditMoney: '',
  balance: '',
  dutyOPUserName: '',
  dutyOPUserId: '',
  addAuditStatus: OrderAuditEmit.NEW_WAIT
});
const rules = {
  productType: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'change'
  }],
  dutyOPUserName: [{
    required: true,
    message: '请选择操作计调',
    trigger: 'change'
  }],
  outOPUserName: [{
    required: true,
    message: '请选择客服计调',
    trigger: 'change'
  }],
  saleUserName: [{
    required: true,
    message: '请选择业务员',
    trigger: 'change'
  }],
  groupTypeName: [{
    required: true,
    message: '请选择团队类型',
    trigger: 'change'
  }]
};
export default {
  components: {
    SelectStaff,
    SelectDictionary,
    SelectCustom,
    SelectRegion
  },
  data() {
    return {
      visible: false,
      isTeamOrder: false,
      orderId: '',
      rules: rules,
      form: {
        ...defaultState(),
        product: {}
      },
      tourists: [],
      others: []
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    }
  },
  methods: {
    show(row, type) {
      this.orderId = row.id;
      this.visible = true;
      this.form = {
        ...row
      };
      if (row.product) {
        this.form.productId = row.product.id;
        this.form.productName = row.product.name;
      }
      this.form.orderType = type;
      this.form.mallOrderId = this.orderId;
      this.getUserData(this.userData);
      this.fetchOrderDetail(row.id);
    },
    hide() {
      this.visible = false;
    },
    fetchOrderDetail(id) {
      this.$store.dispatch('order/mall/fetchDetail', id).then(data => {
        if (!data) return;
        this.tourists = data.tourists;
        this.others = data.others;
      });
    },
    saveTourist(id, list) {
      if (!list || !list.length) return;
      const tourist = list.map(it => {
        it.cardType = 0;
        it.customerOrderId = id;
        return it;
      });
      this.$store.dispatch('order/index/createOrUpdateTourist', tourist);
    },
    saveOtherPay(id, list) {
      if (!list || !list.length) return;
      const otherPay = list.map(it => {
        it.customerOrderId = id;
        it.customerId = this.form.customerId;
        it.total = it.totalPay;
        it.cashType = '网付';
        return it;
      });
      this.$store.dispatch('order/index/createOtherPay', otherPay);
    },
    changeOPUser(type, val) {
      this.form[type + 'Name'] = val.label;
      this.form[type + 'Id'] = val.value;
    },
    onSelectCustomer(val) {
      this.form.customerId = val.id;
      this.form.customerName = val.name;

      // customerContactName
    },
    onSelectGroupType(val) {
      this.form.groupType = val.label;
      this.form.groupTypeName = val.name;
    },
    // 获取用户数据
    getUserData(val) {
      if (!val) return;
      this.form.dutyOPUserName = val.realName;
      this.form.dutyOPUserId = val.id;
      this.form.saleUserName = val.realName;
      this.form.saleUserId = val.id;
      this.form.outOPUserName = val.realName;
      this.form.outOPUserId = val.id;
    },
    getProductDays(id) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('resource/product/fetchDetail', id).then(res => {
          resolve(res);
        }).catch(() => {
          reject();
        });
      });
    },
    formatProduct({
      data,
      customerOrderId,
      startTripDate,
      betweenDay
    }) {
      const {
        itineraries
      } = data;
      let curDate = startTripDate;
      let tripList = [];
      for (let i = 0; i < betweenDay; i++) {
        const item = itineraries[i];

        // 如果日期没有超出行程
        if (item) {
          delete item.id;
          item.itineraryDate = curDate;
          item.customerOrderId = customerOrderId;
          item.rooms = [roomsState()];
          item.transports = [];
          tripList.push(item);
        } else {
          tripList.push({
            isPick: false,
            isSend: false,
            itineraryDate: curDate,
            customerOrderId,
            rooms: [roomsState()],
            transports: []
          });
        }
        curDate = getNextDate(curDate);
      }
      return tripList;
    },
    formatHotel(data, customerOrderId) {
      let tripList = [];
      data = data.filter(it => it.area || it.standard);
      if (!data.length) return [];
      tripList = data.map(it => {
        const item = {
          customerOrderId,
          customerId: this.form.customerId,
          count: this.form.adultCount / 2,
          hotelArea: it.area,
          hotelName: it.hotelName,
          hotelId: it.hotelId,
          orderDate: it.itineraryDate,
          hotelStandard: it.standard
        };
        return item;
      });
      return tripList;
    },
    // 选择产品 获取产品数据
    getProduct({
      productId,
      customerOrderId,
      startTripDate,
      betweenDay
    }) {
      return this.$store.dispatch('resource/product/fetchItinerary', productId).then(data => {
        return this.formatProduct({
          data,
          customerOrderId,
          startTripDate,
          betweenDay
        });
      });
    },
    createOrUpdateItinerary(data) {
      this.$store.dispatch('order/index/createOrUpdateItinerary', data);
    },
    // 生成行程
    generateTrip(customerOrderId) {
      const {
        product,
        startTripDate,
        endTripDate
      } = this.form;
      const {
        resPId: productId
      } = product;
      const betweenDay = getDaysBetween(startTripDate, endTripDate);
      return this.getProduct({
        productId,
        customerOrderId,
        startTripDate,
        betweenDay
      }).then(data => {
        // 不需要删除行程，因为是第一次创建
        this.createOrUpdateItinerary(data);

        // 如果是新建的时候  根据产品信息生成酒店信息
        const list = this.formatHotel(data, customerOrderId);
        if (!list.length) return false;
        this.$store.dispatch('order/index/createOrHotel', list);
      });
    },
    handleTransfor() {
      const opts = this.form;
      delete opts.id;
      this.$store.dispatch('order/index/createOrUpdate', {
        ...opts,
        addAuditStatus: OrderAuditEmit.NO_AUDITED
      }).then(res => {
        const {
          id
        } = res.data;
        this.hide();
        this.$bus.tip();
        this.saveTourist(id, this.tourists);
        this.saveOtherPay(id, this.others);
        this.generateTrip(res.data.id);
      });
    },
    handleConfirm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.handleTransfor();
        } else {
          return false;
        }
      });
    }
  }
};