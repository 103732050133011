import { auditMixin } from '@/mixin';
export default {
  mixins: [auditMixin],
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    // 单个选中
    handleCheck(row, val) {
      row.status = val;
      this.$emit('on-check', val);
    },
    handleAuditOne(row) {
      this.$emit('on-audit', row);
    }
  }
};