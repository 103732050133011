import TableTourist from './TableTourist';
export default {
  components: {
    TableTourist
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  mounted() {},
  methods: {
    show(data) {
      this.form = data;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.form = {};
    },
    handleCreate() {
      this.$parent.createOrUpdate(this.form);
      this.hide();
    }
  }
};