import { orderProductLine } from '@/config/order';
import { mapGetters } from 'vuex';
import { dictionaryMixin } from '@/mixin';
import propMixin from './propMixin';
import { formatItinerarys } from '@/views/order/utils';
import TopTitle from '../TopTitle.vue';
import IconRight from '../IconRight.vue';
import IconWrong from '../IconWrong.vue';
export default {
  components: {
    TopTitle,
    IconRight,
    IconWrong
  },
  mixins: [dictionaryMixin, propMixin],
  data() {
    return {
      columnData: orderProductLine.slice(1),
      type: 'itinerariesList',
      // 返款列表，对应接口字段
      visible: ''
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'order/index/currentOrder',
      receptionList: 'order/reception/receptionList'
    })
  },
  watch: {
    tableData: {
      handler: function (list) {
        this.visible = false;
        const data = formatItinerarys(list);
        this.itinerariesData = data;
        this.$nextTick(() => {
          this.visible = true;
        });
      },
      immediate: true
    }
  },
  methods: {}
};