import { render, staticRenderFns } from "./TableRefund.vue?vue&type=template&id=70267148"
import script from "./TableRefund.vue?vue&type=script&lang=js"
export * from "./TableRefund.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\develop\\project\\future-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70267148')) {
      api.createRecord('70267148', component.options)
    } else {
      api.reload('70267148', component.options)
    }
    module.hot.accept("./TableRefund.vue?vue&type=template&id=70267148", function () {
      api.rerender('70267148', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/TableRefund.vue"
export default component.exports