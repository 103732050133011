import { inputMixin, selectListMixin } from '@/mixin';
export default {
  mixins: [inputMixin, selectListMixin],
  inheritAttrs: false,
  data: () => ({
    fetchPath: 'ticket/index/fetchTicket',
    listData: []
  }),
  computed: {
    data() {
      return this.$store.getters['ticket/index/ticketData'];
    }
  },
  watch: {
    data: {
      handler: 'initData',
      immediate: true
    }
  },
  methods: {
    remoteMethod(name) {
      this.page.pageNum = 1;
      this.fetchData({
        ...this.page,
        name
      });
    },
    initData(data) {
      if (!data) {
        this.fetchData();
        return false;
      }
      this.listData = data.list;
    },
    fetchData(params) {
      this.$store.dispatch(this.fetchPath, params);
    },
    loadMoreData() {
      if (!this.data.hasMore) return;
      this.page.pageNum++;
      this.fetchData(this.page);
    }
  }
};