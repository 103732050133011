import "core-js/modules/es.array.push.js";
// import DraggableSort from './DraggableSort'
import { FooterBar, SelectAccount, SelectBankCard, SelectDictionary } from '@/components/common';
import { mapGetters } from 'vuex';
import { reloadMixin, tagViewMixin } from '@/mixin';
import { SELECTOR } from '@/config/select';
import { TableTeamFee, ShuttlePrice, EnterpriseLink } from './components';
const ISNAME = ['t_tourist_planNumber_auto_v', 's_tourist_planNumber_auto_v', 'c_tourist_planNumber_auto_v'];
const PLAN_NUMBER = ['t_tourist_planNumber_1_v', 't_tourist_planNumber_2_v', 't_tourist_planNumber_3_v', 't_tourist_planNumber_auto_v', 's_tourist_planNumber_1_v', 's_tourist_planNumber_2_v', 's_tourist_planNumber_auto_v', 'c_tourist_planNumber_1_v', 'c_tourist_planNumber_2_v', 'c_tourist_planNumber_auto_v'];
export default {
  components: {
    FooterBar,
    SelectAccount,
    SelectBankCard,
    SelectDictionary,
    TableTeamFee,
    ShuttlePrice,
    EnterpriseLink
  },
  mixins: [tagViewMixin, reloadMixin],
  data() {
    return {
      visible: false,
      rowCount: 1,
      formData: [],
      paymode: 'normal',
      authProxy: null,
      subjectId: 'test',
      subjectObj: {},
      collectMode: '',
      planNumberPrefix: '',
      ISNAME,
      inviteLink: ''
    };
  },
  computed: {
    ...mapGetters({
      subjectData: 'system/accounting/subjectData',
      systemConfig: 'system/systemConf/configData',
      systemConfigType: 'system/systemConf/configType'
    }),
    // 判断大团号生成模式是不是自由组合
    getConfName() {
      return item => {
        if (ISNAME.includes(item.code)) {
          return '自由组合：';
        }
        return item.name;
      };
    }
  },
  watch: {
    systemConfig: {
      handler: function (data) {
        if (!data) {
          // this.fetchData()
          return;
        }
        this.fetchAuthInfo();
        this.formData = this.formatContent(data);
      },
      immediate: true
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch('system/systemConf/fetchType');
    },
    fetchAuthInfo() {
      this.$store.dispatch('system/systemConf/fetchAudit').then(data => {
        const {
          collectAuthSubjectStr,
          collectAuthIncomeType
        } = data;
        this.collectMode = collectAuthIncomeType;
        this.subjectObj = collectAuthSubjectStr ? JSON.parse(collectAuthSubjectStr) : {};
        this.authProxy = data;
      });
    },
    changeRadio(item, val) {
      // this.suffixFormat()
      item.radioValue = val;
      if (item.values.length) {
        item.values.forEach(it => {
          it.isCheck = false;
          it.value = false;
          const {
            prefix,
            suffix
          } = this.suffixFormat(it.strValue);
          it.planNumberSuffix = suffix;
          item.planNumberPrefix = prefix;
          if (val == it.code) {
            it.isCheck = true;
            it.value = true;
          }
        });
      }
    },
    formatPlanItem(val) {
      if (!val) return SELECTOR.planNumberAuto;
      return SELECTOR.planNumberAuto.map(it => {
        it.disabled = false;
        if (it.value == val) {
          it.disabled = true;
        }
        return it;
      });
    },
    onSelectAuto(row, val) {
      let data = val.join('+');
      row.planNumberSuffix = `${data}+NUM`;
    },
    showDialog() {
      this.visible = true;
    },
    addRow() {
      this.rowCount++;
    },
    selectAccount(val) {
      const {
        id,
        subId
      } = val;
      const item = {
        selId: id,
        subjectId: id,
        subId
      };
      this.authProxy.collectAuthSubjectStr = JSON.stringify(item);
    },
    selectCollectMode(val) {
      this.authProxy.collectAuthIncomeType = val ? val.label : '';
    },
    handleCancel() {
      this.closeSelectedTag();
    },
    handleConfirm() {
      const optList = this.beforeCommit(this.formData);
      this.$store.dispatch('system/systemConf/update', optList).then(() => {
        this.fetchData();
        this.$store.dispatch('system/systemConf/updateAudit', this.authProxy).then(() => {
          this.$bus.tip();
        });
      });
    },
    // 截取大团号前缀和后区存起来
    suffixFormat(strValue) {
      let [prefix, fix0, fix1, fix2] = strValue.split('+');
      prefix = prefix.substr(1); //删除第一个字符
      prefix = prefix.substr(0, prefix.length - 1);
      //删除最后一字符
      const suffix = `${fix0}+${fix1}${fix2 ? '+' + fix2 : ''}`;
      return {
        prefix,
        suffix
      };
    },
    formatContent(list) {
      const res = [];
      list.forEach(it => {
        const {
          configs
        } = it;
        configs.forEach(item => {
          if (item.isSingle && item.values.length) {
            const radioItem = item.values.find(v => !!v.value);
            this.$set(item, 'radioValue', radioItem ? radioItem.code : item.values[0].code);
          }
          item.values.forEach(val => {
            val.pCode = item.code;
            if (val.strValue && PLAN_NUMBER.includes(val.code)) {
              // 保存团号后区
              const {
                prefix,
                suffix
              } = this.suffixFormat(val.strValue);

              // item.numberPrefix = prefix
              this.$set(item, 'planNumberPrefix', prefix);
              val.planNumberSuffix = suffix;
              // 判断是否是大团号自由组合
              if (ISNAME.includes(val.code)) {
                let planNumberAuto = [];
                // 如果是散客和空位则去掉客户选择
                planNumberAuto = SELECTOR.planNumberAuto.filter(it => it.value != 'CD');
                // 如果是团队则全部需要
                if (val.code == 't_tourist_planNumber_auto_v') {
                  planNumberAuto = SELECTOR.planNumberAuto;
                }
                this.$set(val, 'planNums1', planNumberAuto);
                let suffixItem = suffix.split('+');
                suffixItem = suffixItem.filter(it => it && it != 'NUM');
                this.$set(val, 'strValue1', suffixItem);
                val.planNumberSuffix = suffix;
              }
            }
            this.$set(val, 'isCheck', val.value);
          });
        });
        res.push(it);
      });
      return res;
    },
    formatAutoData(val) {
      if (!val) return '';
      const data = SELECTOR.planNumberAuto.filter(it => it.value == val)[0];
      return data ? data.label : '';
    },
    beforeCommit(data) {
      const res = [];
      data.forEach(it => {
        it.configs.forEach(config => {
          config.values.forEach(val => {
            const {
              id,
              name,
              code,
              orderNumber,
              isCheck
            } = val;
            const opt = {
              code: code,
              configId: config.id,
              id,
              name,
              orderNumber,
              value: Boolean(isCheck)
            };
            if (PLAN_NUMBER.includes(code)) {
              opt.strValue = config.planNumberPrefix ? `[${config.planNumberPrefix}]+${val.planNumberSuffix}` : `[]+${val.planNumberSuffix}`;
            }
            res.push(opt);
          });
        });
      });
      return res;
    }
  }
};