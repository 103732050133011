// import { PlanType } from './plan'
// import { TYPES as auditType } from './audit'
// // 不判断权限的url 父级
// export const requireAuthUrl = [
//   {
//     path: '/order/team-input-detail',
//     pPath: '/order/team',
//   },
//   {
//     path: '/order/team-input-create',
//     pPath: '/order/team',
//   },
//   {
//     path: '/order/team-input-update',
//     pPath: '/order/team',
//   },
//   {
//     path: '/order/team-input-clone',
//     pPath: '/order/team',
//   },
//   {
//     path: '/order/personal-input-update',
//     pPath: '/order/personal',
//   },
//   {
//     path: '/order/personal-input-create',
//     pPath: '/order/personal',
//   },
//   {
//     path: '/order/personal-input-detail',
//     pPath: '/order/personal',
//   },
//   {
//     path: '/order/personal-input-clone',
//     pPath: '/order/personal',
//   },
// ]
// const commonOpts = [
//   { name: '新增' },
//   { name: '编辑' },
//   { name: '删除' },
//   { name: '复制' },
//   { name: '查看' },
//   { name: '日志' },
//   { name: '客户结算价' },
//   { name: '协议' },
// ]
// const agreementOpts = commonOpts
// const checkout = [{ name: '确认对账' }, { name: '取消对账' }]
// const writeOff = [
//   { name: '新增核销' },
//   { name: '删除核销' },
//   { name: '修改核销' },
//   { name: '批量核销' },
// ]

// const planOperate = [
//   { name: '安排车辆', code: PlanType.bus },
//   { name: '安排导游', code: PlanType.guide },
//   { name: '安排酒店', code: PlanType.hotel },
//   { name: '安排餐厅', code: PlanType.restaurant },
//   { name: '安排自费', code: PlanType.selfPay },
//   { name: '安排保险', code: PlanType.insurance },
//   { name: '安排交通', code: PlanType.transport },
//   { name: '安排接送', code: PlanType.shuttle },
//   { name: '安排景区', code: PlanType.scenic },
//   { name: '安排其他收入', code: PlanType.ortherIncome },
//   { name: '安排其他支出', code: PlanType.ortherPay },
//   { name: '安排签证', code: PlanType.visa },
//   { name: '安排购物', code: PlanType.shop },
//   { name: '导游借款申请', code: PlanType.borrow },
//   { name: '导游代收', code: PlanType.collect },
//   {
//     name: '外转操作',
//     code: PlanType.localTravelAgency,
//   },
//   { name: '内转操作', code: 'turnIn' },
//   { name: '中转操作', code: 'turnMid' },
//   { name: '确认/撤销发团', code: 'confirm' },
//   { name: '景区底价', code: 'scenicFloorPrice' },
//   { name: '酒店底价', code: 'hotelFloorPrice' },
//   { name: '显示团利润', code: 'teamProfit' },
//   { name: '上报派单', code: 'reportDispatch' },
//   { name: '录入', code: 'plan:tourist:entering' },
//   { name: '复制', code: 'plan:copy' },
// ]

// export const PsermissionList = [
//   {
//     title: '首页',
//     code: '/',
//     submenu: [
//       {
//         title: '首页',
//         code: '/dashboard',
//         operate: [
//           { name: '订单审核', code: 'home:order:audit' },
//           { name: '确认成团', code: 'home:confirm:plan' },
//           { name: '未拼团', code: 'home:no:plan' },
//           { name: '确认发团', code: 'home:send:plan' },
//           { name: '计调审核', code: 'home:operator:audit' },
//           { name: '财务审核', code: 'home:finance:audit' },
//         ],
//       },
//     ],
//   },
//   {
//     title: '订单管理',
//     code: '/order',
//     submenu: [
//       {
//         title: '团队输单',
//         code: '/order/team',
//         operate: [
//           { name: '新增订单' },
//           { name: '修改订单' },
//           { name: '删除订单' },
//           { name: '保存订单' },
//           { name: '酒店安排' },
//           { name: '其他收入安排' },
//           { name: '其他支出安排' },
//           { name: '景区安排' },
//           { name: '购物安排' },
//           { name: '餐饮安排' },
//           { name: '车队安排' },
//           { name: '大交通安排' },
//           { name: '保险安排' },
//           { name: '自费安排' },
//           { name: '签证安排' },
//           { name: '房间安排' },
//           { name: '门票安排' },
//           { name: '自费项安排' },
//           { name: '确认文档' },
//           { name: '修改团款申请' },
//           { name: '查看分房表' },
//           { name: '退单' },
//           { name: '查看' },
//           { name: '确认成团' },
//           { name: '游客退团' },
//           { name: '取消计划' },
//           { name: '单团明细' },
//           { name: '创建订单并设置为暂不提交审核' },
//         ],
//       },

//       {
//         title: '散客输单',
//         code: '/order/personal',
//         operate: [
//           { name: '新增订单' },
//           { name: '修改订单' },
//           { name: '删除订单' },
//           { name: '保存订单' },
//           { name: '并团' },
//           { name: '按天转拼' },
//           { name: '勾选' },
//           { name: '转移' },
//           { name: '酒店安排' },
//           { name: '其他收入安排' },
//           { name: '其他支出安排' },
//           { name: '景区安排' },
//           { name: '购物安排' },
//           { name: '餐饮安排' },
//           { name: '车队安排' },
//           { name: '大交通安排' },
//           { name: '保险安排' },
//           { name: '自费安排' },
//           { name: '签证安排' },
//           { name: '房间安排' },
//           { name: '门票安排' },
//           { name: '自费项安排' },
//           { name: '确认文档' },
//           { name: '修改团款申请' },
//           { name: '查看分房表' },
//           { name: '退单' },
//           { name: '单团明细' },
//           { name: '创建订单并设置为暂不提交审核' },
//           { name: '保存并提交审核' },
//         ],
//       },

//       {
//         title: '预控输单',
//         code: '/order/reserve',
//         operate: [
//           { name: '新增计划' },
//           { name: '修改计划' },
//           { name: '删除计划' },
//           { name: '报名录单' },
//           { name: '修改订单' },
//           { name: '删除订单' },
//           { name: '设置计划可见范围' },
//           { name: '总控管理' },
//           { name: '修改团款申请' },
//           { name: '查看分房表' },
//         ],
//       },

//       {
//         title: '总控管理',
//         operate: [
//           { name: '新增总控' },
//           { name: '编辑总控' },
//           { name: '删除总控' },
//           { name: '新增占位' },
//           { name: '编辑占位' },
//           { name: '删除占位' },
//         ],
//       },

//       {
//         title: '代订输单',
//         operate: [...commonOpts, { name: '修改团款申请' }],
//       },

//       {
//         title: '内转接收',
//         operate: [
//           { name: '确认接收' },
//           { name: '退回' },
//           { name: '拒绝' },
//           { name: '自动接收' },
//           { name: '接收后自动成团' },
//         ],
//       },

//       {
//         title: '外转接收',
//         code: '/order/turn-in',
//         operate: [{ name: '确认接收' }, { name: '退回' }, { name: '拒绝' }],
//       },

//       {
//         title: '回收站',
//         code: '/order/recycle',
//         operate: [{ name: '恢复散客' }, { name: '恢复团队' }],
//       },

//       {
//         title: '商城订单',
//         code: '/order/mall',
//         operate: [{ name: ' 转散客订单' }, { name: ' 转团队订单' }],
//       },

//       {
//         title: '所有订单',
//         code: '/order/order-all',
//         operate: commonOpts,
//       },

//       {
//         title: '所有订单2',
//         code: '/order/order-all2',
//         operate: commonOpts,
//       },

//       {
//         title: '订单审核',
//         code: '/order/order-audit',
//         operate: [
//           { name: '查看' },
//           { name: '进度' },
//           { name: '审核' },
//           { name: '取消审核' },
//         ],
//       },
//     ],
//   },

//   {
//     title: '计调操作',
//     code: '/plan',
//     submenu: [
//       {
//         title: '发团预定安排',
//         code: '/plan/team-arrange',
//         operate: planOperate,
//       },
//       {
//         title: '发团预定安排2',
//         code: '/plan/team-role-arrange',
//         operate: planOperate,
//       },
//       {
//         title: '接送预定安排',
//         code: '/plan/pickup',
//         operate: [
//           { name: '下单', code: 'fleet' },
//           { name: '日志', code: 'fleet' },
//           { name: '编辑', code: 'fleet' },
//           { name: '费用', code: 'fleet' },
//         ],
//       },
//       {
//         title: '外转安排',
//         code: '/plan/turn-out',
//         operate: [
//           { name: '查看', code: 'detail' },
//           { name: '编辑', code: 'edit' },
//           { name: '日志', code: 'log' },
//         ],
//       },

//       {
//         title: '房调预订安排',
//         code: '/plan/hotel',
//         operate: [{ name: '安排房间' }],
//       },

//       {
//         title: '车调预订安排',
//         operate: [{ name: '安排车队' }],
//       },

//       {
//         title: '内转转出操作',
//         operate: [
//           { name: '查看', code: 'detail' },
//           { name: '编辑', code: 'edit' },
//           { name: '日志', code: 'log' },
//         ],
//       },

//       {
//         title: '订单转出操作',
//         operate: [
//           { name: '添加外转' },
//           { name: '编辑外转' },
//           { name: '删除外转' },
//           { name: '添加内转' },
//           { name: '编辑内转' },
//           { name: '删除内转' },
//         ],
//       },

//       {
//         title: '车控预订安排',
//         operate: [{ name: '安排车辆' }],
//       },

//       {
//         title: '餐控预订安排',
//         operate: [{ name: '安排餐厅' }],
//       },

//       {
//         title: '导控预订安排',
//         operate: [{ name: '安排导游' }],
//       },

//       {
//         title: '票控预订安排',
//         operate: [{ name: '安排景区' }],
//       },

//       {
//         title: '险控预订安排',
//         operate: [{ name: '安排保险' }],
//       },

//       {
//         title: '出团通知模板',
//         operate: commonOpts,
//       },
//     ],
//   },

//   {
//     title: '签单操作',
//     code: '/ticket',
//     submenu: [
//       {
//         title: '签单录入',
//         code: '/ticket/ticket-input',
//         operate: commonOpts,
//       },

//       {
//         title: '签单核销',
//         code: '/ticket/ticket-check',
//         operate: [{ name: '审核' }],
//       },

//       {
//         title: '签单管理',
//         code: '/ticket/ticket-manage',
//         operate: commonOpts,
//       },
//       {
//         title: '签单查询',
//         code: '/ticket/ticket-search',
//         operate: commonOpts,
//       },
//     ],
//   },

//   {
//     title: '审核管理',
//     code: '/audit',
//     submenu: [
//       {
//         title: '单团审核',
//         code: '/audit/team-single',
//         operate: [
//           // { name: '计调审核', code: 'op' },
//           // { name: '财务审核', code: 'finance' },
//           // { name: '导管审核', code: 'guideManage' },
//           // { name: '经理审核', code: 'manage' },
//           // { name: 'BOSS审核', code: 'boss' },
//           { name: '审核单条记录', code: 'auditOne' },
//           { name: '设为计调已审核', code: 'opAudited' },
//           { name: '设为财务已审核', code: 'financeAudited' },
//           // { name: '设为导管已审核', cocde: 'guideManageAudited' },
//           // { name: '设为经理已审核', code: 'manageAudited' },
//           // { name: '设为BOSS已审核', code: 'bossAudited' },
//           { name: '退回给导游重报', code: 'returnGuide' },
//           { name: '退回给计调重审', code: 'returnOp' },
//           // { name: '退回给财务重审', code: 'returnFinance' },
//           { name: '取消审核', code: 'cancelAudit' },
//           {
//             name: '忽略下级审核状态可编辑单条记录',
//             code: 'ignoreJunior',
//           },
//           { name: '忽略下级审购物', code: 'ignoreShopping' },
//           { name: '忽略下级审团款', code: 'ignoreTeamFund' },
//           { name: '忽略下级审大交通', code: 'ignoreTraffic' },
//           { name: '忽略下级审酒店', code: 'ignoreHotel' },
//           { name: '忽略下级审房间', code: 'ignoreRoom' },
//           { name: '审核客户团款', code: auditType.fee },
//           { name: '审核景区', code: auditType.scenic },
//           { name: '审核地接', code: auditType.out_fee },
//           { name: '审核酒店', code: auditType.hotel },
//           { name: '审核餐饮', code: auditType.restaurant },
//           { name: '审核车队', code: auditType.bus_company },
//           { name: '审核大交通', code: auditType.transport },
//           { name: '审核保险', code: auditType.insurance },
//           { name: '审核接送', code: auditType.shuttle },
//           { name: '审核签证', code: 'visa' },
//           { name: '审核购物', code: 'shop' },
//           {
//             name: '审核其他收入',
//             code: auditType.other_income,
//           },
//           { name: '审核其他支出', code: auditType.other_pay },
//           {
//             name: '审核客户后返',
//             code: auditType.subsidy_rate,
//           },
//           {
//             name: '显示二返',
//             code: auditType.subsidy_two,
//           },
//           { name: '审核导游', code: 'guide' },
//           { name: '审核代收', code: auditType.collect },
//           {
//             name: '审核导游借款',
//             code: auditType.guide_borrow,
//           },
//           { name: '审核导游交款', codee: 'guidePay' },
//           { name: '审核外转费用', code: 'turnOutPay' },
//           { name: '审核加点', code: auditType.add_scenic },
//           // { name: '审核内转费用', code: 'turnInPay' },
//           // { name: '审核长线段' },
//           // { name: '设为团审会计审核[单选]' },
//           // { name: '设为应收会计审核[单选]' },
//           { name: '确认该团已结算' },
//           // { name: '签单表审核' },
//           { name: '票据录入' },
//           { name: '可以编辑客户团款/代收/返款' },
//           // { name: '显示组团社信息' },
//           { name: '新增单条记录默认为已审核' },
//           // { name: '投诉' },
//           // { name: '计调审核单条记录[单选]' },
//           // { name: '导管审核单条记录[单选]' },
//           // { name: '经理审核单条记录[单选]' },
//           // { name: '财务审核单条记录[单选]' },
//           // { name: 'BOSS审核单条记录[单选]' },
//           // { name: '管理员审核单条记录[单选]' },
//           // { name: '提交报账' },
//           // { name: '退回报账', code: 'back' },
//           { name: '酒店底价', code: 'hotelFloorPriceAudit' },
//           { name: '景区底价', code: 'scenicFloorPriceAudit' },
//           { name: '显示团利润', code: 'teamProfit' },
//           // { name: '分佣表' },
//         ],
//       },

//       {
//         title: '单团归档',
//         code: '/audit/team-file',
//         operate: [{ name: '确认归档' }, { name: '取消归档' }],
//       },
//     ],
//   },
//   {
//     title: '对账管理',
//     code: '/bill-check',
//     submenu: [
//       {
//         title: '车队对账',
//         code: '/bill-check/fleet',
//         operate: checkout,
//       },

//       {
//         title: '房间对账',
//         code: '/bill-check/hotel',
//         operate: checkout,
//       },

//       {
//         title: '保险对账',
//         code: '/bill-check/insurance',
//         operate: checkout,
//       },

//       {
//         title: '景区对账',
//         code: '/bill-check/scenic',
//         operate: checkout,
//       },

//       { title: '自费项对账', operate: checkout },

//       {
//         title: '餐厅对账',
//         code: '/bill-check/restaurant',
//         operate: checkout,
//       },

//       {
//         title: '交通对账',
//         code: '/bill-check/traffic',
//         operate: checkout,
//       },

//       { title: '客户对账', operate: checkout },

//       { title: '外转对账', operate: checkout },

//       { title: '公司对账', operate: checkout },

//       { title: '单团对账', operate: checkout },

//       {
//         title: '其他收入对账',
//         code: '/bill-check/other-income',
//         operate: checkout,
//       },

//       {
//         title: '其他支出对账',
//         code: '/bill-check/other-pay',
//         operate: checkout,
//       },
//     ],
//   },

//   {
//     title: '销账管理',
//     code: '/bill',
//     submenu: [
//       {
//         title: '收团款',
//         code: '/bill/regiment-collect',
//         operate: commonOpts,
//       },

//       {
//         title: '付外转',
//         code: '/bill/regiment-pay/reception',
//         operate: commonOpts,
//       },

//       {
//         title: '付房间',
//         code: '/bill/regiment-pay/hotel',
//         operate: commonOpts,
//       },

//       {
//         title: '付车队',
//         code: '/bill/regiment-pay/fleet',
//         operate: commonOpts,
//       },

//       {
//         title: '付保险',
//         code: '/bill/regiment-pay/insurance',
//         operate: commonOpts,
//       },

//       { title: '付自费项', operate: commonOpts },

//       {
//         title: '付景区',
//         code: '/bill/regiment-pay/scenic',
//         operate: commonOpts,
//       },

//       {
//         title: '付交通',
//         code: '/bill/regiment-pay/traffic',
//         operate: commonOpts,
//       },

//       {
//         title: '付餐厅',
//         code: '/bill/regiment-pay/restaurant',
//         operate: commonOpts,
//       },

//       {
//         title: '收单位团款',
//         code: '/bill/unit-collect',
//         operate: commonOpts,
//       },

//       {
//         title: '按公司去下账',
//         code: '/bill/company',
//         operate: commonOpts,
//       },

//       {
//         title: '付外转',
//         code: '/bill/unit-pay/reception',
//         operate: commonOpts,
//       },

//       {
//         title: '付房间',
//         code: '/bill/unit-pay/hotel',
//         operate: commonOpts,
//       },

//       {
//         title: '付车队',
//         code: '/bill/unit-pay/fleet',
//         operate: commonOpts,
//       },

//       {
//         title: '付保险',
//         code: '/bill/unit-pay/insurance',
//         operate: commonOpts,
//       },

//       { title: '付自费项', operate: commonOpts },

//       {
//         title: '付景区',
//         code: '/bill/unit-pay/scenic',
//         operate: commonOpts,
//       },

//       {
//         title: '付交通',
//         code: '/bill/unit-pay/traffic',
//         operate: commonOpts,
//       },

//       {
//         title: '付餐厅',
//         code: '/bill/unit-pay/restaurant',
//         operate: commonOpts,
//       },

//       { title: '其他收入核销', code: '/bill/other-collect', operate: writeOff },

//       { title: '其他支出核销', code: '/bill/other-pay', operate: writeOff },

//       { title: '客户代收核销', code: '/bill/custom-proxy', operate: writeOff },

//       { title: '客户返款核销', code: '/bill/custom-pay', operate: writeOff },

//       { title: '外转代付核销', operate: writeOff },

//       { title: '车费网付核销', operate: writeOff },

//       { title: '导游网付核销', operate: writeOff },

//       {
//         title: '导游单团借款',
//         code: '/bill/guide-borrow',
//         operate: commonOpts,
//       },

//       {
//         title: '导游单团报账',
//         code: '/bill/guide-audit',
//         operate: [
//           { name: '对账' },
//           { submit: false, name: '报账' },
//           { fix: false, name: '修复' },
//         ],
//       },

//       {
//         title: '导游批量报账',
//         code: '/bill/guide-audit-batch',
//         operate: commonOpts,
//       },

//       {
//         title: '预收付款管理',
//         code: '/bill/pre-collect-pay',
//         operate: [{ name: '删除预收付款' }],
//       },

//       {
//         title: '现金日记流水',
//         code: '/bill/cash-diray',
//         operate: commonOpts,
//       },

//       {
//         title: '导游预约报账',
//         operate: [{ name: '修改预约时间' }],
//       },
//     ],
//   },

//   {
//     title: '报表统计',
//     submenu: [
//       { title: '预定用房统计', operate: [] },
//       {
//         title: '单团利润统计',
//         code: '/report/profit-team',
//         operate: [{ name: '显示二返', code: 'report:profit-team:subsidy-two' }],
//       },
//     ],
//   },

//   {
//     title: '审批管理',
//     submenu: [
//       {
//         title: '审批管理',
//         operate: [...commonOpts, { name: '下账' }],
//       },

//       {
//         title: '审批流程配置',
//         operate: [...commonOpts, { name: '类型管理' }],
//       },
//     ],
//   },

//   {
//     title: '资源管理',
//     code: '/resource',
//     submenu: [
//       {
//         title: '导游管理',
//         code: '/resource/guide',
//         operate: commonOpts,
//       },
//       {
//         title: '导管管理',
//         code: '/resource/guide-manager',
//         operate: commonOpts,
//       },
//       {
//         title: '导游组长',
//         code: '/resource/guide-captain',
//         operate: commonOpts,
//       },
//       {
//         title: '车队管理',
//         code: '/resource/fleet',
//         operate: commonOpts,
//       },
//       {
//         title: '车辆管理',
//         code: '/resource/vehicle',
//         operate: [],
//       },
//       {
//         title: '司机管理',
//         code: '/resource/driver',
//         operate: [],
//       },
//       {
//         title: '房间管理',
//         code: '/resource/hotel',
//         operate: commonOpts,
//       },

//       {
//         title: '自费项管理',
//         operate: commonOpts,
//       },

//       {
//         title: '保险管理',
//         code: '/resource/insurance',
//         operate: commonOpts,
//       },

//       {
//         title: '景区管理',
//         code: '/resource/scenic',
//         operate: commonOpts,
//       },

//       {
//         title: '门票管理',
//         operate: commonOpts,
//       },

//       {
//         title: '餐厅管理',
//         code: '/resource/restaurant',
//         operate: commonOpts,
//       },

//       {
//         title: '购物店管理',
//         code: '/resource/shop',
//         operate: commonOpts,
//       },

//       {
//         title: '交通管理',
//         code: '/resource/traffic',
//         operate: commonOpts,
//       },

//       {
//         title: '线路管理',
//         code: '/resource/line',
//         operate: commonOpts,
//       },

//       {
//         title: '产品管理',
//         code: '/resource/product',
//         operate: commonOpts,
//       },

//       {
//         title: '客户管理',
//         code: '/resource/custom',
//         operate: commonOpts,
//       },

//       {
//         title: '公司管理',
//         code: '/resource/company',
//         operate: commonOpts,
//       },
//       {
//         title: '其他单位',
//         code: '/resource/other',
//         operate: commonOpts,
//       },

//       {
//         title: '账户管理',
//         code: '/resource/account',
//         operate: [...commonOpts, { name: '卡卡转账' }, { name: '卡内调账' }],
//       },
//     ],
//   },

//   {
//     title: '购物管理',
//     submenu: [
//       { title: '购物录入', path: '/shopping/input', operate: commonOpts }, // 购物录入
//       {
//         title: '购物录入',
//         path: '/shopping/input-create',
//         operate: commonOpts,
//       }, // 购物录入
//       { title: '购物财务', path: '/shopping/finance', operate: commonOpts }, // 购物财务
//       { title: '购物审核', path: '/shopping/audit', operate: commonOpts }, // 购物审核
//       { title: '购物对账', path: '/shopping/check', operate: commonOpts }, // 购物对账
//       {
//         title: '购物收款',
//         path: '/shopping/shop-collect',
//         operate: commonOpts,
//       }, // 购物收款
//       {
//         title: '新增购物收款',
//         path: '/shopping/shop-create',
//         operate: commonOpts,
//       }, // 新增购物收款
//       { title: '购物统计', path: '/shopping/total', operate: commonOpts }, // 购物统计
//       {
//         title: '客户利润分析',
//         path: '/shopping/total/profit',
//         operate: commonOpts,
//       },
//       {
//         title: '客户数据分析表',
//         path: '/shopping/analyse-custom-data',
//         operate: commonOpts,
//       },
//       {
//         title: '单产品客户分析表',
//         path: '/shopping/product-custom',
//         operate: commonOpts,
//       },
//       {
//         title: '按店铺统计',
//         path: '/shopping/total/shop',
//         operate: commonOpts,
//       }, // 按店铺统计
//       {
//         title: '按导游统计',
//         path: '/shopping/total/guide',
//         operate: commonOpts,
//       }, // 按导游统计
//       {
//         title: '按商品统计',
//         path: '/shopping/total/goods',
//         operate: commonOpts,
//       }, // 按商品统计
//       {
//         title: '按单团统计',
//         path: '/shopping/total/single',
//         operate: commonOpts,
//       }, // 按单团统计
//       {
//         title: '客户利润分析',
//         path: '/shopping/total/profit',
//         operate: commonOpts,
//       }, // 客户利润分析
//       {
//         title: '按组团统计',
//         path: '/shopping/total/team',
//         operate: commonOpts,
//       }, // 按组团统计
//       {
//         title: '按小团统计',
//         path: '/shopping/total/team-small',
//         operate: commonOpts,
//       }, // 按小团统计
//     ],
//   },

//   {
//     title: '协议管理',
//     submenu: [
//       { title: '车队协议', operate: [] },

//       { title: '房间协议', operate: agreementOpts },

//       { title: '保险协议', operate: agreementOpts },

//       { title: '景区协议', operate: agreementOpts },

//       { title: '自费项协议', operate: agreementOpts },

//       { title: '餐厅协议', operate: agreementOpts },

//       { title: '交通协议', operate: agreementOpts },
//     ],
//   },

//   {
//     title: '办公管理',
//     code: '/work',
//     submenu: [
//       {
//         title: '办公费用录入',
//         code: '/work/input',
//         operate: [],
//       },

//       {
//         title: '办公费用科目',
//         code: '/work/subject',
//         operate: [],
//       },
//       {
//         title: '办公费用流水',
//         code: '/work/stream',
//         operate: [],
//       },
//     ],
//   },

//   {
//     title: '系统管理',
//     code: '/system',
//     submenu: [
//       { title: '公司信息', code: '/system/company', operate: commonOpts },

//       {
//         title: '会计科目',
//         code: '/system/accounting',
//         operate: commonOpts,
//       },

//       {
//         title: '部门管理',
//         code: '/system/department',
//         operate: commonOpts,
//       },

//       {
//         title: '员工管理',
//         code: '/system/staff',
//         operate: [...commonOpts, { name: '员工授权' }],
//       },

//       {
//         title: '角色管理',
//         code: '/system/role',
//         operate: [
//           ...commonOpts,
//           { name: '授权角色' },
//           { name: '同步角色权限到员工' },
//         ],
//       },

//       { title: '审核流程', operate: [{ name: '编辑' }] },

//       { title: '导游报账设置', operate: [{ name: '确认' }] },

//       { title: '节假时间配置', operate: commonOpts },

//       {
//         title: '字典管理',
//         code: '/system/dictionary',
//         operate: commonOpts,
//       },

//       {
//         title: '角色代办管理',
//         code: '/system/role-agency',
//         operate: commonOpts,
//       },

//       {
//         title: '公告管理',
//         code: '/system/notice',
//         operate: commonOpts,
//       },
//     ],
//   },
// ]

// const operateObj = {}

// PsermissionList.forEach(it => {
//   it.submenu.forEach(sub => {
//     const { operate, code } = sub
//     if (!operateObj[code]) {
//       operateObj[code] = {}
//     }

//     operate.forEach((v, i) => {
//       operateObj[code][i] = v.name
//     })
//   })
// })

// export const Operate = operateObj

// 适配
export const PathMap = {
  '/order/personal-input-create': '/order/personal',
  '/order/personal-input-update': '/order/personal',
  '/order/team-input-create': '/order/team',
  '/order/team-input-update': '/order/team'
};
export const CustomAuthKeys = {
  forbidOperationPlan: 'forbidOperationPlan',
  forbidOperationPlan2: 'forbidOperationPlan2',
  forbidOperationAudit: 'forbidOperationAudit',
  scenicFloorPrice: 'scenicFloorPrice',
  scenicFloorPrice2: 'scenicFloorPrice2',
  hotelFloorPrice: 'hotelFloorPrice',
  hotelFloorPrice2: 'hotelFloorPrice2',
  scenicFloorPriceAudit: 'scenicFloorPriceAudit',
  hotelFloorPriceAudit: 'hotelFloorPriceAudit',
  'audit:team-single:ignoreAudit': 'audit:team-single:ignoreAudit',
  shopSubsidyTwoPrice: 'audit:team-single:subsidy-two',
  shoppingFinanceSubsidyTwo: 'shopping:finance:subsidy-two',
  shoppingCollectSubsidyTwo: 'shopping:shop-collect:subsidy-two',
  shoppingInputSubsidyTwo: 'shopping:input:subsidy-two',
  reportProfitTeamSubsidyTow: 'report:profit-team:subsidy-two'
};