import { inputMixin } from '@/mixin';
export default {
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    planType: {
      type: Number,
      default: 1
    }
  },
  data() {
    // this.label =
    //   this.planType === 1
    //     ? '外联计调'
    //     : this.planType === 2
    //     ? '责任计调'
    //     : '业务员'
    return {
      placeholder: '请选择计调'
    };
  },
  created() {
    this.plans = this.loadAll();
  },
  methods: {
    loadAll() {
      return [{
        value: '三全鲜食（北新泾店）',
        address: '长宁区新渔路144号'
      }, {
        value: 'Hot honey 首尔炸鸡（仙霞路）',
        address: '上海市长宁区淞虹路661号'
      }, {
        value: '新旺角茶餐厅',
        address: '上海市普陀区真北路988号创邑金沙谷6号楼113'
      }];
    }
  }
};