/*
 * @Author: cyg
 * @Date: 2022-11-10 10:08:17
 * @LastEditors: cyg
 * @LastEditTime: 2022-11-10 15:03:51
 * @FilePath: \travel-admin\src\config\dashboard.js
 */
// 今日发团
export const todayPlan = [{
  attrs: {
    prop: 'planNumber',
    label: '大团号',
    width: '120'
  },
  slot: 'planNumber'
}, {
  attrs: {
    prop: 'orderType',
    label: '团型'
  },
  slot: 'orderType'
}, {
  attrs: {
    prop: 'productName',
    label: '线路产品'
  }
}, {
  attrs: {
    prop: 'outOPUsers',
    label: '客服计调'
  },
  slot: 'outOPUsers'
}, {
  attrs: {
    prop: 'dutyOPUserName',
    label: '操作计调'
  }
}, {
  attrs: {
    prop: 'startPlanDate',
    label: '发团日期'
  }
}, {
  attrs: {
    prop: 'endPlanDate',
    label: '散团日期'
  }
}, {
  attrs: {
    prop: 'guides',
    label: '安排导游'
  },
  slot: 'guides'
}, {
  attrs: {
    prop: 'number',
    label: '人数'
  },
  slot: 'number'
}, {
  attrs: {
    prop: '',
    label: '团款'
  },
  children: [{
    attrs: {
      prop: 'totalCustomerIncome',
      label: '应收'
    }
  }, {
    attrs: {
      prop: 'totalCustomerIncomed',
      label: '已收'
    }
  }, {
    attrs: {
      prop: 'unIncome',
      label: '未收'
    }
  }]
}, {
  attrs: {
    prop: 'totalIncome',
    label: '总收入'
  }
}, {
  attrs: {
    prop: 'totalPay',
    label: '总成本'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossProfit',
    label: '毛利'
  }
}, {
  attrs: {
    prop: 'totalQuoteGrossMargin',
    label: '人均毛利'
  }
}, {
  attrs: {
    prop: 'sendGroupStatus',
    label: '发团状态'
  },
  slot: 'sendGroupStatus'
}, {
  attrs: {
    prop: 'auditStatus',
    label: '审核状态',
    width: 120
  },
  slot: 'auditStatus'
}];

// tomorrowSend