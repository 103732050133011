var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("DialogBase", {
    staticClass: "tourist-dialog-box",
    attrs: {
      title: "游客详细完整列表",
      width: "1080px",
      "has-foot": false,
      visible: _vm.visible
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.onExport
    }
  }, [_vm._v("导出")]), _c("div", {
    attrs: {
      id: "exportTab"
    }
  }, [_c("TableBase", {
    attrs: {
      data: _vm.tableData,
      dynamic: false,
      "column-data": _vm.columnData,
      border: ""
    },
    scopedSlots: _vm._u([{
      key: "index",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")];
      }
    }, {
      key: "desProvince",
      fn: function ({
        scope
      }) {
        return [_vm._v(" " + _vm._s(_vm.getDesCity(scope.row)) + " ")];
      }
    }, {
      key: "remarks",
      fn: function ({
        scope
      }) {
        return [_c("el-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("div", {
          attrs: {
            slot: "content"
          },
          slot: "content"
        }, [_vm._v(" " + _vm._s(scope.row.remarks) + " ")]), _c("p", {
          staticClass: "ellipsis-2"
        }, [_vm._v(" " + _vm._s(scope.row.remarks) + " ")])])];
      }
    }, {
      key: "action",
      fn: function ({
        scope
      }) {
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function ($event) {
              return _vm.onRemarkDialog(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.remarks ? "编辑" : "添加") + " ")])];
      }
    }])
  })], 1)], 1), _c("DialogBase", {
    attrs: {
      title: "备注修改",
      width: "500px",
      visible: _vm.visibleRemark
    },
    on: {
      "update:visible": function ($event) {
        _vm.visibleRemark = $event;
      },
      cancel: _vm.handleCancel,
      confirm: _vm.handleConfirm
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 6
      },
      placeholder: "请输入备注信息"
    },
    model: {
      value: _vm.touristRevamp.remarks,
      callback: function ($$v) {
        _vm.$set(_vm.touristRevamp, "remarks", $$v);
      },
      expression: "touristRevamp.remarks"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };