//文字转图片
export default function textToImage(text) {
  // let tLength = text.length //获取文本个数
  const canvas = document.createElement('canvas'); //创建画布
  canvas.width = 200; //设置画布宽度，15为字体大小
  canvas.height = 180; //设置画布高度

  const ctx = canvas.getContext('2d'); //获取绘图环境
  ctx.textAlign = 'center'; //设置居中
  ctx.fillStyle = '#fff'; //设置白色画笔
  ctx.fillRect(0, 0, canvas.width, canvas.height); //绘制白色背景，rect为方形
  ctx.font = '22px Arial'; //加粗，字号，字体
  ctx.fillStyle = '#999'; //设置黑色画笔
  ctx.rotate(-Math.PI / 8);
  ctx.fillText(text, canvas.width / 2, canvas.height / 2); //添加文字
  return canvas.toDataURL('image/jpeg'); //返回Base64
}