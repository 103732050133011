import newIcon from '@/assets/news.png';
import DialogPolicy from './DialogPolicy.vue';
import { parseTime } from '@/utils';
import { ColumnsNotice as Columns } from '../tableConfig';
export default {
  components: {
    DialogPolicy
  },
  data() {
    return {
      Columns,
      newIcon
    };
  },
  computed: {
    data() {
      return this.$store.getters['notice/policy/data'];
    },
    tableData() {
      const data = this.data;
      return data ? this.formatData(data.list) : [];
    },
    total() {
      const data = this.data;
      return data ? data.totalCount : 0;
    }
  },
  watch: {
    data: {
      handler: function (d) {
        if (!d) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(params) {
      return this.$store.dispatch('notice/policy/list', params);
    },
    onPage(params) {
      this.fetchData(params);
    },
    formatData(data) {
      return data.map(it => {
        return {
          ...it,
          $createTime: it.createTime ? parseTime(it.createTime) : ''
        };
      });
    },
    showDialog(row) {
      this.$refs.dialogRef.show({
        ...row
      });
    }
  }
};