import "core-js/modules/es.array.push.js";
import { shoppingInputDetail } from '@/config/shopping';
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: [],
      columnData: shoppingInputDetail
    };
  },
  computed: {
    getTotalMoney() {
      return (row, key) => {
        const {
          money
        } = row;
        const val = row[key] || 0;
        return Number(money * val) / 100;
      };
    }
  },
  watch: {
    form: {
      handler: function (data) {
        if (data.shopGoods) {
          this.tableData = data.shopGoods;
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchData(id) {
      this.$store.dispatch('plan/shopping/fetch', {
        planId: id
      }).then(data => {
        this.tableData = this.formatData(data.list);
      });
    },
    formatData(list) {
      if (!list.length) return [];
      const result = [];
      list.forEach(it => {
        const itemList = it.shopGoods;
        const goodsList = it.contractPrices || [];
        const item = {
          ...it,
          rowId: it.id
        };
        if (itemList.length) {
          itemList.forEach((v, i) => {
            result.push({
              ...item,
              ...v,
              goodsList: this.formatOptions(goodsList),
              itemIndex: i
            });
          });
        } else {
          result.push({
            ...item,
            itemIndex: 0
          });
        }
        // 同步id
        this.rowId++;
      });
      return result;
    },
    formatOptions(list) {
      if (!list || !list.length) return;
      return list.map(it => {
        it.label = it.contentType;
        it.value = it.id;
        return it;
      });
    }
  }
};