var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SelectData", _vm._g(_vm._b({
    attrs: {
      data: _vm.getData,
      list: _vm.list,
      label: _vm.label
    },
    on: {
      "on-load": _vm.loadMoreData,
      focus: _vm.lazyLoad
    }
  }, "SelectData", _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };