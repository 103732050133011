export default {
  name: 'FooterScrollBar',
  props: {
    width: {
      type: Number,
      required: true
    },
    realWidth: {
      type: Number,
      required: true
    }
  },
  computed: {
    getBarWidth() {
      return {
        width: this.width + 'px'
      };
    },
    getDragBarWidth() {
      return {
        width: this.realWidth + 'px'
      };
    }
  },
  mounted() {
    const el = document.querySelector('.table-scroll-bar');
    el.addEventListener('scroll', () => {
      this.$emit('on-scroll', el.scrollLeft);
    }, false);
  }
};