import "core-js/modules/es.array.push.js";
import Breadcrumb from '@/components/Breadcrumb';
import { mapGetters } from 'vuex';
export default {
  components: {
    Breadcrumb
  },
  props: {
    isSideBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: '',
      activeIndex: '1'
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData',
      roleData: 'system/role/roleData'
    }),
    notDashboard() {
      const {
        path
      } = this.$route;
      return path != '/dashboard';
    },
    isCollapse() {
      return this.$store.state.app.sidebar.opened;
    },
    getRole() {
      if (!this.roleData) return '';
      const item = this.roleData.list.find(it => it.id == this.userData.roleId);
      return item ? item.name : '';
    },
    getCompany() {
      return this.userData && this.userData.travelAgency.name;
    },
    getUserName() {
      return this.userData && this.userData.realName;
    }
  },
  watch: {
    value() {
      this.toggleSideBar();
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login`);
    }
  }
};