export default {
  props: {
    searchLoading: {
      type: Boolean,
      default: false
    },
    excelLoading: {
      type: Boolean,
      default: false
    },
    wordLoading: {
      type: Boolean,
      default: false
    },
    hasWord: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasExcel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    exportExcel() {
      this.$bus.open(() => {
        this.$emit('on-excel');
      }, '确定要导出吗？');
    },
    exportWord() {
      this.$emit('on-word');
    },
    handleSearch() {
      this.$emit('on-search');
    }
  }
};