import TableList from './TableList';
import { shopData } from '@/config/shopping';
import { BtnExport, SelectShop } from '@/components/common';
export default {
  components: {
    TableList,
    BtnExport,
    SelectShop
  },
  data: () => ({
    searchParams: {},
    tableLoading: false,
    columnData: shopData,
    startTripDate: [],
    shopDate: [],
    tableData: []
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    // 选择发团日期、进店日期
    onChangStartTripDate(type, date) {
      if (type == 'startTripDate') {
        this.searchParams.startTripDateS = date[0];
        this.searchParams.startTripDateE = date[1];
      }
      if (type == 'shopDate') {
        this.searchParams.inDateS = date[0];
        this.searchParams.inDateE = date[1];
      }
    },
    onSelect(type, val) {
      const {
        label,
        value
      } = val;
      this.searchParams[type + 'Name'] = label;
      this.searchParams[type + 'Id'] = value;
    },
    selectPlanType(val) {
      if (val) {
        val.value = val.value.toUpperCase();
      }
      this.searchParams.orderType = val.value;
    },
    selectArea(val) {
      this.searchParams.area = val.label;
    },
    // 搜索
    handleSearch() {
      this.fetchData(this.searchParams);
    },
    fetchData() {},
    exportExcel() {}
  }
};