import { BillInput, ResourceItem } from '@/components/common';
import { cashLogType } from '@/config/billDown';
export default {
  components: {
    BillInput,
    ResourceItem
  },
  data() {
    return {
      visible: false,
      form: {}
    };
  },
  methods: {
    show(data) {
      const {
        cash,
        resourceName,
        cashType,
        subjectId
      } = data;
      this.visible = true;
      this.form = {
        ...data,
        money: cash,
        cashType,
        cashTypeLabel: cashLogType[cashType],
        resourceName,
        subject: subjectId
      };
    },
    hide() {
      this.visible = false;
    },
    handleCommit() {
      this.$refs.billInputRef.onSave();
    },
    handleSave(data) {
      const opts = {
        ...data,
        ...this.form,
        cashMode
      };
      const cashMode = opts.cashMode && opts.cashMode.toUperCase();
      this.$store.dispatch('bill/billDown/pay/createPay', {
        ...opts,
        cashMode
      }).then(() => {
        this.$bus.tip();
        this.hide();
        this.$emit('reload');
      });
    }
  }
};