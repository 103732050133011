var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-collapse", _vm._g(_vm._b({
    class: {
      "big-title": _vm.isStrong
    },
    model: {
      value: _vm.activeNames,
      callback: function ($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, "el-collapse", _vm.$attrs, false), _vm.$listeners), [_c("el-collapse-item", {
    attrs: {
      title: _vm.title,
      name: "1"
    }
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };