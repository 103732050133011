export default {
  created() {
    this.columnData = this.isDetail() ? this.columnData.slice(0, -1) : this.columnData;
  },
  methods: {
    isDetail() {
      const path = this.$route.path;
      const name = path.substring(path.lastIndexOf('/') + 1);
      return name.split('-')[1] == 'detail';
    }
  }
};