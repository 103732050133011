import "core-js/modules/es.array.reduce.js";
import { tableMixin, rowProfitMixin } from '@/mixin';
export default {
  mixins: [tableMixin, rowProfitMixin],
  data() {
    return {};
  },
  methods: {
    onChecksLink(row, type) {
      this.$parent.onLink({
        row,
        type
      });
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      let avgIdx = {};
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // 求和
        if (column.property != 'action' && column.property != 'contactName' && column.property != 'customerArea') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          const avgList = data.map(it => {
            return it;
          });
          // 总订单数
          const order = avgList.reduce((acc, cur) => (acc, cur['orders'] + acc), 0);
          // 总已对单
          const check = avgList.reduce((acc, cur) => (acc, cur['checks'] + acc), 0);
          if (column.property == 'checks') {
            avgIdx = {
              index,
              check,
              order
            };
          }
          const [, suffix] = sums[index].toString().split('.');
          sums[index] = suffix && suffix.length > 2 ? Number(sums[index]).toFixed(2) : sums[index];
        }
      });
      sums[avgIdx.index] = `${avgIdx.check}/${avgIdx.order}`;
      return sums;
    },
    sumTotal(values) {
      let sums = '';
      sums = values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
      return sums;
    }
  }
};